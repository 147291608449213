// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { DATE_PROPS, NAME_PROPS } from './constants/documents-table-header.constants';
// Styles
import { DocumentsTableHeaderComponentStyled } from './documents-table-header.component.styled';
// Translations
import { DATE, NAME } from './translations/documents-table-header.translations';

export const DocumentsTableHeaderComponent = (): React.ReactElement => {
  return (
    <DocumentsTableHeaderComponentStyled>
      <ParagraphComponent {...NAME_PROPS}>
        <FormattedMessageComponent id={DATE} />
      </ParagraphComponent>
      <ParagraphComponent {...DATE_PROPS}>
        <FormattedMessageComponent id={NAME} />
      </ParagraphComponent>
    </DocumentsTableHeaderComponentStyled>
  );
};
