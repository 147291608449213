// Resolvers
import { getBenefitsResolver } from '../resolvers/card-settings-benefits.resolvers';
// Types
import { CardSettingsBenefitsLandingHandlersReturnType } from './types/card-settings-benefits.handlers-return.type';
import { CardSettingsBenefitsLandingHandlersPropsType } from './types/card-settings-benefits.handlers.type';

const fetchBenefits = async ({
  cardContractId,
  setBenefits,
  setError,
  setFetching,
}: CardSettingsBenefitsLandingHandlersPropsType) => {
  setFetching(true);
  const [response, error] = await getBenefitsResolver({
    cardContractId,
  });

  if (error) {
    setError(true);
  } else {
    setBenefits(response);
  }
  setFetching(false);
};

const CardSettingsBenefitsLandingHandlers = ({
  cardContractId,
  setBenefits,
  setError,
  setFetching,
}: CardSettingsBenefitsLandingHandlersPropsType): CardSettingsBenefitsLandingHandlersReturnType => ({
  handleFetchBenefits: () => fetchBenefits({ cardContractId, setBenefits, setError, setFetching }),
});

export { CardSettingsBenefitsLandingHandlers };
