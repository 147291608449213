// Event handlers
import TransactionsLandingRowDetailsHandlers from '../handlers/transactions-landing-row-details-report.handlers';
import TransactionsLandingRowDetailsTrackingHandlers from '../handlers/transactions-landing-row-details-report.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsLandingRowDetailsReportBuilderReturnHandlersType } from '../handlers/types/transactions-landing-row-details-report-builder-return.handlers.type';
import { useNavigate } from 'react-router-dom';

const TransactionsLandingRowDetailsReportHook = (
  id: string
): TransactionsLandingRowDetailsReportBuilderReturnHandlersType =>
  TransactionsLandingRowDetailsHandlers({
    ...AppTrackingHook(TransactionsLandingRowDetailsTrackingHandlers),
    id,
    navigate: useNavigate(),
  });

export default TransactionsLandingRowDetailsReportHook;
