// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPPIContractingStep3TrackingHandlers } from '../handlers/financing-ppi-contracting-step3.tracking.handlers';
import { useFinancingPPIContractingContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/contexts/financing-ppi-contracting.context';
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import { FinancingPPIContractingStep3Handlers } from '../handlers/financing-ppi-contracting-step3.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep3HookType } from './types/financing-ppi-contracting-step3.hook.type';

const useFinancingPPIContractingStep3Hook = (): FinancingPPIContractingStep3HookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [challenged, setChallenged] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');

  const {
      documentDownloaded,
      postalDocumentation,
      setErrorDocumentDownloaded,
      setDocumentDownloaded,
    } = useFinancingPPIContractingContextConsumerHook(),
    { setFetchPPI } = useFinancingPPIContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { cardContractId: productContractId, productTypeCode: productContractType } = currentCard || {};

  const { handleResetDocumentDownloadedEvent, ...handlers } = FinancingPPIContractingStep3Handlers({
    ...AppTrackingHook(FinancingPPIContractingStep3TrackingHandlers),
    documentDownloaded,
    productContractId,
    productContractType,
    postalDocumentation,
    progressId,
    setChallenged,
    setDocumentDownloaded,
    setErrorDocumentDownloaded,
    setFetching,
    setFetchPPI,
    setProgressId,
    setResult,
  });

  useEffect(() => {
    handleResetDocumentDownloadedEvent();
  }, []);

  return {
    ...handlers,
    challenged,
    fetching,
    productContractId,
    productContractType,
    postalDocumentation,
  };
};

export { useFinancingPPIContractingStep3Hook };
