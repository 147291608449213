// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';

export const isErrorStatus = (status: number, progressId?: string): boolean => {
  const isErrorStatus = !httpStatusUtils.isSuccessStatus(status);

  if (progressId) {
    return isErrorStatus;
  }

  return isErrorStatus && status !== HttpStatusCodesEnum.FORBIDDEN;
};
