// Vendors
import { apiUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  GET_DEVICES_PROPS,
  GET_DEVICES_PARAMS,
  GET_TRUSTED_DEVICE_PROPS,
  GET_SESSONS_PROPS,
  MODIFY_DEVICE_ALIAS_PROPS,
  UNROLL_TRUSTED_DEVICE_PROPS,
  BLOCK_DEVICE_PROPS,
  UNBLOCK_DEVICE_PROPS,
  SIGN_OUT_DEVICE_PROPS
} from './constants/signature-key-devices.services.constants';
// Types
import { SignatureKeyDevicesDeviceType } from '../types/signature-key-devices.device.type';
import { SignatureKeyDevicesSessionType } from '../types/signature-key-devices.session.type';
import { DevicesListPropsType } from '../types/devices-list.props.type';
import { DeviceActionsPropsType } from '../types/device-action.props.type';
import { DeviceUnrollPropsType } from '../types/device-unroll.props.type';
import { EditAliasPropsType } from '../types/edit-alias.props.type';
import { SignOutPropsType } from '../types/sign-out.props.type';

const fetchDevicesList = async (
  { blocked }: DevicesListPropsType): Promise<[Array<SignatureKeyDevicesDeviceType>, Response]> => 
  await apiUtils.post({
    ...GET_DEVICES_PROPS,
    queryParams: {
      ...GET_DEVICES_PARAMS,
      blocked
    }
  });

const fetchTrustedDevice = async (): Promise<[SignatureKeyDevicesDeviceType, Response]> => 
  await apiUtils.get(GET_TRUSTED_DEVICE_PROPS);

const fetchSessionsListById = async (
  { deviceUUID }: DeviceActionsPropsType): Promise<[Array<SignatureKeyDevicesSessionType>, Response]> => 
  await apiUtils.post({
    ...GET_SESSONS_PROPS,
    params: {
      deviceUUID,
    },
  });

  const fetchEditDeviceAlias = async (
    { deviceUUID, alias }: EditAliasPropsType): Promise<[Record<string, string>, Response]> => 
  await apiUtils.post({
    ...MODIFY_DEVICE_ALIAS_PROPS,
    params: {
      deviceUUID,
      alias
    },
  });
  
  const fetchUnrollTrustedDevice = async (
    { deviceUUID, progressId }: DeviceUnrollPropsType): Promise<[null, Response]> => 
  await apiUtils.post({
    ...UNROLL_TRUSTED_DEVICE_PROPS,
    params: {
      deviceUUID,
    },
    customHeaders: progressId ? { progressId } : {},
  });

  const fetchBlockDevice = async (
    { deviceUUID }: DeviceActionsPropsType): Promise<[null, Response]> => 
  await apiUtils.post({
    ...BLOCK_DEVICE_PROPS,
    params: {
      deviceUUID,
    },
  });

  const fetchUnblockDevice = async (
    { deviceUUID }: DeviceActionsPropsType): Promise<[null, Response]> => 
  await apiUtils.post({
    ...UNBLOCK_DEVICE_PROPS,
    params: {
      deviceUUID,
    },
  });

  const fetchSignOutDevice = async (
    { sessionId }: SignOutPropsType): Promise<[null, Response]> => 
  await apiUtils.post({
    ...SIGN_OUT_DEVICE_PROPS,
    params: {
      sessionId
    },
  });

export {
  fetchDevicesList,
  fetchTrustedDevice,
  fetchSessionsListById,
  fetchEditDeviceAlias,
  fetchUnrollTrustedDevice,
  fetchBlockDevice,
  fetchUnblockDevice,
  fetchSignOutDevice
};