// Enumerations
import { CardSettingsStatementsTableRowTagEnumeration } from '../enumerations/card-settings-statements-table-row-tag.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cardStatements.status.';

const CARD_STATEMENTS_STATUS_TAG = {
  [CardSettingsStatementsTableRowTagEnumeration.PAID]: `${DOMAIN}paid`,
  [CardSettingsStatementsTableRowTagEnumeration.PENDING]: `${DOMAIN}pending`,
  [CardSettingsStatementsTableRowTagEnumeration.UNPAID]: `${DOMAIN}unpaid`,
};

export { CARD_STATEMENTS_STATUS_TAG };
