// Vendors
import React from 'react';
// Components
import { ChipsComponent } from '@openbank/cb-ui-commons';
// Types
import { formatFormValuesToFilterChips } from './filter-chips-map/utils/filter-chips-map.utils';
// Utilities
import { FilterChipsMapComponentPropsType } from './filter-chips-map/types/filter-chips-map.component.type';

const FilterChipsMapComponent = ({
  formValues,
  handleClickChip,
}: FilterChipsMapComponentPropsType): React.ReactElement | null => {
  return (
    <>
      {formatFormValuesToFilterChips({ formValues, handleClickChip }).map((filterChip, i) => (
        <ChipsComponent key={i + filterChip.id} {...filterChip} />
      ))}
    </>
  );
};

export { FilterChipsMapComponent };
