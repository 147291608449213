// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Enumerations
import { ActivityTypeDeclaredEnumeration } from 'containers/financing/views/credit-limit-increase/components/step2/sections/employment-data/enumerations/employment-status.enumeration';
// Handlers
import { InitHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-0-init.handler';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import { Step4UploadDocumentationTracking } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/trackings/step-4-upload-documentation.tracking';
// Types
import { UploadDocumentsSectinoHookType } from './types/upload-documents-section.hook.type';
import { PersonalDataLocalType } from 'containers/financing/views/credit-limit-increase/contexts/types/personal-data-local-step-2.type';

const UploadDocumentsSectionHook = (): UploadDocumentsSectinoHookType => {
  const [isLoadingExitProcess, setIsLoadingExitProcess] = useState(false);
  const {
    handleOpenItIsNotPossibleModalTracking,
    handleCloseItIsNotPossibleModalTracking,
    handleClickButtonItIsNotPossibleModalTracking,
  } = AppTrackingHook(Step4UploadDocumentationTracking);
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};
  const { step4 } = creditLimitContext;
  const { handleCancelProcessClick } = InitHandlers({ cardId, creditLimitContext });
  const { activityTypeDeclared } = step4.stepData?.personalData as PersonalDataLocalType;
  const { documentsRejected } = step4?.stepData || {};
  const isRentierOrSelfEmployed =
    activityTypeDeclared === ActivityTypeDeclaredEnumeration.RENTIER ||
    activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED;

  useEffect(handleOpenItIsNotPossibleModalTracking, []);

  return {
    activityTypeDeclared,
    documentsRejected,
    isLoadingExitProcess,
    step4,
    onHeaderClose: async () => {
      handleCloseItIsNotPossibleModalTracking();
      await handleCancelProcessClick({ setDisabledButtonsCancelModal: setIsLoadingExitProcess });
    },
    handOnClickExitProcess: async () => {
      handleClickButtonItIsNotPossibleModalTracking();
      await handleCancelProcessClick({ setDisabledButtonsCancelModal: setIsLoadingExitProcess });
    },
  };
};

export { UploadDocumentsSectionHook };
