const RECOVERY_KEY_STEP1_EMAIL_PROPS = {
  isRequired: true,
  testing: {
    helperId: 'recovery-key-constraints-helper',
    inputId: 'recovery-key-constraints-input',
    labelId: 'recovery-key-constraints-label',
  },
  type: 'email',
};

export { RECOVERY_KEY_STEP1_EMAIL_PROPS };
