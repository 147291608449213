export default {
  CONTEXT_DEFAULT_VALUE: {
    accessKey: '',
    isDisabledLastStep: false,
    newAccessKey: '',
    progressId: '',
    repeatNewAccessKey: '',
    setAccessKey: (): null => null,
    setIsDisabledLastStep: (): null => null,
    setNewAccessKey: (): null => null,
    setProgressId: (): null => null,
    setRepeatNewAccessKey: (): null => null,
    setInputError: (): null => null,
    inputError: null,
  },
};
