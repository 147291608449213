// Vendors
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// Utils
import { getBannerProps } from './utils/banner.component.hook.utils';
// Types
import { AppBannerComponentHookType } from './types/banner.component.hook.types';

export const AppBannerComponentHook = (): AppBannerComponentHookType => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const bannerProps = getBannerProps({ formatMessage, navigate });

  return { bannerProps };
};
