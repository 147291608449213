// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const NotificactionsSettingsListComponentStyled = styled.section.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  width: 100%;
`;

const NotificactionsSettingsListToggleComponentStyled = styled.section`
  padding: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingXxxxxl} ${theme.spaces.spacingS}`};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingL} ${theme.spaces.spacingS}`};
  }
`;

const NotificactionsSettingsListSectionDividerWrapperStyled = styled.section`
  padding: 0 ${({ theme }) => theme.spaces.spacingXxxxl};
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryA};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `0 ${theme.spaces.spacingS}`};
    background-color: transparent;
  }
`;

export {
  NotificactionsSettingsListComponentStyled,
  NotificactionsSettingsListToggleComponentStyled,
  NotificactionsSettingsListSectionDividerWrapperStyled,
};