// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

export default {
  LOGIN_GAPS_ASSET_PROPS: {
    id: 'edit',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'backgroundInfoD',
    },
    sizes: {
      height: 32,
      width: 32,
    },
    testId: 'login-gaps-title-asset',
  },
  LOGIN_GAPS_TITLE_PROPS: {
    overflow: true,
    testId: 'login-gaps-title',
    tag: titleConstants.TAGS.H3,
  },
};
