// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Constants
import { NUMBER } from 'constants/number.constants';
// Contexts
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Handlers
import NotificationsLandingHandlers from '../handlers/notifications-landing.handlers';
import NotificationsLandingTrackingHandlers from '../handlers/notifications-landing-tracking.handlers';
// Hooks
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import {
  NotificationsLandingHookReturnType,
  NotificationsLandingPaginationStateType,
} from './types/notifications-landing.hook.type';
// Utils

const NotificationsLandingHook = (): NotificationsLandingHookReturnType => {
  const { notificationsData } = ManagerContextConsumerHook();
  const { formatDate } = useCbIntl();
  const [paginationState, setPaginationState] = useState<NotificationsLandingPaginationStateType>({
    ...(notificationsData?.pagination || {}),
  });
  const [dataState, setDataState] = useState([...(notificationsData?.data || [])]);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean | undefined>();
  const [page, setPage] = useState<number>(0);

  const {
    formValues,
    formValuesParams,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setIsShowFilterModal,
  } = useFiltersContextConsumerHook();

  const {
    handleNotificationNextPage,
    handleSetPagination,
    onUserEnter,
    handleGoToNotificationsSettings,
    handleFetchNotifications,
    handleShowFilterModal,
  } = NotificationsLandingHandlers({
    dataState,
    formatDate,
    formValuesParams,
    isLoadingNextPage,
    navigate: useNavigate(),
    page,
    paginationState,
    setDataState,
    setError,
    setIsLoading,
    setIsLoadingNextPage,
    setIsShowFilterModal,
    setPage,
    setPaginationState,
    ...AppTrackingHook(NotificationsLandingTrackingHandlers),
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onUserEnter(error);
  }, [error]);

  useEffect(() => {
    setPaginationState({ ...paginationState, ...{} });
    handleFetchNotifications();
  }, [formValuesParams]);

  useEffect(() => {
    if (page > NUMBER.ZERO && notificationsData?.pagination) {
      handleNotificationNextPage();
    }
  }, [page]);

  return {
    canGoNextPage: paginationState.pageNumber + 1 < paginationState.totalPages,
    error,
    formValues,
    formValuesParams,
    handleGoToNotificationsSettings,
    handleSetPagination,
    handleShowFilterModal,
    isLoading,
    isLoadingNextPage,
    notifications: dataState,
    page,
    retryHandler: () => handleNotificationNextPage(),
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setIsLoading,
    setIsLoadingNextPage,
  };
};

export default NotificationsLandingHook;
