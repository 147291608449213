// Types
import { FinancingPaymentMethodSecondStepReturnHandlersType } from './types/financing-payment-method-second-step-return.handlers.type';
import { FinancingPaymentMethodSecondStepHandlersType } from './types/financing-payment-method-second-step.handlers.type';
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';
import { ChallengeRenderHandlerType } from './types/challenge-render-handler.type';

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType) => {
  if (!challenge) setError(true);
};

const challengeSuccessHandler = ({ challenge, setChallenge }: ChallengeSuccessHandlerType) => {
  setChallenge(challenge);
};

const challengeRetryButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => setChallengeError(false);

const challengeRenderHandler = ({ paymentMethodParams, setError }: ChallengeRenderHandlerType) => {
  if (!paymentMethodParams) setError(true);
};

const FinancingPaymentMethodSecondStepHandlers = ({
  challenge,
  paymentMethodParams,
  setChallenge,
  setChallengeError,
  setError,
}: FinancingPaymentMethodSecondStepHandlersType): FinancingPaymentMethodSecondStepReturnHandlersType => ({
  handleChallengeError: () => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setChallengeError }),
  handleChallengeRender: () => challengeRenderHandler({ paymentMethodParams, setError }),
});

export default FinancingPaymentMethodSecondStepHandlers;
