// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputTextComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/profile-personal-name-alias-input.constants';
// Custom Hooks
import ProfilePersonalNameAliasInputHook from './hooks/profile-personal-name-alias-input.hook';
// Translations
import translations from './translations/profile-personal-name-alias-input.translations';
// Utilities
import { saveNewAliasLinkIsDisabled } from './utils/profile-personal-name-alias-input.utils';

const ProfilePersonalNameAliasInputComponent = (): React.ReactElement => {
  const {
    alias,
    fetching,
    formatMessage,
    error,
    handleChangeAliasInputClickTracking,
    handleCheckNewAliasEmailInputEvent,
    handleSaveNewAliasInputEvent,
    handleSubmitNewAliasInputEvent,
    newAlias,
  } = ProfilePersonalNameAliasInputHook();

  return (
    <InputTextComponent
      {...{ ...constants.PROFILE_PERSONAL_NAME_ALIAS_INPUT_INPUT_PROPS, fetching }}
      errorMessage={error && <FormattedMessageComponent id={translations.ERROR_INPUT} />}
      disabled={fetching}
      helperMessage={<FormattedMessageComponent id={translations.LIMIT_CHARS} />}
      label={formatMessage({ id: translations.INPUT_LABEL })}
      limitCharsConfiguration={{
        ...constants.PROFILE_PERSONAL_NAME_ALIAS_INPUT_LIMIT_PROPS,
        error,
      }}
      linkConfiguration={{
        ...constants.PROFILE_PERSONAL_NAME_ALIAS_INPUT_LINK_PROPS,
        children: <FormattedMessageComponent id={translations.LINK} />,
        disabled: saveNewAliasLinkIsDisabled({ alias, fetching, error, newAlias }),
        onClick: handleSubmitNewAliasInputEvent,
      }}
      onBlur={handleCheckNewAliasEmailInputEvent}
      onChange={handleSaveNewAliasInputEvent}
      onClick={handleChangeAliasInputClickTracking}
      value={newAlias}
      testing={constants.PROFILE_PERSONAL_NAME_ALIAS_INPUT_INPUT_PROPS.testing}
    />
  );
};

export default ProfilePersonalNameAliasInputComponent;
