// Vendors
import { useState } from 'react';
// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import { CardStatementTableRowDownloadHandlers } from '../handlers/card-statement-table-row-download.handlers';
import { CardStatementTableRowDownloadTrackingHandlers } from '../handlers/card-statement-table-row-download.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardStatementTableRowDownloadHookType } from './types/card-statement-table-row-download.hook.type';
import { CardStatementTableRowDownloadComponentType } from '../types/card-statement-table-row-download.component.type';

const useCardStatementTableRowDownloadHook = (
  props: CardStatementTableRowDownloadComponentType
): CardStatementTableRowDownloadHookType => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { setToastConfiguration } = ToastContextConsumerHook();

  return {
    ...CardStatementTableRowDownloadHandlers({
      ...AppTrackingHook(CardStatementTableRowDownloadTrackingHandlers),
      ...props,
      setToastConfiguration,
      setFetching,
    }),
    fetching,
  };
};

export { useCardStatementTableRowDownloadHook };
