// Vendors
import styled from 'styled-components';
// Utilities
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from '@openbank/cf-ui-framework';

const OtpSectionComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;
OtpSectionComponentStyled.displayName = 'OtpSectionComponentStyled';

const OtpSectionContentComponentStyled = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: 30% 70%;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
  }
`;
OtpSectionContentComponentStyled.displayName = 'OtpSectionContentComponentStyled';

const OtpSectionContentResumeComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
OtpSectionContentResumeComponentStyled.displayName = 'OtpSectionContentResumeComponentStyled';

export {
  OtpSectionComponentStyled,
  OtpSectionContentComponentStyled,
  OtpSectionContentResumeComponentStyled,
};
