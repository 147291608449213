// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { DashboardContextConsumerHook } from 'containers/dashboard/contexts/dashboard.context';
// Event handlers
import DashboardTableHandlers from '../handlers/dashboard-table.handlers';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Types
import { DashboardTableHookType } from './types/dashboard-table.hook.type';
// Utils
import { getDefaultCardInfo } from 'containers/dashboard/utils/get-default-card-info.utils';

const DashboardTableHook = (): DashboardTableHookType => {
  const navigate = useNavigate();
  const [error, setError] = useState<TransactionsFetchErrorEnumeration | null>(null);

  const { information } = DashboardContextConsumerHook(),
    { transactions = {} } = getDefaultCardInfo(information) || {};

  const { handleSetupDashboardTableError, handleNavigateToTransactions } = DashboardTableHandlers({
    setError,
    transactions,
    navigate,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupDashboardTableError();
  }, []);

  return {
    error,
    transactions,
    handleNavigateToTransactions,
  };
};

export default DashboardTableHook;
