export const EDIT_ICON_PROPS = {
  colors: { fill: 'colorLinkPrimaryA' },
  id: 'edit',
  sizes: { width: 24, height: 24 },
  testId: 'edit-alias-link',
};

export const LINK_PROPS = {
  assetConfiguration: {
    id: 'chevron_right'
  },
  size: {
    width: 12,
    height: 12
  },
  testId: 'manage-device-link-mobile',
};