// Translations
import { TITLE, UNDERSTOOD } from '../translations/card-pin-info-modal.translations';
// Types
import { GetCardPinInfoModalConfigurationsType } from './types/card-pin-info-modal.configurations.type';
import { GetCardPinInfoModalConfigurationsReturnType } from './types/card-pin-info-modal.configurations.return.type';

export const getCardPinInfoModalConfigurations = (
  { onCloseModal, formatMessage
}: GetCardPinInfoModalConfigurationsType): GetCardPinInfoModalConfigurationsReturnType => ({
  title: formatMessage({ id: TITLE }),
  onHeaderClose: onCloseModal,
  primaryButtonConfiguration: {
    children: formatMessage({ id: UNDERSTOOD }),
    onClick: onCloseModal,
  }
});