// Translations
import {
  CARD_CONTROL,
  FAST_CASH,
  GET_CVV,
  GET_PIN,
  DEPOSIT_MONEY_EXTERNAL,
  MODIFY_PIN,
  PPI,
  STATEMENTS,
  UPDATE_PAYMENT_METHOD,
  ALL_OPTIONS,
} from './quick-actions.translations.common';

const DOMAIN = 'cobrandedCC.services.userSettings.privateArea.cbQuickActions.quickActions.';

const AUTOMATIC_RENEWAL = `${DOMAIN}automaticRenewal`,
  REQUEST_ADDITIONAL_CREDIT = `${DOMAIN}requestModifyCredit`,
  RESET_BLOCK_PIN = `${DOMAIN}resetBlockPIN`,
  VIEW_DENIED_MOVEMENTS = `${DOMAIN}viewDeniedMovements`;

export {
  AUTOMATIC_RENEWAL,
  RESET_BLOCK_PIN,
  VIEW_DENIED_MOVEMENTS,
  CARD_CONTROL,
  FAST_CASH,
  GET_CVV,
  GET_PIN,
  DEPOSIT_MONEY_EXTERNAL,
  MODIFY_PIN,
  PPI,
  REQUEST_ADDITIONAL_CREDIT,
  STATEMENTS,
  UPDATE_PAYMENT_METHOD,
  ALL_OPTIONS,
};
