const validateRangeValues = (
  fromValue: string | undefined,
  toValue: string | undefined
): boolean => {
  if (!fromValue || !toValue) {
    return false;
  }
  return parseFloat(fromValue) >= parseFloat(toValue);
};

export { validateRangeValues };
