// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { useCardSettingsChange3DContextConsumerHook } from 'containers/card-settings/views/change-3d/contexts/card-settings-change-3d.context';
// Event handlers
import { CardSettingsChange3dStep1RepeatedCodeHandlers } from '../handlers/card-settings-change-3d-step1-repeated-code.handlers';
import { CardSettingsChange3dStep1RepeatedCodeTrackingHandlers } from '../handlers/card-settings-change-3d-step1-repeated-code.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChange3dStep1RepeatedCodeHookType } from 'containers/card-settings/views/change-3d/components/step1/components/repeated-code/hooks/types/card-settings-change-3d-step1-repeated-code.hook.type';

const useCardSettingsChange3DStep1RepeatedCodeHook =
  (): CardSettingsChange3dStep1RepeatedCodeHookType => {
    const [error, setError] = useState<boolean>(false);

    const { new3DCode, repeated3DCode, isRepeated3DCodeEnabled, setRepeated3DCode } =
    useCardSettingsChange3DContextConsumerHook();

    const { handleRepeated3DCodeInputErrorTracking, handleRepeated3DCodeInputClickTracking } =
        AppTrackingHook(CardSettingsChange3dStep1RepeatedCodeTrackingHandlers),
      {
        handleCheckNewCodeErrorEvent,
        handleSaveRepeated3dCodeInputChangeEvent,
        handleValidateRepeated3dCodeInputChangeEvent,
      } = CardSettingsChange3dStep1RepeatedCodeHandlers({
        handleRepeated3DCodeInputErrorTracking,
        new3DCode,
        repeated3DCode,
        setRepeated3DCode,
        setError,
      });

    useEffect(() => {
      handleCheckNewCodeErrorEvent();
    }, [new3DCode]);

    return {
      error,
      handleRepeated3DCodeInputClickTracking,
      handleSaveRepeated3dCodeInputChangeEvent,
      handleValidateRepeated3dCodeInputChangeEvent,
      repeated3DCode,
      isRepeated3DCodeEnabled,
    };
  };

export { useCardSettingsChange3DStep1RepeatedCodeHook };
