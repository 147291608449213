// Vendors
import React from 'react';
// Components
import LabelsLinkCardComponent from 'components/labels-link-card/labels-link-card.component';
// Constants
import constants from './constants/security-keys-password.constants';
// Hooks
import SecurityKeysHook from 'containers/security-keys/views/landing/hooks/security-keys.hook';
// Resolvers
import { getLastTimeSecurityItemChangeResolver } from '../../resolvers/security-keys-landing.resolvers';
// Translations
import translations from './translations/security-keys-access-key.translations';

const SecurityKeysAccessKeyComponent = (): React.ReactElement => {
  const { timestamp: date } = SecurityKeysHook(
    getLastTimeSecurityItemChangeResolver(constants.SECURITY_KEYS_ACCESS_KEY_ENDPOINT)
  );

  return (
    <LabelsLinkCardComponent
      {...constants.SECURITY_KEYS_ACCESS_KEY_PROPS}
      changeConfiguration={{ date, label: translations.LAST_LABEL }}
    />
  );
};

export default SecurityKeysAccessKeyComponent;
