// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/login-error.constants';
// Styles
import { LoginErrorStyled } from './login-error.component.styled';
// Types
import { LoginErrorInterface } from './types/login-error.component.type';

const LoginErrorComponent: React.FC<LoginErrorInterface> = ({ error, message, link }) =>
  error ? (
    <MessageComponent {...constants.LOGIN_MESSAGE_PROPS}>
      <LoginErrorStyled>
        <ParagraphComponent {...constants.LOGIN_MESSAGE_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={message} />
        </ParagraphComponent>
        {link}
      </LoginErrorStyled>
    </MessageComponent>
  ) : null;

export { LoginErrorComponent };
