// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Enumerations
import { CustomerTitlesEnumeration } from 'enumerations/customer-titles.enumeration';
// Types
import { ProfilePersonalNameTitleDropdownBuilderReturnTrackingHandlersType } from './types/profile-personal-name-title-dropdown-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const miscellaneousTitlesDropdownClickTrackingHandler = (
  item: CustomerTitlesEnumeration
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickDropdown',
    eventLabel: 'miscellaneousTitles:' + item,
  },
  type: TrackingEventsEnum.LINK,
});

const miscellaneousTitleClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickDropdown',
    eventLabel: 'miscellaneousTitles',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameTitleDropdownTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameTitleDropdownBuilderReturnTrackingHandlersType => ({
  handleMiscellaneousTitleClickTracking: () => track(miscellaneousTitleClickTrackingHandler()),
  handleMiscellaneousTitlesDropdownClickTracking: (item: CustomerTitlesEnumeration) =>
    track(miscellaneousTitlesDropdownClickTrackingHandler(item)),
});

export default ProfilePersonalNameTitleDropdownTrackingHandlers;
