// Types
import { PersonalNumberStep4ActionsHookType } from '../hooks/types/personal-number-step4-actions.hook.type';

const confirmChangePhoneActionButtonIsDisabled = ({
  challenged,
  fetching,
}: Pick<PersonalNumberStep4ActionsHookType, 'challenged' | 'fetching'>): boolean =>
  Boolean(challenged || fetching);

export { confirmChangePhoneActionButtonIsDisabled };
