// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/toast.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ToastComponentType } from 'components/toast/types/toast.component.type';
import { ToastContextType } from './types/toast.context.type';

const ToastContext = createContext<ToastContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const ToastContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ToastContextType>> => {
  const [toastConfiguration, setToastConfiguration] = useState<
    ToastComponentType | Record<string, unknown>
  >({});

  return (
    <ToastContext.Provider value={{ setToastConfiguration, toastConfiguration }}>
      {children as React.ReactElement}
    </ToastContext.Provider>
  );
};

export const ToastContextConsumerHook = (): ToastContextType => useContext(ToastContext);

/* eslint-disable */
export const ToastContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ToastContextType>> =>
    (
      <ToastContextProvider>
        <Component {...props} />
      </ToastContextProvider>
    );
