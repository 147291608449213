// Constants
import { messageConstants, paragraphConstants } from '@openbank/cb-ui-commons';

const PERSONAL_ADDRESS_SIDEBAR_PROPS = {
  testId: 'personal-address',
};

const PERSONAL_ADDRESS_SIDEBAR_DESCRIPTION_PROPS = {
  testId: 'personal-address-sidebar-description',
  size: paragraphConstants.SIZES.M,
};

const PERSONAL_ADDRESS_SIDEBAR_MESSAGE_PROPS = {
  testId: 'personal-address-sidebar-message',
  type: messageConstants.TYPES.WARNING,
};

const PERSONAL_ADDRESS_SIDEBAR_MESSAGE_01_PROPS = {
  testId: 'personal-address-sidebar-message-01',
};

const PERSONAL_ADDRESS_SIDEBAR_MESSAGE_02_PROPS = {
  testId: 'personal-address-sidebar-message-02',
};

export {
  PERSONAL_ADDRESS_SIDEBAR_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_DESCRIPTION_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_MESSAGE_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_MESSAGE_01_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_MESSAGE_02_PROPS,
};
