// Translations
import {
  DESCRIPTION,
  TITLE,
} from '../translations/financing-ppi-contracting-step2-expandable-circumstances.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_CIRCUMSTANCES_PROPS = {
  description01: DESCRIPTION,
  title: TITLE,
  showLineSeparator: false,
};

export { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_CIRCUMSTANCES_PROPS };
