// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const CardSettingsBlockCardSuccessContentComponentStyled = styled.div`
  margin: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingXxxxxl};

  ${tabletMediaQuery} {
    margin: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl};
  }

  ${mobileMediaQuery} {
    margin: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};
  }
`;
