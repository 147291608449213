const ACCOUNT_PREFIX = '...';
const ACCOUNT_SLICE = -5;
const COMISSION_PERCENTAGE = 100;
const DEFAULT_CURRENCY = 'EUR';
const DEFAULT_VALUE = '--,-- ';
const DEFAULT_COMMISSION = 'X,XX %';
const FORMAT_NUMBER = {
  AMOUNT: 'currency',
  COMMISSION: { minimumFractionDigits: 2 },
};

export {
  ACCOUNT_PREFIX,
  ACCOUNT_SLICE,
  COMISSION_PERCENTAGE,
  DEFAULT_CURRENCY,
  DEFAULT_VALUE,
  DEFAULT_COMMISSION,
  FORMAT_NUMBER,
};
