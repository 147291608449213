const DOMAIN_REPORT_A_PROBLEM_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.confirmation.';

const TOTAL_AMOUNT_LABEL = `${DOMAIN_REPORT_A_PROBLEM_CONFIRMATION}totalAmount`;

const CLAIM_TYPE_LABEL = `${DOMAIN_REPORT_A_PROBLEM_CONFIRMATION}claimType`;

const CARD_TO_BLOCK = `${DOMAIN_REPORT_A_PROBLEM_CONFIRMATION}cardToBlock`;

const SHIPPING_ADDRESS = `${DOMAIN_REPORT_A_PROBLEM_CONFIRMATION}shippingAddress`;

export { CLAIM_TYPE_LABEL, TOTAL_AMOUNT_LABEL, CARD_TO_BLOCK, SHIPPING_ADDRESS };
