// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_INFO_PROPS: {
    testId: 'settings-card-control-locations-info',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_INFO_DESCRIPTION_PROPS: {
    testId: 'settings-card-control-locations-info-table-row-description',
  },
};
