// Contexts
import { useCaptchaContextHook } from 'contexts/captcha/captcha.context';
// Event Handlers
import { CaptchaHandlers } from '../handlers/captcha.handlers';
// Types
import { CaptchaHookType } from './types/captcha.hook.type';
import { useEffect } from 'react';

const useCaptchaHook = (): CaptchaHookType => {
  const { captcha, captchaRef, setCaptcha } = useCaptchaContextHook();

  useEffect(() => {
    captcha && setCaptcha('');
  }, []);

  return { ...CaptchaHandlers({ setCaptcha }), captchaRef };
};

export { useCaptchaHook };
