const CHANGE_ADDRESS_PAYLOAD = {
  eventAction: 'clickBox',
  eventLabel: 'selectAddressSendingCardBox',
};

const ADD_ADDRESS_PAYLOAD = {
  eventAction: 'clickBox',
  eventLabel: 'addNewAddressSendingCardBox',
};

const OPEN_EDIT_ADDRESS_PAYLOAD = {
  eventAction: 'clickIcon',
  eventLabel: 'editNewAddressSendingCardIcon',
};

const OPEN_DELETE_ADDRESS_PAYLOAD = {
  eventAction: 'deleteShippingAddress',
  eventLabel: 'open',
};

const CANCEL_DELETE_ADDRESS_PAYLOAD = {
  eventAction: 'deleteShippingAddress',
  eventLabel: 'navigationKeepTemporaryAddressButton',
};

const CLOSE_DELETE_ADDRESS_PAYLOAD = {
  eventAction: 'deleteShippingAddress',
  eventLabel: 'close',
};

const DELETE_ADDRESS_PAYLOAD = {
  eventAction: 'deleteShippingAddress',
  eventLabel: 'navigationDeleteTemporaryAddressLink',
};

const DEFAULT_COUNTRY_CODE = 'DE';

export {
  ADD_ADDRESS_PAYLOAD,
  CANCEL_DELETE_ADDRESS_PAYLOAD,
  CHANGE_ADDRESS_PAYLOAD,
  CLOSE_DELETE_ADDRESS_PAYLOAD,
  DEFAULT_COUNTRY_CODE,
  DELETE_ADDRESS_PAYLOAD,
  OPEN_DELETE_ADDRESS_PAYLOAD,
  OPEN_EDIT_ADDRESS_PAYLOAD,
};
