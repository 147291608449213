// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Types
import { RecoveryKeyStep1NExtButtonIsDisabledType } from './types/recovery-key-step1-actions.utils.type';
// Utilities
import { isValidCaptcha } from 'components/captcha/utils/captcha.utils';
import { isValidEmail } from 'utils/email.utils';
import { isValidPhoneNumber } from 'utils/phone.utils';

const recoveryKeyIsButtonDisabled = ({
  fetching,
  email,
  prefix,
  phoneNumber,
}: Omit<RecoveryKeyStep1NExtButtonIsDisabledType, 'captcha'>): boolean =>
  Boolean(fetching || !isValidEmail(email) || !isValidPhoneNumber({ prefix, phoneNumber }));

const recoveryKeyStep1NextButtonIsDisabled = ({
  captcha,
  fetching,
  email,
  prefix,
  phoneNumber,
}: RecoveryKeyStep1NExtButtonIsDisabledType): boolean =>
  recoveryKeyIsButtonDisabled({ fetching, email, prefix, phoneNumber }) ||
  !isValidCaptcha({ captcha, featureFlag: FeatureFlagsEnumeration.CAPTCHA_CHANGE_ACCESS_KEY });

export { recoveryKeyIsButtonDisabled, recoveryKeyStep1NextButtonIsDisabled };
