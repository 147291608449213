// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
import { WEBID_SERVICE_URL } from '../components/step6/hooks/constants/step-6-credit-limit-increase.component.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Types
import { OnReceiveMessageHandlerType } from './types/webid-websocket-receive-message-handler.type';
import { Step6WebsocketHandlersReturnType } from './types/step-6-websocket.handler-return.type';
import { Step6WebsocketHandlersType } from './types/step-6-websocket.handler.type';

const onReceiveMessageHandler = ({
  message,
  wizardStatus,
  setIsWebsocketActive,
  setFinalFeedback,
  setWizardStatus,
}: OnReceiveMessageHandlerType) => {
  if (message) {
    const response = JSON.parse(message);
    const nextStep = response?.detail?.payload?.nextStep;
    const taxIdRequired = response?.detail?.payload?.taxIdRequired;

    const currentStep = StepEnumToIndexMap[nextStep];

    if (
      nextStep === CreditLimitIncreaseStepsEnumeration.SUCCESS ||
      nextStep === CreditLimitIncreaseStepsEnumeration.COMPLETED ||
      nextStep === CreditLimitIncreaseStepsEnumeration.ERROR_CMS_SUCCESS
    ) {
      setFinalFeedback({
        taxIdRequired: nextStep === CreditLimitIncreaseStepsEnumeration.SUCCESS && taxIdRequired,
      });
      setWizardStatus({
        ...wizardStatus,
        currentStep,
        currentStepInText: nextStep as CreditLimitIncreaseStepsEnumeration,
      });
      setIsWebsocketActive(false);
    }
  }
};

const onConnectHandler = ({ caseId, webSocketSendMessage }): void => {
  webSocketSendMessage({
    endpoint: WEBID_SERVICE_URL,
    payload: { caseId },
  });
};

const Step6WebsocketHandlers = ({
  caseId,
  setFinalFeedback,
  setWizardStatus,
  setIsWebsocketActive,
  wizardStatus,
  webSocketSendMessage,
}: Step6WebsocketHandlersType): Step6WebsocketHandlersReturnType => ({
  handleOnReceiveMessage: (message: string) =>
    onReceiveMessageHandler({
      message,
      wizardStatus,
      setIsWebsocketActive,
      setFinalFeedback,
      setWizardStatus,
    }),
  handleOnConnect: () => onConnectHandler({ caseId, webSocketSendMessage }),
});

export { Step6WebsocketHandlers };
