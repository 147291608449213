// Contexts
import { PersonalPhotoContextConsumerHook } from 'containers/personal/views/photo/contexts/personal-photo.context';
// Events handlers
import PersonalPhotoAvatarsHandlers from '../handlers/personal-photo-avatars.handlers';
import PersonalPhotoAvatarsTrackingHandlers from '../handlers/personal-photo-avatars.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalPhotoAvatarsHookType } from './types/personal-photo-avatars.hook.type';

const usePersonalPhotoAvatarsHook = (): PersonalPhotoAvatarsHookType => {
  const { avatar, setAvatar, setPhoto } = PersonalPhotoContextConsumerHook();

  return {
    ...PersonalPhotoAvatarsHandlers({
      ...AppTrackingHook(PersonalPhotoAvatarsTrackingHandlers),
      setAvatar,
      setPhoto,
    }),
    avatar,
  };
};

export default usePersonalPhotoAvatarsHook;
