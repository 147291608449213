// Vendors
import React from 'react';
// Components
import { ParagraphComponent, LoaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import { IvrLoaderComponentCcStyled } from './ivr-loader.component.styled.cc';
// Translations
import { WAIT_IN_IVR_DESCRIPTION } from './translations/ivr-loader.translations.cc';

const IvrLoaderComponentCc = (): React.ReactElement => {
  return (
    <IvrLoaderComponentCcStyled>
      <LoaderComponent type="primary" />
      <ParagraphComponent>
        <FormattedMessageComponent id={WAIT_IN_IVR_DESCRIPTION} />
      </ParagraphComponent>
    </IvrLoaderComponentCcStyled>
  );
};

export { IvrLoaderComponentCc };
