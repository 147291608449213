// Contexts
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Types
import { FinancingPaymentMethodFirstStepRevolvingReturnHookType } from './types/financing-payment-method-first-step-revolving-return.hook.type';

const FinancingPaymentMethodFirstStepRevolvingHook =
  (): FinancingPaymentMethodFirstStepRevolvingReturnHookType => {
    const { paymentMethod } = FinancingPaymentMethodContextConsumerHook();

    return {
      paymentMethod,
    };
  };

export default FinancingPaymentMethodFirstStepRevolvingHook;
