// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS } from 'constants/wizard/wizard.constants';
// Hooks
import CancelContractChangeStep2ActionsHook from './hook/support-cancel-contract-second-step-actions.hook';
// Translations
import { CANCEL_CONTRACT } from './translations/support-cancel-contract-second-step.translations';

const SupportCancelContractSecondStepActionsComponent = (): React.ReactElement => {
  const { handleFinishActionsEvent, isDisabledLastStep } = CancelContractChangeStep2ActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS}
      rightButtonConfiguration={{
        children: <FormattedMessageComponent id={CANCEL_CONTRACT} />,
        disabled: isDisabledLastStep,
        onClick: handleFinishActionsEvent,
      }}
    />
  );
};

export default SupportCancelContractSecondStepActionsComponent;
