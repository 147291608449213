// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/personal-email.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalEmailContextType } from './types/personal-email.context.type';
import { PersonalEmailTypesEnumeration } from 'containers/personal/views/email/enumerations/personal-email-types.enumeration';

const PersonalEmailContext = createContext<PersonalEmailContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const PersonalEmailContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalEmailContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [challenged, setChallenged] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');
  const [newEmail, setNewEmail] = useState<string>('');
  const [repeatEmail, setRepeatEmail] = useState<string>('');
  const [showModal, setShowModal] = useState<PersonalEmailTypesEnumeration | null>(null);
  const [signatureExpired, setSignatureExpired] = useState<boolean>(false);

  return (
    <PersonalEmailContext.Provider
      value={{
        challenged,
        fetching,
        error,
        newEmail,
        progressId,
        signatureExpired,
        repeatEmail,
        showModal,
        setChallenged,
        setFetching,
        setError,
        setNewEmail,
        setProgressId,
        setRepeatEmail,
        setShowModal,
        setSignatureExpired
      }}
    >
      {children as React.ReactNode}
    </PersonalEmailContext.Provider>
  );
};

export const PersonalEmailContextConsumerHook = (): PersonalEmailContextType =>
  useContext(PersonalEmailContext);

/* eslint-disable */
export const PersonalEmailContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalEmailContextType>> =>
    (
      <PersonalEmailContextProvider>
        <Component {...props} />
      </PersonalEmailContextProvider>
    );
