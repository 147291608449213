// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { PathsCommonEnum } from '@openbank/cf-ui-static-data';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const FINANCING_ANY_ROUTE_PROPS = {
    path: PathsCommonEnum.ANY,
  },
  LANDING_ROUTE = generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES])
  );

export { FINANCING_ANY_ROUTE_PROPS, LANDING_ROUTE };
