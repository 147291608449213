// Enumerations
import { Check3dMessageTypesEnumeration } from '../enumerations/check-3d-message-types.enumeration';
// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_PROPS = {
    testId: 'card-settings-check-3d-confirmation-message',
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TITLE_PROPS = {
    bold: true,
    testId: 'card-settings-check-3d-confirmation-message-title',
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'card-settings-check-3d-confirmation-message-description',
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TYPES = {
    [Check3dMessageTypesEnumeration.WARNING]: messageConstants.TYPES.WARNING,
    [Check3dMessageTypesEnumeration.REMEMBER]: messageConstants.TYPES.INFORMATIVE,
  };

export {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TITLE_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TYPES,
};
