// Enumerations
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';

const CARD_SETTINGS_LANDING_QUICK_ACTIONS_PROPS = {
    id: QuickActionsSectionEnumeration.SETTINGS,
  },
  CARD_SETTINGS_SECTION_DIVIDER_PROPS = {
    titleBold: false,
    type: 'informative',
  };

export { CARD_SETTINGS_LANDING_QUICK_ACTIONS_PROPS, CARD_SETTINGS_SECTION_DIVIDER_PROPS };
