// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const RecoveryKeyStep4CreditCardComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
RecoveryKeyStep4CreditCardComponentStyled.displayName = 'RecoveryKeyStep4CreditCardComponentStyled';

const RecoveryKeyStep4CreditCardPanComponentStyled = styled.section.attrs(() => ({
  'data-testid': 'recovery-key-step-4-credit-card-pan-component'
}))`
  width: ${convertPxsToRems({ pixels: 276, base: 14 })};

  ${mobileMediaQuery} {
    width: 100%;
  }
`;
RecoveryKeyStep4CreditCardPanComponentStyled.displayName = 'RecoveryKeyStep4CreditCardPanComponentStyled';

export { RecoveryKeyStep4CreditCardComponentStyled, RecoveryKeyStep4CreditCardPanComponentStyled };