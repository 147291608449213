// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

const MODAL_INDEX = 4,
  SESSION_EXPIRED_MODAL_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.PNG,
    id: 'session-expired-es_optimized',
    sizes: {
      height: 180,
      width: 180,
    },
    type: assetConstants.TYPES.IMAGE,
    testId: 'session-expired-asset',
  },
  SESSION_EXPIRED_MODAL_DESCRIPTION_PROPS = {
    testId: 'session-expired-description',
  },
  SESSION_EXPIRED_MODAL_BUTTON_PROPS = {
    testId: 'session-expired-button',
  },
  SESSION_EXPIRED_MODAL_LAYOUT_PROPS = {
    align: 'center',
  };

export {
  MODAL_INDEX,
  SESSION_EXPIRED_MODAL_ASSET_PROPS,
  SESSION_EXPIRED_MODAL_BUTTON_PROPS,
  SESSION_EXPIRED_MODAL_DESCRIPTION_PROPS,
  SESSION_EXPIRED_MODAL_LAYOUT_PROPS,
};
