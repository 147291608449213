// Configurations
import {
  getLinkTypeTrackingConfiguration,
  getViewTypeTrackingConfiguration,
} from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import constants from './constants/step-3-credit-limit-increase.tracking.constants';
// Types
import { Step3CreditIncreaseLimitTrackingHandlersType } from './types/step-3-credit-limit-increase.tracking.type';

export const Step3CreditLimitChageTracking = (
  track: (param: unknown) => void
): Step3CreditIncreaseLimitTrackingHandlersType => ({
  handleEnterViewTracking: () => track(getViewTypeTrackingConfiguration(constants.VIEW)),
  handleClickUploadDocuemtationTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.CLICK_UPLOAD_DOCUMENTATION)),
});

export default Step3CreditLimitChageTracking;
