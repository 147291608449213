// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  WIZARD_STEP_FOOTER_CANCEL_MODAL_TITLE,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_01_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_02_PROPS,
} from 'components/wizard-step-footer/components/cancel-modal/constants/wizard-step-footer-cancel-modal.constants';
// Styles
import { WizardStepFooterFeedbackCancelModalTextComponentStyled } from './wizard-step-footer-cancel-modal-content.component.styled';
// Translations
import * as translations from 'components/wizard-step-footer/components/cancel-modal/translations/wizard-step-footer-cancel-modal.translations';
// Types
import { WizardStepFooterCancelModalComponentType } from '../types/wizard-step-footer-cancel-modal.component.type';

const WizardStepFooterCancelModalContentComponent = ({
  cancelModalTranslations,
}: Pick<
  WizardStepFooterCancelModalComponentType,
  'cancelModalTranslations'
>): React.ReactElement => {
  const {
    description = translations.DESCRIPTION_01,
    valuesDescription,
    description2,
    title,
  } = cancelModalTranslations || {};

  return !title ? (
    <WizardStepFooterFeedbackCancelModalTextComponentStyled>
      <ParagraphComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_TITLE}>
        <FormattedMessageComponent id={translations.CANCEL_TITLE} />
      </ParagraphComponent>
    </WizardStepFooterFeedbackCancelModalTextComponentStyled>
  ) : (
    <>
      <ParagraphComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_01_PROPS}>
        <FormattedMessageComponent id={description} values={valuesDescription} />
      </ParagraphComponent>
      {description2 && (
        <ParagraphComponent {...WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_02_PROPS}>
          <FormattedMessageComponent id={description2} />
        </ParagraphComponent>
      )}
    </>
  );
};

export { WizardStepFooterCancelModalContentComponent };
