// Constants
import { LAST_API_CALL, TOKEN_REFRESHED } from 'constants/storage/storage.constants';
import { TWO_MINUTES_IN_MS } from './constants/inactivity-refresh.handlers.utils.constants';
// Enumerations
import { StorageLocalEnum } from '@openbank/cf-ui-static-data';

const getDateWhenRefreshTokenExpired = (): Date =>
  new Date(String(localStorage.getItem(StorageLocalEnum.SESSION_EXPIRES_AT)));

const isLeftOneOrLessThanOneMinuteToDie = (): boolean =>
  new Date().getTime() >= getDateWhenRefreshTokenExpired().getTime();

const getRefreshTokeAliveTimeStamp = (): null | string =>
  localStorage.getItem(StorageLocalEnum.SESSION_EXPIRES_AT);

const refreshTokenFlagExistsAndIsOnTimeToDie = (): boolean =>
  getRefreshTokeAliveTimeStamp() !== null && isLeftOneOrLessThanOneMinuteToDie();

const lastApiCallWasMadeLessThanTwoMinutesAgo = (): boolean =>
  new Date().getTime() - new Date(String(localStorage.getItem(LAST_API_CALL))).getTime() <=
  TWO_MINUTES_IN_MS;

const timeToShowRefreshModalButInsteadShouldBeRefreshed = (): boolean =>
  refreshTokenFlagExistsAndIsOnTimeToDie() && lastApiCallWasMadeLessThanTwoMinutesAgo();

const noExistsConditionsToRefreshSession = (): boolean =>
  getRefreshTokeAliveTimeStamp() === null ||
  localStorage.getItem(StorageLocalEnum.USERNAME) === null;

const refreshTokenFromApiClientIsDone = (): boolean =>
  localStorage.getItem(TOKEN_REFRESHED) === 'true';

export {
  getRefreshTokeAliveTimeStamp,
  noExistsConditionsToRefreshSession,
  refreshTokenFlagExistsAndIsOnTimeToDie,
  refreshTokenFromApiClientIsDone,
  timeToShowRefreshModalButInsteadShouldBeRefreshed,
};

// 1. Dejamos morir la session sin tocar el modal
// 2. Alargamos la vida del refresh token al menos una vez y dejamos morir
// 3. Matamos la session con el modal
// 4. Alargamos la vida del refresh pero haciendo que falle el refresh
// 5. Nos cargamos el refresh
// 6. Nos cargamos el token
// 7. Nos cargamos el username
// 8. Nos cargamos el localstorage
// 9. Probamos si se refresca si el usuario ha hecho refresh si hace menos de 2m el last api call
