// Enums
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';
import translations from '../translations/notifications-landing.translations';

const LABELS_LINK_CARD_TITLE_PROPS = {
  tag: 'h4',
  testId: 'notificationsLandingTitle',
};

const CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS = {
  titleBold: false,
  type: SectionDividerTypesEnumeration.INFORMATIVE,
};

const SETTINGS_LINK_PROPS = {
  testId: 'notificationsSettingsLink',
  assetConfiguration: {
    id: 'symbols_settings_filled',
  },
  disabled: false,
  iconOnLeft: true,
  label: translations.SETTINGS,
  underline: false,
};

const FILTERS_LINK_PROPS = {
  testId: 'notificationsFiltersLink',
  assetConfiguration: {
    id: 'filter',
  },
  iconOnLeft: true,
  label: translations.FILTERS,
  underline: false,
};

const ERROR_NOT_FOUNT_PROPS = {
  assetConfiguration: {
    id: 'Empty_No_Result',
    sizes: { height: 110, width: 110 },
    testId: 'notifications-landing-empty-icon',
    type: 'illustration',
  },
  testing: {
    descriptionId: 'notifications-landing-empty-description',
    titleId: 'notifications-landing-empty-title',
  },
};

const CHIPS_FILTERS_PROPS = {
  id: 'date-range',
  assetId: 'navigation_arrow',
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS,
  CHIPS_FILTERS_PROPS,
  ERROR_NOT_FOUNT_PROPS,
  FILTERS_LINK_PROPS,
  LABELS_LINK_CARD_TITLE_PROPS,
  SETTINGS_LINK_PROPS,
};
