// Vendors
import React from 'react';
// Components
import { ScriptBoxComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { PERSONAL_DATA_SCRIPTBOX_CC_PROPS } from './constants/profile-personal-data-message-cc.constants';
// Translations
import { DESCRIPTION } from './translations/profile-personal-data-message-cc.translations';
// Utils
import { isCC } from 'utils/app.utils';

export const ProfilePersonalDataMessageCCComponent = (): React.ReactElement | null => {
  return isCC() ? (
    <ScriptBoxComponent>
      <ParagraphComponent {...PERSONAL_DATA_SCRIPTBOX_CC_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </ScriptBoxComponent>
  ) : null;
};
