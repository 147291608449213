// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsChangePinContextType } from './types/card-settings-change-pin.context.type';

const CardSettingsChangePinContext = createContext<CardSettingsChangePinContextType>(
  {} as CardSettingsChangePinContextType
);

export const CardSettingsChangePinContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsChangePinContextType>> => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [newPin, setNewPin] = useState<string>('');
  const [progressId, setProgressId] = useState<string>('');
  const [repeatedPin, setRepeatedPin] = useState<string>('');
  const [shownCode, setShownCode] = useState<boolean>(false);
  const [solvedChallenged, setSolvedChallenged] = useState<boolean>(false);
  const [challengeError, setChallengeError] = useState<boolean>(false);

  return (
    <CardSettingsChangePinContext.Provider
      value={{
        challenged,
        challengeError,
        newPin,
        progressId,
        repeatedPin,
        setChallenged,
        setChallengeError,
        setNewPin,
        setProgressId,
        setRepeatedPin,
        setShownCode,
        setSolvedChallenged,
        shownCode,
        solvedChallenged,
      }}
    >
      {children as React.ReactNode}
    </CardSettingsChangePinContext.Provider>
  );
};

export const useCardSettingsChangePinContextConsumerHook = (): CardSettingsChangePinContextType =>
  useContext(CardSettingsChangePinContext);

/* eslint-disable */
export const CardSettingsChangePinContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<CardSettingsChangePinContextType>> =>
    (
      <CardSettingsChangePinContextProvider>
        <Component {...props} />
      </CardSettingsChangePinContextProvider>
    );
