// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent, ButtonComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { BUTTON_PROPS, MESSAGE_PROPS } from './constants/expired-signature-message.constants';
// Styled
import { ExpiredSignatureComponentStyled, ExpiredSignatureButtonComponentStyled } from './expired-signature-message.component.styled';
// Hooks
import { ExpiredSignatureHook } from './hooks/expired-signature-message.hook';
// Translations
import { DESCRIPTION, BUTTON } from './translations/expired-signature-message.translations';

const ExpiredSignatureComponent = (): React.ReactElement => {
  const { navigateToChangeSignatureHandler } = ExpiredSignatureHook();

  return (
    <MessageComponent {...{...MESSAGE_PROPS}}>
      <ExpiredSignatureComponentStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <ExpiredSignatureButtonComponentStyled>
          <ButtonComponent {...{
            ...BUTTON_PROPS,
            onClick: navigateToChangeSignatureHandler
          }}>
            <FormattedMessageComponent id={BUTTON} />
          </ButtonComponent>
        </ExpiredSignatureButtonComponentStyled>
      </ExpiredSignatureComponentStyled>
    </MessageComponent>
  );
};

export { ExpiredSignatureComponent };
