// Types
import { DashboardComponentType } from '../types/dashboard.component.type';
import { DashboardCardComponentType } from '../types/dashboard-card.component.type';
import { DashboardCardCreditLimitInfoType } from '../types/dashboard-credit-limit-info.type';

const getCreditLimitStatus = (
  information: DashboardComponentType | Record<string, any>
): DashboardCardCreditLimitInfoType => {
  return information?.cardsInfo?.cards[0].creditDetails.limitIncreaseInfo;
};

const getDefaultCardInfo = (
  information: DashboardComponentType | Record<string, any>
): DashboardCardComponentType => {
  return information?.cardsInfo?.cards[0];
};

export { getCreditLimitStatus, getDefaultCardInfo };