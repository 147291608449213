// Constants
import { buttonConstants, titleConstants } from '@openbank/cb-ui-commons';

const NOT_FOUND_VIDEO_PROPS = {
    autoPlay: true,
    loop: true,
    muted: true,
    preload: 'auto',
    height: '100%',
    width: '100%',
  },
  NOT_FOUND_VIDEO_SOURCE_PROPS = {
    src: window.ASSETS_SERVER_PATH + 'media/404_ok_8.mp4',
    type: 'video/mp4',
  },
  NOT_FOUND_TITLE_PROPS = {
    tag: titleConstants.TAGS.H2,
    testId: 'not-found-title',
  },
  NOT_FOUND_DESCRIPTION_PROPS = {
    bold: false,
    tag: titleConstants.TAGS.H6,
    testId: 'not-found-description',
  },
  NOT_FOUND_BUTTON_PROPS = {
    rwdFull: false,
    size: buttonConstants.SIZES.BIG,
    testId: 'not-found-button',
  };

export {
  NOT_FOUND_BUTTON_PROPS,
  NOT_FOUND_DESCRIPTION_PROPS,
  NOT_FOUND_TITLE_PROPS,
  NOT_FOUND_VIDEO_PROPS,
  NOT_FOUND_VIDEO_SOURCE_PROPS,
};
