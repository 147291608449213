// Vendors
import React from 'react';
// Components
import TableRowComponent from 'components/table-row/table-row.component';
import { CardSettingsStatementsTableRowAmountComponent } from './components/amount/card-settings-statements-table-row-amount.component';
import { CardSettingsStatementsTableRowDateComponent } from './components/date/card-settings-statements-table-row-date.component';
import { CardSettingsStatementsTableRowDescriptionComponent } from './components/description/card-settings-statements-table-row-description.component';
import { CardSettingsStatementsTableRowDetailsComponent } from './components/details/card-settings-statements-table-row-details.component';
import { CardSettingsStatementsTableRowTagComponent } from './components/tag/card-settings-statements-table-row-tag.component';
import { CardStatementTableRowDownloadComponent } from 'components/card-statement-download/card-statement-table-row-download.component';
// Styles
import {
  CardSettingsStatementsTableRowColumnLeftComponentStyled,
  CardSettingsStatementsTableRowColumnRightComponentStyled,
  CardSettingsStatementsTableRowComponentStyled,
  CardSettingsStatementsTableRowContentComponentStyled,
} from './card-settings-statements-table-row.component.styled';
// Types
import { CardSettingsStatementsTableRowComponentType } from './types/card-settings-statements-table-row.component.type';

const CardSettingsStatementsTableRowComponent = ({
  element,
}: CardSettingsStatementsTableRowComponentType): React.ReactElement => {
  const {
    documentId,
    version,
    statementDate,
    composeData: { currency, paymentMethod, totalSettlementAmount },
    statementStatus,
  } = element;

  return (
    <TableRowComponent
      {...{ detailsComponent: CardSettingsStatementsTableRowDetailsComponent, element }}
    >
      <CardSettingsStatementsTableRowComponentStyled>
        <CardSettingsStatementsTableRowDateComponent {...{ statementDate }} />
        <CardSettingsStatementsTableRowContentComponentStyled>
          <CardSettingsStatementsTableRowColumnLeftComponentStyled>
            <CardSettingsStatementsTableRowDescriptionComponent {...{ paymentMethod }} />
            <CardSettingsStatementsTableRowTagComponent {...{ statementStatus }} />
          </CardSettingsStatementsTableRowColumnLeftComponentStyled>
          <CardSettingsStatementsTableRowColumnRightComponentStyled>
            <CardSettingsStatementsTableRowAmountComponent
              {...{ currency, totalSettlementAmount }}
            />
            <CardStatementTableRowDownloadComponent {...{ documentId, version }} />
          </CardSettingsStatementsTableRowColumnRightComponentStyled>
        </CardSettingsStatementsTableRowContentComponentStyled>
      </CardSettingsStatementsTableRowComponentStyled>
    </TableRowComponent>
  );
};

export { CardSettingsStatementsTableRowComponent };
