const DOMAIN_PAYMENT_METHOD = 'cobranded.services.userSettings.privateArea.billingPaymentMethods.';

const FULL_PAYMENT = {
  TITLE: `${DOMAIN_PAYMENT_METHOD}fullPayment.title`,
  DESCRIPTION: `${DOMAIN_PAYMENT_METHOD}fullPayment.description`,
};

const RESOLVING_AMOUNT = {
  TITLE: `${DOMAIN_PAYMENT_METHOD}revolvingAmount.title`,
  DESCRIPTION: `${DOMAIN_PAYMENT_METHOD}revolvingAmount.description`,
};

const FIX_AMOUNT = {
  TITLE: `${DOMAIN_PAYMENT_METHOD}fixAmount.title`,
  DESCRIPTION: `${DOMAIN_PAYMENT_METHOD}fixAmount.description`,
};

const PERCENTAGE_AMOUNT = {
  TITLE: `${DOMAIN_PAYMENT_METHOD}percentageAmount.title`,
  DESCRIPTION: `${DOMAIN_PAYMENT_METHOD}percentageAmount.description`,
};

export { FIX_AMOUNT, FULL_PAYMENT, PERCENTAGE_AMOUNT, RESOLVING_AMOUNT };
