// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { SecurityModuleComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_CHECK_PIN_NEW_PIN_PROPS } from './constants/card-settings-change-pin-new-pin.constants';
// Hooks
import useCardSettingsChangePinNewPinHook from './hooks/card-settings-change-pin-new-pin.hook';
// Translations
import { INVALID_NEW_PIN } from './translations/card-settings-change-pin-new-pin.translations';
// Utilities
import { newPinComponentIsDisabled } from './utils/card-settings-change-pin-new-pin.utils';

const CardSettingsChangePinNewPinComponent = (): React.ReactElement => {
  const {
    challenged,
    error,
    handleSaveCardSettingsChangePinNewPinChange,
    handleCardSettingsChangePinNewPinClickTracking,
    newPin,
    shownCode,
    solvedChallenged,
  } = useCardSettingsChangePinNewPinHook();

  return (
    <SecurityModuleComponent
      {...CARD_SETTINGS_CHECK_PIN_NEW_PIN_PROPS}
      disabled={newPinComponentIsDisabled({ challenged, solvedChallenged })}
      errorMessage={error && <FormattedMessageComponent id={INVALID_NEW_PIN} />}
      onChange={handleSaveCardSettingsChangePinNewPinChange}
      onClick={handleCardSettingsChangePinNewPinClickTracking}
      value={newPin}
      visible={shownCode}
    />
  );
};

export default CardSettingsChangePinNewPinComponent;
