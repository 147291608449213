// Enumerations
import { CustomerTitlesEnumeration } from 'enumerations/customer-titles.enumeration';
// Services
import {
  changeUserTitleService,
  fetchTitleMiscellaneousTypesService,
} from '../services/profile-personal-name-title-dropdown.services';
// Types
import { ProfilePersonalNameTitleDropdownApiMiscellaneousItemType } from '../types/profile-personal-name-title-dropdown-api-miscellaneous-item.type';
import { ProfilePersonalNameTitleDropdownResolversType } from './types/profile-personal-name-title-dropdown.resolvers.type';
// Utilities
import { isMiscellaneousTitleValidConfiguration } from './utils/profile-personal-name-title-dropdown.resolvers.utils';

const changeUserTitleResolver = async (
  title: CustomerTitlesEnumeration
): Promise<[ProfilePersonalNameTitleDropdownResolversType] | [null, true]> => {
  const [response] = await changeUserTitleService(title);

  return response?.title ? [response.title] : [null, true];
};

const fetchTitleMiscellaneousTypesResolver = async (): Promise<
  [ProfilePersonalNameTitleDropdownApiMiscellaneousItemType[]] | [null, true]
> => {
  const [response, { status }] = await fetchTitleMiscellaneousTypesService();

  return isMiscellaneousTitleValidConfiguration(response[0])
    ? [response[0].elements]
    : [null, true];
};

export { changeUserTitleResolver, fetchTitleMiscellaneousTypesResolver };
