// Vendors
import styled from 'styled-components';

export const PersonalEmailStep3ChallengeScriptBoxStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.spacingXl};
`;

export const PersonalEmailStep3ScriptBoxViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;