// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Hooks
import useCardSettingsContractCurrentBillingMethodValueHook from '../hooks/card-settings-contract-current-billing-method-value.hook';
// Styles
import { TooltipContentComponentContainerStyled } from './tooltip-content-component.styled';
// Translations
import {
  TOOLTIP_MESSAGE_CONTENT,
  TOOLTIP_MESSAGE_TITLE,
} from '../translations/card-settings-contract-current-billing-method-value.translations';
// Types
import { TooltipContentComponentPropsType } from './types/tooltip-content-component.type';

const TooltipContentComponent = ({
  paymentMethodCode,
}: TooltipContentComponentPropsType): React.ReactElement => {
  const { formatMessage } = useCardSettingsContractCurrentBillingMethodValueHook();

  return (
    <TooltipContentComponentContainerStyled>
      <ParagraphComponent bold>
        {formatMessage({ id: TOOLTIP_MESSAGE_TITLE[paymentMethodCode] })}
      </ParagraphComponent>
      <ParagraphComponent>
        {formatMessage({ id: TOOLTIP_MESSAGE_CONTENT[paymentMethodCode] })}
      </ParagraphComponent>
    </TooltipContentComponentContainerStyled>
  );
};

export { TooltipContentComponent };
