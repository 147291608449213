// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_STATEMENTS_TABLE_ROW_LINK_PROPS } from './constants/card-statement-table-row-download.constants';
// Hooks
import { useCardStatementTableRowDownloadHook } from './hooks/card-statement-table-row-download.hook';
// Styles
import { CardStatementTableRowDownloadComponentStyled } from './card-statement-table-row-download.component.styled';
// Translations
import { DOWNLOAD_DOCUMENT } from './translations/card-statement-table-row-download.translations';
// Types
import { CardStatementTableRowDownloadComponentType } from './types/card-statement-table-row-download.component.type';

const CardStatementTableRowDownloadComponent = ({
  documentId,
  version,
}: CardStatementTableRowDownloadComponentType): React.ReactElement | null => {
  const { fetching, handleDownloadCardStatementDocumentLinkClick } =
    useCardStatementTableRowDownloadHook({ documentId, version });

  return documentId && version ? (
    <CardStatementTableRowDownloadComponentStyled>
      <LinkComponent
        {...CARD_STATEMENTS_TABLE_ROW_LINK_PROPS}
        disabled={fetching}
        onClick={handleDownloadCardStatementDocumentLinkClick}
      >
        <FormattedMessageComponent id={DOWNLOAD_DOCUMENT} />
      </LinkComponent>
    </CardStatementTableRowDownloadComponentStyled>
  ) : null;
};

export { CardStatementTableRowDownloadComponent };
