// Vendors
import React from 'react';
// Components
import { TransactionsEppStep1CreditMessageComponent } from '../credit-message/transactions-epp-step1-credit-message.component';
import { TransactionsEppStep1SimulationComponent } from '../simulation/transactions-epp-step1-simulation.component';
import CardStatusComponent from 'components/card-status/card-status.component';
// Types
import { TransactionsEppStep1HookType } from '../../hooks/types/transactions-epp-step1.hook.type';

const TransactionsEppStep1OrchestratorComponent = ({
  cardEnabled,
  isCreditInsufficient,
}: Pick<
  TransactionsEppStep1HookType,
  'cardEnabled' | 'isCreditInsufficient'
>): React.ReactElement => {
  if (!cardEnabled) {
    return <CardStatusComponent />;
  }

  if (isCreditInsufficient) {
    return <TransactionsEppStep1CreditMessageComponent />;
  }

  return <TransactionsEppStep1SimulationComponent />;
};

export { TransactionsEppStep1OrchestratorComponent };
