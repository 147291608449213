// Services
import { fetchLocalizationConfigurationService } from '../services/localization.service';
// Utils
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Types
import { LocalizationConfigurationType } from 'types/localization-configuration.type';

const fetchLocalizationConfigurationResolver = async (): Promise<
  [LocalizationConfigurationType | Record<string, any>] | [null, true]
> => {
  const [response, { status }] = await fetchLocalizationConfigurationService();

  return wasSuccessRequest(status) ? [response] : [null, true];
};

export { fetchLocalizationConfigurationResolver };
