// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import {
  IBAN_BILLING_CHANGE_ROUTE_PROPS,
  IBAN_BILLING_CYCLE_ROUTE_PROPS,
  IBAN_BILLING_LANDING_ROUTE_PROPS,
} from './constants/iban-billing-router.constants';
// Components
import { WorkInProgressComponent } from 'components/working-in-progress/work-in-progress.component';
// Containers
const IbanBillingLandingView = lazy(() => import('../../views/landing/iban-billing-landing.view'));
const ChangeIbanView = lazy(
  () => import('containers/iban-billings/views/change-iban/change-iban.view')
);

const IbanBillingRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...IBAN_BILLING_CYCLE_ROUTE_PROPS} element={<WorkInProgressComponent />} />
    <Route {...IBAN_BILLING_CHANGE_ROUTE_PROPS} element={<ChangeIbanView />} />
    <Route {...IBAN_BILLING_LANDING_ROUTE_PROPS} element={<IbanBillingLandingView />} />
  </Routes>
);

export default IbanBillingRouterComponent;
