// Vendors
import React from 'react';
// Contexts
import { usePersonalNumberStep3ContextConsumerHook } from 'containers/personal/views/number/components/step3/contexts/personal-number-step3.context';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { THIRD_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Utilities
import { modifyPersonalPhoneStep3NextButtonIsEnabled } from './utils/personal-number-step3-actions.utils';

const PersonalNumberStep3ActionsComponent = (): React.ReactElement => {
  const { confirmationCodeOption } = usePersonalNumberStep3ContextConsumerHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: !modifyPersonalPhoneStep3NextButtonIsEnabled(confirmationCodeOption),
      }}
      targets={THIRD_STEP_TARGETS}
    />
  );
};

export default PersonalNumberStep3ActionsComponent;
