// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent, TitleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Constants
import { MESSAGE_PROPS, PARAGRAPH_PROPS } from './constants/profile-consents-summary.constants';
// Utilities
import {
  getAcceptedConsentsValue,
  getAcceptedSubConsents,
  getRejectedConsentsValue,
  getRejectedSubConsents,
} from './utils/profile-consents-summary.utils';
// Translations
import {
  ACCEPTED_CONSENTS,
  INFORMATIVE_MESSAGE_DESCRIPTIONS,
  REJECTED_CONSENTS,
} from './translations/profile-consents-summary.translations';
// Styles
import {
  ProfileConsentsSummaryBodyWrapper,
  ProfileConsentsSummaryWrapper,
} from './profile-consents-summary.component.styled';
// Types
import { ProfileConsentsSummaryPropsType } from './types/profile-consents-summary-props.type';

const ProfileConsentsSummaryComponent = ({
  consents,
}: ProfileConsentsSummaryPropsType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <MessageComponent {...MESSAGE_PROPS}>
      <ProfileConsentsSummaryWrapper>
        {consents.map(({ id, subConsents }) => (
          <ProfileConsentsSummaryBodyWrapper key={id}>
            <ParagraphComponent>
              <FormattedMessageComponent id={INFORMATIVE_MESSAGE_DESCRIPTIONS[id]} />
            </ParagraphComponent>
            {getAcceptedSubConsents(subConsents).length ? (
              <TitleComponent {...PARAGRAPH_PROPS}>
                <FormattedMessageComponent
                  id={ACCEPTED_CONSENTS}
                  values={getAcceptedConsentsValue(subConsents, formatMessage)}
                />
              </TitleComponent>
            ) : null}
            {getRejectedSubConsents(subConsents).length ? (
              <TitleComponent {...PARAGRAPH_PROPS}>
                <FormattedMessageComponent
                  id={REJECTED_CONSENTS}
                  values={getRejectedConsentsValue(subConsents, formatMessage)}
                />
              </TitleComponent>
            ) : null}
          </ProfileConsentsSummaryBodyWrapper>
        ))}
      </ProfileConsentsSummaryWrapper>
    </MessageComponent>
  );
};

export default ProfileConsentsSummaryComponent;
