// Vendors
import React from 'react';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import {
  CARD_SETTINGS_CHANGE_PIN_BUTTON_PROPS,
  CARD_SETTINGS_CHANGE_PIN_CHALLENGE_PROPS,
} from './constants/card-settings-change-pin-challenge.constants';
// Hooks
import useCardSettingsChangePinChallengeHook from './hooks/card-settings-change-pin-challenge.hook';
// Translations
import { VALIDATE_PIN } from './translations/card-settings-change-pin-challenge.translations';
// Utilities
import { isValidNewPin } from '../../utils/card-settings-change-pin.utils';

const CardSettingsChangePinChallengeComponent = (): React.ReactElement | null => {
  const {
    cardId,
    challenged,
    challengeError,
    dateOfBirth,
    handleManageErrorChangePinCardEvent,
    handleManageSuccessChangePinCardEvent,
    handleRetryChallengeButtonClick,
    handleValidatePinButtonClick,
    newPin,
    repeatedPin,
  } = useCardSettingsChangePinChallengeHook();

  return challenged ? (
    <ChallengeWrapperComponent
      {...{ ...CARD_SETTINGS_CHANGE_PIN_CHALLENGE_PROPS }}
      baseServiceBuilder={{ pathParams: { cardId }, params: { pin: newPin } }}
      challengeError={challengeError}
      onError={handleManageErrorChangePinCardEvent}
      onRetry={handleRetryChallengeButtonClick}
      onSuccess={handleManageSuccessChangePinCardEvent}
    />
  ) : (
    <ButtonComponent
      {...CARD_SETTINGS_CHANGE_PIN_BUTTON_PROPS}
      disabled={!isValidNewPin({ dateOfBirth, newPin, repeatedPin })}
      onClick={handleValidatePinButtonClick}
    >
      <FormattedMessageComponent id={VALIDATE_PIN} />
    </ButtonComponent>
  );
};

export default CardSettingsChangePinChallengeComponent;
