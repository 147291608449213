// Services
import { fetchInsurancesInfoService } from '../services/insurances.services';
// Types
import { InsurancesInfoResponseType } from 'types/insurances/insurances-info-response.type';

const fetchInsurancesInfoResolver = async (
  productId?: string[]
): Promise<[InsurancesInfoResponseType] | [null, boolean]> => {
  const [response] = await fetchInsurancesInfoService(productId);

  return response?.insurances ? [response] : [null, true];
};

export { fetchInsurancesInfoResolver };
