// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { PathsCommonEnum } from '@openbank/cf-ui-static-data';

const LOGIN_ROUTE_PROPS = {
    path: RoutesEnumeration.LOGIN,
  },
  ANY_PROSPECT_ROUTE_PROPS = {
    path: PathsCommonEnum.ANY,
  },
  ANY_PROSPECT_NAVIGATE_ROUTE_PROPS = {
    replace: true,
    to: `/${RoutesEnumeration.LOGIN}`,
  },
  RECOVERY_KEY_ROUTE_PROPS = {
    path: RoutesEnumeration.RECOVERY_KEY,
  };

export {
  ANY_PROSPECT_ROUTE_PROPS,
  ANY_PROSPECT_NAVIGATE_ROUTE_PROPS,
  LOGIN_ROUTE_PROPS,
  RECOVERY_KEY_ROUTE_PROPS,
};
