// Enumerations
import {
  SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration,
  SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration,
} from '../components/second-step/enumerations/security-keys-signature-key-change-second-step.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.securityAndKeys.changeSignatureKey.';

export default {
  CHANGE_SIGNATURE_KEY_TITLE: `${DOMAIN}changeSignatureKeyTitle`,
  STEP_1: `${DOMAIN}Step1Title`,
  STEP_2: `${DOMAIN}Step2Title`,
  STEP_3: `${DOMAIN}Step3Title`,
  PASSWORD_RULE: {
    [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_CONTAINS_CUSTOMER_DATE]: `${DOMAIN}inputBirthdayRuleText`,
    [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_EQUALS_OLD_KEY]: `${DOMAIN}inputDifferentOldRuleText`,
    [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_IS_A_SEQUENCE]: `${DOMAIN}ascendingDescendingRuleText`,
    [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_REPEATS_NUMBER]: `${DOMAIN}sequenceRuleText`,
  },
  ERRORS: {
    [SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration.INFORMATION_ABOVE]: `${DOMAIN}informationAboveError`,
    [SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration.DONT_MATCH]: `${DOMAIN}DontMatchSignatureKeyError`,
  },
  PASSWORD_COMPONENT_TITLE_FIRST_STEP: `${DOMAIN}firstStep.title`,
  PASSWORD_COMPONENT_DESCRIPTION_FIRST_STEP: `${DOMAIN}firstStep.description`,
  PASSWORD_COMPONENT_TITLE_SECOND_STEP: `${DOMAIN}passwordComponentTitleSecondStep`,
  PASSWORD_COMPONENT_TITLE_SECOND_STEP_INPUT: `${DOMAIN}passwordComponentTitleSecondStepInput`,
  SHOW_PASSWORD_TEXT: `${DOMAIN}showPasswordLabel`,
  HIDE_PASSWORD_TEXT: `${DOMAIN}hidePasswordLabel`,
  NOTIFICATION_FIRST_LABEL: `${DOMAIN}headerFirstMessage`,
  NOTIFICATION_SECOND_LABEL: `${DOMAIN}headerSecondMessage`,
  RECOVERY_KEY_LABEL: `${DOMAIN}recoveryKeyLabel`,
  RECOVERY_KEY_LINK: `${DOMAIN}recoveryKeyLink`,
};
