// Vendors
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

export default {
  FINANCING_LANDING_ROW_DETAILS_TOTAL_INTEREST_PROPS: {
    testing: {
      labelId: 'financing-landing-row-details-total-interest-label',
      valueId: 'financing-landing-row-details-total-interest-value',
    },
    type: SectionDividerTypesEnumeration.INFORMATIVE,
  },
};
