// Vendors
import styled from 'styled-components';

const FinancingInsurancesContractingLPIAdditionalListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

const FinancingInsurancesContractingLPIAdditionalGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export {
  FinancingInsurancesContractingLPIAdditionalGroupStyled,
  FinancingInsurancesContractingLPIAdditionalListStyled,
};
