// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const ExpiredSignatureComponentStyled = styled.div`
  display: flex;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXs};
  }
`;

ExpiredSignatureComponentStyled.displayName = 'ExpiredSignatureComponentStyled';

const ExpiredSignatureButtonComponentStyled = styled.div`
  width: max-content;
`;

export { ExpiredSignatureComponentStyled, ExpiredSignatureButtonComponentStyled };