// Resolvers
import { fetchCardResumeCardArtResolver } from '../resolvers/card-resume.resolver';
// Types
import { CardDetailsHandlersType } from './types/card-resume-builder.handlers.type';
import { CardDetailsReturnHandlersType } from './types/card-resume-builder-return.handlers.type';

const loadCardArtEventHandler = async ({
  productTypeCode,
  setCardImage,
}: CardDetailsHandlersType): Promise<void> => {
  const [response] = await fetchCardResumeCardArtResolver(productTypeCode);

  response && setCardImage(response);
};

const CardResumeHandlers = (props: CardDetailsHandlersType): CardDetailsReturnHandlersType => ({
  handleLoadCardArtEvent: () => loadCardArtEventHandler(props),
});

export default CardResumeHandlers;
