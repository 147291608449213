// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/transactions-landing-row-details-view-installment.constants';
// Hooks
import TransactionsLandingRowDetailsViewInstallmentHook from './hooks/transactions-landing-row-details-view-installment.hook';
// Translations
import translations from './translations/transactions-landing-row-details-view-installment.translations';
// Types
import { CardTransactionType } from 'types/card-transaction.type';
// Utilities
import { showViewTransactionInstallmentPlan } from './utils/transactions-landing-row-details-view-installment.utils';

const TransactionsLandingRowDetailsViewInstallmentComponent = ({
  eppStatusCode,
  id,
}: Pick<CardTransactionType, 'eppStatusCode' | 'id'>): React.ReactElement | null => {
  const { handleViewInstallmentsPlanLinkClick } =
    TransactionsLandingRowDetailsViewInstallmentHook(id);

  return showViewTransactionInstallmentPlan(eppStatusCode) ? (
    <LinkComponent
      {...constants.TRANSACTIONS_LANDING_ROW_DETAILS_VIEW_INSTALLMENT_LINK_PROPS}
      onClick={handleViewInstallmentsPlanLinkClick}
    >
      <FormattedMessageComponent id={translations.VIEW_ORDER_ID} />
    </LinkComponent>
  ) : null;
};

export default TransactionsLandingRowDetailsViewInstallmentComponent;
