// Configurations
import { getLinkTypeTrackingConfiguration } from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import constants from './constants/next-step-error-credit-limit-increase.component.tracking.constants';
// Types
import { NextStepErrorCreditLimitIncreaseComponentTrackingType } from './types/next-step-error-credit-limit-increase.component.tracking.type';

export const NextSetepErrorCreditLimitChangeTracking = (
  track: (param: unknown) => void
): NextStepErrorCreditLimitIncreaseComponentTrackingType => ({
  handleEnterViewTracking: () => track(getLinkTypeTrackingConfiguration(constants.VIEW)),
  handleClickBackButtonTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.BACK_BUTTON)),
});

export default NextSetepErrorCreditLimitChangeTracking;
