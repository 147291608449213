// Constants
import constants from '../constants/verifying-details-credit-limit-increase.component.constants';
import { VerifyingDetailsCreditLimitIncreaseComponentHandlersPropsType } from './types/verifying-details-credit-limit-increase-props.component.handler.type';
// Types
import { VerifyingDetailsCreditLimitIncreaseComponentHandlerType } from './types/verifying-details-credit-limit-increase.component.handler.type';

const incrementStepHandler = ({
  currentStep,
  setCurrentStep,
}: VerifyingDetailsCreditLimitIncreaseComponentHandlersPropsType): void => {
  const { lastStep } = constants.PROGRESS_BAR_CONFIGURATION;
  const movingTimeInMiliseconds = 500;
  let incrementStep = 0;

  if (currentStep === 0) {
    incrementStep = (lastStep / 100) * 20;
  } else {
    incrementStep = Math.ceil((lastStep - currentStep) / (lastStep / 10));
  }

  setTimeout(() => {
    if (currentStep + incrementStep < (lastStep / 100) * 98) {
      setCurrentStep(currentStep + incrementStep);
    }
  }, movingTimeInMiliseconds);
};

const VerifyingDetailsCreditLimitIncreaseComponentHandlers = ({
  currentStep,
  setCurrentStep,
}: VerifyingDetailsCreditLimitIncreaseComponentHandlersPropsType): VerifyingDetailsCreditLimitIncreaseComponentHandlerType => ({
  handleIncrementStep: () => incrementStepHandler({ currentStep, setCurrentStep }),
});

export default VerifyingDetailsCreditLimitIncreaseComponentHandlers;
