// Vendors
import React from 'react';
// Components
import { ExtraInformationInputComponent } from './components/extra-information-input/extra-information-input-component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { RadioListComponent } from '@openbank/cb-ui-commons';
// Hooks
import CardDetailsWrapperHook from 'components/card-details/hooks/card-details-wrapper.hook';
import useCbIntl from 'hooks/useCbIntl';
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
// Styles
import {
  TransactionsReportFraudulentMovementFirstStepOptionsBoxContentStyled,
  TransactionsReportFraudulentMovementFirstStepOptionsComponentStyled,
  TransactionsReportFraudulentMovementFirstStepOptionsTitleStyled,
} from './transactions-report-fraudulent-movement-additional-info-options.component.styled';
// Utils
import {
  getCardId,
  getInfoOptions,
} from './utils/transactions-report-fraudulent-movement-additional-info-options.utils';

export const TransactionsReportFraudulentMovementAdditionalInfoOptionsComponent =
  (): React.ReactElement => {
    const { selectedOptions, setSelectedOptions } =
      TransactionsReportFraudulentMovementConsumerHook();

    const { formatMessage } = useCbIntl();
    const { cardNumber } = CardDetailsWrapperHook();

    const cardIdValue = getCardId(cardNumber);
    return (
      <TransactionsReportFraudulentMovementFirstStepOptionsComponentStyled>
        {getInfoOptions({ formatMessage, cardIdValue }).map(({ title, options, fieldName }) => {
          return (
            <div key={fieldName}>
              <TransactionsReportFraudulentMovementFirstStepOptionsTitleStyled>
                <FormattedMessageComponent id={title} />
              </TransactionsReportFraudulentMovementFirstStepOptionsTitleStyled>
              <TransactionsReportFraudulentMovementFirstStepOptionsBoxContentStyled>
                <RadioListComponent
                  options={options}
                  value={selectedOptions[fieldName]}
                  selected={selectedOptions[fieldName]}
                  onChange={value => {
                    setSelectedOptions({ ...selectedOptions, [fieldName]: value });
                  }}
                ></RadioListComponent>
              </TransactionsReportFraudulentMovementFirstStepOptionsBoxContentStyled>
            </div>
          );
        })}
        <ExtraInformationInputComponent />
      </TransactionsReportFraudulentMovementFirstStepOptionsComponentStyled>
    );
  };
