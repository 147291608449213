// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const NotificationsSettingsSidebarComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export const NotificationsSettingsWrapperSidebarComponentStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
`;

export const NotificationsSettingsSidebarProductDetailWrapperStyled = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacingXxs};
`;
