// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from '../constants/card-resume.constants';
// Translations
import { CARD_TITLE } from '../translations/card-resume.translations';
// Types
import { GetCarResumeConfigurationReturnType } from './types/get-card-resume-configuration-return.type';
import { GetCardResumeConfigurationType } from './types/get-card-resume-configuration.type';

export const getCardResumeConfiguration = ({
  cardImage,
  cardNumber,
  cardType,
  large,
}: GetCardResumeConfigurationType): GetCarResumeConfigurationReturnType => ({
  ...constants.CARD_RESUME_COMPONENT_PROPS,
  cardImage,
  cardNumber,
  cardType,
  title: <FormattedMessageComponent id={CARD_TITLE} />,
});
