// Vendors
import React from 'react';
// Components
import { CreditChangeInfoCard } from 'containers/financing/components/credit-change-info-card/credit-change-info-card.component';
import { CreditLimitDecreaseCCChallengeComponent } from './components/challenge/credit-limit-decrease-cc-challenge.component';
import { ParagraphComponent, ScriptBoxComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { MAXIMUM_FRACTION_DIGITS } from '../../constants/credit-limit-decrease-cc.constants';
import { WIZARD_STEP_FOOTER_PROPS } from './constants/step-2-credit-limit-decrease-cc.constants';
// Hooks
import { Step2CreditLimitDecreaseCCHook } from './hooks/step-2-credit-limit-decrease-cc.hook';
// Translations
import {
  CONFIRM_BUTTON,
  CONTACT_CENTER_TEXT_1,
  CONTACT_CENTER_TEXT_2,
  SUBTITLE,
} from './translations/step-2-credit-limit-decrease-cc.translations';
// Styles
import { Step2CreditLimitDecreaseCCStyled } from './step-2-credit-limit-decrease-cc.component.styled';
// Utils
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

const Step2CreditLimitDecreaseCCComponent = (): React.ReactElement => {
  const {
    cardContractId,
    currency,
    currentCreditLimit,
    challenge,
    challengeError,
    newCreditLimit,
    isConfirmLoading,
    userName,
    formatMessage,
    setChallenge,
    setChallengeError,
    handleDecreaseLimit,
  } = Step2CreditLimitDecreaseCCHook();

  return (
    <Step2CreditLimitDecreaseCCStyled>
      <ParagraphComponent>{formatMessage({ id: SUBTITLE })}</ParagraphComponent>
      <ScriptBoxComponent>
        <ParagraphComponent>
          {formatMessage(
            { id: CONTACT_CENTER_TEXT_1 },
            { customerName: userName, b: makeChunkBoldIntlUtil }
          )}
        </ParagraphComponent>
      </ScriptBoxComponent>
      <CreditChangeInfoCard
        {...{
          currency,
          currentCreditLimit,
          newCreditLimit,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }}
      />
      <ScriptBoxComponent>
        <ParagraphComponent>{formatMessage({ id: CONTACT_CENTER_TEXT_2 })}</ParagraphComponent>
      </ScriptBoxComponent>
      <CreditLimitDecreaseCCChallengeComponent
        {...{ challengeError, setChallenge, setChallengeError }}
        baseServiceBuilder={{
          pathParams: { cardContractId },
          params: { limit: newCreditLimit },
        }}
      />
      <WizardStepFooterComponent
        {...WIZARD_STEP_FOOTER_PROPS}
        rightButtonConfiguration={{
          loading: isConfirmLoading,
          disabled: !challenge?.progressId || challengeError || isConfirmLoading,
          onClick: handleDecreaseLimit,
          children: formatMessage({ id: CONFIRM_BUTTON }),
        }}
      />
    </Step2CreditLimitDecreaseCCStyled>
  );
};

export { Step2CreditLimitDecreaseCCComponent };
