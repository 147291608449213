// Types
import {
  CardSettingsChange3DStep1NewCodeBuilderHandlersType,
  CardSettingsChange3DStep1NewCodeBuilderReturnHandlersType,
  CardSettingsChange3DStep1NewCodeSaveBuilderHandlersType,
  CardSettingsChange3DStep1NewCodeValidateBuilderHandlersType,
} from './types/card-settings-change-3d-step1-new-code.handlers.type';
// Utilities
import {
  code3DSecureMatchesConstraints,
  hasMoreThanThreeNumbers,
} from 'containers/card-settings/views/change-3d/components/step1/utils/card-settings-change-3d-step1.utils';

const saveNew3DCodeInputChangeEventHandler = ({
  newCode,
  setError,
  setNew3DCode,
  setIsRepeated3DCodeEnabled,
}: CardSettingsChange3DStep1NewCodeSaveBuilderHandlersType): void => {
  setNew3DCode(newCode);
  setError(false);
  setIsRepeated3DCodeEnabled(hasMoreThanThreeNumbers(newCode));
};

const validateNew3DCodeInputChangeEventHandler = ({
  dateOfBirth,
  new3DCode,
  handleChange3DNewCodeInputErrorTracking,
  setError,
  setIsRepeated3DCodeEnabled,
}: CardSettingsChange3DStep1NewCodeValidateBuilderHandlersType): void => {
  if (new3DCode && !code3DSecureMatchesConstraints({ dateOfBirth, new3DCode })) {
    handleChange3DNewCodeInputErrorTracking();
    setError(true);
    setIsRepeated3DCodeEnabled(false);
  } else {
    setError(false);
    setIsRepeated3DCodeEnabled(true);
  }
};

const CardSettingsChange3dStep1NewCodeHandlers = ({
  dateOfBirth,
  new3DCode,
  handleChange3DNewCodeInputErrorTracking,
  setError,
  setNew3DCode,
  setIsRepeated3DCodeEnabled,
}: CardSettingsChange3DStep1NewCodeBuilderHandlersType): CardSettingsChange3DStep1NewCodeBuilderReturnHandlersType => ({
  handleSaveNew3DCodeInputChangeEvent: (newCode: string) =>
    saveNew3DCodeInputChangeEventHandler({
      newCode,
      setError,
      setNew3DCode,
      setIsRepeated3DCodeEnabled,
    }),
  handleValidateNew3DCodeInputChangeEvent: () =>
    validateNew3DCodeInputChangeEventHandler({
      dateOfBirth,
      new3DCode,
      handleChange3DNewCodeInputErrorTracking,
      setError,
      setIsRepeated3DCodeEnabled,
    }),
});

export { CardSettingsChange3dStep1NewCodeHandlers };
