// Types
import { AddNewParam } from 'containers/transactions/views/landing/services/types/transactions-landing-service.type';
import { SetParamsType } from './types/set-params.type';
// Utils
import { hasTransactionsChallengeSuccess } from 'organisms/table-organism/components/transactions-table/utils/transactions-table.utils';

const addNewParam: AddNewParam = (param, value, SERVICE_CONFIGURATION): void => {
  SERVICE_CONFIGURATION.queryParams = {
    ...SERVICE_CONFIGURATION.queryParams,
    [param]: value,
  };
};

const setParams = ({ metadata, serviceConfiguration }: SetParamsType): void => {
  const cursor = metadata?.lastNextCursor || metadata?.previousCursor;

  if (cursor) {
    addNewParam('cursor', cursor, serviceConfiguration);
  }
  if (metadata?.includeAllRecords || hasTransactionsChallengeSuccess()) {
    addNewParam('includeAllRecords', true, serviceConfiguration);
  }
};

export { setParams };
