// Vendors
import React from 'react';
import geography from './vendors/world.json';
// Components
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import SettingsCardControlLocationsMapLegendComponent from './components/legend/settings-card-control-locations-map-legend.component';
// Constants
import constants from './constants/setting-card-control-locations-map.constants';
// Configurations
import { getMapConfigurations } from './configurations/setting-card-control-locations-map.configurations';
// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Styles
import { SettingsCardControlLocationsMapComponentStyled } from './settings-card-control-locations-map.component.styled';
// Utils
import { getGeographyStyles } from './utils/setting-card-control-locations-map.utils';

const SettingsCardControlLocationsMapComponent = (): React.ReactElement | null => {
  const { continents, mainCountry } = SettingsCardControlLocationsContextConsumerHook(),
    { mainCountryId, mainCountryName } = mainCountry || {};

  return (
    <SettingsCardControlLocationsMapComponentStyled
      data-testId={constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_GEOGRAPHY_PROPS.testId}
    >
      <ComposableMap {...getMapConfigurations()}>
        <Geographies disableOptimization geography={geography}>
          {({ geographies, projection }) =>
            geographies.map((geography: any) => (
              <Geography
                {...{ geography, projection }}
                key={geography.id}
                style={getGeographyStyles({
                  id: geography.id,
                  continents,
                  mainCountryId,
                  mainCountryName,
                })}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
      <SettingsCardControlLocationsMapLegendComponent />
    </SettingsCardControlLocationsMapComponentStyled>
  );
};

export default SettingsCardControlLocationsMapComponent;
