// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useRecoveryKeyContextConsumerHook } from 'containers/recovery-key/contexts/recovery-key.context';
// Event handlers
import RecoveryKeyStep1EmailHandlers from '../handlers/recovery-key-email.handlers';
import RecoveryKeyStep1EmailTrackingHandlers from '../handlers/recovery-key-email.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RecoveryKeyStep1EmailHookType } from './types/recovery-key-email.hook.type';

const useRecoveryKeyEmail = (): RecoveryKeyStep1EmailHookType => {
  const [error, setError] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();

  const { email, setEmail, fetching } = useRecoveryKeyContextConsumerHook();

  const {
    handleRecoveryKeyStep1EmailErrorInputEventTracking,
    handleRecoveryKeyStep1EmailInputClickTracking,
  } = AppTrackingHook(RecoveryKeyStep1EmailTrackingHandlers);

  return {
    ...RecoveryKeyStep1EmailHandlers({
      handleRecoveryKeyStep1EmailErrorInputEventTracking,
      email,
      setEmail,
      setError,
    }),
    email: email ?? '',
    fetching,
    formatMessage,
    error,
    handleRecoveryKeyStep1EmailInputClickTracking,
  };
};

export { useRecoveryKeyEmail };
