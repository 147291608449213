// Vendors
import React from 'react';
// Components
import {
  LineSeparatorComponent,
  ParagraphComponent,
  RowToggleComponent,
} from '@openbank/cb-ui-commons';
import { SettingsRowToggleInputCurrencyComponent } from './components/settings-row-toggle-input-currency/settings-row-toggle-input-currency.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  ROW_TOGGLE_TEXT_PROPS_TEST_ID,
  SETTINGS_ROW_TOGGLE_TEST_ID,
} from './constants/settings-row-toggle.constants';
// Configurations
import { getSettingsRowToggleConfiguration } from './configurations/settings-row-toggle.component.configurations';
// Styles
import {
  SettingsRowToggleComponentStyled,
  SettingsRowToggleWrapperStyled,
  SettingsRowToggleParragraphStyled,
} from './settings-row-toggle.component.styled';
// Translations
import { NOTIFICATION_SETTING } from '../../translations/notifications-settings-category.translations';
// Types
import { SettingsRowToggleComponentPropsType } from './types/settings-row-toggle.component.type';
// Utilities
import { hasControllers } from '../../utils/notifications-settings-category.component.utils';
import { formatTitle } from './components/settings-row-toggle-input-currency/utils/settings-row-toggle-input-currency.utils';

const SettingsRowToggleComponent = ({
  categoryId,
  controllerId,
  formatMessage,
  formatNumber,
  handleControllerChange,
  handleToggleNotification,
  hasLineSeparator,
  notificationSetting,
  testing = SETTINGS_ROW_TOGGLE_TEST_ID,
}: SettingsRowToggleComponentPropsType): React.ReactElement<any> => (
  <SettingsRowToggleComponentStyled {...{ ...testing }}>
    <SettingsRowToggleWrapperStyled>
      <RowToggleComponent
        key={notificationSetting.id}
        title={formatTitle({ formatMessage, notificationSetting })}
        toggleConfiguration={{
          ...getSettingsRowToggleConfiguration({
            notificationSetting,
            categoryId,
            formatMessage,
            handleToggleNotification,
          }),
        }}
      >
        {!hasControllers({ notificationSetting }) ? (
          <SettingsRowToggleParragraphStyled>
            <ParagraphComponent testId={ROW_TOGGLE_TEXT_PROPS_TEST_ID + notificationSetting.id}>
              <FormattedMessageComponent
                id={NOTIFICATION_SETTING + notificationSetting.description}
              />
            </ParagraphComponent>
          </SettingsRowToggleParragraphStyled>
        ) : (
          <SettingsRowToggleInputCurrencyComponent
            {...{
              categoryId,
              controllerId,
              formatMessage,
              formatNumber,
              handleControllerChange,
              notificationSetting,
            }}
          />
        )}
      </RowToggleComponent>
    </SettingsRowToggleWrapperStyled>
    {hasLineSeparator && <LineSeparatorComponent isAlternative={true} />}
  </SettingsRowToggleComponentStyled>
);

export { SettingsRowToggleComponent };
