// Vendors
import React from 'react';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportNotReceivedConfirmationActionsComponent } from './components/actions/transactions-report-not-received-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/transactions-report-not-received-confirmation.constants';
// Hooks
import { useTransactionsReportNotReceivedConfirmationHook } from './hooks/transactions-report-not-received-confirmation-component.hook';
// Styles
import { TransactionsReportNotReceivedConfirmationComponentStyled } from './transactions-report-not-received-confirmation.component.styled';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-not-received-confirmation.translations';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const TransactionsReportNotReceivedConfirmationComponent = (): React.ReactElement => {
  const { cardId, description, files, formatMessage, notReceivedDate, transactionId } =
    useTransactionsReportNotReceivedConfirmationHook();

  return (
    <TransactionsReportNotReceivedConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            notReceiveProductServicePurchased: {
              notReceivedDateReceptionProduct: notReceivedDate,
              notReceivedDescriptionProduct: description,
            },
            documents: getDocumentIds(files),
          },
        }}
      />
      <TransactionsReportNotReceivedConfirmationActionsComponent />
    </TransactionsReportNotReceivedConfirmationComponentStyled>
  );
};
