// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const OrderMobileModalWrapperStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

OrderMobileModalWrapperStyled.displayName = 'OrderMobileModalWrapperStyled';

const OrderModalTitleStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: flex;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  border-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  justify-content: space-between;
  padding: ${convertPxsToRems({ pixels: 8, base: 14 })} ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

OrderModalTitleStyled.displayName = 'OrderModalTitleStyled';

const InstallmentsDetailStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

InstallmentsDetailStyled.displayName = 'InstallmentsDetailStyled';

const InstallmentsStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyB};
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS};
`;

InstallmentsStyled.displayName = 'InstallmentsStyled';

const OrderListWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

OrderListWrapperStyled.displayName = 'OrderListWrapperStyled';

export {
  InstallmentsDetailStyled,
  InstallmentsStyled,
  OrderMobileModalWrapperStyled,
  OrderModalTitleStyled,
  OrderListWrapperStyled,
};
