// Vendors
import { FormatNumberOptions } from 'react-intl';
// Components
import { CardSettingsStatementsTableRowComponent } from 'containers/card-settings/views/statements/components/row/card-settings-statements-table-row.component';
// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';

const CARD_SETTINGS_STATEMENTS_TABLE_PROPS = {
  showFilters: false,
  rowComponent: CardSettingsStatementsTableRowComponent,
  id: { idName: 'eppId', groupId: 'statementDate', idTable: 'card-statements' },
};

const FORMATTED_VALUES_PROPS: FormatNumberOptions = {
  ...EURO_FORMAT_OPTIONS,
  signDisplay: 'exceptZero',
};

export { CARD_SETTINGS_STATEMENTS_TABLE_PROPS, FORMATTED_VALUES_PROPS };
