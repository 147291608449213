// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { LoginContextConsumerHook } from 'containers/login/contexts/login.context';
// Event handlers
import LoginEmailHandlers from '../handlers/login-email.handlers';
import LoginEmailTrackingHandlers from '../handlers/login-email.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoginEmailHookType } from './types/login-email.hook.type';

const useLoginEmail = (): LoginEmailHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const { formatMessage } = useCbIntl();
  const {
    autoLogin,
    email,
    rememberMe,
    error,
    setError,
    setAutoLogin,
    setEmail,
    setLoginView,
    setRememberMe,
    setUserAlias,
  } = LoginContextConsumerHook();

  const {
      handleContinuePasswordGapsButtonClickTracking,
      handleEmailInputClickTracking,
      handleEmailInputErrorStateTracking,
      handleEnterLoginEmailViewTracking,
      handleRememberMeChangeCheckboxTracking,
    } = AppTrackingHook(LoginEmailTrackingHandlers),
    {
      handleChangeRememberMeCheckBoxEvent,
      handleContinuePasswordGapsClick,
      handleLoginRememberedUserEvent,
      handleSaveLoginEmailInputEvent,
      handleValidateLoginEmailInputEvent,
    } = LoginEmailHandlers({
      autoLogin,
      email,
      handleContinuePasswordGapsButtonClickTracking,
      handleEmailInputErrorStateTracking,
      handleEnterLoginEmailViewTracking,
      handleRememberMeChangeCheckboxTracking,
      rememberMe,
      setAutoLogin,
      setEmail,
      setError,
      setFetching,
      setLoginView,
      setRememberMe,
      setUserAlias,
    });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleLoginRememberedUserEvent();
  }, []);

  return {
    fetching,
    formatMessage,
    email,
    error,
    handleChangeRememberMeCheckBoxEvent,
    handleContinuePasswordGapsClick,
    handleEmailInputClickTracking,
    handleSaveLoginEmailInputEvent,
    handleValidateLoginEmailInputEvent,
    rememberMe,
  };
};

export { useLoginEmail };
