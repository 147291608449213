// Translations
import {
  CARD_CONTROL,
  FAST_CASH,
  GET_CVV,
  GET_PIN,
  DEPOSIT_MONEY_EXTERNAL,
  MODIFY_PIN,
  PPI,
  STATEMENTS,
  UPDATE_PAYMENT_METHOD,
  ALL_OPTIONS,
} from './quick-actions.translations.common';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cbQuickActions.quickActions.';

const REQUEST_ADDITIONAL_CREDIT = `${DOMAIN}requestAdditionalCredit`;

export {
  CARD_CONTROL,
  FAST_CASH,
  GET_CVV,
  GET_PIN,
  DEPOSIT_MONEY_EXTERNAL,
  MODIFY_PIN,
  PPI,
  REQUEST_ADDITIONAL_CREDIT,
  STATEMENTS,
  UPDATE_PAYMENT_METHOD,
  ALL_OPTIONS,
};
