// Types
import { IsSelectedOptionInvalidType } from './types/is-selected-option-invalid.type';
import { HasDifferentValuesType } from './types/has-different-values.type';
// Utils
import {
  getNewPaymentMethod,
  isFixAmount,
  isFullPayment,
  isPercentageAmount,
} from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

const hasDifferentValues = ({
  accountBalancePercentage,
  fee,
  fixedPaymentAmount,
  newPaymentMethod,
  paymentMethod,
  paymentMethodCode,
  revolvingMethod,
}: HasDifferentValuesType): boolean =>
  newPaymentMethod !== paymentMethodCode ||
  (!isFullPayment(paymentMethod) &&
    ((isFixAmount(revolvingMethod) && fixedPaymentAmount !== fee) ||
      (isPercentageAmount(revolvingMethod) && accountBalancePercentage !== fee)));

const IsSelectedOptionInvalid = ({
  accountBalancePercentage,
  fee,
  fixedPaymentAmount,
  paymentMethod,
  paymentMethodCode,
  revolvingMethod,
}: IsSelectedOptionInvalidType): boolean => {
  const newPaymentMethod = getNewPaymentMethod({
    paymentMethod,
    revolvingMethod,
  });

  if (!newPaymentMethod) return true;

  if (
    hasDifferentValues({
      accountBalancePercentage,
      fee,
      fixedPaymentAmount,
      newPaymentMethod,
      paymentMethod,
      paymentMethodCode,
      revolvingMethod,
    })
  ) {
    return false;
  }

  return true;
};

export { hasDifferentValues, IsSelectedOptionInvalid };
