// Vendors
import { useEffect } from 'react';
// Contexts
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import LoginHandlers from '../handlers/login.handlers';
// Types
import { ManagerContextType } from 'contexts/types/manager.context.type';

const LoginHook = (): Pick<ManagerContextType, 'fetching'> => {
  const { fetching, setFetching } = ManagerContextConsumerHook();

  const { handleHideLoginAppLoaderEvent } = LoginHandlers({ setFetching });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleHideLoginAppLoaderEvent();
  }, []);

  return { fetching };
};

export default LoginHook;
