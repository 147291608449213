// Types
import { TransactionsTableOrganismPropsReturnType } from 'organisms/table-organism/components/transactions-table/types/transactions-table-configurations.component.type';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const isDownloadEnabled = ({
  downloadButton,
}: TransactionsTableOrganismPropsReturnType): boolean => {
  if (!downloadButton?.enabled) {
    return false;
  }

  if (downloadButton?.featureflag) {
    return getFeatureFlagInstance().isEnabled(downloadButton.featureflag);
  }

  return true;
};

export { isDownloadEnabled };
