// Resolvers
import { TRANSACTIONS_EPP_STEP2_SERVICE_CONFIGURATION } from '../services/constants/transactions-epp-step2.service.constants';

const TRANSACTIONS_EPP_STEP_2_CHALLENGE_PROPS = {
    baseService: TRANSACTIONS_EPP_STEP2_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'post',
  },
  TRANSACTIONS_EPP_STEP_2_FOOTER_PROPS = {
    hasDefaultLeftButton: true,
    hasDefaultRightButton: false,
  };

export { TRANSACTIONS_EPP_STEP_2_CHALLENGE_PROPS, TRANSACTIONS_EPP_STEP_2_FOOTER_PROPS };
