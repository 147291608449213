// Constants
import constants from '../../challenge/services/constants/security-keys-signature-key-change-third-step-challenge.service.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';

export const getChallengeConfiguration = ({
  oldSignatureKey,
  newSignatureKey,
  handleChallengeError,
  handleChallengeSuccess,
}: any): ChallengeWrapperComponentType => ({
  baseService: constants.COMPLETE_SIGNATURE_KEY_CHANGE_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: { params: { newSignatureKey, oldSignatureKey } },
  baseServiceMethod: constants.COMPLETE_SIGNATURE_KEY_CHANGE_OTP_SERVICE_CONFIGURATION.method,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
