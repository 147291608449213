// Translations
import translations from 'containers/card-settings/views/card-control/components/sidebar/translations/settings-card-control-sidebar.translations';

export default {
  SETTINGS_CARD_CONTROL_SIDEBAR_PROPS: {
    items: [
      {
        description: translations.EXPANDABLE_DESCRIPTION_1,
        label: translations.EXPANDABLE_TITLE_1,
        testId: 'settings-card-control-sidebar-expandable-card-table-row-description-1',
        testing: {
          rowId: 'settings-card-control-sidebar-expandable-card-1',
          titleId: 'settings-card-control-sidebar-expandable-title-section-1',
        },
      },
      {
        description: translations.EXPANDABLE_DESCRIPTION_2,
        label: translations.EXPANDABLE_TITLE_2,
        testId: 'settings-card-control-sidebar-expandable-card-table-row-description-2',
        testing: {
          rowId: 'settings-card-control-sidebar-expandable-card-2',
          titleId: 'settings-card-control-sidebar-expandable-title-section-2',
        },
      },
      {
        description: translations.EXPANDABLE_DESCRIPTION_3,
        label: translations.EXPANDABLE_TITLE_3,
        testId: 'settings-card-control-sidebar-expandable-card-table-row-description-3',
        testing: {
          rowId: 'settings-card-control-sidebar-expandable-card-3',
          titleId: 'settings-card-control-sidebar-expandable-title-section-3',
        },
      },
    ],
    testId: 'settings-card-control',
    title: translations.TITLE,
  },
};
