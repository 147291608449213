const modalComponent = 'modal/updateYourDetails';
const creditLimitIncreaseLabel = 'credit-limit-increase';

const MODAL_TRACKING_CONSTANTS = {
  SHOW_MODAL: {
    action: 'open',
    format: 'modal',
    component: modalComponent,
    element: 'full',
    eventCategory: creditLimitIncreaseLabel,
    eventAction: 'updateYourDetails',
    eventLabel: 'open',
  },
  CLOSE_MODAL: {
    action: 'close',
    format: 'cross',
    component: modalComponent,
    element: 'close',
    eventCategory: creditLimitIncreaseLabel,
    eventAction: 'updateYourDetails',
    eventLabel: 'close',
  },
  LINK_MODAL: {
    action: 'click',
    format: 'link',
    component: 'exit-modal-creditLimitIncreaseLabel',
    element: 'noGoBackProcess',
    eventCategory: creditLimitIncreaseLabel,
    eventAction: 'exitProcess',
    eventLabel: 'modalLink',
  },
  BUTTON_MODAL: {
    action: 'exit',
    format: 'link',
    component: modalComponent,
    element: 'noGoBackToTheFinancing',
    eventCategory: creditLimitIncreaseLabel,
    eventAction: 'updateYourDetails',
    eventLabel: 'navigationNoGoBackToTheFinancingLink',
  },
} as const;

export { MODAL_TRACKING_CONSTANTS };
