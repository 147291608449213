// Enums
import { ActivityTypeDeclaredEnumeration } from '../enumerations/employment-status.enumeration';
// Types
import { EmploymentDataHandlersPropsType } from './types/employment-data-credit-limit-increase-props.component.handler.type';
import { EmploymentDataHandlersReturnType } from './types/employment-data-credit-limit-increase-return.component.handler.type';
import { OnChangeRadioButtonHandlerType } from './types/on-change-radio-button.handler.type';
import { UpdateStatusAndStep2HandlerType } from './types/update-status-step-2.handler.type';
import { SetEditModeHandlerType } from './types/set-edit-mode-handler.type';
// Utils
import { setEmploymentData } from 'containers/financing/views/credit-limit-increase/components/step2/utils/step-2-credit-limit-increase.utils';
import { hasActivityTypeDeclaredChangedAndisSalariedCivilServantOrSelftEmployed } from './utils/employment-data-credit-limit-increase.utils';

// eslint-disable-next-line complexity
const updateStatusAndStep2Handler = ({
  step2,
  setStep2,
}: UpdateStatusAndStep2HandlerType): void => {
  const { activityTypeDeclared, employments } = step2.personalData;
  const { netIncome, yearJoinedCompany, monthJoinedCompany } = employments?.[0] || {};
  const newIsSalariedCivilServantOrSelftEmployed =
    activityTypeDeclared === ActivityTypeDeclaredEnumeration.EMPLOYED ||
    activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED;
  let isEmploymentDataReady = Boolean(activityTypeDeclared) && Boolean(netIncome);

  if (newIsSalariedCivilServantOrSelftEmployed) {
    isEmploymentDataReady =
      isEmploymentDataReady && Boolean(monthJoinedCompany) && Boolean(yearJoinedCompany);
  }

  if (step2.isEmploymentDataReady !== isEmploymentDataReady) {
    setStep2?.({
      ...step2,
      isEmploymentDataReady,
    });
  }
};

const onChangeRadioButtonHandler = ({
  e,
  newEmploymentStatus,
  step2,
  setStep2,
  handleSelectEmploymentStatusTracking,
}: OnChangeRadioButtonHandlerType): void => {
  if (e.target.checked) {
    const newPersonalData = {
      ...step2.personalData,
      activityTypeDeclared: newEmploymentStatus,
    };
    if (
      hasActivityTypeDeclaredChangedAndisSalariedCivilServantOrSelftEmployed(
        step2,
        newEmploymentStatus
      )
    ) {
      if (newPersonalData.employments?.[0]?.monthJoinedCompany) {
        newPersonalData.employments[0].monthJoinedCompany = undefined;
      }
      if (newPersonalData.employments?.[0]?.yearJoinedCompany) {
        newPersonalData.employments[0].yearJoinedCompany = undefined;
      }
    }
    setStep2({
      ...step2,
      personalData: newPersonalData,
    });
    handleSelectEmploymentStatusTracking();
  }
};

const setEditModeOnHandler = ({ step2, setStep2 }: SetEditModeHandlerType): void => {
  setStep2({
    ...step2,
    isEmploymentDataEditModeOn: true,
  });
};

const EmploymentDataHandlers = ({
  step2,
  handleSelectEmploymentStatusTracking,
  setStep2,
}: EmploymentDataHandlersPropsType): EmploymentDataHandlersReturnType => ({
  handleUpdateStatusAndStep2: () =>
    updateStatusAndStep2Handler({
      step2,
      setStep2,
    }),
  handleOnChangeRadioButton: (
    e: React.ChangeEvent<HTMLInputElement>,
    newEmploymentStatus: ActivityTypeDeclaredEnumeration
  ) =>
    onChangeRadioButtonHandler({
      e,
      step2,
      setStep2,
      newEmploymentStatus,
      handleSelectEmploymentStatusTracking,
    }),
  handleOnChangeNetIncome: (value: string) => {
    setEmploymentData({ setStep2, step2, values: { netIncome: value } });
  },
  handleOnSetEditModeOn: () => {
    setEditModeOnHandler({ step2, setStep2 });
  },
});

export default EmploymentDataHandlers;
