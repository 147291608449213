// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameAliasInputBuilderReturnTrackingHandlersType } from './types/profile-personal-name-alias-input-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const submitNewAliasInputEventTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'saveNewAlias',
  },
  type: TrackingEventsEnum.LINK,
});

const errorStateChangeAliasInputEventTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'changeAliasInputError',
    eventLabel: 'changeAliasInput',
    errorCategory: 'changeAliasInputError',
    errorDescription: 'invalidAliasFormat',
  },
  type: TrackingEventsEnum.LINK,
});

const changeAliasInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'changeAlias',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameAliasInputTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameAliasInputBuilderReturnTrackingHandlersType => ({
  handleChangeAliasInputClickTracking: () => track(changeAliasInputClickTrackingHandler()),
  handleErrorStateChangeAliasInputEventTracking: () =>
    track(errorStateChangeAliasInputEventTrackingHandler()),
  handleSubmitNewAliasInputEventTracking: () => track(submitNewAliasInputEventTrackingHandler()),
});

export default ProfilePersonalNameAliasInputTrackingHandlers;
