// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useRequestSignatureKeyContextConsumerHook } from '../contexts/request-signature-key.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import RequestSignatureKeyHandlers from '../handlers/request-signature-key.handlers';
// Types
import { RequestsignatureKeyHookType } from './types/request-signature-key.hook.type';

const useRequestSignatureKeyHook = (): RequestsignatureKeyHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const { setIsAlreadyRequested, setErrorResponse } = useRequestSignatureKeyContextConsumerHook();
  const { profile } = AppContextConsumerHook(),
    address = profile?.addressDetails?.[0] || {};
  const { formatMessage } = useCbIntl();

  const { handleVerifySignatureKeyStatus } = RequestSignatureKeyHandlers({
    setErrorResponse,
    setFetching,
    setIsAlreadyRequested,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleVerifySignatureKeyStatus();
  }, []);

  return {
    address,
    fetching,
    formatMessage,
  };
};

export default useRequestSignatureKeyHook;
