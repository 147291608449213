// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  SUPPORT_LANDING_SIDEBAR_PROPS: {
    testId: 'support-landing',
  },
  SUPPORT_LANDING_SIDEBAR_MESSAGE_PROPS: {
    testId: 'support-landing-sidebar-message',
    size: paragraphConstants.SIZES.M,
  },
};
