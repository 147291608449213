// Enumerations
import { CategoryAssetIdEnumeration } from 'enumerations/category-asset-id.enumeration';

export default {
  [CategoryAssetIdEnumeration.ATM]: 'atms',
  [CategoryAssetIdEnumeration.BANKS]: 'transfers-bank-charges-loans',
  [CategoryAssetIdEnumeration.HOME]: 'home-colored',
  [CategoryAssetIdEnumeration.HEALTH]: 'education-health-beauty-wellness',
  [CategoryAssetIdEnumeration.INCOME]: 'income',
  [CategoryAssetIdEnumeration.INVESTMENTS]: 'investments-colored',
  [CategoryAssetIdEnumeration.INSURANCE]: 'insurance-colored',
  [CategoryAssetIdEnumeration.EXCLUSIVE]: 'exclusive-colored',
  [CategoryAssetIdEnumeration.PROFESSIONAL]: 'professional-colored',
  [CategoryAssetIdEnumeration.OTHERS]: 'other-colored',
  [CategoryAssetIdEnumeration.RESTAURANTS]: 'restaurants-colored',
  [CategoryAssetIdEnumeration.STORES]: 'stores-colored',
  [CategoryAssetIdEnumeration.TRAVELS]: 'leisure-travel',
  [CategoryAssetIdEnumeration.TRANSPORTS]: 'transport-automove',
};
