// Vendors
import styled from 'styled-components';
// Utilities
import {
  convertPxsToRems,
  desktopMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

export const WizardFeedbackHostHeaderComponentStyled = styled.div.attrs(
  ({ testId }: { testId?: string }) => ({ 'data-testid': testId })
)<{ color: string }>`
  align-items: flex-start;
  background: ${({ color, theme }) => theme.colors[color]};
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXs};
  padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};

  svg {
    width: ${convertPxsToRems({ pixels: 32, base: 14 })};
    height: ${convertPxsToRems({ pixels: 32, base: 14 })};
  }

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingS};
    padding: ${({ theme }) => theme.spaces.spacingM};

    svg {
      width: ${convertPxsToRems({ pixels: 48, base: 14 })};
      height: ${convertPxsToRems({ pixels: 48, base: 14 })};
    }
  }

  ${desktopMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingS};
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingXxxxl};

    svg {
      width: ${convertPxsToRems({ pixels: 48, base: 14 })};
      height: ${convertPxsToRems({ pixels: 48, base: 14 })};
    }
  }
`;

export const WizardFeedbackHostHeaderRightComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const WizardFeedbackHostHeaderTitleComponentStyled = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacingXxxs};

  ${tabletMediaQuery} {
    margin-top: ${({ theme }) => theme.spaces.spacingXxs};
  }

  ${desktopMediaQuery} {
    margin-top: ${({ theme }) => theme.spaces.spacingXxs};
  }
`;

export const WizardFeedbackHostFooterComponentStyled = styled.footer`
  padding: 0 0 ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingM};
  }

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS};
  }
`;
