const CANCEL_CONTRACT_DROPDOWN_PROPS = {
  isRequired: true,
  testing: {
    placeholderId: 'cancel-contract-dropdown-placeholder',
    optionsPlaceholderId: 'cancel-contract-dropdown-options-placeholder',
    labelId: 'cancel-contract-dropdown-label',
  },
};
const CANCEL_CONTRACT_PARAGRAPH_01_PROPS = {
  testId: 'cancel-contract-paragraph-01-step1',
};
const CANCEL_CONTRACT_PARAGRAPH_02_PROPS = {
  testId: 'cancel-contract-paragraph-02-step1',
};

export {
  CANCEL_CONTRACT_DROPDOWN_PROPS,
  CANCEL_CONTRACT_PARAGRAPH_01_PROPS,
  CANCEL_CONTRACT_PARAGRAPH_02_PROPS,
};
