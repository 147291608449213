// Vendors
import React from 'react';
// Components
import { TitleComponent } from '@openbank/cb-ui-commons';
import ProfilePersonalNameAliasComponent from './components/alias/profile-personal-name-alias.component';
import ProfilePersonalNameAvatarComponent from './components/avatar/profile-personal-name-avatar.component';
import ProfilePersonalNameHeaderTooltipComponent from './components/name-tooltip/profile-personal-name-header-tooltip.component';
import ProfilePersonalNameTitleComponent from './components/title/profile-personal-name-title.component';
// Constants
import constants from './constants/profile-personal-name.constants';
// Hooks
import ProfilePersonalNameHook from './hooks/profile-personal-name.hook';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import {
  ProfilePersonalNameComponentStyled,
  ProfilePersonalNameEditionComponentStyled,
  ProfilePersonalNameHeaderComponentStyled,
  ProfilePersonalNameLeftComponentStyled,
  ProfilePersonalNameRightComponentStyled,
} from './profile-personal-name.component.styled';
// Utils
import { getTranslatedTitle } from './utils/profile-personal-name.component.utils';
import { isCC } from 'utils/app.utils';

const ProfilePersonalNameComponent = (): React.ReactElement | null => {
  const { userName, title, formatMessage } = ProfilePersonalNameHook();

  return !isCC() ? (
    <AppCardSectionComponentStyled>
      <AppCardContentSectionComponentStyled>
        <ProfilePersonalNameComponentStyled>
          <ProfilePersonalNameLeftComponentStyled>
            <ProfilePersonalNameAvatarComponent />
          </ProfilePersonalNameLeftComponentStyled>
          <ProfilePersonalNameRightComponentStyled>
            <ProfilePersonalNameHeaderComponentStyled>
              <TitleComponent {...constants.PROFILE_PERSONAL_NAME_TITLE_PROPS}>
                {getTranslatedTitle({ formatMessage, title })}
                {userName}
                <ProfilePersonalNameHeaderTooltipComponent />
              </TitleComponent>
            </ProfilePersonalNameHeaderComponentStyled>
            <ProfilePersonalNameEditionComponentStyled>
              <ProfilePersonalNameAliasComponent />
              <ProfilePersonalNameTitleComponent />
            </ProfilePersonalNameEditionComponentStyled>
          </ProfilePersonalNameRightComponentStyled>
        </ProfilePersonalNameComponentStyled>
      </AppCardContentSectionComponentStyled>
    </AppCardSectionComponentStyled>
  ) : null;
};

export default ProfilePersonalNameComponent;
