// Vendors
import React from 'react';
// Components
import FiltersComponent from 'components/filters/filters-component';
import { TransactionsTableComponent } from './components/transactions-table/transactions-table.component';
// Types
import { TableOrganismComponentPropsType } from './types/table-organism.component.type';

const TableOrganismComponent = ({
  tableConfigurationProps,
  filterId,
}: TableOrganismComponentPropsType): React.ReactElement => {
  return (
    <FiltersComponent {...{ filterId }}>
      <TransactionsTableComponent {...tableConfigurationProps} />
    </FiltersComponent>
  );
};

export { TableOrganismComponent };
