// Constants
import constants from '../../constants/change-credit-limit.constants';
// Translations
import translations from 'containers/financing/views/credit-limit-increase/components/step1/translations/step-1-credit-limit-increase.translations';
// Types
import { CreditLimitValidationUtilsType } from './types/credit-limit-validation-utils.type';
import { CreditLimitMultipleOfValidationUtilsType } from './types/credit-limit-multiple-of-validation-utils.type';

const isNewCreditLimitSmallerOrEqualThanCurrent = ({
  newCreditLimit,
  currentLimit,
}: Pick<CreditLimitValidationUtilsType, 'newCreditLimit' | 'currentLimit'>): boolean => {
  return newCreditLimit <= currentLimit;
};

const isNewCreditLimitBiggerThanMax = ({
  newCreditLimit,
  maxAmount,
}: Pick<CreditLimitValidationUtilsType, 'newCreditLimit' | 'maxAmount'>): boolean => {
  return newCreditLimit > maxAmount;
};

const isNewCreditLimitSmallerThanMin = ({
  newCreditLimit,
  minAmount,
}: Pick<CreditLimitValidationUtilsType, 'newCreditLimit' | 'minAmount'>): boolean => {
  return newCreditLimit < minAmount;
};

const isNewCreditLimitMultipleOf = ({
  newCreditLimit,
  step,
}: CreditLimitMultipleOfValidationUtilsType): boolean => newCreditLimit % step === 0;

export const isNewCreditLimitValid = ({
  newCreditLimit,
  currentLimit,
  maxAmount,
  minAmount,
}: CreditLimitValidationUtilsType): string => {
  if (isNewCreditLimitSmallerOrEqualThanCurrent({ newCreditLimit, currentLimit })) {
    return translations.CREDIT_LIMIT_SHOULD_BE_BIGGER_THAN_CURRENT;
  }
  if (isNewCreditLimitBiggerThanMax({ newCreditLimit, maxAmount })) {
    return translations.CREDIT_LIMIT_SHOULD_NOT_BE_BIGGER;
  }
  if (isNewCreditLimitSmallerThanMin({ newCreditLimit, minAmount })) {
    return translations.CREDIT_LIMIT_SHOULD_BE_BIGGER;
  }
  if (!isNewCreditLimitMultipleOf({ newCreditLimit, step: constants.CREDIT_LIMIT_CONFIG.STEP })) {
    return translations.CREDIT_LIMIT_STEP_VALIDATION;
  }
  return '';
};
