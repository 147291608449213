// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_CONTRACT_SIDEBAR_PROPS,
  CARD_SETTINGS_CONTRACT_SIDEBAR_DESCRIPTION_PROPS,
} from 'containers/card-settings/views/contract/components/sidebar/constants/card-settings-contract-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from 'containers/card-settings/views/contract/components/sidebar/translations/card-settings-contract-sidebar.translations';

const CardSettingsContractSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...CARD_SETTINGS_CONTRACT_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <ParagraphComponent {...CARD_SETTINGS_CONTRACT_SIDEBAR_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default CardSettingsContractSidebarComponent;
