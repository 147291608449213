// Vendors
import { useState } from 'react';
// Contexts
import { useCardSettingsChange3DContextConsumerHook } from 'containers/card-settings/views/change-3d/contexts/card-settings-change-3d.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import { CardSettingsChange3dStep2Handlers } from '../handlers/card-settings-change-3d-step2.handlers';
import { CardSettingsChange3dStep2TrackingHandlers } from '../handlers/card-settings-change-3d-step2.tracking.handlers';
import { ChallengeRetryHandlers } from 'handlers/challenge/challenge-retry.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChange3dStep2HookType } from './types/card-settings-change-3d-step2.hook.type';

const useCardSettingsChange3DStep2Hook = (): CardSettingsChange3dStep2HookType => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');

  const { setResult } = useWizardFeedbackContextConsumerHook(),
    { new3DCode, setErrorDescription } = useCardSettingsChange3DContextConsumerHook();

  return {
    ...CardSettingsChange3dStep2Handlers({
      ...AppTrackingHook(CardSettingsChange3dStep2TrackingHandlers),
      new3DCode,
      progressId,
      setChallenged,
      setChallengeError,
      setErrorDescription,
      setFetching,
      setProgressId,
      setResult,
    }),
    ...ChallengeRetryHandlers({
      setChallengeError,
    }),
    challenged,
    challengeError,
    fetching,
    new3DCode,
  };
};

export { useCardSettingsChange3DStep2Hook };
