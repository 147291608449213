// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { DashboardLastSessionBuilderReturnTrackingHandlersType } from './types/dashboard-last-session-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const navigateToAccessAreaLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'lastAccess',
  },
  type: TrackingEventsEnum.LINK,
});

const DashboardLastSessionTrackingHandlers = (
  track: TrackBuilderType
): DashboardLastSessionBuilderReturnTrackingHandlersType => ({
  handleNavigateToAccessAreaLinkClickTracking: () =>
    track(navigateToAccessAreaLinkClickTrackingHandler()),
});

export default DashboardLastSessionTrackingHandlers;
