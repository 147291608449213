export enum RoutesCommonEnumeration {
  ACCESS_KEY = 'access-key',
  ACCESS_PORTABILITY = 'access-portability',
  ACTIVATE = 'activate',
  BENEFICIARY = 'beneficiary',
  BENEFITS = 'benefits',
  BLOCK = 'block',
  CARD_INFO = 'card-info',
  CARD_SETTINGS = 'card-settings',
  CARD_CONTROL = 'card-control',
  CATEGORIZER = 'categorizer',
  CHANGE_PASSWORD = 'change-password',
  CHANGE_PIN = 'change-pin',
  CHANGE_3D_SECURE = 'change-3d-secure',
  CHECK_3D_SECURE = 'check-3d-secure',
  CREATE_INSTALLMENTS_PLAN = 'create-installments-plan',
  CONTACT = 'contact',
  CONTRACT = 'contract',
  CONSENTS = 'consents',
  CVV = 'cvv',
  DEPOSIT_MONEY = 'deposit-money',
  DOCUMENTATION = 'documentation',
  DASHBOARD = 'dashboard',
  DEVICES = 'devices',
  FAQS = 'faqs',
  FAST_CASH = 'fast-cash',
  FINANCING = 'financing',
  EDIT_ADDRESS = 'edit-address',
  EDIT_PHONE_NUMBER = 'edit-phone-number',
  EDIT_PHOTO = 'edit-photo',
  EDIT_EMAIL = 'edit-email',
  HIRE_CARD = 'hire-card',
  IBAN_AND_BILLING = 'iban-and-billing',
  INSTALLMENTS_PLAN = 'installments-plan',
  INSURANCES = 'insurances',
  CREDIT_LIMIT_INCREASE = 'credit-limit-increase',
  LAST_STATEMENTS = 'last-statements',
  LOGIN = 'login',
  MODIFY_LIMIT = 'modify-limit',
  NOT_FOUND = 'not-found',
  PPI = 'ppi',
  PERSONAL_INFORMATION = 'personal-information',
  PACKAGES = 'packages',
  PAYMENT_METHOD = 'payment-method',
  PIN = 'pin',
  POINTS = 'points',
  OTP = 'otp',
  SECURITY = 'security',
  SECURE_ELECTRONIC_COMMERCE = 'secure-electronic-commerce',
  SIGNATURE_KEY = 'signature-key',
  STATEMENTS = 'statements',
  SUPPORT = 'support',
  TRANSACTIONS = 'transactions',
  ONBOARDING_LINK = 'stc/onboarding/state',
}
