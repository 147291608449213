// Vendors
import React from 'react';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
import { DeviceModalComponent } from 'containers/security-keys/views/devices/components/device-modal/device-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getButtonConfiguration } from './configurations/sign-out.configurations';
// Enums
import { DeviceModalEnum } from 'containers/security-keys/views/devices/components/device-modal/enumerations/device-modal.enumeration';
// Hooks
import { SignOutHook } from './hooks/sign-out.hook';
// Translations
import { SIGN_OUT } from './translations/sign-out.translations';
// Types
import { SignatureKeyDevicesDeviceType } from 'containers/security-keys/views/devices/types/signature-key-devices.device.type';

const SignOutComponent = ({
  device,
}: {
  device: SignatureKeyDevicesDeviceType;
}): React.ReactElement => {
  const { open, onCloseModal, onOpenModal, onSignOut, loading } = SignOutHook({ device });

  return (
    <>
      {open && (
        <DeviceModalComponent
          loading={loading}
          type={DeviceModalEnum.SIGN_OUT}
          onClose={onCloseModal}
          device={device}
          onAccept={onSignOut}
        />
      )}
      <ButtonComponent {...getButtonConfiguration(device.deviceUUID)} onClick={onOpenModal}>
        <FormattedMessageComponent id={SIGN_OUT} />
      </ButtonComponent>
    </>
  );
};

export { SignOutComponent };
