// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/financing-early-repayment.context.constants';
// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingEarlyRepaymentContextType } from './types/financing-early-repayment.context.type';
import { ChallengeType } from 'types/challenge.type';
import { EppCardType } from 'types/epp-card.type';

const FinancingEarlyRepaymentContext =
  createContext<FinancingEarlyRepaymentContextType>(CONTEXT_DEFAULT_VALUE);

export const FinancingEarlyRepaymentContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FinancingEarlyRepaymentContextType>> => {
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [eppDetails, setEppDetails] = useState<EppCardType | null>(null);
  const [error, setError] = useState<boolean>(false);

  return (
    <FinancingEarlyRepaymentContext.Provider
      value={{
        challenge,
        challengeError,
        eppDetails,
        error,
        setChallenge,
        setChallengeError,
        setEppDetails,
        setError,
      }}
    >
      {children as React.ReactNode}
    </FinancingEarlyRepaymentContext.Provider>
  );
};

export const FinancingEarlyRepaymentContextConsumerHook = (): FinancingEarlyRepaymentContextType =>
  useContext(FinancingEarlyRepaymentContext);

/* eslint-disable */
export const FinancingEarlyRepaymentContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingEarlyRepaymentContextType>> =>
    (
      <FinancingEarlyRepaymentContextProvider>
        <Component {...props} />
      </FinancingEarlyRepaymentContextProvider>
    );
