// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, ScriptBoxComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PERSONAL_EMAIL_STEP_3_CHALLENGE_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_3_CHALLENGE_MESSAGE_TITLE_PROPS,
  PERSONAL_EMAIL_STEP_3_MESSAGE_CHALLENGE_DESCRIPTION_PROPS,
} from './constants/personal-email-step3-challenge-script-box.constants.cc';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Styled
import {
  PersonalEmailStep3ScriptBoxViewStyled,
  PersonalEmailStep3ChallengeScriptBoxStyled,
} from './personal-email-step3-challenge-script-box.component.styled.cc';
// Translations
import {
  MESSAGE_DESCRIPTION,
  MESSAGE_TITLE,
} from './translations/personal-email-step3-challenge-script-box.translations.cc';
// Utils
import { isCC } from 'utils/app.utils';

const PersonalEmailStep3ChallengeScriptBoxComponent = (): React.ReactElement | null => {
  const { profile } = AppContextConsumerHook();

  return isCC() ? (
    <PersonalEmailStep3ChallengeScriptBoxStyled>
      <ScriptBoxComponent {...PERSONAL_EMAIL_STEP_3_CHALLENGE_MESSAGE_PROPS}>
        <PersonalEmailStep3ScriptBoxViewStyled>
          <ParagraphComponent {...PERSONAL_EMAIL_STEP_3_CHALLENGE_MESSAGE_TITLE_PROPS}>
            <FormattedMessageComponent
              id={MESSAGE_TITLE}
              values={{ surname: profile?.userDetails?.firstSurname }}
            />
          </ParagraphComponent>
          <ParagraphComponent {...PERSONAL_EMAIL_STEP_3_MESSAGE_CHALLENGE_DESCRIPTION_PROPS}>
            <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
          </ParagraphComponent>
        </PersonalEmailStep3ScriptBoxViewStyled>
      </ScriptBoxComponent>
    </PersonalEmailStep3ChallengeScriptBoxStyled>
  ) : null;
};

export { PersonalEmailStep3ChallengeScriptBoxComponent };
