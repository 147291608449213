// Vendors
import { useEffect } from 'react';
// Event Handlers
import ProfileConsentsStep1Handlers from '../handlers/profile-consents-step1.handlers';
import { ConsentsStep1TrackingHandlers } from '../handlers/profile-consents-step1.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import { ProfileConsentsContextConsumerHook } from 'containers/personal/views/consents/contexts/profile-consents.context';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ProfileConsentsStep1HookType } from './types/profile-consents-step1.hook.type';
import { AppContextConsumerHook } from 'contexts/app.context';

const ProfileConsentsStep1Hook = (): ProfileConsentsStep1HookType => {
  const { consents, initialConsents, setConsents } = ProfileConsentsContextConsumerHook();
  const { formatMessage } = useCbIntl();
  const tracking = AppTrackingHook(ConsentsStep1TrackingHandlers);
  const {
    localization: {
      config: { cfg_public_url_privacy_policy_web: privacyPolicyUrl },
    },
  } = AppContextConsumerHook();

  const { handleChangeSubconsent, handleToggleConsent } = ProfileConsentsStep1Handlers({
    ...tracking,
    setConsents,
  });

  useEffect(() => {
    tracking.handleConsentsStep1StepLoadedTracking();
  }, []);

  return {
    consents: consents?.consentTypes,
    formatMessage,
    handleChangeSubconsent,
    handleToggleConsent,
    privacyPolicyUrl,
    initialConsents: initialConsents?.consentTypes,
  };
};

export default ProfileConsentsStep1Hook;
