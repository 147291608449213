// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { SettingsCardControlLocationsSearchBuilderReturnTrackingHandlersType } from './types/settings-card-control-locations-search-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const searchCountriesInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'searchCountriesInputClickTracking',
  },
  type: TrackingEventsEnum.LINK,
});

const SettingsCardControlLocationsSearchTrackingHandlers = (
  track: TrackBuilderType
): SettingsCardControlLocationsSearchBuilderReturnTrackingHandlersType => ({
  handleSearchCountriesInputEventTracking: () => track(searchCountriesInputClickTrackingHandler()),
});

export default SettingsCardControlLocationsSearchTrackingHandlers;
