// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
// Handlers
import RequestPortabilityStep2Handlers from '../handlers/request-portability-step2.handlers';
// Types
import { RequestPortabilityStep2HookType } from './types/request-portability-step2.hook.type';

const RequestPortabilityStep2Hook = (): RequestPortabilityStep2HookType => {
  const { emailValue, setPortabilityParams, emailCheckboxValue } =
    AccessPortabilityContextConsumerHook(),
    { setCurrentStep } = useWizardContextConsumerHook();

  const { handleNextStepClickEvent } = RequestPortabilityStep2Handlers({
    emailCheckboxValue,
    emailValue,
    setCurrentStep,
    setPortabilityParams,
  });

  return {
    handleNextStepClickEvent,
  };
};

export default RequestPortabilityStep2Hook;
