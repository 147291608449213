// Contexts
import { ProfilePersonalNameTitleContextConsumerHook } from 'containers/personal/views/landing/components/name/components/title/contexts/profile-personal-name-title.context';
// Event handlers
import { ProfilePersonalNameTitleEmptyHandlers } from '../handlers/profile-personal-name-title-empty.handlers';
import { ProfilePersonalNameTitleEmptyTrackingHandlers } from '../handlers/profile-personal-name-title-empty.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameTitleEmptyBuilderReturnHandlersType } from '../handlers/types/profile-personal-name-title-empty-builder-return.handlers.type';

const ProfilePersonalNameTitleEmptyHook =
  (): ProfilePersonalNameTitleEmptyBuilderReturnHandlersType => {
    const { setEditTitle } = ProfilePersonalNameTitleContextConsumerHook();

    return ProfilePersonalNameTitleEmptyHandlers({
      ...AppTrackingHook(ProfilePersonalNameTitleEmptyTrackingHandlers),
      setEditTitle,
    });
  };

export { ProfilePersonalNameTitleEmptyHook };
