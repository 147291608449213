// Constants
import { PUBLIC_SITE_CHAT } from 'constants/storage/storage.constants';
// Types
import {
  PublicInteractionsHandlersBuilderType,
  PublicInteractionsHandlersMethodsHandlersType,
} from 'providers/public-interactions/handlers/types/public-interactions.handlers.type';
// Utilities
import { publicSiteIsDemandingChat } from './utils/public-interactions.handlers.utils';

const createFlagToRedirectToChat = (): void => {
  localStorage.setItem(PUBLIC_SITE_CHAT, String(true));
};

const checkAnyPublicSitePrivateAreaInteractionsHandler = async (
  props: PublicInteractionsHandlersBuilderType
): Promise<void> => {
  publicSiteIsDemandingChat(props) && createFlagToRedirectToChat();
};

const PublicInteractionsHandlers = (
  props: PublicInteractionsHandlersBuilderType
): PublicInteractionsHandlersMethodsHandlersType => ({
  handleCheckAnyPublicSitePrivateAreaInteractions: () =>
    checkAnyPublicSitePrivateAreaInteractionsHandler(props),
});

export { PublicInteractionsHandlers };
