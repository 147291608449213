// Vendors
import React from 'react';
// Components
import { RequestSignatureKeyConfirmationComponent } from '../confirmation/request-signature-key-confirmation.component';
import RequestSignatureKeyWizardComponent from '../wizard/request-signature-key-wizard.component';
// Contexts
import { useRequestSignatureKeyContextConsumerHook } from '../../contexts/request-signature-key.context';

const RequestSignatureKeyOrchestratorComponent = (): React.ReactElement => {
  const { isAlreadyRequested } = useRequestSignatureKeyContextConsumerHook();

  return isAlreadyRequested ? (
    <RequestSignatureKeyConfirmationComponent />
  ) : (
    <RequestSignatureKeyWizardComponent />
  );
};

export default RequestSignatureKeyOrchestratorComponent;
