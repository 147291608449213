// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent, ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/setting-card-control-locations-warning.constants';
// Hooks
import SettingCardControlLocationsWarningHook from './hooks/setting-card-control-locations-warning.hook';
// Styles
import {
  SettingCardControlLocationsTopWarningComponentStyled,
  SettingsCardControlLocationsWarningComponentStyled,
} from './settings-card-control-locations-warning.component.styled';
// Translations
import translations from './translations/setting-card-control-locations-warning.translations';
// Utils
import { locationsTabIsBlocked } from '../../utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsWarningComponent = (): React.ReactElement | null => {
  const { handleNavigateCardControlOperationsLinkClick, ...rest } =
    SettingCardControlLocationsWarningHook();

  return locationsTabIsBlocked(rest) ? (
    <MessageComponent {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_PROPS}>
      <SettingsCardControlLocationsWarningComponentStyled>
        <SettingCardControlLocationsTopWarningComponentStyled>
          <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.TITLE} />
          </ParagraphComponent>
          <ParagraphComponent
            {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_DESCRIPTION_PROPS}
          >
            <FormattedMessageComponent id={translations.DESCRIPTION} />
          </ParagraphComponent>
        </SettingCardControlLocationsTopWarningComponentStyled>
        <LinkComponent
          {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_LINK_PROPS}
          onClick={handleNavigateCardControlOperationsLinkClick}
        >
          <FormattedMessageComponent id={translations.LINK} />
        </LinkComponent>
      </SettingsCardControlLocationsWarningComponentStyled>
    </MessageComponent>
  ) : null;
};

export default SettingsCardControlLocationsWarningComponent;
