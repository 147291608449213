// Vendors
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import CardStatusHook from 'hooks/card-status.hook';
// Utils
import { isInactive } from './utils/card-settings-router-inactive-card-routes.utils';

const CardSettingsRouterInactiveCardRoutesComponent = (): React.ReactElement => {
  const { status } = CardStatusHook();
  return isInactive(status) ? (
    <Outlet />
  ) : (
    <Navigate to={`/${RoutesEnumeration.DASHBOARD}`} />
  );
};

export default CardSettingsRouterInactiveCardRoutesComponent;
