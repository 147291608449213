// Types
import { AccessPortabilityContextType } from 'containers/personal/views/access-portability/contexts/types/access-portability.context.type';
import { CardComponentType } from 'types/card.component.type';

const validateBeneficiary = (cards: CardComponentType[]): boolean => {
  return cards.some(card => card.isBeneficiary === true);
};

const isMovementsCheckboxDisabled = ({
  mainCardCheckbox,
  beneficiaryCardCheckbox,
}: Pick<AccessPortabilityContextType, 'beneficiaryCardCheckbox' | 'mainCardCheckbox'>): boolean => {
  return !(mainCardCheckbox || beneficiaryCardCheckbox);
};

export { validateBeneficiary, isMovementsCheckboxDisabled };
