// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalNumberStep3ContextType } from 'containers/personal/views/number/components/step3/contexts/types/personal-number-step3.context.type';
// Enumeration
import { ConfirmationOptionEnumeration } from './enumerations/confirmation-option.enumeration';

const PersonalNumberStep3Context = createContext<PersonalNumberStep3ContextType>(
  {} as PersonalNumberStep3ContextType
);

const PersonalNumberStep3ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalNumberStep3ContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [confirmationCodeOption, setConfirmationCodeOption] =
    useState<ConfirmationOptionEnumeration | null>(null);

  return (
    <PersonalNumberStep3Context.Provider
      value={{
        fetching,
        confirmationCodeOption,
        setConfirmationCodeOption,
      }}
    >
      {children as React.ReactNode}
    </PersonalNumberStep3Context.Provider>
  );
};

const usePersonalNumberStep3ContextConsumerHook = (): PersonalNumberStep3ContextType =>
  useContext(PersonalNumberStep3Context);

/* eslint-disable */
const PersonalNumberStep3ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalNumberStep3ContextType>> =>
    (
      <PersonalNumberStep3ContextProvider>
        <Component {...props} />
      </PersonalNumberStep3ContextProvider>
    );

export { PersonalNumberStep3ContextConsumerHOC, usePersonalNumberStep3ContextConsumerHook };
