// Vendors
import { Usd } from '@openbank/cf-ui-framework';
// Constants
import { ACCESS_KEY_CHANGE_FEEDBACK_PROPS } from 'containers/security-keys/views/access-key-change/constants/security-keys-access-key-change.constants.cc';
// Translations
import translations from 'containers/security-keys/views/access-key-change/translations/security-keys-access-key-change-card.translations.cc';

const SECURITY_KEYS_ACCESS_CHANGE_CONSTANTS = {
  haveConstraints: true,
  ivrFeedback: ACCESS_KEY_CHANGE_FEEDBACK_PROPS,
  initiatorIvr: Usd()?.changeKeyInIvr,
  resultIvr: Usd()?.getAccessKeyChanged,
  text: {
    firstConstraint: translations.CHANGE_ACCESS_KEY_CC_FIRST_CONSTRAINTS,
    firstText: translations.CHANGE_ACCESS_KEY_CC_FIRST_TEXT,
    fourthText: translations.CHANGE_ACCESS_KEY_CC_FOURTH_TEXT,
    initialText: translations.CHANGE_ACCESS_KEY_CC_INITIAL_TEXT,
    lastText: translations.CHANGE_ACCESS_KEY_CC_LAST_TEXT,
    secondConstraint: translations.CHANGE_ACCESS_KEY_CC_SECOND_CONSTRAINTS,
    secondText: translations.CHANGE_ACCESS_KEY_CC_SECOND_TEXT,
    thirdConstraint: translations.CHANGE_ACCESS_KEY_CC_THIRD_CONSTRAINTS,
    thirdText: translations.CHANGE_ACCESS_KEY_CC_THIRD_TEXT,
    title: translations.CHANGE_ACCESS_KEY_CC_TITLE,
  },
};

export default SECURITY_KEYS_ACCESS_CHANGE_CONSTANTS;
