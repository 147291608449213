// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsContractCurrentComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
`;

const CardSettingsContractCurrentTitleComponentStyled = styled.header`
  padding: 0
    calc(${({ theme }) => theme.spaces.spacingXxxxl} + ${({ theme }) => theme.spaces.spacingS});

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;

const CardSettingsContractCurrentMessageComponentStyled = styled.section`
  display: flex;
  flex-direction: row;
`;

export {
  CardSettingsContractCurrentComponentStyled,
  CardSettingsContractCurrentMessageComponentStyled,
  CardSettingsContractCurrentTitleComponentStyled,
};
