// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Events Handlers
import OrchestratorRouterContainerErrorHandlers from '../handlers/orchestrator-router-container-error.handlers';
import OrchestratorRouterContainerErrorTrackingHandlers from '../handlers/orchestrator-router-container-error.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { OrchestratorRouterContainerErrorHookType } from './types/orchestrator-router-container-error.hook.type';

const useOrchestratorRouterContainerErrorHook = (): OrchestratorRouterContainerErrorHookType => {
  const { formatMessage } = useCbIntl();

  const { error } = ManagerContextConsumerHook();

  return {
    ...OrchestratorRouterContainerErrorHandlers(
      AppTrackingHook(OrchestratorRouterContainerErrorTrackingHandlers)
    ),
    formatMessage,
    error,
  };
};

export default useOrchestratorRouterContainerErrorHook;
