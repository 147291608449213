// Contexts
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import FinancingPaymentMethodSecondStepActionsHandlers from '../handlers/financing-payment-method-second-step-actions.handlers';
// Hooks
import usePaymentMethodHook from 'containers/financing/views/payment-method/hooks/payment-method.hook';
// Types
import { FinancingPaymentMethodSecondStepActionsHookReturnType } from './types/financing-payment-method-second-step-actions.hook.type';

const FinancingPaymentMethodSecondStepActionsHook =
  (): FinancingPaymentMethodSecondStepActionsHookReturnType => {
    const { handleSelectCurrentMethod } = usePaymentMethodHook();

    const { currentCard } = AppContextConsumerHook(),
      { cardContractId: contractId } = currentCard || {},
      {
        challenge,
        paymentMethodParams,
        setChallenge,
        setChallengeError,
        setError,
        setPaymentMethodParams,
      } = FinancingPaymentMethodContextConsumerHook(),
      { setCard } = ManagerContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...FinancingPaymentMethodSecondStepActionsHandlers({
        challenge,
        contractId,
        paymentMethodParams,
        setCard,
        setChallenge,
        setChallengeError,
        setError,
        setPaymentMethodParams,
        setResult,
        handleSelectCurrentMethod,
      }),
      challenge,
    };
  };

export default FinancingPaymentMethodSecondStepActionsHook;
