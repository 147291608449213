// Vendors
import { useEffect, useState } from 'react';
// Event handlers
import { SessionHealthHandlers } from '../handlers/session-health.handlers';

const useSessionHealthHook = (): any => {
  const [fetching, setFetching] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const { handleCheckClientSessionHealthEvent } = SessionHealthHandlers({
    setError,
    setFetching,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleCheckClientSessionHealthEvent();
  }, []);

  return { fetching, error };
};

export { useSessionHealthHook };
