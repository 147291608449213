// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const FinancingLandingTableRowTagComponentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export const ParagraphComponentStyled = styled.div`
  ${mobileMediaQuery} {
    margin-right: ${({ theme }) => theme.spaces.spacingL};
  }
`;
