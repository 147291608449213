// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const AppNoUserDataHeaderContainerStyled = styled.header.attrs(() => ({
  'data-testid': 'app-no-user-data-header-container',
}))`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  display: flex;
  justify-content: space-between;
  min-height: ${convertPxsToRems({ pixels: 64, base: 14 })};
  padding: 0 ${({ theme }) => theme.spaces.spacingS} 0 ${({ theme }) => theme.spaces.spacingL};
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};

    button {
      width: fit-content;
    }
  }
`;
AppNoUserDataHeaderContainerStyled.displayName = 'AppNoUserDataHeaderContainerStyled';

const AppNoUserDataHeaderContainerMenuUserLogout = styled.div`
  align-items: center;
  display: flex;
  width: auto;

  ${mobileMediaQuery} {
    display: none;
    padding-left: 0;
    padding-right: 0;
  }
`;

export {
  AppNoUserDataHeaderContainerStyled,
  AppNoUserDataHeaderContainerMenuUserLogout
};
