// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TABLE_ROW_ASSET_DATE_ASSET_DESCRIPTION_PROPS,
  TABLE_ROW_ASSET_DATE_ASSET_PROPS,
} from './constants/table-row-asset-date.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import { TableRowAssetDateComponentStyled } from './table-row-asset-date.component.styled';
// Types
import { TableRowAssetDateParams } from './types/table-row-asset-date.component.type';
// Utils
import { getCategoryAsset } from 'utils/category.utils';
import { getUserFriendlyRelativeDate } from 'utils/dates/date.utils';

const TableRowAssetDateComponent = ({
  categoryCode,
  date,
}: TableRowAssetDateParams): React.ReactElement => {
  const { formatDate, formatMessage } = useCbIntl();

  return (
    <TableRowAssetDateComponentStyled>
      <AssetComponent {...TABLE_ROW_ASSET_DATE_ASSET_PROPS} id={getCategoryAsset(categoryCode)} />
      <ParagraphSecondaryComponentStyled>
        <ParagraphComponent {...TABLE_ROW_ASSET_DATE_ASSET_DESCRIPTION_PROPS}>
          {getUserFriendlyRelativeDate({
            date,
            formatDate,
            formatMessage,
            formatOpts: MiscellaneousDateConstants.FORMAT_DD_MM,
          })}
        </ParagraphComponent>
      </ParagraphSecondaryComponentStyled>
    </TableRowAssetDateComponentStyled>
  );
};

export default TableRowAssetDateComponent;
