// Vendors
import React from 'react';
// Components
import NotificationsRouterComponent from './components/router/notifications-router.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Styles
import {
  NotificationsContainerStyled,
  NotificationsContentContainerStyled,
} from './notifications.container.styled';
// Constants
import constants from './constants/notifications.constants';

const NotificationsContainer = (): React.ReactElement => (
  <NotificationsContainerStyled>
    <TitleSectionComponent {...constants.NOTIFICATIONS_TITLE_SECTION_PROPS} />
    <NotificationsContentContainerStyled>
      <NotificationsRouterComponent />
    </NotificationsContentContainerStyled>
  </NotificationsContainerStyled>
);

export default NotificationsContainer;
