// Types
import {
  FinancingPPIContractingStep1CoverageDescriptionLinkBuilderHandlersType,
  FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType,
} from 'containers/financing/views/ppi/components/contracting/components/description-link/components/link/handlers/types/financing-ppi-contracting-description-link-link.handlers.type';

const openExternalUrlPPIContractingLinkClickHandler = ({
  handleOpenExternalUrlPPIContractingLinkClickTracking,
  url,
}: FinancingPPIContractingStep1CoverageDescriptionLinkBuilderHandlersType): void => {
  if (url) {
    handleOpenExternalUrlPPIContractingLinkClickTracking(url);
    window.open(url);
  }
};

const FinancingPPIContractingDescriptionLinkLinkHandlers = (
  props: FinancingPPIContractingStep1CoverageDescriptionLinkBuilderHandlersType
): FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType => ({
  handleOpenExternalUrlPPIContractingLinkClick: () =>
    openExternalUrlPPIContractingLinkClickHandler(props),
});

export { FinancingPPIContractingDescriptionLinkLinkHandlers };
