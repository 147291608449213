// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const CreditChangeInfoCardSectionStyled = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  padding: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const CreditChangeInfoCardInfoStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 50%;
`;
