// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingDownloadComponent } from '../download/financing-ppi-contracting-download.component';
import { FinancingPPIContractingResumeComponent } from '../resume/financing-ppi-contracting-resume.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_3_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_LAYOUT_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_CHALLENGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_FOOTER_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_SERVICE_PROPS,
} from './constants/financing-ppi-contracting-step3.constants';
// Hooks
import { useFinancingPPIContractingStep3Hook } from './hooks/financing-ppi-contracting-step3.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { CONFIRM, DESCRIPTION } from './translations/financing-ppi-contracting-step3.translations';
// Utils
import { contractPPIStep3ButtonIsDisabled } from './utils/financing-ppi-contracting-step3.utils';

const FinancingPPIContractingStep3Component = (): React.ReactElement => {
  const {
    fetching,
    handleContractPPIChallengeErrorEvent: onError,
    handleContractPPIChallengeSuccessEvent: onSuccess,
    handleConfirmContractPPIButtonClick,
    postalDocumentation,
    productContractId,
    productContractType,
    ...rest
  } = useFinancingPPIContractingStep3Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_3_LAYOUT_CONTENT_PROPS}>
        <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_3_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <FinancingPPIContractingDownloadComponent
          {...FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_PROPS}
          serviceParams={{
            ...FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_SERVICE_PROPS,
            queryParams: { productContractId, productContractType },
          }}
        />
      </LayoutColumnFlexComponentStyled>
      <FinancingPPIContractingResumeComponent />
      <ChallengeWrapperComponent
        {...{ ...FINANCING_PPI_CONTRACTING_STEP_3_CHALLENGE_PROPS }}
        baseServiceBuilder={{
          params: { postalDocumentation, productContractId, productContractType },
        }}
        {...{ onError, onSuccess }}
      />
      <WizardStepFooterComponent
        {...FINANCING_PPI_CONTRACTING_STEP_3_FOOTER_PROPS}
        rightButtonConfiguration={{
          children: <FormattedMessageComponent id={CONFIRM} />,
          disabled: contractPPIStep3ButtonIsDisabled({ fetching, ...rest }),
          loading: fetching,
          onClick: handleConfirmContractPPIButtonClick,
        }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingPPIContractingStep3Component };
