// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SnackBar } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/toast.constants';
// Hooks
import ToastHook from './hooks/toast.hook';
// Utilities
import { toastHasConfigurationDefined } from './utils/toast.utils';

const AppToastComponent = (): React.ReactElement => {
  const { description, handleHideAppToastEvent, type } = ToastHook();

  return (
    <SnackBar
      {...({ ...constants.TOAST_PROPS, type } as any)}
      show={toastHasConfigurationDefined({ description, type })}
      onFinishTimer={handleHideAppToastEvent}
    >
      <ParagraphComponent {...constants.TOAST_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={description} />
      </ParagraphComponent>
    </SnackBar>
  );
};

export default AppToastComponent;
