// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import FinancingDepositMoneyHandlers from '../handlers/financing-deposit-money.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingDepositMoneyHookType } from './types/financing-deposit-money.hook.type';
import { DepositMoneySectionsType } from '../types/financing-deposit-money-sections.type';
import { FinancingDepositMoneyViewType } from '../types/financing-deposit-money.view.type';

const FinancingDepositMoneyHook = (): FinancingDepositMoneyHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [issueBank, setIssueBank] = useState<string>('');
  const [maxTransferLimit, setMaxTransferLimit] = useState<number>(0);
  const [sections, setSections] = useState<DepositMoneySectionsType[]>([]);
  const [virtualIban, setVirtualIban] = useState<string>('');

  const { currentCard } = AppContextConsumerHook(),
    { limit } = currentCard?.creditDetails || {},
    { id: cardId } = currentCard;
  const { formatMessage } = useCbIntl();
  const navigate = useNavigate();
  const { handleLoadDepositMoney } = FinancingDepositMoneyHandlers({
    cardId,
    setIssueBank,
    setMaxTransferLimit,
    setSections,
    setVirtualIban,
    setFetching,
    setError,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleLoadDepositMoney();
  }, []);

  return {
    fetching,
    navigate,
    formatMessage,
    error,
    issueBank,
    limit,
    maxTransferLimit,
    sections,
    virtualIban,
  };
};

export default FinancingDepositMoneyHook;
