// Services
import {
  changePaymentMethodService,
  getRevolvingOptionsService,
} from '../services/financing-payment-method.services';
// Types
import { ChallengeType } from 'types/challenge.type';
import { ChangePaymentMethodResolverType } from './types/change-payment-method-resolver.type';
import { ChangePaymentMethodResponseType } from '../services/types/change-payment-method-response.type';
import { RevolvingOptionsType } from '../types/revolving-options.type';
// Utilities
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';

const getRevolvingOptionsResolver = async (): Promise<
  [RevolvingOptionsType | null] | [RevolvingOptionsType | null, number]
> => {
  const [response, { status }] = await getRevolvingOptionsService();

  return requestHasSuccessStatus(status) ? [response] : [response, status];
};

const changePaymentMethodResolver = async (
  props: ChangePaymentMethodResolverType
): Promise<[null] | [ChangePaymentMethodResponseType | ChallengeType | null, number]> => {
  const [response, { status }] = await changePaymentMethodService(props);

  return requestHasSuccessStatus(status) ? [null] : [response, status];
};

export { changePaymentMethodResolver, getRevolvingOptionsResolver };
