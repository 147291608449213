// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/labels-link-card-change.constants';
// Styles
import { LabelsLinkCardChangeComponentStyled } from './labels-link-card-change.component.styled';
// Types
import { LabelsLinkCardComponentType } from 'components/labels-link-card/types/labels-link-card.component.type';

const LabelsLinkCardChangeComponent = ({
  changeConfiguration: { date, label } = constants.LABELS_LINK_CARD_CHANGE_DEFAULT_CONFIGURATION,
}: Pick<LabelsLinkCardComponentType, 'changeConfiguration'>): React.ReactElement | null =>
  label ? (
    <LabelsLinkCardChangeComponentStyled>
      <AssetComponent {...constants.LABELS_LINK_CARD_LAST_ASSET_PROPS} />
      <ParagraphComponent {...constants.LABELS_LINK_CARD_LAST_PROPS}>
        <FormattedMessageComponent id={label} values={{ date }} />
      </ParagraphComponent>
    </LabelsLinkCardChangeComponentStyled>
  ) : null;

export default LabelsLinkCardChangeComponent;
