// Types
import { LoginEmailUtilsType } from 'containers/login/views/email/utils/types/login-email.utils.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';

const continueLoginEmailButtonIsDisabled = ({
  fetching,
  email,
  error,
}: LoginEmailUtilsType): boolean => Boolean(fetching || error || !isValidEmail(email));

export { continueLoginEmailButtonIsDisabled };
