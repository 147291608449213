// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputTextComponent } from '@openbank/cb-ui-commons';
// Constants
import { RECOVERY_KEY_STEP1_EMAIL_PROPS } from './constants/recovery-key-email.constants';
// Hooks
import { useRecoveryKeyEmail } from './hooks/recovery-key-email.hook';
// Translations
import {
  LABEL,
  ERROR,
  HELPER_MESSAGE,
  PLACEHOLDER,
} from './translations/recovery-key-email.translations';

const RecoveryKeyEmailComponent = ({ label = LABEL }: { label?: string }): React.ReactElement => {
  const {
    fetching,
    formatMessage,
    email: value,
    error,
    handleBlurRecoveryKeyStep1EmailInputEvent: onBlur,
    handleRecoveryKeyStep1EmailInputClickTracking: onClick,
    handleSaveRecoveryKeyStep1EmailInputEvent: onChange,
  } = useRecoveryKeyEmail();

  return (
    <InputTextComponent
      {...{ ...RECOVERY_KEY_STEP1_EMAIL_PROPS, onBlur, onChange, onClick, value }}
      disabled={fetching}
      errorMessage={error && <FormattedMessageComponent id={ERROR} />}
      label={<FormattedMessageComponent id={label} />}
      placeholder={formatMessage({ id: PLACEHOLDER })}
      helperMessage={<FormattedMessageComponent id={HELPER_MESSAGE} />}
    />
  );
};

export { RecoveryKeyEmailComponent };
