// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const CARDS_SETTINGS_ROUTE_PROPS = {
    path: RoutesEnumeration.CARD_SETTINGS + '/*',
  },
  DASHBOARD_ROUTE_PROPS = {
    path: RoutesEnumeration.DASHBOARD + '/*',
  },
  DOCUMENTATION_ROUTE_PROPS = {
    path: RoutesEnumeration.DOCUMENTATION + '/*',
  },
  NOTIFICATIONS_ROUTE_PROPS = {
    path: RoutesEnumeration.NOTIFICATIONS + '/*',
  },
  FINANCING_ROUTE_PROPS = {
    path: RoutesEnumeration.FINANCING + '/*',
  },
  IBAN_BILLING_ROUTE_PROPS = {
    path: RoutesEnumeration.IBAN_AND_BILLING + '/*',
  },
  PACKAGE_ROUTE_PROPS = {
    path: RoutesEnumeration.PACKAGES + '/*',
  },
  POINTS_ROUTE_PROPS = {
    path: RoutesEnumeration.POINTS + '/*',
  },
  PERSONAL_ROUTE_PROPS = {
    path: RoutesEnumeration.PERSONAL_INFORMATION + '/*',
  },
  OTP_ROUTE_PROPS = {
    path: RoutesEnumeration.OTP,
  },
  SECURITY_KEYS_ROUTE_PROPS = {
    path: RoutesEnumeration.SECURITY + '/*',
  },
  SUPPORT_ROUTE_PROPS = {
    path: RoutesEnumeration.SUPPORT + '/*',
  },
  TRANSACTIONS_ROUTE_PROPS = {
    path: RoutesEnumeration.TRANSACTIONS + '/*',
  },
  ANY_ROUTE_PROPS = {
    path: '*',
  },
  ANY_NAVIGATE_ROUTE_PROPS = {
    replace: true,
    to: `/${RoutesEnumeration.NOT_FOUND}`,
  },
  LOWER_NAVIGATION_ID= 'lower-navigation';

export {
  ANY_ROUTE_PROPS,
  ANY_NAVIGATE_ROUTE_PROPS,
  CARDS_SETTINGS_ROUTE_PROPS,
  DASHBOARD_ROUTE_PROPS,
  DOCUMENTATION_ROUTE_PROPS,
  FINANCING_ROUTE_PROPS,
  IBAN_BILLING_ROUTE_PROPS,
  PACKAGE_ROUTE_PROPS,
  PERSONAL_ROUTE_PROPS,
  POINTS_ROUTE_PROPS,
  OTP_ROUTE_PROPS,
  SECURITY_KEYS_ROUTE_PROPS,
  SUPPORT_ROUTE_PROPS,
  TRANSACTIONS_ROUTE_PROPS,
  NOTIFICATIONS_ROUTE_PROPS,
  LOWER_NAVIGATION_ID,
};
