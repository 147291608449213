// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/transactions-landing-row-details-subcategory.constants';
// Styles
import { TransactionsLandingRowDetailsResumeLinkComponentStyled } from '../../transactions-landing-row-details.component.styled';
// Translations
import translations from './translations/transactions-landing-row-details-subcategory.translations';
// Types
import { CardTransactionType } from 'types/card-transaction.type';

const TransactionsLandingRowDetailsSubcategoryComponent = ({
  category,
}: Pick<CardTransactionType, 'category'>): React.ReactElement | null => (
  <TransactionsLandingRowDetailsResumeLinkComponentStyled>
    <ResumeItemComponent
      {...constants.TRANSACTIONS_LANDING_ROW_DETAILS_SUBCATEGORY_PROPS}
      label={<FormattedMessageComponent id={translations.SUBCATEGORY} />}
      value={
        <FormattedMessageComponent
          id={
            category?.subcategoryCode
              ? `${translations.SUBCATEGORY_VALUE}_${category.categoryCode}_${category.subcategoryCode}`
              : translations.TO_BE_ASSIGNED
          }
        />
      }
    />
  </TransactionsLandingRowDetailsResumeLinkComponentStyled>
);

export default TransactionsLandingRowDetailsSubcategoryComponent;
