// Constants
import checkCvvPinConstants from 'containers/card-settings/views/check-cvv-pin/constants/check-cvv-pin.constants';

const { CVV, PIN, TRANSLATIONS: { CVV_DOMAIN, PIN_DOMAIN } } = checkCvvPinConstants;
const SECTION = 'sidebar.';

export default {
  [CVV]: {
    TITLE: `${CVV_DOMAIN}${SECTION}title`,
    SIDEBAR_FIRST_TITLE: `${CVV_DOMAIN}${SECTION}WhatisTitle`,
    SIDEBAR_FIRST_DESC: `${CVV_DOMAIN}${SECTION}WhatIsDescription`,
    SIDEBAR_SECOND_TITLE: `${CVV_DOMAIN}${SECTION}WhereTitle`,
    SIDEBAR_SECOND_DESC: `${CVV_DOMAIN}${SECTION}WhereDescription`,
    SIDEBAR_THIRD_TITLE: `${CVV_DOMAIN}${SECTION}WhatDynamicTitle`,
    SIDEBAR_THIRD_DESC: `${CVV_DOMAIN}${SECTION}WhatDynamicDescription`,
  },
  [PIN]: {
    TITLE: `${PIN_DOMAIN}${SECTION}title`,
    SIDEBAR_FIRST_TITLE: `${PIN_DOMAIN}${SECTION}WhatisTitle`,
    SIDEBAR_FIRST_DESC: `${PIN_DOMAIN}${SECTION}WhatIsDescription`,
    SIDEBAR_SECOND_TITLE: `${PIN_DOMAIN}${SECTION}HowTitle`,
    SIDEBAR_SECOND_DESC: `${PIN_DOMAIN}${SECTION}HowDescription`,
    SIDEBAR_THIRD_TITLE: `${PIN_DOMAIN}${SECTION}ChangePinTitle`,
    SIDEBAR_THIRD_DESC: `${PIN_DOMAIN}${SECTION}ChangePinDescription`,
  }
};
