// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import { BUTTON, DESCRIPTION, TITLE } from '../translations/security-check.component.translations';

export const SECURITY_CHECK_PROPS = {
  assetConfiguration: {
    id: 'Attention_Alternative',
    sizes: { height: 110, width: 110 },
    type: 'illustration',
  },
  description: <FormattedMessageComponent id={DESCRIPTION} />,
  descriptionSize: paragraphConstants.SIZES.S,
  title: <FormattedMessageComponent id={TITLE} />,
  buttonConfiguration: {
    children: <FormattedMessageComponent id={BUTTON} />,
  },
};
