// Constants
import constants from './constants/login-gaps.handlers.utils.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const isLocalHostEnvironment = (): boolean =>
  new RegExp(constants.LOCALHOST_REGEX).test(window.location.hostname);

const getEnvironmentDomain = (): string =>
  isLocalHostEnvironment() ? window.location.hostname : constants.AMAZON_DOMAIN;

const getLoginRedirectUrl = (isOnboardingIncomplete: boolean): string =>
  isOnboardingIncomplete ? RoutesEnumeration.ONBOARDING_LINK : RoutesEnumeration.DASHBOARD;

const getRememberMeFlagMethod = (isRememberMe: boolean): string =>
  isRememberMe ? constants.METHODS.SETTER : constants.METHODS.REMOVE;

export { getEnvironmentDomain, getLoginRedirectUrl, getRememberMeFlagMethod };
