// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { CONFIRMATION_OPTION_CHANGE_PAYLOAD } from './constants/personal-number-step3-confirmation-option.handlers.tracking.constants';
// Types
import { PersonalNumberStep3ConfirmationOptionReturnHandlersTrackingType } from './types/personal-number-confirmation-option-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const confirmationOptionChangeTrackingHandler = (
  eventLabel: string
): TrackingLinkEventReturnType => ({
  payload: { eventLabel, ...CONFIRMATION_OPTION_CHANGE_PAYLOAD },
  type: TrackingEventsEnum.LINK,
});

const PersonalNumberStep3ConfirmationOptionTrackingHandlers = (
  track: TrackBuilderType
): PersonalNumberStep3ConfirmationOptionReturnHandlersTrackingType => ({
  handleConfirmationOptionChangeTracking: eventLabel =>
    track(confirmationOptionChangeTrackingHandler(eventLabel)),
});

export default PersonalNumberStep3ConfirmationOptionTrackingHandlers;
