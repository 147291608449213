// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/request-access-step1-descriptions.component.constants';
// Hooks
import RequestAccessHook from 'containers/personal/views/access-portability/views/request/hooks/request-access.hook';
// Styles
import {
  RequestAccessStep1DescriptionsStyled,
  RequestAccessStep1DescriptionsLinkStyled,
} from './request-access-step1-descriptions.component.styled';
// Translations
import translations from 'containers/personal/views/access-portability/views/request/components/step1/translations/request-access-step1.translations';

const RequestAccessStep1Descriptions = (): React.ReactElement => {
  const { email, handlePrivacyPolicyLinkClick } = RequestAccessHook();

  return (
    <RequestAccessStep1DescriptionsStyled>
      <ParagraphComponent {...constants.ACCESS_PORTABILITY_REQUEST_STEP1_PARAGRAPH_01_PROPS}>
        <FormattedMessageComponent
          id={translations.DESCRIPTION_01}
          values={{
            email: <strong>{email}</strong>,
            b: boldText => <strong>{boldText}</strong>,
            br: <br />,
          }}
        />
      </ParagraphComponent>
      <ParagraphComponent {...constants.ACCESS_PORTABILITY_REQUEST_STEP1_PARAGRAPH_02_PROPS}>
        <FormattedMessageComponent id={translations.DESCRIPTION_02} />
      </ParagraphComponent>
      <RequestAccessStep1DescriptionsLinkStyled>
        <ParagraphComponent {...constants.ACCESS_PORTABILITY_REQUEST_STEP1_PARAGRAPH_03_PROPS}>
          <FormattedMessageComponent
            id={translations.DESCRIPTION_03}
            values={{
              link: (
                <LinkComponent
                  {...constants.ACCESS_PORTABILITY_REQUEST_STEP1_LINK_PROPS}
                  onClick={handlePrivacyPolicyLinkClick}
                >
                  <FormattedMessageComponent id={translations.LINK} />
                </LinkComponent>
              ),
            }}
          />
        </ParagraphComponent>
      </RequestAccessStep1DescriptionsLinkStyled>
    </RequestAccessStep1DescriptionsStyled>
  );
};

export default RequestAccessStep1Descriptions;
