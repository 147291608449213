// Vendors
import React from 'react';
// Components
import { ModalComponent, ParagraphComponent, AssetComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getModalConfiguration } from './configurations/expired-document-modal.configuration';
// Constants
import {
  EXPIRED_DOCUMENT_MODAL_ASSET_PROPS,
  EXPIRED_DOCUMENT_MODAL_DESCRIPTION_PROPS,
  LAYAOUT_COLUMN_FLEX_COMPONENT_STYLED_PROPS,
} from './constants/expired-document-modal.constants';
// Hooks
import { useExpiredDocumentModalHook } from './hooks/expired-document-modal.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { DESCRIPTION } from './translations/expired-document-modal.translations';
// Types
import { ExpiredDocumentModalPropsType } from './types/expired-document-modal-props.component.type';

const ExpiredDocumentModalComponent = ({
  isExpiredDocumentModalOpen,
  setIsExpiredDocumentModalOpen,
}: ExpiredDocumentModalPropsType): React.ReactElement | null => {
  const { formatMessage, handleClickCloseModal, handleUpdateDocumentNavigation } =
    useExpiredDocumentModalHook({
      setIsExpiredDocumentModalOpen,
    });

  return isExpiredDocumentModalOpen ? (
    <ModalComponent
      {...getModalConfiguration({
        formatMessage,
        handleClickCloseModal,
        handleUpdateDocumentNavigation,
      })}
    >
      <LayoutColumnFlexComponentStyled {...LAYAOUT_COLUMN_FLEX_COMPONENT_STYLED_PROPS}>
        <AssetComponent {...EXPIRED_DOCUMENT_MODAL_ASSET_PROPS} />
        <ParagraphComponent {...EXPIRED_DOCUMENT_MODAL_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
      </LayoutColumnFlexComponentStyled>
    </ModalComponent>
  ) : null;
};

export { ExpiredDocumentModalComponent };
