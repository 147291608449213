// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Types
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';
import { RequestPortabilityStep3BuilderHandlersType } from './types/request-portability-step3-challenge-builder.handlers.type';
import { RequestPortabilityStep3ChallengeBuilderReturnHandlersType } from './types/request-portability-step3-challenge-builder-return.handlers.type';
import { RequestStep3ChallengeMethodBuilderHandlersType } from './types/request-portability-step3-challenge-method-builder.handlers.type';

const manageErrorOnRequestPortabilityEvent = ({
  setChallenged,
  setResult,
}: Pick<RequestPortabilityStep3BuilderHandlersType, 'setChallenged' | 'setResult'>): void => {
  setChallenged(false);
  setResult(OperativeResultsEnumeration.ERROR);
};

const manageSuccessOnRequestPortabilityEvent = ({
  progressId,
  setChallenged,
  setProgressId,
}: RequestStep3ChallengeMethodBuilderHandlersType): void => {
  setChallenged(false);
  if (progressId) {
    setProgressId(progressId);
  }
};

const challengeRetryButtonClickHandler = ({ setError }: ChallengeRetryButtonClickHandlerType) =>
  setError(false);

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<RequestPortabilityStep3BuilderHandlersType, 'setChallenged'>): any => {
  setChallenged(true);
};

const RequestPortabilityStep3ChallengeHandlers = ({
  setChallenged,
  setError,
  setProgressId,
  setResult,
}: RequestPortabilityStep3BuilderHandlersType): RequestPortabilityStep3ChallengeBuilderReturnHandlersType => ({
  handleManageErrorOnRequestPortabilityEvent: () =>
    manageErrorOnRequestPortabilityEvent({ setChallenged, setResult }),
  handleManageSuccessOnRequestPortabilityEvent: ({ progressId }) =>
    manageSuccessOnRequestPortabilityEvent({ progressId, setChallenged, setProgressId }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setError }),
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler({ setChallenged }),
});

export default RequestPortabilityStep3ChallengeHandlers;
