// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Translations
import translations from 'containers/security-keys/views/signature-key-change/translations/security-keys-signature-key-change.translations';
// Styled
import { SecurityKeysSignatureKeyChangeThirdStepContainerHeaderStyled } from './security-keys-signature-key-change-third-step-container-header.styled';

const SecurityKeysSignatureKeyChangeThirdStepContainerHeaderComponent = (): React.ReactElement => {
  return (
    <SecurityKeysSignatureKeyChangeThirdStepContainerHeaderStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.NOTIFICATION_FIRST_LABEL} />
      </ParagraphComponent>
      <FormattedMessageComponent id={translations.NOTIFICATION_SECOND_LABEL} />
    </SecurityKeysSignatureKeyChangeThirdStepContainerHeaderStyled>
  );
};

export default SecurityKeysSignatureKeyChangeThirdStepContainerHeaderComponent;
