// Vendors
import React from 'react';
// Components
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import WizardComponent from 'components/wizard/wizard.component';
import SidebarDropdownsComponent from 'components/sidebar-dropdowns/sidebar-dropdowns.component';
// Constants
import {
  ERROR_CONFIGURATION,
  SIDEBAR_PROPS,
  STEPS,
  SUCCESS_CONFIGURATION,
} from './constants/card-settings-new-card.constants';
// Contexts
import {
  CardSettingsNewCardContextConsumerHOC,
  useCardSettingsNewCardContextConsumerHook,
} from './contexts/card-settings-new-card.context';
import { BlockCardContextConsumerHOC } from 'components/block-card/contexts/block-card.context';

const CardSettingsNewCardBaseView = (): React.ReactElement => {
  const { error } = useCardSettingsNewCardContextConsumerHook();

  return (
    <OperativeLayoutComponent
      sidebarComponent={() => <SidebarDropdownsComponent {...SIDEBAR_PROPS} />}
    >
      <WizardComponent
        feedbackConfiguration={{
          errorConfiguration: { ...ERROR_CONFIGURATION, ...error },
          successConfiguration: SUCCESS_CONFIGURATION,
        }}
        steps={STEPS}
      />
    </OperativeLayoutComponent>
  );
};

export const CardSettingsNewCardView = CardSettingsNewCardContextConsumerHOC(
  BlockCardContextConsumerHOC(CardSettingsNewCardBaseView)
);
