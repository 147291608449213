// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { ParagraphComponent, MessageComponent, ButtonComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_STATEMENTS_MESSAGE_BUTTON_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_DESCRIPTION_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_01_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_02_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_TITLE_PROPS,
} from './constants/card-settings-statements-message.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { useCardSettingsStatementsMessageHook } from './hooks/card-settings-statements-message.hook';
// Translations
import {
  BUTTON,
  DESCRIPTION,
  TITLE,
} from './translations/card-settings-statements-message.translations';
// Styles
import {
  AppCardHorizontalSectionComponentStyled,
  LayoutColumnFlexComponentStyled,
} from 'styles/app.styled';
// Utils
import {
  getTotalUnpaidAmount,
  hasUnpaidCardSettingsStatements,
} from './utils/card-settings-statements-message.utils';

const CardSettingsStatementsMessageComponent = (): React.ReactElement | null => {
  const { currency, elements, handleMakeATransferMoneyButtonClick } =
    useCardSettingsStatementsMessageHook();

  return hasUnpaidCardSettingsStatements(elements) ? (
    <AppCardHorizontalSectionComponentStyled>
      <MessageComponent {...CARD_SETTINGS_STATEMENTS_MESSAGE_PROPS}>
        <LayoutColumnFlexComponentStyled {...CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_01_PROPS}>
          <LayoutColumnFlexComponentStyled {...CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_02_PROPS}>
            <ParagraphComponent {...CARD_SETTINGS_STATEMENTS_MESSAGE_TITLE_PROPS}>
              <FormattedMessageComponent id={TITLE} />
            </ParagraphComponent>
            <ParagraphComponent {...CARD_SETTINGS_STATEMENTS_MESSAGE_DESCRIPTION_PROPS}>
              <FormattedMessageComponent
                id={DESCRIPTION}
                values={{
                  amount: (
                    <FormattedNumber
                      {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
                      value={getTotalUnpaidAmount(elements)}
                    />
                  ),
                }}
              />
            </ParagraphComponent>
          </LayoutColumnFlexComponentStyled>
          <ButtonComponent
            {...CARD_SETTINGS_STATEMENTS_MESSAGE_BUTTON_PROPS}
            onClick={handleMakeATransferMoneyButtonClick}
          >
            <FormattedMessageComponent id={BUTTON} />
          </ButtonComponent>
        </LayoutColumnFlexComponentStyled>
      </MessageComponent>
    </AppCardHorizontalSectionComponentStyled>
  ) : null;
};

export { CardSettingsStatementsMessageComponent };
