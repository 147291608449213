// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Translations
import { TODAY, YESTERDAY } from './translations/date.utils.translations';
// Types
import { DateUtilsType } from './types/date.utils.type';

const isYesterday = (date: string): boolean => {
  const yesterday = new Date();
  yesterday.setDate(new Date().getDate() - 1);

  return yesterday.toDateString() === new Date(date).toDateString();
};

const isToday = (date: string): boolean =>
  new Date().toDateString() === new Date(date).toDateString();

const getUserFriendlyRelativeDate = ({
  date,
  formatDate,
  formatMessage,
  formatOpts = MiscellaneousDateConstants.FORMAT_DD_MM_YYYY,
}: DateUtilsType): string | void =>
  ({
    [`${isYesterday(date)}`]: formatMessage({ id: YESTERDAY }),
    [`${isToday(date)}`]: formatMessage({ id: TODAY }),
  }['true'] || formatDate(date, formatOpts));

export { getUserFriendlyRelativeDate };
