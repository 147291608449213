// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Constants
import constants from './constants/settings-card-control-operations-toggle.hooks.constants';
// Enumerations
import { SettingsCardControlOperationsToggleHookType } from 'containers/card-settings/views/card-control/components/operations/components/toggle/hooks/types/settings-card-control-operations-toggle.hook.type';
// Types
import { SettingsCardControlOperationsToggleComponentType } from 'containers/card-settings/views/card-control/components/operations/components/toggle/types/settings-card-control-operations-toggle.component.type';
// Utilities
import { getOperativeOperationTranslations } from './utils/settings-card-control-operations-toggle.hook.utils';

const SettingsCardControlOperationsToggleHook = ({
  id,
  translations,
}: SettingsCardControlOperationsToggleComponentType): SettingsCardControlOperationsToggleHookType => {
  const { currentCardDetail } = AppContextConsumerHook(),
    { control } = currentCardDetail || {},
    controlItem = control[constants[id]],
    value = !controlItem;

  return {
    checked: controlItem,
    translations: getOperativeOperationTranslations({ translations, value }),
    value,
  };
};

export default SettingsCardControlOperationsToggleHook;
