// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Types
import { SettingsCardControlsLocationsMainCountryType } from 'containers/card-settings/views/card-control/components/locations/types/settings-card-controls-locations-main-country.type';
import useCbIntl from 'hooks/useCbIntl';
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';

const SettingsCardControlLocationsMainHook = (): Pick<
  SettingsCardControlsLocationsMainCountryType,
  'mainCountryId' | 'mainCountryName2Dig' | 'enableMainCountry'
> &
  Pick<UseCbIntlReturnType, 'formatMessage'> => {
  const { formatMessage } = useCbIntl();
  const { mainCountry } = SettingsCardControlLocationsContextConsumerHook(),
    { mainCountryName2Dig, mainCountryId, enableMainCountry } = mainCountry || {};

  return {
    enableMainCountry,
    formatMessage,
    mainCountryId,
    mainCountryName2Dig,
  };
};

export default SettingsCardControlLocationsMainHook;
