// Vendors
import React from 'react';
import { createPortal } from 'react-dom';
// Components
import { NotificationsSettingsTitleComponent } from './components/title/notifications-settings-title.component';
import { NotificationSettingsListComponent } from './components/list/notifications-settings-list.component';
import { NotificationSettingsSidebarComponent } from './components/sidebar/notifications-settings-sidebar.component';
import { NotificationSettingsFooterComponent } from './components/settings-footer/notifications-settings-footer.component';
import { NotificationsSettingsModalComponent } from './components/settings-modal/notifications-settings-modal.component';
// Constants
import { DEFAULT_TEST_ID, FOOTER_CONTENT_ID } from './constants/notifications-settings.constants';
// Styles
import {
  NotificactionsSettingsViewWrapperStyled,
  NotificactionsSettingsViewStyled,
} from './notifications-settings.view.styled';
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentRightComponentStyled,
  LayoutGridContentLeftComponentStyled,
} from 'styles/app.styled';
// Hooks
import { NotificationsSettingsHook } from './components/list/hooks/notifications-settings-list.hook';

const NotificationSettingsView = (): React.ReactElement => {
  const {
    error,
    fetching,
    formatMessage,
    formatNumber,
    handleControllerChange,
    handleFaqButtonClick,
    handleGoBackButtonClick,
    handleModalSaveButtonClick,
    handleSaveChangesClick,
    handleShowToolTipTracking,
    handleToggleAllNotifications,
    handleToggleNotification,
    hasChangedSettings,
    notificationsSettings,
    saving,
    setShowModal,
    showModal,
  } = NotificationsSettingsHook();

  const footerNavigationEl = document.getElementById(FOOTER_CONTENT_ID);

  return (
    <NotificactionsSettingsViewStyled {...DEFAULT_TEST_ID}>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <NotificactionsSettingsViewWrapperStyled>
            <NotificationsSettingsTitleComponent
              {...{ formatMessage, handleShowToolTipTracking }}
            />
            <NotificationSettingsListComponent
              {...{
                error,
                fetching,
                formatMessage,
                formatNumber,
                handleControllerChange,
                handleToggleAllNotifications,
                handleToggleNotification,
                notificationsSettings,
              }}
            />
          </NotificactionsSettingsViewWrapperStyled>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <NotificationSettingsSidebarComponent {...{ handleFaqButtonClick }} />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
      {footerNavigationEl &&
        createPortal(
          <NotificationSettingsFooterComponent
            {...{
              handleGoBackButtonClick,
              handleSaveChangesClick,
              hasChangedSettings,
              notificationsSettings,
              saving,
            }}
          />,
          footerNavigationEl
        )}
      <NotificationsSettingsModalComponent
        {...{ showModal, setShowModal, handleModalSaveButtonClick, notificationsSettings }}
      />
    </NotificactionsSettingsViewStyled>
  );
};

export default NotificationSettingsView;
