// Constants
import { paragraphConstants, titleConstants } from '@openbank/cb-ui-commons';

export default {
  DASHBOARD_SPENT_SUMMARY_TITLE_PROPS: {
    tag: titleConstants.TAGS.H3,
    testId: 'dashboard-spent-total-title-section',
  },
  DASHBOARD_SPENT_SUMMARY_DEFAULT_TEST_ID: 'dashboard-spent-summary',
};
