// Constants
import constants from 'containers/personal/views/access-portability/views/request/components/step2/services/constants/access-portability-request-step2.services.constants';
// Enums
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';

export const getChallengeConfiguration = ({
  challengeError,
  handleChallengeError,
  handleChallengeRetryButtonClick,
  handleChallengeSuccess,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: constants.GENERATE_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: {
    params: {
      type: AccessPortabilityTypeEnumeration.ACCESS,
      includePersonalData: true,
    },
  },
  baseServiceMethod: 'post',
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
