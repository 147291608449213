// Types
import { SideBarHookReturnType } from './types/notifications-landing-sidebar.hook.return.type';
// Handlers
import SidebarHookHandlers from '../handlers/notifications-landing-sidebar.hook.handlers';
import SidebarHookTrankingHandlers from '../handlers/notifications-landing-sidebar.hook.traking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';

const SideBarHook = (): SideBarHookReturnType =>
  SidebarHookHandlers(AppTrackingHook(SidebarHookTrankingHandlers));

export default SideBarHook;
