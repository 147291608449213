const DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS = {
  testId: 'card-settings-deposit-money-parameter-row',
};
const DEPOSIT_MONEY_PARAMETERS_TABLE_TITLE_PROPS = {
  testId: 'card-settings-deposit-money-parameter-title',
};

export {
  DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS,
  DEPOSIT_MONEY_PARAMETERS_TABLE_TITLE_PROPS,
};
