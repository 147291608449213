// Enumerations
import { ProblemsEnumeration } from 'containers/transactions/views/report/enumerations/problems.enumeration';

const DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.landing.options.';

const OPTIONS_TEXTS = {
  [ProblemsEnumeration.NOT_RECOGNIZE_CHARGE]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}notRecognizeCharge.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}notRecognizeCharge.title`,
  },
  [ProblemsEnumeration.DIFFERENT_AMOUNT_ATM]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}differentAmountAtm.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}differentAmountAtm.title`,
  },
  [ProblemsEnumeration.DUPLICATED_CHARGE]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}duplicatedCharge.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}duplicatedCharge.title`,
  },
  [ProblemsEnumeration.DEFECTIVE_PRODUCT]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}defectiveProduct.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}defectiveProduct.title`,
  },
  [ProblemsEnumeration.NOT_RECEIVED_PRODUCT]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}notReceivedProduct.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}notReceivedProduct.title`,
  },
  [ProblemsEnumeration.CHARGED_SERVICE_CANCELLED]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}chargedServiceCancelled.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}chargedServiceCancelled.title`,
  },
  [ProblemsEnumeration.SUBSCRIPTION_PASSED]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}subscriptionPassed.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}subscriptionPassed.title`,
  },
  [ProblemsEnumeration.PAYMENT_OTHER_MEANS]: {
    description: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}paymentOtherMeans.description`,
    title: `${DOMAIN_REPORT_A_PROBLEM_LANDING_OPTIONS}paymentOtherMeans.title`,
  },
};

export { OPTIONS_TEXTS };
