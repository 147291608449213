// Contexts
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodFirstStepPaymentOptionsHandlers from '../handlers/financing-payment-method-first-step-payment-options.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingPaymentMethodFirstStepPaymentOptionsHookReturnType } from './types/financing-payment-method-first-step-payment-options-return.hook.type';

const FinancingPaymentMethodFirstStepPaymentOptionsHook = (): FinancingPaymentMethodFirstStepPaymentOptionsHookReturnType => {
  const { formatMessage } = useCbIntl();

  const { paymentMethod, setPaymentMethod } = FinancingPaymentMethodContextConsumerHook();

  return {
    ...FinancingPaymentMethodFirstStepPaymentOptionsHandlers({ setPaymentMethod }),
    formatMessage,
    paymentMethod,
  };
};

export default FinancingPaymentMethodFirstStepPaymentOptionsHook;
