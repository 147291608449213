// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Translations
import translations from './translations/security-keys.handlers.utils.translations';
// Types
import { SecurityKeysHandlersUtilsType } from './types/security-keys.handlers.utils.type';

const formatTimestampOrReturnUnknownTag = ({
  formatMessage,
  formatDate,
  timestamp,
}: SecurityKeysHandlersUtilsType): string =>
  timestamp
    ? formatDate(timestamp, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY_HH_MM)
    : formatMessage({ id: translations.UNKNOWN });

export { formatTimestampOrReturnUnknownTag };
