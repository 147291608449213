const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbTransactions.createInstallmentsPlan.step2.cbResume.';

const CONSUMPTION_CONCEPT = `${DOMAIN}consumptionConceptLabel`,
  LOAN_AMOUNT = `${DOMAIN}loanAmountLabel`,
  MONTHLY_INSTALLMENT_PAYMENT = `${DOMAIN}monthAmountInstallmentLabel`,
  NUMBER_INSTALLMENTS = `${DOMAIN}numberInstallmentLabel`,
  TOTAL_AMOUNT = `${DOMAIN}totalAmountLabel`,
  TOTAL_INTEREST = `${DOMAIN}totalInterestLabel`,
  VIEW_AMORTIZATION_TABLE = `${DOMAIN}seeAmortizationTableLink`;

export {
  CONSUMPTION_CONCEPT,
  LOAN_AMOUNT,
  MONTHLY_INSTALLMENT_PAYMENT,
  NUMBER_INSTALLMENTS,
  TOTAL_AMOUNT,
  TOTAL_INTEREST,
  VIEW_AMORTIZATION_TABLE,
};
