// Utils
import {
  formatCurrency,
  getCreditLimitMessageDescription,
  getCreditLimitMessageTitle,
  isEvaluateNewOffer,
} from '../utils/credit-limit-status.utils';
// Translations
import { translations as limitIncreaseTranslations } from '../translations/credit-limit-increase-status.translations';
// Types
import { CreditLimitStatusMessageConfigurationType } from './types/credit-limit-status-message.configuration.type';
import { DashboardTopMessageDescriptionComponentPropsType } from '../components/description/types/dashboard-top-message-description-props.component.type';

const getCreditLimitStatusMessageConfiguration = ({
  formatMessage,
  limitIncreaseStatus,
  locale,
  offeredLimit,
  requestedLimit,
}: CreditLimitStatusMessageConfigurationType): DashboardTopMessageDescriptionComponentPropsType => {
  const isEvaluate = isEvaluateNewOffer(limitIncreaseStatus);
  const description =
    isEvaluate && offeredLimit && requestedLimit
      ? formatMessage(
          { id: limitIncreaseTranslations.EVALUATE_NEW_BETTER_OFFER_DESCRIPTION },
          {
            offeredLimit: formatCurrency({ value: offeredLimit, locale }),
            requestedLimit: formatCurrency({ value: requestedLimit, locale }),
          }
        )
      : '';
  return {
    isCC: false,
    TRANSLATIONS: {
      title: getCreditLimitMessageTitle(limitIncreaseStatus),
      description: getCreditLimitMessageDescription(limitIncreaseStatus),
    },
    description,
  };
};

export { getCreditLimitStatusMessageConfiguration };
