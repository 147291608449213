// Vendors
import React from 'react';
// Components
import { CheckboxComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_ADDRESS_PROPS } from './constants/financing-ppi-contracting-step2-address.constants';
// Hooks
import { useFinancingPPIContractingStep2AddressHook } from './hooks/financing-ppi-contracting-step2-address.hook';
// Translations
import { ADDRESS_CONSENT } from './translations/financing-ppi-contracting-step2-address.translations';

const FinancingPPIContractingStep2AddressComponent = (): React.ReactElement => {
  const {
    address,
    handleChangePostalDocumentationConsentCheckBoxClick: onChange,
    postalDocumentation: value,
  } = useFinancingPPIContractingStep2AddressHook();

  return (
    <CheckboxComponent
      {...{ ...FINANCING_PPI_CONTRACTING_STEP_2_ADDRESS_PROPS, onChange, value }}
      title={<FormattedMessageComponent id={ADDRESS_CONSENT} values={{ address }} />}
    />
  );
};

export { FinancingPPIContractingStep2AddressComponent };
