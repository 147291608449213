// Contexts
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
import { usePersonalNumberStep3ContextConsumerHook } from 'containers/personal/views/number/components/step3/contexts/personal-number-step3.context';
// Handlers
import PersonalNumberStep3ConfirmationOptionHandlers from '../handlers/personal-number-step3-confirmation-option.handlers';
import PersonalNumberStep3ConfirmationOptionTrackingHandlers from '../handlers/personal-number-step3-confirmation-option.handlers.tracking';
// Types
import { PersonalNumberStep3ConfirmationOptionReturnHookType } from './types/personal-number-step3-confirmation-option-return.hook.type';
import AppTrackingHook from 'tracking/app.tracking.hook';

const PersonalNumberStep3ConfirmationOptionHook =
  (): PersonalNumberStep3ConfirmationOptionReturnHookType => {
    const { confirmationCodeOption, setConfirmationCodeOption } =
        usePersonalNumberStep3ContextConsumerHook(),
      { setWithEmail } = PersonalNumberContextConsumerHook();

    return {
      ...PersonalNumberStep3ConfirmationOptionHandlers({
        ...AppTrackingHook(PersonalNumberStep3ConfirmationOptionTrackingHandlers),
        setConfirmationCodeOption,
        setWithEmail,
      }),
      confirmationCodeOption,
    };
  };

export default PersonalNumberStep3ConfirmationOptionHook;
