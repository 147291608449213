// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingPPIContractingContextType } from 'containers/financing/views/ppi/components/contracting/contexts/types/financing-ppi-contracting.context.type';

const FinancingPPIContractingContext = createContext<FinancingPPIContractingContextType>(
  {} as FinancingPPIContractingContextType
);

const FinancingPPIContractingContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FinancingPPIContractingContextType>> => {
  const [documentDownloaded, setDocumentDownloaded] = useState<boolean>(false);
  const [errorDocumentDownloaded, setErrorDocumentDownloaded] = useState<boolean>(false);
  const [postalDocumentation, setPostalDocumentation] = useState<boolean>(false);

  return (
    <FinancingPPIContractingContext.Provider
      value={{
        documentDownloaded,
        errorDocumentDownloaded,
        postalDocumentation,
        setDocumentDownloaded,
        setErrorDocumentDownloaded,
        setPostalDocumentation,
      }}
    >
      {children as React.ReactNode}
    </FinancingPPIContractingContext.Provider>
  );
};

const useFinancingPPIContractingContextConsumerHook = (): FinancingPPIContractingContextType =>
  useContext(FinancingPPIContractingContext);

export { FinancingPPIContractingContextProvider, useFinancingPPIContractingContextConsumerHook };
