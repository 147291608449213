// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useTransactionsReportServiceCancelledContextConsumerHook } from 'containers/transactions/views/report/components/service-cancelled/contexts/transactions-report-service-cancelled.context';
// Handlers
import { TransactionsReportServiceCancelledAdditionalInfoActionsHandlers } from '../handlers/transactions-report-service-cancelled-additional-info-actions.handlers';
// Types
import { TransactionsReportServiceCancelledAdditionalInfoActionsHandlersReturnType } from '../handlers/types/transactions-report-service-cancelled-additional-info-actions-return.handlers.type';

export const useTransactionsReportServiceCancelledAdditionalInfoActionsHook =
  (): TransactionsReportServiceCancelledAdditionalInfoActionsHandlersReturnType => {
    const { setCancellationDate } = useTransactionsReportServiceCancelledContextConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();

    return {
      ...TransactionsReportServiceCancelledAdditionalInfoActionsHandlers({
        setCancellationDate,
        setCurrentStep,
      }),
    };
  };
