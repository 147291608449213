// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  SidebarComponent,
} from '@openbank/cb-ui-commons';
// Constants
import {
  TRANSACTIONS_EPP_SIDEBAR_ITEMS,
  TRANSACTIONS_EPP_SIDEBAR_LAYOUT_PROPS,
  TRANSACTIONS_EPP_SIDEBAR_PROPS,
} from 'containers/transactions/views/epp/components/sidebar/constants/transactions-epp-sidebar.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { TITLE } from 'containers/transactions/views/epp/components/sidebar/translations/transactions-epp-sidebar.translations';

const TransactionsEppSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...TRANSACTIONS_EPP_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <LayoutColumnFlexComponentStyled {...TRANSACTIONS_EPP_SIDEBAR_LAYOUT_PROPS}>
      {TRANSACTIONS_EPP_SIDEBAR_ITEMS.map(
        ({ description, testing: { expandable, paragraph }, title }, key: number) => (
          <ExpandableRowComponent
            key={key}
            testing={expandable}
            title={<FormattedMessageComponent id={title} />}
          >
            <ParagraphComponent testId={paragraph}>
              <FormattedMessageComponent id={description} />
            </ParagraphComponent>
          </ExpandableRowComponent>
        )
      )}
    </LayoutColumnFlexComponentStyled>
  </SidebarComponent>
);

export { TransactionsEppSidebarComponent };
