// Vendors
import React from 'react';
// Components
import AccessPortabilityCardComponent from '../../components/card/access-portability-card.component';
import { PersonalSidebarCCComponent } from 'containers/personal/components/sidebar-cc/personal-sidebar-cc.component';
import AccessPortabilitySidebarComponent from '../../components/sidebar/access-portability-sidebar.component';
// Constants
import constants from './constants/access-portability-landing.constants';
// Contexts
import { AccessPortabilityContextConsumerHOC } from '../../contexts/access-portability.context';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { AccessPortabilityLeftContent } from './access-portability-landing.component.styled';

const AccessPortabilityView = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <AccessPortabilityLeftContent>
      <AccessPortabilityCardComponent {...constants.ACCESS_RIGHTS_CARD_PROPS} />
      <AccessPortabilityCardComponent {...constants.PORTABILITY_RIGHTS_CARD_PROPS} />
    </AccessPortabilityLeftContent>
    <LayoutGridContentRightComponentStyled>
      <PersonalSidebarCCComponent />
      <AccessPortabilitySidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export default AccessPortabilityContextConsumerHOC(AccessPortabilityView);
