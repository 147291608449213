// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cardStatus.cbTag.';

const CARD_STATUS_LABELS = {
  [CardStatusTypesEnumeration.BLOCKED]: `${DOMAIN}blockedLabel`,
  [CardStatusTypesEnumeration.CANCELLED]: `${DOMAIN}cancelledLabel`,
  [CardStatusTypesEnumeration.INACTIVE]: `${DOMAIN}inactiveLabel`,
  [CardStatusTypesEnumeration.LOCKED]: `${DOMAIN}lockedLabel`,
  [CardStatusTypesEnumeration.TEMPORARILY_BLOCKED]: `${DOMAIN}temporarilyBlockedLabel`,
};

export { CARD_STATUS_LABELS };
