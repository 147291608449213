// Enumerations
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';

const REPORT_SUBSCRIPTION_ALREADY_CANCELLED_CONTEXT_DEFAULT_VALUE = {
  cancelledSubDateBefore: undefined,
  files: [],
  setCancelledSubDateBefore: (): undefined => undefined,
  setFiles: (): null => null,
};

const FILES_INITIAL_STATE = [
  {
    id: 'proof-of-contact',
    documentId: undefined,
    documentType: DocumentsTypesEnumeration.PROOF_OF_CONTACT,
    file: null,
    state: DocumentsUploadStatesEnumeration.DEFAULT,
  },
];

export { FILES_INITIAL_STATE, REPORT_SUBSCRIPTION_ALREADY_CANCELLED_CONTEXT_DEFAULT_VALUE };
