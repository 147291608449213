// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import { CardSettingsPaymentModalHandlers } from '../handlers/card-settings-payment-modal.handlers';
import { CardSettingsPaymentModalTrackingHandlers } from '../handlers/card-settings-payment-modal.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsPaymentModalHookType } from './types/card-settings-payment-modal.hook.type';
import { CardSettingsPaymentModalComponentType } from '../types/card-settings-payment-modal.component.type';

const useCardSettingsPaymentModalHook = (
  props: Pick<CardSettingsPaymentModalComponentType, 'enabled' | 'onClose'>
): CardSettingsPaymentModalHookType => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');

  const { formatMessage } = useCbIntl(),
    { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {},
    { setToastConfiguration } = ToastContextConsumerHook(),
    { setCard } = ManagerContextConsumerHook();

  return {
    ...CardSettingsPaymentModalHandlers({
      ...AppTrackingHook(CardSettingsPaymentModalTrackingHandlers),
      ...props,
      cardId,
      progressId,
      setCard,
      setFetching,
      setChallenged,
      setProgressId,
      setToastConfiguration,
    }),
    cardId,
    challenged,
    formatMessage,
    fetching,
  };
};

export { useCardSettingsPaymentModalHook };
