// Resolvers
import { generateNewEmailOtpResolver } from '../resolvers/personal-email-step2.resolvers';
// Types
import { PersonalEmailStep2BuilderHandlersType } from 'containers/personal/views/email/components/step2/handlers/types/personal-email-step2-builder.handlers.type';
import { PersonalEmailStep2BuilderReturnHandlersType } from './types/personal-email-step2-builder-return.handlers.type';

const requestNewEmailOtpLinkClickHandler = async ({
  handleRequestNewEmailOtpLinkClickTracking,
  name,
  newEmail: emailId,
  setChallenged,
  setFetching,
  setError,
  setOtpId,
}: PersonalEmailStep2BuilderHandlersType): Promise<void> => {
  setFetching(true);
  setError(false);
  handleRequestNewEmailOtpLinkClickTracking();

  const [otpId, error] = await generateNewEmailOtpResolver({ emailId, name });

  if (otpId) {
    setChallenged(true);
    setOtpId(otpId);
  } else {
    setError(true);
  }

  setFetching(false);
};

const PersonalEmailStep2Handlers = (
  props: PersonalEmailStep2BuilderHandlersType
): PersonalEmailStep2BuilderReturnHandlersType => ({
  handleRequestNewEmailOtpLinkClick: () => requestNewEmailOtpLinkClickHandler(props),
});

export default PersonalEmailStep2Handlers;
