// Resolvers
import { change3DSecureCodeResolver } from 'containers/card-settings/views/change-3d/components/step2/resolvers/card-settings-change-3d-step2.resolver';
// Translations
import { ERROR_DESCRIPTION } from 'containers/card-settings/views/change-3d/translations/card-settings-change-3d-feedback.translations';
// Types
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';
import {
  CardSettingsChange3DStep2BuilderHandlersType,
  CardSettingsChange3DStep2BuilderReturnHandlersType,
  CardSettingsChange3DStep2SuccessBuilderHandlersType,
} from './types/card-settings-change-3d-step2.handlers.type';
// Utils
import { getErrorDescription } from './utils/card-settings-change-3d-step2.handlers.utils';
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const manageErrorChange3DSecureCodeEventHandler = ({
  setErrorDescription,
  setResult,
}: Pick<
  CardSettingsChange3DStep2BuilderHandlersType,
  'setResult' | 'setErrorDescription'
>): void => {
  setErrorDescription(ERROR_DESCRIPTION);
  setResult(false);
};

const manageSuccessChange3DSecureCodeEventHandler = ({
  newProgressId,
  setChallenged,
  setProgressId,
}: CardSettingsChange3DStep2SuccessBuilderHandlersType): void => {
  setProgressId(newProgressId);
  setChallenged(true);
};

const change3DCodeSecureButtonClickHandler = async ({
  handleChange3DCodeSecureButtonClickTracking,
  new3DCode: scaChallenge,
  progressId,
  setChallenged,
  setChallengeError,
  setErrorDescription,
  setFetching,
  setProgressId,
  setResult,
}: CardSettingsChange3DStep2BuilderHandlersType): Promise<void> => {
  handleChange3DCodeSecureButtonClickTracking();
  setFetching(true);

  try {
    const [, error] = await change3DSecureCodeResolver({ progressId, scaChallenge });

    if (error) {
      if (isWrongOTPError(error)) {
        setChallenged(false);
        setChallengeError(true);
        setProgressId('');

        return;
      }

      setErrorDescription(getErrorDescription(error));
    }

    setResult(!error);
  } finally {
    setFetching(false);
  }
};

const CardSettingsChange3dStep2Handlers = ({
  handleChange3DCodeSecureButtonClickTracking,
  new3DCode,
  progressId,
  setChallenged,
  setChallengeError,
  setErrorDescription,
  setFetching,
  setProgressId,
  setResult,
}: CardSettingsChange3DStep2BuilderHandlersType): CardSettingsChange3DStep2BuilderReturnHandlersType => ({
  handleChange3DCodeSecureButtonClick: () =>
    change3DCodeSecureButtonClickHandler({
      handleChange3DCodeSecureButtonClickTracking,
      new3DCode,
      progressId,
      setChallenged,
      setChallengeError,
      setErrorDescription,
      setFetching,
      setProgressId,
      setResult,
    }),
  handleManageErrorChange3DSecureCodeEvent: () =>
    manageErrorChange3DSecureCodeEventHandler({ setErrorDescription, setResult }),
  handleManageSuccessChange3DSecureCodeEvent: ({
    progressId: newProgressId,
  }: ChallengeProgressIdType) =>
    manageSuccessChange3DSecureCodeEventHandler({ newProgressId, setChallenged, setProgressId }),
});

export { CardSettingsChange3dStep2Handlers };
