// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsChangePinActionsTrackingHandlersType } from './types/card-settings-change-pin-actions.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const cancelChangePinProcessLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancel',
  },
  type: TrackingEventsEnum.LINK,
});

const confirmChangePinButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'changePin',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsChangePinActionsTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsChangePinActionsTrackingHandlersType => ({
  handleCancelChangePinProcessLinkClickTracking: () =>
    track(cancelChangePinProcessLinkClickTrackingHandler()),
  handleConfirmChangePinButtonClickTracking: () =>
    track(confirmChangePinButtonClickTrackingHandler()),
});

export default CardSettingsChangePinActionsTrackingHandlers;
