// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Translations
import {
  FEATURE_01_DESCRIPTION,
  FEATURE_02_DESCRIPTION,
  FEATURE_03_DESCRIPTION,
  FEATURE_04_DESCRIPTION,
  FEATURE_01_TITLE,
  FEATURE_02_TITLE,
  FEATURE_03_TITLE,
  FEATURE_04_TITLE,
} from '../translations/travel-plus-features.translations';

const TRAVEL_PLUS_FEATURES = [
    {
      icon: '0percentage',
      description: FEATURE_03_DESCRIPTION,
      title: FEATURE_03_TITLE,
    },
    {
      icon: 'bill',
      description: FEATURE_02_DESCRIPTION,
      title: FEATURE_02_TITLE,
    },
    {
      icon: 'health',
      description: FEATURE_04_DESCRIPTION,
      title: FEATURE_04_TITLE,
    },
    {
      icon: 'cart',
      description: FEATURE_01_DESCRIPTION,
      title: FEATURE_01_TITLE,
    },
  ],
  TRAVEL_PLUS_FEATURE_ASSET_PROPS = {
    sizes: {
      height: 20,
      width: 20,
    },
  },
  TRAVEL_PLUS_FEATURE_TITLE_PROPS = {
    bold: true,
  },
  TRAVEL_PLUS_FEATURES_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxxs,
  },
  TRAVEL_PLUS_FEATURE_ASSET_TEST_ID = 'travel-plus-feature-asset-',
  TRAVEL_PLUS_FEATURE_TITLE_TEST_ID = 'travel-plus-feature-title-',
  TRAVEL_PLUS_FEATURE_DESCRIPTION_TEST_ID = 'travel-plus-feature-description-';

export {
  TRAVEL_PLUS_FEATURES,
  TRAVEL_PLUS_FEATURE_ASSET_PROPS,
  TRAVEL_PLUS_FEATURE_ASSET_TEST_ID,
  TRAVEL_PLUS_FEATURE_DESCRIPTION_TEST_ID,
  TRAVEL_PLUS_FEATURES_LAYOUT_PROPS,
  TRAVEL_PLUS_FEATURE_TITLE_TEST_ID,
  TRAVEL_PLUS_FEATURE_TITLE_PROPS,
};
