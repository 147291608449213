const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyEmail.';
const ERROR_DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyEmail.errorFeedback.';
const SUCCESS_DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyEmail.successFeedback.';

const TITLE = `${DOMAIN}title`,
  ERROR_BUTTON = `${ERROR_DOMAIN}retryButton`,
  ERROR_DESCRIPTION = `${ERROR_DOMAIN}description`,
  ERROR_LINK = `${ERROR_DOMAIN}cancelLink`,
  ERROR_TITLE = `${ERROR_DOMAIN}title`,
  SUCCESS_DESCRIPTION = `${SUCCESS_DOMAIN}description`,
  LINK_LABEL = `${SUCCESS_DOMAIN}returnToPersonalInformationLink`,
  SUCCESS_TITLE = `${SUCCESS_DOMAIN}title`,
  NEXT = `${DOMAIN}nextButton`,
  CANCEL = `${DOMAIN}cancelButton`;

export {
  TITLE,
  ERROR_BUTTON,
  ERROR_DESCRIPTION,
  ERROR_LINK,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  LINK_LABEL,
  SUCCESS_TITLE,
  NEXT,
  CANCEL,
};
