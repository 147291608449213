// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DevicesManagerLandingViewStyled = styled.section.attrs(() => ({
  'data-testid': 'device-manager-component',
}))`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => `${theme.spaces.spacingM} ${theme.spaces.spacingXxxxxl} ${theme.spaces.spacingL}`};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `${theme.spaces.spacingS}`};
  }
`;