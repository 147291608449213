// Vendors
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
// Components
import { ResumeItemComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import CopyLinkComponent from 'components/copy-link/copy-link.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  OTP_SECTION_RESUME_ITEM_1_PROPS,
  OTP_SECTION_RESUME_ITEM_2_PROPS,
  OTP_SECTION_TITLE_PROPS,
} from './constants/otp-section.constants';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Styles
import {
  OtpSectionComponentStyled,
  OtpSectionContentComponentStyled,
  OtpSectionContentResumeComponentStyled,
} from './otp-section.component.styled';
// Translations
import { OTP, PLAIN_MESSAGE } from './translations/otp.translations';
// Types
import { OtpSectionComponentType } from './types/otp-section.component.type';
// Utilities
import { getOtpCode } from './utils/otp-section.utils';

const OtpSectionComponent = ({
  message: { otp = CharactersEnum.NO_VALUE, plainMessage = CharactersEnum.NO_VALUE },
  title,
}: OtpSectionComponentType): React.ReactElement => (
  <OtpSectionComponentStyled>
    <TitleHeaderComponent {...OTP_SECTION_TITLE_PROPS}>
      <FormattedMessageComponent id={title} />
    </TitleHeaderComponent>
    <OtpSectionContentComponentStyled>
      <OtpSectionContentResumeComponentStyled>
        <ResumeItemComponent
          {...OTP_SECTION_RESUME_ITEM_1_PROPS}
          label={<FormattedMessageComponent id={OTP} />}
          value={getOtpCode(otp)}
        />
        <CopyLinkComponent label={OTP} text={getOtpCode(otp)} />
      </OtpSectionContentResumeComponentStyled>
      <ResumeItemComponent
        {...OTP_SECTION_RESUME_ITEM_2_PROPS}
        label={<FormattedMessageComponent id={PLAIN_MESSAGE} />}
        value={ReactHtmlParser(getOtpCode(plainMessage))}
      />
    </OtpSectionContentComponentStyled>
  </OtpSectionComponentStyled>
);

export default OtpSectionComponent;
