export const OPEN_BLOCK_MODAL_PAYLOAD = {
  action: 'click',
  format: 'button',
  component: 'accordionSecurityDevice',
  element: 'blockDevice',
  eventAction: 'clickButton',
  eventLabel: 'blockDeviceButton'
};

export const OPEN_UNBLOCK_MODAL_PAYLOAD = {
  action: 'click',
  format: 'button',
  component: 'accordionSecurityDevice',
  element: 'unblockDevice',
  eventAction: 'clickButton',
  eventLabel: 'unblockDeviceButton'
};

export const OPEN_BLOCK_UNROLL_PAYLOAD = {
  action: 'click',
  format: 'button',
  component: 'accordionSecurityDevice',
  element: 'unrollTrustedDevice',
  eventAction: 'clickButton',
  eventLabel: 'unrollTrustedDeviceButton'
};