export default {
  VIEW: {
    action: 'view',
    format: 'error',
    component: 'body',
    element: 'full',
    errorCategory: 'rejectedByRiskDepartament',
    errorDescription: 'unespectedError',
    eventCategory: 'credit-limit-increase',
    eventAction: 'rejectedByRiskDepartament',
    eventLabel: 'view',
  },
  BACK_BUTTON: {
    action: 'click',
    format: 'button',
    component: 'next-step-error-credit-limit-increase',
    element: 'backToFinancing',
    eventCategory: 'credit-limit-increase',
    eventAction: 'clickButton',
    eventLabel: 'backToFinancingButton',
  },
} as const;
