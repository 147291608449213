// Enumerations
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

const TRANSACTIONS_TABLE_LIST_GROUPS_DIVIDER_PROPS = {
  testId: 'transactions-all-list-groups-divider',
  testing: {
    elementId: 'transactions-all-list-groups-divider',
    titleId: 'transactions-all-list-groups-divider-title',
  },
  titleBold: false,
  type: SectionDividerTypesEnumeration.INFORMATIVE,
};

export { TRANSACTIONS_TABLE_LIST_GROUPS_DIVIDER_PROPS };
