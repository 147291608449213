// Resolvers
import { fetchDownloadCardStatementDocumentResolver } from '../resolvers/card-statement-table-row-download.resolver';
// Translations
import { ERROR_DOWNLOAD } from './translations/card-statement-table-row-download.handlers.translations';
// Types
import {
  CardStatementTableRowDownloadBuilderHandlersType,
  CardStatementTableRowDownloadMethodsHandlersType,
} from './types/card-statement-table-row-download.handlers.type';
// Utilities
import { setupToastConfiguration } from 'components/toast/utils/toast.utils';

const downloadCardStatementDocumentLinkClickHandler = async ({
  documentId,
  handleDownloadCardStatementDocumentLinkClickTracking,
  setFetching,
  setToastConfiguration,
  version,
}: CardStatementTableRowDownloadBuilderHandlersType): Promise<void> => {
  setFetching(true);
  documentId && handleDownloadCardStatementDocumentLinkClickTracking(documentId);

  const [document] = await fetchDownloadCardStatementDocumentResolver({ documentId, version });

  document
    ? window.open(document?.documentUri)
    : setToastConfiguration(setupToastConfiguration({ error: true, description: ERROR_DOWNLOAD }));
  setFetching(false);
};

const CardStatementTableRowDownloadHandlers = (
  props: CardStatementTableRowDownloadBuilderHandlersType
): CardStatementTableRowDownloadMethodsHandlersType => ({
  handleDownloadCardStatementDocumentLinkClick: () =>
    downloadCardStatementDocumentLinkClickHandler(props),
});

export { CardStatementTableRowDownloadHandlers };
