// Vendors
import styled from 'styled-components';
import { AmazonTheme } from '@openbank/cf-ui-static-data';

export const Step4UploadDocumentationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const UploadDocTipsStyled = styled.div`
  > div {
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};

    > div:nth-child(2) {
      gap: ${({ theme }) => theme.spaces.spacingL};

      > div {
        align-items: flex-start;
      }
    }
  }
`;

export const ParagraphStyled = styled.p`
  font-family: ${AmazonTheme.default.fontFamilies.fontFamilyB};
  margin-top: 0;
`;
