// Vendors
import styled from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const FinancingModalComponentStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: grid;
  gap: ${({ theme }) => theme.spaces.spacingS};
  grid-template-columns: 1fr 1fr 1fr;
  padding: ${({ theme }) => theme.spaces.spacingS};

  span:nth-child(2) {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  span:nth-child(3) {
    align-items: flex-start;
    display: flex;
    justify-content: end;
  }
`;

FinancingModalComponentStyled.displayName = 'FinancingModalComponentStyled';

const FinancingModalStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  padding: 0;
`;

FinancingModalStyled.displayName = 'FinancingModalStyled';

const InstallmentsStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: grid;
  gap: ${({ theme }) => theme.spaces.spacingS};
  grid-template-columns: 1fr 1fr 1fr;
  padding: ${({ theme }) => theme.spaces.spacingS};
`;

InstallmentsStyled.displayName = 'InstallmentsStyled';

const ParagraphCenteredComponentStyled = styled(ParagraphComponent)`
  display: flex;
  justify-content: center;
  text-align: center;
`;

ParagraphCenteredComponentStyled.displayName = 'ParagraphCenteredComponentStyled';

const ParagraphAlignEndComponentStyled = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  justify-content: end;
`;

ParagraphAlignEndComponentStyled.displayName = 'ParagraphAlignEndComponentStyled';

const ParagraphComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-self: center;
  text-wrap: nowrap;
`;

ParagraphComponentStyled.displayName = 'ParagraphComponentStyled';

const ParagraphOrderComponentStyled = styled(ParagraphComponent)`
  line-height: ${({ theme }) => theme.lineHeights.fontLineHeightJ};
`;

ParagraphOrderComponentStyled.displayName = 'ParagraphOrderComponentStyled';

export {
  FinancingModalStyled,
  FinancingModalComponentStyled,
  InstallmentsStyled,
  ParagraphCenteredComponentStyled,
  ParagraphAlignEndComponentStyled,
  ParagraphComponentStyled,
  ParagraphOrderComponentStyled,
};
