// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalPhotoNameButtonBuilderReturnTrackingHandlersType } from './types/personal-photo-name-button-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const uploadPhotoButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'uploadAPhoto',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalPhotoNameButtonTrackingHandlers = (
  track: TrackBuilderType
): PersonalPhotoNameButtonBuilderReturnTrackingHandlersType => ({
  handleUploadPhotoButtonClickTracking: () => track(uploadPhotoButtonClickTracking()),
});

export default PersonalPhotoNameButtonTrackingHandlers;
