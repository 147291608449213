// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { NON_SECURE_STORES_SERVICE_CONFIGURATION } from './constants/card-settings-payment-modal.service.constants';
// Types
import { CardSettingsPaymentModalApiBuilderType } from '../types/card-settings-payment-modal-api-builder.type';

const toggleNonSecureStoresService = async ({
  cardId,
  enabled: isAllowPaymentsNonSecStore,
  progressId,
}: CardSettingsPaymentModalApiBuilderType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.patch({
    ...NON_SECURE_STORES_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    params: { isAllowPaymentsNonSecStore },
    pathParams: { cardId },
  });

export { toggleNonSecureStoresService };
