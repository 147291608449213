// Vendors
import React from 'react';
// Componentes
import { LineSeparatorComponent, InputRangePickerComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  INPUT_RANGE_DATE_PICKER_DEFAULT_PROPS,
  INPUT_RANGE_PICKER_PROPS,
} from './constants/filter-modal-input-range-date-picker.constants';
// Hooks
import { FilterModalInputRangeDatePickerHook } from './hooks/filter-modal-input-range-date-picker.hook';
// Styles
import {
  FilterModalInputRangeDatePickerComponentStyled,
  FilterModalInputRangeDatePickerContentStyled,
} from './filter-modal-input-range-date-picker.component.styled';
// Translations
import { FROM, TO } from '../translations/filter-modal-input-range.translations';
// Types
import { FilterModalInputRangeDatePickerComponentType } from './types/filter-modal-input-range-date-picker.component.type';

const FilterModalInputRangeDatePickerComponent = ({
  showLineSeparator = INPUT_RANGE_DATE_PICKER_DEFAULT_PROPS.showLineSeparator,
  testId = INPUT_RANGE_DATE_PICKER_DEFAULT_PROPS.testId,
  onChangeFromInput,
  onChangeToInput,
  fromValue,
  toValue,
}: FilterModalInputRangeDatePickerComponentType): React.ReactElement => {
  const { handleSelectRange, formatMessage, todayDate } = FilterModalInputRangeDatePickerHook({
    onChangeFromInput,
    onChangeToInput,
  });

  return (
    <FilterModalInputRangeDatePickerComponentStyled {...{ testId }}>
      <FilterModalInputRangeDatePickerContentStyled>
        <InputRangePickerComponent
          {...{
            disableFutureFromDate: new Date(todayDate.setDate(todayDate.getDate() + 1))
              .toISOString()
              .split('T')[0],
            label: {
              from: formatMessage({ id: FROM }),
              to: formatMessage({ id: TO }),
            },
            onSelectRange: handleSelectRange,
            range: {
              from: fromValue,
              to: toValue,
            },
            ...INPUT_RANGE_PICKER_PROPS,
          }}
        />
      </FilterModalInputRangeDatePickerContentStyled>
      {showLineSeparator && <LineSeparatorComponent />}
    </FilterModalInputRangeDatePickerComponentStyled>
  );
};

export default FilterModalInputRangeDatePickerComponent;
