// Vendors
import React from 'react';
// Components
import SettingsCardControlOperationsComponent from '../operations/settings-card-control-operations.component';
import SettingsCardControlLocationsComponent from '../locations/settings-card-control-locations.component';
// Enumerations
import { CardControlTabsIdsEnumeration } from '../../enumerations/card-control-tabs-ids.enumeration';

const SettingsCardControlOrchestratorComponent = ({
  tabSelectedId,
}: any): React.ReactElement | null => {
  switch (tabSelectedId) {
    case CardControlTabsIdsEnumeration.LOCATIONS:
      return <SettingsCardControlLocationsComponent />;
    case CardControlTabsIdsEnumeration.OPERATIONS:
      return <SettingsCardControlOperationsComponent />;
    default:
      return null;
  }
};

export default SettingsCardControlOrchestratorComponent;
