// Vendors
import styled from 'styled-components';

export const RequestAccessStep1DescriptionsStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const RequestAccessStep1DescriptionsLinkStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;