// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';
// Hooks
import { useTransactionsReportDifferentAmountAtmConfirmationActionsHook } from './hooks/transactions-report-different-amount-atm-confirmation-actions.hook';

export const TransactionsReportDifferentAmountAtmConfirmationActionsComponent =
  (): React.ReactElement => {
    const { challenge, challengeError, handleConfirmButtonClick } =
      useTransactionsReportDifferentAmountAtmConfirmationActionsHook();

    return (
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        hasDefaultLeftButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleConfirmButtonClick,
        }}
        targets={SECOND_STEP_TARGETS}
      />
    );
  };
