// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { CardSettingsResumeLinkAssetComponent } from './components/asset/card-settings-resume-link-asset.component';
import { CardSettingsResumeLinkLinksComponent } from './components/links/card-settings-resume-link-links.component';
import { CardSettingsResumeLinkValueComponent } from './components/value/card-settings-resume-link-value.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_RESUME_LINK_LABEL_DEFAULT_TESTING_PROPS } from './constants/card-settings-resume-link.constants';
// Styles
import {
  CardSettingsResumeLinkComponentStyled,
  CardSettingsResumeLinkContentComponentStyled,
  CardSettingsResumeLinkTopComponentStyled,
} from './card-settings-resume-link.component.styled';
// Types
import { CardSettingsResumeLinkComponentType } from './types/card-settings-resume-link.component.type';

const CardSettingsResumeLinkCommonComponent = ({
  label,
  links,
  testing: { labelId, valueId } = CARD_SETTINGS_RESUME_LINK_LABEL_DEFAULT_TESTING_PROPS,
  tooltipMessage,
  value,
}: CardSettingsResumeLinkComponentType): React.ReactElement<CardSettingsResumeLinkComponentType> => (
  <CardSettingsResumeLinkComponentStyled>
    <CardSettingsResumeLinkContentComponentStyled>
      <CardSettingsResumeLinkTopComponentStyled>
        <ParagraphComponent testId={labelId}>
          <FormattedMessageComponent id={label} />
        </ParagraphComponent>
        <CardSettingsResumeLinkAssetComponent {...{ tooltipMessage }} />
      </CardSettingsResumeLinkTopComponentStyled>
      <CardSettingsResumeLinkValueComponent {...{ value, testId: valueId }} />
    </CardSettingsResumeLinkContentComponentStyled>
    <CardSettingsResumeLinkLinksComponent {...{ links }} />
  </CardSettingsResumeLinkComponentStyled>
);

export { CardSettingsResumeLinkCommonComponent };
