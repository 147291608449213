// Vendors
import { useEffect } from 'react';
// Contexts
import { SecurityKeysAccessKeyChangeContextConsumerHook } from 'containers/security-keys/views/access-key-change/contexts/security-keys-access-key-change.context';
// Event handlers
import SecurityKeysAccessKeyChangeStep3TrackingHandlers from '../../handlers/security-keys-access-key-change-third-step.tracking.handlers';
import AccessKeyChangeStep3ActionsHandlers from '../handlers/security-keys-access-key-change-third-step.actions.handlers';
// Hooks
import { SecurityKeysAccessKeyChangeHook } from 'containers/security-keys/views/access-key-change/hooks/security-keys-access-key-change.hook';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { AccessKeyChangeStep3ActionsHandlersParamsType } from './types/security-keys-access-key-change-step3-actions-handlers.type';

const AccessKeyChangeStep3ActionsHook = (): AccessKeyChangeStep3ActionsHandlersParamsType => {
  const { isDisabledLastStep } = SecurityKeysAccessKeyChangeContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const {
    accessKey,
    newAccessKey,
    progressId,
    setAccessKey,
    setNewAccessKey,
    setProgressId,
    setRepeatNewAccessKey,
  } = SecurityKeysAccessKeyChangeContextConsumerHook();

  const {
    handleNextButtonClickTrackingHandlerStep3,
    handleOnEnterThirdStepAccessKeyChangeTrackingHandler,
  } = AppTrackingHook(SecurityKeysAccessKeyChangeStep3TrackingHandlers);

  const { handleFinishActionsEvent } = AccessKeyChangeStep3ActionsHandlers({
    accessKey,
    handleNextButtonClickTrackingHandlerStep3,
    newAccessKey,
    progressId,
    setAccessKey,
    setNewAccessKey,
    setRepeatNewAccessKey,
    setResult,
    setProgressId,
  });
/* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleOnEnterThirdStepAccessKeyChangeTrackingHandler();
  }, []);

  return {
    ...SecurityKeysAccessKeyChangeHook(),
    handleFinishActionsEvent,
    isDisabledLastStep,
    newAccessKey,
  };
};

export default AccessKeyChangeStep3ActionsHook;
