// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK,
  SUCCESS_TITLE,
} from '../translations/card-settings-change-pin-feedback.translations';

const CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_ERROR_PROPS = {
    exitRoute: RoutesEnumeration.CARD_SETTINGS,
    title: ERROR_TITLE,
  },
  CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_SUCCESS_PROPS = {
    exitRoute: RoutesEnumeration.CARD_SETTINGS,
    linkLabel: SUCCESS_LINK,
    description: SUCCESS_DESCRIPTION,
    title: SUCCESS_TITLE,
  };

export {
  CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_ERROR_PROPS,
  CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_SUCCESS_PROPS,
};
