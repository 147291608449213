// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Handlers
import FeedbackHandlers from 'containers/financing/views/credit-limit-increase/handlers/step-5-feedback.handler';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Trackings
import Step5CreditLimitChageTracking from '../trackings/step-5-credit-limit-increase.tracking';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { Step5CreditLimitIncreaseHookType } from './types/step-5-credit-limit-increase.component.hook.type';
import { CreditLimitIncreaseStep5StatusType } from './types/step-5-status.type';

const Step5CreditLimitIncreaseHook = (): Step5CreditLimitIncreaseHookType => {
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step5, wizardStatus } = creditLimitContext;
  const ppiStatus = step5.ppiStatus ?? '';
  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();
  const navigate = useNavigate();
  const { currentCard } = AppContextConsumerHook();
  const { currencyCode } = currentCard || {};
  const { setCurrentStep } = useWizardContextConsumerHook();
  const {
    handleEnterViewTracking,
    handleClickGoToFinancingTracking,
    handleClickGoToSignContractTracking,
    handleClickRejectOfferTracking,
  } = AppTrackingHook(Step5CreditLimitChageTracking);
  const { handleStep5GoNext, handleGoToFinancing, handleEvaluateNewOffer, handleGetPPIInfo } =
    FeedbackHandlers({
      ppiStatus,
      creditLimitContext,
      onNavigateHybridFlow,
      navigate,
      setCurrentStep,
      handleClickGoToFinancingTracking,
      handleClickGoToSignContractTracking,
      handleClickRejectOfferTracking,
    });
  const creditLimitIncreaseStatus =
    wizardStatus.currentStepInText as CreditLimitIncreaseStep5StatusType;
  const isApprovalOrPendingOrRiskReview =
    creditLimitIncreaseStatus === CreditLimitIncreaseStepsEnumeration.APPROVAL ||
    creditLimitIncreaseStatus === CreditLimitIncreaseStepsEnumeration.PENDING ||
    creditLimitIncreaseStatus === CreditLimitIncreaseStepsEnumeration.RISK_REVIEW;

  useEffect(() => {
    handleEnterViewTracking();
    handleGetPPIInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    creditLimitIncreaseStatus,
    isApprovalOrPendingOrRiskReview,
    currentCreditLimit: step5.currentCreditLimit as number,
    newCreditLimit: step5.newCreditLimit as number,
    maxAmount: step5.maxAmount as number,
    currencyCode,
    requestedCreditLimit: step5.requestedCreditLimit as number,
    ppiStatus,
    nextButtonDisabled: !step5.newCreditLimit || step5.nextDisabled,
    handleStep5GoNext,
    handleGoToFinancing,
    handleEvaluateNewOffer,
  };
};

export default Step5CreditLimitIncreaseHook;
