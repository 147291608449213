// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsChangePinRepeatedPinBuilderReturnTrackingHandlersType } from 'containers/card-settings/views/change-pin/components/repeated-pin/handlers/types/card-settings-change-pin-repeated-pin.tracking.handlers.type';

const ChangePinRepeatedPinClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'repeatedPinCardSettingsChangePin',
  },
  type: TrackingEventsEnum.LINK,
});

const ChangePinRepeatedPinErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'repeatedPinNotMatchesError',
    eventLabel: 'noMatchNewPin',
    errorCategory: 'repeatedPinNotMatchesError',
    errorDescription: 'invalidPin',
  },
  type: TrackingEventsEnum.LINK,
});

const LoginEmailTrackingHandlers = (
  track: (param: unknown) => void
): CardSettingsChangePinRepeatedPinBuilderReturnTrackingHandlersType => ({
  handleCardSettingsChangePinRepeatedPinClickTracking: () =>
    track(ChangePinRepeatedPinClickTrackingHandler()),
  handleCardSettingsChangePinRepeatedPinErrorTracking: () =>
    track(ChangePinRepeatedPinErrorTrackingHandler()),
});

export default LoginEmailTrackingHandlers;
