import {
  CARD_SETTINGS_PAYMENT_CHANGE_PROPS,
  CARD_SETTINGS_PAYMENT_CHECK_PROPS,
  CARD_SETTINGS_PAYMENT_DIVIDER_PROPS,
  CARD_SETTINGS_PAYMENT_STORES_PROPS,
} from './card-settings-payment.common.constants';

const CARD_SETTINGS_PAYMENT_DIVIDER_SECTION_PROPS = {
  layout: '1fr 1fr 2fr',
};

export {
  CARD_SETTINGS_PAYMENT_CHANGE_PROPS,
  CARD_SETTINGS_PAYMENT_CHECK_PROPS,
  CARD_SETTINGS_PAYMENT_DIVIDER_PROPS,
  CARD_SETTINGS_PAYMENT_DIVIDER_SECTION_PROPS,
  CARD_SETTINGS_PAYMENT_STORES_PROPS,
};
