// Vendors
import styled from 'styled-components';
// Constants
import { PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_PREVIEW_PROPS } from 'containers/personal/views/photo/components/name-button/components/upload-modal/components/preview/constants/personal-photo-name-button-upload-modal-preview.constants';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const PersonalPhotoNameButtonUploadModalPreviewComponentStyled = styled.div.attrs({
  'data-testid': PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_PREVIEW_PROPS,
})<{ src: string }>`
  background: url("${({ src }) => src}") no-repeat center center;
  background-size: 100% 100%;
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  height: ${convertPxsToRems({ pixels: 284, base: 14 })};
  width: 100%;
`;

export { PersonalPhotoNameButtonUploadModalPreviewComponentStyled };
