// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  LOGIN_MESSAGE_PROPS: {
    testId: 'login-message',
    type: messageConstants.TYPES.ERROR,
  },
  LOGIN_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'login-message-table-row-description',
  },
};
