// Services
import {
  fetchDevicesList,
  fetchTrustedDevice,
  fetchSessionsListById,
  fetchEditDeviceAlias,
  fetchUnrollTrustedDevice,
  fetchBlockDevice,
  fetchUnblockDevice,
  fetchSignOutDevice
} from '../services/signature-key-devices.services';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Types
import { SignatureKeyDevicesDeviceType } from '../types/signature-key-devices.device.type';
import { SignatureKeyDevicesSessionType } from '../types/signature-key-devices.session.type';
import { DevicesListPropsType } from '../types/devices-list.props.type';
import { DeviceActionsPropsType } from '../types/device-action.props.type';
import { DeviceUnrollPropsType } from '../types/device-unroll.props.type';
import { EditAliasPropsType } from '../types/edit-alias.props.type';
import { SignOutPropsType } from '../types/sign-out.props.type';

export const devicesListResolver = async (
  { blocked = false }: DevicesListPropsType): Promise<[Array<SignatureKeyDevicesDeviceType> | null, boolean]> => {
  const [response, { status }] = await fetchDevicesList({ blocked });

  return wasSuccessRequest(status) ? [response, false] : [null, true];
};

export const trustedDeviceResolver = async (): Promise<[SignatureKeyDevicesDeviceType | null, boolean]> => {
  const [response, { status }] = await fetchTrustedDevice();

  return wasSuccessRequest(status) ? [response, false] : [null, true];
};

export const deviceSessionsResolver = async (
  { deviceUUID }: DeviceActionsPropsType): Promise<[Array<SignatureKeyDevicesSessionType> | null, boolean]> => {
  const [response, { status }] = await fetchSessionsListById({ deviceUUID });

  return wasSuccessRequest(status) ? [response, false] : [null, true];
};

export const editDeviceAliasResolver = async (
  { deviceUUID, alias }: EditAliasPropsType): Promise<[Record<string, string>, boolean]> => {
  const [response, { status }] = await fetchEditDeviceAlias({ deviceUUID, alias });

  return wasSuccessRequest(status) ? [response, false] : [response, true];
};

export const unrollTrustedDeviceResolver = async (
  { deviceUUID, progressId }: DeviceUnrollPropsType): Promise<[null, boolean]> => {
  const [, { status }] = await fetchUnrollTrustedDevice({ deviceUUID, progressId });

  return wasSuccessRequest(status) ? [null, false] : [null, true];
};

export const blockDeviceResolver = async (
  { deviceUUID }: DeviceActionsPropsType): Promise<[null, boolean]> => {
  const [, { status }] = await fetchBlockDevice({ deviceUUID });

  return wasSuccessRequest(status) ? [null, false] : [null, true];
};

export const unblockDeviceResolver = async (
  { deviceUUID }: DeviceActionsPropsType): Promise<[null, boolean]> => {
  const [, { status }] = await fetchUnblockDevice({ deviceUUID });

  return wasSuccessRequest(status) ? [null , false] : [null, true];
};

export const signOutDeviceResolver = async (args: SignOutPropsType): Promise<[null, boolean]> => {
  const [, { status }] = await fetchSignOutDevice(args);

  return wasSuccessRequest(status) ? [null, false] : [null, true];
};