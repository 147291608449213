// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const ConsentCheckerCheckboxesWarpperStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: ${({ theme }) => theme.spaces.spacingM};
  row-gap: ${({ theme }) => theme.spaces.spacingS};
  margin: ${({ theme }) => theme.spaces.spacingXxs} 0 ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    gap: ${({ theme }) => theme.spaces.spacingM};
    flex-direction: column;
  }
`;

const ConsentCheckerToggleWarpperStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export { ConsentCheckerCheckboxesWarpperStyled, ConsentCheckerToggleWarpperStyled };
