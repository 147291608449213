// Vendors
import { useState } from 'react';
// Contexts
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { FinancingInsurancesCancellationLPIHandlers } from '../handlers/financing-insurances-cancellation-lpi.handlers';
import { FinancingInsurancesCancellationLPITrackingHandlers } from '../handlers/financing-insurances-cancellation-lpi.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesCancellationLPIHookReturnType } from './types/financing-insurances-cancellation-lpi-return.hook.type';

const useFinancingInsurancesCancellationLPI =
  (): FinancingInsurancesCancellationLPIHookReturnType => {
    const [challenged, setChallenged] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [progressId, setProgressId] = useState<string>('');

    const { formatMessage, formatDate } = useCbIntl();
    const { setFetchPPI } = useFinancingPPIContextConsumerHook();
    const { insurancesInfo } = useInsurancesContextConsumerHook(),
      { contractId = '' } = insurancesInfo?.insurances?.LPI ?? {};

    const { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...FinancingInsurancesCancellationLPIHandlers({
        ...AppTrackingHook(FinancingInsurancesCancellationLPITrackingHandlers),
        contractId,
        progressId,
        setChallenged,
        setFetching,
        setFetchPPI,
        setProgressId,
        setResult,
      }),
      challenged,
      contractId,
      fetching,
      formatDate,
      formatMessage,
      insurancesInfo,
    };
  };

export { useFinancingInsurancesCancellationLPI };
