// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { PersonalSidebarCCComponent } from 'containers/personal/components/sidebar-cc/personal-sidebar-cc.component';
// Constants
import {
  PROFILE_CONSENTS_SIDEBAR_DESCRIPTION,
  SIDEBAR_INFO_PROPS,
} from './constants/profile-consents-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import {
  SIDE_BAR_DESCRIPTION,
  SIDE_BAR_TITLE,
} from './translations/profile-consents-sidebar.translations';

const ProfileConsentsSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <PersonalSidebarCCComponent />
    <SidebarComponent
      {...SIDEBAR_INFO_PROPS}
      title={<FormattedMessageComponent id={SIDE_BAR_TITLE} />}
    >
      <ParagraphComponent {...PROFILE_CONSENTS_SIDEBAR_DESCRIPTION}>
        <FormattedMessageComponent id={SIDE_BAR_DESCRIPTION} />
      </ParagraphComponent>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default ProfileConsentsSidebarComponent;
