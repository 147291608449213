// Contexts
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { TotalAmountHookType } from './types/total-amount.hook.type';

const TotalAmountHook = (): TotalAmountHookType => {
  const { formatMessage, formatNumber } = useCbIntl();
  const { fastCashCommission, amountValue, disableNextBtn } =
    FinancingFastCashContextConsumerHook();

  return {
    amountValue,
    showValues: !disableNextBtn && !!amountValue,
    currency: fastCashCommission?.currency,
    commission: fastCashCommission?.value,
    formatMessage,
    formatNumber,
  };
};

export default TotalAmountHook;
