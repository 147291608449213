import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';
// Vendors
import styled from 'styled-components';

export const Step6CreditLimitIncreaseTextSectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const Step6CreditLimitIncreaseContentFirstSetionStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const Step6CreditLimitIncreaseContentSecondSetionStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const Step6CreditLimitIncreaseContentInfoSetionStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export const Step6CreditLimitIncreaseWebIdDisclaimerStyled = styled.div`
  width: 100%;
  height: ${convertPxsToRems({ pixels: 100, base: 16 })};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    gap: ${({ theme }) => theme.spaces.spacingXxs};
  }
`;

export const LoeaderComponentContainerStyled = styled.div`
  left: 50%;
  top: 50%;
  position: relative;
`;
