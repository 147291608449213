// Vendors
import React from 'react';
import { ParagraphComponent, LinkComponent } from '@openbank/cb-ui-commons';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { EditableInfoPanelConstants } from './constants/editable-info-panel.constants';
// Styles
import {
  BottomLinkWrapperStyled,
  EditableInfoPanelWrapperStyled,
  InfoPanelRowStyled,
} from './editable-info-panel.component.styled';
// Translations
import translations from '../step2/translations/step-2-credit-limit-increase.translations';
// Types
import { EditableInfoPanelPropsType } from './types/editable-info-panel.component.type';

const EditableInfoPanelComponent = ({
  key = EditableInfoPanelConstants.testId,
  content,
  onEdit,
}: EditableInfoPanelPropsType): React.ReactElement => {
  return (
    <EditableInfoPanelWrapperStyled>
      {content.map((item, index) => (
        <InfoPanelRowStyled key={`${key}${index}`}>
          <ParagraphComponent bold>{item.title}</ParagraphComponent>
          <ParagraphComponent>{item.text}</ParagraphComponent>
        </InfoPanelRowStyled>
      ))}
      {onEdit && (
        <BottomLinkWrapperStyled>
          <LinkComponent {...EditableInfoPanelConstants.EDIT_ASSET_PROPS} onClick={onEdit}>
            <FormattedMessageComponent id={translations.EDIT} />
          </LinkComponent>
        </BottomLinkWrapperStyled>
      )}
    </EditableInfoPanelWrapperStyled>
  );
};

export default EditableInfoPanelComponent;
