// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { useTransactionsReportNotReceivedContextConsumerHook } from 'containers/transactions/views/report/components/not-received/contexts/transactions-report-not-received.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { TransactionsReportNotReceivedConfirmationActionsHandlers } from '../handlers/transactions-report-not-received-confirmation-actions.handlers';
// Hooks
import { useCleanChallengeHook } from 'containers/transactions/views/report/hooks/clean-challenge.hook';
// Types
import { TransactionsReportNotReceivedConfirmationActionsHookReturnType } from './types/transactions-report-not-received-confirmation-actions.hook.type';

export const useTransactionsReportNotReceivedConfirmationActionsHook =
  (): TransactionsReportNotReceivedConfirmationActionsHookReturnType => {
    const { notReceivedDate, description, files, setNotReceivedDate, setDescription, setFiles } =
      useTransactionsReportNotReceivedContextConsumerHook();
    const { challenge, challengeError, setChallengeError, setCaseId, setError } =
      useTransactionsReportContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    useCleanChallengeHook();

    return {
      ...TransactionsReportNotReceivedConfirmationActionsHandlers({
        notReceivedDate,
        description,
        cardId,
        challenge,
        files,
        setNotReceivedDate,
        setDescription,
        setCaseId,
        setChallengeError,
        setError,
        setFiles,
        setResult,
        transactionId,
      }),
      challenge,
      challengeError,
    };
  };
