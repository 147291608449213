// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent, MessageComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CHANGE_IBAN_SIDEBAR_MESSAGE_01_PROPS,
  CHANGE_IBAN_SIDEBAR_MESSAGE_02_PROPS,
  CHANGE_IBAN_SIDEBAR_PROPS,
} from './constants/change-iban-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import {
  ChangeIbanSidebarComponentContainerStyled,
  ChangeIbanSidebarItemComponentStyled,
  ChangeIbanSidebarMessageStyled,
} from './change-iban-sidebar.component.styled';
// Translations
import translations from './translations/change-iban-sidebar.translations';

const ChangeIbanSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...CHANGE_IBAN_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <ChangeIbanSidebarComponentContainerStyled>
        <ChangeIbanSidebarItemComponentStyled>
          <ParagraphComponent {...CHANGE_IBAN_SIDEBAR_MESSAGE_01_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_01} />
          </ParagraphComponent>
        </ChangeIbanSidebarItemComponentStyled>
        <MessageComponent {...CHANGE_IBAN_SIDEBAR_MESSAGE_02_PROPS}>
          <ChangeIbanSidebarMessageStyled>
            <ParagraphComponent>
              <FormattedMessageComponent id={translations.MESSAGE_02} />
            </ParagraphComponent>
          </ChangeIbanSidebarMessageStyled>
        </MessageComponent>
      </ChangeIbanSidebarComponentContainerStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export { ChangeIbanSidebarComponent };
