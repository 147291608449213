const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cardsLanding.cbToggleInfo.';

export default {
  ACTIVE_UNTIL: `${DOMAIN}activeUntil`,
  ON: `${DOMAIN}onLabel`,
  OFF: `${DOMAIN}offLabel`,
  SWITCHOFF: `${DOMAIN}switchOff`,
  SWITCHON: `${DOMAIN}switchOn`,
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING: `${DOMAIN}cardLabel`,
  SWITCH: `${DOMAIN}switch`,
  TRAVEL_PLUS: `${DOMAIN}travelLabel`,
};
