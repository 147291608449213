// Constants
import constants from './constants/personal-email-step3.challenge.constants';
import serviceConstants from 'containers/personal/views/email/components/step3/components/challenge/services/constants/personal-email-step3-challenge.service.constants';
// Types
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
import { GetChallengeConfigurationReturnType } from './types/get-challenge-configuration-return.type';

export const getChallengeConfiguration = ({
  customerId,
  challengeError,
  email,
  handleChallengeError,
  handleChallengeSuccess,
  handleChallengeRetryButtonClick,
}: GetChallengeConfigurationType): GetChallengeConfigurationReturnType => ({
  baseService: serviceConstants.VALIDATE_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: { pathParams: { customerId }, params: { email } },
  baseServiceMethod: constants.EMAIL_CHALLENGE_PROPS.baseServiceMethod,
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
