// Vendors
import styled from 'styled-components';

const CardSettingsCheck3dConfirmationMessageComponentStyled = styled.section`
  margin-top: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;
CardSettingsCheck3dConfirmationMessageComponentStyled.displayName =
  'CardSettingsCheck3dConfirmationMessageComponentStyled';

const CardSettingsCheck3dConfirmationMessageContentComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
`;
CardSettingsCheck3dConfirmationMessageContentComponentStyled.displayName =
  'CardSettingsCheck3dConfirmationMessageContentComponentStyled';

export {
  CardSettingsCheck3dConfirmationMessageComponentStyled,
  CardSettingsCheck3dConfirmationMessageContentComponentStyled,
};
