// Enumerations
import { TrackingEventsEnum } from '@openbank/cf-ui-static-data';
// Types
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { ToggleNotificationTrackingPropsType } from './types/toggle-notification-tracking.handler.type';
import { ChangeControllerTrackingPropsType } from './types/change-controller-tracking.handler.type';
import { NotificationsSettingsListBuilderReturnTrackingHandlersType } from './types/notifications-settings-list-builder-return.tracking.handlers.type';

const toggleNotificationTracking = ({
  notificationType,
  isOn,
}: ToggleNotificationTrackingPropsType): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickToggle',
    eventLabel: `${notificationType} ${isOn ? 'on' : 'off' } Toggle`,
  },
  type: TrackingEventsEnum.LINK,
});

const changeControllerTracking = ({
  amount,
}: ChangeControllerTrackingPropsType): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickTextBox',
    eventLabel: `${amount} TextBox`,
  },
  type: TrackingEventsEnum.LINK,
});

const showToolTipTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickToolTip',
    eventLabel: 'tooltip',
  },
  type: TrackingEventsEnum.LINK,
});

const saveChangesClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationSaveChangesButton',
  },
  type: TrackingEventsEnum.LINK,
});

const saveAndExitModalTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'saveNewSettings',
    eventLabel: 'navigationSaveAndExitButton',
  },
  type: TrackingEventsEnum.LINK,
});

const goBackButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationCancelLink',
  },
  type: TrackingEventsEnum.LINK,
});

const successNotificationsSettingsSave = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'successSnackbarNotificationSettingsSaved',
    eventLabel: 'view',
  },
  type: TrackingEventsEnum.LINK,
});

const errorNotificationsSettingsSave = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'errorSnackbarUnableToSaveNotificationSettings',
    eventLabel: 'view',
  },
  type: TrackingEventsEnum.LINK,
});

const NotificationsSettingsTrackingHandlers = (
  track: TrackBuilderType
): NotificationsSettingsListBuilderReturnTrackingHandlersType => ({
  handleToggleNotificationTracking: (notificationType, isOn) =>
    track(toggleNotificationTracking({ notificationType, isOn })),
  handleChangeControllerTracking: (amount) =>
    track(changeControllerTracking({ amount })),
  handleShowToolTipTracking: () => track(showToolTipTracking()),
  handleSaveChangesClickTracking: () => track(saveChangesClickTracking()),
  handleSaveAndExitModalTracking: () => track(saveAndExitModalTracking()),
  handleGoBackButtonClickTracking: () => track(goBackButtonClickTracking()),
  handleSuccessNotificationsSettingsSaveTracking: () => track(successNotificationsSettingsSave()),
  handleErrorNotificationsSettingsSaveTracking: () => track(errorNotificationsSettingsSave()),
});

export { NotificationsSettingsTrackingHandlers };