// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const NotificactionsLandingViewStyled = styled.section.attrs(() => ({
  'data-testid': 'notifications-landing-component',
}))`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) =>
    `${theme.spaces.spacingM} ${theme.spaces.spacingXxxxxl} ${theme.spaces.spacingL}`};

  ${mobileMediaQuery} {
    padding: ${({ theme }) =>
      `${theme.spaces.spacingM} ${theme.spaces.spacingS} ${theme.spaces.spacingM}`};
  }
`;

export const NotificactionsLandingViewTitleStyled = styled.section`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const NotificactionsLandingViewContentStyled = styled.div``;

export const NotificactionsLandingViewNotificationsStyled = styled.div`
  margin: 0;
  padding: 0 ${({ theme }) => theme.spaces.spacingXs};
`;

export const NotificactionsLandingViewFiltersStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
