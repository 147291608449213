// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import {
  useRecoveryKeyContextConsumerHook,
  useRecoveryOptionContextConsumerHook,
} from 'containers/recovery-key/contexts/recovery-key.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Event handlers
import RecoveryKeyStep3Handlers from '../handlers/recovery-key-step3.handlers';
import RecoveryKeyStep3TrackingHandlers from '../handlers/recovery-key-step3.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RecoveryKeyStep3HookType } from './types/recovery-key-step3.hook.type';
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';
import { useRecoveryKey } from 'containers/recovery-key/hooks/recovery-key.hook';

const useRecoveryKeyStep3Hook = (): RecoveryKeyStep3HookType => {
  const { formatMessage } = useCbIntl();
  const [open, setOpen] = useState<boolean>(false);
  const { authData } = useRecoveryKeyContextConsumerHook(),
    { recoveryOption, setRecoveryOption } = useRecoveryOptionContextConsumerHook(),
    { setCurrentStep } = useWizardContextConsumerHook(),
    {
      passwordRecoveryOptions = [
        PasswordRecoveryOptionsEnumeration.CREDIT_CARD,
        PasswordRecoveryOptionsEnumeration.SECURITY_QUESTIONS,
        PasswordRecoveryOptionsEnumeration.SIGNATURE_KEY,
      ],
    } = (authData as PasswordRecoveryOptionsApiResponseType) || {};
  const { handleSetRecoveryOption } = useRecoveryKey({});

  return {
    ...RecoveryKeyStep3Handlers({
      ...AppTrackingHook(RecoveryKeyStep3TrackingHandlers),
      setCurrentStep,
      setRecoveryOption,
    }),
    handleSetRecoveryOption,
    formatMessage,
    passwordRecoveryOptions,
    recoveryOption,
    open,
    onCloseModal: () => {
      setOpen(false);
    },
    onInfoLickClick: () => {
      setOpen(true);
    },
  };
};

export default useRecoveryKeyStep3Hook;
