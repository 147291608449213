// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Resolvers
import { selectLimitResolver } from '../resolvers/step-1-select-limit.resolvers';
// Types
import { CreditLimitChangeHandlersReturnType } from './types/step-1-credit-limit-change-handlers-return.type';
import { CreditLimitChangeHandlersType } from './types/step-1-credit-limit-change-handlers.type';
// Utils
import { mapPersonalDataToLocal } from './../services/utils/step-2-laboral-economic-data.utils';
import { validateLaboralAndEmploymentData } from './utils/step-2-validators.utils';

const selectCreditLimitHandler = async ({
  creditLimitContext,
  currentCard,
  setCurrentStep,
}: CreditLimitChangeHandlersType): Promise<void> => {
  const { newCreditLimit } = creditLimitContext.step1;
  const { id: cardId } = currentCard || {};
  const { setStep1, setStep2, step1, step2, wizardStatus, setWizardStatus } = creditLimitContext;

  setStep1({
    ...step1,
    isLoadingNextRequest: true,
  });

  const [result, error] = await selectLimitResolver({
    caseId: wizardStatus.caseId,
    cardId,
    newCreditLimit,
  });
  if (error) {
    setWizardStatus({
      ...wizardStatus,
      fetching: false,
      error: true,
    });
    setStep1({
      ...step1,
      isLoadingNextRequest: false,
    });
    setStep2({
      personalData: {},
      isEmploymentDataEditModeOn: true,
      isExpensesEditModeOn: true,
      isPersonalSituationEditModeOn: true,
    });
    return;
  }
  if (result) {
    const currentStep = StepEnumToIndexMap[result.nextStep];
    const currentStepInText = result.nextStep as CreditLimitIncreaseStepsEnumeration;
    const mappedData = mapPersonalDataToLocal(result.stepData.personalData);

    setStep1({
      ...step1,
      isLoadingNextRequest: false,
    });
    setStep2({
      ...step2,
      ...validateLaboralAndEmploymentData(mappedData),
    });
    setWizardStatus({ ...wizardStatus, currentStep, currentStepInText });
    setCurrentStep(currentStep);
  }
};

export const CreditLimitChangeHandlers = ({
  creditLimitContext,
  currentCard,
  setCurrentStep,
}: CreditLimitChangeHandlersType): CreditLimitChangeHandlersReturnType => ({
  handleSelectCreditLimit: () =>
    selectCreditLimitHandler({ creditLimitContext, currentCard, setCurrentStep }),
});
