// Vendors
import styled from 'styled-components';
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from '@openbank/cf-ui-framework';

const TransactionTableRowComponentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: center;
    gap: ${({ theme }) => theme.spaces.spacingS};
    overflow: hidden;
  }
`;

const TransactionTableRowLeftComponentStyled = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 0.2fr 1fr 1fr;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: ${({ theme }) => theme.spaces.spacingXxs};
  }
`;

export { TransactionTableRowComponentStyled, TransactionTableRowLeftComponentStyled };
