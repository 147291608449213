// Services
import { fetchCardDetailsService, fetchCardPanService } from '../services/card-detail.services';
// Types
import { CardTransactionType } from 'types/card-transaction.type';

const fetchCardDetailsResolver = async (
  cardId: string
): Promise<[CardTransactionType] | [null, true]> => {
  const [response] = await fetchCardDetailsService(cardId);

  return response?.resource ? [response?.resource] : [null, true];
};

const fetchCardPanResolver = async (cardId: string): Promise<[string] | [null, true]> => {
  const [response] = await fetchCardPanService(cardId);

  return response?.resource?.pan ? [response?.resource?.pan] : [null, true];
};

export { fetchCardDetailsResolver, fetchCardPanResolver };
