// Resolvers
import { contractPPIResolver } from '../resolvers/financing-ppi-contracting-step3.resolver';
// Types
import {
  FinancingPPIContractingStep3BuilderHandlersType,
  FinancingPPIContractingStep3MethodsHandlersType,
  FinancingPPIContractingStep3SuccessBuilderHandlersType,
} from './types/financing-ppi-contracting-step3.handlers.type';
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';

const confirmContractPPIButtonClickHandler = async ({
  documentDownloaded,
  handleConfirmContractPPIButtonClickTracking,
  productContractId,
  productContractType,
  postalDocumentation,
  progressId,
  setErrorDocumentDownloaded,
  setFetching,
  setFetchPPI,
  setResult,
}: Omit<
  FinancingPPIContractingStep3BuilderHandlersType,
  'setChallenged' | 'setProgressId' | 'setDocumentDownloaded'
>): Promise<void> => {
  if (documentDownloaded) {
    setFetching(true);
    handleConfirmContractPPIButtonClickTracking();

    const [, error] = await contractPPIResolver({
      productContractId,
      productContractType,
      postalDocumentation,
      progressId,
    });

    setResult(!error);
    setFetchPPI(!error);
  } else {
    setErrorDocumentDownloaded(true);
  }
};

const contractPPIChallengeErrorEventHandler = (
  setResult: FinancingPPIContractingStep3BuilderHandlersType['setResult']
): void => {
  setResult(false);
};

const contractPPIChallengeSuccessEventHandler = ({
  setProgressId,
  setChallenged,
  progressId,
}: FinancingPPIContractingStep3SuccessBuilderHandlersType): void => {
  setProgressId(progressId);
  setChallenged(true);
};

const resetDocumentDownloadedEventHandler = (
  setDocumentDownloaded: FinancingPPIContractingStep3BuilderHandlersType['setDocumentDownloaded']
): void => {
  setDocumentDownloaded(false);
};

const FinancingPPIContractingStep3Handlers = ({
  setChallenged,
  setDocumentDownloaded,
  setProgressId,
  setResult,
  ...rest
}: FinancingPPIContractingStep3BuilderHandlersType): FinancingPPIContractingStep3MethodsHandlersType => ({
  handleConfirmContractPPIButtonClick: () =>
    confirmContractPPIButtonClickHandler({ ...rest, setResult }),
  handleContractPPIChallengeErrorEvent: () => contractPPIChallengeErrorEventHandler(setResult),
  handleContractPPIChallengeSuccessEvent: (props: ChallengeProgressIdType) =>
    contractPPIChallengeSuccessEventHandler({ ...props, setProgressId, setChallenged }),
  handleResetDocumentDownloadedEvent: () =>
    resetDocumentDownloadedEventHandler(setDocumentDownloaded),
});

export { FinancingPPIContractingStep3Handlers };
