// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SignatureKeyChangeStep1HandlersTrackingReturnType } from './types/signature-key-change-step1-handlers-tracking-return.type';

const passwordPositionInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickTextBox',
    eventLabel: 'currentSignatureKeyTextBox',
  },
  type: TrackingEventsEnum.LINK,
});

const showHidePasswordClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'showSignatureKeyLink',
  },
  type: TrackingEventsEnum.LINK,
});

const recoveryKeyInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'recoverSignatureKeyLink',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterFirstStepSignatureKeyChangeTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeSignatureKey/step01/currentSignatureKey',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'cards',
    interactionType: 'page',
    serviceType: 'productSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SecurityKeysSignatureKeyChangeFirstStepTrackingHandlers = (
  track: TrackBuilderType
): SignatureKeyChangeStep1HandlersTrackingReturnType => ({
  handlePasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickTrackingHandler()),
  handleRecoveryKeyInputClickTrackingHandler: () => track(recoveryKeyInputClickTrackingHandler()),
  handleOnEnterFirstStepSignatureKeyChangeTracking: () =>
    track(onEnterFirstStepSignatureKeyChangeTrackingHandler()),
  handleShowHidePasswordClickTracking: () => track(showHidePasswordClickTrackingHandler()),
});

export default SecurityKeysSignatureKeyChangeFirstStepTrackingHandlers;
