// Vendors
import { useEffect, useState } from 'react';
// Configurations
import { getCardSettingsBenefitsTravelPlusConfiguration } from '../configurations/travel-plus/card-settings-benefits-travel-plus.configuration';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { CardSettingsBenefitsLandingHandlers } from '../handlers/card-settings-benefits.handlers';
// Hooks
import CardSettingsTogglesHook from '../../landing/components/toggles/hooks/card-settings-toggles.hook';
import { useTravelPlusMessageHook } from 'components/travel-plus/components/links/hooks/travel-plus-links.hook';
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Types
import { CardSettingsBenefitsType } from './types/card-settings-benefits-travel.hook.type';
import {
  CardSettingsBenefitsLandingHookReturnType,
  CommercialCardConfigurationType,
} from './types/card-settings-benefits-return.hook.type';

const CardSettingsBenefitsLandingHook = (): CardSettingsBenefitsLandingHookReturnType => {
  const [benefits, setBenefits] = useState<CardSettingsBenefitsType | null>(null);
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(true);

  const { currentCard } = AppContextConsumerHook(),
    { cardContractId } = currentCard || {};

  const { handleToggleTravelPlusToggleChange, showTravelPlusModal, travelPlus } =
    CardSettingsTogglesHook();

  const { formatMessage, formatDate } = useCbIntl();

  const { handleOpenTravelPlusModalLinkClick } = useTravelPlusMessageHook();

  const { handleFetchBenefits } = CardSettingsBenefitsLandingHandlers({
    cardContractId,
    setBenefits,
    setError,
    setFetching,
  });

  useEffect(() => {
    handleFetchBenefits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [travelPlus]);

  return {
    commercialCardConfiguration: [
      getCardSettingsBenefitsTravelPlusConfiguration({
        benefits,
        formatMessage,
        formatDate,
        handleToggleTravelPlusToggleChange,
        handleOpenTravelPlusModalLinkClick,
        travelPlusInfo: benefits?.travelPlus,
      }) as CommercialCardConfigurationType,
    ],
    error,
    fetching,
    formatMessage,
    handleToggleTravelPlusToggleChange,
    navigate: useNavigate(),
    showTravelPlusModal,
  };
};

export { CardSettingsBenefitsLandingHook };
