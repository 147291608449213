const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.step1.';

const DESCRIPTION = `${DOMAIN}description`;

const NEXT_BUTTON = `${DOMAIN}nextButton`;

const CANCEL_LINK = `${DOMAIN}cancelLink`;

export { DESCRIPTION, NEXT_BUTTON, CANCEL_LINK };
