// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';
// Resolvers
import {
  fetchCardsResourcesResolver,
} from '../resolvers/cards.resolvers';
// Types
import { CardsBuilderReturnHandlersType } from './types/cards-builder-return.handlers.type';
import { CardsBuilderHandlersType } from './types/cards-builder.handlers.type';
// Utilities
import {
  extractAllActionsFromCardUi,
  extractAllQuickActionsFromCardUi,
} from './utils/cards.handlers.utils';
import { isNumber } from 'utils/type.utils';

const setupViewEventHandler = async ({
  setActions,
  setCard,
  setCards,
  setCardDetail,
  setCurrentCard,
  setFetching,
  setError,
  setErrorCard,
  setQuickActions,
}: CardsBuilderHandlersType): Promise<void> => {
  const [elements, error] = await fetchCardsResourcesResolver();
  
  if (isNumber(error)) {
    setErrorCard(true);
    setCard(false);
    setFetching(false);
    return;
  }
  
  if (error) {
    setError(ErrorCodesEnumeration.DEFAULT);
    setCard(false);
  } else if (elements) {
    setCardDetail(true);
    setCards(elements);
    const currentCard = elements[0];
    setCurrentCard(currentCard);
    setActions(extractAllActionsFromCardUi(currentCard.ui));
    setQuickActions(extractAllQuickActionsFromCardUi(currentCard.ui));
    setFetching(false);
    setCard(false);
  }
};

const CardsHandlers = (props: CardsBuilderHandlersType): CardsBuilderReturnHandlersType => ({
  handleSetupCardInformationEvent: () => setupViewEventHandler(props),
});

export default CardsHandlers;
