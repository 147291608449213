// Vendors
import React from 'react';
// Components
import { ConfirmationNextStepsComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CONFIRMATION_NEXT_STEPS_PROPS,
  NEXT_STEPS,
} from './constants/transactions-report-wizard-success.constants';
// Styles
import { TransactionsReportWizardSuccessComponentStyled } from './transactions-report-wizard-success.component.styled';
// Translations
import { TITLE } from './translations/transactions-report-wizard-success.translations';

export const TransactionsReportWizardSuccessComponent = (): React.ReactElement => (
  <TransactionsReportWizardSuccessComponentStyled>
    <ConfirmationNextStepsComponent
      {...CONFIRMATION_NEXT_STEPS_PROPS}
      steps={NEXT_STEPS.map(({ title, description }) => ({
        description: <FormattedMessageComponent id={description} />,
        title: <FormattedMessageComponent id={title} />,
      }))}
      title={<FormattedMessageComponent id={TITLE} />}
    />
  </TransactionsReportWizardSuccessComponentStyled>
);
