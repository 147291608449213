// Vendors
import styled from 'styled-components';
// Utilities
import { tabletMediaQuery } from '@openbank/cf-ui-framework';

export const IbanBillingLandingViewStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
