// Enums
import { ChildrenOptionsEnumeration } from '../enumerations/children-options.enumeration';
import { MaritalStatusEnumeration } from '../enumerations/marital-status.enumeration';

const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step2.personalSection.';

export default {
  [ChildrenOptionsEnumeration.FIVE_OR_MORE]: `${DOMAIN}fiveOrMore`,
  PERSONAL_SITUATION: `${DOMAIN}personalSituation`,
  MARITAL_STATUS: `${DOMAIN}maritalStatus`,
  [MaritalStatusEnumeration.DIVORCED]: `${DOMAIN}divorced`,
  [MaritalStatusEnumeration.SINGLE]: `${DOMAIN}single`,
  [MaritalStatusEnumeration.MARRIED]: `${DOMAIN}married`,
  [MaritalStatusEnumeration.SEPARATED]: `${DOMAIN}separated`,
  [MaritalStatusEnumeration.WIDOWED]: `${DOMAIN}widow`,
  [MaritalStatusEnumeration.UNMARRIED]: `${DOMAIN}unmarried`,
  NUMBER_OF_CHILDREN_H: `${DOMAIN}numberOfChildrenH`,
  NUMBER_OF_CHILDREN_H_PLACEHOLDER: `${DOMAIN}numberOfChildrenHPlaceholder`,
  NUMBER_OF_CHILDREN_P: `${DOMAIN}numberOfChildrenP`,
  NUMBER_OF_CHILDREN_P_PLACEHOLDER: `${DOMAIN}numberOfChildrenPPlaceholder`,
  NUMBER_OF_CHILDREN_P_TOOLTIP: `${DOMAIN}numberOfChildrenPTooltip`,
};
