// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { AccessPortabilityRequestBuilderReturnTrackingHandlersType } from './types/access-portability-request-builder-return.tracking.handlers.type';

const privacyPolicyLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'privacyPolicyLink',
  },
  type: TrackingEventsEnum.LINK,
});

const AccessPortabilityRequestTrackingHandlers = (
  track: (param: unknown) => void
): AccessPortabilityRequestBuilderReturnTrackingHandlersType => ({
  handlePrivacyPolicyLinkClickTracking: () => track(privacyPolicyLinkClickTrackingHandler()),
});

export default AccessPortabilityRequestTrackingHandlers;
