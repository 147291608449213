// Vendors
import React from 'react';
// Components
import DashboardSpentExpensesListComponent from '../../components/expenses-list/dashboard-expenses-list.component';
import DashboardSpentMessageComponent from '../../components/message/dashboard-spent-message.component';
// Constants
import constants from './constants/dashboard-spent-bottom-inner.constants';
// Styles
import { DashboardSpentBottomInnerComponentStyled } from './dashboard-spent-bottom-inner.component.styled';
// Types
import { DashboardSpentBottomInnerComponentType } from './types/dashboard-spent-bottom-inner.component.type';

const DashboardSpentBottomInnerComponent = ({
  expenses,
  spentMessageConfiguration,
  testId = constants.DASHBOARD_SPENT_BORTTOM_INNER_DEFAULT_TEST_ID,
}: DashboardSpentBottomInnerComponentType): React.ReactElement => (
  <DashboardSpentBottomInnerComponentStyled {...{ testId }}>
    <DashboardSpentMessageComponent {...{ spentMessageConfiguration }} />
    <DashboardSpentExpensesListComponent {...{ expenses }} />
  </DashboardSpentBottomInnerComponentStyled>
);

export default DashboardSpentBottomInnerComponent;
