// Types
import { ChallengeRetryHandlersReturnType } from './types/challenge-retry-return.handlers.type';
import { ChallengeRetryHandlersType } from './types/challenge-retry.handlers.type';

const retryChallengeButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryHandlersType): void => {
  setChallengeError(false);
};

export const ChallengeRetryHandlers = (
  props: ChallengeRetryHandlersType
): ChallengeRetryHandlersReturnType => ({
  handleRetryChallengeButtonClick: () => retryChallengeButtonClickHandler(props),
});
