// Vendors
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useFetchErrorContextConsumerHook } from 'contexts/fetch-error/fetch-error.context';
// Event handlers
import { TransactionsEppHandlers } from '../handlers/transactions-epp.handlers';
// Types
import { TransactionsEppHookType } from './types/transactions-epp.hook.type';
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';

const useTransactionsEppHook = (): TransactionsEppHookType => {
  const { transactionId } = useParams(),
    { formatMessage } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { fetching, error, setError, setFetching } = useFetchErrorContextConsumerHook(),
    { setTransactionDetails } = useTransactionsEppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  const { handleCheckInstallmentPlanAvailabilityEvent } = TransactionsEppHandlers({
    cardId,
    setFetching,
    setError,
    setTransactionDetails,
    transactionId,
  });

  useEffect(() => {
    handleCheckInstallmentPlanAvailabilityEvent();
  }, []);

  return {
    fetching,
    formatMessage,
    error,
  };
};

export { useTransactionsEppHook };
