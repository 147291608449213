const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.step4.signatureKey.';
const CHALLENGE_DOMAIN =
  'cobranded.services.userSettings.privateArea.cbChallengeModule.signatureKeyChallenge.';

const TITLE = `${DOMAIN}title`,
  DESCRIPTION = `${DOMAIN}description`;

const CHALLENGE_TRANSLATIONS = {
  BUTTON: `${CHALLENGE_DOMAIN}sendButton`,
  DESCRIPTION: `${CHALLENGE_DOMAIN}description`,
  ERROR: `${CHALLENGE_DOMAIN}errorDescription`,
  PLACEHOLDER: `${CHALLENGE_DOMAIN}cbInput.inputOtpPlaceholder`,
  TITLE: `${CHALLENGE_DOMAIN}title`,
};

export { TITLE, DESCRIPTION, CHALLENGE_TRANSLATIONS };
