// Themes
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { buttonConstants, linkConstants, titleConstants } from '@openbank/cb-ui-commons';

export default {
  CARD_SETTINGS_ACTIVATE_CARD_TITLE_PROPS: {
    tag: titleConstants.TAGS.H4,
    testId: 'card-settings-activate-card-title',
  },
  CARD_SETTINGS_ACTIVATE_CARD_CHALLENGE_NAVIGATION_FOOTER_PROPS: {
    leftLinkConfiguration: {
      type: linkConstants.TYPES.ACTION_SECONDARY,
      testId: 'card-settings-activate-card-back-button',
    },
    rightButtonConfiguration: {
      rwdFull: false,
      type: buttonConstants.TYPES.PRIMARY,
      testId: 'card-settings-activate-card-activate-button',
    },
    testId: 'card-settings-activate-card-challenge-navigation-footer',
  },
  CARD_SETTINGS_CHALLENGE_PROPS: {
    baseServiceMethod: 'post',
  },
};
