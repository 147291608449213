// Contexts
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
// Types
import { PersonalNumberHookType } from './types/personal-number.hook.type';

const PersonalNumberStep4Hook = (): PersonalNumberHookType => {
  const { newPhoneNumber, newPrefix, withEmail } = PersonalNumberContextConsumerHook();

  return {
    newPrefix,
    newPhoneNumber,
    withEmail,
  };
};

export default PersonalNumberStep4Hook;
