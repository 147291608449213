const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_01_PROPS = {
  testId: 'card-settings-contract-current-billing-tooltip-title-01',
  bold: true,
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_02_PROPS = {
  testId: 'card-settings-contract-current-billing-tooltip-title-02',
  bold: true,
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_01_PROPS = {
  testId: 'card-settings-contract-current-billing-tooltip-description-01',
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_02_PROPS = {
  testId: 'card-settings-contract-current-billing-tooltip-description-02',
};

const TOOLTIP_ASSET = {
  id: 'info',
  colors: {
    fill: 'linkPrimaryA',
  },
  sizes: {
    height: 16,
    width: 16,
  },
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_01_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_TITLE_02_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_01_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_TOOLTIP_DESCRIPTION_02_PROPS,
  TOOLTIP_ASSET,
};
