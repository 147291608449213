// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { PersonalEmailStep1RepeatBuilderReturnTrackingHandlersType } from './types/personal-email-step1-repeat-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const modifyEmailRepeatInputErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'repeatModifyEmail',
    eventLabel: 'repeatModifyEmail',
    errorCategory: 'invalidEmail',
    errorDescription: 'invalidEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const modifyEmailRepeatInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'repeatModifyEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalEmailStep1RepeatTrackingHandlers = (
  track: TrackBuilderType
): PersonalEmailStep1RepeatBuilderReturnTrackingHandlersType => ({
  handleModifyEmailRepeatInputClickTracking: () =>
    track(modifyEmailRepeatInputClickTrackingHandler()),
  handleModifyEmailRepeatInputErrorTracking: () =>
    track(modifyEmailRepeatInputErrorTrackingHandler()),
});

export default PersonalEmailStep1RepeatTrackingHandlers;
