// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const FinancingPaymentMethodFirstStepRevolvingOptionsComponentStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};

  & > * {
    max-width: ${convertPxsToRems({ pixels: 250, base: 14 })};
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};

    & > * {
      max-width: 100%;
    }
  }
`;
