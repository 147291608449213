// Vendors
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

const DashboardLastSessionComponentStyled = styled.footer`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;

  ${mobileMediaQuery} {
    margin: 0 ${({ theme }) => theme.spaces.spacingS};
  }

  ${tabletMediaQuery} {
    margin: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
DashboardLastSessionComponentStyled.displayName = 'DashboardLastSessionComponentStyled';

export { DashboardLastSessionComponentStyled };
