// Enumerations
import { AccessPortabilityTypeEnumeration } from '../enumerations/access-portability.enumeration';
// Services
import { getAccessPortabilityInformationService } from '../services/access-portability.service';
// Types
import { AccessPortabilityComponentType } from '../types/access-portability.component.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const getAccessPortabilityInformationResolver = async (
  rightsType: AccessPortabilityTypeEnumeration
): Promise<[AccessPortabilityComponentType] | [null, true]> => {
  const [response, { status }] = await getAccessPortabilityInformationService(rightsType);

  return wasSuccessRequest(status) ? [response] : [null, true];
};

export { getAccessPortabilityInformationResolver };
