// Vendors
import React from 'react';
// Components
import {
  MessageComponent,
  ParagraphComponent,
  ProductDetailComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { FormattedAddressComponent } from 'components/formatted-address/formatted-address.component';
// Constants
import {
  DESCRIPTION_PARAGRAPH_PROPS,
  MESSAGE_PROPS,
  PRODUCT_DETAIL_PARAGRAPH_PROPS,
  PRODUCT_DETAIL_PROPS,
} from './constants/personal-address-step2-summary.constants';
// Styles
import { PersonalAddressStep2SummaryComponentStyled } from './personal-address-step2-summary.component.styled';
// Translations
import {
  DESCRIPTION,
  SUMMARY_TITLE,
  MESSAGE_DESCRIPTION,
} from './translations/personal-address-step2-summary.translations';
// Types
import { PersonalAddressStep2SummaryComponentType } from './types/personal-address-step2-summary.component.type';

export const PersonalAddressStep2SummaryComponent = ({
  newAddress,
}: PersonalAddressStep2SummaryComponentType): React.ReactElement => (
  <>
    <ParagraphComponent {...DESCRIPTION_PARAGRAPH_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <PersonalAddressStep2SummaryComponentStyled>
      <ProductDetailComponent
        {...PRODUCT_DETAIL_PROPS}
        title={<FormattedMessageComponent id={SUMMARY_TITLE} />}
      >
        <FormattedAddressComponent
          address={newAddress}
          paragraphProps={PRODUCT_DETAIL_PARAGRAPH_PROPS}
        />
      </ProductDetailComponent>
    </PersonalAddressStep2SummaryComponentStyled>
    <MessageComponent {...MESSAGE_PROPS}>
      <ParagraphComponent>
        <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
      </ParagraphComponent>
    </MessageComponent>
  </>
);
