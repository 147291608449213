// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TransactionsTableHeaderLinkBarListLinksBuilderReturnTrackingHandlersType } from './types/transactions-table-header-link-bar-list-links.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const tableHeaderCCLinksLinkClickTracking = (url: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: `tableHeaderLink:${url}`,
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsTableHeaderLinkBarListLinksTrackingHandlers = (
  track: TrackBuilderType
): TransactionsTableHeaderLinkBarListLinksBuilderReturnTrackingHandlersType => ({
  handleTableHeaderCCLinksLinkClickTracking: (url: string) =>
    track(tableHeaderCCLinksLinkClickTracking(url)),
});

export { TransactionsTableHeaderLinkBarListLinksTrackingHandlers };
