// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { UPLOAD_DOCUMENT_ON_DB_PROPS } from './constants/personal-upload-documentation.services.constants';
// Types
import { RequestedDocumentsResponse } from '../types/requested-documents.type';
import { RequestedDocumentType } from '../types/requested-document.type';

const getRequestedDocuemnts = async (): Promise<[RequestedDocumentsResponse, Response]> =>
  apiUtilsCb.get({
    ...UPLOAD_DOCUMENT_ON_DB_PROPS,
  });

const reRequestDocuementsService = async (
  params: Pick<RequestedDocumentType, 'customerId' | 'documentId' | 'documentType'>
): Promise<[Record<string, unknown>, Response]> =>
  apiUtilsCb.put({
    ...UPLOAD_DOCUMENT_ON_DB_PROPS,
    params,
  });

const updateDocumentOnDBService = async ({
  customerId,
  documentId,
  documentType,
  signal,
}: RequestedDocumentType): Promise<[Record<string, unknown>, Response]> =>
  apiUtilsCb.patch({
    ...UPLOAD_DOCUMENT_ON_DB_PROPS,
    params: { customerId, documentId, documentType },
    signal,
  });

export { getRequestedDocuemnts, reRequestDocuementsService, updateDocumentOnDBService };
