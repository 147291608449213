// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from './configurations/support-cancel-contract-second-step-challenge.configurations';
// Hooks
import SupportCancelContractSecondStepChallengeHook from './hook/support-cancel-contract-second-step-challenge.hook';
// Styled
import { SupportCancelContractSecondStepChallengeComponentStyled } from './support-cancel-contract-second-step-challenge.component.styled';

const SupportCancelContractSecondStepChallengeComponent = (): React.ReactElement => {
  const { handleManageSuccessOnChangeSmsEvent, reason, cardContractId } =
    SupportCancelContractSecondStepChallengeHook();

  return (
    <SupportCancelContractSecondStepChallengeComponentStyled>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          handleChallengeSuccess: handleManageSuccessOnChangeSmsEvent,
          reason,
          cardContractId,
        })}
      />
    </SupportCancelContractSecondStepChallengeComponentStyled>
  );
};

export default SupportCancelContractSecondStepChallengeComponent;
