// Vendor
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { ERROR_CONFIGURATION_PROPS } from '../constants/notifications-landing-content.constants';
// Translations
import translations from '../translations/notifications-landing-content.translations';
// Types
import { NotificationsLandingConfigurationsReturnType } from '../types/notifications-landing-content.configurations.return.type';
import { NotificationsLandingConfigurationsPropsType } from '../types/notifications-landing-content.configurations.props.type';

const getFetchErrorLandingConfiguration = ({
  error,
  formatMessage,
  onClick
}: NotificationsLandingConfigurationsPropsType): NotificationsLandingConfigurationsReturnType => ({
  ...ERROR_CONFIGURATION_PROPS,
  error: Boolean(error),
  formatMessage,
  buttonConfiguration: {
    children: <FormattedMessageComponent id={translations.ERROR_DESCRIPTION} />,
    onClick
  }
});

export { getFetchErrorLandingConfiguration };