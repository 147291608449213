// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import { TITLE_SECTION_RWD_ASSET_PROPS } from './constants/title-section-rwd-asset.constants';
// Types
import { TitleSectionRwdComponentType } from '../../types/title-section-rwd.component.type';
// Utilities
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';

const TitleSectionRwdAssetComponent = ({
  assetConfiguration,
}: Pick<TitleSectionRwdComponentType, 'assetConfiguration'>): React.ReactElement | null =>
  assetConfiguration && !isSecondLevelRoutePath() ? (
    <AssetComponent {...{ ...TITLE_SECTION_RWD_ASSET_PROPS, ...assetConfiguration }} />
  ) : null;

export default TitleSectionRwdAssetComponent;
