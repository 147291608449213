// Enumerations
import { Check3dMessageTypesEnumeration } from '../../enumerations/check-3d-message-types.enumeration';
// Utilities
import { userHasTrustedDevice } from 'containers/card-settings/views/check-3d/components/confirmation/utils/card-settings-check-3d-confirmation.utils';

const getCheck3DMessageTypeAccordingTrustedDevice = (
  devices: any
): Check3dMessageTypesEnumeration =>
  userHasTrustedDevice(devices)
    ? Check3dMessageTypesEnumeration.WARNING
    : Check3dMessageTypesEnumeration.REMEMBER;

export { getCheck3DMessageTypeAccordingTrustedDevice };
