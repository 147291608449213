// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { MobileConnectorContextConsumerHook } from '../../mobile-connector/contexts/mobile-connector.provider.context';
// Event handlers
import { InactivityRefreshHandlers } from '../handlers/inactivity-refresh.handlers';
import { InactivityModalHandlers } from '../handlers/inactivity-modal.handlers';
import { InactivityModalTrackingHandlers } from '../handlers/inactivity-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { InactivityHookType } from './types/inactivity.hook.type';

const useInactivityHook = (): InactivityHookType => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(-1);

  const { handleSendToMobileAccessToken, isHybrid } = MobileConnectorContextConsumerHook();

  const { handleObserveRefreshValidityEvent } = InactivityRefreshHandlers({
      handleSendToMobileAccessToken,
      isHybrid,
      setShowModal,
      setSeconds,
    }),
    { handleManageModalCounterSecondsEvent, ...RestModalEventHandlers } = InactivityModalHandlers({
      ...AppTrackingHook(InactivityModalTrackingHandlers),
      handleObserveRefreshValidityEvent,
      handleSendToMobileAccessToken,
      isHybrid,
      seconds,
      setShowModal,
      setSeconds,
    });

  useEffect(() => {
    handleObserveRefreshValidityEvent();
  }, []);

  useEffect(() => {
    handleManageModalCounterSecondsEvent();
  }, [seconds]);

  return { ...RestModalEventHandlers, showModal, seconds };
};

export { useInactivityHook };
