// Vendors
import { useEffect } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsStatementsContextConsumerHook } from '../contexts/card-settings-statements.context';
import { useFetchErrorContextConsumerHook } from 'contexts/fetch-error/fetch-error.context';
// Event handlers
import { CardSettingsStatementsHandlers } from '../handlers/card-settings-statements.handlers';
// Types
import { CardSettingsStatementsHookType } from './types/card-settings-statements.hook.type';

const useCardSettingsStatementsHook = (): CardSettingsStatementsHookType => {
  const { fetching, error, setFetching, setError } = useFetchErrorContextConsumerHook(),
    { setStatements, statements: transactions } = useCardSettingsStatementsContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { cardContractId } = currentCard || {};

  const { handleFetchCardStatementEvent } = CardSettingsStatementsHandlers({
    cardContractId,
    setFetching,
    setError,
    setStatements,
  });

  useEffect(() => {
    handleFetchCardStatementEvent();
  }, []);

  return {
    fetching,
    error,
    transactions,
  };
};

export { useCardSettingsStatementsHook };
