// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/access-portability.context.constants';
import portabilityConstants from 'containers/personal/views/access-portability/views/request-portability/constants/request-portability.constants';
// Types
import { AccessPortabilityComponentType } from '../types/access-portability.component.type';
import { AccessPortabilityContextType } from './types/access-portability.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { RequestPortabilityParamsType } from '../types/request-portability-params.type';
// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
import { RequestEmailOptionEnumeration } from '../enumerations/access-portability.enumeration';

const AccessPortabilityContext = createContext<AccessPortabilityContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const AccessPortabilityContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<AccessPortabilityContextType>> => {
  const [information, setInformation] = useState<
    AccessPortabilityComponentType | Record<string, any>
  >({});
  const [showModal, setShowModal] = useState<boolean>(false);
  const [challenged, setChallenged] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');
  const [result, setResult] = useState<null | OperativeResultsEnumeration>(null);
  const [personalDataToggle, setPersonalDataToggle] = useState<boolean>(false);
  const [contractedProductsToggle, setContractedProductsToggle] = useState<boolean>(false);
  const [movementsCheckbox, setMovementsCheckbox] = useState<boolean>(false);
  const [mainCardCheckbox, setMainCardCheckbox] = useState<boolean>(false);
  const [beneficiaryCardCheckbox, setBeneficiaryCardCheckbox] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailCheckboxValue, setEmailCheckboxValue] =
    useState<RequestEmailOptionEnumeration | null>(null);
  const [portabilityParams, setPortabilityParams] = useState<RequestPortabilityParamsType | null>(
    portabilityConstants.REQUEST_PORTABILITY_DEFAULT_PARAMS
  );

  return (
    <AccessPortabilityContext.Provider
      value={{
        beneficiaryCardCheckbox,
        challenged,
        contractedProductsToggle,
        emailCheckboxValue,
        emailValue,
        error,
        fetching,
        information,
        mainCardCheckbox,
        movementsCheckbox,
        personalDataToggle,
        portabilityParams,
        progressId,
        result,
        setBeneficiaryCardCheckbox,
        setChallenged,
        setContractedProductsToggle,
        setEmailCheckboxValue,
        setEmailValue,
        setError,
        setFetching,
        setInformation,
        setMainCardCheckbox,
        setMovementsCheckbox,
        setPersonalDataToggle,
        setPortabilityParams,
        setProgressId,
        setResult,
        setShowModal,
        showModal,
      }}
    >
      {children as React.ReactNode}
    </AccessPortabilityContext.Provider>
  );
};

export const AccessPortabilityContextConsumerHook = (): AccessPortabilityContextType =>
  useContext(AccessPortabilityContext);

/* eslint-disable */
export const AccessPortabilityContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<AccessPortabilityContextType>> =>
    (
      <AccessPortabilityContextProvider>
        <Component {...props} />
      </AccessPortabilityContextProvider>
    );
