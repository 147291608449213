// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { FINANCING_PPI_CONTRACTING_DOWNLOAD_DOCUMENT_TRACKING_PROPS } from 'containers/financing/views/ppi/components/contracting/components/download/handlers/constants/financing-ppi-contracting.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingDownloadTrackingHandlersType } from 'containers/financing/views/ppi/components/contracting/components/download/handlers/types/financing-ppi-contracting-download.tracking.handlers.type';

const downloadContractingPPIDocumentClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...FINANCING_PPI_CONTRACTING_DOWNLOAD_DOCUMENT_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const FinancingPPIContractingDownloadTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingDownloadTrackingHandlersType => ({
  handleDownloadContractingPPIDocumentClickTracking: () =>
    track(downloadContractingPPIDocumentClickTrackingHandler()),
});

export { FinancingPPIContractingDownloadTrackingHandlers };
