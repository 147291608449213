export default {
  PROFILE_PERSONAL_NAME_TITLE_DROPDOWN_PROPS: {
    isRequired: true,
    testing: {
      placeholderId: 'profile-personal-name-title-dropdown-placeholder',
      optionsPlaceholderId: 'profile-personal-name-title-dropdown-options-placeholder',
      labelId: 'profile-personal-name-title-dropdown-label',
    },
  },
};
