// Translations
import optionsTranslations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title-dropdown-options.translations';
import translations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title.translations';
// Types
import { ProfilePersonalNameTitleDropdownApiMiscellaneousItemType } from '../types/profile-personal-name-title-dropdown-api-miscellaneous-item.type';
import { ProfilePersonalNameTitleDropdownUtilsType } from './types/profile-personal-name-title-dropdown.utils.type';
import { ProfilePersonalNameTitleInputHookType } from '../hooks/types/profile-personal-name-title-dropdown.hook.type';

const mapMiscellaneousTitles = ({
  formatMessage,
  titles,
}: Pick<
  ProfilePersonalNameTitleInputHookType,
  'formatMessage' | 'titles'
>): ProfilePersonalNameTitleDropdownUtilsType[] =>
  titles?.map(({ code: value }: ProfilePersonalNameTitleDropdownApiMiscellaneousItemType) =>
    Object.assign(
      { value },
      {
        label: optionsTranslations?.[value]
          ? formatMessage({ id: optionsTranslations[value] })
          : value,
      }
    )
  );

export { mapMiscellaneousTitles };
