// Vendors
import React from 'react';
// Components
import SettingsCardControlLocationsMainComponent from '../main/settings-card-control-locations-main.component';
import SettingsCardControlLocationsContinentsComponent from '../continents/settings-card-control-locations-continents.component';
// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from '../../contexts/settings-card-control-locations.context';
// Styles
import { SettingsCardControlLocationsRawComponentStyled } from './settings-card-control-locations-raw.component.styled';

const SettingsCardControlLocationsRawComponent = (): React.ReactElement | null => {
  const { continents } = SettingsCardControlLocationsContextConsumerHook();

  return (
    <SettingsCardControlLocationsRawComponentStyled>
      <SettingsCardControlLocationsMainComponent />
      <SettingsCardControlLocationsContinentsComponent {...{ continents }} />
    </SettingsCardControlLocationsRawComponentStyled>
  );
};

export default SettingsCardControlLocationsRawComponent;
