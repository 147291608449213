// Vendors
import React from 'react';
// Components
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import { TransactionsReportServiceCancelledDocumentsActionsComponent } from './components/actions/transactions-report-service-cancelled-documents-actions.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
// Contexts
import { useTransactionsReportServiceCancelledContextConsumerHook } from '../../contexts/transactions-report-service-cancelled.context';
// Styles
import { TransactionsReportServiceCancelledDocumentsComponentStyled } from './transactions-report-service-cancelled-documents.component.styled';

export const TransactionsReportServiceCancelledDocumentsComponent = (): React.ReactElement => {
  const { files, setFiles } = useTransactionsReportServiceCancelledContextConsumerHook();

  return (
    <TransactionsReportServiceCancelledDocumentsComponentStyled>
      <UploadDocumentsTipsComponent />
      <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
      <TransactionsReportServiceCancelledDocumentsActionsComponent
        files={files}
        setFiles={setFiles}
      />
    </TransactionsReportServiceCancelledDocumentsComponentStyled>
  );
};
