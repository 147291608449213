// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentBillingMethodValueHookType } from './types/card-settings-contract-current-billing-method-value.hook.type';

const useCardSettingsContractCurrentBillingMethodValueHook =
  (): CardSettingsContractCurrentBillingMethodValueHookType => {
    const { formatMessage } = useCbIntl();
    const { currentCardDetail } = AppContextConsumerHook(),
      { currencyCode } = currentCardDetail,
      {
        fixedPaymentAmount = 0,
        paymentMethodCode = CharactersEnum.NO_VALUE,
        accountBalancePercentage = 0,
      } = currentCardDetail?.creditDetails || {};

    return {
      accountBalancePercentage,
      currencyCode,
      fixedPaymentAmount,
      formatMessage,
      paymentMethodCode,
    };
  };

export default useCardSettingsContractCurrentBillingMethodValueHook;
