// Contexts
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodFirstStepRevolvingOptionsHandlers from '../handlers/financing-payment-method-first-step-revolving-options.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingPaymentMethodFirstStepRevolvingOptionsReturnHookType } from './types/financing-payment-method-first-step-revolving-options-return.hook.type';

const FinancingPaymentMethodFirstStepRevolvingOptionsHook =
  (): FinancingPaymentMethodFirstStepRevolvingOptionsReturnHookType => {
    const { formatMessage } = useCbIntl();

    const { revolvingOptions, setFee, revolvingMethod, setRevolvingMethod } =
      FinancingPaymentMethodContextConsumerHook();

    return {
      ...FinancingPaymentMethodFirstStepRevolvingOptionsHandlers({
        revolvingOptions,
        setFee,
        setRevolvingMethod,
      }),
      formatMessage,
      revolvingMethod,
    };
  };

export default FinancingPaymentMethodFirstStepRevolvingOptionsHook;
