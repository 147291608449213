//Handlers
import { FilterChipsHandlers } from '../handlers/filter-chips.handlers';
//Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FilterChipsHookPropsType, FilterChipsReturnType } from './types/filter-chip.hooks.type';

const FilterChipsHook = ({
  setCopyOfFormValues,
  setFormValues,
  setFormValuesParams,
}: FilterChipsHookPropsType): FilterChipsReturnType => {
  const { formatMessage } = useCbIntl();

  const { handleClearFilterModal, handleClickChip } = FilterChipsHandlers({
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
  });

  return {
    formatMessage,
    handleClearFilterModal,
    handleClickChip,
  };
};

export { FilterChipsHook };
