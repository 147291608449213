const DOMAIN_PAYMENT_METHOD_FIRST_STEP_PAYMENT =
  'cobranded.services.userSettings.privateArea.financing.paymentMethod.first.payment.';

const TITLE = `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_PAYMENT}title`;

const DESCRIPTION = {
  FIRST_PARAGRAPH: `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_PAYMENT}description.firstParagraph`,
  SECOND_PARAGRAPH: `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_PAYMENT}description.secondParagraph`,
};

export { TITLE, DESCRIPTION };
