// Constants
import checkCvvPinConstants from '../constants/check-cvv-pin.constants';
import checkCvvPinTrackingConstants from '../constants/check-cvv-pin-tracking.constants';
// Enumerations
import { TrackingEventsEnum } from '@openbank/cf-ui-static-data';
// Types
import { ScreenViewType } from '../types/screen-view-type.type';
import { getTrackingHandlerProps, TrackingFn, TrackingHandlers } from './types/handlers.type';

const { CVV } = checkCvvPinConstants;

const getSecurityVerificationHandlers = ({
  track,
}: getTrackingHandlerProps): TrackingHandlers['securityVerification'] => {
  const onCancel = () => {
    track({
      payload: checkCvvPinTrackingConstants.securityVerificationOnCancel,
      type: TrackingEventsEnum.LINK,
    });
  };

  const onContinue = () => {
    track({
      payload: checkCvvPinTrackingConstants.securityVerificationOnContinue,
      type: TrackingEventsEnum.LINK,
    });
  };

  const onError = () => {
    track({
      payload: checkCvvPinTrackingConstants.securityVerificationOnError,
      type: TrackingEventsEnum.LINK,
    });
  };

  const onSecurityError = () => {
    track({
      payload: checkCvvPinTrackingConstants.securityVerificationOnSecurityError,
      type: TrackingEventsEnum.LINK,
    });
  };

  const onLoad = () => {
    track({
      type: TrackingEventsEnum.VIEW,
    });
  };

  const onSuccess = () => {
    track({
      payload: checkCvvPinTrackingConstants.securityVerificationOnSuccess,
      type: TrackingEventsEnum.LINK,
    });
  };

  const onTryAgain = () => {
    track({
      payload: checkCvvPinTrackingConstants.securityVerificationOnTryAgain,
      type: TrackingEventsEnum.LINK,
    });
  };

  return {
    onCancel,
    onContinue,
    onError,
    onLoad,
    onSecurityError,
    onSuccess,
    onTryAgain,
  };
};

const getViewCodeHandlers = ({
  track,
  type,
}: getTrackingHandlerProps): TrackingHandlers['viewCode'] => {
  const onGoToCardSettings = () => {
    track({
      payload: checkCvvPinTrackingConstants.viewCodeOnGoToCardSettings,
      type: TrackingEventsEnum.LINK,
    });
  };

  const onShowCode = () => {
    const eventLabel = type === CVV ? 'showCVVButton' : 'showPINButton';

    track({
      payload: {
        ...checkCvvPinTrackingConstants.viewCodeOnShowCode,
        eventLabel,
      },
      type: TrackingEventsEnum.LINK,
    });
  };

  const onShowCodeAgain = () => {
    const eventLabel = type === CVV ? 'showCVVAgainButton' : 'showPINAgainButton';

    track({
      payload: {
        ...checkCvvPinTrackingConstants.viewCodeOnShowCodeAgain,
        eventLabel,
      },
      type: TrackingEventsEnum.LINK,
    });
  };

  return {
    onGoToCardSettings,
    onShowCode,
    onShowCodeAgain,
  };
};

const getKoMessageHandlers = ({
  track,
  type,
}: getTrackingHandlerProps): TrackingHandlers['koMessage'] => {
  const onLoad = () => {
    const processType = type === CVV ? 'checkCVV' : 'checkPIN';
    const errorCategory = `/${processType}/error/notPossibleToCheck`;

    track({
      payload: {
        ...checkCvvPinTrackingConstants.koMessageOnLoad,
        processType,
        errorCategory,
      },
      type: TrackingEventsEnum.VIEW,
    });
  };

  const onTryAgain = () => {
    track({
      payload: checkCvvPinTrackingConstants.koMessageOnTryAgain,
      type: TrackingEventsEnum.LINK,
    });
  };

  return {
    onLoad,
    onTryAgain,
  };
};

export const getTrackingHandlers =
  (type: ScreenViewType) =>
  (track: TrackingFn): TrackingHandlers => ({
    securityVerification: getSecurityVerificationHandlers({ track, type }),
    viewCode: getViewCodeHandlers({ track, type }),
    koMessage: getKoMessageHandlers({ track, type }),
  });
