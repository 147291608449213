const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.additional.notCovered.';

const TITLE = `${DOMAIN}title`;

const INCAPACITY = {
  title: `${DOMAIN}incapacity.title`,
  firstCase: `${DOMAIN}incapacity.firstCase`,
  secondCase: `${DOMAIN}incapacity.secondCase`,
};

const UNEMPLOYMENT = {
  title: `${DOMAIN}unemployment.title`,
  firstCase: `${DOMAIN}unemployment.firstCase`,
  secondCase: `${DOMAIN}unemployment.secondCase`,
};

const ILLNESS = {
  title: `${DOMAIN}illness.title`,
  firstCase: `${DOMAIN}illness.firstCase`,
};

export { TITLE, INCAPACITY, UNEMPLOYMENT, ILLNESS };
