// Resolvers
import { fetchCardControlLocationsResolver } from '../resolvers/settings-card-control-locations.resolvers';
// Types
import { SettingsCardControlLocationsBuilderHandlersType } from './types/settings-card-control-locations-builder.handlers.type';
import { SettingsCardControlLocationsBuilderReturnHandlersType } from './types/settings-card-control-locations-builder-return.handlers.type';

const fetchCardControlLocationsEventHandler = async ({
  cardId,
  setContinents,
  setFetching,
  setError,
  setMainCountry,
}: SettingsCardControlLocationsBuilderHandlersType): Promise<void> => {
  setFetching(true);

  const [response, error] = await fetchCardControlLocationsResolver(cardId);

  if (error) {
    setError(true);
  } else if (response) {
    const { continents, ...rest } = response;

    setContinents(continents);
    setMainCountry(rest);
  }

  setFetching(false);
};

const SettingsCardControlLocationsHandlers = (
  props: SettingsCardControlLocationsBuilderHandlersType
): SettingsCardControlLocationsBuilderReturnHandlersType => ({
  handleFetchCardControlLocationsEvent: () => fetchCardControlLocationsEventHandler(props),
});

export default SettingsCardControlLocationsHandlers;
