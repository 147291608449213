// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
// Constants
import { CONTROLLED_LOGOUT } from 'constants/storage/storage.constants';
// Enumerations
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';

const cookieWasRemoveAwareMode = (id: StorageCookiesEnum): boolean => !cookies.getCookie(id);

const cookieWasRemovedWithoutReason = (id: StorageCookiesEnum): boolean =>
  cookieWasRemoveAwareMode(id) && !localStorage.getItem(CONTROLLED_LOGOUT);

export { cookieWasRemoveAwareMode, cookieWasRemovedWithoutReason };
