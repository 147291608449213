// Constants
import {
  ASCENDING_DESCENDING_REGEX,
  CONSECUTIVE_NUMBERS_REGEX,
  ONLY_4_NUMBERS,
} from './constants/constraints.utils.constants';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { ConstraintsUtilsType } from './types/constraints.utils.type';

const checkHasFourNumbersOnly = (value: string): boolean => Boolean(value.match(ONLY_4_NUMBERS));

const checkNoAscendingDescendingSequences = (value = ''): boolean =>
  Boolean(value.match(ASCENDING_DESCENDING_REGEX));

const checkNoContain3OrMoreConsecutiveAndEqualsNumbers = (value = ''): boolean =>
  value.match(CONSECUTIVE_NUMBERS_REGEX) !== null;

const checkNoMatchCustomerBirthDate = ({ dateOfBirth, value }: ConstraintsUtilsType): boolean =>
  dateOfBirth?.split(CharactersEnum.NO_VALUE).every((date: string) => !value.includes(date));

export {
  checkHasFourNumbersOnly,
  checkNoAscendingDescendingSequences,
  checkNoContain3OrMoreConsecutiveAndEqualsNumbers,
  checkNoMatchCustomerBirthDate,
};
