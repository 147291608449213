// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ButtonComponent,
  DividerComponent,
  LinkComponent,
  PasswordModuleComponent,
} from '@openbank/cb-ui-commons';
import LoginGapsTitleComponent from './components/title/login-gaps-title.component';
import { LoginErrorComponent } from '../../components/error/login-error.component';
import { LoginAccessDataComponent } from 'containers/login/components/access-data/login-access-data.component';
import { CaptchaComponent } from 'components/captcha/captcha.component';
// Constants
import constants from './constants/login-email.constants';
// Enums
import { LoginErrorEnum } from 'containers/login/enumerations/login.enumeration';
// Hooks
import { useLoginGaps } from 'containers/login/views/gaps/hooks/login-gaps.hook';
import { useLoginAccessData } from 'containers/login/components/access-data/hooks/login-access-data.hook';
// Styles
import { LoginFormContentChildrenContainerStyled } from '../../login.container.styled';
import {
  LoginGapsInputViewStyled,
  LoginGapsViewStyled,
} from 'containers/login/views/gaps/login-gaps.view.styled';
// Translations
import {
  ACCESS_DATA,
  ONBOARDING_INCOMPLETE_ERROR,
  PASSWORD,
} from '../../translations/login.translations';
// Utilities
import { isContinueButtonDisabled } from './utils/login-gaps.utils';

const LoginGapsView = (): React.ReactElement => {
  const {
    captcha,
    fetching,
    formatMessage,
    error,
    handleForgetPasswordLinkClick,
    handleContinuePasswordGapsButtonClick,
    handlePasswordPositionInputClickTracking,
    handleSaveUserPasswordInputEvent,
    password,
  } = useLoginGaps();
  const { handleIdenfityButtonClick } = useLoginAccessData();
  const isOnboardingIncomplete = error === LoginErrorEnum.ONBOARDING_INCOMPLETE;

  return (
    <LoginGapsViewStyled>
      <LoginGapsTitleComponent />
      <LoginFormContentChildrenContainerStyled>
        <LoginGapsInputViewStyled>
          <PasswordModuleComponent
            {...constants.LOGIN_GAPS_INPUT_PROPS}
            errorMessage={Boolean(error)}
            disabled={fetching}
            onChange={handleSaveUserPasswordInputEvent}
            onClick={handlePasswordPositionInputClickTracking}
            title={formatMessage({ id: PASSWORD.INPUT_LABEL })}
            value={password}
          />
          <LinkComponent
            {...constants.LOGIN_GAPS_LINK_PROPS}
            disabled={fetching}
            onClick={handleForgetPasswordLinkClick}
          >
            <FormattedMessageComponent id={PASSWORD.FORGET_PASSWORD} />
          </LinkComponent>
          <LoginErrorComponent
            message={isOnboardingIncomplete ? ONBOARDING_INCOMPLETE_ERROR : PASSWORD.ERROR_INPUT}
            error={error}
            link={
              isOnboardingIncomplete ? (
                <LinkComponent onClick={handleIdenfityButtonClick}>
                  <FormattedMessageComponent id={ACCESS_DATA.BUTTON} />
                </LinkComponent>
              ) : undefined
            }
          />
          <CaptchaComponent {...constants.CAPTCHA_PROPS} />
        </LoginGapsInputViewStyled>
        <ButtonComponent
          {...constants.LOGIN_EMAIL_BUTTON_PROPS}
          disabled={isContinueButtonDisabled({ captcha, fetching, password, error })}
          loading={fetching}
          onClick={handleContinuePasswordGapsButtonClick}
        >
          <FormattedMessageComponent id={PASSWORD.BUTTON} />
        </ButtonComponent>
      </LoginFormContentChildrenContainerStyled>
      <DividerComponent {...constants.LOGIN_EMAIL_DIVIDER_PROPS} />
      <LoginAccessDataComponent />
    </LoginGapsViewStyled>
  );
};

export default LoginGapsView;
