// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LINK_PROPS } from './constants/financing-ppi-contracting-step2-expandable-link.constants';
// Hooks
import { useFinancingPPIContractingStep2ExpandableLinkHook } from './hooks/financing-ppi-contracting-step2-expandable-link.hook';
// Types
import { FinancingPPIContractingStep2ExpandableLinkType } from './types/financing-ppi-contracting-step2-expandable-link.type';

const FinancingPPIContractingStep2ExpandableLinkComponent = ({
  chunks,
}: FinancingPPIContractingStep2ExpandableLinkType): React.ReactElement => {
  const { handleOpenPPIContractingTermsAndConditionsLinkClick } =
    useFinancingPPIContractingStep2ExpandableLinkHook();

  return (
    <LinkComponent
      {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LINK_PROPS}
      onClick={handleOpenPPIContractingTermsAndConditionsLinkClick}
    >
      {chunks}
    </LinkComponent>
  );
};

export { FinancingPPIContractingStep2ExpandableLinkComponent };
