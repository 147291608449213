// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Types
import { CardComponentType } from 'types/card.component.type';
import { FormatProductFamilyWithCardIdType } from './types/format-product-family-with-card-id.type';

const isValidCurrentCard = (currentCard: CardComponentType | Record<string, unknown>): boolean =>
  Boolean(currentCard?.cardContractId && currentCard?.cmsCardId);

const cardIsInactive = (status: string): boolean => status === CardStatusTypesEnumeration.INACTIVE;

const cardActive = (status: string): boolean => status === CardStatusTypesEnumeration.ACTIVE;

const formatProductFamilyWithCardId = ({
  cmsCardId,
  productFamily,
}: FormatProductFamilyWithCardIdType): string => `${productFamily} ...${cmsCardId}`;

export { cardActive, cardIsInactive, isValidCurrentCard, formatProductFamilyWithCardId };
