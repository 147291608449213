// Services
import { cancelPPIContractService } from '../services/financing-ppi-cancellation-landing-footer.service';
// Types
import { FinancingPPICancellationLandingFooterApiBuilderType } from '../types/financing-ppi-cancellation-landing-footer-api-builder.type';
// Utils
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const cancelPPIContractResolver = async (
  props: FinancingPPICancellationLandingFooterApiBuilderType
): Promise<[number] | [null, boolean]> => {
  const [, { status }] = await cancelPPIContractService(props);

  return wasSuccessRequest(status) ? [status] : [null, true];
};

export { cancelPPIContractResolver };
