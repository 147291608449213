// Vendors
import { useEffect, useState } from 'react';
// Constants
import { EMPTY_STRING } from '../constants/report-defective-additional-info.constants';
// Contexts
import { useReportDefectiveContextConsumerHook } from 'containers/transactions/views/report/components/defective/contexts/report-defective.context';
// Handlers
import { ReportDefectiveAdditionalInfoHandler } from '../handlers/report-defective-additional-info.handlers';
// Types
import { ReportDefectiveAdditionalInfoHookReturnProps } from './types/report-defective-additional-info.hook.type';

const ReportDefectiveAdditionalInfoHook = (): ReportDefectiveAdditionalInfoHookReturnProps => {
  const [selectedOption, setSelectedOption] = useState<string>(EMPTY_STRING);

  const { data, setData } = useReportDefectiveContextConsumerHook(),
    { defectiveProductService } = data || {},
    { defectProductReturnProductCommerce } = defectiveProductService;

  const existsReturnProductCommerce = () =>
    !Object.is(defectProductReturnProductCommerce, undefined);

  return {
    existsReturnProductCommerce,
    defectProductReturnProductCommerce,
    selectedOption,
    setData,
    ...ReportDefectiveAdditionalInfoHandler({
      selectedOption,
      setSelectedOption,
      setData,
      data,
    }),
  };
};

export { ReportDefectiveAdditionalInfoHook };
