// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Handlers
import { NotificationsSettingsModalHandlers } from '../handlers/notifications-settings-modal.handlers';
// Types
import { NotificationsSettingsModalHookPropsType } from './types/notifications-settings-modal.type';
import { NotificationsSettingsModalHookReturnType } from './types/notifications-settings-modal-return.hook.type';
// Handlers
import { NotificationSettingsModalTrackingHandlers } from '../handlers/notifications-settings-modal-tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';

const NotificationsSettingsModalHook = ({
  setShowModal,
  showModal,  
}: NotificationsSettingsModalHookPropsType): 
NotificationsSettingsModalHookReturnType => {

  const {
    handleCloseModalTracking,
    handleExitWithoutSavingModalTracking,
    handleShowModalTracking,
  } = AppTrackingHook(NotificationSettingsModalTrackingHandlers);

  useEffect(() => {
    if (showModal) handleShowModalTracking?.();
  }, [showModal]);

  return {
    ...NotificationsSettingsModalHandlers({
      handleCloseModalTracking,
      handleExitWithoutSavingModalTracking,
      navigate: useNavigate(),
      setShowModal,
    }),
  };
};

export { NotificationsSettingsModalHook };