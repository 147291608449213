// Vendors
import React from 'react';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getFetchErrorConfiguration } from '@/configurations/app.configurations';
// Constants
import {
  FETCH_ERROR_PROPS,
  ERROR_PROPS,
  TRY_AGAIN_BUTTON_PROPS,
  ERROR_DEFAULT_TRANSLATIONS,
} from './constants/cards.provider.constants';
// Hooks
import CardsHook from 'providers/cards/hooks/cards.hook';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
// Translations
import { BUTTON } from './translations/cards.translations';

const CardsProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { errorCard, fetching, formatMessage, handleMakeUserLogoutEvent } = CardsHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent
        {...{
          ...FETCH_ERROR_PROPS,
          fetching,
          error: getFetchErrorConfiguration({
            ...ERROR_PROPS,
            buttonConfiguration: {
              ...TRY_AGAIN_BUTTON_PROPS,
              children: <FormattedMessageComponent id={BUTTON} />,
              onClick: handleMakeUserLogoutEvent,
            },
            error: errorCard,
            formatMessage,
            translations: ERROR_DEFAULT_TRANSLATIONS,
          }),
        }}
      >
        {children}
      </FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export default CardsProvider;
