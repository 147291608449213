// Contexts
import { SecurityKeysSignatureKeyChangeContextConsumerHook } from 'containers/security-keys/views/signature-key-change/contexts/security-keys-signature-key-change.context';
// Event handlers
import SecurityKeysSignatureKeyChangeThirdStepChallengeHandlers from '../handlers/security-keys-signature-key-change-third-step-challenge.handlers';
import SecurityKeysSignatureKeyChangeThirdStepChallengeTrackingHandlers from '../handlers/security-keys-signature-key-change-third-step-challenge.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SignatureKeyChangeStep3ChallengeType } from './types/security-keys-signature-key-change-third-step-challenge.type';

const SecurityKeysSignatureKeyChangeThirdStepChallengeHook = (): SignatureKeyChangeStep3ChallengeType => {
  const { signatureKey, newSignatureKey, setIsDisabledLastStep, setProgressId } =
    SecurityKeysSignatureKeyChangeContextConsumerHook();

  const { handleSendOtpCodeStep3TrackingHandler, handleSendOtpCodeSuccessStep3TrackingHandler } =
    AppTrackingHook(SecurityKeysSignatureKeyChangeThirdStepChallengeTrackingHandlers);

  const { handleManageSuccessOnChangeSmsEvent } = SecurityKeysSignatureKeyChangeThirdStepChallengeHandlers({
    setProgressId,
    setIsDisabledLastStep,
    handleSendOtpCodeStep3TrackingHandler,
    handleSendOtpCodeSuccessStep3TrackingHandler,
  });

  return {
    handleManageSuccessOnChangeSmsEvent,
    newSignatureKey,
    signatureKey,
  };
};

export default SecurityKeysSignatureKeyChangeThirdStepChallengeHook;
