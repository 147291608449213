// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Translations
import { ERROR_MESSAGES } from '../../translations/address-modal-inputs.translations';

const VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME = 300;

const ZIP_LENGTH = 5;

const RULES_REGEX_FLAGS = 'g';

const NUMBER_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.NUMBER_MAX_LENGTH,
    value: 8,
  },
};

const ZIP_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.ZIP_MAX_LENGTH,
    value: ZIP_LENGTH,
  },
  minLength: {
    message: ERROR_MESSAGES.ZIP_MAX_LENGTH,
    value: ZIP_LENGTH,
  },
};

const STREET_RULES = {
  required: ERROR_MESSAGES.REQUIRED,
  maxLength: {
    message: ERROR_MESSAGES.STREET_MAX_LENGTH,
    value: 50,
  },
};

const CITY_RULES = {
  deps: [InputNamesEnumeration.ZIP],
  required: ERROR_MESSAGES.REQUIRED,
};

export {
  RULES_REGEX_FLAGS,
  VALIDATE_CITY_WITH_POSTCODE_DEBOUNCED_TIME,
  NUMBER_RULES,
  ZIP_RULES,
  STREET_RULES,
  CITY_RULES,
};
