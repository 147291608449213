// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputTextComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/personal-email-step1-new.constants';
// Hooks
import PersonalEmailStep1NewHook from './hooks/personal-email-step1-new.hook';
// Translations
import translations from './translations/personal-email-step1-new.translations';

const PersonalEmailStep1NewComponent = (): React.ReactElement => {
  const {
    handleModifyEmailNewInputClickTracking,
    handleSaveNewModifyEmailInputEvent,
    handleValidateNewModifyEmailInputEvent,
    newEmail,
    inputError,
  } = PersonalEmailStep1NewHook();

  return (
    <InputTextComponent
      {...constants.PERSONAL_EMAIL_STEP_1_NEW_INPUT_PROPS}
      errorMessage={
        inputError && <FormattedMessageComponent id={translations.ERRORS[inputError]} />
      }
      label={<FormattedMessageComponent id={translations.INPUT_LABEL} />}
      onClick={handleModifyEmailNewInputClickTracking}
      onChange={handleSaveNewModifyEmailInputEvent}
      onBlur={handleValidateNewModifyEmailInputEvent}
      value={newEmail}
    />
  );
};

export default PersonalEmailStep1NewComponent;
