// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export const IMAGE_ASSEST_PROPS = {
  id: 'amazon_digital_card_back_pan',
  type: assetConstants.TYPES.ILLUSTRATION,
  extension: 'png',
  sizes: {
    height: 208,
    width: 132
  },
  testId: 'amazon-digital-card-asset',
};