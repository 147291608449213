// Constants
import { ADDRESS_FORM_DEFAULT_VALUES } from './constants/address-modal.utils.constants';
// Types
import { IsInvalidAddressType } from './types/is-invalid-address.type';
import { AddressFormValuesType } from '../types/address-form-values.type';
import { AddressType } from 'types/address.type';

const isInvalidAddress = ({ street, number, zip, city }: IsInvalidAddressType): boolean =>
  !street || !number || !zip || !city;

const formatAddressFormValues = (address: AddressType | null): AddressFormValuesType =>
  address
    ? {
        additional: address.additionalItems1 || ADDRESS_FORM_DEFAULT_VALUES.additional,
        city: address.city,
        number: address.number,
        street: address.street,
        zip: address.postalCode,
      }
    : ADDRESS_FORM_DEFAULT_VALUES;

const trimValues = ({
  additional,
  city,
  number,
  street,
  zip,
}: AddressFormValuesType): AddressFormValuesType => ({
  additional: additional.trim(),
  city: city.trim(),
  number: number.trim(),
  street: street.trim(),
  zip: zip.trim(),
});

export { formatAddressFormValues, isInvalidAddress, trimValues };
