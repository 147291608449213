// Vendors
import React from 'react';
// Components
import CardStatusTagComponent from 'components/card-status-tag/card-status-tag.component';
// Utilities
import { isSectionToShowStatus } from './utils/title-section-status.utils';

const TitleSectionStatusComponent = (): React.ReactElement | null =>
  isSectionToShowStatus() ? <CardStatusTagComponent /> : null;

export default TitleSectionStatusComponent;
