// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { THIRD_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useReportSubscriptionAlreadyCancelledConfirmationActionsHook } from './hooks/report-subscription-already-cancelled-confirmation-actions.hook';
// Translations
import { CONFIRM_BUTTON_LABEL } from 'containers/transactions/views/report/translations/transactions-report.translations';

export const ReportSubscriptionAlreadyCancelledConfirmationActionsComponent =
  (): React.ReactElement => {
    const { challenge, challengeError, handleConfirmButtonClick } =
      useReportSubscriptionAlreadyCancelledConfirmationActionsHook();

    return (
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        hasDefaultLeftButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={CONFIRM_BUTTON_LABEL} />,
          disabled: !challenge || challengeError,
          onClick: handleConfirmButtonClick,
        }}
        targets={THIRD_STEP_TARGETS}
      />
    );
  };
