// Translations
import translations from 'containers/dashboard/components/points/translations/dashboard-points.translations';

export default {
  DASHBOARD_POINTS_LINE_GRAPH_PROPS: {
    testId: 'dashboard-points-details',
    translations: translations.LINE_GRAPH_DESCRIPTIONS,
  },
  EXPANDABLE_ROW_PROPS: {
    arrowPosition: 'left',
    lateralDetail: false,
    type: 'reduced-accordion',
  },
};
