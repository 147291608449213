const OPTIONS_STRINGS = {
  YES_OPTION: 'yes',
  NO_OPTION: 'no',
};

const DATA_TEST_ID = {
  wrapper: { testId: 'report-problem-additional-info-wrapper' },
  subtitle: { testId: 'report-problem-additional-info-subtitle' },
  options: { testId: 'report-problem-additional-info-options-wrapper' },
};

const EMPTY_STRING = '';

export { OPTIONS_STRINGS, EMPTY_STRING, DATA_TEST_ID };
