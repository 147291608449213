// Vendors
import React from 'react';
// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/error.constants';
import errorAssetConstants from './constants/error-asset.constants';
// Enumerations
import { ErrorIdEnumeration } from './enumerations/error-id.enumeration';
// Hooks
import { useErrorHook } from './hooks/error.hook';
// Styles
import { ErrorProviderLayoutStyled } from './error.provider.styled';
// Translations
import translations from './translations/error.translations';
// Types
import { ErrorComponentType } from './types/error.component.type';
// Utilities
import { mapErrorForPreventExceptions } from './utils/error.utils';

const ErrorProvider = ({
  error,
}: ErrorComponentType): React.ReactElement<ErrorComponentType> | null => {
  const mappedError = mapErrorForPreventExceptions(error);

  const { handleResetAppAndReload, locale } = useErrorHook();

  return localStorage.getItem('refreshFailed') ? null : (
    <ErrorProviderLayoutStyled>
      <ErrorComponent
        {...constants.ERROR_PROVIDER_PROPS}
        assetConfiguration={{
          ...constants.ERROR_ASSET_CONFIGURATION_PROVIDER_PROPS,
          id: errorAssetConstants[String(mappedError)],
        }}
        buttonConfiguration={{
          children: translations[mappedError][locale][ErrorIdEnumeration.BUTTON],
          onClick: handleResetAppAndReload,
        }}
        description={translations[mappedError][locale][ErrorIdEnumeration.DESCRIPTION]}
        title={translations[mappedError][locale][ErrorIdEnumeration.TITLE]}
      />
    </ErrorProviderLayoutStyled>
  );
};

export { ErrorProvider };
