// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import {
  HIDE_PIN_TEXT,
  SHOW_PIN_TEXT,
} from '../../new-pin/translations/card-settings-change-pin-new-pin.translations';

const CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_TITLE_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'card-settings-check-pin-repeated-pin-title',
  },
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_DESCRIPTION_PROPS = {
    testId: 'card-settings-check-pin-repeated-pin-description',
  },
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_SECURITY_MODULE_PROPS = {
    testId: 'card-settings-check-pin-repeated-pin-security-module',
    translations: {
      HIDE_PASSWORD: <FormattedMessageComponent id={HIDE_PIN_TEXT} />,
      SHOW_PASSWORD: <FormattedMessageComponent id={SHOW_PIN_TEXT} />,
    },
  };

export {
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_SECURITY_MODULE_PROPS,
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_TITLE_PROPS,
};
