// Constants
import { messageConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

export default {
  [CardStatusTypesEnumeration.BLOCKED]: messageConstants.TYPES.ERROR,
  [CardStatusTypesEnumeration.CANCELLED]: messageConstants.TYPES.ERROR,
  [CardStatusTypesEnumeration.INACTIVE]: messageConstants.TYPES.WARNING,
  [CardStatusTypesEnumeration.LOCKED]: messageConstants.TYPES.WARNING,
  [CardStatusTypesEnumeration.TEMPORARILY_BLOCKED]: messageConstants.TYPES.ERROR,
};
