// Vendors
import React from 'react';
// Components
import {
  SidebarComponent,
  ExpandableRowComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-deposit-money-sidebar.constants';
// Styles
import {
  DepositMoneySidebarComponentStyled,
  ExpandableRowContentStyled,
} from './financing-deposit-money-sidebar.component.styled';
// Translations
import translations from './translations/financing-deposit-money-sidebar.translations';

const FinancingDepositMoneySidebarComponent = (): React.ReactElement => (
  <DepositMoneySidebarComponentStyled>
    <SidebarComponent
      {...constants.CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      {constants.ROWS_PROPS.map(({ content, id, title }) => (
        <ExpandableRowComponent key={id} title={<FormattedMessageComponent id={title} />}>
          <ExpandableRowContentStyled>
            <ParagraphComponent {...constants.PARAGRAPH_PROPS}>
              <FormattedMessageComponent id={content} />
            </ParagraphComponent>
          </ExpandableRowContentStyled>
        </ExpandableRowComponent>
      ))}
    </SidebarComponent>
  </DepositMoneySidebarComponentStyled>
);

export default FinancingDepositMoneySidebarComponent;
