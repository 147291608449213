// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { PersonalEmailStep3MessageComponent } from './components/message/personal-email-step3-message.component';
import { PersonalEmailStep3ScriptBoxComponent } from './components/script-box/personal-email-step3-script-box.component.cc';
import PersonalEmailNewEmailComponent from '../new-email/personal-email-new-email.component';
import PersonalEmailStep3ActionsComponent from './components/actions/personal-email-step3-actions.component';
import PersonalEmailStep3ChallengeComponent from './components/challenge/personal-email-step3-challenge.component';
// Constants
import constants from './constants/personal-email-step3.constants';
// Styles
import { PersonalEmailStepLayoutViewStyled } from '../../personal-email.view.styled';
// Translations
import { DESCRIPTION } from './translations/personal-email-step3.translations';

const PersonalEmailStep3Component = (): React.ReactElement => (
  <PersonalEmailStepLayoutViewStyled>
    <ParagraphComponent {...constants.PERSONAL_EMAIL_STEP_3_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <PersonalEmailStep3ScriptBoxComponent />
    <PersonalEmailNewEmailComponent />
    <PersonalEmailStep3MessageComponent />
    <PersonalEmailStep3ChallengeComponent />
    <PersonalEmailStep3ActionsComponent />
  </PersonalEmailStepLayoutViewStyled>
);
export default PersonalEmailStep3Component;
