// Vendors
//import { getLocaleFromBrowser } from '@openbank/cf-ui-framework';
// Enumerations
import { CountriesLocaleEnum } from '@openbank/cf-ui-static-data';
// Types
import { InternationalizationHookUtilsType } from './types/internationalization.hook.utils.type';

const getDefaultLanguage = (): string =>
  // getLocaleFromBrowser() || CountriesLocaleEnum.GERMANY;
  CountriesLocaleEnum.GERMANY;

const convertLocaleToTrainCase = (locale: string): string =>
  locale.length > 2 ? locale.replace('_', '-') : locale;

const getUserLocale = ({
  languagePreference,
  profile,
}: InternationalizationHookUtilsType): string =>
  languagePreference || profile?.userDetails?.language || getDefaultLanguage();

export { convertLocaleToTrainCase, getDefaultLanguage, getUserLocale };
