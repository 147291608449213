// Constants
import {
  PASSWORD_MODULE_ASSET_COLOR,
  PASSWORD_MODULE_ASSET_ID,
  VALIDATION_ID,
} from './security-keys-signature-key-change.constants.common';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  SIGNATURE_KEY_FEEDBACK_BUTTON,
  SIGNATURE_KEY_FEEDBACK_BUTTON_ERROR,
  SIGNATURE_KEY_FEEDBACK_ERROR_DESCRIPTION,
  SIGNATURE_KEY_FEEDBACK_ERROR_TITLE,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_DESCRIPTION,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_TITLE,
} from '../components/cc/translations/security-keys-signature-key-change-feedback.translations.cc';
// Utilities

const SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS_CC = {
  errorConfiguration: {
    description: SIGNATURE_KEY_FEEDBACK_ERROR_DESCRIPTION,
    title: SIGNATURE_KEY_FEEDBACK_ERROR_TITLE,
    linkLabel: SIGNATURE_KEY_FEEDBACK_BUTTON_ERROR,
    exitRoute: RoutesEnumeration.SECURITY,
  },
  successConfiguration: {
    description: SIGNATURE_KEY_FEEDBACK_SUCCESS_DESCRIPTION,
    linkLabel: SIGNATURE_KEY_FEEDBACK_BUTTON,
    title: SIGNATURE_KEY_FEEDBACK_SUCCESS_TITLE,
    exitRoute: RoutesEnumeration.SECURITY,
  },
};

export {
  PASSWORD_MODULE_ASSET_COLOR,
  PASSWORD_MODULE_ASSET_ID,
  SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS_CC,
  VALIDATION_ID
};

