// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from '../../constants/dashboad-top-message.constants';
// Styles
import { DashboardTopMessageInnerWrapperComponentStyled } from './dashboard-top-message-description.component.styled';
// Types
import { DashboardTopMessageDescriptionComponentPropsType } from './types/dashboard-top-message-description-props.component.type';

const DashboardTopMessageDescriptionComponent = ({
  cardBlockCode,
  cardBlockDescription,
  description,
  isCC,
  TRANSLATIONS,
  TESTING = {
    titleId: constants.DASHBOARD_TOP_MESSAGE_TITLE_PROPS.testId,
    descriptionId: constants.DASHBOARD_TOP_MESSAGE_PARAGRAPH_PROPS.testId,
    cardBlockId: constants.DASHBOARD_TOP_MESSAGE_CARD_BLOCK_PROPS.testId,
    cardBlockDescriptionId: constants.DASHBOARD_TOP_MESSAGE_CARD_BLOCK_DESC_PROPS.testId,
  },
}: DashboardTopMessageDescriptionComponentPropsType): React.ReactElement | null => (
  <DashboardTopMessageInnerWrapperComponentStyled>
    <ParagraphComponent {...{ testId: TESTING.titleId }} bold>
      <FormattedMessageComponent id={TRANSLATIONS.title} />
    </ParagraphComponent>
    {(TRANSLATIONS.description || description) && (
      <ParagraphComponent {...{ testId: TESTING.descriptionId }}>
        {description || (
          <FormattedMessageComponent id={TRANSLATIONS.description} />
        )}
      </ParagraphComponent>
    )}
    {isCC ? (
      <>
        <ParagraphComponent {...{ testId: TESTING.cardBlockId }}>
          <FormattedMessageComponent id={TRANSLATIONS.cardBlock} values={{ cardBlockCode }} />
        </ParagraphComponent>
        <ParagraphComponent {...{ testId: TESTING.cardBlockDescriptionId }}>
          <FormattedMessageComponent
            id={TRANSLATIONS.cardBlockDescription}
            values={{ cardBlockDescription }}
          />
        </ParagraphComponent>
      </>
    ) : null}
  </DashboardTopMessageInnerWrapperComponentStyled>
);

export { DashboardTopMessageDescriptionComponent };
