// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/credit-limit-decrease-cc.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CreditLimitDecreaseCCContextType } from './types/credit-limit-decrease-cc.context.type';

const CreditLimitDecreaseCCContext =
  createContext<CreditLimitDecreaseCCContextType>(CONTEXT_DEFAULT_VALUE);

export const CreditLimitDecreaseCCContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CreditLimitDecreaseCCContextType>> => {
  const [amount, setAmount] = useState<number | ''>('');
  const [defaultResult, setDefaultResult] = useState<boolean | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      amount,
      defaultResult,
      error,
      fetching,
      setAmount,
      setDefaultResult,
      setError,
      setFetching,
    }),
    [amount, defaultResult, error, fetching]
  );

  return (
    <CreditLimitDecreaseCCContext.Provider value={contextValue}>
      {children}
    </CreditLimitDecreaseCCContext.Provider>
  );
};

export const CreditLimitDecreaseCCContextConsumerHook = (): CreditLimitDecreaseCCContextType =>
  useContext(CreditLimitDecreaseCCContext);

/* eslint-disable */
export const CreditLimitDecreaseCCContextConsumerHOC =
  (Component: React.FunctionComponent<any>): React.FunctionComponent<any> =>
  (props: any): React.ReactElement<React.Provider<CreditLimitDecreaseCCContextType>> =>
    (
      <CreditLimitDecreaseCCContextProvider>
        <Component {...props} />
      </CreditLimitDecreaseCCContextProvider>
    );
