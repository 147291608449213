// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ModalComponent } from '@openbank/cb-ui-commons';
import { TravelPlusCostComponent } from './components/cost/travel-plus-cost.component';
import { TravelPlusFeaturesComponent } from './components/features/travel-plus-features.component';
import { TravelPlusLinksComponent } from './components/links/travel-plus-links.component';
import { TravelPlusMessageComponent } from './components/message/travel-plus-message.component';
// Constants
import {
  TRAVEL_PLUS_MODAL_BUTTON_PROPS,
  TRAVEL_PLUS_MODAL_LAYOUT_PROPS,
  TRAVEL_PLUS_MODAL_LINK_PROPS,
} from './constants/travel-plus.constants';
// Hooks
import { useTravelPlusHook } from './hooks/travel-plus.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { BUTTONS, CANCEL_LINK, TITLES } from './translations/travel-plus.translations';
// Types
import { TravelPlusComponentType } from 'components/travel-plus/types/travel-plus.component.type';
// Utilities
import {
  getTravelPlusNextStatusLabel,
  maskCmsCardId,
} from 'components/travel-plus/utils/travel-plus.utils';

const TravelPlusComponent = ({ onHide }: TravelPlusComponentType): React.ReactElement | null => {
  const {
    activated,
    cmsCardId,
    fetching,
    handleConfirmTravelPlusButtonClick,
    handleCloseTravelPlusAssetClick,
    handleCancelShowTravelPlusLinkClick,
  } = useTravelPlusHook(onHide);

  return (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...TRAVEL_PLUS_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={CANCEL_LINK} />,
        disabled: fetching,
        onClick: handleCancelShowTravelPlusLinkClick,
      }}
      primaryButtonConfiguration={{
        ...TRAVEL_PLUS_MODAL_BUTTON_PROPS,
        children: (
          <FormattedMessageComponent id={BUTTONS[getTravelPlusNextStatusLabel(activated)]} />
        ),
        disabled: fetching,
        loading: fetching,
        onClick: handleConfirmTravelPlusButtonClick,
      }}
      onHeaderClose={handleCloseTravelPlusAssetClick}
      title={
        <FormattedMessageComponent
          id={TITLES[getTravelPlusNextStatusLabel(activated)]}
          values={{ cmsCardId: maskCmsCardId(cmsCardId) }}
        />
      }
    >
      <LayoutColumnFlexComponentStyled {...TRAVEL_PLUS_MODAL_LAYOUT_PROPS}>
        <TravelPlusMessageComponent />
        <TravelPlusFeaturesComponent />
        <TravelPlusCostComponent />
        <TravelPlusLinksComponent />
      </LayoutColumnFlexComponentStyled>
    </ModalComponent>
  );
};

export { TravelPlusComponent };
