// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Utilities
import { isSecondLevelRoutePathAndMobileOrHybrid } from '../utils/title-section.utils';

export const useIsSecondLevelRoutePathAndMobileHook = (): boolean => {
  const { isHybrid } = MobileConnectorContextConsumerHook();

  const [isSecondLevelRoutePathAndMobileOrHybridValue, setIsSecondLevelRoutePathAndMobileValue] =
    useState<boolean>(isSecondLevelRoutePathAndMobileOrHybrid(isHybrid));

  useEffect(() => {
    const handleResize = () => {
      setIsSecondLevelRoutePathAndMobileValue(isSecondLevelRoutePathAndMobileOrHybrid(isHybrid));
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [window.location.pathname]);

  return isSecondLevelRoutePathAndMobileOrHybridValue;
};
