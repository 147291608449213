// Types
import { ChatTranslations } from '@openbank/cb-ui-commons';

const DOMAIN = 'cobranded.services.userSettings.privateArea.chat.';

const translations: ChatTranslations = {
  endConversationButton: `${DOMAIN}endConversation.buttonDescription`,
  endConversationDescription: `${DOMAIN}endConversation.description`,
  endConversationLink: `${DOMAIN}endConversation.linkDescription`,
  errorDescription: `${DOMAIN}error.description`,
  errorLink: `${DOMAIN}error.link`,
  errorTitle: `${DOMAIN}error.title`,
  informativeMessage: `${DOMAIN}offlineChat.informativeMessage`,
  inputPlaceholder: `${DOMAIN}inputPlaceholder`,
  inputPlaceholderDisabled: `${DOMAIN}inputPlaceholderDisabled`,
  onboardingMsg: `${DOMAIN}onboardingMsg`,
  scheduleMessageTitle: `${DOMAIN}offlineChat.scheduleMessageTitle`,
  scheduleAvailability1: `${DOMAIN}offlineChat.scheduleAvailability1`,
  scheduleAvailability2: `${DOMAIN}offlineChat.scheduleAvailability2`,
  sendButton: `${DOMAIN}sendButton`,
  sessionExpiredDescription: `${DOMAIN}sessionExpired.description`,
  sessionExpiredLink: `${DOMAIN}sessionExpired.link`,
  supportDescription: `${DOMAIN}offlineChat.supportDescription`,
  supportLink: `${DOMAIN}offlineChat.supportLink`,
  supportTitle: `${DOMAIN}offlineChat.supportTitle`,
  waitForAgent: `${DOMAIN}waitForAgent`,
  welcomeMessage: `${DOMAIN}welcomeMessage`,
  windowTitle: `${DOMAIN}windowTitle`,
};

export default translations;
