// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Translations
import step1Translations from 'containers/financing/views/credit-limit-increase/components/step1/translations/step-1-credit-limit-increase.translations';
import step2Translations from 'containers/financing/views/credit-limit-increase/components/step2/translations/step-2-credit-limit-increase.translations';
import step3Translations from 'containers/financing/views/credit-limit-increase/components/step3/translations/step-3-credit-limit-increase.translations';
import { step4AccountAggregationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/account-aggregation/translations/step-4-account-aggregation.translations';
import { step4UploadDocumentationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/translations/step-4-upload-documentation.translations';
import { step5Translations } from 'containers/financing/views/credit-limit-increase/components/step5/translations/step-5-credit-limit-increase.translations';
import { step6Translations } from 'containers/financing/views/credit-limit-increase/components/step6/translations/step-6-credit-limit-increase.translations';
import { translationsFinalFeedback } from 'containers/financing/views/credit-limit-increase/translations/final-feedback.translations';

export const titleTranslations = {
  [CreditLimitIncreaseStepsEnumeration.SELECT_LIMIT_AMOUNT]: step1Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.LABORAL_ECONOMIC_DATA]: step2Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.SELECT_METHOD]: step3Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION]:
    step4AccountAggregationTranslations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION]:
    step4AccountAggregationTranslations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT]:
    step4UploadDocumentationTranslations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: step5Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER]: step5Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.PENDING]: step5Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.RISK_REVIEW]: step5Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: step6Translations.TITLE,
  [CreditLimitIncreaseStepsEnumeration.COMPLETED]: translationsFinalFeedback.SUCCESS_TITLE,
  [CreditLimitIncreaseStepsEnumeration.ERROR_CMS_SUCCESS]: translationsFinalFeedback.ERROR_TITLE,
  [CreditLimitIncreaseStepsEnumeration.END_NAVIGATION]: translationsFinalFeedback.ERROR_TITLE,
  [CreditLimitIncreaseStepsEnumeration.REJECT_M2]: translationsFinalFeedback.ERROR_TITLE,
  [CreditLimitIncreaseStepsEnumeration.SUCCESS]: translationsFinalFeedback.SUCCESS_TITLE,
};
