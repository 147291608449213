import {
  checkNoAscendingDescendingSequences,
  checkNoUse3EqualsNumbers,
} from 'utils/access-key/access-key-constraints-checkers.utils';

const recoveryKeyHasCorrectLength = (recoveryKey: string): boolean => recoveryKey.length === 4;

const isValidNewRecoveryKey = (recoveryKey: string): boolean =>
  recoveryKeyHasCorrectLength(recoveryKey) &&
  checkNoAscendingDescendingSequences(recoveryKey) &&
  checkNoUse3EqualsNumbers(recoveryKey);

export { isValidNewRecoveryKey, recoveryKeyHasCorrectLength };
