// Vendors
import React from 'react';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
// Hooks
import { useCardSettingsLandingFooterHook } from './hooks/card-settings-landing-footer.hook';
// Styles
import { CardSettingsLandingFooterComponentStyled } from './card-settings-landing-footer.component.styled';

const CardSettingsLandingFooterComponent = (): React.ReactElement => {
  const { leftLinkConfiguration, rightButtonConfiguration } = useCardSettingsLandingFooterHook();

  return (
    <CardSettingsLandingFooterComponentStyled>
      <NavigationFooterComponent
        {...{ rightButtonConfiguration }}
        {...(leftLinkConfiguration ? { leftLinkConfiguration } : {})}
      />
    </CardSettingsLandingFooterComponentStyled>
  );
};

export { CardSettingsLandingFooterComponent };
