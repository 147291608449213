// Constants
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import constants from '../challenge/services/constants/security-keys-access-key-change-third-step-challenge.service.constants';

export const getChallengeConfiguration = ({
  oldPassword,
  newPassword,
  handleChallengeError,
  handleChallengeSuccess,
}: any): ChallengeWrapperComponentType => ({
  baseService: constants.COMPLETE_ACCESS_KEY_CHANGE_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: { params: { oldPassword, newPassword } },
  baseServiceMethod: constants.COMPLETE_ACCESS_KEY_CHANGE_OTP_SERVICE_CONFIGURATION.method,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
