// Constants
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_ALL_MINIMUM } from './constants/transactions-epp-step1-simulation-options-all.utils.constants';
// Types
import { TransactionsEppStep1SimulationOptionsAllUtilsType } from './types/transactions-epp-step1-simulation-options-all.utils.type';

const allOptionRadioButtonIsDisabled = ({
  fetching,
  minNumOfInstallments,
}: TransactionsEppStep1SimulationOptionsAllUtilsType): boolean =>
  Boolean(fetching || minNumOfInstallments > TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_ALL_MINIMUM);

export { allOptionRadioButtonIsDisabled };
