// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

const PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS = {
    testId: 'personal-email-step3-script-box-message',
    type: messageConstants.TYPES.WARNING,
  },
  PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'personal-email-step3-script-box-message-table-row-description',
  },
  PERSONAL_EMAIL_STEP_3_MESSAGE_TITLE_PROPS = {
    bold: true,
    testId: 'personal-email-step3-message-title',
  };

export {
  PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS,
  PERSONAL_EMAIL_STEP_3_MESSAGE_TITLE_PROPS,
};
