// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { RowToggleInputPreffixCurrencyComponent } from './components/row-toggle-input-suffix-component/row-toggle-input-suffix-component.component';
// Constants
import {
  DATA_TESTID_WRAPPER,
  PARAGRAPH_TEST_ID,
  HELPER_TEXT_TEST_ID,
} from './constants/settings-row-toggle-input-currency.constants';
// Styles
import {
  SettingsRowToggleInputCurrencyWrapperStyled,
  ToggleRowInputComponentStyled,
  SettingsRowToggleInputCurrencyParragraphStyled,
} from './settings-row-toggle-input-currency.component.styled';
// Types
import { SettingsRowToggleInputCurrencyComponentPropsType } from './types/settings-row-toggle-input-currency-props.type';
// Utils
import {
  formatDescription,
  formatControllerDescription,
} from './utils/settings-row-toggle-input-currency.utils';

const SettingsRowToggleInputCurrencyComponent = ({
  categoryId,
  controllerId,
  formatMessage,
  formatNumber,
  handleControllerChange,
  notificationSetting,
}: SettingsRowToggleInputCurrencyComponentPropsType): React.ReactElement | null => (
  <SettingsRowToggleInputCurrencyWrapperStyled {...{ testId: DATA_TESTID_WRAPPER }}>
    <SettingsRowToggleInputCurrencyParragraphStyled>
      <ParagraphComponent testId={PARAGRAPH_TEST_ID + notificationSetting.id}>
        {formatDescription({ formatMessage, notificationSetting, formatNumber })}
      </ParagraphComponent>
    </SettingsRowToggleInputCurrencyParragraphStyled>
    <ToggleRowInputComponentStyled>
      <ParagraphComponent testId={HELPER_TEXT_TEST_ID + notificationSetting.id}>
        {formatControllerDescription({ formatMessage, notificationSetting })}
      </ParagraphComponent>
      <RowToggleInputPreffixCurrencyComponent
        {...{
          categoryId,
          controllerId,
          formatMessage,
          formatNumber,
          handleControllerChange,
          notificationSetting,
        }}
      />
    </ToggleRowInputComponentStyled>
  </SettingsRowToggleInputCurrencyWrapperStyled>
);

export { SettingsRowToggleInputCurrencyComponent };
