const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyBillingAddress.cbSidebar.';
const INFO_DOMAIN = `${DOMAIN}information.`;

const DESCRIPTION = `${DOMAIN}description`;

const MESSAGE_1 = `${INFO_DOMAIN}parragraph1`;

const MESSAGE_2 = `${INFO_DOMAIN}parragraph2`;

const TITLE = `${DOMAIN}title`;

export { DESCRIPTION, MESSAGE_1, MESSAGE_2, TITLE };
