// Translations
import { translations } from './translations/request-signature-key.utils.translations';
// Types
import { ProfilePersonalDataAddressUtilsType } from './types/request-signature-key.utils.type';

const createPostalAddress = ({
  formatMessage,
  address,
}: ProfilePersonalDataAddressUtilsType): string[] =>
  address
    ? `${address.streetLine1} ${address.number}.\n${address.postCode}, ${
        address.town
      }\n${formatMessage({
        id: `${translations.COUNTRY}_${address.countryCode}`,
      })}`.split('\n')
    : [];

export { createPostalAddress };
