// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
import PersonalNumberLogoutModalComponent from './components/logout-modal/personal-number-logout-modal.component';
import PersonalNumberSidebarComponent from './components/sidebar/personal-number-sidebar.component';
import { PersonalSidebarCCComponent } from 'containers/personal/components/sidebar-cc/personal-sidebar-cc.component';
// Contexts
import { PersonalNumberContextConsumerHOC } from './contexts/personal-number.context';
// Constants
import constants from './constants/personal-number.constants';
// Hooks
import { PersonalNumberViewHook } from './hooks/persona-number-view.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const PersonalNumberView = (): React.ReactElement => {
  const { errorConfigurationRef } = PersonalNumberViewHook();

  return (
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <WizardComponent
          {...constants.PERSONAL_NUMBER_WIZARD_STEPS}
          feedbackConfiguration={{
            errorConfiguration: errorConfigurationRef?.current,
            successConfiguration:
              constants.PERSONAL_NUMBER_WIZARD_STEPS?.feedbackConfiguration?.successConfiguration,
          }}
        />
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled {...constants.PERSONAL_NUMBER_RIGHT_COLUMN_PROPS}>
        <PersonalSidebarCCComponent />
        <PersonalNumberSidebarComponent />
      </LayoutGridContentRightComponentStyled>
      <PersonalNumberLogoutModalComponent />
    </LayoutGridContentComponentStyled>
  );
};

export { PersonalNumberView };
export default PersonalNumberContextConsumerHOC(PersonalNumberView);
