// Services
import { validateNewNumberOtpService } from '../services/personal-number-step2-challenge.service';
// Types
import { PersonalNumberStep2ChallengeValidateApiBuilderType } from '../types/personal-number-step2-challenge-validate-api-builder.type';
// Utils
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const validateNewNumberOtpResolver = async (
  props: PersonalNumberStep2ChallengeValidateApiBuilderType
): Promise<[string | any] | [null, true]> => {
  const [, { status }] = await validateNewNumberOtpService(props);

  return wasSuccessRequest(status) ? [status] : [null, true];
};

export { validateNewNumberOtpResolver };
