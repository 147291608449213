// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration.cc';
// Components
import { Step2CreditLimitDecreaseCCComponent } from '../components/step2/step-2-credit-limit-decrease-cc.component';
import { Step1CreditLimitDecreaseCCComponent } from '../components/step1/step-1-credit-limit-decrease-cc.component';
// Translations
import {
  ERROR_LINK,
  ERROR_SUBTITLE,
  ERROR_TITLE,
  STEP_1_TITLE,
  STEP_2_TITLE,
  SUCCESS_LINK,
  SUCCESS_SUBTITLE,
  SUCCESS_TITLE,
} from './translations/credit-limit-decrease-cc.configuration.translations';
// Types
import { CreditLimitDecreaseCCViewHookType } from '../hooks/types/credit-limit-decrease-cc.view.hook.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { WizardComponentType } from 'components/wizard/types/wizard.component.type';

export const getCreditLimitDecreaseCCConfiguration = ({
  defaultResult,
  navigate,
}: Pick<CreditLimitDecreaseCCViewHookType, 'defaultResult' | 'navigate'>): WizardComponentType => ({
  feedbackConfiguration: {
    defaultResult,
    errorConfiguration: {
      title: ERROR_TITLE,
      description: ERROR_SUBTITLE,
      linkLabelId: ERROR_LINK,
      linkClick: () => navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING)),
      exitRoute: '',
    },
    successConfiguration: {
      title: SUCCESS_TITLE,
      description: SUCCESS_SUBTITLE,
      linkLabel: SUCCESS_LINK,
      linkClick: () => navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING)),
      exitRoute: '',
    },
  },
  steps: [
    {
      title: STEP_1_TITLE,
      component: Step1CreditLimitDecreaseCCComponent,
    },
    {
      title: STEP_2_TITLE,
      component: Step2CreditLimitDecreaseCCComponent,
    },
  ],
});
