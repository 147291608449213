// Enumerations
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.';

export default {
  ERROR_DESCRIPTION: `${DOMAIN}accessCard.cbError.resultsErrorDescription`,
  [AccessPortabilityTypeEnumeration.ACCESS]: {
    BUTTON: `${DOMAIN}accessCard.button`,
    DESCRIPTIONS: [`${DOMAIN}accessCard.description01`, `${DOMAIN}accessCard.description02`],
    TITLE: `${DOMAIN}accessCard.title`,
  },
  [AccessPortabilityTypeEnumeration.PORTABILITY]: {
    BUTTON: `${DOMAIN}portabilityCard.button`,
    DESCRIPTIONS: [
      `${DOMAIN}portabilityCard.description01`,
      `${DOMAIN}portabilityCard.description02`,
      `${DOMAIN}portabilityCard.description03`,
    ],
    TITLE: `${DOMAIN}portabilityCard.title`,
  },
};
