// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  OTP_SIDEBAR_DESCRIPTION_PROPS,
  OTP_SIDEBAR_PROPS,
} from './constants/otp-sidebar.constants';
// Translations
import { DESCRIPTION, TITLE } from './translations/otp-sidebar.translations';

const OtpSidebarComponent = (): React.ReactElement => (
  <SidebarComponent {...OTP_SIDEBAR_PROPS} title={<FormattedMessageComponent id={TITLE} />}>
    <ParagraphComponent {...OTP_SIDEBAR_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
  </SidebarComponent>
);

export default OtpSidebarComponent;
