// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const NOTIFICATION_SETTINGS_SIDEBAR_PROPS = {
    testId: 'notifications-settings',
  },
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_01_PROPS = {
    testId: 'notifications-settings-sidebar-info-table-row-description-1',
    size: paragraphConstants.SIZES.M,
  },
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_02_PROPS = {
    testId: 'notifications-settings-sidebar-info-table-row-description-2',
    size: paragraphConstants.SIZES.M,
  },
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_03_PROPS = {
    testId: 'notifications-settings-sidebar-info-table-row-description-3',
    size: paragraphConstants.SIZES.M,
  };

export {
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_01_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_02_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_03_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_PROPS,
};
