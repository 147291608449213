// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsCheck3dWizardStep2BuilderTrackingReturnHandlersType } from './types/card-settings-check-3d-wizard-step2.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const check3DCodeButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'checkCode',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsCheck3dWizardStep2ActionsTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsCheck3dWizardStep2BuilderTrackingReturnHandlersType => ({
  handleCheck3DCodeButtonClickTracking: () => track(check3DCodeButtonClickTrackingHandler()),
});

export default CardSettingsCheck3dWizardStep2ActionsTrackingHandlers;
