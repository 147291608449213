// Vendors
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import { CARD_SETTINGS_ROUTER_ROUTES } from './constants/card-settings-router.constants';

const CardSettingsRouterComponent = (): React.ReactElement => (
  <Routes>
    {CARD_SETTINGS_ROUTER_ROUTES.map(({ element: RouteComponent, elementProps, props }, index) => (
      <Route {...props} element={<RouteComponent {...elementProps} />} key={index} />
    ))}
  </Routes>
);

export { CardSettingsRouterComponent };
