// Translations
import translations from '../translations/financing-payment-method-sidebar.translations';

export default {
  SIDEBAR_PROPS: {
    testId: 'financing-payment-method',
  },
  ROWS_PROPS: [
    {
      title: translations.ROWS.FIRST.TITLE,
      content: translations.ROWS.FIRST.CONTENT,
    },
    {
      title: translations.ROWS.SECOND.TITLE,
      content: translations.ROWS.SECOND.CONTENT,
    },
    {
      title: translations.ROWS.THIRD.TITLE,
      content: translations.ROWS.THIRD.CONTENT,
    },
  ],
  PARAGRAPH_PROPS: {
    size: 'M',
  },
};
