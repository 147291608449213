// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const Step5CreditLimitIncreaseStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export const Step5CreditLimitIncreaseFeedbackSectionStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingS};
  border: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryC};
  border-radius: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export const Step5CreditLimitIncreaseTextsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
