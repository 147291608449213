// Event Handlers
import { NotFoundHandlers } from 'containers/not-found/handlers/not-found.handlers';
import { NotFoundTrackingHandlers } from 'containers/not-found/handlers/not-found.handlers.tracking';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { NotFoundMethodsHandlersType } from '../handlers/types/not-found.handlers.type';

const useNotFoundHook = (): NotFoundMethodsHandlersType => {
  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  return {
    ...NotFoundHandlers({
      ...AppTrackingHook(NotFoundTrackingHandlers),
      onNavigateHybridFlow,
    }),
  };
};

export { useNotFoundHook };
