// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_INSURANCES_INFO_ENDPOINT } from './constants/insurances.services.constants';
// Types
import { InsurancesInfoResponseType } from 'types/insurances/insurances-info-response.type';

const fetchInsurancesInfoService = async (
  insuranceTypes?: string[]
): Promise<[InsurancesInfoResponseType | null, Response]> =>
  apiUtilsCb.get({
    endpoint: FETCH_INSURANCES_INFO_ENDPOINT,
    queryParams: insuranceTypes ? { insuranceTypes } : {},
  });

export { fetchInsurancesInfoService };
