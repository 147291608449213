// Constants
import translations from '../translations/dashboard-points-error.translations';

export default {
  DASHBOARD_POINTS_ERROR_CONFIGURATION: {
    assetId: 'Empty_No_Result',
    translations,
    type: 'horizontal',
  },
};
