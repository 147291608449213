// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { OTP_LAST_MESSAGES_SERVICE_CONFIGURATION } from './constants/otp.service.constants';
// Types
import { OtpApiResponseType } from '../types/otp-api-response.type';

const getLastUserOtpMessagesService = async (): Promise<
  [OtpApiResponseType | Record<string, any>, Response]
> => apiUtilsCb.get(OTP_LAST_MESSAGES_SERVICE_CONFIGURATION);

export { getLastUserOtpMessagesService };
