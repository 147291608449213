// Vendors
import styled from 'styled-components';

export const TitleSpacerComponent = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacingL};
`;

export const DescriptionSpacerComponent = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const CardDetailsSpacerComponent = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacingL};

  & > div {
    padding-left: 0;
  }
`;

export const AuthenticatorSpacerComponent = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingL} 0;

  & > div > section {
    width: 100%;
  }
`;

export const ErrorSpacerComponent = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacingL};
`;
