// Vendors
import React from 'react';
// Components
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import FinancingEarlyRepaymentSidebarComponent from './components/sidebar/financing-early-repayment-sidebar.component';
import FinancingOperativeLayoutComponent from '../../components/financing-operative-layout/financing-operative-layout.component';
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import {
  EARLY_REPAYMENT_WIZARD_ERROR_PROPS,
  EARLY_REPAYMENT_WIZARD_STEPS_PROPS,
  EARLY_REPAYMENT_WIZARD_SUCCESS_PROPS,
} from './constants/financing-early-repayment.constants';
// Contexts
import { FinancingEarlyRepaymentContextConsumerHOC } from './contexts/financing-early-repayment.context';
// Hooks
import FinancingEarlyRepaymentHook from './hooks/financing-early-repayment.hook';

const FinancingEarlyRepaymentBaseView = (): React.ReactElement => {
  const { cardNumber, error, fetching } = FinancingEarlyRepaymentHook();

  return (
    <ErrorWrapperComponent {...{ error, fetching }}>
      <FinancingOperativeLayoutComponent sidebar={<FinancingEarlyRepaymentSidebarComponent />}>
        <WizardComponent
          steps={EARLY_REPAYMENT_WIZARD_STEPS_PROPS}
          feedbackConfiguration={{
            errorConfiguration: EARLY_REPAYMENT_WIZARD_ERROR_PROPS,
            successConfiguration: {
              ...EARLY_REPAYMENT_WIZARD_SUCCESS_PROPS,
              descriptionValues: { cardNumber },
            },
          }}
        />
      </FinancingOperativeLayoutComponent>
    </ErrorWrapperComponent>
  );
};

const FinancingEarlyRepaymentView = FinancingEarlyRepaymentContextConsumerHOC(
  FinancingEarlyRepaymentBaseView
);

export { FinancingEarlyRepaymentView };
