// Vendors
import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
// Components
import { AssetComponent, ButtonComponent } from '@openbank/cb-ui-commons';
import { ExpiredAccessKeyModalComponent } from '../expired-access-key-modal/expired-access-key-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CancelModalComponent from 'components/cancel-modal/cancel-modal.component';
// Constants
import { ORCHESTRATOR_LOGOUT_MODAL_PROPS } from 'containers/orchestrator/components/header/constants/orchestrator-header.constants';
import {
  ACTION_BUTTON_SIZE,
  ACTION_BUTTON_TESTID,
  ACTION_BUTTON_TYPE,
  APP_NO_USER_DATA_LOGO_PROPS
} from './constants/header.constants';
// Styles
import {
  AppNoUserDataHeaderContainerMenuUserLogout,
  AppNoUserDataHeaderContainerStyled
} from './header.component.styled';
// Hooks
import { AppNoUserDataHeaderHook } from './hooks/header.hook';
// Translations
import { BUTTON } from './translations/header.translations';
// types
import { AppNoUserDataHookReturnType } from '../../hooks/types/app-no-user-data.hook.return.type';

const AppNoUserDataHeaderComponent = ({
  showExpiredAccessKeyModal,
  onAcceptExpiredAccessKeyModal
}: AppNoUserDataHookReturnType): React.ReactElement => {
  const {
    formatMessage,
    showLogOutModal,
    handleMakeUserLogoutClick,
    handleMakeUserCancelClickHandler,
    handleMakeUserLogoutButtonClick
  } = AppNoUserDataHeaderHook();

  return (
    <AppNoUserDataHeaderContainerStyled>
      <ExpiredAccessKeyModalComponent {...{
        formatMessage,
        onClose: handleMakeUserLogoutButtonClick,
        onAccept: onAcceptExpiredAccessKeyModal,
        show: !showLogOutModal && showExpiredAccessKeyModal
      }}
      />
      <CancelModalComponent
        {...ORCHESTRATOR_LOGOUT_MODAL_PROPS}
        onClose={handleMakeUserCancelClickHandler}
        onConfirm={handleMakeUserLogoutClick}
        show={showLogOutModal}
      />
      <AssetComponent {...APP_NO_USER_DATA_LOGO_PROPS} />
      <AppNoUserDataHeaderContainerMenuUserLogout>
      <ButtonComponent
        onClick={handleMakeUserLogoutButtonClick}
        size={ACTION_BUTTON_SIZE}
        testId={ACTION_BUTTON_TESTID}
        type={ACTION_BUTTON_TYPE}
      >
        <FormattedMessageComponent id={BUTTON} /> 
      </ButtonComponent>
      </AppNoUserDataHeaderContainerMenuUserLogout>
    </AppNoUserDataHeaderContainerStyled>
  );
};

export { AppNoUserDataHeaderComponent };
