// Constants
import { MAX_MOBILE_QUICK_ACTIONS } from './constants/quick-actions.utils.constants';
import { VIEW_MORE_ACTION } from 'components/quick-actions/constants/quick-actions-configurations.common.constants';
import { PRIMARY, SECONDARY } from '../constants/quick-actions-sections.constants';
// Enumerations
import { QuickActionsSectionIdsEnumeration } from 'enumerations/quick-actions/quick-actions-section-ids.enumeration';
// Types
import { QuickActionsItemComponentType } from '../types/quick-actions-item.component.type';
import { GetVisibleQuickActionsPropsType } from './types/quick-actions.utils.types';

const getVisibleQuickActions = ({
  mappedQuickActions,
  isMobile,
}: GetVisibleQuickActionsPropsType): QuickActionsItemComponentType[] =>
  isMobile && mappedQuickActions.length > MAX_MOBILE_QUICK_ACTIONS
    ? [...mappedQuickActions.slice(0, MAX_MOBILE_QUICK_ACTIONS - 1), VIEW_MORE_ACTION]
    : mappedQuickActions;

const getQuickActionsType = (action: string): string =>
  action === QuickActionsSectionIdsEnumeration.ALL_OPTIONS ? SECONDARY : PRIMARY;

export { getVisibleQuickActions, getQuickActionsType };
