// Vendors
import React from 'react';
// Components
import { CardSettingsBenefitsComponent } from './components/benefits/card-settings-benefits.component';
import { CardSettingsDetailsComponent } from './components/details/card-settings-details.component';
import { CardSettingsLandingFooterComponent } from './components/footer/card-settings-landing-footer.component';
import { CardSettingsPaymentComponent } from './components/payment/card-settings-payment.component';
import { CardSettingsTogglesComponent } from './components/toggles/card-settings-toggles.component';
import { QuickActionsComponent } from 'components/quick-actions/quick-actions.component';
import CardStatusComponent from 'components/card-status/card-status.component';
// Constants
import { CARD_SETTINGS_LANDING_QUICK_ACTIONS_PROPS } from './constants/card-settings.constants';
// Styles
import {
  AppCardHeaderSectionComponentStyled,
  AppCardSectionComponentStyled,
  AppCardVerticalSectionComponentStyled,
  LayoutColumnFlexComponentStyled,
} from 'styles/app.styled';
import { CardSettingsTopContentContainerStyled } from './card-settings-landing.container.styled';

const CardSettingsLandingContainer = (): React.ReactElement => (
  <AppCardSectionComponentStyled>
    <AppCardVerticalSectionComponentStyled>
      <LayoutColumnFlexComponentStyled>
        <AppCardHeaderSectionComponentStyled>
          <CardStatusComponent />
          <CardSettingsTopContentContainerStyled>
            <CardSettingsTogglesComponent />
            <QuickActionsComponent {...CARD_SETTINGS_LANDING_QUICK_ACTIONS_PROPS} />
          </CardSettingsTopContentContainerStyled>
        </AppCardHeaderSectionComponentStyled>
        <LayoutColumnFlexComponentStyled>
          <CardSettingsDetailsComponent />
          <CardSettingsPaymentComponent />
          <CardSettingsBenefitsComponent />
          <CardSettingsLandingFooterComponent />
        </LayoutColumnFlexComponentStyled>
      </LayoutColumnFlexComponentStyled>
    </AppCardVerticalSectionComponentStyled>
  </AppCardSectionComponentStyled>
);

export default CardSettingsLandingContainer;
