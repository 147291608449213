export default {
  CONTEXT_DEFAULT_VALUE: {
    challenged: false,
    fetching: false,
    error: false,
    signatureExpired: false,
    newEmail: '',
    progressId: '',
    result: null,
    repeatEmail: '',
    showModal: null,
    setChallenged: (): null => null,
    setFetching: (): null => null,
    setError: (): null => null,
    setNewEmail: (): null => null,
    setProgressId: (): null => null,
    setResult: (): null => null,
    setRepeatEmail: (): null => null,
    setShowModal: (): null => null,
    setSignatureExpired: (): null => null,
  },
};
