// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { DOCUMENTS_SERVICE_CONFIGURATION } from './constants/request-table-row.service.constants';

const getDocumentLinkService = async ({
  documentId,
}: {
  documentId: string;
}): Promise<[any | Record<string, any>, Response]> =>
  apiUtilsCb.get({ ...DOCUMENTS_SERVICE_CONFIGURATION, pathParams: { reportId: documentId } });

export { getDocumentLinkService };
