const APP_AMAZON_MOBILE_SCHEMA = 'cbamz://',
  HTTPS_WEB_DOMAIN = 'https://',
  QUERY_PARAM_USER_ID = 'userId',
  MAC_INTEL_PLATFORM = 'MacIntel',
  IOS_REGEX = /iPad|iPhone|iPod/i;

export {
  APP_AMAZON_MOBILE_SCHEMA,
  HTTPS_WEB_DOMAIN,
  IOS_REGEX,
  QUERY_PARAM_USER_ID,
  MAC_INTEL_PLATFORM,
};
