const CARD_SETTINGS_CHECK_3D_CONFIRMATION_FOOTER_LINK_PROPS = {
  assetConfiguration: {
    id: 'chevron_right',
    sizes: {
      height: 12,
      width: 12,
    },
    testId: 'card-settings-check-3d-confirmation-footer-link-asset',
  },
  testId: 'card-settings-check-3d-confirmation-footer-link',
};

export { CARD_SETTINGS_CHECK_3D_CONFIRMATION_FOOTER_LINK_PROPS };
