// Vendors
import React from 'react';
// Components
import { ExpiredSessionModalComponent } from 'components/expired-session-modal/expired-session-modal.component';
// Hooks
import { useSessionExpiredHook } from './hooks/session-expired.hook';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const SessionExpiredProvider = ({ children }: ReactChildrenType): React.ReactElement => (
  <>
    <ExpiredSessionModalComponent {...useSessionExpiredHook()} />
    {children}
  </>
);

export { SessionExpiredProvider };
