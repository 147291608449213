// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from '../constants/card-details-wrapper.constants';
// Translations
import { CARD_TITLE } from '../translations/card-details-wrapper.translations';
// Types
import { GetCardDetailsConfigurationReturnType } from './types/get-card-details-configuration-return.type';
import { GetCardDetailsConfigurationType } from './types/get-card-details-configuration.type';

export const getCardDetailsConfiguration = ({
  cardImage,
  cardNumber,
  cardType,
}: GetCardDetailsConfigurationType): GetCardDetailsConfigurationReturnType => ({
  ...constants.CARD_DETAILS_DEFAULT_PROPS,
  cardImage,
  cardNumber,
  cardType,
  title: <FormattedMessageComponent id={CARD_TITLE} />,
});
