// Vendors
import React from 'react';
// Components
import { DividerComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { UploadDocumentCreditLimitIncreaseComponent } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/upload-document-credit-limit-increase.component';
// Constants
import { PARAGRAPHS_L_SIZE } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/constants/step-4-upload-documentation.component.constants';
import { TERTIARY_D } from '../../constants/upload-documents-section.constants';
// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Hooks
import { useMainAccoutnSectionHook } from './hooks/main-account-section.hook';
// Styles
import { DisplayGridStyled } from '../../upload-documents-section.component.styled';
// Types
import { MainAccountSectionPropsType } from './types/main-account-section.type';
// Translations
import { step4UploadDocumentationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/translations/step-4-upload-documentation.translations';

export const MainAccountSectionComponent = ({
  documentsRejected,
  formatMessage,
}: MainAccountSectionPropsType): React.ReactElement | null => {
  const { isMainBankAccountRejected, shouldRenderSection } =
    useMainAccoutnSectionHook(documentsRejected);
    
  if (!shouldRenderSection) return null;

  return (
    <>
      <ParagraphComponent bold size={PARAGRAPHS_L_SIZE}>
        {formatMessage({ id: step4UploadDocumentationTranslations.MAIN_BANK_ACCOUNT })}
      </ParagraphComponent>
      <ParagraphComponent>
        {formatMessage({
          id: step4UploadDocumentationTranslations.MAIN_BANK_ACCOUNT_DESCRIPTION,
        })}
      </ParagraphComponent>
      <DisplayGridStyled>
        <UploadDocumentCreditLimitIncreaseComponent
          id={UploadDocumentIdsEnumeration.MAIN_BANK_ACCOUNT}
          isRejected={isMainBankAccountRejected}
        />
      </DisplayGridStyled>
      <DividerComponent type={TERTIARY_D} />
    </>
  );
};
