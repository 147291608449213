const ORDER_LINK_PROPS = {
  testId: 'financing-landing-row-details-orders-link',
};

const ORDER_MODAL_ORCHESTRATOR_PROPS = {
  testId: 'order-modal-orchestrator',
  MODAL_MAX_WIDTH: 600,
};

export { ORDER_LINK_PROPS, ORDER_MODAL_ORCHESTRATOR_PROPS };
