// Vendors
import { useEffect } from 'react';
// Contexts
import { PersonalPhotoContextConsumerHook } from 'containers/personal/views/photo/contexts/personal-photo.context';
// Event handlers
import PersonalPhotoNameButtonUploadModalHandlers from '../handlers/personal-photo-name-button-upload-modal.handlers';
import PersonalEmailCancelModalTrackingHandlers from '../handlers/personal-photo-name-button-upload-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalPhotoNameButtonUploadModalHookType } from './types/personal-photo-name-button-upload-modal.hook.type';

const usePersonalPhotoNameButtonUploadModalHook =
  (): PersonalPhotoNameButtonUploadModalHookType => {
    const { photoModal, setCropCompleted, setPhoto, setPhotoModal } =
      PersonalPhotoContextConsumerHook();

    const {
      handleApplyCropPhotoButtonClickTracking,
      handleCancelCropPhotoLinkClickTracking,
      handleCloseCropUploadedPhotoModalTracking,
      handleOpenCropUploadedPhotoModalTracking,
    } = AppTrackingHook(PersonalEmailCancelModalTrackingHandlers);
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      handleOpenCropUploadedPhotoModalTracking();
    }, []);

    return {
      ...PersonalPhotoNameButtonUploadModalHandlers({
        handleApplyCropPhotoButtonClickTracking,
        handleCancelCropPhotoLinkClickTracking,
        handleCloseCropUploadedPhotoModalTracking,
        setCropCompleted,
        setPhoto,
        setPhotoModal,
      }),
      photoModal,
    };
  };

export default usePersonalPhotoNameButtonUploadModalHook;
