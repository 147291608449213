export default {
  securityVerificationOnCancel: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationCancelLink'
  },
  securityVerificationOnContinue: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationContinueButton'
  },

  securityVerificationOnSuccess: {
    eventAction: 'successSnackbaSMSVerificationSent',
    eventLabel: 'view'
  },
  securityVerificationOnError: {
    eventAction: 'warningMessageSomethingWentWrong',
    eventLabel: 'view'
  },
  securityVerificationOnSecurityError: {
    eventAction: 'warningMessageIncorrectCode',
    eventLabel: 'view'
  },
  securityVerificationOnTryAgain: {
    eventAction: 'clickButton',
    eventLabel: 'tryAgainButton'
  },
  viewCodeOnGoToCardSettings: {
    eventAction: 'lowerNavigation',
    eventLabel: 'goToCardSettingsLink'
  },
  viewCodeOnShowCode: {
    eventAction: 'clickButton',
  },
  viewCodeOnShowCodeAgain: {
    eventAction: 'clickButton',
  },
  koMessageOnLoad: {
    processStep: 'error',
    proccessDetail: 'notPossibleToCheck',
  },
  koMessageOnTryAgain: {
    eventAction: 'clickLink',
    eventLabel: 'tryAgainLink'
  },
};
