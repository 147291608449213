// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const ChallengeWrapperStyled = styled.div<{ withTopBorder: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
  ${({ withTopBorder }) =>
    withTopBorder &&
    css`
      border-top: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
        ${({ theme }) => theme.colors.backgroundTertiaryD};
      padding-top: ${({ theme }) => theme.spaces.spacingM};
    `}
`;
ChallengeWrapperStyled.displayName = 'ChallengeWrapperStyled';

export { ChallengeWrapperStyled };
