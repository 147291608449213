// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
// Handlers
import { DeviceSessionsHandlers } from '../handlers/device-sessions.handlers';
import { DeviceSessionsTrackingHandlers } from '../handlers/device-sessions.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { DeviceSessionsHookPropsType } from './types/device-sessions.hook.props.type';
import { DeviceSessionsHookReturnType } from './types/device-sessions.hook.return.type';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';

const DeviceSessionsHook = ({
  deviceUUID
}: DeviceSessionsHookPropsType): DeviceSessionsHookReturnType => {
  const { formatDate, formatMessage } = useCbIntl();
  const { sessions, setSessions } = SignatureKeyDevicesContextConsumerHook();
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { getDeviceSessions, onRetry } = DeviceSessionsHandlers({
    ...AppTrackingHook(DeviceSessionsTrackingHandlers),
    deviceUUID,
    setSessions,
    setFetching,
    setError
  });

  useEffect(() => {
    !sessions[deviceUUID] && getDeviceSessions();
  }, []);

  return {
    formatMessage,
    formatDate,
    fetching,
    deviceSessions: sessions[deviceUUID],
    onRetry,
    error
  };
};

export { DeviceSessionsHook };