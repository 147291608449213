// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ChangeIbanStep1ContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const ChangeIbanStep1InputContainerStyled = styled.div`
  display: flex;
  width: fit-content;
`;