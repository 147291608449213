const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyPhoneNumber.step4.';

const TITLE = `${DOMAIN}title`,
  DESCRIPTION = `${DOMAIN}description`,
  MESSAGE_DESCRIPTION = `${DOMAIN}cbMessage.description`,
  NEW_PHONE = `${DOMAIN}newPhoneLabel`,
  NEXT = `${DOMAIN}nextButton`,
  CURRENT_PHONE = `${DOMAIN}phoneLabel`,
  SMS_CODE_SENT = `${DOMAIN}smsCodeSent`,
  EMAIL_CODE_SENT = `${DOMAIN}emailCodeSent`,
  CANCEL = `${DOMAIN}cancelButton`;

export {
  DESCRIPTION,
  MESSAGE_DESCRIPTION,
  NEW_PHONE,
  TITLE,
  SMS_CODE_SENT,
  EMAIL_CODE_SENT,
  NEXT,
  CANCEL,
  CURRENT_PHONE,
};
