// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { type ChatBuilderReturnTrackingHandlersType } from './types/chat.tracking.handlers.type';
import { type TrackBuilderType } from 'types/tracking/track-builder.type';

const onCloseTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'close',
  },
  type: TrackingEventsEnum.LINK,
});

const onOpenChatTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'open',
  },
  type: TrackingEventsEnum.LINK,
});

const onMaximizeChatTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'maximizeButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onMinimizeChatTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'minimizeButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onConnectionErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'connectionError',
    eventLabel: 'view',
  },
  type: TrackingEventsEnum.LINK,
});

const onGoToFraqsTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'goToFraqsLink',
  },
  type: TrackingEventsEnum.LINK,
});
const chatUnavailableTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'chatUnavailableSnackbar',
    eventLabel: 'view',
  },
  type: TrackingEventsEnum.LINK,
});

const onOpenErrorSnackbarTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'chatSessionEndedSnackbar',
    eventLabel: 'view',
  },
  type: TrackingEventsEnum.LINK,
});

const onWritingMessageTrackingHanlder = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'sendMessageTextBox',
  },
  type: TrackingEventsEnum.LINK,
});

const onSendMessageTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'sendButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onRestartChatTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'chatSessionEndedSnackbar',
    eventLabel: 'restartChatLink',
  },
  type: TrackingEventsEnum.LINK,
});

const onResendMessageTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'tryAgaintLink',
  },
  type: TrackingEventsEnum.LINK,
});

const onSendMessageErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'messageNotSend',
  },
  type: TrackingEventsEnum.LINK,
});

const onStayChatTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'stayInChatLink',
  },
  type: TrackingEventsEnum.LINK,
});

const onUnMaximizeChatTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'unMaximizeButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onLeaveTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'modalChat',
    eventLabel: 'endConversationWithAgentButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onFabButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'helpButton',
  },
  type: TrackingEventsEnum.LINK,
});

const ChatTrackingHandlers = (track: TrackBuilderType): ChatBuilderReturnTrackingHandlersType => ({
  handleCloseChatTracking: () => track(onCloseTrackingHandler()),
  handleConnectionErrorTracking: () => track(onConnectionErrorTrackingHandler()),
  handleFabButtonClickTracking: () => track(onFabButtonClickTrackingHandler()),
  handleGoToFaqsTracking: () => track(onGoToFraqsTrackingHandler()),
  handleLeaveChatTracking: () => track(onLeaveTrackingHandler()),
  handleMinimizeChatTracking: () => track(onMinimizeChatTrackingHandler()),
  handleMaximizeChatTracking: () => track(onMaximizeChatTrackingHandler()),
  handleOpenChatTracking: () => track(onOpenChatTrackingHandler()),
  handleOpenChatInactiveSnackbarTracking: () => track(chatUnavailableTrackingHandler()),
  handleOpenErrorSnackbarTracking: () => track(onOpenErrorSnackbarTrackingHandler()),
  handleSendMessageTracking: () => track(onSendMessageTrackingHandler()),
  handleResendMessageTracking: () => track(onResendMessageTrackingHandler()),
  handleRestartChatTracking: () => track(onRestartChatTrackingHandler()),
  handleSendMessageErrorTracking: () => track(onSendMessageErrorTrackingHandler()),
  handleStayChatTracking: () => track(onStayChatTrackingHandler()),
  handleUnMaximizeChatTracking: () => track(onUnMaximizeChatTrackingHandler()),
  handleWritingMessageTracking: () => track(onWritingMessageTrackingHanlder()),
});

export { ChatTrackingHandlers };
