// Vendors
import React from 'react';
// Hooks
import PoeKeysHook from './hooks/poe-keys.hook';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const PoeKeysProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  PoeKeysHook();

  return children as React.ReactElement;
};

export default PoeKeysProvider;
