// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';

const errorIsMapped = (error?: ErrorCodesEnumeration | string): boolean =>
  [
    ErrorCodesEnumeration.ME,
    ErrorCodesEnumeration.CARD,
    ErrorCodesEnumeration.DEFAULT,
    ErrorCodesEnumeration.TRANSLATIONS,
  ].includes(error as any);

const mapErrorForPreventExceptions = (error?: ErrorCodesEnumeration): ErrorCodesEnumeration =>
  error && errorIsMapped(error) ? error : ErrorCodesEnumeration.UNKNOWN;

export { mapErrorForPreventExceptions };
