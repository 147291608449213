// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  EXPANDABLE_ROW_PROPS: {
    arrowPosition: 'left',
    type: 'reduced-accordion',
    lateralDetail: false,
  },
  DASHBOARD_SPENT_EXPANDABLE_BOTTOM_INNER_CHEVRON_PROPS: {
    size: paragraphConstants.SIZES.XS,
  },
  DASHBOARD_SPENT_EXPANDABLE_BOTTOM_INNER_ROW_TESTING: {
    assetId : 'dashboard-spent-bottom-exandable-row-asset',
    rowId : 'dashboard-spent-bottom-exandable-row',
    titleId : 'dashboard-spent-bottom-exandable-row-title',
  },
};
