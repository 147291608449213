// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
import { linkConstants } from '@openbank/cb-ui-commons';

const TRAVEL_PLUS_MODAL_BUTTON_PROPS = {
    testId: 'travel-plus-button',
  },
  TRAVEL_PLUS_MODAL_LINK_PROPS = {
    type: linkConstants.TYPES.ACTION_PRIMARY,
    testId: 'travel-plus-link',
  },
  TRAVEL_PLUS_MODAL_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingL,
  };

export {
  TRAVEL_PLUS_MODAL_BUTTON_PROPS,
  TRAVEL_PLUS_MODAL_LINK_PROPS,
  TRAVEL_PLUS_MODAL_LAYOUT_PROPS,
};
