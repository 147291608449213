// Constants
import { linkConstants } from '@openbank/cb-ui-commons';

const NOTIFICATIONS_SETTINGS_FOOTER_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'notifications-settings-footer-button',
  },
  NOTIFICATIONS_SETTINGS_FOOTER_LINK_PROPS = {
    type: linkConstants.TYPES.SECONDARY,
    testId: 'notifications-settings-footer-link',
  };

export { NOTIFICATIONS_SETTINGS_FOOTER_BUTTON_PROPS, NOTIFICATIONS_SETTINGS_FOOTER_LINK_PROPS };
