// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const AVATAR_ASSET_PROPS = {
  id: 'profile_default_avatar',
  sizes: {
    height: 100,
    width: 100,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'default-avatar',
};

export { AVATAR_ASSET_PROPS };
