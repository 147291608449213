// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { REDIRECTION_BUTTON_PAYLOAD } from './constants/transactions-table.handlers.tracking.constants';
// Types
import { FinancingErrorButtonTrackingHandlersType } from './types/financing-error-button.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const navigateToTransactionsViewButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: REDIRECTION_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const FinancingErrorButtonTrackingHandlers = (
  track: TrackBuilderType
): FinancingErrorButtonTrackingHandlersType => ({
  handleNavigateToTransactionsViewButtonClickTracking: () =>
    track(navigateToTransactionsViewButtonClickTrackingHandler()),
});

export default FinancingErrorButtonTrackingHandlers;
