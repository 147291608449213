// Types
import { FormValuesParamsType } from 'components/filters/context/types/filters-context.type';

export enum NotificationsStatusEnum {
  readed = 'READ',
  delivered = 'DELIVERED',
}

export type NotificationsListServiceProps = {
  params?: Record<string, any>;
  filters?: FormValuesParamsType;
};
