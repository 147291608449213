// Types
import { FormatSettingsPayloadReturnType } from './types/format-settings-payload-return.handlers.utils.type';
import { ToggleNotificationUtilHandlersType } from './types/toggle-notification-util.handlers.type';
import { controllerChangeUtilType } from './types/controller-change.handlers.utils.type';
import { NotificationsSettingsFormattedType } from '../../types/notification-settings-formatted.type';
import { NotificationSettingsListControllerType } from '../../types/notification-settings-list-controller.type';
import { NotificationSettingsListType } from '../../types/notification-settings-list.type';
import { NotificationsSettingsListCategoryType } from '../../types/notifications-settings-list-category.type';

const formatSettingsPayload = (
  notificationsSettings: NotificationsSettingsFormattedType
): FormatSettingsPayloadReturnType => ({
notifications: notificationsSettings?.categories.flatMap((category: NotificationsSettingsListCategoryType) =>
  category.notifications.map((notification: NotificationSettingsListType) => ({
    id: notification.id,
    enabled: notification.enabled,
    ...(notification.controllers.length > 0 && {
      controllers: notification.controllers.map((controller: NotificationSettingsListControllerType) => ({
        id: controller.id,
        type: controller.type,
        amount: Number(controller.amount)
      }))
    })
  }))
)
});

const toggleNotificationUtil = ({
  prevSettings,
  categoryId,
  notificationId,
}: ToggleNotificationUtilHandlersType): NotificationsSettingsFormattedType => {

  let allEnabled = true;

  const updatedCategories = prevSettings.categories.map((category: NotificationsSettingsListCategoryType) => { 
    if (category.id === categoryId) {
      const updatedNotifications = category.notifications.map((notification: NotificationSettingsListType) => {
        const newEnabled = notification.id === notificationId ? !notification.enabled : notification.enabled;

        if (!newEnabled) {
          allEnabled = false;
        }
        return {
          ...notification,
          enabled: newEnabled
        };
      });
      return {
        ...category,
        notifications: updatedNotifications
      };
    }

    if (category.notifications.some((n: NotificationSettingsListType) => !n.enabled)) {
      allEnabled = false;
    }

    return category;
  });

  return {
    ...prevSettings,
    allEnabled: allEnabled,
    categories: updatedCategories
  };
};

const toggleAllNotificationsUtil = (
  prevSettings: NotificationsSettingsFormattedType
): NotificationsSettingsFormattedType => {

  const allCurrentlyEnabled = prevSettings.categories.every((category: NotificationsSettingsListCategoryType) =>
    category.notifications.every((notification: NotificationSettingsListType) => notification.enabled)
  );

  return {
    ...prevSettings,
    allEnabled: !allCurrentlyEnabled,
    categories: prevSettings.categories.map((category: NotificationsSettingsListCategoryType) => ({
      ...category,
      notifications: category.notifications.map((notification: NotificationSettingsListType) => ({
        ...notification,
        enabled: !allCurrentlyEnabled
      }))
    }))
  };
};

const controllerChangeUtil = ({
  prevSettings,
  categoryId,
  notificationId,
  controllerId,
  value
}: controllerChangeUtilType): NotificationsSettingsFormattedType => ({
  ...prevSettings,
  categories: prevSettings.categories.map((category: NotificationsSettingsListCategoryType) => {
  if (category.id === categoryId) {
    const updatedNotifications = category.notifications.map((notification: NotificationSettingsListType) => {
      if (notification.id === notificationId) {
          const updatedControllers = notification.controllers.map((controller: NotificationSettingsListControllerType) => {
            if (controller.id === controllerId) {
              return { ...controller, amount: value};
            }
            return controller;
          });
        return { ...notification, controllers: updatedControllers };
      }
      return notification;
    });
    return { ...category, notifications: updatedNotifications };
  }

  return category;
  })
});

const updateNotificationSettings = (
  setNotificationsSettings: (arg: (prevSettings: NotificationsSettingsFormattedType | null) => 
    NotificationsSettingsFormattedType | null) => void,
    updateFn: (prevSettings: NotificationsSettingsFormattedType) => NotificationsSettingsFormattedType
): void => {
  setNotificationsSettings((prevSettings) => {
    if (!prevSettings) return null;

    return updateFn(prevSettings);
  });
};

export { formatSettingsPayload, toggleNotificationUtil, toggleAllNotificationsUtil, controllerChangeUtil, updateNotificationSettings};