// Vendors
import { getAppLocale, getTrackingInstance } from '@openbank/cf-ui-framework';
// Constants
import { TRACKING_CUSTOM_CONFIGURATION } from './constants/app.tracking.hook.constants';
// Configurations
import { getTrackingConfiguration } from './configurations/app.tracking.configurations';
// Types
import { AppContextConsumerHook } from 'contexts/app.context';
// Utilities
import getWebDeviceVersion from './utils/app.tracking.hook.utils';

const AppTrackingHook = <T>(
  TrackingHandlers: (track: ({ payload, ...rest }: any) => any) => T
): T => {
  const { profile } = AppContextConsumerHook(),
    { track } = getTrackingInstance(
      getTrackingConfiguration(profile?.userDetails?.customerId)
    ).trackEvent();

  return TrackingHandlers(({ payload, ...rest }: any): any =>
    track({
      ...rest,
      payload: {
        ...TRACKING_CUSTOM_CONFIGURATION,
        ...payload,
        webLang: getAppLocale(),
        webVersion: getWebDeviceVersion(),
      },
    })
  );
};

export default AppTrackingHook;
