// Vendors
import React from 'react';
// Components
import ProfilePersonalNameAliasEditComponent from './components/edit/profile-personal-name-alias-edit.component';
import ProfilePersonalNameAliasEmptyComponent from './components/empty/profile-personal-name-alias-empty.component';
import ProfilePersonalNameAliasInputComponent from './components/input/profile-personal-name-alias-input.component';
// Contexts
import { ProfilePersonalNameAliasContextConsumerHOC } from './contexts/profile-personal-name-alias.context';
// Hooks
import ProfilePersonalNameAliasHook from './hooks/profile-personal-name-alias.hook';
// Utilities
import { isNotEditingAndNoAlias } from './utils/profile-personal-name-alias.utils';

const ProfilePersonalNameAliasComponent = (): React.ReactElement => {
  const { alias, editMode } = ProfilePersonalNameAliasHook();

  switch (true) {
    case editMode:
      return <ProfilePersonalNameAliasInputComponent />;
    case isNotEditingAndNoAlias({ alias, editMode }):
      return <ProfilePersonalNameAliasEmptyComponent />;
    default:
      return <ProfilePersonalNameAliasEditComponent />;
  }
};

export { ProfilePersonalNameAliasComponent };

export default ProfilePersonalNameAliasContextConsumerHOC(ProfilePersonalNameAliasComponent);
