export default {
  VIEW: {
    pageName: '/financing/credit-limit-increase',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'financing',
    interactionType: 'funnel',
    serviceType: 'productSettings',
    processType: 'creditLimitIncrease',
    processStep: 'step2',
    processDetail: 'personalEmployedAndFinancialData',
  },
  LINK: {
    CLICK_PREVIOUS_BUTTON: {
      action: 'return',
      format: 'button',
      component: 'previousButton',
      element: 'previous',
      pathname: 'step2',
      eventCategory: 'step2 credit-limit-increase',
      eventAction: 'previousButton',
      eventLabel: 'navigationPreviousButton',
    },

    PERSONAL_SITUATION: {
      MARITAL_STATUS: {
        action: 'select',
        format: 'radioButton',
        component: 'maritalStatus',
        element: '<single|married|separate|widow|civilUnion>',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickRadioButton',
        eventLabel: '<single|married|separate|widow|civilUnion>',
      },
      CLICK_CHILDREN_IN_HOUSEHOLD: {
        action: '<open | close>',
        format: 'dropdown',
        component: 'childrenInHousehold',
        element: 'numberChildrenInHousehold',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickDropdown',
        eventLabel: '<open | close>',
      },
      SELECT_CHILDREN_IN_HOUSEHOLD: {
        action: 'select',
        format: 'dropdown',
        component: 'childrenInHousehold',
        element: 'numberChildrenInHousehold',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'selectDropdown',
        eventLabel: '<selection>',
      },
      CLICK_CHILDREN_WHOM_YOU_PAY: {
        action: '<open | close>',
        format: 'dropdown',
        component: 'childrenWhonYouPay',
        element: 'numberChildrenWhonYouPay',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickDropdown',
        eventLabel: '<open | close>',
      },
      SELECT_CHILDREN_WHOM_YOU_PAY: {
        action: 'select',
        format: 'dropdown',
        component: 'childrenWhonYouPay',
        element: 'numberChildrenWhonYouPay',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'selectDropdown',
        eventLabel: '<selection>',
      },
      OPEN_CHILDREN_WHOM_YOU_PAY_TOOLTIP: {
        action: 'open',
        format: 'icon',
        component: 'childrenWhonYouPay tooltip',
        element: 'informationChildrenWhonYouPay',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'open childrenWhonYouPay tooltip',
        eventLabel: 'open',
      },
    },

    EMPLOYMENT_DATA: {
      EMPLOYMENT_STATUS: {
        action: 'select',
        format: 'radioButton',
        component: 'employmentStatus',
        element: '<salaried/civilServant|self-employed|rentist|pensionist|no-economical-activity>',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickRadioButton',
        eventLabel:
          '<salaried/civilServant|self-employed|rentist|pensionist|no-economical-activity>',
      },
      CLICK_MONTH_JOIN_COMPANY: {
        action: '<open | close>',
        format: 'dropdown',
        component: 'monthJoinCompany',
        element: 'monthJoinCompany',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickDropdown',
        eventLabel: '<open | close>',
      },
      SELECT_MONTH_JOIN_COMPANY: {
        action: 'select',
        format: 'dropdown',
        component: 'monthJoinCompany',
        element: 'monthJoinCompany',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'selectDropdown',
        eventLabel: '<selection>',
      },
      CLICK_YEAR_JOIN_COMPANY: {
        action: '<open | close>',
        format: 'dropdown',
        component: 'yearJoinCompany',
        element: 'yearJoinCompany',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickDropdown',
        eventLabel: '<open | close>',
      },
      SELECT_YEAR_JOIN_COMPANY: {
        action: 'select',
        format: 'dropdown',
        component: 'yearJoinCompany',
        element: 'yearJoinCompany',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'selectDropdown',
        eventLabel: '<selection>',
      },
      SELECT_CONTRACT_TYPE: {
        action: 'select',
        format: 'dropdown',
        component: 'kindOfContract',
        element: 'kindOfContract',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'selectDropdown',
        eventLabel: '<selection>',
      },
    },

    ICONME: {
      CLICK_APROXIMATE_MONTHLY_SALARY_INPUT: {
        action: 'click',
        format: 'input',
        component: 'aproximateMonthlySalary',
        element: 'aproximateMonthlySalary',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickInput',
        eventLabel: 'aproximateMonthlySalary',
      },
      OPEN_APROXIMATE_MONTHLY_SALARY_TOOLTIP: {
        action: 'open',
        format: 'icon',
        component: 'aproximateMonthlySalary tooltip',
        element: 'informationAproximateMonthlySalary',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'open aproximateMonthlySalary tooltip',
        eventLabel: 'open',
      },
    },

    EXPENSES: {
      CLICK_SPEND_MONTHLY_ON_RENT_INPUT: {
        action: 'click',
        format: 'input',
        component: 'spendMonthlyOnRent',
        element: 'spendMonthlyOnRent',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickInput',
        eventLabel: 'spendMonthlyOnRent',
      },
      CLICK_SPEND_MONTHLY_ON_LOANS_INPUT: {
        action: 'click',
        format: 'input',
        component: 'spendMonthlyOnLoans',
        element: 'spendMonthlyOnLoans',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickInput',
        eventLabel: 'spendMonthlyOnLoans',
      },
      CLICK_SPEND_MONTHLY_ON_MORTGAGES_INPUT: {
        action: 'click',
        format: 'input',
        component: 'spendMonthlyOnMortgages',
        element: 'spendMonthlyOnMortgages',
        eventCategory: 'step2 credit-limit-increase',
        eventAction: 'clickInput',
        eventLabel: 'spendMonthlyOnMortgages',
      },
    },
  },
} as const;
