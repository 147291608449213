const DOMAIN = 'cobranded.services.userSettings.privateArea.transactions.transactionsLanding.';

const DATE_FROM_TITLE = `${DOMAIN}filterModal.date.fromTitle`,
  DATE_OPTION_ALL = `${DOMAIN}filterModal.date.options.all`,
  DATE_OPTION_CUSTOM_DATE_TITLE = `${DOMAIN}filterModal.date.options.customDate.title`,
  DATE_OPTION_LAST_15_DAYS_TITLE = `${DOMAIN}filterModal.date.options.last15Days.title`,
  DATE_OPTION_LAST_6_MONTHS_TITLE = `${DOMAIN}filterModal.date.options.last6Months.title`,
  DATE_OPTION_LAST_12_MONTHS_TITLE = `${DOMAIN}filterModal.date.options.last12Months.title`,
  DATE_OPTION_LAST_MONTH_TITLE = `${DOMAIN}filterModal.date.options.lastMonth.title`,
  DATE_OPTION_RANGE_TITLE = `${DOMAIN}filterModal.date.options.range.title`,
  DATE_TITLE = `${DOMAIN}filterModal.date.title`,
  DATE_TO_TITLE = `${DOMAIN}filterModal.date.toTitle`;

export {
  DATE_FROM_TITLE,
  DATE_OPTION_ALL,
  DATE_OPTION_CUSTOM_DATE_TITLE,
  DATE_OPTION_LAST_15_DAYS_TITLE,
  DATE_OPTION_LAST_6_MONTHS_TITLE,
  DATE_OPTION_LAST_12_MONTHS_TITLE,
  DATE_OPTION_LAST_MONTH_TITLE,
  DATE_OPTION_RANGE_TITLE,
  DATE_TITLE,
  DATE_TO_TITLE,
};
