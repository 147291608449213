// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const PersonalPhotoNavigationFooterComponentStyled = styled.footer`
  margin-top: ${({ theme }) => theme.spaces.spacingXs};
`;

export { PersonalPhotoNavigationFooterComponentStyled };
