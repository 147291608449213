// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsCheck3dContextType } from './types/card-settings-check-3d.context.type';

const CardSettingsCheck3dContext = createContext<CardSettingsCheck3dContextType>(
  {} as CardSettingsCheck3dContextType
);

export const CardSettingsCheck3DContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsCheck3dContextType>> => {
  const [hasChallenged, setHasChallenged] = useState<boolean>(false);
  const [scaCode, setScaCode] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<number>(1);

  return (
    <CardSettingsCheck3dContext.Provider
      value={{ hasChallenged, setHasChallenged, scaCode, setScaCode, currentStep, setCurrentStep }}
    >
      {children}
    </CardSettingsCheck3dContext.Provider>
  );
};

export const useCardSettingsCheck3DContextConsumerHook = (): CardSettingsCheck3dContextType =>
  useContext(CardSettingsCheck3dContext);

/* eslint-disable */
export const CardSettingsCheck3DContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<CardSettingsCheck3dContextType>> =>
    (
      <CardSettingsCheck3DContextProvider>
        <Component {...props} />
      </CardSettingsCheck3DContextProvider>
    );
