// Vendors
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Configurations
import { getTrackingIdAccordingConfiguredView } from '../configurations/app.tracking.configurations';
// Event Handlers
import AppTrackingHandlers from '../handlers/app.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Utilities
import { getPathnameFromBrowser } from 'utils/app-routing.utils';

const AppTrackingCustomHook = (): any => {
  const location = useLocation();

  const { handleEnterSectionTracking } = AppTrackingHook(AppTrackingHandlers);
  useEffect(() => {
    const configuration = getTrackingIdAccordingConfiguredView(getPathnameFromBrowser());

    configuration && handleEnterSectionTracking(configuration);
  }, [location]);
};

export default AppTrackingCustomHook;
