// Translations
import translations from '../translations/settings-card-control-locations-map-legend.translations';

export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_ON_PROPS: {
    assetColor: 'brandB',
    description: translations.ON,
    testing: {
      descriptionId: 'settings-card-control-locations-map-legend-on',
    },
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_PARTIALLY_ON_PROPS: {
    assetColor: 'backgroundPrimaryA',
    description: translations.PARTIAL_ON,
    testing: {
      descriptionId: 'settings-card-control-locations-map-legend-partially-on',
    },
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_OFF_PROPS: {
    assetColor: 'backgroundTertiaryD',
    description: translations.OFF,
    testing: {
      descriptionId: 'settings-card-control-locations-map-legend-off',
    },
  },
};
