// Types
import { TransactionsTableListGroupsBuilderHandlersType } from './types/transactions-table-list-groups-builder.handlers.type';
import { TransactionsTableListGroupsBuilderReturnHandlersType } from './types/transactions-table-list-groups-builder-return.handlers.type';
// Utilities
import { createTransactionsLandingTableGroups } from './utils/transactions-table-list-groups.handlers.utils';

const setupTransactionsLandingTableGroupsHandler = ({
  elements,
  groupId,
  isFinancing,
  setGroups,
}: TransactionsTableListGroupsBuilderHandlersType): any => {
  setGroups(createTransactionsLandingTableGroups({ elements, groupId, isFinancing }));
};

const TransactionsTableListGroupsHandlers = (
  props: TransactionsTableListGroupsBuilderHandlersType
): TransactionsTableListGroupsBuilderReturnHandlersType => ({
  handleSetupTransactionsLandingTableGroups: () =>
    setupTransactionsLandingTableGroupsHandler(props),
});

export default TransactionsTableListGroupsHandlers;
