// Vendors
import React from 'react';
import { getAppLocale } from '@openbank/cf-ui-framework';
import HCaptcha from '@hcaptcha/react-hcaptcha';
// Components
import { FeatureFlagManager } from '@openbank/cb-ui-commons';
// Constants
import { CAPTCHA_PROPS } from './constants/captcha.constants';
// Hooks
import { useCaptchaHook } from './hooks/captcha.hook';
// Styled
import { CaptchaComponentStyled } from './captcha.component.styled';
// Utitlities
import { getCaptchaKey } from './utils/captcha.utils';
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
// Types
import { CaptchaComponentPropsType } from './types/captcha-props.component.type';

const CaptchaComponent = ({
  featureFlag,
}: CaptchaComponentPropsType): React.ReactElement | null => {
  const { handleVerifyCaptchaEvent: onVerify, captchaRef: ref } = useCaptchaHook();

  return (
    <FeatureFlagManager flags={getFeatureFlagInstance()} flag={featureFlag}>
      <CaptchaComponentStyled>
        <HCaptcha
          {...{
            ...CAPTCHA_PROPS,
            ref,
            sitekey: getCaptchaKey(),
            onVerify,
          }}
          languageOverride={getAppLocale()}
        />
      </CaptchaComponentStyled>
    </FeatureFlagManager>
  );
};

export { CaptchaComponent };
