// Enums
import { TrackingEventsEnum } from '@openbank/cf-ui-static-data';
// Types
import {
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SupportCancelContractSecondStepTrackingHandlersReturnType } from './types/support-cancel-contract-second-step.tracking.handlers.type';

const nextButtonClickTrackingHandlerStep2 = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationConfirmCancelContractButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterThirdStepCancelContractTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/cancelContract/step02/confirmation',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'support',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SupportCancelContractSecondStepTrackingHandlers = (
  track: TrackBuilderType
): SupportCancelContractSecondStepTrackingHandlersReturnType => ({
  handleNextButtonClickTrackingHandlerStep2: () => track(nextButtonClickTrackingHandlerStep2()),
  handleOnEnterThirdStepCancelContractTrackingHandler: () =>
    track(onEnterThirdStepCancelContractTrackingHandler()),
});

export default SupportCancelContractSecondStepTrackingHandlers;
