const DOMAIN_ACTIVATE_CARD_CHALLENGE =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.challenge.';
const DOMAIN_ACTIVATE_CARD_CHALLENGE_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.challenge.buttons.';

export default {
  BUTTONS: {
    CANCEL: `${DOMAIN_ACTIVATE_CARD_CHALLENGE_BUTTONS}cancel`,
    ACTIVATE: `${DOMAIN_ACTIVATE_CARD_CHALLENGE_BUTTONS}activate`,
  },
  TITLE: `${DOMAIN_ACTIVATE_CARD_CHALLENGE}title`,
};
