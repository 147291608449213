// Vendors
import styled from 'styled-components';

export const DashboardSpentMessageWrapperComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
`;

export const DashboardSpentMessageInnerComponentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const DashboardSpentMessageTopComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
