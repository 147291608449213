// Constants
import constants from './constants/card-settings.configurations.constants';
// Translations
import translations from './translations/card-settings.configurations.translations';
// Types
import { CardSettingsReturnConfigurationsType } from './types/card-settings-return.configurations.type';
import { CardSettingsConfigurationsType } from './types/card-settings.configurations.type';

const getCardSettingsErrorConfiguration = ({
  formatMessage,
  error,
}: CardSettingsConfigurationsType): CardSettingsReturnConfigurationsType | Record<string, any> =>
  error
    ? {
        error: {
          ...constants.ERROR_ASSET_CONFIGURATION_PROPS,
          description: formatMessage({ id: translations.ERROR_DESCRIPTION }),
          title: formatMessage({ id: translations.ERROR_TITLE }),
        },
      }
    : {};

export { getCardSettingsErrorConfiguration };
