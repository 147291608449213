// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useRequestSignatureKeyContextConsumerHook } from 'containers/security-keys/views/request-signature-key/contexts/request-signature-key.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Types
import { RequestSignatureKeyStep2HookType } from './types/request-signature-key-step2.hook.type';
// Handlers
import RequestSignatureKeyStep2Handlers from '../handlers/request-signature-key-step2.handlers';

const useRequestSignatureKeyStep2Hook = (): RequestSignatureKeyStep2HookType => {
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { challenged, progressId, setProgressId, setFetching } =
    useRequestSignatureKeyContextConsumerHook();
  const {
    profile: {
      userDetails: { firstSurname },
      addressDetails,
    },
  } = AppContextConsumerHook();
  const address = addressDetails?.[0] || {};
  const { formatMessage } = useCbIntl();

  return {
    ...RequestSignatureKeyStep2Handlers({
      progressId,
      setProgressId,
      setResult,
      setFetching,
    }),
    address,
    challenged,
    firstLastName: firstSurname,
    formatMessage,
  };
};

export default useRequestSignatureKeyStep2Hook;
