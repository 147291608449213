// Constants
import {
  CLOSE_CONTRACT_MODAL_EVENT_LABEL,
  OPEN_CONTRACT_MODAL_EVENT_LABEL,
  UNDERSTOOD_CONTRACT_MODAL_EVENT_LABEL,
} from './constants/financing-insurances-landing-card.handlers.tracking.constants';
// Types
import { FinancingInsurancesLandingCardReturnHandlersType } from './types/financing-insurances-landing-card-return.handlers.type';
import { FinancingInsurancesLandingCardHandlersType } from './types/financing-insurances-landing-card.handlers.type';

const openContractModalButtonClickHandler = ({
  handleContractModalButtonClickTracking,
  setIsContractModalOpen,
}: FinancingInsurancesLandingCardHandlersType): void => {
  handleContractModalButtonClickTracking(OPEN_CONTRACT_MODAL_EVENT_LABEL);
  setIsContractModalOpen(true);
};

const closeContractModalButtonClickHandler = ({
  handleContractModalButtonClickTracking,
  setIsContractModalOpen,
}: FinancingInsurancesLandingCardHandlersType): void => {
  handleContractModalButtonClickTracking(CLOSE_CONTRACT_MODAL_EVENT_LABEL);
  setIsContractModalOpen(false);
};

const understoodButtonClickHandler = ({
  handleContractModalButtonClickTracking,
  setIsContractModalOpen,
}: FinancingInsurancesLandingCardHandlersType): void => {
  handleContractModalButtonClickTracking(UNDERSTOOD_CONTRACT_MODAL_EVENT_LABEL);
  setIsContractModalOpen(false);
};

export const FinancingInsurancesLandingCardHandlers = (
  props: FinancingInsurancesLandingCardHandlersType
): FinancingInsurancesLandingCardReturnHandlersType => ({
  handleOpenContractModalButtonClick: () => openContractModalButtonClickHandler(props),
  handleCloseContractModalButtonClick: () => closeContractModalButtonClickHandler(props),
  handleUnderstoodButtonClick: () => understoodButtonClickHandler(props),
});
