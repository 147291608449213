// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalNumberStep4ActionsBuilderReturnTrackingHandlersType } from './types/personal-number-step4-actions-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Constants
import {
  CLICK_BUTTON_TRACKING,
  CONFIRM_EVENT_TRACKING,
} from './constants/personal-number-step4-actions.handlers.constants';

const confirmChangeNumberButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_BUTTON_TRACKING,
    eventLabel: CONFIRM_EVENT_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalNumberStep4ActionsTrackingHandlers = (
  track: TrackBuilderType
): PersonalNumberStep4ActionsBuilderReturnTrackingHandlersType => ({
  handleConfirmChangeNumberButtonClickTracking: () =>
    track(confirmChangeNumberButtonClickTrackingHandler()),
});

export default PersonalNumberStep4ActionsTrackingHandlers;
