// Vendors
import styled from 'styled-components';

export const DashboarPointsLineGraphComponentStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
  width: 100%;
`;
