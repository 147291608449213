// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { LoginErrorBuilderReturnTrackingHandlersType } from './types/login-error-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const retryAgainButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'retryAgain',
  },
  type: TrackingEventsEnum.LINK,
});

const enterLoginErrorViewTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: 'loginError',
    sectionFamily: 'cobranded',
    sectionCategory: 'services',
    sectionName: 'login',
    interactionType: 'page',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const LoginErrorTrackingHandlers = (
  track: TrackBuilderType
): LoginErrorBuilderReturnTrackingHandlersType => ({
  handleRetryAgainButtonClickTracking: () => track(retryAgainButtonClickTrackingHandler()),
  handleEnterLoginErrorViewTracking: () => track(enterLoginErrorViewTrackingHandler()),
});

export default LoginErrorTrackingHandlers;
