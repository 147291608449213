const DOMAIN = 'cobrandedCC.services.userSettings.privateArea.cardSettings.cbResetPin.';

const PAGE_TITLE = `${DOMAIN}pageTitle`,
  TITLE = `${DOMAIN}title`,
  MESSAGE_INFO_CARD = `${DOMAIN}messageInfoCard`,
  MESSAGE_INFO_CHALLENGE = `${DOMAIN}messageInfoChallenge`,
  HOLDER_NAME_LABEL = `${DOMAIN}cardHolder`,
  CARD_NUMBER_LABEL = `${DOMAIN}cardNumber`;

export {
  CARD_NUMBER_LABEL,
  HOLDER_NAME_LABEL,
  MESSAGE_INFO_CARD,
  MESSAGE_INFO_CHALLENGE,
  PAGE_TITLE,
  TITLE,
};
