// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const FinancingContentContainerStyled = styled.header`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    align-items: center;
    flex-direction: column;
  }
`;
FinancingContentContainerStyled.displayName = 'FinancingContentContainerStyled';

export { FinancingContentContainerStyled };
