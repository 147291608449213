// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const OrchestratorWrapperContainerStyled = styled.div<{
  fetching: boolean;
  pageLoader: boolean;
}>`
  ${({ fetching, pageLoader }) => (fetching || pageLoader) && { height: '100vh' }};
`;
const OrchestratorContainerStyled = styled.main<{ notchSize: number }>`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  padding-top: ${({ notchSize }) => convertPxsToRems({ pixels: notchSize, base: 14 })};
  position: relative;
  justify-content: flex-start;
  width: 100%;

  ${mobileMediaQuery} {
    gap: 0;
  }
`;
OrchestratorContainerStyled.displayName = 'OrchestratorContainerStyled';

export { OrchestratorContainerStyled };
