// Constants
import constants from 'containers/card-settings/views/landing/components/toggles/services/utils/constants/card-settings-toggles.services.utils.constants';
// Enumerations
import { CardSettingsToggleServiceActivationEnum } from './enums/cars-settings-toggles.services.enum';
// Utilities
import { cardActive } from 'utils/card.utils';

const getCardNextAction = (status: string): string =>
  cardActive(status)
    ? CardSettingsToggleServiceActivationEnum.TURN_OFF
    : CardSettingsToggleServiceActivationEnum.TURN_ON;

const getTurnOffOnEndpointAccordingStatus = (status: string): string =>
  `${constants.FETCH_CARD_SETTINGS_CARD_ACTIVATION_SERVICE.endpoint}${getCardNextAction(status)}`;

export { getTurnOffOnEndpointAccordingStatus };
