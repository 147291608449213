// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { AppContextConsumerHook } from 'contexts/app.context';
// Styles
import { CardSettingsContractCurrentTravelPlusMessageComponentWrapper } from './card-settings-contract-travel-plus-message.component.styled';
// Translations
import { TRAVEL_PLUS_SUBSCRIPTION_VALID_UNTIL_MESSAGE } from './translations/card-settings-contract-travel-plus-message-component.translations';
// Utils
import { showExpirationDate } from './utils/card-settings-contract-travel-plus-message-component.utils';

const CardSettingsContractCurrentTravelPlusMessageComponent = (): React.ReactElement | null => {
  const { currentCard } = AppContextConsumerHook(),
    { expirationDate, activated } = currentCard?.travelPlus || {};

  return showExpirationDate({ activated, expirationDate }) ? (
    <CardSettingsContractCurrentTravelPlusMessageComponentWrapper>
      <ParagraphComponent bold>
        <FormattedMessageComponent
          id={TRAVEL_PLUS_SUBSCRIPTION_VALID_UNTIL_MESSAGE}
          values={{
            date: (
              <FormattedDate
                {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
                value={expirationDate}
              />
            ),
          }}
        />
      </ParagraphComponent>
    </CardSettingsContractCurrentTravelPlusMessageComponentWrapper>
  ) : null;
};

export { CardSettingsContractCurrentTravelPlusMessageComponent };
