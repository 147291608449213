// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// Components
import { AssetComponent, ButtonComponent, TitleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MAXIMUM_FRACTION_DIGITS } from '../../constants/credit-limit-decrease-cc.constants';
import {
  DEFAULT_CURRENCY,
  LOWEST_CREDIT_LIMIT,
  LOWEST_CREDIT_LIMIT_ASSET_PROPS,
  LOWEST_CREDIT_LIMIT_BUTTON_PROPS,
  LOWEST_CREDIT_LIMIT_TEXT_PROPS,
} from './constants/lowest-credit-limit.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Styles
import { LowestCreditLimitComponentStyled } from './lowest-credit-limit.component.styled';
// Translations
import {
  LOWEST_CREDIT_LIMIT_BUTTON_TEXT,
  LOWEST_CREDIT_LIMIT_TITLE,
} from './translations/lowest-credit-limit.translations';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const LowestCreditLimitComponent = (): React.ReactElement => {
  const navigate = useNavigate();

  return (
    <LowestCreditLimitComponentStyled>
      <AssetComponent {...LOWEST_CREDIT_LIMIT_ASSET_PROPS} />
      <TitleComponent {...LOWEST_CREDIT_LIMIT_TEXT_PROPS}>
        <FormattedMessageComponent
          id={LOWEST_CREDIT_LIMIT_TITLE}
          values={{
            amount: (
              <FormattedNumber
                {...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS}
                currency={DEFAULT_CURRENCY}
                value={LOWEST_CREDIT_LIMIT}
                maximumFractionDigits={MAXIMUM_FRACTION_DIGITS}
              />
            ),
          }}
        />
      </TitleComponent>
      <ButtonComponent
        {...LOWEST_CREDIT_LIMIT_BUTTON_PROPS}
        onClick={() => navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING))}
      >
        <FormattedMessageComponent id={LOWEST_CREDIT_LIMIT_BUTTON_TEXT} />
      </ButtonComponent>
    </LowestCreditLimitComponentStyled>
  );
};

export { LowestCreditLimitComponent };
