// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  FetchErrorComponent,
  ParagraphComponent,
  RadioButtonComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import { TransactionsEppStep1SimulationOptionsAllComponent } from './components/all/transactions-epp-step1-simulation-options-all.component';
// Configurations
import { getTransactionEppInstallmentsPlanOptionsConfiguration } from './configurations/transactions-epp-step1-simulation-options.configuration';
// Constants
import {
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_DESCRIPTION_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_LIST_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_TITLE_PROPS,
} from './constants/transactions-epp-step1-simulation-options.constants';
// Hooks
import { useTransactionsEppStep1SimulationOptionsHook } from './hooks/transactions-epp-step1-simulation-options.hook';
// Styles
import {
  LayoutColumnFlexComponentStyled,
  ParagraphSecondaryComponentStyled,
} from 'styles/app.styled';
// Translations
import {
  DESCRIPTION,
  INSTALLMENT,
  TITLE,
} from './translations/transactions-epp-step1-simulation-options.translations';
// Types
import { TransactionsEppStep1SimulationOptionType } from './types/transactions-epp-step1-simulation-option.type';
// Utilities
import { isEppSimulationOptionSelected } from './utils/transactions-epp-step1-simulation-options.utils';

const TransactionsEppStep1SimulationOptionsComponent = (): React.ReactElement => {
  const {
    fetching,
    formatMessage,
    error,
    handleSelectInstallmentOptionRadioButtonClick,
    handleSimulateEppInstallmentPlanEvent: onChange,
    maxNumOfInstallments,
    minNumOfInstallments,
    ...props
  } = useTransactionsEppStep1SimulationOptionsHook();

  return (
    <LayoutColumnFlexComponentStyled {...TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_PROPS}>
      <TitleComponent {...TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </TitleComponent>
      <ParagraphSecondaryComponentStyled>
        <ParagraphComponent {...TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
      </ParagraphSecondaryComponentStyled>
      <LayoutColumnFlexComponentStyled
        {...TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_LIST_PROPS}
      >
        {getTransactionEppInstallmentsPlanOptionsConfiguration({
          maxNumOfInstallments,
          minNumOfInstallments,
        }).map(({ id, ...rest }: TransactionsEppStep1SimulationOptionType, key: number) => (
          <RadioButtonComponent
            {...rest}
            disabled={fetching}
            key={key}
            onChange={() => handleSelectInstallmentOptionRadioButtonClick(id)}
            title={<FormattedMessageComponent id={INSTALLMENT} values={{ number: id }} />}
            value={isEppSimulationOptionSelected({ ...props, id })}
          />
        ))}
        <TransactionsEppStep1SimulationOptionsAllComponent {...{ fetching, onChange }} />
      </LayoutColumnFlexComponentStyled>
    </LayoutColumnFlexComponentStyled>
  );
};

export { TransactionsEppStep1SimulationOptionsComponent };
