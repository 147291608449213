// Resolvers
import { fetchContractingPPITermsFileResolver } from '../resolvers/financing-ppi-contracting-step2-terms-link.resolver';
// Types
import {
  FinancingPPIContractingStepFeaturesSectionBuilderHandlersType,
  FinancingPPIContractingStepFeaturesSectionBuilderReturnHandlersType,
} from './types/financing-ppi-contracting-step2-features-section.handlers.type';

const openFinancingPPIContractingConditionsFileLinkClickHandler = async ({
  handleOpenFinancingPPIContractingConditionsFileLinkClickTracking,
  setToastConfiguration,
}: FinancingPPIContractingStepFeaturesSectionBuilderHandlersType): Promise<void> => {
  handleOpenFinancingPPIContractingConditionsFileLinkClickTracking();

  const [response, error] = await fetchContractingPPITermsFileResolver();

  error ? setToastConfiguration({}) : window.open(response);
};

const FinancingPPIContractingStep2TermsLinkHandlers = (
  props: FinancingPPIContractingStepFeaturesSectionBuilderHandlersType
): FinancingPPIContractingStepFeaturesSectionBuilderReturnHandlersType => ({
  handleOpenFinancingPPIContractingConditionsFileLinkClick: () =>
    openFinancingPPIContractingConditionsFileLinkClickHandler(props),
});

export { FinancingPPIContractingStep2TermsLinkHandlers };
