// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Translations
import sectionsTranslations from 'translations/sections.translations';
import translations from 'components/title-section/components/rwd/utils/translations/title-section-rwd.utils.translations';

const cleanConditionalPathItems = (item: string): boolean => !item.includes(':');

const getPathNameUrlItems = (breadCrumbPath?: string): string[] => {
  let actualPath = window.location.pathname
    .split(CharactersEnum.SLASH)
    .filter(cleanConditionalPathItems)
    .filter(item => sectionsTranslations[item]);

  actualPath = breadCrumbPath ? actualPath.splice(0, 1, breadCrumbPath) : actualPath;

  return actualPath;
};

const getLastPathNameUrlItem = (): string => getPathNameUrlItems().pop() || '';

const getBreadCrumbCurrentItemTitle = (section = getLastPathNameUrlItem()): string =>
  sectionsTranslations[section] || translations.ROUTE_UNKNOWN;

const getMappedBreadCrumbTitlePath = (path: string): string =>
  getPathNameUrlItems(path).map((item: string): string => getBreadCrumbCurrentItemTitle(item))[0];

export {
  getBreadCrumbCurrentItemTitle,
  getPathNameUrlItems,
  getLastPathNameUrlItem,
  getMappedBreadCrumbTitlePath,
};
