// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const EXPIRED_KYC_MODAL_DEFAULT_ASSET_PROPS = {
  id: 'Attention_Alternative',
  sizes: {
    height: 120,
    width: 120,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'cancel-modal-asset',
};

const MODAL_PROPS = {
  testId: 'expired-kyc-modal',
};

export { EXPIRED_KYC_MODAL_DEFAULT_ASSET_PROPS, MODAL_PROPS };
