// Vendors
import styled from 'styled-components';

const AmountDetailsModalDescriptionComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    text-align: left;
  }
`;

const AmountDetailsModalRowDescriptionComponentStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingXxxs} 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AmountDetailsModalRowLineSeparatorComponentStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
  width: 100%;
`;

export {
  AmountDetailsModalDescriptionComponentStyled,
  AmountDetailsModalRowDescriptionComponentStyled,
  AmountDetailsModalRowLineSeparatorComponentStyled,
};
