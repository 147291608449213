// Vendors
import styled from 'styled-components';

const SupportCancelContractSidebarComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  margin: 0;
`;

SupportCancelContractSidebarComponentStyled.displayName =
  'SupportCancelContractSidebarComponentStyled';

export { SupportCancelContractSidebarComponentStyled };
