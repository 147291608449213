// Vendors
import { paragraphConstants } from '@openbank/cb-ui-commons';

const VALUE_PARAGRAPH_PROPS = {
  bold: true,
  size: paragraphConstants.SIZES.M,
};
const DETAIL_CONFIRMATION_COMPONENT_PROPS = { testId: 'product-detail-wrapper' };

export { VALUE_PARAGRAPH_PROPS, DETAIL_CONFIRMATION_COMPONENT_PROPS };
