// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Types
import {
  CardSettingsCheck3dWizardStep2ChallengeBuilderHandlersType,
  CardSettingsCheck3dWizardStep2ChallengeBuilderReturnHandlersType,
  CardSettingsCheck3dWizardStep2ChallengeMethodBuilderHandlersType,
  CardSettingsCheck3dWizardStep2ChallengeMethodBuilderReturnHandlersType,
} from './types/card-settings-check-3d-wizard-step2-challenge.handlers.type';

const check3DChallengeErrorEventHandler = ({
  setResult,
}: Pick<CardSettingsCheck3dWizardStep2ChallengeBuilderHandlersType, 'setResult'>) => {
  setResult(OperativeResultsEnumeration.ERROR);
};

const check3DChallengeSuccessEventHandler = ({
  progressId,
  setProgressId,
}: CardSettingsCheck3dWizardStep2ChallengeMethodBuilderReturnHandlersType) => {
  setProgressId(progressId);
};

const CardSettingsCheck3dWizardStep2ChallengeHandlers = ({
  setProgressId,
  setResult,
}: CardSettingsCheck3dWizardStep2ChallengeBuilderHandlersType): CardSettingsCheck3dWizardStep2ChallengeBuilderReturnHandlersType => ({
  handleCheck3DChallengeErrorEvent: () => check3DChallengeErrorEventHandler({ setResult }),
  handleCheck3DChallengeSuccessEvent: ({
    progressId,
  }: CardSettingsCheck3dWizardStep2ChallengeMethodBuilderHandlersType) =>
    check3DChallengeSuccessEventHandler({ progressId, setProgressId }),
});

export default CardSettingsCheck3dWizardStep2ChallengeHandlers;
