// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { PersonalSidebarCCComponent } from 'containers/personal/components/sidebar-cc/personal-sidebar-cc.component';
// Constants
import {
  PERSONAL_ADDRESS_SIDEBAR_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_DESCRIPTION_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_MESSAGE_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_MESSAGE_01_PROPS,
  PERSONAL_ADDRESS_SIDEBAR_MESSAGE_02_PROPS,
} from './constants/personal-address-sidebar.constants';
// Styles
import {
  PersonalAddressSidebarComponentStyled,
  PersonalAddressSidebarMessageComponentStyled,
} from './personal-address-sidebar.component.styled';
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION,
  MESSAGE_1,
  MESSAGE_2,
  TITLE,
} from './translations/personal-address-sidebar.translations';

export const PersonalAddressSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <PersonalSidebarCCComponent />
    <SidebarComponent
      {...PERSONAL_ADDRESS_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <PersonalAddressSidebarComponentStyled>
        <ParagraphComponent {...PERSONAL_ADDRESS_SIDEBAR_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <MessageComponent {...PERSONAL_ADDRESS_SIDEBAR_MESSAGE_PROPS}>
          <PersonalAddressSidebarMessageComponentStyled>
            <ParagraphComponent {...PERSONAL_ADDRESS_SIDEBAR_MESSAGE_01_PROPS}>
              <FormattedMessageComponent id={MESSAGE_1} />
            </ParagraphComponent>
            <br />
            <ParagraphComponent {...PERSONAL_ADDRESS_SIDEBAR_MESSAGE_02_PROPS}>
              <FormattedMessageComponent id={MESSAGE_2} />
            </ParagraphComponent>
          </PersonalAddressSidebarMessageComponentStyled>
        </MessageComponent>
      </PersonalAddressSidebarComponentStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);
