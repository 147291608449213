// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import { fetchProfileConsentsService } from '../services/profile-consents.services';
// Utilities
import validateApiType from 'utils/validate-type';
// Validators
import { consentsValidator as validator } from './validators/profile-consents-step1.resolver.validators';
// Types
import { ProfileConsentsServiceType } from '../services/types/profile-consents.services.type';

const fetchProfileConsentsResolver = async (): Promise<
  [ProfileConsentsServiceType | null, boolean]
> => {
  const [response, { status }] = await validateApiType(fetchProfileConsentsService(), validator);

  if (httpStatusUtils.isSuccessStatus(status)) {
    return [response[0], false];
  }

  return [null, true];
};

export { fetchProfileConsentsResolver };
