// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_UPDATE_CONSENT_TRACKING_PROPS } from './constants/financing-ppi-contracting-step2-consent.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingStep2ConsentTrackingHandlersType } from './types/financing-ppi-contracting-step2-consent.tracking.handlers.type';
import { FinancingPPIContractingStep2ConsentBuilderHandlersType } from './types/financing-ppi-contracting-step2-consent.handlers.type';

const changePPIContractingConsentRadioChangeTrackingHandler = (
  consentId: FinancingPPIContractingStep2ConsentBuilderHandlersType['consentId']
): TrackingLinkEventReturnType => ({
  payload: {
    ...FINANCING_PPI_CONTRACTING_STEP_2_UPDATE_CONSENT_TRACKING_PROPS,
    eventLabel: consentId,
  },
  type: TrackingEventsEnum.LINK,
});

const FinancingPPIContractingStep2ConsentTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingStep2ConsentTrackingHandlersType => ({
  handleChangePPIContractingConsentRadioChangeTracking: (
    consentId: FinancingPPIContractingStep2ConsentBuilderHandlersType['consentId']
  ) => track(changePPIContractingConsentRadioChangeTrackingHandler(consentId)),
});

export { FinancingPPIContractingStep2ConsentTrackingHandlers };
