// Vendors
import styled from 'styled-components';

const RecoveryKeyStep4SecurityQuestionsComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
RecoveryKeyStep4SecurityQuestionsComponentStyled.displayName =
  'RecoveryKeyStep4SecurityQuestionsComponentStyled';

export { RecoveryKeyStep4SecurityQuestionsComponentStyled };
