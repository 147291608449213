// Vendors
import { cookies, sessionUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/login-gaps.handlers.constants';
// Enumerations
import {
  StorageCookiesEnum,
  StorageLocalEnum,
} from '@openbank/cf-ui-static-data';
import { LoginErrorEnum } from 'containers/login/enumerations/login.enumeration';
// Resolvers
import { getPasswordPositionsResolver, loginUserResolver } from '../resolvers/login-gaps.resolvers';
// Types
import { LoginGapsBuilderReturnApiHandlersType } from './types/login-gaps-builder-return.api.handlers.type';
import { LoginGapsBuilderApiHandlersType } from './types/login-gaps-builder.api.handlers.type';
import { LoginGapsMethodBuilderApiHandlersType } from './types/login-gaps-method-builder.api.handlers.type';
import { LoginGapsMethodContinueBuilderApiHandlersType } from './types/login-gaps-method-continue-builder.api.handlers.type';
import { LoginGapsApiLoginReturnType } from '../types/login-gaps-api-login-return.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import {
  getEnvironmentDomain,
  getLoginRedirectUrl,
  getRememberMeFlagMethod,
} from './utils/login-gaps-api.handlers.utils';
import { isContinueButtonDisabled } from 'containers/login/views/gaps/utils/login-gaps.utils';
import { IS_CUSTOMER, PUBLIC_DOMAIN } from 'constants/storage/storage.constants';

const checkSetupSaveRememberMeFlag = (isRememberMe: boolean): any => {
  localStorage[getRememberMeFlagMethod(isRememberMe)](StorageLocalEnum.REMEMBER_USER, String(true));
};

const continuePasswordGapsButtonClickHandler = async ({
  captcha,
  captchaRef,
  email: username,
  fetching,
  error: loginError,
  handleContinuePasswordGapsButtonClickTracking,
  handlePasswordPositionInputErrorTracking,
  password,
  passwordPositions: passwordPosition,
  rememberMe: isRememberMe,
  setCaptcha,
  setFetching,
  setError,
  setPassword,
  setPasswordPositions,
}: LoginGapsMethodContinueBuilderApiHandlersType): Promise<void> => {

  if (isContinueButtonDisabled({ captcha, fetching, password, error: loginError })) {
    return;
  }

  handleContinuePasswordGapsButtonClickTracking();
  setFetching(true);

  const [response, error] = await loginUserResolver({
    captcha,
    isRememberMe,
    password,
    passwordPosition,
    username,
  });

  if (error || !response) {
    const [positions, err] = await getPasswordPositionsResolver(username);
    const isUserLogged = localStorage.getItem(StorageLocalEnum.IS_USER_LOGGED);

    if (!err) {
      setPasswordPositions(positions as string[]);
    }
    handlePasswordPositionInputErrorTracking();
    setPassword('');
    setError(isUserLogged ? LoginErrorEnum.UNAUTHORIZED : LoginErrorEnum.ONBOARDING_INCOMPLETE);
    setFetching(false);

    captchaRef.current?.resetCaptcha();
    setCaptcha('');
  } else {
    const { isOnboardingIncomplete = false } = response as LoginGapsApiLoginReturnType;
    const { accessKeyExpired = false } = response as LoginGapsApiLoginReturnType;

    sessionUtils.saveSessionData({ ...response, domain: getEnvironmentDomain() });
    localStorage.setItem(constants.LOGIN_FROM_PRIVATE, String(true));
    localStorage.setItem(IS_CUSTOMER, String(true));
    localStorage.setItem(StorageCookiesEnum.USERNAME, username);
    localStorage.setItem(StorageLocalEnum.IS_USER_LOGGED, String(true));
    cookies.setCookie({ name: IS_CUSTOMER, value: true, domain: PUBLIC_DOMAIN });
    cookies.setCookie({
      name: StorageCookiesEnum.ONBOARDING_COMPLETED,
      value: !isOnboardingIncomplete,
      domain: getEnvironmentDomain(),
    });
    cookies.setCookie({
      name: StorageCookiesEnum.ACCESS_KEY_EXPIRED,
      value: accessKeyExpired,
      domain: getEnvironmentDomain(),
    });
    checkSetupSaveRememberMeFlag(isRememberMe);
    window.location.replace(generateAbsoluteRoute(getLoginRedirectUrl(isOnboardingIncomplete)));
  }
};

const setupPasswordPositionsViewHandler = async ({
  email: username,
  handleEnterLoginPasswordPositionsViewTracking,
  setFetching,
  setPasswordPositions,
}: LoginGapsMethodBuilderApiHandlersType): Promise<void> => {
  handleEnterLoginPasswordPositionsViewTracking();
  setFetching(true);

  const [positions, error] = await getPasswordPositionsResolver(username);

  if (!error) {
    setPasswordPositions(positions as string[]);
  }
  setFetching(false);
};

const LoginGapsApiHandlers = (
  props: LoginGapsBuilderApiHandlersType
): LoginGapsBuilderReturnApiHandlersType => ({
  handleContinuePasswordGapsButtonClick: () => continuePasswordGapsButtonClickHandler(props),
  handleSetupPasswordPositionsViewHandler: () => setupPasswordPositionsViewHandler(props),
});

export default LoginGapsApiHandlers;
