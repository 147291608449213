// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Translations
import titleTranslations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title.translations';

const PROFILE_PERSONAL_NAME_TITLE_EMPTY_PROPS = {
    label: titleTranslations.TITLE,
  },
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_TITLE_PROPS = {
    bold: false,
    overflow: true,
    testId: 'profile-personal-name-button-title-empty-title-section',
    tag: titleConstants.TAGS.H6,
  },
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_ASSET_PROPS = {
    id: 'add',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'profile-personal-name-button-title-empty-asset',
  };

export {
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_PROPS,
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_TITLE_PROPS,
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_ASSET_PROPS,
};
