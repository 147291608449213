// Constants
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const LINE_SEPARATOR_PROPS = {
  color: AmazonTheme.default.colors.backgroundTertiaryD,
};

const TITLE_PROPS = {
  bold: true,
  size: 'L',
};

export { LINE_SEPARATOR_PROPS, TITLE_PROPS };
