// Resolvers
import { requestSignatureKeyResolver } from 'containers/security-keys/views/request-signature-key/resolvers/request-signature-key.resolver';
// Types
import { RequestSignatureKeyStep2ChallengeBuilderReturnHandlersType } from './types/request-signature-key-step2-builder-return.handlers.type';
import { RequestSignatureKeyStep2BuilderHandlersType } from './types/request-signature-key-step2.handlers.type';

const requestSignatureKeyButtonClick = async ({
  progressId,
  setFetching,
  setResult,
}: RequestSignatureKeyStep2BuilderHandlersType): Promise<void> => {
  setFetching(true);

  const [, error] = await requestSignatureKeyResolver({ progressId });

  if (error) {
    setResult(false);
  } else {
    setResult(true);
  }

  setFetching(false);
};

const RequestSignatureKeyStep2Handlers = (
  props: RequestSignatureKeyStep2BuilderHandlersType
): RequestSignatureKeyStep2ChallengeBuilderReturnHandlersType => ({
  handleRequestSignatureKeyButtonClick: () => requestSignatureKeyButtonClick(props),
});

export default RequestSignatureKeyStep2Handlers;
