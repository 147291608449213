// Vendors
import styled from 'styled-components';

const CardPinInfoModalComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  justify-content: center;
  width: 100%;
`;
CardPinInfoModalComponentStyled.displayName = 'CardPinInfoModalComponentStyled';

const CardPinInfoModalComponentListStyled = styled.ul`
  width: 100%;
`;
CardPinInfoModalComponentListStyled.displayName = 'CardPinInfoModalComponentListStyled';

const CardPinInfoModalComponentListItemStyled = styled.li`
  margin: 0;
  padding: 0;
`;
CardPinInfoModalComponentListItemStyled.displayName = 'CardPinInfoModalComponentListItemStyled';

const CardPinInfoModalComponentImageStyled = styled.li`
  display: flex;
  justify-content: center;
  align-items: center;
`;
CardPinInfoModalComponentImageStyled.displayName = 'CardPinInfoModalComponentImageStyled';

export {
  CardPinInfoModalComponentStyled,
  CardPinInfoModalComponentListStyled,
  CardPinInfoModalComponentListItemStyled,
  CardPinInfoModalComponentImageStyled
};
