// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

export default {
  LABELS_LINK_CARD_TITLE_PROPS: {
    tag: titleConstants.TAGS.H4,
  },
  LABELS_LINK_CARD_TITLE_DEFAULT_PROPS: {
    useTitleHeader: true,
  },
  DEFAULT_LABELS_LINK_CARD_TESTING_PROPS: {
    descriptionId: 'labels-links-card-table-row-description',
    linkId: 'labels-links-card-links',
    titleId: 'labels-links-card-title-section',
  },
};
