// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import { ChangeIbanStep2ActionsHandlers } from '../handlers/change-iban-step2-actions.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ChangeIbanStep2ActionsHookType } from './types/change-iban-step2-actions.hook.type';

const ChangeIbanStep2ActionsHook = (): ChangeIbanStep2ActionsHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const { formatMessage } = useCbIntl();
  const { challenged, error, progressId, ibanValue } = ChangeIbanContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { cardContractId: cardId } = currentCard || {};

  return {
    ...ChangeIbanStep2ActionsHandlers({
      cardId,
      ibanValue,
      progressId,
      setFetching,
      setResult,
    }),
    challenged,
    error,
    fetching,
    formatMessage,
  };
};

export { ChangeIbanStep2ActionsHook };
