// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Events handlers
import SettingsCardControlLocationsFilteredHandlers from '../handlers/settings-card-control-locations-filtered.handlers';
// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { SettingsCardControlLocationsFilteredHookType } from './types/settings-card-control-locations-filtered.hook.type';

const SettingsCardControlLocationsFilteredHook =
  (): SettingsCardControlLocationsFilteredHookType => {
    const { formatMessage } = useCbIntl();

    const { continents, keyword } = SettingsCardControlLocationsContextConsumerHook();

    const [filteredCountries, setFilteredCountries] =
      useState<CardControlContinentComponentType[]>(continents);

    const [filteredContinents, setFilteredContinents] =
      useState<CardControlContinentComponentType[]>(continents);

    const { handleFilterCountriesContinentEvent } = SettingsCardControlLocationsFilteredHandlers({
      continents,
      formatMessage,
      keyword,
      setFilteredCountries,
      setFilteredContinents,
    });
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      handleFilterCountriesContinentEvent();
    }, [keyword, continents]);

    return {
      filteredCountries,
      filteredContinents,
      formatMessage,
    };
  };

export default SettingsCardControlLocationsFilteredHook;
