// Vendors
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Contexts
import { useQuickActionsContextConsumerHook } from 'contexts/quick-actions/quick-actions.context';
// Enumerations
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

export const FinancingRouterInsurancesComponent = (): React.ReactElement => {
  const { quickActions } = useQuickActionsContextConsumerHook();

  if (!quickActions[QuickActionsSectionEnumeration.FINANCING]?.PPI) {
    return <Outlet />;
  }

  return <Navigate to={generateAbsoluteRoute(RoutesEnumeration.FINANCING)} />;
};
