// Enumerations
import { CardControlIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-ids.enumeration';
// Translations
import translations from '../translations/settings-card-control-locations-toggle.translations';

export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_TOGGLE_PROPS: {
    id: CardControlIdsEnumeration.COUNTRIES,
    translations,
  },
};
