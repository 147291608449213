// Vendors
import React from 'react';
// Components
import { AccordionSideMenuComponent } from '@openbank/cb-ui-commons';
// Hooks
import { useDocumentationCategoryListHook } from './hooks/documentation-category-list.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import { DocumentationCategoryListComponentStyled } from './documentation-category-list.component.styles';
// Types
import { type DocumentationCategoryListPropsType } from './types/documentation-category-list.type';
// Utils
import { generateAccordionItems } from './utils/documentation-category-list.component.utils';
import { isIosWebView } from '@gruposantander/web-ui-framework';

export const DocumentationCategoryListComponent = ({
  categories,
  onClick,
}: DocumentationCategoryListPropsType): React.ReactElement => {
  const { categorySelected, formatMessage } = useDocumentationCategoryListHook();

  return (
    <AppCardSectionComponentStyled>
      <DocumentationCategoryListComponentStyled>
        {categories && (
          <AccordionSideMenuComponent
            isIosDevice={isIosWebView()}
            items={generateAccordionItems({ categories, formatMessage })}
            itemSelected={categorySelected}
            onClick={category => onClick(category)}
          />
        )}
      </DocumentationCategoryListComponentStyled>
    </AppCardSectionComponentStyled>
  );
};
