// Types
import { LoginAccessDataBuilderReturnTrackingHandlersType } from './types/login-access-data-return.tracking.handlers.type';
import { LoginAccessDataBuilderReturnHandlersType } from './types/login-access-data-return.handlers.type';
// Utils
import { getVerifyAccountUrl } from 'containers/login/utils/login.utils';

const identifyButtonClickHandler = ({
  handleIdenfityButtonClickTracking,
}: LoginAccessDataBuilderReturnTrackingHandlersType): void => {
  handleIdenfityButtonClickTracking(getVerifyAccountUrl());
  window.location.replace(getVerifyAccountUrl());
};

const LoginAccessDataHandlers = ({
  handleIdenfityButtonClickTracking,
}: LoginAccessDataBuilderReturnTrackingHandlersType): LoginAccessDataBuilderReturnHandlersType => ({
  handleIdenfityButtonClick: () =>
    identifyButtonClickHandler({ handleIdenfityButtonClickTracking }),
});

export { LoginAccessDataHandlers };
