// Types
import { UpdateNextButtonHandlerPropsType } from './types/update-next-button-props.component.handler.type';
import { Step2CreditLimitIncreaseHandlersReturnType } from './types/step-2-credit-limit-increase-return.component.handler.type';

const updateNextButtonHandler = ({ step2, setStep2 }: UpdateNextButtonHandlerPropsType): void => {
  const nextDisabled =
    !step2.isEmploymentDataReady ||
    !step2.isPersonalSituationReady ||
    !step2.isExpensesReady;

  if (step2.nextDisabled !== nextDisabled) {
    setStep2?.({ ...step2, nextDisabled });
  }
};

const Step2CreditLimitIncreaseHandlers = ({
  step2,
  setStep2,
}: UpdateNextButtonHandlerPropsType): Step2CreditLimitIncreaseHandlersReturnType => ({
  handleUpdateNextButton: () => updateNextButtonHandler({ step2, setStep2 }),
});

export default Step2CreditLimitIncreaseHandlers;
