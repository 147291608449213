// Constants
import constants from 'configurations/constants/app.configuration.constants';
// Traslations
import { ERROR_EXIT_LINK } from 'translations/common.translations';
// Types
import { AppReturnConfigurationType } from 'configurations/types/app-return.configuration.type';
import {
  AppConfigurationType,
  GetErrorLinkConfigurationReturnType,
  GetErrorLinkConfigurationType,
} from 'configurations/types/app.configuration.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const getFetchErrorConfiguration = ({
  assetId = constants.ERROR_DEFAULT_ASSET_ID,
  buttonConfiguration,
  error,
  formatMessage,
  linkConfiguration,
  translations = constants.ERROR_DEFAULT_TRANSLATIONS,
  type,
}: AppConfigurationType): AppReturnConfigurationType | Record<string, any> =>
  error
    ? {
        ...constants.ERROR_CONFIGURATION_PROPS,
        ...(buttonConfiguration ? { buttonConfiguration } : {}),
        ...(linkConfiguration ? { linkConfiguration } : {}),
        type,
        assetConfiguration: {
          ...constants.ERROR_ASSET_CONFIGURATION_PROPS.assetConfiguration,
          id: assetId,
        },
        description: formatMessage({ id: translations.ERROR_DESCRIPTION }),
        title: translations.ERROR_TITLE && formatMessage({ id: translations.ERROR_TITLE }),
      }
    : {};

const getErrorLinkConfiguration = ({
  formatMessage,
  route,
  navigate,
}: GetErrorLinkConfigurationType): GetErrorLinkConfigurationReturnType => ({
  children: formatMessage({ id: ERROR_EXIT_LINK }),
  onClick: () => navigate(generateAbsoluteRoute(route)),
});

export { getFetchErrorConfiguration, getErrorLinkConfiguration };
