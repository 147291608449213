// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { SettingsCardControlContextConsumerHook } from 'containers/card-settings/views/card-control/contexts/settings-card-control.context';
// Events handlers
import SettingsCardControlsHandlers from '../handlers/settings-card-controls.handlers';
import SettingsCardControlsTrackingHandlers from '../handlers/settings-card-controls.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SettingsCardControlTabsHookType } from './types/settings-card-control-tabs.hook.type';

const SettingsCardControlTabsHook = (): SettingsCardControlTabsHookType => {
  const { formatMessage } = useCbIntl();

  const { tabSelectedId, setTabSelectedId } = SettingsCardControlContextConsumerHook();

  return {
    ...SettingsCardControlsHandlers({
      ...AppTrackingHook(SettingsCardControlsTrackingHandlers),
      setTabSelectedId,
    }),
    formatMessage,
    tabSelectedId,
  };
};

export default SettingsCardControlTabsHook;
