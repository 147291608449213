// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { SecurityCheckModalStepEnumerations } from './enumerations/security-check-modal-step.enumerations';
// Handlers
import SecurityCheckModalHandlers from '../handlers/security-check-modal.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { SecurityCheckModalHookType } from './types/security-check-modal.hook.type';
import { SecurityCheckModalHookPropsType } from './types/security-check-modal-props.hook.type';

const SecurityCheckModalHook = ({
  challenge,
  setChallenge,
  setChallengeError,
  setError,
  setShowSecurityCheckModal,
  showSecurityCheckModal,
}: SecurityCheckModalHookPropsType): SecurityCheckModalHookType => {
  const { formatMessage } = useCbIntl();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  const [modalStep, setModalStep] = useState<SecurityCheckModalStepEnumerations>(
    SecurityCheckModalStepEnumerations.FIRST
  );

  useEffect(() => {
    if (!showSecurityCheckModal) {
      setModalStep(SecurityCheckModalStepEnumerations.FIRST);
    }
  }, [showSecurityCheckModal]);

  return {
    cardId,
    formatMessage,
    modalStep,
    ...SecurityCheckModalHandlers({
      challenge,
      setChallenge,
      setChallengeError,
      setError,
      setModalStep,
      setShowSecurityCheckModal,
    }),
  };
};

export { SecurityCheckModalHook };
