// Utilities
import { isArray, isString } from 'utils/validate-type';
// Types
import { Consent } from '../../types/consent.type';
import { SubConsentType } from '../../types/sub-consent.type';
import { Validator } from 'utils/validate-type/types/validate-type.types';

const consentsValidator: Validator<any> = {
  consentTypes: isArray<Consent>({
    consentType: isString,
    subTypes: isArray<SubConsentType>({
      consentSubType: isString,
    }),
    channelReceived: isString,
  }),
  arrangementId: isString,
  customerId: isString,
  productTypeTCode: isString,
};

export { consentsValidator };
