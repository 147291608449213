// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getChallengeConfiguration } from './configurations/security-check-modal-second-step.configurations';
// Constants
import { CONTENT_PARAGRAPH_PROPS } from './constants/security-check-modal.constants';
// Hooks
import { SecurityCheckModalSecondStepHook } from './hooks/security-check-modal-second-step.hook';
// Translations
import { CONTENT } from './translations/security-check-modal-second-step.translations';
// Types
import { SecurityCheckModalSecondStepComponentType } from './types/security-check-modal-second-step.type';
// Styles
import { SecurityCheckModalSecondStepComponentStyled } from './security-check-modal-second-step.component.styled';

const SecurityCheckModalSecondStepComponent = ({
  cardId,
  challengeError,
  formValuesParams,
  handleChallengeError,
  handleChallengeSuccess,
  handleRetryChallengeError,
  metadata,
  serviceConfiguration,
}: SecurityCheckModalSecondStepComponentType): React.ReactElement | null => {
  const { phone } = SecurityCheckModalSecondStepHook();

  return (
    <SecurityCheckModalSecondStepComponentStyled>
      <ParagraphComponent {...CONTENT_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={CONTENT} values={{ phone }} />
      </ParagraphComponent>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          cardId,
          challengeError,
          handleChallengeError,
          handleChallengeSuccess,
          handleRetryChallengeError,
          formValuesParams,
          metadata,
          serviceConfiguration,
        })}
      />
    </SecurityCheckModalSecondStepComponentStyled>
  );
};

export { SecurityCheckModalSecondStepComponent };
