// Resolvers
import { fetchReportProblemResolver } from 'containers/transactions/views/report/resolvers/transactions-report.resolvers';
// Constants
import { DISPUTE_PARAMS } from 'containers/transactions/views/report/components/subscription-already-cancelled/components/confirmation/constants/report-subscription-already-cancelled-confirmation.constants';
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/service-cancelled/contexts/constants/transactions-report-service-cancelled.context.constants';
// Types
import { ReportSubscriptionAlreadyCancelledConfirmationActionsHandlersType } from './types/report-subscription-already-cancelled-confirmation-actions.handlers.type';
import { ReportSubscriptionAlreadyCancelledConfirmationActionsHandlersReturnType } from './types/report-subscription-already-cancelled-confirmation-actions-return.handlers.type';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const confirmButtonClickHandler = async ({
  cancelledSubDateBefore,
  cardId,
  challenge,
  files,
  setCancelledSubDateBefore,
  setCaseId,
  setChallengeError,
  setError,
  setFiles,
  setResult,
  transactionId,
}: ReportSubscriptionAlreadyCancelledConfirmationActionsHandlersType): Promise<void> => {
  try {
    if (!cardId || !transactionId) throw new Error();

    const [response, error] = await fetchReportProblemResolver({
      cardId,
      transactionId,
      data: {
        ...DISPUTE_PARAMS,
        subscriptionCanceled: {
          cancelledSubDateBefore: cancelledSubDateBefore,
        },
        documents: getDocumentIds(files),
      },
      progressId: challenge?.progressId,
    });

    if (error) {
      if (isWrongOTPError(error)) {
        setChallengeError(true);
      } else {
        setCancelledSubDateBefore(undefined);
        setFiles(FILES_INITIAL_STATE);
        setResult(false);
      }
    } else {
      setResult(true);
      setCaseId(response?.caseId);
    }
  } catch {
    setError(true);
  }
};

export const ReportSubscriptionAlreadyCancelledConfirmationActionsHandlers = (
  props: ReportSubscriptionAlreadyCancelledConfirmationActionsHandlersType
): ReportSubscriptionAlreadyCancelledConfirmationActionsHandlersReturnType => ({
  handleConfirmButtonClick: () => confirmButtonClickHandler(props),
});
