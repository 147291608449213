// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PERSONAL_EMAIL_STEP_1_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_1_MESSAGE_TITLE_PROPS,
  PERSONAL_EMAIL_STEP_1_MESSAGE_DESCRIPTION_PROPS,
} from './constants/personal-email-step1-message.constants';
import { PersonalEmailStep1MessageViewStyled } from '../../personal-email-step1.component.styled';
// Translations
import {
  MESSAGE_DESCRIPTION,
  MESSAGE_TITLE,
} from './translations/personal-email-step1-message.translations';

const PersonalEmailStep1MessageComponent = (): React.ReactElement => (
  <MessageComponent {...PERSONAL_EMAIL_STEP_1_MESSAGE_PROPS}>
    <PersonalEmailStep1MessageViewStyled>
      <ParagraphComponent {...PERSONAL_EMAIL_STEP_1_MESSAGE_TITLE_PROPS}>
        <FormattedMessageComponent id={MESSAGE_TITLE} />
      </ParagraphComponent>
      <ParagraphComponent {...PERSONAL_EMAIL_STEP_1_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
      </ParagraphComponent>
    </PersonalEmailStep1MessageViewStyled>
  </MessageComponent>
);

export { PersonalEmailStep1MessageComponent };
