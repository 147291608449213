// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_DESCRIPTION_PROPS,
} from './constants/financing-ppi-contracting-download-error.constants';
// Styles
import { FinancingPPIContractingDownloadErrorComponentStyled } from './financing-ppi-contracting-download-error.component.styled';
// Types
import { FinancingPPIContractingDownloadErrorComponentType } from './types/financing-ppi-contracting-download-error.component.type';

const FinancingPPIContractingDownloadErrorComponent = ({
  error,
  errorDocumentDownloaded,
}: FinancingPPIContractingDownloadErrorComponentType): React.ReactElement | null =>
  errorDocumentDownloaded ? (
    <FinancingPPIContractingDownloadErrorComponentStyled>
      <AssetComponent {...FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_ASSET_PROPS} />
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={error} />
      </ParagraphComponent>
    </FinancingPPIContractingDownloadErrorComponentStyled>
  ) : null;

export { FinancingPPIContractingDownloadErrorComponent };
