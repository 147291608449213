// Translations
import { INVALID_DATE_ERROR_MESSAGE } from '../translations/transactions-report.translations';
// Types
import { DocumentIdsType } from 'containers/transactions/views/report/types/document-ids.type';
import { FileDataType } from 'types/file-data.type';
import { SetErrorIfInvalidDatePropsType } from './types/set-error-if-invalid-date-props.type';
import { HasRequiredDocsPropsType } from './types/transactions-report-utils.type';

const getDocumentIds = (files: FileDataType[]): DocumentIdsType => {
  const documentIds: DocumentIdsType = [];

  for (const { documentId } of files) {
    if (documentId) {
      documentIds.push({ documentId });
    }
  }

  return documentIds;
};

const setErrorIfInvalidDate = ({
  control,
  hasInvalidDate,
  dateName,
}: SetErrorIfInvalidDatePropsType): void => {
  if (hasInvalidDate) {
    control.setError(dateName, { message: INVALID_DATE_ERROR_MESSAGE });
  }
};

const hasRequiredDocs = ({ files, requiredDocType }: HasRequiredDocsPropsType): boolean =>
  files.some(({ documentId, documentType }) => documentId && documentType === requiredDocType);

const getMaskedPan = (pan: string): string => {
  if (!pan) return '';
  return `***${pan.slice(-4)}`;
};

export { getDocumentIds, getMaskedPan, hasRequiredDocs, setErrorIfInvalidDate };
