// Vendors
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
// Enumerations
import { SectionDividerTypesEnumeration } from './enums/section-divider-types.enumeration';
// Styles
import { SectionCreditLimitIncreaseStyled } from './section-credit-limit-increase.component.styled';
// Types
import { SectionCreditLimitIncreaseComponentPropsType } from './types/section-credit-limit-increasae.component.type';

const SectionCreditLimitIncreaseComponent = ({
  title,
  children,
}: SectionCreditLimitIncreaseComponentPropsType): React.ReactElement => {
  return (
    <SectionCreditLimitIncreaseStyled>
      <SectionDividerComponent
        {...{ title }}
        titleBold={false}
        type={SectionDividerTypesEnumeration.INFORMATIVE}
      />
      {children}
    </SectionCreditLimitIncreaseStyled>
  );
};

export default SectionCreditLimitIncreaseComponent;
