// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_01_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_02_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_03_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_PROPS,
} from './constants/notifications-settings-sidebar-info.constants';
// Styles
import { NotificationsSettingsSidebarComponentStyled } from '../../notifications-settings-sidebar.component.styled';
// Translations
import {
  DESCRIPTION_1,
  DESCRIPTION_2,
  DESCRIPTION_3,
  TITLE,
} from './translations/notifications-settings-sidebar-info.translations';

const NotificationSettingsSidebarInfoComponent = (): React.ReactElement => (
  <SidebarComponent
    {...NOTIFICATION_SETTINGS_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <NotificationsSettingsSidebarComponentStyled>
      <ParagraphComponent {...NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_01_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_1} />
      </ParagraphComponent>
      <ParagraphComponent {...NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_02_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_2} />
      </ParagraphComponent>
      <ParagraphComponent {...NOTIFICATION_SETTINGS_SIDEBAR_DESCRIPTION_03_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_3} />
      </ParagraphComponent>
    </NotificationsSettingsSidebarComponentStyled>
  </SidebarComponent>
);

export { NotificationSettingsSidebarInfoComponent };
