export const GET_DEVICES_PROPS = {
  endpoint: 'mb-api-sss-devices/device/list',
};
export const GET_DEVICES_PARAMS = {
  hidden: false
};
export const GET_TRUSTED_DEVICE_PROPS = {
  endpoint: 'mb-api-sss-devices/device/trusted',
};
export const GET_SESSONS_PROPS = {
  endpoint: 'mb-api-sss-devices/session/list',
};
export const MODIFY_DEVICE_ALIAS_PROPS = {
  endpoint: 'mb-api-sss-devices/device/alias',
};
export const BLOCK_DEVICE_PROPS = {
  endpoint: 'mb-api-sss-devices/device/block',
};
export const UNBLOCK_DEVICE_PROPS = {
  endpoint: 'mb-api-sss-devices/device/unblock',
};
export const UNROLL_TRUSTED_DEVICE_PROPS = {
  endpoint: 'mb-api-sss-devices/device/untrust',
};
export const SIGN_OUT_DEVICE_PROPS = {
  endpoint: 'mb-api-auth/authentication/session/invalidate',
};