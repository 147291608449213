// Enumerations
import { PaymentMethodsEnumeration } from '../enumerations/payment-methods.enumeration';
import { RevolvingMethodsEnumeration } from '../enumerations/revolving-methods.enumeration';
import { BillingPaymentMethodEnumeration } from 'enumerations/billing-payment-method.enumeration';
// Types
import { PaymentMethodHandlersType } from './types/payment-method.handlers.type';
import { PaymentMethodReturnHandlersType } from './types/payment-method-return.handlers.type';
import { SelectCurrentMethodHandlerType } from './types/select-current-method-handler.type';

const selectCurrentMethodHandler = async ({
  accountBalancePercentage,
  fixedPaymentAmount,
  paymentMethodCode,
  setFee,
  setPaymentMethod,
  setRevolvingMethod,
}: SelectCurrentMethodHandlerType) => {
  const setters = {
    [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: () => {
      setPaymentMethod(PaymentMethodsEnumeration.FULL_PAYMENT);
    },
    [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: () => {
      setPaymentMethod(PaymentMethodsEnumeration.RESOLVING_AMOUNT);
      setRevolvingMethod(RevolvingMethodsEnumeration.FIX_AMOUNT);
      setFee(fixedPaymentAmount);
    },
    [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]: () => {
      setPaymentMethod(PaymentMethodsEnumeration.RESOLVING_AMOUNT);
      setRevolvingMethod(RevolvingMethodsEnumeration.PERCENTAGE_AMOUNT);
      setFee(accountBalancePercentage);
    },
  };

  setters[paymentMethodCode]?.();
};

const PaymentMethodHandlers = (
  props: PaymentMethodHandlersType
): PaymentMethodReturnHandlersType => ({
  handleSelectCurrentMethod: () => selectCurrentMethodHandler(props),
});

export default PaymentMethodHandlers;
