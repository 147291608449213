// Vendors
import styled from 'styled-components';

export const SettingsCardControlLocationsWarningComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding-bottom: ${({ theme }) => theme.spaces.spacingXs};
`;

export const SettingCardControlLocationsTopWarningComponentStyled = styled(
  SettingsCardControlLocationsWarningComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  padding-bottom: 0;
`;
