// Components
import PersonalEmailStep1Component from '../components/step1/personal-email-step1.component';
import PersonalEmailStep2Component from '../components/step2/personal-email-step2.component';
import PersonalEmailStep3Component from '../components/step3/personal-email-step3.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  LINK_LABEL,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
} from '../translations/personal-email.translations';
import { TITLE as STEP_1_TITLE } from '../components/step1/translations/personal-email-step1.translations';
import { TITLE as STEP_2_TITLE } from '../components/step2/translations/personal-email-step2.translations';
import { TITLE as STEP_3_TITLE } from '../components/step3/translations/personal-email-step3.translations';

export default {
  PERSONAL_EMAIL_RIGHT_COLUMN_PROPS: {
    maxWidth: 276,
  },
  PERSONAL_EMAIL_WIZARD_STEPS: {
    feedbackConfiguration: {
      errorConfiguration: {
        description: ERROR_DESCRIPTION,
        title: ERROR_TITLE,
        exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
      },
      successConfiguration: {
        description: SUCCESS_DESCRIPTION,
        linkLabel: LINK_LABEL,
        title: SUCCESS_TITLE,
        exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
      },
    },
    steps: [
      {
        component: PersonalEmailStep1Component,
        title: STEP_1_TITLE,
      },
      {
        component: PersonalEmailStep2Component,
        title: STEP_2_TITLE,
      },
      {
        component: PersonalEmailStep3Component,
        title: STEP_3_TITLE,
      },
    ],
  },
};
