// Translations
import { NO_TRANSACTION_DESCRIPTION } from './translations/transaction-description.utils.translations';
// Types
import { TransactionDescriptionUtilsType } from './types/transaction-description.utils.type';

const getTransactionDescriptionLabel = ({
  description,
  merchantDetails,
  formatMessage,
  poEditorNarrative,
  narrative,
}: TransactionDescriptionUtilsType): string =>
  poEditorNarrative?.key
    ? formatMessage(
        { id: poEditorNarrative.key },
        Object.fromEntries(poEditorNarrative.params.map(param => [param.key, param.value]))
      )
    : narrative ||
      description ||
      merchantDetails?.name ||
      formatMessage({ id: NO_TRANSACTION_DESCRIPTION });

export { getTransactionDescriptionLabel };
