// Vendors
import { assetConstants, paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_MAIN_ASSET_PROPS: {
    sizes: {
      height: 32,
      width: 32,
    },
    testId: 'settings-card-control-locations-main-asset',
    type: assetConstants.TYPES.FLAG,
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_MAIN_TITLE_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'settings-card-control-locations-main-title',
  },
};
