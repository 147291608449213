// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import FinancingFastCashSecondStepActionsHandlers from '../handlers/financing-fast-cash-second-step-actions.handlers';
// Hooks
import CardsHook from 'providers/cards/hooks/cards.hook';
// Types
import { FinancingFastCashSecondStepActionsHookType } from './types/financing-fast-cash-second-step-actions.hook.type';
import { FinancingFastCashSecondStepActionsHookReturnType } from './types/financing-fast-cash-second-step-actions-return.hook.type';
// Utils
import { getAccountIdWithoutWhiteSpace } from 'containers/financing/views/fast-cash/utils/financing-fast-cash.utils';

const FinancingFastCashSecondStepActionsHook = ({
  handleRetryChallengeError,
}: FinancingFastCashSecondStepActionsHookType): FinancingFastCashSecondStepActionsHookReturnType => {
  const { handleSetupCardInformationEvent } = CardsHook();
  const { amountValue, challenge, setAmountValue, setChallenge } =
      FinancingFastCashContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {},
    { primaryAccountId: accountId } = currentCard || CharactersEnum.NO_VALUE;

  return {
    ...FinancingFastCashSecondStepActionsHandlers({
      accountId: getAccountIdWithoutWhiteSpace(accountId),
      amountValue,
      cardId,
      challenge,
      handleRetryChallengeError,
      setAmountValue,
      setChallenge,
      setResult,
      handleSetupCardInformationEvent,
    }),
    challenge,
  };
};

export default FinancingFastCashSecondStepActionsHook;
