// Types
import {
  CardSettingsChangePinRepeatedPinBuilderHandlersType,
  CardSettingsChangePinRepeatedPinBuilderReturnHandlersType,
  CardSettingsChangePinRepeatedPinMethodBuilderHandlersType,
} from './types/card-settings-change-pin-repeated-pin.handlers.type';
// Utilities
import { isValidRepeatedPin } from './utils/card-settings-change-pin-repeat-password.handlers.utils';
import { pinFilledIsFilled } from 'containers/card-settings/views/change-pin/utils/card-settings-change-pin.utils';

const saveCardSettingsChangePinRepeatedPinChangeHandler = ({
  dateOfBirth,
  handleCardSettingsChangePinRepeatedPinErrorTracking,
  newPin,
  repeatedPin,
  setError,
  setRepeatedPin,
}: CardSettingsChangePinRepeatedPinMethodBuilderHandlersType): void => {
  setRepeatedPin(repeatedPin);
  setError(false);

  if (pinFilledIsFilled(repeatedPin) && !isValidRepeatedPin({ dateOfBirth, newPin, repeatedPin })) {
    handleCardSettingsChangePinRepeatedPinErrorTracking();
    setError(true);
  }
};

const CardSettingsChangePinRepeatedPinHandlers = (
  props: CardSettingsChangePinRepeatedPinBuilderHandlersType
): CardSettingsChangePinRepeatedPinBuilderReturnHandlersType => ({
  handleSaveCardSettingsChangePinRepeatedPinChange: (repeatedPin: string) =>
    saveCardSettingsChangePinRepeatedPinChangeHandler({ ...props, repeatedPin }),
});

export default CardSettingsChangePinRepeatedPinHandlers;
