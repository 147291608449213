// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { FinancingPPIContractingStep2ExpandableLinkHandlers } from '../handlers/financing-ppi-contracting-step2-expandable-link.handlers';
import { FinancingPPIContractingStep2ExpandableLinkTrackingHandlers } from '../handlers/financing-ppi-contracting-step2-expandable-link.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep2ExpandableDescriptionLinkMethodsHandlersType } from '../handlers/types/financing-ppi-contracting-step2-expandable-description-link.handlers.type';

const useFinancingPPIContractingStep2ExpandableLinkHook =
  (): FinancingPPIContractingStep2ExpandableDescriptionLinkMethodsHandlersType => {
    const { localization } = AppContextConsumerHook(),
      { cfg_public_cpi_general_tnc: url } = localization?.config || {};

    return FinancingPPIContractingStep2ExpandableLinkHandlers({
      ...AppTrackingHook(FinancingPPIContractingStep2ExpandableLinkTrackingHandlers),
      url,
    });
  };

export { useFinancingPPIContractingStep2ExpandableLinkHook };
