// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
// Handlers
import FinancingFastCashAmountHandlers from '../handlers/amount.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Translations
import { INPUT_ERRORS } from '../translations/amount.translations';
// Types
import { FinancingFastCashAmountCommonHookType } from './types/amount.hook.common.type';

const FinancingFastCashAmountCommonHook = (): FinancingFastCashAmountCommonHookType => {
  const { formatMessage, formatNumber } = useCbIntl();
  const { fastCashLimits, fastCashCommission, amountValue, setAmountValue, setDisableNextBtn } =
    FinancingFastCashContextConsumerHook();
  const [inputErrorMsg, setInputErrorMsg] = useState<string | null>('');

  return {
    amountValue,
    formatMessage,
    formatNumber,
    inputErrorMsg: inputErrorMsg ? formatMessage({ id: INPUT_ERRORS[inputErrorMsg] }) : '',
    fastCashCommission,
    fastCashLimits,
    setAmountValue,
    setDisableNextBtn,
    setInputErrorMsg,
  };
};

export { FinancingFastCashAmountCommonHook };
