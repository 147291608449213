// Services
import { validateSignatureKeyService } from '../services/security-keys-signature-key-change-second-step.services';
// Types
import { SignatureKeyValidationResolverPropsType } from './types/security-keys-signature-key-change-second-step.validation.resolver.type';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';

const validateSignatureKeyResolver = async ({
  signatureKey,
}: SignatureKeyValidationResolverPropsType): Promise<[Record<string, any>, boolean]> => {
  const [response, { status }] = await validateSignatureKeyService({
    signatureKey,
  });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export { validateSignatureKeyResolver };
