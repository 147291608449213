// Vendors
import { messageConstants, paragraphConstants } from '@openbank/cb-ui-commons';

const TRAVEL_PLUS_MESSAGE_PROPS = {
    testId: 'travel-plus-message',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  TRAVEL_PLUS_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'travel-plus-message-description',
  },
  TRAVEL_PLUS_MESSAGE_TITLE_PROPS = {
    bold: true,
    testId: 'travel-plus-message-title',
  };

export {
  TRAVEL_PLUS_MESSAGE_DESCRIPTION_PROPS,
  TRAVEL_PLUS_MESSAGE_PROPS,
  TRAVEL_PLUS_MESSAGE_TITLE_PROPS,
};
