const DOMAIN = 'cobrandedCC.services.financing.creditLimitDecrease.';
const ERROR_RESULT_DOMAIN = `${DOMAIN}errorResult.`;
const SUCCESS_RESULT_DOMAIN = `${DOMAIN}successResult.`;
const STEP_1_DOMAIN = `${DOMAIN}step1.`;
const STEP_2_DOMAIN = `${DOMAIN}step2.`;

export const ERROR_TITLE = `${ERROR_RESULT_DOMAIN}errorTitle`;
export const ERROR_SUBTITLE = `${ERROR_RESULT_DOMAIN}errorSubtitle`;
export const ERROR_LINK = `${ERROR_RESULT_DOMAIN}errorLink`;

export const SUCCESS_TITLE = `${SUCCESS_RESULT_DOMAIN}successTitle`;
export const SUCCESS_SUBTITLE = `${SUCCESS_RESULT_DOMAIN}successSubtitle`;
export const SUCCESS_LINK = `${SUCCESS_RESULT_DOMAIN}successLink`;

export const STEP_1_TITLE = `${STEP_1_DOMAIN}title`;

export const STEP_2_TITLE = `${STEP_2_DOMAIN}title`;
