// Vendors
import React from 'react';
// Components
import { ChangeIbanStep1ActionsComponent } from './components/actions/change-iban-step1-actions.component';
import { CheckboxComponent, ParagraphComponent, InputTextComponent } from '@openbank/cb-ui-commons';
import { IbanMessageComponent } from '../iban-message/iban-message.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import SepaModalComponent from './components/sepa-modal/sepa-modal.component';
// Constants
import {
  CHANGE_IBAN_CHECKBOX_PROPS,
  CHANGE_IBAN_INPUT_PROPS,
  CHANGE_IBAN_STEP1_MESSAGE,
} from './constants/change-iban-step1.constants';
// Hooks
import { ChangeIbanStep1Hook } from './hooks/change-iban-step1.hook';
// Styles
import {
  ChangeIbanStep1ContainerStyled,
  ChangeIbanStep1InputContainerStyled,
} from './change-iban-step1.component.styled';
// Translations
import translations from './translations/change-iban-step1.translations';
// Utils
import { formatIban } from '@openbank/cf-ui-framework';

const ChangeIbanStep1Component = (): React.ReactElement => {
  const {
    checkboxValue,
    error,
    formatMessage,
    handleChangeCheckboxEvent,
    handleCheckNewIbanInputEvent,
    handleCloseSepaModalLinkClick,
    handleOpenSepaModalLinkClick,
    handleSaveNewIbanInputEvent,
    ibanValue,
    openSepaModal,
    sepaMandate,
  } = ChangeIbanStep1Hook();

  return (
    <ChangeIbanStep1ContainerStyled>
      <ParagraphComponent {...CHANGE_IBAN_STEP1_MESSAGE}>
        <FormattedMessageComponent id={translations.MESSAGE_01} />
      </ParagraphComponent>
      <IbanMessageComponent linkAction={handleOpenSepaModalLinkClick} sepaMandate={sepaMandate} />
      <ChangeIbanStep1InputContainerStyled>
        <InputTextComponent
          {...CHANGE_IBAN_INPUT_PROPS}
          errorMessage={error && <FormattedMessageComponent id={translations.INPUT_ERROR} />}
          helperMessage={formatMessage({ id: translations.INPUT_HELPER_MESSAGE })}
          label={formatMessage({ id: translations.INPUT_LABEL })}
          onBlur={handleCheckNewIbanInputEvent}
          onChange={handleSaveNewIbanInputEvent}
          placeholder={formatMessage({ id: translations.INPUT_PLACEHOLDER })}
          value={formatIban({ iban: ibanValue })}
        />
      </ChangeIbanStep1InputContainerStyled>
      <CheckboxComponent
        {...CHANGE_IBAN_CHECKBOX_PROPS}
        disabled={false}
        onChange={handleChangeCheckboxEvent}
        value={checkboxValue}
        helperMessage={formatMessage({ id: translations.CHECKBOX_LABEL }, { br: <br /> })}
      />
      <ChangeIbanStep1ActionsComponent />
      <SepaModalComponent isOpen={openSepaModal} onClose={handleCloseSepaModalLinkClick} />
    </ChangeIbanStep1ContainerStyled>
  );
};

export { ChangeIbanStep1Component };
