// Types
import { SupportCancelContractSecondStepChallengeHandlersReturnType } from './types/support-cancel-contract-second-step-challenge-handlers-return.type';
import { SupportCancelContractSecondStepChallengeHandlersParamsType } from './types/support-cancel-contract-second-step-challenge-handlers.type';
import { SupportCancelContractSecondStepChallengeHandlersManageSuccessOnChangeType } from './types/support-cancel-contract-second-step-challenge-manage-success-handlers.type';
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';

const manageSuccessOnChangeSmsEventHandler = async ({
  setProgressId,
  progressId,
  setIsDisabledLastStep,
  handleSendOtpCodeSuccessStep2TrackingHandler,
  handleSendOtpCodeStep2TrackingHandler,
}: SupportCancelContractSecondStepChallengeHandlersManageSuccessOnChangeType): Promise<void> => {
  handleSendOtpCodeStep2TrackingHandler();
  handleSendOtpCodeSuccessStep2TrackingHandler();
  progressId && setProgressId(progressId);
  setIsDisabledLastStep(false);
};

const SupportCancelContractSecondStepChallengeHandlers = ({
  setProgressId,
  setIsDisabledLastStep,
  handleSendOtpCodeStep2TrackingHandler,
  handleSendOtpCodeSuccessStep2TrackingHandler,
}: SupportCancelContractSecondStepChallengeHandlersParamsType): SupportCancelContractSecondStepChallengeHandlersReturnType => ({
  handleManageSuccessOnChangeSmsEvent: ({ progressId }: ChallengeProgressIdType) =>
    manageSuccessOnChangeSmsEventHandler({
      progressId,
      setProgressId,
      setIsDisabledLastStep,
      handleSendOtpCodeSuccessStep2TrackingHandler,
      handleSendOtpCodeStep2TrackingHandler,
    }),
});

export default SupportCancelContractSecondStepChallengeHandlers;
