// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ButtonComponent, TitleComponent } from '@openbank/cb-ui-commons';
import PersonalPhotoNameButtonUploadedComponent from './components/uploaded/personal-photo-name-button-uploaded.component';
import PersonalPhotoNameButtonUploadModalComponent from './components/upload-modal/personal-photo-name-button-upload-modal.component';
// Constants
import {
  PERSONAL_PHOTO_NAME_BUTTON_BUTTON_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_TITLE_PROPS,
} from './constants/personal-photo-name-button.constants';
// Hooks
import usePersonalPhotoNameButtonHook from './hooks/personal-photo-name-button.hook';
// Styles
import {
  PersonalPhotoNameButtonComponentStyled,
  PersonalPhotoNameButtonInputComponentStyled,
} from './personal-photo-name-button.component.styled';
// Translations
import { UPLOAD_PHOTO } from './translations/personal-photo-name-button.translations';

const PersonalPhotoNameButtonComponent = (): React.ReactElement => {
  const {
    handleOpenInputFilePrompt,
    handleSavePhotoUploadedInputChange,
    handleUploadPhotoButtonClick,
    inputRef,
    userName,
  } = usePersonalPhotoNameButtonHook();

  return (
    <PersonalPhotoNameButtonComponentStyled>
      <TitleComponent {...PERSONAL_PHOTO_NAME_BUTTON_TITLE_PROPS}>{userName}</TitleComponent>
      <ButtonComponent
        {...PERSONAL_PHOTO_NAME_BUTTON_BUTTON_PROPS}
        onClick={handleUploadPhotoButtonClick}
      >
        <FormattedMessageComponent id={UPLOAD_PHOTO} />
        <PersonalPhotoNameButtonInputComponentStyled
          ref={inputRef}
          onChange={handleSavePhotoUploadedInputChange}
        />
      </ButtonComponent>
      <PersonalPhotoNameButtonUploadModalComponent onRetry={handleOpenInputFilePrompt} />
    </PersonalPhotoNameButtonComponentStyled>
  );
};

export default PersonalPhotoNameButtonComponent;
