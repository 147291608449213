// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/card-resume.service.constants';
// Types
import { CardResumeApiReturnComponentType } from '../types/card-resume-api-return.component.type';

const fetchCardResumeCardArtService = async (
  productType: string
): Promise<[CardResumeApiReturnComponentType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...constants.CARD_RESUME_CARD_ART_SERVICE_CONFIGURATION,
    pathParams: { productType },
  });

export { fetchCardResumeCardArtService };
