const DOMAIN = 'cobranded.services.userSettings.privateArea.transactions.transactionsLanding.';

const AMOUNT_TITLE = `${DOMAIN}filterModal.amount.title`,
  CATEGORIES_OPTION_ALL = `${DOMAIN}filterModal.categories.options.all`,
  CATEGORIES_OPTION_ATM = `${DOMAIN}filterModal.categories.options.atm`,
  CATEGORIES_OPTION_EDUCATION_HEALTH_BEAUTY_WELNESS = `${DOMAIN}filterModal.categories.options.educationHealthBeautyWellness`,
  CATEGORIES_OPTION_EXCLUSIVE_MOVEMENTS = `${DOMAIN}filterModal.categories.options.exclusiveMovements`,
  CATEGORIES_OPTION_HOUSE_AND_HOME = `${DOMAIN}filterModal.categories.options.houseAndHome`,
  CATEGORIES_OPTION_INCOME = `${DOMAIN}filterModal.categories.options.income`,
  CATEGORIES_OPTION_INSURANCE_ORGANIZATIONS_ONGS = `${DOMAIN}filterModal.categories.options.insuranceOrganizationsOngs`,
  CATEGORIES_OPTION_INVESTMENTS_SAVINGS = `${DOMAIN}filterModal.categories.options.investmentsSavings`,
  CATEGORIES_OPTION_LEIUSRE_TRAVEL = `${DOMAIN}filterModal.categories.options.leisureTravel`,
  CATEGORIES_OPTION_OTHER_EXPENSES = `${DOMAIN}filterModal.categories.options.otherExpenses`,
  CATEGORIES_OPTION_PROFESSIONAL_EXPENSES = `${DOMAIN}filterModal.categories.options.professionalExpenses`,
  CATEGORIES_OPTION_RENTING = `${DOMAIN}filterModal.categories.options.renting`,
  CATEGORIES_OPTION_RESTAURANTS = `${DOMAIN}filterModal.categories.options.restaurants`,
  CATEGORIES_OPTION_RETAIL_STORES = `${DOMAIN}filterModal.categories.options.retailStores`,
  CATEGORIES_OPTION_TRANSFERS_BANK_CHANGES_LOAN = `${DOMAIN}filterModal.categories.options.transfersBankChangesLoan`,
  CATEGORIES_OPTION_TRANSPORT_AUTOMOVE = `${DOMAIN}filterModal.categories.options.transportAutomove`,
  CATEGORIES_PLACEHOLDER = `${DOMAIN}filterModal.categories.placeholder`,
  CATEGORIES_TITLE = `${DOMAIN}filterModal.categories.title`,
  MODAL_TITLE = `${DOMAIN}filterModal.modalTitle`,
  MOVEMENTS_OPTION_ALL = `${DOMAIN}filterModal.movements.options.all`,
  MOVEMENTS_OPTION_EXPENSES = `${DOMAIN}filterModal.movements.options.expenses`,
  MOVEMENTS_OPTION_INCOME = `${DOMAIN}filterModal.movements.options.income`,
  MOVEMENTS_TITLE = `${DOMAIN}filterModal.movements.title`,
  SITUATION_OPTION_ALL = `${DOMAIN}filterModal.situation.options.all`,
  SITUATION_OPTION_AUTHORISED = `${DOMAIN}filterModal.situation.options.authorised`,
  SITUATION_OPTION_PENDING_SETTLEMENT = `${DOMAIN}filterModal.situation.options.pendingSettlement`,
  SITUATION_OPTION_SETTLED = `${DOMAIN}filterModal.situation.options.settled`,
  SITUATION_TITLE = `${DOMAIN}filterModal.situation.title`,
  STATUS_OPTION_ALL = `${DOMAIN}filterModal.status.options.all`,
  STATUS_OPTION_FINANCED = `${DOMAIN}filterModal.status.options.financed`,
  STATUS_OPTION_INSTALMENT = `${DOMAIN}filterModal.status.options.instalment`,
  STATUS_TITLE = `${DOMAIN}filterModal.status.title`,
  TITLE = `${DOMAIN}title`;

export {
  AMOUNT_TITLE,
  CATEGORIES_OPTION_ALL,
  CATEGORIES_OPTION_ATM,
  CATEGORIES_OPTION_EDUCATION_HEALTH_BEAUTY_WELNESS,
  CATEGORIES_OPTION_EXCLUSIVE_MOVEMENTS,
  CATEGORIES_OPTION_HOUSE_AND_HOME,
  CATEGORIES_OPTION_INCOME,
  CATEGORIES_OPTION_INSURANCE_ORGANIZATIONS_ONGS,
  CATEGORIES_OPTION_INVESTMENTS_SAVINGS,
  CATEGORIES_OPTION_LEIUSRE_TRAVEL,
  CATEGORIES_OPTION_OTHER_EXPENSES,
  CATEGORIES_OPTION_PROFESSIONAL_EXPENSES,
  CATEGORIES_OPTION_RENTING,
  CATEGORIES_OPTION_RESTAURANTS,
  CATEGORIES_OPTION_RETAIL_STORES,
  CATEGORIES_OPTION_TRANSFERS_BANK_CHANGES_LOAN,
  CATEGORIES_OPTION_TRANSPORT_AUTOMOVE,
  CATEGORIES_PLACEHOLDER,
  CATEGORIES_TITLE,
  MODAL_TITLE,
  MOVEMENTS_OPTION_ALL,
  MOVEMENTS_OPTION_EXPENSES,
  MOVEMENTS_OPTION_INCOME,
  MOVEMENTS_TITLE,
  SITUATION_OPTION_ALL,
  SITUATION_OPTION_AUTHORISED,
  SITUATION_OPTION_PENDING_SETTLEMENT,
  SITUATION_OPTION_SETTLED,
  SITUATION_TITLE,
  STATUS_OPTION_ALL,
  STATUS_OPTION_FINANCED,
  STATUS_OPTION_INSTALMENT,
  STATUS_TITLE,
  TITLE,
};
