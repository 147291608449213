// Vendors
import React from 'react';
// Components
import { QuickActionComponent } from '@openbank/cb-ui-commons';
import { ExpiredDocumentModalComponent } from './components/expired-document-modal/expired-document-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { QuickActionsModalComponent } from './components/quick-actions-modal/quick-actions-modal.component';
// Constants
import { QUICK_ACTIONS_SECTION_MAPPER } from './constants/quick-actions-sections.constants';
// Hooks
import { useQuickActionsHook } from './hooks/quick-actions.hook';
// Styles
import { QuickActionsComponentStyled } from 'components/quick-actions/quick-actions.component.styled';
// Types
import { QuickActionsItemComponentType } from './types/quick-actions-item.component.type';
import { QuickActionsComponentType } from './types/quick-actions.component.type';
// Utils
import { getQuickActionsType, getVisibleQuickActions } from './utils/quick-actions.utils';

const QuickActionsComponent = ({
  id,
}: QuickActionsComponentType): React.ReactElement<QuickActionsComponentType> => {
  const {
      handleClickCloseModal,
      handleQuickActionClick,
      isMobile,
      isModalOpen,
      isExpiredDocumentModalOpen,
      quickActions,
      setIsExpiredDocumentModalOpen,
    } = useQuickActionsHook(id),
    mappedQuickActions: QuickActionsItemComponentType[] = QUICK_ACTIONS_SECTION_MAPPER[id].map(
      section => ({
        component: section.component,
        id: section.id,
        label: section.label,
        tracking: section.tracking,
        url: section.url,
      })
    ),
    visibleQuickActions = getVisibleQuickActions({ isMobile, mappedQuickActions });

  return (
    <>
      <QuickActionsComponentStyled columns={visibleQuickActions.length} data-testid={id}>
        {visibleQuickActions.map(
          ({ component, id, label, tracking, url }: QuickActionsItemComponentType, key: number) => (
            <QuickActionComponent
              {...{ ...component }}
              disabled={Boolean(quickActions[id])}
              type={getQuickActionsType(id)}
              key={key}
              label={<FormattedMessageComponent id={label} />}
              onClick={() => handleQuickActionClick({ id, tracking, url })}
            />
          )
        )}
      </QuickActionsComponentStyled>
      <ExpiredDocumentModalComponent
        {...{ isExpiredDocumentModalOpen, setIsExpiredDocumentModalOpen }}
      />
      <QuickActionsModalComponent
        {...{
          handleClickCloseModal,
          handleQuickActionClick,
          isMobile,
          isModalOpen,
          mappedQuickActions,
          quickActions,
        }}
      />
    </>
  );
};

export { QuickActionsComponent };
