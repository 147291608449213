// Translations
import { CONTINENTS_TRANSLATION_KEY_PREFFIX } from '../../continents/translations/settings-card-control-locations-continents.translations';
import { COUNTRIES_PREFFIX } from 'translations/locations.translations';
// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { SettingsCardControlLocationsFilteredBuilderHandlersType } from './types/settings-card-control-locations-filtered-builder.handlers.type';
import { SettingsCardControlLocationsFilteredBuilderReturnHandlersType } from './types/settings-card-control-locations-filtered-builder-return.handlers.type';
import { SettingsCardControlLocationsFilteredUpdateBuilderHandlersType } from './types/settings-card-control-locations-filtered-update-builder.handlers.type';
import { SettingsCardControlLocationsFilteredCountriesContinentEventReturnHandlersType } from './types/settings-card-control-locations-filtered-countries-continents-event-return.handlers.type';
// Utilities
import { filterByKeyword } from './utils/settings-card-control-locations-filtered.handlers.utils';
import { formatLocationTranslationId } from 'containers/card-settings/views/card-control/components/locations/utils/setting-card-control-locations.utils';

const updateFilteredCountries = ({
  continents,
  formatMessage,
  keyword,
}: SettingsCardControlLocationsFilteredUpdateBuilderHandlersType) =>
  continents.map(
    (continent): CardControlContinentComponentType => ({
      ...continent,
      filteredCountries: continent.countries.filter(country =>
        filterByKeyword(
          formatMessage({
            id: formatLocationTranslationId({
              preffix: COUNTRIES_PREFFIX,
              location: country.name2Dig,
            }),
          }),
          keyword
        )
      ),
    })
  );

const updateFilteredContinents = ({
  continents,
  formatMessage,
  keyword,
}: SettingsCardControlLocationsFilteredUpdateBuilderHandlersType) =>
  continents.filter(continent =>
    filterByKeyword(
      formatMessage({
        id: formatLocationTranslationId({
          preffix: CONTINENTS_TRANSLATION_KEY_PREFFIX,
          location: continent.code,
        }),
      }),
      keyword
    )
  );

const filterCountriesContinentEventHandler = ({
  continents,
  keyword,
  formatMessage,
  setFilteredCountries,
  setFilteredContinents,
}: SettingsCardControlLocationsFilteredBuilderHandlersType): SettingsCardControlLocationsFilteredCountriesContinentEventReturnHandlersType => {
  setFilteredCountries(updateFilteredCountries({ continents, formatMessage, keyword }));
  setFilteredContinents(updateFilteredContinents({ continents, formatMessage, keyword }));

  return { setFilteredContinents, setFilteredCountries };
};

const SettingsCardControlLocationsFilteredHandlers = (
  props: SettingsCardControlLocationsFilteredBuilderHandlersType
): SettingsCardControlLocationsFilteredBuilderReturnHandlersType => ({
  handleFilterCountriesContinentEvent: () => filterCountriesContinentEventHandler(props),
});

export default SettingsCardControlLocationsFilteredHandlers;
