// Vendors
import React from 'react';
// Components
import { TitleHeaderComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingMessageComponent } from '../message/financing-ppi-contracting-message.component';
import { FinancingPPIContractingDescriptionLinkComponent } from '../description-link/financing-ppi-contracting-description-link.component';
import { FinancingPPIContractingStep2AddressComponent } from './components/address/financing-ppi-contracting-step2-address.component';
import { FinancingPPIContractingStep2DownloadComponent } from './components/download/financing-ppi-contracting-step2-download.component';
import { FinancingPPIContractingStep2TNCComponent } from './components/tnc/financing-ppi-contracting-step2-tnc.component';
import { FinancingPpiContractingStep2ExpandableComponent } from './components/expandable/financing-ppi-contracting-step2-expandable.component';
import { FinancingPPIContractingResumeComponent } from '../resume/financing-ppi-contracting-resume.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_POLICY_HOLDER_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_SECOND_TITLE_PROPS,
} from './constants/financing-ppi-contracting-step2.constants';
import { FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS } from '../../constants/financing-ppi-contracting.constants';
// Contexts
import { FinancingPPIContractingStep2ContextConsumerHOC } from './contexts/financing-ppi-contracting-step2.context';
// Hooks
import { useFinancingPPIContractingStep2Hook } from './hooks/financing-ppi-contracting-step2.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION,
  SECOND_TITLE,
  TERMS_ACCEPTANCE_CONSENT,
} from './translations/financing-ppi-contracting-step2.translations';

const FinancingPPIContractingStep2BaseComponent = (): React.ReactElement => {
  const {
    handleValidateChecksAndDownloadButtonClick: onClick,
    errorChecksDone: error,
    fetching: loading,
    url,
  } = useFinancingPPIContractingStep2Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS}>
        <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <FinancingPPIContractingStep2DownloadComponent />
        <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={TERMS_ACCEPTANCE_CONSENT} />
        </ParagraphComponent>
        <FinancingPPIContractingStep2AddressComponent />
      </LayoutColumnFlexComponentStyled>
      <TitleHeaderComponent {...FINANCING_PPI_CONTRACTING_STEP_2_SECOND_TITLE_PROPS}>
        <FormattedMessageComponent id={SECOND_TITLE} />
      </TitleHeaderComponent>
      <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS}>
        <FinancingPPIContractingResumeComponent />
        <FinancingPPIContractingStep2TNCComponent />
        <FinancingPPIContractingMessageComponent
          {...{ ...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS, error }}
        />
      </LayoutColumnFlexComponentStyled>
      <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS}>
        <FinancingPPIContractingDescriptionLinkComponent
          {...{ ...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS, url }}
        />
        <FinancingPPIContractingDescriptionLinkComponent
          {...{ ...FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_POLICY_HOLDER_PROPS }}
        />
      </LayoutColumnFlexComponentStyled>
      <FinancingPpiContractingStep2ExpandableComponent />

      <WizardStepFooterComponent
        {...FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ disabled: loading, loading, onClick }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

const FinancingPPIContractingStep2Component = FinancingPPIContractingStep2ContextConsumerHOC(
  FinancingPPIContractingStep2BaseComponent
);

export { FinancingPPIContractingStep2Component };
