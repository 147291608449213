// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import ProfilePersonalDataContactHandlers from '../handlers/profile-personal-data-contact.handlers';
import ProfilePersonalDataContactTrackingHandlers from '../handlers/profile-personal-data-contact.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalDataContactHookType } from './types/profile-personal-data-contact.hook.type';
// Utilities
import { getPrimaryConfigurableTelephone } from './utils/profile-personal-data-contact.hook.utils';

const ProfilePersonalDataContactHook = (): ProfilePersonalDataContactHookType => {
  const { profile } = AppContextConsumerHook(),
    { email, telephones } = profile?.contactDetails || {};

  return {
    ...ProfilePersonalDataContactHandlers({
      ...AppTrackingHook(ProfilePersonalDataContactTrackingHandlers),
      navigate: useNavigate(),
    }),
    email,
    phone: getPrimaryConfigurableTelephone(telephones),
  };
};
export default ProfilePersonalDataContactHook;
