// Components
import { TransactionsReportDifferentAmountAtmAdditionalInfoComponent } from '../components/additional-info/transactions-report-different-amount-atm-additional-info.component';
import { TransactionsReportDifferentAmountAtmConfirmationComponent } from '../components/confirmation/transactions-report-different-amount-atm-confirmation.component';
// Translations
import {
  ADDITIONAL_INFORMATION_TITLE,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
} from 'containers/transactions/views/report/translations/transactions-report.translations';
import { ADDITIONAL_INFORMATION_DESCRIPTION } from '../translations/transactions-report-different-amount-atm.translations';

const STEPS = [
  {
    component: TransactionsReportDifferentAmountAtmAdditionalInfoComponent,
    description: ADDITIONAL_INFORMATION_DESCRIPTION,
    title: ADDITIONAL_INFORMATION_TITLE,
  },
  {
    component: TransactionsReportDifferentAmountAtmConfirmationComponent,
    description: CONFIRMATION_DESCRIPTION,
    title: CONFIRMATION_TITLE,
  },
];

export { STEPS };
