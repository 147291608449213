// Types
import { RecoveryKeyStep1PhoneBuilderHandlersType } from '../types/recovery-key-phone.handlers.type';
// Utilities
import { isValidPhoneNumber } from 'utils/phone.utils';

const phoneIsInvalid = ({
  phoneNumber,
  prefix,
}: Pick<RecoveryKeyStep1PhoneBuilderHandlersType, 'phoneNumber' | 'prefix'>): boolean =>
  Boolean(prefix && phoneNumber && !isValidPhoneNumber({ prefix, phoneNumber }));

export { phoneIsInvalid };
