// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import TransactionsLandingRowDetailsViewInstallmentHandlers from '../handlers/transactions-landing-row-details-view-installment.handlers';
import TransactionsLandingRowDetailsViewInstallmentTrackingHandlers from '../handlers/transactions-landing-row-details-view-installment.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsLandingRowDetailsViewInstallmentBuilderReturnHandlersType } from '../handlers/types/transactions-landing-row-details-installment-builder-return.handlers.type';

const TransactionsLandingRowDetailsViewInstallmentHook = (
  id: string
): TransactionsLandingRowDetailsViewInstallmentBuilderReturnHandlersType =>
  TransactionsLandingRowDetailsViewInstallmentHandlers({
    ...AppTrackingHook(TransactionsLandingRowDetailsViewInstallmentTrackingHandlers),
    id,
    navigate: useNavigate(),
  });

export default TransactionsLandingRowDetailsViewInstallmentHook;
