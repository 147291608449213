// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  TITLE_01,
  TITLE_02,
} from 'containers/transactions/views/epp/components/sidebar/translations/transactions-epp-sidebar.translations';

const TRANSACTIONS_EPP_SIDEBAR_PROPS = {
    testId: 'transactions-epp',
  },
  TRANSACTIONS_EPP_SIDEBAR_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXs,
  },
  TRANSACTIONS_EPP_SIDEBAR_ITEMS = [
    {
      description: DESCRIPTION_01,
      testing: {
        expandable: {
          titleId: 'transactions-epp-sidebar-item-1-title',
        },
        paragraph: 'transactions-epp-sidebar-item-1-description',
      },
      title: TITLE_01,
    },
    {
      description: DESCRIPTION_02,
      testing: {
        expandable: {
          titleId: 'transactions-epp-sidebar-item-2-title',
        },
        paragraph: 'transactions-epp-sidebar-item-2-description',
      },
      title: TITLE_02,
    },
  ];

export {
  TRANSACTIONS_EPP_SIDEBAR_PROPS,
  TRANSACTIONS_EPP_SIDEBAR_LAYOUT_PROPS,
  TRANSACTIONS_EPP_SIDEBAR_ITEMS,
};
