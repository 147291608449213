// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import {
  getCitiesByTermService,
  validateCityWithPostcodeService,
} from '../services/address-modal.services';
// Types
import { ValidateCityWithPostcodeResolverType } from './types/validate-city-with-postcode-resolver.type';
import { ValidateCityWithPostcodeServiceResponseType } from '../services/types/validate-city-with-postcode-service-response.type';
import { GetCitiesByTermServiceResponse } from '../services/types/get-cities-by-term-service-response.type';

const getCitiesByTermResolver = async (
  term: string
): Promise<[GetCitiesByTermServiceResponse] | [null, true]> => {
  const [response, { status }] = await getCitiesByTermService(term);

  return requestHasSuccessStatus(status) && response ? [response] : [null, true];
};

const validateCityWithPostcodeResolver = async (
  props: ValidateCityWithPostcodeResolverType
): Promise<[ValidateCityWithPostcodeServiceResponseType] | [null, true]> => {
  const [response, _] = await validateCityWithPostcodeService(props);

  return response?.status ? [response] : [null, true];
};

export { getCitiesByTermResolver, validateCityWithPostcodeResolver };
