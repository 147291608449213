// Vendors
import { useEffect } from 'react';
// Contexts
import { useTransactionsReportContextConsumerHook } from '../contexts/transactions-report.context';
// Handlers
import { CleanChallengeHandlers } from '../handlers/clean-challenge.handlers';

export const useCleanChallengeHook = (): void => {
  const { setChallenge, setChallengeError } = useTransactionsReportContextConsumerHook();

  const { handleChallengeUnmount } = CleanChallengeHandlers({ setChallenge, setChallengeError });

  useEffect(() => {
    return handleChallengeUnmount;
  }, []);
};
