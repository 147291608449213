const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.step3.additional.';

const TITLE = `${DOMAIN}title`;

const TERMINATION = {
  title: `${DOMAIN}termination.title`,
  description: `${DOMAIN}termination.description`,
};

const NOTIFY = {
  title: `${DOMAIN}notify.title`,
  description: `${DOMAIN}notify.description`,
};

const PAYMENT = {
  title: `${DOMAIN}payment.title`,
  description: `${DOMAIN}payment.description`,
};

export { TITLE, TERMINATION, NOTIFY, PAYMENT };
