// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/personal-number-step2-challenge.service.constants';
// Types
import { PersonalNumberStep2ChallengeValidateApiBuilderType } from '../types/personal-number-step2-challenge-validate-api-builder.type';
import { PersonalNumberStep2ChallengeVerifyReturnHookType } from '../types/personal-number-step2-challenge-verify-return.hook.type';

const validateNewNumberOtpService = async (
  params: PersonalNumberStep2ChallengeValidateApiBuilderType
): Promise<[PersonalNumberStep2ChallengeVerifyReturnHookType | Record<string, any>, Response]> =>
  apiUtilsCb.post({ ...constants.VALIDATE_OTP_SERVICE_CONFIGURATION, params });

export { validateNewNumberOtpService };
