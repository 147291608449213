// Types
import { QuickActionsSectionCategoryItemType } from 'types/quick-actions/quick-actions-section-category-item.type';
import { QuickActionsSectionType } from 'types/quick-actions/quick-actions-section.type';

const convertCategoryItem = (categoryItem: QuickActionsSectionCategoryItemType[]): any =>
  Object.fromEntries(
    Object.entries(Object.assign({}, categoryItem)).map(([, { id, disabled }]) => [id, disabled])
  );

const extractAllActionsFromCardUi = (ui: QuickActionsSectionType): any =>
  Object.fromEntries(
    Object.entries(ui).map(([key, value]) => [key, convertCategoryItem(value.actions)])
  );

const extractAllQuickActionsFromCardUi = (ui: QuickActionsSectionType): any =>
  Object.fromEntries(
    Object.entries(ui).map(([key, value]) => [key, convertCategoryItem(value.quickactions)])
  );

export { extractAllActionsFromCardUi, extractAllQuickActionsFromCardUi };
