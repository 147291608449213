// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsContractCurrentBillingHookType } from 'containers/card-settings/views/contract/components/current/components/billing/hooks/types/card-settings-contract-current-billing.hook.type';

const useCardSettingsContractCurrentBillingHook =
  (): CardSettingsContractCurrentBillingHookType => {
    const { currentCardDetail } = AppContextConsumerHook(),
      { billingCyclePeriodicity = CharactersEnum.NO_VALUE } = currentCardDetail?.creditDetails || {},
      { primaryAccountId = CharactersEnum.NO_VALUE } = currentCardDetail?.contractInfo || {};
    const { formatMessage } = useCbIntl();

    return {
      billingCyclePeriodicity,
      formatMessage,
      primaryAccountId,
    };
  };

export default useCardSettingsContractCurrentBillingHook;
