// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlOperationsToggleComponent from '../toggle/settings-card-control-operations-toggle.component';
// Constants
import constants from './constants/settings-card-control-operations-atm.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import {
  SettingsCardControlOperationsAtmComponentStyled,
  SettingsCardControlOperationsAtmLeftComponentStyled,
  SettingsCardControlOperationsContentAtmComponentStyled,
} from './settings-card-control-operations-atm.component.styled';
// Translations
import translations from './translations/settings-card-control-operations.translations';

const SettingsCardControlOperationsAtmComponent = (): React.ReactElement => (
  <SettingsCardControlOperationsAtmComponentStyled>
    <SettingsCardControlOperationsAtmLeftComponentStyled>
      <AssetComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_ATM_ASSET_PROPS} />
      <SettingsCardControlOperationsContentAtmComponentStyled>
        <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_ATM_TITLE_PROPS}>
          <FormattedMessageComponent id={translations.TITLE} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_ATM_DESCRIPTION_PROPS}>
          <ParagraphSecondaryComponentStyled>
            <FormattedMessageComponent id={translations.DESCRIPTION} />
          </ParagraphSecondaryComponentStyled>
        </ParagraphComponent>
      </SettingsCardControlOperationsContentAtmComponentStyled>
    </SettingsCardControlOperationsAtmLeftComponentStyled>
    <SettingsCardControlOperationsToggleComponent
      {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_ATM_TOGGLE_PROPS}
    />
  </SettingsCardControlOperationsAtmComponentStyled>
);

export default SettingsCardControlOperationsAtmComponent;
