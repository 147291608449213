// Constants
import { messageConstants, titleConstants } from '@openbank/cb-ui-commons';

export default {
  PERSONAL_NUMBER_STEP_3_SUBTITLE_PROPS: {
    testId: 'personal-number-step3-subtitle',
    tag: titleConstants.TAGS.H5,
  },
  PERSONAL_NUMBER_STEP_3_RESUME_ITEM_PROPS: {
    testing: {
      labelId: 'personal-number-step3-resume-item-label',
      valueId: 'personal-number-step3-resume-item-value',
    },
  },
  PERSONAL_NUMBER_STEP_3_MESSAGE_PROPS: {
    testId: 'personal-number-step3-message',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  PERSONAL_NUMBER_STEP_3_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'personal-number-step3-paragraph-description',
  },
  PERSONAL_NUMBER_STEP_3_MESSAGE_TITLE_PROPS: {
    bold: true,
    testId: 'personal-number-step3-message-title',
  },
};
