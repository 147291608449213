// Types
import { ListMapperInterface, ListMapperReturnInterface } from './types/map-list-items.type';

const mapAndTranslateListItems = ({
  formatMessage,
  list,
}: ListMapperInterface): ListMapperReturnInterface[] =>
  list.map((id: string) => ({
    label: formatMessage({ id }),
  }));

export { mapAndTranslateListItems };
