// Types
import { CorrectType, WrongType } from '../types/validate-type-classes.type';
import { ValidateTypeFn } from '../types/validate-type.types';

const getWrongTypes = (
  validationResults: Array<CorrectType | Array<WrongType>>
): Array<WrongType> =>
  validationResults
    .filter(result => Array.isArray(result))
    .map(result => result as WrongType[])
    .reduce((acc: Array<WrongType>, cur) => acc.concat(cur), []);

const isType = <T>(obj: T, field: keyof T, typeToCheck: string): CorrectType | Array<WrongType> =>
  obj && typeof obj?.[field] === typeToCheck
    ? new CorrectType()
    : createWrongType(obj, field, typeToCheck);

const createWrongType = <T>(obj: T, field: keyof T, expectedType: string): Array<WrongType> => {
  const property = obj && obj[field] ? obj[field] : undefined;
  return [
    new WrongType(
      `Field ${String(
        field
      )} is not correct. Found ${typeof property}, but expected ${expectedType}`
    ),
  ];
};

const checkValidation = <T>(result: ValidateTypeFn<T>): boolean =>
  !Array.isArray(result)
    ? true
    : !result.some(obj => obj instanceof WrongType || obj[0] instanceof WrongType);

export { createWrongType, getWrongTypes, isType, checkValidation };
