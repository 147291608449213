// Utils
import { getDevice, hasDevices } from '../utils/device-detail.utils';
// Types
import { DevicesDetailHandlerReturnType } from './types/device-detail.handlers.return.type';
import { DevicesDetailHandlerType } from './types/device-detail.handlers.type';

const getAlldevicesHandler = async ({
  id,
  devices,
  blockedDevices,
  trusted,
  setDevice,
  setError,
  getLastDevices
}) => {
  if (!id) return setError(true);
  if (!hasDevices({ devices, blockedDevices, trusted })) {
    return getLastDevices();
  }

  setDevice(getDevice({ devices, blockedDevices, trusted, id }));
};

const DeviceDetailHandlers = (
  props: DevicesDetailHandlerType
 ): DevicesDetailHandlerReturnType => ({
  getAlldevices: () => getAlldevicesHandler(props)
 });

export { DeviceDetailHandlers };