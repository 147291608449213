// Constants
import translations from '../translations/security-keys-access-devices.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export default {
  SECURITY_KEYS_ACCESS_DEVICES_PROPS: {
    description: translations.DESCRIPTION,
    link: translations.LINK,
    testing: {
      descriptionId: 'security-keys-access-devices-table-row-description',
      linkId: 'security-keys-access-devices-links',
      titleId: 'security-keys-access-devices-title',
    },
    title: translations.TITLE,
    url: RoutesEnumeration.DEVICES,
  },
  SECURITY_KEYS_ACCESS_DEVICES_ENDPOINT: 'mb-api-sss-devices/session/last-login',
};
