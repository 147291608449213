// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsLandingFooterComponentStyled = styled.section`
  width: 100%;

  footer > section {
    padding: 0 ${({ theme }) => theme.spaces.spacingXxxxxl} 0;

    ${tabletMediaQuery} {
      padding: 0 ${({ theme }) => theme.spaces.spacingXl} 0;
    }

    ${mobileMediaQuery} {
      padding: 0 ${({ theme }) => theme.spaces.spacingS} 0;
    }
  }
`;
CardSettingsLandingFooterComponentStyled.displayName = 'CardSettingsLandingFooterComponentStyled';

export { CardSettingsLandingFooterComponentStyled };
