// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { CHANGE_BILLING_ADDRESS_SERVICE_CONFIGURATION } from './constants/personal-address.services.constants';
// Types
import { ChangeBillingAddressServiceType } from './types/change-billing-address-service.type';
import { ChangeBillingAddressServiceResponseType } from './types/change-billing-address-service-response.type';
import { ChallengeType } from 'types/challenge.type';

const changeBillingAddressService = async ({
  params,
  progressId,
}: ChangeBillingAddressServiceType): Promise<
  [ChangeBillingAddressServiceResponseType | ChallengeType, Response]
> =>
  apiUtilsCb.patch({
    ...CHANGE_BILLING_ADDRESS_SERVICE_CONFIGURATION,
    customHeaders: progressId ? { progressId } : {},
    params,
  });

export { changeBillingAddressService };
