// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const TABLE_ROW_DESCRIPTION_POINTS_PROPS = {
  size: paragraphConstants.SIZES.XS,
  rowsEllipsisLength: 1,
  testId: 'table-row-description-points',
};

export { TABLE_ROW_DESCRIPTION_POINTS_PROPS };
