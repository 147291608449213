// Vendors
import React, { createContext, useState, useContext } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/personal-address.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalAddressContextType } from './types/personal-address.context.type';
import { AddressType } from 'types/address.type';
import { ChallengeType } from 'types/challenge.type';

const PersonalAddressContext = createContext<PersonalAddressContextType>(CONTEXT_DEFAULT_VALUE);

export const PersonalAddressContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalAddressContextType>> => {
  const [newAddress, setNewAddress] = useState<AddressType | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [signatureExpired, setSignatureExpired] = useState<boolean>(false);

  return (
    <PersonalAddressContext.Provider
      value={{
        challenge,
        challengeError,
        error,
        isEditing,
        newAddress,
        signatureExpired,
        setChallenge,
        setChallengeError,
        setError,
        setIsEditing,
        setNewAddress,
        setSignatureExpired
      }}
    >
      {children as React.ReactNode}
    </PersonalAddressContext.Provider>
  );
};

export const usePersonalAddressContextConsumerHook = (): PersonalAddressContextType =>
  useContext(PersonalAddressContext);

/* eslint-disable */
export const PersonalAddressContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalAddressContextType>> =>
    (
      <PersonalAddressContextProvider>
        <Component {...props} />
      </PersonalAddressContextProvider>
    );
