import translations from '../translations/support-cancel-contract-reasons.translations';

const REASONS = {
  UNEXPECTED_BANK_CHARGES: translations.REASONS.UNEXPECTED_BANK_CHARGES,
  HIGH_DEBT: translations.REASONS.HIGH_DEBT,
  NOT_ENOUGH_CREDIT_LINE: translations.REASONS.NOT_ENOUGH_CREDIT_LINE,
  HIGH_INTEREST_RATES: translations.REASONS.HIGH_INTEREST_RATES,
  FRAUD_VICTIM: translations.REASONS.FRAUD_VICTIM,
  BETTER_CONDITIONS_IN_ANOTHER_BANK: translations.REASONS.BETTER_CONDITIONS_IN_ANOTHER_BANK,
  FAILED_TO_MEET_EXPECTATIONS: translations.REASONS.FAILED_TO_MEET_EXPECTATIONS,
  BAD_EXPERIENCE_ON_DIGITAL_PLATFORMS: translations.REASONS.BAD_EXPERIENCE_ON_DIGITAL_PLATFORMS,
  BAD_EXPERIENCE_IN_CUSTOMER_SERVICE: translations.REASONS.BAD_EXPERIENCE_IN_CUSTOMER_SERVICE,
  CARD_USAGE_PROBLEMS: translations.REASONS.CARD_USAGE_PROBLEMS,
  CAMPAIGN_PROMOTION: translations.REASONS.CAMPAIGN_PROMOTION,
  NOT_USING_CARD: translations.REASONS.NOT_USING_CARD,
  OTHER_REASON: translations.REASONS.OTHER_REASON,
};

export const PASSWORD_MODULE_ASSET_ID = 'symbols_sign';
export const PASSWORD_MODULE_ASSET_COLOR = 'brandB';
export const CONSTANTS_REASON_NOT_FOUND = 'NOT_FOUND';

export const CONSTANTS_REASONS_OPTIONS = [
  {
    label: REASONS.UNEXPECTED_BANK_CHARGES,
    value: 'C01',
  },
  {
    label: REASONS.HIGH_DEBT,
    value: 'C02',
  },
  {
    label: REASONS.NOT_ENOUGH_CREDIT_LINE,
    value: 'C04',
  },
  {
    label: REASONS.HIGH_INTEREST_RATES,
    value: 'C05',
  },
  {
    label: REASONS.FRAUD_VICTIM,
    value: 'C06',
  },
  {
    label: REASONS.BETTER_CONDITIONS_IN_ANOTHER_BANK,
    value: 'C07',
  },
  {
    label: REASONS.FAILED_TO_MEET_EXPECTATIONS,
    value: 'C08',
  },
  {
    label: REASONS.BAD_EXPERIENCE_ON_DIGITAL_PLATFORMS,
    value: 'C09',
  },
  {
    label: REASONS.BAD_EXPERIENCE_IN_CUSTOMER_SERVICE,
    value: 'C10',
  },
  {
    label: REASONS.CARD_USAGE_PROBLEMS,
    value: 'C11',
  },
  {
    label: REASONS.CAMPAIGN_PROMOTION,
    value: 'C13',
  },
  {
    label: REASONS.NOT_USING_CARD,
    value: 'C14',
  },
  {
    label: REASONS.OTHER_REASON,
    value: 'C15',
  },
];
