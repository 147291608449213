// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useTransactionsReportNotReceivedContextConsumerHook } from 'containers/transactions/views/report/components/not-received/contexts/transactions-report-not-received.context';
// Handlers
import { TransactionsReportNotReceivedAdditionalInfoActionsHandlers } from '../handlers/transactions-report-not-received-additional-info-actions.handlers';
// Types
import { TransactionsReportNotReceivedAdditionalInfoActionsHandlersReturnType } from '../handlers/types/transactions-report-not-received-additional-info-actions-return.handlers.type';

export const useTransactionsReportNotReceivedAdditionalInfoActionsHook =
  (): TransactionsReportNotReceivedAdditionalInfoActionsHandlersReturnType => {
    const { setNotReceivedDate, setDescription } =
      useTransactionsReportNotReceivedContextConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();

    return {
      ...TransactionsReportNotReceivedAdditionalInfoActionsHandlers({
        setNotReceivedDate,
        setDescription,
        setCurrentStep,
      }),
    };
  };
