// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import {
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_01,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_01,
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_02,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_02,
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_03,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_03,
} from '../translations/card-settings-statements-sidebar.translations';

const CARD_STATEMENTS_SIDEBAR_PROPS = {
    testId: 'card-settings-statements',
  },
  CARD_SETTING_STATEMENTS_SIDEBAR_ITEMS = [
    {
      content: CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_01,
      testing: {
        descriptionId: 'card-settings-statements-sidebar-first-row-description',
        titleId: 'card-settings-statements-sidebar-first-row-title',
      },
      title: CARD_STATEMENTS_SIDEBAR_ROW_TITLE_01,
    },
    {
      content: CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_02,
      testing: {
        descriptionId: 'card-settings-statements-sidebar-second-row-description',
        titleId: 'card-settings-statements-sidebar-second-row-title',
      },
      title: CARD_STATEMENTS_SIDEBAR_ROW_TITLE_02,
    },
    {
      content: CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_03,
      testing: {
        descriptionId: 'card-settings-statements-sidebar-third-row-description',
        titleId: 'card-settings-statements-sidebar-third-row-title',
      },
      title: CARD_STATEMENTS_SIDEBAR_ROW_TITLE_03,
    },
  ],
  CARD_STATEMENTS_SIDEBAR_DESCRIPTION_PROPS = {
    size: paragraphConstants.SIZES.M,
  };

export {
  CARD_STATEMENTS_SIDEBAR_PROPS,
  CARD_SETTING_STATEMENTS_SIDEBAR_ITEMS,
  CARD_STATEMENTS_SIDEBAR_DESCRIPTION_PROPS,
};
