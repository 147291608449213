// Handlers
import { OrderModalHandlers } from '../handlers/order-modal.handler';
import { OrderModalTrackingHandlers } from '../handlers/order-modal.tracking.handler';
// Hook
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { OrderModalHookReturnType } from './types/financing-landing-row-details-orders.hook.type';

const OrderModalHooks = (urlAmazon: string): OrderModalHookReturnType => {
  return {
    ...OrderModalHandlers({ ...AppTrackingHook(OrderModalTrackingHandlers), urlAmazon }),
  };
};

export { OrderModalHooks };
