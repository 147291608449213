// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS } from 'containers/financing/views/ppi/components/contracting/constants/financing-ppi-contracting.constants';
// Resolvers
import { FINANCING_CONTRACT_PPI_SERVICE_CONFIGURATION } from '../services/constants/financing-ppi-contracting-step3.service.constants';
// Translations
import * as translations from '../translations/financing-ppi-contracting-step3-download.translations';

const FINANCING_PPI_CONTRACTING_STEP_3_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-contracting-step3-description',
  },
  FINANCING_PPI_CONTRACTING_STEP_3_LAYOUT_CONTENT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_PROPS = {
    documentName: 'Ratenschutzversicherung.pdf',
    serviceMethod: 'get',
    translations,
  },
  FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_SERVICE_PROPS = {
    endpoint: 'cb-api-ppi/v1/ppi/documents/contract',
  },
  FINANCING_PPI_CONTRACTING_STEP_3_CHALLENGE_PROPS = {
    baseService: FINANCING_CONTRACT_PPI_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'post',
  },
  FINANCING_PPI_CONTRACTING_STEP_3_FOOTER_PROPS = {
    ...FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS,
    hasDefaultRightButton: false,
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_3_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_LAYOUT_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_SERVICE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_DOWNLOAD_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_CHALLENGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_3_FOOTER_PROPS,
};
