// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import translations from '../translations/iban-billing-landing.translations';

export default {
  IBAN_BILLING_LANDING_IBAN_CARD_PROPS: {
    description: translations.IBAN_CARD_DESCRIPTION,
    link: translations.IBAN_CARD_LINK,
    testing: {
      descriptionId: 'iban-billing-landing-card-iban-table-row-description',
      linkId: 'iban-billing-landing-card-iban-links',
      titleId: 'iban-billing-landing-card-iban-title-section',
    },
    title: translations.IBAN_CARD_TITLE,
    url: RoutesEnumeration.CHANGE_IBAN,
    useTitleHeader: false,
  },
  IBAN_BILLING_LANDING_BILLING_CARD_PROPS: {
    description: translations.BILLING_CARD_DESCRIPTION,
    link: translations.BILLING_CARD_LINK,
    testing: {
      descriptionId: 'iban-billing-landing-card-billing-table-row-description',
      linkId: 'iban-billing-landing-card-billing-links',
      titleId: 'iban-billing-landing-card-billing-title-section',
    },
    title: translations.BILLING_CARD_TITLE,
    url: RoutesEnumeration.CHANGE_BILLING_CYCLE,
    useTitleHeader: false,
  },
};
