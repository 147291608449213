// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Translations
import {
  HIDE_PIN_TEXT,
  SHOW_PIN_TEXT,
} from '../translations/card-settings-change-pin-new-pin.translations';

const CARD_SETTINGS_CHECK_PIN_NEW_PIN_PROPS = {
  testId: 'card-settings-check-pin-new-pin',
  translations: {
    HIDE_PASSWORD: <FormattedMessageComponent id={HIDE_PIN_TEXT} />,
    SHOW_PASSWORD: <FormattedMessageComponent id={SHOW_PIN_TEXT} />,
  },
};

export { CARD_SETTINGS_CHECK_PIN_NEW_PIN_PROPS };
