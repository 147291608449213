// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalEmailStep3ActionsBuilderReturnTrackingHandlersType } from './types/personal-email-step3-actions-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const confirmChangeEmailButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'confirm',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalEmailStep3ActionsTrackingHandlers = (
  track: TrackBuilderType
): PersonalEmailStep3ActionsBuilderReturnTrackingHandlersType => ({
  handleConfirmChangeEmailButtonClickTracking: () =>
    track(confirmChangeEmailButtonClickTrackingHandler()),
});

export default PersonalEmailStep3ActionsTrackingHandlers;
