const DOMAIN = 'cobranded.services.userSettings.privateArea.notifications.settings.list.category.notificationSetting.';

const CATEGORY_TITLE = `${DOMAIN}cbSectionDivider.`,
  DETAIL_TITLE = `${DOMAIN}cbProductDetail.title`,
  HELPER = '.helperMessage',
  LINK_TITLE = `${DOMAIN}cbLink.title`,
  NOTIFICATION_SETTING = `${DOMAIN}cbRowToggle.`,
  PARRAGRAPH_TITLE = `${DOMAIN}cbParragraph.title`,
  TITLE = `${DOMAIN}title`,
  SWITCH_ON = `${DOMAIN}switchOn`,
  SWITCH_OFF = `${DOMAIN}switchOff`;

  export { CATEGORY_TITLE, DETAIL_TITLE, HELPER, LINK_TITLE, NOTIFICATION_SETTING, PARRAGRAPH_TITLE, TITLE, SWITCH_ON, SWITCH_OFF };
