const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step2.expandable.covered.';

const TITLE = `${DOMAIN}title`,
  DESCRIPTION_01 = `${DOMAIN}description01`,
  DESCRIPTION_02 = `${DOMAIN}description02`,
  DESCRIPTION_03 = `${DOMAIN}description03`,
  DESCRIPTION_04 = `${DOMAIN}description04`;

export { DESCRIPTION_01, DESCRIPTION_02, DESCRIPTION_03, DESCRIPTION_04, TITLE };
