// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Resolvers
import { getLastTimeUserSessionResolver } from '../resolvers/dashboard-last-session.resolvers';
// Types
import { DashboardLastSessionBuilderReturnHandlersType } from './types/dashboard-last-session-builder-return.handlers.type';
import { DashboardLastSessionBuilderHandlersType } from './types/dashboard-last-session-builder.handlers.type';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const fetchLastUserSessionLoginHandler = async ({
  setTimestamp,
}: Pick<DashboardLastSessionBuilderHandlersType, 'setTimestamp'>): Promise<void> => {
  const [timestamp] = await getLastTimeUserSessionResolver();

  timestamp && setTimestamp(timestamp);
};

const navigateToAccessAreaLinkClickHandler = ({
  handleNavigateToAccessAreaLinkClickTracking,
  navigate,
}: Pick<
  DashboardLastSessionBuilderHandlersType,
  'handleNavigateToAccessAreaLinkClickTracking' | 'navigate'
>): any => {
  const url = generatePathRoute([RoutesEnumeration.SECURITY, RoutesEnumeration.DEVICES]);

  handleNavigateToAccessAreaLinkClickTracking();
  navigate(generateAbsoluteRoute(url));
};

const DashboardLastSessionHandlers = ({
  handleNavigateToAccessAreaLinkClickTracking,
  navigate,
  setTimestamp,
}: DashboardLastSessionBuilderHandlersType): DashboardLastSessionBuilderReturnHandlersType => ({
  handleFetchLastUserSessionLogin: () => fetchLastUserSessionLoginHandler({ setTimestamp }),
  handleNavigateToAccessAreaLinkClick: () =>
    navigateToAccessAreaLinkClickHandler({ handleNavigateToAccessAreaLinkClickTracking, navigate }),
});

export default DashboardLastSessionHandlers;
