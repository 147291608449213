// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsChangePinContextConsumerHook } from 'containers/card-settings/views/change-pin/contexts/card-settings-change-pin.context';
// Event handlers
import CardSettingsChangePinNewPinHandlers from '../handlers/card-settings-change-pin-new-pin.handlers';
import CardSettingsChangePinNewPinTrackingHandlers from '../handlers/card-settings-change-pin-new-pin.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChangePinNewPinHookType } from './types/card-settings-change-pin-new-pin.hook.type';

const useCardSettingsChangePinNewPinHook = (): CardSettingsChangePinNewPinHookType => {
  const [error, setError] = useState<boolean>(false);

  const { challenged, newPin, setNewPin, shownCode, solvedChallenged } =
      useCardSettingsChangePinContextConsumerHook(),
    { profile } = AppContextConsumerHook(),
    { dateOfBirth } = profile?.birthDetails || {};

  const {
    handleCardSettingsChangePinNewPinClickTracking,
    handleCardSettingsChangePinNewPinErrorTracking,
  } = AppTrackingHook(CardSettingsChangePinNewPinTrackingHandlers);

  return {
    ...CardSettingsChangePinNewPinHandlers({
      dateOfBirth,
      handleCardSettingsChangePinNewPinErrorTracking,
      setNewPin,
      setError,
    }),
    challenged,
    error,
    handleCardSettingsChangePinNewPinClickTracking,
    newPin,
    shownCode,
    solvedChallenged,
  };
};

export default useCardSettingsChangePinNewPinHook;
