// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  SidebarComponent,
} from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/sidebar-dropdowns.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import {
  SidebarDropdownsComponentStyled,
  SidebarDropdownsItemComponentStyled,
} from './sidebar-dropdowns.component.styled';
// Types
import { SidebarDropdownsComponentType } from './types/sidebar-dropdowns.component.type';
import { SidebarDropdownsItemComponentType } from './types/sidebar-dropdowns-item.component.type';
// Utils
import { isCC } from 'utils/app.utils';

const SidebarDropdownsComponent = ({
  items,
  testId,
  title,
}: SidebarDropdownsComponentType): React.ReactElement<SidebarDropdownsComponentType> => (
  <SidebarComponent isCC={isCC()} {...{ testId }} title={<FormattedMessageComponent id={title} />}>
    <SidebarDropdownsComponentStyled>
      {items.map(
        (
          { label, description, testId, testing }: SidebarDropdownsItemComponentType,
          key: number
        ) => (
          <ExpandableRowComponent
            {...{ testing }}
            key={key}
            title={<FormattedMessageComponent id={label} />}
          >
            <ParagraphSecondaryComponentStyled>
              <SidebarDropdownsItemComponentStyled>
                <ParagraphComponent {...{ ...constants.SIDEBAR_DROPDOWNS_ITEM_PROPS, testId }}>
                  <FormattedMessageComponent id={description} />
                </ParagraphComponent>
              </SidebarDropdownsItemComponentStyled>
            </ParagraphSecondaryComponentStyled>
          </ExpandableRowComponent>
        )
      )}
    </SidebarDropdownsComponentStyled>
  </SidebarComponent>
);

export default SidebarDropdownsComponent;
