// Constants
import { LAST_SESSION_TIME } from './constants/last-access.constants.hook';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { LastAccessHookReturnType } from './types/last-access.return.type';
import { LastAccessHookType } from './types/last-access.type';

const LastAccessHook = ({
  device
}: LastAccessHookType): LastAccessHookReturnType => {
  const { formatDate } = useCbIntl();

  return {
    lastAccess: device.lastSession 
      ? formatDate(new Date(device?.lastSession?.endTime), LAST_SESSION_TIME)
      : ''
  };
};

export { LastAccessHook };