// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from './constants/personal-number-step2-actions.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Contexts
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
// Utilities
import { modifyNumberStep2NextButtonIsDisabled } from './utils/personal-number-step2-actions.utils';
// Constants
import { BACK_BUTTON } from 'containers/personal/views/number/translations/personal-number.translations';

const PersonalNumberStep2ActionsComponent = (): React.ReactElement => {
  const { challenged, fetching, error } = PersonalNumberContextConsumerHook();

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={constants.WIZARD_PROPS.hasDefaultLeftButton}
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        disabled: modifyNumberStep2NextButtonIsDisabled({ challenged, error, fetching }),
        loading: fetching,
      }}
      leftButtonConfiguration={{
        children: <FormattedMessageComponent id={BACK_BUTTON} />,
      }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};

export default PersonalNumberStep2ActionsComponent;
