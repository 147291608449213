// Vendors
import React from 'react';
import { ParagraphComponent, SelectorCardComponent } from '@openbank/cb-ui-commons';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  REQUEST_SIGNATURE_KEY_ADDRESS_BOX_PROPS,
  REQUEST_SIGNATURE_KEY_ADDRESS_BOX_DESCRIPTION_PROPS,
} from '../../constants/request-signature-key.constants';
// Hooks
import useRequestSignatureKeyHook from '../../hooks/request-signature-key.hook';
// Translations
import { ADDRESS_BOX_TITLE } from '../step1/translations/request-signature-key-step1.translations.common';
// Utils
import { createPostalAddress } from '../../utils/request-signature-key.utils';
// Styles
import { RequestSignatureKeyAddressSelectorStyled } from './request-signature-key-address-selector.component.styled';

const RequestSignatureKeyAddressSelector = (): React.ReactElement => {
  const { address, formatMessage } = useRequestSignatureKeyHook();

  return (
    <SelectorCardComponent
      {...REQUEST_SIGNATURE_KEY_ADDRESS_BOX_PROPS}
      selectorCardList={[
        {
          title: <FormattedMessageComponent id={ADDRESS_BOX_TITLE} />,
          children: (
            <RequestSignatureKeyAddressSelectorStyled>
              {address &&
                createPostalAddress({ address, formatMessage }).map((item, index) => (
                  <ParagraphComponent
                    {...REQUEST_SIGNATURE_KEY_ADDRESS_BOX_DESCRIPTION_PROPS}
                    key={`${item}-${index}`}
                  >
                    {item}
                  </ParagraphComponent>
                ))}
            </RequestSignatureKeyAddressSelectorStyled>
          ),
        },
      ]}
    />
  );
};

export { RequestSignatureKeyAddressSelector };
