// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import { accessKeyChangeCheckpointService } from '../services/security-keys-access-key-change-third-step-challenge-validation.services';
// Types
import { AccessKeyChangeCheckpointResolverPropsType } from './types/security-keys-access-key-change-third-step-challenge-checkpoint-props-resolvers.type';

const accessKeyChangeCheckpointResolver = async ({
  accessKey,
  newAccessKey,
  progressId,
}: AccessKeyChangeCheckpointResolverPropsType): Promise<[any | null, boolean]> => {
  const [response, { status }] = await accessKeyChangeCheckpointService({
    oldPassword: accessKey,
    newPassword: newAccessKey,
    progressId,
  });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export { accessKeyChangeCheckpointResolver };
