const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.step5.cbConstraints.';

const TITLE = `${DOMAIN}title`,
  DESCRIPTION = `${DOMAIN}description`,
  ERROR = `${DOMAIN}inputError`,
  SHOW_PASSWORD = `${DOMAIN}showAccessKeyLink`,
  HIDE_PASSWORD = `${DOMAIN}hideAccessKeyLink`;

export { TITLE, DESCRIPTION, ERROR, SHOW_PASSWORD, HIDE_PASSWORD };
