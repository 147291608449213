// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-early-repayment-date.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Translations
import translations from './translations/financing-landing-row-details-early-repayment-date.translations';
// Types
import { FinancingLandingRowDetailsEarlyRepaymentDateComponentType } from './types/financing-landing-row-details-early-repayment-date.component.type';

const FinancingLandingRowDetailsEarlyRepaymentDateComponent = ({
  eppCancellationDate,
  status,
}: FinancingLandingRowDetailsEarlyRepaymentDateComponentType): React.ReactElement | null => {
  if (
    status !== EppElementStatusEnumeration.CANCELLED &&
    status !== EppElementStatusEnumeration.PARTIAL_RETURNED
  ) {
    return null;
  }

  return (
    <ProductDetailComponent
      {...constants.FINANCING_LANDING_ROW_DETAILS_EARLY_REPAYMENT_DATE_PROPS}
      title={<FormattedMessageComponent id={translations.EARLY_REPAYMENT_DATE} />}
    >
      <ParagraphComponent
        bold
        {...constants.FINANCING_LANDING_ROW_DETAILS_EARLY_REPAYMENT_DATE_PROPS}
      >
        <FormattedDate
          {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
          value={eppCancellationDate}
        />
      </ParagraphComponent>
    </ProductDetailComponent>
  );
};

export default FinancingLandingRowDetailsEarlyRepaymentDateComponent;
