// Vendors
import styled from 'styled-components';

const RecoveryKeySidebarComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
RecoveryKeySidebarComponentStyled.displayName = 'RecoveryKeySidebarComponentStyled';

export { RecoveryKeySidebarComponentStyled };
