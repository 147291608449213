export default {
  messageProps: {
    showIcon: true,
    testId: 'ko-message',
    type: 'error',
  },
  messageTitleProps: {
    testId: 'ko-message-title',
  },
  messageDescriptionProps: {
    testId: 'ko-message-description',
  },
  messageButtonProps: {
    testId: 'ko-message-button',
  }
};
