// Vendors
import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// Components
import { FeatureFlagManager } from '@openbank/cb-ui-commons';
import FiltersComponent from 'components/filters/filters-component';
// Constants
import {
  NOTIFICATIONS_ROUTE_PROPS,
  NOTIFICATIONS_SETTINGS_ROUTE_PROPS,
} from './constants/notifications-router.constants';
import {
  ANY_NAVIGATE_ROUTE_PROPS,
  ANY_ROUTE_PROPS,
} from 'containers/orchestrator/components/router/components/containers/constants/orchestrator-router-containers.constants';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
// Views
const NotificationsLandingView = lazy(
  () => import('containers/notifications/views/landing/notifications-landing.view')
);
const NotificationsSettingsView = lazy(
  () => import('containers/notifications/views/notifications-settings/notifications-settings.view')
);

const NotificationsRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route
      {...NOTIFICATIONS_ROUTE_PROPS}
      element={
        <FiltersComponent filterId="documentation">
          <NotificationsLandingView />
        </FiltersComponent>
      }
    />
    <Route
      {...NOTIFICATIONS_SETTINGS_ROUTE_PROPS}
      element={
        <FeatureFlagManager
          flags={getFeatureFlagInstance()}
          flag={FeatureFlagsEnumeration.NOTIFICATION_SETTING}
        >
          <NotificationsSettingsView />
        </FeatureFlagManager>
      }
    />
    <Route {...ANY_ROUTE_PROPS} element={<Navigate {...ANY_NAVIGATE_ROUTE_PROPS} />} />
  </Routes>
);

export default NotificationsRouterComponent;
