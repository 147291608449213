// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const SizeOneInputStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 300, base: 14 })};

  > div > label {
    width: max-content;
  }

  ${mobileMediaQuery} {
    width: 100%;

    > div > label {
      width: 100%;
    }
  }
`;
