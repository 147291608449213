// Vendors
import React from 'react';
// Components
import { ButtonComponent, MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/challenge-wrapper-fail-message.constants';
// Styles
import {
  MessageContentLeftStyled,
  MessageContentRightStyled,
  MessageContentStyled,
} from './challenge-wrapper-fail-message.component.styled';
// Translations
import challengeCommonTranslations from 'translations/challenge-common.translations';
// Types
import { ChallengeWrapperFailMessageComponentType } from './types/challenge-wrapper-fail-message.component.type';

const ChallengeWrapperFailMessageComponent = ({
  onClick,
}: ChallengeWrapperFailMessageComponentType): React.ReactElement => (
  <MessageComponent {...constants.CHALLENGE_WRAPPER_FAIL_MESSAGE_PROPS}>
    <MessageContentStyled>
      <MessageContentLeftStyled>
        <ParagraphComponent {...constants.CHALLENGE_WRAPPER_FAIL_MESSAGE_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={challengeCommonTranslations.SECURITY_ERROR.DESCRIPTION} />
        </ParagraphComponent>
      </MessageContentLeftStyled>
      <MessageContentRightStyled>
        <ButtonComponent
          {...constants.CHALLENGE_WRAPPER_FAIL_MESSAGE_BUTTON_PROPS}
          onClick={onClick}
        >
          <FormattedMessageComponent id={challengeCommonTranslations.SECURITY_ERROR.BUTTON} />
        </ButtonComponent>
      </MessageContentRightStyled>
    </MessageContentStyled>
  </MessageComponent>
);

export default ChallengeWrapperFailMessageComponent;
