const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cardControl.operations.cbToast.';

export default {
  ACTIVATE: {
    ERROR_MESSAGE: `${DOMAIN}activatedATMError`,
    SUCCESS_MESSAGE: `${DOMAIN}activatedATMSuccess`,
  },
  DEACTIVATE: {
    ERROR_MESSAGE: `${DOMAIN}deactivatedATMError`,
    SUCCESS_MESSAGE: `${DOMAIN}deactivatedATMSuccess`,
  },
};
