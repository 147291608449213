// Vendors
import React from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Components
import {
  AssetComponent,
  FeatureFlagManager,
  ModalComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useThirdDownHook } from 'providers/third-down/hooks/third-down.hook';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
// Constants
import {
  THIRD_DOWN_MODAL_ASSET_PROPS,
  THIRD_DOWN_MODAL_BUTTON_PROPS,
  THIRD_DOWN_MODAL_DESCRIPTION_PROPS,
  THIRD_DOWN_MODAL_LAYOUT_PROPS,
  THIRD_DOWN_MODAL_MAIN_LAYOUT_PROPS,
} from './constants/third-down.constants';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { BUTTON, DESCRIPTION, TITLE } from './translations/third-down.translations';

const ThirdDownProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { handleMakeThirdPartyAgreementButtonClick, showThirdDownModal } = useThirdDownHook();

  return (
    <>
      {showThirdDownModal ? (
        <FeatureFlagManager
          flags={getFeatureFlagInstance()}
          flag={FeatureFlagsEnumeration.THIRD_PARTY_DOWN}
        >
          <ModalComponent
            isIosDevice={isIosWebView()}
            primaryButtonConfiguration={{
              ...THIRD_DOWN_MODAL_BUTTON_PROPS,
              children: <FormattedMessageComponent id={BUTTON} />,
              onClick: handleMakeThirdPartyAgreementButtonClick,
            }}
            title={<FormattedMessageComponent id={TITLE} />}
          >
            <LayoutColumnFlexComponentStyled {...THIRD_DOWN_MODAL_MAIN_LAYOUT_PROPS}>
              <AssetComponent {...THIRD_DOWN_MODAL_ASSET_PROPS} />
              <LayoutColumnFlexComponentStyled {...THIRD_DOWN_MODAL_LAYOUT_PROPS}>
                <ParagraphComponent {...THIRD_DOWN_MODAL_DESCRIPTION_PROPS}>
                  <FormattedMessageComponent id={DESCRIPTION} />
                </ParagraphComponent>
              </LayoutColumnFlexComponentStyled>
            </LayoutColumnFlexComponentStyled>
          </ModalComponent>
        </FeatureFlagManager>
      ) : null}
      {children}
    </>
  );
};

export { ThirdDownProvider };
