// Constants
import { SIGNATURE_KEY_EXPIRED_CODE } from './constants/personal-number-step4-challenge.handlers.constants';
// Types
import { PersonalNumberStep4ChallengeBuilderReturnHandlersType } from './types/personal-number-step4-challenge-builder-return.handlers.type';
import { PersonalNumberStep4ChallengeBuilderHandlersType } from './types/personal-number-step4-challenge-builder.handlers.type';
import { PersonalNumberStep4ChallengeMethodBuilderHandlersType } from './types/personal-number-step4-challenge-method-builder.handlers.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';

const manageErrorOnChangePhoneEventHandler = ({
  setChallenged,
  setResult,
  setSignatureExpired,
  code
}: Pick<PersonalNumberStep4ChallengeBuilderHandlersType, 'setChallenged' | 'setResult' | 'code' | 'setSignatureExpired'>): any => {
  if (code === SIGNATURE_KEY_EXPIRED_CODE) {
    return setSignatureExpired(true);
  }
  setChallenged(false);
  setResult(false);
};

const manageSuccessOnChangeNumberEventHandler = ({
  progressId,
  setChallenged,
  setProgressId,
}: PersonalNumberStep4ChallengeMethodBuilderHandlersType): void => {
  setChallenged(false);
  setProgressId(progressId);
};

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<PersonalNumberStep4ChallengeBuilderHandlersType, 'setChallenged'>): void => {
  setChallenged(true);
};

const challengeRetryButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => setChallengeError(false);

const PersonalNumberStep4ChallengeHandlers = ({
  setChallenged,
  setChallengeError,
  setProgressId,
  setResult,
  setSignatureExpired
}: PersonalNumberStep4ChallengeBuilderHandlersType): PersonalNumberStep4ChallengeBuilderReturnHandlersType => ({
  handleManageErrorOnChangePhoneEvent: (code: string) =>
    manageErrorOnChangePhoneEventHandler({ setChallenged, setResult, code, setSignatureExpired }),
  handleManageSuccessOnChangePhoneEvent: ({ progressId }: { progressId: string }) =>
    manageSuccessOnChangeNumberEventHandler({ progressId, setChallenged, setProgressId }),
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler({ setChallenged }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setChallengeError }),
});

export default PersonalNumberStep4ChallengeHandlers;
