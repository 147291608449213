// Types
import { AccessKeyChangeStep1HandlersReturnType } from './types/security-keys-access-key-change-first-step-handlers-return.type';
import { AccessKeyChangeStep1HandlersPropsType } from './types/security-keys-access-key-change-first-step-handlers-props.type';
import { SaveNewModifyAccessKeyChangeInputEventHandlerPropsType } from './types/security-keys-access-key-change-first-step-handlers-save-access-key-props.type';

const saveNewModifyAccessKeyChangeInputEventHandler = ({
  accessKey,
  setAccessKey,
}: SaveNewModifyAccessKeyChangeInputEventHandlerPropsType): void => {
  setAccessKey(accessKey);
};

const SecurityKeysAccessKeyChangeFirstStepHandlers = ({
  setAccessKey,
}: AccessKeyChangeStep1HandlersPropsType): AccessKeyChangeStep1HandlersReturnType => ({
  handleSaveNewModifyAccessKeyChangeInputEvent: (accessKey: string) =>
    saveNewModifyAccessKeyChangeInputEventHandler({ accessKey, setAccessKey }),
});

export default SecurityKeysAccessKeyChangeFirstStepHandlers;
