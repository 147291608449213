// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  TRAVEL_PLUS_CANCEL_LINK_TRACKING_PROPS,
  TRAVEL_PLUS_OPEN_MODAL_TRACKING_PROPS,
  TRAVEL_PLUS_CLOSE_ASSET_TRACKING_PROPS,
  TRAVEL_PLUS_CONFIRM_BUTTON_TRACKING_PROPS,
} from './constants/travel-plus.handlers.tracking.constants';
// Types
import { TravelPlusTrackingHandlersType } from 'components/travel-plus/handlers/types/travel-plus.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const confirmTravelPlusButtonClickTrackingHandler = (
  enable: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    ...TRAVEL_PLUS_CONFIRM_BUTTON_TRACKING_PROPS,
    eventLabel: `yesSwitch${enable ? 'On' : 'Off'}Travel+`,
  },
  type: TrackingEventsEnum.LINK,
});

const cancelShowTravelPlusLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...TRAVEL_PLUS_CANCEL_LINK_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const closeChangeEmailModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...TRAVEL_PLUS_CLOSE_ASSET_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const openTravelPlusTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...TRAVEL_PLUS_OPEN_MODAL_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const TravelPlusTrackingHandlers = (track: TrackBuilderType): TravelPlusTrackingHandlersType => ({
  handleConfirmTravelPlusButtonClickTracking: (enable: boolean) =>
    track(confirmTravelPlusButtonClickTrackingHandler(enable)),
  handleCloseTravelPlusAssetClickTracking: () => track(closeChangeEmailModalTrackingHandler()),
  handleCancelShowTravelPlusLinkClickTracking: () =>
    track(cancelShowTravelPlusLinkClickTrackingHandler()),
  handleOpenTravelPlusModalEventTracking: () => track(openTravelPlusTrackingHandler()),
});

export { TravelPlusTrackingHandlers };
