// Vendors
import React from 'react';
// Components
import { ChangeIbanSidebarComponent } from './components/sidebar/change-iban-sidebar.component';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import WizardComponent from 'components/wizard/wizard.component';
// Contexts
import { ChangeIbanContextConsumerHOC } from './contexts/change-iban.context';
// Hooks
import { useChangeIbanHook } from './hooks/change-iban.hook';
// Styles
import { ChangeIbanViewStyled } from './change-iban.view.styled';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { getChangeIbanWizardConfiguration } from './configurations/change-iban.configuration';

const ChangeIbanView = (): React.ReactElement => {
  const { fetching } = useChangeIbanHook();

  return (
    <ChangeIbanViewStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <FetchErrorComponent {...{ fetching }}>
            <WizardComponent {...getChangeIbanWizardConfiguration()} />
          </FetchErrorComponent>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <ChangeIbanSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </ChangeIbanViewStyled>
  );
};

export { ChangeIbanView };
export default ChangeIbanContextConsumerHOC(ChangeIbanView);
