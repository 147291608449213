// Services
import { fetchContractingPPIDocumentService } from '../services/financing-ppi-contracting-download.service';
// Types
import { FinancingPPIContractingDownloadComponentType } from '../types/financing-ppi-contracting-download.component.type';
// Utilities
import { fileIsBlobType } from '@openbank/cf-ui-framework';

const fetchContractingPPIDocumentResolver = async (
  params: Pick<FinancingPPIContractingDownloadComponentType, 'serviceParams' | 'serviceMethod'>
): Promise<[Blob] | [null, boolean]> => {
  const [response] = await fetchContractingPPIDocumentService(params);

  return fileIsBlobType(response as Blob) ? [response as Blob] : [null, true];
};

export { fetchContractingPPIDocumentResolver };
