// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Translations
import translations from './translations/dashboard-last-session-date.translations';
// Utils
import { lastSessionIsDefined } from './utils/dashboard-last-session-date.utils';

const DashboardLastSessionDateComponent = ({ timestamp }: any): React.ReactElement =>
  lastSessionIsDefined(timestamp) ? (
    <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY_HH_MM} value={timestamp} />
  ) : (
    <FormattedMessageComponent id={translations.NOT_AVAILABLE} />
  );

export default DashboardLastSessionDateComponent;
