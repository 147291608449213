// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/profile-personal-name-header-tooltip.constants';
import { PROFILE_PERSONAL_TOOLTIP_PROPS } from 'containers/personal/views/landing/constants/personal-landing.constants';
// Styles
import { ProfilePersonalNameHeaderTooltipComponentStyled } from './profile-personal-name-header-tooltip.component.styled';
// Translations
import translations from '../../translations/profile-personal-name.translations';

const ProfilePersonalNameHeaderTooltipComponent = (): React.ReactElement => (
  <ProfilePersonalNameHeaderTooltipComponentStyled>
    <AssetComponent
      {...constants.PROFILE_PERSONAL_NAME_ASSET_PROPS}
      tooltipConfiguration={{
        ...PROFILE_PERSONAL_TOOLTIP_PROPS,
        content: (
          <ParagraphComponent { ...constants.PROFILE_PERSONAL_NAME_TOOLTIP_CONTENT_PROPS}>
            <FormattedMessageComponent id={translations.TOOLTIP} />
          </ParagraphComponent>
        ),
      }}
    />
  </ProfilePersonalNameHeaderTooltipComponentStyled>
);

export default ProfilePersonalNameHeaderTooltipComponent;
