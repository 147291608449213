// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { assetConstants, linkConstants } from '@openbank/cb-ui-commons';
import { NUMBER } from 'constants/number.constants';
// Translations
import {
  CANCEL_LINK,
  UPDATE_DOCUMENTATION_BUTTON,
} from '../translations/expired-document-modal.translations';

export const EXPIRED_DOCUMENT_MODAL_ASSET_PROPS = {
  extension: assetConstants.EXTENSIONS.SVG,
  id: 'document-ko-framing-error-alternative',
  sizes: {
    height: 128,
    width: 128,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'expired-document-modal-asset',
};

export const EXPIRED_DOCUMENT_MODAL_COMPONENT_PROPS = {
  testid: 'expired-document-modal',
  index: NUMBER.THREE,
};

export const EXPIRED_DOCUMENT_MODAL_DESCRIPTION_PROPS = {
  testid: 'expired-document-modal-description',
};

export const EXPIRED_DOCUMENT_MODAL_PRIMARY_BUTTON_PROPS = {
  testid: 'update-documentation-button',
  children: <FormattedMessageComponent id={UPDATE_DOCUMENTATION_BUTTON} />,
};

export const CANCEL_EXPIRED_DOCUMENT_MODAL_LINK_PROPS = {
  type: linkConstants.TYPES.ACTION_PRIMARY,
  testId: 'cancel-expired-document-modal-link',
  children: <FormattedMessageComponent id={CANCEL_LINK} />,
};

export const LAYAOUT_COLUMN_FLEX_COMPONENT_STYLED_PROPS = {
  align: 'center',
};
