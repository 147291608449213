export default {
  PERSONAL_ACCESS_PORTABILITY_SIDEBAR_PROPS: {
    testId: 'personal-access-portability',
  },
  PERSONAL_ACCESS_PORTABILITY_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'personal-access-portability-sidebar-message-01',
  },
  PERSONAL_ACCESS_PORTABILITY_SIDEBAR_MESSAGE_02_PROPS: {
    testId: 'personal-access-portability-sidebar-message-02',
  },
  PERSONAL_ACCESS_PORTABILITY_SIDEBAR_MESSAGE_03_PROPS: {
    testId: 'personal-access-portability-sidebar-message-03',
  },
};
