// Types
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';
import { RequestSignatureKeyStep2BuilderReturnHandlersType } from './types/request-signature-key-step2-challenge-builder-return.handlers.type';
import { RequestSignatureKeyMethodBuilderHandlersType } from './types/request-signature-key-step2-challenge-method-builder.handlers.type';
import { RequestSignatureKeyStep2BuilderHandlersType } from './types/request-signature-key-step2-challenge.handlers.type';

const manageErrorOnRequestSignatureKeyEvent = ({
  setChallengeError,
}: Pick<RequestSignatureKeyStep2BuilderHandlersType, 'setChallengeError'>): void => {
  setChallengeError(true);
};

const manageSuccessOnRequestSignatureKeyEvent = ({
  progressId,
  setChallenged,
  setProgressId,
}: RequestSignatureKeyMethodBuilderHandlersType): void => {
  setChallenged(false);
  setProgressId(progressId);
};

const challengeRetryButtonClick = ({ setChallengeError }: ChallengeRetryButtonClickHandlerType) =>
  setChallengeError(false);

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<RequestSignatureKeyStep2BuilderHandlersType, 'setChallenged'>): void => {
  setChallenged(true);
};

const RequestSignatureKeyStep2ChallengeHandlers = ({
  setChallengeError,
  setChallenged,
  setProgressId,
}: RequestSignatureKeyStep2BuilderHandlersType): RequestSignatureKeyStep2BuilderReturnHandlersType => ({
  handleManageErrorOnRequestSignatureKeyEvent: () =>
    manageErrorOnRequestSignatureKeyEvent({ setChallengeError }),
  handleManageSuccessOnRequestSignatureKeyEvent: ({ progressId }) =>
    manageSuccessOnRequestSignatureKeyEvent({ progressId, setChallenged, setProgressId }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClick({ setChallengeError }),
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler({ setChallenged }),
});

export default RequestSignatureKeyStep2ChallengeHandlers;
