// Types
import { FinancingPPIContractingStep3HookType } from '../hooks/types/financing-ppi-contracting-step3.hook.type';

const contractPPIStep3ButtonIsDisabled = ({
  challenged,
  fetching,
}: Pick<FinancingPPIContractingStep3HookType, 'challenged' | 'fetching'>): boolean =>
  Boolean(!challenged || fetching);

export { contractPPIStep3ButtonIsDisabled };
