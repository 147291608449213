// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { FOURTH_STEP_TARGETS, THIRD_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useTransactionsReportFraudulentMovementConfirmationActionsHook } from './hooks/transactions-report-fraudulent-movement-confirmation-actions.hook';
// Translations
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';
// Types
import { TransactionsReportFraudulentMovementConfirmationActionsComponentType } from './types/transactions-report-fraudulent-movement-confirmation-actions.component.type';

export const TransactionsReportFraudulentMovementCheckInfoActionsComponent = ({
  isNotBlocked,
}: TransactionsReportFraudulentMovementConfirmationActionsComponentType): React.ReactElement => {
  const { challenge, challengeError, handleConfirmButtonClick } =
    useTransactionsReportFraudulentMovementConfirmationActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      hasDefaultLeftButton
      rightButtonConfiguration={{
        assetConfiguration: {},
        children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
        disabled: !challenge || challengeError,
        onClick: handleConfirmButtonClick,
      }}
      targets={isNotBlocked ? FOURTH_STEP_TARGETS : THIRD_STEP_TARGETS}
    />
  );
};
