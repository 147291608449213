const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbDetails.';

const REPORT_MESSAGE = `${DOMAIN}reportMessage`;

const PARTIAL_RETURNED_MESSAGE = `${DOMAIN}partialReturnedMessage`;

const REPORT_PARTIAL_RETURNED_MESSAGE = `${DOMAIN}reportAndPartialReturnedMessage`;

export { REPORT_MESSAGE, PARTIAL_RETURNED_MESSAGE, REPORT_PARTIAL_RETURNED_MESSAGE };
