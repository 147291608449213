// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_ITEM_DESCRIPTION_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.XS,
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_ITEM_ASSET_PROPS: {
    id: 'bullet',
    sizes: {
      height: 8,
      width: 8,
    },
    testId: 'settings-card-control-locations-map-legend-item-asset',
  },
};
