// Constants
import constants from './constants/card-status.hook.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardStatusHookType } from './types/card-status.hook.type';

const CardStatusHook = (): CardStatusHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { status } = currentCard || constants.CARD_DEFAULT_STATUS;

  return { status };
};

export default CardStatusHook;
