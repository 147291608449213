// Vendors
import React from 'react';
// Components
import {
  SidebarComponent,
  ExpandableRowComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_STATEMENTS_SIDEBAR_PROPS,
  CARD_SETTING_STATEMENTS_SIDEBAR_ITEMS,
  CARD_STATEMENTS_SIDEBAR_DESCRIPTION_PROPS,
} from './constants/card-settings-statements-sidebar.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { CARD_STATEMENTS_SIDEBAR_TITLE } from './translations/card-settings-statements-sidebar.translations';
// Types
import { CardStatementsSidebarItemType } from './types/card-statements-sidebar-item.type';

const CardSettingsStatementsSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...CARD_STATEMENTS_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={CARD_STATEMENTS_SIDEBAR_TITLE} />}
  >
    <LayoutColumnFlexComponentStyled>
      {CARD_SETTING_STATEMENTS_SIDEBAR_ITEMS.map(
        (
          { content, title, testing: { descriptionId, titleId } }: CardStatementsSidebarItemType,
          key: number
        ) => (
          <ExpandableRowComponent
            key={key}
            testing={{ titleId }}
            title={<FormattedMessageComponent id={title} />}
          >
            <ParagraphComponent
              {...CARD_STATEMENTS_SIDEBAR_DESCRIPTION_PROPS}
              testId={descriptionId}
            >
              <FormattedMessageComponent id={content} />
            </ParagraphComponent>
          </ExpandableRowComponent>
        )
      )}
    </LayoutColumnFlexComponentStyled>
  </SidebarComponent>
);

export { CardSettingsStatementsSidebarComponent };
