// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Handlers
import { UploadDocumentationHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-4-upload-documentation.handler';
// Trackings
import AppTrackingHook from 'tracking/app.tracking.hook';
import { Step4UploadDocumentationTracking } from '../trackings/step-4-upload-documentation.tracking';
// Types
import { SetStateType } from '@openbank/cf-ui-static-data';
import { Step4UploadDocumentationHookType } from './types/step-4-upload-documentation.component.hook.type';
// Utils
import {
  getDocumentsToUpload,
  getIfAreDocumentsUploaded,
} from './utils/step-4-upload-documentation.hook.utils';

const Step4UploadDocumentationHook = (
  setShowModal: SetStateType<boolean>
): Step4UploadDocumentationHookType => {
  const { currentCard } = AppContextConsumerHook(),
  { id: cardId } = currentCard || {};
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step4 } = creditLimitContext;
  const { setCurrentStep } = useWizardContextConsumerHook();
  const {
    handleEnterViewTracking,
    handleClickThisExampleDocumentTracking,
    handleClickGoBackToPreviousStepLinkTracking,
    handleClickCancelLinkTracking,
    handleClickUploadDocumentationButtonTracking,
  } = AppTrackingHook(Step4UploadDocumentationTracking);
  const { handleUploadDocumentationBack, handleUploadDocumentationGoNext } =
    UploadDocumentationHandlers({
      cardId,
      creditLimitContext,
      setCurrentStep,
    });
  const [showInfoModal, setShowInfoModal] = useState(false);

  useEffect(handleEnterViewTracking, []);

  return {
    areDocumentsUploaded: getIfAreDocumentsUploaded(step4),
    showInfoModal,
    setShowInfoModal: (newShowInfoModal: boolean) => {
      handleClickThisExampleDocumentTracking();
      setShowInfoModal(newShowInfoModal);
    },
    setShowModalWithTracking: (newShowModal: boolean) => {
      handleClickCancelLinkTracking();
      setShowModal(newShowModal);
    },
    handleUploadDocumentationGoNext: async () => {
      handleClickUploadDocumentationButtonTracking();
      await handleUploadDocumentationGoNext(getDocumentsToUpload(step4));
    },
    handleUploadDocumentationBack: () =>
      handleUploadDocumentationBack(handleClickGoBackToPreviousStepLinkTracking),
    handleClickCancelLinkTracking,
  };
};

export { Step4UploadDocumentationHook };
