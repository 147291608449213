// Vendors
import React from 'react';
// Components
import FinancingPaymentMethodFirstStepPaymentComponent from './components/payment/financing-payment-method-first-step-payment.component';
import FinancingPaymentMethodFirstStepRevolvingComponent from './components/revolving/financing-payment-method-first-step-revolving.component';
import FinancingPaymentMethodFirstStepActionsComponent from './components/actions/financing-payment-method-first-step-actions.component';
// Styles
import { FinancingPaymentMethodFirstStepComponentStyled } from './financing-payment-method-first-step.component.styled';

const FinancingPaymentMethodFirstStepComponent = (): React.ReactElement => (
  <FinancingPaymentMethodFirstStepComponentStyled>
    <FinancingPaymentMethodFirstStepPaymentComponent />
    <FinancingPaymentMethodFirstStepRevolvingComponent />
    <FinancingPaymentMethodFirstStepActionsComponent />
  </FinancingPaymentMethodFirstStepComponentStyled>
);

export default FinancingPaymentMethodFirstStepComponent;
