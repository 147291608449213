// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { TitleHeaderComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/transactions-table-title.constants';
// Types
import { TransactionsTableComponentType } from '../../types/transactions-table.component.type';

const TransactionsTableTitleComponent = ({
  title,
}: Pick<TransactionsTableComponentType, 'title'>): React.ReactElement | null =>
  title ? (
    <TitleHeaderComponent {...constants.TRANSACTIONS_TABLE_TITLE_PROPS}>
      <FormattedMessageComponent id={title} />
    </TitleHeaderComponent>
  ) : null;

export default TransactionsTableTitleComponent;
