const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step4.uploadDocumentation.';

export const step4UploadDocumentationTranslations = {
  TITLE: `${DOMAIN}title`,
  SUBTITLE: `${DOMAIN}subtitle`,
  REQUIRED_DOCUMENTATION: `${DOMAIN}requiredDocumentation`,
  REQUIRED_1: `${DOMAIN}required1`,
  REQUIRED_2: `${DOMAIN}required2`,
  TIPS_FOR_UPLOADING_TITLE: `${DOMAIN}tipsForUploadingTitle`,
  TIP_1: `${DOMAIN}tip1`,
  TIP_2: `${DOMAIN}tip2`,
  TIP_3: `${DOMAIN}tip3`,
  MAIN_BANK_ACCOUNT: `${DOMAIN}mainBankAccount`,
  MAIN_BANK_ACCOUNT_DESCRIPTION: `${DOMAIN}mainBankAccountDescription`,
  SALARY_SLIPS: `${DOMAIN}salarySlips`,
  SALARY_SLIPS_DESCRIPTION: `${DOMAIN}salarySlipsDescription`,
  LAST_SALARY_SLIP: `${DOMAIN}lastSalarySlip`,
  PREVIOUS_SALARY_SLIP: `${DOMAIN}previousSalarySlip`,
  BEFORE_PREVIOUS_SALARY_SLIP: `${DOMAIN}beforePreviousSalarySlip`,
  ANNUAL_PENSION: `${DOMAIN}annualPension`,
  ANNUAL_PENSION_DESCRIPTION: `${DOMAIN}annualPensionDescription`,
  LAST_BWA: `${DOMAIN}lastBwa`,
  LAST_BWA_DESCRIPTION: `${DOMAIN}lastBwaDescription`,
  LAST_INCOME_TAX: `${DOMAIN}lastIncomeTax`,
  MESSAGE_INFO: `${DOMAIN}messageInfo`,
  MESSAGE_INFO_LINK: `${DOMAIN}messageInfoLink`,
  UPLOAD_DOCUMENTATION: `${DOMAIN}uploadDocumentation`,
  IT_IS_NOT_POSSIBLE: `${DOMAIN}itIsNotPossible`,
  PLEASE_ABANDON: `${DOMAIN}pleaseAbandon`,
  EXIT_THE_PROCESS: `${DOMAIN}exitTheProcess`,
  MODAL_TIPS_FOR_PHOTOGRAPHING: `${DOMAIN}modalTipsForPhotographing`,
  MODAL_TIP_1_PHOTOGRAPHING: `${DOMAIN}modalTip1Photographing`,
  MODAL_TIP_2_PHOTOGRAPHING: `${DOMAIN}modalTip2Photographing`,
  MODAL_TIP_3_PHOTOGRAPHING: `${DOMAIN}modalTip3Photographing`,
  MODAL_TIPS_DESCRIPTION: `${DOMAIN}modalTipsDescription`,
  MODAL_BACK_DOCUMENTATION_UPLOAD: `${DOMAIN}modalBackDocumentationUpload`,
};
