// Enumerations
import { RevolvingMethodsEnumeration } from 'containers/financing/views/payment-method/enumerations/revolving-methods.enumeration';
// Translations
import { FIX_AMOUNT, PERCENTAGE_AMOUNT } from 'translations/billing-payment-methods.translations';

const OPTIONS = [
  {
    name: RevolvingMethodsEnumeration.FIX_AMOUNT,
    title: FIX_AMOUNT.TITLE,
    description: FIX_AMOUNT.DESCRIPTION,
  },
  {
    name: RevolvingMethodsEnumeration.PERCENTAGE_AMOUNT,
    title: PERCENTAGE_AMOUNT.TITLE,
    description: PERCENTAGE_AMOUNT.DESCRIPTION,
  },
];

export { OPTIONS };
