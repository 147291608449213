// Vendors
import React from 'react';
// Component
import { ErrorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WORK_IN_PROGRESS_PROPS } from './constants/work-in-progress.constants';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import { WorkInProgressComponentStyled } from 'components/working-in-progress/work-in-progress.component.styled';
// Translations
import { DESCRIPTION, TITLE } from './translations/work-in-progress.translations';

const WorkInProgressComponent = (): React.ReactElement => (
  <AppCardSectionComponentStyled>
    <AppCardContentSectionComponentStyled>
      <WorkInProgressComponentStyled>
        <ErrorComponent
          {...WORK_IN_PROGRESS_PROPS}
          description={<FormattedMessageComponent id={DESCRIPTION} />}
          title={<FormattedMessageComponent id={TITLE} />}
        />
      </WorkInProgressComponentStyled>
    </AppCardContentSectionComponentStyled>
  </AppCardSectionComponentStyled>
);

export { WorkInProgressComponent };
