// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { AccessKeyChangeStep2TrackingHandlersType } from './types/security-keys-access-key-change-second-step-handlers-handlers-tracking-return.type';

const passwordPositionInputClickFirstPasswordTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickTextBox',
    eventLabel: 'newAccessKeyTextBox',
  },
  type: TrackingEventsEnum.LINK,
});

const passwordPositionInputClickSecondPasswordTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickTextBox',
      eventLabel: 'repeatAccessKeyTextBox',
    },
    type: TrackingEventsEnum.LINK,
  });

const onEnterSecondStepAccessKeyChangeTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeAccessKey/step02/newAccessKey',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'security',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SecurityKeysAccessKeyChangeSecondStepTrackingHandlers = (
  track: TrackBuilderType
): AccessKeyChangeStep2TrackingHandlersType => ({
  handleFirstPasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickFirstPasswordTrackingHandler()),
  handleSecondPasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickSecondPasswordTrackingHandler()),
  handleOnEnterSecondStepAccessKeyChangeTrackingHandler: () =>
    track(onEnterSecondStepAccessKeyChangeTrackingHandler()),
});

export default SecurityKeysAccessKeyChangeSecondStepTrackingHandlers;
