// Vendors
import React from 'react';
import { isIosWebView } from '@openbank/web-ui-framework';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CANCEL_MODAL_BUTTON_PROPS,
  CANCEL_MODAL_DEFAULT_ASSET_PROPS,
  CANCEL_MODAL_LINK_PROPS,
  CANCEL_MODAL_PARAGRAPH_01_PROPS,
  CANCEL_MODAL_PARAGRAPH_02_PROPS,
  CANCEL_MODAL_HAVE_DESCRIPTION_PROPS,
} from './constants/cancel-modal.constants';
// Hooks
import useCancelModalHook from './hooks/cancel-modal.hook';
// Styles
import {
  CancelModalDescriptionComponentStyled,
  CancelModalDescriptionSubtitleStyled,
} from './cancel-modal.component.styled';
// Translations
import {
  DEFAULT_BUTTON,
  DEFAULT_DESCRIPTION_01,
  DEFAULT_DESCRIPTION_02,
  DEFAULT_LINK,
  DEFAULT_TITLE,
} from './translations/cancel-modal.translations';
// Types
import { CancelModalHookType } from 'components/cancel-modal/types/cancel-modal.hook.type';

// eslint-disable-next-line complexity
const CancelModalComponent = ({
  assetId,
  buttonText,
  firstDescription,
  link,
  onClose,
  onConfirm,
  secondDescription,
  show,
  title,
  haveDescription = CANCEL_MODAL_HAVE_DESCRIPTION_PROPS,
}: CancelModalHookType): React.ReactElement | null => {
  const {
    handleConfirmCancelModalButtonClick,
    handleCloseCancelModalAssetClick,
    handleCancelShowCancelModalLinkClick,
  } = useCancelModalHook({ onClose, onConfirm, show });

  return show ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...CANCEL_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={link ?? DEFAULT_LINK} />,
        onClick: handleCancelShowCancelModalLinkClick,
      }}
      primaryButtonConfiguration={{
        ...CANCEL_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={buttonText ?? DEFAULT_BUTTON} />,
        onClick: handleConfirmCancelModalButtonClick,
      }}
      onHeaderClose={handleCloseCancelModalAssetClick}
      title={<FormattedMessageComponent id={title ?? DEFAULT_TITLE} />}
    >
      <CancelModalDescriptionComponentStyled>
        <AssetComponent
          {...CANCEL_MODAL_DEFAULT_ASSET_PROPS}
          id={assetId ?? CANCEL_MODAL_DEFAULT_ASSET_PROPS.id}
        />
        {haveDescription && (
          <CancelModalDescriptionSubtitleStyled>
            <ParagraphComponent {...CANCEL_MODAL_PARAGRAPH_01_PROPS}>
              <FormattedMessageComponent id={firstDescription ?? DEFAULT_DESCRIPTION_01} />
            </ParagraphComponent>
            <ParagraphComponent {...CANCEL_MODAL_PARAGRAPH_02_PROPS}>
              <FormattedMessageComponent id={secondDescription ?? DEFAULT_DESCRIPTION_02} />
            </ParagraphComponent>
          </CancelModalDescriptionSubtitleStyled>
        )}
      </CancelModalDescriptionComponentStyled>
    </ModalComponent>
  ) : null;
};

export default CancelModalComponent;
