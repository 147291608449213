// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const SettingsRowToggleComponentStyled = styled.section.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
`;

const SettingsRowToggleWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.spaces.spacingS};
`;

const SettingsRowToggleParragraphStyled = styled.div`
  padding-top: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `0 ${theme.spaces.spacingXl} 0 0`};
  }
`;

export { SettingsRowToggleComponentStyled, SettingsRowToggleWrapperStyled, SettingsRowToggleParragraphStyled };