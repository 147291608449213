const TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS = [
  {
    id: 2,
    testId: 'transactions-epp-step1-simulation-options-option-2',
  },
  {
    id: 3,
    testId: 'transactions-epp-step1-simulation-options-option-3',
  },
  {
    id: 6,
    testId: 'transactions-epp-step1-simulation-options-option-6',
  },
  {
    id: 12,
    testId: 'transactions-epp-step1-simulation-options-option-12',
  },
];

export { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS };
