// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsCheck3dConfirmationButtonsTrackingHandlersType } from './types/card-settings-check-3d-confirmation-buttons.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const modify3DSecureCodeButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'modifyCode',
  },
  type: TrackingEventsEnum.LINK,
});

const showHide3DSecureCodeButtonClickTrackingHandler = (
  show: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: `${show ? 'show' : 'hide'}Code`,
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsCheck3dConfirmationButtonsTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsCheck3dConfirmationButtonsTrackingHandlersType => ({
  handleModify3DSecureCodeButtonClickTracking: () =>
    track(modify3DSecureCodeButtonClickTrackingHandler()),
  handleShowHide3DSecureCodeButtonClickTracking: (show: boolean) =>
    track(showHide3DSecureCodeButtonClickTrackingHandler(show)),
});

export default CardSettingsCheck3dConfirmationButtonsTrackingHandlers;
