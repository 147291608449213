// Types
import { RequestPortabilityCheckboxesBuilderHandlersType } from './types/request-portability-checkboxes-builder.handlers.type';
import { RequestPortabilityCheckboxesBuilderReturnHandlersType } from './types/request-portability-checkboxes-builder-return.handlers.type';
import { RequestPortabilityParamsType } from 'containers/personal/views/access-portability/types/request-portability-params.type';
import { RequestPortabilitySelectedProductsType } from 'containers/personal/views/access-portability/types/request-portability-selected-products.type';
import { RequestPortabilityCheckboxesBuilderMainCheckboxHandlersType } from './types/request-portability-checkboxes-builder-main-checkbox.handlers.type';
import { RequestPortabilityCheckboxesBuilderMovementsCheckboxHandlersType } from './types/request-portability-checkboxes-builder-movements-checkbox.handlers.type';
import { RequestPortabilityCheckboxesBuilderBeneficiaryCheckboxHandlersType } from './types/request-portability-checkboxes-builder-beneficiary-checkbox.handlers.type';

const movementsCheckBoxChangeEvent = ({
  handleMovementsChangeCheckboxTracking,
  movementsCheckbox,
  setMovementsCheckbox,
  setPortabilityParams,
}: RequestPortabilityCheckboxesBuilderMovementsCheckboxHandlersType): void => {
  handleMovementsChangeCheckboxTracking(movementsCheckbox);
  setMovementsCheckbox(!movementsCheckbox);
  setPortabilityParams((prevParams): RequestPortabilityParamsType => {
    const updatedSelectedProducts =
      prevParams?.selectedProducts?.map(product => {
        return {
          ...product,
          movements: !movementsCheckbox,
        };
      }) ?? [];

    return {
      ...prevParams,
      selectedProducts: updatedSelectedProducts,
    };
  });
};

const mainCardCheckBoxChangeEvent = ({
  currentCard,
  handleMainCardChangeCheckboxTracking,
  mainCardCheckbox,
  setMainCardCheckbox,
  setMovementsCheckbox,
  setPortabilityParams,
}: RequestPortabilityCheckboxesBuilderMainCheckboxHandlersType): void => {
  const cardToSend: RequestPortabilitySelectedProductsType = {
    arrangementId: currentCard?.cardContractId,
    movements: false,
    type: currentCard?.productTypeCode,
  };
  handleMainCardChangeCheckboxTracking(mainCardCheckbox);
  setMainCardCheckbox(!mainCardCheckbox);
  if (mainCardCheckbox) {
    setMovementsCheckbox(false);
  }
  setPortabilityParams(
    (prevParams): RequestPortabilityParamsType => ({
      ...prevParams,
      selectedProducts: mainCardCheckbox ? [] : [cardToSend],
    })
  );
};

const beneficiaryCheckBoxChangeEvent = ({
  beneficiaryCardCheckbox,
  handleBeneficiaryCardChangeCheckboxTracking,
  setBeneficiaryCardCheckbox,
}: RequestPortabilityCheckboxesBuilderBeneficiaryCheckboxHandlersType): void => {
  handleBeneficiaryCardChangeCheckboxTracking(beneficiaryCardCheckbox);
  setBeneficiaryCardCheckbox(!beneficiaryCardCheckbox);
};

const RequestPortabilityCheckboxesHandlers = ({
  beneficiaryCardCheckbox,
  currentCard,
  handleBeneficiaryCardChangeCheckboxTracking,
  handleMainCardChangeCheckboxTracking,
  handleMovementsChangeCheckboxTracking,
  mainCardCheckbox,
  movementsCheckbox,
  setBeneficiaryCardCheckbox,
  setMainCardCheckbox,
  setMovementsCheckbox,
  setPortabilityParams,
}: RequestPortabilityCheckboxesBuilderHandlersType): RequestPortabilityCheckboxesBuilderReturnHandlersType => ({
  handleMovementsCheckBoxChangeEvent: () =>
    movementsCheckBoxChangeEvent({
      handleMovementsChangeCheckboxTracking,
      movementsCheckbox,
      setMovementsCheckbox,
      setPortabilityParams,
    }),
  handleMainCardCheckBoxChangeEvent: () =>
    mainCardCheckBoxChangeEvent({
      currentCard,
      handleMainCardChangeCheckboxTracking,
      mainCardCheckbox,
      setMainCardCheckbox,
      setPortabilityParams,
      setMovementsCheckbox,
    }),
  handleBeneficiaryCardCheckBoxChangeEvent: () =>
    beneficiaryCheckBoxChangeEvent({
      beneficiaryCardCheckbox,
      handleBeneficiaryCardChangeCheckboxTracking,
      setBeneficiaryCardCheckbox,
    }),
});

export default RequestPortabilityCheckboxesHandlers;
