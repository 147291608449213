// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ButtonComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getLoginHeaderLogoConfiguration } from './configurations/login-header.configurations';
// Constants
import constants from './constants/login-header.constants';
// Custom Hooks
import LoginHeaderHook from './hooks/login-header.hook';
// Translations
import translations from './translations/login-header.translations';
// Utilities
import { LoginHeaderComponentStyled } from './login-header.component.styled';

const LoginHeaderComponent = (): React.ReactElement => {
  const { handleExitFromLoginView } = LoginHeaderHook();

  return (
    <LoginHeaderComponentStyled>
      <AssetComponent {...getLoginHeaderLogoConfiguration()} />
      <ButtonComponent {...constants.LOGIN_HEADER_BUTTON_PROPS} onClick={handleExitFromLoginView}>
        <FormattedMessageComponent id={translations.EXIT} />
      </ButtonComponent>
    </LoginHeaderComponentStyled>
  );
};

export default LoginHeaderComponent;
