// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  REQUEST_SIGNATURE_KEY_MODAL_LINK_PROPS,
  REQUEST_SIGNATURE_KEY_MODAL_BUTTON_PROPS,
} from './constants/request-signature-key-modal.constants';
// Hooks
import useRequestSignatureKeyModalHook from './hooks/request-signature-key-modal.hook';
// Styles
import {
  RecoverySignatureKeyModalListItemStyled,
  RecoverySignatureKeyModalListStyled,
  RecoverySignatureKeyModalStyled,
} from './request-signature-key-modal.component.styled';
// Translations
import {
  BUTTON,
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_03,
  LINK,
  MODAL_TITLE,
} from './translations/request-signature-key-modal.translations';

const RequestSignatureKeyModalComponent = (): React.ReactElement | null => {
  const {
    handleCloseLinkClick,
    handleConfirmButtonClick,
    handleCloseAssetClick,
    openRequestSignatureModal,
  } = useRequestSignatureKeyModalHook();

  return openRequestSignatureModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      title={<FormattedMessageComponent id={MODAL_TITLE} />}
      linkConfiguration={{
        ...REQUEST_SIGNATURE_KEY_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={LINK} />,
        onClick: handleCloseLinkClick,
      }}
      primaryButtonConfiguration={{
        ...REQUEST_SIGNATURE_KEY_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={BUTTON} />,
        onClick: handleConfirmButtonClick,
      }}
      onHeaderClose={handleCloseAssetClick}
    >
      <RecoverySignatureKeyModalStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION_01} />
        </ParagraphComponent>
        <RecoverySignatureKeyModalListStyled>
          <RecoverySignatureKeyModalListItemStyled>
            <ParagraphComponent>
              <FormattedMessageComponent id={DESCRIPTION_02} />
            </ParagraphComponent>
          </RecoverySignatureKeyModalListItemStyled>
          <RecoverySignatureKeyModalListItemStyled>
            <ParagraphComponent>
              <FormattedMessageComponent id={DESCRIPTION_03} />
            </ParagraphComponent>
          </RecoverySignatureKeyModalListItemStyled>
        </RecoverySignatureKeyModalListStyled>
      </RecoverySignatureKeyModalStyled>
    </ModalComponent>
  ) : null;
};

export default RequestSignatureKeyModalComponent;
