// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TRANSACTION_TABLE_ROW_AMOUNT_HELPER_PROPS,
  TRANSACTION_TABLE_ROW_AMOUNT_HELPER_STYLED_PROPS,
} from 'components/transaction-table-row/components/amount/components/helper/constants/transaction-table-row-amount-helper.constants';
// Enumerations
import { TransactionsStatusCodeEnumeration } from 'enumerations/transactions-status-code.enumeration';
// Styles
import { TransactionTableRowAmountHelperComponentStyled } from 'components/transaction-table-row/components/amount/components/helper/transaction-table-row-amount-helper.component.styled';
// Translations
import { TRANSACTIONS_TABLE_STATUS_TRANSLATIONS } from './translations/transaction-table-row-amount-helper.translations';
// Types
import { CardTransactionType } from 'types/card-transaction.type';
// Utilities
import { showTransactionStatusMessage } from 'components/transaction-table-row/components/amount/components/helper/utils/transaction-table-row-amount-helper.utils';

const TransactionTableRowAmountHelperComponent = ({
  transactionStatusCode,
  transactionLabel = '',
}: Pick<
  CardTransactionType,
  'transactionStatusCode' | 'transactionLabel'
>): React.ReactElement | null =>
  showTransactionStatusMessage(transactionStatusCode, transactionLabel) ? (
    <ParagraphComponent {...TRANSACTION_TABLE_ROW_AMOUNT_HELPER_PROPS}>
      <TransactionTableRowAmountHelperComponentStyled
        {...TRANSACTION_TABLE_ROW_AMOUNT_HELPER_STYLED_PROPS}
        {...{ transactionStatusCode }}
      >
        <FormattedMessageComponent
          id={TRANSACTIONS_TABLE_STATUS_TRANSLATIONS[TransactionsStatusCodeEnumeration.AUTHORIZED]}
        />
      </TransactionTableRowAmountHelperComponentStyled>
    </ParagraphComponent>
  ) : null;

export { TransactionTableRowAmountHelperComponent };
