// Vendors
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Context
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
// Handlers
import { DeviceDetailHandlers } from '../handlers/device-detail.handlers';
import { DeviceManagerHandlers } from 'containers/security-keys/views/devices/handlers/signature-key-devices.handlers';
// Types
import { SignatureKeyDevicesDeviceType } from '../../../types/signature-key-devices.device.type';
import { DeviceDetailHookReturnType } from './types/device-detail.hook.return.type';

const DeviceDetailHook = (): DeviceDetailHookReturnType => {
  const { id } = useParams();
  const { devices, trusted, blockedDevices, fetching, setFetching, setDevices, setBlockedDevices, setError, setTrusted, error } = SignatureKeyDevicesContextConsumerHook();
  const [device, setDevice] = useState<SignatureKeyDevicesDeviceType>();

  const { getLastDevices } = DeviceManagerHandlers({ setFetching, setDevices, setBlockedDevices, setError, setTrusted });
  const { getAlldevices } = DeviceDetailHandlers({
    id,
    devices,
    trusted,
    blockedDevices,
    setDevice,
    setError,
    getLastDevices
  });
  useEffect(() => {
    getAlldevices();
  }, [devices, trusted, blockedDevices, id ]);

  return {
    device,
    fetching,
    error
  };
};

export { DeviceDetailHook };