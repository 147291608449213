// Contexts
import { ProfilePersonalNameAliasContextConsumerHook } from 'containers/personal/views/landing/components/name/components/alias/contexts/profile-personal-name-alias.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import ProfilePersonalNameAliasEditHandlers from '../handlers/profile-personal-name-alias-edit.handlers';
import ProfilePersonalNameAliasEditTrackingHandlers from '../handlers/profile-personal-name-alias-edit.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameAliasEditHookType } from 'containers/personal/views/landing/components/name/components/alias/components/edit/hooks/types/profile-personal-name-alias-edit.hook.type';

const ProfilePersonalNameAliasEditHook = (): ProfilePersonalNameAliasEditHookType => {
  const { setEditMode } = ProfilePersonalNameAliasContextConsumerHook(),
    { profile } = AppContextConsumerHook(),
    { alias } = profile?.userDetails || {};

  return {
    ...ProfilePersonalNameAliasEditHandlers({
      ...AppTrackingHook(ProfilePersonalNameAliasEditTrackingHandlers),
      setEditMode,
    }),
    alias,
  };
};

export default ProfilePersonalNameAliasEditHook;
