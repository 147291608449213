// Vendors
import React from 'react';
// Components
import CreditComponent from '../credit/credit.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Constants
import constants from './constants/credit-header.constants';
// Styles
import {
  CreditHeaderComponentStyled,
  CreditHeaderCreditComponentStyled,
} from './credit-header.component.styled';
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';

const CreditHeaderComponent = (): React.ReactElement => (
  <CreditHeaderComponentStyled>
    <TitleSectionComponent />
    {!isSecondLevelRoutePath() && (
      <CreditHeaderCreditComponentStyled>
        <CreditComponent {...constants.DASHBOARD_CREDIT_CARD_PROPS} />
      </CreditHeaderCreditComponentStyled>
    )}
  </CreditHeaderComponentStyled>
);

export default CreditHeaderComponent;
