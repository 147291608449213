// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsCheck3dWizardHookType } from 'containers/card-settings/views/check-3d/components/wizard/hooks/types/card-settings-check-3d-wizard.hook.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const useCardSettingsCheck3dWizardHook = (): CardSettingsCheck3dWizardHookType => {
  const { formatMessage } = useCbIntl();
  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  return { formatMessage, onNavigateHybridFlow };
};

export default useCardSettingsCheck3dWizardHook;
