// Constants
import { MODAL_PROPS } from './constants/card-settings-block-card-replacement-modal.configurations.constants';
// Translations
import {
  BUTTONS,
  TITLE,
} from './translations/card-settings-block-card-replacement-modal.configurations.translations';
// Types
import { GetModalConfigurationReturnType } from './types/get-modal-configuration-return.type';
import { GetModalConfigurationType } from './types/get-modal-configuration.type';

export const getModalConfiguration = ({
  formatMessage,
  handleCloseButtonClick,
  handleBackButtonClick,
}: GetModalConfigurationType): GetModalConfigurationReturnType => ({
  ...MODAL_PROPS,
  onHeaderClose: handleCloseButtonClick,
  title: formatMessage({ id: TITLE }),
  primaryButtonConfiguration: {
    children: formatMessage({ id: BUTTONS.BACK }),
    onClick: handleBackButtonClick,
    testId: 'card-settings-block-card-replacement-modal-button',
  },
});
