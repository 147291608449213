// Components
import CardSettingsBlockCardFirstStepComponent from '../components/first-step/card-settings-block-card-first-step.component';
import CardSettingsBlockCardSecondStepComponent from '../components/second-step/card-settings-block-card-second-step.component';
import CardSettingsBlockCardSuccessContentComponent from '../components/success-content/card-settings-block-card-success-content.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { FAILED, STEPS, SUCCESS } from '../translations/card-settings-block-card.translations';

const BLOCK_CARD_WIZARD_PROPS = {
  feedbackConfiguration: {
    errorConfiguration: {
      description: FAILED.CONFIRMATION.CONTENT,
      exitRoute: RoutesEnumeration.CARD_SETTINGS,
      title: FAILED.CONFIRMATION.TITLE,
    },
    successConfiguration: {
      content: CardSettingsBlockCardSuccessContentComponent,
      description: SUCCESS.CONFIRMATION.CONTENT,
      exitRoute: RoutesEnumeration.CARD_SETTINGS,
      linkLabel: SUCCESS.BACK_BUTTTON,
      title: SUCCESS.CONFIRMATION.TITLE,
    },
  },
  steps: [
    {
      component: CardSettingsBlockCardFirstStepComponent,
      title: STEPS.FIRST.TITLE,
    },
    {
      component: CardSettingsBlockCardSecondStepComponent,
      title: STEPS.SECOND.TITLE,
    },
  ],
};

export { BLOCK_CARD_WIZARD_PROPS };
