// Types
import { FinancingPaymentMethodSecondStepSummaryHandlersType } from './types/financing-payment-method-second-step-summary.handlers.type';
import { FinancingPaymentMethodSecondStepSummaryReturnHandlersType } from './types/financing-payment-method-second-step-summary-return.handlers.type';
import { PaymentMethodSummaryRenderHandlerType } from './types/payment-method-summary-render-handler.type';
// Utils
import {
  getNewPaymentMethod,
  getPaymentMethodLabel,
} from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

const paymentMethodSummaryRenderHandler = ({
  accountBalancePercentage,
  fee,
  fixedPaymentAmount,
  formatMessage,
  formatNumber,
  paymentMethod,
  paymentMethodCode,
  revolvingMethod,
  setError,
  setPaymentMethodSummary,
}: PaymentMethodSummaryRenderHandlerType) => {
  const paymentMethodLabel = getPaymentMethodLabel({
    accountBalancePercentage,
    fixedPaymentAmount,
    formatMessage,
    formatNumber,
    paymentMethodCode,
  });

  const newPaymentMethodLabel = getPaymentMethodLabel({
    accountBalancePercentage: fee,
    fixedPaymentAmount: fee,
    formatMessage,
    formatNumber,
    paymentMethodCode: getNewPaymentMethod({ paymentMethod, revolvingMethod }),
  });

  if (!paymentMethodLabel || !newPaymentMethodLabel) {
    setError(true);
  }

  setPaymentMethodSummary({ paymentMethodLabel, newPaymentMethodLabel });
};

const FinancingPaymentMethodSecondStepSummaryHandlers = (
  props: FinancingPaymentMethodSecondStepSummaryHandlersType
): FinancingPaymentMethodSecondStepSummaryReturnHandlersType => ({
  handlePaymentMethodSummaryRender: () => paymentMethodSummaryRenderHandler(props),
});

export default FinancingPaymentMethodSecondStepSummaryHandlers;
