// Constants
import commonConstants from '../constants/transactions-table-header-link-bar.constants.common';

export default {
  ...commonConstants,
  TRANSACTIONS_TABLE_LINK_VIEW_OR_DOWNLOAD_PROPS: {
    assetConfiguration: {
      id: 'download_simple',
      colors: {
        fill: 'linkPrimaryA',
        hover: 'backgroundInfoD',
      },
      sizes: {
        height: 24,
        width: 24,
      },
      testId: 'transactions-all-download-asset',
    },
    iconOnLeft: true,
    testId: 'transactions-all-download',
  },
};
