// Constants
import { WIZARD_FEEDBACK_SCROLL_PROPS } from './constants/wizard-feedback.handlers.constants';
// Types
import { WizardFeedbackMethodsHandlersType } from './types/wizard-feedback.handlers.type';
import { WizardFeedbackContextType } from '../contexts/types/wizard-feedback.context.type';

const restoreAllWindowScrollHandler = (result: WizardFeedbackContextType['result']): void => {
  result !== undefined && window.scrollTo(WIZARD_FEEDBACK_SCROLL_PROPS as ScrollToOptions);
};

const WizardFeedbackHandlers = (
  result: WizardFeedbackContextType['result']
): WizardFeedbackMethodsHandlersType => ({
  handleRestoreAllWindowScroll: () => restoreAllWindowScrollHandler(result),
});

export { WizardFeedbackHandlers };
