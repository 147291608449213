// Handlers
import { TransactionsReportFraudulentMovementUploadDocumentHandlers } from '../handlers/transactions-report-fraudulent-movement-upload-documents-actions.handlers';
// Hooks
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import CardStatusHook from 'hooks/card-status.hook';
// Types
import { UseFradulentMovementUploadDocumentsActionsHookReturnType } from './types/transactions-report-fraudulent-movement-upload-documents-actions.type';
// Utils
import { isStatusNotBlockedOrCanceled } from 'containers/transactions/views/report/components/fraudulent-movement/components/confirmation/utils/transactions-report-fraudulent-movement-confirmation.utils';

const useFradulentMovementUploadDocumentsActionsHook =
  (): UseFradulentMovementUploadDocumentsActionsHookReturnType => {
    const { setFiles } = TransactionsReportFraudulentMovementConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();
    const { status } = CardStatusHook();
    const isNotBlocked = isStatusNotBlockedOrCanceled(status);
    const { handleGoBack } = TransactionsReportFraudulentMovementUploadDocumentHandlers({
      isNotBlocked,
      setFiles,
      setCurrentStep,
    });

    return {
      isNotBlocked,
      handleGoBack,
    };
  };

export { useFradulentMovementUploadDocumentsActionsHook };
