// Constants
import { MULTIPLE_VALUE } from './constants/amount.handlers.utils';
// Enumerations
import { ValidationStatus } from './enumerations/amoun.handlers.utils.enumerations';
// Types
import { GetInputStatusPropsType } from './types/get-input-status-props.type';

const getInputStatus = ({ value, minAmount, maxAmount }: GetInputStatusPropsType): string => {
  const newValue = Number(value);

  switch (true) {
    case newValue < minAmount:
      return ValidationStatus.BelowMin;
    case newValue > maxAmount:
      return ValidationStatus.AboveMax;
    case newValue && newValue % MULTIPLE_VALUE !== 0:
      return ValidationStatus.NotMultipleOf100;
    default:
      return '';
  }
};

const isNumeric = (value: string): boolean => {
  const regex = /^[0-9]*,?[0-9]{0,2}$/;
  return regex.test(value);
};

export { getInputStatus, isNumeric };
