// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
import { useSearchParams } from 'react-router-dom';
// Utils
import {
  changeAccessKeyFromMobile,
  isAccessKeyCookieExpired,
  noNeedUserData,
} from '../utils/entrypoint-authorization.utils';
// Enums
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Types
import { EntrypointAuthorizationHookType } from './types/entrypoint-authorization.hook.type';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import { isOnboardingIncompleted } from 'utils/onboarding.utils'; 

const EntrypointAuthorizationHook = (): EntrypointAuthorizationHookType => {
  const accessKeyExpired = cookies.getCookie(StorageCookiesEnum.ACCESS_KEY_EXPIRED);

  const { isHybrid } = MobileConnectorContextConsumerHook();
  const [searchParams] = useSearchParams();
  const onboardingComplete = cookies.getCookie(StorageCookiesEnum.ONBOARDING_COMPLETED);

  return {
    noNeedsUserData: noNeedUserData([
      changeAccessKeyFromMobile({ isHybrid, searchParams }),
      isAccessKeyCookieExpired(accessKeyExpired),
    ]),
    onboardingIncomplete: isOnboardingIncompleted(onboardingComplete),
  };
};

export { EntrypointAuthorizationHook };
