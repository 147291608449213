// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from './configurations/request-signature-key-step2.configurations';
// Hooks
import useRequestSignatureKeyStep2ChallengeHook from './hooks/request-signature-key-step2-challenge.hook';

const RequestSignatureKeyStep2ChallengeComponent = (): React.ReactElement => {
  const {
    challengeError,
    handleChallengeRetryButtonClick,
    handleManageErrorOnRequestSignatureKeyEvent,
    handleManageSuccessOnRequestSignatureKeyEvent,
  } = useRequestSignatureKeyStep2ChallengeHook();

  return (
    <ChallengeWrapperComponent
      {...getChallengeConfiguration({
        challengeError,
        handleChallengeError: handleManageErrorOnRequestSignatureKeyEvent,
        handleChallengeSuccess: handleManageSuccessOnRequestSignatureKeyEvent,
        handleChallengeRetryButtonClick,
      })}
    />
  );
};

export default RequestSignatureKeyStep2ChallengeComponent;
