// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  NOTIFICATIONS_SETTINGS_MODAL_LINK_PROPS,
  NOTIFICATIONS_SETTINGS_MODAL_BUTTON_PROPS,
  NOTIFICATIONS_SETTINGS_MODAL_PARAGRAPH_PROPS,
} from './constants/notifications-settings-modal.constants';
// Hooks
import { NotificationsSettingsModalHook } from './hooks/notifications-settings-modal.hook';
// Styles
import { NotificationsSettingsModalDescriptionComponentStyled } from './notifications-settings-modal.component.styled';
// Translations
import {
  BUTTON_TITLE,
  PARRAGRAPH_DESCRIPTION,
  LINK_TITLE,
  TITLE,
} from './translations/notifications-settings-modal.translations';
// Types
import { NotificationsSettingsModalComponentPropsType } from './types/notifications-settings-modal.type';
// Utils
import { hasInputCurrencyErrors } from '../../utils/notifications-settings.view.utils';

const NotificationsSettingsModalComponent = ({
  handleModalSaveButtonClick,
  notificationsSettings,
  setShowModal,
  showModal,
}: NotificationsSettingsModalComponentPropsType): React.ReactElement | null => {
  const { handleModalExitButtonClick, handleCloseModalButtonClick } =
    NotificationsSettingsModalHook({ showModal, setShowModal });

  return showModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...NOTIFICATIONS_SETTINGS_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={LINK_TITLE} />,
        onClick: handleModalExitButtonClick,
      }}
      primaryButtonConfiguration={{
        ...NOTIFICATIONS_SETTINGS_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={BUTTON_TITLE} />,
        onClick: handleModalSaveButtonClick,
        disabled: hasInputCurrencyErrors({ notificationsSettings }),
      }}
      onHeaderClose={handleCloseModalButtonClick}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <NotificationsSettingsModalDescriptionComponentStyled>
        <ParagraphComponent {...NOTIFICATIONS_SETTINGS_MODAL_PARAGRAPH_PROPS}>
          <FormattedMessageComponent id={PARRAGRAPH_DESCRIPTION} />
        </ParagraphComponent>
      </NotificationsSettingsModalDescriptionComponentStyled>
    </ModalComponent>
  ) : null;
};

export { NotificationsSettingsModalComponent };
