// Types
import { ScreenViewType, StepType } from '../types/check-cvv-pin.type';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.';

export default {
  CHALLENGE: 'challenge' as StepType,
  CODE: 'code' as StepType,
  KO: 'ko' as StepType,
  CVV: 'cvv' as ScreenViewType,
  PIN: 'pin' as ScreenViewType,
  DEFAULT_CARD_ART: 'https://assets.zinia.com/cb/illustrations/amazon_visa_prime.png',
  styles: {
    wrapper: {
      maxWidth: '100%',
    },
    sidebar: {
      maxWidth: 276,
    },
  },
  TRANSLATIONS: {
    CVV_DOMAIN: `${DOMAIN}checkCvv.`,
    PIN_DOMAIN: `${DOMAIN}checkPin.`,
  },
};
