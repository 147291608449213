export const DOCUMENT_TIP_1_ASSET = {
  id: 'document-ko-lightning-error-alternative',
  type: 'illustration',
  sizes: { height: 128, width: 128 },
};

export const DOCUMENT_TIP_2_ASSET = {
  id: 'document-ko-framing-error-alternative',
  type: 'illustration',
  sizes: { height: 128, width: 128 },
};

export const DOCUMENT_TIP_3_ASSET = {
  id: 'Document_Ok_Alternative',
  type: 'illustration',
  sizes: { height: 128, width: 128 },
};

export const PARAGRAPHS_XS_SIZE = 'XS';
