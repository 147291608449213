// Constants
import { linkConstants, titleConstants } from '@openbank/cb-ui-commons';

const WIZARD_FEEDBACK_HOST_ASSET_PROPS = {
    sizes: {
      height: 48,
      width: 48,
    },
    testId: 'wizard-feedback-host-asset',
  },
  WIZARD_FEEDBACK_HOST_BUTTON_PROPS = {
    testId: 'wizard-feedback-host-button',
  },
  WIZARD_FEEDBACK_HOST_RIGHT_LINK_PROPS = {
    testId: 'wizard-feedback-host-right-link',
    type: linkConstants.TYPES.ACTION_PRIMARY,
  },
  WIZARD_FEEDBACK_HOST_LAYOUT_PROPS = {
    testId: 'wizard-feedback-host-layout',
  },
  WIZARD_FEEDBACK_HOST_LEFT_LINK_PROPS = {
    testId: 'wizard-feedback-host-left-link',
    type: linkConstants.TYPES.ACTION_PRIMARY,
  },
  WIZARD_FEEDBACK_HOST_TITLE_PROPS = {
    testId: 'wizard-feedback-host-title',
    tag: titleConstants.TAGS.H4,
  };

export {
  WIZARD_FEEDBACK_HOST_ASSET_PROPS,
  WIZARD_FEEDBACK_HOST_BUTTON_PROPS,
  WIZARD_FEEDBACK_HOST_LEFT_LINK_PROPS,
  WIZARD_FEEDBACK_HOST_RIGHT_LINK_PROPS,
  WIZARD_FEEDBACK_HOST_LAYOUT_PROPS,
  WIZARD_FEEDBACK_HOST_TITLE_PROPS,
};
