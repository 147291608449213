import { titleConstants } from '@openbank/cb-ui-commons';

export const DEVICE_ICON_PROPS = { sizes: { width: 48, height: 48 } };
export const DEVICE_ICON_CURRENT_COLOR = {
  colors: { fill: 'backgroundDoneA' },
};

export const DEVICE_ICON_ERROR_COLOR = {
  colors: { fill: 'fontTertiaryF' },
};

export const CHALLENGE_WRAPPER_PROPS = {
  baseService: 'mb-api-sss-devices/device/untrust',
  baseServiceMethod: 'post',
  modal: true
};

export const DEVICE_ALIAS_TITLE_PROPS = {
  tag: titleConstants.TAGS.H3,
  testId: 'device-alias-title',
};

export const DEFAULT_ICON_DEVICE = 'computer';
export const WARNING_PROPS = { type: 'warning' };