// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent, SectionDividerComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/profile-personal-data-address.constants';
// Hooks
import ProfilePersonalDataAddressHook from './hooks/profile-personal-data-address.hook';
// Styles
import { ProfilePersonalDataAddressComponentStyled } from './profile-personal-data-address.component.styled';
import { ProfilePersonalDataSectionComponentStyled } from '../../profile-personal-data.component.styled';
import { LayoutHorizontalPaddingStyledComponent } from '@/styles/app.styled';
// Translations
import translations from './translations/profile-personal-data-address.translations';
// Utilities
import { createMappedPrimaryResidentialAddress } from './utils/profile-personal-data-address.utils';

const ProfilePersonalDataAddressComponent = (): React.ReactElement => {
  const { address, formatMessage, handleEditCurrentPrimaryResidentialAssetClick } =
    ProfilePersonalDataAddressHook();

  return (
    <ProfilePersonalDataAddressComponentStyled>
      <SectionDividerComponent
        {...constants.PROFILE_PERSONAL_DATA_ADDRESS_DIVIDER_PROPS}
        title={<FormattedMessageComponent id={translations.TITLE} />}
      >
        <LayoutHorizontalPaddingStyledComponent>
          <ProfilePersonalDataSectionComponentStyled>
            <ResumeItemComponent
              {...constants.PROFILE_PERSONAL_DATA_ADDRESS_PROPS}
              label={<FormattedMessageComponent id={translations.PRIMARY_RESIDENTIAL} />}
              onEdit={handleEditCurrentPrimaryResidentialAssetClick}
              value={createMappedPrimaryResidentialAddress({ address, formatMessage })}
            />
          </ProfilePersonalDataSectionComponentStyled>
        </LayoutHorizontalPaddingStyledComponent>
      </SectionDividerComponent>
    </ProfilePersonalDataAddressComponentStyled>
  );
};

export default ProfilePersonalDataAddressComponent;
