const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.cancellation.cbFeedback.';

const FEEDBACK_ERROR_DESCRIPTION = `${DOMAIN}error.description`,
  FEEDBACK_ERROR_TITLE = `${DOMAIN}error.title`,
  FEEDBACK_SUCCESS_DESCRIPTION = `${DOMAIN}success.description`,
  FEEDBACK_SUCCESS_TITLE = `${DOMAIN}success.title`,
  GO_TO_INSURANCES = `${DOMAIN}goToInsurancesLink`;

export {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_INSURANCES,
};
