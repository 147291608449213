// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_BUTTON_PROPS,
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_CHALLENGE_PROPS,
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_FOOTER_PROPS,
} from './constants/financing-ppi-cancellation-landing-footer.constants';
// Hooks
import { useFinancingPPICancellationLandingFooterActionsHook } from './hooks/financing-ppi-cancellation-landing-footer.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { BUTTON } from './translations/financing-ppi-cancellation-landing-footer.translations';
// Utilities
import { challengeFlowButtonIsDisabled } from 'utils/challenges/challenge.utils';
import { isSameObject } from 'utils/app.utils';

const FinancingPPICancellationLandingFooterComponent = (): React.ReactElement => {
  const {
    contractId,
    challenged,
    fetching,
    handleConfirmCancelPPIContractButtonClick,
    handleManageFailedCancelPPIChallengeEvent: onError,
    handleManageSuccessCancelPPIChallengeEvent: onSuccess,
  } = useFinancingPPICancellationLandingFooterActionsHook();

  return (
    <LayoutColumnFlexComponentStyled>
      <ChallengeWrapperComponent
        {...FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_CHALLENGE_PROPS}
        {...{ onError, onSuccess }}
        baseServiceBuilder={{ pathParams: { contractId } }}
      />
      <WizardStepFooterComponent
        {...FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_FOOTER_PROPS}
        shouldShowModal={!isSameObject(challenged, false)}
        rightButtonConfiguration={{
          ...FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_BUTTON_PROPS,
          disabled: challengeFlowButtonIsDisabled({ challenged, fetching }),
          children: <FormattedMessageComponent id={BUTTON} />,
          loading: fetching,
          onClick: handleConfirmCancelPPIContractButtonClick,
        }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingPPICancellationLandingFooterComponent };
