const formatCurrency = (value: number, locale = 'de-DE', currency = 'EUR'): string => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
};

const formatPercentage = (value: number, locale = 'de-DE'): string => {
  return new Intl.NumberFormat(locale, { style: 'percent', minimumFractionDigits: 2 }).format(
    value / 100
  );
};

export { formatCurrency, formatPercentage };
