// Vendors
import React from 'react';
// Components
import { ButtonComponent, MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  BUTTON_PROPS,
  MESSAGE_PROPS,
  TITLE_PROPS,
} from './constants/transactions-epp-step1-credit-message.constants';
// Hooks
import { useTransactionsEppStep1CreditMessage } from './hooks/transactions-epp-step1-credit-message.hook';
// Styles
import {
  TransactionsEppStep1CreditMessageContentStyled,
  TransactionsEppStep1CreditMessageContentTextStyled,
} from './transactions-epp-step1-credit-message.component.styled';
// Translations
import {
  BUTTON,
  DESCRIPTION,
  TITLE,
} from './translations/transactions-epp-step1-credit-message.translations';

const TransactionsEppStep1CreditMessageComponent = (): React.ReactElement => {
  const { handleModifyCreditLimitButtonClick } = useTransactionsEppStep1CreditMessage();

  return (
    <MessageComponent {...MESSAGE_PROPS}>
      <TransactionsEppStep1CreditMessageContentStyled>
        <TransactionsEppStep1CreditMessageContentTextStyled>
          <ParagraphComponent {...TITLE_PROPS}>
            <FormattedMessageComponent id={TITLE} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
        </TransactionsEppStep1CreditMessageContentTextStyled>
        <ButtonComponent {...BUTTON_PROPS} onClick={handleModifyCreditLimitButtonClick}>
          <FormattedMessageComponent id={BUTTON} />
        </ButtonComponent>
      </TransactionsEppStep1CreditMessageContentStyled>
    </MessageComponent>
  );
};

export { TransactionsEppStep1CreditMessageComponent };
