// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { SecurityKeysSignatureKeyChangeFirstStepActionsUtilsType } from './types/signature-key-change-step1-actions.utils.type';

const modifySignatureKeyChangeStep1NextButtonIsDisabled = ({
  signatureKey,
}: SecurityKeysSignatureKeyChangeFirstStepActionsUtilsType): boolean => {
  return signatureKey.length !== NUMBER.EIGHT;
};

export { modifySignatureKeyChangeStep1NextButtonIsDisabled };
