// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/change-iban.context.constants';
// Types
import { ChangeIbanContextType } from './types/change-iban.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SepaMandateType } from 'types/sepa-mandate.type';

const ChangeIbanContext = createContext<ChangeIbanContextType>(CONTEXT_DEFAULT_VALUE);

export const ChangeIbanContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ChangeIbanContextType>> => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [checkboxValue, setCheckboxValue] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [ibanValue, setIbanValue] = useState<string>('');
  const [openSepaModal, setOpenSepaModal] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');
  const [sepaMandate, setSepaMandate] = useState<SepaMandateType | null>(null);

  return (
    <ChangeIbanContext.Provider
      value={{
        challenged,
        checkboxValue,
        error,
        fetching,
        ibanValue,
        openSepaModal,
        progressId,
        sepaMandate,
        setChallenged,
        setCheckboxValue,
        setError,
        setFetching,
        setIbanValue,
        setOpenSepaModal,
        setProgressId,
        setSepaMandate,
      }}
    >
      {children as React.ReactNode}
    </ChangeIbanContext.Provider>
  );
};

export const ChangeIbanContextConsumerHook = (): ChangeIbanContextType =>
  useContext(ChangeIbanContext);

/* eslint-disable */
export const ChangeIbanContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ChangeIbanContextType>> =>
    (
      <ChangeIbanContextProvider>
        <Component {...props} />
      </ChangeIbanContextProvider>
    );
