// Enumerations
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';
// Types
import {
  RecoveryKeyStep3BuilderHandlersType,
  RecoveryKeyStep3BuilderReturnHandlersType,
  RecoveryKeyStep3MethodBuilderHandlersType,
} from './types/recovery-key-step3.handlers.type';
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';

const recoveryOptionItemRadioClickHandler = ({
  handleRecoveryOptionItemRadioClickTracking,
  id,
  setRecoveryOption,
}: RecoveryKeyStep3MethodBuilderHandlersType): void => {
  handleRecoveryOptionItemRadioClickTracking(id);
  setRecoveryOption(id);
};

const goToStep4RecoveryFlowButtonClickHandler = ({
  setCurrentStep,
}: Pick<RecoveryKeyStep3BuilderHandlersType, 'setCurrentStep'>): void => {
  setCurrentStep(WizardStepsEnumeration.FOUR);
};

const RecoveryKeyStep3Handlers = ({
  handleRecoveryOptionItemRadioClickTracking,
  setCurrentStep,
  setRecoveryOption,
}: RecoveryKeyStep3BuilderHandlersType): RecoveryKeyStep3BuilderReturnHandlersType => ({
  handleGoToStep4RecoveryFlowButtonClick: () =>
    goToStep4RecoveryFlowButtonClickHandler({ setCurrentStep }),
  handleRecoveryOptionItemRadioClick: (id: PasswordRecoveryOptionsEnumeration) =>
    recoveryOptionItemRadioClickHandler({
      handleRecoveryOptionItemRadioClickTracking,
      id,
      setRecoveryOption,
    }),
});

export default RecoveryKeyStep3Handlers;
