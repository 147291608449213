export default {
  TRANSACTIONS_LANDING_ROW_DETAILS_SUBCATEGORY_PROPS: {
    testId: 'transactions-landing-row-details-subcategory',
  },
  TRANSACTIONS_LANDING_ROW_DETAILS_SUBCATEGORY_LINK_PROPS: {
    testId: 'transactions-landing-row-details-subcategory-links',
    disabled: true,
  },
  AUTHORIZED: 'authorized',
};
