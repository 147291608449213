// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const NotificationsSettingsRowToggleWrapperStyled = styled.section.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingXxxxxl} 0;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingL} ${theme.spaces.spacingS}`};
  }
`;

const CategorySectionDividerWrapperStyled = styled.section`
  padding: 0 ${({ theme }) => theme.spaces.spacingXxxxl};
  background-color: ${({ theme }) => theme.colors.brandE};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `0 ${theme.spaces.spacingS}`};
    background-color: transparent;
  }
`;

export { NotificationsSettingsRowToggleWrapperStyled, CategorySectionDividerWrapperStyled };