export default {
  DASHBOARD_LAST_SESSION_LINK_PROPS: {
    assetConfiguration: {
      id: 'clock',
      sizes: {
        height: 20,
        width: 20,
      },
      testId: 'dashboard-last-session-links-asset',
    },
    iconOnLeft: true,
    testId: 'dashboard-last-session-links',
  },
  DASHBOARD_LAST_SESSION_DESCRIPTION_PROPS: {
    testId: 'dashboard-last-session-table-row-description',
  },
};
