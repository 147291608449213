// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { UploadDocumentHandlers } from '../handlers/upload-document.handlers';
// Types
import { UseUploadDocumentHookPropsType } from './types/upload-document-props.type.hook';
import { UseUploadDocumentHookType } from './types/upload-document.hook.type';

export const useUploadDocumentHook = ({
  documentId,
  documentType,
  file,
  id,
  onChangeFiles,
  reRequestFiles,
  updateFilesOnDB,
}: UseUploadDocumentHookPropsType): UseUploadDocumentHookType => {
  const { profile } = AppContextConsumerHook(),
    { customerId } = profile?.userDetails || {};

  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [documentUploaded, setDocumentUploaded] = useState<string | undefined>();

  const docUriController = new AbortController();
  const uploadDocController = new AbortController();
  const updateDocController = new AbortController();

  const propsHandlers = {
    customerId,
    documentId,
    documentUploaded,
    documentType,
    docUriController,
    file,
    id,
    isCancelled,
    onChangeFiles,
    uploadDocController,
    reRequestFiles,
    setIsCancelled,
    setDocumentUploaded,
    updateFilesOnDB,
    updateDocController,
  };

  const { handleDocumentUploaded } = UploadDocumentHandlers(propsHandlers);

  useEffect(() => {
    handleDocumentUploaded();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentUploaded, isCancelled]);

  return {
    ...UploadDocumentHandlers(propsHandlers),
    isCancelled,
  };
};
