// Vendors
import { TrackingEventsEnum, TrackingViewEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ChangeIbanBuilderReturnTrackingHandlersType } from './types/change-iban-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const displayChangeIbanPageTracking = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeIban/step01/reviseAndModifyIban',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'accounts',
    interactionType: 'funnel',
    serviceType: 'productSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const ChangeIbanTrackingHandlers = (
  track: TrackBuilderType
): ChangeIbanBuilderReturnTrackingHandlersType => ({
  handleDisplayChangeIbanPageTracking: () => track(displayChangeIbanPageTracking()),
});

export default ChangeIbanTrackingHandlers;
