// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
// Enumerations
import { CookiesEnumeration } from 'enumerations/cookies.enumeration';

const thirdPartyDownCookieIsNotSet = (): boolean =>
  !cookies.getCookie(CookiesEnumeration.THIRD_PARTY_DOWN) ||
  cookies.getCookie(CookiesEnumeration.THIRD_PARTY_DOWN) !== 'true';

export { thirdPartyDownCookieIsNotSet };
