// Constants
import constants from 'constants/app.constants';
import { EnvironmentsEnum } from '@openbank/cf-ui-static-data';

const isLocalHostEnvironment = (): boolean =>
  new RegExp(constants.LOCALHOST_REGEX).test(window.location.hostname);

const isLocalhost = (): boolean =>
  isLocalHostEnvironment() || location.hostname === constants.HOST_NAME;

const isCC = (): boolean => constants.IS_CC;

const isDev = (): boolean => constants.DEV_ENVIRONMENTS.includes(window.ENVIRONMENT);

const isStg = (): boolean => constants.STG_ENVIRONMENTS.includes(window.ENVIRONMENT);

const isDemo = (): boolean => constants.DEMO_ENVIRONMENTS.includes(window.ENVIRONMENT);

const isProduction = (): boolean =>
  window.ENVIRONMENT.toUpperCase() === EnvironmentsEnum.PROD.toUpperCase();

const getEnvironmentDomain = (): string =>
  isLocalHostEnvironment() ? window.location.hostname : constants.AMAZON_DOMAIN;

const isSameObject = <T1, T2>(obj1: T1, obj2: T2): boolean =>
  JSON.stringify(obj1) === JSON.stringify(obj2);

export {
  getEnvironmentDomain,
  isCC,
  isDemo,
  isDev,
  isLocalhost,
  isLocalHostEnvironment,
  isProduction,
  isSameObject,
  isStg,
};
