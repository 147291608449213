const ADDRESS_ATTRIBUTES_FOR_FILTER = [
    'streetLine1',
    'streetLine2',
    'number',
    'town',
    'postCode',
    'countryCode',
  ],
  ADDRESS_ATTRIBUTES_FOR_TRANSLATE = ['countryCode'];

export { ADDRESS_ATTRIBUTES_FOR_FILTER, ADDRESS_ATTRIBUTES_FOR_TRANSLATE };
