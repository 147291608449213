// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Hooks
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';
// Event handlers
import PersonalNumberStep4ActionsHandlers from '../handlers/personal-number-step4-actions.handlers';
import PersonalNumberStep4TrackingHandlers from '../handlers/personal-number-step4-actions.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalNumberStep4ActionsHookType } from './types/personal-number-step4-actions.hook.type';

const PersonalNumberStep4ActionsHook = (): PersonalNumberStep4ActionsHookType => {
  const {
      challenged,
      fetching,
      newPhoneNumber,
      progressId,
      setShowModal,
      setFetching,
      newPrefix,
      withEmail,
      setAlternativeError,
    } = PersonalNumberContextConsumerHook(),
    { primaryConfigurablePhoneId, customerId } = PersonalNumberHook();
  const { setResult } = useWizardFeedbackContextConsumerHook(),
    { setMe } = ManagerContextConsumerHook();
  return {
    ...PersonalNumberStep4ActionsHandlers({
      ...AppTrackingHook(PersonalNumberStep4TrackingHandlers),
      customerId,
      newPhoneNumber,
      newPrefix,
      phoneId: primaryConfigurablePhoneId,
      progressId,
      setAlternativeError,
      setFetching,
      setMe,
      setResult,
      setShowModal,
      withEmail,
    }),
    challenged,
    fetching,
    withEmail,
  };
};

export default PersonalNumberStep4ActionsHook;
