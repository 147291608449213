// Vendors
import { useIntl } from 'react-intl';
// Constants
import appConstants from 'constants/app.constants';
// Hooks
import PoeKeysHook from 'providers/poe-keys/hooks/poe-keys.hook';
// Types
import { FormattedMessageHandlerPropsType } from './types/formatted-message-handler.type';
import { UseCbIntlReturnType } from './types/use-cb-intl-return.type';
// Utils
import { replaceWithDictionaryValue } from './utils/useCbIntl.utils';

const useCbIntl = (): UseCbIntlReturnType => {
  const { isShowingKeys } = PoeKeysHook();
  const dictionary = JSON.parse(localStorage.getItem(appConstants.DICTIONARY) || '{}');
  const intl = useIntl();

  const formattedMessageHandler = ({
    descriptor,
    values,
    opts,
  }: FormattedMessageHandlerPropsType) => {
    if (!isShowingKeys) {
      return replaceWithDictionaryValue({
        dictionary,
        input: intl.formatMessage(descriptor, values, opts),
      });
    }
    return descriptor.id;
  };

  return {
    ...intl,
    formatMessage: (descriptor, values, opts) =>
      formattedMessageHandler({ descriptor, values, opts }),
  };
};

export default useCbIntl;
