// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TagComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_STATEMENTS_TABLE_ROW_TAG_PROPS,
  CARD_STATEMENTS_TABLE_ROW_TAG_TYPES,
} from './constants/card-settings-statements-table-row-tag.constants';
// Styles
import { CardSettingsStatementsTableRowTagComponentStyled } from '../../card-settings-statements-table-row.component.styled';
// Translations
import { CARD_STATEMENTS_STATUS_TAG } from './translations/card-settings-statements-table-row-tag.translations';
// Types
import { CardSettingsStatementsTableRowTagComponentType } from './types/card-settings-statements-table-row-tag.component.types';

const CardSettingsStatementsTableRowTagComponent = ({
  statementStatus,
}: CardSettingsStatementsTableRowTagComponentType): React.ReactElement | null =>
  statementStatus ? (
    <CardSettingsStatementsTableRowTagComponentStyled>
      <TagComponent
        {...CARD_STATEMENTS_TABLE_ROW_TAG_PROPS}
        type={CARD_STATEMENTS_TABLE_ROW_TAG_TYPES[statementStatus]}
      >
        <FormattedMessageComponent uppercase id={CARD_STATEMENTS_STATUS_TAG[statementStatus]} />
      </TagComponent>
    </CardSettingsStatementsTableRowTagComponentStyled>
  ) : null;

export { CardSettingsStatementsTableRowTagComponent };
