// Vendors
import React from 'react';
// Components
import { SecurityModuleComponent } from '@openbank/cb-ui-commons';
import CardSettingsCheck3dConfirmationSecurityModuleDescriptionComponent from './components/description/card-settings-check-3d-confirmation-security-module-description.component';
// Constants
import { CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_PROPS } from './constants/card-settings-check-3d-confirmation-security-module.constants';
// Hooks
import useCardSettingsCheck3dConfirmationSecurityModuleHook from './hooks/card-settings-check-3d-confirmation-security-module.hook';
// Styles
import { CardSettingsCheck3dConfirmationSecurityModuleComponentStyled } from './card-settings-check-3d-confirmation-security-module.component.styled';

const CardSettingsCheck3dConfirmationSecurityModuleComponent = (): React.ReactElement => {
  const { shownCode, scaCode, showAllowance } =
    useCardSettingsCheck3dConfirmationSecurityModuleHook();

  return (
    <CardSettingsCheck3dConfirmationSecurityModuleComponentStyled>
      <SecurityModuleComponent
        {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_PROPS}
        disabled={!showAllowance}
        visible={shownCode}
        value={scaCode}
      />
      <CardSettingsCheck3dConfirmationSecurityModuleDescriptionComponent />
    </CardSettingsCheck3dConfirmationSecurityModuleComponentStyled>
  );
};

export default CardSettingsCheck3dConfirmationSecurityModuleComponent;
