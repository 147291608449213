// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import {
  getRequestedDocuemnts,
  reRequestDocuementsService,
  updateDocumentOnDBService,
} from '../services/personal-upload-documentation.services';
// Types
import { RequestedDocumentsResponse } from '../types/requested-documents.type';
import { RequestedDocumentType } from '../types/requested-document.type';

const getRequestedDocumentsResolver = async (): Promise<
  [RequestedDocumentsResponse['documents'] | null, boolean]
> => {
  const [response, { status }] = await getRequestedDocuemnts();

  return requestHasSuccessStatus(status) ? [response.documents, false] : [null, true];
};

const reRequestDocumentsResolver = async (
  props: Pick<RequestedDocumentType, 'customerId' | 'documentId' | 'documentType'>
): Promise<[Record<string, unknown> | null, boolean]> => {
  const [_, { status }] = await reRequestDocuementsService(props);

  return requestHasSuccessStatus(status) ? [{}, false] : [null, true];
};

const updateDocumentOnDBResolver = async ({
  customerId,
  documentId,
  documentType,
  signal,
}: RequestedDocumentType): Promise<[Record<string, unknown> | null, boolean]> => {
  const [_, { status }] = await updateDocumentOnDBService({
    customerId,
    documentId,
    documentType,
    signal,
  });

  return requestHasSuccessStatus(status) ? [{}, false] : [null, true];
};

export { getRequestedDocumentsResolver, reRequestDocumentsResolver, updateDocumentOnDBResolver };
