// Vendors
import styled from 'styled-components';
import { convertPxsToRems } from '@openbank/cf-ui-framework';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';

const ReportDefectiveProductInformationContainer = styled.div.attrs(({ testId }: any) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: flex;
  flex-direction: column;
  gap: inherit;
  width: 100%;
`;

ReportDefectiveProductInformationContainer.displayName =
  'ReportDefectiveAdditionalInformationContainer';

const ParagraphComponentStyled = styled(ParagraphComponent)``;

ParagraphComponentStyled.displayName = 'ParagraphComponentStyled';

const ReportProblemDefectiveInputDatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: ${convertPxsToRems({ pixels: 378, base: 14 })};
`;

ReportProblemDefectiveInputDatePickerWrapper.displayName =
  'ReportProblemDefectiveInputDatePickerWrapper';

const ReportProblemDefectiveInputsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

ReportProblemDefectiveInputsWrapper.displayName = 'ReportProblemDefectiveInputsWrapper';

const ReportProblemDefectiveInputTextAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

ReportProblemDefectiveInputTextAreaWrapper.displayName =
  'ReportProblemDefectiveInputTextAreaWrapper';

export {
  ReportProblemDefectiveInputDatePickerWrapper,
  ReportProblemDefectiveInputsWrapper,
  ReportProblemDefectiveInputTextAreaWrapper,
  ParagraphComponentStyled,
  ReportDefectiveProductInformationContainer,
};
