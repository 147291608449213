import { NewEmailErrorIdEnumeration } from '../enumerations/personal-email-step1-new.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyEmail.step1.cbInput.';

export default {
  ERRORS: {
    [NewEmailErrorIdEnumeration.FORMAT]: `${DOMAIN}newEmailAddressError`,
    [NewEmailErrorIdEnumeration.EMPTY]: `${DOMAIN}newEmailAddressEmptyError`,
  },
  INPUT_LABEL: `${DOMAIN}newEmailAddressLabel`,
};
