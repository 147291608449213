// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/credit-change-info-card.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styled
import {
  CreditChangeInfoCardSectionStyled,
  CreditChangeInfoCardInfoStyled,
} from './credit-change-info-card.styled';
// Translations
import translations from './translations/credit-change-info-card.translations';
// Types
import { CreditChangeInfoCardProps } from './types/credit-change-info-card.type';
// Utils
import { formatCreditAmount } from 'components/credit/utils/credit.utils';

export const CreditChangeInfoCard = ({
  currentCreditLimit,
  newCreditLimit,
  currency,
  maximumFractionDigits,
}: CreditChangeInfoCardProps): React.ReactElement => {
  const { formatMessage, formatNumber } = useCbIntl();

  return (
    <CreditChangeInfoCardSectionStyled>
      <CreditChangeInfoCardInfoStyled>
        <ParagraphComponent>
          {formatMessage({ id: translations.PREVIOUS_CREDIT_LIMIT })}
        </ParagraphComponent>
        <ParagraphComponent {...constants.FORMAT_NUMBER_CONSTANTS}>
          {formatCreditAmount({
            currency,
            formatNumber,
            value: currentCreditLimit,
            maximumFractionDigits,
          })}
        </ParagraphComponent>
      </CreditChangeInfoCardInfoStyled>
      <CreditChangeInfoCardInfoStyled>
        <ParagraphComponent>
          {formatMessage({ id: translations.NEW_CREDIT_LIMIT })}
        </ParagraphComponent>
        <ParagraphComponent {...constants.FORMAT_NUMBER_CONSTANTS}>
          {formatCreditAmount({
            currency,
            formatNumber,
            value: newCreditLimit,
            maximumFractionDigits,
          })}
        </ParagraphComponent>
      </CreditChangeInfoCardInfoStyled>
    </CreditChangeInfoCardSectionStyled>
  );
};
