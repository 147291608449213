// Vendors
import React from 'react';
// Components
import {
  AssetComponent,
  ButtonComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  ALREADY_CANCELLED_ASSET_PROPS,
  ALREADY_CANCELLED_BUTTON_PROPS,
  ALREADY_CANCELLED_DESCRIPTION_PROPS,
  ALREADY_CANCELLED_TITLE_PROPS,
} from './constants/support-cancel-contract-already-cancelled.constants';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import {
  SupportCancelContractAlreadyCancelledStyled,
  SupportCancelContractAlreadyCancelledParagraphStyled,
} from './support-cancel-contract-already-cancelled.styled';
// Translations
import {
  BUTTON,
  DESCRIPTION,
  TITLE,
} from './translations/support-cancel-contract-already-cancelled.translations';
// Types
import { SupportCancelContractAlreadyCancelledType } from './types/support-cancel-contract-already-cancelled.types';

const SupportCancelContractAlreadyCancelled = ({
  cancellationDate,
  onClick,
}: SupportCancelContractAlreadyCancelledType): React.ReactElement => (
  <AppCardSectionComponentStyled>
    <AppCardContentSectionComponentStyled>
      <SupportCancelContractAlreadyCancelledStyled>
        <AssetComponent {...ALREADY_CANCELLED_ASSET_PROPS} />
        <SupportCancelContractAlreadyCancelledParagraphStyled>
          <TitleComponent {...ALREADY_CANCELLED_TITLE_PROPS}>
            <FormattedMessageComponent id={TITLE} />
          </TitleComponent>
          <ParagraphComponent {...ALREADY_CANCELLED_DESCRIPTION_PROPS}>
            <FormattedMessageComponent
              id={DESCRIPTION}
              values={{
                date: cancellationDate,
              }}
            />
          </ParagraphComponent>
        </SupportCancelContractAlreadyCancelledParagraphStyled>
        <ButtonComponent {...ALREADY_CANCELLED_BUTTON_PROPS} onClick={onClick}>
          <FormattedMessageComponent id={BUTTON} />
        </ButtonComponent>
      </SupportCancelContractAlreadyCancelledStyled>
    </AppCardContentSectionComponentStyled>
  </AppCardSectionComponentStyled>
);

export { SupportCancelContractAlreadyCancelled };
