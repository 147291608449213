// Commons
import { FINANCING_COMMON_QUICK_ACTIONS } from './quick-actions-configurations.common.constants';
// Enumerations
import { QuickActionsSectionIdsEnumeration } from 'enumerations/quick-actions/quick-actions-section-ids.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CARD_CONTROL,
  GET_CVV,
  GET_PIN,
  MODIFY_PIN,
  REQUEST_ADDITIONAL_CREDIT,
  STATEMENTS,
} from '../translations/quick-actions.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const ADDITIONAL_CREDIT_LIMIT = {
  id: QuickActionsSectionIdsEnumeration.REQUEST_ADDITIONAL_CREDIT,
  component: {
    assetConfiguration: {
      id: 'increase_credit_limit',
      testId: 'quick-action-credit-limit-increase-asset',
    },
    testing: {
      buttonId: 'quick-action-credit-limit-increase-button',
      labelId: 'quick-action-credit-limit-increase-label',
    },
  },
  label: REQUEST_ADDITIONAL_CREDIT,
  tracking: 'limitIncrease',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.CREDIT_LIMIT_INCREASE])
  ),
};

const GET_CVV_OPTION = {
    id: QuickActionsSectionIdsEnumeration.GET_CVV,
    component: {
      assetConfiguration: {
        id: 'cvv',
        testId: 'quick-action-get-cvv-asset',
      },
      testing: {
        buttonId: 'quick-action-get-cvv-button',
        labelId: 'quick-action-get-cvv-label',
      },
    },
    label: GET_CVV,
    tracking: 'checkCvv',
    url: generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CVV])
    ),
  },
  GET_PIN_OPTION = {
    id: QuickActionsSectionIdsEnumeration.GET_PIN,
    component: {
      assetConfiguration: {
        id: 'see',
        testId: 'quick-action-get-pin-asset',
      },
      testing: {
        buttonId: 'quick-action-get-pin-button',
        labelId: 'quick-action-get-pin-label',
      },
    },
    label: GET_PIN,
    tracking: 'checkPin',
    url: generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.PIN])
    ),
  },
  DASHBOARD_QUICK_ACTIONS = [
    {
      id: QuickActionsSectionIdsEnumeration.STATEMENTS,
      component: {
        assetConfiguration: {
          id: 'download_simple',
          testId: 'quick-action-statements-asset',
        },
        testing: {
          buttonId: 'quick-action-statements-button',
          labelId: 'quick-action-statements-label',
        },
      },
      label: STATEMENTS,
      tracking: 'lastStatements',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_STATEMENTS])
      ),
    },
    GET_CVV_OPTION,
    GET_PIN_OPTION,
    ADDITIONAL_CREDIT_LIMIT,
  ],
  CARD_SETTINGS_QUICK_ACTIONS = [
    GET_PIN_OPTION,
    {
      id: QuickActionsSectionIdsEnumeration.MODIFY_PIN,
      component: {
        assetConfiguration: {
          id: 'password',
          testId: 'quick-action-change-pin-asset',
        },
        testing: {
          buttonId: 'quick-action-change-pin-button',
          labelId: 'quick-action-change-pin-label',
        },
      },
      label: MODIFY_PIN,
      tracking: 'changePin',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CHANGE_PIN])
      ),
    },
    GET_CVV_OPTION,
    {
      id: QuickActionsSectionIdsEnumeration.CARD_CONTROL,
      component: {
        assetConfiguration: {
          id: 'card_control',
          testId: 'quick-action-card-control-asset',
        },
        testing: {
          buttonId: 'quick-action-card-control-button',
          labelId: 'quick-action-card-control-label',
        },
      },
      label: CARD_CONTROL,
      tracking: 'cardControl',
      url: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_CONTROL])
      ),
    },
  ],
  FINANCING_QUICK_ACTIONS = [ADDITIONAL_CREDIT_LIMIT, ...FINANCING_COMMON_QUICK_ACTIONS];

export { CARD_SETTINGS_QUICK_ACTIONS, DASHBOARD_QUICK_ACTIONS, FINANCING_QUICK_ACTIONS };
