// Enumerations
import { SecurityCheckModalStepEnumerations } from '../hooks/enumerations/security-check-modal-step.enumerations';
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Types
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';
import { NextModalStepHandlerType } from './types/next-modal-step-handler.type';
import { OnCloseModalHandlerType } from './types/on-close-modal-handler.type';
import { SecurityCheckModalHandlersType } from './types/security-check-modal.handlers.type';
import { SecurityCheckModalReturnHandlersType } from './types/security-check-modal-return.handlers.type';

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType): void => {
  if (!challenge) setError(TransactionsFetchErrorEnumeration.CHALLENGE);
};

const challengeSuccessHandler = ({
  challenge,
  setChallenge,
}: ChallengeSuccessHandlerType): void => {
  setChallenge(challenge);
};

const retryChallengeErrorHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType): void => setChallengeError(prev => !prev);

const nextModalStepHandler = ({ setModalStep }: NextModalStepHandlerType): void => {
  setModalStep(SecurityCheckModalStepEnumerations.SECOND);
};

const onCloseModalHandler = ({
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setOtpValue,
  setShowDownloadSecurityCheckModal,
}: OnCloseModalHandlerType): void => {
  setChallenged(false);
  setChallenge(null);
  setChallengeError(false);
  setDownloadDocumentType('');
  setOtpValue('');
  setShowDownloadSecurityCheckModal(false);
};

const SecurityCheckModalHandlers = ({
  challenge,
  setChallenge,
  setChallenged,
  setChallengeError,
  setError,
  setDownloadDocumentType,
  setModalStep,
  setOtpValue,
  setShowDownloadSecurityCheckModal,
}: SecurityCheckModalHandlersType): SecurityCheckModalReturnHandlersType => ({
  handleChallengeError: () => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleNextModalStep: () => nextModalStepHandler({ setModalStep }),
  handleOnCloseModal: () =>
    onCloseModalHandler({
      setChallenge,
      setChallenged,
      setChallengeError,
      setDownloadDocumentType,
      setOtpValue,
      setShowDownloadSecurityCheckModal,
    }),
  handleRetryChallengeError: () => retryChallengeErrorHandler({ setChallengeError }),
});

export default SecurityCheckModalHandlers;
