// Types
import { GetRadioButtonConfigurationType } from './types/get-radio-button-configuration.type';
import { GetRadioButtonConfigurationReturnType } from './types/get-radio-button-configuration-return.type';
// Utils
import {
  getHelperMessage,
  isSelected,
} from './utils/request-portability-step2.component.configurations.utils';

export const getRadioButtonConfiguration = ({
  email,
  emailCheckboxValue,
  formatMessage,
  handleEmailOptionChange,
  testId,
  value,
  title,
}: GetRadioButtonConfigurationType): GetRadioButtonConfigurationReturnType => ({
  onChange: () => handleEmailOptionChange({ value }),
  testing: {
    dot: testId,
  },
  value: isSelected({ emailCheckboxValue, value }),
  title: formatMessage({ id: title }),
  helperMessage: getHelperMessage({ value, email, formatMessage }),
});
