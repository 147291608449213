// Vendors
import styled from 'styled-components';
// Types
import { FilterModalInputRangeDatePickerComponentType } from './types/filter-modal-input-range-date-picker.component.type';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const FilterModalInputRangeDatePickerComponentStyled = styled.div.attrs(
  ({ testId }: Pick<FilterModalInputRangeDatePickerComponentType, 'testId'>) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

const FilterModalInputRangeDatePickerContentStyled = styled.div`
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
  }
`;

export {
  FilterModalInputRangeDatePickerComponentStyled,
  FilterModalInputRangeDatePickerContentStyled,
};
