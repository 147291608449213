// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportDifferentAmountAtmContextConsumerHook } from 'containers/transactions/views/report/components/different-amount-atm/context/transactions-report-different-amount-atm.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { TransactionsReportDifferentAmountAtmConfirmationHookReturnType } from './types/transactions-report-different-amount-atm-confirmation.hook.type';

export const useTransactionsReportDifferentAmountAtmConfirmationHook =
  (): TransactionsReportDifferentAmountAtmConfirmationHookReturnType => {
    const { formatMessage } = useCbIntl();
    const { formValues } = useTransactionsReportDifferentAmountAtmContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    return {
      formatMessage,
      formValues,
      transactionId,
      cardId,
    };
  };
