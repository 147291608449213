// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const NotificactionsSettingsViewTitleStyled = styled.section.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `${theme.spaces.spacingXl} ${theme.spaces.spacingXxxxxl} 0`};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingS} 0`};
  }
`;

export { NotificactionsSettingsViewTitleStyled };