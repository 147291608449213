// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_STEP_PROPS } from './constants/financing-payment-method-second-step-actions.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import FinancingPaymentMethodSecondStepActionsHook from './hooks/financing-payment-method-second-step-actions.hook';
// Translations
import { STEPS } from 'containers/financing/views/payment-method/translations/financing-payment-method.translations';

const FinancingPaymentMethodSecondStepActionsComponent = (): React.ReactElement => {
  const { challenge, handleNextButtonClick } = FinancingPaymentMethodSecondStepActionsHook();

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={WIZARD_STEP_PROPS.hasDefaultLeftButton}
      rightButtonConfiguration={{
        onClick: handleNextButtonClick,
        disabled: !challenge,
        children: <FormattedMessageComponent id={STEPS.SECOND.NEXT} />,
      }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};

export default FinancingPaymentMethodSecondStepActionsComponent;
