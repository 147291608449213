export enum SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration {
  INPUT_REPEATS_NUMBER = 'INPUT_REPEATS_NUMBER',
  INPUT_IS_A_SEQUENCE = 'INPUT_IS_A_SEQUENCE',
  INPUT_EQUALS_OLD_KEY = 'INPUT_EQUALS_OLD_KEY',
  INPUT_CONTAINS_CUSTOMER_DATE = 'INPUT_CONTAINS_CUSTOMER_DATE',
}
export enum SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration {
  DONT_MATCH = 'DONT_MATCH',
  INFORMATION_ABOVE = 'INFORMATION_ABOVE',
}
