// Types
import { ChangeFeeHandlerType } from './types/change-fee-handler.type';
import { FinancingPaymentMethodFirstStepRevolvingFeeSliderReturnHandlersType } from './types/financing-payment-method-first-step-revolving-fee-slider-return.handlers.type';
import { FinancingPaymentMethodFirstStepRevolvingFeeSliderHandlersType } from './types/financing-payment-method-first-step-revolving-fee-slider.handlers.type';
import { RenderSliderHandlerType } from './types/render-slider-handler.type';

const changeFeeHandler = ({ value, setFee }: ChangeFeeHandlerType) => {
  setFee(Number(value));
};

const sliderRenderHandler = ({ revolvingOptions, setError }: RenderSliderHandlerType) => {
  if (!revolvingOptions) setError(true);
};

const FinancingPaymentMethodFirstStepRevolvingFeeSliderHandlers = ({
  setFee,
  revolvingOptions,
  setError,
}: FinancingPaymentMethodFirstStepRevolvingFeeSliderHandlersType): FinancingPaymentMethodFirstStepRevolvingFeeSliderReturnHandlersType => ({
  handleChangeFee: value => changeFeeHandler({ value, setFee }),
  handleSliderRender: () => sliderRenderHandler({ revolvingOptions, setError }),
});

export default FinancingPaymentMethodFirstStepRevolvingFeeSliderHandlers;
