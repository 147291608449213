// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
// Enums
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Handlers
import LogoutHandlers from 'handlers/logout.handlers';
// Types
import { SecurityKeysAccessKeyChangeHook } from './types/security-keys-access-key-change.hook.type';
// Utils
import { isAccessKeyCookieExpired } from 'entrypoint/components/authorization/utils/entrypoint-authorization.utils';

const SecurityKeysAccessKeyChangeHook = (): SecurityKeysAccessKeyChangeHook => {
  const accessKeyExpired = cookies.getCookie(StorageCookiesEnum.ACCESS_KEY_EXPIRED);

  return {
    ...LogoutHandlers(),
    accessKeyExpired: isAccessKeyCookieExpired(accessKeyExpired),
  };
};

export { SecurityKeysAccessKeyChangeHook };
