// Vendors
import styled from 'styled-components';

const NotificationsSettingsModalDescriptionComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    text-align: left;
  }
`;

export { NotificationsSettingsModalDescriptionComponentStyled };
