import { buttonConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_MODIFY_PROPS = {
    assetConfiguration: {
      id: 'edit',
      testId: 'card-settings-check-3d-confirmation-buttons-modify-asset',
    },
    rwdFull: false,
    size: buttonConstants.SIZES.BIG,
    type: buttonConstants.TYPES.TERTIARY,
    testId: 'card-settings-check-3d-confirmation-buttons-modify',
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_SHOW_HIDE_PROPS = {
    testId: 'card-settings-check-3d-confirmation-buttons-show-hide',
  };

export {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_SHOW_HIDE_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_MODIFY_PROPS,
};
