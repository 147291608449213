// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const AvatarComponentStyled = styled.img.attrs(() => ({
  'data-testid': 'avatar',
}))<{ height?: number; width?: number }>`
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusF};
  height: ${({ height = 100 }) => convertPxsToRems({ pixels: height, base: 14 })};
  position: relative;
  width: ${({ width = 100 }) => convertPxsToRems({ pixels: width, base: 14 })};
`;

export { AvatarComponentStyled };
