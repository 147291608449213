// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GENERATE_OTP_SERVICE_CONFIGURATION } from './constants/change-iban.services.constants';
// Types
import { SepaMandateType } from 'types/sepa-mandate.type';

const getMandateInfoService = async (): Promise<[SepaMandateType, Response]> => {
  return apiUtilsCb.get({
    ...GENERATE_OTP_SERVICE_CONFIGURATION,
    params: {},
  });
};

export { getMandateInfoService };
