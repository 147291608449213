// Vendors
import { useEffect } from 'react';
// Handlers
import ChangeIbanHandlers from '../handlers/change-iban.handlers';
// Hooks
import { ChangeIbanContextConsumerHook } from '../contexts/change-iban.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import ChangeIbanTrackingHandlers from '../handlers/change-iban.tracking.handlers';
// Types
import { ChageIbanHookType } from './types/change-iban.hook.type';

const useChangeIbanHook = (): ChageIbanHookType => {
  const { fetching, setFetching, setSepaMandate } = ChangeIbanContextConsumerHook();
  const { handleGetMandateInfoEvent } = ChangeIbanHandlers({
    ...AppTrackingHook(ChangeIbanTrackingHandlers),
    setFetching,
    setSepaMandate,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleGetMandateInfoEvent();
  }, []);

  return {
    fetching,
  };
};

export { useChangeIbanHook };
