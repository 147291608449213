// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
// Types
import { FinancingInsurancesLandingNoInsurancesHandlersType } from './types/financing-insurances-landing-no-insurances.handlers.type';
import { FinancingInsurancesLandingNoInsurancesReturnHandlersType } from './types/financing-insurances-landing-no-insurances-return.handlers.type';

const goToFinancingButtonClickHandler = ({
  navigate,
  handleGoToFinancingButtonClickTracking,
}: FinancingInsurancesLandingNoInsurancesHandlersType): void => {
  handleGoToFinancingButtonClickTracking();
  navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING));
};

export const FinancingInsurancesLandingNoInsurancesHandlers = (
  props: FinancingInsurancesLandingNoInsurancesHandlersType
): FinancingInsurancesLandingNoInsurancesReturnHandlersType => ({
  handleGoToFinancingButtonClick: () => goToFinancingButtonClickHandler(props),
});
