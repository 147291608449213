// Vendors
import React from 'react';
// Components
import ProfilePersonalNameEditionComponent from 'containers/personal/views/landing/components/name/components/edition/profile-personal-name-edition.component';
// Constants
import constants from './constants/profile-personal-name-title-edit.constants';
// Hooks
import ProfilePersonalNameTitleEditHook from './hooks/profile-personal-name-title-edit.hook';
// Utilities
import { getDefaultOrTranslatedTitle } from '../../utils/profile-personal-name-title.utils';

const ProfilePersonalNameTitleEditComponent = (): React.ReactElement => {
  const {
    handleChangeCurrentTitleAssetClick: onClick,
    formatMessage,
    title,
  } = ProfilePersonalNameTitleEditHook();

  return (
    <ProfilePersonalNameEditionComponent
      {...{ ...constants.PROFILE_PERSONAL_NAME_TITLE_EDIT_PROPS, onClick }}
      value={getDefaultOrTranslatedTitle({ formatMessage, title })}
    />
  );
};

export default ProfilePersonalNameTitleEditComponent;
