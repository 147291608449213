// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import {
  LineSeparatorComponent,
  ParagraphComponent,
  ProductDetailComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import FinancingLandingRowDetailsAmortizationTableComponent from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/financing-landing-row-details-amortization-table.component';
// Constants
import {
  PENDING_AMOUNT_VALUE_PARAGRAPH_PROPS,
  PRODUCT_DETAIL_PROPS,
  PRODUCT_DETAIL_TOOLTIP_PROPS,
  TITLE_PROPS,
} from './constants/financing-early-repayment-first-step-instalment-status.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Styles
import {
  FinancingEarlyRepaymentFirstStepInstalmentStatusContentFieldsStyled,
  FinancingEarlyRepaymentFirstStepInstalmentStatusContentStyled,
} from './financing-early-repayment-first-step-instalment-status.component.styled';
// Translations
import {
  TITLE,
  PENDING_AMOUNT,
} from './translations/financing-early-repayment-first-step-instalment-status.translations';
// Types
import { FinancingEarlyRepaymentFirstStepInstalmentStatusComponentType } from './types/financing-early-repayment-first-step-instalment-status.component.type';

const FinancingEarlyRepaymentFirstStepInstalmentStatusComponent = ({
  currentAmount,
  installments,
}: FinancingEarlyRepaymentFirstStepInstalmentStatusComponentType): React.ReactElement => (
  <>
    <ParagraphComponent {...TITLE_PROPS}>
      <FormattedMessageComponent id={TITLE} />
    </ParagraphComponent>
    <FinancingEarlyRepaymentFirstStepInstalmentStatusContentStyled>
      <FinancingEarlyRepaymentFirstStepInstalmentStatusContentFieldsStyled>
        <ProductDetailComponent
          {...PRODUCT_DETAIL_PROPS}
          title={<FormattedMessageComponent id={PENDING_AMOUNT.label} />}
          tooltipConfiguration={{
            ...PRODUCT_DETAIL_TOOLTIP_PROPS,
            content: <FormattedMessageComponent id={PENDING_AMOUNT.tooltip} />,
          }}
        />
        <ParagraphComponent {...PENDING_AMOUNT_VALUE_PARAGRAPH_PROPS}>
          <FormattedNumber {...EURO_FORMAT_OPTIONS} value={currentAmount} />
        </ParagraphComponent>
      </FinancingEarlyRepaymentFirstStepInstalmentStatusContentFieldsStyled>
      <LineSeparatorComponent />
      <FinancingLandingRowDetailsAmortizationTableComponent {...{ installments }} />
    </FinancingEarlyRepaymentFirstStepInstalmentStatusContentStyled>
  </>
);

export default FinancingEarlyRepaymentFirstStepInstalmentStatusComponent;
