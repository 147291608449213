// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Constants
import { STEPS } from './constants/transactions-report-service-cancelled.constants';
// Contexts
import { TransactionsReportServiceCancelledContextConsumerHOC } from './contexts/transactions-report-service-cancelled.context';

const TransactionsReportServiceCancelledBaseComponent = (): React.ReactElement => (
  <TransactionsReportWizardComponent steps={STEPS} />
);

const TransactionsReportServiceCancelledComponent =
  TransactionsReportServiceCancelledContextConsumerHOC(
    TransactionsReportServiceCancelledBaseComponent
  );

export { TransactionsReportServiceCancelledComponent };
