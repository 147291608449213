// Enums
import { MonthsEnumeration } from 'enumerations/months.enumeration';

const DOMAIN = 'cobranded.services.months.';

export default {
  [MonthsEnumeration.JANUARY]: `${DOMAIN}january`,
  [MonthsEnumeration.FEBRUARY]: `${DOMAIN}february`,
  [MonthsEnumeration.MARCH]: `${DOMAIN}march`,
  [MonthsEnumeration.APRIL]: `${DOMAIN}april`,
  [MonthsEnumeration.MAY]: `${DOMAIN}may`,
  [MonthsEnumeration.JUNE]: `${DOMAIN}june`,
  [MonthsEnumeration.JULY]: `${DOMAIN}july`,
  [MonthsEnumeration.AUGUST]: `${DOMAIN}august`,
  [MonthsEnumeration.SEPTEMBER]: `${DOMAIN}september`,
  [MonthsEnumeration.OCTOBER]: `${DOMAIN}october`,
  [MonthsEnumeration.NOVEMBER]: `${DOMAIN}november`,
  [MonthsEnumeration.DECEMBER]: `${DOMAIN}december`,
};
