// Translations
import {
  ITEM_TITLE_01,
  ITEM_01_SUBITEM_01,
  ITEM_01_SUBITEM_02,
  ITEM_TITLE_02,
  ITEM_02_SUBITEM_01,
  ITEM_02_SUBITEM_02,
  ITEM_TITLE_03,
  ITEM_03_SUBITEM_01,
} from './translations/financing-ppi-contracting-step2-expandable-uncovered.constants.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_PROPS = {
    ordered: true,
    testId: 'financing-ppi-contracting-step2-expandable-uncovered',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_ITEMS = {
    items: [
      {
        label: ITEM_TITLE_01,
        subItems: [ITEM_01_SUBITEM_01, ITEM_01_SUBITEM_02],
      },
      {
        label: ITEM_TITLE_02,
        subItems: [ITEM_02_SUBITEM_01, ITEM_02_SUBITEM_02],
      },
      {
        label: ITEM_TITLE_03,
        subItems: [ITEM_03_SUBITEM_01],
      },
    ],
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_ITEMS,
};
