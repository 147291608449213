// Vendors
import React from 'react';
// Components
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import FinancingFastCashSidebarComponent from './components/sidebar/financing-fast-cash-sidebar.component';
import FinancingOperativeLayoutComponent from '../../components/financing-operative-layout/financing-operative-layout.component';
import LimitModalComponent from './components/limit-modal/limit-modal.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import { getErrorLinkConfiguration } from 'configurations/app.configurations';
// Constants
import { FINANCING_FAST_CASH_WIZARD_PROPS } from './constants/financing-fast-cash.constants';
// Contexts
import { FinancingFastCashContextConsumerHOC } from './contexts/financing-fast-cash.context';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import FinancingFastCashHook from './hooks/financing-fast-cash.hook';

const FinancingFastCashBaseView = (): React.ReactElement => {
  const { error, navigate, formatMessage, showLimitModal, handleCloseLimitModal, loading } =
    FinancingFastCashHook();

  return (
    <ErrorWrapperComponent
      {...{
        fetching: loading,
        error,
        linkConfiguration: getErrorLinkConfiguration({
          formatMessage,
          route: RoutesEnumeration.FINANCING,
          navigate,
        }),
      }}
    >
      <FinancingOperativeLayoutComponent sidebar={<FinancingFastCashSidebarComponent />}>
        <WizardComponent {...FINANCING_FAST_CASH_WIZARD_PROPS} />
      </FinancingOperativeLayoutComponent>
      <LimitModalComponent isVisible={showLimitModal} onCloseModal={handleCloseLimitModal} />
    </ErrorWrapperComponent>
  );
};

const FinancingFastCashView = FinancingFastCashContextConsumerHOC(FinancingFastCashBaseView);

export { FinancingFastCashView };
