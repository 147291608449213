// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { ProfilePersonalNameAliasContextConsumerHook } from 'containers/personal/views/landing/components/name/components/alias/contexts/profile-personal-name-alias.context';
// Event handlers
import ProfilePersonalNameAliasEmptyHandlers from '../handlers/profile-personal-name-alias-empty.handlers';
import ProfilePersonalNameAliasEmptyTrackingHandlers from '../handlers/profile-personal-name-alias-empty.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameAliasEmptyBuilderReturnHandlersType } from '../handlers/types/profile-personal-name-alias-empty-builder-return.handlers.type';

const ProfilePersonalNameAliasEmptyHook =
  (): ProfilePersonalNameAliasEmptyBuilderReturnHandlersType => {
    const { setEditMode } = ProfilePersonalNameAliasContextConsumerHook();

    return ProfilePersonalNameAliasEmptyHandlers({
      ...AppTrackingHook(ProfilePersonalNameAliasEmptyTrackingHandlers),
      setEditMode,
    });
  };

export default ProfilePersonalNameAliasEmptyHook;
