// Vendors
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { TransactionsLandingRowDetailsCreatePlanHandlers } from '../handlers/transactions-landing-row-details-create-plan.handlers';
import { TransactionsLandingRowDetailsViewInstallmentTrackingHandlers } from '../handlers/transactions-landing-row-details-create-plan.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsLandingRowDetailsCreatePlanHookType } from './types/transactions-landing-row-details-create-plan.hook.type';
import { TransactionsLandingRowDetailsCreatePlanComponentType } from '../types/transactions-landing-row-details-create-plan.component.type';

const useTransactionsLandingRowDetailsCreatePlanHook = (
  props: Omit<TransactionsLandingRowDetailsCreatePlanComponentType, 'eppStatusCode'>
): TransactionsLandingRowDetailsCreatePlanHookType => {
  const [showPointsModal, setShowPointsModal] = useState<boolean>(false);

  const { currentCard } = AppContextConsumerHook(),
    { status } = currentCard || {};

  return {
    ...TransactionsLandingRowDetailsCreatePlanHandlers({
      ...AppTrackingHook(TransactionsLandingRowDetailsViewInstallmentTrackingHandlers),
      ...props,
      navigate: useNavigate(),
      setShowPointsModal,
      status,
    }),
    showPointsModal,
  };
};

export { useTransactionsLandingRowDetailsCreatePlanHook };
