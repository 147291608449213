// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import { cancelContractCheckpointService } from '../services/support-cancel-contract-second-step-validation.services';
// Types
import { CancelContractCheckpointResolverPropsType } from './types/support-cancel-contract-second-step-checkpoint-props-resolvers.type';
import { ChallengeType } from 'types/challenge.type';

const cancelContractCheckpointResolver = async ({
  reason,
  progressId,
  cardContractId,
}: CancelContractCheckpointResolverPropsType): Promise<[ChallengeType | null, boolean]> => {
  const [response, { status }] = await cancelContractCheckpointService({
    cancellationReason: reason,
    cardContractId,
    progressId,
  });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export { cancelContractCheckpointResolver };
