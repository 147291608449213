// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { GraphicBarsComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import DashboardSpentSummaryComponent from '.././summary/dashboard-spent-summary.component';
// Constants
import constants from './constants/dashboard-spent-top.constants';
// Styles
import {
  DashboardSpentTopGraphicBarsWrapperStyled,
  DashboardSpentTopLabelComponentStyled,
  DashboardSpentTopSummariesContainerStyled,
  DashboardSpentTopSummariesContainerTopWrapperStyled,
  DashboardSpentTopUpperComponentStyled,
} from './dashboard-spent-top.component.styled';
// Translations
import translations from '../../translations/dashboard-spent.translations';
// Types
import { DashboardSpentTopComponentType } from './types/dashboard-spent-top.component.type';

const DashboardSpentTopComponent = ({
  currentPercentage,
  percentageDifference,
  previousPercentage,
  testId = constants.DASHBOARD_SPENT_TOP_DEFAULT_TEST_ID,
  total,
}: DashboardSpentTopComponentType): React.ReactElement => (
  <>
    <DashboardSpentTopLabelComponentStyled {...{ testId }}>
      <ParagraphComponent {...constants.DASHBOARD_SPENT_TOP_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.TITLE} />
      </ParagraphComponent>
    </DashboardSpentTopLabelComponentStyled>
    <DashboardSpentTopSummariesContainerStyled>
      <DashboardSpentTopSummariesContainerTopWrapperStyled>
        <DashboardSpentTopUpperComponentStyled>
          <DashboardSpentTopGraphicBarsWrapperStyled>
            {Boolean(currentPercentage && previousPercentage) && (
              <GraphicBarsComponent
                currentPercentage={currentPercentage}
                lastPercentage={previousPercentage}
              />
            )}
          </DashboardSpentTopGraphicBarsWrapperStyled>
          <DashboardSpentSummaryComponent {...{ total, percentageDifference }} />
        </DashboardSpentTopUpperComponentStyled>
      </DashboardSpentTopSummariesContainerTopWrapperStyled>
    </DashboardSpentTopSummariesContainerStyled>
  </>
);

export { DashboardSpentTopComponent };
