// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ToggleComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/settings-card-control-toggle.constants';
// Hooks
import SettingsCardControlToggleHook from './hooks/settings-card-control-toggle.hook';
// Translations
import translations from './translations/settings-card-control-toggle.translations';
// Types
import { SettingsCardControlToggleComponentType } from './types/settings-card-control-toggle.component.type';

const SettingsCardControlToggleComponent = ({
  checked,
  id,
  toggleId,
  value,
  ...rest
}: SettingsCardControlToggleComponentType): React.ReactElement<SettingsCardControlToggleComponentType> => {
  const { fetching, handleChangeCardControlItemClick } = SettingsCardControlToggleHook({
    id,
    value,
    ...rest,
  });

  return (
    <ToggleComponent
      defaultChecked={checked}
      disabled={fetching}
      labelOff={<FormattedMessageComponent id={translations.OFF} />}
      labelOn={<FormattedMessageComponent id={translations.ON} />}
      onChange={handleChangeCardControlItemClick}
      testId={constants.SETTINGS_CARD_CONTROL_TOGGLE_TEST_ID + (toggleId ?? id)}
      value={checked}
    />
  );
};

export default SettingsCardControlToggleComponent;
