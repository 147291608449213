// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/personal-number-step4-actions.services.constants';
// Types
import { PersonalNumberStep4ActionsApiBuilderType } from '../types/personal-number-step4-actions-api-builder.type';

const changeUserNumberOtpService = async ({
  phoneId,
  phoneCountryCode,
  phone,
  progressId,
  withEmail,
}: PersonalNumberStep4ActionsApiBuilderType): Promise<[Record<string, any>, Response]> => {
  const customHeaders = { deviceLocale: 'de_DE', applicationLocale: 'de_DE' };

  if (progressId) {
    customHeaders['progressId'] = progressId;
  }

  return apiUtilsCb.patch({
    ...constants.CHANGE_NUMBER_SERVICE_CONFIGURATION,
    customHeaders,
    params: { id: phoneId, countryCode: phoneCountryCode, phone, withEmail },
  });
};
export { changeUserNumberOtpService };
