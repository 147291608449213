// Vendors
import styled from 'styled-components';

export const FinancingPaymentMethodFirstStepPaymentComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const FinancingPaymentMethodFirstStepPaymentDescriptionStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;
