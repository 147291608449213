// Constants
import { SLIDER_PROPS } from './constants/financing-payment-method-first-step-revolving-fee-slider.configurations.constants';
// Translations
import {
  FIXED_FEE_LABEL,
  MAX_LABEL,
  MIN_LABEL,
  VARIABLE_FEE_LABEL,
} from './translations/financing-payment-method-first-step-revolving-fee-slider.configurations.translations';
// Types
import { GetSiderConfigurationType } from './types/get-slider-configuration.type';
import { GetSliderConfigurationReturnType } from './types/get-slider-configuration-return.type';
// Utils
import { formatSliderCurrency } from './utils/financing-payment-method-first-step-revolving-fee-slider.configurations.utils';
import { isFixAmount } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

export const getSliderConfiguration = ({
  fee,
  formatNumber,
  formatMessage,
  handleChangeFee,
  revolvingMethod,
  revolvingOptions,
}: GetSiderConfigurationType): GetSliderConfigurationReturnType => {
  const options = isFixAmount(revolvingMethod)
    ? revolvingOptions.FIXED_PAYMENT_AMOUNT
    : revolvingOptions.PERCENTAGE_ACCOUNT_BALANCE;
  const min = options.values[0];
  const max = options.values[options.values.length - 1];
  const label = isFixAmount(revolvingMethod) ? FIXED_FEE_LABEL : VARIABLE_FEE_LABEL;

  return {
    ...SLIDER_PROPS,
    key: revolvingMethod,
    defaultValue: String(fee),
    onChange: value => handleChangeFee(value),
    label: formatMessage({ id: label }),
    max,
    min,
    leftHelperText: formatMessage(
      { id: MIN_LABEL },
      { value: formatSliderCurrency({ value: min, revolvingMethod, formatNumber }) }
    ),
    rightHelperText: formatMessage(
      { id: MAX_LABEL },
      { value: formatSliderCurrency({ value: max, revolvingMethod, formatNumber }) }
    ),
    options: options.values.map(value => ({
      label: formatSliderCurrency({ value, revolvingMethod, formatNumber }),
      value: `${value}`,
    })),
  };
};
