// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';

export default {
  CONTEXT_DEFAULT_VALUE: {
    blockReason: null,
    error: false,
    selectedAddress: AddressTypesEnum.HBTL,
    setBlockReason: (): null => null,
    setError: (): null => null,
    setSelectedAddress: (): null => null,
    setShowReplacementModal: (): null => null,
    setTempAddress: (): null => null,
    showReplacementModal: false,
    tempAddress: null,
  },
};
