// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsChange3DContextType } from './types/card-settings-change-3d.context.type';

const CardSettingsChange3dContext = createContext<CardSettingsChange3DContextType>(
  {} as CardSettingsChange3DContextType
);

const CardSettingsChange3DContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsChange3DContextType>> => {
  const [new3DCode, setNew3DCode] = useState<string>('');
  const [repeated3DCode, setRepeated3DCode] = useState<string>('');
  const [isRepeated3DCodeEnabled, setIsRepeated3DCodeEnabled] = useState<boolean>(false);
  const [errorDescription, setErrorDescription] = useState<string>('');

  return (
    <CardSettingsChange3dContext.Provider
      value={{
        errorDescription,
        isRepeated3DCodeEnabled,
        new3DCode,
        repeated3DCode,
        setErrorDescription,
        setIsRepeated3DCodeEnabled,
        setNew3DCode,
        setRepeated3DCode,
      }}
    >
      {children as React.ReactNode}
    </CardSettingsChange3dContext.Provider>
  );
};

const useCardSettingsChange3DContextConsumerHook = (): CardSettingsChange3DContextType =>
  useContext(CardSettingsChange3dContext);

/* eslint-disable */
const CardSettingsChange3DContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<CardSettingsChange3DContextType>> =>
    (
      <CardSettingsChange3DContextProvider>
        <Component {...props} />
      </CardSettingsChange3DContextProvider>
    );

export { CardSettingsChange3DContextConsumerHOC, useCardSettingsChange3DContextConsumerHook };
