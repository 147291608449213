// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Handlers
import CardResumeHandlers from '../handlers/card-resume.handlers';
// Types
import { CardResumeHookType } from './types/card-resume.hook.type';

const CardResumeHook = (): CardResumeHookType => {
  const [cardImage, setCardImage] = useState<string | undefined>(undefined);

  const { currentCard } = AppContextConsumerHook(),
    {
      cmsCardId: cardNumber = CharactersEnum.NO_VALUE,
      productType = { productFamily: CharactersEnum.NO_VALUE },
      productTypeCode = CharactersEnum.NO_VALUE,
    } = currentCard || {};

  const { handleLoadCardArtEvent } = CardResumeHandlers({
    productTypeCode,
    setCardImage,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleLoadCardArtEvent();
  }, []);

  return {
    cardImage,
    cardNumber,
    cardType: productType?.productFamily,
  };
};

export default CardResumeHook;
