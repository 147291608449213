const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.cbPPI.landing.';
const DOMAIN_LPI = 'cobranded.services.userSettings.privateArea.financing.insurances.landing.lpi.';

const PAGE_TITLE = `${DOMAIN}pageTitle`,
  VIEW_TERMS_AND_CONDITIONS = `${DOMAIN}viewTermsAndConditionsLink`;

const LPI = {
  DESCRIPTION: `${DOMAIN_LPI}description`,
  ITEM_1_DESCRIPTION: `${DOMAIN_LPI}item1Description`,
  ITEM_1_TITLE: `${DOMAIN_LPI}item1Title`,
  ITEM_2_DESCRIPTION: `${DOMAIN_LPI}item2Description`,
  ITEM_2_TITLE: `${DOMAIN_LPI}item2Title`,
  TITLE: `${DOMAIN_LPI}title`,
};

const CPI = {
  DESCRIPTION: `${DOMAIN}description.v2`,
  FIRST_ITEM_TITLE: `${DOMAIN}feature01Title`,
  FIRST_ITEM_DESCRIPTION: `${DOMAIN}feature01Description`,
  SECOND_ITEM_TITLE: `${DOMAIN}feature02Title`,
  SECOND_ITEM_DESCRIPTION: `${DOMAIN}feature02Description`,
  TITLE: `${DOMAIN}title`,
};

export { CPI, LPI, PAGE_TITLE, VIEW_TERMS_AND_CONDITIONS };
