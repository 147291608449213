const CONTROLLED_LOGOUT = 'CONTROLLED_LOGOUT',
  LAST_API_CALL = 'lastApiCall',
  TOKEN_REFRESHED = 'tokenRefreshed',
  PUBLIC_SITE_CHAT = 'publicSiteChat',
  IS_CUSTOMER = 'isCustomer',
  PUBLIC_DOMAIN = 'zinia.de';

export {
  CONTROLLED_LOGOUT,
  IS_CUSTOMER,
  PUBLIC_SITE_CHAT,
  LAST_API_CALL,
  TOKEN_REFRESHED,
  PUBLIC_DOMAIN,
};
