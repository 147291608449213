// Vendors
import { useEffect } from 'react';
// Contexts
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Handlers
import { SelectMethodHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-3-select-method.handler';
// Hooks
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Trackings
import Step3CreditLimitChageTracking from '../trackings/step-3-credit-limit-increase.tracking';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { Step3CreditIncreaseLimitHookType } from './types/step-3-credit-limit-increase.component.hook.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const Step3CreditLimitIncreaseHook = (): Step3CreditIncreaseLimitHookType => {
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step3 } = creditLimitContext;
  const { handleEnterViewTracking } = AppTrackingHook(Step3CreditLimitChageTracking);
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleSelectAccountAggregation, handleSelectUploadDocumentation } = SelectMethodHandlers({
    creditLimitContext,
    setCurrentStep,
  });

  const { handleOpenExternalUrlOnHybridFlow } = MobileConnectorContextConsumerHook();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleEnterViewTracking, []);

  return {
    isLoadingNextRequest: step3.isLoadingNextRequest,
    isLoadingUploadDocumentationRequest: step3.isLoadingUploadDocumentationRequest,
    handleSelectAccountAggregation,
    handleSelectUploadDocumentation,
    handleOpenExternalUrlOnHybridFlow,
  };
};

export default Step3CreditLimitIncreaseHook;
