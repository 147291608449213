// Vendors
import React from 'react';
// Components
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Constants
import {
  CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_ERROR_PROPS,
  CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_SUCCESS_PROPS,
} from './constants/card-settings-change-pin-feedback.constants';
// Hooks
import useCardSettingsChangePinFeedbackHook from './hooks/card-settings-change-pin-feedback.hook';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const CardSettingsChangePinFeedbackComponent = ({
  children,
}: ReactChildrenType): React.ReactElement => {
  const { handleRetryChangeCardPinButtonClick } = useCardSettingsChangePinFeedbackHook();

  return (
    <WizardFeedbackComponent
      errorConfiguration={{
        ...CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_ERROR_PROPS,
        buttonClick: handleRetryChangeCardPinButtonClick,
      }}
      successConfiguration={CARD_SETTINGS_CHANGE_PIN_WIZARD_FEEDBACK_SUCCESS_PROPS}
    >
      {children}
    </WizardFeedbackComponent>
  );
};

export default CardSettingsChangePinFeedbackComponent;
