export enum MotiveDalEnum {
  CHARGE_DUPLICATED = 'CHARGE_DUPLICATED',
  DEFECTIVE_PRODUCT_SERVICE = 'DEFECTIVE_PRODUCT_SERVICE',
  NOT_RECEIVE_PRODUCT_SERVICE_PURCHASED = 'NOT_RECEIVE_PRODUCT_SERVICE_PURCHASED',
  CHARGED_FOR_SERVICE_ALREADY_CANCELED = 'CHARGED_FOR_SERVICE_ALREADY_CANCELED',
  SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED',
  PAYMENT_MADE_FOR_OTHER_REASONS = 'PAYMENT_MADE_FOR_OTHER_REASONS',
  RECEIVED_DIFF_AMOUNT_THAN_REQUESTED = 'RECEIVED_DIFF_AMOUNT_THAN_REQUESTED',
  NOT_ACK_CHARGE_AND_NOT_AUTH = 'NOT_ACK_CHARGE_AND_NOT_AUTH',
}
