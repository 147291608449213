// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameAliasEmptyBuilderReturnTrackingHandlersType } from './types/profile-personal-name-alias-empty-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const handleCreateNewAliasAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'createNewAlias',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameAliasEmptyTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameAliasEmptyBuilderReturnTrackingHandlersType => ({
  handleCreateNewAliasAssetClickTracking: () =>
    track(handleCreateNewAliasAssetClickTrackingHandler()),
});

export default ProfilePersonalNameAliasEmptyTrackingHandlers;
