// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';
import { SettingCardControlLocationsWarningHookType } from 'containers/card-settings/views/card-control/components/locations/components/warning/hooks/types/setting-card-control-locations-warning.hook.type';
import { SettingCardControlLocationsUtilsType } from 'containers/card-settings/views/card-control/components/locations/utils/types/setting-card-control-locations.utils.type';
import { FormatLocationTranslationIdType } from './types/format-location-translation-id.type';

const someCountryInSomeContinentIsConfigured = ({
  continents,
}: Pick<SettingCardControlLocationsWarningHookType, 'continents'>): boolean =>
  continents?.some(({ countries }: CardControlContinentComponentType): boolean =>
    countries?.some(({ enable }: CardControlContinentCountryComponentType) => enable)
  );

const someCountryInContinentIsConfigured = (countries: any): boolean =>
  Boolean(countries?.some(({ enable }: CardControlContinentCountryComponentType) => enable));

const someCountryIsConfigured = ({
  mainCountry,
  continents,
}: Pick<SettingCardControlLocationsWarningHookType, 'continents' | 'mainCountry'>): boolean =>
  Boolean(
    mainCountry?.enableMainCountry === true ||
      someCountryInSomeContinentIsConfigured({ continents })
  );

const countryIsEnabled = ({ id, continents }: SettingCardControlLocationsUtilsType): boolean =>
  Boolean(
    continents?.some(({ countries }: CardControlContinentComponentType): boolean =>
      countries?.some(
        ({ enable, id: countryId }: CardControlContinentCountryComponentType) =>
          countryId === id && enable
      )
    )
  );

const locationsTabIsBlocked = ({
  continents,
  isAtmCashAllowed,
  isInPersonPurchaseAllowed,
  mainCountry,
}: Omit<
  SettingCardControlLocationsWarningHookType,
  'handleNavigateCardControlOperationsLinkClick'
>): boolean =>
  Boolean(
    !isAtmCashAllowed &&
      !isInPersonPurchaseAllowed &&
      someCountryIsConfigured({ continents, mainCountry })
  );

const formatLocationTranslationId = ({
  preffix,
  location,
}: FormatLocationTranslationIdType): string => `${preffix}${location}`;

export {
  countryIsEnabled,
  formatLocationTranslationId,
  locationsTabIsBlocked,
  someCountryInContinentIsConfigured,
};
