// Vendors
import React from 'react';
// Components
import { SelectorBoxComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getSelectorBoxConfiguration } from './configurations/financing-payment-method-first-step-revolving-options.component.configurations';
// Constants
import { OPTIONS } from './constants/financing-payment-method-first-step-revolving-options.constants';
// Hooks
import FinancingPaymentMethodFirstStepRevolvingOptionsHook from './hooks/financing-payment-method-first-step-revolving-options.hook';
// Styles
import { FinancingPaymentMethodFirstStepRevolvingOptionsComponentStyled } from './financing-payment-method-first-step-revolving-options.component.styled';

const FinancingPaymentMethodFirstStepRevolvingOptionsComponent = (): React.ReactElement => {
  const { formatMessage, handleChangeRevolvingMethod, revolvingMethod } =
    FinancingPaymentMethodFirstStepRevolvingOptionsHook();

  return (
    <FinancingPaymentMethodFirstStepRevolvingOptionsComponentStyled>
      {OPTIONS.map(({ description, ...props }, i) => (
        <SelectorBoxComponent
          key={i}
          {...getSelectorBoxConfiguration({
            ...props,
            formatMessage,
            handleOnClick: handleChangeRevolvingMethod,
            selected: revolvingMethod,
          })}
        >
          <ParagraphComponent>
            <FormattedMessageComponent id={description} />
          </ParagraphComponent>
        </SelectorBoxComponent>
      ))}
    </FinancingPaymentMethodFirstStepRevolvingOptionsComponentStyled>
  );
};

export default FinancingPaymentMethodFirstStepRevolvingOptionsComponent;
