// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/block-card.context.constants';
// Types
import { AddressType } from 'types/address.type';
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
import { BlockCardContextType } from './types/block-card.context.type';
import { BlockReasonsEnumeration } from 'enumerations/block-reasons.enumeration';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const BlockCardContext = createContext<BlockCardContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const BlockCardContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<BlockCardContextType>> => {
  const [blockReason, setBlockReason] = useState<BlockReasonsEnumeration | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState<AddressTypesEnum>(AddressTypesEnum.HBTL);
  const [showReplacementModal, setShowReplacementModal] = useState<boolean>(false);
  const [tempAddress, setTempAddress] = useState<AddressType | null>(null);
  return (
    <BlockCardContext.Provider
      value={{
        blockReason,
        error,
        selectedAddress,
        setBlockReason,
        setError,
        setSelectedAddress,
        setShowReplacementModal,
        setTempAddress,
        showReplacementModal,
        tempAddress,
      }}
    >
      {children as React.ReactNode}
    </BlockCardContext.Provider>
  );
};

export const BlockCardContextConsumerHook = (): BlockCardContextType =>
  useContext(BlockCardContext);

/* eslint-disable */
export const BlockCardContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<BlockCardContextType>> =>
    (
      <BlockCardContextProvider>
        <Component {...props} />
      </BlockCardContextProvider>
    );
