const ENTER_TRANKING_PAYLOAD = {
  pageName: 'notificationsHistory',
  sectionFamily: 'banking',
  sectionCategory: 'settings',
  sectionName: 'accounts',
  interactionType: 'page',
  serviceType: 'userInformation',
};

const ENTER_ERROR_TRANKING_PAYLOAD = {
  pageName: '/notificationsHistory/error/pageCouldNotBeLoaded',
  sectionFamily: 'banking',
  sectionCategory: 'settings',
  sectionName: 'accounts',
  interactionType: 'page',
  serviceType: 'userInformation',
  processType: 'notificationsHistory',
  processStep: 'error',
  errorCategory: 'pageCouldNotBeLoaded',
  errorDescription: 'errorServiceUnavailable',
};

const OPEN_FILTERS_TRANKING_PAYLOAD = {
  eventAction: 'clickLink',
  eventLabel: 'filterLink'
};

const CLEAR_FILTERS_TRANKING_PAYLOAD = {
  eventAction: 'clickLink',
  eventLabel: 'clearFiltersLink'
};

const REMOVE_FILTERS_TRANKING_PAYLOAD = {
  eventAction: 'clickCross',
  eventLabel: 'removeDateFilter'
};

const GO_TO_NOTIFICATIONS_SETTINGS_TRANKING_PAYLOAD = {
  eventAction: 'clickLink',
  eventLabel: 'settingsLink'
};

export default {
  ENTER_TRANKING_PAYLOAD,
  ENTER_ERROR_TRANKING_PAYLOAD,
  OPEN_FILTERS_TRANKING_PAYLOAD,
  CLEAR_FILTERS_TRANKING_PAYLOAD,
  REMOVE_FILTERS_TRANKING_PAYLOAD,
  GO_TO_NOTIFICATIONS_SETTINGS_TRANKING_PAYLOAD,
};