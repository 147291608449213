// Configurations
import {
  getLinkTypeTrackingConfiguration,
  getViewTypeTrackingConfiguration,
} from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import constants from './constants/final-feedback.component.tracking.constants';
// Types
import { FinalFeedbackTrackingType } from './types/final-feedback.component.tracking.type';

export const FinalFeedbackTracking = (
  track: (param: unknown) => void
): FinalFeedbackTrackingType => ({
  handleEnterViewTracking: processStep =>
    track(getViewTypeTrackingConfiguration({ ...constants.VIEW, processStep })),
  handleGoToFinancingTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.GO_TO_FINANCING)),
});

export default FinalFeedbackTracking;
