// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { LoginContextConsumerHook } from 'containers/login/contexts/login.context';
// Event handlers
import LoginGapsTitleHandlers from '../handlers/login-gaps-title.handlers';
import LoginGapsTitleTrackingHandlers from '../handlers/login-gaps-title.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoginGapsTitleHookType } from './types/login-gaps-title.hook.type';

const LoginGapsTitleHook = (): LoginGapsTitleHookType => {
  const { email, userAlias, setLoginView, setEmail, setUserAlias } = LoginContextConsumerHook();

  return {
    ...LoginGapsTitleHandlers({
      ...AppTrackingHook(LoginGapsTitleTrackingHandlers),
      email,
      setLoginView,
      setEmail,
      setUserAlias,
    }),
    email,
    userAlias,
  };
};

export default LoginGapsTitleHook;
