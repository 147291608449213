// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { ExpiredKycModalHandlers } from '../handlers/expired-kyc-modal.handlers';
// Trackings
import AppTrackingHook from 'tracking/app.tracking.hook';
import { ExpiredKycModalTracking } from '../trackings/expired-kyc-modal.tracking';
// Types
import { KycExpiredModalReturnHookType } from './types/expired-kyc-modal.hook.type';

const useKycExpiredModalHook = (): KycExpiredModalReturnHookType => {
  const { formatMessage } = useCbIntl();
  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();
  const navigate = useNavigate();
  const {
    handleClickButtonModalTracking,
    handleClickCloseModalTracking,
    handleClickLinkModalTracking,
    handleShowExpiredKycModalTracking,
  } = AppTrackingHook(ExpiredKycModalTracking);

  useEffect(() => {
    handleShowExpiredKycModalTracking();
  }, []);

  const { handleNavigateToFincancing, handleNavigateToPersonalData, handleCloseModal } =
    ExpiredKycModalHandlers({
      handleClickButtonModalTracking,
      handleClickCloseModalTracking,
      handleClickLinkModalTracking,
      onNavigateHybridFlow,
      navigate,
    });

  return {
    formatMessage,
    handleCloseModal,
    handleNavigateToFincancing,
    handleNavigateToPersonalData,
  };
};

export { useKycExpiredModalHook };
