// Constants
import checkCvvPinConstants from '../constants/check-cvv-pin.constants';
// Resolvers
import {
  fetchCardArtsResolver,
  fetchCardCvvResolver,
  fetchCardPinResolver,
} from 'providers/cards/resolvers/cards.resolvers';
// Types
import { GetCommonHandlersProps, GetHandlersCommonProps, Handlers } from './types/handlers.type';

const { CHALLENGE, CODE, CVV, KO, PIN } = checkCvvPinConstants;

export * from './check-cvv-pin-tracking.handlers';

/**
 * Generic handler creator. Once the `fetchCode` function is passed via props,
 * this function generates all needed handlers to make Check CVV/PIN pages
 * work properly
 */
const getCommonHandlers = ({
  cardId,
  fetchCode,
  navigate,
  setArt,
  setChallengeError,
  setCode,
  setError,
  setStep,
  setSuccess,
  tracking,
  type,
}: GetCommonHandlersProps): Handlers => {
  /**
   * Executed when user clicks in `Continue` button, under
   * `SecurityVerificationComponent`
   */
  const onContinue = () => {
    tracking.securityVerification.onContinue();
    setStep(CODE);
  };

  /**
   * Executed when `SecurityVerificationComponent` is mounted
   */
  const downloadCardArt = async (productType: string): Promise<void> => {
    const [data] = await fetchCardArtsResolver(productType);

    if ((data as any).cardArts[0]) {
      setArt((data as any).cardArts[0]);
    }
  };

  /**
   * Executed when there is an error while verifying
   * the verification code, inside `ChallengeComposite` component
   *
   * At the moment, no special behavior is required
   */
  const onError = () => {
    tracking.securityVerification.onError();
    setError(true);
  };

  /**
   * Executed on `KoComponent` page
   */
  const onRestartProcess = () => {
    tracking.koMessage.onTryAgain();
    setCode('');
    setChallengeError(false);
    setError(false);
    setSuccess(false);
    setStep(CHALLENGE);
  };

  /**
   * Executed when the verification code has been verified
   * and there are no more wizard-challenge pending
   */
  const onSuccess = async ({ progressId }) => {
    const [response, error] = await fetchCode(cardId, progressId);

    if (error) {
      if (error === 403) {
        tracking.securityVerification.onSecurityError();
        setChallengeError(true);
        return;
      }

      setStep(KO);
      return;
    }

    if (response?.[type]) {
      setCode(response[type] as string);
      setSuccess(true);
    }
  };

  /**
   * Executed when getting a 403 error (meaning something in the
   * verification process has failed)
   */
  const onTryAgain = () => {
    tracking.securityVerification.onTryAgain();
    setChallengeError(false);
  };

  /**
   * When view is loaded, navigates back to previous page
   */
  const onGoBack = () => {
    tracking.securityVerification.onCancel();
    navigate(-1);
  };

  /**
   * When code is displayed, button to go back to card settings
   */
  const onGoToPreviousPath = () => {
    tracking.viewCode.onGoToCardSettings();
    navigate(-1);
  };

  return {
    handleDownloadCardArt: downloadCardArt,
    handleContinue: onContinue,
    handleError: onError,
    handleGoBack: onGoBack,
    handleGoToPreviousPath: onGoToPreviousPath,
    handleRestartProcess: onRestartProcess,
    handleSuccess: onSuccess,
    handleTryAgain: onTryAgain,
  };
};

/**
 * Depending on the screen `type`, it will return a
 * set of handlers for that page.
 */
export const getHandlers = (props: GetHandlersCommonProps): Handlers => {
  switch (props.type) {
    case CVV:
      return getCommonHandlers({
        ...props,
        fetchCode: fetchCardCvvResolver,
      });
    case PIN:
    default:
      return getCommonHandlers({
        ...props,
        fetchCode: fetchCardPinResolver,
      });
  }
};
