// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/financing-fast-cash-sidebar.constants';
// Styles
import {
  FinancingFastCashSidebarComponentStyled,
  FinancingFastCashSidebarItemComponentStyled,
} from './financing-fast-cash-sidebar.component.styled';
// Translations
import translations from './translations/financing-fast-cash-sidebar.translations';

const FinancingFastCashSidebarComponent = (): React.ReactElement => (
  <FinancingFastCashSidebarComponentStyled>
    <SidebarComponent
      {...constants.SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <FinancingFastCashSidebarItemComponentStyled>
        <ParagraphComponent {...{ ...constants.SIDEBAR_PARAGRAPH_PROPS }}>
          <FormattedMessageComponent id={translations.DESCRIPTION} />
        </ParagraphComponent>
      </FinancingFastCashSidebarItemComponentStyled>
    </SidebarComponent>
  </FinancingFastCashSidebarComponentStyled>
);

export default FinancingFastCashSidebarComponent;
