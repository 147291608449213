// Constants
import { CHALLENGE_PROPS } from './constants/security-check-modal.configurations.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
// Utils
import { setParams } from '../../../utils/security-check-modal.service.utils';

const getChallengeConfiguration = ({
  cardId,
  challengeError,
  formValuesParams,
  handleChallengeError,
  handleChallengeSuccess,
  handleRetryChallengeError,
  metadata,
  serviceConfiguration,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => {
  setParams({ metadata, serviceConfiguration });
  return {
    ...CHALLENGE_PROPS,
    baseService: serviceConfiguration.endpoint,
    baseServiceBuilder: {
      pathParams: { cardId },
      queryParams: { ...serviceConfiguration.queryParams, ...(formValuesParams ?? {}) },
    },
    challengeError,
    onRetry: handleRetryChallengeError,
    onError: handleChallengeError,
    onSuccess: handleChallengeSuccess,
  };
};

export { getChallengeConfiguration };
