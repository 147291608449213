import { buttonConstants } from '@openbank/cb-ui-commons';

const PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_RETRY_PROPS = {
  assetConfiguration: {
    id: 'upload',
    testId: 'personal-photo-name-button-upload-modal-buttons-retry-asset',
  },
  rwdFull: false,
  type: buttonConstants.TYPES.SECONDARY,
  testId: 'personal-photo-name-button-upload-modal-buttons-retry-button',
};
const PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_ROTATE_PROPS = {
  assetConfiguration: {
    id: 'rotate-image',
    position: 'left',
    testId: 'personal-photo-name-button-upload-modal-buttons-rotate-asset',
  },
  rwdFull: false,
  type: buttonConstants.TYPES.SECONDARY,
  testId: 'personal-photo-name-button-upload-modal-buttons-rotate-button',
};

export {
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_RETRY_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_ROTATE_PROPS,
};
