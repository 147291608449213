const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cardControl.cbSidebar.';

export default {
  EXPANDABLE_TITLE_1: `${DOMAIN}title01`,
  EXPANDABLE_TITLE_2: `${DOMAIN}title02`,
  EXPANDABLE_TITLE_3: `${DOMAIN}title03`,
  EXPANDABLE_DESCRIPTION_1: `${DOMAIN}description01`,
  EXPANDABLE_DESCRIPTION_2: `${DOMAIN}description02`,
  EXPANDABLE_DESCRIPTION_3: `${DOMAIN}description03`,
  TITLE: `${DOMAIN}title`,
};
