// Enumerations
import { CardControlTabsIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-tabs-ids.enumeration';
// Translations
import translations from './translations/settings-card-control-tabs.configurations.translations';
// Types
import { SettingsCardControlReturnConfigurationsType } from './types/settings-card-control-return.configurations.type';
import { SettingsCardControlConfigurationsType } from './types/settings-card-control.configurations.type';

const getCardControlMappedTabsConfiguration = ({
  formatMessage,
  onClick,
}: SettingsCardControlConfigurationsType): SettingsCardControlReturnConfigurationsType[] =>
  [CardControlTabsIdsEnumeration.OPERATIONS, CardControlTabsIdsEnumeration.LOCATIONS].map(
    (id: CardControlTabsIdsEnumeration) =>
      Object.assign(
        { id },
        { testId: `card-control-tab-${id}` },
        { title: formatMessage({ id: translations[id] }) },
        { onClick: () => onClick(id) }
      )
  );

export { getCardControlMappedTabsConfiguration };
