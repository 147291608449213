// Vendors
import styled from 'styled-components';

export const PersonalSidebarComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontPrimaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const PersonalSidebarContentComponentStyled = styled.section`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
