// Enumerations
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

export const SEPA_MODAL_CREDITOR_DIVIDER = {
  type: SectionDividerTypesEnumeration.INFORMATIVE,
};

export const SEPA_MODAL_CREDITOR_DIVIDER_TEXT = {
  uppercase: true,
};

export const SEPA_MODAL_DEBTOR_DIVIDER = {
  type: SectionDividerTypesEnumeration.INFORMATIVE,
};

export const SEPA_MODAL_DEBTOR_DIVIDER_TEXT = {
  uppercase: true,
};

export const SEPA_MODAL_CREDITOR_ID_PROPS = {
  testing: {
    labelId: 'sepa-modal-creditor-id-label',
    valueId: 'sepa-modal-creditor-id-value',
  },
};

export const SEPA_MODAL_IBAN_AND_BIC_PROPS = {
  testing: {
    labelId: 'sepa-modal-iban-and-bic-label',
    valueId: 'sepa-modal-iban-and-bic-value',
  },
};

export const SEPA_MODAL_CREDITOR_ADDRESS_PROPS = {
  testing: {
    labelId: 'sepa-modal-creditor-address-label',
    valueId: 'sepa-modal-creditor-address-value',
  },
};

export const SEPA_MODAL_SEPA_MANDATE_PROPS = {
  testing: {
    labelId: 'sepa-modal-sepa-mandate-label',
    valueId: 'sepa-modal-sepa-mandate-value',
  },
};

export const SEPA_MODAL_DEBTORS_NAME_PROPS = {
  testing: {
    labelId: 'sepa-modal-debtors-name-label',
    valueId: 'sepa-modal-debtors-name-value',
  },
};

export const SEPA_MODAL_CYCLE_DAY_PROPS = {
  testing: {
    labelId: 'sepa-modal-cycle-day-label',
    valueId: 'sepa-modal-cycle-day-value',
  },
};

export const SEPA_MODAL_ADDRESS_PROPS = {
  testing: {
    labelId: 'sepa-modal-address-label',
    valueId: 'sepa-modal-address-value',
  },
};

export const SEPA_MODAL_TYPE_OF_PAYMENT_PROPS = {
  testing: {
    labelId: 'sepa-modal-type-of-payment-label',
    valueId: 'sepa-modal-type-of-payment-value',
  },
};

export const PRIMARY_BUTTON_CONFIGURATION = {
  type: 'primary',
  testId: 'sepa-modal-button',
};
