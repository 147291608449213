// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/dashboard-total-points.constants';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Styles
import { DashboardTotalPointsComponentMobileStyled } from './dashboard-total-points.component.styled';
// Types
import { DashboardTotalPointsComponentType } from './types/dashboard-total-points.component.type';
import { DesktopContent } from './components/dashboard-total-points-desktop-content/dashboard-total-points-desktop.component';

const DashboardTotalPointsComponent = ({
  currency,
  showRespectively,
  respectively,
  total,
}: DashboardTotalPointsComponentType): React.ReactElement => {
  const { isMobile } = DeviceTypeHook();
  const content = (
    <DesktopContent
      currency={currency}
      showRespectively={showRespectively}
      isMobile={isMobile}
      respectively={respectively}
      total={total}
    />
  );
  return isMobile ? (
    <>
      <DashboardTotalPointsComponentMobileStyled>
        <AssetComponent {...constants.DASHBOARD_POINTS_ASSET_PROPS} />
        {content}
      </DashboardTotalPointsComponentMobileStyled>
    </>
  ) : (
    <>{content}</>
  );
};

export default DashboardTotalPointsComponent;
