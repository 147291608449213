// Vendors
import React from 'react';
import { isMobileDevice } from '@openbank/cf-ui-framework';
// Components
import { LinkComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { FILTER_LINK_PROPS } from './constants/documentation-header.constants';
import { CATEGORIES_DOMAIN } from 'containers/documentation/components/category-list/constants/documentation-category-list.constants';
// Enumerations
import { DocumentTypeEnumeration } from 'containers/documentation/enumerations/documentation.enumeration';
// Styles
import { DocumentationContentHeaderContainer } from './documentation-content-header.component.styled';
// Translations
import { FILTER } from './translations/documentation-header.translations';
import { DOCUMENTATION_CONTENT_TITLE_PROPS } from '../../constants/documentation-documents-content.constants';
// Types
import { type DocumentationContentHeaderPropsType } from './types/documentation-content-header.component.type';

const DocumentationContentHeaderComponent = ({
  categorySelected,
  handleClickFilter,
}: DocumentationContentHeaderPropsType): React.ReactElement => (
  <DocumentationContentHeaderContainer>
    <TitleHeaderComponent {...DOCUMENTATION_CONTENT_TITLE_PROPS}>
      <FormattedMessageComponent id={`${CATEGORIES_DOMAIN}${categorySelected}`} />
    </TitleHeaderComponent>
    {categorySelected !== DocumentTypeEnumeration.CONTRACT && (
      <LinkComponent {...FILTER_LINK_PROPS} onClick={handleClickFilter}>
        {!isMobileDevice() && <FormattedMessageComponent id={FILTER} />}
      </LinkComponent>
    )}
  </DocumentationContentHeaderContainer>
);

export { DocumentationContentHeaderComponent };
