// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Resolvers
import { cancelProcessResolver, getInitCaseResolver } from '../resolvers/step-0-init.resolvers';
// Utils
import { isKycExpired } from '../utils/kycDate.utils';
import { mapPersonalDataToLocal } from '../services/utils/step-2-laboral-economic-data.utils';
import { validateLaboralAndEmploymentData } from './utils/step-2-validators.utils';
// Types
import { CancelModalLinkClickReturnType } from './types/cancel-modal-link-click-return.type';
import { CreditLimitIncreaseContextType } from '../contexts/types/credit-limit-increase.context.type';
import { GetInitHandlerPropsType } from './types/get-init-case-handler-props.type';
import { HandleCancelModalLinkClickPropsType } from './types/handle-cancel-modal-link-click-props.type';
import { InitPropsType } from './types/step-0-init-props.handler.type';
import { InitReturnType } from './types/step-0-init-return.handler.type';
import { HandleGetInitCasePropsType } from './types/handle-get-init-case-props.type';

// eslint-disable-next-line complexity
const getInitCaseHandler = async ({
  amlNextReviewDate,
  creditLimitContext,
  cardId,
  callbackWebId,
  setSearchParams,
}: GetInitHandlerPropsType): Promise<void> => {
  const {
    wizardStatus,
    setWizardStatus,
    step1,
    setStep1,
    step2,
    setStep2,
    step4,
    setStep4,
    step5,
    setStep5,
    step6,
    setStep6,
    finalFeedback,
    setFinalFeedback,
    setShowExpiredFiocModal,
  } = creditLimitContext;

  if (isKycExpired(amlNextReviewDate)) {
    setShowExpiredFiocModal(true);
    return;
  }

  const [result, error] = await getInitCaseResolver({ cardId, callbackWebId });

  if (error) {
    setWizardStatus({
      ...wizardStatus,
      fetching: false,
      error: true,
    });
    return;
  }
  if (result) {
    const currentStep = StepEnumToIndexMap[result.nextStep];

    setWizardStatus({
      ...wizardStatus,
      error: currentStep === undefined,
      fetching: false,
      currentStep,
      currentStepInText: result.nextStep as CreditLimitIncreaseStepsEnumeration,
      caseId: result.caseId,
    });

    switch (currentStep) {
      case 1:
        setStep1({
          ...step1,
          minAmount: result.stepData.minAmount as number,
          maxAmount: result.stepData.maxAmount as number,
          nextDisabled: Boolean(
            !result.stepData.newCreditLimit ||
              Number(result.stepData.newCreditLimit) <
                Number(Number(result.stepData.minAmount) + 500)
          ),
          currentCreditLimit:
            result.stepData.newCreditLimit ||
            result.stepData.minAmount ||
            creditLimitContext.step1.currentCreditLimit,
          newCreditLimit:
            result.stepData.newCreditLimit ||
            result.stepData.minAmount ||
            creditLimitContext.step1.currentCreditLimit,
        });
        break;
      case 2:
        if (result.stepData.personalData) {
          const personalDataLocal = mapPersonalDataToLocal(result.stepData.personalData);
          setStep2({
            ...step2,
            ...validateLaboralAndEmploymentData(personalDataLocal),
          });
        }
        break;
      case 4:
        setStep4({
          ...step4,
          stepData: result?.stepData,
          aggregationUrl: result.stepData.aggregationUrl,
        });
        break;
      case 5:
        setStep5({
          ...step5,
          ...result.stepData,
        });
        break;
      case 6:
        setStep6({
          ...step6,
          stepData: result?.stepData,
        });
        break;
      case 7:
        setSearchParams();
        setFinalFeedback({
          ...finalFeedback,
          taxIdRequired: result?.taxIdRequired || false,
        });
        break;
      default:
        break;
    }
  }
};

const cancelProcessClickHandler = async ({
  creditLimitContext,
  setDisabledButtonsCancelModal,
}: Pick<InitPropsType, 'creditLimitContext'> &
  Pick<
    HandleCancelModalLinkClickPropsType,
    'setDisabledButtonsCancelModal'
  >): CancelModalLinkClickReturnType => {
  setDisabledButtonsCancelModal(true);

  const { wizardStatus } = creditLimitContext;
  const [_] = await cancelProcessResolver(wizardStatus.caseId);

  // if (error) {
  //   // TODO show some error message
  //   setDisabledButtonsCancelModal(false);
  // }

  return {
    hasToNavigate: true,
  };
};

const getDefaultFinalFeedbackHandler = (
  creditLimitContext: CreditLimitIncreaseContextType
): boolean | undefined => {
  const { wizardStatus } = creditLimitContext;

  if (
    wizardStatus.currentStepInText === CreditLimitIncreaseStepsEnumeration.SUCCESS ||
    wizardStatus.currentStepInText === CreditLimitIncreaseStepsEnumeration.COMPLETED
  ) {
    return true;
  }
  if (
    wizardStatus.currentStepInText === CreditLimitIncreaseStepsEnumeration.ERROR_CMS_SUCCESS ||
    wizardStatus.currentStepInText === CreditLimitIncreaseStepsEnumeration.END_NAVIGATION ||
    wizardStatus.currentStepInText === CreditLimitIncreaseStepsEnumeration.REJECT_M2
  ) {
    return false;
  }

  return undefined;
};

export const InitHandlers = ({ cardId, creditLimitContext }: InitPropsType): InitReturnType => ({
  handleGetInitCase: ({
    amlNextReviewDate,
    callbackWebId,
    setSearchParams,
  }: HandleGetInitCasePropsType) =>
    getInitCaseHandler({
      amlNextReviewDate,
      cardId,
      creditLimitContext,
      callbackWebId,
      setSearchParams,
    }),
  handleCancelProcessClick: ({ setDisabledButtonsCancelModal }) =>
    cancelProcessClickHandler({ creditLimitContext, setDisabledButtonsCancelModal }),
  handleGetDefaultFinalFeedback: () => getDefaultFinalFeedbackHandler(creditLimitContext),
});
