// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Hooks
import { useInsurances } from './hooks/insurances.hook';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { InsurancesContextType } from './types/insurances.context.type';
import { InsurancesInfoResponseType } from 'types/insurances/insurances-info-response.type';

const InsurancesContext = createContext<InsurancesContextType>({} as InsurancesContextType);

const InsurancesContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<InsurancesContextType>> => {
  const [insurancesInfo, setInsurancesInfo] = useState<InsurancesInfoResponseType | undefined>();
  const [fetching, setFetching] = useState(true);
  const [fetchError, setFetchError] = useState(false);

  const { handleFetchInsurancesInfo } = useInsurances({
    setFetchError,
    setFetching,
    setInsurancesInfo,
  });

  const contextValue = useMemo(
    () => ({
      insurancesInfo,
      fetchError,
      fetching,
      handleFetchInsurancesInfo,
    }),
    [fetchError, fetching, handleFetchInsurancesInfo, insurancesInfo]
  );

  return <InsurancesContext.Provider value={contextValue}>{children}</InsurancesContext.Provider>;
};

const useInsurancesContextConsumerHook = (): InsurancesContextType => useContext(InsurancesContext);

/* eslint-disable */
const InsurancesContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<InsurancesContextType>> =>
    (
      <InsurancesContextProvider>
        <Component {...props} />
      </InsurancesContextProvider>
    );

export { InsurancesContextConsumerHOC, useInsurancesContextConsumerHook };
