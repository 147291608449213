// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { LIST_SELECTOR_CONTENT_PARAGRAPH_PROPS } from '../constants/filter-modal-list-selector-content.constants';
// Styles
import {
  FilterModalListSelectorComponentDescriptionStyled,
  FilterModalListSelectorComponentStyled,
} from './filter-modal-list-selector-content.styled';
// Types
import { FilterModalListSelectorContentComponentType } from './types/filter-modal-list-selector-content.component.type';

const FilterModalListSelectorContentComponent = ({
  titleOption,
  dateDescription,
}: FilterModalListSelectorContentComponentType): React.ReactElement => (
  <FilterModalListSelectorComponentStyled>
    <ParagraphComponent {...LIST_SELECTOR_CONTENT_PARAGRAPH_PROPS}>
      {titleOption}
    </ParagraphComponent>
    <FilterModalListSelectorComponentDescriptionStyled>
      <ParagraphComponent>{dateDescription}</ParagraphComponent>
    </FilterModalListSelectorComponentDescriptionStyled>
  </FilterModalListSelectorComponentStyled>
);

export { FilterModalListSelectorContentComponent };
