// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  SessionExpiredBuilderHandlersType,
  SessionExpiredBuilderReturnHandlersType,
} from 'components/expired-session-modal/handlers/types/expired-session-modal.handlers.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { clearSessionData } from 'utils/session/session.utils';

const returnToLoginOrAppControl = ({
  isHybrid,
  onNavigateHybridFlow,
}: Pick<SessionExpiredBuilderHandlersType, 'isHybrid' | 'onNavigateHybridFlow'>): void => {
  isHybrid
    ? onNavigateHybridFlow()
    : window.location.replace(generateAbsoluteRoute(RoutesEnumeration.LOGIN));
};

const goToLoginFromExpiredSessionButtonClickHandler = ({
  handleGoToLoginFromExpiredSessionButtonClickTracking,
  ...rest
}: Omit<SessionExpiredBuilderHandlersType, 'setExpiredReason'>): void => {
  handleGoToLoginFromExpiredSessionButtonClickTracking();
  clearSessionData();
  returnToLoginOrAppControl(rest);
};

const SessionExpiredHandlers = (
  props: SessionExpiredBuilderHandlersType
): SessionExpiredBuilderReturnHandlersType => ({
  handleGoToLoginFromExpiredSessionButtonClick: () =>
    goToLoginFromExpiredSessionButtonClickHandler(props),
});

export { SessionExpiredHandlers };
