const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.finalFeedback.';

export const translationsFinalFeedback = {
  SUCCESS_TITLE: `${DOMAIN}successTitle`,
  SUCCESS_SUBTITLE: `${DOMAIN}successSubtitle`,
  ERROR_TITLE: `${DOMAIN}errorTitle`,
  ERROR_SUBTITLE: `${DOMAIN}errorSubtitle`,
  TAXT_SUCCESS_TITLE: `${DOMAIN}taxtSuccessTitle`,
  TAXT_SUCCESS_SUBTITLE: `${DOMAIN}taxtSuccessSubtitle`,
  INPUT_TAXT_ID: `${DOMAIN}inputTaxtId`,
  INPUT_PLACEHOLDER: `${DOMAIN}inputPlaceholder`,
  SAVE: `${DOMAIN}save`,
  EXIT_THE_PROCESS: `${DOMAIN}exitTheProcess`,
  GO_TO_FINANCING: `${DOMAIN}goToFinancing`,
  TAX_ID_SAVED: `${DOMAIN}taxIdSaved`,
  TAX_ID_NOT_SAVED: `${DOMAIN}taxIdNotSaved`,
};
