// Vendors
import React from 'react';
// Components
import { PasswordModuleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import AccessKeyStep2ActionsComponent from './actions/security-keys-access-key-change-second-step-actions.component';
// Constants
import constants from './constants/security-keys-access-key-change-second-step.constants';
// Translations
import translations from '../../translations/security-keys-access-key-change.translations';
// Hooks
import SecurityKeysAccessKeyChangeSecondStepHook from './hooks/security-keys-access-key-change-second-step.hook';
// Utils
import { getAccessKeyConstraints } from '../../utils/security-keys-access-key-change-constraints.util';

const SecurityKeysAccessKeyChangeSecondStepComponent = (): React.ReactElement => {
  const {
    newAccessKey,
    repeatNewAccessKey,
    handleSaveNewAccessKeyChangeInputEvent,
    handleSaveNewRepeatAccessKeyChangeInputEvent,
    inputError,
    inputErrorRepeat,
    handleFirstPasswordPositionInputClickTracking,
    handleSecondPasswordPositionInputClickTracking,
    handleShowHideInputClickTracking,
    formatMessage,
  } = SecurityKeysAccessKeyChangeSecondStepHook();

  return (
    <>
      <PasswordModuleComponent
        {...constants.ACCESS_KEY_CHANGE_SECOND_STEP_FIRST_PASSWORD_PROPS}
        analytics={{ handleOnClick: () => handleShowHideInputClickTracking() }}
        constraints={getAccessKeyConstraints(inputError, formatMessage)}
        description={formatMessage({ id: translations.DESCRIPTION_PASSWORD_TEXT })}
        onChange={handleSaveNewAccessKeyChangeInputEvent}
        onClick={handleFirstPasswordPositionInputClickTracking}
        translations={{
          SHOW_PASSWORD: formatMessage({ id: translations.SHOW_PASSWORD_TEXT }),
          HIDE_PASSWORD: formatMessage({ id: translations.HIDE_PASSWORD_TEXT }),
        }}
        title={formatMessage({ id: translations.DEFINE_NEW_ACCES_KEY })}
        value={newAccessKey}
        errorMessage={
          inputError && <FormattedMessageComponent id={translations.ERRORS.INFORMATION_ABOVE} />
        }
      />
      <PasswordModuleComponent
        {...constants.ACCESS_KEY_CHANGE_SECOND_STEP_SECOND_PASSWORD_PROPS}
        analytics={{ handleOnClick: () => handleShowHideInputClickTracking() }}
        description={formatMessage({ id: translations.DESCRIPTION_PASSWORD_TEXT })}
        onChange={handleSaveNewRepeatAccessKeyChangeInputEvent}
        onClick={handleSecondPasswordPositionInputClickTracking}
        translations={{
          SHOW_PASSWORD: formatMessage({ id: translations.SHOW_PASSWORD_TEXT }),
          HIDE_PASSWORD: formatMessage({ id: translations.HIDE_PASSWORD_TEXT }),
        }}
        title={formatMessage({ id: translations.REPEAT_NEW_ACCES_KEY })}
        value={repeatNewAccessKey}
        errorMessage={
          inputErrorRepeat && (
            <FormattedMessageComponent id={translations.ERRORS[inputErrorRepeat]} />
          )
        }
      />
      <AccessKeyStep2ActionsComponent />
    </>
  );
};

export default SecurityKeysAccessKeyChangeSecondStepComponent;
