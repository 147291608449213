// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { LABORAL_ECONOMIC_DATA_URL_SUFFIX } from './constants/step-2-set-laboral-economic-data.services.constants';
import { LIMIT_INCREASE_CASES_URL } from './constants/commons.constants';
// Types
import { SetLaboralEconomicDataPropsType } from './types/step-2-set-laboral-economic-data-service.type';
import { SetLaboralEconomicDataReturnPropsType } from './types/step-2-set-laboral-economic-data-service-return.type';

export const setLaboralEconomicDataService = ({
  caseId,
  personalData,
}: SetLaboralEconomicDataPropsType): Promise<[SetLaboralEconomicDataReturnPropsType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${LABORAL_ECONOMIC_DATA_URL_SUFFIX}`,
    params: {
      ...personalData,
    },
  });
