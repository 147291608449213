// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsChangePinContextConsumerHook } from 'containers/card-settings/views/change-pin/contexts/card-settings-change-pin.context';
// Event handlers
import CardSettingsChangePinRepeatedPinHandlers from '../handlers/card-settings-change-pin-repeat-pin.handlers';
import CardSettingsChangePinRepeatedPinTrackingHandlers from '../handlers/card-settings-change-pin-repeat-pin.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChangePinRepeatedPinHookType } from './types/card-settings-change-pin-repeat-pin.hook.type';

const useCardSettingsChangePinRepeatedPinHook = (): CardSettingsChangePinRepeatedPinHookType => {
  const [error, setError] = useState<boolean>(false);

  const { challenged, newPin, repeatedPin, setRepeatedPin, shownCode, solvedChallenged } =
      useCardSettingsChangePinContextConsumerHook(),
    { profile } = AppContextConsumerHook(),
    { dateOfBirth } = profile?.birthDetails || {};

  const {
      handleCardSettingsChangePinRepeatedPinClickTracking,
      handleCardSettingsChangePinRepeatedPinErrorTracking,
    } = AppTrackingHook(CardSettingsChangePinRepeatedPinTrackingHandlers),
    { handleSaveCardSettingsChangePinRepeatedPinChange } = CardSettingsChangePinRepeatedPinHandlers(
      {
        dateOfBirth,
        handleCardSettingsChangePinRepeatedPinErrorTracking,
        newPin,
        setRepeatedPin,
        setError,
      }
    );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSaveCardSettingsChangePinRepeatedPinChange(repeatedPin);
  }, [newPin, repeatedPin]);

  return {
    challenged,
    dateOfBirth,
    error,
    handleCardSettingsChangePinRepeatedPinClickTracking,
    handleSaveCardSettingsChangePinRepeatedPinChange,
    newPin,
    repeatedPin,
    shownCode,
    solvedChallenged,
  };
};

export default useCardSettingsChangePinRepeatedPinHook;
