// Vendors
import { useRef, useEffect } from 'react';
import { Location } from 'react-router-dom';

const usePrevLocation = (location: Location): Location => {
  const prevLocRef = useRef(location);

  useEffect(() => {
    prevLocRef.current = location;
  }, [location]);

  return prevLocRef.current;
};

export default usePrevLocation;
