// Vendors
import { useState } from 'react';
import { isIosWebView } from '@openbank/web-ui-framework';
// Handlers
import { DocumentationTableHandlers } from '../handlers/documents-table-row.handlers';
// TYpes
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
import { DocumentsTableBuilderReturnHandlersType } from 'containers/documentation/components/documents-table/handlers/types/documents-table-builder-return.handlers.type';

export const useDocumentationTableHook = (): DocumentsTableBuilderReturnHandlersType => {
  const { handleOpenExternalUrlOnHybridFlow } = MobileConnectorContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook();

  return DocumentationTableHandlers({ setToastConfiguration, handleOpenExternalUrlOnHybridFlow });
};
