// Vendors
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import useAccessPortabilityHook from 'containers/personal/views/access-portability/hooks/access-portability.hook';
// Types
import { AccessPortabilityRequestGuardType } from './types/access-portability-request-guard.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { isThereAPendingRequest } from 'containers/personal/views/access-portability/utils/access-portability.utils';

const AccessPortabilityRequestGuardComponent = ({
  rightsType,
}: AccessPortabilityRequestGuardType): React.ReactElement => {
  const { information, pageLoader } = useAccessPortabilityHook({ rightsType });

  return (
    <FetchErrorComponent fetching={pageLoader}>
      {!isThereAPendingRequest(information) ? (
        <Outlet />
      ) : (
        <Navigate
          replace={true}
          to={generateAbsoluteRoute(
            `${RoutesEnumeration.PERSONAL_INFORMATION}/${RoutesEnumeration.ACCESS_PORTABILITY}`
          )}
        />
      )}
    </FetchErrorComponent>
  );
};

export { AccessPortabilityRequestGuardComponent };
