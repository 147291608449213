// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_STATEMENTS_TABLE_ROW_DESCRIPTION } from './constants/card-settings-statements-table-row-description.constants';
// Translations
import { PAYMENT_METHOD_VALUE } from './translations/card-settings-statements-table-row-description.translations';
// Types
import { CardSettingsStatementsTableRowDescriptionComponentType } from './types/card-settings-statements-table-row-description.component.type';

const CardSettingsStatementsTableRowDescriptionComponent = ({
  paymentMethod,
}: CardSettingsStatementsTableRowDescriptionComponentType): React.ReactElement => (
  <ParagraphComponent {...CARD_SETTINGS_STATEMENTS_TABLE_ROW_DESCRIPTION}>
    <FormattedMessageComponent id={PAYMENT_METHOD_VALUE[paymentMethod]} />
  </ParagraphComponent>
);

export { CardSettingsStatementsTableRowDescriptionComponent };
