// Constants
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_MINIMUM } from 'containers/transactions/views/epp/components/step1/components/simulation/components/options/constants/transactions-epp-step1-simulation-options.constants';
// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Event handlers
import { TransactionsEppStep1SimulationOptionsAllHandlers } from '../handlers/transactions-epp-step1-simulation-options-all.handlers';
import { TransactionsEppStep1SimulationOptionsAllTrackingHandlers } from '../handlers/transactions-epp-step1-simulation-options-all.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsEppStep1SimulationOptionsAllHookType } from './types/transactions-epp-step1-simulation-options-all.hook.type';

const useTransactionsEppStep1SimulationOptionsAllHook =
  (): TransactionsEppStep1SimulationOptionsAllHookType => {
    const { isAllOptionsSelected, setIsAllOptionsSelected, setInstallments, transactionDetails } =
        useTransactionsEppContextConsumerHook(),
      { minNumOfInstallments = TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_MINIMUM } =
        transactionDetails || {};

    return {
      ...TransactionsEppStep1SimulationOptionsAllHandlers({
        ...AppTrackingHook(TransactionsEppStep1SimulationOptionsAllTrackingHandlers),
        minNumOfInstallments,
        setIsAllOptionsSelected,
        setInstallments,
      }),
      isAllOptionsSelected,
      minNumOfInstallments,
    };
  };

export { useTransactionsEppStep1SimulationOptionsAllHook };
