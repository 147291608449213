// Types
import {
  FinancingPPIContractingStep2AddressBuilderHandlersType,
  FinancingPPIContractingStep2AddressMethodsHandlersType,
} from './types/financing-ppi-contracting-step2-address.handlers.type';

const updatePostalCodeStatus =
  () =>
  (prevPostalDocumentation: boolean): boolean =>
    !prevPostalDocumentation;

const changePostalDocumentationConsentCheckBoxClick = ({
  handleChangePostalDocumentationConsentCheckBoxClickTracking,
  setPostalDocumentation,
}: FinancingPPIContractingStep2AddressBuilderHandlersType): void => {
  handleChangePostalDocumentationConsentCheckBoxClickTracking();
  setPostalDocumentation(updatePostalCodeStatus());
};

const FinancingPpiContractingStep2AddressHandlers = (
  props: FinancingPPIContractingStep2AddressBuilderHandlersType
): FinancingPPIContractingStep2AddressMethodsHandlersType => ({
  handleChangePostalDocumentationConsentCheckBoxClick: () =>
    changePostalDocumentationConsentCheckBoxClick(props),
});

export { FinancingPpiContractingStep2AddressHandlers };
