// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  LABELS_LINK_CARD_LAST_ASSET_PROPS: {
    id: 'clock',
    sizes: {
      height: 14,
      width: 14,
    },
    testId: 'labels-links-card-last-asset',
  },
  LABELS_LINK_CARD_CHANGE_DEFAULT_CONFIGURATION: {
    date: '',
    label: '',
  },
  LABELS_LINK_CARD_LAST_PROPS: {
    size: paragraphConstants.SIZES.XS,
    testId: 'labels-links-card-last-label',
  },
};
