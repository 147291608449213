// Enumerations
import { ConfirmationOptionEnumeration } from 'containers/personal/views/number/components/step3/components/confirmation-option/enumerations/confirmation-option.enumeration';

export default {
  CONFIRMATION_OPTIONS: [
    {
      eventLabel: 'phoneConfirmationOpton',
      id: ConfirmationOptionEnumeration.PHONE,
      testId: 'personal-number-step3-confirmation-option-phone',
      value: ConfirmationOptionEnumeration.PHONE,
    },
    {
      eventLabel: 'emailConfirmationOpton',
      id: ConfirmationOptionEnumeration.EMAIL,
      testId: 'personal-number-step3-confirmation-option-email',
      value: ConfirmationOptionEnumeration.EMAIL,
    },
  ],
};
