// Handlers
import { ChallengeHandlers } from 'handlers/challenge/challenge.handlers';
// Hooks
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Types
import { UseFinancingInsurancesContractingLPIStep4ChallengeHookPropsType } from './types/financing-insurances-contracting-lpi-step4-challenge-props.hook.type';
import { UseFinancingInsurancesContractingLPIStep4ChallengeHookReturnType } from './types/financing-insurances-contracting-lpi-step4-challenge-return.hook.type';

const useFinancingInsurancesContractingLPIStep4ChallengeHook = ({
  setChallenge,
  setChallengeError,
}: UseFinancingInsurancesContractingLPIStep4ChallengeHookPropsType): UseFinancingInsurancesContractingLPIStep4ChallengeHookReturnType => {
  const { plan } = useFinancingInsurancesContractingLPIContextConsumerHook();
  const { insurancesInfo } = useInsurancesContextConsumerHook();
  const insuranceDetail = insurancesInfo?.insurances?.LPI;

  return {
    ...ChallengeHandlers({
      setChallenge,
      setChallengeError,
    }),
    productId: insuranceDetail?.productId,
    productType: insuranceDetail?.productType,
    plan,
    setChallengeError,
  };
};

export { useFinancingInsurancesContractingLPIStep4ChallengeHook };
