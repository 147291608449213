// Enumerations
import { CaseTypeEnum } from 'containers/transactions/views/report/enumerations/case-type.enumeration';
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
import { MotiveDalEnum } from 'containers/transactions/views/report/enumerations/motive-dal.enumeration';

const DISPUTE_PARAMS = {
  caseType: CaseTypeEnum.MANAGEMENT_TECH_DISPUTES,
  claimReasonType: ClaimReasonEnum.PAID_BY_OTHER_MEANS,
  motiveDal: MotiveDalEnum.PAYMENT_MADE_FOR_OTHER_REASONS,
  blockCard: false,
};

export { DISPUTE_PARAMS };
