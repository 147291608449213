// Types
import {
  CardSettingsChangePinNewPinBuilderHandlersType,
  CardSettingsChangePinNewPinBuilderReturnHandlersType,
  CardSettingsChangePinNewPinMethodBuilderHandlersType,
} from './types/card-settings-change-pin-new-pin.handlers.type';
// Utilities
import {
  pinFilledIsFilled,
  pinMatchesAllConstraints,
} from 'containers/card-settings/views/change-pin/utils/card-settings-change-pin.utils';

const saveCardSettingsChangePinNewPinChangeHandler = async ({
  dateOfBirth,
  handleCardSettingsChangePinNewPinErrorTracking,
  pin,
  setNewPin,
  setError,
}: CardSettingsChangePinNewPinMethodBuilderHandlersType): Promise<void> => {
  setError(false);
  setNewPin(pin);

  if (pinFilledIsFilled(pin) && !pinMatchesAllConstraints({ dateOfBirth, pin })) {
    handleCardSettingsChangePinNewPinErrorTracking();
    setError(true);
  }
};

const CardSettingsChangePinNewPinHandlers = (
  props: CardSettingsChangePinNewPinBuilderHandlersType
): CardSettingsChangePinNewPinBuilderReturnHandlersType => ({
  handleSaveCardSettingsChangePinNewPinChange: (pin: string) =>
    saveCardSettingsChangePinNewPinChangeHandler({ ...props, pin }),
});

export default CardSettingsChangePinNewPinHandlers;
