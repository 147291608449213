// Handlers
import { WizardFeedbackErrorCommonHandlers } from './wizard-feedback-error.common.handlers';
// Types
import { RecoveryKeyFeedbackBuilderReturnHandlersType } from './types/wizard-feedback-error-builder-return.handlers.type';
import { RecoveryKeyFeedbackBuilderHandlersType } from './types/wizard-feedback-error-builder.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const wizardFeedbackErrorLinkClickHandler = ({
  exitRoute,
  handleWizardFeedbackErrorLinkClickTracking,
  linkClick = () => onNavigateHybridFlow?.(generateAbsoluteRoute(exitRoute)),
  onNavigateHybridFlow,
}: RecoveryKeyFeedbackBuilderHandlersType): void => {
  handleWizardFeedbackErrorLinkClickTracking();
  linkClick();
};

const WizardFeedbackErrorHandlers = (
  props: RecoveryKeyFeedbackBuilderHandlersType
): RecoveryKeyFeedbackBuilderReturnHandlersType => ({
  ...WizardFeedbackErrorCommonHandlers(props),
  handleWizardFeedbackErrorLinkClick: () => wizardFeedbackErrorLinkClickHandler(props),
});

export default WizardFeedbackErrorHandlers;
