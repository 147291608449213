// Types
import {
  TransactionsEppModalBuilderHandlersType,
  TransactionsEppModalMethodsHandlersType,
} from './types/transactions-epp-modal.handlers.type';

const updateAmortizationTableVisibility =
  ({
    handleToggleAmortizationTableModalLinkClickTracking,
  }: Pick<
    TransactionsEppModalBuilderHandlersType,
    'handleToggleAmortizationTableModalLinkClickTracking'
  >): ((prevVisibility: boolean) => boolean) =>
  (prevVisibility: boolean): boolean => {
    handleToggleAmortizationTableModalLinkClickTracking(!prevVisibility);

    return !prevVisibility;
  };

const toggleAmortizationTableModalLinkClickHandler = ({
  setShowAmortizationTableModal,
  ...restProps
}: TransactionsEppModalBuilderHandlersType): void => {
  setShowAmortizationTableModal(updateAmortizationTableVisibility(restProps));
};

const TransactionsEppModalHandlers = (
  props: TransactionsEppModalBuilderHandlersType
): TransactionsEppModalMethodsHandlersType => ({
  handleToggleAmortizationTableModalLinkClick: () =>
    toggleAmortizationTableModalLinkClickHandler(props),
});

export { TransactionsEppModalHandlers };
