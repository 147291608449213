// Types
import { PersonalEmailStep1UtilsType } from './types/personal-email-step1.utils.type';

const newEmailAndRepeatedDontMatch = ({
  newEmail,
  repeatEmail,
}: PersonalEmailStep1UtilsType): boolean =>
  Boolean(newEmail && repeatEmail && newEmail !== repeatEmail);

const isEmptyField = (email: string): boolean => email === '';

export { newEmailAndRepeatedDontMatch, isEmptyField };
