// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  LinkComponent,
  SidebarComponent,
  ProductDetailComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PARRAGRAPH_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_LINK_PROPS,
} from './constants/notifications-settings-sidebar-faq.constants';
// Styles
import {
  NotificationsSettingsSidebarComponentStyled,
  NotificationsSettingsSidebarProductDetailWrapperStyled,
} from '../../notifications-settings-sidebar.component.styled';
// Translations
import {
  DETAIL_TITLE,
  PARRAGRAPH_TITLE,
  LINK_TITLE,
  TITLE,
} from './translations/notifications-settings-sidebar-faq.translations';
// Types
import { NotificationSettingsSidebarFaqPropsType } from './types/notification-settings-sidebar-faq.type';

const NotificationSettingsSidebarFaqComponent = ({
  handleFaqButtonClick,
}: NotificationSettingsSidebarFaqPropsType): React.ReactElement => (
  <SidebarComponent
    {...NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <NotificationsSettingsSidebarComponentStyled>
      <ParagraphComponent {...NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PARRAGRAPH_PROPS}>
        <FormattedMessageComponent id={PARRAGRAPH_TITLE} />
      </ParagraphComponent>

      <ProductDetailComponent title={<FormattedMessageComponent id={DETAIL_TITLE} />}>
        <NotificationsSettingsSidebarProductDetailWrapperStyled>
          <LinkComponent
            onClick={handleFaqButtonClick}
            {...NOTIFICATION_SETTINGS_SIDEBAR_FAQ_LINK_PROPS}
          >
            <FormattedMessageComponent id={LINK_TITLE} />
          </LinkComponent>
        </NotificationsSettingsSidebarProductDetailWrapperStyled>
      </ProductDetailComponent>
    </NotificationsSettingsSidebarComponentStyled>
  </SidebarComponent>
);

export { NotificationSettingsSidebarFaqComponent };
