// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-landing-row-details-creation-date.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Translations
import translations from './translations/financing-landing-row-details-creation-date.translations';
// Types
import { FinancingLandingRowDetailsCreationDateComponentType } from './types/financing-landing-row-details-creation-date.component.type';

const FinancingLandingRowDetailsCreationDateComponent = ({
  eppCreationDate,
}: FinancingLandingRowDetailsCreationDateComponentType): React.ReactElement => (
  <ProductDetailComponent
    {...constants.FINANCING_LANDING_ROW_DETAILS_CREATION_DATE_PROPS}
    title={<FormattedMessageComponent id={translations.CREATION_DATE} />}
  >
    <ParagraphComponent bold {...constants.FINANCING_LANDING_ROW_DETAILS_CREATION_DATE_PROPS}>
      <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY} value={eppCreationDate} />
    </ParagraphComponent>
  </ProductDetailComponent>
);

export default FinancingLandingRowDetailsCreationDateComponent;
