// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import SelectorCardListComponent from './components/selector-card-component/financing-deposit-money-selector-card-list.component';
import { ParagraphComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  DEPOSIT_MONEY_DESCRIPTION_PROPS,
  DEPOSIT_MONEY_EURO_FORMAT_OPTIONS,
  DEPOSIT_MONEY_TITLE_PROPS,
} from './constants/financing-deposit-money-informative-component.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  DepositMoneyInformativeContentStyled,
  DepositMoneyMessageStyled,
} from './financing-deposit-money-informative.component.styled';
// Traslations
import translations from './translations/financing-deposit-money-informative.component.translations';
// Types
import { FinancingDepositMoneyInformativeTextsComponentType } from './types/financing-deposit-money-informative.component.type';

const FinancingDepositMoneyInformativeTextsComponent = ({
  issueBank,
  limit,
  maxTransferLimit,
  virtualIban,
}: FinancingDepositMoneyInformativeTextsComponentType): React.ReactElement => {
  const { formatNumber } = useCbIntl();

  return (
    <DepositMoneyInformativeContentStyled>
      <TitleHeaderComponent {...DEPOSIT_MONEY_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.FIRST_TITLE} />
      </TitleHeaderComponent>
      <DepositMoneyMessageStyled>
        <ParagraphComponent {...DEPOSIT_MONEY_DESCRIPTION_PROPS}>
          <FormattedMessageComponent
            id={translations.FIRST_CONTENT}
            values={{
              limitAmount: formatNumber(limit, DEPOSIT_MONEY_EURO_FORMAT_OPTIONS),
            }}
          />
          <FormattedMessageComponent
            bold
            id={translations.FIRST_SECONDARY_CONTENT}
            values={{
              maxTransferLimitAmount: formatNumber(
                maxTransferLimit,
                DEPOSIT_MONEY_EURO_FORMAT_OPTIONS
              ),
            }}
          />
        </ParagraphComponent>
      </DepositMoneyMessageStyled>
      <SelectorCardListComponent {...{ issueBank, virtualIban }} />
      <TitleHeaderComponent {...DEPOSIT_MONEY_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.SECOND_TITLE} />
      </TitleHeaderComponent>
      <DepositMoneyMessageStyled>
        <ParagraphComponent {...DEPOSIT_MONEY_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.SECOND_CONTENT} />
          <FormattedMessageComponent id={translations.THIRD_CONTENT} />
        </ParagraphComponent>
      </DepositMoneyMessageStyled>
      <TitleHeaderComponent {...DEPOSIT_MONEY_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.THIRD_TITLE} />
      </TitleHeaderComponent>
      <DepositMoneyMessageStyled>
        <ParagraphComponent {...DEPOSIT_MONEY_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.FOURTH_CONTENT} />
        </ParagraphComponent>
      </DepositMoneyMessageStyled>
      <DepositMoneyMessageStyled>
        <ParagraphComponent {...DEPOSIT_MONEY_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.FIFTH_CONTENT} />
        </ParagraphComponent>
      </DepositMoneyMessageStyled>
    </DepositMoneyInformativeContentStyled>
  );
};

export default FinancingDepositMoneyInformativeTextsComponent;
