// Vendors
import React from 'react';
// Components
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import WizardComponent from 'components/wizard/wizard.component';
import { TransactionsEppModalComponent } from './components/modal/transactions-epp-modal.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import {
  TRANSACTIONS_EPP_LAYOUT_PROPS,
  TRANSACTIONS_EPP_WIZARD_PROPS,
} from './constants/transactions-epp.constants';
// Providers
import { withCardDetailProvider } from 'providers/card-detail/card-detail.provider';
// Contexts
import { TransactionsEppContextConsumerHOC } from './contexts/transactions-epp.context';
// Hooks
import { useTransactionsEppHook } from './hooks/transactions-epp.hook';
// Translations
import * as translations from 'containers/transactions/views/epp/translations/transactions-epp-error.translations';

const TransactionsEppBaseComponent = (): React.ReactElement => {
  const { fetching, ...props } = useTransactionsEppHook();

  return (
    <OperativeLayoutComponent
      {...{ ...TRANSACTIONS_EPP_LAYOUT_PROPS, fetching }}
      error={getFetchErrorConfiguration({ ...props, translations })}
    >
      <>
        <WizardComponent {...TRANSACTIONS_EPP_WIZARD_PROPS} />
        <TransactionsEppModalComponent />
      </>
    </OperativeLayoutComponent>
  );
};

const TransactionsEppComponent = withCardDetailProvider(
  TransactionsEppContextConsumerHOC(TransactionsEppBaseComponent)
);

export { TransactionsEppComponent };
