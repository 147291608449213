// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPaymentMethodContextConsumerHook } from '../contexts/financing-payment-method.context';
// Handlers
import PaymentMethodHandlers from '../handlers/payment-method.handlers';
// Types
import { PaymentMethodHookType } from './types/payment-method.hook.type';

const usePaymentMethodHook = (): PaymentMethodHookType => {
  const { currentCardDetail } = AppContextConsumerHook(),
    { creditDetails } = currentCardDetail || {},
    { accountBalancePercentage, fixedPaymentAmount, paymentMethodCode } = creditDetails || {},
    { setFee, setPaymentMethod, setRevolvingMethod } = FinancingPaymentMethodContextConsumerHook();

  const { handleSelectCurrentMethod } = PaymentMethodHandlers({
    accountBalancePercentage,
    fixedPaymentAmount,
    paymentMethodCode,
    setFee,
    setPaymentMethod,
    setRevolvingMethod,
  });

  return {
    handleSelectCurrentMethod,
  };
};

export default usePaymentMethodHook;
