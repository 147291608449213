// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useTransactionsTableHeaderLinkBarListLinksHook } from './hooks/transactions-table-header-link-bar-list-links.hook';
// Types
import { TransactionsTableHeaderLinkBarListLinksComponentCCPropsType } from './types/transactions-table-header-link-bar-list-links.component.type.cc';

const TransactionsTableHeaderLinkBarListLinksComponentCC = ({
  listLinksConfigurations,
}: TransactionsTableHeaderLinkBarListLinksComponentCCPropsType): React.ReactElement | null => {
  const { handleTableHeaderCCLinksLinkClick } = useTransactionsTableHeaderLinkBarListLinksHook();

  return (
    <>
      {listLinksConfigurations &&
        [...listLinksConfigurations].reverse().map(({ url, messageId, ...rest }, index) => (
          <LinkComponent
            key={index}
            onClick={() => handleTableHeaderCCLinksLinkClick(url)}
            {...rest}
          >
            <FormattedMessageComponent id={messageId} />
          </LinkComponent>
        ))}
    </>
  );
};

export { TransactionsTableHeaderLinkBarListLinksComponentCC };
