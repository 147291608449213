// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const PDF = 'PDF',
  EXCEL = 'EXCEL';

const FILE_TYPE = {
  PDF: {
    fileType: 'application/pdf',
    fileExtension: '.pdf',
  },
  EXCEL: {
    fileType: 'application/vnd.ms-excel',
    fileExtension: '.xls',
  },
};

const INVALID_FILE_TYPE = {
  fileType: '',
  fileExtension: '',
};

const TRANSACTIONS_TABLE_HEADER_LINKBAR_DOWNLOAD_MODAL_FILTER_DEFAULT_PROPS = {
  primaryButtonConfiguration: {
    type: 'primary',
  },
  secondaryButtonConfiguration: {
    type: 'tertiary',
    assetConfiguration: {},
  },
  linkConfiguration: {
    children: 'Cancel',
  },
  modalContentConfiguration: [{ id: 'example', component: 'Example Component' }],
};

const MODAL_DESCRIPTION_ASSET_PROPS = {
  id: 'Working_on_it_Alternative',
  sizes: {
    height: 120,
    width: 120,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'download-modal-asset',
};

const MODAL_PARAGRAPH_DESCRIPTION_PROPS = {
  testId: 'modal-paragraph-description',
};

export {
  EXCEL,
  FILE_TYPE,
  INVALID_FILE_TYPE,
  MODAL_DESCRIPTION_ASSET_PROPS,
  MODAL_PARAGRAPH_DESCRIPTION_PROPS,
  PDF,
  TRANSACTIONS_TABLE_HEADER_LINKBAR_DOWNLOAD_MODAL_FILTER_DEFAULT_PROPS,
};
