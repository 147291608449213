// Types
import { SelectedOptionsType } from 'types/selected-options.type';

const hasAllSelectedOptions = ({
  notRecognizeChargeClickLink,
  notRecognizeChargeHaveYourCard,
  notRecognizeChargeMotiveFraud,
}: Pick<
  SelectedOptionsType,
  'notRecognizeChargeClickLink' | 'notRecognizeChargeHaveYourCard' | 'notRecognizeChargeMotiveFraud'
>): boolean =>
  notRecognizeChargeClickLink !== undefined &&
  notRecognizeChargeHaveYourCard !== undefined &&
  notRecognizeChargeMotiveFraud !== undefined;

const hasSelectedOptionsChanged = ({
  extraInformation,
  notRecognizeChargeClickLink,
  notRecognizeChargeHaveYourCard,
  notRecognizeChargeMotiveFraud,
}: Omit<SelectedOptionsType, 'length'>): boolean =>
  notRecognizeChargeHaveYourCard !== undefined ||
  notRecognizeChargeClickLink !== undefined ||
  notRecognizeChargeMotiveFraud !== undefined ||
  (extraInformation !== undefined && extraInformation?.length > 0);

export { hasAllSelectedOptions, hasSelectedOptionsChanged };
