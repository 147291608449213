// Translations
import { ENABLE, DISABLE } from './translations/card-settings-payment-modal.utils.translations';
// Types
import { CardSettingsPaymentModalHookType } from '../hooks/types/card-settings-payment-modal.hook.type';

const nonSecureStoresButtonIsDisabled = ({
  challenged,
  fetching,
}: Pick<CardSettingsPaymentModalHookType, 'challenged' | 'fetching'>): boolean =>
  Boolean(fetching || !challenged);

const nonSecureStoresChallengeIsDisabled = ({
  challenged,
  fetching,
}: Pick<CardSettingsPaymentModalHookType, 'challenged' | 'fetching'>): boolean =>
  Boolean(fetching || challenged);

const getNonSecureStoresOption = (enabled: boolean): string => (enabled ? ENABLE : DISABLE);

export {
  getNonSecureStoresOption,
  nonSecureStoresButtonIsDisabled,
  nonSecureStoresChallengeIsDisabled,
};
