// Vendors
import React from 'react';
// Components
import { AssetComponent, TitleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import ProfilePersonalNameResumeLayoutComponent from 'containers/personal/views/landing/components/name/components/resume-layout/profile-personal-name-resume-layout.component';
// Constants
import {
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_ASSET_PROPS,
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_PROPS,
  PROFILE_PERSONAL_NAME_TITLE_EMPTY_TITLE_PROPS,
} from './constants/profile-personal-name-title-empty.constants';
// Hooks
import { ProfilePersonalNameTitleEmptyHook } from './hooks/profile-personal-name-title-empty.hook';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import { ProfilePersonalNameEditContentTitleComponentStyled } from './profile-personal-name-title-empty.component.styled';
// Translations
import translations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title.translations';

const ProfilePersonalNameTitleEmptyComponent = (): React.ReactElement => {
  const { handleCreateNewTitleAssetClick } = ProfilePersonalNameTitleEmptyHook();

  return (
    <ProfilePersonalNameResumeLayoutComponent {...PROFILE_PERSONAL_NAME_TITLE_EMPTY_PROPS}>
      <ProfilePersonalNameEditContentTitleComponentStyled>
        <ParagraphSecondaryComponentStyled>
          <TitleComponent {...PROFILE_PERSONAL_NAME_TITLE_EMPTY_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.NO_TITLE} />
          </TitleComponent>
        </ParagraphSecondaryComponentStyled>
        <AssetComponent
          {...PROFILE_PERSONAL_NAME_TITLE_EMPTY_ASSET_PROPS}
          onClick={handleCreateNewTitleAssetClick}
        />
      </ProfilePersonalNameEditContentTitleComponentStyled>
    </ProfilePersonalNameResumeLayoutComponent>
  );
};

export default ProfilePersonalNameTitleEmptyComponent;
