// Vendors
import styled from 'styled-components';

const SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled = styled.section`
  width: 100%;
`;

SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled.displayName =
  'SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled';

export { SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled };
