// Vendors
import { useState } from 'react';
// Constants
import { DEFAULT_MIN_LIMIT_PRODUCT } from '../constants/step-1-credit-limit-decrease-cc.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitDecreaseCCContextConsumerHook } from 'containers/financing/views/credit-limit-decrease-cc/contexts/credit-limit-decrease-cc.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardComponentType } from 'types/card.component.type';
import { CardDetailCreditComponentType } from 'types/card-credit.component.type';
import { Step1CreditLimitDecreaseCCHookType } from './types/step-1-credit-limit-decrease-cc.hook.type';
// Utils
import { formatCurrency } from './utils/step-1-credit-limit-decrease-cc.hook.utils';

const Step1CreditLimitDecreaseCCHook = (): Step1CreditLimitDecreaseCCHookType => {
  const [amountError, setAmountError] = useState<string | undefined>();
  const [check1, setCheck1] = useState<boolean>(false);
  const [check2, setCheck2] = useState<boolean>(false);
  const { formatMessage, formatNumber } = useCbIntl();
  const { amount, setAmount } = CreditLimitDecreaseCCContextConsumerHook();
  const { currentCardDetail } = AppContextConsumerHook();
  const { currencyCode } = (currentCardDetail || {}) as CardComponentType;
  const {
    paymentMethodCode = '',
    fixedPaymentAmount = 0,
    accountBalancePercentage = 0,
    limit,
    minLimitProduct = DEFAULT_MIN_LIMIT_PRODUCT,
  } = (currentCardDetail?.creditDetails || {}) as CardDetailCreditComponentType;

  return {
    paymentMethodCode,
    fixedPaymentAmount,
    accountBalancePercentage,
    limit,
    min: minLimitProduct,
    mainCurrency: currencyCode,
    amount,
    amountError,
    check1,
    check2,
    setAmount,
    setAmountError,
    setCheck1,
    setCheck2,
    formatMessage,
    formatNumber,
    formatCurrencySeted: formatCurrency({ currency: currencyCode, formatNumber }),
  };
};

export { Step1CreditLimitDecreaseCCHook };
