// Vendors
import React from 'react';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import {
  getShowHideButtonConfiguration,
  getShowHideButtonLabel,
} from './configurations/card-settings-check-3d-confirmation.configuration';
// Constants
import {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_MODIFY_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_SHOW_HIDE_PROPS,
} from './constants/card-settings-check-3d-confirmation-buttons.constants';
// Hooks
import useCardSettingsCheck3dConfirmationSecurityModuleHook from './hooks/card-settings-check-3d-confirmation-buttons.hook';
// Styles
import { CardSettingsCheck3dConfirmationButtonsComponentStyled } from './card-settings-check-3d-confirmation-buttons.component.styled';
// Translations
import { MODIFY } from './translations/card-settings-check-3d-confirmation-security-module.translations';
// Utilities
import { showHideButtonIsDisabled } from './utils/card-settings-check-3d-confirmation.utils';
import { userHasTrustedDevice } from '../../utils/card-settings-check-3d-confirmation.utils';

const CardSettingsCheck3dConfirmationButtonsComponent = (): React.ReactElement => {
  const {
    handleModify3DSecureCodeButtonClick,
    handleShowHide3DSecureCodeButtonClick,
    shownCode,
    showAllowance,
  } = useCardSettingsCheck3dConfirmationSecurityModuleHook();

  return (
    <CardSettingsCheck3dConfirmationButtonsComponentStyled>
      <ButtonComponent
        {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_SHOW_HIDE_PROPS}
        {...getShowHideButtonConfiguration(shownCode)}
        disabled={showHideButtonIsDisabled({ showAllowance, devices: [] })}
        onClick={handleShowHide3DSecureCodeButtonClick}
      >
        <FormattedMessageComponent id={getShowHideButtonLabel(shownCode)} />
      </ButtonComponent>
      <ButtonComponent
        {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_BUTTONS_MODIFY_PROPS}
        onClick={handleModify3DSecureCodeButtonClick}
        disabled={userHasTrustedDevice([])}
      >
        <FormattedMessageComponent id={MODIFY} />
      </ButtonComponent>
    </CardSettingsCheck3dConfirmationButtonsComponentStyled>
  );
};

export default CardSettingsCheck3dConfirmationButtonsComponent;
