// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsChange3dStep1NewCodeBuilderTrackingHandlersType } from './types/card-settings-change-3d-step1-new-code.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const change3DNewCodeInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'new3DCode',
  },
  type: TrackingEventsEnum.LINK,
});

const change3DNewCodeInputErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'new3DCodeError',
    eventLabel: 'invalidCode',
    errorCategory: 'new3DCodeError',
    errorDescription: 'invalidFormat',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsChange3dStep1NewCodeTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsChange3dStep1NewCodeBuilderTrackingHandlersType => ({
  handleChange3DNewCodeInputClickTracking: () => track(change3DNewCodeInputClickTrackingHandler()),
  handleChange3DNewCodeInputErrorTracking: () => track(change3DNewCodeInputErrorTrackingHandler()),
});

export { CardSettingsChange3dStep1NewCodeTrackingHandlers };
