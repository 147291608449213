// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DeviceHeaderAliasInputComponentStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 276, base: 16 })}

  ${mobileMediaQuery} {
    padding-top: ${({ theme }) => theme.spaces.spacingXs};
    width: ${convertPxsToRems({ pixels: 264, base: 16 })}
  }
`;

export const DeviceHeaderAliasLinkComponentStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;