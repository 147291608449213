// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardTotalPointsComponentStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: flex-end;

    .desktop-title-content {
      display: flex;
      flex-direction: column-reverse;
    }

    font-size: ${({ theme }) => theme.fontSizes.fontSizeP};
  }
`;

export const DashboardTotalPointsComponentMobileStyled = styled.div`
  display: flex;
  flex-direction: row;
  text-align: right;
  width: 100%;
`;
