// Constants
import { STATUS_CODE_ERROR } from './constants/personal-number-step4-actions.handlers.constants';
import { changeUserPhoneOtpResolver } from '../resolvers/personal-number-step4-actions.resolver';
// Types
import { PersonalNumberStep4ActionsBuilderReturnHandlersType } from './types/personal-number-step4-actions-builder-return.handlers.type';
import { PersonalNumberStep4ActionsBuilderHandlersType } from './types/personal-number-step4-actions-builder.handlers.type';

const confirmChangePhoneButtonClickHandler = async ({
  handleConfirmChangeNumberButtonClickTracking,
  newPhoneNumber: phone,
  progressId,
  setResult,
  setFetching,
  setAlternativeError,
  setMe,
  phoneId,
  withEmail,
  newPrefix: phoneCountryCode,
}: PersonalNumberStep4ActionsBuilderHandlersType): Promise<void> => {
  setFetching(true);
  handleConfirmChangeNumberButtonClickTracking();

  const [status, error] = await changeUserPhoneOtpResolver({
    phoneId,
    phoneCountryCode,
    phone,
    progressId,
    withEmail,
  });

  if (error) {
    if (typeof status === 'number' && status === STATUS_CODE_ERROR) {
      setAlternativeError(true);
    }
    setResult(false);
  } else {
    setResult(true);
    setMe(true);
  }
  setFetching(false);
};

const PersonalNumberStep4ActionsHandlers = (
  props: PersonalNumberStep4ActionsBuilderHandlersType
): PersonalNumberStep4ActionsBuilderReturnHandlersType => ({
  handleConfirmChangePhoneButtonClick: () => confirmChangePhoneButtonClickHandler(props),
});

export default PersonalNumberStep4ActionsHandlers;
