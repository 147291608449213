// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/signature-key-devices.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SignatureKeyDevicesDeviceType } from '../types/signature-key-devices.device.type';
import { SignatureKeyDevicesSessionType } from '../types/signature-key-devices.session.type';
import { SignatureKeyDevicesContextType } from './types/signature-key-devices.context.type';

const SignatureKeyDevicesContext = createContext<SignatureKeyDevicesContextType>(
  { ... CONTEXT_DEFAULT_VALUE } as unknown as SignatureKeyDevicesContextType
);

export const SignatureKeyDevicesContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<SignatureKeyDevicesContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [devices, setDevices] = useState<Array<SignatureKeyDevicesDeviceType>>([]);
  const [trusted, setTrusted] = useState<SignatureKeyDevicesDeviceType | null>(null);
  const [blockedDevices, setBlockedDevices] = useState<Array<SignatureKeyDevicesDeviceType>>([]);
  const [sessions, setSessions] = useState<Record<string, Array<SignatureKeyDevicesSessionType>>>({});
  
  return (
    <SignatureKeyDevicesContext.Provider
      value={{
        fetching,
        loading,
        devices,
        trusted,
        setTrusted,
        blockedDevices,
        setBlockedDevices,
        sessions,
        error,
        setSessions,
        setFetching,
        setLoading,
        setError,
        setDevices
      }}
    >
      {children as React.ReactNode}
    </SignatureKeyDevicesContext.Provider>
  );
};

export const SignatureKeyDevicesContextConsumerHook = (): SignatureKeyDevicesContextType =>
  useContext(SignatureKeyDevicesContext);

/* eslint-disable */
export const SignatureKeyDevicesContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<any>> =>
    (
      <SignatureKeyDevicesContextProvider>
        <Component {...props} />
      </SignatureKeyDevicesContextProvider>
    );
