// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { NotificationSettingsModalBuilderReturnTrackingHandlersType } from './types/notifications-settings-modal-builder-return.tracking.handlers.type';

const exitWithoutSavingModalTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'saveNewSettings',
    eventLabel: 'navigationExitWithoutSavingLink',
  },
  type: TrackingEventsEnum.LINK,
});

const closeModalTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'saveNewSettings',
    eventLabel: 'close',
  },
  type: TrackingEventsEnum.LINK,
});

const showModalTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'saveNewSettings',
    eventLabel: 'open',
  },
  type: TrackingEventsEnum.LINK,
});

const NotificationSettingsModalTrackingHandlers = (
  track: TrackBuilderType
): NotificationSettingsModalBuilderReturnTrackingHandlersType => ({
  handleCloseModalTracking: () => track(closeModalTracking()),
  handleExitWithoutSavingModalTracking: () => track(exitWithoutSavingModalTracking()),
  handleShowModalTracking: () => track(showModalTracking()),
});

export { NotificationSettingsModalTrackingHandlers };