// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from 'containers/personal/views/email/components/step2/contexts/constants/personal-email-step2.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalEmailStep2ContextType } from 'containers/personal/views/email/components/step2/contexts/types/personal-email-step2.context.type';

const PersonalEmailStep2Context = createContext<PersonalEmailStep2ContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const PersonalEmailStep2ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalEmailStep2ContextType>> => {
  const [otpId, setOtpId] = useState<string>('');
  const [otpValue, setOtpValue] = useState<string>('');

  return (
    <PersonalEmailStep2Context.Provider value={{ otpId, otpValue, setOtpId, setOtpValue }}>
      {children as React.ReactNode}
    </PersonalEmailStep2Context.Provider>
  );
};

export const PersonalEmailStep2ContextConsumerHook = (): PersonalEmailStep2ContextType =>
  useContext(PersonalEmailStep2Context);

/* eslint-disable */
export const PersonalEmailStep2ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalEmailStep2ContextType>> =>
    (
      <PersonalEmailStep2ContextProvider>
        <Component {...props} />
      </PersonalEmailStep2ContextProvider>
    );
