// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const ProfilePersonalNameEditionComponentStyled = styled.section<{ isCC: boolean }>`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  min-width: ${convertPxsToRems({ pixels: 120, base: 14 })};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ isCC }) => isCC && 'justify-content: space-between;'}
`;
