// Vendors
import React, { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
// Components
import EntrypointAuthorizationComponent from './components/authorization/entrypoint-authorization.component';
// Contexts
import { AppContextProvider } from 'contexts/app.context';
import { FetchErrorContextProvider } from 'contexts/fetch-error/fetch-error.context';
import { ManagerContextProvider } from 'contexts/manager.context';
import { QuickActionsContextProvider } from 'contexts/quick-actions/quick-actions.context';
import { ToastContextProvider } from 'contexts/toast.context';
// Providers
import { CaptchaProvider } from 'contexts/captcha/captcha.context';
import { ErrorProvider } from 'providers/error/error.provider';
import { FeatureFlagsProvider } from 'providers/feature-flags/feature-flags.provider';
import { IncreaseLimitHybridProvider } from 'providers/increase-limit-hybrid/increase-limit-hybrid.provider';
import InternationalizationProvider from 'providers/internationalization/internationalization.provider';
import LocalizationProvider from 'providers/localization/localization.provider';

const EntrypointCommon = (): React.ReactElement => (
  <FeatureFlagsProvider>
    <ErrorBoundary FallbackComponent={ErrorProvider}>
      <ManagerContextProvider>
        <AppContextProvider>
          <ToastContextProvider>
            <QuickActionsContextProvider>
              <FetchErrorContextProvider>
                <IncreaseLimitHybridProvider>
                  <LocalizationProvider>
                    <InternationalizationProvider>
                      <CaptchaProvider>
                        <Suspense fallback={<div />}>
                          <EntrypointAuthorizationComponent />
                        </Suspense>
                      </CaptchaProvider>
                    </InternationalizationProvider>
                  </LocalizationProvider>
                </IncreaseLimitHybridProvider>
              </FetchErrorContextProvider>
            </QuickActionsContextProvider>
          </ToastContextProvider>
        </AppContextProvider>
      </ManagerContextProvider>
    </ErrorBoundary>
  </FeatureFlagsProvider>
);

export default EntrypointCommon;
