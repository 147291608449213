export default {
  ACCESS_KEY_CHANGE_SECOND_STEP_FIRST_PASSWORD_PROPS: {
    assetId: 'symbols_sign',
    assetColor: 'brandB',
    testId: 'second-step-change-access-key-first-password',
    cellsAmount: 4,
  },
  ACCESS_KEY_CHANGE_SECOND_STEP_SECOND_PASSWORD_PROPS: {
    assetId: 'symbols_sign',
    assetColor: 'brandB',
    testId: 'second-step-change-access-key-second-password',
    cellsAmount: 4,
  },
};
