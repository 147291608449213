const PASSWORD_MODULE_ASSET_ID = 'symbols_sign';
const PASSWORD_MODULE_ASSET_COLOR = 'brandB';
const VALIDATION_ID = {
  INPUT_IS_A_SEQUENCE: 'INPUT_IS_A_SEQUENCE',
  INPUT_REPEATS_NUMBER: 'INPUT_REPEATS_NUMBER',
  INPUT_EQUALS_OLD_KEY: 'INPUT_EQUALS_OLD_KEY',
  INPUT_CONTAINS_CUSTOMER_DATE: 'INPUT_CONTAINS_CUSTOMER_DATE',
};

export { PASSWORD_MODULE_ASSET_ID, PASSWORD_MODULE_ASSET_COLOR, VALIDATION_ID };
