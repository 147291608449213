// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';
import { ErrorIdEnumeration } from '../enumerations/error-id.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.home.cbHome.appError.';
const DEFAULT_ERROR_DOMAIN = 'cobranded.services.userSettings.privateArea.cbGeneric.cbError.';

export default {
  [ErrorCodesEnumeration.CARD]: {
    'en-GB': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Beim Versuch, Informationen zu deinen Karten wiederherzustellen, ist ein Problem aufgetreten. Bitte lade die Seite neu.',
      [ErrorIdEnumeration.TITLE]: 'Technisches Problem mit Karten',
    },
    'de-DE': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Beim Versuch, Informationen zu deinen Karten wiederherzustellen, ist ein Problem aufgetreten. Bitte lade die Seite neu.',
      [ErrorIdEnumeration.TITLE]: 'Technisches Problem mit Karten',
    },
  },
  [ErrorCodesEnumeration.DEFAULT]: {
    'en-GB': {
      [ErrorIdEnumeration.BUTTON]: 'Versuche es erneut',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Es ist ein Fehler aufgetreten. Versuche die Seite in ein paar Minuten neu zu laden.',
      [ErrorIdEnumeration.TITLE]: 'Hoppla! Wir haben einen Fehler festgestellt',
    },
    'de-DE': {
      [ErrorIdEnumeration.BUTTON]: 'Versuche es erneut',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Es ist ein Fehler aufgetreten. Versuche die Seite in ein paar Minuten neu zu laden.',
      [ErrorIdEnumeration.TITLE]: 'Hoppla! Wir haben einen Fehler festgestellt',
    },
  },
  [ErrorCodesEnumeration.ME]: {
    'en-GB': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Beim Versuch, Informationen über dich wiederherzustellen, ist ein Problem aufgetreten. Bitte lade die Seite neu.',
      [ErrorIdEnumeration.TITLE]: 'Technisches Problem mit Ihren Informationen',
    },
    'de-DE': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Beim Versuch, Informationen über dich wiederherzustellen, ist ein Problem aufgetreten. Bitte lade die Seite neu.',
      [ErrorIdEnumeration.TITLE]: 'Technisches Problem mit Ihren Informationen',
    },
  },
  [ErrorCodesEnumeration.TRANSLATIONS]: {
    'en-GB': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Beim Versuch, die korrekte Sprache für dich wiederherzustellen, ist ein Problem aufgetreten. Bitte lade die Seite neu.',
      [ErrorIdEnumeration.TITLE]: 'Technisches Problem mit Übersetzungen',
    },
    'de-DE': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]:
        'Beim Versuch, die korrekte Sprache für dich wiederherzustellen, ist ein Problem aufgetreten. Bitte lade die Seite neu.',
      [ErrorIdEnumeration.TITLE]: 'Technisches Problem mit Übersetzungen',
    },
  },
  [ErrorCodesEnumeration.UNKNOWN]: {
    'en-GB': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]: 'Es tut uns leid, es ist ein Fehler aufgetreten.',
      [ErrorIdEnumeration.TITLE]: 'Unbekanntes technisches Problem',
    },
    'de-DE': {
      [ErrorIdEnumeration.BUTTON]: 'Lade die Seite neu',
      [ErrorIdEnumeration.DESCRIPTION]: 'Es tut uns leid, es ist ein Fehler aufgetreten.',
      [ErrorIdEnumeration.TITLE]: 'Unbekanntes technisches Problem',
    },
  },
};
