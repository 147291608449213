// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { SecurityKeysAccessKeyChangeContextConsumerHook } from 'containers/security-keys/views/access-key-change/contexts/security-keys-access-key-change.context';
// Enumerations
import { AccessKeyChangeSecondStepRepeatErrorIdEnumeration } from '../enumerations/security-keys-access-key-change-second-step.enumeration';
// Handlers
import SecurityKeysAccessKeyChangeSecondStepHandlers from '../handlers/security-keys-access-key-change-second-step.handlers';
import SecurityKeysAccessKeyChangeSecondStepTrackingHandlers from '../handlers/security-keys-access-key-change-second-step.tracking.handlers';
import SecurityKeysAccessKeyChangeTrackingHandlers from 'containers/security-keys/views/access-key-change/handlers/security-keys-access-key-change.tracking.handlers';
// Hooks
import { SecurityKeysAccessKeyChangeHook } from 'containers/security-keys/views/access-key-change/hooks/security-keys-access-key-change.hook';
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { AccessKeyChangeStep2NewHookType } from './types/security-keys-access-key-change-second-step.hook.type';


const SecurityKeysAccessKeyChangeSecondStepHook = (): AccessKeyChangeStep2NewHookType => {
  const { formatMessage } = useCbIntl();
  const [inputErrorRepeat, setInputErrorRepeat] =
    useState<AccessKeyChangeSecondStepRepeatErrorIdEnumeration | null>(null);
  const { handleShowHideInputClickTracking } = AppTrackingHook(SecurityKeysAccessKeyChangeTrackingHandlers);
  const {
    accessKey,
    newAccessKey,
    setNewAccessKey,
    repeatNewAccessKey,
    setRepeatNewAccessKey,
    inputError,
    setInputError,
  } = SecurityKeysAccessKeyChangeContextConsumerHook();
  const {
    handleFirstPasswordPositionInputClickTracking,
    handleSecondPasswordPositionInputClickTracking,
    handleOnEnterSecondStepAccessKeyChangeTrackingHandler,
  } = AppTrackingHook(SecurityKeysAccessKeyChangeSecondStepTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleOnEnterSecondStepAccessKeyChangeTrackingHandler();
  }, []);

  return {
    ...SecurityKeysAccessKeyChangeHook(),
    ...SecurityKeysAccessKeyChangeSecondStepHandlers({
      accessKey,
      newAccessKey,
      repeatNewAccessKey,
      setInputError,
      setNewAccessKey,
      setRepeatNewAccessKey,
      setInputErrorRepeat,
    }),
    handleFirstPasswordPositionInputClickTracking,
    handleSecondPasswordPositionInputClickTracking,
    handleShowHideInputClickTracking,
    inputError,
    newAccessKey,
    repeatNewAccessKey,
    inputErrorRepeat,
    formatMessage,
  };
};

export default SecurityKeysAccessKeyChangeSecondStepHook;
