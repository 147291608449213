// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const CONTRACT_MODAL_IMAGE_PROPS = {
  id: 'Attention_Alternative',
  sizes: {
    height: 120,
    width: 120,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'cancel-modal-asset',
};

export { CONTRACT_MODAL_IMAGE_PROPS };
