// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ChallengeDownloadWrapperBuilderReturnTrackingHandlersType } from './types/challenge-download-wrapper-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Constants
import {
  CLICK_BUTTON_TRACKING,
  CLICK_LINK_TRACKING,
  REQUEST_NEW_PHONE_OTP_TRACKING,
  SENT_OTP_CONFIRMATION_TRACKING,
} from './constants/challenge-download-wrapper.handlers.constants';

const sendNumberOtpConfirmationButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_BUTTON_TRACKING,
    eventLabel: SENT_OTP_CONFIRMATION_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const requestNewNumberOtpLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_LINK_TRACKING,
    eventLabel: REQUEST_NEW_PHONE_OTP_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const ChallengeDownloadWrapperTrackingHandlers = (
  track: TrackBuilderType
): ChallengeDownloadWrapperBuilderReturnTrackingHandlersType => ({
  handleSendNumberOtpConfirmationButtonClickTracking: () =>
    track(sendNumberOtpConfirmationButtonClickTrackingHandler()),
  handleRequestNewNumberOtpLinkClickTracking: () =>
    track(requestNewNumberOtpLinkClickTrackingHandler()),
});

export { ChallengeDownloadWrapperTrackingHandlers };
