const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.changeSignatureKey.cbSidebarInfo.';

export default {
  TITLE: `${DOMAIN}title`,
  SIDEBAR_TITLE_1: `${DOMAIN}sidebarTitle1`,
  SIDEBAR_TEXT_1: `${DOMAIN}sidebarText1`,
  SIDEBAR_TITLE_2: `${DOMAIN}sidebarTitle2`,
  SIDEBAR_TEXT_2: `${DOMAIN}sidebarText2`,
  SIDEBAR_TITLE_3: `${DOMAIN}sidebarTitle3`,
  SIDEBAR_TEXT_3: `${DOMAIN}sidebarText3`,
  SIDEBAR_TEXT_4: `${DOMAIN}sidebarText4`,
  SIDEBAR_MAIN_TITLE: `${DOMAIN}sidebarMainTitle`,
};
