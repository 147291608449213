// Constants
import constants from './constants/toast.utils.constants';
// Types
import { ToastComponentType } from '../types/toast.component.type';
import { ToastUtilsType } from './types/toast.utils.type';
import { ToastMessageUtilsType } from './types/toast-message.utils.type';

const toastHasConfigurationDefined = (
  toastConfiguration: ToastComponentType | Record<string, any>
): boolean => Boolean(toastConfiguration?.description && toastConfiguration?.type);

const setupToastConfiguration = ({ error, description }: ToastUtilsType): ToastComponentType => ({
  description,
  ...(error ? constants.ERROR_TOAST_CONFIGURATION : constants.SUCCESS_TOAST_CONFIGURATION),
});

const getToastMessage = ({ error, translations }: ToastMessageUtilsType): string =>
  error ? translations.ERROR_MESSAGE : translations.SUCCESS_MESSAGE;

export { getToastMessage, setupToastConfiguration, toastHasConfigurationDefined };
