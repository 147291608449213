// Vendors
import React from 'react';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  WIZARD_STEP_FOOTER_RIGHT_BUTTON_ASSET_PROPS,
  WIZARD_STEP_FOOTER_BUTTON_PROPS,
  WIZARD_STEP_FOOTER_LEFT_LINK_PROPS,
  WIZARD_STEP_FOOTER_LEFT_BUTTON_PROPS,
  WIZARD_STEP_FOOTER_RIGHT_LINK_PROPS,
} from '../constants/wizard-step-footer.constants';
// Translations
import { CANCEL, NEXT, PREVIOUS } from '../translations/wizard-step-footer.translations';
// Types
import { GetWizardStepFooterConfigurationPropsType } from './types/wizard-step-footer.component.configuration.type';

export const getWizardStepFooterConfiguration = ({
  cancelFlow,
  formatMessage,
  handleLeftEventButtonClick,
  handleRightEventButtonClick,
  handleToggleCancelFlowModalLinkClick,
  hasDefaultLeftButton,
  hasDefaultLeftLink,
  hasDefaultRightButton,
  isFooterDisabled,
  leftButtonConfiguration,
  leftLinkConfiguration,
  rightButtonConfiguration,
  rightLinkConfiguration,
  shouldShowModal,
}: GetWizardStepFooterConfigurationPropsType): React.ComponentProps<
  typeof NavigationFooterComponent
> => {
  const defaultLeftButtonConfiguration = hasDefaultLeftButton
    ? {
        children: formatMessage({ id: PREVIOUS }),
        onClick: handleLeftEventButtonClick,
      }
    : {};
  const defaultRightButtonConfiguration = hasDefaultRightButton
    ? {
        ...WIZARD_STEP_FOOTER_RIGHT_BUTTON_ASSET_PROPS,
        children: formatMessage({ id: NEXT }),
        onClick: handleRightEventButtonClick,
      }
    : {};
  const defaultLeftLinkConfiguration = hasDefaultLeftLink
    ? {
        children: formatMessage({ id: CANCEL }),
        onClick: shouldShowModal ? handleToggleCancelFlowModalLinkClick : cancelFlow,
      }
    : {};

  return {
    leftButtonConfiguration: {
      ...WIZARD_STEP_FOOTER_LEFT_BUTTON_PROPS,
      ...defaultLeftButtonConfiguration,
      disabled: isFooterDisabled,
      ...leftButtonConfiguration,
    },
    rightButtonConfiguration: {
      ...WIZARD_STEP_FOOTER_BUTTON_PROPS,
      ...defaultRightButtonConfiguration,
      disabled: isFooterDisabled,
      ...rightButtonConfiguration,
    },
    leftLinkConfiguration: {
      ...WIZARD_STEP_FOOTER_LEFT_LINK_PROPS,
      ...defaultLeftLinkConfiguration,
      disabled: isFooterDisabled,
      ...leftLinkConfiguration,
    },
    rightLinkConfiguration: {
      ...WIZARD_STEP_FOOTER_RIGHT_LINK_PROPS,
      disabled: isFooterDisabled,
      ...rightLinkConfiguration,
    },
  };
};
