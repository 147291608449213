// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FINANCING_INSURANCES_CANCELLATION_LPI_SERVICE_CONFIGURATION } from './constants/financing-insurances-cancellation-lpi.constants';
// Types
import { CancelLPIContractServicePropsType } from './types/financing-insurances-cancellation-lpi.service.type';
import { CancelLPIContractServiceReturnType } from './types/financing-insurances-cancellation-lpi-return.service.type';

const cancelLPIContractService = async ({
  contractId,
  progressId,
}: CancelLPIContractServicePropsType): Promise<
  [CancelLPIContractServiceReturnType | Record<string, never>, Response]
> =>
  apiUtilsCb.post({
    ...FINANCING_INSURANCES_CANCELLATION_LPI_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    params: { contractId, insuranceType: 'LPI' },
  });

export { cancelLPIContractService };
