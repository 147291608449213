// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const BannerComponentStyled = styled.section<{ backgroundImage: string }>`
  display: flex;
  width: 100%;
  justify-content: center;

  > div {
    background-image: ${({ backgroundImage }) => `url(${backgroundImage})`} !important;
    color: ${({ theme }) => theme.colors.fontPrimaryA};

    ${mobileMediaQuery} {
      background-image: ${({ backgroundImage }) =>
        `url(${backgroundImage.replace('desktop', 'mobile')})`} !important;
      margin: 0 ${({ theme }) => theme.spaces.spacingS};
      width: 100%;
      max-width: none;
    }

    ${tabletMediaQuery} {
      background-image: ${({ backgroundImage }) =>
        `url(${backgroundImage.replace('desktop', 'tablet')})`} !important;
      margin: 0 ${({ theme }) => theme.spaces.spacingS};
      width: 100%;
      max-width: none;
    }
  }
`;
BannerComponentStyled.displayName = 'BannerComponentStyled';

export { BannerComponentStyled };
