// Vendors
import styled from 'styled-components';

export const SettingsCardControlLocationsMapComponentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingXxxl};
  width: 100%;
`;
