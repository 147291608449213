const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.cbSidebar.';
const DOMAIN_CC =
  'cobrandedCC.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.cbSidebar.';

const DESCRIPTION_TITLE_01 = `${DOMAIN}descriptionTitle01`,
  TITLE = `${DOMAIN}title`,
  DESCRIPTION_01 = `${DOMAIN}description01`,
  DESCRIPTION_TITLE_02 = `${DOMAIN}descriptionTitle02`,
  DESCRIPTION_02 = `${DOMAIN}description02`,
  TITLE_CC = `${DOMAIN_CC}title`,
  DESCRIPTION_TITLE_01_CC = `${DOMAIN_CC}descriptionTitle01`,
  DESCRIPTION_01_CC = `${DOMAIN_CC}description01`,
  DESCRIPTION_TITLE_02_CC = `${DOMAIN_CC}descriptionTitle02`,
  DESCRIPTION_02_CC = `${DOMAIN_CC}description02`,
  DESCRIPTION_TITLE_03_CC = `${DOMAIN_CC}descriptionTitle03`,
  DESCRIPTION_03_CC = `${DOMAIN_CC}description03`;

export {
  DESCRIPTION_01_CC,
  DESCRIPTION_01,
  DESCRIPTION_02_CC,
  DESCRIPTION_02,
  DESCRIPTION_03_CC,
  DESCRIPTION_TITLE_01_CC,
  DESCRIPTION_TITLE_01,
  DESCRIPTION_TITLE_02_CC,
  DESCRIPTION_TITLE_02,
  DESCRIPTION_TITLE_03_CC,
  TITLE_CC,
  TITLE,
};
