// Vendors
import { useLocation, useNavigate } from 'react-router-dom';
// Handlers
import TitleSectionRwdBreadcrumbsTrackingHandlers from '../handlers/title-section-rwd-breadcrumbs.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TitleSectionRwdBreadcrumbsHookType } from 'components/title-section/components/rwd/components/breadcrumbs/hooks/types/title-section-rwd-breadcrumbs.hook.type';

const TitleSectionRwdBreadcrumbsHook = (): TitleSectionRwdBreadcrumbsHookType => {
  const { formatMessage } = useCbIntl();
  const { handleBreadcrumbClickTrackingHandler } = AppTrackingHook(
    TitleSectionRwdBreadcrumbsTrackingHandlers
  );

  return {
    formatMessage,
    breadCrumbPath: useLocation()?.state?.breadCrumbPath,
    navigate: useNavigate(),
    handleBreadcrumbClickTrackingHandler,
  };
};

export default TitleSectionRwdBreadcrumbsHook;
