const DOMAIN_BLOCK_CARD = 'cobranded.services.userSettings.privateArea.blockCard.';
const DOMAIN_REPORT_A_PROBLEM =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.';
const DOMAIN_REPORT_A_PROBLEM_WIZARD =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.wizard.';

const ADDITIONAL_INFORMATION_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}additionalInformation.title`;

const BLOCK_CARD_TITLE = `${DOMAIN_BLOCK_CARD}blockCard.description`;

const CONFIRM_BUTTON_LABEL = `${DOMAIN_REPORT_A_PROBLEM}confirmButtonLabel`;

const CONFIRMATION_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}confirmation.description`;

const CONFIRMATION_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}confirmation.title`;

const ERROR_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}error.description`;

const ERROR_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}error.title`;

const PAGE_TITLE = `${DOMAIN_REPORT_A_PROBLEM}pageTitle`;

const SUCCESS_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}success.description`;

const SUCCESS_LINK_LABEL = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}success.linkLabel`;

const PRODUCT_INFORMATION_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}productInformation.title`;

const PRODUCT_INFORMATION_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}productInformation.description`;

const SUCCESS_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}success.title`;

const UPLOAD_DOCUMENTS_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}uploadDocuments.description`;

const UPLOAD_DOCUMENTS_OPTIONAL_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}uploadDocumentsOptional.title`;

const UPLOAD_DOCUMENTS_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD}uploadDocuments.title`;

const INVALID_DATE_ERROR_MESSAGE = `${DOMAIN_REPORT_A_PROBLEM}date.errorMessage.invalidDate`;

export {
  ADDITIONAL_INFORMATION_TITLE,
  BLOCK_CARD_TITLE,
  CONFIRM_BUTTON_LABEL,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  INVALID_DATE_ERROR_MESSAGE,
  PAGE_TITLE,
  PRODUCT_INFORMATION_DESCRIPTION,
  PRODUCT_INFORMATION_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK_LABEL,
  SUCCESS_TITLE,
  UPLOAD_DOCUMENTS_DESCRIPTION,
  UPLOAD_DOCUMENTS_OPTIONAL_TITLE,
  UPLOAD_DOCUMENTS_TITLE,
};
