// Vendors
import { DownloadDocumentStateEnumeration } from '@openbank/cb-ui-commons';
// Types
import { GetDownloadStateType } from './types/get-download-state.type';

const getDownloadState = ({ downloading, downloadedError }: GetDownloadStateType): string => {
  if (downloading) {
    return DownloadDocumentStateEnumeration.DOWNLOADING;
  }

  if (downloadedError) {
    return DownloadDocumentStateEnumeration.RETRY_DOWNLOAD;
  }

  return DownloadDocumentStateEnumeration.DOWNLOAD;
};

export { getDownloadState };
