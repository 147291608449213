const DATA_TESTID_WRAPPER = 'settings-row-toggle-input-currency-wrapper',
  DEFAULT_ROW_INPUT_PLACEHOLDER = '00,00',
  DEFAULT_ROW_INPUT_SUFFIX = '€',
  PARAGRAPH_TEST_ID = 'settings-row-toggle-input-currency-paragraph',
  HELPER_TEXT_TEST_ID = 'settings-row-toggle-input-currency-helper-text';

export {
  DATA_TESTID_WRAPPER,
  DEFAULT_ROW_INPUT_PLACEHOLDER,
  DEFAULT_ROW_INPUT_SUFFIX,
  PARAGRAPH_TEST_ID,
  HELPER_TEXT_TEST_ID,
};
