// Vendors
import React from 'react';
import { isEmpty } from '@openbank/web-ui-framework';
// Components
import { ModalAdressComponent } from '@openbank/cb-ui-commons';
import { AddressModalInputsComponent } from './components/inputs/address-modal-inputs.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  MODAL_BUTTON_PROPS,
  MODAL_LINK_PROPS,
  MODAL_PROPS,
} from './constants/address-modal.constants';
// Hooks
import { useAddressModalHook } from './hooks/address-modal.hook';
// Translations
import {
  ADDRESS_MODAL_CANCEL_BUTTON,
  ADDRESS_MODAL_SAVE_BUTTON,
  ADDRESS_MODAL_TITLE,
} from './translations/address-modal.translations';
// Types
import { AddressModalComponentType } from './types/address-modal.component.type';

export const AddressModalComponent = ({
  defaultValues,
  isOpen,
  onCancel,
  onClose,
  onError,
  onSave,
}: AddressModalComponentType): React.ReactElement => {
  const {
    control,
    errors,
    formatMessage,
    handleReset,
    handleSave,
    handleSubmit,
    isIosDevice,
    isValidating,
    setValue,
  } = useAddressModalHook({
    defaultValues,
    onSave,
  });

  return (
    <ModalAdressComponent
      {...{ ...MODAL_PROPS, isIosDevice }}
      footerConfiguration={{
        buttonConfiguration: {
          ...MODAL_BUTTON_PROPS,
          children: <FormattedMessageComponent id={ADDRESS_MODAL_SAVE_BUTTON} />,
          disabled: !isEmpty(errors) || isValidating,
          onClick: handleSubmit(handleSave),
        },
        linkConfiguration: {
          ...MODAL_LINK_PROPS,
          children: <FormattedMessageComponent id={ADDRESS_MODAL_CANCEL_BUTTON} />,
          onClick: handleReset(onCancel),
        },
      }}
      isOpen={isOpen}
      title={<FormattedMessageComponent id={ADDRESS_MODAL_TITLE} />}
      onClose={handleReset(onClose)}
    >
      <AddressModalInputsComponent
        control={control}
        errors={errors}
        formatMessage={formatMessage}
        isIosDevice={isIosDevice}
        onError={onError}
        setValue={setValue}
      />
    </ModalAdressComponent>
  );
};
