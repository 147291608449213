// Constants
import { OTHER_DOCUMENTS_CODE } from '../constants/upload-document.constants';
// Resolvers
import { rejectDocumentResolver } from 'resolvers/upload-documents/upload-documents.resolvers';
// Utilities
import { isInvalidFile } from '../utils/upload-document.utils';
import {
  getFileId,
  removeFile,
  updateFileWithCurrentState,
  uploadDocumentWithUrl,
} from './utils/upload-document.handlers.utils';
// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
import { DocumentsUploadErrorsEnumeration } from '../enumerations/documents-upload-errors.enumeration';
// Types
import { AddFileHandlerPropsType } from './types/upload-document-add-file-props.handlers.type';
import { DocumentUploadedHandlerPropsType } from './types/upload-document-upload-props.handlers.type';
import { OnCancelUploadHandlerPropsType } from './types/upload-document-on-cancel-upload-props.handlers.type';
import { RemoveFileHandlerPropsType } from './types/upload-document-remove-file-props.handlers.type';
import { UploadDocumentHandlersPropsType } from './types/upload-document-props.handlers.type';
import { UploadDocumentHandlersType } from './types/upload-document.handlers.type';

const addFileHandler = async ({
  docUriController,
  customerId,
  file,
  documentType,
  id,
  onChangeFiles,
  setDocumentUploaded,
  setIsCancelled,
  uploadDocController,
  updateDocController,
  updateFilesOnDB,
}: AddFileHandlerPropsType) => {
  try {
    setIsCancelled(false);
    onChangeFiles(
      updateFileWithCurrentState({
        file,
        fileId: id,
        state: DocumentsUploadStatesEnumeration.LOADING,
      })
    );

    const errorType = isInvalidFile(file);

    if (errorType) {
      onChangeFiles(
        updateFileWithCurrentState({
          errorType,
          fileId: id,
          state: DocumentsUploadStatesEnumeration.BACK_OFFICE_ERROR,
        })
      );
      return;
    }

    const documentId = await uploadDocumentWithUrl({
      controllers: { docUriController, uploadDocController, updateDocController },
      customerId,
      documentType,
      file,
      updateFilesOnDB,
    });

    setDocumentUploaded(documentId);
  } catch (_) {
    setIsCancelled(false);
    onChangeFiles(
      updateFileWithCurrentState({
        fileId: id,
        state: DocumentsUploadStatesEnumeration.SYSTEM_ERROR,
        errorType: DocumentsUploadErrorsEnumeration.SYSTEM_ERROR,
      })
    );
  }
};

const addAnotherFileHandler = ({
  onChangeFiles,
}: Pick<UploadDocumentHandlersPropsType, 'onChangeFiles'>) => {
  onChangeFiles(prev => [
    ...prev,
    {
      id: getFileId(prev.length),
      documentId: undefined,
      documentType: OTHER_DOCUMENTS_CODE,
      file: null,
      state: DocumentsUploadStatesEnumeration.DEFAULT,
    },
  ]);
};

const removeFileHandler = ({
  customerId,
  documentId,
  documentType,
  onChangeFiles,
  reRequestFiles,
}: RemoveFileHandlerPropsType) => {
  rejectDocumentResolver({
    documentId,
  });
  reRequestFiles?.({ customerId, documentId, documentType });

  onChangeFiles(removeFile(documentId));
};

const onCancelUploadHandler = ({
  docUriController,
  setIsCancelled,
  uploadDocController,
  updateDocController,
}: OnCancelUploadHandlerPropsType) => {
  docUriController.abort();
  uploadDocController.abort();
  updateDocController.abort();
  setIsCancelled(true);
};

const documentUploadedHandler = ({
  documentUploaded,
  id,
  isCancelled,
  onChangeFiles,
}: DocumentUploadedHandlerPropsType) => {
  if (documentUploaded) {
    const newState = isCancelled
      ? DocumentsUploadStatesEnumeration.DEFAULT
      : DocumentsUploadStatesEnumeration.UPLOADED;

    const newDocumentId = isCancelled ? undefined : documentUploaded;

    if (isCancelled) {
      removeFile(documentUploaded);
    }

    onChangeFiles(
      updateFileWithCurrentState({
        documentId: newDocumentId,
        fileId: id,
        state: newState,
      })
    );
  }
};

export const UploadDocumentHandlers = ({
  customerId,
  documentId,
  documentUploaded,
  documentType,
  docUriController,
  id,
  isCancelled,
  onChangeFiles,
  reRequestFiles,
  setIsCancelled,
  setDocumentUploaded,
  uploadDocController,
  updateFilesOnDB,
  updateDocController,
}: UploadDocumentHandlersPropsType): UploadDocumentHandlersType => ({
  handleAddFile: (newFile: File | null) =>
    addFileHandler({
      docUriController,
      customerId,
      setIsCancelled,
      setDocumentUploaded,
      file: newFile,
      documentType,
      id,
      onChangeFiles,
      uploadDocController,
      updateFilesOnDB,
      updateDocController,
    }),
  handleAddAnotherFile: () => addAnotherFileHandler({ onChangeFiles }),
  handleRemoveFile: () =>
    removeFileHandler({ customerId, documentId, documentType, onChangeFiles, reRequestFiles }),
  handleCancelUpload: () =>
    onCancelUploadHandler({
      docUriController,
      setIsCancelled,
      uploadDocController,
      updateDocController,
    }),
  handleDocumentUploaded: () =>
    documentUploadedHandler({ documentUploaded, id, isCancelled, onChangeFiles }),
});
