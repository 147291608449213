// Vendors
import { useEffect } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import { AccessPortabilityRequestStep2ContextConsumerHook } from 'containers/personal/views/access-portability/views/request/components/step2/contexts/access-portability-request-step2.context';
// Event handlers
import RequestAccessStep2ChallengeHandlers from '../handlers/request-access-step2-challenge.handlers';
// Types
import { RequestAccessStep2ChallengeHookType } from './types/request-access-step2-challenge.hook.type';

const RequestAccessStep2ChallengeHook = (): RequestAccessStep2ChallengeHookType => {
  const { formatMessage } = useCbIntl();

  const { otpId, otpValue, setOtpValue } = AccessPortabilityRequestStep2ContextConsumerHook(),
    {
      challenged,
      error,
      fetching,
      progressId,
      setChallenged,
      setError,
      setFetching,
      setProgressId,
      setResult,
    } = AccessPortabilityContextConsumerHook();

  const { handleSetupStartedChallengeEvent, ...rest } = RequestAccessStep2ChallengeHandlers({
    otpId,
    otpValue,
    progressId,
    setChallenged,
    setError,
    setFetching,
    setProgressId,
    setResult,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupStartedChallengeEvent();
  }, []);

  return {
    ...rest,
    challenged,
    error,
    fetching,
    formatMessage,
    handleSetupStartedChallengeEvent,
    otpValue,
    setOtpValue,
  };
};

export default RequestAccessStep2ChallengeHook;
