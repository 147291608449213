/* eslint-disable complexity */
// Configurations
import { getErrorAccordingStatus } from './configurations/transactions-table.handlers.configuration';
// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { CardTransactionsErrorHandlersType } from './types/card-transactions-error.handlers.type';
import { TransactionsFetchCardTransactionsHandlerType } from './types/transactions-fetch-card-transactions-handlers.type';
// Utilities
import { scrollFromTop } from '@openbank/cf-ui-framework';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import {
  isValidMetadataContract,
  removeHasTransactionsChallengeSuccess,
  setHasTransactionsChallengeSuccess,
} from '../utils/transactions-table.utils';
import {
  isLegitTransactionsResponse,
  updateElements,
  updateElementsFiltered,
  updateMetadata,
} from './utils/transactions-table.handlers.utils';

const onFetchPsd2TransactionsSuccessHandler = ({
  setShowSecurityCheckModal,
}: Pick<TransactionsFetchCardTransactionsHandlerType, 'setShowSecurityCheckModal'>): void => {
  setHasTransactionsChallengeSuccess();
  setShowSecurityCheckModal?.(false);
};

const fetchCardTransactionsHandler = async ({
  challenge,
  cardId,
  formValuesParams,
  metadata,
  resolver = () => Promise.resolve([]),
  setShowSecurityCheckModal,
  setFetching,
  setChallenge,
  setError,
  setChallengeError,
  setErrorManager,
  setElements,
  setMetadata,
  filter,
  idName,
}: TransactionsFetchCardTransactionsHandlerType): Promise<void> => {
  const scrollValue = window.scrollY;
  setFetching(true);

  const [response, error] = await resolver(
    cardId,
    formValuesParams,
    challenge,
    filter ? null : metadata
  );

  if (error) {
    cardTransactionsErrorHandler({
      error,
      formValuesParams,
      setError,
      setChallengeError,
      setErrorManager,
    });
  } else if (isLegitTransactionsResponse(response)) {
    const { elements, metadata } = response;
    setElements(idName ? updateElementsFiltered(elements, idName) : updateElements(elements));
    isValidMetadataContract(metadata) && setMetadata(updateMetadata(metadata));
    challenge?.progressId && onFetchPsd2TransactionsSuccessHandler({ setShowSecurityCheckModal });
    setTimeout(() => {
      scrollFromTop(scrollValue);
    }, 10);
    setError(null);
  }
  setChallenge?.(null);
  setFetching(false);
};

const cardTransactionsErrorHandler = ({
  error,
  formValuesParams,
  setError,
  setChallengeError,
  setErrorManager,
}: CardTransactionsErrorHandlersType): void => {
  const errorActions = {
    [TransactionsFetchErrorEnumeration.CHALLENGE]: () => {
      removeHasTransactionsChallengeSuccess();
      setError(TransactionsFetchErrorEnumeration.CHALLENGE);
      setChallengeError(true);
    },
    [TransactionsFetchErrorEnumeration.NO_RESULTS]: () =>
      setError(TransactionsFetchErrorEnumeration.NO_RESULTS),
    [TransactionsFetchErrorEnumeration.NO_FILTER_RESULTS]: () =>
      setError(TransactionsFetchErrorEnumeration.NO_FILTER_RESULTS),
    [TransactionsFetchErrorEnumeration.FETCH]: () => {
      if (window.location.pathname === generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS)) {
        setErrorManager(true);
        return;
      }
      setError(TransactionsFetchErrorEnumeration.FETCH);
    },
  };
  const errorAction = errorActions[getErrorAccordingStatus({ error, formValuesParams })];
  typeof errorAction === 'function'
    ? errorAction()
    : setError(getErrorAccordingStatus({ error, formValuesParams }));
};

export {
  fetchCardTransactionsHandler,
  cardTransactionsErrorHandler,
  onFetchPsd2TransactionsSuccessHandler,
};
