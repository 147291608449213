// Vendors
import styled from 'styled-components';
// Utils
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

const IvrWrapperCcComponentStyled = styled.section.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.backgroundPrimaryB};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl};
  width: 100%;
  position: relative;

  ${mobileAndTabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingM};
  }
`;

IvrWrapperCcComponentStyled.displayName = 'IvrWrapperCcComponentStyled';

const IvrWrapperCcListStyled = styled.ul.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingM};
`;

IvrWrapperCcListStyled.displayName = 'IvrWrapperCcListStyled';

const IvrWrapperCcListItemStyled = styled.li.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  padding: 0;
  margin: 0;
`;

IvrWrapperCcListItemStyled.displayName = 'IvrWrapperCcListItemStyled';

const IvrWrapperComponentCcTextStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

IvrWrapperComponentCcTextStyled.displayName = 'IvrWrapperComponentCcTextStyled';

const IvrWrapperCcSeparatorStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingM} 0 ${({ theme }) => theme.spaces.spacingM};
`;

IvrWrapperCcSeparatorStyled.displayName = 'IvrWrapperCcSeparatorStyled';

const IvrWrapperCcTextStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

IvrWrapperCcTextStyled.displayName = 'IvrWrapperCcTextStyled';

const IvrWrapperLastTextCcStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

IvrWrapperLastTextCcStyled.displayName = 'IvrWrapperLastTextCcStyled';

export {
  IvrWrapperCcListItemStyled,
  IvrWrapperCcListStyled,
  IvrWrapperCcSeparatorStyled,
  IvrWrapperCcTextStyled,
  IvrWrapperComponentCcTextStyled,
  IvrWrapperLastTextCcStyled,
  IvrWrapperCcComponentStyled,
};
