// Types
import { IsSelectedType } from './types/is-selected.type';
import { IsShownMessageType } from './types/is-shown-message.type';

const isSelected = ({ value, selected }: IsSelectedType): boolean => value === selected;

const isShownMessage = ({ hasMessage, value, selected }: IsShownMessageType): boolean =>
  !!hasMessage && isSelected({ value, selected });

export { isSelected, isShownMessage };
