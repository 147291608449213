// Vendors
import styled from 'styled-components';
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const UploadDocumentGroupComponentStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileAndTabletMediaQuery} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
