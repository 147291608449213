// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import { TravelPlusHandlers } from '../handlers/travel-plus.handlers';
import { TravelPlusTrackingHandlers } from '../handlers/travel-plus.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TravelPlusHookType } from './types/travel-plus.hook.type';
import { TravelPlusComponentType } from '../types/travel-plus.component.type';

const useTravelPlusHook = (onHide: TravelPlusComponentType['onHide']): TravelPlusHookType => {
  const [fetching, setFetching] = useState(false);

  const { setToastConfiguration } = ToastContextConsumerHook(),
    { setCard } = ManagerContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { id, cmsCardId, travelPlus } = currentCard || {},
    { activated } = travelPlus || {};

  const { handleOpenTravelPlusModalEventTracking, ...trackingHandlers } = AppTrackingHook(
    TravelPlusTrackingHandlers
  );

  useEffect(() => {
    handleOpenTravelPlusModalEventTracking();
  }, []);

  return {
    ...TravelPlusHandlers({
      ...trackingHandlers,
      activated,
      id,
      onHide,
      setCard,
      setFetching,
      setToastConfiguration,
    }),
    activated,
    cmsCardId,
    fetching,
  };
};

export { useTravelPlusHook };
