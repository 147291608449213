// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

const CARD_SETTINGS_RESUME_LINK_LINKS_TEST_IDS = 'card-settings-resume-link-links-item-',
  CARD_SETTINGS_RESUME_LINK_LINKS_INVALID_STATUS = [
    CardStatusTypesEnumeration.CANCELLED,
    CardStatusTypesEnumeration.BLOCKED,
    CardStatusTypesEnumeration.LOCKED,
    CardStatusTypesEnumeration.INACTIVE,
    CardStatusTypesEnumeration.TEMPORARILY_BLOCKED,
  ];

export { CARD_SETTINGS_RESUME_LINK_LINKS_TEST_IDS, CARD_SETTINGS_RESUME_LINK_LINKS_INVALID_STATUS };
