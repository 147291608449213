// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_RESUME_LINK_VALUE_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.M,
  },
  CARD_SETTINGS_RESUME_LINK_LABEL_DEFAULT_TESTING_PROPS = 'card-settings-resume-link-label';

export {
  CARD_SETTINGS_RESUME_LINK_VALUE_PROPS,
  CARD_SETTINGS_RESUME_LINK_LABEL_DEFAULT_TESTING_PROPS,
};
