// Vendors
import styled from 'styled-components';
import { tabletMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardSpentSummaryComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
  text-align: center;

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingXxxxs};
    padding-right: ${({ theme }) => theme.spaces.spacingS};
  }
`;
