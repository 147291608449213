const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.ppi.';

const INSURANCE_STATUS = `${DOMAIN}insuranceStatusLabel`;
const TITLE = `${DOMAIN}benefitAndInsuranceTitle`;

const ACTIVE = `${DOMAIN}status.active`;
const INACTIVE = `${DOMAIN}status.inactive`;
const COVERED = `${DOMAIN}status.covered`;

export { ACTIVE, COVERED, INACTIVE, INSURANCE_STATUS, TITLE };
