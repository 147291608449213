// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const PERSONAL_PHOTO_AVATARS_ITEM_PROPS = {
  extension: assetConstants.EXTENSIONS.PNG,
  sizes: {
    height: 50,
    width: 50,
  },
  type: assetConstants.TYPES.IMAGE,
};

const PERSONAL_PHOTO_AVATARS_DESCRIPTION_PROPS = {
  testId: 'personal-photo-avatars-description',
};

const PERSONAL_PHOTO_AVATARS_ITEM_TEST_ID = 'personal-photo-avatars-item-';

export {
  PERSONAL_PHOTO_AVATARS_ITEM_TEST_ID,
  PERSONAL_PHOTO_AVATARS_ITEM_PROPS,
  PERSONAL_PHOTO_AVATARS_DESCRIPTION_PROPS,
};
