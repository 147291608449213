// Types
import {
  FindOptionByValueReturnType,
  FindOptionValuePropsType,
  FormatSelectOptionsPropsType,
  FormatSelectOptionsReturnType,
} from './types/filter-modal-input-select.utils.type';

const findOptionByValue = ({
  value,
  options,
}: FindOptionValuePropsType): FindOptionByValueReturnType => {
  const option = options.find(option => option?.value === value);
  if (option) {
    return { assetId: option.assetConfiguration.id, label: option.label };
  }
  return { assetId: '', label: '' };
};

const formatSelectOptions = ({
  options,
  formatMessage,
}: FormatSelectOptionsPropsType): FormatSelectOptionsReturnType =>
  options.map(option => ({
    ...option,
    label: formatMessage({ id: option.label }),
  }));

export { findOptionByValue, formatSelectOptions };
