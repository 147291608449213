// Enumerations
import { MonthsEnumeration } from 'enumerations/months.enumeration';
import { TransactionsTableListGroupsEnumeration } from '../enumerations/transactions-table-list-groups.enumeration';
// Translations
import monthsTranslations from 'translations/months.translations';

const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.financingLanding.cbTable.';

export default {
  // TODO: Remove at the end of the task credit-limit-increase
  // [TransactionsTableListGroupsEnumeration.JANUARY]: `${DOMAIN}groupJanuary`,
  // [TransactionsTableListGroupsEnumeration.FEBRUARY]: `${DOMAIN}groupFebruary`,
  // [TransactionsTableListGroupsEnumeration.MARCH]: `${DOMAIN}groupMarch`,
  // [TransactionsTableListGroupsEnumeration.APRIL]: `${DOMAIN}groupApril`,
  // [TransactionsTableListGroupsEnumeration.MAY]: `${DOMAIN}groupMay`,
  // [TransactionsTableListGroupsEnumeration.JUNE]: `${DOMAIN}groupJune`,
  // [TransactionsTableListGroupsEnumeration.JULY]: `${DOMAIN}groupJuly`,
  // [TransactionsTableListGroupsEnumeration.AUGUST]: `${DOMAIN}groupAugust`,
  // [TransactionsTableListGroupsEnumeration.SEPTEMBER]: `${DOMAIN}groupSeptember`,
  // [TransactionsTableListGroupsEnumeration.OCTOBER]: `${DOMAIN}groupOctober`,
  // [TransactionsTableListGroupsEnumeration.NOVEMBER]: `${DOMAIN}groupNovember`,
  // [TransactionsTableListGroupsEnumeration.DECEMBER]: `${DOMAIN}groupDecember`,

  [TransactionsTableListGroupsEnumeration.JANUARY]: monthsTranslations[MonthsEnumeration.JANUARY],
  [TransactionsTableListGroupsEnumeration.FEBRUARY]: monthsTranslations[MonthsEnumeration.FEBRUARY],
  [TransactionsTableListGroupsEnumeration.MARCH]: monthsTranslations[MonthsEnumeration.MARCH],
  [TransactionsTableListGroupsEnumeration.APRIL]: monthsTranslations[MonthsEnumeration.APRIL],
  [TransactionsTableListGroupsEnumeration.MAY]: monthsTranslations[MonthsEnumeration.MAY],
  [TransactionsTableListGroupsEnumeration.JUNE]: monthsTranslations[MonthsEnumeration.JUNE],
  [TransactionsTableListGroupsEnumeration.JULY]: monthsTranslations[MonthsEnumeration.JULY],
  [TransactionsTableListGroupsEnumeration.AUGUST]: monthsTranslations[MonthsEnumeration.AUGUST],
  [TransactionsTableListGroupsEnumeration.SEPTEMBER]:
    monthsTranslations[MonthsEnumeration.SEPTEMBER],
  [TransactionsTableListGroupsEnumeration.OCTOBER]: monthsTranslations[MonthsEnumeration.OCTOBER],
  [TransactionsTableListGroupsEnumeration.NOVEMBER]: monthsTranslations[MonthsEnumeration.NOVEMBER],
  [TransactionsTableListGroupsEnumeration.DECEMBER]: monthsTranslations[MonthsEnumeration.DECEMBER],
  [TransactionsTableListGroupsEnumeration.THIS_MONTH]: `${DOMAIN}groupThisMonth`,
};
