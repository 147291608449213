// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Handlers
import { NotificationsSettingsHandlers } from '../handlers/notifications-settings-list.handlers';
import { NotificationsSettingsTrackingHandlers } from '../handlers/notifications-settings-list-tracking.handlers';
// Hooks
import { ToastContextConsumerHook } from 'contexts/toast.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { NotificationsSettingsFormattedType } from 'containers/notifications/views/notifications-settings/components/list/types/notification-settings-formatted.type';
import { NotificationsSettingsHookReturnType } from './types/notifications-settings.hook.type';

const NotificationsSettingsHook = (): NotificationsSettingsHookReturnType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { setToastConfiguration } = ToastContextConsumerHook();
  const [notificationsSettings, setNotificationsSettings] = useState<NotificationsSettingsFormattedType | null>(null);
  const [hasChangedSettings, setHasChangedSettings] = useState<boolean>(false);
  const { formatMessage, formatNumber } = useCbIntl();

  const { 
    handleShowToolTipTracking,
    ...restTracking
  } = AppTrackingHook(NotificationsSettingsTrackingHandlers);
  
  const { 
    handleFetchNotificationsSettings,
    ...rest
  } = NotificationsSettingsHandlers({
    hasChangedSettings,
    navigate: useNavigate(),
    notificationsSettings,
    setError,
    setFetching,
    setHasChangedSettings,
    setNotificationsSettings,
    setSaving,
    setShowModal,
    setToastConfiguration,
    ...restTracking,
  });

  useEffect(() => {
    handleFetchNotificationsSettings();
  }, []);

  return {
    ...rest,
    error,
    fetching,
    formatMessage,
    formatNumber,
    handleShowToolTipTracking,
    hasChangedSettings,
    notificationsSettings,
    saving,
    setHasChangedSettings,
    setShowModal,
    showModal,
  };

};

export { NotificationsSettingsHook };