// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { WizardFeedbackErrorTrackingHandlersType } from './types/wizard-feedback-error.tracking.handlers.type';

const wizardFeedbackErrorButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'tryAgain',
  },
  type: TrackingEventsEnum.LINK,
});

const wizardFeedbackErrorLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancel',
  },
  type: TrackingEventsEnum.LINK,
});

const WizardFeedbackErrorTrackingHandlers = (
  track: TrackBuilderType
): WizardFeedbackErrorTrackingHandlersType => ({
  handleWizardFeedbackErrorButtonClickTracking: () =>
    track(wizardFeedbackErrorButtonClickTrackingHandler()),
  handleWizardFeedbackErrorLinkClickTracking: () =>
    track(wizardFeedbackErrorLinkClickTrackingHandler()),
});

export default WizardFeedbackErrorTrackingHandlers;
