// Vendors
import React from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
// Constants
import { PARAGRAPH_PROPS } from './constants/financing-early-repayment-second-step-summary.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Hooks
import FinancingPaymentMethodSecondStepSummaryHook from './hooks/financing-early-repayment-second-step-summary.hook';
// Styles
import { FinancingEarlyRepaymentSecondStepSummaryComponentStyled } from './financing-early-repayment-second-step-summary.component.styled';
// Translations
import {
  AMOUNT_PAID,
  CONSUMPTION_CONCEPT,
  CREATION_DATE,
  INSTALMENTS_PAID,
  INSTALMENTS_PAID_VALUE,
  ORIGINAL_AMOUNT,
  OUTSTANDING_AMOUNT,
} from './translations/financing-early-repayment-second-step-summary.translations';

const FinancingEarlyRepaymentSecondStepSummaryComponent = (): React.ReactElement | null => {
  const { eppDetails, formatMessage } = FinancingPaymentMethodSecondStepSummaryHook();

  if (!eppDetails) {
    return null;
  }

  const {
      amortizationTable,
      eppCreationDate,
      originalAmount,
      paidAmount,
      pendingAmount,
      transactionInfo,
    } = eppDetails,
    { numOfInstallments, numOfPaidInstallments } = amortizationTable,
    { description } = transactionInfo;

  return (
    <FinancingEarlyRepaymentSecondStepSummaryComponentStyled>
      <ProductDetailComponent title={formatMessage({ id: CONSUMPTION_CONCEPT })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>{description}</ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent title={formatMessage({ id: CREATION_DATE })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>
          <FormattedDate
            {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
            value={eppCreationDate}
          />
        </ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent title={formatMessage({ id: INSTALMENTS_PAID })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>
          <FormattedMessage
            id={INSTALMENTS_PAID_VALUE}
            values={{ numOfInstallments, numOfPaidInstallments }}
          />
        </ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent title={formatMessage({ id: ORIGINAL_AMOUNT })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>
          <FormattedNumber {...EURO_FORMAT_OPTIONS} value={originalAmount} />
        </ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent title={formatMessage({ id: AMOUNT_PAID })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>
          <FormattedNumber {...EURO_FORMAT_OPTIONS} value={paidAmount} />
        </ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent title={formatMessage({ id: OUTSTANDING_AMOUNT })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>
          <FormattedNumber {...EURO_FORMAT_OPTIONS} value={pendingAmount} />
        </ParagraphComponent>
      </ProductDetailComponent>
    </FinancingEarlyRepaymentSecondStepSummaryComponentStyled>
  );
};

export default FinancingEarlyRepaymentSecondStepSummaryComponent;
