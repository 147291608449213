// Vendors
import { useEffect } from 'react';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import { Step4UploadDocumentationTracking } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/trackings/step-4-upload-documentation.tracking';
// Types
import { InformationModalComponentType } from '../types/information-modal.component.type';
import { InformationModalHookType } from './types/information-modal.component.hook.type';

export const InformationModalHook = (
  setShowInfoModal: InformationModalComponentType['setShowInfoModal']
): InformationModalHookType => {
  const {
    handleOpenTipsModalTracking,
    handleCloseTipsModalTracking,
    handleClickButtonTipsModalTracking,
  } = AppTrackingHook(Step4UploadDocumentationTracking);

  useEffect(handleOpenTipsModalTracking, []);

  return {
    onHeaderClose: () => {
      handleCloseTipsModalTracking();
      setShowInfoModal(false);
    },
    onClickPrimaryButton: () => {
      handleClickButtonTipsModalTracking();
      setShowInfoModal(false);
    },
  };
};
