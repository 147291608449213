// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalPhotoNameButtonUploadModalBuilderReturnTrackingHandlersType } from './types/personal-photo-name-button-upload-modal-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const applyCropPhotoButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'apply',
  },
  type: TrackingEventsEnum.LINK,
});

const cancelCropPhotoLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancel',
  },
  type: TrackingEventsEnum.LINK,
});

const closeCropUploadedPhotoModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cropUploadedPhotoModalClose',
  },
  type: TrackingEventsEnum.LINK,
});

const openCropUploadedPhotoModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cropUploadedPhotoModalOpen',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalPhotoNameButtonUploadModalTrackingHandlers = (
  track: TrackBuilderType
): PersonalPhotoNameButtonUploadModalBuilderReturnTrackingHandlersType => ({
  handleApplyCropPhotoButtonClickTracking: () => track(applyCropPhotoButtonClickTrackingHandler()),
  handleCloseCropUploadedPhotoModalTracking: () =>
    track(closeCropUploadedPhotoModalTrackingHandler()),
  handleCancelCropPhotoLinkClickTracking: () => track(cancelCropPhotoLinkClickTrackingHandler()),
  handleOpenCropUploadedPhotoModalTracking: () =>
    track(openCropUploadedPhotoModalTrackingHandler()),
});

export default PersonalPhotoNameButtonUploadModalTrackingHandlers;
