// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/settings-card-control-toggle.services.constants';
// Types
import { SettingsCardControlToggleApiBuilderType } from '../types/settings-card-control-toggle-api-builder.type';

const changeCardControlsControlService = async ({
  cardId,
  id,
  value,
}: SettingsCardControlToggleApiBuilderType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.patch({
    ...constants.CHANGE_CARD_CONTROLS_SERVICE_CONFIGURATION,
    pathParams: { cardId },
    params: { [id]: value },
  });

export { changeCardControlsControlService };
