// Vendors
import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
// Event handlers
import { PublicInteractionsHandlers } from '../handlers/public-interactions.handlers';

const usePublicInteractionsHook = (): void => {
  const { pathname } = useLocation(),
    [searchParams] = useSearchParams();

  const { handleCheckAnyPublicSitePrivateAreaInteractions } = PublicInteractionsHandlers({
    pathname,
    searchParams,
  });

  useEffect(() => {
    handleCheckAnyPublicSitePrivateAreaInteractions();
  }, []);
};

export { usePublicInteractionsHook };
