// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import LanguageHandlers from '../handlers/language.handlers';

const LanguageHook = (): any => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { setLanguagePreference } = AppContextConsumerHook();

  const { handleFetchLayoutLanguage } = LanguageHandlers({ setLanguagePreference, setFetching });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchLayoutLanguage();
  }, []);

  return { fetching };
};

export default LanguageHook;
