// Vendors
import styled from 'styled-components';

export const RequestPortabilityStep1ContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const RequestPortabilityStep1ListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingM};
  margin: 0;
`;

export const RequestPortabilityStep1ToggleContainerStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const RequestPortabilityStep1ToggleLabelsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const RequestPortabilityStep1ListItemStyled = styled.li`
  padding: 0;
  margin: 0;
`;
