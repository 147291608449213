// Services
import { getTrustedDeviceStatusService } from '../services/request-signature-key-wizard.service';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const getTrustedDeviceStatusResolver = async (): Promise<[true] | [null, true]> => {
  const [, { status }] = await getTrustedDeviceStatusService();

  return !requestRespondedNoContent(status) ? [true] : [null, true];
};

export { getTrustedDeviceStatusResolver };
