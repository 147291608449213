// Vendors
import styled from 'styled-components';

const WizardStepFooterFeedbackCancelModalTextComponentStyled = styled.div`
  margin: auto;
  text-align: center;
`;

WizardStepFooterFeedbackCancelModalTextComponentStyled.displayName =
  'WizardStepFooterFeedbackCancelModalTextComponentStyled';

export { WizardStepFooterFeedbackCancelModalTextComponentStyled };
