// Contexts
import { useCardSettingsChangePinContextConsumerHook } from 'containers/card-settings/views/change-pin/contexts/card-settings-change-pin.context';
// Event handlers
import CardSettingsChangePinFeedbackHandlers from '../handlers/card-settings-change-pin-feedback.handlers';
// Types
import { CardSettingsChangePinFeedbackHookType } from './types/card-settings-change-pin-feedback.hook.type';

const useCardSettingsChangePinFeedbackHook = (): CardSettingsChangePinFeedbackHookType => {
  const { setNewPin, setRepeatedPin, setSolvedChallenged, setChallenged } =
    useCardSettingsChangePinContextConsumerHook();

  return {
    ...CardSettingsChangePinFeedbackHandlers({
      setNewPin,
      setRepeatedPin,
      setSolvedChallenged,
      setChallenged,
    }),
  };
};

export default useCardSettingsChangePinFeedbackHook;
