import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export enum ProblemsEnumeration {
  NOT_RECOGNIZE_CHARGE = RoutesEnumeration.NOT_RECOGNIZE_CHARGE,
  DIFFERENT_AMOUNT_ATM = RoutesEnumeration.DIFFERENT_AMOUNT_ATM,
  DUPLICATED_CHARGE = RoutesEnumeration.DUPLICATED_CHARGE,
  DEFECTIVE_PRODUCT = RoutesEnumeration.DEFECTIVE_PRODUCT,
  NOT_RECEIVED_PRODUCT = RoutesEnumeration.NOT_RECEIVED_PRODUCT,
  CHARGED_SERVICE_CANCELLED = RoutesEnumeration.CHARGED_SERVICE_CANCELLED,
  SUBSCRIPTION_PASSED = RoutesEnumeration.SUBSCRIPTION_PASSED,
  PAYMENT_OTHER_MEANS = RoutesEnumeration.PAYMENT_OTHER_MEANS,
}
