/* eslint-disable jsx-a11y/media-has-caption */
// Vendors
import React from 'react';
// Components
import { ButtonComponent, TitleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  NOT_FOUND_BUTTON_PROPS,
  NOT_FOUND_DESCRIPTION_PROPS,
  NOT_FOUND_TITLE_PROPS,
  NOT_FOUND_VIDEO_PROPS,
  NOT_FOUND_VIDEO_SOURCE_PROPS,
} from './constants/not-found.constants';
// Hooks
import { useNotFoundHook } from './hooks/not-found.hook';
// Styles
import {
  NotFoundContainerStyled,
  NotFoundContentContainerStyled,
} from './not-found.container.styled';
// Translations
import { DESCRIPTION, TITLE, GO_BACK } from './translations/not-found.translations';

const NotFoundContainer = (): React.ReactElement => {
  const { handleGoBackFrom404ViewButtonClick } = useNotFoundHook();

  return (
    <NotFoundContainerStyled>
      <video {...NOT_FOUND_VIDEO_PROPS}>
        <source {...NOT_FOUND_VIDEO_SOURCE_PROPS} />
      </video>
      <NotFoundContentContainerStyled>
        <TitleComponent {...NOT_FOUND_TITLE_PROPS}>
          <FormattedMessageComponent id={TITLE} />
        </TitleComponent>
        <TitleComponent {...NOT_FOUND_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </TitleComponent>
        <ButtonComponent {...NOT_FOUND_BUTTON_PROPS} onClick={handleGoBackFrom404ViewButtonClick}>
          <FormattedMessageComponent id={GO_BACK} />
        </ButtonComponent>
      </NotFoundContentContainerStyled>
    </NotFoundContainerStyled>
  );
};

export { NotFoundContainer };
