// Handlers
import { TransactionsReportFraudulentMovementBlockCardActionsHandlers } from '../handlers/transactions-report-fraudulent-movement-block-card-actions.handlers';
// Hooks
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Types
import { UseTransactionsReportFraudulentMovementBlockCardActionsHookType } from './types/transactions-report-fraudulent-movement-block-card-actions-hook.type';

const useFraudulentMovementBlockCardActionsHook =
  (): UseTransactionsReportFraudulentMovementBlockCardActionsHookType => {
    const { blockReason, setBlockReason, setSelectedAddress } = BlockCardContextConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();
    const { handleGoBack } = TransactionsReportFraudulentMovementBlockCardActionsHandlers({
      setBlockReason,
      setCurrentStep,
      setSelectedAddress,
    });

    return {
      blockReason,
      handleGoBack,
    };
  };

export { useFraudulentMovementBlockCardActionsHook };
