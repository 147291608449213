import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const TitleSectionRwdBreadcrumbsComponentStyled = styled.header`
  ${mobileMediaQuery} {
    overflow: auto;
    white-space: pre;
    width: 100%;
  }
`;

export { TitleSectionRwdBreadcrumbsComponentStyled };
