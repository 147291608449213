// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  ACCOUNT_AGGREGATION_URL_SUFFIX,
  ACCOUNT_COMPLETE_URL_SUFFIX,
  TINK_ERROR_URL_SUFFIX,
} from './constants/step-4-account-aggregation.services.constants';
import {
  LIMIT_INCREASE_CASES_URL,
  LIMIT_INCREASE_URL,
  TINK_EXTEND_TOKEN_SERVICE_URL,
} from './constants/commons.constants';
// Types
import { CaseInitResponseType } from './types/step-0-init.type';
import { AccountAggregationPropsType } from '../resolvers/types/step-4-account-aggregation-props.resolver.type';
import { SendTinkErrorParamsType } from '../resolvers/types/send-tink-error-params.type';
// Utils
import { getAggregationCallback } from './utils/step-0-init.services.utils';

const accountAggregationService = ({
  caseId,
  tinkData,
}: AccountAggregationPropsType): Promise<[CaseInitResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${ACCOUNT_AGGREGATION_URL_SUFFIX}`,
    params: {
      ...tinkData,
      aggregationCallback: getAggregationCallback(),
    },
  });

const accountAggregationGoNextService = (
  caseId: string
): Promise<[CaseInitResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${ACCOUNT_COMPLETE_URL_SUFFIX}`,
  });

const accountAggregationExtendTokenService = (): Promise<[null, Response]> =>
  apiUtilsCb.get({
    endpoint: TINK_EXTEND_TOKEN_SERVICE_URL,
  });

const sendTinkErrorService = (params: SendTinkErrorParamsType): Promise<[null, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_URL}${TINK_ERROR_URL_SUFFIX}`,
    params,
  });

export {
  accountAggregationService,
  accountAggregationGoNextService,
  accountAggregationExtendTokenService,
  sendTinkErrorService,
};
