// Resolvers
import { fetchContractingPPIDocumentResolver } from '../resolvers/financing-ppi-contracting-download.resolver';
// Types
import {
  FinancingPPIContractingDownloadBuilderHandlersType,
  FinancingPPIContractingDownloadMethodsHandlersType,
} from './types/financing-ppi-contracting-download.handlers.type';
// Utilities
import { createTagAndsDispatchFile } from '@openbank/cf-ui-framework';
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const updateIfNeverDownloaded =
  (error?: boolean): ((prevDownloaded: boolean) => boolean) =>
  (prevDownloaded: boolean): boolean =>
    prevDownloaded ? prevDownloaded : Boolean(!error);

const downloadContractingPPIDocumentClickHandler = async ({
  documentName: filename,
  handleDownloadContractingPPIDocumentClickTracking,
  setFetching,
  setErrorDocumentDownloaded,
  setDocumentDownloaded,
  setToastConfiguration,
  translations,
  ...serviceProps
}: FinancingPPIContractingDownloadBuilderHandlersType): Promise<void> => {
  setFetching(true);
  setErrorDocumentDownloaded(false);
  handleDownloadContractingPPIDocumentClickTracking();

  const [blob, error] = await fetchContractingPPIDocumentResolver(serviceProps);

  blob && createTagAndsDispatchFile({ blob, filename });
  setDocumentDownloaded(updateIfNeverDownloaded(error));
  setFetching(false);
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations }) })
  );
};

const FinancingPPIContractingDownloadHandlers = (
  props: FinancingPPIContractingDownloadBuilderHandlersType
): FinancingPPIContractingDownloadMethodsHandlersType => ({
  handleDownloadContractingPPIDocumentClick: () =>
    downloadContractingPPIDocumentClickHandler(props),
});

export { FinancingPPIContractingDownloadHandlers };
