// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/manager.context.constants';
// Types
import { PaginationType, ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ManagerContextType, NotificationsDataType } from './types/manager.context.type';

const ManagerContext = createContext<ManagerContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const ManagerContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ManagerContextType>> => {
  const [card, setCard] = useState<boolean>(false);
  const [cardDetail, setCardDetail] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [me, setMe] = useState<boolean>(false);
  const [pageLoader, setPageLoader] = useState<boolean>(false);
  const [notificationsData, setNotificationsData] = useState<NotificationsDataType>(null);
  const [lastSession, setLastSession] = useState<string>('');

  return (
    <ManagerContext.Provider
      value={{
        card,
        cardDetail,
        fetching,
        error,
        me,
        pageLoader,
        setCard,
        setCardDetail,
        setFetching,
        setError,
        setMe,
        setPageLoader,
        notificationsData,
        setNotificationsData,
        lastSession,
        setLastSession,
      }}
    >
      {children as React.ReactNode}
    </ManagerContext.Provider>
  );
};

export const ManagerContextConsumerHook = (): ManagerContextType => useContext(ManagerContext);
