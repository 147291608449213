// Vendors
import { useEffect, useState } from 'react';
// Constants
import { EMPTY_STRING } from '../constants/report-defective-product-info.component.constants';
// Contexts
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useReportDefectiveContextConsumerHook } from 'containers/transactions/views/report/components/defective/contexts/report-defective.context';
// Handlers
import { ReportDefectiveProductInfoHandler } from '../handlers/report-defective-product-info.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ReportDefectiveProductInfoHookReturnType } from './types/report-defective-product-info.hook.type';

const ReportDefectiveProductInfoHook = (): ReportDefectiveProductInfoHookReturnType => {
  const { formatDate, formatMessage } = useCbIntl();
  const { data, setData } = useReportDefectiveContextConsumerHook();
  const { setCurrentStep } = useWizardContextConsumerHook();

  const { defectiveProductService } = data || {},
    { defectProductDateReturn, defectProductDescriptionProduct, defectProductDescriptionProblem } =
      defectiveProductService || {};

  const { transactionDetails } = useTransactionsReportContextConsumerHook();

  const [date, setDate] = useState<string | Date>(defectProductDateReturn || EMPTY_STRING);
  const [productDescription, setProductDescription] = useState<string>(
    defectProductDescriptionProduct || EMPTY_STRING
  );
  const [productProblem, setProductProblem] = useState<string>(
    defectProductDescriptionProblem || EMPTY_STRING
  );
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState<boolean>(false);
  const [dateError, setDateError] = useState<string>(EMPTY_STRING);

  const { handleNextButtonEnabled, handleGoBack, ...defectiveProductHandlers } =
    ReportDefectiveProductInfoHandler({
      data,
      date,
      dateError,
      formatDate,
      formatMessage,
      productDescription,
      productProblem,
      setCurrentStep,
      setData,
      setDate,
      setDateError,
      setIsNextButtonEnabled,
      setProductDescription,
      setProductProblem,
      transactionDetails,
    });

  useEffect(() => {
    handleNextButtonEnabled();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productProblem, productDescription, date]);

  return {
    ...defectiveProductHandlers,
    data,
    date,
    dateError,
    formatMessage,
    handleGoBack,
    isNextButtonEnabled,
    productDescription,
    productProblem,
    setProductDescription,
    setProductProblem,
    transactionDetails,
  };
};

export { ReportDefectiveProductInfoHook };
