// Enumerations
import { LoginViewsEnumeration } from '../../enumerations/login.enumeration';

export default {
  CONTEXT_DEFAULT_VALUE: {
    autoLogin: false,
    email: '',
    loginView: LoginViewsEnumeration.EMAIL,
    rememberMe: false,
    userAlias: null,
    error: undefined,
    setError: (): undefined => undefined,
    setUserAlias: (): null => null,
    setRememberMe: (): null => null,
    setLoginView: (): null => null,
    setEmail: (): null => null,
    setAutoLogin: (): null => null,
  },
};
