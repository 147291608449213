// Types
import { SecurityKeysAccessKeyChangeFirstStepActionsUtilsType } from './types/security-keys-access-key-change-first-step-actions.utils.type';
// Utilities
import { checkHasFourNumbersOnly } from 'containers/security-keys/views/access-key-change/utils/security-keys-access-key-change-constraints.util';

const modifyAccessKeyChangeStep1NextButtonIsDisabled = ({
  accessKey,
}: SecurityKeysAccessKeyChangeFirstStepActionsUtilsType): boolean => Boolean(!checkHasFourNumbersOnly(accessKey));

export { modifyAccessKeyChangeStep1NextButtonIsDisabled };
