// Vendors
import React from 'react';
// Constants
import {
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_RETRY_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_ROTATE_PROPS,
} from './constants/personal-photo-name-button-upload-modal-buttons.constants';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import usePersonalPhotoNameButtonUploadModalButtonHook from './hooks/personal-photo-name-button-upload-modal-buttons.hook';
// Styles
import { PersonalPhotoNameButtonUploadModalButtonsComponentStyled } from './personal-photo-name-button-upload-modal-buttons.component.styled';
// Translations
import { UPLOAD_ANOTHER_PHOTO } from './translations/personal-photo-name-button-upload-modal-buttons.translations';
// Types
import { PersonalPhotoNameButtonUploadModalButtonsComponentType } from './types/personal-photo-name-button-upload-modal-buttons.component.type';

const PersonalPhotoNameButtonUploadModalButtonsComponent = ({
  onRetry,
}: PersonalPhotoNameButtonUploadModalButtonsComponentType): React.ReactElement | null => {
  const { handleUploadAnotherPhotoButtonClick, handleRotatePhotoButtonClick } =
    usePersonalPhotoNameButtonUploadModalButtonHook(onRetry);

  return (
    <PersonalPhotoNameButtonUploadModalButtonsComponentStyled>
      <ButtonComponent
        {...PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_RETRY_PROPS}
        onClick={handleUploadAnotherPhotoButtonClick}
      >
        <FormattedMessageComponent id={UPLOAD_ANOTHER_PHOTO} />
      </ButtonComponent>
      <ButtonComponent
        {...PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTONS_ROTATE_PROPS}
        onClick={handleRotatePhotoButtonClick}
      />
    </PersonalPhotoNameButtonUploadModalButtonsComponentStyled>
  );
};

export default PersonalPhotoNameButtonUploadModalButtonsComponent;
