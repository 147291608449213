// Contexts
import { useFinancingPPIContractingStep2ContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/components/step2/contexts/financing-ppi-contracting-step2.context';
// Event handlers
import { FinancingPPIContractingStep2ConsentHandlers } from '../handlers/financing-ppi-contracting-step2-consent.handlers';
import { FinancingPPIContractingStep2ConsentTrackingHandlers } from '../handlers/financing-ppi-contracting-step2-consent.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep2ConsentType } from './types/financing-ppi-contracting-step2-consent.type';
import { FinancingPPIContractingStep2ConsentComponentType } from '../types/financing-ppi-contracting-step2-consent.component.type';

const useFinancingPPIContractingStep2ConsentHook = (
  consentId: FinancingPPIContractingStep2ConsentComponentType['consentId']
): FinancingPPIContractingStep2ConsentType => {
  const { checksDone, errorChecksDone, setChecksDone } =
    useFinancingPPIContractingStep2ContextConsumerHook();

  return {
    ...FinancingPPIContractingStep2ConsentHandlers({
      ...AppTrackingHook(FinancingPPIContractingStep2ConsentTrackingHandlers),
      consentId,
      setChecksDone,
    }),
    checksDone,
    errorChecksDone,
  };
};

export { useFinancingPPIContractingStep2ConsentHook };
