// Types
import { CardSettingsStatementsRowDetailsComponentType } from './types/card-settings-statements-table-row-details.utils.type';

const showCardStatementDetailsResumeItem = ({
  composeData,
  showAlways,
  value,
}: CardSettingsStatementsRowDetailsComponentType): boolean =>
  Boolean(showAlways || composeData[value] > 0);

const convertPositiveNumbersToNegative = (value?: number): number =>
  value && value >= 0 ? -value : Number(value);

export { convertPositiveNumbersToNegative, showCardStatementDetailsResumeItem };
