// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Types
import { DashboardTableBuilderReturnHandlersType } from 'containers/dashboard/components/table/handlers/types/dashboard-table-builder-return.handlers.type';
import { DashboardTableBuilderHandlersType } from 'containers/dashboard/components/table/handlers/types/dashboard-table-builder.handlers.type';
import { NavigateToTransactionsHandlerType } from './types/navigate-to-transactions-handler.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const setupDashboardTableErrorHandler = ({
  setError,
  transactions,
}: DashboardTableBuilderHandlersType): void => {
  transactions?.elements?.length === 0 && setError(TransactionsFetchErrorEnumeration.NO_RESULTS);
};

const navigateToTransactionsHandler = ({
  navigate,
  isPSD2 = false,
}: NavigateToTransactionsHandlerType): void => {
  navigate(generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS), {
    state: {
      fetchTransactions: isPSD2,
    },
  });
};

const DashboardTableHandlers = (
  props: DashboardTableBuilderHandlersType
): DashboardTableBuilderReturnHandlersType => ({
  handleSetupDashboardTableError: () => setupDashboardTableErrorHandler(props),
  handleNavigateToTransactions: (isPSD2?: boolean) =>
    navigateToTransactionsHandler({ ...props, isPSD2 }),
});

export default DashboardTableHandlers;
