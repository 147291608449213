// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Hooks
import { useTransactionsEppModalHook } from 'containers/transactions/views/epp/hooks/transactions-epp-modal.hook';
// Types
import { TransactionsEppStep2ResumeHookType } from './types/transactions-epp-step2-resume.hook.type';
// Utilities
import { getTransactionDescriptionLabel } from 'utils/transaction-description/transaction-description.utils';

const useTransactionsEppStep2ResumeHook = (): TransactionsEppStep2ResumeHookType => {
  const { formatMessage } = useCbIntl();

  const { eppPlanSimulation } = useTransactionsEppContextConsumerHook(),
    { currentCardDetail } = AppContextConsumerHook(),
    { description, merchantDetails, narrative, poEditorNarrative } = currentCardDetail || {};

  const { handleToggleAmortizationTableModalLinkClick } = useTransactionsEppModalHook();

  return {
    eppPlanSimulation,
    handleToggleAmortizationTableModalLinkClick,
    transactionDescription: getTransactionDescriptionLabel({
      formatMessage,
      description,
      merchantDetails,
      narrative,
      poEditorNarrative,
    }),
  };
};

export { useTransactionsEppStep2ResumeHook };
