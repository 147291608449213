// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Resolvers
import { selectMethodResolver } from '../resolvers/step-3-select-method.resolvers';
// Types
import { SelectMethodPropsType } from './types/step-3-select-method-props.handler.type';
import { SelectMethodReturnType } from './types/step-3-select-method-return.handler.type';

const selectAccountAggregationHandler = async ({
  creditLimitContext,
  setCurrentStep,
}: SelectMethodPropsType): Promise<void> => {
  const { wizardStatus, step3, step4, setStep3, setStep4, setWizardStatus } = creditLimitContext;

  setStep3({
    ...step3,
    isLoadingNextRequest: true,
  });

  const { caseId } = wizardStatus;
  const [result, error] = await selectMethodResolver({ caseId });

  if (error) {
    setStep3({
      ...step3,
      isLoadingNextRequest: false,
    });
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }
  if (result) {
    const currentStep = StepEnumToIndexMap[result.nextStep];
    const currentStepInText = result.nextStep as CreditLimitIncreaseStepsEnumeration;
    setStep3({
      ...step3,
      isLoadingNextRequest: false,
    });
    setStep4({
      ...step4,
      stepData: result.stepData,
      aggregationUrl: result.stepData.aggregationUrl,
    });
    setWizardStatus({ ...wizardStatus, caseId: result.caseId, currentStep, currentStepInText });
    setCurrentStep(currentStep);
  }
};

const selectUploadDocumentationHandler = async ({
  creditLimitContext,
  setCurrentStep,
}: SelectMethodPropsType): Promise<void> => {
  const { wizardStatus, step3, step4, setStep3, setStep4, setWizardStatus } = creditLimitContext;

  setStep3({
    ...step3,
    isLoadingUploadDocumentationRequest: true,
  });

  const { caseId } = wizardStatus;
  const [result, error] = await selectMethodResolver({ caseId, isUploadDocumentation: true });

  if (error) {
    setStep3({
      ...step3,
      isLoadingUploadDocumentationRequest: false,
    });
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }
  if (result) {
    const currentStep = StepEnumToIndexMap[result.nextStep];
    const currentStepInText = result.nextStep as CreditLimitIncreaseStepsEnumeration;
    setStep3({
      ...step3,
      isLoadingUploadDocumentationRequest: false,
    });
    setStep4({
      ...step4,
      stepData: result.stepData,
    });
    setWizardStatus({ ...wizardStatus, caseId: result.caseId, currentStep, currentStepInText });
    setCurrentStep(currentStep);
  }
};

export const SelectMethodHandlers = ({
  creditLimitContext,
  setCurrentStep,
}: SelectMethodPropsType): SelectMethodReturnType => ({
  handleSelectAccountAggregation: () =>
    selectAccountAggregationHandler({ creditLimitContext, setCurrentStep }),
  handleSelectUploadDocumentation: () =>
    selectUploadDocumentationHandler({ creditLimitContext, setCurrentStep }),
});
