// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { saveFastCashResolver } from 'containers/financing/views/fast-cash/resolvers/financing-fast-cash.resolvers';
// Types
import { FinancingFastCashSecondStepActionsHandlersType } from './types/financing-fast-cash-second-step-actions.handlers.type';
import { FinancingFastCashSecondStepActionsReturnHandlersType } from './types/financing-fast-cash-second-step-actions-return.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const nextButtonClickHandler = async ({
  accountId,
  amountValue,
  cardId,
  challenge,
  setAmountValue,
  setChallenge,
  setResult,
  handleRetryChallengeError,
  handleSetupCardInformationEvent,
}: NextButtonClickHandlerType): Promise<void> => {
  const amount = amountValue ?? 0;

  const [_, error] = await saveFastCashResolver({
    accountId,
    amount,
    cardId,
    progressId: challenge?.progressId,
  });

  if (error) {
    setChallenge(null);

    if (error === HttpStatusCodesEnum.FORBIDDEN) {
      handleRetryChallengeError();
    } else {
      setAmountValue(null);
      setResult(false);
    }
  } else {
    handleSetupCardInformationEvent();
    setResult(true);
  }
};

const FinancingFastCashSecondStepActionsHandlers = (
  props: FinancingFastCashSecondStepActionsHandlersType
): FinancingFastCashSecondStepActionsReturnHandlersType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});

export default FinancingFastCashSecondStepActionsHandlers;
