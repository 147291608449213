// Vendors
import styled from 'styled-components';

export const CardSettingsChangePinSidebarComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  max-width: 100%;
`;

export const CardSettingsChangePinSidebarItemComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  padding-bottom: ${({ theme }) => theme.spaces.spacingXxs};
`;
