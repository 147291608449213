// Vendors
import React from 'react';
// Components
import { CardSettingsChange3DSidebarComponent } from './components/sidebar/card-settings-change-3d-sidebar.component';
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import {
  CARD_SETTINGS_CHANGE_3D_WIZARD_STEPS,
  CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_SUCCESS,
  CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_ERROR,
} from './constants/card-settings-change-3d.constants';
// Contexts
import {
  CardSettingsChange3DContextConsumerHOC,
  useCardSettingsChange3DContextConsumerHook,
} from './contexts/card-settings-change-3d.context';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { CardSettingsChange3dViewStyled } from 'containers/card-settings/views/change-3d/card-settings-change-3d.view.styled';

const CardSettingsChange3dBaseView = (): React.ReactElement => {
  const { errorDescription } = useCardSettingsChange3DContextConsumerHook();

  return (
    <CardSettingsChange3dViewStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <WizardComponent
            steps={CARD_SETTINGS_CHANGE_3D_WIZARD_STEPS}
            feedbackConfiguration={{
              errorConfiguration: {
                ...CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_ERROR,
                description: errorDescription,
              },
              successConfiguration: CARD_SETTINGS_CHANGE_3D_WIZARD_FEEDBACK_SUCCESS,
            }}
          />
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <CardSettingsChange3DSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </CardSettingsChange3dViewStyled>
  );
};

const CardSettingsChange3DView = CardSettingsChange3DContextConsumerHOC(
  CardSettingsChange3dBaseView
);

export { CardSettingsChange3dBaseView, CardSettingsChange3DView };
