// Vendors
import { isTabletDevice } from '@openbank/cf-ui-framework';
import { useState } from 'react';
// Handlers
import { FinancingLandingRowDetailsHandler } from '../handlers/financing-landing-row-details-amortization-table.component.handlers';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Types
import { FinancingLandingRowDetailsHookType } from './types/financing-landing-row-details-amortization-table.component.hook.type';

export const FinancingLandingRowDetailsHook = (): FinancingLandingRowDetailsHookType => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { isMobile } = DeviceTypeHook();
  const isTablet = isTabletDevice();
  const isMobileOrTablet = isMobile || isTablet;

  return {
    isModalOpen,
    isMobile: isMobileOrTablet,
    handleOpenModal: () => FinancingLandingRowDetailsHandler.handleOpenModal(setModalOpen),
    handleCloseModal: () => FinancingLandingRowDetailsHandler.handleCloseModal(setModalOpen),
  };
};
