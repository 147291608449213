// Vendors
import React from 'react';
// Styles
import {
  AppCardSectionComponentStyled,
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
// Types
import { FinancingOperativeLayoutComponentType } from './types/financing-operative-layout.component.type';

const FinancingOperativeLayoutComponent = ({
  children,
  sidebar,
}: FinancingOperativeLayoutComponentType): React.ReactElement => (
  <LayoutGridComponentStyled>
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <AppCardSectionComponentStyled>{children}</AppCardSectionComponentStyled>
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled>{sidebar}</LayoutGridContentRightComponentStyled>
    </LayoutGridContentComponentStyled>
  </LayoutGridComponentStyled>
);

export default FinancingOperativeLayoutComponent;
