const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cbTravelPlus.';

const FEATURE_01_DESCRIPTION = `${DOMAIN}feature01Description`,
  FEATURE_02_DESCRIPTION = `${DOMAIN}feature02Description`,
  FEATURE_03_DESCRIPTION = `${DOMAIN}feature03Description`,
  FEATURE_04_DESCRIPTION = `${DOMAIN}feature04Description`,
  FEATURE_01_TITLE = `${DOMAIN}feature01Title`,
  FEATURE_02_TITLE = `${DOMAIN}feature02Title`,
  FEATURE_03_TITLE = `${DOMAIN}feature03Title`,
  FEATURE_04_TITLE = `${DOMAIN}feature04Title`;

export {
  FEATURE_01_DESCRIPTION,
  FEATURE_02_DESCRIPTION,
  FEATURE_03_DESCRIPTION,
  FEATURE_04_DESCRIPTION,
  FEATURE_01_TITLE,
  FEATURE_02_TITLE,
  FEATURE_03_TITLE,
  FEATURE_04_TITLE,
};
