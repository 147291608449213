// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { ExtraInformationInputHanders } from '../handlers/extra-information-input.handler';
// Types
import { ExtraInformationHookReturnType } from './types/extra-information-input-return.hook.type';
import { ExtraInformationHookType } from './types/extra-information-input.hook.type';

export const useExtraInformationHook = ({
  selectedOptions,
  setSelectedOptions,
}: ExtraInformationHookType): ExtraInformationHookReturnType => {
  const { formatMessage } = useCbIntl();
  const [value, setValue] = useState<string>(selectedOptions.extraInformation ?? '');
  const { changeInputEventHandler } = ExtraInformationInputHanders({
    setValue,
    selectedOptions,
    setSelectedOptions,
  });

  return {
    value,
    formatMessage,
    handleChangeInputEvent: value => changeInputEventHandler(value),
  };
};
