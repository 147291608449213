// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import FinancingPaymentMethodFirstStepPaymentOptionsComponent from './components/options/financing-payment-method-first-step-payment-options.component';
// Constants
import { TITLE_PROPS } from './constants/financing-payment-method-first-step-payment.constants';
import { EURO_FORMAT_OPTIONS } from 'containers/financing/views/payment-method/constants/financing-payment-method.constants';
// Hooks
import FinancingPaymentMethodFirstStepPaymentHook from './hooks/financing-payment-method-first-step-payment.hook';
// Styles
import {
  FinancingPaymentMethodFirstStepPaymentComponentStyled,
  FinancingPaymentMethodFirstStepPaymentDescriptionStyled,
} from './financing-payment-method-first-step-payment.component.styled';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from './translations/financing-payment-method-first-step-payment.translations';

const FinancingPaymentMethodFirstStepPaymentComponent = (): React.ReactElement => {
  const {
    billingCyclePeriodicity,
    currentAmountAuthorized,
    formatNumber,
    limit,
    paymentMethodLabel,
  } = FinancingPaymentMethodFirstStepPaymentHook();

  return (
    <FinancingPaymentMethodFirstStepPaymentComponentStyled>
      <ParagraphComponent {...TITLE_PROPS}>
        <FormattedMessageComponent
          id={TITLE}
          values={{
            paymentMethod: paymentMethodLabel,
          }}
        />
      </ParagraphComponent>
      <FinancingPaymentMethodFirstStepPaymentDescriptionStyled>
        <ParagraphComponent>
          <FormattedMessageComponent
            id={DESCRIPTION.FIRST_PARAGRAPH}
            values={{
              consumed: formatNumber(currentAmountAuthorized, EURO_FORMAT_OPTIONS),
              limit: formatNumber(limit, EURO_FORMAT_OPTIONS),
            }}
          />
        </ParagraphComponent>
        <ParagraphComponent>
          <FormattedMessageComponent
            id={DESCRIPTION.SECOND_PARAGRAPH}
            values={{ day: billingCyclePeriodicity }}
          />
        </ParagraphComponent>
      </FinancingPaymentMethodFirstStepPaymentDescriptionStyled>
      <FinancingPaymentMethodFirstStepPaymentOptionsComponent />
    </FinancingPaymentMethodFirstStepPaymentComponentStyled>
  );
};

export default FinancingPaymentMethodFirstStepPaymentComponent;
