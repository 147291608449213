// Resolvers
import { fetchCardsTransactionsDetailResolver } from 'containers/transactions/resolvers/transactions.resolvers';
// Types
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
import {
  TransactionsEppBuilderHandlersType,
  TransactionsEppMethodsHandlersType,
} from 'containers/transactions/views/epp/handlers/types/transactions-epp.handlers.type';
// Utilities
import { cardTransactionCantBeInstallmentPlan } from './utils/transactions-epp.handlers.utils';

const checkInstallmentPlanAvailabilityEventHandler = async ({
  setFetching,
  setError,
  setTransactionDetails,
  ...props
}: TransactionsEppBuilderHandlersType): Promise<void> => {
  setFetching(true);

  const [response, error] = await fetchCardsTransactionsDetailResolver(props);

  cardTransactionCantBeInstallmentPlan({ error, response })
    ? setError(true)
    : setTransactionDetails(response as CardTransactionsDetailType);
  setFetching(false);
};

const TransactionsEppHandlers = (
  props: TransactionsEppBuilderHandlersType
): TransactionsEppMethodsHandlersType => ({
  handleCheckInstallmentPlanAvailabilityEvent: () =>
    checkInstallmentPlanAvailabilityEventHandler(props),
});

export { TransactionsEppHandlers };
