// Vendors
import { apiUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/notification-settings-list.service.constants';
// Types
import { FetchNotificationsSettingsApiReturnType } from './types/fetch-notifications-settings-list-api-return.type';
import { FormatSettingsPayloadReturnType } from '../handlers/utils/types/format-settings-payload-return.handlers.utils.type';

const fetchNotificationsSettingsService = async (): Promise<[FetchNotificationsSettingsApiReturnType, Response]> =>
  apiUtils.get({
    endpoint: constants.FETCH_NOTIFICATIONS_SETTINGS_ENDPOINT,
  });

const saveNotificationsSettingsService = async (formattedSettings: FormatSettingsPayloadReturnType): Promise<[null | null, Response]> =>
  apiUtils.post({
    endpoint: constants.SAVE_NOTIFICATIONS_SETTINGS_ENDPOINT,
    params: { ...formattedSettings },
  });

export { fetchNotificationsSettingsService, saveNotificationsSettingsService };
