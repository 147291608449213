// Resolvers
import {
  getFastCashCommissionResolver,
  getFastCashLimitsResolver,
} from '../../resolvers/financing-fast-cash.resolvers';
import { CloseLimitModalHandlerPropsType } from './types/close-limit-modal-handler-props.type';
// Types
import { FinancingFastCashHandlersPropsType } from './types/financing-fast-cash-handlers-props.type';
import { FinancingFastCashHandlersReturnType } from './types/financing-fast-cash-handlers-return.type';
import { LoadFastCashCommissionHandlerPropsType } from './types/load-fast-cash-commission-handler-props.type';
import { LoadFastCashLimitsHandlerPropsType } from './types/load-fast-cash-limits-handler-props.type';
// Utilities
import { availableBalance } from './utils/financing-fast-cash-handlers.utils';

const loadFastCashLimitsHandler = async ({
  availableAmount,
  cardId,
  setError,
  setFastCashCommission,
  setFastCashLimits,
  setLoading,
  setShowLimitModal,
}: LoadFastCashLimitsHandlerPropsType): Promise<void> => {
  setLoading(true);
  const [response, error] = await getFastCashLimitsResolver({
    cardId,
  });
  if (error || !response) {
    setLoading(false);
    setError(true);
    return;
  }
  if (availableBalance({ availableAmount, fastCashLimits: response })) {
    setFastCashLimits(response);
    loadFastCashCommissionHandler({ cardId, setLoading, setFastCashCommission, setError });
  } else {
    setLoading(false);
    setShowLimitModal(true);
  }
};

const loadFastCashCommissionHandler = async ({
  cardId,
  setLoading,
  setFastCashCommission,
  setError,
}: LoadFastCashCommissionHandlerPropsType): Promise<void> => {
  setLoading(true);
  const [response, error] = await getFastCashCommissionResolver({
    cardId,
  });
  if (error || !response) {
    setError(true);
    setLoading(false);
    return;
  }
  setLoading(false);
  setFastCashCommission(response);
};

const closeLimitModalHandler = ({ setShowLimitModal }: CloseLimitModalHandlerPropsType): void => {
  setShowLimitModal(false);
};

const FinancingFastCashHandlers = ({
  availableAmount,
  cardId,
  setLoading,
  setError,
  setFastCashLimits,
  setFastCashCommission,
  setShowLimitModal,
}: FinancingFastCashHandlersPropsType): FinancingFastCashHandlersReturnType => ({
  handleLoadFastCashLimits: () =>
    loadFastCashLimitsHandler({
      availableAmount,
      cardId,
      setError,
      setFastCashCommission,
      setFastCashLimits,
      setLoading,
      setShowLimitModal,
    }),
  handleCloseLimitModal: () => closeLimitModalHandler({ setShowLimitModal }),
});

export default FinancingFastCashHandlers;
