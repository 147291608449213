// Constants
import {
  EDIT_BUTTON_PAYLOAD,
  CLICK_INPUT_PAYLOAD,
  SAVE_BUTTON_PAYLOAD,
  MIN_LENGTH_ERROR_PAYLOAD,
  REQUIRED_ERROR_PAYLOAD,
  VALIDATION_ERROR_PAYLOAD,
  SNACKBAR_ERROR_OPEN_PAYLOAD,
  SNACKBAR_ERROR_CLOSE_PAYLOAD,
  SNACKBAR_SUCCESS_OPEN_PAYLOAD,
  SNACKBAR_SUCCESS_CLOSE_PAYLOAD
} from './constants/device-header-alias.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { DeviceHeaderAliasTrankingHandlersReturnType } from './type/device-header-alias.tracking.handlers.return.type';

const openEditingInputDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: EDIT_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openInputClickDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CLICK_INPUT_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openSaveClickDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: SAVE_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const minLengthErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: MIN_LENGTH_ERROR_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const requiredErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: REQUIRED_ERROR_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const validationErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: VALIDATION_ERROR_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const snackBarErrorOpenTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: SNACKBAR_ERROR_OPEN_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const snackBarErrorCloseTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: SNACKBAR_ERROR_CLOSE_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const snackBarSuccessOpenTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: SNACKBAR_SUCCESS_OPEN_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const snackBarSuccessCloseTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: SNACKBAR_SUCCESS_CLOSE_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const DeviceAliasTrackingHandlers = (
  track: (param: unknown) => void
): DeviceHeaderAliasTrankingHandlersReturnType => ({
    onOpenEditingInputDeviceTracking: () => track(openEditingInputDeviceTrackingHandler()),
    onOpenInputClickDeviceTracking: () => track(openInputClickDeviceTrackingHandler()),
    onOpenSaveClickDeviceTracking: () => track(openSaveClickDeviceTrackingHandler()),
    onMinLengthErrorTracking: () => track(minLengthErrorTrackingHandler()),
    onRequiredErrorTracking: () => track(requiredErrorTrackingHandler()),
    onValidationErrorTracking: () => track(validationErrorTrackingHandler()),
    onSnackBarErrorOpenTracking: () => track(snackBarErrorOpenTrackingHandler()),
    onSnackBarErrorCloseTracking: () => track(snackBarErrorCloseTrackingHandler()),
    onSnackBarSuccessOpenTracking: () => track(snackBarSuccessOpenTrackingHandler()),
    onSnackBarSuccessCloseTracking: () => track(snackBarSuccessCloseTrackingHandler()),

});

export { DeviceAliasTrackingHandlers };