// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
import { DashboardPointsLinegraphHookReturnType } from './types/dashboard-points-line-graph.component.hook.type';

const DashboardPointsLinegraphHook = (): DashboardPointsLinegraphHookReturnType => {
  const { formatMessage } = useCbIntl();
  const { isMobile } = DeviceTypeHook();

  return {
    formatMessage,
    isMobile,
  };
};
export default DashboardPointsLinegraphHook;
