const DOMAIN_BLOCK_CARD_FIRST_STEP_REASON =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.first.reason.';

const DESCRIPTION = `${DOMAIN_BLOCK_CARD_FIRST_STEP_REASON}description`;

const LOST = `${DOMAIN_BLOCK_CARD_FIRST_STEP_REASON}lost`;

const STOLEN = `${DOMAIN_BLOCK_CARD_FIRST_STEP_REASON}stolen`;

export { DESCRIPTION, LOST, STOLEN };
