export const CHANGE_IBAN_STEP1_RESUME_ITEM_01_PROPS = {
  testId: 'change-iban-step1-item-01',
};

export const CHANGE_IBAN_STEP1_RESUME_ITEM_03_PROPS = {
  testId: 'change-iban-step1-item-03',
};

export const CHANGE_IBAN_STEP1_RESUME_ITEM_04_PROPS = {
  testId: 'change-iban-step1-item-04',
};

export const CHANGE_IBAN_STEP1_MESSAGE_PROPS = {
  showIcon: false,
  testId: 'change-iban-step1-message',
  type: 'informative',
};

export const CHANGE_IBAN_STEP1_MESSAGE_LINK = {
  testId: 'change-iban-step1-message-link',
};
