// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TRAVEL_PLUS_LINK_TRACKING_PROPS } from './constants/travel-plus-links.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TravelPlusLinksTrackingHandlersType } from './types/travel-plus-links.tracking.handlers.type';

const openTravelPlusModalLinkClickTrackingHandler = (
  eventLabel: string
): TrackingLinkEventReturnType => ({
  payload: {
    ...TRAVEL_PLUS_LINK_TRACKING_PROPS,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

const TravelPlusLinksTrackingHandlers = (
  track: TrackBuilderType
): TravelPlusLinksTrackingHandlersType => ({
  handleOpenTravelPlusModalLinkClickTracking: (id: string) =>
    track(openTravelPlusModalLinkClickTrackingHandler(id)),
});

export { TravelPlusLinksTrackingHandlers };
