// Types
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';
// Utilities
import {
  hasTransactionsChallengeSuccess,
  isNotDashboardRoute,
  isValidMetadataContract,
} from 'organisms/table-organism/components/transactions-table/utils/transactions-table.utils';
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';

const hasChallengeSuccess = (
  metadata: TransactionsTableMetadataComponentType | Record<string, any> = {}
) => isNotDashboardRoute() && hasTransactionsChallengeSuccess() && metadata?.hasPendingRecords;

const showLoadMoreTransactionsLink = (
  componentError: TransactionsFetchErrorEnumeration | null,
  metadata: TransactionsTableMetadataComponentType | Record<string, any>
): boolean =>
  Boolean(
    (isValidMetadataContract(metadata) &&
      metadata?.nextCursor &&
      componentError !== TransactionsFetchErrorEnumeration.NO_RESULTS &&
      componentError !== TransactionsFetchErrorEnumeration.NO_FILTER_RESULTS) ||
      hasChallengeSuccess(metadata)
  );

export { showLoadMoreTransactionsLink };
