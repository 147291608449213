// Vendors
import { CountriesLocaleEnum, MiscellaneousCurrenciesEnum} from '@openbank/cf-ui-static-data';
// Constants
import {
  creditLimitStatusesWithMessage,
  statusToCTAMap,
  statusToDescriptionMap,
  statusToMessageTypeMap,
  statusToTitleMap,
  statusToTrackingCTAValueMap,
} from './constants/credit-limit-status-maps.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Types
import { FormatCurrencyPropsType } from './types/credit-limit-status.utils.type';

const formatCurrency = ({
  value,
  locale = CountriesLocaleEnum.GERMANY,
  currency = MiscellaneousCurrenciesEnum.EURO,
}: FormatCurrencyPropsType): string => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value);
};

const hasCreditLimitIncreaseStatusMessage = (
  creditLimitStatus: CreditLimitIncreaseStepsEnumeration
): boolean => {
  return creditLimitStatusesWithMessage.includes(creditLimitStatus);
};

const getCreditLimitMessageType = (limitIncreaseStatus: string): string => {
  return statusToMessageTypeMap[limitIncreaseStatus] || '';
};

const getCreditLimitMessageTitle = (limitIncreaseStatus: string): string => {
  return statusToTitleMap[limitIncreaseStatus] || '';
};

const getCreditLimitMessageDescription = (limitIncreaseStatus: string): string | undefined => {
  return statusToDescriptionMap[limitIncreaseStatus] || '';
};

const getCreditLimitMessageCTA = (creditLimitStatus: string): string => {
  return statusToCTAMap[creditLimitStatus] || '';
};

const getCreditLimitTrackingCTAValue = (creditLimitStatus: string): string => {
  return statusToTrackingCTAValueMap[creditLimitStatus] || '';
};

const isEvaluateNewOffer = (limitIncreaseStatus: string): boolean =>
  limitIncreaseStatus === CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER;

export {
  formatCurrency,
  getCreditLimitMessageCTA,
  getCreditLimitMessageDescription,
  getCreditLimitMessageTitle,
  getCreditLimitMessageType,
  getCreditLimitTrackingCTAValue,
  hasCreditLimitIncreaseStatusMessage,
  isEvaluateNewOffer,
};
