const CLICK_BUTTON_TRACKING = 'clickButton';
const CLICK_LINK_TRACKING = 'clickLink';
const NEXT_CHALLENGE_TYPE = 'None';
const REQUEST_NEW_PHONE_OTP_TRACKING = 'requestNewPhoneOtpCode';
const SENT_OTP_CONFIRMATION_TRACKING = 'sendPhoneOtpConfirmation';

export {
  CLICK_BUTTON_TRACKING,
  CLICK_LINK_TRACKING,
  NEXT_CHALLENGE_TYPE,
  REQUEST_NEW_PHONE_OTP_TRACKING,
  SENT_OTP_CONFIRMATION_TRACKING,
};
