// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { AddressModalComponent } from 'components/address-modal/address-modal.component';
import { PersonalAddressStep1ActionsComponent } from './components/actions/personal-address-step1-actions.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { PersonalAddressStep1AddressBoxComponent } from './components/address-box/personal-address-step1-address-box.component';
// Constants
import { DESCRIPTION_PARAGRAPH_PROPS } from './constants/personal-address-step1.constants';
// Hooks
import { usePersonalAddressStep1Hook } from './hooks/personal-address-step1.hook';
// Translations
import { DESCRIPTION } from './translations/personal-address-step1.translations';
// Styles
import { PersonalAddressStep1Styled } from './personal-address-step1.component.styled';

export const PersonalAddressStep1Component = (): React.ReactElement | null => {
  const {
    address,
    handleCloseModal,
    handleErrorModal,
    handleOpenModal,
    handleSaveButtonClick,
    isEditing,
    newAddress,
  } = usePersonalAddressStep1Hook();

  if (!newAddress) {
    return null;
  }

  return (
    <PersonalAddressStep1Styled>
      <ParagraphComponent {...DESCRIPTION_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <PersonalAddressStep1AddressBoxComponent {...{ newAddress, handleOpenModal }} />
      <PersonalAddressStep1ActionsComponent {...{ address, newAddress }} />
      <AddressModalComponent
        defaultValues={newAddress}
        isOpen={isEditing}
        onCancel={handleCloseModal}
        onClose={handleCloseModal}
        onError={handleErrorModal}
        onSave={handleSaveButtonClick}
      />
    </PersonalAddressStep1Styled>
  );
};
