// Vendors
import { useState } from 'react';
// Event handlers
import TransactionsLandingRowDetailsAmountHandlers from '../handlers/transactions-landing-row-details-amount.handlers';
import TransactionsLandingRowDetailsTrackingHandlers from '../handlers/transactions-landing-row-details-amount.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsLandingRowDetailsAmountBuilderReturnHandlersType } from './types/transactions-landing-row-details-amount-builder-return.hook.type';

export const TransactionsLandingRowDetailsAmountHook =
  (): TransactionsLandingRowDetailsAmountBuilderReturnHandlersType => {
    const [showModal, setShowModal] = useState<boolean>(false);

    const { handleSeeDetailsElementAmountLinkClick } = TransactionsLandingRowDetailsAmountHandlers({
      ...AppTrackingHook(TransactionsLandingRowDetailsTrackingHandlers),
      showModal,
      setShowModal,
    });

    return {
      handleSeeDetailsElementAmountLinkClick,
      showModal,
      setShowModal,
    };
  };