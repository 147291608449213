// Constants
import { CARD_SETTINGS_ROUTER_ROUTES as CARD_SETTINGS_ROUTER_ROUTES_COMMON } from './card-settings-router.common.constants';
import checkCvvPinConstants from 'containers/card-settings/views/check-cvv-pin/constants/check-cvv-pin.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Views
import { CardSettingsChange3DView } from 'containers/card-settings/views/change-3d/card-settings-change-3d.view';
import { CardSettingsCheck3dView } from 'containers/card-settings/views/check-3d/card-settings-check-3d.view';
import { CardSettingsChangePinComponent } from 'containers/card-settings/views/change-pin/card-settings-change-pin.component';
import CheckCvvPinView from 'containers/card-settings/views/check-cvv-pin/check-cvv-pin.view';

const { PIN, CVV } = checkCvvPinConstants;

const CARD_SETTINGS_ROUTER_ROUTES: RouteType[] = [
  ...(CARD_SETTINGS_ROUTER_ROUTES_COMMON as RouteType[]),
  { element: CardSettingsChange3DView, props: { path: RoutesEnumeration.CHANGE_3D_SECURE } },
  { element: CardSettingsChangePinComponent, props: { path: RoutesEnumeration.CHANGE_PIN } },
  { element: CardSettingsCheck3dView, props: { path: RoutesEnumeration.CHECK_3D_SECURE } },
  { element: CheckCvvPinView, elementProps: { type: CVV }, props: { path: RoutesEnumeration.CVV } },
  { element: CheckCvvPinView, elementProps: { type: PIN }, props: { path: RoutesEnumeration.PIN } },
];

export { CARD_SETTINGS_ROUTER_ROUTES };
