// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
import PersonalEmailLogoutModalComponent from './components/logout-modal/personal-email-logout-modal.component';
import PersonalEmailSidebarComponent from './components/sidebar/personal-email-sidebar.component';
import { PersonalSidebarCCComponent } from '../../components/sidebar-cc/personal-sidebar-cc.component';
// Contexts
import { PersonalEmailContextConsumerHOC } from './contexts/personal-email.context';
// Constants
import constants from './constants/personal-email.constants';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const PersonalEmailView = (): React.ReactElement => {
  return (
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <WizardComponent {...constants.PERSONAL_EMAIL_WIZARD_STEPS} />
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled {...constants.PERSONAL_EMAIL_RIGHT_COLUMN_PROPS}>
        <PersonalSidebarCCComponent />
        <PersonalEmailSidebarComponent />
      </LayoutGridContentRightComponentStyled>
      <PersonalEmailLogoutModalComponent />
    </LayoutGridContentComponentStyled>
  );
};

export { PersonalEmailView };
export default PersonalEmailContextConsumerHOC(PersonalEmailView);
