// Vendors
import React, { createContext, useContext, useState } from 'react';
// Constants
import constants from './constants/security-keys-access-key-change.context.constants';
// Enumerations
import { AccessKeyChangeSecondStepErrorIdEnumeration } from '../components/second-step/enumerations/security-keys-access-key-change-second-step.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SecurityKeysAccessKeyChangeContextType } from './types/security-keys-access-key-change.context.type';

const SecurityKeysAccessKeyChangeContext = createContext<SecurityKeysAccessKeyChangeContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const SecurityKeysAccessKeyChangeContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<SecurityKeysAccessKeyChangeContextType>
> => {
  const [accessKey, setAccessKey] = useState<string>('');
  const [isDisabledLastStep, setIsDisabledLastStep] = useState<boolean>(true);
  const [newAccessKey, setNewAccessKey] = useState<string>('');
  const [progressId, setProgressId] = useState<string>('');
  const [repeatNewAccessKey, setRepeatNewAccessKey] = useState<string>('');
  const [inputError, setInputError] = useState<
    AccessKeyChangeSecondStepErrorIdEnumeration[] | null
  >(null);
  return (
    <SecurityKeysAccessKeyChangeContext.Provider
      value={{
        accessKey,
        isDisabledLastStep,
        newAccessKey,
        progressId,
        repeatNewAccessKey,
        setAccessKey,
        setIsDisabledLastStep,
        setNewAccessKey,
        setProgressId,
        setRepeatNewAccessKey,
        inputError,
        setInputError,
      }}
    >
      {children as React.ReactNode}
    </SecurityKeysAccessKeyChangeContext.Provider>
  );
};

export const SecurityKeysAccessKeyChangeContextConsumerHook =
  (): SecurityKeysAccessKeyChangeContextType => useContext(SecurityKeysAccessKeyChangeContext);

/* eslint-disable */
export const SecurityKeysAccessKeyChangeContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<SecurityKeysAccessKeyChangeContextType>> =>
    (
      <SecurityKeysAccessKeyChangeContextProvider>
        <Component {...props} />
      </SecurityKeysAccessKeyChangeContextProvider>
    );
