// Enumerations
import { CardControlTabsIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-tabs-ids.enumeration';
// Types
import { SettingCardControlLocationsWarningBuilderReturnHandlersType } from './types/setting-card-control-locations-warning-builder-return.handlers.type';
import { SettingCardControlLocationsWarningBuilderHandlersType } from './types/setting-card-control-locations-warning-builder.handlers.type';

const manageChangeCardStatusButtonClickHandler = ({
  handleNavigateCardControlOperationsLinkClickTracking,
  setTabSelectedId,
}: SettingCardControlLocationsWarningBuilderHandlersType): any => {
  handleNavigateCardControlOperationsLinkClickTracking();
  setTabSelectedId(CardControlTabsIdsEnumeration.OPERATIONS);
};

const SettingCardControlLocationsWarningHandlers = (
  props: SettingCardControlLocationsWarningBuilderHandlersType
): SettingCardControlLocationsWarningBuilderReturnHandlersType => ({
  handleNavigateCardControlOperationsLinkClick: () =>
    manageChangeCardStatusButtonClickHandler(props),
});

export default SettingCardControlLocationsWarningHandlers;
