// Constants
import { UPDATE_CONSENTS_ENDPOINT } from '../services/constants/profile-consents-step2.service.constants';

const CHALLENGE_WRAPPER_PROPS = {
  baseService: UPDATE_CONSENTS_ENDPOINT,
  baseServiceMethod: 'patch',
};

const WIZARD_FOOTER_PROPS = {
  hasDefaultLeftButton: true,
};

export { CHALLENGE_WRAPPER_PROPS, WIZARD_FOOTER_PROPS };
