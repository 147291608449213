// Types
import { TitleSectionRwdBreadcrumbsConfigurationsType } from 'components/title-section/components/rwd/components/breadcrumbs/configurations/types/title-section-rwd-breadcrumbs.configurations.type';
import { TitleSectionRwdBreadcrumbsHookType } from 'components/title-section/components/rwd/components/breadcrumbs/hooks/types/title-section-rwd-breadcrumbs.hook.type';
import { TitleSectionRwdBreadcrumbsReturnConfigurationsType } from 'components/title-section/components/rwd/components/breadcrumbs/configurations/types/title-section-rwd-breadcrumbs-return.configurations.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import {
  getBreadCrumbCurrentItemTitle,
  getPathNameUrlItems,
} from 'components/title-section/components/rwd/utils/title-section-rwd.utils';

const breadCrumbItemClick = ({
  navigate,
  item,
  index,
  handleBreadcrumbClickTrackingHandler,
}: TitleSectionRwdBreadcrumbsConfigurationsType): any => {
  const url = index >= 1 ? item : generateAbsoluteRoute(item);

  navigate(url);
  index === 0 && handleBreadcrumbClickTrackingHandler?.();
};

const getCustomBreadCrumbsItems = ({
  breadCrumbPath,
  formatMessage,
  navigate,
  handleBreadcrumbClickTrackingHandler,
}: TitleSectionRwdBreadcrumbsHookType): TitleSectionRwdBreadcrumbsReturnConfigurationsType[] =>
  getPathNameUrlItems(breadCrumbPath).map(
    (item: string, index: number): TitleSectionRwdBreadcrumbsReturnConfigurationsType => ({
      onClick: () =>
        breadCrumbItemClick({ navigate, item, index, handleBreadcrumbClickTrackingHandler }),
      text: formatMessage({ id: getBreadCrumbCurrentItemTitle(item) }),
      testId: item,
    })
  );

export { getCustomBreadCrumbsItems };
