const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step1.';

export default {
  TITLE: `${DOMAIN}title`,
  CONTENT_TITLE: `${DOMAIN}contentTitle`,
  PAYMENT_METHOD_TEXT: `${DOMAIN}paymentMethodText`,
  MESSAGE_COMPONENT_TITLE: `${DOMAIN}messageComponentTitle`,
  MESSAGE_COMPONENT_TEXT: `${DOMAIN}messageComponentText`,
  CREDIT_LIMIT_SHOULD_BE_BIGGER: `${DOMAIN}creditLimitShouldBeBigger`,
  CREDIT_LIMIT_SHOULD_NOT_BE_BIGGER: `${DOMAIN}creditLimitShouldNotBeBigger`,
  CREDIT_LIMIT_SHOULD_BE_BIGGER_THAN_CURRENT: `${DOMAIN}creditLimitShouldBeBiggerThanCurrent`,
  CREDIT_LIMIT_STEP_VALIDATION: `${DOMAIN}creditLimitStepValidation`,
};
