// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsChange3dStep1ComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
CardSettingsChange3dStep1ComponentStyled.displayName = 'CardSettingsChange3dStep1ComponentStyled';

const CardSettingsChange3DStep1ContentComponentStyled = styled(
  CardSettingsChange3dStep1ComponentStyled
)`
  max-width: ${convertPxsToRems({ pixels: 276, base: 14 })};

  ${mobileMediaQuery} {
    max-width: 100%;
  }
`;
CardSettingsChange3DStep1ContentComponentStyled.displayName =
  'CardSettingsChange3DStep1ContentComponentStyled';

export {
  CardSettingsChange3dStep1ComponentStyled,
  CardSettingsChange3DStep1ContentComponentStyled,
};
