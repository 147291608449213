// Types
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
import { FileDataType } from 'types/file-data.type';

const hasEmptyDocuments = (files: FileDataType[]): boolean =>
  files.some(
    ({ file, state }) =>
      !file ||
      [
        DocumentsUploadStatesEnumeration.SYSTEM_ERROR,
        DocumentsUploadStatesEnumeration.REJECTED,
        DocumentsUploadStatesEnumeration.LOADING,
      ].includes(state)
  );

export { hasEmptyDocuments };
