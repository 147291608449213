// Constants
import { NUMBER } from 'constants/number.constants';

const getFormattedPercentage = (percentage: number | undefined): string => {
  if ((percentage ?? 0) > 0) {
    return `+${percentage}`;
  }

  return percentage?.toString() ?? '';
};

const percentageDifferencePositive = (percentageDifference: number | undefined): boolean => 
  (percentageDifference || 0) !== NUMBER.ZERO;

export { getFormattedPercentage, percentageDifferencePositive };
