// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { ConfirmCancelContractEventHandlerPropsType } from './types/support-cancel-contract-first-step-confirm-props.type';
import { CancelContractStep1HandlersPropsType } from './types/support-cancel-contract-first-step-handlers-props.type';
import { CancelContractStep1HandlersReturnType } from './types/support-cancel-contract-first-step-handlers-return.type';
import { SelectReasonCancelContractEventHandlerPropsType } from './types/support-cancel-contract-first-step-select-reason-props.type';
import { ShowModalCancelContractEventHandlerPropsType } from './types/support-cancel-contract-first-step-show-modal-props.type';

const selectReasonCancelContractEventHandler = ({
  option,
  setReason,
  handleDropdownSelectTrackingHandler,
}: SelectReasonCancelContractEventHandlerPropsType): void => {
  handleDropdownSelectTrackingHandler();
  setReason(option);
};

const showModalCancelContractEventHandler = ({
  setShowModal,
  showModal,
}: ShowModalCancelContractEventHandlerPropsType): void => {
  setShowModal(!showModal);
};

const onConfirmCancelContractEventHandler = ({
  setShowModal,
  setCurrentStep,
}: ConfirmCancelContractEventHandlerPropsType): void => {
  setCurrentStep(NUMBER.TWO);
  setShowModal(false);
};

const SupportCancelContractFirstStepHandlers = ({
  handleDropdownSelectTrackingHandler,
  setCurrentStep,
  setReason,
  setShowModal,
  showModal,
}: CancelContractStep1HandlersPropsType): CancelContractStep1HandlersReturnType => ({
  handleSelectReasonCancelContractEventHandler: (option: string) =>
    selectReasonCancelContractEventHandler({
      handleDropdownSelectTrackingHandler,
      option,
      setReason,
    }),
  handleShowModalCancelContractEventHandler: () =>
    showModalCancelContractEventHandler({
      showModal,
      setShowModal,
    }),
  handleOnConfirmCancelContractEventHandler: () =>
    onConfirmCancelContractEventHandler({
      setCurrentStep,
      setShowModal,
    }),
});

export default SupportCancelContractFirstStepHandlers;
