// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/personal-landing-sidebar.constants';
// Styles
import {
  PersonalLandingSidebarComponentStyled,
  PersonalLandingSidebarMessageComponentStyled,
} from './personal-landing-sidebar.component.styled';
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import translations from './translations/personal-landing-sidebar.translations';

const PersonalLandingSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.PERSONAL_LANDING_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <PersonalLandingSidebarComponentStyled>
        <ParagraphComponent {...constants.PERSONAL_LANDING_SIDEBAR_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.DESCRIPTION} />
        </ParagraphComponent>
        <MessageComponent {...constants.PERSONAL_LANDING_SIDEBAR_MESSAGE_PROPS}>
          <PersonalLandingSidebarMessageComponentStyled>
            <ParagraphComponent {...constants.PERSONAL_LANDING_SIDEBAR_MESSAGE_01_PROPS}>
              <FormattedMessageComponent id={translations.MESSAGE_1} />
            </ParagraphComponent>
            <ParagraphComponent {...constants.PERSONAL_LANDING_SIDEBAR_MESSAGE_02_PROPS}>
              <FormattedMessageComponent id={translations.MESSAGE_2} />
            </ParagraphComponent>
            <ParagraphComponent {...constants.PERSONAL_LANDING_SIDEBAR_MESSAGE_03_PROPS}>
              <FormattedMessageComponent id={translations.MESSAGE_3} />
            </ParagraphComponent>
          </PersonalLandingSidebarMessageComponentStyled>
        </MessageComponent>
      </PersonalLandingSidebarComponentStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default PersonalLandingSidebarComponent;
