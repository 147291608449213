const DOMAIN =
  'cobrandedCC.services.userSettings.privateArea.securityAndKeys.changeSignatureKey.';

const SIGNATURE_KEY_FEEDBACK_ERROR_DESCRIPTION = `${DOMAIN}changeSignatureKeyCCDescriptionWizardFeedbackError`,
  SIGNATURE_KEY_FEEDBACK_ERROR_TITLE = `${DOMAIN}changeSignatureKeyCCTitleWizardFeedbackError`,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_DESCRIPTION = `${DOMAIN}changeSignatureKeyCCDescriptionWizardFeedbackSuccess`,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_TITLE = `${DOMAIN}changeSignatureKeyCCTitleWizardFeedbackSuccess`,
  SIGNATURE_KEY_FEEDBACK_BUTTON = `${DOMAIN}changeSignatureKeyCCButtonWizardFeedbackSuccess`,
  SIGNATURE_KEY_FEEDBACK_BUTTON_ERROR = `${DOMAIN}changeSignatureKeyCCButtonWizardFeedbackError`;

export {
  SIGNATURE_KEY_FEEDBACK_ERROR_DESCRIPTION,
  SIGNATURE_KEY_FEEDBACK_ERROR_TITLE,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_DESCRIPTION,
  SIGNATURE_KEY_FEEDBACK_SUCCESS_TITLE,
  SIGNATURE_KEY_FEEDBACK_BUTTON,
  SIGNATURE_KEY_FEEDBACK_BUTTON_ERROR,
};
