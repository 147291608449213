// Vendors
import { useEffect } from 'react';
// Event handlers
import ExampleModalHandlers from '../handlers/example-modal.handlers';
import ExampleModalTrackingHandlers from '../handlers/example-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ExampleModalBuilderReturnHandlersType } from '../handlers/types/example-modal-builder-return.handlers.type';
import { ExampleModalBuilderPropsType } from '../handlers/types/example-modal-builder-props.type';

const useExampleModalHook = ({
  onClose,
  show,
}: ExampleModalBuilderPropsType): ExampleModalBuilderReturnHandlersType => {
  const { handleCloseExampleModalAssetClickTracking, handleOpenExampleModalTracking } =
    AppTrackingHook(ExampleModalTrackingHandlers);
  useEffect(() => {
    show && handleOpenExampleModalTracking();
  }, [show]);

  return ExampleModalHandlers({
    handleCloseExampleModalAssetClickTracking,
    onClose,
  });
};

export default useExampleModalHook;
