export default {
  TABLE_LINK_PROPS: {
    assetConfiguration: {
      id: 'right',
      colors: {
        fill: 'linkPrimaryA',
        hover: 'backgroundInfoD',
      },
      sizes: {
        height: 32,
        width: 32,
      },
      testId: 'transactions-all-links-asset',
    },
    testId: 'transactions-all-links',
  },
};
