// Vendors
import React from 'react';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Components
import { SupportCancelContractAlreadyCancelled } from '../already-cancelled/support-cancel-contract-already-cancelled';
import { SupportCancelContractWizard } from '../wizard/support-cancel-contract-wizard.component';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import { useSupportCancelContract } from '../../hooks/support-cancel-contract.hook';
// Utils
import { isAlreadyCancelled } from '../../utils/support-cancel-contract.utils';

const SupportCancelContractOrchestrator = (): React.ReactElement => {
  const { blockDetails, handleExitCancelContractEvent, formatDate, fetching } =
    useSupportCancelContract();

  return (
    <FetchErrorComponent fetching={fetching}>
      {isAlreadyCancelled(blockDetails) ? (
        <SupportCancelContractAlreadyCancelled
          onClick={handleExitCancelContractEvent}
          cancellationDate={formatDate(
            blockDetails?.blockDate,
            MiscellaneousDateConstants.FORMAT_DD_MM_YYYY
          )}
        />
      ) : (
        <SupportCancelContractWizard />
      )}
    </FetchErrorComponent>
  );
};

export { SupportCancelContractOrchestrator };
