// Enumerations
import {
  SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration,
  SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration,
} from '../enumerations/security-keys-signature-key-change-second-step.enumeration';
// Resolvers
import { validateSignatureKeyResolver } from './resolvers/security-keys-signature-key-change-second-step.resolvers';
// Types
import { SignatureKeyChangeStep2NewHandlersPropsType } from './types/security-keys-signature-key-change-second-step-handlers-props-return.type';
import { SignatureKeyChangeStep2NewHandlersReturnType } from './types/security-keys-signature-key-change-second-step-handlers-return.type';
import { SaveNewSignatureKeyChangeInputEventHandlerPropsType } from './types/security-keys-signature-key-change-second-step-save-new-access-key-props.handler.type';
import { SaveRepeatNewSignatureKeyChangeInputEventHandlerPropsType } from './types/security-keys-signature-key-change-second-step-save-repeat-access-key-props.handler.type';
import { ValidateSignatureKeyChangeInputEventHandlerPropsType } from './types/security-keys-signature-key-change-second-step-validate-new-access-key-props.handler.type';
import { ValidateRepeatSignatureKeyChangeInputEventHandlerPropsType } from './types/security-keys-signature-key-change-second-step-validate-repeat-access-key-props.handler.type';
// Utilities
import {
  checkHasEightNumbersOnly,
  differentFromCurrentSignatureKey,
} from '../../../utils/security-keys-signature-key-change-constraints.utils';

const saveNewSignatureKeyChangeInputEventHandler = ({
  signatureKey,
  newSignatureKey,
  setNewSignatureKey,
  setInputError,
  setInputErrorRepeat,
  repeatNewSignatureKey,
}: SaveNewSignatureKeyChangeInputEventHandlerPropsType): void => {
  setNewSignatureKey(newSignatureKey);
  if (checkHasEightNumbersOnly(newSignatureKey)) {
    validateSignatureKey({
      signatureKey,
      newSignatureKey,
      setInputError,
    });
    checkHasEightNumbersOnly(repeatNewSignatureKey) &&
      validateRepeatSignatureKeyChangeInputEventHandler({
        newSignatureKey,
        repeatNewSignatureKey,
        setInputErrorRepeat,
      });
  } else {
    setInputError(null);
    setInputErrorRepeat(null);
  }
};

// eslint-disable-next-line complexity
const validateSignatureKey = async ({
  signatureKey,
  newSignatureKey,
  setInputError,
}: ValidateSignatureKeyChangeInputEventHandlerPropsType): Promise<void> => {
  const [response, error] = await validateSignatureKeyResolver({
    signatureKey: newSignatureKey,
  });
  if (!error) {
    const errorMessages = response && response.failedValidations;
    const validateSignatureKeyDontRepeat = !differentFromCurrentSignatureKey({
      signatureKey,
      newSignatureKey,
    })
      ? [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_EQUALS_OLD_KEY]
      : [];

    setInputError(
      errorMessages && validateSignatureKeyDontRepeat.length > 0
        ? [...errorMessages, ...validateSignatureKeyDontRepeat]
        : errorMessages || validateSignatureKeyDontRepeat
    );
  }
};

const saveRepeatNewSignatureKeyChangeInputEventHandler = ({
  newSignatureKey,
  repeatNewSignatureKey,
  setInputErrorRepeat,
  setRepeatNewSignatureKey,
}: SaveRepeatNewSignatureKeyChangeInputEventHandlerPropsType): void => {
  setRepeatNewSignatureKey(repeatNewSignatureKey);
  if (checkHasEightNumbersOnly(repeatNewSignatureKey)) {
    validateRepeatSignatureKeyChangeInputEventHandler({
      newSignatureKey,
      repeatNewSignatureKey,
      setInputErrorRepeat,
    });
  } else {
    setInputErrorRepeat(null);
  }
};

const validateRepeatSignatureKeyChangeInputEventHandler = ({
  setInputErrorRepeat,
  newSignatureKey,
  repeatNewSignatureKey,
}: ValidateRepeatSignatureKeyChangeInputEventHandlerPropsType): void => {
  if (newSignatureKey !== repeatNewSignatureKey) {
    setInputErrorRepeat(
      SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration.DONT_MATCH as any
    );
  } else {
    setInputErrorRepeat(null);
  }
};

const SecurityKeysSignatureKeyChangeSecondStepHandlers = ({
  signatureKey,
  newSignatureKey,
  setInputError,
  setNewSignatureKey,
  setRepeatNewSignatureKey,
  setInputErrorRepeat,
  repeatNewSignatureKey,
}: SignatureKeyChangeStep2NewHandlersPropsType): SignatureKeyChangeStep2NewHandlersReturnType => ({
  handleSaveNewSignatureKeyChangeInputEvent: (newSignatureKey: string) =>
    saveNewSignatureKeyChangeInputEventHandler({
      signatureKey,
      setInputError,
      newSignatureKey,
      setNewSignatureKey,
      setInputErrorRepeat,
      repeatNewSignatureKey,
    }),
  handleSaveNewRepeatSignatureKeyChangeInputEvent: (repeatNewSignatureKey: string) =>
    saveRepeatNewSignatureKeyChangeInputEventHandler({
      newSignatureKey,
      repeatNewSignatureKey,
      setInputErrorRepeat,
      setRepeatNewSignatureKey,
    }),
});

export default SecurityKeysSignatureKeyChangeSecondStepHandlers;
