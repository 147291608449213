// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const OTP_SIDEBAR_PROPS = {
    testId: 'otp',
  },
  OTP_SIDEBAR_DESCRIPTION_PROPS = {
    testId: 'otp-sidebar-description',
    size: paragraphConstants.SIZES.M,
  };

export { OTP_SIDEBAR_PROPS, OTP_SIDEBAR_DESCRIPTION_PROPS };
