// Events handlers
import { ErrorHandlers } from '../handlers/error.handlers';
// Hooks
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Types
import { ErrorHookType } from 'providers/error/hooks/types/error.hook.type';
// Utilities
import { getBrowserOrSavedLanguage } from './utils/error.hook.utils';

const useErrorHook = (): ErrorHookType => {
  const { isHybrid, onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  return {
    ...ErrorHandlers({ isHybrid, onNavigateHybridFlow }),
    locale: getBrowserOrSavedLanguage().replace('_', '-'),
  };
};

export { useErrorHook };
