// Vendors
import { useEffect, useState } from 'react';
// Enumerations
import { SecurityCheckModalStepEnumerations } from './enumerations/security-check-modal-step.enumerations';
// Handlers
import SecurityCheckModalHandlers from '../handlers/security-check-modal.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { SecurityCheckModalHookType } from './types/security-check-modal.hook.type';
import { SecurityCheckModalHookPropsType } from './types/security-check-modal-props.hook.type';

const SecurityCheckModalHook = ({
  challenge,
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setError,
  setOtpValue,
  setShowDownloadSecurityCheckModal,
  showDownloadSecurityCheckModal,
}: SecurityCheckModalHookPropsType): SecurityCheckModalHookType => {
  const { formatMessage } = useCbIntl();
  const [modalStep, setModalStep] = useState<SecurityCheckModalStepEnumerations>(
    SecurityCheckModalStepEnumerations.FIRST
  );

  useEffect(() => {
    if (!showDownloadSecurityCheckModal) {
      setModalStep(SecurityCheckModalStepEnumerations.FIRST);
    }
  }, [showDownloadSecurityCheckModal]);

  return {
    formatMessage,
    modalStep,
    ...SecurityCheckModalHandlers({
      challenge,
      setChallenge,
      setChallenged,
      setChallengeError,
      setDownloadDocumentType,
      setError,
      setModalStep,
      setOtpValue,
      setShowDownloadSecurityCheckModal,
    }),
  };
};

export { SecurityCheckModalHook };
