// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  ScriptBoxComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import IvrCcConstraintsList from './components/constraints/ivr-component-wapper-cc-constraints';
import IvrComponentCcComponentFinalText from './components/final-text/ivr-component-wapper-cc-final-text';
import { IvrLoaderComponentCc } from './components/loader/ivr-loader.component.cc';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Constants
import { IVR_COMPONENT_CONSTANTS_CC } from './constants/ivr-component-wapper-cc.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import IvrComponentWrapperHook from './hooks/ivr-component-wrapper-cc.hook';
// Types
import { IvrComponentWrapperCCPropsType } from './types/ivr-component-wrapper-cc.component.type';
// Styled
import {
  IvrWrapperCcComponentStyled,
  IvrWrapperCcSeparatorStyled,
  IvrWrapperComponentCcTextStyled,
} from './ivr-component-wrapper-cc.component.styled';
// Translations
import { TRANSFER_IN_IVR_BUTTON_TITLE } from './translations/ivr-components-wrapper-cc.translations';

const IvrComponentWrapperCC = ({
  ivrFeedback,
  haveConstraints = true,
  initiatorIvr,
  resultIvr,
  text,
}: IvrComponentWrapperCCPropsType): React.ReactElement => {
  const { profile } = AppContextConsumerHook();
  const { handleInitiatorIvr, isLoadingIvr, result } = IvrComponentWrapperHook({
    initiatorIvr,
    resultIvr,
  });

  return (
    <WizardFeedbackComponent
      defaultResult={result}
      {...ivrFeedback}
      errorConfiguration={{
        ...ivrFeedback.errorConfiguration,
        buttonClick: handleInitiatorIvr,
      }}
    >
      <IvrWrapperCcComponentStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.containerId}>
        {!isLoadingIvr ? (
          <>
            <TitleHeaderComponent testId={IVR_COMPONENT_CONSTANTS_CC.titleId}>
              <FormattedMessageComponent id={text.title} />
            </TitleHeaderComponent>
            <IvrWrapperCcSeparatorStyled>
              <ScriptBoxComponent>
                <IvrWrapperComponentCcTextStyled>
                  {text?.initialText && (
                    <ParagraphComponent bold>
                      <FormattedMessageComponent
                        id={text?.initialText}
                        values={{ name: profile?.userDetails?.firstName }}
                      />
                    </ParagraphComponent>
                  )}
                  <ParagraphComponent testId={IVR_COMPONENT_CONSTANTS_CC.firstTextId}>
                    <FormattedMessageComponent id={text.firstText} />
                  </ParagraphComponent>
                  <ParagraphComponent testId={IVR_COMPONENT_CONSTANTS_CC.secondTextId}>
                    <FormattedMessageComponent id={text.secondText} />
                  </ParagraphComponent>
                  {haveConstraints && <IvrCcConstraintsList text={text} />}
                  <IvrComponentCcComponentFinalText text={text} />
                </IvrWrapperComponentCcTextStyled>
              </ScriptBoxComponent>
            </IvrWrapperCcSeparatorStyled>
            <WizardStepFooterComponent
              hasDefaultRightButton={false}
              rightButtonConfiguration={{
                testId: IVR_COMPONENT_CONSTANTS_CC.rightButtonTestId,
                onClick: handleInitiatorIvr,
                children: (
                  <FormattedMessageComponent id={text.buttonText ?? TRANSFER_IN_IVR_BUTTON_TITLE} />
                ),
                disabled: isLoadingIvr,
                loading: isLoadingIvr,
              }}
            />
          </>
        ) : (
          <IvrLoaderComponentCc />
        )}
      </IvrWrapperCcComponentStyled>
    </WizardFeedbackComponent>
  );
};

export default IvrComponentWrapperCC;
