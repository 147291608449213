// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_RESUME_LINK_LABEL_DEFAULT_TESTING_PROPS,
  CARD_SETTINGS_RESUME_LINK_VALUE_PROPS,
} from './constants/card-settings-resume-link-value.constants';
// Styles
import { CardSettingsResumeLinkValueComponentStyled } from './card-settings-resume-link-value.component.styled';
// Types
import { CardSettingsResumeLinkValueComponentType } from './types/card-settings-resume-link-value.component.type';

const CardSettingsResumeLinkValueComponent = ({
  testId = CARD_SETTINGS_RESUME_LINK_LABEL_DEFAULT_TESTING_PROPS,
  value,
}: CardSettingsResumeLinkValueComponentType): React.ReactElement | null =>
  value ? (
    <CardSettingsResumeLinkValueComponentStyled>
      <ParagraphComponent {...{ ...CARD_SETTINGS_RESUME_LINK_VALUE_PROPS, testId }}>
        {value}
      </ParagraphComponent>
    </CardSettingsResumeLinkValueComponentStyled>
  ) : null;

export { CardSettingsResumeLinkValueComponent };
