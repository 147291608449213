// Enums
import { DeviceModalEnum } from '../../device-modal/enumerations/device-modal.enumeration';
// Resolvers
import {
  unrollTrustedDeviceResolver,
  blockDeviceResolver,
  unblockDeviceResolver
} from 'containers/security-keys/views/devices/resolvers/signature-key-devices.resolvers';
// Translations
import {
  BIOMETRIC_ACCESS,
  BROWSER,
  NO,
  PLATFORM,
  ROOT_ACCESS_DESCRIPTION,
  ROOT_ACCESS,
  YES,
} from '../translations/device-info.translations';
// Types
import { DeviceInfoConfigurationsReturnType } from './types/device-info.configurations.return.type';
import { DeviceInfoConfigurationsType } from './types/device-info.configurations.type';
import { DeviceInfoConfigurationsResolverType } from './types/device-info.configurations.resolver.type';

export const getDeviceInfoConfig = ({ device, formatMessage }: DeviceInfoConfigurationsType): DeviceInfoConfigurationsReturnType => ({
  info: {
    platform: {
      title: formatMessage({ id: PLATFORM }),
      description:  (device.webDeviceInfo?.osVersion || device.mobileDeviceInfo?.platform)
  }, 
  ...(device.webDeviceInfo?.webBrowserType ? {
      browser: {
        title: formatMessage({ id: BROWSER }),
        description: device.webDeviceInfo?.webBrowserType }
      } : {})
  },
  ...(device.trusted ? {
      trustedInfo: {
        biometricAccess: {
          title: formatMessage({ id: BIOMETRIC_ACCESS }),
          description: formatMessage({ id: device.biometricEnabled ? YES : NO }),
        },
        rootAccess: {
          toolkit: {
          title: formatMessage({ id: ROOT_ACCESS }),
          content: formatMessage({ id: ROOT_ACCESS_DESCRIPTION }),
        },
          title: formatMessage({ id: ROOT_ACCESS }),
          description: formatMessage({ id: device.mobileDeviceInfo ? YES : NO }),
        }
    }
  }: {})
});

export const getResolverByType = (type: DeviceModalEnum): DeviceInfoConfigurationsResolverType => {
  return {
    [DeviceModalEnum.UNROLL_TRUSTED]: unrollTrustedDeviceResolver,
    [DeviceModalEnum.BLOCK]: blockDeviceResolver,
    [DeviceModalEnum.UNBLOCK]: unblockDeviceResolver
  }[type];
};