// Vendors
import React from 'react';
// Components
import { ErrorComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_ERROR_PROPS,
  RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_TITLE_PROPS,
} from './constants/recovery-key-step4-security-questions.constants';
// Styles
import { RecoveryKeyStep4SecurityQuestionsComponentStyled } from './recovery-key-step4-security-questions.component.styled';
// Translations
import { TITLE } from './translations/recovery-key-step4-security-questions.translations';

const RecoveryKeyStep4SecurityQuestionsComponent = (): React.ReactElement => (
  <RecoveryKeyStep4SecurityQuestionsComponentStyled>
    <TitleHeaderComponent {...RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_TITLE_PROPS}>
      <FormattedMessageComponent id={TITLE} />
    </TitleHeaderComponent>
    <ErrorComponent {...RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_ERROR_PROPS} />
  </RecoveryKeyStep4SecurityQuestionsComponentStyled>
);

export default RecoveryKeyStep4SecurityQuestionsComponent;
