// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_3D_SIDEBAR_PROPS = {
    testId: 'card-settings-check-3d',
  },
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_TITLE_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'card-settings-check-3d-sidebar-description-title',
  },
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_01_PROPS = {
    size: paragraphConstants.SIZES.M,
    testId: 'card-settings-check-3d-sidebar-description-01',
  },
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_02_PROPS = {
    size: paragraphConstants.SIZES.M,
    testId: 'card-settings-check-3d-sidebar-description-02',
  },
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_03_PROPS = {
    size: paragraphConstants.SIZES.M,
    testId: 'card-settings-check-3d-sidebar-description-03',
  };

export {
  CARD_SETTINGS_CHECK_3D_SIDEBAR_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_TITLE_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_01_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_02_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_03_PROPS,
};
