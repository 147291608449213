// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import { EURO_FORMAT_OPTIONS } from '../constants/financing-payment-method.constants';
// Enumerations
import { BillingPaymentMethodEnumeration } from 'enumerations/billing-payment-method.enumeration';
import { PaymentMethodsEnumeration } from '../enumerations/payment-methods.enumeration';
import { RevolvingMethodsEnumeration } from '../enumerations/revolving-methods.enumeration';
// Translations
import {
  FIX_AMOUNT,
  FULL_PAYMENT,
  PERCENTAGE_AMOUNT,
} from 'translations/billing-payment-methods.translations';
// Types
import { GetNewPaymentMethodParamsType } from './types/get-new-payment-method-params.type';
import { GetNewPaymentMethodType } from './types/get-new-payment-method.type';
import { GetNewPaymentMethodReturnType } from './types/get-new-payment-method-return.type';
import { GetNewPaymentMethodParamsReturnType } from './types/get-new-payment-method-params-return.type';
import { GetPaymentMethodLabelType } from './types/get-payment-method-label.type';
import { FormatPercentageAccountBalanceLabelType } from './types/format-percentage-account-balance-label.type';
import { FormatFixedPaymentAmountLabelType } from './types/format-fixed-payment-amount-label.type';
import { PaymentMethodParamsType } from '../types/payment-method-params.type';

const isFullPayment = (paymentMethod: PaymentMethodsEnumeration | null): boolean =>
  paymentMethod === PaymentMethodsEnumeration.FULL_PAYMENT;

const isFixAmount = (revolvingMethod: RevolvingMethodsEnumeration | null): boolean =>
  revolvingMethod === RevolvingMethodsEnumeration.FIX_AMOUNT;

const isPercentageAmount = (revolvingMethod: RevolvingMethodsEnumeration | null): boolean =>
  revolvingMethod === RevolvingMethodsEnumeration.PERCENTAGE_AMOUNT;

const getNewPaymentMethod = ({
  paymentMethod,
  revolvingMethod,
}: GetNewPaymentMethodType): GetNewPaymentMethodReturnType => {
  if (isFullPayment(paymentMethod)) {
    return BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE;
  }

  if (isFixAmount(revolvingMethod)) {
    return BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT;
  }

  if (isPercentageAmount(revolvingMethod)) {
    return BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE;
  }
};

const getPaymentMethodParams = ({
  fee,
  paymentMethod,
  revolvingMethod,
}: GetNewPaymentMethodParamsType): GetNewPaymentMethodParamsReturnType => {
  const newPaymentMethod = getNewPaymentMethod({
    paymentMethod,
    revolvingMethod,
  });

  if (!newPaymentMethod) return;

  const paymentMethodParams: PaymentMethodParamsType = {
    creditDetails: {
      paymentMethod: newPaymentMethod,
    },
  };

  if (newPaymentMethod === BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT && fee) {
    paymentMethodParams.creditDetails.fixedPaymentAmount = fee;
  }

  if (newPaymentMethod === BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE && fee) {
    paymentMethodParams.creditDetails.accountBalancePercentage = fee;
  }

  return paymentMethodParams;
};

const formatFixedPaymentAmountLabel = ({
  fixedPaymentAmount,
  formatNumber,
  formatMessage,
}: FormatFixedPaymentAmountLabelType): string => {
  if (fixedPaymentAmount === null) {
    return '';
  }

  return `${formatMessage({ id: FIX_AMOUNT.TITLE })} (${formatNumber(
    fixedPaymentAmount,
    EURO_FORMAT_OPTIONS
  )})`;
};

const formatPercentageAccountBalanceLabel = ({
  accountBalancePercentage,
  formatMessage,
  formatNumber,
}: FormatPercentageAccountBalanceLabelType): string => {
  if (accountBalancePercentage === null) {
    return '';
  }

  return `${formatMessage({ id: PERCENTAGE_AMOUNT.TITLE })} (${formatNumber(
    accountBalancePercentage / 100,
    {
      style: MiscellaneousCurrencyConstants.AMOUNT_PERCENTAGE_FORMAT_PROPS.style,
    }
  )})`;
};

const getPaymentMethodLabel = ({
  accountBalancePercentage,
  fixedPaymentAmount,
  formatMessage,
  formatNumber,
  paymentMethodCode,
}: GetPaymentMethodLabelType): string => {
  if (!paymentMethodCode) return '';

  const labels = {
    [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: formatFixedPaymentAmountLabel({
      fixedPaymentAmount,
      formatNumber,
      formatMessage,
    }),
    [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]:
      formatPercentageAccountBalanceLabel({
        accountBalancePercentage,
        formatMessage,
        formatNumber,
      }),
    [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: formatMessage({
      id: FULL_PAYMENT.TITLE,
    }),
  };

  return labels[paymentMethodCode] || '';
};

export {
  getNewPaymentMethod,
  getPaymentMethodLabel,
  getPaymentMethodParams,
  isFixAmount,
  isFullPayment,
  isPercentageAmount,
};
