// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import { SignatureKeySidebarComponentStyled } from './security-keys-signature-key-change-sidebar.component.styled';
// Constants
import constants from './constants/security-keys-signature-key-change-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import translations from './translations/security-keys-signature-key-change-sidebar.translations';

const SecurityKeysSignatureKeyChangeSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.SIGNATURE_KEY_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.SIDEBAR_MAIN_TITLE} />}
    >
      <SignatureKeySidebarComponentStyled>
        <ParagraphComponent bold {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_01_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TITLE_1} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_02_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_1} />
        </ParagraphComponent>
        <ParagraphComponent bold {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_03_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TITLE_2} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_04_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_2} />
        </ParagraphComponent>
        <ParagraphComponent bold {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_05_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TITLE_3} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_06_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_3} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.SIGNATURE_KEY_SIDEBAR_MESSAGE_07_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_4} />
        </ParagraphComponent>
      </SignatureKeySidebarComponentStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default SecurityKeysSignatureKeyChangeSidebarComponent;
