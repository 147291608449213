// Vendors
import { useEffect } from 'react';
// Handlers
import { DevicesManagerTrackingHandlers } from '../handlers/devices.manager.view.tracking.handlers';
import { DevicesManagerHandlers } from '../handlers/devices.manager.view.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';

const DevicesManagerHook = (): void => {
  const { onEnter } = DevicesManagerHandlers({
    ...AppTrackingHook(DevicesManagerTrackingHandlers)
  });

  useEffect(() => { onEnter(); }, []);
};

export { DevicesManagerHook };