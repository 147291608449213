// Types
import { TransactionsEppStep1SimulationOptionsUtilsType } from './types/transactions-epp-step1-simulation-options.utils.type';

const isEppSimulationOptionSelected = ({
  id,
  installments,
  isAllOptionsSelected,
}: TransactionsEppStep1SimulationOptionsUtilsType): boolean =>
  Boolean(id === installments && !isAllOptionsSelected);

export { isEppSimulationOptionSelected };
