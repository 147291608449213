// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
// Types
import { FileDataType } from 'types/file-data.type';

const ERROR_STATES = [
  DocumentsUploadStatesEnumeration.BACK_OFFICE_ERROR,
  DocumentsUploadStatesEnumeration.REJECTED,
  DocumentsUploadStatesEnumeration.SYSTEM_ERROR,
];

export const shouldDisableSendButton = (files: FileDataType[]): boolean =>
  files.some(file => ERROR_STATES.includes(file.state)) ||
  !files.some(file => file.state === DocumentsUploadStatesEnumeration.UPLOADED);
