// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Constants
import {
  FINANCING_COMMON_ROUTER_ROUTES,
  FINANCING_INSURANCES_PATH,
} from './financing-router.constants.common';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Views
import { FinancingCreditLimitIncreaseView } from 'containers/financing/views/credit-limit-increase/credit-limit-increase.view';

const FINANCING_ROUTER_ROUTES: RouteType[] = [
  ...FINANCING_COMMON_ROUTER_ROUTES,
  {
    element: FinancingCreditLimitIncreaseView,
    props: { path: RoutesEnumeration.CREDIT_LIMIT_INCREASE },
  },
];

export { FINANCING_INSURANCES_PATH, FINANCING_ROUTER_ROUTES };
