// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { PersonalEmailStep1NewBuilderReturnTrackingHandlersType } from './types/personal-email-step1-new-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const modifyEmailNewInputErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'newModifyEmail',
    eventLabel: 'newModifyEmail',
    errorCategory: 'invalidEmail',
    errorDescription: 'invalidEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const modifyEmailNewInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'newModifyEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalEmailStep1NewTrackingHandlers = (
  track: TrackBuilderType
): PersonalEmailStep1NewBuilderReturnTrackingHandlersType => ({
  handleModifyEmailNewInputClickTracking: () => track(modifyEmailNewInputClickTrackingHandler()),
  handleModifyEmailNewInputErrorTracking: () => track(modifyEmailNewInputErrorTrackingHandler()),
});

export default PersonalEmailStep1NewTrackingHandlers;
