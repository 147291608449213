// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/documentation.context.constants';
// Enums
import { DocumentErrorTypeEnumeration } from '../enumerations/documentation.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { type DocumentationContextType } from './types/documentation.context.type';
import { DocumentsType } from 'types/documents.type';

const DocumentationContext = createContext<DocumentationContextType>(CONTEXT_DEFAULT_VALUE);

export const DocumentationContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<DocumentationContextType>> => {
  const [documents, setDocuments] = useState<DocumentsType[] | null>([]);
  const [categorySelected, setCategorySelected] = useState<string>('');
  const [categoryError, setCategoryError] = useState<DocumentErrorTypeEnumeration>();
  const [documentsError, setDocumentsError] = useState<boolean>(false);
  const [categories, setCategories] = useState<string[]>([]);

  return (
    <DocumentationContext.Provider
      value={{
        categories,
        categoryError,
        categorySelected,
        documents,
        documentsError,
        setCategories,
        setCategoryError,
        setCategorySelected,
        setDocuments,
        setDocumentsError,
      }}
    >
      {children}
    </DocumentationContext.Provider>
  );
};

export const useDocumentationContextConsumerHook = (): DocumentationContextType =>
  useContext(DocumentationContext);

/* eslint-disable */
export const DocumentationContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<DocumentationContextType>> =>
    (
      <DocumentationContextProvider>
        <Component {...props} />
      </DocumentationContextProvider>
    );
