// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/transactions-table-link.constants';
// Styles
import {
  TransactionsTableLinkComponentStyled,
  TransactionsTableLinkContentComponentStyled,
} from './transactions-table-link.component.styled';
// Translations
import translations from './translations/transactions-table-link.translations';
// Types
import { TransactionsTableLinkComponentType } from './types/transactions-table-link.component.type';
// Utilities
import { showLoadMoreTransactionsLink } from './utils/transactions-table-link.utils';

const TransactionsTableLinkComponent = ({
  metadata,
  componentError,
  onClick,
  innerPadding,
}: TransactionsTableLinkComponentType): React.ReactElement<TransactionsTableLinkComponentType> | null =>
  showLoadMoreTransactionsLink(componentError, metadata) ? (
    <TransactionsTableLinkComponentStyled {...{ innerPadding: innerPadding }}>
      <TransactionsTableLinkContentComponentStyled>
        <LinkComponent {...{ ...constants.TABLE_LINK_PROPS, onClick }}>
          <FormattedMessageComponent id={translations.VIEW_MORE} />
        </LinkComponent>
      </TransactionsTableLinkContentComponentStyled>
    </TransactionsTableLinkComponentStyled>
  ) : null;

export default TransactionsTableLinkComponent;
