// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { InputDropdownPhoneNumberComponent } from '@openbank/cb-ui-commons';
// Custom Hooks
import usePersonalNumberStep1PhoneHook from './hooks/personal-number-step1-phone.hook';
// Utilities
import { getMappedPhonePrefixes } from 'utils/phone.utils';
// Translations
import {
  HELPER_ERROR_MESSAGE,
  LABEL,
  PLACEHOLDER,
} from './translations/personal-number-step1-phone.translations';
// Constants
import { MODIFY_PHONE_STEP1_PHONE_PROPS } from './constants/personal-number-step1-phone.constants';

const PersonalNumberStep1PhoneComponent = (): React.ReactElement => {
  const {
    formatMessage,
    handleSavePersonalNumberStep1NumberPrefixOptionClick: onSelect,
    handleSavePersonalNumberStep1NumberInputEvent: onChange,
    handleValidatePersonalNumberStep1NumberInputEvent: onBlur,
    fetching: disabled,
    error,
    phoneNumber: number,
    prefixes,
    canUpdatePhone,
    defaultPrefix,
  } = usePersonalNumberStep1PhoneHook();

  return (
    <InputDropdownPhoneNumberComponent
      {...{ ...MODIFY_PHONE_STEP1_PHONE_PROPS, number }}
      {...{ onBlur, onChange, onSelect }}
      dropdownValues={getMappedPhonePrefixes(prefixes)}
      errorMessage={error && <FormattedMessageComponent id={HELPER_ERROR_MESSAGE} />}
      inputPlaceholder={formatMessage({ id: PLACEHOLDER })}
      label={<FormattedMessageComponent id={LABEL} />}
      prefix={defaultPrefix}
      value={defaultPrefix}
      disabled={!canUpdatePhone || disabled}
    />
  );
};

export default PersonalNumberStep1PhoneComponent;
