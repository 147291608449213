const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbTransactions.createInstallmentsPlan.step1.cbResume.';

const FINANCED = `${DOMAIN}amountToFinanceLabel`,
  MONTHLY = `${DOMAIN}monthlyInstallmentAmountLabel`,
  TOTAL_INTERESTS = `${DOMAIN}totalInterestsLabel`,
  TOTAL_PAID = `${DOMAIN}amountToPayLabel`,
  VIEW_AMORTIZATION_TABLE = `${DOMAIN}viewAmortizationTableLink`;

export { FINANCED, TOTAL_INTERESTS, TOTAL_PAID, MONTHLY, VIEW_AMORTIZATION_TABLE };
