// Components
import { WizardComponent } from '@openbank/cb-ui-commons';
import SupportCancelContractFirstStep from '../../first-step/support-cancel-contract-first-step.component';
import SupportCancelContractSecondStep from '../../second-step/support-cancel-contract-second-step.component';
// Translations
import {
  FAILED,
  SUCCESS,
  STEP_1,
  STEP_2,
} from '../translations/support-cancel-contract-wizard.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { SupportCancelContractWizardType } from './types/support-cancel-contract-wizard.type';

const getSupportCancelContractWizardConfiguration = ({
  onLinkClick,
}: SupportCancelContractWizardType): React.ComponentProps<typeof WizardComponent> => ({
  feedbackConfiguration: {
    errorConfiguration: {
      description: FAILED.CONFIRMATION.CONTENT,
      title: FAILED.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SUPPORT,
    },
    successConfiguration: {
      description: SUCCESS.CONFIRMATION.CONTENT,
      title: SUCCESS.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SUPPORT,
      linkLabel: SUCCESS.BACK_BUTTTON,
      linkClick: onLinkClick,
    },
  },
  steps: [
    {
      component: SupportCancelContractFirstStep,
      title: STEP_1,
    },
    {
      component: SupportCancelContractSecondStep,
      title: STEP_2,
    },
  ],
});

export { getSupportCancelContractWizardConfiguration };
