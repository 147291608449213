// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const SupportCancelContractAlreadyCancelledStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
  align-items: center;
  text-align: center;

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`; 

export const SupportCancelContractAlreadyCancelledParagraphStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
  align-items: center;
  text-align: center;
`;
