// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_CONTENT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
    marginTop: AmazonTheme.default.spaces.spacingS,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_PROPS = {
    type: 'accordion',
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_PROPS,
};
