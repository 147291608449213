// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';

const useTitleSectionStatusHook = (): any => {
  const { currentCard } = AppContextConsumerHook(),
    { status } = currentCard;

  return { status };
};

export default useTitleSectionStatusHook;
