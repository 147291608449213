// Vendors
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Contexts
import { useFinancingPPIContextConsumerHook } from '../contexts/financing-ppi.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Handlers
import { FinancingInsurancesHandlers } from '../handlers/financing-insurances.handlers';

const useFinancingPPIHook = (): void => {
  const location = useLocation();

  const { fetchPPI, setFetchPPI } = useFinancingPPIContextConsumerHook();

  const { handleFetchInsurancesInfo } = useInsurancesContextConsumerHook();

  const { handleInsurancesInfoRefetchEnd } = FinancingInsurancesHandlers({
    setFetchPPI,
  });

  useEffect(() => {
    fetchPPI && handleFetchInsurancesInfo().finally(handleInsurancesInfoRefetchEnd);
  }, [location]);
};

export { useFinancingPPIHook };
