const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.billing.';
const TOOLTIP_DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.';

const BILLING_ACCOUNT = `${DOMAIN}billingAccountLabel`;
const BILLING_CYCLE_LABEL = `${DOMAIN}billingCycleLabel`;
const CARD_STATEMENTS_LINK = `${DOMAIN}viewCardStatementsLink`;
const CARD_STATEMENTS = `${DOMAIN}cardStatementsLabel`;
const TITLE = `${DOMAIN}title`;
const TOOTLTIP_TITLE = `${TOOLTIP_DOMAIN}billingAccountTooltipTitle`;
const TOOLTIP_CONTENT = `${TOOLTIP_DOMAIN}billingAccountTooltip`;

export {
  BILLING_ACCOUNT,
  BILLING_CYCLE_LABEL,
  CARD_STATEMENTS_LINK,
  CARD_STATEMENTS,
  TITLE,
  TOOTLTIP_TITLE,
  TOOLTIP_CONTENT,
};
