// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
import { PersonalNumberStep2ContextConsumerHook } from 'containers/personal/views/number/components/step2/contexts/personal-number-step2.context';
// Hooks
import useTimer from 'hooks/use-timer.hook';
// Event handlers
import PersonalNumberStep2ChallengeHandlers from '../handlers/personal-number-step2-challenge.handlers';
import PersonalNumberStep2TrackingHandlers from '../handlers/personal-number-step2-challenge.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalNumberStep2ChallengeHookType } from './types/personal-number-step2-challenge.hook.type';
import { PersonalNumberStep2ChallengePropsType } from './../types/personal-number-step2-challenge-props.type';
// Utils
import { getTimerLabelTranslationId } from './utils/personal-number-step2-challenge.hook.utils';
// Constants
import constants from './constants/personal-number-step2-challenge.hook.constants';

const PersonalNumberStep2ChallengeHook = ({
  onRetry,
}: Pick<
  PersonalNumberStep2ChallengePropsType,
  'onRetry'
>): PersonalNumberStep2ChallengeHookType => {
  const { formatMessage } = useCbIntl();
  const [error, setError] = useState<boolean | null>(null);

  const { otpId, otpValue, setOtpValue } = PersonalNumberStep2ContextConsumerHook(),
    { challenged, fetching, newPrefix, newPhoneNumber, setChallenged, setFetching } =
      PersonalNumberContextConsumerHook();

  const { timer, isTimerActive, handleRestart } = useTimer(constants.DEFAULT_RETRY_TIMER);

  const timerLabelTranslationId = getTimerLabelTranslationId(timer);

  const handlerProps = {
    onRetry,
    handleRestart,
    props: {
      ...AppTrackingHook(PersonalNumberStep2TrackingHandlers),
      newPrefix,
      newPhoneNumber,
      otpId,
      otpValue,
      setChallenged,
      setError,
      setFetching,
    },
  };

  return {
    ...PersonalNumberStep2ChallengeHandlers(handlerProps),
    challenged,
    error,
    fetching,
    formatMessage,
    otpValue,
    setOtpValue,
    isTimerActive,
    timer,
    timerLabelTranslationId,
  };
};

export default PersonalNumberStep2ChallengeHook;
