// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { HookType } from './types/card-settings.hook.type';

const Hook = (): HookType => {
  const { formatMessage } = useCbIntl();

  const { currentCard } = AppContextConsumerHook();

  return { currentCard, formatMessage };
};

export default Hook;
