// Resolvers
import { fetchInsurancesInfoResolver } from '../resolvers/insurances.resolvers';
// Types
import { InsurancesReturnHandlersType } from './types/insurances-return.handlers.type';
import { InsurancesHandlersType } from './types/insurances.handlers.type';

const fetchInsurancesInfoHandler = async ({
  setFetchError,
  setFetching,
  setInsurancesInfo,
}: InsurancesHandlersType): Promise<void> => {
  try {
    setFetching(true);

    const [response, error] = await fetchInsurancesInfoResolver();

    if (error || !response) {
      throw new Error();
    }

    setInsurancesInfo(response);
  } catch (error) {
    setFetchError(true);
  } finally {
    setFetching(false);
  }
};

export const InsurancesHandlers = (
  props: InsurancesHandlersType
): InsurancesReturnHandlersType => ({
  handleFetchInsurancesInfo: () => fetchInsurancesInfoHandler(props),
});
