// Vendors
import React from 'react';
// Components
import { FilterModalListSelectorComponent } from '../../components/list-selector/filter-modal-list-selector.component';
import { FilterModalListSelectorDateComponent } from '../../components/list-selector-date/filter-modal-list-selector-date.component';
import { FilterModalInputRangeComponent } from '../../components/input-range/filter-modal-input-range.component';
// Constants
import { SITUATION_OPTIONS } from 'components/filters/components/filter-modal/constants/filter-modal.constants';
// Enumerations
import { FilterModalInputRangeTypesEnumeration } from '../../components/input-range/enumerations/filter-modal-input-range-types.enumeration';
// Translations
import { MOVEMENTS_TITLE } from '../transactions-filter-modal-configuration/translations/transactions-filter-modal.translations';
import {
  GetTransactionsLandingFilterModalConfigurationReturnType,
  GetTransactionsLandingFilterModalConfigurationType,
} from '../transactions-filter-modal-configuration/types/transactions-landing-filter-modal.configuration.type';
// Types

const getNotificationLandingFilterModalConfiguration = ({
  formatMessage,
  formValues,
  handleFormValuesChange,
}: GetTransactionsLandingFilterModalConfigurationType): GetTransactionsLandingFilterModalConfigurationReturnType => {
  return {
    filterModalContentConfiguration: [
      {
        id: 'list-dates',
        component: (
          <FilterModalListSelectorDateComponent
            {...{
              formValues,
              handleFormValuesChange,
            }}
          />
        ),
      },
    ],
  };
};

export { getNotificationLandingFilterModalConfiguration };
