// Constants
import { CHANGE_3D_SECURE_CODE_SERVICE_CONFIGURATION } from '../services/constants/card-settings-change-3d-step2.service.constants';

const CARD_SETTINGS_CHANGE_3D_CODE_CHALLENGE_PROPS = {
    baseService: CHANGE_3D_SECURE_CODE_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'put',
  },
  CARD_SETTINGS_CHANGE_3D_CODE_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'card-settings-change-3d-code-button',
  },
  CARD_SETTINGS_CHANGE_3D_STEP_2_RESUME_PROPS = {
    testId: 'card-settings-change-3d-step2',
  };

export {
  CARD_SETTINGS_CHANGE_3D_CODE_BUTTON_PROPS,
  CARD_SETTINGS_CHANGE_3D_CODE_CHALLENGE_PROPS,
  CARD_SETTINGS_CHANGE_3D_STEP_2_RESUME_PROPS,
};
