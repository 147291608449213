const DOMAIN = 'cobranded.services.userSettings.login.clLogin.';
const DOMAIN_EMAIL = `${DOMAIN}enterEmail.`;
const DOMAIN_PASSWORD = `${DOMAIN}passwordPositions.`;

const ACCESS_DATA = {
  MESSAGE: `${DOMAIN}accessData.message`,
  BUTTON: `${DOMAIN}accessData.button`,
};

const ONBOARDING_INCOMPLETE_ERROR = `${DOMAIN}onboardingIncomplete.message`;

const EMAIL = {
  ABOUT_PASSWORDS: `${DOMAIN_EMAIL}aboutYourPasswords`,
  BUTTON: `${DOMAIN_EMAIL}continueButton`,
  CHECKBOX_LABEL: `${DOMAIN_EMAIL}clCheckbox.label`,
  ERROR_INPUT: `${DOMAIN_EMAIL}cbMessage.invalidEmailDescription`,
  INPUT_LABEL: `${DOMAIN_EMAIL}clInput.label`,
  LINK_1: `${DOMAIN_EMAIL}link1`,
  LINK_2: `${DOMAIN_EMAIL}link2`,
  LINK_3: `${DOMAIN_EMAIL}link3`,
  PLACEHOLDER: `${DOMAIN_EMAIL}clInput.placeholder`,
  REQUEST_ACCESS: `${DOMAIN_EMAIL}requestYourCardAmazon`,
  SECURITY_ADVICE: `${DOMAIN_EMAIL}securityAdvice`,
  TITLE: `${DOMAIN_EMAIL}title`,
  VERIFY_ACCOUNT: `${DOMAIN_EMAIL}verifyMyAccount`,
  FORGET_PASSWORD: `${DOMAIN_EMAIL}forgotPasswordLink`,
  PASSWORD_POSITIONS: `${DOMAIN_EMAIL}passwordPositions`,
};

const PASSWORD = {
  ABOUT_PASSWORDS: `${DOMAIN_PASSWORD}aboutYourPasswords`,
  BUTTON: `${DOMAIN_PASSWORD}enterButton`,
  CHECKBOX_LABEL: `${DOMAIN_PASSWORD}clCheckbox.label`,
  ERROR_INPUT: `${DOMAIN_PASSWORD}cbMessage.invalidPositionsErrorDescription`,
  FORGET_PASSWORD: `${DOMAIN_PASSWORD}forgotPasswordLink`,
  INPUT_LABEL: `${DOMAIN_PASSWORD}clInput.label`,
  INPUT_SUBTITLE: `${DOMAIN_PASSWORD}clInput.subtitle`,
  LINK_1: `${DOMAIN_PASSWORD}link1`,
  LINK_2: `${DOMAIN_PASSWORD}link2`,
  LINK_3: `${DOMAIN_PASSWORD}link3`,
  PLACEHOLDER: `${DOMAIN_PASSWORD}clInput.placeholder`,
  REQUEST_ACCESS: `${DOMAIN_PASSWORD}requestYourCardAmazon`,
  SECURITY_ADVICE: `${DOMAIN_PASSWORD}securityAdvice`,
  TITLE: `${DOMAIN_PASSWORD}title`,
  VERIFY_ACCOUNT: `${DOMAIN_PASSWORD}verifyMyAccount`,
};

export { EMAIL, PASSWORD, ACCESS_DATA, ONBOARDING_INCOMPLETE_ERROR };
