export default {
  PERSONAL_NUMBER_STEP_4_ACTIONS: {
    CONFIRM_PROPS: {
      testId: 'personal-number-step4-footer-confirm',
    },
    WIZARD_PROPS: {
      hasDefaultLeftButton: true,
    },
  },
};
