// Vendors
import React from 'react';
// Components
import { SelectorBoxComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TransactionsReportLandingOptionsMessageComponent } from './components/message/transactions-report-landing-options-message.component';
// Constants
import { OPTIONS } from './constants/transactions-report-landing-options.constants';
// Styles
import {
  TransactionsReportLandingOptionsBoxContentStyled,
  TransactionsReportLandingOptionsComponentStyled,
} from './transactions-report-landing-options.component.styled';
// Types
import { TransactionsReportLandingOptionsComponentType } from './types/transactions-report-landing-options.component.type';
// Utils
import { isSelected, isShownMessage } from './utils/transactions-report-landing-options.utils';

export const TransactionsReportLandingOptionsComponent = ({
  selected,
  onChange,
}: TransactionsReportLandingOptionsComponentType): React.ReactElement => (
  <TransactionsReportLandingOptionsComponentStyled>
    {OPTIONS.map(({ description, eventLabel, hasMessage, testId, title, value }) => (
      <SelectorBoxComponent
        key={title}
        testId={testId}
        title={<FormattedMessageComponent id={title} />}
        selected={isSelected({ selected, value })}
        onClick={() => {
          onChange({ eventLabel, value });
        }}
      >
        <TransactionsReportLandingOptionsBoxContentStyled>
          <ParagraphComponent>
            <FormattedMessageComponent id={description} />
          </ParagraphComponent>
          <TransactionsReportLandingOptionsMessageComponent
            showMessage={isShownMessage({ selected, value, hasMessage })}
          />
        </TransactionsReportLandingOptionsBoxContentStyled>
      </SelectorBoxComponent>
    ))}
  </TransactionsReportLandingOptionsComponentStyled>
);
