// Types
import { FinancingPPILandingStatusBuilderType } from '../types/financing-ppi-landing-status.configuration.type';

const getPPIEndCoverageDate = ({
  formatDate,
  endCoverageDate,
}: Pick<FinancingPPILandingStatusBuilderType, 'formatDate' | 'endCoverageDate'>): string =>
  endCoverageDate ? formatDate(endCoverageDate) : '';

export { getPPIEndCoverageDate };
