// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Enums
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';
// Types
import { PersonalPhotoAvatarsBuilderReturnTrackingHandlersType } from './types/personal-photo-avatars-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const selectNewAvatarAssetClickHandlerTracking = (
  id: AvatarIdsEnumeration
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'newAvatar:' + id,
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalPhotoAvatarsTrackingHandlers = (
  track: TrackBuilderType
): PersonalPhotoAvatarsBuilderReturnTrackingHandlersType => ({
  handleSelectNewAvatarAssetClickTracking: (id: AvatarIdsEnumeration) =>
    track(selectNewAvatarAssetClickHandlerTracking(id)),
});

export default PersonalPhotoAvatarsTrackingHandlers;
