// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { LIMIT_INCREASE_CASES_URL } from './constants/commons.constants';
import { SELECT_METHOD_URL_SUFFIX } from './constants/step-3-accept-terms.constants';
// Enumerations
import { SelectMethodEnumeration } from './enumerations/select-method.enumeration';
// Types
import { SelectMethodResolverType } from '../resolvers/types/step-3-select-method.resolvers.type';
// Utils
import { getAggregationCallback } from './utils/step-0-init.services.utils';

export const selectMethodService = ({
  caseId,
  isUploadDocumentation,
}: SelectMethodResolverType): Promise<[any, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${SELECT_METHOD_URL_SUFFIX}`,
    params: {
      method: isUploadDocumentation
        ? SelectMethodEnumeration.UPLOAD_DOCUMENTATION
        : SelectMethodEnumeration.BANK_AGGREGATION,
      aggregationCallback: getAggregationCallback(),
    },
  });
