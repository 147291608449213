// Enumerations
import { ValidationStatus } from './../handlers/utils/enumerations/amoun.handlers.utils.enumerations';

const DOMAIN_FAST_CASH_FIRST_STEP =
  'cobranded.services.userSettings.privateArea.financing.fastCash.firstStep.amount.';

const AMOUNT_TITLE = `${DOMAIN_FAST_CASH_FIRST_STEP}title`;
const TOOLTIP = `${DOMAIN_FAST_CASH_FIRST_STEP}tooltip`;
const INPUT_LABEL = `${DOMAIN_FAST_CASH_FIRST_STEP}inputLabel`;
const INPUT_ERRORS = {
  [ValidationStatus.BelowMin]: `${DOMAIN_FAST_CASH_FIRST_STEP}inputError.min`,
  [ValidationStatus.AboveMax]: `${DOMAIN_FAST_CASH_FIRST_STEP}inputError.max`,
  [ValidationStatus.NotMultipleOf100]: `${DOMAIN_FAST_CASH_FIRST_STEP}inputError.multiple`,
};

export { AMOUNT_TITLE, INPUT_LABEL, TOOLTIP, INPUT_ERRORS };
