// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Types
import { ChallengeUtilsType } from 'utils/challenges/types/challenge.utils.type';

const challengeFlowButtonIsDisabled = ({
  challenged,
  challengeError,
  fetching,
}: ChallengeUtilsType): boolean => Boolean(fetching || !challenged || challengeError);

const isWrongOTPError = (error?: number): boolean => error === HttpStatusCodesEnum.FORBIDDEN;

export { challengeFlowButtonIsDisabled, isWrongOTPError };
