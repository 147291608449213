// Vendors
import { paragraphConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CONTRACT_SIDEBAR_PROPS = {
  testId: 'card-settings-contract',
};
const CARD_SETTINGS_CONTRACT_SIDEBAR_DESCRIPTION_PROPS = {
  size: paragraphConstants.SIZES.M,
  testId: 'card-settings-contract-sidebar-description',
};

export { CARD_SETTINGS_CONTRACT_SIDEBAR_PROPS, CARD_SETTINGS_CONTRACT_SIDEBAR_DESCRIPTION_PROPS };
