// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { SecurityKeysSignatureKeyChangeContextConsumerHook } from '../../../contexts/security-keys-signature-key-change.context';
// Enumerations
import { SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration } from '../enumerations/security-keys-signature-key-change-second-step.enumeration';
// Handlers
import SecurityKeysSignatureKeyChangeSecondStepHandlers from '../handlers/security-keys-signature-key-change-second-step.handlers';
import SecurityKeysSignatureKeyChangeSecondStepTrackingHandlers from '../handlers/security-keys-signature-key-change-second-step.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SecurityKeysSignatureKeyChangeSecondStepHookType } from './types/security-keys-signature-key-change-second-step.hook.type';

const SecurityKeysSignatureKeyChangeSecondStepHook = (): SecurityKeysSignatureKeyChangeSecondStepHookType => {
  const [inputErrorRepeat, setInputErrorRepeat] =
    useState<SecurityKeysSignatureKeyChangeSecondStepRepeatErrorIdEnumeration | null>(null);
  const {
    signatureKey,
    newSignatureKey,
    setNewSignatureKey,
    repeatNewSignatureKey,
    setRepeatNewSignatureKey,
    inputError,
    setInputError,
  } = SecurityKeysSignatureKeyChangeContextConsumerHook();
  const {
    handleFirstPasswordPositionInputClickTracking,
    handleSecondPasswordPositionInputClickTracking,
    handleOnEnterSecondStepSignatureKeyChangeTrackingHandler,
    handleShowHidePasswordClickTracking,
  } = AppTrackingHook(SecurityKeysSignatureKeyChangeSecondStepTrackingHandlers);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleOnEnterSecondStepSignatureKeyChangeTrackingHandler();
  }, []);

  return {
    ...SecurityKeysSignatureKeyChangeSecondStepHandlers({
      signatureKey,
      newSignatureKey,
      setInputError,
      setNewSignatureKey,
      setRepeatNewSignatureKey,
      setInputErrorRepeat,
      repeatNewSignatureKey,
    }),
    handleShowHidePasswordClickTracking,
    handleFirstPasswordPositionInputClickTracking,
    handleSecondPasswordPositionInputClickTracking,
    inputError,
    newSignatureKey,
    repeatNewSignatureKey,
    inputErrorRepeat,
  };
};

export default SecurityKeysSignatureKeyChangeSecondStepHook;
