// Enumerations
import { CardControlIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-ids.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cardControl.operations.cbToast.';

export default {
  [CardControlIdsEnumeration.PHYSICAL_STORES]: {
    ACTIVATE: {
      ERROR_MESSAGE: `${DOMAIN}activatedPhysicalStoresError`,
      SUCCESS_MESSAGE: `${DOMAIN}activatedPhysicalStoresSuccess`,
    },
    DEACTIVATE: {
      ERROR_MESSAGE: `${DOMAIN}deactivatedPhysicalStoresError`,
      SUCCESS_MESSAGE: `${DOMAIN}deactivatedPhysicalStoresSuccess`,
    },
  },
  [CardControlIdsEnumeration.ONLINE_STORES]: {
    ACTIVATE: {
      ERROR_MESSAGE: `${DOMAIN}activatedOnlineStoresError`,
      SUCCESS_MESSAGE: `${DOMAIN}activatedOnlineStoresSuccess`,
    },
    DEACTIVATE: {
      ERROR_MESSAGE: `${DOMAIN}deactivatedOnlineStoresError`,
      SUCCESS_MESSAGE: `${DOMAIN}deactivatedOnlineStoresSuccess`,
    },
  },
};
