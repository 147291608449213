// Vendors
import styled from 'styled-components';

const CardSettingsContractCurrentBillingMethodValueComponentStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export { CardSettingsContractCurrentBillingMethodValueComponentStyled };
