// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { TransactionsReportFraudulentMovementConfirmationActionsHandlers } from '../handlers/transactions-report-fraudulent-movement-confirmation-actions.handlers';
// Hooks
import { useCleanChallengeHook } from 'containers/transactions/views/report/hooks/clean-challenge.hook';
// Types
import { TransactionsReportFraudulentMovementConfirmationActionsHookReturnType } from './types/transactions-report-fraudulent-movement-confirmation-actions.hook.type';

export const useTransactionsReportFraudulentMovementConfirmationActionsHook =
  (): TransactionsReportFraudulentMovementConfirmationActionsHookReturnType => {
    const { setCard } = ManagerContextConsumerHook();
    const { selectedOptions, files, blockReason, addresses, status, setFiles, setSelectedOptions } =
      TransactionsReportFraudulentMovementConsumerHook();
    const { challenge, challengeError, setChallengeError, setCaseId, setError } =
      useTransactionsReportContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    useCleanChallengeHook();

    return {
      ...TransactionsReportFraudulentMovementConfirmationActionsHandlers({
        addresses,
        blockReason,
        cardId,
        challenge,
        files,
        selectedOptions,
        setCard,
        setCaseId,
        setChallengeError,
        setSelectedOptions,
        setError,
        setFiles,
        setResult,
        status,
        transactionId,
      }),
      challenge,
      challengeError,
    };
  };
