const DOMAIN = 'cobranded.services.userSettings.privateArea.personalInformation.';

export const ACCESS_PORTABILITY_CARD_DESCRIPTION = `${DOMAIN}cbCard.accessPortability.description`;
export const ACCESS_PORTABILITY_CARD_TITLE = `${DOMAIN}cbCard.accessPortability.title`;
export const ACCESS_PORTABILITY_CARD_LINK = `${DOMAIN}cbCard.accessPortability.requestYourAccessLink`;
export const CONSENTS_CARD_LINK = `${DOMAIN}cbCard.consentsGDPR.manageYourConsentsLink`;
export const CONSENTS_CARD_DESCRIPTION = `${DOMAIN}cbCard.consentsGDPR.description`;
export const CONSENTS_CARD_TITLE = `${DOMAIN}cbCard.consentsGDPR.title`;
export const DOCUMENTS_CARD_LINK = `${DOMAIN}cbCard.documentUpload.uploadDocumentsLink`;
export const DOCUMENTS_CARD_DESCRIPTION = `${DOMAIN}cbCard.documentUpload.description`;
export const DOCUMENTS_CARD_TITLE = `${DOMAIN}cbCard.documentUpload.title`;
export const PERSONAL_INFORMATION = `${DOMAIN}title`;
export const PAPERLESS_TITLE = `${DOMAIN}cbCard.paperless.title`;
export const PAPERLESS_DESCRIPTION = `${DOMAIN}cbCard.paperless.description`;