// Constants
import { SELECTOR_BOX_BASE_TEST_ID } from '../constants/block-card-address.configurations.constants';
// Translations
import {
  ADD_ADDRESS_TITLE,
  CUSTOMER_ADDRESS_TITLE,
  TEMP_ADDRESS_TITLE,
} from '../translations/block-card-address.configurations.translations';
// Types
import { GetSelectorBoxTestIdType } from './types/get-selector-box-test-id.type';
import { GetSelectorBoxTitleType } from './types/get-selector-box-title.type';
import { HasActionsType } from './types/has-actions.type';
import { HasEmptyTempAddressType } from './types/has-empty-temp-address.type';

const hasActions = ({ address, isTemp }: HasActionsType): boolean => !!address && isTemp;

const hasEmptyTempAddress = ({ address, isTemp }: HasEmptyTempAddressType): boolean =>
  !address && isTemp;

const getSelectorBoxTitle = ({ address, isTemp }: GetSelectorBoxTitleType): string =>
  !isTemp ? CUSTOMER_ADDRESS_TITLE : address ? TEMP_ADDRESS_TITLE : ADD_ADDRESS_TITLE;

const getSelectorBoxTestId = ({ type }: GetSelectorBoxTestIdType): string =>
  `${SELECTOR_BOX_BASE_TEST_ID}${type}`;

export { hasActions, hasEmptyTempAddress, getSelectorBoxTitle, getSelectorBoxTestId };
