// Enumerations
import { UploadDocumentComponentStateEnum } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/enums/upload-document-component-state.enumeration';
// Types
import { DocumentInfoType } from 'containers/financing/views/credit-limit-increase/contexts/types/document-info.type';

export const getState = (documentInfo?: DocumentInfoType): UploadDocumentComponentStateEnum => {
  if (documentInfo?.isLoading) {
    return UploadDocumentComponentStateEnum.LOADING;
  } else if (documentInfo?.isUploaded) {
    return UploadDocumentComponentStateEnum.UPLOADED;
  } else if (documentInfo?.hasBackOfficeError) {
    return UploadDocumentComponentStateEnum.BACK_OFFICE_ERROR;
  } else if (documentInfo?.hasSystemError) {
    return UploadDocumentComponentStateEnum.SYSTEM_ERROR;
  }

  return UploadDocumentComponentStateEnum.DEFAULT;
};

export const onCancelUpload = (
  controller1: AbortController,
  controller2: AbortController
): void => {
  controller1.abort();
  controller2.abort();
};
