// Services
import { validateAccessKeyService } from '../services/security-keys-access-key-change-second-step.services';
// Types
import { AccessKeyValidationResolverPropsType } from './types/security-keys-access-key-change-second-step.validation.resolver.type';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';

const validateAccessKeyResolver = async ({
  oldAccessKey,
  newAccessKey,
}: AccessKeyValidationResolverPropsType): Promise<[Record<string, any>, boolean]> => {
  const [response, { status }] = await validateAccessKeyService({ oldAccessKey, newAccessKey });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export { validateAccessKeyResolver };
