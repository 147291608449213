// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/personal-email-step2.services.constants';
// Types
import { PersonalEmailStep2GenerateApiBuilderType } from '../types/personal-email-step2-generate-api-builder.type';
import { PersonalEmailStep2GenerateReturnHookType } from '../types/personal-email-step2-generate-return.hook.type';

const generateNewEmailOtpService = async (
  params: PersonalEmailStep2GenerateApiBuilderType
): Promise<[PersonalEmailStep2GenerateReturnHookType | Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...constants.GENERATE_OTP_SERVICE_CONFIGURATION,
    params,
  });

export { generateNewEmailOtpService };
