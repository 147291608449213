// Vendors
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Components
import { TransactionsRouterReportGuardComponent } from '../components/report-guard/transactions-router-report-guard.component';
import { TransactionsRouterEppGuardComponent } from '../components/epp-guard/transactions-router-epp-guard.component';
// Constants
import { TRANSACTION_ID_PARAM } from 'constants/routes.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { RouteType } from 'types/react-router-dom/route-configuration.type';
// Utils
import { generatePathRoute } from 'utils/app-routing.utils';
// Views
import TransactionsLandingView from 'containers/transactions/views/landing/transactions-landing.view';
import { TransactionsReportView } from 'containers/transactions/views/report/transactions-report.view';
import { TransactionsEppComponent } from 'containers/transactions/views/epp/transactions-epp.component';

const TRANSACTIONS_ROUTER_COMMON_ROUTES: RouteType[] = [
  {
    element: TransactionsReportView,
    guard: TransactionsRouterReportGuardComponent,
    props: {
      path: generatePathRoute([
        TRANSACTION_ID_PARAM,
        RoutesEnumeration.REPORT_A_PROBLEM,
        CharactersEnum.ASTERISK,
      ]),
    },
  },
  {
    element: TransactionsEppComponent,
    guard: TransactionsRouterEppGuardComponent,
    props: {
      path: generatePathRoute([TRANSACTION_ID_PARAM, RoutesEnumeration.CREATE_INSTALLMENTS_PLAN]),
    },
  },
  { element: TransactionsLandingView, props: { index: true } },
];

export { TRANSACTIONS_ROUTER_COMMON_ROUTES };
