// Constants
import appConstants from 'constants/app.constants';
// Types
import {
  DashboardPointsBuilderHandlersType,
  DashboardPointsBuilderReturnHandlersType,
} from './types/dashboard-points.handlers.type';

const knowMoreAboutPointsLinkClickHandler = ({
  handleKnowMoreAboutPointsLinkClickTracking,
}: DashboardPointsBuilderHandlersType): void => {
  handleKnowMoreAboutPointsLinkClickTracking();
  window.open(appConstants.AMAZON_PUBLIC_SITE_FAQS);
};

const DashboardPointsHandlers = (
  props: DashboardPointsBuilderHandlersType
): DashboardPointsBuilderReturnHandlersType => ({
  handleKnowMoreAboutPointsLinkClick: () => knowMoreAboutPointsLinkClickHandler(props),
});

export { DashboardPointsHandlers };
