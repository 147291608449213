// Constants
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS } from './constants/transactions-epp-step1-simulation-options.configuration.constants';
// Types
import { TransactionsEppStep1SimulationOptionsConfigurationType } from './types/transactions-epp-step1-simulation-options.configuration.type';
import { TransactionsEppStep1SimulationOptionType } from '../types/transactions-epp-step1-simulation-option.type';
// Utilities
import { filterOptionAccordingMaxInstallments } from './utils/transactions-epp-step1-simulation-options.configuration.utils';

const getTransactionEppInstallmentsPlanOptionsConfiguration = (
  props: TransactionsEppStep1SimulationOptionsConfigurationType
): TransactionsEppStep1SimulationOptionType[] =>
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS.filter(
    (option: TransactionsEppStep1SimulationOptionType) =>
      filterOptionAccordingMaxInstallments({ ...props, option })
  ).filter(Boolean);

export { getTransactionEppInstallmentsPlanOptionsConfiguration };
