const FILTER_MODAL_INPUT_SELECT_DEFAULT_PROPS = {
  showLineSeparator: false,
  testId: 'filter-modal-input-select',
};

const FILTER_MODAL_TITLE_COMPONENT_PROPS = {
  tag: 'h5',
  bold: false,
};

export { FILTER_MODAL_INPUT_SELECT_DEFAULT_PROPS, FILTER_MODAL_TITLE_COMPONENT_PROPS };
