const DOMAIN = 'cobranded.services.userSettings.privateArea.cbChallengeModule.smsChallenge.';

const BUTTON = `${DOMAIN}sendButton`,
  DESCRIPTION = `${DOMAIN}description`,
  ERROR = `${DOMAIN}cbInput.errorDescription`,
  HELPER = `${DOMAIN}requestNewCodeDescription`,
  REQUEST_LINK = `${DOMAIN}requestNewCodeLink`,
  PLACEHOLDER = `${DOMAIN}inputOtpPlaceholder`,
  TITLE = `${DOMAIN}title`,
  TRY_AGAIN = `${DOMAIN}cbMessage.retryButton`,
  DESCRIPTION_ERROR = `${DOMAIN}cbMessage.description`;

export {
  BUTTON,
  DESCRIPTION,
  ERROR,
  HELPER,
  REQUEST_LINK,
  PLACEHOLDER,
  TITLE,
  TRY_AGAIN,
  DESCRIPTION_ERROR,
};
