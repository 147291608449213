const DOMAIN_FAST_CASH_LIMIT_MODAL =
  'cobranded.services.userSettings.privateArea.financing.fastCash.limitModal.';

const MODAL = {
  CONFIG: {
    TITLE: `${DOMAIN_FAST_CASH_LIMIT_MODAL}title`,
    LINK: `${DOMAIN_FAST_CASH_LIMIT_MODAL}link`,
    BUTTON: `${DOMAIN_FAST_CASH_LIMIT_MODAL}button`,
  },
  CONTENT: `${DOMAIN_FAST_CASH_LIMIT_MODAL}content`,
};

export { MODAL };
