const DOMAIN_BLOCK_CARD_ADDRESS =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.first.address.';
const DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_ACTIONS =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.first.address.actions.';

const CUSTOMER_ADDRESS_TITLE = `${DOMAIN_BLOCK_CARD_ADDRESS}customerAddressTitle`;

const TEMP_ADDRESS_TITLE = `${DOMAIN_BLOCK_CARD_ADDRESS}tempAddressTitle`;

const ADD_ADDRESS_TITLE = `${DOMAIN_BLOCK_CARD_ADDRESS}addAddressTitle`;

const EDIT_ACTION = `${DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_ACTIONS}edit`;

const DELETE_ACTION = `${DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_ACTIONS}delete`;

export {
  ADD_ADDRESS_TITLE,
  CUSTOMER_ADDRESS_TITLE,
  DELETE_ACTION,
  EDIT_ACTION,
  TEMP_ADDRESS_TITLE,
};
