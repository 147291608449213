// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/profile-personal-name-resume-layout.constants';
// Styles
import {
  ProfilePersonalNameResumeLayoutComponentStyled,
  ProfilePersonalNameResumeLayoutContentComponentStyled,
} from './profile-personal-name-resume-layout.component.styled';
// Types
import { ProfilePersonalNameResumeLayoutComponentType } from './types/profile-personal-name-resume-layout.component.type';

const ProfilePersonalNameResumeLayoutComponent = ({
  label,
  children,
}: ProfilePersonalNameResumeLayoutComponentType): React.ReactElement<ProfilePersonalNameResumeLayoutComponentType> => (
  <ProfilePersonalNameResumeLayoutComponentStyled>
    <ParagraphComponent {...constants.PROFILE_PERSONAL_NAME_RESUME_LAYOUT_LABEL_PROPS}>
      <FormattedMessageComponent id={label} />
    </ParagraphComponent>
    <ProfilePersonalNameResumeLayoutContentComponentStyled>
      {children}
    </ProfilePersonalNameResumeLayoutContentComponentStyled>
  </ProfilePersonalNameResumeLayoutComponentStyled>
);

export default ProfilePersonalNameResumeLayoutComponent;
