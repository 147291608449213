// Enumerations
import { TransactionsEppStatusCodeEnumeration } from 'enumerations/transactions-epp-status-code.enumeration';
// Types
import { TransactionsEppHandlersUtilsType } from './types/transactions-epp.handlers.utils.type';

const cardTransactionCantBeInstallmentPlan = ({
  error,
  response,
}: TransactionsEppHandlersUtilsType): boolean =>
  Boolean(
    error || response?.eppStatusCode !== TransactionsEppStatusCodeEnumeration.ELIGIBLE_FOR_EPP
  );

export { cardTransactionCantBeInstallmentPlan };
