// Vendors
import { useEffect } from 'react';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Types
import { DeviceListHookReturnType } from './types/devices-list.hook.return.type';
//Contexts
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
// Handlers
import { DevicesListHandlers } from '../handlers/devices-list.handlers';
import { DeviceManagerHandlers } from 'containers/security-keys/views/devices/handlers/signature-key-devices.handlers';
import { DevicesListHandlersTracking } from '../handlers/devices-list.handlers.tracking';

const DevicesListHook = (): DeviceListHookReturnType => {
  const { formatMessage } = useCbIntl();
  const { isMobile } = DeviceTypeHook();
  const {
    fetching,
    devices,
    blockedDevices,
    setFetching,
    setDevices,
    setError,
    setBlockedDevices,
    setTrusted,
    error,
  } = SignatureKeyDevicesContextConsumerHook();
  const { getTrustedDevice } = DeviceManagerHandlers({
    ...AppTrackingHook(DevicesListHandlersTracking),
    setFetching,
    setDevices,
    setError,
    setBlockedDevices,
    setTrusted,
  });

  const { getDevices } = DevicesListHandlers({
    setFetching,
    setDevices,
    setError,
    setBlockedDevices,
    setTrusted,
    getTrustedDevice,
  });

  useEffect(() => {
    !devices.length && getDevices();
  }, []);

  return {
    formatMessage,
    error,
    fetching,
    devices,
    blockedDevices,
    isMobile,
    getDevices,
  };
};

export { DevicesListHook };
