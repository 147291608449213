const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbGenericWizard.genericWizard.navigationFooter.';

const CANCEL = `${DOMAIN}cancelLink`;

const NEXT = `${DOMAIN}nextButton`;

const PREVIOUS = `${DOMAIN}previousButton`;

export { CANCEL, NEXT, PREVIOUS };
