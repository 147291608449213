// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { DeviceSessionsComponent } from '../../components/device-sessions/device-sessions.component';
import { DeviceHeaderComponent } from '../../components/device-header/device-header.component';
import { DeviceInfoComponent } from '../../components/device-info/device-info.component';
// Styles
import { DeviceDetailViewStyled } from './device-detail.view.styled';
import { LayoutGridContentComponentStyled } from 'styles/app.styled';
// Hooks
import { DeviceDetailHook } from './hooks/device-detail.hook';

const DeviceDetailView = (): React.ReactElement => {
  const { fetching, device } = DeviceDetailHook();

  return (
    <FetchErrorComponent fetching={fetching}>
      <LayoutGridContentComponentStyled>
        {
          device && (
            <DeviceDetailViewStyled>
              <DeviceHeaderComponent device={device} />
              <DeviceInfoComponent device={device} />
              <DeviceSessionsComponent deviceUUID={device.deviceUUID} />
            </DeviceDetailViewStyled>
          )
        }
      </LayoutGridContentComponentStyled>
    </FetchErrorComponent>
  );
};

export default DeviceDetailView;