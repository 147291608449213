// Vendors
import React from 'react';
// Components
import CardDetailsWrapperComponent from 'components/card-details/card-details-wrapper.component';
// Styles
import { CardSettingsContractCurrentCardComponentStyled } from './card-settings-contract-current-card.component.styled';

const CardSettingsContractCurrentCardComponent = (): React.ReactElement => (
  <CardSettingsContractCurrentCardComponentStyled>
    <CardDetailsWrapperComponent />
  </CardSettingsContractCurrentCardComponentStyled>
);

export default CardSettingsContractCurrentCardComponent;
