// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { ResumeItemComponent, LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AmountDetailsModalComponent } from './components/amount-details-modal/amount-details-modal.component';
// Constants
import {
  TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_LINK_PROPS,
} from './constants/transactions-landing-row-details-amount.constants';
import { FORMATTED_VALUES_PROPS } from 'containers/card-settings/views/statements/constants/card-settings-statements.constants';
// Hooks
import { TransactionsLandingRowDetailsAmountHook } from './hooks/transactions-landing-row-details-amount.hook';
// Styles
import { TransactionsLandingRowDetailsResumeLinkComponentStyled } from '../../transactions-landing-row-details.component.styled';
// Translations
import {
  AMOUNT,
  SEE_DETAILS,
} from './translations/transactions-landing-row-details-amount.translations';
// Types
import { TransactionsLandingRowDetailsAmountComponentPropsType } from './types/transactions-landing-row-details.component.type';
// Utilities
import { findTransactionTotalAmount } from 'utils/transactions/transactions.utils';

export const TransactionsLandingRowDetailsAmountComponent = ({
  amounts,
  detailedAmount,
}: TransactionsLandingRowDetailsAmountComponentPropsType): React.ReactElement | null => {
  const { showModal, setShowModal, handleSeeDetailsElementAmountLinkClick } =
    TransactionsLandingRowDetailsAmountHook();

  return (
    <TransactionsLandingRowDetailsResumeLinkComponentStyled>
      <ResumeItemComponent
        {...TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_PROPS}
        label={<FormattedMessageComponent id={AMOUNT} />}
        value={
          <FormattedNumber
            {...FORMATTED_VALUES_PROPS}
            value={findTransactionTotalAmount(amounts)}
          />
        }
      />
      <LinkComponent
        {...TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_LINK_PROPS}
        onClick={handleSeeDetailsElementAmountLinkClick}
      >
        <FormattedMessageComponent id={SEE_DETAILS} />
      </LinkComponent>
      <AmountDetailsModalComponent
        {...{ showModal, setShowModal, handleSeeDetailsElementAmountLinkClick, detailedAmount }}
      />
    </TransactionsLandingRowDetailsResumeLinkComponentStyled>
  );
};
