// Resolvers
import { validateNewEmailOtpResolver } from '../resolvers/personal-email-step2-challenge.resolver';
// Types
import { PersonalEmailStep2ChallengeBuilderReturnHandlersType } from './types/personal-email-step2-challenge-builder-return.handlers.type';
import { PersonalEmailStep2ChallengePropsType } from 'containers/personal/views/email/components/step2/components/challenge/handlers/types/personal-email-step2-challenge-props.handlers.type';
import { PersonalEmailStep2ChallengeBuilderHandlersType } from 'containers/personal/views/email/components/step2/components/challenge/handlers/types/personal-email-step2-challenge-builder.handlers.type';

const retryButtonClickHandler = ({
  onRetry,
  handleRestart,
}: Omit<PersonalEmailStep2ChallengeBuilderHandlersType, 'props'>): any => {
  onRetry();
  handleRestart();
};

const sendEmailOtpConfirmationButtonClickHandler = async ({
  handleSendEmailOtpConfirmationButtonClickTracking,
  newEmail: emailId,
  otpId,
  otpValue,
  setChallenged,
  setFetching,
  setError,
}: PersonalEmailStep2ChallengePropsType): Promise<void> => {
  setFetching(true);
  handleSendEmailOtpConfirmationButtonClickTracking();

  const [response, error] = await validateNewEmailOtpResolver({ emailId, otpId, otpValue });

  if (error) {
    setError(true);
  } else if (response) {
    setError(false);
    setChallenged(false);
  }

  setFetching(false);
};

const PersonalEmailStep2ChallengeHandlers = ({
  handleRestart,
  onRetry,
  props,
}: PersonalEmailStep2ChallengeBuilderHandlersType): PersonalEmailStep2ChallengeBuilderReturnHandlersType => ({
  handleRetryButtonClick: () =>
    retryButtonClickHandler({
      onRetry,
      handleRestart,
    }),
  handleSendEmailOtpConfirmationButtonClick: () =>
    sendEmailOtpConfirmationButtonClickHandler(props),
});

export default PersonalEmailStep2ChallengeHandlers;
