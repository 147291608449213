// Types
import { InputSearchOptionType } from 'components/address-modal/components/inputs/types/input-search-option.type';
import { GetCitiesByTermServiceResponse } from 'components/address-modal/services/types/get-cities-by-term-service-response.type';

const formatCityOptions = (
  response: GetCitiesByTermServiceResponse | null
): InputSearchOptionType[] =>
  response?.cities?.map(({ city }) => ({ label: city, value: city })) || [];

export { formatCityOptions };
