// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DeviceHeaderComponentStyled = styled.div.attrs(() => ({
  'data-testid': 'device-header-component'
}))`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};
    border: 1px solid ${({ theme }) => theme.colors.backgroundTertiaryC};
    flex-direction: column;
    align-items: flex-end;
    }
  }
`;

export const DeviceHeaderComponentLeftStyled = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingXs};

  ${mobileMediaQuery} {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const DeviceHeaderComponentRightStyled = styled.div<{
  button?: boolean
  activeDevice?: boolean
}>`
  display: flex;
  ${({ activeDevice, theme }) => !activeDevice && `gap: ${theme.spaces.spacingXxs}`};
  ${mobileMediaQuery} {
    ${({ button, theme }) => button && `padding-bottom: ${theme.spaces.spacingS};`};
    width: 100%;
  }
`;

export const DeviceHeaderComponentTagStyled = styled.div`
  padding: ${({ theme }) => `${theme.spaces.spacingXs} ${theme.spaces.spacingS}`};
`;

export const DeviceHeaderComponentNotCurrentStyled = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
`;