// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingDownloadComponent } from 'containers/financing/views/ppi/components/contracting/components/download/financing-ppi-contracting-download.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_SERVICE_PROPS,
} from './constants/financing-ppi-contracting-step2-download.constants';
// Hooks
import { useFinancingPPIContractingStep2DownloadHook } from './hooks/financing-ppi-contracting-step2-download.hook';
// Translations
import * as translations from './translations/financing-ppi-contracting-step2-download.translations';

const FinancingPPIContractingStep2DownloadComponent = (): React.ReactElement => {
  const { params } = useFinancingPPIContractingStep2DownloadHook();

  return (
    <FinancingPPIContractingDownloadComponent
      {...FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_PROPS}
      {...{ translations }}
      serviceParams={{ ...FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_SERVICE_PROPS, params }}
    />
  );
};

export { FinancingPPIContractingStep2DownloadComponent };
