// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { CardSettingsDetailsHandlers } from '../handlers/card-settings-details.handlers';
import { CardSettingsDetailsTrackingHandlers } from '../handlers/card-settings-details.tracking.handlers';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Hooks
import useCopyHook from 'hooks/copy.hook';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsDetailsHookType } from './types/card-settings-details.hook.type';

const useCardSettingsDetailsHook = (): CardSettingsDetailsHookType => {
  const { formatDate, formatMessage } = useCbIntl();

  const { handleCopyGenericEvent } = useCopyHook();

  const { currentCardDetail } = AppContextConsumerHook(),
    { pan = CharactersEnum.NO_VALUE, expirationDate: expiryDate = CharactersEnum.NO_VALUE } =
      currentCardDetail || {};

  return {
    ...CardSettingsDetailsHandlers({
      ...AppTrackingHook(CardSettingsDetailsTrackingHandlers),
      formatMessage,
      handleCopyGenericEvent,
      pan,
    }),
    formatDate,
    expiryDate,
    pan,
  };
};

export { useCardSettingsDetailsHook };
