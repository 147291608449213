const MESSAGE_PROPS = {
  showIcon: false,
  type: 'informative',
};

const PARAGRAPH_PROPS = {
  tag: 'h6',
};

export { MESSAGE_PROPS, PARAGRAPH_PROPS };
