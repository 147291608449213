// Types
import { DashboardCardExpensesType } from 'containers/dashboard/types/dashboard-card-expenses.type';
import { mapDashboardSpentConfigurationReturnType } from './types/dashboard-spent.hook.utils.type';

export const mapDashboardSpentConfiguration = (
  cardExpenses: DashboardCardExpensesType
): mapDashboardSpentConfigurationReturnType => {
  const currentTotalAmount = cardExpenses?.currentPeriod?.total?.amount;
  const previousTotalAmount = cardExpenses?.previousMonth?.total?.amount;

  const { currentPercentage, previousPercentage, percentageDifference } =
    calculateDashboardSpentPercentagesUtil(currentTotalAmount, previousTotalAmount);

  const expenses =
    cardExpenses?.currentPeriod?.categories?.map(category => ({
      categoryCode: category.category.categoryCode,
      amount: category.total.amount,
      step: category.percentage.percentage,
    })) || [];

  return {
    currentPercentage,
    expenses,
    percentageDifference,
    previousPercentage,
    total: currentTotalAmount,
  };
};

// eslint-disable-next-line complexity
const calculateDashboardSpentPercentagesUtil = (
  currentTotalAmount: number,
  previousTotalAmount: number
) => {
  if (currentTotalAmount === 0 && previousTotalAmount === 0) {
    return {
      currentPercentage: 0,
      previousPercentage: 0,
      percentageDifference: 0,
    };
  }

  if (currentTotalAmount === 0) {
    return {
      currentPercentage: 1,
      previousPercentage: 100,
      percentageDifference: 0,
    };
  }

  if (previousTotalAmount === 0) {
    return {
      currentPercentage: 100,
      previousPercentage: 1,
      percentageDifference: 0,
    };
  }

  const difference = currentTotalAmount - previousTotalAmount;
  const percentageChange = (difference / previousTotalAmount) * 100;
  const currentPercentage =
    currentTotalAmount >= previousTotalAmount
      ? 100
      : (currentTotalAmount / previousTotalAmount) * 100;
  const previousPercentage =
    currentTotalAmount >= previousTotalAmount
      ? (previousTotalAmount / currentTotalAmount) * 100
      : 100;

  return {
    currentPercentage: Math.round(currentPercentage),
    previousPercentage: Math.round(previousPercentage),
    percentageDifference: Math.round(percentageChange),
  };
};
