// Constants
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Translations
import {
  FEATURE_01,
  FEATURE_02,
  FEATURE_03,
} from './translations/financing-ppi-contracting-step2-expandable-covered.constants.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxs,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_01_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-covered-description-01',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_02_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-covered-description-02',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_03_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-covered-description-03',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_04_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-covered-description-04',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-covered',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_ITEMS = {
    list: [FEATURE_01, FEATURE_02, FEATURE_03],
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_01_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_02_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_03_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_DESCRIPTION_04_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_COVERED_LIST_ITEMS,
};
