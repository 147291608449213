// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Translations
import { STREET } from '../translations/address-modal-inputs-street.translations';

const STREET_PROPS = {
  id: 'address-modal-input-street',
  name: InputNamesEnumeration.STREET,
  translations: STREET,
};

const STREET_INPUT_PROPS = {
  disableFilter: false,
  isOptionSelectionRequired: false,
};

export { STREET_INPUT_PROPS, STREET_PROPS };
