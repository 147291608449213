// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import PersonalPhotoAvatarsCheckComponent from './components/check/personal-photo-avatars-check.component';
// Constants
import {
  PERSONAL_PHOTO_AVATARS_DESCRIPTION_PROPS,
  PERSONAL_PHOTO_AVATARS_ITEM_PROPS,
  PERSONAL_PHOTO_AVATARS_ITEM_TEST_ID,
} from './constants/personal-photo-avatars.constants';
// Enumerations
import { AvatarIdsEnumeration } from '../../enumerations/avatar-ids.enumeration';
// Hooks
import usePersonalPhotoAvatarsHook from './hooks/personal-photo-avatars.hook';
// Styles
import {
  PersonalPhotoAvatarsComponentStyled,
  PersonalPhotoAvatarsListComponentStyled,
  PersonalPhotoAvatarsListItemComponentStyled,
} from './personal-photo-avatars.component.styled';
// Translations
import { DESCRIPTION } from './translations/personal-photo-avatars.translations';

const PersonalPhotoAvatarsComponent = (): React.ReactElement => {
  const { avatar, handleSelectNewAvatarAssetClick } = usePersonalPhotoAvatarsHook();

  return (
    <PersonalPhotoAvatarsComponentStyled>
      <ParagraphComponent {...PERSONAL_PHOTO_AVATARS_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <PersonalPhotoAvatarsListComponentStyled>
        {Object.values(AvatarIdsEnumeration).map((id: AvatarIdsEnumeration, key: number) => (
          <PersonalPhotoAvatarsListItemComponentStyled key={key}>
            <AssetComponent
              {...{ ...PERSONAL_PHOTO_AVATARS_ITEM_PROPS, id }}
              onClick={() => handleSelectNewAvatarAssetClick(id)}
              testId={PERSONAL_PHOTO_AVATARS_ITEM_TEST_ID + key}
            />
            <PersonalPhotoAvatarsCheckComponent {...{ avatar, id }} />
          </PersonalPhotoAvatarsListItemComponentStyled>
        ))}
      </PersonalPhotoAvatarsListComponentStyled>
    </PersonalPhotoAvatarsComponentStyled>
  );
};

export default PersonalPhotoAvatarsComponent;
