export default {
  ERROR_NEXT_STEP_PROPS: {
    assetConfiguration: {
      id: 'oops-alternative',
      type: 'illustration',
      sizes: {
        width: 128,
        height: 128,
      },
    },
    buttonConfiguration: {
      size: 'full',
    },
  },
} as const;
