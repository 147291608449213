// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import {
  WIZARD_FEEDBACK_ERROR,
  WIZARD_FEEDBACK_SUCCESS,
} from '../../constants/transactions-report.constants';
// Contexts
import { useTransactionsReportContextConsumerHook } from '../../contexts/transactions-report.context';
// Types
import { TransactionsReportWizardComponentType } from './types/transactions-report-wizard.component.type';
// Utils
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

export const TransactionsReportWizardComponent = ({
  steps,
}: TransactionsReportWizardComponentType): React.ReactElement => {
  const { caseId } = useTransactionsReportContextConsumerHook();

  return (
    <WizardComponent
      feedbackConfiguration={{
        errorConfiguration: WIZARD_FEEDBACK_ERROR,
        successConfiguration: {
          ...WIZARD_FEEDBACK_SUCCESS,
          descriptionValues: {
            b: makeChunkBoldIntlUtil,
            br: <br />,
            caseId,
          },
        },
      }}
      steps={steps}
    />
  );
};
