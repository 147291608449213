// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardFeedbackHostComponent from '../host/wizard-feedback-host.component';
// Constants
import { WIZARD_FEEDBACK_SUCCESS_PROPS } from './constants/wizard-feedback-success.constants';
// Hooks
import useWizardFeedbackSuccessHook from './hooks/wizard-feedback-success.hook';
// Types
import { WizardFeedbackSuccessComponentType } from '../../types/wizard-feedback-success.component.type';

const WizardFeedbackSuccessComponent = ({
  exitRoute,
  linkClick,
  linkLabel,
  linkValues,
  ...rest
}: WizardFeedbackSuccessComponentType): React.ReactElement<WizardFeedbackSuccessComponentType> => {
  const { handleWizardFeedbackSuccessLinkClick } = useWizardFeedbackSuccessHook({
    linkClick,
    exitRoute,
  });

  return (
    <WizardFeedbackHostComponent
      {...{ ...WIZARD_FEEDBACK_SUCCESS_PROPS, ...rest }}
      rightLinkConfiguration={{
        children: <FormattedMessageComponent id={linkLabel} values={linkValues} />,
        onClick: handleWizardFeedbackSuccessLinkClick,
      }}
    />
  );
};

export default WizardFeedbackSuccessComponent;
