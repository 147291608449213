// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Types
import { CardTransactionsErrorHandlersType } from '../types/card-transactions-error.handlers.type';
// Utils
import { requestRespondedNoContent, wasForbiddenRequest } from '@openbank/cf-ui-framework';

const getErrorAccordingStatus = ({
  formValuesParams,
  error: status,
}: Pick<
  CardTransactionsErrorHandlersType,
  'formValuesParams' | 'error'
>): TransactionsFetchErrorEnumeration => {
  switch (true) {
    case requestRespondedNoContent(status):
      if (Object.keys(formValuesParams).length) {
        return TransactionsFetchErrorEnumeration.NO_FILTER_RESULTS;
      }
      return TransactionsFetchErrorEnumeration.NO_RESULTS;
    case wasForbiddenRequest(status):
      return TransactionsFetchErrorEnumeration.CHALLENGE;
    default:
      return TransactionsFetchErrorEnumeration.FETCH;
  }
};

export { getErrorAccordingStatus };
