// Vendors
import { useEffect } from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Handlers
import SupportCancelContractFirstStepHandlers from '../handlers/support-cancel-contract-first-step.handlers';
import SupportCancelContractFirstStepTrackingHandlers from '../handlers/support-cancel-contract-first-step.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { SupportCancelContractFirstStepHookType } from './types/support-cancel-contract-first-step.hook.type';
// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { SupportCancelContractContextConsumerHook } from 'containers/support/views/cancel-contract/contexts/support-cancel-contract.context';

const SupportCancelContractFirstStepHook = (): SupportCancelContractFirstStepHookType => {
  const { reason, setReason, showModal, setShowModal } = SupportCancelContractContextConsumerHook();
  const { formatMessage } = useCbIntl();
  const { setCurrentStep } = useWizardContextConsumerHook();
  const {
    handleDropdownInputClickTrackingHandler,
    handleDropdownSelectTrackingHandler,
    handleOnEnterFirstStepCancelContractTracking,
  } = AppTrackingHook(SupportCancelContractFirstStepTrackingHandlers);

  useEffect(() => {
    handleOnEnterFirstStepCancelContractTracking();
  }, []);

  return {
    ...SupportCancelContractFirstStepHandlers({
      handleDropdownSelectTrackingHandler,
      setCurrentStep,
      setReason,
      setShowModal,
      showModal,
    }),
    reason,
    formatMessage,
    handleDropdownInputClickTrackingHandler,
    showModal,
    isIosDevice: isIosWebView(),
  };
};

export default SupportCancelContractFirstStepHook;
