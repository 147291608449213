// Vendors
import styled from 'styled-components';

const CardSettingsDividerTitleComponentStyled = styled.span.attrs(
  ({ testId }: { testId?: string }) => ({ 'data-testid': testId })
)`
  text-transform: uppercase;
`;
CardSettingsDividerTitleComponentStyled.displayName = 'CardSettingsDividerTitleComponentStyled';

export { CardSettingsDividerTitleComponentStyled };
