// Constants
import { CANCEL_STATUSES, CONTRACT_STATUSES } from './constants/financing-ppi.utils.constants';
// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';
// Types
import { isAvailableInsuranceType } from './types/is-available-insurance.type';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const isAvailableInsurance = ({
  customerContractingEnabled,
  featureFlag,
}: isAvailableInsuranceType): boolean =>
  !!customerContractingEnabled && getFeatureFlagInstance().isEnabled(featureFlag);

const isContractableInsurance = (status: InsuranceStatusEnumeration): boolean =>
  CONTRACT_STATUSES.includes(status);

const isCancelableInsurance = (status: InsuranceStatusEnumeration): boolean =>
  CANCEL_STATUSES.includes(status);

export { isCancelableInsurance, isContractableInsurance, isAvailableInsurance };
