// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import PersonalEmailStep3ActionsHandlers from '../handlers/personal-email-step3-actions.handlers';
import PersonalEmailStep3TrackingHandlers from '../handlers/personal-email-step3-actions.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalEmailStep3ActionsHookType } from './types/personal-email-step3-actions.hook.type';

const PersonalEmailStep3ActionsHook = (): PersonalEmailStep3ActionsHookType => {
  const { setMe } = ManagerContextConsumerHook();
  const { profile } = AppContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { customerId } = profile?.userDetails || {};

  const { challenged, fetching, newEmail, progressId, setShowModal, setFetching } =
    PersonalEmailContextConsumerHook();

  return {
    ...PersonalEmailStep3ActionsHandlers({
      ...AppTrackingHook(PersonalEmailStep3TrackingHandlers),
      customerId,
      newEmail,
      progressId,
      setFetching,
      setShowModal,
      setResult,
      setMe
    }),
    challenged,
    fetching,
  };
};

export default PersonalEmailStep3ActionsHook;
