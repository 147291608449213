// Vendors
import React from 'react';
// Components
import SidebarContentComponent from './components/sidebar-content/sidebar-content.component';
// Constants
import checkCvvPinConstants from './constants/check-cvv-pin.constants';
// Hooks
import { useViewSteps } from './hooks/check-cvv-pin.hook';
// Styles
import { AppCardSectionComponentStyled, LayoutGridComponentStyled } from 'styles/app.styled';
import {
  CheckCvvPinLayoutGridContent,
  CheckCvvPinMainContentComponentStyled,
  CheckCvvPinSidebarComponentStyled,
  CheckCvvPinWrapperComponentStyled,
} from './check-cvv-pin.styled.view';
// Types
import { CheckCvvPinProps } from './types/check-cvv-pin.type';
// Utils
import { hasPadding } from './utils/check-cvv-pin.utils';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';

const { PIN } = checkCvvPinConstants;

const CheckCvvPinView = ({ type = PIN }: CheckCvvPinProps): React.ReactElement => {
  const { error, render, step } = useViewSteps({ type });

  return (
    <ErrorWrapperComponent error={error}>
      <LayoutGridComponentStyled>
      <CheckCvvPinLayoutGridContent>
        <CheckCvvPinMainContentComponentStyled>
          <AppCardSectionComponentStyled>
            <CheckCvvPinWrapperComponentStyled $padding={hasPadding(step)}>
              {render()}
            </CheckCvvPinWrapperComponentStyled>
          </AppCardSectionComponentStyled>
        </CheckCvvPinMainContentComponentStyled>
        <CheckCvvPinSidebarComponentStyled>
          <SidebarContentComponent type={type} />
        </CheckCvvPinSidebarComponentStyled>
      </CheckCvvPinLayoutGridContent>
    </LayoutGridComponentStyled>
  </ErrorWrapperComponent>
  );
};

export default CheckCvvPinView;
