// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

const TRANSACTION_EPP_STEP2_MESSAGE_PROPS = {
    testId: 'transactions-epp-step2-message',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  TRANSACTION_EPP_STEP2_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'transactions-epp-step2-message-description',
  };
export { TRANSACTION_EPP_STEP2_MESSAGE_DESCRIPTION_PROPS, TRANSACTION_EPP_STEP2_MESSAGE_PROPS };
