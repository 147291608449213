// Types
import { CardSettingsCheck3dConfirmationUtilsType } from './types/card-settings-check-3d-confirmation.utils.type';
// Utilities
import { userHasTrustedDevice } from 'containers/card-settings/views/check-3d/components/confirmation/utils/card-settings-check-3d-confirmation.utils';

const showHideButtonIsDisabled = ({
  showAllowance,
  devices,
}: CardSettingsCheck3dConfirmationUtilsType): boolean =>
  Boolean(!showAllowance || userHasTrustedDevice(devices));

export { showHideButtonIsDisabled };
