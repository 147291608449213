// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import { FinancingInsurancesContractingLPIStep2PlansErrorHandlers } from '../handlers/financing-insurances-contracting-lpi-step2-plans-error.handlers';
// Types
import { FinancingInsurancesContractingLPIStep2PlansErrorReturnHandlersType } from '../handlers/types/financing-insurances-contracting-lpi-step2-plans-error-return.handlers.type';

export const useFinancingInsurancesContractingLPIStep2PlansError =
  (): FinancingInsurancesContractingLPIStep2PlansErrorReturnHandlersType =>
    FinancingInsurancesContractingLPIStep2PlansErrorHandlers({
      navigate: useNavigate(),
    });
