// Constants
import constants from './constants/transactions-landing-row-details-view-order.handlers.constants';
// Types
import { TransactionsLandingRowDetailsViewOrderBuilderReturnHandlersType } from './types/transactions-landing-row-details-view-order-builder-return.handlers.type';
import { TransactionsLandingRowDetailsViewOrderBuilderHandlersType } from './types/transactions-landing-row-details-view-order-builder.handlers.type';

const reportTransactionProblemLinkClickHandler = ({
  handleViewTransactionOnAmazonLinkClickTracking,
}: TransactionsLandingRowDetailsViewOrderBuilderHandlersType): any => {
  handleViewTransactionOnAmazonLinkClickTracking();
  window.open(constants.AMAZON_TRANSACTIONS_URL);
};

const TransactionsLandingRowDetailsViewOrderHandlers = (
  props: TransactionsLandingRowDetailsViewOrderBuilderHandlersType
): TransactionsLandingRowDetailsViewOrderBuilderReturnHandlersType => ({
  handleViewTransactionOnAmazonLinkClick: () => reportTransactionProblemLinkClickHandler(props),
});

export default TransactionsLandingRowDetailsViewOrderHandlers;
