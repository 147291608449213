// Translations
import { assetConstants } from '@openbank/cb-ui-commons';

const WORK_IN_PROGRESS_PROPS = {
  assetConfiguration: {
    id: 'working-on-it-unhappy-alternative',
    sizes: {
      height: 120,
      width: 120,
    },
    testId: 'working-in-progress-illustration',
    type: assetConstants.TYPES.ILLUSTRATION,
  },
};

export { WORK_IN_PROGRESS_PROPS };
