// Vendors
import React from 'react';
// Components
import WizardFeedbackErrorComponent from './components/error/wizard-feedback-error.component';
import WizardFeedbackSuccessComponent from './components/success/wizard-feedback-success.component';
// Contexts
import { WizardFeedbackContextConsumerHOC } from './contexts/wizard-feedback.context';
// Hooks
import { useWizardFeedbackHook } from './hooks/wizard-feedback.hook';
// Types
import { WizardFeedbackComponentType } from './types/wizard-feedback.component.type';
import { WizardFeedbackResultComponentType } from './types/wizard-feedback-result.component.type';
import { WizardFeedbackSuccessComponentType } from './types/wizard-feedback-success.component.type';
// Utils
import { isWizardErrorFeedback, isWizardSuccessFeedback } from './utils/wizard-feedback.utils';

const WizardFeedbackComponent = ({
  children,
  errorConfiguration,
  successConfiguration,
}: WizardFeedbackComponentType): React.ReactElement => {
  const { result } = useWizardFeedbackHook();

  switch (true) {
    case isWizardErrorFeedback({ result, errorConfiguration }):
      return (
        <WizardFeedbackErrorComponent
          {...(errorConfiguration as WizardFeedbackResultComponentType)}
        />
      );
    case isWizardSuccessFeedback({ result, successConfiguration }):
      return (
        <WizardFeedbackSuccessComponent
          {...(successConfiguration as WizardFeedbackSuccessComponentType)}
        />
      );
    default:
      return children as React.ReactElement;
  }
};

export { WizardFeedbackComponent };

export default WizardFeedbackContextConsumerHOC(WizardFeedbackComponent);
