// Vendors
import { apiUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_PROFILE_CONSENTS_ENDPOINT } from './constants/profile-consents.services.constants';
// Types
import { ProfileConsentsServiceType } from './types/profile-consents.services.type';
import { ApiUtilsReturnType } from 'utils/validate-type/types/validate-type.types';

const fetchProfileConsentsService = async (): Promise<
  ApiUtilsReturnType<ProfileConsentsServiceType[]>
> => apiUtils.get({ endpoint: FETCH_PROFILE_CONSENTS_ENDPOINT });

export { fetchProfileConsentsService };
