// Vendors
import React, { ReactElement } from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import CardDetailsWrapperComponent from 'components/card-details/card-details-wrapper.component';
// Constants
import { MAXIMUM_FRACTION_DIGITS } from 'containers/financing/views/credit-limit-decrease-cc/constants/credit-limit-decrease-cc.constants';
import {
  PARAGRAPH_PROPS,
  PRODUCT_DETAIL_PROPS,
} from '../../constants/step-1-credit-limit-decrease-cc.constants';
// Translations
import {
  CURRENT_CREDIT_LIMIT,
  PAYMENT_METHOD_TEXT,
} from '../../translations/step-1-credit-limit-decrease-cc.translations';
// Types
import { Step1CardDetailsComponentType } from './types/step-1-card-details.component.type';
// Styles
import {
  CardDetailsStyled,
  ProductDetailsStyled,
  CardLimitFirstRowStyled,
} from '../../step-1-credit-limit-decrease-cc.component.styled';
// Utils
import { getPaymentMethodValueBy } from 'containers/financing/utils/financing.utils';

const Step1CardDetailsComponent = ({
  paymentMethodCode,
  fixedPaymentAmount,
  accountBalancePercentage,
  limit,
  mainCurrency,
  formatMessage,
  formatNumber,
  formatCurrencySeted,
}: Step1CardDetailsComponentType): React.ReactElement => (
  <CardLimitFirstRowStyled>
    <CardDetailsStyled>
      <CardDetailsWrapperComponent />
    </CardDetailsStyled>
    <ProductDetailsStyled>
      <ProductDetailComponent
        {...PRODUCT_DETAIL_PROPS}
        title={formatMessage({ id: CURRENT_CREDIT_LIMIT })}
      >
        <ParagraphComponent {...PARAGRAPH_PROPS}>{formatCurrencySeted(limit)}</ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent
        {...PRODUCT_DETAIL_PROPS}
        title={formatMessage({ id: PAYMENT_METHOD_TEXT })}
      >
        <ParagraphComponent {...PARAGRAPH_PROPS}>
          {getPaymentMethodValueBy({
            accountBalancePercentage,
            fixedPaymentAmount,
            mainCurrency,
            paymentMethodCode,
            maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
            formatMessage,
            formatNumber,
          })}
        </ParagraphComponent>
      </ProductDetailComponent>
    </ProductDetailsStyled>
  </CardLimitFirstRowStyled>
);

export { Step1CardDetailsComponent };
