// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CLOCK_ICON_PROPS } from './constants/last-access.constants';
// Styled
import { LastAccessComponentStyled } from './last-access.component-styled';
// Hooks
import { LastAccessHook } from './hooks/last-access.hook';
// Translations
import { LAST_ACCESS } from '../../translations/device-header.translations';
// Types
import { SignatureKeyDevicesDeviceType } from 'containers/security-keys/views/devices/types/signature-key-devices.device.type';

const LastAccessComponent = ({
  device
}: { device: SignatureKeyDevicesDeviceType }): React.ReactElement => {
  const { lastAccess } = LastAccessHook({ device });

  return (
    <LastAccessComponentStyled>
      <AssetComponent { ...CLOCK_ICON_PROPS } />
      <ParagraphComponent>
        <FormattedMessageComponent
          id={LAST_ACCESS}
          values={{ accessDate: lastAccess }}
        /> 
      </ParagraphComponent>
    </LastAccessComponentStyled>
  );
};

export { LastAccessComponent };