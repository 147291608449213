// Resolvers
import { getEppDetailsResolver } from 'resolvers/epp/epp.resolvers';
// Types
import { FinancingEarlyRepaymentHandlersType } from './types/financing-early-repayment.handlers.type';
import { FinancingEarlyRepaymentReturnHandlersType } from './types/financing-early-repayment-return.handlers.type';
import { EarlyRepaymentRenderHandlerType } from './types/early-repayment-render-handler.type';

const earlyRepaymentRenderHandler = async ({
  cardId,
  eppId,
  setEppDetails,
  setError,
  setFetching,
}: EarlyRepaymentRenderHandlerType) => {
  try {
    setFetching(true);

    if (!eppId) {
      throw new Error();
    }

    const [response, error] = await getEppDetailsResolver({ cardId, eppId });

    if (error) {
      throw new Error();
    }

    setEppDetails(response);
  } catch {
    setError(true);
  } finally {
    setFetching(false);
  }
};
const FinancingEarlyRepaymentHandlers = ({
  cardId,
  eppId,
  setEppDetails,
  setError,
  setFetching,
}: FinancingEarlyRepaymentHandlersType): FinancingEarlyRepaymentReturnHandlersType => ({
  handleEarlyRepaymentRender: () =>
    earlyRepaymentRenderHandler({
      cardId,
      eppId,
      setEppDetails,
      setError,
      setFetching,
    }),
});

export default FinancingEarlyRepaymentHandlers;
