// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const SupportCancelContractFirstStepInputDropdownStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 300, base: 14 })};

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

SupportCancelContractFirstStepInputDropdownStyled.displayName =
  'SupportCancelContractFirstStepInputDropdownStyled';

export { SupportCancelContractFirstStepInputDropdownStyled };
