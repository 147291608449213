const DOMAIN = 'cobranded.services.userSettings.privateArea.cardStatus.cbMessage.cbSwitchOnModal.';

export default {
  ON_ENABLE_CONFIRM: `${DOMAIN}yesSwitchOnCardButton`,
  ON_DISABLE_CONFIRM: `${DOMAIN}yesSwitchOffCardButton`,
  FEATURE_01: `${DOMAIN}feature01`,
  FEATURE_02: `${DOMAIN}feature02`,
  FEATURE_03: `${DOMAIN}feature03`,
  ON_ENABLE_DESCRIPTION: `${DOMAIN}description`,
  ON_DISABLE_DESCRIPTION: `${DOMAIN}disableDescription`,
  DISMISS: `${DOMAIN}cancelLink`,
  ON_ENABLE_TITLE: `${DOMAIN}title`,
  ON_DISABLE_TITLE: `${DOMAIN}disableTitle`,
  ON_ENABLE_ERROR: `${DOMAIN}error`,
  ON_DISABLE_ERROR: `${DOMAIN}disableError`,
  ON_ENABLE_SUCCESS: `${DOMAIN}success`,
  ON_DISABLE_SUCCESS: `${DOMAIN}disableSuccess`,
};
