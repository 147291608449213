// Types
import { ProfilePersonalNameTitleEditBuilderHandlersType } from './types/profile-personal-name-title-edit-builder.handlers.type';
import { ProfilePersonalNameTitleEditBuilderReturnHandlersType } from './types/profile-personal-name-title-edit-builder-return.handlers.type';

const changeCurrentTitleAssetClickHandler = ({
  handleChangeCurrentTitleAssetClickTracking,
  setEditTitle,
}: ProfilePersonalNameTitleEditBuilderHandlersType): any => {
  handleChangeCurrentTitleAssetClickTracking();
  setEditTitle(true);
};

const ProfilePersonalNameTitleEditHandlers = (
  props: ProfilePersonalNameTitleEditBuilderHandlersType
): ProfilePersonalNameTitleEditBuilderReturnHandlersType => ({
  handleChangeCurrentTitleAssetClick: () => changeCurrentTitleAssetClickHandler(props),
});

export default ProfilePersonalNameTitleEditHandlers;
