// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { SESSION_EXPIRED_TRACKING_PROPS } from 'components/expired-session-modal/handlers/constants/expired-session-modal.tracking.handlers.constants';
// Types
import { SessionExpiredTrackingReturnHandlersType } from 'components/expired-session-modal/handlers/types/expired-session-modal.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const goToLoginFromExpiredSessionButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...SESSION_EXPIRED_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const SessionExpiredTrackingHandlers = (
  track: TrackBuilderType
): SessionExpiredTrackingReturnHandlersType => ({
  handleGoToLoginFromExpiredSessionButtonClickTracking: () =>
    track(goToLoginFromExpiredSessionButtonClickTrackingHandler()),
});

export { SessionExpiredTrackingHandlers };
