// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ParagraphComponent,
  MessageComponent,
  ResumeItemComponent,
  ScriptBoxComponent,
} from '@openbank/cb-ui-commons';
import PersonalNumberStep1ActionsComponent from './components/actions/personal-number-step1-actions.component';
import PersonalNumberStep1PhoneComponent from './components/phone/personal-number-step1-phone.component';
import PersonalNumberStep1RepeatPhoneComponent from './components/repeatPhone/personal-number-step1-repeat-phone.component';
// Constants
import constants from './constants/personal-number-step1.constants';
// Contexts
import { PersonalNumberStep1ContextConsumerHOC } from './contexts/personal-number-step1.context';
// Hooks
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';
// Styles
import { PersonalNumberStepLayoutViewStyled } from '../../personal-number.view.styled';
import {
  PersonalNumberStep1MessageViewStyled,
  PersonalNumberStep1ComponentStyled,
} from './personal-number-step1.component.styled';
// Translations
import { CURRENT_PHONE, PARAGRAPH } from './translations/personal-number-step1.translations';
import { ADDITIONAL_INFORMATION } from 'containers/personal/views/email/components/step1/components/message/translations/personal-email-step1-message.translations.cc';
// Utils
import { getMessageComponentConfig } from './utils/personal-number-step1.utils';
import { isCC } from 'utils/app.utils';

const PersonalNumberStep1Component = (): React.ReactElement => {
  const { canUpdatePhone, primaryConfigurablePhone } = PersonalNumberHook();
  const { descriptionProps, componentProps, titleProps, text, title } =
    getMessageComponentConfig(canUpdatePhone);

  return (
    <PersonalNumberStepLayoutViewStyled>
      <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_1_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={PARAGRAPH} />
      </ParagraphComponent>
      <ResumeItemComponent
        {...constants.PERSONAL_NUMBER_STEP_1_RESUME_ITEM_PROPS}
        label={<FormattedMessageComponent id={CURRENT_PHONE} />}
        value={primaryConfigurablePhone}
      />
      <PersonalNumberStep1ComponentStyled>
        <PersonalNumberStep1PhoneComponent />
        <PersonalNumberStep1RepeatPhoneComponent />
      </PersonalNumberStep1ComponentStyled>
      {isCC() ? (
        <ScriptBoxComponent>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={ADDITIONAL_INFORMATION} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={text} />
          </ParagraphComponent>
        </ScriptBoxComponent>
      ) : (
        <MessageComponent {...componentProps}>
          <PersonalNumberStep1MessageViewStyled>
            <ParagraphComponent {...titleProps}>
              <FormattedMessageComponent id={title} />
            </ParagraphComponent>
            <ParagraphComponent {...descriptionProps}>
              <FormattedMessageComponent id={text} />
            </ParagraphComponent>
          </PersonalNumberStep1MessageViewStyled>
        </MessageComponent>
      )}
      <PersonalNumberStep1ActionsComponent />
    </PersonalNumberStepLayoutViewStyled>
  );
};

export { PersonalNumberStep1Component };
export default PersonalNumberStep1ContextConsumerHOC(PersonalNumberStep1Component);
