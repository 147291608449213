// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { useTransactionsReportServiceCancelledContextConsumerHook } from 'containers/transactions/views/report/components/service-cancelled/contexts/transactions-report-service-cancelled.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { TransactionsReportServiceCancelledConfirmationActionsHandlers } from '../handlers/transactions-report-service-cancelled-confirmation-actions.handlers';
// Hooks
import { useCleanChallengeHook } from 'containers/transactions/views/report/hooks/clean-challenge.hook';
// Types
import { TransactionsReportServiceCancelledConfirmationActionsHookReturnType } from './types/transactions-report-service-cancelled-confirmation-actions.hook.type';

export const useTransactionsReportServiceCancelledConfirmationActionsHook =
  (): TransactionsReportServiceCancelledConfirmationActionsHookReturnType => {
    const { cancellationDate, files, setCancellationDate, setFiles } =
      useTransactionsReportServiceCancelledContextConsumerHook();
    const { challenge, challengeError, setChallengeError, setCaseId, setError } =
      useTransactionsReportContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    useCleanChallengeHook();

    return {
      ...TransactionsReportServiceCancelledConfirmationActionsHandlers({
        cancellationDate,
        cardId,
        challenge,
        files,
        setCancellationDate,
        setCaseId,
        setChallengeError,
        setError,
        setFiles,
        setResult,
        transactionId,
      }),
      challenge,
      challengeError,
    };
  };
