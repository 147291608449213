// Enumerations
import { CaseTypeEnum } from 'containers/transactions/views/report/enumerations/case-type.enumeration';
import { MotiveDalEnum } from 'containers/transactions/views/report/enumerations/motive-dal.enumeration';

const DISPUTE_PARAMS = {
  caseType: CaseTypeEnum.CARD_FRAUD_MANAGEMENT,
  motiveDal: MotiveDalEnum.NOT_ACK_CHARGE_AND_NOT_AUTH,
};

export { DISPUTE_PARAMS };
