export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_PROPS: {
    centerYoffSet: 2,
    height: 512,
    scale: 150,
    width: 1050,
    yOffset: 45,
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_MAP_GEOGRAPHY_PROPS: {
    disableOptimization: true,
    testId: 'card-control-locations-map-geography',
  },
};
