// Vendors
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Services
import { validateEmailFormatService } from '../services/login-email.services';

const validateEmailFormatResolver = async (email: string): Promise<[true] | [null, true]> => {
  const [, { status }] = await validateEmailFormatService(email);

  return wasSuccessRequest(status) ? [true] : [null, true];
};

export { validateEmailFormatResolver };
