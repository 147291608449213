// Vendors
import { ChatApi } from '@openbank/cb-ui-commons';
// Config
import { createChatResponseFromApi } from '../config/chat.config';
// Services
import * as services from '../services/chat.services';

export const getChatStatus: ChatApi['getChatStatus'] = async () => {
  const [response] = await services.getStatus();

  if (!response) {
    throw new Error();
  }

  return {
    available: response.enabled && response.available,
    schedule: response.calendarCode,
  };
};

export const getConversations: ChatApi['getConversations'] = async () => {
  const [response] = await services.getConversations();
  if (!response?.conversations?.length) {
    return;
  }

  let openConversation;
  response.conversations.reverse();
  // Get messages for each conversations, look for the first conversation
  // that has not been ended and store id as `openConversation`
  for (const conversation of response.conversations) {
    const messages = await getChatMessages(conversation.chatId);
    if (!messages.chatEnded) {
      openConversation = messages;
      break;
    }
  }

  return openConversation;
};

export const getChatMessages: ChatApi['getChatMessages'] = async chatId => {
  const [response] = await services.getChatMessages({ chatId });

  if (!response) {
    throw new Error();
  }

  return createChatResponseFromApi(response);
};

export const startChatSession: ChatApi['startChatSession'] = async params => {
  const [response] = await services.startChatSession({
    applicationLocale: params.applicationLocale ?? 'de-DE',
    alias: params.author,
    topic: params.text,
  });

  if (!response) {
    throw new Error();
  }

  return createChatResponseFromApi(response);
};

export const sendChatMessage: ChatApi['sendChatMessage'] = async params => {
  const [response] = await services.sendChatMessage({
    chatId: params.chatId,
    message: params.text,
    transcriptPosition: params.position,
  });

  if (!response) {
    throw new Error();
  }

  return createChatResponseFromApi(response);
};

export const closeChatSession: ChatApi['closeChatSession'] = async params =>
  await services.closeChatSession(params);
