// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodSecondStepHandlers from '../handlers/financing-payment-method-second-step.handlers';
// Types
import { FinancingPaymentMethodSecondStepHookReturnType } from './types/financing-payment-method-second-step-return.hook.type';
import { useEffect } from 'react';

const FinancingPaymentMethodSecondStepHook = (): FinancingPaymentMethodSecondStepHookReturnType => {
  const { currentCard } = AppContextConsumerHook(),
    { cardContractId: contractId } = currentCard || {};
  const {
    challenge,
    challengeError,
    setChallenge,
    setChallengeError,
    setError,
    paymentMethodParams,
  } = FinancingPaymentMethodContextConsumerHook();

  const { handleChallengeRender, ...rest } = FinancingPaymentMethodSecondStepHandlers({
    challenge,
    paymentMethodParams,
    setChallenge,
    setChallengeError,
    setError,
  });

  useEffect(() => {
    handleChallengeRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    ...rest,
    challengeError,
    contractId,
    paymentMethodParams,
  };
};

export default FinancingPaymentMethodSecondStepHook;
