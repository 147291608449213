// Services
import { selectMethodService } from '../services/step-3-select-method.services';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Types
import { CaseInitResponseType } from '../services/types/step-0-init.type';
import { SelectMethodResolverType } from './types/step-3-select-method.resolvers.type';

export const selectMethodResolver = async (
  props: SelectMethodResolverType
): Promise<[CaseInitResponseType | null, boolean]> => {
  const [response, { status }] = await selectMethodService(props);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};
