// Constants
import {
  OPEN_BLOCK_MODAL_PAYLOAD,
  OPEN_BLOCK_UNROLL_PAYLOAD,
  OPEN_UNBLOCK_MODAL_PAYLOAD
} from './constants/device-info.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { BlockHandlersReturnTrackingType } from './type/device-info.handlers.return.tracking.type';

const openBlockModalDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_BLOCK_MODAL_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openUnrollTrustedModalDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_BLOCK_UNROLL_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openUnBlockModalDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_UNBLOCK_MODAL_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const DeviceInfoTrackingHandlers = (
  track: (param: unknown) => void
): BlockHandlersReturnTrackingType => ({
    onOpenUnrollTrustedModalDeviceTracking: () => track(openUnrollTrustedModalDeviceTrackingHandler()),
    onOpenBlockModalDeviceTracking: () => track(openBlockModalDeviceTrackingHandler()),
    onOpenUnBlockModalDeviceTracking: () => track(openUnBlockModalDeviceTrackingHandler())
});

export { DeviceInfoTrackingHandlers };