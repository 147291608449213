// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import RequestPortabilityStep2Hook from '../../hooks/request-portability-step2.hook';
// Utils
import { requestPortabilityStep2NextButtonIsDisabled } from './utils/request-portability-step2-actions.utils';

const RequestPortabilityStep2ActionsComponent = (): React.ReactElement => {
  const { emailCheckboxValue, emailValue } = AccessPortabilityContextConsumerHook();
  const { handleNextStepClickEvent } = RequestPortabilityStep2Hook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        onClick: handleNextStepClickEvent,
        disabled: requestPortabilityStep2NextButtonIsDisabled({
          emailCheckboxValue,
          emailValue,
        }),
      }}
    />
  );
};

export default RequestPortabilityStep2ActionsComponent;
