const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.additional.covered.';

const TITLE = `${DOMAIN}title`;
const DESCRIPTION = `${DOMAIN}description`;
const COVERED_LIST_FIRST = `${DOMAIN}coveredListFirst`;
const COVERED_LIST_SECOND = `${DOMAIN}coveredListSecond`;
const COVERED_LIST_THIRD = `${DOMAIN}coveredListThird`;
const SECOND_DESCRIPTION = `${DOMAIN}secondDescription`;

export {
  COVERED_LIST_FIRST,
  COVERED_LIST_SECOND,
  COVERED_LIST_THIRD,
  DESCRIPTION,
  SECOND_DESCRIPTION,
  TITLE,
};
