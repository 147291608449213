// Vendors
import React from 'react';
// Components
import { ParagraphComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CardSettingsCheck3dConfirmationButtonsComponent from './components/buttons/card-settings-check-3d-confirmation-buttons.component';
import CardSettingsCheck3dConfirmationFooterComponent from './components/footer/card-settings-check-3d-confirmation-footer.component';
import CardSettingsCheck3dConfirmationMessageComponent from './components/message/card-settings-check-3d-confirmation-message.component';
import CardSettingsCheck3dConfirmationSecurityModuleComponent from './components/security-module/card-settings-check-3d-confirmation-security-module.component';
// Constants
import {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_TITLE_PROPS,
} from './constants/card-settings-check-3d-confirmation.constants';
// Contexts
import { CardSettingsCheck3DConfirmationContextConsumerHOC } from './contexts/card-settings-check-3d-confirmation.context';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import { CardSettingsCheck3dConfirmationComponentStyled } from './card-settings-check-3d-confirmation.component.styled';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from './translations/card-settings-check-3d-confirmation.translations';

const CardSettingsCheck3dConfirmationComponent = (): React.ReactElement => (
  <AppCardSectionComponentStyled>
    <AppCardContentSectionComponentStyled>
      <CardSettingsCheck3dConfirmationComponentStyled>
        <TitleHeaderComponent {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_TITLE_PROPS}>
          <FormattedMessageComponent id={TITLE} />
        </TitleHeaderComponent>
        <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <CardSettingsCheck3dConfirmationSecurityModuleComponent />
        <CardSettingsCheck3dConfirmationButtonsComponent />
        <CardSettingsCheck3dConfirmationMessageComponent />
        <CardSettingsCheck3dConfirmationFooterComponent />
      </CardSettingsCheck3dConfirmationComponentStyled>
    </AppCardContentSectionComponentStyled>
  </AppCardSectionComponentStyled>
);

export { CardSettingsCheck3dConfirmationComponent };

export default CardSettingsCheck3DConfirmationContextConsumerHOC(
  CardSettingsCheck3dConfirmationComponent
);
