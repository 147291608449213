// Vendors
import React from 'react';
// Components
import CardSettingsCheck3dSidebarComponent from './components/sidebar/card-settings-check-3d-sidebar.component';
import CardSettingsCheck3dOrchestratorComponent from './components/orchestrator/card-settings-check-3d-orchestrator.component';
// Contexts
import { CardSettingsCheck3DContextConsumerHOC } from './contexts/card-settings-check-3d.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const CardSettingsCheck3dBaseView = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <CardSettingsCheck3dOrchestratorComponent />
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <CardSettingsCheck3dSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

const CardSettingsCheck3dView = CardSettingsCheck3DContextConsumerHOC(CardSettingsCheck3dBaseView);

export { CardSettingsCheck3dBaseView, CardSettingsCheck3dView };
