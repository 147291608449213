// Resolvers
import { getDashboardInformationResolver } from '../resolvers/dashboard.resolver';
// Types
import { DashboardBuilderReturnHandlersType } from './types/dashboard-builder-return.handlers.type';
import { DashboardBuilderHandlersType } from './types/dashboard-builder.handlers.type';
import { DashboardComponentType } from '../types/dashboard.component.type';

const fetchDashboardInformationEventHandler = async ({
  setPageLoader,
  setError,
  setInformation,
}: DashboardBuilderHandlersType): Promise<void> => {
  setPageLoader(true);

  const [response, error] = await getDashboardInformationResolver();

  error ? setError(true) : setInformation(response as DashboardComponentType);
  setPageLoader(false);
};

const DashboardHandlers = (
  props: DashboardBuilderHandlersType
): DashboardBuilderReturnHandlersType => ({
  handleFetchDashboardInformationEvent: () => fetchDashboardInformationEventHandler(props),
});

export default DashboardHandlers;
