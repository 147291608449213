// Vendors
import React from 'react';
// Components
import { ParagraphComponent, TitleComponent, ScriptBoxComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import PersonalNumberStep3ActionsComponent from './components/actions/personal-number-step3-actions.component';
import PersonalNumberStep3ConfirmationOptionComponent from './components/confirmation-option/personal-number-step3-confirmation-option.component';
import { ClientName } from 'containers/recovery-key/components/client-name/client-name.component';
// Constants
import constants from './constants/personal-number-step3.constants';
// Contexts
import { PersonalNumberStep3ContextConsumerHOC } from './contexts/personal-number-step3.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Styles
import { PersonalNumberStepLayoutViewStyled } from '../../personal-number.view.styled';
import {
  PersonalNumberStep3MessageViewStyled,
  PersonalNumberStep3SubtitleStyled,
} from './personal-number-step3.component.styled';
// Translations
import { DESCRIPTION, SUBTITLE } from './translations/personal-number-step3.translations';
import {
  COSTUMER_CANNOT_RECEIVE,
  COSTUMER_CANNOT_RECEIVE_STEPS,
  MODIFY_PERSONAL_DATA,
  SEND_CONFIRMATION_CODE,
} from '../../translations/personal-number.translations';
// Utilities
import { isCC } from 'utils/app.utils';

const PersonalNumberStep3Component = (): React.ReactElement => {
  const {
    profile: {
      userDetails: { firstSurname: firstLastName },
    },
  } = AppContextConsumerHook();

  return (
    <PersonalNumberStepLayoutViewStyled>
      <PersonalNumberStep3MessageViewStyled>
        <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_3_MESSAGE_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        {isCC() && (
          <ScriptBoxComponent>
            <ClientName firstLastName={firstLastName} />
            <ParagraphComponent>
              <FormattedMessageComponent id={SEND_CONFIRMATION_CODE} />
            </ParagraphComponent>
            <ParagraphComponent>
              <FormattedMessageComponent id={MODIFY_PERSONAL_DATA} />
            </ParagraphComponent>
          </ScriptBoxComponent>
        )}
        <PersonalNumberStep3SubtitleStyled>
          <TitleComponent {...constants.PERSONAL_NUMBER_STEP_3_SUBTITLE_PROPS}>
            <FormattedMessageComponent id={SUBTITLE} />
          </TitleComponent>
        </PersonalNumberStep3SubtitleStyled>
        <PersonalNumberStep3ConfirmationOptionComponent />
        {isCC() && (
          <ScriptBoxComponent>
            <ParagraphComponent bold>
              <FormattedMessageComponent id={COSTUMER_CANNOT_RECEIVE} />
            </ParagraphComponent>
            <ParagraphComponent>
              <FormattedMessageComponent id={COSTUMER_CANNOT_RECEIVE_STEPS} />
            </ParagraphComponent>
          </ScriptBoxComponent>
        )}
      </PersonalNumberStep3MessageViewStyled>
      <PersonalNumberStep3ActionsComponent />
    </PersonalNumberStepLayoutViewStyled>
  );
};

export { PersonalNumberStep3Component };
export default PersonalNumberStep3ContextConsumerHOC(PersonalNumberStep3Component);
