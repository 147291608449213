const DOMAIN = 'cobranded.services.dashboard.cbMessage.creditLimitStatus.';

const translations = {
  APPROVED_TITLE: `${DOMAIN}approvedTitle`,
  APPROVED_DESCRIPTION: `${DOMAIN}approvedDescription`,
  APPROVED_CTA: `${DOMAIN}approvedCTA`,
  PENDING_TITLE: `${DOMAIN}pendingTitle`,
  PENDING_DESCRIPTION: `${DOMAIN}pendingDescription`,
  EVALUATE_NEW_BETTER_OFFER_TITLE: `${DOMAIN}evaluateOfferTitle`,
  EVALUATE_NEW_BETTER_OFFER_DESCRIPTION: `${DOMAIN}evaluateOfferDescription`,
  EVALUATE_NEW_BETTER_OFFER_CTA: `${DOMAIN}evaluateOfferCTA`,
  COMPLETE_CREDIT_LIMIT_INCREASE_TITLE: `${DOMAIN}finishCreditLimitTitle`,
  COMPLETE_CREDIT_LIMIT_INCREASE_DESCRIPTION: `${DOMAIN}finishCreditLimitDescription`,
  COMPLETE_CREDIT_LIMIT_INCREASE_CTA: `${DOMAIN}finishCreditLimitCTA`,
  ADDITIONAL_DOCUMENTATION_TITLE: `${DOMAIN}additionalDocumentationTitle`,
  ADDITIONAL_DOCUMENTATION_DESCRIPTION: `${DOMAIN}additionalDocumentationDescription`,
  ADDITIONAL_DOCUMENTATION_CTA: `${DOMAIN}additionalDocumentationCTA`,
};

export { translations };
