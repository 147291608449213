// Types
import { SupportCancelContractFirstStepActionsUtilsType } from './types/support-cancel-contract-first-step-actions.utils.type';

const modifyCancelContractStep1NextButtonIsDisabled = ({
  reason,
}: SupportCancelContractFirstStepActionsUtilsType): boolean => {
  return !reason;
};

export { modifyCancelContractStep1NextButtonIsDisabled };
