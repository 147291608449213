// Enumerations
import { BankIdsEnum, PlatformsEnum } from '@openbank/cf-ui-static-data';

const featureFlagsConfig = {
  appVersion: '2.164.1',
  bankId: BankIdsEnum.OPENBANK_DEU,
  platform: PlatformsEnum.CB,
};

export { featureFlagsConfig };
