// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';

const CITY_PROPS = {
  id: 'address-modal-input-city',
  name: InputNamesEnumeration.CITY,
};

const CITY_INPUT_PROPS = {
  disableFilter: false,
};

export { CITY_PROPS, CITY_INPUT_PROPS };
