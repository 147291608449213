// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  LIMIT_INCREASE_CASES_URL,
  WEBID_EXTEND_TOKEN_SERVICE_URL,
} from './constants/commons.constants';
import { SIGNATURE_CONFIRMATION_URL_SUFFIX } from './constants/step-6-signature.constants';
// Types
import { WebIdSignatureResolverType } from '../resolvers/types/step-6-signature-services-props.resolver.type';
import { WebIdWebsocketDataType } from '../handlers/types/webid-websocket-data.type';

const webIdSignatureService = ({
  caseId,
  isSuccess,
}: WebIdSignatureResolverType): Promise<[WebIdWebsocketDataType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${SIGNATURE_CONFIRMATION_URL_SUFFIX}`,
    params: {
      success: isSuccess,
    },
  });

const webIdExtendTokenService = (): Promise<[null, Response]> =>
  apiUtilsCb.get({
    endpoint: WEBID_EXTEND_TOKEN_SERVICE_URL,
  });

export { webIdSignatureService, webIdExtendTokenService };
