// Vendors
import React from 'react';
// Components
import {
  AssetComponent,
  ModalComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useInactivityHook } from './hooks/inactivity.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from '@/styles/app.styled';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import {
  INACTIVITY_MODAL_ASSET_PROPS,
  INACTIVITY_MODAL_BUTTON_PROPS,
  INACTIVITY_MODAL_DESCRIPTION_PROPS,
  INACTIVITY_MODAL_LINK_PROPS,
  INACTIVITY_MODAL_SECONDS_PROPS,
  INACTIVITY_MODAL_TITLE_PROPS,
  INACTIVITY_MODAL_LAYOUT_PROPS,
  INACTIVITY_MODAL_LAYOUT_CONTENT_PROPS,
  DEFAULT_INDEX,
} from './constants/inactivity.constants';
import {
  BUTTON,
  DESCRIPTION,
  DESCRIPTION_TITLE,
  LINK,
  SECONDS,
  TITLE,
} from './translations/inactivity.translations';
// Utilities
import { isIosWebView } from '@gruposantander/web-ui-framework';

const InactivityProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const {
    handleKillUserSessionAndExitLinkClick,
    handleKeepSessionAliveButtonClick,
    seconds,
    showModal,
  } = useInactivityHook();

  return (
    <>
      {showModal && (
        <ModalComponent
          index={DEFAULT_INDEX}
          isIosDevice={isIosWebView()}
          linkConfiguration={{
            ...INACTIVITY_MODAL_LINK_PROPS,
            children: <FormattedMessageComponent id={LINK} />,
            onClick: handleKillUserSessionAndExitLinkClick,
          }}
          primaryButtonConfiguration={{
            ...INACTIVITY_MODAL_BUTTON_PROPS,
            children: <FormattedMessageComponent id={BUTTON} />,
            onClick: handleKeepSessionAliveButtonClick,
          }}
          title={<FormattedMessageComponent id={TITLE} />}
        >
          <LayoutColumnFlexComponentStyled>
            <LayoutColumnFlexComponentStyled {...INACTIVITY_MODAL_LAYOUT_PROPS}>
              <AssetComponent {...INACTIVITY_MODAL_ASSET_PROPS} />
              <TitleComponent {...INACTIVITY_MODAL_SECONDS_PROPS}>
                <FormattedMessageComponent id={SECONDS} values={{ seconds }} />
              </TitleComponent>
            </LayoutColumnFlexComponentStyled>
            <LayoutColumnFlexComponentStyled {...INACTIVITY_MODAL_LAYOUT_CONTENT_PROPS}>
              <ParagraphComponent {...INACTIVITY_MODAL_TITLE_PROPS}>
                <FormattedMessageComponent id={DESCRIPTION_TITLE} />
              </ParagraphComponent>
              <ParagraphComponent {...INACTIVITY_MODAL_DESCRIPTION_PROPS}>
                <FormattedMessageComponent id={DESCRIPTION} />
              </ParagraphComponent>
            </LayoutColumnFlexComponentStyled>
          </LayoutColumnFlexComponentStyled>
        </ModalComponent>
      )}
      {children}
    </>
  );
};

export { InactivityProvider };
