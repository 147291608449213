// Vendors
import React from 'react';
// Components
import LabelsLinkCardComponent from 'components/labels-link-card/labels-link-card.component';
// Constants
import constants from './constants/security-keys-signature-key.constants';
// Hooks
import SecurityKeysHook from 'containers/security-keys/views/landing/hooks/security-keys.hook';
// Resolvers
import { getLastTimeSignatureKeyChangeResolver } from './resolvers/security-keys-signature-key.resolvers';
// Translations
import translations from './translations/security-keys-signature-key.translations';

const SecurityKeysSignatureKeyComponent = (): React.ReactElement => {
  const { timestamp: date } = SecurityKeysHook(getLastTimeSignatureKeyChangeResolver);

  return (
    <LabelsLinkCardComponent
      {...constants.SECURITY_KEYS_SIGNATURE_KEYS_PROPS}
      changeConfiguration={{ date, label: translations.LAST_LABEL }}
    />
  );
};

export default SecurityKeysSignatureKeyComponent;
