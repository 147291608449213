// Vendors
import React from 'react';
// Components
import SupportCancelContractSecondStepActionsComponent from './actions/support-cancel-contract-second-step-actions.component';
import SupportCancelContractSecondStepChallengeComponent from './challenge/support-cancel-contract-second-step-challenge.component';
import SupportCancelContractSecondStepContainerHeader from './components/support-cancel-contract-second-step-container-header.component';

const SupportCancelContractSecondStep = (): React.ReactElement => (
  <>
    <SupportCancelContractSecondStepContainerHeader />
    <SupportCancelContractSecondStepChallengeComponent />
    <SupportCancelContractSecondStepActionsComponent />
  </>
);

export default SupportCancelContractSecondStep;
