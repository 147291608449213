// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsChangePinNewPinBuilderReturnTrackingHandlersType } from './types/card-settings-change-pin-new-pin.tracking.handlers.type';

const changePinNewPinClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'newPin',
  },
  type: TrackingEventsEnum.LINK,
});

const changePinNewPinErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'newPinCardSettingsChangePinError',
    eventLabel: 'invalidPin',
    errorCategory: 'newPinCardSettingsChangePinError',
    errorDescription: 'invalidPin',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsChangePinNewPinTrackingHandlers = (
  track: (param: unknown) => void
): CardSettingsChangePinNewPinBuilderReturnTrackingHandlersType => ({
  handleCardSettingsChangePinNewPinClickTracking: () =>
    track(changePinNewPinClickTrackingHandler()),
  handleCardSettingsChangePinNewPinErrorTracking: () =>
    track(changePinNewPinErrorTrackingHandler()),
});

export default CardSettingsChangePinNewPinTrackingHandlers;
