// Constants
import { PRIMARY_BUTTON_CONFIGURATION } from '../constants/sepa-modal.component.constants';
// Translations
import translations from '../translations/sepa-modal.translations';
// Types
import { SepaModalConfigReturnType } from './types/sepa-modal.configurations-return.type';
import { SepaModalConfigType } from './types/sepa-modal.configurations.type';

export const getSepaModalConfig = ({
  formatMessage,
  onClose,
}: SepaModalConfigType): SepaModalConfigReturnType => ({
  title: formatMessage({ id: translations.TITLE }),
  onHeaderClose: onClose,
  primaryButtonConfiguration: {
    ...PRIMARY_BUTTON_CONFIGURATION,
    children: formatMessage({ id: translations.MODAL_BUTTON }),
    onClick: onClose,
    disabled: false,
  },
});
