// types
import { ShowExpirationDateUtilPropsType } from './types/card-settings-contract-travel-plus-message-component-utils.type';

const isTravelPlusContractExpired = (expirationDate: string): boolean =>
  new Date().getTime() >= new Date(String(expirationDate)).getTime();

const showExpirationDate = ({
  activated,
  expirationDate,
}: ShowExpirationDateUtilPropsType): boolean =>
  !activated && !!expirationDate && !isTravelPlusContractExpired(expirationDate);

export { showExpirationDate };
