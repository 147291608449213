// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { RequestPortabilityStep2EmailOptionsReturnTrackingHandlersType } from './types/request-portability-step2-email-options-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const emailInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'otherEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const RequestPortabilityStep2EmailOptionsTrackingHandlers = (
  track: TrackBuilderType
): RequestPortabilityStep2EmailOptionsReturnTrackingHandlersType => ({
  handleEmailInputClickTracking: () => track(emailInputClickTrackingHandler()),
});

export default RequestPortabilityStep2EmailOptionsTrackingHandlers;
