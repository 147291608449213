// Vendors
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const AmountContainerStyled = styled.div.attrs(({ testId }: TestIdPropsType) => ({
  'data-testid': testId,
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const AmountLeftWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const AmountLeftTitleWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const AmountRightWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AmountInputPrefixSuffixComponentStyled = styled.div<{
  errorCalc?: number;
  errorLength?: number;
  errorMaxLength: number;
}>`
  max-width: ${({ errorCalc, errorLength, errorMaxLength }) =>
    errorLength && errorLength > errorMaxLength ? `calc(100% - ${errorLength}*${errorCalc}%)` : ''};

  ${mobileMediaQuery} {
    max-width: 100%;
  }
`;
