// Constants
import checkCvvPinConstants from 'containers/card-settings/views/check-cvv-pin/constants/check-cvv-pin.constants';

const { CVV, PIN, TRANSLATIONS: { CVV_DOMAIN, PIN_DOMAIN } } = checkCvvPinConstants;
const SECTION = 'challenge.';

export default {
  [CVV]: {
    TITLE: `${CVV_DOMAIN}${SECTION}title`,
    DESCRIPTION: `${CVV_DOMAIN}${SECTION}description`,
    CANCEL: `${CVV_DOMAIN}${SECTION}cancel`,
    CONTINUE: `${CVV_DOMAIN}${SECTION}continue`,
  },
  [PIN]: {
    TITLE: `${PIN_DOMAIN}${SECTION}title`,
    DESCRIPTION: `${PIN_DOMAIN}${SECTION}description`,
    CANCEL: `${PIN_DOMAIN}${SECTION}cancel`,
    CONTINUE: `${PIN_DOMAIN}${SECTION}continue`,
  }
};
