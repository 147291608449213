// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TitleHeaderComponent, TooltipComponent, AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  NOTIFICATIONS_SETTINGS_VIEW_TITLE_PROPS,
  TOOLTIP_ASSET_PROPS,
  NOTIFICATIONS_SETTINGS_TOOLTIP_PROPS,
  DEFAULT_TEST_ID,
} from './constants/notifications-settings-title.constants';
// Styles
import { NotificactionsSettingsViewTitleStyled } from './notifications-settings-title.component.styled';
// Translations
import {
  TITLE_HEADER,
  TOOLTIP_TITLE,
} from './translations/notifications-settings-title.translations';
// Types
import { NotificationsSettingsTitlePropsType } from './types/notifications-settings-title.component.type';

const NotificationsSettingsTitleComponent = ({
  formatMessage,
  handleShowToolTipTracking,
}: NotificationsSettingsTitlePropsType): React.ReactElement => {
  return (
    <NotificactionsSettingsViewTitleStyled {...DEFAULT_TEST_ID}>
      <TitleHeaderComponent {...NOTIFICATIONS_SETTINGS_VIEW_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE_HEADER} />
      </TitleHeaderComponent>
      <TooltipComponent
        {...NOTIFICATIONS_SETTINGS_TOOLTIP_PROPS}
        content={formatMessage({ id: TOOLTIP_TITLE })}
        doWhenTooltipIsOpen={handleShowToolTipTracking}
      >
        <AssetComponent {...TOOLTIP_ASSET_PROPS} />
      </TooltipComponent>
    </NotificactionsSettingsViewTitleStyled>
  );
};

export { NotificationsSettingsTitleComponent };
