// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsBlockCardSecondStepSummaryReturnHookType } from './types/card-settings-block-card-second-step-summary-return.hook.type';

const CardSettingsBlockCardSecondStepSummaryHook =
  (): CardSettingsBlockCardSecondStepSummaryReturnHookType => {
    const { formatMessage } = useCbIntl();

    return {
      formatMessage,
    };
  };

export default CardSettingsBlockCardSecondStepSummaryHook;
