// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  MESSAGE_PROPS,
  MESSAGE_TITLE_PROPS,
} from './constants/financing-early-repayment-first-step-info-message.constants';
// Styles
import { FinancingEarlyRepaymentFirstStepInfoMessageContentStyled } from './financing-early-repayment-first-step-info-message.component.styled';
// Translations
import {
  TITLE,
  DESCRIPTION,
} from './translations/financing-early-repayment-first-step-info-message.translations';

const FinancingEarlyRepaymentFirstStepInfoMessageComponent = (): React.ReactElement => (
  <MessageComponent {...MESSAGE_PROPS}>
    <FinancingEarlyRepaymentFirstStepInfoMessageContentStyled>
      <ParagraphComponent {...MESSAGE_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </ParagraphComponent>
      <ParagraphComponent>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </FinancingEarlyRepaymentFirstStepInfoMessageContentStyled>
  </MessageComponent>
);

export default FinancingEarlyRepaymentFirstStepInfoMessageComponent;
