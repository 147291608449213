// Vendors
import styled from 'styled-components';
// Constants
import { PERSONAL_PHOTO_NAME_BUTTON_INPUT_PROPS } from './constants/personal-photo-name-button.constants';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const PersonalPhotoNameButtonComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    flex: 100%;
    gap: ${({ theme }) => theme.spaces.spacingM};
    width: 100%;
  }
`;

const PersonalPhotoNameButtonInputComponentStyled = styled.input.attrs(
  () => PERSONAL_PHOTO_NAME_BUTTON_INPUT_PROPS
)`
  display: none;
`;

export { PersonalPhotoNameButtonComponentStyled, PersonalPhotoNameButtonInputComponentStyled };
