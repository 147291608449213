// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  ACESS_KEY_SIDEBAR_PROPS: {
    testId: 'access-key',
  },
  ACESS_KEY_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'access-key-sidebar-message-01',
    size: paragraphConstants.SIZES.M,
  },
  ACESS_KEY_SIDEBAR_MESSAGE_02_PROPS: {
    testId: 'access-key-sidebar-message-02',
    size: paragraphConstants.SIZES.M,
  },
};
