// Vendors
import React from 'react';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Components
import {
  ParagraphComponent,
  DownloadDocumentComponent,
  CheckboxComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useFinancingInsurancesContratingLPIStep3Download } from './hooks/financing-insurances-contracting-lpi-step3-download.hook';
// Translations
import {
  DOWNLOAD_DOCUMENT_BUTTON_LABEL,
  DOWNLOAD_DOCUMENT_TITLE,
  NOT_DOWNLOADED_DOCUMENT_ERROR_MESSAGE,
  SEND_DOCUMENT_VIA_POST_CHECKBOX_LABEL,
} from './translations/financing-insurances-contracting-lpi-step3-download.translations';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { FinancingInsurancesContratingLPIDownloadGridStyled } from 'containers/financing/views/ppi/components/contracting/lpi/financing-insurances-contracting-lpi.component.styled';
// Types
import { FinancingInsurancesContractingLPIStep3DownloadComponentType } from './types/financing-insurances-contracting-lpi-step3-download.component.type';
// Utils
import { getDownloadState } from 'containers/financing/views/ppi/components/contracting/lpi/utils/financing-insurances-contracting-lpi.utils';
import { formatAddress } from 'utils/addresses/addresses.utils';

export const FinancingInsurancesContractingLPIStep3DownloadComponent = ({
  downloadedError,
  handleToggleDownloaded,
}: FinancingInsurancesContractingLPIStep3DownloadComponentType): React.ReactElement => {
  const {
    address,
    downloading,
    formatMessage,
    handleDownloadLinkClick,
    handleTogglePostalDocumentation,
    postalDocumentation,
  } = useFinancingInsurancesContratingLPIStep3Download({
    handleToggleDownloaded,
  });

  return (
    <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingS}>
      <ParagraphComponent>
        <FormattedMessageComponent id={DOWNLOAD_DOCUMENT_TITLE} />
      </ParagraphComponent>
      <FinancingInsurancesContratingLPIDownloadGridStyled>
        <DownloadDocumentComponent
          onDownload={handleDownloadLinkClick}
          onRetryDownload={handleDownloadLinkClick}
          state={getDownloadState({ downloading, downloadedError })}
          translations={{
            DOWNLOAD_LINK: <FormattedMessageComponent id={DOWNLOAD_DOCUMENT_BUTTON_LABEL} />,
            RETRY_DOWNLOAD_MESSAGE: (
              <FormattedMessageComponent id={NOT_DOWNLOADED_DOCUMENT_ERROR_MESSAGE} />
            ),
            RETRY_DOWNLOAD_LINK: <FormattedMessageComponent id={DOWNLOAD_DOCUMENT_BUTTON_LABEL} />,
          }}
        />
      </FinancingInsurancesContratingLPIDownloadGridStyled>
      <CheckboxComponent
        value={postalDocumentation}
        onChange={handleTogglePostalDocumentation}
        title={
          <FormattedMessageComponent
            id={SEND_DOCUMENT_VIA_POST_CHECKBOX_LABEL}
            values={{ customerAddress: formatAddress({ address, formatMessage }) }}
          />
        }
      />
    </LayoutColumnFlexComponentStyled>
  );
};
