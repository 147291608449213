// Constants
import { linkConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/enable-disable-card-confirmation-modal.translations';

export default {
  CONFIRMATION_MODAL_BUTTON_PROPS: {
    testId: 'confirmation-modal-confirm-button',
  },
  CONFIRMATION_MODAL_DESCRIPTION_PROPS: {
    testId: 'confirmation-modal-description-text',
  },
  CONFIRMATION_MODAL_DETAILS_PROPS: {
    large: true,
  },
  CONFIRMATION_MODAL_FEATURES: [
    {
      label: translations.FEATURE_01,
      testId: 'confirmation-modal-feature-1',
    },
    {
      label: translations.FEATURE_02,
      testId: 'confirmation-modal-feature-2',
    },
    {
      label: translations.FEATURE_03,
      testId: 'confirmation-modal-feature-3',
    },
  ],
  CONFIRMATION_MODAL_FEATURES_BULLET_PROPS: {
    colors: {
      fill: 'brandB',
    },
    id: 'bullet',
    sizes: {
      height: 8,
      width: 8,
    },
    testId: 'confirmation-modal-feature-asset',
  },
  CONFIRMATION_MODAL_LINK_PROPS: {
    type: linkConstants.TYPES.ACTION_SECONDARY,
    testId: 'confirmation-modal-dismiss-link',
  },
};
