// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { TravelPlusMessageHookType } from './types/travel-plus-message.hook.type';

const useTravelPlusMessageHook = (): TravelPlusMessageHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { travelPlus } = currentCard || {},
    { activated, expirationDate } = travelPlus || {};

  return {
    activated,
    expirationDate,
  };
};

export { useTravelPlusMessageHook };
