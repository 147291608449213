// Contexts
import { FinancingEarlyRepaymentContextConsumerHook } from 'containers/financing/views/early-repayment/contexts/financing-early-repayment.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingEarlyRepaymentSecondStepSummaryReturnHookType } from './types/financing-early-repayment-second-step-summary-return.hook.type';

const FinancingPaymentMethodSecondStepSummaryHook =
  (): FinancingEarlyRepaymentSecondStepSummaryReturnHookType => {
    const { formatMessage } = useCbIntl();

    const { eppDetails } = FinancingEarlyRepaymentContextConsumerHook();

    return {
      eppDetails,
      formatMessage,
    };
  };

export default FinancingPaymentMethodSecondStepSummaryHook;
