// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getTransactionAmazonPointsConfiguration } from 'containers/transactions/views/landing/components/row/components/details/components/points/configurations/transactions-landing-row-details-points.configuration';
// Constants
import { TABLE_ROW_DESCRIPTION_POINTS_PROPS } from './constants/table-row-description-points.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Types
import { TableRowDescriptionComponentType } from '../../types/table-row-description.component.type';
// Utilities
import { transactionHasPoints } from './utils/table-row-description-points.utils';

const TableRowDescriptionPointsComponent = ({
  reward,
}: Pick<TableRowDescriptionComponentType, 'reward'>): React.ReactElement | null => {
  const { formatNumber } = useCbIntl();

  return transactionHasPoints(reward) ? (
    <ParagraphSecondaryComponentStyled>
      <ParagraphComponent {...TABLE_ROW_DESCRIPTION_POINTS_PROPS}>
        <FormattedMessageComponent
          {...getTransactionAmazonPointsConfiguration({ reward, formatNumber })}
        />
      </ParagraphComponent>
    </ParagraphSecondaryComponentStyled>
  ) : null;
};
export default TableRowDescriptionPointsComponent;
