// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsChangePinContextConsumerHook } from 'containers/card-settings/views/change-pin/contexts/card-settings-change-pin.context';
// Types
import { CardSettingsChangePinConstraintsHookType } from './types/card-settings-change-pin-constraints.hook.type';

const useCardSettingsChangePinConstraintsHook = (): CardSettingsChangePinConstraintsHookType => {
  const { profile } = AppContextConsumerHook(),
    { challenged, solvedChallenged, newPin } = useCardSettingsChangePinContextConsumerHook(),
    { dateOfBirth } = profile?.birthDetails || {};

  return { dateOfBirth, challenged, solvedChallenged, newPin };
};

export default useCardSettingsChangePinConstraintsHook;
