// Translations
import translations from 'containers/card-settings/views/landing/components/toggles/translations/card-settings-toggles.translations';
import { STATUS } from '../../translations/card-settings-benefits.translations';
// Types
import { CardSettingsBenefitsTravelPlusStatusConfigurationPropsType } from './types/card-settings-benefits-travel-plus-status.configuration.type';
import { CardSettingsBenefitsTravelPlusStatusConfigurationReturnType } from './types/card-settings-benefits-travel-plus-status-return.configuration.type';

const getBenefitsLandingTravelPlusStatusConfiguration = ({
  travelPlusInfo,
  formatDate,
  formatMessage,
}: CardSettingsBenefitsTravelPlusStatusConfigurationPropsType): CardSettingsBenefitsTravelPlusStatusConfigurationReturnType => ({
  title: formatMessage({ id: STATUS }),
  subtitle:
    travelPlusInfo?.expirationDate && !travelPlusInfo?.status
      ? `${formatMessage({ id: translations.ACTIVE_UNTIL })} ${formatDate(
          travelPlusInfo?.expirationDate
        )}`
      : null,
});

export { getBenefitsLandingTravelPlusStatusConfiguration };
