// Vendors
import styled from 'styled-components';

const CardSettingsContractViewStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
`;

export { CardSettingsContractViewStyled };
