// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import LocalizationHandlers from '../handlers/localization.handlers';
// Types
import { FetchErrorContextType } from 'contexts/fetch-error/types/fetch-error.context.type';

const useLocalizationHook = (): Pick<FetchErrorContextType, 'fetching'> => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { setLocalization } = AppContextConsumerHook();

  const { handleFetchLocalizationConfiguration } = LocalizationHandlers({
    setFetching,
    setLocalization,
  });

  useEffect(() => {
    handleFetchLocalizationConfiguration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fetching };
};

export { useLocalizationHook };
