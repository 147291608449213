// Vendors
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Configurations
import { convertNavItemLinksConfiguration } from './orchestrator-header.configurations.common';
// Translations
import { LAST_ACCESS } from '../translations/orchestrator-header.translations';
// Types
import { GetLastAccessTranslationConfiguration } from './types/get-last-asccess-translation.configuration.type';

const getLastAccessTranslationConfiguration = ({
  formatDate,
  formatMessage,
  lastAccess,
}: GetLastAccessTranslationConfiguration): string | undefined =>
  lastAccess &&
  formatMessage(
    { id: LAST_ACCESS },
    { date: formatDate(lastAccess, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY) }
  );

export { getLastAccessTranslationConfiguration, convertNavItemLinksConfiguration };
