// Components
import { TransactionsReportPaymentAdditionalInfoComponent } from '../components/additional-info/transactions-report-payment-additional-info.component';
import { TransactionsReportPaymentDocumentsComponent } from '../components/documents/transactions-report-payment-documents.component';
import { TransactionsReportPaymentConfirmationComponent } from '../components/confirmation/transactions-report-payment-confirmation.component';
// Translations
import {
  ADDITIONAL_INFORMATION_TITLE,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
  UPLOAD_DOCUMENTS_DESCRIPTION,
  UPLOAD_DOCUMENTS_TITLE,
} from 'containers/transactions/views/report/translations/transactions-report.translations';
import { ADDITIONAL_INFORMATION_DESCRIPTION } from '../translations/transactions-report-payment.translations';

const STEPS = [
  {
    component: TransactionsReportPaymentAdditionalInfoComponent,
    description: ADDITIONAL_INFORMATION_DESCRIPTION,
    title: ADDITIONAL_INFORMATION_TITLE,
  },
  {
    component: TransactionsReportPaymentDocumentsComponent,
    description: UPLOAD_DOCUMENTS_DESCRIPTION,
    title: UPLOAD_DOCUMENTS_TITLE,
  },
  {
    component: TransactionsReportPaymentConfirmationComponent,
    description: CONFIRMATION_DESCRIPTION,
    title: CONFIRMATION_TITLE,
  },
];

export { STEPS };
