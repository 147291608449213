const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.exitModal.';
const DOMAIN_STEP_1_OR_2 = `${DOMAIN}step1Or2.`;
const DOMAIN_OTHER_STEPS = `${DOMAIN}otherSteps.`;
const DOMAIN_REJECT_OFFER = `${DOMAIN}rejectOffer.`;

const translationsStep1Or2 = {
  TITLE: `${DOMAIN_STEP_1_OR_2}title`,
  DESCRIPTION: `${DOMAIN_STEP_1_OR_2}description`,
  LINK_TEXT: `${DOMAIN_STEP_1_OR_2}linkText`,
  PRIMARY_BUTTON_TEXT: `${DOMAIN_STEP_1_OR_2}primaryButtonText`,
};
const translationsOtherSteps = {
  TITLE: `${DOMAIN_OTHER_STEPS}title`,
  DESCRIPTION: `${DOMAIN_OTHER_STEPS}description`,
  LINK_TEXT: `${DOMAIN_OTHER_STEPS}linkText`,
  PRIMARY_BUTTON_TEXT: `${DOMAIN_OTHER_STEPS}primaryButtonText`,
};
const translationsRejectOffer = {
  TITLE: `${DOMAIN_REJECT_OFFER}title`,
  DESCRIPTION: `${DOMAIN_REJECT_OFFER}description`,
  LINK_TEXT: `${DOMAIN_REJECT_OFFER}linkText`,
  PRIMARY_BUTTON_TEXT: `${DOMAIN_REJECT_OFFER}primaryButtonText`,
};

export { translationsStep1Or2, translationsOtherSteps, translationsRejectOffer };
