// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
// Event handlers
import RequestPortabilityStep3ActionsHandlers from '../handlers/request-portability-step3-actions.handlers';
// Types
import { RequestPortabilityStep3ActionsHookType } from './types/request-portability-step3-actions.hook.type';

const RequestPortabilityStep3ActionsHook =
  (): RequestPortabilityStep3ActionsHookType => {
    const { challenged, error, fetching, progressId, setFetching, portabilityParams } =
      AccessPortabilityContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();
      

    return {
      ...RequestPortabilityStep3ActionsHandlers({
        portabilityParams,
        progressId,
        setFetching,
        setResult,
      }),
      challenged,
      error,
      fetching,
    };
  };

export default RequestPortabilityStep3ActionsHook;
