// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const NotFoundContainerStyled = styled.div`
  height: 100%;
  position: relative;
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    justify-content: center;
    height: 75vh;
    overflow: hidden;

    video {
      width: 150vh;
    }
  }
`;
NotFoundContainerStyled.displayName = 'NotFoundContainerStyled';

const NotFoundContentContainerStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  max-width: 50vw;
  padding: ${({ theme }) => theme.spaces.spacingXl};
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
    padding: ${({ theme }) => theme.spaces.spacingM};
    max-width: 100%;
  }
`;
NotFoundContentContainerStyled.displayName = 'NotFoundContentContainerStyled';

export { NotFoundContainerStyled, NotFoundContentContainerStyled };
