// Services
import { fetchNotificationsSettingsService, saveNotificationsSettingsService } from '../services/notifications-settings-list.service';
// Types
import { NotificationsSettingsProductType } from 'containers/notifications/views/notifications-settings/types/notifications-settings-product.type';
import { NotificationsSettingsFormattedType } from 'containers/notifications/views/notifications-settings/components/list/types/notification-settings-formatted.type';
import { saveNotificationsSettingsResolverType } from './types/save-notifications-settings-list.resolver.type';
// Utilities
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
import { isValidNotificationsSettingsApiContract, formatSettingsResponse } from './utils/notifications-settings.resolver.utils';

const fetchNotificationsSettingsResolver = async (): Promise<[NotificationsSettingsFormattedType] | [null, number]> => {
  const [response, { status }] = await fetchNotificationsSettingsService();

  return isValidNotificationsSettingsApiContract(response) ? [formatSettingsResponse(response)] : [null, status];
};

const saveNotificationsSettingsResolver = async ({
  formattedSettings
}: saveNotificationsSettingsResolverType): Promise<[number | any] | [null, true]> => {
  const [, { status }] = await saveNotificationsSettingsService(formattedSettings);

  return requestHasSuccessStatus(status) ? [status] : [null, true];
};

export { fetchNotificationsSettingsResolver, saveNotificationsSettingsResolver };
