// Constants
import {
  FINANCING_PPI_LANDING_CANCEL_BUTTON_PROPS,
  FINANCING_PPI_LANDING_CONTRACT_BUTTON_PROPS,
} from './constants/financing-ppi-landing-button.configuration.constants';
// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';
// Translations
import {
  CANCEL_PPI_BUTTON,
  CONTRACT_PPI_BUTTON,
} from './translations/financing-ppi-landing-button.configuration.translations';
import {
  FinancingPPILandingButtonBuilderType,
  FinancingPPILandingButtonReturnType,
} from './types/financing-ppi-landing-button.configuration.type';

const getPPILandingButtonContractConfiguration = ({
  customerContractingEnabled,
  formatMessage,
  handleContractPPIButtonClick,
  handleOpenContractModalButtonClick,
  status,
}: Omit<FinancingPPILandingButtonBuilderType, 'handleCancelPPIContractButtonClick'>) =>
  [
    InsuranceStatusEnumeration.EXPIRED,
    InsuranceStatusEnumeration.UNHIRED,
    InsuranceStatusEnumeration.KYC_PENDING,
  ].includes(status as InsuranceStatusEnumeration) && {
    ...FINANCING_PPI_LANDING_CONTRACT_BUTTON_PROPS,
    children: formatMessage({ id: CONTRACT_PPI_BUTTON }),
    disabled: !customerContractingEnabled,
    onClick:
      status === InsuranceStatusEnumeration.KYC_PENDING
        ? handleOpenContractModalButtonClick
        : handleContractPPIButtonClick,
  };

const getPPILandingButtonCancelConfiguration = ({
  formatMessage,
  handleCancelPPIContractButtonClick,
  status,
}: Pick<
  FinancingPPILandingButtonBuilderType,
  'formatMessage' | 'handleCancelPPIContractButtonClick' | 'status'
>) =>
  [
    InsuranceStatusEnumeration.ACTIVE,
    InsuranceStatusEnumeration.COOLING_OFF,
    InsuranceStatusEnumeration.CREATED,
  ].includes(status as InsuranceStatusEnumeration) && {
    ...FINANCING_PPI_LANDING_CANCEL_BUTTON_PROPS,
    children: formatMessage({ id: CANCEL_PPI_BUTTON }),
    onClick: handleCancelPPIContractButtonClick,
  };

const getPPILandingButtonCoveredConfiguration = ({
  formatMessage,
  status,
}: Pick<FinancingPPILandingButtonBuilderType, 'formatMessage' | 'status'>) =>
  [InsuranceStatusEnumeration.TEMPORAL].includes(status as InsuranceStatusEnumeration) && {
    children: formatMessage({ id: CONTRACT_PPI_BUTTON }),
    disabled: true,
  };

const getPPILandingButtonConfiguration = ({
  handleCancelPPIContractButtonClick,
  handleContractPPIButtonClick,
  handleOpenContractModalButtonClick,
  ...props
}: FinancingPPILandingButtonBuilderType): FinancingPPILandingButtonReturnType =>
  getPPILandingButtonContractConfiguration({
    handleContractPPIButtonClick,
    handleOpenContractModalButtonClick,
    ...props,
  }) ||
  getPPILandingButtonCancelConfiguration({ handleCancelPPIContractButtonClick, ...props }) ||
  getPPILandingButtonCoveredConfiguration(props) ||
  {};

export { getPPILandingButtonConfiguration };
