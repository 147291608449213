// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Enumerations
import { DownloadDocumentIdsEnumeration } from '../enumerations/download-document-ids.enumeration';

const FINANCING_PPI_CONTRACTING_DOWNLOAD_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxs,
  },
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS = {
    id: DownloadDocumentIdsEnumeration.DOWNLOAD,
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    sizes: {
      height: 32,
      width: 32,
    },
    testId: 'financing-ppi-contracting-download-asset',
  },
  FINANCING_PPI_CONTRACTING_DOWNLOAD_LINK_PROPS = {
    testId: 'financing-ppi-contracting-download-link',
  };

export {
  FINANCING_PPI_CONTRACTING_DOWNLOAD_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_DOWNLOAD_LINK_PROPS,
};
