// Vendors
import { useEffect } from 'react';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import LogoutHandlers from 'handlers/logout.handlers';
import PersonalEmailLogoutModalHandlers from '../handlers/personal-email-logout-modal.handlers';
import PersonalEmailLogoutModalTrackingHandlers from '../handlers/personal-email-logout-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalEmailLogoutModalHookType } from './types/personal-email-logout-modal.hook.type';

const PersonalEmailLogoutModalHook = (): PersonalEmailLogoutModalHookType => {
  const { showModal } = PersonalEmailContextConsumerHook(),
    { setFetching } = ManagerContextConsumerHook();

  const { handleOpenLogOffModalTracking, handleLogoutFromChangeEmailButtonClickTracking } =
    AppTrackingHook(PersonalEmailLogoutModalTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleOpenLogOffModalTracking();
  }, []);

  return {
    ...PersonalEmailLogoutModalHandlers({
      ...LogoutHandlers(),
      handleLogoutFromChangeEmailButtonClickTracking,
      setFetching,
    }),
    showModal,
  };
};

export default PersonalEmailLogoutModalHook;
