// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import CardStatusSwitchOnModalHandlers from '../handlers/card-status-switch-on-modal.handlers';
import PersonalEmailCancelModalTrackingHandlers from '../handlers/card-status-switch-on-modal.tracking.handlers';
// Hooks
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoadingStatus } from 'types/loading-status.type';
import { CardStatusButtonSwitchOnModalComponentType } from '../types/card-status-button-switch-on-modal.component.type';
import { CardStatusButtonSwitchOnModalHookType } from './types/card-status-button-switch-on-modal.hook.type';

const CardStatusButtonSwitchOnModalHook = ({
  onClose,
}: Pick<
  CardStatusButtonSwitchOnModalComponentType,
  'onClose'
>): CardStatusButtonSwitchOnModalHookType => {
  const [fetching, setFetching] = useState<LoadingStatus>('idle');

  const { currentCard } = AppContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook(),
    { setCard } = ManagerContextConsumerHook(),
    { id: cardId } = currentCard;

  const {
    handleCancelSwitchOnCardLinkClickTracking,
    handleCloseSwitchOnCardModalAssetClickTracking,
    handleConfirmSwitchOnCardButtonClickTracking,
    handleOpenSwitchOnCardModalTracking,
  } = AppTrackingHook(PersonalEmailCancelModalTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleOpenSwitchOnCardModalTracking();
  }, []);

  return {
    ...CardStatusSwitchOnModalHandlers({
      cardId,
      handleCancelSwitchOnCardLinkClickTracking,
      handleCloseSwitchOnCardModalAssetClickTracking,
      handleConfirmSwitchOnCardButtonClickTracking,
      onClose,
      setCard,
      setFetching,
      setToastConfiguration,
    }),
    fetching,
  };
};

export default CardStatusButtonSwitchOnModalHook;
