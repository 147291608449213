// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
import { ToggleTypesEnumeration } from '@openbank/cb-ui-commons';
// Translations
import {
  INSURANCE_STATUS,
  TITLE,
} from '../translations/card-settings-contract-current-benefits-insurances.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_TOGGLE_PROPS = {
  disabled: true,
  label: INSURANCE_STATUS,
  type: ToggleTypesEnumeration.VERTICAL,
};

const CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DIVIDER_PROPS = {
  id: CardContractInfoSectionsEnumeration.INSURANCE,
  twoColumns: false,
  title: TITLE,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_TOGGLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DIVIDER_PROPS,
};
