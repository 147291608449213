// Vendors
import React from 'react';
// Components
import { FeatureFlagManager, LinkComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import { FilterChipsComponent } from 'components/filters/components/filter-chips/filter-chips.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import NotificationsLandingContentComponent from './components/content/notifications-landing-content.component';
import NotificationsLandingSidebarComponent from './components/sidebar/notifications-landing-sidebar.component';
// Constants
import {
  FILTERS_LINK_PROPS,
  LABELS_LINK_CARD_TITLE_PROPS,
  SETTINGS_LINK_PROPS,
} from './constants/notifications-landing.constants';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Hooks
import NotificationsLandingHook from './hooks/notifications-landing.hook';
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import {
  NotificactionsLandingViewStyled,
  NotificactionsLandingViewTitleStyled,
} from './notifications-landing.view.styled';
// Translations
import translations from './translations/notifications-landing.translations';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const NotificationsLandingView = (): React.ReactElement => {
  const { isMobile } = DeviceTypeHook();
  const {
    canGoNextPage,
    error,
    formValues,
    formValuesParams,
    handleGoToNotificationsSettings,
    handleSetPagination,
    handleShowFilterModal,
    isLoading,
    isLoadingNextPage,
    notifications,
    retryHandler,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
  } = NotificationsLandingHook();

  return (
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <NotificactionsLandingViewStyled>
          <NotificactionsLandingViewTitleStyled>
            <TitleHeaderComponent {...LABELS_LINK_CARD_TITLE_PROPS}>
              <FormattedMessageComponent id={translations.SUB_TITLE} />
            </TitleHeaderComponent>
            <LinkComponent {...FILTERS_LINK_PROPS} onClick={handleShowFilterModal}>
              <FormattedMessageComponent id={FILTERS_LINK_PROPS.label} />
            </LinkComponent>
            <FeatureFlagManager
              flags={getFeatureFlagInstance()}
              flag={FeatureFlagsEnumeration.NOTIFICATION_SETTING}
            >
              <LinkComponent {...SETTINGS_LINK_PROPS} onClick={handleGoToNotificationsSettings}>
                {!isMobile && <FormattedMessageComponent id={SETTINGS_LINK_PROPS.label} />}
              </LinkComponent>
            </FeatureFlagManager>
          </NotificactionsLandingViewTitleStyled>
          <FilterChipsComponent
            {...{
              formValues,
              formValuesParams,
              setCopyOfFormValues,
              setFormValues,
              setFormValuesParams,
            }}
          />

          <NotificationsLandingContentComponent
            {...{
              canGoNextPage,
              error,
              formValues,
              isLoading,
              isLoadingNextPage,
              notifications,
              handleSetPagination,
              onRetry: retryHandler,
            }}
          />
        </NotificactionsLandingViewStyled>
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled>
        <NotificationsLandingSidebarComponent />
      </LayoutGridContentRightComponentStyled>
    </LayoutGridContentComponentStyled>
  );
};

export default NotificationsLandingView;
