// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/security-keys-access-key-change-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import { AccessKeySidebarComponentStyled } from './security-keys-access-key-change-component-sidebar.component.styled';
// Translations
import translations from './translations/security-keys-access-key-change-sidebar.translations';

const SecurityKeysAccessKeyChangeSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.ACESS_KEY_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.SIDEBAR_TITLE} />}
    >
      <AccessKeySidebarComponentStyled>
        <ParagraphComponent {...constants.ACESS_KEY_SIDEBAR_MESSAGE_01_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_1} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.ACESS_KEY_SIDEBAR_MESSAGE_02_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_2} />
        </ParagraphComponent>
      </AccessKeySidebarComponentStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default SecurityKeysAccessKeyChangeSidebarComponent;
