// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useTransactionsReportDifferentAmountAtmContextConsumerHook } from 'containers/transactions/views/report/components/different-amount-atm/context/transactions-report-different-amount-atm.context';
// Handlers
import { TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlers } from '../handlers/transactions-report-different-amount-atm-additional-info-actions.handlers';
// Types
import { TransactionsReportDifferentAmountAtmAdditionalInfoActionsHookReturnType } from './types/transactions-report-different-amount-atm-additional-info-actions.hook.type';

export const useTransactionsReportDifferentAmountAtmAdditionalInfoActionsHook =
  (): TransactionsReportDifferentAmountAtmAdditionalInfoActionsHookReturnType => {
    const { setCurrentStep } = useWizardContextConsumerHook();
    const { setFormValues } = useTransactionsReportDifferentAmountAtmContextConsumerHook();

    return {
      ...TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlers({
        setFormValues,
        setCurrentStep,
      }),
    };
  };
