// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const PersonalPhotoNameButtonUploadModalButtonsComponentStyled = styled.footer`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export { PersonalPhotoNameButtonUploadModalButtonsComponentStyled };
