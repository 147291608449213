// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import { useIncreaseLimitHybridHook } from './hooks/increase-limit-hybrid.hook';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const IncreaseLimitHybridProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { fetching } = useIncreaseLimitHybridHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent {...{ fetching }}>{children}</FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export { IncreaseLimitHybridProvider };
