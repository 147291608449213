// Types
import { CardSettingsChangePinRepeatedPinUtilsType } from './types/card-settings-change-pin-repeat-pin.utils.type';
// Utilities
import { pinMatchesAllConstraints } from 'containers/card-settings/views/change-pin/utils/card-settings-change-pin.utils';

const repeatedPinComponentIsDisabled = ({
  challenged,
  dateOfBirth,
  newPin,
  solvedChallenged,
}: CardSettingsChangePinRepeatedPinUtilsType): boolean =>
  Boolean(
    challenged || solvedChallenged || !pinMatchesAllConstraints({ dateOfBirth, pin: newPin })
  );

export { repeatedPinComponentIsDisabled };
