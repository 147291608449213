export enum DocumentsUploadStatesEnumeration {
  ADD = 'add',
  BACK_OFFICE_ERROR = 'backOfficeError',
  REJECTED = 'rejected',
  DEFAULT = 'default',
  DISABLED = 'disabled',
  LOADING = 'loading',
  SENT = 'sent',
  SYSTEM_ERROR = 'systemError',
  UPLOADED = 'uploaded',
}
