// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  CHANGE_TITLE_SERVICE_CONFIGURATION,
  FETCH_MISCELLANEOUS_SERVICE_CONFIGURATION,
} from './constants/profile-personal-name-title-dropdown.services.constants';
// Enumerations
import { CustomerTitlesEnumeration } from 'enumerations/customer-titles.enumeration';
// Types
import { ProfilePersonalNameTitleDropdownApiMiscellaneousReturnType } from '../types/profile-personal-name-title-dropdown-api-miscellaneous-return.type';

const changeUserTitleService = async (
  title: CustomerTitlesEnumeration
): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.patch({
    ...CHANGE_TITLE_SERVICE_CONFIGURATION,
    params: { title },
  });

const fetchTitleMiscellaneousTypesService = async (): Promise<
  [ProfilePersonalNameTitleDropdownApiMiscellaneousReturnType | Record<string, any>, Response]
> => apiUtilsCb.get(FETCH_MISCELLANEOUS_SERVICE_CONFIGURATION);

export { changeUserTitleService, fetchTitleMiscellaneousTypesService };
