import { isCC } from 'utils/app.utils';

const MODIFY_PHONE_STEP1_REPEAT_PHONE_PROPS = {
  isRequired: !isCC(),
  prefixPlaceholder: '+XX',
  testing: {
    placeholderId: 'personal-repeat-number-step1-message-input-placeholder',
    optionsPlaceholderId: 'personal-repeat-number-step1-message-input-dropdown-placeholder',
    labelId: 'personal-repeat-number-step1-message-input-label',
  },
  type: 'tel',
};

export { MODIFY_PHONE_STEP1_REPEAT_PHONE_PROPS };
