// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export default {
  ERROR_PROVIDER_PROPS: {
    smallSize: false,
    testing: {
      assetId: 'provider-error-generic-asset',
      descriptionId: 'provider-error-generic-table-row-description',
      titleId: 'provider-error-generic-title-section',
    },
  },
  ERROR_ASSET_CONFIGURATION_PROVIDER_PROPS: {
    sizes: {
      height: 112,
      width: 112,
    },
    testId: 'provider-error-generic-asset',
    type: assetConstants.TYPES.ILLUSTRATION,
  },
};
