// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportServiceCancelledContextConsumerHook } from 'containers/transactions/views/report/components/service-cancelled/contexts/transactions-report-service-cancelled.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// types
import { TransactionsReportServiceCancelledConfirmationComponentHookReturnType } from './types/transactions-report-service-cancelled-confirmation.component-hook-return.type';

const useTransactionsReportServiceCancelledConfirmationComponentHook =
  (): TransactionsReportServiceCancelledConfirmationComponentHookReturnType => {
    const { formatMessage } = useCbIntl();
    const { cancellationDate, files } = useTransactionsReportServiceCancelledContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    return {
      cancellationDate,
      cardId,
      files,
      formatMessage,
      transactionId,
    };
  };

export { useTransactionsReportServiceCancelledConfirmationComponentHook };
