// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CardSettingsBlockCardSecondStepSummaryComponent from './components/summary/card-settings-block-card-second-step-summary.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import CardSettingsBlockCardSecondStepActionsComponent from './components/actions/card-settings-block-card-second-step-actions.component';
// Configurations
import { getChallengeConfiguration } from './configurations/card-settings-block-card-second-step.component.configurations';
// Hooks
import CardSettingsBlockCardSecondStepHook from './hooks/card-settings-block-card-second-step.hook';
// Styles
import {
  CardSettingsBlockCardSecondStepComponentStyled,
  CardSettingsBlockCardSecondStepIntroductionStyled,
} from './card-settings-block-card-second-step.component.styled';
// Translations
import { DESCRIPTION } from './translations/card-settings-block-card-second-step.translations';

const CardSettingsBlockCardSecondStepComponent = (): React.ReactElement => {
  const {
    address,
    cardId,
    cardNumber,
    challenge,
    challengeError,
    handleChallengeError,
    handleChallengeSuccess,
    handleToggleChallengeError,
    params,
  } = CardSettingsBlockCardSecondStepHook();

  return (
    <CardSettingsBlockCardSecondStepComponentStyled>
      <CardSettingsBlockCardSecondStepIntroductionStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
      </CardSettingsBlockCardSecondStepIntroductionStyled>
      <CardSettingsBlockCardSecondStepSummaryComponent address={address} cardNumber={cardNumber} />
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          address,
          params,
          cardId,
          challengeError,
          handleChallengeError,
          handleChallengeSuccess,
          handleToggleChallengeError,
        })}
      />
      <CardSettingsBlockCardSecondStepActionsComponent
        {...{ handleToggleChallengeError, challenge, challengeError }}
      />
    </CardSettingsBlockCardSecondStepComponentStyled>
  );
};

export default CardSettingsBlockCardSecondStepComponent;
