// Services
import { verifySignatureKeyStatusService, requestSignatureKeyService } from '../services/request-signature-key.services';
// Types
import { RequestSignatureKeyApiBuilderType } from '../types/request-signature-key-api-builder.type';
import { RequestSignatureKeyApiReturnComponentType } from '../types/request-signature-key-api-return.component.type';
// Utils
import { isAlreadyRequested } from './utils/request-signature-key.resolver.utils';
import { requestWasCreated } from '@openbank/cf-ui-framework';

const requestSignatureKeyResolver = async (
  props: RequestSignatureKeyApiBuilderType
): Promise<[true] | [null, true]> => {
  const [, { status }] = await requestSignatureKeyService(props);

  return requestWasCreated(status) ? [true] : [null, true];
};

const verifySignatureKeyStatusResolver = async (): Promise<[RequestSignatureKeyApiReturnComponentType | null, boolean | null]> => {
  const [response] = await verifySignatureKeyStatusService();

  return isAlreadyRequested(response) ? [response, null] : [response, true];
};

export { verifySignatureKeyStatusResolver, requestSignatureKeyResolver };
