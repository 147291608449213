// Services
import { getMandateInfoService } from '../services/change-iban.services';
// Types
import { SepaMandateType } from 'types/sepa-mandate.type';
// Utils
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const getMandateInfoResolver = async (): Promise<[SepaMandateType] | [null, true]> => {
  const [response, { status }] = await getMandateInfoService();

  return wasSuccessRequest(status) ? [response] : [null, true];
};

export { getMandateInfoResolver };
