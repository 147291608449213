export const CHANGE_IBAN_STEP1_MESSAGE = {
  testId: 'change-iban-step1-message-01',
};

export const CHANGE_IBAN_INPUT_PROPS = {
  testing: {
    inputId: 'change-iban-step1-input',
    helperId: 'change-iban-step1-input-helper',
    labelId: 'change-iban-step1-input-label',
  },
};

export const CHANGE_IBAN_CHECKBOX_PROPS = {
  testing: {
    helperTestId: 'change-iban-checkbox-helper',
    elementTestId: 'change-iban-checkbox',
  },
};
