// Translations
import {
  ERROR_TITLE,
  ERROR_DESCRIPTION,
} from '../translations/orchestrator-router-container-error.translations';

const ORCHESTRATOR_ROUTER_CONTAINERS_ERROR_LOAD_PROPS = {
    assetId: 'oops-alternative',
    translations: { ERROR_TITLE, ERROR_DESCRIPTION },
  },
  ORCHESTRATOR_ROUTER_CONTAINERS_LOAD_BUTTON_PROPS = {
    testId: 'orchestrator-router-container-error-button',
  };

export {
  ORCHESTRATOR_ROUTER_CONTAINERS_LOAD_BUTTON_PROPS,
  ORCHESTRATOR_ROUTER_CONTAINERS_ERROR_LOAD_PROPS,
};
