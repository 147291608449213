// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { CANCEL_EPP_SERVICE_CONFIGURATION } from './constants/financing-early-repayment.services.constants';
// Types
import { CancelEppServiceType } from './types/cancel-epp-service.type';
import { ChallengeType } from 'types/challenge.type';

const cancelEppService = async ({
  cardId,
  eppId,
  progressId,
}: CancelEppServiceType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    ...CANCEL_EPP_SERVICE_CONFIGURATION,
    customHeaders: progressId ? { progressId } : {},
    pathParams: { cardId, eppId },
  });

export { cancelEppService };
