// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  DASHBOARD_TOP_MESSAGE_PROPS: {
    type: messageConstants.TYPES.WARNING,
    testId: 'dashboard-message-component',
  },
  DASHBOARD_TOP_MESSAGE_TITLE_PROPS: {
    bold: true,
    testId: 'dashboard-message-title-section',
  },
  DASHBOARD_TOP_MESSAGE_PARAGRAPH_PROPS: {
    testId: 'dashboard-message-description',
  },
  DASHBOARD_TOP_MESSAGE_CARD_BLOCK_PROPS: {
    testId: 'dashboard-message-card-block',
  },
  DASHBOARD_TOP_MESSAGE_CARD_BLOCK_DESC_PROPS: {
    testId: 'dashboard-message-card-block-description',
  },
  DASHBOARD_THIRD_PARTY_MESSAGE_PROPS: {
    type: messageConstants.TYPES.ERROR,
    testId: 'dashboard-third-party-message-component',
  },
};
