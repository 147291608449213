// Types
import { AccessKeyChangeStep2ActionsUtilsType } from './types/security-keys-access-key-change-second-step-actions.utils.type';
// Utilities
import {
  newAccessKeyAndRepeatedDontMatch,
  notInputError,
  checkHasFourNumbersOnly,
} from 'containers/security-keys/views/access-key-change/utils/security-keys-access-key-change-constraints.util';

const modifyAccessKeyChangeStep2NextButtonIsDisabled = ({
  newAccessKey,
  repeatNewAccessKey,
  inputError,
}: AccessKeyChangeStep2ActionsUtilsType): boolean =>
  Boolean(
    !checkHasFourNumbersOnly(newAccessKey) ||
      !checkHasFourNumbersOnly(repeatNewAccessKey) ||
      newAccessKeyAndRepeatedDontMatch({ newAccessKey, repeatNewAccessKey }) ||
      notInputError(inputError)
  );

export { modifyAccessKeyChangeStep2NextButtonIsDisabled };
