// Constants
import { NUMBER } from 'constants/number.constants';

const QUICK_ACTIONS_MODAL_TEST_ID = 'quick-actions-modal',
  QUICK_ACTIONS_MODAL_DEFAULT_COLUMNS = 4,
  MODAL_COMPONENT_PROPS = {
    isFlexibleHeight: true,
    index: NUMBER.TWO,
  };

export { QUICK_ACTIONS_MODAL_TEST_ID, QUICK_ACTIONS_MODAL_DEFAULT_COLUMNS, MODAL_COMPONENT_PROPS };
