// Vendors
import React from 'react';
// Components
import { AssetComponent, TitleComponent } from '@openbank/cb-ui-commons';
import ProfilePersonalNameResumeLayoutComponent from 'containers/personal/views/landing/components/name/components/resume-layout/profile-personal-name-resume-layout.component';
// Constants
import constants from './constants/profile-personal-name-alias-edit.constants';
// Hooks
import ProfilePersonalNameAliasEditHook from './hooks/profile-personal-name-alias-edit.hook';
// Styles
import { ProfilePersonalNameEditContentComponentStyled } from './profile-personal-name-alias-edit.component.styled';

const ProfilePersonalNameAliasEditComponent = (): React.ReactElement => {
  const { alias, handleChangeCurrentAliasAssetClick } = ProfilePersonalNameAliasEditHook();

  return (
    <ProfilePersonalNameResumeLayoutComponent {...constants.PROFILE_PERSONAL_NAME_ALIAS_EDIT_PROPS}>
      <ProfilePersonalNameEditContentComponentStyled>
        <TitleComponent {...constants.PROFILE_PERSONAL_NAME_ALIAS_EDIT_TITLE_PROPS}>
          {alias}
        </TitleComponent>
        <AssetComponent
          {...constants.PROFILE_PERSONAL_NAME_ALIAS_EDIT_ASSET_PROPS}
          onClick={handleChangeCurrentAliasAssetClick}
        />
      </ProfilePersonalNameEditContentComponentStyled>
    </ProfilePersonalNameResumeLayoutComponent>
  );
};

export default ProfilePersonalNameAliasEditComponent;
