// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/security-keys-signature-key.services.constants';
// Types
import { SecurityKeysSignatureKeyApiReturnComponentType } from '../types/security-keys-signature-key-api-return.component.type';

const getLastTimeSignatureKeyChangeService = async (): Promise<
  [SecurityKeysSignatureKeyApiReturnComponentType | Record<string, any>, Response]
> => apiUtilsCb.get(constants.LAST_CHANGE_SIGNATURE_KEY_SERVICE_CONFIGURATION);

export { getLastTimeSignatureKeyChangeService };
