// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import FinancingEarlyRepaymentSecondStepSummaryComponent from './components/summary/financing-early-repayment-second-step-summary.component';
import FinancingEarlyRepaymentSecondStepActionsComponent from './components/actions/financing-early-repayment-second-step-actions.component';
import FinancingEarlyRepaymentSecondStepChallengeComponent from './components/challenge/financing-early-repayment-second-step-challenge.component';
// Styles
import {
  FinancingEarlyRepaymentSecondStepComponentStyled,
  FinancingEarlyRepaymentSecondStepDescriptionStyled,
} from './financing-early-repayment-second-step.component.styled';
// Translations
import { DESCRIPTION } from './translations/financing-early-repayment-second-step.translations';

const FinancingEarlyRepaymentSecondStepComponent = (): React.ReactElement => (
  <FinancingEarlyRepaymentSecondStepComponentStyled>
    <FinancingEarlyRepaymentSecondStepDescriptionStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </FinancingEarlyRepaymentSecondStepDescriptionStyled>
    <FinancingEarlyRepaymentSecondStepSummaryComponent />
    <FinancingEarlyRepaymentSecondStepChallengeComponent />
    <FinancingEarlyRepaymentSecondStepActionsComponent />
  </FinancingEarlyRepaymentSecondStepComponentStyled>
);

export default FinancingEarlyRepaymentSecondStepComponent;
