// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const RENDER_TOOLTIP_CONTENT_CONFIGURATION = {
  title: 'title',
};

const TITLE_SECTION_MOBILE_ASSET_PROPS = {
  id: 'chevron_left',
  testId: 'title-section-mobile-asset',
};

const TITLE_SECTION_MOBILE_TOOLTIP_ASSET = {
  colors: { fill: 'backgroundLinkPrimaryA' },
  id: 'info',
  sizes: {
    height: 24,
    width: 24,
  },
};

const TITLE_SECTION_MOBILE_PROPS = {
  bold: true,
  size: paragraphConstants.SIZES.M,
  testId: 'title-section-mobile',
};

export {
  RENDER_TOOLTIP_CONTENT_CONFIGURATION,
  TITLE_SECTION_MOBILE_PROPS,
  TITLE_SECTION_MOBILE_ASSET_PROPS,
  TITLE_SECTION_MOBILE_TOOLTIP_ASSET,
};
