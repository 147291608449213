// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { PasswordModuleComponent } from '@openbank/cb-ui-commons';
// Constants
import { RECOVERY_KEY_STEP5_PASSWORD_MODULE_PROPS } from './constants/recovery-key-step5-constraints.constants';
// Hooks
import useRecoveryKeyStep5ConstraintsHook from './hooks/recovery-key-step5-constraints.hook';
// Translations
import {
  DESCRIPTION,
  ERROR,
  HIDE_PASSWORD,
  SHOW_PASSWORD,
  TITLE,
} from './translations/recovery-key-step5-constraints.translations';
// Utilities
import { getAccessKeyConstraints } from 'utils/access-key/access-key-constraints.utils';
import { showRecoveryKeyConstraintsError } from './utils/recovery-key-step5-constraints.utils';

const RecoveryKeyStep5ConstraintsComponent = (): React.ReactElement => {
  const {
    formatMessage,
    recoveryKey: value,
    setRecoveryKey: onChange,
  } = useRecoveryKeyStep5ConstraintsHook();

  return (
    <PasswordModuleComponent
      {...{ ...RECOVERY_KEY_STEP5_PASSWORD_MODULE_PROPS, onChange, value }}
      constraints={getAccessKeyConstraints(formatMessage)}
      description={<FormattedMessageComponent id={DESCRIPTION} />}
      errorMessage={
        showRecoveryKeyConstraintsError(value) && <FormattedMessageComponent id={ERROR} />
      }
      translations={{
        HIDE_PASSWORD: formatMessage({ id: HIDE_PASSWORD }),
        SHOW_PASSWORD: formatMessage({ id: SHOW_PASSWORD }),
      }}
      title={<FormattedMessageComponent id={TITLE} />}
    />
  );
};

export default RecoveryKeyStep5ConstraintsComponent;
