// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { SettingsCardControlLocationsSearchBuilderReturnHandlersType } from './types/settings-card-control-locations-search-builder-return.handlers.type';
import { SettingsCardControlLocationsSearchBuilderHandlersType } from './types/settings-card-control-locations-search-builder.handlers.type';
import { SettingsCardControlLocationsSearchMethodBuilderHandlersType } from './types/settings-card-control-locations-search-method-builder.handlers.type';

const updateContinents = (country: string) => (continents: CardControlContinentComponentType[]) =>
  continents;

const searchCountriesInputClickHandler = ({
  country,
  setContinents,
  setKeyword,
}: SettingsCardControlLocationsSearchMethodBuilderHandlersType): any => {
  setKeyword(country);
  setContinents(updateContinents(country));
};

const SettingsCardControlLocationsSearchHandlers = (
  props: SettingsCardControlLocationsSearchBuilderHandlersType
): SettingsCardControlLocationsSearchBuilderReturnHandlersType => ({
  handleSearchCountriesInputEvent: (country: string) =>
    searchCountriesInputClickHandler({ ...props, country }),
});

export default SettingsCardControlLocationsSearchHandlers;
