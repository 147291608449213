// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  PROPS: {
    testId: 'financing-fast-cash-first-step',
  },
  TITLE_PARAGRAPH_PROPS: {
    size: paragraphConstants.SIZES.S,
  },
  FIRST_BLOCK_TITLE_PARAGRAPH_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.L,
  },
};
