// Enums
import { DeviceModalEnum } from '../../enumerations/device-modal.enumeration';

export const PART_TYPE_PAYLOAD = {
  [DeviceModalEnum.UNROLL_TRUSTED]: {
    component: 'modal/doYouWantUnrollTrustedDevice',
    eventAction: 'doYouWantUnrollTrustedDevice'
  },
  [DeviceModalEnum.BLOCK]: {
    component: 'modal/doYouWantBlockThisDevice',
    eventAction: 'doYouWantBlockThisDevice',
  },
  [DeviceModalEnum.UNBLOCK]: {
    component: 'modal/doYouWantUnblockThisDevice',
    eventAction: 'doYouWantUnblockThisDevice'
  },
  [DeviceModalEnum.SIGN_OUT]: {
    component: 'modal/doYouWantSignOutThisDevice',
    eventAction: 'doYouWantSignOutThisDevice'
  }
};

export const ACCEPT_TYPE_PAYLOAD = {
  [DeviceModalEnum.UNROLL_TRUSTED]: {
    element: 'unrollMyTrustedDevice',
    eventLabel: 'modalNavigationUnrollMyTrustedDeviceButton'
  },
  [DeviceModalEnum.BLOCK]: {
    element: 'blockThisDevice',
    eventLabel: 'modalNavigationBlockThisDeviceButton'
    
  },
  [DeviceModalEnum.UNBLOCK]: {
    element: 'unblockThisDevice',
    eventLabel: 'modalNavigationUnblockThisDeviceButton'
  },
  [DeviceModalEnum.SIGN_OUT]: {
    element: 'signOutThisDevice',
    eventLabel: 'modalNavigationSignOutThisDeviceButton'
  }
};

export const OPEN_MODAL_PAYLOAD = {
  action: 'open',
  format: 'modal',
  element: 'full',
  eventLabel: 'open'
};

export const CLICK_CROSS_MODAL_PAYLOAD = {
  action: 'close',
  format: 'cross',
  element: 'close',
  eventLabel: 'close'
};

export const CLOSE_MODAL_PAYLOAD = {
  action: 'click',
  format: 'link',
  element: 'goBack',
  eventLabel: 'modalNavigationGoBackLink'
};

export const ACCEPT_MODAL_PAYLOAD = {
  action: 'click',
  format: 'button',
};

export const IS_CURRENT_CONECTION_PAYLOAD = {
  action: 'open',
  format: 'message',
  component: 'message/warning/yourCurrentConnectionIsThroughThisDevice',
  element: 'full',
  eventAction: 'warningMessageYourCurrentConnectionIsThroughThisDevice',
  eventLabel: 'view'
};

export const IS_TRUSTED_CONECTION_PAYLOAD = {
  action: 'open',
  format: 'message',
  component: 'message/warning/thisIsYourTrustedDevice',
  element: 'full',
  eventAction: 'warningMessageThisIsYourTrustedDevice',
  eventLabel: 'view'
};
