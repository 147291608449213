// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Resolvers
import { rejectM2Resolver, sendTaxIdResolver } from '../resolvers/final-feedback.resolvers';
// Translations
import { translationsFinalFeedback } from '../translations/final-feedback.translations';
// Types
import { CreditLimitIncreaseContextType } from '../contexts/types/credit-limit-increase.context.type';
import { FinalFeedbackHandlersReturnType } from './types/final-feedback-return.handler.type';
import { FinalFeedbackHandlersPropsType } from './types/final-feedback-props.handler.type';

const saveSuccessTaxIdHandler = async ({
  finalFeedback,
  taxId,
  wizardStatus,
  setFinalFeedback,
  setIsSaveDisabled,
  setIsSaveLoading,
  setIsTaxIdSent,
  setToastConfiguration,
  formatMessage,
}: FinalFeedbackHandlersPropsType): Promise<void> => {
  setIsSaveLoading(true);
  setIsSaveDisabled(true);

  const [result, error] = await sendTaxIdResolver({
    caseId: wizardStatus.caseId,
    taxId: taxId as number,
  });

  if (error) {
    setToastConfiguration({
      type: ToastTypesEnumeration.ERROR,
      description: formatMessage({ id: translationsFinalFeedback.TAX_ID_NOT_SAVED }),
    });
  } else if (result) {
    setToastConfiguration({
      type: ToastTypesEnumeration.SUCCESS,
      description: formatMessage({ id: translationsFinalFeedback.TAX_ID_SAVED }),
    });
    setIsTaxIdSent(true);
    setFinalFeedback({
      ...finalFeedback,
      taxIdRequired: false,
    });
  }

  setIsSaveLoading(false);
  setIsSaveDisabled(false);
};

const validateTaxIdHandler = ({ taxId, setIsSaveDisabled }) => {
  const taxIdString = taxId?.toString() ?? '';
  if (taxIdString.length >= 10 && taxIdString?.length <= 11) {
    setIsSaveDisabled(false);
  } else {
    setIsSaveDisabled(true);
  }
};

export const rejectM2Handler = async (
  wizardStatus: CreditLimitIncreaseContextType['wizardStatus']
): Promise<void> => {
  const { caseId, currentStepInText } = wizardStatus;

  if (currentStepInText === CreditLimitIncreaseStepsEnumeration.REJECT_M2) {
    await rejectM2Resolver(caseId);
  }
};

export const FinalFeedbackHandlers = ({
  finalFeedback,
  taxId,
  wizardStatus,
  setFinalFeedback,
  setIsSaveDisabled,
  setIsSaveLoading,
  setIsTaxIdSent,
  setToastConfiguration,
  formatMessage,
}: FinalFeedbackHandlersPropsType): FinalFeedbackHandlersReturnType => ({
  handleSaveSuccessTaxId: () =>
    saveSuccessTaxIdHandler({
      finalFeedback,
      taxId,
      wizardStatus,
      setFinalFeedback,
      setIsSaveDisabled,
      setIsSaveLoading,
      setIsTaxIdSent,
      setToastConfiguration,
      formatMessage,
    }),
  handleValidateTaxId: () => validateTaxIdHandler({ taxId, setIsSaveDisabled }),
});
