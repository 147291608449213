// Vendors
import React from 'react';
// Components
import {
  TitleComponent,
  RadioListComponent,
  LineSeparatorComponent,
} from '@openbank/cb-ui-commons';
// Constants
import {
  FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS,
  FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT,
} from './constants/filter-modal-list-selector.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FilterModalListSelectorComponentStyled } from './filter-modal-list-selector.component.styled';
// Types
import { FilterModalListSelectorComponentType } from './types/filter-modal-list-selector.component.type';
// Utilities
import {
  formatListOptions,
  getSelectedOptionValue,
} from './utils/filter-modal-list-selector.utils';
import { getValueOrDefault } from '../../utils/filter-modal.utils';

const FilterModalListSelectorComponent = ({
  field,
  formValues,
  handleFormValuesChange,
  listOptions,
  showLineSeparator = FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS.showLineSeparator,
  testId = FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS.testId,
  title,
}: FilterModalListSelectorComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  return (
    <FilterModalListSelectorComponentStyled>
      <TitleComponent {...FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT}>{title}</TitleComponent>
      <RadioListComponent
        gap={FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS.gap}
        options={formatListOptions({
          options: listOptions,
          messageKey: 'helperMessage',
          formatMessage,
        })}
        onChange={(id: string) =>
          handleFormValuesChange({
            field,
            value: {
              value: id,
              label: formatMessage({ id: getSelectedOptionValue({ id, listOptions }).label }),
            },
          })
        }
        value={getValueOrDefault(formValues?.[field]?.value)}
      />
      {showLineSeparator && <LineSeparatorComponent />}
    </FilterModalListSelectorComponentStyled>
  );
};

export { FilterModalListSelectorComponent };
