// Components
import { TransactionsReportWizardSuccessComponent } from '../components/wizard-success/transactions-report-wizard-success.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  SUCCESS_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK_LABEL,
  ERROR_TITLE,
  ERROR_DESCRIPTION,
} from '../translations/transactions-report.translations';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const WIZARD_FEEDBACK_SUCCESS = {
  content: TransactionsReportWizardSuccessComponent,
  description: SUCCESS_DESCRIPTION,
  exitRoute: RoutesEnumeration.TRANSACTIONS,
  linkLabel: SUCCESS_LINK_LABEL,
  title: SUCCESS_TITLE,
};

const WIZARD_FEEDBACK_ERROR = {
  description: ERROR_DESCRIPTION,
  title: ERROR_TITLE,
  exitRoute: RoutesEnumeration.TRANSACTIONS,
};

const WIZARD_CANCEL_MODAL_PROPS = {
  cancelModal: {
    cancelUrl: generateAbsoluteRoute(generatePathRoute([RoutesEnumeration.TRANSACTIONS])),
  },
};

export { WIZARD_CANCEL_MODAL_PROPS, WIZARD_FEEDBACK_SUCCESS, WIZARD_FEEDBACK_ERROR };
