// Types
import { RetryButtonClickHandlerType } from './types/retry-button-click-handler.type';
import { WizardHandlersReturnType } from './types/wizard-return.handlers.type';
import { WizardHandlersType } from './types/wizard.handlers.type';

const retryButtonClickHandler = ({ setCurrentStep, currentStep }: RetryButtonClickHandlerType) => {
  setCurrentStep(currentStep);
};

const WizardHandlers = (props: WizardHandlersType): WizardHandlersReturnType => ({
  handleRetryButtonClick: (currentStep: number) =>
    retryButtonClickHandler({ ...props, currentStep }),
});

export default WizardHandlers;
