// Enumerations
import { TravelPlusNextStatusEnumeration } from 'components/travel-plus/enumerations/travel-plus-next-status.enumeration';
// Types
import { TravelPlusLinksHookUtilsType } from './types/travel-plus-links.hook.utils.type';

const travelPlusContractIsExpired = (
  expirationDate: TravelPlusLinksHookUtilsType['expirationDate']
): boolean => new Date().getTime() >= new Date(String(expirationDate)).getTime();

const getCurrentTravelPlusEnumerateValue = ({
  activated,
  expirationDate = new Date().toString(),
}: TravelPlusLinksHookUtilsType): TravelPlusNextStatusEnumeration =>
  !activated && travelPlusContractIsExpired(expirationDate)
    ? TravelPlusNextStatusEnumeration.DISABLE
    : TravelPlusNextStatusEnumeration.ENABLE;

export { getCurrentTravelPlusEnumerateValue };
