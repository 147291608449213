// Vendors
import styled from 'styled-components';
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const PersonalPhotoAvatarsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

const PersonalPhotoAvatarsListComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;

  ${mobileMediaQuery} {
    display: flex;
    flex-wrap: unset;
    overflow-x: auto;
    max-width: calc(100vw - ${convertPxsToRems({ pixels: 32, base: 14 })});

    img {
      height: ${convertPxsToRems({ pixels: 80, base: 14 })};
      width: ${convertPxsToRems({ pixels: 80, base: 14 })};
    }
  }
`;

const PersonalPhotoAvatarsListItemComponentStyled = styled.div`
  align-items: center;
  display: flex;
  border-radius: 100%;
  cursor: pointer;
  height: ${convertPxsToRems({ pixels: 50, base: 14 })};
  justify-content: center;
  position: relative;
  width: ${convertPxsToRems({ pixels: 50, base: 14 })};

  ${mobileMediaQuery} {
    height: ${convertPxsToRems({ pixels: 80, base: 14 })};
    width: ${convertPxsToRems({ pixels: 80, base: 14 })};
  }
`;

export {
  PersonalPhotoAvatarsComponentStyled,
  PersonalPhotoAvatarsListComponentStyled,
  PersonalPhotoAvatarsListItemComponentStyled,
};
