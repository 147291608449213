// Vendors
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Constants
import { LANDING_ROUTE } from '../../constants/financing-insurances.constants';
// Types
import { InsuranceStatusBuilderType } from '../../types/insurance-status-builder.type';
// Utils
import { isCancelableInsurance } from 'containers/financing/views/ppi/utils/financing-ppi.utils';

export const FinancingInsurancesRouterCancelComponent = ({
  status,
}: InsuranceStatusBuilderType): React.ReactElement => {
  if (isCancelableInsurance(status)) {
    return <Outlet />;
  }

  return <Navigate to={LANDING_ROUTE} />;
};
