// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';
import { StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { fetchCustomerInformationResolver } from '../resolvers/customer.resolvers';
// Types
import { CustomerProviderHandlersType } from './types/customer.provider.handlers.type';
import { CustomerReturnProviderHandlersType } from './types/customer-return.provider.handlers.type';
import { CustomerDataType } from 'types/customer/customer-data.type';
import { UserDetailsType } from 'types/user-details.type';

const saveUserLocale = ({ language }: UserDetailsType): void => {
  language && localStorage.setItem(StorageLocalEnum.LOCALE, language);
};

const saveUserAlias = ({ alias }: UserDetailsType): void => {
  alias && localStorage.setItem(StorageLocalEnum.USER_ALIAS, alias);
};

const fetchAllCustomerNotificationsHandler = async ({
  handleGetAllCustomerNotifications,
  setFetching,
}: Pick<
  CustomerProviderHandlersType,
  'handleGetAllCustomerNotifications' | 'setFetching'
>): Promise<any> => {
  await handleGetAllCustomerNotifications();

  setFetching(false);
};

const fetchCustomerInfoHandler = async ({
  handleGetAllCustomerNotifications,
  me,
  setError,
  setFetching,
  setProfile,
  setMe,
}: CustomerProviderHandlersType): Promise<any> => {
  setFetching(!me);
  const [response, error] = await fetchCustomerInformationResolver();

  if (error) {
    setError(ErrorCodesEnumeration.DEFAULT);
    setFetching(false);
  } else {
    const { userDetails } = response as CustomerDataType;

    setProfile(response);
    saveUserLocale(userDetails);
    saveUserAlias(userDetails);
    setMe(false);
    !me
      ? fetchAllCustomerNotificationsHandler({ handleGetAllCustomerNotifications, setFetching })
      : setFetching(false);
  }
};

const CustomerHandlers = (
  props: CustomerProviderHandlersType
): CustomerReturnProviderHandlersType => ({
  handleFetchCustomerInfo: () => fetchCustomerInfoHandler(props),
});

export { CustomerHandlers };
