// Types
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { WizardFeedbackSuccessLinkClickHandlerType } from './types/wizard-feedback-success-link-click-handler.type';
import {
  RecoveryKeyFeedbackBuilderReturnHandlersType,
  RecoveryKeyFeedbackBuilderHandlersType,
} from './types/wizard-feedback-success.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const wizardFeedbackSuccessLinkClickHandler = ({
  exitRoute,
  handleWizardFeedbackSuccessLinkClickTracking,
  linkClick,
  onNavigateHybridFlow,
}: WizardFeedbackSuccessLinkClickHandlerType): void => {
  handleWizardFeedbackSuccessLinkClickTracking();
  linkClick ? linkClick() : onNavigateHybridFlow?.(generateAbsoluteRoute(exitRoute));
};
const WizardFeedbackSuccessHandlers = ({
  exitRoute,
  handleWizardFeedbackSuccessLinkClickTracking,
  linkClick,
  navigate,
  onNavigateHybridFlow,
}: RecoveryKeyFeedbackBuilderHandlersType): RecoveryKeyFeedbackBuilderReturnHandlersType => ({
  handleWizardFeedbackSuccessLinkClick: () =>
    wizardFeedbackSuccessLinkClickHandler({
      exitRoute,
      handleWizardFeedbackSuccessLinkClickTracking,
      linkClick,
      navigate,
      onNavigateHybridFlow,
    }),
});

export default WizardFeedbackSuccessHandlers;
