// Vendors
import React from 'react';

// Components
import { ParagraphComponent, SidebarComponent, LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/notifications-landing-sidebar.constants';
// Styles
import { NotificationsLandingSidebarComponentStyled } from './notifications-landing-sidebar.component.styled';
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import translations from './translations/notifications-landing-sidebar.translations';
// Hooks
import SideBarHook from './hooks/notifications-landing-sidebar.hook';

const NotificationsLandingSidebarComponent = (): React.ReactElement => {
  const { onFaqClick } = SideBarHook();

  return (
    <SidebarComponentStyled>
      <SidebarComponent
        {...constants.NOTIFICATIONS_LANDING_SIDEBAR_PROPS}
        title={<FormattedMessageComponent id={translations.TITLE} />}
      >
        <NotificationsLandingSidebarComponentStyled>
          <ParagraphComponent {...constants.NOTIFICATIONS_LANDING_SIDEBAR_TITLE_PROPS}>
            FAQs
          </ParagraphComponent>
          <ParagraphComponent {...constants.NOTIFICATIONS_LANDING_SIDEBAR_DESCRIPTION_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION} />
          </ParagraphComponent>
          <LinkComponent
            {...constants.NOTIFICATIONS_LANDING_SIDEBAR_LINK_PROPS}
            onClick={onFaqClick}
          >
            <FormattedMessageComponent id={translations.LINK} />
          </LinkComponent>
        </NotificationsLandingSidebarComponentStyled>
      </SidebarComponent>
    </SidebarComponentStyled>
  );
};

export default NotificationsLandingSidebarComponent;
