// Vendors
import { sessionUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import { INACTIVITY_REFRESH_SECONDS_UNTIL_DIE } from './constants/inactivity-refresh.handlers.constants';
// Enumerations
import { TimerEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { refreshTokenResolver } from 'resolvers/refresh-token.resolvers';
// Types
import {
  InactivityRefreshBuilderHandlersType,
  InactivityRefreshMethodsHandlersType,
} from './types/inactivity-refresh.handlers.type';
// Utils
import { clearSessionData } from 'utils/session/session.utils';
import {
  noExistsConditionsToRefreshSession,
  refreshTokenFlagExistsAndIsOnTimeToDie,
  refreshTokenFromApiClientIsDone,
  timeToShowRefreshModalButInsteadShouldBeRefreshed,
} from './utils/inactivity-refresh.handlers.utils';
import { getEnvironmentDomain } from 'utils/app.utils';
import { TOKEN_REFRESHED } from 'constants/storage/storage.constants';

let REFRESH_INTERVAL: NodeJS.Timeout;

const showModalAndSetCounter = ({ setSeconds, setShowModal }) => {
  setSeconds(INACTIVITY_REFRESH_SECONDS_UNTIL_DIE);
  window.clearInterval(REFRESH_INTERVAL);
  setShowModal(true);
};

const refreshTokenInsteadOfShowModal = async ({
  handleSendToMobileAccessToken,
  isHybrid,
  setSeconds,
  setShowModal,
}: InactivityRefreshBuilderHandlersType): Promise<void> => {
  const [response, error] = await refreshTokenResolver();

  if (error) {
    showModalAndSetCounter({ setSeconds, setShowModal });
  } else {
    sessionUtils.saveSessionData({ ...response, domain: getEnvironmentDomain() });
    isHybrid && handleSendToMobileAccessToken();
  }
};

const checkRefreshTokenAliveTimestamp = ({
  setShowModal,
  setSeconds,
  ...restProps
}: InactivityRefreshBuilderHandlersType): void => {
  if (timeToShowRefreshModalButInsteadShouldBeRefreshed()) {
    refreshTokenInsteadOfShowModal({ setSeconds, setShowModal, ...restProps });
  } else if (refreshTokenFlagExistsAndIsOnTimeToDie()) {
    showModalAndSetCounter({ setSeconds, setShowModal });
  } else if (noExistsConditionsToRefreshSession()) {
    window.clearInterval(REFRESH_INTERVAL);
    clearSessionData();
  }
};

const sendToHybridNewAccessTokenHandler = ({
  isHybrid,
  handleSendToMobileAccessToken,
}: Pick<
  InactivityRefreshBuilderHandlersType,
  'isHybrid' | 'handleSendToMobileAccessToken'
>): void => {
  if (refreshTokenFromApiClientIsDone()) {
    isHybrid && handleSendToMobileAccessToken();
    localStorage.removeItem(TOKEN_REFRESHED);
  }
};

const observeRefreshValidityEventHandler = ({
  isHybrid,
  handleSendToMobileAccessToken,
  ...props
}: InactivityRefreshBuilderHandlersType): void => {
  REFRESH_INTERVAL = setInterval(() => {
    checkRefreshTokenAliveTimestamp({ ...props, isHybrid, handleSendToMobileAccessToken });
    sendToHybridNewAccessTokenHandler({ isHybrid, handleSendToMobileAccessToken });
  }, TimerEnum.TIMEOUT_ONE_SECOND);
};

const InactivityRefreshHandlers = (
  props: InactivityRefreshBuilderHandlersType
): InactivityRefreshMethodsHandlersType => ({
  handleObserveRefreshValidityEvent: () => observeRefreshValidityEventHandler(props),
});

export { InactivityRefreshHandlers };
