// Vendors
import React from 'react';
// Components
import { DownloadSecurityCheckModalComponent } from '../security-check-download-modal/security-check-modal.component';
// Styles
import { SecurityCheckComponentStyled } from './security-check.component.styled';
// Types
import { TransactionsDownloadSecurityCheckComponentType } from './types/security-check.component.type';

const TransactionsDownloadSecurityCheckComponent = ({
  challenge,
  challenged,
  challengeError,
  fetchingDownload,
  handleDownloadTransactions,
  otpId,
  otpValue,
  setChallenge,
  setChallenged,
  setChallengeError,
  setError,
  setDownloadDocumentType,
  setIsDownloadAvailable,
  setOtpId,
  setOtpValue,
  setShowDownloadSecurityCheckModal,
  showDownloadSecurityCheckModal,
}: TransactionsDownloadSecurityCheckComponentType): React.ReactElement | null =>
  showDownloadSecurityCheckModal ? (
    <SecurityCheckComponentStyled>
      <DownloadSecurityCheckModalComponent
        {...{
          challenge,
          challenged,
          challengeError,
          fetchingDownload,
          handleDownloadTransactions,
          otpId,
          otpValue,
          setChallenge,
          setChallenged,
          setChallengeError,
          setError,
          setDownloadDocumentType,
          setIsDownloadAvailable,
          setOtpId,
          setOtpValue,
          setShowDownloadSecurityCheckModal,
          showDownloadSecurityCheckModal,
        }}
      />
    </SecurityCheckComponentStyled>
  ) : null;

export { TransactionsDownloadSecurityCheckComponent };
