// Vendors
import styled from 'styled-components';

export const CardSettingsBlockCardFirstStepComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const CardSettingsBlockCardFirstStepIntroductionStyled = styled.div.attrs(
  ({ testId }: any) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;
