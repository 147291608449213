// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { DrPicassoManagerContextType } from './types/dr-picasso-manager.context.type';
import { DrPicassoClientDataType } from 'containers/dr-picasso/components/step1/types/dr-picasso-client-data.type';

const DrPicassoManagerContext = createContext<DrPicassoManagerContextType | Record<string, any>>(
  {}
);

const DrPicassoManagerContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<DrPicassoManagerContextType>> => {
  const [clientData, setClientData] = useState<DrPicassoClientDataType>({});
  const [isDrPicasso, setIsDrPicasso] = useState<boolean>(false);

  return (
    <DrPicassoManagerContext.Provider
      value={{ clientData, isDrPicasso, setClientData, setIsDrPicasso }}
    >
      {children as React.ReactElement}
    </DrPicassoManagerContext.Provider>
  );
};

const useDrPicassoManagerContextConsumerHook = (): DrPicassoManagerContextType =>
  useContext(DrPicassoManagerContext) as DrPicassoManagerContextType;

export { DrPicassoManagerContextProvider, useDrPicassoManagerContextConsumerHook };
