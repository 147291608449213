// Enumerations
import { RepeatEmailErrorIdEnumeration } from '../enumerations/new-email-error-id.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyEmail.step1.cbInput.';

export default {
  ERRORS: {
    [RepeatEmailErrorIdEnumeration.FORMAT]: `${DOMAIN}repeatEmailAddressFormatError`,
    [RepeatEmailErrorIdEnumeration.MATCH]: `${DOMAIN}repeatEmailAddressMatchError`,
    [RepeatEmailErrorIdEnumeration.EMPTY]: `${DOMAIN}repeatEmailAddressEmptyError`,
  },
  INPUT_LABEL: `${DOMAIN}repeatNewEmailAddressLabel`,
};
