// Vendors
import React from 'react';
// Components
import SettingsCardControlLocationsToggleComponent from 'containers/card-settings/views/card-control/components/locations/components/toggle/settings-card-control-locations-toggle.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Translations
import { COUNTRIES_PREFFIX } from 'translations/locations.translations';
// Types
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';
import { SettingsCardControlLocationsContinentsCountriesReturnComponentType } from './types/settings-card-control-locations-continents-countries-return.component.type';
// Utils
import { formatLocationTranslationId } from 'containers/card-settings/views/card-control/components/locations/utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsContinentsCountriesComponent = (
  countries: CardControlContinentCountryComponentType[]
): SettingsCardControlLocationsContinentsCountriesReturnComponentType[] | any =>
  countries.map(({ id: countryId, name2Dig }: CardControlContinentCountryComponentType) => ({
    label: (
      <FormattedMessageComponent
        id={formatLocationTranslationId({
          preffix: COUNTRIES_PREFFIX,
          location: name2Dig,
        })}
      />
    ),
    rightComponent: () => <SettingsCardControlLocationsToggleComponent {...{ countryId }} />,
  }));

export default SettingsCardControlLocationsContinentsCountriesComponent;
