// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitDecreaseCCContextConsumerHook } from 'containers/financing/views/credit-limit-decrease-cc/contexts/credit-limit-decrease-cc.context';
// Handlers
import { CreditLimitDecreaseCCHandlers } from 'containers/financing/views/credit-limit-decrease-cc/handlers/credit-limit-decrease-cc.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardComponentType } from 'types/card.component.type';
import { CardCreditComponentType } from 'types/card-credit.component.type';
import { ChallengeType } from 'types/challenge.type';
import { Step2CreditLimitDecreaseCCHookType } from './types/step-2-credit-limit-decrease-cc.hook.type';

const Step2CreditLimitDecreaseCCHook = (): Step2CreditLimitDecreaseCCHookType => {
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [challenge, setChallenge] = useState<ChallengeType>();
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const { formatMessage } = useCbIntl();
  const { amount, setDefaultResult } = CreditLimitDecreaseCCContextConsumerHook();
  const { currentCard, profile, setCurrentCard } = AppContextConsumerHook();
  const { currencyCode, cardContractId } = (currentCard || {}) as CardComponentType;
  const { limit } = (currentCard?.creditDetails || {}) as CardCreditComponentType;
  const { progressId = '' } = challenge || {};
  const newCreditLimit = amount as number;

  return {
    cardContractId,
    challenge,
    challengeError,
    currentCreditLimit: limit,
    currency: currencyCode,
    newCreditLimit,
    isConfirmLoading,
    userName: profile?.userDetails?.userName || '',
    formatMessage,
    setChallenge,
    setChallengeError,
    ...CreditLimitDecreaseCCHandlers({
      currentCard,
      progressId,
      newCreditLimit,
      setCurrentCard,
      setDefaultResult,
      setIsConfirmLoading,
    }),
  };
};

export { Step2CreditLimitDecreaseCCHook };
