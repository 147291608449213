// Vendors
import { RefObject, useEffect, useState } from 'react';
// Types
import {
  MoreNotificationsHookType,
  MoreNotificationsHookReturnType,
} from './types/more-notifications.hook.type';

const MoreNotificationsHook = ({
  onNext,
}: MoreNotificationsHookType): MoreNotificationsHookReturnType => {
  const [ComponentRef] = useState<RefObject<HTMLInputElement>>({ current: null });

  useEffect(() => {
    const cmp = ComponentRef.current;
    const observer: IntersectionObserver = new IntersectionObserver(
      entries => {
        entries.forEach((entry: IntersectionObserverEntry) => {
          entry.isIntersecting && onNext();
        });
      },
      { threshold: 1, rootMargin: '0px', root: null }
    );

    cmp && observer.observe(cmp);

    return () => {
      cmp && observer.unobserve(cmp);
    };
  }, [ComponentRef, onNext]);

  return {
    ComponentRef,
  };
};

export default MoreNotificationsHook;
