// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  ACCESS_PORTABILITY_NEW_REQUEST_BUTTON_CONFIGURATION: {
    testId: 'access-portability-all-new-request-button',
    rwdFull: false,
  },
  ACCESS_PORTABILITY_NEW_REQUEST_PARAGRAPH_01_PROPS: {
    testId: 'access-portability-all-new-request-subtitle-01',
    bold: true,
    size: paragraphConstants.SIZES.L,
  },
};
