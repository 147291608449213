const IVR_COMPONENT_CONSTANTS_CC = {
  containerId: 'ivr-cc-container',
  titleId: 'ivr-cc-title-section',
  constraintsId: 'ivr-cc-constraints-text',
  firstConstraintsId: 'ivr-cc-constraints-text-first',
  secondConstraintsId: 'ivr-cc-constraints-text-second',
  thirdConstraintsId: 'ivr-cc-constraints-text-third',
  fourthConstraintsId: 'ivr-cc-constraints-text-fourth',
  firstTextId: 'ivr-cc-first-text',
  secondTextId: 'ivr-cc-second-text',
  thirdTextId: 'ivr-cc-third-text',
  fourthTextId: 'ivr-cc-fourth-text',
  rightButtonTestId: 'ivr-cc-footer-button',
};

export { IVR_COMPONENT_CONSTANTS_CC };
