// Vendors
import styled from 'styled-components';

const CardSettingsResumeLinkComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
CardSettingsResumeLinkComponentStyled.displayName = 'CardSettingsResumeLinkComponentStyled';

const CardSettingsResumeLinkContentComponentStyled = styled(CardSettingsResumeLinkComponentStyled)`
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
CardSettingsResumeLinkContentComponentStyled.displayName =
  'CardSettingsResumeLinkContentComponentStyled';

const CardSettingsResumeLinkTopComponentStyled = styled(
  CardSettingsResumeLinkContentComponentStyled
)`
  align-items: center;
  flex-direction: row;
`;
CardSettingsResumeLinkTopComponentStyled.displayName = 'CardSettingsResumeLinkTopComponentStyled';

export {
  CardSettingsResumeLinkComponentStyled,
  CardSettingsResumeLinkContentComponentStyled,
  CardSettingsResumeLinkTopComponentStyled,
};
