// Vendors
import styled from 'styled-components';

const SupportCancelContractSecondStepChallengeComponentStyled = styled.section`
  width: 100%;
`;

SupportCancelContractSecondStepChallengeComponentStyled.displayName =
  'SupportCancelContractSecondStepChallengeComponentStyled';

export { SupportCancelContractSecondStepChallengeComponentStyled };
