// Vendors
import styled from 'styled-components';

const TableRowDescriptionComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
`;

export { TableRowDescriptionComponentStyled };
