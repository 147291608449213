// Vendors
import React from 'react';
// Components
import { SelectorCardComponent } from '@openbank/cb-ui-commons';
import { FormattedAddressComponent } from 'components/formatted-address/formatted-address.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { SELECTOR_CARD_PROPS } from './constants/personal-address-step1-address-box.constants';
// Styles
import { PersonalAddressStep1AddressBoxComponentStyled } from './personal-address-step1-address-box.component.styled';
// Translations
import {
  CARD_LINK,
  CARD_TITLE,
} from './translations/personal-address-step1-address-box.translations';
// Types
import { PersonalAddressStep1AddressBoxComponentType } from './types/personal-address-step1-address-box.component.type';

export const PersonalAddressStep1AddressBoxComponent = ({
  newAddress,
  handleOpenModal,
}: PersonalAddressStep1AddressBoxComponentType): React.ReactElement => (
  <PersonalAddressStep1AddressBoxComponentStyled>
    <SelectorCardComponent
      {...SELECTOR_CARD_PROPS}
      selectorCardList={[
        {
          title: <FormattedMessageComponent id={CARD_TITLE} />,
          children: <FormattedAddressComponent address={newAddress} />,
          linkConfiguration: {
            children: <FormattedMessageComponent id={CARD_LINK} />,
            onClick: handleOpenModal,
          },
        },
      ]}
    />
  </PersonalAddressStep1AddressBoxComponentStyled>
);
