// Vendors
import React from 'react';
// Components
import { DocumentationCategoryListComponent } from './components/category-list/documentation-category-list.component';
import { DocumentationDocumentsContentComponent } from './components/documents-content/documentation-documents-content';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import {
  DOCUMENTATION_CATEGORY_ERROR_PROPS,
  DOCUMENTATION_TITLE_PROPS,
} from './constants/documentation.constants';
// Contexts
import { DocumentationContextConsumerHOC } from './contexts/documentation.context';
// Hooks
import { useDocumentationHook } from './hooks/documentation.hook';
// Styles
import {
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { LayoutGridContentRightComponentContainerStyled } from './documentation.container.styled';
// Translations
import { ERROR } from './translations/documentation.translations';
import { DocumentErrorTypeEnumeration } from './enumerations/documentation.enumeration';
// Utils
import { hideCategorieSection } from './utils/documentation.container.utils';

const DocumentationBaseContainer = (): React.ReactElement => {
  const {
    categories,
    categoryError,
    formatMessage,
    formValues,
    formValuesParams,
    setCopyOfFormValues,
    setFormValuesParams,
    setFormValues,
    handleClickFilter,
    handleCategoryClickEvent,
    handleFetchCategoriesEvent,
    handleFetchDocumentsByCategoryEvent,
    loading,
    loadingTableContent,
  } = useDocumentationHook();

  return (
    <LayoutGridComponentStyled>
      <TitleSectionComponent {...DOCUMENTATION_TITLE_PROPS} />
      <FetchErrorComponent
        fetching={loading}
        error={getFetchErrorConfiguration({
          ...DOCUMENTATION_CATEGORY_ERROR_PROPS,
          error: categoryError === DocumentErrorTypeEnumeration.ERROR,
          formatMessage,
          buttonConfiguration: {
            children: <FormattedMessageComponent id={ERROR.BUTTON} />,
            onClick: handleFetchCategoriesEvent,
          },
        })}
      >
        <LayoutGridContentComponentStyled>
          <LayoutGridContentRightComponentContainerStyled
            $hidden={hideCategorieSection({ categories, categoryError })}
          >
            <LayoutGridContentRightComponentStyled>
              <DocumentationCategoryListComponent
                categories={categories}
                onClick={handleCategoryClickEvent}
              />
            </LayoutGridContentRightComponentStyled>
          </LayoutGridContentRightComponentContainerStyled>
          <LayoutGridContentLeftComponentStyled>
            <DocumentationDocumentsContentComponent
              categoryError={categoryError}
              loading={loadingTableContent}
              onRetry={handleFetchDocumentsByCategoryEvent}
              {...{
                formValues,
                formValuesParams,
                setCopyOfFormValues,
                setFormValuesParams,
                setFormValues,
                handleClickFilter,
              }}
            />
          </LayoutGridContentLeftComponentStyled>
        </LayoutGridContentComponentStyled>
      </FetchErrorComponent>
    </LayoutGridComponentStyled>
  );
};

const DocumentationContainer = DocumentationContextConsumerHOC(DocumentationBaseContainer);

export { DocumentationContainer };
