const DOMAIN_FAST_CASH_SECOND_STEP_VERIFY_DATA =
  'cobranded.services.userSettings.privateArea.financing.fastCash.firstStep.verifyCorrectData.';

const TITLE = `${DOMAIN_FAST_CASH_SECOND_STEP_VERIFY_DATA}title`;
const DATA = {
  DESTINATION_ACCOUNT_TITLE: `${DOMAIN_FAST_CASH_SECOND_STEP_VERIFY_DATA}data.destinationAccount.title`,
  DESTINATION_ACCOUNT_DESCRIPTION: `${DOMAIN_FAST_CASH_SECOND_STEP_VERIFY_DATA}data.destinationAccount.description`,
  AMOUNT_TO_TRANSFER: `${DOMAIN_FAST_CASH_SECOND_STEP_VERIFY_DATA}data.amountToTransfer`,
  APPLIED_COMMISSION: `${DOMAIN_FAST_CASH_SECOND_STEP_VERIFY_DATA}data.appliedCommission`,
};

export { DATA, TITLE };
