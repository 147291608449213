// Services
import {
  accountAggregationGoNextService,
  accountAggregationService,
  accountAggregationExtendTokenService,
  sendTinkErrorService,
} from '../services/step-4-account-aggregation.services';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Types
import { CaseInitResponseType } from '../services/types/step-0-init.type';
import { AccountAggregationPropsType } from './types/step-4-account-aggregation-props.resolver.type';
import { SendTinkErrorParamsType } from './types/send-tink-error-params.type';

export const accountAggregationResolver = async ({
  caseId,
  tinkData,
}: AccountAggregationPropsType): Promise<[CaseInitResponseType | null, boolean, number]> => {
  const [response, { status }] = await accountAggregationService({ caseId, tinkData });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false, status] : [null, true, status];
};

export const accountAggregationGoNextResolver = async (
  caseId: string
): Promise<[CaseInitResponseType, boolean]> => {
  const [response, { status }] = await accountAggregationGoNextService(caseId);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export const accountAggregationExtendTokenResolver = async (): Promise<[null, boolean]> => {
  const [response, { status }] = await accountAggregationExtendTokenService();
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export const sendTinkErrorResolver = async (
  params: SendTinkErrorParamsType
): Promise<[null, boolean]> => {
  const [response, { status }] = await sendTinkErrorService(params);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};
