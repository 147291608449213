// Enumerations
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.requestTable.';

export default {
  [AccessPortabilityTypeEnumeration.ACCESS]: {
    TABLE_BUTTON: `${DOMAIN}newRequestAccessButton`,
    TABLE_TITLE: `${DOMAIN}newRequestAccessTitle`,
  },
  [AccessPortabilityTypeEnumeration.PORTABILITY]: {
    TABLE_BUTTON: `${DOMAIN}newRequestPortabilityButton`,
    TABLE_TITLE: `${DOMAIN}newRequestPortabilityTitle`,
  },
};
