// Constants
import appConstants from 'constants/app.constants';
// Types
import { NotificationsLandingSideBarHookHandlersPropsType } from './types/notifications-landing-sidebar-hook-handlers-props.type';
import { NotificationsLandingSideBarHookHandlersReturnType } from './types/notifications-landing-sidebar-hook-handlers-return.type';

const onFaqClickHandler = ({ handleClickFaqsTracking }) => {
  handleClickFaqsTracking();
  window.open(appConstants.AMAZON_PUBLIC_SITE_FAQS);
};

const SidebarHookHandlers = (
  props: NotificationsLandingSideBarHookHandlersPropsType
): NotificationsLandingSideBarHookHandlersReturnType => ({
  onFaqClick: () => onFaqClickHandler(props),
});

export default SidebarHookHandlers;
