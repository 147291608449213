// Vendors
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
import TransactionsTableListElementsComponent from '../elements/transactions-table-list-elements.component';
// Constants
import { TRANSACTIONS_TABLE_LIST_GROUPS_DIVIDER_PROPS } from './constants/transactions-table-list-groups.constants';
// Hooks
import TransactionsTableListGroupsHook from './hooks/transactions-table-list-groups.hook';
// Styles
import {
  TransactionsTableListGroupsComponentStyled,
  TransactionsTableListGroupsItemComponentStyled,
} from './transactions-table-list-groups.component.styled';
// Translations
import translations from './translations/transactions-table-list-groups.translations';
// Types
import { TransactionsLandingTableListGroupComponentType } from '../../types/transactions-landing-table-list-group.component.type';
import { TransactionsLandingTableListComponentType } from '../../types/transactions-landing-table-list.component.type';

const TransactionsTableListGroupsComponent = ({
  elements = [],
  groupId,
  rowComponent,
  isFinancing,
}: any): React.ReactElement<TransactionsLandingTableListComponentType> | null => {
  const { groups } = TransactionsTableListGroupsHook({
    elements,
    groupId,
    isFinancing,
  });

  return (
    <TransactionsTableListGroupsComponentStyled>
      {groups.map(
        ({ data: elements, id }: TransactionsLandingTableListGroupComponentType, key: number) => (
          <SectionDividerComponent
            {...TRANSACTIONS_TABLE_LIST_GROUPS_DIVIDER_PROPS}
            key={key}
            title={<FormattedMessageComponent id={translations[id]} />}
          >
            <TransactionsTableListGroupsItemComponentStyled>
              <TransactionsTableListElementsComponent
                {...{ rowComponent, elements, isFinancing: isFinancing }}
              />
            </TransactionsTableListGroupsItemComponentStyled>
          </SectionDividerComponent>
        )
      )}
    </TransactionsTableListGroupsComponentStyled>
  );
};

export default TransactionsTableListGroupsComponent;
