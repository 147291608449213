const DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.requestPortability.step1.';

export default {
  DESCRIPTION_01: `${DOMAIN}description01`,
  DESCRIPTION_02: `${DOMAIN}description02`,
  DESCRIPTION_03: `${DOMAIN}description03`,
  PERSONAL_DATA_TITLE: `${DOMAIN}personalDataTitle`,
  CONTRACTED_PRODUCTS_TITLE: `${DOMAIN}contractedProductsTitle`,
  MESSAGE_1: `${DOMAIN}message1`,
  MESSAGE_2: `${DOMAIN}message2`,
  MESSAGE_3: `${DOMAIN}message3`,
  MESSAGE_4: `${DOMAIN}message4`,
  MESSAGE_5: `${DOMAIN}message5`,
};
