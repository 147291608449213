export default {
  ACTIVATE: 'activateCard',
  CARD_SETTINGS: 'cardSettings',
  DASHBOARD: 'dashboard',
  FINANCING: 'financing',
  POINTS: 'points',
  REPORT_A_PROBLEM: 'reportAProblem',
  SUPPORT: 'support',
  TRANSACTIONS: 'transactions',
};
