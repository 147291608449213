// Vendors
import styled from 'styled-components';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const FinancingInsurancesCancellationLPIComponentStyled = styled(LayoutColumnFlexComponentStyled)`
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
FinancingInsurancesCancellationLPIComponentStyled.displayName =
  'FinancingInsurancesCancellationLPIComponentStyled';

export { FinancingInsurancesCancellationLPIComponentStyled };
