// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  FINANCING_INSURANCES_CANCELLATION_LPI_SIDEBAR_PROPS: {
    testId: 'financing-insurances-cancellation-lpi',
  },
  FINANCING_INSURANCES_CANCELLATION_LPI_SIDEBAR_CONTENT_PROPS: {
    size: paragraphConstants.SIZES.M,
  },
};
