// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from 'containers/card-settings/views/card-control/contexts/constants/settings-card-control.context.constants';
// Enumerations
import { CardControlTabsIdsEnumeration } from '../enumerations/card-control-tabs-ids.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SettingsCardControlContextType } from './types/settings-card-control.context.type';

const SettingsSettingsCardControlContext = createContext<SettingsCardControlContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const SettingsCardControlContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<SettingsCardControlContextType>> => {
  const [tabSelectedId, setTabSelectedId] = useState<CardControlTabsIdsEnumeration>(
    CardControlTabsIdsEnumeration.OPERATIONS
  );

  return (
    <SettingsSettingsCardControlContext.Provider value={{ setTabSelectedId, tabSelectedId }}>
      {children as React.ReactNode}
    </SettingsSettingsCardControlContext.Provider>
  );
};

export const SettingsCardControlContextConsumerHook = (): SettingsCardControlContextType =>
  useContext(SettingsSettingsCardControlContext);

/* eslint-disable */
export const SettingsCardControlContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<SettingsCardControlContextType>> =>
    (
      <SettingsCardControlContextProvider>
        <Component {...props} />
      </SettingsCardControlContextProvider>
    );
