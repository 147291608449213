// Vendors
import styled from 'styled-components';
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 378, base: 14 })};
  margin-bottom: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

const TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentStyled = styled.div`
  width: 100%;
`;

export {
  TransactionsReportDifferentAmountAtmAdditionalInfoInputsCurrencyComponentStyled,
  TransactionsReportDifferentAmountAtmAdditionalInfoInputsComponentStyled,
};
