// Vendors
import styled from 'styled-components';

const ProfileConsentsSummaryBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

const ProfileConsentsSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export { ProfileConsentsSummaryBodyWrapper, ProfileConsentsSummaryWrapper };
