// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useDocumentationContextConsumerHook } from 'containers/documentation/contexts/documentation.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { type DocumentationDocumentsContentReturnHookType } from './types/documentation-documents-content-return.hook.type';
import { CharactersEnum } from '@openbank/cf-ui-static-data';

export const useDocumentationDocumentsContentHook =
  (): DocumentationDocumentsContentReturnHookType => {
    const { categorySelected, documents, documentsError } = useDocumentationContextConsumerHook();
    const { profile, currentCard } = AppContextConsumerHook(),
      { cmsAccountId: contractNumber = CharactersEnum.NO_VALUE, isBeneficiary } = currentCard || {},
      { userName = CharactersEnum.NO_VALUE } = profile?.userDetails || {};
    const { formatMessage } = useCbIntl();

    return {
      categorySelected,
      contractNumber,
      documents,
      documentsError,
      formatMessage,
      isBeneficiary,
      userName,
    };
  };
