// Contexts
import { DashboardContextConsumerHook } from 'containers/dashboard/contexts/dashboard.context';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Utils
import { mapDashboardSpentConfiguration } from './utils/dashboard-spent.hook.utils';
// Types
import { DashboardSpentHookType } from './types/dashboard-spent.hook.type';

const DashboardSpentHook = (): DashboardSpentHookType => {
  const { isMobile, isTablet, isMobileOrTablet } = DeviceTypeHook();
  const { information } = DashboardContextConsumerHook();
  const {
    currentPercentage,
    expenses,
    percentageDifference,
    previousPercentage,
    total,
  } = mapDashboardSpentConfiguration(information?.cardsInfo?.cardExpenses);

  return {
    currentPercentage,
    expenses,
    isMobile,
    isTablet,
    isMobileOrTablet,
    percentageDifference,
    previousPercentage,
    total,
  };
};

export default DashboardSpentHook;
