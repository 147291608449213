// Vendors
import styled from 'styled-components';
// Styles
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsCheck3dConfirmationButtonsComponentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;
CardSettingsCheck3dConfirmationButtonsComponentStyled.displayName =
  'CardSettingsCheck3dConfirmationButtonsComponentStyled';

export { CardSettingsCheck3dConfirmationButtonsComponentStyled };
