// Vendors
import React from 'react';
// Components
import {
  TitleComponent,
  LineSeparatorComponent,
  InputDropdownIconComponent,
} from '@openbank/cb-ui-commons';
// Constants
import {
  FILTER_MODAL_INPUT_SELECT_DEFAULT_PROPS,
  FILTER_MODAL_TITLE_COMPONENT_PROPS,
} from './constants/filter-modal-input-select.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FilterModalInputSelectComponentStyled } from './filter-modal-input-select.component.styled';
// Types
import { FilterModalInputSelectComponentType } from './types/filter-modal-input-select.component.type';
// Utilities
import { findOptionByValue, formatSelectOptions } from './utils/filter-modal-input-select.utils';

const FilterModalInputSelectComponent = ({
  assetConfiguration,
  handleFormValuesChange,
  listOptions,
  placeholder,
  showLineSeparator = FILTER_MODAL_INPUT_SELECT_DEFAULT_PROPS.showLineSeparator,
  testId = FILTER_MODAL_INPUT_SELECT_DEFAULT_PROPS.testId,
  title,
  value,
}: FilterModalInputSelectComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  const listOptionsFormatted = formatSelectOptions({
    options: listOptions,
    formatMessage,
  });

  const handleSelectOption = (value: string) => {
    const { assetId, label } = findOptionByValue({ value, options: listOptionsFormatted });
    handleFormValuesChange({
      field: 'categoryCode',
      value: { code: value, assetId, label },
    });
  };

  return (
    <FilterModalInputSelectComponentStyled {...{ testId }}>
      <TitleComponent {...FILTER_MODAL_TITLE_COMPONENT_PROPS}>{title}</TitleComponent>
      <InputDropdownIconComponent
        assetConfiguration={assetConfiguration}
        placeholder={placeholder}
        onSelectOption={handleSelectOption}
        options={listOptionsFormatted}
        value={value}
        dropUp={true}
      />
      {showLineSeparator && <LineSeparatorComponent />}
    </FilterModalInputSelectComponentStyled>
  );
};

export { FilterModalInputSelectComponent };
