// Services
import {
  uploadDocumentToGetDocumentUriService,
  uploadDocumentWithDocumentUriService,
  uploadDocumentationGoNextService,
} from '../services/step-4-upload-documentation.services';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Types
import { UploadDocumentToGetDocumentUriPropsType } from './types/upload-document-to-get-uri-props.type';
import { UploadDocumentToGetDocumentUriReturnType } from './types/upload-document-to-get-uri-return.type';
import { UploadDocumentWithDocumentUriPropsType } from './types/upload-document-with-document-uri-props.type';
import { UploadDocumentationGoNextPropsType } from './types/step-4-upload-documentation-props.type';
import { CaseInitResponseType } from '../services/types/step-0-init.type';

export const uploadDocumentToGetDocumentUriResolver = async (
  props: UploadDocumentToGetDocumentUriPropsType
): Promise<[UploadDocumentToGetDocumentUriReturnType, boolean]> => {
  const [response, { status }] = await uploadDocumentToGetDocumentUriService(props);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export const uploadDocumentWithDocumentUriResolver = async (
  props: UploadDocumentWithDocumentUriPropsType
): Promise<[Record<string, never>, boolean]> => {
  const [response, { status }] = await uploadDocumentWithDocumentUriService(props);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export const uploadDocumentationGoNextResolver = async (
  props: UploadDocumentationGoNextPropsType
): Promise<[CaseInitResponseType | null, boolean]> => {
  const [response, { status }] = await uploadDocumentationGoNextService(props);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};
