// Types
import { DashboardTopMessageButtonBuilderReturnHandlersType } from './types/dashboard-top-message-button-builder-return.handlers.type';
import { DashboardTopMessageButtonBuilderHandlersType } from './types/dashboard-top-message-button-builder.handlers.type';
import { DashboardTopMessageButtonItemComponentType } from './types/dashboard-top-message-button-item.component.type';
import { DashboardTopMessageButtonMethodBuilderHandlersType } from './types/dashboard-top-message-button-method-builder.handlers.type';

const dashboardTopMessageButtonClickHandler = ({
  handleDashboardTopMessageButtonClickTracking,
  navigate,
  trackingLabel,
  url,
}: DashboardTopMessageButtonMethodBuilderHandlersType) => {
  handleDashboardTopMessageButtonClickTracking(trackingLabel);
  navigate(url);
};

const DashboardTopMessageButtonHandlers = (
  builderProps: DashboardTopMessageButtonBuilderHandlersType
): DashboardTopMessageButtonBuilderReturnHandlersType => ({
  handleDashboardTopMessageButtonClick: (
    props: Pick<DashboardTopMessageButtonItemComponentType, 'trackingLabel' | 'url'>
  ) => dashboardTopMessageButtonClickHandler({ ...props, ...builderProps }),
});

export default DashboardTopMessageButtonHandlers;
