//Configurations
import { getSnackBarConfiguration } from '../configurations/device-header-alias.configurations';
// Constants
import { VALIDATION_CODE_ERROR } from './constants/device-header-alias.handlers.constants';
// Enumerations
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Resolvers
import { editDeviceAliasResolver } from 'containers/security-keys/views/devices/resolvers/signature-key-devices.resolvers';
// Translations
import {
  INPUT_ERROR_REQUIRED,
  INPUT_ERROR_MIN_LENGTH,
  VALIDATION_ERROR
} from '../translations/alias.translations';
// Types
import { DeviceHeaderAliasHandlersPropsType } from './type/device-header-alias.handlers.props.type';
import { DeviceHeaderAliasHandlersReturnType } from './type/device-header-alias.handlers.return.type';

const handleClickOutside = (args): void => {
  const { alias, error, setError, success, event, inputRef, setClosing, setEditing, setName, setSuccess } = args;
  const input = inputRef.current.querySelector('input').parentNode;

  if (input && !input.contains(event.target as HTMLElement)) {
    setClosing(true);
    setEditing(false);
    
    (!success || error.description) && setName(alias);
    
    setSuccess(false);
    setError({ description: '' });

    setTimeout(() => {
      setClosing(false);
      document.removeEventListener('click', handleClickOutside.bind(null, args), true);
    });
  }
};

const makeEditClickHandler = ({ setEditing, closing, onOpenEditingInputDeviceTracking }) => {
  !closing && setEditing(curEditing => {
    !curEditing && onOpenEditingInputDeviceTracking();
    return !curEditing;
  });
};

const onOpenChangeHandler = (args) => {
  const handlerClickListener = (event: MouseEvent) => handleClickOutside({ event, ...args });

  if (args.editing) {
    document.addEventListener('click', handlerClickListener, true);
  }

  return () => {
    document.removeEventListener('click', handlerClickListener, true);
  };
};

const handleInputChange = ({ setSuccess, setError, setName, value, formatMessage, onMinLengthErrorTracking, onRequiredErrorTracking }): string => {
  setName(value);
  setSuccess(false);

  switch (true) {
    case !value.length:
      onRequiredErrorTracking();
      return setError({ description: formatMessage({ id: INPUT_ERROR_REQUIRED }) });
    case value.length < 6:
      onMinLengthErrorTracking();
      return setError({ description: formatMessage({ id: INPUT_ERROR_MIN_LENGTH }) });
    default:
      return setError({ description: '' });
  }
};

const saveNewAliasHandler = async({
  formatMessage,
  error,
  name,
  deviceUUID,
  setToastConfiguration,
  setLoading,
  setSuccess,
  setError,
  devices,
  setDevices,
  setTrusted,
  onOpenSaveClickDeviceTracking,
  onValidationErrorTracking,
  onSnackBarErrorOpenTracking,
  onSnackBarErrorCloseTracking,
  onSnackBarSuccessOpenTracking,
  onSnackBarSuccessCloseTracking
}) => {
  if(!error.description) {
    setSuccess(false);
    setLoading(true);
    onOpenSaveClickDeviceTracking();

    const [response, err] = await editDeviceAliasResolver({ alias: name, deviceUUID });

    if(err) {
      onSnackBarErrorOpenTracking();
      setToastConfiguration(getSnackBarConfiguration({
        type: ToastTypesEnumeration.ERROR,
        onClose: onSnackBarErrorCloseTracking
      }));

      if(response?.errorCode === VALIDATION_CODE_ERROR) {
        onValidationErrorTracking();
        setError({
          description: formatMessage({ id: VALIDATION_ERROR }),
          icon: true
        });
      }
    } else {
      const index = devices.findIndex(device => device.deviceUUID === deviceUUID);

      (index < 0)
        ? setTrusted(trusted => ({...trusted, alias: name}))
        : devices[index].alias = name;

      setDevices([...devices]);
      setSuccess(true);
      onSnackBarSuccessOpenTracking();
      setToastConfiguration(getSnackBarConfiguration({
        type: ToastTypesEnumeration.SUCCESS,
        onClose: onSnackBarSuccessCloseTracking
      }));
    }

    setLoading(false);
  }
};

const onInputFocusHandler = ({ onOpenInputClickDeviceTracking }) => onOpenInputClickDeviceTracking();

const DeviceHeaderAliasHandler = (
  props: DeviceHeaderAliasHandlersPropsType & { name: string }): DeviceHeaderAliasHandlersReturnType => ({
    openHandler: () => onOpenChangeHandler(props),
    onEditClickHandler: () => makeEditClickHandler(props),
    onInputChangeHandler: (value) => handleInputChange({...props, value}),
    onSaveClickHandler: () => saveNewAliasHandler(props),
    onClickOutside: (event) => handleClickOutside({ ...props, event}),
    onInputFocus: () => onInputFocusHandler(props)
});

export { DeviceHeaderAliasHandler };
