// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SignatureKeyChangeStep3ChallengeTrackingHandlersType } from './types/security-keys-signature-key-change-third-step-challenge-handlers-tracking-return.type';

const sendOtpCodeStep3TrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'verifyButton',
  },
  type: TrackingEventsEnum.LINK,
});

const sendOtpCodeSuccessStep3TrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'otpAuthenticationSMS',
    eventLabel: 'success',
  },
  type: TrackingEventsEnum.LINK,
});

const SecurityKeysSignatureKeyChangeThirdStepChallengeTrackingHandlers = (
  track: TrackBuilderType
): SignatureKeyChangeStep3ChallengeTrackingHandlersType => ({
  handleSendOtpCodeStep3TrackingHandler: () => track(sendOtpCodeStep3TrackingHandler()),
  handleSendOtpCodeSuccessStep3TrackingHandler: () =>
    track(sendOtpCodeSuccessStep3TrackingHandler()),
});

export default SecurityKeysSignatureKeyChangeThirdStepChallengeTrackingHandlers;
