// Constants
import { PHONE_LENGTHS } from './constants/phone.utils.constants';
// Types
import { LocalizationPhonePrefixProviderType } from 'types/localization-configuration.type';
import { PhoneUtilsType } from './types/phone.utils.type';

const isValidPhoneNumber = ({ prefix, phoneNumber }: any): boolean =>
  PHONE_LENGTHS[Number(prefix)]?.includes(phoneNumber?.length);

const getMappedPhonePrefixes = (
  prefixes: LocalizationPhonePrefixProviderType[]
): PhoneUtilsType[] =>
  prefixes.map(({ country, prefix }) => ({
    label: prefix,
    value: prefix,
  }));

export { isValidPhoneNumber, getMappedPhonePrefixes };
