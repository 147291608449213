// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingPPIContextType } from './types/financing-ppi.context.type';

const FinancingPPIContext = createContext<FinancingPPIContextType>({} as FinancingPPIContextType);

const FinancingPPIContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FinancingPPIContextType>> => {
  const [fetchPPI, setFetchPPI] = useState<boolean>(false);

  return (
    <FinancingPPIContext.Provider value={{ fetchPPI, setFetchPPI }}>
      {children as React.ReactNode}
    </FinancingPPIContext.Provider>
  );
};

const useFinancingPPIContextConsumerHook = (): FinancingPPIContextType =>
  useContext(FinancingPPIContext);

/* eslint-disable */
const FinancingPPIContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingPPIContextType>> =>
    (
      <FinancingPPIContextProvider>
        <Component {...props} />
      </FinancingPPIContextProvider>
    );

export { FinancingPPIContextConsumerHOC, useFinancingPPIContextConsumerHook };
