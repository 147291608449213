// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { OrchestratorBuilderTrackingHandlersType } from './types/orchestrator-builder.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const makeUserLogoutButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'logout',
  },
  type: TrackingEventsEnum.LINK,
});

const OrchestratorHeaderTrackingHandlers = (
  track: TrackBuilderType
): OrchestratorBuilderTrackingHandlersType => ({
  handleMakeUserLogoutButtonClickTracking: () => track(makeUserLogoutButtonClickTracking()),
});

export default OrchestratorHeaderTrackingHandlers;
