export default {
  CONTEXT_DEFAULT_VALUE: {
    continents: [],
    keyword: '',
    mainCountry: {},
    setContinents: (): null => null,
    setKeyword: (): null => null,
    setMainCountry: (): null => null,
  },
};
