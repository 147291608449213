// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Hooks
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Types
import { OrchestratorFooterHookType } from './types/orchestrator-footer.hook.type';

const useOrchestratorFooterHook = (): OrchestratorFooterHookType => {
  const { formatMessage } = useCbIntl();

  const { isHybrid } = MobileConnectorContextConsumerHook();

  return { formatMessage, isHybrid };
};

export { useOrchestratorFooterHook };
