// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  DOCUMENT_TIP_1_ASSET,
  DOCUMENT_TIP_2_ASSET,
  DOCUMENT_TIP_3_ASSET,
  PARAGRAPHS_XS_SIZE,
} from './constants/information-modal.component.constants';
// Hooks
import { InformationModalHook } from './hooks/information-modal.component.hook';
// Styles
import {
  AssetsContainerStyled,
  ModalContainerStyled,
  TipElementStyled,
} from './information-modal.component.styled';
// Translations
import { step4UploadDocumentationTranslations } from '../../translations/step-4-upload-documentation.translations';
// Types
import { InformationModalComponentType } from './types/information-modal.component.type';

const InformationModalComponent = ({
  showInfoModal,
  setShowInfoModal,
  formatMessage,
}: InformationModalComponentType): React.ReactElement | null => {
  if (!showInfoModal) return null;

  const { onHeaderClose, onClickPrimaryButton } = InformationModalHook(setShowInfoModal);

  return (
    <ModalComponent
      isIosDevice={isIosWebView()}
      onHeaderClose={onHeaderClose}
      title={formatMessage({
        id: step4UploadDocumentationTranslations.MODAL_TIPS_FOR_PHOTOGRAPHING,
      })}
      primaryButtonConfiguration={{
        children: formatMessage({
          id: step4UploadDocumentationTranslations.MODAL_BACK_DOCUMENTATION_UPLOAD,
        }),
        onClick: onClickPrimaryButton,
      }}
    >
      <ModalContainerStyled>
        <AssetsContainerStyled>
          <TipElementStyled>
            <AssetComponent {...DOCUMENT_TIP_1_ASSET} />
            <ParagraphComponent size={PARAGRAPHS_XS_SIZE}>
              {formatMessage({
                id: step4UploadDocumentationTranslations.MODAL_TIP_1_PHOTOGRAPHING,
              })}
            </ParagraphComponent>
          </TipElementStyled>
          <TipElementStyled>
            <AssetComponent {...DOCUMENT_TIP_2_ASSET} />
            <ParagraphComponent size={PARAGRAPHS_XS_SIZE}>
              {formatMessage({
                id: step4UploadDocumentationTranslations.MODAL_TIP_2_PHOTOGRAPHING,
              })}
            </ParagraphComponent>
          </TipElementStyled>
          <TipElementStyled>
            <AssetComponent {...DOCUMENT_TIP_3_ASSET} />
            <ParagraphComponent size={PARAGRAPHS_XS_SIZE}>
              {formatMessage({
                id: step4UploadDocumentationTranslations.MODAL_TIP_3_PHOTOGRAPHING,
              })}
            </ParagraphComponent>
          </TipElementStyled>
        </AssetsContainerStyled>
        <ParagraphComponent>
          {formatMessage({ id: step4UploadDocumentationTranslations.MODAL_TIPS_DESCRIPTION })}
        </ParagraphComponent>
      </ModalContainerStyled>
    </ModalComponent>
  );
};

export { InformationModalComponent };
