const fromDatePickercommonProps = {
  placeholder: 'DD. MM. YYYY',
  locale: 'es-ES',
};

const FROM_DATE_PICKER_DEFAULT_PROPS = {
  ...fromDatePickercommonProps,
  label: 'From',
};

const TO_DATE_PICKER_DEFAULT_PROPS = {
  ...fromDatePickercommonProps,
  label: 'To',
};

const INPUT_RANGE_DATE_PICKER_DEFAULT_PROPS = {
  showLineSeparator: false,
  testId: 'filter-modal-input-range-date-picker-component',
};

const INPUT_RANGE_PICKER_PROPS = {
  locale: 'de-DE',
  placeholder: 'DD. MM. YYYY',
  testId: 'input-range-picker',
};

export {
  FROM_DATE_PICKER_DEFAULT_PROPS,
  INPUT_RANGE_DATE_PICKER_DEFAULT_PROPS,
  INPUT_RANGE_PICKER_PROPS,
  TO_DATE_PICKER_DEFAULT_PROPS,
};
