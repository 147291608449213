// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_STATEMENTS_TABLE_ROW_AMOUNT_PROPS } from './constants/card-settings-statements-table-row-amount.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Styles
import { CardSettingsStatementsTableRowAmountComponentStyled } from '../../card-settings-statements-table-row.component.styled';
// Types
import { CardSettingsStatementsTableRowAmountComponentType } from './types/card-settings-statements-table-row-amount.component.type';

const CardSettingsStatementsTableRowAmountComponent = ({
  currency,
  totalSettlementAmount,
}: CardSettingsStatementsTableRowAmountComponentType): React.ReactElement => (
  <CardSettingsStatementsTableRowAmountComponentStyled>
    <ParagraphComponent {...CARD_SETTINGS_STATEMENTS_TABLE_ROW_AMOUNT_PROPS}>
      <FormattedNumber
        {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
        value={-totalSettlementAmount}
      />
    </ParagraphComponent>
  </CardSettingsStatementsTableRowAmountComponentStyled>
);

export { CardSettingsStatementsTableRowAmountComponent };
