// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Enumerations
import { ChildrenOptionsEnumeration } from '../enumerations/children-options.enumeration';
// Types
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';
// Translations
import translations from '../translations/personal-situation-credit-limit-increase.component.translations';

export const getChildrenOptions = (
  formatMessage: UseCbIntlReturnType['formatMessage']
): React.ComponentProps<typeof InputDropdownComponent>['options'] => [
  { label: '0', value: ChildrenOptionsEnumeration.ZERO },
  { label: '1', value: ChildrenOptionsEnumeration.ONE },
  { label: '2', value: ChildrenOptionsEnumeration.TWO },
  { label: '3', value: ChildrenOptionsEnumeration.THREE },
  { label: '4', value: ChildrenOptionsEnumeration.FOUR },
  {
    label: formatMessage({ id: translations[ChildrenOptionsEnumeration.FIVE_OR_MORE] }),
    value: ChildrenOptionsEnumeration.FIVE_OR_MORE,
  },
];
