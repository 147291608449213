// Vendors
import { assetConstants, linkConstants } from '@openbank/cb-ui-commons';

const CANCEL_MODAL_HAVE_DESCRIPTION_PROPS = true;

const CANCEL_MODAL_PARAGRAPH_01_PROPS = {
  testId: 'cancel-modal-description-01',
};
const CANCEL_MODAL_PARAGRAPH_02_PROPS = {
  testId: 'cancel-modal-description-02',
};
const CANCEL_MODAL_BUTTON_PROPS = {
  testId: 'cancel-modal-button',
};
const CANCEL_MODAL_LINK_PROPS = {
  type: linkConstants.TYPES.ACTION_PRIMARY,
  testId: 'cancel-modal-links',
};
const CANCEL_MODAL_DEFAULT_ASSET_PROPS = {
  id: 'Working_on_it_Default_Error',
  sizes: {
    height: 120,
    width: 120,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'cancel-modal-asset',
};

export {
  CANCEL_MODAL_HAVE_DESCRIPTION_PROPS,
  CANCEL_MODAL_BUTTON_PROPS,
  CANCEL_MODAL_DEFAULT_ASSET_PROPS,
  CANCEL_MODAL_LINK_PROPS,
  CANCEL_MODAL_PARAGRAPH_01_PROPS,
  CANCEL_MODAL_PARAGRAPH_02_PROPS,
};
