// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS } from 'constants/wizard/wizard.constants';
// Hooks
import { ChangeIbanStep2ActionsHook } from './hooks/change-iban-step2-actions.hook';
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
// Translations
import translations from './translations/change-iban-step2-actions.translations';

const ChangeIbanStep2ActionsComponent = (): React.ReactElement => {
  const { handleChangeIbanButtonClick, formatMessage, fetching } = ChangeIbanStep2ActionsHook(),
    { challenged } = ChangeIbanContextConsumerHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS}
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        onClick: handleChangeIbanButtonClick,
        disabled: challenged || fetching,
        loading: fetching,
        children: formatMessage({ id: translations.CONFIRM }),
      }}
    />
  );
};

export { ChangeIbanStep2ActionsComponent };
