// Vendors
import styled from 'styled-components';

export const SidebarDropdownsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const SidebarDropdownsItemComponentStyled = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacingS};
`;
