// Translations
import {
  FEATURE_01,
  FEATURE_02,
  FEATURE_03,
  FEATURE_04,
  FEATURE_05,
  FEATURE_06,
  FEATURE_07,
  TITLE,
} from '../translations/financing-ppi-contracting-step2-expandable-details.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_DETAILS_PROPS = {
  features: [FEATURE_01, FEATURE_02, FEATURE_03, FEATURE_04, FEATURE_05, FEATURE_06, FEATURE_07],
  title: TITLE,
};

export { FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_DETAILS_PROPS };
