// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingSidebarComponent } from './components/sidebar/financing-ppi-contracting-sidebar.component';
import WizardComponent from 'components/wizard/wizard.component';
// Contexts
import { FinancingPPIContractingContextProvider } from './contexts/financing-ppi-contracting.context';
// Constants
import { FINANCING_PPI_CONTRACTING_WIZARD_PROPS } from './constants/financing-ppi-contracting.constants';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const FinancingPPIContractingComponent = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <FinancingPPIContractingContextProvider>
        <WizardComponent {...FINANCING_PPI_CONTRACTING_WIZARD_PROPS} />
      </FinancingPPIContractingContextProvider>
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <FinancingPPIContractingSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export { FinancingPPIContractingComponent };
