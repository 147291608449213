// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Translations
import translations from 'containers/security-keys/views/access-key-change/translations/security-keys-access-key-change.translations';
// Styled
import { AccessKeyContainerHeaderStyled } from './security-keys-access-key-change-third-step-header.component.styled';

const SecurityKeysAccessKeyChangeContainerHeaderThirdStep = (): React.ReactElement => {
  return (
    <AccessKeyContainerHeaderStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.NOTIFICATION_FIRST_LABEL} />
      </ParagraphComponent>
      <FormattedMessageComponent id={translations.NOTIFICATION_SECOND_LABEL} />
    </AccessKeyContainerHeaderStyled>
  );
};

export default SecurityKeysAccessKeyChangeContainerHeaderThirdStep;
