// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import constants from './constants/card-settings-activate-card-success.handlers.constants';
// Types
import { CardSettingsActivateCardSuccessReturnTrackingHandlersType } from './types/card-settings-activate-card-success-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const backLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: constants.BACK_LINK_CLICK_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const CardSettingsActivateCardSuccessTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsActivateCardSuccessReturnTrackingHandlersType => ({
  handleBackLinkClickTracking: () => track(backLinkClickTrackingHandler()),
});

export default CardSettingsActivateCardSuccessTrackingHandlers;
