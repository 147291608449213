// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const DocumentationCategoryListComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  width: ${convertPxsToRems({ pixels: 276, base: 14 })};

  ${mobileAndTabletMediaQuery} {
    width: 100%;
  }
`;
