// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/dashboard.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { DashboardContextType } from './types/dashboard.context.type';
import { DashboardComponentType } from '../types/dashboard.component.type';

const DashboardContext = createContext<DashboardContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const DashboardContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<DashboardContextType>> => {
  const [information, setInformation] = useState<DashboardComponentType | Record<string, any>>({});
  return (
    <DashboardContext.Provider value={{ information, setInformation }}>
      {children as React.ReactNode}
    </DashboardContext.Provider>
  );
};

export const DashboardContextConsumerHook = (): DashboardContextType =>
  useContext(DashboardContext);

/* eslint-disable */
export const DashboardContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<DashboardContextType>> =>
    (
      <DashboardContextProvider>
        <Component {...props} />
      </DashboardContextProvider>
    );
