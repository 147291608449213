// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  DOCUMENTS_SERVICE_CONFIGURATION,
  CATEGORIES_SERVICE_CONFIGURATION,
} from './constants/documentation.service.constants';
// Types
import { type DocumentationApiReturnType } from '../types/documentation-api-return.type';
import { type CategoriesApiReturnType } from '../types/categories-api-return.type';

export const getDocumentInformationService = async (
  categorySelected: string | null,
  formValuesParams: Record<string, any>
): Promise<[DocumentationApiReturnType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...DOCUMENTS_SERVICE_CONFIGURATION,

    queryParams: { categories: [categorySelected], limit: 10, ...formValuesParams },
  });

export const getCategoriesService = async (): Promise<
  [CategoriesApiReturnType | Record<string, any>, Response]
> => apiUtilsCb.get(CATEGORIES_SERVICE_CONFIGURATION);
