// Constants
import {
  CARD_MOVEMENT_REFUNDS_CONTROLLER,
  CARD_COMMERCE_PAYMENT_CONTROLLER,
} from './constants/notifications-settings-list.utils.constants';
import { NUMBER } from 'constants/number.constants';
// Types
import { getDefaultControllerAmountType } from './types/get-default-controller-amount.type';
import { getControllerMinAmountByIdType } from './types/get-controller-min-amount-by-id.type';
import { getControllerMinAmountType } from './types/get-controller-min-amount.type';
import { isMinAmountType } from './types/is-min-amount.type';

const isMinAmount = ({ amount, minAmount }: isMinAmountType): boolean => amount < minAmount;

const getDefaultControllerAmount = ({ amount, notificationSetting }: getDefaultControllerAmountType): number => {
  const minAmount = getControllerMinAmount({ notificationSetting });

  return isMinAmount({ amount, minAmount }) ?
    minAmount : 
    amount;
};

const getControllerMinAmount = ({ notificationSetting }: getControllerMinAmountType): number => {
  if (String(notificationSetting?.controllers[0]?.minAmount)) {
    return notificationSetting?.controllers[0]?.minAmount;
  }
  return getControllerMinAmountById({ notificationSettingId: notificationSetting.id });
};

const getControllerMinAmountById = ({
  notificationSettingId,
}: getControllerMinAmountByIdType): number => {
  switch (notificationSettingId) {
    case CARD_MOVEMENT_REFUNDS_CONTROLLER.DEFAULT_ID:
      return CARD_MOVEMENT_REFUNDS_CONTROLLER.DEFAULT_MIN_AMOUNT;

    case CARD_COMMERCE_PAYMENT_CONTROLLER.DEFAULT_ID:
      return CARD_COMMERCE_PAYMENT_CONTROLLER.DEFAULT_MIN_AMOUNT;

    default:
      return NUMBER.ZERO;
  }
};

export { getControllerMinAmount, getDefaultControllerAmount, isMinAmount };
