// Vendors
import styled from 'styled-components';

const CardSettingsCheck3dWizardStep1ComponentStyled = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export { CardSettingsCheck3dWizardStep1ComponentStyled };
