// Vendors
import React from 'react';
// Components
import ProfilePersonalNameTitleDropdownComponent from './components/dropdown/profile-personal-name-title-dropdown.component';
import ProfilePersonalNameTitleEditComponent from './components/edit/profile-personal-name-title-edit.component';
import ProfilePersonalNameTitleEmptyComponent from './components/empty/profile-personal-name-title-empty.component';
// Contexts
import ProfilePersonalNameTitleInputHook from './components/dropdown/hooks/profile-personal-name-title-dropdown.hook';
import {
  ProfilePersonalNameTitleContextConsumerHOC
} from './contexts/profile-personal-name-title.context';
// Utils
import { isNotEditingAndNoTitle } from './utils/profile-personal-name-title.utils';

const ProfilePersonalNameTitleComponent = (): React.ReactElement => {
  const { title, editTitle } = ProfilePersonalNameTitleInputHook();

  switch (true) {
    case editTitle:
      return <ProfilePersonalNameTitleDropdownComponent />;
    case isNotEditingAndNoTitle({ title, editTitle }):
      return <ProfilePersonalNameTitleEmptyComponent />;
    default:
      return <ProfilePersonalNameTitleEditComponent />;
  }
};

export { ProfilePersonalNameTitleComponent };

export default ProfilePersonalNameTitleContextConsumerHOC(ProfilePersonalNameTitleComponent);
