// Vendors
import { SetStateType } from '@openbank/cf-ui-static-data';
// Constants
import { TransactionsReportNotReceivedDocumentsActionsHandlers } from '../handlers/transactions-report-not-received-documents-actions.handlers';
// Hooks
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Types
import { FileDataType } from 'types/file-data.type';
import { TransactionsReportNotReceivedDocumentsActionsHooksType } from './types/transactions-report-not-received-documents-actions-hook.type';

const useTransactionsReportNotReceivedDocumentsActionsHooks = (
  setFiles: SetStateType<FileDataType[]>
): TransactionsReportNotReceivedDocumentsActionsHooksType => {
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleGoBack } = TransactionsReportNotReceivedDocumentsActionsHandlers({
    setFiles,
    setCurrentStep,
  });

  return {
    handleGoBack,
  };
};

export { useTransactionsReportNotReceivedDocumentsActionsHooks };
