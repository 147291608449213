// Constants
import { SIGNATURE_KEY_EXPIRED_CODE } from './constants/personal-address-step2-challenge.handlers.constants';
// Types
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';
import { PersonalAddressStep2ChallengeHandlersType } from './types/personal-address-step2-challenge.handlers.type';
import { PersonalAddressStep2ChallengeReturnHandlersType } from './types/personal-address-step2-challenge-return.handlers.type';

const challengeErrorHandler = ({ challenge, setError, setSignatureExpired, code }: ChallengeErrorHandlerType): void => {
  if (code === SIGNATURE_KEY_EXPIRED_CODE) {
    return setSignatureExpired(true);
  }

  if (!challenge) setError(true);
};

const challengeSuccessHandler = ({ challenge, setChallenge }: ChallengeSuccessHandlerType): void => {
  setChallenge(challenge);
};

const challengeRetryButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType): void => setChallengeError(false);

export const PersonalAddressStep2ChallengeHandlers = ({
  challenge,
  setChallenge,
  setChallengeError,
  setError,
  setSignatureExpired
}: PersonalAddressStep2ChallengeHandlersType): PersonalAddressStep2ChallengeReturnHandlersType => ({
  handleChallengeError: (code: string) => challengeErrorHandler({ challenge, setError, setSignatureExpired, code }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setChallengeError }),
});
