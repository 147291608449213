// Vendors
import React from 'react';
// Configurations
import { getChallengeConfiguration } from './configurations/challenge-wrapper.component.configurations';
// Components
import { ChallengeCompositeComponent } from '@openbank/cb-ui-commons';
import ChallengeWrapperFailMessageComponent from './components/fail-message/challenge-wrapper-fail-message.component';
// Hooks
import ChallengeWrapperHook from './hooks/challenge-wrapper.hook';
// Styles
import { ChallengeWrapperStyled } from './challenge-wrapper.component.styled';
// Types
import { ChallengeWrapperComponentType } from './types/challenge-wrapper.component.type';

const ChallengeWrapperComponent = ({
  challengeError,
  onRetry,
  withTopBorder = true,
  ...props
}: ChallengeWrapperComponentType): React.ReactElement => {
  const { handleRetryButtonClick, handleOpenRequestSignatureModal, ...rest } = ChallengeWrapperHook(
    { onRetry }
  );

  return (
    <ChallengeWrapperStyled {...{ withTopBorder }}>
      <ChallengeCompositeComponent
        {...getChallengeConfiguration({
          ...props,
          ...rest,
          onRequestSignature: handleOpenRequestSignatureModal,
        })}
      />
      {challengeError && <ChallengeWrapperFailMessageComponent onClick={handleRetryButtonClick} />}
    </ChallengeWrapperStyled>
  );
};

export default ChallengeWrapperComponent;
