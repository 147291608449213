// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const DOCUMENTATION_CONTRACT_CATEGORY = 'cat_contratos';

const DOCUMENTATION_CONTENT_TITLE_PROPS = {
  tag: titleConstants.TAGS.H4,
  testId: 'documents-content-title',
};

const DOCUMENTATION_CONTRACT_PROPS = {
  testId: 'documentation-contract',
};

const DOCUMENTATION_NAME_PROPS = {
  testId: 'documentation-name',
};

const DOCUMENTATION_TYPE_PROPS = {
  testId: 'documentation-type',
};

export {
  DOCUMENTATION_CONTENT_TITLE_PROPS,
  DOCUMENTATION_CONTRACT_CATEGORY,
  DOCUMENTATION_CONTRACT_PROPS,
  DOCUMENTATION_NAME_PROPS,
  DOCUMENTATION_TYPE_PROPS,
};
