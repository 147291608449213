// Vendors
import styled from 'styled-components';
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const CreditLimitIncreaseStyled = styled.div.attrs(({ titleId }: { titleId?: string }) => ({
  'data-testid': titleId,
}))<{ isMobileOrTablet?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: ${convertPxsToRems({ pixels: 1980, base: 14 })};
  width: 100%;

  ${tabletMediaQuery} {
    padding: 0;
  }

  ${mobileMediaQuery} {
    padding: 0;
  }
`;
CreditLimitIncreaseStyled.displayName = 'CreditLimitIncreaseStyled';  

export { CreditLimitIncreaseStyled };