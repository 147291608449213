// Enumerations
import {
  DocumentErrorTypeEnumeration,
  DocumentTypeEnumeration,
} from '../enumerations/documentation.enumeration';
// Resolvers
import { getCategoriesResolver, getDocumentsResolver } from '../resolvers/documentation.resolvers';
// Types
import { type DocumentationBuilderHandlersType } from './types/documentation-builder.handlers.type';
import { type DocumentationBuilderMethodHandlersType } from './types/documentation-builder-method.handlers.type';
import { type DocumentationBuilderReturnHandlersType } from './types/documentation-builder-return.handlers.type';

const categoryClickEventHandler = ({
  category,
  handleClickCategoryListTracking,
  setCategorySelected,
  setFormValuesParams,
  setFormValues,
}) => {
  handleClickCategoryListTracking(category);
  setFormValuesParams({});
  setFormValues({});
  setCategorySelected(category);
};

const fetchCategoriesEventHandler = async ({
  handleDisplayDocumentationPageTracking,
  setCategories,
  setCategoryError,
  setLoading,
  setCategorySelected,
}: DocumentationBuilderHandlersType): Promise<void> => {
  handleDisplayDocumentationPageTracking();

  setLoading(true);

  const [response, error] = await getCategoriesResolver();

  if (response?.categories) {
    setCategories(response.categories);
    if (response.categories.includes(DocumentTypeEnumeration.CONTRACT)) {
      setCategorySelected(DocumentTypeEnumeration.CONTRACT);
    } else {
      setCategorySelected(response.categories[0]);
    }
  } else {
    setLoading(false);
    setCategoryError(
      error ? DocumentErrorTypeEnumeration.ERROR : DocumentErrorTypeEnumeration.EMPTY
    );
  }
};

const fetchDocumentsByCategoryEventHandler = async ({
  categorySelected,
  formValuesParams,
  setDocuments,
  setDocumentsError,
  setLoading,
  setLoadingTableContent,
}: DocumentationBuilderMethodHandlersType): Promise<void> => {
  setLoadingTableContent(true);

  const [response] = await getDocumentsResolver(categorySelected, formValuesParams);
  if (response?.documents) {
    setDocuments(response.documents);
    setDocumentsError(false);
  } else {
    setDocumentsError(true);
  }

  setLoadingTableContent(false);
  setLoading(false);
};

export const DocumentationHandlers = (
  props: DocumentationBuilderHandlersType
): DocumentationBuilderReturnHandlersType => ({
  handleCategoryClickEvent: category => categoryClickEventHandler({ ...props, category }),
  handleFetchCategoriesEvent: () => fetchCategoriesEventHandler(props),
  handleFetchDocumentsByCategoryEvent: () => fetchDocumentsByCategoryEventHandler(props),
  handleClickFilter: () => props.setIsShowFilterModal(true),
});
