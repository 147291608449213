// Vendors
import React from 'react';
// Components
import { LinkComponent, ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { LINK_PROPS } from './constants/financing-payment-method-second-step-summary-tnc.constants';
// Hooks
import { useFinancingPaymentMethodSecondStepSummaryTncHook } from './hooks/financing-payment-method-second-step-summary-tnc.hook';
// Translations
import {
  CURRENT_INTEREST_RATE_DETAILS,
  TERMS_AND_CONDITIONS,
} from './translations/financing-payment-method-second-step-summary-tnc.translations';
// Utils
import { isFullPayment } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

export const FinancingPaymentMethodSecondStepSummaryTncComponent =
  (): React.ReactElement | null => {
    const { formatMessage, paymentMethod, visaGeneralTnc } =
      useFinancingPaymentMethodSecondStepSummaryTncHook();

    return isFullPayment(paymentMethod) ? null : (
      <ProductDetailComponent title={formatMessage({ id: CURRENT_INTEREST_RATE_DETAILS })}>
        <ParagraphComponent>
          <LinkComponent {...LINK_PROPS} href={visaGeneralTnc}>
            <FormattedMessageComponent id={TERMS_AND_CONDITIONS} />
          </LinkComponent>
        </ParagraphComponent>
      </ProductDetailComponent>
    );
  };
