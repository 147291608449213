// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ACCESS_PORTABILITY_CARD_DESCRIPTION,
  ACCESS_PORTABILITY_CARD_LINK,
  ACCESS_PORTABILITY_CARD_TITLE,
  CONSENTS_CARD_DESCRIPTION,
  CONSENTS_CARD_LINK,
  CONSENTS_CARD_TITLE,
  DOCUMENTS_CARD_DESCRIPTION,
  DOCUMENTS_CARD_LINK,
  DOCUMENTS_CARD_TITLE,
  PAPERLESS_DESCRIPTION,
  PAPERLESS_TITLE,
} from '../translations/personal-landing.translations';

export const PROFILE_PERSONAL_TOOLTIP_PROPS = {
  position: 'top',
};

export const PERSONAL_LANDING_PAPERLESS_PROPS = {
  description: PAPERLESS_DESCRIPTION,
  testing: {
    descriptionId: 'personal-landing-paperless-table-row-description',
    titleId: 'personal-landing-paperless-title-section',
  },
  title: PAPERLESS_TITLE,
};

export const PERSONAL_LANDING_ACCESS_PORTABILITY_PROPS = {
  description: ACCESS_PORTABILITY_CARD_DESCRIPTION,
  link: ACCESS_PORTABILITY_CARD_LINK,
  testing: {
    descriptionId: 'personal-landing-access-portability-table-row-description',
    linkId: 'personal-landing-access-portability-links',
    titleId: 'personal-landing-access-portability-title-section',
  },
  title: ACCESS_PORTABILITY_CARD_TITLE,
  url: RoutesEnumeration.ACCESS_PORTABILITY,
};

export const PERSONAL_LANDING_CONSENTS_PROPS = {
  description: CONSENTS_CARD_DESCRIPTION,
  link: CONSENTS_CARD_LINK,
  testing: {
    descriptionId: 'personal-landing-consents-table-row-description',
    linkId: 'personal-landing-consents-links',
    titleId: 'personal-landing-consents-title-section',
  },
  title: CONSENTS_CARD_TITLE,
  url: RoutesEnumeration.CONSENTS,
};

export const PERSONAL_LANDING_DOCUMENTS_PROPS = {
  description: DOCUMENTS_CARD_DESCRIPTION,
  link: DOCUMENTS_CARD_LINK,
  testing: {
    descriptionId: 'personal-landing-documents-table-row-description',
    linkId: 'personal-landing-documents-links',
    titleId: 'personal-landing-documents-title-section',
  },
  title: DOCUMENTS_CARD_TITLE,
  url: RoutesEnumeration.DOCUMENTATION,
};
