// Vendors
import styled from 'styled-components';
// Enumeration
import { TransactionsStatusCodeEnumeration } from 'enumerations/transactions-status-code.enumeration';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const getAmountHelperColor = ({ transactionStatusCode, theme }) =>
  ({
    [TransactionsStatusCodeEnumeration.ANNULLED]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.AUTHORIZED]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.DECLINED]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.DROPPED]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.FAILED]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.LIQUIDATED]: theme.colors.backgroundWarningA,
    [TransactionsStatusCodeEnumeration.NEW]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.PENDING_CONSOLIDATION]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.PENDING_LIQUIDATION]: theme.colors.backgroundTertiaryA,
    [TransactionsStatusCodeEnumeration.REJECTED]: theme.colors.backgroundTertiaryA,
  }[transactionStatusCode]);

export const TransactionTableRowAmountHelperComponentStyled = styled.span.attrs(
  ({ testId }: TestIdPropsType) => ({ 'data-testid': testId })
)<{
  transactionStatusCode: TransactionsStatusCodeEnumeration;
}>`
  color: ${({ transactionStatusCode, theme }) =>
    getAmountHelperColor({ transactionStatusCode, theme })};
`;
