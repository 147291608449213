// Vendors
import styled, { css } from 'styled-components';
// Utils
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from '@openbank/cf-ui-framework';

export const DocumentsTableHeaderComponentStyled = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: grid;
  grid-template-columns: ${convertPxsToRems({ pixels: 100, base: 14 })} 1fr;
  padding: 0 ${({ theme }) => theme.spaces.spacingS};
  height: ${convertPxsToRems({ pixels: 56, base: 14 })};
  width: 100%;

  ${mobileMediaQuery} {
    display: none;
  }
`;
