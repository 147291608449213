import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const IBAN_BILLING_LANDING_ROUTE_PROPS = {
    index: true,
  },
  IBAN_BILLING_CHANGE_ROUTE_PROPS = {
    path: RoutesEnumeration.CHANGE_IBAN,
  },
  IBAN_BILLING_CYCLE_ROUTE_PROPS = {
    path: RoutesEnumeration.CHANGE_BILLING_CYCLE,
  };

export {
  IBAN_BILLING_LANDING_ROUTE_PROPS,
  IBAN_BILLING_CHANGE_ROUTE_PROPS,
  IBAN_BILLING_CYCLE_ROUTE_PROPS,
};
