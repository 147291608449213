// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { QuickActionsBuilderReturnTrackingHandlersType } from './types/quick-actions.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const quickActionClickTrackingHandler = (eventLabel: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

const QuickActionsTrackingHandlers = (
  track: TrackBuilderType
): QuickActionsBuilderReturnTrackingHandlersType => ({
  handleQuickActionClickTracking: (eventLabel: string) =>
    track(quickActionClickTrackingHandler(eventLabel)),
});

export { QuickActionsTrackingHandlers };
