// Enumerations
import { BankStatusEnumeration } from '../enumerations/bank-status.enumeration';
// Types
import { BankDataType } from 'containers/financing/views/credit-limit-increase/services/types/bank-data.type';

export const hasValidBankData = (bankData: BankDataType[]): boolean => {
  if (!bankData || !bankData.length) {
    return false;
  }
  if (bankData.some(bank => bank.status === BankStatusEnumeration.OK)) {
    return true;
  }
  return false;
};
