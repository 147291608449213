// Enums
import { TranslationsUtilsType } from 'utils/types/translations.utils.type';

const translateLabels = ({
  formatMessage,
  translations,
}: TranslationsUtilsType): Record<string, string> | any =>
  Object.fromEntries(
    Object.entries(translations).map((attribute: [string, any]) => [
      attribute[0],
      formatMessage({ id: attribute[1] }),
    ])
  );

const translateItemsList = ({ formatMessage, list }: any): Record<string, string> | any =>
  list.map((id: string) => formatMessage({ id }));

export { translateLabels, translateItemsList };
