// Constants
import { DEFAULT_DELAY } from './constants/debounce.constants';

export const debounce = (
  callback: (...args: any[]) => void,
  delay?: number
): ((...args: any[]) => any) => {
  let timer: string | number | NodeJS.Timeout | undefined;

  return (...args) =>
    new Promise((resolve, reject) => {
      clearTimeout(timer);

      timer = setTimeout(() => {
        try {
          const output = callback(...args);

          resolve(output);
        } catch (err) {
          reject(err);
        }
      }, delay || DEFAULT_DELAY);
    });
};
