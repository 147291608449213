// Vendors
import styled from 'styled-components';
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const FinancingInsurancesContratingLPIDownloadGridStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileAndTabletMediaQuery} {
    grid-template-columns: 1fr;
  }
`;
