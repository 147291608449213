export default {
  VIEW: {
    STEP1_SET_NEW_LIMIT_VIEW: {
      pageName: '/creditLimitIncrease/step01/setNewCardLimit',
      sectionFamily: 'banking',
      sectionCategory: 'products',
      sectionName: 'cards',
      interactionType: 'funnel',
      serviceType: 'productSettings',
      processType: 'creditLimitIncrease',
      processStep: 'step01',
      processDetail: 'setNewCardLimit',
    },
  },
  LINK: {
    TEXT_BOX_CLICK: {
      eventAction: 'clickTextBox',
      eventLabel: 'amountTextBox',
    },
    CREDIT_LIMIT_SLIDER_CHANGE: (value: string) => ({
      eventAction: 'creditLimitSlidebar',
      eventLabel: value,
    }),
  },
} as const;
