// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const MessageContentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const MessageContentLeftStyled = styled.div`
  flex: 1;
`;

export const MessageContentRightStyled = styled.div`
  flex: 0 0 auto;
`;
