// Types
import { HandlerChangeInputEventPropsType } from './types/extra-information-input.handler.type';
import { HandleChangeInputEventPropsType } from './types/handle-change-input-event.type';
import { HandlerChangeInputEventReturnPropsType } from './types/handle-change-input-event-return.type';

const handleChangeInputEvent = ({
  setValue,
  selectedOptions,
  setSelectedOptions,
  value,
}: HandleChangeInputEventPropsType): void => {
  setValue(value);
  setSelectedOptions({ ...selectedOptions, extraInformation: value });
};

export const ExtraInformationInputHanders = ({
  selectedOptions,
  setSelectedOptions,
  setValue,
}: HandlerChangeInputEventPropsType): HandlerChangeInputEventReturnPropsType => ({
  changeInputEventHandler: (value: string) =>
    handleChangeInputEvent({ selectedOptions, setSelectedOptions, setValue, value }),
});
