// Types
import { CardTransactionAmountType } from 'types/card-transaction-amount.type';

const findTransactionTotalAmount = (amounts: CardTransactionAmountType[]): number =>
  amounts?.find((amount: CardTransactionAmountType) => 'totalAmount' in amount)?.totalAmount || 0;

const getAbsoluteTransactionTotalAmount = (amounts: CardTransactionAmountType[]): number =>
  Math.abs(findTransactionTotalAmount(amounts));

const isTransactionTotalAmountPositive = (amounts: CardTransactionAmountType[]): boolean =>
  findTransactionTotalAmount(amounts) > 0;

const transaformTransactionTotalAmount = (amounts: CardTransactionAmountType[]): string =>
  findTransactionTotalAmount(amounts) > 0 ? '+' : '';

export {
  findTransactionTotalAmount,
  getAbsoluteTransactionTotalAmount,
  isTransactionTotalAmountPositive,
  transaformTransactionTotalAmount,
};
