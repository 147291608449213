// Services
import { changeCardControlsControlService } from '../services/settings-card-control-toggle.services';
// Types
import { SettingsCardControlToggleApiBuilderType } from '../types/settings-card-control-toggle-api-builder.type';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const changeCardControlsControlResolver = async (
  props: SettingsCardControlToggleApiBuilderType
): Promise<[number] | [null, true]> => {
  const [, { status }] = await changeCardControlsControlService(props);

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

export { changeCardControlsControlResolver };
