// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';
// Resolvers
import {
  getUserAvatarResolver,
  updateUserPhotoResolver,
} from '../resolvers/personal-photo-navigation-footer.resolver';
// Translations
import {
  ERROR_MESSAGE,
  SUCCESS_MESSAGE,
} from './translations/personal-photo-navigation-footer.handlers.translations';
// Types
import { PersonalPhotoNavigationFooterApiBuilderHandlersType } from './types/personal-photo-navigation-footer-api-builder.handlers.type';
import { PersonalPhotoNavigationFooterApiBuilderReturnHandlersType } from './types/personal-photo-navigation-footer-api-builder-return.handlers.type';
import { PersonalPhotoNavigationFooterApiMethodBuilderHandlersType } from './types/personal-photo-navigation-footer-api-method-builder.handlers.type';
// Utils
import { convertBlobToBase64 } from './utils/personal-photo-navigation-footer.handlers.utils';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { isValidBlobInstance } from 'containers/personal/views/photo/utils/personal-photo.utils';
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const handleManageUploadResult = ({
  error,
  navigate,
  setMe,
  setToastConfiguration,
}: PersonalPhotoNavigationFooterApiMethodBuilderHandlersType): any => {
  !error && setMe(true);
  setToastConfiguration(
    setupToastConfiguration({
      error,
      description: getToastMessage({ error, translations: { ERROR_MESSAGE, SUCCESS_MESSAGE } }),
    })
  );
  navigate(generateAbsoluteRoute(RoutesEnumeration.PERSONAL_INFORMATION));
};

const manageToDownloadAvatarAndUpload = async (
  avatar: AvatarIdsEnumeration | null
): Promise<[number] | [boolean] | [null, true] | any> => {
  const [response, error] = await getUserAvatarResolver(avatar);

  if (error) {
    return Promise.resolve([response, error]);
  } else if (response && isValidBlobInstance(response)) {
    return convertToBase32AndUpload(response as Blob);
  }
};

const convertToBase32AndUpload = async (avatar: Blob): Promise<[number] | [null, true]> =>
  updateUserPhotoResolver(await convertBlobToBase64(avatar));

const saveUploadPhotoButtonClickEventHandler = async ({
  avatar,
  handleSaveUploadPhotoButtonClickTracking,
  navigate,
  photo,
  setFetching,
  setMe,
  setToastConfiguration,
}: PersonalPhotoNavigationFooterApiBuilderHandlersType): Promise<void> => {
  handleSaveUploadPhotoButtonClickTracking();
  setFetching(true);

  if (avatar) {
    const [, error] = await manageToDownloadAvatarAndUpload(avatar);

    handleManageUploadResult({ error, navigate, setMe, setToastConfiguration });
  } else {
    const [, error] = await updateUserPhotoResolver(photo);

    handleManageUploadResult({ error, navigate, setMe, setToastConfiguration });
  }

  setFetching(false);
};

const PersonalPhotoNavigationFooterApiHandlers = (
  props: PersonalPhotoNavigationFooterApiBuilderHandlersType
): PersonalPhotoNavigationFooterApiBuilderReturnHandlersType => ({
  handleSaveUploadPhotoButtonClick: () => saveUploadPhotoButtonClickEventHandler(props),
});

export default PersonalPhotoNavigationFooterApiHandlers;
