// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FinancingPaymentMethodSecondStepActionsComponent from './components/actions/financing-payment-method-second-step-actions.component';
import FinancingPaymentMethodSecondStepSummaryComponent from './components/summary/financing-payment-method-second-step-summary.component';
// Configurations
import { getChallengeConfiguration } from './configurations/financing-payment-method-second-step.component.configurations';
// Hooks
import FinancingPaymentMethodSecondStepHook from './hooks/financing-payment-method-second-step.hook';
// Styles
import { FinancingPaymentMethodSecondStepComponentStyled } from './financing-payment-method-second-step.component.styled';

const FinancingPaymentMethodSecondStepComponent = (): React.ReactElement | null => {
  const { paymentMethodParams, ...challengeParams } = FinancingPaymentMethodSecondStepHook();

  return paymentMethodParams ? (
    <FinancingPaymentMethodSecondStepComponentStyled>
      <FinancingPaymentMethodSecondStepSummaryComponent />
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({ paymentMethodParams, ...challengeParams })}
      />
      <FinancingPaymentMethodSecondStepActionsComponent />
    </FinancingPaymentMethodSecondStepComponentStyled>
  ) : null;
};

export default FinancingPaymentMethodSecondStepComponent;
