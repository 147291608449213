// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/transactions-landing-row-details-category.constants';
// Styles
import { TransactionsLandingRowDetailsResumeLinkComponentStyled } from '../../transactions-landing-row-details.component.styled';
// Translations
import translations from './translations/transactions-landing-row-details-category.translations';
// Types
import { CardTransactionType } from 'types/card-transaction.type';

const TransactionsLandingRowDetailsCategoryComponent = ({
  category,
}: Pick<CardTransactionType, 'category'>): React.ReactElement => (
  <TransactionsLandingRowDetailsResumeLinkComponentStyled>
    <ResumeItemComponent
      {...constants.TRANSACTIONS_LANDING_ROW_DETAILS_CATEGORY_PROPS}
      label={<FormattedMessageComponent id={translations.CATEGORY} />}
      value={
        <FormattedMessageComponent
          id={
            category?.categoryCode
              ? translations.CATEGORY_VALUE + category?.categoryCode
              : translations.TO_BE_ASSIGNED
          }
        />
      }
    />
  </TransactionsLandingRowDetailsResumeLinkComponentStyled>
);

export default TransactionsLandingRowDetailsCategoryComponent;
