// Vendors
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS = {
  titleBold: false,
  type: SectionDividerTypesEnumeration.INFORMATIVE,
};

const CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_ELEMENT_PROPS =
  'card-settings-contract-current-section-divider-element-';
const CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_TITLE_PROPS =
  'card-settings-contract-current-section-divider-title-';

const CARD_SETTINGS_CONTRACT_CURRENT_SECTION_TITLE_PROPS = {
  uppercase: true,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_ELEMENT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_TITLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_TITLE_PROPS,
};
