// Types
import { FinancingFastCashSecondStepReturnHandlersType } from './types/financing-fast-cash-second-step-return.handlers.type';
import { FinancingFastCashSecondStepHandlersType } from './types/financing-fast-cash-second-step.handlers.type';
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType): void => {
  if (!challenge) setError(true);
};

const challengeSuccessHandler = ({
  challenge,
  setChallenge,
}: ChallengeSuccessHandlerType): void => {
  setChallenge(challenge);
};

const retryChallengeErrorHandler = ({ setChallengeError }: ChallengeRetryButtonClickHandlerType) =>
  setChallengeError(prev => !prev);

const FinancingFastCashSecondStepHandlers = ({
  challenge,
  setChallenge,
  setChallengeError,
  setError,
}: FinancingFastCashSecondStepHandlersType): FinancingFastCashSecondStepReturnHandlersType => ({
  handleChallengeError: () => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleRetryChallengeError: () => retryChallengeErrorHandler({ setChallengeError }),
});

export default FinancingFastCashSecondStepHandlers;
