// Vendors
import React from 'react';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DOCUMENTATION_CONTRACT_CATEGORY,
  DOCUMENTATION_CONTRACT_PROPS,
  DOCUMENTATION_NAME_PROPS,
  DOCUMENTATION_TYPE_PROPS,
} from '../../constants/documentation-documents-content.constants';
// Styles
import { DocumentsContentContractDataStyled } from './documentation-content-contract-data.component.styled';
// Translations
import {
  BENEFICIARY,
  CARDHOLDER,
  CONTRACT_NUMBER,
  NAME,
  TYPE,
} from '../../translations/documentation-documents-content.translations';
// Types
import { type DocumentationContentContractData } from './types/documentation-content-contract-data.component.type';

const DocumentationContentContractDataComponent = ({
  category,
  contractNumber,
  userName,
  isBeneficiary,
}: DocumentationContentContractData): React.ReactElement | null =>
  category === DOCUMENTATION_CONTRACT_CATEGORY ? (
    <DocumentsContentContractDataStyled>
      <ResumeItemComponent
        {...DOCUMENTATION_CONTRACT_PROPS}
        label={<FormattedMessageComponent id={CONTRACT_NUMBER} />}
        value={contractNumber}
      />
      <ResumeItemComponent
        {...DOCUMENTATION_NAME_PROPS}
        label={<FormattedMessageComponent id={NAME} />}
        value={userName}
      />
      <ResumeItemComponent
        {...DOCUMENTATION_TYPE_PROPS}
        label={<FormattedMessageComponent id={TYPE} />}
        value={
          isBeneficiary ? (
            <FormattedMessageComponent id={BENEFICIARY} />
          ) : (
            <FormattedMessageComponent id={CARDHOLDER} />
          )
        }
      />
    </DocumentsContentContractDataStyled>
  ) : null;

export { DocumentationContentContractDataComponent };
