import styled from 'styled-components';

export const PersonalUploadDocumentationComponentTipStyled = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spaces.spacingS};
`;
PersonalUploadDocumentationComponentTipStyled.displayName =
  'PersonalUploadDocumentationComponentTipStyled';

export const PersonalUploadDocumentationSeparatorStyled = styled.div`
  width: 100%;
  margin: ${({ theme }) => theme.spaces.spacingXs} 0;
`;
PersonalUploadDocumentationSeparatorStyled.displayName =
  'PersonalUploadDocumentationSeparatorStyled';
