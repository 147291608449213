// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import translations from '../translations/support-landing.translations';

export default {
  SUPPORT_LANDING_CHAT_CARD_PROPS: {
    description: translations.CHAT_CARD_DESCRIPTION,
    link: translations.CHAT_CARD_LINK,
    testing: {
      descriptionId: 'support-landing-card-chat-table-row-description',
      linkId: 'support-landing-card-chat-links',
      titleId: 'support-landing-card-chat-title-section',
    },
    title: translations.CHAT_CARD_TITLE,
    useTitleHeader: false,
  },
  SUPPORT_LANDING_FAQS_CARD_PROPS: {
    description: translations.FAQS_CARD_DESCRIPTION,
    link: translations.FAQS_CARD_LINK,
    testing: {
      descriptionId: 'support-landing-card-faqs-table-row-description',
      linkId: 'support-landing-card-faqs-links',
      titleId: 'support-landing-card-faqs-title-section',
    },
    title: translations.FAQS_CARD_TITLE,
    useTitleHeader: false,
  },
  SUPPORT_LANDING_END_CARD_PROPS: {
    description: translations.CANCEL_CARD_DESCRIPTION,
    link: translations.CANCEL_CARD_LINK,
    testing: {
      descriptionId: 'support-landing-card-cancel-table-row-description',
      linkId: 'support-landing-card-cancel-links',
      titleId: 'support-landing-card-cancel-title-section',
    },
    title: translations.CANCEL_CARD_TITLE,
    url: RoutesEnumeration.CANCEL_CONTRACT,
    useTitleHeader: false,
  },
};
