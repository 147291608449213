// Vendors
import React from 'react';
// Components
import {
  InputDropdownComponent,
  InputPrefixSuffixComponent,
  MessageComponent,
  ParagraphComponent,
  RadioButtonComponent,
} from '@openbank/cb-ui-commons';
import EditableInfoPanelComponent from 'containers/financing/views/credit-limit-increase/components/editable-info-panel/editable-info-panel.component';
import SectionCreditLimitIncreaseComponent from '../../components/section/section-credit-limit-increase.component';
// Configurations
import { getInfoPanelContentConfig } from './configurations/employment-data-credit-limit-increase.configuration';
// Constants
import constants from '../../constants/credit-limit-increase.component.constants';
import employmentSectionConstants from './constants/employment-data-credit-limit-increase.component.constants';
// Enums
import { ActivityTypeDeclaredEnumeration } from './enumerations/employment-status.enumeration';
// Hooks
import EmploymentDataCreditLimitIncreaseHook from './hooks/employment-data-credit-limit-increase.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Translations
import translations from './translations/employment-data-credit-limit-increase.component.translations';
// Styles
import {
  CreditLimitIncreaseFormWrapper,
  DisplayNoneStyled,
  EmploymentDataMonthAndYearStyled,
  EmploymentDataRadioButtonsStyled,
} from './employment-data-credit-limit-increase.component.styled';
import { SizeTwoInputStyled } from '../size-two-inputs.styled';
import { SizeOneInputStyled } from '../size-one-input.styled';
// Utils
import {
  getMonthOptions,
  getYearOptions,
} from './utils/employment-data-credit-limit-increase.component.utils';

const EmploymentDataCreditLimitIncreaseComponent = (): React.ReactElement => {
  const { formatMessage, formatNumber } = useCbIntl();
  const {
    activityTypeDeclared,
    isEditModeOn,
    monthJoinedCompany,
    netIncome,
    yearJoinedCompany,
    isSalariedCivilServantOrSelftEmployed,
    currency,
    showMessageInfo,
    isIosDevice,
    setIsEditModeOn,
    onClickMonthJoinedCompany,
    onSelectMonthJoinedCompany,
    onClickYearJoinedCompany,
    onSelectYearJoinedCompany,
    onChangeRadioButton,
    onClickAproximateMonthlySalary,
    onNetIncomeChange,
    onOpenAproximateMonthlySalaryTooltip,
  } = EmploymentDataCreditLimitIncreaseHook();

  return (
    <SectionCreditLimitIncreaseComponent
      title={formatMessage({ id: translations.EMPLOYMENT_DATA })}
    >
      {isEditModeOn ? (
        <CreditLimitIncreaseFormWrapper>
          <DisplayNoneStyled>
            <ParagraphComponent bold>
              {formatMessage({ id: translations.EMPLOYMENT_STATUS })}
            </ParagraphComponent>
            <EmploymentDataRadioButtonsStyled>
              {Object.values(ActivityTypeDeclaredEnumeration).map(activityTypeDeclaredEnum => (
                <RadioButtonComponent
                  key={activityTypeDeclaredEnum}
                  helperMessage={formatMessage({ id: translations[activityTypeDeclaredEnum] })}
                  value={activityTypeDeclared === activityTypeDeclaredEnum}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    onChangeRadioButton(e, activityTypeDeclaredEnum)
                  }
                />
              ))}
            </EmploymentDataRadioButtonsStyled>
          </DisplayNoneStyled>
          {isSalariedCivilServantOrSelftEmployed && (
            <EmploymentDataMonthAndYearStyled>
              <ParagraphComponent bold>
                {formatMessage({ id: translations.MONTH_AND_YEAR_JOIN_COMPANY })}
              </ParagraphComponent>
              <SizeTwoInputStyled>
                <InputDropdownComponent
                  isNotSorted
                  isIosDevice={isIosDevice}
                  placeholder={formatMessage({ id: translations.MONTH })}
                  value={monthJoinedCompany}
                  options={getMonthOptions(formatMessage)}
                  onClick={onClickMonthJoinedCompany}
                  onSelectOption={onSelectMonthJoinedCompany}
                />
                <InputDropdownComponent
                  isNotSorted
                  isIosDevice={isIosDevice}
                  placeholder={formatMessage({ id: translations.YEAR })}
                  value={yearJoinedCompany}
                  options={getYearOptions()}
                  onClick={onClickYearJoinedCompany}
                  onSelectOption={onSelectYearJoinedCompany}
                />
              </SizeTwoInputStyled>
            </EmploymentDataMonthAndYearStyled>
          )}
          <SizeOneInputStyled>
            <InputPrefixSuffixComponent
              {...constants.INPUT_PREFIX_SUFFIX_PROPS}
              label={formatMessage({ id: translations.APROXIMATE_MONTHLY_SALARY })}
              tooltipConfiguration={{
                content: formatMessage({ id: translations.APROXIMATE_MONTHLY_SALARY_TOOLTIP }),
                doWhenTooltipIsOpen: onOpenAproximateMonthlySalaryTooltip,
              }}
              value={netIncome}
              onFocus={onClickAproximateMonthlySalary}
              onChange={onNetIncomeChange}
            />
          </SizeOneInputStyled>
          {showMessageInfo && (
            <MessageComponent {...constants.MESSAGE_PROPS}>
              <ParagraphComponent bold>
                {formatMessage({ id: translations.EMPLOYMENT_STATUS_CHANGED })}
              </ParagraphComponent>
              <ParagraphComponent>
                {formatMessage({ id: translations.EMPLOYMENT_STATUS_CHANGED_TEXT })}
              </ParagraphComponent>
            </MessageComponent>
          )}
        </CreditLimitIncreaseFormWrapper>
      ) : (
        <EditableInfoPanelComponent
          key={employmentSectionConstants.CONTENT_TEST_ID}
          content={getInfoPanelContentConfig({
            activityTypeDeclared,
            monthJoinedCompany,
            yearJoinedCompany,
            netIncome,
            currency,
            formatMessage,
            formatNumber,
          })}
          onEdit={setIsEditModeOn}
        />
      )}
    </SectionCreditLimitIncreaseComponent>
  );
};

export default EmploymentDataCreditLimitIncreaseComponent;
