// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { TransactionsReportServiceCancelledAdditionalInfoActionsHandlersType } from './types/transactions-report-service-cancelled-additional-info-actions.handlers.type';
import { TransactionsReportServiceCancelledAdditionalInfoActionsHandlersReturnType } from './types/transactions-report-service-cancelled-additional-info-actions-return.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const nextButtonClickHandler = ({
  date,
  setCancellationDate,
  setCurrentStep,
}: NextButtonClickHandlerType): void => {
  setCancellationDate(date);
  setCurrentStep(NUMBER.TWO);
};

export const TransactionsReportServiceCancelledAdditionalInfoActionsHandlers = ({
  setCancellationDate,
  setCurrentStep,
}: TransactionsReportServiceCancelledAdditionalInfoActionsHandlersType): TransactionsReportServiceCancelledAdditionalInfoActionsHandlersReturnType => ({
  handleNextButtonClick: ({ date }) =>
    nextButtonClickHandler({ date, setCancellationDate, setCurrentStep }),
});
