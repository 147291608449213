// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
import { SecurityCheckModalSecondStepComponent } from './components/second-step/security-check-modal-second-step.component';
import { SecurityCheckModalFirstStepComponent } from './components/first-step/security-check-modal-first-step.component';
// Configurations
import { getModalConfiguration } from './configurations/security-check-modal.configurations';
// Enumerations
import { SecurityCheckModalStepEnumerations } from './hooks/enumerations/security-check-modal-step.enumerations';
// Hooks
import { SecurityCheckModalHook } from './hooks/security-check-modal.hook';
// Types
import { SecurityCheckModalComponentType } from './types/security-check-modal.component.type';

const SecurityCheckModalComponent = ({
  challenge,
  challengeError,
  handleFetchCardTransactions,
  formValuesParams,
  metadata,
  setChallenge,
  setChallengeError,
  setError,
  setShowSecurityCheckModal,
  showSecurityCheckModal,
  serviceConfiguration,
}: SecurityCheckModalComponentType): React.ReactElement | null => {
  const {
    cardId,
    formatMessage,
    handleChallengeError,
    handleChallengeSuccess,
    handleRetryChallengeError,
    handleOnCloseModal,
    handleNextModalStep,
    modalStep,
  } = SecurityCheckModalHook({
    challenge,
    setChallenge,
    setChallengeError,
    setError,
    setShowSecurityCheckModal,
    showSecurityCheckModal,
  });

  return showSecurityCheckModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...getModalConfiguration({
        formatMessage,
        challenge,
        handleOnCloseModal,
        modalStep,
        handleFetchCardTransactions,
        handleNextModalStep,
      })}
    >
      {modalStep === SecurityCheckModalStepEnumerations.FIRST ? (
        <SecurityCheckModalFirstStepComponent />
      ) : (
        <SecurityCheckModalSecondStepComponent
          {...{
            cardId,
            challengeError,
            formValuesParams,
            handleChallengeError,
            handleChallengeSuccess,
            handleRetryChallengeError,
            metadata,
            serviceConfiguration,
          }}
        />
      )}
    </ModalComponent>
  ) : null;
};

export { SecurityCheckModalComponent };
