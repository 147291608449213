// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { TagComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_STATUS_TAG_TYPES } from './constants/card-status-tag.constants';
// Hooks
import useTitleSectionStatusHook from './hooks/card-status-tag.hook';
// Styles
import { CardStatusTagComponentStyled } from './card-status-tag.component.styled';
// Translations
import { CARD_STATUS_LABELS } from './translations/card-status-tag.translations';
// Utilities
import { cardActive } from 'utils/card.utils';

const CardStatusTagComponent = (): React.ReactElement | null => {
  const { status } = useTitleSectionStatusHook();

  return !cardActive(status) ? (
    <CardStatusTagComponentStyled>
      <TagComponent type={CARD_STATUS_TAG_TYPES[status]}>
        <FormattedMessageComponent id={CARD_STATUS_LABELS[status]} />
      </TagComponent>
    </CardStatusTagComponentStyled>
  ) : null;
};

export default CardStatusTagComponent;
