// Vendors
import React from 'react';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CARD_SETTINGS_CHECK_3D_CONFIRMATION_FOOTER_LINK_PROPS } from './constants/card-settings-check-3d-confirmation-footer.constants';
// Hooks
import useCardSettingsCheck3dConfirmationFooterHook from 'containers/card-settings/views/check-3d/components/confirmation/components/footer/hooks/card-settings-check-3d-confirmation-footer.hook';
// Translations
import { LINK } from './translations/card-settings-check-3d-confirmation-message.translations';

const CardSettingsCheck3dConfirmationFooterComponent = (): React.ReactElement => {
  const { handleGoBackToCardSettingsLandingLinkClick } = useCardSettingsCheck3dConfirmationFooterHook();

  return (
    <NavigationFooterComponent
      rightLinkConfiguration={{
        ...CARD_SETTINGS_CHECK_3D_CONFIRMATION_FOOTER_LINK_PROPS,
        children: <FormattedMessageComponent id={LINK} />,
        onClick: handleGoBackToCardSettingsLandingLinkClick,
      }}
    />
  );
};

export default CardSettingsCheck3dConfirmationFooterComponent;
