// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalPhotoNavigationFooterBuilderReturnTrackingHandlersType } from './types/personal-photo-navigation-footer-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const cancelUploadPhotoLinkClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancel',
  },
  type: TrackingEventsEnum.LINK,
});

const saveUploadPhotoButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'save',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalPhotoNavigationFooterTrackingHandlers = (
  track: TrackBuilderType
): PersonalPhotoNavigationFooterBuilderReturnTrackingHandlersType => ({
  handleCancelUploadPhotoLinkClickTracking: () => track(cancelUploadPhotoLinkClickTracking()),
  handleSaveUploadPhotoButtonClickTracking: () => track(saveUploadPhotoButtonClickTracking()),
});

export default PersonalPhotoNavigationFooterTrackingHandlers;
