// Vendors
import { createTagAndsDispatchFile } from '@openbank/cf-ui-framework';
// Resolvers
import { getInsuranceContractResolver } from 'containers/financing/views/ppi/resolvers/financing-insurances.resolvers';
// Translations
import { DOWNLOAD_DOCUMENT_ERROR_MESSAGE } from 'containers/financing/views/ppi/components/contracting/lpi/components/step3/components/download/translations/financing-insurances-contracting-lpi-step3-download.translations';
import { DOWNLOAD_CONTRACT_SUCCESS_MESSAGE } from '../translations/financing-insurances-contracting-lpi-step4-download.translations';
// Types
import { FinancingInsurancesContractingLPIStep4DownloadHandlersType } from './types/financing-insurances-contracting-lpi-step4-download.handlers.type';
import { FinancingInsurancesContractingLPIStep4DownloadReturnHandlersType } from './types/financing-insurances-contracting-lpi-step4-download-return.handlers.type';
// Utils
import { setupToastConfiguration } from 'components/toast/utils/toast.utils';

const downloadLinkClickHandler = async ({
  handleToggleDownloaded,
  plan,
  productId,
  productType,
  setDownloading,
  setToastConfiguration,
}: FinancingInsurancesContractingLPIStep4DownloadHandlersType): Promise<void> => {
  try {
    setDownloading(true);

    if (!productId || !productType) {
      throw new Error();
    }

    const [blob, error] = await getInsuranceContractResolver({
      insuranceType: 'LPI',
      planCode: plan,
      productContractId: productId,
      productContractType: productType,
    });

    if (error || !blob) {
      throw new Error();
    }

    createTagAndsDispatchFile({ blob, filename: 'LifeStyle Versicherung.pdf' });
    handleToggleDownloaded();
    setToastConfiguration(
      setupToastConfiguration({ error: false, description: DOWNLOAD_CONTRACT_SUCCESS_MESSAGE })
    );
  } catch (_) {
    setToastConfiguration(
      setupToastConfiguration({ error: true, description: DOWNLOAD_DOCUMENT_ERROR_MESSAGE })
    );
  } finally {
    setDownloading(false);
  }
};

export const FinancingInsurancesContractingLPIStep4DownloadHandlers = (
  props: FinancingInsurancesContractingLPIStep4DownloadHandlersType
): FinancingInsurancesContractingLPIStep4DownloadReturnHandlersType => ({
  handleDownloadLinkClick: () => downloadLinkClickHandler(props),
});
