// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from './configurations/request-access-step2-challenge.component.configurations';
// Hooks
import RequestAccessStep2ChallengeHook from './hooks/request-access-step2-challenge.hook';
// Styles
import { RequestAccessStep2ChallengeComponentStyled } from './request-access-step2-challenge.component.styled';

const RequestAccessStep2ChallengeComponent = (): React.ReactElement => {
  const {
    error,
    handleManageErrorOnRequestAccessEvent,
    handleManageSuccessOnRequestAccessEvent,
    handleChallengeRetryButtonClick,
  } = RequestAccessStep2ChallengeHook();

  return (
    <RequestAccessStep2ChallengeComponentStyled>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          challengeError: error,
          handleChallengeError: handleManageErrorOnRequestAccessEvent,
          handleChallengeSuccess: handleManageSuccessOnRequestAccessEvent,
          handleChallengeRetryButtonClick,
        })}
      />
    </RequestAccessStep2ChallengeComponentStyled>
  );
};

export default RequestAccessStep2ChallengeComponent;
