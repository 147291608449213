// Enumerations
import { ActivityTypeDeclaredEnumeration } from '../../components/step2/sections/employment-data/enumerations/employment-status.enumeration';
import { ChildrenOptionsEnumeration } from '../../components/step2/sections/personal-situation/enumerations/children-options.enumeration';
import { MaritalStatusEnumeration } from '../../components/step2/sections/personal-situation/enumerations/marital-status.enumeration';
import { MonthsEnumeration } from 'enumerations/months.enumeration';
import { CountriesLocaleEnum } from '@openbank/cf-ui-static-data';
// Types
import { DateUtilsPropsType } from './types/step-2-laboral-economic-data.type';
import { PersonalDataType } from '../../contexts/types/personal-data-step-2.type';
import { PersonalDataLocalType } from '../../contexts/types/personal-data-local-step-2.type';
import { EmploymentType } from '../../contexts/types/employment-step-2.type';

const validateDate = ({ month, year }: DateUtilsPropsType): boolean => {
  return !!month && !!year && month >= 1 && month <= 12;
};

export const getMonth = (month: MonthsEnumeration | undefined): number | undefined => {
  if (!month) return undefined;
  return Object.values(MonthsEnumeration).indexOf(month) + 1;
};

export const getDateFrom = ({ month, year }: DateUtilsPropsType): string | undefined => {
  if (!validateDate({ month, year })) {
    return undefined;
  }
  const monthIndex = month && month <= 9 ? `0${month}` : `${month}`;
  return new Date(`${String(year)}-${String(monthIndex)}-01`).toISOString();
};

export const mapLocalToPersonalData = (
  personalDataLocal: PersonalDataLocalType
): PersonalDataType => {
  const employment1 = personalDataLocal?.employments?.[0];

  const employmentsData = [
    {
      ...employment1,
      employmentStartDate: getDateFrom({
        month: getMonth(employment1?.monthJoinedCompany),
        year: employment1?.yearJoinedCompany,
      }),
    },
  ];
  delete employmentsData[0].monthJoinedCompany;
  delete employmentsData[0].yearJoinedCompany;

  return {
    ...personalDataLocal,
    employments: employmentsData as EmploymentType[],
  };
};

const getMonthFromDate = (date: string | undefined): MonthsEnumeration | undefined => {
  if (date) {
    const dDate = new Date(date);
    const month = dDate.getMonth();
    const keys = Object.keys(MonthsEnumeration);
    return keys.length > 0 ? MonthsEnumeration[keys[month]] : undefined;
  }
  return undefined;
};

const getYearFromDate = (date: string | undefined): number | undefined => {
  if (date) {
    const dDate = new Date(date);
    return dDate.getFullYear();
  }
  return undefined;
};

const getChildrenOption = (enumValue: string): ChildrenOptionsEnumeration | undefined => {
  const keys = Object.keys(ChildrenOptionsEnumeration).filter(
    x => ChildrenOptionsEnumeration[x] === enumValue
  );
  return keys.length > 0 ? ChildrenOptionsEnumeration[keys[0]] : undefined;
};

export const mapPersonalDataToLocal = (personalData: PersonalDataType): PersonalDataLocalType => {
  const { activityTypeDeclared, maritalStatus, employments } = personalData;

  const employment1 = employments?.[0];
  const employmentsLocal = [
    {
      ...employment1,
      childrenInHousehold:
        employment1?.childrenInHousehold !== undefined
          ? getChildrenOption(String(employment1?.childrenInHousehold))
          : undefined,
      childrenWithMaintenance:
        employment1?.childrenWithMaintenance !== undefined
          ? getChildrenOption(String(employment1?.childrenWithMaintenance))
          : undefined,
      monthJoinedCompany: getMonthFromDate(employment1?.employmentStartDate),
      yearJoinedCompany: getYearFromDate(employment1?.employmentStartDate),
    },
  ];
  delete employmentsLocal[0].employmentStartDate;

  return {
    activityTypeDeclared: activityTypeDeclared
      ? ActivityTypeDeclaredEnumeration[activityTypeDeclared]
      : undefined,
    maritalStatus: maritalStatus ? MaritalStatusEnumeration[maritalStatus] : undefined,
    employments: employmentsLocal,
  };
};
