// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Types
import { ApiUtilsReturnType, Validator } from './types/validate-type.types';
// Utils
import { checkValidation } from './utils/validate-type.util';
// Validators
import { validateType } from './validate-type';

const validateApiType: <T>(
  apiPromisedResponse: Promise<ApiUtilsReturnType<T>>,
  validator: Validator<T>
) => Promise<ApiUtilsReturnType<T>> = async (apiPromisedResponse, validator) => {
  const apiResponse = await apiPromisedResponse;
  if (!httpStatusUtils.isSuccessStatus(apiResponse[1].status)) {
    return apiResponse;
  }

  const validation = validateType(apiResponse[0], validator);
  if (checkValidation(validation)) {
    return apiResponse;
  }

  return [apiResponse[0], { status: 418 } as Response];
};

export { validateApiType };
