import {
  isArray,
  isBoolean,
  isDate,
  isNumber,
  isObject,
  isUnion,
  isString,
  optional,
  validateType,
} from './validate-type';
import { validateApiType } from './validate-api-type';

export default validateApiType;
export {
  isArray,
  isBoolean,
  isDate,
  isNumber,
  isObject,
  isUnion,
  isString,
  optional,
  validateType,
};
