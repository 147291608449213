// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  TITLE,
} from '../translations/financing-ppi-contracting-step2-expandable-terminate.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_TERMINATE_PROPS = {
  description01: DESCRIPTION_01,
  description02: DESCRIPTION_02,
  title: TITLE,
};

export { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_TERMINATE_PROPS };
