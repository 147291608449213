// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';
// Types
import { ExpiredDocumentModalHandlersPropsType } from './types/expired-document-modal-props.handlers.type';
import { ExpiredDocumentModalHandlersReturnType } from './types/expired-document-modal-return.handlers.type';

const updateDocumentNavigationHandler = ({
  navigate,
}: Pick<ExpiredDocumentModalHandlersPropsType, 'navigate'>) => {
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.PERSONAL_INFORMATION, RoutesEnumeration.DOCUMENTATION])
    )
  );
};

const clickCloseModalHandler = ({
  setIsExpiredDocumentModalOpen,
}: Pick<ExpiredDocumentModalHandlersPropsType, 'setIsExpiredDocumentModalOpen'>) => {
  setIsExpiredDocumentModalOpen(false);
};

const ExpiredDocumentModalHandlers = (
  props: ExpiredDocumentModalHandlersPropsType
): ExpiredDocumentModalHandlersReturnType => ({
  handleClickCloseModal: () => clickCloseModalHandler(props),
  handleUpdateDocumentNavigation: () => updateDocumentNavigationHandler(props),
});

export { ExpiredDocumentModalHandlers };
