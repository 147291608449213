// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import CardSettingsActivateCardChallengeComponent from './components/challenge/card-settings-activate-card-challenge.component';
import CardSettingsActivateCardSidebarComponent from './components/sidebar/card-settings-activate-card-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import constants from './constants/card-settings-activate-card.constants';
// Contexts
import { CardSettingsActivateCardContextConsumerHOC } from './contexts/card-settings-activate-card.context';
// Hooks
import CardSettingsActivateCardHook from './hooks/card-settings-activate-card.hook';
// Translations
import translations from './translations/card-settings-activate-card.translations';

const CardSettingsActivateCardComponent = (): React.ReactElement => {
  const { error, linkLabel, formatMessage } = CardSettingsActivateCardHook();

  return (
    <OperativeLayoutComponent sidebarComponent={CardSettingsActivateCardSidebarComponent}>
      <FetchErrorComponent
        {...constants.FETCH_ERROR_PROPS}
        error={getFetchErrorConfiguration({
          error,
          formatMessage,
          translations: translations.ERROR,
        })}
      >
        <WizardFeedbackComponent
          successConfiguration={{
            ...constants.ACTIVATE_CARD_SUCCESS_FEEDBACK_PROPS,
            linkValues: { page: formatMessage({ id: linkLabel }) },
          }}
          errorConfiguration={constants.ACTIVATE_CARD_ERROR_FEEDBACK_PROPS}
        >
          <CardSettingsActivateCardChallengeComponent />
        </WizardFeedbackComponent>
      </FetchErrorComponent>
    </OperativeLayoutComponent>
  );
};

export { CardSettingsActivateCardComponent };

export default CardSettingsActivateCardContextConsumerHOC(CardSettingsActivateCardComponent);
