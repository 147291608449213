// Vendors
import React, { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
// Components
import OrchestratorRouterContainerErrorComponent from 'containers/orchestrator/components/router/components/containers/components/error-load/orchestrator-router-container-error.component';
// Containers
const CardSettingsContainer = lazy(
  () => import('containers/card-settings/card-settings.container')
);
const DashboardContainer = lazy(() => import('containers/dashboard/dashboard.container'));
const NotificationsContainer = lazy(
  () => import('containers/notifications/notifications.container')
);
import { FinancingContainer } from 'containers/financing/financing.container';
import { DocumentationContainer } from 'containers/documentation/documentation.container';
import { PackagesContainer } from 'containers/packages/packages.container';
const IbanBillingContainer = lazy(() => import('containers/iban-billings/iban-billing.container'));
const PersonalContainer = lazy(() => import('containers/personal/personal.container'));
const PointsContainer = lazy(() => import('containers/points/points.container'));
const SupportContainer = lazy(() => import('containers/support/support.container'));
const OtpContainer = lazy(() => import('containers/otp/otp.container'));
const SecurityKeysContainer = lazy(
  () => import('containers/security-keys/security-keys.container')
);
const TransactionsContainer = lazy(() => import('containers/transactions/transactions.container'));
// Constants
import {
  ANY_NAVIGATE_ROUTE_PROPS,
  ANY_ROUTE_PROPS,
  CARDS_SETTINGS_ROUTE_PROPS,
  DASHBOARD_ROUTE_PROPS,
  FINANCING_ROUTE_PROPS,
  IBAN_BILLING_ROUTE_PROPS,
  OTP_ROUTE_PROPS,
  PERSONAL_ROUTE_PROPS,
  POINTS_ROUTE_PROPS,
  SECURITY_KEYS_ROUTE_PROPS,
  SUPPORT_ROUTE_PROPS,
  TRANSACTIONS_ROUTE_PROPS,
  NOTIFICATIONS_ROUTE_PROPS,
  DOCUMENTATION_ROUTE_PROPS,
  PACKAGE_ROUTE_PROPS,
  LOWER_NAVIGATION_ID,
} from 'containers/orchestrator/components/router/components/containers/constants/orchestrator-router-containers.constants';
// Styles
import {
  OrchestratorRouterContainersComponentStyled,
  LowerNavigationComponentStyled,
} from './orchestrator-router-containers.component.styled';
// Utilities
import { currentPageIsDashboard } from 'containers/orchestrator/components/router/components/containers/utils/orchestrator-router-containers.utils';
import FiltersComponent from 'components/filters/filters-component';

const OrchestratorRouterContainersComponent = (): React.ReactElement => (
  <>
    <OrchestratorRouterContainersComponentStyled isDashboard={currentPageIsDashboard()}>
      <OrchestratorRouterContainerErrorComponent>
        <Routes>
          <Route {...NOTIFICATIONS_ROUTE_PROPS} element={<NotificationsContainer />} />
          <Route {...CARDS_SETTINGS_ROUTE_PROPS} element={<CardSettingsContainer />} />
          <Route {...DASHBOARD_ROUTE_PROPS} element={<DashboardContainer />} />
          <Route
            {...DOCUMENTATION_ROUTE_PROPS}
            element={
              <FiltersComponent filterId="notification">
                <DocumentationContainer />
              </FiltersComponent>
            }
          />
          <Route {...FINANCING_ROUTE_PROPS} element={<FinancingContainer />} />
          <Route {...IBAN_BILLING_ROUTE_PROPS} element={<IbanBillingContainer />} />
          <Route {...PACKAGE_ROUTE_PROPS} element={<PackagesContainer />} />
          <Route {...POINTS_ROUTE_PROPS} element={<PointsContainer />} />
          <Route {...PERSONAL_ROUTE_PROPS} element={<PersonalContainer />} />
          <Route {...OTP_ROUTE_PROPS} element={<OtpContainer />} />
          <Route {...SUPPORT_ROUTE_PROPS} element={<SupportContainer />} />
          <Route {...SECURITY_KEYS_ROUTE_PROPS} element={<SecurityKeysContainer />} />
          <Route {...TRANSACTIONS_ROUTE_PROPS} element={<TransactionsContainer />} />
          <Route {...ANY_ROUTE_PROPS} element={<Navigate {...ANY_NAVIGATE_ROUTE_PROPS} />} />
        </Routes>
      </OrchestratorRouterContainerErrorComponent>
    </OrchestratorRouterContainersComponentStyled>
    <LowerNavigationComponentStyled id={LOWER_NAVIGATION_ID} />
  </>
);

export default OrchestratorRouterContainersComponent;
