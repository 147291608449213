const DOMAIN = 'cobranded.services.userSettings.privateArea.documentation.documentationLanding.';
const DOMAIN_CATEGORY_ERROR =
  'cobranded.services.userSettings.privateArea.documentation.documentationLanding.error.';
const DOMAIN_CATEGORY_EMPTY =
  'cobranded.services.userSettings.privateArea.documentation.documentationLanding.empty.';

const PAGE_TITLE = `${DOMAIN}title`;
const ERROR = {
  ERROR_TITLE: `${DOMAIN_CATEGORY_ERROR}title`,
  ERROR_DESCRIPTION: `${DOMAIN_CATEGORY_ERROR}description`,
  BUTTON: `${DOMAIN_CATEGORY_ERROR}button`,
};
const EMPTY = {
  ERROR_TITLE: `${DOMAIN_CATEGORY_EMPTY}title`,
  ERROR_DESCRIPTION: `${DOMAIN_CATEGORY_EMPTY}description`,
};

export { PAGE_TITLE, EMPTY, ERROR };
