// Constants
import { buttonConstants, paragraphConstants, titleConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from 'containers/login/views/error/translations/login-error.translations';

export default {
  LOGIN_ERROR_LINKS_PROPS: {
    links: [
      {
        href: '/login',
        testId: 'login-error-links-1',
        text: translations.LINK_1,
      },
      {
        href: '/login',
        testId: 'login-error-links-2',
        text: translations.LINK_2,
      },
      {
        href: '/login',
        testId: 'login-error-links-3',
        text: translations.LINK_3,
      },
    ],
  },
  LOGIN_ERROR_DESCRIPTION_PROPS: {
    testId: 'login-error-table-row-description',
    size: paragraphConstants.SIZES.M,
  },
  LOGIN_ERROR_TITLE_PROPS: {
    testId: 'login-error-title',
    tag: titleConstants.TAGS.H3,
  },
  LOGIN_ERROR_BUTTON_PROPS: {
    size: buttonConstants.SIZES.BIG,
    testId: 'login-error-links',
    type: buttonConstants.TYPES.PRIMARY,
  },
};
