// Services
import { updateConsentsService } from '../services/profile-consents-step2.service';
// Types
import { UpdateConsentsPropsType } from '../services/types/update-consents-props.type';
// Utilities
import { isErrorStatus } from './utils/profile-consents-step2.resolvers.utils';
import { ChallengeType } from 'types/challenge.type';

export const updateConsentsResolver = async (
  params: UpdateConsentsPropsType
): Promise<[ChallengeType | Record<string, unknown>, number | null]> => {
  const [response, { status }] = await updateConsentsService(params);

  if (isErrorStatus(status, params.progressId)) {
    return [{}, status];
  }

  return [response, null];
};
