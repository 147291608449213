const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.additional.limitations.';

const TITLE = `${DOMAIN}title`;

const DESCRIPTION = `${DOMAIN}description`;

const INCAPACITY = {
  title: `${DOMAIN}incapacity.title`,
  firstLimitation: `${DOMAIN}incapacity.firstLimitation`,
  secondLimitation: `${DOMAIN}incapacity.secondLimitation`,
};

const UNEMPLOYMENT = {
  title: `${DOMAIN}unemployment.title`,
  firstLimitation: `${DOMAIN}unemployment.firstLimitation`,
  secondLimitation: `${DOMAIN}unemployment.secondLimitation`,
};

const ILLNESS = {
  title: `${DOMAIN}illness.title`,
  firstLimitation: `${DOMAIN}illness.firstLimitation`,
};

export { DESCRIPTION, TITLE, INCAPACITY, UNEMPLOYMENT, ILLNESS };
