// Vendors
import { useEffect, useState } from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Enums
import { MonthsEnumeration } from 'enumerations/months.enumeration';
// Handlers
import EmploymentDataHandlers from '../handlers/employment-data-credit-limit-increase.component.handler';
// Trackings
import Step2CreditLimitChageTracking from 'containers/financing/views/credit-limit-increase/components/step2/trackings/step-2-credit-limit-increase.tracking';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { EmploymentDataCreditLimitIncreaseHookType } from './types/employment-data-credit-limit-increase.component.hook.type';
import { ActivityTypeDeclaredEnumeration } from '../enumerations/employment-status.enumeration';
// Utils
import { setEmploymentData } from 'containers/financing/views/credit-limit-increase/components/step2/utils/step-2-credit-limit-increase.utils';

const EmploymentDataCreditLimitIncreaseHook = (): EmploymentDataCreditLimitIncreaseHookType => {
  const [firstActivityTypeDeclared, setFirstActivityTypeDeclared] = useState<
    ActivityTypeDeclaredEnumeration | undefined
  >(undefined);
  const { currentCard } = AppContextConsumerHook();
  const { currencyCode } = currentCard || {};
  const { step2, setStep2 } = CreditLimitIncreaseContextConsumerHook();
  const { activityTypeDeclared, employments } = step2.personalData;
  const { netIncome, monthJoinedCompany, yearJoinedCompany } = employments?.[0] || {};

  const {
    handleClickAproximateMonthlySalaryTracking,
    handleOpenAproximateMonthlySalaryTooltipTracking,
    handleSelectEmploymentStatusTracking,
    handleClickMonthJoinedCompanyTracking,
    handleSelectMonthJoinedCompanyTracking,
    handleClickYearJoinedCompanyTracking,
    handleSelectYearJoinedCompanyTracking,
  } = AppTrackingHook(Step2CreditLimitChageTracking);
  const { handleUpdateStatusAndStep2, handleOnChangeRadioButton, handleOnChangeNetIncome } =
    EmploymentDataHandlers({
      step2,
      handleSelectEmploymentStatusTracking,
      setStep2,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleUpdateStatusAndStep2, [step2.personalData]);
  useEffect(() => {
    if (!firstActivityTypeDeclared) {
      setFirstActivityTypeDeclared(activityTypeDeclared);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityTypeDeclared]);

  return {
    isEditModeOn: step2.isEmploymentDataEditModeOn ?? true,
    activityTypeDeclared,
    monthJoinedCompany,
    netIncome: netIncome ?? '',
    yearJoinedCompany,
    isSalariedCivilServantOrSelftEmployed:
      step2?.personalData?.activityTypeDeclared === ActivityTypeDeclaredEnumeration.EMPLOYED ||
      step2?.personalData?.activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED,
    currency: currencyCode,
    showMessageInfo: activityTypeDeclared !== firstActivityTypeDeclared,
    isIosDevice: isIosWebView(),
    onClickMonthJoinedCompany: handleClickMonthJoinedCompanyTracking,
    onSelectMonthJoinedCompany: (value: MonthsEnumeration) => {
      setEmploymentData({ setStep2, step2, values: { monthJoinedCompany: value } });
      handleSelectMonthJoinedCompanyTracking();
    },
    onClickYearJoinedCompany: handleClickYearJoinedCompanyTracking,
    onSelectYearJoinedCompany: (value: number) => {
      setEmploymentData({ setStep2, step2, values: { yearJoinedCompany: value } });
      handleSelectYearJoinedCompanyTracking();
    },
    onNetIncomeChange: (value: string) => handleOnChangeNetIncome(value),
    onOpenAproximateMonthlySalaryTooltip: handleOpenAproximateMonthlySalaryTooltipTracking,
    onClickAproximateMonthlySalary: handleClickAproximateMonthlySalaryTracking,
    onChangeRadioButton: handleOnChangeRadioButton,
    setIsEditModeOn: () => setStep2({ ...step2, isEmploymentDataEditModeOn: true }),
  };
};

export default EmploymentDataCreditLimitIncreaseHook;
