// Vendors
import React from 'react';
// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
  LayoutGridComponentStyled,
} from 'styles/app.styled';

const PointsContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <TitleSectionComponent />
    <AppCardSectionComponentStyled>
      <AppCardContentSectionComponentStyled>
        <ErrorComponent
          assetConfiguration={{
            id: 'Working_on_it_Alternative',
            sizes: { height: 110, width: 110 },
            type: 'illustration',
          }}
          description="We are creating this component. Please be patient. Thank you."
          title="Work in progress"
        />
      </AppCardContentSectionComponentStyled>
    </AppCardSectionComponentStyled>
  </LayoutGridComponentStyled>
);

export default PointsContainer;
