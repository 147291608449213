// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Configurations
import { STEPS } from './configurations/report-defective.configurations';
// Context
import { ReportDefectiveContextConsumerHOC } from './contexts/report-defective.context';

const ReportDefectiveBaseContainer = (): React.ReactElement => (
  <TransactionsReportWizardComponent steps={STEPS} />
);

const ReportDefectiveContainer = ReportDefectiveContextConsumerHOC(ReportDefectiveBaseContainer);

export { ReportDefectiveContainer };
