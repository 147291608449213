// Resolver
import { signatureKeyChangeCheckpointResolver } from '../../challenge/resolvers/security-keys-signature-key-change-third-step.resolvers';
// Types
import { SetupFinishActionsEventHandlerParamsType } from '../hook/types/security-keys-signature-key-change-third-step-actions-setup-finish-handlers.type';
import { SignatureKeyChangeStep3ActionsHandlersReturnType } from './types/security-keys-signature-key-change-third-step-actions-handlers-return.type';
import { SignatureKeyChangeStep3ActionsHandlersParamsType } from './types/security-keys-signature-key-change-third-step-actions-handlers.type';

const setupFinishActionsEventHandler = async ({
  signatureKey,
  handleNextButtonClickTrackingHandlerStep3,
  newSignatureKey,
  progressId,
  setFetching,
  setSignatureKey,
  setNewSignatureKey,
  setRepeatNewSignatureKey,
  setResult,
  setProgressId,
}: SetupFinishActionsEventHandlerParamsType): Promise<void> => {
  setFetching(true);
  handleNextButtonClickTrackingHandlerStep3();

  const [response, error] = await signatureKeyChangeCheckpointResolver({
    signatureKey,
    newSignatureKey,
    progressId,
  });

  setResult(Boolean(response && !error));
  setSignatureKey('');
  setNewSignatureKey('');
  setProgressId('');
  setRepeatNewSignatureKey('');
  setFetching(false);
};

const SignatureKeyChangeStep3ActionsHandlers = (
  props: SignatureKeyChangeStep3ActionsHandlersParamsType
): SignatureKeyChangeStep3ActionsHandlersReturnType => ({
  handleFinishActionsEvent: () => setupFinishActionsEventHandler(props),
});

export default SignatureKeyChangeStep3ActionsHandlers;
