// Vendors
import React from 'react';
// Components
import SettingsCardControlToggleComponent from 'containers/card-settings/views/card-control/components/toggle/settings-card-control-toggle.component';
// Constants
import constants from './constants/settings-card-control-location-toggle.constants';
// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Types
import { SettingsCardControlToggleComponentType } from './types/settings-card-control-toggle.component.type';
// Utilities
import {
  addOrRemoveCurrentCountry,
  getCurrentCountryValue,
} from './utils/setting-card-control-locations-toggle.utils';

const SettingsCardControlLocationsToggleComponent = ({
  countryId,
  mainCountryId,
}: SettingsCardControlToggleComponentType): React.ReactElement<SettingsCardControlToggleComponentType> => {
  const { continents } = SettingsCardControlLocationsContextConsumerHook();

  return (
    <SettingsCardControlToggleComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_TOGGLE_PROPS}
      toggleId={countryId ?? mainCountryId}
      checked={getCurrentCountryValue({ continents, countryId, mainCountryId })}
      value={addOrRemoveCurrentCountry({ continents, countryId, mainCountryId })}
    />
  );
};

export default SettingsCardControlLocationsToggleComponent;
