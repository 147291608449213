// Vendors
import {
  MiscellaneousCurrenciesEnum,
  MiscellaneousCurrencyConstants,
} from '@openbank/cf-ui-static-data';
// Constants
import {
  TRANSACTION_EPP_SIMULATION_SUMMARY_PLACEHOLDER_CURRENCY,
  TRANSACTION_EPP_SIMULATION_SUMMARY_PLACEHOLDER_ITEM,
} from './constants/transactions-epp-step1-simulation-summary-item.utils.constants';
// Enumerations
// Types
import {
  TransactionsEppStep1SimulationSummaryItemCurrenciesUtilsType,
  TransactionsEppStep1SimulationSummaryItemPlaceholderUtilsType,
  TransactionsEppStep1SimulationSummaryItemUtilsType,
} from './types/transactions-epp-step1-simulation-summary-item.utils.type';
// Utilities
import { simulationPlanIsNotDone } from 'containers/transactions/views/epp/components/step1/utils/transactions-epp-step1.utils';

const getEppInstallmentPlanSummaryDataPlaceholder = ({
  currency,
  formatNumber,
}: TransactionsEppStep1SimulationSummaryItemPlaceholderUtilsType): string =>
  TRANSACTION_EPP_SIMULATION_SUMMARY_PLACEHOLDER_ITEM.replace(
    TRANSACTION_EPP_SIMULATION_SUMMARY_PLACEHOLDER_CURRENCY,
    formatNumber(Number(undefined), { currency }).replace(/NaN/g, '').trim()
  );

const getTransactionsDetailsCurrencyOrPlanSimulationCurrency = ({
  eppPlanCurrency,
  transactionCurrency,
}: TransactionsEppStep1SimulationSummaryItemCurrenciesUtilsType): string =>
  eppPlanCurrency || transactionCurrency;

const getEppSimulationDataOrPlaceholder = ({
  currencyCode: transactionCurrency,
  formatNumber,
  id,
  eppPlanSimulation: { currencyCode: eppPlanCurrency, ...simulation },
}: TransactionsEppStep1SimulationSummaryItemUtilsType): string => {
  const currency = getTransactionsDetailsCurrencyOrPlanSimulationCurrency({
    eppPlanCurrency,
    transactionCurrency,
  });

  return simulationPlanIsNotDone(simulation)
    ? getEppInstallmentPlanSummaryDataPlaceholder({ currency, formatNumber })
    : formatNumber(Number(simulation[id]), {
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
        currency,
      });
};

export { getEppSimulationDataOrPlaceholder };
