// Vendors
import React from 'react';
// Components
import { ParagraphComponent, InputTextComponent, LinkComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getInputAliasConfiguration } from './configurations/device-header-alias.configurations';
// Constants
import { DEVICE_HEADER_ALIAS_PROPS } from './constants/device-header-alias.constants';
import { EDIT_ICON_PROPS } from '../../constants/device-header.constants';
// Hooks
import { DeviceHeaderAliasHook } from './hooks/device-header-alias.hook';
// Styles
import {
  DeviceHeaderAliasInputComponentStyled,
  DeviceHeaderAliasLinkComponentStyled,
} from './device-header-alias.component-styled';
// Types
import { DeviceHeaderAliasType } from './types/device-header-alias.type';

const DeviceHeaderAliasComponent = ({
  alias,
  deviceUUID,
}: DeviceHeaderAliasType): React.ReactElement => {
  const {
    name,
    error,
    editing,
    success,
    loading,
    onEditClickHandler,
    onSaveClickHandler,
    onInputChangeHandler,
    onInputFocus,
    inputRef,
    formatMessage,
  } = DeviceHeaderAliasHook({ alias, deviceUUID });

  return (
    <>
      {editing ? (
        <DeviceHeaderAliasInputComponentStyled ref={inputRef}>
          <InputTextComponent
            {...getInputAliasConfiguration({
              formatMessage,
              success,
              error,
              loading,
              onChange: onInputChangeHandler,
              onClick: onSaveClickHandler,
              onFocus: onInputFocus,
            })}
            value={name}
          />
        </DeviceHeaderAliasInputComponentStyled>
      ) : (
        <DeviceHeaderAliasLinkComponentStyled>
          <ParagraphComponent {...DEVICE_HEADER_ALIAS_PROPS}>{name}</ParagraphComponent>
          <LinkComponent onClick={onEditClickHandler} assetConfiguration={{ ...EDIT_ICON_PROPS }} />
        </DeviceHeaderAliasLinkComponentStyled>
      )}
    </>
  );
};

export { DeviceHeaderAliasComponent };
