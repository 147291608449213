// Constants
import constants from './constants/profile-personal-name-alias-input.utils.constants';
// Types
import { ProfilePersonalNameAliasInputUtilsType } from './types/profile-personal-name-alias-input.utils.type';

const isValidAlias = (alias: string): boolean =>
  alias?.length > 0 && new RegExp(constants.ALIAS_REGEX).test(alias);

const currentAliasIsSameAsNewAlias = ({
  alias,
  newAlias,
}: Pick<ProfilePersonalNameAliasInputUtilsType, 'alias' | 'newAlias'>): boolean =>
  alias === newAlias;

const saveNewAliasLinkIsDisabled = ({
  alias,
  fetching,
  error,
  newAlias,
}: ProfilePersonalNameAliasInputUtilsType): boolean =>
  Boolean(
    fetching ||
      error ||
      currentAliasIsSameAsNewAlias({ alias, newAlias }) ||
      !isValidAlias(newAlias)
  );

export { isValidAlias, saveNewAliasLinkIsDisabled };
