// Vendors
import React from 'react';
import { LinkComponent } from '@openbank/cb-ui-commons';
// Components
import { OrderModalOrchestratorComponent } from './components/modal/order-modal-orchestrator.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  ORDER_LINK_PROPS,
  ORDER_MODAL_ORCHESTRATOR_PROPS,
} from './constants/financing-landing-row-details-orders-link.component.constants';
// Hooks
import { FinancingLandingRowDetailsOrdersHooks } from './hooks/financing-landing-row-details-orders.hook';
// Translations
import { ORDERS_LINK } from './translations/financing-landing-row-details-order.translations';
// Types
import { OrderLinkPropsType } from './types/financing-landing-row-details-orders-link.component.type';
// Utilities
import { showViewDetailsLink } from './utils/financing-landing-row-details-order-link.utils';

const FinancingLandingRowDetailsOrdersLinkComponent = ({
  hasOrders,
  status,
  ...rest
}: OrderLinkPropsType): React.ReactElement | null => {
  const { formatMessage, open, handleModal } = FinancingLandingRowDetailsOrdersHooks();

  return showViewDetailsLink({ hasOrders, status }) ? (
    <>
      <LinkComponent {...ORDER_LINK_PROPS} onClick={handleModal}>
        <FormattedMessageComponent id={ORDERS_LINK} />
      </LinkComponent>
      <OrderModalOrchestratorComponent
        {...ORDER_MODAL_ORCHESTRATOR_PROPS}
        {...{ ...rest, formatMessage, open, handleModal }}
      />
    </>
  ) : null;
};

export { FinancingLandingRowDetailsOrdersLinkComponent };
