// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Events handlers
import { TravelPlusLinksHandlers } from '../handlers/travel-plus-links.handlers';
import { TravelPlusLinksTrackingHandlers } from '../handlers/travel-plus-links.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TravelPlusLinksHookType } from './types/travel-plus-links.hook.type';
// Utilities
import { getCurrentTravelPlusEnumerateValue } from './utils/travel-plus-links.hook.utils';

const useTravelPlusMessageHook = (): TravelPlusLinksHookType => {
  const { currentCard, localization } = AppContextConsumerHook(),
    { activated, expirationDate } = currentCard?.travelPlus || {},
    configuration = localization?.config || {};

  const { formatMessage } = useCbIntl();

  return {
    ...TravelPlusLinksHandlers({
      ...AppTrackingHook(TravelPlusLinksTrackingHandlers),
      configuration,
      formatMessage,
    }),
    status: getCurrentTravelPlusEnumerateValue({ activated, expirationDate }),
  };
};

export { useTravelPlusMessageHook };
