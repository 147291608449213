// Vendors
import { useEffect } from 'react';
// Contexts
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import LogoutHandlers from 'handlers/logout.handlers';
import PersonalNumberLogoutModalHandlers from '../handlers/personal-number-logout-modal.handlers';
import PersonalNumberLogoutModalTrackingHandlers from '../handlers/personal-number-logout-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalNumberLogoutModalHookType } from './types/personal-number-logout-modal.hook.type';

const PersonalNumberLogoutModalHook = (): PersonalNumberLogoutModalHookType => {
  const { showModal } = PersonalNumberContextConsumerHook(),
    { setFetching } = ManagerContextConsumerHook();

  const { handleOpenLogOffModalTracking, handleLogoutFromChangeNumberButtonClickTracking } =
    AppTrackingHook(PersonalNumberLogoutModalTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleOpenLogOffModalTracking();
  }, []);

  return {
    ...PersonalNumberLogoutModalHandlers({
      ...LogoutHandlers(),
      handleLogoutFromChangeNumberButtonClickTracking,
      setFetching,
    }),
    showModal,
  };
};

export default PersonalNumberLogoutModalHook;
