const DOMAIN = 'cobranded.services.userSettings.privateArea.cbAppHeader.';

export const ACCOUNT_LABEL = `${DOMAIN}accountLabel`;
export const LAST_ACCESS = `${DOMAIN}lastAccessLabel`;
export const LOGOUT = `${DOMAIN}logoutButton`;
export const MODAL_BUTTON = `${DOMAIN}modal.logoutButton`;
export const MODAL_LINK = `${DOMAIN}modal.logoutLink`;
export const MODAL_NOTIFICATIONS_ALL_NOTIFICATIONS = `${DOMAIN}modalNotifications.allNotifications`;
export const MODAL_NOTIFICATIONS_ERROR_DESCRIPTION = `${DOMAIN}modalNotifications.error.description`;
export const MODAL_NOTIFICATIONS_ERROR_TITLE = `${DOMAIN}modalNotifications.error.title`;
export const MODAL_NOTIFICATIONS_TITLE = `${DOMAIN}modalNotifications.title`;
export const MODAL_NOTIFICATIONS_SETTINGS = `${DOMAIN}modalNotifications.settings`;
export const MODAL_TITLE = `${DOMAIN}modal.logoutTitle`;
export const SEND_SIGNATURE_KEY = `${DOMAIN}sendSignatureKey`;
export const SEE_PERSONAL_DATA = `${DOMAIN}seePersonalData`;
