// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from '../components/access-devices/constants/security-keys-access-devices.constants';
// Types
import {
  SecurityKeysApiReturnComponentType,
  SecurityKeysLastLoginApiReturnComponentType,
} from '../types/security-keys-api-return.component.type';

const getLastTimeSecurityItemChangeService = async (
  endpoint: string
): Promise<[SecurityKeysApiReturnComponentType | Record<string, any>, Response]> =>
  apiUtilsCb.get({ endpoint });

const getLastTimeLoginService = async (): Promise<
  [SecurityKeysLastLoginApiReturnComponentType | Record<string, any>, Response]
> => apiUtilsCb.post({ endpoint: constants.SECURITY_KEYS_ACCESS_DEVICES_ENDPOINT });

export { getLastTimeSecurityItemChangeService, getLastTimeLoginService };
