// Vendors
import React from 'react';
import { FormatXMLElementFn } from 'intl-messageformat';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { ClientNameStyled } from './client-name.component.styled';
// Translations
import { CLIENT_NAME } from 'containers/recovery-key/translations/recovery-key.translations.cc';

const ClientName: React.FC<{ firstLastName?: string; action?: string }> = ({
  firstLastName = '',
  action,
}) => {
  const { formatMessage } = useCbIntl();

  return (
    <ClientNameStyled>
      {action ? (
        <ParagraphComponent>
          {(
            formatMessage(
              { id: CLIENT_NAME },
              {
                firstLastName: (<strong>{firstLastName}</strong>) as unknown as FormatXMLElementFn<
                  string,
                  string
                >,
              }
            ) as string
          ).concat(' ', formatMessage({ id: action }))}
        </ParagraphComponent>
      ) : (
        <ParagraphComponent bold>
          <FormattedMessageComponent id={CLIENT_NAME} values={{ firstLastName }} />
        </ParagraphComponent>
      )}
    </ClientNameStyled>
  );
};
export { ClientName };
