// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsCheck3dConfirmationContextType } from './types/card-settings-check-3d-confirmation.context.type';

const CardSettingsCheck3DConfirmationContext = createContext<
  CardSettingsCheck3dConfirmationContextType | Record<string, unknown>
>({});

export const CardSettingsCheck3DConfirmationContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<CardSettingsCheck3dConfirmationContextType>
> => {
  const [showAllowance, setShowAllowance] = useState<boolean>(true);
  const [shownCode, setShownCode] = useState<boolean>(false);

  return (
    <CardSettingsCheck3DConfirmationContext.Provider
      value={{ showAllowance, setShowAllowance, shownCode, setShownCode }}
    >
      {children}
    </CardSettingsCheck3DConfirmationContext.Provider>
  );
};

export const useCardSettingsCheck3DConfirmationContextConsumerHook =
  (): CardSettingsCheck3dConfirmationContextType =>
    useContext(
      CardSettingsCheck3DConfirmationContext
    ) as CardSettingsCheck3dConfirmationContextType;

/* eslint-disable */
export const CardSettingsCheck3DConfirmationContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<CardSettingsCheck3dConfirmationContextType>> =>
    (
      <CardSettingsCheck3DConfirmationContextProvider>
        <Component {...props} />
      </CardSettingsCheck3DConfirmationContextProvider>
    );
