// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { ProfileConsentsContextConsumerHook } from 'containers/personal/views/consents/contexts/profile-consents.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event Handlers
import ProfileConsentsStep2Handlers from '../handlers/profile-consents-step2.handlers';
import { ConsentsStep2TrackingHandlers } from '../handlers/profile-consents-step2.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ProfileConsentsStep2HookType } from './types/profile-consents-step2.hook.type';
import { ChallengeType } from 'types/challenge.type';

const ProfileConsentsStep2Hook = (): ProfileConsentsStep2HookType => {
  const { formatMessage } = useCbIntl();
  const [fetching, setFetching] = useState(false);
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challenged, setChallenged] = useState(false);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const { consents, initialConsents, setError, setConsents } = ProfileConsentsContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { handleConsentsStep2LoadedTracking } = AppTrackingHook(ConsentsStep2TrackingHandlers);

  useEffect(() => {
    handleConsentsStep2LoadedTracking();
  }, []);

  const {
    handleChallengeError,
    handleChallengeRetryButtonClick,
    handleChallengeSuccess,
    handleUpdateConsents,
  } = ProfileConsentsStep2Handlers({
    challenge,
    consents,
    initialConsents,
    setChallenge,
    setChallengeError,
    setChallenged,
    setConsents,
    setError,
    setFetching,
    setResult,
  });

  return {
    challenged,
    challengeError,
    consents,
    fetching,
    formatMessage,
    handleChallengeError,
    handleChallengeSuccess,
    handleUpdateConsents,
    handleChallengeRetryButtonClick,
  };
};

export default ProfileConsentsStep2Hook;
