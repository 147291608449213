// Vendors
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

const CardStatusWrapperComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
CardStatusWrapperComponentStyled.displayName = 'CardStatusWrapperComponentStyled';

const CardStatusTopWrapperComponentStyled = styled(CardStatusWrapperComponentStyled)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    flex-direction: column;
    width: 100%;
  }
`;
CardStatusWrapperComponentStyled.displayName = 'CardStatusWrapperComponentStyled';

export { CardStatusWrapperComponentStyled, CardStatusTopWrapperComponentStyled };
