// Vendors
import { cookies, sessionUtils, storageConstants } from '@openbank/cf-ui-architecture-core';
// Enumerations
import { StorageCookiesEnum, StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Constants
import { IS_CUSTOMER, PUBLIC_DOMAIN } from 'constants/storage/storage.constants';
// Types
import { UserSessionType } from 'types/user-session.type';

const isUserLogged = (): boolean => Boolean(cookies.getCookie(StorageCookiesEnum.ACCESS_TOKEN));

const clearSessionData = (): void => {
  const userIsRemembered =
      localStorage.getItem(storageConstants.LOCALSTORAGE_IDS.REMEMBER_USER) === String(true),
    username = localStorage.getItem(storageConstants.LOCALSTORAGE_IDS.USERNAME) ?? '',
    alias = localStorage.getItem(StorageLocalEnum.USER_ALIAS) ?? '';

  sessionUtils.clearSessionData();
  localStorage.setItem(storageConstants.LOCALSTORAGE_IDS.REMEMBER_USER, String(userIsRemembered));
  localStorage.setItem(StorageLocalEnum.USER_ALIAS, alias);
  localStorage.setItem(storageConstants.LOCALSTORAGE_IDS.USERNAME, username);
  localStorage.setItem(IS_CUSTOMER, String(true));
  localStorage.setItem(StorageCookiesEnum.USERNAME, username);
  cookies.setCookie({ name: IS_CUSTOMER, value: true, domain: PUBLIC_DOMAIN });
};

const isValidUserSessionContract = (response: Record<string, any> | UserSessionType): boolean =>
  Boolean(response?.accessToken && response?.refreshToken && response?.expiresIn);

export { clearSessionData, isUserLogged, isValidUserSessionContract };
