const TITLE_PROPS = {
  tag: 'h4',
  testId: 'transactions-report-landing-title',
};

const DESCRIPTION_PARAGRAPH_PROPS = {
  testId: 'transactions-report-landing-description',
};

export { DESCRIPTION_PARAGRAPH_PROPS, TITLE_PROPS };
