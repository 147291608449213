// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const SUPPORT_LANDING_ROUTE_PROPS = {
    index: true,
  },
  SUPPORT_FAQS_ROUTE_PROPS = {
    path: RoutesEnumeration.FAQS,
  },
  SUPPORT_CANCEL_CONTRACT_ROUTE_PROPS = {
    path: RoutesEnumeration.CANCEL_CONTRACT,
  };

export {
  SUPPORT_LANDING_ROUTE_PROPS,
  SUPPORT_CANCEL_CONTRACT_ROUTE_PROPS,
  SUPPORT_FAQS_ROUTE_PROPS,
};
