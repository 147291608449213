const DOMAIN_NEW_CARD = 'cobranded.services.userSettings.privateArea.cardSettings.newCard.';
const DOMAIN_NEW_CARD_SIDEBAR =
  'cobranded.services.userSettings.privateArea.cardSettings.newCard.sidebar.';
const DOMAIN_NEW_CARD_SIDEBAR_ROWS =
  'cobranded.services.userSettings.privateArea.cardSettings.newCard.sidebar.rows.';

const DELIVERY_ADDRESS = {
  TITLE: `${DOMAIN_NEW_CARD}deliveryAddress.title`,
  DESCRIPTION: `${DOMAIN_NEW_CARD}deliveryAddress.description`,
};

const CONFIRMATION = {
  TITLE: `${DOMAIN_NEW_CARD}confirmation.title`,
  DESCRIPTION:
    `${DOMAIN_NEW_CARD}confirmation.description`,
};

const SUCCESS = {
  TITLE: `${DOMAIN_NEW_CARD}success.title`,
  DESCRIPTION:`${DOMAIN_NEW_CARD}success.description`,
};

const ERROR = {
  TITLE: `${DOMAIN_NEW_CARD}error.title`,
  DESCRIPTION: `${DOMAIN_NEW_CARD}error.description`,
};

const REPLACEMENT = {
  TITLE: `${DOMAIN_NEW_CARD}replacementError.title`,
  DESCRIPTION:`${DOMAIN_NEW_CARD}replacementError.description`,
};

const TITLE = `${DOMAIN_NEW_CARD_SIDEBAR}title`;

const ROWS = {
  FIRST: {
    TITLE: `${DOMAIN_NEW_CARD_SIDEBAR_ROWS}first.title`,
    CONTENT: `${DOMAIN_NEW_CARD_SIDEBAR_ROWS}first.content`,
  },
  SECOND: {
    TITLE: `${DOMAIN_NEW_CARD_SIDEBAR_ROWS}second.title`,
    CONTENT: `${DOMAIN_NEW_CARD_SIDEBAR_ROWS}second.content`,
  },
};

export { DELIVERY_ADDRESS, CONFIRMATION, SUCCESS, ERROR, TITLE, ROWS, REPLACEMENT };
