// Enumerations
import { ProblemsEnumeration } from 'containers/transactions/views/report/enumerations/problems.enumeration';
// Translations
import { OPTIONS_TEXTS } from '../translations/transactions-report-landing-options.translations';

const OPTIONS = [
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.NOT_RECOGNIZE_CHARGE].description,
    eventLabel: 'chargeNotAuthorisedBox',
    testId: 'transactions-report-landing-options-not-recognize-charge',
    title: OPTIONS_TEXTS[ProblemsEnumeration.NOT_RECOGNIZE_CHARGE].title,
    value: ProblemsEnumeration.NOT_RECOGNIZE_CHARGE,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.DIFFERENT_AMOUNT_ATM].description,
    eventLabel: 'differentAmountBox',
    testId: 'transactions-report-landing-options-different-amount-atm',
    title: OPTIONS_TEXTS[ProblemsEnumeration.DIFFERENT_AMOUNT_ATM].title,
    value: ProblemsEnumeration.DIFFERENT_AMOUNT_ATM,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.DUPLICATED_CHARGE].description,
    eventLabel: 'duplicatedChargeBox',
    testId: 'transactions-report-landing-options-duplicated-charge',
    title: OPTIONS_TEXTS[ProblemsEnumeration.DUPLICATED_CHARGE].title,
    value: ProblemsEnumeration.DUPLICATED_CHARGE,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.DEFECTIVE_PRODUCT].description,
    eventLabel: 'defectiveProductBox',
    hasMessage: true,
    testId: 'transactions-report-landing-options-defective-product',
    title: OPTIONS_TEXTS[ProblemsEnumeration.DEFECTIVE_PRODUCT].title,
    value: ProblemsEnumeration.DEFECTIVE_PRODUCT,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.NOT_RECEIVED_PRODUCT].description,
    eventLabel: 'productNotReceivedBox',
    hasMessage: true,
    testId: 'transactions-report-landing-options-not-received-product',
    title: OPTIONS_TEXTS[ProblemsEnumeration.NOT_RECEIVED_PRODUCT].title,
    value: ProblemsEnumeration.NOT_RECEIVED_PRODUCT,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.CHARGED_SERVICE_CANCELLED].description,
    eventLabel: 'chargedForCancelledServiceBox',
    hasMessage: true,
    testId: 'transactions-report-landing-options-charged-service-cancelled',
    title: OPTIONS_TEXTS[ProblemsEnumeration.CHARGED_SERVICE_CANCELLED].title,
    value: ProblemsEnumeration.CHARGED_SERVICE_CANCELLED,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.SUBSCRIPTION_PASSED].description,
    eventLabel: 'subscriptionAlreadyCancelledBox',
    hasMessage: true,
    testId: 'transactions-report-landing-options-subscription-passed',
    title: OPTIONS_TEXTS[ProblemsEnumeration.SUBSCRIPTION_PASSED].title,
    value: ProblemsEnumeration.SUBSCRIPTION_PASSED,
  },
  {
    description: OPTIONS_TEXTS[ProblemsEnumeration.PAYMENT_OTHER_MEANS].description,
    eventLabel: 'paymentByOtherMeansBox',
    hasMessage: true,
    testId: 'transactions-report-landing-options-payment-other-means',
    title: OPTIONS_TEXTS[ProblemsEnumeration.PAYMENT_OTHER_MEANS].title,
    value: ProblemsEnumeration.PAYMENT_OTHER_MEANS,
  },
];

export { OPTIONS };
