// Types
import {
  FinancingPPIContractingStep2ExpandableDescriptionLinkBuilderHandlersType,
  FinancingPPIContractingStep2ExpandableDescriptionLinkMethodsHandlersType,
} from './types/financing-ppi-contracting-step2-expandable-description-link.handlers.type';

const openPPITermsAndConditionsLinkClickHandler = ({
  handleOpenPPIContractingTermsAndConditionsLinkClickTracking,
  url,
}: FinancingPPIContractingStep2ExpandableDescriptionLinkBuilderHandlersType): void => {
  handleOpenPPIContractingTermsAndConditionsLinkClickTracking();
  window.open(url);
};

const FinancingPPIContractingStep2ExpandableLinkHandlers = (
  props: FinancingPPIContractingStep2ExpandableDescriptionLinkBuilderHandlersType
): FinancingPPIContractingStep2ExpandableDescriptionLinkMethodsHandlersType => ({
  handleOpenPPIContractingTermsAndConditionsLinkClick: () =>
    openPPITermsAndConditionsLinkClickHandler(props),
});

export { FinancingPPIContractingStep2ExpandableLinkHandlers };
