// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { FinancingPPIContractingStep1HiringLegalHandlers } from '../handlers/financing-ppi-contracting-step1-hiring-legal.handlers';
import { FinancingPPIContractingStep1HiringLegalTrackingHandlers } from '../handlers/financing-ppi-contracting-step1-hiring-legal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep1HiringLegalMethodsHandlersType } from '../handlers/types/financing-ppi-contracting-step1-hiring-legal.handlers.type';

const useFinancingPPIContractingStep1HiringLegalHook =
  (): FinancingPPIContractingStep1HiringLegalMethodsHandlersType => {
    const { localization } = AppContextConsumerHook(),
      { cfg_dgsfp_url: url } = localization?.config || {};

    return FinancingPPIContractingStep1HiringLegalHandlers({
      ...AppTrackingHook(FinancingPPIContractingStep1HiringLegalTrackingHandlers),
      url,
    });
  };

export { useFinancingPPIContractingStep1HiringLegalHook };
