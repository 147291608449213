// Vendors
import { TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  EVENT_ACTION,
  EVENT_LABEL_CLOSE,
  EVENT_LABEL_OPEN,
  TYPE,
} from './constants/financing-landing-row-details-order.tracking.handler.constants';
// Types
import { FinancingLandingRowDetailsOrdersTrackingHandlersReturnType } from './types/financing-landing-row-detail-order.tracking.handler.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const handlerViewDetailTrackingHandler = (open: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: EVENT_ACTION,
    eventLabel: `${open ? EVENT_LABEL_OPEN : EVENT_LABEL_CLOSE}`,
  },
  type: TYPE,
});

const FinancingLandingRowDetailsOrdersTrackingHandlers = (
  track: TrackBuilderType
): FinancingLandingRowDetailsOrdersTrackingHandlersReturnType => ({
  handleViewDetailClickTracking: (open: boolean) => track(handlerViewDetailTrackingHandler(open)),
});

export { FinancingLandingRowDetailsOrdersTrackingHandlers };
