// Services
import { getLastTimeSecurityItemChangeService, getLastTimeLoginService } from '../services/security-keys-landing.services';

const getLastTimeSecurityItemChangeResolver =
  (endpoint: string) => async (): Promise<[string] | [null, true]> => {
    const [response] = await getLastTimeSecurityItemChangeService(endpoint);

    return response?.passwordUpdatedAt ? [response.passwordUpdatedAt] : [null, true];
  };

const getLastTimeLoginResolver = () => async (): Promise<[string] | [null, true]> => {
  const [response] = await getLastTimeLoginService();

  return response?.lastLoginTime ? [response.lastLoginTime] : [null, true];
};

export { getLastTimeSecurityItemChangeResolver, getLastTimeLoginResolver };
