// Vendors
import styled from 'styled-components';

const FilterModalListSelectorComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: fit;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

const FilterModalListSelectorComponentDescriptionStyled = styled.div`
  p {
    color: ${({ theme }) => theme.colors.linkTertiaryA};
  }
`;

export {
  FilterModalListSelectorComponentStyled,
  FilterModalListSelectorComponentDescriptionStyled,
};
