const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.cbFeedback.';

const ERROR_DESCRIPTION = `${DOMAIN}errorDescription`,
  ERROR_TITLE = `${DOMAIN}errorTitle`,
  EXIT = `${DOMAIN}exitButton`,
  EXIT_LINK = `${DOMAIN}exitTheProcessLink`,
  SUCCESS_DESCRIPTION = `${DOMAIN}successDescription`,
  SUCCESS_TITLE = `${DOMAIN}successTitle`;

export { ERROR_DESCRIPTION, EXIT_LINK, ERROR_TITLE, EXIT, SUCCESS_DESCRIPTION, SUCCESS_TITLE };
