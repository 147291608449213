// Contexts
import { usePersonalAddressContextConsumerHook } from 'containers/personal/views/address/contexts/personal-address.context';
// Handlers
import { PersonalAddressStep2ChallengeHandlers } from '../handlers/personal-address-step2-challenge.handlers';
// Types
import { PersonalAddressStep2ChallengeHookReturnType } from './types/personal-address-step2-challenge-return.hook.type';

export const usePersonalAddressStep2ChallengeHook =
  (): PersonalAddressStep2ChallengeHookReturnType => {
    const { challenge, challengeError, setChallenge, setChallengeError, setError, signatureExpired, setSignatureExpired } =
      usePersonalAddressContextConsumerHook();

    return {
      ...PersonalAddressStep2ChallengeHandlers({
        challenge,
        setChallenge,
        setChallengeError,
        setError,
        setSignatureExpired
      }),
      signatureExpired,
      challengeError,
    };
  };
