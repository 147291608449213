// Vendors
import React from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Components
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getModalConfiguration } from './configurations/block-card-address-delete-modal.component.configurations';
// Hooks
import CardSettingsBlockCardFirstStepAddressDeleteModalHook from './hooks/block-card-address-delete-modal.hook';
// Translations
import { DESCRIPTION } from './translations/block-card-address-delete-modal.translations';
// Types
import { CardSettingsBlockCardFirstStepAddressDeleteModalComponentType } from './types/block-card-address-delete-modal.component.type';

const BlockCardAddressDeleteModalComponent = ({
  actions,
  isOpen,
}: CardSettingsBlockCardFirstStepAddressDeleteModalComponentType): React.ReactElement | null => {
  const { formatMessage } = CardSettingsBlockCardFirstStepAddressDeleteModalHook();

  return isOpen ? (
    <ModalComponent
      {...getModalConfiguration({ formatMessage, ...actions })}
      isIosDevice={isIosWebView()}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </ModalComponent>
  ) : null;
};

export { BlockCardAddressDeleteModalComponent };
