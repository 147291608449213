const CONTEXT_DEFAULT_VALUE = {
  challenge: null,
  challengeError: false,
  eppDetails: null,
  error: false,
  setChallenge: (): null => null,
  setChallengeError: (): null => null,
  setEppDetails: (): null => null,
  setError: (): null => null,
};

export { CONTEXT_DEFAULT_VALUE };
