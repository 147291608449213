// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { SupportCancelContractHandlers } from '../handlers/support-cancel-contract.handler';
// Types
import { SupportCancelContractHookType } from './types/support-cancel-contract.hook.type';
import CardsHook from 'providers/cards/hooks/cards.hook';

const useSupportCancelContract = (): SupportCancelContractHookType => {
  const { currentCardDetail } = AppContextConsumerHook();
  const { blockDetails } = currentCardDetail || {};
  const { formatDate } = useCbIntl();
  const { handleSetupCardInformationEvent } = CardsHook();
  const [fetching, setFetching] = useState<boolean>(false);
  const { handleExitCancelContractEvent, handleLinkClickEvent, handleCheckCardStatusEvent } =
    SupportCancelContractHandlers({
      handleSetupCardInformationEvent,
      navigate: useNavigate(),
      setFetching,
    });

  useEffect(() => {
    handleCheckCardStatusEvent();
  }, []);

  return {
    blockDetails,
    fetching,
    formatDate,
    handleCheckCardStatusEvent,
    handleExitCancelContractEvent,
    handleLinkClickEvent,
  };
};

export { useSupportCancelContract };
