// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const constantsStep6 = {
  ASSET_PROPS: {
    id: 'web-id',
    type: assetConstants.TYPES.LOGO,
    extension: assetConstants.EXTENSIONS.SVG,
    sizes: {
      height: 128,
      width: 128,
    },
  },
  LOADER_PROPS: {
    sizes: {
      border: 4,
      height: 40,
      width: 40,
    },
  },
} as const;

export { constantsStep6 };
