// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  ADDRESS_DETAIL_PROPS,
  CARD_DETAIL_NUMBER,
  CARD_DETAIL_PROPS,
  CARD_DETAIL_STREET_DATA,
  PARAGRAPH_PROPS,
} from './constants/card-settings-block-card-second-step-summary.constants';
// Hooks
import CardSettingsBlockCardSecondStepSummaryHook from './hooks/card-settings-block-card-second-step-summary.hook';
// Styles
import { CardSettingsBlockCardSecondStepSummaryComponentStyled } from './card-settings-block-card-second-step-summary.component.styled';
// Translations
import {
  ADDRESS_TITLE,
  CARD_INFO_TITLE,
} from './translations/card-settings-block-card-second-step-summary.translations';
// Types
import { CardSettingsBlockCardSecondStepSummaryComponentType } from './types/card-settings-block-card-second-step-summary.component.type';
import BlockCardAddressContentComponent from 'components/block-card/components/address-content/block-card-address-content.component';

const CardSettingsBlockCardSecondStepSummaryComponent = ({
  address,
  cardNumber,
}: CardSettingsBlockCardSecondStepSummaryComponentType): React.ReactElement => {
  const { formatMessage } = CardSettingsBlockCardSecondStepSummaryHook();

  return (
    <CardSettingsBlockCardSecondStepSummaryComponentStyled>
      <ProductDetailComponent {...CARD_DETAIL_PROPS} title={formatMessage({ id: CARD_INFO_TITLE })}>
        <ParagraphComponent {...PARAGRAPH_PROPS} {...CARD_DETAIL_NUMBER}>
          {cardNumber}
        </ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent
        {...ADDRESS_DETAIL_PROPS}
        title={formatMessage({ id: ADDRESS_TITLE })}
      >
        <BlockCardAddressContentComponent
          address={address}
          paragraphProps={PARAGRAPH_PROPS}
          testId={CARD_DETAIL_STREET_DATA.testId}
        />
      </ProductDetailComponent>
    </CardSettingsBlockCardSecondStepSummaryComponentStyled>
  );
};

export default CardSettingsBlockCardSecondStepSummaryComponent;
