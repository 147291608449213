// Types
import { PersonalEmailStep1RepeatBuilderReturnHandlersType } from 'containers/personal/views/email/components/step1/components/repeat/handlers/types/personal-email-step1-repeat-builder-return.handlers.type';
import { PersonalEmailStep1RepeatBuilderHandlersType } from 'containers/personal/views/email/components/step1/components/repeat/handlers/types/personal-email-step1-repeat-builder.handlers.type';
import { PersonalEmailStep1RepeatMethodBuilderHandlersType } from 'containers/personal/views/email/components/step1/components/repeat/handlers/types/personal-email-step1-repeat-method-builder.handlers.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';
import { RepeatEmailErrorIdEnumeration } from 'containers/personal/views/email/components/step1/components/repeat/enumerations/new-email-error-id.enumeration';
import {
  isEmptyField,
  newEmailAndRepeatedDontMatch,
} from 'containers/personal/views/email/components/step1/utils/personal-email-step1.utils';

const saveRepeatModifyEmailInputEventHandler = ({
  email,
  setInputError,
  setRepeatEmail,
}: PersonalEmailStep1RepeatMethodBuilderHandlersType): any => {
  setInputError(null);
  setRepeatEmail(email);
};

const validateRepeatModifyEmailInputEventHandler = ({
  handleModifyEmailRepeatInputErrorTracking,
  newEmail,
  repeatEmail,
  setInputError,
}: Omit<PersonalEmailStep1RepeatBuilderHandlersType, 'setRepeatEmail'>): any => {
  if (isEmptyField(repeatEmail)) {
    handleModifyEmailRepeatInputErrorTracking();
    setInputError(RepeatEmailErrorIdEnumeration.EMPTY);
  } else if (repeatEmail && !isValidEmail(repeatEmail)) {
    handleModifyEmailRepeatInputErrorTracking();
    setInputError(RepeatEmailErrorIdEnumeration.FORMAT);
  } else if (newEmailAndRepeatedDontMatch({ newEmail, repeatEmail })) {
    handleModifyEmailRepeatInputErrorTracking();
    setInputError(RepeatEmailErrorIdEnumeration.MATCH);
  } else {
    setInputError(null);
  }
};

const PersonalEmailStep1RepeatHandlers = ({
  handleModifyEmailRepeatInputErrorTracking,
  newEmail,
  repeatEmail,
  setInputError,
  setRepeatEmail,
}: PersonalEmailStep1RepeatBuilderHandlersType): PersonalEmailStep1RepeatBuilderReturnHandlersType => ({
  handleSaveRepeatModifyEmailInputEvent: (email: string) =>
    saveRepeatModifyEmailInputEventHandler({ email, setInputError, setRepeatEmail }),
  handleValidateRepeatModifyEmailInputEvent: () =>
    validateRepeatModifyEmailInputEventHandler({
      handleModifyEmailRepeatInputErrorTracking,
      repeatEmail,
      newEmail,
      setInputError,
    }),
});

export default PersonalEmailStep1RepeatHandlers;
