// Services
import { savePPITermsAndConditionsService } from '../services/financing-ppi-contracting-step2.service';
// Types
import { FinancingPPIContractingStepApiBuilderType } from '../types/financing-ppi-contracting-step-api-builder.type';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const savePPITermsAndConditionsResolver = async (
  params: FinancingPPIContractingStepApiBuilderType
): Promise<[number] | [null, boolean]> => {
  const [, { status }] = await savePPITermsAndConditionsService(params);

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

export { savePPITermsAndConditionsResolver };
