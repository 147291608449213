// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Types
import { FinancingInsurancesContractingLPIContextType } from './types/financing-insurances-contracting-lpi-context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const FinancingInsurancesContractingLPIContext =
  createContext<FinancingInsurancesContractingLPIContextType>(
    {} as FinancingInsurancesContractingLPIContextType
  );

export const FinancingInsurancesContractingContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<FinancingInsurancesContractingLPIContextType>
> => {
  const [plan, setPlan] = useState<string | undefined>();
  const [postalDocumentation, setPostalDocumentation] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      plan,
      postalDocumentation,
      setPlan,
      setPostalDocumentation,
    }),
    [plan, postalDocumentation]
  );

  return (
    <FinancingInsurancesContractingLPIContext.Provider value={contextValue}>
      {children}
    </FinancingInsurancesContractingLPIContext.Provider>
  );
};

export const useFinancingInsurancesContractingLPIContextConsumerHook =
  (): FinancingInsurancesContractingLPIContextType =>
    useContext(FinancingInsurancesContractingLPIContext);

/* eslint-disable */
export const FinancingInsurancesContractingLPIContextConsumerHOC =
  (Component: React.FunctionComponent<any>): React.FunctionComponent<any> =>
  (props: any): React.ReactElement<React.Provider<FinancingInsurancesContractingLPIContextType>> =>
    (
      <FinancingInsurancesContractingContextProvider>
        <Component {...props} />
      </FinancingInsurancesContractingContextProvider>
    );
