// Translations
import { ERROR_TITLE } from 'configurations/translations/app.configuration.translations';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.transactionsLanding.rowDetail.';

const ERROR_TRANSLATIONS = {
  ERROR_DESCRIPTION: `${DOMAIN}fetchError.description`,
  ERROR_TITLE,
};

const BUTTON_LABEL_ERROR = `${DOMAIN}fetchError.buttonLabel`;

export { ERROR_TRANSLATIONS, BUTTON_LABEL_ERROR };
