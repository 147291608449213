// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const RequestPortabilityStep3ComponentStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};
  flex-direction: column;
  width: 100%;
`;
export const AccessPortabilityRequestStep3ComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    flex-direction: column;
    width: 100%;
  }
`;

export const PersonalEmailStep1MessageViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const RequestPortabilityStep3MessageStyled = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingXxxxl};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXs};
    width: 100%;
  }
`;

export const RequestPortabilityStep3MessageItemStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
`;
