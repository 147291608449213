// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  TooltipComponent,
  AssetComponent
} from '@openbank/cb-ui-commons';
// Constants
import { ASSET_PROPS } from './constants/platform.constants';
// Hooks
import { PlatformHook } from './hooks/platform.hook';
// Styled
import {
  DeviceInfoPlatformComponentStyled,
  DeviceInfoPlatformItemComponentStyled,
  DeviceInfoPlatformItemContentComponentStyled
} from './platform.component-styled';
// Types
import { PlatformComponentType } from './types/platform.component.type';

const DeviceInfoPlatformComponent = ({
  configuration
}: PlatformComponentType): React.ReactElement => {
  const { parserConfiguration, onOpenTooltip } = PlatformHook({ configuration });

  return (
    <>
      { Object.keys(parserConfiguration).map(key => (
          <DeviceInfoPlatformComponentStyled key={key}>
            { Object.keys(parserConfiguration[key]).map(subKey => (
                <DeviceInfoPlatformItemComponentStyled key={subKey}>
                  <DeviceInfoPlatformItemContentComponentStyled>
                    <ParagraphComponent>
                      {parserConfiguration[key][subKey].title}
                    </ParagraphComponent>
                    { parserConfiguration[key][subKey].toolkit &&
                      <TooltipComponent
                        {...{...parserConfiguration[key][subKey].toolkit}}
                        doWhenTooltipIsOpen={onOpenTooltip}
                      >
                        <AssetComponent {...ASSET_PROPS} />
                      </TooltipComponent>
                    }
                  </DeviceInfoPlatformItemContentComponentStyled>
                  <ParagraphComponent bold>
                    {parserConfiguration[key][subKey].description}
                  </ParagraphComponent>
                </DeviceInfoPlatformItemComponentStyled>
              ))
            }
          </DeviceInfoPlatformComponentStyled>
        ))
      }
    </>   
  );
};

export { DeviceInfoPlatformComponent };