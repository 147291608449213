// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  INPUT_CLICK_PAYLOAD,
  INPUT_ERROR_PAYLOAD,
} from './constants/amount.handlers.tracking.constants';
// Types
import { AmountHandlersTrackingType } from './types/amount-handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const inputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: INPUT_CLICK_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const inputErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: INPUT_ERROR_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const AmountTrackingHandlers = (track: TrackBuilderType): AmountHandlersTrackingType => ({
  handleInputClickTracking: () => track(inputClickTrackingHandler()),
  handleInputErrorTracking: () => track(inputErrorTrackingHandler()),
});

export default AmountTrackingHandlers;
