// Resolvers
import { updateCardPinResolver } from 'containers/card-settings/views/change-pin/resolvers/card-settings-change-pin.resolver';
// Types
import {
  CardSettingsChangePinActionsBuilderHandlersType,
  CardSettingsChangePinActionsBuilderReturnHandlersType,
  CardSettingsChangePinActionsMethodBuilderHandlersType,
  CardSettingsChangePinCancelProcessLinkType,
} from './types/card-settings-change-pin-actions.handlers.type';
// Utilities
import { getFeedbackErrorResult } from './utils/card-settings-change-pin-actions.handlers.utils';
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const cancelChangePinProcessLinkClickHandler = ({
  handleCancelChangePinProcessLinkClickTracking,
  navigate,
  onNavigateHybridFlow,
}: CardSettingsChangePinCancelProcessLinkType): void => {
  handleCancelChangePinProcessLinkClickTracking();
  onNavigateHybridFlow();
};

const confirmChangePinButtonClickHandler = async ({
  cardId,
  handleConfirmChangePinButtonClickTracking,
  newPin: pin,
  progressId,
  setSolvedChallenged,
  setChallengeError,
  setFetching,
  setResult,
}: CardSettingsChangePinActionsMethodBuilderHandlersType): Promise<void> => {
  handleConfirmChangePinButtonClickTracking();
  setFetching(true);

  const [, error] = await updateCardPinResolver({ cardId, pin, progressId });

  if (isWrongOTPError(error)) {
    setSolvedChallenged(false);
    setChallengeError(true);
  } else {
    setResult(getFeedbackErrorResult(error));
  }

  setFetching(false);
};

const CardSettingsChangePinActionsHandlers = ({
  cardId,
  handleCancelChangePinProcessLinkClickTracking,
  handleConfirmChangePinButtonClickTracking,
  navigate,
  newPin,
  onNavigateHybridFlow,
  progressId,
  setSolvedChallenged,
  setChallengeError,
  setFetching,
  setResult,
}: CardSettingsChangePinActionsBuilderHandlersType): CardSettingsChangePinActionsBuilderReturnHandlersType => ({
  handleCancelChangePinProcessLinkClick: () =>
    cancelChangePinProcessLinkClickHandler({
      handleCancelChangePinProcessLinkClickTracking,
      navigate,
      onNavigateHybridFlow,
    }),
  handleConfirmChangePinButtonClick: () =>
    confirmChangePinButtonClickHandler({
      cardId,
      handleConfirmChangePinButtonClickTracking,
      newPin,
      progressId,
      setSolvedChallenged,
      setChallengeError,
      setFetching,
      setResult,
    }),
});

export default CardSettingsChangePinActionsHandlers;
