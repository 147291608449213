// Vendors
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

export const CreditLimitIncreaseFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacingM};
  border: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryC};
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const EmploymentDataRadioButtonsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

export const EmploymentDataMonthAndYearStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const DisplayNoneStyled = styled.div`
  display: none;
`;
