const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.step4.creditCard.';

const TITLE = `${DOMAIN}title`;
const DESCRIPTION = `${DOMAIN}description`;
const PIN_TITLE = `${DOMAIN}pinTitle`;
const PIN_DESCRIPTION = `${DOMAIN}pinDescription`;
const SHOW_PASSWORD = `${DOMAIN}showPin`;
const HIDE_PASSWORD = `${DOMAIN}hidePin`;
const INPUT_ERROR_MESSAGE = `${DOMAIN}errorMessage`;
const INPUT_HELPER_MESSAGE = `${DOMAIN}helperMessage`;
const INPUT_LABEL = `${DOMAIN}label`;

export {
  TITLE,
  DESCRIPTION,
  PIN_TITLE,
  PIN_DESCRIPTION,
  SHOW_PASSWORD,
  HIDE_PASSWORD,
  INPUT_ERROR_MESSAGE,
  INPUT_HELPER_MESSAGE,
  INPUT_LABEL 
};