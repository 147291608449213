// Translations
import {
  DESCRIPTION,
  TITLE,
} from 'containers/financing/views/ppi/components/contracting/components/step2/components/expandable/components/limit/translations/financing-ppi-contracting-step2-expandable-limit.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMIT_PROPS = {
  description01: DESCRIPTION,
  title: TITLE,
};

export { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMIT_PROPS };
