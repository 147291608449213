// Types
import {
  PersonalNumberStep1PhoneBuilderHandlersType,
  PersonalNumberStep1PhoneBuilderReturnHandlersType,
  PersonalNumberStep1PhoneChangeBuilderHandlersType,
  PersonalNumberStep1PhoneClickBuilderHandlersType,
  PersonalNumberStep1PhoneValidateBuilderHandlersType,
} from './types/personal-number-step1-phone.handlers.type';
// Utilities
import { isInvalidPhone } from 'containers/personal/views/number/components/step1/utils/personal-number-step1.utils';

const savePersonalNumberStep1PrefixOptionClickEventHandler = ({
  handleSavePersonalNumberStep1NumberPrefixOptionClickTracking,
  prefix,
  setPrefix,
  setError,
}: PersonalNumberStep1PhoneClickBuilderHandlersType): void => {
  handleSavePersonalNumberStep1NumberPrefixOptionClickTracking(prefix);
  setPrefix(prefix);
  setError(false);
};

const savePersonalNumberStep1NumberInputEventHandler = ({
  phoneNumber,
  setError,
  setPhoneNumber,
  setRepeatPhoneNumber,
}: PersonalNumberStep1PhoneChangeBuilderHandlersType): void => {
  setPhoneNumber(phoneNumber);
  setError(false);
  setRepeatPhoneNumber('');
};

const validatePersonalNumberStep1NumberInputEvent = ({
  handleManagePersonalNumberStep1NumberInputErrorTracking,
  prefix,
  phoneNumber,
  setError,
  setPhoneIsValid,
}: PersonalNumberStep1PhoneValidateBuilderHandlersType): void => {
  const isValid = !isInvalidPhone({ prefix, phoneNumber });
  if (isValid) {
    setPhoneIsValid(true);
  } else if (!isValid) {
    setPhoneIsValid(false);
    handleManagePersonalNumberStep1NumberInputErrorTracking();
    setError(true);
  }
};

const PersonalPhoneStep1PhoneHandlers = ({
  handleSavePersonalNumberStep1NumberPrefixOptionClickTracking,
  handleManagePersonalNumberStep1NumberInputErrorTracking,
  phoneNumber,
  prefix,
  setError,
  setPrefix,
  setPhoneNumber,
  setPhoneIsValid,
  setRepeatPhoneNumber,
}: PersonalNumberStep1PhoneBuilderHandlersType): PersonalNumberStep1PhoneBuilderReturnHandlersType => ({
  handleSavePersonalNumberStep1NumberPrefixOptionClick: (prefix: string) =>
    savePersonalNumberStep1PrefixOptionClickEventHandler({
      handleSavePersonalNumberStep1NumberPrefixOptionClickTracking,
      prefix,
      setPrefix,
      setError,
    }),
  handleSavePersonalNumberStep1NumberInputEvent: (phoneNumber: string) =>
    savePersonalNumberStep1NumberInputEventHandler({
      phoneNumber,
      setError,
      setPhoneNumber,
      setRepeatPhoneNumber,
    }),
  handleValidatePersonalNumberStep1NumberInputEvent: () =>
    validatePersonalNumberStep1NumberInputEvent({
      handleManagePersonalNumberStep1NumberInputErrorTracking,
      prefix,
      phoneNumber,
      setError,
      setPhoneIsValid,
    }),
});

export default PersonalPhoneStep1PhoneHandlers;
