// Vendors
import { useEffect, useState } from 'react';
import { cookies } from '@openbank/cf-ui-architecture-core';
import { useSearchParams } from 'react-router-dom';
// Enums
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Utils
import {
  changeAccessKeyFromMobile,
  isAccessKeyCookieExpired,
} from '../../authorization/utils/entrypoint-authorization.utils';
// Types
import { AppNoUserDataHookReturnType } from './types/app-no-user-data.hook.return.type';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const AppNoUserDataHook = (): AppNoUserDataHookReturnType => {
  const accessKeyExpired = cookies.getCookie(StorageCookiesEnum.ACCESS_KEY_EXPIRED);
  const { isHybrid } = MobileConnectorContextConsumerHook();
  const [searchParams] = useSearchParams();
  const [showExpiredAccessKeyModal, setShowExpiredAccessKeyModal] = useState<boolean>(() =>
    isAccessKeyCookieExpired(accessKeyExpired)
  );

  useEffect(() => {
    setShowExpiredAccessKeyModal(!changeAccessKeyFromMobile({ isHybrid, searchParams }));
  }, []);

  return {
    showExpiredAccessKeyModal,
    onAcceptExpiredAccessKeyModal: () => setShowExpiredAccessKeyModal(false),
  };
};

export { AppNoUserDataHook };
