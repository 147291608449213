// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Enumerations
import { UploadDocumentIdsEnumeration } from '../enums/upload-document-ids.enumeration';
// Handlers
import { UploadDocumentationHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-4-upload-documentation.handler';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import { Step4UploadDocumentationTracking } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/trackings/step-4-upload-documentation.tracking';
// Types
import { DocumentInfoType } from 'containers/financing/views/credit-limit-increase/contexts/types/document-info.type';
import { UploadDocumentCreditLimitIncreaseHookType } from './types/upload-document-credit-limit-increase.hook.type';
// Translations
import {
  UPLOAD_DOCUMENT_TRANSLATIONS,
  uploadDocumentTranlations,
} from 'translations/upload-document.translations';
// Utils
import { getState, onCancelUpload } from './utils/upload-document-credit-limit-increase.hook.utils';

const UploadDocumentCreditLimitIncreaseHook = (
  id: UploadDocumentIdsEnumeration
): UploadDocumentCreditLimitIncreaseHookType => {
  const [file, setFile] = useState<File | null>(null);
  const { formatMessage } = useCbIntl();
  const { currentCard } = AppContextConsumerHook();
  const { customerId, id: cardId } = currentCard;
  const {
    handleClickCancelUploadTracking,
    handleClickChooseFileTracking,
    handleClickRemoveUploadedFileTracking,
    handleClickRetryUploadTracking,
    handleShowInvalidFormatErrorTracking,
    handleShowOversizedErrorTracking,
    handleShowUploadErrorTracking,
  } = AppTrackingHook(Step4UploadDocumentationTracking);
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step4, setStep4 } = creditLimitContext;
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleUploadDocument, handleRejectDocument } = UploadDocumentationHandlers({
    cardId,
    creditLimitContext,
    setCurrentStep,
  });
  const controller1 = new AbortController();
  const controller2 = new AbortController();

  const onRemoveUploadedFile = () => {
    if (step4?.documentsInfo && step4.documentsInfo[id]?.dataToUpload?.documentId) {
      handleRejectDocument(step4.documentsInfo[id]?.dataToUpload?.documentId as string);
    }
    setStep4({ ...step4, documentsInfo: { ...step4.documentsInfo, [id]: {} } });
    setFile(null);
  };

  const onRetryUpload = () => {
    onRemoveUploadedFile();
  };

  useEffect(() => {
    handleUploadDocument({
      file,
      id,
      customerId,
      controller1,
      controller2,
      handleShowInvalidFormatErrorTracking,
      handleShowOversizedErrorTracking,
      handleShowUploadErrorTracking,
    });
  }, [file]);

  return {
    SYSTEM_ERROR_MESSAGE: formatMessage({ id: step4.documentsInfo?.[id]?.errorKeyMessage || '-' }),
    SYSTEM_ERROR_TAG: formatMessage({ id: step4.documentsInfo?.[id]?.errorKeyTitle || '-' }),
    BACK_OFFICE_ERROR_LINK: formatMessage({ id: uploadDocumentTranlations.RETRY_UPLOAD }),
    BACK_OFFICE_ERROR_MESSAGE: formatMessage({
      id: uploadDocumentTranlations.UPLOAD_FAILED_MESSAGE,
    }),
    BACK_OFFICE_ERROR_TAG: formatMessage({ id: uploadDocumentTranlations.UPLOAD_FAILED }),
    DEFAULT_TEXT: formatMessage({ id: file?.name ?? uploadDocumentTranlations.DRAG_AND_DROP }),
    DEFAULT_LINK: formatMessage({ id: uploadDocumentTranlations.CHOOSE_FILE }),
    LOADING_LINK: formatMessage({ id: uploadDocumentTranlations.CANCEL_UPLOAD }),
    SYSTEM_ERROR_LINK: formatMessage({ id: uploadDocumentTranlations.RETRY_UPLOAD }),
    UPLOADED_LINK: formatMessage({ id: uploadDocumentTranlations.REMOVE_UPLOADED_FILE }),
    REJECTED_LINK: formatMessage({ id: UPLOAD_DOCUMENT_TRANSLATIONS.REJECTED_LINK }),
    REJECTED_MESSAGE: formatMessage({ id: UPLOAD_DOCUMENT_TRANSLATIONS.REJECTED_MESSAGE }),
    REJECTED_TAG: formatMessage({ id: UPLOAD_DOCUMENT_TRANSLATIONS.REJECTED_TAG }),
    REJECTED_TEXT: formatMessage({ id: UPLOAD_DOCUMENT_TRANSLATIONS.REJECTED_TEXT }),
    state: getState(creditLimitContext.step4.documentsInfo?.[id] as DocumentInfoType),
    file,
    setFile: (file: File | null) => {
      handleClickChooseFileTracking();
      setFile(file);
    },
    onCancelUpload: () => {
      handleClickCancelUploadTracking();
      onCancelUpload(controller1, controller2);
    },
    onRemoveUploadedFile: () => {
      handleClickRemoveUploadedFileTracking();
      onRemoveUploadedFile();
    },
    onRetryUpload: () => {
      handleClickRetryUploadTracking();
      onRetryUpload();
    },
  };
};

export { UploadDocumentCreditLimitIncreaseHook };
