// Vendors
import { createTagAndsDispatchFile } from '@openbank/cf-ui-framework';
// Resolvers
import { createInsurancePrecontractResolver } from 'containers/financing/views/ppi/resolvers/financing-insurances.resolvers';
// Translations
import {
  DOWNLOAD_DOCUMENT_ERROR_MESSAGE,
  DOWNLOAD_DOCUMENT_SUCCESS_MESSAGE,
} from '../translations/financing-insurances-contracting-lpi-step3-download.translations';
// Types
import { FinancingInsurancesContractingLPIStep3DownloadHandlersType } from './types/financing-insurances-contracting-lpi-step3-download.handlers.type';
import { FinancingInsurancesContractingLPIStep3DownloadReturnHandlersType } from './types/financing-insurances-contracting-lpi-step3-download-return.handlers.type';
// Utils
import { setupToastConfiguration } from 'components/toast/utils/toast.utils';

const downloadLinkClickHandler = async ({
  handleToggleDownloaded,
  plan,
  productId,
  productType,
  setDownloading,
  setToastConfiguration,
}: Omit<
  FinancingInsurancesContractingLPIStep3DownloadHandlersType,
  'setPostalDocumentation'
>): Promise<void> => {
  try {
    setDownloading(true);

    if (!productId || !productType) {
      throw new Error();
    }

    const [blob, error] = await createInsurancePrecontractResolver({
      insuranceType: 'LPI',
      planCode: plan,
      productContractId: productId,
      productContractType: productType,
    });

    if (error || !blob) {
      throw new Error();
    }

    createTagAndsDispatchFile({ blob, filename: 'Vorvertragliche Informationen.pdf' });
    handleToggleDownloaded();
    setToastConfiguration(
      setupToastConfiguration({ error: false, description: DOWNLOAD_DOCUMENT_SUCCESS_MESSAGE })
    );
  } catch (_) {
    setToastConfiguration(
      setupToastConfiguration({ error: true, description: DOWNLOAD_DOCUMENT_ERROR_MESSAGE })
    );
  } finally {
    setDownloading(false);
  }
};

const togglePostalDocumentationHandler = ({
  setPostalDocumentation,
}: Pick<FinancingInsurancesContractingLPIStep3DownloadHandlersType, 'setPostalDocumentation'>) => {
  setPostalDocumentation(prev => !prev);
};

export const FinancingInsurancesContractingLPIStep3DownloadHandlers = ({
  handleToggleDownloaded,
  plan,
  productId,
  productType,
  setDownloading,
  setToastConfiguration,
  setPostalDocumentation,
}: FinancingInsurancesContractingLPIStep3DownloadHandlersType): FinancingInsurancesContractingLPIStep3DownloadReturnHandlersType => ({
  handleDownloadLinkClick: () =>
    downloadLinkClickHandler({
      handleToggleDownloaded,
      plan,
      productId,
      productType,
      setDownloading,
      setToastConfiguration,
    }),
  handleTogglePostalDocumentation: () =>
    togglePostalDocumentationHandler({ setPostalDocumentation }),
});
