// Vendors
import React from 'react';
// Components
import { LineSeparatorComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import FinancingPaymentMethodFirstStepRevolvingOptionsComponent from './components/options/financing-payment-method-first-step-revolving-options.component';
import FinancingPaymentMethodFirstStepRevolvingFeeSliderComponent from './components/fee-slider/financing-payment-method-first-step-revolving-fee-slider.component';
// Constants
import {
  LINE_SEPARATOR_PROPS,
  TITLE_PROPS,
} from './constants/financing-payment-method-first-step-revolving.constants';
// Hooks
import FinancingPaymentMethodFirstStepRevolvingHook from './hooks/financing-payment-method-first-step-revolving.hook';
// Styles
import {
  FinancingPaymentMethodFirstStepRevolvingComponentStyled,
  FinancingPaymentMethodFirstStepRevolvingDescriptionStyled,
} from './financing-payment-method-first-step-revolving.component.styled';
// Translations
import {
  DESCRIPTION,
  TITLE,
} from './translations/financing-payment-method-first-step-revolving.translations';
// Utils
import { isFullPayment } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

const FinancingPaymentMethodFirstStepRevolvingComponent = (): React.ReactElement | null => {
  const { paymentMethod } = FinancingPaymentMethodFirstStepRevolvingHook();

  return isFullPayment(paymentMethod) ? null : (
    <>
      <LineSeparatorComponent {...LINE_SEPARATOR_PROPS} />
      <FinancingPaymentMethodFirstStepRevolvingComponentStyled>
        <ParagraphComponent {...TITLE_PROPS}>
          <FormattedMessageComponent id={TITLE} />
        </ParagraphComponent>
        <FinancingPaymentMethodFirstStepRevolvingDescriptionStyled>
          <ParagraphComponent>
            <FormattedMessageComponent id={DESCRIPTION.FIRST_PARAGRAPH} />
          </ParagraphComponent>
        </FinancingPaymentMethodFirstStepRevolvingDescriptionStyled>
        <FinancingPaymentMethodFirstStepRevolvingOptionsComponent />
        <FinancingPaymentMethodFirstStepRevolvingFeeSliderComponent />
      </FinancingPaymentMethodFirstStepRevolvingComponentStyled>
    </>
  );
};

export default FinancingPaymentMethodFirstStepRevolvingComponent;
