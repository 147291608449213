// Vendors
import { useEffect } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import ExpensesCreditLimitIncreaseHandlers from '../handlers/expenses-credit-limit-increase.component.handler';
// Hooks
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Trackings
import Step2CreditLimitChageTracking from 'containers/financing/views/credit-limit-increase/components/step2/trackings/step-2-credit-limit-increase.tracking';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ExpensesCreditLimitIncreaseHookType } from './types/expenses-credit-limit-increase.component.hook.type';

const ExpensesCreditLimitIncreaseHook = (): ExpensesCreditLimitIncreaseHookType => {
  const { step2, setStep2 } = CreditLimitIncreaseContextConsumerHook();
  const {
    monthlyRentalExpense = '',
    monthlyLoanExpense = '',
    monthlyMortagageExpense = '',
  } = step2.personalData?.employments?.[0] || {};
  const { currentCard } = AppContextConsumerHook();
  const { currencyCode } = currentCard || {};
  const {
    handleClickSpendMonthlyOnRentTracking,
    handleClickSpendMonthlyOnLoansTracking,
    handleClickSpendMonthlyOnMortgagesTracking,
  } = AppTrackingHook(Step2CreditLimitChageTracking);
  const {
    handleUpdateStep2,
    handleChangeMonthlyMortagageExpense,
    handleChangeMonthlyLoanExpense,
    handleChangeMonthlyRentalExpense,
  } = ExpensesCreditLimitIncreaseHandlers({
    step2,
    setStep2,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleUpdateStep2, [monthlyRentalExpense, monthlyLoanExpense, monthlyMortagageExpense]);

  return {
    currency: currencyCode,
    isEditModeOn: step2.isExpensesEditModeOn ?? true,
    monthlyRentalExpense,
    monthlyLoanExpense,
    monthlyMortagageExpense,
    onChangeMonthlyRentalExpense: handleChangeMonthlyRentalExpense,
    onChangeMonthlyLoanExpense: handleChangeMonthlyLoanExpense,
    onChangeMonthlyMortagageExpense: handleChangeMonthlyMortagageExpense,
    onClickSpendMonthlyOnRent: handleClickSpendMonthlyOnRentTracking,
    onClickSpendMonthlyOnLoans: handleClickSpendMonthlyOnLoansTracking,
    onClickSpendMonthlyOnMortgages: handleClickSpendMonthlyOnMortgagesTracking,
    setIsEditModeOn: () => setStep2({ ...step2, isExpensesEditModeOn: true }),
  };
};

export default ExpensesCreditLimitIncreaseHook;
