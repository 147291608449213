// Enumerations
import { PPICardStatus } from '../enumerations/status.enumeration';
import { PPIInfoStatus } from '../enumerations/ppi-info-status.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Translations
import { CPI, LPI } from '../translations/financing-ppi-landing.translations';

const PPI_STATUS = {
  [PPIInfoStatus.ACTIVE]: PPICardStatus.ACTIVE,
  [PPIInfoStatus.CREATED]: PPICardStatus.ACTIVE,
  [PPIInfoStatus.COOLING_OFF]: PPICardStatus.ACTIVE,
  [PPIInfoStatus.UNHIRED]: PPICardStatus.INACTIVE,
  [PPIInfoStatus.EXPIRED]: PPICardStatus.INACTIVE,
  [PPIInfoStatus.TEMPORAL]: PPICardStatus.COVERED,
};

const LPI_PROPS = {
  description: LPI.DESCRIPTION,
  featureFlag: FeatureFlagsEnumeration.INSURANCES_LPI,
  image: {
    id: 'ppi-alternative',
    type: 'ILLUSTRATION',
  },
  items: [
    {
      description: LPI.ITEM_1_DESCRIPTION,
      icon: 'security',
      title: LPI.ITEM_1_TITLE,
    },
    {
      description: LPI.ITEM_2_DESCRIPTION,
      icon: 'bill',
      title: LPI.ITEM_2_TITLE,
    },
  ],
  routes: {
    cancel: RoutesEnumeration.LPI_CANCELLATION,
    contract: RoutesEnumeration.LPI_CONTRACTING,
  },
  title: LPI.TITLE,
};

const CPI_PROPS = {
  title: CPI.TITLE,
  featureFlag: FeatureFlagsEnumeration.INSURANCES_CPI,
  description: CPI.DESCRIPTION,
  image: {
    id: 'ppi-alternative',
    type: 'ILLUSTRATION',
  },
  items: [
    {
      description: CPI.FIRST_ITEM_DESCRIPTION,
      icon: 'giving_happyness',
      title: CPI.FIRST_ITEM_TITLE,
    },
    {
      description: CPI.SECOND_ITEM_DESCRIPTION,
      icon: 'payouts',
      title: CPI.SECOND_ITEM_TITLE,
    },
  ],
  routes: {
    cancel: RoutesEnumeration.CPI_CANCELLATION,
    contract: RoutesEnumeration.CPI_CONTRACTING,
  },
};

export { CPI_PROPS, LPI_PROPS, PPI_STATUS };
