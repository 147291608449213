// Types
import {
  CardSettingsChange3DStep1NewCodeCheckErrorBuilderHandlersType,
  CardSettingsChange3DStep1RepeatedCodeBuilderHandlersType,
  CardSettingsChange3DStep1RepeatedCodeBuilderReturnHandlersType,
  CardSettingsChange3DStep1RepeatedCodeSaveBuilderHandlersType,
  CardSettingsChange3DStep1RepeatedCodeValidateBuilderHandlersType,
} from './types/card-settings-change-3d-step1-repeated-code.handlers.type';
// Utilities
import {
  codeHasCorrectLengthAndNoEqual,
  codes3DSecureAreNotEquals,
} from 'containers/card-settings/views/change-3d/components/step1/utils/card-settings-change-3d-step1.utils';

const saveRepeated3dCodeInputChangeEventHandler = ({
  repeatedCode,
  setError,
  setRepeated3DCode,
}: CardSettingsChange3DStep1RepeatedCodeSaveBuilderHandlersType): void => {
  setError(false);
  setRepeated3DCode(repeatedCode);
};

const validateRepeated3dCodeInputChangeEventHandler = ({
  handleRepeated3DCodeInputErrorTracking,
  new3DCode,
  repeated3DCode,
  setError,
}: CardSettingsChange3DStep1RepeatedCodeValidateBuilderHandlersType): void => {
  if (codes3DSecureAreNotEquals({ new3DCode, repeated3DCode })) {
    handleRepeated3DCodeInputErrorTracking();
    setError(true);
  }
};

const checkNewCodeErrorEventHandler = ({
  new3DCode,
  repeated3DCode,
  setError,
}: CardSettingsChange3DStep1NewCodeCheckErrorBuilderHandlersType): void => {
  setError(codeHasCorrectLengthAndNoEqual({ code: repeated3DCode, otherCode: new3DCode }));
};

const CardSettingsChange3dStep1RepeatedCodeHandlers = ({
  handleRepeated3DCodeInputErrorTracking,
  new3DCode,
  repeated3DCode,
  setError,
  setRepeated3DCode,
}: CardSettingsChange3DStep1RepeatedCodeBuilderHandlersType): CardSettingsChange3DStep1RepeatedCodeBuilderReturnHandlersType => ({
  handleCheckNewCodeErrorEvent: () =>
    checkNewCodeErrorEventHandler({
      new3DCode,
      repeated3DCode,
      setError,
    }),
  handleSaveRepeated3dCodeInputChangeEvent: (repeatedCode: string) =>
    saveRepeated3dCodeInputChangeEventHandler({ repeatedCode, setError, setRepeated3DCode }),
  handleValidateRepeated3dCodeInputChangeEvent: () =>
    validateRepeated3dCodeInputChangeEventHandler({
      handleRepeated3DCodeInputErrorTracking,
      new3DCode,
      repeated3DCode,
      setError,
    }),
});

export { CardSettingsChange3dStep1RepeatedCodeHandlers };
