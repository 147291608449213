const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyBillingAddress.step2.';
const DOMAIN_CC =
  'cobrandedCC.services.userSettings.privateArea.personalInformation.personalDetails.modifyBillingAddress.step2.';

const DESCRIPTION = `${DOMAIN}description`,
  SUMMARY_TITLE = `${DOMAIN}summary.title`,
  MESSAGE_DESCRIPTION = `${DOMAIN}cbMessage`;

const READING_REQUIRED = `${DOMAIN_CC}script.first.title`,
  POLICY_OBLIGATION = `${DOMAIN_CC}script.first.description`,
  ARE_YOU_SATISFIED = `${DOMAIN_CC}script.second.title`,
  CONFIRM_TRANSACTION = `${DOMAIN_CC}script.second.description`;

export {
  DESCRIPTION,
  POLICY_OBLIGATION,
  READING_REQUIRED,
  MESSAGE_DESCRIPTION,
  CONFIRM_TRANSACTION,
  ARE_YOU_SATISFIED,
  SUMMARY_TITLE,
};
