// Vendors
import styled from 'styled-components';

const TooltipContentComponentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

export { TooltipContentComponentContainerStyled };
