// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useTransactionsReportNotReceivedAdditionalInfoActionsHook } from './hooks/transactions-report-not-received-additional-info-actions.hook';
// Types
import { TransactionsReportNotReceivedAdditionalInfoActionsComponentType } from './types/transactions-report-not-received-additional-info-actions.component.type';

export const TransactionsReportNotReceivedAdditionalInfoActionsComponent = ({
  handleSubmit,
  isDirty,
  isValid,
}: TransactionsReportNotReceivedAdditionalInfoActionsComponentType): React.ReactElement => {
  const { handleNextButtonClick } = useTransactionsReportNotReceivedAdditionalInfoActionsHook();

  return (
    <WizardStepFooterComponent
      shouldShowModal={isDirty}
      {...WIZARD_CANCEL_MODAL_PROPS}
      rightButtonConfiguration={{
        disabled: !isValid,
        onClick: handleSubmit(handleNextButtonClick),
      }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};
