// Vendors
import {
  TrackingEventsEnum,
  TrackingViewEventReturnType,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { DocumentationBuilderReturnTrackingHandlersType } from './types/documentation-builder-return.tracking.handlers.type';

const clickCategoryListTrackingHandler = (category?: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickBox',
    eventLabel: category ?? 'categoryItem',
  },
  type: TrackingEventsEnum.LINK,
});

const displayDocumentationPageTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/documentation',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'accounts',
    interactionType: 'page',
    serviceType: 'productInformation',
  },
  type: TrackingEventsEnum.VIEW,
});

const DocumentationTrackingHandlers = (
  track: TrackBuilderType
): DocumentationBuilderReturnTrackingHandlersType => ({
  handleDisplayDocumentationPageTracking: () => track(displayDocumentationPageTrackingHandler()),
  handleClickCategoryListTracking: (category?: string) =>
    track(clickCategoryListTrackingHandler(category)),
});

export { DocumentationTrackingHandlers };
