// Constants
import { messageConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Translations
import { translations } from '../../translations/credit-limit-increase-status.translations';

const statusToMessageTypeMap = {
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: messageConstants.TYPES.SUCCESS,
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: messageConstants.TYPES.SUCCESS,
  [CreditLimitIncreaseStepsEnumeration.PENDING]: messageConstants.TYPES.INFORMATIVE,
  [CreditLimitIncreaseStepsEnumeration.RISK_REVIEW]: messageConstants.TYPES.INFORMATIVE,
  [CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER]: messageConstants.TYPES.WARNING,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT]: messageConstants.TYPES.WARNING,
  [CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION]: messageConstants.TYPES.WARNING,
  [CreditLimitIncreaseStepsEnumeration.SELECT_METHOD]: messageConstants.TYPES.WARNING,
  [CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION]:
    messageConstants.TYPES.WARNING,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED]: messageConstants.TYPES.WARNING,
};

const statusToTitleMap = {
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: translations.APPROVED_TITLE,
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: translations.APPROVED_TITLE,
  [CreditLimitIncreaseStepsEnumeration.PENDING]: translations.PENDING_TITLE,
  [CreditLimitIncreaseStepsEnumeration.RISK_REVIEW]: translations.PENDING_TITLE,
  [CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER]:
    translations.EVALUATE_NEW_BETTER_OFFER_TITLE,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_TITLE,
  [CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_TITLE,
  [CreditLimitIncreaseStepsEnumeration.SELECT_METHOD]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_TITLE,
  [CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_TITLE,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED]:
    translations.ADDITIONAL_DOCUMENTATION_TITLE,
};

const statusToDescriptionMap = {
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: translations.APPROVED_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: translations.APPROVED_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.PENDING]: translations.PENDING_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.RISK_REVIEW]: translations.PENDING_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.SELECT_METHOD]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_DESCRIPTION,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED]:
    translations.ADDITIONAL_DOCUMENTATION_DESCRIPTION,
};

const statusToCTAMap = {
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: translations.APPROVED_CTA,
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: translations.APPROVED_CTA,
  [CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER]:
    translations.EVALUATE_NEW_BETTER_OFFER_CTA,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_CTA,
  [CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_CTA,
  [CreditLimitIncreaseStepsEnumeration.SELECT_METHOD]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_CTA,
  [CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION]:
    translations.COMPLETE_CREDIT_LIMIT_INCREASE_CTA,
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED]:
    translations.ADDITIONAL_DOCUMENTATION_CTA,
};

const statusToTrackingCTAValueMap = {
  [CreditLimitIncreaseStepsEnumeration.APPROVAL]: 'completeCreditLimitIncreaseButton',
  [CreditLimitIncreaseStepsEnumeration.SIGNATURE]: 'completeCreditLimitIncreaseButton',
  [CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER]: 'selectYourNewCreditLimitButton',
  [CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED]: 'uploadDocumentationButton',
};

const creditLimitStatusesWithMessage = [
  CreditLimitIncreaseStepsEnumeration.APPROVAL,
  CreditLimitIncreaseStepsEnumeration.SIGNATURE,
  CreditLimitIncreaseStepsEnumeration.PENDING,
  CreditLimitIncreaseStepsEnumeration.RISK_REVIEW,
  CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER,
  CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT,
  CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION,
  CreditLimitIncreaseStepsEnumeration.SELECT_METHOD,
  CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION,
  CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED,
];

export {
  creditLimitStatusesWithMessage,
  statusToCTAMap,
  statusToDescriptionMap,
  statusToMessageTypeMap,
  statusToTitleMap,
  statusToTrackingCTAValueMap,
};
