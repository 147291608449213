// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const DashboardSpentExpensesListComponentStyled = styled.div.attrs(({ testId }: { testId: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`;