// Constants
import { GENERATE_OTP_SERVICE_CONFIGURATION } from 'containers/iban-billings/views/change-iban/components/step2/components/actions/services/constants/change-iban-step2.services.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';

export const getChallengeConfiguration = ({
  cardId,
  challengeError,
  handleChallengeError,
  handleChallengeRetryButtonClick,
  handleChallengeSuccess,
  ibanValue,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: GENERATE_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: {
    pathParams: { cardId },
    params: { externalIban: ibanValue },
  },
  baseServiceMethod: 'patch',
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
