// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DeviceHeaderInfoComponentStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    height: auto;
    flex-direction: column;
    gap: 0;
  }
`;