// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const SupportCancelContractSecondStepContainerHeaderStyled = styled.div`
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  display: grid;
`;

SupportCancelContractSecondStepContainerHeaderStyled.displayName =
  'SupportCancelContractSecondStepContainerHeaderStyled';

const SupportCancelContractSecondStepContainerStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${convertPxsToRems({ pixels: 16, base: 14 })};
  height: ${convertPxsToRems({ pixels: 78, base: 14 })};

  ${mobileMediaQuery} {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: ${convertPxsToRems({ pixels: 16, base: 14 })} 0;
    gap: ${({ theme }) => theme.spaces.spacingXxxs};
  }
`;

SupportCancelContractSecondStepContainerStyled.displayName =
  'SupportCancelContractSecondStepContainerStyled';

const CancelContractSegmentStyled = styled.div`
  flex: 1;

  ${mobileMediaQuery} {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spaces.spacingXxxs};
  }
`;

CancelContractSegmentStyled.displayName = 'CancelContractSegmentStyled';

export {
  SupportCancelContractSecondStepContainerHeaderStyled,
  SupportCancelContractSecondStepContainerStyled,
  CancelContractSegmentStyled,
};
