export default {
  CONTEXT_DEFAULT_VALUE: {
    avatar: null,
    cropCompleted: false,
    photo: '',
    photoModal: null,
    setAvatar: (): null => null,
    setCropCompleted: (): null => null,
    setPhoto: (): null => null,
    setPhotoModal: (): null => null,
  },
};
