// Vendors
import React, { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useLocation, useNavigate } from 'react-router-dom';
// Components
import KoMessageComponent from '../components/ko-message/ko-message.component';
import SecurityVerificationComponent from '../components/security-verification/security-verification.component';
import ViewCodeComponent from '../components/view-code/view-code.component';
// Configs
import { getCardDetailProps } from '../configs/check-cvv-pin.configs';
// Constants
import checkCvvPinConstants from '../constants/check-cvv-pin.constants';
// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { getHandlers, getTrackingHandlers } from '../handlers/check-cvv-pin.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { StepType } from '../types/check-cvv-pin.type';
import { UseViewStepsProps, UseViewStepsReturnType } from './types/check-cvv-pin.type';

const { CHALLENGE, CODE, DEFAULT_CARD_ART, KO } = checkCvvPinConstants;

export const useViewSteps = ({ type }: UseViewStepsProps): UseViewStepsReturnType => {
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();

  const [art, setArt] = useState(DEFAULT_CARD_ART);
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [challengeError, setChallengeError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [step, setStep] = useState<StepType>(CHALLENGE);

  const { currentCard } = AppContextConsumerHook();
  const cardId = currentCard.id;

  const trackingHandlers = AppTrackingHook(getTrackingHandlers(type));

  const {
    handleContinue,
    handleDownloadCardArt,
    handleError,
    handleGoBack,
    handleGoToPreviousPath,
    handleRestartProcess,
    handleSuccess,
    handleTryAgain,
  } = getHandlers({
    cardId,
    navigate,
    setArt,
    setChallengeError,
    setCode,
    setError,
    setStep,
    setSuccess,
    tracking: trackingHandlers,
    type,
  });

  useEffect(() => {
    if (currentCard.productTypeCode) {
      handleDownloadCardArt(currentCard.productTypeCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard]);

  const renderSecurityVerification = (): React.ReactElement => {
    const cardProps = getCardDetailProps({ art, currentCard, formatMessage });

    return (
      <SecurityVerificationComponent
        card={cardProps}
        cardId={cardId}
        challengeError={challengeError}
        onCancel={handleGoBack}
        onContinue={handleContinue}
        onError={handleError}
        onSuccess={handleSuccess}
        onTryAgain={handleTryAgain}
        success={success}
        tracking={trackingHandlers.securityVerification}
        type={type}
      />
    );
  };

  const renderCode = (): React.ReactElement => (
    <ViewCodeComponent
      code={code}
      formatMessage={formatMessage}
      handleRestartProcess={handleRestartProcess}
      onGoToPreviousPath={handleGoToPreviousPath}
      tracking={trackingHandlers.viewCode}
      type={type}
    />
  );

  const renderKo = (): React.ReactElement => (
    <KoMessageComponent
      onTryAgain={handleRestartProcess}
      tracking={trackingHandlers.koMessage}
      type={type}
    />
  );

  const render = (): React.ReactElement => {
    switch (step) {
      case CODE:
        return renderCode();
      case KO:
        return renderKo();
      case CHALLENGE:
      default:
        return renderSecurityVerification();
    }
  };

  return {
    render,
    step,
    error,
  };
};
