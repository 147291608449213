// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, RadioListComponent } from '@openbank/cb-ui-commons';
import { CardPinInfoModalComponent } from './components/card-pin-info-modal/card-pin-info-modal.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Configurations
import { getRecoveryOptionsConfiguration } from 'containers/recovery-key/components/step3/configurations/recovery-key-step3.configuration';
// Constants
import { RECOVERY_KEY_STEP3_DESCRIPTION_PROPS } from './constants/recovery-key-step3.constants';
// Hooks
import useRecoveryKeyStep3Hook from './hooks/recovery-key-step3.hook';
// Styles
import { RecoveryKeyStep3ComponentStyled } from './recovery-key-step3.component.styled';
// Translations
import { DESCRIPTION } from './translations/recovery-key-step3.translations';
// Utilities
import { noneRecoveryOptionSelected } from './utils/recovery-key-step3.utils';

const RecoveryKeyStep3Component = (): React.ReactElement => {
  const {
    formatMessage,
    handleGoToStep4RecoveryFlowButtonClick: nextClick,
    handleSetRecoveryOption: onChange,
    recoveryOption,
    passwordRecoveryOptions,
    onInfoLickClick,
    onCloseModal,
    open,
  } = useRecoveryKeyStep3Hook();

  return (
    <RecoveryKeyStep3ComponentStyled>
      {open && <CardPinInfoModalComponent {...{ onCloseModal, formatMessage }} />}
      <ParagraphComponent {...RECOVERY_KEY_STEP3_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <RadioListComponent
        {...{ onChange }}
        options={getRecoveryOptionsConfiguration({
          formatMessage,
          passwordRecoveryOptions,
          onInfoLickClick,
        })}
        value={recoveryOption}
      />
      <WizardStepFooterComponent
        rightButtonConfiguration={{
          onClick: nextClick,
          disabled: noneRecoveryOptionSelected(recoveryOption),
        }}
      />
    </RecoveryKeyStep3ComponentStyled>
  );
};

export { RecoveryKeyStep3Component };
