// Vendors
import { mobileMediaQuery, convertPxsToRems } from '@openbank/cf-ui-framework';
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const SettingsRowToggleInputCurrencyWrapperStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
`;

const ToggleRowInputComponentStyled = styled.div`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  display: grid;
  grid-template-areas: "image content input";
  grid-template-columns: minmax(${convertPxsToRems({ pixels: 24, base: 16 })}, max-content) 3fr 0.2fr;
  grid-auto-flow: column;
  align-items: flex-start;
  margin: ${({ theme }) => theme.spaces.spacingS} 0;

  ${mobileMediaQuery} {
    grid-template-columns: minmax(${convertPxsToRems({ pixels: 24, base: 16 })}, max-content) auto;
    grid-template-areas:
      "image content"
      "input . ";
  }
`;

const ToggleRowAssetStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXs};
  grid-area: content;
`;

const RowToggleInputPreffixCurrencyContainerStyled = styled.div`
  display: flex;
  align-self: flex-start;
  grid-area: input;
  width: 276px;

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

const SettingsRowToggleInputCurrencyParragraphStyled = styled.div`
  padding-top: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    margin: 0;
    padding: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingXl} 0 0`};
  }
`;

export { SettingsRowToggleInputCurrencyWrapperStyled, ToggleRowInputComponentStyled, ToggleRowAssetStyled, RowToggleInputPreffixCurrencyContainerStyled, SettingsRowToggleInputCurrencyParragraphStyled };