// Enumerations
import { PhotoModalIdsEnumeration } from 'containers/personal/views/photo/enumerations/photo-modal-ids.enumeration';
// Types
import { PersonalPhotoNameButtonBuilderReturnHandlersType } from './types/personal-photo-name-button-builder-return.handlers.type';
import { PersonalPhotoNameButtonBuilderHandlersType } from './types/personal-photo-name-button-builder.handlers.type';
// Utilities
import { convertBlobToBase64 } from 'containers/personal/views/photo/components/navigation-footer/handlers/utils/personal-photo-navigation-footer.handlers.utils';
import { fileIsLowerThan3MB } from './utils/personal-photo-name-button.handlers.utils';

const savePhotoUploadedInputChangeHandler = async ({
  inputRef,
  setAvatar,
  setPhoto,
  setPhotoModal,
}: Omit<
  PersonalPhotoNameButtonBuilderHandlersType,
  'handleUploadPhotoButtonClickTracking' | 'photo'
>): Promise<void> => {
  const file: File = inputRef?.current?.files?.[0] || new File([], '');

  if (fileIsLowerThan3MB(file)) {
    const photo = await convertBlobToBase64(file);

    setAvatar(null);
    setPhoto(photo);
    setPhotoModal(PhotoModalIdsEnumeration.CROP_PHOTO);
  } // Else: TODO: Show error message ASK UX
};

const uploadPhotoButtonClickHandler = ({
  handleUploadPhotoButtonClickTracking,
  inputRef,
  photo,
  setPhotoModal,
}: Omit<PersonalPhotoNameButtonBuilderHandlersType, 'setAvatar' | 'setPhoto'>): any => {
  handleUploadPhotoButtonClickTracking();
  photo
    ? setPhotoModal(PhotoModalIdsEnumeration.CROP_PHOTO)
    : openInputFilePromptHandler({ inputRef });
};

const openInputFilePromptHandler = ({
  inputRef,
}: Pick<PersonalPhotoNameButtonBuilderHandlersType, 'inputRef'>): any => {
  inputRef?.current?.click();
};

const PersonalPhotoNameButtonHandlers = ({
  handleUploadPhotoButtonClickTracking,
  inputRef,
  photo,
  setAvatar,
  setPhoto,
  setPhotoModal,
}: PersonalPhotoNameButtonBuilderHandlersType): PersonalPhotoNameButtonBuilderReturnHandlersType => ({
  handleOpenInputFilePrompt: () => openInputFilePromptHandler({ inputRef }),
  handleSavePhotoUploadedInputChange: () =>
    savePhotoUploadedInputChangeHandler({ inputRef, setAvatar, setPhoto, setPhotoModal }),
  handleUploadPhotoButtonClick: () =>
    uploadPhotoButtonClickHandler({
      handleUploadPhotoButtonClickTracking,
      photo,
      setPhotoModal,
      inputRef,
    }),
});

export default PersonalPhotoNameButtonHandlers;
