// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Types
import { FinancingPPIContractingDownloadComponentType } from '../types/financing-ppi-contracting-download.component.type';

const fetchContractingPPIDocumentService = async ({
  serviceParams: params,
  serviceMethod = 'post',
}: Pick<FinancingPPIContractingDownloadComponentType, 'serviceParams' | 'serviceMethod'>): Promise<
  [Record<string, never> | Blob, Response]
> => apiUtilsCb[serviceMethod](params);

export { fetchContractingPPIDocumentService };
