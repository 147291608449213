// Vendors
import styled from 'styled-components';

const PersonalAddressStep1Styled = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};

  & section {
    width: 100%;
  }
`;

export { PersonalAddressStep1Styled };
