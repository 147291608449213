// Vendors
import React from 'react';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportPaymentConfirmationActionsComponent } from './components/actions/transactions-report-payment-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/transactions-report-payment-confirmation.constants';
// Hooks
import { useTransactionsReportPaymentConfirmationHook } from './hooks/transactions-report-payment-confirmation.component.hook';
// Styles
import { TransactionsReportPaymentConfirmationComponentStyled } from './transactions-report-payment-confirmation.component.styled';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-payment-confirmation.translations';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const TransactionsReportPaymentConfirmationComponent = (): React.ReactElement => {
  const { cardId, files, formatMessage, paymentMethod, transactionId } =
    useTransactionsReportPaymentConfirmationHook();

  return (
    <TransactionsReportPaymentConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            paymentMadeForOtherReasons: { payOthersPaymentMethod: paymentMethod },
            documents: getDocumentIds(files),
          },
        }}
      />
      <TransactionsReportPaymentConfirmationActionsComponent />
    </TransactionsReportPaymentConfirmationComponentStyled>
  );
};