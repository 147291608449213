const DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING =
  'cobranded.services.userSettings.privateArea.financing.paymentMethod.first.revolving.';

const TITLE = `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING}title`;

const DESCRIPTION = {
  FIRST_PARAGRAPH: `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING}description.firstParagraph`,
};

export { TITLE, DESCRIPTION };
