// Types
import { GetRadioButtonConfigurationType } from './types/get-radio-button-configuration.type';
import { GetRadioButtonConfigurationReturnType } from './types/get-radio-button-configuration-return.type';
// Utils
import { isSelected } from './utils/personal-number-step3-confirmation-option.component.configurations.utils';

export const getRadioButtonConfiguration = ({
  confirmationCodeOption,
  eventLabel,
  handleConfirmationOptionChange,
  testId,
  value,
}: GetRadioButtonConfigurationType): GetRadioButtonConfigurationReturnType => ({
  onChange: () => handleConfirmationOptionChange({ eventLabel, value }),
  testing: {
    description: testId,
  },
  value: isSelected({ confirmationCodeOption, value }),
});
