// Types
import { CardSettingsActivateCardFailedHandlersType } from './types/card-settings-activate-card-failed.handlers.type';
import { CardSettingsActivateCardFailedReturnHandlersType } from './types/card-settings-activate-card-failed-return.handlers.type';

const retryHandler = ({
  handleRetryLinkClickTracking,
  setChallenge,
}: CardSettingsActivateCardFailedHandlersType) => {
  handleRetryLinkClickTracking();
  setChallenge(null);
};

const CardSettingsActivateCardFailedHandlers = (
  props: CardSettingsActivateCardFailedHandlersType
): CardSettingsActivateCardFailedReturnHandlersType => ({
  handleRetryLinkClick: () => retryHandler(props),
});

export default CardSettingsActivateCardFailedHandlers;
