// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkCommonComponent } from './card-settings-resume-link.common.component';
// Types
import { CardSettingsResumeLinkComponentType } from './types/card-settings-resume-link.component.type';

const CardSettingsResumeLinkComponent = (
  props: CardSettingsResumeLinkComponentType
): React.ReactElement<CardSettingsResumeLinkComponentType> => (
  <CardSettingsResumeLinkCommonComponent {...props} />
);

export { CardSettingsResumeLinkComponent };
