// Types
import {
  FilterModalHandlersPropsType,
  FilterModalReturnHandlersType,
} from './types/filter-modal.handlers.type';
// Utiltiies
import { formatFormValueParams } from '../utils/filter-modal.utils';

const formValuesChangeHandler = ({ field, setFormValues, value }) => {
  setFormValues(prev => ({ ...prev, [field]: value }));
};

const cancelFilterModalHandler = ({ setIsShowFilterModal, copyOfFormValues, setFormValues }) => {
  setFormValues(copyOfFormValues);
  setIsShowFilterModal(false);
};

const onApplyFilterHandler = ({
  formValues,
  onApplyFilter,
  setCopyOfFormValues,
  setFormValuesParams,
  setIsShowFilterModal,
}) => {
  setCopyOfFormValues(formValues);
  setFormValuesParams(formatFormValueParams(formValues));
  setIsShowFilterModal(false);
};

const FilterModalHandlers = ({
  copyOfFormValues,
  formValues,
  onApplyFilter,
  setCopyOfFormValues,
  setFormValues,
  setFormValuesParams,
  setIsShowFilterModal,
}: FilterModalHandlersPropsType): FilterModalReturnHandlersType => ({
  handleFormValuesChange: ({ field, value }): void =>
    formValuesChangeHandler({ field, setFormValues, value }),
  handleOnClickCancel: (): void =>
    cancelFilterModalHandler({ setIsShowFilterModal, copyOfFormValues, setFormValues }),
  handleApplyFilter: (): void =>
    onApplyFilterHandler({
      formValues,
      setIsShowFilterModal,
      onApplyFilter,
      setFormValuesParams,
      setCopyOfFormValues,
    }),
});

export { FilterModalHandlers };
