// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const RECOVERY_KEY_STEP_4_SIGNATURE_KEY_DESCRIPTION_PROPS = {
    testId: 'recovery-key-step-4-signature-key-description',
  },
  RECOVERY_KEY_STEP_4_SIGNATURE_KEY_TITLE_PROPS = {
    tag: titleConstants.TAGS.H4,
    testId: 'recovery-key-step-4-signature-key-title',
  };

export {
  RECOVERY_KEY_STEP_4_SIGNATURE_KEY_DESCRIPTION_PROPS,
  RECOVERY_KEY_STEP_4_SIGNATURE_KEY_TITLE_PROPS,
};
