// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.';

export default {
  TITLE: `${DOMAIN}title`,
  ACCESS_TITLE: `${DOMAIN}requestAccess.title`,
  PORTABILITY_TITLE: `${DOMAIN}requestPortability.title`,
  ACCESS_CARD_BUTTON: `${DOMAIN}accessCard.requestButton`,
  PORTABILITY_CARD_BUTTON: `${DOMAIN}portabilityCard.requestButton`,
  [TransactionsFetchErrorEnumeration.NO_RESULTS]: {
    ERROR_DESCRIPTION: `${DOMAIN}resultsErrorDescription`,
    ERROR_TITLE: `${DOMAIN}resultsErrorDescription`,
  },
};
