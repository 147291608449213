// Vendors
import React from 'react';
// Configurations
import { getMapSelectorDescription } from './configurations/settings-card-control-locations-continents.configurations';
// Components
import { MapSelectorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import SettingsCardControlLocationsContinentsCountriesComponent from './components/countries/settings-card-control-locations-continents-countries.component';
import SettingsCardControlLocationsContinentsToggleComponent from './components/toggle/settings-card-control-locations-continents-toggle.component';
// Styles
import { SettingsCardControlLocationsContinentsComponentStyled } from './settings-card-control-locations-continents.component.styled';
// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { SettingsCardControlLocationsContinentsComponentType } from './types/settings-card-control-locations-continents.component.type';
// Translations
import {
  CONTINENT_TITLE,
  CONTINENTS_TRANSLATION_KEY_PREFFIX,
} from './translations/settings-card-control-locations-continents.translations';
// Utilities
import { getAllEnabledCountriesFromContinent } from './utils/setting-card-control-locations-continents.utils';
import { formatLocationTranslationId } from '../../utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsContinentsComponent = ({
  continents,
}: SettingsCardControlLocationsContinentsComponentType): React.ReactElement<SettingsCardControlLocationsContinentsComponentType> => (
  <SettingsCardControlLocationsContinentsComponentStyled>
    {continents.map(
      ({ code, countries, numberOfCountries }: CardControlContinentComponentType, key: number) => (
        <MapSelectorComponent
          key={key}
          description={
            <FormattedMessageComponent
              id={getMapSelectorDescription({ countries, numberOfCountries })}
              values={{
                amount: getAllEnabledCountriesFromContinent(countries),
                numberOfCountries,
              }}
            />
          }
          data={SettingsCardControlLocationsContinentsCountriesComponent(countries)}
          title={
            <FormattedMessageComponent
              id={CONTINENT_TITLE}
              values={{
                title: (
                  <FormattedMessageComponent
                    id={formatLocationTranslationId({
                      preffix: CONTINENTS_TRANSLATION_KEY_PREFFIX,
                      location: code,
                    })}
                  />
                ),
                numberOfCountries,
              }}
            />
          }
          toggle={
            <SettingsCardControlLocationsContinentsToggleComponent {...{ continents, countries, countryId: code }} />
          }
        />
      )
    )}
  </SettingsCardControlLocationsContinentsComponentStyled>
);

export default SettingsCardControlLocationsContinentsComponent;
