// Vendors
import { assetConstants, linkConstants } from '@openbank/cb-ui-commons';

const TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_ASSET_PROPS = {
    id: 'points-reward-amazon-alternative',
    sizes: {
      height: 120,
      width: 120,
    },
    type: assetConstants.TYPES.ILLUSTRATION,
    testId: 'transactions-landing-row-details-create-plan-asset',
  },
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_LINK_PROPS = {
    testId: 'transactions-landing-row-details-create-plan-link',
  },
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_BUTTON_PROPS = {
    testId: 'transactions-landing-row-details-create-plan-button',
  },
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_DESCRIPTION_PROPS = {
    id: 'default_description',
    testId: 'transactions-landing-row-details-create-plan-description',
  },
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_PROPS = {
    tracking: {
      base: 'createInstallmentPlanModal',
      link: 'cancelCreation',
    },
  };

export {
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_ASSET_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_BUTTON_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_DESCRIPTION_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_LINK_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_PROPS,
};
