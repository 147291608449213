// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingStep2ExpandableDescriptionLinkTrackingHandlersType } from './types/financing-ppi-contracting-step2-expandable-description-link.tracking.handlers.type';

const openPPIContractingTermsAndConditionsLinkClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'generalTermsAndConditions',
    },
    type: TrackingEventsEnum.LINK,
  });

const FinancingPPIContractingStep2ExpandableLinkTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingStep2ExpandableDescriptionLinkTrackingHandlersType => ({
  handleOpenPPIContractingTermsAndConditionsLinkClickTracking: () =>
    track(openPPIContractingTermsAndConditionsLinkClickTrackingHandler()),
});

export { FinancingPPIContractingStep2ExpandableLinkTrackingHandlers };
