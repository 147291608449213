// Vendors
import React from 'react';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TRANSACTION_DATE_FORMAT_OPTIONS,
  TRANSACTIONS_DATE_PROPS,
} from './constants/transactions-date.constants';
// Translations
import translations from './translations/transactions-date.translations';
// Types
import { TransactionsDateComponentType } from './types/transactions-date.component.type';
// Utils
import { getDetailsDateTime } from './utils/transactions-date.component.util';

const TransactionsDateComponent = ({
  date,
  formatDate,
  time,
}: TransactionsDateComponentType): React.ReactElement => (
  <ResumeItemComponent
    {...TRANSACTIONS_DATE_PROPS}
    label={<FormattedMessageComponent id={translations.DATE} />}
    value={
      <FormattedMessageComponent
        id={translations.TIME}
        values={{
          dateTime: getDetailsDateTime({
            date,
            time,
            formatDate,
            options: TRANSACTION_DATE_FORMAT_OPTIONS,
          }),
        }}
      />
    }
  />
);

export { TransactionsDateComponent };
