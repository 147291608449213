// Vendors
import React from 'react';
// Constants
import constants from './constants/security-keys-access-key-change-first-step.constants';
// Components
import { PasswordModuleComponent } from '@openbank/cb-ui-commons';
import SecurityKeysAccessKeyChangeFirstStepActionsComponent from './actions/security-keys-access-key-change-first-step-actions.component';
// Translations
import translations from '../../translations/security-keys-access-key-change.translations';
// Hooks
import SecurityKeysAccessKeyChangeFirstStepHook from './hooks/security-keys-access-key-change-first-step.hook';

const SecurityKeysAccessKeyChangeFirstStep = (): React.ReactElement => {
  const {
    accessKey,
    formatMessage,
    handleSaveNewModifyAccessKeyChangeInputEvent,
    handlePasswordPositionInputClickTracking,
    handleShowHideInputClickTracking,
  } = SecurityKeysAccessKeyChangeFirstStepHook();

  return (
    <>
      <PasswordModuleComponent
        {...constants.ACCESS_KEY_CHANGE_FIRST_STEP_PASSWORD_PROPS}
        analytics={{ handleOnClick: () => handleShowHideInputClickTracking() }}
        onClick={handlePasswordPositionInputClickTracking}
        description={formatMessage({ id: translations.DESCRIPTION_PASSWORD_TEXT })}
        onChange={handleSaveNewModifyAccessKeyChangeInputEvent}
        translations={{
          SHOW_PASSWORD: formatMessage({ id: translations.SHOW_PASSWORD_TEXT }),
          HIDE_PASSWORD: formatMessage({ id: translations.HIDE_PASSWORD_TEXT }),
        }}
        title={formatMessage({ id: translations.ENTER_CURRENT_ACCES_KEY })}
        value={accessKey}
      />
      <SecurityKeysAccessKeyChangeFirstStepActionsComponent />
    </>
  );
};

export default SecurityKeysAccessKeyChangeFirstStep;
