// Resolvers
import { generateNewNumberOtpResolver } from '../resolvers/personal-number-step2.resolvers';
// Types
import { PersonalEmailStep2BuilderHandlersType } from './types/personal-number-step2-builder.handlers.type';
import { PersonalNumberStep2BuilderReturnHandlersType } from './types/personal-number-step2-builder-return.handlers.type';

const requestNewNumberOtpLinkClickHandler = async ({
  handleRequestNewNumberOtpLinkClickTracking,
  newPhoneNumber,
  newPrefix,
  progressId,
  setChallenged,
  setFetching,
  setError,
  setOtpId,
}: PersonalEmailStep2BuilderHandlersType): Promise<void> => {
  setFetching(true);
  setError(false);
  handleRequestNewNumberOtpLinkClickTracking();

  const [otpId, error] = await generateNewNumberOtpResolver({
    phoneCountryCode: Number(newPrefix),
    phone: newPhoneNumber,
    progressId,
  });

  if (otpId) {
    setChallenged(true);
    setOtpId(otpId);
  } else {
    setError(true);
  }

  setFetching(false);
};

const PersonalNumberStep2Handlers = (
  props: PersonalEmailStep2BuilderHandlersType
): PersonalNumberStep2BuilderReturnHandlersType => ({
  handleRequestNewNumberOtpLinkClick: () => requestNewNumberOtpLinkClickHandler(props),
});

export default PersonalNumberStep2Handlers;
