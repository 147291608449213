// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

const RECOVERY_KEY_STEP5_MESSAGE_PROPS = {
    testId: 'recovery-key-step5-message',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  RECOVERY_KEY_STEP5_MESSAGE_TITLE_PROPS = {
    bold: true,
    testId: 'recovery-key-step5-message-title',
  },
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_01_PROPS = {
    testId: 'recovery-key-step5-message-description-01',
  },
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_02_PROPS = {
    testId: 'recovery-key-step5-message-description-02',
  },
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_03_PROPS = {
    testId: 'recovery-key-step5-message-description-03',
  };

export {
  RECOVERY_KEY_STEP5_MESSAGE_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_TITLE_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_01_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_02_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_03_PROPS,
};
