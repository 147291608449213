// Constants
import { TABLE_ORGANISM_PROPS_IDS } from './constants/table-organism.utils.constants';
import { TRANSACTION_CONFIGURATION_PROPS } from '../components/transactions-table/configurations/constants/transactions-table.configuration.constants';
import {
  CARD_STATEMENTS_ORGANISM_TABLE_PROPS,
  DASHBOARD_ORGANISM_TABLE_PROPS,
  DENIED_TRANSACTIONS_ORGANISM_TABLE_PROPS,
  FINANCING_ORGANISM_TABLE_PROPS,
  TRANSACTION_ORGANISM_TABLE_PROPS,
} from '../constants/table-organism.constants';
// Types
import { GetServiceTransactionsConfigurationPropsType } from './types/table-organism-transactions-service-configuration-props.utils.type';
import { GetServiceTransactionsConfigurationReturnType } from './types/table-organism-transactions-service-configuration-return.utils.type';
import { TransactionsTableOrganismPropsReturnType } from '../components/transactions-table/types/transactions-table-configurations.component.type';

const TABLE_ORGANISM_PROPS: { [key: string]: TransactionsTableOrganismPropsReturnType } = {
  [TABLE_ORGANISM_PROPS_IDS.CARD_STATEMENTS]: CARD_STATEMENTS_ORGANISM_TABLE_PROPS,
  [TABLE_ORGANISM_PROPS_IDS.DASHBOARD]: DASHBOARD_ORGANISM_TABLE_PROPS,
  [TABLE_ORGANISM_PROPS_IDS.DENIED_TRANSACTIONS]: DENIED_TRANSACTIONS_ORGANISM_TABLE_PROPS,
  [TABLE_ORGANISM_PROPS_IDS.FINANCING]: FINANCING_ORGANISM_TABLE_PROPS,
  [TABLE_ORGANISM_PROPS_IDS.TRANSACTIONS]: TRANSACTION_ORGANISM_TABLE_PROPS,
};

const getServiceTransactionsConfiguration = ({
  idTable,
  cardId,
}: GetServiceTransactionsConfigurationPropsType): GetServiceTransactionsConfigurationReturnType => {
  const tableOrganismProps = getTableOrganismPropsById(idTable).serviceConfiguration;

  if (!tableOrganismProps) {
    return {
      endpoint: '',
      queryParams: {},
    };
  }

  return {
    ...tableOrganismProps,
    queryParams: {
      ...tableOrganismProps.queryParams,
      cardId,
    },
  };
};

const getTableOrganismPropsById = (
  idTable: string | undefined
): TransactionsTableOrganismPropsReturnType =>
  TABLE_ORGANISM_PROPS[idTable || ''] || TRANSACTION_CONFIGURATION_PROPS;

export { getTableOrganismPropsById, getServiceTransactionsConfiguration };
