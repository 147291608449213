// Vendors
import { fileIsBlobType, wasSuccessRequest } from '@openbank/cf-ui-framework';
// Services
import {
  createInsurancePrecontractService,
  createInsuranceContractService,
  getInsuranceContractService,
} from '../services/financing-insurances.services';
// Types
import { InsurancePrecontractParamsType } from './types/insurance-precontract-params.type';

const createInsurancePrecontractResolver = async (
  params: InsurancePrecontractParamsType
): Promise<[Blob] | [null, boolean]> => {
  const [response] = await createInsurancePrecontractService(params);

  return fileIsBlobType(response as Blob) ? [response as Blob] : [null, true];
};

const createInsuranceContractResolver = async (params: any): Promise<[any] | [null, number]> => {
  const [_, { status }] = await createInsuranceContractService(params);

  return wasSuccessRequest(status) ? [status] : [null, status];
};

const getInsuranceContractResolver = async (
  queryParams: InsurancePrecontractParamsType
): Promise<[Blob] | [null, boolean]> => {
  const [response] = await getInsuranceContractService(queryParams);

  return fileIsBlobType(response as Blob) ? [response as Blob] : [null, true];
};

export {
  createInsurancePrecontractResolver,
  createInsuranceContractResolver,
  getInsuranceContractResolver,
};
