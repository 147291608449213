// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const CreditLimitSpanStyled = styled.span`
  color: ${({ theme }) => theme.colors.fontPrimaryA};
`;

const CreditLimitStyled = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  height: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;
  padding: 0;

  ${mobileMediaQuery} {
    width: ${convertPxsToRems({ pixels: 144, base: 16 })};
  }
`;

const CreditLimitValuesStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  height: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;
  padding: 0;
`;

const DepositMoneyParameterTableStyled = styled.div`
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  padding: 0;

  ${mobileMediaQuery} {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    height: ${convertPxsToRems({ pixels: 344, base: 16 })};
    justify-content: flex-start;
  }
`;

const MaximumLimitStyled = styled.div`
  align-self: stretch;
  flex-grow: 0;
  font-size: ${({ theme }) => theme.spaces.spacingXs};
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
    width: ${convertPxsToRems({ pixels: 279, base: 16 })};
  }
`;

const TitleComponentStyled = styled.div`
  align-items: flex-start;
  align-self: stretch;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  flex-grow: 0;
  flex-direction: row;
  font-size: ${({ theme }) => theme.spaces.spacingXs};
  font-weight: bold;
  gap: ${({ theme }) => theme.spaces.spacingS};
  height: ${({ theme }) => theme.spaces.spacingL};
  justify-content: flex-start;
  padding: ${({ theme }) => `${theme.spaces.spacingXxs} ${theme.spaces.spacingS}`};

  ${mobileMediaQuery} {
    display: flex;
    height: ${({ theme }) => theme.spaces.spacingXxl};
  }
`;

const ValuesComponentStyled = styled.div`
  align-items: stretch;
  background-color: #fff;
  border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  gap: ${convertPxsToRems({ pixels: 340, base: 16 })};
  height: ${({ theme }) => theme.spaces.spacingXxxl};
  padding: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingS} 0`};

  ${mobileMediaQuery} {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    gap: ${({ theme }) => theme.spaces.spacingS};
    height: ${({ theme }) => theme.spaces.spacingXxxl};
    justify-content: flex-end;
  }
`;

export {
  CreditLimitSpanStyled,
  CreditLimitStyled,
  CreditLimitValuesStyled,
  DepositMoneyParameterTableStyled,
  MaximumLimitStyled,
  TitleComponentStyled,
  ValuesComponentStyled,
};
