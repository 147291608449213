// Vendors
import { useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import { CustomerHandlers } from '../handlers/customer.handlers';
import { NotificationsHandlers } from 'handlers/notifications.handlers';
// Types
import { FetchErrorContextType } from 'contexts/fetch-error/types/fetch-error.context.type';

const useCustomerHook = (): Pick<FetchErrorContextType, 'fetching'> => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { showBoundary: setError } = useErrorBoundary();

  const { setProfile } = AppContextConsumerHook(),
    { me, setMe, setNotificationsData } = ManagerContextConsumerHook();

  const { handleGetAllCustomerNotifications } = NotificationsHandlers({
      setNotificationsData,
    }),
    { handleFetchCustomerInfo } = CustomerHandlers({
      me,
      handleGetAllCustomerNotifications,
      setError,
      setFetching,
      setMe,
      setProfile,
    });

  useEffect(() => {
    handleFetchCustomerInfo();
  }, []);

  useEffect((): void => {
    me && handleFetchCustomerInfo();
  }, [me]);

  return { fetching };
};

export { useCustomerHook };
