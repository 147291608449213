// Types
import { PersonalNumberStep3ConfirmationOptionChangeHandlerType } from './types/personal-number-confirmation-option-change-handler.type';
import { PersonalNumberStep3ConfirmationOptionReturnHandlersType } from './types/personal-number-confirmation-option-return.handlers.type';
import { PersonalNumberStep3ConfirmationOptionHandlersType } from './types/personal-number-confirmation-option-handler.type';
// Utils
import { isEmailOption } from '../utils/personal-number-step3.utils';

const confirmationOptionChangeHandler = ({
  eventLabel,
  value,
  handleConfirmationOptionChangeTracking,
  setConfirmationCodeOption,
  setWithEmail,
}: PersonalNumberStep3ConfirmationOptionChangeHandlerType) => {
  handleConfirmationOptionChangeTracking(eventLabel);
  setConfirmationCodeOption(value);
  setWithEmail(!!isEmailOption(value));
};

const PersonalNumberStep3ConfirmationOptionHandlers = ({
  handleConfirmationOptionChangeTracking,
  setConfirmationCodeOption,
  setWithEmail,
}: PersonalNumberStep3ConfirmationOptionHandlersType): PersonalNumberStep3ConfirmationOptionReturnHandlersType => ({
  handleConfirmationOptionChange: ({ eventLabel, value }) =>
    confirmationOptionChangeHandler({
      eventLabel,
      value,
      handleConfirmationOptionChangeTracking,
      setConfirmationCodeOption,
      setWithEmail,
    }),
});

export default PersonalNumberStep3ConfirmationOptionHandlers;
