// Types
import {
  WizardChallengeBuilderHandlersType,
  WizardChallengeMethodsHandlersType,
  WizardChallengeSuccessBuilderHandlersType,
} from './types/wizard-challenge.handlers.type';
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';

const wizardChallengeErrorEventHandler = (
  setResult: WizardChallengeBuilderHandlersType['setResult']
): void => {
  setResult(false);
};

const wizardChallengeSuccessEventHandler = ({
  setProgressId,
  setChallenged,
  progressId,
}: WizardChallengeSuccessBuilderHandlersType): void => {
  setProgressId(progressId);
  setChallenged(true);
};

const WizardChallengeHandlers = ({
  setResult,
  ...builderProps
}: WizardChallengeBuilderHandlersType): WizardChallengeMethodsHandlersType => ({
  handleWizardChallengeErrorEvent: () => wizardChallengeErrorEventHandler(setResult),
  handleWizardChallengeSuccessEvent: (props: ChallengeProgressIdType) =>
    wizardChallengeSuccessEventHandler({ ...props, ...builderProps }),
});

export { WizardChallengeHandlers };
