const eventCategory = { eventCategory: 'Step4-uploadDocumentation' };
const errorCategory = { errorCategory: 'errorUploadingFile' };
const clickLInk = {
  ...eventCategory,
  action: 'click',
  format: 'link',
  eventAction: 'clickLink',
};

export const step4UploadDocumentationConstants = {
  VIEW: {
    STEP_4_UPLOAD_DOCUMENTATION: {
      pageName: '/creditLimitIncrease/step04/uploadDocumentation',
      sectionFamily: 'banking',
      sectionCategory: 'products',
      sectionName: 'cards',
      interactionType: 'funnel',
      serviceType: 'productSettings',
      processType: 'creditLimitIncrease',
      processStep: 'step04',
      processDetail: 'uploadDocumentation',
    },
  },
  LINK: {
    CLICK_THIS_EXAMPLE_DOCUMENT: {
      ...clickLInk,
      component: 'uploadDocTips',
      element: 'thisExample',
      eventLabel: 'thisExampleLink',
    },
    CLICK_CHOOSE_FILE: {
      ...clickLInk,
      action: 'upload',
      component: 'uploadDocument',
      element: 'chooseFile',
      eventLabel: 'chooseFileLink',
    },
    CLICK_CANCEL_UPLOAD: {
      ...clickLInk,
      action: 'cancel',
      component: 'uploadDocument',
      element: 'cancelUpload',
      eventLabel: 'cancelUploadLink',
    },
    CLICK_REMOVE_UPLOADED_FILE: {
      ...clickLInk,
      component: 'uploadDocument',
      element: 'removeUploadedFile',
      eventLabel: 'removeUploadedFileLink',
    },
    CLICK_RETRY_UPLOAD: {
      ...clickLInk,
      component: 'uploadDocument',
      element: 'retryUpload',
      eventLabel: 'retryUploadLink',
    },
    SHOW_UPLOAD_ERROR: {
      ...errorCategory,
      action: 'errorFound',
      format: 'box',
      component: 'uploadDocument',
      // element: 'errorCurrentMonthsAccountMovements',
      errorDescription: 'thereWasAnErrorUploadingFile',
      eventAction: 'errorFound',
      eventLabel: 'box - error',
    },
    SHOW_INVALID_FORMAT_ERROR: {
      ...errorCategory,
      action: 'errorFound',
      format: 'box',
      component: 'uploadDocument',
      // element: 'errorCurrentMonthsAccountMovements',
      errorDescription: 'theFormatFileIsNotValid',
      eventAction: 'errorFound',
      eventLabel: 'box - error',
    },
    SHOW_OVERSIZED_ERROR: {
      ...errorCategory,
      action: 'errorFound',
      format: 'box',
      component: 'uploadDocument',
      // element: 'errorCurrentMonthsAccountMovements',
      errorDescription: 'theFileIsOversized',
      eventAction: 'errorFound',
      eventLabel: 'box - error',
    },
    CLICK_GO_BACK_TO_PREVIOUS_STEP_LINK: {
      ...clickLInk,
      component: 'message/info/riskAssessmentProcess',
      element: 'goBackToTheAutomaticRiskAssessmentProcess',
      pathname: 'goBackToTheAutomaticRiskAssessmentProcess',
      eventAction: 'infoMessageRiskAssessmentProcess',
      eventLabel: 'goBackToTheAutomaticRiskAssessmentProcessLink',
    },
    CLICK_CANCEL_LINK: {
      ...eventCategory,
      action: 'cancel',
      format: 'link',
      component: 'lowerNavigation',
      element: 'cancel',
      eventAction: 'lowerNavigation',
      eventLabel: 'navigationCancelLink',
    },
    CLICK_UPLOAD_DOCUMENTATION_BUTTON: {
      ...eventCategory,
      action: 'click',
      format: 'button',
      component: 'lowerNavigation',
      element: 'uploadDocumentation',
      pathname: 'uploadDocumentationButton',
      eventAction: 'lowerNavigation',
      eventLabel: 'navigationUploadDocumentationButton',
    },
    OPEN_IT_IS_NOT_POSSIBLE_MODAL: {
      ...eventCategory,
      action: 'open',
      format: 'modal',
      component: 'modal/notPossibleContinueLimitIncreaseRequest',
      element: 'full',
      eventAction: 'notPossibleContinueLimitIncreaseRequest',
      eventLabel: 'open',
    },
    CLOSE_IT_IS_NOT_POSSIBLE_MODAL: {
      ...eventCategory,
      action: 'close',
      format: 'cross',
      component: 'modal/notPossibleContinueLimitIncreaseRequest',
      element: 'close',
      eventAction: 'notPossibleContinueLimitIncreaseRequest',
      eventLabel: 'close',
    },
    CLICK_BUTTON_IT_IS_NOT_POSSIBLE_MODAL: {
      ...eventCategory,
      action: 'exit',
      format: 'button',
      component: 'modal/notPossibleContinueLimitIncreaseRequest',
      element: 'exitTheProcess',
      eventAction: 'notPossibleContinueLimitIncreaseRequest',
      eventLabel: 'modalNavigationExitTheProcessButton',
    },
    OPEN_TIPS_MODAL: {
      ...eventCategory,
      action: 'open',
      format: 'modal',
      component: 'modal/photographingDocumentsTips',
      element: 'full',
      eventAction: 'photographingDocumentsTips',
      eventLabel: 'open',
    },
    CLOSE_TIPS_MODAL: {
      ...eventCategory,
      action: 'close',
      format: 'cross',
      component: 'modal/photographingDocumentsTips',
      element: 'close',
      eventAction: 'photographingDocumentsTips',
      eventLabel: 'close',
    },
    CLICK_BUTTON_TIPS_MODAL: {
      ...eventCategory,
      action: 'click',
      format: 'button',
      component: 'modal/photographingDocumentsTips',
      element: 'backToDocumentationUpload',
      eventAction: 'photographingDocumentsTips',
      eventLabel: 'modalNavigationBackToDocumentationUploadButton',
    },
  },
};
