// Constants
import assetConstants from './constants/category-asset.constants';
// Enumerations
import { CategoryAssetIdEnumeration } from '../enumerations/category-asset-id.enumeration';

const getCategoryAsset = (id: string): string => {
  if (!assetConstants[id]) return assetConstants[CategoryAssetIdEnumeration.OTHERS];

  return assetConstants[id];
};

export { getCategoryAsset };
