// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Translations
import {
  HELPER,
  NOTIFICATION_SETTING,
} from 'containers/notifications/views/notifications-settings/components/list/components/category/translations/notifications-settings-category.translations';
// Types
import { getInputCurrencyErrorPropsType } from './types/get-input-currency-error.type';
import { formatTitlePropsType } from './types/format-title-util-props.type';
import { formatDescriptionPropsType } from './types/format-description-util-props.type';
import { formatControllerDescriptionPropsType } from './types/format-controller-description-util-props.type';
import { formatInputHelperMessagePropsType } from './types/format-input-helper-message-util-props.type';
import { getInputCurrencyErrorMessagePropsType } from './types/get-input-currency-error-message-util-props.type';
// Utils
import {
  getControllerMinAmount,
  isMinAmount,
} from 'containers/notifications/views/notifications-settings/components/list/utils/notifications-settings-list.utils';

const getInputCurrencyError = ({
  formatMessage,
  formatNumber,
  notificationSetting,
  value,
  enabled,
}: getInputCurrencyErrorPropsType): string => {
  const minAmount = getControllerMinAmount({ notificationSetting });
  if (isMinAmount({ amount: value, minAmount }) && enabled) {
    return formatMessage(
      { id: `${NOTIFICATION_SETTING}${notificationSetting.title}${HELPER}` },
      { value: formatNumber(minAmount, EURO_FORMAT_OPTIONS) }
    );
  }

  return '';
};

const formatTitle = ({ formatMessage, notificationSetting }: formatTitlePropsType): string =>
  formatMessage({ id: `${NOTIFICATION_SETTING}${notificationSetting.title}` });

const formatDescription = ({
  formatMessage,
  notificationSetting,
  formatNumber,
}: formatDescriptionPropsType): string =>
  formatMessage(
    { id: `${NOTIFICATION_SETTING}${notificationSetting.description}` },
    {
      value: formatNumber(Number(notificationSetting?.controllers[0].amount), EURO_FORMAT_OPTIONS),
    }
  );

const formatControllerDescription = ({
  formatMessage,
  notificationSetting,
}: formatControllerDescriptionPropsType): string =>
  formatMessage({ id: NOTIFICATION_SETTING + notificationSetting.controllers[0].description });

const formatInputHelperMessage = ({
  formatMessage,
  notificationSetting,
  formatNumber,
}: formatInputHelperMessagePropsType): string =>
  formatMessage(
    { id: `${NOTIFICATION_SETTING}${notificationSetting.controllers[0].name}${HELPER}` },
    {
      value: formatNumber(
        getControllerMinAmount({ notificationSetting: notificationSetting }),
        EURO_FORMAT_OPTIONS
      ),
    }
  );

const getInputCurrencyErrorMessage = ({
  formatMessage,
  formatNumber,
  notificationSetting,
}: getInputCurrencyErrorMessagePropsType): string =>
  getInputCurrencyError({
    formatMessage,
    formatNumber,
    notificationSetting: notificationSetting,
    enabled: notificationSetting.enabled,
    value: Number(notificationSetting.controllers[0].amount),
  });

export {
  getInputCurrencyError,
  formatTitle,
  formatDescription,
  formatControllerDescription,
  formatInputHelperMessage,
  getInputCurrencyErrorMessage,
};
