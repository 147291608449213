export default {
  ASSET_PROPS: {
    testId: 'aset-test-id',
    type: 'illustration',
    sizes: {
      height: 80,
      width: 80,
    },
  },
} as const;
