// Types
import { RequestPortabilityStep3ActionsUtilsType } from './types/request-portability-step3-actions.utils.type';

const requestPortabilityStep3NextButtonIsDisabled = ({
  challenged,
  error,
  fetching,
}: RequestPortabilityStep3ActionsUtilsType): boolean => challenged || error || fetching;

export { requestPortabilityStep3NextButtonIsDisabled };
