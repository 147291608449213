// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Event handlers
import DashboardLastSessionHandlers from '../handlers/dashboard-last-session.handlers';
import DashboardLastSessionTrackingHandlers from '../handlers/dashboard-last-session.tracking.handlers';
// Hooks
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { DashboardLastSessionHookType } from './types/dashboard-last-session.hook.type';

const DashboardLastSessionHook = (): DashboardLastSessionHookType => {
  const { lastSession, setLastSession } = ManagerContextConsumerHook();

  const { handleFetchLastUserSessionLogin, handleNavigateToAccessAreaLinkClick } =
    DashboardLastSessionHandlers({
      ...AppTrackingHook(DashboardLastSessionTrackingHandlers),
      navigate: useNavigate(),
      setTimestamp: setLastSession,
    });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchLastUserSessionLogin();
  }, []);

  return {
    handleNavigateToAccessAreaLinkClick,
    timestamp: lastSession,
  };
};

export default DashboardLastSessionHook;
