// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
// Types
import { NotificationsSettingsModalBuilderHandlersType } from './types/notifications-settings-modal-builder.handlers.type';
import { NotificationsSettingsModalBuilderHandlersReturnType } from './types/notifications-settings-modal-builder-return.handlers.type';
import { ModalExitButtonClickHandlerPropsType } from './types/modal-exit-button-click-handler.type';
import { ModalCloseButtonClickHanlderPropsType } from './types/modal-close-button-click-handler.type';

const modalExitButtonClickHandler = ({
  handleExitWithoutSavingModalTracking,
  navigate
}: ModalExitButtonClickHandlerPropsType) => {
  handleExitWithoutSavingModalTracking();
  navigate(generateAbsoluteRoute(RoutesEnumeration.NOTIFICATIONS));
};

const modalCloseButtonClickHanlder = ({
  setShowModal,
  handleCloseModalTracking,
}: ModalCloseButtonClickHanlderPropsType) => {
  handleCloseModalTracking();
  setShowModal(false);
};

const NotificationsSettingsModalHandlers = ({
  handleCloseModalTracking,
  handleExitWithoutSavingModalTracking,
  navigate,
  setShowModal,
}: NotificationsSettingsModalBuilderHandlersType):
NotificationsSettingsModalBuilderHandlersReturnType => ({
  handleModalExitButtonClick: () => 
    modalExitButtonClickHandler({
      handleExitWithoutSavingModalTracking,
      navigate,
    }),
  handleCloseModalButtonClick: () =>
    modalCloseButtonClickHanlder({
      handleCloseModalTracking,
      setShowModal,
    }),
});

export { NotificationsSettingsModalHandlers };
