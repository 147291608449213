// Vendors
import React from 'react';
// Components
import { TransactionsReportFraudulentMovementAdditionalInfoActionsComponent } from './components/actions/transactions-report-fraudulent-movement-additional-info-actions.component';
import { TransactionsReportFraudulentMovementAdditionalInfoOptionsComponent } from './components/options/transactions-report-fraudulent-movement-additional-info-options.component';
// Styles
import { TransactionsReportFraudulentMovementAdditionalInfoComponentStyled } from './transactions-report-fraudulent-movement-additional-info.component.styled';

const TransactionsReportFraudulentMovementAdditionalInfoComponent = (): React.ReactElement => {
  return (
    <TransactionsReportFraudulentMovementAdditionalInfoComponentStyled>
      <TransactionsReportFraudulentMovementAdditionalInfoOptionsComponent />
      <TransactionsReportFraudulentMovementAdditionalInfoActionsComponent />
    </TransactionsReportFraudulentMovementAdditionalInfoComponentStyled>
  );
};
export { TransactionsReportFraudulentMovementAdditionalInfoComponent };
