// Vendors
import React from 'react';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
import { TransactionsReportFraudulentMovementUploadDocumentsActionsComponent } from './components/actions/transactions-report-fraudulent-movement-upload-documents-actions.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { REPORT_PROBLEM_FRAUDULENT_MESSAGE_PROPS } from './constants/transactions-report-fraudulent-movement-upload-documents.constants';
// Contexts
import { TransactionsReportFraudulentMovementConsumerHook } from '../../contexts/transactions-report-fraudulent-movement.context';
// Translations
import { UPLOAD_DOCUMENTS_MESSAGE_DESCRIPTION } from './translations/transactions-report-fraudulent-movement-upload-documents.translations';
// Styles
import { TransactionsReportFraudulentMovementUploadDocumentsComponentStyled } from './transactions-report-fraudulent-movement-upload-documents.component.styled';

export const TransactionsReportFraudulentMovementUploadDocumentsComponent =
  (): React.ReactElement => {
    const { files, setFiles } = TransactionsReportFraudulentMovementConsumerHook();

    return (
      <TransactionsReportFraudulentMovementUploadDocumentsComponentStyled>
        <UploadDocumentsTipsComponent />
        <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
        <MessageComponent {...REPORT_PROBLEM_FRAUDULENT_MESSAGE_PROPS}>
          <ParagraphComponent>
            <FormattedMessageComponent id={UPLOAD_DOCUMENTS_MESSAGE_DESCRIPTION} />
          </ParagraphComponent>
        </MessageComponent>
        <TransactionsReportFraudulentMovementUploadDocumentsActionsComponent />
      </TransactionsReportFraudulentMovementUploadDocumentsComponentStyled>
    );
  };
