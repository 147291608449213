// Vendors
import styled from 'styled-components';

export const SettingsCardControlOperationsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
`;

export const SettingsCardControlOperationsContentComponentStyled = styled(
  SettingsCardControlOperationsComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const SettingsCardControlOperationsContentBottomComponentStyled = styled(
  SettingsCardControlOperationsComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingL};
  padding-bottom: ${({ theme }) => theme.spaces.spacingM};
`;
