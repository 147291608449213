// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { useReportSubscriptionAlreadyCancelledContextConsumerHook } from 'containers/transactions/views/report/components/subscription-already-cancelled/contexts/report-subscription-already-cancelled.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { ReportSubscriptionAlreadyCancelledConfirmationActionsHandlers } from '../handlers/report-subscription-already-cancelled-confirmation-actions.handlers';
// Hooks
import { useCleanChallengeHook } from 'containers/transactions/views/report/hooks/clean-challenge.hook';
// Types
import { ReportSubscriptionAlreadyCancelledConfirmationActionsHookReturnType } from './types/report-subscription-already-cancelled-confirmation-actions.hook.type';

export const useReportSubscriptionAlreadyCancelledConfirmationActionsHook =
  (): ReportSubscriptionAlreadyCancelledConfirmationActionsHookReturnType => {
    const { cancelledSubDateBefore, files, setCancelledSubDateBefore, setFiles } =
      useReportSubscriptionAlreadyCancelledContextConsumerHook();
    const { challenge, challengeError, setChallengeError, setCaseId, setError } =
      useTransactionsReportContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    useCleanChallengeHook();

    return {
      ...ReportSubscriptionAlreadyCancelledConfirmationActionsHandlers({
        cancelledSubDateBefore,
        cardId,
        challenge,
        files,
        setCancelledSubDateBefore,
        setCaseId,
        setChallengeError,
        setError,
        setFiles,
        setResult,
        transactionId,
      }),
      challenge,
      challengeError,
    };
  };
