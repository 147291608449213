// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getTransactionAmazonPointsConfiguration } from './configurations/transactions-landing-row-details-points.configuration';
// Constants
import constants from './constants/transactions-landing-row-details-points.constants';
// Translations
import translations from './translations/transactions-landing-row-details-points.translations';
// Types
import { CardTransactionType } from 'types/card-transaction.type';
// Utilities
import { transactionHasPoints } from 'components/table-row-description/components/points/utils/table-row-description-points.utils';

const TransactionsLandingRowDetailsPointsComponent = ({
  reward,
}: Pick<CardTransactionType, 'reward'>): React.ReactElement | null => {
  const { formatNumber } = useCbIntl();

  return transactionHasPoints(reward) ? (
    <ResumeItemComponent
      {...constants.TRANSACTIONS_LANDING_ROW_DETAILS_POINTS_PROPS}
      label={<FormattedMessageComponent id={translations.AMAZON_POINTS} />}
      value={
        <FormattedMessageComponent
          {...getTransactionAmazonPointsConfiguration({ reward, formatNumber })}
        />
      }
    />
  ) : null;
};
export default TransactionsLandingRowDetailsPointsComponent;
