// Vendors
import React from 'react';
// Configurations
import {
  getChallengeConfiguration,
  getNavigationFooterConfiguration,
} from './configurations/card-settings-activate-card-challenge.component.configurations';
// Components
import CardSettingsActivateCardIntroductionComponent from './components/introduction/card-settings-activate-card-introduction.component';
import { NavigationFooterComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/card-settings-activate-card.constants';
// Hooks
import CardSettingsActivateCardChallengeHook from './hooks/card-settings-activate-card-challenge.hook';
// Styles
import {
  CardSettingsActivateCardChallengeComponentStyled,
  CardSettingsActivateCardChallengeContentStyled,
  CardSettingsActivateCardChallengeTitleStyled,
} from './card-settings-activate-card-challenge.component.styled';
// Translations
import translations from './translations/card-settings-activate-card-challenge.translations';

const CardSettingsActivateCardChallengeComponent = (): React.ReactElement => {
  const {
    cardId,
    challenge,
    challengeError,
    formatMessage,
    handleActivateButtonClick,
    handleCancelLinkClick,
    handleChallengeError,
    handleChallengeSuccess,
    handleChallengeRetryButtonClick,
  } = CardSettingsActivateCardChallengeHook();

  return (
    <CardSettingsActivateCardChallengeComponentStyled>
      <CardSettingsActivateCardChallengeContentStyled>
        <CardSettingsActivateCardChallengeTitleStyled>
          <TitleHeaderComponent {...constants.CARD_SETTINGS_ACTIVATE_CARD_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.TITLE} />
          </TitleHeaderComponent>
        </CardSettingsActivateCardChallengeTitleStyled>
        <CardSettingsActivateCardIntroductionComponent />
        <ChallengeWrapperComponent
          {...getChallengeConfiguration({
            cardId,
            challengeError,
            handleChallengeError,
            handleChallengeSuccess,
            handleChallengeRetryButtonClick,
          })}
        />
      </CardSettingsActivateCardChallengeContentStyled>
      <NavigationFooterComponent
        {...getNavigationFooterConfiguration({
          challenge,
          formatMessage,
          handleActivateButtonClick,
          handleCancelLinkClick,
        })}
      />
    </CardSettingsActivateCardChallengeComponentStyled>
  );
};

export default CardSettingsActivateCardChallengeComponent;
