// Vendors
import { RefObject, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { PersonalPhotoContextConsumerHook } from 'containers/personal/views/photo/contexts/personal-photo.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Events handlers
import PersonalPhotoNameButtonHandlers from '../handlers/personal-photo-name-button.handlers';
import PersonalPhotoNameButtonTrackingHandlers from '../handlers/personal-photo-name-button.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalPhotoNameButtonHookType } from './types/personal-photo-name-button.hook.type';

const usePersonalPhotoNameButtonHook = (): PersonalPhotoNameButtonHookType => {
  const [inputRef] = useState<RefObject<HTMLInputElement>>({ current: null });

  const { profile } = AppContextConsumerHook(),
    { photo, setAvatar, setPhoto, setPhotoModal } = PersonalPhotoContextConsumerHook(),
    { userName = CharactersEnum.NO_VALUE } = profile?.userDetails || {};

  return {
    ...PersonalPhotoNameButtonHandlers({
      ...AppTrackingHook(PersonalPhotoNameButtonTrackingHandlers),
      inputRef,
      photo,
      setAvatar,
      setPhoto,
      setPhotoModal,
    }),
    inputRef,
    userName,
  };
};

export default usePersonalPhotoNameButtonHook;
