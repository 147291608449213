// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { InstallmentsStatusEnumeration } from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/enumerations/epp-element-status.enumeration';

const AMORTIZATION_MODAL_COMPONENT_PROPS = {
  bold: true,
  testId: 'financing-landing-table-row-tag',
};

const AMORTIZATION_TABLE_STATUS_TAG_TYPES = {
  [InstallmentsStatusEnumeration.CANCELLED]: tagConstants.TYPES.PENDING,
  [InstallmentsStatusEnumeration.LIQUIDATED]: tagConstants.TYPES.SUCCESS,
  [InstallmentsStatusEnumeration.PENDING]: tagConstants.TYPES.PENDING,
  [InstallmentsStatusEnumeration.RETURNED]: tagConstants.TYPES.DISABLED,
};

export { AMORTIZATION_MODAL_COMPONENT_PROPS, AMORTIZATION_TABLE_STATUS_TAG_TYPES };
