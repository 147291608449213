// Theme features
import { AmazonTheme } from '@openbank/cf-ui-static-data';

export default {
  ADD_BANK_BUTTON: {
    rwdFull: false,
    type: 'tertiary',
    testId: 'add-bank-button',
    assetConfiguration: {
      id: 'add',
      position: 'left',
      sizes: {
        height: 20,
        width: 20,
      },
      testId: 'add-bank-button-asset',
    },
  },
  GO_TO_UPLOAD_DOCUMENTATION_BUTTON: {
    rwdFull: false,
    type: 'secondary',
    testId: 'go-to-upload-documentation-button',
  },
  LOADER_PROPS: {
    sizes: {
      border: 2,
      height: 20,
      width: 20,
    },
  },
  SUCCESS_PROPS: {
    id: 'checkmark-colored',
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'success-asset',
  },
  ERROR_PROPS: {
    id: 'critical',
    colors: {
      fill: 'fontTertiaryF',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'critical-asset',
  },
  BANK_HEADER_PROPS: {
    isHeader: true,
    height: 32,
    background: AmazonTheme.default.colors.backgroundTertiaryD,
  },
  BANK_BODY_PROPS: {
    height: 56,
  },
  MESSAGE_ERROR_PROPS: {
    type: 'error',
  },
} as const;
