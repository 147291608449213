// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ParagraphComponentStyled = styled.div`
  ${mobileMediaQuery} {
    margin-bottom: ${({ theme }) => theme.spaces.spacingXs};
  }
`;
