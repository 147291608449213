// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputTextComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/personal-email-step1-repeat.constants';
// Hooks
import PersonalEmailStep1RepeatHook from './hooks/personal-email-step1-repeat.hook';
// Translations
import translations from './translations/personal-email-step1-repeat.translations';
// Utilities
import { newEmailIsNotValid } from './utils/personal-email-step1-repeat.utils';

const PersonalEmailStep1RepeatComponent = (): React.ReactElement => {
  const {
    handleModifyEmailRepeatInputClickTracking,
    handleSaveRepeatModifyEmailInputEvent,
    handleValidateRepeatModifyEmailInputEvent,
    newEmail,
    inputError,
    repeatEmail,
  } = PersonalEmailStep1RepeatHook();

  return (
    <InputTextComponent
      {...constants.PERSONAL_EMAIL_STEP_1_REPEAT_INPUT_PROPS}
      disabled={newEmailIsNotValid(newEmail)}
      errorMessage={inputError && <FormattedMessageComponent id={translations.ERRORS[inputError]} />}
      label={<FormattedMessageComponent id={translations.INPUT_LABEL} />}
      onClick={handleModifyEmailRepeatInputClickTracking}
      onChange={handleSaveRepeatModifyEmailInputEvent}
      onBlur={handleValidateRepeatModifyEmailInputEvent}
      value={repeatEmail}
    />
  );
};

export default PersonalEmailStep1RepeatComponent;
