// Event handlers
import { LoginAccessDataHandlers } from '../handlers/login-access-data.handlers';
import { LoginLinksTrackingHandlers } from '../handlers/login-access-data.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoginAccessDataBuilderReturnHandlersType } from '../handlers/types/login-access-data-return.handlers.type';

const useLoginAccessData = (): LoginAccessDataBuilderReturnHandlersType =>
  LoginAccessDataHandlers({
    ...AppTrackingHook(LoginLinksTrackingHandlers),
  });

export { useLoginAccessData };
