// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
// Constants
import { REPORT_DEFECTIVE_DEFAULT_STATE } from './constants/report-defective.context.constants';
// Types
import { ReportDataType } from '../types/report-defective.type';
import { ReportDefectiveContextType } from './types/report-defective.context.type';
import { FileDataType } from 'types/file-data.type';

const ReportDefectivContext = createContext<ReportDefectiveContextType>(
  REPORT_DEFECTIVE_DEFAULT_STATE
);

export const ReportDefectiveContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ReportDefectiveContextType>> => {
  const [files, setFiles] = useState<FileDataType[]>(REPORT_DEFECTIVE_DEFAULT_STATE.files);
  const [data, setData] = useState<ReportDataType>(REPORT_DEFECTIVE_DEFAULT_STATE.data);

  const contextValue = useMemo(
    () => ({
      data,
      files,
      setData,
      setFiles,
    }),
    [data, files]
  );

  return (
    <ReportDefectivContext.Provider value={contextValue}>{children}</ReportDefectivContext.Provider>
  );
};

export const useReportDefectiveContextConsumerHook = (): ReportDefectiveContextType =>
  useContext(ReportDefectivContext);

/* eslint-disable */
export const ReportDefectiveContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ReportDefectiveContextType>> =>
    (
      <ReportDefectiveContextProvider>
        <Component {...props} />
      </ReportDefectiveContextProvider>
    );
