// Constants
import { linkConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_PIN_ACTIONS_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'card-settings-check-pin-card-button',
  },
  CARD_SETTINGS_CHECK_PIN_ACTIONS_LINK_PROPS = {
    type: linkConstants.TYPES.SECONDARY,
    testId: 'card-settings-check-pin-card-link',
  };

export { CARD_SETTINGS_CHECK_PIN_ACTIONS_BUTTON_PROPS, CARD_SETTINGS_CHECK_PIN_ACTIONS_LINK_PROPS };
