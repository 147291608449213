// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsChangePinContextConsumerHook } from 'containers/card-settings/views/change-pin/contexts/card-settings-change-pin.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import CardSettingsChangePinChallengeHandlers from '../handlers/card-settings-change-pin-challenge.handlers';
import CardSettingsChangePinChallengeTrackingHandlers from '../handlers/card-settings-change-pin-challenge.tracking.handlers';
import { ChallengeRetryHandlers } from 'handlers/challenge/challenge-retry.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChangePinChallengeHookType } from './types/card-settings-change-pin-challenge.hook.type';

const useCardSettingsChangePinChallengeHook = (): CardSettingsChangePinChallengeHookType => {
  const { currentCardDetail, profile } = AppContextConsumerHook(),
    {
      challenged,
      challengeError,
      newPin,
      repeatedPin,
      setChallenged,
      setChallengeError,
      setNewPin,
      setProgressId,
      setRepeatedPin,
      setSolvedChallenged,
    } = useCardSettingsChangePinContextConsumerHook(),
    { dateOfBirth } = profile?.birthDetails || {},
    { id: cardId, publicPem } = currentCardDetail || {},
    { setResult } = useWizardFeedbackContextConsumerHook();

  const {
    handleManageErrorChangePinCardEvent,
    handleManageSuccessChangePinCardEvent,
    handleValidatePinButtonClick,
  } = CardSettingsChangePinChallengeHandlers({
    ...AppTrackingHook(CardSettingsChangePinChallengeTrackingHandlers),
    newPin,
    publicPem,
    repeatedPin,
    setChallenged,
    setNewPin,
    setProgressId,
    setRepeatedPin,
    setResult,
    setSolvedChallenged,
  });

  return {
    ...ChallengeRetryHandlers({
      setChallengeError,
    }),
    cardId,
    challenged,
    challengeError,
    dateOfBirth,
    handleManageErrorChangePinCardEvent,
    handleManageSuccessChangePinCardEvent,
    handleValidatePinButtonClick,
    newPin,
    repeatedPin,
  };
};

export default useCardSettingsChangePinChallengeHook;
