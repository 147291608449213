// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Resolvers
import { changePaymentMethodResolver } from 'containers/financing/views/payment-method/resolvers/financing-payment-method.resolvers';
// Types
import { FinancingPaymentMethodSecondStepActionsReturnHandlersType } from './types/financing-payment-method-second-step-actions-return.handlers.type';
import { FinancingPaymentMethodSecondStepActionsHandlersType } from './types/financing-payment-method-second-step-actions.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const nextButtonClickHandler = async ({
  challenge,
  contractId,
  handleSelectCurrentMethod,
  paymentMethodParams,
  setCard,
  setChallenge,
  setChallengeError,
  setError,
  setPaymentMethodParams,
  setResult,
}: NextButtonClickHandlerType) => {
  if (paymentMethodParams) {
    const [, error] = await changePaymentMethodResolver({
      contractId,
      progressId: challenge?.progressId,
      params: paymentMethodParams,
    });

    if (error) {
      setChallenge(null);

      if (error === HttpStatusCodesEnum.FORBIDDEN) {
        setChallengeError(true);
      } else {
        setResult(false);
        setPaymentMethodParams(null);
        handleSelectCurrentMethod();
      }
    } else {
      setCard(true);
      setResult(true);
    }
  } else {
    setError(true);
  }
};

const FinancingPaymentMethodSecondStepActionsHandlers = (
  props: FinancingPaymentMethodSecondStepActionsHandlersType
): FinancingPaymentMethodSecondStepActionsReturnHandlersType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});

export default FinancingPaymentMethodSecondStepActionsHandlers;
