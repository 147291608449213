// Constants
import translations from '../translations/security-keys-access-key.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export default {
  SECURITY_KEYS_ACCESS_KEY_PROPS: {
    description: translations.DESCRIPTION,
    link: translations.LINK,
    testing: {
      descriptionId: 'security-keys-access-key-table-row-description',
      linkId: 'security-keys-access-key-links',
      titleId: 'security-keys-access-key-title',
    },
    title: translations.TITLE,
    url: RoutesEnumeration.ACCESS_KEY,
  },
  SECURITY_KEYS_ACCESS_KEY_ENDPOINT: 'cb-api-auth/authentication/configuration/last-modified',
};
