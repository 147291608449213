// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getLimitModalConfiguration } from './configurations/limit-modal.component.configurations';
// Hooks
import LimitModalHook from './hooks/limit-modal.hook';
// Translations
import { MODAL } from './translations/limit-modal.component.translations';
// Types
import { LimitModalComponentPropsType } from './types/limit-modal.component.type';

const LimitModalComponent = ({
  isVisible,
  onCloseModal,
}: LimitModalComponentPropsType): React.ReactElement | null => {
  const {
    formatMessage,
    handleCancelLinkClickTracking,
    handleCloseButtonClickTracking,
    handleContinueButtonClickTracking,
    navigate,
    username,
  } = LimitModalHook();

  return isVisible ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...getLimitModalConfiguration({
        formatMessage,
        username,
        onCloseModal,
        navigate,
        handleCancelLinkClickTracking,
        handleCloseButtonClickTracking,
        handleContinueButtonClickTracking,
      })}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={MODAL.CONTENT} values={{ br: <br /> }} />
      </ParagraphComponent>
    </ModalComponent>
  ) : null;
};

export default LimitModalComponent;
