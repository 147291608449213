// Vendors
import { assetConstants, linkConstants } from '@openbank/cb-ui-commons';

const REQUEST_SIGNATURE_KEY_MODAL_PARAGRAPH_01_PROPS = {
  testId: 'request-signature-key-modal-description-01',
};
const REQUEST_SIGNATURE_KEY_MODAL_PARAGRAPH_02_PROPS = {
  testId: 'request-signature-key-modal-description-02',
};
const REQUEST_SIGNATURE_KEY_MODAL_PARAGRAPH_03_PROPS = {
  testId: 'request-signature-key-modal-description-03',
};
const REQUEST_SIGNATURE_KEY_MODAL_BUTTON_PROPS = {
  testId: 'request-signature-key-modal-button',
};
const REQUEST_SIGNATURE_KEY_MODAL_LINK_PROPS = {
  type: linkConstants.TYPES.ACTION_PRIMARY,
  testId: 'request-signature-key-modal-links',
};

export {
  REQUEST_SIGNATURE_KEY_MODAL_BUTTON_PROPS,
  REQUEST_SIGNATURE_KEY_MODAL_LINK_PROPS,
  REQUEST_SIGNATURE_KEY_MODAL_PARAGRAPH_01_PROPS,
  REQUEST_SIGNATURE_KEY_MODAL_PARAGRAPH_02_PROPS,
  REQUEST_SIGNATURE_KEY_MODAL_PARAGRAPH_03_PROPS,
};
