const DOMAIN_ACTIVATE_CARD_INTRODUCTION =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.challenge.introduction.';
const DOMAIN_ACTIVATE_CARD_INTRODUCTION_FIRST_CC_MESSAGE =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.challenge.introduction.ccFirstMessage.';

export default {
  FIRST_CC_MESSAGE: {
    FIRST_PARAGRAPH: `${DOMAIN_ACTIVATE_CARD_INTRODUCTION_FIRST_CC_MESSAGE}firstParagraph`,
    SECOND_PARAGRAPH: `${DOMAIN_ACTIVATE_CARD_INTRODUCTION_FIRST_CC_MESSAGE}secondParagraph`,
    THIRD_PARAGRAPH: `${DOMAIN_ACTIVATE_CARD_INTRODUCTION_FIRST_CC_MESSAGE}thirdParagraph`,
  },
  SECOND_CC_MESSAGE: `${DOMAIN_ACTIVATE_CARD_INTRODUCTION}ccSecondMessage.firstParagraph`,
  SUBTITLE: `${DOMAIN_ACTIVATE_CARD_INTRODUCTION}subtitle`,
};
