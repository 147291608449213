// Resolvers
import { cancelLPIContractResolver } from '../resolvers/financing-insurances-cancellation-lpi.resolver';
// Types
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';
import { FinancingInsurancesCancellationLPIHandlersReturnType } from './types/financing-insurances-cancellation-lpi-return.handlers.type';
import { FinancingInsurancesCancellationLPIHandlersPropsType } from './types/financing-insurances-cancellation-lpi.handlers.type';

const confirmCancelLPIContractButtonClickHandler = async ({
  contractId = '',
  progressId,
  setFetching,
  setFetchPPI,
  setResult,
}: Pick<
  FinancingInsurancesCancellationLPIHandlersPropsType,
  'contractId' | 'progressId' | 'setFetching' | 'setFetchPPI' | 'setResult'
>): Promise<void> => {
  setFetching(true);
  const [, error] = await cancelLPIContractResolver({ contractId, progressId });

  setFetchPPI(!error);
  setResult(!error);
  setFetching(false);
};

const manageSuccessCancelLPIChallengeEventHandler = ({
  progressId,
  setChallenged,
  setProgressId,
}: Pick<
  FinancingInsurancesCancellationLPIHandlersPropsType,
  'progressId' | 'setChallenged' | 'setProgressId'
>): void => {
  setChallenged(true);
  setProgressId(progressId);
};

const manageFailedCancelLPIChallengeEventHandler = ({
  setResult,
}: Pick<FinancingInsurancesCancellationLPIHandlersPropsType, 'setResult'>): void => {
  setResult(false);
};

const FinancingInsurancesCancellationLPIHandlers = ({
  contractId,
  progressId,
  setChallenged,
  setFetching,
  setFetchPPI,
  setProgressId,
  setResult,
}: FinancingInsurancesCancellationLPIHandlersPropsType): FinancingInsurancesCancellationLPIHandlersReturnType => ({
  handleConfirmCancelLPIContractButtonClick: () =>
    confirmCancelLPIContractButtonClickHandler({
      contractId,
      progressId,
      setFetching,
      setFetchPPI,
      setResult,
    }),
  handleManageFailedCancelLPIChallengeEvent: () =>
    manageFailedCancelLPIChallengeEventHandler({ setResult }),
  handleManageSuccessCancelLPIChallengeEvent: (props: ChallengeProgressIdType) =>
    manageSuccessCancelLPIChallengeEventHandler({ ...props, setChallenged, setProgressId }),
});

export { FinancingInsurancesCancellationLPIHandlers };
