// Resolvers
import { devicesListResolver, trustedDeviceResolver } from 'containers/security-keys/views/devices/resolvers/signature-key-devices.resolvers';
// Types
import { DevicesManagerHandlerType } from './types/device-manager.handlers.type';
import { DevicesManagerHandlerReturnType } from './types/device-manager.handlers.return.type';
import { SignatureKeyDevicesDeviceType } from '../types/signature-key-devices.device.type';

const getLastDevicesHandlers = async ({ setFetching, setDevices, setBlockedDevices, setError, setTrusted }) => {
  setFetching(true);
  const [devices, error] = await devicesListResolver({ blocked: false });
  const [blockeDevices] = await devicesListResolver({ blocked: true });

  if(setTrusted) {
    const [trustedDevices] = await trustedDeviceResolver();

    setTrusted(trustedDevices);
  }

  if (error) {
    setFetching(false);

    return setError(true);
  }

  setBlockedDevices(blockeDevices);
  setDevices(devices);
  setFetching(false);
};

const getTrustedDeviceHanders = async (
  { setFetching, setTrusted, setError, onNoTrustedDeviceTracking }: DevicesManagerHandlerType
): Promise<void> => {
    setFetching(true);
    const [device, error] = await trustedDeviceResolver();
    
    if (error) {
      onNoTrustedDeviceTracking?.();
      setFetching(false);

      return setError(true);
    }

    setTrusted(device as SignatureKeyDevicesDeviceType);
    setFetching(false);
  };

const DeviceManagerHandlers = (
  props: DevicesManagerHandlerType
): DevicesManagerHandlerReturnType => ({
  getLastDevices: () => getLastDevicesHandlers(props),
  getTrustedDevice: () => getTrustedDeviceHanders(props)
});

export { DeviceManagerHandlers };