// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Constants
import { REPORT_DEFECTIVE_DEFAULT_STATE } from 'containers/transactions/views/report/components/defective/contexts/constants/report-defective.context.constants';
// Resolvers
import { fetchReportProblemResolver } from 'containers/transactions/views/report/resolvers/transactions-report.resolvers';
// Types
import { ReportProblemDefectiveReturnHandlersType } from './types/report-problem-defective-return.handlers.type';
import { ReportProblemDefectiveHandlersPropsType } from './types/report-problem-defective-props.handlers.type';
// Utils
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const reportProblemHandler = async ({
  challenge,
  transactionId,
  cardId,
  data,
  setData,
  setCaseId,
  setChallengeError,
  setResult,
  setError,
  setFiles,
}) => {
  try {
    if (!cardId || !transactionId) throw new Error();

    const [response, error] = await fetchReportProblemResolver({
      cardId,
      transactionId,
      data,
      progressId: challenge.progressId,
    });

    if (error) {
      if (isWrongOTPError(error)) {
        setChallengeError(true);
      } else {
        setData(REPORT_DEFECTIVE_DEFAULT_STATE.data);
        setFiles(REPORT_DEFECTIVE_DEFAULT_STATE.files);
        setResult(false);
      }
    } else {
      setResult(true);
      setCaseId(response?.caseId);
    }
  } catch (_) {
    setError(true);
  }
};

const ReportProblemDefectiveConfirmationHandlers = ({
  challenge,
  setChallengeError,
  setResult,
  setError,
  setCaseId,
  setData,
  setFiles,
  transactionId,
  cardId,
  data,
}: ReportProblemDefectiveHandlersPropsType): ReportProblemDefectiveReturnHandlersType => ({
  handleReportProblem: () =>
    reportProblemHandler({
      challenge,
      transactionId,
      cardId,
      data,
      setCaseId,
      setChallengeError,
      setResult,
      setError,
      setData,
      setFiles,
    }),
});

export { ReportProblemDefectiveConfirmationHandlers };
