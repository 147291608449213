// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportPaymentContextConsumerHook } from 'containers/transactions/views/report/components/payment/contexts/transactions-report-payment.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { TransactionsReportPaymentConfirmationHookReturnType } from './types/transactions-report-payment-confirmation.component-hook-return.type';

export const useTransactionsReportPaymentConfirmationHook =
  (): TransactionsReportPaymentConfirmationHookReturnType => {
    const { formatMessage } = useCbIntl();
    const { paymentMethod, files } = useTransactionsReportPaymentContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    return {
      formatMessage,
      paymentMethod,
      files,
      transactionId,
      cardId,
    };
  };
