// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/financing-fast-cash-sidebar.translations';

export default {
  SIDEBAR_PROPS: {
    testId: 'financing-fast-cash',
  },
  SIDEBAR_PARAGRAPH_PROPS: {
    size: paragraphConstants.SIZES.M,
    testId: 'financing-fast-cash-sidebar-paragraph',
  },
  DESCRIPTION: translations.DESCRIPTION,
};
