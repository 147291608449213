// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Events handlers
import CopyHandlers from 'handlers/copy.handlers';
// Types
import { CopyBuilderReturnHandlersType } from '../handlers/types/copy/copy-builder-return.handlers.type';

const useCopyHook = (): CopyBuilderReturnHandlersType => {
  const { setToastConfiguration } = ToastContextConsumerHook();

  return CopyHandlers({ setToastConfiguration });
};

export default useCopyHook;
