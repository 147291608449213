// Services
import { createRecoveryKeyService } from '../services/recovery-key-step5-actions.service';
// Types
import { RecoveryKeyStep5ApiBuilderType } from '../types/recovery-key-step-5-api-builder.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const createRecoveryKeyResolver = async (
  props: RecoveryKeyStep5ApiBuilderType
): Promise<[true] | [null, true]> => {
  const [, { status }] = await createRecoveryKeyService(props);

  return wasSuccessRequest(status) ? [true] : [null, true];
};

export { createRecoveryKeyResolver };
