// Vendors
import styled from 'styled-components';

export const AccessPortabilityTableContentStyled = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const AccessPortabilityTableContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
