// Constants
import { assetConstants } from '@openbank/cb-ui-commons';
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const THIRD_DOWN_MODAL_ASSET_PROPS = {
    id: 'alert-third-down',
    extension: assetConstants.EXTENSIONS.PNG,
    sizes: {
      height: 90,
      width: 110,
    },
    type: assetConstants.TYPES.IMAGE,
    testId: 'third-down-asset',
  },
  THIRD_DOWN_MODAL_MAIN_LAYOUT_PROPS = {
    align: 'center',
  },
  THIRD_DOWN_MODAL_BUTTON_PROPS = {
    testId: 'third-down-button',
  },
  THIRD_DOWN_MODAL_DESCRIPTION_PROPS = {
    testId: 'third-down-description',
  },
  THIRD_DOWN_MODAL_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxs,
  };

export {
  THIRD_DOWN_MODAL_ASSET_PROPS,
  THIRD_DOWN_MODAL_MAIN_LAYOUT_PROPS,
  THIRD_DOWN_MODAL_BUTTON_PROPS,
  THIRD_DOWN_MODAL_DESCRIPTION_PROPS,
  THIRD_DOWN_MODAL_LAYOUT_PROPS,
};
