// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { FormattedAddressComponent } from 'components/formatted-address/formatted-address.component';
// Constants
import { NO_ADDRESS_CONTENT_TEST_ID } from './constants/block-card-address-content.constants';
// Translations
import { NO_ADDRESS_MESSAGE } from './translations/block-card-address-content.translations';
// Types
import { BlockCardAddressContentComponentType } from './types/block-card-address-content.type';

const BlockCardAddressContentComponent = ({
  address,
  paragraphProps,
  testId,
}: BlockCardAddressContentComponentType): React.ReactElement =>
  address ? (
    <FormattedAddressComponent {...{ address, paragraphProps, testId }} />
  ) : (
    <ParagraphComponent testId={NO_ADDRESS_CONTENT_TEST_ID}>
      <FormattedMessageComponent id={NO_ADDRESS_MESSAGE} />
    </ParagraphComponent>
  );

export default BlockCardAddressContentComponent;
