// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/transactions-landing-row-details-view-order.constants';
// Hooks
import TransactionsLandingRowDetailsViewOrderHook from './hooks/transactions-landing-row-details-view-order.hook';
// Translations
import translations from './translations/transactions-landing-row-details-view-order.translations';
// Types
import { TransactionsLandingRowDetailsViewOrderComponentType } from './types/transactions-landing-row-details-view-order.component.type';

const TransactionsLandingRowDetailsViewOrderComponent = ({
  isAMZ,
}: TransactionsLandingRowDetailsViewOrderComponentType): React.ReactElement | null => {
  const { handleViewTransactionOnAmazonLinkClick } = TransactionsLandingRowDetailsViewOrderHook();

  if (!isAMZ) {
    return null;
  }

  return (
    <LinkComponent
      {...constants.TRANSACTIONS_LANDING_ROW_DETAILS_VIEW_ORDER_LINK_PROPS}
      onClick={handleViewTransactionOnAmazonLinkClick}
    >
      <FormattedMessageComponent id={translations.VIEW_ORDER_ID} />
    </LinkComponent>
  );
};

export { TransactionsLandingRowDetailsViewOrderComponent };
