// Services
import { changeUserNumberOtpService } from '../services/personal-number-step4-actions.service';
// Types
import { PersonalNumberStep4ActionsApiBuilderType } from '../types/personal-number-step4-actions-api-builder.type';
// Utils
import { isValidPhonePatchResponse } from './utils/personal-number-step4-actions.resolver.utils';

const changeUserPhoneOtpResolver = async (
  props: PersonalNumberStep4ActionsApiBuilderType
): Promise<[object, boolean]> => {
  const [response, { status }] = await changeUserNumberOtpService(props);
  return isValidPhonePatchResponse(status) ? [{ status }, false] : [{ status }, true];
};

export { changeUserPhoneOtpResolver };
