// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TRAVEL_PLUS_COST_DESCRIPTION_01_PROPS,
  TRAVEL_PLUS_COST_DESCRIPTION_02_PROPS,
  TRAVEL_PLUS_COST_PRICE_01_PROPS,
  TRAVEL_PLUS_COST_PRICE_02_PROPS,
  TRAVEL_PLUS_COST_LAYOUT_CONTENT_PROPS,
} from './constants/travel-plus-cost.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { useTravelPlusCostHook } from './hooks/travel-plus-cost.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import {
  TravelPlusCostBoxComponentStyled,
  TravelPlusCostComponentStyled,
} from './travel-plus-cost.component.styled';
// Translations
import { BENEFITS, INTRODUCTION, PER_MONTH } from './translations/travel-plus-cost.translations';

const TravelPlusCostComponent = (): React.ReactElement | null => {
  const { activated, currency, finalCost } = useTravelPlusCostHook();

  return activated ? null : (
    <TravelPlusCostComponentStyled>
      <ParagraphComponent {...TRAVEL_PLUS_COST_DESCRIPTION_01_PROPS}>
        <FormattedMessageComponent id={BENEFITS} />
      </ParagraphComponent>
      <TravelPlusCostBoxComponentStyled>
        <ParagraphComponent {...TRAVEL_PLUS_COST_DESCRIPTION_02_PROPS}>
          <FormattedMessageComponent id={INTRODUCTION} />
        </ParagraphComponent>
        <LayoutColumnFlexComponentStyled {...TRAVEL_PLUS_COST_LAYOUT_CONTENT_PROPS}>
          <ParagraphComponent {...TRAVEL_PLUS_COST_PRICE_01_PROPS}>
            <FormattedNumber
              {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
              value={Number(finalCost)}
            />
          </ParagraphComponent>
          <ParagraphComponent {...TRAVEL_PLUS_COST_PRICE_02_PROPS}>
            <FormattedMessageComponent id={PER_MONTH} />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
      </TravelPlusCostBoxComponentStyled>
    </TravelPlusCostComponentStyled>
  );
};

export { TravelPlusCostComponent };
