// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const QuickActionsComponentStyled = styled.section<{ columns?: number }>`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(${({ columns = 1 }) => columns}, 1fr);
  justify-content: space-between;
  width: 100%;

  ${mobileMediaQuery} {
    word-break: break-word;
    gap: ${({ theme }) => theme.spaces.spacingXs};
  }
`;

export { QuickActionsComponentStyled };
