// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  ORCHESTRATOR_ROUTER_CONTAINERS_ERROR_LOAD_PROPS,
  ORCHESTRATOR_ROUTER_CONTAINERS_LOAD_BUTTON_PROPS,
} from './constants/orchestrator-router-container-error.constants';
// Hooks
import useOrchestratorRouterContainerErrorHook from './hooks/orchestrator-router-container-error.hook';
// Styles
import { OrchestratorRouterContainerErrorComponentStyled } from './orchestrator-router-container-error.component.styled';
// Translations
import { BUTTON } from './translations/orchestrator-router-container-error.translations';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const OrchestratorRouterContainerErrorComponent = ({
  children,
}: ReactChildrenType): React.ReactElement => {
  const { formatMessage, error, handleReloadAndClearPageErrorButtonClick } =
    useOrchestratorRouterContainerErrorHook();

  return (
    <OrchestratorRouterContainerErrorComponentStyled {...{ error }}>
      <FetchErrorComponent
        error={getFetchErrorConfiguration({
          ...ORCHESTRATOR_ROUTER_CONTAINERS_ERROR_LOAD_PROPS,
          buttonConfiguration: {
            ...ORCHESTRATOR_ROUTER_CONTAINERS_LOAD_BUTTON_PROPS,
            children: <FormattedMessageComponent id={BUTTON} />,
            onClick: handleReloadAndClearPageErrorButtonClick,
          },
          error,
          formatMessage,
        })}
      >
        {children}
      </FetchErrorComponent>
    </OrchestratorRouterContainerErrorComponentStyled>
  );
};

export default OrchestratorRouterContainerErrorComponent;
