// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardSpentExpensesListItemComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
`;

export const ExpensesListItemCategoryWrapperStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const ExpensesListItemInnerContainerStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
  min-width: ${convertPxsToRems({ pixels: 116, base: 14 })};
`;

export const ExpensesListItemAmountWrapperStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  min-width: ${convertPxsToRems({ pixels: 88, base: 14 })};
  text-align: right;
`;