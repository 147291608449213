// Vendors
import styled from 'styled-components';

export const TransactionsEppStep1CreditMessageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
export const TransactionsEppStep1CreditMessageContentTextStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
