// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, TitleComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/login-gaps-title.constants';
// Hooks
import LoginGapsTitleHook from './hooks/login-gaps-title.hook';
// Styles
import { LoginGapsTitleComponentStyled } from './login-gaps-title.component.styled';
// Translations
import translations from './translations/login-gaps-title.translations';

const LoginGapsTitleComponent = (): React.ReactElement => {
  const { handleEditUsernameAssetClick, email, userAlias } = LoginGapsTitleHook();

  return (
    <LoginGapsTitleComponentStyled>
      <TitleComponent {...constants.LOGIN_GAPS_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.TITLE} values={{ name: userAlias || email }} />
      </TitleComponent>
      <AssetComponent
        {...constants.LOGIN_GAPS_ASSET_PROPS}
        onClick={handleEditUsernameAssetClick}
      />
    </LoginGapsTitleComponentStyled>
  );
};

export default LoginGapsTitleComponent;
