export default {
  PERSONAL_EMAIL_STEP_3_ACTIONS: {
    CONFIRM_PROPS: {
      testId: 'personal-email-step3-footer-confirm',
    },
    WIZARD_PROPS: {
      hasDefaultLeftButton: true,
    },
  },
};
