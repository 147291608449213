// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import CardDetailsWrapperComponent from 'components/card-details/card-details-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/card-settings-active-card-introduction.constants';
// Styles
import {
  CardSettingsActivateCardIntroductionCardDetailsStyled,
  CardSettingsActivateCardIntroductionComponentStyled,
  CardSettingsActivateCardIntroductionDescriptionComponentStyled,
} from './card-settings-activate-card-introduction.component.styled';
// Translations
import translations from './translations/card-settings-activate-card-introduction.translations';

const CardSettingsActivateCardIntroductionComponent = (): React.ReactElement => (
  <CardSettingsActivateCardIntroductionComponentStyled>
    <CardSettingsActivateCardIntroductionDescriptionComponentStyled>
      <ParagraphComponent {...constants.PARAGRAPH_DEFAULT_PROPS}>
        <FormattedMessageComponent id={translations.SUBTITLE} />
      </ParagraphComponent>
    </CardSettingsActivateCardIntroductionDescriptionComponentStyled>
    <CardSettingsActivateCardIntroductionCardDetailsStyled>
      <CardDetailsWrapperComponent />
    </CardSettingsActivateCardIntroductionCardDetailsStyled>
  </CardSettingsActivateCardIntroductionComponentStyled>
);

export default CardSettingsActivateCardIntroductionComponent;
