// Vendors
import { useEffect } from 'react';
// Contexts
import { usePersonalAddressContextConsumerHook } from '../contexts/personal-address.context';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
// Types
import { PersonalAddressHookType } from './types/personal-address.hook.type';

export const usePersonalAddressHook = (): PersonalAddressHookType => {
  const { address } = useAddressHook();

  const { error, setNewAddress } = usePersonalAddressContextConsumerHook();

  useEffect(() => {
    setNewAddress(address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
  };
};
