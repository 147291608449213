// Vendors
import { useEffect } from 'react';
// Hooks
import { useRequestSignatureKeyContextConsumerHook } from 'containers/security-keys/views/request-signature-key/contexts/request-signature-key.context';
// Handlers
import RequestSignatureKeyWizardHandlers from '../handlers/request-signature-key-wizard.handlers';
import RequestSignatureKeyWizardTrackingHandlers from '../handlers/request-signature-key-wizard.tracking.handlers';
// Types
import { RequestSignatureKeyWizardHookType } from './types/request-signature-key-wizard.hook.type';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';

const useRequestSignatureKeyWizardHook = (): RequestSignatureKeyWizardHookType => {
  const { hasTrustedDevice, setHasTrustedDevice } = useRequestSignatureKeyContextConsumerHook();
  const { handleFetchTrustedDeviceStatusEvent } = RequestSignatureKeyWizardHandlers({
    setHasTrustedDevice,
  });
  const { handleEnterRequestSignatureKeyWizardTracking } = AppTrackingHook(
    RequestSignatureKeyWizardTrackingHandlers
  );
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleEnterRequestSignatureKeyWizardTracking();
    handleFetchTrustedDeviceStatusEvent();
  }, []);

  return {
    hasTrustedDevice,
  };
};

export default useRequestSignatureKeyWizardHook;
