const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step3.downloadContract.';

const DOWNLOADING = `${DOMAIN}downloadContractLink`,
  DOWNLOAD = `${DOMAIN}downloadContractLink`,
  ERROR = `${DOMAIN}retryDownloadLink`,
  ERROR_DESCRIPTION = `${DOMAIN}errorDescription`,
  ERROR_MESSAGE = `${DOMAIN}cbToast.errorDescription`,
  SUCCESS_MESSAGE = `${DOMAIN}cbToast.successDescription`;

export { DOWNLOADING, DOWNLOAD, ERROR, ERROR_DESCRIPTION, ERROR_MESSAGE, SUCCESS_MESSAGE };
