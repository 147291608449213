// Vendors
import React from 'react';
// Components
import { FinancingRouterComponent } from './components/router/financing-router.component';
import { FinancingHeaderComponent } from './components/header/financing-header.component';
// Contexts
import { FinancingContextConsumerHOC } from './contexts/financing.context';
// Styles
import { LayoutGridComponentStyled } from 'styles/app.styled';

const FinancingBaseContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <FinancingHeaderComponent />
    <FinancingRouterComponent />
  </LayoutGridComponentStyled>
);

const FinancingContainer = FinancingContextConsumerHOC(FinancingBaseContainer);

export { FinancingContainer };
