// Vendors
import styled from 'styled-components';

export const PersonalNumberStep3MessageViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const PersonalNumberStep3SubtitleStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingM} 0;
`;
