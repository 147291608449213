const INACTIVITY_MODAL_KILL_TRACKING_PROPS = {
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'exit',
    },
  },
  INACTIVITY_MODAL_KEEP_TRACKING_PROPS = {
    payload: {
      eventAction: 'clickButton',
      eventLabel: 'keepSessionAlive',
    },
  };

export { INACTIVITY_MODAL_KILL_TRACKING_PROPS, INACTIVITY_MODAL_KEEP_TRACKING_PROPS };
