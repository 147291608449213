// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
// Components
import { InputDatePickerComponent, InputTextAreaComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DATE_CONTROLLER_NAME,
  DATE_CONTROLLER_RULES,
  DATE_INPUT_PROPS,
  DESCRIPTION_CONTROLLER_NAME,
  DESCRIPTION_CONTROLLER_RULES,
  DESCRIPTION_INPUT_PROPS,
} from './constants/transactions-report-not-received-additional-info-inputs.constants';
// Contexts
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  TransactionsReportNotReceivedAdditionalInfoInputDateStyled,
  TransactionsReportNotReceivedAdditionalInfoInputsComponentStyled,
} from './transactions-report-not-received-additional-info-inputs.component.styled';
// Translations
import {
  DATE_LABEL,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
} from './translations/transactions-report-not-received-additional-info-inputs.translations';
// Types
import { TransactionsReportNotReceivedAdditionalInfoInputsComponentType } from './types/transactions-report-not-received-additional-info-inputs.component.type';
// Utils
import { validateDate } from './utils/transactions-report-not-received-additional-info-inputs.utils';
import { setErrorIfInvalidDate } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const TransactionsReportNotReceivedAdditionalInfoInputsComponent = ({
  control,
  errors,
}: TransactionsReportNotReceivedAdditionalInfoInputsComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { transactionDetails } = useTransactionsReportContextConsumerHook(),
    { date = '' } = transactionDetails || {};
  const errorMessage = errors.date?.message && (
    <FormattedMessageComponent id={errors.date.message} />
  );

  return (
    <TransactionsReportNotReceivedAdditionalInfoInputsComponentStyled>
      <TransactionsReportNotReceivedAdditionalInfoInputDateStyled>
        <Controller
          control={control}
          name={DATE_CONTROLLER_NAME}
          rules={{
            ...DATE_CONTROLLER_RULES,
            validate: value => validateDate(value, date),
          }}
          render={({ field }) => (
            <InputDatePickerComponent
              {...DATE_INPUT_PROPS}
              {...field}
              customErrorMessage={errorMessage}
              errorMessage={errorMessage}
              label={<FormattedMessageComponent id={DATE_LABEL} />}
              onSelectDate={field.onChange}
              onError={(hasInvalidDate: boolean) =>
                setErrorIfInvalidDate({ control, hasInvalidDate, dateName: DATE_CONTROLLER_NAME })
              }
            />
          )}
        />
      </TransactionsReportNotReceivedAdditionalInfoInputDateStyled>
      <Controller
        control={control}
        name={DESCRIPTION_CONTROLLER_NAME}
        rules={DESCRIPTION_CONTROLLER_RULES}
        render={({ field }) => (
          <InputTextAreaComponent
            {...DESCRIPTION_INPUT_PROPS}
            {...field}
            label={<FormattedMessageComponent id={DESCRIPTION_LABEL} />}
            placeholder={formatMessage({ id: DESCRIPTION_PLACEHOLDER })}
            onSelectDate={field.onChange}
          />
        )}
      />
    </TransactionsReportNotReceivedAdditionalInfoInputsComponentStyled>
  );
};
