// Vendors
import { sessionUtils, storageConstants } from '@openbank/cf-ui-architecture-core';
// Constants
import { UTILS_CONSTANTS as constants, EMPTY_STRING } from './constants/utils.constants';
// Utils
import { isProduction } from 'utils/app.utils';
// Types
import { StoreDataType } from './types/redirect-lo-login.type';

const redirectToEnvironmentLogin = (): void => {
  window.location.href = `https://customer.${window.ENVIRONMENT}.cb.amazon.zinia.de/login`;
};

const redirectToProdLogin = (): void => {
  window.location.href = constants.PROD_URL;
};

const storeData = ({ userIsRemembered, username, wsAlive }: StoreDataType) => {
  localStorage.setItem(storageConstants.LOCALSTORAGE_IDS.REMEMBER_USER, String(userIsRemembered));
  if (userIsRemembered) {
    localStorage.setItem(storageConstants.LOCALSTORAGE_IDS.USERNAME, username);
  }
  if (wsAlive) {
    localStorage.setItem(storageConstants.LOCALSTORAGE_IDS.WS_ALIVE, wsAlive);
  }
};

export const redirectToLoginAndClearSessionData = (): void => {
  const userIsRemembered =
    localStorage.getItem(storageConstants.LOCALSTORAGE_IDS.REMEMBER_USER) === String(true);
  const username = localStorage.getItem(storageConstants.LOCALSTORAGE_IDS.USERNAME) ?? EMPTY_STRING;
  const wsAlive = localStorage.getItem(storageConstants.LOCALSTORAGE_IDS.WS_ALIVE) ?? EMPTY_STRING;

  sessionUtils.clearSessionData();
  storeData({ userIsRemembered, username, wsAlive });

  !isProduction() ? redirectToEnvironmentLogin() : redirectToProdLogin();
};
