// Vendors
import React from 'react';
import { MessageComponent, ResumeItemComponent, LinkComponent } from '@openbank/cb-ui-commons';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CHANGE_IBAN_STEP1_RESUME_ITEM_01_PROPS,
  CHANGE_IBAN_STEP1_RESUME_ITEM_03_PROPS,
  CHANGE_IBAN_STEP1_RESUME_ITEM_04_PROPS,
  CHANGE_IBAN_STEP1_MESSAGE_PROPS,
  CHANGE_IBAN_STEP1_MESSAGE_LINK,
} from './constants/iban-message.constants';
// Styles
import {
  ChangeIbanStep1MessageColumnLinkStyled,
  ChangeIbanStep1MessageColumnStyled,
  ChangeIbanStep1MessageStyled,
  ChangeIbanStep1MessageRowStyled,
} from './iban-message.component.styled';
// Translations
import translations from './translations/iban-message.translations';
// Types
import { IbanMessageComponentType } from './types/iban-message.component.type';

const IbanMessageComponent = ({
  linkAction,
  sepaMandate,
}: IbanMessageComponentType): React.ReactElement => (
  <MessageComponent {...CHANGE_IBAN_STEP1_MESSAGE_PROPS}>
    <ChangeIbanStep1MessageStyled>
      <ChangeIbanStep1MessageRowStyled>
        <ChangeIbanStep1MessageColumnStyled>
          <ChangeIbanStep1MessageColumnLinkStyled>
            <ResumeItemComponent
              {...CHANGE_IBAN_STEP1_RESUME_ITEM_01_PROPS}
              label={<FormattedMessageComponent id={translations.LABEL_01} />}
              value={sepaMandate?.debtorAccount}
            />
            <LinkComponent {...CHANGE_IBAN_STEP1_MESSAGE_LINK} onClick={linkAction}>
              <FormattedMessageComponent id={translations.SEPA_MODAL} />
            </LinkComponent>
          </ChangeIbanStep1MessageColumnLinkStyled>
        </ChangeIbanStep1MessageColumnStyled>
        <ChangeIbanStep1MessageColumnStyled>
          <ResumeItemComponent
            {...CHANGE_IBAN_STEP1_RESUME_ITEM_03_PROPS}
            label={<FormattedMessageComponent id={translations.LABEL_03} />}
            value={sepaMandate?.id}
          />
        </ChangeIbanStep1MessageColumnStyled>
      </ChangeIbanStep1MessageRowStyled>
      <ChangeIbanStep1MessageRowStyled>
        <ChangeIbanStep1MessageColumnStyled>
          <ResumeItemComponent
            {...CHANGE_IBAN_STEP1_RESUME_ITEM_04_PROPS}
            label={<FormattedMessageComponent id={translations.LABEL_04} />}
            value={<FormattedMessageComponent id={translations.CREDITOR_ID} />}
          />
        </ChangeIbanStep1MessageColumnStyled>
      </ChangeIbanStep1MessageRowStyled>
    </ChangeIbanStep1MessageStyled>
  </MessageComponent>
);

export { IbanMessageComponent };
