const LOCALSTORAGE_CLIENT_SESSION_DATE = 'clientSessionDate',
  LOCALSTORAGE_DR_PICASSO_DATA = 'drPicassoData',
  LOCALSTORAGE_INTERACTION_ID = 'interactionId',
  LOCAL_STORAGE_LOGIN_FROM_PRIVATE = 'loginFromPrivate';

export {
  LOCALSTORAGE_CLIENT_SESSION_DATE,
  LOCALSTORAGE_DR_PICASSO_DATA,
  LOCAL_STORAGE_LOGIN_FROM_PRIVATE,
  LOCALSTORAGE_INTERACTION_ID,
};
