// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from '../configurations/security-keys-access-key-change-third-step.challenge.configurations';
// Hooks
import SecurityKeysAccessKeyChangeThirdStepChallengeHook from './hook/security-keys-access-key-change-third-step-challenge.hook';
// Styled
import { SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled } from './security-keys-access-key-change-third-step-challenge.component.styled';

const SecurityKeysAccessKeyChangeThirdStepChallengeComponent = (): React.ReactElement => {
  const { handleManageSuccessOnChangeSmsEvent, accessKey, newAccessKey } =
    SecurityKeysAccessKeyChangeThirdStepChallengeHook();

  return (
    <SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          oldPassword: accessKey,
          newPassword: newAccessKey,
          handleChallengeSuccess: handleManageSuccessOnChangeSmsEvent,
        })}
      />
    </SecurityKeysAccessKeyChangeThirdStepChallengeComponentStyled>
  );
};

export default SecurityKeysAccessKeyChangeThirdStepChallengeComponent;
