// Constants
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
import { REQUEST_NEW_CARD_PARAMS } from '../constants/card-settings-new-card-confirmation.constants';
import {
  DEFAULT_ERROR,
  REPLACEMENT_ERROR,
} from 'containers/card-settings/views/new-card/constants/card-settings-new-card.constants';
// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
// Resolvers
import { blockCardResolver } from 'resolvers/block-card/block-card.resolvers';
// Types
import { CardSettingsNewCardConfirmationHandlersReturnType } from './types/card-settings-new-card-confirmation-return.handlers.type';
import { CardSettingsNewCardConfirmationHandlersType } from './types/card-settings-new-card-confirmation.handlers.type';
// Utils
import { isReplacementError } from 'utils/block-card/block-card.utils';

const nextButtonClickHandler = async ({
  address,
  cardId,
  challenge,
  setCard,
  setChallengeError,
  setError,
  setResult,
  setSelectedAddress,
  setTempAddress,
}: CardSettingsNewCardConfirmationHandlersType): Promise<void> => {
  const [response, error] = await blockCardResolver({
    cardId,
    progressId: challenge?.progressId,
    params: {
      ...REQUEST_NEW_CARD_PARAMS,
      address,
    },
  });

  if (error) {
    if (error === HttpStatusCodesEnum.FORBIDDEN) {
      setChallengeError(true);

      return;
    }

    setError(isReplacementError(response) ? REPLACEMENT_ERROR : DEFAULT_ERROR);
    setResult(false);
    setSelectedAddress(AddressTypesEnum.HBTL);
    setTempAddress(null);

    return;
  }

  setCard(true);
  setResult(true);
};

export const CardSettingsNewCardConfirmationHandlers = (
  props: CardSettingsNewCardConfirmationHandlersType
): CardSettingsNewCardConfirmationHandlersReturnType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});
