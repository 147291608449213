// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const FinancingPaymentMethodSecondStepSummaryComponentStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingL};
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  padding: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    grid-template-columns: auto;
  }
`;
