// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import {
  FILES_INITIAL_STATE,
  REPORT_SUBSCRIPTION_ALREADY_CANCELLED_CONTEXT_DEFAULT_VALUE,
} from './constants/report-subscription-already-cancelled.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ReportSubscriptionAlreadyCancelledContextType } from './types/report-subscription-already-cancelled.context.type';
import { FileDataType } from 'types/file-data.type';

const ReportSubscriptionAlreadyContext = createContext<any>(
  REPORT_SUBSCRIPTION_ALREADY_CANCELLED_CONTEXT_DEFAULT_VALUE
);

export const ReportSubscriptionAlreadyCancelledContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<ReportSubscriptionAlreadyCancelledContextType>
> => {
  const [cancelledSubDateBefore, setCancelledSubDateBefore] = useState<boolean | undefined>();
  const [files, setFiles] = useState<FileDataType[]>(FILES_INITIAL_STATE);

  const contextValue = useMemo(
    () => ({
      cancelledSubDateBefore,
      files,
      setCancelledSubDateBefore,
      setFiles,
    }),
    [cancelledSubDateBefore, files]
  );

  return (
    <ReportSubscriptionAlreadyContext.Provider value={contextValue}>
      {children}
    </ReportSubscriptionAlreadyContext.Provider>
  );
};

export const useReportSubscriptionAlreadyCancelledContextConsumerHook =
  (): ReportSubscriptionAlreadyCancelledContextType => useContext(ReportSubscriptionAlreadyContext);

/* eslint-disable */
export const ReportSubscriptionAlreadyCancelledContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ReportSubscriptionAlreadyCancelledContextType>> =>
    (
      <ReportSubscriptionAlreadyCancelledContextProvider>
        <Component {...props} />
      </ReportSubscriptionAlreadyCancelledContextProvider>
    );
