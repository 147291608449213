// Vendors
import { useEffect } from 'react';
// Contexts
import { DashboardContextConsumerHook } from '../contexts/dashboard.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import DashboardHandlers from '../handlers/dashboard.handlers';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Types
import { DashboardHookType } from './types/dashboard.hook.type';
// Utils
import { isCC } from 'utils/app.utils';

const useDashboardHook = (): DashboardHookType => {
  const { pageLoader, setPageLoader, setError } = ManagerContextConsumerHook(),
    { setInformation } = DashboardContextConsumerHook(),
    { isMobile } = DeviceTypeHook();

  const { handleFetchDashboardInformationEvent } = DashboardHandlers({
    setPageLoader,
    setError,
    setInformation,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchDashboardInformationEvent();
  }, []);

  return { pageLoader, changeOrder: isMobile && !isCC() };
};

export default useDashboardHook;
