// Enumerations
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

export default {
  REQUEST_PORTABILITY_DEFAULT_PARAMS: {
    includePersonalData: false,
    type: AccessPortabilityTypeEnumeration.PORTABILITY,
    emailThirdParty: '',
    selectedProducts: [],
  },
};
