// Types
import { LoadingStatus } from 'types/loading-status.type';

export default {
  MILLISECONDS_TIMEOUT: 10 as number,
  CARD_STATUS_LOADING: {
    ERROR: 'error' as LoadingStatus,
    LOADING: 'loading' as LoadingStatus,
    SUCCESS: 'success' as LoadingStatus,
    IDLE: 'idle' as LoadingStatus,
  },
};
