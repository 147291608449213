export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_SEARCH_PROPS: {
    iconConfiguration: {
      id: 'search',
      testId: 'settings-card-control-locations-search',
    },
    testing: {
      inputId: 'settings-card-control-locations-search-new',
      helperId: 'settings-card-control-locations-search-helper',
      labelId: 'settings-card-control-locations-search-label',
    },
  },
};
