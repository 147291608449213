// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_ADDRESS_TRACKING_PROPS } from './constants/financing-ppi-contracting-step2-address.handlers.tracking.constants';
// Types
import { FinancingPpiContractingStep2AddressTrackingMethodsHandlersType } from './types/financing-ppi-contracting-step2-address.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const changePostalDocumentationConsentCheckBoxClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    ...FINANCING_PPI_CONTRACTING_STEP_2_ADDRESS_TRACKING_PROPS,
    type: TrackingEventsEnum.LINK,
  });

const FinancingPpiContractingStep2AddressTrackingHandlers = (
  track: TrackBuilderType
): FinancingPpiContractingStep2AddressTrackingMethodsHandlersType => ({
  handleChangePostalDocumentationConsentCheckBoxClickTracking: () =>
    track(changePostalDocumentationConsentCheckBoxClickTrackingHandler()),
});

export { FinancingPpiContractingStep2AddressTrackingHandlers };
