// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  CANCEL_CONTRACT_SIDEBAR_PROPS: {
    testId: 'cancel-contract',
  },
  CANCEL_CONTRACT_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'cancel-contract-sidebar-message-01',
    size: paragraphConstants.SIZES.M,
  },
};
