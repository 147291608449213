// Vends
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ProfilePersonalNameTitleContextConsumerHook } from 'containers/personal/views/landing/components/name/components/title/contexts/profile-personal-name-title.context';
// Event handlers
import ProfilePersonalNameTitleEditHandlers from '../handlers/profile-personal-name-title-edit.handlers';
import ProfilePersonalNameTitleEditTrackingHandlers from '../handlers/profile-personal-name-title-edit.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameTitleEditHookType } from './types/profile-personal-name-title-edit.hook.type';

const ProfilePersonalNameTitleEditHook = (): ProfilePersonalNameTitleEditHookType => {
  const { formatMessage } = useCbIntl();

  const { setEditTitle } = ProfilePersonalNameTitleContextConsumerHook(),
    { profile } = AppContextConsumerHook(),
    { title } = profile?.userDetails || {};

  return {
    ...ProfilePersonalNameTitleEditHandlers({
      ...AppTrackingHook(ProfilePersonalNameTitleEditTrackingHandlers),
      setEditTitle,
    }),
    formatMessage,
    title,
  };
};

export default ProfilePersonalNameTitleEditHook;
