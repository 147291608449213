// Vendors
import React from 'react';
// Components
import {
  TitleComponent,
  RadioListComponent,
  LineSeparatorComponent,
} from '@openbank/cb-ui-commons';
import { FilterModalInputRangeComponent } from '../input-range/filter-modal-input-range.component';
// Constants
import {
  DATE_OPTIONS,
  FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS,
  FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT,
} from './constants/filter-modal-list-selector-date.constants';
// Enumerations
import { CalculatedDateOptionsEnumeration } from './enumerations/calculated-dates-options-types.enumeration';
import { FilterModalInputRangeTypesEnumeration } from '../input-range/enumerations/filter-modal-input-range-types.enumeration';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FilterModalListSelectorComponentStyled } from './filter-modal-list-selector-date.component.styled';
// Translations
import translations from 'containers/transactions/views/landing/translations/transactions-landing.translations';
// Types
import { FilterModalListSelectorDateComponentType } from './types/filter-modal-list-selector-date.component.type';
// Utilities
import {
  calculatedDates,
  isCustomDateOption,
  mapDateOptions,
} from './utils/filter-modal-list-selector-date.utils';
import { getValueOrDefault } from '../../utils/filter-modal.utils';

const FilterModalListSelectorDateComponent = ({
  formValues,
  handleFormValuesChange,
  showLineSeparator = FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS.showLineSeparator,
  testId = FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS.testId,
}: FilterModalListSelectorDateComponentType): React.ReactElement => {
  const { formatMessage, formatDate } = useCbIntl();

  return (
    <>
      <FilterModalListSelectorComponentStyled>
        <TitleComponent {...FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT}>
          {formatMessage({ id: translations.DATE_TITLE })}
        </TitleComponent>
        <RadioListComponent
          gap={FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS.gap}
          options={mapDateOptions({ options: DATE_OPTIONS, formatMessage, formatDate })}
          onChange={(id: string) => {
            handleFormValuesChange({
              field: 'dateOption',
              value: {
                value: id as CalculatedDateOptionsEnumeration,
              },
            });
            handleFormValuesChange({
              field: 'fromDate',
              value: {
                value: calculatedDates({
                  calculatedDatesOptions: id as CalculatedDateOptionsEnumeration,
                }).firstDay,
                label: calculatedDates({
                  calculatedDatesOptions: id as CalculatedDateOptionsEnumeration,
                  format: true,
                  formatDate,
                }).firstDay,
              },
            });
            handleFormValuesChange({
              field: 'toDate',
              value: {
                value: calculatedDates({
                  calculatedDatesOptions: id as CalculatedDateOptionsEnumeration,
                }).lastDay,
                label: calculatedDates({
                  calculatedDatesOptions: id as CalculatedDateOptionsEnumeration,
                  format: true,
                  formatDate,
                }).lastDay,
              },
            });
          }}
          value={getValueOrDefault(formValues?.dateOption?.value)}
        />
      </FilterModalListSelectorComponentStyled>
      {!showLineSeparator ||
        (!isCustomDateOption(formValues?.dateOption?.value) && <LineSeparatorComponent />)}
      {isCustomDateOption(formValues?.dateOption?.value) && (
        <FilterModalInputRangeComponent
          {...{
            type: FilterModalInputRangeTypesEnumeration.DATE,
            handleFormValuesChange,
            formValues,
          }}
        />
      )}
      {!showLineSeparator ||
        (isCustomDateOption(formValues?.dateOption?.value) && <LineSeparatorComponent />)}
    </>
  );
};

export { FilterModalListSelectorDateComponent };
