// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  INACTIVITY_MODAL_KEEP_TRACKING_PROPS,
  INACTIVITY_MODAL_KILL_TRACKING_PROPS,
} from './constants/inactivity-modal.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { InactivityModalMethodsTrackingHandlersType } from './types/inactivity-modal.tracking.handlers.type';

const killUserSessionAndExitClickTrackingHandler = (): TrackingLinkEventReturnType => ({
    ...INACTIVITY_MODAL_KILL_TRACKING_PROPS,
    type: TrackingEventsEnum.LINK,
  }),
  keepSessionAliveButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
    ...INACTIVITY_MODAL_KEEP_TRACKING_PROPS,
    type: TrackingEventsEnum.LINK,
  });

const InactivityModalTrackingHandlers = (
  track: TrackBuilderType
): InactivityModalMethodsTrackingHandlersType => ({
  handleKeepSessionAliveButtonClickTracking: () =>
    track(keepSessionAliveButtonClickTrackingHandler()),
  handleKillUserSessionAndExitLinkClickTracking: () =>
    track(killUserSessionAndExitClickTrackingHandler()),
});

export { InactivityModalTrackingHandlers };
