// Types
import { ProfileConsentsStep1HandlersPropsType } from './types/profile-consents-step1-props.handlers.type';
import { ProfileConsentsStep1HandlersType } from './types/profile-consents-step1.handlers.type';
import { OnChangeSubconsentHandlerPropsType } from './types/on-change-subconsent-props.handler.type';
import { OnToggleConsentHandlerPropsType } from './types/on-toggle-consent-props.handler.type';
// Utilities
import { toggleConsent, toggleSubconsentById } from './utils/profile-consents-step1.handlers.utils';

const onToggleConsentHandler = ({
  handleSelectConsentsTracking,
  index,
  setConsents,
}: OnToggleConsentHandlerPropsType): void =>
  setConsents(prevState =>
    prevState?.consentTypes
      ? {
          ...prevState,
          consentTypes: toggleConsent({
            consents: prevState.consentTypes,
            index,
            tracking: handleSelectConsentsTracking,
          }),
        }
      : prevState
  );

const onChangeSubconsentHandler = ({
  handleCheckSubconsentsTracking,
  subConsentId,
  setConsents,
}: OnChangeSubconsentHandlerPropsType): void => {
  handleCheckSubconsentsTracking(subConsentId);
  setConsents(prevState =>
    prevState?.consentTypes
      ? {
          ...prevState,
          consentTypes: toggleSubconsentById(prevState.consentTypes, subConsentId),
        }
      : prevState
  );
};

const ProfileConsentsStep1Handlers = ({
  handleSelectConsentsTracking,
  handleCheckSubconsentsTracking,
  setConsents,
}: ProfileConsentsStep1HandlersPropsType): ProfileConsentsStep1HandlersType => ({
  handleToggleConsent: (index: number) =>
    onToggleConsentHandler({ handleSelectConsentsTracking, index, setConsents }),
  handleChangeSubconsent: (subConsentId: string) =>
    onChangeSubconsentHandler({ handleCheckSubconsentsTracking, subConsentId, setConsents }),
});

export default ProfileConsentsStep1Handlers;
