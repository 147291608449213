// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const NotificactionsSettingsViewWrapperStyled = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

const NotificactionsSettingsViewStyled = styled.section.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  height: 100%;
`;

export { NotificactionsSettingsViewWrapperStyled, NotificactionsSettingsViewStyled };