// Vendors
import React from 'react';
// Components
import CardSettingsContractSidebarComponent from './components/sidebar/card-settings-contract-sidebar.component';
import CardSettingsContractCurrentComponent from './components/current/card-settings-contract-current.component';
import CardSettingsContractOthersComponent from './components/others/card-settings-contract-others.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
// Configurations
import { getErrorLinkConfiguration } from 'configurations/app.configurations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { useCardSettingsContractHook } from './hooks/card-settings-contract.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { CardSettingsContractViewStyled } from './card-settings-contract.view.styled';

const CardSettingsContractView = (): React.ReactElement => {
  const { error, fetching, formatMessage, navigate, ppiInfo } = useCardSettingsContractHook();

  return (
    <ErrorWrapperComponent
      {...{
        fetching,
        error,
        linkConfiguration: getErrorLinkConfiguration({
          formatMessage,
          route: RoutesEnumeration.CARD_SETTINGS,
          navigate,
        }),
      }}
    >
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <CardSettingsContractViewStyled>
            <CardSettingsContractCurrentComponent
              endCoverageDate={ppiInfo.endCoverageDate}
              status={ppiInfo.status}
            />
            <CardSettingsContractOthersComponent />
          </CardSettingsContractViewStyled>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <CardSettingsContractSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </ErrorWrapperComponent>
  );
};
export default CardSettingsContractView;
