// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const RecoveryKeyStep1ComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
RecoveryKeyStep1ComponentStyled.displayName = 'RecoveryKeyStep1ComponentStyled';

const RecoveryKeyStep1ContentComponentStyled = styled(RecoveryKeyStep1ComponentStyled)`
  max-width: ${convertPxsToRems({ pixels: 276, base: 14 })};

  ${mobileMediaQuery} {
    max-width: 100%;
  }
`;
RecoveryKeyStep1ContentComponentStyled.displayName = 'RecoveryKeyStep1ContentComponentStyled';

export { RecoveryKeyStep1ComponentStyled, RecoveryKeyStep1ContentComponentStyled };
