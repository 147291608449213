// Vendors
import React from 'react';
// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { NO_RESULTS_IMAGE } from './constants/financing-insurances-landing-no-insurances.constants';
// Hooks
import { useFinancingInsurancesLandingNoInsurances } from './hooks/financing-insurances-landing-no-insurances.hook';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import { FinancingInsurancesLandingNoInsurancesComponentStyled } from './financing-insurances-landing-no-insurances.component.styled';
// Translations
import { GO_TO_FINANCING } from 'translations/common.translations';
import { NO_INSURANCES_TITLE } from './translations/financing-insurances-landing-no-insurances.translations';

export const FinancingInsurancesLandingNoInsurancesComponent = (): React.ReactElement => {
  const { handleGoToFinancingButtonClick } = useFinancingInsurancesLandingNoInsurances();

  return (
    <AppCardSectionComponentStyled>
      <AppCardContentSectionComponentStyled>
        <FinancingInsurancesLandingNoInsurancesComponentStyled>
          <ErrorComponent
            assetConfiguration={NO_RESULTS_IMAGE}
            buttonConfiguration={{
              children: <FormattedMessageComponent id={GO_TO_FINANCING} />,
              onClick: handleGoToFinancingButtonClick,
            }}
            title={<FormattedMessageComponent id={NO_INSURANCES_TITLE} />}
          />
        </FinancingInsurancesLandingNoInsurancesComponentStyled>
      </AppCardContentSectionComponentStyled>
    </AppCardSectionComponentStyled>
  );
};
