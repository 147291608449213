// Vendors
import React from 'react';
// Components
import SecurityKeysAccessKeyChangeStep3ActionsComponent from './actions/security-keys-access-key-change-third-step-actions.component';
import SecurityKeysAccessKeyChangeContainerHeaderThirdStep from './actions/components/security-keys-access-key-change-third-step-header.component';
import SecurityKeysAccessKeyChangeThirdStepChallengeComponent from './challenge/security-keys-access-key-change-third-step-challenge.component';


const SecurityKeysAccessKeyChangeThirdStepComponent = (): React.ReactElement => {
  return (
    <>
      <SecurityKeysAccessKeyChangeContainerHeaderThirdStep />
      <SecurityKeysAccessKeyChangeThirdStepChallengeComponent />
      <SecurityKeysAccessKeyChangeStep3ActionsComponent />
    </>
  );
};

export default SecurityKeysAccessKeyChangeThirdStepComponent;
