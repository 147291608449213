const DOMAIN = 'cobranded.services.userSettings.privateArea.ibanBilling.changeIBAN.step1.cbMessage.';

export default {
  LABEL_01: `${DOMAIN}label01`,
  LABEL_02: `${DOMAIN}label02`,
  LABEL_03: `${DOMAIN}label03`,
  LABEL_04: `${DOMAIN}label04`,
  CREDITOR_ID: `${DOMAIN}creditorId`,
  SEPA_MODAL: `${DOMAIN}sepaModal`,
};
