// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  COMPLETE,
  EXAMPLE_MODAL_BUTTON_PROPS,
  EXAMPLE_MODAL_DESCRIPTION_PROPS,
  NOT_REFLECTIONS,
  VALID_AND_UP_TO_DATE,
} from './constants/example-modal.constants';
// Hooks
import useCancelModalHook from './hooks/example-modal.hook';
// Styles
import {
  AssetComponentStyled,
  CancelModalDescriptionComponentStyled,
  CancelModalDescriptionSubtitleStyled,
  ModalComponentStyled,
} from './example-modal.component.styled';
//Translations
import {
  BE_COMPLETE_DESCRIPTION,
  BUTTON,
  DESCRIPTION,
  NOT_REFLECTIONS_DESCRIPTION,
  TITLE,
  VALID_AND_UP_TO_DATE_DESCRIPTION,
} from './translations/example-modal.translations';
// Types
import { CancelModalHookType } from 'components/cancel-modal/types/cancel-modal.hook.type';

const ExampleModalComponent = ({
  onClose,
  show,
}: CancelModalHookType): React.ReactElement | null => {
  const { handleCloseExampleModalAssetClick } = useCancelModalHook({ onClose, show });

  return show ? (
    <ModalComponentStyled>
      <ModalComponent
        isIosDevice={isIosWebView()}
        primaryButtonConfiguration={{
          ...EXAMPLE_MODAL_BUTTON_PROPS,
          children: <FormattedMessageComponent id={BUTTON} />,
          onClick: handleCloseExampleModalAssetClick,
        }}
        onHeaderClose={handleCloseExampleModalAssetClick}
        title={<FormattedMessageComponent id={TITLE} />}
      >
        <CancelModalDescriptionSubtitleStyled>
          <CancelModalDescriptionComponentStyled>
            <AssetComponentStyled>
              <AssetComponent {...NOT_REFLECTIONS} />
              <ParagraphComponent>
                <FormattedMessageComponent id={NOT_REFLECTIONS_DESCRIPTION} />
              </ParagraphComponent>
            </AssetComponentStyled>
            <AssetComponentStyled>
              <AssetComponent {...COMPLETE} />
              <ParagraphComponent>
                <FormattedMessageComponent id={BE_COMPLETE_DESCRIPTION} />
              </ParagraphComponent>
            </AssetComponentStyled>
            <AssetComponentStyled>
              <AssetComponent {...VALID_AND_UP_TO_DATE} />
              <ParagraphComponent>
                <FormattedMessageComponent id={VALID_AND_UP_TO_DATE_DESCRIPTION} />
              </ParagraphComponent>
            </AssetComponentStyled>
          </CancelModalDescriptionComponentStyled>
          <ParagraphComponent {...EXAMPLE_MODAL_DESCRIPTION_PROPS}>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
        </CancelModalDescriptionSubtitleStyled>
      </ModalComponent>
    </ModalComponentStyled>
  ) : null;
};

export { ExampleModalComponent };
