// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { INSURANCE_BENEFITS_CONSTANTS } from './constants/insurance-benefits-component.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { InsuranceBenefitsComponentWrapperStyled } from './insurance-benefits-component.style';
import { LayoutRowFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  FIRST_INSURANCE_BENEFIT_DESCRIPTION,
  FOURTH_INSURANCE_BENEFIT_DESCRIPTION,
  SECOND_INSURANCE_BENEFIT_DESCRIPTION,
  THIRD_INSURANCE_BENEFIT_DESCRIPTION,
} from './translations/insurance-benefits-component.translations';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

const InsuranceBenefitsComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const {
    localization: {
      config: { cfg_public_lpi_general_tnc },
    },
  } = AppContextConsumerHook();

  return (
    <InsuranceBenefitsComponentWrapperStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.FIRST} />
        <ParagraphComponent>
          <FormattedMessageComponent id={FIRST_INSURANCE_BENEFIT_DESCRIPTION} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.SECOND} />
        <ParagraphComponent>
          {formatMessage(
            { id: SECOND_INSURANCE_BENEFIT_DESCRIPTION },
            {
              br: <br />,
              termsUrl: chunk =>
                makeChunkLinkIntlUtil({
                  chunk,
                  onClick: () => window.open(cfg_public_lpi_general_tnc, '_blank'),
                }),
            }
          )}
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.THIRD} />
        <ParagraphComponent>
          <FormattedMessageComponent id={THIRD_INSURANCE_BENEFIT_DESCRIPTION} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...INSURANCE_BENEFITS_CONSTANTS.GAP}>
        <AssetComponent {...INSURANCE_BENEFITS_CONSTANTS.ASSETS.FOURTH} />
        <ParagraphComponent>
          <FormattedMessageComponent id={FOURTH_INSURANCE_BENEFIT_DESCRIPTION} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
    </InsuranceBenefitsComponentWrapperStyled>
  );
};

export { InsuranceBenefitsComponent };
