// Enumerations
import { BillingPaymentMethodEnumeration } from 'enumerations/billing-payment-method.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cardStatements.cbTableRowDescription.';

const PAYMENT_METHOD_VALUE = {
  [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: `${DOMAIN}fixedPaymentAmount`,
  [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: `${DOMAIN}fullAccountBalance`,
  [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]: `${DOMAIN}revolvingPercentageAmount`,
};

export { PAYMENT_METHOD_VALUE };
