// Constants
import constants from '../constants/login.constants';
// Enums
import { EnvironmentsEnum } from '@openbank/cf-ui-static-data';

export const getVerifyAccountUrl = (): string => {
  let verifyAccountUrl = constants.LOGIN_VERIFY_NOW_PROD_URL;

  if (window.ENVIRONMENT.toUpperCase() === EnvironmentsEnum.PROD.toUpperCase()) {
    return verifyAccountUrl;
  } else if (Object.keys(EnvironmentsEnum).includes(window.ENVIRONMENT.toUpperCase())) {
    const environment = window.ENVIRONMENT;
    verifyAccountUrl = `https://customer.${environment}.cb.amazon.zinia.de/stc/onboarding/verify-now`;
  }
  return verifyAccountUrl;
};
