// Vendors
import { useEffect, useState } from 'react';
// Event handlers
import OtpHandlers from '../handlers/otp.handlers';
import OtpTrackingHandlers from '../handlers/otp.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { OtpHookType } from './types/otp.hook.type';
import { OtpApiComponentResponseType } from '../types/otp-api-response.type';

const useOtpHook = (): OtpHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [emailMessage, setEmailMessage] = useState<
    OtpApiComponentResponseType | Record<string, any>
  >({});
  const [smsMessage, setSmsMessage] = useState<OtpApiComponentResponseType | Record<string, any>>(
    {}
  );

  const { handleFetchLastSmsEmailMessagesEvent, handleLoadLastUserMessagesButtonClick } =
    OtpHandlers({
      ...AppTrackingHook(OtpTrackingHandlers),
      setFetching,
      setError,
      setEmailMessage,
      setSmsMessage,
    });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchLastSmsEmailMessagesEvent();
  }, []);

  return {
    fetching,
    error,
    emailMessage,
    handleLoadLastUserMessagesButtonClick,
    smsMessage,
  };
};

export default useOtpHook;
