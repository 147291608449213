// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { usePersonalNumberStep1ContextConsumerHook } from 'containers/personal/views/number/components/step1/contexts/personal-number-step1.context';
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
// Event handlers
import PersonalPhoneStep1RepeatPhoneHandlers from '../handlers/personal-number-step1-repeat-phone.handlers';
import PersonalPhoneStep1PhoneTrackingHandlers from '../handlers/personal-number-step1-repeat-phone.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalNumberKeyStep1RepeatPhoneHookType } from './types/personal-number-step1-repeat-phone.hook.type';
// Hooks
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';
// Utils
import { getDefaultDropDownPrefixValue } from 'containers/personal/views/number/components/step1/utils/personal-number-step1.utils';

const usePersonalNumberStep1RepeatPhoneHook = (): PersonalNumberKeyStep1RepeatPhoneHookType => {
  const { localization } = AppContextConsumerHook(),
    {
      fetching,
      phoneNumber,
      repeatPhoneNumber,
      prefix,
      repeatPrefix,
      setRepeatPhoneNumber,
      setRepeatPrefix,
      phonesMatch,
      setPhonesMatch,
      phoneIsValid,
    } = usePersonalNumberStep1ContextConsumerHook(),
    { setNewPhoneNumber, setNewPrefix } = PersonalNumberContextConsumerHook(),
    { phonePrefixes: prefixes = [] } = localization,
    { primaryConfigurablePrefix } = PersonalNumberHook(),
    { formatMessage } = useCbIntl();
  const [error, setError] = useState<boolean>(false);

  const defaultPrefix = getDefaultDropDownPrefixValue({
    prefixes,
    prefix: repeatPrefix === '' ? `+${primaryConfigurablePrefix}` : repeatPrefix,
    setFunction: setRepeatPrefix,
  });

  const {
    handleManagePersonalNumberStep1RepeatNumberInputErrorTracking,
    handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking,
    handleSavePersonalNumberStep1RepeatNumberInputClickEventTracking,
  } = AppTrackingHook(PersonalPhoneStep1PhoneTrackingHandlers);

  return {
    ...PersonalPhoneStep1RepeatPhoneHandlers({
      handleManagePersonalNumberStep1RepeatNumberInputErrorTracking,
      handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking,
      prefix,
      repeatPrefix,
      phoneNumber,
      repeatPhoneNumber,
      phoneIsValid,
      setError,
      setRepeatPhoneNumber,
      setRepeatPrefix,
      setPhonesMatch,
      setNewPhoneNumber,
      setNewPrefix,
    }),
    error,
    fetching,
    formatMessage,
    handleSavePersonalNumberStep1RepeatNumberInputClickEventTracking,
    defaultPrefix,
    phoneNumber,
    repeatPhoneNumber,
    prefixes,
    prefix,
    repeatPrefix,
    phonesMatch,
    phoneIsValid,
    primaryConfigurablePrefix,
    setPhonesMatch,
    setRepeatPrefix,
  };
};

export default usePersonalNumberStep1RepeatPhoneHook;
