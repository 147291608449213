// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingPaymentMethodSecondStepSummaryTncHookType } from './types/financing-payment-method-second-step-summary-tnc.hook.type';

const useFinancingPaymentMethodSecondStepSummaryTncHook =
  (): FinancingPaymentMethodSecondStepSummaryTncHookType => {
    const { formatMessage } = useCbIntl();

    const { paymentMethod } = FinancingPaymentMethodContextConsumerHook(),
      { localization } = AppContextConsumerHook(),
      { cfg_public_visa_general_tnc: visaGeneralTnc } = localization?.config || {};

    return {
      formatMessage,
      paymentMethod,
      visaGeneralTnc,
    };
  };

export { useFinancingPaymentMethodSecondStepSummaryTncHook };
