// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { FilterContextPropsType, FiltersContextType } from './types/filters-context.type';

const FiltersContext = createContext<FiltersContextType>({} as FiltersContextType);

const FiltersContextProvider = ({
  children,
}: FilterContextPropsType): React.ReactElement<React.Provider<FiltersContextType>> => {
  const [formValues, setFormValues] = useState({});
  const [copyOfFormValues, setCopyOfFormValues] = useState({});
  const [formValuesParams, setFormValuesParams] = useState({});
  const [isShowFilterModal, setIsShowFilterModal] =
    useState<FiltersContextType['isShowFilterModal']>(false);

  return (
    <FiltersContext.Provider
      value={{
        copyOfFormValues,
        formValues,
        formValuesParams,
        isShowFilterModal,
        setCopyOfFormValues,
        setFormValues,
        setFormValuesParams,
        setIsShowFilterModal,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

const useFiltersContextConsumerHook = (): FiltersContextType => useContext(FiltersContext);

export { FiltersContextProvider, useFiltersContextConsumerHook };
