// Vendors
import { useEffect } from 'react';
// Handlers
import { InsurancesHandlers } from '../handlers/insurances.handlers';
// Types
import { InsurancesHookType } from './types/insurances.hook.type';
import { InsurancesReturnHookType } from './types/insurances-return.hook.type';

export const useInsurances = ({
  setFetchError,
  setFetching,
  setInsurancesInfo,
}: InsurancesHookType): InsurancesReturnHookType => {
  const { handleFetchInsurancesInfo } = InsurancesHandlers({
    setFetchError,
    setFetching,
    setInsurancesInfo,
  });

  useEffect(() => {
    handleFetchInsurancesInfo();
  }, []);

  return {
    handleFetchInsurancesInfo,
  };
};
