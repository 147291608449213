// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RADIO_EVENT_LABEL,
  TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RADIO_TRACKING,
} from 'containers/transactions/views/epp/components/step1/components/simulation/components/options/handlers/constants/transactions-epp-step1-simulation-options.tracking.handlers.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TransactionsEppStep1SimulationOptionsTrackingMethodsHandlersType } from './types/transactions-epp-step1-simulation-options.tracking.handlers.type';
import { TransactionsEppStep1SimulationOptionType } from '../types/transactions-epp-step1-simulation-option.type';

const selectInstallmentOptionCheckClickTrackingHandler = (
  id: TransactionsEppStep1SimulationOptionType['id']
): TrackingLinkEventReturnType => ({
  payload: {
    ...TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RADIO_TRACKING,
    eventLabel: TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RADIO_EVENT_LABEL + id,
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsEppStep1SimulationOptionsTrackingHandlers = (
  track: TrackBuilderType
): TransactionsEppStep1SimulationOptionsTrackingMethodsHandlersType => ({
  handleSelectInstallmentOptionCheckClickTracking: (
    id: TransactionsEppStep1SimulationOptionType['id']
  ) => track(selectInstallmentOptionCheckClickTrackingHandler(id)),
});

export { TransactionsEppStep1SimulationOptionsTrackingHandlers };
