// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalNumberLogoutModalTrackingHandlersType } from 'containers/personal/views/number/components/logout-modal/handlers/types/personal-number-logout-modal.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Constants
import {
  CLICK_LINK_TRACKING,
  CLICK_BUTTON_TRACKING,
  LOG_OFF_MODAL_OPENING_TRACKING,
  LOGOUT_EVENT_TRACKING,
} from './constants/personal-number-logout-modal.handlers.constants';

const openLogOffModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_LINK_TRACKING,
    eventLabel: LOG_OFF_MODAL_OPENING_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const logoutFromChangeNumberButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_BUTTON_TRACKING,
    eventLabel: LOGOUT_EVENT_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalNumberLogoutModalTrackingHandlers = (
  track: TrackBuilderType
): PersonalNumberLogoutModalTrackingHandlersType => ({
  handleOpenLogOffModalTracking: () => track(openLogOffModalTrackingHandler()),
  handleLogoutFromChangeNumberButtonClickTracking: () =>
    track(logoutFromChangeNumberButtonClickTrackingHandler()),
});

export default PersonalNumberLogoutModalTrackingHandlers;
