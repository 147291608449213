// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
// Enums
import { AccessPortabilityStatusEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
export const AccessPortabilityRequestTableRowContainerStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const AccessPortabilityRequestTableRowContentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: space-between;
  width: 100%;

  ${mobileMediaQuery} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const AccessPortabilityDownloadButtonStyled = styled(ButtonComponent)<{
  $status: AccessPortabilityStatusEnumeration;
}>`
  visibility: ${({ $status }) =>
    $status !== AccessPortabilityStatusEnumeration.AVAILABLE ? 'hidden' : 'visible'};

  ${mobileMediaQuery} {
    display: ${({ $status }) =>
    $status !== AccessPortabilityStatusEnumeration.AVAILABLE ? 'none' : 'unset'};
  }
`;
