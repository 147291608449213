// Types
import { ToastBuilderHandlersType } from 'components/toast/handlers/types/toast-builder.handlers.type';
import { ToastBuilderReturnHandlersType } from 'components/toast/handlers/types/toast-builder-return.handlers.type';

const hideAppToastEventHandler = ({ setToastConfiguration, onClose }: ToastBuilderHandlersType, event?: MouseEvent): any => {
  event && onClose?.();

  setToastConfiguration({});
};

const ToastHandlers = (props: ToastBuilderHandlersType): ToastBuilderReturnHandlersType => ({
  handleHideAppToastEvent: (event?: MouseEvent) => hideAppToastEventHandler(props, event),
});

export default ToastHandlers;
