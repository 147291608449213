// Vendors
import { useState } from 'react';
import { isMobileDevice, isTabletDevice } from '@openbank/cf-ui-framework';
// Handlers
import { useHandleResize } from '../handlers/device-type.handler';
// Types
import { DeviceTypeHookType } from './types/device-type.hook.type';

export const DeviceTypeHook = (): DeviceTypeHookType => {
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [isTablet, setIsTablet] = useState(isTabletDevice());

  useHandleResize(setIsMobile, setIsTablet);

  return { isMobile, isTablet, isMobileOrTablet: isMobile || isTablet };
};
