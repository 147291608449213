const SELECTOR_CARD_LIST_COPIED = ' Copied!';

const SELECTOR_CARD_LIST_PROPS = [
  {
    title: 'IBAN',
    linkConfiguration: {
      children: 'Copy',
      type: 'action_primazry',
      underline: true,
    },
  },
  {
    title: 'Bank',
  },
];

export { SELECTOR_CARD_LIST_COPIED, SELECTOR_CARD_LIST_PROPS };
