// Vendors
import styled from 'styled-components';

const RequestSignatureKeyAddressSelectorStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxxs};
`;

export { RequestSignatureKeyAddressSelectorStyled };
