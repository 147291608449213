// Vendors
import styled from 'styled-components';

export const PersonalAccessPortabilitySidebarListStyled = styled.ul`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingM};
  margin: 0;
`;

export const PersonalAccessPortabilitySidebarListItemStyled = styled.li`
  padding: 0;
  margin: 0;
`;
