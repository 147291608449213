// Vendors
import React from 'react';
// Components
import { FormattedDate } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import {
  TRAVEL_PLUS_MESSAGE_DESCRIPTION_PROPS,
  TRAVEL_PLUS_MESSAGE_PROPS,
  TRAVEL_PLUS_MESSAGE_TITLE_PROPS,
} from './constants/travel-plus-message.constants';
// Hooks
import { useTravelPlusMessageHook } from './hooks/travel-plus-message.hook';
// Styles
import { TravelPlusMessageComponentStyled } from './travel-plus-message.component.styled';
// Translations
import { DESCRIPTION, TITLE } from './translations/travel-plus-message.translations';
// Utilities
import { isActivatedAndHasRenovationDate } from './utils/travel-plus-message.utils';

const TravelPlusMessageComponent = (): React.ReactElement | null => {
  const { activated, expirationDate } = useTravelPlusMessageHook();

  return isActivatedAndHasRenovationDate({ activated, expirationDate }) ? (
    <TravelPlusMessageComponentStyled>
      <MessageComponent {...TRAVEL_PLUS_MESSAGE_PROPS}>
        <TravelPlusMessageComponentStyled>
          <ParagraphComponent {...TRAVEL_PLUS_MESSAGE_TITLE_PROPS}>
            <FormattedMessageComponent
              id={TITLE}
              values={{
                date: (
                  <FormattedDate
                    {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
                    value={expirationDate}
                  />
                ),
              }}
            />
          </ParagraphComponent>
          <ParagraphComponent {...TRAVEL_PLUS_MESSAGE_DESCRIPTION_PROPS}>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
        </TravelPlusMessageComponentStyled>
      </MessageComponent>
    </TravelPlusMessageComponentStyled>
  ) : null;
};

export { TravelPlusMessageComponent };
