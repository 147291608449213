// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent, ParagraphComponent, AssetComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  EXPIRED_KYC_MODAL_DEFAULT_ASSET_PROPS,
  MODAL_PROPS,
} from './constants/expired-kyc-modal.constants';
// Hooks
import { useKycExpiredModalHook } from './hooks/expired-kyc-modal.hook';
// Styles
import {
  ExpiredKycModalDescriptionComponentStyled,
  ExpiredKycModalDescriptionSubtitleStyled,
} from './expired-kyc-modal.compent.styled';
// Translations
import { KYC_MODAL_TRANSLATIONS } from './translations/expired-kyc-modal.components.translations';

const KycExpiredModalComponent = (): React.ReactElement => {
  const {
    formatMessage,
    handleNavigateToFincancing,
    handleNavigateToPersonalData,
    handleCloseModal,
  } = useKycExpiredModalHook();

  return (
    <ModalComponent
      {...MODAL_PROPS}
      isIosDevice={isIosWebView()}
      title={formatMessage({ id: KYC_MODAL_TRANSLATIONS.TITLE })}
      onHeaderClose={handleCloseModal}
      description={formatMessage({ id: KYC_MODAL_TRANSLATIONS.DESCRIPTION })}
      primaryButtonConfiguration={{
        children: formatMessage({ id: KYC_MODAL_TRANSLATIONS.BUTTON }),
        onClick: handleNavigateToPersonalData,
      }}
      linkConfiguration={{
        children: formatMessage({ id: KYC_MODAL_TRANSLATIONS.LINK }),
        onClick: handleNavigateToFincancing,
      }}
    >
      <ExpiredKycModalDescriptionComponentStyled
        {...{ testId: `${MODAL_PROPS.testId}-descritpion` }}
      >
        <AssetComponent
          {...EXPIRED_KYC_MODAL_DEFAULT_ASSET_PROPS}
          id={EXPIRED_KYC_MODAL_DEFAULT_ASSET_PROPS.id}
        />
        <ExpiredKycModalDescriptionSubtitleStyled {...{ testId: `${MODAL_PROPS.testId}-subtitle` }}>
          <ParagraphComponent>
            <FormattedMessageComponent id={KYC_MODAL_TRANSLATIONS.DESCRIPTION} />
          </ParagraphComponent>
        </ExpiredKycModalDescriptionSubtitleStyled>
      </ExpiredKycModalDescriptionComponentStyled>
    </ModalComponent>
  );
};

export { KycExpiredModalComponent };
