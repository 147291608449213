// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Constants
import constants from './constants/personal-email-step2-challenge.hook.constants';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
import { PersonalEmailStep2ContextConsumerHook } from 'containers/personal/views/email/components/step2/contexts/personal-email-step2.context';
// Hooks
import useTimer from 'hooks/use-timer.hook';
// Event handlers
import PersonalEmailStep2ChallengeHandlers from '../handlers/personal-email-step2-challenge.handlers';
import PersonalEmailStep2TrackingHandlers from '../handlers/personal-email-step2-challenge.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalEmailStep2ChallengeHookType } from './types/personal-email-step2-challenge.hook.type';
import { PersonalEmailStep2ChallengePropsType } from './../types/personal-email-step2-challenge-props.type';
// Utils
import { getTimerLabelTranslationId } from './utils/personal-email-step2-challenge.hook.utils';

const PersonalEmailStep2ChallengeHook = ({
  onRetry,
}: Pick<PersonalEmailStep2ChallengePropsType, 'onRetry'>): PersonalEmailStep2ChallengeHookType => {
  const { formatMessage } = useCbIntl();
  const [error, setError] = useState<boolean | null>(null);

  const { otpId, otpValue, setOtpValue } = PersonalEmailStep2ContextConsumerHook(),
    { challenged, fetching, newEmail, setChallenged, setFetching } =
      PersonalEmailContextConsumerHook();

  const { timer, isTimerActive, handleRestart } = useTimer(constants.DEFAULT_RETRY_TIMER);

  const timerLabelTranslationId = getTimerLabelTranslationId(timer);
  
  const handlerProps = { 
    onRetry,
    handleRestart,
    props: {
      ...AppTrackingHook(PersonalEmailStep2TrackingHandlers),
      newEmail,
      otpId,
      otpValue,
      setChallenged,
      setError, 
      setFetching, 
    },
  }; 

  return {
    ...PersonalEmailStep2ChallengeHandlers(handlerProps),
    challenged,
    error,
    fetching,
    formatMessage,
    otpValue,
    setOtpValue, 
    isTimerActive,
    timer,
    timerLabelTranslationId,
  };
};

export default PersonalEmailStep2ChallengeHook;
