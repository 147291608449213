// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Types
import { TransactionsEppStep1HookType } from './types/transactions-epp-step1.hook.type';
// Utilities
import { cardActive } from 'utils/card.utils';
import {
  eppPlanInstallmentStep1FooterIsDisabled,
  hasInsufficientCredit,
} from './utils/transactions-epp-step1.hook.utils';
import { getAbsoluteTransactionTotalAmount } from 'utils/transactions/transactions.utils';

const useTransactionsEppStep1Hook = (): TransactionsEppStep1HookType => {
  const navigate = useNavigate();
  const { currentCard } = AppContextConsumerHook(),
    { eppPlanSimulation, transactionDetails: { amounts, transactionLabel } = {} } =
      useTransactionsEppContextConsumerHook(),
    {
      status,
      creditDetails: { availableAmount },
    } = currentCard || {};

  const isCreditInsufficient = hasInsufficientCredit({
    availableAmount,
    transactionLabel,
    totalAmount: getAbsoluteTransactionTotalAmount(amounts),
  });

  return {
    cardEnabled: cardActive(status),
    disabled:
      eppPlanInstallmentStep1FooterIsDisabled({ eppPlanSimulation, status }) ||
      isCreditInsufficient,
    isCreditInsufficient,
    navigate,
  };
};

export { useTransactionsEppStep1Hook };
