// Enumerations
import { CardControlTabsIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-tabs-ids.enumeration';
// Types
import { SettingsCardControlsBuilderHandlersType } from './types/settings-card-controls-builder.handlers.type';
import { SettingsCardControlsBuilderReturnHandlersType } from './types/settings-card-controls-builder-return.handlers.type';
import { SettingsCardControlsMethodBuilderHandlersType } from './types/settings-card-controls-method-builder.handlers.type';

const changeCardControlTabClickTrackingHandler = ({
  handleChangeCardControlTabClickTracking,
  id,
  setTabSelectedId,
}: SettingsCardControlsMethodBuilderHandlersType): any => {
  handleChangeCardControlTabClickTracking(id);
  setTabSelectedId(id);
};

const SettingsCardControlsHandlers = (
  props: SettingsCardControlsBuilderHandlersType
): SettingsCardControlsBuilderReturnHandlersType => ({
  handleChangeCardControlTabClick: (id: CardControlTabsIdsEnumeration) =>
    changeCardControlTabClickTrackingHandler({ ...props, id }),
});

export default SettingsCardControlsHandlers;
