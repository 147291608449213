// Vendors
import { apiUtilsCb, cookies } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/logout.services.constants';
// Enumerations
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';

const logoutService = async (): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...constants.LOGOUT_SERVICE_CONFIGURATION,
    params: {
      [StorageCookiesEnum.ACCESS_TOKEN]: cookies.getCookie(StorageCookiesEnum.ACCESS_TOKEN),
    },
  });

export { logoutService };
