// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import constants from './constants/card-settings-activate-card-failed.handlers.constants';
// Types
import { CardSettingsActivateCardFailedReturnTrackingHandlersType } from './types/card-settings-activate-card-failed-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const retryLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: constants.RETRY_LINK_CLICK_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const CardSettingsActivateCardFailedTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsActivateCardFailedReturnTrackingHandlersType => ({
  handleRetryLinkClickTracking: () => track(retryLinkClickTrackingHandler()),
});

export default CardSettingsActivateCardFailedTrackingHandlers;
