// Vendors
import React from 'react';
// Components
import { AssetComponent, NavigationFooterComponent, TitleComponent } from '@openbank/cb-ui-commons';
import WizardFeedbackHostDescriptionComponent from './components/description/wizard-feedback-host-description.component';
import WizardFeedbackHostContentComponent from './components/content/wizard-feedback-host-content.component';
import { FormattedMessage } from 'react-intl';
// Constants
import {
  WIZARD_FEEDBACK_HOST_ASSET_PROPS,
  WIZARD_FEEDBACK_HOST_BUTTON_PROPS,
  WIZARD_FEEDBACK_HOST_LAYOUT_PROPS,
  WIZARD_FEEDBACK_HOST_LEFT_LINK_PROPS,
  WIZARD_FEEDBACK_HOST_RIGHT_LINK_PROPS,
  WIZARD_FEEDBACK_HOST_TITLE_PROPS,
} from './constants/wizard-feedback-host.constants';
// Hooks
import WizardFeedbackHook from './hooks/wizard-feedback-host.hook';
// Styles
import {
  AppCardHorizontalSectionComponentStyled,
  AppCardSectionComponentStyled,
} from 'styles/app.styled';
import {
  WizardFeedbackHostFooterComponentStyled,
  WizardFeedbackHostHeaderComponentStyled,
  WizardFeedbackHostHeaderRightComponentStyled,
  WizardFeedbackHostHeaderTitleComponentStyled,
} from './wizard-feedback-host.component.styled';
// Types
import { WizardFeedbackHostComponentType } from './types/wizard-feedback-host.component.type';

const WizardFeedbackHostComponent = ({
  assetId: id,
  assetColor: fill,
  leftLinkConfiguration,
  color,
  content,
  description,
  descriptionValues,
  rightButtonConfiguration,
  rightLinkConfiguration,
  title,
  titleValues,
  type,
}: WizardFeedbackHostComponentType): React.ReactElement<WizardFeedbackHostComponentType> => {
  WizardFeedbackHook({ type });

  return (
    <AppCardSectionComponentStyled>
      <WizardFeedbackHostHeaderComponentStyled {...{ ...WIZARD_FEEDBACK_HOST_LAYOUT_PROPS, color }}>
        <AssetComponent {...{ ...WIZARD_FEEDBACK_HOST_ASSET_PROPS, id }} colors={{ fill }} />
        <WizardFeedbackHostHeaderRightComponentStyled>
          <WizardFeedbackHostHeaderTitleComponentStyled>
            <TitleComponent {...WIZARD_FEEDBACK_HOST_TITLE_PROPS}>
              <FormattedMessage id={title} values={titleValues} />
            </TitleComponent>
          </WizardFeedbackHostHeaderTitleComponentStyled>
          <WizardFeedbackHostDescriptionComponent {...{ description, descriptionValues }} />
        </WizardFeedbackHostHeaderRightComponentStyled>
      </WizardFeedbackHostHeaderComponentStyled>
      <WizardFeedbackHostContentComponent {...{ content }} />
      <AppCardHorizontalSectionComponentStyled>
        <WizardFeedbackHostFooterComponentStyled>
          <NavigationFooterComponent
            leftLinkConfiguration={{
              ...WIZARD_FEEDBACK_HOST_LEFT_LINK_PROPS,
              ...leftLinkConfiguration,
            }}
            rightButtonConfiguration={{
              ...WIZARD_FEEDBACK_HOST_BUTTON_PROPS,
              ...rightButtonConfiguration,
            }}
            rightLinkConfiguration={{
              ...WIZARD_FEEDBACK_HOST_RIGHT_LINK_PROPS,
              ...rightLinkConfiguration,
            }}
          />
        </WizardFeedbackHostFooterComponentStyled>
      </AppCardHorizontalSectionComponentStyled>
    </AppCardSectionComponentStyled>
  );
};

export default WizardFeedbackHostComponent;
