// Types
import { IsUploadDocumentRejectedPropsType } from './types/upload-documents-section.util.type';

const isUploadDocumentRejected = ({
  uploadDocumentId,
  rejectedDocumentsMap,
}: IsUploadDocumentRejectedPropsType): boolean => {
  if (!rejectedDocumentsMap) return false;
  return rejectedDocumentsMap[uploadDocumentId] ?? false;
};

export { isUploadDocumentRejected };
