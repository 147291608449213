// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardSpentBottomInnerComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    padding-top: ${({ theme }) => theme.spaces.spacingS};
  }
`;