import { AMAZON_DOMAIN } from './customers.constants';

export default {
  AMAZON_DOMAIN,
  DEMO_ENVIRONMENTS: ['demo', 'demo-cc'],
  DEMO_URL: `https://customer.demo.cb.${AMAZON_DOMAIN}`,
  DEV_ENVIRONMENTS: ['dev', 'dev-cc'],
  DEV_URL: `https://customer.dev.cb.${AMAZON_DOMAIN}`,
  DICTIONARY: 'dictionary',
  ENVIRONMENTS: ['dev', 'stg', 'qa', 'dev-cc', 'qa-cc', 'stg-cc', 'demo', 'demo-cc'],
  HOST_NAME: '127.0.0.1',
  IS_CC: false,
  LOCALHOST_REGEX: /localhost/,
  LOCALHOST_URL: 'http://localhost:8080',
  PROD_URL: `https://customer.${AMAZON_DOMAIN}`,
  SESSION_ID: 'sessionId',
  STG_ENVIRONMENTS: ['stg', 'stg-cc'],
  STG_URL: `https://customer.stg.cb.${AMAZON_DOMAIN}`,
  AMAZON_PUBLIC_SITE_FAQS: 'https://www.zinia.de/amazon/hilfe/',
};