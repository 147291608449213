// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_SIDEBAR_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_SIDEBAR_PROPS,
} from './constants/financing-ppi-contracting-sidebar.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { DESCRIPTION, TITLE } from './translations/financing-ppi-contracting-sidebar.translations';

const FinancingPPIContractingSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...FINANCING_PPI_CONTRACTING_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <ParagraphComponent {...FINANCING_PPI_CONTRACTING_SIDEBAR_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
  </SidebarComponent>
);

export { FinancingPPIContractingSidebarComponent };
