const DOMAIN_CARD_STATEMENTS_SIDEBAR =
  'cobranded.services.userSettings.privateArea.cardSettings.cardContract.cardStatements.sidebar.';
const DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS =
  'cobranded.services.userSettings.privateArea.cardSettings.cardStatements.sidebar.rows.';

const CARD_STATEMENTS_SIDEBAR_ROW_TITLE_01 = `${DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS}first.title`,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_01 = `${DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS}first.content`,
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_02 = `${DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS}second.title`,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_02 = `${DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS}second.content`,
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_03 = `${DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS}third.title`,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_03 = `${DOMAIN_CARD_STATEMENTS_SIDEBAR_ROWS}third.content`,
  CARD_STATEMENTS_SIDEBAR_TITLE = `${DOMAIN_CARD_STATEMENTS_SIDEBAR}title`;

export {
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_01,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_01,
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_02,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_02,
  CARD_STATEMENTS_SIDEBAR_ROW_TITLE_03,
  CARD_STATEMENTS_SIDEBAR_ROW_CONTENT_03,
  CARD_STATEMENTS_SIDEBAR_TITLE,
};
