// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/dashboard-spent-summary-inner.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Types
import { DashboardSpentSummaryInnerComponentType } from './types/dashboard-spent-summary-inner.component.type';
// Utils
import { getFormattedPercentage, percentageDifferencePositive } from './utils/dashboard-spent-summary-inner.component.utils';

const DashboardSpentInnerSummaryComponent = ({
  additionalText,
  percentageDifference,
  title,
}: DashboardSpentSummaryInnerComponentType): React.ReactElement => (
  <>
    <ParagraphComponent {...constants.DASHBOARD_SPENT_SUMMARY_SECONDARY_TITLE_PROPS}>
      <FormattedMessageComponent id={title} />
    </ParagraphComponent>
    <ParagraphSecondaryComponentStyled>
      <ParagraphComponent {...constants.DASHBOARD_SPENT_SUMMARY_SECONDARY_ADDITIONAL_PROPS}>
        {percentageDifferencePositive(percentageDifference) ? (
          <FormattedMessageComponent
            id={additionalText}
            values={{
              percentage: getFormattedPercentage(percentageDifference),
            }}
          />
        ) : null}
      </ParagraphComponent>
    </ParagraphSecondaryComponentStyled>
  </>
);

export default DashboardSpentInnerSummaryComponent;
