// Constants
import {
  IVR_INTERVAL_TIME,
  IVR_SUCCESS_RESULT,
  IVR_TIME_OUT,
} from './constants/ivr-component-wrapper-cc.handlers.constants.cc';
// Types
import { IvrComponentWrapperCCHandlersReturnHandlersType } from './types/ivr-component-wrapper-cc.handlers.return.type';
import { IvrComponentWrapperCCHandlersHandlersType } from './types/ivr-component-wrapper-cc.handlers.type';

const InitiaterIvr = ({
  setResult,
  setIsLoadingIvr,
  initiatorIvr,
  resultIvr,
}: IvrComponentWrapperCCHandlersHandlersType): Promise<boolean | undefined> => {
  setIsLoadingIvr(true);
  setResult(undefined);

  return new Promise(resolve => {
    setTimeout(() => {
      initiatorIvr();
      const startTime = Date.now();
      const intervalId = setInterval(() => {
        if (resultIvr()) {
          const IVRResult = resultIvr();
          clearInterval(intervalId);
          setIsLoadingIvr(false);
          setResult(IVRResult === IVR_SUCCESS_RESULT);
          resolve(IVRResult === IVR_SUCCESS_RESULT);
        } else if (Date.now() - startTime > IVR_TIME_OUT) {
          clearInterval(intervalId);
          setIsLoadingIvr(false);
          setResult(false);
          resolve(false);
        }
      }, IVR_INTERVAL_TIME);
    }, 2000);
  });
};

const IvrComponentWrapperCCHandlers = ({
  setResult,
  setIsLoadingIvr,
  initiatorIvr,
  resultIvr,
}: IvrComponentWrapperCCHandlersHandlersType): IvrComponentWrapperCCHandlersReturnHandlersType => ({
  handleInitiatorIvr: () => InitiaterIvr({ setResult, setIsLoadingIvr, initiatorIvr, resultIvr }),
});

export default IvrComponentWrapperCCHandlers;
