// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  BACK_BUTTON_PAYLOAD,
  CLOSE_BUTTON_PAYLOAD,
} from './constants/card-settings-block-card-replacement-modal.handlers.tracking.constants';
// Types
import { CardSettingsBlockCardReplacementModalReturnHandlersTrackingType } from './types/card-settings-block-card-replacement-modal-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const closeButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CLOSE_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const backButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: BACK_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const CardSettingsBlockCardReplacementModalTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsBlockCardReplacementModalReturnHandlersTrackingType => ({
  handleCloseButtonClickTracking: () => track(closeButtonClickTrackingHandler()),
  handleBackButtonClickTracking: () => track(backButtonClickTrackingHandler()),
});

export default CardSettingsBlockCardReplacementModalTrackingHandlers;
