export default {
  PROFILE_PERSONAL_NAME_ALIAS_INPUT_INPUT_PROPS: {
    isRequired: true,
    testId: 'profile-personal-name-button-alias-new-new',
    testing: {
      inputId: 'profile-personal-name-button-alias-new-new',
      helperId: 'profile-personal-name-button-alias-new-helper',
      labelId: 'profile-personal-name-button-alias-new-label',
    },
  },
  PROFILE_PERSONAL_NAME_ALIAS_INPUT_LIMIT_PROPS: {
    limit: 20,
  },
  PROFILE_PERSONAL_NAME_ALIAS_INPUT_LINK_PROPS: {
    testId: 'profile-personal-name-button-alias-new-links',
  },
};
