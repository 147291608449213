export default {
  SLIDER_PROPS: {
    test_id: 'change-credit-limit-slider',
    inputType: 'currency',
    labelProps: { size: 'L' },
    key: 'change-credit-limit-slider',
  },
  CREDIT_LIMIT_CONFIG: {
    STEP: 500,
    MIN: 2000,
    MAX: 40000,
  },
  PERSONAL_EMAIL_STEP_1_NEW_INPUT_PROPS: {
    testing: {
      helperId: 'personal-email-step1-new-input-helper',
      inputId: 'personal-email-step1-new-input',
      labelId: 'personal-email-step1-new-input-label',
    },
    showIcon: false,
  },
};
