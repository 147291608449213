const TRANSACTIONS_REPORT_CONTEXT_DEFAULT_VALUE = {
  caseId: undefined,
  challenge: undefined,
  challengeError: false,
  error: false,
  fetching: true,
  setCaseId: (): null => null,
  setChallenge: (): undefined => undefined,
  setChallengeError: (): null => null,
  setError: (): null => null,
  setFetching: (): null => null,
  setTransactionDetails: (): null => null,
  transactionDetails: null,
};

export { TRANSACTIONS_REPORT_CONTEXT_DEFAULT_VALUE };
