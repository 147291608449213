// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FINANCING_CONTRACT_PPI_SERVICE_CONFIGURATION } from './constants/financing-ppi-contracting-step3.service.constants';
// Types
import { FinancingPPIContractingStep3ApiBuilderType } from '../types/financing-ppi-contracting-step3-api-builder.type';

const contractPPIService = async ({
  progressId,
  ...params
}: FinancingPPIContractingStep3ApiBuilderType): Promise<[Record<string, never>, Response]> =>
  apiUtilsCb.post({
    ...FINANCING_CONTRACT_PPI_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    params,
  });

export { contractPPIService };
