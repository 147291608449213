// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { RadioButtonComponent } from '@openbank/cb-ui-commons';
import { TransactionsEppStep1SimulationOptionsAllSliderComponent } from './components/slider/transactions-epp-step1-simulation-options-all-slider.component';
// Constants
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_ALL_RADIO_PROPS } from './constants/transactions-epp-step1-simulation-options-all.constants';
// Hooks
import { useTransactionsEppStep1SimulationOptionsAllHook } from './hooks/transactions-epp-step1-simulation-options-all.hook';
// Translations
import { ALL_OPTIONS } from './translations/transactions-epp-step1-simulation-options-all.translations';
// Types
import { TransactionsEppStep1SimulationOptionsAllComponentType } from './types/transactions-epp-step1-simulation-options-all.component.type';
// Utilities
import { allOptionRadioButtonIsDisabled } from './utils/transactions-epp-step1-simulation-options-all.utils';

const TransactionsEppStep1SimulationOptionsAllComponent = ({
  fetching,
  ...rest
}: TransactionsEppStep1SimulationOptionsAllComponentType): React.ReactElement | null => {
  const {
    handleChangeAllOptionsRadioButtonChangeEvent,
    isAllOptionsSelected,
    minNumOfInstallments,
  } = useTransactionsEppStep1SimulationOptionsAllHook();

  return (
    <>
      <RadioButtonComponent
        {...TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_ALL_RADIO_PROPS}
        disabled={allOptionRadioButtonIsDisabled({ fetching, minNumOfInstallments })}
        onChange={handleChangeAllOptionsRadioButtonChangeEvent}
        title={<FormattedMessageComponent id={ALL_OPTIONS} />}
        value={isAllOptionsSelected}
      />
      <TransactionsEppStep1SimulationOptionsAllSliderComponent {...{ ...rest, fetching }} />
    </>
  );
};

export { TransactionsEppStep1SimulationOptionsAllComponent };
