// Translations
import translations from 'containers/transactions/views/landing/translations/transactions-landing.translations';

const MODAL_FILTER_DEFAULT_PROPS = {
  primaryButtonConfiguration: {
    children: 'Apply',
    type: 'primary',
    testId: 'filter-modal-apply-button',
  },
  linkConfiguration: {
    children: 'Cancel',
    testId: 'filter-modal-cancel-link',
  },
  modalContentConfiguration: [{ id: 'example', component: 'Example Component' }],
};

const CALCULATED_MESSAGE_PROPS = {
  Empty: '',
};

const CATEGORY_OPTIONS = [
  {
    assetConfiguration: {
      id: 'all-categories-colored',
    },
    label: translations.CATEGORIES_OPTION_ALL,
    value: 'All',
  },
  {
    assetConfiguration: {
      id: 'atms',
    },
    label: translations.CATEGORIES_OPTION_ATM,
    value: '1',
  },
  {
    assetConfiguration: {
      id: 'house-and-home',
    },
    label: translations.CATEGORIES_OPTION_HOUSE_AND_HOME,
    value: '2',
  },
  {
    assetConfiguration: {
      id: 'retail-stores',
    },
    label: translations.CATEGORIES_OPTION_RETAIL_STORES,
    value: '3',
  },
  {
    assetConfiguration: {
      id: 'education-health-beauty-wellness',
    },
    label: translations.CATEGORIES_OPTION_EDUCATION_HEALTH_BEAUTY_WELNESS,
    value: '4',
  },
  {
    assetConfiguration: {
      id: 'income',
    },
    label: translations.CATEGORIES_OPTION_INCOME,
    value: '5',
  },
  {
    assetConfiguration: {
      id: 'investments-savings',
    },
    label: translations.CATEGORIES_OPTION_INVESTMENTS_SAVINGS,
    value: '6',
  },
  {
    assetConfiguration: {
      id: 'exclusive-movements',
    },
    label: translations.CATEGORIES_OPTION_EXCLUSIVE_MOVEMENTS,
    value: '7',
  },
  {
    assetConfiguration: {
      id: 'leisure-travel',
    },
    label: translations.CATEGORIES_OPTION_LEIUSRE_TRAVEL,
    value: '8',
  },
  {
    assetConfiguration: {
      id: 'other-expenses',
    },
    label: translations.CATEGORIES_OPTION_OTHER_EXPENSES,
    value: '9',
  },
  {
    assetConfiguration: {
      id: 'restaurants',
    },
    label: translations.CATEGORIES_OPTION_RESTAURANTS,
    value: '10',
  },
  {
    assetConfiguration: {
      id: 'insurance-organizations-ongs',
    },
    label: translations.CATEGORIES_OPTION_INSURANCE_ORGANIZATIONS_ONGS,
    value: '11',
  },
  {
    assetConfiguration: {
      id: 'transfers-bank-charges-loans',
    },
    label: translations.CATEGORIES_OPTION_TRANSFERS_BANK_CHANGES_LOAN,
    value: '12',
  },
  {
    assetConfiguration: {
      id: 'transport-automove',
    },
    label: translations.CATEGORIES_OPTION_TRANSPORT_AUTOMOVE,
    value: '13',
  },
  {
    assetConfiguration: {
      id: 'professional-expenses',
    },
    label: translations.CATEGORIES_OPTION_PROFESSIONAL_EXPENSES,
    value: '14',
  },
];

const MOVEMENTS_OPTIONS = [
  {
    id: 'All',
    helperMessage: translations.MOVEMENTS_OPTION_ALL,
  },
  {
    id: 'INCOME',
    helperMessage: translations.MOVEMENTS_OPTION_INCOME,
  },
  {
    id: 'SPENDING',
    helperMessage: translations.MOVEMENTS_OPTION_EXPENSES,
  },
];

const SITUATION_OPTIONS = [
  {
    id: 'All',
    helperMessage: translations.SITUATION_OPTION_ALL,
  },
  {
    id: 'Authorized',
    helperMessage: translations.SITUATION_OPTION_AUTHORISED,
  },
];

const STATUS_OPTIONS = [
  {
    id: 'All',
    helperMessage: translations.STATUS_OPTION_ALL,
  },
  {
    id: 'CONVERTED_TO_EPP',
    helperMessage: translations.STATUS_OPTION_FINANCED,
  },
  {
    id: 'ELIGIBLE_FOR_EPP',
    helperMessage: translations.STATUS_OPTION_INSTALMENT,
  },
];

const EMPTY_FILTER_FIELD_VALUES = {
  value: '',
  label: '',
};

export {
  CALCULATED_MESSAGE_PROPS,
  CATEGORY_OPTIONS,
  EMPTY_FILTER_FIELD_VALUES,
  MODAL_FILTER_DEFAULT_PROPS,
  MOVEMENTS_OPTIONS,
  SITUATION_OPTIONS,
  STATUS_OPTIONS,
};
