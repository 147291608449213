// Vendors
import { useState } from 'react';
// Enums
import { DeviceModalEnum } from '../../device-modal/enumerations/device-modal.enumeration';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { DeviceInfoHandlers } from '../handlers/device-info.handlers';
import { DeviceInfoTrackingHandlers } from '../handlers/device-info.tracking.handlers';
// Types
import { DeviceInfoHookPropsType } from './type/device-info.hook.props.type';
import { DeviceInfoHookReturnType } from './type/device-info.hook.return.type';
// Contexts
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Translations
import { UNBLOCK_DEVICE, BLOCK_DEVICE } from '../translations/device-info.translations';
import AppTrackingHook from 'tracking/app.tracking.hook';

const DeviceInfoHook = ({
  device
}: DeviceInfoHookPropsType): DeviceInfoHookReturnType => {
  const { formatMessage } = useCbIntl();
  const { setTrusted } = SignatureKeyDevicesContextConsumerHook();
  const { setFetching, setDevices, setBlockedDevices, setError, loading, setLoading } = SignatureKeyDevicesContextConsumerHook();
  const { setToastConfiguration } = ToastContextConsumerHook();
  const [ open, setOpen ] = useState(false);
  const [ modalType, setModalType ] = useState<DeviceModalEnum>(DeviceModalEnum.BLOCK);

  const {
    openBlockToggleModal,
    openUnrollModal,
    onAccepModal
  } = DeviceInfoHandlers({
    ...AppTrackingHook(DeviceInfoTrackingHandlers),
    device,
    setOpen,
    setModalType,
    modalType,
    setToastConfiguration,
    setFetching,
    setLoading,
    setDevices,
    setBlockedDevices,
    setError,
    setTrusted,
  });

  return {
    loading,
    open,
    modalType,
    onCloseModal: () => { setOpen(false); },
    onAccepModal,
    openBlockToggleModal,
    openUnrollModal,
    formatMessage,
    toggleButtonMessage: device.blocked ? UNBLOCK_DEVICE : BLOCK_DEVICE
  };
};

export { DeviceInfoHook };
