const DOMAIN_ACTIVATE_CARD =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.';
const DOMAIN_ACTIVATE_CARD_ERROR =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.error.';
const DOMAIN_ACTIVATE_CARD_SUCCESS =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.success.';
const DOMAIN_ACTIVATE_CARD_SUCCESS_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.success.buttons.';
const DOMAIN_ACTIVATE_CARD_SUCCESS_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.success.confirmation.';
const DOMAIN_ACTIVATE_CARD_FAILED_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.failed.buttons.';
const DOMAIN_ACTIVATE_CARD_FAILED_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.cardSettings.activateCard.failed.confirmation.';

export default {
  TITLE: `${DOMAIN_ACTIVATE_CARD}title`,
  ERROR: {
    ERROR_TITLE: `${DOMAIN_ACTIVATE_CARD_ERROR}title`,
    ERROR_DESCRIPTION: `${DOMAIN_ACTIVATE_CARD_ERROR}description`,
  },
  SUCCESS: {
    CONFIRMATION: {
      TITLE: `${DOMAIN_ACTIVATE_CARD_SUCCESS_CONFIRMATION}title`,
      CONTENT: `${DOMAIN_ACTIVATE_CARD_SUCCESS_CONFIRMATION}content`,
    },
    TITLE: `${DOMAIN_ACTIVATE_CARD_SUCCESS}title`,
    BACK_BUTTTON: `${DOMAIN_ACTIVATE_CARD_SUCCESS_BUTTONS}back`,
  },
  FAILED: {
    CONFIRMATION: {
      TITLE: `${DOMAIN_ACTIVATE_CARD_FAILED_CONFIRMATION}title`,
      CONTENT: `${DOMAIN_ACTIVATE_CARD_FAILED_CONFIRMATION}content`,
    },
    TRY_AGAIN_BUTTTON: `${DOMAIN_ACTIVATE_CARD_FAILED_BUTTONS}again`,
  },
};
