// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const VerifyingDetailsIntermediateStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const VerifyingDetailsCreditLimitIncreaseStyled = styled(VerifyingDetailsIntermediateStyled)`
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileMediaQuery} {
    max-width: 100%;
    margin: 0;
    padding: 0;
  }
`;

export const VerifyingDetailsProgressBarStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 328, base: 14 })};

  ${mobileMediaQuery} {
    max-width: 100%;
  }
`;

export const VerifyingDetailsContentStyled = styled(VerifyingDetailsIntermediateStyled)`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const VerifyingDetailsTextsStyled = styled(VerifyingDetailsIntermediateStyled)`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const VerifyingDetailsDescriptionStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;
