// Constants
import translations from '../translations/security-keys-signature-key.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export default {
  SECURITY_KEYS_SIGNATURE_KEYS_PROPS: {
    description: translations.DESCRIPTION,
    link: translations.LINK,
    testing: {
      descriptionId: 'security-keys-signature-key-table-row-description',
      linkId: 'security-keys-signature-key-links',
      titleId: 'security-keys-signature-key-title',
    },
    title: translations.TITLE,
    url: RoutesEnumeration.SIGNATURE_KEY,
  },
};
