// Vendors
import React from 'react';
// Components
import { LinkComponent, paragraphConstants } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_PROPS,
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_SIZES,
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_TEST_ID,
} from './constants/financing-ppi-contracting-description-link-link.constants';
// Hooks
import { useFinancingPPIContractingDescriptionLinkLinkHook } from './hooks/financing-ppi-contracting-description-link-link.hook';
// Types
import { FinancingPPIContractingStep1DescriptionLinkLinkType } from './types/financing-ppi-contracting-step1-description-link-link.type';

const FinancingPPIContractingDescriptionLinkLinkComponent = ({
  chunks,
  size = paragraphConstants.SIZES.M,
  testId,
  url,
}: FinancingPPIContractingStep1DescriptionLinkLinkType): React.ReactElement | null => {
  const { handleOpenExternalUrlPPIContractingLinkClick } =
    useFinancingPPIContractingDescriptionLinkLinkHook(url);

  return (
    <LinkComponent
      {...{ ...FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_PROPS }}
      size={FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_SIZES[size]}
      onClick={handleOpenExternalUrlPPIContractingLinkClick}
      testId={FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_TEST_ID + testId}
    >
      {chunks}
    </LinkComponent>
  );
};

export { FinancingPPIContractingDescriptionLinkLinkComponent };
