// Vendors
import React, { createContext, useContext, useState } from 'react';
// Constants
import constants from './constants/support-cancel-contract.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SupportCancelContractContextType } from './types/support-cancel-contract.context.type';

const SupportCancelContractContext = createContext<SupportCancelContractContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const SupportCancelContractContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<SupportCancelContractContextType>> => {
  const [reason, setReason] = useState<string>('');
  const [progressId, setProgressId] = useState<string>('');
  const [isDisabledLastStep, setIsDisabledLastStep] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <SupportCancelContractContext.Provider
      value={{
        reason,
        setReason,
        setProgressId,
        progressId,
        setIsDisabledLastStep,
        isDisabledLastStep,
        setShowModal,
        showModal,
      }}
    >
      {children}
    </SupportCancelContractContext.Provider>
  );
};

export const SupportCancelContractContextConsumerHook = (): SupportCancelContractContextType =>
  useContext(SupportCancelContractContext);

/* eslint-disable */
export const SupportCancelContractContextConsumerHOC =
  (Component: React.FunctionComponent): React.FunctionComponent =>
  (props: any): React.ReactElement<React.Provider<SupportCancelContractContextType>> =>
    (
      <SupportCancelContractContextProvider>
        <Component {...props} />
      </SupportCancelContractContextProvider>
    );
