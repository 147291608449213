// Types
import {
  TransactionsTableHeaderLinkBarListLinksBuilderHandlersType,
  TransactionsTableHeaderLinkBarListLinksBuilderReturnHandlersType,
  TransactionsTableHeaderLinkBarListLinksMethodReturnHandlersType,
} from './types/transactions-table-header-link-bar-list-links.type';

const tableHeaderCCLinksLinkClickHandler = ({
  handleTableHeaderCCLinksLinkClickTracking,
  navigate,
  url,
}: TransactionsTableHeaderLinkBarListLinksMethodReturnHandlersType): void => {
  if (url) {
    handleTableHeaderCCLinksLinkClickTracking(url);
    navigate(url);
  }
};

const TransactionsTableHeaderLinkBarListLinksHandlers = (
  props: TransactionsTableHeaderLinkBarListLinksBuilderHandlersType
): TransactionsTableHeaderLinkBarListLinksBuilderReturnHandlersType => ({
  handleTableHeaderCCLinksLinkClick: (url?: string) =>
    tableHeaderCCLinksLinkClickHandler({ ...props, url }),
});

export { TransactionsTableHeaderLinkBarListLinksHandlers };
