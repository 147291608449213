const LOCALIZATION_SERVICE_CONFIGURATION_CUSTOM_HEADERS = {
    customHeaders: {
      Accept: 'application/json',
    },
  },
  LOCALIZATION_SERVICE_CONFIGURATION = {
    endpoint: 'cb-api-localization/v1/public/platform/WEB/configuration',
    ...LOCALIZATION_SERVICE_CONFIGURATION_CUSTOM_HEADERS,
  };

export { LOCALIZATION_SERVICE_CONFIGURATION };
