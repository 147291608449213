// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const TravelPlusLinksComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXs};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;
TravelPlusLinksComponentStyled.displayName = 'TravelPlusLinksComponentStyled';

const TravelPlusLinksComponentDividerStyled = styled.div<{ $length: number; $position: number }>`
  display: ${({ $length, $position }) => ($position >= $length - 1 ? 'none' : 'block')};
  background: ${({ theme }) => theme.colors.backgroundTertiaryD};
  height: ${convertPxsToRems({ pixels: 20, base: 14 })};
  width: ${convertPxsToRems({ pixels: 1, base: 14 })};

  ${mobileMediaQuery} {
    display: none;
  }
`;
TravelPlusLinksComponentDividerStyled.displayName = 'TravelPlusLinksComponentDividerStyled';

export { TravelPlusLinksComponentStyled, TravelPlusLinksComponentDividerStyled };
