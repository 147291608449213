// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  FETCH_POE_CONFIGURATION,
  FETCH_TRANSLATIONS_SERVICE_CONFIGURATION,
  TRANSLATIONS_SERVICE_CUSTOM_HEADERS,
} from './constants/internationalization.services.constants';
import { TRANSLATIONS_PLATFORM } from './constants/internationalization-platforms.services.constants';
// Types
import { PublicConfigurationType } from '../types/public-configuration.type';
import { LanguageServiceResponseType } from '../types/language-service-response.type';

const fetchLanguagesConfigurationService = async (): Promise<
  [LanguageServiceResponseType | Record<string, any>, Response]
> =>
  apiUtilsCb.get({
    ...FETCH_TRANSLATIONS_SERVICE_CONFIGURATION,
    pathParams: { platform: TRANSLATIONS_PLATFORM },
  });

const fetchLanguageService = async (
  absoluteUrl: string
): Promise<[LanguageServiceResponseType | Record<string, any>, Response]> =>
  apiUtilsCb.get({ ...TRANSLATIONS_SERVICE_CUSTOM_HEADERS, absoluteUrl });

const fetchDictionaryConfigurationService = async (): Promise<
  [PublicConfigurationType | Record<string, any>, Response]
> => apiUtilsCb.get(FETCH_POE_CONFIGURATION);

export {
  fetchDictionaryConfigurationService,
  fetchLanguagesConfigurationService,
  fetchLanguageService,
};
