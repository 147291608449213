// Vendors
import React from 'react';
// Components
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { THIRD_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Contexts
import { ReportDefectiveUploadDocumentsHook } from './hooks/report-defective-upload-documents.hook';
// Enumerations
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';
// Utils
import { hasRequiredDocs } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const ReportDefectiveUploadDocumentsComponent = (): React.ReactElement => {
  const { files, setFiles, handleFiles, handleGoBack } = ReportDefectiveUploadDocumentsHook();

  return (
    <>
      <UploadDocumentsTipsComponent />
      <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        hasDefaultLeftButton
        leftButtonConfiguration={{
          onClick: () => handleGoBack(),
        }}
        hasDefaultRightButton
        rightButtonConfiguration={{
          disabled: !hasRequiredDocs({
            files,
            requiredDocType: DocumentsTypesEnumeration.PROOF_OF_CONTACT,
          }),
          onClick: () => handleFiles(),
        }}
        targets={THIRD_STEP_TARGETS}
      />
    </>
  );
};
