// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export default {
  LOGIN_ASIDE_ASSET_PROPS: {
    id: 'amazon_phisical_card_front',
    type: assetConstants.TYPES.ILLUSTRATION,
    extension: 'png',
    sizes: {
      height: 328,
      width: 206.5,
    },
    testId: 'login-aside-asset',
  },
  LOGIN_VERIFY_NOW_PROD_URL: 'https://customer.amazon.zinia.de/stc/onboarding/verify-now',
};
//'https://www.amazon.zinia.de/stc/onboarding/verify-now',
