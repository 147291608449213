// Vendors
import styled from 'styled-components';

export const PersonalAddressStep2SummaryComponentStyled = styled.div`
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const PersonalAddressStep2SummaryScriptContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
