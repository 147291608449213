// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DashboardTopMessageContainerComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    padding: ${({ theme }) =>
      `${theme.spaces.spacingS} ${theme.spaces.spacingS} 0 ${theme.spaces.spacingS}`};
  }
`;

export const DashboardTopMessageInnerWrapperComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export const DashboardTopMessageWrapperComponentStyled = styled(
  DashboardTopMessageInnerWrapperComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
