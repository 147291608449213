// Vendors
import styled from 'styled-components';

export const SettingsCardControlLocationsContinentsComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
`;
