// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import { TransactionsTableHeaderLinkBarListLinksHandlers } from '../handlers/transactions-table-header-link-bar-list-links.handlers';
import { TransactionsTableHeaderLinkBarListLinksTrackingHandlers } from '../handlers/transactions-table-header-link-bar-list-links.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsTableHeaderLinkBarListLinksBuilderReturnHandlersType } from '../handlers/types/transactions-table-header-link-bar-list-links.type';

const useTransactionsTableHeaderLinkBarListLinksHook =
  (): TransactionsTableHeaderLinkBarListLinksBuilderReturnHandlersType =>
    TransactionsTableHeaderLinkBarListLinksHandlers({
      ...AppTrackingHook(TransactionsTableHeaderLinkBarListLinksTrackingHandlers),
      navigate: useNavigate(),
    });

export { useTransactionsTableHeaderLinkBarListLinksHook };
