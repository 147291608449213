// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import CustomerIdHook from './hooks/customer-id.hook';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const CustomerIdProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { fetching } = CustomerIdHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent {...{ fetching }}>{children}</FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export default CustomerIdProvider;
