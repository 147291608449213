// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const FinancingDepositMoneyContentStyled = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-grow: 0;
  gap: ${({ theme }) => theme.spaces.spacingM};
  justify-content: center;
  padding: 0;

  ${mobileMediaQuery} {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: center;
  }

  ${tabletMediaQuery} {
    align-items: stretch;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: flex-start;
    padding: 0 ${({ theme }) => theme.spaces.spacingXl};
  }
`;
export const FinancingDepositMoneyLeftContentStyled = styled.div`
  align-items: stretch;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) =>
    `${theme.spaces.spacingM} ${theme.spaces.spacingXxxxxl} ${theme.spaces.spacingXxl}`};

  ${mobileMediaQuery} {
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
