// Vendors
import React from 'react';
// Components
import LabelsLinkCardComponent from 'components/labels-link-card/labels-link-card.component';
// Constants
import { SECURITY_KEYS_3D_SECURITY_PROPS } from './constants/3d-security.constants';

const SecurityKeys3DSecurityComponent = (): React.ReactElement => {
  return (
    <LabelsLinkCardComponent
      { ...SECURITY_KEYS_3D_SECURITY_PROPS }
    />
  );
};

export { SecurityKeys3DSecurityComponent };
