// Constants
import { messageConstants, titleConstants } from '@openbank/cb-ui-commons';

export default {
  PERSONAL_NUMBER_STEP_4_MESSAGE_PROPS: {
    testId: 'personal-number-step4-message',
    type: messageConstants.TYPES.WARNING,
  },
  PERSONAL_NUMBER_STEP_4_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'personal-number-step4-message-table-row-description',
  },
  PERSONAL_NUMBER_STEP_4_DESCRIPTION_PROPS: {
    testId: 'personal-number-step4-table-row-description',
  },
  PERSONAL_NUMBER_STEP_4_OTP_CURRENT_PHONE_LABEL: {
    bold: true,
    tag: titleConstants.TAGS.H7,
    testId: 'personal-number-step4-otp-current-phone-label-title',
  },
  PERSONAL_NUMBER_STEP_4_OTP_CURRENT_PHONE_VALUE: {
    testId: 'personal-number-step4-otp-current-phone-label-value',
  },
  PERSONAL_NUMBER_STEP_4_OTP_CURRENT_EMAIL_LABEL: {
    bold: true,
    tag: titleConstants.TAGS.H7,
    testId: 'personal-number-step4-otp-current-email-label-title',
  },
  PERSONAL_NUMBER_STEP_4_OTP_CURRENT_EMAIL_VALUE: {
    testId: 'personal-number-step4-otp-current-email-label-value',
  },
  PERSONAL_NUMBER_STEP_4_OTP_NEW_PHONE_LABEL: {
    bold: true,
    tag: titleConstants.TAGS.H7,
    testId: 'personal-number-step4-otp-new-phone-label-title',
  },
  PERSONAL_NUMBER_STEP_4_OTP_NEW_PHONE_VALUE: {
    testId: 'personal-number-step4-otp-new-phone-label-value',
  },
};
