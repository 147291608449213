// Services
import { generateNewNumberOtpService } from '../services/personal-number-step2.services';
// Types
import { PersonalNumberStep2GenerateApiBuilderType } from '../types/personal-number-step2-generate-api-builder.type';

const generateNewNumberOtpResolver = async (
  params: PersonalNumberStep2GenerateApiBuilderType
): Promise<[string] | [null, true]> => {
  const [response] = await generateNewNumberOtpService(params);

  return response?.otpId ? [response.otpId] : [null, true];
};

export { generateNewNumberOtpResolver };
