// Types
import type {
  ErrorHandlersMethodsType,
  ErrorHandlersBuilderType,
} from './types/error.handlers.type';
// Utilities
import { clearSessionData } from 'utils/session/session.utils';

const resetAppAndReloadHandler = ({
  isHybrid,
  onNavigateHybridFlow,
}: ErrorHandlersBuilderType): void => {
  clearSessionData();
  isHybrid ? onNavigateHybridFlow() : window.location.reload();
};

const ErrorHandlers = (props: ErrorHandlersBuilderType): ErrorHandlersMethodsType => ({
  handleResetAppAndReload: () => resetAppAndReloadHandler(props),
});

export { ErrorHandlers };
