// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
import { PersonalAddressSidebarComponent } from './components/sidebar/personal-address-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
// Constants
import constants from './constants/personal-address.constants';
// Contexts
import { PersonalAddressContextConsumerHOC } from './contexts/personal-address.context';
// Hooks
import { usePersonalAddressHook } from './hooks/personal-address.hook';

const PersonalAddress = (): React.ReactElement => {
  const { error } = usePersonalAddressHook();

  return (
    <ErrorWrapperComponent {...{ error }}>
      <OperativeLayoutComponent sidebarComponent={PersonalAddressSidebarComponent}>
        <WizardComponent {...constants.PERSONAL_ADDRESS_WIZARD_STEPS} />
      </OperativeLayoutComponent>
    </ErrorWrapperComponent>
  );
};

export { PersonalAddress };

export default PersonalAddressContextConsumerHOC(PersonalAddress);
