// Vendors
import styled from 'styled-components';

export const ChangeIbanStep1MessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const ChangeIbanStep1MessageColumnStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const ChangeIbanStep1MessageColumnLinkStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export const ChangeIbanStep1MessageRowStyled = styled.div`
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
