// Types
import { PlatformHandlersType } from './types/platform-return.handlers.type';
import { PlatformHandlersReturnType } from './types/platform.handlers.type.return';

const openTooltipHandler = ({ onOpenTooltipTracking, setTooltipOpened, tooltipOpened }) => {
  if(!tooltipOpened) {
    onOpenTooltipTracking();
    setTooltipOpened(true);
  }
};

const PlatformHandlers = (
  props: PlatformHandlersType
  ): PlatformHandlersReturnType => ({
  onOpenTooltip: () => openTooltipHandler(props)
});

export { PlatformHandlers };