const DOMAIN = 'cobranded.services.userSettings.privateArea.ibanBilling.ibanBillingLanding.cbCard.';

export default {
  BILLING_CARD_TITLE: `${DOMAIN}cbCard.billingTitle`,
  BILLING_CARD_LINK: `${DOMAIN}cbCard.billingLink`,
  BILLING_CARD_DESCRIPTION: `${DOMAIN}cbCard.billingDescription`,
  IBAN_CARD_TITLE: `${DOMAIN}cbCard.ibanTitle`,
  IBAN_CARD_LINK: `${DOMAIN}ibanLink`,
  IBAN_CARD_DESCRIPTION: `${DOMAIN}ibanDescription`,
  IBAN_CARD_DATE: `${DOMAIN}billingDate`,
};
