// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const PersonalEmailStep4ChallengeComponentStyled = styled.section`
  padding-top: ${({ theme }) => theme.spaces.spacingXl};

  ${mobileMediaQuery} {
    padding-top: ${({ theme }) => theme.spaces.spacingM};
  }
`;
