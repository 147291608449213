// Vendors
import styled from 'styled-components';

export const DashboardSpentBottomComponentStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  width: 100%;
`;

export const DashboardSpentBottomComponenInnerWrapperStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    'data-testid': testId,
  })
)`
  margin-top: ${({ theme }) => theme.spaces.spacingS};
`;
