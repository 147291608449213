// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Types
import { ReportSubscriptionAlreadyCancelledDocumentsActionsComponentType } from './types/report-subscription-already-cancelled-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from './utils/report-subscription-already-cancelled-documents-actions.utils';
import { useReportSubscriptionAlreadyCancelledDocumentsActionsHook } from './hooks/report-subscription-already-cancelled-documents-actions.hook';

export const ReportSubscriptionAlreadyCancelledDocumentsActionsComponent = ({
  files,
  setFiles,
}: ReportSubscriptionAlreadyCancelledDocumentsActionsComponentType): React.ReactElement => {
  const { handleGoBack } = useReportSubscriptionAlreadyCancelledDocumentsActionsHook(setFiles);
  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      hasDefaultLeftButton
      leftButtonConfiguration={{
        onClick: () => handleGoBack(),
      }}
      rightButtonConfiguration={{ disabled: !hasRequiredDocs(files) }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};
