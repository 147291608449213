// Vendors
import {
  MobileCommunicator,
  MobileHandler,
  AndroidDeviceConnector,
  IOSDeviceConnector,
  isIosWebView,
  isAndroidWebView,
  IMobileCommunicator,
} from '@openbank/web-ui-framework';

export let mobileCommunicator: IMobileCommunicator;

async function initCommunicator(deviceConnector) {
  if (deviceConnector) {
    const mobileHandler = new MobileHandler(deviceConnector);
    mobileCommunicator = new MobileCommunicator(deviceConnector, mobileHandler);

    await mobileCommunicator.init();

    return true;
  }

  return false;
}

const initCommon = async (): Promise<boolean> => {
  let deviceConnector;
  if (isAndroidWebView()) {
    deviceConnector = new AndroidDeviceConnector();
  } else if (isIosWebView()) {
    deviceConnector = new IOSDeviceConnector();
  }

  return await initCommunicator(deviceConnector);
};

export const initMobileCommunicator = async (): Promise<boolean> => await initCommon();

export const initMobileCommunicatorWithoutSession = async (): Promise<boolean> => await initCommon();
