// Vendors
import { useEffect, useState } from 'react';
// Context
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
import { AppContextConsumerHook } from 'contexts/app.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Handlers
import { TransactionsTableHeaderLinkBarDownloadModalHandlers } from '../handlers/transactions-table-header-link-bar-download-modal.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalReturnHookType } from './types/transactions-table-header-link-bar-download-modal-return.hook.type';
import { TransactionsTableHeaderLinkBarDownloadModalHookPropsType } from './types/transactions-table-header-link-bar-download-modal.hook.type';
// Utilities
import { hasTransactionsChallengeSuccess } from 'organisms/table-organism/components/transactions-table/utils/transactions-table.utils';

export const useTransactionsTableHeaderLinkBarDownloadModalHook = ({
  challenged,
  downloadDocumentType,
  handleHideSecurityCheckModal,
  handleShowSecurityCheckModal,
  isDownloadAvailable,
  isShowDownloadModal,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setFetchingDownload,
  setIsDownloadAvailable,
  setIsShowDownloadModal,
  showSecurityCheckModal,
}: TransactionsTableHeaderLinkBarDownloadModalHookPropsType): TransactionsTableHeaderLinkBarDownloadModalReturnHookType => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();
  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  const { formValuesParams } = useFiltersContextConsumerHook();
  const { setToastConfiguration } = ToastContextConsumerHook();

  const {
    handleCleanDownloadState,
    handleDownload,
    handleDownloadButtonClick,
    handleOnClickCancel,
  } = TransactionsTableHeaderLinkBarDownloadModalHandlers({
    cardId,
    challenged,
    downloadDocumentType,
    formValuesParams,
    handleHideSecurityCheckModal,
    handleShowSecurityCheckModal,
    progressId,
    setChallenge,
    setChallenged,
    setChallengeError,
    setDownloadDocumentType,
    setFetching,
    setFetchingDownload,
    setIsDownloadAvailable,
    setIsShowDownloadModal,
    setToastConfiguration,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (downloadDocumentType && isShowDownloadModal && !fetching) {
      handleDownload({ downloadDocumentType });
    }
  }, [downloadDocumentType, isShowDownloadModal]);

  useEffect(() => {
    if (isDownloadAvailable) {
      handleDownload({ downloadDocumentType });
    }
  }, [isDownloadAvailable]);

  useEffect(() => {
    if (!showSecurityCheckModal && !hasTransactionsChallengeSuccess()) {
      handleCleanDownloadState();
    }
  }, [showSecurityCheckModal]);

  return {
    fetching,
    formatMessage,
    handleDownloadButtonClick,
    handleOnClickCancel,
  };
};
