// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from '../constants/next-step-error-credit-limit-increase.component.constants';
// Translations
import translations from '../translations/next-step-error-credit-limit-increase.component.translations';
// Types
import { GetNextStepErrorCreditLimitIncreaseComponentPropsType } from './types/next-step-error-credit-limit-increase.component.configurations.type';

export const getNextStepErrorCreditLimitIncreaseConfiguration = ({
  formatMessage,
  handleOnClickBackButton,
}: GetNextStepErrorCreditLimitIncreaseComponentPropsType): React.ComponentProps<
  typeof ErrorComponent
> => ({
  ...constants.ERROR_NEXT_STEP_PROPS,
  buttonConfiguration: {
    ...constants.ERROR_NEXT_STEP_PROPS.buttonConfiguration,
    children: formatMessage({ id: translations.BUTTON_TEXT }),
    onClick: handleOnClickBackButton,
  },
  description: formatMessage({ id: translations.DESCRIPTION }),
  title: formatMessage({ id: translations.TITLE }),
});
