// Enumerations
import {
  AccessKeyChangeSecondStepErrorIdEnumeration,
  AccessKeyChangeSecondStepRepeatErrorIdEnumeration,
} from '../components/second-step/enumerations/security-keys-access-key-change-second-step.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.securityAndKeys.changeAccessKey.';

export default {
  CHANGE_ACCESS_KEY_TITLE: `${DOMAIN}changeAccessKeyTitle`,
  REPEAT_NEW_ACCES_KEY: `${DOMAIN}repeatNewAccessKey`,
  DEFINE_NEW_ACCES_KEY: `${DOMAIN}defineCurrentAccessKey`,
  ENTER_CURRENT_ACCES_KEY: `${DOMAIN}enterCurrentAccessKey`,
  CHANGE_ACCES_KEY: `${DOMAIN}changeAccessKey`,
  DESCRIPTION_PASSWORD_TEXT: `${DOMAIN}descriptionPassword`,
  STEP_1: `${DOMAIN}Step1Title`,
  STEP_2: `${DOMAIN}Step2Title`,
  STEP_3: `${DOMAIN}Step3Title`,
  PASSWORD_RULE: {
    [AccessKeyChangeSecondStepErrorIdEnumeration.INPUT_CONTAINS_CUSTOMER_DATE]: `${DOMAIN}inputBirthdayRuleText`,
    [AccessKeyChangeSecondStepErrorIdEnumeration.INPUT_EQUALS_OLD_KEY]: `${DOMAIN}inputDifferentOldRuleText`,
    [AccessKeyChangeSecondStepErrorIdEnumeration.INPUT_REPEATS_NUMBER]: `${DOMAIN}sequenceRuleText`,
    [AccessKeyChangeSecondStepErrorIdEnumeration.INPUT_IS_A_SEQUENCE]: `${DOMAIN}ascendingDescendingRuleText`,
  },
  ERRORS: {
    [AccessKeyChangeSecondStepRepeatErrorIdEnumeration.INFORMATION_ABOVE]: `${DOMAIN}informationAboveError`,
    [AccessKeyChangeSecondStepRepeatErrorIdEnumeration.DONT_MATCH]: `${DOMAIN}DontMatchAccessKeyError`,
  },
  PASSWORD_INPUT_ERROR: `${DOMAIN}passwordInputErrorMessage`,
  PASSWORD_COMPONENT_DESCRIPTION: `${DOMAIN}passwordComponentDescription`,
  SHOW_PASSWORD_TEXT: `${DOMAIN}showPasswordLabel`,
  HIDE_PASSWORD_TEXT: `${DOMAIN}hidePasswordLabel`,
  NOTIFICATION_FIRST_LABEL: `${DOMAIN}headerFirstMessage`,
  NOTIFICATION_SECOND_LABEL: `${DOMAIN}headerSecondMessage`,
};
