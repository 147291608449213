// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';

export const REPORT_DEFECTIVE_DEFAULT_STATE = {
  data: {
    caseType: 'MANAGEMENT_TECH_DISPUTES',
    claimReasonType: 'DAMAGED_PRODUCT',
    motiveDal: 'DEFECTIVE_PRODUCT_SERVICE',
    defectiveProductService: {
      defectProductReturnProductCommerce: undefined,
      defectProductDateReturn: undefined,
      defectProductDescriptionProduct: '',
      defectProductDescriptionProblem: '',
    },
    blockCard: false,
    documents: [],
  },
  setData: (): undefined => undefined,
  files: [
    {
      documentId: undefined,
      documentType: 'CO008',
      id: 'proof_of_contact',
      file: null,
      state: DocumentsUploadStatesEnumeration.DEFAULT,
    },
  ],
  setFiles: (): undefined => undefined,
};
