const DOMAIN_ACCESS_KEY =
  'cobranded.services.userSettings.privateArea.securityAndKeys.changeAccessKey.';

const SUCCESS = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_ACCESS_KEY}successTitle`,
    CONTENT: `${DOMAIN_ACCESS_KEY}successContent`,
  },
  BACK_BUTTTON: `${DOMAIN_ACCESS_KEY}successBack`,
};

const FAILED = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_ACCESS_KEY}failedTitle`,
    CONTENT: `${DOMAIN_ACCESS_KEY}failedContent`,
  },
  CANCEL_BUTTTON: `${DOMAIN_ACCESS_KEY}failedCancel`,
  TRY_AGAIN_BUTTTON: `${DOMAIN_ACCESS_KEY}failedTryAgain`,
};

export { SUCCESS, FAILED };
