// Vendors
import React from 'react';
// Components
import RequestPortabilityStep1Component from '../components/step1/request-portability-step1.component';
import RequestPortabilityStep2Component from '../components/step2/request-portability-step2.component';
import RequestPortabilityStep3Component from '../components/step3/request-portability-step3.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { WizardComponentType } from 'components/wizard/types/wizard.component.type';
// Translations
import translations from './translations/request-portability.configuration.translations';
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK,
  SUCCESS_TITLE,
} from '../translations/request-portability.translations';
// Utils
import { generatePathRoute } from 'utils/app-routing.utils';

const getRequestPortabilityWizardConfiguration = ({
  email,
}: {
  email: string;
}): WizardComponentType => ({
  feedbackConfiguration: {
    errorConfiguration: {
      description: ERROR_DESCRIPTION,
      title: ERROR_TITLE,
      exitRoute: generatePathRoute([
        RoutesEnumeration.PERSONAL_INFORMATION,
        RoutesEnumeration.ACCESS_PORTABILITY,
      ]),
    },
    successConfiguration: {
      description: SUCCESS_DESCRIPTION,
      descriptionValues: {
        b: chunks => <strong>{chunks}</strong>,
        email,
        br: <br />,
      },
      linkLabel: SUCCESS_LINK,
      title: SUCCESS_TITLE,
      exitRoute: generatePathRoute([
        RoutesEnumeration.PERSONAL_INFORMATION,
        RoutesEnumeration.ACCESS_PORTABILITY,
      ]),
    },
  },
  steps: [
    {
      component: RequestPortabilityStep1Component,
      title: translations.STEP_1,
    },
    {
      component: RequestPortabilityStep2Component,
      title: translations.STEP_2,
    },
    {
      component: RequestPortabilityStep3Component,
      title: translations.STEP_3,
    },
  ],
});

export { getRequestPortabilityWizardConfiguration };
