const DOMAIN_BLOCK_CARD_SUCCESS_CONTENT =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.success.content.';

const TITLE = `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}title`;

const FIRST_STEP = {
  TITLE: `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}firstStep.title`,
  DESCRIPTION: `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}firstStep.description`,
};

const SECOND_STEP = {
  TITLE: `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}secondStep.title`,
  DESCRIPTION: `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}secondStep.description`,
};

const THIRD_STEP = {
  TITLE: `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}thirdStep.title`,
  DESCRIPTION: `${DOMAIN_BLOCK_CARD_SUCCESS_CONTENT}thirdStep.description`,
};

export { TITLE, FIRST_STEP, SECOND_STEP, THIRD_STEP };
