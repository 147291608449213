// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import SecurityKeysSignatureKeyChangeFirstStepHook from '../hooks/signature-key-change-step1.hook';
// Utilities
import { modifySignatureKeyChangeStep1NextButtonIsDisabled } from './utils/signature-key-change-step1-actions.utils';

const SecurityKeysSignatureKeyChangeFirstStepActionsComponent = (): React.ReactElement => {
  const { signatureKey } = SecurityKeysSignatureKeyChangeFirstStepHook();

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        disabled: modifySignatureKeyChangeStep1NextButtonIsDisabled({ signatureKey }),
      }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};

export default SecurityKeysSignatureKeyChangeFirstStepActionsComponent;
