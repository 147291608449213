// Vendors
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Services
import { getDocumentByTypeService } from '../services/transactions-table-header-link-bar-download-modal.services';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalResolverType } from './types/transactions-table-header-link-bar-download-modal.resolvers.type';
import { TransactionsTableHeaderLinkBarDownloadModalGetDocumentTypeResolverResponseType } from './types/transactions-table-header-link-bar-download-modal-return.resolvers.type';

const getDocumentByTypeResolver = async ({
  cardId,
  downloadDocumentType,
  formValuesParams,
  progressId,
}: TransactionsTableHeaderLinkBarDownloadModalResolverType): Promise<
  [TransactionsTableHeaderLinkBarDownloadModalGetDocumentTypeResolverResponseType] | [null, number]
> => {
  const [response, { status }] = await getDocumentByTypeService({
    cardId,
    downloadDocumentType,
    formValuesParams,
    progressId,
  });

  return wasSuccessRequest(status) ? [response] : [response, status];
};

export { getDocumentByTypeResolver };
