// Vendors
import React from 'react';
// Components
import { RecoveryKeyStep1Component } from '../components/step1/recovery-key-step1.component';
import { RecoveryKeyStep2Component } from '../components/step2/recovery-key-step2.component';
import { RecoveryKeyStep3Component } from '../components/step3/recovery-key-step3.component';
import RecoveryKeyStep4Component from '../components/step4/recovery-key-step4.component';
import RecoveryKeyStep5Component from '../components/step5/recovery-key-step5.component';
// Constants
import {
  FEEDBACK_CONFIGURATION,
  RECOVERY_KEY_LOGO_PROPS,
  TIMER_COUNTER,
} from './recovery-key.constants.common';
// Translations
import { TITLE as STEP_1_TITLE } from 'containers/recovery-key/components/step1/translations/recovery-key-step1.translations';
import { TITLE as STEP_2_TITLE } from 'containers/recovery-key/components/step2/translations/recovery-key-step2.translations';
import { TITLE as STEP_3_TITLE } from 'containers/recovery-key/components/step3/translations/recovery-key-step3.translations';
import { TITLE as STEP_5_TITLE } from 'containers/recovery-key/components/step5/translations/recovery-key-step5.translations';
// Types
import { WizardComponentType } from 'components/wizard/types/wizard.component.type';

const RECOVER_KEYS_WIZARD_PROPS = (_?: any): WizardComponentType => ({
  feedbackConfiguration: FEEDBACK_CONFIGURATION,
  steps: [
    {
      component: () => <RecoveryKeyStep1Component />,
      title: STEP_1_TITLE,
    },
    {
      component: () => <RecoveryKeyStep2Component />,
      title: STEP_2_TITLE,
    },
    {
      component: () => <RecoveryKeyStep3Component />,
      title: STEP_3_TITLE,
    },
    {
      component: () => <RecoveryKeyStep4Component />,
    },
    {
      component: () => <RecoveryKeyStep5Component />,
      title: STEP_5_TITLE,
    },
  ],
});

export { RECOVERY_KEY_LOGO_PROPS, TIMER_COUNTER, RECOVER_KEYS_WIZARD_PROPS };
