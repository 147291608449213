// Vendors
import styled from 'styled-components';
// Utils
import { isCC } from 'utils/app.utils';

const ChangeIbanSidebarItemComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;

const ChangeIbanSidebarMessageStyled = styled.div`
  color: ${({ theme }) => (isCC() ? theme.colors.fontPrimaryB : theme.colors.fontPrimaryA)};
`;

const ChangeIbanSidebarComponentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export {
  ChangeIbanSidebarItemComponentStyled,
  ChangeIbanSidebarMessageStyled,
  ChangeIbanSidebarComponentContainerStyled,
};
