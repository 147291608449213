const DOMAIN = 'cobranded.services.userSettings.privateArea.ibanBilling.changeIBAN.step1.';

export default {
  MESSAGE_01: `${DOMAIN}message01`,
  INPUT_PLACEHOLDER: `${DOMAIN}inputPlaceholder`,
  INPUT_LABEL: `${DOMAIN}inputLabel`,
  INPUT_HELPER_MESSAGE: `${DOMAIN}inputHelperMessage`,
  INPUT_ERROR: `${DOMAIN}inputError`,
  CHECKBOX_LABEL: `${DOMAIN}checkboxLabel`,
};
