// Vendors
import React from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
import { SecurityCheckModalSecondStepComponent } from './components/second-step/security-check-modal-second-step.component';
import { SecurityCheckModalFirstStepComponent } from './components/first-step/security-check-modal-first-step.component';
// Configurations
import { getModalConfiguration } from './configurations/security-check-modal.configurations';
// Enumerations
import { SecurityCheckModalStepEnumerations } from './hooks/enumerations/security-check-modal-step.enumerations';
// Hooks
import { SecurityCheckModalHook } from './hooks/security-check-modal.hook';
// Types
import { SecurityCheckModalComponentType } from './types/security-check-modal.component.type';

const DownloadSecurityCheckModalComponent = ({
  challenge,
  challenged,
  challengeError,
  fetchingDownload,
  handleDownloadTransactions,
  otpId,
  otpValue,
  setChallenge,
  setChallenged,
  setChallengeError,
  setError,
  setIsDownloadAvailable,
  setOtpId,
  setOtpValue,
  setDownloadDocumentType,
  setShowDownloadSecurityCheckModal,
  showDownloadSecurityCheckModal,
}: SecurityCheckModalComponentType): React.ReactElement | null => {
  const { formatMessage, handleNextModalStep, handleOnCloseModal, modalStep } =
    SecurityCheckModalHook({
      challenge,
      setChallenge,
      setChallenged,
      setChallengeError,
      setError,
      setDownloadDocumentType,
      setOtpValue,
      setShowDownloadSecurityCheckModal,
      showDownloadSecurityCheckModal,
    });
  return showDownloadSecurityCheckModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...getModalConfiguration({
        challenge,
        challenged,
        fetchingDownload,
        formatMessage,
        handleDownloadTransactions,
        handleNextModalStep,
        handleOnCloseModal,
        modalStep,
      })}
    >
      {modalStep === SecurityCheckModalStepEnumerations.FIRST ? (
        <SecurityCheckModalFirstStepComponent />
      ) : (
        <SecurityCheckModalSecondStepComponent
          {...{
            challenge,
            challenged,
            challengeError,
            otpId,
            otpValue,
            setChallenge,
            setChallenged,
            setChallengeError,
            setIsDownloadAvailable,
            setOtpId,
            setOtpValue,
          }}
        />
      )}
    </ModalComponent>
  ) : null;
};

export { DownloadSecurityCheckModalComponent };
