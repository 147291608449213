// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import CardSettingsBlockCardSecondStepActions from '../handlers/card-settings-block-card-second-step-actions.handlers';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
// Types
import { CardSettingsBlockCardSecondStepActionsHookReturnType } from './types/card-settings-block-card-second-step-actions-return.hook.type';
import { CardSettingsBlockCardSecondStepActionsHookType } from './types/card-settings-block-card-second-step-actions.hook.type';

const CardSettingsBlockCardSecondStepActionsHook = ({
  challenge,
  handleToggleChallengeError,
}: CardSettingsBlockCardSecondStepActionsHookType): CardSettingsBlockCardSecondStepActionsHookReturnType => {
  const {
      blockReason,
      selectedAddress,
      setBlockReason,
      setSelectedAddress,
      setShowReplacementModal,
      setTempAddress,
      tempAddress,
    } = BlockCardContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { setCard } = ManagerContextConsumerHook(),
    { address } = useAddressHook(),
    { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  return {
    ...CardSettingsBlockCardSecondStepActions({
      address,
      blockReason,
      cardId,
      challenge,
      handleToggleChallengeError,
      selectedAddress,
      setBlockReason,
      setCard,
      setResult,
      setSelectedAddress,
      setShowReplacementModal,
      setTempAddress,
      tempAddress,
    }),
  };
};

export default CardSettingsBlockCardSecondStepActionsHook;
