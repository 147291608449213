const DOMAIN = 'cobranded.services.userSettings.privateArea.notifications.landing.';

export default {
  SUB_TITLE: `${DOMAIN}title`,
  READED_TITLE: `${DOMAIN}readedTitle`,
  NOT_FOUND_TITLE: `${DOMAIN}notFound.title`,
  NOT_FOUND_DESCRIPTION: `${DOMAIN}notFound.description`,
  NOT_FOUND_BY_FILTERS: `${DOMAIN}notFound.byFilters`,
  FILTERS: `${DOMAIN}headerLinks.filters`,
  SETTINGS: `${DOMAIN}headerLinks.settings`,
  CLEAR_FILTERS: `${DOMAIN}headerLinks.clearFilters`,
};