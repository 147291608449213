// Resolvers
import { changeUserEmailOtpResolver } from 'containers/personal/views/email/components/step3/components/actions/resolvers/personal-email-step3-actions.resolver';
// Types
import { PersonalEmailStep3ActionsBuilderReturnHandlersType } from 'containers/personal/views/email/components/step3/components/actions/handlers/types/personal-email-step3-actions-builder-return.handlers.type';
import { PersonalEmailStep3ActionsBuilderHandlersType } from 'containers/personal/views/email/components/step3/components/actions/handlers/types/personal-email-step3-actions-builder.handlers.type';
import { isApiChallengeFlow } from 'utils/challenge.utils';
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
import { PersonalEmailTypesEnumeration } from 'containers/personal/views/email/enumerations/personal-email-types.enumeration';
import { ManagerContextConsumerHook } from 'contexts/manager.context';

const confirmChangeEmailButtonClickHandler = async ({
  handleConfirmChangeEmailButtonClickTracking,
  customerId,
  newEmail: email,
  progressId,
  setFetching,
  setShowModal,
  setResult,
  setMe,
}: PersonalEmailStep3ActionsBuilderHandlersType): Promise<void> => {
  setFetching(true);
  handleConfirmChangeEmailButtonClickTracking();

  const [, error] = await changeUserEmailOtpResolver({
    customerId,
    email,
    progressId,
  });

  if (error) {
    setShowModal(PersonalEmailTypesEnumeration.LOG_OUT);
  } else {
    setMe(true);
    setResult(true);
  }

  setFetching(false);
};

const PersonalEmailStep3ActionsHandlers = (
  props: PersonalEmailStep3ActionsBuilderHandlersType
): PersonalEmailStep3ActionsBuilderReturnHandlersType => ({
  handleConfirmChangeEmailButtonClick: () => confirmChangeEmailButtonClickHandler(props),
});

export default PersonalEmailStep3ActionsHandlers;
