// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { PersonalSidebarCCHookType } from './types/personal-sidebar-cc.hook.type';

export const PersonalSidebarCCHook = (): PersonalSidebarCCHookType => {
  const { formatMessage } = useCbIntl();
  const { profile } = AppContextConsumerHook(),
    { userName = CharactersEnum.NO_VALUE, alias = CharactersEnum.NO_VALUE } =
      profile?.userDetails || {};

  return { alias, formatMessage, userName };
};
