const GET_CITIES_BY_TERM_SERVICE_CONFIGURATION = {
  endpoint: 'cb-api-localization/v1/cities',
};

const VALIDATE_CITY_WITH_POSTCODE_SERVICE_CONFIGURATION = {
  endpoint: 'cb-api-localization/v1/cities/address-validation/validate',
};

export {
  GET_CITIES_BY_TERM_SERVICE_CONFIGURATION,
  VALIDATE_CITY_WITH_POSTCODE_SERVICE_CONFIGURATION,
};
