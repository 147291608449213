// Services
import { validatePinService, getPublickKeyService } from '../services/recovery-key-step4-credit-card.services';
// Types
import { VerifySignatureKeyRecoverApiResponseType } from 'types/challenge/verify-signature-key-recover-api-response.type';
import { ValidatePinPanResolverType } from './types/recovery-key-step4-credit-card.resolvers.type';
// Utilities
import { encrypt } from '@openbank/cf-ui-framework';

const validatePinPanResolver = async (
  props: ValidatePinPanResolverType
): Promise<[VerifySignatureKeyRecoverApiResponseType] | [null, true]> => {
  const [{ publicKey }] = await getPublickKeyService();

  if(!publicKey) {
    return [null, true];
  }

  const pin = await encrypt(publicKey, props.pin);
  const [response] = await validatePinService({ ...props, pin });

  return response?.progressId
    ? [response as VerifySignatureKeyRecoverApiResponseType]
    : [null, true];
};

export { validatePinPanResolver };
