// Vendors
import styled from 'styled-components';

export const SettingsCardControlOperationsShopsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const SettingsCardControlOperationsShopsTopComponentStyled = styled.header`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const SettingsCardControlOperationsShopsBottomComponentStyled = styled(
  SettingsCardControlOperationsShopsComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  padding-left: ${({ theme }) => theme.spaces.spacingXxxl};
`;
