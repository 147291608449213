// Vendors
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Contexts
import { useTransactionsReportContextConsumerHook } from '../contexts/transactions-report.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { TransactionsReportHandlers } from '../handlers/transactions-report.handlers';
// Types
import { TransactionsReportReturnHookType } from './types/transactions-report.hook.type';

export const useTransactionsReportHook = (): TransactionsReportReturnHookType => {
  const { transactionId } = useParams();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  const { error, fetching, setError, setFetching, setTransactionDetails, transactionDetails } =
    useTransactionsReportContextConsumerHook();

  const { handleRenderView } = TransactionsReportHandlers({
    cardId,
    navigate: useNavigate(),
    setError,
    setFetching,
    setTransactionDetails,
    transactionId,
  });

  useEffect(() => {
    handleRenderView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    error,
    fetching,
    transactionDetails,
  };
};
