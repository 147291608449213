// Vendors
import React from 'react';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FilterModalInputRangeComponentType } from './types/filter-modal-input-range.component.type';
// Utilities
import { getInputRangeComponent } from './utils/get-input-range.utils';

const FilterModalInputRangeComponent = ({
  showLineSeparator,
  title,
  handleFormValuesChange,
  formValues,
  type,
}: FilterModalInputRangeComponentType): React.ReactElement | null => {
  const { formatNumber, formatDate } = useCbIntl();

  return getInputRangeComponent({
    formatNumber,
    formatDate,
    handleFormValuesChange,
    showLineSeparator,
    title,
    formValues,
    type,
  });
};
export { FilterModalInputRangeComponent };
