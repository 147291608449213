// Handlers
import { ExpiredDocumentModalHandlers } from '../handlers/expired-document-modal.handlers';
// Hooks
import { useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { UseExpiredDocumentModalHookPropsType } from './types/expired-document-modal-props.hook.type';
import { UseExpiredDocumentModalHookReturnType } from './types/expired-document-modal-return.hook.type';

const useExpiredDocumentModalHook = ({
  setIsExpiredDocumentModalOpen,
}: UseExpiredDocumentModalHookPropsType): UseExpiredDocumentModalHookReturnType => {
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();

  return {
    ...ExpiredDocumentModalHandlers({ setIsExpiredDocumentModalOpen, navigate }),
    formatMessage,
  };
};

export { useExpiredDocumentModalHook };
