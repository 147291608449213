// Vendors
import React from 'react';
// Configurations
import {
  convertNavItemLinksConfiguration,
  getLastAccessTranslationConfiguration,
} from './configurations/orchestrator-header.configurations';
// Components
import { MenuComponent } from '@openbank/cb-ui-commons';
import AvatarComponent from 'components/avatar/avatar.component';
import CancelModalComponent from 'components/cancel-modal/cancel-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  ORCHESTRATOR_HEADER_AVATAR_PROPS,
  ORCHESTRATOR_HEADER_MAIN_ROUTE,
  ORCHESTRATOR_HEADER_NAV_ITEMS,
  ORCHESTRATOR_HEADER_USER_NAV_ITEMS,
  ORCHESTRATOR_LOGOUT_MODAL_PROPS,
  ORCHESTRATOR_SIDE_BAR_LINK_PROPS,
} from './constants/orchestrator-header.constants';
// Hooks
import useOrchestratorHeaderHook from './hooks/orchestrator-header.hook';
// Styles
import { OrchestratorHeaderComponentStyled } from './orchestrator-header.component.styled';
// Translations
import {
  ACCOUNT_LABEL,
  LOGOUT,
  SEE_PERSONAL_DATA,
} from './translations/orchestrator-header.translations';
// Utilities
import { isCC } from 'utils/app.utils';
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';

const OrchestratorHeaderComponent = (): React.ReactElement => {
  const {
      formatDate,
      formatMessage,
      handleMakeUserCancelClickHandler,
      handleMakeUserLogoutButtonClick,
      handleMakeUserLogoutClickHandler,
      handleMakePersonalDataClickHandler,
      isHybrid,
      name,
      navigate,
      showModal,
      notificationsModalOptions,
      headerRef,
      lastAccess,
    } = useOrchestratorHeaderHook(),
    avatar = () => <AvatarComponent {...ORCHESTRATOR_HEADER_AVATAR_PROPS} />;

  return (
    <OrchestratorHeaderComponentStyled
      ref={headerRef}
      isSecondLevel={isSecondLevelRoutePath()}
      isHybrid={isHybrid}
    >
      <MenuComponent
        {...{ navigate, notificationsModalOptions }}
        mainRoute={ORCHESTRATOR_HEADER_MAIN_ROUTE}
        translations={{
          lastAccess: getLastAccessTranslationConfiguration({
            formatDate,
            formatMessage,
            lastAccess,
          }),
          logout: formatMessage({ id: LOGOUT }),
          account: formatMessage({ id: ACCOUNT_LABEL }),
        }}
        onLogout={handleMakeUserLogoutButtonClick}
        currentRoute={window.location.pathname}
        navItems={convertNavItemLinksConfiguration({
          formatMessage,
          items: ORCHESTRATOR_HEADER_NAV_ITEMS,
        })}
        user={{ avatar, name }}
        userNavItems={convertNavItemLinksConfiguration({
          formatMessage,
          items: ORCHESTRATOR_HEADER_USER_NAV_ITEMS,
        })}
        isCC={isCC()}
        sidebarLinkConfiguration={{
          children: <FormattedMessageComponent id={SEE_PERSONAL_DATA} />,
          onClick: handleMakePersonalDataClickHandler,
          ...ORCHESTRATOR_SIDE_BAR_LINK_PROPS,
        }}
      />
      <CancelModalComponent
        {...ORCHESTRATOR_LOGOUT_MODAL_PROPS}
        haveDescription={false}
        onClose={handleMakeUserCancelClickHandler}
        onConfirm={handleMakeUserLogoutClickHandler}
        show={showModal}
      />
    </OrchestratorHeaderComponentStyled>
  );
};

export default OrchestratorHeaderComponent;
