// Types
import { OrderModalComponentProps } from 'containers/financing/views/landing/components/row/components/details/components/orders/components/modal/types/order-modal.component.types';

const ORDER_MODAL_DESKTOP_CONSTANTS = {
  testId: 'order-modal-all-desktop-component',
  underline: false,
};

const ORDER_DESKTOP_LINK_CONSTANTS = {
  testId: 'order-all-desktop-link',
  underline: true,
};

const ORDER_DESKTOP_TABLE_DETAILS_HEADER = {
  testId: 'order-all-desktop-details-header',
};

const ORDER_DESKTOP_TABLE_DETAILS_BODY = {
  testId: 'order-all-desktop-deatils-body',
};

const ORDER_MODAL_TEST_CONSTANTS = {
  PURCHASE_LABEL: 'Purchase date',
  DEALER_LABEL: 'Dealer',
  ORDER_NUMBER_LABEL: 'Order number',
  PROPS: {
    orders: ['order1', 'order2'],
    urlAmazon: 'url',
    transactionInfo: { date: '10/01/2024' },
  } as OrderModalComponentProps,
};

export {
  ORDER_MODAL_DESKTOP_CONSTANTS,
  ORDER_DESKTOP_LINK_CONSTANTS,
  ORDER_DESKTOP_TABLE_DETAILS_HEADER,
  ORDER_DESKTOP_TABLE_DETAILS_BODY,
  ORDER_MODAL_TEST_CONSTANTS,
};
