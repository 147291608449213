import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Translations
import {
  ITEM_TITLE_01,
  ITEM_01_SUBITEM_01,
  ITEM_01_SUBITEM_02,
  ITEM_TITLE_02,
  ITEM_02_SUBITEM_01,
  ITEM_02_SUBITEM_02,
  ITEM_TITLE_03,
  ITEM_03_SUBITEM_01,
  ITEM_01_SUBITEM_03,
  ITEM_03_SUBITEM_02,
} from './translations/financing-ppi-contracting-step2-expandable-limitations.constants.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_LIST_PROPS = {
    ordered: true,
    testId: 'financing-ppi-contracting-step2-expandable-limitations',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_DESCRIPTION_01_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-limitations-description-01',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS__LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxs,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_DESCRIPTION_02_PROPS = {
    testId: 'financing-ppi-contracting-step2-expandable-limitations-description-02',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_LIST_ITEMS = {
    items: [
      {
        label: ITEM_TITLE_01,
        subItems: [ITEM_01_SUBITEM_01, ITEM_01_SUBITEM_02, ITEM_01_SUBITEM_03],
      },
      {
        label: ITEM_TITLE_02,
        subItems: [ITEM_02_SUBITEM_01, ITEM_02_SUBITEM_02],
      },
      {
        label: ITEM_TITLE_03,
        subItems: [ITEM_03_SUBITEM_01, ITEM_03_SUBITEM_02],
      },
    ],
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS__LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_DESCRIPTION_01_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_DESCRIPTION_02_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMITATIONS_LIST_ITEMS,
};
