// Vendors
import { CountriesLocaleEnum } from '@openbank/cf-ui-static-data';
import { getLocaleFromBrowser } from '@openbank/cf-ui-framework';
// Contexts
import { DashboardContextConsumerHook } from 'containers/dashboard/contexts/dashboard.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { DashboardTopMessageHookType } from './types/dashboard-top-message.hook.type';
// Utils
import {
  getCreditLimitStatus,
  getDefaultCardInfo,
} from 'containers/dashboard/utils/get-default-card-info.utils';

const DashboardTopMessageHook = (): DashboardTopMessageHookType => {
  const { formatMessage } = useCbIntl();
  const { information } = DashboardContextConsumerHook();
  const { blockDetails: { cardBlockCode = '', description = '', nonPaid = false } = {} } =
    getDefaultCardInfo(information) ?? {};
  const { limitIncreaseStatus, requestedLimit, offeredLimit } =
    getCreditLimitStatus(information) ?? {};
  const locale = getLocaleFromBrowser() || CountriesLocaleEnum.GERMANY;

  return {
    cardBlockCode,
    cardBlockDescription: description,
    formatMessage,
    limitIncreaseStatus,
    locale,
    nonPaid,
    offeredLimit,
    requestedLimit,
  };
};

export default DashboardTopMessageHook;
