export const TYPE_ACCESS_TOKEN = 'access_token';
export const TYPE_ACCOUNT_INFO = 'account_info';
export const TYPE_CHANGE_CONTEXT = 'change_context';
export const TYPE_CONTACT = 'contact';
export const TYPE_DEVICE_INFO = 'device_info';
export const TYPE_FILE_DOWNLOAD = 'download_file';
export const TYPE_FILE_SHARE = 'share_file';
export const TYPE_FINISH = 'finish_process';
export const TYPE_FINISH_OTP = 'finish_otp_listener';
export const TYPE_GEOLOCATION = 'geolocation';
export const TYPE_GO_BACK = 'go_back';
export const TYPE_INIT_OTP = 'init_otp_listener';
export const TYPE_INVALIDATE_CACHE = 'invalidate_cache';
export const TYPE_LANGUAGE = 'language';
export const TYPE_SET_ACCESS_TOKEN = 'set_access_token';
export const TYPE_OPEN_WEB_BROWSER = 'open_link_on_native_browser';
export const TYPE_OPEN_WEB_VIEW_BROWSER = 'open_link_on_native_webview';
export const TYPE_PASSWORD_UPDATE = 'password_update_success';
export const TYPE_REFRESH_TOKEN = 'refresh_token';
export const TYPE_SEND_OTP_CODE = 'receive_sms';
export const TYPE_USER_NAME = 'user_name';
