// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_EPP_DETAILS_SERVICE_CONFIGURATION } from './constants/epp.services.constants';
// Types
import { EppCardType } from 'types/epp-card.type';
import { GetEppDetailsServiceType } from './types/get-epp-details-service.type';

const getEppDetailsService = async ({
  cardId,
  eppId,
}: GetEppDetailsServiceType): Promise<[EppCardType | null, Response]> =>
  apiUtilsCb.get({ ...FETCH_EPP_DETAILS_SERVICE_CONFIGURATION, pathParams: { cardId, eppId } });

export { getEppDetailsService };
