// Constants
import * as MESSAGES from '../constants/mobile-connector.constants';
// Factory
import { asyncCallFactory, asyncListenFactory } from '../factory/mobile-connector.factory';
// Types
import { AccountInfoResponse } from './types/account-info-response.type';
import { DeviceInfoResponse } from './types/device-info-response.type';
import { FileShare } from './types/file-share.type';
import { FinishProcessRequest } from './types/finish-process-request.type';
import { GeolocationResponse } from './types/geolocation-response.type';
import { GetUserNameResponse } from './types/get-user-name-response.type';
import { LanguageInfoResponse } from './types/language-info-response.type';

export const changeAntiFraudContext = asyncCallFactory<void, string>(MESSAGES.TYPE_CHANGE_CONTEXT);
export const downloadFile = asyncCallFactory<string, void>(MESSAGES.TYPE_FILE_DOWNLOAD);
export const finishOtp = asyncCallFactory<void, string>(MESSAGES.TYPE_FINISH_OTP);
export const finishProcess = asyncCallFactory<FinishProcessRequest, void>(MESSAGES.TYPE_FINISH);
export const getAccessToken = asyncCallFactory<void, string>(MESSAGES.TYPE_ACCESS_TOKEN);
export const getRefreshToken = asyncCallFactory<void, string>(MESSAGES.TYPE_ACCESS_TOKEN);
export const getAccountInfo = asyncCallFactory<void, AccountInfoResponse>(
  MESSAGES.TYPE_ACCOUNT_INFO
);
export const getContact = asyncCallFactory<void, string>(MESSAGES.TYPE_CONTACT);
export const getDeviceInfo = asyncCallFactory<void, DeviceInfoResponse>(MESSAGES.TYPE_DEVICE_INFO);
export const getGeolocation = asyncCallFactory<void, GeolocationResponse>(
  MESSAGES.TYPE_GEOLOCATION
);
export const getLanguage = asyncCallFactory<void, LanguageInfoResponse>(MESSAGES.TYPE_LANGUAGE);
export const getUserName = asyncCallFactory<void, GetUserNameResponse>(MESSAGES.TYPE_USER_NAME);
export const setAccessToken = asyncCallFactory<{ accessToken: string }, void>(
  MESSAGES.TYPE_SET_ACCESS_TOKEN
);
export const goBack = asyncCallFactory<void, void>(MESSAGES.TYPE_GO_BACK);
export const initOtp = asyncCallFactory<void, string>(MESSAGES.TYPE_INIT_OTP);
export const invalidateCache = asyncCallFactory<void, void>(MESSAGES.TYPE_INVALIDATE_CACHE);
export const listenOtp = asyncListenFactory(MESSAGES.TYPE_SEND_OTP_CODE);
export const openBrowserLink = asyncCallFactory<string, void>(MESSAGES.TYPE_OPEN_WEB_BROWSER);
export const openWebViewBrowserLink = asyncCallFactory<string, void>(
  MESSAGES.TYPE_OPEN_WEB_VIEW_BROWSER
);
export const refreshToken = asyncCallFactory<void, string>(MESSAGES.TYPE_REFRESH_TOKEN);
export const shareFile = asyncCallFactory<void, FileShare>(MESSAGES.TYPE_FILE_SHARE);
export const updatePassword = asyncCallFactory<void, void>(MESSAGES.TYPE_PASSWORD_UPDATE);

// data class AnalyticsPayloadData(val tealiumId: String) : MBHybridPayloadData()

// data class AckPayloadData(val fromType: MBHybridPayloadType, val status: AckPayloadDataStatus) :
