// Translations
import {
  LEGAL_INFORMATION,
  LEGAL_NOTICE,
  PRIVACY_POLICY_AND_COOKIES,
} from '../translations/orchestrator-footer.utils.translations';

const APP_FOOTER_PUBLIC_LINKS = [
  {
    testId: 'entrypoint-footer-public-links-legal-notice',
    text: LEGAL_NOTICE,
    to: 'https://www.zinia.de/amazon/impressum/',
  },
  {
    testId: 'entrypoint-footer-public-links-privacy-policy-and-cookies',
    text: PRIVACY_POLICY_AND_COOKIES,
    to: 'https://www.zinia.de/amazon/datenschutz-cookies/',
  },
  {
    testId: 'entrypoint-footer-public-links-legal-information',
    text: LEGAL_INFORMATION,
    to: 'https://www.openbank.de/assets/MB_DEU/de-DE/documents/v1/CB.AMZ.DE.TC.GENERAL_SPECIAL.pdf',
  },
];

export { APP_FOOTER_PUBLIC_LINKS };
