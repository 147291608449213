const TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_PROPS = {
  testId: 'transactions-landing-row-details-amount',
};
const TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_LINK_PROPS = {
  testId: 'transactions-landing-row-details-amount-links',
};
export {
  TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_AMOUNT_LINK_PROPS,
};
