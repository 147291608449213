// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Types
import { LabelsLinkCardLinkComponentType } from './types/labels-link-card-link.type';

const LabelsLinkCardLinkComponent = ({
  link,
  linkId,
  handleLabelsLinkCardLinkClick,
}: LabelsLinkCardLinkComponentType): React.ReactElement | null => 
  link ? (
    <LinkComponent onClick={handleLabelsLinkCardLinkClick} testId={linkId}>
      <FormattedMessageComponent id={link} />
    </LinkComponent>
  ) : null;

export default LabelsLinkCardLinkComponent;
