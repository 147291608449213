// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const SizeTwoInputStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};

  > div {
    width: ${convertPxsToRems({ pixels: 146, base: 14 })};

    ${mobileMediaQuery} {
      width: 50%;
    }

    > div > label {
      width: max-content;
    }
  }
`;
