// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { FinancingPPIContractingStep2DownloadType } from './types/financing-ppi-contracting-step2-download.type';

const useFinancingPPIContractingStep2DownloadHook =
  (): FinancingPPIContractingStep2DownloadType => {
    const { currentCard } = AppContextConsumerHook(),
      { cardContractId: productContractId, productTypeCode: productContractType } = currentCard;

    return { params: { productContractId, productContractType } };
  };

export { useFinancingPPIContractingStep2DownloadHook };
