// Constants
import { UNDEFINED_ADDRESS_DETAILS } from './constants/use-address-predictions.util.constants';
// Types
import {
  AddDetailsToPredictionUtilPropsType,
  AddDetailsToPredictionUtilReturnType,
  AddDetailsToPredictionsUtilClosurePropType,
  AddDetailsToPredictionsUtilClosureReturnType,
} from './types/use-address-predictions.util.type';
// Utils
import { parseAddressDetails } from './utils/parse-address-details.util';

export const addDetailsToPrediction = async ({
  addressPrediction,
  geocoderService,
}: AddDetailsToPredictionUtilPropsType): AddDetailsToPredictionUtilReturnType => {
  try {
    const { results } = await geocoderService.current.geocode({
      address: addressPrediction,
    });

    return parseAddressDetails(results[0].address_components);
  } catch (error) {
    return UNDEFINED_ADDRESS_DETAILS;
  }
};

export const AddDetailsToPredictionsUtilClosure = (
  geocoderService: AddDetailsToPredictionsUtilClosurePropType
): AddDetailsToPredictionsUtilClosureReturnType => ({
  addDetailsToPredictionUtil: async addressPrediction =>
    addDetailsToPrediction({ addressPrediction, geocoderService }),
});
