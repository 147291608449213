// Vendors
import React from 'react';
// Components
import FormattedMessageBaseComponent from '../formatted-message-base/formatted-message-base.component';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Providers
import PoeKeysHook from 'providers/poe-keys/hooks/poe-keys.hook';
// Styles
import { FormattedMessageComponentStyled } from './formatted-message.component.styled';
// Types
import type { FormattedMessagePropsType } from './types/formatted-message-props.type';

const FormattedMessageComponent = (props: FormattedMessagePropsType): React.ReactElement => {
  const { isShowingKeys } = PoeKeysHook();
  const { formatMessage } = useCbIntl();
  const descriptor = {
    defaultMessage: props?.defaultMessage,
    description: props?.description,
    id: props?.id,
  };

  return (
    <FormattedMessageComponentStyled
      bold={props.bold}
      uppercase={props.uppercase}
      isNormalFont={props.isNormalFont}
    >
      <FormattedMessageBaseComponent id={props.id} {...{ isShowingKeys }}>
        {formatMessage(descriptor, props.values)}
      </FormattedMessageBaseComponent>
    </FormattedMessageComponentStyled>
  );
};

export default FormattedMessageComponent;
