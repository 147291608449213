// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import {
  ACCESS_REQUEST_ROUTE_PROPS,
  ACCESS_PORTABILITY_LANDING_ROUTE_PROPS,
  PORTABILITY_REQUEST_ROUTE_PROPS,
} from './constants/access-portability-router.constants';
// Context
import { AccessPortabilityContextConsumerHOC } from '../../contexts/access-portability.context';
// Components
import { AccessPortabilityRequestGuardComponent } from './components/request-guard/access-portability-request-guard.component';
// Enumerations
import { AccessPortabilityTypeEnumeration } from '../../enumerations/access-portability.enumeration';
// Views
const AccessRequestView = lazy(
  () =>
    import('containers/personal/views/access-portability/views/request/request-access.component')
);
const PortabilityRequestView = lazy(
  () =>
    import(
      'containers/personal/views/access-portability/views/request-portability/request-portability.component'
    )
);
const AccessPortabilityLandingView = lazy(
  () =>
    import(
      'containers/personal/views/access-portability/views/landing/access-portability-landing.component'
    )
);

const AccessPortabilityRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...ACCESS_PORTABILITY_LANDING_ROUTE_PROPS} element={<AccessPortabilityLandingView />} />
    <Route
      element={
        <AccessPortabilityRequestGuardComponent
          rightsType={AccessPortabilityTypeEnumeration.ACCESS}
        />
      }
    >
      <Route {...ACCESS_REQUEST_ROUTE_PROPS} element={<AccessRequestView />} />
    </Route>
    <Route
      element={
        <AccessPortabilityRequestGuardComponent
          rightsType={AccessPortabilityTypeEnumeration.PORTABILITY}
        />
      }
    >
      <Route {...PORTABILITY_REQUEST_ROUTE_PROPS} element={<PortabilityRequestView />} />
    </Route>
  </Routes>
);

export default AccessPortabilityContextConsumerHOC(AccessPortabilityRouterComponent);
