// Vendors
import { useEffect } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useInternationalizationCommonHook from './internationalization.common.hook';
// Types
import { InternationalizationReturnHookType } from './types/internationalization-return.hook.type';
// Utilities
import { getUserLocale } from './utils/internationalization.hook.utils';

const useInternationalizationHook = (): Omit<
  InternationalizationReturnHookType,
  'handleFetchTranslations'
> => {
  const { languagePreference } = AppContextConsumerHook();

  const { handleFetchTranslations, ...rest } = useInternationalizationCommonHook(
    getUserLocale({ languagePreference, profile: {} })
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchTranslations();
  }, [languagePreference]);

  return rest;
};

export default useInternationalizationHook;
