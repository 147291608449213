// Resolvers
import { fetchReportProblemResolver } from 'containers/transactions/views/report/resolvers/transactions-report.resolvers';
// Constants
import { DISPUTE_PARAMS } from 'containers/transactions/views/report/components/payment/components/confirmation/constants/transactions-report-payment-confirmation.constants';
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/payment/contexts/constants/transactions-report-payment.context.constants';
// Types
import { TransactionsReportPaymentConfirmationActionsHandlersType } from './types/transactions-report-payment-confirmation-actions.handlers.type';
import { TransactionsReportPaymentConfirmationActionsHandlersReturnType } from './types/transactions-report-payment-confirmation-actions-return.handlers.type';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const confirmButtonClickHandler = async ({
  paymentMethod,
  cardId,
  challenge,
  files,
  setPaymentMethod,
  setCaseId,
  setChallengeError,
  setError,
  setFiles,
  setResult,
  transactionId,
}: TransactionsReportPaymentConfirmationActionsHandlersType): Promise<void> => {
  try {
    if (!cardId || !transactionId) throw new Error();

    const [response, error] = await fetchReportProblemResolver({
      cardId,
      transactionId,
      data: {
        ...DISPUTE_PARAMS,
        paymentMadeForOtherReasons: { payOthersPaymentMethod: paymentMethod },
        documents: getDocumentIds(files),
      },
      progressId: challenge?.progressId,
    });

    if (error) {
      if (isWrongOTPError(error)) {
        setChallengeError(true);
      } else {
        setPaymentMethod(undefined);
        setFiles(FILES_INITIAL_STATE);
        setResult(false);
      }
    } else {
      setResult(true);
      setCaseId(response?.caseId);
    }
  } catch {
    setError(true);
  }
};

export const TransactionsReportPaymentConfirmationActionsHandlers = (
  props: TransactionsReportPaymentConfirmationActionsHandlersType
): TransactionsReportPaymentConfirmationActionsHandlersReturnType => ({
  handleConfirmButtonClick: () => confirmButtonClickHandler(props),
});
