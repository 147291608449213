// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsChange3DContextConsumerHook } from 'containers/card-settings/views/change-3d/contexts/card-settings-change-3d.context';
// Types
import { CardSettingsChange3dStep1HookType } from 'containers/card-settings/views/change-3d/components/step1/hooks/types/card-settings-change-3d-step1.hook.type';

const useCardSettingsChange3dStep1Hook = (): CardSettingsChange3dStep1HookType => {
  const { new3DCode, repeated3DCode } = useCardSettingsChange3DContextConsumerHook(),
    { profile } = AppContextConsumerHook(),
    { dateOfBirth } = profile?.birthDetails || {};

  return {
    dateOfBirth,
    new3DCode,
    repeated3DCode,
  };
};

export { useCardSettingsChange3dStep1Hook };
