// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, ExpandableRowComponent } from '@openbank/cb-ui-commons';
import DashboardSpentBottomInnerComponent from '../../components/inner/dashboard-spent-bottom-inner.component';
// Translations
import translations from './translations/dashboard-spent-expandable-inner.translations';
// Constants
import constants from './constants/dashboard-spent-expandable-inner.constants';
// Types
import { DashboardSpentExpandableBottomInnerComponentType } from './types/dashboard-spent-expandable-inner.component.type';

const DashboardSpentExpandableBottomInnerComponent = ({
  expenses,
  isMobile,
  spentMessageConfiguration,
  testing = { ...constants.DASHBOARD_SPENT_EXPANDABLE_BOTTOM_INNER_ROW_TESTING },
  total,
}: DashboardSpentExpandableBottomInnerComponentType): React.ReactElement => (
  <ExpandableRowComponent
    arrowPosition={constants.EXPANDABLE_ROW_PROPS.arrowPosition}
    title={
      <ParagraphComponent {...constants.DASHBOARD_SPENT_EXPANDABLE_BOTTOM_INNER_CHEVRON_PROPS}>
        <FormattedMessageComponent id={translations.title} />
      </ParagraphComponent>
    }
    type={constants.EXPANDABLE_ROW_PROPS.type}
    lateralDetail={constants.EXPANDABLE_ROW_PROPS.lateralDetail}
    {...{ testing }}
  >
    <DashboardSpentBottomInnerComponent
      {...{ expenses, isMobile, total, spentMessageConfiguration }}
    />
  </ExpandableRowComponent>
);

export default DashboardSpentExpandableBottomInnerComponent;
