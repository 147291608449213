const CLICK_LINK_TRACKING = 'clickLink';
const CLICK_BUTTON_TRACKING = 'clickButton';
const LOG_OFF_MODAL_OPENING_TRACKING = 'logOffModalOpen';
const LOGOUT_EVENT_TRACKING = 'logOut';

export {
  CLICK_LINK_TRACKING,
  CLICK_BUTTON_TRACKING,
  LOG_OFF_MODAL_OPENING_TRACKING,
  LOGOUT_EVENT_TRACKING,
};
