export enum TransactionsStatusCodeEnumeration {
  ANNULLED = 'ANNULLED',
  AUTHORIZED = 'AUTHORIZED',
  DECLINED = 'DECLINED',
  DROPPED = 'DROPPED',
  FAILED = 'FAILED',
  LIQUIDATED = 'LIQUIDATED',
  NEW = 'NEW',
  PENDING_CONSOLIDATION = 'PENDING_CONSOLIDATION',
  PENDING_LIQUIDATION = 'PENDING_LIQUIDATION',
  REJECTED = 'REJECTED',
  UNKNOWN = 'UNKNOWN',
}
