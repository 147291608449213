// Vendors
import { isValidObject } from '@openbank/cf-ui-framework';
// Services
import {
  fetchDictionaryConfigurationService,
  fetchLanguagesConfigurationService,
  fetchLanguageService,
} from '../services/internationalization.services';
// Types
import { LanguageServiceResponseType } from '../types/language-service-response.type';
import { PublicConfigurationType } from '../types/public-configuration.type';
// Utilities
import {
  isValidLanguageConfiguration,
  isValidPoeConfiguration,
} from './utils/internationalization.resolvers.utils';

const fetchLanguagesConfigurationResolver = async (): Promise<
  [LanguageServiceResponseType] | [null, true]
> => {
  const [response] = await fetchLanguagesConfigurationService();

  return isValidLanguageConfiguration(response)
    ? [response as LanguageServiceResponseType]
    : [null, true];
};

const fetchLanguageResolver = async (
  url: string
): Promise<[Record<string, any>] | [null, boolean]> => {
  const [{ entries } = { entries: {} }] = await fetchLanguageService(url);

  return isValidObject(entries) ? [entries] : [null, true];
};

const fetchDictionaryConfigurationResolver = async (): Promise<
  [PublicConfigurationType] | [null, boolean]
> => {
  const [response] = await fetchDictionaryConfigurationService();

  return isValidPoeConfiguration(response) ? [response as PublicConfigurationType] : [null, true];
};

export {
  fetchDictionaryConfigurationResolver,
  fetchLanguageResolver,
  fetchLanguagesConfigurationResolver,
};
