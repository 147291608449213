// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Enums
import {
  RequestEmailInputErrorEnumeration,
} from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Context
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import RequestPortabilityStep2EmailOptionsHandlers from '../handlers/request-portability-step2-email-options.handlers';
import RequestPortabilityStep2EmailOptionsTrackingHandlers from '../handlers/request-portability-step2-email-options.handlers.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { AccessPortabilityStep2EmailOptionsReturnHookType } from './types/request-portability-step2-email-options-return.hook.type';

const RequestPortabilityStep2EmailOptionsHook =
  (): AccessPortabilityStep2EmailOptionsReturnHookType => {
    const [error, setError] = useState<RequestEmailInputErrorEnumeration | null>(null);
    const { emailValue, setEmailValue, emailCheckboxValue, setEmailCheckboxValue } =
      AccessPortabilityContextConsumerHook();
    const { formatMessage } = useCbIntl();
    const { profile } = AppContextConsumerHook(),
      { email } = profile?.contactDetails || {};

    return {
      ...AppTrackingHook(RequestPortabilityStep2EmailOptionsTrackingHandlers),
      ...RequestPortabilityStep2EmailOptionsHandlers({
        emailValue,
        setEmailCheckboxValue,
        setEmailValue,
        setError,
      }),
      email,
      emailCheckboxValue,
      error,
      formatMessage,
    };
  };

export default RequestPortabilityStep2EmailOptionsHook;
