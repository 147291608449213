// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalEmailStep2ChallengeBuilderReturnTrackingHandlersType } from 'containers/personal/views/email/components/step2/components/challenge/handlers/types/personal-email-step2-challenge-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const sendEmailOtpConfirmationButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'sendEmailOtpConfirmation',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalEmailCancelTrackingHandlers = (
  track: TrackBuilderType
): PersonalEmailStep2ChallengeBuilderReturnTrackingHandlersType => ({
  handleSendEmailOtpConfirmationButtonClickTracking: () =>
    track(sendEmailOtpConfirmationButtonClickTrackingHandler()),
});

export default PersonalEmailCancelTrackingHandlers;
