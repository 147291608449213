// Types
import { PersonalNumberStep1ActionsUtilsType } from './types/personal-number-step1-actions.utils.type';
// Utilities
import { IsValidPhoneMatch } from 'containers/personal/views/number/components/step1/utils/personal-number-step1.utils';
import { isValidPhoneNumber } from 'utils/phone.utils';

const modifyPersonalPhoneStep1NextButtonIsDisabled = ({
  prefix,
  repeatPrefix,
  phoneNumber,
  repeatPhoneNumber,
}: PersonalNumberStep1ActionsUtilsType): boolean => {
  const isValidPhone = isValidPhoneNumber({ phoneNumber, prefix });
  const isValidRepeatPhone = isValidPhoneNumber({
    phoneNumber: repeatPhoneNumber,
    prefix: repeatPrefix,
  });
  const isPhoneMatch = IsValidPhoneMatch({ prefix, repeatPrefix, phoneNumber, repeatPhoneNumber });

  return Boolean(!isValidPhone || !isValidRepeatPhone || !isPhoneMatch);
};

export { modifyPersonalPhoneStep1NextButtonIsDisabled };
