// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_STEP_PROPS } from './constants/financing-early-repayment-second-step-actions.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import FinancingEarlyRepaymentSecondStepActionsHook from './hooks/financing-early-repayment-second-step-actions.hook';
// Translations
import { STEPS } from 'containers/financing/views/early-repayment/translations/financing-early-repayment.translations';

const FinancingEarlyRepaymentSecondStepActionsComponent = (): React.ReactElement => {
  const { challenge, handleNextButtonClick } = FinancingEarlyRepaymentSecondStepActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_STEP_PROPS}
      rightButtonConfiguration={{
        onClick: handleNextButtonClick,
        disabled: !challenge,
        children: <FormattedMessageComponent id={STEPS.SECOND.NEXT} />,
      }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};

export default FinancingEarlyRepaymentSecondStepActionsComponent;
