// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { SecurityModuleComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_CHANGE_3D_REPEATED_CODE_PROPS } from './constants/card-settings-change-3d-step1-repeated-code.constants';
// Hooks
import { useCardSettingsChange3DStep1RepeatedCodeHook } from './hooks/card-settings-change-3d-step1-repeated-code.hook';
// Translations
import {
  REPEATED_CODE,
  ERROR_NOT_MATCH,
} from './translations/card-settings-change-3d-step1-repeated-code.translations';
import { HIDE_PIN, SHOW_PIN } from '../../translations/card-settings-change-3d-step1.translations';

const CardSettingsChange3dStep1RepeatedCodeComponent = (): React.ReactElement => {
  const {
    error,
    handleRepeated3DCodeInputClickTracking: onClick,
    handleSaveRepeated3dCodeInputChangeEvent: onChange,
    handleValidateRepeated3dCodeInputChangeEvent: onValidate,
    repeated3DCode: value,
    isRepeated3DCodeEnabled: enabled,
  } = useCardSettingsChange3DStep1RepeatedCodeHook();

  return (
    <SecurityModuleComponent
      {...CARD_SETTINGS_CHANGE_3D_REPEATED_CODE_PROPS}
      {...{ onClick, onChange, onValidate, value }}
      errorMessage={error && <FormattedMessageComponent id={ERROR_NOT_MATCH} />}
      label={<FormattedMessageComponent id={REPEATED_CODE} />}
      disabled={!enabled}
      translations={{
        HIDE_PASSWORD: <FormattedMessageComponent id={HIDE_PIN} />,
        SHOW_PASSWORD: <FormattedMessageComponent id={SHOW_PIN} />,
      }}
    />
  );
};

export { CardSettingsChange3dStep1RepeatedCodeComponent };
