// Vendors
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Enumerations
import { ProblemsEnumeration } from 'containers/transactions/views/report/enumerations/problems.enumeration';
// Handlers
import { TransactionsReportLandingHandlers } from '../handlers/transactions-report-landing.handlers';
import TransactionsReportLandingTrackingHandlers from '../handlers/transactions-report-landing.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsReportLandingReturnHookType } from './types/transactions-report-landing-return.hook.type';

export const useTransactionsReportLandingHook = (): TransactionsReportLandingReturnHookType => {
  const [selected, setSelected] = useState<ProblemsEnumeration | null>(null);

  return {
    ...TransactionsReportLandingHandlers({
      ...AppTrackingHook(TransactionsReportLandingTrackingHandlers),
      navigate: useNavigate(),
      selected,
      setSelected,
    }),
    selected,
  };
};
