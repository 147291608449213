// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { OtpTrackingHandlersType } from './types/otp.tracking.handlers.type';

const loadLastUserMessagesButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'loadAgain',
  },
  type: TrackingEventsEnum.LINK,
});

const OtpTrackingHandlers = (track: TrackBuilderType): OtpTrackingHandlersType => ({
  handleLoadLastUserMessagesButtonClickTracking: () =>
    track(loadLastUserMessagesButtonClickTrackingHandler()),
});

export default OtpTrackingHandlers;
