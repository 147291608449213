// Vendors
import React from 'react';
// Components
import AppPrivateComponent from '../private/app-private.component';
import AppProspectComponent from '../prospect/app-prospect.component';
import AppNoUserDataComponent from '../no-user-data/app-no-user-data.component';
import { ONBRedirectComponent } from 'components/onb-redirect';
// Hooks
import { EntrypointAuthorizationHook } from './hooks/entrypoint-authorization.hook';
// Utils
import { isUserLogged } from 'utils/session/session.utils';

const EntrypointAuthorizationComponent = (): React.ReactElement | null => {
  const { noNeedsUserData, onboardingIncomplete } = EntrypointAuthorizationHook();

  switch (true) {
    case onboardingIncomplete:
      return <ONBRedirectComponent />;
    case !isUserLogged():
      return <AppProspectComponent />;
    case noNeedsUserData:
      return <AppNoUserDataComponent />;
    default:
      return <AppPrivateComponent />;
  }
};

export default EntrypointAuthorizationComponent;
