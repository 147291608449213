// Vendors
import React from 'react';
// Components
import CardStatusComponent from 'components/card-status/card-status.component';
import { QuickActionsComponent } from 'components/quick-actions/quick-actions.component';
import { TableOrganismComponent } from 'organisms/table-organism/table-organism.component';
// Constants
import {
  FINANCING_LANDING_TABLE_PROPS,
  CARD_STATUS_PROPS,
  FINANCING_LANDING_QUICK_ACTIONS,
} from './constants/financing-landing.constants';
// Styles
import { FinancingLandingViewStyled } from './financing-landing.view.styled';
import {
  AppCardHeaderSectionComponentStyled,
  AppCardSectionComponentStyled,
  AppCardVerticalSectionComponentStyled,
} from 'styles/app.styled';

const FinancingLandingView = (): React.ReactElement => (
  <FinancingLandingViewStyled>
    <AppCardSectionComponentStyled>
      <AppCardVerticalSectionComponentStyled>
        <AppCardHeaderSectionComponentStyled>
          <CardStatusComponent {...CARD_STATUS_PROPS} />
          <QuickActionsComponent {...FINANCING_LANDING_QUICK_ACTIONS} />
        </AppCardHeaderSectionComponentStyled>
        <TableOrganismComponent
          {...{
            tableConfigurationProps: {
              ...FINANCING_LANDING_TABLE_PROPS,
            },
            filterId: 'financing',
          }}
        />
      </AppCardVerticalSectionComponentStyled>
    </AppCardSectionComponentStyled>
  </FinancingLandingViewStyled>
);

export { FinancingLandingView };
