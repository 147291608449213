// Types
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';

const getAllEnabledCountriesFromContinent = (
  countries: CardControlContinentCountryComponentType[]
): number =>
  countries.reduce(
    (acc, { enable }: CardControlContinentCountryComponentType) => (enable ? (acc += 1) : acc),
    0
  );

export { getAllEnabledCountriesFromContinent };
