// Configuration
import { getLineGraphConfiguration } from '../configurations/dashboard-points-line-graph.configurations';
// Hooks
import DashboardPointsLinegraphHook from '../hooks/dashboard-points-line-graph.component.hook';
// Types
import { DashboardLineGraphReturnComponentType } from './types/dashboard-points-line-graph.component.return.utils.types';
import { DataType } from './types/dashboard-points-line-graph.component.utils.types';

export const getLineGraphProps = (data: DataType): DashboardLineGraphReturnComponentType => {
  const { isMobile } = DashboardPointsLinegraphHook();
  const lineGraphPropsModified = getLineGraphConfiguration(isMobile);
  return {
    availableCreditPoints: data.availableCreditPoints,
    availablePoints: data.availablePoints,
    collectedCreditPoints: data.collectedCreditPoints,
    collectedPoints: data.collectedPoints,
    currency: data.currencyCode,
    isEmpty: data.collectedPoints === 0,
    redeemedCreditPoints: data.redeemedCreditPoints,
    redeemedPoints: data.redeemedPoints,
    translations: lineGraphPropsModified?.translations,
  };
};
