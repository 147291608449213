// Vendors
import { useEffect } from 'react';
// Handlers
import SecurityKeysAccessKeyChangeFirstStepHandlers from '../handlers/security-keys-access-key-change-first-step.handlers';
import SecurityKeysAccessKeyChangeFirstStepTrackingHandlers from '../handlers/security-keys-access-key-change-first-step.tracking.handlers';
import SecurityKeysAccessKeyChangeTrackingHandlers from 'containers/security-keys/views/access-key-change/handlers/security-keys-access-key-change.tracking.handlers';
// Hooks
import { SecurityKeysAccessKeyChangeHook } from 'containers/security-keys/views/access-key-change/hooks/security-keys-access-key-change.hook';
import { SecurityKeysAccessKeyChangeContextConsumerHook } from 'containers/security-keys/views/access-key-change/contexts/security-keys-access-key-change.context';
import useCbIntl from 'hooks/useCbIntl';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SecurityKeysAccessKeyChangeFirstStepHookType } from './types/security-keys-access-key-change-first-step.hook.type';

const SecurityKeysAccessKeyChangeFirstStepHook =
  (): SecurityKeysAccessKeyChangeFirstStepHookType => {
    const { accessKey, setAccessKey } = SecurityKeysAccessKeyChangeContextConsumerHook();
    const { formatMessage } = useCbIntl();
    const {
      handlePasswordPositionInputClickTracking,
      handleOnEnterFirstStepAccessKeyChangeTrackingHandler,
    } = AppTrackingHook(SecurityKeysAccessKeyChangeFirstStepTrackingHandlers);
    const { handleShowHideInputClickTracking } = AppTrackingHook(
      SecurityKeysAccessKeyChangeTrackingHandlers
    );

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      handleOnEnterFirstStepAccessKeyChangeTrackingHandler();
    }, []);

    return {
      ...SecurityKeysAccessKeyChangeHook(),
      ...SecurityKeysAccessKeyChangeFirstStepHandlers({
        setAccessKey,
      }),
      handlePasswordPositionInputClickTracking,
      handleShowHideInputClickTracking,
      accessKey,
      formatMessage,
    };
  };

export default SecurityKeysAccessKeyChangeFirstStepHook;
