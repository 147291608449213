// Types
import { CardSettingsContractHandlersReturnType } from './types/card-settings-contract-handlers-return.type';
import { CardSettingsContractHandlersPropsType } from './types/card-settings-contract-handlers.type';
// Resolvers
import { getPPIInfoResolver } from '../resolver/card-settings-contract.resolver';

const getPPIInfoHandler = async ({
  setError,
  setFetching,
  setPPIInfo,
}: CardSettingsContractHandlersPropsType): Promise<void> => {
  setFetching(true);
  const [response, error] = await getPPIInfoResolver();
  setFetching(false);

  if (error || !response) {
    setError(true);
    return;
  }

  setPPIInfo(response);
};

const CardSettingsContracHandlers = ({
  setError,
  setFetching,
  setPPIInfo,
}: CardSettingsContractHandlersPropsType): CardSettingsContractHandlersReturnType => ({
  handleGetPPIInfo: () => getPPIInfoHandler({ setError, setFetching, setPPIInfo }),
});

export { CardSettingsContracHandlers };
