// Constants
import { BASE_SERVICE_METHOD } from './constants/financing-payment-method-second-step.configurations.constants';
import { CHANGE_PAYMENT_METHOD_ENDPOINT } from 'containers/financing/views/payment-method/services/constants/financing-payment-method.services.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';

export const getChallengeConfiguration = ({
  contractId,
  challengeError,
  handleChallengeError,
  handleChallengeRetryButtonClick,
  handleChallengeSuccess,
  paymentMethodParams,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: CHANGE_PAYMENT_METHOD_ENDPOINT,
  baseServiceBuilder: {
    pathParams: { contractId },
    params: paymentMethodParams,
  },
  baseServiceMethod: BASE_SERVICE_METHOD,
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
