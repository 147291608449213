// Translations
import translations from './translations/settings-card-control-locations-continents.configurations.translations';
// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
// Utilities
import { getAllEnabledCountriesFromContinent } from '../utils/setting-card-control-locations-continents.utils';

const getMapSelectorDescription = ({
  countries,
  numberOfCountries,
}: Pick<CardControlContinentComponentType, 'countries' | 'numberOfCountries'>): string => {
  switch (true) {
    case getAllEnabledCountriesFromContinent(countries) === 0:
      return translations.NONE_COUNTRY_SELECTED;
    case getAllEnabledCountriesFromContinent(countries) === numberOfCountries:
      return translations.ALL_COUNTRY_SELECTED;
    default:
      return translations.SOME_COUNTRY_SELECTED;
  }
};

export { getMapSelectorDescription };
