// Vendors
import React from 'react';
// Components
import {
  CheckboxComponent,
  MessageComponent,
  ParagraphComponent,
  messageConstants,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import {
  RECEIPT_CHECKBOX_ERROR,
  RECEIPT_CHECKBOX_MESSAGE,
} from './translations/financing-insurances-contracting-lpi-step3-receipt.translations';
// Styles
import { FinancingInsurancesContractingLPIStep3ReceiptComponentStyled } from './financing-insurances-contracting-lpi-step3-receipt.component.styled';
// Types
import { FinancingInsurancesContractingLPIStep3ReceiptComponentType } from './types/financing-insurances-contracting-lpi-step3-receipt.component.type';

export const FinancingInsurancesContractingLPIStep3ReceiptComponent = ({
  docsReceived,
  docsReceivedError,
  handleToggleReceipt,
}: FinancingInsurancesContractingLPIStep3ReceiptComponentType): React.ReactElement => (
  <FinancingInsurancesContractingLPIStep3ReceiptComponentStyled>
    <CheckboxComponent
      value={docsReceived}
      onChange={handleToggleReceipt}
      title={<FormattedMessageComponent id={RECEIPT_CHECKBOX_MESSAGE} />}
    />
    {docsReceivedError && (
      <MessageComponent type={messageConstants.TYPES.ERROR}>
        <ParagraphComponent>
          <FormattedMessageComponent id={RECEIPT_CHECKBOX_ERROR} />
        </ParagraphComponent>
      </MessageComponent>
    )}
  </FinancingInsurancesContractingLPIStep3ReceiptComponentStyled>
);
