// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/iban-billing-sidebar.translations';

export default {
  IBAN_BILLING_SIDEBAR_PROPS: {
    items: [
      {
        description: translations.MESSAGE_1,
        label: translations.TITLE_1,
        testId: 'landing-iban-billing-sidebar-row-description-1',
        testing: {
          rowId: 'landing-iban-billing-sidebar-expandable-card-1',
          titleId: 'landing-iban-billing-sidebar-expandable-title-section-1',
        },
      },
      {
        description: translations.MESSAGE_2,
        label: translations.TITLE_2,
        testId: 'landing-iban-billing-sidebar-row-description-2',
        testing: {
          rowId: 'landing-iban-billing-sidebar-expandable-card-2',
          titleId: 'landing-iban-billing-sidebar-expandable-title-section-2',
        },
      },
      {
        description: translations.MESSAGE_3,
        label: translations.TITLE_3,
        testId: 'landing-iban-billing-sidebar-row-description-3',
        testing: {
          rowId: 'landing-iban-billing-sidebar-expandable-card-3',
          titleId: 'landing-iban-billing-sidebar-expandable-title-section-3',
        },
      },
    ],
    testId: 'landing-iban-billing',
    title: translations.TITLE,
  },
  PROFILE_SECURITY_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'landing-iban-billing-sidebar-message-01',
    size: paragraphConstants.SIZES.M,
  },
  PROFILE_SECURITY_SIDEBAR_MESSAGE_02_PROPS: {
    testId: 'landing-iban-billing-sidebar-message-02',
    size: paragraphConstants.SIZES.M,
  },
  PROFILE_SECURITY_SIDEBAR_MESSAGE_03_PROPS: {
    testId: 'landing-iban-billing-sidebar-message-03',
    size: paragraphConstants.SIZES.M,
  },
};
