// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import FinancingFastCashFirstStepActionsHook from './hooks/financing-fast-cash-first-step-actions.hook';
// Utils
import { isSameObject } from 'utils/app.utils';

const FinancingFastCashFirstStepActionsComponent = (): React.ReactElement => {
  const { amountValue, disableNextBtn } = FinancingFastCashFirstStepActionsHook();

  return (
    <WizardStepFooterComponent
      shouldShowModal={!isSameObject(amountValue, null)}
      rightButtonConfiguration={{ disabled: disableNextBtn }}
      targets={FIRST_STEP_TARGETS}
    />
  );
};

export default FinancingFastCashFirstStepActionsComponent;
