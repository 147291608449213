// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Constants
import { DEFAULT_RETRY_TIMER } from './constants/challenge-download-wrapper.hook.constants';
import { ChallengDownloadWrapperHandlers } from '../handlers/challenge-download-wrapper.handlers';
import { ChallengeDownloadWrapperTrackingHandlers } from '../handlers/challenge-download-wrapper.tracking.handlers';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useTimer from 'hooks/use-timer.hook';
// Event handlers
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ChallengeDownloadWrapperHookType } from './types/challenge-download-wrapper.hook.type';
import { ChallengeDownloadWrapperHookReturnType } from 'components/challenge-download-wrapper/hooks/types/challenge-download-wrapper-return.hook.type';
// Utils
import { getTimerLabelTranslationId } from './utils/challenge-download-wrapper.hook.utils';

const ChallengeDownloadWrapperComponentHook = ({
  challenge,
  challenged,
  challengeError,
  otpId,
  otpValue,
  setChallenge,
  setChallenged,
  setChallengeError,
  setIsDownloadAvailable,
  setOtpId,
  setOtpValue,
}: ChallengeDownloadWrapperHookType): ChallengeDownloadWrapperHookReturnType => {
  const { profile } = AppContextConsumerHook(),
    { telephones = [] } = profile?.contactDetails || {};
  const [error, setError] = useState<boolean | null>(null);
  const [fetching, setFetching] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();

  const { timer, isTimerActive, handleRestart } = useTimer(DEFAULT_RETRY_TIMER);

  const {
    handleRetryButtonClick,
    handleResendNumberOtpConfirmationButtonClick,
    handleSendNumberOtpConfirmationButtonClick,
  } = ChallengDownloadWrapperHandlers({
    ...AppTrackingHook(ChallengeDownloadWrapperTrackingHandlers),
    handleRestart,
    otpId,
    otpValue,
    progressId: challenge?.progressId ?? '',
    setChallenge,
    setChallengeError,
    setChallenged,
    setIsDownloadAvailable,
    setError,
    setFetching,
    setOtpId,
    setOtpValue,
  });

  return {
    challenge,
    disabled: challenged || (challengeError !== null && challengeError !== false),
    fetching,
    formatMessage,
    handleResendOtp: handleResendNumberOtpConfirmationButtonClick,
    handleSendChallenge: handleSendNumberOtpConfirmationButtonClick,
    handleTryAgain: handleRetryButtonClick,
    isTimerActive,
    onChangeChallenge: setOtpValue,
    telephones,
    timer,
    timerLabelTranslationId: getTimerLabelTranslationId(timer),
    value: otpValue,
  };
};

export { ChallengeDownloadWrapperComponentHook };
