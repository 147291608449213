// Contexts
import { usePersonalAddressContextConsumerHook } from 'containers/personal/views/address/contexts/personal-address.context';
// Handlers
import { PersonalAddressStep1Handlers } from '../handlers/personal-address-step1.handlers';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
// Types
import { PersonalAddressStep1HookReturnType } from './types/personal-address-step1-return.hook.type';

export const usePersonalAddressStep1Hook = (): PersonalAddressStep1HookReturnType => {
  const { isEditing, newAddress, setError, setIsEditing, setNewAddress } =
      usePersonalAddressContextConsumerHook(),
    { address } = useAddressHook();

  return {
    ...PersonalAddressStep1Handlers({
      address,
      setError,
      setIsEditing,
      setNewAddress,
    }),
    address,
    isEditing,
    newAddress,
  };
};
