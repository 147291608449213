// Vendors
import React from 'react';
// Types
import type { FormattedMessageBasePropsType } from './types/formatted-message-base-props.type';

const FormattedMessageBaseComponent = ({
  children,
  id,
  isShowingKeys,
}: React.PropsWithChildren<FormattedMessageBasePropsType>): React.ReactElement => {
  if (!isShowingKeys) {
    return children as React.ReactElement;
  }
  return <>{id}</>;
};

export default FormattedMessageBaseComponent;
