// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Translations
import translations from 'containers/financing/views/landing/components/row/components/details/translations/financing-landing-table-row-details.component.translations';

export default {
  FINANCING_LANDING_ROW_DETAILS_STATUS_MESSAGE_PROPS: {
    testId: 'financing-landing-row-details-status-message',
  },
};

const statusMessages = {
  [EppElementStatusEnumeration.CANCELLED]: translations.CANCELLED_STATUS,
  [EppElementStatusEnumeration.PARTIAL_RETURNED]: translations.PARTIAL_RETURN_STATUS,
  [EppElementStatusEnumeration.RETURNED]: translations.RETURN_STATUS,
};

export { statusMessages };
