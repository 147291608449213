// Resolvers
import { createTransactionEppPlanResolver } from '../resolvers/transactions-epp-step2.resolver';
// Types
import {
  TransactionsEppStep2BuilderHandlersType,
  TransactionsEppStep2MethodsHandlersType,
} from './types/transactions-epp-step2.handlers.type';

const confirmCreateTransactionEppPlanButtonClickHandler = async ({
  handleConfirmCreateTransactionEppPlanButtonClickTracking,
  setFetching,
  setResult,
  ...apiBuilderProps
}: TransactionsEppStep2BuilderHandlersType): Promise<void> => {
  setFetching(true);
  handleConfirmCreateTransactionEppPlanButtonClickTracking();

  const [, error] = await createTransactionEppPlanResolver(apiBuilderProps);
  setResult(!error);
};

const TransactionsEppStep2Handlers = (
  props: TransactionsEppStep2BuilderHandlersType
): TransactionsEppStep2MethodsHandlersType => ({
  handleConfirmCreateTransactionEppPlanButtonClick: () =>
    confirmCreateTransactionEppPlanButtonClickHandler(props),
});

export { TransactionsEppStep2Handlers };
