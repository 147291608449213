// Vendors
import React from 'react';
// Components
import SettingsCardControlToggleComponent from 'containers/card-settings/views/card-control/components/toggle/settings-card-control-toggle.component';
// Constants
import constants from 'containers/card-settings/views/card-control/components/locations/components/toggle/constants/settings-card-control-location-toggle.constants';
// Types
import { SettingsCardControlLocationsContinentsToggleComponentType } from 'containers/card-settings/views/card-control/components/locations/components/continents/components/toggle/types/settings-card-control-locations-continents-toggle.component.type';
// Utilities
import { getSwitchContinentCountriesList } from './utils/setting-card-control-locations-continents-toggle.utils';
import { someCountryInContinentIsConfigured } from 'containers/card-settings/views/card-control/components/locations/utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsContinentsToggleComponent = ({
  continents,
  countries,
  countryId,
}: SettingsCardControlLocationsContinentsToggleComponentType): React.ReactElement<SettingsCardControlLocationsContinentsToggleComponentType> => (
  <SettingsCardControlToggleComponent
    {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_TOGGLE_PROPS}
    checked={someCountryInContinentIsConfigured(countries)}
    toggleId={countryId}
    value={getSwitchContinentCountriesList({ continents, countries })}
  />
);

export default SettingsCardControlLocationsContinentsToggleComponent;
