// Types
import {
  PersonalEmailLogoutModalBuilderHandlersType,
  PersonalEmailLogoutModalBuilderReturnHandlersType,
} from './types/personal-emal-logout-modal.handlers.type';

const logoutFromChangeEmailButtonClickHandler = async ({
  handleLogoutFromChangeEmailButtonClickTracking,
  handleMakeUserLogoutEvent,
  setFetching,
}: PersonalEmailLogoutModalBuilderHandlersType): Promise<void> => {
  handleLogoutFromChangeEmailButtonClickTracking();
  setFetching(true);

  await handleMakeUserLogoutEvent();
};

const PersonalEmailLogoutModalHandlers = (
  props: PersonalEmailLogoutModalBuilderHandlersType
): PersonalEmailLogoutModalBuilderReturnHandlersType => ({
  handleLogoutFromChangeEmailButtonClick: () => logoutFromChangeEmailButtonClickHandler(props),
});

export default PersonalEmailLogoutModalHandlers;
