// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  ERROR_TOAST_CONFIGURATION: {
    type: messageConstants.TYPES.ERROR,
  },
  SUCCESS_TOAST_CONFIGURATION: {
    type: messageConstants.TYPES.SUCCESS,
  },
};
