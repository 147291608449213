const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyPhoneNumber.step3.';

const TITLE = `${DOMAIN}title`,
  SUBTITLE = `${DOMAIN}subtitle`,
  DESCRIPTION = `${DOMAIN}description`,
  CURRENT_PHONE = `${DOMAIN}currentPhoneLabel`,
  WITH_EMAIL = `${DOMAIN}email`,
  CURRENT_NEW = `${DOMAIN}newPhoneLabel`,
  NEXT = `${DOMAIN}nextButton`,
  CANCEL = `${DOMAIN}cancelButton`;

export { TITLE, SUBTITLE, DESCRIPTION, WITH_EMAIL, CURRENT_PHONE, CURRENT_NEW, NEXT, CANCEL };
