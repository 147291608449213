// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks

import FinancingPaymentMethodFirstStepActionsHook from './hooks/financing-payment-method-first-step-actions.hook';
// Utils
import {
  hasDifferentValues,
  IsSelectedOptionInvalid,
} from './hooks/utils/financing-payment-method-first-step-actions.hook.utils';
import { getNewPaymentMethod } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

const FinancingPaymentMethodFirstStepActionsComponent = (): React.ReactElement => {
  const {
    accountBalancePercentage,
    fee,
    fixedPaymentAmount,
    handleNextButtonClick,
    paymentMethod,
    paymentMethodCode,
    revolvingMethod,
  } = FinancingPaymentMethodFirstStepActionsHook();

  const differentValues = hasDifferentValues({
    accountBalancePercentage,
    fee,
    fixedPaymentAmount,
    newPaymentMethod: getNewPaymentMethod({
      paymentMethod,
      revolvingMethod,
    }),
    paymentMethod,
    paymentMethodCode,
    revolvingMethod,
  });

  return (
    <WizardStepFooterComponent
      rightButtonConfiguration={{
        onClick: handleNextButtonClick,
        disabled: IsSelectedOptionInvalid({
          accountBalancePercentage,
          fee,
          fixedPaymentAmount,
          paymentMethod,
          paymentMethodCode,
          revolvingMethod,
        }),
      }}
      shouldShowModal={differentValues}
    />
  );
};

export default FinancingPaymentMethodFirstStepActionsComponent;
