// Constants
import { ADDRESS_DETAIL_MAPPER } from './constants/parse-address-details.util.constants';
// Types
import { AddressDetails } from 'hooks/useAddressPredictions/types/use-address-predictions.hook.type';
import { GeocoderAddressComponent } from 'hooks/useAddressPredictions/types/google-api.type';

export const parseAddressDetails = (
  address_components: GeocoderAddressComponent[]
): AddressDetails => {
  let addressDetails: AddressDetails = {};
  for (const component of address_components) {
    const { long_name, types } = component;

    if (ADDRESS_DETAIL_MAPPER[types[0]]) {
      addressDetails = {
        ...addressDetails,
        [ADDRESS_DETAIL_MAPPER[types[0]]]: long_name,
      };
    }
  }
  return addressDetails;
};
