// Vendors
import {
  MiscellaneousCurrenciesEnum,
  MiscellaneousCurrencyConstants,
} from '@openbank/cf-ui-static-data';
// Constants
import { NUMBER } from './number.constants';

const EURO_FORMAT_OPTIONS = {
  ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
  currency: MiscellaneousCurrenciesEnum.EURO,
  minimumFractionDigits: NUMBER.TWO,
};

export { EURO_FORMAT_OPTIONS };
