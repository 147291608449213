// Enumerations
import { SecurityCheckModalStepEnumerations } from '../hooks/enumerations/security-check-modal-step.enumerations';
// Translations
import {
  FIRST_STEP,
  LINK,
  SECOND_STEP,
  TITLE,
} from '../translations/security-check-modal.translations';
// Types
import { GetModalConfigurationType } from './types/get-modal-configuration.type';
import { GetModalConfigurationReturnType } from './types/get-modal-configuration-return.type';

const getModalConfiguration = ({
  challenge,
  formatMessage,
  handleOnCloseModal,
  handleNextModalStep,
  handleFetchCardTransactions,
  modalStep,
}: GetModalConfigurationType): GetModalConfigurationReturnType => {
  const stepConfig = {
    [SecurityCheckModalStepEnumerations.FIRST]: {
      children: formatMessage({ id: FIRST_STEP.BUTTON }),
      disabled: false,
      onClick: handleNextModalStep,
    },
    [SecurityCheckModalStepEnumerations.SECOND]: {
      children: formatMessage({ id: SECOND_STEP.BUTTON }),
      disabled: !challenge?.progressId,
      onClick: handleFetchCardTransactions,
    },
  };
  return {
    title: formatMessage({ id: TITLE }),
    linkConfiguration: {
      children: formatMessage({ id: LINK }),
      disabled: false,
      onClick: handleOnCloseModal,
    },
    primaryButtonConfiguration: {
      ...stepConfig[modalStep],
    },
    onHeaderClose: handleOnCloseModal,
  };
};

export { getModalConfiguration };
