// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Enums
import { MonthsEnumeration } from 'enumerations/months.enumeration';
// Types
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';
// Translations
import monthsTranslations from 'translations/months.translations';

export const getMonthOptions = (
  formatMessage: UseCbIntlReturnType['formatMessage']
): React.ComponentProps<typeof InputDropdownComponent>['options'] =>
  Object.values(MonthsEnumeration).map(month => ({
    label: formatMessage({ id: monthsTranslations[month] }),
    value: month,
  }));

export const getYearOptions = (): React.ComponentProps<
  typeof InputDropdownComponent
>['options'] => {
  const year = new Date().getFullYear();
  const years = Array.from({ length: 50 }, (_, i) => year - i);

  return years.map(year => ({
    label: year.toString(),
    value: year,
  }));
};
