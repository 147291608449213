// Vendors
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
import { CardSettingsDividerTitleComponent } from 'containers/card-settings/views/landing/components/divider-title/card-settings-divider-title.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_DETAILS_CARD_PROPS,
  CARD_SETTINGS_DETAILS_DIVIDER_PROPS,
  CARD_SETTINGS_DETAILS_EXPIRY_PROPS,
  CARD_SETTINGS_DETAILS_INFO_PROPS,
  CARD_SETTINGS_DETAILS_STATEMENTS_PROPS,
} from './constants/card-settings-details.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { useCardSettingsDetailsHook } from './hooks/card-settings-details.hook';
// Styles
import { CardSettingSectionDividerComponentStyled } from '../../card-settings-landing.container.styled';
// Translations
import { TITLE } from './translations/card-settings-details.translations';
// Utils
import { isProduction } from 'utils/app.utils';

const CardSettingsDetailsComponent = (): React.ReactElement => {
  const {
    formatDate,
    expiryDate,
    handleCopyCardNumberLinkClick: onClick,
    pan,
  } = useCardSettingsDetailsHook();

  return (
    <SectionDividerComponent
      {...CARD_SETTINGS_DETAILS_DIVIDER_PROPS}
      title={<CardSettingsDividerTitleComponent title={TITLE} />}
    >
      <CardSettingSectionDividerComponentStyled>
        <CardSettingsResumeLinkComponent {...CARD_SETTINGS_DETAILS_STATEMENTS_PROPS} />
        <CardSettingsResumeLinkComponent
          {...CARD_SETTINGS_DETAILS_CARD_PROPS}
          links={[{ ...CARD_SETTINGS_DETAILS_CARD_PROPS.links[0], onClick }]}
          value={pan}
        />
        <CardSettingsResumeLinkComponent
          {...CARD_SETTINGS_DETAILS_EXPIRY_PROPS}
          value={formatDate(expiryDate, MiscellaneousDateConstants.FORMAT_MM_YYYY)}
        />
        {!isProduction() && (
          <CardSettingsResumeLinkComponent {...CARD_SETTINGS_DETAILS_INFO_PROPS} />
        )}
      </CardSettingSectionDividerComponentStyled>
    </SectionDividerComponent>
  );
};

export { CardSettingsDetailsComponent };
