// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_PIN_TITLE_PROPS = {
    testId: 'card-settings-check-pin-title',
    tag: titleConstants.TAGS.H4,
  },
  CARD_SETTINGS_CHECK_PIN_DESCRIPTION_PROPS = {
    testId: 'card-settings-check-pin-description',
  };

export { CARD_SETTINGS_CHECK_PIN_TITLE_PROPS, CARD_SETTINGS_CHECK_PIN_DESCRIPTION_PROPS };
