// Constants
import {
  PART_TYPE_PAYLOAD,
  CLICK_CROSS_MODAL_PAYLOAD,
  OPEN_MODAL_PAYLOAD,
  CLOSE_MODAL_PAYLOAD,
  ACCEPT_MODAL_PAYLOAD,
  IS_CURRENT_CONECTION_PAYLOAD,
  IS_TRUSTED_CONECTION_PAYLOAD,
  ACCEPT_TYPE_PAYLOAD
} from './constants/device-modal.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { DeviceModalTrackingHandlersReturnType } from './types/device-modal.tracking.handlers.return.type';

const modalOpenTrackingHandler = ({ type }) : TrackingLinkEventReturnType => ({
  payload: {
    ...PART_TYPE_PAYLOAD[type],
    ...OPEN_MODAL_PAYLOAD
  },
  type: TrackingEventsEnum.LINK
});

const modalCrossTrackingHandler = ({ type }) : TrackingLinkEventReturnType => ({
  payload: {
    ...PART_TYPE_PAYLOAD[type],
    ...CLICK_CROSS_MODAL_PAYLOAD
  },
  type: TrackingEventsEnum.LINK
});

const modalCloseTrackingHandler = ({ type }) : TrackingLinkEventReturnType => ({
  payload: {
    ...PART_TYPE_PAYLOAD[type],
    ...CLOSE_MODAL_PAYLOAD
  },
  type: TrackingEventsEnum.LINK
});

const modalAcceptTrackingHandler = ({ type }) : TrackingLinkEventReturnType => ({
  payload: {
    ...ACCEPT_MODAL_PAYLOAD,
    ...PART_TYPE_PAYLOAD[type],
    ...ACCEPT_TYPE_PAYLOAD[type]
  },
  type: TrackingEventsEnum.LINK
});

const isCurrentConectionTrackingHandler = () : TrackingLinkEventReturnType => ({
  payload: IS_CURRENT_CONECTION_PAYLOAD,
  type: TrackingEventsEnum.LINK
});

const isTrustedDeviceTrackingHandler = () : TrackingLinkEventReturnType => ({
  payload: IS_TRUSTED_CONECTION_PAYLOAD,
  type: TrackingEventsEnum.LINK
});

const DeviceModalTrackingHandlers = (
  track: (param: unknown) => void
): DeviceModalTrackingHandlersReturnType => ({
  onModalOpenTracking: (type) => track(modalOpenTrackingHandler({ type })),
  onModalCrossTracking: (type) => track(modalCrossTrackingHandler({ type })),
  onModalCloseTracking: (type) => track(modalCloseTrackingHandler({ type })),
  onModalAcceptTracking: (type) => track(modalAcceptTrackingHandler({ type })),
  isCurrentConectionTracking: () => track(isCurrentConectionTrackingHandler()),
  isTrustedDeviceTracking: () => track(isTrustedDeviceTrackingHandler())
});

export { DeviceModalTrackingHandlers };