// Enumerations
import { CaseTypeEnum } from 'containers/transactions/views/report/enumerations/case-type.enumeration';
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
import { MotiveDalEnum } from 'containers/transactions/views/report/enumerations/motive-dal.enumeration';

const DISPUTE_PARAMS = {
  caseType: CaseTypeEnum.ATM_DISPUTE_MANAGEMENT,
  motiveDal: MotiveDalEnum.RECEIVED_DIFF_AMOUNT_THAN_REQUESTED,
  blockCard: false,
};

export { DISPUTE_PARAMS };
