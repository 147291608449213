const FINANCING_PPI_LANDING_CONTRACT_PPI_TRACKING_PROPS = {
    payload: {
      eventAction: 'clickButton',
      eventLabel: 'contractInsurance',
    },
  },
  FINANCING_PPI_LANDING_CANCEL_PPI_CONTRACT_TRACKING_PROPS = {
    payload: {
      eventAction: 'clickButton',
      eventLabel: 'cancelInsurance',
    },
  },
  FINANCING_PPI_LANDING_VIEW_TERMS_TRACKING_PROPS = {
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'viewTermsAndConditions',
    },
  };

export {
  FINANCING_PPI_LANDING_CONTRACT_PPI_TRACKING_PROPS,
  FINANCING_PPI_LANDING_CANCEL_PPI_CONTRACT_TRACKING_PROPS,
  FINANCING_PPI_LANDING_VIEW_TERMS_TRACKING_PROPS,
};
