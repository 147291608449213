// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TransactionsLandingRowDetailsReportBuilderReturnTrackingHandlersType } from './types/transactions-landing-row-details-report-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const editTransactionElementCategoryLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'reportAProblem',
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsLandingRowDetailsReportTrackingHandlers = (
  track: TrackBuilderType
): TransactionsLandingRowDetailsReportBuilderReturnTrackingHandlersType => ({
  handleReportTransactionProblemLinkClickTracking: () =>
    track(editTransactionElementCategoryLinkClickTrackingHandler()),
});

export default TransactionsLandingRowDetailsReportTrackingHandlers;
