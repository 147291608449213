// Enumerations
import { NewEmailErrorIdEnumeration } from '../enumerations/personal-email-step1-new.enumeration';
// Types
import { PersonalEmailStep1NewBuilderReturnHandlersType } from './types/personal-email-step1-new-builder-return.handlers.type';
import { PersonalEmailStep1NewBuilderHandlersType } from './types/personal-email-step1-new-builder.handlers.type';
import { PersonalEmailStep1NewMethodBuilderHandlersType } from './types/personal-email-step1-new-method-builder.handlers.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';
import { isEmptyField } from 'containers/personal/views/email/components/step1/utils/personal-email-step1.utils';

const saveNewModifyEmailInputEventHandler = ({
  email,
  setInputError,
  setNewEmail,
}: PersonalEmailStep1NewMethodBuilderHandlersType): void => {
  setInputError(null);
  setNewEmail(email);
};

const validateNewModifyEmailInputEventHandler = ({
  handleModifyEmailNewInputErrorTracking,
  newEmail,
  setInputError,
}: Omit<PersonalEmailStep1NewBuilderHandlersType, 'setNewEmail'>): void => {
  if (isEmptyField(newEmail)) {
    setInputError(NewEmailErrorIdEnumeration.EMPTY);
  } else if (!isValidEmail(newEmail)) {
    handleModifyEmailNewInputErrorTracking();
    setInputError(NewEmailErrorIdEnumeration.FORMAT);
  } else {
    setInputError(null);
  }
};

const PersonalEmailStep1NewHandlers = ({
  handleModifyEmailNewInputErrorTracking,
  newEmail,
  setInputError,
  setNewEmail,
}: PersonalEmailStep1NewBuilderHandlersType): PersonalEmailStep1NewBuilderReturnHandlersType => ({
  handleSaveNewModifyEmailInputEvent: (email: string) =>
    saveNewModifyEmailInputEventHandler({ email, setInputError, setNewEmail }),
  handleValidateNewModifyEmailInputEvent: () =>
    validateNewModifyEmailInputEventHandler({
      handleModifyEmailNewInputErrorTracking,
      newEmail,
      setInputError,
    }),
});

export default PersonalEmailStep1NewHandlers;
