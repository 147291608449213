// Resolvers
import { getMandateInfoResolver } from '../resolvers/change-iban.resolvers';
// Types
import { ChangeIbanBuilderHandlersType } from './types/change-iban.handlers.type';
import { ChangeIbanBuilderReturnHandlersType } from './types/change-iban-return.handlers.type';

const getMandateInfoEventHandler = async ({
  handleDisplayChangeIbanPageTracking,
  setFetching,
  setSepaMandate,
}): Promise<void> => {
  handleDisplayChangeIbanPageTracking();
  setFetching(true);

  const [response, error] = await getMandateInfoResolver();

  if (error) {
    setFetching(false);
    return;
  }

  setSepaMandate(response);
  setFetching(false);
};

const ChangeIbanHandlers = (
  props: ChangeIbanBuilderHandlersType
): ChangeIbanBuilderReturnHandlersType => ({
  handleGetMandateInfoEvent: () => getMandateInfoEventHandler(props),
});

export default ChangeIbanHandlers;
