// Constants
import { ENOUGH_LENGTH_TO_BLUR } from './constants/personal-email-new-email.utils.constants';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';

const generateAsteriskStringChain = (word: string): string =>
  new Array(word.length - 2).fill(CharactersEnum.ASTERISK).join('');

const isEnoughLengthToBlur = (word: string): boolean => word.length > ENOUGH_LENGTH_TO_BLUR;

const blurFirstEmailPart = (email: string): string =>
  isEnoughLengthToBlur(email)
    ? email[0] + generateAsteriskStringChain(email) + email[email.length - 1]
    : email;

const blurEmail = (email: string): string => {
  const splitEmail = email.split(CharactersEnum.AT);

  return `${blurFirstEmailPart(splitEmail[0])}${CharactersEnum.AT}${splitEmail[1]}`;
};

export { blurEmail };
