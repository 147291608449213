// Services
import {
  cancelProcessService,
  backToPreviousStepResolverService,
  getInitCaseService,
  getPPIInfoService,
} from '../services/step-0-init.services';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Types
import { BackToPreviousStepResolverPropsType } from '../services/types/step-0-back-to-previous-step.type';
import { CancelProcessResponseType } from '../services/types/step-0-cancel-process.type';
import { CaseInitResponseType } from '../services/types/step-0-init.type';
import { GetInitiCaseResolverPropsType } from './types/get-init-case.resolver.type';
import { PPIInfoResponseType } from '../services/types/ppi-info.type';

const getInitCaseResolver = async ({
  cardId,
  callbackWebId,
}: GetInitiCaseResolverPropsType): Promise<[CaseInitResponseType | null, boolean]> => {
  const [response, { status }] = await getInitCaseService({ cardId, callbackWebId });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

const cancelProcessResolver = async (
  caseId: string
): Promise<[CancelProcessResponseType] | [null, boolean]> => {
  const [response, { status }] = await cancelProcessService(caseId);

  return httpStatusUtils.isSuccessStatus(status) ? [response] : [null, true];
};

const getPPIInfoResolver = async (): Promise<[PPIInfoResponseType | null, boolean]> => {
  const [response, { status }] = await getPPIInfoService();
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

const backToPreviousStepResolver = async (
  props: BackToPreviousStepResolverPropsType
): Promise<[CaseInitResponseType | null, boolean]> => {
  const [response, { status }] = await backToPreviousStepResolverService(props);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

export {
  getInitCaseResolver,
  cancelProcessResolver,
  getPPIInfoResolver,
  backToPreviousStepResolver,
};
