// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import RequestPortabilityCheckboxesTrackingHandlers from '../handlers/request-portability-checkboxes.tracking.handlers';
import RequestPortabilityCheckboxesHandlers from '../handlers/request-portability-checkboxes.handlers';
// Hooks
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RequestPortabilityCheckboxesHookType } from './types/request-portability-checkboxes.hook.type';

const RequestPortabilityCheckboxesHook = (): RequestPortabilityCheckboxesHookType => {
  const { formatMessage } = useCbIntl();
  const { currentCard, cards } = AppContextConsumerHook();

  const {
    beneficiaryCardCheckbox,
    mainCardCheckbox,
    movementsCheckbox,
    setBeneficiaryCardCheckbox,
    setMainCardCheckbox,
    setMovementsCheckbox,
    portabilityParams,
    setPortabilityParams,
  } = AccessPortabilityContextConsumerHook();

  const {
      handleBeneficiaryCardChangeCheckboxTracking,
      handleMainCardChangeCheckboxTracking,
      handleMovementsChangeCheckboxTracking,
    } = AppTrackingHook(RequestPortabilityCheckboxesTrackingHandlers),
    {
      handleMovementsCheckBoxChangeEvent,
      handleMainCardCheckBoxChangeEvent,
      handleBeneficiaryCardCheckBoxChangeEvent,
    } = RequestPortabilityCheckboxesHandlers({
      beneficiaryCardCheckbox,
      currentCard,
      handleBeneficiaryCardChangeCheckboxTracking,
      handleMainCardChangeCheckboxTracking,
      handleMovementsChangeCheckboxTracking,
      mainCardCheckbox,
      movementsCheckbox,
      portabilityParams,
      setBeneficiaryCardCheckbox,
      setMainCardCheckbox,
      setMovementsCheckbox,
      setPortabilityParams,
    });

  return {
    formatMessage,
    handleMovementsCheckBoxChangeEvent,
    handleMainCardCheckBoxChangeEvent,
    handleBeneficiaryCardCheckBoxChangeEvent,
    mainCardCheckbox,
    movementsCheckbox,
    beneficiaryCardCheckbox,
    cards,
  };
};

export default RequestPortabilityCheckboxesHook;
