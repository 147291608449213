// Types
import { TransactionsEppStep1SimulationSummaryHookUtilsType } from './types/transactions-epp-step1-simulation-summary.hook.utils.type';

const getEppInstallmentPlanLoanAmount = ({
  loanAmount,
  totalAmount,
}: TransactionsEppStep1SimulationSummaryHookUtilsType): number =>
  Math.abs(loanAmount || totalAmount || 0);

export { getEppInstallmentPlanLoanAmount };
