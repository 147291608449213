// Constants
import { ERROR_PAYLOAD, ON_RETRY_PAYLOAD } from './contants/device-sessions.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { DeviceSessionsTrackingHandlersReturnType } from './types/device-sessions.tracking.handlers.return.type';

const onRetryTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ON_RETRY_PAYLOAD,
  type: TrackingEventsEnum.LINK
});

const onErrorTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ERROR_PAYLOAD,
  type: TrackingEventsEnum.LINK
});

const DeviceSessionsTrackingHandlers = (
    track: (param: unknown) => void
  ): DeviceSessionsTrackingHandlersReturnType => ({
    onRetryTracking: () => track(onRetryTrackingHandler()),
    onErrorTracking: () => track(onErrorTrackingHandler())
});

export { DeviceSessionsTrackingHandlers };