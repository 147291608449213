// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const NOTIFICATIONS_SETTINGS_VIEW_TITLE_PROPS = {
  testId: 'notifications-settings-view-title-section',
  tag: titleConstants.TAGS.H4,
},
TOOLTIP_ASSET_PROPS = {
  id: 'info',
  colors: {
    fill: 'linkPrimaryA',
  },
  testId: 'notifications-settings-tooltip-asset',
},
NOTIFICATIONS_SETTINGS_TOOLTIP_PROPS = {
  showCloseIcon: true
},
DEFAULT_TEST_ID={
  testId: 'notifications-settings-title',
} ;

export { NOTIFICATIONS_SETTINGS_VIEW_TITLE_PROPS, TOOLTIP_ASSET_PROPS, NOTIFICATIONS_SETTINGS_TOOLTIP_PROPS, DEFAULT_TEST_ID};
