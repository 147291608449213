// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { LabelsLinkCardBuilderReturnHandlersType } from './types/labels-link-card-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const labelsLinkCardLinkClickTrackingHandler = (url?: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: url ? `navigateTo:${url}` : 'labelLinkCardLink',
  },
  type: TrackingEventsEnum.LINK,
});

const LabelsLinkCardTrackingHandlers = (
  track: TrackBuilderType
): LabelsLinkCardBuilderReturnHandlersType => ({
  handleLabelsLinkCardLinkClickTracking: (url?: string) =>
    track(labelsLinkCardLinkClickTrackingHandler(url)),
});

export default LabelsLinkCardTrackingHandlers;
