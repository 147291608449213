// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { PageParamsHandlerType } from './types/notifications-landing-page-params.handler.type';
import { NotificationsLandingHandlersType } from './types/notifications-landing.handlers.type';
import { NotificationsLandingReturnHandlersType } from './types/notifications-landings-return.handlers.type';
import { goToNotificationsSettingsHandlerType } from './types/notifications-settings.handler.type';
// Resolvers
import { notificationsDetailsResolver } from 'resolvers/notifications.resolvers';

const makeCallToGetNotificationsByFilters = async ({
  setIsLoading,
  setPaginationState,
  setDataState,
  setError,
  filters,
}: NotificationsLandingHandlersType): Promise<void> => {
  setIsLoading(true);
  setError(false);

  const [response, error] = await notificationsDetailsResolver({
    filters,
  });

  if (error && setError) {
    setError(true);
    return setIsLoading(false);
  }

  setPaginationState(response.pagination);
  setDataState([...response.data]);

  setIsLoading(false);
};

const makeCallToGetNextPage = async ({
  dataState,
  page = 0,
  paginationState,
  filters,
  setDataState,
  setError,
  setIsLoading,
  setIsLoadingNextPage,
  setPaginationState,
}: NotificationsLandingHandlersType): Promise<void> => {
  setIsLoadingNextPage(true);

  setError(false);

  const [response, error] = await notificationsDetailsResolver({
    params: {
      page: paginationState.pageNumber + 1,
      size: paginationState.pageSize,
    },
    filters,
  });

  if (error && setError) {
    setError(true);
    return !page ? setIsLoading(false) : setIsLoadingNextPage(false);
  }

  setPaginationState(response.pagination);
  setDataState([...dataState, ...response.data]);

  setIsLoadingNextPage(false);
};

const onOpenFiltersModalHandler = ({
  setIsShowFilterModal,
  handleOpenFiltersNotificationsTracking,
}) => {
  handleOpenFiltersNotificationsTracking();
  setIsShowFilterModal(true);
};

const onUserEnterHandler = ({
  error,
  handleUserEnterOnNotificationsLandingErrorTracking,
  handleUserEnterOnNotificationsLandingTracking,
}) => {
  error !== undefined && error
    ? handleUserEnterOnNotificationsLandingErrorTracking()
    : handleUserEnterOnNotificationsLandingTracking();
};

const onPaginationHandler = ({ page, setPage, isLoadingNextPage }: PageParamsHandlerType) =>
  !isLoadingNextPage && setPage(page + 1);

const goToNotificationsSettingsHandler = ({
  navigate,
  handleGoToNotificationsSettingsTracking,
}: goToNotificationsSettingsHandlerType) => {
  handleGoToNotificationsSettingsTracking();
  navigate(RoutesEnumeration.NOTIFICATIONS_SETTINGS);
};

const NotificationsLandingHandlers = (
  props: NotificationsLandingHandlersType
): NotificationsLandingReturnHandlersType => ({
  handleNotificationNextPage: () =>
    makeCallToGetNextPage({ ...props, filters: props.formValuesParams }),
  handleSetPagination: () =>
    onPaginationHandler({
      page: props.page || 0,
      setPage: props.setPage,
      isLoadingNextPage: props.isLoadingNextPage,
    }),
  handleShowFilterModal: () => onOpenFiltersModalHandler(props),
  handleFetchNotifications: () =>
    makeCallToGetNotificationsByFilters({ ...props, filters: props.formValuesParams }),
  onUserEnter: error => onUserEnterHandler({ ...props, error }),
  handleGoToNotificationsSettings: () => goToNotificationsSettingsHandler({ ...props }),
});

export default NotificationsLandingHandlers;
