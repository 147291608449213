// Translations
import { ERROR_DESCRIPTION, ERROR_TITLE } from '../translations/cards.translations';

const FETCH_ERROR_PROPS = {
  testing: {
    loaderTestId: 'fetch-error-component-loader',
  },
};

const ERROR_PROPS = {
  assetId: 'oops-alternative',
};

const ERROR_DEFAULT_TRANSLATIONS = {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
};

const DEFAULT_FETCHING_VALUE = false;

const TRY_AGAIN_BUTTON_PROPS = {
  testId: 'error-wrapper-try-again-button',
};

export {
  DEFAULT_FETCHING_VALUE,
  ERROR_DEFAULT_TRANSLATIONS,
  ERROR_PROPS,
  FETCH_ERROR_PROPS,
  TRY_AGAIN_BUTTON_PROPS,
};
