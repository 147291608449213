// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const OtpContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingM};
  }
`;
OtpContainerStyled.displayName = 'OtpContainerStyled';

export { OtpContainerStyled };
