// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import {
  DEVICES_ROUTE_PROPS,
  DEVICE_MANAGER_ROUTE_PROPS
} from './constants/security-keys-devices-router.constants';
// Containers
const DevicesManagerView = lazy(
  () => import('containers/security-keys/views/devices/views/devices-manager/devices.manager.view')
);
const DeviceManagerDetailView = lazy(
  () => import('containers/security-keys/views/devices/views/device-detail/device-detail.view')
);

const SecurityKeysDevicesRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route { ...DEVICE_MANAGER_ROUTE_PROPS } element={<DevicesManagerView />} />
    <Route { ...DEVICES_ROUTE_PROPS } element={<DeviceManagerDetailView />} />
  </Routes>
);

export { SecurityKeysDevicesRouterComponent };