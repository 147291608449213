// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import CardSettingsContractCurrentBillingMethodComponent from './components/method/card-settings-contract-current-billing-method.component';
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS,
} from './constants/card-settings-contract-current-billing.constants';
// Hooks
import useCardSettingsContractCurrentBillingHook from './hooks/card-settings-contract-current-billing.hook';
// Styles
import { ToolTipContainerStyled } from './card-settings-contract-current-billing.component.styled';
// Translations
import {
  TOOTLTIP_TITLE,
  TOOLTIP_CONTENT,
} from './translations/card-settings-contract-current-billing.translations';

const CardSettingsContractCurrentBillingComponent = (): React.ReactElement => {
  const {
    billingCyclePeriodicity: day,
    formatMessage,
    primaryAccountId,
  } = useCardSettingsContractCurrentBillingHook();

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS}
    >
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS}
        value={primaryAccountId}
        tooltipMessage={
          <ToolTipContainerStyled>
            <ParagraphComponent>{formatMessage({ id: TOOTLTIP_TITLE })}</ParagraphComponent>
            <ParagraphComponent>{formatMessage({ id: TOOLTIP_CONTENT })}</ParagraphComponent>
          </ToolTipContainerStyled>
        }
      />
      <CardSettingsContractCurrentBillingMethodComponent />
    </CardSettingsContractCurrentSectionComponent>
  );
};

export default CardSettingsContractCurrentBillingComponent;
