// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import LimitModalTrackingHandlers from '../handlers/limit-modal.handlers.tracking';
// Types
import { LimitModalHookType } from './types/limit-modal.hook.type';

const LimitModalHook = (): LimitModalHookType => {
  const { formatMessage } = useCbIntl();
  const navigate = useNavigate();

  const { profile } = AppContextConsumerHook(),
    { firstName: username } = profile?.userDetails || {};
  const limitTrackingHandlers = AppTrackingHook(LimitModalTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    limitTrackingHandlers.handleLoadLimitModalTracking();
  }, []);

  return {
    formatMessage,
    navigate,
    username,
    ...limitTrackingHandlers,
  };
};

export default LimitModalHook;
