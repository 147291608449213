// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/security-keys-access-key-change-third-step-challenge.service.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { AccessKeyChangeCheckpointServiceType } from '../resolvers/types/security-keys-access-key-change-third-step-challenge.resolvers.type';

const accessKeyChangeCheckpointService = async ({
  oldPassword,
  newPassword,
  progressId,
}: AccessKeyChangeCheckpointServiceType): Promise<[ChallengeType, Response]> => {
  const customHeaders = progressId ? { progressId } : undefined;
  return apiUtilsCb.post({
    endpoint: constants.COMPLETE_ACCESS_KEY_CHANGE_OTP_SERVICE_CONFIGURATION.endpoint,
    params: { oldPassword, newPassword },
    customHeaders,
  });
};
export { accessKeyChangeCheckpointService };
