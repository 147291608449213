// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const TravelPlusFeaturesComponentStyled = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
    row-gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;
TravelPlusFeaturesComponentStyled.displayName = 'TravelPlusFeaturesComponentStyled';

const TravelPlusFeaturesContentComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXs};
  width: 100%;
`;
TravelPlusFeaturesContentComponentStyled.displayName = 'TravelPlusFeaturesContentComponentStyled';

export { TravelPlusFeaturesComponentStyled, TravelPlusFeaturesContentComponentStyled };
