// Constants
import {
  NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_PROPS,
  NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_WITH_BLOCK_PROPS,
} from '../constants/transactions-report-fraudulent-movement.constants';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Types
import { UseFilteredStepsReturnType } from './types/transactions-report.fraudulent-movement.type';
// Utils
import { isStatusNotBlockedOrCanceled } from '../components/confirmation/utils/transactions-report-fraudulent-movement-confirmation.utils';

export const getFilteredSteps = (
  status: CardStatusTypesEnumeration
): UseFilteredStepsReturnType[] =>
  isStatusNotBlockedOrCanceled(status)
    ? NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_WITH_BLOCK_PROPS
    : NOT_RECOGNIZE_CHARGE_ROUTE_STEPS_PROPS;
