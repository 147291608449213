// Types
import { PersonalEmailStep3ActionsHookType } from '../hooks/types/personal-email-step3-actions.hook.type';

const confirmChangeEmailActionButtonIsDisabled = ({
  challenged,
  fetching,
}: Pick<PersonalEmailStep3ActionsHookType, 'challenged' | 'fetching'>): boolean =>
  Boolean(challenged || fetching);

export { confirmChangeEmailActionButtonIsDisabled };
