// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { TransactionsLandingRowDetailsViewOrderBuilderReturnTrackingHandlersType } from './types/transactions-landing-row-details-view-order-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Types

const viewTransactionOnAmazonLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'viewOrderId',
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsLandingRowDetailsViewOrderTrackingHandlers = (
  track: TrackBuilderType
): TransactionsLandingRowDetailsViewOrderBuilderReturnTrackingHandlersType => ({
  handleViewTransactionOnAmazonLinkClickTracking: () =>
    track(viewTransactionOnAmazonLinkClickTrackingHandler()),
});

export default TransactionsLandingRowDetailsViewOrderTrackingHandlers;
