// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useLocation } from 'react-router-dom';
// Constants
import { REQUEST_SIGNATURE_KEY_CONFIRMATION_DEFAULT_ROUTE } from '../constants/request-signature-key-confirmation.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Handlers
import { RequestSignatureKeyConfirmationHandlers } from '../handlers/request-signature-key-confirmation.handlers';
import RequestSignatureKeyConfirmationTrackingHandlers from '../handlers/request-signature-key-confirmation.tracking.handlers';
// Hooks
import { useRequestSignatureKeyContextConsumerHook } from 'containers/security-keys/views/request-signature-key/contexts/request-signature-key.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RequestSignatureKeyConfirmationHookType } from './types/request-signature-key-confirmation.hook.type';

const RequestSignatureKeyConfirmationHook = (): RequestSignatureKeyConfirmationHookType => {
  const { formatMessage, formatDate } = useCbIntl();
  const { state } = useLocation(),
    { route = REQUEST_SIGNATURE_KEY_CONFIRMATION_DEFAULT_ROUTE } = state || {};

  const { profile } = AppContextConsumerHook(),
    address = profile?.addressDetails?.[0] || {};

  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  const { errorResponse } = useRequestSignatureKeyContextConsumerHook(),
    dateRequested = errorResponse?.errorData?.lastTimeUpdated || '';

  return {
    ...RequestSignatureKeyConfirmationHandlers({
      ...AppTrackingHook(RequestSignatureKeyConfirmationTrackingHandlers),
      route,
      onNavigateHybridFlow,
    }),
    address,
    dateRequested,
    formatMessage,
    formatDate,
  };
};

export default RequestSignatureKeyConfirmationHook;
