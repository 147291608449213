// Vendors
import styled from 'styled-components';

export const ProfilePersonalNameHeaderTooltipComponentStyled = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.fontSizeO};
  margin-left: ${({ theme }) => theme.spaces.spacingXxs};

  p {
    text-align: left;
  }
`;
