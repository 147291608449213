// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_01_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_02_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_02_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_01_PROPS,
  REQUEST_SIGNATURE_KEY_SIDEBAR_PROPS,
} from './constants/request-signature-key-sidebar.constants';
// Styles
import {
  RequestSignatureKeySidebarComponentStyled,
  RequestSignatureKeySidebarItemStyled,
} from './request-signature-key-sidebar.component.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_TITLE_01,
  DESCRIPTION_TITLE_02,
  TITLE,
} from './translations/request-signature-key-sidebar.translations';

const RequestSignatureKeySidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...REQUEST_SIGNATURE_KEY_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <RequestSignatureKeySidebarComponentStyled>
      <RequestSignatureKeySidebarItemStyled>
        <ParagraphComponent {...REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_01_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION_TITLE_01} />
        </ParagraphComponent>
        <ParagraphComponent {...REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_01_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION_01} />
        </ParagraphComponent>
      </RequestSignatureKeySidebarItemStyled>
      <RequestSignatureKeySidebarItemStyled>
        <ParagraphComponent {...REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_TITLE_02_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION_TITLE_02} />
        </ParagraphComponent>
        <ParagraphComponent {...REQUEST_SIGNATURE_KEY_SIDEBAR_DESCRIPTION_02_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION_02} />
        </ParagraphComponent>
      </RequestSignatureKeySidebarItemStyled>
    </RequestSignatureKeySidebarComponentStyled>
  </SidebarComponent>
);

export { RequestSignatureKeySidebarComponent };
