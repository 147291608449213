// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SecurityModuleComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_SECURITY_MODULE_PROPS,
  CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_TITLE_PROPS,
} from './constants/card-settings-change-pin-repeat-pin.constants';
// Hooks
import useCardSettingsChangePinRepeatedPinHook from './hooks/card-settings-change-pin-repeat-pin.hook';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import {
  CardSettingsChangePinRepeatedPinComponentStyled,
  CardSettingsChangePinRepeatedPinHeaderComponentStyled,
} from './card-settings-change-pin-repeat-pin.component.styled';
// Translations
import {
  DESCRIPTION,
  PASSWORD_NO_MATCH,
  TITLE,
} from './translations/card-settings-change-pin-repeat-pin.translations';
// Utilities
import { repeatedPinComponentIsDisabled } from './utils/card-settings-change-pin-repeat-pin.utils';

const CardSettingsChangePinRepeatedPinComponent = (): React.ReactElement => {
  const {
    error,
    handleSaveCardSettingsChangePinRepeatedPinChange,
    handleCardSettingsChangePinRepeatedPinClickTracking,
    repeatedPin,
    shownCode,
    ...rest
  } = useCardSettingsChangePinRepeatedPinHook();

  return (
    <CardSettingsChangePinRepeatedPinComponentStyled>
      <CardSettingsChangePinRepeatedPinHeaderComponentStyled>
        <ParagraphComponent {...CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_TITLE_PROPS}>
          <FormattedMessageComponent id={TITLE} />
        </ParagraphComponent>
        <ParagraphSecondaryComponentStyled>
          <ParagraphComponent {...CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_DESCRIPTION_PROPS}>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
        </ParagraphSecondaryComponentStyled>
      </CardSettingsChangePinRepeatedPinHeaderComponentStyled>
      <SecurityModuleComponent
        {...CARD_SETTINGS_CHECK_PIN_REPEATED_PIN_SECURITY_MODULE_PROPS}
        errorMessage={error && <FormattedMessageComponent id={PASSWORD_NO_MATCH} />}
        disabled={repeatedPinComponentIsDisabled(rest)}
        onChange={handleSaveCardSettingsChangePinRepeatedPinChange}
        onClick={handleCardSettingsChangePinRepeatedPinClickTracking}
        visible={shownCode}
        value={repeatedPin}
      />
    </CardSettingsChangePinRepeatedPinComponentStyled>
  );
};

export default CardSettingsChangePinRepeatedPinComponent;
