// Constants
import { NEXT_CHALLENGE_TYPE } from './constants/challenge-download-wrapper.handlers.constants';
// Resolvers
import {
  regenerateNewNumberOtpResolver,
  validateNewNumberOtpResolver,
} from '../resolvers/challenge-download-wrapper.resolver';
// Types
import { ChallengeDownloadWrapperBuilderReturnHandlersType } from './types/challenge-download-wrapper-builder-return.handlers.type';
import { ChallengeDownloadWrapperPropsType } from './types/challenge-download-wrapper-props.handlers.type';
import { ChallengeDownloadWrapperBuilderHandlersType } from './types/challenge-download-wrapper-builder.handlers.type';

const sendNumberOtpConfirmationButtonClickHandler = async ({
  handleSendNumberOtpConfirmationButtonClickTracking,
  otpId,
  otpValue,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setError,
  setFetching,
}: Omit<ChallengeDownloadWrapperPropsType, 'handleRestart'>): Promise<void> => {
  setFetching(true);
  handleSendNumberOtpConfirmationButtonClickTracking();

  const [nextChallenge, error] = await validateNewNumberOtpResolver({
    progressId,
    otpId,
    otpValue,
  });

  setError(null);

  if (error) {
    setError(true);
    setChallengeError(true);
  } else if (nextChallenge?.nextChallengeType === NEXT_CHALLENGE_TYPE) {
    setError(false);
    setChallengeError(false);
    setChallenged(true);
    setChallenge({
      otpId: nextChallenge.otpId,
      progressId: nextChallenge.progressId,
      nextChallengeType: nextChallenge?.nextChallengeType,
    });
  }

  setFetching(false);
};

const requestNewChallengeLinkClickHandler = async ({
  otpId,
  progressId,
  handleRestart,
  setError,
  setFetching,
  setChallenge,
  setChallenged,
  setChallengeError,
}: Omit<
  ChallengeDownloadWrapperPropsType,
  | 'handleRequestNewNumberOtpLinkClickTracking'
  | 'handleSendNumberOtpConfirmationButtonClickTracking'
  | 'otpValue'
  | 'setOtpId'
>): Promise<void> => {
  setFetching(true);

  const [nextChallenge, error] = await regenerateNewNumberOtpResolver({
    otpId,
    progressId,
  });

  setError(null);
  if (error) {
    setError(true);
    setChallengeError(true);
  } else if (nextChallenge?.progressId && nextChallenge?.otpId) {
    setChallengeError(false);
    setChallenged(false);
    setChallenge(nextChallenge);
    handleRestart();
  }
  setFetching(false);
};

const tryAgainCurrentChallengeButtonClickHandler = ({
  setChallenge,
  setChallengeError,
  setIsDownloadAvailable,
  setOtpValue,
}): void => {
  setChallenge(null);
  setOtpValue('');
  setChallengeError(false);
  setIsDownloadAvailable(true);
};

const ChallengDownloadWrapperHandlers = ({
  handleRequestNewNumberOtpLinkClickTracking,
  handleRestart,
  handleSendNumberOtpConfirmationButtonClickTracking,
  otpId,
  otpValue,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setError,
  setFetching,
  setIsDownloadAvailable,
  setOtpId,
  setOtpValue,
}: ChallengeDownloadWrapperBuilderHandlersType): ChallengeDownloadWrapperBuilderReturnHandlersType => ({
  handleResendNumberOtpConfirmationButtonClick: () =>
    requestNewChallengeLinkClickHandler({
      handleRestart,
      otpId,
      progressId,
      setChallenge,
      setChallenged,
      setChallengeError,
      setError,
      setFetching,
    }),
  handleRetryButtonClick: () =>
    tryAgainCurrentChallengeButtonClickHandler({
      setChallenge,
      setChallengeError,
      setIsDownloadAvailable,
      setOtpValue,
    }),
  handleSendNumberOtpConfirmationButtonClick: () =>
    sendNumberOtpConfirmationButtonClickHandler({
      handleRequestNewNumberOtpLinkClickTracking,
      handleSendNumberOtpConfirmationButtonClickTracking,
      otpId,
      otpValue,
      progressId,
      setChallenge,
      setChallenged,
      setChallengeError,
      setError,
      setFetching,
      setOtpId,
    }),
});

export { ChallengDownloadWrapperHandlers };
