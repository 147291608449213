// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  SECURITY_KEYS_SIDEBAR_PROPS: {
    testId: 'security-keys',
  },
  SECURITY_KEYS_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'security-keys-sidebar-message-01',
    size: paragraphConstants.SIZES.M,
  },
  SECURITY_KEYS_SIDEBAR_MESSAGE_02_PROPS: {
    testId: 'security-keys-sidebar-message-02',
    size: paragraphConstants.SIZES.M,
  },
  SECURITY_KEYS_SIDEBAR_MESSAGE_03_PROPS: {
    testId: 'security-keys-sidebar-message-03',
    size: paragraphConstants.SIZES.M,
  },
};
