// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalDataAddressBuilderReturnTrackingHandlersType } from './types/profile-personal-data-address-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const editCurrentPrimaryResidentialAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'editAddress',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalDataAddressTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalDataAddressBuilderReturnTrackingHandlersType => ({
  handleEditCurrentPrimaryResidentialAssetClickTracking: () =>
    track(editCurrentPrimaryResidentialAssetClickTrackingHandler()),
});

export default ProfilePersonalDataAddressTrackingHandlers;
