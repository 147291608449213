// Types
import { PersonalPhotoNameButtonUploadModalBuilderHandlersType } from './types/personal-photo-name-button-upload-modal-builder.handlers.type';
import { PersonalPhotoNameButtonUploadModalBuilderReturnHandlersType } from './types/personal-photo-name-button-upload-modal-builder-return.handlers.type';

const applyCropPhotoButtonClickHandler = ({
  handleApplyCropPhotoButtonClickTracking,
  setCropCompleted,
  setPhotoModal,
}: Pick<
  PersonalPhotoNameButtonUploadModalBuilderHandlersType,
  'handleApplyCropPhotoButtonClickTracking' | 'setPhotoModal' | 'setCropCompleted'
>): any => {
  handleApplyCropPhotoButtonClickTracking();
  setCropCompleted(true);
  setPhotoModal(null);
};

const cancelCropPhotoLinkClickHandler = ({
  handleCancelCropPhotoLinkClickTracking,
  setPhoto,
  setPhotoModal,
}: Pick<
  PersonalPhotoNameButtonUploadModalBuilderHandlersType,
  'handleCancelCropPhotoLinkClickTracking' | 'setPhotoModal' | 'setPhoto'
>): void => {
  handleCancelCropPhotoLinkClickTracking();
  setPhoto('');
  setPhotoModal(null);
};

const closeCropUploadedPhotoModalAssetClickHandler = ({
  handleCloseCropUploadedPhotoModalTracking,
  setPhoto,
  setPhotoModal,
}: Pick<
  PersonalPhotoNameButtonUploadModalBuilderHandlersType,
  'handleCloseCropUploadedPhotoModalTracking' | 'setPhotoModal' | 'setPhoto'
>): void => {
  handleCloseCropUploadedPhotoModalTracking();
  setPhoto('');
  setPhotoModal(null);
};

const PersonalPhotoNameButtonUploadModalHandlers = ({
  handleApplyCropPhotoButtonClickTracking,
  handleCancelCropPhotoLinkClickTracking,
  handleCloseCropUploadedPhotoModalTracking,
  setCropCompleted,
  setPhoto,
  setPhotoModal,
}: PersonalPhotoNameButtonUploadModalBuilderHandlersType): PersonalPhotoNameButtonUploadModalBuilderReturnHandlersType => ({
  handleApplyCropPhotoButtonClick: () =>
    applyCropPhotoButtonClickHandler({
      handleApplyCropPhotoButtonClickTracking,
      setCropCompleted,
      setPhotoModal,
    }),
  handleCancelCropPhotoLinkClick: () =>
    cancelCropPhotoLinkClickHandler({
      handleCancelCropPhotoLinkClickTracking,
      setPhoto,
      setPhotoModal,
    }),
  handleCloseCropUploadedPhotoModalAssetClick: () =>
    closeCropUploadedPhotoModalAssetClickHandler({
      handleCloseCropUploadedPhotoModalTracking,
      setPhoto,
      setPhotoModal,
    }),
});

export default PersonalPhotoNameButtonUploadModalHandlers;
