// Constants
import constants from './constants/app.tracking.configurations.constants';
import { TRACKING_CONFIGURATION } from '../constants/app.tracking.hook.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { GetTrackingInstanceType } from 'tracking/types/get-tracking-instance.type';

const getTrackingIdAccordingConfiguredView = (pathname: string): string =>
  ({
    [RoutesEnumeration.ACTIVATE]: constants.ACTIVATE,
    [RoutesEnumeration.CARD_SETTINGS]: constants.CARD_SETTINGS,
    [RoutesEnumeration.DASHBOARD]: constants.DASHBOARD,
    [RoutesEnumeration.FINANCING]: constants.FINANCING,
    [RoutesEnumeration.POINTS]: constants.POINTS,
    [RoutesEnumeration.REPORT_A_PROBLEM]: constants.REPORT_A_PROBLEM,
    [RoutesEnumeration.SUPPORT]: constants.SUPPORT,
    [RoutesEnumeration.TRANSACTIONS]: constants.TRANSACTIONS,
  }[pathname] ?? '');

const getTrackingConfiguration = (userId: string): GetTrackingInstanceType => ({
  ...TRACKING_CONFIGURATION,
  userId: userId ?? '',
});

export { getTrackingConfiguration, getTrackingIdAccordingConfiguredView };
