// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';
// Translations
import {
  ACTIVE,
  INACTIVE,
  COVERED,
} from '../translations/card-settings-contract-current-benefits-insurances.translations';
import translations from 'containers/card-settings/views/landing/components/toggles/translations/card-settings-toggles.translations';
// Types
import { GetInsuranceCoverageStatusPropsType } from './types/get-Insurance-coverage-status.type';
import { GetTagTypeFromStatusPropsType } from './types/card-settings-contract-current-insurance-utils.type';
import { GetTagUtilityPropsType } from './types/get-tag-utility-props.type';

const isDateExpired = (expirationDate: string): boolean =>
  new Date().getTime() >= new Date(String(expirationDate)).getTime();

const isInsuranceActive = (status?: string): boolean => {
  return (
    status === InsuranceStatusEnumeration.ACTIVE ||
    status === InsuranceStatusEnumeration.COOLING_OFF
  );
};

const getTagTypeFromStatus = ({
  status,
  endCoverageDate,
}: GetTagTypeFromStatusPropsType): string => {
  if (!isInsuranceActive(status) && endCoverageDate && !isDateExpired(endCoverageDate)) {
    return tagConstants.TYPES.PENDING;
  }
  if (isInsuranceActive(status)) {
    return tagConstants.TYPES.SUCCESS;
  }

  return tagConstants.TYPES.INFORMATIVE;
};

const getInsuranceCoverageStatus = ({
  status,
  endCoverageDate,
}: GetInsuranceCoverageStatusPropsType): string => {
  if (!isInsuranceActive(status) && endCoverageDate && !isDateExpired(endCoverageDate)) {
    return COVERED;
  }
  if (isInsuranceActive(status)) {
    return ACTIVE;
  }
  return INACTIVE;
};

const getTagMessage = ({ expirationDate, checked }: GetTagUtilityPropsType): string => {
  if (checked) {
    return translations.ON;
  }
  return translations.OFF;
};

const getTagType = ({ expirationDate, checked }: GetTagUtilityPropsType): string => {
  if (expirationDate && !checked) {
    return tagConstants.TYPES.PENDING;
  }
  if (checked) {
    return tagConstants.TYPES.SUCCESS;
  }
  return tagConstants.TYPES.INFORMATIVE;
};

export {
  getInsuranceCoverageStatus,
  getTagMessage,
  getTagType,
  getTagTypeFromStatus,
  isDateExpired,
  isInsuranceActive,
};
