// Vendors
import { titleConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_3D_CONFIRMATION_TITLE_PROPS = {
    testId: 'card-settings-check-3d-confirmation-title',
    tag: titleConstants.TAGS.H4,
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_DESCRIPTION_PROPS = {
    testId: 'card-settings-check-3d-confirmation-description',
  };

export {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_TITLE_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_DESCRIPTION_PROPS,
};
