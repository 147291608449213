const INPUT_CLICK_PAYLOAD = {
  eventAction: 'clickTextBox',
  eventLabel: 'amountTextBox',
};

const INPUT_ERROR_PAYLOAD = {
  eventAction: 'clickTextBox',
  eventLabel: 'textBox - error',
};

export { INPUT_CLICK_PAYLOAD, INPUT_ERROR_PAYLOAD };
