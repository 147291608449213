// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/card-settings-activate-card.context.constants';
// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsActivateCardContextType } from './types/card-settings-activate-card.context.type';
import { ChallengeType } from 'types/challenge.type';

const CardSettingsActivateCardContext = createContext<CardSettingsActivateCardContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const CardSettingsActivateCardContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsActivateCardContextType>> => {
  const [cardImage, setCardImage] = useState<string | undefined>(undefined);
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challenged, setChallenged] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  return (
    <CardSettingsActivateCardContext.Provider
      value={{
        cardImage,
        challenge,
        challenged,
        error,
        setCardImage,
        setChallenge,
        setChallenged,
        setError,
      }}
    >
      {children as React.ReactNode}
    </CardSettingsActivateCardContext.Provider>
  );
};

export const CardSettingsActivateCardContextConsumerHook =
  (): CardSettingsActivateCardContextType => useContext(CardSettingsActivateCardContext);

/* eslint-disable */
export const CardSettingsActivateCardContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<CardSettingsActivateCardContextType>> =>
    (
      <CardSettingsActivateCardContextProvider>
        <Component {...props} />
      </CardSettingsActivateCardContextProvider>
    );
