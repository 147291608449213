// Types
import { NotificationsSettingsFormattedType } from '../../types/notification-settings-formatted.type';
import { FetchNotificationsSettingsResolverType } from '../types/notifications-settings-list.resolver.type';
// Utils
import { getDefaultControllerAmount } from '../../utils/notifications-settings-list.utils';

const isValidNotificationsSettingsApiContract = (
  response: FetchNotificationsSettingsResolverType | null
): boolean => Boolean(
  response?.products && response?.products.length > 1 && response?.products[0].categories 
  && response?.products[0].categories.length > 0 && response?.products[0].categories[0].notifications 
    && response?.products[0].categories[0].notifications.length > 0);

const formatSettingsResponse = (
  response: FetchNotificationsSettingsResolverType
): NotificationsSettingsFormattedType => {

  const allEnabled = response.products[1]?.categories?.every(category =>
    category.notifications.every(notification => notification.enabled)  
  );

  const modifiedProduct = {
    ...response.products[1],
    categories: response.products[1]?.categories.map(category => ({
      ...category,
      notifications: category?.notifications.map(notification => ({
        ...notification,
        controllers: notification?.controllers.map(controller => ({
          ...controller,
          amount: getDefaultControllerAmount({
            amount: controller?.amount,
            notificationSetting: notification
          }).toString()
        }))
      }))
    }))
  };

  return {
    ...modifiedProduct,
    allEnabled: allEnabled,
  };
};

export { isValidNotificationsSettingsApiContract, formatSettingsResponse };