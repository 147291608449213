// Vendors
import { messageConstants } from '@openbank/cb-ui-commons';
// Resolvers
import { getDocumentLinkResolver } from '../resolvers/documents-table.resolver';
// Translations
import { ERROR } from './translations/documents-table-row.handlers.translations';
// TYpes
import { type DocumentsTableBuilderReturnHandlersType } from './types/documents-table-builder-return.handlers.type';
import { type DocumentsTableBuilderHandlersType } from './types/documents-table-builder.handlers.type';
import { DocumentsType } from 'types/documents.type';

const fetchDocumentaLinkEventHandler = async ({
  document,
  handleOpenExternalUrlOnHybridFlow,
  setToastConfiguration,
}: DocumentsTableBuilderHandlersType & { document: DocumentsType }) => {
  const [response] = await getDocumentLinkResolver({ documentId: document?.id });

  if (response?.uri && response.uri !== '') {
    handleOpenExternalUrlOnHybridFlow(response.uri);
  } else {
    setToastConfiguration({ description: ERROR, type: messageConstants.TYPES.ERROR });
  }
};
//
export const DocumentationTableHandlers = (
  props: DocumentsTableBuilderHandlersType
): DocumentsTableBuilderReturnHandlersType => ({
  handleFetchDocumentLinkEvent: (document: any) =>
    fetchDocumentaLinkEventHandler({ ...props, ...document }),
});
