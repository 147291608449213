// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import { AVATAR_ASSET_PROPS } from './constants/avatar.constants';
// Hooks
import useAvatarHook from './hooks/avatar.hook';
// Styles
import { AvatarComponentStyled } from './avatar.component.styled';
// Types
import { AvatarComponentType } from 'components/avatar/types/avatar.component.type';

const AvatarComponent = ({ sizes }: AvatarComponentType): React.ReactElement => {
  const { avatar } = useAvatarHook();

  return avatar ? (
    <div>
      <AvatarComponentStyled src={avatar} {...sizes} />
    </div>
  ) : (
    <AssetComponent { ...AVATAR_ASSET_PROPS } {...{ ...(sizes?.height ? { sizes } : {}) }} />
  );
};

export default AvatarComponent;
