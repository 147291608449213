// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';

const noCountriesFilteredFound = (
  filteredCountries: CardControlContinentComponentType[]
): boolean =>
  filteredCountries.every(({ filteredCountries = [] }: any) => filteredCountries.length === 0);

const noContinentsFilteredFound = (
  filteredContinents: CardControlContinentComponentType[]
): boolean => filteredContinents && filteredContinents.length === 0;

export { noCountriesFilteredFound, noContinentsFilteredFound };
