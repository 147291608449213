// Vendors
import React from 'react';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  SESSION_EXPIRED_MODAL_ASSET_PROPS,
  SESSION_EXPIRED_MODAL_BUTTON_PROPS,
  SESSION_EXPIRED_MODAL_DESCRIPTION_PROPS,
  SESSION_EXPIRED_MODAL_LAYOUT_PROPS,
  MODAL_INDEX,
} from './constants/expired-session-modal.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Hooks
import { useExpiredSessionModalHook } from './hooks/expired-session-modal.hook';
// Translations
import { BUTTON, DESCRIPTIONS, TITLE } from './translations/expired-session-modal.translations';
// Types
import { ExpiredSessionModalComponentType } from './types/expired-session-modal.component.type';
// Utilities
import { isIosWebView } from '@gruposantander/web-ui-framework';

const ExpiredSessionModalComponent = ({
  expiredReason,
}: ExpiredSessionModalComponentType): React.ReactElement | null => {
  const { handleGoToLoginFromExpiredSessionButtonClick } = useExpiredSessionModalHook();

  return expiredReason ? (
    <ModalComponent
      index={MODAL_INDEX}
      isIosDevice={isIosWebView()}
      primaryButtonConfiguration={{
        ...SESSION_EXPIRED_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={BUTTON} />,
        onClick: handleGoToLoginFromExpiredSessionButtonClick,
      }}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <LayoutColumnFlexComponentStyled {...SESSION_EXPIRED_MODAL_LAYOUT_PROPS}>
        <AssetComponent {...SESSION_EXPIRED_MODAL_ASSET_PROPS} />
        <ParagraphComponent {...SESSION_EXPIRED_MODAL_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTIONS[expiredReason]} />
        </ParagraphComponent>
      </LayoutColumnFlexComponentStyled>
    </ModalComponent>
  ) : null;
};

export { ExpiredSessionModalComponent };
