const DOMAIN = 'cobrandedCC.services.financing.creditLimitDecrease.step1.';

export const SUBTITLE = `${DOMAIN}subtitle`;
export const CURRENT_CREDIT_LIMIT = `${DOMAIN}currentCreditLimit`;
export const PAYMENT_METHOD_TEXT = `${DOMAIN}paymentMethodText`;
export const CONTACT_CENTER_TEXT_1 = `${DOMAIN}contactCenterText1`;
export const CONTACT_CENTER_TEXT_2 = `${DOMAIN}contactCenterText2`;
export const INPUT_LABEL = `${DOMAIN}inputLabel`;
export const MIN_VALUE = `${DOMAIN}minValue`;
export const MAX_VALUE = `${DOMAIN}maxValue`;
export const ERROR_MINIMUN_AMOUNT = `${DOMAIN}errorMinimunAmount`;
export const ERROR_MAXIMUM_AMOUNT = `${DOMAIN}errorMaximumAmount`;
export const ERROR_MUST_BE_MULTIPLE = `${DOMAIN}errorMustBeMultiple`;
export const ERROR_EMPTY_FIELD = `${DOMAIN}errorEmptyField`;
export const ERROR_DIFFERENT = `${DOMAIN}errorDifferent`;
export const CHECK_TEXT_1 = `${DOMAIN}checkText1`;
export const CHECK_TEXT_2 = `${DOMAIN}checkText2`;
