const PAYMENT_METHOD_CONTROLLER_NAME = 'paymentMethod';

const PAYMENT_METHOD_CONTROLLER_RULES = {
  required: true,
};

const PAYMENT_METHOD_DROPDOWN_PROPS = {
  isRequired: true,
  testId: 'transactions-report-payment-additional-info-payment-method-dropdown',
};

export {
  PAYMENT_METHOD_CONTROLLER_NAME,
  PAYMENT_METHOD_CONTROLLER_RULES,
  PAYMENT_METHOD_DROPDOWN_PROPS,
};
