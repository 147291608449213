// Vendors
import styled from 'styled-components';

export const ParagraphContainerStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.spaces.spacingXs};
`;
export const LastExpandableRowStyled = styled.div`
  margin: ${({ theme }) => theme.spaces.spacingXs} 0;
`;
export const LastParagraphContainerStyled = styled.div`
  margin: ${({ theme }) => theme.spaces.spacingXs} 0;
`;