// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import PersonalEmailStep1NewComponent from './components/new/personal-email-step1-new.component';
import PersonalEmailStep1RepeatComponent from './components/repeat/personal-email-step1-repeat.component';
import PersonalEmailStep1ActionsComponent from './components/actions/personal-email-step1-actions.component';
import { PersonalEmailStep1MessageComponent } from './components/message/personal-email-step1-message.component';
import { PersonalEmailStep1ScriptBoxComponent } from './components/message/personal-email-step1-script-box.component.cc';
// Constants
import constants from './constants/personal-email-step1.constants';
// Hooks
import PersonalEmailStep1Hook from './hooks/personal-email-step1.hook';
// Styles
import { PersonalEmailStepLayoutViewStyled } from '../../personal-email.view.styled';
import { PersonalEmailStep1ComponentStyled } from './personal-email-step1.component.styled';
// Translations
import { CURRENT_EMAIL } from './translations/personal-email-step1.translations';
// Utilities
import { isCC } from 'utils/app.utils';

const PersonalEmailStep1Component = (): React.ReactElement => {
  const { email } = PersonalEmailStep1Hook();

  return (
    <PersonalEmailStepLayoutViewStyled>
      <ResumeItemComponent
        {...constants.PERSONAL_EMAIL_STEP_1_RESUME_ITEM_PROPS}
        label={<FormattedMessageComponent id={CURRENT_EMAIL} />}
        value={email?.toLowerCase()}
      />
      <PersonalEmailStep1ComponentStyled>
        <PersonalEmailStep1NewComponent />
        <PersonalEmailStep1RepeatComponent />
      </PersonalEmailStep1ComponentStyled>
      {isCC() ? <PersonalEmailStep1ScriptBoxComponent /> : <PersonalEmailStep1MessageComponent />}
      <PersonalEmailStep1ActionsComponent />
    </PersonalEmailStepLayoutViewStyled>
  );
};

export default PersonalEmailStep1Component;
