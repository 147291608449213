// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { useRecoveryKeyContextConsumerHook } from 'containers/recovery-key/contexts/recovery-key.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Event handlers
import RecoveryKeyStepChallengeHandlers from '../handlers/recovery-key-step-challenge.handlers';
import RecoveryKeyStepChallengeTrackingHandlers from '../handlers/recovery-key-step-challenge.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import {
  RecoveryKeyStepChallengeBuilderHookType,
  RecoveryKeyStepChallengeHookType,
} from './types/recovery-key-step-challenge.hook.type';

const useRecoveryKeyStepChallenge = (
  props: RecoveryKeyStepChallengeBuilderHookType
): RecoveryKeyStepChallengeHookType => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [challengeValue, setChallengeValue] = useState<string>('');

  const { formatMessage } = useCbIntl();

  const { authData, setAuthData, pin, last4DigitsPan, setFetching } =
      useRecoveryKeyContextConsumerHook(),
    { setCurrentStep } = useWizardContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { onHybridFlow } = MobileConnectorContextConsumerHook();

  useEffect(() => {
    onHybridFlow(RoutesEnumeration.RECOVERY_KEY);
  }, []);

  return {
    ...RecoveryKeyStepChallengeHandlers({
      ...AppTrackingHook(RecoveryKeyStepChallengeTrackingHandlers),
      ...props,
      pin,
      last4DigitsPan,
      authData,
      setAuthData,
      setChallenged,
      setCurrentStep,
      setResult,
      setFetching,
      challengeValue,
    }),
    challenged,
    formatMessage,
    challengeValue,
    setChallengeValue,
  };
};

export { useRecoveryKeyStepChallenge };
