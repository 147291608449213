// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Constants
import constants from './constants/notifications-landing-tracking.handlers.constants';
// Types
import {
  NotificationsLandingReturnTrackingHandlersType,
  NotificationsLandingReturnTrackingHandlersViewErrorType
} from './types/notifications-landing-return.tracking.handlers.type';

const NotificationsLandingEnterTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: constants.ENTER_TRANKING_PAYLOAD,
  type: TrackingEventsEnum.VIEW,
});

const NotificationsLandingEnterErrorTrackingHandler = (): NotificationsLandingReturnTrackingHandlersViewErrorType => ({
  payload: constants.ENTER_ERROR_TRANKING_PAYLOAD,
  type: TrackingEventsEnum.VIEW,
});

const NotificationsLandingOpenFiltersTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: constants.OPEN_FILTERS_TRANKING_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const NotificationsLandingClearRemoveFiltersTrackingHandler = (type: string): TrackingLinkEventReturnType => ({
  payload: constants[type],
  type: TrackingEventsEnum.LINK,
});

const NotificationsLandingGoToNotificationsSettingsTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: constants.GO_TO_NOTIFICATIONS_SETTINGS_TRANKING_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const NotificationsLandingTrackingHandlers = (
  track: (param: unknown) => void
): NotificationsLandingReturnTrackingHandlersType => ({
  handleUserEnterOnNotificationsLandingTracking: () =>
    track(NotificationsLandingEnterTrackingHandler()),
  handleOpenFiltersNotificationsTracking: () =>
    track(NotificationsLandingOpenFiltersTrackingHandler()),
  handleUserEnterOnNotificationsLandingErrorTracking: () =>
    track(NotificationsLandingEnterErrorTrackingHandler()),
  handleClearRemoveFiltersNotificationsTracking: (type) =>
    track(NotificationsLandingClearRemoveFiltersTrackingHandler(type)),
  handleGoToNotificationsSettingsTracking: () =>
    track(NotificationsLandingGoToNotificationsSettingsTrackingHandler()),
});

export default NotificationsLandingTrackingHandlers;