export default {
  defaultCodeTime: 10,
  rightLinkProps: {
    isBold: true,
    testId: 'view-code-go-back',
    underline: false
  },
  viewCodeCodeDetailsProps: {
    'data-testid': 'view-code-code-details',
  },
  viewCodeDescriptionProps: {
    size: 'S',
    testId: 'view-code-description',
  },
  viewCodeTitleProps: {
    testId: 'view-code-title',
  },
};
