import { buttonConstants } from '@openbank/cb-ui-commons';

const COMMON_BUTTON_CONFIGURATION = {
    rwdFull: false,
    size: buttonConstants.SIZES.BIG,
    type: buttonConstants.TYPES.SECONDARY,
  },
  COMMON_BUTTON_ASSET_CONFIGURATION = {
    position: 'left',
  },
  HIDE_BUTTON_CONFIGURATION = {
    ...COMMON_BUTTON_CONFIGURATION,
    assetConfiguration: {
      ...COMMON_BUTTON_ASSET_CONFIGURATION,
      id: 'hide',
      testId: 'card-settings-check-3d-confirmation-buttons-hide-asset',
    },
  },
  SHOW_BUTTON_CONFIGURATION = {
    ...COMMON_BUTTON_CONFIGURATION,
    assetConfiguration: {
      ...COMMON_BUTTON_ASSET_CONFIGURATION,
      id: 'see',
      testId: 'card-settings-check-3d-confirmation-buttons-show-asset',
    },
  };

export { HIDE_BUTTON_CONFIGURATION, SHOW_BUTTON_CONFIGURATION };
