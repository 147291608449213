// Vendor
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { ERROR_CONFIGURATION_PROPS } from '../constants/devices-list.constants';
// Translations
import {
  ERROR_TITLE,
  ERROR_DESCRIPTION
} from '../translations/devices-list.translations';
// Types
import { DevicesListErrorConfigurationsPropsType } from './types/devices-list.configurations.props.type';
import { DevicesListErrorConfigurationsReturnType } from './types/devices-list.configurations.return.type';

const getFetchDevicesErrorConfiguration = ({
  error,
  formatMessage,
  onClick
}: DevicesListErrorConfigurationsPropsType): DevicesListErrorConfigurationsReturnType => ({
  ...ERROR_CONFIGURATION_PROPS,
  error,
  formatMessage,
  translations: {
    ERROR_TITLE: formatMessage({ id: ERROR_TITLE }),
    ERROR_DESCRIPTION: formatMessage({ id: ERROR_DESCRIPTION })
  },
  buttonConfiguration: {
    children: <FormattedMessageComponent id={ERROR_DESCRIPTION} />,
    onClick
  }
});

export { getFetchDevicesErrorConfiguration };