// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { CardSettingsRouterComponent } from './components/router/card-settings-router.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Providers
import { withCardDetailProvider } from 'providers/card-detail/card-detail.provider';
// Configurations
import { getCardSettingsErrorConfiguration } from './configurations/card-settings.configurations';
// Hooks
import CardSettingsHook from './hooks/card-settings.hook';
// Styles
import {
  LayoutGridComponentStyled,
  LayoutLoaderStyled,
  LayoutPaddingRootHeaderFooterStyled,
} from 'styles/app.styled';
// Utilities
import { isValidCurrentCard } from 'utils/card.utils';
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';

const CardSettingsContainer = (): React.ReactElement => {
  const { currentCard, formatMessage } = CardSettingsHook(),
    error = !isValidCurrentCard(currentCard);

  return (
    <LayoutPaddingRootHeaderFooterStyled isSecondLevel={isSecondLevelRoutePath()}>
      <LayoutGridComponentStyled>
        <TitleSectionComponent />
        <LayoutLoaderStyled fetching={error}>
          <FetchErrorComponent
            {...{ ...getCardSettingsErrorConfiguration({ formatMessage, error }) }}
          >
            <CardSettingsRouterComponent />
          </FetchErrorComponent>
        </LayoutLoaderStyled>
      </LayoutGridComponentStyled>
    </LayoutPaddingRootHeaderFooterStyled>
  );
};

export default withCardDetailProvider(CardSettingsContainer);
