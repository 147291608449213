const DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.requestAccess.step1.';

export default {
  BUTTON_CANCEL: `${DOMAIN}cancel`,
  BUTTON_NEXT: `${DOMAIN}next`,
  DESCRIPTION_01: `${DOMAIN}description01`,
  DESCRIPTION_02: `${DOMAIN}description02`,
  DESCRIPTION_03: `${DOMAIN}description03`,
  LINK: `${DOMAIN}link`,
  TITLE: `${DOMAIN}secondTitle`,
  MESSAGE_1: `${DOMAIN}message1`,
  MESSAGE_2: `${DOMAIN}message2`,
  MESSAGE_3: `${DOMAIN}message3`,
  MESSAGE_4: `${DOMAIN}message4`,
  MESSAGE_5: `${DOMAIN}message5`,
};
