// Constants
import { CARD_SETTINGS_SECTION_DIVIDER_PROPS } from 'containers/card-settings/views/landing/constants/card-settings.constants';
// Enumerations
import { QuickActionsActionsIdsEnumeration } from 'enumerations/quick-actions/quick-actions-actions-ids.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CARD_INFO,
  CARD_NUMBER,
  COPY,
  EXPIRY_DATE,
  MONTHLY_MOVEMENTS,
  VIEW_CARD_INFO,
  VIEW_STATEMENTS,
} from './translations/card-settings-details.constants.translations';

const CARD_SETTINGS_DETAILS_STATEMENTS_PROPS = {
    label: MONTHLY_MOVEMENTS,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.STATEMENTS,
        label: VIEW_STATEMENTS,
        trackingLabel: 'viewStatements',
        testId: 'card-settings-details-statements-link',
        url: RoutesEnumeration.CARD_STATEMENTS,
      },
    ],
    testing: {
      labelId: 'card-settings-details-statements-label',
    },
  },
  CARD_SETTINGS_DETAILS_CARD_PROPS = {
    label: CARD_NUMBER,
    links: [
      {
        label: COPY,
        testId: 'card-settings-details-card-copy-link',
        trackingLabel: 'copyCardNumber',
      },
    ],
    testing: {
      labelId: 'card-settings-details-card-label',
      valueId: 'card-settings-details-card-value',
    },
  },
  CARD_SETTINGS_DETAILS_EXPIRY_PROPS = {
    label: EXPIRY_DATE,
    testing: {
      labelId: 'card-settings-details-expiry-label',
      valueId: 'card-settings-details-expiry-value',
    },
  },
  CARD_SETTINGS_DETAILS_INFO_PROPS = {
    label: CARD_INFO,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.CARD_INFO,
        label: VIEW_CARD_INFO,
        trackingLabel: 'viewCardInfo',
        testId: 'card-settings-details-info-link',
        url: RoutesEnumeration.CONTRACT,
      },
    ],
    testing: {
      labelId: 'card-settings-details-info-label',
    },
  },
  CARD_SETTINGS_DETAILS_DIVIDER_PROPS = {
    ...CARD_SETTINGS_SECTION_DIVIDER_PROPS,
    testId: 'card-settings-details-divider',
    testing: {
      elementId: 'card-settings-details-divider',
      titleId: 'card-settings-details-divider-title',
    },
  };

export {
  CARD_SETTINGS_DETAILS_STATEMENTS_PROPS,
  CARD_SETTINGS_DETAILS_CARD_PROPS,
  CARD_SETTINGS_DETAILS_EXPIRY_PROPS,
  CARD_SETTINGS_DETAILS_INFO_PROPS,
  CARD_SETTINGS_DETAILS_DIVIDER_PROPS,
};
