// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
import { getLocaleFromBrowser } from '@openbank/cf-ui-framework';
// Constants
import {
  MB_API_DOCUMENTS_URL,
  UPLOADING_DOCUMENT_URL_SUFFIX,
} from './constants/step-4-upload-documentation.services.constants';
import { LIMIT_INCREASE_CASES_URL } from './constants/commons.constants';
// Enumerations
import { CountriesLocaleEnum, CountriesCodeEnum } from '@openbank/cf-ui-static-data';
// Types
import { UploadDocumentToGetDocumentUriPropsType } from '../resolvers/types/upload-document-to-get-uri-props.type';
import { UploadDocumentToGetDocumentUriReturnType } from '../resolvers/types/upload-document-to-get-uri-return.type';
import { UploadDocumentWithDocumentUriPropsType } from '../resolvers/types/upload-document-with-document-uri-props.type';
import { UploadDocumentationGoNextPropsType } from '../resolvers/types/step-4-upload-documentation-props.type';
import { CaseInitResponseType } from './types/step-0-init.type';

export const uploadDocumentToGetDocumentUriService = ({
  customerId,
  params,
  signal,
}: UploadDocumentToGetDocumentUriPropsType): Promise<
  [UploadDocumentToGetDocumentUriReturnType, Response]
> =>
  apiUtilsCb.post({
    signal,
    endpoint: MB_API_DOCUMENTS_URL,
    params: {
      ...params,
      customers: [
        {
          customerCode: customerId,
        },
      ],
      locale: getLocaleFromBrowser() || CountriesLocaleEnum.GERMANY,
      countryCode: getLocaleFromBrowser()?.split('-')?.[1] || CountriesCodeEnum.GERMANY,
    },
  });

export const uploadDocumentWithDocumentUriService = ({
  customerId,
  documentUri,
  file,
  signal,
}: UploadDocumentWithDocumentUriPropsType): Promise<[Record<string, never>, Response]> =>
  apiUtilsCb.put({
    signal,
    absoluteUrl: documentUri,
    hasCommonHeaders: false,
    body: file,
    customHeaders: {
      'Content-Type': file.type,
      'x-amz-meta-customers': customerId,
    },
  });

export const uploadDocumentationGoNextService = ({
  caseId,
  documents,
}: UploadDocumentationGoNextPropsType): Promise<[CaseInitResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${UPLOADING_DOCUMENT_URL_SUFFIX}`,
    params: {
      documents,
    },
  });
