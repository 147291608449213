// Vendors
import styled from 'styled-components';

const SecurityKeysSignatureKeyChangeChallengeComponentStyled = styled.section`
  width: 100%;
`;

SecurityKeysSignatureKeyChangeChallengeComponentStyled.displayName =
  'SecurityKeysSignatureKeyChangeChallengeComponentStyled';

export { SecurityKeysSignatureKeyChangeChallengeComponentStyled };
