// Types
import { CancelModalBuilderReturnHandlersType } from './types/cancel-modal-builder-return.handlers.type';
import { CancelModalBuilderHandlersType } from './types/cancel-modal-builder.handlers.type';

const cancelShowCancelModalLinkClickHandler = ({
  handleCancelShowCancelModalLinkClickTracking,
  onClose,
}: Pick<
  CancelModalBuilderHandlersType,
  'handleCancelShowCancelModalLinkClickTracking' | 'onClose'
>): any => {
  handleCancelShowCancelModalLinkClickTracking();
  onClose();
};

const closeChangeEmailModalAssetClickHandler = ({
  handleCloseCancelModalAssetClickTracking,
  onClose,
}: Pick<
  CancelModalBuilderHandlersType,
  'handleCloseCancelModalAssetClickTracking' | 'onClose'
>): any => {
  handleCloseCancelModalAssetClickTracking();
  onClose();
};

const confirmChangeEmailButtonClickHandler = ({
  handleConfirmCancelModalButtonClickTracking,
  navigate,
  onConfirm,
}: Pick<
  CancelModalBuilderHandlersType,
  'handleConfirmCancelModalButtonClickTracking' | 'navigate' | 'onConfirm'
>): any => {
  handleConfirmCancelModalButtonClickTracking();
  if (onConfirm) {
    onConfirm();
  } else {
    navigate(-1);
  }
};

const CancelModalHandlers = ({
  handleCloseCancelModalAssetClickTracking,
  handleConfirmCancelModalButtonClickTracking,
  handleCancelShowCancelModalLinkClickTracking,
  navigate,
  onClose,
  onConfirm,
}: CancelModalBuilderHandlersType): CancelModalBuilderReturnHandlersType => ({
  handleConfirmCancelModalButtonClick: () =>
    confirmChangeEmailButtonClickHandler({
      handleConfirmCancelModalButtonClickTracking,
      navigate,
      onConfirm,
    }),
  handleCloseCancelModalAssetClick: () =>
    closeChangeEmailModalAssetClickHandler({
      handleCloseCancelModalAssetClickTracking,
      onClose,
    }),
  handleCancelShowCancelModalLinkClick: () =>
    cancelShowCancelModalLinkClickHandler({
      handleCancelShowCancelModalLinkClickTracking,
      onClose,
    }),
});

export default CancelModalHandlers;
