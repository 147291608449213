// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { TransactionsReportDifferentAmountAtmContextType } from './types/transactions-report-different-amount-atm.type';
import { AdditionalInfoFormValuesType } from '../components/additional-info/types/additional-info-form-values.type';

const TransactionsReportDifferentAmountAtmContext =
  createContext<TransactionsReportDifferentAmountAtmContextType>(
    {} as TransactionsReportDifferentAmountAtmContextType
  );

export const TransactionsReportDifferentAmountAtmContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<TransactionsReportDifferentAmountAtmContextType>
> => {
  const [formValues, setFormValues] = useState<AdditionalInfoFormValuesType>({});

  return (
    <TransactionsReportDifferentAmountAtmContext.Provider
      value={{
        formValues,
        setFormValues,
      }}
    >
      {children}
    </TransactionsReportDifferentAmountAtmContext.Provider>
  );
};

export const useTransactionsReportDifferentAmountAtmContextConsumerHook =
  (): TransactionsReportDifferentAmountAtmContextType =>
    useContext(TransactionsReportDifferentAmountAtmContext);

/* eslint-disable */
export const TransactionsReportDifferentAmountAtmContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (
    props: any
  ): React.ReactElement<React.Provider<TransactionsReportDifferentAmountAtmContextType>> =>
    (
      <TransactionsReportDifferentAmountAtmContextProvider>
        <Component {...props} />
      </TransactionsReportDifferentAmountAtmContextProvider>
    );
