// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import { CARD_SETTINGS_CHECK_3D_WIZARD_STEP_2_CHALLENGE_PROPS } from './constants/card-settings-check-3d-wizard-step2-challenge.constants';
// Hooks
import useCardSettingsCheck3dWizardStep2ChallengeHook from './hooks/card-settings-check-3d-wizard-step2-challenge.hook';
// Types
import { CardSettingsCheck3dWizardStep2ChallengeComponentType } from './types/card-settings-check-3d-wizard-step2-challenge.component.type';

const CardSettingsCheck3dWizardStep2ChallengeComponent = ({
  challengeError,
  onRetry,
}: CardSettingsCheck3dWizardStep2ChallengeComponentType): React.ReactElement => {
  const { handleCheck3DChallengeErrorEvent, handleCheck3DChallengeSuccessEvent } =
    useCardSettingsCheck3dWizardStep2ChallengeHook();

  return (
    <ChallengeWrapperComponent
      {...CARD_SETTINGS_CHECK_3D_WIZARD_STEP_2_CHALLENGE_PROPS}
      challengeError={challengeError}
      onError={handleCheck3DChallengeErrorEvent}
      onSuccess={handleCheck3DChallengeSuccessEvent}
      onRetry={onRetry}
    />
  );
};

export default CardSettingsCheck3dWizardStep2ChallengeComponent;
