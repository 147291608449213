// Constants
import constants from 'organisms/table-organism/components/transactions-table/components/list/components/elements/utils/constants/transactions-table-list-elements.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { TransactionsLandingRowDetailsViewInstallmentBuilderHandlersType } from './types/transactions-landing-row-details-view-installment-builder.handlers.type';
import { TransactionsLandingRowDetailsViewInstallmentBuilderReturnHandlersType } from './types/transactions-landing-row-details-installment-builder-return.handlers.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const viewInstallmentsPlanLinkClickHandler = ({
  handleViewInstallmentsPlanLinkClickTracking,
  id,
  navigate,
}: TransactionsLandingRowDetailsViewInstallmentBuilderHandlersType): any => {
  handleViewInstallmentsPlanLinkClickTracking();
  localStorage.setItem(constants.EPP_ID_STRING, id);
  navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING));
};

const TransactionsLandingRowDetailsViewInstallmentHandlers = (
  props: TransactionsLandingRowDetailsViewInstallmentBuilderHandlersType
): TransactionsLandingRowDetailsViewInstallmentBuilderReturnHandlersType => ({
  handleViewInstallmentsPlanLinkClick: () => viewInstallmentsPlanLinkClickHandler(props),
});

export default TransactionsLandingRowDetailsViewInstallmentHandlers;
