// Types
import { ExampleModalBuilderReturnHandlersType } from './types/example-modal-builder-return.handlers.type';
import { ExampleModalBuilderHandlersType } from './types/example-modal-builder.handlers.type';

const closeModalAssetClickHandler = ({
  handleCloseExampleModalAssetClickTracking,
  onClose,
}: Pick<
  ExampleModalBuilderHandlersType,
  'handleCloseExampleModalAssetClickTracking' | 'onClose'
>): any => {
  handleCloseExampleModalAssetClickTracking();
  onClose();
};

const ExampleModalHandlers = ({
  handleCloseExampleModalAssetClickTracking,
  onClose,
}: ExampleModalBuilderHandlersType): ExampleModalBuilderReturnHandlersType => ({
  handleCloseExampleModalAssetClick: () =>
    closeModalAssetClickHandler({
      handleCloseExampleModalAssetClickTracking,
      onClose,
    }),
});

export default ExampleModalHandlers;
