// Vendors
import { assetConstants, buttonConstants } from '@openbank/cb-ui-commons';

const REQUEST_SIGNATURE_KEY_CONFIRMATION_ASSET_PROPS = {
    id: 'Check_mail_Alternative',
    sizes: {
      height: 128,
      width: 128,
    },
    testId: 'request-signature-key-confirmation-asset',
    type: assetConstants.TYPES.ILLUSTRATION,
  },
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_01_PROPS = {
    testId: 'request-signature-key-confirmation-description-01',
  },
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_02_PROPS = {
    bold: true,
    testId: 'request-signature-key-confirmation-description-02',
  },
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_03_PROPS = {
    testId: 'request-signature-key-confirmation-description-03',
  },
  REQUEST_SIGNATURE_KEY_CONFIRMATION_BUTTON_PROPS = {
    testId: 'request-signature-key-confirmation-button',
    rwdFull: false,
    type: buttonConstants.TYPES.PRIMARY,
  },
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DEFAULT_ROUTE = 'dashboard';

export {
  REQUEST_SIGNATURE_KEY_CONFIRMATION_ASSET_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_BUTTON_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DEFAULT_ROUTE,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_01_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_02_PROPS,
  REQUEST_SIGNATURE_KEY_CONFIRMATION_DESCRIPTION_03_PROPS,
};
