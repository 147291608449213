// Vendors
import { useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import ProfilePersonalDataAddressHandlers from '../handlers/profile-personal-data-address.handlers';
import ProfilePersonalDataAddressTrackingHandlers from '../handlers/profile-personal-data-address.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalDataAddressHookType } from './types/profile-personal-data-address.hook.type';

const ProfilePersonalDataAddressHook = (): ProfilePersonalDataAddressHookType => {
  const { formatMessage } = useCbIntl();

  const { profile } = AppContextConsumerHook(),
    address = profile?.addressDetails?.[0] || {};

  return {
    ...ProfilePersonalDataAddressHandlers({
      ...AppTrackingHook(ProfilePersonalDataAddressTrackingHandlers),
      navigate: useNavigate(),
    }),
    formatMessage,
    address,
  };
};
export default ProfilePersonalDataAddressHook;
