export const ICON_CONFIGURATION_SUCCESS = {
  iconConfiguration: {
    id: 'checkmark',
    colors: {
      fill: 'linkPrimaryA'
    }
  }
};

export const ICON_CONFIGURATION_ERROR = {
  iconConfiguration: {
    id: 'critical',
    colors: {
      fill: 'backgroundErrorA'
    }
  }
};

export const INPUT_LIMIT = 20;