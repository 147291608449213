// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const ListComponentStyled = styled.ul.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  list-style-position: outside;
  width: 100%;
  padding-left: ${convertPxsToRems({ pixels: 18, base: 14 })};
  margin: 0;

  li {
    list-style: disc;
  }
`;

export const ListItemComponentStyled = styled.li.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  p {
    display: inline;
  }
`;
