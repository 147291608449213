export const CONTEXT_DEFAULT_VALUE = {
  amount: '' as const,
  setAmount: (): void => undefined,
  defaultResult: undefined,
  setDefaultResult: (): void => undefined,
  error: false,
  setError: (): void => undefined,
  fetching: false,
  setFetching: (): void => undefined,
};
