// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
// Translations
import { DASHBOARD_TOP_MESSAGE_LABEL } from './translations/dashboard-top-message-button.translations';
// Hooks
import { DashboardTopMessageButtonHook } from './hooks/dashboard-top-message-button.hook';
// Types
import { DashboardTopMessageButtonComponentPropsType } from './types/dashboard-top-message-button.component';

const DashboardTopMessageButtonComponent = ({
  rwdFull,
  testId,
  type,
  trackingLabel,
  url,
  label = DASHBOARD_TOP_MESSAGE_LABEL,
}: DashboardTopMessageButtonComponentPropsType): React.ReactElement | null => {
  const { handleDashboardTopMessageButtonClick } = DashboardTopMessageButtonHook();

  return (
    <ButtonComponent
      {...{ rwdFull, testId, type, trackingLabel, url }}
      onClick={() =>
        handleDashboardTopMessageButtonClick({
          trackingLabel,
          url,
        })
      }
    >
      <FormattedMessageComponent id={label} />
    </ButtonComponent>
  );
};

export default DashboardTopMessageButtonComponent;
