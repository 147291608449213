// Constants
import { DOWNLOAD } from './constants/transactions-table.handler.constants';
// Handlers
import { fetchCardTransactionsHandler } from './fetch-card-transactions.handler';
// Types
import { TransactionsTableBuilderReturnHandlersType } from './types/transactions-table-builder-return.handlers.type';
import { TransactionsTableBuilderHandlersType } from './types/transactions-table-builder.handlers.type';
import { TransactionsTableSecurityCheckHandlersType } from './types/transactions-table-security-check.handlers.type';

const setOutProvidingErrorEvent = ({
  setError,
  wrapperError,
}: Pick<TransactionsTableBuilderHandlersType, 'setError' | 'wrapperError'>): void => {
  setError(wrapperError);
};

const setIsDownloadAvailableHandler = ({
  setIsDownloadAvailable,
}: Pick<TransactionsTableBuilderHandlersType, 'setIsDownloadAvailable'>): void => {
  setIsDownloadAvailable(true);
};

const showSecurityCheckModalHandler = ({
  setShowSecurityCheckModal,
  setShowDownloadSecurityCheckModal,
  type,
}: TransactionsTableSecurityCheckHandlersType): void => {
  type === DOWNLOAD ? setShowDownloadSecurityCheckModal(true) : setShowSecurityCheckModal?.(true);
};

const hideSecurityCheckModalHandler = ({
  setShowSecurityCheckModal,
  setShowDownloadSecurityCheckModal,
  type,
}: TransactionsTableSecurityCheckHandlersType): void => {
  type === DOWNLOAD ? setShowDownloadSecurityCheckModal(false) : setShowSecurityCheckModal?.(false);
};

const resetTransactionsHandler = (props: TransactionsTableBuilderHandlersType): void => {
  props.setElements([]);
  fetchCardTransactionsHandler({ ...props, filter: true });
};

export const TransactionsTableHandlers = (
  props: TransactionsTableBuilderHandlersType
): TransactionsTableBuilderReturnHandlersType => ({
  handleDownloadTransactions: () => {
    setIsDownloadAvailableHandler({ setIsDownloadAvailable: props.setIsDownloadAvailable });
  },
  handleFetchCardTransactions: () => {
    fetchCardTransactionsHandler(props);
  },
  handleHideSecurityCheckModal: (type?: string) =>
    hideSecurityCheckModalHandler({
      setShowSecurityCheckModal: props.setShowSecurityCheckModal,
      setShowDownloadSecurityCheckModal: props.setShowDownloadSecurityCheckModal,
      type,
    }),
  handleResetTransactions: () => {
    resetTransactionsHandler(props);
  },
  handleShowSecurityCheckModal: (type?: string) => {
    showSecurityCheckModalHandler({
      setShowSecurityCheckModal: props.setShowSecurityCheckModal,
      setShowDownloadSecurityCheckModal: props.setShowDownloadSecurityCheckModal,
      type,
    });
  },
  handleSetOutProvidingErrorEvent: () => {
    setOutProvidingErrorEvent({ setError: props.setError, wrapperError: props.wrapperError });
  },
});
