// Types
import { DevicesManagerHandlersType } from './types/devices.manager.view.handlers.type';
import { DevicesManagerHandlersReturnType } from './types/devices.manager.view.handlers.return.type';

const onEnterHandler = ({ onDeviceManagerEnterTracking }) => {
  onDeviceManagerEnterTracking();
};

const DevicesManagerHandlers = (
  props: DevicesManagerHandlersType
): DevicesManagerHandlersReturnType => ({
  onEnter: () => onEnterHandler(props)
});

export { DevicesManagerHandlers };