// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import { DeviceHeaderStatusComponent } from './components/status/device-header-status.component';
import { DeviceHeaderInfoComponent } from '../../views/devices-manager/components/header-info/device-header-info.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { LINK_PROPS } from './constants/device-header.constants';
// Hooks
import { DeviceHeaderHook } from './hooks/device-header.hook';
// Styled
import {
  DeviceHeaderComponentLeftStyled,
  DeviceHeaderComponentRightStyled,
  DeviceHeaderComponentStyled
} from './device-header.component-styled';
// Types
import { DeviceHeaderAliasType } from './types/device-header.component.type';
// Translations
import { MANAGE_DEVICE } from './translations/device-header.translations';

const DeviceHeaderComponent = ({
  button = false,
  link = false,
  device
}: DeviceHeaderAliasType): React.ReactElement => {
  const { isMobile, onLinkClick } = DeviceHeaderHook({ device });
  
  return (
    <DeviceHeaderComponentStyled>
      <DeviceHeaderComponentLeftStyled>
        <DeviceHeaderInfoComponent device={device}/>
      </DeviceHeaderComponentLeftStyled>

      {(!isMobile || device.lastSession?.active) &&
        <DeviceHeaderComponentRightStyled button={button} activeDevice={device.lastSession?.active}>
          <DeviceHeaderStatusComponent button={button} device={device} />
        </DeviceHeaderComponentRightStyled> }

        {isMobile && link &&
          <LinkComponent
          {...{...LINK_PROPS}}
            onClick={onLinkClick}
          >
            <FormattedMessageComponent id={MANAGE_DEVICE} /> 
          </LinkComponent>
        }
    </DeviceHeaderComponentStyled>   
  );
};

export { DeviceHeaderComponent };