// Types
import { DeviceModalConfigurationsType } from './types/device-modal.configurations.type';
import { DeviceModalConfigurationsReturnType } from './types/device-modal.configurations.return.type';
// Translations
import { CANCEL_BUTTON, MODAL_TYPES } from '../translations/device-modal.translations';

export const getDeviceModalConfigurations = ({
  type,
  formatMessage,
  onCrossClose,
  onClose,
  onAccept,
  buttonConfiguration,
  loading,
}: DeviceModalConfigurationsType): DeviceModalConfigurationsReturnType => ({
  title: formatMessage({ id: MODAL_TYPES[type].title }),
  onHeaderClose: onCrossClose,
  primaryButtonConfiguration: {
    children: formatMessage({ id: MODAL_TYPES[type].button }),
    onClick: onAccept,
    type: 'primary',
    testId: MODAL_TYPES[type].buttonId,
    loading,
    disabled: loading || buttonConfiguration.disabled,
  },
  linkConfiguration: {
    disabled: loading,
    onClick: onClose,
    children: formatMessage({ id: CANCEL_BUTTON }),
  },
});
