// Vendors
import React from 'react';
// Components
import { LineSeparatorComponent } from '@openbank/cb-ui-commons';
import DashboardSpentExpandableBottomInnerComponent from './components/expandable-inner/dashboard-spent-expandable-inner.component';
import DashboardSpentBottomInnerComponent from './components/inner/dashboard-spent-bottom-inner.component';
// Constants
import constants from './constants/dashboard-spent-bottom.constants';
// Hooks
import DashboardSpentMessageHook from './hooks/dashboard-spent-message.hook';
// Styles
import {
  DashboardSpentBottomComponentStyled,
  DashboardSpentBottomComponenInnerWrapperStyled,
} from './dashboard-spent-bottom.component.styled';
// Types
import { DashboardSpentBottomComponentType } from './types/dashboard-spent-bottom.component.type';
// Utils
import { hasBottomComponent } from './utils/dashboard-spent-bottom.utils';

const DashboardSpentBottomComponent = ({
  expenses,
  isMobile,
  testId = constants.DASHBOARD_SPENT_BORTTOM_DEFAULT_TEST_ID,
  total,
}: DashboardSpentBottomComponentType): React.ReactElement | null => {
  const { spentMessageConfiguration } = DashboardSpentMessageHook();

  return hasBottomComponent({ expenses, spentMessageConfiguration }) ? (
    <DashboardSpentBottomComponentStyled {...{ testId }}>
      <LineSeparatorComponent />
      <DashboardSpentBottomComponenInnerWrapperStyled>
        {isMobile ? (
          <DashboardSpentExpandableBottomInnerComponent
            {...{ expenses, isMobile, total, spentMessageConfiguration }}
          />
        ) : (
          <DashboardSpentBottomInnerComponent
            {...{ expenses, isMobile, spentMessageConfiguration }}
          />
        )}
      </DashboardSpentBottomComponenInnerWrapperStyled>
    </DashboardSpentBottomComponentStyled>
  ) : null;
};

export default DashboardSpentBottomComponent;
