// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Types
import { PersonalAddressStep1ActionsComponentType } from './types/personal-address-step1-actions.component.type';
// Utils
import { isInvalidNewAddress } from './utils/personal-address-step1-actions.utils';

export const PersonalAddressStep1ActionsComponent = ({
  address,
  newAddress,
}: PersonalAddressStep1ActionsComponentType): React.ReactElement => (
  <WizardStepFooterComponent
    rightButtonConfiguration={{
      disabled: isInvalidNewAddress({ address, newAddress }),
    }}
    targets={FIRST_STEP_TARGETS}
  />
);
