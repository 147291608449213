// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Event handlers
import AccessPortabilityHandlers from '../handlers/access-portability.handlers';
// Types
import { AccessPortabilityHookType } from './types/access-portability.hook.type';
import { AccessPortabilityComponentType } from '../types/access-portability.component.type';
import { AccessPortabilityReturnHookType } from './types/access-portability.hook-return.type';

const useAccessPortabilityHook = ({
  rightsType,
}: AccessPortabilityHookType): AccessPortabilityReturnHookType => {
  const { pageLoader, setPageLoader, setError } = ManagerContextConsumerHook();
  const [information, setInformation] = useState<
    AccessPortabilityComponentType | Record<string, any>
  >({});

  const { handleFetchAccessPortabilityInformationEvent } = AccessPortabilityHandlers({
    rightsType,
    setPageLoader,
    setError,
    setInformation,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchAccessPortabilityInformationEvent();
  }, []);

  return {
    pageLoader,
    information,
  };
};

export default useAccessPortabilityHook;
