// Enum
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export const DEVICE_MANAGER_ROUTE_PROPS = {
  index: true,
};

export const DEVICES_ROUTE_PROPS = {
  index: true,
  path: RoutesEnumeration.MANAGE_DEVICE + '/:id',
};