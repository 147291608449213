// Translations
import translations from './translations/table-row-description.utils.translations';
// Types
import { TableRowDescriptionUtilsType } from './types/table-row-description.utils.type';

const getNarrativeParamsKeysFromDescription = ({
  formatMessage,
  poEditorNarrative,
}: TableRowDescriptionUtilsType): string =>
  poEditorNarrative.params.reduce(
    (description, param) => description?.replace(`{${param.key}}`, param.value),
    formatMessage({ id: poEditorNarrative.key })
  );

const getTransactionRowDescription = ({
  description,
  merchantDetails,
  formatMessage,
  poEditorNarrative,
  narrative,
}: TableRowDescriptionUtilsType): string => {
  return poEditorNarrative?.key
    ? getNarrativeParamsKeysFromDescription({ formatMessage, poEditorNarrative })
    : narrative ||
        description ||
        merchantDetails?.name ||
        formatMessage({ id: translations.NO_DESCRIPTION });
};
export { getTransactionRowDescription };
