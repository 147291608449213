// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import { selectLimitService } from '../services/step-1-select-limit.services';
// Types
import { SelectLimitResolverPropsType } from './types/step-1-select-limit.resolvers.type';
import { ServiceLimitPropsReturnType } from '../services/types/step-1-select-limit-props-return.type';

export const selectLimitResolver = async ({
  caseId,
  cardId,
  newCreditLimit,
}: SelectLimitResolverPropsType): Promise<[ServiceLimitPropsReturnType, boolean]> => {
  const [response, { status }] = await selectLimitService({ caseId, cardId, newCreditLimit });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};
