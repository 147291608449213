// Resolvers
import { fetchCardsStatementsResolver } from 'containers/card-settings/views/statements/resolvers/card-settings-statements.resolvers';
import {
  CardSettingsStatementsBuilderHandlersType,
  CardSettingsStatementsMethodsHandlersType,
} from './types/card-settings-statements.handlers.type';
import { CardSettingsStatementsApiResponseType } from 'containers/card-settings/views/statements/types/card-settings-statements-api-response.type';
// Utils
import { getFetchOrNoContentError } from 'utils/errors/errors.utils';

const fetchCardStatementEventHandler = async ({
  cardContractId,
  setFetching,
  setError,
  setStatements,
}: CardSettingsStatementsBuilderHandlersType): Promise<void> => {
  setFetching(true);

  const [statements, error] = await fetchCardsStatementsResolver(cardContractId);

  error
    ? setError(getFetchOrNoContentError(error))
    : setStatements(statements as CardSettingsStatementsApiResponseType);
  setFetching(false);
};

const CardSettingsStatementsHandlers = (
  props: CardSettingsStatementsBuilderHandlersType
): CardSettingsStatementsMethodsHandlersType => ({
  handleFetchCardStatementEvent: () => fetchCardStatementEventHandler(props),
});

export { CardSettingsStatementsHandlers };
