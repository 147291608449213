// Event handlers
import PersonalPhotoNameButtonUploadModalButtonsHandlers from '../handlers/personal-photo-name-button-upload-modal-buttons.handlers';
import PersonalPhotoNameButtonUploadModalButtonsTrackingHandlers from '../handlers/personal-photo-name-button-upload-modal-buttons.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalPhotoNameButtonUploadModalButtonsBuilderReturnHandlersType } from '../handlers/types/personal-photo-name-button-upload-modal-buttons-builder-return.handlers.type';

const usePersonalPhotoNameButtonUploadModalButtonHook = (
  onRetry: VoidFunction
): PersonalPhotoNameButtonUploadModalButtonsBuilderReturnHandlersType =>
  PersonalPhotoNameButtonUploadModalButtonsHandlers({
    ...AppTrackingHook(PersonalPhotoNameButtonUploadModalButtonsTrackingHandlers),
    onRetry,
  });

export default usePersonalPhotoNameButtonUploadModalButtonHook;
