// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const PROPS = {
    testId: 'amount-component',
  },
  TITLE_PARAGRAPH_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.L,
  },
  ASSET_PROPS = {
    id: 'info',
    colors: {
      fill: 'linkPrimaryA',
    },
  },
  AMOUNT_ERROR_LENGTH = 34;

export { PROPS, TITLE_PARAGRAPH_PROPS, ASSET_PROPS, AMOUNT_ERROR_LENGTH };
