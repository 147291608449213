// Types
import {
  TransactionsEppStep1SimulationOptionsAllBuilderHandlersType,
  TransactionsEppStep1SimulationOptionsAllMethodsHandlersType,
} from './types/transactions-epp-step1-simulation-options-all.handlers.type';

const changeAllOptionsRadioButtonChangeEventHandler = ({
  handleChangeAllOptionsRadioButtonChangeEventTracking,
  minNumOfInstallments,
  setIsAllOptionsSelected,
  setInstallments,
}: TransactionsEppStep1SimulationOptionsAllBuilderHandlersType): void => {
  handleChangeAllOptionsRadioButtonChangeEventTracking();
  setIsAllOptionsSelected(true);
  setInstallments(minNumOfInstallments);
};

const TransactionsEppStep1SimulationOptionsAllHandlers = (
  props: TransactionsEppStep1SimulationOptionsAllBuilderHandlersType
): TransactionsEppStep1SimulationOptionsAllMethodsHandlersType => ({
  handleChangeAllOptionsRadioButtonChangeEvent: () =>
    changeAllOptionsRadioButtonChangeEventHandler(props),
});

export { TransactionsEppStep1SimulationOptionsAllHandlers };
