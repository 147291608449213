// Vendors
import React from 'react';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CardSettingsCheck3dWizardStep2ChallengeComponent from './components/challenge/card-settings-check-3d-wizard-step2-challenge.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { CARD_SETTINGS_CHECK_3D_WIZARD_STEP_2_RESUME_PROPS } from './constants/card-settings-check-3d-wizard-step2.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import useCardSettingsCheck3dWizardStep2ActionsHook from './hooks/card-settings-check-3d-wizard-step2-actions.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  CHECK_CODE,
  LABEL,
  VALUE,
} from './translations/card-settings-check-3d-wizard-step2.translations';

const CardSettingsCheck3dWizardStep2Component = (): React.ReactElement => {
  const {
    challengeError,
    handleCheck3DCodeButtonClick,
    handleCheck3DCodePreviousButtonClick,
    handleRetryChallengeButtonClick,
    progressId,
  } = useCardSettingsCheck3dWizardStep2ActionsHook();

  return (
    <LayoutColumnFlexComponentStyled>
      <ResumeItemComponent
        {...CARD_SETTINGS_CHECK_3D_WIZARD_STEP_2_RESUME_PROPS}
        label={<FormattedMessageComponent id={LABEL} />}
        value={<FormattedMessageComponent id={VALUE} />}
      />
      <CardSettingsCheck3dWizardStep2ChallengeComponent
        challengeError={challengeError}
        onRetry={handleRetryChallengeButtonClick}
      />
      <WizardStepFooterComponent
        hasDefaultLeftButton={true}
        rightButtonConfiguration={{
          children: <FormattedMessageComponent id={CHECK_CODE} />,
          disabled: !progressId,
          onClick: handleCheck3DCodeButtonClick,
        }}
        leftButtonConfiguration={{
          onClick: handleCheck3DCodePreviousButtonClick,
        }}
        targets={SECOND_STEP_TARGETS}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export default CardSettingsCheck3dWizardStep2Component;
