// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { CardSettingsDetailsTrackingBuilderReturnHandlersType } from './types/card-settings-details.tracking.handlers.type';

const copyCardNumberLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'copyCardPan',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsDetailsTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsDetailsTrackingBuilderReturnHandlersType => ({
  handleCopyCardNumberLinkClickTracking: () => track(copyCardNumberLinkClickTrackingHandler()),
});

export { CardSettingsDetailsTrackingHandlers };
