// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, SelectorCardComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/current-account-box.constants';
// Hooks
import FinancingFastCashCurrentAccountBoxHook from './hooks/current-account-box.hook';
// Translations
import { SUBTITLE, TITLE } from './translations/current-account-box.translations';

const CurrentAccountBoxComponent = (): React.ReactElement => {
  const { accountNumber, formatMessage } = FinancingFastCashCurrentAccountBoxHook();

  return (
    <SelectorCardComponent
      {...constants.PROPS}
      selectorCardList={[
        {
          title: formatMessage({ id: TITLE }),
          children: (
            <ParagraphComponent {...constants.DESCRIPTION_PARAGRAPH_PROPS}>
              <FormattedMessageComponent id={SUBTITLE} values={{ accountNumber }} />
            </ParagraphComponent>
          ),
        },
      ]}
    />
  );
};

export default CurrentAccountBoxComponent;
