// Enumerations
import { TravelPlusNextStatusEnumeration } from 'components/travel-plus/enumerations/travel-plus-next-status.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cbTravelPlus.cbToast.';

const TOASTS = {
  [TravelPlusNextStatusEnumeration.ENABLE]: {
    ERROR_MESSAGE: `${DOMAIN}errorSwitchOffDescription`,
    SUCCESS_MESSAGE: `${DOMAIN}successSwitchOffDescription`,
  },
  [TravelPlusNextStatusEnumeration.DISABLE]: {
    ERROR_MESSAGE: `${DOMAIN}errorSwitchOnDescription`,
    SUCCESS_MESSAGE: `${DOMAIN}successSwitchOnDescription`,
  },
};

export { TOASTS };
