// Components
import FinancingFastCashSecondStepComponent from '../components/second-step/financing-fast-cash-second-step.component';
import FinancingFastCashFirstStepComponent from '../components/first-step/financing-fast-cash-first-step.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { FAILED, STEPS, SUCCESS } from '../translations/financing-fast-cash.translations';

const FINANCING_FAST_CASH_WIZARD_PROPS = {
  feedbackConfiguration: {
    errorConfiguration: {
      description: FAILED.CONFIRMATION.CONTENT,
      title: FAILED.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.FINANCING,
    },
    successConfiguration: {
      description: SUCCESS.CONFIRMATION.CONTENT,
      linkLabel: SUCCESS.BACK_BUTTON,
      title: SUCCESS.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.FINANCING,
    },
  },
  steps: [
    {
      component: FinancingFastCashFirstStepComponent,
      title: STEPS.FIRST.TITLE,
    },
    {
      component: FinancingFastCashSecondStepComponent,
      title: STEPS.SECOND.TITLE,
    },
  ],
};

export { FINANCING_FAST_CASH_WIZARD_PROPS };
