// Constants
import constants from './constants/card-settings-toggles.handlers.constants';
// Resolvers
import {
  toggleCardAvailabilityResolver,
  toggleCardTravelPlusAvailabilityResolver,
} from '../resolvers/card-settings-toggles.resolvers';
// Types
import { CardSettingsTogglesBuilderHandlersType } from './types/card-settings-toggles-builder.handlers.type';
import { CardSettingsTogglesBuilderReturnHandlersType } from './types/card-settings-toggles-builder-return.handlers.type';
import { CardSettingsTogglesTravelPlusBuilderHandlersType } from './types/card-settings-toggles-travel-plus-builder.handlers.type';
import { ToggleActivityCardHandlerProps } from './types/toggle-activity-card-handler-props.type';
import { ConfirmActivityCardHandlerProps } from './types/confirm-activity-card-handler-props.type';
// Utilities
import { cardActive } from 'utils/card.utils';

const updateTravelPlusVisibility =
  (
    handleToggleTravelPlusToggleChangeTracking: CardSettingsTogglesTravelPlusBuilderHandlersType['handleToggleTravelPlusToggleChangeTracking']
  ): ((prevVisible: boolean) => boolean) =>
  (prevVisible: boolean): boolean => {
    handleToggleTravelPlusToggleChangeTracking(!prevVisible);

    return !prevVisible;
  };

const toggleTravelPlusToggleChangeHandler = ({
  handleToggleTravelPlusToggleChangeTracking,
  setShowTravelPlusModal,
}: Pick<
  CardSettingsTogglesBuilderHandlersType,
  'handleToggleTravelPlusToggleChangeTracking' | 'setShowTravelPlusModal'
>): void => {
  setShowTravelPlusModal(updateTravelPlusVisibility(handleToggleTravelPlusToggleChangeTracking));
};

const confirmActivityCardHandler = async ({
  handleToggleActivityCardToggleChangeTracking,
  id: cardId,
  setCardStatusLoading,
  setCard,
  status,
}: ConfirmActivityCardHandlerProps): Promise<void> => {
  setCardStatusLoading(constants.CARD_STATUS_LOADING.LOADING);
  handleToggleActivityCardToggleChangeTracking?.(cardActive(status));

  const [response, error] = await toggleCardAvailabilityResolver({ cardId, status });

  // Response
  if (error) {
    setCardStatusLoading(constants.CARD_STATUS_LOADING.ERROR);
  } else if (response) {
    setCardStatusLoading(constants.CARD_STATUS_LOADING.SUCCESS);
    setTimeout(() => {
      setCard(true);
    }, constants.MILLISECONDS_TIMEOUT);
  }
};

const toggleActivityCardToggleChangeHandler = ({
  handleToggleActivityCardToggleChangeTracking,
  setCardStatusLoading,
  setShowConfirmationModal,
  status,
}: ToggleActivityCardHandlerProps): void => {
  handleToggleActivityCardToggleChangeTracking?.(cardActive(status));
  setShowConfirmationModal(true);
  setCardStatusLoading(constants.CARD_STATUS_LOADING.IDLE);
};

const CardSettingsTogglesHandlers = ({
  handleToggleTravelPlusToggleChangeTracking,
  handleToggleActivityCardToggleChangeTracking,
  id,
  setCardStatusLoading,
  setShowTravelPlusModal,
  setShowConfirmationModal,
  setError,
  setCard,
  status,
}: CardSettingsTogglesBuilderHandlersType &
  CardSettingsTogglesTravelPlusBuilderHandlersType): CardSettingsTogglesBuilderReturnHandlersType => ({
  handleToggleTravelPlusToggleChange: () =>
    toggleTravelPlusToggleChangeHandler({
      handleToggleTravelPlusToggleChangeTracking,
      setShowTravelPlusModal,
    }),
  handleToggleActivityCardToggleChange: () =>
    toggleActivityCardToggleChangeHandler({
      handleToggleActivityCardToggleChangeTracking,
      setCardStatusLoading,
      setShowConfirmationModal,
      status,
    }),
  handleConfirmActivityCardToggleChange: () =>
    confirmActivityCardHandler({
      id,
      handleToggleActivityCardToggleChangeTracking,
      handleToggleTravelPlusToggleChangeTracking,
      setCardStatusLoading,
      setError,
      setCard,
      status,
    }),
  handleDismissActivityCardToggleChange: () => {
    setShowConfirmationModal(false);
  },
});

export default CardSettingsTogglesHandlers;
