export enum QuickActionsActionsIdsEnumeration {
  ADVANTAGES = 'advantages',
  BLOCK = 'block',
  CARD_INFO = 'cardInfo',
  CHECK_3D_SECURE = 'check3DSecure',
  CHANGE_3D_SECURE = 'change3DSecure',
  DEFERRED_PURCHASES_AND_PAYMENTS = 'deferredPurchasesAndPayments',
  REQUEST_ADDITIONAL_CREDIT = 'requestAdditionalCredit',
  REQUEST_NEW_CARD = 'requestNewCard',
  STATEMENTS = 'statements',
}
