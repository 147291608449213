// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import CardSettingsChangePinActionsComponent from './components/actions/card-settings-change-pin-actions.component';
import CardSettingsChangePinConstraintsComponent from './components/constraints/card-settings-change-pin-constraints.component';
import CardSettingsChangePinChallengeComponent from './components/challenge/card-settings-change-pin-challenge.component';
import CardSettingsChangePinFeedbackComponent from './components/feedback/card-settings-change-pin-feedback.component';
import CardSettingsChangePinNewPinComponent from './components/new-pin/card-settings-change-pin-new-pin.component';
import CardSettingsChangePinRepeatedPinComponent from './components/repeated-pin/card-settings-change-pin-repeat-pin.component';
import CardSettingsChangePinSidebarComponent from './components/sidebar/card-settings-change-pin-sidebar.component';
// Constants
import {
  CARD_SETTINGS_CHECK_PIN_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_PIN_TITLE_PROPS,
} from './constants/card-settings-change-pin.constants';
// Contexts
import { CardSettingsChangePinContextConsumerHOC } from './contexts/card-settings-change-pin.context';
// Hooks
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import {
  CardSettingsChangePinComponentStyled,
  CardSettingsChangePinContentComponentStyled,
} from './card-settings-change-pin.component.styled';
// Translations
import { DESCRIPTION, TITLE } from './translations/card-settings-change-pin.translations';

const CardSettingsChangePinBaseComponent = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <CardSettingsChangePinFeedbackComponent>
          <AppCardSectionComponentStyled>
            <AppCardContentSectionComponentStyled>
              <CardSettingsChangePinComponentStyled>
                <TitleHeaderComponent {...CARD_SETTINGS_CHECK_PIN_TITLE_PROPS}>
                  <FormattedMessageComponent id={TITLE} />
                </TitleHeaderComponent>
                <ParagraphComponent {...CARD_SETTINGS_CHECK_PIN_DESCRIPTION_PROPS}>
                  <FormattedMessageComponent id={DESCRIPTION} />
                </ParagraphComponent>
                <CardSettingsChangePinContentComponentStyled>
                  <CardSettingsChangePinConstraintsComponent />
                  <CardSettingsChangePinNewPinComponent />
                  <CardSettingsChangePinRepeatedPinComponent />
                  <CardSettingsChangePinChallengeComponent />
                  <CardSettingsChangePinActionsComponent />
                </CardSettingsChangePinContentComponentStyled>
              </CardSettingsChangePinComponentStyled>
            </AppCardContentSectionComponentStyled>
          </AppCardSectionComponentStyled>
        </CardSettingsChangePinFeedbackComponent>
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled>
        <CardSettingsChangePinSidebarComponent />
      </LayoutGridContentRightComponentStyled>
    </LayoutGridContentComponentStyled>
  </LayoutGridComponentStyled>
);

const CardSettingsChangePinComponent = CardSettingsChangePinContextConsumerHOC(
  CardSettingsChangePinBaseComponent
);

export { CardSettingsChangePinComponent };
