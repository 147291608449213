// Vendors
import styled from 'styled-components';

export const CancelModalDescriptionComponentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const CancelModalDescriptionSubtitleStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    text-align: left;
  }
`;
