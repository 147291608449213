const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyPhoneNumber.';
const DOMAIN_CC =
  'cobrandedCC.services.userSettings.privateArea.personalInformation.personalDetails.modifyPhoneNumber.';

const TITLE = `${DOMAIN}title`,
  PAGE_TITLE = `${DOMAIN}title`,
  ERROR_BUTTON = `${DOMAIN}errorFeedback.retryButton`,
  ERROR_LINK = `${DOMAIN}errorFeedback.cancelLink`,
  ERROR_TITLE = `${DOMAIN}errorFeedback.title`,
  ERROR_DESCRIPTION = `${DOMAIN}errorFeedback.description`,
  ERROR_TITLE_2 = `${DOMAIN}errorFeedback.imposibleTitle`,
  ERROR_DESCRIPTION_2 = `${DOMAIN}errorFeedback.imposibleDescription`,
  SUCCESS_TITLE = `${DOMAIN}successFeedback.title`,
  SUCCESS_DESCRIPTION = `${DOMAIN}successFeedback.description`,
  LINK_LABEL = `${DOMAIN}successFeedback.returnToPersonalInformationLink`,
  NEXT = `${DOMAIN}nextButton`,
  CANCEL = `${DOMAIN}cancelButton`,
  BACK_BUTTON = `${DOMAIN}backButton`,
  CONFIRM = `${DOMAIN}cbWizard.confirmButton`,
  SEND_CONFIRMATION_CODE = `${DOMAIN_CC}sendConfirmationCode`,
  MODIFY_PERSONAL_DATA = `${DOMAIN_CC}modifyPersonalData`,
  DO_YOU_AGREE = `${DOMAIN_CC}doYouAgree`,
  TELL_CONFIRMATION_CODE = `${DOMAIN_CC}tellConfirmationCode`,
  COSTUMER_CANNOT_RECEIVE = `${DOMAIN_CC}costumerCannotReceive`,
  COSTUMER_CANNOT_RECEIVE_STEPS = `${DOMAIN_CC}costumerCannotReceiveSteps`;

export {
  TITLE,
  PAGE_TITLE,
  ERROR_BUTTON,
  ERROR_LINK,
  ERROR_TITLE,
  ERROR_DESCRIPTION,
  ERROR_TITLE_2,
  ERROR_DESCRIPTION_2,
  SUCCESS_TITLE,
  SUCCESS_DESCRIPTION,
  LINK_LABEL,
  NEXT,
  CANCEL,
  BACK_BUTTON,
  CONFIRM,
  DO_YOU_AGREE,
  MODIFY_PERSONAL_DATA,
  TELL_CONFIRMATION_CODE,
  SEND_CONFIRMATION_CODE,
  COSTUMER_CANNOT_RECEIVE,
  COSTUMER_CANNOT_RECEIVE_STEPS,
};
