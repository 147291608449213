// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { UploadDocumentCreditLimitIncreaseComponent } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/upload-document-credit-limit-increase.component';
// Constants
import { PARAGRAPHS_L_SIZE } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/constants/step-4-upload-documentation.component.constants';
// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Styles
import { DisplayGridStyled } from '../../upload-documents-section.component.styled';
// Types
import { EmployedSectionPropsType } from './types/employed-section.type';
// Translations
import { step4UploadDocumentationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/translations/step-4-upload-documentation.translations';
// Utils
import { useEmployedSectionHook } from './hooks/employed-section.hook';

export const EmployedSectionComponent = ({
  activityTypeDeclared,
  documentsRejected,
  formatMessage,
}: EmployedSectionPropsType): React.ReactElement | null => {
  const {
    showLastSalary,
    showPreviousSalary,
    showBeforePreviousSalary,
    isLastSalaryRejected,
    isPreviousSalaryRejected,
    isBeforePreviousSalaryRejected,
    shouldRenderSection,
  } = useEmployedSectionHook({
    activityTypeDeclared,
    documentsRejected,
  });

  if (!shouldRenderSection) return null;

  return (
    <>
      <ParagraphComponent bold size={PARAGRAPHS_L_SIZE}>
        {formatMessage({ id: step4UploadDocumentationTranslations.SALARY_SLIPS })}
      </ParagraphComponent>
      <ParagraphComponent>
        {formatMessage({ id: step4UploadDocumentationTranslations.SALARY_SLIPS_DESCRIPTION })}
      </ParagraphComponent>
      <DisplayGridStyled>
        {showLastSalary && (
          <UploadDocumentCreditLimitIncreaseComponent
            id={UploadDocumentIdsEnumeration.LAST_SALARY}
            title={formatMessage({ id: step4UploadDocumentationTranslations.LAST_SALARY_SLIP })}
            isRejected={isLastSalaryRejected}
          />
        )}
        {showPreviousSalary && (
          <UploadDocumentCreditLimitIncreaseComponent
            id={UploadDocumentIdsEnumeration.PREVIOUS_SALARY}
            title={formatMessage({
              id: step4UploadDocumentationTranslations.PREVIOUS_SALARY_SLIP,
            })}
            isRejected={isPreviousSalaryRejected}
          />
        )}
        {showBeforePreviousSalary && (
          <UploadDocumentCreditLimitIncreaseComponent
            id={UploadDocumentIdsEnumeration.BEFORE_PREVIOUS_SALARY}
            title={formatMessage({
              id: step4UploadDocumentationTranslations.BEFORE_PREVIOUS_SALARY_SLIP,
            })}
            isRejected={isBeforePreviousSalaryRejected}
          />
        )}
      </DisplayGridStyled>
    </>
  );
};
