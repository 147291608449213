// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Event handlers
import CancelModalHandlers from '../handlers/cancel-modal.handlers';
import CancelModalTrackingHandlers from '../handlers/cancel-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CancelModalBuilderReturnHandlersType } from '../handlers/types/cancel-modal-builder-return.handlers.type';
import { CancelModalBuilderPropsType } from '../handlers/types/cancel-modal-builder-props.type';

const useCancelModalHook = ({
  onClose,
  onConfirm,
  show,
}: CancelModalBuilderPropsType): CancelModalBuilderReturnHandlersType => {
  const {
    handleConfirmCancelModalButtonClickTracking,
    handleCloseCancelModalAssetClickTracking,
    handleCancelShowCancelModalLinkClickTracking,
    handleOpenCancelModalTracking,
  } = AppTrackingHook(CancelModalTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    show && handleOpenCancelModalTracking();
  }, [show]);

  return CancelModalHandlers({
    handleCancelShowCancelModalLinkClickTracking,
    handleConfirmCancelModalButtonClickTracking,
    handleCloseCancelModalAssetClickTracking,
    navigate: useNavigate(),
    onClose,
    onConfirm,
  });
};

export default useCancelModalHook;
