// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLPIStep4DownloadComponent } from './components/download/financing-insurances-contracting-lpi-step4-download.component';
import { FinancingInsurancesContractingLPIStep4ChallengeComponent } from './components/challenge/financing-insurances-contracting-lpi-step4-challenge.component';
import { FinancingInsurancesContractingLPIDetailConfirmationComponent } from '../detail-confirmation/financing-insurances-contracting-lpi-detail-confirmation.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS } from '../../constants/financing-insurances-contracting-lpi.constants';
import { FOURTH_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useFinancingInsurancesContractingLpiStep4 } from './hooks/financing-insurances-contracting-lpi-step4.hooks';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';

const FinancingInsurancesContractingLPIStep4Component = (): React.ReactElement => {
  const {
    downloadedError,
    handleConfirmContractLPIButtonClick,
    handleToggleDownloaded,
    setChallenge,
    challenge,
    fetching,
    setChallengeError,
    challengeError,
    postalDocumentation,
  } = useFinancingInsurancesContractingLpiStep4();

  return (
    <LayoutColumnFlexComponentStyled>
      <FinancingInsurancesContractingLPIStep4DownloadComponent
        downloadedError={downloadedError}
        handleToggleDownloaded={handleToggleDownloaded}
      />
      <FinancingInsurancesContractingLPIDetailConfirmationComponent />
      <FinancingInsurancesContractingLPIStep4ChallengeComponent
        setChallenge={setChallenge}
        setChallengeError={setChallengeError}
        challengeError={challengeError}
        postalDocumentation={postalDocumentation}
      />
      <WizardStepFooterComponent
        {...FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS}
        targets={FOURTH_STEP_TARGETS}
        rightButtonConfiguration={{
          onClick: handleConfirmContractLPIButtonClick,
          disabled: !challenge || fetching,
          loading: fetching,
        }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingInsurancesContractingLPIStep4Component };
