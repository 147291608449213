const PARAGRAPH_PROPS_TITLE = {
  testId: 'amount-details-modal-description-total-amount-title',
  bold: true,
};
const PARAGRAPH_PROPS_VALUE = {
  testId: 'amount-details-modal-description-total-amount-value',
  bold: true,
};
const FORMATTED_NUMPER_SIGNS_PROP = 'never';

export { PARAGRAPH_PROPS_TITLE, PARAGRAPH_PROPS_VALUE, FORMATTED_NUMPER_SIGNS_PROP };
