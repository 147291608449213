const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.changePin.sidebar.';

export default {
  EXPANDABLE_TITLE_1: `${DOMAIN}expandableTitle01`,
  EXPANDABLE_TITLE_2: `${DOMAIN}expandableTitle02`,
  EXPANDABLE_TITLE_3: `${DOMAIN}expandableTitle03`,
  EXPANDABLE_DESCRIPTION_1: `${DOMAIN}expandableDescription01`,
  EXPANDABLE_DESCRIPTION_2: `${DOMAIN}expandableDescription02`,
  EXPANDABLE_DESCRIPTION_3: `${DOMAIN}expandableDescription03`,
  TITLE: `${DOMAIN}title`,
};
