// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const CaptchaComponentStyled = styled.div`
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.backgroundTertiaryD} 40%,
    ${({ theme }) => theme.colors.backgroundTertiaryE},
    ${({ theme }) => theme.colors.backgroundTertiaryD} 60%
  ) right / 300% 100%;
  height: ${convertPxsToRems({ pixels: 82, base: 16 })};
  width: ${convertPxsToRems({ pixels: 340, base: 16 })};
  border-width: ${convertPxsToRems({ pixels: 1, base: 16 })};
  border-style: solid;
  border-radius: ${convertPxsToRems({ pixels: 4, base: 16 })};
  border-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  animation: skeleton-loading 1.5s linear infinite;

  @keyframes skeleton-loading {
    to {
      background-position: left;
    }
  }
`;
CaptchaComponentStyled.displayName = 'CaptchaComponentStyled';

export { CaptchaComponentStyled };
