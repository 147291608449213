const ROW_TOGGLE_INPUT_PREFFIX_TEST_ID = 'row-toggle-input-currency-wrapper',
  DEFAULT_ROW_INPUT_PLACEHOLDER = '00,00',
  DEFAULT_TYPE_INPUT = 'currency',
  DEFAULT_ROW_INPUT_SUFFIX = '€',
  PARRAGRAPH_TEST_ID = 'settings-row-toggle-input-currency-paragraph';

export {
  ROW_TOGGLE_INPUT_PREFFIX_TEST_ID,
  DEFAULT_ROW_INPUT_PLACEHOLDER,
  DEFAULT_TYPE_INPUT,
  DEFAULT_ROW_INPUT_SUFFIX,
  PARRAGRAPH_TEST_ID,
};
