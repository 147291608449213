// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { RequestSignatureKeyModalBuilderHandlersType } from './types/request-signature-key-modal-builder.handlers.type';
import { RequestSignatureKeyModalBuilderReturnHandlersType } from './types/request-signature-key-modal-builder-return.handlers.type';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const closeLinkClickHandler = ({
  setOpenRequestSignatureModal,
}: Pick<RequestSignatureKeyModalBuilderHandlersType, 'setOpenRequestSignatureModal'>): void => {
  setOpenRequestSignatureModal(false);
};

const closeAssetClickHandler = ({
  setOpenRequestSignatureModal,
}: Pick<RequestSignatureKeyModalBuilderHandlersType, 'setOpenRequestSignatureModal'>): void => {
  setOpenRequestSignatureModal(false);
};

const confirmButtonClickHandler = ({
  navigate,
  setOpenRequestSignatureModal,
}: RequestSignatureKeyModalBuilderHandlersType): void => {
  setOpenRequestSignatureModal(false);
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.SECURITY, RoutesEnumeration.REQUEST_SIGNATURE_KEY])
    )
  );
};

const RequestSignatureKeyModalHandlers = ({
  navigate,
  setOpenRequestSignatureModal,
}: RequestSignatureKeyModalBuilderHandlersType): RequestSignatureKeyModalBuilderReturnHandlersType => ({
  handleConfirmButtonClick: () =>
    confirmButtonClickHandler({
      navigate,
      setOpenRequestSignatureModal,
    }),
  handleCloseAssetClick: () =>
    closeAssetClickHandler({
      setOpenRequestSignatureModal,
    }),
  handleCloseLinkClick: () =>
    closeLinkClickHandler({
      setOpenRequestSignatureModal,
    }),
});

export default RequestSignatureKeyModalHandlers;
