// Vendors
import React from 'react';
// Components
import AccessPortabilitySidebarComponent from '../../components/sidebar/access-portability-sidebar.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import { getRequestPortabilityWizardConfiguration } from './configurations/request-portability.configuration';
// Contexts
import { AccessPortabilityContextConsumerHOC } from '../../contexts/access-portability.context';
// Hooks
import RequestPortabilityHook from './hooks/request-portability.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { RequestPortabilityComponentStyled } from './request-portability.component.styled';

const RequestPortabilityComponent = (): React.ReactElement => {
  const { email } = RequestPortabilityHook();

  return (
    <RequestPortabilityComponentStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <WizardComponent {...getRequestPortabilityWizardConfiguration({ email })} />
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <AccessPortabilitySidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </RequestPortabilityComponentStyled>
  );
};

export { RequestPortabilityComponent };
export default AccessPortabilityContextConsumerHOC(RequestPortabilityComponent);
