// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Types
import { FinancingPPICancellationLandingResumeCoveredHookType } from '../hooks/types/financing-ppi-cancellation-landing-resume-covered.hook.type';

const getDateStartingFromCycleDate = (date: string): Date => {
  const currentDate = new Date(date);

  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
};

const getNextCycleBillingPPIDate = ({
  nextCycleDate,
  formatDate,
}: Pick<
  FinancingPPICancellationLandingResumeCoveredHookType,
  'nextCycleDate' | 'formatDate'
>): string =>
  formatDate(
    getDateStartingFromCycleDate(nextCycleDate),
    MiscellaneousDateConstants.FORMAT_DD_MM_YYYY
  );

export { getNextCycleBillingPPIDate };
