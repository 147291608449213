// Enumerations
import { TravelPlusNextStatusEnumeration } from '../enumerations/travel-plus-next-status.enumeration';

const getTravelPlusNextStatusLabel = (activated: boolean): TravelPlusNextStatusEnumeration =>
  activated ? TravelPlusNextStatusEnumeration.DISABLE : TravelPlusNextStatusEnumeration.ENABLE;

const getTravelPlusStatusLabel = (activated: boolean): TravelPlusNextStatusEnumeration =>
  activated ? TravelPlusNextStatusEnumeration.ENABLE : TravelPlusNextStatusEnumeration.DISABLE;

const maskCmsCardId = (cmsCardId: string): string => {
  return `****${cmsCardId}`;
};
export { getTravelPlusNextStatusLabel, getTravelPlusStatusLabel, maskCmsCardId };
