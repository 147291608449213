// Translations
import translations from 'containers/card-settings/views/landing/components/toggles/translations/card-settings-toggles.translations';
// Types
import { ToggleButtonComponentType } from '../types/toggle-button.types';

const checkExpirationDate = (date: string): boolean => {
  const expirationDate: Date = new Date(date);
  const actualDate: Date = new Date();

  return expirationDate.getTime() > actualDate.getTime();
};

const checkOnOff = (value: boolean): string =>
  value ? translations.SWITCHOFF : translations.SWITCHON;

const showExpirationDate = ({
  expirationDate,
  value,
}: Pick<ToggleButtonComponentType, 'expirationDate' | 'value'>): boolean =>
  !value && new Date().getTime() < new Date(String(expirationDate)).getTime();

export { checkExpirationDate, checkOnOff, showExpirationDate };
