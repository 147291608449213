// Vendors
import React from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TRANSACTIONS_TABLE_HEADER_LINKBAR_DOWNLOAD_MODAL_FILTER_DEFAULT_PROPS,
  MODAL_DESCRIPTION_ASSET_PROPS,
  MODAL_PARAGRAPH_DESCRIPTION_PROPS,
  EXCEL,
  PDF,
} from './constants/transactions-table-header-link-bar-download-modal.constants';
// Hooks
import { useTransactionsTableHeaderLinkBarDownloadModalHook } from './hook/transactions-table-header-link-bar-download-modal.hook';
// Styles
import { TransactionsTableHeaderLinkbarComponentStyled } from './transactions-table-header-link-bar-download-modal.component.styled';
// Translations
import {
  CANCEL,
  DESCRIPTION,
  MODAL_TITLE,
  PDF_DOWNLOAD,
  XLS_DOWNLOAD,
} from './translations/transactions-table-header-link-bar-download-modal.translations';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalComponentType } from './types/transactions-table-header-link-bar-download-modal.component.type';
// Utilities
import {
  isFetchingPDF,
  isFetchingXls,
} from './utils/transactions-table-header-link-bar-download-modal.utils';

const TransactionsTableHeaderLinkBarDownloadModalComponent = ({
  challenged,
  downloadDocumentType,
  handleHideSecurityCheckModal,
  handleShowSecurityCheckModal,
  isDownloadAvailable,
  isShowDownloadModal,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setFetchingDownload,
  setIsDownloadAvailable,
  setIsShowDownloadModal,
  showSecurityCheckModal,
}: TransactionsTableHeaderLinkBarDownloadModalComponentType): React.ReactElement | null => {
  const { fetching, formatMessage, handleDownloadButtonClick, handleOnClickCancel } =
    useTransactionsTableHeaderLinkBarDownloadModalHook({
      challenged,
      downloadDocumentType,
      handleHideSecurityCheckModal,
      handleShowSecurityCheckModal,
      isDownloadAvailable,
      isShowDownloadModal,
      progressId,
      setChallenge,
      setChallenged,
      setChallengeError,
      setDownloadDocumentType,
      setFetchingDownload,
      setIsDownloadAvailable,
      setIsShowDownloadModal,
      showSecurityCheckModal,
    });

  return isShowDownloadModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...TRANSACTIONS_TABLE_HEADER_LINKBAR_DOWNLOAD_MODAL_FILTER_DEFAULT_PROPS.linkConfiguration,
        children: formatMessage({ id: CANCEL }),
        onClick: handleOnClickCancel,
      }}
      onHeaderClose={handleOnClickCancel}
      primaryButtonConfiguration={{
        ...TRANSACTIONS_TABLE_HEADER_LINKBAR_DOWNLOAD_MODAL_FILTER_DEFAULT_PROPS.primaryButtonConfiguration,
        children: formatMessage({ id: PDF_DOWNLOAD }),
        onClick: () => handleDownloadButtonClick(PDF),
        loading: isFetchingPDF(downloadDocumentType, fetching),
        disabled: isFetchingXls(downloadDocumentType, fetching),
      }}
      secondaryButtonConfiguration={{
        ...TRANSACTIONS_TABLE_HEADER_LINKBAR_DOWNLOAD_MODAL_FILTER_DEFAULT_PROPS.secondaryButtonConfiguration,
        children: formatMessage({ id: XLS_DOWNLOAD }),
        onClick: () => handleDownloadButtonClick(EXCEL),
        loading: isFetchingXls(downloadDocumentType, fetching),
        disabled: isFetchingPDF(downloadDocumentType, fetching),
      }}
      title={formatMessage({ id: MODAL_TITLE })}
    >
      <TransactionsTableHeaderLinkbarComponentStyled>
        <AssetComponent {...MODAL_DESCRIPTION_ASSET_PROPS} />
        <ParagraphComponent {...MODAL_PARAGRAPH_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
      </TransactionsTableHeaderLinkbarComponentStyled>
    </ModalComponent>
  ) : null;
};

export { TransactionsTableHeaderLinkBarDownloadModalComponent };
