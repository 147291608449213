// Handlers
import { getPredictionsHandler } from './get-predictions.handler';
import { loadGoogleServicesHandler } from './load-google-services.handler';
// Types
import {
  UseAddressPredictionsHandlerPropsType,
  UseAddressPredictionsHandlerReturnType,
} from './types/use-address-predictions.handler.type';

export const UseAddressPredictionsHandler = ({
  autocompleteService,
  geocoderService,
  setPredictions,
  setIsLoading,
}: UseAddressPredictionsHandlerPropsType): UseAddressPredictionsHandlerReturnType => ({
  handleGetPredictions: ({ query, minTriggerLength }) =>
    getPredictionsHandler({
      autocompleteService,
      query,
      minTriggerLength,
      setIsLoading,
      setPredictions,
    }),
  handleLoadGoogleServices: () => {
    loadGoogleServicesHandler({ autocompleteService, geocoderService });
  },
});
