// Vendors
import React from 'react';
// Hooks
import { usePublicInteractionsHook } from './hooks/public-interactions.hook';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const PublicInteractionsProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  usePublicInteractionsHook();

  return children as React.ReactElement;
};

export { PublicInteractionsProvider };
