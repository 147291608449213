// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { changeBillingAddressResolver } from 'containers/personal/views/address/resolvers/personal-address.resolvers';
// Types
import { PersonalAddressStep1ActionsHandlersReturnType } from './types/personal-address-step2-actions-return.handlers.type';
import { PersonalAddressStep1ActionsHandlersType } from './types/personal-address-step2-actions.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';
// Utils
import { formatChangeAddressParams } from 'containers/personal/views/address/components/step2/utils/personal-address-step2.utils';

const nextButtonClickHandler = async ({
  address,
  challenge,
  newAddress,
  setChallenge,
  setChallengeError,
  setMe,
  setNewAddress,
  setResult,
}: NextButtonClickHandlerType) => {
  const [, error] = await changeBillingAddressResolver({
    params: formatChangeAddressParams(newAddress),
    progressId: challenge?.progressId,
  });

  if (error) {
    setChallenge(null);

    if (error === HttpStatusCodesEnum.FORBIDDEN) {
      setChallengeError(true);
    } else {
      setResult(false);
      setNewAddress(address);
    }
  } else {
    setMe(true);
    setResult(true);
  }
};

export const PersonalAddressStep2ActionsHandlers = (
  props: PersonalAddressStep1ActionsHandlersType
): PersonalAddressStep1ActionsHandlersReturnType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});
