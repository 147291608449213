// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { DashboardPointsTrackingHandlersType } from 'containers/dashboard/components/points/handlers/types/dashboard-points.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const knowMoreAboutPointsLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'knowMore',
  },
  type: TrackingEventsEnum.LINK,
});

const DashboardPointsTrackingHandlers = (
  track: TrackBuilderType
): DashboardPointsTrackingHandlersType => ({
  handleKnowMoreAboutPointsLinkClickTracking: () =>
    track(knowMoreAboutPointsLinkClickTrackingHandler()),
});

export { DashboardPointsTrackingHandlers };
