// Types
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';
import { ChangeIbanStep2ChallengeHandlersReturnType } from './types/change-iban-step2-challenge-return.handler.type';
import { ChangeIbanStep2ChallengeHandlersType } from './types/change-iban-step2-challenge.handler.type';
import { ChangeIbanStep2ChallengeMethodHandlersType } from './types/change-iban-step2-challenge-method.handler.type';

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<ChangeIbanStep2ChallengeHandlersType, 'setChallenged'>): void => {
  setChallenged(true);
};

const manageErrorOnChangeIbanEventHandler = ({
  setChallenged,
  setResult,
}: Pick<ChangeIbanStep2ChallengeHandlersType, 'setChallenged' | 'setResult'>): void => {
  setChallenged(false);
  setResult(false);
};

const manageSuccessOnChangeIbanEventHandler = ({
  progressId,
  setChallenged,
  setProgressId,
}: ChangeIbanStep2ChallengeMethodHandlersType): void => {
  setChallenged(false);
  if (progressId) {
    setProgressId(progressId);
  }
};

const challengeRetryButtonClickHandler = ({ setError }: ChallengeRetryButtonClickHandlerType) =>
  setError(false);

const ChangeIbanStep2ChallengeHandlers = (
  props: ChangeIbanStep2ChallengeHandlersType
): ChangeIbanStep2ChallengeHandlersReturnType => ({
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler(props),
  handleManageErrorOnChangeIbanEvent: () => manageErrorOnChangeIbanEventHandler(props),
  handleManageSuccessOnChangeIbanEvent: ({ progressId }): void =>
    manageSuccessOnChangeIbanEventHandler({ ...props, progressId }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler(props),
});

export { ChangeIbanStep2ChallengeHandlers };
