// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_ALL_RADIO_TRACKING,
  TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RADIO_ALL_EVENT_LABEL,
} from 'containers/transactions/views/epp/components/step1/components/simulation/components/options/components/all/components/slider/handlers/constants/transactions-epp-step1-simulation-options-all-slider.tracking.handlers.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TransactionsEppStep1SimulationOptionsAllTrackingMethodsHandlersType } from 'containers/transactions/views/epp/components/step1/components/simulation/components/options/components/all/components/slider/handlers/types/transactions-epp-step1-simulation-options-all-slider.tracking.handlers.type';

const changeInstallmentOptionSliderEventTrackingHandler = (
  value: number
): TrackingLinkEventReturnType => ({
  payload: {
    ...TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_ALL_RADIO_TRACKING,
    eventLabel: TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RADIO_ALL_EVENT_LABEL + value,
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsEppStep1SimulationOptionsAllSliderTrackingHandlers = (
  track: TrackBuilderType
): TransactionsEppStep1SimulationOptionsAllTrackingMethodsHandlersType => ({
  handleChangeAllOptionsSliderChangeEventTracking: (value: number) =>
    track(changeInstallmentOptionSliderEventTrackingHandler(value)),
});

export { TransactionsEppStep1SimulationOptionsAllSliderTrackingHandlers };
