// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { TransactionsEppStep2MessageHookType } from './types/transactions-epp-step2-message.hook.type';

const useTransactionsEppStep2MessageHook = (): TransactionsEppStep2MessageHookType => {
  const { eppPlanSimulation, transactionDetails } = useTransactionsEppContextConsumerHook(),
    { currentCardDetail } = AppContextConsumerHook(),
    { currencyCode, transactionLabel } = transactionDetails || {},
    { primaryAccountId } = currentCardDetail?.contractInfo || {},
    { loanAmount } = eppPlanSimulation || {};

  return {
    currencyCode,
    loanAmount,
    primaryAccountId,
    transactionLabel,
  };
};

export { useTransactionsEppStep2MessageHook };
