// Constants
import appConstants from 'constants/app.constants';
// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';
import { CountriesLocaleEnum, StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import {
  fetchDictionaryConfigurationResolver,
  fetchLanguageResolver,
  fetchLanguagesConfigurationResolver,
} from '../resolvers/internationalization.resolvers';
// Types
import { InternationalizationBuilderHandlersType } from './types/internationalization-builder.handlers.type';
import { InternationalizationBuilderReturnHandlersType } from './types/internationalization-builder-return.handlers.type';
import { InternationalizationDownloadHandlersType } from './types/internationalization-download.handlers.type';
// Utils
import {
  changeHostDomain,
  newTranslationsVersionAvailable,
} from './utils/internationalization.handlers.utils';

const downloadNewLanguageMessages = async ({
  response,
  setError,
  setFetching,
  setTranslations,
}: InternationalizationDownloadHandlersType): Promise<void> => {
  const { language, downloadUrl, lastChangedVersion } = response;

  if (!downloadUrl) {
    setError(ErrorCodesEnumeration.TRANSLATIONS);
    setFetching(false);
    return;
  }

  const [entries, error] = await fetchLanguageResolver(changeHostDomain(downloadUrl));

  if (error) {
    setError(ErrorCodesEnumeration.TRANSLATIONS);
  } else {
    setTranslations(entries);
    localStorage.setItem(StorageLocalEnum.TRANSLATIONS, JSON.stringify(entries));
    localStorage.setItem(StorageLocalEnum.VERSION, lastChangedVersion);
    localStorage.setItem(StorageLocalEnum.LANGUAGE, language);
  }
  setFetching(false);
};

const fetchDictionaryHandler = async () => {
  const [response, error] = await fetchDictionaryConfigurationResolver();

  if (!error && response) {
    localStorage.setItem(appConstants.DICTIONARY, JSON.stringify(response.config));
  }
};

const fetchTranslationsHandler = async ({
  language,
  setFetching,
  setError,
  setLocale,
  setTranslations,
}: InternationalizationBuilderHandlersType): Promise<void> => {
  localStorage.setItem(StorageLocalEnum.LANGUAGE, CountriesLocaleEnum.GERMANY); //  @TODO We force German until release stable date, of course, never =)
  localStorage.setItem(StorageLocalEnum.LOCALE, CountriesLocaleEnum.GERMANY); // @TODO We force German until release stable date, of course, never =)
  setFetching(true);
  setLocale(language);

  await fetchDictionaryHandler();

  const [response, error] = await fetchLanguagesConfigurationResolver();

  if (error) {
    setError(ErrorCodesEnumeration.TRANSLATIONS);
    setFetching(false);
  } else if (response && newTranslationsVersionAvailable(response)) {
    await downloadNewLanguageMessages({ response, setFetching, setError, setTranslations });
  } else {
    setFetching(false);
  }
};

const InternationalizationHandlers = (
  props: InternationalizationBuilderHandlersType
): InternationalizationBuilderReturnHandlersType => ({
  handleFetchTranslations: () => fetchTranslationsHandler(props),
});

export default InternationalizationHandlers;
