// Types
import {
  MessageComponentPropTypes,
  PersonalNumberStep1UtilsType,
  PersonalNumberStep1UtilsDefaultPrefixType,
} from './types/personal-number-step1.utils.type';
// Utils
import { getMappedPhonePrefixes } from 'utils/phone.utils';
import { isValidPhoneNumber } from 'utils/phone.utils';
// Types
import {
  InvalidPhoneNumberUtilTypes,
  InvalidPhoneMatchUtilTypes,
} from './types/personal-number-step1.utils.type';
// Constants
import constants from '../constants/personal-number-step1.constants';
// Translations
import {
  MESSAGE_TEXT,
  ERROR_MESSAGE_TEXT,
  MESSAGE_TITLE,
} from '../translations/personal-number-step1.translations';

const {
  PERSONAL_NUMBER_STEP_1_MESSAGE_PROPS,
  PERSONAL_NUMBER_STEP_1_MESSAGE_TITLE_PROPS,
  PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_TITLE_PROPS,
  PERSONAL_NUMBER_STEP_1_MESSAGE_DESCRIPTION_PROPS,
  PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_PROPS,
  PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_DESCRIPTION_PROPS,
} = constants;

const newNumberAndRepeatedDontMatch = ({
  phoneNumber,
  repeatPhoneNumber,
}: PersonalNumberStep1UtilsType): boolean =>
  Boolean(phoneNumber && repeatPhoneNumber && phoneNumber !== repeatPhoneNumber);

const getDefaultDropDownPrefixValue = ({
  prefixes,
  prefix,
  setFunction,
}: PersonalNumberStep1UtilsDefaultPrefixType): string | undefined => {
  const prefixValues = getMappedPhonePrefixes(prefixes);

  if (prefixValues) {
    const prefixFound = prefixValues.find(item => item.value === prefix);
    if (prefixFound) {
      setFunction(prefixFound.value);
      return prefixFound.value;
    }
  }

  return undefined;
};

const isInvalidPhone = ({ phoneNumber, prefix }: InvalidPhoneNumberUtilTypes): boolean => {
  const phoneNumberPattern = /^(?!0)\d{1,11}$/;
  const isValid =
    phoneNumberPattern.test(phoneNumber) && isValidPhoneNumber({ prefix, phoneNumber });
  return Boolean(prefix && phoneNumber && !isValid);
};

const IsValidPhoneMatch = ({
  prefix,
  phoneNumber,
  repeatPrefix,
  repeatPhoneNumber,
}: InvalidPhoneMatchUtilTypes): boolean =>
  Boolean(`${prefix}${phoneNumber}` === `${repeatPrefix}${repeatPhoneNumber}`);

const errorFeedbackConfiMapping: { [key: string]: MessageComponentPropTypes } = {
  false: {
    componentProps: PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_PROPS,
    descriptionProps: PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_DESCRIPTION_PROPS,
    titleProps: PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_TITLE_PROPS,
    text: ERROR_MESSAGE_TEXT,
    title: MESSAGE_TITLE,
  },
  true: {
    componentProps: PERSONAL_NUMBER_STEP_1_MESSAGE_PROPS,
    descriptionProps: PERSONAL_NUMBER_STEP_1_MESSAGE_DESCRIPTION_PROPS,
    titleProps: PERSONAL_NUMBER_STEP_1_MESSAGE_TITLE_PROPS,
    text: MESSAGE_TEXT,
    title: MESSAGE_TITLE,
  },
};

const getMessageComponentConfig = (value: boolean): MessageComponentPropTypes => {
  return errorFeedbackConfiMapping[value.toString()];
};

export {
  newNumberAndRepeatedDontMatch,
  getDefaultDropDownPrefixValue,
  getMessageComponentConfig,
  isInvalidPhone,
  IsValidPhoneMatch,
};
