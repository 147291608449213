// Constants
import { VALIDATION_ID } from '../constants/security-keys-signature-key-change.constants';
import { CONSTRAINTS_LABELS } from './constants/security-keys-signature-key-change-constraints.utils.constants';
// Types
import { SecurityKeysSignatureKeyChangeUtilsConstraintsUtilReturnType } from './types/security-keys-signature-key-change-return.constraints.util.type';
// Translations
import { FormatMessageType } from 'types/format-message.type';
// Enumerations
import { SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration } from '../components/second-step/enumerations/security-keys-signature-key-change-second-step.enumeration';

const hasFailedValidation = (
  validationMap: SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration[],
  validationId: SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration
): boolean => !validationMap.includes(validationId);

const getAllValidationIds = () => {
  return Object.values(
    VALIDATION_ID
  ) as SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration[];
};

export const getSignatureKeyConstraints = (
  failedValidationStatus: SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration[] | null,
  formatMessage: FormatMessageType
): SecurityKeysSignatureKeyChangeUtilsConstraintsUtilReturnType[] =>
  getAllValidationIds().map(validationId => ({
    label: formatMessage({ id: CONSTRAINTS_LABELS[validationId] }),
    check: () =>
      failedValidationStatus
        ? hasFailedValidation(failedValidationStatus, validationId)
        : undefined,
  }));
