// Constants
import {
  CUSTOMER_ROLE_T_CODE,
  DATE_LOCALE,
  DATE_FORMAT_OPTIONS,
} from './constants/profile-consents-step2.utils.constants';
// Enumerations
import { ConsentsGivenEnum } from 'containers/personal/views/consents/enumerations/consents-given.enum';
import { ConsentTypesEnum } from 'containers/personal/views/consents/enumerations/consent-types.enum';
// Types
import { ProfileConsentsConfig } from 'containers/personal/views/consents/types/profile-consent.config.type';
import { PatchConsentsType } from 'containers/personal/views/consents/types/patch-consents.type';
import { Consent } from 'containers/personal/views/consents/types/consent.type';
import { ProfileSubConsent } from 'containers/personal/views/consents/types/profile-consent.type';
import { SubConsentType } from 'containers/personal/views/consents/types/sub-consent.type';
// Utilities
import { isINTLConsent } from 'containers/personal/views/consents/utils/profile-consents.utils';

const getSubTypes = (subConsent: ProfileSubConsent): SubConsentType =>
  subConsent.id !== ConsentTypesEnum.NO_CONSENT
    ? {
        subTypeConsentGiven: subConsent.value ? ConsentsGivenEnum.YES : ConsentsGivenEnum.NO,
        consentSubType: subConsent.id,
        consentStGivenDate: new Date().toLocaleDateString(DATE_LOCALE, DATE_FORMAT_OPTIONS),
      }
    : {
        consentSubType: subConsent.id,
      };

const getConsentTypes = (consents: ProfileConsentsConfig['consentTypes']): Array<Consent> =>
  consents.flatMap(consent => {
    if (isINTLConsent(consent.id)) {
      return consent.subConsents.map(subConsent => ({
        consentGivenDate: new Date().toLocaleDateString(DATE_LOCALE, DATE_FORMAT_OPTIONS),
        consentType: subConsent.consentId,
        consentGiven: subConsent.value ? ConsentsGivenEnum.YES : ConsentsGivenEnum.NO,
        subTypes: [getSubTypes(subConsent)],
      }));
    }
    return {
      consentGivenDate: new Date().toLocaleDateString(DATE_LOCALE, DATE_FORMAT_OPTIONS),
      consentType: consent.id,
      consentGiven: consent.showConsents ? ConsentsGivenEnum.YES : ConsentsGivenEnum.NO,
      subTypes: consent.subConsents.map(subConsent => getSubTypes(subConsent)),
    };
  });

export const mapProfileConsentsToPatchConsents = (
  consents: ProfileConsentsConfig
): PatchConsentsType => ({
  body: {
    parties: [
      {
        customerId: consents.customerId,
        customerRoleTCode: CUSTOMER_ROLE_T_CODE,
      },
    ],
    cdpConsent: {
      consentTypes: getConsentTypes(consents.consentTypes),
    },
    productTypeTCode: consents.productTypeTCode,
  },
});
