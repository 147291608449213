// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useParams } from 'react-router-dom';
// Components
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportFraudulentMovementCheckInfoActionsComponent } from './components/actions/transactions-report-fraudulent-movement-confirmation-actions.component';
// Contexts
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Constants
import { DISPUTE_PARAMS } from './constants/transactions-report-fraudulent-movement-confirmation.constants';
// Contexts
import { TransactionsReportFraudulentMovementConsumerHook } from '../../contexts/transactions-report-fraudulent-movement.context';
// Styles
import { TransactionsReportFraudulentMovementConfirmationComponentStyled } from './transactions-report-fraudulent-movement-confirmation.component.styled';
// Hooks
import { AppContextConsumerHook } from 'contexts/app.context';
import { useAddressHook } from 'hooks/address.hook';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';
import {
  getClaimEnum,
  isStatusNotBlockedOrCanceled,
  getAdditionalDetails,
} from './utils/transactions-report-fraudulent-movement-confirmation.utils';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-fraudulent-movement-confirmation.component.translations';
// Utils
import { getSelectedAddress } from 'utils/addresses/addresses.utils';

const TransactionsReportFraudulentMovementConfirmationComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const { address: customerAddress } = useAddressHook();
  const { transactionId } = useParams();
  const {
    selectedOptions: {
      extraInformation,
      notRecognizeChargeClickLink,
      notRecognizeChargeHaveYourCard,
      notRecognizeChargeMotiveFraud,
    },
    files,
  } = TransactionsReportFraudulentMovementConsumerHook();

  if (notRecognizeChargeMotiveFraud === undefined) return <></>;

  const { blockReason, selectedAddress, tempAddress } = BlockCardContextConsumerHook();
  const { currentCard } = AppContextConsumerHook();
  const {
    id: cardId,
    cmsCardId = CharactersEnum.NO_VALUE,
    productType = { productFamily: CharactersEnum.NO_VALUE },
    status,
  } = currentCard || {};
  const address = getSelectedAddress({
    selectedAddress,
    tempAddress,
    customerAddress,
  });

  const isNotBlocked = isStatusNotBlockedOrCanceled(status);

  return (
    <TransactionsReportFraudulentMovementConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent
        claimType={formatMessage({ id: CLAIM_TYPE })}
        additional={getAdditionalDetails({
          status,
          cardType: productType.productFamily,
          cardNumber: cmsCardId,
          address,
          formatMessage,
        })}
      />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            blockCard: isNotBlocked,
            claimReasonType: getClaimEnum(notRecognizeChargeMotiveFraud),
            notAckChargeAndNotAuth: {
              notRecognizeChargeMotiveFraud,
              notRecognizeChargeHaveYourCard,
              notRecognizeChargeClickLink,
              notRecognizeChargeOtherInfo: extraInformation,
            },
            documents: getDocumentIds(files),
            ...(isNotBlocked
              ? {
                  blockCardInfo: {
                    reasonCode: blockReason,
                    address,
                  },
                }
              : {}),
          },
        }}
      />
      <TransactionsReportFraudulentMovementCheckInfoActionsComponent isNotBlocked={isNotBlocked} />
    </TransactionsReportFraudulentMovementConfirmationComponentStyled>
  );
};

export { TransactionsReportFraudulentMovementConfirmationComponent };
