// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import { FinancingInsurancesLandingNoInsurancesHandlers } from '../handlers/financing-insurances-landing-no-insurances.handlers';
import { FinancingInsurancesLandingNoInsurancesTrackingHandlers } from '../handlers/financing-insurances-landing-no-insurances.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingInsurancesLandingNoInsurancesReturnHandlersType } from '../handlers/types/financing-insurances-landing-no-insurances-return.handlers.type';

export const useFinancingInsurancesLandingNoInsurances =
  (): FinancingInsurancesLandingNoInsurancesReturnHandlersType => {
    const navigate = useNavigate();

    return FinancingInsurancesLandingNoInsurancesHandlers({
      ...AppTrackingHook(FinancingInsurancesLandingNoInsurancesTrackingHandlers),
      navigate,
    });
  };
