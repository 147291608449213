const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cardsLanding.cardDetails.';

const CARD_NUMBER = `${DOMAIN}cardNumberLabel`,
  CARD_INFO = `${DOMAIN}cardInfoLabel`,
  COPY = `${DOMAIN}copyCardNumberLink`,
  EXPIRY_DATE = `${DOMAIN}expiryDateLabel`,
  MONTHLY_MOVEMENTS = `${DOMAIN}montlyStatements`,
  VIEW_STATEMENTS = `${DOMAIN}viewStatementsLink`,
  VIEW_CARD_INFO = `${DOMAIN}viewCardInfoLink`;

export {
  CARD_NUMBER,
  CARD_INFO,
  COPY,
  EXPIRY_DATE,
  MONTHLY_MOVEMENTS,
  VIEW_STATEMENTS,
  VIEW_CARD_INFO,
};
