// Translations
import { ERROR, EMPTY } from '../translations/documentation.translations';

const DOCUMENTATION_CATEGORY_EMPTY_PROPS = {
  assetId: 'empty-no-result',
  translations: EMPTY,
};

const DOCUMENTATION_CATEGORY_ERROR_PROPS = {
  assetId: 'oops-alternative',
  translations: ERROR,
};

const DOCUMENTATION_CATEGORY_ERROR_RETRY_PROPS = {
  testId: 'retry-button',
};

const DOCUMENTATION_TITLE_PROPS = {
  assetConfiguration: { id: 'document' },
};

export {
  DOCUMENTATION_CATEGORY_EMPTY_PROPS,
  DOCUMENTATION_CATEGORY_ERROR_PROPS,
  DOCUMENTATION_CATEGORY_ERROR_RETRY_PROPS,
  DOCUMENTATION_TITLE_PROPS,
};
