// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const TableRowComponentStyled = styled.div.attrs(({ testId }: { testId: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
  width: 100%;

  &:last-child {
    border-bottom: unset;
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS};
  }
`;

const TableRowTopComponentStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: flex-start;
    gap: ${({ theme }) => theme.spaces.spacingXxs};
  }
`;

export { TableRowComponentStyled, TableRowTopComponentStyled };
