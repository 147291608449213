// Vendors
import React from 'react';
// Components
import SettingsCardControlToggleComponent from 'containers/card-settings/views/card-control/components/toggle/settings-card-control-toggle.component';
// Hooks
import SettingsCardControlOperationsToggleHook from './hooks/settings-card-control-operations-toggle.hook';
// Types
import { SettingsCardControlOperationsToggleComponentType } from './types/settings-card-control-operations-toggle.component.type';

const SettingsCardControlOperationsToggleComponent = ({
  id,
  translations,
}: SettingsCardControlOperationsToggleComponentType): React.ReactElement<SettingsCardControlOperationsToggleComponentType> => (
  <SettingsCardControlToggleComponent
    {...{ ...SettingsCardControlOperationsToggleHook({ id, translations }), id }}
  />
);

export default SettingsCardControlOperationsToggleComponent;
