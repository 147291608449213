export default {
  FETCH_CARD_ART_SERVICE_CONFIGURATION: {
    endpoint: 'cb-api-cards/v2/card-arts/:productType',
  },
  FETCH_CARDS_SERVICE_CONFIGURATION: {
    endpoint:
      'cb-api-cards/v3/cards?getMenus=EXPANDED&cardStatusCode=ACTIVE,INACTIVE,LOCKED,TEMPORARILY_BLOCKED,BLOCKED,CANCELED',
  },
  FETCH_CARD_DETAILS_SERVICE_CONFIGURATION: {
    endpoint: 'cb-api-cards/v3/cards/:cardId',
  },
  FETCH_CARD_DETAILS_PAN_CONFIGURATION: {
    endpoint: 'cb-api-cards/v3/cards/:cardId/pan',
  },
  FETCH_CARD_CVV_SERVICE_CONFIGURATION: {
    endpoint: 'cb-api-cards/v2/cards/:cardId/online-purchases',
  },
  FETCH_CARD_PIN_SERVICE_CONFIGURATION: {
    endpoint: 'cb-api-cards/v2/cards/:cardId/pin',
  },
};
