export const VALIDATION_CODE_ERROR = 'BUSINESS_VALIDATION_FAILED';
export const EDIT_BUTTON_PAYLOAD = {
    action: 'edit',
    format: 'icon',
    component: 'accordionSecurityDevice',
    element: 'editDeviceName',
    eventAction: 'clickIcon',
    eventLabel: 'editDeviceNameIcon'
  };

export const SAVE_BUTTON_PAYLOAD = {
  action: 'click',
  format: 'link',
  component: 'accordionSecurityDevice',
  element: 'save',
  eventAction: 'clickLink',
  eventLabel: 'saveLink'
};

export const CLICK_INPUT_PAYLOAD = {
  action: 'type',
  format: 'textBox',
  component: 'accordionSecurityDevice',
  element: 'newDeviceName',
  eventAction: 'clickTextBox',
  eventLabel: 'newDeviceNameTextBox'
};

export const MIN_LENGTH_ERROR_PAYLOAD = {
  component: 'accordionSecurityDevice',
  element: 'errorDeviceName',
  errorCategory: 'nameNeedMin6Characters',
  eventAction: 'clickTextBox',
  eventLabel: 'textBox - error'
};

export const REQUIRED_ERROR_PAYLOAD = {
  action: 'type',
  format: 'textBox',
  component: 'accordionSecurityDevice',
  element: 'errorDeviceName',
  errorCategory: 'youNeedHaveDeviceName',
  errorDescription: 'You need to have a device name',
  eventAction: 'clickTextBox',
  eventLabel: 'textBox - error'
};

export const VALIDATION_ERROR_PAYLOAD = {
  action: 'type',
  format: 'textBox',
  component: 'accordionSecurityDevice',
  element: 'errorDeviceName',
  errorCategory: 'validationErrorOcurred',
  errorDescription: 'Validation error occurred',
  eventAction: 'clickTextBox',
  eventLabel: 'textBox - error'
};

export const SNACKBAR_ERROR_OPEN_PAYLOAD = {
  action: 'open',
  format: 'snackbar',
  component: 'snackbar/error/notChangeDeviceName',
  element: 'full',
  eventAction: 'errorSnackbarNotChangeDeviceName',
  eventLabel:'view'
};

export const SNACKBAR_ERROR_CLOSE_PAYLOAD = {
  action: 'close',
  format: 'cross',
  component: 'snackbar/error/notChangeDeviceName',
  element: 'cross',
  eventAction: 'errorSnackbarNotChangeDeviceName',
  eventLabel: 'close'
};

export const SNACKBAR_SUCCESS_OPEN_PAYLOAD = {
  action: 'open', 
  format: 'snackbar', 
  component: 'snackbar/success/deviceNameChanged', 
  element: 'full',
  eventAction: 'successSnackbarDeviceNameChanged',
  eventLabel: 'view'
};

export const SNACKBAR_SUCCESS_CLOSE_PAYLOAD = {
  action: 'close',
  format: 'cross',
  component: 'snackbar/success/deviceNameChanged',
  element: 'cross',
  eventAction: 'successSnackbarDeviceNameChanged',
  eventLabel: 'close'
};
