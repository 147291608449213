// Vendors
import React, { useEffect } from 'react';
// Components
import {
  CardDetailsComponent,
  NavigationFooterComponent,
  ParagraphComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import securityVerificationConstants from './constants/security-verification.constants';
// Styles
import {
  AuthenticatorSpacerComponent,
  CardDetailsSpacerComponent,
  DescriptionSpacerComponent,
  TitleSpacerComponent,
} from './security-verification.component.styled';
// Translations
import securityVerificationTranslations from './translations/security-verification.translations';
// Types
import { SecurityVerificationComponentProps } from './types/security-verification.type';

const {
  cardDetailsProps,
  CHALLENGE_SERVICE_METHOD,
  challengeProps,
  descriptionProps,
  endpoints,
  leftLinkProps,
  rightButtonProps,
  titleProps,
} = securityVerificationConstants;

const SecurityVerificationComponent = ({
  card,
  cardId,
  onCancel,
  onContinue,
  challengeError,
  onError,
  onSuccess,
  onTryAgain,
  success,
  tracking,
  type,
}: SecurityVerificationComponentProps): React.ReactElement => {
  useEffect(() => {
    tracking.onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitleSpacerComponent>
        <TitleHeaderComponent {...titleProps}>
          <FormattedMessageComponent id={securityVerificationTranslations[type]?.TITLE} />
        </TitleHeaderComponent>
      </TitleSpacerComponent>

      <DescriptionSpacerComponent>
        <ParagraphComponent {...descriptionProps}>
          <FormattedMessageComponent id={securityVerificationTranslations[type]?.DESCRIPTION} />
        </ParagraphComponent>
      </DescriptionSpacerComponent>

      <CardDetailsSpacerComponent {...cardDetailsProps}>
        <CardDetailsComponent {...card} />
      </CardDetailsSpacerComponent>

      <AuthenticatorSpacerComponent {...challengeProps}>
        <ChallengeWrapperComponent
          baseService={endpoints[type]}
          baseServiceBuilder={{ pathParams: { cardId } }}
          baseServiceMethod={CHALLENGE_SERVICE_METHOD}
          challengeError={challengeError}
          onRetry={onTryAgain}
          onError={onError}
          onSuccess={onSuccess}
        />
      </AuthenticatorSpacerComponent>

      <NavigationFooterComponent
        leftLinkConfiguration={{
          ...leftLinkProps,
          children: (
            <FormattedMessageComponent id={securityVerificationTranslations[type]?.CANCEL} />
          ),
          onClick: onCancel,
        }}
        rightButtonConfiguration={{
          ...rightButtonProps,
          children: (
            <FormattedMessageComponent id={securityVerificationTranslations[type]?.CONTINUE} />
          ),
          disabled: !success,
          onClick: onContinue,
        }}
      />
    </>
  );
};

export default SecurityVerificationComponent;
