const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.reportProblem.defective.productInfo';

const LABEL_DATE = `${DOMAIN}.labelDate`,
  LABEL_DESCRIPTION = `${DOMAIN}.labelDescription`,
  LABEL_PROBLEM = `${DOMAIN}.labelProblem`,
  ERROR_DATE = `${DOMAIN}.errorDate`,
  ERROR_DATE_GREATER_THAN_TODAY = `${DOMAIN}.errorDateGreaterThanToday`,
  PLACEHOLDER_DESCRIPTION = `${DOMAIN}.placeholderDescription`,
  PLACEHOLDER_PROBLEM = `${DOMAIN}.placeholderProblem`,
  INVALID_DATE = `${DOMAIN}.invalidDate`;

export {
  LABEL_DATE,
  LABEL_DESCRIPTION,
  LABEL_PROBLEM,
  ERROR_DATE,
  ERROR_DATE_GREATER_THAN_TODAY,
  INVALID_DATE,
  PLACEHOLDER_DESCRIPTION,
  PLACEHOLDER_PROBLEM,
};
