// Vendors
import React from 'react';
// Components
import {
  RadioButtonComponent,
  ParagraphComponent,
  MessageComponent,
} from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { messageConstants } from '@openbank/cb-ui-commons';
import { SUBSCRIPTION_ALREADY_CANCELLED_ADDITIONAL_INFO } from './constants/report-subscription-already-cancelled-additional-info.constants';
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { ReportSubscriptionAlreadyCancelledAdditionalInformationHook } from './hooks/report-subscription-already-cancelled-additional-info.hook';
// Translations
import {
  QUESTION_SUBS_ALREADY_CANCELLED,
  MESSAGE_INFO,
  TITLE_DATE_AFTER,
  TITLE_DATE_BEFORE,
} from './translations/report-subscription-already-cancelled-additional-info.translations';
// Styles
import {
  ReportSubscriptionAlreadyCancelledAdditionalInformationContainerStyled,
  ReportSubscriptionAlreadyCancelledAdditionalInformationOptionsStyled,
} from './report-subscription-already-cancelled-additional-info.component.styles';
// Utils
import { isSameObject } from 'utils/app.utils';

export const ReportSubscriptionAlreadyCancelledAdditionalInfoComponent = (): React.ReactElement => {
  const {
    cancelledSubDateBefore,
    existCancellationSubDate,
    formatMessage,
    setCancelledSubDateBefore,
  } = ReportSubscriptionAlreadyCancelledAdditionalInformationHook();

  return (
    <ReportSubscriptionAlreadyCancelledAdditionalInformationContainerStyled>
      <ReportSubscriptionAlreadyCancelledAdditionalInformationOptionsStyled
        {...SUBSCRIPTION_ALREADY_CANCELLED_ADDITIONAL_INFO.WRAPPER_OPTIONS}
      >
        <FormattedMessageComponent id={QUESTION_SUBS_ALREADY_CANCELLED} />
        <RadioButtonComponent
          onChange={ev => setCancelledSubDateBefore(!!ev.target.value)}
          value={existCancellationSubDate && cancelledSubDateBefore}
          title={formatMessage({ id: TITLE_DATE_BEFORE })}
        />
        <RadioButtonComponent
          onChange={ev => setCancelledSubDateBefore(!ev.target.value)}
          value={existCancellationSubDate && !cancelledSubDateBefore}
          title={formatMessage({ id: TITLE_DATE_AFTER })}
        />
        {existCancellationSubDate && !cancelledSubDateBefore && (
          <MessageComponent type={messageConstants.TYPES.ERROR}>
            <ParagraphComponent>
              <FormattedMessageComponent id={MESSAGE_INFO} />
            </ParagraphComponent>
          </MessageComponent>
        )}
      </ReportSubscriptionAlreadyCancelledAdditionalInformationOptionsStyled>
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        rightButtonConfiguration={{ disabled: !cancelledSubDateBefore }}
        targets={FIRST_STEP_TARGETS}
        shouldShowModal={!isSameObject(cancelledSubDateBefore, undefined)}
      />
    </ReportSubscriptionAlreadyCancelledAdditionalInformationContainerStyled>
  );
};
