// Constants
import { GET_3D_SECURE_SERVICE_CONFIGURATION } from 'containers/card-settings/views/check-3d/components/wizard/components/step2/services/constants/card-settings-check-3d-wizard-step2.services.constants';

const CARD_SETTINGS_CHECK_3D_WIZARD_STEP_2_CHALLENGE_PROPS = {
  baseService: GET_3D_SECURE_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: {},
  baseServiceMethod: 'get',
  challengeError: false,
};

export { CARD_SETTINGS_CHECK_3D_WIZARD_STEP_2_CHALLENGE_PROPS };
