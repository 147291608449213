// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Enumerations
import { CardControlIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-ids.enumeration';
// Types
import { SettingsCardControlToggleBuilderReturnTrackingHandlersType } from './types/settings-card-control-toggle-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const changeCardControlTabClickTrackingHandler = (
  id: CardControlIdsEnumeration
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: `changeCardControlFlag:${id}`,
  },
  type: TrackingEventsEnum.LINK,
});

const SettingsCardControlsTrackingHandlers = (
  track: TrackBuilderType
): SettingsCardControlToggleBuilderReturnTrackingHandlersType => ({
  handleChangeCardControlItemClickTracking: (id: CardControlIdsEnumeration) =>
    track(changeCardControlTabClickTrackingHandler(id)),
});

export default SettingsCardControlsTrackingHandlers;
