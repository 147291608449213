// Vendors
import { convertPxsToRems } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

export const AssetComponentStyled = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  border-radius: ${({ theme }) => theme.spaces.spacingXxxs};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: ${({ theme }) => theme.spaces.spacingS};
  height: ${convertPxsToRems({ pixels: 180, base: 14 })};
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const CancelModalDescriptionComponentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const CancelModalDescriptionSubtitleStyled = styled.div`
  align-items: center;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: ${({ theme }) => theme.spaces.spacingM};
  justify-content: flex-start;
`;

export const ModalComponentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
`;
