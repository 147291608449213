// Configurations
import {
  getLinkTypeTrackingConfiguration,
  getViewTypeTrackingConfiguration,
} from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import constants from './constants/step-5-credit-limit-increase.tracking.constants';
// Types
import { Step5CreditIncreaseLimitTrackingHandlersType } from './types/step-5-credit-limit-increase.tracking.type';

export const Step5CreditLimitChageTracking = (
  track: (param: unknown) => void
): Step5CreditIncreaseLimitTrackingHandlersType => ({
  handleEnterViewTracking: () => track(getViewTypeTrackingConfiguration(constants.VIEW)),
  handleClickGoToFinancingTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.GO_TO_FINANCING)),
  handleClickGoToSignContractTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.GO_TO_SIGN_CONTRACT)),
  handleClickRejectOfferTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.REJECT_OFFER)),
  // TODO: add the rest of the trackings
});

export default Step5CreditLimitChageTracking;
