// Vendors
import React from 'react';
// Components
import { FilterChipsComponent } from 'components/filters/components/filter-chips/filter-chips.component';
import TransactionsTableTitleComponent from '../title/transactions-table-title.component';
import TransactionsTableHeaderLinkBarComponent from './components/link-bar/transactions-table-header-link-bar.component';
// Types
import { TransactionsTableFilterModalComponentPropsType } from './types/transactions-table-header.component.type';
// Styles
import {
  TransactionsTableHeaderActionsStyled,
  TransactionsTableHeaderOrganismStyled,
} from './transactions-table-header.styled';
// Utilities
import { getTableOrganismPropsById } from '@/organisms/table-organism/utils/table-organism.utils';

const TransactionsTableHeaderComponent = ({
  challenged,
  downloadDocumentType,
  formValues,
  formValuesParams,
  handleHideSecurityCheckModal,
  handleShowSecurityCheckModal,
  idTable,
  isDownloadAvailable,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setCopyOfFormValues,
  setDownloadDocumentType,
  setFetchingDownload,
  setFormValues,
  setFormValuesParams,
  setIsDownloadAvailable,
  showSecurityCheckModal,
  title,
}: TransactionsTableFilterModalComponentPropsType): React.ReactElement => (
  <TransactionsTableHeaderOrganismStyled
    {...{ innerPadding: getTableOrganismPropsById(idTable).innerPadding }}
  >
    <TransactionsTableHeaderActionsStyled>
      <TransactionsTableTitleComponent {...{ title }} />
      {getTableOrganismPropsById(idTable).showFilters && (
        <TransactionsTableHeaderLinkBarComponent
          {...{
            challenged,
            downloadDocumentType,
            handleHideSecurityCheckModal,
            handleShowSecurityCheckModal,
            idTable,
            isDownloadAvailable,
            listLinksConfigurations: getTableOrganismPropsById(idTable).listLinksConfigurations,
            progressId,
            setChallenge,
            setChallenged,
            setChallengeError,
            setDownloadDocumentType,
            setFetchingDownload,
            setIsDownloadAvailable,
            showActionBar: getTableOrganismPropsById(idTable).showActionBar,
            showSecurityCheckModal,
          }}
        />
      )}
    </TransactionsTableHeaderActionsStyled>
    {getTableOrganismPropsById(idTable).showFilters && (
      <FilterChipsComponent
        {...{
          formValues,
          formValuesParams,
          setCopyOfFormValues,
          setFormValues,
          setFormValuesParams,
        }}
      />
    )}
  </TransactionsTableHeaderOrganismStyled>
);

export { TransactionsTableHeaderComponent };
