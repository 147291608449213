// Vendors
import React, { useState, useContext, createContext, useEffect } from 'react';
// Types
import { WizardFeedbackContextType } from './types/wizard-feedback.context.type';

const WizardFeedbackContext = createContext<WizardFeedbackContextType>(
  {} as WizardFeedbackContextType
);

const WizardFeedbackContextProvider = ({
  defaultResult,
  children,
}: React.PropsWithChildren<{ defaultResult?: boolean }>): React.ReactElement<
  React.Provider<WizardFeedbackContextType>
> => {
  const [result, setResult] = useState<boolean | undefined>(defaultResult);

  useEffect(() => {
    setResult(defaultResult);
  }, [defaultResult]);

  return (
    <WizardFeedbackContext.Provider value={{ result, setResult }}>
      {children as React.ReactNode}
    </WizardFeedbackContext.Provider>
  );
};

const useWizardFeedbackContextConsumerHook = (): WizardFeedbackContextType =>
  useContext(WizardFeedbackContext);

/* eslint-disable */
const WizardFeedbackContextConsumerHOC =
  (Component: React.FunctionComponent<any>): React.FunctionComponent<any> =>
  (props: any): React.ReactElement<React.Provider<WizardFeedbackContextType>> =>
    (
      <WizardFeedbackContextProvider defaultResult={props.defaultResult}>
        <Component {...props} />
      </WizardFeedbackContextProvider>
    );

export { WizardFeedbackContextConsumerHOC, useWizardFeedbackContextConsumerHook };
