// Constants
import {
  ASCENDING_DESCENDING_REGEX,
  CONSECUTIVE_NUMBERS_REGEX,
  ONLY_4_NUMBERS,
} from './constants/card-settings-change-pin-constraints.utils.constants';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';

const checkHasFourNumbersOnly = (value: string): boolean => Boolean(value.match(ONLY_4_NUMBERS));

const checkNoAscendingDescendingSequences = (value: string): boolean =>
  Boolean(value.match(ASCENDING_DESCENDING_REGEX));

const noContain3OrMoreConsecutiveAndEqualsNumbers = (value: string): boolean =>
  value.match(CONSECUTIVE_NUMBERS_REGEX) !== null;

const notMatchMontDayOrYearCustomerBirthDate = ({ dateOfBirth, value }: any): boolean =>
  dateOfBirth?.split(CharactersEnum.NO_VALUE).every((date: string) => !value.includes(date));

export {
  checkNoAscendingDescendingSequences,
  checkHasFourNumbersOnly,
  noContain3OrMoreConsecutiveAndEqualsNumbers,
  notMatchMontDayOrYearCustomerBirthDate,
};
