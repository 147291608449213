// Vendors
import React from 'react';
// Components
import SecurityKeysSignatureKeyChangeSecurityKeyComponent from '../security-key-component/security-keys-signature-key-change-second-step-security-key.component';
import SecurityKeysSignatureKeyChangeFirstStepActionsComponent from './actions/signature-key-change-step1-actions.component';
// Translations
import translations from '../../translations/security-keys-signature-key-change.translations';
// Hooks
import SecurityKeysSignatureKeyChangeFirstStepHook from './hooks/signature-key-change-step1.hook';

const SecurityKeysSignatureKeyChangeFirstStep = (): React.ReactElement => {
  const {
    handleOpenRequestSignatureKeyModalEvent,
    handlePasswordPositionInputClickTracking,
    handleSaveNewModifySignatureKeyChangeInputEvent,
    signatureKey,
    handleShowHidePasswordClickTracking,
  } = SecurityKeysSignatureKeyChangeFirstStepHook();

  return (
    <>
      <SecurityKeysSignatureKeyChangeSecurityKeyComponent
        analytics={{ handleOnClick: () => handleShowHidePasswordClickTracking() }}
        value={signatureKey}
        title={translations.PASSWORD_COMPONENT_TITLE_FIRST_STEP}
        onClick={handlePasswordPositionInputClickTracking}
        description={translations.PASSWORD_COMPONENT_DESCRIPTION_FIRST_STEP}
        onChange={handleSaveNewModifySignatureKeyChangeInputEvent}
        recoveryLink={handleOpenRequestSignatureKeyModalEvent}
      />
      <SecurityKeysSignatureKeyChangeFirstStepActionsComponent />
    </>
  );
};

export default SecurityKeysSignatureKeyChangeFirstStep;
