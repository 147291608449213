// Configurations
import {
  getLinkTypeTrackingConfiguration,
  getViewTypeTrackingConfiguration,
} from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import constants from './constants/step-1-credit-limit-increase.tracking.constants';
// Types
import { Step1CreditIncreaseLimitTrackingType } from './types/step-1-credit-limit-increase-tracking.type';

export const Step1CreditLimitChageTracking = (
  track: (param: unknown) => void
): Step1CreditIncreaseLimitTrackingType => ({
  handleEnterViewTracking: () =>
    track(getViewTypeTrackingConfiguration(constants.VIEW.STEP1_SET_NEW_LIMIT_VIEW)),
  handleClickChangeCreditLimitBoxTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.TEXT_BOX_CLICK)),
  handleClickCreditLimitSlider: (value: string) =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.CREDIT_LIMIT_SLIDER_CHANGE(value))), // TODO use it when commons allows it
});

export default Step1CreditLimitChageTracking;
