// Enumerations
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

const NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_PROPS = {
  type: SectionDividerTypesEnumeration.INFORMATIVE,
},
NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_TEST_ID = 'notifications-settings-category-section-title',
NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TEXT_TEST_ID = 'notifications-settings-category-section-text',
NOTIFICATTIONS_SETTINGS_CATEGORY_ROW_TOGGLE_TEST_ID = 'notifications-settings-category-row-toggle';

export { 
  NOTIFICATTIONS_SETTINGS_CATEGORY_ROW_TOGGLE_TEST_ID,
  NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TEXT_TEST_ID,
  NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_PROPS,
  NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_TEST_ID,
};