// Types
import {
  FilterModalInputRangeDatePickerHandlerPropsType,
  FilterModalInputRangeDatePickerHandlerReturnType,
} from './types/filter-modal-input-range-date-picker.handlers.type';

const onChangeSelectRange = ({ range, onChangeFromInput, onChangeToInput }) => {
  onChangeFromInput(range.from);
  onChangeToInput(range.to);
};

const FilterModalInputRangeDatePickerHandler = ({
  onChangeFromInput,
  onChangeToInput,
}: FilterModalInputRangeDatePickerHandlerPropsType): FilterModalInputRangeDatePickerHandlerReturnType => ({
  handleSelectRange: range => {
    onChangeSelectRange({ range, onChangeFromInput, onChangeToInput });
  },
});

export { FilterModalInputRangeDatePickerHandler };
