export default {
  TRANSACTIONS_LANDING_ROW_DETAILS_CATEGORY_PROPS: {
    testId: 'transactions-landing-row-details-category',
  },
  TRANSACTIONS_LANDING_ROW_DETAILS_CATEGORY_LINK_PROPS: {
    testId: 'transactions-landing-row-details-category-links',
    disabled: true,
  },
  AUTHORIZED: 'authorized',
};
