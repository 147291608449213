// Vendors
import React from 'react';
// Components
import {
  linkConstants,
  MessageComponent,
  messageConstants,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Enumerations
import { PlanErrorEnumeration } from 'containers/financing/views/ppi/components/contracting/lpi/components/step2/enumerations/plan-error.enumeration';
// Hooks
import { useFinancingInsurancesContractingLPIStep2PlansError } from './hooks/financing-insurances-contracting-lpi-step2-plans-error.hook';
// Styles
import { FinancingInsurancesContractingLPIStep2PlansErrorMessageStyled } from './financing-insurances-contracting-lpi-step2-plans-error.component.styled';
// Translations
import {
  INSUFFICIENT_CREDIT_ERROR,
  NOT_SELECTED_ERROR,
} from './translations/financing-insurances-contracting-lpi-step2-plans-error.translations';
// Types
import { FinancingInsurancesContractingLPIStep2PlansErrorType } from './types/financing-insurances-contracting-lpi-step2-plans-error.component.type';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

export const FinancingInsurancesContractingLPIStep2PlansErrorComponent = ({
  error,
}: FinancingInsurancesContractingLPIStep2PlansErrorType): React.ReactElement | null => {
  const { handleCreditLimitIncreaseLinkClick } =
    useFinancingInsurancesContractingLPIStep2PlansError();

  if (!error) {
    return null;
  }

  return (
    <MessageComponent type={messageConstants.TYPES.ERROR}>
      {error === PlanErrorEnumeration.INSUFFICIENT_CREDIT ? (
        <FinancingInsurancesContractingLPIStep2PlansErrorMessageStyled>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={INSUFFICIENT_CREDIT_ERROR.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent
              id={INSUFFICIENT_CREDIT_ERROR.description}
              values={{
                creditLimitLink: chunk =>
                  makeChunkLinkIntlUtil({
                    chunk,
                    onClick: handleCreditLimitIncreaseLinkClick,
                    type: linkConstants.TYPES.ACTION_PRIMARY,
                  }),
              }}
            />
          </ParagraphComponent>
        </FinancingInsurancesContractingLPIStep2PlansErrorMessageStyled>
      ) : (
        <ParagraphComponent>
          <FormattedMessageComponent id={NOT_SELECTED_ERROR} />
        </ParagraphComponent>
      )}
    </MessageComponent>
  );
};
