// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PARAGRAPH_PROPS_TITLE,
  PARAGRAPH_PROPS_VALUE,
  FORMATTED_NUMPER_SIGNS_PROP,
} from './constants/total-amount-component.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Styles
import { AmountDetailsModalRowDescriptionComponentStyled } from '../../amount-details-modal.component.styled';
// Translations
import { TOTAL_AMOUNT } from '../../translations/amount-details-modal.translations';
// Types
import { CardTransactionDetailedAmountType } from 'types/card-transactions-detailed-amount.type';

export const TotalAmountComponent = ({
  totalAmount,
}: Pick<CardTransactionDetailedAmountType, 'totalAmount'>): React.ReactElement | null => (
  <AmountDetailsModalRowDescriptionComponentStyled>
    <ParagraphComponent {...PARAGRAPH_PROPS_TITLE}>
      <FormattedMessageComponent id={TOTAL_AMOUNT} />
    </ParagraphComponent>
    <ParagraphComponent {...PARAGRAPH_PROPS_VALUE}>
      <FormattedNumber
        {...EURO_FORMAT_OPTIONS}
        value={totalAmount}
        signDisplay={FORMATTED_NUMPER_SIGNS_PROP}
      />
    </ParagraphComponent>
  </AmountDetailsModalRowDescriptionComponentStyled>
);