// Constants
import {
  ASSET_PROPS,
  DEFAULT_REPORT_VALUE,
  LINK_PROPS,
  SELECTOR_BOX_ADDRESS_ACTIONS_ID,
} from './constants/block-card-address.configurations.constants';
// Translations
import {
  DELETE_ACTION,
  EDIT_ACTION,
} from './translations/block-card-address.configurations.translations';
// Types
import { GetInputSelectorBoxAddressConfigurationType } from './types/get-input-selector-box-address-configuration.type';
import { GetInputSelectorBoxAddressConfigurationReturnType } from './types/get-input-selector-box-address-configuration-return.type';
// Utils
import { isTemporalAddress } from 'utils/addresses/addresses.utils';
import {
  getSelectorBoxTestId,
  getSelectorBoxTitle,
  hasActions,
  hasEmptyTempAddress,
} from './utils/block-card-address.configurations.utils';

export const getInputSelectorBoxAddressConfiguration = ({
  address,
  formatMessage,
  handleSelectAddress,
  handleOpenAddressModal,
  handleOpenEditAddressModal,
  handleOpenDeleteModal,
  selectedAddress,
  type,
}: GetInputSelectorBoxAddressConfigurationType): GetInputSelectorBoxAddressConfigurationReturnType => {
  const isTemp = isTemporalAddress(type);
  const isEmpty = hasEmptyTempAddress({ address, isTemp });
  const actions = hasActions({ address, isTemp })
    ? {
        deleteLinkConfiguration: {
          ...LINK_PROPS,
          ...SELECTOR_BOX_ADDRESS_ACTIONS_ID.DELETE,
          children: formatMessage({ id: DELETE_ACTION }),
          onClick: handleOpenDeleteModal,
        },
        editLinkConfiguration: {
          ...LINK_PROPS,
          ...SELECTOR_BOX_ADDRESS_ACTIONS_ID.EDIT,
          children: formatMessage({ id: EDIT_ACTION }),
          onClick: handleOpenEditAddressModal,
        },
      }
    : {};

  return {
    ...actions,
    assetConfiguration: isEmpty ? ASSET_PROPS : undefined,
    onClick: isEmpty ? handleOpenAddressModal : () => handleSelectAddress(type),
    selected: selectedAddress === type,
    testId: getSelectorBoxTestId({ type }),
    title: formatMessage({ id: getSelectorBoxTitle({ isTemp, address }) }),
  };
};
