// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Configurations
import { getAllEnabledCountries } from '../../../configurations/settings-card-control-locations.configurations';
// Enums
import { SettingsCardControlLocationEnabledEnumeration } from '../enumerations/settings-card-control-locations-map.enumeration';
// Types
import { SettingCardControlLocationsUtilsType } from 'containers/card-settings/views/card-control/components/locations/utils/types/setting-card-control-locations.utils.type';
import {
  GeographyStylesReturnType,
  SettingsCardControlLocationsMapUtilsType,
} from './types/setting-card-control-locations-map.types';
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';

const getGeographyStyles = ({
  id,
  continents,
  mainCountryId,
  mainCountryName,
}: SettingCardControlLocationsUtilsType): GeographyStylesReturnType => {
  const defaultColor = getEnabledOrDisabledColor({
    id,
    continents,
    mainCountryId,
    mainCountryName,
  });
  return {
    default: { fill: defaultColor, outline: defaultColor, stroke: defaultColor },
    hover: { fill: defaultColor, outline: defaultColor, stroke: defaultColor },
    pressed: {
      fill: defaultColor,
      outline: defaultColor,
      stroke: defaultColor,
    },
  };
};

const countryIsEnabledByName = ({
  id,
  continents,
}: SettingCardControlLocationsUtilsType): string | undefined => {
  const continent = continents?.find(({ countries }: CardControlContinentComponentType) =>
    countries?.some(({ name }: CardControlContinentCountryComponentType) => name === id)
  );
  return continent?.status;
};

const isOnlyMainEnabled = ({
  enabledCountries,
  mainCountryId,
}: SettingsCardControlLocationsMapUtilsType): boolean => {
  const mainCountryExists = enabledCountries.some(({ id }) => id === mainCountryId);
  const hasOtherId = enabledCountries.some(({ id }) => id !== mainCountryId);
  return mainCountryExists && !hasOtherId;
};

const getEnabledOrDisabledColor = ({
  id,
  continents,
  mainCountryId,
  mainCountryName,
}: SettingCardControlLocationsUtilsType): string => {
  const enabledCountries = getAllEnabledCountries(continents);
  const { ALL_ENABLED, ALL_DISABLED, PARTIALLY_ENABLED } =
    SettingsCardControlLocationEnabledEnumeration;

  if (isOnlyMainEnabled({ enabledCountries, mainCountryId })) {
    return id === mainCountryName
      ? AmazonTheme.default.colors.brandB
      : AmazonTheme.default.colors.backgroundTertiaryC;
  }

  const isEnabled = countryIsEnabledByName({ id, continents });
  switch (isEnabled) {
    case ALL_ENABLED:
      return AmazonTheme.default.colors.brandB;
    case ALL_DISABLED:
      return AmazonTheme.default.colors.backgroundTertiaryC;
    case PARTIALLY_ENABLED:
      return AmazonTheme.default.colors.brandA;
    default:
      return '';
  }
};

export { getGeographyStyles, getEnabledOrDisabledColor };
