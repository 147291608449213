// Vendors
import { createV4Uuid, getDeviceLoginParams } from '@openbank/cf-ui-framework';
// Enumerations
import { SssDocumentTypesEnum } from '@openbank/cf-ui-static-data';

export default {
  PASSWORD_POSITIONS_SERVICE_CONFIGURATION: {
    endpoint: 'cb-api-auth/authentication/users/password-positions',
  },
  LOGIN_SERVICE_CONFIGURATION: {
    endpoint: 'cb-api-auth/authentication/login',
  },
  LOGIN_SERVICE_PARAMS_CONFIGURATION: {
    ...getDeviceLoginParams({}),
    customerSessionId: createV4Uuid(),
    documentType: SssDocumentTypesEnum.EMAIL,
  },
  PASSWORD_POSITIONS_SERVICE_PARAMS_CONFIGURATION: {
    documentType: SssDocumentTypesEnum.EMAIL,
  },
};
