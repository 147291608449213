// Enumerations
import { NotRecognizeChargeMotiveFraudEnum } from 'containers/transactions/views/report/enumerations/not-recognize-charge-motive-fraud.enumeration';
// Types
import { TransactionsReportFraudulentMovementAdditionalInfoOptionsBuildType } from '../constants/types/transactions-report-fraudulent-movement-additional-info-options-build.constants.type';
import { TransactionsReportFraudulentMovementAdditionalInfoOptionsReturnType } from '../constants/types/transactions-report-fraudulent-movement-additional-info-options-return.constants.type';
import { IsSelectedType } from './types/is-selected.type';
// Translations
import * as translations from '../translations/transactions-report-fraudulent-movement-first-options.translations';

const isSelected = ({ value, selected }: IsSelectedType): boolean => value === selected;

const getCardId = (cardNumber: string): string => `***${cardNumber}`;

const getInfoOptions = ({
  formatMessage,
  cardIdValue,
}: TransactionsReportFraudulentMovementAdditionalInfoOptionsBuildType): TransactionsReportFraudulentMovementAdditionalInfoOptionsReturnType => [
  {
    title: formatMessage(
      {
        id: translations.FIRST_CONFIRM_TITLE,
      },
      { value: cardIdValue }
    ),

    fieldName: 'notRecognizeChargeHaveYourCard',
    options: [
      {
        helperMessage: formatMessage({
          id: translations.FIRST_CONFIRM_AFFIRMATIVE,
        }),
        testId: 'transactions-report-landing-options-not-recognize-charge-have-your-card',
        value: true,
        id: true,
      },
      {
        helperMessage: formatMessage({ id: translations.FIRST_CONFIRM_NEGATIVE }),
        testId: 'transactions-report-landing-options-not-recognize-charge-not-have-your-card',
        value: false,
        id: false,
      },
    ],
  },
  {
    title: translations.SECOND_CONFIRM_TITLE,
    fieldName: 'notRecognizeChargeClickLink',
    options: [
      {
        helperMessage: formatMessage({ id: translations.SECOND_CONFIRM_AFFIRMATIVE }),
        testId: 'transactions-report-landing-options-not-recognize-charge-click-link',
        value: true,
        id: true,
      },
      {
        helperMessage: formatMessage({ id: translations.SECOND_CONFIRM_NEGATIVE }),
        testId: 'transactions-report-landing-options-not-recognize-charge-not-click-link',
        value: false,
        id: false,
      },
    ],
  },
  {
    title: translations.THIRD_CONFIRM_TITLE,
    fieldName: 'notRecognizeChargeMotiveFraud',
    options: [
      {
        helperMessage: formatMessage({ id: translations.THIRD_CONFIRM_FIRST_OPTION }),
        testId:
          'transactions-report-landing-options-not-recognize-charge-motive-fraud-not-received',
        value: NotRecognizeChargeMotiveFraudEnum.CARD_NOT_RECEIVED_FRAUD,
        id: NotRecognizeChargeMotiveFraudEnum.CARD_NOT_RECEIVED_FRAUD,
      },
      {
        helperMessage: formatMessage({ id: translations.THIRD_CONFIRM_SECOND_OPTION }),
        testId: 'transactions-report-landing-options-not-recognize-charge-motive-fraud-forged',
        value: NotRecognizeChargeMotiveFraudEnum.COUNTERFEIT_CARD_FRAUD,
        id: NotRecognizeChargeMotiveFraudEnum.COUNTERFEIT_CARD_FRAUD,
      },
      {
        helperMessage: formatMessage({ id: translations.THIRD_CONFIRM_FOURTH_OPTION }),
        testId: 'transactions-report-landing-options-not-recognize-charge-motive-fraud-lost',
        value: NotRecognizeChargeMotiveFraudEnum.LOST_CARD_FRAUD,
        id: NotRecognizeChargeMotiveFraudEnum.LOST_CARD_FRAUD,
      },
      {
        helperMessage: formatMessage({ id: translations.THIRD_CONFIRM_FIFTH_OPTION }),
        testId: 'transactions-report-landing-options-not-recognize-charge-motive-fraud-stolen',
        value: NotRecognizeChargeMotiveFraudEnum.STOLEN_CARD_FRAUD,
        id: NotRecognizeChargeMotiveFraudEnum.STOLEN_CARD_FRAUD,
      },
      {
        helperMessage: formatMessage({ id: translations.THIRD_CONFIRM_SIXTH_OPTION }),
        testId:
          'transactions-report-landing-options-not-recognize-charge-motive-fraud-unrecognised',
        value: NotRecognizeChargeMotiveFraudEnum.EMAIL_FRAUD,
        id: NotRecognizeChargeMotiveFraudEnum.EMAIL_FRAUD,
      },
    ],
  },
];

export { isSelected, getCardId, getInfoOptions };
