// Vendors
import { useState } from 'react';
// Handlers
import TransactionsTableHeaderLinkBarTrackingHandlers from '../handlers/transactions-table-header-link-bar.tracking.handlers';
import TransactionsTableHeaderLinkBarHandlers from '../handlers/transactions-table-header-link-bar.handlers';
// Contexts
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsTableHeaderLinkBarReturnHooksType } from './types/transactions-table-header-link-bar-return.hook.type';

const TransactionsTableHeaderLinkBarHook = (): TransactionsTableHeaderLinkBarReturnHooksType => {
  const [isShowDownloadModal, setIsShowDownloadModal] = useState(false);
  const { setIsShowFilterModal } = useFiltersContextConsumerHook();

  const { handleDownloadTransactionsLinkClick, handleFilterTransactionsLinkClick } =
    TransactionsTableHeaderLinkBarHandlers({
      setIsShowFilterModal,
      isShowDownloadModal,
      setIsShowDownloadModal,
      ...AppTrackingHook(TransactionsTableHeaderLinkBarTrackingHandlers),
    });

  return {
    handleDownloadTransactionsLinkClick,
    handleFilterTransactionsLinkClick,
    isShowDownloadModal,
    setIsShowDownloadModal,
  };
};

export default TransactionsTableHeaderLinkBarHook;
