// Vendors
import React from 'react';
import { ThemeProvider as StyledProvider } from 'styled-components';
// Styles
import ThemeProviderStyled from './theme.provider.styled';
import { ThemeProviderType } from './types/theme.provider.type';

const ThemeProvider = ({
  children,
  theme,
}: ThemeProviderType): React.ReactElement<ThemeProviderType> => (
  <StyledProvider {...{ theme }}>
    <ThemeProviderStyled />
    {children}
  </StyledProvider>
);

export default ThemeProvider;
