// Resolvers
import { changeCardControlsControlResolver } from '../resolvers/settings-card-control-toggle.resolvers';
import { fetchCardControlLocationsResolver } from '../../locations/resolvers/settings-card-control-locations.resolvers';
// Types
import { SettingsCardControlToggleBuilderHandlersType } from './types/settings-card-control-toggle-builder.handlers.type';
import { SettingsCardControlToggleBuilderReturnHandlersType } from './types/settings-card-control-toggle-builder-return.handlers.type';
// Utilities
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const changeCardControlItemClickHandler = async ({
  cardId,
  handleChangeCardControlItemClickTracking,
  id,
  setCard,
  setFetching,
  setContinents,
  setToastConfiguration,
  translations,
  value,
}: SettingsCardControlToggleBuilderHandlersType): Promise<void> => {
  handleChangeCardControlItemClickTracking(id);
  setFetching(true);

  const [response, error] = await changeCardControlsControlResolver({ cardId, id, value });
  const [responseLocation, errorMessageLocation] = await fetchCardControlLocationsResolver(cardId);

  response && setCard(true);
  setToastConfiguration(
    setupToastConfiguration({
      error,
      description: getToastMessage({ error, translations }),
    })
  );
  setFetching(false);

  if (!errorMessageLocation && responseLocation) {
    const { continents } = responseLocation;
    setContinents(continents);
  }
};

const SettingsCardControlToggleHandlers = (
  props: SettingsCardControlToggleBuilderHandlersType
): SettingsCardControlToggleBuilderReturnHandlersType => ({
  handleChangeCardControlItemClick: () => changeCardControlItemClickHandler(props),
});

export default SettingsCardControlToggleHandlers;
