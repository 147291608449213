// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
// Handlers
import RequestPortabilityHandlers from '../handlers/request-portability.handlers';
import RequestPortabilityTrackingHandlers from '../handlers/request-portability.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RequestPortabilityHookType } from './types/request-portability.hook.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const RequestPortabilityHook = (): RequestPortabilityHookType => {
  const [personalDataToggle, setPersonalDataToggle] = useState<boolean>(false);

  const { profile } = AppContextConsumerHook(),
    { email } = profile?.contactDetails || {};
  const { formatMessage } = useCbIntl();

  const {
    contractedProductsToggle,
    error,
    movementsCheckbox,
    setContractedProductsToggle,
    setPortabilityParams,
  } = AccessPortabilityContextConsumerHook();

  const { isHybrid } = MobileConnectorContextConsumerHook();

  const { handleMovementsChangeCheckboxTracking } = AppTrackingHook(
    RequestPortabilityTrackingHandlers
  );

  return {
    ...RequestPortabilityHandlers({
      contractedProductsToggle,
      handleMovementsChangeCheckboxTracking,
      navigate: useNavigate(),
      personalDataToggle,
      setContractedProductsToggle,
      setPersonalDataToggle,
      setPortabilityParams,
    }),
    contractedProductsToggle,
    email,
    error,
    formatMessage,
    isHybrid,
    movementsCheckbox,
    personalDataToggle,
  };
};

export default RequestPortabilityHook;
