// Components
import ProfileConsentsStep1Component from '../components/step1/profile-consents-step1.component';
import ProfileConsentsStep2Component from '../components/step2/profile-consents-step2.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_PERSONAL_INFO,
  STEP_1_TITLE,
  STEP_2_TITLE,
} from '../translations/profile-consents.translations';

const CONSENTS_WIZARD_PROPS = {
  feedbackConfiguration: {
    errorConfiguration: {
      description: FEEDBACK_ERROR_DESCRIPTION,
      title: FEEDBACK_ERROR_TITLE,
      exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
    },
    successConfiguration: {
      description: FEEDBACK_SUCCESS_DESCRIPTION,
      linkLabel: GO_TO_PERSONAL_INFO,
      title: FEEDBACK_SUCCESS_TITLE,
      exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
    },
  },
  steps: [
    {
      component: ProfileConsentsStep1Component,
      title: STEP_1_TITLE,
    },
    {
      component: ProfileConsentsStep2Component,
      title: STEP_2_TITLE,
    },
  ],
};

export { CONSENTS_WIZARD_PROPS };
