// Resolvers
import { fetchLayoutLanguageResolver } from '../resolvers/language.resolvers';
// Types
import { LanguageProviderHandlersType } from './types/language.provider.handlers.type';
import { LanguageReturnProviderHandlersType } from './types/language-return.provider.handlers.type';

const fetchLayoutLanguageHandler = async ({
  setFetching,
  setLanguagePreference,
}: LanguageProviderHandlersType): Promise<any> => {
  setFetching(true);

  const [language] = await fetchLayoutLanguageResolver();

  language && setLanguagePreference(language);
  setFetching(false);
};

const LanguageHandlers = (
  props: LanguageProviderHandlersType
): LanguageReturnProviderHandlersType => ({
  handleFetchLayoutLanguage: () => fetchLayoutLanguageHandler(props),
});

export default LanguageHandlers;
