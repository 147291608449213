// Vendros
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsStatementsContextConsumerHook } from 'containers/card-settings/views/statements/contexts/card-settings-statements.context';
// Event handlers
import { CardSettingsStatementsMessageHandlers } from '../handlers/card-settings-statements-message.handlers';
import { CardSettingsStatementsMessageTrackingHandlers } from '../handlers/card-settings-statements-message.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsStatementsMessageHookType } from './types/card-settings-statements-message.hook.type';

const useCardSettingsStatementsMessageHook = (): CardSettingsStatementsMessageHookType => {
  const {
      statements: { elements },
    } = useCardSettingsStatementsContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { currencyCode: currency } = currentCard || {};

  return {
    ...CardSettingsStatementsMessageHandlers({
      ...AppTrackingHook(CardSettingsStatementsMessageTrackingHandlers),
      navigate: useNavigate(),
    }),
    currency,
    elements,
  };
};

export { useCardSettingsStatementsMessageHook };
