// Vendors
import { useEffect } from 'react';
// Contexts
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodFirstStepRevolvingFeeSliderHandlers from '../handlers/financing-payment-method-first-step-revolving-fee-slider.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingPaymentMethodFirstStepRevolvingFeeSliderReturnHookType } from './types/financing-payment-method-first-step-revolving-fee-slider-return.hook.type';

const FinancingPaymentMethodFirstStepRevolvingFeeSliderHook =
  (): FinancingPaymentMethodFirstStepRevolvingFeeSliderReturnHookType => {
    const { formatMessage, formatNumber } = useCbIntl();

    const { fee, setFee, revolvingMethod, revolvingOptions, setError } =
      FinancingPaymentMethodContextConsumerHook();

    const { handleSliderRender, ...rest } =
      FinancingPaymentMethodFirstStepRevolvingFeeSliderHandlers({
        revolvingOptions,
        setError,
        setFee,
      });

    useEffect(() => {
      handleSliderRender();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      ...rest,
      fee,
      formatMessage,
      formatNumber,
      revolvingMethod,
      revolvingOptions,
    };
  };

export default FinancingPaymentMethodFirstStepRevolvingFeeSliderHook;
