// Vendors
import React, { useEffect } from 'react';
// Components
import {
  MessageComponent,
  NavigationFooterComponent,
  ParagraphComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import koMessageConstants from './constants/ko-message.constants';
// Translations
import koMessageTranslations from './translations/ko-message.translations';
// Styles
import {
  KoMessageMessageComponentStyled,
  KoMessageNavigationComponentStyled,
  KoMessageTitleComponentStyled,
} from './ko-message.component.styled';
// Types
import { KoMessageProps } from './types/ko-message.component.type';

const { messageProps, messageButtonProps, messageDescriptionProps, messageTitleProps } =
  koMessageConstants;

const KoMessageComponent = ({ onTryAgain, tracking, type }: KoMessageProps): React.ReactElement => {
  const { DESCRIPTION, TITLE, TRY_AGAIN } = koMessageTranslations[type];

  useEffect(() => {
    tracking.onLoad();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <KoMessageMessageComponentStyled>
        <MessageComponent {...messageProps}>
          <KoMessageTitleComponentStyled>
            <TitleComponent {...messageTitleProps}>
              <FormattedMessageComponent id={TITLE} />
            </TitleComponent>
          </KoMessageTitleComponentStyled>
          <ParagraphComponent {...messageDescriptionProps}>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
        </MessageComponent>
      </KoMessageMessageComponentStyled>

      <KoMessageNavigationComponentStyled>
        <NavigationFooterComponent
          rightLinkConfiguration={{
            ...messageButtonProps,
            children: <FormattedMessageComponent id={TRY_AGAIN} />,
            onClick: onTryAgain,
          }}
        />
      </KoMessageNavigationComponentStyled>
    </>
  );
};

export default KoMessageComponent;
