// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Types
import { TransactionsEppStep2AmountHookType } from './types/transactions-epp-step2-amount.hook.type';

const useTransactionsEppStep2ResumeAmountHook = (): TransactionsEppStep2AmountHookType => {
  const { formatNumber } = useCbIntl();

  const {
    eppPlanSimulation: { currencyCode: currency, ...simulation },
  } = useTransactionsEppContextConsumerHook();

  return {
    currency,
    formatNumber,
    simulation,
  };
};

export { useTransactionsEppStep2ResumeAmountHook };
