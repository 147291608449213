// Services
import { checkClientUserSessionHealthService } from '../services/session-health.service';
// Utilities
import { wasUnauthorizedRequest } from '@openbank/cf-ui-framework';

const checkClientUserSessionHealthResolver = async (): Promise<any> => {
  const [, { status }] = await checkClientUserSessionHealthService();

  return !wasUnauthorizedRequest(status) ? [status] : [null, status];
};

export { checkClientUserSessionHealthResolver };
