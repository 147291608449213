// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Types
import { getRejectedDocumentsMap } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/hooks/utils/upload-documents-section.utils';
import { EmployedSectionHookPropsType } from './types/employed-section-props.type';
import { EmployedSectionHookReturnPropsType } from './types/employed-section-return-props.type';
// Utils
import { isUploadDocumentRejected } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/utils/upload-documents-section.utils';
import { hasRejectedDocuments, isEmployed } from '../utils/employed-section.utils';

const useEmployedSectionHook = ({
  activityTypeDeclared,
  documentsRejected,
}: EmployedSectionHookPropsType): EmployedSectionHookReturnPropsType => {
  const rejectedDocumentsMap = getRejectedDocumentsMap(documentsRejected);
  const isLastSalaryRejected: boolean = isUploadDocumentRejected({
    uploadDocumentId: UploadDocumentIdsEnumeration.LAST_SALARY,
    rejectedDocumentsMap,
  });
  const isPreviousSalaryRejected: boolean = isUploadDocumentRejected({
    uploadDocumentId: UploadDocumentIdsEnumeration.PREVIOUS_SALARY,
    rejectedDocumentsMap,
  });
  const isBeforePreviousSalaryRejected: boolean = isUploadDocumentRejected({
    uploadDocumentId: UploadDocumentIdsEnumeration.BEFORE_PREVIOUS_SALARY,
    rejectedDocumentsMap,
  });

  const shouldRenderSection = (): boolean => {
    if (!isEmployed(activityTypeDeclared)) return false;
    if (!documentsRejected || documentsRejected.length === NUMBER.ZERO) return true;
    return documentsRejected?.length > NUMBER.ZERO && hasRejectedDocuments(documentsRejected);
  };

  return {
    showLastSalary: !documentsRejected || isLastSalaryRejected,
    showPreviousSalary: !documentsRejected || isPreviousSalaryRejected,
    showBeforePreviousSalary: !documentsRejected || isBeforePreviousSalaryRejected,
    isLastSalaryRejected,
    isPreviousSalaryRejected,
    isBeforePreviousSalaryRejected,
    shouldRenderSection: shouldRenderSection(),
  };
};

export { useEmployedSectionHook };
