// Vendors
import React from 'react';
// Components
import { ChangeIbanStep2ActionsComponent } from './components/actions/change-iban-step2-actions.component';
import { ChangeIbanStep2ChallengeComponent } from './components/challenge/change-iban-step2-challenge.component';
import { MessageComponent, ParagraphComponent, ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CHANGE_IBAN_STEP2_MESSAGE_PROPS,
  CHANGE_IBAN_STEP2_MESSAGE,
  CHANGE_IBAN_STEP2_NEW_IBAN_ITEM_PROPS,
} from './constants/change-iban-step2.constants';
// Hooks
import { ChangeIbanStep2Hook } from './hooks/change-iban-step2.hook';
// Styles
import { ChangeIbanStep2ComponentStyled } from './change-iban-step2.component.styled';
// Translations
import translations from './translations/change-iban-step2.translations';

const ChangeIbanStep2Component = (): React.ReactElement => {
  const { ibanValue } = ChangeIbanStep2Hook();

  return (
    <ChangeIbanStep2ComponentStyled>
      <ParagraphComponent {...CHANGE_IBAN_STEP2_MESSAGE}>
        <FormattedMessageComponent id={translations.MESSAGE_01} />
      </ParagraphComponent>
      <MessageComponent {...CHANGE_IBAN_STEP2_MESSAGE_PROPS}>
        <ResumeItemComponent
          {...CHANGE_IBAN_STEP2_NEW_IBAN_ITEM_PROPS}
          label={<FormattedMessageComponent id={translations.NEW_IBAN} />}
          value={ibanValue}
        />
      </MessageComponent>
      <ChangeIbanStep2ChallengeComponent />
      <ChangeIbanStep2ActionsComponent />
    </ChangeIbanStep2ComponentStyled>
  );
};

export { ChangeIbanStep2Component };
