// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getChange3DSecureCodeConstraintsConfiguration } from 'containers/card-settings/views/change-3d/components/step1/configurations/card-settings-change-3d-step1.configuration';
// Components
import { ConstraintsComponent, SecurityModuleComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_CHANGE_3D_NEW_CODE_PROPS } from './constants/card-settings-change-3d-step1-new-code.constants';
// Hooks
import { useCardSettingsChange3dStep1NewCodeHook } from './hooks/card-settings-change-3d-step1-new-code.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { HIDE_PIN, SHOW_PIN } from '../../translations/card-settings-change-3d-step1.translations';
import { ERROR_NOT_MATCH_CONDITION } from './translations/card-settings-change-3d-step1-new-code.translations';

const CardSettingsChange3dStep1NewCodeComponent = (): React.ReactElement => {
  const {
      dateOfBirth,
      formatMessage,
      error,
      handleChange3DNewCodeInputClickTracking: onClick,
      handleSaveNew3DCodeInputChangeEvent: onChange,
      handleValidateNew3DCodeInputChangeEvent: onValidate,
      new3DCode: value,
    } = useCardSettingsChange3dStep1NewCodeHook(),
    constraints = getChange3DSecureCodeConstraintsConfiguration({
      dateOfBirth,
      formatMessage,
    });

  return (
    <LayoutColumnFlexComponentStyled>
      <ConstraintsComponent {...{ constraints, value }} />
      <SecurityModuleComponent
        {...CARD_SETTINGS_CHANGE_3D_NEW_CODE_PROPS}
        {...{ onClick, onChange, onValidate, value }}
        errorMessage={error && <FormattedMessageComponent id={ERROR_NOT_MATCH_CONDITION} />}
        translations={{
          HIDE_PASSWORD: <FormattedMessageComponent id={HIDE_PIN} />,
          SHOW_PASSWORD: <FormattedMessageComponent id={SHOW_PIN} />,
        }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { CardSettingsChange3dStep1NewCodeComponent };
