// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_ALL_RADIO_TRACKING } from './constants/transactions-epp-step1-simulation-options-all.tracking.handlers.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TransactionsEppStep1SimulationOptionsAllTrackingMethodsHandlersType } from './types/transactions-epp-step1-simulation-options-all.tracking.handlers.type';

const changeAllOptionsRadioButtonChangeEventTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_ALL_RADIO_TRACKING,
  type: TrackingEventsEnum.LINK,
});

const TransactionsEppStep1SimulationOptionsAllTrackingHandlers = (
  track: TrackBuilderType
): TransactionsEppStep1SimulationOptionsAllTrackingMethodsHandlersType => ({
  handleChangeAllOptionsRadioButtonChangeEventTracking: () =>
    track(changeAllOptionsRadioButtonChangeEventTrackingHandler()),
});

export { TransactionsEppStep1SimulationOptionsAllTrackingHandlers };
