// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import {
  TransactionsEppContextType,
  TransactionsEppEppContextType,
  TransactionsEppTransactionContextType,
} from './types/transactions-epp.context.type';

const TransactionsEppContext = createContext<TransactionsEppContextType>(
  {} as TransactionsEppContextType
);

const TransactionsEppContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<TransactionsEppContextType>> => {
  const [eppPlanSimulation, setEppPlanSimulation] = useState<TransactionsEppEppContextType>({}),
    [isAllOptionsSelected, setIsAllOptionsSelected] = useState<boolean>(false),
    [installments, setInstallments] = useState<number | null>(null),
    [showAmortizationTableModal, setShowAmortizationTableModal] = useState<boolean>(false),
    [transactionDetails, setTransactionDetails] = useState<TransactionsEppTransactionContextType>(
      {}
    );

  return (
    <TransactionsEppContext.Provider
      value={{
        eppPlanSimulation,
        isAllOptionsSelected,
        installments,
        showAmortizationTableModal,
        setEppPlanSimulation,
        setIsAllOptionsSelected,
        setInstallments,
        setTransactionDetails,
        setShowAmortizationTableModal,
        transactionDetails,
      }}
    >
      {children as React.ReactNode}
    </TransactionsEppContext.Provider>
  );
};

const useTransactionsEppContextConsumerHook = (): TransactionsEppContextType =>
  useContext(TransactionsEppContext);

/* eslint-disable */
const TransactionsEppContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<any>> =>
    (
      <TransactionsEppContextProvider>
        <Component {...props} />
      </TransactionsEppContextProvider>
    );

export { TransactionsEppContextConsumerHOC, useTransactionsEppContextConsumerHook };
