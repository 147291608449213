// Constants
import constants from './constants/transactions-table-list-elements.constants';
// Types
import { EppCardType } from 'types/epp-card.type';

const shouldOpenDetail = (element: EppCardType): boolean => {
  const hasStorageEppId = localStorage.getItem(constants.EPP_ID_STRING) === element.transactionId;

  if (!hasStorageEppId) {
    return false;
  }

  localStorage.removeItem(constants.EPP_ID_STRING);
  setTimeout(() => {
    document
      .getElementById(element.transactionId)
      ?.scrollIntoView(constants.SCROLL_INTO_VIEW_CONFIGURATION as ScrollIntoViewOptions);
  }, constants.MILLISECONDS_SET_TIMEOUT);

  return true;
};

export { shouldOpenDetail };
