// Enumerations
import { ConsentTypesEnum } from 'containers/personal/views/consents/enumerations/consent-types.enum';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cblegalConsents.legalConsents.step2.';

const INFORMATIVE_MESSAGE_DESCRIPTIONS = {
  [ConsentTypesEnum.INTL]: `${DOMAIN}informativeMessageIntl`,
  [ConsentTypesEnum.COM_NTSIMLR]: `${DOMAIN}informativeMessageIntl`,
  [ConsentTypesEnum.AMZN_WALLET]: `${DOMAIN}informativeMessageWallet`,
};

const ACCEPTED_CONSENTS = `${DOMAIN}acceptedConsentsLabel`;
const REJECTED_CONSENTS = `${DOMAIN}rejecetdConsentsLabel`;
const ALL_CONCENTS_ACCEPTED = `${DOMAIN}allConsentsAcceptedLabel`;

export {
  ACCEPTED_CONSENTS,
  ALL_CONCENTS_ACCEPTED,
  INFORMATIVE_MESSAGE_DESCRIPTIONS,
  REJECTED_CONSENTS,
};
