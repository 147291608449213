// Vendors
import React from 'react';
// Components
import PersonalRouterComponent from './components/router/personal-router.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Constants
import constants from './constants/personal.constants';
// Styles
import { LayoutGridComponentStyled } from 'styles/app.styled';

const PersonalContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <TitleSectionComponent {...constants.PERSONAL_TITLE_SECTION_PROPS} />
    <PersonalRouterComponent />
  </LayoutGridComponentStyled>
);

export default PersonalContainer;
