// Types
import {
  TitleSectionMobileBuilderHandlersType,
  TitleSectionMobileMethodsHandlersType,
} from './types/title-section-mobile-builder.handlers.type';
// Utils
import { getFinancingUrlDependingOnPathname } from './utils/title-section-mobile.handlers.utils';

const navigatePopStateAssetClickHandler = ({
  handleNavigatePopStateAssetClickTracking,
  onNavigateHybridFlow,
}: Pick<
  TitleSectionMobileBuilderHandlersType,
  'handleNavigatePopStateAssetClickTracking' | 'onNavigateHybridFlow'
>): void => {
  handleNavigatePopStateAssetClickTracking();
  onNavigateHybridFlow(getFinancingUrlDependingOnPathname());
};

const setupObserveScrollOnPageHandler = ({
  isHybrid,
  notchSize,
  setLocalNotchSize,
}: Pick<
  TitleSectionMobileBuilderHandlersType,
  'isHybrid' | 'notchSize' | 'setLocalNotchSize'
>): void => {
  if (isHybrid) {
    document.addEventListener('scroll', () => {
      setLocalNotchSize(window.scrollY <= notchSize ? window.scrollY : notchSize);
    });
  }
};

const TitleSectionMobileHandlers = ({
  handleNavigatePopStateAssetClickTracking,
  isHybrid,
  notchSize,
  onNavigateHybridFlow,
  setLocalNotchSize,
}: TitleSectionMobileBuilderHandlersType): TitleSectionMobileMethodsHandlersType => ({
  handleNavigatePopStateAssetClick: () =>
    navigatePopStateAssetClickHandler({
      handleNavigatePopStateAssetClickTracking,
      onNavigateHybridFlow,
    }),
  handleSetupObserveScrollOnPage: () =>
    setupObserveScrollOnPageHandler({ isHybrid, notchSize, setLocalNotchSize }),
});

export { TitleSectionMobileHandlers };
