/* eslint-disable complexity */
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Resolvers
import { fetchCardsTransactionsDetailResolver } from 'containers/transactions/resolvers/transactions.resolvers';
// Types
import { RenderViewHandlerType } from './types/render-view-handler.type';
import { TransactionsReportHandlersReturnType } from './types/transactions-report-return.handlers.type';
import { TransactionsReportHandlersType } from './types/transactions-report.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const renderViewHandler = async ({
  cardId,
  navigate,
  setError,
  setFetching,
  setTransactionDetails,
  transactionId,
}: RenderViewHandlerType): Promise<void> => {
  try {
    setFetching(true);

    if (!cardId || !transactionId) throw new Error();

    const [response, error] = await fetchCardsTransactionsDetailResolver({ cardId, transactionId });

    if (error || !response) throw new Error();

    const {
      report: { active },
    } = response;

    if (!active) {
      navigate(generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS));

      return;
    }

    setTransactionDetails(response);
  } catch (error) {
    setError(true);
  } finally {
    setFetching(false);
  }
};

export const TransactionsReportHandlers = (
  props: TransactionsReportHandlersType
): TransactionsReportHandlersReturnType => ({
  handleRenderView: () => renderViewHandler(props),
});
