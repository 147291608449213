// Resolvers
import { cancelPPIContractResolver } from '../resolvers/financing-ppi-cancellation-landing-footer.resolver';
// Types
import {
  FinancingPPICancellationLandingFooterBuilderHandlersType,
  FinancingPPICancellationLandingFooterBuilderReturnHandlersType,
} from './types/financing-ppi-cancellation-landing-footer.handlers.type';
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';

const confirmCancelPPIContractButtonClickHandler = async ({
  contractId = '',
  handleConfirmCancelPPIContractButtonClickTracking,
  progressId,
  setFetching,
  setFetchPPI,
  setResult,
}: Omit<
  FinancingPPICancellationLandingFooterBuilderHandlersType,
  'setChallenged' | 'setProgressId'
>): Promise<void> => {
  handleConfirmCancelPPIContractButtonClickTracking();
  setFetching(true);

  const [, error] = await cancelPPIContractResolver({ contractId, progressId });

  setFetchPPI(!error);
  setResult(!error);
};

const manageSuccessCancelPPIChallengeEventHandler = ({
  progressId,
  setChallenged,
  setProgressId,
}: Pick<
  FinancingPPICancellationLandingFooterBuilderHandlersType,
  'setChallenged' | 'setProgressId' | 'progressId'
>): void => {
  setChallenged(true);
  setProgressId(progressId);
};

const manageFailedCancelPPIChallengeEventHandler = ({
  setResult,
}: Pick<FinancingPPICancellationLandingFooterBuilderHandlersType, 'setResult'>): void => {
  setResult(false);
};

const FinancingPPICancellationLandingFooterHandlers = ({
  contractId,
  handleConfirmCancelPPIContractButtonClickTracking,
  progressId,
  setChallenged,
  setFetching,
  setFetchPPI,
  setProgressId,
  setResult,
}: FinancingPPICancellationLandingFooterBuilderHandlersType): FinancingPPICancellationLandingFooterBuilderReturnHandlersType => ({
  handleConfirmCancelPPIContractButtonClick: () =>
    confirmCancelPPIContractButtonClickHandler({
      contractId,
      handleConfirmCancelPPIContractButtonClickTracking,
      progressId,
      setFetchPPI,
      setFetching,
      setResult,
    }),
  handleManageFailedCancelPPIChallengeEvent: () =>
    manageFailedCancelPPIChallengeEventHandler({ setResult }),
  handleManageSuccessCancelPPIChallengeEvent: (props: ChallengeProgressIdType) =>
    manageSuccessCancelPPIChallengeEventHandler({ ...props, setChallenged, setProgressId }),
});

export { FinancingPPICancellationLandingFooterHandlers };
