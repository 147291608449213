// Constants
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

export default {
  PROFILE_PERSONAL_DATA_CONTACT_DIVIDER_PROPS: {
    testId: 'profile-personal-data-contact-divider',
    testing: {
      elementId: 'profile-personal-data-contact-divider',
      titleId: 'profile-personal-data-contact-divider-title-section',
    },
    type: SectionDividerTypesEnumeration.INFORMATIVE,
  },
  PROFILE_PERSONAL_DATA_CONTACT_EMAIL_PROPS: {
    testId: 'profile-personal-data-contact-email',
  },
  PROFILE_PERSONAL_DATA_CONTACT_PHONE_PROPS: {
    testId: 'profile-personal-data-contact-phone',
  },
};
