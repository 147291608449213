// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';

export default {
  CARD_STATUS_BUTTON_PROPS: {
    rwdFull: false,
    testId: 'card-status-tag-button',
    type: buttonConstants.TYPES.SECONDARY,
  },
};
