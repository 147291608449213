// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent, ParagraphComponent, AssetComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CANCEL_CONTRACT_MODAL_BUTTON_PROPS,
  CANCEL_CONTRACT_MODAL_DEFAULT_ASSET_PROPS,
  CANCEL_CONTRACT_MODAL_LINK_PROPS,
  CANCEL_CONTRACT_MODAL_PARAGRAPH_01_PROPS,
} from './constants/support-cancel-contract-modal.constants';
// Translations
import {
  CANCEL,
  CONFIRM,
  DESCRIPTION,
  PAGE_TITLE,
} from './translations/support-cancel-contract-modal.translations';
//Styled
import {
  SupportCancelContractModalDescriptionComponentStyled,
  SupportCancelContractModalDescriptionTextComponentStyled,
} from './support-cancel-contract-modal.component.styled';
// Types
import { SupportCancelContractModalComponentType } from './types/support-cancel-contract-modal.component.type';

const SupportCancelContractModalComponent = ({
  onConfirm,
  cancelClick,
  showModal,
}: SupportCancelContractModalComponentType): React.ReactElement | null => {
  return showModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      primaryButtonConfiguration={{
        ...CANCEL_CONTRACT_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={CONFIRM} />,
        onClick: onConfirm,
      }}
      linkConfiguration={{
        ...CANCEL_CONTRACT_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={CANCEL} />,
        onClick: cancelClick,
      }}
      title={<FormattedMessageComponent id={PAGE_TITLE} />}
    >
      <SupportCancelContractModalDescriptionComponentStyled>
        <AssetComponent {...CANCEL_CONTRACT_MODAL_DEFAULT_ASSET_PROPS} />
        <SupportCancelContractModalDescriptionTextComponentStyled>
          <ParagraphComponent {...CANCEL_CONTRACT_MODAL_PARAGRAPH_01_PROPS}>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
        </SupportCancelContractModalDescriptionTextComponentStyled>
      </SupportCancelContractModalDescriptionComponentStyled>
    </ModalComponent>
  ) : null;
};

export default SupportCancelContractModalComponent;
