// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { ViewTrackingPropsType } from './types/view-tracking.configuration.type';
import { LinkTrackingPropsType } from './types/link-tracking.configuration.type';
import { ErrorTrackingPropsType } from './types/error-tracking.configuration.type';

export const getViewTypeTrackingConfiguration = ({
  clStore,
  interactionType,
  pageName,
  processDetail,
  processStep,
  processType,
  sectionCategory,
  sectionFamily,
  sectionName,
  serviceType,
}: ViewTrackingPropsType): TrackingViewEventReturnType => ({
  payload: {
    clStore,
    interactionType,
    pageName,
    processDetail,
    processStep,
    processType,
    sectionCategory,
    sectionFamily,
    sectionName,
    serviceType,
  },
  type: TrackingEventsEnum.VIEW,
});

export const getLinkTypeTrackingConfiguration = ({
  eventAction,
  eventLabel,
}: LinkTrackingPropsType): TrackingLinkEventReturnType => ({
  payload: {
    eventAction,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

export const getLinkErrorTrackingConfiguration = ({
  errorCategory,
  errorDescription,
  eventAction,
  eventLabel,
}: ErrorTrackingPropsType): TrackingErrorEventReturnType => ({
  payload: {
    errorCategory,
    errorDescription,
    eventAction,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});
