// Vendors
import styled from 'styled-components';

const WizardStepFooterCancelModalComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
WizardStepFooterCancelModalComponentStyled.displayName =
  'WizardStepFooterCancelModalComponentStyled';

const WizardStepFooterFeedbackCancelModalAssetComponentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;
WizardStepFooterFeedbackCancelModalAssetComponentStyled.displayName =
  'WizardStepFooterFeedbackCancelModalAssetComponentStyled';

export {
  WizardStepFooterCancelModalComponentStyled,
  WizardStepFooterFeedbackCancelModalAssetComponentStyled,
};
