// Constants
import {
  TRAVEL_PLUS_LINKS_COMMONS,
  TRAVEL_PLUS_LINKS_ITEMS,
} from 'components/travel-plus/components/links/constants/travel-plus-links.constants';
// Types
import { CardSettingsBenefitsTravelPlusLinkConfigurationType } from './types/card-settings-benefits-travel-plus-link.configuration.type';
import { CardSettingsBenefitsTravelPlusLinkConfigurationReturnType } from './types/card-settings-benefits-travel-plus-link-return.configuration.type';

const addProperties = ({ links, formatMessage, handleOpenTravelPlusModalLinkClick }) => {
  return links.map(link => ({
    ...link,
    onClick: () => handleOpenTravelPlusModalLinkClick({ id: link.id, text: link.text }),
    children: formatMessage({ id: link.text }),
  }));
};

const getBenefitsLandingTravelPlusLinkContractConfiguration = ({
  formatMessage,
  handleOpenTravelPlusModalLinkClick,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusLinkConfigurationType): CardSettingsBenefitsTravelPlusLinkConfigurationReturnType => {
  return !travelPlusInfo?.status && !travelPlusInfo?.expirationDate
    ? addProperties({
        links: TRAVEL_PLUS_LINKS_COMMONS,
        formatMessage,
        handleOpenTravelPlusModalLinkClick,
      })
    : addProperties({
        links: TRAVEL_PLUS_LINKS_ITEMS.enabled,
        formatMessage,
        handleOpenTravelPlusModalLinkClick,
      });
};

export { getBenefitsLandingTravelPlusLinkContractConfiguration };
