// Vendors
import React from 'react';
// Components
import { CardDetailsComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getCardResumeConfiguration } from './configurations/card-resume.configurations';
// Constants
import constants from './constants/card-resume.constants';
// Hooks
import CardResumeHook from './hooks/card-resume.hook';
// Types
import { CardResumeComponentType } from './types/card-resume.component.type';

const CardResumeComponent = ({ large }: CardResumeComponentType): React.ReactElement => {
  const { cardImage, cardNumber, cardType } = CardResumeHook();

  return (
    <CardDetailsComponent
      {...constants.CARD_RESUME_COMPONENT_PROPS}
      {...getCardResumeConfiguration({ cardImage, cardNumber, cardType, large })}
    />
  );
};

export default CardResumeComponent;
