// Constants
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/not-received/contexts/constants/transactions-report-not-received.context.constants';
import { NUMBER } from 'constants/number.constants';
// Types';
import { TransactionsReportNotRecievedDocumentsActionsHandlersPropsType } from 'containers/transactions/views/report/components/not-received/components/documents/components/actions/handlers/types/transactions-report-not-received-documents-actions-handlers-props.type';
import { TransactionsReportNotRecievedDocumentsActionsHandlersReturnType } from 'containers/transactions/views/report/components/not-received/components/documents/components/actions/handlers/types/transactions-report-not-received-documents-actions-handlers-return.type';

const handleGoBack = ({
  setCurrentStep,
  setFiles,
}: TransactionsReportNotRecievedDocumentsActionsHandlersPropsType): void => {
  setCurrentStep(NUMBER.ONE);
  setFiles(FILES_INITIAL_STATE);
};

const TransactionsReportPaymentDocumentsActionsHandlers = ({
  setFiles,
  setCurrentStep,
}: TransactionsReportNotRecievedDocumentsActionsHandlersPropsType): TransactionsReportNotRecievedDocumentsActionsHandlersReturnType => ({
  handleGoBack: () => handleGoBack({ setFiles, setCurrentStep }),
});

export { TransactionsReportPaymentDocumentsActionsHandlers };
