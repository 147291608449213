// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { SIGNATURE_KEY_STATUS_SERVICE_CONFIGURATION, REQUEST_SIGNATURE_KEY_ACTIONS_SERVICE_PROPS } from './constants/request-signature-key.services.constants';
// Types
import { RequestSignatureKeyApiBuilderType } from '../types/request-signature-key-api-builder.type';
import { RequestSignatureKeyApiReturnComponentType } from '../types/request-signature-key-api-return.component.type';

const requestSignatureKeyService = async ({
  progressId,
}: RequestSignatureKeyApiBuilderType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...REQUEST_SIGNATURE_KEY_ACTIONS_SERVICE_PROPS,
    customHeaders: progressId ? { progressId } : {},
  });

const verifySignatureKeyStatusService = async (): Promise<
  [RequestSignatureKeyApiReturnComponentType | Record<string, any>, Response]
> =>
  apiUtilsCb.get({
    ...SIGNATURE_KEY_STATUS_SERVICE_CONFIGURATION,
  });

export { verifySignatureKeyStatusService, requestSignatureKeyService };
