// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export default {
  PERSONAL_NUMBER_LOGOUT_MODAL_ASSET_PROPS: {
    id: 'Working_on_it_Default_Error',
    sizes: {
      height: 120,
      width: 120,
    },
    type: assetConstants.TYPES.ILLUSTRATION,
    testId: 'personal-email-logout-modal-asset',
  },
  PERSONAL_NUMBER_LOGOUT_MODAL_PARAGRAPH_01_PROPS: {
    testId: 'personal-email-logout-modal-table-row-description-01',
  },
  PERSONAL_NUMBER_LOGOUT_MODAL_PARAGRAPH_02_PROPS: {
    testId: 'personal-email-logout-modal-table-row-description-02',
  },
  PERSONAL_NUMBER_LOGOUT_MODAL_BUTTON_PROPS: {
    testId: 'personal-email-logout-modal-button',
  },
};
