const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.expiredKycModal.';

const KYC_MODAL_TRANSLATIONS = {
  TITLE: `${DOMAIN}kycModalTitle`,
  DESCRIPTION: `${DOMAIN}kycModalDescription`,
  BUTTON: `${DOMAIN}kycModalButton`,
  LINK: `${DOMAIN}kycModalLink`,
};

export { KYC_MODAL_TRANSLATIONS };
