// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { useCardSettingsCheck3DContextConsumerHook } from 'containers/card-settings/views/check-3d/contexts/card-settings-check-3d.context';
import { useCardSettingsCheck3DConfirmationContextConsumerHook } from 'containers/card-settings/views/check-3d/components/confirmation/contexts/card-settings-check-3d-confirmation.context';
// Constants
import { SHOW_CODE_SECONDS } from '../constants/card-settings-check-3d-confirmation-security-module-description.constants';
// Event handlers
import CardSettingsCheck3dConfirmationSecurityModuleHandlers from '../handlers/card-settings-check-3d-confirmation-security-module-description.handlers';
import CardSettingsChangePinNewPasswordTrackingHandlers from '../handlers/card-settings-check-3d-confirmation-security-module-description.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsCheck3dConfirmationSecurityModuleDescriptionHookType } from './types/card-settings-check-3d-confirmation-security-module-description.hook.type';

const useCardSettingsCheck3dConfirmationSecurityModuleHook =
  (): CardSettingsCheck3dConfirmationSecurityModuleDescriptionHookType => {
    const [seconds, setSeconds] = useState<number>(SHOW_CODE_SECONDS);

    const { showAllowance, shownCode, setShowAllowance, setShownCode } =
        useCardSettingsCheck3DConfirmationContextConsumerHook(),
      { setScaCode, setHasChallenged, setCurrentStep } =
        useCardSettingsCheck3DContextConsumerHook();

    const { handleMakeANewQueryLinkClick, handleManageCountDownForShowCodeEvent } =
      CardSettingsCheck3dConfirmationSecurityModuleHandlers({
        ...AppTrackingHook(CardSettingsChangePinNewPasswordTrackingHandlers),
        setScaCode,
        setHasChallenged,
        setShowAllowance,
        setShownCode,
        setSeconds,
        setCurrentStep,
      });
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      showAllowance && shownCode && handleManageCountDownForShowCodeEvent();
    }, [seconds, showAllowance, shownCode]);

    return {
      handleMakeANewQueryLinkClick,
      showAllowance,
      seconds,
    };
  };

export default useCardSettingsCheck3dConfirmationSecurityModuleHook;
