// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import {
  ORDER_MOBILE_LINK_CONSTANTS,
  ORDER_MOBILE_TABLE_DETAILS_BODY,
  ORDER_MODAL_MOBILE_CONSTANTS,
} from './constants/order-modal-mobile.component.constants';
// Hooks
import { OrderModalHooks } from '../../hooks/order-modal.hook';
// Styles
import {
  InstallmentsDetailStyled,
  InstallmentsStyled,
  OrderMobileModalWrapperStyled,
  OrderListWrapperStyled,
} from './order-modal-mobile.component.styled';
// Translations
import {
  DEALER_LABEL,
  DEALER_NAME,
  ORDER_NUMBER,
  PURCHASE_DATE,
} from '../../transalations/order-modal.translations';
// Types
import { OrderModalComponentProps } from '../../types/order-modal.component.types';

const OrderModalMobileComponent = ({
  orders,
  urlAmazon,
  transactionInfo: { date },
}: OrderModalComponentProps): React.ReactElement => {
  const { handleViewDetailLink } = OrderModalHooks(urlAmazon);

  return (
    <OrderMobileModalWrapperStyled {...ORDER_MODAL_MOBILE_CONSTANTS}>
      <InstallmentsStyled {...ORDER_MOBILE_TABLE_DETAILS_BODY}>
        <InstallmentsDetailStyled>
          <FormattedMessageComponent id={PURCHASE_DATE} />
          <ParagraphComponent>
            <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM} value={date} />
          </ParagraphComponent>
        </InstallmentsDetailStyled>
        <InstallmentsDetailStyled>
          <FormattedMessageComponent id={DEALER_LABEL} />
          <FormattedMessageComponent id={DEALER_NAME} />
        </InstallmentsDetailStyled>
        <InstallmentsDetailStyled>
          <FormattedMessageComponent id={ORDER_NUMBER} />
          <OrderListWrapperStyled>
            {orders?.map(order => (
              <LinkComponent
                {...ORDER_MOBILE_LINK_CONSTANTS}
                onClick={() => handleViewDetailLink(order)}
                key={order}
              >
                {order}
              </LinkComponent>
            ))}
          </OrderListWrapperStyled>
        </InstallmentsDetailStyled>
      </InstallmentsStyled>
    </OrderMobileModalWrapperStyled>
  );
};

export { OrderModalMobileComponent };
