// Contexts
import { SecurityKeysAccessKeyChangeContextConsumerHook } from 'containers/security-keys/views/access-key-change/contexts/security-keys-access-key-change.context';
// Event handlers
import SecurityKeysAccessKeyChangeThirdStepChallengeTrackingHandlers from '../handlers/security-keys-access-key-change-third-step-challenge.tracking.handlers';
import SecurityKeysAccessKeyChangeThirdStepChallengeHandlers from '../handlers/security-keys-access-key-change-third-step-challenge.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { AccessKeyChangeStep3ChallengeType } from './types/security-keys-access-key-change-third-step-challenge.type';

const SecurityKeysAccessKeyChangeThirdStepChallengeHook = (): AccessKeyChangeStep3ChallengeType => {
  const { accessKey, newAccessKey, setIsDisabledLastStep, setProgressId } =
    SecurityKeysAccessKeyChangeContextConsumerHook();
  const { handleSendOtpCodeStep3TrackingHandler, handleSendOtpCodeSuccessStep3TrackingHandler } =
    AppTrackingHook(SecurityKeysAccessKeyChangeThirdStepChallengeTrackingHandlers);

  const { handleManageSuccessOnChangeSmsEvent } = SecurityKeysAccessKeyChangeThirdStepChallengeHandlers({
    handleSendOtpCodeStep3TrackingHandler,
    handleSendOtpCodeSuccessStep3TrackingHandler,
    setIsDisabledLastStep,
    setProgressId,
  });

  return {
    handleManageSuccessOnChangeSmsEvent,
    accessKey,
    newAccessKey,
  };
};

export default SecurityKeysAccessKeyChangeThirdStepChallengeHook;
