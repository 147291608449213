// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LineSeparatorComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep2TermsLinkComponent } from '../terms-link/financing-ppi-contracting-step2-terms-link.component';
import { FormattedNumber } from 'react-intl';
// Constants
import { FINANCING_PPI_PERCENTAGE_PROMILE_BASE } from 'containers/financing/views/ppi/components/landing/configurations/constants/financing-ppi-landing.configuration.constants';
import { FINANCING_PPI_CONTRACTING_STEP_2_FEATURES_SECTION_FEATURE_TEST_ID } from './constants/financing-ppi-contracting-step2-features-section.constants';
import { FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE } from '../../constants/financing-ppi-contracting-step2.constants';
// Contexts
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Styles
import {
  FinancingPPIContractingStep2FeaturesSectionComponentStyled,
  FinancingPPIContractingStep2FeaturesSectionListComponentStyled,
  FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled,
} from './financing-ppi-contracting-step2-features-section.component.styled';
// Types
import { FinancingPPIContractingStep2FeaturesSectionType } from './types/financing-ppi-contracting-step2-features-section.type';

const FinancingPPIContractingStep2FeaturesSectionComponent = ({
  features,
  title,
}: FinancingPPIContractingStep2FeaturesSectionType): React.ReactElement => {
  const { insurancesInfo } = useInsurancesContextConsumerHook(),
    { ppiPercentage } = insurancesInfo?.insurances.CPI || {};

  return (
    <FinancingPPIContractingStep2FeaturesSectionComponentStyled>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE}>
        <FormattedMessageComponent id={title} />
      </ParagraphComponent>
      <FinancingPPIContractingStep2FeaturesSectionListComponentStyled>
        {features.map((id: string, key: number) => (
          <FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled key={key}>
            <ParagraphComponent
              key={id}
              testId={FINANCING_PPI_CONTRACTING_STEP_2_FEATURES_SECTION_FEATURE_TEST_ID + key}
            >
              <FormattedMessageComponent
                id={id}
                values={{
                  link: (label: string | React.ReactNode) => (
                    <FinancingPPIContractingStep2TermsLinkComponent {...{ label }} />
                  ),
                  ppiPercentagePromile: (
                    <FormattedNumber
                      value={Number(ppiPercentage) * FINANCING_PPI_PERCENTAGE_PROMILE_BASE}
                    />
                  ),
                  ppiPercentage: <FormattedNumber value={Number(ppiPercentage)} />,
                }}
              />
            </ParagraphComponent>
          </FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled>
        ))}
      </FinancingPPIContractingStep2FeaturesSectionListComponentStyled>
      <LineSeparatorComponent />
    </FinancingPPIContractingStep2FeaturesSectionComponentStyled>
  );
};

export { FinancingPPIContractingStep2FeaturesSectionComponent };
