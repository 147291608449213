// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent, SectionDividerComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/profile-personal-data-contact.constants';
// Hooks
import ProfilePersonalDataContactHook from './hooks/profile-personal-data-contact.hook';
// Styles
import { ProfilePersonalDataSectionComponentStyled } from '../../profile-personal-data.component.styled';
import {
  ProfilePersonalDataContactComponentStyled,
  ProfilePersonalDataContactContentItemComponentStyled,
} from './profile-personal-data-contact.component.styled';
// Translations
import translations from './translations/profile-personal-data-contact.translations';
import { LayoutHorizontalPaddingStyledComponent } from '@/styles/app.styled';

const ProfilePersonalDataContactComponent = (): React.ReactElement => {
  const { email, handleEditCurrentPhoneNumberAssetClick, handleEditCurrentEmailAssetClick, phone } =
    ProfilePersonalDataContactHook();

  return (
    <ProfilePersonalDataContactComponentStyled>
      <SectionDividerComponent
        {...constants.PROFILE_PERSONAL_DATA_CONTACT_DIVIDER_PROPS}
        title={<FormattedMessageComponent id={translations.TITLE} />}
      >
        <LayoutHorizontalPaddingStyledComponent>
          <ProfilePersonalDataSectionComponentStyled>
            <ProfilePersonalDataContactContentItemComponentStyled>
              <ResumeItemComponent
                {...constants.PROFILE_PERSONAL_DATA_CONTACT_PHONE_PROPS}
                label={<FormattedMessageComponent id={translations.PHONE_NUMBER} />}
                onEdit={handleEditCurrentPhoneNumberAssetClick}
                value={phone}
              />
            </ProfilePersonalDataContactContentItemComponentStyled>
            <ProfilePersonalDataContactContentItemComponentStyled>
              <ResumeItemComponent
                {...constants.PROFILE_PERSONAL_DATA_CONTACT_EMAIL_PROPS}
                label={<FormattedMessageComponent id={translations.EMAIL} />}
                onEdit={handleEditCurrentEmailAssetClick}
                value={email}
              />
            </ProfilePersonalDataContactContentItemComponentStyled>
          </ProfilePersonalDataSectionComponentStyled>
        </LayoutHorizontalPaddingStyledComponent>
      </SectionDividerComponent>
    </ProfilePersonalDataContactComponentStyled>
  );
};

export default ProfilePersonalDataContactComponent;
