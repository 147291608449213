const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.cbProfileImage.cbCropModal.';

const APPLY = `${DOMAIN}applyButton`;
const CANCEL = `${DOMAIN}cancelLink`;
const DESCRIPTION = `${DOMAIN}description`;
const TITLE = `${DOMAIN}title`;
const UPLOAD_ANOTHER_PHOTO = `${DOMAIN}uploadAnotherPhotoButton`;

export { APPLY, CANCEL, DESCRIPTION, TITLE, UPLOAD_ANOTHER_PHOTO };
