// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';
// Types
import { FinancingLandingRowDetailsEarlyRepaymentLinkHandlersType } from './types/financing-landing-row-details-early-repayment-link.handlers.type';
import { FinancingLandingRowDetailsEarlyRepaymentLinkReturnHandlersType } from './types/financing-landing-row-details-early-repayment-link-return.handlers.type';

const earlyRepaymentLinkClickHandler = ({ eppId, navigate }) =>
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.FINANCING, eppId, RoutesEnumeration.EARLY_REPAYMENT])
    )
  );

const FinancingLandingRowDetailsEarlyRepaymentLinkHandlers = ({
  eppId,
  navigate,
}: FinancingLandingRowDetailsEarlyRepaymentLinkHandlersType): FinancingLandingRowDetailsEarlyRepaymentLinkReturnHandlersType => ({
  handleEarlyRepaymentLinkClick: () => earlyRepaymentLinkClickHandler({ eppId, navigate }),
});

export default FinancingLandingRowDetailsEarlyRepaymentLinkHandlers;
