// Vendors
import React from 'react';
// Components
import { TitleHeaderComponent } from '@openbank/cb-ui-commons';
import AvatarComponent from 'components/avatar/avatar.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CardSettingsContractSidebarComponent from 'containers/card-settings/views/contract/components/sidebar/card-settings-contract-sidebar.component';
// Constants
import { PERSONAL_PHOTO_TITLE_PROPS } from 'containers/card-settings/views/contract/constants/card-settings-contract.constants';
// Styles
import { LayoutGridContentComponentStyled } from 'styles/app.styled';

const CardSettingsContractOthersComponent = (): React.ReactElement => (
  <LayoutGridContentComponentStyled></LayoutGridContentComponentStyled>
);

export default CardSettingsContractOthersComponent;
