// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { RequestSignatureKeyConfirmationTrackingHandlersType } from './types/request-signature-key-confirmation.tracking.handlers.type';

const goToDashboardButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'goToDashboardButton',
  },
  type: TrackingEventsEnum.LINK,
});

const RequestSignatureKeyConfirmationTrackingHandlers = (
  track: TrackBuilderType
): RequestSignatureKeyConfirmationTrackingHandlersType => ({
  handleGoToDashboardButtonClickTracking: () => track(goToDashboardButtonClickTrackingHandler()),
});

export default RequestSignatureKeyConfirmationTrackingHandlers;
