// Vendors
import React from 'react';
// Components
import CardSettingsCheck3dWizardComponent from '../wizard/card-settings-check-3d-wizard.component';
import CardSettingsCheck3dConfirmationComponent from '../confirmation/card-settings-check-3d-confirmation.component';
// Contexts
import { useCardSettingsCheck3DContextConsumerHook } from '../../contexts/card-settings-check-3d.context';

const CardSettingsCheck3dOrchestratorComponent = (): React.ReactElement => {
  const { hasChallenged, currentStep } = useCardSettingsCheck3DContextConsumerHook();

  return hasChallenged ? (
    <CardSettingsCheck3dConfirmationComponent />
  ) : (
    <CardSettingsCheck3dWizardComponent {...{ currentStep }} />
  );
};

export default CardSettingsCheck3dOrchestratorComponent;
