// Vendors
import styled from 'styled-components';

export const MoreNotificationsComponentStyled = styled.div.attrs(() => ({
  'data-testid': 'more-notifications-component' })
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
`;