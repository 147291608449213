// Enumerations
import { TimerEnum } from '@openbank/cf-ui-static-data';
// Types
import { LoginBuilderHandlersType } from './types/login-builder.handlers.type';
import { LoginBuilderReturnHandlersType } from './types/login-builder-return.handlers.type';

const hideLoginAppLoaderEventHandler = ({ setFetching }: LoginBuilderHandlersType): any => {
  setTimeout(() => setFetching(false), TimerEnum.TIMEOUT_ONE_SECOND);
};

const LoginHandlers = (props: LoginBuilderHandlersType): LoginBuilderReturnHandlersType => ({
  handleHideLoginAppLoaderEvent: () => hideLoginAppLoaderEventHandler(props),
});

export default LoginHandlers;
