// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { DashboardTableTrackingHandlersType } from './types/dashboard-table.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const navigateToTransactionsViewLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'viewAll',
  },
  type: TrackingEventsEnum.LINK,
});

const DashboardTableLinkTrackingHandlers = (
  track: TrackBuilderType
): DashboardTableTrackingHandlersType => ({
  handleNavigateToTransactionsViewLinkClickTracking: () =>
    track(navigateToTransactionsViewLinkClickTrackingHandler()),
});

export default DashboardTableLinkTrackingHandlers;
