// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { CardStatusSwitchOnModalBuilderReturnTrackingHandlersType } from 'components/card-status/components/button/components/switch-on-modal/handlers/types/card-status-switch-on-modal-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const cancelSwitchOnCardLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancel',
  },
  type: TrackingEventsEnum.LINK,
});

const confirmSwitchOnCardButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'yesSwitchOnCard',
  },
  type: TrackingEventsEnum.LINK,
});

const closeSwitchOnCardModalAssetClickHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'switchOnCardChangeModalClose',
  },
  type: TrackingEventsEnum.LINK,
});

const openSwitchOnCardModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'switchOnCardChangeModalOpen',
  },
  type: TrackingEventsEnum.LINK,
});

const CardStatusSwitchOnModalTrackingHandlers = (
  track: TrackBuilderType
): CardStatusSwitchOnModalBuilderReturnTrackingHandlersType => ({
  handleCancelSwitchOnCardLinkClickTracking: () =>
    track(cancelSwitchOnCardLinkClickTrackingHandler()),
  handleConfirmSwitchOnCardButtonClickTracking: () =>
    track(confirmSwitchOnCardButtonClickTrackingHandler()),
  handleCloseSwitchOnCardModalAssetClickTracking: () =>
    track(closeSwitchOnCardModalAssetClickHandler()),
  handleOpenSwitchOnCardModalTracking: () => track(openSwitchOnCardModalTrackingHandler()),
});

export default CardStatusSwitchOnModalTrackingHandlers;
