// Vendors
//import { getLocaleFromBrowser } from '@openbank/cf-ui-framework'; // @TODO Habilitar cuando deje de ser el aleman solo el default
// Enumerations
import {
  CountriesLocaleEnum,
  EnvironmentsEnum,
  StorageLocalEnum,
} from '@openbank/cf-ui-static-data';
// Constants
import { HOSTS, IDS } from './constants/internationalization.utils.constants';
// Types
import { InternationalizationRegisterTranslationProviderType } from '../../types/internationalization-register-translation.provider.type';
import { InternationalizationHookUtilsType } from 'providers/internationalization/hooks/utils/types/internationalization.hook.utils.type';

const versionHasChanged = (version: string): boolean =>
  localStorage.getItem(StorageLocalEnum.VERSION) !== version;

const languageHasChanged = (language: string): boolean =>
  localStorage.getItem(StorageLocalEnum.LANGUAGE) !== language;

const changeDemoApiEnvironment = (url: string): string =>
  window.location.hostname.includes(EnvironmentsEnum.DEMO.toLowerCase())
    ? url.replace(EnvironmentsEnum.STG.toLowerCase(), EnvironmentsEnum.DEMO.toLowerCase())
    : url;

const changeHostDomain = (url: string): string =>
  changeDemoApiEnvironment(
    url.replace(HOSTS.MODEL, HOSTS.COBRANDED).replace(IDS.MODEL, IDS.COBRANDED)
  );

const newTranslationsVersionAvailable = ({
  language,
  lastChangedVersion,
}: InternationalizationRegisterTranslationProviderType | Record<string, string>): boolean =>
  Boolean(
    !localStorage.getItem(StorageLocalEnum.TRANSLATIONS) ||
      versionHasChanged(lastChangedVersion) ||
      languageHasChanged(language)
  );

const getUserLocale = ({
  languagePreference,
  profile,
}: InternationalizationHookUtilsType): string =>
  languagePreference ||
  profile?.userDetails?.language ||
  // getLocaleFromBrowser() || // @TODO Habilitar cuando deje de ser el aleman solo el default
  CountriesLocaleEnum.GERMANY;

export { changeHostDomain, getUserLocale, newTranslationsVersionAvailable };
