// Services
import { getDashboardInformationService } from '../services/dashboard.service';
// Types
import { DashboardComponentType } from '../types/dashboard.component.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
import { generateBasicDashboardSkeleton } from './utils/dashboard.resolver.utils';

const getDashboardInformationResolver = async (): Promise<
  [DashboardComponentType] | [null, true]
> => {
  const [response, { status }] = await getDashboardInformationService();

  return wasSuccessRequest(status) ? [generateBasicDashboardSkeleton(response)] : [null, true];
};

export { getDashboardInformationResolver };
