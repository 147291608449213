// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import { FinancingPPICancellationLandingResumeComponent } from './components/resume/financing-ppi-cancellation-landing-resume.component';
import { FinancingPPICancellationLandingFooterComponent } from './components/footer/financing-ppi-cancellation-landing-footer.component';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Constants
import {
  FINANCING_PPI_CANCELLATION_DESCRIPTION_PROPS,
  FINANCING_PPI_CANCELLATION_FEEDBACK_PROPS,
  FINANCING_PPI_CANCELLATION_TITLE_PROPS,
} from './constants/financing-ppi-cancellation-landing.constants';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
  LayoutColumnFlexComponentStyled,
} from 'styles/app.styled';
import { FinancingPPICancellationLandingComponentStyled } from './financing-ppi-cancellation-landing.component.styled';
// Translations
import { TITLE } from './translations/financing-ppi-cancellation-landing.translations';
// Utils
import { getPPICancellationDescription } from './utils/financing-ppi-cancellation-landing.utils';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';

const FinancingPPICancellationLandingComponent = (): React.ReactElement => {
  const { insurancesInfo } = useInsurancesContextConsumerHook();

  return (
    <WizardFeedbackComponent {...FINANCING_PPI_CANCELLATION_FEEDBACK_PROPS}>
      <AppCardSectionComponentStyled>
        <AppCardContentSectionComponentStyled>
          <FinancingPPICancellationLandingComponentStyled>
            <TitleHeaderComponent {...FINANCING_PPI_CANCELLATION_TITLE_PROPS}>
              <FormattedMessageComponent id={TITLE} />
            </TitleHeaderComponent>
            <LayoutColumnFlexComponentStyled>
              <ParagraphComponent {...FINANCING_PPI_CANCELLATION_DESCRIPTION_PROPS}>
                <FormattedMessageComponent
                  id={getPPICancellationDescription(insurancesInfo?.insurances.CPI.status)}
                />
              </ParagraphComponent>
              <FinancingPPICancellationLandingResumeComponent />
              <FinancingPPICancellationLandingFooterComponent />
            </LayoutColumnFlexComponentStyled>
          </FinancingPPICancellationLandingComponentStyled>
        </AppCardContentSectionComponentStyled>
      </AppCardSectionComponentStyled>
    </WizardFeedbackComponent>
  );
};

export { FinancingPPICancellationLandingComponent };
