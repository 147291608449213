// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Types
import {
  CardSettingsChangePinChallengeBuilderHandlersType,
  CardSettingsChangePinChallengeBuilderReturnHandlersType,
  CardSettingsChangePinChallengeProgressHandlersType,
  CardSettingsChangePinChallengeSuccessBuilderHandlersType,
  CardSettingsChangePinChallengeValidateBuilderHandlersType,
} from './types/card-settings-change-pin-challenge.handlers.type';
// Utilities
import { encrypt } from '@openbank/cf-ui-framework';

const manageErrorOnChangeEmailEventHandler = ({
  setChallenged,
  setResult,
}: Pick<
  CardSettingsChangePinChallengeBuilderHandlersType,
  'setChallenged' | 'setResult'
>): void => {
  setChallenged(false);
  setResult(false);
};

const manageSuccessOnChangeEmailEventHandler = ({
  progressId,
  setSolvedChallenged,
  setProgressId,
}: CardSettingsChangePinChallengeSuccessBuilderHandlersType): void => {
  setProgressId(progressId);
  setSolvedChallenged(true);
};

const validatePinButtonClickHandler = async ({
  handleValidatePinButtonClickTracking,
  newPin,
  publicPem,
  repeatedPin,
  setNewPin,
  setRepeatedPin,
  setChallenged,
}: CardSettingsChangePinChallengeValidateBuilderHandlersType): Promise<void> => {
  const encryptedNewPin = await encrypt(publicPem, newPin),
    encryptedRepeatedPin = await encrypt(publicPem, repeatedPin);
  handleValidatePinButtonClickTracking();
  setNewPin(encryptedNewPin);
  setRepeatedPin(encryptedRepeatedPin);
  setChallenged(true);
};

const CardSettingsChangePinChallengeHandlers = ({
  handleValidatePinButtonClickTracking,
  newPin,
  publicPem,
  repeatedPin,
  setChallenged,
  setNewPin,
  setProgressId,
  setRepeatedPin,
  setResult,
  setSolvedChallenged,
}: CardSettingsChangePinChallengeBuilderHandlersType): CardSettingsChangePinChallengeBuilderReturnHandlersType => ({
  handleManageErrorChangePinCardEvent: () =>
    manageErrorOnChangeEmailEventHandler({ setChallenged, setResult }),
  handleManageSuccessChangePinCardEvent: ({
    progressId,
  }: CardSettingsChangePinChallengeProgressHandlersType) =>
    manageSuccessOnChangeEmailEventHandler({ progressId, setSolvedChallenged, setProgressId }),
  handleValidatePinButtonClick: () =>
    validatePinButtonClickHandler({
      handleValidatePinButtonClickTracking,
      newPin,
      publicPem,
      repeatedPin,
      setChallenged,
      setNewPin,
      setRepeatedPin,
    }),
});

export default CardSettingsChangePinChallengeHandlers;
