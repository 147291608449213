// Vendors
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

export const TransactionsRouterReportGuardComponent = (): React.ReactElement => {
  if (getFeatureFlagInstance().isEnabled(FeatureFlagsEnumeration.OPEN_DISPUTE)) {
    return <Outlet />;
  }

  return <Navigate to={generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS)} />;
};
