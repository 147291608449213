// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { AccessPortabilityRequestBuilderReturnTrackingHandlersType } from './types/request-portability-builder-return.tracking.handlers.type';

const movementsChangeCheckboxTrackingHandler = (value: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickCheckbox',
    eventLabel: 'movements' + (value ? 'Checked' : 'Unchecked'),
  },
  type: TrackingEventsEnum.LINK,
});

const RequestPortabilityTrackingHandlers = (
  track: (param: unknown) => void
): AccessPortabilityRequestBuilderReturnTrackingHandlersType => ({
  handleMovementsChangeCheckboxTracking: (value: boolean) =>
    track(movementsChangeCheckboxTrackingHandler(value)),
});

export default RequestPortabilityTrackingHandlers;
