// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import ProfilePersonalNameAvatarHandlers from '../handlers/profile-personal-name-avatar.handlers';
import ProfilePersonalNameAvatarTrackingHandlers from '../handlers/profile-personal-name-avatar.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameAvatarHookType } from './types/profile-personal-name-avatar.hook.type';

const ProfilePersonalNameAvatarHook = (): ProfilePersonalNameAvatarHookType => {
  const { profile } = AppContextConsumerHook(),
    { genderTCode, targetTCode } = profile?.userDetails || {};

  return {
    ...ProfilePersonalNameAvatarHandlers({
      ...AppTrackingHook(ProfilePersonalNameAvatarTrackingHandlers),
      navigate: useNavigate(),
    }),
    genderTCode,
    targetTCode,
  };
};

export default ProfilePersonalNameAvatarHook;
