// Types
import { CardSettingsChangePinRepeatedPinMethodBuilderHandlersType } from '../types/card-settings-change-pin-repeated-pin.handlers.type';
// Utilities
import {
  newPinMatchesWithRepeatedPin,
  pinMatchesAllConstraints,
} from 'containers/card-settings/views/change-pin/utils/card-settings-change-pin.utils';

const isValidRepeatedPin = ({
  dateOfBirth,
  newPin,
  repeatedPin,
}: Pick<
  CardSettingsChangePinRepeatedPinMethodBuilderHandlersType,
  'dateOfBirth' | 'newPin' | 'repeatedPin'
>): boolean =>
  pinMatchesAllConstraints({ dateOfBirth, pin: repeatedPin }) &&
  newPinMatchesWithRepeatedPin({ newPin, repeatedPin });

export { isValidRepeatedPin };
