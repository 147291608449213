// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import {
  webIdExtendTokenService,
  webIdSignatureService,
} from '../services/step-6-signature.services';
// Types
import { WebIdSignatureResolverType } from './types/step-6-signature-services-props.resolver.type';
import { WebIdWebsocketDataType } from '../handlers/types/webid-websocket-data.type';

const webIdSignatureResolver = async ({
  caseId,
  isSuccess,
}: WebIdSignatureResolverType): Promise<[WebIdWebsocketDataType | null, boolean]> => {
  const [response, { status }] = await webIdSignatureService({ caseId, isSuccess });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

const webIdExtendTokenResolver = async (): Promise<[null, boolean]> => {
  const [response, { status }] = await webIdExtendTokenService();
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export { webIdSignatureResolver, webIdExtendTokenResolver };
