// Constants
import constants from './constants/financing-fast-cash-second-step.configurations.constants';
import serviceConstants from 'containers/financing/views/fast-cash/services/constants/financing-fast-cash.services.constants';
// Types
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';

export const getChallengeConfiguration = ({
  cardId,
  challengeError,
  handleChallengeError,
  handleChallengeSuccess,
  handleRetryChallengeError,
  params,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: serviceConstants.SAVE_FAST_CASH_ENDPOINT,
  baseServiceBuilder: { pathParams: { cardId }, params },
  baseServiceMethod: constants.CHALLENGE_PROPS.baseServiceMethod,
  challengeError,
  onRetry: handleRetryChallengeError,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
