// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Constants
import { MODAL_PROPS } from './constants/block-card-address-delete-modal.configurations.constants';
// Translations
import {
  CANCEL_BUTTON,
  DELETE_BUTTON,
  TITLE,
} from './translations/block-card-address-delete-modal.configurations.translations';
// Types
import { GetModalConfigurationType } from './types/get-modal-configuration.type';
import { GetModalConfigurationReturnType } from './types/get-modal-configuration-return.type';

export const getModalConfiguration = ({
  formatMessage,
  onCancel,
  onClose,
  onDelete,
}: GetModalConfigurationType): GetModalConfigurationReturnType => ({
  ...MODAL_PROPS,
  onHeaderClose: onClose,
  title: formatMessage({ id: TITLE }),
  isIosDevice: isIosWebView(),
  primaryButtonConfiguration: {
    children: formatMessage({ id: CANCEL_BUTTON }),
    onClick: onCancel,
  },
  linkConfiguration: {
    onClick: onDelete,
    children: formatMessage({ id: DELETE_BUTTON }),
  },
});
