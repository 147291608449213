// Vendors
import styled from 'styled-components';

const AccessKeySidebarComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  margin: 0;
`;

AccessKeySidebarComponentStyled.displayName = 'AccessKeySidebarComponentStyled';

export { AccessKeySidebarComponentStyled };
