// Vendors
import styled from 'styled-components';

const PasswordModuleAssetComponentStyled = styled.aside`
  flex: 0 0 auto;
`;

PasswordModuleAssetComponentStyled.displayName = 'PasswordModuleAssetComponentStyled';

export { PasswordModuleAssetComponentStyled };
