const ERROR_DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.errorFeedback.';
const SUCCESS_DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.successFeedback.';

const ERROR_BUTTON = `${ERROR_DOMAIN}retryButton`,
  ERROR_DESCRIPTION = `${ERROR_DOMAIN}description`,
  ERROR_LINK = `${ERROR_DOMAIN}cancelLink`,
  ERROR_TITLE = `${ERROR_DOMAIN}title`,
  SUCCESS_DESCRIPTION = `${SUCCESS_DOMAIN}description`,
  SUCCESS_LINK = `${SUCCESS_DOMAIN}returnToPersonalInformationLink`,
  SUCCESS_TITLE = `${SUCCESS_DOMAIN}title`;

export {
  ERROR_BUTTON,
  ERROR_DESCRIPTION,
  ERROR_LINK,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK,
  SUCCESS_TITLE,
};
