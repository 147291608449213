// Components
import { ReportSubscriptionAlreadyCancelledAdditionalInfoComponent } from '../components/additional-info/report-subscription-already-cancelled-additional-info.component';
import { ReportSubscriptionAlreadyCancelledConfirmationComponent } from '../components/confirmation/report-subscription-already-cancelled-confirmation.component';
import { ReportSubscriptionAlreadyCancelledDocumentsComponent } from '../components/documents/report-subscription-already-cancelled-documents.component';
// Translations
import {
  ADDITIONAL_INFORMATION_TITLE,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
  UPLOAD_DOCUMENTS_DESCRIPTION,
  UPLOAD_DOCUMENTS_TITLE,
} from 'containers/transactions/views/report/translations/transactions-report.translations';
import { ADDITIONAL_INFORMATION_DESCRIPTION } from '../translations/report-subscription-already-cancelled.translations';

const STEPS = [
  {
    component: ReportSubscriptionAlreadyCancelledAdditionalInfoComponent,
    description: ADDITIONAL_INFORMATION_DESCRIPTION,
    title: ADDITIONAL_INFORMATION_TITLE,
  },
  {
    component: ReportSubscriptionAlreadyCancelledDocumentsComponent,
    description: UPLOAD_DOCUMENTS_DESCRIPTION,
    title: UPLOAD_DOCUMENTS_TITLE,
  },
  {
    component: ReportSubscriptionAlreadyCancelledConfirmationComponent,
    description: CONFIRMATION_DESCRIPTION,
    title: CONFIRMATION_TITLE,
  },
];

export { STEPS };
