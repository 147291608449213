// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { TitleComponent } from '@openbank/cb-ui-commons';
import DashboardSpentInnerSummaryComponent from './components/summary-inner/dashboard-spent-summary-inner.component';
// Constants
import constants from './constants/dashboard-spent-summary.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Enumerations
import { MiscellaneousCurrenciesEnum } from '@openbank/cf-ui-static-data';
// Styles
import { DashboardSpentSummaryComponentStyled } from './dashboard-spent-summary.component.styled';
// Translations
import translations from '././translations/dashboard-spent-summary.translations';
// Types
import { DashboardSpentSummaryComponentType } from './types/dashboard-spent-summary.component.type';

const DashboardSpentSummaryComponent = ({
  currency = MiscellaneousCurrenciesEnum.EURO,
  percentageDifference,
  testId = constants.DASHBOARD_SPENT_SUMMARY_DEFAULT_TEST_ID,
  total,
}: DashboardSpentSummaryComponentType): React.ReactElement => (
  <DashboardSpentSummaryComponentStyled {...{ testId }}>
    <TitleComponent {...constants.DASHBOARD_SPENT_SUMMARY_TITLE_PROPS}>
      <FormattedNumber
        {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
        value={total}
      />
    </TitleComponent>
    <DashboardSpentInnerSummaryComponent
      additionalText={translations.PERCENTAGE}
      percentageDifference={percentageDifference}
      title={translations.TOTAL}
    />
  </DashboardSpentSummaryComponentStyled>
);

export default DashboardSpentSummaryComponent;
