// Vendor
import { useState } from 'react';
// Contexts
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Types
import { SignOutHookReturnType } from './types/sign-out.hook.return.type';
import { SignOutHookType } from './types/sign-out.hook.type';
// Handlers 
import { SignOutHandlers } from '../handlers/sign-out.handlers';
import { DeviceManagerHandlers } from 'containers/security-keys/views/devices/handlers/signature-key-devices.handlers';
import { SignOutHandlersTracking } from '../handlers/sign-out.handlers.tracking';
// Utilities
import { clearSessionData } from 'utils/session/session.utils';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';

const SignOutHook = ({
  device
}: SignOutHookType): SignOutHookReturnType => {
  const { sessions, setSessions, setTrusted, loading, setLoading, setFetching, setDevices, setBlockedDevices, setError } = SignatureKeyDevicesContextConsumerHook();
  const { setToastConfiguration } = ToastContextConsumerHook();
  const [open, setOpen] = useState<boolean>(false);

  const { getLastDevices } = DeviceManagerHandlers({
    setTrusted,
    setFetching,
    setDevices,
    setBlockedDevices,
    setError,
  });

  const { onSignOut, onOpenSignOutModal } = SignOutHandlers({
    ...AppTrackingHook(SignOutHandlersTracking),
    setToastConfiguration,
    device,
    sessions,
    setSessions,
    setLoading,
    setOpen,
    setFetching,
    setDevices,
    setBlockedDevices,
    setError,
    clearSessionData,
    getLastDevices
  });

  return {
    loading,
    open,
    onSignOut,
    onCloseModal: () => setOpen(false),
    onOpenModal: onOpenSignOutModal
  };
};

export { SignOutHook };