// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
// Translations
import {
  BILLING_ACCOUNT,
  TITLE,
} from '../translations/card-settings-contract-current-billing.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS = {
  label: BILLING_ACCOUNT,
};

const CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS = {
  id: CardContractInfoSectionsEnumeration.BILLING,
  title: TITLE,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_ACCOUNT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_BILLING_DIVIDER_PROPS,
};
