// Vendors
import React from 'react';
// Components
import {
  CheckboxComponent,
  ParagraphComponent,
  LineSeparatorComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/request-portability-checkboxes.constants';
// Hooks
import RequestPortabilityCheckboxesHook from './hooks/request-portability-checkboxes.hook';
// Translations
import translations from './translations/request-portability-checkboxes.translations';
// Styled
import {
  RequestPortabilityCheckboxesContainerStyled,
  RequestPortabilityCardCheckboxContainerStyled,
} from './request-portability-checkboxes.component.styles';
// Types
import { RequestPortabilityCheckboxesType } from './types/request-portability-checkboxes.hook.type';
// Utils
import {
  isMovementsCheckboxDisabled,
  validateBeneficiary,
} from './utils/request-portability-checkboxes.utils';

const RequestPortabilityCheckboxes = ({
  contractedProductsToggle,
}: RequestPortabilityCheckboxesType): React.ReactElement | null => {
  const {
    formatMessage,
    handleMovementsCheckBoxChangeEvent,
    handleBeneficiaryCardCheckBoxChangeEvent,
    handleMainCardCheckBoxChangeEvent,
    mainCardCheckbox,
    movementsCheckbox,
    beneficiaryCardCheckbox,
    cards,
  } = RequestPortabilityCheckboxesHook();

  return contractedProductsToggle ? (
    <>
      <RequestPortabilityCheckboxesContainerStyled>
        <ParagraphComponent {...constants.REQUEST_PORTABILITY_CARDS_CHECKBOX_TITLE_PROPS}>
          <FormattedMessageComponent id={translations.CARDS_CHECKBOX_TILE_LABEL} />
        </ParagraphComponent>
        <RequestPortabilityCardCheckboxContainerStyled>
          <CheckboxComponent
            {...constants.REQUEST_PORTABILITY_CHECKBOX_PROPS}
            onChange={handleMainCardCheckBoxChangeEvent}
            value={mainCardCheckbox}
            title={formatMessage({
              id: translations.MAIN_CARD_CHECKBOX_LABEL,
            })}
          />
          {validateBeneficiary(cards) && (
            <CheckboxComponent
              {...constants.REQUEST_PORTABILITY_CHECKBOX_PROPS}
              onChange={handleBeneficiaryCardCheckBoxChangeEvent}
              value={beneficiaryCardCheckbox}
              title={formatMessage({
                id: translations.BENEFICIARY_CARD_CHECKBOX_LABEL,
              })}
            />
          )}
        </RequestPortabilityCardCheckboxContainerStyled>
        <LineSeparatorComponent {...constants.LINE_SEPARATOR_PROPS} />
      </RequestPortabilityCheckboxesContainerStyled>
      <CheckboxComponent
        {...constants.REQUEST_PORTABILITY_CHECKBOX_PROPS}
        onChange={handleMovementsCheckBoxChangeEvent}
        value={movementsCheckbox}
        title={formatMessage({
          id: translations.MOVEMENTS_CHECKBOX_LABEL,
        })}
        disabled={isMovementsCheckboxDisabled({ mainCardCheckbox, beneficiaryCardCheckbox })}
      />
    </>
  ) : null;
};

export default RequestPortabilityCheckboxes;
