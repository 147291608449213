// Constants
import { CHANGE_PIN_SERVICE_CONFIGURATION } from 'containers/card-settings/views/change-pin/services/constants/card-settings-change-pin.service.constants';

const CARD_SETTINGS_CHANGE_PIN_CHALLENGE_PROPS = {
    baseService: CHANGE_PIN_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'put',
  },
  CARD_SETTINGS_CHANGE_PIN_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'card-settings-change-pin-button',
  };

export { CARD_SETTINGS_CHANGE_PIN_CHALLENGE_PROPS, CARD_SETTINGS_CHANGE_PIN_BUTTON_PROPS };
