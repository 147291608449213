// Constants
import { assetConstants, titleConstants } from '@openbank/cb-ui-commons';

export const LOWEST_CREDIT_LIMIT = 210;
export const DEFAULT_CURRENCY = 'EUR';

export const LOWEST_CREDIT_LIMIT_ASSET_PROPS = {
  id: 'Attention_Alternative',
  sizes: {
    height: 120,
    width: 120,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'lowest-credit-limit-asset',
};

export const LOWEST_CREDIT_LIMIT_TEXT_PROPS = {
  testId: 'lowest-credit-limit-text',
  tag: titleConstants.TAGS.H5,
};

export const LOWEST_CREDIT_LIMIT_BUTTON_PROPS = {
  rwdFull: false,
};
