// Vendors
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import CardStatusButtonTrackingHandlers from '../handlers/card-status-button.tracking.handlers';
import CardStatusButtonHandlers from '../handlers/card-status-button.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardStatusButtonHookType } from './types/card-status-button.hook.type';
import { CardStatusButtonHookBuildType } from './types/card-status-button.hook.build.type';

const CardStatusButtonHook = ({
  activateCardRedirection,
  breadCrumbPath,
}: CardStatusButtonHookBuildType): CardStatusButtonHookType => {
  const [showActivateModal, setShowActivateModal] = useState<boolean>(false);
  const { currentCard } = AppContextConsumerHook(),
    { status } = currentCard;

  return {
    ...CardStatusButtonHandlers({
      ...AppTrackingHook(CardStatusButtonTrackingHandlers),
      activateCardRedirection,
      breadCrumbPath,
      navigate: useNavigate(),
      setShowActivateModal,
      status,
    }),
    showActivateModal,
    status,
  };
};

export default CardStatusButtonHook;
