// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

const CARD_STATUS_TAG_TYPES = {
  [CardStatusTypesEnumeration.BLOCKED]: tagConstants.TYPES.WARNING,
  [CardStatusTypesEnumeration.CANCELLED]: tagConstants.TYPES.WARNING,
  [CardStatusTypesEnumeration.LOCKED]: tagConstants.TYPES.DISABLED,
  [CardStatusTypesEnumeration.INACTIVE]: tagConstants.TYPES.IDENTIFICATION,
  [CardStatusTypesEnumeration.TEMPORARILY_BLOCKED]: tagConstants.TYPES.WARNING,
};

export { CARD_STATUS_TAG_TYPES };
