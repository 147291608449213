// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import constants, {
  SECURITY_KEYS_DEVICES_ROUTE_PROPS,
} from 'containers/security-keys/components/router/constants/security-keys.constants';
// Components
import { WorkInProgressComponent } from 'components/working-in-progress/work-in-progress.component';
// Containers
const RequestSignatureKeyView = lazy(
  () => import('containers/security-keys/views/request-signature-key/request-signature-key.view')
);
const SupportLandingView = lazy(
  () => import('containers/security-keys/views/landing/security-keys-landing.view')
);
const SecurityKeysAccessKeyChangeView = lazy(
  () =>
    import('containers/security-keys/views/access-key-change/security-keys-access-key-change.view')
);
const SecurityKeysSignatureKeyChangeView = lazy(
  () =>
    import(
      'containers/security-keys/views/signature-key-change/security-keys-signature-key-change.view'
    )
);
const SignatureKeyDevicesContainer = lazy(
  () => import('containers/security-keys/views/devices/signature-key-devices.container')
);

const SecurityKeysRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...constants.ACESS_KEY_ROUTE_PROPS} element={<SecurityKeysAccessKeyChangeView />} />
    <Route {...constants.REQUEST_SIGNATURE_KEY_ROUTE_PROPS} element={<RequestSignatureKeyView />} />
    <Route {...constants.SIGNATURE_KEY_ROUTE_PROPS} element={<SecurityKeysSignatureKeyChangeView />} />
    <Route {...constants.DEVICES_ROUTE_PROPS} element={<SignatureKeyDevicesContainer />} />
    <Route {...constants.SUPPORT_LANDING_ROUTE_PROPS} element={<SupportLandingView />} />
    <Route {...SECURITY_KEYS_DEVICES_ROUTE_PROPS} element={<WorkInProgressComponent />} />
  </Routes>
);

export default SecurityKeysRouterComponent;
