// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const BlockCardAddressComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const InputSelectorBoxAddressesStyled = styled.div<{ isIosDevice: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileAndTabletMediaQuery} {
    grid-template-columns: 1fr;
    max-height: ${({ isIosDevice }) => isIosDevice && convertPxsToRems({ pixels: 270, base: 14 })};
  }
`;
