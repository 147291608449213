// Constants
import {
  ERROR_MESSAGES,
  MAX_FILE_SIZE,
  TITLE_PREFIX,
  VALID_FORMATS,
} from './constants/upload-document.utils.constants';
// Enumerations
import { DocumentsUploadErrorsEnumeration } from '../enumerations/documents-upload-errors.enumeration';
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
// Types
import { GetBackOfficeMessageReturnType } from './types/upload-document-return.utils.type';
import { GetFileUploadedIfNotCancelledPropsType } from './types/upload-document-get-file-uploaded-if-not-cancelled-props.utils.type';
import { GetStateFilePropsType } from './types/upload-document-get-state-file-props.utils.type';

const getUploadDocumentTitle = (documentType: string): string => `${TITLE_PREFIX}${documentType}`;

const isFileBiggerThan10MB = (file: File | null): boolean =>
  file ? file.size > MAX_FILE_SIZE : false;

const isFileWeirdFormat = (file: File | null): boolean =>
  !!file && !VALID_FORMATS.includes(file.type);

const isInvalidFile = (file: File | null): DocumentsUploadErrorsEnumeration | undefined => {
  if (isFileBiggerThan10MB(file)) return DocumentsUploadErrorsEnumeration.OVERSIZED_FILE;

  if (isFileWeirdFormat(file)) return DocumentsUploadErrorsEnumeration.INVALID_FORMAT;
};

const getStateFile = ({
  state,
  isCancelled,
}: GetStateFilePropsType): DocumentsUploadStatesEnumeration =>
  isCancelled ? DocumentsUploadStatesEnumeration.DEFAULT : state;

const getFileUploadedIfNotCancelled = ({
  file,
  isCancelled,
}: GetFileUploadedIfNotCancelledPropsType): File | null => (isCancelled ? null : file);

const getBackOfficeMessage = (
  errorType?: DocumentsUploadErrorsEnumeration
): GetBackOfficeMessageReturnType =>
  (errorType && ERROR_MESSAGES?.[errorType]) || {
    backOfficeErrorMessage: '',
    backOfficeErrorTag: '',
  };

export {
  getBackOfficeMessage,
  getUploadDocumentTitle,
  isInvalidFile,
  getStateFile,
  getFileUploadedIfNotCancelled,
};
