// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep1PointsComponent } from './components/points/financing-ppi-contracting-step1-points.component';
import { FinancingPPIContractingStep1HiringLegalComponent } from './components/legal/financing-ppi-contracting-step1-hiring-legal.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_DESCRIPTION_PROPS } from './constants/financing-ppi-contracting-step1.constants';
import { FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS } from '../../constants/financing-ppi-contracting.constants';
// Hooks
import { useFinancingPPIContractingStep1Hook } from './hooks/financing-ppi-contracting-step1.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { DESCRIPTION } from './translations/financing-ppi-contracting-step1.translations';

const FinancingPPIContractingStep1Component = (): React.ReactElement => {
  const { handleGoToStep2ButtonClick: onClick } = useFinancingPPIContractingStep1Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <FinancingPPIContractingStep1PointsComponent />
      <FinancingPPIContractingStep1HiringLegalComponent />
      <WizardStepFooterComponent
        {...FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ onClick }}
        shouldShowModal={false}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingPPIContractingStep1Component };
