// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { PAYLOAD_PROPS } from './constants/transactions-report-landing.handlers.tracking.constants';
// Types
import { TransactionsReportLandingBuilderReturnTrackingHandlersType } from './types/transactions-report-landing-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const optionClickTrackingHandler = (eventLabel: string): TrackingLinkEventReturnType => ({
  payload: {
    ...PAYLOAD_PROPS,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsReportLandingTrackingHandlers = (
  track: TrackBuilderType
): TransactionsReportLandingBuilderReturnTrackingHandlersType => ({
  handleOptionClickTracking: eventLabel => track(optionClickTrackingHandler(eventLabel)),
});

export default TransactionsReportLandingTrackingHandlers;
