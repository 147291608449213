const DOMAIN_FAST_CASH_FIRST_STEP_TOTAL_AMOUNT =
  'cobranded.services.userSettings.privateArea.financing.fastCash.firstStep.totalAmount.';

const TITLE = {
  FIRST: `${DOMAIN_FAST_CASH_FIRST_STEP_TOTAL_AMOUNT}first.title`,
  SECOND: `${DOMAIN_FAST_CASH_FIRST_STEP_TOTAL_AMOUNT}second.title`,
};
const TOOLTIP = `${DOMAIN_FAST_CASH_FIRST_STEP_TOTAL_AMOUNT}tooltip`;

export { TITLE, TOOLTIP };
