// Vendors
import { apiUtilsCb, cookies } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/security-keys-signature-key-change-second-step.validation.service.constants';
// Enumerations
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Types
import { SignatureKeyValidationServicePropsType } from './types/security-keys-signature-key-change-second-step.validation.services.type';

const validateSignatureKeyService = async ({
  signatureKey,
}: SignatureKeyValidationServicePropsType): Promise<[Record<string, any>, Response]> => {
  const accessToken = cookies.getCookie(StorageCookiesEnum.ACCESS_TOKEN);
  return apiUtilsCb.post({
    endpoint: constants.VALIDATE_SIGNATURE_KEY_SERVICE,
    params: { signatureKey },
    customHeaders: { accessToken },
  });
};

export { validateSignatureKeyService };
