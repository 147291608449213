// Types
import { CleanChallengeHandlersReturnType } from './types/clean-challenge-return.handlers.type';
import { CleanChallengeHandlersType } from './types/clean-challenge.handlers.type';

const challengeUnmountHandler = ({
  setChallenge,
  setChallengeError,
}: CleanChallengeHandlersType): void => {
  setChallenge(undefined);
  setChallengeError(false);
};

export const CleanChallengeHandlers = (
  props: CleanChallengeHandlersType
): CleanChallengeHandlersReturnType => ({
  handleChallengeUnmount: () => challengeUnmountHandler(props),
});
