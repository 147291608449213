const FINANCING_PPI_LANDING_CARD_BASE_PROPS = {
    image: {
      id: 'ppi-alternative',
      type: 'ILLUSTRATION',
    },
    testId: 'ppi-info-card',
  },
  FINANCING_PPI_LANDING_CARD_FIRST_ICON = { icon: 'giving_happyness' },
  FINANCING_PPI_LANDING_CARD_SECOND_ICON = { icon: 'payouts' };

const FINANCING_PPI_PERCENTAGE_PROMILE_BASE = 10;

export {
  FINANCING_PPI_LANDING_CARD_BASE_PROPS,
  FINANCING_PPI_LANDING_CARD_FIRST_ICON,
  FINANCING_PPI_LANDING_CARD_SECOND_ICON,
  FINANCING_PPI_PERCENTAGE_PROMILE_BASE,
};
