// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from './constants/personal-number-step4-actions.constants';
import { FOURTH_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import PersonalNumberStep4ActionsHook from './hooks/personal-number-step4-actions.hook';
// Utilities
import { confirmChangePhoneActionButtonIsDisabled } from './utils/personal-number-step4-actions.utils';
// Constants
import {
  CONFIRM,
  BACK_BUTTON,
} from 'containers/personal/views/number/translations/personal-number.translations';

const PersonalNumberStep4ActionsComponent = (): React.ReactElement => {
  const { challenged, fetching, handleConfirmChangePhoneButtonClick } =
    PersonalNumberStep4ActionsHook();

  return (
    <WizardStepFooterComponent
      hasDefaultLeftButton={
        constants.PERSONAL_NUMBER_STEP_4_ACTIONS.WIZARD_PROPS.hasDefaultLeftButton
      }
      isFooterDisabled={fetching}
      leftButtonConfiguration={{
        children: <FormattedMessageComponent id={BACK_BUTTON} />,
      }}
      rightButtonConfiguration={{
        children: <FormattedMessageComponent id={CONFIRM} />,
        disabled: confirmChangePhoneActionButtonIsDisabled({ challenged, fetching }),
        loading: fetching,
        onClick: handleConfirmChangePhoneButtonClick,
      }}
      targets={FOURTH_STEP_TARGETS}
    />
  );
};

export default PersonalNumberStep4ActionsComponent;
