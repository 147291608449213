// Resolvers
import { fetchCustomerIdResolver } from '../resolvers/customer-id.resolvers';
// Types
import { CustomerIdProviderHandlersType } from './types/customer-id.provider.handlers.type';
import { CustomerIdReturnProviderHandlersType } from './types/customer-id-return.provider.handlers.type';

const fetchCustomerIdHandler = async ({
  setCustomerId,
  setFetching,
}: CustomerIdProviderHandlersType): Promise<any> => {
  setFetching(true);
  const [customerId] = await fetchCustomerIdResolver();

  customerId && setCustomerId(customerId);
  setFetching(false);
};

const CustomerIdHandlers = (
  props: CustomerIdProviderHandlersType
): CustomerIdReturnProviderHandlersType => ({
  handleFetchCustomerId: () => fetchCustomerIdHandler(props),
});

export default CustomerIdHandlers;
