// Vendors
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import { DateFormatOptions } from '@openbank/web-ui-framework';

const TRANSACTIONS_DATE_PROPS = {
  testId: 'transactions-date',
};

// TODO: Once static-data version is upgraded this constant will not be needed, we can use FORMAT_DD_MM_YYYY_HH_MM directly
const TRANSACTION_DATE_FORMAT_OPTIONS: DateFormatOptions = {
  ...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY_HH_MM,
  minute: 'numeric',
};

export { TRANSACTION_DATE_FORMAT_OPTIONS, TRANSACTIONS_DATE_PROPS };
