const DOMAIN =
  'cobranded.services.userSettings.privateArea.documentation.documentationLanding.documentsContent.';

const CONTRACT_NUMBER = `${DOMAIN}contractNumber`,
  NAME = `${DOMAIN}name`,
  TYPE = `${DOMAIN}type`,
  CARDHOLDER = `${DOMAIN}cardholder`,
  BENEFICIARY = `${DOMAIN}beneficiary`,
  RETRY = `${DOMAIN}retry`;

export { BENEFICIARY, CARDHOLDER, CONTRACT_NUMBER, NAME, TYPE, RETRY };
