// Enums
import { RequestEmailInputErrorEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.requestPortability.step2.';

export default {
  EMAIL_TO_SEND_TITLE: `${DOMAIN}emailTitle`,
  YOUR_ACCOUNT_EMAIL_RADIO_TITLE: `${DOMAIN}yourEmailRadioTitle`,
  OTHER_EMAIL_RADIO_TITLE: `${DOMAIN}otherRadioTitle`,
  ERRORS: {
    [RequestEmailInputErrorEnumeration.FORMAT]: `${DOMAIN}emailAddressError`,
    [RequestEmailInputErrorEnumeration.EMPTY]: `${DOMAIN}emailAddressEmptyError`,
  },
  EMAIL_INPUT_LABEL: `${DOMAIN}emailAddressInputLabel`,
  EMAIL_INPUT_PLACEHOLDER: `${DOMAIN}emailInputPlaceholderLabel`,
  OTHER_EMAiL_INPUT_HELPER: `${DOMAIN}emailInput.helperMessage`,
};
