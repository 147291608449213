// Vendors
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Contexts
import { FinancingEarlyRepaymentContextConsumerHook } from '../contexts/financing-early-repayment.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import FinancingEarlyRepaymentHandlers from '../handlers/financing-early-repayment.handlers';
// Types
import { FinancingEarlyRepaymentReturnHookType } from './types/financing-early-repayment-return.hook.type';

const FinancingEarlyRepaymentHook = (): FinancingEarlyRepaymentReturnHookType => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { eppId } = useParams();

  const { currentCard } = AppContextConsumerHook(),
    { cmsCardId: cardNumber, id: cardId } = currentCard,
    { error, setError, setEppDetails } = FinancingEarlyRepaymentContextConsumerHook();

  const { handleEarlyRepaymentRender } = FinancingEarlyRepaymentHandlers({
    cardId,
    eppId,
    setEppDetails,
    setError,
    setFetching,
  });

  useEffect(() => {
    handleEarlyRepaymentRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cardNumber,
    error,
    fetching,
  };
};

export default FinancingEarlyRepaymentHook;
