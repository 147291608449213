// Vendors
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

export const FinancingEarlyRepaymentSecondStepSummaryComponentStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, minmax(0, 1fr));
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-auto-flow: column;
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    grid-template-rows: repeat(6, minmax(0, 1fr));
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;
