// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MESSAGE_PROPS } from './constants/transactions-report-landing-message.constants';
// Translations
import { MISSING_REASON_MESSAGE } from './translations/transactions-report-landing-message.translations';

export const TransactionsReportLandingMessageComponent = (): React.ReactElement => (
  <MessageComponent {...MESSAGE_PROPS}>
    <ParagraphComponent>
      <FormattedMessageComponent id={MISSING_REASON_MESSAGE} />
    </ParagraphComponent>
  </MessageComponent>
);
