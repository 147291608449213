// Constants
import {
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_ERROR_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_LOADING_ASSET_PROPS,
} from './constants/financing-ppi-contracting-download.utils.constants';
// Types
import {
  FinancingPPIContractingDownloadBuilderUtilsType,
  FinancingPPIContractingDownloadUtilsType,
} from './types/financing-ppi-contracting-download.utils.type';
import { FinancingPPIContractingDownloadType } from '../hooks/types/financing-ppi-contracting-download.type';

const getDownloadDocumentTermsAsset = ({
  fetching,
  errorDocumentDownloaded,
}: Pick<
  FinancingPPIContractingDownloadType,
  'fetching' | 'errorDocumentDownloaded'
>): FinancingPPIContractingDownloadUtilsType =>
  ({
    [`${fetching}`]: FINANCING_PPI_CONTRACTING_STEP_2_LOADING_ASSET_PROPS,
    [`${errorDocumentDownloaded}`]: FINANCING_PPI_CONTRACTING_STEP_2_ERROR_ASSET_PROPS,
  }['true'] || FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS);

const getDownloadDocumentTermsLabel = ({
  fetching,
  errorDocumentDownloaded,
  translations: { DOWNLOAD, DOWNLOADING, ERROR },
}: FinancingPPIContractingDownloadBuilderUtilsType): string =>
  ({
    [`${fetching}`]: DOWNLOADING,
    [`${errorDocumentDownloaded}`]: ERROR,
  }['true'] || DOWNLOAD);

export { getDownloadDocumentTermsAsset, getDownloadDocumentTermsLabel };
