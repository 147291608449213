// Vendors
import { apiUtilsCb, cookies } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/security-keys-access-key-change-second-step.validation.service.constants';
// Enumerations
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Types
import { AccessKeyValidationServicePropsType } from './types/security-keys-access-key-change-second-step.validation.services.type';

const validateAccessKeyService = async ({
  oldAccessKey,
  newAccessKey,
}: AccessKeyValidationServicePropsType): Promise<[Record<string, any>, Response]> => {
  const accessToken = cookies.getCookie(StorageCookiesEnum.ACCESS_TOKEN);
  return apiUtilsCb.post({
    endpoint: constants.VALIDATE_ACCESS_KEY_SERVICE,
    params: { newAccessKey, oldAccessKey },
    customHeaders: { accessToken },
  });
};

export { validateAccessKeyService };
