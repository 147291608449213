// Vendors
import { useEffect } from 'react';
// Contexts
import { SecurityKeysSignatureKeyChangeContextConsumerHook } from 'containers/security-keys/views/signature-key-change/contexts/security-keys-signature-key-change.context';
// Handlers
import SecurityKeysSignatureKeyChangeThirdStepTrackingHandlers from '../../handlers/security-keys-signature-key-change.third-step.tracking.handlers';
import SignatureKeyChangeStep3ActionsHandlers from '../handlers/security-keys-signature-key-change-third-step.actions.handlers';
// Hooks
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SignatureKeyChangeStep3ActionsHandlersParamsType } from './types/security-keys-signature-key-change-third-step-actions-handlers.type';
import { useFetchErrorContextConsumerHook } from 'contexts/fetch-error/fetch-error.context';

const SecurityKeysSignatureKeyChangeThirdStepActionsHook =
  (): SignatureKeyChangeStep3ActionsHandlersParamsType => {
    const { fetching, setFetching } = useFetchErrorContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();

    const {
      isDisabledLastStep,
      signatureKey,
      newSignatureKey,
      progressId,
      setSignatureKey,
      setNewSignatureKey,
      setProgressId,
      setRepeatNewSignatureKey,
    } = SecurityKeysSignatureKeyChangeContextConsumerHook();

    const {
      handleNextButtonClickTrackingHandlerStep3,
      handleOnEnterThirdStepSignatureKeyChangeTrackingHandler,
    } = AppTrackingHook(SecurityKeysSignatureKeyChangeThirdStepTrackingHandlers);

    const { handleFinishActionsEvent } = SignatureKeyChangeStep3ActionsHandlers({
      signatureKey,
      handleNextButtonClickTrackingHandlerStep3,
      newSignatureKey,
      progressId,
      setFetching,
      setSignatureKey,
      setNewSignatureKey,
      setRepeatNewSignatureKey,
      setResult,
      setProgressId,
    });

    useEffect(() => {
      handleOnEnterThirdStepSignatureKeyChangeTrackingHandler();
    }, []);

    return {
      fetching,
      handleFinishActionsEvent,
      isDisabledLastStep,
    };
  };

export { SecurityKeysSignatureKeyChangeThirdStepActionsHook };
