// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_LINK_PROPS,
} from './constants/financing-ppi-contracting-step1-hiring-legal.constants';
// Hooks
import { useFinancingPPIContractingStep1HiringLegalHook } from './hooks/financing-ppi-contracting-step1-hiring-legal.hook';
// Translations
import { LEGAL_TEXT } from './translations/financing-ppi-contracting-step1-hiring-legal.translations';

const FinancingPPIContractingStep1HiringLegalComponent = (): React.ReactElement | null => {
  const { handleOpenGermanBranchWebsiteLinkClick } =
    useFinancingPPIContractingStep1HiringLegalHook();

  return (
    <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_PROPS}>
      <FormattedMessageComponent
        id={LEGAL_TEXT}
        values={{
          link: (chunks: string | React.ReactNode) => (
            <LinkComponent
              {...FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_LINK_PROPS}
              onClick={handleOpenGermanBranchWebsiteLinkClick}
            >
              {chunks}
            </LinkComponent>
          ),
        }}
      />
    </ParagraphComponent>
  );
};

export { FinancingPPIContractingStep1HiringLegalComponent };
