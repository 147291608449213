// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { AccessPortabilityRequestBuilderHandlersType } from './types/request-portability-builder.handlers.type';
import { AccessPortabilityRequestBuilderReturnHandlersType } from './types/request-portability-builder-return.handlers.type';
import { RequestPortabilityParamsType } from '../../../types/request-portability-params.type';
// Utilities
import { generatePathRoute, generateAbsoluteRoute } from 'utils/app-routing.utils';

const personalDataToggleChange = ({
  setPortabilityParams,
  personalDataToggle,
  setPersonalDataToggle,
}: Pick<
  AccessPortabilityRequestBuilderHandlersType,
  'personalDataToggle' | 'setPersonalDataToggle' | 'setPortabilityParams'
>): void => {
  setPersonalDataToggle(prevState => !prevState);
  setPortabilityParams((prevState: RequestPortabilityParamsType | null) => {
    return {
      ...prevState,
      includePersonalData: !personalDataToggle,
    };
  });
};

const contractedProductsToggleChangeEvent = ({
  contractedProductsToggle,
  setContractedProductsToggle,
}: Pick<
  AccessPortabilityRequestBuilderHandlersType,
  'contractedProductsToggle' | 'setContractedProductsToggle'
>): void => {
  setContractedProductsToggle(!contractedProductsToggle);
};

const retryRequesPortabilityProcessLinkClickHandler = ({
  navigate,
}: Pick<AccessPortabilityRequestBuilderHandlersType, 'navigate'>): void => {
  const url = generatePathRoute([
    RoutesEnumeration.PERSONAL_INFORMATION,
    RoutesEnumeration.ACCESS_PORTABILITY,
  ]);

  navigate(generateAbsoluteRoute(url));
};

const goToPersonalDataLinkClickHandler = ({
  navigate,
}: Pick<AccessPortabilityRequestBuilderHandlersType, 'navigate'>): void => {
  navigate(generateAbsoluteRoute(RoutesEnumeration.PERSONAL_INFORMATION));
};

const RequestPortabilityHandlers = ({
  contractedProductsToggle,
  navigate,
  personalDataToggle,
  setContractedProductsToggle,
  setPersonalDataToggle,
  setPortabilityParams,
}: AccessPortabilityRequestBuilderHandlersType): AccessPortabilityRequestBuilderReturnHandlersType => ({
  handlePersonalDataToggleChangeEvent: () =>
    personalDataToggleChange({
      personalDataToggle,
      setPersonalDataToggle,
      setPortabilityParams,
    }),
  handleContractedProductsToggleChangeEvent: () =>
    contractedProductsToggleChangeEvent({ contractedProductsToggle, setContractedProductsToggle }),
  handleRetryRequesPortabilityProcessLinkClickHandler: () =>
    retryRequesPortabilityProcessLinkClickHandler({ navigate }),
  handleGoToPersonalDataLinkClickHandler: () => goToPersonalDataLinkClickHandler({ navigate }),
});

export default RequestPortabilityHandlers;
