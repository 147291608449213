// Enumerations
import { TransactionsStatusCodeEnumeration } from 'enumerations/transactions-status-code.enumeration';

const showTransactionStatusMessage = (
  transactionStatusCode: TransactionsStatusCodeEnumeration,
  transactionLabel: string | undefined
): boolean => {
  if (transactionLabel?.length) {
    return transactionStatusCode === TransactionsStatusCodeEnumeration.AUTHORIZED;
  }
  return false;
};

export { showTransactionStatusMessage };
