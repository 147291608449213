// Enums
import { ActivityTypeDeclaredEnumeration } from '../enumerations/employment-status.enumeration';

const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step2.employmentSection.';
const INCOME_DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step2.incomeSection.';

export default {
  EMPLOYMENT_DATA: `${DOMAIN}employmentData`,
  EMPLOYMENT_STATUS: `${DOMAIN}employmentStatus`,
  [ActivityTypeDeclaredEnumeration.EMPLOYED]: `${DOMAIN}salariedCivilServant`,
  [ActivityTypeDeclaredEnumeration.SELF_EMPLOYED]: `${DOMAIN}selfEmployed`,
  [ActivityTypeDeclaredEnumeration.RENTIER]: `${DOMAIN}rentist`,
  [ActivityTypeDeclaredEnumeration.PENSIONER]: `${DOMAIN}pensionist`,
  [ActivityTypeDeclaredEnumeration.UNEMPLOYED]: `${DOMAIN}noEconomicalActivity`,
  MONTH_AND_YEAR_JOIN_COMPANY: `${DOMAIN}monthAndYearJoinCompany`,
  MONTH: `${DOMAIN}month`,
  YEAR: `${DOMAIN}year`,
  INCOME: `${INCOME_DOMAIN}income`,
  APROXIMATE_MONTHLY_SALARY: `${INCOME_DOMAIN}aproximateMonthlySalary`,
  APROXIMATE_MONTHLY_SALARY_TOOLTIP: `${INCOME_DOMAIN}aproximateMonthlySalaryTooltip`,
  EMPLOYMENT_STATUS_CHANGED: `${DOMAIN}employmentStatusChanged`,
  EMPLOYMENT_STATUS_CHANGED_TEXT: `${DOMAIN}employmentStatusChangedText`,
};
