// Types
import { TransactionsLandingRowDetailsAmountBuilderHandlersType } from './types/transactions-landing-row-details-amount-builder.handlers.type';
import { TransactionsLandingRowDetailsAmountBuilderReturnHandlersType } from './types/transactions-landing-row-details-amount-builder-return.handlers.type';

const SeeDetailsElementAmountLinkClickHandler = ({
  handleSeeDetailsElementAmountLinkClickTracking,
  showModal,
  setShowModal,
}: TransactionsLandingRowDetailsAmountBuilderHandlersType): any => {
  handleSeeDetailsElementAmountLinkClickTracking();
  setShowModal(!showModal);
};

const TransactionsLandingRowDetailsAmountHandlers = (
  props: TransactionsLandingRowDetailsAmountBuilderHandlersType
): TransactionsLandingRowDetailsAmountBuilderReturnHandlersType => ({
  handleSeeDetailsElementAmountLinkClick: () => SeeDetailsElementAmountLinkClickHandler(props),
});

export default TransactionsLandingRowDetailsAmountHandlers;
