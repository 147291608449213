// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event Handlers
import { CardSettingsPaymentHandlers } from '../handlers/card-settings-payment.handlers';
import { CardSettingsPaymentTrackingHandlers } from '../handlers/card-settings-payment.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsPaymentHookType } from './types/card-settings-payment.hook.type';

const useCardSettingsPaymentHook = (): CardSettingsPaymentHookType => {
  const [showNonSecureStoreModal, setShowNonSecureStoreModal] = useState<boolean>(false);

  const { currentCardDetail } = AppContextConsumerHook(),
    { isAllowPaymentsNonSecStore = false } = currentCardDetail?.control || {};

  return {
    ...CardSettingsPaymentHandlers({
      ...AppTrackingHook(CardSettingsPaymentTrackingHandlers),
      setShowNonSecureStoreModal,
    }),
    isAllowPaymentsNonSecStore,
    showNonSecureStoreModal,
  };
};

export { useCardSettingsPaymentHook };
