// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/personal-number-step2.services.constants';
// Types
import { PersonalNumberStep2GenerateApiBuilderType } from '../types/personal-number-step2-generate-api-builder.type';
import { PersonalNumberStep2GenerateReturnHookType } from '../types/personal-number-step2-generate-return.hook.type';

const generateNewNumberOtpService = async (
  params: PersonalNumberStep2GenerateApiBuilderType
): Promise<[PersonalNumberStep2GenerateReturnHookType | Record<string, any>, Response]> => {
  const customHeaders = { deviceLocale: 'de_DE', applicationLocale: 'de_DE' };

  if (params.progressId) {
    customHeaders['progressId'] = params.progressId;
  }
  return apiUtilsCb.post({
    ...constants.GENERATE_OTP_SERVICE_CONFIGURATION,
    customHeaders,
    params: { phoneCountryCode: params?.phoneCountryCode, phone: params?.phone },
  });
};

export { generateNewNumberOtpService };
