// Vendors
import { titleConstants } from '@openbank/cb-ui-commons';

const PERSONAL_PHOTO_TITLE_PROPS = {
  testId: 'personal-photo-title',
  tag: titleConstants.TAGS.H4,
};

const PPI_INFO_URL = 'cb-api-cards-contra/v1/card-contracts/ppi/info';

export { PERSONAL_PHOTO_TITLE_PROPS, PPI_INFO_URL };
