const DOMAIN_REPORT_A_PROBLEM_NOT_RECEIVED_ADDITIONAL_INFO_INPUTS =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.notReceived.additionalInfo.inputs.';

const DATE_LABEL = `${DOMAIN_REPORT_A_PROBLEM_NOT_RECEIVED_ADDITIONAL_INFO_INPUTS}date.label`;

const AFTER_DATE_ERROR_MESSAGE = `${DOMAIN_REPORT_A_PROBLEM_NOT_RECEIVED_ADDITIONAL_INFO_INPUTS}date.errorMessage.afterDate`;

const TODAY_OR_LATER_DATE_ERROR_MESSAGE = `${DOMAIN_REPORT_A_PROBLEM_NOT_RECEIVED_ADDITIONAL_INFO_INPUTS}date.errorMessage.todayOrLaterDate`;

const DESCRIPTION_LABEL = `${DOMAIN_REPORT_A_PROBLEM_NOT_RECEIVED_ADDITIONAL_INFO_INPUTS}description.label`;

const DESCRIPTION_PLACEHOLDER = `${DOMAIN_REPORT_A_PROBLEM_NOT_RECEIVED_ADDITIONAL_INFO_INPUTS}description.placeholder`;

export {
  DATE_LABEL,
  AFTER_DATE_ERROR_MESSAGE,
  TODAY_OR_LATER_DATE_ERROR_MESSAGE,
  DESCRIPTION_LABEL,
  DESCRIPTION_PLACEHOLDER,
};
