// Constants
import { CALLBACK_WEBID } from './constants/step-6-credit-limit-increase.component.handler.constants';
// Enumerations
import { WebIdCallBackStatusEnumeration } from '../hooks/enums/webid-call-back-status.enumeration';
// Handlers
import { Step6WebsocketHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-6-websocket.handler';
// Types
import { Step6CreditLimitIncreaseHandlersReturnType } from './types/step-6-credit-limit-increase-return.component.handler.type';
import { Step6CreditLimitIncreaseHandlersPropsType } from './types/step-6-credit-limit-increase-props.component.handler.type';

const initWebsocketHandler = ({
  caseId,
  searchParams,
  wizardStatus,
  setFinalFeedback,
  setWizardStatus,
  setSearchParams,
  setIsWebsocketActive,
  webSocketConnect,
  webSocketDisconnect,
  webSocketSendMessage,
  handleWebidSignature,
}: Step6CreditLimitIncreaseHandlersPropsType): (() => void) => {
  let initializeWebsocket: () => Promise<void>;
  const callbackParameter = searchParams.get(CALLBACK_WEBID);

  if (callbackParameter === WebIdCallBackStatusEnumeration.SUCCESS) {
    setIsWebsocketActive(true);
    initializeWebsocket = async () => {
      await webSocketConnect(
        Step6WebsocketHandlers({
          caseId,
          wizardStatus,
          setIsWebsocketActive,
          setFinalFeedback,
          setWizardStatus,
          webSocketSendMessage,
        })
      );
    };
    initializeWebsocket();
  }
  if (callbackParameter === WebIdCallBackStatusEnumeration.FAILED) {
    handleWebidSignature({ caseId, isSuccess: false });
  }

  setSearchParams();
  return () => {
    if (callbackParameter) {
      webSocketDisconnect();
    }
  };
};

const Step6CreditLimitIncreaseHandlers = (
  props: Step6CreditLimitIncreaseHandlersPropsType
): Step6CreditLimitIncreaseHandlersReturnType => ({
  handleInitWebsocket: () => initWebsocketHandler(props),
});

export { Step6CreditLimitIncreaseHandlers };
