// Vendors
import React from 'react';
// Components
import { LinkComponent, ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  LINK_PROPS,
  SIDEBAR_INFO_PROPS,
} from './constants/personal-upload-documentation-sidebar.constants';
// Styled
import { PersonalUploadDocumentationSidebarComponentStyled } from './personal-upload-documentation-sidebar.component.styled';
// Translations
import {
  LINK_DESCRIPTION,
  SIDE_BAR_DESCRIPTION,
  SIDE_BAR_TITLE,
} from './translations/personal-upload-documentation-sidebar.translations';

const PersonalUploadDocumentsSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...SIDEBAR_INFO_PROPS}
    title={<FormattedMessageComponent id={SIDE_BAR_TITLE} />}
  >
    <PersonalUploadDocumentationSidebarComponentStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={SIDE_BAR_DESCRIPTION} />
      </ParagraphComponent>
      <LinkComponent {...LINK_PROPS}>
        <FormattedMessageComponent id={LINK_DESCRIPTION} />
      </LinkComponent>
    </PersonalUploadDocumentationSidebarComponentStyled>
  </SidebarComponent>
);

export { PersonalUploadDocumentsSidebarComponent };
