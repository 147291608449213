// Vendors
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

const CardSettingsContractCurrentMessageComponentStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: 1 1 0%;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

const CardSettingsContractCurrentMessageComponentSectionContentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export {
  CardSettingsContractCurrentMessageComponentStyled,
  CardSettingsContractCurrentMessageComponentSectionContentStyled,
};
