// Vendors
import React from 'react';
// Components
import { FilterModalInputSelectComponent } from '../../components/input-select/filter-modal-input-select.component';
import { FilterModalListSelectorDateComponent } from '../../components/list-selector-date/filter-modal-list-selector-date.component';
import { FilterModalInputRangeComponent } from '../../components/input-range/filter-modal-input-range.component';
// Constants
import { CATEGORY_OPTIONS } from 'components/filters/components/filter-modal/constants/filter-modal.constants';
// Enumerations
import { FilterModalInputRangeTypesEnumeration } from '../../components/input-range/enumerations/filter-modal-input-range-types.enumeration';
// Translations
import {
  CATEGORIES_PLACEHOLDER,
  CATEGORIES_TITLE,
} from './translations/transactions-filter-modal.translations';
// Types
import {
  GetTransactionsLandingFilterModalConfigurationReturnType,
  GetTransactionsLandingFilterModalConfigurationType,
} from './types/transactions-landing-filter-modal.configuration.type';

const getDeniedFilterModalConfiguration = ({
  formatMessage,
  formValues,
  handleFormValuesChange,
}: GetTransactionsLandingFilterModalConfigurationType): GetTransactionsLandingFilterModalConfigurationReturnType => {
  return {
    filterModalContentConfiguration: [
      {
        id: 'list-dates',
        component: (
          <FilterModalListSelectorDateComponent
            {...{
              formValues,
              handleFormValuesChange,
              showLineSeparator: true,
            }}
          />
        ),
      },
      {
        id: 'range-amount',
        component: (
          <FilterModalInputRangeComponent
            {...{
              handleFormValuesChange,
              formValues,
              type: FilterModalInputRangeTypesEnumeration.AMOUNT,
            }}
          />
        ),
      },
      {
        id: 'select-categories',
        component: (
          <FilterModalInputSelectComponent
            {...{
              assetConfiguration: { id: 'all-categories-colored' },
              handleFormValuesChange,
              placeholder: formatMessage({ id: CATEGORIES_PLACEHOLDER }),
              showLineSeparator: false,
              title: formatMessage({ id: CATEGORIES_TITLE }),
              value: formValues?.categoryCode?.code,
              listOptions: CATEGORY_OPTIONS,
            }}
          />
        ),
      },
    ],
  };
};

export { getDeniedFilterModalConfiguration };
