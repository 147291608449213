// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import { FilterChipsMapComponent } from './components/filter-chips-map.component';
// Constants
import {
  LINK_COMPONENT_PROPS,
  FILTER_CLIPS_DEFAULT_TEST_ID,
} from './constants/filter-chips.constants';
// Hooks
import { FilterChipsHook } from './hooks/filter-chips.hook';
// Styles
import { FilterDownloadComponentStyled } from './filter-chips.component.styled';
// Translations
import translations from './translations/filter-chips.translations';
// Types
import { FilterChipsComponentPropsType } from './types/filter-chips.component.type';

const FilterChipsComponent = ({
  formValues,
  formValuesParams,
  setCopyOfFormValues,
  setFormValues,
  setFormValuesParams,
  testId = FILTER_CLIPS_DEFAULT_TEST_ID.testId,
}: FilterChipsComponentPropsType): React.ReactElement | null => {
  const { handleClearFilterModal, handleClickChip, formatMessage } = FilterChipsHook({
    setCopyOfFormValues,
    setFormValuesParams,
    setFormValues,
  });

  return Object.keys(formValuesParams).length > 0 ? (
    <FilterDownloadComponentStyled data-testid={testId}>
      <FilterChipsMapComponent {...{ handleClickChip, formValues, formValuesParams }} />
      <LinkComponent onClick={handleClearFilterModal} {...LINK_COMPONENT_PROPS}>
        {formatMessage({ id: translations.CLEAR_FILTER })}
      </LinkComponent>
    </FilterDownloadComponentStyled>
  ) : null;
};

export { FilterChipsComponent };
