// Components
import FinancingLandingTableRowComponent from '../components/row/financing-landing-table-row.component';
// Resolvers
import { fetchCardsEppTransactionsResolver } from 'containers/financing/views/landing/resolvers/financing-landing.resolver';
// Translations
import { TITLE } from '../translations/financing-landing.translations';
// Enumerations
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const FINANCING_LANDING_TABLE_PROPS = {
  resolver: fetchCardsEppTransactionsResolver,
  rowComponent: FinancingLandingTableRowComponent,
  title: TITLE,
  id: { idName: 'eppId', groupId: 'eppCreationDate', idTable: 'financing' },
};

const CARD_STATUS_PROPS = {
  activateCardRedirection: {
    linkLabel: TITLE,
    route: RoutesEnumeration.FINANCING,
  },
  breadCrumbPath: RoutesEnumeration.FINANCING,
};

const FINANCING_LANDING_QUICK_ACTIONS = {
  id: QuickActionsSectionEnumeration.FINANCING,
};

export { CARD_STATUS_PROPS, FINANCING_LANDING_QUICK_ACTIONS, FINANCING_LANDING_TABLE_PROPS };
