const DEFAULT_TEST_ID = {
    testId: 'notifications-settings-list',
  },
  FETCH_ERROR_TEST_ID = {
    testing: {
      loaderTestId: 'notifications-settings-view-component-loader',
    },
  },
  ROW_TOGGLE_CONFIGURATION = {
    testId: 'notifications-settings-list-toggle',
  },
  SECTION_DIVIDER_TESTING = {
    testing: {
      titleId: 'notifications-settings-list-section-divider',
    },
  };

export { DEFAULT_TEST_ID, FETCH_ERROR_TEST_ID, ROW_TOGGLE_CONFIGURATION, SECTION_DIVIDER_TESTING };
