// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const SecurityCheckModalFirstStepStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': 'first-step',
  })
)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
