// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Event handlers
import { TitleSectionMobileHandlers } from '../handlers/title-section-mobile.handlers';
import { TitleSectionMobileTrackingHandlers } from '../handlers/title-section-mobile.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TitleSectionMobileHookType } from './types/title-section-mobile.hook.type';
// Utilities
import {
  getBreadCrumbCurrentItemTitle,
  getLastPathNameUrlItem,
} from '../../rwd/utils/title-section-rwd.utils';

const useTitleSectionMobileHook = (): TitleSectionMobileHookType => {
  const { isHybrid, isInCreditLimitIncrease, onNavigateHybridFlow, notchSize } =
    MobileConnectorContextConsumerHook();

  const [localNotchSize, setLocalNotchSize] = useState<number>(0);

  const { handleNavigatePopStateAssetClick, handleSetupObserveScrollOnPage } =
    TitleSectionMobileHandlers({
      ...AppTrackingHook(TitleSectionMobileTrackingHandlers),
      isHybrid,
      notchSize,
      onNavigateHybridFlow,
      setLocalNotchSize,
    });

  useEffect(() => {
    handleSetupObserveScrollOnPage();
  }, []);

  return {
    handleNavigatePopStateAssetClick,
    isHybrid,
    isInCreditLimitIncrease,
    notchSize: localNotchSize,
    pathName: getLastPathNameUrlItem(),
    title: getBreadCrumbCurrentItemTitle(),
  };
};

export { useTitleSectionMobileHook };
