// Vendors
import styled from 'styled-components';

const RecoveryKeyStep4SignatureKeyComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
RecoveryKeyStep4SignatureKeyComponentStyled.displayName =
  'RecoveryKeyStep4SignatureKeyComponentStyled';

export { RecoveryKeyStep4SignatureKeyComponentStyled };
