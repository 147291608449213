// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MESSAGE_PROPS } from './constants/transactions-report-landing-options-message.constants';
// Translations
import { PROVIDE_PROOF_MESSAGE } from './translations/transactions-report-landing-options-message.translations';
// Types
import { TransactionsReportLandingOptionsMessageComponentType } from './types/transactions-report-landing-options-message.component.type';

export const TransactionsReportLandingOptionsMessageComponent = ({
  showMessage,
}: TransactionsReportLandingOptionsMessageComponentType): React.ReactElement | null =>
  showMessage ? (
    <MessageComponent {...MESSAGE_PROPS}>
      <ParagraphComponent>
        <FormattedMessageComponent id={PROVIDE_PROOF_MESSAGE} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;
