const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbDetails.cbModalComponent.';

const BUTTON_TITLE = `${DOMAIN}cbButton.title`,
  TRANSACTION_AMOUNT = `${DOMAIN}cbParragraph.transactionAmount`,
  EXCHANGE_RATE = `${DOMAIN}cbParragraph.exchangeRate`,
  CONVERTED_AMOUNT = `${DOMAIN}cbParragraph.convertedAmount`,
  TOTAL_AMOUNT = `${DOMAIN}cbParragraph.totalAmount`,
  TITLE = `${DOMAIN}title`;

export {
  BUTTON_TITLE,
  CONVERTED_AMOUNT,
  EXCHANGE_RATE,
  TITLE,
  TOTAL_AMOUNT,
  TRANSACTION_AMOUNT,
};
