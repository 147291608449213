// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const FinancingLandingViewStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxl};

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingL};
  }

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;
FinancingLandingViewStyled.displayName = 'FinancingLandingViewStyled';

export { FinancingLandingViewStyled };
