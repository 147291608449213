// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTING_PAYMENT_MODAL_BUTTON_PROPS,
  CARD_SETTING_PAYMENT_MODAL_CHALLENGE_PROPS,
  CARD_SETTING_PAYMENT_MODAL_LINK_PROPS,
} from './constants/card-settings-payment-modal.constants';
// Hooks
import { useCardSettingsPaymentModalHook } from './hooks/card-settings-payment-modal.hook';
// Translations
import { BUTTON, LINK, TITLE } from './translations/card-settings-payment-modal.translations';
// Types
import { CardSettingsPaymentModalComponentType } from './types/card-settings-payment-modal.component.type';
// Utilities
import {
  getNonSecureStoresOption,
  nonSecureStoresButtonIsDisabled,
} from './utils/card-settings-payment-modal.utils';

const CardSettingsPaymentModalComponent = ({
  enabled: isAllowPaymentsNonSecStore,
  onClose,
  visible,
}: CardSettingsPaymentModalComponentType): React.ReactElement | null => {
  const {
      challenged,
      cardId,
      fetching,
      formatMessage,
      handleFailureChallengedNonSecureStoresEvent: onError,
      handleSuccessChallengedNonSecureStoresEvent: onSuccess,
      handleToggleNonSecureStoresButtonClick,
    } = useCardSettingsPaymentModalHook({ enabled: isAllowPaymentsNonSecStore, onClose }),
    option = formatMessage({ id: getNonSecureStoresOption(isAllowPaymentsNonSecStore) });

  return visible ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...CARD_SETTING_PAYMENT_MODAL_LINK_PROPS,
        disabled: fetching,
        children: <FormattedMessageComponent id={LINK} />,
        onClick: onClose,
      }}
      primaryButtonConfiguration={{
        ...CARD_SETTING_PAYMENT_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={BUTTON} values={{ option }} />,
        disabled: nonSecureStoresButtonIsDisabled({ challenged, fetching }),
        loading: fetching,
        onClick: handleToggleNonSecureStoresButtonClick,
      }}
      onHeaderClose={onClose}
      title={<FormattedMessageComponent id={TITLE} values={{ option }} />}
    >
      <ChallengeWrapperComponent
        {...{ ...CARD_SETTING_PAYMENT_MODAL_CHALLENGE_PROPS, onError, onSuccess }}
        baseServiceBuilder={{ pathParams: { cardId }, params: { isAllowPaymentsNonSecStore } }}
      />
    </ModalComponent>
  ) : null;
};

export { CardSettingsPaymentModalComponent };
