// Types
import { TransactionsReportLandingHandlersType } from './types/transactions-report-landing.handlers.type';
import { TransactionsReportLandingReturnHandlersType } from './types/transactions-report-landing-return.handlers.type';
import { OptionClickHandlerType } from './types/option-click-handler.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const nextButtonClickHandler = ({ selected, navigate }: NextButtonClickHandlerType): void => {
  if (selected) navigate(selected);
};

const optionClickHandler = ({
  eventLabel,
  handleOptionClickTracking,
  setSelected,
  value,
}: OptionClickHandlerType): void => {
  handleOptionClickTracking(eventLabel);

  setSelected(value);
};

export const TransactionsReportLandingHandlers = ({
  handleOptionClickTracking,
  navigate,
  selected,
  setSelected,
}: TransactionsReportLandingHandlersType): TransactionsReportLandingReturnHandlersType => ({
  handleNextButtonClick: () => nextButtonClickHandler({ selected, navigate }),
  handleOptionClick: ({ eventLabel, value }) =>
    optionClickHandler({ eventLabel, handleOptionClickTracking, setSelected, value }),
});
