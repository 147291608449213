// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CONSTANTS_REASONS_OPTIONS,
  CONSTANTS_REASON_NOT_FOUND,
} from '../constants/support-cancel-contract.constants';
// Enums
import { BlockTypeEnumeration } from 'enumerations/block-type.enumeration';
import { CardBlockCodeEnumeration } from 'enumerations/card-block-code.enumeration';
// Types
import { IntlShape } from 'react-intl';
import { type BlockDetailsType } from 'types/block-details.type';

export const getReasonOptions = (
  formatMessage: IntlShape['formatMessage']
): React.ComponentProps<typeof InputDropdownComponent>['options'] =>
  Object.values(CONSTANTS_REASONS_OPTIONS).map(reason => ({
    label: formatMessage({ id: reason.label }),
    value: reason.value,
  }));

export const getReasonToCancel = (reason: string): string => {
  const foundReason = CONSTANTS_REASONS_OPTIONS.find(option => option.value === reason);
  return foundReason ? foundReason.label : CONSTANTS_REASON_NOT_FOUND;
};

const isAlreadyCancelled = (blockDetails?: BlockDetailsType): boolean =>
  (blockDetails?.cardBlockCode === CardBlockCodeEnumeration.A ||
    blockDetails?.cardBlockCode === CardBlockCodeEnumeration.B) &&
  blockDetails?.blockType === BlockTypeEnumeration.PERMANENT;

export { isAlreadyCancelled };
