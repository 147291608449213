// Services
import { switchOnCardService } from '../services/card-status-button-switch-on-modal.service';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const switchOnCardResolver = async (cardId: string): Promise<[true] | [null, true]> => {
  const [, { status }] = await switchOnCardService(cardId);

  return requestRespondedNoContent(status) ? [true] : [null, true];
};

export { switchOnCardResolver };
