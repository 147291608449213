// Types
import {
  RecoveryKeyStep1PhoneBuilderHandlersType,
  RecoveryKeyStep1PhoneBuilderReturnHandlersType,
  RecoveryKeyStep1PhoneChangeBuilderHandlersType,
  RecoveryKeyStep1PhoneClickBuilderHandlersType,
  RecoveryKeyStep1PhoneValidateBuilderHandlersType,
} from './types/recovery-key-phone.handlers.type';
// Utilities
import { phoneIsInvalid } from 'containers/recovery-key/components/phone/handlers/utils/recovery-key-phone.handlers.utils';

const saveRecoveryStep1PrefixOptionClickEventHandler = ({
  handleSaveRecoveryStep1PrefixOptionClickTracking,
  newPrefix,
  setPrefix,
  setError,
}: RecoveryKeyStep1PhoneClickBuilderHandlersType): void => {
  handleSaveRecoveryStep1PrefixOptionClickTracking(newPrefix);
  setPrefix(newPrefix);
  setError(false);
};

const saveRecoveryStep1NumberInputEventHandler = ({
  newPhoneNumber,
  setError,
  setPhoneNumber,
}: RecoveryKeyStep1PhoneChangeBuilderHandlersType): void => {
  setPhoneNumber(newPhoneNumber);
  setError(false);
};

const validateRecoveryStep1NumberInputEvent = ({
  handleManagePhoneNumberRecoveryStep1InputErrorTracking,
  prefix,
  phoneNumber,
  setError,
}: RecoveryKeyStep1PhoneValidateBuilderHandlersType): void => {
  if (phoneIsInvalid({ prefix, phoneNumber })) {
    handleManagePhoneNumberRecoveryStep1InputErrorTracking();
    setError(true);
  }
};

const RecoveryKeyStep1PhoneHandlers = ({
  handleSaveRecoveryStep1PrefixOptionClickTracking,
  handleManagePhoneNumberRecoveryStep1InputErrorTracking,
  phoneNumber,
  prefix,
  setError,
  setPrefix,
  setPhoneNumber,
}: RecoveryKeyStep1PhoneBuilderHandlersType): RecoveryKeyStep1PhoneBuilderReturnHandlersType => ({
  handleSaveRecoveryStep1PrefixOptionClick: (newPrefix: string) =>
    saveRecoveryStep1PrefixOptionClickEventHandler({
      handleSaveRecoveryStep1PrefixOptionClickTracking,
      newPrefix,
      setPrefix,
      setError,
    }),
  handleSaveRecoveryStep1NumberInputEvent: (newPhoneNumber: string) =>
    saveRecoveryStep1NumberInputEventHandler({ newPhoneNumber, setError, setPhoneNumber }),
  handleValidateRecoveryStep1NumberInputEvent: () =>
    validateRecoveryStep1NumberInputEvent({
      handleManagePhoneNumberRecoveryStep1InputErrorTracking,
      prefix,
      phoneNumber,
      setError,
    }),
});

export default RecoveryKeyStep1PhoneHandlers;
