// Constants
import { linkConstants } from '@openbank/cb-ui-commons';

const PERSONAL_PHOTO_NAVIGATION_FOOTER_LINK_PROPS = {
  testId: 'personal-photo-navigation-footer-links',
  type: linkConstants.TYPES.ACTION_SECONDARY,
};

const PERSONAL_PHOTO_NAVIGATION_FOOTER_BUTTON_PROPS = {
  testId: 'personal-photo-navigation-footer-button',
};

export {
  PERSONAL_PHOTO_NAVIGATION_FOOTER_BUTTON_PROPS,
  PERSONAL_PHOTO_NAVIGATION_FOOTER_LINK_PROPS,
};
