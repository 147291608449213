// Vendors
import styled from 'styled-components';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';

const TransactionsLandingRowDetailsCreatePlanModalComponentStyled = styled(
  LayoutColumnFlexComponentStyled
)`
  align-items: center;
`;
TransactionsLandingRowDetailsCreatePlanModalComponentStyled.displayName =
  'TransactionsLandingRowDetailsCreatePlanModalComponentStyled';

export { TransactionsLandingRowDetailsCreatePlanModalComponentStyled };
