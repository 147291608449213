// Vendors
import React from 'react';
// Components
import {
  CheckboxComponent,
  ParagraphComponent,
  TitleComponent,
  ToggleComponent,
} from '@openbank/cb-ui-commons';
// Constants
import { TOGGLE_DESCRIPTION_PROPS, TOGGLE_PROPS } from './constants/consent-checker.constants';
// Styles
import {
  ConsentCheckerCheckboxesWarpperStyled,
  ConsentCheckerToggleWarpperStyled,
} from './consent-checker.component.styled';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ConsentCheckerComponentPropsType } from './types/consent-checker-props.component.type';

const ConsentCheckerComponent = ({
  consents,
  headerDescription,
  onChangeSubconsent,
  showConsents,
  toggleConfiguration,
}: ConsentCheckerComponentPropsType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <>
      <ConsentCheckerToggleWarpperStyled>
        <ToggleComponent
          {...TOGGLE_PROPS}
          defaultChecked={showConsents}
          onChange={toggleConfiguration.onChange}
        />
        <TitleComponent {...TOGGLE_DESCRIPTION_PROPS}>
          {toggleConfiguration.description}
        </TitleComponent>
      </ConsentCheckerToggleWarpperStyled>

      {showConsents ? (
        <>
          <ParagraphComponent>{headerDescription}</ParagraphComponent>
          {consents?.[0].title ? (
            <ConsentCheckerCheckboxesWarpperStyled>
              {consents.map(consent => (
                <CheckboxComponent
                  key={consent.title}
                  {...{
                    ...consent,
                    title: formatMessage({ id: consent.title }),
                    onChange: () => onChangeSubconsent(consent.id),
                  }}
                />
              ))}
            </ConsentCheckerCheckboxesWarpperStyled>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default ConsentCheckerComponent;
