// Constants
import { PUBLIC_SITE_CHAT } from 'constants/storage/storage.constants';
// Types
import {
  SupportLandingBuilderHandlersType,
  SupportLandingMethodsHandlersType,
} from './types/support-landing.handlers.type';
// Utils
import { publicSiteIsDemandingChat } from 'utils/public-interactions/public-interactions.utils';

const watchOpenChatFromPublicEventHandler = ({
  openChat,
}: SupportLandingBuilderHandlersType): void => {
  if (publicSiteIsDemandingChat()) {
    localStorage.removeItem(PUBLIC_SITE_CHAT);
    openChat();
  }
};

const SupportLandingHandlers = (
  props: SupportLandingBuilderHandlersType
): SupportLandingMethodsHandlersType => ({
  handleWatchOpenChatFromPublicEvent: () => watchOpenChatFromPublicEventHandler(props),
});

export { SupportLandingHandlers };
