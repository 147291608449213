const DOMAIN = 'cobranded.services.userSettings.privateArea.securityAndKeys.deviceInfo.';

export const BLOCK_DEVICE = `${DOMAIN}blockDevice`;
export const UNBLOCK_DEVICE = `${DOMAIN}unblockDevice`;
export const UNROLL_TRUSTED_DEVICE = `${DOMAIN}unrollTrustedDevice`;
export const PLATFORM = `${DOMAIN}platform`;
export const BROWSER = `${DOMAIN}browser`;
export const BIOMETRIC_ACCESS = `${DOMAIN}biometricAccess`;
export const YES = `${DOMAIN}yes`;
export const NO = `${DOMAIN}no`;
export const ROOT_ACCESS = `${DOMAIN}rootAccess`;
export const ROOT_ACCESS_DESCRIPTION = `${DOMAIN}rootAccessDescription`;
export const SNACKBAR_MESSAGES = {
  ERROR: {
    UNROLL_TRUSTED: `${DOMAIN}snackbarMessages.error.unrollTrusted`,
    BLOCK: `${DOMAIN}snackbarMessages.error.block`,
    UNBLOCK: `${DOMAIN}snackbarMessages.error.unblock`
  },
  SUCCESS: {
    UNROLL_TRUSTED: `${DOMAIN}snackbarMessages.success.unrollTrusted`,
    BLOCK: `${DOMAIN}snackbarMessages.success.block`,
    UNBLOCK: `${DOMAIN}snackbarMessages.success.unblock`
  }
};