// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { RecoveryKeyStep1EmailTrackingHandlersType } from './types/recovery-key-email.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const recoveryKeyStep1EmailErrorInputEventTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'newModifyEmail',
    eventLabel: 'newModifyEmail',
    errorCategory: 'invalidEmail',
    errorDescription: 'invalidEmail',
  },
  type: TrackingEventsEnum.LINK,
});

const recoveryKeyStep1EmailInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'emailInput',
  },
  type: TrackingEventsEnum.LINK,
});

const RecoveryKeyStep1EmailTrackingHandlers = (
  track: TrackBuilderType
): RecoveryKeyStep1EmailTrackingHandlersType => ({
  handleRecoveryKeyStep1EmailInputClickTracking: () =>
    track(recoveryKeyStep1EmailInputClickTrackingHandler()),
  handleRecoveryKeyStep1EmailErrorInputEventTracking: () =>
    track(recoveryKeyStep1EmailErrorInputEventTrackingHandler()),
});

export default RecoveryKeyStep1EmailTrackingHandlers;
