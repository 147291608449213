// Services
import { changeUserAliasService } from '../services/profile-personal-name-alias-input.services';
// Types
import { ProfilePersonalNameAliasInputApiBuilderType } from '../types/profile-personal-name-alias-input-api-builder.type';

const changeUserAliasResolver = async (
  props: ProfilePersonalNameAliasInputApiBuilderType
): Promise<[string] | [null, true]> => {
  const [response, { status }] = await changeUserAliasService(props);

  return response?.alias ? [response.alias] : [null, true];
};

export { changeUserAliasResolver };
