// Vendors
import React, { PropsWithChildren } from 'react';
// Hooks
import useAvatarHook from 'components/avatar/hooks/avatar.hook';
import { AppContextConsumerHook } from 'contexts/app.context';
import useCbIntl from 'hooks/useCbIntl';
import { useTranslations } from './hooks/use-translations';
import AppTrackingHook from 'tracking/app.tracking.hook';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Handlers
import { ChatTrackingHandlers } from './handlers/chat.tracking.handlers';
// Resolvers
import * as chatApi from './resolvers/chat.resolvers';
// Styles
import { ChatContextStyled } from './chat.provider.styled';

const ChatProvider = ({ children }: PropsWithChildren): React.ReactElement => {
  const { $t } = useCbIntl();
  const { profile } = AppContextConsumerHook();
  const { firstName: nickname = '' } = profile?.userDetails ?? {};
  const { avatar = '' } = useAvatarHook();
  const translations = useTranslations({ nickname });
  const analytics = AppTrackingHook(ChatTrackingHandlers);
  const { isHybrid } = MobileConnectorContextConsumerHook();

  if (isHybrid) {
    return <>{children}</>;
  }

  return (
    <ChatContextStyled
      {...{
        analytics,
        chatApi,
        tFunction: (id: string, values: Record<string, any>) => $t({ id }, values),
        translations,
        user: {
          avatar,
          nickname,
        },
      }}
    >
      {children}
    </ChatContextStyled>
  );
};

export default ChatProvider;
