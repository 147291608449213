// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ErrorWrapperTrackingHandlersType } from './types/error-wrapper.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const reloadAndClearPageErrorButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'tryAgain',
  },
  type: TrackingEventsEnum.LINK,
});

const ErrorWrapperTrackingHandlers = (
  track: TrackBuilderType
): ErrorWrapperTrackingHandlersType => ({
  handleTryAgainButtonClickTracking: () =>
    track(reloadAndClearPageErrorButtonClickTracking()),
});

export default ErrorWrapperTrackingHandlers;
