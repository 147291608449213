// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
import { FileDataType } from 'types/file-data.type';
import { RequestedDocumentsResponse } from '../../types/requested-documents.type';
// Utilities
import { getFileId } from 'components/upload-document-group/components/upload-document/handlers/utils/upload-document.handlers.utils';

export const mapRequestedDocumentsToFileData = (
  response: RequestedDocumentsResponse['documents'] | null
): FileDataType[] =>
  response
    ? response.map(({ documentType }, index) => ({
        id: getFileId(index),
        documentType,
        file: null,
        state: DocumentsUploadStatesEnumeration.DEFAULT,
      }))
    : [];
