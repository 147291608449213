// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_01_PROPS,
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_02_PROPS,
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_03_PROPS,
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_PROPS,
} from './constants/card-settings-change-3d-sidebar.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_03,
  TITLE,
} from './translations/card-settings-change-3d-sidebar.translations';

const CardSettingsChange3DSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...CARD_SETTINGS_CHANGE_3D_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <LayoutColumnFlexComponentStyled>
      <ParagraphComponent {...CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_01_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_01} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_02_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_02} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_03_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_03} />
      </ParagraphComponent>
    </LayoutColumnFlexComponentStyled>
  </SidebarComponent>
);

export { CardSettingsChange3DSidebarComponent };
