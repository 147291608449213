// Constants
import {
  MAX_TEST_ID,
  MIN_TEST_ID,
} from 'containers/financing/views/credit-limit-decrease-cc/components/step1/constants/step-1-credit-limit-decrease-cc.constants';
// Translations
import {
  MAX_VALUE,
  MIN_VALUE,
} from 'containers/financing/views/credit-limit-decrease-cc/components/step1/translations/step-1-credit-limit-decrease-cc.translations';
// Types
import { GetCurrencyRangeInputConfigurationType } from './types/step-1-form.configuration.type';
import { GetCurrencyRangeInputConfigurationReturnType } from './types/step-1-form-return.configuration.type';

export const getCurrencyRangeInputConfiguration = ({
  formatMessage,
  formatCurrencySeted,
  min,
  max,
}: GetCurrencyRangeInputConfigurationType): GetCurrencyRangeInputConfigurationReturnType => ({
  minMessage: formatMessage(
    { id: MIN_VALUE },
    {
      minAmount: formatCurrencySeted(min),
    }
  ),
  maxMessage: formatMessage(
    { id: MAX_VALUE },
    {
      maxAmount: formatCurrencySeted(max),
    }
  ),
  minMessageId: MIN_TEST_ID,
  maxMessageId: MAX_TEST_ID,
});
