const LINK_COMPONENT_PROPS = {
  disabled: false,
  iconOnLeft: true,
  assetConfiguration: { id: 'trash' },
  testId: 'transactions-table-clear-filter',
}, 
  FILTER_CLIPS_DEFAULT_TEST_ID = {
    testId: 'filter-chips-test-id'
  };

export { LINK_COMPONENT_PROPS, FILTER_CLIPS_DEFAULT_TEST_ID };
