// Services
import { generateAccessPortabilityRequestOtpService } from '../services/request-access-step2.services';
// Types
import { ChallengeType } from 'types/challenge.type';
import { AccessPortabilityRequestStep2GenerateApiBuilderType } from '../types/access-portability-request-step2-generate-api-builder.type';
// Utils
import { isValidAccessPortabilityResponse } from './utils/access-portability-request-step2.resolver.utils';

const generateAccessPortabilityRequestOtpResolver = async (
  progressId: AccessPortabilityRequestStep2GenerateApiBuilderType
): Promise<[ChallengeType] | [null, true]> => {
  const [response] = await generateAccessPortabilityRequestOtpService(progressId);

  return isValidAccessPortabilityResponse(response) ? [response] : [null, true];
};

export { generateAccessPortabilityRequestOtpResolver };
