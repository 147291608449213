// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Types
import { GetDisplayValueType } from './types/financing-landing-row-details-amount-pending.component.utils.types';

export const getDisplayValue = ({
  pendingAmount,
  formatCurrency,
  status,
}: GetDisplayValueType): string => {
  const validStatus = [
    EppElementStatusEnumeration.CREATED,
    EppElementStatusEnumeration.REQUESTED,
    EppElementStatusEnumeration.PARTIAL_RETURNED,
  ];

  if (validStatus.includes(status as EppElementStatusEnumeration)) {
    return formatCurrency(pendingAmount);
  }

  return '-';
};
