// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent, TitleComponent } from '@openbank/cb-ui-commons';
import ChangeCreditLimitComponent from '../change-credit-limit/change-credit-limit.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import { CreditChangeInfoCard } from 'containers/financing/components/credit-change-info-card/credit-change-info-card.component';
// Configurations
import { getWizardStepFooterConfiguration } from './configurations/step-5-credit-limit-increase.configuration';
// Constants
import constants from './constants/step-5-credit-limit-increase.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
// Hooks
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import Step5CreditLimitIncreaseHook from './hooks/step-5-credit-limit-increase.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  Step5CreditLimitIncreaseFeedbackSectionStyled,
  Step5CreditLimitIncreaseStyled,
  Step5CreditLimitIncreaseTextsStyled,
} from './step-5-credit-limit-increase.component.styled';
// Translations
import { step5Translations as translations } from './translations/step-5-credit-limit-increase.translations';
// Utils
import {
  getAssetId,
  getFeedbackDescription,
  getFeedbackTitle,
  showPPIWarningMessage,
} from './utils/step-5-credit-limit-increase.component.utils';

const Step5CreditLimitIncreaseComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const {
    creditLimitIncreaseStatus,
    isApprovalOrPendingOrRiskReview,
    currentCreditLimit,
    currencyCode,
    newCreditLimit,
    maxAmount,
    requestedCreditLimit,
    ppiStatus,
    nextButtonDisabled,
    handleStep5GoNext,
    handleGoToFinancing,
    handleEvaluateNewOffer,
  } = Step5CreditLimitIncreaseHook();
  const {
    disabledButtonsCancelModal,
    isRejectModal,
    showModal,
    setIsRejectModal,
    setShowModal,
    cancelModalButtonClick,
    cancelModalLinkClick,
  } = ExitModalHook(handleEvaluateNewOffer);

  return (
    <Step5CreditLimitIncreaseStyled>
      <Step5CreditLimitIncreaseFeedbackSectionStyled>
        <AssetComponent {...constants.ASSET_PROPS} id={getAssetId(creditLimitIncreaseStatus)} />
        <Step5CreditLimitIncreaseTextsStyled>
          <TitleComponent>
            {formatMessage(
              { id: getFeedbackTitle(creditLimitIncreaseStatus) },
              { moneyRequested: requestedCreditLimit, moneyOffered: maxAmount }
            )}
          </TitleComponent>
          <ParagraphComponent>
            {formatMessage(
              { id: getFeedbackDescription(creditLimitIncreaseStatus) },
              { br: <br /> }
            )}
          </ParagraphComponent>
          {showPPIWarningMessage({ status: ppiStatus, creditLimitIncreaseStatus }) && (
            <ParagraphComponent>
              {formatMessage({ id: translations.APPROVAL_FEEDBACK_DESCRIPTION_ALTERNATE })}
            </ParagraphComponent>
          )}
        </Step5CreditLimitIncreaseTextsStyled>
      </Step5CreditLimitIncreaseFeedbackSectionStyled>

      {creditLimitIncreaseStatus ===
        CreditLimitIncreaseStepsEnumeration.EVALUATE_NEW_BETTER_OFFER && (
        <ChangeCreditLimitComponent />
      )}

      {isApprovalOrPendingOrRiskReview && (
        <>
          {creditLimitIncreaseStatus === CreditLimitIncreaseStepsEnumeration.APPROVAL && (
            <ParagraphComponent>
              {formatMessage({ id: translations.CHECK_INFOMATION })}
            </ParagraphComponent>
          )}
          <CreditChangeInfoCard
            {...{ currentCreditLimit, newCreditLimit, currency: currencyCode }}
          />
        </>
      )}

      <WizardStepFooterComponent
        {...getWizardStepFooterConfiguration({
          status: creditLimitIncreaseStatus,
          nextButtonDisabled,
          formatMessage,
          disabledButtonsCancelModal,
          isRejectModal,
          showModal,
          setIsRejectModal,
          setShowModal,
          cancelModalButtonClick,
          cancelModalLinkClick,
          handleStep5GoNext,
          handleGoToFinancing,
          handleEvaluateNewOffer,
        })}
      />
    </Step5CreditLimitIncreaseStyled>
  );
};

export default Step5CreditLimitIncreaseComponent;
