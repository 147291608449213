// Translations
const DOMAIN = 'cobranded.services.userSettings.privateArea.cbQuickActions.quickActions.';

const CARD_CONTROL = `${DOMAIN}cardControl`,
  FAST_CASH = `${DOMAIN}fastCash`,
  GET_CVV = `${DOMAIN}getCVV`,
  GET_PIN = `${DOMAIN}getPIN`,
  DEPOSIT_MONEY_EXTERNAL = `${DOMAIN}depositMoneyExternal`,
  MODIFY_PIN = `${DOMAIN}modifyPin`,
  PPI = `${DOMAIN}PPI`,
  UPDATE_PAYMENT_METHOD = `${DOMAIN}updatePaymentMethod`,
  STATEMENTS = `${DOMAIN}statements`,
  ALL_OPTIONS = `${DOMAIN}allOptions`;

export {
  CARD_CONTROL,
  FAST_CASH,
  GET_CVV,
  GET_PIN,
  DEPOSIT_MONEY_EXTERNAL,
  MODIFY_PIN,
  PPI,
  STATEMENTS,
  UPDATE_PAYMENT_METHOD,
  ALL_OPTIONS,
};
