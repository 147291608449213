// Vendors
import React from 'react';
// Components
import { BlockCardAddressComponent } from 'components/block-card/components/address/block-card-address.component';
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import CardDetailsWrapperComponent from 'components/card-details/card-details-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { MESSAGE_PROPS } from './constants/card-settings-new-card-delivery-address.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Context
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Styles
import { WizardStepContentStyled } from 'styles/app.styled';
// Translations
import { INFORMATIVE_MESSAGE } from './translations/card-settings-new-card-delivery-address.translations';
// Utils
import { isSameObject } from 'utils/app.utils';

export const CardSettingsNewCardDeliveryAddressComponent = (): React.ReactElement => {
  const { tempAddress } = BlockCardContextConsumerHook();
  return (
    <WizardStepContentStyled>
      <CardDetailsWrapperComponent />
      <MessageComponent {...MESSAGE_PROPS}>
        <ParagraphComponent>
          <FormattedMessageComponent id={INFORMATIVE_MESSAGE} />
        </ParagraphComponent>
      </MessageComponent>
      <BlockCardAddressComponent />
      <WizardStepFooterComponent
        targets={FIRST_STEP_TARGETS}
        shouldShowModal={!isSameObject(tempAddress, null)}
      />
    </WizardStepContentStyled>
  );
};
