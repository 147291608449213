// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ProfilePersonalNameAliasContextConsumerHook } from 'containers/personal/views/landing/components/name/components/alias/contexts/profile-personal-name-alias.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import ProfilePersonalNameAliasInputHandlers from '../handlers/profile-personal-name-alias-input.handlers';
import ProfilePersonalNameAliasInputTrackingHandlers from '../handlers/profile-personal-name-alias-input.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ProfilePersonalNameAliasInputHookType } from './types/profile-personal-name-alias-input.hook.type';

const ProfilePersonalNameAliasInputHook = (): ProfilePersonalNameAliasInputHookType => {
  const { profile } = AppContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook(),
    { setMe } = ManagerContextConsumerHook(),
    { setEditMode } = ProfilePersonalNameAliasContextConsumerHook(),
    { alias, customerId } = profile?.userDetails || {};

  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [newAlias, setNewAlias] = useState<string>(alias);

  const { formatMessage } = useCbIntl();

  const {
    handleChangeAliasInputClickTracking,
    handleErrorStateChangeAliasInputEventTracking,
    handleSubmitNewAliasInputEventTracking,
  } = AppTrackingHook(ProfilePersonalNameAliasInputTrackingHandlers);

  return {
    ...ProfilePersonalNameAliasInputHandlers({
      customerId,
      handleErrorStateChangeAliasInputEventTracking,
      handleSubmitNewAliasInputEventTracking,
      newAlias,
      setFetching,
      setEditMode,
      setError,
      setMe,
      setNewAlias,
      setToastConfiguration,
    }),
    alias,
    fetching,
    formatMessage,
    error,
    handleChangeAliasInputClickTracking,
    newAlias,
  };
};

export default ProfilePersonalNameAliasInputHook;
