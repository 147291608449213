// Constants
import { messageConstants } from '@openbank/cb-ui-commons';
// Resolvers
import { toggleNonSecureStoresResolver } from '../resolvers/card-settings-payment-modal.resolver';
// Types
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';
import {
  CardSettingsPaymentModalBuilderHandlersType,
  CardSettingsPaymentModalBuilderReturnHandlersType,
  CardSettingsPaymentModalSuccessBuilderHandlersType,
} from './types/card-settings-payment-modal.handlers.type';
// Translations
import * as translations from './translations/card-settings-payment-modal.handlers.translations';
// Utilities
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const failureChallengedNonSecureStoresEventHandler = ({
  onClose,
  setToastConfiguration,
}: Pick<
  CardSettingsPaymentModalBuilderHandlersType,
  'onClose' | 'setToastConfiguration'
>): void => {
  onClose();
  setToastConfiguration({
    description: translations.ERROR_MESSAGE,
    type: messageConstants.TYPES.ERROR,
  });
};

const successChallengedNonSecureStoresEventHandler = ({
  newProgressId,
  setChallenged,
  setProgressId,
}: CardSettingsPaymentModalSuccessBuilderHandlersType): void => {
  setProgressId(newProgressId);
  setChallenged(true);
};

const toggleNonSecureStoresButtonClickHandler = async ({
  cardId,
  enabled,
  handleToggleNonSecureStoresButtonClickTracking,
  progressId,
  onClose,
  setCard,
  setFetching,
  setToastConfiguration,
}: Omit<
  CardSettingsPaymentModalBuilderHandlersType,
  'setChallenged' | 'setProgressId'
>): Promise<void> => {
  handleToggleNonSecureStoresButtonClickTracking(enabled);
  setFetching(true);

  const [, error] = await toggleNonSecureStoresResolver({ cardId, enabled, progressId });

  onClose();
  setCard(true);
  setToastConfiguration(
    setupToastConfiguration({
      error,
      description: getToastMessage({ error, translations }),
    })
  );
  setFetching(false);
};

const CardSettingsPaymentModalHandlers = ({
  cardId,
  enabled,
  handleToggleNonSecureStoresButtonClickTracking,
  progressId,
  onClose,
  setCard,
  setChallenged,
  setFetching,
  setProgressId,
  setToastConfiguration,
}: CardSettingsPaymentModalBuilderHandlersType): CardSettingsPaymentModalBuilderReturnHandlersType => ({
  handleFailureChallengedNonSecureStoresEvent: () =>
    failureChallengedNonSecureStoresEventHandler({ onClose, setToastConfiguration }),
  handleSuccessChallengedNonSecureStoresEvent: ({
    progressId: newProgressId,
  }: ChallengeProgressIdType) =>
    successChallengedNonSecureStoresEventHandler({ newProgressId, setChallenged, setProgressId }),
  handleToggleNonSecureStoresButtonClick: () =>
    toggleNonSecureStoresButtonClickHandler({
      cardId,
      enabled,
      handleToggleNonSecureStoresButtonClickTracking,
      onClose,
      progressId,
      setCard,
      setFetching,
      setToastConfiguration,
    }),
});

export { CardSettingsPaymentModalHandlers };
