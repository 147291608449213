// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { WIZARD_STEP_FOOTER_PROPS } from 'containers/transactions/views/report/components/fraudulent-movement/constants/transactions-report-fraudulent-movement.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
import { useFraudulentMovementBlockCardActionsHook } from './hooks/transactions-report-fraudulent-movement-block-card-actions.hook';

const TransactionsReportFraudulentMovementBlockCardActionsComponent = (): React.ReactElement => {
  const { blockReason } = BlockCardContextConsumerHook();
  const { handleGoBack } = useFraudulentMovementBlockCardActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      hasDefaultLeftButton={WIZARD_STEP_FOOTER_PROPS.hasDefaultLeftButton}
      leftButtonConfiguration={{ onClick: handleGoBack }}
      rightButtonConfiguration={{
        disabled: !blockReason,
      }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};

export { TransactionsReportFraudulentMovementBlockCardActionsComponent };
