// Services
import { blockCardService } from 'services/block-card/block-card.services';
// Types
import { CardSettingsBlockCardResolversType } from './types/block-card.resolvers.type';
import { ChallengeType } from 'types/challenge.type';
import { ErrorBodyType } from 'types/error-body.type';
// Utilities
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';

const blockCardResolver = async (
  props: CardSettingsBlockCardResolversType
): Promise<[null] | [ChallengeType | ErrorBodyType | null, number]> => {
  const [response, { status }] = await blockCardService(props);

  return requestHasSuccessStatus(status) ? [null] : [response, status];
};

export { blockCardResolver };
