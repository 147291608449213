// Services
import { validateNewEmailOtpService } from '../services/personal-email-step2-challenge.service';
// Types
import { PersonalEmailStep2ChallengeValidateApiBuilderType } from '../types/personal-email-step2-challenge-validate-api-builder.type';
// Utils
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const validateNewEmailOtpResolver = async (
  props: PersonalEmailStep2ChallengeValidateApiBuilderType
): Promise<[string | any] | [null, true]> => {
  const [, { status }] = await validateNewEmailOtpService(props);

  return wasSuccessRequest(status) ? [status] : [null, true];
};

export { validateNewEmailOtpResolver };
