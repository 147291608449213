// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Enumerations
import { CardControlTabsIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-tabs-ids.enumeration';
// Types
import { SettingsCardControlsBuilderReturnTrackingHandlersType } from './types/settings-card-controls-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const changeCardControlTabClickTrackingHandler = (
  id: CardControlTabsIdsEnumeration
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: `changeCardControlTab:${id}`,
  },
  type: TrackingEventsEnum.LINK,
});

const SettingsCardControlsTrackingHandlers = (
  track: TrackBuilderType
): SettingsCardControlsBuilderReturnTrackingHandlersType => ({
  handleChangeCardControlTabClickTracking: (id: CardControlTabsIdsEnumeration) =>
    track(changeCardControlTabClickTrackingHandler(id)),
});

export default SettingsCardControlsTrackingHandlers;
