// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_STATEMENTS_TABLE_ROW_DETAILS_ITEMS,
  CARD_STATEMENTS_TABLE_ROW_DETAILS_LAYOUT_PROPS,
} from './constants/card-settings-statements-table-row-details.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { CardSettingsStatementsRowDetailsComponentStyled } from './card-settings-statements-table-row-details.component.styled';
// Types
import { CardSettingsStatementsRowDetailsComponentType } from './types/card-settings-statements-table-row-details.component.type';
import { CardSettingsStatementsTableRowDetailsItemType } from './types/card-settings-statements-table-row-details-item.type';
// Utilities
import {
  convertPositiveNumbersToNegative,
  showCardStatementDetailsResumeItem,
} from './utils/card-settings-statements-table-row-details.utils';

const CardSettingsStatementsTableRowDetailsComponent = ({
  element: { composeData },
  open,
}: CardSettingsStatementsRowDetailsComponentType): React.ReactElement | null =>
  open ? (
    <CardSettingsStatementsRowDetailsComponentStyled>
      <LayoutColumnFlexComponentStyled {...CARD_STATEMENTS_TABLE_ROW_DETAILS_LAYOUT_PROPS}>
        {CARD_STATEMENTS_TABLE_ROW_DETAILS_ITEMS.map(
          (
            { showAlways, testId, title, value }: CardSettingsStatementsTableRowDetailsItemType,
            key: number
          ) =>
            showCardStatementDetailsResumeItem({ composeData, showAlways, value }) ? (
              <ResumeItemComponent
                {...{ testId }}
                label={<FormattedMessageComponent id={title} />}
                key={key}
                value={
                  <FormattedNumber
                    {...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS}
                    currency={composeData?.currency}
                    value={convertPositiveNumbersToNegative(composeData[value])}
                  />
                }
              />
            ) : null
        )}
      </LayoutColumnFlexComponentStyled>
    </CardSettingsStatementsRowDetailsComponentStyled>
  ) : null;

export { CardSettingsStatementsTableRowDetailsComponent };
