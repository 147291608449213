// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsPaymentModalTrackingBuilderReturnHandlersType } from './types/card-settings-payment-modal.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const toggleNonSecureStoresButtonClickTrackingHandler = (
  enable: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: `${enable ? 'enable' : 'disable'}NonSecureStores`,
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsPaymentModalTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsPaymentModalTrackingBuilderReturnHandlersType => ({
  handleToggleNonSecureStoresButtonClickTracking: (enabled: boolean) =>
    track(toggleNonSecureStoresButtonClickTrackingHandler(enabled)),
});

export { CardSettingsPaymentModalTrackingHandlers };
