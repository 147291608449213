// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { ParagraphComponent, TagComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  AMORTIZATION_MODAL_COMPONENT_PROPS,
  AMORTIZATION_TABLE_STATUS_TAG_TYPES,
} from './constants/amortization-modal.component.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Styles
import {
  AmortizationModalTitleStyled,
  FirstSection,
  FormatDateStyled,
  InstallmentsDetailStyled,
  InstallmentsStyled,
} from './amortization-modal.component.style';
// Translations
import { DESCRIPTIONS, TABLE_TRANSLATIONS } from './translations/amortization-modal.translations';
// Types
import { AmortizationModalComponentType } from './types/amortization-modal.component.type';
// Utils
import { formatCurrency } from 'containers/financing/views/landing/components/row/components/details/utils/financing-landing-table-row-details.utils';
import { isEmphasizedTag, isValidateTag } from './utils/amortization-modal.component.utils';

const AmortizationModalComponent = ({
  installments,
}: AmortizationModalComponentType): React.ReactElement<AmortizationModalComponentType> => (
  <>
    {installments.map(installment => (
      <>
        <AmortizationModalTitleStyled>
          <FirstSection>
            <ParagraphComponent {...AMORTIZATION_MODAL_COMPONENT_PROPS}>
              <FormattedMessageComponent id={TABLE_TRANSLATIONS.INSTALMENT} />
              <FormattedMessageComponent id={`${installment.term}/${installments.length}`} />
            </ParagraphComponent>
            {isValidateTag(installment?.status) && (
              <TagComponent
                isEmphasizedTag={isEmphasizedTag(
                  AMORTIZATION_TABLE_STATUS_TAG_TYPES[installment.status]
                )}
                type={AMORTIZATION_TABLE_STATUS_TAG_TYPES[installment.status]}
              >
                <FormattedMessageComponent uppercase id={DESCRIPTIONS[installment.status]} />
              </TagComponent>
            )}
          </FirstSection>
          <FormatDateStyled>
            <FormattedDate
              {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
              value={installment.dueDate}
            />
          </FormatDateStyled>
        </AmortizationModalTitleStyled>
        <InstallmentsStyled>
          <InstallmentsDetailStyled>
            <FormattedMessageComponent id={TABLE_TRANSLATIONS.INTERESTS} />
            <ParagraphComponent>{formatCurrency(installment.interestAmount)}</ParagraphComponent>
          </InstallmentsDetailStyled>
          <InstallmentsDetailStyled>
            <FormattedMessageComponent id={TABLE_TRANSLATIONS.INSTALMENT_AMOUNT} />
            <ParagraphComponent>{formatCurrency(installment.capitalAmount)}</ParagraphComponent>
          </InstallmentsDetailStyled>
          <InstallmentsDetailStyled>
            <FormattedMessageComponent id={TABLE_TRANSLATIONS.TOTAL_PAID} />
            <FormattedMessageComponent id={formatCurrency(installment.dueAmount)} />
          </InstallmentsDetailStyled>
          <InstallmentsDetailStyled>
            <FormattedMessageComponent id={TABLE_TRANSLATIONS.PENDING_AMOUNT} />
            {formatCurrency(installment.outstandingCapital)}
          </InstallmentsDetailStyled>
        </InstallmentsStyled>
      </>
    ))}
  </>
);

export { AmortizationModalComponent };
