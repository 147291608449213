const CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_DISPLAY_PROPS = {
    testId: 'card-settings-check-3d-confirmation-description-display',
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_PROPS = {
    testId: 'card-settings-check-3d-confirmation-description-query',
  },
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_LINK_PROPS = {
    testId: 'card-settings-check-3d-confirmation-description-query-link',
  },
  SHOW_CODE_SECONDS = 10,
  STEP_TWO = 2;

export {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_DISPLAY_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_LINK_PROPS,
  SHOW_CODE_SECONDS,
  STEP_TWO,
};
