export const CONTEXT_DEFAULT_VALUE = {
  challenged: false,
  checkboxValue: false,
  error: false,
  fetching: false,
  ibanValue: '',
  openSepaModal: false,
  progressId: '',
  sepaMandate: null,
  setChallenged: (): null => null,
  setCheckboxValue: (): null => null,
  setError: (): null => null,
  setFetching: (): null => null,
  setIbanValue: (): null => null,
  setOpenSepaModal: (): null => null,
  setProgressId: (): null => null,
  setSepaMandate: (): null => null,
};
