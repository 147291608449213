// Services
import { simulateTransactionInstallmentsPlanService } from '../services/transactions-epp-step1-simulation-options.service';
// Types
import { EppSimulationType } from 'types/epps/simulation/epp-simulation.type';
import { TransactionsEppStep1SimulationApiBuilderType } from '../types/transactions-epp-step1-simulation-api-builder.type';

const simulateTransactionInstallmentsPlanResolver = async (
  params: TransactionsEppStep1SimulationApiBuilderType
): Promise<[EppSimulationType] | [null, boolean]> => {
  const [response] = await simulateTransactionInstallmentsPlanService(params);

  return response?.resource ? [response.resource] : [null, true];
};

export { simulateTransactionInstallmentsPlanResolver };
