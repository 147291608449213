// Types
import { ChallengeWrapperReturnHandlersType } from './types/challenge-wrapper-return.handlers.type';
import { ChallengeWrapperHandlersType } from './types/challenge-wrapper.handlers.type';

const retryButtonClickHandler = ({ onRetry, setChallengeKey }: ChallengeWrapperHandlersType) => {
  onRetry();
  setChallengeKey(new Date());
};

const openRequestSignatureModalHandler = ({ setOpenRequestSignatureModal }: ChallengeWrapperHandlersType) => {
  setOpenRequestSignatureModal(true);
};

const ChallengeWrapperHandlers = (
  props: ChallengeWrapperHandlersType
): ChallengeWrapperReturnHandlersType => ({
  handleRetryButtonClick: () => retryButtonClickHandler(props),
  handleOpenRequestSignatureModal : () => openRequestSignatureModalHandler(props),
});

export default ChallengeWrapperHandlers;
