export default {
  CONTEXT_DEFAULT_VALUE: {
    cardImage: undefined,
    challenge: null,
    challenged: false,
    error: false,
    setCardImage: (): null => null,
    setChallenge: (): null => null,
    setChallenged: (): null => null,
    setError: (): null => null,
  },
};
