// Vendors
import React from 'react';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
import { OrderModalMobileComponent } from './components/mobile/order-modal-mobile.component';
import { OrderModalDesktopComponent } from './components/desktop/order-modal-desktop.component';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Translations
import { MODAL_CLOSE_BUTTON, TITLE } from './transalations/order-modal.translations';
// Types
import { OrderModalOrchestratorComponentProps } from './types/order-modal-orquestrator.component.types';
import { OrderModalComponentProps } from './types/order-modal.component.types';

const OrderModalOrchestratorComponent = ({
  formatMessage,
  open,
  handleModal,
  ...rest
}: OrderModalOrchestratorComponentProps): React.ReactElement<OrderModalComponentProps> | null => {
  const { isMobile } = DeviceTypeHook();
  return open ? (
    <ModalComponent
      onHeaderClose={handleModal}
      primaryButtonConfiguration={{
        children: formatMessage({ id: MODAL_CLOSE_BUTTON }),
        onClick: handleModal,
      }}
      title={formatMessage({ id: TITLE })}
    >
      {isMobile ? (
        <OrderModalMobileComponent {...(rest as OrderModalComponentProps)} />
      ) : (
        <OrderModalDesktopComponent {...(rest as OrderModalComponentProps)} />
      )}
    </ModalComponent>
  ) : null;
};

export { OrderModalOrchestratorComponent };
