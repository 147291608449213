// Vendors
import React from 'react';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { RequestSignatureKeyAddressSelector } from '../address-selector/request-signature-key-address-selector.component';
// Constants
import constants from './constants/request-signature-key-step1.constants';
// Hooks
import { RequestSignatureKeyStep1Hook } from './hooks/request-signature-key-step1.hook';
// Styles
import { RequestSignatureKeyContainerStyled } from './request-signature-key-step1.component.styled';
// Translations
import {
  ADDRESS_BOX_TITLE,
  MESSAGE_01,
  MESSAGE_03,
  TRUSTED_DESCRIPTION,
  TRUSTED_TITLE,
} from './translations/request-signature-key-step1.translations';

const RequestSignatureKeyStep1Component = (): React.ReactElement => {
  const { hasTrustedDevice, handleClickNextButton } = RequestSignatureKeyStep1Hook();

  return (
    <RequestSignatureKeyContainerStyled>
      {hasTrustedDevice && (
        <MessageComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_PROPS}>
          <ParagraphComponent
            {...constants.REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_TITLE_PROPS}
          >
            <FormattedMessageComponent id={TRUSTED_TITLE} />
          </ParagraphComponent>
          <ParagraphComponent
            {...constants.REQUEST_SIGNATURE_KEY_STEP_1_TRUSTED_MESSAGE_DESCRIPTION_PROPS}
          >
            <FormattedMessageComponent id={TRUSTED_DESCRIPTION} />
          </ParagraphComponent>
        </MessageComponent>
      )}
      <ParagraphComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_01_PROPS}>
        <FormattedMessageComponent id={MESSAGE_01} />
      </ParagraphComponent>
      <RequestSignatureKeyAddressSelector />
      <ParagraphComponent {...constants.REQUEST_SIGNATURE_KEY_STEP_1_MESSAGE_03_PROPS}>
        <FormattedMessageComponent id={MESSAGE_03} />
      </ParagraphComponent>
      <WizardStepFooterComponent rightButtonConfiguration={{ onClick: handleClickNextButton }} />
    </RequestSignatureKeyContainerStyled>
  );
};

export { RequestSignatureKeyStep1Component };
