// Vendors
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Contexts
import { FinancingEarlyRepaymentContextConsumerHook } from 'containers/financing/views/early-repayment/contexts/financing-early-repayment.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { FinancingEarlyRepaymentFirstStepHookReturnType } from './types/financing-early-repayment-first-step-return.hook.type';

const FinancingEarlyRepaymentFirstStepHook = (): FinancingEarlyRepaymentFirstStepHookReturnType => {
  const { eppDetails } = FinancingEarlyRepaymentContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { cmsCardId: cardNumber = CharactersEnum.NO_VALUE } = currentCard || {};

  return { cardNumber, eppDetails };
};

export default FinancingEarlyRepaymentFirstStepHook;
