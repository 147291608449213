// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { AccessPortabilityCardRequestBuilderReturnTrackingHandlersType } from './types/access-portability-card-builder-return.tracking.handlers.type';

const rightsRequestClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'requestAccessRightsButton',
  },
  type: TrackingEventsEnum.LINK,
});

const AccessPortabilityCardTrackingHandlers = (
  track: (param: unknown) => void
): AccessPortabilityCardRequestBuilderReturnTrackingHandlersType => ({
  handleRightsRequestClickTracking: () => track(rightsRequestClickTrackingHandler()),
});

export default AccessPortabilityCardTrackingHandlers;
