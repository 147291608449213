// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import RequestSignatureKeyModalHandlers from '../handlers/request-signature-key-modal.handlers';
// Types
import { RequestSignatureKeyModalReturnHookType } from './types/request-signature-key-modal-return.hook.type';

const useRequestSignatureKeyModalHook = (): RequestSignatureKeyModalReturnHookType => {
  const { openRequestSignatureModal, setOpenRequestSignatureModal } = AppContextConsumerHook();

  return {
    ...RequestSignatureKeyModalHandlers({ navigate: useNavigate(), setOpenRequestSignatureModal }),
    openRequestSignatureModal,
  };
};

export default useRequestSignatureKeyModalHook;
