// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const SettingsCardControlLocationsMainComponentStyled = styled.div`
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-grow: 0;
  gap: ${({ theme }) => theme.spaces.spacingM};
  height: ${convertPxsToRems({ pixels: 73, base: 14 })};
  justify-content: center;
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
`;

export const SettingsCardControlLocationsMainTitleComponentStyled = styled.div`
  border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 0 0 ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;
