// Types
import { EntrypointAuthorizationFromMobileUtilsType } from './types/entrypoint-authorization-from-mobile.utils.type';

export const noNeedUserData = (control: Array<boolean>): boolean => control.some(item => item);

export const isAccessKeyCookieExpired = (accessKeyExpired?: string): boolean =>
  accessKeyExpired !== undefined && accessKeyExpired !== null && accessKeyExpired === 'true';

export const changeAccessKeyFromMobile = ({
  searchParams,
  isHybrid,
}: EntrypointAuthorizationFromMobileUtilsType): boolean =>
  searchParams.get('access-key-expired') === 'true' && isHybrid;
