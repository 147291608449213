// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { buttonConstants, messageConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_STATEMENTS_MESSAGE_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'card-settings-statements-message-button',
    type: buttonConstants.TYPES.SECONDARY,
  },
  CARD_SETTINGS_STATEMENTS_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'card-settings-statements-message-description',
  },
  CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_01_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_02_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxxxs,
  },
  CARD_SETTINGS_STATEMENTS_MESSAGE_PROPS = {
    type: messageConstants.TYPES.ERROR,
    testId: 'card-settings-statements-message',
  },
  CARD_SETTINGS_STATEMENTS_MESSAGE_TITLE_PROPS = {
    bold: true,
    testId: 'card-settings-statements-message-title',
  };

export {
  CARD_SETTINGS_STATEMENTS_MESSAGE_BUTTON_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_DESCRIPTION_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_01_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_LAYOUT_02_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_PROPS,
  CARD_SETTINGS_STATEMENTS_MESSAGE_TITLE_PROPS,
};
