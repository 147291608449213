// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/challenge-wrapper.configurations.constants';
// Translations
import challengeCommonTranslations from 'translations/challenge-common.translations';
import challengeEmailTranslations from 'translations/challenge-email.translations';
import challengeSignatureKeyTranslations from 'translations/challenge-signature-key.translations';
import {
  BUTTON,
  DESCRIPTION,
  HELPER,
  ERROR,
  PLACEHOLDER,
  REQUEST_LINK,
  TITLE,
} from 'translations/challenge-sms.translations';
// Types
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
import { GetChallengeConfigurationReturnType } from './types/get-challenge-configuration-return.type';
// Utils
import { isCC } from 'utils/app.utils';
import { formatTimerText } from 'utils/timer.utils';

export const getChallengeConfiguration = ({
  baseService,
  challengeKey,
  formatMessage,
  timer,
  timerLabelTranslationId,
  ...rest
}: GetChallengeConfigurationType): GetChallengeConfigurationReturnType => ({
  ...rest,
  isCC: isCC(),
  apiClient: apiUtilsCb,
  key: challengeKey,
  services: {
    baseService,
    regenerateService: constants.ENDPOINTS.regenerate,
    verifyService: constants.ENDPOINTS.verify,
  },
  translations: {
    EMAIL_OTP: {
      BUTTON: formatMessage({ id: challengeEmailTranslations.BUTTON }),
      DESCRIPTION: formatMessage({ id: challengeEmailTranslations.DESCRIPTION }),
      ERROR: {
        BUTTON: formatMessage({ id: challengeCommonTranslations.ERROR.BUTTON }),
        DESCRIPTION: formatMessage({ id: challengeCommonTranslations.ERROR.DESCRIPTION }),
      },
      HELPER: formatMessage({ id: challengeEmailTranslations.HELPER }),
      REQUEST_NEW_CODE: formatTimerText({ formatMessage, timerLabelTranslationId, timer }),
      INPUT_ERROR: formatMessage({ id: challengeEmailTranslations.ERROR }),
      PLACEHOLDER: formatMessage({ id: challengeEmailTranslations.PLACEHOLDER }),
      REQUEST_LINK: formatMessage({ id: challengeEmailTranslations.REQUEST_LINK }),
      TITLE: formatMessage({ id: challengeEmailTranslations.TITLE }),
    },
    OTP: {
      BUTTON: formatMessage({ id: BUTTON }),
      DESCRIPTION: formatMessage({ id: DESCRIPTION }),
      ERROR: {
        BUTTON: formatMessage({ id: challengeCommonTranslations.ERROR.BUTTON }),
        DESCRIPTION: formatMessage({ id: challengeCommonTranslations.ERROR.DESCRIPTION }),
      },
      HELPER: formatMessage({ id: HELPER }),
      REQUEST_NEW_CODE: formatTimerText({ formatMessage, timerLabelTranslationId, timer }),
      INPUT_ERROR: formatMessage({ id: ERROR }),
      PLACEHOLDER: formatMessage({ id: PLACEHOLDER }),
      REQUEST_LINK: formatMessage({ id: REQUEST_LINK }),
      TITLE: formatMessage({ id: TITLE }),
    },
    SIGNATURE_KEY: {
      BUTTON: formatMessage({ id: challengeSignatureKeyTranslations.BUTTON }),
      DESCRIPTION: formatMessage({ id: challengeSignatureKeyTranslations.DESCRIPTION }),
      ERROR: {
        DESCRIPTION: formatMessage({ id: challengeCommonTranslations.ERROR.DESCRIPTION }),
        BUTTON: formatMessage({ id: challengeCommonTranslations.ERROR.BUTTON }),
      },
      HELPER: formatMessage({ id: challengeSignatureKeyTranslations.HELPER }),
      INPUT_ERROR: formatMessage({ id: challengeSignatureKeyTranslations.ERROR }),
      PLACEHOLDER: formatMessage({ id: challengeSignatureKeyTranslations.PLACEHOLDER }),
      REQUEST_LINK: formatMessage({ id: challengeSignatureKeyTranslations.REQUEST_LINK }),
      TITLE: formatMessage({ id: challengeSignatureKeyTranslations.TITLE }),
    },
    ERROR: {
      BUTTON: formatMessage({ id: challengeSignatureKeyTranslations.TITLE }),
      DESCRIPTION: formatMessage({ id: challengeSignatureKeyTranslations.TITLE }),
      TITLE: formatMessage({ id: challengeSignatureKeyTranslations.TITLE }),
    },
  },
});
