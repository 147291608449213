// Vendors
import { useEffect, useState } from 'react';
// Context
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { TransactionsDetailsHandlers } from 'containers/transactions/handlers/details/transactions-details.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
import { TransactionsDetailsHookType } from './types/transactions-details.hook.type';
import { TransactionsDetailsHookReturnType } from './types/transactions-details-return.hook.type';
import { EppCardType } from 'types/epp-card.type';

const useTransactionsDetailsHook = ({
  id,
  open,
  resolverDetail,
}: TransactionsDetailsHookType): TransactionsDetailsHookReturnType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [details, setDetails] = useState<CardTransactionsDetailType | any>({});
  const [eppDetails, setEppDetails] = useState<EppCardType | null>(null);

  const { formatDate, formatMessage } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard;

  const { handleCloseTransactionsDetail, handleFetchCardTransactionsDetail } =
    TransactionsDetailsHandlers({
      cardId: cardId,
      resolver: resolverDetail,
      setDetails,
      setEppDetails,
      setError,
      setFetching,
      transactionId: id,
    });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      handleFetchCardTransactionsDetail();
    } else {
      handleCloseTransactionsDetail();
    }
  }, [open]);

  return {
    details,
    eppDetails,
    error,
    fetching,
    formatDate,
    formatMessage,
    handleFetchCardTransactionsDetail,
  };
};

export { useTransactionsDetailsHook };
