// Vendors
import React from 'react';
// Components
import { PersonalSidebarCCComponent } from 'containers/personal/components/sidebar-cc/personal-sidebar-cc.component';
import LabelsLinkCardComponent from 'components/labels-link-card/labels-link-card.component';
import PersonalLandingSidebarComponent from './components/sidebar/personal-landing-sidebar.component';
import ProfilePersonalDataComponent from './components/data/profile-personal-data.component';
import ProfilePersonalNameComponent from './components/name/profile-personal-name.component';
// Constants
import {
  PERSONAL_LANDING_DOCUMENTS_PROPS,
  PERSONAL_LANDING_CONSENTS_PROPS,
  PERSONAL_LANDING_ACCESS_PORTABILITY_PROPS,
} from './constants/personal-landing.constants';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { PersonalLandingViewStyled } from './personal-landing.view.styled';

const PersonalLandingView = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <PersonalLandingViewStyled>
        <ProfilePersonalNameComponent />
        <ProfilePersonalDataComponent />
        <LabelsLinkCardComponent {...PERSONAL_LANDING_DOCUMENTS_PROPS} />
        <LabelsLinkCardComponent {...PERSONAL_LANDING_CONSENTS_PROPS} />
        <LabelsLinkCardComponent {...PERSONAL_LANDING_ACCESS_PORTABILITY_PROPS} />
      </PersonalLandingViewStyled>
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <PersonalSidebarCCComponent />
      <PersonalLandingSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export default PersonalLandingView;
