// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CARD_SETTINGS_DIVIDER_TITLE_PROPS } from './constants/card-settings-divider-title.constants';
// Styles
import { CardSettingsDividerTitleComponentStyled } from './card-settings-divider-title.component.styled';
// Types
import { CardSettingsDividerTitleComponentType } from './types/card-settings-divider-title.component.type';

const CardSettingsDividerTitleComponent = ({
  title,
}: CardSettingsDividerTitleComponentType): React.ReactElement<CardSettingsDividerTitleComponentType> | null => (
  <CardSettingsDividerTitleComponentStyled {...CARD_SETTINGS_DIVIDER_TITLE_PROPS}>
    <FormattedMessageComponent id={title} />
  </CardSettingsDividerTitleComponentStyled>
);

export { CardSettingsDividerTitleComponent };
