// Vendors
import styled from 'styled-components';

const CardSettingsCheck3dConfirmationSecurityModuleComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;
CardSettingsCheck3dConfirmationSecurityModuleComponentStyled.displayName =
  'CardSettingsCheck3dConfirmationSecurityModuleComponentStyled';

export { CardSettingsCheck3dConfirmationSecurityModuleComponentStyled };
