// Vendors
import React from 'react';
// Components
import AccessPortabilitySidebarComponent from '../../components/sidebar/access-portability-sidebar.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import { getRequestAccessWizardConfiguration } from './configurations/access-portability-request.configuration';
// Contexts
import { AccessPortabilityContextConsumerHOC } from '../../contexts/access-portability.context';
// Hooks
import RequestAccessHook from './hooks/request-access.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { RequestAccessComponentStyled } from './request-access.component.styled';

const AccessPortabilityRequest = (): React.ReactElement => {
  const { email } = RequestAccessHook();

  return (
    <RequestAccessComponentStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <WizardComponent {...getRequestAccessWizardConfiguration({ email })} />
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <AccessPortabilitySidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </RequestAccessComponentStyled>
  );
};

export { AccessPortabilityRequest };
export default AccessPortabilityContextConsumerHOC(AccessPortabilityRequest);
