// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Event handlers
import SettingsCardControlLocationsSearchHandlers from '../handlers/settings-card-control-locations-search.handlers';
import SettingsCardControlLocationsSearchTrackingHandlers from '../handlers/settings-card-control-locations-search.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SettingsCardControlLocationsSearchHookType } from './types/settings-card-control-locations-search.hook.type';

const SettingsCardControlLocationsSearchHook = (): SettingsCardControlLocationsSearchHookType => {
  const { setContinents, setKeyword } = SettingsCardControlLocationsContextConsumerHook();

  const { formatMessage } = useCbIntl();

  return {
    ...AppTrackingHook(SettingsCardControlLocationsSearchTrackingHandlers),
    ...SettingsCardControlLocationsSearchHandlers({ setContinents, setKeyword }),
    formatMessage,
  };
};

export default SettingsCardControlLocationsSearchHook;
