// Vendors
import React from 'react';
// Constants
import { assetConstants } from '@openbank/cb-ui-commons';
// Configurations
import { getBenefitsLandingTravelPlusLinkContractConfiguration } from './card-settings-benefits-travel-plus-link.configuration';
import { getBenefitsLandingTravelPlusTagConfiguration } from './card-settings-benefits-travel-plus-tag.configuration';
import { getBenefitsLandingTravelPlusStatusConfiguration } from './card-settings-benefits-travel-plus-status.configuration';
import { getBenefitsLandingTravelPlusButtonConfiguration } from './card-settings-benefits-travel-plus-button.configuration';
// Translations
import {
  FEATURE_01_DESCRIPTION,
  FEATURE_02_DESCRIPTION,
  FEATURE_02_TITLE,
  FEATURE_03_DESCRIPTION,
  FEATURE_03_TITLE,
  FEATURE_04_DESCRIPTION,
  FEATURE_04_TITLE,
} from 'components/travel-plus/components/features/translations/travel-plus-features.translations';
import translations from 'containers/card-settings/views/landing/components/toggles/translations/card-settings-toggles.translations';
import { TRAVEL_PLUS_DESCRIPTION } from '../../translations/card-settings-benefits.translations';
// Types
import { CardSettingsBenefitsTravelPlusConfigurationPropsType } from './types/card-settings-benefits-travel-plus.configuration.type';
import { CardSettingsBenefitsTravelPlusConfigurationReturnType } from './types/card-settings-benefits-travel-plus-return.configuration.type';

const getCardSettingsBenefitsTravelPlusConfiguration = ({
  benefits,
  formatMessage,
  formatDate,
  handleToggleTravelPlusToggleChange,
  handleOpenTravelPlusModalLinkClick,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusConfigurationPropsType): CardSettingsBenefitsTravelPlusConfigurationReturnType => {
  if (!benefits) {
    return null;
  }

  return {
    id: 'TravelPlus',
    description: formatMessage({ id: TRAVEL_PLUS_DESCRIPTION }),
    image: {
      id: 'benefits-travel',
      type: assetConstants.TYPES.ILLUSTRATION,
    },
    items: [
      {
        id: 'benefits-travel-1',
        title: formatMessage({ id: FEATURE_03_TITLE }),
        description: formatMessage({ id: FEATURE_03_DESCRIPTION }),
        icon: '0percentage',
      },
      {
        id: 'benefits-travel-2',
        title: formatMessage({ id: FEATURE_02_TITLE }),
        description: formatMessage({ id: FEATURE_02_DESCRIPTION }),
        icon: 'bill',
      },
      {
        id: 'benefits-travel-3',
        title: formatMessage({ id: FEATURE_04_TITLE }),
        description: formatMessage({ id: FEATURE_04_DESCRIPTION }),
        icon: 'health',
      },
      {
        id: 'benefits-travel-4',
        title: formatMessage({ id: FEATURE_01_DESCRIPTION }),
        description: formatMessage({ id: FEATURE_01_DESCRIPTION }),
        icon: 'cart',
      },
    ],
    title: formatMessage({ id: translations.TRAVEL_PLUS }),
    buttonConfiguration: getBenefitsLandingTravelPlusButtonConfiguration({
      formatMessage,
      onClick: handleToggleTravelPlusToggleChange,
      travelPlusInfo,
    }),
    statusConfiguration: {
      linkConfiguration: getBenefitsLandingTravelPlusLinkContractConfiguration({
        formatMessage,
        travelPlusInfo,
        handleOpenTravelPlusModalLinkClick,
      }),
      statusInfo: getBenefitsLandingTravelPlusStatusConfiguration({
        travelPlusInfo,
        formatMessage,
        formatDate,
      }),
      tagConfiguration: getBenefitsLandingTravelPlusTagConfiguration({
        formatMessage,
        travelPlusInfo,
      }),
    },
  };
};

export { getCardSettingsBenefitsTravelPlusConfiguration };
