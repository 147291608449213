// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { Step1CardDetailsComponent } from './components/cardDetails/step-1-card-details.component';
import { Step1FormComponent } from './components/form/step-1-form.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { Step1CreditLimitDecreaseCCHook } from './hooks/step-1-credit-limit-decrease-cc.hook';
// Styles
import { Step1CreditLimitDecreaseCCStyled } from './step-1-credit-limit-decrease-cc.component.styled';
// Translations
import { SUBTITLE } from './translations/step-1-credit-limit-decrease-cc.translations';

const Step1CreditLimitDecreaseCCComponent = (): React.ReactElement => {
  const {
    amount,
    amountError,
    check1,
    check2,
    paymentMethodCode,
    fixedPaymentAmount,
    accountBalancePercentage,
    limit,
    mainCurrency,
    min,
    setAmount,
    setAmountError,
    setCheck1,
    setCheck2,
    formatMessage,
    formatNumber,
    formatCurrencySeted,
  } = Step1CreditLimitDecreaseCCHook();

  return (
    <Step1CreditLimitDecreaseCCStyled>
      <ParagraphComponent>{formatMessage({ id: SUBTITLE })}</ParagraphComponent>
      <Step1CardDetailsComponent
        {...{
          paymentMethodCode,
          fixedPaymentAmount,
          accountBalancePercentage,
          limit,
          mainCurrency,
          formatMessage,
          formatNumber,
          formatCurrencySeted,
        }}
      />
      <Step1FormComponent
        {...{
          amount,
          amountError,
          check1,
          check2,
          min,
          max: limit,
          setAmount,
          setAmountError,
          setCheck1,
          setCheck2,
          formatMessage,
          formatCurrencySeted,
        }}
      />
      <WizardStepFooterComponent
        rightButtonConfiguration={{
          disabled: !check1 || !check2 || !amount || Boolean(amountError),
        }}
      />
    </Step1CreditLimitDecreaseCCStyled>
  );
};

export { Step1CreditLimitDecreaseCCComponent };
