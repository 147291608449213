// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const LANDING_ROUTE_PROPS = {
    index: true,
  },
  NOT_RECOGNIZE_CHARGE_ROUTE_PROPS = {
    path: RoutesEnumeration.NOT_RECOGNIZE_CHARGE,
  },
  DIFFERENT_AMOUNT_ATM_ROUTE_PROPS = {
    path: RoutesEnumeration.DIFFERENT_AMOUNT_ATM,
  },
  DUPLICATED_CHARGE_ROUTE_PROPS = {
    path: RoutesEnumeration.DUPLICATED_CHARGE,
  },
  DEFECTIVE_PRODUCT_ROUTE_PROPS = {
    path: RoutesEnumeration.DEFECTIVE_PRODUCT,
  },
  NOT_RECEIVED_PRODUCT_ROUTE_PROPS = {
    path: RoutesEnumeration.NOT_RECEIVED_PRODUCT,
  },
  CHARGED_SERVICE_CANCELLED_ROUTE_PROPS = {
    path: RoutesEnumeration.CHARGED_SERVICE_CANCELLED,
  },
  SUBSCRIPTION_PASSED_ROUTE_PROPS = {
    path: RoutesEnumeration.SUBSCRIPTION_PASSED,
  },
  PAYMENT_OTHER_MEANS_ROUTE_PROPS = {
    path: RoutesEnumeration.PAYMENT_OTHER_MEANS,
  };

export {
  CHARGED_SERVICE_CANCELLED_ROUTE_PROPS,
  DEFECTIVE_PRODUCT_ROUTE_PROPS,
  DIFFERENT_AMOUNT_ATM_ROUTE_PROPS,
  DUPLICATED_CHARGE_ROUTE_PROPS,
  LANDING_ROUTE_PROPS,
  NOT_RECEIVED_PRODUCT_ROUTE_PROPS,
  NOT_RECOGNIZE_CHARGE_ROUTE_PROPS,
  PAYMENT_OTHER_MEANS_ROUTE_PROPS,
  SUBSCRIPTION_PASSED_ROUTE_PROPS,
};
