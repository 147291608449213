// Contexts
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import RequestAccessStep2ActionsHandlers from '../handlers/request-access-step2-actions.handlers';
import AccessPortabilityRequestStep2TrackingHandlers from '../handlers/request-access-step2-actions.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { AccessPortabilityRequestStep2ActionsHookType } from './types/access-portability-request-step2-actions.hook.type';

const AccessPortabilityRequestStep2ActionsHook =
  (): AccessPortabilityRequestStep2ActionsHookType => {
    const { challenged, error, fetching, progressId, setFetching } =
      AccessPortabilityContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...RequestAccessStep2ActionsHandlers({
        ...AppTrackingHook(AccessPortabilityRequestStep2TrackingHandlers),
        progressId,
        setFetching,
        setResult,
      }),
      challenged,
      error,
      fetching,
    };
  };

export default AccessPortabilityRequestStep2ActionsHook;
