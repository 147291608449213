// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import { signatureKeyChangeCheckpointService } from '../services/security-keys-signature-key-change-third-step-validation.services';
// Types
import { SignatureKeyChangeCheckpointResolverPropsType } from './types/signature-key-change-checkpoint-props-resolvers.type';

const signatureKeyChangeCheckpointResolver = async ({
  signatureKey,
  newSignatureKey,
  progressId,
}: SignatureKeyChangeCheckpointResolverPropsType): Promise<[any | null, boolean]> => {
  const [response, { status }] = await signatureKeyChangeCheckpointService({
    oldSignatureKey: signatureKey,
    newSignatureKey: newSignatureKey,
    progressId,
  });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [response, true];
};

export { signatureKeyChangeCheckpointResolver };
