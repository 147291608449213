// Handlers
import FinancingLandingRowDetailsEarlyRepaymentLinkHandlers from '../handlers/financing-landing-row-details-early-repayment-link.handlers';
// Hooks
import { useNavigate } from 'react-router-dom';
// Types
import { FinancingLandingRowDetailsEarlyRepaymentLinkHookType } from './types/financing-landing-row-details-early-repayment-link.hook.type';
import { FinancingLandingRowDetailsEarlyRepaymentLinkReturnHookType } from './types/financing-landing-row-details-early-repayment-link-return.hook.type';

const FinancingLandingRowDetailsEarlyRepaymentLinkHook = ({
  eppId,
}: FinancingLandingRowDetailsEarlyRepaymentLinkHookType): FinancingLandingRowDetailsEarlyRepaymentLinkReturnHookType => {
  const navigate = useNavigate();

  return {
    ...FinancingLandingRowDetailsEarlyRepaymentLinkHandlers({ eppId, navigate }),
  };
};

export default FinancingLandingRowDetailsEarlyRepaymentLinkHook;
