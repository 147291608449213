// Constants
import { OPEN_MODAL_PAYLOAD } from './constants/sign-out.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { SignOutHandlersReturnTrackingType } from './types/sign-out-handlers.return.tracking.type';

const openSignOutModalDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_MODAL_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const SignOutHandlersTracking = (
  track: (param: unknown) => void
): SignOutHandlersReturnTrackingType => ({
    onOpenSignOutModalDeviceTracking: () => track(openSignOutModalDeviceTrackingHandler())
});

export { SignOutHandlersTracking };