// Enumerations
import { Check3dMessageTypesEnumeration } from '../enumerations/check-3d-message-types.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.check3DSecure.cbCheck3DSecure.confirmation.cbMessage.';

const TITLES = {
    [Check3dMessageTypesEnumeration.WARNING]: `${DOMAIN}warning.title`,
    [Check3dMessageTypesEnumeration.REMEMBER]: `${DOMAIN}remember.title`,
  },
  DESCRIPTIONS = {
    [Check3dMessageTypesEnumeration.WARNING]: `${DOMAIN}warning.description`,
    [Check3dMessageTypesEnumeration.REMEMBER]: `${DOMAIN}remember.description`,
  };

export { DESCRIPTIONS, TITLES };
