// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_EPPS_SERVICE_CONFIGURATION } from './constants/financing-landing.service.constants';
// Types
import { EppCardType } from 'types/epp-card.type';
import { FormValuesParamsType } from 'components/filters/context/types/filters-context.type';

const fetchCardsEppTransactionsService = async (
  cardId: string,
  formValuesParams: FormValuesParamsType
): Promise<[EppCardType[] | Record<string, any>, Response]> => {
  const SERVICE_CONFIGURATION = { ...FETCH_EPPS_SERVICE_CONFIGURATION };
  SERVICE_CONFIGURATION.queryParams = {
    ...SERVICE_CONFIGURATION.queryParams,
    ...formValuesParams,
  };

  return apiUtilsCb.get({
    ...SERVICE_CONFIGURATION,
    pathParams: { cardId },
  });
};

export { fetchCardsEppTransactionsService };
