// Vendors
import { useEffect, useState } from 'react';
// Constants
import { SHOW_MODAL_DEFAULT_VALUE } from '../constants/wizard-step-footer.constants';
// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Event handlers
import WizardStepFooterHandlers from '../handlers/wizard-step-footer.handlers';
import WizardStepFooterTrackingHandlers from '../handlers/wizard-step-footer.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { WizardStepFooterHookCommonType } from './types/wizard-step-footer.hook.common.type';
import { WizardStepFooterHookCommonReturnType } from './types/wizard-step-footer-return.hook.common.type';

const WizardStepFooterCommonHook = ({
  setShowModal,
  showModal = SHOW_MODAL_DEFAULT_VALUE,
  targets,
}: WizardStepFooterHookCommonType): WizardStepFooterHookCommonReturnType => {
  const { formatMessage } = useCbIntl();
  const { setCurrentStep } = useWizardContextConsumerHook();

  const [showCancelModal, setShowCancelModal] = useState<boolean>(showModal);

  useEffect(() => setShowCancelModal(showModal), [showModal]);

  return {
    ...WizardStepFooterHandlers({
      ...AppTrackingHook(WizardStepFooterTrackingHandlers),
      setCurrentStep,
      setShowCancelModal: setShowModal || setShowCancelModal,
      targets,
    }),
    formatMessage,
    showCancelModal,
  };
};

export { WizardStepFooterCommonHook };
