// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_ASSET_PROPS = {
    id: 'Mobile_device_Alternative',
    sizes: {
      height: 128,
      width: 128,
    },
    type: assetConstants.TYPES.ILLUSTRATION,
    testId: 'card-settings-check-3d-wizard-step1-asset',
  },
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_DESCRIPTION_PROPS = {
    testId: 'card-settings-check-3d-wizard-step1-description',
  },
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_WIZARD_STEP_FOOTER_PROPS = {
    isFooterDisabled: false,
    hasDefaultLeftButton: false,
  };

export {
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_ASSET_PROPS,
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_3D_WIZARD_STEP_1_WIZARD_STEP_FOOTER_PROPS,
};
