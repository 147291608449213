// Vendors
import styled from 'styled-components';

const FormattedMessageComponentStyled = styled.span<{
  bold?: boolean;
  isNormalFont?: boolean;
  uppercase?: boolean;
}>`
  ${({ bold }) => (bold ? 'font-weight: bold;' : '')}
  ${({ isNormalFont, theme }) =>
    isNormalFont ? `font-family: ${theme.fontFamilies.fontFamilyB}` : ''};
  ${({ uppercase }) => (uppercase ? 'text-transform: uppercase;' : '')}
`;

export { FormattedMessageComponentStyled };
