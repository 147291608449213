// Contexts
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Handlers
import BlockCardReasonHandlers from '../handlers/block-card-reason.handlers';
import BlockCardReasonTrackingHandlers from '../handlers/block-card-reason.handlers.tracking';
// Types
import { BlockCardReasonReturnHookType } from './types/block-card-reason-return.hook.type';
import AppTrackingHook from 'tracking/app.tracking.hook';

const BlockCardReasonHook = (): BlockCardReasonReturnHookType => {
  const { blockReason, setBlockReason } = BlockCardContextConsumerHook();
  return {
    ...BlockCardReasonHandlers({
      ...AppTrackingHook(BlockCardReasonTrackingHandlers),
      setBlockReason,
    }),
    blockReason,
  };
};

export default BlockCardReasonHook;
