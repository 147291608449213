export const ON_RETRY_PAYLOAD = {
  action: 'click',
  format: 'button',
  component: 'emptyState',
  element: 'tryAgain',
  eventAction: 'clickButton',
  eventLabel: 'tryAgainButton'
};

export const ERROR_PAYLOAD = {
  action: 'view',
  format: 'screen',
  component: 'emptyState',
  element: 'full',
  errorCategory: 'informationNotLoaded',
  errorDescription:'No sessions loaded',
  eventAction: 'informationNotLoaded',
  eventLabel: 'view'
};