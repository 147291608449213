// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { AccessPortabilityStep2ActionsBuilderReturnTrackingHandlersType } from './types/request-access-step2-actions-builder-return.tracking.handlers.type';

const requestAccessPortabilityButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationRequestAccessRightsButton',
  },
  type: TrackingEventsEnum.LINK,
});

const AccessPortabilityStep2ActionsTrackingHandlers = (
  track: (param: unknown) => void
): AccessPortabilityStep2ActionsBuilderReturnTrackingHandlersType => ({
  handleRequestAccessButtonClickTracking: () =>
    track(requestAccessPortabilityButtonClickTrackingHandler()),
});

export default AccessPortabilityStep2ActionsTrackingHandlers;
