// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Enumerations
import { PayOthersPaymentMethodEnumeration } from 'containers/transactions/views/report/enumerations/pay-others-payment-method.enumeration';
// Translations
import { PAYMENT_METHOD_OPTIONS } from '../translations/transactions-report-payment-additional-info-input.translations';
// Types
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';

export const getPaymentMethodOptions = (
  formatMessage: UseCbIntlReturnType['formatMessage']
): React.ComponentProps<typeof InputDropdownComponent>['options'] =>
  Object.values(PayOthersPaymentMethodEnumeration).map(month => ({
    label: formatMessage({ id: PAYMENT_METHOD_OPTIONS[month] }),
    value: month,
  }));
