// Vendors
import styled, { css } from 'styled-components';
// Utils
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
} from '@openbank/cf-ui-framework';

const DashboardWrapperCardContainerStyled = styled.div.attrs(({ testId }: { testId: string }) => ({
  'data-testid': testId,
}))`
  padding: ${({ theme }) => theme.spaces.spacingS};
`;
DashboardWrapperCardContainerStyled.displayName = 'DashboardWrapperCardContainerStyled';

const DashboardContainerStyled = styled.main<{ $fetching: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding-bottom: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
  ${({ $fetching }) =>
    $fetching &&
    css`
      align-items: center;
      height: calc(100vh - 285px);
    `};
`;
DashboardContainerStyled.displayName = 'DashboardContainerStyled';

const DashboardContentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileAndTabletMediaQuery} {
    flex-direction: column;
  }
`;

const DashboardLeftColumnContainerStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  flex: 1;

  ${mobileAndTabletMediaQuery} {
    flex: 100%;
    width: 100%;
  }
`;
DashboardLeftColumnContainerStyled.displayName = 'DashboardLeftColumnContainerStyled';

const DashboardLeftColumnTopContainerStyled = styled.header`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;
DashboardLeftColumnTopContainerStyled.displayName = 'DashboardLeftColumnTopContainerStyled';

const DashboardRightColumnContainerStyled = styled(DashboardLeftColumnContainerStyled)`
  max-width: ${convertPxsToRems({ pixels: 330, base: 14 })};

  ${mobileAndTabletMediaQuery} {
    max-width: 100%;
    width: 100%;
  }
`;

const DashboardTableAreaContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXl};
  padding: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};
  }
`;
DashboardTableAreaContainerStyled.displayName = 'DashboardTableAreaContainerStyled';

export {
  DashboardWrapperCardContainerStyled,
  DashboardContainerStyled,
  DashboardContentStyled,
  DashboardLeftColumnContainerStyled,
  DashboardLeftColumnTopContainerStyled,
  DashboardRightColumnContainerStyled,
  DashboardTableAreaContainerStyled,
};
