// Vendors
import styled from 'styled-components';

const FilterModalChildrenComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
  padding-right: ${({ theme }) => theme.spaces.spacingXs};
`;
export { FilterModalChildrenComponentStyled };
