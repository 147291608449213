// Vendors
import * as React from 'react';
// Components
import { DetailConfirmationComponent } from 'components/detail-confirmation/detail-confirmation.component';
// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import { useFinancingInsurancesContractingLPIDetailConfirmation } from './hooks/financing-insurances-contracting-lpi-detail-confirmation.hook';
import useCbIntl from 'hooks/useCbIntl';
// Translations
import {
  LPI,
  PREMIUM_LABEL,
  PREMIUM_DETAIL,
  COVERAGE_LABEL,
  COVERAGE_DETAIL,
  PRODUCT_NAME_LABEL,
  POLICY_HOLDER_LABEL,
} from './translations/financing-insurances-contracting-lpi-detail-confirmation.translations';
// Utils
import { getCurrencyFractionDigits } from 'utils/number.utils';

const FinancingInsurancesContractingLPIDetailConfirmationComponent =
  (): React.ReactElement | null => {
    const { formatNumber, formatMessage } = useCbIntl();
    const { profile } = AppContextConsumerHook(),
      { userName } = profile?.userDetails || {};
    const { grossPremium, coverage } = useFinancingInsurancesContractingLPIDetailConfirmation();

    if (!grossPremium || !coverage) {
      return null;
    }

    return (
      <DetailConfirmationComponent
        details={[
          { label: PRODUCT_NAME_LABEL, value: formatMessage({ id: LPI }) },
          { label: POLICY_HOLDER_LABEL, value: userName },
          {
            label: PREMIUM_LABEL,
            value: formatMessage(
              { id: PREMIUM_DETAIL },
              {
                grossPremium: formatNumber(grossPremium, {
                  ...EURO_FORMAT_OPTIONS,
                  minimumFractionDigits: getCurrencyFractionDigits(grossPremium),
                }),
              }
            ),
          },
          {
            label: COVERAGE_LABEL,
            value: formatMessage(
              { id: COVERAGE_DETAIL },
              {
                coverage: formatNumber(coverage, {
                  ...EURO_FORMAT_OPTIONS,
                  minimumFractionDigits: getCurrencyFractionDigits(coverage),
                }),
              }
            ),
          },
        ]}
      />
    );
  };

export { FinancingInsurancesContractingLPIDetailConfirmationComponent };
