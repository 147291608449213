// Constants
import { OPEN_TOOLTIP_PAYLOAD } from './constants/platform.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { PlatformTrackingHandlersReturnType } from './types/platform-return.tracking.handlers.type';

const openTooltipTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_TOOLTIP_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const PlatformTrackingHandlers = (
  track: (param: unknown) => void
): PlatformTrackingHandlersReturnType => ({
  onOpenTooltipTracking: () => track(openTooltipTrackingHandler())
});

export { PlatformTrackingHandlers };