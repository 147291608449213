// Vendors
import React from 'react';
// Constants
import { IVR_COMPONENT_CONSTANTS_CC } from 'components/ivr-cc/constants/ivr-component-wapper-cc.constants';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import {
  IvrWrapperCcListItemStyled,
  IvrWrapperCcListStyled,
} from 'components/ivr-cc/ivr-component-wrapper-cc.component.styled';
// Types
import { IvrComponentWrapperCCPropsType } from 'components/ivr-cc/types/ivr-component-wrapper-cc.component.type';

const IvrCcConstraintsList = ({
  text,
}: Pick<IvrComponentWrapperCCPropsType, 'text'>): React.ReactElement => (
  <IvrWrapperCcListStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.constraintsId}>
    {text.firstConstraint && (
      <IvrWrapperCcListItemStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.firstConstraintsId}>
        <ParagraphComponent>
          <FormattedMessageComponent id={text.firstConstraint} />
        </ParagraphComponent>
      </IvrWrapperCcListItemStyled>
    )}
    {text.secondConstraint && (
      <IvrWrapperCcListItemStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.secondConstraintsId}>
        <ParagraphComponent>
          <FormattedMessageComponent id={text.secondConstraint} />
        </ParagraphComponent>
      </IvrWrapperCcListItemStyled>
    )}
    {text.thirdConstraint && (
      <IvrWrapperCcListItemStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.thirdConstraintsId}>
        <ParagraphComponent>
          <FormattedMessageComponent id={text.thirdConstraint} />
        </ParagraphComponent>
      </IvrWrapperCcListItemStyled>
    )}
    {text.fourthConstraint && (
      <IvrWrapperCcListItemStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.fourthConstraintsId}>
        <ParagraphComponent>
          <FormattedMessageComponent id={text.fourthConstraint} />
        </ParagraphComponent>
      </IvrWrapperCcListItemStyled>
    )}
  </IvrWrapperCcListStyled>
);

export default IvrCcConstraintsList;
