// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PERSONAL_PHOTO_SIDEBAR_PROPS,
  PERSONAL_PHOTO_SIDEBAR_DESCRIPTION_PROPS,
} from './constants/personal-photo-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import { DESCRIPTION, TITLE } from './translations/personal-photo-sidebar.translations';

const PersonalPhotoSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...PERSONAL_PHOTO_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <ParagraphComponent {...PERSONAL_PHOTO_SIDEBAR_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default PersonalPhotoSidebarComponent;
