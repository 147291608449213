// Vendors
import styled from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const PersonalNumberStep4PhonesWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM} 0;
`;

export const PersonalNumberStep4ParagraphComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${convertPxsToRems({ pixels: 3, base: 14 })} 0;
`;

export const PersonalNumberStep4ParagraphBoldStyled = styled(ParagraphComponent)`
  color: ${({ theme }) => theme.colors.backgroundTertiaryA};
`;
