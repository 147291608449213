// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { ProfilePersonalNameAvatarBuilderReturnHandlersType } from './types/profile-personal-name-avatar-builder-return.handlers.type';
import { ProfilePersonalNameAvatarBuilderHandlersType } from './types/profile-personal-name-avatar-builder.handlers.type';

const profilePersonalNameEditAvatarAssetClickHandler = ({
  handleProfilePersonalNameEditAvatarAssetClickTracking,
  navigate,
}: ProfilePersonalNameAvatarBuilderHandlersType): any => {
  handleProfilePersonalNameEditAvatarAssetClickTracking();
  navigate(RoutesEnumeration.EDIT_PHOTO);
};

const ProfilePersonalNameAvatarHandlers = (
  props: ProfilePersonalNameAvatarBuilderHandlersType
): ProfilePersonalNameAvatarBuilderReturnHandlersType => ({
  handleProfilePersonalNameEditAvatarAssetClick: () =>
    profilePersonalNameEditAvatarAssetClickHandler(props),
});

export default ProfilePersonalNameAvatarHandlers;
