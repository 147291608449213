// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { FormattedNumber } from 'react-intl';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import { TransactionsEppStep1SimulationSummaryItemComponent } from './components/item/transactions-epp-step1-simulation-summary-item.component';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import {
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_FINANCED_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_INTERESTS_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_MONTHLY_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_TOTAL_PROPS,
} from './constants/transactions-epp-step1-simulation-summary.constants';
// Hooks
import { useTransactionsEppStep1SimulationSummaryHook } from './hooks/transactions-epp-step1-simulation-summary.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { TransactionsEppStep1SimulationSummaryComponentStyled } from './transactions-epp-step1-simulation-summary.component.styled';
// Translations
import {
  FINANCED,
  VIEW_AMORTIZATION_TABLE,
} from './translations/transactions-epp-step1-simulation-summary.translations';
// Utilities
import { simulationPlanIsNotDone } from 'containers/transactions/views/epp/components/step1/utils/transactions-epp-step1.utils';

const TransactionsEppStep1SimulationSummaryComponent = (): React.ReactElement => {
  const {
    currencyCode: currency,
    eppPlanSimulation,
    handleToggleAmortizationTableModalLinkClick,
    loanAmount,
  } = useTransactionsEppStep1SimulationSummaryHook();

  return (
    <TransactionsEppStep1SimulationSummaryComponentStyled>
      <LayoutColumnFlexComponentStyled>
        <ResumeItemComponent
          {...TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_FINANCED_PROPS}
          label={<FormattedMessageComponent id={FINANCED} />}
          value={
            <FormattedNumber
              {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
              value={loanAmount}
            />
          }
        />
        <TransactionsEppStep1SimulationSummaryItemComponent
          {...TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_INTERESTS_PROPS}
        />
        <TransactionsEppStep1SimulationSummaryItemComponent
          {...TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_TOTAL_PROPS}
        />
        <TransactionsEppStep1SimulationSummaryItemComponent
          {...TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_MONTHLY_PROPS}
          linkConfiguration={{
            children: <FormattedMessageComponent id={VIEW_AMORTIZATION_TABLE} />,
            disabled: simulationPlanIsNotDone(eppPlanSimulation),
            onClick: handleToggleAmortizationTableModalLinkClick,
          }}
        />
      </LayoutColumnFlexComponentStyled>
    </TransactionsEppStep1SimulationSummaryComponentStyled>
  );
};

export { TransactionsEppStep1SimulationSummaryComponent };
