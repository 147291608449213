// Vendors
import { RefObject, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Events Handlers
import LogoutHandlers from 'handlers/logout.handlers';
import { NotificationsHandlers } from 'handlers/notifications.handlers';
import OrchestratorHeaderHandlers from '../handlers/orchestrator-header.handlers';
import OrchestratorHeaderTrackingHandlers from '../handlers/orchestrator-header.tracking.handlers';
import OrchestratorHeaderNotificationsTrackingHandlers from '../handlers/orchestrator-header-notifications.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { OrchestratorHeaderHookReturnType } from './types/orchestrator-header.hook.return.type';
// Utils
import {
  getUserAvatar,
  getNotificationsCount,
  getFiveFirstNotifications,
  getNotificationsModalOptions,
} from './utils/orchestrator-header.hook.utils';

const useOrchestratorHeaderHook = (): OrchestratorHeaderHookReturnType => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [headerRef] = useState<RefObject<HTMLElement>>({ current: null });
  const { formatDate, formatMessage } = useCbIntl(),
    navigate = useNavigate();

  const { profile } = AppContextConsumerHook(),
    { setFetching, notificationsData, lastSession, setNotificationsData } =
      ManagerContextConsumerHook(),
    { isHybrid } = MobileConnectorContextConsumerHook(),
    { genderTCode, targetTCode, firstName: name } = profile?.userDetails || {};

  const notificationsHandlers = NotificationsHandlers({
    ...AppTrackingHook(OrchestratorHeaderNotificationsTrackingHandlers),
    navigate,
    headerRef,
    setNotificationsData,
  });
  const notificationsCount = getNotificationsCount(notificationsData?.data || []);
  const notifications = getFiveFirstNotifications(notificationsData?.data || []);
  const notificationsModalOptions = getNotificationsModalOptions({
    ...notificationsHandlers,
    formatMessage,
    notifications,
    notificationsCount,
  });

  return {
    ...OrchestratorHeaderHandlers({
      ...LogoutHandlers(),
      ...AppTrackingHook(OrchestratorHeaderTrackingHandlers),
      setFetching,
      setShowModal,
      navigate,
    }),
    avatar: getUserAvatar({ genderTCode, targetTCode }),
    formatDate,
    formatMessage,
    name,
    navigate,
    showModal,
    isHybrid,
    notificationsModalOptions,
    headerRef,
    lastAccess: new Date(lastSession),
  };
};

export default useOrchestratorHeaderHook;
