// Constants
import {
  PDF,
  EXCEL,
  FILE_TYPE,
  INVALID_FILE_TYPE,
} from '../constants/transactions-table-header-link-bar-download-modal.constants';
// Types
import { FileTypeAndExtensionReturnType } from './types/transactions-table-header-link-bar-download-modal-return.utils.type';

const isFetchingPDF = (downloadDocumentType: string, fetching: boolean): boolean => {
  return downloadDocumentType === PDF && fetching;
};

const isFetchingXls = (downloadDocumentType: string, fetching: boolean): boolean => {
  return downloadDocumentType === EXCEL && fetching;
};
const getFileTypeAndExtension = (downloadDocumentType: string): FileTypeAndExtensionReturnType =>
  FILE_TYPE[downloadDocumentType] || INVALID_FILE_TYPE;

export { isFetchingPDF, isFetchingXls, getFileTypeAndExtension };
