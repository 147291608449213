// Constants
import { COPY_TOAST_CONFIGURATION } from './constants/copy.handlers.constants';
// Types
import { CopyBuilderHandlersType } from './types/copy/copy-builder.handlers.type';
import { CopyBuilderReturnHandlersType } from './types/copy/copy-builder-return.handlers.type';
import { CopyMethodBuilderHandlersType } from './types/copy/copy-method-builder.handlers.type';
import { CopyHandlerBuilderHandlersType } from './types/copy/copy-handler-builder.handlers.type';

const copyGenericEventHandler = ({
  label,
  setToastConfiguration,
  text,
}: CopyHandlerBuilderHandlersType): any => {
  window.navigator.clipboard.writeText(text);
  setToastConfiguration({ ...COPY_TOAST_CONFIGURATION, description: label });
};

const CopyHandlers = (props: CopyBuilderHandlersType): CopyBuilderReturnHandlersType => ({
  handleCopyGenericEvent: (methodProps: CopyMethodBuilderHandlersType) =>
    copyGenericEventHandler({ ...props, ...methodProps }),
});

export default CopyHandlers;
