// Vendors
import React from 'react';
// Components
import { CommercialCardComponent } from '@openbank/cb-ui-commons';
// Types
import { CardSettingsBenefitsCommercialCardComponentPropsType } from './types/card-settings-benefits-commercial-card.component.type';

const CardSettingsBenefitsCommercialCardComponent = ({
  commercialCardConfiguration,
}: CardSettingsBenefitsCommercialCardComponentPropsType): React.ReactElement => {
  return (
    <>
      {commercialCardConfiguration?.map(configuration => (
        <CommercialCardComponent key={configuration.id} {...configuration} />
      ))}
    </>
  );
};
export { CardSettingsBenefitsCommercialCardComponent };
