// Enums
import { RequestEmailOptionEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
import { isValidEmail } from 'utils/email.utils';
// Types
import { RequestPortabilityStep2ActionsUtilsType } from './types/request-portability-step2-actions.utils.type';

const requestPortabilityStep2NextButtonIsDisabled = ({
  emailCheckboxValue,
  emailValue,
}: RequestPortabilityStep2ActionsUtilsType): boolean =>
 emailCheckboxValue !== RequestEmailOptionEnumeration.YOUR_EMAIL && !isValidEmail(emailValue);

export { requestPortabilityStep2NextButtonIsDisabled };
