// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DocumentationDocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const DocumentsContentContractStyled = styled.div`
  align-items: flex-start;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  width: 100%;
`;
