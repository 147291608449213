// Vendors
import { useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
// Enumerations
import { StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Event handlers
import InternationalizationHandlers from '../handlers/internationalization.handlers';
// Types
import { InternationalizationReturnHookType } from './types/internationalization-return.hook.type';
// Utilities
import { convertLocaleToTrainCase } from './utils/internationalization.hook.utils';

const useInternationalizationCommonHook = (
  language: string
): InternationalizationReturnHookType => {
  const savedTranslations = JSON.parse(localStorage.getItem(StorageLocalEnum.TRANSLATIONS) || '{}');

  const { showBoundary: setError } = useErrorBoundary();

  const [fetching, setFetching] = useState<boolean>(true);

  const [locale, setLocale] = useState<string>('');
  const [translations, setTranslations] = useState<any>(savedTranslations);

  return {
    ...InternationalizationHandlers({
      language,
      setError,
      setLocale,
      setFetching,
      setTranslations,
    }),
    fetching,
    locale: convertLocaleToTrainCase(locale),
    translations,
  };
};

export default useInternationalizationCommonHook;
