// Vendors
import styled from 'styled-components';
// Utils
import {
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

export const NotificationsContainerStyled = styled.section.attrs(() => ({
  'data-testid': 'notifications-container',
}))`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingXxxxl};

  ${mobileAndTabletMediaQuery} {
    flex: 100%;
  }

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingL};
  }

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingXs};
  }
`;

export const NotificationsContentContainerStyled = styled.section.attrs(() => ({
  'data-testid': 'notifications-landing-content',
}))`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
`;