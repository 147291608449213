// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

const CARD_SETTINGS_TOGGLE_STATUS_PROPS = {
    id: 'turnOnOffCard',
    labelTestId: 'card-settings-toggle-card-status-label',
    testId: 'card-settings-toggle-card-status-tag',
  },
  CARD_SETTINGS_TOGGLE_TRAVEL_PROPS = {
    id: 'updateTravelPlusCredit',
    labelTestId: 'card-settings-toggle-card-travel-label',
    testId: 'card-settings-toggle-travel',
  },
  CARD_SETTINGS_TOGGLE_VERTICAL_DIVIDER_PROPS = {
    color: AmazonTheme.default.colors.backgroundTertiaryD,
  },
  INVALID_STATUS = [
    CardStatusTypesEnumeration.BLOCKED,
    CardStatusTypesEnumeration.CANCELLED,
    CardStatusTypesEnumeration.INACTIVE,
    CardStatusTypesEnumeration.TEMPORARILY_BLOCKED,
  ];

export {
  CARD_SETTINGS_TOGGLE_STATUS_PROPS,
  CARD_SETTINGS_TOGGLE_TRAVEL_PROPS,
  CARD_SETTINGS_TOGGLE_VERTICAL_DIVIDER_PROPS,
  INVALID_STATUS,
};
