// Types
import { PlatformConfigurationType } from '../types/platform.configuration.type';
import { PlatformGetConfigurationType } from '../types/platform.get-configuration.type';

export const getConfig = ({ configuration, isMobile }: PlatformGetConfigurationType): PlatformConfigurationType => {
  return !isMobile 
    ? configuration 
    : Object.keys(configuration).reduce((acc, key) => {
      acc.info = { ...acc.info, ...configuration[key] };

      return acc;
      },
    { info: {} });
};