// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const SepaModalComponentStyled = styled.div.attrs(() => ({
  'data-testid': 'sepa-modal-component',
}))`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const SepaModalRowStyled = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const SepaModalColumnStyled = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-basis: 100%;
  padding: 0 ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;
