// Constants
import { CARD_SETTINGS_SECTION_DIVIDER_PROPS } from 'containers/card-settings/views/landing/constants/card-settings.constants';
// Enumerations
import { QuickActionsActionsIdsEnumeration } from 'enumerations/quick-actions/quick-actions-actions-ids.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CHANGE_3D,
  CHANGE_3D_LINK,
  CHECK_3D,
  CHECK_3D_LINK,
  PURCHASE_IN_STORES,
  PURCHASE_LINK_CHANGE,
} from './translations/card-settings-payment.constants.translations';

const CARD_SETTINGS_PAYMENT_CHANGE_PROPS = {
    label: CHANGE_3D,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.CHANGE_3D_SECURE,
        label: CHANGE_3D_LINK,
        trackingLabel: 'change3DSecure',
        testId: 'card-settings-payment-change',
        url: RoutesEnumeration.CHANGE_3D_SECURE,
      },
    ],
    testing: {
      labelId: 'card-settings-payment-change-label',
      valueId: 'card-settings-payment-change-value',
    },
  },
  CARD_SETTINGS_PAYMENT_CHECK_PROPS = {
    label: CHECK_3D,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.CHECK_3D_SECURE,
        label: CHECK_3D_LINK,
        trackingLabel: 'check3DSecure',
        testId: 'card-settings-payment-check',
        url: RoutesEnumeration.CHECK_3D_SECURE,
      },
    ],
    testing: {
      labelId: 'card-settings-payment-check-label',
      valueId: 'card-settings-payment-check-value',
    },
  },
  CARD_SETTINGS_PAYMENT_DIVIDER_PROPS = {
    ...CARD_SETTINGS_SECTION_DIVIDER_PROPS,
    testing: {
      elementId: 'card-settings-payment-divider',
      titleId: 'card-settings-payment-divider-title-section',
    },
  },
  CARD_SETTINGS_PAYMENT_STORES_PROPS = {
    label: PURCHASE_IN_STORES,
    links: [
      {
        actionId: QuickActionsActionsIdsEnumeration.DEFERRED_PURCHASES_AND_PAYMENTS,
        label: PURCHASE_LINK_CHANGE,
        trackingLabel: 'changePurchaseInNotSecureStores',
        testId: 'card-settings-payment-stores',
        url: RoutesEnumeration.SECURE_ELECTRONIC_COMMERCE,
      },
    ],
    testing: {
      labelId: 'card-settings-payment-stores-label',
      valueId: 'card-settings-payment-stores-value',
    },
  };

export {
  CARD_SETTINGS_PAYMENT_CHANGE_PROPS,
  CARD_SETTINGS_PAYMENT_CHECK_PROPS,
  CARD_SETTINGS_PAYMENT_DIVIDER_PROPS,
  CARD_SETTINGS_PAYMENT_STORES_PROPS,
};
