// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TooltipContentComponent } from './components/tooltip-content-component';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import { TOOLTIP_ASSET } from './constants/card-settings-contract-current-billing-method-value.constants';
// Enumerations
import { BillingPaymentMethodEnumeration } from 'enumerations/billing-payment-method.enumeration';
// Hooks
import useCardSettingsContractCurrentBillingMethodValueHook from './hooks/card-settings-contract-current-billing-method-value.hook';
// Translations
import { PAYMENT_METHOD_VALUE } from './translations/card-settings-contract-current-billing-method-value.translations';
import { CardSettingsContractCurrentBillingMethodValueComponentStyled } from './card-settings-contract-current-billing-method-value.component.styled';

const CardSettingsContractCurrentBillingMethodValueComponent = (): React.ReactElement | null => {
  const {
    currencyCode: currency,
    fixedPaymentAmount,
    accountBalancePercentage,
    paymentMethodCode,
  } = useCardSettingsContractCurrentBillingMethodValueHook();

  return paymentMethodCode ? (
    <CardSettingsContractCurrentBillingMethodValueComponentStyled>
      <FormattedMessageComponent
        id={PAYMENT_METHOD_VALUE[paymentMethodCode]}
        values={{
          fixedPaymentAmount: (
            <FormattedNumber
              {...{
                ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
                currency,
                value: fixedPaymentAmount,
              }}
            />
          ),
          accountBalancePercentage,
        }}
      />
      <AssetComponent
        {...TOOLTIP_ASSET}
        tooltipConfiguration={{
          content: <TooltipContentComponent {...{ paymentMethodCode }} />,
        }}
      />
    </CardSettingsContractCurrentBillingMethodValueComponentStyled>
  ) : null;
};

export default CardSettingsContractCurrentBillingMethodValueComponent;
