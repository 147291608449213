// Types
import { FinancingFastCashAmountHandlersReturnType } from './types/amount-return.handlers.type';
import { FinancingFastCashAmountHandlersPropsType } from './types/amount.handlers.type';
import { OnChangeValueHandlerPropsType } from './types/onchange-value-handler-props.type';
// Utils
import { getInputStatus } from './utils/amount.handlers.utils';

const onChangeValueHandler = ({
  newValue,
  setAmountValue,
  setInputErrorMsg,
  minAmount,
  maxAmount,
}: OnChangeValueHandlerPropsType) => {
  const status = getInputStatus({ value: (newValue ?? '').toString(), minAmount, maxAmount });
  setAmountValue(newValue);
  setInputErrorMsg(status);
};

const FinancingFastCashAmountHandlers = ({
  minAmount,
  maxAmount,
  setAmountValue,
  setInputErrorMsg,
}: FinancingFastCashAmountHandlersPropsType): FinancingFastCashAmountHandlersReturnType => ({
  handleOnChangeValue: (newValue: number) =>
    onChangeValueHandler({
      newValue,
      setAmountValue,
      setInputErrorMsg,
      minAmount,
      maxAmount,
    }),
});

export default FinancingFastCashAmountHandlers;
