// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const PersonalPhotoViewStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

const PersonalPhotoTitleViewStyled = styled.header`
  width: 100%;

  ${mobileMediaQuery} {
    display: none;
  }
`;

const PersonalPhotoContentViewStyled = styled.div`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    align-items: center;
    flex-direction: column;
    text-align: center;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

const PersonalPhotoContentRightViewStyled = styled(PersonalPhotoViewStyled)`
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export {
  PersonalPhotoViewStyled,
  PersonalPhotoTitleViewStyled,
  PersonalPhotoContentViewStyled,
  PersonalPhotoContentRightViewStyled,
};
