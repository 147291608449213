// Types
import { AppNoUserDataHeaderHandlersReturnType } from './types/header.handlers.return.type';
import { AppNoUserDataHeaderHandlersType } from './types/header.handlers.type';

const handleMakeUserLogoutClickHandler = async (
  { handleMakeUserLogoutEvent }: AppNoUserDataHeaderHandlersType): Promise<void> => {
  await handleMakeUserLogoutEvent();

  window.location.reload();
};

const AppNoUserDataHeaderHandlers = (
  props: AppNoUserDataHeaderHandlersType
): AppNoUserDataHeaderHandlersReturnType => ({
  handleMakeUserLogoutClick: () => handleMakeUserLogoutClickHandler(props),
});

export { AppNoUserDataHeaderHandlers };
