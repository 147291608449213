// Vendors
import React from 'react';
import { IntlProvider } from 'react-intl';
import { FetchErrorComponent } from '@openbank/cb-ui-commons/';
// Hooks
import useInternationalizationHook from './hooks/internationalization.hook';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const InternationalizationProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { locale, fetching, translations: messages } = useInternationalizationHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent {...{ fetching }}>
        <IntlProvider {...{ locale, messages }}>{children}</IntlProvider>
      </FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export default InternationalizationProvider;
