// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputDropdownPhoneNumberComponent } from '@openbank/cb-ui-commons';
// Constants
import { MODIFY_PHONE_STEP1_REPEAT_PHONE_PROPS } from './constants/personal-number-step1-repeat-phone.constants';
// Custom Hooks
import usePersonalNumberStep1RepeatPhoneHook from './hooks/personal-number-step1-repeat-phone.hook';
// Utilities
import { getMappedPhonePrefixes } from 'utils/phone.utils';
// Translations
import {
  HELPER_ERROR_MESSAGE,
  LABEL,
  PLACEHOLDER,
} from './translations/personal-number-step1-repeat-phone.translations';

const PersonalNumberStep1RepeatPhoneComponent = (): React.ReactElement => {
  const {
    formatMessage,
    fetching: disabled,
    error,
    handleSavePersonalNumberStep1RepeatNumberPrefixOptionClick: onSelect,
    handleSavePersonalNumberStep1RepeatNumberInputEvent: onChange,
    handleValidatePersonalNumberStep1RepeatNumberInputEvent: onBlur,
    repeatPhoneNumber: number,
    prefixes,
    phoneIsValid,
    defaultPrefix,
  } = usePersonalNumberStep1RepeatPhoneHook();

  return (
    <InputDropdownPhoneNumberComponent
      {...{ ...MODIFY_PHONE_STEP1_REPEAT_PHONE_PROPS, number }}
      {...{ onBlur, onChange, onSelect }}
      dropdownValues={getMappedPhonePrefixes(prefixes)}
      errorMessage={error && <FormattedMessageComponent id={HELPER_ERROR_MESSAGE} />}
      inputPlaceholder={formatMessage({ id: PLACEHOLDER })}
      label={<FormattedMessageComponent id={LABEL} />}
      prefix={defaultPrefix}
      value={defaultPrefix}
      disabled={disabled || !phoneIsValid}
    />
  );
};

export default PersonalNumberStep1RepeatPhoneComponent;
