// Constants
import { buttonConstants, DividerTypesEnumeration, titleConstants } from '@openbank/cb-ui-commons';

export default {
  LOGIN_EMAIL_CHECKBOX_PROPS: {
    testing: {
      labelTestId: 'login-email-checkbox-label',
      helperTestId: 'login-email-checkbox-helper',
      elementTestId: 'login-email-checkbox',
    },
  },
  LOGIN_EMAIL_INPUT_PROPS: {
    isRequired: true,
    testing: { inputId: 'login-email-email' },
  },
  LOGIN_EMAIL_TITLE_PROPS: {
    testId: 'login-email-title',
    tag: titleConstants.TAGS.H3,
  },
  LOGIN_EMAIL_BUTTON_PROPS: {
    size: buttonConstants.SIZES.FULL,
    testId: 'login-email-links',
    type: buttonConstants.TYPES.PRIMARY,
  },
  LOGIN_EMAIL_DIVIDER_PROPS: {
    testId: 'login-email-divider',
    type: DividerTypesEnumeration.TERTIARY_D,
  },
  LOGIN_GAPS_INPUT_PROPS: {
    cellsAmount: 4,
    testId: 'login-gaps-dropdown',
  },
  LOGIN_GAPS_LINK_PROPS: {
    testId: 'login-gaps-links',
  },
};
