// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Context
import { BlockCardContextConsumerHOC } from 'components/block-card/contexts/block-card.context';
import { TransactionsReportFraudulentMovementConsumerHOC } from './contexts/transactions-report-fraudulent-movement.context';
// Hooks
import CardStatusHook from 'hooks/card-status.hook';
// Utils
import { getFilteredSteps } from './utils/transactions-report-fraudulent-movement.utils';

const TransactionsReportFraudulentMovementBaseComponent = (): React.ReactElement => {
  const { status } = CardStatusHook();

  return <TransactionsReportWizardComponent steps={getFilteredSteps(status)} />;
};

export const TransactionsReportFraudulentMovementComponent = BlockCardContextConsumerHOC(
  TransactionsReportFraudulentMovementConsumerHOC(TransactionsReportFraudulentMovementBaseComponent)
);
