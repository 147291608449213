// Vendors
import React from 'react';
// Components
import { FilterModalListSelectorContentComponent } from '../components/list-selector-content/filter-modal-list-selector-content.component';
// Types
import {
  FormatListOptionsPropsType,
  ListOptionsType,
} from 'components/filters/components/filter-modal/utils/types/filter-modal.utils.type';
import { FilterModalListSelectorContentComponentType } from '../components/list-selector-content/types/filter-modal-list-selector-content.component.type';
import {
  GetSelectedOptionValuePropsType,
  GetSelectedOptionValueReturnType,
} from './types/process-options-list-return.type';

const processHelperMessage = (
  helperMessage: string | FilterModalListSelectorContentComponentType
): string | React.ReactElement => {
  if (typeof helperMessage === 'string') {
    return helperMessage;
  }

  return <FilterModalListSelectorContentComponent {...helperMessage} />;
};

const formatListOptions = ({
  options,
  messageKey,
  formatMessage,
}: FormatListOptionsPropsType): ListOptionsType =>
  options.map(option => ({
    ...option,
    [messageKey]: formatMessage({ id: option[messageKey] }),
  }));

const getSelectedOptionValue = ({
  id,
  listOptions,
}: GetSelectedOptionValuePropsType): GetSelectedOptionValueReturnType => {
  const selectedOption = listOptions.find(option => option.id === id);

  if (!selectedOption) {
    return { label: '', value: '' };
  }

  return {
    value: id,
    label: selectedOption.helperMessage,
  };
};

export { formatListOptions, getSelectedOptionValue, processHelperMessage };
