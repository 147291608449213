// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalPhotoNameButtonUploadModalButtonsBuilderReturnTrackingHandlersType } from './types/personal-photo-name-button-upload-modal-buttons-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const rotatePhotoButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'rotateImage',
  },
  type: TrackingEventsEnum.LINK,
});

const uploadAnotherPhotoButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'uploadAnotherPhoto',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalPhotoNameButtonUploadModalButtonsTrackingHandlers = (
  track: TrackBuilderType
): PersonalPhotoNameButtonUploadModalButtonsBuilderReturnTrackingHandlersType => ({
  handleRotatePhotoButtonClickTracking: () => track(rotatePhotoButtonClickTrackingHandler()),
  handleUploadAnotherPhotoButtonClickTracking: () =>
    track(uploadAnotherPhotoButtonClickTrackingHandler()),
});

export default PersonalPhotoNameButtonUploadModalButtonsTrackingHandlers;
