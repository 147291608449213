// Vendors
import React from 'react';
// Components
import { FilterModalComponent } from './components/filter-modal/filter-modal.component';
// Providers
import { FiltersContextProvider } from './context/filters.context';
// Types
import { FiltersComponentPropsType } from './types/filters.component.type';

const FiltersComponent = ({
  children,
  filterId,
  onApplyFilter,
}: FiltersComponentPropsType): React.ReactElement => {
  return (
    <FiltersContextProvider>
      {children}
      <FilterModalComponent {...{ filterId, onApplyFilter }} />
    </FiltersContextProvider>
  );
};

export default FiltersComponent;
