// Vendors
import { assetConstants, linkConstants } from '@openbank/cb-ui-commons';

const CANCEL_CONTRACT_MODAL_PARAGRAPH_01_PROPS = {
  testId: 'cancel-modal-description-01',
};

const CANCEL_CONTRACT_MODAL_BUTTON_PROPS = {
  testId: 'cancel-modal-button',
};
const CANCEL_CONTRACT_MODAL_LINK_PROPS = {
  type: linkConstants.TYPES.ACTION_PRIMARY,
  testId: 'cancel-modal-links',
};
const CANCEL_CONTRACT_MODAL_DEFAULT_ASSET_PROPS = {
  id: 'cancel-contract-alterative',
  sizes: {
    height: 120,
    width: 120,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'cancel-modal-asset',
};

export {
  CANCEL_CONTRACT_MODAL_BUTTON_PROPS,
  CANCEL_CONTRACT_MODAL_DEFAULT_ASSET_PROPS,
  CANCEL_CONTRACT_MODAL_LINK_PROPS,
  CANCEL_CONTRACT_MODAL_PARAGRAPH_01_PROPS,
};
