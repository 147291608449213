const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.',
  DOMAIN_WIZARD = `${DOMAIN}wizard.`;

const FIRST_STEP_TITLE = `${DOMAIN_WIZARD}firstStepTitle`;

const SECOND_STEP_TITLE = `${DOMAIN_WIZARD}secondStepTitle`;

const THIRD_STEP_TITLE = `${DOMAIN_WIZARD}thirdStepTitle`;

const FOURTH_STEP_TITLE = `${DOMAIN_WIZARD}fourthStepTitle`;

const ERROR_DESCRIPTION = `${DOMAIN}errorDescription`;

const ERROR_TITLE = `${DOMAIN}errorTitle`;

const SUCCESS_DESCRIPTION = `${DOMAIN}successDescription`;

const SUCCESS_TITLE = `${DOMAIN}successTitle`;

const SUCCESS_LINK_LABEL = `${DOMAIN}successLinkLabel`;

const PAGE_TITLE = `${DOMAIN}pageTitle`;

const CANCEL_MODAL_TRANSLATIONS = {
  title: `${DOMAIN}cancelModalTitle`,
  description: `${DOMAIN}cancelModalDescription`,
  link: `${DOMAIN}cancelModalLink`,
  button: `${DOMAIN}cancelModalButton`,
};

export {
  CANCEL_MODAL_TRANSLATIONS,
  FIRST_STEP_TITLE,
  SECOND_STEP_TITLE,
  THIRD_STEP_TITLE,
  FOURTH_STEP_TITLE,
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
  SUCCESS_LINK_LABEL,
  PAGE_TITLE,
};
