// Constants
import { DEFAULT_OTP_RESPONSE } from './constants/otp.handlers.constants';
// Resolvers
import { getLastUserOtpMessagesResolver } from '../resolvers/otp.resolver';
// Types
import { OtpBuilderHandlersType, OtpBuilderReturnHandlersType } from './types/otp.handlers.type';

const fetchLastSmsEmailMessagesEventHandler = async ({
  setFetching,
  setError,
  setEmailMessage,
  setSmsMessage,
}: Omit<
  OtpBuilderHandlersType,
  'handleLoadLastUserMessagesButtonClickTracking'
>): Promise<void> => {
  setFetching(true);

  const [response, error] = await getLastUserOtpMessagesResolver();

  if (error) {
    setError(true);
  } else {
    const { emailMessage, smsMessage } = response || DEFAULT_OTP_RESPONSE;

    setEmailMessage(emailMessage);
    setSmsMessage(smsMessage);
  }

  setFetching(false);
};

const fetchLoadLastUserMessagesButtonClickEventHandler = ({
  handleLoadLastUserMessagesButtonClickTracking,
  ...rest
}: OtpBuilderHandlersType): void => {
  handleLoadLastUserMessagesButtonClickTracking();
  fetchLastSmsEmailMessagesEventHandler(rest);
};

const OtpHandlers = ({
  handleLoadLastUserMessagesButtonClickTracking,
  ...rest
}: OtpBuilderHandlersType): OtpBuilderReturnHandlersType => ({
  handleFetchLastSmsEmailMessagesEvent: () => fetchLastSmsEmailMessagesEventHandler(rest),
  handleLoadLastUserMessagesButtonClick: () =>
    fetchLoadLastUserMessagesButtonClickEventHandler({
      handleLoadLastUserMessagesButtonClickTracking,
      ...rest,
    }),
});

export default OtpHandlers;
