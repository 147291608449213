// Enumerations
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

export const DEFAULT_CARD_CONTENT_TESTING_PROPS = {
  descriptionId: 'access-portability-card-content-description',
  titleId: 'access-portability-card-content-title',
};
export const ACCESS_PORTABILITY_DEFAULT_ERROR_CONFIGURATION = {
  error: true,
  assetId: 'no-movements',
};

export const BUTTON_IDS = {
  [AccessPortabilityTypeEnumeration.ACCESS]: {
    testId: 'access-portability-information-error-button',
  },
  [AccessPortabilityTypeEnumeration.PORTABILITY]: {
    testId: 'access-portability-transferability-error-button',
  },
};
