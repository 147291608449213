// Types
import { TrackingEventsEnum, TrackingViewEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { AppBuilderReturnTrackingHandlersType } from './types/app-builder-return.tracking.handlers.type';

const enterSectionTrackingHandler = (view: string): TrackingViewEventReturnType => ({
  type: TrackingEventsEnum.VIEW,
  payload: {
    pageName: view,
    sectionFamily: 'cobranded',
    sectionCategory: 'services',
    sectionName: 'privateArea',
    interactionType: 'page',
    serviceType: 'userSettings',
  },
});

const AppTrackingHandlers = (track: (any) => void): AppBuilderReturnTrackingHandlersType => ({
  handleEnterSectionTracking: (view: string) => track(enterSectionTrackingHandler(view)),
});

export default AppTrackingHandlers;
