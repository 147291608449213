// Vendors
import { useEffect } from 'react';
// Contexts
import { useWizardFeedbackContextConsumerHook } from '../contexts/wizard-feedback.context';
// Event handlers
import { WizardFeedbackHandlers } from '../handlers/wizard-feedback.handlers';
// Types
import { WizardFeedbackContextType } from '../contexts/types/wizard-feedback.context.type';

const useWizardFeedbackHook = (): Pick<WizardFeedbackContextType, 'result'> => {
  const { result } = useWizardFeedbackContextConsumerHook();

  const { handleRestoreAllWindowScroll } = WizardFeedbackHandlers(result);

  useEffect(() => {
    handleRestoreAllWindowScroll();
  }, [result]);

  return { result };
};

export { useWizardFeedbackHook };
