// Types
import { PersonalPhotoNameButtonUploadModalButtonsBuilderHandlersType } from 'containers/personal/views/photo/components/name-button/components/upload-modal/components/buttons/handlers/types/personal-photo-name-button-upload-modal-buttons-builder.handlers.type';
import { PersonalPhotoNameButtonUploadModalButtonsBuilderReturnHandlersType } from 'containers/personal/views/photo/components/name-button/components/upload-modal/components/buttons/handlers/types/personal-photo-name-button-upload-modal-buttons-builder-return.handlers.type';

const rotatePhotoButtonClickHandler = ({
  handleRotatePhotoButtonClickTracking,
}: Pick<
  PersonalPhotoNameButtonUploadModalButtonsBuilderHandlersType,
  'handleRotatePhotoButtonClickTracking'
>): any => {
  handleRotatePhotoButtonClickTracking();
};

const uploadAnotherPhotoButtonClickHandler = ({
  handleUploadAnotherPhotoButtonClickTracking,
  onRetry,
}: Pick<
  PersonalPhotoNameButtonUploadModalButtonsBuilderHandlersType,
  'handleUploadAnotherPhotoButtonClickTracking' | 'onRetry'
>): any => {
  handleUploadAnotherPhotoButtonClickTracking();
  onRetry();
};

const PersonalPhotoNameButtonUploadModalButtonsHandlers = ({
  handleRotatePhotoButtonClickTracking,
  handleUploadAnotherPhotoButtonClickTracking,
  onRetry,
}: PersonalPhotoNameButtonUploadModalButtonsBuilderHandlersType): PersonalPhotoNameButtonUploadModalButtonsBuilderReturnHandlersType => ({
  handleRotatePhotoButtonClick: () =>
    rotatePhotoButtonClickHandler({
      handleRotatePhotoButtonClickTracking,
    }),
  handleUploadAnotherPhotoButtonClick: () =>
    uploadAnotherPhotoButtonClickHandler({
      handleUploadAnotherPhotoButtonClickTracking,
      onRetry,
    }),
});

export default PersonalPhotoNameButtonUploadModalButtonsHandlers;
