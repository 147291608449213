// Vendors
import React from 'react';
// Components
import DashboardSpentExpensesListItemComponent from './components/list-item/dashboard-spent-expenses-list-item.component';
// Constants
import constants from './constants/dashboard-expenses-list.component.constants';
// Styles
import { DashboardSpentExpensesListComponentStyled } from './dashboard-expenses-list.styled';
// Types
import { DashboardSpentExpensesListComponentType } from './types/expenses-list-item.component.type';
import { ExpensesListItemComponentType } from './components/list-item/types/dashboard-spent-expenses-list-item.component.type';

const DashboardSpentExpensesListComponent = ({
  expenses,
  testId = constants.EXPENSES_LIST_DEFAULT_TEST_ID,
}: DashboardSpentExpensesListComponentType): React.ReactElement | null => {
  if (expenses?.length === 0) {
    return null;
  }

  return (
    <DashboardSpentExpensesListComponentStyled {...{ testId }}>
      {expenses?.map((expense: ExpensesListItemComponentType, key: number) => {
        return (
          <DashboardSpentExpensesListItemComponent
            categoryCode={expense.categoryCode}
            amount={expense.amount}
            step={expense.step}
            key={key}
          />
        );
      })}
    </DashboardSpentExpensesListComponentStyled>
  );
};

export default DashboardSpentExpensesListComponent;
