// Translations
import { SssDocumentTypesEnum } from '@openbank/cf-ui-static-data';

const RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PROPS = {
    endpoint: 'cb-api-auth/password/recover/init',
  },
  RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PARAMS_PROPS = {
    documentType: SssDocumentTypesEnum.EMAIL,
  };

export {
  RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PROPS,
  RECOVERY_KEY_STEP1_ACTIONS_SERVICE_PARAMS_PROPS,
};
