// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import constants from './constants/credit-limit-increase.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CreditLimitIncreaseContextType } from './types/credit-limit-increase.context.type';
import { Step1Type } from './types/step-1-credit-limit-increase.context.type';
import { Step2Type } from './types/step-2-credit-limit-increase.context.type';
import { Step3Type } from './types/step-3-credit-limit-increase.context.type';
import { Step4Type } from './types/step-4-credit-limit-increase.context.type';
import { Step5Type } from './types/step-5-credit-limit-increase.context.type';
import { Step6Type } from './types/step-6-credit-limit-increase.context.type';
import { FinalFeedbackType } from './types/final-feedback.type';
import { WizardStatusType } from './types/wizard-status.type';

const CreditLimitIncreaseContext = createContext<CreditLimitIncreaseContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const CreditLimitIncreaseContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CreditLimitIncreaseContextType>> => {
  const [step1, setStep1] = useState<Step1Type>(constants.CONTEXT_DEFAULT_VALUE.step1);
  const [step2, setStep2] = useState<Step2Type>(constants.CONTEXT_DEFAULT_VALUE.step2);
  const [step3, setStep3] = useState<Step3Type>(constants.CONTEXT_DEFAULT_VALUE.step3);
  const [step4, setStep4] = useState<Step4Type>(constants.CONTEXT_DEFAULT_VALUE.step4);
  const [step5, setStep5] = useState<Step5Type>(constants.CONTEXT_DEFAULT_VALUE.step5);
  const [step6, setStep6] = useState<Step6Type>(constants.CONTEXT_DEFAULT_VALUE.step6);
  const [finalFeedback, setFinalFeedback] = useState<FinalFeedbackType>(
    constants.CONTEXT_DEFAULT_VALUE.finalFeedback
  );
  const [wizardStatus, setWizardStatus] = useState<WizardStatusType>(
    constants.CONTEXT_DEFAULT_VALUE.wizardStatus
  );
  const [showExpiredFiocModal, setShowExpiredFiocModal] = useState<boolean>(
    constants.CONTEXT_DEFAULT_VALUE.showExpiredFiocModal
  );

  const contextValue = useMemo(
    () => ({
      step1,
      setStep1,
      step2,
      setStep2,
      step3,
      setStep3,
      step4,
      setStep4,
      step5,
      setStep5,
      step6,
      setStep6,
      finalFeedback,
      setFinalFeedback,
      wizardStatus,
      setWizardStatus,
      showExpiredFiocModal,
      setShowExpiredFiocModal,
    }),
    [step1, step2, step3, step4, step5, step6, finalFeedback, wizardStatus, showExpiredFiocModal]
  );
  return (
    <CreditLimitIncreaseContext.Provider value={contextValue}>
      {children}
    </CreditLimitIncreaseContext.Provider>
  );
};

export const CreditLimitIncreaseContextConsumerHook = (): CreditLimitIncreaseContextType =>
  useContext(CreditLimitIncreaseContext);

/* eslint-disable */
export const CreditLimitIncreaseContextConsumerHOC =
  (Component: React.FunctionComponent<any>): React.FunctionComponent<any> =>
  (props: any): React.ReactElement<React.Provider<CreditLimitIncreaseContextType>> =>
    (
      <CreditLimitIncreaseContextProvider>
        <Component {...props} />
      </CreditLimitIncreaseContextProvider>
    );
