// Enumerations
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Types
import { FinancingPaymentMethodFirstStepActionsHandlersType } from './types/financing-payment-method-first-step-actions.handlers.type';
import { FinancingPaymentMethodFirstStepActionsHandlersReturnType } from './types/financing-payment-method-first-step-actions-return.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';
// Utils
import { getPaymentMethodParams } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

const nextButtonClickHandler = ({
  fee,
  paymentMethod,
  revolvingMethod,
  setCurrentStep,
  setError,
  setPaymentMethodParams,
}: NextButtonClickHandlerType) => {
  const paymentMethodParams = getPaymentMethodParams({ fee, paymentMethod, revolvingMethod });

  if (paymentMethodParams) {
    setPaymentMethodParams(paymentMethodParams);
    setCurrentStep(WizardStepsEnumeration.TWO);
  } else {
    setError(true);
  }

  window.scrollTo(0, 0);
};

const FinancingPaymentMethodFirstStepActionsHandlers = (
  props: FinancingPaymentMethodFirstStepActionsHandlersType
): FinancingPaymentMethodFirstStepActionsHandlersReturnType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});

export default FinancingPaymentMethodFirstStepActionsHandlers;
