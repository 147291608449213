// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import DashboardLastSessionDateComponent from './components/date/dashboard-last-session-date.component';
// Constants
import constants from './constants/dashboard-last-session.constants';
// Custom Hooks
import DashboardLastSessionHook from 'containers/dashboard/components/last-session/hooks/dashboard-last-session.hook';
// Styles
import { DashboardLastSessionComponentStyled } from './dashboard-last-session.component.styled';
// Translations
import translations from './translations/dashboard-last-session.translations';

const DashboardLastSessionComponent = (): React.ReactElement => {
  const { handleNavigateToAccessAreaLinkClick, timestamp } = DashboardLastSessionHook();

  return (
    <DashboardLastSessionComponentStyled>
      <LinkComponent
        {...constants.DASHBOARD_LAST_SESSION_LINK_PROPS}
        onClick={handleNavigateToAccessAreaLinkClick}
      >
        <FormattedMessageComponent id={translations.LAST_ACCESS} />
      </LinkComponent>
      <ParagraphComponent {...constants.DASHBOARD_LAST_SESSION_DESCRIPTION_PROPS}>
        <DashboardLastSessionDateComponent {...{ timestamp }} />
  
      </ParagraphComponent>
    </DashboardLastSessionComponentStyled>
  );
};

export default DashboardLastSessionComponent;
