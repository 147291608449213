// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { REPORT_PROBLEM_SERVICE_CONFIGURATION } from './constants/transactions-report.service.constants';
// Types
import {
  TransactionsReportResponseType,
  TransactionsReportPropsType,
} from '../types/transactions-report.type';
import { ApiUtilsReturnType } from 'utils/validate-type/types/validate-type.types';
import { ChallengeType } from 'types/challenge.type';

const reportProblemService = async ({
  cardId,
  transactionId,
  data,
  progressId,
}: TransactionsReportPropsType): Promise<ApiUtilsReturnType<TransactionsReportResponseType | ChallengeType>> =>
  apiUtilsCb.post({
    ...REPORT_PROBLEM_SERVICE_CONFIGURATION,
    pathParams: { cardId, transactionId },
    customHeaders: progressId ? { progressId } : {},
    params: data,
  });

export { reportProblemService };
