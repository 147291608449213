// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Handlers
import { FinalFeedbackHandlers } from 'containers/financing/views/credit-limit-increase/handlers/final-feedback.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { TaxSuccessHookType } from './types/tax-success.hook.type';

const TaxSuccessHook = (): TaxSuccessHookType => {
  const [taxId, setTaxId] = useState<number>();
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
  const [isTaxIdSent, setIsTaxIdSent] = useState<boolean>(false);
  const { formatMessage } = useCbIntl();
  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { finalFeedback, wizardStatus, setFinalFeedback } = creditLimitContext;
  const { setToastConfiguration } = ToastContextConsumerHook();
  const { handleSaveSuccessTaxId, handleValidateTaxId } = FinalFeedbackHandlers({
    finalFeedback,
    taxId,
    wizardStatus,
    setFinalFeedback,
    setIsSaveDisabled,
    setIsSaveLoading,
    setIsTaxIdSent,
    setToastConfiguration,
    formatMessage,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleValidateTaxId, [taxId]);

  return {
    taxId,
    isSaveLoading,
    isSaveDisabled,
    isTaxIdSent,
    setTaxId,
    handleSaveSuccessTaxId,
    formatMessage,
  };
};

export default TaxSuccessHook;
