// Types
import { CardSettingsBlockCardSecondStepReturnHandlersType } from './types/card-settings-block-card-second-step-return.handlers.type';
import { CardSettingsBlockCardSecondStepHandlersType } from './types/card-settings-block-card-second-step.handlers.type';
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType) => {
  if (!challenge) setError(true);
};

const challengeSuccessHandler = ({ challenge, setChallenge }: ChallengeSuccessHandlerType) => {
  setChallenge(challenge);
};

const toggleChallengeErrorHandler = ({
  setChallenge,
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => {
  setChallenge(undefined);
  setChallengeError(prev => !prev);
};

const CardSettingsBlockCardSecondStepHandlers = ({
  challenge,
  setChallenge,
  setChallengeError,
  setError,
}: CardSettingsBlockCardSecondStepHandlersType): CardSettingsBlockCardSecondStepReturnHandlersType => ({
  handleChallengeError: () => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleToggleChallengeError: () =>
    toggleChallengeErrorHandler({ setChallenge, setChallengeError }),
});

export default CardSettingsBlockCardSecondStepHandlers;
