// Constants
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Types
import { FinancingInsurancesContractingLPIStep2HandlersType } from './types/financing-insurances-contracting-lpi-step2.handlers.type';
import { FinancingInsurancesContractingLPIStep2ReturnHandlersType } from './types/financing-insurances-contracting-lpi-step2-return.handlers.type';
import { PlanErrorEnumeration } from '../enumerations/plan-error.enumeration';

const nextButtonClickHandler = ({
  insurancesInfo,
  plan,
  setCurrentStep,
  setError,
}: FinancingInsurancesContractingLPIStep2HandlersType) => {
  if (!plan) {
    setError(PlanErrorEnumeration.NOT_SELECTED);
    return;
  }

  const selectedPlan = insurancesInfo?.insurances?.LPI?.availablePlans?.find(
    ({ code }) => code === plan
  );

  if (
    selectedPlan?.grossPremium &&
    insurancesInfo?.insurances?.LPI?.availableAmount &&
    selectedPlan?.grossPremium > insurancesInfo?.insurances?.LPI?.availableAmount
  ) {
    setError(PlanErrorEnumeration.INSUFFICIENT_CREDIT);
    return;
  }

  setCurrentStep(SECOND_STEP_TARGETS.next);
};

export const FinancingInsurancesContractingLPIStep2Handlers = (
  props: FinancingInsurancesContractingLPIStep2HandlersType
): FinancingInsurancesContractingLPIStep2ReturnHandlersType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});
