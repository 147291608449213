// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/profile-personal-name-alias.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ProfilePersonalNameAliasContextType } from './types/profile-personal-name-alias.context.type';

const ProfilePersonalNameAliasContext = createContext<ProfilePersonalNameAliasContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const ProfilePersonalNameAliasContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ProfilePersonalNameAliasContextType>> => {
  const [editMode, setEditMode] = useState<boolean>(false);

  return (
    <ProfilePersonalNameAliasContext.Provider value={{ editMode, setEditMode }}>
      {children as React.ReactNode}
    </ProfilePersonalNameAliasContext.Provider>
  );
};

export const ProfilePersonalNameAliasContextConsumerHook =
  (): ProfilePersonalNameAliasContextType => useContext(ProfilePersonalNameAliasContext);

/* eslint-disable */
export const ProfilePersonalNameAliasContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ProfilePersonalNameAliasContextType>> =>
    (
      <ProfilePersonalNameAliasContextProvider>
        <Component {...props} />
      </ProfilePersonalNameAliasContextProvider>
    );
