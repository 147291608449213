// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Types
import { CardTransactionDetailReportType } from 'types/card-transtions-details-report.type';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const showReportProblem = (report: CardTransactionDetailReportType): boolean =>
  report.active && getFeatureFlagInstance().isEnabled(FeatureFlagsEnumeration.OPEN_DISPUTE);

export { showReportProblem };
