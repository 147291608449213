// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const TransactionsReportPaymentAdditionalInfoInputComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export const TransactionsReportPaymentAdditionalInfoInputDateStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 378, base: 14 })};

  label {
    white-space: nowrap;
  }

  ${mobileMediaQuery} {
    width: 100%;

    label {
      white-space: normal;
    }
  }
`;
