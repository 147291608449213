// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { LinkComponent, ParagraphComponent, LineSeparatorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import { VIEW_LINK_PROPS } from './constants/documents-table-row.component.constants';
// Styles
import {
  DocumentsTableRowComponentSeparatorStyled,
  DocumentsTableRowComponentStyled,
  DocumentsTableRowDateStyled,
  DocumentsTableRowDescriptionStyled,
} from './documents-table-row.component.styled';
// Translations
import { VIEW } from './translations/documents-table-row.translations';
// Types
import { type DocumentsTableRowComponentProps } from './types/documents-table-row.component.type';

export const DocumentsTableRowComponent = ({
  document,
  onClick,
}: DocumentsTableRowComponentProps): React.ReactElement => (
  <div>
    <DocumentsTableRowComponentStyled>
      <DocumentsTableRowDateStyled>
        <ParagraphComponent>
          <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM} value={document?.date} />
        </ParagraphComponent>
      </DocumentsTableRowDateStyled>
      <DocumentsTableRowDescriptionStyled>
        <ParagraphComponent>{document.fileName}</ParagraphComponent>
        <LinkComponent {...VIEW_LINK_PROPS} onClick={onClick}>
          <FormattedMessageComponent id={VIEW} />
        </LinkComponent>
      </DocumentsTableRowDescriptionStyled>
    </DocumentsTableRowComponentStyled>
    <DocumentsTableRowComponentSeparatorStyled>
      <LineSeparatorComponent />
    </DocumentsTableRowComponentSeparatorStyled>
  </div>
);
