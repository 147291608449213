// Vendors
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Handlers
import { CardSettingsContracHandlers } from '../handlers/card-settings-contract.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsContractHooksReturnType } from './types/card-settings-contract-hook.type';
import { PPIInfoResponseType } from '../resolver/types/card-settings-contract-resolver.type';

const useCardSettingsContractHook = (): CardSettingsContractHooksReturnType => {
  const { formatMessage } = useCbIntl();
  const navigate = useNavigate();
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);
  const [ppiInfo, setPPIInfo] = useState<PPIInfoResponseType>({});

  const { handleGetPPIInfo } = CardSettingsContracHandlers({
    setError,
    setFetching,
    setPPIInfo,
  });

  useEffect(() => {
    handleGetPPIInfo();
  }, []);

  return {
    error,
    fetching,
    formatMessage,
    navigate,
    ppiInfo,
  };
};

export { useCardSettingsContractHook };
