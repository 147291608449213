export default {
  ASSET_INFO_PROPS: {
    colors: { fill: 'linkPrimaryA' },
    id: 'info',
    sizes: {
      height: 16,
      width: 16,
    },
  },
  CONTENT_TEST_ID: 'employment-data-credit-limit-increase-',
} as const;
