// Services
import { getDocumentInformationService } from '../services/documentation-table.service';
// Types
import { type DocumentsTableApiReturnType } from '../types/documents-table-api-return.type';
import { type DocumentsTableResolverPropsType } from './types/documents-table.resolvers.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

export const getDocumentLinkResolver = async ({
  documentId,
}: DocumentsTableResolverPropsType): Promise<[DocumentsTableApiReturnType] | [null, true]> => {
  const [response, { status }] = await getDocumentInformationService({ documentId });

  return wasSuccessRequest(status) ? [response as DocumentsTableApiReturnType] : [null, true];
};
