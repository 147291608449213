// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputDropdownPhoneNumberComponent } from '@openbank/cb-ui-commons';
// Constants
import { RECOVERY_KEY_STEP1_PHONE_PROPS } from './constants/recovery-key-phone.constants';
// Custom Hooks
import { useRecoveryKeyPhone } from './hooks/recovery-key-phone.hook';
// Translations
import {
  ERROR,
  HELPER_MESSAGE,
  LABEL,
  PLACEHOLDER,
} from './translations/recovery-key-phone.translations';
// Utilities
import { getMappedPhonePrefixes } from 'utils/phone.utils';

const RecoveryKeyPhoneComponent = ({ label = LABEL }: { label?: string }): React.ReactElement => {
  const {
    fetching: disabled,
    formatMessage,
    error,
    handleSaveRecoveryStep1PrefixOptionClick: onSelect,
    handleSaveRecoveryStep1NumberInputEvent: onChange,
    handleValidateRecoveryStep1NumberInputEvent: onBlur,
    phoneNumber: number,
    prefix,
    prefixes,
  } = useRecoveryKeyPhone();

  return (
    <InputDropdownPhoneNumberComponent
      {...{ ...RECOVERY_KEY_STEP1_PHONE_PROPS, disabled, prefix, number }}
      {...{ onBlur, onChange, onSelect }}
      dropdownValues={getMappedPhonePrefixes(prefixes)}
      errorMessage={error && <FormattedMessageComponent id={ERROR} />}
      helperMessage={<FormattedMessageComponent id={HELPER_MESSAGE} />}
      inputPlaceholder={formatMessage({ id: PLACEHOLDER })}
      label={<FormattedMessageComponent id={label} />}
    />
  );
};

export { RecoveryKeyPhoneComponent };
