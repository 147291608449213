// Vendors
import styled from 'styled-components';
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const PersonalAccessPortabilityCardContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) =>
    `${theme.spaces.spacingL} ${`calc(${theme.spaces.spacingXxxxl} + ${theme.spaces.spacingS})`}`};
  margin: 0;

  ${tabletMediaQuery} {
    padding: ${({ theme }) =>
      `calc(${theme.spaces.spacingL} + ${theme.spaces.spacingXs}) ${theme.spaces.spacingL}`};
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const AccessPortabilityCardDescriptionsStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
