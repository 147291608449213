const PARAGRAPH_PROPS = {
  bold: true,
  size: 'M',
};

const CARD_DETAIL_PROPS = {
  testId: 'card-product-detail',
};

const ADDRESS_DETAIL_PROPS = {
  testId: 'address-product-detail',
};

const CARD_DETAIL_NUMBER = {
  testId: 'card-product-detail-number',
};

const CARD_DETAIL_STREET_DATA = {
  testId: 'card-product-detail-street-data',
};

export { ADDRESS_DETAIL_PROPS, CARD_DETAIL_NUMBER, CARD_DETAIL_PROPS, CARD_DETAIL_STREET_DATA, PARAGRAPH_PROPS };
