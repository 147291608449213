const CANCEL_LINK_PAYLOAD = {
  eventAction: 'insufficientLimit',
  eventLabel: 'modalNavigationCancelLink',
};

const CLOSE_BUTTON_PAYLOAD = {
  eventAction: 'insufficientLimit',
  eventLabel: 'close',
};

const CONTINUE_BUTTON_PAYLOAD = {
  eventAction: 'insufficientLimit',
  eventLabel: 'modalNavigationGoToFinancingButton',
};

const LOAD_PAYLOAD = {
  eventAction: 'insufficientLimit',
  eventLabel: 'open',
};

export { CANCEL_LINK_PAYLOAD, CLOSE_BUTTON_PAYLOAD, CONTINUE_BUTTON_PAYLOAD, LOAD_PAYLOAD };
