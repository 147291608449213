// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { UserContactDetailsTelephoneType } from 'types/user-contact-details-telephone.type';

const findPrimaryTelephone = ({ isPrimaryTelephone }: UserContactDetailsTelephoneType): boolean =>
  Boolean(isPrimaryTelephone);

const fundPrimaryConfigurableTelephone = ({
  canUpdatePhone,
  isPrimaryTelephone,
}: UserContactDetailsTelephoneType): boolean => Boolean(canUpdatePhone && isPrimaryTelephone);

const getPrimaryConfigurableTelephone = (telephones: UserContactDetailsTelephoneType[]): string => {
  const { fullTelephoneNumber: primaryConfigurablePhone } =
      telephones?.find(fundPrimaryConfigurableTelephone) || {},
    { fullTelephoneNumber: primaryFull } = telephones?.find(findPrimaryTelephone) || {};

  return primaryConfigurablePhone || primaryFull || CharactersEnum.NO_VALUE;
};

export { getPrimaryConfigurableTelephone };
