// Vendors
import React from 'react';
// Components
import IbanBillingSidebarComponent from '../../components/sidebar/iban-billing-sidebar.component';
import LabelsLinkCardComponent from 'components/labels-link-card/labels-link-card.component';
// Constants
import constants from './constants/iban-billing-landing.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import useIbanBillingHook from './hooks/iban-billing.hook';
// Styles
import { IbanBillingLandingViewStyled } from './iban-billing-landing.view.styled';
import {
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
// Translations
import translations from './translations/iban-billing-landing.translations';

const IbanBillingLandingView = (): React.ReactElement => {
  // const { formatDate, nextCycleDate } = useIbanBillingHook();

  return (
    <LayoutGridComponentStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <IbanBillingLandingViewStyled>
            <LabelsLinkCardComponent {...constants.IBAN_BILLING_LANDING_IBAN_CARD_PROPS} />
            {/*<LabelsLinkCardComponent*/}
            {/*  {...constants.IBAN_BILLING_LANDING_BILLING_CARD_PROPS}*/}
            {/*  changeConfiguration={{*/}
            {/*    date: formatDate(nextCycleDate, MiscellaneousDateConstants.FORMAT_DD_MM),*/}
            {/*    label: translations.IBAN_CARD_DATE,*/}
            {/*  }}*/}
            {/*/>*/}
          </IbanBillingLandingViewStyled>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <IbanBillingSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </LayoutGridComponentStyled>
  );
};

export default IbanBillingLandingView;
