// Handlers
import { ChallengeHandlers } from 'handlers/challenge/challenge.handlers';
// Types
import { CreditLimitDecreaseCCChallengeReturnHookType } from './types/credit-limit-decrease-cc-challenge-return.hook.type';
import { Step2CreditLimitDecreaseCCHookType } from '../../../hooks/types/step-2-credit-limit-decrease-cc.hook.type';

const CreditLimitDecreaseCCChallengeHook = ({
  setChallenge,
  setChallengeError,
}: Pick<
  Step2CreditLimitDecreaseCCHookType,
  'setChallenge' | 'setChallengeError'
>): CreditLimitDecreaseCCChallengeReturnHookType => ({
  ...ChallengeHandlers({
    setChallenge,
    setChallengeError,
  }),
});

export { CreditLimitDecreaseCCChallengeHook };
