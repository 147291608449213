// Vendors
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';
import useCbIntl from 'hooks/useCbIntl';

const useTableRowDescriptionHook = (): Pick<UseCbIntlReturnType, 'formatMessage'> => {
  const { formatMessage } = useCbIntl();

  return { formatMessage };
};

export default useTableRowDescriptionHook;
