// Types
import { GetSelectorBoxConfigurationReturnType } from './types/get-selector-box-configuration-return.type';
import { GetSelectorBoxConfigurationType } from './types/get-selector-box-configuration.type';
// Utils
import { isSelected } from './utils/financing-payment-method-first-step-payment-options.configurations.utils';

export const getSelectorBoxConfiguration = ({
  formatMessage,
  handleOnClick,
  name,
  selected,
  title,
}: GetSelectorBoxConfigurationType): GetSelectorBoxConfigurationReturnType => ({
  title: formatMessage({ id: title }),
  selected: isSelected({ name, selected }),
  onClick: () => handleOnClick(name),
});
