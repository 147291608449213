// class, because we can check the type with instanceof
class CorrectType {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}
}

class WrongType {
  constructor(public mismatch: string) {}
}

export { CorrectType, WrongType };
