// Enumerations
import { AccessKeyChangeSecondStepRepeatErrorIdEnumeration } from '../enumerations/security-keys-access-key-change-second-step.enumeration';
// Types
import { AccessKeyChangeStep2NewHandlersPropsType } from './types/security-keys-access-key-change-second-step-handlers-props-return.type';
import { AccessKeyChangeStep2NewHandlersReturnType } from './types/security-keys-access-key-change-second-step-handlers-return.type';
import { SaveNewAccessKeyChangeInputEventHandlerPropsType } from './types/security-keys-access-key-change-second-step-handlers-save-new-access-key-props.handler.type';
import { SaveRepeatNewAccessKeyChangeInputEventHandlerPropsType } from './types/security-keys-access-key-change-second-step-handlers-save-repeat-access-key-props.handler.type';
import { ValidateAccessKeyChangeInputEventHandlerPropsType } from './types/security-keys-access-key-change-second-step-handlers-validate-new-access-key-props.handler.type';
import { ValidateRepeatAccessKeyChangeInputEventHandlerPropsType } from './types/security-keys-access-key-change-second-step-handlers-validate-repeat-access-key-props.handler.type';
// Resolvers
import { validateAccessKeyResolver } from '../resolvers/security-keys-access-key-change-second-step.resolvers';
// Utilities
import { checkHasFourNumbersOnly } from '../../../utils/security-keys-access-key-change-constraints.util';


const validateAccessKey = async ({
  accessKey,
  newAccessKey,
  setInputError,
}: ValidateAccessKeyChangeInputEventHandlerPropsType): Promise<void> => {
  const [response, error] = await validateAccessKeyResolver({
    oldAccessKey: accessKey,
    newAccessKey,
  });
  setInputError(!error ? response.failedValidations : null);
};

const saveNewAccessKeyChangeInputEventHandler = ({
  accessKey,
  newAccessKey,
  repeatNewAccessKey,
  setNewAccessKey,
  setInputError,
  setInputErrorRepeat,
}: SaveNewAccessKeyChangeInputEventHandlerPropsType): void => {
  setNewAccessKey(newAccessKey);
  if (checkHasFourNumbersOnly(newAccessKey)) {
    validateAccessKey({
      accessKey,
      newAccessKey,
      setInputError,
    });
    checkHasFourNumbersOnly(repeatNewAccessKey) &&
      validateRepeatAccessKeyChangeInputEventHandler({
        newAccessKey,
        repeatNewAccessKey,
        setInputErrorRepeat,
      });
  } else {
    setInputError(null);
    setInputErrorRepeat(null);
  }
};

const saveRepeatNewAccessKeyChangeInputEventHandler = ({
  newAccessKey,
  repeatNewAccessKey,
  setInputErrorRepeat,
  setRepeatNewAccessKey,
}: SaveRepeatNewAccessKeyChangeInputEventHandlerPropsType): void => {
  setRepeatNewAccessKey(repeatNewAccessKey);
  if (checkHasFourNumbersOnly(repeatNewAccessKey)) {
    validateRepeatAccessKeyChangeInputEventHandler({
      newAccessKey,
      repeatNewAccessKey,
      setInputErrorRepeat,
    });
  } else {
    setInputErrorRepeat(null);
  }
};

const validateRepeatAccessKeyChangeInputEventHandler = ({
  setInputErrorRepeat,
  newAccessKey,
  repeatNewAccessKey,
}: ValidateRepeatAccessKeyChangeInputEventHandlerPropsType): void => {
  if (newAccessKey !== repeatNewAccessKey) {
    setInputErrorRepeat(AccessKeyChangeSecondStepRepeatErrorIdEnumeration.DONT_MATCH);
  } else {
    setInputErrorRepeat(null);
  }
};

const SecurityKeysAccessKeyChangeSecondStepHandlers = ({
  accessKey,
  newAccessKey,
  repeatNewAccessKey,
  setInputError,
  setNewAccessKey,
  setRepeatNewAccessKey,
  setInputErrorRepeat,
}: AccessKeyChangeStep2NewHandlersPropsType): AccessKeyChangeStep2NewHandlersReturnType => ({
  handleSaveNewAccessKeyChangeInputEvent: (newAccessKey: string) =>
    saveNewAccessKeyChangeInputEventHandler({
      accessKey,
      newAccessKey,
      repeatNewAccessKey,
      setNewAccessKey,
      setInputError,
      setInputErrorRepeat,
    }),
  handleSaveNewRepeatAccessKeyChangeInputEvent: (repeatNewAccessKey: string) =>
    saveRepeatNewAccessKeyChangeInputEventHandler({
      newAccessKey,
      repeatNewAccessKey,
      setInputErrorRepeat,
      setRepeatNewAccessKey,
    }),
});

export default SecurityKeysAccessKeyChangeSecondStepHandlers;
