// Resolver
import { cancelContractCheckpointResolver } from '../../challenge/resolvers/support-cancel-contract-second-step.resolvers';
// Types
import { SetupFinishActionsEventHandlerParamsType } from '../hook/types/support-cancel-contract-second-step-actions-setup-finish-handlers.type';
import { SupportCancelContractSecondStepActionsHandlersReturnType } from './types/support-cancel-contract-second-step-actions-handlers-return.type';
import { SupportCancelContractSecondStepActionsHandlersParamsType } from './types/support-cancel-contract-second-step-actions-handlers.type';

const setupFinishActionsEventHandler = async ({
  cardContractId,
  handleNextButtonClickTrackingHandlerStep2,
  progressId,
  reason,
  setIsDisabledLastStep,
  setProgressId,
  setReason,
  setResult,
}: SetupFinishActionsEventHandlerParamsType): Promise<void> => {
  const [response, error] = await cancelContractCheckpointResolver({
    cardContractId,
    progressId,
    reason,
  });

  handleNextButtonClickTrackingHandlerStep2();

  if (response && !error) {
    setResult(true);
  } else {
    setResult(false);
  }
  setProgressId('');
  setReason('');
  setIsDisabledLastStep(true);
};

const SupportCancelContractSecondStepActionsHandlers = ({
  cardContractId,
  handleNextButtonClickTrackingHandlerStep2,
  progressId,
  reason,
  setIsDisabledLastStep,
  setProgressId,
  setReason,
  setResult,
}: SupportCancelContractSecondStepActionsHandlersParamsType): SupportCancelContractSecondStepActionsHandlersReturnType => ({
  handleFinishActionsEvent: () =>
    setupFinishActionsEventHandler({
      cardContractId,
      handleNextButtonClickTrackingHandlerStep2,
      progressId,
      reason,
      setIsDisabledLastStep,
      setProgressId,
      setReason,
      setResult,
    }),
});

export default SupportCancelContractSecondStepActionsHandlers;
