// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const BoldMessageComponentStyled = styled.b.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyA};
`;
