// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const TableRowAssetDateComponentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  text-align: center;

  ${mobileMediaQuery} {
    flex-direction: row;
  }
`;

export { TableRowAssetDateComponentStyled };
