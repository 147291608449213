// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const VerifyCorrectDataComponentWrapperStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const FirstBlockWrapperStyled = styled.div`
  display: flex;
  flex-direction: row;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const DataWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 50%;
`;
