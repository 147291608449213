// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const TransactionsEppStep1SimulationSummaryComponentStyled = styled.section`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.backgroundInfoE};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  flex: 1;
  padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};
  max-width: ${convertPxsToRems({ pixels: 276, base: 14 })};
  width: 100%;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};
    max-width: 100%;
  }
`;
TransactionsEppStep1SimulationSummaryComponentStyled.displayName =
  'TransactionsEppStep1SimulationSummaryComponentStyled';

export { TransactionsEppStep1SimulationSummaryComponentStyled };
