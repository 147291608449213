// Vendors
import styled from 'styled-components';

export const DashboardTopMessageInnerWrapperComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
DashboardTopMessageInnerWrapperComponentStyled.displayName =
  'DashboardTopMessageInnerWrapperComponentStyled';
