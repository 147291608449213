// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_BLOCK_BUTTON_PROPS = {
  assetConfiguration: {
    id: 'lock',
    sizes: {
      height: 20,
      width: 20,
    },
    position: 'left',
    testId: 'card-settings-block-button-asset',
  },
  rwdFull: false,
  type: buttonConstants.TYPES.TERTIARY,
  testId: 'card-settings-block-button',
};

export { CARD_SETTINGS_BLOCK_BUTTON_PROPS };
