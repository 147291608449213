// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { RECOVERY_KEY_STEP5_ACTIONS_SERVICE_PROPS } from './constants/recovery-key-step5-actions.service.constants';
// Types
import { RecoveryKeyStep5ApiBuilderType } from '../types/recovery-key-step-5-api-builder.type';

const createRecoveryKeyService = async ({
  password,
  progressId,
}: RecoveryKeyStep5ApiBuilderType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...RECOVERY_KEY_STEP5_ACTIONS_SERVICE_PROPS,
    customHeaders: { progressId },
    params: { password },
  });

export { createRecoveryKeyService };
