// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Types
import { EppCardType } from 'types/epp-card.type';

const isEPPCompleted = (status: string): boolean =>
  status === EppElementStatusEnumeration.CLOSED ||
  status === EppElementStatusEnumeration.CANCELLED ||
  status === EppElementStatusEnumeration.RETURNED;

const isEPPInProgress = (status: string): boolean =>
  status === EppElementStatusEnumeration.CREATED ||
  status === EppElementStatusEnumeration.PARTIAL_RETURNED ||
  status === EppElementStatusEnumeration.REQUESTED;

const showViewDetailsLink = ({
  hasOrders,
  status,
}: Pick<EppCardType, 'hasOrders' | 'status'>): boolean => {
  return hasOrders && (isEPPCompleted(status) || isEPPInProgress(status));
};

export { showViewDetailsLink };
