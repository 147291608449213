// Enums
import { TrackingEventsEnum } from '@openbank/cf-ui-static-data';
// Types
import {
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SignatureKeyChangeStep3TrackingHandlersReturnType } from './types/security-keys-signature-key-change.third-step.tracking.handlers.type';

const nextButtonClickTrackingHandlerStep3 = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationConfirmNewSignatureKeyButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterThirdStepSignatureKeyChangeTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeSignatureKey/step03/confirmation',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'security',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SecurityKeysSignatureKeyChangeThirdStepTrackingHandlers = (
  track: TrackBuilderType
): SignatureKeyChangeStep3TrackingHandlersReturnType => ({
  handleNextButtonClickTrackingHandlerStep3: () => track(nextButtonClickTrackingHandlerStep3()),
  handleOnEnterThirdStepSignatureKeyChangeTrackingHandler: () =>
    track(onEnterThirdStepSignatureKeyChangeTrackingHandler()),
});

export default SecurityKeysSignatureKeyChangeThirdStepTrackingHandlers;
