// Constants
import { BLOCK_CARD_ENDPOINT } from 'services/block-card/constants/block-card.services.constants';
// Enumerations
import { BlockReasonsEnumeration } from 'enumerations/block-reasons.enumeration';

const CHALLENGE_PROPS = {
  baseService: BLOCK_CARD_ENDPOINT,
  baseServiceMethod: 'post',
};

const REQUEST_NEW_CARD_PARAMS = {
  isNewCardRequested: true,
  reasonCode: BlockReasonsEnumeration.DAMAGED,
};

export { CHALLENGE_PROPS, REQUEST_NEW_CARD_PARAMS };
