const FILTER_LINK_PROPS = {
  assetConfiguration: {
    id: 'filter-a-light',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'backgroundInfoD',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'documents-all-filter-asset',
  },
  disabled: false,
  iconOnLeft: true,
  testId: 'documents-all-filter',
};

export { FILTER_LINK_PROPS };
