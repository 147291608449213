const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cardsLanding.newCardsAndBenefits.';

const BENEFITS_AND_INSURANCES = `${DOMAIN}benefitsAndInsurancesLabel`,
  BENEFITS_AND_INSURANCES_LINK = `${DOMAIN}benefitsAndInsurancesLink`,
  REQUEST_NEW_CARD = `${DOMAIN}requestNewCardLabel`,
  REQUEST_NEW_CARD_LINK = `${DOMAIN}requestNewCardLink`,
  REQUEST_SECONDARY_CARD = `${DOMAIN}requestSecondaryCardLabel`,
  REQUEST_SECONDARY_CARD_LINK = `${DOMAIN}requestSecondaryCardLink`;

export {
  BENEFITS_AND_INSURANCES,
  BENEFITS_AND_INSURANCES_LINK,
  REQUEST_NEW_CARD,
  REQUEST_NEW_CARD_LINK,
  REQUEST_SECONDARY_CARD,
  REQUEST_SECONDARY_CARD_LINK,
};
