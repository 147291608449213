// Vendors
import { useState } from 'react';
// Contexts
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Enumerations
import { PlanErrorEnumeration } from '../enumerations/plan-error.enumeration';
// Handlers
import { FinancingInsurancesContractingLPIStep2Handlers } from '../handlers/financing-insurances-contracting-lpi-step2.handlers';
// Types
import { FinancingInsurancesContractingLPIStep2ReturnHookType } from './types/financing-insurances-contracting-lpi-step2-return.hook.type';

export const useFinancingInsurancesContractingLPIStep2 =
  (): FinancingInsurancesContractingLPIStep2ReturnHookType => {
    const [error, setError] = useState<PlanErrorEnumeration | null>(null);

    const { insurancesInfo } = useInsurancesContextConsumerHook();
    const { plan } = useFinancingInsurancesContractingLPIContextConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();

    return {
      ...FinancingInsurancesContractingLPIStep2Handlers({
        insurancesInfo,
        plan,
        setCurrentStep,
        setError,
      }),
      error,
    };
  };
