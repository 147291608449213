// Vendors
import styled from 'styled-components';

export const DevicesManagerListComponentStyled = styled.div.attrs(() => ({
  'data-testid': 'devices-list-component',
}))`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const DevicesManagerListItemComponentStyled = styled.div`
  width: 100%;
`;