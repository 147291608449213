// Constants
import { STEP_TWO } from '../constants/card-settings-check-3d-confirmation-security-module-description.constants';
// Enumerations
import { TimerEnum } from '@openbank/cf-ui-static-data';
// Types
import {
  CardSettingsCheck3dConfirmationSecurityModuleDescriptionHandlersType,
  CardSettingsCheck3dConfirmationSecurityModuleDescriptionMethodHandlersType,
  CardSettingsCheck3dConfirmationSecurityModuleDescriptionReturnHandlersType,
} from './types/card-settings-check-3d-confirmation-security-module-description.handlers.type';
import {
  CardSettingsCheck3dConfirmationSecurityModuleManageCountDownType,
  CardSettingsCheck3dConfirmationSecurityModuleNewQueryLinkHandlersType,
} from './types/card-settings-check-3d-confirmation-security-modeule-description-functions.handler.type';

const hideAndShowCode = ({
  setShowAllowance,
  setShownCode,
}: CardSettingsCheck3dConfirmationSecurityModuleDescriptionMethodHandlersType): void => {
  setShowAllowance(false);
  setShownCode(false);
};

const updateSeconds =
  (
    props: CardSettingsCheck3dConfirmationSecurityModuleDescriptionMethodHandlersType
  ): ((prevSeconds: number) => number) =>
  (prevSeconds: number) => {
    const newSeconds = prevSeconds - 1;

    newSeconds === 0 && hideAndShowCode(props);

    return newSeconds;
  };

const manageCountDownForShowCodeEventHandler = ({
  setSeconds,
  setShownCode,
  setShowAllowance,
}: CardSettingsCheck3dConfirmationSecurityModuleManageCountDownType): void => {
  setTimeout(() => {
    setSeconds(updateSeconds({ setShowAllowance, setShownCode }));
  }, TimerEnum.TIMEOUT_ONE_SECOND);
};

const makeANewQueryLinkClickHandler = ({
  handleMakeANewQueryLinkClickTracking,
  setScaCode,
  setHasChallenged,
  setCurrentStep,
}: CardSettingsCheck3dConfirmationSecurityModuleNewQueryLinkHandlersType): void => {
  handleMakeANewQueryLinkClickTracking();
  setScaCode('');
  setHasChallenged(false);
  setCurrentStep(STEP_TWO);
};

const CardSettingsCheck3dConfirmationSecurityModuleDescriptionHandlers = ({
  handleMakeANewQueryLinkClickTracking,
  setScaCode,
  setHasChallenged,
  setShowAllowance,
  setShownCode,
  setSeconds,
  setCurrentStep,
}: CardSettingsCheck3dConfirmationSecurityModuleDescriptionHandlersType): CardSettingsCheck3dConfirmationSecurityModuleDescriptionReturnHandlersType => ({
  handleMakeANewQueryLinkClick: () =>
    makeANewQueryLinkClickHandler({
      handleMakeANewQueryLinkClickTracking,
      setScaCode,
      setHasChallenged,
      setCurrentStep,
    }),
  handleManageCountDownForShowCodeEvent: () =>
    manageCountDownForShowCodeEventHandler({ setShowAllowance, setShownCode, setSeconds }),
});

export default CardSettingsCheck3dConfirmationSecurityModuleDescriptionHandlers;
