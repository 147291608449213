// Vendors
import React from 'react';
import { Routes, Route } from 'react-router-dom';
// Constants
import { TRANSACTIONS_ROUTER_ROUTES } from './constants/transactions-router.constants';

const TransactionsRouterComponent = (): React.ReactElement => (
  <Routes>
    {TRANSACTIONS_ROUTER_ROUTES.map(({ element: RouteComponent, elementProps, props }, index) => (
      <Route {...props} element={<RouteComponent {...elementProps} />} key={index} />
    ))}
  </Routes>
);

export { TransactionsRouterComponent };
