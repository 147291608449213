// Components
import { UploadDocTips } from '@openbank/cb-ui-commons';
// Constants
import { ASSET_TIPS } from '../constants/step-4-upload-documentation.component.constants';
// Translations
import { step4UploadDocumentationTranslations } from '../translations/step-4-upload-documentation.translations';
// Types
import { GetUploadDocTipsConfigurationType } from './types/upload-doc-tips.cofiguration.type';
// Utils
import {
  makeChunkLinkIntlUtil,
  makeChunkBoldIntlUtil,
} from 'hooks/useCbIntl/utils/useCbIntl.utils';

export const getUploadDocTipsConfiguration = ({
  formatMessage,
  setShowInfoModal,
}: GetUploadDocTipsConfigurationType): React.ComponentProps<typeof UploadDocTips> => ({
  title: formatMessage({ id: step4UploadDocumentationTranslations.TIPS_FOR_UPLOADING_TITLE }),
  tips: [
    {
      assetConfiguration: { ...ASSET_TIPS.SIZE_TIP.assetConfiguration },
      text: formatMessage(
        { id: step4UploadDocumentationTranslations.TIP_1 },
        {
          b: makeChunkBoldIntlUtil,
          a: chunk => makeChunkLinkIntlUtil({ chunk, onClick: () => setShowInfoModal(true) }),
        }
      ),
    },
    {
      assetConfiguration: { ...ASSET_TIPS.FORMAT_TIP.assetConfiguration },
      text: formatMessage(
        { id: step4UploadDocumentationTranslations.TIP_2 },
        { b: makeChunkBoldIntlUtil }
      ),
    },
    {
      assetConfiguration: { ...ASSET_TIPS.IMAGE_TIP.assetConfiguration },
      text: formatMessage(
        { id: step4UploadDocumentationTranslations.TIP_3 },
        { b: makeChunkBoldIntlUtil }
      ),
    },
  ],
});
