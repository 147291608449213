// Constants
import { SettingsCardControlOperationsToggleHookUtilsType } from './types/settings-card-control-operations-toggle.hook.utils.type';
import { SettingsCardControlOperationsToggleReturnHookUtilsType } from 'containers/card-settings/views/card-control/components/operations/components/toggle/hooks/utils/types/settings-card-control-operations-toggle-return.hook.utils.type';

const getOperativeOperationTranslations = ({
  translations,
  value,
}: SettingsCardControlOperationsToggleHookUtilsType): SettingsCardControlOperationsToggleReturnHookUtilsType =>
  value ? translations.ACTIVATE : translations.DEACTIVATE;

export { getOperativeOperationTranslations };
