// Services
import { cancelLPIContractService } from '../services/financing-insurances-cancellation-lpi.service';
// Types
import { CancelLPIContractResolverPropsType } from './types/financing-insurances-cancellation-lpi.resolver.type';
// Utils
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const cancelLPIContractResolver = async (
  props: CancelLPIContractResolverPropsType
): Promise<[number] | [null, boolean]> => {
  const [, { status }] = await cancelLPIContractService(props);

  return wasSuccessRequest(status) ? [status] : [null, true];
};

export { cancelLPIContractResolver };
