// Vendors
import styled from 'styled-components';

export const LoginGapsTitleComponentStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;
