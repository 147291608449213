// Types
import { AvailableBalancePropsType } from './types/available-balance-props.type';

const availableBalance = ({
  availableAmount,
  fastCashLimits,
}: AvailableBalancePropsType): boolean => {
  return availableAmount >= fastCashLimits.minAmount;
};

export { availableBalance };
