// Vendors
import React from 'react';
// Components
import EnableDisableCardConfirmationModal from 'components/enable-disable-card-confirmation-modal/enable-disable-card-confirmation-modal.component';
// Hooks
import CardStatusButtonSwitchOnModalHook from './hooks/card-status-button-switch-on-modal.hook';
// Types
import { CardStatusButtonSwitchOnModalComponentType } from './types/card-status-button-switch-on-modal.component.type';

const CardStatusButtonSwitchOnModalComponent = ({
  onClose,
  showActivateModal,
}: CardStatusButtonSwitchOnModalComponentType): React.ReactElement<CardStatusButtonSwitchOnModalComponentType> | null => {
  const {
    fetching,
    handleCancelSwitchOnCardLinkClick,
    handleCloseSwitchOnCardModalAssetClick,
    handleConfirmSwitchOnCardButtonClick,
  } = CardStatusButtonSwitchOnModalHook({ onClose });

  return showActivateModal ? (
    <EnableDisableCardConfirmationModal
      isEnabled={false}
      loadingStatus={fetching}
      onClose={handleCloseSwitchOnCardModalAssetClick}
      onConfirm={handleConfirmSwitchOnCardButtonClick}
      onDismiss={handleCancelSwitchOnCardLinkClick}
    />
  ) : null;
};

export default CardStatusButtonSwitchOnModalComponent;
