const FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_PROPS = {
    documentName: 'Vorvertragliche Informationen.pdf',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_SERVICE_PROPS = {
    endpoint: 'cb-api-ppi/v1/ppi/precontract',
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_DOWNLOAD_SERVICE_PROPS,
};
