// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsCheck3DWizardContextType } from 'containers/card-settings/views/check-3d/components/wizard/contexts/types/card-settings-check-3d-wizard.context.type';
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
import { CardSettingsCheck3dWizardProps } from '../types/card-settings-check-3d-wizard.component.type';

const CardSettingsCheck3DWizardWizardContext = createContext<
  CardSettingsCheck3DWizardContextType | Record<string, unknown>
>({});

export const CardSettingsCheck3DWizardContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsCheck3DWizardContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');
  const [result, setResult] = useState<OperativeResultsEnumeration | null>(null);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);

  return (
    <CardSettingsCheck3DWizardWizardContext.Provider
      value={{
        fetching,
        progressId,
        setFetching,
        setProgressId,
        result,
        setResult,
        showCancelModal,
        setShowCancelModal,
      }}
    >
      {children}
    </CardSettingsCheck3DWizardWizardContext.Provider>
  );
};

export const useCardSettingsCheck3DWizardContextConsumerHook =
  (): CardSettingsCheck3DWizardContextType =>
    useContext(CardSettingsCheck3DWizardWizardContext) as CardSettingsCheck3DWizardContextType;

/* eslint-disable */
export const CardSettingsCheck3DWizardContextConsumerHOC =
  (
    Component: React.FunctionComponent<CardSettingsCheck3dWizardProps>
  ): React.FunctionComponent<CardSettingsCheck3dWizardProps> =>
  (
    props: CardSettingsCheck3dWizardProps
  ): React.ReactElement<React.Provider<CardSettingsCheck3DWizardContextType>> =>
    (
      <CardSettingsCheck3DWizardContextProvider>
        <Component {...props} />
      </CardSettingsCheck3DWizardContextProvider>
    );
