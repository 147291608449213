// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Enumerations
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';
// Translations
import {
  RECOVERY_OPTIONS,
  WHAT_IS_THIS,
} from './translations/recovery-key-step3.configuration.translations';
// Types
import { RecoveryKeyStep3ReturnConfigurationType } from './types/recovery-key-step3-return.configuration.type';
import { GetHelperMessageConfigurationType } from './types/recovery-key-step3-helper.configuration.type';
import { RecoveryKeyStep3HookType } from '../hooks/types/recovery-key-step3.hook.type';
// Styled
import { RecoveryKeyStep3ComponentLinkStyled } from '../recovery-key-step3.component.styled';

export const getHelperMessageConfiguration = ({
  formatMessage,
  id,
  onInfoLickClick,
}: GetHelperMessageConfigurationType): React.ReactElement | string =>
  id === PasswordRecoveryOptionsEnumeration.CREDIT_CARD ? (
    <RecoveryKeyStep3ComponentLinkStyled>
      <FormattedMessageComponent {...{ id: RECOVERY_OPTIONS[id] }} />
      <LinkComponent onClick={onInfoLickClick}>
        <FormattedMessageComponent {...{ id: WHAT_IS_THIS }} />
      </LinkComponent>
    </RecoveryKeyStep3ComponentLinkStyled>
  ) : (
    formatMessage({ id: RECOVERY_OPTIONS[id] })
  );

const getRecoveryOptionsConfiguration = ({
  formatMessage,
  passwordRecoveryOptions,
  onInfoLickClick,
}: Pick<
  RecoveryKeyStep3HookType,
  'passwordRecoveryOptions' | 'formatMessage' | 'onInfoLickClick'
>): RecoveryKeyStep3ReturnConfigurationType[] =>
  passwordRecoveryOptions?.map((id: PasswordRecoveryOptionsEnumeration) => ({
    id,
    helperMessage: getHelperMessageConfiguration({ formatMessage, id, onInfoLickClick }),
  })) || [];

export { getRecoveryOptionsConfiguration };
