// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/verify-correct-data.constants';
// Hooks
import VerifyCorrectDataHook from './hooks/verify-correct-data.hook';
// Styles
import {
  DataWrapperStyled,
  FirstBlockWrapperStyled,
  VerifyCorrectDataComponentWrapperStyled,
} from './verify-correct-data.component.styled';
// Translations
import { DATA, TITLE } from './translations/verify-correct-data.translations';

const VerifyCorrectDataComponent = (): React.ReactElement => {
  const { accountNumber, amountValue, commission, totalCommission } = VerifyCorrectDataHook();

  return (
    <VerifyCorrectDataComponentWrapperStyled {...constants.PROPS}>
      <ParagraphComponent {...constants.MAIN_TITLE_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </ParagraphComponent>
      <FirstBlockWrapperStyled>
        <DataWrapperStyled>
          <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
            <FormattedMessageComponent id={DATA.DESTINATION_ACCOUNT_TITLE} />
          </ParagraphComponent>
          <ParagraphComponent {...constants.DESCRIPTION_PARAGRAPH_PROPS}>
            <FormattedMessageComponent
              id={DATA.DESTINATION_ACCOUNT_DESCRIPTION}
              values={{ accountNumber }}
            />
          </ParagraphComponent>
        </DataWrapperStyled>
        <DataWrapperStyled>
          <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
            <FormattedMessageComponent id={DATA.AMOUNT_TO_TRANSFER} />
          </ParagraphComponent>
          <ParagraphComponent {...constants.DESCRIPTION_PARAGRAPH_PROPS}>
            {amountValue}
          </ParagraphComponent>
        </DataWrapperStyled>
      </FirstBlockWrapperStyled>

      <DataWrapperStyled>
        <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
          <FormattedMessageComponent id={DATA.APPLIED_COMMISSION} values={{ commission }} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.DESCRIPTION_PARAGRAPH_PROPS}>
          {totalCommission}
        </ParagraphComponent>
      </DataWrapperStyled>
    </VerifyCorrectDataComponentWrapperStyled>
  );
};

export default VerifyCorrectDataComponent;
