// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export const DEVICES_SIDEBAR_PROPS = {
  testId: 'signature-key-devices',
};

export const DEVICES_SIDEBAR_DESCRIPTION_PROPS = {
  testId: 'signature-key-devices-sidebar-table-row-description',
};

export const DEVICES_SIDEBAR_PROPS_MESSAGE_01 = {
  testId: 'security-keys-sidebar-message-01',
  size: paragraphConstants.SIZES.M,
};

export const DEVICES_SIDEBAR_PROPS_MESSAGE_02 = {
  testId: 'security-keys-sidebar-message-02',
  size: paragraphConstants.SIZES.M,
};

export const DEVICES_SIDEBAR_PROPS_MESSAGE_03 = {
  testId: 'security-keys-sidebar-message-03',
  size: paragraphConstants.SIZES.M,
};

export const DEVICES_SIDEBAR_PROPS_MESSAGE_04 = {
  testId: 'security-keys-sidebar-message-04',
  size: paragraphConstants.SIZES.M,
};
