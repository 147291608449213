const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbTransactions.createInstallmentsPlan.step1.creditMessage.';

const TITLE = `${DOMAIN}title`;

const DESCRIPTION = `${DOMAIN}description`;

const BUTTON = `${DOMAIN}button`;

export { TITLE, DESCRIPTION, BUTTON };
