const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbTransactions.createInstallmentsPlan.cbFeedback.';

const ERROR_DESCRIPTION = `${DOMAIN}errorDescription`,
  ERROR_TITLE = `${DOMAIN}errorTitle`,
  EXIT = `${DOMAIN}goToTransactionsLink`,
  EXIT_LINK = `${DOMAIN}goToTransactionsLink`,
  SUCCESS_TITLE = `${DOMAIN}successTitle`;

export { ERROR_DESCRIPTION, EXIT_LINK, ERROR_TITLE, EXIT, SUCCESS_TITLE };
