// Vendors
import styled from 'styled-components';
import { convertPxsToRems } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const TotalAmmountWrapperStyled = styled.section.attrs(({ testId }: TestIdPropsType) => ({
  'data-testid': testId,
}))`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundInfoE};
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingM};
  min-width: ${convertPxsToRems({ pixels: 276, base: 14 })};
`;

export const InfoWrapperStyled = styled.section`
  display: flex;
  flex-direction: column;

  > *:first-child {
    color: ${({ theme }) => theme.colors.fontSecondaryA};
  }
`;

export const CommissionWrapperStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  align-items: center;
`;
