// Vendors
import React from 'react';
// Components
import SecurityKeysSignatureKeyChangeThirdStepContainerHeaderComponent from './actions/components/security-keys-signature-key-change-third-step-container-header.component';
import SecurityKeysSignatureKeyChangeThirdStepActionsComponent from './actions/security-keys-signature-key-change-third-step-actions.component';
import SecurityKeysSignatureKeyChangeChallengeComponent from './challenge/security-keys-signature-key-change-third-step-challenge.component';

const SecurityKeysSignatureKeyChangeThirdStepComponent = (): React.ReactElement => (
  <>
    <SecurityKeysSignatureKeyChangeThirdStepContainerHeaderComponent />
    <SecurityKeysSignatureKeyChangeChallengeComponent />
    <SecurityKeysSignatureKeyChangeThirdStepActionsComponent />
  </>
);

export default SecurityKeysSignatureKeyChangeThirdStepComponent;
