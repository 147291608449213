// Vendors
import { useEffect } from 'react';
// Handlers
import { CreditLimitChangeHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-1-select-limit.handler';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Trackings
import Step1CreditLimitChageTracking from '../../step1/trackings/step-1-credit-limit-increase.tracking';
// Types
import { Step1CreditLimitIncreaseHookType } from './types/step-1-credit-limit-increase.hook.types';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

export const Step1CreditLimitIncreaseHook = (): Step1CreditLimitIncreaseHookType => {
  const creditLimitIncreaseContext = CreditLimitIncreaseContextConsumerHook();
  const { step1 } = creditLimitIncreaseContext;
  const isDisabled = step1.nextDisabled;
  const { currentCard } = AppContextConsumerHook();
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleSelectCreditLimit } = CreditLimitChangeHandlers({
    creditLimitContext: creditLimitIncreaseContext,
    currentCard,
    setCurrentStep,
  });
  const { handleOpenExternalUrlOnHybridFlow } = MobileConnectorContextConsumerHook();
  const { handleEnterViewTracking } = AppTrackingHook(Step1CreditLimitChageTracking);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleEnterViewTracking, []);

  return {
    disabled: isDisabled ?? true,
    isLoadingNextRequest: step1.isLoadingNextRequest,
    handleSelectCreditLimit,
    handleOpenExternalUrlOnHybridFlow,
  };
};
