const DOMAIN = 'cobranded.services.userSettings.privateArea.ibanBilling.changeIBAN.step1.sepaModal.';

export default {
  TITLE: `${DOMAIN}title`,
  CREDITOR_TITLE: `${DOMAIN}creditorTitle`,
  DEBTOR_TITLE: `${DOMAIN}debtorTitle`,
  CREDITOR_ID: `${DOMAIN}creditorId`,
  CREDITOR_ID_VALUE: `${DOMAIN}creditorIdValue`,
  IBAN_AND_BIC: `${DOMAIN}ibanAndBic`,
  CREDITOR_ADDRESS: `${DOMAIN}creditorAddress`,
  CREDITOR_ADDRESS_VALUE: `${DOMAIN}creditorAddressValue`,
  SEPA_MANDATE: `${DOMAIN}sepaMandate`,
  DEBTORS_NAME: `${DOMAIN}debtorsName`,
  CYCLE_DAY: `${DOMAIN}cycleDay`,
  DEBTORS_ADDRESS: `${DOMAIN}debtorsAddress`,
  TYPE_OF_PAYMENT: `${DOMAIN}typeOfPayment`,
  TYPE_OF_PAYMENT_VALUE: `${DOMAIN}typeOfPaymentValue`,
  MODAL_BUTTON: `${DOMAIN}modalButton`,
};