// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GET_PUBLIC_KEY_SERVICE_CONFIGURATION, VERIFY_PIN_PAN_SERVICE_CONFIGURATION } from './constants/recovery-key-step4-credit-card.services.constants';
// Types
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';
import { ValidatePinServiceType } from './types/recovery-key-step4-credit-card.services.type';

const validatePinService = async ({
  authData: { progressId },
  pin,
  last4DigitsPan
}: ValidatePinServiceType): Promise<
  [PasswordRecoveryOptionsApiResponseType | Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    ...VERIFY_PIN_PAN_SERVICE_CONFIGURATION,
    customHeaders: { progressId },
    params: {
      last4DigitsPan,
      pin
    },
  });

  const getPublickKeyService = async (): Promise<any> =>
    apiUtilsCb.get({
      ...GET_PUBLIC_KEY_SERVICE_CONFIGURATION,
    });

export { validatePinService, getPublickKeyService };
