// Vendors
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const TransactionsRouterEppGuardComponent = (): React.ReactElement => {
  if (getFeatureFlagInstance()?.isEnabled(FeatureFlagsEnumeration.CREATE_EPP)) {
    return <Outlet />;
  }

  return <Navigate to={generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS)} />;
};

export { TransactionsRouterEppGuardComponent };
