// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  ADD_ADDRESS_PAYLOAD,
  CANCEL_DELETE_ADDRESS_PAYLOAD,
  CHANGE_ADDRESS_PAYLOAD,
  CLOSE_DELETE_ADDRESS_PAYLOAD,
  DELETE_ADDRESS_PAYLOAD,
  OPEN_DELETE_ADDRESS_PAYLOAD,
  OPEN_EDIT_ADDRESS_PAYLOAD,
} from './constants/block-card-address.handlers.tracking.constants';
// Types
import { BlockCardAddressReturnHandlersTrackingType } from './types/block-card-address-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const selectAddressTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CHANGE_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openAddressModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: ADD_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openEditAddressModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_EDIT_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const openDeleteModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: OPEN_DELETE_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const cancelDeleteAddressTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CANCEL_DELETE_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const closeDeleteModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CLOSE_DELETE_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const deleteAddressTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: DELETE_ADDRESS_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const BlockCardAddressTrackingHandlers = (
  track: TrackBuilderType
): BlockCardAddressReturnHandlersTrackingType => ({
  handleSelectAddressTracking: () => track(selectAddressTrackingHandler()),
  handleOpenAddressModalTracking: () => track(openAddressModalTrackingHandler()),
  handleOpenEditAddressModalTracking: () => track(openEditAddressModalTrackingHandler()),
  handleOpenDeleteModalTracking: () => track(openDeleteModalTrackingHandler()),
  handleCancelDeleteAddressTracking: () => track(cancelDeleteAddressTrackingHandler()),
  handleCloseDeleteModalTracking: () => track(closeDeleteModalTrackingHandler()),
  handleDeleteAddressTracking: () => track(deleteAddressTrackingHandler()),
});

export { BlockCardAddressTrackingHandlers };
