// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DeviceInfoPlatformComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  padding: ${({ theme }) => theme.spaces.spacingS};
  display: flex;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXs}
  }
`;

export const DeviceInfoPlatformItemComponentStyled = styled.div`
  width: 100%;
`;

export const DeviceInfoPlatformItemContentComponentStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;