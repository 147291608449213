// Configurations
import { getResolverByType } from '../configurations/device-info.configurations';
// Constants
import { MESSAGES_SNACKBAR } from '../constants/device-info.constants';
// Enums
import { DeviceModalEnum } from '../../device-modal/enumerations/device-modal.enumeration';
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Handlers
import { DeviceManagerHandlers } from 'containers/security-keys/views/devices/handlers/signature-key-devices.handlers';
// Types
import { DeviceInfoHandlersPropsType } from './type/device-info.handlers.props.type';
import { DeviceInfoHandlersReturnType } from './type/device-info.handlers.return.type';
import { DeviceInfoAcceptHandlersPropsType } from './type/device-info.accept.handlers.props.type';
// Utilities
import { scrollFromTop } from '@openbank/cf-ui-framework';

const openBlockToggleModalHandler = ({
  device,
  setOpen,
  setModalType,
  onOpenBlockModalDeviceTracking,
  onOpenUnBlockModalDeviceTracking,
}: DeviceInfoHandlersPropsType) => {
  device.blocked ? onOpenUnBlockModalDeviceTracking() : onOpenBlockModalDeviceTracking();

  setModalType(device.blocked ? DeviceModalEnum.UNBLOCK : DeviceModalEnum.BLOCK);
  setOpen(true);
};

const openUnrollModalHandler = ({
  setOpen,
  setModalType,
  onOpenUnrollTrustedModalDeviceTracking,
}: DeviceInfoHandlersPropsType) => {
  onOpenUnrollTrustedModalDeviceTracking();
  setModalType(DeviceModalEnum.UNROLL_TRUSTED);
  setOpen(true);
};

const onAccepModalHandler = async (args: DeviceInfoAcceptHandlersPropsType) => {
  const { getLastDevices } = DeviceManagerHandlers(args);
  const { device, setOpen, modalType, setToastConfiguration, setLoading, progressId, setTrusted } =
    args;

  setLoading(true);

  if (!progressId && modalType === DeviceModalEnum.UNROLL_TRUSTED) {
    setLoading(false);

    return setToastConfiguration({
      type: ToastTypesEnumeration.ERROR,
      description: MESSAGES_SNACKBAR.ERROR[modalType],
    });
  }

  const [, error] = await getResolverByType(modalType)({
    deviceUUID: device.deviceUUID,
    progressId,
  });

  if (error) {
    setToastConfiguration({
      type: ToastTypesEnumeration.ERROR,
      description: MESSAGES_SNACKBAR.ERROR[modalType],
    });
  } else {
    setToastConfiguration({
      type: ToastTypesEnumeration.SUCCESS,
      description: MESSAGES_SNACKBAR.SUCCESS[modalType],
    });

    scrollFromTop(0);
    modalType !== DeviceModalEnum.UNROLL_TRUSTED ? getLastDevices() : setTrusted(null);
  }

  setLoading(false);
  setOpen(false);
};

const DeviceInfoHandlers = (props: DeviceInfoHandlersPropsType): DeviceInfoHandlersReturnType => ({
  openBlockToggleModal: () => openBlockToggleModalHandler(props),
  openUnrollModal: () => openUnrollModalHandler(props),
  onAccepModal: args => onAccepModalHandler({ ...props, ...args }),
});

export { DeviceInfoHandlers };
