const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step5.';

const step5Translations = {
  TITLE: `${DOMAIN}title`,
  APPROVAL_FEEDBACK_TITLE: `${DOMAIN}approvalFeedbackTitle`,
  APPROVAL_FEEDBACK_DESCRIPTION: `${DOMAIN}approvalFeedbackDescription`,
  APPROVAL_FEEDBACK_DESCRIPTION_ALTERNATE: `${DOMAIN}approvalFeedbackDescriptionAlternate`,
  NEW_OFFER_FEEDBACK_TITLE: `${DOMAIN}newOfferFeedbackTitle`,
  NEW_OFFER_FEEDBACK_DESCRIPTION: `${DOMAIN}newOfferFeedbackDescription`,
  PENDING_FEEDBACK_TITLE: `${DOMAIN}pendingFeedbackTitle`,
  PENDING_FEEDBACK_DESCRIPTION: `${DOMAIN}pendingFeedbackDescription`,
  DOCUMENTATION_UPLOADED: `${DOMAIN}documentationUploaded`,
  DOCUMENTATION_UPLOADED_DESCRIPTION: `${DOMAIN}documentationUploadedDescription`,
  CHECK_INFOMATION: `${DOMAIN}checkInformation`,
  GO_TO_FINANCING: `${DOMAIN}goToFinancing`,
  GO_TO_SIGN_CONTRACT: `${DOMAIN}goToSignContract`,
  REJECT_OFFER: `${DOMAIN}rejectOffer`,
  REJECTION_FEEDBACK_TITLE: `${DOMAIN}rejectionFeedbackTitle`,
  REJECTION_FEEDBACK_DESCRIPTION: `${DOMAIN}rejectionFeedbackDescription`,
  EXIT_THE_PROCESS: `${DOMAIN}exitTheProcess`,
};

export { step5Translations };
