// Types
import { CardSettingsActivateCardHandlersType } from './types/card-settings-activate-card.handlers.type';
import { CardSettingsActivateCardReturnHandlersType } from './types/card-settings-activate-card-return.handlers.type';

const unmountHandler = ({ setCard }: CardSettingsActivateCardHandlersType) => setCard(true);

const CardSettingsActivateCardHandlers = (
  props: CardSettingsActivateCardHandlersType
): CardSettingsActivateCardReturnHandlersType => ({
  handleUnmount: () => unmountHandler(props),
});

export default CardSettingsActivateCardHandlers;
