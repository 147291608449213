// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TRAVEL_PLUS_FEATURES,
  TRAVEL_PLUS_FEATURE_ASSET_PROPS,
  TRAVEL_PLUS_FEATURE_ASSET_TEST_ID,
  TRAVEL_PLUS_FEATURE_DESCRIPTION_TEST_ID,
  TRAVEL_PLUS_FEATURES_LAYOUT_PROPS,
  TRAVEL_PLUS_FEATURE_TITLE_TEST_ID,
  TRAVEL_PLUS_FEATURE_TITLE_PROPS,
} from './constants/travel-plus-features.constants';
// Styles
import {
  TravelPlusFeaturesComponentStyled,
  TravelPlusFeaturesContentComponentStyled,
} from './travel-plus-features.component.styled';
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Types
import { TravelPlusFeatureType } from './types/travel-plus-feature.type';

const TravelPlusFeaturesComponent = (): React.ReactElement => (
  <TravelPlusFeaturesComponentStyled>
    {TRAVEL_PLUS_FEATURES.map(
      ({ description, icon: id, title }: TravelPlusFeatureType, key: number) => (
        <TravelPlusFeaturesContentComponentStyled key={key}>
          <AssetComponent
            {...{ ...TRAVEL_PLUS_FEATURE_ASSET_PROPS, id }}
            testId={TRAVEL_PLUS_FEATURE_ASSET_TEST_ID + id}
          />
          <LayoutColumnFlexComponentStyled {...TRAVEL_PLUS_FEATURES_LAYOUT_PROPS}>
            <ParagraphComponent
              {...TRAVEL_PLUS_FEATURE_TITLE_PROPS}
              testId={TRAVEL_PLUS_FEATURE_TITLE_TEST_ID + id}
            >
              <FormattedMessageComponent id={title} />
            </ParagraphComponent>
            <ParagraphComponent testId={TRAVEL_PLUS_FEATURE_DESCRIPTION_TEST_ID + id}>
              <FormattedMessageComponent id={description} />
            </ParagraphComponent>
          </LayoutColumnFlexComponentStyled>
        </TravelPlusFeaturesContentComponentStyled>
      )
    )}
  </TravelPlusFeaturesComponentStyled>
);

export { TravelPlusFeaturesComponent };
