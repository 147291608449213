// Constants
import { REQUEST_SIGNATURE_KEY_ACTIONS_SERVICE_PROPS } from 'containers/security-keys/views/request-signature-key/services/constants/request-signature-key.services.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';

export const getChallengeConfiguration = ({
  challengeError,
  handleChallengeError,
  handleChallengeRetryButtonClick,
  handleChallengeSuccess,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: REQUEST_SIGNATURE_KEY_ACTIONS_SERVICE_PROPS.endpoint,
  baseServiceBuilder: {},
  baseServiceMethod: 'post',
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
