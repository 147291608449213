// Vendors
import { useEffect } from 'react';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Constants
import { LOCAL_STORAGE_LOGIN_FROM_PRIVATE } from 'constants/storage.constants';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const useONBRedirect = (): null => {
  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_LOGIN_FROM_PRIVATE, String(true));
    window.location.replace(generateAbsoluteRoute(RoutesEnumeration.ONBOARDING_LINK));
  }, []);

  return null;
};

export { useONBRedirect };
