// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { CARD_SETTINGS_STATEMENTS_MESSAGE_TRACKING_PROPS } from 'containers/card-settings/views/statements/components/message/handlers/constants/card-settings-statements-table-row-download.handlers.tracking.constants';
// Types
import { CardSettingsStatementsMessageBuilderReturnTrackingHandlersType } from 'containers/card-settings/views/statements/components/message/handlers/types/card-settings-statements-message.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const makeATransferMoneyButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...CARD_SETTINGS_STATEMENTS_MESSAGE_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const CardSettingsStatementsMessageTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsStatementsMessageBuilderReturnTrackingHandlersType => ({
  handleMakeATransferMoneyButtonClickTracking: () =>
    track(makeATransferMoneyButtonClickTrackingHandler()),
});

export { CardSettingsStatementsMessageTrackingHandlers };
