// Types
import { SignatureKeyChangeStep3ChallengeHandlersReturnType } from './types/security-keys-signature-key-change-third-step-challenge-handlers-return.type';
import { SignatureKeyChangeStep3ChallengeHandlersParamsType } from './types/security-keys-signature-key-change-third-step-challenge-handlers.type';
import { SupportCancelContractSecondStepChallengeHandlersManageSuccessOnChangeType } from './types/security-keys-signature-key-change-third-step-challenge-manage-success-handlers.type';
import { ChallengeProgressIdType } from 'types/challenge/challenge-progress-id.type';

const manageSuccessOnChangeSmsEventHandler = async ({
  setProgressId,
  progressId,
  setIsDisabledLastStep,
  handleSendOtpCodeSuccessStep3TrackingHandler,
  handleSendOtpCodeStep3TrackingHandler,
}: SupportCancelContractSecondStepChallengeHandlersManageSuccessOnChangeType): Promise<void> => {
  handleSendOtpCodeStep3TrackingHandler();
  handleSendOtpCodeSuccessStep3TrackingHandler();
  progressId && setProgressId(progressId);
  setIsDisabledLastStep(false);
};

const SecurityKeysSignatureKeyChangeThirdStepChallengeHandlers = ({
  setProgressId,
  setIsDisabledLastStep,
  handleSendOtpCodeStep3TrackingHandler,
  handleSendOtpCodeSuccessStep3TrackingHandler,
}: SignatureKeyChangeStep3ChallengeHandlersParamsType): SignatureKeyChangeStep3ChallengeHandlersReturnType => ({
  handleManageSuccessOnChangeSmsEvent: ({ progressId }: ChallengeProgressIdType) =>
    manageSuccessOnChangeSmsEventHandler({
      progressId,
      setProgressId,
      setIsDisabledLastStep,
      handleSendOtpCodeSuccessStep3TrackingHandler,
      handleSendOtpCodeStep3TrackingHandler,
    }),
});

export default SecurityKeysSignatureKeyChangeThirdStepChallengeHandlers;
