// Enums
import { RequestEmailOptionEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Translations
import translations from '../../translations/request-portability-step2-email-options.translations';
// Types
import { IsSelectedType } from './types/is-selected.type';
import { HelperMessageType } from './types/request-portability-step2-helper-message.type';

const isSelected = ({ emailCheckboxValue, value }: IsSelectedType): boolean =>
  emailCheckboxValue === value;

const getHelperMessage = ({ value, email, formatMessage }: HelperMessageType): string => {
  let helperMessage = formatMessage({ id: translations.OTHER_EMAiL_INPUT_HELPER });

  if (value === RequestEmailOptionEnumeration.YOUR_EMAIL) {
    helperMessage = email;
  }

  return helperMessage;
};

export { getHelperMessage, isSelected };
