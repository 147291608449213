// Components
import TransactionsLandingRowComponent from 'containers/transactions/views/landing/components/row/transactions-landing-row.component';
// Translations
import translations from '../translations/dashboard-table.translations';

const DASHBOARD_LANDING_TABLE_PROPS = {
  rowComponent: TransactionsLandingRowComponent,
  title: translations.TITLE,
  id: { idName: 'id', idTable: 'dashboard' },
};

export { DASHBOARD_LANDING_TABLE_PROPS };
