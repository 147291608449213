// Constants
import {
  QUERY_DESTINATION_CHAT_PARAM,
  QUERY_DESTINATION_PARAM,
} from 'providers/public-interactions/handlers/utils/constants/public-interactions.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { PublicInteractionsHandlersBuilderType } from 'providers/public-interactions/handlers/types/public-interactions.handlers.type';

const publicSiteIsDemandingChat = ({
  pathname,
  searchParams,
}: PublicInteractionsHandlersBuilderType): boolean =>
  pathname.includes(RoutesEnumeration.SUPPORT) &&
  searchParams.get(QUERY_DESTINATION_PARAM) === QUERY_DESTINATION_CHAT_PARAM;

export { publicSiteIsDemandingChat };
