// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const getFetchOrNoContentError = (status: number): TransactionsFetchErrorEnumeration =>
  requestRespondedNoContent(status)
    ? TransactionsFetchErrorEnumeration.NO_RESULTS
    : TransactionsFetchErrorEnumeration.FETCH;

export { getFetchOrNoContentError };
