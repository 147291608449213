// Constants
import { NUMBER } from 'constants/number.constants';
// Translations
import {
  AMOUNT_NOT_MULTIPLE_OF_TEN,
  AMOUNT_RECEIVED_VALIDATION,
  AMOUNT_WITH_DECIMALS,
} from '../translations/transactions-report-different-amount-atm-additional-info-inputs.translations';
// Types
import { AdditionalInfoFormValuesType } from 'containers/transactions/views/report/components/different-amount-atm/components/additional-info/types/additional-info-form-values.type';
// Utils
import { hasDecimals, isMultipleOf } from 'utils/number.utils';

const validateIsTheAmountReceivedLessThanRequested = (
  value: string | number,
  values: AdditionalInfoFormValuesType
): string | boolean =>
  Number(value) < Number(values?.movementAtmImportRequested) || AMOUNT_RECEIVED_VALIDATION;

const validateAmountHasDecimals = (value: string | number): string | boolean =>
  !hasDecimals(value) || AMOUNT_WITH_DECIMALS;

const validateAmountMultipleOfTen = (value: string | number): string | boolean =>
  isMultipleOf({ value, multipleOf: NUMBER.TEN }) || AMOUNT_NOT_MULTIPLE_OF_TEN;

export {
  validateIsTheAmountReceivedLessThanRequested,
  validateAmountHasDecimals,
  validateAmountMultipleOfTen,
};
