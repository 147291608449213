// Constants
import constants from '../constants/access-portability-request.constants';
// Enums
import { EnvironmentsEnum } from '@openbank/cf-ui-static-data';

const getPrivacyUrl = (): string => {
  let privacyUrl = constants.REQUEST_ACCESS_PRIVACY_PROD_URL;

  if (Object.keys(EnvironmentsEnum).includes(window.ENVIRONMENT.toUpperCase())) {
    const environment = window.ENVIRONMENT;
    privacyUrl = `https://www.${environment}.cb.zinia.de/amazon/datenschutz-cookies/`;
  }
  return privacyUrl;
};

export { getPrivacyUrl };
