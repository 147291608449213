const inputRangeAmountcommonProps = {
  placeholder: '0,00',
  type: 'currency',
  suffix: '€',
};

const FROM_INPUT_RANGE_AMOUNT_DEFAULT_PROPS = {
  ...inputRangeAmountcommonProps,
};

const TO_INPUT_RANGE_AMOUNT_DEFAULT_PROPS = {
  ...inputRangeAmountcommonProps,
};

const TITLE_COMPONENT_PRPOPS = {
  tag: 'h5',
  bold: false,
};

const FILTER_MODAL_INPUT_RANGE_DEFAULT_PROPS = {
  testId: 'filter-modal-input-range-amount-component',
  title: 'Amount',
  showLineSeparator: true,
};

export {
  FILTER_MODAL_INPUT_RANGE_DEFAULT_PROPS,
  FROM_INPUT_RANGE_AMOUNT_DEFAULT_PROPS,
  TITLE_COMPONENT_PRPOPS,
  TO_INPUT_RANGE_AMOUNT_DEFAULT_PROPS,
};
