// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Constants
import constants from '../constants/card-settings-activate-card.constants';
// Contexts
import { CardSettingsActivateCardContextConsumerHook } from '../contexts/card-settings-activate-card.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import CardSettingsActivateCardSuccessHandlers from '../handlers/card-settings-activate-card-success.handlers';
import CardSettingsActivateCardFailedHandlers from '../handlers/card-settings-activate-card-failed.handlers';
import CardSettingsActivateCardSuccessTrackingHandlers from '../handlers/card-settings-activate-card-success.tracking.handlers';
import CardSettingsActivateCardFailedTrackingHandlers from '../handlers/card-settings-activate-card-failed.tracking.handlers';
import CardSettingsActivateCardHandlers from '../handlers/card-settings-activate-card.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Translations
import translations from 'containers/card-settings/views/landing/translations/card-settings.translations';
// Types
import { CardSettingsActivateCardReturnHookType } from './types/card-settings-activate-card-return.hook.type';
// Utilities
import { getMappedBreadCrumbTitlePath } from 'components/title-section/components/rwd/utils/title-section-rwd.utils';

const CardSettingsActivateCardHook = (): CardSettingsActivateCardReturnHookType => {
  const { formatMessage } = useCbIntl();

  const prevPath: string = localStorage.getItem('prevPath') || '';
  const route = prevPath || constants.DEFAULT_BACK_ROUTE;
  const linkLabel = getMappedBreadCrumbTitlePath(prevPath) ?? translations.TITLE;
  const { error, setChallenge } = CardSettingsActivateCardContextConsumerHook();
  const { setCard } = ManagerContextConsumerHook();
  const { result } = useWizardFeedbackContextConsumerHook();

  const { handleUnmount } = CardSettingsActivateCardHandlers({
    result,
    setCard,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleUnmount, []);

  return {
    ...CardSettingsActivateCardSuccessHandlers({
      ...AppTrackingHook(CardSettingsActivateCardSuccessTrackingHandlers),
      navigate: useNavigate(),
      route,
    }),
    ...CardSettingsActivateCardFailedHandlers({
      ...AppTrackingHook(CardSettingsActivateCardFailedTrackingHandlers),
      setChallenge,
    }),
    error,
    formatMessage,
    linkLabel,
  };
};

export default CardSettingsActivateCardHook;
