// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_BUTTON_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_ASSET_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_DESCRIPTION_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_LINK_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_PROPS,
} from './constants/transactions-landing-row-details-create-plan-modal.constants';
// Hooks
import { useTransactionsLandingRowDetailsCreatePlanModalHook } from './hooks/transactions-landing-row-details-create-plan-modal.hook';
// Styles
import { TransactionsLandingRowDetailsCreatePlanModalComponentStyled } from './transactions-landing-row-details-create-plan-modal.component.styled';
// Translations
import {
  CONTINUE_CREATING,
  CANCEL_CREATION,
  DESCRIPTION,
  TITLE,
} from './translations/transactions-landing-row-details-create-plan-modal.translations';
// Types
import { TransactionsLandingRowDetailsCreatePlanModalHookType } from './types/transactions-landing-row-details-create-plan-modal.hook.type';

const TransactionsLandingRowDetailsCreatePlanModalComponent = ({
  id,
  onClose: onHeaderClose,
  show,
}: TransactionsLandingRowDetailsCreatePlanModalHookType): React.ReactElement | null => {
  const { handleConfirmContinueCreatePlanButtonClick } =
    useTransactionsLandingRowDetailsCreatePlanModalHook(id);

  return show ? (
    <ModalComponent
      {...{ ...TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_PROPS, onHeaderClose, show }}
      linkConfiguration={{
        ...TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={CANCEL_CREATION} />,
        onClick: onHeaderClose,
      }}
      primaryButtonConfiguration={{
        ...TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={CONTINUE_CREATING} />,
        onClick: handleConfirmContinueCreatePlanButtonClick,
      }}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <TransactionsLandingRowDetailsCreatePlanModalComponentStyled>
        <AssetComponent {...TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_ASSET_PROPS} />
        <ParagraphComponent
          {...TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_DESCRIPTION_PROPS}
        >
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
      </TransactionsLandingRowDetailsCreatePlanModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export { TransactionsLandingRowDetailsCreatePlanModalComponent };
