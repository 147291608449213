// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
// Components
import { InputDatePickerComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DATE_CONTROLLER_NAME,
  DATE_CONTROLLER_RULES,
  DATE_INPUT_PROPS,
} from './constants/transactions-report-service-cancelled-additional-info-inputs.constants';
// Contexts
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
// Styles
import { TransactionsReportServiceCancelledAdditionalInfoInputsComponentStyled } from './transactions-report-service-cancelled-additional-info-inputs.component.styled';
// Translations
import { DATE_LABEL } from './translations/transactions-report-service-cancelled-additional-info-inputs.translations';
// Types
import { TransactionsReportServiceCancelledAdditionalInfoInputsComponentType } from './types/transactions-report-service-cancelled-additional-info-inputs.component.type';
// Utils
import { validateDate } from './utils/transactions-report-service-cancelled-additional-info-inputs.utils';
import { setErrorIfInvalidDate } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const TransactionsReportServiceCancelledAdditionalInfoInputsComponent = ({
  control,
  errors,
}: TransactionsReportServiceCancelledAdditionalInfoInputsComponentType): React.ReactElement => {
  const { transactionDetails } = useTransactionsReportContextConsumerHook(),
    { date = '' } = transactionDetails || {};
  const errorMessage = errors.date?.message && (
    <FormattedMessageComponent id={errors.date.message} />
  );

  return (
    <TransactionsReportServiceCancelledAdditionalInfoInputsComponentStyled>
      <Controller
        control={control}
        name={DATE_CONTROLLER_NAME}
        rules={{
          ...DATE_CONTROLLER_RULES,
          validate: value => validateDate(value, date),
        }}
        render={({ field }) => (
          <InputDatePickerComponent
            {...DATE_INPUT_PROPS}
            {...field}
            customErrorMessage={errorMessage}
            errorMessage={errorMessage}
            label={<FormattedMessageComponent id={DATE_LABEL} />}
            onSelectDate={field.onChange}
            onError={(hasInvalidDate: boolean) =>
              setErrorIfInvalidDate({ control, hasInvalidDate, dateName: DATE_CONTROLLER_NAME })
            }
          />
        )}
      />
    </TransactionsReportServiceCancelledAdditionalInfoInputsComponentStyled>
  );
};
