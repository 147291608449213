// Vendors
import React from 'react';
// Components
import { InputTextComponent, RadioButtonComponent, TitleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getRadioButtonConfiguration } from './configurations/request-portability-step2.component.configurations';
// Constants
import constants from './constants/request-portability-step2-email-options.constants';
// Hooks
import RequestPortabilityStep2EmailOptionsHook from './hooks/request-portability-step2-email-options.hook';
// Styles
import {
  EmailOptionsContainerStyled,
  EmailOptionsInputContainerStyled,
} from './request-portability-step2-email-options.component.styled';
// Translations
import translations from './translations/request-portability-step2-email-options.translations';
// Utils
import { isEmailInputDisabled } from './utils/request-portability-step2-email-options.utils';
import { RequestEmailOptionEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

const RequestPortabilityStep2EmailOptionsComponent = (): React.ReactElement => {
  const {
    email,
    emailCheckboxValue,
    error,
    formatMessage,
    handleEmailInputClickTracking,
    handleEmailOptionChange,
    handleSaveEmailInputEvent,
    handleValidateEmailInputEvent,
  } = RequestPortabilityStep2EmailOptionsHook();

  return (
    <EmailOptionsContainerStyled>
      <TitleComponent {...constants.TITLE_EMAIL_TO_SEND_PROPS}>
        <FormattedMessageComponent id={translations.EMAIL_TO_SEND_TITLE} />
      </TitleComponent>
      {constants.EMAIL_OPTIONS.map(({ title, testId, value }, i) => (
        <RadioButtonComponent
          key={i}
          {...getRadioButtonConfiguration({
            email,
            emailCheckboxValue,
            formatMessage,
            handleEmailOptionChange,
            testId,
            title,
            value,
          })}
        />
      ))}
      {emailCheckboxValue === RequestEmailOptionEnumeration.OTHER_EMAIL ? (
        <EmailOptionsInputContainerStyled>
          <InputTextComponent
            {...constants.EMAIL_TO_SEND_INPUT_PROPS}
            errorMessage={error && <FormattedMessageComponent id={translations.ERRORS[error]} />}
            disabled={isEmailInputDisabled({ emailCheckboxValue })}
            label={formatMessage({ id: translations.EMAIL_INPUT_LABEL })}
            placeholder={formatMessage({ id: translations.EMAIL_INPUT_PLACEHOLDER })}
            fullWidth={false}
            onBlur={handleValidateEmailInputEvent}
            onChange={handleSaveEmailInputEvent}
            onClick={handleEmailInputClickTracking}
          />
        </EmailOptionsInputContainerStyled>
      ) : null}
    </EmailOptionsContainerStyled>
  );
};

export default RequestPortabilityStep2EmailOptionsComponent;
