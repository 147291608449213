// Constants
import { assetConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  GO_TO_LOGIN,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
} from '../translations/recovery-key-feedback.translations';

const RECOVERY_KEY_LOGO_PROPS = {
    id: 'amazon-zinia-logo',
    sizes: {
      width: 272,
      height: 40,
    },
    type: assetConstants.TYPES.LOGO,
    testId: 'recovery-key-dr-picasso-header-logo',
  },
  TIMER_COUNTER = 10;
const ENDPOINTS = {
  VERIFY_SIGNATURE_KEY: 'cb-api-auth/password/recover/signature-key/verify',
  VERIFY_OTP: 'cb-api-auth/password/recover/otp/verify',
  OTP_INITIALIZATION: 'cb-api-auth/password/recover/init',
  RECOVERY_KEY: 'cb-api-auth/password/recover',
};

const FEEDBACK_CONFIGURATION = {
  errorConfiguration: {
    description: ERROR_DESCRIPTION,
    title: ERROR_TITLE,
    exitRoute: RoutesEnumeration.LOGIN,
  },
  successConfiguration: {
    description: SUCCESS_DESCRIPTION,
    linkLabel: GO_TO_LOGIN,
    title: SUCCESS_TITLE,
    exitRoute: RoutesEnumeration.LOGIN,
  },
};

export { RECOVERY_KEY_LOGO_PROPS, TIMER_COUNTER, ENDPOINTS, FEEDBACK_CONFIGURATION };
