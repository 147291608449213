// Constants
import { messageConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/profile-personal-name-title-dropdown.handlers.translations';

export default {
  FETCH_ERROR_TITLES_TOAST_CONFIGURATION: {
    type: messageConstants.TYPES.ERROR,
    description: translations.ERROR_FETCH_TITLES,
  },
};
