// Vendors
import React from 'react';
// Components
import { TravelPlusComponent } from 'components/travel-plus/travel-plus.component';
// Types
import { CardSettingsTogglesTravelPlusModalType } from './types/card-settings-toggles-travel-plus-modal.type';

const CardSettingsTogglesTravelPlusModalComponent = ({
  onClick: onHide,
  showTravelPlusModal,
}: CardSettingsTogglesTravelPlusModalType): React.ReactElement | null =>
  showTravelPlusModal ? <TravelPlusComponent {...{ onHide }} /> : null;

export { CardSettingsTogglesTravelPlusModalComponent };
