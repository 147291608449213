// Vendors
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsCheck3dConfirmationFooterTrackingHandlersType } from 'containers/card-settings/views/check-3d/components/confirmation/components/footer/handlers/types/card-settings-check-3d-confirmation-footer.tracking.handlers.type';

const goBackToCardSettingsLandingLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'backToCardSettings',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsCheck3dConfirmationFooterTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsCheck3dConfirmationFooterTrackingHandlersType => ({
  handleGoBackToCardSettingsLandingLinkClickTracking: () =>
    track(goBackToCardSettingsLandingLinkClickTrackingHandler()),
});

export default CardSettingsCheck3dConfirmationFooterTrackingHandlers;
