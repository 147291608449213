// Hooks
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
// Types
import { UseFinancingInsurancesContractingLPIDetailConfirmationProps } from './types/financing-insurances-contracting-lpi-detail-confirmation-return.hook.type';

const useFinancingInsurancesContractingLPIDetailConfirmation =
  (): UseFinancingInsurancesContractingLPIDetailConfirmationProps => {
    const { plan: planId } = useFinancingInsurancesContractingLPIContextConsumerHook();

    const { insurancesInfo } = useInsurancesContextConsumerHook();

    const planDetail = insurancesInfo?.insurances?.LPI?.availablePlans?.find(
      plan => plan?.code === planId
    );

    const { grossPremium, coverage } = planDetail || {};

    return { grossPremium, coverage };
  };

export { useFinancingInsurancesContractingLPIDetailConfirmation };
