export default {
  REQUEST_SIGNATURE_KEY_STEP_2_MESSAGE_01_PROPS: {
    testId: 'request-signature-key-step2-message-01',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_MESSAGE_PROPS: {
    showIcon: false,
    testId: 'request-signature-key-step2-message',
    type: 'informative',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_REQUEST_RESUME_ITEM_PROPS: {
    testId: 'request-signature-key-step2-request',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_CONFIRM_ADDRESS_SCRIPTBOX_PROPS: {
    testId: 'request-signature-key-step2-confirm-address-scriptbox',
    type: 'agent',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_CONFIRM_ADDRESS_SCRIPTBOX_DESCRIPTION_1_PROPS: {
    testId: 'request-signature-key-step2-confirm-address-description-1-scriptbox',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_CONFIRM_ADDRESS_SCRIPTBOX_DESCRIPTION_2_PROPS: {
    testId: 'request-signature-key-step2-confirm-address-description-2-scriptbox',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_ADDRESS_BOX_PROPS: {
    testId: 'request-signature-key-step2-address-box',
  },
  REQUEST_SIGNATURE_KEY_STEP_2_ADDRESS_BOX_DESCRIPTION_PROPS: {
    testId: 'request-signature-key-step2-address-box-description',
  },
};
