// Vendors
import { useState } from 'react';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
// Enumerations
import { NewEmailErrorIdEnumeration } from '../enumerations/personal-email-step1-new.enumeration';
// Event handlers
import PersonalEmailStep1NewHandlers from '../handlers/personal-email-step1-new.handlers';
import PersonalEmailStep1NewTrackingHandlers from '../handlers/personal-email-step1-new.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalEmailStep1NewHookType } from './types/personal-email-step1-new.hook.type';

const PersonalEmailStep1NewHook = (): PersonalEmailStep1NewHookType => {
  const [inputError, setInputError] = useState<NewEmailErrorIdEnumeration | null>(null);

  const { newEmail, setNewEmail } = PersonalEmailContextConsumerHook();

  const { handleModifyEmailNewInputClickTracking, handleModifyEmailNewInputErrorTracking } =
      AppTrackingHook(PersonalEmailStep1NewTrackingHandlers),
    { handleSaveNewModifyEmailInputEvent, handleValidateNewModifyEmailInputEvent } =
      PersonalEmailStep1NewHandlers({
        handleModifyEmailNewInputErrorTracking,
        newEmail,
        setInputError,
        setNewEmail,
      });

  return {
    handleModifyEmailNewInputClickTracking,
    handleSaveNewModifyEmailInputEvent,
    handleValidateNewModifyEmailInputEvent,
    inputError,
    newEmail,
  };
};

export default PersonalEmailStep1NewHook;
