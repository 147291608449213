// Types
import { PersonalEmailStep2ActionsUtilsType } from './types/personal-email-step2-actions.utils.type';

const modifyEmailStep2NextButtonIsDisabled = ({
  challenged,
  error,
  fetching,
}: PersonalEmailStep2ActionsUtilsType): boolean => Boolean(challenged || error || fetching);

export { modifyEmailStep2NextButtonIsDisabled };
