// Vendors
import React from 'react';
// Components
import { CardDetailsComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getCardDetailsConfiguration } from './configurations/card-details-wrapper.component.configurations';
// Hooks
import CardDetailsWrapperHook from './hooks/card-details-wrapper.hook';

const CardDetailsWrapperComponent = (): React.ReactElement => {
  const { cardImage, cardNumber, cardType } = CardDetailsWrapperHook();

  return (
    <CardDetailsComponent {...getCardDetailsConfiguration({ cardImage, cardNumber, cardType })} />
  );
};

export default CardDetailsWrapperComponent;
