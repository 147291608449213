// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_01_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_02_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_03_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_TITLE_PROPS,
  CARD_SETTINGS_CHECK_3D_SIDEBAR_PROPS,
} from './constants/card-settings-check-3d-sidebar.constants';
// Styles
import { CardSettingsCheck3dSidebarComponentStyled } from './card-settings-check-3d-sidebar.component.styled';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_03,
  DESCRIPTION_TITLE,
  TITLE,
} from './translations/card-settings-check-3d-sidebar.translations';

const CardSettingsCheck3dSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...CARD_SETTINGS_CHECK_3D_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={TITLE} />}
  >
    <CardSettingsCheck3dSidebarComponentStyled>
      <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_TITLE_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_TITLE} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_01_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_01} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_02_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_02} />
      </ParagraphComponent>
      <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_SIDEBAR_DESCRIPTION_03_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION_03} />
      </ParagraphComponent>
    </CardSettingsCheck3dSidebarComponentStyled>
  </SidebarComponent>
);

export default CardSettingsCheck3dSidebarComponent;
