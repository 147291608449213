// Event Handlers
import { CardSettingsLandingFooterHookCommonHandlers } from 'containers/card-settings/views/landing/components/footer/hooks/handlers/card-settings-landing-footer.hook.common.handlers';
// Types
import {
  CardSettingsLandingFooterBuilderHandlersType,
  CardSettingsLandingFooterBuilderReturnHandlersType,
} from 'containers/card-settings/views/landing/components/footer/hooks/handlers/types/card-settings-landing-footer.hook.handlers.type';

const CardSettingsLandingFooterHookHandlers = (
  props: CardSettingsLandingFooterBuilderHandlersType
): CardSettingsLandingFooterBuilderReturnHandlersType =>
  CardSettingsLandingFooterHookCommonHandlers(props);

export { CardSettingsLandingFooterHookHandlers };
