// Resolvers
import { generateAccessPortabilityRequestOtpResolver } from 'containers/personal/views/access-portability/views/request/components/step2/resolvers/request-access-step2.resolvers';
// Types
import { RequestAccessStep2ActionsBuilderHandlersType } from './types/request-access-step2-actions-builder.handlers.type';
import { RequestAccessStep2ActionsBuilderReturnHandlersType } from './types/request-access-step2-actions-builder-return.handlers.type';

const confirmRequestAccessButtonClickHandler = async ({
  handleRequestAccessButtonClickTracking,
  progressId,
  setFetching,
  setResult,
}: RequestAccessStep2ActionsBuilderHandlersType): Promise<void> => {
  setFetching(true);
  handleRequestAccessButtonClickTracking();

  const [, error] = await generateAccessPortabilityRequestOtpResolver({ progressId });

  setResult(error ? false : true);
  setFetching(false);
};

const RequestAccessStep2ActionsHandlers = (
  props: RequestAccessStep2ActionsBuilderHandlersType
): RequestAccessStep2ActionsBuilderReturnHandlersType => ({
  handleRequestAccessButtonClick: () => confirmRequestAccessButtonClickHandler(props),
});

export default RequestAccessStep2ActionsHandlers;
