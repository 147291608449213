// Vendors
import React from 'react';
// Components
import {
  TitleHeaderComponent,
  InputTextComponent,
  SecurityModuleComponent,
  TitleComponent,
  ParagraphComponent
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  RECOVERY_KEY_STEP_4_CREDIT_CARD_TITLE_PROPS,
} from './constants/recovery-key-step4-credit-card.constants';
// Configurations
import { getPanInputConfiguration } from './configurations/recovery-key-step4-credit-card.configurations';
// Hooks
import { RecoveryKeyStep4CreditCardHook } from './hooks/recovery-key-step4-credit-card.hook';
// Styles
import {
  RecoveryKeyStep4CreditCardComponentStyled,
  RecoveryKeyStep4CreditCardPanComponentStyled
} from './recovery-key-step4-credit-card.component.styled';
// Translations
import {
  TITLE,
  DESCRIPTION,
  PIN_TITLE,
  PIN_DESCRIPTION,
  SHOW_PASSWORD,
  HIDE_PASSWORD
} from './translations/recovery-key-step4-credit-card.translations';

const RecoveryKeyStep4CreditCardComponent = (): React.ReactElement => {
  const {
    pin,
    last4DigitsPan,
    error,
    fetching,
    onChangePan,
    onChangePin,
    validatePan,
    onContinue,
    canContinue,
    formatMessage
  } = RecoveryKeyStep4CreditCardHook();
  
  return (
    <RecoveryKeyStep4CreditCardComponentStyled>
      <TitleHeaderComponent {...RECOVERY_KEY_STEP_4_CREDIT_CARD_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </TitleHeaderComponent>
      <FormattedMessageComponent id={DESCRIPTION} />
      <RecoveryKeyStep4CreditCardPanComponentStyled>
        <InputTextComponent {...getPanInputConfiguration({
          formatMessage,
          value: last4DigitsPan,
          onBlur: validatePan,
          onChange: onChangePan,
          error
        })}/>
      </RecoveryKeyStep4CreditCardPanComponentStyled>
      <TitleComponent bold>
        <FormattedMessageComponent id={PIN_TITLE} />
      </TitleComponent>
      <ParagraphComponent>
        <FormattedMessageComponent id={PIN_DESCRIPTION} />
      </ParagraphComponent>
      <SecurityModuleComponent
        translations={{
          SHOW_PASSWORD: formatMessage({ id: SHOW_PASSWORD }),
          HIDE_PASSWORD: formatMessage({ id: HIDE_PASSWORD })
        }}
        onChange={onChangePin}
        value={pin}
      />
      <WizardStepFooterComponent
        isFooterDisabled={fetching}
        rightButtonConfiguration={{
          onClick: onContinue,
          disabled: !canContinue,
          loading: fetching,
        }}
      />
    </RecoveryKeyStep4CreditCardComponentStyled>
  );
};

export default RecoveryKeyStep4CreditCardComponent;
