// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WIZARD_FEEDBACK_HOST_DESCRIPTION_PROPS } from './constants/wizard-feedback-host-description.constants';
// Types
import { WizardFeedbackHostComponentType } from '../../types/wizard-feedback-host.component.type';

const WizardFeedbackHostDescriptionComponent = ({
  description,
  descriptionValues,
}: Pick<
  WizardFeedbackHostComponentType,
  'description' | 'descriptionValues'
>): React.ReactElement | null =>
  description ? (
    <ParagraphComponent {...WIZARD_FEEDBACK_HOST_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={description} values={descriptionValues} />
    </ParagraphComponent>
  ) : null;

export default WizardFeedbackHostDescriptionComponent;
