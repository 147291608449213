// Vendors
import { useState } from 'react';
// Event handlers
import { ThirdDownHandlers } from '../handlers/third-down.handlers';
// Types
import { ThirdDownHookType } from './types/third-down.hook.type';
// Utilities
import { thirdPartyDownCookieIsNotSet } from 'providers/third-down/hooks/utils/third-down.hook.utils';

const useThirdDownHook = (): ThirdDownHookType => {
  const [showThirdDownModal, setShowThirdDownModal] = useState(thirdPartyDownCookieIsNotSet());

  return {
    ...ThirdDownHandlers(setShowThirdDownModal),
    showThirdDownModal,
  };
};

export { useThirdDownHook };
