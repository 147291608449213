// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { BLOCK_REASON_CHANGE_PAYLOAD } from './constants/block-card-reason.handlers.tracking.constants';
// Types
import { BlockCardReasonReturnHandlersTrackingType } from './types/block-card-reason-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const blockReasonChangeTrackingHandler = (eventLabel: string): TrackingLinkEventReturnType => ({
  payload: { eventLabel, ...BLOCK_REASON_CHANGE_PAYLOAD },
  type: TrackingEventsEnum.LINK,
});

const BlockCardReasonTrackingHandlers = (
  track: TrackBuilderType
): BlockCardReasonReturnHandlersTrackingType => ({
  handleBlockReasonChangeTracking: eventLabel =>
    track(blockReasonChangeTrackingHandler(eventLabel)),
});

export default BlockCardReasonTrackingHandlers;
