export enum CategoryAssetIdEnumeration {
  ATM = '1',
  HOME = '2',
  STORES = '3',
  HEALTH = '4',
  INCOME = '5',
  INVESTMENTS = '6',
  EXCLUSIVE = '7',
  TRAVELS = '8',
  OTHERS = '9',
  RESTAURANTS = '10',
  INSURANCE = '11',
  BANKS = '12',
  TRANSPORTS = '13',
  PROFESSIONAL = '14',
}
