// Vendors
import React from 'react';
// Components
import AccessPortabilityRouterComponent from './components/router/access-portability-router.component';
// Styles
import { LayoutGridContentComponentStyled } from 'styles/app.styled';

const AccessPortabilityView = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <AccessPortabilityRouterComponent />
  </LayoutGridContentComponentStyled>
);

export default AccessPortabilityView;
