const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbDetails.cbModalComponent.';

const DESCRIPTIONS = {
  EU: `${DOMAIN}cbParragraph.eurWithdrawalFee`,
  FX: `${DOMAIN}cbParragraph.foreignCurrencyFee`,
  GE: `${DOMAIN}cbParragraph.issueFee`,
  IN: `${DOMAIN}cbParragraph.intWithdrawalFee`,
};

export { DESCRIPTIONS };
