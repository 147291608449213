// Constants
import { AMORTIZATION_TABLE_STATUS_TAG_TYPES } from '../constants/amortization-modal.component.constants';
import { DISABLED } from './constants/amortization-modal.component.utils.constants';

const isEmphasizedTag = (tag: string): boolean => tag === DISABLED;

const isValidateTag = (status?: string): boolean =>
  Boolean(status && AMORTIZATION_TABLE_STATUS_TAG_TYPES?.[status]);

export { isEmphasizedTag, isValidateTag };
