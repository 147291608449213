// Types
import {
  FilterChipsHandlersReturnType,
  FilterChipsHandlersType,
} from './types/filter-chips.handlers.type';

const deleteKeysFromObject = (obj, keysToDelete) => {
  const newObj = { ...obj };
  keysToDelete.forEach(key => {
    delete newObj[key];
  });
  return newObj;
};

const FilterChipsHandlers = ({
  setCopyOfFormValues,
  setFormValuesParams,
  setFormValues,
}: FilterChipsHandlersType): FilterChipsHandlersReturnType => {
  const updateFormValues = (prev, keys) => deleteKeysFromObject(prev, keys);

  return {
    handleClearFilterModal: () => {
      setFormValues({});
      setFormValuesParams?.({});
      setCopyOfFormValues({});
    },
    handleClickChip: ({ key }) => {
      const dateKeys = ['fromDate', 'toDate'];
      const amountKeys = ['fromAmount', 'toAmount'];
      const keysToDelete =
        dateKeys.includes(key) || key === 'dateDescription'
          ? [...dateKeys, 'dateDescription', 'dateOption']
          : amountKeys.includes(key)
          ? amountKeys
          : [key];
      setCopyOfFormValues(prev => updateFormValues(prev, keysToDelete));
      setFormValuesParams?.(prev => updateFormValues(prev, keysToDelete));
      setFormValues(prev => updateFormValues(prev, keysToDelete));
    },
  };
};

export { FilterChipsHandlers };
