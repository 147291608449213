export default {
  PROFILE_PERSONAL_NAME_ASSET_PROPS: {
    id: 'info',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    testId: 'profile-personal-name-asset',
  },
  PROFILE_PERSONAL_NAME_TOOLTIP_CONTENT_PROPS: {
    size: 'S',
    testId: 'personal-landing-tooltip-content-asset',
  },
};
