// Vendors
import React from 'react';
// Components
import { CommercialCardComponent } from '@openbank/cb-ui-commons';
import { FinancingInsurancesLandingCardContractModalComponent } from './components/contract-modal/financing-insurances-landing-card-contract-modal.component';
// Hooks
import { useFinancingInsurancesLandingCard } from './hooks/financing-insurances-landing-card.hook';
// Types
import { FinancingInsurancesLandingCardComponentType } from './types/financing-insurances-landing-card.component.type';
// Configurations
import { getPPILandingButtonConfiguration } from '../../configurations/financing-ppi-landing-button.configuration';
import { getPPILandingStatusConfiguration } from '../../configurations/financing-ppi-landing-status.configuration';
import { getPPILandingTagConfiguration } from '../../configurations/financing-ppi-landing-tag.configuration';
import { getPPILandingLinkContractConfiguration } from '../../configurations/financing-ppi-landing-link.configuration';
// Utils
import { isAvailableInsurance } from 'containers/financing/views/ppi/utils/financing-ppi.utils';
import { getCardHelperMessage } from '../../utils/financing-ppi-landing.utils';

export const FinancingInsurancesLandingCardComponent = ({
  description,
  descriptionValues,
  featureFlag,
  handleCancelPPIContractButtonClick,
  handleContractPPIButtonClick,
  handleViewTermsPolicyLinkClick,
  image,
  insuranceInfo,
  items,
  routes: { cancel, contract },
  title,
  tnc,
}: FinancingInsurancesLandingCardComponentType): React.ReactElement | null => {
  const {
    formatDate,
    formatMessage,
    handleCloseContractModalButtonClick,
    handleOpenContractModalButtonClick,
    handleUnderstoodButtonClick,
    isContractModalOpen,
  } = useFinancingInsurancesLandingCard();

  if (
    !insuranceInfo ||
    !isAvailableInsurance({
      customerContractingEnabled: insuranceInfo.customerContractingEnabled,
      featureFlag,
    })
  ) {
    return null;
  }

  const {
    customerContractingEnabled,
    status,
    endCoverageDate,
    contractingCoolingOffPeriodEndDate,
  } = insuranceInfo;

  return (
    <>
      <CommercialCardComponent
        description={formatMessage({ id: description }, descriptionValues)}
        title={formatMessage({ id: title })}
        items={items.map(({ description, title, ...rest }) => ({
          description: formatMessage({ id: description }),
          title: formatMessage({ id: title }),
          ...rest,
        }))}
        image={image}
        buttonConfiguration={getPPILandingButtonConfiguration({
          customerContractingEnabled,
          formatMessage,
          handleCancelPPIContractButtonClick: () => handleCancelPPIContractButtonClick(cancel),
          handleContractPPIButtonClick: () => handleContractPPIButtonClick(contract),
          handleOpenContractModalButtonClick,
          status,
        })}
        statusConfiguration={{
          ...getPPILandingLinkContractConfiguration({
            tnc,
            formatMessage,
            onClick: handleViewTermsPolicyLinkClick,
            status,
          }),
          helperText: getCardHelperMessage({ formatMessage, status }),
          statusInfo: getPPILandingStatusConfiguration({
            endCoverageDate,
            formatDate,
            formatMessage,
            status,
          }),
          tagConfiguration: getPPILandingTagConfiguration({ formatMessage, status }),
        }}
      />
      <FinancingInsurancesLandingCardContractModalComponent
        contractingCoolingOffPeriodEndDate={contractingCoolingOffPeriodEndDate}
        handleCloseContractModalButtonClick={handleCloseContractModalButtonClick}
        handleUnderstoodButtonClick={handleUnderstoodButtonClick}
        insuranceTitle={title}
        isContractModalOpen={isContractModalOpen}
      />
    </>
  );
};
