// Translations
import {
  FIRST_STEP,
  SECOND_STEP,
  THIRD_STEP,
} from '../translations/card-settings-block-card-success-content.configurations.translations';

const CONFIRMATION_NEXT_STEPS_PROPS = {
  isTitleHeader: true,
  testId: 'card-settings-block-card-success-content-confirmation-next-steps',
};

const STEPS = [
  {
    title: FIRST_STEP.TITLE,
    description: FIRST_STEP.DESCRIPTION,
  },
  {
    title: SECOND_STEP.TITLE,
    description: SECOND_STEP.DESCRIPTION,
  },
  {
    title: THIRD_STEP.TITLE,
    description: THIRD_STEP.DESCRIPTION,
  },
];

export { CONFIRMATION_NEXT_STEPS_PROPS, STEPS };
