// Handlers
import { RequestTableRowHandlers } from '../handlers/request-table-row.handlers';
// Types
import { type RequestTableRowHookType } from './types/request-table-row.hook.type';
import { type RequestTableRowReturnHookType } from './types/request-table-row-return.hook.type';

const RequestTableRowHook = ({
  documentId,
}: RequestTableRowHookType): RequestTableRowReturnHookType => {
  return {
    ...RequestTableRowHandlers({ documentId }),
  };
};

export { RequestTableRowHook };
