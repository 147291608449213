const RECOVERY_KEY_STEP1_PHONE_PROPS = {
  isRequired: true,
  prefixPlaceholder: '+XX',
  testing: {
    placeholderId: 'recovery-key-step1-message-input-placeholder',
    optionsPlaceholderId: 'recovery-key-step1-message-input-dropdown-placeholder',
    labelId: 'recovery-key-step1-message-input-label',
  },
  type: 'tel',
  testId: 'dropdown-phone-number-children-dropdown-arrow',
};

export { RECOVERY_KEY_STEP1_PHONE_PROPS };
