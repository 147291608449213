// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useTransactionsReportPaymentContextConsumerHook } from 'containers/transactions/views/report/components/payment/contexts/transactions-report-payment.context';
// Handlers
import { TransactionsReportPaymentAdditionalInfoActionsHandlers } from '../handlers/transactions-report-payment-additional-info-actions.handlers';
// Types
import { TransactionsReportPaymentAdditionalInfoActionsHandlersReturnType } from '../handlers/types/transactions-report-payment-additional-info-actions-return.handlers.type';

export const useTransactionsReportPaymentAdditionalInfoActionsHook =
  (): TransactionsReportPaymentAdditionalInfoActionsHandlersReturnType => {
    const { setPaymentMethod } = useTransactionsReportPaymentContextConsumerHook();
    const { setCurrentStep } = useWizardContextConsumerHook();

    return {
      ...TransactionsReportPaymentAdditionalInfoActionsHandlers({
        setPaymentMethod,
        setCurrentStep,
      }),
    };
  };
