// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { SettingCardControlLocationsWarningBuilderReturnTrackingHandlersType } from './types/setting-card-control-locations-warning-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const navigateCardControlOperationsButtonClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'goToOperations',
    },
    type: TrackingEventsEnum.LINK,
  });

const SettingCardControlLocationsWarningTrackingHandlers = (
  track: TrackBuilderType
): SettingCardControlLocationsWarningBuilderReturnTrackingHandlersType => ({
  handleNavigateCardControlOperationsLinkClickTracking: () =>
    track(navigateCardControlOperationsButtonClickTrackingHandler()),
});

export default SettingCardControlLocationsWarningTrackingHandlers;
