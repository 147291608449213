// Services
import { updateCardPinService } from '../services/card-settings-change-pin.service';
// Types
import { CardSettingsChangePinApiType } from '../types/card-settings-change-pin-api.type';
// Utils
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const updateCardPinResolver = async (
  props: CardSettingsChangePinApiType
): Promise<[string | any] | [null, number]> => {
  const [, { status }] = await updateCardPinService(props);

  return requestRespondedNoContent(status) ? [status] : [null, status];
};

export { updateCardPinResolver };
