// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
import { titleConstants, paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import {
  LEGAL_TEXT,
  MESSAGE,
  POLICY_HOLDER_TEXT,
} from '../translations/financing-ppi-contracting-step2.translations';

const FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-contracting-step2-description',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS = {
    label: MESSAGE,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS = {
    description: LEGAL_TEXT,
    size: paragraphConstants.SIZES.XS,
    testId: 'legal',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_POLICY_HOLDER_PROPS = {
    description: POLICY_HOLDER_TEXT,
    size: paragraphConstants.SIZES.XS,
    testId: 'policyholder',
  },
  FINANCING_PPI_CONTRACTING_STEP_2_SECOND_TITLE_PROPS = {
    testId: 'financing-ppi-contracting-step2-second-title',
    tag: titleConstants.TAGS.H4,
  },
  FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE = {
    bold: true,
    size: 'M',
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_2_DESCRIPTION_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_LAYOUT_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_LEGAL_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_CONSENT_POLICY_HOLDER_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_SECOND_TITLE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE,
};
