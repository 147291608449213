// Configurations
import { getSnackBarConfiguration } from '../configurations/sign-out.configurations';
// Enumerations
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Resolvers
import { deviceSessionsResolver, signOutDeviceResolver } from 'containers/security-keys/views/devices/resolvers/signature-key-devices.resolvers';
// Types
import { SignOutHandlersReturnType } from './types/sign-out-handlers.return.type';
import { SignOutHandlersType } from './types/sign-out-handlers.type';

const getActiveSessionId = async({ sessions, device, setSessions }) => {
  const deviceUUID = device.deviceUUID;
  let deviceSessions = sessions[deviceUUID];

  if (!deviceSessions) {
    const [newSessions, devicesError] = await deviceSessionsResolver({ deviceUUID });

    if(!devicesError && newSessions?.length) {
      deviceSessions = newSessions;
      setSessions(currSessions => ({...currSessions, [deviceUUID]: newSessions }));
    }
  }

  return deviceSessions.find(session => session.active)?.sessionId ;
};

const onSignOutHandlers = async (args) => {
  const { device, sessions, setSessions, setToastConfiguration, setLoading, setOpen } = args;

  setLoading(true);

  const sessionId = await getActiveSessionId({ sessions, device, setSessions });

  if (!sessionId) {
    setToastConfiguration({
      type: ToastTypesEnumeration.ERROR,
    });

    return setOpen(false);
  }

  const [, error] = await signOutDeviceResolver({ sessionId });

  setToastConfiguration(getSnackBarConfiguration({
    type: error ? ToastTypesEnumeration.ERROR : ToastTypesEnumeration.SUCCESS,
  }));

  if (!error && device.currentDevice) {
    args.clearSessionData();

    window.location.reload();
  } else if(!error) {
    args.getLastDevices();
  }

  setLoading(false);
  setOpen(false);
};

const onOpenSignOutModalHandler = ({ setOpen, onOpenSignOutModalDeviceTracking }) => {
  onOpenSignOutModalDeviceTracking();
  setOpen(true);
};

const SignOutHandlers = (
  props: SignOutHandlersType
): SignOutHandlersReturnType => ({
  onSignOut: () => onSignOutHandlers(props),
  onOpenSignOutModal: () => onOpenSignOutModalHandler(props),
  getActiveSessionHandler: () => getActiveSessionId(props)
});

export { SignOutHandlers };