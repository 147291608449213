// Vendors
import React from 'react';
// Components
import {
  ButtonComponent,
  MessageComponent,
  InputTextComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/tax-success.constants';
// Hooks
import TaxSuccessHook from './hooks/tax-success.hook';
// Styles
import { InputAndButtonStyled, TaxSuccessStyled } from './tax-success.component.styled';
// Translations
import { translationsFinalFeedback } from '../../translations/final-feedback.translations';

const TaxSuccessComponent = (): React.ReactElement => {
  const {
    taxId,
    isSaveLoading,
    isSaveDisabled,
    isTaxIdSent,
    setTaxId,
    handleSaveSuccessTaxId,
    formatMessage,
  } = TaxSuccessHook();

  return (
    <TaxSuccessStyled>
      <MessageComponent {...constants.MESSAGE_PROPS}>
        <ParagraphComponent bold>
          {formatMessage({ id: translationsFinalFeedback.TAXT_SUCCESS_TITLE })}
        </ParagraphComponent>
        <ParagraphComponent>
          {formatMessage({ id: translationsFinalFeedback.TAXT_SUCCESS_SUBTITLE })}
        </ParagraphComponent>
        <InputAndButtonStyled>
          <InputTextComponent
            {...constants.INPUT_PROPS}
            value={taxId}
            onChange={(value: number) => setTaxId(value)}
            label={formatMessage({ id: translationsFinalFeedback.INPUT_TAXT_ID })}
            placeholder={formatMessage({ id: translationsFinalFeedback.INPUT_PLACEHOLDER })}
          />
          <ButtonComponent
            {...constants.BUTTON_PROPS}
            loading={isSaveLoading}
            disabled={isSaveDisabled || isTaxIdSent}
            onClick={handleSaveSuccessTaxId}
          >
            {formatMessage({ id: translationsFinalFeedback.SAVE })}
          </ButtonComponent>
        </InputAndButtonStyled>
      </MessageComponent>
    </TaxSuccessStyled>
  );
};

export default TaxSuccessComponent;
