// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  PROPS: {
    testId: 'verify-correct-data-container',
  },
  MAIN_TITLE_PARAGRAPH_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.L,
  },
  TITLE_PARAGRAPH_PROPS: {
    size: paragraphConstants.SIZES.S,
  },
  DESCRIPTION_PARAGRAPH_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.M,
  },
};
