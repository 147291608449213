// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CONVERTED_AMOUNT_PARAGRAPH_PROPS_TITLE,
  CONVERTED_AMOUNT_PARAGRAPH_PROPS_VALUE,
  FORMATTED_NUMPER_SIGNS_PROP,
  PARAGRAPH_PROPS_TITLE,
  PARAGRAPH_PROPS_VALUE,
} from './constants/exchange-rate-component.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Styles
import { AmountDetailsModalRowDescriptionComponentStyled } from '../../amount-details-modal.component.styled';
// Translations
import {
  EXCHANGE_RATE,
  CONVERTED_AMOUNT,
} from '../../translations/amount-details-modal.translations';
// Types
import { CardTransactionDetailedAmountType } from 'types/card-transactions-detailed-amount.type';

export const ExchangeRateComponent = ({
  originalCurrency,
  exchangeRate,
  originalAmountEuro,
}: Pick<
  CardTransactionDetailedAmountType,
  'originalCurrency' | 'exchangeRate' | 'originalAmountEuro'
>): React.ReactElement | null => (
  <>
    <AmountDetailsModalRowDescriptionComponentStyled>
      <ParagraphComponent {...PARAGRAPH_PROPS_TITLE}>
        <FormattedMessageComponent id={EXCHANGE_RATE} />
      </ParagraphComponent>
      <ParagraphComponent {...PARAGRAPH_PROPS_VALUE}>
        <FormattedNumber
          {...EURO_FORMAT_OPTIONS}
          value={1}
          signDisplay={FORMATTED_NUMPER_SIGNS_PROP}
        />{' '}
        =
        <FormattedNumber
          {...EURO_FORMAT_OPTIONS}
          signDisplay={FORMATTED_NUMPER_SIGNS_PROP}
          currency={originalCurrency}
          value={exchangeRate}
          minimumFractionDigits={4}
        />
      </ParagraphComponent>
    </AmountDetailsModalRowDescriptionComponentStyled>
    <AmountDetailsModalRowDescriptionComponentStyled>
      <ParagraphComponent {...CONVERTED_AMOUNT_PARAGRAPH_PROPS_TITLE}>
        <FormattedMessageComponent id={CONVERTED_AMOUNT} />
      </ParagraphComponent>
      <ParagraphComponent {...CONVERTED_AMOUNT_PARAGRAPH_PROPS_VALUE}>
        <FormattedNumber {...EURO_FORMAT_OPTIONS} value={originalAmountEuro} />
      </ParagraphComponent>
    </AmountDetailsModalRowDescriptionComponentStyled>
  </>
);
