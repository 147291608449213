export default {
  DASHBOARD_SPENT_MESSAGE_PROPS: {
    inverted: false,
    testId: 'dashboard-spent-message-testing',
  },
  DASHBOARD_SPENT_MESSAGE_PARRAGRAPH_PROPS: {
    bold: true,
  },
  DASHBOARD_SPENT_MESSAGE_MOCK_TEXT: {
    title:'Informative: Title',
    body:'Body text',
  },
  DASHBOARD_SPENT_MESSAGE_DEFAULT_TEST_ID: 'dashboard-spent-message'
};