// Vendors
import React from 'react';
// Components
import { AddressModalInputsSearchComponent } from '../search/address-modal-inputs-search.component';
// Constants
import { CITY_PROPS, CITY_INPUT_PROPS } from './constants/address-modal-inputs-city.constants';
// Hooks
import AddressModalInputsCityHook from './hooks/address-modal-inputs-city.hook';
// Translations
import { CITY_INPUT_TRANSLATIONS } from './translations/address-modal-inputs-city.translations';
// Types
import { AddressModalInputsCityComponentType } from './types/address-modal-inputs-city.component.type';

export const AddressModalInputsCityComponent = ({
  control,
  errorMessage,
  ...rest
}: AddressModalInputsCityComponentType): React.ReactElement => {
  const { error, isLoading, onClickNoResultsLink, options } = AddressModalInputsCityHook(control);

  return (
    <AddressModalInputsSearchComponent
      {...CITY_PROPS}
      {...rest}
      control={control}
      errorMessage={errorMessage}
      inputProps={{ ...CITY_INPUT_PROPS, isLoading, options, onClickNoResultsLink }}
      translations={{ ...CITY_INPUT_TRANSLATIONS, ...error }}
    />
  );
};
