// Enumerations
import { OperativeResultsEnumeration } from 'components/wizard-feedback/enumerations/operative-results.enumeration';

const WIZARD_FEEDBACK_ERROR_PROPS = {
  assetId: 'critical',
  assetColor: 'backgroundErrorA',
  color: 'backgroundErrorE',
  type: OperativeResultsEnumeration.ERROR,
};

const HAS_LEFT_LINK = true;

export { WIZARD_FEEDBACK_ERROR_PROPS, HAS_LEFT_LINK };
