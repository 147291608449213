// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  CardSettingsLandingFooterBuilderHandlersType,
  CardSettingsLandingFooterBuilderReturnHandlersType,
} from 'containers/card-settings/views/landing/components/footer/hooks/handlers/types/card-settings-landing-footer.hook.handlers.type';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const blockDefinitiveCardButtonClickHandler = ({
  handleBlockDefinitiveCardButtonClickTracking,
  navigate,
}: CardSettingsLandingFooterBuilderHandlersType): void => {
  const url = generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.BLOCK]);

  handleBlockDefinitiveCardButtonClickTracking();
  navigate(generateAbsoluteRoute(url));
};

const CardSettingsLandingFooterHookCommonHandlers = (
  props: CardSettingsLandingFooterBuilderHandlersType
): CardSettingsLandingFooterBuilderReturnHandlersType => ({
  handleBlockDefinitiveCardButtonClick: () => blockDefinitiveCardButtonClickHandler(props),
});

export { CardSettingsLandingFooterHookCommonHandlers };
