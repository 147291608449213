// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  FinancingErrorButtonBuilderHandlersType,
  FinancingErrorButtonBuilderReturnHandlersType,
} from './types/financing-error-button.handlers.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const navigateToTransactionsViewButtonClick = ({
  handleNavigateToTransactionsViewButtonClickTracking,
  navigate,
}: FinancingErrorButtonBuilderHandlersType): any => {
  handleNavigateToTransactionsViewButtonClickTracking();
  navigate(generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS));
};

const FinancingErrorButtonHandlers = (
  props: FinancingErrorButtonBuilderHandlersType
): FinancingErrorButtonBuilderReturnHandlersType => ({
  handleNavigateToTransactionsViewButtonClick: () => navigateToTransactionsViewButtonClick(props),
});

export default FinancingErrorButtonHandlers;
