// Types
import { IncreaseLimitHybridMethodsHandlersType } from './types/increase-limit-hybrid.handlers.type';
import { SetStateType } from '@openbank/cf-ui-static-data';
// Utilities
import {
  generateNativeCustomSchema,
  isBrowserOpenedFromIncreaseLimitMobileFlow,
} from './utils/increase-limit-hybrid.handlers.utils';

const detectBrowserHybridIncreaseLimitFlowEventHandler = (
  setFetching: SetStateType<boolean>
): void => {
  if (isBrowserOpenedFromIncreaseLimitMobileFlow()) {
    window.location.replace(generateNativeCustomSchema());
    setTimeout(() => {
      setFetching(false);
    }, 20000);
  } else {
    setFetching(false);
  }
};

const IncreaseLimitHybridHandlers = (
  setFetching: SetStateType<boolean>
): IncreaseLimitHybridMethodsHandlersType => ({
  handleDetectBrowserHybridIncreaseLimitFlowEvent: () =>
    detectBrowserHybridIncreaseLimitFlowEventHandler(setFetching),
});

export { IncreaseLimitHybridHandlers };
