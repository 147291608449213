// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalEmailLogoutModalTrackingHandlersType } from 'containers/personal/views/email/components/logout-modal/handlers/types/personal-email-logout-modal.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const openLogOffModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'logOffModalOpen',
  },
  type: TrackingEventsEnum.LINK,
});

const logoutFromChangeEmailButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'logOut',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalEmailLogoutModalTrackingHandlers = (
  track: TrackBuilderType
): PersonalEmailLogoutModalTrackingHandlersType => ({
  handleOpenLogOffModalTracking: () => track(openLogOffModalTrackingHandler()),
  handleLogoutFromChangeEmailButtonClickTracking: () =>
    track(logoutFromChangeEmailButtonClickTrackingHandler()),
});

export default PersonalEmailLogoutModalTrackingHandlers;
