// Constants
import { OPTIONS_STRINGS } from 'containers/transactions/views/report/components/defective/components/additional-info/constants/report-defective-additional-info.constants';
// Types
import {
  ReportDefectiveAdditionalInfoHandlerPropsType,
  ReportDefectiveAdditionalInfoHandlerReturnType,
} from './types/report-defective-additional-info.handlers.type';

const handleOption = ({ setSelectedOption, setData, value }): void => {
  setSelectedOption(value);
  setData(prevState => ({
    ...prevState,
    defectiveProductService: {
      ...prevState.defectiveProductService,
      defectProductDateReturn: undefined,
      defectProductReturnProductCommerce: value === OPTIONS_STRINGS.YES_OPTION,
    },
  }));
};

const ReportDefectiveAdditionalInfoHandler = (
  props: ReportDefectiveAdditionalInfoHandlerPropsType
): ReportDefectiveAdditionalInfoHandlerReturnType => ({
  handleSelectedOption: (value: string) => handleOption({ ...props, value }),
});

export { ReportDefectiveAdditionalInfoHandler };
