// Vendors
import React from 'react';
// Configurations
import { getChallengeConfiguration } from './configurations/personal-number-step4.challenge.configurations';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import { ExpiredSignatureComponent } from 'containers/personal/components/expired-signature-message/expired-signature-message.component';
// Hooks
import PersonalNumberStep4ChallengeHook from './hooks/personal-number-step4-challenge.hook';
// Styled
import { PersonalEmailStep4ChallengeComponentStyled } from './personal-number-step4-challenge.component.styled';

const PersonalNumberStep4ChallengeComponent = (): React.ReactElement => {
  const {
    challengeError,
    customerId,
    newPrefix,
    newPhoneNumber,
    signatureExpired,
    withEmail,
    phoneId,
    handleChallengeRetryButtonClick,
    handleManageErrorOnChangePhoneEvent,
    handleManageSuccessOnChangePhoneEvent,
  } = PersonalNumberStep4ChallengeHook();

  return (
    <PersonalEmailStep4ChallengeComponentStyled>
      { signatureExpired && <ExpiredSignatureComponent /> }
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          customerId,
          challengeError,
          newPrefix,
          newPhoneNumber,
          withEmail,
          handleChallengeError: handleManageErrorOnChangePhoneEvent,
          handleChallengeSuccess: handleManageSuccessOnChangePhoneEvent,
          handleChallengeRetryButtonClick,
          id: phoneId,
        })}
      />
    </PersonalEmailStep4ChallengeComponentStyled>
  );
};

export default PersonalNumberStep4ChallengeComponent;
