// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalEmailStep2BuilderReturnTrackingHandlersType } from './types/personal-email-step2-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const requestNewEmailOtpLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'requestNewEmailOtpCode',
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalEmailCancelTrackingHandlers = (
  track: TrackBuilderType
): PersonalEmailStep2BuilderReturnTrackingHandlersType => ({
  handleRequestNewEmailOtpLinkClickTracking: () =>
    track(requestNewEmailOtpLinkClickTrackingHandler()),
});

export default PersonalEmailCancelTrackingHandlers;
