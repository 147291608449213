// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Configurations
import { getWizardLinkConfiguration } from '../../../configurations/security-keys-access-key-change.configurations';
// Constants
import { WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS } from 'constants/wizard/wizard.constants';
// Hooks
import SecurityKeysAccessKeyChangeThirdStepActionsHook from './hook/security-keys-access-key-change-third-step-actions.hook';
// Translations
import translations from './translations/security-keys-access-key-change-third-step.translations';

const SecurityKeysAccessKeyChangeStep3ActionsComponent = (): React.ReactElement => {
  const {
    handleFinishActionsEvent,
    isDisabledLastStep,
    accessKeyExpired,
    handleMakeUserLogoutEvent,
  } = SecurityKeysAccessKeyChangeThirdStepActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS}
      {...getWizardLinkConfiguration({ accessKeyExpired, handleMakeUserLogoutEvent })}
      rightButtonConfiguration={{
        onClick: handleFinishActionsEvent,
        disabled: isDisabledLastStep,
        children: <FormattedMessageComponent id={translations.NEXT_FINAL_STEP} />,
      }}
    />
  );
};

export default SecurityKeysAccessKeyChangeStep3ActionsComponent;
