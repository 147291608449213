// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { NOT_FOUND_BUTTON_TRACKING_PROPS } from './constants/not-found.handlers.tracking.constants';
// Types
import { NotFoundHandlersMethodsTrackingType } from 'containers/not-found/handlers/types/not-found.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const getGoBackFrom404ViewButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...NOT_FOUND_BUTTON_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const NotFoundTrackingHandlers = (
  track: TrackBuilderType
): NotFoundHandlersMethodsTrackingType => ({
  handleGoBackFrom404ViewButtonClickTracking: () =>
    track(getGoBackFrom404ViewButtonClickTrackingHandler()),
});

export { NotFoundTrackingHandlers };
