const DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_DELETE_MODAL =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.first.address.deleteModal.';
const DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_DELETE_MODAL_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.first.address.deleteModal.buttons.';

const TITLE = `${DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_DELETE_MODAL}title`;

const DELETE_BUTTON = `${DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_DELETE_MODAL_BUTTONS}delete`;

const CANCEL_BUTTON = `${DOMAIN_BLOCK_CARD_FIRST_STEP_ADDRESS_DELETE_MODAL_BUTTONS}cancel`;

export { CANCEL_BUTTON, DELETE_BUTTON, TITLE };
