// Vendors
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Services
import { setLaboralEconomicDataService } from '../services/step-2-set-laboral-economic-data.services';
// Types
import { SetLaboralEconomicDataPropsType } from './types/step-2-set-laboral-economic-data-props.resolvers.type';
import { SetLaboralEconomicDataReturnType } from './types/step-2-set-laboral-economic-data-return.resolvers.type';

export const setLaboralEconomicDataResolver = async ({
  caseId,
  personalData,
}: SetLaboralEconomicDataPropsType): Promise<
  [SetLaboralEconomicDataReturnType | null, boolean]
> => {
  const [response, { status }] = await setLaboralEconomicDataService({
    caseId,
    personalData,
  });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};
