// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { DOCUMENTS_SERVICE_CONFIGURATION } from './constants/documentation.service.constants';
// Types
import { type DocumentsTableApiReturnType } from '../types/documents-table-api-return.type';
import { type DocumentationServicePropsType } from './types/documentation.service.type';

export const getDocumentInformationService = async ({
  documentId,
}: DocumentationServicePropsType): Promise<
  [DocumentsTableApiReturnType | Record<string, any>, Response]
> => apiUtilsCb.get({ ...DOCUMENTS_SERVICE_CONFIGURATION, pathParams: { id: documentId } });
