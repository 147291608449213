// Vendors
import { sessionUtils } from '@openbank/cf-ui-architecture-core';
// Enumerations
import { TimerEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { logoutResolver } from 'resolvers/logout.resolvers';
import { refreshTokenResolver } from 'resolvers/refresh-token.resolvers';
// Types
import {
  InactivityModalBuilderHandlersType,
  InactivityModalMethodsHandlersType,
} from './types/inactivity-modal.handlers.type';
// Utilities
import { clearSessionData } from 'utils/session/session.utils';
import { getEnvironmentDomain } from 'utils/app.utils';

const keepSessionAliveButtonClickHandler = async ({
  handleKeepSessionAliveButtonClickTracking,
  handleObserveRefreshValidityEvent,
  handleSendToMobileAccessToken,
  isHybrid,
  setShowModal,
  setSeconds,
}: Omit<
  InactivityModalBuilderHandlersType,
  'handleKillUserSessionAndExitLinkClickTracking' | 'seconds'
>): Promise<void> => {
  handleKeepSessionAliveButtonClickTracking();

  const [response, error] = await refreshTokenResolver();

  if (error) {
    clearSessionData();
  } else {
    sessionUtils.saveSessionData({ ...response, domain: getEnvironmentDomain() });
    isHybrid && handleSendToMobileAccessToken();
    setSeconds(-1);
    handleObserveRefreshValidityEvent();
  }
  setShowModal(false);
};

const killUserSessionAndExitClickHandler = async ({
  handleKillUserSessionAndExitLinkClickTracking,
  setShowModal,
  setSeconds,
}: Pick<
  InactivityModalBuilderHandlersType,
  'handleKillUserSessionAndExitLinkClickTracking' | 'setShowModal' | 'setSeconds'
>): Promise<void> => {
  handleKillUserSessionAndExitLinkClickTracking();
  await logoutResolver();
  setShowModal(false);
  setSeconds(-1);
  clearSessionData();
};

const updateKillSessionSeconds =
  (setShowModal: InactivityModalBuilderHandlersType['setShowModal']) =>
  (prevSeconds: number): number => {
    const currentSeconds = prevSeconds - 1;

    if (currentSeconds === 0) {
      setShowModal(false);
      clearSessionData();
    }

    return currentSeconds;
  };

const manageSessionExpirationTimeEventHandler = ({
  seconds,
  setShowModal,
  setSeconds,
}: Pick<InactivityModalBuilderHandlersType, 'seconds' | 'setSeconds' | 'setShowModal'>): void => {
  if (seconds >= 0) {
    setTimeout(() => {
      setSeconds(updateKillSessionSeconds(setShowModal));
    }, TimerEnum.TIMEOUT_ONE_SECOND);
  }
};

const InactivityModalHandlers = ({
  handleKillUserSessionAndExitLinkClickTracking,
  handleKeepSessionAliveButtonClickTracking,
  handleObserveRefreshValidityEvent,
  handleSendToMobileAccessToken,
  isHybrid,
  seconds,
  setSeconds,
  setShowModal,
}: InactivityModalBuilderHandlersType): InactivityModalMethodsHandlersType => ({
  handleKeepSessionAliveButtonClick: () =>
    keepSessionAliveButtonClickHandler({
      handleKeepSessionAliveButtonClickTracking,
      handleObserveRefreshValidityEvent,
      handleSendToMobileAccessToken,
      isHybrid,
      setShowModal,
      setSeconds,
    }),
  handleKillUserSessionAndExitLinkClick: () =>
    killUserSessionAndExitClickHandler({
      handleKillUserSessionAndExitLinkClickTracking,
      setShowModal,
      setSeconds,
    }),
  handleManageModalCounterSecondsEvent: () =>
    manageSessionExpirationTimeEventHandler({
      seconds,
      setShowModal,
      setSeconds,
    }),
});

export { InactivityModalHandlers };
