// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Constants
import { STEPS } from './constants/transactions-report-payment.constants';
// Contexts
import { TransactionsReportPaymentContextConsumerHOC } from './contexts/transactions-report-payment.context';

const TransactionsReportPaymentBaseComponent = (): React.ReactElement => (
  <TransactionsReportWizardComponent steps={STEPS} />
);

const TransactionsReportPaymentComponent = TransactionsReportPaymentContextConsumerHOC(
  TransactionsReportPaymentBaseComponent
);

export { TransactionsReportPaymentComponent };
