export const TIPS_FOR_UPLOADING_ASSET_CONSTANTS = {
  SIZE_TIP: {
    assetConfiguration: {
      id: 'see',
      type: 'icon',
      size: 32,
      fill: { default: 'backgroundInfoA', inverted: 'backgroundInfoC' },
    },
  },
  FORMAT_TIP: {
    assetConfiguration: {
      id: 'document',
      size: 32,
      fill: { default: 'backgroundInfoA', inverted: 'backgroundInfoC' },
    },
  },
  IMAGE_TIP: {
    assetConfiguration: {
      id: 'id-card',
      size: 32,
      fill: { default: 'backgroundInfoA', inverted: 'backgroundInfoC' },
    },
  },
} as const;
