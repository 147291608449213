const DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING_FEE =
  'cobranded.services.userSettings.privateArea.financing.paymentMethod.first.revolving.fee.';

const FIXED_FEE_LABEL = `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING_FEE}fixed`;

const VARIABLE_FEE_LABEL = `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING_FEE}variable`;

const MIN_LABEL = `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING_FEE}min`;

const MAX_LABEL = `${DOMAIN_PAYMENT_METHOD_FIRST_STEP_RESOLVING_FEE}max`;

export { FIXED_FEE_LABEL, MAX_LABEL, MIN_LABEL, VARIABLE_FEE_LABEL };
