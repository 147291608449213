// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingFastCashCurrentAccountBoxHookType } from './types/current-account-box.hook.type';
// Utils
import { getFormattedAccountNumber } from 'containers/financing/views/fast-cash/utils/financing-fast-cash.utils';

const FinancingFastCashCurrentAccountBoxHook = (): FinancingFastCashCurrentAccountBoxHookType => {
  const { formatMessage } = useCbIntl();

  const { currentCard } = AppContextConsumerHook(),
    { primaryAccountId } = currentCard || {};

  return {
    formatMessage,
    accountNumber: getFormattedAccountNumber(primaryAccountId),
  };
};

export default FinancingFastCashCurrentAccountBoxHook;
