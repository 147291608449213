// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/dashboard-last-session.service.constants';
// Types
import { LastSessionType } from 'types/last-session.type';

const getLastTimeUserSessionService = async (): Promise<
  [LastSessionType | Record<string, any>, Response]
> => apiUtilsCb.post(constants.LAST_SESSION_CONFIGURATION_SERVICE);

export { getLastTimeUserSessionService };
