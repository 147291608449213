// Services
import { createTransactionEppPlanService } from '../services/transactions-epp-step2.service';
// Types
import { TransactionsEppStep2ApiBuilderType } from '../types/transactions-epp-step2-api-builder.type';

const createTransactionEppPlanResolver = async (
  params: TransactionsEppStep2ApiBuilderType
): Promise<[boolean] | [null, boolean]> => {
  const [response] = await createTransactionEppPlanService(params);

  return response?.eppId ? [true] : [null, true];
};

export { createTransactionEppPlanResolver };
