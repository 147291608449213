// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/login-email.services.constants';
// Types
import { LoginEmailApiParamsType } from '../types/login-email-api-params.type';

const validateEmailFormatService = async (
  email: string
): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...constants.VALIDATE_USERNAME_SERVICE_CONFIGURATION,
    params: { email, ...constants.VALIDATE_USERNAME_SERVICE_PARAMS_CONFIGURATION },
  });

export { validateEmailFormatService };
