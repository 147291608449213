// Vendors
import { lazy } from 'react';

const TransactionsReportLandingComponent = lazy(() =>
  import('../../landing/transactions-report-landing.component').then(module => ({
    default: module.TransactionsReportLandingComponent,
  }))
);
const TransactionsReportNotReceivedComponent = lazy(() =>
  import('../../not-received/transactions-report-not-received.component').then(module => ({
    default: module.TransactionsReportNotReceivedComponent,
  }))
);
const TransactionsReportServiceCancelledComponent = lazy(() =>
  import('../../service-cancelled/transactions-report-service-cancelled.component').then(
    module => ({
      default: module.TransactionsReportServiceCancelledComponent,
    })
  )
);
const ReportDefectiveContainer = lazy(() =>
  import('../../defective/report-defective.container').then(module => ({
    default: module.ReportDefectiveContainer,
  }))
);
const ReportSubscriptionAlreadyCancelledComponent = lazy(() =>
  import(
    '../../subscription-already-cancelled/report-subscription-already-cancelled.component'
  ).then(module => ({
    default: module.ReportSubscriptionAlreadyCancelledComponent,
  }))
);
const TransactionsReportDuplicateChargeComponent = lazy(() =>
  import('../../duplicate-charge/transactions-report-duplicate-charge.component').then(module => ({
    default: module.TransactionsReportDuplicateChargeComponent,
  }))
);
const TransactionsReportDifferentAmountAtmComponent = lazy(() =>
  import('../../different-amount-atm/transactions-report-different-amount-atm.component').then(
    module => ({
      default: module.TransactionsReportDifferentAmountAtmComponent,
    })
  )
);
const TransactionsReportPaymentComponent = lazy(() =>
  import('../../payment/transactions-report-payment.component').then(module => ({
    default: module.TransactionsReportPaymentComponent,
  }))
);
const TransactionsReportFraudulentMovementComponent = lazy(() =>
  import('../../fraudulent-movement/transactions-report-fraudulent-movement.component').then(
    module => ({
      default: module.TransactionsReportFraudulentMovementComponent,
    })
  )
);

export {
  ReportDefectiveContainer,
  ReportSubscriptionAlreadyCancelledComponent,
  TransactionsReportDifferentAmountAtmComponent,
  TransactionsReportDuplicateChargeComponent,
  TransactionsReportFraudulentMovementComponent,
  TransactionsReportLandingComponent,
  TransactionsReportNotReceivedComponent,
  TransactionsReportPaymentComponent,
  TransactionsReportServiceCancelledComponent,
};
