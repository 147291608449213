export default {
  SETTINGS_CARD_CONTROL_OPERATIONS_DESCRIPTION_PROPS: {
    testId: 'settings-card-control-operations-table-row-description',
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_COMMON_ASSET_PROPS: {
    colors: {
      fill: 'brandB',
    },
    sizes: {
      height: 32,
      width: 32,
    },
  },
};
