// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const AmortizationModalTitleStyled = styled.div`
  align-items: baseline;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  justify-content: flex-start;
  padding: ${convertPxsToRems({ pixels: 8, base: 14 })} ${convertPxsToRems({ pixels: 16, base: 14 })};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: row;
    flex-grow: 0;
    gap: 8px;
    justify-content: flex-start;
    width: 100%;
  }
`;

const FirstSection = styled.div`
  align-items: basalislne;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXxxs};
  }
`;

export const FormatDateStyled = styled.div`
  flex-grow: 0;
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyB};
  font-size: ${({ theme }) => theme.fontSizes.fontSizeP};
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: right;
  width: 100%;
`;
const InstallmentsDetailStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const InstallmentsStyled = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fontFamilies.fontFamilyB};
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingXxs} ${({ theme }) => theme.spaces.spacingS};
`;

export { AmortizationModalTitleStyled, FirstSection, InstallmentsDetailStyled, InstallmentsStyled };
