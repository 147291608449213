// Vendors
import React from 'react';
// Components
import { AmortizationModalComponent } from './components/amortizationModal/amortization-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent, ModalComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_LANDING_ROW_DETAILS_AMORTIZATION_TABLE_LINK_PROPS,
  AMMORTIZATION_MODAL_CONSTANTS,
} from './constants/financing-landing-row-details-amortization-table.constants';
// Hooks
import { FinancingLandingRowDetailsHook } from './hooks/financing-landing-row-details-amortization-table.component.hook';
// Translations
import { AMMORTIZATION_MODAL_CLOSE_BUTTON } from 'containers/transactions/views/epp/components/modal/translations/transactions-epp-modal.translations';
import translations from './translations/financing-landing-row-details-amortization-table.translations';
// Types
import { FinancingLandingRowDetailsAmortizationTableComponentType } from './types/financing-landing-row-details-amortization-table.component.type';

const FinancingLandingRowDetailsAmortizationTableComponent = ({
  installments,
}: FinancingLandingRowDetailsAmortizationTableComponentType): React.ReactElement => {
  const { isModalOpen, handleOpenModal, handleCloseModal } = FinancingLandingRowDetailsHook();
  return (
    <>
      <LinkComponent
        {...FINANCING_LANDING_ROW_DETAILS_AMORTIZATION_TABLE_LINK_PROPS}
        onClick={handleOpenModal}
      >
        <FormattedMessageComponent id={translations.VIEW_AMORTIZATION} />
      </LinkComponent>
      {isModalOpen && (
        <ModalComponent
          max-width={AMMORTIZATION_MODAL_CONSTANTS.MODAL_MAX_WIDTH}
          onHeaderClose={handleCloseModal}
          primaryButtonConfiguration={{
            children: <FormattedMessageComponent id={AMMORTIZATION_MODAL_CLOSE_BUTTON} />,
            onClick: handleCloseModal,
            testId: AMMORTIZATION_MODAL_CONSTANTS.CLOSE_TEST_ID,
          }}
          title={<FormattedMessageComponent id={translations.AMORTIZATION_TABLE} />}
        >
          <AmortizationModalComponent installments={installments} />
        </ModalComponent>
      )}
    </>
  );
};

export default FinancingLandingRowDetailsAmortizationTableComponent;
