// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/financing-fast-cash.services.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { FastCashCommissionType } from '../types/fast-cash-commission.type';
import { FastCashLimitsType } from '../types/fast-cash-limits.type';
import { GetFastCashCommissionServicePropsType } from './types/get-fast-cash-commission-service-props.type';
import { GetFastCashLimitsServicePropsType } from './types/get-fast-cash-limits-service-props.type';
import { SaveFastCashServicePropsType } from './types/save-fast-cash-service-props.type';

const getFastCashCommissionService = async ({
  cardId,
}: GetFastCashCommissionServicePropsType): Promise<[FastCashCommissionType | null, Response]> =>
  apiUtilsCb.get({
    endpoint: constants.COMMISSION_ENDPOINT,
    pathParams: { cardId },
  });

const getFastCashLimitsService = async ({
  cardId,
}: GetFastCashLimitsServicePropsType): Promise<[FastCashLimitsType | null, Response]> =>
  apiUtilsCb.get({
    endpoint: constants.LIMITS_ENDPOINT,
    pathParams: { cardId },
  });

const saveFastCashService = async ({
  accountId,
  amount,
  cardId,
  progressId,
}: SaveFastCashServicePropsType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    endpoint: constants.SAVE_FAST_CASH_ENDPOINT,
    params: { accountId, amount },
    pathParams: { cardId },
    customHeaders: progressId ? { progressId } : {},
  });

export { getFastCashCommissionService, getFastCashLimitsService, saveFastCashService };
