// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingFastCashContextConsumerHook } from 'containers/financing/views/fast-cash/contexts/financing-fast-cash.context';
// Types
import { VerifyCorrectDataHookType } from './types/verify-correct-data.hook.type';
// Utils
import {
  getCommission,
  getFormattedAccountNumber,
  getTotalAmount,
  getTotalCommission,
} from 'containers/financing/views/fast-cash/utils/financing-fast-cash.utils';

const VerifyCorrectDataHook = (): VerifyCorrectDataHookType => {
  const { formatNumber } = useCbIntl();
  const { amountValue, fastCashCommission } = FinancingFastCashContextConsumerHook();
  const { currentCard } = AppContextConsumerHook(),
    { primaryAccountId } = currentCard || {};

  return {
    accountNumber: getFormattedAccountNumber(primaryAccountId),
    amountValue: getTotalAmount({
      showValues: true,
      formatNumber,
      amountValue,
      currency: fastCashCommission?.currency,
    }),
    commission: getCommission({
      showValues: true,
      formatNumber,
      commission: fastCashCommission?.value,
    }),
    totalCommission: getTotalCommission({
      showValues: true,
      formatNumber,
      amountValue,
      commission: fastCashCommission?.value,
      currency: fastCashCommission?.currency,
    }),
  };
};

export default VerifyCorrectDataHook;
