// Vendors
import React from 'react';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Utilities
import { modifyEmailStep1NextButtonIsDisabled } from './utils/personal-email-step1-actions.utils';

const PersonalEmailStep1ActionsComponent = (): React.ReactElement => {
  const { newEmail, repeatEmail } = PersonalEmailContextConsumerHook();

  return (
    <WizardStepFooterComponent
      targets={FIRST_STEP_TARGETS}
      rightButtonConfiguration={{
        disabled: modifyEmailStep1NextButtonIsDisabled({ newEmail, repeatEmail }),
      }}
    />
  );
};

export default PersonalEmailStep1ActionsComponent;
