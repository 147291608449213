// Vendors
import React from 'react';
// Components
import { UploadDocumentComponent } from './components/upload-document/upload-document.component';
// Constants
import { UPLOAD_DOCUMENT_MORE_FILES_PROPS } from './constants/upload-document-group.constants';
// Translations
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'translations/upload-document.translations';
// Styles
import { UploadDocumentGroupComponentStyled } from './upload-document-group.component.styled';
// Types
import { UploadDocumentGroupComponentType } from './types/upload-document-group.component.type';
// Utils
import { hasEmptyDocuments } from './utils/upload-document-group.utils';

export const UploadDocumentGroupComponent = ({
  files,
  limit = 4,
  onChangeFiles,
  reRequestFiles,
  translations,
  updateFilesOnDB,
}: UploadDocumentGroupComponentType): React.ReactElement => (
  <UploadDocumentGroupComponentStyled>
    {files.map(({ id, ...rest }) => (
      <UploadDocumentComponent
        {...{
          ...rest,
          id,
          onChangeFiles,
          updateFilesOnDB,
          reRequestFiles,
        }}
        key={id}
        translations={{ ...UPLOAD_DOCUMENT_TRANSLATIONS, ...translations }}
      />
    ))}
    {files.length < limit && (
      <UploadDocumentComponent
        {...UPLOAD_DOCUMENT_MORE_FILES_PROPS}
        onChangeFiles={onChangeFiles}
        translations={{ ...UPLOAD_DOCUMENT_TRANSLATIONS, ...translations }}
        isDisabled={hasEmptyDocuments(files)}
        reRequestFiles={reRequestFiles}
        updateFilesOnDB={updateFilesOnDB}
      />
    )}
  </UploadDocumentGroupComponentStyled>
);
