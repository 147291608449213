// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import { LinkComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import {
  ORDER_MODAL_DESKTOP_CONSTANTS,
  ORDER_DESKTOP_LINK_CONSTANTS,
  ORDER_DESKTOP_TABLE_DETAILS_HEADER,
  ORDER_DESKTOP_TABLE_DETAILS_BODY,
} from './constants/order-modal-desktop.component.constants';
// Hooks
import { OrderModalHooks } from '../../hooks/order-modal.hook';
// Styles
import {
  FinancingModalComponentStyled,
  FinancingModalStyled,
  InstallmentsStyled,
  ParagraphCenteredComponentStyled,
  ParagraphAlignEndComponentStyled,
} from './order-modal-desktop.component.styled';
// Translations
import {
  DEALER_LABEL,
  DEALER_NAME,
  ORDER_NUMBER,
  PURCHASE_DATE,
} from '../../transalations/order-modal.translations';
// Types
import { OrderModalComponentProps } from '../../types/order-modal.component.types';

const OrderModalDesktopComponent = ({
  orders,
  urlAmazon,
  transactionInfo: { date },
}: OrderModalComponentProps): React.ReactElement => {
  const { handleViewDetailLink } = OrderModalHooks(urlAmazon);

  return (
    <FinancingModalStyled {...ORDER_MODAL_DESKTOP_CONSTANTS}>
      <FinancingModalComponentStyled {...ORDER_DESKTOP_TABLE_DETAILS_HEADER}>
        <FormattedMessageComponent id={PURCHASE_DATE} />
        <FormattedMessageComponent id={DEALER_LABEL} />
        <FormattedMessageComponent id={ORDER_NUMBER} />
      </FinancingModalComponentStyled>
      <InstallmentsStyled {...ORDER_DESKTOP_TABLE_DETAILS_BODY}>
        <ParagraphComponent>
          <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM} value={date} />
        </ParagraphComponent>
        <ParagraphCenteredComponentStyled>
          <FormattedMessageComponent id={DEALER_NAME} />
        </ParagraphCenteredComponentStyled>
        <ParagraphAlignEndComponentStyled>
          {orders?.map(order => (
            <LinkComponent
              {...ORDER_DESKTOP_LINK_CONSTANTS}
              onClick={() => handleViewDetailLink(order)}
              key={order}
            >
              {order}
            </LinkComponent>
          ))}
        </ParagraphAlignEndComponentStyled>
      </InstallmentsStyled>
    </FinancingModalStyled>
  );
};

export { OrderModalDesktopComponent };
