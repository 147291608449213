//  Vendors
import React, { Fragment } from 'react';
// Components
import { ParagraphComponent, LineSeparatorComponent, LinkComponent } from '@openbank/cb-ui-commons';
import ConsentCheckerComponent from '../consent-checker/consent-checker.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { LINE_SEPARATOR_PROPS } from './constants/profile-consents-step1.constants';
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import useProfileConsentsStep1Hook from './hooks/profile-consents-step1.hook';
// Styles
import {
  ConsentsStep1BodyWrapper,
  ConsentsStep1HeaderWrapper,
} from './profile-consents-step1.component.styled';
// Translations
import {
  HEADER_DESCRIPTIONS,
  STEP_DESCRIPTION1,
  STEP_DESCRIPTION2,
  TOGGLE_DESCRIPTIONS,
} from './translations/profile-consents-step1.translations';
// Utilities
import { showSeparator } from './utils/profile-consents-step1.utils';
import { isSameObject } from 'utils/app.utils';

const ProfileConsentsStep1Component = (): React.ReactElement | null => {
  const {
    consents,
    formatMessage,
    handleChangeSubconsent,
    handleToggleConsent,
    initialConsents,
    privacyPolicyUrl,
  } = useProfileConsentsStep1Hook();

  return consents ? (
    <>
      <ConsentsStep1HeaderWrapper>
        <ParagraphComponent>
          <FormattedMessageComponent id={STEP_DESCRIPTION1} />
        </ParagraphComponent>
        <ParagraphComponent>
          <FormattedMessageComponent id={STEP_DESCRIPTION2} />
        </ParagraphComponent>
      </ConsentsStep1HeaderWrapper>
      <ConsentsStep1BodyWrapper>
        {consents.map((consent, index) => (
          <Fragment key={consent.id}>
            <ConsentCheckerComponent
              consents={consent.subConsents}
              headerDescription={formatMessage(
                { id: HEADER_DESCRIPTIONS[consent.id] },
                {
                  contractSignatureLink: (chunks: React.ReactNode) => (
                    <LinkComponent href={privacyPolicyUrl}>{chunks}</LinkComponent>
                  ),
                }
              )}
              onChangeSubconsent={handleChangeSubconsent}
              showConsents={consent.showConsents}
              toggleConfiguration={{
                description: formatMessage({ id: TOGGLE_DESCRIPTIONS[consent.id] }),
                onChange: () => handleToggleConsent(index),
              }}
            />
            {showSeparator(index, consents.length) ? (
              <LineSeparatorComponent {...LINE_SEPARATOR_PROPS} />
            ) : null}
          </Fragment>
        ))}
      </ConsentsStep1BodyWrapper>
      <WizardStepFooterComponent
        rightButtonConfiguration={{ disabled: isSameObject(consents, initialConsents) }}
        targets={FIRST_STEP_TARGETS}
      />
    </>
  ) : null;
};

export default ProfileConsentsStep1Component;
