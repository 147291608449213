// Services
import { fetchCardsStatementsService } from '../services/card-settings-statements.service';
// Types
import { CardSettingsStatementsApiResponseType } from '../types/card-settings-statements-api-response.type';
// Utils
import { isValidTransactionsApiContract } from 'utils/transactions-api-contract.utils';

const fetchCardsStatementsResolver = async (
  cardContractId: string
): Promise<[CardSettingsStatementsApiResponseType] | [null, number]> => {
  const [response, { status }] = await fetchCardsStatementsService(cardContractId);

  return isValidTransactionsApiContract(response)
    ? [response as CardSettingsStatementsApiResponseType]
    : [null, status];
};

export { fetchCardsStatementsResolver };
