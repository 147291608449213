// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
import { paragraphConstants } from '@openbank/cb-ui-commons';

const TRAVEL_PLUS_COST_LAYOUT_CONTENT_PROPS = {
    align: 'center',
    gap: AmazonTheme.default.spaces.spacingXxxxs,
  },
  TRAVEL_PLUS_COST_DESCRIPTION_01_PROPS = {
    testId: 'travel-plus-cost-description-01',
  },
  TRAVEL_PLUS_COST_DESCRIPTION_02_PROPS = {
    testId: 'travel-plus-cost-description-02',
  },
  TRAVEL_PLUS_COST_PRICE_01_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.XL,
    testId: 'travel-plus-cost-price-01',
  },
  TRAVEL_PLUS_COST_PRICE_02_PROPS = {
    bold: true,
    testId: 'travel-plus-cost-price-02',
    size: paragraphConstants.SIZES.S,
  };

export {
  TRAVEL_PLUS_COST_DESCRIPTION_01_PROPS,
  TRAVEL_PLUS_COST_DESCRIPTION_02_PROPS,
  TRAVEL_PLUS_COST_LAYOUT_CONTENT_PROPS,
  TRAVEL_PLUS_COST_PRICE_01_PROPS,
  TRAVEL_PLUS_COST_PRICE_02_PROPS,
};
