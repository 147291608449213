// Vendors
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Event handlers
import WizardStepFooterCancelModalHandlers from '../handlers/wizard-step-footer-cancel-modal.handlers';
import WizardStepFooterCancelModalTrackingHandlers from '../handlers/wizard-step-footer-cancel-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { WizardStepFooterCancelModalBuilderReturnHandlersType } from '../handlers/types/wizard-step-footer-cancel-modal-builder-return.handlers.type';
import { WizardStepFooterCancelModalComponentType } from '../types/wizard-step-footer-cancel-modal.component.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const useWizardStepFooterCancelModalHook = (
  props: WizardStepFooterCancelModalComponentType
): WizardStepFooterCancelModalBuilderReturnHandlersType => {
  const { handleOpenCancelWizardStepFooterModalTracking, ...rest } = AppTrackingHook(
    WizardStepFooterCancelModalTrackingHandlers
  );

  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    props.showModal && handleOpenCancelWizardStepFooterModalTracking();
  }, [props.showModal]);

  return WizardStepFooterCancelModalHandlers({
    ...rest,
    ...props,
    navigate: useNavigate(),
    onNavigateHybridFlow,
  });
};

export default useWizardStepFooterCancelModalHook;
