// Vendors
import React from 'react';
import { MessageComponent, ResumeItemComponent } from '@openbank/cb-ui-commons';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import RequestPortabilityStep3ActionsComponent from './components/actions/request-portability-step3-actions.component';
import RequestPortabilityStep3ChallengeComponent from './components/challenge/request-portability-step3-challenge.component';
// Constants
import constants from './constants/request-portability-step3.constants';
// Styles
import {
  RequestPortabilityStep3ComponentStyled,
  RequestPortabilityStep3MessageItemStyled,
  RequestPortabilityStep3MessageStyled,
} from './request-portability-step3.component.styled';
// Translations
import translations from './translations/request-portability-step3.translations';

const RequestPortabilityStep3Component = (): React.ReactElement => (
  <RequestPortabilityStep3ComponentStyled>
    <MessageComponent {...constants.REQUEST_PORTABILITY_STEP_3_MESSAGE_PROPS}>
      <RequestPortabilityStep3MessageStyled>
        <RequestPortabilityStep3MessageItemStyled>
          <ResumeItemComponent
            {...constants.REQUEST_PORTABILITY_STEP_3_REQUEST_RESUME_ITEM_PROPS}
            label={<FormattedMessageComponent id={translations.REQUESTING_LABEL} />}
            value={<FormattedMessageComponent id={translations.PORTABILITY_RIGHTS_LABEL} />}
          />
        </RequestPortabilityStep3MessageItemStyled>
        <RequestPortabilityStep3MessageItemStyled>
          <ResumeItemComponent
            {...constants.REQUEST_PORTABILITY_STEP_3_COST_RESUME_ITEM_PROPS}
            label={<FormattedMessageComponent id={translations.COST_LABEL} />}
            value={<FormattedMessageComponent id={translations.FREE_LABEL} />}
          />
        </RequestPortabilityStep3MessageItemStyled>
      </RequestPortabilityStep3MessageStyled>
    </MessageComponent>
    <RequestPortabilityStep3ChallengeComponent />
    <RequestPortabilityStep3ActionsComponent />
  </RequestPortabilityStep3ComponentStyled>
);

export default RequestPortabilityStep3Component;
