// Hooks
import useCbIntl from 'hooks/useCbIntl';
import { useReportSubscriptionAlreadyCancelledContextConsumerHook } from 'containers/transactions/views/report/components/subscription-already-cancelled/contexts/report-subscription-already-cancelled.context';
// Types
import { ReportSubscriptionAlreadyCancelledAdditionalInformationHookReturnType } from './types/report-subscription-already-cancelled-additional-info.hook.types';

const ReportSubscriptionAlreadyCancelledAdditionalInformationHook =
  (): ReportSubscriptionAlreadyCancelledAdditionalInformationHookReturnType => {
    const { setCancelledSubDateBefore, cancelledSubDateBefore } =
      useReportSubscriptionAlreadyCancelledContextConsumerHook();
    const { formatMessage } = useCbIntl();

    const existCancellationSubDate = !Object.is(cancelledSubDateBefore, undefined);

    return {
      cancelledSubDateBefore,
      existCancellationSubDate,
      formatMessage,
      setCancelledSubDateBefore,
    };
  };

export { ReportSubscriptionAlreadyCancelledAdditionalInformationHook };
