// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingContextType } from './types/financing.context.type';
import { CardSettingsCheck3dConfirmationContextType } from 'containers/card-settings/views/check-3d/components/confirmation/contexts/types/card-settings-check-3d-confirmation.context.type';
import { CardSettingsCheck3DConfirmationContextProvider } from 'containers/card-settings/views/check-3d/components/confirmation/contexts/card-settings-check-3d-confirmation.context';

const FinancingContext = createContext<FinancingContextType>({
  showModal: false,
  setShowModal: () => undefined,
});

const FinancingContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FinancingContextType>> => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <FinancingContext.Provider value={{ showModal, setShowModal }}>
      {children}
    </FinancingContext.Provider>
  );
};

const useFinancingContextConsumerHook = (): FinancingContextType => useContext(FinancingContext);

/* eslint-disable */
const FinancingContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingContextType>> =>
    (
      <FinancingContextProvider>
        <Component {...props} />
      </FinancingContextProvider>
    );

export { useFinancingContextConsumerHook, FinancingContextConsumerHOC };
