// Contexts
import { useDocumentationContextConsumerHook } from 'containers/documentation/contexts/documentation.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { type DocumentationCategoryListReturnHookType } from './types/documentation-category-list-return.hook.type';

export const useDocumentationCategoryListHook = (): DocumentationCategoryListReturnHookType => {
  const { categorySelected } = useDocumentationContextConsumerHook();

  const { formatMessage } = useCbIntl();

  return {
    categorySelected,
    formatMessage,
  };
};
