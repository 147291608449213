// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  MessageComponent
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WARNING_PROPS } from '../constants/device-modal.constants';
// Enumerations
import { DeviceModalEnum } from '../enumerations/device-modal.enumeration';
// Styled
import { DeviceModalContentBodyStyled } from '../device-modal.component-styled';
// Translations
import {
  CURRENT_DEVICE_ADVICE,
  CURRENT_DEVICE_ADVICE_DESCRIPTION,
  CURRENT_TRUSTED_ADVICE,
  CURRENT_TRUSTED_ADVICE_DESCRIPTION
} from '../translations/device-modal.translations';
// Types
import { DeviceModalBodyComponentType } from './types/device-modal-body.type';

const DeviceModalBodyComponent = ({
  device,
  type
}: DeviceModalBodyComponentType): React.ReactElement => (
  <DeviceModalContentBodyStyled>
  { device.lastSession?.active &&
    <MessageComponent { ...WARNING_PROPS}>
      <ParagraphComponent bold>
        <FormattedMessageComponent id={CURRENT_DEVICE_ADVICE} />
      </ParagraphComponent>
      <ParagraphComponent>
        <FormattedMessageComponent id={CURRENT_DEVICE_ADVICE_DESCRIPTION} />
      </ParagraphComponent>
    </MessageComponent> }

    { device.trusted && type !== DeviceModalEnum.UNBLOCK &&
      <MessageComponent { ...WARNING_PROPS}>
        <ParagraphComponent bold>
          <FormattedMessageComponent id={CURRENT_TRUSTED_ADVICE} />
        </ParagraphComponent>
        <ParagraphComponent>
        <FormattedMessageComponent id={CURRENT_TRUSTED_ADVICE_DESCRIPTION} />
        </ParagraphComponent>
    </MessageComponent> }
  </DeviceModalContentBodyStyled>
);

export { DeviceModalBodyComponent };