// Constants
import {
  APP_AMAZON_MOBILE_SCHEMA,
  HTTPS_WEB_DOMAIN,
  IOS_REGEX,
  MAC_INTEL_PLATFORM,
  QUERY_PARAM_USER_ID,
} from './constants/increase-limit-hybrid.handlers.utils.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utilities
import { isIosWebView, isMobilePlatform } from '@gruposantander/web-ui-framework';

const hasNavigator = (): boolean => typeof window.navigator !== 'undefined';

const isIOSBrowser = (): boolean => {
  if (!hasNavigator()) {
    return false;
  }
  // MacIntel is used to detect for iPad os > 13
  return (
    IOS_REGEX.test(window.navigator.userAgent) ||
    (window.navigator.platform === MAC_INTEL_PLATFORM && window.navigator.maxTouchPoints > 1)
  );
};

const hasUrlAndQueryParamsTINKCallBack = (): boolean =>
  window.location.pathname.includes(RoutesEnumeration.CREDIT_LIMIT_INCREASE) &&
  window.location.search.includes(QUERY_PARAM_USER_ID);

const isBrowserOpenedFromIncreaseLimitMobileFlow = (): boolean =>
  !isIosWebView() && isIOSBrowser() && isMobilePlatform() && hasUrlAndQueryParamsTINKCallBack();

const generateNativeCustomSchema = (): string => {
  const newDomain = window.location.href;

  return newDomain.replace(HTTPS_WEB_DOMAIN, APP_AMAZON_MOBILE_SCHEMA);
};

export { generateNativeCustomSchema, isBrowserOpenedFromIncreaseLimitMobileFlow };
