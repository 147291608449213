const TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_APR_PROPS = {
    testId: 'transactions-epp-step1-simulation-interests-apr',
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_NIR_PROPS = {
    testId: 'transactions-epp-step1-simulation-interests-nir',
  };

export {
  TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_APR_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_NIR_PROPS,
};
