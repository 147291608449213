// Types
import { LabelsLinkCardBuilderReturnHandlersType } from './types/labels-link-card-builder-return.handlers.type';
import { LabelsLinkCardBuilderHandlersType } from './types/labels-link-card-builder.handlers.type';

const labelsLinkCardLinkClickHandler = ({
  handleLabelsLinkCardLinkClickTracking,
  navigate,
  onClick,
  url,
}: LabelsLinkCardBuilderHandlersType): any => {
  handleLabelsLinkCardLinkClickTracking(url);
  if (url) {
    navigate(url);
  } else {
    onClick && onClick();
  }
};

const LabelsLinkCardHandlers = (
  props: LabelsLinkCardBuilderHandlersType
): LabelsLinkCardBuilderReturnHandlersType => ({
  handleLabelsLinkCardLinkClick: () => labelsLinkCardLinkClickHandler(props),
});

export default LabelsLinkCardHandlers;
