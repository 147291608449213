// Vendors
import React from 'react';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { TransactionsReportDifferentAmountAtmConfirmationActionsComponent } from './components/actions/transactions-report-different-amount-atm-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/transactions-report-different-amount-atm-confirmation.constants';
// Hooks
import { useTransactionsReportDifferentAmountAtmConfirmationHook } from './hooks/transactions-report-different-amount-atm-confirmation.hook';
// Styles
import { TransactionsReportDifferentAmountAtmConfirmationComponentStyled } from './transactions-report-different-amount-atm-confirmation.styled';
// Translations
import { CLAIM_TYPE } from './translations/transactions-report-different-amount-atm-confirmation.translations';
// Utilities
import { determineClaimReasonType } from './utils/transactions-report-different-amount-atm-confirmation.utils';

export const TransactionsReportDifferentAmountAtmConfirmationComponent = (): React.ReactElement => {
  const { cardId, formValues, formatMessage, transactionId } =
    useTransactionsReportDifferentAmountAtmConfirmationHook();

  return (
    <TransactionsReportDifferentAmountAtmConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            claimReasonType: determineClaimReasonType({ formValues }),
            receiveDiffAmountThanRequested: formValues,
          },
        }}
      />
      <TransactionsReportDifferentAmountAtmConfirmationActionsComponent />
    </TransactionsReportDifferentAmountAtmConfirmationComponentStyled>
  );
};
