// Translations
import commonTranslations from 'translations/common.translations';
// Types
import { WizardStepsStepConfigurationType } from './types/wizard-steps-step.configuration.type';
import { WizardStepsBuilderConfigurationType } from './types/wizard-steps-builder.configuration.type';
import { WizardStepsConfigurationType } from './types/wizard-steps.configuration.type';

const getWizardStepsStepConfiguration = ({
  formatMessage,
  isTitleMobile,
  steps,
}: Omit<
  WizardStepsBuilderConfigurationType,
  'step' | 'isHybrid'
>): WizardStepsStepConfigurationType[] =>
  steps.map(({ component, description, title }: WizardStepsStepConfigurationType) => ({
    component,
    ...(title && isTitleMobile ? { title: formatMessage({ id: title }) } : {}),
    ...(description && { description: formatMessage({ id: description }) }),
  }));

const getWizardStepsConfiguration = ({
  formatMessage,
  isHybrid,
  isTitleMobile,
  step,
  steps,
}: WizardStepsBuilderConfigurationType): WizardStepsConfigurationType => ({
  isHybrid,
  steps: getWizardStepsStepConfiguration({ formatMessage, isTitleMobile: !isTitleMobile, steps }),
  title: getWizardStepsStepConfiguration({ formatMessage, isTitleMobile: isTitleMobile, steps })[
    step
  ]?.title,
  TRANSLATIONS: { TEXT_SEPARATOR: formatMessage({ id: commonTranslations.WIZARD.TEXT_SEPARATOR }) },
});

export { getWizardStepsConfiguration };
