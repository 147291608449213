// Types
import {
  UploadDocumentsTipsHandlersType,
  UploadDocumentsTipsReturnHandlersType,
} from './types/upload-documents-tips.handlers.type';

const closeModalHandler = ({ setShowModal }: UploadDocumentsTipsHandlersType) => {
  setShowModal(false);
};

const openModalHandler = ({ setShowModal }: UploadDocumentsTipsHandlersType) => {
  setShowModal(true);
};

const UploadDocumentsTipsHandlers = (
  props: UploadDocumentsTipsHandlersType
): UploadDocumentsTipsReturnHandlersType => ({
  handleCloseModalHandler: () => closeModalHandler(props),
  handleOpenModal: () => openModalHandler(props),
});

export default UploadDocumentsTipsHandlers;
