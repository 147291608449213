// Vendors
import React from 'react';
// Components
import { DocumentationContentHeaderComponent } from './components/header/documentation-content-header.component';
import { DocumentsTableComponent } from '../documents-table/documents-table.component';
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import {
  DOCUMENTATION_CATEGORY_EMPTY_PROPS,
  DOCUMENTATION_CATEGORY_ERROR_PROPS,
  DOCUMENTATION_CATEGORY_ERROR_RETRY_PROPS,
} from '../../constants/documentation.constants';
// Hooks
import { useDocumentationDocumentsContentHook } from './hooks/documentation-documents-content.hook';
// Styles
import {
  AppCardSectionComponentStyled,
  AppCardContentSectionComponentStyled,
} from 'styles/app.styled';
// Translations
import { RETRY } from './translations/documentation-documents-content.translations';
// Styles
import { DocumentationDocumentsContainer } from './documentation-documents-content.styled';
import { DocumentationDocumentsContentComponentProps } from './types/documentation-documents-content.type';
import { DocumentationContentContractDataComponent } from './components/contract-data/documentation-content-contract-data.component';
import { DocumentErrorTypeEnumeration } from 'containers/documentation/enumerations/documentation.enumeration';
import { FilterChipsComponent } from 'components/filters/components/filter-chips/filter-chips.component';

export const DocumentationDocumentsContentComponent = ({
  categoryError,
  formValues,
  formValuesParams,
  loading,
  onRetry,
  setCopyOfFormValues,
  setFormValues,
  setFormValuesParams,
  handleClickFilter,
}: DocumentationDocumentsContentComponentProps): React.ReactElement => {
  const {
    categorySelected,
    contractNumber,
    documents,
    documentsError,
    formatMessage,
    isBeneficiary,
    userName,
  } = useDocumentationDocumentsContentHook();

  return (
    <AppCardSectionComponentStyled>
      <AppCardContentSectionComponentStyled>
        <DocumentationDocumentsContainer>
          <FetchErrorComponent
            error={getFetchErrorConfiguration({
              ...DOCUMENTATION_CATEGORY_EMPTY_PROPS,
              error: categoryError === DocumentErrorTypeEnumeration.EMPTY,
              formatMessage,
            })}
          >
            {categorySelected !== '' && (
              <DocumentationContentHeaderComponent {...{ categorySelected, handleClickFilter }} />
            )}
            <FilterChipsComponent
              {...{
                formValues,
                formValuesParams,
                setCopyOfFormValues,
                setFormValues,
                setFormValuesParams,
              }}
            />
            <FetchErrorComponent
              error={getFetchErrorConfiguration({
                ...DOCUMENTATION_CATEGORY_ERROR_PROPS,
                error: documentsError,
                formatMessage,
                buttonConfiguration: {
                  ...DOCUMENTATION_CATEGORY_ERROR_RETRY_PROPS,
                  children: formatMessage({ id: RETRY }),
                  onClick: onRetry,
                },
              })}
              fetching={loading}
            >
              <DocumentationContentContractDataComponent
                {...{
                  category: categorySelected,
                  contractNumber,
                  userName,
                  isBeneficiary,
                }}
              />
              <DocumentsTableComponent documents={documents} />
            </FetchErrorComponent>
          </FetchErrorComponent>
        </DocumentationDocumentsContainer>
      </AppCardContentSectionComponentStyled>
    </AppCardSectionComponentStyled>
  );
};
