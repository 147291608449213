const DOMAIN = 'cobranded.services.userSettings.login.clLogin.error.';

export default {
  BUTTON: `${DOMAIN}tryAgainButton`,
  DESCRIPTION: `${DOMAIN}description`,
  LINK_1: `${DOMAIN}link1`,
  LINK_2: `${DOMAIN}link2`,
  LINK_3: `${DOMAIN}link3`,
  TITLE: `${DOMAIN}title`,
};
