const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbTransactions.createInstallmentsPlan.cbSidebar.';

const DESCRIPTION_01 = `${DOMAIN}cbExpandableRow.description01`,
  DESCRIPTION_02 = `${DOMAIN}cbExpandableRow.description02`,
  TITLE_01 = `${DOMAIN}cbExpandableRow.title01`,
  TITLE_02 = `${DOMAIN}cbExpandableRow.title02`,
  TITLE = `${DOMAIN}title`;

export { DESCRIPTION_01, DESCRIPTION_02, TITLE_01, TITLE_02, TITLE };
