// Translations
import translations from '../translations/personal-situation-credit-limit-increase.component.translations';
// Types
import { InfoPanelContentType } from 'containers/financing/views/credit-limit-increase/components/editable-info-panel/types/info-panel-content.type';
import { PersonalSituationCreditLimitIncreaseConfigPropsType } from './types/personal-situation-credit-limit-increase.configurations.type';

export const getInfoPanelContentConfig = ({
  maritalStatus,
  childrenInHousehold,
  childrenWithMaintenance,
  formatMessage,
}: PersonalSituationCreditLimitIncreaseConfigPropsType): InfoPanelContentType[] => {
  if (!maritalStatus || !childrenInHousehold || !childrenWithMaintenance) return [];
  return [
    {
      title: formatMessage({ id: translations.MARITAL_STATUS }),
      text: formatMessage({ id: translations[maritalStatus] }),
    },
    {
      title: formatMessage({ id: translations.NUMBER_OF_CHILDREN_H }),
      text: childrenInHousehold.toString(),
    },
    {
      title: formatMessage({ id: translations.NUMBER_OF_CHILDREN_P }),
      text: childrenWithMaintenance.toString(),
    },
  ];
};
