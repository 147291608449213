// Constants
import {
  CANCEL_EXPIRED_DOCUMENT_MODAL_LINK_PROPS,
  EXPIRED_DOCUMENT_MODAL_COMPONENT_PROPS,
  EXPIRED_DOCUMENT_MODAL_PRIMARY_BUTTON_PROPS,
} from '../constants/expired-document-modal.constants';
// Translations
import { TITLE } from '../translations/expired-document-modal.translations';
// Types
import { GetModalConfigurationPropsType } from './types/expired-document-modal-props.configuration.type';
import { GetModalConfigurationReturnType } from './types/expired-document-modal-return.configuration.type';

const getModalConfiguration = ({
  formatMessage,
  handleClickCloseModal,
  handleUpdateDocumentNavigation,
}: GetModalConfigurationPropsType): GetModalConfigurationReturnType => {
  return {
    ...EXPIRED_DOCUMENT_MODAL_COMPONENT_PROPS,
    title: formatMessage({ id: TITLE }),
    onHeaderClose: handleClickCloseModal,
    linkConfiguration: {
      ...CANCEL_EXPIRED_DOCUMENT_MODAL_LINK_PROPS,
      onClick: handleClickCloseModal,
    },
    primaryButtonConfiguration: {
      ...EXPIRED_DOCUMENT_MODAL_PRIMARY_BUTTON_PROPS,
      onClick: handleUpdateDocumentNavigation,
    },
  };
};

export { getModalConfiguration };
