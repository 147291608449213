// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Hooks
import { useFinancingInsurancesContractingLPIStep4ChallengeHook } from './hooks/financing-insurances-contracting-lpi-step4-challenge.hook';
import { FinancingInsurancesContractingLPIStep4ChallengeComponentPropsType } from './types/financing-insurances-contracting-lpi-step4-challenge.component.type';

const FinancingInsurancesContractingLPIStep4ChallengeComponent = ({
  setChallenge,
  setChallengeError,
  challengeError,
  postalDocumentation,
}: FinancingInsurancesContractingLPIStep4ChallengeComponentPropsType): React.ReactElement => {
  const { plan, productId, productType, handleChallengeSuccess, handleChallengeRetryButtonClick } =
    useFinancingInsurancesContractingLPIStep4ChallengeHook({
      setChallenge,
      setChallengeError,
    });

  return (
    <ChallengeWrapperComponent
      baseService={'cb-api-insurances/v1/insurances/contracts'}
      baseServiceMethod={'post'}
      baseServiceBuilder={{
        params: {
          insuranceType: 'LPI',
          planCode: plan,
          productContractId: productId,
          productContractType: productType,
          postalDocumentation,
        },
      }}
      challengeError={challengeError}
      onRetry={handleChallengeRetryButtonClick}
      onError={handleChallengeRetryButtonClick}
      onSuccess={handleChallengeSuccess}
    />
  );
};

export { FinancingInsurancesContractingLPIStep4ChallengeComponent };
