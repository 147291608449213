// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingDescriptionLinkLinkTrackingHandlersType } from 'containers/financing/views/ppi/components/contracting/components/description-link/components/link/handlers/types/financing-ppi-contracting-description-link-link.tracking.handlers.type';

const openExternalUrlPPIContractingLinkClickTrackingHandler = (
  url: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: url,
  },
  type: TrackingEventsEnum.LINK,
});

const FinancingPPIContractingDescriptionLinkLinkTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingDescriptionLinkLinkTrackingHandlersType => ({
  handleOpenExternalUrlPPIContractingLinkClickTracking: (url: string) =>
    track(openExternalUrlPPIContractingLinkClickTrackingHandler(url)),
});

export { FinancingPPIContractingDescriptionLinkLinkTrackingHandlers };
