// Enumerations
import { TransactionsStatusCodeEnumeration } from 'enumerations/transactions-status-code.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.transactionStatusCode.';

const TRANSACTIONS_TABLE_STATUS_TRANSLATIONS = {
  [TransactionsStatusCodeEnumeration.AUTHORIZED]: `${DOMAIN}authorized`,
  [TransactionsStatusCodeEnumeration.PENDING_CONSOLIDATION]: `${DOMAIN}settlementPending`,
  [TransactionsStatusCodeEnumeration.LIQUIDATED]: `${DOMAIN}settled`,
};

export { TRANSACTIONS_TABLE_STATUS_TRANSLATIONS };
