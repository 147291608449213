const FINANCING_LANDING_ROW_DETAILS_AMOUNT_PENDING_PROPS = {
  testing: {
    labelId: 'financing-landing-row-details-amortization-table-label',
    valueId: 'financing-landing-row-details-amortization-table-value',
  },
};

const FINANCING_LANDING_ROW_DETAILS_AMORTIZATION_TABLE_LINK_PROPS = {
  testId: 'financing-landing-row-details-amortization-table-link',
};
const AMMORTIZATION_MODAL_CONSTANTS = {
  MODAL_MAX_WIDTH: 600,
  CLOSE_TEST_ID: 'financing-landing-row-details-amortization-table-close-button',
};

export {
  FINANCING_LANDING_ROW_DETAILS_AMOUNT_PENDING_PROPS,
  FINANCING_LANDING_ROW_DETAILS_AMORTIZATION_TABLE_LINK_PROPS,
  AMMORTIZATION_MODAL_CONSTANTS,
};
