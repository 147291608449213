// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const PersonalPhotoAvatarsCheckComponentStyled = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.brandB};
  border: ${convertPxsToRems({ pixels: 2, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundPrimaryB};
  bottom: 0;
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusE};
  display: flex;
  height: ${convertPxsToRems({ pixels: 16, base: 14 })};
  justify-content: center;
  position: absolute;
  right: 0;
  width: ${convertPxsToRems({ pixels: 16, base: 14 })};
  z-index: ${({ theme }) => theme.zIndex.zIndexB};
`;

export { PersonalPhotoAvatarsCheckComponentStyled };
