// Enumerations
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';
// Types
import { FileDataType } from 'types/file-data.type';

export const hasRequiredDocs = (files: FileDataType[]): boolean =>
  files.some(
    ({ documentId, documentType }) =>
      documentId && documentType === DocumentsTypesEnumeration.PROOF_OF_CONTACT
  );
