// Enumerations
import { SimulationResumeItemsEnumeration } from 'containers/transactions/views/epp/enumerations/simulation-resume-items.enumeration';
// Translations
import {
  LOAN_AMOUNT,
  MONTHLY_INSTALLMENT_PAYMENT,
  TOTAL_AMOUNT,
  TOTAL_INTEREST,
} from '../translations/transactions-epp-step2-resume.translations';

const TRANSACTION_EPP_STEP2_RESUME_CONSUMPTION_CONCEPT_PROPS = {
    testId: 'transaction-epp-step2-resume-consumption-concept',
  },
  TRANSACTION_EPP_STEP2_RESUME_TOTAL_INTEREST_PROPS = {
    label: TOTAL_INTEREST,
    id: SimulationResumeItemsEnumeration.TOTAL_INTEREST,
    testId: 'transaction-epp-step2-resume-total-interest',
  },
  TRANSACTION_EPP_STEP2_RESUME_LOAN_AMOUNT_PROPS = {
    label: LOAN_AMOUNT,
    id: SimulationResumeItemsEnumeration.LOAN_AMOUNT,
    testId: 'transaction-epp-step2-resume-loan-amount',
  },
  TRANSACTION_EPP_STEP2_RESUME_TOTAL_AMOUNT_PROPS = {
    label: TOTAL_AMOUNT,
    id: SimulationResumeItemsEnumeration.TOTAL_AMOUNT,
    testId: 'transaction-epp-step2-resume-total-amount',
  },
  TRANSACTION_EPP_STEP2_RESUME_NUMBER_INSTALLMENTS_PROPS = {
    testId: 'transaction-epp-step2-resume-number-installments',
  },
  TRANSACTION_EPP_STEP2_RESUME_MONTHLY_INSTALLMENT_PAYMENT_PROPS = {
    label: MONTHLY_INSTALLMENT_PAYMENT,
    id: SimulationResumeItemsEnumeration.MONTHLY_PAYMENT,
    testId: 'transaction-epp-step2-resume-monthly-installment-payment',
  };

export {
  TRANSACTION_EPP_STEP2_RESUME_CONSUMPTION_CONCEPT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_TOTAL_INTEREST_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_LOAN_AMOUNT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_TOTAL_AMOUNT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_NUMBER_INSTALLMENTS_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_MONTHLY_INSTALLMENT_PAYMENT_PROPS,
};
