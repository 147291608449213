// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from '../formatted-message/formatted-message.component';
// Constants
import { COPY_LINK_PROPS } from 'components/copy-link/constants/copy-link.constants';
// Hooks
import useCopyHook from 'hooks/copy.hook';
// Translations
import { COPY } from './translations/copy-link.translations';
// Types
import { CopyMethodBuilderHandlersType } from 'handlers/types/copy/copy-method-builder.handlers.type';

const CopyLinkComponent = (props: CopyMethodBuilderHandlersType): React.ReactElement => {
  const { handleCopyGenericEvent } = useCopyHook();

  return (
    <LinkComponent {...COPY_LINK_PROPS} onClick={() => handleCopyGenericEvent(props)}>
      <FormattedMessageComponent id={COPY} />
    </LinkComponent>
  );
};

export default CopyLinkComponent;
