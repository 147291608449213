const DASHBOARD_TABLE_LINK_PROPS = {
  assetConfiguration: {
    id: 'chevron_right',
    sizes: {
      height: 16,
      width: 16,
    },
    testId: 'dashboard-all-link-icon',
  },
  testId: 'dashboard-all-link',
};

const MAX_LENGHT_TO_SHOW_VIEW_ALL = 4;

export { DASHBOARD_TABLE_LINK_PROPS, MAX_LENGHT_TO_SHOW_VIEW_ALL };
