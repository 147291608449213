// Enumerations
import { ActivityTypeDeclaredEnumeration } from '../../enumerations/employment-status.enumeration';
// Types
import { Step2Type } from 'containers/financing/views/credit-limit-increase/contexts/types/step-2-credit-limit-increase.context.type';

export const hasActivityTypeDeclaredChangedAndisSalariedCivilServantOrSelftEmployed = (
  step2: Step2Type,
  newEmploymentStatus: ActivityTypeDeclaredEnumeration
): boolean =>
  step2.personalData?.activityTypeDeclared !== newEmploymentStatus &&
  newEmploymentStatus !== ActivityTypeDeclaredEnumeration.EMPLOYED &&
  newEmploymentStatus !== ActivityTypeDeclaredEnumeration.SELF_EMPLOYED;
