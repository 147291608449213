// Vendors
import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Components
import {
  AssetComponent,
  LineSeparatorComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
import { AMOUNT_PARAGRAPH_PROPS, ASSET_PROPS } from './constants/purchase-amount.constants';
// Styles
import {
  PurchaseAmountComponentStyled,
  PurchaseAmountItemStyled,
} from './purchase-amount.component.styled';
// Translations
import { AMOUNT_LABEL, DATE_LABEL } from './translations/purchase-amount.translations';
// Types
import { PurchaseAmountComponentType } from './types/purchase-amount.component.type';

export const PurchaseAmountComponent = ({
  date,
  description,
  totalAmount,
}: PurchaseAmountComponentType): React.ReactElement => (
  <PurchaseAmountComponentStyled>
    <PurchaseAmountItemStyled>
      <AssetComponent {...ASSET_PROPS} />
      <ParagraphComponent>{description}</ParagraphComponent>
    </PurchaseAmountItemStyled>
    <PurchaseAmountItemStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={DATE_LABEL} />
      </ParagraphComponent>
      <ParagraphComponent>
        <FormattedDate {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY} value={date} />
      </ParagraphComponent>
    </PurchaseAmountItemStyled>
    <LineSeparatorComponent />
    <PurchaseAmountItemStyled>
      <ParagraphComponent {...AMOUNT_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={AMOUNT_LABEL} />
      </ParagraphComponent>
      <ParagraphComponent {...AMOUNT_PARAGRAPH_PROPS}>
        <FormattedNumber {...EURO_FORMAT_OPTIONS} value={totalAmount} />
      </ParagraphComponent>
    </PurchaseAmountItemStyled>
  </PurchaseAmountComponentStyled>
);
