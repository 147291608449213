// Vendors
import React from 'react';
// Components
import {
  SidebarComponent,
  ExpandableRowComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/card-settings-block-card-sidebar.constants';
// Styles
import {
  CardSettingsBlockCardSidebarComponentStyled,
  ExpandableRowContentStyled,
} from './card-settings-block-card-sidebar.component.styled';
// Translations
import translations from './translations/card-settings-block-card-sidebar.translations';

const CardSettingsBlockCardSidebarComponent = (): React.ReactElement => (
  <CardSettingsBlockCardSidebarComponentStyled>
    <SidebarComponent
      {...constants.SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      {constants.ROWS_PROPS.map(({ title, content }, i) => (
        <ExpandableRowComponent key={content} title={<FormattedMessageComponent id={title} />}>
          <ExpandableRowContentStyled>
            <ParagraphComponent {...constants.PARAGRAPH_PROPS}>
              <FormattedMessageComponent id={content} />
            </ParagraphComponent>
          </ExpandableRowContentStyled>
        </ExpandableRowComponent>
      ))}
    </SidebarComponent>
  </CardSettingsBlockCardSidebarComponentStyled>
);

export default CardSettingsBlockCardSidebarComponent;
