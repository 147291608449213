import checkCvvPinConstants from '../constants/check-cvv-pin.constants';

const {
  CVV,
  PIN,
  TRANSLATIONS: { CVV_DOMAIN, PIN_DOMAIN },
} = checkCvvPinConstants;

const CARD_TITLE = 'cobranded.services.userSettings.privateArea.checkCvvPin.title';

export default {
  [CVV]: {
    TITLE: `${CVV_DOMAIN}title`,
  },
  [PIN]: {
    TITLE: `${PIN_DOMAIN}title`,
  },
};

export { CARD_TITLE };
