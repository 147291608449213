// Vendors
import {
  MiscellaneousCurrenciesEnum,
  MiscellaneousCurrencyConstants,
} from '@openbank/cf-ui-static-data';
// Components
import FinancingPaymentMethodFirstStepComponent from '../components/first-step/financing-payment-method-first-step.component';
import FinancingPaymentMethodSecondStepComponent from '../components/second-step/financing-payment-method-second-step.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { FAILED, SUCCESS, STEPS } from '../translations/financing-payment-method.translations';

const EURO_FORMAT_OPTIONS = {
    ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    currency: MiscellaneousCurrenciesEnum.EURO,
    minimumFractionDigits: 0,
  },
  FETCH_ERROR_PROPS = {
    testing: {
      loaderTestId: 'financing-payment-method-fetch-error-component-loader',
    },
  },
  PAYMENT_METHOD_WIZARD_STEPS_PROPS = [
    {
      component: FinancingPaymentMethodFirstStepComponent,
      title: STEPS.FIRST.TITLE,
    },
    {
      component: FinancingPaymentMethodSecondStepComponent,
      title: STEPS.SECOND.TITLE,
    },
  ],
  PAYMENT_METHOD_WIZARD_SUCCESS_PROPS = {
    description: SUCCESS.CONFIRMATION.CONTENT,
    linkLabel: SUCCESS.BACK_BUTTTON,
    title: SUCCESS.CONFIRMATION.TITLE,
    exitRoute: RoutesEnumeration.FINANCING,
  },
  PAYMENT_METHOD_WIZARD_ERROR_PROPS = {
    description: FAILED.CONFIRMATION.CONTENT,
    title: FAILED.CONFIRMATION.TITLE,
    exitRoute: RoutesEnumeration.FINANCING,
  };

export {
  EURO_FORMAT_OPTIONS,
  FETCH_ERROR_PROPS,
  PAYMENT_METHOD_WIZARD_ERROR_PROPS,
  PAYMENT_METHOD_WIZARD_STEPS_PROPS,
  PAYMENT_METHOD_WIZARD_SUCCESS_PROPS,
};
