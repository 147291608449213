// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const ProfilePersonalNameResumeLayoutComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  min-width: ${convertPxsToRems({ pixels: 120, base: 14 })};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ProfilePersonalNameResumeLayoutContentComponentStyled = styled.div`
  font-size: ${({ theme }) => theme.fontSizes.fontSizeH};
`;
