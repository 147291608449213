// Vendors
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CardSettingsActivateCardContextConsumerHook } from 'containers/card-settings/views/activate-card/contexts/card-settings-activate-card.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Constants
import constants from 'containers/card-settings/views/activate-card/constants/card-settings-activate-card.constants';
// Handlers
import CardSettingsActivateCardChallengeHandlers from '../handlers/card-settings-activate-card-challenge.handlers';
import CardSettingsActivateCardChallengeTrackingHandlers from '../handlers/card-settings-activate-card-challenge.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsActivateCardChallengeReturnHookType } from './types/card-settings-activate-card-challenge-return.hook.type';

const CardSettingsActivateCardChallengeHook =
  (): CardSettingsActivateCardChallengeReturnHookType => {
    const [challengeError, setChallengeError] = useState<boolean>(false);

    const { formatMessage } = useCbIntl();
    const { state } = useLocation(),
      { activateCardRedirection } = state || {},
      { route = constants.DEFAULT_BACK_ROUTE } = activateCardRedirection || {};

    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};
    const { challenge, setError, setChallenge } = CardSettingsActivateCardContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...CardSettingsActivateCardChallengeHandlers({
        ...AppTrackingHook(CardSettingsActivateCardChallengeTrackingHandlers),
        cardId,
        challenge,
        lastPage: route,
        navigate: useNavigate(),
        setChallenge,
        setChallengeError,
        setError,
        setResult,
      }),
      cardId,
      challenge,
      challengeError,
      formatMessage,
    };
  };

export default CardSettingsActivateCardChallengeHook;
