// Vendors
import styled from 'styled-components';

const CardSettingsChangePinConstraintsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
CardSettingsChangePinConstraintsComponentStyled.displayName =
  'CardSettingsChangePinConstraintsComponentStyled';

export { CardSettingsChangePinConstraintsComponentStyled };
