export const DEFAULT_INTERVAL = 1000;
export const DEFAULT_MAX_ATTEMPTS = 999999;

const ERROR_MESSAGES_BASE = {
  MAX_ATTEMPTS_EXCEEDED: 'Maximum number of attempts exceeded',
  CONNECTION_ALREADY_OPEN: 'Connection is already open',
  CONNECTION_ALREADY_CLOSED: 'There is not an open connection to close',
};

export const ERROR_MESSAGES_POLLING = {
  ...ERROR_MESSAGES_BASE,
  CONNECTION_NOT_OPEN: 'There is not an open connection or configured sendDataFn to send data',
};

export const ERROR_MESSAGES_WEBSOCKETS = {
  ...ERROR_MESSAGES_BASE,
  CONNECTION_NOT_OPEN: 'There is not an open connection',
};
