// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/card-settings-change-pin-sidebar.translations';

export default {
  CARD_SETTINGS_CHANGE_PIN_SIDEBAR_PROPS: {
    testId: 'card-settings-change-pin',
  },
  CARD_SETTINGS_CHANGE_PIN_SIDEBAR_ITEM_PROPS: {
    size: paragraphConstants.SIZES.M,
  },
  CARD_SETTINGS_CHANGE_PIN_EXPANDABLE_ITEMS: [
    {
      description: translations.EXPANDABLE_DESCRIPTION_1,
      label: translations.EXPANDABLE_TITLE_1,
      testId: 'card-settings-change-pin-sidebar-expandable-card-table-row-description-1',
      testing: {
        rowId: 'card-settings-change-pin-sidebar-expandable-card-1',
        titleId: 'card-settings-change-pin-sidebar-expandable-title-section-1',
      },
    },
    {
      description: translations.EXPANDABLE_DESCRIPTION_2,
      label: translations.EXPANDABLE_TITLE_2,
      testId: 'card-settings-change-pin-sidebar-expandable-card-table-row-description-2',
      testing: {
        rowId: 'card-settings-change-pin-sidebar-expandable-card-2',
        titleId: 'card-settings-change-pin-sidebar-expandable-title-section-2',
      },
    },
    {
      description: translations.EXPANDABLE_DESCRIPTION_3,
      label: translations.EXPANDABLE_TITLE_3,
      testId: 'card-settings-change-pin-sidebar-expandable-card-table-row-description-3',
      testing: {
        rowId: 'card-settings-change-pin-sidebar-expandable-card-3',
        titleId: 'card-settings-change-pin-sidebar-expandable-title-section-3',
      },
    },
  ],
};
