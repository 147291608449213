// Components
import { FinancingPPIContractingStep1Component } from '../components/step1/financing-ppi-contracting-step1.component';
import { FinancingPPIContractingStep2Component } from '../components/step2/financing-ppi-contracting-step2.component';
import { FinancingPPIContractingStep3Component } from '../components/step3/financing-ppi-contracting-step3.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { TITLE as STEP_1_TITLE } from '../components/step1/translations/financing-ppi-contracting-step1.translations';
import { TITLE as STEP_2_TITLE } from '../components/step2/translations/financing-ppi-contracting-step2.translations';
import { TITLE as STEP_3_TITLE } from '../components/step3/translations/financing-ppi-contracting-step3.translations';
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  EXIT_LINK,
  EXIT,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
} from '../translations/financing-ppi-contracting-feedback.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const FINANCING_PPI_CONTRACTING_WIZARD_PROPS = {
    feedbackConfiguration: {
      errorConfiguration: {
        description: ERROR_DESCRIPTION,
        title: ERROR_TITLE,
        linkLabelId: EXIT_LINK,
        exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES]),
      },
      successConfiguration: {
        description: SUCCESS_DESCRIPTION,
        linkLabel: EXIT,
        title: SUCCESS_TITLE,
        exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES]),
      },
    },
    steps: [
      {
        component: FinancingPPIContractingStep1Component,
        title: STEP_1_TITLE,
      },
      {
        component: FinancingPPIContractingStep2Component,
        title: STEP_2_TITLE,
      },
      {
        component: FinancingPPIContractingStep3Component,
        title: STEP_3_TITLE,
      },
    ],
  },
  FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS = {
    cancelModal: {
      cancelUrl: generateAbsoluteRoute(
        generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES])
      ),
    },
  };

export { FINANCING_PPI_CONTRACTING_WIZARD_PROPS, FINANCING_PPI_CONTRACTING_COMMON_CANCEL_PROPS };
