// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { INITIAL_CONTEXT } from './constants/quick-actions.context.constants';
// Types
import { QuickActionsContextType } from './types/quick-actions.context.type';
import { QuickActionsSectionCategoryItemType } from 'types/quick-actions/quick-actions-section-category-item.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const QuickActionsContext = createContext<QuickActionsContextType>(INITIAL_CONTEXT);

const QuickActionsContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<QuickActionsContextType>> => {
  const [actions, setActions] = useState<QuickActionsSectionCategoryItemType[]>([]);
  const [quickActions, setQuickActions] = useState<QuickActionsSectionCategoryItemType[]>([]);

  return (
    <QuickActionsContext.Provider value={{ actions, quickActions, setActions, setQuickActions }}>
      {children}
    </QuickActionsContext.Provider>
  );
};

const useQuickActionsContextConsumerHook = (): QuickActionsContextType =>
  useContext(QuickActionsContext);

export { QuickActionsContextProvider, useQuickActionsContextConsumerHook };
