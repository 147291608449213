// Vendors
import styled from 'styled-components';

const CardStatementTableRowDownloadComponentStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;

CardStatementTableRowDownloadComponentStyled.displayName =
  'CardStatementsTableRowDownloadComponentStyled';

export { CardStatementTableRowDownloadComponentStyled };
