// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ProfilePersonalNameAvatarComponentStyled = styled.section`
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusE};
  position: relative;
`;

export const ProfilePersonalNameAvatarEditComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryD};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusF};
  bottom: 0;
  padding: ${({ theme }) => theme.spaces.spacingXxs};
  position: absolute;
  right: 0;

  ${mobileMediaQuery} {
    bottom: ${convertPxsToRems({ pixels: -20, base: 14 })};
    left: 50%;
    right: unset;
    transform: translateX(-50%);
  }
`;
