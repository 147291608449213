// Constants
import { TRANSACTION_DATE_FORMAT_PROPS } from './constants/transactions-date.constants.util';
// Types
import { GetDetailsDateTimeType } from './types/get-details-date-time.type';

const getDetailsDateTime = ({ date, formatDate, options, time }: GetDetailsDateTimeType): string =>
  `${formatDate(`${date} ${time}`, options)}`.replace(
    TRANSACTION_DATE_FORMAT_PROPS.SUFFIX,
    TRANSACTION_DATE_FORMAT_PROPS.SEPARATOR
  );

export { getDetailsDateTime };
