// Vendors
import React from 'react';
// Components
import {
  KeyCardComponent,
  NavigationFooterComponent,
  ParagraphComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Config
import {
  getKeyCardComponentProps,
  getKeyCardGoBackPathLabel,
  getRightLinkProps,
} from './config/view-code.config';
// Constants
import viewCodeConstants from './constants/view-code.constants';
// Styles
import { CardSpacedComponentStyled } from './view-code.component.styled';
// Translations
import viewCodeTranslations from './translations/view-code.translations';
// Types
import { ViewCodeComponentProps } from './types/view-code.type';

const { defaultCodeTime, viewCodeCodeDetailsProps, viewCodeDescriptionProps, viewCodeTitleProps } =
  viewCodeConstants;

const ViewCodeComponent = ({
  code,
  formatMessage,
  handleRestartProcess,
  onGoToPreviousPath,
  time = defaultCodeTime,
  tracking,
  type,
}: ViewCodeComponentProps): React.ReactElement => (
  <>
    <CardSpacedComponentStyled>
      <TitleHeaderComponent {...viewCodeTitleProps}>
        <FormattedMessageComponent id={viewCodeTranslations[type]?.TITLE} />
      </TitleHeaderComponent>
    </CardSpacedComponentStyled>
    <CardSpacedComponentStyled>
      <ParagraphComponent {...viewCodeDescriptionProps}>
        <FormattedMessageComponent id={viewCodeTranslations[type]?.DESCRIPTION} />
      </ParagraphComponent>
    </CardSpacedComponentStyled>

    <CardSpacedComponentStyled {...viewCodeCodeDetailsProps}>
      <KeyCardComponent
        {...getKeyCardComponentProps({
          code,
          formatMessage,
          time,
          tracking,
          type,
          handleRestartProcess,
        })}
      />
    </CardSpacedComponentStyled>

    <NavigationFooterComponent
      rightLinkConfiguration={getRightLinkProps({
        children: getKeyCardGoBackPathLabel({ type }),
        onClick: onGoToPreviousPath,
      })}
    />
  </>
);

export default ViewCodeComponent;
