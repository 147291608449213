// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { DECREASE_LIMIT_ENDPOINT } from 'containers/financing/views/credit-limit-decrease-cc/services/constants/decrease-limit.services.constants';
// Types
import { DecreaseLimitServicesType } from './types/decrease-limit.services.type';
import { ChallengeType } from 'types/challenge.type';

const decreaseLimitService = async ({
  cardContractId,
  params,
  progressId,
}: DecreaseLimitServicesType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    customHeaders: progressId ? { progressId } : {},
    endpoint: DECREASE_LIMIT_ENDPOINT,
    params,
    pathParams: { cardContractId },
  });

export { decreaseLimitService };
