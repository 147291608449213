// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import {
  FILES_INITIAL_STATE,
  TRANSACTIONS_REPORT_SERVICE_CANCELLED_CONTEXT_DEFAULT_VALUE,
} from './constants/transactions-report-service-cancelled.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { TransactionsReportServiceCancelledContextType } from './types/transactions-report-service-cancelled.context.type';
import { FileDataType } from 'types/file-data.type';

const TransactionsReportServiceCancelledContext =
  createContext<TransactionsReportServiceCancelledContextType>(
    TRANSACTIONS_REPORT_SERVICE_CANCELLED_CONTEXT_DEFAULT_VALUE
  );

export const TransactionsReportServiceCancelledContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<TransactionsReportServiceCancelledContextType>
> => {
  const [cancellationDate, setCancellationDate] = useState<string>('');
  const [files, setFiles] = useState<FileDataType[]>(FILES_INITIAL_STATE);

  const contextValue = useMemo(
    () => ({
      cancellationDate,
      files,
      setCancellationDate,
      setFiles,
    }),
    [cancellationDate, files]
  );

  return (
    <TransactionsReportServiceCancelledContext.Provider value={contextValue}>
      {children}
    </TransactionsReportServiceCancelledContext.Provider>
  );
};

export const useTransactionsReportServiceCancelledContextConsumerHook =
  (): TransactionsReportServiceCancelledContextType =>
    useContext(TransactionsReportServiceCancelledContext);

/* eslint-disable */
export const TransactionsReportServiceCancelledContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<TransactionsReportServiceCancelledContextType>> =>
    (
      <TransactionsReportServiceCancelledContextProvider>
        <Component {...props} />
      </TransactionsReportServiceCancelledContextProvider>
    );
