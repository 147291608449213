export default {
  CONTEXT_DEFAULT_VALUE: {
    beneficiaryCardCheckbox: false,
    challenged: false,
    contractedProductsToggle: false,
    emailCheckboxValue: null,
    emailValue: '',
    error: false,
    fetching: false,
    information: {},
    mainCardCheckbox: false,
    movementsCheckbox: false,
    personalDataToggle: false,
    portabilityParams: null,
    progressId: '',
    result: null,
    setBeneficiaryCardCheckbox: (): null => null,
    setChallenged: (): null => null,
    setContractedProductsToggle: (): null => null,
    setEmailCheckboxValue: (): null => null,
    setEmailValue: (): null => null,
    setError: (): null => null,
    setFetching: (): null => null,
    setInformation: (): null => null,
    setMainCardCheckbox: (): null => null,
    setMovementsCheckbox: (): null => null,
    setPersonalDataToggle: (): null => null,
    setPortabilityParams: (): null => null,
    setProgressId: (): null => null,
    setResult: (): null => null,
    setShowModal: (): null => null,
    showModal: false,
  },
};
