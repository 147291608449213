// Types
import { CardSettingsContractCurrentLinkHookType } from '../hooks/types/card-settings-contract-current-link.hook.type';
// Translations
import {
  MAIN_TITLE,
  SECONDARY_TITLE,
} from './translations/card-settings-contract-current.utils.translations';
// Utilities
import { cardActive } from 'utils/card.utils';
import { isMainCardSelected } from 'containers/card-settings/views/contract/utils/card-settings-contract.utils';

const getCurrentContractCardTitle = (isAddon: boolean): string =>
  isAddon ? SECONDARY_TITLE : MAIN_TITLE;

const isPartnerCardOrInactive = ({
  isAddon,
  status,
}: CardSettingsContractCurrentLinkHookType): boolean =>
  !isMainCardSelected(isAddon) || !cardActive(status);

export { isPartnerCardOrInactive, getCurrentContractCardTitle };
