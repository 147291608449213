// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { CardSettingsChange3DStep2TrackingBuilderReturnHandlersType } from './types/card-settings-change-3d-step2.tracking.handlers.type';

const change3DCodeSecureButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'changeCode',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsChange3dStep2TrackingHandlers = (
  track: TrackBuilderType
): CardSettingsChange3DStep2TrackingBuilderReturnHandlersType => ({
  handleChange3DCodeSecureButtonClickTracking: () =>
    track(change3DCodeSecureButtonClickTrackingHandler()),
});

export { CardSettingsChange3dStep2TrackingHandlers };
