// Vendors
import React from 'react';
// Components
import { BreadCrumbsComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getCustomBreadCrumbsItems } from './configurations/title-section-rwd-breadcrumbs.configurations';
// Hooks
import TitleSectionRwdBreadcrumbsHook from './hooks/title-section-rwd-breadcrumbs.hook';
// Styles
import { TitleSectionRwdBreadcrumbsComponentStyled } from './title-section-rwd-breadcrumbs.component.styled';
// Utilities
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';

const TitleSectionRwdBreadcrumbsComponent = (): React.ReactElement | null => {
  const { breadCrumbPath, formatMessage, navigate, handleBreadcrumbClickTrackingHandler } =
    TitleSectionRwdBreadcrumbsHook();

  return isSecondLevelRoutePath() ? (
    <TitleSectionRwdBreadcrumbsComponentStyled>
      <BreadCrumbsComponent
        crumbs={getCustomBreadCrumbsItems({
          breadCrumbPath,
          formatMessage,
          navigate,
          handleBreadcrumbClickTrackingHandler,
        })}
      />
    </TitleSectionRwdBreadcrumbsComponentStyled>
  ) : null;
};

export default TitleSectionRwdBreadcrumbsComponent;
