// Vendors
import styled from 'styled-components';

export const FinancingEarlyRepaymentFirstStepComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const FinancingEarlyRepaymentFirstStepDescriptionStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;

export const FinancingEarlyRepaymentFirstStepInstalmentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
