// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

export default {
  LABELS_LINK_CARD_TITLE_PROPS: {
    tag: titleConstants.TAGS.H4,
  },
  DEFAULT_LABELS_LINK_CARD_TESTING_PROPS: {
    titleSimpleId: 'labels-link-card-title-simple',
    titleHeaderId: 'labels-link-card-title-dr-picasso-header',
  },
};
