// Vendors
import React from 'react';
// Configurations
import { getChallengeConfiguration } from './configurations/personal-email-step3.challenge.configurations';
// Components
import { ExpiredSignatureComponent } from 'containers/personal/components/expired-signature-message/expired-signature-message.component';
import { PersonalEmailStep3ChallengeScriptBoxComponent } from './components/script-box/personal-email-step3-challenge-script-box.component.cc';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Hooks
import PersonalEmailStep3ChallengeHook from './hooks/personal-email-step3-challenge.hook';
// Styled
import { PersonalEmailStep3ChallengeComponentStyled } from './personal-email-step3-challenge.component.styled';

const PersonalEmailStep3ChallengeComponent = (): React.ReactElement => {
  const {
    challengeError,
    customerId,
    handleChallengeRetryButtonClick,
    handleManageErrorOnChangeEmailEvent,
    handleManageSuccessOnChangeEmailEvent,
    newEmail: email,
    signatureExpired,
  } = PersonalEmailStep3ChallengeHook();

  return (
    <PersonalEmailStep3ChallengeComponentStyled>
      {signatureExpired && <ExpiredSignatureComponent />}
      <PersonalEmailStep3ChallengeScriptBoxComponent />
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          customerId,
          challengeError,
          email,
          handleChallengeError: handleManageErrorOnChangeEmailEvent,
          handleChallengeSuccess: handleManageSuccessOnChangeEmailEvent,
          handleChallengeRetryButtonClick,
        })}
      />
    </PersonalEmailStep3ChallengeComponentStyled>
  );
};

export default PersonalEmailStep3ChallengeComponent;
