const ASSET_PROPS = {
  id: 'otp-desktop-alternative',
  type: 'illustration',
  sizes: {
    width: 128,
    height: 128,
  },
  testId: 'security-download-check-modal-first-step-asset',
};

const PARAGRAPH_PROPS = {
  testId: 'security-download-check-modal-first-step-paragraph',
};

export { ASSET_PROPS, PARAGRAPH_PROPS };
