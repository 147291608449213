// Vendors
import React from 'react';
// Components
import DashboardSpentBottomComponent from './components/bottom/dashboard-spent-bottom.component';
import { DashboardSpentTopComponent } from './components/top/dashboard-spent-top.component';
// Hooks
import DashboardSpentHook from './hooks/dashboard-spent.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import { DashboardSpentComponentStyled } from './dashboard-spent.component.styled';
// Utils
import { isValidPercentage } from './utils/dashboard-spent.component.utils';

const DashboardSpentComponent: React.FC = () => {
  const {
    currentPercentage = 0,
    expenses,
    isMobile,
    percentageDifference = 0,
    previousPercentage = 0,
    total = 0,
  } = DashboardSpentHook();

  return isValidPercentage(percentageDifference) ? (
    <AppCardSectionComponentStyled>
      <DashboardSpentComponentStyled>
        <DashboardSpentTopComponent
          {...{
            currentPercentage,
            expenses,
            isMobile,
            previousPercentage,
            percentageDifference,
            total,
          }}
        />
        <DashboardSpentBottomComponent {...{ expenses, isMobile, total }} />
      </DashboardSpentComponentStyled>
    </AppCardSectionComponentStyled>
  ) : null;
};

export { DashboardSpentComponent };
