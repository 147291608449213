// Event handlers
import TransactionsLandingRowDetailsViewOrderHandlers from '../handlers/transactions-landing-row-details-view-order.handlers';
import TransactionsLandingRowDetailsViewOrderTrackingHandlers from '../handlers/transactions-landing-row-details-view-order.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsLandingRowDetailsViewOrderBuilderReturnHandlersType } from '../handlers/types/transactions-landing-row-details-view-order-builder-return.handlers.type';

const TransactionsLandingRowDetailsViewOrderHook =
  (): TransactionsLandingRowDetailsViewOrderBuilderReturnHandlersType =>
    TransactionsLandingRowDetailsViewOrderHandlers(
      AppTrackingHook(TransactionsLandingRowDetailsViewOrderTrackingHandlers)
    );

export default TransactionsLandingRowDetailsViewOrderHook;
