const DOMAIN_SIGNATURE_KEY_CC =
  'cobrandedCC.services.userSettings.privateArea.securityAndKeys.changeSignatureKey.';

const CHANGE_SIGNATURE_KEY_CC_TITLE = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCTitle`;
const CHANGE_SIGNATURE_KEY_CC_INITIAL_TEXT = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCInitialText`;
const CHANGE_SIGNATURE_KEY_CC_LAST_TEXT = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCLastText`;
const CHANGE_SIGNATURE_KEY_CC_FIRST_TEXT = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCFirstText`;
const CHANGE_SIGNATURE_KEY_CC_SECOND_TEXT = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCSecondText`;
const CHANGE_SIGNATURE_KEY_CC_FIRST_CONSTRAINTS = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCFirstConstraints`;
const CHANGE_SIGNATURE_KEY_CC_SECOND_CONSTRAINTS = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCSecondConstraints`;
const CHANGE_SIGNATURE_KEY_CC_THIRD_CONSTRAINTS = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCThirdConstraints`;
const CHANGE_SIGNATURE_KEY_CC_FOURTH_CONSTRAINTS = `${DOMAIN_SIGNATURE_KEY_CC}FourthConstraints`;
const CHANGE_SIGNATURE_KEY_CC_THIRD_TEXT = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCThirdText`;
const CHANGE_SIGNATURE_KEY_CC_FOURTH_TEXT = `${DOMAIN_SIGNATURE_KEY_CC}changeSignatureKeyCCFourthText`;

export {
  CHANGE_SIGNATURE_KEY_CC_TITLE,
  CHANGE_SIGNATURE_KEY_CC_INITIAL_TEXT,
  CHANGE_SIGNATURE_KEY_CC_LAST_TEXT,
  CHANGE_SIGNATURE_KEY_CC_FIRST_TEXT,
  CHANGE_SIGNATURE_KEY_CC_SECOND_TEXT,
  CHANGE_SIGNATURE_KEY_CC_FIRST_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_SECOND_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_THIRD_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_FOURTH_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_THIRD_TEXT,
  CHANGE_SIGNATURE_KEY_CC_FOURTH_TEXT,
};
