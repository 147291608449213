const DOMAIN = 'cobranded.services.userSettings.privateArea.securityAndKeys.deviceModal.';

export const CURRENT_DEVICE_ADVICE = `${DOMAIN}currentDeviceAdvice`;
export const CURRENT_DEVICE_ADVICE_DESCRIPTION = `${DOMAIN}currentDeviceAdviceDescription`;
export const CURRENT_TRUSTED_ADVICE = `${DOMAIN}trustedDeviceAdvice`;
export const CURRENT_TRUSTED_ADVICE_DESCRIPTION = `${DOMAIN}trustedDeviceAdviceDescription`;

export const CANCEL_BUTTON = `${DOMAIN}cancelButton`;
export const MODAL_TYPES = {
  BLOCK: {
    title: `${DOMAIN}block.title`,
    description: `${DOMAIN}block.description`,
    button: `${DOMAIN}block.button`,
    buttonId: 'device-modal-block-button',
  },
  UNBLOCK: {
    title: `${DOMAIN}unblock.title`,
    description: `${DOMAIN}unblock.description`,
    button: `${DOMAIN}unblock.button`,
    buttonId: 'device-modal-unblock-button',
  },
  SIGN_OUT: {
    title: `${DOMAIN}signOut.title`,
    description: `${DOMAIN}signOut.description`,
    button: `${DOMAIN}signOut.button`,
    buttonId: 'device-modal-sign-out-button',
  },
  UNROLL_TRUSTED: {
    title: `${DOMAIN}unrollTrusted.title`,
    description: `${DOMAIN}unrollTrusted.description`,
    button: `${DOMAIN}unrollTrusted.button`,
    buttonId: 'device-modal-unroll-trusted-button',
  },
};
