// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Event handlers
import ToastHandlers from '../handlers/toast.handlers';
// Types
import { ToastHookType } from './types/toast.hook.type';

const ToastHook = (): ToastHookType => {
  const {
    setToastConfiguration,
    toastConfiguration: { description, type, onClose },
  } = ToastContextConsumerHook();

  return { ...ToastHandlers({ setToastConfiguration, onClose }), description, type };
};

export default ToastHook;
