// Vendors
import styled from 'styled-components';

export const DeviceDetailViewStyled = styled.section.attrs(() => ({
  'data-testid': 'device-detail-view'
}))`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => `${theme.spaces.spacingS}`};
  width: 100%;
`;