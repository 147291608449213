// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import {
  FILES_INITIAL_STATE,
  TRANSACTIONS_REPORT_PAYMENT_CONTEXT_DEFAULT_VALUE,
} from './constants/transactions-report-payment.context.constants';
// Enumerations
import { PayOthersPaymentMethodEnumeration } from 'containers/transactions/views/report/enumerations/pay-others-payment-method.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { TransactionsReportPaymentContextType } from './types/transactions-report-payment.context.type';
import { FileDataType } from 'types/file-data.type';

const TransactionsReportPaymentContext = createContext<TransactionsReportPaymentContextType>(
  TRANSACTIONS_REPORT_PAYMENT_CONTEXT_DEFAULT_VALUE
);

export const TransactionsReportPaymentContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<TransactionsReportPaymentContextType>> => {
  const [paymentMethod, setPaymentMethod] = useState<
    PayOthersPaymentMethodEnumeration | undefined
  >();
  const [files, setFiles] = useState<FileDataType[]>(FILES_INITIAL_STATE);

  const contextValue = useMemo(
    () => ({
      paymentMethod,
      files,
      setPaymentMethod,
      setFiles,
    }),
    [paymentMethod, files]
  );

  return (
    <TransactionsReportPaymentContext.Provider value={contextValue}>
      {children}
    </TransactionsReportPaymentContext.Provider>
  );
};

export const useTransactionsReportPaymentContextConsumerHook =
  (): TransactionsReportPaymentContextType => useContext(TransactionsReportPaymentContext);

/* eslint-disable */
export const TransactionsReportPaymentContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<TransactionsReportPaymentContextType>> =>
    (
      <TransactionsReportPaymentContextProvider>
        <Component {...props} />
      </TransactionsReportPaymentContextProvider>
    );
