// Vendors
import React from 'react';
// Components
import {
  InputDropdownComponent,
  ParagraphComponent,
  RadioButtonComponent,
} from '@openbank/cb-ui-commons';
import EditableInfoPanelComponent from 'containers/financing/views/credit-limit-increase/components/editable-info-panel/editable-info-panel.component';
import SectionCreditLimitIncreaseComponent from '../../components/section/section-credit-limit-increase.component';
// Configurations
import { getInfoPanelContentConfig } from './configurations/personal-situation-credit-limit-increase.configurations';
// Constants
import { constants } from './constants/personal-situation-credit-limit-increase.constants';
// Enums
import { MaritalStatusEnumeration } from './enumerations/marital-status.enumeration';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import PersonalSituationCreditLimitIncreaseHook from './hooks/personal-situation-credit-limit-increase.component.hook';
// Translations
import translations from './translations/personal-situation-credit-limit-increase.component.translations';
// Styles
import {
  CreditLimitIncreaseFormWrapper,
  PersonalSituationRadioButtonsStyled,
} from './personal-situation-credit-limit-increase.component.styled';
import { SizeOneInputStyled } from '../size-one-input.styled';
// Utils
import { getChildrenOptions } from './utils/personal-situation-credit-limit-increase.component.utils';

const PersonalSituationCreditLimitIncreaseComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const {
    isEditModeOn,
    maritalStatus,
    childrenInHousehold,
    childrenWithMaintenance,
    isIosDevice,
    onOpenChildrenWhomYouPayTooltip,
    onClickChildrenInHousehold,
    onSelectChildrenInHousehold,
    onClickChildrenWhomYouPay,
    onSelectChildrenWhomYouPay,
    onChangeRadioButton,
    setIsEditModeOn,
  } = PersonalSituationCreditLimitIncreaseHook();
  const childrenOptions = getChildrenOptions(formatMessage);

  return (
    <SectionCreditLimitIncreaseComponent
      title={formatMessage({ id: translations.PERSONAL_SITUATION })}
    >
      {isEditModeOn ? (
        <CreditLimitIncreaseFormWrapper>
          <ParagraphComponent bold>
            {formatMessage({ id: translations.MARITAL_STATUS })}
          </ParagraphComponent>
          <PersonalSituationRadioButtonsStyled>
            {Object.values(MaritalStatusEnumeration).map(maritalStatusEnum => (
              <RadioButtonComponent
                key={maritalStatusEnum}
                helperMessage={formatMessage({ id: translations[maritalStatusEnum] })}
                value={maritalStatus === maritalStatusEnum}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeRadioButton(e, maritalStatusEnum)
                }
              />
            ))}
          </PersonalSituationRadioButtonsStyled>
          <SizeOneInputStyled>
            <InputDropdownComponent
              label={formatMessage({ id: translations.NUMBER_OF_CHILDREN_H })}
              placeholder={formatMessage({ id: translations.NUMBER_OF_CHILDREN_H_PLACEHOLDER })}
              value={childrenInHousehold}
              isIosDevice={isIosDevice}
              options={childrenOptions}
              onClick={onClickChildrenInHousehold}
              onSelectOption={onSelectChildrenInHousehold}
            />
          </SizeOneInputStyled>
          <SizeOneInputStyled>
            <InputDropdownComponent
              label={formatMessage({ id: translations.NUMBER_OF_CHILDREN_P })}
              placeholder={formatMessage({ id: translations.NUMBER_OF_CHILDREN_P_PLACEHOLDER })}
              tooltipConfiguration={{
                content: formatMessage({ id: translations.NUMBER_OF_CHILDREN_P_TOOLTIP }),
                doWhenTooltipIsOpen: onOpenChildrenWhomYouPayTooltip,
              }}
              isIosDevice={isIosDevice}
              value={childrenWithMaintenance}
              options={childrenOptions}
              onClick={onClickChildrenWhomYouPay}
              onSelectOption={onSelectChildrenWhomYouPay}
            />
          </SizeOneInputStyled>
        </CreditLimitIncreaseFormWrapper>
      ) : (
        <EditableInfoPanelComponent
          key={constants.CONTENT_TEST_ID}
          content={getInfoPanelContentConfig({
            maritalStatus,
            childrenInHousehold,
            childrenWithMaintenance,
            formatMessage,
          })}
          onEdit={setIsEditModeOn}
        />
      )}
    </SectionCreditLimitIncreaseComponent>
  );
};

export default PersonalSituationCreditLimitIncreaseComponent;
