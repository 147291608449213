// Vendors
import { useEffect } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
import { PersonalEmailStep2ContextConsumerHook } from '../contexts/personal-email-step2.context';
// Event handlers
import PersonalEmailStep2ChallengeHandlers from '../handlers/personal-email-step2.handlers';
import PersonalEmailStep2TrackingHandlers from '../handlers/personal-email-step2.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalEmailStep2HookType } from 'containers/personal/views/email/components/step2/hooks/types/personal-email-step2.hook.type';

const PersonalEmailStep2Hook = (): PersonalEmailStep2HookType => {
  const { formatMessage } = useCbIntl();

  const { profile } = AppContextConsumerHook(),
    { setOtpId } = PersonalEmailStep2ContextConsumerHook(),
    { firstName: name } = profile?.userDetails || {};

  const { error, newEmail, setChallenged, setFetching, setError } =
    PersonalEmailContextConsumerHook();

  const { handleRequestNewEmailOtpLinkClick } = PersonalEmailStep2ChallengeHandlers({
    ...AppTrackingHook(PersonalEmailStep2TrackingHandlers),
    name,
    newEmail,
    setChallenged,
    setFetching,
    setError,
    setOtpId,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleRequestNewEmailOtpLinkClick();
  }, []);

  return {
    formatMessage,
    error,
    handleRequestNewEmailOtpLinkClick,
  };
};

export default PersonalEmailStep2Hook;
