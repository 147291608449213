// Constants
import { step4UploadDocumentationConstants } from '../constants/step-4-upload-documentation.tracking.constants';
// Types
import { CustomLinkTrackingPropsType } from './types/step-4-upload-documentation.tracking.configuration.type';

export const getShowUploadErrorConfiguration = (element: string): CustomLinkTrackingPropsType => ({
  ...step4UploadDocumentationConstants.LINK.SHOW_UPLOAD_ERROR,
  element,
});
export const getShowInvalidFormatErrorConfiguration = (
  element: string
): CustomLinkTrackingPropsType => ({
  ...step4UploadDocumentationConstants.LINK.SHOW_INVALID_FORMAT_ERROR,
  element,
});
export const getShowOversizedErrorConfiguration = (
  element: string
): CustomLinkTrackingPropsType => ({
  ...step4UploadDocumentationConstants.LINK.SHOW_OVERSIZED_ERROR,
  element,
});
