const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.detailConfirmation.';

const LPI = `${DOMAIN}LPI`;
const PREMIUM_LABEL = `${DOMAIN}premiumLabel`;
const PREMIUM_DETAIL = `${DOMAIN}premiumDetail`;
const COVERAGE_LABEL = `${DOMAIN}coverageLabel`;
const COVERAGE_DETAIL = `${DOMAIN}coverageDetail`;
const PRODUCT_NAME_LABEL = `${DOMAIN}productNameLabel`;
const POLICY_HOLDER_LABEL = `${DOMAIN}policyHolderLabel`;

export {
  LPI,
  PREMIUM_LABEL,
  PREMIUM_DETAIL,
  COVERAGE_LABEL,
  COVERAGE_DETAIL,
  PRODUCT_NAME_LABEL,
  POLICY_HOLDER_LABEL,
};
