const PARAGRAPH_PROPS_TITLE = {
  testId: 'amount-details-modal-description-fees-title',
};
const PARAGRAPH_PROPS_VALUE = {
  testId: 'amount-details-modal-description-fees-value',
};
const FORMATTED_NUMPER_SIGNS_PROP_ALLWAYS = 'always';
const FORMATTED_NUMPER_SIGNS_PROP_NEVER = 'never';
const SIGN_D = 'D';
const ISSUER_TYPE = 'ISSUER';

export {
  FORMATTED_NUMPER_SIGNS_PROP_ALLWAYS,
  FORMATTED_NUMPER_SIGNS_PROP_NEVER,
  ISSUER_TYPE,
  PARAGRAPH_PROPS_TITLE,
  PARAGRAPH_PROPS_VALUE,
  SIGN_D,
};
