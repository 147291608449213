// Translations
import translations from 'translations/challenge-otp.translations';
// Constants
import { TIMER } from './constants/challenge-download-wrapper.hook.utils.constants';

const getTimerLabelTranslationId = (timer: number): string => {
  return timer > TIMER ? translations.TIMER_LABEL_SECONDS : translations.TIMER_LABEL_SECOND;
};

export { getTimerLabelTranslationId };
