// Constants
import { APP_FOOTER_PUBLIC_LINKS } from './constants/orchestrator-footer.utils.constants';
// Types
import { OrchestratorFooterUtilsType } from './types/orchestrator-footer.utils.type';
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';

const convertPublicFooterLinks = (
  formatMessage: UseCbIntlReturnType['formatMessage']
): OrchestratorFooterUtilsType[] =>
  APP_FOOTER_PUBLIC_LINKS.map((link: OrchestratorFooterUtilsType) => ({
    ...link,
    text: formatMessage({ id: link.text }),
  }));

export { convertPublicFooterLinks };
