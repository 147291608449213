// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  GET_CITIES_BY_TERM_SERVICE_CONFIGURATION,
  VALIDATE_CITY_WITH_POSTCODE_SERVICE_CONFIGURATION,
} from './constants/address-modal.services.constants';
// Types
import { ValidateCityWithPostcodeServiceType } from './types/validate-city-with-postcode-service.type';
import { ValidateCityWithPostcodeServiceResponseType } from './types/validate-city-with-postcode-service-response.type';
import { GetCitiesByTermServiceResponse } from './types/get-cities-by-term-service-response.type';

const getCitiesByTermService = async (
  term: string
): Promise<[GetCitiesByTermServiceResponse | null, Response]> =>
  apiUtilsCb.get({
    ...GET_CITIES_BY_TERM_SERVICE_CONFIGURATION,
    queryParams: { city: term },
  });

const validateCityWithPostcodeService = async ({
  city,
  postCode,
}: ValidateCityWithPostcodeServiceType): Promise<
  [ValidateCityWithPostcodeServiceResponseType | null, Response]
> =>
  apiUtilsCb.post({
    ...VALIDATE_CITY_WITH_POSTCODE_SERVICE_CONFIGURATION,
    params: { city, postCode },
  });

export { getCitiesByTermService, validateCityWithPostcodeService };
