// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, TagComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_LANDING_TABLE_ROW_TAG_PROPS,
  FINANCING_LANDING_TABLE_ROW_TAG_TYPES,
} from './constants/financing-landing-table-row-tag.constants';
import { FINANCING_LANDING_TABLE_ROW_INSTALLMENTS_PROPS } from '../installments/constants/financing-landing-table-row-installments.constants';
// Styles
import {
  FinancingLandingTableRowTagComponentStyled,
  ParagraphComponentStyled,
} from './financing-landing-table-row-tag.component.styled';
// Translations
import { FINANCING_TABLE_TAG_LABELS } from './translations/transactions-landing-table-list-row-tag.translations';
import { INSTALLMENTS } from '../installments/translations/financing-landing-table-row-installments.translations';
// Types
import { FinancingLandingTableRowTagComponentType } from './types/financing-landing-table-row-tag.component.types';

const FinancingLandingTableRowTagComponent = ({
  status,
  numOfPaidInstallments,
  numOfInstallments,
}: FinancingLandingTableRowTagComponentType): React.ReactElement => (
  <FinancingLandingTableRowTagComponentStyled>
    <TagComponent
      {...FINANCING_LANDING_TABLE_ROW_TAG_PROPS}
      type={FINANCING_LANDING_TABLE_ROW_TAG_TYPES[status]}
    >
      <FormattedMessageComponent uppercase id={FINANCING_TABLE_TAG_LABELS[status]} />
    </TagComponent>
    <ParagraphComponentStyled>
      <ParagraphComponent {...FINANCING_LANDING_TABLE_ROW_INSTALLMENTS_PROPS}>
        <FormattedMessageComponent
          id={INSTALLMENTS}
          values={{ numOfPaidInstallments, numOfInstallments }}
        />
      </ParagraphComponent>
    </ParagraphComponentStyled>
  </FinancingLandingTableRowTagComponentStyled>
);
export default FinancingLandingTableRowTagComponent;
