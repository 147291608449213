// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from './../../translations/banner.translations';
// Types
import { AppBannerPropsType } from './../types/banner.component.hook.types';
import { GetBannerPropsUtilType } from './types/banner.component.hook.utils.types';

const ALLOWED_PIXEL_RATIO = [1, 2, 3];

const getDevicePixelRatio = (): number => {
  const devicePixelRatio = Math.trunc(window.devicePixelRatio ?? 2);
  return ALLOWED_PIXEL_RATIO.includes(devicePixelRatio) ? devicePixelRatio : 2;
};

const onClickCtaRedirection = ({ formatMessage, navigate }: GetBannerPropsUtilType) => {
  const redirectUrl = formatMessage({ id: translations.CTA_LINK });
  if (redirectUrl.includes('http')) {
    window.open(redirectUrl);
  } else {
    navigate(redirectUrl);
  }
};

const getButtonConfiguration = ({ formatMessage, navigate }: GetBannerPropsUtilType) => ({
  size: buttonConstants.SIZES.SMALL,
  children: formatMessage({ id: translations.CTA }),
  onClick: () => onClickCtaRedirection({ formatMessage, navigate }),
});

export const getBannerProps = ({
  formatMessage,
  navigate,
}: GetBannerPropsUtilType): AppBannerPropsType => ({
  backgroundImage: formatMessage(
    { id: translations.IMAGE_URL },
    {
      devicePixelRatio: getDevicePixelRatio(),
      serverAssetsPath: window.ASSETS_SERVER_PATH,
    }
  ),
  title: formatMessage({ id: translations.TITLE }),
  buttonConfiguration: getButtonConfiguration({ formatMessage, navigate }),
});
