// Components
import PersonalNumberStep1Component from '../components/step1/personal-number-step1.component';
import PersonalNumberStep3Component from '../components/step3/personal-number-step3.component';
import PersonalNumberStep4Component from '../components/step4/personal-number-step4.component';
import PersonalNumberStep2Component from '../components/step2/personal-number-step2.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  LINK_LABEL,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
} from '../translations/personal-number.translations';
import { TITLE as STEP_1_TITLE } from '../components/step1/translations/personal-number-step1.translations';
import { TITLE as STEP_2_TITLE } from '../components/step2/translations/personal-number-step2.translations';
import { TITLE as STEP_3_TITLE } from '../components/step3/translations/personal-number-step3.translations';
import { TITLE as STEP_4_TITLE } from '../components/step4/translations/personal-number-step4.translations';

export default {
  PERSONAL_NUMBER_RIGHT_COLUMN_PROPS: {
    maxWidth: 276,
  },
  PERSONAL_NUMBER_WIZARD_STEPS: {
    feedbackConfiguration: {
      errorConfiguration: {
        description: ERROR_DESCRIPTION,
        title: ERROR_TITLE,
        exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
      },
      successConfiguration: {
        description: SUCCESS_DESCRIPTION,
        linkLabel: LINK_LABEL,
        title: SUCCESS_TITLE,
        exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
      },
    },
    steps: [
      {
        component: PersonalNumberStep1Component,
        title: STEP_1_TITLE,
      },
      {
        component: PersonalNumberStep2Component,
        title: STEP_2_TITLE,
      },
      {
        component: PersonalNumberStep3Component,
        title: STEP_3_TITLE,
      },
      {
        component: PersonalNumberStep4Component,
        title: STEP_4_TITLE,
      },
    ],
  },
};
