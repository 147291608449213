// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { DEPOSIT_MONEY_EURO_FORMAT_OPTIONS } from '../informative-component/constants/financing-deposit-money-informative-component.constants';
import {
  DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS,
  DEPOSIT_MONEY_PARAMETERS_TABLE_TITLE_PROPS,
} from './constants/financing-deposit-money-parameters-table.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  TitleComponentStyled,
  DepositMoneyParameterTableStyled,
  CreditLimitStyled,
  MaximumLimitStyled,
  ValuesComponentStyled,
  CreditLimitValuesStyled,
  CreditLimitSpanStyled,
} from './financing-deposit-money-parameters-table.component.style';
// Translations
import translations from './translations/financing-deposit-money-parameters-table.translations';
// Types
import { FinancingDepositMoneyParameterTableComponentType } from './types/financing-deposit-money-parameters-table.component.type';

const FinancingDepositMoneyParameterTableComponent = ({
  sections,
}: FinancingDepositMoneyParameterTableComponentType): React.ReactElement => {
  const { formatNumber } = useCbIntl();

  return (
    <DepositMoneyParameterTableStyled>
      <TitleComponentStyled>
        <CreditLimitStyled>
          <ParagraphComponent {...DEPOSIT_MONEY_PARAMETERS_TABLE_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.CREDIT_LIMIT} />
          </ParagraphComponent>
        </CreditLimitStyled>
        <MaximumLimitStyled>
          <ParagraphComponent {...DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS}>
            <FormattedMessageComponent id={translations.INCOMING_TRANSFER} />
          </ParagraphComponent>
        </MaximumLimitStyled>
      </TitleComponentStyled>
      {sections.map((param, index: number) => (
        <ValuesComponentStyled key={index}>
          {index === 0 ? (
            <CreditLimitStyled>
              <ParagraphComponent {...DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS}>
                {formatNumber(param.maxLimit, DEPOSIT_MONEY_EURO_FORMAT_OPTIONS)}
              </ParagraphComponent>
            </CreditLimitStyled>
          ) : (
            <CreditLimitValuesStyled>
              <ParagraphComponent {...DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS}>
                <FormattedMessageComponent
                  id={translations.FROM_TO_LIMITS}
                  values={{
                    minLimitAmount: (
                      <CreditLimitSpanStyled>
                        {formatNumber(param.minLimit, DEPOSIT_MONEY_EURO_FORMAT_OPTIONS)}
                      </CreditLimitSpanStyled>
                    ),
                    maxLimitAmount: (
                      <CreditLimitSpanStyled>
                        {formatNumber(param.maxLimit, DEPOSIT_MONEY_EURO_FORMAT_OPTIONS)}
                      </CreditLimitSpanStyled>
                    ),
                  }}
                />
              </ParagraphComponent>
            </CreditLimitValuesStyled>
          )}
          <MaximumLimitStyled>
            <ParagraphComponent {...DEPOSIT_MONEY_PARAMETERS_TABLE_CONSTANTS_PROPS}>
              {formatNumber(param.transferAmount, DEPOSIT_MONEY_EURO_FORMAT_OPTIONS)}
              <FormattedMessageComponent id={translations.INCOMING_TRANSFER_VALUES} />
            </ParagraphComponent>
          </MaximumLimitStyled>
        </ValuesComponentStyled>
      ))}
    </DepositMoneyParameterTableStyled>
  );
};

export default FinancingDepositMoneyParameterTableComponent;
