// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Translations
import { STILL_INACTIVE } from './translations/financing-ppi-landing.utils.translations';
// Types
import { GetCardHelperMessageType } from './types/get-card-helper-message.type';
import { InsurancesInfoResponseType } from 'types/insurances/insurances-info-response.type';
// Utils
import { isAvailableInsurance } from 'containers/financing/views/ppi/utils/financing-ppi.utils';

const getCardHelperMessage = ({ formatMessage, status }: GetCardHelperMessageType): string =>
  status === InsuranceStatusEnumeration.TEMPORAL ? formatMessage({ id: STILL_INACTIVE }) : '';

const showTermsAndConditionsLink = (status: InsuranceStatusEnumeration): boolean =>
  [
    InsuranceStatusEnumeration.UNHIRED,
    InsuranceStatusEnumeration.EXPIRED,
    InsuranceStatusEnumeration.KYC_PENDING,
  ].includes(status);

const hasAvailableInsurances = ({
  insurances: { CPI, LPI },
}: InsurancesInfoResponseType): boolean =>
  isAvailableInsurance({
    customerContractingEnabled: CPI.customerContractingEnabled,
    featureFlag: FeatureFlagsEnumeration.INSURANCES_CPI,
  }) ||
  isAvailableInsurance({
    customerContractingEnabled: LPI.customerContractingEnabled,
    featureFlag: FeatureFlagsEnumeration.INSURANCES_LPI,
  });

export { getCardHelperMessage, hasAvailableInsurances, showTermsAndConditionsLink };
