// Vendors
import { useEffect } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
import { PersonalNumberStep2ContextConsumerHook } from 'containers/personal/views/number/components/step2/contexts/personal-number-step2.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import PersonalNumberStep2ChallengeHandlers from '../handlers/personal-number-step2.handlers';
import PersonalNumberStep2TrackingHandlers from '../handlers/personal-number-step2.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalNumberStep2HookType } from './types/personal-number-step2.hook.type';

const PersonalNumberStep2Hook = (): PersonalNumberStep2HookType => {
  const { formatMessage } = useCbIntl();

  const { setOtpId } = PersonalNumberStep2ContextConsumerHook();
  const { error, setChallenged, setFetching, setError, newPhoneNumber, newPrefix, progressId } =
    PersonalNumberContextConsumerHook();
  const {
    profile: {
      userDetails: { firstSurname },
    },
  } = AppContextConsumerHook();

  const { handleRequestNewNumberOtpLinkClick } = PersonalNumberStep2ChallengeHandlers({
    ...AppTrackingHook(PersonalNumberStep2TrackingHandlers),
    setChallenged,
    setFetching,
    setError,
    setOtpId,
    newPhoneNumber,
    newPrefix,
    progressId,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleRequestNewNumberOtpLinkClick();
  }, []);

  return {
    formatMessage,
    error,
    newPrefix,
    newPhoneNumber,
    firstLastName: firstSurname,
    handleRequestNewNumberOtpLinkClick,
  };
};

export default PersonalNumberStep2Hook;
