// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';

const CANCEL_STATUSES = [
  InsuranceStatusEnumeration.ACTIVE,
  InsuranceStatusEnumeration.COOLING_OFF,
  InsuranceStatusEnumeration.CREATED,
];

const CONTRACT_STATUSES = [InsuranceStatusEnumeration.EXPIRED, InsuranceStatusEnumeration.UNHIRED];

export { CANCEL_STATUSES, CONTRACT_STATUSES };
