// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import TableRowDescriptionPointsComponent from './components/points/table-row-description-points.component';
import FormattedMessageComponent from '../formatted-message/formatted-message.component';
// Constants
import { TABLE_ROW_DESCRIPTION_POINTS_PROPS } from './constants/table-row-description.constants';
// Hooks
import useTableRowDescriptionHook from './hooks/table-row-description.hook';
// Styles
import { TableRowDescriptionComponentStyled } from './table-row-description.component.styled';
// Types
import { TableRowDescriptionComponentType } from './types/table-row-description.component.type';
// Utilities
import { getTransactionRowDescription } from './utils/table-row-description.utils';

const TableRowDescriptionComponent = ({
  description,
  narrative,
  poEditorNarrative,
  merchantDetails,
  reward,
}: TableRowDescriptionComponentType): React.ReactElement<TableRowDescriptionComponentType> => {
  const { formatMessage } = useTableRowDescriptionHook();

  return (
    <TableRowDescriptionComponentStyled>
      <ParagraphComponent {...TABLE_ROW_DESCRIPTION_POINTS_PROPS}>
        {getTransactionRowDescription({
          description,
          formatMessage,
          merchantDetails,
          narrative,
          poEditorNarrative,
        })}
      </ParagraphComponent>
      <TableRowDescriptionPointsComponent {...{ reward }} />
    </TableRowDescriptionComponentStyled>
  );
};

export default TableRowDescriptionComponent;
