// Enumerations
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

export default {
  ACCESS_RIGHTS_CARD_PROPS: {
    rightsType: AccessPortabilityTypeEnumeration.ACCESS,
  },
  PORTABILITY_RIGHTS_CARD_PROPS: {
    rightsType: AccessPortabilityTypeEnumeration.PORTABILITY,
  },
};
