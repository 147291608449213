// Types
import { ProfilePersonalNameTitleEmptyBuilderReturnHandlersType } from './types/profile-personal-name-title-empty-builder-return.handlers.type';
import { ProfilePersonalNameTitleEmptyBuilderHandlersType } from './types/profile-personal-name-title-empty-builder.handlers.type';

const createNewTitleAssetClickHandler = ({
  handleCreateNewTitleAssetClickTracking,
  setEditTitle,
}: ProfilePersonalNameTitleEmptyBuilderHandlersType): any => {
  handleCreateNewTitleAssetClickTracking();
  setEditTitle(true);
};

const ProfilePersonalNameTitleEmptyHandlers = (
  props: ProfilePersonalNameTitleEmptyBuilderHandlersType
): ProfilePersonalNameTitleEmptyBuilderReturnHandlersType => ({
  handleCreateNewTitleAssetClick: () => createNewTitleAssetClickHandler(props),
});

export { ProfilePersonalNameTitleEmptyHandlers };
