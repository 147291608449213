// Event handlers
import LoginHeaderHandlers from '../handlers/login-header.handlers';
import LoginHeaderTrackingHandlers from '../handlers/login-header.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoginHeaderBuilderReturnHandlersType } from '../handlers/types/login-header-builder-return.handlers.type';

const LoginHeaderHook = (): LoginHeaderBuilderReturnHandlersType =>
  LoginHeaderHandlers(AppTrackingHook(LoginHeaderTrackingHandlers));

export default LoginHeaderHook;
