// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Constants
import constants from './constants/personal-email-logout-modal.constants';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Hooks
import PersonalEmailLogoutModalHook from './hooks/personal-email-logout-modal.hook';
// Styles
import {
  PersonalEmailLogoutModalComponentStyled,
  PersonalEmailCancelModalDescriptionComponentStyled,
} from './personal-email-logout-modal.component.styled';
// Translations
import translations from './translations/personal-email-logout-modal.translations';
// Utilities
import { isLogoutModalConfigured } from './utils/personal-email-logout-modal.utils';

const PersonalEmailLogoutModalComponent = (): React.ReactElement | null => {
  const { handleLogoutFromChangeEmailButtonClick, showModal } = PersonalEmailLogoutModalHook();

  return isLogoutModalConfigured(showModal) ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      primaryButtonConfiguration={{
        ...constants.PERSONAL_EMAIL_LOGOUT_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={translations.BUTTON} />,
        onClick: handleLogoutFromChangeEmailButtonClick,
      }}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <PersonalEmailLogoutModalComponentStyled>
        <AssetComponent {...constants.PERSONAL_EMAIL_LOGOUT_MODAL_ASSET_PROPS} />
        <PersonalEmailCancelModalDescriptionComponentStyled>
          <ParagraphComponent {...constants.PERSONAL_EMAIL_LOGOUT_MODAL_PARAGRAPH_01_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION_01} />
          </ParagraphComponent>
          <ParagraphComponent {...constants.PERSONAL_EMAIL_LOGOUT_MODAL_PARAGRAPH_02_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION_02} />
          </ParagraphComponent>
        </PersonalEmailCancelModalDescriptionComponentStyled>
      </PersonalEmailLogoutModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export default PersonalEmailLogoutModalComponent;
