// Vendors
import { SetStateType } from '@openbank/cf-ui-static-data';
// Constants
import { NUMBER } from 'constants/number.constants';
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/subscription-already-cancelled/contexts/constants/report-subscription-already-cancelled.context.constants';
// Hooks
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { TransactionsReportSubscriptionCancelledDocumentsActionsHookType } from './types/report-subscription-already-cancelled-documents-actions-hook.type';
// Types
import { FileDataType } from 'types/file-data.type';

const useReportSubscriptionAlreadyCancelledDocumentsActionsHook = (
  setFiles: SetStateType<FileDataType[]>
): TransactionsReportSubscriptionCancelledDocumentsActionsHookType => {
  const { setCurrentStep } = useWizardContextConsumerHook();

  const goBackHandler = () => {
    setCurrentStep(NUMBER.ONE);
    setFiles(FILES_INITIAL_STATE);
  };

  return {
    handleGoBack: () => goBackHandler(),
  };
};

export { useReportSubscriptionAlreadyCancelledDocumentsActionsHook };
