// Vendors
import React from 'react';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Components
import {
  ExpandableRowComponent,
  LineSeparatorComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import { FinancingInsurancesContractingLPIAdditionalCoveredComponent } from './components/covered/financing-insurances-contracting-lpi-additional-covered.component';
import { FinancingInsurancesContractingLPIAdditionalNotCoveredComponent } from './components/not-covered/financing-insurances-contracting-lpi-additional-not-covered.component';
import { FinancingInsurancesContractingLPIAdditionalLimitationsComponent } from './components/limitations/financing-insurances-contracting-lpi-additional-limitations.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  EXPANDABLE_ROW_PROPS,
  EXPANDABLE_ROW_TITLE_PROPS,
} from './constants/financing-insurances-contracting-lpi-additional.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { EXPANDABLE_ROW_TITLE } from './translations/financing-insurances-contracting-lpi-additional.translations';

const FinancingInsurancesContractingLPIAdditionalComponent = (): React.ReactElement => (
  <ExpandableRowComponent
    {...EXPANDABLE_ROW_PROPS}
    title={
      <ParagraphComponent {...EXPANDABLE_ROW_TITLE_PROPS}>
        <FormattedMessageComponent id={EXPANDABLE_ROW_TITLE} />
      </ParagraphComponent>
    }
  >
    <LayoutColumnFlexComponentStyled
      marginTop={AmazonTheme.default.spaces.spacingXxs}
      gap={AmazonTheme.default.spaces.spacingS}
    >
      <FinancingInsurancesContractingLPIAdditionalCoveredComponent />
      <LineSeparatorComponent />
      <FinancingInsurancesContractingLPIAdditionalNotCoveredComponent />
      <LineSeparatorComponent />
      <FinancingInsurancesContractingLPIAdditionalLimitationsComponent />
    </LayoutColumnFlexComponentStyled>
  </ExpandableRowComponent>
);

export { FinancingInsurancesContractingLPIAdditionalComponent };
