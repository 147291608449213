// Vendors
import styled from 'styled-components';

const PersonalAddressStep2Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export { PersonalAddressStep2Styled };
