// Vendors
import { sessionUtils } from '@openbank/cf-ui-architecture-core';
// Resolvers
import { refreshTokenResolver } from 'resolvers/refresh-token.resolvers';
// Utils
import { redirectToLoginAndClearSessionData } from 'utils/redirect-to-login.utils';
import { getEnvironmentDomain } from './app.utils';

const isNotLoginView = (): boolean => window.location.pathname !== '/login';

const refreshToken = async (): Promise<boolean | void> => {
  const [response] = await refreshTokenResolver();

  if (response) {
    sessionUtils.saveSessionData({ ...response, domain: getEnvironmentDomain() });
    return true;
  }
  if (isNotLoginView()) {
    redirectToLoginAndClearSessionData();
  }
};

export { isNotLoginView, refreshToken };
