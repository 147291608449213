// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsChangePinChallengeBuilderReturnTrackingHandlersType } from './types/card-settings-change-pin-challenge.tracking.handlers.type';

const validatePinButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'changePin',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsChangePinChallengeTrackingHandlers = (
  track: (param: unknown) => void
): CardSettingsChangePinChallengeBuilderReturnTrackingHandlersType => ({
  handleValidatePinButtonClickTracking: () => track(validatePinButtonClickTrackingHandler()),
});

export default CardSettingsChangePinChallengeTrackingHandlers;
