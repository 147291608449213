// Components
import { PersonalAddressStep1Component } from '../components/step1/personal-address-step1.component';
import { PersonalAddressStep2Component } from '../components/step2/personal-address-step2.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  LINK_LABEL,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
} from '../translations/personal-address.translations';
import { TITLE as STEP_1_TITLE } from '../components/step1/translations/personal-address-step1.translations';
import { TITLE as STEP_2_TITLE } from '../components/step2/translations/personal-address-step2.translations';

export default {
  PERSONAL_ADDRESS_RIGHT_COLUMN_PROPS: {
    maxWidth: 276,
  },
  PERSONAL_ADDRESS_WIZARD_STEPS: {
    feedbackConfiguration: {
      errorConfiguration: {
        description: ERROR_DESCRIPTION,
        title: ERROR_TITLE,
        exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
      },
      successConfiguration: {
        description: SUCCESS_DESCRIPTION,
        linkLabel: LINK_LABEL,
        title: SUCCESS_TITLE,
        exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
      },
    },
    steps: [
      {
        component: PersonalAddressStep1Component,
        title: STEP_1_TITLE,
      },
      {
        component: PersonalAddressStep2Component,
        title: STEP_2_TITLE,
      },
    ],
  },
};
