// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Translations
import { ADDITIONAL, NUMBER, ZIP } from '../translations/address-modal-inputs.translations';

const NUMBER_PROPS = {
  id: 'address-modal-input-number',
  inputProps: {},
  name: InputNamesEnumeration.NUMBER,
  translations: NUMBER,
};

const ADDITIONAL_PROPS = {
  id: 'address-modal-input-additional',
  inputProps: {
    limitCharsConfiguration: { limit: 70 },
  },
  name: InputNamesEnumeration.ADDITIONAL,
  translations: ADDITIONAL,
};

const ZIP_PROPS = {
  id: 'address-modal-input-zip',
  inputProps: {},
  name: InputNamesEnumeration.ZIP,
  translations: ZIP,
};

export { NUMBER_PROPS, ADDITIONAL_PROPS, ZIP_PROPS };
