// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { TitleComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/labels-link-card-title.constants';
// Styles
import { LabelsLinkCardTitleComponentStyled } from './labels-link-card-title.component.styled';
// Types
import { LabelsLinkCardTitleComponentType } from './types/labels-link-card-title.component.type';

const LabelsLinkCardTitleComponent = ({
  title,
  titleId,
  testing: { titleSimpleId, titleHeaderId } = constants.DEFAULT_LABELS_LINK_CARD_TESTING_PROPS,
  useTitleHeader,
}: LabelsLinkCardTitleComponentType): React.ReactElement =>
  <LabelsLinkCardTitleComponentStyled {...{titleId}}>
    {useTitleHeader ? (
      <TitleHeaderComponent {...constants.LABELS_LINK_CARD_TITLE_PROPS} testId={titleHeaderId}>
        <FormattedMessageComponent id={title} />
      </TitleHeaderComponent>
    ) : (
      <TitleComponent {...constants.LABELS_LINK_CARD_TITLE_PROPS} testId={titleSimpleId}>
        <FormattedMessageComponent id={title} />
      </TitleComponent>
    )}
  </LabelsLinkCardTitleComponentStyled>;

export default LabelsLinkCardTitleComponent;
