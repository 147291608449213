// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Types
import { TransactionsEppStep1SimulationSummaryItemHookType } from './types/transactions-epp-step1-simulation-summary-item.hook.type';

const useTransactionsEppStep1SimulationSummaryItemHook =
  (): TransactionsEppStep1SimulationSummaryItemHookType => {
    const { formatNumber } = useCbIntl();

    const { eppPlanSimulation, transactionDetails } = useTransactionsEppContextConsumerHook(),
      { currencyCode } = transactionDetails || {};

    return {
      currencyCode,
      formatNumber,
      eppPlanSimulation,
    };
  };

export { useTransactionsEppStep1SimulationSummaryItemHook };
