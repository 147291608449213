// Types
import {
  AddOtherKeysToMapPropsType,
  AddRangeToMapPropsType,
  AddRangeToMapReturnType,
  CreateChipPropsType,
  CreateChipsReturnType,
  CreateRangePropsType,
  CreateRangePropsTypeReturnType,
  FormatFormValuesToFilterChipsPropsType,
  FormatFormValuesToFilterChipsReturnType,
} from './types/filter-chips-map.utils.type';

const createRange = ({
  id,
  rangeOne,
  rangeTwo,
  handleClickChip,
  key,
}: CreateRangePropsType): CreateRangePropsTypeReturnType => ({
  id,
  rangeOne,
  rangeTwo,
  assetId: 'navigation_arrow',
  onClick: () => handleClickChip({ key }),
});

const createChip = ({
  key,
  formValue,
  handleClickChip,
}: CreateChipPropsType): CreateChipsReturnType => ({
  id: key === 'categoryCode' ? 'category' : key,
  label: formValue?.label,
  onClick: () => handleClickChip({ key }),
  assetId: formValue?.assetId,
});

const addRangeToMap = ({
  id,
  rangeOne,
  rangeTwo,
  handleClickChip,
  ListChipsToMap,
  key,
}: AddRangeToMapPropsType): AddRangeToMapReturnType | void => {
  if (rangeOne && rangeTwo) {
    ListChipsToMap.push(createRange({ id, rangeOne, rangeTwo, handleClickChip, key }));
  }
};

const addOtherKeysToMap = ({
  formValues,
  handleClickChip,
  ListChipsToMap,
}: AddOtherKeysToMapPropsType): void => {
  const otherKeys = Object.keys(formValues).filter(
    key => !['fromDate', 'toDate', 'fromAmount', 'toAmount', 'dateOption'].includes(key)
  );

  otherKeys.forEach(key => {
    if (formValues[key].value !== 'All' && formValues[key].code !== 'All') {
      ListChipsToMap.push(createChip({ key, formValue: formValues[key], handleClickChip }));
    }
  });
};

const formatFormValuesToFilterChips = ({
  formValues,
  handleClickChip,
}: FormatFormValuesToFilterChipsPropsType): FormatFormValuesToFilterChipsReturnType[] => {
  const ListChipsToMap = [];

  addRangeToMap({
    id: 'date-range',
    rangeOne: formValues?.fromDate?.label,
    rangeTwo: formValues?.toDate?.label,
    handleClickChip,
    ListChipsToMap,
    key: 'fromDate',
  });

  addRangeToMap({
    id: 'date-range',
    rangeOne: formValues?.fromAmount?.label,
    rangeTwo: formValues?.toAmount?.label,
    handleClickChip,
    ListChipsToMap,
    key: 'fromAmount',
  });

  addOtherKeysToMap({ formValues, handleClickChip, ListChipsToMap });

  return ListChipsToMap as FormatFormValuesToFilterChipsReturnType[];
};

export { formatFormValuesToFilterChips, addOtherKeysToMap, addRangeToMap, createChip, createRange };
