// Vendors
import React from 'react';
// Components
import { SelectorBoxComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getSelectorBoxConfiguration } from './configurations/financing-payment-method-first-step-payment-options.component.configurations';
// Constants
import { OPTIONS } from './constants/financing-payment-method-first-step-payment-options.constants';
// Hooks
import FinancingPaymentMethodFirstStepPaymentOptionsHook from './hooks/financing-payment-method-first-step-payment-options.hook';
// Styles
import { FinancingPaymentMethodFirstStepPaymentOptionsComponentStyled } from './financing-payment-method-first-step-payment-options.component.styled';

const FinancingPaymentMethodFirstStepPaymentOptionsComponent = (): React.ReactElement => {
  const { formatMessage, handleChangePaymentMethod, paymentMethod } =
    FinancingPaymentMethodFirstStepPaymentOptionsHook();

  return (
    <FinancingPaymentMethodFirstStepPaymentOptionsComponentStyled>
      {OPTIONS.map(({ description, ...props }, i) => (
        <SelectorBoxComponent
          key={i}
          {...getSelectorBoxConfiguration({
            ...props,
            formatMessage,
            handleOnClick: handleChangePaymentMethod,
            selected: paymentMethod,
          })}
        >
          <ParagraphComponent>
            <FormattedMessageComponent id={description} />
          </ParagraphComponent>
        </SelectorBoxComponent>
      ))}
    </FinancingPaymentMethodFirstStepPaymentOptionsComponentStyled>
  );
};

export default FinancingPaymentMethodFirstStepPaymentOptionsComponent;
