// Translations
import { ALL_CONCENTS_ACCEPTED } from '../translations/profile-consents-summary.translations';
// Types
import { ProfileSubConsent } from 'containers/personal/views/consents/types/profile-consent.type';
import { FormatMessageType } from 'types/format-message.type';
import { GetConsentsValueType } from './types/get-consents-value.utils.type';

const getAcceptedSubConsents = (subConsents: Array<ProfileSubConsent>): Array<ProfileSubConsent> =>
  subConsents.filter(subConsent => subConsent.value);

const getRejectedSubConsents = (subConsents: Array<ProfileSubConsent>): Array<ProfileSubConsent> =>
  subConsents.filter(subConsent => !subConsent.value);

const concatenateSubConsents = (
  subConsents: Array<ProfileSubConsent>,
  formatMessage: FormatMessageType
): string =>
  subConsents
    .map(subConsent =>
      subConsent.title ? formatMessage({ id: subConsent.title }) : subConsent.title
    )
    .join(', ');

const getAcceptedConsentsValue = (
  subConsents: Array<ProfileSubConsent>,
  formatMessage: FormatMessageType
): GetConsentsValueType => {
  const acceptedSubConsents = getAcceptedSubConsents(subConsents);
  const hasSomeAcceptedSubConsents = acceptedSubConsents.length < subConsents.length;

  return {
    value: hasSomeAcceptedSubConsents
      ? concatenateSubConsents(acceptedSubConsents, formatMessage)
      : formatMessage({
          id: ALL_CONCENTS_ACCEPTED,
        }),
  };
};

const getRejectedConsentsValue = (
  subConsents: Array<ProfileSubConsent>,
  formatMessage: FormatMessageType
): GetConsentsValueType => {
  const rejectedSubConsents = getRejectedSubConsents(subConsents);
  const hasSomeRejectedSubConsents = rejectedSubConsents.length < subConsents.length;

  return {
    value: hasSomeRejectedSubConsents
      ? concatenateSubConsents(rejectedSubConsents, formatMessage)
      : formatMessage({
          id: ALL_CONCENTS_ACCEPTED,
        }),
  };
};

export {
  getAcceptedSubConsents,
  getRejectedSubConsents,
  getAcceptedConsentsValue,
  getRejectedConsentsValue,
};
