// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  EVENT_ACTION,
  OPEN_CONTRACT_MODAL_EVENT_LABEL,
  CLOSE_CONTRACT_MODAL_EVENT_LABEL,
  UNDERSTOOD_CONTRACT_MODAL_EVENT_LABEL,
} from './constants/financing-insurances-landing-card.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingInsurancesLandingCardReturnTrackingHandlersType } from './types/financing-insurances-landing-card-return.handlers.tracking.type';

const contractModalButtonClickTrackingHandler = (
  eventLabel: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: EVENT_ACTION,
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

export const FinancingInsurancesLandingCardTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesLandingCardReturnTrackingHandlersType => ({
  handleContractModalButtonClickTracking: eventLabel =>
    track(contractModalButtonClickTrackingHandler(eventLabel)),
});
