// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { RecoveryKeyStep1PhoneBuilderReturnTrackingHandlersType } from './types/recovery-key-phone.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const managePhoneNumberRecoveryStep1InputErrorTrackingHandler =
  (): TrackingErrorEventReturnType => ({
    payload: {
      eventAction: 'invalidPhoneNumber',
      eventLabel: 'invalidPhoneNumber',
      errorCategory: 'invalidPhoneNumber',
      errorDescription: 'invalidPhoneNumber',
    },
    type: TrackingEventsEnum.LINK,
  });

const saveRecoveryStep1PrefixOptionClickEventHandler = (
  prefix: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickDropdown',
    eventLabel: 'prefix:' + prefix,
  },
  type: TrackingEventsEnum.LINK,
});

const saveRecoveryStep1NumberInputClickEventTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'phoneNumber',
  },
  type: TrackingEventsEnum.LINK,
});

const RecoveryKeyStep1PhoneTrackingHandlers = (
  track: TrackBuilderType
): RecoveryKeyStep1PhoneBuilderReturnTrackingHandlersType => ({
  handleManagePhoneNumberRecoveryStep1InputErrorTracking: () =>
    track(managePhoneNumberRecoveryStep1InputErrorTrackingHandler()),
  handleSaveRecoveryStep1PrefixOptionClickTracking: (prefix: string) =>
    track(saveRecoveryStep1PrefixOptionClickEventHandler(prefix)),
  handleSaveRecoveryStep1NumberInputClickEventTracking: () =>
    track(saveRecoveryStep1NumberInputClickEventTrackingHandler()),
});

export default RecoveryKeyStep1PhoneTrackingHandlers;
