// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const FinancingLandingRowDetailsComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingS} 0 ${({ theme }) => theme.spaces.spacingS}
    ${convertPxsToRems({ pixels: 112, base: 14 })};
  text-align: left;
  width: 100%;

  ${mobileMediaQuery} {
    padding: 0 0 0 ${({ theme }) => theme.spaces.spacingL};
  }
`;

FinancingLandingRowDetailsComponentStyled.displayName = 'FinancingLandingRowDetailsComponentStyled';

const TransactionsLandingRowDetailsResumeLinkComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  width: 100%;
`;

TransactionsLandingRowDetailsResumeLinkComponentStyled.displayName = 'TransactionsLandingRowDetailsResumeLinkComponentStyled';

export {
  FinancingLandingRowDetailsComponentStyled,
  TransactionsLandingRowDetailsResumeLinkComponentStyled,
};
