// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from './configurations/request-portability-step3-challenge.component.configurations';
// Hooks
import RequestPortabilityStep3ChallengeHook from './hooks/request-portability-step3-challenge.hook';
// Styles
import { RequestPortabilityStep3ChallengeComponentStyled } from './request-portability-step3-challenge.component.styled';

const RequestPortabilityStep3ChallengeComponent = (): React.ReactElement => {
  const {
    error,
    portabilityParams,
    handleChallengeRetryButtonClick,
    handleManageErrorOnRequestPortabilityEvent,
    handleManageSuccessOnRequestPortabilityEvent,
  } = RequestPortabilityStep3ChallengeHook();

  return (
    <RequestPortabilityStep3ChallengeComponentStyled>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          portabilityParams: portabilityParams || {},
          challengeError: error,
          handleChallengeError: handleManageErrorOnRequestPortabilityEvent,
          handleChallengeSuccess: handleManageSuccessOnRequestPortabilityEvent,
          handleChallengeRetryButtonClick,
        })}
      />
    </RequestPortabilityStep3ChallengeComponentStyled>
  );
};

export default RequestPortabilityStep3ChallengeComponent;
