// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_RESUME_FEE_PROPS,
  FINANCING_PPI_CONTRACTING_RESUME_HOLDER_PROPS,
  FINANCING_PPI_CONTRACTING_RESUME_PRODUCT_PROPS,
} from './constants/financing-ppi-contracting-resume.constants';
// Hooks
import { useFinancingPPIContractingResumeHook } from './hooks/financing-ppi-contracting-resume.hook';
// Styles
import { FinancingPPIContractingComponentStyled } from './financing-ppi-contracting-resume.component.styled';
// Translations
import {
  FEE,
  VALUE_FEE,
  POLICY_HOLDER,
  VALUE_POLICY_HOLDER,
  PRODUCT_NAME,
} from './translations/financing-ppi-contracting-resume.translations';

const FinancingPPIContractingResumeComponent = (): React.ReactElement => {
  const { productName, ppiPercentage, userName } = useFinancingPPIContractingResumeHook();

  return (
    <FinancingPPIContractingComponentStyled>
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_RESUME_PRODUCT_PROPS}
        label={<FormattedMessageComponent id={PRODUCT_NAME} />}
        value={productName}
      />
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_RESUME_HOLDER_PROPS}
        label={<FormattedMessageComponent id={POLICY_HOLDER} />}
        value={
          <FormattedMessageComponent
            id={VALUE_POLICY_HOLDER}
            values={{
              userName,
            }}
          />
        }
      />
      <ResumeItemComponent
        {...FINANCING_PPI_CONTRACTING_RESUME_FEE_PROPS}
        label={<FormattedMessageComponent id={FEE} />}
        value={
          <FormattedMessageComponent
            id={VALUE_FEE}
            values={{
              ppiPercentage: <FormattedNumber value={Number(ppiPercentage)} />,
            }}
          />
        }
      />
    </FinancingPPIContractingComponentStyled>
  );
};

export { FinancingPPIContractingResumeComponent };
