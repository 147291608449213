// Components
import NextStepErrorCreditLimitIncreaseComponent from '../components/next-step-error/next-step-error-credit-limit-increase.component';
import VerifyingDetailsCreditLimitIncreaseComponent from '../components/verifying-details/verifying-details-credit-limit-increase.component';
// Types
import { GetStepPropsType } from './types/get-step-props.type';
import { GetStepReturnType } from './types/get-step-return.type';
import { Step2Type } from '../contexts/types/step-2-credit-limit-increase.context.type';

export const getStepConfiguration = ({
  currentStep,
  step,
  component,
  title,
}: GetStepPropsType): GetStepReturnType => {
  if ((currentStep === 2 || currentStep === 4) && step.isLoadingNextRequest) {
    return { component: VerifyingDetailsCreditLimitIncreaseComponent };
  }

  if (currentStep === 2 && (step as Step2Type).isRejectM1) {
    return { component: NextStepErrorCreditLimitIncreaseComponent };
  }

  return {
    component,
    title,
  };
};
