// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TRANSACTION_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_HANDLERS_TRACKING_PROPS } from './constants/transactions-landing-row-details-create-plan-modal.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TransactionsLandingRowDetailsCreatePlanModalTrackingMethodsHandlersType } from './types/transactions-landing-row-details-create-plan-modal.tracking.handlers.type';

const confirmContinueCreatePlanButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...TRANSACTION_LANDING_ROW_DETAILS_CREATE_PLAN_MODAL_HANDLERS_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const TransactionsLandingRowDetailsCreatePlanModalTrackingHandlers = (
  track: TrackBuilderType
): TransactionsLandingRowDetailsCreatePlanModalTrackingMethodsHandlersType => ({
  handleConfirmContinueCreatePlanButtonClickTracking: () =>
    track(confirmContinueCreatePlanButtonClickTrackingHandler()),
});

export { TransactionsLandingRowDetailsCreatePlanModalTrackingHandlers };
