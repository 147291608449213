// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const PersonalNumberStep1ComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileMediaQuery} {
    flex-direction: column;
    width: 100%;
  }
`;

export const PersonalNumberStep1MessageViewStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;
