// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Hooks
import { useTransactionsEppModalHook as useEppProcessModalHook } from 'containers/transactions/views/epp/hooks/transactions-epp-modal.hook';
// Types
import { TransactionsEppModalReturnHookType } from './types/transactions-epp-modal.hook.type';

const useTransactionsEppModalHook = (): TransactionsEppModalReturnHookType => {
  const { eppPlanSimulation, showAmortizationTableModal } = useTransactionsEppContextConsumerHook(),
    { installments } = eppPlanSimulation;

  const { handleToggleAmortizationTableModalLinkClick } = useEppProcessModalHook();

  return {
    handleToggleAmortizationTableModalLinkClick,
    installments,
    showAmortizationTableModal,
  };
};

export { useTransactionsEppModalHook };
