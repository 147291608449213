// Resolver
import { accessKeyChangeCheckpointResolver } from '../../challenge/resolvers/security-keys-access-key-change-third-step-challenge.resolvers';
// Types
import { SetupFinishActionsEventHandlerParamsType } from '../hook/types/security-keys-access-key-change-component-step3-actions-setup-finish-handlers.type';
import { AccessKeyChangeStep3ChallengeHandlersReturnType } from './types/security-keys-access-key-change-third-step-actions-handlers-return.type';
import { AccessKeyChangeStep3ActionsHandlersParamsType } from './types/security-keys-access-key-change-third-step-actions-handlers.type';

const setupFinishActionsEventHandler = async ({
  accessKey,
  handleNextButtonClickTrackingHandlerStep3,
  newAccessKey,
  progressId,
  setAccessKey,
  setNewAccessKey,
  setRepeatNewAccessKey,
  setResult,
  setProgressId,
}: SetupFinishActionsEventHandlerParamsType): Promise<void> => {
  const [response, error] = await accessKeyChangeCheckpointResolver({
    accessKey,
    newAccessKey,
    progressId,
  });
  handleNextButtonClickTrackingHandlerStep3();
  if (response && !error) {
    setResult(true);
  } else {
    setResult(false);
  }
  setAccessKey('');
  setNewAccessKey('');
  setProgressId('');
  setRepeatNewAccessKey('');
};

const AccessKeyChangeStep3ActionsHandlers = ({
  accessKey,
  handleNextButtonClickTrackingHandlerStep3,
  newAccessKey,
  progressId,
  setAccessKey,
  setNewAccessKey,
  setProgressId,
  setRepeatNewAccessKey,
  setResult,
}: AccessKeyChangeStep3ActionsHandlersParamsType): AccessKeyChangeStep3ChallengeHandlersReturnType => ({
  handleFinishActionsEvent: () =>
    setupFinishActionsEventHandler({
      accessKey,
      handleNextButtonClickTrackingHandlerStep3,
      newAccessKey,
      progressId,
      setAccessKey,
      setNewAccessKey,
      setProgressId,
      setRepeatNewAccessKey,
      setResult,
    }),
});

export default AccessKeyChangeStep3ActionsHandlers;
