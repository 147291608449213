// Types
import { CustomerDataType } from 'types/customer/customer-data.type';

const isLegitClientDataApiResponse = (response: CustomerDataType | Record<string, any>): boolean =>
  Boolean(
    response?.userDetails &&
      response?.addressDetails &&
      response?.contactDetails &&
      response?.birthDetails
  );

export { isLegitClientDataApiResponse };
