// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { TravelPlusCostHookType } from 'components/travel-plus/components/cost/hooks/types/travel-plus-cost.hook.type';

const useTravelPlusCostHook = (): TravelPlusCostHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { travelPlus } = currentCard || {},
    { activated, currency, finalCost } = travelPlus || {};

  return {
    activated,
    currency,
    finalCost,
  };
};

export { useTravelPlusCostHook };
