const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.';

export default {
  PREVIOUS: `${DOMAIN}previous`,
  CANCEL: `${DOMAIN}cancel`,
  NEXT: `${DOMAIN}next`,
  EXIT: `${DOMAIN}exit`,
  TRY_AGAIN: `${DOMAIN}tryAgain`,
  SIDEBAR_TITLE: `${DOMAIN}sidebarTitle`,
  SIDEBAR_HOW_DOES_IT_WORK: `${DOMAIN}sidebarHowDoesItWork`,
  SIDEBAR_HOW_DOES_IT_WORK_DESCRIPTION: `${DOMAIN}sidebarHowDoesItWorkDescription`,
  SIDEBAR_WHAT_RISK: `${DOMAIN}sidebarWhatRisk`,
  SIDEBAR_WHAT_RISK_DESCRIPTION: `${DOMAIN}sidebarWhatRiskDescription`,
  SIDEBAR_INTEREST_CHARGES: `${DOMAIN}sidebarInterestCharges`,
  SIDEBAR_INTEREST_CHARGES_DESCRIPTION: `${DOMAIN}sidebarInterestChargesDescription`,
  SIDEBAR_BANK_AGGREGATION: `${DOMAIN}sidebarAccountAggregation`,
  SIDEBAR_BANK_AGGREGATION_DESCRIPTION: `${DOMAIN}sidebarAccountAggregationDescription`,
  SOMETHING_WENT_WRONG: `${DOMAIN}somethingWentWrong`,
};

const PAGE_TITLE = `${DOMAIN}pageTitle`;

export { PAGE_TITLE };
