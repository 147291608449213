// Services
import { getDocumentLinkService } from '../services/request-table-row.service';
// Types
import { type RequestTableRowResolverPropsType } from './types/request-table-row.resolver.type';
import { type RequestTableRowApiReturnType } from '../types/request-table-row-api-return.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const getDocumentLinkResolver = async ({
  documentId,
}: RequestTableRowResolverPropsType): Promise<[RequestTableRowApiReturnType] | [null, true]> => {
  const [response, { status }] = await getDocumentLinkService({ documentId });

  return wasSuccessRequest(status) ? [response] : [null, true];
};

export { getDocumentLinkResolver };
