// Types
import { createObjectUrlFromBlob, fileIsBlobType } from '@openbank/cf-ui-framework';
// Services
import { fetchContractingPPITermsFileService } from '../services/financing-ppi-contracting-step2-terms-link.service';

const fetchContractingPPITermsFileResolver = async (): Promise<[any | null] | [null, boolean]> => {
  const [response] = await fetchContractingPPITermsFileService();

  return fileIsBlobType(response) ? [createObjectUrlFromBlob(response)] : [null, true];
};

export { fetchContractingPPITermsFileResolver };
