// Vendors
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
// Constants
import { LANDING_ROUTE } from '../../constants/financing-insurances.constants';
// Types
import { InsuranceInfoBuilderType } from '../../types/insurance-info-builder.type';
// Utils
import { isAvailableInsurance } from 'containers/financing/views/ppi/utils/financing-ppi.utils';

export const FinancingInsurancesRouterInsuranceComponent = ({
  insurance,
  featureFlag,
}: InsuranceInfoBuilderType): React.ReactElement => {
  if (
    insurance &&
    isAvailableInsurance({
      customerContractingEnabled: insurance.customerContractingEnabled,
      featureFlag,
    })
  ) {
    return <Outlet />;
  }

  return <Navigate to={LANDING_ROUTE} />;
};
