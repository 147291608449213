// Vendors
import { TrackingEventsEnum, TrackingViewEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { ConsentsStep2TrackingHandlersType } from './types/profile-consents-step2.tracking.handlers.type';

const onConsentsStep2LoadedTracking = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/comunicationManagement/step02/confirmation',
    sectionFamily: 'banking',
    sectionCategory: 'settings',
    sectionName: 'accounts',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const onSuccessChallengeTracking = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/comunicationManagement/success/commercialCommunicationsUpdated',
    sectionFamily: 'banking',
    sectionCategory: 'settings',
    sectionName: 'accounts',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const ConsentsStep2TrackingHandlers = (
  track: TrackBuilderType
): ConsentsStep2TrackingHandlersType => ({
  handleConsentsStep2LoadedTracking: () => track(onConsentsStep2LoadedTracking()),
});

export { ConsentsStep2TrackingHandlers };
