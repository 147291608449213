// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getModalConfiguration } from './configurations/card-settings-block-card-replacement-modal.component.configurations';
// Hooks
import CardSettingsBlockCardReplacementModalHook from './hooks/card-settings-block-card-replacement-modal.hook';
// Translations
import { DESCRIPTION } from './translations/card-settings-block-card-replacement-modal.translations';
// Types
import { CardSettingsBlockCardReplacementModalComponentType } from './types/card-settings-block-card-replacement-modal.component.type';

const CardSettingsBlockCardReplacementModalComponent = ({
  actions,
  isOpen,
}: CardSettingsBlockCardReplacementModalComponentType): React.ReactElement | null => {
  const { formatMessage } = CardSettingsBlockCardReplacementModalHook();

  return isOpen ? (
    <ModalComponent
      {...getModalConfiguration({ formatMessage, ...actions })}
      isIosDevice={isIosWebView()}
    >
      <ParagraphComponent>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
    </ModalComponent>
  ) : null;
};

export default CardSettingsBlockCardReplacementModalComponent;
