// Vendors
import styled from 'styled-components';

export const LoginErrorViewStyled = styled.section`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};
  text-align: left;
  width: 100%;
`;

export const LoginEmailInputMessageViewStyled = styled(LoginErrorViewStyled)`
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
