// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { TransactionsEppStep1SimulationInterestsComponent } from './components/interests/transactions-epp-step1-simulation-interests.component';
import { TransactionsEppStep1SimulationOptionsComponent } from './components/options/transactions-epp-step1-simulation-options.component';
import { TransactionsEppStep1SimulationSummaryComponent } from './components/summary/transactions-epp-step1-simulation-summary.component';
// Constants
import {
  TRANSACTIONS_EPP_STEP1_SIMULATION_DESCRIPTION_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_LAYOUT_PROPS,
} from './constants/transactions-epp-step1-simulation.constants';
// Styles
import {
  Flex1ComponentStyled,
  LayoutColumnFlexComponentStyled,
  LayoutRowFlexComponentStyled,
} from 'styles/app.styled';
// Translations
import { DESCRIPTION } from './translations/transactions-epp-step1-simulation.translations';

const TransactionsEppStep1SimulationComponent = (): React.ReactElement => (
  <LayoutColumnFlexComponentStyled {...TRANSACTIONS_EPP_STEP1_SIMULATION_LAYOUT_PROPS}>
    <ParagraphComponent {...TRANSACTIONS_EPP_STEP1_SIMULATION_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <LayoutRowFlexComponentStyled>
      <Flex1ComponentStyled>
        <LayoutColumnFlexComponentStyled>
          <LayoutColumnFlexComponentStyled {...TRANSACTIONS_EPP_STEP1_SIMULATION_LAYOUT_PROPS}>
            <TransactionsEppStep1SimulationOptionsComponent />
          </LayoutColumnFlexComponentStyled>
          <TransactionsEppStep1SimulationInterestsComponent />
        </LayoutColumnFlexComponentStyled>
      </Flex1ComponentStyled>
      <TransactionsEppStep1SimulationSummaryComponent />
    </LayoutRowFlexComponentStyled>
  </LayoutColumnFlexComponentStyled>
);

export { TransactionsEppStep1SimulationComponent };
