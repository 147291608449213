// Vendors
import { useState } from 'react';
// Event handlers
import TransactionsLandingRowTrackingHandlers from '../handlers/table-row.tracking.handlers';
import TransactionsLandingRowHandlers from '../handlers/table-row.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TableRowHookType } from './types/table-row.hook.type';

const TableRowHook = (defaultOpen = false): TableRowHookType => {
  const [open, setOpen] = useState<boolean>(defaultOpen);

  return {
    ...TransactionsLandingRowHandlers({
      ...AppTrackingHook(TransactionsLandingRowTrackingHandlers),
      setOpen,
    }),
    open,
  };
};

export default TableRowHook;
