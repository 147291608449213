// Vendors
import React from 'react';
// Components
import { ButtonComponent, ParagraphComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import RequestTableRowComponent from './components/table-row/request-table-row.component';
// Constants
import constants from './constants/access-portability-request-table.constants';
// Styled
import {
  AccessPortabilityTableContainerStyled,
  AccessPortabilityTableContentStyled,
} from './access-portability-request-table.component.styled';
// Translations
import translations from './translations/access-portability-request-table.translations';
// Types
import { AccessPortabilityRequestTableComponentType } from './types/access-portability-request-table.component.type';
import { AccessPortabilityComponentContentType } from '../../types/access-portability.component.type';
// Utils
import { isThereAPendingRequest } from '../../utils/access-portability.utils';

const AccessPortabilityRequestTableComponent = ({
  information,
  email,
  handleAccessPortabilityRequestClick,
  rightsType,
}: AccessPortabilityRequestTableComponentType): React.ReactElement => {
  const { content } = information;

  return (
    <AccessPortabilityTableContainerStyled>
      <ParagraphComponent {...constants.ACCESS_PORTABILITY_NEW_REQUEST_PARAGRAPH_01_PROPS}>
        <FormattedMessageComponent id={translations[rightsType].TABLE_TITLE} />
      </ParagraphComponent>
      <AccessPortabilityTableContentStyled>
        {content?.map((requestContent: AccessPortabilityComponentContentType) => (
          <RequestTableRowComponent
            {...{ ...requestContent, email }}
            key={requestContent.requestId}
          />
        ))}
        <ButtonComponent
          {...constants.ACCESS_PORTABILITY_NEW_REQUEST_BUTTON_CONFIGURATION}
          onClick={handleAccessPortabilityRequestClick}
          disabled={isThereAPendingRequest(information)}
        >
          <FormattedMessageComponent id={translations[rightsType].TABLE_BUTTON} />
        </ButtonComponent>
      </AccessPortabilityTableContentStyled>
    </AccessPortabilityTableContainerStyled>
  );
};

export default AccessPortabilityRequestTableComponent;
