// Vendors
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';
import styled, { css } from 'styled-components';

export const KoMessageMessageComponentStyled = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      /* MessageComponentStyled */
      & > div {
        flex-direction: row;
        padding: ${spaces.spacingM} ${spaces.spacingXxxxl};

        ${tabletMediaQuery} {
          padding: ${spaces.spacingM} ${spaces.spacingM};
        }

        ${mobileMediaQuery} {
          padding: ${spaces.spacingM} ${spaces.spacingS};
        }

        /* MessageComponentIconTypeStyled */
        & > aside {
          padding-left: ${spaces.spacingXs};
          padding-right: ${spaces.spacingS};

          /* AssetComponent */
          & > span, & > span > svg {
            height: ${spaces.spacingXxl};
            width: ${spaces.spacingXxl};
          }

          ${tabletMediaQuery} {
            & > span, & > span > svg {
              height: ${spaces.spacingL};
              width: ${spaces.spacingL};
            }
          }

          ${mobileMediaQuery} {
            padding-left: 0;
            padding-right: ${spaces.spacingXxs};

            & > span, & > span > svg {
              height: initial;
              width: initial;
            }
          }
        }
      }
    `;
  }}
`;

export const KoMessageTitleComponentStyled = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      line-height: ${spaces.spacingXxl};
      margin-bottom: ${spaces.spacingXxs};

      ${tabletMediaQuery} {
        line-height: ${spaces.spacingL};
        margin-bottom: ${spaces.spacingXxxs};
      }

      ${mobileMediaQuery} {
        line-height: ${spaces.spacingM};
        margin-bottom: ${spaces.spacingXxxs};
      }
    `;
  }}
`;

export const KoMessageNavigationComponentStyled = styled.div`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      & > footer {
        gap: 0;

        & > section {
          gap: 0;

          & > div {
            display: none;
          }

          & > aside {
            flex: 1;
            padding: ${spaces.spacingS} ${spaces.spacingXxxxxl};

            ${tabletMediaQuery} {
              padding: ${spaces.spacingS} ${spaces.spacingXl};
            }

            ${mobileMediaQuery} {
              align-items: flex-end;
              padding: ${spaces.spacingL} ${spaces.spacingS};
            }
          }
        }
      }
    `;
  }}
`;
