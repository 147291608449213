// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import { getSupportCancelContractWizardConfiguration } from './configurations/support-cancel-contract-wizard.configuration';
// Hooks
import { useSupportCancelContract } from '../../hooks/support-cancel-contract.hook';

const SupportCancelContractWizard = (): React.ReactElement => {
  const { handleLinkClickEvent } = useSupportCancelContract();

  return (
    <WizardComponent
      {...getSupportCancelContractWizardConfiguration({
        onLinkClick: handleLinkClickEvent,
      })}
    />
  );
};

export { SupportCancelContractWizard };
