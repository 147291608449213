const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbDetails.';

const AMOUNT = `${DOMAIN}amountLabel`,
  AMOUNT_VALUE = 'category',
  EDIT_AMOUNT = `${DOMAIN}editAmountLink`,
  AUTHORIZED_CATEGORY = `${DOMAIN}authorizedStatus`,
  SEE_DETAILS = `${DOMAIN}seeDetails`;

export {
  AMOUNT,
  AMOUNT_VALUE,
  EDIT_AMOUNT,
  AUTHORIZED_CATEGORY,
  SEE_DETAILS,
};
