// Vendors
import { useNavigate } from 'react-router-dom';
import useCbIntl from 'hooks/useCbIntl';
// Constants
import { CARD_SETTINGS_BLOCK_BUTTON_PROPS } from './constants/card-settings-landing-footer.hook.constants';
// Enumerations
import { QuickActionsActionsIdsEnumeration } from 'enumerations/quick-actions/quick-actions-actions-ids.enumeration';
// Handlers
import { CardSettingsLandingFooterHookHandlers } from './handlers/card-settings-landing-footer.hook.handlers';
import { CardSettingsLandingFooterHookTrackingHandlers } from './handlers/card-settings-landing-footer.hook.tracking.handlers';
// Hooks
import { useCardSettingsLandingActionsHook } from 'containers/card-settings/views/landing/hooks/card-settings-landing-actions.hook';
// Translations
import { BUTTON } from './translations/card-settings-footer.hook.common.translations';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsLandingFooterHookType } from './types/card-settings-landing-footer.hook.type';

const useCardSettingsLandingFooterCommonHook = (): Pick<
  CardSettingsLandingFooterHookType,
  'rightButtonConfiguration'
> => {
  const { formatMessage } = useCbIntl();

  const { handleBlockDefinitiveCardButtonClick } = CardSettingsLandingFooterHookHandlers({
    ...AppTrackingHook(CardSettingsLandingFooterHookTrackingHandlers),
    navigate: useNavigate(),
  });

  return {
    rightButtonConfiguration: {
      ...CARD_SETTINGS_BLOCK_BUTTON_PROPS,
      children: formatMessage({ id: BUTTON }),
      disabled: useCardSettingsLandingActionsHook(QuickActionsActionsIdsEnumeration.BLOCK),
      onClick: handleBlockDefinitiveCardButtonClick,
    },
  };
};

export { useCardSettingsLandingFooterCommonHook };
