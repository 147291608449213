// Handlers
import { ChallengeHandlers } from 'handlers/challenge/challenge.handlers';
// Contexts
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
// Types
import { ReportProblemChallengeReturnHookType } from './types/report-problem-challenge-return.hook.type';

const ReportProblemChallengeHook = (): ReportProblemChallengeReturnHookType => {
  const { challengeError, setChallenge, setChallengeError } =
    useTransactionsReportContextConsumerHook();

  return {
    ...ChallengeHandlers({
      setChallenge,
      setChallengeError,
    }),
    challengeError,
  };
};

export { ReportProblemChallengeHook };
