// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/dashboard-spent-message.constants';
// Styles
import {
  DashboardSpentMessageWrapperComponentStyled,
  DashboardSpentMessageInnerComponentStyled,
  DashboardSpentMessageTopComponentStyled,
} from './dashboard-spent-message.component.styled';
// Types
import { DashboardSpentMessageComponentType } from './types/dashboard-spent-message.component.type';

const DashboardSpentMessageComponent = ({
  spentMessageConfiguration,
  testId = constants.DASHBOARD_SPENT_MESSAGE_DEFAULT_TEST_ID,
}: DashboardSpentMessageComponentType): React.ReactElement | null => {
  if (!spentMessageConfiguration) {
    return null;
  }

  return (
    <DashboardSpentMessageWrapperComponentStyled {...{ testId }}>
      <MessageComponent
        {...constants.DASHBOARD_SPENT_MESSAGE_PROPS}
        type={spentMessageConfiguration.type}
      >
        <DashboardSpentMessageInnerComponentStyled>
          <DashboardSpentMessageTopComponentStyled>
            <ParagraphComponent {...constants.DASHBOARD_SPENT_MESSAGE_PARRAGRAPH_PROPS}>
              {spentMessageConfiguration.title}
            </ParagraphComponent>
            <ParagraphComponent>{spentMessageConfiguration.description}</ParagraphComponent>
          </DashboardSpentMessageTopComponentStyled>
        </DashboardSpentMessageInnerComponentStyled>
      </MessageComponent>
    </DashboardSpentMessageWrapperComponentStyled>
  );
};

export default DashboardSpentMessageComponent;
