// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  PERSONAL_NUMBER_STEP_1_RESUME_ITEM_PROPS: {
    testId: 'personal-number-step1',
  },
  PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_TITLE_PROPS: {
    bold: true,
    testId: 'personal-number-step1-error-message-title',
  },
  PERSONAL_NUMBER_STEP_1_DESCRIPTION_PROPS: {
    testId: 'personal-number-step1-description',
  },
  PERSONAL_NUMBER_STEP_1_MESSAGE_TITLE_PROPS: {
    bold: true,
    testId: 'personal-number-step1-message-title',
  },
  PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_PROPS: {
    testId: 'personal-number-step1-error-message',
    type: messageConstants.TYPES.ERROR,
  },
  PERSONAL_NUMBER_STEP_1_ERROR_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'personal-number-step1-error-message-description',
  },
  PERSONAL_NUMBER_STEP_1_MESSAGE_PROPS: {
    testId: 'personal-number-step1-message',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  PERSONAL_NUMBER_STEP_1_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'personal-number-step1-message-description',
  },
};
