// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Types
import { CreditUtilsType } from './types/credit.utils.type';

const formatCreditAmount = ({
  currency,
  formatNumber,
  value,
  minimumFractionDigits,
  maximumFractionDigits,
}: CreditUtilsType): string =>
  formatNumber(value, {
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
  });

export { formatCreditAmount };
