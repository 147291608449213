// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsBlockCardSuccessContentHookReturnType } from './types/card-settings-block-card-success-content-return.hook.type';

const CardSettingsBlockCardSuccessContentHook =
  (): CardSettingsBlockCardSuccessContentHookReturnType => {
    const { formatMessage } = useCbIntl();

    return {
      formatMessage,
    };
  };

export default CardSettingsBlockCardSuccessContentHook;
