// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { TransactionsLandingRowDetailsReportBuilderHandlersType } from './types/transactions-landing-row-details-report-builder.handlers.type';
import { TransactionsLandingRowDetailsReportBuilderReturnHandlersType } from './types/transactions-landing-row-details-report-builder-return.handlers.type';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const reportTransactionProblemLinkClickHandler = ({
  handleReportTransactionProblemLinkClickTracking,
  id,
  navigate,
}: TransactionsLandingRowDetailsReportBuilderHandlersType): any => {
  handleReportTransactionProblemLinkClickTracking();
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.TRANSACTIONS, id, RoutesEnumeration.REPORT_A_PROBLEM])
    )
  );
};

const TransactionsLandingRowDetailsReportHandlers = (
  props: TransactionsLandingRowDetailsReportBuilderHandlersType
): TransactionsLandingRowDetailsReportBuilderReturnHandlersType => ({
  handleReportTransactionProblemLinkClick: () => reportTransactionProblemLinkClickHandler(props),
});

export default TransactionsLandingRowDetailsReportHandlers;
