// Types
import { RequestSignatureKeyConfirmationHandlersReturnType } from './types/request-signature-key-confirmation.handlers-return.type';
import { RequestSignatureKeyConfirmationHandlersType } from './types/request-signature-key-confirmation.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const goToDashboardButtonHandler = ({
  handleGoToDashboardButtonClickTracking,
  route,
  onNavigateHybridFlow,
}: RequestSignatureKeyConfirmationHandlersType) => {
  handleGoToDashboardButtonClickTracking();
  onNavigateHybridFlow?.(generateAbsoluteRoute(route));
};

const RequestSignatureKeyConfirmationHandlers = ({
  handleGoToDashboardButtonClickTracking,
  route,
  onNavigateHybridFlow,
}: RequestSignatureKeyConfirmationHandlersType): RequestSignatureKeyConfirmationHandlersReturnType => ({
  handleGoToDashboardButtonClick: () =>
    goToDashboardButtonHandler({
      handleGoToDashboardButtonClickTracking,
      route,
      onNavigateHybridFlow,
    }),
});

export { RequestSignatureKeyConfirmationHandlers };
