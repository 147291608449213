// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalNumberStep1ContextType } from './types/personal-number-step1.context.type';

const PersonalNumberStep1Context = createContext<PersonalNumberStep1ContextType>(
  {} as PersonalNumberStep1ContextType
);

const PersonalNumberStep1ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalNumberStep1ContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [prefix, setPrefix] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [repeatPrefix, setRepeatPrefix] = useState<string>('');
  const [repeatPhoneNumber, setRepeatPhoneNumber] = useState<string>('');
  const [phonesMatch, setPhonesMatch] = useState<boolean>(false);
  const [phoneIsValid, setPhoneIsValid] = useState<boolean>(false);

  return (
    <PersonalNumberStep1Context.Provider
      value={{
        fetching,
        prefix,
        phoneNumber,
        repeatPrefix,
        repeatPhoneNumber,
        phonesMatch,
        phoneIsValid,
        setFetching,
        setPrefix,
        setRepeatPrefix,
        setPhoneNumber,
        setRepeatPhoneNumber,
        setPhonesMatch,
        setPhoneIsValid,
      }}
    >
      {children as React.ReactNode}
    </PersonalNumberStep1Context.Provider>
  );
};

const usePersonalNumberStep1ContextConsumerHook = (): PersonalNumberStep1ContextType =>
  useContext(PersonalNumberStep1Context);

/* eslint-disable */
const PersonalNumberStep1ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalNumberStep1ContextType>> =>
    (
      <PersonalNumberStep1ContextProvider>
        <Component {...props} />
      </PersonalNumberStep1ContextProvider>
    );

export { PersonalNumberStep1ContextConsumerHOC, usePersonalNumberStep1ContextConsumerHook };
