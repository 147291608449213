// Translations
import { DESCRIPTION, LINK, TITLE } from '../translations/3d-security.translations';
// Enums
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

export const SECURITY_KEYS_3D_SECURITY_PROPS = {
  description: DESCRIPTION,
  link: LINK,
  testing: {
    descriptionId: '3d-security-table-row-description',
    linkId: '3d-security-links',
    titleId: '3d-security-title',
  },
  title: TITLE,
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CHECK_3D_SECURE])
  ),
};
