// Vendors
import styled from 'styled-components';

export const AddressModalInputsStyled = styled.form.attrs({
  autoComplete: 'off',
})`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding-right: ${({ theme }) => theme.spaces.spacingXxxxs};
`;
