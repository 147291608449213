// Vendors
import React from 'react';
// Components
import { ChallengeSmsOtpComponent } from '@openbank/cb-ui-commons';
// Hooks
import PersonalNumberStep2ChallengeHook from './hooks/personal-number-step2-challenge.hook';
// Translations
import * as translations from 'translations/challenge-sms.translations';
// Types
import { PersonalNumberStep2ChallengePropsType } from './types/personal-number-step2-challenge-props.type';
// Utils
import { formatTimerText } from 'utils/timer.utils';
import { isCC } from 'utils/app.utils';
import { translateLabels } from 'utils/translations.utils';

const PersonalNumberStep2ChallengeComponent = ({
  onRetry,
}: PersonalNumberStep2ChallengePropsType): React.ReactElement => {
  const {
    challenged,
    fetching,
    formatMessage,
    error,
    handleSendNumberOtpConfirmationButtonClick,
    handleRetryButtonClick,
    otpValue,
    setOtpValue,
    isTimerActive,
    timer,
    timerLabelTranslationId,
  } = PersonalNumberStep2ChallengeHook({ onRetry });

  return (
    <ChallengeSmsOtpComponent
      {...{ fetching, error, isTimerActive }}
      disabled={!challenged}
      isCC={isCC()}
      handlers={{
        buttonHandler: handleSendNumberOtpConfirmationButtonClick,
        changeHandler: setOtpValue,
        requestHandler: handleRetryButtonClick,
        tryAgainHandler: onRetry,
      }}
      translations={{
        ...translateLabels({ formatMessage, translations }),
        REQUEST_NEW_CODE: formatTimerText({ formatMessage, timerLabelTranslationId, timer }),
        ERROR: {
          BUTTON: formatMessage({ id: translations.TRY_AGAIN }),
          DESCRIPTION: formatMessage({ id: translations.DESCRIPTION_ERROR }),
        },
      }}
      value={otpValue}
    />
  );
};

export default PersonalNumberStep2ChallengeComponent;
