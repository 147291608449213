// Vendors
import React from 'react';
// Components
import LabelsLinkCardComponent from 'components/labels-link-card/labels-link-card.component';
import SupportLandingSidebarComponent from './components/sidebar/support-landing-sidebar.component';
// Constants
import constants from './constants/support-landing.constants';
import appConstants from 'constants/app.constants';
// Hooks
import { useSupportLandingHook } from './hooks/support-landing.hook';
// Styles
import { SupportLandingViewStyled } from './support-landing.view.styled';
import {
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const SupportLandingView = (): React.ReactElement => {
  const { openChat } = useSupportLandingHook();

  return (
    <LayoutGridComponentStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <SupportLandingViewStyled>
            <LabelsLinkCardComponent
              {...constants.SUPPORT_LANDING_FAQS_CARD_PROPS}
              onClick={() => window.open(appConstants.AMAZON_PUBLIC_SITE_FAQS)}
            />
            <LabelsLinkCardComponent
              {...constants.SUPPORT_LANDING_CHAT_CARD_PROPS}
              onClick={openChat}
            />
            <LabelsLinkCardComponent {...constants.SUPPORT_LANDING_END_CARD_PROPS} />
          </SupportLandingViewStyled>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <SupportLandingSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </LayoutGridComponentStyled>
  );
};

export default SupportLandingView;
