// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import { TransactionsLandingRowDetailsCreatePlanModalHandlers } from '../handlers/transactions-landing-row-details-create-plan-modal.handlers';
import { TransactionsLandingRowDetailsCreatePlanModalTrackingHandlers } from '../handlers/transactions-landing-row-details-create-plan-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsLandingRowDetailsCreatePlanModalMethodsHandlersType } from '../handlers/types/transactions-landing-row-details-create-plan-modal.handlers.type';
import { TransactionsLandingRowDetailsCreatePlanModalHookType } from '../types/transactions-landing-row-details-create-plan-modal.hook.type';

const useTransactionsLandingRowDetailsCreatePlanModalHook = (
  id: TransactionsLandingRowDetailsCreatePlanModalHookType['id']
): TransactionsLandingRowDetailsCreatePlanModalMethodsHandlersType =>
  TransactionsLandingRowDetailsCreatePlanModalHandlers({
    ...AppTrackingHook(TransactionsLandingRowDetailsCreatePlanModalTrackingHandlers),
    id,
    navigate: useNavigate(),
  });

export { useTransactionsLandingRowDetailsCreatePlanModalHook };
