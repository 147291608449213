// Vendors
import styled from 'styled-components';
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const PersonalUploadDocumentationComponentWrapperStyled = styled.div`
  align-items: flex-start;
  background: ${({ theme }) => theme.colors.backgroundPrimaryB};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl}
    ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileAndTabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingM}
      ${({ theme }) => theme.spaces.spacingM};
  }
`;
PersonalUploadDocumentationComponentWrapperStyled.displayName =
  'PersonalUploadDocumentationComponentWrapperStyled';

export const PersonalUploadNoDocumentationComponentWrapperStyled = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundPrimaryB};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl}
    ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileAndTabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingM}
      ${({ theme }) => theme.spaces.spacingM};
  }
`;
PersonalUploadNoDocumentationComponentWrapperStyled.displayName =
  'PersonalUploadNoDocumentationComponentWrapperStyled';
