// Constants
import { OTHER_DOCUMENTS_CODE } from '../components/upload-document/constants/upload-document.constants';
// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';

const UPLOAD_DOCUMENT_MORE_FILES_PROPS = {
  file: new File([new Blob([''], { type: 'image/png' })], 'filename.png', {
    type: 'image/png',
  }),
  documentType: OTHER_DOCUMENTS_CODE,
  id: 'add-file',
  state: DocumentsUploadStatesEnumeration.ADD,
};

export { UPLOAD_DOCUMENT_MORE_FILES_PROPS };
