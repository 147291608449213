// Vendors
import React from 'react';
// Components
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { ReportSubscriptionAlreadyCancelledConfirmationActionsComponent } from './components/actions/report-subscription-already-cancelled-confirmation-actions.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
// Constants
import { DISPUTE_PARAMS } from './constants/report-subscription-already-cancelled-confirmation.constants';
// Hooks
import { useReportSubscriptionAlreadyCancelledConfirmationHook } from './hooks/report-subscription-already-cancelled-confirmation-component.hook';
// Styles
import { ReportSubscriptionAlreadyCancelledConfirmationComponentStyled } from './report-subscription-already-cancelled-confirmation.component.styled';
// Translations
import { CLAIM_TYPE } from './translations/report-subscription-already-cancelled-confirmation.translations';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';

export const ReportSubscriptionAlreadyCancelledConfirmationComponent = (): React.ReactElement => {
  const { cardId, cancelledSubDateBefore, files, formatMessage, transactionId } =
    useReportSubscriptionAlreadyCancelledConfirmationHook();

  return (
    <ReportSubscriptionAlreadyCancelledConfirmationComponentStyled>
      <TransactionsReportConfirmationComponent claimType={formatMessage({ id: CLAIM_TYPE })} />
      <ReportProblemChallengeComponent
        baseServiceBuilder={{
          pathParams: {
            cardId,
            transactionId,
          },
          params: {
            ...DISPUTE_PARAMS,
            subscriptionCanceled: {
              cancelledSubDateBefore,
            },
            documents: getDocumentIds(files),
          },
        }}
      />
      <ReportSubscriptionAlreadyCancelledConfirmationActionsComponent />
    </ReportSubscriptionAlreadyCancelledConfirmationComponentStyled>
  );
};
