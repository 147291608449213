// Vendors
import React from 'react';
// Components
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
import { TransactionsReportDuplicateChargeConfirmationComponent } from './components/confirmation/transactions-report-duplicate-charge-confirmation.component';
// Constants
import {
  WIZARD_FEEDBACK_ERROR,
  WIZARD_FEEDBACK_SUCCESS,
} from '../../constants/transactions-report.constants';
// Contexts
import { useTransactionsReportContextConsumerHook } from '../../contexts/transactions-report.context';
// Utils
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

const TransactionsReportDuplicateChargeComponent = (): React.ReactElement => {
  const { caseId } = useTransactionsReportContextConsumerHook();

  return (
    <WizardFeedbackComponent
      errorConfiguration={WIZARD_FEEDBACK_ERROR}
      successConfiguration={{
        ...WIZARD_FEEDBACK_SUCCESS,
        descriptionValues: {
          b: makeChunkBoldIntlUtil,
          br: <br />,
          caseId,
        },
      }}
    >
      <TransactionsReportDuplicateChargeConfirmationComponent />
    </WizardFeedbackComponent>
  );
};

export { TransactionsReportDuplicateChargeComponent };
