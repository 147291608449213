// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP3_TRACKING_PROPS } from './constants/financing-ppi-contracting-step3.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingPPIContractingStep3MethodsTrackingHandlersType } from './types/financing-ppi-contracting-step3.tracking.handlers.type';

const confirmContractPPIButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...FINANCING_PPI_CONTRACTING_STEP3_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const FinancingPPIContractingStep3TrackingHandlers = (
  track: TrackBuilderType
): FinancingPPIContractingStep3MethodsTrackingHandlersType => ({
  handleConfirmContractPPIButtonClickTracking: () =>
    track(confirmContractPPIButtonClickTrackingHandler()),
});

export { FinancingPPIContractingStep3TrackingHandlers };
