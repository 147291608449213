// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { CardSettingsStatementsMessageComponent } from './components/message/card-settings-statements-message.component';
import { CardSettingsStatementsSidebarComponent } from './components/sidebar/card-settings-statements-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import { TransactionsTableComponent } from 'organisms/table-organism/components/transactions-table/transactions-table.component';
// Contexts
import { CardSettingsStatementsContextConsumerHOC } from './contexts/card-settings-statements.context';
// Constants
import { CARD_SETTINGS_STATEMENTS_TABLE_PROPS } from './constants/card-settings-statements.constants';
// Hooks
import { useCardSettingsStatementsHook } from './hooks/card-settings-statements.hook';
// Styles
import {
  AppCardVerticalSectionComponentStyled,
  LayoutColumnFlexComponentStyled,
} from 'styles/app.styled';

const CardSettingsStatementsBaseView = (): React.ReactElement => {
  const { fetching, error, transactions } = useCardSettingsStatementsHook();

  return (
    <OperativeLayoutComponent sidebarComponent={CardSettingsStatementsSidebarComponent}>
      <FetchErrorComponent {...{ fetching }}>
        <AppCardVerticalSectionComponentStyled>
          <LayoutColumnFlexComponentStyled>
            <CardSettingsStatementsMessageComponent />
            <TransactionsTableComponent
              {...{ ...CARD_SETTINGS_STATEMENTS_TABLE_PROPS, error, transactions }}
            />
          </LayoutColumnFlexComponentStyled>
        </AppCardVerticalSectionComponentStyled>
      </FetchErrorComponent>
    </OperativeLayoutComponent>
  );
};

const CardSettingsStatementsView = CardSettingsStatementsContextConsumerHOC(
  CardSettingsStatementsBaseView
);

export { CardSettingsStatementsView };
