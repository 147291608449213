// Contexts
import { useCardSettingsCheck3DConfirmationContextConsumerHook } from 'containers/card-settings/views/check-3d/components/confirmation/contexts/card-settings-check-3d-confirmation.context';
import { useCardSettingsCheck3DContextConsumerHook } from 'containers/card-settings/views/check-3d/contexts/card-settings-check-3d.context';
// Types
import { CardSettingsCheck3dConfirmationSecurityModuleHookType } from './types/card-settings-check-3d-confirmation-security-module.hook.type';

const useCardSettingsCheck3dConfirmationSecurityModuleHook =
  (): CardSettingsCheck3dConfirmationSecurityModuleHookType => {
    const { showAllowance, shownCode } = useCardSettingsCheck3DConfirmationContextConsumerHook(),
      { scaCode } = useCardSettingsCheck3DContextConsumerHook();

    return { shownCode, showAllowance, scaCode };
  };

export default useCardSettingsCheck3dConfirmationSecurityModuleHook;
