// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from './configurations/change-iban-step2-challenge.component.configurations';
// Hooks
import { ChangeIbanStep2ChallengeHook } from './hooks/change-iban-step2-challenge.hook';
// Styles
import { ChangeIbanStep2ChallengeComponentStyled } from './change-iban-step2-challenge.component.styled';

const ChangeIbanStep2ChallengeComponent = (): React.ReactElement => {
  const {
    cardId,
    error,
    handleManageErrorOnChangeIbanEvent,
    handleManageSuccessOnChangeIbanEvent,
    handleChallengeRetryButtonClick,
    ibanValue,
  } = ChangeIbanStep2ChallengeHook();

  return (
    <ChangeIbanStep2ChallengeComponentStyled>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          cardId,
          challengeError: error,
          handleChallengeError: handleManageErrorOnChangeIbanEvent,
          handleChallengeRetryButtonClick,
          handleChallengeSuccess: handleManageSuccessOnChangeIbanEvent,
          ibanValue,
        })}
      />
    </ChangeIbanStep2ChallengeComponentStyled>
  );
};

export { ChangeIbanStep2ChallengeComponent };
