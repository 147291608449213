// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  CANCEL_CONTRACT_PARAPGRAPH_1_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'cancel-contract-parapgrah1-label',
  },
  CANCEL_CONTRACT_PARAPGRAPH_2_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'cancel-contract-parapgrah2-label',
  },
};
