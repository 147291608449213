// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  CANCEL_LINK_PAYLOAD,
  CLOSE_BUTTON_PAYLOAD,
  CONTINUE_BUTTON_PAYLOAD,
  LOAD_PAYLOAD,
} from './constants/limit-modal.handlers.tracking.constants';
// Types
import { LimitModalReturnHandlersTrackingType } from './types/limit-modal-return.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const loadLimitModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: LOAD_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const cancelLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CANCEL_LINK_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const closeButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CLOSE_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const continueButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: CONTINUE_BUTTON_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const LimitModalTrackingHandlers = (
  track: TrackBuilderType
): LimitModalReturnHandlersTrackingType => ({
  handleLoadLimitModalTracking: () => track(loadLimitModalTrackingHandler()),
  handleCancelLinkClickTracking: () => track(cancelLinkClickTrackingHandler()),
  handleCloseButtonClickTracking: () => track(closeButtonClickTrackingHandler()),
  handleContinueButtonClickTracking: () => track(continueButtonClickTrackingHandler()),
});

export default LimitModalTrackingHandlers;
