// Types
import { GetRadioButtonConfigurationType } from './types/get-radio-button-configuration.type';
import { GetRadioButtonConfigurationReturnType } from './types/get-radio-button-configuration-return.type';
// Utils
import { isSelected } from './utils/block-card-reason.component.configurations.utils';

export const getRadioButtonConfiguration = ({
  blockReason,
  eventLabel,
  handleBlockReasonChange,
  testId,
  value,
}: GetRadioButtonConfigurationType): GetRadioButtonConfigurationReturnType => ({
  onChange: () => handleBlockReasonChange({ eventLabel, value }),
  testing: {
    component: testId,
  },
  value: isSelected({ blockReason, value }),
});
