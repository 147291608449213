// Vendors
import styled from 'styled-components';
// Styles
import { CardSettingsContractCurrentSectionComponentStyled } from '../section/card-settings-contract-current-section.component.styled';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsContractCurrentCardComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  padding: ${({ theme }) => theme.spaces.spacingM}
    calc(${({ theme }) => theme.spaces.spacingXxxxl} + ${({ theme }) => theme.spaces.spacingS});

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXxs};
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS};
  }
`;

const CardSettingsContractCurrentCardTagComponentStyled = styled.div`
  display: none;

  ${mobileMediaQuery} {
    display: block;
  }
`;

const CardSettingsContractCurrentCardLayoutComponentStyled = styled(
  CardSettingsContractCurrentSectionComponentStyled
)`
  padding: 0;
`;

export {
  CardSettingsContractCurrentCardTagComponentStyled,
  CardSettingsContractCurrentCardComponentStyled,
  CardSettingsContractCurrentCardLayoutComponentStyled,
};
