// Vendors
import React from 'react';
// Components
import {
  InputPrefixSuffixComponent,
  LineSeparatorComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
// Constants
import {
  FILTER_MODAL_INPUT_RANGE_DEFAULT_PROPS,
  FROM_INPUT_RANGE_AMOUNT_DEFAULT_PROPS,
  TITLE_COMPONENT_PRPOPS,
  TO_INPUT_RANGE_AMOUNT_DEFAULT_PROPS,
} from './constants/filter-modal-input-range-amount.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  FilterModalInputRangeAmountComponentStyled,
  FilterModalInputRangeAmountContentStyled,
} from './filter-modal-input-range-amount.component.styled';
// Translations
import {
  FROM,
  TO,
} from 'components/filters/components/filter-modal/components/input-range/components/translations/filter-modal-input-range.translations';
import { INPUT_RANGE_CURRENCY_VALIDATION } from 'components/filters/components/filter-modal/translations/filter-modal.translations';
import { AMOUNT_TITLE } from 'containers/financing/views/fast-cash/components/first-step/components/amount/translations/amount.translations';
// Types
import { FilterModalInputRangeAmountComponentType } from './types/filter-modal-input-range-amount.component.type';
// Utilities
import { validateRangeValues } from './utils/filter-modal-input-range-amount.utils';

const FilterModalInputRangeAmountComponent = ({
  onChangeFromInput,
  onChangeToInput,
  fromValue,
  toValue,
  showLineSeparator = FILTER_MODAL_INPUT_RANGE_DEFAULT_PROPS.showLineSeparator,
  testId = FILTER_MODAL_INPUT_RANGE_DEFAULT_PROPS.testId,
}: FilterModalInputRangeAmountComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  return (
    <FilterModalInputRangeAmountComponentStyled {...{ testId }}>
      <TitleComponent {...TITLE_COMPONENT_PRPOPS}>
        {formatMessage({ id: AMOUNT_TITLE })}
      </TitleComponent>
      <FilterModalInputRangeAmountContentStyled>
        <InputPrefixSuffixComponent
          {...FROM_INPUT_RANGE_AMOUNT_DEFAULT_PROPS}
          label={formatMessage({ id: FROM })}
          onChange={onChangeFromInput}
          value={fromValue}
        />
        <InputPrefixSuffixComponent
          {...TO_INPUT_RANGE_AMOUNT_DEFAULT_PROPS}
          label={formatMessage({ id: TO })}
          onChange={onChangeToInput}
          errorMessage={
            validateRangeValues(fromValue, toValue) &&
            formatMessage({ id: INPUT_RANGE_CURRENCY_VALIDATION })
          }
          value={toValue}
        />
      </FilterModalInputRangeAmountContentStyled>
      {showLineSeparator && <LineSeparatorComponent />}
    </FilterModalInputRangeAmountComponentStyled>
  );
};

export default FilterModalInputRangeAmountComponent;
