// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TrustedDeviceComponent } from './components/trusted-device/trusted-device.component';
import { DevicesManagerSidebarComponent } from './components/sidebar/sidebar.component';
import { DevicesManagerListComponent } from './components/devices-list/devices-list.component';
// Constants
import { LABEL, DEVICES_VIEW_RIGHT_COLUMN_PROPS } from './constants/devices-manager.constants';
// Hooks
import { DevicesManagerHook } from './hooks/devices.manager.view.hook';
// Styles
import { DevicesManagerLandingViewStyled } from './devices_manager.view.styled';
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
// Translations
import { TITLE, DESCRIPTION } from './translations/devices-manager.translations';

const DevicesManagerView = (): React.ReactElement => {
  DevicesManagerHook();

  return (
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <DevicesManagerLandingViewStyled>
          <TitleHeaderComponent {...LABEL }>
            <FormattedMessageComponent id={TITLE} />
          </TitleHeaderComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
          <TrustedDeviceComponent />
          <DevicesManagerListComponent />
        </DevicesManagerLandingViewStyled>
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled {...DEVICES_VIEW_RIGHT_COLUMN_PROPS}>
        <DevicesManagerSidebarComponent />
      </LayoutGridContentRightComponentStyled>
    </LayoutGridContentComponentStyled>
  );
};

export default DevicesManagerView;
