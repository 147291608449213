// Services
import { changeUserEmailOtpService } from '../services/personal-email-step3-actions.service';
// Types
import { PersonalEmailStep3ActionsApiBuilderType } from '../types/personal-email-step3-actions-api-builder.type';
import { PersonalEmailStep3ActionsApiReturnType } from '../types/personal-email-step3-actions-api-return.type';
// Utilities
import { isValidEmailPatchResponse } from './utils/personal-email-step-actions.resolver.utils';

const changeUserEmailOtpResolver = async (
  props: PersonalEmailStep3ActionsApiBuilderType
): Promise<[PersonalEmailStep3ActionsApiReturnType | Record<string, any>] | [null, true]> => {
  const [response] = await changeUserEmailOtpService(props);

  return isValidEmailPatchResponse(response) ? [response] : [null, true];
};

export { changeUserEmailOtpResolver };
