// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/support-cancel-contract-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import { SupportCancelContractSidebarComponentStyled } from './support-cancel-contract-sidebar.component.styled';
// Translations
import translations from './translations/support-cancel-contract-sidebar.translations';

const SupportCancelContractSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.CANCEL_CONTRACT_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.SIDEBAR_MAIN_TITLE} />}
    >
      <SupportCancelContractSidebarComponentStyled>
        <ParagraphComponent {...constants.CANCEL_CONTRACT_SIDEBAR_MESSAGE_01_PROPS}>
          <FormattedMessageComponent id={translations.SIDEBAR_TEXT_1} />
        </ParagraphComponent>
      </SupportCancelContractSidebarComponentStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default SupportCancelContractSidebarComponent;
