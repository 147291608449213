// Vendors
import React from 'react';
// Components
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import { TransactionsReportRouterComponent } from './components/router/transactions-report-router.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import { TransactionsReportSidebarComponent } from './components/sidebar/transactions-report-sidebar.component';
// Contexts
import { TransactionsReportContextConsumerHOC } from './contexts/transactions-report.context';
// Providers
import { withCardDetailProvider } from 'providers/card-detail/card-detail.provider';
// Hooks
import { useTransactionsReportHook } from './hooks/transactions-report.hook';

const TransactionsReportBaseView = (): React.ReactElement => {
  const { error, fetching, transactionDetails } = useTransactionsReportHook();

  return (
    <ErrorWrapperComponent error={error} fetching={fetching}>
      <OperativeLayoutComponent
        sidebarComponent={() => (
          <TransactionsReportSidebarComponent transactionDetails={transactionDetails} />
        )}
      >
        <TransactionsReportRouterComponent />
      </OperativeLayoutComponent>
    </ErrorWrapperComponent>
  );
};

const TransactionsReportView = withCardDetailProvider(
  TransactionsReportContextConsumerHOC(TransactionsReportBaseView)
);

export { TransactionsReportView };
