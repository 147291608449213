// Translations
import {
  ILLNESS,
  INCAPACITY,
  UNEMPLOYMENT,
} from '../translations/financing-insurances-contracting-lpi-additional-not-covered.translations';

const FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_PROPS = {
    ordered: true,
    testId: 'financing-lpi-contracting-step1-expandable-uncovered',
  },
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_ITEMS = {
    items: [
      {
        label: INCAPACITY.title,
        subItems: [INCAPACITY.firstCase, INCAPACITY.secondCase],
      },
      {
        label: UNEMPLOYMENT.title,
        subItems: [ UNEMPLOYMENT.firstCase, UNEMPLOYMENT.secondCase],
      },
      {
        label: ILLNESS.title,
        subItems: [ILLNESS.firstCase],
      },
    ],
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_UNCOVERED_LIST_ITEMS,
};
