// Services
import { fetchCustomerIdService } from '../services/customer-id.services.constants';

const fetchCustomerIdResolver = async (): Promise<any> => {
  const [response] = await fetchCustomerIdService();

  return response?.resource?.customerId ? [response?.resource?.customerId] : [null, true];
};

export { fetchCustomerIdResolver };
