// Vendors
import { wasForbiddenRequest } from '@openbank/cf-ui-framework';
// Resolvers
import { createInsuranceContractResolver } from 'containers/financing/views/ppi/resolvers/financing-insurances.resolvers';
// Types
import { FinancingInsurancesContractingLPIStep4ReturnHandlersType } from './types/financing-insurances-contracting-lpi-step4-return.handler.type';
import { FinancingInsurancesContractingLPIStep4PropsHandlersTypeProps } from './types/financing-insurances-contracting-lpi-step4-props.handler.type';

const confirmContractLPIButtonClickHandler = async ({
  challenge,
  downloaded,
  plan,
  productContractId,
  productContractType,
  postalDocumentation,
  setChallengeError,
  setDownloadedError,
  setFetching,
  setFetchPPI,
  setResult,
  setPlan,
  setPostalDocumentation,
}: Omit<
  FinancingInsurancesContractingLPIStep4PropsHandlersTypeProps,
  'setDownloaded' | 'downloadedError'
>): Promise<void> => {
  if (!downloaded) {
    setDownloadedError(true);
    return;
  }

  setFetching(true);

  const [_, error] = await createInsuranceContractResolver({
    productContractId,
    insuranceType: 'LPI',
    planCode: plan,
    productContractType,
    postalDocumentation,
    progressId: challenge?.progressId,
  });

  if (error && wasForbiddenRequest(error)) {
    setChallengeError(true);
    setFetching(false);
    return;
  }

  setPlan(undefined);
  setPostalDocumentation(false);
  setResult(!error);
  setFetching(!error);
  setFetchPPI(!error);
};

const toggleDownloadedHandler = ({
  setDownloaded,
  setDownloadedError,
}: Pick<
  FinancingInsurancesContractingLPIStep4PropsHandlersTypeProps,
  'setDownloaded' | 'setDownloadedError'
>) => {
  setDownloaded(true);
  setDownloadedError(false);
};

const FinancingInsurancesContractingLPIStep4Handlers = ({
  challenge,
  downloaded,
  plan,
  postalDocumentation,
  productContractId,
  productContractType,
  setDownloaded,
  setDownloadedError,
  setChallengeError,
  setFetching,
  setFetchPPI,
  setResult,
  setPlan,
  setPostalDocumentation,
}: FinancingInsurancesContractingLPIStep4PropsHandlersTypeProps): FinancingInsurancesContractingLPIStep4ReturnHandlersType => ({
  handleConfirmContractLPIButtonClick: () =>
    confirmContractLPIButtonClickHandler({
      challenge,
      downloaded,
      plan,
      postalDocumentation,
      productContractId,
      productContractType,
      setChallengeError,
      setDownloadedError,
      setFetching,
      setFetchPPI,
      setResult,
      setPlan,
      setPostalDocumentation,
    }),
  handleToggleDownloaded: () => toggleDownloadedHandler({ setDownloaded, setDownloadedError }),
});

export { FinancingInsurancesContractingLPIStep4Handlers };
