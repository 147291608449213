// Vendors
import styled from 'styled-components';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const ExpiredKycModalDescriptionComponentStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

const ExpiredKycModalDescriptionSubtitleStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    text-align: left;
  }
`;

export { ExpiredKycModalDescriptionComponentStyled, ExpiredKycModalDescriptionSubtitleStyled };
