// Vendors
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_ELEMENT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_TITLE_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_SECTION_TITLE_PROPS,
} from './constants/card-settings-contract-current-section.constants';
// Styles
import { CardSettingsContractCurrentSectionComponentStyled } from './card-settings-contract-current-section.component.styled';
// Types
import { CardSettingsContractCurrentSectionComponentType } from './types/card-settings-contract-current-section.component.type';

const CardSettingsContractCurrentSectionComponent = ({
  children,
  id,
  title,
  twoColumns = true,
}: CardSettingsContractCurrentSectionComponentType): React.ReactElement => (
  <SectionDividerComponent
    {...CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_PROPS}
    testing={{
      elementId: CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_ELEMENT_PROPS + id,
      titleId: CARD_SETTINGS_CONTRACT_CURRENT_SECTION_DIVIDER_TITLE_PROPS + id,
    }}
    title={
      <FormattedMessageComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_SECTION_TITLE_PROPS}
        id={title}
      />
    }
  >
    <CardSettingsContractCurrentSectionComponentStyled {...{ twoColumns }}>
      {children}
    </CardSettingsContractCurrentSectionComponentStyled>
  </SectionDividerComponent>
);

export default CardSettingsContractCurrentSectionComponent;
