// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import { ExpiredSignatureHandlers } from '../handlers/expired-signature-message.handlers';
// Types
import { ExpiredSignatureHookType } from './types/expired-signature-message.hook.type';

const ExpiredSignatureHook = (): ExpiredSignatureHookType => {
  const navigate = useNavigate();
  const { navigateToChangeSignatureHandler } = ExpiredSignatureHandlers({ navigate });

  return {
    navigateToChangeSignatureHandler
  };
};

export { ExpiredSignatureHook };
