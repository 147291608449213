// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const Step3CreditLimitIncreaseStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const ParagraphComponentStyled = styled.div`
  > p {
    color: ${({ theme }) => theme.colors.fontSecondaryA};
  }
`;

export const Step3CreditLimitIncreaseTextSectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const Step3CreditLimitIncreaseDisplayRowStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    flex-direction: column;
  }

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const Step3CreditLimitIncreaseDisplayRow2Styled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const Step3CreditLimitIncreaseImageAndTextsStyled = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

export const Step3CreditLimitIncreaseSeparatorStyled = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundTertiaryD};
  margin: ${({ theme }) => theme.spaces.spacingXxxs} 0;
`;

export const ConfirmationNextStepsComponentStyled = styled.div`
  > div {
    padding: 0;
  }
`;

export const Step3CreditLimitIncreaseUploadDocumentationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingS};
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};

  a {
    width: fit-content;
  }
`;
