// Vendors
import {
  TrackingEventsEnum,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Constants
import { ENTER_TRANKING_PAYLOAD } from './constants/devices.manager.view.tranking.handlers.constants';
// Types
import { DevicesManagerHandlersTrackingReturnType } from './types/devices.manager.view.handlers.tracking.return.type';

const DeviceManagerEnterTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: ENTER_TRANKING_PAYLOAD,
  type: TrackingEventsEnum.VIEW,
});

const DevicesManagerTrackingHandlers = (
  track: (param: unknown) => void
): DevicesManagerHandlersTrackingReturnType => ({
  onDeviceManagerEnterTracking: () =>
    track(DeviceManagerEnterTrackingHandler()),
});


export { DevicesManagerTrackingHandlers };