// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const OrchestratorRouterContainerErrorComponentStyled = styled.section<{ error: boolean }>`
  ${({ error }) =>
    error &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
      min-height: calc(100vh - 285px);
      height: 100%;
      width: 100%;

      ${mobileMediaQuery} {
        padding: 0 ${({ theme }) => theme.spaces.spacingM};
      }
    `}
`;
