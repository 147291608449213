// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FINANCING_PPI_CANCELLATION_SERVICE_CONFIGURATION } from './constants/financing-ppi-cancellation-landing-footer.service.constants';
// Types
import { FinancingPPICancellationType } from 'containers/financing/views/ppi/types/financing-ppi-cancellation.type';
import { FinancingPPICancellationLandingFooterApiBuilderType } from '../types/financing-ppi-cancellation-landing-footer-api-builder.type';

const cancelPPIContractService = async ({
  contractId,
  progressId,
}: FinancingPPICancellationLandingFooterApiBuilderType): Promise<
  [FinancingPPICancellationType | Record<string, never>, Response]
> =>
  apiUtilsCb.post({
    ...FINANCING_PPI_CANCELLATION_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    pathParams: { contractId },
  });

export { cancelPPIContractService };
