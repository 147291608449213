// Types
import { CardResourcesApiReturnComponentType } from 'types/card-resources-api-return.component.type';

const isValidCardsResourcesContract = (
  response: CardResourcesApiReturnComponentType | Record<string, any>
): boolean => Boolean(response?.elements?.length > 0);

const isForbiddenError = (response: any): boolean => {
  if ('errorCode' in response && response?.errorCode === 'FORBIDDEN') {
    return true;
  }

  return false;
};

const hasCards = (response: any, status: number): boolean =>
  status === 204 || (response.elements && !response.elements.length);

export { hasCards, isForbiddenError, isValidCardsResourcesContract };
