// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

export const DetailConfirmationComponentStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingL};
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  padding: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    grid-template-columns: auto;
  }
`;
