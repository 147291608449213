// Configurations
import { getLinkTypeTrackingConfiguration } from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import { MODAL_TRACKING_CONSTANTS } from './constants/expired-kyc-modal.tracking.constants';
// Types
import { ExpiredKycModalTrackingHandlersType } from './types/expired-kyc-modal.tracking.type';

const ExpiredKycModalTracking = (
  track: (param: unknown) => void
): ExpiredKycModalTrackingHandlersType => ({
  handleShowExpiredKycModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(MODAL_TRACKING_CONSTANTS.SHOW_MODAL)),
  handleClickCloseModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(MODAL_TRACKING_CONSTANTS.CLOSE_MODAL)),
  handleClickLinkModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(MODAL_TRACKING_CONSTANTS.LINK_MODAL)),
  handleClickButtonModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(MODAL_TRACKING_CONSTANTS.BUTTON_MODAL)),
});

export { ExpiredKycModalTracking };
