// Vendors
import React from 'react';
// Components
import { ErrorComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getNextStepErrorCreditLimitIncreaseConfiguration } from './configurations/next-step-error-credit-limit-increase.component.configurations';
// Hooks
import NextStepErrorCreditLimitIncreaseHook from './hooks/next-step-error-credit-limit-increase.component.hook';

const NextStepErrorCreditLimitIncreaseComponent = (): React.ReactElement => {
  const { formatMessage, handleOnClickBackButton } = NextStepErrorCreditLimitIncreaseHook();

  return (
    <ErrorComponent
      {...getNextStepErrorCreditLimitIncreaseConfiguration({
        formatMessage,
        handleOnClickBackButton,
      })}
    />
  );
};

export default NextStepErrorCreditLimitIncreaseComponent;
