// Translations
import translations from '../translations/card-settings-block-card-sidebar.translations';

export default {
  SIDEBAR_PROPS: {
    testId: 'card-settings-block-card',
  },
  ROWS_PROPS: [
    {
      title: translations.ROWS.FIRST.TITLE,
      content: translations.ROWS.FIRST.CONTENT,
    },
    {
      title: translations.ROWS.SECOND.TITLE,
      content: translations.ROWS.SECOND.CONTENT,
    },
    {
      title: translations.ROWS.THIRD.TITLE,
      content: translations.ROWS.THIRD.CONTENT,
    },
    {
      title: translations.ROWS.FOURTH.TITLE,
      content: translations.ROWS.FOURTH.CONTENT,
    },
  ],
  PARAGRAPH_PROPS: {
    size: 'M',
  },
};
