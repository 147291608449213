const REQUEST_SIGNATURE_KEY_ADDRESS_BOX_PROPS = {
  testId: 'request-signature-key-address-box',
};

const REQUEST_SIGNATURE_KEY_ADDRESS_BOX_DESCRIPTION_PROPS = {
  testId: 'request-signature-key-address-box-description',
};

export {
  REQUEST_SIGNATURE_KEY_ADDRESS_BOX_PROPS,
  REQUEST_SIGNATURE_KEY_ADDRESS_BOX_DESCRIPTION_PROPS,
};
