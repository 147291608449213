// Vendors
import { ChatTranslations } from '@openbank/cb-ui-commons';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Translations
import translations from '../translations/chat.provider.translations';
// Types
import { UseTranslationsProps } from './types/use-translations-props.type';

export const useTranslations = ({ nickname }: UseTranslationsProps): ChatTranslations => {
  const { formatMessage } = useCbIntl();

  return {
    endConversationButton: formatMessage({ id: translations.endConversationButton }),
    endConversationDescription: formatMessage({ id: translations.endConversationDescription }),
    endConversationLink: formatMessage({ id: translations.endConversationLink }),
    errorDescription: formatMessage({ id: translations.errorDescription }),
    errorTitle: formatMessage({ id: translations.errorTitle }),
    errorLink: formatMessage({ id: translations.errorLink }),
    informativeMessage: formatMessage({ id: translations.informativeMessage }),
    inputPlaceholder: formatMessage({ id: translations.inputPlaceholder }),
    inputPlaceholderDisabled: formatMessage({ id: translations.inputPlaceholderDisabled }),
    onboardingMsg: formatMessage({ id: translations.onboardingMsg }, { username: nickname }),
    sendButton: formatMessage({ id: translations.sendButton }),
    sessionExpiredDescription: translations.sessionExpiredDescription,
    sessionExpiredLink: formatMessage({ id: translations.sessionExpiredLink }),
    scheduleMessageTitle: formatMessage({ id: translations.scheduleMessageTitle }),
    scheduleAvailability1: formatMessage({ id: translations.scheduleAvailability1 }),
    scheduleAvailability2: formatMessage({ id: translations.scheduleAvailability2 }),
    supportDescription: formatMessage({ id: translations.supportDescription }),
    supportLink: formatMessage({ id: translations.supportLink }),
    supportTitle: formatMessage({ id: translations.supportTitle }),
    waitForAgent: formatMessage({ id: translations.waitForAgent }),
    welcomeMessage: formatMessage({ id: translations.welcomeMessage }),
    windowTitle: formatMessage({ id: translations.windowTitle }),
  };
};
