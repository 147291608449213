// Constants
import {
  SITEKEY_PRE_PRODUCTION,
  SITEKEY_PRODUCTION,
  VALID_CAPTCHA,
} from '../constants/captcha.constants';
// Utilities
import { isProduction } from 'utils/app.utils';
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';
// Types
import { IsValidCaptchaPropsType } from './types/is-valid-captcha-props.type';

const isValidCaptcha = ({ captcha, featureFlag }: IsValidCaptchaPropsType): boolean =>
  getFeatureFlagInstance().isEnabled(featureFlag) ? Boolean(captcha) : VALID_CAPTCHA;

const getCaptchaKey = (): string => (isProduction() ? SITEKEY_PRODUCTION : SITEKEY_PRE_PRODUCTION);

export { isValidCaptcha, getCaptchaKey };
