// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { RecoveryKeyStep5ContextType } from './types/recovery-key-step1.context.type';

const RecoveryKeyStep5Step1Context = createContext<RecoveryKeyStep5ContextType>(
  {} as RecoveryKeyStep5ContextType
);

const RecoveryKeyStep5ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<RecoveryKeyStep5ContextType>> => {
  const [recoveryKey, setRecoveryKey] = useState<string>('');

  return (
    <RecoveryKeyStep5Step1Context.Provider value={{ recoveryKey, setRecoveryKey }}>
      {children as React.ReactNode}
    </RecoveryKeyStep5Step1Context.Provider>
  );
};

const useRecoveryKeyStep5ContextConsumerHook = (): RecoveryKeyStep5ContextType =>
  useContext(RecoveryKeyStep5Step1Context);

/* eslint-disable */
const RecoveryKeyStep5ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<RecoveryKeyStep5ContextType>> =>
    (
      <RecoveryKeyStep5ContextProvider>
        <Component {...props} />
      </RecoveryKeyStep5ContextProvider>
    );

export { RecoveryKeyStep5ContextConsumerHOC, useRecoveryKeyStep5ContextConsumerHook };
