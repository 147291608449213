// Constants
import constants from 'services/constants/notifications.services.constants';
// Types
import { hasInputCurrencyErrorsPropsType } from './types/has-input-currency-errors.type';
// Utils
import {
  getControllerMinAmount,
  isMinAmount,
} from 'containers/notifications/views/notifications-settings/components/list/utils/notifications-settings-list.utils';

const hasInputCurrencyErrors = ({
  notificationsSettings,
}: hasInputCurrencyErrorsPropsType): boolean | undefined => {
  return notificationsSettings?.categories?.some(category => {
    return category?.notifications?.some(notification => {
      return notification?.controllers?.some(controller => {
        const minAmount = getControllerMinAmount({ notificationSetting: notification });
        const unformattedValue = Number(controller.amount);

        return isMinAmount({ amount: unformattedValue, minAmount }) && notification.enabled;
      });
    });
  });
};

export const setEndOfDay = (date: Date): string => {
  date.setUTCHours(constants.LAST_HOUR, constants.LAST_MINUTE, constants.LAST_SECOND);
  return date.toISOString();
};

export { hasInputCurrencyErrors };
