// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Translations
import monthsTranslations from 'translations/months.translations';
import translations from '../translations/employment-data-credit-limit-increase.component.translations';
// Types
import { EmploymentDataConfigPropsType } from './types/employment-data-credit-limit-increase.configuration.type';
import { InfoPanelContentType } from 'containers/financing/views/credit-limit-increase/components/editable-info-panel/types/info-panel-content.type';

export const getInfoPanelContentConfig = ({
  // activityTypeDeclared,
  monthJoinedCompany,
  yearJoinedCompany,
  netIncome,
  currency,
  formatMessage,
  formatNumber,
}: EmploymentDataConfigPropsType): InfoPanelContentType[] => {
  return (
    [
      // TODO: will be necessary in future
      // activityTypeDeclared ?? {
      //   title: formatMessage({ id: translations.EMPLOYMENT_STATUS }),
      //   text: formatMessage({ id: translations[activityTypeDeclared] }),
      // } : undefined,
      monthJoinedCompany && yearJoinedCompany
        ? {
            title: formatMessage({ id: translations.MONTH_AND_YEAR_JOIN_COMPANY }),
            text: `${formatMessage({
              id: monthsTranslations[monthJoinedCompany],
            })} ${yearJoinedCompany}`,
          }
        : undefined,
      netIncome
        ? {
            title: formatMessage({ id: translations.APROXIMATE_MONTHLY_SALARY }),
            text: formatNumber(Number(netIncome), {
              currency,
              ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
            }),
          }
        : undefined,
    ] as InfoPanelContentType[]
  ).filter(x => x);
};
