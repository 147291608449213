// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/card-settings-activate-card-sidebar.constants';
// Styles
import {
  CardSettingsActivateCardSidebarComponentStyled,
  CardSettingsActivateCardSidebarContentComponentStyled,
} from './card-settings-activate-card-sidebar.component.styled';
// Translations
import translations from './translations/card-settings-activate-card-sidebar.translations';

const CardSettingsActivateCardSidebarComponent = (): React.ReactElement => (
  <CardSettingsActivateCardSidebarComponentStyled>
    <SidebarComponent
      {...constants.CARD_SETTINGS_ACTIVATE_CARD_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <CardSettingsActivateCardSidebarContentComponentStyled>
        <ParagraphComponent {...{ ...constants.CARD_SETTINGS_ACTIVATE_CARD_SIDEBAR_CONTENT_PROPS }}>
          <FormattedMessageComponent id={translations.CONTENT} />
        </ParagraphComponent>
      </CardSettingsActivateCardSidebarContentComponentStyled>
    </SidebarComponent>
  </CardSettingsActivateCardSidebarComponentStyled>
);

export default CardSettingsActivateCardSidebarComponent;
