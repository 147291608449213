// Constants
import { CreditLimitIncreaseStepsEnumeration } from 'containers/financing/views/credit-limit-increase/enums/credit-limit-increase.view.enumeration';
import step1Constants from '../../components/step1/constants/step-1-credit-limit-increase.constants';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default {
  CONTEXT_DEFAULT_VALUE: {
    step1: {
      ...step1Constants.DEFAULT_VALUES,
    },
    setStep1: () => undefined,
    step2: {
      nextDisabled: true,
      personalData: {},
    },
    setStep2: () => undefined,
    step3: {
      nextDisabled: true,
    },
    setStep3: () => undefined,
    step4: {
      nextDisabled: true,
    },
    setStep4: () => undefined,
    step5: {
      nextDisabled: true,
    },
    setStep5: () => undefined,
    step6: {
      nextDisabled: true,
    },
    setStep6: () => undefined,
    finalFeedback: {
      taxIdRequired: false,
    },
    setFinalFeedback: () => undefined,
    wizardStatus: {
      caseId: '',
      currentStep: 1,
      currentStepInText: CreditLimitIncreaseStepsEnumeration.SELECT_LIMIT_AMOUNT,
      error: false,
      fetching: true,
    },
    setWizardStatus: () => undefined,
    showExpiredFiocModal: false,
    setShowExpiredFiocModal: () => undefined,
  },
};
