const DOMAIN =
  'cobranded.services.userSettings.privateArea.check3DSecure.cbCheck3DSecure.cbSidebar.';

const DESCRIPTION_TITLE = `${DOMAIN}descriptionTitle`,
  TITLE = `${DOMAIN}title`,
  DESCRIPTION_01 = `${DOMAIN}description01`,
  DESCRIPTION_02 = `${DOMAIN}description02`,
  DESCRIPTION_03 = `${DOMAIN}description03`;

export { DESCRIPTION_TITLE, TITLE, DESCRIPTION_01, DESCRIPTION_02, DESCRIPTION_03 };

export default { DESCRIPTION_TITLE, TITLE, DESCRIPTION_01, DESCRIPTION_02, DESCRIPTION_03 };
