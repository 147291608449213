// Services
import { generateNewEmailOtpService } from '../services/personal-email-step2.services';
// Types
import { PersonalEmailStep2GenerateApiBuilderType } from '../types/personal-email-step2-generate-api-builder.type';

const generateNewEmailOtpResolver = async (
  props: PersonalEmailStep2GenerateApiBuilderType
): Promise<[string] | [null, true]> => {
  const [response] = await generateNewEmailOtpService(props);

  return response?.otpId ? [response.otpId] : [null, true];
};

export { generateNewEmailOtpResolver };
