// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLpiSidebarComponent } from './components/sidebar/financing-insurances-contracting-lpi-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import { FINANCING_CONTRACTING_LPI_WIZARD_PROPS } from './constants/financing-insurances-contracting-lpi.constants';
// Contexts
import { FinancingInsurancesContractingLPIContextConsumerHOC } from './contexts/financing-insurances-contracting-lpi.context';

const FinancingInsurancesContractingLPIBaseComponent = (): React.ReactElement => (
  <OperativeLayoutComponent sidebarComponent={FinancingInsurancesContractingLpiSidebarComponent}>
    <WizardComponent {...FINANCING_CONTRACTING_LPI_WIZARD_PROPS} />
  </OperativeLayoutComponent>
);

const FinancingInsurancesContractingLPIComponent =
  FinancingInsurancesContractingLPIContextConsumerHOC(
    FinancingInsurancesContractingLPIBaseComponent
  );

export { FinancingInsurancesContractingLPIComponent };
