// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { CUSTOMER_FETCH_SERVICE_CONFIGURATION } from './constants/customer.services.constants';
// Types
import { CustomerDataType } from 'types/customer/customer-data.type';

const fetchCustomerInformationService = async (): Promise<
  [CustomerDataType | Record<string, any>, Response]
> => apiUtilsCb.get(CUSTOMER_FETCH_SERVICE_CONFIGURATION);

export { fetchCustomerInformationService };
