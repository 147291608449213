// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { TransactionsReportNotReceivedAdditionalInfoActionsHandlersType } from './types/transactions-report-not-received-additional-info-actions.handlers.type';
import { TransactionsReportNotReceivedAdditionalInfoActionsHandlersReturnType } from './types/transactions-report-not-received-additional-info-actions-return.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const nextButtonClickHandler = ({
  date,
  description,
  setNotReceivedDate,
  setDescription,
  setCurrentStep,
}: NextButtonClickHandlerType): void => {
  setNotReceivedDate(date);
  setDescription(description);
  setCurrentStep(NUMBER.TWO);
};

export const TransactionsReportNotReceivedAdditionalInfoActionsHandlers = ({
  setNotReceivedDate,
  setDescription,
  setCurrentStep,
}: TransactionsReportNotReceivedAdditionalInfoActionsHandlersType): TransactionsReportNotReceivedAdditionalInfoActionsHandlersReturnType => ({
  handleNextButtonClick: ({ date, description }) =>
    nextButtonClickHandler({
      date,
      description,
      setNotReceivedDate,
      setDescription,
      setCurrentStep,
    }),
});
