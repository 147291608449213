// Vendors
import React from 'react';
// Components
import {
  TitleComponent,
  FetchErrorComponent,
  MessageComponent,
  ParagraphComponent,
  AccordionComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { DeviceHeaderComponent } from 'containers/security-keys/views/devices/components/device-header/device-header.component';
import { DeviceInfoComponent } from 'containers/security-keys/views/devices/components/device-info/device-info.component';
import { DeviceSessionsComponent } from 'containers/security-keys/views/devices/components/device-sessions/device-sessions.component';
// Constants
import { LABEL, NO_TRUST_DEVICE_MESSAGE } from './constants/trusted-device.constants';
// Hooks
import { TrustedDeviceHook } from './hooks/trusted-device.hook';
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Styled
import { TrustedDeviceComponentStyled } from './trusted-device.component.styled';
// Translations
import { TITLE, DESCRIPTION, NO_TRUSTED_DEVICE } from './translations/trusted-device.translations';

const TrustedDeviceComponent = (): React.ReactElement => {
  const { fetching, trusted } = TrustedDeviceHook();
  const { isMobile } = DeviceTypeHook();

  return (
    <FetchErrorComponent fetching={fetching}>
      <TitleComponent {...LABEL}>
        <FormattedMessageComponent id={TITLE} />
      </TitleComponent>
      {trusted ? (
        <TrustedDeviceComponentStyled key={trusted.deviceUUID}>
          {isMobile ? (
            <DeviceHeaderComponent link device={trusted} />
          ) : (
            <AccordionComponent headerChildren={<DeviceHeaderComponent button device={trusted} />}>
              <DeviceInfoComponent device={trusted} />
              <DeviceSessionsComponent deviceUUID={trusted.deviceUUID} />
            </AccordionComponent>
          )}
        </TrustedDeviceComponentStyled>
      ) : (
        <MessageComponent {...NO_TRUST_DEVICE_MESSAGE}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={DESCRIPTION} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={NO_TRUSTED_DEVICE} />
          </ParagraphComponent>
        </MessageComponent>
      )}
    </FetchErrorComponent>
  );
};

export { TrustedDeviceComponent };
