// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  PROPS: {
    testId: 'current-account-box',
  },
  DESCRIPTION_PARAGRAPH_PROPS: {
    size: paragraphConstants.SIZES.XS,
  },
};
