// Enumerations
import { TransactionsFetchErrorEnumeration } from 'enumerations/transactions-fetch-error.enumeration';
// Types
import { SecurityCheckUtilsType } from './types/security-check.utils.type';
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';
// Utils
import { hasTransactionsChallengeSuccess } from 'organisms/table-organism/components/transactions-table/utils/transactions-table.utils';

const isNoResultsError = (
  componentError: TransactionsFetchErrorEnumeration | null | undefined
): boolean => {
  return componentError === TransactionsFetchErrorEnumeration.NO_RESULTS;
};

const isChallengeError = (
  componentError: TransactionsFetchErrorEnumeration | null | undefined
): boolean => {
  return componentError === TransactionsFetchErrorEnumeration.CHALLENGE;
};

const hasPendingRecordsWithoutSuccess = (
  metadata: TransactionsTableMetadataComponentType | Record<string, any>
): boolean => {
  return metadata.hasPendingRecords && !hasTransactionsChallengeSuccess();
};

const showSecurityCheck = ({
  componentError,
  challenge,
  metadata,
}: SecurityCheckUtilsType): boolean => {
  if (isNoResultsError(componentError) && challenge?.progressId) {
    return true;
  }

  if (isNoResultsError(componentError)) {
    return false;
  }

  return (
    !isNoResultsError(componentError) &&
    (isChallengeError(componentError) || hasPendingRecordsWithoutSuccess(metadata))
  );
};

export { showSecurityCheck };
