// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/profile-personal-name-alias-input.services.constants';
// Types
import { ProfilePersonalNameAliasInputApiBuilderType } from '../types/profile-personal-name-alias-input-api-builder.type';

const changeUserAliasService = async ({
  alias,
  customerId,
}: ProfilePersonalNameAliasInputApiBuilderType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.patch({
    ...constants.CHANGE_ALIAS_SERVICE_CONFIGURATION,
    pathParams: { customerId },
    params: { alias },
  });

export { changeUserAliasService };
