const DOMAIN = 'cobranded.services.userSettings.dashboard.cbMessage.';

const translations = {
  CARD_BLOCK_CODE: `${DOMAIN}cardBlockCode`,
  CARD_BLOCK_DESCRIPTION: `${DOMAIN}cardBlockDescription`,
  TITLE: `${DOMAIN}unpaidReceiptsTitle`,
  DESCRIPTION: `${DOMAIN}unpaidReceiptsDescription`,
};

export { translations };
