// Constants
import { MULTIPLE_AMOUNT } from 'containers/financing/views/credit-limit-decrease-cc/components/step1/constants/step-1-credit-limit-decrease-cc.constants';
// Translations
import {
  ERROR_DIFFERENT,
  ERROR_EMPTY_FIELD,
  ERROR_MAXIMUM_AMOUNT,
  ERROR_MINIMUN_AMOUNT,
  ERROR_MUST_BE_MULTIPLE,
} from 'containers/financing/views/credit-limit-decrease-cc/components/step1/translations/step-1-credit-limit-decrease-cc.translations';
// Types
import { Step1FormUtilsType } from './types/step-1-form.utils.type';

export const checkForAmountErrors = ({
  min,
  max,
  newAmount,
  setAmountError,
  formatMessage,
  formatCurrencySeted,
}: Step1FormUtilsType): void => {
  if (newAmount === '') {
    setAmountError(formatMessage({ id: ERROR_EMPTY_FIELD }));
  } else if (newAmount === max) {
    setAmountError(formatMessage({ id: ERROR_DIFFERENT }));
  } else if (newAmount < min) {
    setAmountError(
      formatMessage({ id: ERROR_MINIMUN_AMOUNT }, { minAmount: formatCurrencySeted(min) })
    );
  } else if (newAmount > max) {
    setAmountError(
      formatMessage({ id: ERROR_MAXIMUM_AMOUNT }, { maxAmount: formatCurrencySeted(max) })
    );
  } else if (newAmount % MULTIPLE_AMOUNT !== 0) {
    setAmountError(
      formatMessage(
        { id: ERROR_MUST_BE_MULTIPLE },
        {
          multiple: MULTIPLE_AMOUNT,
          example: formatCurrencySeted(max - 10),
        }
      )
    );
  } else {
    setAmountError(undefined);
  }
};
