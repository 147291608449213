// Constants
import { SIGNATURE_KEY_EXPIRED_CODE } from './constants/personal-email-step3-challenge.handlers.constants';
// Types
import { PersonalEmailStep3ChallengeBuilderReturnHandlersType } from './types/personal-email-step3-challenge-builder-return.handlers.type';
import { PersonalEmailStep3ChallengeBuilderHandlersType } from './types/personal-email-step3-challenge-builder.handlers.type';
import { PersonalEmailStep3ChallengeMethodBuilderHandlersType } from './types/personal-email-step3-challenge-method-builder.handlers.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';

const manageErrorOnChangeEmailEventHandler = ({
  setChallenged,
  setResult,
  setSignatureExpired,
  code,
}: Pick<PersonalEmailStep3ChallengeBuilderHandlersType, 'setChallenged' | 'setResult' | 'code' | 'setSignatureExpired'>): void => {
  if (code === SIGNATURE_KEY_EXPIRED_CODE) {
    return setSignatureExpired(true);
  }
  setChallenged(false);
  setResult(false);
};

const manageSuccessOnChangeEmailEventHandler = ({
  progressId,
  setChallenged,
  setProgressId,
}: PersonalEmailStep3ChallengeMethodBuilderHandlersType): void => {
  setChallenged(false);
  setProgressId(progressId);
};

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<PersonalEmailStep3ChallengeBuilderHandlersType, 'setChallenged'>): void => {
  setChallenged(true);
};

const challengeRetryButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => setChallengeError(false);

const PersonalEmailStep3ChallengeHandlers = ({
  setChallenged,
  setChallengeError,
  setProgressId,
  setResult,
  setSignatureExpired
}: PersonalEmailStep3ChallengeBuilderHandlersType): PersonalEmailStep3ChallengeBuilderReturnHandlersType => ({
  handleManageErrorOnChangeEmailEvent: (code) =>
    manageErrorOnChangeEmailEventHandler({ setChallenged, setResult, setSignatureExpired, code }),
  handleManageSuccessOnChangeEmailEvent: ({ progressId }: { progressId: string }) =>
    manageSuccessOnChangeEmailEventHandler({ progressId, setChallenged, setProgressId }),
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler({ setChallenged }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setChallengeError }),
});

export default PersonalEmailStep3ChallengeHandlers;
