// Types
import { CorrectType, WrongType } from './types/validate-type-classes.type';
import { ValidateTypeFn, Validator, ValidatorFn } from './types/validate-type.types';
// Utils
import { createWrongType, getWrongTypes, isType } from './utils/validate-type.util';

const isString = <T>(obj: T, field: keyof T): CorrectType | Array<WrongType> =>
  isType(obj, field, 'string');

const isBoolean = <T>(obj: T, field: keyof T): CorrectType | Array<WrongType> =>
  isType(obj, field, 'boolean');

const isNumber = <T>(obj: T, field: keyof T): CorrectType | Array<WrongType> =>
  isType(obj, field, 'number');

const isDate = <T>(obj: T, field: keyof T): CorrectType | Array<WrongType> =>
  obj && obj[field] && obj[field] instanceof Date
    ? new CorrectType()
    : createWrongType(obj, field, 'Date');

const isObject =
  <T>(validator: Validator<T>): ValidatorFn<T> =>
  (obj: any, field: keyof any) => {
    if (obj === undefined || obj === null || obj[field] === undefined || obj[field] === null) {
      return createWrongType(obj, field, 'Object');
    }
    const nestedObj = obj[field];
    const validationResults = Object.entries(validator)
      .map(([key, value]) => ({
        field: key as keyof T,
        validatorFn: value as ValidatorFn<T>,
      }))
      .map(({ field, validatorFn }) => validatorFn(nestedObj, field));
    const wrongTypes = getWrongTypes(validationResults);
    return wrongTypes.length === 0 ? new CorrectType() : wrongTypes;
  };

const optional =
  <T>(validator: ValidatorFn<T>): ValidatorFn<T> =>
  (obj: T, field: keyof T) =>
    obj[field] === undefined || obj[field] === null ? new CorrectType() : validator(obj, field);

const isArray =
  <T>(validator: Validator<T>): ValidatorFn<T> =>
  (obj: any, field: keyof T) => {
    if (!obj && !Array.isArray(obj?.[field] ?? {})) {
      return createWrongType(obj, field, 'Array');
    }
    const firstArrayItem = (obj[field] as Array<any>)?.[0];
    return validateType(firstArrayItem, validator);
  };

const isUnion =
  <T>(options: Array<T>): ValidatorFn<any> =>
  (obj: any, field: keyof any) => {
    if (!obj && !obj[field]) {
      return createWrongType(obj, field, `options: ${options}`);
    }
    const matchesOption = options.filter(option => option === obj[field]).length > 0;
    return matchesOption ? new CorrectType() : createWrongType(obj, field, `options: ${options}`);
  };

const validateType = <T>(obj: T | Array<T>, validator: Validator<T>): ValidateTypeFn<T> => {
  if (Array.isArray(obj)) {
    return obj.map(item => validateType<T>(item, validator)) as Array<T | Array<WrongType>>;
  }

  const validationResults = Object.entries(validator)
    .map(([key, value]) => ({
      field: key as keyof T,
      validatorFn: value as ValidatorFn<T>,
    }))
    .map(({ field, validatorFn }) => validatorFn(obj, field));
  const wrongTypes = getWrongTypes(validationResults);
  return wrongTypes.length === 0 ? obj : wrongTypes;
};

export {
  isArray,
  isBoolean,
  isDate,
  isNumber,
  isObject,
  isString,
  isUnion,
  optional,
  validateType,
};
