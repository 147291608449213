// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const TransactionsTableHeaderOrganismStyled = styled.header<{ innerPadding?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
  ${({ innerPadding }) =>
    innerPadding &&
    css`
      padding: ${({ theme }) => `${theme.spaces.spacingXs} ${theme.spaces.spacingXxxxxl} 0`};

      ${tabletMediaQuery} {
        padding: 0 ${({ theme }) => theme.spaces.spacingXl};
      }

      ${mobileMediaQuery} {
        padding: 0 ${({ theme }) => theme.spaces.spacingS};
      }
    `};
`;

const TransactionsTableHeaderOrganismTest = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
`;

const TransactionsTableHeaderActionsStyled = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
`;

export {
  TransactionsTableHeaderOrganismStyled,
  TransactionsTableHeaderActionsStyled,
  TransactionsTableHeaderOrganismTest,
};
