// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { SELECT_LIMIT_URL_SUFFIX } from './constants/step-1-select-limit.services.constants';
import { LIMIT_INCREASE_CASES_URL } from './constants/commons.constants';
// Types
import { ServiceLimitPropsReturnType } from './types/step-1-select-limit-props-return.type';
import { ServiceLimitPropsType } from './types/step-1-select-limit-props.type';

export const selectLimitService = ({
  caseId,
  cardId,
  newCreditLimit,
}: ServiceLimitPropsType): Promise<[ServiceLimitPropsReturnType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${SELECT_LIMIT_URL_SUFFIX}`,
    params: {
      cardId,
      newCreditLimit,
    },
  });
