// Vendors
import React from 'react';
// Components
import {
  CheckboxComponent,
  ParagraphComponent,
  ScriptBoxComponent,
  InputPrefixSuffixComponent,
} from '@openbank/cb-ui-commons';
// Configurations
import { getCurrencyRangeInputConfiguration } from './configurations/step-1-form.configuration';
// Constants
import { INPUT_PREFIX_SUFFIX_PROPS } from '../../constants/step-1-credit-limit-decrease-cc.constants';
// Styles
import { InputContainerStyled } from '../../step-1-credit-limit-decrease-cc.component.styled';
// Translations
import {
  CHECK_TEXT_1,
  CHECK_TEXT_2,
  CONTACT_CENTER_TEXT_1,
  CONTACT_CENTER_TEXT_2,
  INPUT_LABEL,
} from '../../translations/step-1-credit-limit-decrease-cc.translations';
// Types
import { Step1FormComponentType } from './types/step-1-form.component.type';
// Utils
import { checkForAmountErrors } from './utils/step-1-form.utils';

const Step1FormComponent = ({
  amount,
  amountError,
  check1,
  check2,
  min,
  max,
  setAmount,
  setAmountError,
  setCheck1,
  setCheck2,
  formatMessage,
  formatCurrencySeted,
}: Step1FormComponentType): React.ReactElement => (
  <>
    <ScriptBoxComponent>
      <ParagraphComponent>{formatMessage({ id: CONTACT_CENTER_TEXT_1 })}</ParagraphComponent>
    </ScriptBoxComponent>
    <InputContainerStyled>
      <InputPrefixSuffixComponent
        {...INPUT_PREFIX_SUFFIX_PROPS}
        label={formatMessage({ id: INPUT_LABEL })}
        value={amount}
        errorMessage={amountError}
        onChange={(newAmount: number | '') => {
          setAmount(newAmount);
          checkForAmountErrors({
            min,
            max,
            newAmount,
            setAmountError,
            formatMessage,
            formatCurrencySeted,
          });
        }}
        currencyRange={getCurrencyRangeInputConfiguration({
          formatMessage,
          formatCurrencySeted,
          min,
          max,
        })}
      />
    </InputContainerStyled>
    <ScriptBoxComponent>
      <ParagraphComponent>{formatMessage({ id: CONTACT_CENTER_TEXT_2 })}</ParagraphComponent>
    </ScriptBoxComponent>
    <CheckboxComponent
      value={check1}
      onChange={() => setCheck1(!check1)}
      helperMessage={formatMessage(
        { id: CHECK_TEXT_1 },
        {
          different: formatCurrencySeted(typeof amount === 'number' ? max - amount : 0),
        }
      )}
    />
    <CheckboxComponent
      value={check2}
      onChange={() => setCheck2(!check2)}
      helperMessage={formatMessage(
        { id: CHECK_TEXT_2 },
        {
          newLimit: formatCurrencySeted((amount as number) | 0),
        }
      )}
    />
  </>
);

export { Step1FormComponent };
