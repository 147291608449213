// Vendors
import React from 'react';
// Components
import { AssetComponent, FetchErrorComponent } from '@openbank/cb-ui-commons';
import LoginHeaderComponent from './components/header/login-header.component';
import LoginOrchestratorComponent from './components/orchestrator/login-orchestrator.component';
// Constants
import constants from './constants/login.constants';
// Contexts
import { LoginContextConsumerHOC } from './contexts/login.context';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
import {
  LoginAsideContainerStyled,
  LoginContainerStyled,
  LoginContentContainerStyled,
  LoginFormContainerStyled,
  LoginFormContentContainerStyled,
} from './login.container.styled';
import LoginHook from 'containers/login/hooks/login.hook';

const LoginContainer = (): React.ReactElement => {
  const { fetching } = LoginHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent {...{ fetching }}>
        <LoginContainerStyled>
          <LoginHeaderComponent />
          <LoginContentContainerStyled>
            <LoginAsideContainerStyled>
              <AssetComponent {...constants.LOGIN_ASIDE_ASSET_PROPS} />
            </LoginAsideContainerStyled>
            <LoginFormContainerStyled>
              <LoginFormContentContainerStyled>
                <LoginOrchestratorComponent />
              </LoginFormContentContainerStyled>
            </LoginFormContainerStyled>
          </LoginContentContainerStyled>
        </LoginContainerStyled>
      </FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export { LoginContainer };

export default LoginContextConsumerHOC(LoginContainer);
