// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-insurances-cancellation-lpi-sidebar.constants';
// Styles
import {
  FinancingInsurancesCancellationLPISidebarComponentStyled,
  FinancingInsurancesCancellationLPISidebarContentComponentStyled,
} from './financing-insurances-cancellation-lpi-sidebar.component.styled';
// Translations
import translations from './translations/financing-insurances-cancellation-lpi-sidebar.translations';

const FinancingInsurancesCancellationLPISidebarComponent = (): React.ReactElement => (
  <FinancingInsurancesCancellationLPISidebarComponentStyled>
    <SidebarComponent
      {...constants.FINANCING_INSURANCES_CANCELLATION_LPI_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <FinancingInsurancesCancellationLPISidebarContentComponentStyled>
        <ParagraphComponent
          {...{ ...constants.FINANCING_INSURANCES_CANCELLATION_LPI_SIDEBAR_CONTENT_PROPS }}
        >
          <FormattedMessageComponent id={translations.CONTENT} />
        </ParagraphComponent>
      </FinancingInsurancesCancellationLPISidebarContentComponentStyled>
    </SidebarComponent>
  </FinancingInsurancesCancellationLPISidebarComponentStyled>
);

export { FinancingInsurancesCancellationLPISidebarComponent };
