// Vendors
import { useCallback, useEffect, useState } from 'react';
// Constants
import constants from './constants/poe-keys.hooks.constants';
// Types
import { PoeKeysHookReturnType } from './types/poe-keys-hook-return.type';

const PoeKeysHook = (): PoeKeysHookReturnType => {
  const [isShowingKeys, setIsShowingKeys] = useState<boolean>(false);

  const checkShowingKeys = useCallback(() => {
    const showKeysValue = new URLSearchParams(window.location.search).get(constants.SHOW_KEYS);
    setIsShowingKeys(showKeysValue?.toLowerCase() === constants.TRUE);
  }, []);

  useEffect(() => checkShowingKeys(), [checkShowingKeys]);

  return { isShowingKeys };
};

export default PoeKeysHook;
