// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/dashboard-total-points-desktop-title.component.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Styles
import { DashboardTotalPointsComponentStyled } from '../../dashboard-total-points.component.styled';
// Translations
import translations from '../../translations/dashboard-total-points.translations';
// Types
import { DesktopTitleContentType } from './types/dashboard-total-points-desktop-title.component.type';

export const DesktopTitleContent = ({ total }: DesktopTitleContentType): React.ReactElement => (
  <div className={constants.id}>
    <FormattedNumber {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS }} value={total} />
    <DashboardTotalPointsComponentStyled>
      <FormattedMessageComponent id={translations.POINTS} />
    </DashboardTotalPointsComponentStyled>
  </div>
);
