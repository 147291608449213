// Services
import {
  getFastCashCommissionService,
  getFastCashLimitsService,
  saveFastCashService,
} from '../services/financing-fast-cash.services';
// Types
import { ChallengeType } from 'types/challenge.type';
import { ErrorBodyType } from 'types/error-body.type';
import { FastCashCommissionType } from '../types/fast-cash-commission.type';
import { FastCashLimitsType } from '../types/fast-cash-limits.type';
import { GetFastCashCommissionResolverPropsType } from './types/get-fast-cash-commission-resolver-props.type';
import { GetFastCashLimitsResolverPropsType } from './types/get-fast-cash-limits-resolver-props.type';
import { SaveFastCashResolverPropsType } from './types/save-fast-cash-resolver-props.type';
// Utilities
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';

const getFastCashCommissionResolver = async (
  props: GetFastCashCommissionResolverPropsType
): Promise<[FastCashCommissionType | null] | [null, boolean]> => {
  const [response, { status }] = await getFastCashCommissionService(props);

  return requestHasSuccessStatus(status) ? [response] : [null, true];
};

const getFastCashLimitsResolver = async (
  props: GetFastCashLimitsResolverPropsType
): Promise<[FastCashLimitsType | null] | [null, boolean]> => {
  const [response, { status }] = await getFastCashLimitsService(props);

  return requestHasSuccessStatus(status) ? [response] : [null, true];
};

const saveFastCashResolver = async (
  props: SaveFastCashResolverPropsType
): Promise<[null] | [ChallengeType | ErrorBodyType | null, number]> => {
  const [response, { status }] = await saveFastCashService(props);

  return requestHasSuccessStatus(status) ? [null] : [response, status];
};

export { getFastCashCommissionResolver, getFastCashLimitsResolver, saveFastCashResolver };
