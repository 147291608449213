// Vendors
import React from 'react';
// Components
import { TransactionsRouterComponent } from './components/router/transactions-router.component';
import CreditHeaderComponent from 'components/credit-header/credit-header.component';
// Styles
import { LayoutGridComponentStyled, LayoutGridContentComponentStyled } from 'styles/app.styled';

const TransactionsContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <CreditHeaderComponent />
    <LayoutGridContentComponentStyled>
      <TransactionsRouterComponent />
    </LayoutGridContentComponentStyled>
  </LayoutGridComponentStyled>
);

export default TransactionsContainer;
