// Vendors
import { apiUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import { CHANGE_3D_SECURE_CODE_SERVICE_CONFIGURATION } from './constants/card-settings-change-3d-step2.service.constants';
// Types
import { CardSettingsChange3dStep2ApiBuilderType } from '../types/card-settings-change-3d-step2-api-builder.type';

const change3DSecureCodeService = async ({
  scaChallenge,
  progressId,
}: CardSettingsChange3dStep2ApiBuilderType): Promise<[Record<string, any>, Response]> =>
  apiUtils.put({
    ...CHANGE_3D_SECURE_CODE_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    params: { scaChallenge },
  });

export { change3DSecureCodeService };
