// Types
import { WizardLinkConfigurationReturnType } from './types/security-keys-access-key-change.configurations.return.type';
import { WizardLinkConfigurationType } from './types/security-keys-access-key-change.configurations.type';

export const getWizardLinkConfiguration = ({
  handleMakeUserLogoutEvent,
  accessKeyExpired
}: WizardLinkConfigurationType): WizardLinkConfigurationReturnType => ({
  ...(accessKeyExpired ? { leftLinkConfiguration: { onClick: () => {
    handleMakeUserLogoutEvent();
    window.location.reload();
  } } } : {})
});