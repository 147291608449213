// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/access-portability-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import {
  PersonalAccessPortabilitySidebarListStyled,
  PersonalAccessPortabilitySidebarListItemStyled,
} from './access-portability-sidebar.component.styled';
// Translations
import translations from './translations/access-portability-sidebar.translations';

const AccessPortabilitySidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.PERSONAL_ACCESS_PORTABILITY_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <PersonalAccessPortabilitySidebarListStyled>
        <PersonalAccessPortabilitySidebarListItemStyled>
          <ParagraphComponent {...constants.PERSONAL_ACCESS_PORTABILITY_SIDEBAR_MESSAGE_01_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_1} />
          </ParagraphComponent>
        </PersonalAccessPortabilitySidebarListItemStyled>
        <PersonalAccessPortabilitySidebarListItemStyled>
          <ParagraphComponent {...constants.PERSONAL_ACCESS_PORTABILITY_SIDEBAR_MESSAGE_02_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_2} />
          </ParagraphComponent>
        </PersonalAccessPortabilitySidebarListItemStyled>
        <PersonalAccessPortabilitySidebarListItemStyled>
          <ParagraphComponent {...constants.PERSONAL_ACCESS_PORTABILITY_SIDEBAR_MESSAGE_03_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_3} />
          </ParagraphComponent>
        </PersonalAccessPortabilitySidebarListItemStyled>
      </PersonalAccessPortabilitySidebarListStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default AccessPortabilitySidebarComponent;
