// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import { TransactionsEppStep1CreditMessageHandlers } from '../handlers/transactions-epp-step1-credit-message.handlers';
// Types
import { TransactionsEppStep1CreditMessageHandlersReturnType } from '../handlers/types/transactions-epp-step1-credit-message-return.handlers.type';

export const useTransactionsEppStep1CreditMessage =
  (): TransactionsEppStep1CreditMessageHandlersReturnType =>
    TransactionsEppStep1CreditMessageHandlers({ navigate: useNavigate() });
