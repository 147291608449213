const ORCHESTRATOR_FOOTER_SOCIAL_LINKS = {
  actionLinks: [
    {
      analytics: {
        handleOnClick: (): any => window.open('https://www.zinia.de/amazon/hilfe/'),
      },
      icon: 'question',
      label: 'FAQs',
    },
  ],
  socialLinks: [],
};

export { ORCHESTRATOR_FOOTER_SOCIAL_LINKS };
