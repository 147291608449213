// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingInsurancesCancellationLPITrackingHandlersReturnType } from '../handlers/types/financing-insurances-cancellation-lpi-return.tracking.handlers.type';

const confirmCancelLPIContractButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'confirmCancellation',
  },
  type: TrackingEventsEnum.LINK,
});

const FinancingInsurancesCancellationLPITrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesCancellationLPITrackingHandlersReturnType => ({
  handleConfirmCancelLPIContractButtonClickTracking: () =>
    track(confirmCancelLPIContractButtonClickTrackingHandler()),
});

export { FinancingInsurancesCancellationLPITrackingHandlers };
