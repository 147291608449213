export default {
  CONTEXT_DEFAULT_VALUE: {
    card: false,
    cardDetail: false,
    fetching: true,
    error: false,
    me: false,
    pageLoader: false,
    notificationsData: [],
    lastSession: '',
    setCard: (): null => null,
    setCardDetail: (): null => null,
    setFetching: (): null => null,
    setError: (): null => null,
    setMe: (): null => null,
    setPageLoader: (): null => null,
    setNotificationsData: (): null => null,
    setLastSession: (): null => null,
  },
};
