// Vendors
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Handlers
import { ContractSignatureHandlers } from 'containers/financing/views/credit-limit-increase/handlers/step-6-contract-signature.handler';
import { Step6CreditLimitIncreaseHandlers } from '../handlers/step-6-credit-limit-increase.component.handler';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import { WebsocketHook } from 'hooks/websocket/websocket-hook';
// Types
import { Step6CreditIncreaseLimitHookType } from './types/step-6-credit-limit-increase.component.hook.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const Step6CreditLimitIncreaseHook = (): Step6CreditIncreaseLimitHookType => {
  const { formatMessage } = useCbIntl();
  const [searchParams, setSearchParams] = useSearchParams();

  const { handleOpenWebViewBrowser, handleOpenExternalUrlOnHybridFlow, isHybrid } =
    MobileConnectorContextConsumerHook();

  const { handleWebidSignature, handleOnClickSignWithWebID } = ContractSignatureHandlers({
    handleOpenWebViewBrowser,
    handleOpenExternalUrlOnHybridFlow,
    isHybrid,
  });

  const creditLimitContext = CreditLimitIncreaseContextConsumerHook();
  const { step6, wizardStatus, setFinalFeedback, setWizardStatus } = creditLimitContext;
  const [isWebsocketActive, setIsWebsocketActive] = useState<boolean>(false);
  const { currentCreditLimit, newCreditLimit } = step6.stepData || {};
  const { caseId } = wizardStatus;
  const { currentCard } = AppContextConsumerHook();
  const { currencyCode } = currentCard || {};
  const { handleInitWebsocket } = Step6CreditLimitIncreaseHandlers({
    ...WebsocketHook(),
    caseId,
    searchParams,
    wizardStatus,
    setIsWebsocketActive,
    handleWebidSignature,
    setFinalFeedback,
    setSearchParams,
    setWizardStatus,
  });

  useEffect(handleInitWebsocket, []);

  return {
    currentCreditLimit: currentCreditLimit as number,
    newCreditLimit: newCreditLimit as number,
    currencyCode,
    isWebsocketActive,
    formatMessage,
    handleOnClickSignWithWebID: () => handleOnClickSignWithWebID(step6?.stepData?.signUrl),
  };
};

export { Step6CreditLimitIncreaseHook };
