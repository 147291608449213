const CONTEXT_DEFAULT_VALUE = {
  challenge: null,
  challengeError: false,
  error: false,
  isEditing: false,
  signatureExpired: false,
  newAddress: null,
  setChallenge: (): null => null,
  setChallengeError: (): null => null,
  setError: (): null => null,
  setIsEditing: (): null => null,
  setNewAddress: (): null => null,
  setSignatureExpired: (): null => null,
};

export { CONTEXT_DEFAULT_VALUE };
