// Types
import { type CategoriesApiReturnType } from 'containers/documentation/types/categories-api-return.type';
import { type DocumentationApiReturnType } from 'containers/documentation/types/documentation-api-return.type';

const isDocumentTypeResponse = (
  response: DocumentationApiReturnType | Record<string, any>
): response is DocumentationApiReturnType =>
  (response as DocumentationApiReturnType).documents !== undefined;

const isCategoryTypeResponse = (
  response: CategoriesApiReturnType | Record<string, any>
): response is CategoriesApiReturnType =>
  (response as CategoriesApiReturnType).categories !== undefined || isResponseEmpty(response);

const isResponseEmpty = (
  response: DocumentationApiReturnType | CategoriesApiReturnType | Record<string, any>
): boolean => Object.keys(response).length === 0;

export { isDocumentTypeResponse, isCategoryTypeResponse };
