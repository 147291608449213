// Constants
import constants from 'utils/constants/avatar.utils.constants';
// Types
import { AvatarUtilsType } from 'utils/types/avatar.utils.type';

const convertToCamelCaseGender = (genderTCode: string): string =>
  genderTCode.charAt(0).toUpperCase() + genderTCode.slice(1).toLowerCase();

const convertToZeroCase = (value: string): string => `0${value}`.slice(-2);

const buildAvatarPersonalDataAccordingData = ({
  genderTCode,
  targetTCode,
}: Omit<AvatarUtilsType, 'avatar'>): string =>
  `${constants.AVATAR_PREFIX}_${convertToCamelCaseGender(genderTCode)}_${convertToZeroCase(
    targetTCode
  )}`;

const getPersonalNameAvatar = ({ genderTCode, targetTCode }: AvatarUtilsType): string =>
  buildAvatarPersonalDataAccordingData({
    genderTCode: genderTCode || constants.DEFAULT_GENDER_T_CODE,
    targetTCode: targetTCode || constants.DEFAULT_TARGET_T_CODE,
  });

export { getPersonalNameAvatar };
