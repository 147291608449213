// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useRecoveryKeyContextConsumerHook } from 'containers/recovery-key/contexts/recovery-key.context';
// Event handlers
import RecoveryKeyStep1PhoneHandlers from '../handlers/recovery-key-phone.handlers';
import RecoveryKeyStep1PhoneTrackingHandlers from '../handlers/recovery-key-phone.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RecoveryKeyStep1PhoneHookType } from './types/recovery-key-phone.hook.type';

const useRecoveryKeyPhone = (): RecoveryKeyStep1PhoneHookType => {
  const { localization } = AppContextConsumerHook(),
    { phoneNumber, prefix, setPhoneNumber, setPrefix, fetching } =
      useRecoveryKeyContextConsumerHook(),
    { phonePrefixes: prefixes = [] } = localization;

  const { formatMessage } = useCbIntl();

  const [error, setError] = useState<boolean>(false);

  const {
    handleManagePhoneNumberRecoveryStep1InputErrorTracking,
    handleSaveRecoveryStep1PrefixOptionClickTracking,
    handleSaveRecoveryStep1NumberInputClickEventTracking,
  } = AppTrackingHook(RecoveryKeyStep1PhoneTrackingHandlers);

  return {
    ...RecoveryKeyStep1PhoneHandlers({
      handleManagePhoneNumberRecoveryStep1InputErrorTracking,
      handleSaveRecoveryStep1PrefixOptionClickTracking,
      prefix,
      phoneNumber,
      setError,
      setPhoneNumber,
      setPrefix,
    }),
    fetching,
    formatMessage,
    error,
    handleSaveRecoveryStep1NumberInputClickEventTracking,
    phoneNumber,
    prefix,
    prefixes,
  };
};

export { useRecoveryKeyPhone };
