// Types
import { CardTransactionPointsSignEnumeration } from 'enumerations/card-transactions-points-sign.enumeration';
import { CardTransactionPointsType } from 'types/card-transaction-points.type';
import { GetTransactionsPointsPropsType } from './types/table-row-description-points.utils.type';

const transactionHasPoints = (reward?: CardTransactionPointsType | Record<string, any>): boolean =>
  Boolean(reward?.amount && Number(reward?.amount) >= 0);

const hasDiferentSign = (reward: CardTransactionPointsType | Record<string, any>): boolean =>
  reward?.sign !== CardTransactionPointsSignEnumeration.negative &&
  reward?.sign !== CardTransactionPointsSignEnumeration.positive;

const arePositivePoints = (reward: CardTransactionPointsType | Record<string, any>): boolean =>
  reward?.sign === CardTransactionPointsSignEnumeration.positive && reward?.signedAmount > 0;

const transformTransactionsPoint = (
  reward: CardTransactionPointsType | Record<string, any>
): string => {
  if (hasDiferentSign(reward) || arePositivePoints(reward)) {
    return `+${Math.abs(reward?.signedAmount)}`;
  }
  return reward?.signedAmount.toString();
};

const getTransactionsPoints = ({
  reward,
  formatNumber,
}: GetTransactionsPointsPropsType): string => {
  if (!reward) return '0';

  const formattedNumber = formatNumber(parseInt(transformTransactionsPoint(reward), 10));

  return arePositivePoints(reward) ? `+${formattedNumber}` : formattedNumber;
};

export { getTransactionsPoints, transactionHasPoints };
