// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/security-keys-signature-key-change-third-step-challenge.service.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { SignatureKeyChangeCheckpointServiceType } from '../resolvers/types/signature-key-change-resolvers.type';

const signatureKeyChangeCheckpointService = async ({
  oldSignatureKey,
  newSignatureKey,
  progressId,
}: SignatureKeyChangeCheckpointServiceType): Promise<[ChallengeType, Response]> => {
  const customHeaders = progressId ? { progressId } : undefined;
  return apiUtilsCb.post({
    endpoint: constants.COMPLETE_SIGNATURE_KEY_CHANGE_OTP_SERVICE_CONFIGURATION.endpoint,
    params: { oldSignatureKey, newSignatureKey },
    customHeaders,
  });
};
export { signatureKeyChangeCheckpointService };
