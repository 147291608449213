// Vendors
import { useState } from 'react';
// Handlers
import { PlatformHandlers } from '../handlers/platform.handlers';
import { PlatformTrackingHandlers } from '../handlers/platform.tracking.handlers';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PlatformHookReturnType } from './types/platform.hook.return.type';
import { PlatformHookType } from './types/platform.hook.type';
// Utils
import { getConfig } from '../utils/platform.utils';

const PlatformHook = ({ configuration }: PlatformHookType): PlatformHookReturnType => {
  const { isMobile } = DeviceTypeHook();
  const [tooltipOpened, setTooltipOpened] = useState<boolean>(false);

  const { onOpenTooltip } = PlatformHandlers({
    ...AppTrackingHook(PlatformTrackingHandlers),
    setTooltipOpened,
    tooltipOpened,
  });

  return {
    parserConfiguration: getConfig({ configuration, isMobile }),
    isMobile,
    onOpenTooltip,
  };
};

export { PlatformHook };
