// Resolvers
import { fetchLocalizationConfigurationResolver } from '../resolvers/localization.resolver';
// Types
import {
  LocalizationBuilderReturnHandlersType,
  LocalizationBuilderHandlersType,
} from './types/localization.handlers.type';

const fetchLocalizationConfigurationHandler = async ({
  setFetching,
  setLocalization,
}: LocalizationBuilderHandlersType): Promise<any> => {
  setFetching(true);
  const [configuration] = await fetchLocalizationConfigurationResolver();

  setLocalization({});
  configuration && setLocalization(configuration);
  setFetching(false);
};

const LocalizationHandlers = (
  props: LocalizationBuilderHandlersType
): LocalizationBuilderReturnHandlersType => ({
  handleFetchLocalizationConfiguration: () => fetchLocalizationConfigurationHandler(props),
});

export default LocalizationHandlers;
