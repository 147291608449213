// Vendors
import React from 'react';
// Constants
import { CHALLENGE_SERVICE } from './contants/credit-limit-decrease-cc-challenge.constants';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Hooks
import { CreditLimitDecreaseCCChallengeHook } from './hooks/credit-limit-decrease-cc-challenge.hook';
// Types
import { CreditLimitDecreaseCCChallengeComponentProps } from './types/credit-limit-decrease-cc-challenge.component.type';

const CreditLimitDecreaseCCChallengeComponent = ({
  challengeError,
  setChallenge,
  setChallengeError,
  baseServiceBuilder,
}: CreditLimitDecreaseCCChallengeComponentProps): React.ReactElement => {
  const { handleChallengeRetryButtonClick, handleChallengeSuccess } =
    CreditLimitDecreaseCCChallengeHook({ setChallenge, setChallengeError });

  return (
    <ChallengeWrapperComponent
      {...CHALLENGE_SERVICE}
      baseServiceBuilder={baseServiceBuilder}
      challengeError={challengeError}
      onRetry={handleChallengeRetryButtonClick}
      onSuccess={handleChallengeSuccess}
    />
  );
};

export { CreditLimitDecreaseCCChallengeComponent };
