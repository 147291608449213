// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  ASSET_PROPS,
  PARAGRAPH_PROPS,
} from './constants/security-check-modal-first-step.constants';
// Styles
import { SecurityCheckModalFirstStepStyled } from './security-check-modal-first-step.component.styled';
// Translations
import { CONTENT } from './translations/security-check-modal-first-step.translations';

const SecurityCheckModalFirstStepComponent = (): React.ReactElement => {
  return (
    <SecurityCheckModalFirstStepStyled>
      <AssetComponent {...ASSET_PROPS} />
      <ParagraphComponent {...PARAGRAPH_PROPS}>
        <FormattedMessageComponent
          id={CONTENT}
          values={{
            br: <br />,
          }}
        />
      </ParagraphComponent>
    </SecurityCheckModalFirstStepStyled>
  );
};

export { SecurityCheckModalFirstStepComponent };
