// Services
import { fetchCardsTransactionsDetailService } from '../services/transactions.services';
// Types
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
import { TransactionDetailsApiBuilderType } from '../types/transaction-details-api-builder.type';
// Utils
import { isValidTransactionsDetailApiContract } from 'utils/transactions-api-contract.utils';

const fetchCardsTransactionsDetailResolver = async (
  props: TransactionDetailsApiBuilderType
): Promise<[CardTransactionsDetailType] | [null, number]> => {
  const [response, { status }] = await fetchCardsTransactionsDetailService(props);

  return isValidTransactionsDetailApiContract(response)
    ? [response.resource as CardTransactionsDetailType]
    : [null, status];
};

export { fetchCardsTransactionsDetailResolver };
