// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { InputTextComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/settings-card-control-locations-search.constants';
// Custom Hooks
import SettingsCardControlLocationsSearchHook from './hooks/settings-card-control-locations-search.hook';
// Translations
import translations from './translations/settings-card-control-locations-search.translations';

const SettingsCardControlLocationsSearchComponent = (): React.ReactElement => {
  const {
    formatMessage,
    handleSearchCountriesInputEventTracking,
    handleSearchCountriesInputEvent,
  } = SettingsCardControlLocationsSearchHook();

  return (
    <InputTextComponent
      {...{ ...constants.SETTINGS_CARD_CONTROL_LOCATIONS_SEARCH_PROPS }}
      placeholder={formatMessage({ id: translations.PLACEHOLDER })}
      label={formatMessage({ id: translations.LABEL })}
      onChange={handleSearchCountriesInputEvent}
      onClick={handleSearchCountriesInputEventTracking}
    />
  );
};

export default SettingsCardControlLocationsSearchComponent;
