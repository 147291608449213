const PERSONAL_EMAIL_STEP_1_MESSAGE_PROPS = {
    testId: 'personal-email-step1-script-box-message',
  },
  PERSONAL_EMAIL_STEP_1_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'personal-email-step1-message-table-row-script-box-description',
  },
  PERSONAL_EMAIL_STEP_1_MESSAGE_TITLE_PROPS = {
    bold: true,
    testId: 'personal-email-step1-message-script-box-title',
  };

export {
  PERSONAL_EMAIL_STEP_1_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_1_MESSAGE_DESCRIPTION_PROPS,
  PERSONAL_EMAIL_STEP_1_MESSAGE_TITLE_PROPS,
};
