// Vendors
import { useEffect, useRef } from 'react';
// Constants
import constants from '../constants/personal-number.constants';
// Contexts
import { PersonalNumberContextConsumerHook } from '../contexts/personal-number.context';
// Types
import { PersonalNumberViewHookType } from './types/personal-number-view.hook.type';
// Utils
import { getWizardErrorMessageConfiguration } from './utils/personal-number-data-contact.hook.utils';

const PersonalNumberViewHook = (): PersonalNumberViewHookType => {
  const { alternativeError } = PersonalNumberContextConsumerHook();
  const errorConfigurationRef = useRef(
    constants.PERSONAL_NUMBER_WIZARD_STEPS.feedbackConfiguration.errorConfiguration
  );

  useEffect(() => {
    if (alternativeError) {
      errorConfigurationRef.current = getWizardErrorMessageConfiguration(alternativeError);
    }
  }, [alternativeError]);

  return {
    errorConfigurationRef,
  };
};

export { PersonalNumberViewHook };
