// Vendors
import React from 'react';
// Components
import { ParagraphComponent, RadioButtonComponent } from '@openbank/cb-ui-commons';
import CardDetailsWrapperComponent from 'components/card-details/card-details-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getRadioButtonConfiguration } from './configurations/block-card-reason.component.configurations';
// Constants
import constants from './constants/block-card-reason.constants';
// Hooks
import BlockCardReasonHook from './hooks/block-card-reason.hook';
// Styles
import { BlockCardReasonComponentStyled } from './block-card-reason.component.styled';
// Translations
import { DESCRIPTION } from './translations/block-card-reason.translations';

const BlockCardReasonComponent: React.FC = () => {
  const { blockReason, handleBlockReasonChange } = BlockCardReasonHook();

  return (
    <BlockCardReasonComponentStyled>
      <CardDetailsWrapperComponent />
      <FormattedMessageComponent id={DESCRIPTION} />
      {constants.BLOCK_REASONS.map(({ eventLabel, id, testId, value }, i) => (
        <RadioButtonComponent
          key={`${testId}-${i}`}
          {...getRadioButtonConfiguration({
            blockReason,
            eventLabel,
            handleBlockReasonChange,
            testId,
            value,
          })}
        >
          <ParagraphComponent>
            <FormattedMessageComponent id={id} />
          </ParagraphComponent>
        </RadioButtonComponent>
      ))}
    </BlockCardReasonComponentStyled>
  );
};

export default BlockCardReasonComponent;
