// Vendors
import React from 'react';
// Components
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_HOLDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_NUMBER_PROPS,
} from './constants/card-settings-contract-current-contract.constants';
// Hooks
import useCardSettingsContractCurrentContractHook from './hooks/card-settings-contract-current-contract.hook';

const CardSettingsContractCurrentContractComponent = (): React.ReactElement => {
  const { cardContractId, holderName } = useCardSettingsContractCurrentContractHook();

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_DIVIDER_PROPS}
    >
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_HOLDER_PROPS}
        value={holderName}
      />
      <CardSettingsResumeLinkComponent
        {...CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_NUMBER_PROPS}
        value={cardContractId}
      />
    </CardSettingsContractCurrentSectionComponent>
  );
};

export default CardSettingsContractCurrentContractComponent;
