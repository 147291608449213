// Constants
import { PIN_CODE_LENGTH } from './constants/card-settings-change-pin.utils.constants';
// Utilities
import {
  checkHasFourNumbersOnly,
  checkNoAscendingDescendingSequences,
  noContain3OrMoreConsecutiveAndEqualsNumbers,
  notMatchMontDayOrYearCustomerBirthDate,
} from './card-settings-change-pin-constraints.utils';

const newPinMatchesWithRepeatedPin = ({ newPin, repeatedPin }: any): boolean =>
  newPin && repeatedPin && newPin === repeatedPin;

const pinMatchesAllConstraints = ({ pin, dateOfBirth }: any): boolean =>
  [
    checkNoAscendingDescendingSequences(pin),
    checkHasFourNumbersOnly(pin),
    noContain3OrMoreConsecutiveAndEqualsNumbers(pin),
    notMatchMontDayOrYearCustomerBirthDate({ dateOfBirth, value: pin }),
  ].every(constraint => constraint);

const isValidNewPin = ({ newPin, repeatedPin, dateOfBirth }: any): boolean =>
  newPinMatchesWithRepeatedPin({ newPin, repeatedPin }) &&
  pinMatchesAllConstraints({ pin: newPin, dateOfBirth }) &&
  pinMatchesAllConstraints({ pin: repeatedPin, dateOfBirth });

const pinFilledIsFilled = (pin: string): boolean => pin?.length === PIN_CODE_LENGTH;

export { isValidNewPin, newPinMatchesWithRepeatedPin, pinFilledIsFilled, pinMatchesAllConstraints };
