// Vendors
import styled from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Utilities
import { mobileMediaQuery, convertPxsToRems } from '@openbank/cf-ui-framework';

export const TransactionTableRowAmountComponentStyled = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex: 0 0;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  text-align: right;
  width: 100%;
  min-width: ${convertPxsToRems({ pixels: 115, base: 14 })};

  ${mobileMediaQuery} {
    min-width: ${convertPxsToRems({ pixels: 107, base: 14 })};
  }
`;

export const ParagraphComponentStyled = styled(ParagraphComponent)<{ positive: boolean }>`
  ${({ positive, theme }) => positive && `color: ${theme.colors.fontTertiaryC};`}
`;
