// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const COMPLETE = {
  id: 'document-ko-framing-error-alternative',
  sizes: {
    height: 64,
    width: 64,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'complete-modal-asset',
};

const EXAMPLE_MODAL_DESCRIPTION_PROPS = {
  testId: 'example-modal-description',
};

const EXAMPLE_MODAL_BUTTON_PROPS = {
  testId: 'example-modal-button',
};

const NOT_REFLECTIONS = {
  id: 'document-ko-lightning-error-alternative',
  sizes: {
    height: 64,
    width: 64,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'no-reflections-modal-asset',
};

const VALID_AND_UP_TO_DATE = {
  id: 'Document_Ok_Alternative',
  sizes: {
    height: 64,
    width: 64,
  },
  type: assetConstants.TYPES.ILLUSTRATION,
  testId: 'valid-up-to-date-modal-asset',
};

export {
  NOT_REFLECTIONS,
  COMPLETE,
  VALID_AND_UP_TO_DATE,
  EXAMPLE_MODAL_DESCRIPTION_PROPS,
  EXAMPLE_MODAL_BUTTON_PROPS,
};
