// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
// Enumerations
import { CookiesEnumeration } from 'enumerations/cookies.enumeration';
// Types
import {
  ThirdDownHandlersBuilderType,
  ThirdDownHandlersMethodsHandlersType,
} from './types/third-down.handlers.type';

const makeThirdPartyAgreementButtonClickHandler = async (
  setShowThirdDownModal: ThirdDownHandlersBuilderType['setShowThirdDownModal']
): Promise<void> => {
  cookies.setCookie({ name: CookiesEnumeration.THIRD_PARTY_DOWN, value: 'true' });
  setShowThirdDownModal(false);
};

const ThirdDownHandlers = (
  setShowThirdDownModal: ThirdDownHandlersBuilderType['setShowThirdDownModal']
): ThirdDownHandlersMethodsHandlersType => ({
  handleMakeThirdPartyAgreementButtonClick: () =>
    makeThirdPartyAgreementButtonClickHandler(setShowThirdDownModal),
});

export { ThirdDownHandlers };
