// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { DEFAULT_CONTEXT_VALUES } from './constants/card-settings-new-card.context.constants';
import { DEFAULT_ERROR } from '../constants/card-settings-new-card.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { CardSettingsNewCardContextType } from './types/card-settings-new-card.context.type';
import { ErrorMessageType } from './types/error-message.type';

const CardSettingsNewCardContext =
  createContext<CardSettingsNewCardContextType>(DEFAULT_CONTEXT_VALUES);

export const CardSettingsNewCardContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsNewCardContextType>> => {
  const [error, setError] = useState<ErrorMessageType>(DEFAULT_ERROR);

  return (
    <CardSettingsNewCardContext.Provider
      value={{
        error,
        setError,
      }}
    >
      {children as React.ReactNode}
    </CardSettingsNewCardContext.Provider>
  );
};

export const useCardSettingsNewCardContextConsumerHook = (): CardSettingsNewCardContextType =>
  useContext(CardSettingsNewCardContext);

/* eslint-disable */
export const CardSettingsNewCardContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<any>> =>
    (
      <CardSettingsNewCardContextProvider>
        <Component {...props} />
      </CardSettingsNewCardContextProvider>
    );
