// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TransactionsLandingRowDetailsViewInstallmentBuilderReturnTrackingHandlersType } from './types/transactions-landing-row-details-installment-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const viewTransactionOnAmazonLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'viewInstallmentsPlan',
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsLandingRowDetailsViewInstallmentTrackingHandlers = (
  track: TrackBuilderType
): TransactionsLandingRowDetailsViewInstallmentBuilderReturnTrackingHandlersType => ({
  handleViewInstallmentsPlanLinkClickTracking: () =>
    track(viewTransactionOnAmazonLinkClickTrackingHandler()),
});

export default TransactionsLandingRowDetailsViewInstallmentTrackingHandlers;
