// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Constants
import constants from './constants/card-settings-check-3d-wizard-step2-actions.constants';
// Resolvers
import { fetch3DSecureCodeResolver } from 'containers/card-settings/views/check-3d/components/wizard/components/step2/resolvers/card-settings-check-3d-wizard-step2.resolvers';
// Types
import {
  CardSettingsCheck3dWizardStep2BuilderHandlersType,
  CardSettingsCheck3dWizardStep2BuilderReturnHandlersType,
  CardSettingsCheck3dWizardStepPreviousButtonClickType,
} from './types/card-settings-check-3d-wizard-step2.handlers.type';
// Utils
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const check3DCodePreviousButtonClick = ({
  setCurrentStep,
  setProgressId,
}: CardSettingsCheck3dWizardStepPreviousButtonClickType): void => {
  setCurrentStep(constants.STEP_ONE);
  setProgressId(constants.EMPTY_STRING);
};

const check3DCodeButtonClickHandler = async ({
  handleCheck3DCodeButtonClickTracking,
  progressId,
  setChallengeError,
  setCurrentStep,
  setFetching,
  setHasChallenged,
  setProgressId,
  setResult,
  setScaCode,
}: CardSettingsCheck3dWizardStep2BuilderHandlersType): Promise<void> => {
  handleCheck3DCodeButtonClickTracking();
  setFetching(true);

  const [code, error] = await fetch3DSecureCodeResolver(progressId);

  if (code) {
    setCurrentStep(constants.STEP_TWO);
    setScaCode(code);
    setHasChallenged(true);
  } else {
    if (isWrongOTPError(error)) {
      setChallengeError(true);
    } else {
      setResult(false);
    }
  }
  setProgressId(constants.EMPTY_STRING);
  setFetching(false);
};

const CardSettingsCheck3dWizardStep2Handlers = (
  props: CardSettingsCheck3dWizardStep2BuilderHandlersType
): CardSettingsCheck3dWizardStep2BuilderReturnHandlersType => ({
  handleCheck3DCodeButtonClick: () => check3DCodeButtonClickHandler(props),
  handleCheck3DCodePreviousButtonClick: () => check3DCodePreviousButtonClick(props),
});

export default CardSettingsCheck3dWizardStep2Handlers;
