// Vendors
import { useState, useEffect } from 'react';
// Types
import { useTimerHookType } from './types/use-timer.hook.type';
// Handlers
import TimerHandlers from 'handlers/use-timer.handler';
import { TimerEnum } from '@openbank/cf-ui-static-data';

const useTimer = (initialTime: number): useTimerHookType => {
  const [timer, setTimer] = useState(initialTime);
  const [isTimerActive, setIsTimerActive] = useState(false);

  const { handleRestart } = TimerHandlers({
    initialTime,
    setIsTimerActive,
    setTimer,
  });

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;

    if (isTimerActive) {
      interval = setInterval(() => {
        setTimer(prevTime => {
          if (prevTime > 1) {
            return prevTime - 1;
          }
          setIsTimerActive(false);
          return 1;
        });
      }, TimerEnum.TIMEOUT_ONE_SECOND);
    }

    return () => clearInterval(interval);
  }, [isTimerActive]);

  return { timer, isTimerActive, handleRestart };
};

export default useTimer;
