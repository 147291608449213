const DOMAIN = 'cobranded.services.userSettings.privateArea.cbModalAddress.',
  DOMAIN_BUTTONS = `${DOMAIN}buttons.`;

const ADDRESS_MODAL_TITLE = `${DOMAIN}title`;

const ADDRESS_MODAL_CANCEL_BUTTON = `${DOMAIN_BUTTONS}cancel`;

const ADDRESS_MODAL_SAVE_BUTTON = `${DOMAIN_BUTTONS}save`;

export { ADDRESS_MODAL_CANCEL_BUTTON, ADDRESS_MODAL_SAVE_BUTTON, ADDRESS_MODAL_TITLE };
