// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

export default {
  DASHBOARD_POINTS_ASSET_PROPS: {
    id: 'points-alternative',
    sizes: {
      height: 64,
      width: 64,
    },
    testId: 'dashboard-points-asset',
    type: 'illustration',
  },
  DASHBOARD_POINTS_TITLE_PROPS: {
    tag: titleConstants.TAGS.H3,
    testId: 'dashboard-spent-total-title-section',
  },
};
