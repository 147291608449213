// Vendors
import { linkConstants } from '@openbank/cb-ui-commons';

const NOTIFICATIONS_SETTINGS_MODAL_PARAGRAPH_PROPS = {
  testId: 'cancel-modal-description-01',
};
const NOTIFICATIONS_SETTINGS_MODAL_BUTTON_PROPS = {
  testId: 'cancel-modal-button',
};
const NOTIFICATIONS_SETTINGS_MODAL_LINK_PROPS = {
  type: linkConstants.TYPES.ACTION_PRIMARY,
  testId: 'cancel-modal-links',
};

export {
  NOTIFICATIONS_SETTINGS_MODAL_PARAGRAPH_PROPS,
  NOTIFICATIONS_SETTINGS_MODAL_BUTTON_PROPS,
  NOTIFICATIONS_SETTINGS_MODAL_LINK_PROPS,
};
