// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_01_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_02_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_03_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_PROPS,
  RECOVERY_KEY_STEP5_MESSAGE_TITLE_PROPS,
} from './constants/recovery-key-step5-message.constants';
// Translations
import {
  DESCRIPTION_01,
  DESCRIPTION_02,
  DESCRIPTION_03,
  TITLE,
} from './translations/recovery-key-step5-message.translations';

const RecoveryKeyStep5MessageComponent = (): React.ReactElement => (
  <MessageComponent {...RECOVERY_KEY_STEP5_MESSAGE_PROPS}>
    <ParagraphComponent {...RECOVERY_KEY_STEP5_MESSAGE_TITLE_PROPS}>
      <FormattedMessageComponent id={TITLE} />
    </ParagraphComponent>
    <ParagraphComponent {...RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_01_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION_01} />
    </ParagraphComponent>
    <ParagraphComponent {...RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_02_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION_02} />
    </ParagraphComponent>
    <ParagraphComponent {...RECOVERY_KEY_STEP5_MESSAGE_DESCRIPTION_03_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION_03} />
    </ParagraphComponent>
  </MessageComponent>
);

export default RecoveryKeyStep5MessageComponent;
