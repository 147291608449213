// Translations
import translations from 'containers/transactions/views/landing/translations/transactions-landing.translations';
// Enumerations
import { CalculatedDateOptionsEnumeration } from '../enumerations/calculated-dates-options-types.enumeration';

const FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT = {
  tag: 'h5',
  bold: false,
};
const FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS = {
  showLineSeparator: false,
  tag: 'h5',
  testId: 'filter-modal-list-selector',
  gap: 'spacingS',
};

const DAY_OF_MONTH = {
  FIRST: 1,
  LAST: 0,
};
const TIME_PERIODS = {
  ONE_MONTH_AGO: 1,
  SIX_MONTHS_AGO: 6,
  FIFTEEN_DAYS_AGO: 15,
  TWELVE_MONTHS_AGO: 12,
};

const DATE_OPTIONS = [
  { id: 'All', helperMessageId: translations.DATE_OPTION_ALL },
  {
    id: 'lastFifteenDays',
    helperMessageId: translations.DATE_OPTION_LAST_15_DAYS_TITLE,
    calculatedDatesOptions: CalculatedDateOptionsEnumeration.LAST_FIFTEEN_DAYS,
  },
  {
    id: 'lastMonth',
    helperMessageId: translations.DATE_OPTION_LAST_MONTH_TITLE,
    calculatedDatesOptions: CalculatedDateOptionsEnumeration.LAST_MONTH,
  },
  {
    id: 'lastSixMonths',
    helperMessageId: translations.DATE_OPTION_LAST_6_MONTHS_TITLE,
    calculatedDatesOptions: CalculatedDateOptionsEnumeration.LAST_SIX_MONTHS,
  },
  {
    id: 'lastTwelveMonths',
    helperMessageId: translations.DATE_OPTION_LAST_12_MONTHS_TITLE,
    calculatedDatesOptions: CalculatedDateOptionsEnumeration.LAST_TWELVE_MONTHS,
  },
  { id: 'custom', helperMessageId: translations.DATE_OPTION_CUSTOM_DATE_TITLE },
];

export {
  DAY_OF_MONTH,
  TIME_PERIODS,
  FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS,
  FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT,
  DATE_OPTIONS,
};
