// Constants
import { messageConstants, paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  PERSONAL_LANDING_SIDEBAR_PROPS: {
    testId: 'personal-landing',
  },
  PERSONAL_LANDING_SIDEBAR_DESCRIPTION_PROPS: {
    testId: 'personal-landing-sidebar-table-row-description',
    size: paragraphConstants.SIZES.M,
  },
  PERSONAL_LANDING_SIDEBAR_MESSAGE_PROPS: {
    testId: 'personal-landing-sidebar-message',
    type: messageConstants.TYPES.WARNING,
  },
  PERSONAL_LANDING_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'personal-landing-sidebar-message-01',
  },
  PERSONAL_LANDING_SIDEBAR_MESSAGE_02_PROPS: {
    testId: 'personal-landing-sidebar-message-02',
  },
  PERSONAL_LANDING_SIDEBAR_MESSAGE_03_PROPS: {
    testId: 'personal-landing-sidebar-message-03',
  },
};
