// Services
import { changeBillingAddressService } from '../services/personal-address.services';
// Types
import { ChallengeType } from 'types/challenge.type';
import { ChangeBillingAddressResolverType } from './types/change-billing-address-resolver.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const changeBillingAddressResolver = async (
  props: ChangeBillingAddressResolverType
): Promise<[null] | [ChallengeType | null, number]> => {
  const [response, { status }] = await changeBillingAddressService(props);

  return wasSuccessRequest(status) ? [null] : [response as ChallengeType, status];
};

export { changeBillingAddressResolver };
