export const PRODUCT_DETAIL_PROPS = {
  test_id: 'credit-limit-decrease-cc--card-product-detail',
};

export const PARAGRAPH_PROPS = {
  test_id: 'credit-limit-decrease-cc--card-paragraph',
  bold: true,
};

export const INPUT_PREFIX_SUFFIX_PROPS = {
  type: 'currency',
  suffix: '€',
  maxOfDecimalsInCurrency: 0,
};

export const MIN_TEST_ID = 'credit-limit-decrease-cc--min-amount';
export const MAX_TEST_ID = 'credit-limit-decrease-cc--max-amount';
export const MULTIPLE_AMOUNT = 10;
export const DEFAULT_MIN_LIMIT_PRODUCT = 210;
