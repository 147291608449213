// Enums
import { DeviceModalEnum } from '../enumerations/device-modal.enumeration';
// Types
import { DeviceModalHandlersReturnType } from './types/device-modal.handlers.return.type';
import { DeviceModalHandlersType } from './types/device-modal.handlers.type';

const manageErrorUnrollTrustedDeviceEventHandler = ({ setChallenged }) => {
  setChallenged(false);
};

const manageSuccessUnrollTrustedDeviceEventHandler = ({ setSolvedChallenged, setProgressId, newProgressId }) => {
  setProgressId(newProgressId);
  setSolvedChallenged(true);
};

const onAcceptHandler = ({ onAccept, progressId, onModalAcceptTracking, type }) => {
  onModalAcceptTracking(type);
  onAccept({ progressId });
};

const onModalOpenHandler = ({
  type,
  device,
  onModalOpenTracking,
  isCurrentConectionTracking,
  isTrustedDeviceTracking,
}) => {

  onModalOpenTracking(type);
  device.lastSession?.active && isCurrentConectionTracking();
  if(device.trusted && type !== DeviceModalEnum.UNBLOCK) { 
    isTrustedDeviceTracking();
  }
};

const onLinkCloseHandler = ({ onModalCloseTracking, type, onClose }) => {
  onModalCloseTracking(type);
  onClose();
};

const onCrossCloseHandler = ({ onModalCrossTracking, type, onClose }) => {
  onModalCrossTracking(type);
  onClose();
};

const DeviceModalHandlers = (props: DeviceModalHandlersType): DeviceModalHandlersReturnType => ({
  handleManageErrorUnrollTrustedDeviceEvent: () => manageErrorUnrollTrustedDeviceEventHandler(props),
  handleManageSuccessUnrollTrustedDeviceEvent: ({ progressId }) => manageSuccessUnrollTrustedDeviceEventHandler({...props, newProgressId: progressId}),
  handleOnAccept: () => onAcceptHandler(props),
  onModalOpen: () => onModalOpenHandler(props),
  onLinkClose: () => onLinkCloseHandler(props),
  onCrossClose: () => onCrossCloseHandler(props)
  
});

export { DeviceModalHandlers };