// Enumerations
import { InstallmentsStatusEnumeration } from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/enumerations/epp-element-status.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.financingLanding.cbAmortizationModal.status.';
const TABLE_DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbFinancing.landing.cbTable.cbDetails.amortizationTable.';

const DESCRIPTIONS = {
  [InstallmentsStatusEnumeration.CANCELLED]: `${DOMAIN}cancelled`,
  [InstallmentsStatusEnumeration.LIQUIDATED]: `${DOMAIN}settled`,
  [InstallmentsStatusEnumeration.PENDING]: `${DOMAIN}pending`,
  [InstallmentsStatusEnumeration.RETURNED]: `${DOMAIN}returned`,
};

const TABLE_TRANSLATIONS = {
  INTERESTS: `${TABLE_DOMAIN}interests`,
  INSTALMENT: `${TABLE_DOMAIN}instalment`,
  INSTALMENT_NUMBER: `${TABLE_DOMAIN}number`,
  INSTALMENT_AMOUNT: `${TABLE_DOMAIN}amount`,
  PENDING_AMOUNT: `${TABLE_DOMAIN}pending`,
  SETTLEMENT_DAY: `${TABLE_DOMAIN}day`,
  STATUS: `${TABLE_DOMAIN}status`,
  TOTAL_PAID: `${TABLE_DOMAIN}paid`,
};

export { DESCRIPTIONS, TABLE_TRANSLATIONS };
