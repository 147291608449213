// Resolvers
import { getTrustedDeviceStatusResolver } from '../resolvers/request-signature-key-wizard.resolver';
// Types
import { RequestSignatureKeyWizardHandlersReturnType } from './types/request-signature-key-wizard-return.handlers.type';
import { RequestSignatureKeyWizardHandlersType } from './types/request-signature-key-wizard.handlers.type';

const fetchTrustedDeviceStatusHandler = async ({ setHasTrustedDevice }): Promise<void> => {
  const [, error] = await getTrustedDeviceStatusResolver();

  if (!error) {
    setHasTrustedDevice(true);
  }
};

const RequestSignatureKeyWizardHandlers = (
  props: RequestSignatureKeyWizardHandlersType
): RequestSignatureKeyWizardHandlersReturnType => ({
  handleFetchTrustedDeviceStatusEvent: () => fetchTrustedDeviceStatusHandler(props),
});

export default RequestSignatureKeyWizardHandlers;
