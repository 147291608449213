const TRANSACTION_TABLE_ROW_AMOUNT_HELPER_PROPS = {
  testId: 'transactions-table-row-amount-helper',
};
const TRANSACTION_TABLE_ROW_AMOUNT_HELPER_STYLED_PROPS = {
  testId: 'transactions-table-row-amount-helper-styled',
};

export {
  TRANSACTION_TABLE_ROW_AMOUNT_HELPER_PROPS,
  TRANSACTION_TABLE_ROW_AMOUNT_HELPER_STYLED_PROPS,
};
