// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
// Translations
import {
  CONTRACT_NUMBER,
  HOLDER,
  TITLE,
} from '../translations/card-settings-contract-current-contract.translations';

const CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_NUMBER_PROPS = {
  label: CONTRACT_NUMBER,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_HOLDER_PROPS = {
  label: HOLDER,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_DIVIDER_PROPS = {
  id: CardContractInfoSectionsEnumeration.CONTRACT,
  title: TITLE,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_HOLDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CONTRACT_NUMBER_PROPS,
};
