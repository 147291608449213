export const CONTEXT_DEFAULT_VALUE = {
  categories: [],
  categoryError: undefined,
  categorySelected: '',
  documents: null,
  documentsError: false,
  setCategories: (): null => null,
  setCategoryError: (): null => null,
  setCategorySelected: (): null => null,
  setDocuments: (): null => null,
  setDocumentsError: (): null => null,
};
