// Types
import {
  WizardFeedbackErrorUtilsType,
  WizardFeedbackSuccessUtilsType,
} from './types/wizard-feedback.utils.type';
import { WizardFeedbackResultComponentType } from '../types/wizard-feedback-result.component.type';
import { WizardFeedbackSuccessComponentType } from '../types/wizard-feedback-success.component.type';

const isValidWizardSuccessConfiguration = (
  configuration?: WizardFeedbackSuccessComponentType
): boolean => Boolean(configuration?.title && configuration?.linkLabel);

const isValidWizardErrorConfiguration = (
  configuration?: WizardFeedbackResultComponentType
): boolean => Boolean(configuration?.title);

const isWizardSuccessFeedback = ({
  result,
  successConfiguration,
}: WizardFeedbackSuccessUtilsType): boolean =>
  result === true && isValidWizardSuccessConfiguration(successConfiguration);

const isWizardErrorFeedback = ({
  errorConfiguration,
  result,
}: WizardFeedbackErrorUtilsType): boolean =>
  result === false && isValidWizardErrorConfiguration(errorConfiguration);

export { isWizardSuccessFeedback, isWizardErrorFeedback };
