import {
  STATUS_OPTION_ALL,
  STATUS_OPTION_IN_COMPLETED,
  STATUS_OPTION_IN_PROGRESS,
} from '../translations/financing-filter-modal.translations';

const STATUS_EPP_OPTIONS = [
  {
    id: 'All',
    helperMessage: STATUS_OPTION_ALL,
  },
  {
    id: 'REQUESTED,CREATED,PARTIAL_RETURNED',
    helperMessage: STATUS_OPTION_IN_PROGRESS,
  },
  {
    id: 'RETURNED,CLOSED,CANCELLED,ERROR',
    helperMessage: STATUS_OPTION_IN_COMPLETED,
  },
];

export { STATUS_EPP_OPTIONS };
