// Types
import {
  OrchestratorRouterContainerErrorBuilderReturnHandlersType,
  OrchestratorRouterContainerErrorHandlersType,
} from './types/orchestrator-router-container-error.handlers.type';

const reloadAndClearPageErrorButtonClick = ({
  handleReloadAndClearPageErrorButtonClickTracking,
}: OrchestratorRouterContainerErrorHandlersType): any => {
  handleReloadAndClearPageErrorButtonClickTracking();
  window.location.reload();
};

const OrchestratorRouterContainerErrorHandlers = (
  props: OrchestratorRouterContainerErrorHandlersType
): OrchestratorRouterContainerErrorBuilderReturnHandlersType => ({
  handleReloadAndClearPageErrorButtonClick: () => reloadAndClearPageErrorButtonClick(props),
});

export default OrchestratorRouterContainerErrorHandlers;
