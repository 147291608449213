// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/profile-personal-name.translations';

export default {
  PROFILE_PERSONAL_NAME_TITLE_PROPS: {
    testId: 'profile-personal-name-button-title-section',
    tag: titleConstants.TAGS.H4,
    bold: false,
  },
  PROFILE_PERSONAL_NAME_ASSET_PROPS: {
    id: 'info',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    testId: 'profile-personal-name-button-asset',
  },
  PROFILE_PERSONAL_NAME_MARITAL_PROPS: {
    label: translations.MARITAL_STATUS,
    url: 'edit-marital-status',
  },
};
