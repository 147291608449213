// Translations
import { formatTimestampOrReturnUnknownTag } from 'containers/security-keys/views/landing/handlers/utils/security-keys.handlers.utils';
// Types
import { SecurityKeysBuilderReturnHandlersType } from 'containers/security-keys/views/landing/handlers/types/security-keys-builder-return.handlers.type';
import { SecurityKeysBuilderHandlersType } from 'containers/security-keys/views/landing/handlers/types/security-keys-builder.handlers.type';

const fetchLastUpdateTimeHandler = async ({
  formatDate,
  formatMessage,
  resolver,
  setTimestamp,
}: SecurityKeysBuilderHandlersType): Promise<void> => {
  const [timestamp] = await resolver();

  setTimestamp(formatTimestampOrReturnUnknownTag({ formatDate, formatMessage, timestamp }));
};

const SecurityKeysHandlers = (
  props: SecurityKeysBuilderHandlersType
): SecurityKeysBuilderReturnHandlersType => ({
  handleFetchLastUpdateTime: () => fetchLastUpdateTimeHandler(props),
});

export default SecurityKeysHandlers;
