// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  CARD_SETTINGS_ACTIVATE_CARD_SIDEBAR_PROPS: {
    testId: 'card-settings-activate-card',
  },
  CARD_SETTINGS_ACTIVATE_CARD_SIDEBAR_CONTENT_PROPS: {
    size: paragraphConstants.SIZES.M,
  },
};
