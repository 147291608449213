// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import constants from './constants/card-settings-activate-card-challenge.handlers.constants';
// Types
import { CardSettingsActivateCardChallengeReturnTrackingHandlersType } from './types/card-settings-activate-card-challenge-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const activateCardButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: constants.ACTIVATE_CARD_BUTTON_CLICK_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const cancelLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: constants.CANCEL_LINK_CLICK_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const CardSettingsActivateCardChallengeTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsActivateCardChallengeReturnTrackingHandlersType => ({
  handleActivateCardButtonClickTracking: () => track(activateCardButtonClickTrackingHandler()),
  handleCancelLinkClickTracking: () => track(cancelLinkClickTrackingHandler()),
});

export default CardSettingsActivateCardChallengeTrackingHandlers;
