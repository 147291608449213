// Vendors
import { useState } from 'react';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import { WizardChallengeHandlers } from 'handlers/wizard-challenge/wizard-challenge.handlers';
// Types
import { WizardChallengeHookType } from './types/wizard-challenge.hook.type';
// Utilities
import { challengeFlowButtonIsDisabled } from 'utils/challenges/challenge.utils';

const useWizardChallengeHook = (fetching: boolean): WizardChallengeHookType => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');

  const { setResult } = useWizardFeedbackContextConsumerHook();

  return {
    ...WizardChallengeHandlers({ setChallenged, setProgressId, setResult }),
    disabled: challengeFlowButtonIsDisabled({ challenged, fetching }),
    progressId,
  };
};

export { useWizardChallengeHook };
