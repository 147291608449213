// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHECK_PIN_CONSTRAINTS_TITLE_PROPS = {
  bold: true,
  size: paragraphConstants.SIZES.M,
  testId: 'card-settings-check-pin-constraints-title',
};

export { CARD_SETTINGS_CHECK_PIN_CONSTRAINTS_TITLE_PROPS };
