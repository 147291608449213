// Vendors
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Components
import { AppFooterComponent } from '@openbank/cb-ui-commons';
// Constants
import { ORCHESTRATOR_FOOTER_SOCIAL_LINKS } from './constants/orchestrator-footer.constants';
// Hooks
import { useOrchestratorFooterHook } from './hooks/orchestrator-footer.hook';
// Translations
import { LEGAL_TEXT } from './translations/orchestrator-footer.translations';
// Utilities
import { convertPublicFooterLinks } from './utils/orchestrator-footer.utils';

const OrchestratorFooterComponent = (): React.ReactElement | null => {
  const { formatMessage, isHybrid } = useOrchestratorFooterHook();

  return isHybrid ? null : (
    <AppFooterComponent
      {...ORCHESTRATOR_FOOTER_SOCIAL_LINKS}
      publicLinks={convertPublicFooterLinks(formatMessage)}
      translations={{ LEGAL_TEXT: <FormattedMessageComponent id={LEGAL_TEXT} /> }}
    />
  );
};

export default OrchestratorFooterComponent;
