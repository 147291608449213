// Types
import { SupportCancelContractHandlersReturnType } from './types/support-cancel-contract-return.handler.type';
import { SupportCancelContractHandlersType } from './types/support-cancel-contract.handler.type';

const exitCancelContractHandler = ({ navigate }) => navigate(-1);

const linkClickEventHandler = ({ navigate, handleSetupCardInformationEvent }): void => {
  handleSetupCardInformationEvent();
  navigate(-1);
};

const checkCardStatusHandler = async ({
  setFetching,
  handleSetupCardInformationEvent,
}): Promise<void> => {
  setFetching(true);
  await handleSetupCardInformationEvent();
  setFetching(false);
};

const SupportCancelContractHandlers = ({
  handleSetupCardInformationEvent,
  navigate,
  setFetching,
}: SupportCancelContractHandlersType): SupportCancelContractHandlersReturnType => ({
  handleExitCancelContractEvent: () => exitCancelContractHandler({ navigate }),
  handleLinkClickEvent: () => linkClickEventHandler({ navigate, handleSetupCardInformationEvent }),
  handleCheckCardStatusEvent: () =>
    checkCardStatusHandler({ setFetching, handleSetupCardInformationEvent }),
});

export { SupportCancelContractHandlers };
