// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_RESUME_LINK_ASSET_PROPS } from './constants/card-settings-resume-link-asset.constants';
// Types
import { CardSettingsResumeLinkComponentType } from '../../types/card-settings-resume-link.component.type';

const CardSettingsResumeLinkAssetComponent = ({
  tooltipMessage,
}: Pick<CardSettingsResumeLinkComponentType, 'tooltipMessage'>): React.ReactElement | null =>
  tooltipMessage ? (
    <AssetComponent
      {...CARD_SETTINGS_RESUME_LINK_ASSET_PROPS}
      tooltipConfiguration={{ content: tooltipMessage }}
    />
  ) : null;

export { CardSettingsResumeLinkAssetComponent };
