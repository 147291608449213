// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { NUMBER } from 'constants/number.constants';
import {
  INVALID_DATE,
  LABEL_DATE,
  LABEL_DESCRIPTION,
  LABEL_PROBLEM,
  PLACEHOLDER_DESCRIPTION,
  PLACEHOLDER_PROBLEM,
} from '../translations/report-defective-product-info.component.constants';

const PRODUCT_INFO_WRAPPER_TEST_ID = { testId: 'report-defective-product-info-wrapper' };

const EMPTY_STRING = '';

const INPUT_DATE_PICKER_CONFIGURATION = {
  testId: 'input-date-picker',
  placeholder: 'DD. MM. YYYY',
  isRequired: true,
  label: <FormattedMessageComponent id={LABEL_DATE} />,
};

const INPUT_TEXT_AREA_DESCRIPTION_CONFIGURATION = {
  id: 'defectProductDescriptionProduct',
  testId: 'input-text-area-description',
  placeholder: PLACEHOLDER_DESCRIPTION,
  limitCharsConfiguration: { limit: 600 },
  isRequired: true,
  label: <FormattedMessageComponent id={LABEL_DESCRIPTION} />,
};

const INPUT_TEXT_AREA_PROBLEM_CONFIGURATION = {
  id: 'defectProductDescriptionProblem',
  testId: 'input-text-area-problem',
  placeholder: PLACEHOLDER_PROBLEM,
  limitCharsConfiguration: { limit: NUMBER.SIX_HUNDRED },
  isRequired: true,
  label: <FormattedMessageComponent id={LABEL_PROBLEM} />,
};

export {
  EMPTY_STRING,
  INPUT_TEXT_AREA_DESCRIPTION_CONFIGURATION,
  INPUT_TEXT_AREA_PROBLEM_CONFIGURATION,
  INPUT_DATE_PICKER_CONFIGURATION,
  PRODUCT_INFO_WRAPPER_TEST_ID,
};
