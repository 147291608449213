// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Constants
import { STEPS } from './constants/transactions-report-not-received.constants';
// Contexts
import { TransactionsReportNotReceivedContextConsumerHOC } from './contexts/transactions-report-not-received.context';

const TransactionsReportNotReceivedBaseComponent = (): React.ReactElement => (
  <TransactionsReportWizardComponent steps={STEPS} />
);

const TransactionsReportNotReceivedComponent = TransactionsReportNotReceivedContextConsumerHOC(
  TransactionsReportNotReceivedBaseComponent
);

export { TransactionsReportNotReceivedComponent };
