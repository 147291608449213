// Vendors
import { useEffect, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
// Handlers
import AddressModalInputsCityHandlers from '../handlers/address-modal-inputs-city.handlers';
// Translations
import { NO_RESULTS } from 'components/address-modal/components/inputs/translations/address-modal-inputs.translations';
// Types
import { AddressModalInputsCityReturnHookType } from './types/address-modal-inputs-city-return.hook.type';
import { InputSearchOptionType } from 'components/address-modal/components/inputs/types/input-search-option.type';
import { AddressFormValuesType } from 'components/address-modal/types/address-form-values.type';
import { NoResultsTranslationsType } from 'components/address-modal/types/no-results-translations.type';

const AddressModalInputsCityHook = (
  control: Control<AddressFormValuesType, any>
): AddressModalInputsCityReturnHookType => {
  const [error, setError] = useState<NoResultsTranslationsType>(NO_RESULTS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<InputSearchOptionType[]>([]);

  const { city = '' } = useWatch({ control });

  const { handleCityChange } = AddressModalInputsCityHandlers({
    city,
    setError,
    setIsLoading,
    setOptions,
  });

  useEffect(() => {
    handleCityChange();
  }, [city]);

  return {
    error,
    isLoading,
    options,
    onClickNoResultsLink: handleCityChange,
  };
};

export default AddressModalInputsCityHook;
