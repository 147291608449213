// Vendors
import styled from 'styled-components';

export const FinancingEarlyRepaymentFirstStepInstalmentStatusContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: 0 ${({ theme }) => theme.spaces.spacingS};
`;

export const FinancingEarlyRepaymentFirstStepInstalmentStatusContentFieldsStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
