// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const EditableInfoPanelWrapperStyled = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  padding: ${({ theme }) => theme.spaces.spacingM};
  gap: ${({ theme }) => theme.spaces.spacingM};
  border: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryC};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const InfoPanelRowStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;

export const BottomLinkWrapperStyled = styled.div`
  display: flex;
  justify-content: end;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
