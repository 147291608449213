// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { DEPOSIT_MONEY_CONFIGURATION } from './constants/financing--deposit-money.service.constants';

export const fetchDepositMoneyService = async (cardId: string): Promise<any> =>
  apiUtilsCb.get({
    ...DEPOSIT_MONEY_CONFIGURATION,
    pathParams: { cardId },
  });
