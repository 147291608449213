// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/card-settings-activate-card.services.constants';
// Types
import { CardSettingsActivateCardApiBuilderComponentType } from '../types/card-settings-activate-card-api-builder.component.type';
import { ChallengeType } from 'types/challenge.type';

const changeCurrentCardStatusService = async ({
  cardId,
  progressId,
}: CardSettingsActivateCardApiBuilderComponentType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    ...constants,
    customHeaders: progressId ? { progressId } : {},
    pathParams: { cardId },
  });

export { changeCurrentCardStatusService };
