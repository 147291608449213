// Constants
import { CATEGORIES_DOMAIN } from '../constants/documentation-category-list.constants';
// Types
import { type DocumentationCategoryItemType } from '../types/documentation-category-item.type';
import { type DocumentationCategoryListUtilsPropsType } from './types/documentation-category-list.component.utils.type';

const generateAccordionItems = ({
  categories,
  formatMessage,
}: DocumentationCategoryListUtilsPropsType): DocumentationCategoryItemType[] => {
  return categories.map((category, i) => ({
    id: i.toString(),
    key: category,
    label: formatMessage({ id: `${CATEGORIES_DOMAIN}${category}` }),
  }));
};
export { generateAccordionItems };
