// Types
import { ProfilePersonalNameAliasEditBuilderReturnHandlersType } from './types/profile-personal-name-alias-edit-builder-return.handlers.type';
import { ProfilePersonalNameAliasEditBuilderHandlersType } from './types/profile-personal-name-alias-edit-builder.handlers.type';

const changeCurrentAliasAssetClickHandler = ({
  handleChangeCurrentAliasAssetClickTracking,
  setEditMode,
}: ProfilePersonalNameAliasEditBuilderHandlersType): any => {
  handleChangeCurrentAliasAssetClickTracking();
  setEditMode(true);
};

const ProfilePersonalNameAliasEditHandlers = (
  props: ProfilePersonalNameAliasEditBuilderHandlersType
): ProfilePersonalNameAliasEditBuilderReturnHandlersType => ({
  handleChangeCurrentAliasAssetClick: () => changeCurrentAliasAssetClickHandler(props),
});

export default ProfilePersonalNameAliasEditHandlers;
