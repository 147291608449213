export enum CustomerTitlesEnumeration {
  PROFESSOR_DOCTOR = 'DP',
  PROFESSOR_DOCTOR_MISS = 'DPF',
  PROFESSOR_DOCTOR_MISTER = 'DPH',
  PROFESSOR_DOCTOR_MX = 'DPM',
  DOCTOR = 'DR',
  DOCTOR_MISS = 'DRF',
  DOCTOR_MISTER = 'DRH',
  DOCTOR_MX = 'DRM',
  PROFESSOR = 'PR',
  PROFESSOR_MISS = 'PRF',
  PROFESSOR_MISTER = 'PRH',
  PROFESSOR_MX = 'PRM',
  MISTER = 'MR',
  MISS = 'MS',
  MISS_MISTER = 'SE',
  NO_TITLE = 'XX',
}
