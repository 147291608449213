// Services
import { FormValuesParamsType } from 'components/filters/context/types/filters-context.type';
import { fetchCardsEppTransactionsService } from '../services/financing-landing.service';
// Types
import { EppCardType } from 'types/epp-card.type';
// Utils
import { isValidTransactionsApiContract } from 'utils/transactions-api-contract.utils';

const fetchCardsEppTransactionsResolver = async (
  cardId: string,
  formValuesParams: FormValuesParamsType
): Promise<[EppCardType[]] | [null, number]> => {
  const [response, { status }] = await fetchCardsEppTransactionsService(cardId, formValuesParams);

  return isValidTransactionsApiContract(response) ? [response as EppCardType[]] : [null, status];
};

export { fetchCardsEppTransactionsResolver };
