// Types
import {
  ErrorWrapperBuilderReturnHandlersType,
  ErrorWrapperHandlersType,
} from './types/error-wrapper.handlers.type';

const reloadAndClearPageErrorButtonClick = ({
  handleTryAgainButtonClickTracking,
}: ErrorWrapperHandlersType) => {
  handleTryAgainButtonClickTracking();
  window.location.reload();
};

const ErrorWrapperHandlers = (
  props: ErrorWrapperHandlersType
): ErrorWrapperBuilderReturnHandlersType => ({
  handleTryAgainButtonClick: () => reloadAndClearPageErrorButtonClick(props),
});

export default ErrorWrapperHandlers;
