const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.change3DSecure.cbChange3DSecure.cbWizard.step1.';

const CONSTRAINT_01 = `${DOMAIN}constraint01`,
  CONSTRAINT_02 = `${DOMAIN}constraint02`,
  CONSTRAINT_03 = `${DOMAIN}constraint03`,
  CONSTRAINT_04 = `${DOMAIN}constraint04`,
  CONSTRAINT_05 = `${DOMAIN}constraint05`;

export { CONSTRAINT_01, CONSTRAINT_02, CONSTRAINT_03, CONSTRAINT_04, CONSTRAINT_05 };
