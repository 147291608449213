// Vendors
import React, { ReactNode } from 'react';
import { Routes, Route } from 'react-router-dom';
// Components
import { FinancingRouterInsurancesComponent } from './components/insurances/financing-router-insurances.component';
// Constants
import {
  FINANCING_INSURANCES_PATH,
  FINANCING_ROUTER_ROUTES,
} from './constants/financing-router.constants';
// Views
import { FinancingPPIView } from 'containers/financing/views/ppi/financing-ppi.view';

const FinancingRouterComponent = (): React.ReactElement => (
  <Routes>
    {FINANCING_ROUTER_ROUTES.map(
      ({ element: RouteComponent, elementProps, props }, index: number): ReactNode => (
        <Route {...props} element={<RouteComponent {...elementProps} />} key={index} />
      )
    )}
    <Route element={<FinancingRouterInsurancesComponent />}>
      <Route element={<FinancingPPIView />} path={FINANCING_INSURANCES_PATH} />
    </Route>
  </Routes>
);

export { FinancingRouterComponent };
