// Constants
import { DISPUTE_PARAMS } from 'containers/transactions/views/report/components/duplicate-charge/components/confirmation/constants/transactions-report-duplicate-charge-confirmation.constants';
// Resolvers
import { fetchReportProblemResolver } from 'containers/transactions/views/report/resolvers/transactions-report.resolvers';
// Types
import { TransactionsReportDuplicateChargeConfirmationActionsHandlersReturnType } from './types/transactions-report-duplicate-charge-confirmation-actions-return.handlers.type';
import { TransactionsReportDuplicateChargeConfirmationActionsHandlersType } from './types/transactions-report-duplicate-charge-confirmation-actions.handlers.type';
// Utils
import { isWrongOTPError } from 'utils/challenges/challenge.utils';

const confirmButtonClickHandler = async ({
  cardId,
  challenge,
  setCaseId,
  setChallengeError,
  setError,
  setResult,
  transactionId,
}: TransactionsReportDuplicateChargeConfirmationActionsHandlersType): Promise<void> => {
  try {
    if (!cardId || !transactionId) throw new Error();

    const [response, error] = await fetchReportProblemResolver({
      cardId,
      transactionId,
      data: {
        ...DISPUTE_PARAMS,
      },
      progressId: challenge?.progressId,
    });

    if (error) {
      if (isWrongOTPError(error)) {
        setChallengeError(true);
      } else {
        setResult(false);
      }
    } else {
      setResult(true);
      setCaseId(response?.caseId);
    }
  } catch {
    setError(true);
  }
};

export const TransactionsReportDuplicateChargeConfirmationActionsHandlers = (
  props: TransactionsReportDuplicateChargeConfirmationActionsHandlersType
): TransactionsReportDuplicateChargeConfirmationActionsHandlersReturnType => ({
  handleConfirmButtonClick: () => confirmButtonClickHandler(props),
});
