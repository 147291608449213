// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { useTransactionsReportContextConsumerHook } from 'containers/transactions/views/report/contexts/transactions-report.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Handlers
import { TransactionsReportDifferentAmountAtmConfirmationActionsHandlers } from '../handlers/transactions-report-different-amount-atm-confirmation-actions.handlers';
// Hooks
import { useTransactionsReportDifferentAmountAtmContextConsumerHook } from 'containers/transactions/views/report/components/different-amount-atm/context/transactions-report-different-amount-atm.context';
import { useCleanChallengeHook } from 'containers/transactions/views/report/hooks/clean-challenge.hook';
// Types
import { TransactionsReportDifferentAmountAtmConfirmationHookReturnType } from './type/transactions-report-different-amount-atm-confirmation-actions-return.type';

export const useTransactionsReportDifferentAmountAtmConfirmationActionsHook =
  (): TransactionsReportDifferentAmountAtmConfirmationHookReturnType => {
    const { formValues, setFormValues } =
      useTransactionsReportDifferentAmountAtmContextConsumerHook();
    const { challenge, challengeError, setChallengeError, setCaseId, setError } =
      useTransactionsReportContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    useCleanChallengeHook();

    return {
      ...TransactionsReportDifferentAmountAtmConfirmationActionsHandlers({
        cardId,
        challenge,
        formValues,
        setCaseId,
        setChallengeError,
        setError,
        setFormValues,
        setResult,
        transactionId,
      }),
      challenge,
      challengeError,
    };
  };
