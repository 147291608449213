// Vendors
import React from 'react';
// Components
import { PersonalAddressStep2ActionsComponent } from './components/actions/personal-address-step2-actions.component';
import { PersonalAddressStep2ChallengeComponent } from './components/challenge/personal-address-step2-challenge.component';
import { PersonalAddressStep2SummaryComponent } from './components/summary/personal-address-step2-summary.component';
// Contexts
import { usePersonalAddressContextConsumerHook } from '../../contexts/personal-address.context';
// Styles
import { PersonalAddressStep2Styled } from './personal-address-step2.component.styled';

export const PersonalAddressStep2Component = (): React.ReactElement | null => {
  const { newAddress } = usePersonalAddressContextConsumerHook();

  if (!newAddress) {
    return null;
  }

  return (
    <PersonalAddressStep2Styled>
      <PersonalAddressStep2SummaryComponent {...{ newAddress }} />
      <PersonalAddressStep2ChallengeComponent {...{ newAddress }} />
      <PersonalAddressStep2ActionsComponent {...{ newAddress }} />
    </PersonalAddressStep2Styled>
  );
};
