// Constants
import {
  BENEFITS_LANDING_BUTTON_CANCELLED_PROPS,
  BENEFITS_LANDING_BUTTON_CONTRACTED_PROPS,
  BENEFITS_LANDING_BUTTON_COVERED_PROPS,
} from '../../constants/card-settings-benefits.constants';
// Types
import { CardSettingsBenefitsTravelPlusButtonConfigurationReturnType } from './types/card-settings-benefits-travel-plus-button-return.configuration.type';
import { CardSettingsBenefitsTravelPlusButtonConfigurationPropsType } from './types/card-settings-benefits-travel-plus-button.configuration.type';

const getBenefitsLandingButtonContractConfiguration = ({
  formatMessage,
  onClick,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusButtonConfigurationPropsType): CardSettingsBenefitsTravelPlusButtonConfigurationReturnType =>
  travelPlusInfo?.status && {
    ...BENEFITS_LANDING_BUTTON_CONTRACTED_PROPS,
    children: formatMessage({ id: BENEFITS_LANDING_BUTTON_CONTRACTED_PROPS.description }),
    onClick,
  };

const getBenefitsLandingButtonCancelConfiguration = ({
  formatMessage,
  onClick,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusButtonConfigurationPropsType): CardSettingsBenefitsTravelPlusButtonConfigurationReturnType =>
  !travelPlusInfo?.status && {
    ...BENEFITS_LANDING_BUTTON_CANCELLED_PROPS,
    children: formatMessage({ id: BENEFITS_LANDING_BUTTON_CANCELLED_PROPS.description }),
    onClick,
  };

const getBenefitsLandingButtonCoveredConfiguration = ({
  formatMessage,
  travelPlusInfo,
  onClick,
}: CardSettingsBenefitsTravelPlusButtonConfigurationPropsType): CardSettingsBenefitsTravelPlusButtonConfigurationReturnType =>
  !travelPlusInfo?.status &&
  travelPlusInfo?.expirationDate && {
    children: formatMessage({ id: BENEFITS_LANDING_BUTTON_COVERED_PROPS.description }),
    ...BENEFITS_LANDING_BUTTON_COVERED_PROPS,
    onClick,
  };

const getBenefitsLandingTravelPlusButtonConfiguration = (
  props: CardSettingsBenefitsTravelPlusButtonConfigurationPropsType
): CardSettingsBenefitsTravelPlusButtonConfigurationReturnType =>
  getBenefitsLandingButtonCoveredConfiguration(props) ||
  getBenefitsLandingButtonContractConfiguration(props) ||
  getBenefitsLandingButtonCancelConfiguration(props) ||
  {};

export { getBenefitsLandingTravelPlusButtonConfiguration };
