// Vendors
import { useRef } from 'react';
// Constants
import {
  WEBSOCKET_SEND_MESSAGE_CONSTANTS,
  WEBSOCKET_NOT_AVAILABLE,
  SERVICE_CARDS_CREDIT,
} from './constants/websocket-hook.constants';
// Types
import {
  WebSocketHandlers,
  WebSocketSendMessagePropsType,
  WebsocketHookReturnType,
} from './types/websocket-hook.types';
// Utils
import { getCurrentAccessToken, getWebsocketUrl } from './utils/websocket-hook.utils';
import { refreshToken } from 'utils/refresh-token.util';
// Websocket
import { WebSockets } from 'classes/websocket';

const WebsocketHook = (): WebsocketHookReturnType<string> => {
  const websocketRef = useRef<WebSockets | null>(null);
  let hasError = false;

  const webSocketConnect = <T extends string>(handlers: WebSocketHandlers<T>): Promise<void> => {
    if (!websocketRef.current) {
      const retryConnection = async () => {
        if (!hasError) {
          hasError = true;
          await refreshToken();
          websocketRef.current = new WebSockets(getWebsocketUrl());
          websocketRef.current.listen(webSocketEventHandlers);
          websocketRef.current.connect();
        }
      };

      const webSocketEventHandlers = {
        onConnect: async (): Promise<void> => {
          window.console.log('🚀 ~ websocket connected');
          await refreshToken();
          handlers.handleOnConnect?.();
        },
        onDisconnect: (): void => {
          window.console.log('🚀 ~ websocket disconnected');
          handlers.handleOnDisconnect?.();
        },
        onError: async (error: Error): Promise<void> => {
          await retryConnection();
          if (!handlers.handleOnError) {
            window.console.log('🚀 ~ websocket not handleOnError');
            return;
          }
          handlers.handleOnError?.(error);
        },
        onReceiveData: (message: T) => {
          handlers.handleOnReceiveMessage(message);
        },
      };

      websocketRef.current = new WebSockets(getWebsocketUrl());
      websocketRef.current.listen(webSocketEventHandlers);
      return websocketRef.current.connect();
    }
    return Promise.resolve();
  };

  const webSocketDisconnect = (): Promise<void> => {
    if (websocketRef.current) {
      const connectedWebsocket = websocketRef.current;
      websocketRef.current = null;
      return connectedWebsocket.disconnect();
    }
    return Promise.reject(new Error(WEBSOCKET_NOT_AVAILABLE));
  };

  const webSocketSendMessage = ({
    endpoint,
    payload,
    service = SERVICE_CARDS_CREDIT,
  }: WebSocketSendMessagePropsType): void => {
    if (websocketRef.current) {
      const parsedMessage = JSON.stringify({
        ...WEBSOCKET_SEND_MESSAGE_CONSTANTS,
        service,
        endpoint,
        detail: {
          headers: {
            accessToken: getCurrentAccessToken(),
          },
          payload,
        },
      });
      websocketRef.current.send(parsedMessage);
    } else {
      throw new Error(WEBSOCKET_NOT_AVAILABLE);
    }
  };

  return {
    webSocketConnect,
    webSocketDisconnect,
    webSocketSendMessage,
  };
};

export { WebsocketHook };
