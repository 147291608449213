// Enums
import { MaritalStatusEnumeration } from '../enumerations/marital-status.enumeration';
// Types
import { OnChangeRadioButtonHandlerType } from './types/on-change-radio-button.handler.type';
import { PersonalSituationCreditLimitIncreaseHandlersPropsType } from './types/personal-situation-credit-limit-increase-props.component.handler.type';
import { PersonalSituationCreditLimitIncreaseHandlersReturnType } from './types/personal-situation-credit-limit-increase-return.component.handler.type';
import { UpdateStep2HandlerPropsType } from './types/update-step-2-props.component.handler.type';

const updateStep2Handler = ({ step2, setStep2 }: UpdateStep2HandlerPropsType): void => {
  const { maritalStatus, employments } = step2.personalData;
  const { childrenInHousehold, childrenWithMaintenance } = employments?.[0] || {};
  const isPersonalSituationReady =
    Boolean(maritalStatus) && Boolean(childrenInHousehold) && Boolean(childrenWithMaintenance);

  if (step2.isPersonalSituationReady !== isPersonalSituationReady) {
    setStep2?.({ ...step2, isPersonalSituationReady });
  }
};

const onChangeRadioButtonHandler = ({
  e,
  newMaritalStatus,
  step2,
  setStep2,
  handleSelectMaritalStatusTracking,
}: OnChangeRadioButtonHandlerType): void => {
  if (e.target.checked) {
    setStep2({
      ...step2,
      personalData: { ...step2.personalData, maritalStatus: newMaritalStatus },
    });
    handleSelectMaritalStatusTracking();
  }
};

const PersonalSituationCreditLimitIncreaseHandlers = ({
  step2,
  setStep2,
  handleSelectMaritalStatusTracking,
}: PersonalSituationCreditLimitIncreaseHandlersPropsType): PersonalSituationCreditLimitIncreaseHandlersReturnType => ({
  handleUpdateStep2: () => updateStep2Handler({ step2, setStep2 }),
  handleOnChangeRadioButton: (
    e: React.ChangeEvent<HTMLInputElement>,
    newMaritalStatus: MaritalStatusEnumeration
  ) =>
    onChangeRadioButtonHandler({
      e,
      newMaritalStatus,
      step2,
      setStep2,
      handleSelectMaritalStatusTracking,
    }),
});

export default PersonalSituationCreditLimitIncreaseHandlers;
