// Vendors
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Services
import { getBenefitsService } from '../services/card-settings-benefits.services';
// Types
import { CardSettingsBenefitsResolversPropsType } from './types/card-settings-benefits.resolvers.type';

const getBenefitsResolver = async ({
  cardContractId,
}: CardSettingsBenefitsResolversPropsType): Promise<any | [null, number]> => {
  const [response, { status }] = await getBenefitsService({
    cardContractId,
  });

  return wasSuccessRequest(status) ? [response] : [response, status];
};

export { getBenefitsResolver };
