// Services
import {
  regenerateNewNumberOtpService,
  validateNewNumberOtpService,
} from '../services/challenge-download-wrapper.service';
// Types
import { ChallengeDownloadWrapperChallengeDataBuilderType } from '../types/challenge-download-wrapper-validate-api-builder.type';
// Utils
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
import { isChallengeBasicRequestPayload } from './utils/challenge-download-wrapper.resolver.utils';

const validateNewNumberOtpResolver = async ({
  progressId,
  otpId,
  otpValue,
}: ChallengeDownloadWrapperChallengeDataBuilderType): Promise<[string | any] | [null, true]> => {
  const [response] = await validateNewNumberOtpService({ progressId, otpId, otpValue });

  return isChallengeBasicRequestPayload(response) ? [response] : [null, true];
};

const regenerateNewNumberOtpResolver = async ({
  progressId,
  otpId,
}: ChallengeDownloadWrapperChallengeDataBuilderType): Promise<[string | any] | [null, true]> => {
  const [response, { status }] = await regenerateNewNumberOtpService({ progressId, otpId });

  return requestHasSuccessStatus(status) ? [response] : [null, true];
};

export { validateNewNumberOtpResolver, regenerateNewNumberOtpResolver };
