// Vendors
import React, { useState, useContext, createContext } from 'react';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { RequestSignatureKeyApiReturnComponentType } from '../types/request-signature-key-api-return.component.type';
import { RequestSignatureKeyContextType } from './types/request-signature-key.context.type';

const RequestSignatureKeyContext = createContext<RequestSignatureKeyContextType>(
  {} as RequestSignatureKeyContextType
);

export const RequestSignatureKeyContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<RequestSignatureKeyContextType>> => {
  const [challenged, setChallenged] = useState<boolean>(false);
  const [errorResponse, setErrorResponse] = useState<RequestSignatureKeyApiReturnComponentType>({});
  const [fetching, setFetching] = useState<boolean>(false);
  const [hasTrustedDevice, setHasTrustedDevice] = useState<boolean>(false);
  const [isAlreadyRequested, setIsAlreadyRequested] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');

  return (
    <RequestSignatureKeyContext.Provider
      value={{
        challenged,
        errorResponse,
        fetching,
        hasTrustedDevice,
        isAlreadyRequested,
        progressId,
        setChallenged,
        setErrorResponse,
        setFetching,
        setHasTrustedDevice,
        setIsAlreadyRequested,
        setProgressId,
      }}
    >
      {children as React.ReactNode}
    </RequestSignatureKeyContext.Provider>
  );
};

export const useRequestSignatureKeyContextConsumerHook = (): RequestSignatureKeyContextType =>
  useContext(RequestSignatureKeyContext);

/* eslint-disable */
export const RequestSignatureKeyContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<RequestSignatureKeyContextType>> =>
    (
      <RequestSignatureKeyContextProvider>
        <Component {...props} />
      </RequestSignatureKeyContextProvider>
    );
