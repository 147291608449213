const DOMAIN_EARLY_REPAYMENT =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.';
const DOMAIN_EARLY_REPAYMENT_SUCCESS_BUTTONS =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.success.buttons.';
const DOMAIN_EARLY_REPAYMENT_SUCCESS_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.success.confirmation.';
const DOMAIN_EARLY_REPAYMENT_FAILED_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.failed.confirmation.';
const DOMAIN_EARLY_REPAYMENT_STEPS_FIRST =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.steps.first.';
const DOMAIN_EARLY_REPAYMENT_STEPS_SECOND =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.steps.second.';

const TITLE = `${DOMAIN_EARLY_REPAYMENT}title`;

const SUCCESS = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_EARLY_REPAYMENT_SUCCESS_CONFIRMATION}title`,
    CONTENT: `${DOMAIN_EARLY_REPAYMENT_SUCCESS_CONFIRMATION}content`,
  },
  BACK_BUTTTON: `${DOMAIN_EARLY_REPAYMENT_SUCCESS_BUTTONS}back`,
};

const FAILED = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_EARLY_REPAYMENT_FAILED_CONFIRMATION}title`,
    CONTENT: `${DOMAIN_EARLY_REPAYMENT_FAILED_CONFIRMATION}content`,
  },
};

const STEPS = {
  FIRST: {
    TITLE: `${DOMAIN_EARLY_REPAYMENT_STEPS_FIRST}title`,
  },
  SECOND: {
    TITLE: `${DOMAIN_EARLY_REPAYMENT_STEPS_SECOND}title`,
    NEXT: `${DOMAIN_EARLY_REPAYMENT_STEPS_SECOND}next`,
  },
};

export { SUCCESS, FAILED, STEPS, TITLE };
