// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import { useCustomerHook } from './hooks/customer.hook';
// Styles
import { LayoutLoaderStyled } from '@/styles/app.styled';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const CustomerProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { fetching } = useCustomerHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent {...{ fetching }}>{children}</FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export { CustomerProvider };
