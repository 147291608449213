// Services
import { getLastTimeSignatureKeyChangeService } from '../services/security-keys-signature-key.services';
// Utilities
import { isValidSignatureKeyModification } from './utils/security-keys-signature-key.resolvers.utils';

const getLastTimeSignatureKeyChangeResolver = async (): Promise<[string] | [null, true]> => {
  const [response] = await getLastTimeSignatureKeyChangeService();

  return isValidSignatureKeyModification(response)
    ? [response.challenges.SIGNATURE_KEY.updatedAt]
    : [null, true];
};

export { getLastTimeSignatureKeyChangeResolver };