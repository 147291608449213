// Enumerations
import { ExpiredSessionReasonsEnumeration } from 'enumerations/session/expired-session-reasons.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbSessionExpired.sessionExpired.cbModal.';

const BUTTON = `${DOMAIN}loginAgainButton`,
  DESCRIPTIONS = {
    [ExpiredSessionReasonsEnumeration.ACCESS_TOKEN]: `${DOMAIN}accessTokenDescription`,
    [ExpiredSessionReasonsEnumeration.REFRESH_TOKEN]: `${DOMAIN}refreshTokenDescription`,
    [ExpiredSessionReasonsEnumeration.FAIL_REFRESH]: `${DOMAIN}failRefreshDescription`,
  },
  TITLE = `${DOMAIN}title`;

export { BUTTON, DESCRIPTIONS, TITLE };
