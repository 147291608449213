// Vendors
import { useEffect } from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Enums
import { ChildrenOptionsEnumeration } from '../enumerations/children-options.enumeration';
import { MaritalStatusEnumeration } from '../enumerations/marital-status.enumeration';
// Handlers
import PersonalSituationCreditLimitIncreaseHandlers from '../handlers/personal-situation-credit-limit-increase.component.handler';
// Hooks
import { CreditLimitIncreaseContextConsumerHook } from 'containers/financing/views/credit-limit-increase/contexts/credit-limit-increase.context';
// Trackings
import Step2CreditLimitChageTracking from 'containers/financing/views/credit-limit-increase/components/step2/trackings/step-2-credit-limit-increase.tracking';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalSituationCreditLimitIncreaseHookType } from './types/personal-situation-credit-limit-increase.component.hook.type';
// Utils
import { setEmploymentData } from 'containers/financing/views/credit-limit-increase/components/step2/utils/step-2-credit-limit-increase.utils';

const PersonalSituationCreditLimitIncreaseHook =
  (): PersonalSituationCreditLimitIncreaseHookType => {
    const { step2, setStep2 } = CreditLimitIncreaseContextConsumerHook();
    const { maritalStatus, employments } = step2.personalData;
    const { childrenInHousehold, childrenWithMaintenance } = employments?.[0] || {};
    const {
      handleSelectMaritalStatusTracking,
      handleClickChildrenInHouseholdTracking,
      handleSelectChildrenInHouseholdTracking,
      handleClickChildrenWithMaintenanceTracking,
      handleSelectChildrenWithMaintenanceTracking,
      handleOpenChildrenWithMaintenanceTooltipTracking,
    } = AppTrackingHook(Step2CreditLimitChageTracking);
    const { handleUpdateStep2, handleOnChangeRadioButton } =
      PersonalSituationCreditLimitIncreaseHandlers({
        step2,
        setStep2,
        handleSelectMaritalStatusTracking,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(handleUpdateStep2, [maritalStatus, childrenInHousehold, childrenWithMaintenance]);

    return {
      isEditModeOn: step2.isPersonalSituationEditModeOn ?? true,
      maritalStatus,
      childrenInHousehold,
      childrenWithMaintenance,
      isIosDevice: isIosWebView(),
      onOpenChildrenWhomYouPayTooltip: handleOpenChildrenWithMaintenanceTooltipTracking,
      onClickChildrenInHousehold: handleClickChildrenInHouseholdTracking,
      onSelectChildrenInHousehold: (value: ChildrenOptionsEnumeration) => {
        setEmploymentData({ setStep2, step2, values: { childrenInHousehold: value } });
        handleSelectChildrenInHouseholdTracking();
      },
      onClickChildrenWhomYouPay: handleClickChildrenWithMaintenanceTracking,
      onSelectChildrenWhomYouPay: (value: ChildrenOptionsEnumeration) => {
        setEmploymentData({ setStep2, step2, values: { childrenWithMaintenance: value } });
        handleSelectChildrenWithMaintenanceTracking();
      },
      onChangeRadioButton: (
        e: React.ChangeEvent<HTMLInputElement>,
        newMaritalStatus: MaritalStatusEnumeration
      ) => handleOnChangeRadioButton(e, newMaritalStatus),
      setIsEditModeOn: () => setStep2({ ...step2, isPersonalSituationEditModeOn: true }),
    };
  };

export default PersonalSituationCreditLimitIncreaseHook;
