// Types
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
import { ActionsAndQuickactions } from 'containers/card-settings/views/landing/types/card-ui.actions.type';

const isLinkDisabled = (actions: ActionsAndQuickactions[], ACTION_ID: string): boolean =>
  actions?.find(action => action.id === ACTION_ID)?.disabled ?? false;

const cardIsActive = (status: string): boolean => status === CardStatusTypesEnumeration.ACTIVE;

const cardIsInactive = (status: string): boolean =>
  status === CardStatusTypesEnumeration.INACTIVE ||
  status === CardStatusTypesEnumeration.TEMPORARILY_BLOCKED;

export { cardIsActive, cardIsInactive, isLinkDisabled };
