// Services
import { FINANCING_PPI_CANCELLATION_SERVICE_CONFIGURATION } from '../services/constants/financing-ppi-cancellation-landing-footer.service.constants';

const FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_CHALLENGE_PROPS = {
    baseService: FINANCING_PPI_CANCELLATION_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'post',
  },
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_FOOTER_PROPS = {
    hasDefaultRightButton: false,
  },
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'financing-ppi-cancellation-landing-footer-actions-button',
  };

export {
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_FOOTER_PROPS,
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_BUTTON_PROPS,
  FINANCING_PPI_CANCELLATION_LANDING_FOOTER_ACTIONS_CHALLENGE_PROPS,
};
