// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import DashboardTopMessageButtonHandlers from '../handlers/dashboard-top-message-button.handlers';
import DashboardTopMessageButtonTrackingHandlers from '../handlers/dashboard-top-message-button.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { DashboardTopMessageButtonBuilderReturnHandlersType } from '../handlers/types/dashboard-top-message-button-builder-return.handlers.type';

const DashboardTopMessageButtonHook = (): DashboardTopMessageButtonBuilderReturnHandlersType =>
  DashboardTopMessageButtonHandlers({
    ...AppTrackingHook(DashboardTopMessageButtonTrackingHandlers),
    navigate: useNavigate(),
  });

export { DashboardTopMessageButtonHook };
