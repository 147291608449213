// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  INSURANCE_PRE_CONTRACT_ENDPOINT,
  INSURANCE_CONTRACTS_ENDPOINT,
  GET_INSURANCE_CONTRACT_ENDPOINT,
} from '../services/constants/financing-insurances.services.constants';
// Types
import { InsurancePrecontractParamsType } from '../resolvers/types/insurance-precontract-params.type';

const createInsurancePrecontractService = async (
  params: InsurancePrecontractParamsType
): Promise<[Record<string, never> | Blob, Response]> =>
  apiUtilsCb.post({
    endpoint: INSURANCE_PRE_CONTRACT_ENDPOINT,
    params,
  });

const createInsuranceContractService = async ({
  progressId,
  ...params
}: {
  progressId: string;
  params: InsurancePrecontractParamsType;
}): Promise<[Record<string, never> | any, Response]> =>
  apiUtilsCb.post({
    endpoint: INSURANCE_CONTRACTS_ENDPOINT,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    params,
  });

const getInsuranceContractService = async (
  queryParams: InsurancePrecontractParamsType
): Promise<[Record<string, never> | Blob, Response]> =>
  apiUtilsCb.get({
    endpoint: GET_INSURANCE_CONTRACT_ENDPOINT,
    queryParams,
  });

export {
  createInsurancePrecontractService,
  createInsuranceContractService,
  getInsuranceContractService,
};
