// Vendors
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Types
import { CreditUtilsType } from './types/currency-utils.type';

export const formatCreditAmount = ({ currency, formatNumber, value }: CreditUtilsType): string =>
  formatNumber(value, {
    format: currency,
    currency,
    ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
  });
