// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalDataContactBuilderReturnTrackingHandlersType } from 'containers/personal/views/landing/components/data/components/contact/handlers/types/profile-personal-data-contact-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const editCurrentEmailAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'editEmailButton',
  },
  type: TrackingEventsEnum.LINK,
});

const editCurrentPhoneNumberAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'editPhoneButton',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalDataContactTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalDataContactBuilderReturnTrackingHandlersType => ({
  handleEditCurrentEmailAssetClickTracking: () =>
    track(editCurrentEmailAssetClickTrackingHandler()),
  handleEditCurrentPhoneNumberAssetClickTracking: () =>
    track(editCurrentPhoneNumberAssetClickTrackingHandler()),
});

export default ProfilePersonalDataContactTrackingHandlers;
