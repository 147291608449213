// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_PROPS = {
    testId: 'financing-ppi-contracting-message',
    type: messageConstants.TYPES.ERROR,
  },
  FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-contracting-message-description',
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_DESCRIPTION_PROPS,
};
