// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { FetchErrorComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlLocationsContinentsComponent from '../continents/settings-card-control-locations-continents.component';
import SettingsCardControlLocationsCountriesComponent from './components/settings-card-control-locations-countries/settings-card-control-locations-countries.component';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import constants from './constants/settings-card-control-locations-filtered.constants';
// Hooks
import SettingsCardControlLocationsFilteredHook from './hooks/settings-card-control-locations-filtered.hook';
// Styles
import { SettingsCardControlLocationsFilteredComponentStyled } from './settings-card-control-locations-filtered.component.styled';
// Translations
import translations from './translations/settings-card-control-locations-filtered-error.translations';
// Utils
import {
  noContinentsFilteredFound,
  noCountriesFilteredFound,
} from './utils/setting-card-control-locations-filtered.utils';

const SettingsCardControlLocationsFilteredComponent = (): React.ReactElement | null => {
  const { filteredCountries, filteredContinents, formatMessage } =
      SettingsCardControlLocationsFilteredHook(),
    error =
      noCountriesFilteredFound(filteredCountries) && noContinentsFilteredFound(filteredContinents);

  return (
    <SettingsCardControlLocationsFilteredComponentStyled {...{ error }}>
      <FetchErrorComponent
        error={getFetchErrorConfiguration({
          ...constants.SETTINGS_CARD_CONTROL_LOCATIONS_FILTERED_ERROR_PROPS,
          error,
          formatMessage,
          translations,
        })}
      >
        {!noContinentsFilteredFound(filteredContinents) && (
          <ParagraphComponent>
            <FormattedMessageComponent id={translations.TITLE_CONTINENT} />
          </ParagraphComponent>
        )}
        <SettingsCardControlLocationsContinentsComponent continents={filteredContinents} />
        {!noCountriesFilteredFound(filteredCountries) && (
          <ParagraphComponent>
            <FormattedMessageComponent id={translations.TITLE_COUNTRY} />
          </ParagraphComponent>
        )}
        <SettingsCardControlLocationsCountriesComponent filteredCountries={filteredCountries} />
      </FetchErrorComponent>
    </SettingsCardControlLocationsFilteredComponentStyled>
  );
};

export default SettingsCardControlLocationsFilteredComponent;
