// Types
import {
  TransactionsApiContractUtilsType,
  TransactionsDetailApiContractUtilsType,
} from 'utils/types/transactions-api-contract.utils.type';
import { CardResourcesApiReturnComponentType } from 'types/card-resources-api-return.component.type';

const isValidTransactionsApiContract = (
  response:
    | TransactionsApiContractUtilsType
    | CardResourcesApiReturnComponentType
    | Record<string, any>
): boolean => Boolean(response?.elements);

const isValidTransactionsDetailApiContract = (
  response: TransactionsDetailApiContractUtilsType | Record<string, any>
): boolean => Boolean(response?.resource);

export { isValidTransactionsApiContract, isValidTransactionsDetailApiContract };
