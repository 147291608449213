// Constants
import constants from './constants/profile-personal-name-title-dropdown.handlers.constants';
// Enumerations
import { CustomerTitlesEnumeration } from 'enumerations/customer-titles.enumeration';
// Resolvers
import {
  changeUserTitleResolver,
  fetchTitleMiscellaneousTypesResolver,
} from '../resolvers/profile-personal-name-title-dropdown.resolvers';
// Translations
import translations from './translations/profile-personal-name-title-dropdown.handlers.translations';
// Types
import { ProfilePersonalNameTitleDropdownBuilderReturnHandlersType } from './types/profile-personal-name-title-dropdown-builder-return.handlers.type';
import { ProfilePersonalNameTitleDropdownBuilderHandlersType } from './types/profile-personal-name-title-dropdown-builder.handlers.type';
import { ProfilePersonalNameTitleDropdownChangeBuilderHandlersType } from './types/profile-personal-name-title-dropdown-change-builder.handlers.type';
import { ProfilePersonalNameTitleDropdownFetchBuilderHandlersType } from './types/profile-personal-name-title-dropdown-fetch-builder.handlers.type';
// Utilities
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const changeCurrentTitleDropdownItemClickEventHandler = async ({
  handleMiscellaneousTitlesDropdownClickTracking,
  newTitle,
  setEditTitle,
  setFetching,
  setMe,
  setToastConfiguration,
}: ProfilePersonalNameTitleDropdownChangeBuilderHandlersType): Promise<void> => {
  handleMiscellaneousTitlesDropdownClickTracking(newTitle);
  setFetching(true);

  const [response, error] = await changeUserTitleResolver(newTitle);

  setFetching(false);
  setEditTitle(false);
  response && setMe(true);
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations }) })
  );
};

const fetchMiscellaneousTitlesEventHandler = async ({
  setFetching,
  setEditTitle,
  setTitles,
  setToastConfiguration,
}: ProfilePersonalNameTitleDropdownFetchBuilderHandlersType): Promise<void> => {
  setFetching(true);
  const [response, error] = await fetchTitleMiscellaneousTypesResolver();

  if (error) {
    setToastConfiguration(constants.FETCH_ERROR_TITLES_TOAST_CONFIGURATION);
    setEditTitle(false);
  } else if (response) {
    setTitles(response);
  }

  setFetching(false);
};

const ProfilePersonalNameTitleDropdownHandlers = ({
  handleMiscellaneousTitlesDropdownClickTracking,
  setFetching,
  setEditTitle,
  setMe,
  setToastConfiguration,
  setTitles,
}: ProfilePersonalNameTitleDropdownBuilderHandlersType): ProfilePersonalNameTitleDropdownBuilderReturnHandlersType => ({
  handleFetchMiscellaneousTitlesEvent: () =>
    fetchMiscellaneousTitlesEventHandler({
      setFetching,
      setEditTitle,
      setToastConfiguration,
      setTitles,
    }),
  handleChangeCurrentTitleDropdownItemClickEvent: (newTitle: CustomerTitlesEnumeration) =>
    changeCurrentTitleDropdownItemClickEventHandler({
      handleMiscellaneousTitlesDropdownClickTracking,
      newTitle,
      setEditTitle,
      setFetching,
      setMe,
      setToastConfiguration,
    }),
});

export default ProfilePersonalNameTitleDropdownHandlers;
