// Translations
import { EMAIL_SECTION_TITLE, SMS_SECTION_TITLE } from '../translations/otp.translations';

const OTP_SMS_SECTION_PROPS = {
    title: SMS_SECTION_TITLE,
  },
  OTP_EMAIL_SECTION_PROPS = {
    title: EMAIL_SECTION_TITLE,
  },
  OTP_BUTTON_PROPS = {
    testId: 'otp-button',
  };

export { OTP_BUTTON_PROPS, OTP_SMS_SECTION_PROPS, OTP_EMAIL_SECTION_PROPS };
