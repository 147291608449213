// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { ProfilePersonalDataContactBuilderHandlersType } from './types/profile-personal-data-contact-builder.handlers.type';
import { ProfilePersonalDataContactBuilderReturnHandlersType } from './types/profile-personal-data-contact-builder-return.handlers.type';

const editCurrentEmailAssetClickHandler = ({
  handleEditCurrentEmailAssetClickTracking,
  navigate,
}: Omit<
  ProfilePersonalDataContactBuilderHandlersType,
  'handleEditCurrentPhoneNumberAssetClickTracking'
>): any => {
  handleEditCurrentEmailAssetClickTracking();
  navigate(RoutesEnumeration.EDIT_EMAIL);
};

const editCurrentPhoneNumberAssetClickHandler = ({
  handleEditCurrentPhoneNumberAssetClickTracking,
  navigate,
}: Omit<
  ProfilePersonalDataContactBuilderHandlersType,
  'handleEditCurrentEmailAssetClickTracking'
>): any => {
  handleEditCurrentPhoneNumberAssetClickTracking();
  navigate(RoutesEnumeration.EDIT_PHONE_NUMBER);
};

const ProfilePersonalDataContactHandlers = ({
  handleEditCurrentEmailAssetClickTracking,
  handleEditCurrentPhoneNumberAssetClickTracking,
  navigate,
}: ProfilePersonalDataContactBuilderHandlersType): ProfilePersonalDataContactBuilderReturnHandlersType => ({
  handleEditCurrentEmailAssetClick: () =>
    editCurrentEmailAssetClickHandler({ handleEditCurrentEmailAssetClickTracking, navigate }),
  handleEditCurrentPhoneNumberAssetClick: () =>
    editCurrentPhoneNumberAssetClickHandler({
      handleEditCurrentPhoneNumberAssetClickTracking,
      navigate,
    }),
});

export default ProfilePersonalDataContactHandlers;
