export default {
  CONTEXT_DEFAULT_VALUE: {
    reason: '',
    setReason: (): null => null,
    setProgressId: (): null => null,
    progressId: '',
    isDisabledLastStep: false,
    setIsDisabledLastStep: (): null => null,
    setShowModal: (): null => null,
    showModal: false,
  },
};
