// Constants
import { ONLY_8_NUMBERS } from './constants/security-keys-signature-key-change-constraints.utils.constants';
// Enumerations
import { SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration } from '../components/second-step/enumerations/security-keys-signature-key-change-second-step.enumeration';
// Types
import { SecurityKeysSignatureKeyChangeUtilsConstraintsPasswordDifferentPropsRuleType } from './types/security-keys-signature-key-change-different-password-props.constraints.util.type';

const checkHasEightNumbersOnly = (value: string): boolean => Boolean(value.match(ONLY_8_NUMBERS));

const differentFromCurrentSignatureKey = ({
  signatureKey,
  newSignatureKey,
}: SecurityKeysSignatureKeyChangeUtilsConstraintsPasswordDifferentPropsRuleType): boolean =>
  Boolean(signatureKey !== newSignatureKey);

const notInputError = (
  inputError: SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration | null
): boolean => Boolean(inputError);

export { checkHasEightNumbersOnly, differentFromCurrentSignatureKey, notInputError };
