// Enumerations
import { OperativeResultsEnumeration } from 'components/wizard-feedback/enumerations/operative-results.enumeration';

const WIZARD_FEEDBACK_SUCCESS_PROPS = {
  assetId: 'success',
  assetColor: 'backgroundDoneA',
  color: 'backgroundDoneE',
  type: OperativeResultsEnumeration.SUCCESS,
};

export { WIZARD_FEEDBACK_SUCCESS_PROPS };
