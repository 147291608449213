// Vendors
import React, { useContext, createContext, useState } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/profile-consents.contexts.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ProfileConsentsContextType } from './types/profile-consents.context.type';
import { ProfileConsentsConfig } from '../types/profile-consent.config.type';

const ProfileConsentsContext = createContext<ProfileConsentsContextType>(CONTEXT_DEFAULT_VALUE);

export const ProfileConsentsContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ProfileConsentsContextType>> => {
  const [fetching, setFetching] = useState(true);
  const [consents, setConsents] = useState<ProfileConsentsConfig | null>(null);
  const [error, setError] = useState(false);
  const [initialConsents, setInitialConsents] = useState<ProfileConsentsConfig | null>(null);

  return (
    <ProfileConsentsContext.Provider
      value={{
        consents,
        error,
        fetching,
        setFetching,
        setConsents,
        initialConsents,
        setError,
        setInitialConsents,
      }}
    >
      {children}
    </ProfileConsentsContext.Provider>
  );
};

export const ProfileConsentsContextConsumerHook = (): ProfileConsentsContextType =>
  useContext(ProfileConsentsContext);

/* eslint-disable */
export const ProfileConsentsContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ProfileConsentsContextType>> =>
    (
      <ProfileConsentsContextProvider>
        <Component {...props} />
      </ProfileConsentsContextProvider>
    );
