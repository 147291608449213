// Vendors
import { UseFormProps } from 'react-hook-form';

const USE_FORM_OPTIONS: Pick<UseFormProps, 'mode'> = {
  mode: 'onChange',
};

const MESSAGE_COMPONENT_PROPS = {
  type: 'informative',
};

export { USE_FORM_OPTIONS, MESSAGE_COMPONENT_PROPS };
