// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  EXPENSES_LIST_ITEM_TITLE_PROPS: {
    size: paragraphConstants.SIZES.S,
  },
  EXPENSES_LIST_ITEM_AMOUNT_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.XS,
  },
  EXPENSES_LIST_ITEM_LAST_STEP: 100,
  EXPENSES_LIST_ITEM_DEFAULT_TEST_ID: 'dashboard-spent-expenses-list-item',
  EXPENSES_LIST_ITEM_ASSET_PROPS: {
    testId: 'dashboard-spent-expenses-list-item-asset',
    assetConfiguration: {
      sizes: {
        height: 32,
        width: 32,
      },
    }
  },
};