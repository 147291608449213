// Vendors
import styled from 'styled-components';
// Styles
import { LayoutGridContentLeftComponentStyled } from 'styles/app.styled';

export const AccessPortabilityLeftContent = styled(LayoutGridContentLeftComponentStyled)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
`;
