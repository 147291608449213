// Constants
import { SET_VALUE_OPTIONS } from './constants/address-modal-inputs-street.handlers.constants';
// Enumerations
import { InputNamesEnumeration } from 'components/address-modal/enums/input-names.enumeration';
// Types
import { AddressModalInputsStreetHandlersType } from './types/address-modal-inputs-street.handlers.type';
import { StreetOptionClickHandlerType } from './types/street-option-click-handler.type';
import { AddressModalInputsStreetHandlersReturnType } from './types/address-modal-inputs-street-return.handlers.type';
// Utils
import { parseCityAndPostcode } from './utils/address-modal-inputs-street.handlers.utils';

const streetOptionClickHandler = async ({
  addDetailsToPredictionUtil,
  option,
  setValue,
}: StreetOptionClickHandlerType): Promise<void> => {
  const { street, number, postCode, city } = await addDetailsToPredictionUtil(option.value);

  if (!street) return;

  setValue(InputNamesEnumeration.STREET, street, SET_VALUE_OPTIONS);

  if (number) {
    setValue(InputNamesEnumeration.NUMBER, number, SET_VALUE_OPTIONS);
  }

  if (!city || !postCode) return;

  const { cityValue, postCodeValue } = await parseCityAndPostcode({
    city,
    postCode,
  });

  setValue(InputNamesEnumeration.ZIP, postCodeValue, SET_VALUE_OPTIONS);
  setValue(InputNamesEnumeration.CITY, cityValue, SET_VALUE_OPTIONS);
};

export const AddressModalInputsStreetHandlers = ({
  addDetailsToPredictionUtil,
  setValue,
}: AddressModalInputsStreetHandlersType): AddressModalInputsStreetHandlersReturnType => ({
  handleStreetOptionClick: option =>
    streetOptionClickHandler({ addDetailsToPredictionUtil, option, setValue }),
});
