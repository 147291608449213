const ASCENDING_DESCENDING_REGEX =
    /^(?!.*?(?:012|123|234|345|456|567|678|789|987|876|765|654|543|432|321|210)).*$/,
  CONSECUTIVE_NUMBERS_REGEX = /^(?!.*?(?:000|111|222|333|444|555|666|777|888|999)).*$/,
  ONLY_4_NUMBERS = /^\d{4}$/,
  EQUALS_3_OR_MORE_NUMBERS = /(\d)\1{2,}/;

export {
  ASCENDING_DESCENDING_REGEX,
  CONSECUTIVE_NUMBERS_REGEX,
  ONLY_4_NUMBERS,
  EQUALS_3_OR_MORE_NUMBERS,
};
