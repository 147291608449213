// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const CardSettingsResumeLinkLinksLayoutComponentStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXs};

  > div:last-child {
    border-right: none;
  }
`;

const CardSettingsResumeLinkLinksComponentStyled = styled.div`
  border-right: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  padding-right: ${({ theme }) => theme.spaces.spacingXs};
`;

export {
  CardSettingsResumeLinkLinksLayoutComponentStyled,
  CardSettingsResumeLinkLinksComponentStyled,
};
