export const ROW_FLEX_PROS = {
  gap: '0.5rem',
};

export const SECURITY_ASSET_PROPS = {
  id: 'security',
  testId: 'security-asset',
};

export const HEALTH_ASSET_PROPS = {
  id: 'hands-giving',
  testId: 'health-asset',
};

export const WAITING_ASSET_PROPS = {
  id: 'waiting',
  testId: 'waiting-asset',
};

export const COINS_ASSET_PROPS = {
  id: 'income-black',
  testId: 'coins-asset',
};
