// Constants
import { buttonConstants, titleConstants } from '@openbank/cb-ui-commons';

const PERSONAL_PHOTO_NAME_BUTTON_TITLE_PROPS = {
  testId: 'personal-photo-name-button-title',
  tag: titleConstants.TAGS.H4,
  bold: false,
};

const PERSONAL_PHOTO_NAME_BUTTON_BUTTON_PROPS = {
  assetConfiguration: {
    id: 'upload',
    position: 'left',
    testId: 'personal-photo-name-button-button-asset',
  },
  rwdFull: false,
  testId: 'personal-photo-name-button-button',
  type: buttonConstants.TYPES.SECONDARY,
};

const PERSONAL_PHOTO_NAME_BUTTON_INPUT_PROPS = {
  'accept': 'image/png, image/jpeg',
  'capture': true,
  'data-testid': 'personal-photo-name-button-email',
  'type': 'file',
};

export {
  PERSONAL_PHOTO_NAME_BUTTON_INPUT_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_BUTTON_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_TITLE_PROPS,
};
