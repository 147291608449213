// Vendors
import React from 'react';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CancelModalComponent from 'components/cancel-modal/cancel-modal.component';
// Hooks
import usePersonalPhotoNavigationFooterHook from './hooks/personal-photo-navigation-footer.hook';
// Constants
import {
  PERSONAL_PHOTO_NAVIGATION_FOOTER_BUTTON_PROPS,
  PERSONAL_PHOTO_NAVIGATION_FOOTER_LINK_PROPS,
} from './constants/personal-photo-navigation-footer.constants';
// Styles
import { PersonalPhotoNavigationFooterComponentStyled } from './personal-photo-navigation-footer.component.styled';
// Translations
import { CANCEL, SAVE } from './translations/personal-photo-navigation-footer.translations';
// Utils
import {
  isCancelPhotoModalSet,
  saveUploadPhotoButtonDisabled,
} from './utils/personal-photo-navigation-footer.utils';

const PersonalPhotoNavigationFooterComponent = (): React.ReactElement => {
  const {
    avatar,
    fetching,
    handleCancelUploadPhotoLinkClick,
    handleHideCancelUploadModalClickEvent,
    handleSaveUploadPhotoButtonClick,
    photo,
    photoModal,
  } = usePersonalPhotoNavigationFooterHook();

  return (
    <>
      <PersonalPhotoNavigationFooterComponentStyled>
        <NavigationFooterComponent
          leftLinkConfiguration={{
            ...PERSONAL_PHOTO_NAVIGATION_FOOTER_LINK_PROPS,
            children: <FormattedMessageComponent id={CANCEL} />,
            disabled: fetching,
            onClick: handleCancelUploadPhotoLinkClick,
          }}
          rightButtonConfiguration={{
            ...PERSONAL_PHOTO_NAVIGATION_FOOTER_BUTTON_PROPS,
            children: <FormattedMessageComponent id={SAVE} />,
            loading: fetching,
            disabled: saveUploadPhotoButtonDisabled({ avatar, fetching, photo }),
            onClick: handleSaveUploadPhotoButtonClick,
          }}
        />
      </PersonalPhotoNavigationFooterComponentStyled>
      <CancelModalComponent
        onClose={handleHideCancelUploadModalClickEvent}
        show={isCancelPhotoModalSet(photoModal)}
      />
    </>
  );
};

export default PersonalPhotoNavigationFooterComponent;
