// Types
import {
  CardSettingsDetailsBuilderHandlersType,
  CardSettingsDetailsBuilderReturnHandlersType,
} from './types/card-settings-details.handlers.type';
// Translations
import { PAN_COPIED } from '../translations/card-settings-details.translations';

const copyCardNumberLinkClickHandler = ({
  formatMessage,
  pan,
  handleCopyCardNumberLinkClickTracking,
  handleCopyGenericEvent,
}: CardSettingsDetailsBuilderHandlersType): void => {
  window.navigator.clipboard.writeText(pan);
  handleCopyCardNumberLinkClickTracking();
  handleCopyGenericEvent({ label: formatMessage({ id: PAN_COPIED }), text: pan });
};

const CardSettingsDetailsHandlers = (
  props: CardSettingsDetailsBuilderHandlersType
): CardSettingsDetailsBuilderReturnHandlersType => ({
  handleCopyCardNumberLinkClick: () => copyCardNumberLinkClickHandler(props),
});

export { CardSettingsDetailsHandlers };
