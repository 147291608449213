// Vendors
import React from 'react';
// Types
import { WizardFeedbackHostComponentType } from '../../types/wizard-feedback-host.component.type';

const WizardFeedbackHostContentComponent = ({
  content: Content,
}: Pick<WizardFeedbackHostComponentType, 'content'>): React.ReactElement | null =>
  Content ? <Content /> : null;

export default WizardFeedbackHostContentComponent;
