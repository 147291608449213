// Vendors
import React from 'react';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Components
import { ParagraphComponent, paragraphConstants } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Translations
import {
  DISCLAIMER_FIRST_PARAGRAPH,
  DISCLAIMER_SECOND_PARAGRAPH,
} from './translations/financing-insurances-contracting-lpi-step3-disclaimer.translations';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

export const FinancingInsurancesContractingLPIStep3DisclaimerComponent = (): React.ReactElement => {
  const {
    localization: {
      config: { cfg_public_url_privacy_policy_web },
    },
  } = AppContextConsumerHook();

  return (
    <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingS}>
      <ParagraphComponent size={paragraphConstants.SIZES.XS}>
        <FormattedMessageComponent
          id={DISCLAIMER_FIRST_PARAGRAPH}
          values={{
            openbankPrivacyPolicyLink: chunk =>
              makeChunkLinkIntlUtil({
                chunk,
                onClick: () => window.open(cfg_public_url_privacy_policy_web, '_blank'),
              }),
          }}
        />
      </ParagraphComponent>
      <ParagraphComponent size={paragraphConstants.SIZES.XS}>
        <FormattedMessageComponent id={DISCLAIMER_SECOND_PARAGRAPH} />
      </ParagraphComponent>
    </LayoutColumnFlexComponentStyled>
  );
};
