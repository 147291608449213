const FINANCING_PPI_CONTRACTING_SIDEBAR_PROPS = {
    testId: 'financing-ppi-contracting',
  },
  FINANCING_PPI_CONTRACTING_SIDEBAR_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-contracting-sidebar-description',
  };

export {
  FINANCING_PPI_CONTRACTING_SIDEBAR_PROPS,
  FINANCING_PPI_CONTRACTING_SIDEBAR_DESCRIPTION_PROPS,
};
