// Vendors
import React from 'react';
// Components
import { AssetComponent, TitleComponent } from '@openbank/cb-ui-commons';
import ProfilePersonalNameResumeLayoutComponent from '../resume-layout/profile-personal-name-resume-layout.component';
// Constants
import constants from './constants/profile-personal-name-edition.constants';
// Styles
import { ProfilePersonalNameEditionComponentStyled } from './profile-personal-name-edition.component.styled';
// Types
import { ProfilePersonalNameEditionComponentType } from './types/profile-personal-name-edition.component.type';
// Utils
import { isCC } from 'utils/app.utils';

const ProfilePersonalNameEditionComponent = ({
  label,
  onClick,
  value,
}: ProfilePersonalNameEditionComponentType): React.ReactElement<ProfilePersonalNameEditionComponentType> => (
  <ProfilePersonalNameResumeLayoutComponent {...{ label }}>
    <ProfilePersonalNameEditionComponentStyled {...{ isCC: isCC() }}>
      <TitleComponent {...constants.PROFILE_PERSONAL_NAME_TITLE_EDIT_TITLE_PROPS}>
        {value}
      </TitleComponent>
      <AssetComponent {...{ ...constants.PROFILE_PERSONAL_NAME_TITLE_EDIT_ASSET_PROPS, onClick }} />
    </ProfilePersonalNameEditionComponentStyled>
  </ProfilePersonalNameResumeLayoutComponent>
);

export default ProfilePersonalNameEditionComponent;
