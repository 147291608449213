// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardStatusHookType } from 'hooks/types/card-status.hook.type';

const CardStatusHook = (): CardStatusHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { status = '' } = currentCard || {};

    return { status };
};

export default CardStatusHook;
