// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const Step4AccountAggregationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const Step4AccountAggregationTextSectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const Step4AccountAggregationBanksTableStyled = styled.div``;

export const Step4AccountAggregationRowStyled = styled.div<{
  isHeader?: boolean;
  height?: number;
  background?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme, isHeader }) =>
    isHeader
      ? `padding: 0 ${theme.spaces.spacingS}; text-transform: uppercase;`
      : `margin: 0 ${theme.spaces.spacingS};`}
  ${({ height }) => (height ? `height: ${convertPxsToRems({ pixels: height, base: 14 })};` : '')}
  ${({ background }) => (background ? `background: ${background};` : '')}
`;

export const Step4AccountAggregationDataRowsStyled = styled.div`
  > div:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  }
`;

export const Step4AccountAggregationRowSectionStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const Step4AccountAggregationTextStyled = styled.div<{ isError?: boolean }>`
  ${({ theme, isError }) => (isError ? `color: ${theme.colors.fontTertiaryF}` : '')};
`;

export const Step4AccountAggregationGoToUploadDocumentationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  padding-top: ${({ theme }) => theme.spaces.spacingS};
`;

export const MessageComponentHeaderStyled = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
