// Vendors
import { linkConstants } from '@openbank/cb-ui-commons';
// Types
import {
  FinancingPPILandingLinkBuilderType,
  FinancingPPILandingLinkReturnType,
} from './types/financing-ppi-landing-link.configuration.type';
// Utilities
import { showTermsAndConditionsLink } from '../utils/financing-ppi-landing.utils';
import { VIEW_TERMS_AND_CONDITIONS } from '../translations/financing-ppi-landing.translations';

const getPPILandingLinkContractConfiguration = ({
  formatMessage,
  onClick,
  status,
  tnc,
}: FinancingPPILandingLinkBuilderType): FinancingPPILandingLinkReturnType =>
  showTermsAndConditionsLink(status)
    ? {
        linkConfiguration: {
          children: formatMessage({ id: VIEW_TERMS_AND_CONDITIONS }),
          href: tnc,
          onClick,
          type: linkConstants.TYPES.ACTION_SECONDARY,
        },
      }
    : {};

export { getPPILandingLinkContractConfiguration };
