export default {
  ACCESS_PORTABILITY_REQUEST_STEP_2_ERROR_PROPS: {
    assetId: 'Working_on_it_Alternative',
  },
  ACCESS_PORTABILITY_REQUEST_STEP_2_REQUEST_RESUME_ITEM_PROPS: {
    testing: {
      labelId: 'access-portability-request-step2-request-item-label',
      valueId: 'access-portability-request-step2-request-item-value',
    },
  },
  ACCESS_PORTABILITY_REQUEST_STEP_2_COST_RESUME_ITEM_PROPS: {
    testing: {
      labelId: 'access-portability-request-step2-cost-item-label',
      valueId: 'access-portability-request-step2-cost-item-value',
    },
  },
  ACCESS_PORTABILITY_REQUEST_STEP_2_MESSAGE_PROPS: {
    showIcon: false,
    testId: 'access-portability-request-message',
    type: 'informative',
  },
};
