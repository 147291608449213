// Constants
import { NOT_PROVIDED_BIC } from './constants/sepa-modal.utils.constants';
// Translations
import { COUNTRY, NOT_PROVIDED_BIC_LABEL } from './translations/sepa-modal.translations.utils';
// Types
import {
  ProfilePersonalDataAddressUtilsType,
  SepaMandateBicUtilsType,
} from './types/sepa-modal.utils.type';

const createMappedDebtorsAddress = ({
  formatMessage,
  address: { countryCode, number, postCode, streetLine1, town },
}: ProfilePersonalDataAddressUtilsType): string =>
  `${streetLine1} ${number},\n${postCode} ${town}\n${formatMessage({
    id: `${COUNTRY}_${countryCode}`,
  })}`;

const getSEPAModalBICValue = ({ bic, formatMessage }: SepaMandateBicUtilsType): string =>
  bic === NOT_PROVIDED_BIC || !bic ? formatMessage({ id: NOT_PROVIDED_BIC_LABEL }) : bic;

export { createMappedDebtorsAddress, getSEPAModalBICValue };
