const DOMAIN_BLOCK_CARD_SECOND_STEP =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.second.';
const DOMAIN_BLOCK_CARD_SECOND_STEP_CC_FIRST_MESSAGE =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.second.cc.firstMessage.';
const DOMAIN_BLOCK_CARD_SECOND_STEP_CC_SECOND_MESSAGE =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.steps.second.cc.secondMessage.';

const DESCRIPTION = `${DOMAIN_BLOCK_CARD_SECOND_STEP}description`;

const FIRST_CC_MESSAGE = {
  FIRST_PARAGRAPH: `${DOMAIN_BLOCK_CARD_SECOND_STEP_CC_FIRST_MESSAGE}firstParagraph`,
  SECOND_PARAGRAPH: `${DOMAIN_BLOCK_CARD_SECOND_STEP_CC_FIRST_MESSAGE}secondParagraph`,
  THIRD_PARAGRAPH: `${DOMAIN_BLOCK_CARD_SECOND_STEP_CC_FIRST_MESSAGE}thirdParagraph`,
};

const SECOND_CC_MESSAGE = {
  FIRST_PARAGRAPH: `${DOMAIN_BLOCK_CARD_SECOND_STEP_CC_SECOND_MESSAGE}firstParagraph`,
};

export { DESCRIPTION, FIRST_CC_MESSAGE, SECOND_CC_MESSAGE };
