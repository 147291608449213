// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const LoginHeaderComponentStyled = styled.header`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  display: flex;
  justify-content: space-between;
  height: ${convertPxsToRems({ pixels: 64, base: 14 })};
  padding: 0 ${({ theme }) => theme.spaces.spacingS} 0 ${({ theme }) => theme.spaces.spacingL};
  position: sticky;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};

    button {
      width: fit-content;
    }
  }
`;
