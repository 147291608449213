// Types
import { Step1CreditLimitDecreaseCCHookUtilsType } from './types/step-1-credit-limit-decrease-cc.hook.utils.type';
// Utils
import { formatCreditAmount } from 'components/credit/utils/credit.utils';

export const formatCurrency =
  ({
    currency,
    formatNumber,
  }: Step1CreditLimitDecreaseCCHookUtilsType): ((value: number) => string) =>
  (value: number) =>
    formatCreditAmount({
      currency,
      formatNumber,
      value,
      maximumFractionDigits: 0,
    });
