import styled from 'styled-components';
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const TransactionsTableListGroupsComponentStyled = styled.section`
  width: 100%;
`;

const TransactionsTableListGroupsItemComponentStyled = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.spacingXxxxxl};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingM};
  }

  ${mobileMediaQuery} {
    padding: 0;
  }
`;

export {
  TransactionsTableListGroupsComponentStyled,
  TransactionsTableListGroupsItemComponentStyled,
};
