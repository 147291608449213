export default {
  ACTIVATE_CARD_BUTTON_CLICK_PAYLOAD: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationActivateCardButton',
  },
  CANCEL_LINK_CLICK_PAYLOAD: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationCancelLink',
  },
};
