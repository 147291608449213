// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { AssetComponent, ParagraphComponent, TooltipComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/total-amount.constants';
// Hooks
import TotalAmountHook from './hooks/total-amount.hook';
// Styles
import {
  CommissionWrapperStyled,
  InfoWrapperStyled,
  TotalAmmountWrapperStyled,
} from './total-amount.component.styled';
// Translations
import { TITLE, TOOLTIP } from './translations/total-amount.translations';
// Utils
import {
  getTotalAmount,
  getTotalCommission,
} from 'containers/financing/views/fast-cash/utils/financing-fast-cash.utils';

const TotalAmountComponent = (): React.ReactElement => {
  const { amountValue, showValues, currency, commission, formatNumber, formatMessage } =
    TotalAmountHook();

  return (
    <TotalAmmountWrapperStyled {...constants.PROPS}>
      <InfoWrapperStyled>
        <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
          <FormattedMessageComponent id={TITLE.FIRST} />
        </ParagraphComponent>
        <ParagraphComponent {...constants.DESCRIPTION_PARAGRAPH_PROPS}>
          {getTotalAmount({ showValues, formatNumber, amountValue, currency })}
        </ParagraphComponent>
      </InfoWrapperStyled>
      <InfoWrapperStyled>
        <CommissionWrapperStyled>
          <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
            <FormattedMessageComponent id={TITLE.SECOND} />
          </ParagraphComponent>
          <TooltipComponent content={formatMessage({ id: TOOLTIP })}>
            <AssetComponent {...constants.ASSET_PROPS} />
          </TooltipComponent>
        </CommissionWrapperStyled>

        <ParagraphComponent {...constants.DESCRIPTION_PARAGRAPH_COMISSION_PROPS}>
          {getTotalCommission({ showValues, formatNumber, amountValue, commission, currency })}
        </ParagraphComponent>
      </InfoWrapperStyled>
    </TotalAmmountWrapperStyled>
  );
};

export default TotalAmountComponent;
