// Vendors
import { titleConstants } from '@openbank/cb-ui-commons';
// Constants
import changeCreditLimitConstants from '../../change-credit-limit/constants/change-credit-limit.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const TEST_ID_ROOT = 'change-credit-limit-';

export default {
  TITLE_PROPS: {
    test_id: `${TEST_ID_ROOT}title`,
    tag: titleConstants.TAGS.H4,
  },
  PRODUCT_DETAIL_PROPS: {
    test_id: `${TEST_ID_ROOT}product-detail`,
  },
  PARAGRAPH_PROPS: {
    test_id: `${TEST_ID_ROOT}paragraph`,
    bold: true,
  },
  DEFAULT_VALUES: {
    newCreditLimit: changeCreditLimitConstants.CREDIT_LIMIT_CONFIG.MIN,
    currentCreditLimit: changeCreditLimitConstants.CREDIT_LIMIT_CONFIG.MIN,
    minAmount: changeCreditLimitConstants.CREDIT_LIMIT_CONFIG.MIN,
    maxAmount: changeCreditLimitConstants.CREDIT_LIMIT_CONFIG.MAX,
    nextDisabled: true,
  },
  STEP_1_WIZARD_FOOTER_PROPS: {
    cancelUrl: `/${RoutesEnumeration.FINANCING}`,
  },
  STEP_1_ACCORDION_PROPS: {
    type: 'accordion',
  },
} as const;
