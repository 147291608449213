// Vendors
import { geoMiller } from 'd3-geo-projection';
// Constants
import constants from '../constants/setting-card-control-locations-map.constants';
// Types
import { SettingsCardControlLocationsMapConfigurationFunction } from './types/setting-card-control-locations-map-configurations.types';
import { SettingsCardControlLocationsMapConfigurationType } from './types/setting-card-control-locations-map.types';

export const getMapConfigurations: SettingsCardControlLocationsMapConfigurationFunction<
  SettingsCardControlLocationsMapConfigurationType
> = () => {
  const { centerYoffSet, height, scale, width, yOffset } =
    constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_PROPS;

  const projection = geoMiller()
    .translate([width / centerYoffSet, height / centerYoffSet + yOffset])
    .scale(scale);

  return {
    projection,
    height,
    width,
  };
};
