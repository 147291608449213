// Types
import { CardTransactionType } from 'types/card-transaction.type';
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';
import { TransactionsApiReturnComponentType } from 'types/transactions-api-return.component.type';

const isLegitTransactionsResponse = (
  response: TransactionsApiReturnComponentType | Record<string, any>
): boolean => Array.isArray(response?.elements);

const updateElementsFiltered =
  (elements: CardTransactionType[], idName: string) =>
  (prevElements: CardTransactionType[] = []): CardTransactionType[] =>
    [...prevElements, ...elements].reduce((acc, item) => {
      if (!acc.find(x => x[idName] === item[idName])) {
        acc.push(item);
      }
      return acc;
    }, [] as CardTransactionType[]);

const updateElements =
  (elements: CardTransactionType[]) =>
  (prevElements: CardTransactionType[] = []): CardTransactionType[] =>
    [...prevElements, ...elements].reduce((acc, item) => {
      acc.push(item);
      return acc;
    }, [] as CardTransactionType[]);

const updateMetadata =
  (metadata: TransactionsTableMetadataComponentType) =>
  (
    prevMetadata: TransactionsTableMetadataComponentType
  ): TransactionsTableMetadataComponentType => ({
    ...metadata,
    lastNextCursor: metadata.nextCursor ?? prevMetadata.lastNextCursor,
    includeAllRecords: metadata.hasPendingRecords || prevMetadata.includeAllRecords,
  });

export { isLegitTransactionsResponse, updateElements, updateMetadata, updateElementsFiltered };
