// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import { ExpiredSignatureComponent } from 'containers/personal/components/expired-signature-message/expired-signature-message.component';
// Constants
import { CHALLENGE_WRAPPER_PROPS } from './constants/personal-address-step2-challenge.constants';
// Hooks
import { usePersonalAddressStep2ChallengeHook } from './hooks/personal-address-step2-challenge.hook';
// Types
import { PersonalAddressStep2ChallengeComponentType } from './types/personal-address-step2-challenge.component.type';
// Utils
import { formatChangeAddressParams } from '../../utils/personal-address-step2.utils';

export const PersonalAddressStep2ChallengeComponent = ({
  newAddress,
}: PersonalAddressStep2ChallengeComponentType): React.ReactElement => {
  const {
    challengeError,
    handleChallengeError,
    handleChallengeRetryButtonClick,
    handleChallengeSuccess,
    signatureExpired
  } = usePersonalAddressStep2ChallengeHook();

  return (
    <>
      { signatureExpired && <ExpiredSignatureComponent /> }
      <ChallengeWrapperComponent
        {...CHALLENGE_WRAPPER_PROPS}
        baseServiceBuilder={{ params: formatChangeAddressParams(newAddress) }}
        challengeError={challengeError}
        onError={handleChallengeError}
        onRetry={handleChallengeRetryButtonClick}
        onSuccess={handleChallengeSuccess}
      />
    </>
  );
};
