// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const CardSettingsBlockCardSecondStepSummaryComponentStyled = styled.div`
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: start;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
  }
`;
