// @Vendors
import { titleConstants } from '@openbank/cb-ui-commons';
// Constants
import {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_INSURANCES,
} from '../translations/financing-insurances-cancellation-lpi.translations';

// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';
import { FINANCING_INSURANCES_CANCELLATION_LPI_SERVICE_CONFIGURATION } from '../services/constants/financing-insurances-cancellation-lpi.constants';

const FINANCING_INSURANCES_CANCELLATION_LPI_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-cancellation-landing-description',
  },
  FINANCING_INSURANCES_CANCELLATION_LPI_TITLE_PROPS = {
    tag: titleConstants.TAGS.H4,
    testId: 'financing-ppi-cancellation-landing-title',
  },
  FINANCING_INSURANCES_CANCELLATION_LPI_FEEDBACK_PROPS = {
    errorConfiguration: {
      description: FEEDBACK_ERROR_DESCRIPTION,
      linkLabel: GO_TO_INSURANCES,
      title: FEEDBACK_ERROR_TITLE,
      exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES]),
    },
    successConfiguration: {
      description: FEEDBACK_SUCCESS_DESCRIPTION,
      linkLabel: GO_TO_INSURANCES,
      title: FEEDBACK_SUCCESS_TITLE,
      exitRoute: generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.INSURANCES]),
    },
  };

const FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_CHALLENGE_PROPS = {
    baseService: FINANCING_INSURANCES_CANCELLATION_LPI_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'post',
  },
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_FOOTER_PROPS = {
    hasDefaultRightButton: false,
  },
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_BUTTON_PROPS = {
    rwdFull: false,
    testId: 'financing-ppi-cancellation-landing-footer-actions-button',
  };

const FINANCING_INSURANCES_CANCELLATION_LPI_PRODUCT_PROPS = {
  testId: 'financing-lpi-cancellation-landing-product',
};

export {
  FINANCING_INSURANCES_CANCELLATION_LPI_DESCRIPTION_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_TITLE_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FEEDBACK_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_CHALLENGE_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_FOOTER_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_BUTTON_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_PRODUCT_PROPS,
};
