// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import ErrorWrapperHandlers from '../handlers/error-wrapper.handlers';
import ErrorWrapperTrackingHandlers from '../handlers/error-wrapper.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { ErrorWrapperReturnHookType } from './types/error-wrapper-return.hook.type';

const useErrorWrapperHook = (): ErrorWrapperReturnHookType => {
  const { formatMessage } = useCbIntl();

  return {
    ...ErrorWrapperHandlers(AppTrackingHook(ErrorWrapperTrackingHandlers)),
    formatMessage,
  };
};

export default useErrorWrapperHook;
