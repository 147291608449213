// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/support-cancel-contract-second-step-challenge.service.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { CancelContractCheckpointServiceType } from '../resolvers/types/support-cancel-contract-second-step-resolvers.type';

const cancelContractCheckpointService = async ({
  cancellationReason,
  cardContractId,
  progressId,
}: CancelContractCheckpointServiceType): Promise<[ChallengeType, Response]> => {
  const customHeaders = progressId ? { progressId } : undefined;

  return apiUtilsCb.post({
    endpoint: constants.COMPLETE_CANCEL_CONTRACT_OTP_SERVICE_CONFIGURATION.endpoint,
    pathParams: { cardContractId },
    customHeaders,
    params: { cancellationReason },
  });
};
export { cancelContractCheckpointService };
