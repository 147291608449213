// Translations
import translations from '../translations/transactions-landing-row-details-points.translations';
// Types
import { TransactionAmazonPointsConfigurationReturnType } from './types/transactions-landing-row-details-points-return.configuration.type';
import { TransactionAmazonPointsConfigurationType } from './types/transactions-landing-row-details-points.configuration.type';
// Utilities
import { getTransactionsPoints } from 'components/table-row-description/components/points/utils/table-row-description-points.utils';

const getTransactionAmazonPointsConfiguration = ({
  reward,
  formatNumber,
}: TransactionAmazonPointsConfigurationType): TransactionAmazonPointsConfigurationReturnType => {
  const points = reward?.amount || 0;
  return {
    id: Number(points) === 1 ? translations.POINT : translations.POINTS,
    values: {
      points: getTransactionsPoints({ reward, formatNumber }),
    },
  };
};

export { getTransactionAmazonPointsConfiguration };
