// Vendors
import React from 'react';
// Components
import { ExpiredSessionModalComponent } from 'components/expired-session-modal/expired-session-modal.component';
// Constants
import { EXPIRED_SESSION_MODAL_ORCHESTRATOR_MODAL_PROPS } from './constants/session-health-orchestrator.constants';
// Types
import { SessionHealthOrchestratorComponentType } from './types/session-health-orchestrator.component.type';

const SessionHealthOrchestratorComponent = ({
  children,
  error,
}: SessionHealthOrchestratorComponentType): React.ReactElement | React.ReactNode | any =>
  error ? (
    <ExpiredSessionModalComponent {...EXPIRED_SESSION_MODAL_ORCHESTRATOR_MODAL_PROPS} />
  ) : (
    children
  );

export { SessionHealthOrchestratorComponent };
