const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.step4.';

const CONTRACT_CONFIRMATION_MESSAGE = `${DOMAIN}download.contractConfirmationTitle`;
const DOWNLOAD_CONTRACT_BUTTON_LABEL = `${DOMAIN}downloadContractButtonLabel`;
const DOWNLOAD_CONTRACT_SUCCESS_MESSAGE = `${DOMAIN}downloadContractSuccessMessage`;

export {
  CONTRACT_CONFIRMATION_MESSAGE,
  DOWNLOAD_CONTRACT_BUTTON_LABEL,
  DOWNLOAD_CONTRACT_SUCCESS_MESSAGE,
};
