// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/personal-number-step2.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalNumberStep2ContextType } from 'containers/personal/views/number/components/step2/contexts/types/personal-number-step2.context.type';

const PersonalEmailStep2Context = createContext<PersonalNumberStep2ContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const PersonalNumberStep2ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalNumberStep2ContextType>> => {
  const [otpId, setOtpId] = useState<string>('');
  const [otpValue, setOtpValue] = useState<string>('');

  return (
    <PersonalEmailStep2Context.Provider value={{ otpId, otpValue, setOtpId, setOtpValue }}>
      {children as React.ReactNode}
    </PersonalEmailStep2Context.Provider>
  );
};

export const PersonalNumberStep2ContextConsumerHook = (): PersonalNumberStep2ContextType =>
  useContext(PersonalEmailStep2Context);

/* eslint-disable */
export const PersonalNumberStep2ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalNumberStep2ContextType>> =>
    (
      <PersonalNumberStep2ContextProvider>
        <Component {...props} />
      </PersonalNumberStep2ContextProvider>
    );
