const IDS = {
    MODEL: 'mb',
    COBRANDED: 'cb',
  },
  HOSTS = {
    MODEL: 'openbank.de',
    COBRANDED: 'amazon.zinia.de',
  };

export { IDS, HOSTS };
