// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingStep2ExpandableDescriptionLinkComponent } from '../description-link/financing-ppi-contracting-step2-expandable-description-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMIT_PROPS } from './constants/financing-ppi-contracting-step2-expandable-limit.constants';

const FinancingPPIContractingStep2ExpandableLimitComponent = (): React.ReactElement => (
  <FinancingPPIContractingStep2ExpandableDescriptionLinkComponent
    {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_LIMIT_PROPS}
  />
);

export { FinancingPPIContractingStep2ExpandableLimitComponent };
