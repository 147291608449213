// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const DASHBOARD_TOP_MESSAGE_BUTTON_PROPS = {
  rwdFull: false,
  testId: 'dashboard-message-button',
  type: buttonConstants.TYPES.SECONDARY,
  trackingLabel: 'lastStatements',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.CARD_STATEMENTS])
  ),
};

export { DASHBOARD_TOP_MESSAGE_BUTTON_PROPS };
