// Vendors
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { CardSettingsDividerTitleComponent } from '../divider-title/card-settings-divider-title.component';
import { CardSettingsPaymentModalComponent } from './components/modal/card-settings-payment-modal.component';
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
// Constants
import {
  CARD_SETTINGS_PAYMENT_CHANGE_PROPS,
  CARD_SETTINGS_PAYMENT_CHECK_PROPS,
  CARD_SETTINGS_PAYMENT_DIVIDER_SECTION_PROPS,
  CARD_SETTINGS_PAYMENT_DIVIDER_PROPS,
  CARD_SETTINGS_PAYMENT_STORES_PROPS,
} from './constants/card-settings-payment.constants';
// Hooks
import { useCardSettingsPaymentHook } from './hooks/card-settings-payment.hook';
// Styles
import { CardSettingSectionDividerComponentStyled } from '../../card-settings-landing.container.styled';
// Translations
import { TITLE, TOOLTIP } from './translations/card-settings-payment.translations';
// Types
import { getNonSecureStoresPaymentsLabel } from './utils/card-settings-payment.utils';

const CardSettingsPaymentComponent = (): React.ReactElement => {
  const {
    handleShowNonSecureStoreModal: onClick,
    handleToggleNonSecureStoreModal: onClose,
    isAllowPaymentsNonSecStore: enabled,
    showNonSecureStoreModal: visible,
  } = useCardSettingsPaymentHook();

  return (
    <SectionDividerComponent
      {...CARD_SETTINGS_PAYMENT_DIVIDER_PROPS}
      title={<CardSettingsDividerTitleComponent title={TITLE} />}
    >
      <CardSettingSectionDividerComponentStyled {...CARD_SETTINGS_PAYMENT_DIVIDER_SECTION_PROPS}>
        <CardSettingsResumeLinkComponent {...CARD_SETTINGS_PAYMENT_CHECK_PROPS} />
        <CardSettingsResumeLinkComponent {...CARD_SETTINGS_PAYMENT_CHANGE_PROPS} />
        <CardSettingsResumeLinkComponent
          {...CARD_SETTINGS_PAYMENT_STORES_PROPS}
          links={[{ ...CARD_SETTINGS_PAYMENT_STORES_PROPS.links[0], onClick }]}
          tooltipMessage={<FormattedMessageComponent id={TOOLTIP} />}
          value={<FormattedMessageComponent id={getNonSecureStoresPaymentsLabel(enabled)} />}
        />
      </CardSettingSectionDividerComponentStyled>
      <CardSettingsPaymentModalComponent {...{ onClose, visible }} enabled={!enabled} />
    </SectionDividerComponent>
  );
};

export { CardSettingsPaymentComponent };
