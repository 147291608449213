// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import {
  ParagraphComponent,
  AssetComponent,
  ModalComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import { DeviceModalBodyComponent } from './body/device-modal-body.component';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DEVICE_ICON_PROPS,
  DEVICE_ICON_CURRENT_COLOR,
  DEVICE_ICON_ERROR_COLOR,
  DEFAULT_ICON_DEVICE,
  DEVICE_ALIAS_TITLE_PROPS,
  CHALLENGE_WRAPPER_PROPS,
} from './constants/device-modal.constants';
// Configurations
import { getDeviceModalConfigurations } from './configurations/device-modal.configurations';
// Enumerations
import { DeviceModalEnum } from './enumerations/device-modal.enumeration';
import { DeviceHeaderPlatformTypeEnum } from '../device-header/enumerations/device-header.enumerations';
// Hooks
import { DeviceModalHook } from './hooks/device-modal.hook';
// Styled
import {
  DeviceModalContentStyled,
  DeviceModalContentHeaderStyled,
} from './device-modal.component-styled';
// Translations
import { MODAL_TYPES } from './translations/device-modal.translations';
// Types
import { DeviceModalComponentType } from './types/device-modal.type';

const DeviceModalComponent = ({
  device,
  onClose,
  onAccept,
  type = DeviceModalEnum.BLOCK,
  loading = false,
}: DeviceModalComponentType): React.ReactElement => {
  const {
    challenged,
    solvedChallenged,
    handleManageErrorUnrollTrustedDeviceEvent,
    handleManageSuccessUnrollTrustedDeviceEvent,
    handleOnAccept,
    formatMessage,
    onLinkClose,
    onCrossClose,
  } = DeviceModalHook({ type, onAccept, onClose, device });

  return (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...{
        ...getDeviceModalConfigurations({
          type,
          formatMessage,
          loading,
          onClose: onLinkClose,
          onCrossClose: onCrossClose,
          onAccept: handleOnAccept,
          buttonConfiguration: {
            disabled: !solvedChallenged,
          },
        }),
      }}
    >
      <DeviceModalContentStyled>
        <DeviceModalContentHeaderStyled>
          <AssetComponent
            {...{
              ...DEVICE_ICON_PROPS,
              ...(device.lastSession?.active ? DEVICE_ICON_CURRENT_COLOR : {}),
              ...(device.blocked ? DEVICE_ICON_ERROR_COLOR : {}),
            }}
            id={
              DeviceHeaderPlatformTypeEnum[
                device.webDeviceInfo?.deviceType ||
                  device.mobileDeviceInfo?.platform ||
                  DEFAULT_ICON_DEVICE
              ]
            }
          />
          <TitleComponent {...{ ...DEVICE_ALIAS_TITLE_PROPS }}>{device.alias}</TitleComponent>
        </DeviceModalContentHeaderStyled>

        <ParagraphComponent>
          <FormattedMessageComponent id={MODAL_TYPES[type].description} />
        </ParagraphComponent>
        <DeviceModalBodyComponent device={device} type={type} />
        {challenged && (
          <ChallengeWrapperComponent
            {...{
              ...CHALLENGE_WRAPPER_PROPS,
              baseServiceBuilder: {
                params: { deviceUUID: device.deviceUUID },
              },
              onError: handleManageErrorUnrollTrustedDeviceEvent,
              onSuccess: handleManageSuccessUnrollTrustedDeviceEvent,
            }}
          />
        )}
      </DeviceModalContentStyled>
    </ModalComponent>
  );
};

export { DeviceModalComponent };
