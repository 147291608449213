// Vendors
import React from 'react';
// Types
import { CardTransactionType } from 'types/card-transaction.type';
import { EppCardType } from 'types/epp-card.type';
import { TransactionsLandingTableListComponentType } from '../../types/transactions-landing-table-list.component.type';
// Utilities
import { shouldOpenDetail } from './utils/transactions-table-list-elements.utils';

const TransactionsTableListElementsComponent = ({
  elements = [],
  rowComponent: TransactionsTableListElementsRowComponent,
  isFinancing,
}: Pick<TransactionsLandingTableListComponentType, 'rowComponent'> & {
  isFinancing?: boolean;
  elements: CardTransactionType[] | EppCardType[];
}): React.ReactElement => (
  <>
    {elements.map(
      (element: CardTransactionType | EppCardType, key: number): React.ReactElement => (
        <TransactionsTableListElementsRowComponent
          {...{ element }}
          openDefault={isFinancing && shouldOpenDetail(element as EppCardType)}
          key={key}
        />
      )
    )}
  </>
);

export default TransactionsTableListElementsComponent;
