// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export default {
  SUPPORT_LANDING_ROUTE_PROPS: {
    index: true,
  },
  DEVICES_ROUTE_PROPS: {
    index: true,
    path: RoutesEnumeration.DEVICES + '/*',
  },
  ACESS_KEY_ROUTE_PROPS: {
    index: true,
    path: RoutesEnumeration.ACCESS_KEY + '/*',
  },
  SIGNATURE_KEY_ROUTE_PROPS: {
    index: true,
    path: RoutesEnumeration.SIGNATURE_KEY + '/*',
  },
  REQUEST_SIGNATURE_KEY_ROUTE_PROPS: {
    path: RoutesEnumeration.REQUEST_SIGNATURE_KEY + '/*',
  },
};
const SECURITY_KEYS_DEVICES_ROUTE_PROPS = {
  path: RoutesEnumeration.DEVICES,
};

export { SECURITY_KEYS_DEVICES_ROUTE_PROPS };
