// Vendors
import styled from 'styled-components';
import { LayoutColumnFlexComponentStyled } from '@/styles/app.styled';
// Utilities

const FinancingPPIContractingStep2FeaturesSectionComponentStyled = styled(
  LayoutColumnFlexComponentStyled
)`
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
FinancingPPIContractingStep2FeaturesSectionComponentStyled.displayName =
  'FinancingPPIContractingStep2FeaturesSectionComponentStyled';

const FinancingPPIContractingStep2FeaturesSectionListComponentStyled = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  margin: 0;
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;
FinancingPPIContractingStep2FeaturesSectionListComponentStyled.displayName =
  'FinancingPPIContractingStep2FeaturesSectionListComponentStyled';

const FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled = styled.li`
  width: 100%;
`;
FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled.displayName =
  'FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled';

export {
  FinancingPPIContractingStep2FeaturesSectionComponentStyled,
  FinancingPPIContractingStep2FeaturesSectionListComponentStyled,
  FinancingPPIContractingStep2FeaturesSectionListItemComponentStyled,
};
