// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ErrorWrapperComponentStyled = styled.section<{ error: boolean }>`
  width: 100%;
  ${({ error }) =>
    error &&
    css`
      ${mobileMediaQuery} {
        padding: ${({ theme }) => theme.spaces.spacingS};
      }
    `}
`;
