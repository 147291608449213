// Vendors
import React, { createContext, useContext, useState } from 'react';
// Constants
import constants from './constants/security-keys-signature-key-change.context.constants';
// Enumerations
import { SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration } from '../components/second-step/enumerations/security-keys-signature-key-change-second-step.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SecurityKeysSignatureKeyChangeContextType } from './types/security-keys-signature-key-change.context.type';

const SecurityKeysSignatureKeyChangeContext = createContext<SecurityKeysSignatureKeyChangeContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const SecurityKeysSignatureKeyChangeContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<SecurityKeysSignatureKeyChangeContextType>> => {
  const [signatureKey, setSignatureKey] = useState<string>('');
  const [repeatNewSignatureKey, setRepeatNewSignatureKey] = useState<string>('');
  const [progressId, setProgressId] = useState<string>('');
  const [newSignatureKey, setNewSignatureKey] = useState<string>('');
  const [isDisabledLastStep, setIsDisabledLastStep] = useState<boolean>(true);
  const [fromPersonal, setFromPersonal] = useState<boolean>(false);
  const [inputError, setInputError] = useState<
    SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration[] | null
  >(null);

  return (
    <SecurityKeysSignatureKeyChangeContext.Provider
      value={{
        fromPersonal,
        signatureKey,
        setSignatureKey,
        setRepeatNewSignatureKey,
        setProgressId,
        setNewSignatureKey,
        setIsDisabledLastStep,
        setInputError,
        setFromPersonal,
        repeatNewSignatureKey,
        progressId,
        newSignatureKey,
        isDisabledLastStep,
        inputError,
      }}
    >
      {children as React.ReactNode}
    </SecurityKeysSignatureKeyChangeContext.Provider>
  );
};

export const SecurityKeysSignatureKeyChangeContextConsumerHook = (): SecurityKeysSignatureKeyChangeContextType =>
  useContext(SecurityKeysSignatureKeyChangeContext);

/* eslint-disable */
export const SignatureKeyChangeContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<SecurityKeysSignatureKeyChangeContextType>> =>
    (
      <SecurityKeysSignatureKeyChangeContextProvider>
        <Component {...props} />
      </SecurityKeysSignatureKeyChangeContextProvider>
    );
