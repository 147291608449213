// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Styles
import { CardSettingsContractCurrentMessageComponentWrapper } from './card-settings-contract-insurance-coverage-message.component.styled';
// Translations
import { PROTECTION_INSURANCE_SUBSCRIPTION_VALID_UNTIL_MESSAGE } from './translations/card-settings-contract-insurance-coverage-message.component.translations';
// Types
import { CardSettingsContractCurrentInsuranceCoverageMessageComponentType } from './types/card-settings-contract-insurance-coverage-message-component.type';
// Utils
import {
  isDateExpired,
  isInsuranceActive,
} from '../../utils/card-settings-contract-current-insurance.utils';

const CardSettingsContractCurrentInsuranceCoverageMessageComponent = ({
  status,
  endCoverageDate,
}: CardSettingsContractCurrentInsuranceCoverageMessageComponentType): React.ReactElement | null =>
  !isInsuranceActive(status) && endCoverageDate && !isDateExpired(endCoverageDate) ? (
    <CardSettingsContractCurrentMessageComponentWrapper>
      <ParagraphComponent bold>
        <FormattedMessageComponent
          id={PROTECTION_INSURANCE_SUBSCRIPTION_VALID_UNTIL_MESSAGE}
          values={{
            date: (
              <FormattedDate
                {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
                value={endCoverageDate}
              />
            ),
          }}
        />
      </ParagraphComponent>
    </CardSettingsContractCurrentMessageComponentWrapper>
  ) : null;

export { CardSettingsContractCurrentInsuranceCoverageMessageComponent };
