// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  CHANGE_PAYMENT_METHOD_ENDPOINT,
  RESOLVING_OPTIONS_ENDPOINT,
} from './constants/financing-payment-method.services.constants';
// Types
import { RevolvingOptionsType } from '../types/revolving-options.type';
import { ChangePaymentMethodServiceType } from './types/change-payment-method-service.type';
import { ChallengeType } from 'types/challenge.type';
import { ChangePaymentMethodResponseType } from './types/change-payment-method-response.type';

const getRevolvingOptionsService = async (): Promise<[RevolvingOptionsType | null, Response]> =>
  apiUtilsCb.get({
    endpoint: RESOLVING_OPTIONS_ENDPOINT,
  });

const changePaymentMethodService = async ({
  contractId,
  params,
  progressId,
}: ChangePaymentMethodServiceType): Promise<
  [ChangePaymentMethodResponseType | ChallengeType | null, Response]
> =>
  apiUtilsCb.patch({
    endpoint: CHANGE_PAYMENT_METHOD_ENDPOINT,
    customHeaders: progressId ? { progressId } : {},
    params,
    pathParams: { contractId },
  });

export { changePaymentMethodService, getRevolvingOptionsService };
