// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const CreditHeaderComponentStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingL};
    padding: 0 ${({ theme }) => theme.spaces.spacingXxl};
  }

  ${mobileMediaQuery} {
    flex-direction: column;
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
CreditHeaderComponentStyled.displayName = 'CreditHeaderComponentStyled';

const CreditHeaderCreditComponentStyled = styled.div`
  flex: 1;
  max-width: ${convertPxsToRems({ pixels: 475, base: 14 })};

  ${tabletMediaQuery} {
    max-width: 100%;
    width: 100%;
  }
`;
CreditHeaderCreditComponentStyled.displayName = 'CreditHeaderCreditComponentStyled';

export { CreditHeaderComponentStyled, CreditHeaderCreditComponentStyled };
