// Vendors
import React from 'react';
// Components
import { InputDropdownComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import SupportCancelContractModalComponent from '../modal/support-cancel-contract-modal.component';
import SupportCancelContractFirstStepActionsComponent from './actions/support-cancel-contract-first-step-actions.component';
// Constants
import {
  CANCEL_CONTRACT_DROPDOWN_PROPS,
  CANCEL_CONTRACT_PARAGRAPH_01_PROPS,
  CANCEL_CONTRACT_PARAGRAPH_02_PROPS,
} from './constants/support-cancel-contract-first-step-dropdown.constants';
// Translations
import translations from '../../translations/support-cancel-contract.translations';
// Hooks
import SupportCancelContractFirstStepHook from './hooks/support-cancel-contract-first-step.hook';
// Styled
import { SupportCancelContractFirstStepInputDropdownStyled } from './support-cancel-contract-first-step.component.styled';
// Utils
import { getReasonOptions } from '../../utils/support-cancel-contract.utils';

const SupportCancelContractFirstStep = (): React.ReactElement => {
  const {
    formatMessage,
    handleDropdownInputClickTrackingHandler,
    handleOnConfirmCancelContractEventHandler,
    handleSelectReasonCancelContractEventHandler,
    handleShowModalCancelContractEventHandler,
    reason,
    showModal,
    isIosDevice,
  } = SupportCancelContractFirstStepHook();

  return (
    <>
      <ParagraphComponent {...CANCEL_CONTRACT_PARAGRAPH_01_PROPS}>
        <FormattedMessageComponent id={translations.DROPDOWN_PLACHOLDER_TITLE} />
      </ParagraphComponent>
      <ParagraphComponent bold {...CANCEL_CONTRACT_PARAGRAPH_02_PROPS}>
        <FormattedMessageComponent id={translations.DROPDOWN_PLACHOLDER_DESCRIPTION} />
      </ParagraphComponent>
      <SupportCancelContractFirstStepInputDropdownStyled>
        <InputDropdownComponent
          {...CANCEL_CONTRACT_DROPDOWN_PROPS}
          placeholder={formatMessage({ id: translations.DROPDOWN_PLACHOLDER })}
          value={reason}
          isIosDevice={isIosDevice}
          options={getReasonOptions(formatMessage)}
          onClick={handleDropdownInputClickTrackingHandler}
          onSelectOption={handleSelectReasonCancelContractEventHandler}
        />
      </SupportCancelContractFirstStepInputDropdownStyled>
      <SupportCancelContractModalComponent
        cancelClick={handleShowModalCancelContractEventHandler}
        onConfirm={handleOnConfirmCancelContractEventHandler}
        showModal={showModal}
      />
      <SupportCancelContractFirstStepActionsComponent />
    </>
  );
};

export default SupportCancelContractFirstStep;
