// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Event handlers
import { TransactionsEppModalHandlers } from '../handlers/transactions-epp-modal.handlers';
import { TransactionsEppModalTrackingHandlers } from '../handlers/transactions-epp-modal.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsEppModalHookType } from './types/transactions-epp-modal.hook.type';

const useTransactionsEppModalHook = (): TransactionsEppModalHookType => {
  const { showAmortizationTableModal, setShowAmortizationTableModal } =
    useTransactionsEppContextConsumerHook();

  return {
    ...TransactionsEppModalHandlers({
      ...AppTrackingHook(TransactionsEppModalTrackingHandlers),
      setShowAmortizationTableModal,
    }),
    showAmortizationTableModal,
  };
};

export { useTransactionsEppModalHook };
