// Vendors
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import { useCardSettingsChangePinContextConsumerHook } from 'containers/card-settings/views/change-pin/contexts/card-settings-change-pin.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Events handlers
import CardSettingsChangePinActionsTrackingHandlers from '../handlers/card-settings-change-pin-actions.tracking.handlers';
import CardSettingsChangePinActionsHandlers from '../handlers/card-settings-change-pin-actions.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChangePinCardHookType } from './types/card-settings-change-pin-card.hook.type';

const useCardSettingsChangePinActionsHook = (): CardSettingsChangePinCardHookType => {
  const [fetching, setFetching] = useState<boolean>(false);
  const navigate = useNavigate();

  const { currentCard } = AppContextConsumerHook(),
    { id: cardId } = currentCard || {};

  const {
    challenged,
    newPin,
    progressId,
    repeatedPin,
    setChallengeError,
    setSolvedChallenged,
    solvedChallenged,
  } = useCardSettingsChangePinContextConsumerHook();

  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  const { setResult } = useWizardFeedbackContextConsumerHook();

  return {
    ...CardSettingsChangePinActionsHandlers({
      ...AppTrackingHook(CardSettingsChangePinActionsTrackingHandlers),
      cardId,
      navigate,
      newPin,
      onNavigateHybridFlow,
      progressId,
      setChallengeError,
      setFetching,
      setResult,
      setSolvedChallenged,
    }),
    fetching,
    challenged,
    newPin,
    repeatedPin,
    solvedChallenged,
  };
};

export default useCardSettingsChangePinActionsHook;
