// Vendors
import { ChatContext } from '@openbank/cb-ui-commons';
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';
import styled, { css } from 'styled-components';
// Utils
import { convertPxsToRems } from 'utils/styles.utils';

const headerHeight = convertPxsToRems(48);
const headerHeightDesktop = convertPxsToRems(64);

export const ChatContextStyled = styled(ChatContext)`
  ${({theme: {spaces}}) => css`
    max-height: calc(100% - (${spaces.spacingM} * 2) - ${headerHeightDesktop});

    ${mobileMediaQuery} {
      max-height: calc(100% - ${headerHeight});
    }

    ${tabletMediaQuery} {
      max-height: calc(100% - (${spaces.spacingM} * 2) - ${headerHeight});
    }
  `}
`;

ChatContextStyled.displayName = 'ChatContextStyled';
