// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { SliderComponent } from '@openbank/cb-ui-commons';
// Constants
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_ALL_SLIDER_PROPS } from './constants/transactions-epp-step1-simulation-options-all-slider.constants';
// Hooks
import { useTransactionsEppStep1SimulationOptionsAllHook } from './hooks/transactions-epp-step1-simulation-options-all-slider.hook';
// Translations
import {
  MAX,
  MIN,
  NUMBER_INSTALLMENTS,
} from './translations/transactions-epp-step1-simulation-options-all-slider.translations';
// Types
import { TransactionsEppStep1SimulationOptionsAllSliderComponentType } from './types/transactions-epp-step1-simulation-options-all-slider.component.type';
// Utilities
import { getSimulationAllOptionsErrorMessage } from './utils/transactions-epp-step1-simulation-options-all-slider.utils';

const TransactionsEppStep1SimulationOptionsAllSliderComponent = (
  props: TransactionsEppStep1SimulationOptionsAllSliderComponentType
): React.ReactElement | null => {
  const {
    formatMessage,
    handleChangeAllOptionsSliderChangeEvent,
    installments: defaultValue,
    isAllOptionsSelected,
    maxNumOfInstallments: max,
    minNumOfInstallments: min,
  } = useTransactionsEppStep1SimulationOptionsAllHook(props);

  return isAllOptionsSelected ? (
    <SliderComponent
      {...{ ...TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_ALL_SLIDER_PROPS, defaultValue, min, max }}
      error={getSimulationAllOptionsErrorMessage({ defaultValue, formatMessage, min, max })}
      leftHelperText={formatMessage({ id: MIN }, { min })}
      onChange={handleChangeAllOptionsSliderChangeEvent}
      onTouchEnd={handleChangeAllOptionsSliderChangeEvent}
      rightHelperText={formatMessage({ id: MAX }, { max })}
      sliderTitle={formatMessage({ id: NUMBER_INSTALLMENTS })}
    />
  ) : null;
};

export { TransactionsEppStep1SimulationOptionsAllSliderComponent };
