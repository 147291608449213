// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Components
import PersonalPhotoNameButtonUploadModalPreviewComponent from './components/preview/personal-photo-name-button-upload-modal-preview.component';
import PersonalPhotoNameButtonUploadModalButtonsComponent from './components/buttons/personal-photo-name-button-upload-modal-buttons.component';
// Constants
import {
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTON_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_DESCRIPTION_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_LINK_PROPS,
} from './constants/personal-photo-name-button-upload-modal.constants';
// Components
import { ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Hooks
import usePersonalPhotoNameButtonUploadModalHook from './hooks/personal-photo-name-button-upload-modal.hook';
// Styles
import { PersonalPhotoNameButtonUploadModalComponentStyled } from './personal-photo-name-button-upload-modal.component.styled';
// Translations
import {
  APPLY,
  CANCEL,
  DESCRIPTION,
  TITLE,
} from './translations/personal-photo-name-button-upload-modal.translations';
// Types
import { PersonalPhotoNameButtonUploadModalComponentType } from './types/personal-photo-name-button-upload-modal.component.type';
// Utilities
import { isCropModalShown } from './utils/personal-photo-name-button-upload-modal.utils';

const PersonalPhotoNameButtonUploadModalComponent = ({
  onRetry,
}: Pick<PersonalPhotoNameButtonUploadModalComponentType, 'onRetry'>): React.ReactElement | null => {
  const {
    handleApplyCropPhotoButtonClick,
    handleCancelCropPhotoLinkClick,
    handleCloseCropUploadedPhotoModalAssetClick,
    photoModal,
  } = usePersonalPhotoNameButtonUploadModalHook();

  return isCropModalShown(photoModal) ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      linkConfiguration={{
        ...PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_LINK_PROPS,
        children: <FormattedMessageComponent id={CANCEL} />,
        onClick: handleCancelCropPhotoLinkClick,
      }}
      primaryButtonConfiguration={{
        ...PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={APPLY} />,
        onClick: handleApplyCropPhotoButtonClick,
      }}
      onHeaderClose={handleCloseCropUploadedPhotoModalAssetClick}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <PersonalPhotoNameButtonUploadModalComponentStyled>
        <ParagraphComponent {...PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
        <PersonalPhotoNameButtonUploadModalPreviewComponent />
        <PersonalPhotoNameButtonUploadModalButtonsComponent {...{ onRetry }} />
      </PersonalPhotoNameButtonUploadModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export default PersonalPhotoNameButtonUploadModalComponent;
