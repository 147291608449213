// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

export const EditableInfoPanelConstants = {
  EDIT_ASSET_PROPS: {
    assetConfiguration: {
      id: 'edit',
      sizes: {
        height: 16,
        width: 16,
      },
      colors: {
        fill: 'colorLinkPrimaryA',
      },
      testId: 'Info-panel-edit-asset',
    },
    iconOnLeft: true,
  },
  testId: 'editable-info-panel',
} as const;
