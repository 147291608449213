// Configurations
import {
  getLinkTypeTrackingConfiguration,
  getViewTypeTrackingConfiguration,
} from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
// Constants
import constants from './constants/step-2-credit-limit-increase.tracking.constants';
// Types
import { Step2CreditIncreaseLimitTrackingHandlersType } from './types/step-2-credit-limit-increase.tracking.type';

export const Step2CreditLimitChageTracking = (
  track: (param: unknown) => void
): Step2CreditIncreaseLimitTrackingHandlersType => ({
  handleEnterViewTracking: () => track(getViewTypeTrackingConfiguration(constants.VIEW)),
  handleClickPreviousButtonTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.CLICK_PREVIOUS_BUTTON)),
  /* Personal Situation */
  handleSelectMaritalStatusTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.PERSONAL_SITUATION.MARITAL_STATUS)),
  handleClickChildrenInHouseholdTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(
        constants.LINK.PERSONAL_SITUATION.CLICK_CHILDREN_IN_HOUSEHOLD
      )
    ),
  handleSelectChildrenInHouseholdTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(
        constants.LINK.PERSONAL_SITUATION.SELECT_CHILDREN_IN_HOUSEHOLD
      )
    ),
  handleClickChildrenWithMaintenanceTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(
        constants.LINK.PERSONAL_SITUATION.CLICK_CHILDREN_WHOM_YOU_PAY
      )
    ),
  handleSelectChildrenWithMaintenanceTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(
        constants.LINK.PERSONAL_SITUATION.SELECT_CHILDREN_WHOM_YOU_PAY
      )
    ),
  handleOpenChildrenWithMaintenanceTooltipTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(
        constants.LINK.PERSONAL_SITUATION.OPEN_CHILDREN_WHOM_YOU_PAY_TOOLTIP
      )
    ),
  /* Employment and Iconme data */
  handleSelectEmploymentStatusTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.EMPLOYMENT_DATA.EMPLOYMENT_STATUS)),
  handleClickMonthJoinedCompanyTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.EMPLOYMENT_DATA.CLICK_MONTH_JOIN_COMPANY)
    ),
  handleSelectMonthJoinedCompanyTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.EMPLOYMENT_DATA.SELECT_MONTH_JOIN_COMPANY)
    ),
  handleClickYearJoinedCompanyTracking: () =>
    track(getLinkTypeTrackingConfiguration(constants.LINK.EMPLOYMENT_DATA.CLICK_YEAR_JOIN_COMPANY)),
  handleSelectYearJoinedCompanyTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.EMPLOYMENT_DATA.SELECT_YEAR_JOIN_COMPANY)
    ),
  handleClickAproximateMonthlySalaryTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.ICONME.CLICK_APROXIMATE_MONTHLY_SALARY_INPUT)
    ),
  handleOpenAproximateMonthlySalaryTooltipTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.ICONME.OPEN_APROXIMATE_MONTHLY_SALARY_TOOLTIP)
    ),
  /* Expenses */
  handleClickSpendMonthlyOnRentTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.EXPENSES.CLICK_SPEND_MONTHLY_ON_RENT_INPUT)
    ),
  handleClickSpendMonthlyOnLoansTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(constants.LINK.EXPENSES.CLICK_SPEND_MONTHLY_ON_LOANS_INPUT)
    ),
  handleClickSpendMonthlyOnMortgagesTracking: () =>
    track(
      getLinkTypeTrackingConfiguration(
        constants.LINK.EXPENSES.CLICK_SPEND_MONTHLY_ON_MORTGAGES_INPUT
      )
    ),
});

export default Step2CreditLimitChageTracking;
