// Types
import { UpdateStep2HandlerPropsType } from './types/update-step-2-props.component.handler.type';
import { ExpensesCreditLimitIncreaseHandlersReturnType } from './types/expenses-credit-limit-increase-return.component.handler.type';
// Utils
import { setEmploymentData } from 'containers/financing/views/credit-limit-increase/components/step2/utils/step-2-credit-limit-increase.utils';

const updateStep2Handler = ({ step2, setStep2 }: UpdateStep2HandlerPropsType): void => {
  const { monthlyRentalExpense, monthlyLoanExpense, monthlyMortagageExpense } =
    step2.personalData?.employments?.[0] || {};
  const isExpensesReady =
    Boolean(monthlyRentalExpense?.toString()) &&
    Boolean(monthlyLoanExpense?.toString()) &&
    Boolean(monthlyMortagageExpense?.toString());

  if (step2.isExpensesReady !== isExpensesReady) {
    setStep2?.({ ...step2, isExpensesReady });
  }
};

const ExpensesCreditLimitIncreaseHandlers = ({
  step2,
  setStep2,
}: UpdateStep2HandlerPropsType): ExpensesCreditLimitIncreaseHandlersReturnType => ({
  handleUpdateStep2: () =>
    updateStep2Handler({
      step2,
      setStep2,
    }),
  handleChangeMonthlyRentalExpense: (value: string) => {
    setEmploymentData({
      setStep2,
      step2,
      values: { monthlyRentalExpense: value },
    });
  },
  handleChangeMonthlyLoanExpense: (value: string) => {
    setEmploymentData({
      setStep2,
      step2,
      values: { monthlyLoanExpense: value },
    });
  },
  handleChangeMonthlyMortagageExpense: (value: string) => {
    setEmploymentData({
      setStep2,
      step2,
      values: { monthlyMortagageExpense: value },
    });
  },
});

export default ExpensesCreditLimitIncreaseHandlers;
