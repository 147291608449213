// Translations
import translations from '../translations/notifications-landing-content.translations';

const ERROR_CONFIGURATION_PROPS = {
  assetId: 'Empty_No_Result',
  translations: {
    ERROR_TITLE: translations.ERROR_TITLE,
    ERROR_DESCRIPTION: translations.ERROR_DESCRIPTION,
  },
};
const NOTIFICATION_TEST_ID_PROPS = {
  containerId: 'container-notification-id',
  dateContainerId: 'date-container-notification-id',
  textId: 'text-notification-id',
  titleId: 'title-notification-id',
};

export { ERROR_CONFIGURATION_PROPS, NOTIFICATION_TEST_ID_PROPS };
