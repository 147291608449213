const BACK_BUTTON_PAYLOAD = {
  eventAction: 'signedCardLess48hModal',
  eventLabel: 'goCardSettingsButton',
};

const CLOSE_BUTTON_PAYLOAD = {
  eventAction: 'signedCardLess48hModal',
  eventLabel: 'close',
};

export { BACK_BUTTON_PAYLOAD, CLOSE_BUTTON_PAYLOAD };
