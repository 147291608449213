// Vendors
import React from 'react';
// Components
import { ChallengeDownloadWrapperComponent } from 'components/challenge-download-wrapper/challenge-download-wrapper.component';
// Types
import { SecurityCheckModalSecondStepComponentType } from './types/security-check-modal-second-step.type';
// Styles
import { SecurityCheckModalSecondStepComponentStyled } from './security-check-modal-second-step.component.styled';

const SecurityCheckModalSecondStepComponent = ({
  challenge,
  challenged,
  challengeError,
  otpId,
  otpValue,
  setChallenge,
  setChallenged,
  setChallengeError,
  setIsDownloadAvailable,
  setOtpId,
  setOtpValue,
}: SecurityCheckModalSecondStepComponentType): React.ReactElement => (
  <SecurityCheckModalSecondStepComponentStyled>
    <ChallengeDownloadWrapperComponent
      {...{
        challenge,
        challenged,
        challengeError,
        otpId,
        otpValue,
        setChallenge,
        setChallenged,
        setChallengeError,
        setIsDownloadAvailable,
        setOtpId,
        setOtpValue,
      }}
    />
  </SecurityCheckModalSecondStepComponentStyled>
);

export { SecurityCheckModalSecondStepComponent };
