// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useRecoveryKeyStep5ContextConsumerHook } from 'containers/recovery-key/components/step5/contexts/recovery-key-step5.context';
// Types
import { RecoveryKeyStep5ConstraintsHookType } from './types/recovery-key-step5-constraints.hook.type';

const useRecoveryKeyStep5ConstraintsHook = (): RecoveryKeyStep5ConstraintsHookType => {
  const { formatMessage } = useCbIntl();

  const { recoveryKey, setRecoveryKey } = useRecoveryKeyStep5ContextConsumerHook();

  return {
    formatMessage,
    recoveryKey,
    setRecoveryKey,
  };
};

export default useRecoveryKeyStep5ConstraintsHook;
