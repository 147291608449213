// Enums
import { RequestEmailInputErrorEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Types
import { RequestPortabilityStep2EmailOptionsReturnHandlersType } from './types/request-portability-step2-email-options-return.handlers.type';
import { AccessPortabilityStep2EmailOptionsHandlersType } from './types/request-portability-step2-email-options.handlers.type';
// Utils
import { isEmptyField } from '../utils/request-portability-step2-email-options.utils';
import { isValidEmail } from 'utils/email.utils';

const emailOptionChangeHandler = ({ value, setEmailCheckboxValue }) => {
  setEmailCheckboxValue(value);
};

const validateEmailInputEvent = ({
  emailValue,
  setError,
}: Omit<
  AccessPortabilityStep2EmailOptionsHandlersType,
  'setEmailValue' | 'setEmailCheckboxValue'
>) => {
  if (isEmptyField(emailValue)) {
    setError(RequestEmailInputErrorEnumeration.EMPTY);
  } else if (!isValidEmail(emailValue)) {
    setError(RequestEmailInputErrorEnumeration.FORMAT);
  } else {
    setError(null);
  }
};

const saveEmailInputEvent = ({
  emailValue,
  setError,
  setEmailValue,
}: Omit<AccessPortabilityStep2EmailOptionsHandlersType, 'setEmailCheckboxValue'>) => {
  setError(null);
  setEmailValue(emailValue);
};

const RequestPortabilityStep2EmailOptionsHandlers = ({
  setEmailCheckboxValue,
  setEmailValue,
  setError,
  emailValue,
}: AccessPortabilityStep2EmailOptionsHandlersType): RequestPortabilityStep2EmailOptionsReturnHandlersType => ({
  handleEmailOptionChange: ({ value }) =>
    emailOptionChangeHandler({ value, setEmailCheckboxValue }),
  handleValidateEmailInputEvent: () =>
    validateEmailInputEvent({
      emailValue,
      setError,
    }),
  handleSaveEmailInputEvent: (emailValue: string) =>
    saveEmailInputEvent({ emailValue, setError, setEmailValue }),
});

export default RequestPortabilityStep2EmailOptionsHandlers;
