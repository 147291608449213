// Vendors
import { SetStateType } from '@openbank/cf-ui-static-data';
// Handlers
import { TransactionsReportServiceCancelledDocumentsActionsHandlers } from '../handlers/transactions-report-service-cancelled-documents-actions.handlers';
// Hooks
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Types
import { FileDataType } from 'types/file-data.type';
import { TransactionsReportSubscriptionCancelledDocumentsActionsHookType } from './types/transactions-report-service-cancelled-documents-actions-hook.types';

const useTransactionsReportServiceCancelledDocumentsActionsHooks = (
  setFiles: SetStateType<FileDataType[]>
): TransactionsReportSubscriptionCancelledDocumentsActionsHookType => {
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleGoBack } = TransactionsReportServiceCancelledDocumentsActionsHandlers({
    setFiles,
    setCurrentStep,
  });

  return {
    handleGoBack,
  };
};

export { useTransactionsReportServiceCancelledDocumentsActionsHooks };
