// Constants
import {
  ACCOUNT_PREFIX,
  ACCOUNT_SLICE,
  COMISSION_PERCENTAGE,
  DEFAULT_COMMISSION,
  DEFAULT_CURRENCY,
  DEFAULT_VALUE,
  FORMAT_NUMBER,
} from './constants/financing-fast-cash.utils.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Enumerations
import { Currency } from '../enumerations/financing-fast-cash.enumerations';
// Types
import { GetTotalCommissionPropsType } from './types/get-total-commission-props.type';
import { GetTotalAmountPropsType } from './types/get-total-amount-props.type';
import { GetCommissionPropsType } from './types/get-commission-props.type';

const getCurrency = (currencyCode = DEFAULT_CURRENCY): string =>
  Currency[currencyCode] || Currency[DEFAULT_CURRENCY];

const getFormattedAccountNumber = (accountNumber: string): string =>
  `${ACCOUNT_PREFIX}${accountNumber?.slice(ACCOUNT_SLICE)}`;

const getTotalAmount = ({
  showValues,
  formatNumber,
  amountValue,
  currency,
}: GetTotalAmountPropsType): string => {
  const currencySymbol = getCurrency(currency);

  return showValues && amountValue
    ? formatNumber(amountValue, {
        currency,
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
      })
    : DEFAULT_VALUE + currencySymbol;
};

const getCommission = ({ showValues, formatNumber, commission }: GetCommissionPropsType): string =>
  showValues && commission != null
    ? formatNumber(commission / COMISSION_PERCENTAGE, {
        style: 'percent',
        ...FORMAT_NUMBER.COMMISSION,
      })
    : DEFAULT_COMMISSION;

const getTotalCommission = ({
  showValues,
  formatNumber,
  amountValue,
  commission,
  currency,
}: GetTotalCommissionPropsType): string =>
  showValues && amountValue && commission != null
    ? formatNumber((amountValue * commission) / COMISSION_PERCENTAGE, {
        style: 'currency',
        currency,
      })
    : DEFAULT_VALUE + getCurrency(currency);

const getAccountIdWithoutWhiteSpace = (accountId: string): string => {
  return accountId && accountId.length > 1 ? accountId.replace(/\s/g, '') : accountId;
};

export {
  getCommission,
  getCurrency,
  getFormattedAccountNumber,
  getAccountIdWithoutWhiteSpace,
  getTotalAmount,
  getTotalCommission,
};
