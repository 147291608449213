// Themes
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Enums
import { RequestEmailOptionEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Translations
import translations from '../translations/request-portability-step2-email-options.translations';

export default {
  EMAIL_OPTIONS: [
    {
      title: translations.YOUR_ACCOUNT_EMAIL_RADIO_TITLE,
      testId: 'request-portability-step2-your-email-option',
      value: RequestEmailOptionEnumeration.YOUR_EMAIL,
    },
    {
      title: translations.OTHER_EMAIL_RADIO_TITLE,
      testId: 'request-portability-step2-other-email-option',
      value: RequestEmailOptionEnumeration.OTHER_EMAIL,
    },
  ],
  TITLE_EMAIL_TO_SEND_PROPS: {
    bold: true,
    tag: titleConstants.TAGS.H6,
    testId: 'request-portability-email-to-send-title',
  },
  EMAIL_TO_SEND_INPUT_PROPS: {
    testId: 'request-portability-email-to-send-email',
  },
};
