// Enumerations
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';
// Constants
import {
  CARD_SETTINGS_QUICK_ACTIONS,
  DASHBOARD_QUICK_ACTIONS,
  FINANCING_QUICK_ACTIONS,
} from './quick-actions-configurations.constants';

const QUICK_ACTIONS_SECTION_MAPPER = {
  [QuickActionsSectionEnumeration.DASHBOARD]: DASHBOARD_QUICK_ACTIONS,
  [QuickActionsSectionEnumeration.FINANCING]: FINANCING_QUICK_ACTIONS,
  [QuickActionsSectionEnumeration.SETTINGS]: CARD_SETTINGS_QUICK_ACTIONS,
};

const SECONDARY = 'SECONDARY';
const PRIMARY = 'PRIMARY';

export { QUICK_ACTIONS_SECTION_MAPPER, FINANCING_QUICK_ACTIONS, SECONDARY, PRIMARY };
