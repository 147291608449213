// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AmortizationModalComponent } from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/components/amortizationModal/amortization-modal.component';
import { ModalComponent } from '@openbank/cb-ui-commons';
// Constants
import { AMMORTIZATION_MODAL_CONSTANTS } from 'containers/financing/views/landing/components/row/components/details/components/amortizationTable/constants/financing-landing-row-details-amortization-table.constants';
import { TRANSACTIONS_EPP_MODAL_PROPS } from './constants/transactions-epp-modal.constants';
// Hooks
import { useTransactionsEppModalHook } from './hooks/transactions-epp-modal.hook';
// Translations
import {
  AMMORTIZATION_MODAL_CLOSE_BUTTON,
  AMORTIZATION_TABLE,
} from './translations/transactions-epp-modal.translations';

const TransactionsEppModalComponent = (): React.ReactElement | null => {
  const { handleToggleAmortizationTableModalLinkClick, installments, showAmortizationTableModal } =
    useTransactionsEppModalHook();

  return showAmortizationTableModal ? (
    <ModalComponent
      {...TRANSACTIONS_EPP_MODAL_PROPS}
      onHeaderClose={handleToggleAmortizationTableModalLinkClick}
      title={<FormattedMessageComponent id={AMORTIZATION_TABLE} />}
      primaryButtonConfiguration={{
        children: <FormattedMessageComponent id={AMMORTIZATION_MODAL_CLOSE_BUTTON} />,
        onClick: handleToggleAmortizationTableModalLinkClick,
        tesId: AMMORTIZATION_MODAL_CONSTANTS.CLOSE_TEST_ID,
      }}
    >
      <AmortizationModalComponent {...{ installments }} />
    </ModalComponent>
  ) : null;
};

export { TransactionsEppModalComponent };
