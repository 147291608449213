// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Translations
import translations from '../translations/expenses-credit-limit-increase.component.translations';
// Types
import { ExpensesCreditLimitIncreaseConfigurationPropsType } from './types/expenses-credit-limit-increase.configuration.type';
import { InfoPanelContentType } from 'containers/financing/views/credit-limit-increase/components/editable-info-panel/types/info-panel-content.type';

export const getInfoPanelContentConfig = ({
  monthlyRentalExpense,
  monthlyLoanExpense,
  monthlyMortagageExpense,
  formatMessage,
  formatNumber,
  currency,
}: ExpensesCreditLimitIncreaseConfigurationPropsType): InfoPanelContentType[] => {
  if (!monthlyRentalExpense || !monthlyLoanExpense || !monthlyMortagageExpense) return [];
  return [
    {
      title: formatMessage({ id: translations.MONTHLY_EXPENSES_ON_RENT }),
      text: formatNumber(Number(monthlyRentalExpense), {
        currency,
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
      }),
    },
    {
      title: formatMessage({ id: translations.MONTHLY_EXPENSES_ON_LOANS }),
      text: formatNumber(Number(monthlyLoanExpense), {
        currency,
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
      }),
    },
    {
      title: formatMessage({ id: translations.MONTHLY_EXPENSES_ON_MORTGAGES }),
      text: formatNumber(Number(monthlyMortagageExpense), {
        currency,
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
      }),
    },
  ];
};
