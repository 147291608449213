// Utils
import { removeAccents } from 'utils/remove-accent.utils';

const filterByKeyword = (searchList: string, keyword: string): boolean => {
  const normalizedContinentDescription = removeAccents(searchList.toLowerCase());
  const normalizedKeyword = removeAccents(keyword.toLowerCase());

  return normalizedContinentDescription.includes(normalizedKeyword);
};

export { filterByKeyword };
