// Vendors
import React from 'react';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterCancelModalComponent from './components/cancel-modal/wizard-step-footer-cancel-modal.component';
// Configurations
import { getWizardStepFooterConfiguration } from './configurations/wizard-step-footer.component.configuration';
// Constants
import {
  DISABLED_DEFAULT_VALUE,
  HAS_LEFT_LINK_DEFAULT_VALUE,
  HAS_LEFT_BUTTON_DEFAULT_VALUE,
  HAS_RIGHT_BUTTON_DEFAULT_VALUE,
  SHOW_MODAL_DEFAULT_VALUE,
} from './constants/wizard-step-footer.constants';
// Hooks
import WizardStepFooterHook from './hooks/wizard-step-footer.hook';
// Types
import { WizardStepFooterComponentType } from 'components/wizard-step-footer/types/wizard-step-footer.component.type';

const WizardStepFooterComponent = ({
  hasDefaultLeftLink = HAS_LEFT_LINK_DEFAULT_VALUE,
  hasDefaultLeftButton = HAS_LEFT_BUTTON_DEFAULT_VALUE,
  hasDefaultRightButton = HAS_RIGHT_BUTTON_DEFAULT_VALUE,
  isFooterDisabled = DISABLED_DEFAULT_VALUE,
  leftButtonConfiguration,
  leftLinkConfiguration,
  rightButtonConfiguration,
  rightLinkConfiguration,
  cancelModal = { disabledButtons: DISABLED_DEFAULT_VALUE, showModal: SHOW_MODAL_DEFAULT_VALUE },
  shouldShowModal = true,
  targets,
}: WizardStepFooterComponentType): React.ReactElement => {
  const { setShowModal, showModal } = cancelModal;
  const {
    cancelFlow,
    formatMessage,
    handleLeftEventButtonClick,
    handleRightEventButtonClick,
    handleToggleCancelFlowModalLinkClick,
    showCancelModal,
  } = WizardStepFooterHook({ showModal, setShowModal, targets });

  return (
    <>
      <NavigationFooterComponent
        {...getWizardStepFooterConfiguration({
          cancelFlow,
          formatMessage,
          handleLeftEventButtonClick,
          handleRightEventButtonClick,
          handleToggleCancelFlowModalLinkClick,
          hasDefaultLeftButton,
          hasDefaultLeftLink,
          hasDefaultRightButton,
          isFooterDisabled,
          leftButtonConfiguration,
          leftLinkConfiguration,
          rightButtonConfiguration,
          rightLinkConfiguration,
          shouldShowModal,
        })}
      />
      <WizardStepFooterCancelModalComponent
        {...{
          cancelUrl: cancelModal?.cancelUrl,
          cancelModalButtonClick: cancelModal?.clicks?.buttonClick,
          cancelModalLinkClick: cancelModal?.clicks?.linkClick,
          cancelModalTranslations: cancelModal?.translations,
          disabledButtonsCancelModal: cancelModal?.disabledButtons,
        }}
        onClose={handleToggleCancelFlowModalLinkClick}
        showModal={showCancelModal}
      />
    </>
  );
};

export default WizardStepFooterComponent;
