// Translations
import {
  ILLNESS,
  INCAPACITY,
  UNEMPLOYMENT,
} from '../translations/financing-insurances-contracting-lpi-additional-limitations.translations';

const FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_PROPS = {
    ordered: true,
    testId: 'financing-lpi-contracting-step1-expandable-limitations',
  },
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_ITEMS = {
    items: [
      {
        label: INCAPACITY.title,
        subItems: [INCAPACITY.firstLimitation, INCAPACITY.secondLimitation],
      },
      {
        label: UNEMPLOYMENT.title,
        subItems: [ UNEMPLOYMENT.firstLimitation, UNEMPLOYMENT.secondLimitation],
      },
      {
        label: ILLNESS.title,
        subItems: [ILLNESS.firstLimitation],
      },
    ],
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_COVERAGE_LIMITATIONS_LIST_ITEMS,
};
