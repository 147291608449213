// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const FinancingPPICancellationLandingResumeComponentStyled = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.backgroundTertiaryE};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingL};
  padding: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;
FinancingPPICancellationLandingResumeComponentStyled.displayName =
  'FinancingPPICancellationLandingResumeComponentStyled';

export { FinancingPPICancellationLandingResumeComponentStyled };
