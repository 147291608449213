// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { CONTEXT_DEFAULT_VALUE } from './constants/financing-payment-method.context.constants';
// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
import { PaymentMethodsEnumeration } from '../enumerations/payment-methods.enumeration';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { FinancingPaymentMethodContextType } from './types/financing-payment-method.context.type';
import { ChallengeType } from 'types/challenge.type';
import { RevolvingMethodsEnumeration } from '../enumerations/revolving-methods.enumeration';
import { RevolvingOptionsType } from '../types/revolving-options.type';
import { PaymentMethodParamsType } from '../types/payment-method-params.type';

const FinancingPaymentMethodContext =
  createContext<FinancingPaymentMethodContextType>(CONTEXT_DEFAULT_VALUE);

export const FinancingPaymentMethodContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FinancingPaymentMethodContextType>> => {
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [fee, setFee] = useState<number | null>(null);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodsEnumeration | null>(null);
  const [paymentMethodParams, setPaymentMethodParams] = useState<PaymentMethodParamsType | null>(
    null
  );
  const [revolvingMethod, setRevolvingMethod] = useState<RevolvingMethodsEnumeration | null>(null);
  const [revolvingOptions, setRevolvingOptions] = useState<RevolvingOptionsType | null>(null);
  const setPaymentMethodWrapper = (paymentMethod: PaymentMethodsEnumeration | null): void => {
    setPaymentMethod(paymentMethod);
    setRevolvingMethod(null);
  };
  return (
    <FinancingPaymentMethodContext.Provider
      value={{
        challenge,
        challengeError,
        error,
        fee,
        paymentMethod,
        paymentMethodParams,
        revolvingMethod,
        revolvingOptions,
        setChallenge,
        setChallengeError,
        setError,
        setFee,
        setPaymentMethod: setPaymentMethodWrapper,
        setPaymentMethodParams,
        setRevolvingMethod,
        setRevolvingOptions,
      }}
    >
      {children as React.ReactNode}
    </FinancingPaymentMethodContext.Provider>
  );
};

export const FinancingPaymentMethodContextConsumerHook = (): FinancingPaymentMethodContextType =>
  useContext(FinancingPaymentMethodContext);

/* eslint-disable */
export const FinancingPaymentMethodContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingPaymentMethodContextType>> =>
    (
      <FinancingPaymentMethodContextProvider>
        <Component {...props} />
      </FinancingPaymentMethodContextProvider>
    );
