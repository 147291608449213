// Components
import TransactionsLandingRowComponent from '../components/row/transactions-landing-row.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Resolvers
import { fetchCardsTransactionsResolver } from '../resolvers/transactions-landing.resolver';
// Translations
import translations from '../translations/transactions-landing.translations';

export default {
  TRANSACTION_LANDING_TABLE_PROPS: {
    resolver: fetchCardsTransactionsResolver,
    rowComponent: TransactionsLandingRowComponent,
    id: { idName: 'id', groupId: 'date', idTable: 'transactions' },
  },
  TRANSACTION_LANDING_CARD_STATUS_PROPS: {
    activateCardRedirection: {
      linkLabel: translations.TITLE,
      route: RoutesEnumeration.TRANSACTIONS,
    },
    breadCrumbPath: RoutesEnumeration.TRANSACTIONS,
  },
};
