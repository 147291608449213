const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.cbModal.';

const MODAL_TITLE = `${DOMAIN}title`,
  DESCRIPTION_01 = `${DOMAIN}description01`,
  DESCRIPTION_02 = `${DOMAIN}description02`,
  DESCRIPTION_03 = `${DOMAIN}description03`,
  BUTTON = `${DOMAIN}button`,
  LINK = `${DOMAIN}link`;

export { MODAL_TITLE, DESCRIPTION_01, DESCRIPTION_02, DESCRIPTION_03, BUTTON, LINK };
