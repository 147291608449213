// Vendors
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// Contexts
import { SecurityKeysSignatureKeyChangeContextConsumerHook } from '../contexts/security-keys-signature-key-change.context';
// Handlers
import { SecurityKeysSignatureKeyChangeHandlers } from '../handlers/security-keys-signature-key-change.handlers';

const SecurityKeysSignatureKeyChangeHook = (): { fromPersonal: boolean } => {
  const [ searchParams ] = useSearchParams();
  const { fromPersonal, setFromPersonal } = SecurityKeysSignatureKeyChangeContextConsumerHook();
  const { userEnterHandler } = SecurityKeysSignatureKeyChangeHandlers({ searchParams, setFromPersonal });

  useEffect(() => { userEnterHandler(); }, []);

  return { fromPersonal };
};

export { SecurityKeysSignatureKeyChangeHook };
