const PERSONAL_PHOTO_AVATARS_ITEM_CHECK_PROPS = {
  id: 'checkmark',
  sizes: {
    height: 10,
    width: 10,
  },
  testId: 'personal-photo-avatars-item-check',
};

export { PERSONAL_PHOTO_AVATARS_ITEM_CHECK_PROPS };
