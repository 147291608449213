// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { INITIAL_CONTEXT } from 'contexts/fetch-error/constants/fetch-error.context.constants';
// Types
import { FetchErrorContextType } from 'contexts/fetch-error/types/fetch-error.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const FetchErrorContext = createContext<FetchErrorContextType>(INITIAL_CONTEXT);

const FetchErrorContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FetchErrorContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<any>(false);

  return (
    <FetchErrorContext.Provider value={{ fetching, error, setFetching, setError }}>
      {children}
    </FetchErrorContext.Provider>
  );
};

const useFetchErrorContextConsumerHook = (): FetchErrorContextType => useContext(FetchErrorContext);

export { FetchErrorContextProvider, useFetchErrorContextConsumerHook };
