// Vendors
import React from 'react';
// Components
import SettingsCardControlSidebarComponent from './components/sidebar/settings-card-control-sidebar.component';
import SettingsCardControlTabsComponent from './components/tabs/settings-card-control-tabs.component';
// Contexts
import { SettingsCardControlContextConsumerHOC } from './contexts/settings-card-control.context';
// Styles
import {
  AppCardSectionComponentStyled,
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
} from 'styles/app.styled';

const SettingsCardControlView = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <AppCardSectionComponentStyled>
          <SettingsCardControlTabsComponent />
        </AppCardSectionComponentStyled>
      </LayoutGridContentLeftComponentStyled>
      <SettingsCardControlSidebarComponent />
    </LayoutGridContentComponentStyled>
  </LayoutGridComponentStyled>
);

export { SettingsCardControlView };
export default SettingsCardControlContextConsumerHOC(SettingsCardControlView);
