// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Enumerations
import { StorageLocalEnum } from '@openbank/cf-ui-static-data';
// Constants
import { LOCALIZATION_SERVICE_CONFIGURATION } from './constants/localization.service.constants';
// Types
import { LocalizationConfigurationType } from 'types/localization-configuration.type';

const fetchLocalizationConfigurationService = async (): Promise<
  [LocalizationConfigurationType | Record<string, any>, Response]
> =>
  apiUtilsCb.get({
    ...LOCALIZATION_SERVICE_CONFIGURATION,
    queryParams: {
      language: localStorage.getItem(StorageLocalEnum.LANGUAGE),
    },
  });

export { fetchLocalizationConfigurationService };
