// Constants
import {
  HEADER_INCOMPLETE_INDICATOR_KEY,
  HEADER_INCOMPLETE_INDICATOR_VALUE,
  HEADER_EXPIRED_ACCESS_KEY_KEY,
  HEADER_EXPIRED_ACCESS_KEY_VALUE
} from './constants/login-gaps.resolvers.utils.constant';
// Types
import { SssLoginPasswordPositionsType } from 'types/sss/login/sss-login-password-positions.type';
import { LoginGapsApiLoginReturnType } from 'containers/login/views/gaps/types/login-gaps-api-login-return.type';

const isValidPositionsContract = (
  response: SssLoginPasswordPositionsType | Record<string, any>
): boolean => Boolean(response?.nonce && response?.positions?.length > 0);

const isValidSessionContract = (
  response: LoginGapsApiLoginReturnType | Record<string, any>
): boolean => Boolean(response?.accessToken && response?.refreshToken);

const isOnboardingIncomplete = (headers: Headers): boolean => {
  const location = headers && headers.get(HEADER_INCOMPLETE_INDICATOR_KEY);

  return Boolean(location && location === HEADER_INCOMPLETE_INDICATOR_VALUE);
};

const isAccessKeyExpired = (headers: Headers): boolean => {
  const expired = headers && headers.get(HEADER_EXPIRED_ACCESS_KEY_KEY);

  return Boolean(expired && expired === HEADER_EXPIRED_ACCESS_KEY_VALUE);
};

export { isValidSessionContract, isValidPositionsContract, isOnboardingIncomplete, isAccessKeyExpired };
