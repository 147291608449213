// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Types
import { AccessPortabilityCardBuilderReturnHandlersType } from './types/access-portability-card-builder-return.handlers.type';
import { AccessPortabilityCardBuilderHandlersType } from './types/access-portability-card-builder.handlers.type';

const accessPortabilityRequestClickHandler = ({
  handleRightsRequestClickTracking,
  navigate,
  rightsType,
}: AccessPortabilityCardBuilderHandlersType) => {
  handleRightsRequestClickTracking();
  navigate(
    rightsType === AccessPortabilityTypeEnumeration.ACCESS
      ? RoutesEnumeration.ACCESS_REQUEST
      : RoutesEnumeration.PORTABILITY_REQUEST
  );
};

const AccessPortabilityCardHandlers = (
  props: AccessPortabilityCardBuilderHandlersType
): AccessPortabilityCardBuilderReturnHandlersType => ({
  handleAccessPortabilityRequestClick: () => accessPortabilityRequestClickHandler(props),
});

export default AccessPortabilityCardHandlers;
