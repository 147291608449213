const TABLE_ROW_ASSET_PROPS = {
  colors: {
    hover: 'linkPrimaryB',
  },
  testId: 'table-row-asset',
};

const TABLE_ROW_PROPS = {
  testId: 'table-row-',
};

export { TABLE_ROW_ASSET_PROPS, TABLE_ROW_PROPS };
