// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { PersonalNumberStep1RepeatPhoneBuilderReturnTrackingHandlersType } from './types/personal-number-step1-repeat-phone.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Constants
import {
  CLICK_DROPDOWN_TRACKING,
  CLICK_INPUT_TRACKING,
  REPEAT_PHONE_NUMBER_TRACKING,
  PREFIX_TRACKING,
  INVALID_PHONE_NUMBER_TRACKING,
} from './constants/personal-number-step1-repeat-phone.handlers.constants';

const savePersonalNumberStep1RepeatPrefixOptionClickEventHandler = (
  prefix: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_DROPDOWN_TRACKING,
    eventLabel: PREFIX_TRACKING + prefix,
  },
  type: TrackingEventsEnum.LINK,
});

const managePhoneNumberStep1InputErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: INVALID_PHONE_NUMBER_TRACKING,
    eventLabel: INVALID_PHONE_NUMBER_TRACKING,
    errorCategory: INVALID_PHONE_NUMBER_TRACKING,
    errorDescription: INVALID_PHONE_NUMBER_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const saveRecoveryStep1NumberInputClickEventTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_INPUT_TRACKING,
    eventLabel: REPEAT_PHONE_NUMBER_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalPhoneStep1PhoneTrackingHandlers = (
  track: TrackBuilderType
): PersonalNumberStep1RepeatPhoneBuilderReturnTrackingHandlersType => ({
  handleManagePersonalNumberStep1RepeatNumberInputErrorTracking: () =>
    track(managePhoneNumberStep1InputErrorTrackingHandler()),
  handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking: (prefix: string) =>
    track(savePersonalNumberStep1RepeatPrefixOptionClickEventHandler(prefix)),
  handleSavePersonalNumberStep1RepeatNumberInputClickEventTracking: () =>
    track(saveRecoveryStep1NumberInputClickEventTrackingHandler()),
});

export default PersonalPhoneStep1PhoneTrackingHandlers;
