const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.step3.';

const DOWNLOAD_DOCUMENT_BUTTON_LABEL = `${DOMAIN}downloadDocumentButtonLabel`;
const DOWNLOAD_DOCUMENT_TITLE = `${DOMAIN}downloadDocumentTitle`;
const NOT_DOWNLOADED_DOCUMENT_ERROR_MESSAGE = `${DOMAIN}notDownloadedDocumentErrorMessage`;
const DOWNLOAD_DOCUMENT_ERROR_MESSAGE = `${DOMAIN}downloadDocumentErrorMessage`;
const DOWNLOAD_DOCUMENT_SUCCESS_MESSAGE = `${DOMAIN}downloadDocumentSuccessMessage`;
const SEND_DOCUMENT_VIA_POST_CHECKBOX_LABEL = `${DOMAIN}sendDocumentViaPostCheckboxLabel`;

export {
  DOWNLOAD_DOCUMENT_BUTTON_LABEL,
  DOWNLOAD_DOCUMENT_TITLE,
  NOT_DOWNLOADED_DOCUMENT_ERROR_MESSAGE,
  DOWNLOAD_DOCUMENT_ERROR_MESSAGE,
  SEND_DOCUMENT_VIA_POST_CHECKBOX_LABEL,
  DOWNLOAD_DOCUMENT_SUCCESS_MESSAGE,
};
