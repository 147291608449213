const TRAVEL_PLUS_CANCEL_LINK_TRACKING_PROPS = {
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'noCancel',
    },
  },
  TRAVEL_PLUS_OPEN_MODAL_TRACKING_PROPS = {
    payload: {
      eventAction: 'areAboutSwitchTravelOpenCreditVisa',
      eventLabel: 'open',
    },
  },
  TRAVEL_PLUS_CLOSE_ASSET_TRACKING_PROPS = {
    payload: {
      eventAction: 'areAboutSwitchTravelOpenCreditVisa',
      eventLabel: 'close',
    },
  },
  TRAVEL_PLUS_CONFIRM_BUTTON_TRACKING_PROPS = {
    eventAction: 'clickButton',
  };

export {
  TRAVEL_PLUS_CANCEL_LINK_TRACKING_PROPS,
  TRAVEL_PLUS_OPEN_MODAL_TRACKING_PROPS,
  TRAVEL_PLUS_CLOSE_ASSET_TRACKING_PROPS,
  TRAVEL_PLUS_CONFIRM_BUTTON_TRACKING_PROPS,
};
