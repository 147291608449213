// Types
import { FinancingPaymentMethodFirstStepRevolvingOptionsHandlersType } from './types/financing-payment-method-first-step-revolving-options.handlers.type';
import { FinancingPaymentMethodFirstStepRevolvingOptionsReturnHandlersType } from './types/financing-payment-method-first-step-revolving-options-return.handlers.type';
import { ChangeRevolvingMethodHandlerType } from './types/change-revolving-method-handler.type';
// Utils
import { isFixAmount } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';

const changeRevolvingMethodHandler = ({
  name,
  revolvingOptions,
  setFee,
  setRevolvingMethod,
}: ChangeRevolvingMethodHandlerType) => {
  const feeValues = isFixAmount(name)
    ? revolvingOptions?.FIXED_PAYMENT_AMOUNT.values
    : revolvingOptions?.PERCENTAGE_ACCOUNT_BALANCE.values;

  if (feeValues) {
    setFee(feeValues[0]);
    setRevolvingMethod(name);
  }
};

const FinancingPaymentMethodFirstStepRevolvingOptionsHandlers = ({
  revolvingOptions,
  setFee,
  setRevolvingMethod,
}: FinancingPaymentMethodFirstStepRevolvingOptionsHandlersType): FinancingPaymentMethodFirstStepRevolvingOptionsReturnHandlersType => ({
  handleChangeRevolvingMethod: name =>
    changeRevolvingMethodHandler({ name, revolvingOptions, setFee, setRevolvingMethod }),
});

export default FinancingPaymentMethodFirstStepRevolvingOptionsHandlers;
