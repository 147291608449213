// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DeviceInfoComponentButtonsStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
  width: min-content;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    padding: 0;
    width: 100%;
    flex-direction: column;
  }
`;