// Constants
import { DocumentErrorTypeEnumeration } from '../enumerations/documentation.enumeration';
// Types
import { type DocumentationUtilsPropsType } from './types/documentation.container.utils.type';

const hideCategorieSection = ({
  categories,
  categoryError,
}: DocumentationUtilsPropsType): boolean =>
  categoryError === DocumentErrorTypeEnumeration.EMPTY || categories.length === 0;

export { hideCategorieSection };
