// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LineSeparatorComponent, ListComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_ITEMS,
  FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_PROPS,
} from './constants/financing-ppi-contracting-step2-expandable-uncovered.constants';
import { FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE } from 'containers/financing/views/ppi/components/contracting/components/step2/constants/financing-ppi-contracting-step2.constants';
// Translations
import { TITLE } from './translations/financing-ppi-contracting-step2-expandable-uncovered.translations';
// Utilities
import { mapAndTranslateSectionsListItems } from '../../utils/financing-ppi-contracting-step2-expandable.utils';

const FinancingPPIContractingStep2ExpandableUncoveredComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE}>
        <FormattedMessageComponent id={TITLE} />
      </ParagraphComponent>

      <ListComponent
        {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_PROPS}
        items={mapAndTranslateSectionsListItems({
          ...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_UNCOVERED_LIST_ITEMS,
          formatMessage,
        })}
      />
      <LineSeparatorComponent />
    </>
  );
};

export { FinancingPPIContractingStep2ExpandableUncoveredComponent };
