// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { ActivityTypeDeclaredEnumeration } from 'containers/financing/views/credit-limit-increase/components/step2/sections/employment-data/enumerations/employment-status.enumeration';
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Types
import { RejectedDocumentType } from 'containers/financing/views/credit-limit-increase/contexts/types/document-rejected.type';

const hasEmployedRejectedDocument = (rejectDocuments: RejectedDocumentType[]): boolean => {
  return rejectDocuments.some(
    document =>
      document.documentDescription === UploadDocumentIdsEnumeration.LAST_SALARY ||
      document.documentDescription === UploadDocumentIdsEnumeration.PREVIOUS_SALARY ||
      document.documentDescription === UploadDocumentIdsEnumeration.BEFORE_PREVIOUS_SALARY
  );
};

const isEmployed = (activityTypeDeclared?: string): boolean =>
  activityTypeDeclared === ActivityTypeDeclaredEnumeration.EMPLOYED;

const hasRejectedDocuments = (documentsRejected?: RejectedDocumentType[]): boolean => {
  return (
    !!documentsRejected &&
    documentsRejected.length > NUMBER.ZERO &&
    hasEmployedRejectedDocument(documentsRejected)
  );
};

export { hasRejectedDocuments, isEmployed };
