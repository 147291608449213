// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const TaxSuccessStyled = styled.div`
  margin: ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingXxxxxl}`};

  ${tabletMediaQuery} {
    ${({ theme }) => `
      maring-right: ${theme.spaces.spacingXl};
      maring-left: ${theme.spaces.spacingXl};
    `};
  }

  ${mobileMediaQuery} {
    ${({ theme }) => `
      maring-right: ${theme.spaces.spacingS};
      maring-left: ${theme.spaces.spacingS};
    `};
  }
`;

export const InputAndButtonStyled = styled.div`
  display: flex;
  align-items: flex-end;
  width: fit-content;
  gap: ${({ theme }) => theme.spaces.spacingS};
  margin-top: ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    flex-direction: column;
  }
`;
