// Components
import { CardSettingsNewCardDeliveryAddressComponent } from '../components/delivery-address/card-settings-new-card-delivery-address.component';
import { CardSettingsNewCardConfirmationComponent } from '../components/confirmation/card-settings-new-card-confirmation.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  CONFIRMATION,
  DELIVERY_ADDRESS,
  ERROR,
  REPLACEMENT,
  ROWS,
  SUCCESS,
  TITLE,
} from '../translations/card-settings-new-card.translations';
import { BACK_TO_CARD_SETTINGS_LABEL } from '@/translations/wizard-feedback.translations';

const STEPS = [
  {
    component: CardSettingsNewCardDeliveryAddressComponent,
    title: DELIVERY_ADDRESS.TITLE,
    description: DELIVERY_ADDRESS.DESCRIPTION,
  },
  {
    component: CardSettingsNewCardConfirmationComponent,
    title: CONFIRMATION.TITLE,
    description: CONFIRMATION.DESCRIPTION,
  },
];

const DEFAULT_ERROR = {
  description: ERROR.DESCRIPTION,
  title: ERROR.TITLE,
};

const REPLACEMENT_ERROR = {
  description: REPLACEMENT.DESCRIPTION,
  title: REPLACEMENT.TITLE,
};

const ERROR_CONFIGURATION = {
  exitRoute: RoutesEnumeration.CARD_SETTINGS,
};

const SUCCESS_CONFIGURATION = {
  description: SUCCESS.DESCRIPTION,
  exitRoute: RoutesEnumeration.CARD_SETTINGS,
  linkLabel: BACK_TO_CARD_SETTINGS_LABEL,
  title: SUCCESS.TITLE,
};

const SIDEBAR_PROPS = {
  testId: 'card-settings-new-card',
  items: [
    {
      label: ROWS.FIRST.TITLE,
      description: ROWS.FIRST.CONTENT,
    },
    {
      label: ROWS.SECOND.TITLE,
      description: ROWS.SECOND.CONTENT,
    },
  ],
  title: TITLE,
};

export {
  DEFAULT_ERROR,
  ERROR_CONFIGURATION,
  REPLACEMENT_ERROR,
  SIDEBAR_PROPS,
  STEPS,
  SUCCESS_CONFIGURATION,
};
