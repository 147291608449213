// Constants
import { assetConstants } from '@openbank/cb-ui-commons';
// Translations
import { ERROR_DESCRIPTION, ERROR_TITLE } from '../translations/app.configuration.translations';

export default {
  ERROR_DEFAULT_ASSET_ID: 'oops-alternative',
  ERROR_DEFAULT_TRANSLATIONS: {
    ERROR_DESCRIPTION,
    ERROR_TITLE,
  },
  ERROR_ASSET_CONFIGURATION_PROPS: {
    assetConfiguration: {
      sizes: {
        height: 120,
        width: 120,
      },
      type: assetConstants.TYPES.ILLUSTRATION,
      testId: 'entrypoint-configurations-error-asset',
    },
  },
  ERROR_CONFIGURATION_PROPS: {
    testing: {
      descriptionId: 'entrypoint-configurations-error-table-row-description',
      titleId: 'entrypoint-configurations-error-title',
    },
  },
};
