// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const LowestCreditLimitComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingM};
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  padding: ${({ theme }) => theme.spaces.spacingM} ${convertPxsToRems({ pixels: 80, base: 14 })};

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingM};
  }
`;
