// Vendors
import React from 'react';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  SidebarComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from '../../constants/credit-limit-increase.constants';
import sidebarConstants from './constants/credit-limit-increase-sidebar.constants';
// Styles
import {
  LastExpandableRowStyled,
  LastParagraphContainerStyled,
  ParagraphContainerStyled,
} from './credit-limit-increase-sidebar.component.styled';
// Translations
import translations from '../../translations/credit-limit-increase.translations';

const CreditLimitIncreaseSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...constants.SIDEBAR.PROPS}
    title={<FormattedMessageComponent id={translations.SIDEBAR_TITLE} />}
  >
    <ExpandableRowComponent
      title={<FormattedMessageComponent id={translations.SIDEBAR_HOW_DOES_IT_WORK} />}
    >
      <ParagraphContainerStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={translations.SIDEBAR_HOW_DOES_IT_WORK_DESCRIPTION} />
        </ParagraphComponent>
      </ParagraphContainerStyled>
    </ExpandableRowComponent>
    <ExpandableRowComponent
      title={<FormattedMessageComponent id={translations.SIDEBAR_WHAT_RISK} />}
    >
      <ParagraphContainerStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={translations.SIDEBAR_WHAT_RISK_DESCRIPTION} />
        </ParagraphComponent>
      </ParagraphContainerStyled>
    </ExpandableRowComponent>
    <LastExpandableRowStyled>
      <ExpandableRowComponent
        title={<FormattedMessageComponent id={translations.SIDEBAR_INTEREST_CHARGES} />}
        letft
        type={sidebarConstants.SIDEBAR_PROPS.TYPE}
      >
        <LastParagraphContainerStyled>
          <ParagraphComponent>
            <FormattedMessageComponent id={translations.SIDEBAR_INTEREST_CHARGES_DESCRIPTION} />
          </ParagraphComponent>
        </LastParagraphContainerStyled>
      </ExpandableRowComponent>
    </LastExpandableRowStyled>
  </SidebarComponent>
);

export default CreditLimitIncreaseSidebarComponent;
