// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from 'containers/card-settings/views/landing/components/toggles/translations/card-settings-toggles.translations';
import { SWITCHOFF, SWITCHON } from '../translations/card-settings-benefits.translations';

const BENEFITS_LANDING_TAG_CONTRACTED_PROPS = {
    shape: tagConstants.SHAPES.PILL,
    type: tagConstants.TYPES.SUCCESS,
    description: translations.ON,
  },
  BENEFITS_LANDING_TAG_CANCELLED_PROPS = {
    shape: tagConstants.SHAPES.PILL,
    type: tagConstants.TYPES.DISABLED,
    description: translations.OFF,
  },
  BENEFITS_LANDING_TAG_COVERED_PROPS = {
    shape: tagConstants.SHAPES.PILL,
    type: tagConstants.TYPES.PENDING,
    description: translations.OFF,
  },
  BENEFITS_LANDING_BUTTON_CONTRACTED_PROPS = {
    type: 'secondary',
    disabled: false,
    description: SWITCHOFF,
  },
  BENEFITS_LANDING_BUTTON_CANCELLED_PROPS = {
    description: SWITCHON,
    disabled: false,
  },
  BENEFITS_LANDING_BUTTON_COVERED_PROPS = {
    description: SWITCHON,
    disabled: false,
  };

export {
  BENEFITS_LANDING_TAG_CONTRACTED_PROPS,
  BENEFITS_LANDING_TAG_CANCELLED_PROPS,
  BENEFITS_LANDING_TAG_COVERED_PROPS,
  BENEFITS_LANDING_BUTTON_CONTRACTED_PROPS,
  BENEFITS_LANDING_BUTTON_CANCELLED_PROPS,
  BENEFITS_LANDING_BUTTON_COVERED_PROPS,
};
