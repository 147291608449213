// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import { CardSettingsResumeLinkLinksHandlers } from '../handlers/card-settings-resume-link-links.handlers';
import { CardSettingsResumeLinkLinksTrackingHandlers } from '../handlers/card-settings-resume-link-links.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsResumeLinkLinksBuilderReturnHandlersType } from '../handlers/types/card-settings-resume-link-links.handlers.type';
import { useCardSettingsLandingActionsHook } from 'containers/card-settings/views/landing/hooks/card-settings-landing-actions.hook';

const useCardSettingsResumeLinkLinksHook =
  (): CardSettingsResumeLinkLinksBuilderReturnHandlersType =>
    CardSettingsResumeLinkLinksHandlers({
      ...AppTrackingHook(CardSettingsResumeLinkLinksTrackingHandlers),
      actionHook: useCardSettingsLandingActionsHook,
      navigate: useNavigate(),
    });

export { useCardSettingsResumeLinkLinksHook };
