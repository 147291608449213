// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

export const AccessKeyContainerHeaderStyled = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundInfoE};
  padding: ${convertPxsToRems({ pixels: 16, base: 14 })};
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  height: ${convertPxsToRems({ pixels: 78, base: 14 })};
  display: grid;
`;
