// Vendors
import { PositionsConstants } from '@openbank/cf-ui-static-data';
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/credit-header.translations';

export default {
  DASHBOARD_LAST_SESSION_ASSET_PROPS: {
    id: 'clock',
    testId: 'dashboard-last-session-asset',
  },
  DASHBOARD_LAST_SESSION_DESCRIPTION_PROPS: {
    size: paragraphConstants.SIZES.XS,
    testId: 'dashboard-last-session-table-row-description',
  },
  DASHBOARD_CREDIT_CARD_PROPS: {
    messageTooltip: translations.TOOLTIP,
    positionTooltip: 'LEFT',
  },
};
