// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { ProfilePersonalDataAddressBuilderHandlersType } from 'containers/personal/views/landing/components/data/components/address/handlers/types/profile-personal-data-address-builder.handlers.type';
import { ProfilePersonalDataAddressBuilderReturnHandlersType } from 'containers/personal/views/landing/components/data/components/address/handlers/types/profile-personal-data-address-builder-return.handlers.type';

const editCurrentPrimaryResidentialAssetClickHandler = ({
  handleEditCurrentPrimaryResidentialAssetClickTracking,
  navigate,
}: ProfilePersonalDataAddressBuilderHandlersType): any => {
  handleEditCurrentPrimaryResidentialAssetClickTracking();
  navigate(RoutesEnumeration.EDIT_ADDRESS);
};

const ProfilePersonalDataAddressHandlers = (
  props: ProfilePersonalDataAddressBuilderHandlersType
): ProfilePersonalDataAddressBuilderReturnHandlersType => ({
  handleEditCurrentPrimaryResidentialAssetClick: () =>
    editCurrentPrimaryResidentialAssetClickHandler(props),
});

export default ProfilePersonalDataAddressHandlers;
