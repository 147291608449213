const DOMAIN = 'cobranded.services.userSettings.privateArea.uploadDocumentation.feedback.';

const FEEDBACK_ERROR_DESCRIPTION = `${DOMAIN}errorDescription`;
const FEEDBACK_ERROR_TITLE = `${DOMAIN}errorTitle`;
const FEEDBACK_SUCCESS_DESCRIPTION = `${DOMAIN}successDescription`;
const FEEDBACK_SUCCESS_TITLE = `${DOMAIN}successTitle`;
const GO_TO_PERSONAL_INFO = `${DOMAIN}link`;

export {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_PERSONAL_INFO,
};
