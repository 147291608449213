// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const OrchestratorRouterContainersComponentStyled = styled.section<{ isDashboard: boolean }>`
  flex: 1;
  height: auto;
  margin: 0 auto;
  max-width: ${convertPxsToRems({ pixels: 1440, base: 14 })};
  padding: ${({ theme }) => theme.spaces.spacingM}
    ${({ isDashboard, theme }) => (isDashboard ? theme.spaces.spacingM : theme.spaces.spacingXl)};
  width: 100%;

  ${tabletMediaQuery} {
    padding: 0;
  }

  ${mobileMediaQuery} {
    padding: 0;
    min-height: auto;
  }
`;
OrchestratorRouterContainersComponentStyled.displayName =
  'OrchestratorRouterContainersComponentStyled';

export { OrchestratorRouterContainersComponentStyled };

const LowerNavigationComponentStyled = styled.nav`
  position: sticky;
  bottom: 0;
  width: 100%;
`;
export { LowerNavigationComponentStyled };
