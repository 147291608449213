// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from 'containers/personal/views/access-portability/services/constants/access-portability.service.constants';
// Enumerations
import { AccessPortabilityTypeEnumeration } from '../enumerations/access-portability.enumeration';

const getAccessPortabilityInformationService = async (
  rightsType: AccessPortabilityTypeEnumeration
): Promise<[any | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...constants.ACCESS_PORTABILITY_SERVICE_CONFIGURATION,
    queryParams: {
      type: rightsType,
    },
  });

export { getAccessPortabilityInformationService };
