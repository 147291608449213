// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_CARD_STATEMENTS_SERVICE_CONFIGURATION } from './constants/card-settings-statements.service.constants';
// Types
import { CardSettingsStatementsApiResponseType } from '../types/card-settings-statements-api-response.type';

const fetchCardsStatementsService = async (
  cardContractId: string
): Promise<[CardSettingsStatementsApiResponseType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...FETCH_CARD_STATEMENTS_SERVICE_CONFIGURATION,
    pathParams: { cardContractId },
  });

export { fetchCardsStatementsService };
