// Vendors
import { useState } from 'react';
// Enumerations
import { PersonalUploadDocumentationFlows } from '../enumerations/personal-upload-documentation-flows.enum';
// Types
import { UsePersonalUploadDocumentationHookType } from './types/personal-upload-documentation.hook.type';

const usePersonalUploadDocumentationHook = (): UsePersonalUploadDocumentationHookType => {
  const [flowId, setFlowId] = useState(PersonalUploadDocumentationFlows.UPLOAD_DOCS);

  return { flowId, setFlowId };
};

export { usePersonalUploadDocumentationHook };
