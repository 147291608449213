// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { useRecoveryKeyContextConsumerHook } from 'containers/recovery-key/contexts/recovery-key.context';
// Enums
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import { useRecoveryKeyStepChallenge } from 'containers/recovery-key/hooks/recovery-key-step-challenge.hook';
// Handlers
import { RecoveryKeyStep4CreditCardHandlers } from '../handlers/recovery-key-step4-credit-card.handlers';
// Resolvers
import { validatePinPanResolver } from '../resolvers/recovery-key-step4-credit-card.resolvers';
// Types
import { RecoveryKeyStep4CreditCardHookType } from './types/recovery-key-step4-credit-card.hook.type';

const RecoveryKeyStep4CreditCardHook = (): RecoveryKeyStep4CreditCardHookType => {
  const { formatMessage } = useCbIntl();
  const { pin, setPin, last4DigitsPan, setLast4DigitsPan } = useRecoveryKeyContextConsumerHook();
  const [error, setError] = useState<boolean>(false);
  const [canContinue, setCanContinue] = useState<boolean>(false);

  const { validatePan, onChangePan, onValuesChange, onContinue } =
    RecoveryKeyStep4CreditCardHandlers({
      ...useRecoveryKeyStepChallenge({
        nextStep: WizardStepsEnumeration.FIVE,
        resolver: validatePinPanResolver,
      }),
      pin,
      last4DigitsPan,
      setCanContinue,
      setError,
      setLast4DigitsPan,
    });

  useEffect(() => {
    onValuesChange();
  }, [pin, last4DigitsPan]);

  return {
    pin,
    last4DigitsPan,
    error,
    validatePan,
    onChangePan,
    onChangePin: setPin,
    fetching: false,
    onContinue,
    canContinue,
    formatMessage,
  };
};

export { RecoveryKeyStep4CreditCardHook };
