// Vendors
import styled from 'styled-components';

export const RequestPortabilityCheckboxesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
  `;
  
  export const RequestPortabilityCardCheckboxContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spaces.spacingS};
  gap: ${({ theme }) => theme.spaces.spacingXs};
  width: 100%;
`;
