const DOMAIN =
  'cobranded.services.userSettings.privateArea.support.cbSupport.cancelContract.reasons.';

export default {
  REASONS: {
    UNEXPECTED_BANK_CHARGES: `${DOMAIN}unexpectedBankCharges`,
    HIGH_DEBT: `${DOMAIN}highDebt`,
    HIGH_YEARLY_PAYMENT: `${DOMAIN}highYearlyPayment`,
    NOT_ENOUGH_CREDIT_LINE: `${DOMAIN}notEnoughCreditLine`,
    HIGH_INTEREST_RATES: `${DOMAIN}highInterestRates`,
    FRAUD_VICTIM: `${DOMAIN}fraudVictim`,
    BETTER_CONDITIONS_IN_ANOTHER_BANK: `${DOMAIN}betterConditionsInAnotherBank`,
    FAILED_TO_MEET_EXPECTATIONS: `${DOMAIN}failedToMeetExpectations`,
    BAD_EXPERIENCE_ON_DIGITAL_PLATFORMS: `${DOMAIN}badExperienceOnDigitalPlatforms`,
    BAD_EXPERIENCE_IN_CUSTOMER_SERVICE: `${DOMAIN}badExperienceInCustomerService`,
    CARD_USAGE_PROBLEMS: `${DOMAIN}cardUsageProblems`,
    WANTS_ANOTHER_OBMX_CARD: `${DOMAIN}wantsAnotherObmxCard`,
    CAMPAIGN_PROMOTION: `${DOMAIN}campaignPromotion`,
    NOT_USING_CARD: `${DOMAIN}notUsingCard`,
    OTHER_REASON: `${DOMAIN}otherReason`,
  },
};
