// Vendors
import React from 'react';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import { CardDetailHook } from './hooks/card-detail.hook';

const CardDetailProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { fetching } = CardDetailHook();

  return <FetchErrorComponent {...{ fetching }}>{children}</FetchErrorComponent>;
};

export { CardDetailProvider };

/* eslint-disable */
export const withCardDetailProvider =
  (Component: React.ComponentType): React.FC =>
  (props: any) =>
    (
      <CardDetailProvider>
        <Component {...props} />
      </CardDetailProvider>
    );
