// Enumerations
import { BillingPaymentMethodEnumeration } from 'enumerations/billing-payment-method.enumeration';
// Constants
import translations from 'components/credit-header/translations/credit-header.translations';
// Translations
import {
  FIX_AMOUNT,
  FULL_PAYMENT,
  PERCENTAGE_AMOUNT,
} from 'translations/billing-payment-methods.translations';

const FINANCING_CREDIT_COMPONENT_PROPS = {
  messageTooltip: translations.TOOLTIP,
  positionTooltip: 'LEFT',
};

export { FINANCING_CREDIT_COMPONENT_PROPS };

export const PAYMENT_METHOD_VALUE = {
  [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: FIX_AMOUNT.TITLE,
  [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: FULL_PAYMENT.TITLE,
  [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]: PERCENTAGE_AMOUNT.TITLE,
};
