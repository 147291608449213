// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TableRowBuilderReturnTrackingHandlersType } from './types/table-row-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const toggleTransactionsLandingRowAssetClickTrackingHandler = (
  open: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: `${open ? 'open' : 'close'}TransactionsLandingRow`,
  },
  type: TrackingEventsEnum.LINK,
});

const TableRowTrackingHandlers = (
  track: TrackBuilderType
): TableRowBuilderReturnTrackingHandlersType => ({
  handleToggleTransactionsLandingRowAssetClickTracking: (open: boolean) =>
    track(toggleTransactionsLandingRowAssetClickTrackingHandler(open)),
});

export default TableRowTrackingHandlers;
