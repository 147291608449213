// Vendors
import React from 'react';
// Constants
import { PACKAGE_TITLE_PROPS } from './constants/packages.constants';
// Components
import TitleSectionComponent from 'components/title-section/title-section.component';
import { WorkInProgressComponent } from 'components/working-in-progress/work-in-progress.component';
// Styles
import { LayoutGridComponentStyled } from 'styles/app.styled';

const PackagesContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <TitleSectionComponent {...PACKAGE_TITLE_PROPS} />
    <WorkInProgressComponent />
  </LayoutGridComponentStyled>
);

export { PackagesContainer };
