// Translations
import {
  FIRST_STEP_DESCRIPTION,
  FIRST_STEP_TITLE,
  SECOND_STEP_DESCRIPTION,
  SECOND_STEP_TITLE,
  THIRD_STEP_DESCRIPTION,
  THIRD_STEP_TITLE,
} from '../translations/transactions-report-wizard-success.translations';

const CONFIRMATION_NEXT_STEPS_PROPS = {
  testId: 'transactions-report-wizard-success-next-steps',
};

const NEXT_STEPS = [
  {
    description: FIRST_STEP_DESCRIPTION,
    title: FIRST_STEP_TITLE,
  },
  {
    description: SECOND_STEP_DESCRIPTION,
    title: SECOND_STEP_TITLE,
  },
  {
    description: THIRD_STEP_DESCRIPTION,
    title: THIRD_STEP_TITLE,
  },
];

export { CONFIRMATION_NEXT_STEPS_PROPS, NEXT_STEPS };
