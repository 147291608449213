// Vendors
import styled from 'styled-components';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const TravelPlusCostComponentStyled = styled(LayoutColumnFlexComponentStyled)`
  align-items: center;
  justify-content: center;
  text-align: center;
`;
TravelPlusCostComponentStyled.displayName = 'TravelPlusCostComponentStyled';

const TravelPlusCostBoxComponentStyled = styled.div`
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  border: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
  margin: 0 ${({ theme }) => theme.spaces.spacingXxxxl};
  padding: ${({ theme }) => theme.spaces.spacingS};
  text-align: center;
  width: fit-content;
`;
TravelPlusCostBoxComponentStyled.displayName = 'TravelPlusCostBoxComponentStyled';

export { TravelPlusCostComponentStyled, TravelPlusCostBoxComponentStyled };
