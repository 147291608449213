// Types
import { DevicesListHandlersPropsType } from './types/devices-list.handlers.props.type';
import { DevicesListHandlersReturnType } from './types/devices-list.handlers.return.type';
// Resolvers
import { devicesListResolver } from 'containers/security-keys/views/devices/resolvers/signature-key-devices.resolvers';

const getDevicesHandlers = async ({ setFetching, setDevices, setBlockedDevices, setError, setTrusted, getTrustedDevice }) => {
  setFetching(true);
  const [ devices, error ] = await devicesListResolver({ blocked: false });
  const [ blockeDevices ] = await devicesListResolver({ blocked: true });
  const trusted = (devices || []).find(({ trusted }) => trusted);

  if (error) {
    setFetching(false);

    return setError(true);
  }

  trusted ? setTrusted(trusted) : await getTrustedDevice();

  setError(false);
  setBlockedDevices(blockeDevices);
  setDevices((devices || []).filter(({ trusted }) => !trusted));
  setFetching(false);
};

const DevicesListHandlers = (
  props: DevicesListHandlersPropsType): DevicesListHandlersReturnType => ({
  getDevices: () => getDevicesHandlers(props)
});

export { DevicesListHandlers };