// Enumerations
import { StorageLocalEnum } from '@openbank/cf-ui-static-data';

const getSavedUsername = (): string => localStorage.getItem(StorageLocalEnum.USERNAME) ?? '';

const getSavedUserAlias = (): string | null => localStorage.getItem(StorageLocalEnum.USER_ALIAS);

const getUsernameEmail = (): string => (userIsRemembered() ? getSavedUsername() : '');

const userIsRemembered = (): boolean =>
  localStorage.getItem(StorageLocalEnum.REMEMBER_USER) === 'true';

export { getUsernameEmail, userIsRemembered, getSavedUserAlias };
