// Vendors
import React from 'react';
// Components
import { ExampleModalComponent } from './components/example-modal/example-modal.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { UploadDocTips } from '@openbank/cb-ui-commons';
// Constants
import { TIPS_FOR_UPLOADING_ASSET_CONSTANTS as constants } from './constants/upload-documents-tips.constants';
// Hooks
import useUploadDocumentsTipsHook from './hooks/upload-documents-tips.hook';
// Translations
import {
  EXAMPLE_DESCRIPTION,
  EXAMPLE_LINK,
  FORMAT_DESCRIPTION,
  TITLE,
  UP_TO_DATE_DESCRIPTION,
} from './translations/upload-documents-tips.translations';

const UploadDocumentsTipsComponent = (): React.ReactElement => {
  const { handleCloseModalHandler, showModal, handleOpenModal } = useUploadDocumentsTipsHook();
  return (
    <>
      <UploadDocTips
        tips={[
          {
            assetConfiguration: { ...constants.SIZE_TIP.assetConfiguration },
            linkConfiguration: {
              text: <FormattedMessageComponent id={EXAMPLE_LINK} />,
              onClick: handleOpenModal,
            },
            text: <FormattedMessageComponent id={EXAMPLE_DESCRIPTION} />,
          },
          {
            assetConfiguration: { ...constants.FORMAT_TIP.assetConfiguration },
            text: <FormattedMessageComponent id={FORMAT_DESCRIPTION} />,
          },
          {
            assetConfiguration: { ...constants.IMAGE_TIP.assetConfiguration },
            text: <FormattedMessageComponent id={UP_TO_DATE_DESCRIPTION} />,
          },
        ]}
        title={<FormattedMessageComponent id={TITLE} />}
      />
      <ExampleModalComponent show={showModal} onClose={handleCloseModalHandler} />
    </>
  );
};

export { UploadDocumentsTipsComponent };
