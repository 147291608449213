export default {
  cardDetailsProps: {
    'data-testid': 'security-verification-card-details',
  },
  descriptionProps: {
    size: 'S',
    testId: 'security-verification-description',
  },
  challengeProps: {
    'data-testid': 'security-verification-challenge',
  },
  CHALLENGE_SERVICE_METHOD: 'get',
  endpoints: {
    cvv: 'cb-api-cards/v2/cards/:cardId/online-purchases',
    pin: 'cb-api-cards/v2/cards/:cardId/pin',
  },
  leftLinkProps: {
    isBold: true,
    testId: 'security-verification-cancel',
    underline: true,
  },
  rightButtonProps: {
    testId: 'security-verification-continue',
    type: 'primary',
  },
  titleProps: {
    testId: 'security-verification-title',
  },
};
