// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  BACK_URL_SUFFIX,
  CANCEL_URL_SUFFIX,
  CREDIT_LIMIT_INIT_URL,
} from './constants/step-0-init.services.constants';
import { LIMIT_INCREASE_CASES_URL, PPI_INFO_URL } from './constants/commons.constants';
// Types
import { BackToPreviousStepResolverPropsType } from './types/step-0-back-to-previous-step.type';
import { CancelProcessResponseType } from './types/step-0-cancel-process.type';
import { CaseInitResponseType } from './types/step-0-init.type';
import { GetInitCaseServicePropsType } from './types/get-init-case-service-props.type';
import { PPIInfoResponseType } from './types/ppi-info.type';
// Utils
import { getAggregationCallback } from './utils/step-0-init.services.utils';

const getInitCaseService = ({
  cardId,
  callbackWebId = false,
}: GetInitCaseServicePropsType): Promise<[CaseInitResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: CREDIT_LIMIT_INIT_URL,
    params: {
      aggregationCallback: getAggregationCallback(),
      cardId,
      callbackWebId,
    },
  });

const cancelProcessService = (caseId: string): Promise<[CancelProcessResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${CANCEL_URL_SUFFIX}`,
  });

const getPPIInfoService = (): Promise<[PPIInfoResponseType, Response]> =>
  apiUtilsCb.get({
    endpoint: PPI_INFO_URL,
  });

const backToPreviousStepResolverService = ({
  cardId,
  caseId,
  currentStep,
}: BackToPreviousStepResolverPropsType): Promise<[CaseInitResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}/${currentStep}${BACK_URL_SUFFIX}`,
    params: {
      cardId,
    },
  });

export {
  getInitCaseService,
  cancelProcessService,
  getPPIInfoService,
  backToPreviousStepResolverService,
};
