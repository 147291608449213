// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
import { TransactionsEppStatusCodeEnumeration } from 'enumerations/transactions-epp-status-code.enumeration';
// Utils
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const showEppCodeTagComponent = (eppStatusCode: TransactionsEppStatusCodeEnumeration): boolean => {
  return eppStatusCode === TransactionsEppStatusCodeEnumeration.ELIGIBLE_FOR_EPP
    ? getFeatureFlagInstance().isEnabled(FeatureFlagsEnumeration.CREATE_EPP)
    : eppStatusCode !== TransactionsEppStatusCodeEnumeration.NOT_ELIGIBLE_FOR_EPP;
};

export { showEppCodeTagComponent };
