// Types
import { SettingsCardControlLocationsToggleBuilderUtilsType } from './types/settings-card-control-locations-toggle-builder.utils.type';
import { SettingsCardControlLocationsToggleItemUtilsType } from './types/settings-card-control-locations-toggle-item.utils.type';
import { SettingsCardControlLocationsToggleAddUtilsType } from './types/settings-card-control-locations-toggle-add.utils.type';
// Utils
import { getAllEnabledCountries } from 'containers/card-settings/views/card-control/components/locations/configurations/settings-card-control-locations.configurations';

const addCurrentCountry = ({
  enabledCountries,
  countryId: id,
  mainCountryId,
}: SettingsCardControlLocationsToggleBuilderUtilsType): SettingsCardControlLocationsToggleItemUtilsType[] => {
  return mainCountryId ? [{ id: mainCountryId }] : [...enabledCountries, { id: id || '' }];
};

const removeCurrentCountry = ({
  enabledCountries,
  countryId,
  mainCountryId,
}: SettingsCardControlLocationsToggleBuilderUtilsType): any => {
  return mainCountryId ? [] : enabledCountries.filter(({ id }) => id !== countryId);
};

const getCurrentCountryValue = ({
  continents,
  countryId,
  mainCountryId,
}: SettingsCardControlLocationsToggleAddUtilsType): boolean => {
  if (mainCountryId) {
    const enabledCountries = getAllEnabledCountries(continents);
    const mainCountryExists = enabledCountries.some(({ id }) => id === mainCountryId);
    const hasOtherId = enabledCountries.some(({ id }) => id !== mainCountryId);
    return mainCountryExists && !hasOtherId;
  }

  return Boolean(getAllEnabledCountries(continents).find(({ id }) => id === countryId));
};

const addOrRemoveCurrentCountry = ({
  continents,
  countryId,
  mainCountryId,
}: SettingsCardControlLocationsToggleAddUtilsType): SettingsCardControlLocationsToggleItemUtilsType => {
  const enabledCountries = getAllEnabledCountries(continents);
  return getCurrentCountryValue({ continents, countryId, mainCountryId })
    ? removeCurrentCountry({ enabledCountries, countryId, mainCountryId })
    : addCurrentCountry({ enabledCountries, countryId, mainCountryId });
};

export { addOrRemoveCurrentCountry, getCurrentCountryValue };
