// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
// Enumerations
import { RepeatEmailErrorIdEnumeration } from '../enumerations/new-email-error-id.enumeration';
// Event handlers
import PersonalEmailStep1RepeatHandlers from '../handlers/personal-email-step1-repeat.handlers';
import PersonalEmailStep1RepeatTrackingHandlers from '../handlers/personal-email-step1-repeat.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalEmailStep1RepeatHookType } from './types/personal-email-step1-repeat.hook.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';

const PersonalEmailStep1RepeatHook = (): PersonalEmailStep1RepeatHookType => {
  const [inputError, setInputError] = useState<RepeatEmailErrorIdEnumeration | null>(null);

  const { newEmail, repeatEmail, setRepeatEmail } = PersonalEmailContextConsumerHook();

  const { handleModifyEmailRepeatInputClickTracking, handleModifyEmailRepeatInputErrorTracking } =
      AppTrackingHook(PersonalEmailStep1RepeatTrackingHandlers),
    { handleSaveRepeatModifyEmailInputEvent, handleValidateRepeatModifyEmailInputEvent } =
      PersonalEmailStep1RepeatHandlers({
        handleModifyEmailRepeatInputErrorTracking,
        newEmail,
        repeatEmail,
        setInputError,
        setRepeatEmail,
      });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isValidEmail(repeatEmail)) {
      handleValidateRepeatModifyEmailInputEvent();
    }

    if (newEmail === '') {
      setRepeatEmail('');
      setInputError(null);
    }
  }, [newEmail, repeatEmail]);

  return {
    handleModifyEmailRepeatInputClickTracking,
    handleSaveRepeatModifyEmailInputEvent,
    handleValidateRepeatModifyEmailInputEvent,
    inputError,
    newEmail,
    repeatEmail,
  };
};

export default PersonalEmailStep1RepeatHook;
