// Vendors
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/profile-personal-name-title-dropdown.constants';
// Custom Hooks
import ProfilePersonalNameTitleInputHook from './hooks/profile-personal-name-title-dropdown.hook';
// Translations
import translations from '../../translations/profile-personal-name-title.translations';
// Utilities
import { getDefaultOrTranslatedTitle } from '../../utils/profile-personal-name-title.utils';
import { mapMiscellaneousTitles } from './utils/profile-personal-name-title-dropdown.utils';

const ProfilePersonalNameTitleDropdownComponent = (): React.ReactElement => {
  const {
    fetching,
    formatMessage,
    handleChangeCurrentTitleDropdownItemClickEvent,
    handleMiscellaneousTitleClickTracking,
    title,
    titles,
    isIosDevice,
  } = ProfilePersonalNameTitleInputHook();

  return (
    <InputDropdownComponent
      {...{ ...constants.PROFILE_PERSONAL_NAME_TITLE_DROPDOWN_PROPS, fetching }}
      label={<FormattedMessageComponent id={translations.TITLE} />}
      disabled={fetching}
      isIosDevice={isIosDevice}
      placeholder={getDefaultOrTranslatedTitle({ formatMessage, title })}
      onClick={handleMiscellaneousTitleClickTracking}
      onSelectOption={handleChangeCurrentTitleDropdownItemClickEvent}
      options={mapMiscellaneousTitles({ formatMessage, titles })}
      value={title}
    />
  );
};

export default ProfilePersonalNameTitleDropdownComponent;
