// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameTitleEditBuilderReturnTrackingHandlersType } from 'containers/personal/views/landing/components/name/components/title/components/edit/handlers/types/profile-personal-name-title-edit-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const changeCurrentTitleAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'changeCurrentTitle',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameTitleEditTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameTitleEditBuilderReturnTrackingHandlersType => ({
  handleChangeCurrentTitleAssetClickTracking: () =>
    track(changeCurrentTitleAssetClickTrackingHandler()),
});

export default ProfilePersonalNameTitleEditTrackingHandlers;
