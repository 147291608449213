// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Enumerations
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Types
import { FinancingPPIContractingStep1MethodsHandlersType } from './types/financing-ppi-contracting-step1.hook.type';

const useFinancingPPIContractingStep1Hook = (): FinancingPPIContractingStep1MethodsHandlersType => {
  const { setCurrentStep } = useWizardContextConsumerHook();

  return {
    handleGoToStep2ButtonClick: () => setCurrentStep(WizardStepsEnumeration.TWO),
  };
};

export { useFinancingPPIContractingStep1Hook };
