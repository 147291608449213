// Vendors
import React from 'react';
// Components
import { CardSettingsResumeLinkComponent } from 'containers/card-settings/components/resume-link/card-settings-resume-link.component';
import CardSettingsContractCurrentBillingMethodValueComponent from './components/value/card-settings-contract-current-billing-method-value.component';
// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_PROPS } from './constants/card-settings-contract-current-billing-method.constants';

const CardSettingsContractCurrentBillingMethodComponent = (): React.ReactElement => (
  <CardSettingsResumeLinkComponent
    {...CARD_SETTINGS_CONTRACT_CURRENT_BILLING_PAYMENT_METHOD_PROPS}
    value={<CardSettingsContractCurrentBillingMethodValueComponent />}
  />
);

export default CardSettingsContractCurrentBillingMethodComponent;
