// Constants
import {
  PASSWORD_MODULE_ASSET_COLOR,
  PASSWORD_MODULE_ASSET_ID,
  VALIDATION_ID,
} from './security-keys-access-key-change.constants.common';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  FEEDBACK_BUTTON,
  FEEDBACK_BUTTON_ERROR,
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
} from '../components/cc/translations/security-keys-access-key-change-content.cc.translations';
// Utilities
import { generatePathRoute } from 'utils/app-routing.utils';

const ACCESS_KEY_CHANGE_FEEDBACK_PROPS = {
  errorConfiguration: {
    description: FEEDBACK_ERROR_DESCRIPTION,
    title: FEEDBACK_ERROR_TITLE,
    linkLabel: FEEDBACK_BUTTON_ERROR,
    exitRoute: RoutesEnumeration.SECURITY,
  },
  successConfiguration: {
    description: FEEDBACK_SUCCESS_DESCRIPTION,
    linkLabel: FEEDBACK_BUTTON,
    title: FEEDBACK_SUCCESS_TITLE,
    exitRoute: RoutesEnumeration.SECURITY,
  },
};

export {
  ACCESS_KEY_CHANGE_FEEDBACK_PROPS,
  PASSWORD_MODULE_ASSET_COLOR,
  PASSWORD_MODULE_ASSET_ID,
  VALIDATION_ID,
};
