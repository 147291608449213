// Vendors
import {
  assetConstants,
  ImagePlaceholderSizesEnumeration,
  ImagePlaceholderTypesEnumeration,
} from '@openbank/cb-ui-commons';

export default {
  PROFILE_PERSONAL_NAME_AVATAR_ASSET_PROPS: {
    fallbackAssetProps: {
      id: 'profile_default_avatar',
      sizes: {
        height: 100,
        width: 100,
      },
      type: assetConstants.TYPES.ILLUSTRATION,
      testId: 'profile-personal-name-button-avatar-fallback-asset',
    },
    size: ImagePlaceholderSizesEnumeration.XXXL,
    type: ImagePlaceholderTypesEnumeration.CIRCLE,
    testId: 'profile-personal-name-button-avatar-asset',
  },
  PROFILE_PERSONAL_NAME_AVATAR_EDIT_ASSET_PROPS: {
    id: 'edit',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'profile-personal-name-button-avatar-edit-asset',
  },
};
