// Constants
import {
  PROPS,
  TITLE_PARAGRAPH_PROPS,
  ASSET_PROPS,
  AMOUNT_ERROR_LENGTH,
} from './amount.constants.common';

const AMOUNT_LABEL_VALUE = '100,00',
  AMOUNT_MULTIPLE_CALC = 0.55,
  INPUT_PROPS = {
    placeholder: '100,00',
    testId: 'amount-input',
    type: 'currency',
  };

export {
  AMOUNT_ERROR_LENGTH,
  AMOUNT_LABEL_VALUE,
  AMOUNT_MULTIPLE_CALC,
  ASSET_PROPS,
  INPUT_PROPS,
  PROPS,
  TITLE_PARAGRAPH_PROPS,
};
