// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { ProfileConsentsConfig } from 'containers/personal/views/consents/types/profile-consent.config.type';

const showSeparator = (index: number, length: number): boolean => index < length - NUMBER.ONE;

const hasSomeConsentSelected = (consents: ProfileConsentsConfig['consentTypes']): boolean =>
  consents.some(consent => consent.subConsents.some(subConsent => subConsent.value));

export { hasSomeConsentSelected, showSeparator };
