export enum ClaimReasonEnum {
  DUPLICATED_OPERATION = 'DUPLICATED_OPERATION',
  OPERATION_NOT_REIMBURSED = 'OPERATION_NOT_REIMBURSED',
  DAMAGED_PRODUCT = 'DAMAGED_PRODUCT',
  PRODUCT_SERVICES_NOT_DELIVERED = 'PRODUCT_SERVICES_NOT_DELIVERED',
  PERFORMED_CANCELLATION_OF_RESERVATION = 'PERFORMED_CANCELLATION_OF_RESERVATION',
  RECURRENT_SUBSCRIPTION_CANCELED = 'RECURRENT_SUBSCRIPTION_CANCELED',
  PAID_BY_OTHER_MEANS = 'PAID_BY_OTHER_MEANS',
  REQUEST_COPY = 'REQUEST_COPY',
  NONE_OF_THE_ABOVE = 'NONE_OF_THE_ABOVE',
  CASH_NOT_DISPENSED = 'CASH_NOT_DISPENSED',
  CASH_PARTIALLY_DISPENSED = 'CASH_PARTIALLY_DISPENSED',
  ISSUED_CARD_AND_NOT_RECEIVED = 'ISSUED_CARD_AND_NOT_RECEIVED',
  CARD_LOST_OR_STOLEN = 'CARD_LOST_OR_STOLEN',
  CLIENT_HAS_NOT_PERFORMED = 'CLIENT_HAS_NOT_PERFORMED',
  COUNTERFEIT_CARD = 'COUNTERFEIT_CARD',
  ONLINE_OPERATION_NOT_PERFORMED = 'ONLINE_OPERATION_NOT_PERFORMED',
}
