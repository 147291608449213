// Types
import { ModalComponentConfigurationType } from './types/expired-access-key-modal.configurations.type';
import { ModalComponentConfigurationReturnType } from './types/expired-access-key-modal.configurations.return.type';
// Translations
import { ACCEPT_BUTTON, CANCEL_BUTTON, TITLE } from '../translations/expired-access-key-modal.translations';

export const getModalComponentConfiguration = (
  { onClose, onAccept, formatMessage }: ModalComponentConfigurationType
): ModalComponentConfigurationReturnType => ({
  title: formatMessage({ id: TITLE }),
  primaryButtonConfiguration: {
    children: formatMessage({ id: ACCEPT_BUTTON }),
    onClick: onAccept
  },
  linkConfiguration: {
    onClick: onClose,
    children: formatMessage({ id: CANCEL_BUTTON }),
  }
});