// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import { TransactionsEppStep2AmountComponent } from './components/amount/transactions-epp-step2-amount.component';
// Constants
import {
  TRANSACTION_EPP_STEP2_RESUME_CONSUMPTION_CONCEPT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_LOAN_AMOUNT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_MONTHLY_INSTALLMENT_PAYMENT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_NUMBER_INSTALLMENTS_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_TOTAL_AMOUNT_PROPS,
  TRANSACTION_EPP_STEP2_RESUME_TOTAL_INTEREST_PROPS,
} from './constants/transactions-epp-step2-resume.constants';
// Enumerations
import { SimulationResumeItemsEnumeration } from 'containers/transactions/views/epp/enumerations/simulation-resume-items.enumeration';
// Hooks
import { useTransactionsEppStep2ResumeHook } from './hooks/transactions-epp-step2-resume.hook';
// Styles
import { TransactionsEppStep2ResumeComponentStyled } from './transactions-epp-step2-resume.component.styled';
// Translations
import {
  CONSUMPTION_CONCEPT,
  NUMBER_INSTALLMENTS,
  VIEW_AMORTIZATION_TABLE,
} from './translations/transactions-epp-step2-resume.translations';

const TransactionsEppStep2ResumeComponent = (): React.ReactElement => {
  const {
    handleToggleAmortizationTableModalLinkClick,
    eppPlanSimulation: plan,
    transactionDescription,
  } = useTransactionsEppStep2ResumeHook();

  return (
    <TransactionsEppStep2ResumeComponentStyled>
      <ResumeItemComponent
        {...TRANSACTION_EPP_STEP2_RESUME_CONSUMPTION_CONCEPT_PROPS}
        label={<FormattedMessageComponent id={CONSUMPTION_CONCEPT} />}
        value={transactionDescription}
      />
      <TransactionsEppStep2AmountComponent {...TRANSACTION_EPP_STEP2_RESUME_TOTAL_INTEREST_PROPS} />
      <TransactionsEppStep2AmountComponent {...TRANSACTION_EPP_STEP2_RESUME_LOAN_AMOUNT_PROPS} />
      <TransactionsEppStep2AmountComponent {...TRANSACTION_EPP_STEP2_RESUME_TOTAL_AMOUNT_PROPS} />
      <ResumeItemComponent
        {...TRANSACTION_EPP_STEP2_RESUME_NUMBER_INSTALLMENTS_PROPS}
        label={<FormattedMessageComponent id={NUMBER_INSTALLMENTS} />}
        linkConfiguration={{
          children: <FormattedMessageComponent id={VIEW_AMORTIZATION_TABLE} />,
          onClick: handleToggleAmortizationTableModalLinkClick,
        }}
        value={
          <FormattedNumber value={plan[SimulationResumeItemsEnumeration.INSTALLMENTS].length} />
        }
      />
      <TransactionsEppStep2AmountComponent
        {...TRANSACTION_EPP_STEP2_RESUME_MONTHLY_INSTALLMENT_PAYMENT_PROPS}
      />
    </TransactionsEppStep2ResumeComponentStyled>
  );
};

export { TransactionsEppStep2ResumeComponent };
