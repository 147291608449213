// Types
import {
  FinancingPPILandingBuilderHandlersType,
  FinancingPPILandingMethodsHandlersType,
} from './types/financing-ppi-landing.handlers.type';
import { ContractPPIButtonClickHandlerType } from './types/contract-ppi-button-click-handler.type';
import { CancelPPIContractButtonClickHandlerType } from './types/cancel-ppi-contract-button-click-handler.type';
import { ViewTermsPolicyLinkClickHandlerType } from './types/view-terms-policy-link-click-handler.type';

const cancelPPIContractButtonClickHandler = ({
  route,
  handleCancelPPIContractButtonClickTracking,
  navigate,
}: CancelPPIContractButtonClickHandlerType): void => {
  handleCancelPPIContractButtonClickTracking();
  navigate(route);
};

const contractPPIButtonClickHandler = ({
  route,
  handleContractPPIButtonClickTracking,
  navigate,
}: ContractPPIButtonClickHandlerType): void => {
  handleContractPPIButtonClickTracking();
  navigate(route);
};

const viewTermsPolicyLinkClickHandler = async ({
  handleViewTermsPolicyLinkClickTracking,
}: ViewTermsPolicyLinkClickHandlerType): Promise<void> => {
  handleViewTermsPolicyLinkClickTracking();
};

const FinancingPPILandingHandlers = ({
  handleCancelPPIContractButtonClickTracking,
  handleContractPPIButtonClickTracking,
  handleViewTermsPolicyLinkClickTracking,
  navigate,
}: FinancingPPILandingBuilderHandlersType): FinancingPPILandingMethodsHandlersType => ({
  handleCancelPPIContractButtonClick: route =>
    cancelPPIContractButtonClickHandler({
      route,
      handleCancelPPIContractButtonClickTracking,
      navigate,
    }),
  handleContractPPIButtonClick: route =>
    contractPPIButtonClickHandler({
      route,
      handleContractPPIButtonClickTracking,
      navigate,
    }),
  handleViewTermsPolicyLinkClick: () =>
    viewTermsPolicyLinkClickHandler({
      handleViewTermsPolicyLinkClickTracking,
    }),
});

export { FinancingPPILandingHandlers };
