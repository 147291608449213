// Vendors
import React from 'react';
// Components
import { TransactionTableRowAmountComponent } from 'components/transaction-table-row/components/amount/transaction-table-row-amount.component';
import TableRowAssetDateComponent from 'components/table-row-asset-date/table-row-asset-date.component';
import TableRowDescriptionComponent from 'components/table-row-description/table-row-description.component';
import TransactionTableRowTagComponent from './components/tag/transaction-table-row-tag.component';
// Styles
import {
  TransactionTableRowComponentStyled,
  TransactionTableRowLeftComponentStyled,
} from './transaction-table-row.component.styled';
// Types
import { CardTransactionType } from 'types/card-transaction.type';

const TransactionTableRowComponent = ({
  amounts,
  category: { categoryCode },
  date,
  description,
  eppStatusCode,
  merchantDetails,
  poEditorNarrative,
  reward,
  transactionLabel,
  transactionStatusCode,
}: CardTransactionType): React.ReactElement<CardTransactionType> | null => (
  <TransactionTableRowComponentStyled>
    <TransactionTableRowLeftComponentStyled>
      <TableRowAssetDateComponent {...{ date, categoryCode }} />
      <TableRowDescriptionComponent
        {...{ description, poEditorNarrative, merchantDetails, reward }}
      />
      <TransactionTableRowTagComponent {...{ eppStatusCode, transactionStatusCode }} />
    </TransactionTableRowLeftComponentStyled>
    <TransactionTableRowAmountComponent {...{ amounts, transactionStatusCode, transactionLabel }} />
  </TransactionTableRowComponentStyled>
);

export default TransactionTableRowComponent;
