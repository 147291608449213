// Vendors
import styled from 'styled-components';
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const SecurityCheckComponentStyled = styled.section`
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingXxxxxl}
    ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingXxxxxl};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingXl} ${({ theme }) => theme.spaces.spacingS};
  }

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingS};
  }
`;

export { SecurityCheckComponentStyled };
