// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from 'providers/cards/services/constants/card.services.constants';
// Types
import {
  CheckCvvServiceResponse,
  CheckPinServiceResponse,
} from 'containers/card-settings/views/check-cvv-pin/types/check-cvv-pin.type';
import { CardArtsApiReturnType } from 'types/card-arts-api-return.component.type';
import { CardResourcesApiReturnComponentType } from 'types/card-resources-api-return.component.type';

const fetchCardsResourcesService = async (): Promise<
  [CardResourcesApiReturnComponentType | Record<string, any>, Response]
> => apiUtilsCb.get(constants.FETCH_CARDS_SERVICE_CONFIGURATION);

const fetchCardArtsService = async (
  productType: string
): Promise<[CardArtsApiReturnType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...constants.FETCH_CARD_ART_SERVICE_CONFIGURATION,
    pathParams: { productType },
  });

interface Props {
  cardId: string;
  progressId?: string;
}

const fetchCardCvvService = async ({
  cardId,
  progressId,
}: Props): Promise<[CheckCvvServiceResponse]> => {
  const endpoint = constants.FETCH_CARD_CVV_SERVICE_CONFIGURATION.endpoint;

  return apiUtilsCb.get({
    ...constants.FETCH_CARD_CVV_SERVICE_CONFIGURATION,
    endpoint,
    customHeaders: { progressId },
    pathParams: { cardId },
  });
};

const fetchCardPinService = async ({
  cardId,
  progressId,
}: Props): Promise<[CheckPinServiceResponse]> => {
  const endpoint = constants.FETCH_CARD_PIN_SERVICE_CONFIGURATION.endpoint;

  return apiUtilsCb.get({
    ...constants.FETCH_CARD_PIN_SERVICE_CONFIGURATION,
    endpoint,
    customHeaders: { progressId },
    pathParams: { cardId },
  });
};

export {
  fetchCardArtsService,
  fetchCardCvvService,
  fetchCardPinService,
  fetchCardsResourcesService
};
