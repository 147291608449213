// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TRANSACTIONS_EPP_STEP_2_TRACKING_PROPS } from './constants/transactions-epp-step2.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TransactionsEppStep2MethodsTrackingHandlersType } from './types/transactions-epp-step2.tracking.handlers.type';

const confirmCreateTransactionEppPlanButtonClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    ...TRANSACTIONS_EPP_STEP_2_TRACKING_PROPS,
    type: TrackingEventsEnum.LINK,
  });

const TransactionsEppStep2TrackingHandlers = (
  track: TrackBuilderType
): TransactionsEppStep2MethodsTrackingHandlersType => ({
  handleConfirmCreateTransactionEppPlanButtonClickTracking: () =>
    track(confirmCreateTransactionEppPlanButtonClickTrackingHandler()),
});

export { TransactionsEppStep2TrackingHandlers };
