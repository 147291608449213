const CARD_SETTINGS_CHANGE_3D_SIDEBAR_PROPS = {
    testId: 'card-settings-change-3d',
  },
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_01_PROPS = {
    testId: 'card-settings-change-3d-sidebar-description-01',
  },
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_02_PROPS = {
    testId: 'card-settings-change-3d-sidebar-description-02',
  },
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_03_PROPS = {
    testId: 'card-settings-change-3d-sidebar-description-03',
  };

export {
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_PROPS,
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_01_PROPS,
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_02_PROPS,
  CARD_SETTINGS_CHANGE_3D_SIDEBAR_DESCRIPTION_03_PROPS,
};
