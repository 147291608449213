// Enumeration
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const PERSONAL_LANDING_ROUTE_PROPS = {
  index: true,
};
const PERSONAL_EDIT_ADDRESS_ROUTE_PROPS = {
  path: RoutesEnumeration.EDIT_ADDRESS,
};
const PERSONAL_EDIT_EMAIL_ROUTE_PROPS = {
  path: RoutesEnumeration.EDIT_EMAIL,
};
const PERSONAL_EDIT_NUMBER_ROUTE_PROPS = {
  path: RoutesEnumeration.EDIT_PHONE_NUMBER,
};
const PERSONAL_CONSENTS_ROUTE_PROPS = {
  path: RoutesEnumeration.CONSENTS,
};
const PERSONAL_DOCUMENTATION_ROUTE_PROPS = {
  path: RoutesEnumeration.DOCUMENTATION,
};
const PERSONAL_ACCESS_PORTABILITY_ROUTE_PROPS = {
  path: RoutesEnumeration.ACCESS_PORTABILITY,
};
const PERSONAL_EDIT_PHOTO_ROUTE_PROPS = {
  path: RoutesEnumeration.EDIT_PHOTO,
};
const PERSONAL_ACCESS_REQUEST_ROUTE_PROPS = {
  path: RoutesEnumeration.ACCESS_REQUEST,
};

export {
  PERSONAL_ACCESS_PORTABILITY_ROUTE_PROPS,
  PERSONAL_ACCESS_REQUEST_ROUTE_PROPS,
  PERSONAL_EDIT_ADDRESS_ROUTE_PROPS,
  PERSONAL_CONSENTS_ROUTE_PROPS,
  PERSONAL_DOCUMENTATION_ROUTE_PROPS,
  PERSONAL_EDIT_EMAIL_ROUTE_PROPS,
  PERSONAL_EDIT_NUMBER_ROUTE_PROPS,
  PERSONAL_EDIT_PHOTO_ROUTE_PROPS,
  PERSONAL_LANDING_ROUTE_PROPS,
};
