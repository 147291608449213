// Vendors
import React from 'react';
// Components
import { UploadDocumentComponent as CommonUploadDocumentComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { UPLOAD_DOCUMENT_PROPS } from './constants/upload-document.constants';
// Hooks
import { useUploadDocumentHook } from './hooks/upload-document.hook';
// Types
import { UploadDocumentComponentPropsType } from './types/upload-document-props.component.type';
// Utils
import {
  getBackOfficeMessage,
  getFileUploadedIfNotCancelled,
  getStateFile,
  getUploadDocumentTitle,
} from './utils/upload-document.utils';

export const UploadDocumentComponent = ({
  documentId,
  documentType,
  file,
  id,
  isDisabled,
  onChangeFiles,
  reRequestFiles,
  state,
  translations: {
    ADD_LINK,
    BACK_OFFICE_ERROR_LINK,
    BACK_OFFICE_ERROR_TEXT,
    DEFAULT_LINK,
    DEFAULT_TEXT,
    DISABLED_TEXT,
    LOADING_LINK,
    REJECTED_LINK,
    REJECTED_MESSAGE,
    REJECTED_TAG,
    REJECTED_TEXT,
    SYSTEM_ERROR_LINK,
    SYSTEM_ERROR_MESSAGE,
    SYSTEM_ERROR_TAG,
    UPLOADED_LINK,
  },
  errorType,
  updateFilesOnDB,
}: UploadDocumentComponentPropsType): React.ReactElement => {
  const { isCancelled, handleAddFile, handleAddAnotherFile, handleCancelUpload, handleRemoveFile } =
    useUploadDocumentHook({
      documentId,
      documentType,
      onChangeFiles,
      file,
      id,
      reRequestFiles,
      updateFilesOnDB,
    });
  const { backOfficeErrorMessage, backOfficeErrorTag } = getBackOfficeMessage(errorType);

  return (
    <CommonUploadDocumentComponent
      {...UPLOAD_DOCUMENT_PROPS}
      onAddAnotherFile={handleAddAnotherFile}
      onCancelUpload={handleCancelUpload}
      onRemoveUploadedFile={handleRemoveFile}
      onRetryUpload={handleAddFile}
      setFile={handleAddFile}
      file={getFileUploadedIfNotCancelled({ file, isCancelled })}
      state={getStateFile({ state, isCancelled })}
      isDisabled={isDisabled}
      translations={{
        ADD_LINK: <FormattedMessageComponent id={ADD_LINK} />,
        BACK_OFFICE_ERROR_LINK: <FormattedMessageComponent id={BACK_OFFICE_ERROR_LINK} />,
        BACK_OFFICE_ERROR_MESSAGE: <FormattedMessageComponent id={backOfficeErrorMessage} />,
        BACK_OFFICE_ERROR_TAG: <FormattedMessageComponent id={backOfficeErrorTag} />,
        BACK_OFFICE_ERROR_TEXT: <FormattedMessageComponent id={BACK_OFFICE_ERROR_TEXT} />,
        DEFAULT_LINK: <FormattedMessageComponent id={DEFAULT_LINK} />,
        DEFAULT_TEXT: <FormattedMessageComponent id={DEFAULT_TEXT} />,
        DISABLED_TEXT: <FormattedMessageComponent id={DISABLED_TEXT} />,
        LOADING_LINK: <FormattedMessageComponent id={LOADING_LINK} />,
        REJECTED_LINK: <FormattedMessageComponent id={REJECTED_LINK} />,
        REJECTED_MESSAGE: <FormattedMessageComponent id={REJECTED_MESSAGE} />,
        REJECTED_TAG: <FormattedMessageComponent id={REJECTED_TAG} />,
        REJECTED_TEXT: <FormattedMessageComponent id={REJECTED_TEXT} />,
        SYSTEM_ERROR_LINK: <FormattedMessageComponent id={SYSTEM_ERROR_LINK} />,
        SYSTEM_ERROR_MESSAGE: <FormattedMessageComponent id={SYSTEM_ERROR_MESSAGE} />,
        SYSTEM_ERROR_TAG: <FormattedMessageComponent id={SYSTEM_ERROR_TAG} />,
        TITLE: <FormattedMessageComponent id={getUploadDocumentTitle(documentType)} />,
        UPLOADED_LINK: <FormattedMessageComponent id={UPLOADED_LINK} />,
      }}
    />
  );
};
