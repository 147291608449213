// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TRANSACTION_LANDING_ROW_DETAILS_CREATE_PLAN_HANDLERS_TRACKING_PROPS } from './constants/transactions-landing-row-details-create-plan.handlers.tracking.constants';
// Types
import { TransactionsLandingRowDetailsCreatePlanBuilderMethodsTrackingHandlersType } from './types/transactions-landing-row-details-plan.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const createInstallmentsPlanLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...TRANSACTION_LANDING_ROW_DETAILS_CREATE_PLAN_HANDLERS_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const TransactionsLandingRowDetailsViewInstallmentTrackingHandlers = (
  track: TrackBuilderType
): TransactionsLandingRowDetailsCreatePlanBuilderMethodsTrackingHandlersType => ({
  handleCreateInstallmentsPlanButtonClickTracking: () =>
    track(createInstallmentsPlanLinkClickTrackingHandler()),
});

export { TransactionsLandingRowDetailsViewInstallmentTrackingHandlers };
