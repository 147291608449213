// Translations
import optionsTranslations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title-dropdown-options.translations';
// Types
import { ProfilePersonalNameUtilsType } from './types/profile-personal-name.component.utils.type';
// Enums
import { CustomerTitlesEnumeration } from 'enumerations/customer-titles.enumeration';

const getTranslatedTitle = ({
  formatMessage,
  title,
}: ProfilePersonalNameUtilsType): string | null =>
  title && title !== CustomerTitlesEnumeration.NO_TITLE
    ? `${formatMessage({ id: optionsTranslations[String(title)] })} `
    : null;

export { getTranslatedTitle };
