const DATE_CONTROLLER_NAME = 'date';

const DATE_CONTROLLER_RULES = {
  required: true,
};

const DATE_INPUT_PROPS = {
  placeholder: 'DD. MM. YYYY',
  isRequired: true,
  testId: 'transactions-report-not-received-additional-info-date-input',
};

const DESCRIPTION_CONTROLLER_NAME = 'description';

const DESCRIPTION_CONTROLLER_RULES = {
  required: true,
};

const DESCRIPTION_INPUT_PROPS = {
  limitCharsConfiguration: {
    limit: 600,
  },
  isRequired: true,
  testId: 'transactions-report-not-received-additional-info-description-input',
};

export {
  DATE_CONTROLLER_NAME,
  DATE_CONTROLLER_RULES,
  DATE_INPUT_PROPS,
  DESCRIPTION_CONTROLLER_NAME,
  DESCRIPTION_CONTROLLER_RULES,
  DESCRIPTION_INPUT_PROPS,
};
