// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  ASSET_PROPS: {
    id: 'info',
    colors: {
      fill: 'linkPrimaryA',
    },
  },
  PROPS: {
    testId: 'total-amount-component',
  },
  TITLE_PARAGRAPH_PROPS: {
    size: paragraphConstants.SIZES.S,
  },
  DESCRIPTION_PARAGRAPH_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.L,
    testId: 'total-amount-description',
  },
  DESCRIPTION_PARAGRAPH_COMISSION_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.L,
    testId: 'total-amount-commission-description',
  },
};
