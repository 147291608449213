// Vendors
import React from 'react';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CONTRACT_MODAL_IMAGE_PROPS } from './constants/financing-insurances-landing-card-contract-modal.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FinancingInsurancesLandingCardContractModalContentStyled } from './financing-insurances-landing-card-contract-modal.component.styled';
// Translations
import {
  BUTTON,
  DESCRIPTION,
  TITLE,
} from './translations/financing-insurances-landing-card-contract-modal.translations';
// Types
import { FinancingInsurancesLandingCardContractModalComponentType } from './types/financing-insurances-landing-card-contract-modal.component.type';

export const FinancingInsurancesLandingCardContractModalComponent = ({
  contractingCoolingOffPeriodEndDate,
  handleCloseContractModalButtonClick,
  handleUnderstoodButtonClick,
  insuranceTitle,
  isContractModalOpen,
}: FinancingInsurancesLandingCardContractModalComponentType): React.ReactElement | null => {
  const { formatDate, formatMessage } = useCbIntl();

  if (!isContractModalOpen) {
    return null;
  }

  return (
    <ModalComponent
      primaryButtonConfiguration={{
        children: <FormattedMessageComponent id={BUTTON} />,
        onClick: handleUnderstoodButtonClick,
      }}
      title={<FormattedMessageComponent id={TITLE} />}
      onHeaderClose={handleCloseContractModalButtonClick}
    >
      <FinancingInsurancesLandingCardContractModalContentStyled>
        <AssetComponent {...CONTRACT_MODAL_IMAGE_PROPS} />
        <ParagraphComponent>
          <FormattedMessageComponent
            id={DESCRIPTION}
            values={{
              contractDate: formatDate(contractingCoolingOffPeriodEndDate),
              insuranceTitle: formatMessage({ id: insuranceTitle }),
            }}
          />
        </ParagraphComponent>
      </FinancingInsurancesLandingCardContractModalContentStyled>
    </ModalComponent>
  );
};
