// Vendors
import React from 'react';
// Components
import { FetchErrorComponent, NavigationFooterComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import OtpSectionComponent from './components/section/otp-section.component';
import OtpSidebarComponent from './components/sidebar/otp-sidebar.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Constants
import {
  OTP_BUTTON_PROPS,
  OTP_SMS_SECTION_PROPS,
  OTP_EMAIL_SECTION_PROPS,
} from './constants/otp.constants';
// Hooks
import useOtpHook from './hooks/otp.hook';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { OtpContainerStyled } from './otp.container.styled';
// Translations
import { LOAD_LAST } from './translations/otp.translations';

const OtpContainer = (): React.ReactElement => {
  const { fetching, emailMessage, handleLoadLastUserMessagesButtonClick, smsMessage } =
    useOtpHook();

  return (
    <LayoutGridComponentStyled>
      <TitleSectionComponent />
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <AppCardSectionComponentStyled>
            <AppCardContentSectionComponentStyled>
              <FetchErrorComponent {...{ fetching }}>
                <OtpContainerStyled>
                  <OtpSectionComponent {...OTP_SMS_SECTION_PROPS} message={smsMessage} />
                  <OtpSectionComponent {...OTP_EMAIL_SECTION_PROPS} message={emailMessage} />
                  <NavigationFooterComponent
                    rightButtonConfiguration={{
                      ...OTP_BUTTON_PROPS,
                      children: <FormattedMessageComponent id={LOAD_LAST} />,
                      onClick: handleLoadLastUserMessagesButtonClick,
                    }}
                  />
                </OtpContainerStyled>
              </FetchErrorComponent>
            </AppCardContentSectionComponentStyled>
          </AppCardSectionComponentStyled>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <OtpSidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </LayoutGridComponentStyled>
  );
};

export default OtpContainer;
