// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
// Types
import { TransactionsReportDifferentAmountAtmContextType } from 'containers/transactions/views/report/components/different-amount-atm/context/types/transactions-report-different-amount-atm.type';

const determineClaimReasonType = ({
  formValues,
}: Pick<TransactionsReportDifferentAmountAtmContextType, 'formValues'>): ClaimReasonEnum =>
  formValues.movementAtmImportReceived === NUMBER.ZERO
    ? ClaimReasonEnum.CASH_NOT_DISPENSED
    : ClaimReasonEnum.CASH_PARTIALLY_DISPENSED;

export { determineClaimReasonType };
