// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Event Handlers
import { FinancingPPILandingHandlers } from '../handlers/financing-ppi-landing.handlers';
import { FinancingPPILandingTrackingHandlers } from '../handlers/financing-ppi-landing.handlers.tracking';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIReturnHookType } from './types/financing-ppi-landing.hook.type';

const useFinancingPPILandingHook = (): FinancingPPIReturnHookType => {
  const { insurancesInfo } = useInsurancesContextConsumerHook();

  const {
    localization: {
      config: { cfg_public_cpi_general_tnc, cfg_public_lpi_general_tnc },
    },
  } = AppContextConsumerHook();

  return {
    ...FinancingPPILandingHandlers({
      ...AppTrackingHook(FinancingPPILandingTrackingHandlers),
      navigate: useNavigate(),
    }),
    insurancesInfo,
    tnc_cpi: cfg_public_cpi_general_tnc,
    tnc_lpi: cfg_public_lpi_general_tnc,
  };
};

export { useFinancingPPILandingHook };
