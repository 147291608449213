// Vendors
import React from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, RadioButtonComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getRadioButtonConfiguration } from './configurations/personal-number-step3-confirmation-option.component.configurations';
// Constants
import constants from './constants/personal-number-step3-confirmation-option.constants';
// Hooks
import PersonalNumberStep3ConfirmationOptionHook from './hooks/personal-number-step3-confirmation-option.hook';
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';
// Styles
import { PersonalNumberStep3ConfirmationOptionComponentStyled } from './personal-number-step3-confirmation-option.component.styled';
// Utils
import { getCorrectTextLabel } from './utils/personal-number-step3.utils';

const PersonalNumberStep3ConfirmationOptionComponent = (): React.ReactElement => {
  const { confirmationCodeOption, handleConfirmationOptionChange } =
      PersonalNumberStep3ConfirmationOptionHook(),
    { primaryConfigurablePhone, currentEmail } = PersonalNumberHook(),
    { formatMessage } = useCbIntl();

  return (
    <PersonalNumberStep3ConfirmationOptionComponentStyled>
      {constants.CONFIRMATION_OPTIONS.map(({ eventLabel, id, testId, value }) => (
        <RadioButtonComponent
          key={testId}
          {...getRadioButtonConfiguration({
            testId,
            confirmationCodeOption,
            eventLabel,
            handleConfirmationOptionChange,
            value,
          })}
        >
          {primaryConfigurablePhone && (
            <ParagraphComponent testId={`${testId}-paragraph`}>
              <FormattedMessageComponent
                id={getCorrectTextLabel({
                  id,
                  phone: primaryConfigurablePhone,
                  email: currentEmail,
                  formatMessage,
                })}
              />
            </ParagraphComponent>
          )}
        </RadioButtonComponent>
      ))}
    </PersonalNumberStep3ConfirmationOptionComponentStyled>
  );
};

export default PersonalNumberStep3ConfirmationOptionComponent;
