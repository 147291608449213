// Enumerations
import { PhotoModalIdsEnumeration } from 'containers/personal/views/photo/enumerations/photo-modal-ids.enumeration';
// Types
import { PersonalPhotoNavigationFooterUiBuilderReturnHandlersType } from './types/personal-photo-navigation-footer-ui-builder-return.handlers.type';
import { PersonalPhotoNavigationFooterUiBuilderHandlersType } from './types/personal-photo-navigation-footer-ui-builder.handlers.type';

const cancelUploadPhotoLinkClickEventHandler = ({
  handleCancelUploadPhotoLinkClickTracking,
  setPhotoModal,
}: Pick<
  PersonalPhotoNavigationFooterUiBuilderHandlersType,
  'handleCancelUploadPhotoLinkClickTracking' | 'setPhotoModal'
>): any => {
  handleCancelUploadPhotoLinkClickTracking();
  setPhotoModal(PhotoModalIdsEnumeration.CANCEL_OPERATION);
};

const hideCancelUploadModalClickEventHandler = ({
  setPhotoModal,
}: Pick<PersonalPhotoNavigationFooterUiBuilderHandlersType, 'setPhotoModal'>): any => {
  setPhotoModal(null);
};

const PersonalPhotoNavigationFooterUiHandlers = ({
  handleCancelUploadPhotoLinkClickTracking,
  setPhotoModal,
}: PersonalPhotoNavigationFooterUiBuilderHandlersType): PersonalPhotoNavigationFooterUiBuilderReturnHandlersType => ({
  handleCancelUploadPhotoLinkClick: () =>
    cancelUploadPhotoLinkClickEventHandler({
      handleCancelUploadPhotoLinkClickTracking,
      setPhotoModal,
    }),
  handleHideCancelUploadModalClickEvent: () =>
    hideCancelUploadModalClickEventHandler({ setPhotoModal }),
});

export default PersonalPhotoNavigationFooterUiHandlers;
