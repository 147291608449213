const DISABLED_DEFAULT_VALUE = false,
  HAS_LEFT_LINK_DEFAULT_VALUE = true,
  HAS_LEFT_BUTTON_DEFAULT_VALUE = false,
  HAS_RIGHT_BUTTON_DEFAULT_VALUE = true,
  SHOW_MODAL_DEFAULT_VALUE = false,
  WIZARD_STEP_FOOTER_BUTTON_PROPS = {
    testId: 'wizard-step-footer-right-button',
  },
  WIZARD_STEP_FOOTER_LEFT_BUTTON_PROPS = {
    testId: 'wizard-step-footer-left-button',
  },
  WIZARD_STEP_FOOTER_LEFT_LINK_PROPS = {
    testId: 'wizard-step-footer-left-link',
  },
  WIZARD_STEP_FOOTER_RIGHT_LINK_PROPS = {
    testId: 'wizard-step-footer-right-link',
  },
  WIZARD_STEP_FOOTER_RIGHT_BUTTON_ASSET_PROPS = {
    assetConfiguration: {
      id: 'chevron_right',
      sizes: {
        height: 20,
        width: 20,
      },
      testId: 'wizard-step-footer-right-asset',
    },
  };

export {
  DISABLED_DEFAULT_VALUE,
  HAS_LEFT_LINK_DEFAULT_VALUE,
  HAS_LEFT_BUTTON_DEFAULT_VALUE,
  HAS_RIGHT_BUTTON_DEFAULT_VALUE,
  SHOW_MODAL_DEFAULT_VALUE,
  WIZARD_STEP_FOOTER_RIGHT_BUTTON_ASSET_PROPS,
  WIZARD_STEP_FOOTER_BUTTON_PROPS,
  WIZARD_STEP_FOOTER_LEFT_LINK_PROPS,
  WIZARD_STEP_FOOTER_RIGHT_LINK_PROPS,
  WIZARD_STEP_FOOTER_LEFT_BUTTON_PROPS,
};
