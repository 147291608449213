// Enumerations
import { ConfirmationOptionEnumeration } from '../../confirmation-option/enumerations/confirmation-option.enumeration';

const modifyPersonalPhoneStep3NextButtonIsEnabled = (
  confirmationCodeOption: ConfirmationOptionEnumeration | null
): boolean =>
  confirmationCodeOption === ConfirmationOptionEnumeration.PHONE ||
  confirmationCodeOption === ConfirmationOptionEnumeration.EMAIL;

export { modifyPersonalPhoneStep3NextButtonIsEnabled };
