// Resolvers
import { getAccessPortabilityInformationResolver } from '../resolvers/access-portability.resolver';
// Types
import { AccessPortabilityBuilderReturnHandlersType } from './types/access-portability-builder-return.handlers.type';
import { AccessPortabilityComponentType } from '../types/access-portability.component.type';
import { AccessPortabilityBuilderHandlersType } from './types/access-portability-builder.handlers.type';

const fetchAccessPortabilityInformationEventHandler = async ({
  rightsType,
  setPageLoader,
  setError,
  setInformation,
}: AccessPortabilityBuilderHandlersType): Promise<void> => {
  setPageLoader(true);

  const [response, error] = await getAccessPortabilityInformationResolver(rightsType);

  setPageLoader(false);
  error ? setError(true) : setInformation(response as AccessPortabilityComponentType);
};

const AccesPortabilityHandlers = (
  props: AccessPortabilityBuilderHandlersType
): AccessPortabilityBuilderReturnHandlersType => ({
  handleFetchAccessPortabilityInformationEvent: () =>
    fetchAccessPortabilityInformationEventHandler(props),
});

export default AccesPortabilityHandlers;
