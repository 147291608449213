// Vendors
import React, { useState, useContext, createContext } from 'react';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
// Constants
import constants from './constants/access-portability-request-step2.context.constants';
// Types
import { AccessPortabilityRequestStep2ContextType } from './types/access-portability-request-step2.context.type';

const AccessPortabilityRequestStep2Context =
  createContext<AccessPortabilityRequestStep2ContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const AccessPortabilityRequestStep2ContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<AccessPortabilityRequestStep2ContextType>
> => {
  const [otpId, setOtpId] = useState<string>('');
  const [otpValue, setOtpValue] = useState<string>('');

  return (
    <AccessPortabilityRequestStep2Context.Provider
      value={{ otpId, otpValue, setOtpId, setOtpValue }}
    >
      {children as React.ReactNode}
    </AccessPortabilityRequestStep2Context.Provider>
  );
};

export const AccessPortabilityRequestStep2ContextConsumerHook =
  (): AccessPortabilityRequestStep2ContextType => useContext(AccessPortabilityRequestStep2Context);

/* eslint-disable */
export const AccessPortabilityRequestStep2ContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<AccessPortabilityRequestStep2ContextType>> =>
    (
      <AccessPortabilityRequestStep2ContextProvider>
        <Component {...props} />
      </AccessPortabilityRequestStep2ContextProvider>
    );
