// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/access-portability-request-step2.services.constants';
// Enums
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Types
import { ChallengeType } from 'types/challenge.type';
import { AccessPortabilityRequestStep2GenerateApiBuilderType } from '../types/access-portability-request-step2-generate-api-builder.type';

const generateAccessPortabilityRequestOtpService = async ({
  progressId,
}: AccessPortabilityRequestStep2GenerateApiBuilderType): Promise<[ChallengeType, Response]> => {
  const customHeaders = { deviceLocale: 'de_DE', applicationLocale: 'de_DE', bankId: 'MB_DEU' };

  if (progressId) {
    customHeaders['progressId'] = progressId;
  }

  return apiUtilsCb.post({
    ...constants.GENERATE_OTP_SERVICE_CONFIGURATION,
    customHeaders,
    params: { type: AccessPortabilityTypeEnumeration.ACCESS, includePersonalData: true },
  });
};

export { generateAccessPortabilityRequestOtpService };
