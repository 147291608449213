// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { SettingsCardControlLocationsContextConsumerHook } from '../contexts/settings-card-control-locations.context';
// Events handlers
import SettingsCardControlLocationsHandlers from '../handlers/settings-card-control-locations.handlers';
// Types
import { SettingsCardControlLocationsHookType } from './types/settings-card-control-locations.hook.type';

const SettingsCardControlLocationsHook = (): SettingsCardControlLocationsHookType => {
  const { currentCardDetail } = AppContextConsumerHook(),
    { continents, mainCountry, setContinents, setMainCountry } =
      SettingsCardControlLocationsContextConsumerHook(),
    { isAtmCashAllowed, isInPersonPurchaseAllowed } = currentCardDetail?.control || {},
    { id: cardId } = currentCardDetail;

  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const { handleFetchCardControlLocationsEvent } = SettingsCardControlLocationsHandlers({
    cardId,
    setContinents,
    setFetching,
    setError,
    setMainCountry,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchCardControlLocationsEvent();
  }, []);

  return {
    fetching,
    error,
    continents,
    isAtmCashAllowed,
    isInPersonPurchaseAllowed,
    mainCountry,
  };
};

export default SettingsCardControlLocationsHook;
