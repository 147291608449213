const DOMAIN_EARLY_REPAYMENT_FIRST_STEP_INSTALMENT_STATUS =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.firstStep.instalmentStatus.';

const TITLE = `${DOMAIN_EARLY_REPAYMENT_FIRST_STEP_INSTALMENT_STATUS}title`;

const PENDING_AMOUNT = {
  label: `${DOMAIN_EARLY_REPAYMENT_FIRST_STEP_INSTALMENT_STATUS}pendingAmount.label`,
  tooltip: `${DOMAIN_EARLY_REPAYMENT_FIRST_STEP_INSTALMENT_STATUS}pendingAmount.tooltip`,
};

export { TITLE, PENDING_AMOUNT };
