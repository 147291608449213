// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingEarlyRepaymentContextConsumerHook } from 'containers/financing/views/early-repayment/contexts/financing-early-repayment.context';
// Handlers
import FinancingEarlyRepaymentSecondStepChallengeHandlers from '../handlers/financing-early-repayment-second-step-challenge.handlers';
// Types
import { FinancingEarlyRepaymentSecondStepChallengeReturnHookType } from './types/financing-early-repayment-second-step-challenge-return.hook.type';

const FinancingEarlyRepaymentSecondStepChallengeHook =
  (): FinancingEarlyRepaymentSecondStepChallengeReturnHookType => {
    const { eppId } = useParams();

    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard,
      { challenge, setChallenge, setChallengeError, setError } =
        FinancingEarlyRepaymentContextConsumerHook();

    return {
      ...FinancingEarlyRepaymentSecondStepChallengeHandlers({
        challenge,
        setChallenge,
        setChallengeError,
        setError,
      }),
      cardId,
      eppId,
    };
  };

export default FinancingEarlyRepaymentSecondStepChallengeHook;
