// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { WIZARD_CURRENT_STEP } from '../constants/wizard-step-footer.constants';
// Types
import { WizardContextType } from './types/wizard.context.type';
import { WizardPropsContextType } from './types/wizard-props.context.type';
import { WizardComponentType } from '../types/wizard.component.type';

const WizardContext = createContext<WizardContextType>({} as WizardContextType);

const WizardContextProvider = ({
  step,
  children,
}: WizardPropsContextType): React.ReactElement<React.Provider<WizardContextType>> => {
  const [currentStep, setCurrentStep] = useState<WizardContextType['currentStep']>(step);

  return (
    <WizardContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </WizardContext.Provider>
  );
};

const useWizardContextConsumerHook = (): WizardContextType => useContext(WizardContext);

/* eslint-disable */
const WizardContextConsumerHOC =
  (Component: React.FunctionComponent<WizardComponentType>): React.FunctionComponent<any> =>
  (props: any): React.ReactElement<React.Provider<WizardContextType>> =>
    (
      <WizardContextProvider step={props.currentStep || WIZARD_CURRENT_STEP}>
        <Component {...props} />
      </WizardContextProvider>
    );

export { WizardContextConsumerHOC, WizardContextProvider, useWizardContextConsumerHook };
