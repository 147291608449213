// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, ScriptBoxComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_3_MESSAGE_TITLE_PROPS,
  PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS,
} from './constants/personal-email-step3-script-box.constants.cc';
// Styles
import {
  PersonalEmailStep3ScriptBoxViewStyled,
  PersonalEmailStep3ScriptBoxComponentStyled,
} from './personal-email-step3-script-box.component.styled.cc';
// Translations
import { MESSAGE_TITLE } from './translations/personal-email-step3-script-box.translations.cc';
import { MESSAGE_DESCRIPTION } from '../message/translations/personal-email-step3-message.translations.commons';
// Utils
import { isCC } from 'utils/app.utils';

const PersonalEmailStep3ScriptBoxComponent = (): React.ReactElement | null => {
  return isCC() ? (
    <PersonalEmailStep3ScriptBoxComponentStyled>
      <ScriptBoxComponent {...PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS}>
        <PersonalEmailStep3ScriptBoxViewStyled>
          <ParagraphComponent {...PERSONAL_EMAIL_STEP_3_MESSAGE_TITLE_PROPS}>
            <FormattedMessageComponent id={MESSAGE_TITLE} />
          </ParagraphComponent>
          <ParagraphComponent {...PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS}>
            <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
          </ParagraphComponent>
        </PersonalEmailStep3ScriptBoxViewStyled>
      </ScriptBoxComponent>
    </PersonalEmailStep3ScriptBoxComponentStyled>
  ) : null;
};

export { PersonalEmailStep3ScriptBoxComponent };
