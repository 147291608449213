const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyPhoneNumber.step1.';

const TITLE = `${DOMAIN}title`,
  PARAGRAPH = `${DOMAIN}description`,
  CURRENT_PHONE = `${DOMAIN}currentPhoneLabel`,
  CURRENT_NEW = `${DOMAIN}newPhoneLabel`,
  MESSAGE_TEXT = `${DOMAIN}cbMessage.description`,
  MESSAGE_TITLE = `${DOMAIN}cbMessage.title`,
  ERROR_MESSAGE_TEXT = `${DOMAIN}cbMessage.excededErrorDescription`,
  NEXT = `${DOMAIN}nextButton`,
  CANCEL = `${DOMAIN}cancelButton`;

export {
  TITLE,
  PARAGRAPH,
  CURRENT_PHONE,
  CURRENT_NEW,
  MESSAGE_TEXT,
  MESSAGE_TITLE,
  ERROR_MESSAGE_TEXT,
  NEXT,
  CANCEL,
};
