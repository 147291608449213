const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.financingLanding.cbTable.downloadModal.';

const MODAL_TITLE = `${DOMAIN}modalTitle`,
  PDF_DOWNLOAD = `${DOMAIN}downloadPDF`,
  XLS_DOWNLOAD = `${DOMAIN}downloadXLS`,
  CANCEL = `${DOMAIN}cancel`,
  DESCRIPTION = `${DOMAIN}description`,
  DOWNLOAD_ERROR = `${DOMAIN}downloadError`;

export { MODAL_TITLE, PDF_DOWNLOAD, XLS_DOWNLOAD, CANCEL, DESCRIPTION, DOWNLOAD_ERROR };
