import { AccessPortabilityStatusEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.accessPortability.accessPortabilityLanding.requestTable.';

const ACCESS_PORTABILITY_REQUEST_LABELS = {
  [AccessPortabilityStatusEnumeration.AVAILABLE]: `${DOMAIN}cbTag.currentLabel`,
  [AccessPortabilityStatusEnumeration.REQUESTED]: `${DOMAIN}cbTag.requestedLabel`,
  [AccessPortabilityStatusEnumeration.IN_PROGRESS]: `${DOMAIN}cbTag.inProgressLabel`,
  [AccessPortabilityStatusEnumeration.EXPIRED]: `${DOMAIN}cbTag.expiredLabel`,
};

const ACCESS_PORTABILITY_DOWNLOAD_BUTTON = `${DOMAIN}downloadButton`;

export { ACCESS_PORTABILITY_DOWNLOAD_BUTTON, ACCESS_PORTABILITY_REQUEST_LABELS };
