// Enumerations
import { OperativeChallengeEnumeration } from 'enumerations/operative-challenge.enumeration';
// Typess
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';

const isApiChallengeFlow = (id: OperativeChallengeEnumeration): boolean =>
  Boolean(id === OperativeChallengeEnumeration.IN_CHALLENGE);

const isLegitSSSChallengeResponse = (
  response: ChallengeSssApiResponseType | Record<string, any>
): boolean => Boolean(response?.progressId && response?.otpId);

export { isApiChallengeFlow, isLegitSSSChallengeResponse };
