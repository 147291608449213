// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Enumerations
import { MiscellaneousCurrenciesEnum } from '@openbank/cf-ui-static-data';
// Hooks
import { useTransactionsEppModalHook } from 'containers/transactions/views/epp/hooks/transactions-epp-modal.hook';
// Types
import { TransactionsEppStep1SimulationSummaryHookType } from './types/transactions-epp-step1-simulation-summary.hook.type';
// Utilities
import { getEppInstallmentPlanLoanAmount } from './utils/transactions-epp-step1-simulation-summary.hook.utils';

const useTransactionsEppStep1SimulationSummaryHook =
  (): TransactionsEppStep1SimulationSummaryHookType => {
    const { eppPlanSimulation, transactionDetails } = useTransactionsEppContextConsumerHook(),
      { totalAmount } = transactionDetails?.amounts?.[0] || {},
      {
        currencyCode = MiscellaneousCurrenciesEnum.EURO,
        loanAmount,
        ...simulation
      } = eppPlanSimulation;

    const { handleToggleAmortizationTableModalLinkClick } = useTransactionsEppModalHook();

    return {
      currencyCode,
      eppPlanSimulation: simulation,
      handleToggleAmortizationTableModalLinkClick,
      loanAmount: getEppInstallmentPlanLoanAmount({ loanAmount, totalAmount }),
    };
  };

export { useTransactionsEppStep1SimulationSummaryHook };
