const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.securityCheck.modal';

const TITLE = `${DOMAIN}.title`;
const LINK = `${DOMAIN}.link`;
const FIRST_STEP = {
  BUTTON: `${DOMAIN}.firstStep.button`,
  CONTENT: `${DOMAIN}.firstStep.content`,
};
const SECOND_STEP = {
  BUTTON: `${DOMAIN}.secondStep.button`,
  CONTENT: `${DOMAIN}.secondStep.content`,
};

export { TITLE, LINK, FIRST_STEP, SECOND_STEP };
