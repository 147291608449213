// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const CardSettingsActivateCardIntroductionDescriptionComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;

export const MessageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const CardSettingsActivateCardIntroductionComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const CardSettingsActivateCardIntroductionCardDetailsStyled = styled.div`
  ${mobileMediaQuery} {
    margin: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const CardSettingsActivateCardDetailsWrappedStyled = styled.div`
  padding: ${convertPxsToRems({ pixels: 8, base: 14 })}
    ${convertPxsToRems({ pixels: 16, base: 14 })};
`;
