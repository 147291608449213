// Types
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import {
  OrchestratorBuilderHandlersType,
  OrchestratorBuilderReturnHandlersType,
} from './types/orchestrator-builder.handlers.type';

const makeUserLogoutButtonClickHandler = async ({
  handleMakeUserLogoutButtonClickTracking,
  setShowModal,
}: OrchestratorBuilderHandlersType): Promise<void> => {
  handleMakeUserLogoutButtonClickTracking();
  setShowModal(true);
};

const makeUserCancelClickHandler = ({ setShowModal }: OrchestratorBuilderHandlersType): void => {
  setShowModal(false);
};

const makeUserClickPersonalDataHandler = ({ navigate }: OrchestratorBuilderHandlersType): void => {
  navigate(RoutesEnumeration.PERSONAL_INFORMATION);
};

const makeUserLogoutClickHandler = async ({
  handleMakeUserLogoutEvent,
}: OrchestratorBuilderHandlersType): Promise<void> => {
  await handleMakeUserLogoutEvent();
};

const OrchestratorHeaderHandlers = (
  props: OrchestratorBuilderHandlersType
): OrchestratorBuilderReturnHandlersType => ({
  handleMakeUserLogoutButtonClick: () => makeUserLogoutButtonClickHandler(props),
  handleMakeUserLogoutClickHandler: () => makeUserLogoutClickHandler(props),
  handleMakeUserCancelClickHandler: () => makeUserCancelClickHandler(props),
  handleMakePersonalDataClickHandler: () => makeUserClickPersonalDataHandler(props),
});

export default OrchestratorHeaderHandlers;
