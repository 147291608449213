const DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.wizard.success.content.';

const TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}title`;

const FIRST_STEP_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}nextSteps.first.title`;

const FIRST_STEP_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}nextSteps.first.description`;

const SECOND_STEP_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}nextSteps.second.title`;

const SECOND_STEP_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}nextSteps.second.description`;

const THIRD_STEP_TITLE = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}nextSteps.third.title`;

const THIRD_STEP_DESCRIPTION = `${DOMAIN_REPORT_A_PROBLEM_WIZARD_SUCCESS_CONTENT}nextSteps.third.description`;

export {
  FIRST_STEP_DESCRIPTION,
  FIRST_STEP_TITLE,
  SECOND_STEP_DESCRIPTION,
  SECOND_STEP_TITLE,
  THIRD_STEP_DESCRIPTION,
  THIRD_STEP_TITLE,
  TITLE,
};
