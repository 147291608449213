const CHANGE_TITLE_SERVICE_CONFIGURATION = {
    endpoint: 'cb-api-customers/v2/customers/me/title',
  },
  FETCH_MISCELLANEOUS_SERVICE_CONFIGURATION_CUSTOM_HEADERS = {
    customHeaders: {
      Accept: 'application/json',
    },
  },
  FETCH_MISCELLANEOUS_SERVICE_CONFIGURATION = {
    endpoint: 'cb-api-localization/v1/misc-tables?tables=title',
    ...FETCH_MISCELLANEOUS_SERVICE_CONFIGURATION_CUSTOM_HEADERS,
  };

export { CHANGE_TITLE_SERVICE_CONFIGURATION, FETCH_MISCELLANEOUS_SERVICE_CONFIGURATION };
