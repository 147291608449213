// Constants
import { AmazonTheme } from '@openbank/cf-ui-static-data';
import { titleConstants } from '@openbank/cb-ui-commons';

export const PERSONAL_SIDEBAR_CC_PROPS = {
    testId: 'personal-cc',
    isCC: true,
  },
  PERSONAL_SIDEBAR_CC_DESCRIPTION_PROPS = {
    testId: 'personal-sidebar-cc-description',
    tag: titleConstants.TAGS.H4,
    bold: true,
  },
  PERSONAL_SIDEBAR_CC_ALIAS_PROPS = {
    testing: {
      labelId: 'personal-sidebar-cc-alias-label',
      valueId: 'personal-sidebar-cc-alias-value',
    },
  },
  PERSONAL_SIDEBAR_CC_SEGMENT_PROPS = {
    testing: {
      labelId: 'personal-sidebar-cc-segment-label',
      valueId: 'personal-sidebar-cc-segment-value',
    },
  },
  PERSONAL_SIDEBAR_CC_COLLECTIVE_PROPS = {
    testing: {
      labelId: 'personal-sidebar-cc-collective-label',
      valueId: 'personal-sidebar-cc-collective-value',
    },
  },
  PERSONAL_SIDEBAR_CC_OFFICE_PROPS = {
    testing: {
      labelId: 'personal-sidebar-cc-office-label',
      valueId: 'personal-sidebar-cc-office-value',
    },
  },
  PERSONAL_SIDEBAR_CC_LINE_SEPARATOR_PROPS = {
    color: AmazonTheme.default.colors.backgroundTertiaryD,
  },
  PERSONAL_SIDEBAR_CC_NAME_TITLE_EDIT_ASSET_PROPS = {
    id: 'edit',
    colors: {
      fill: 'linkCallCenterC',
      hover: 'linkCallCenterD',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'profile-personal-name-title-edit-asset',
  },
  PERSONAL_SIDEBAR_CC_HOURS_EDIT_ASSET_PROPS = {
    id: 'edit',
    colors: {
      fill: 'linkCallCenterC',
      hover: 'linkCallCenterD',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'profile-personal-name-title-edit-asset',
  };
