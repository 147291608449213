// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
// Types
import { ChangeIbanStep2HookType } from './types/change-iban-step2.hook.type';

const ChangeIbanStep2Hook = (): ChangeIbanStep2HookType => {
  const { formatMessage } = useCbIntl();
  const { ibanValue } = ChangeIbanContextConsumerHook();

  return {
    formatMessage,
    ibanValue,
  };
};

export { ChangeIbanStep2Hook };
