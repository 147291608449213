// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { FormattedNumber } from 'react-intl';
// Components
import { AssetComponent, ParagraphComponent, ProgressBarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/dashboard-spent-expenses-list-item.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Enumerations
import { MiscellaneousCurrenciesEnum } from '@openbank/cf-ui-static-data';
// Styles
import {
  DashboardSpentExpensesListItemComponentStyled,
  ExpensesListItemCategoryWrapperStyled,
  ExpensesListItemInnerContainerStyled,
  ExpensesListItemAmountWrapperStyled,
} from './dashboard-spent-expenses-list-item.styled';
// Translations
import translations from 'containers/transactions/views/landing/components/row/components/details/components/category/translations/transactions-landing-row-details-category.translations';
// Types
import { ExpensesListItemComponentType } from './types/dashboard-spent-expenses-list-item.component.type';
// Utils
import { getCategoryAsset } from 'utils/category.utils';

const DashboardSpentExpensesListItemComponent = ({
  amount,
  categoryCode,
  currency = MiscellaneousCurrenciesEnum.EURO,
  step,
  testId = constants.EXPENSES_LIST_ITEM_DEFAULT_TEST_ID,
}: ExpensesListItemComponentType): React.ReactElement => (
  <DashboardSpentExpensesListItemComponentStyled {...{ testId }}>
    <ExpensesListItemCategoryWrapperStyled>
      <AssetComponent
        id={getCategoryAsset(categoryCode)}
        {...constants.EXPENSES_LIST_ITEM_ASSET_PROPS}
      />
      <ExpensesListItemInnerContainerStyled>
        <ParagraphComponent {...constants.EXPENSES_LIST_ITEM_TITLE_PROPS}>
          <FormattedMessageComponent id={translations.CATEGORY_VALUE + categoryCode} />
        </ParagraphComponent>
        <ProgressBarComponent
          currentStep={step}
          lastStep={constants.EXPENSES_LIST_ITEM_LAST_STEP}
        />
      </ExpensesListItemInnerContainerStyled>
    </ExpensesListItemCategoryWrapperStyled>
    <ExpensesListItemAmountWrapperStyled>
      <ParagraphComponent {...constants.EXPENSES_LIST_ITEM_AMOUNT_PROPS}>
        <FormattedNumber
          {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
          value={amount}
        />
      </ParagraphComponent>
    </ExpensesListItemAmountWrapperStyled>
  </DashboardSpentExpensesListItemComponentStyled>
);

export default DashboardSpentExpensesListItemComponent;
