// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { FinancingPPIContractingStep2TncHookType } from 'containers/financing/views/ppi/components/contracting/components/step2/components/tnc/hooks/types/financing-ppi-contracting-step2-tnc.hook.type';
import useCbIntl from 'hooks/useCbIntl';

const useFinancingPPIContractingStep2TNCHook = (): FinancingPPIContractingStep2TncHookType => {
  const { localization } = AppContextConsumerHook(),
    { cfg_public_cpi_privacy_note: url } = localization?.config || {};

  return { url };
};

export { useFinancingPPIContractingStep2TNCHook };
