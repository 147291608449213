// Vendors
import React from 'react';
// Components
import {
  FetchErrorComponent,
  ParagraphComponent,
  TitleHeaderComponent,
} from '@openbank/cb-ui-commons';
import AccessPortabilityRequestTableComponent from '../request-table/access-portability-request-table.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DEFAULT_CARD_CONTENT_TESTING_PROPS,
  ACCESS_PORTABILITY_DEFAULT_ERROR_CONFIGURATION,
  BUTTON_IDS,
} from './constants/access-portability-card.constants';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Hooks
import AccessPortabilityCardHook from './hooks/access-portability-card.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import {
  PersonalAccessPortabilityCardContentStyled,
  AccessPortabilityCardDescriptionsStyled,
} from './access-portability-card.component.styled';
// Translations
import translations from './translations/access-portability-card.translations';
// Types
import { AccesPortabilityCardComponentType } from './types/access-portability-card.component.type';
import useAccessPortabilityHook from '../../hooks/access-portability.hook';

const AccessPortabilityCardComponent = ({
  rightsType,
  testing: { descriptionId, titleId } = DEFAULT_CARD_CONTENT_TESTING_PROPS,
}: AccesPortabilityCardComponentType): React.ReactElement => {
  const { formatMessage, handleAccessPortabilityRequestClick, email } =
    AccessPortabilityCardHook(rightsType);
  const { information, pageLoader } = useAccessPortabilityHook({ rightsType }),
    { empty } = information;

  return (
    <AppCardSectionComponentStyled>
      <PersonalAccessPortabilityCardContentStyled>
        <TitleHeaderComponent testId={titleId}>
          <FormattedMessageComponent id={translations[rightsType].TITLE} />
        </TitleHeaderComponent>
        <AccessPortabilityCardDescriptionsStyled>
          {translations[rightsType].DESCRIPTIONS.map((description: string, i) => (
            <ParagraphComponent key={`${descriptionId}-${i}`} testId={descriptionId}>
              <FormattedMessageComponent id={description} values={{ br: <br /> }} />
            </ParagraphComponent>
          ))}
        </AccessPortabilityCardDescriptionsStyled>
        <FetchErrorComponent
          error={getFetchErrorConfiguration({
            ...ACCESS_PORTABILITY_DEFAULT_ERROR_CONFIGURATION,
            error: empty,
            buttonConfiguration: {
              testId: BUTTON_IDS[rightsType].testId,
              children: <FormattedMessageComponent id={translations[rightsType].BUTTON} />,
              onClick: handleAccessPortabilityRequestClick,
            },
            formatMessage,
            translations,
          })}
          fetching={pageLoader}
        >
          <AccessPortabilityRequestTableComponent
            {...{ information, email, handleAccessPortabilityRequestClick, rightsType }}
          />
        </FetchErrorComponent>
      </PersonalAccessPortabilityCardContentStyled>
    </AppCardSectionComponentStyled>
  );
};

export default AccessPortabilityCardComponent;
