// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  VALUE_PARAGRAPH_PROPS,
  DETAIL_CONFIRMATION_COMPONENT_PROPS,
} from './constants/detail-confirmation.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { DetailConfirmationComponentStyled } from './detail-confirmation.component.styled';
// Types
import { DetailConfirmationComponentType } from './types/detail-confirmation.component.type';

export const DetailConfirmationComponent = ({
  details,
}: DetailConfirmationComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <DetailConfirmationComponentStyled {...DETAIL_CONFIRMATION_COMPONENT_PROPS}>
      {details.map(({ label, value }, index) => (
        <ProductDetailComponent key={index} title={formatMessage({ id: label })}>
          <ParagraphComponent {...VALUE_PARAGRAPH_PROPS}>{value}</ParagraphComponent>
        </ProductDetailComponent>
      ))}
    </DetailConfirmationComponentStyled>
  );
};
