// Constants
import { CARD_SETTINGS_RESUME_LINK_LINKS_INVALID_STATUS } from './constants/card-settings-resume-link-links.utils.constants';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Types
import { CardSettingsResumeLinkLinksUtilsType } from './types/card-settings-resume-link-links.utils.type';

const productDetailsDisabled = (status: CardStatusTypesEnumeration): boolean =>
  CARD_SETTINGS_RESUME_LINK_LINKS_INVALID_STATUS.includes(status);

const isCardSettingsResumeLinkDisabled = ({
  actionDisabled,
  disabled,
}: CardSettingsResumeLinkLinksUtilsType): boolean => Boolean(actionDisabled || disabled);

export { isCardSettingsResumeLinkDisabled, productDetailsDisabled };
