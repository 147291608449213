// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  SIGNATURE_KEY_SIDEBAR_PROPS: {
    testId: 'signature-key',
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_01_PROPS: {
    testId: 'signature-key-sidebar-message-01',
    size: paragraphConstants.SIZES.M,
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_02_PROPS: {
    testId: 'signature-key-sidebar-message-02',
    size: paragraphConstants.SIZES.M,
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_03_PROPS: {
    testId: 'signature-key-sidebar-message-03',
    size: paragraphConstants.SIZES.M,
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_04_PROPS: {
    testId: 'signature-key-sidebar-message-04',
    size: paragraphConstants.SIZES.M,
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_05_PROPS: {
    testId: 'signature-key-sidebar-message-05',
    size: paragraphConstants.SIZES.M,
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_06_PROPS: {
    testId: 'signature-key-sidebar-message-06',
    size: paragraphConstants.SIZES.M,
  },
  SIGNATURE_KEY_SIDEBAR_MESSAGE_07_PROPS: {
    testId: 'signature-key-sidebar-message-07',
    size: paragraphConstants.SIZES.M,
  },
};
