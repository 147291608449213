// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  CARD_DETAILS_DEFAULT_PROPS: {
    hideToggle: true,
    testId: 'card-settings-activate-card-introduction-card-details',
  },
  FIRST_CC_MESSAGE_DEFAULT_PROPS: {
    testId: 'card-settings-activate-card-introduction-first-cc-message',
    type: messageConstants.TYPES.CC,
  },
  SECOND_CC_MESSAGE_DEFAULT_PROPS: {
    testId: 'card-settings-activate-card-introduction-second-cc-message',
    type: messageConstants.TYPES.CC,
  },
  PARAGRAPH_DEFAULT_PROPS: {
    testId: 'card-settings-activate-card-introduction-subtitle',
    PREFIX_VALUE: '...',
  },
};
