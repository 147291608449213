// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import DashboardTableLinkHandlers from '../handlers/dashboard-table-link.handlers';
import DashboardTableLinkTrackingHandlers from '../handlers/dashboard-table-link.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { DashboardTableBuilderReturnHandlersType } from '../handlers/types/dashboard-table.handlers.type';

const useDashboardTableLinkHook = (): DashboardTableBuilderReturnHandlersType =>
  DashboardTableLinkHandlers({
    ...AppTrackingHook(DashboardTableLinkTrackingHandlers),
    navigate: useNavigate(),
  });

export default useDashboardTableLinkHook;
