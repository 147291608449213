export const ACCEPTED_202 = 202;
export const WEB = 'web';
export const NOT_NECESSARY_TINK_PARAMS = ['caseid', 'userid', 'caseId', 'userId'];
export const POSSIBLE_TINK_ERRORS = [
  'error',
  'error_reason',
  'error_type',
  'message',
  'tracking_id',
  'credentials',
  'provider_name',
  'payment_request_id',
];
