// Translations
import translations from '../translations/enable-disable-card-confirmation-modal.translations';
// Types
import { LoadingStatus } from 'types/loading-status.type';

export const getModalTitle = (isEnabled: boolean): string =>
  isEnabled ? translations.ON_DISABLE_TITLE : translations.ON_ENABLE_TITLE;

export const getModalDescription = (isEnabled: boolean): string =>
  isEnabled ? translations.ON_DISABLE_DESCRIPTION : translations.ON_ENABLE_DESCRIPTION;

export const getModalConfirmLabel = (isEnabled: boolean): string =>
  isEnabled ? translations.ON_DISABLE_CONFIRM : translations.ON_ENABLE_CONFIRM;

export const isLoading = (loadingStatus: LoadingStatus): boolean => loadingStatus === 'loading';
