// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentContractHookType } from './types/card-settings-contract-current-contract.hook.type';

const useCardSettingsContractCurrentContractHook =
  (): CardSettingsContractCurrentContractHookType => {
    const { currentCard } = AppContextConsumerHook(),
      { cardContractId = CharactersEnum.NO_VALUE, holderName = CharactersEnum.NO_VALUE } =
        currentCard || {};

    return {
      cardContractId,
      holderName,
    };
  };

export default useCardSettingsContractCurrentContractHook;
