// Vendors
import { useEffect } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { SecurityKeysSignatureKeyChangeContextConsumerHook } from 'containers/security-keys/views/signature-key-change/contexts/security-keys-signature-key-change.context';
// Handlers
import SecurityKeysSignatureKeyChangeFirstStepHandlers from '../handlers/signature-key-change-step1.handlers';
import SecurityKeysSignatureKeyChangeFirstStepTrackingHandlers from '../handlers/signature-key-change-step1.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SecurityKeysSignatureKeyChangeFirstStepHookType } from './types/signature-key-change-step1.hook.type';

const SecurityKeysSignatureKeyChangeFirstStepHook =
  (): SecurityKeysSignatureKeyChangeFirstStepHookType => {
    const { signatureKey, setSignatureKey } = SecurityKeysSignatureKeyChangeContextConsumerHook();
    const { setOpenRequestSignatureModal } = AppContextConsumerHook();
    const {
      handlePasswordPositionInputClickTracking,
      handleRecoveryKeyInputClickTrackingHandler,
      handleOnEnterFirstStepSignatureKeyChangeTracking,
      handleShowHidePasswordClickTracking,
    } = AppTrackingHook(SecurityKeysSignatureKeyChangeFirstStepTrackingHandlers);
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
      handleOnEnterFirstStepSignatureKeyChangeTracking();
    }, []);

    return {
      ...SecurityKeysSignatureKeyChangeFirstStepHandlers({
        setOpenModal: setOpenRequestSignatureModal,
        setSignatureKey,
        handleRecoveryKeyInputClickTrackingHandler,
      }),
      handlePasswordPositionInputClickTracking,
      handleShowHidePasswordClickTracking,
      signatureKey,
    };
  };

export default SecurityKeysSignatureKeyChangeFirstStepHook;
