// Translations
import {
  CONSTRAINT_01,
  CONSTRAINT_02,
  CONSTRAINT_03,
  CONSTRAINT_05,
} from './translations/card-settings-change-3d-step1.configuration.translations';
// Types
import { CardSettingsChange3dStep1NewCodeHookType } from '../components/new-code/hooks/types/card-settings-change-3d-step1-new-code.hook.type';
import { ConstraintItemType } from 'types/constraints/constraint-item.type';
// Utilities
import {
  checkHasFourNumbersOnly,
  checkNoAscendingDescendingSequences,
  checkNoContain3OrMoreConsecutiveAndEqualsNumbers,
  checkNoMatchCustomerBirthDate,
} from 'utils/constraints/constraints.utils';

const getChange3DSecureCodeConstraintsConfiguration = ({
  dateOfBirth,
  formatMessage,
}: Pick<
  CardSettingsChange3dStep1NewCodeHookType,
  'dateOfBirth' | 'formatMessage'
>): ConstraintItemType[] => [
  {
    label: formatMessage({ id: CONSTRAINT_01 }),
    check: (code: string) => checkNoAscendingDescendingSequences(code),
  },
  {
    label: formatMessage({ id: CONSTRAINT_02 }),
    check: (code: string) => checkNoContain3OrMoreConsecutiveAndEqualsNumbers(code),
  },
  {
    label: formatMessage({ id: CONSTRAINT_03 }),
    check: (code: string) => checkNoMatchCustomerBirthDate({ dateOfBirth, value: code }),
  },
  {
    label: formatMessage({ id: CONSTRAINT_05 }),
    check: (code: string) => checkHasFourNumbersOnly(code),
  },
];

export { getChange3DSecureCodeConstraintsConfiguration };
