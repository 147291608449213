// Services
import {
  toggleCardAvailabilityService,
  toogleTravelPlusAvailabilityService,
} from '../services/card-settings-toggles.services';
// Types
import { CardSettingsTogglesApiCardAvalaibleBuilderComponentType } from '../types/card-settings-toggle-api-card-avalaible-builder.component.type';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';
import { CardSettingsTogglesApiTravelPlusBuilderComponentType } from '../types/card-settings-toggle-api-travel-plus-builder.component.type';

const toggleCardAvailabilityResolver = async (
  props: CardSettingsTogglesApiCardAvalaibleBuilderComponentType
): Promise<[number] | [null, true]> => {
  const [, { status }] = await toggleCardAvailabilityService(props);

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

const toggleCardTravelPlusAvailabilityResolver = async (
  props: CardSettingsTogglesApiTravelPlusBuilderComponentType
): Promise<[number] | [null, true]> => {
  const [, { status }] = await toogleTravelPlusAvailabilityService(props);

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

export { toggleCardAvailabilityResolver, toggleCardTravelPlusAvailabilityResolver };
