// Vendors
import React from 'react';
// Components
import { AssetComponent, ImagePlaceholderComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/profile-personal-name-avatar.constants';
// Hooks
import AvatarComponent from 'components/avatar/avatar.component';
import ProfilePersonalNameAvatarHook from './hooks/profile-personal-name-avatar.hook';
// Styles
import {
  ProfilePersonalNameAvatarComponentStyled,
  ProfilePersonalNameAvatarEditComponentStyled,
} from './profile-personal-name-avatar.component.styled';
// Types
import { ProfilePersonalCardEditComponentType } from './types/profile-personal-card-edit.component.type';

const ProfilePersonalNameAvatarComponent =
  (): React.ReactElement<ProfilePersonalCardEditComponentType> => {
    const { handleProfilePersonalNameEditAvatarAssetClick, genderTCode, targetTCode } =
      ProfilePersonalNameAvatarHook();

    return (
      <ProfilePersonalNameAvatarComponentStyled>
        <AvatarComponent />
        <ProfilePersonalNameAvatarEditComponentStyled>
          <AssetComponent
            {...constants.PROFILE_PERSONAL_NAME_AVATAR_EDIT_ASSET_PROPS}
            onClick={handleProfilePersonalNameEditAvatarAssetClick}
          />
        </ProfilePersonalNameAvatarEditComponentStyled>
      </ProfilePersonalNameAvatarComponentStyled>
    );
  };

export default ProfilePersonalNameAvatarComponent;
