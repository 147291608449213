// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const limitIncreaseConstants = {
  CREDIT_LIMIT_STATUS_TOP_MESSAGE_PROPS: {
    testId: 'credit-limit-status-dashboard-messagge-message-component',
  },
  CREDIT_LIMIT_TOP_MESSAGE_TITLE_PROPS: {
    bold: true,
    testId: 'credit-limit-status-dashboard-messagge-title-section',
  },
  CREDIT_LIMIT_MESSAGE_PARAGRAPH_PROPS: {
    testId: 'credit-limit-status-dashboard-messagge-description',
  },
  CREDIT_LIMIT_MESSAGE_BUTTON_PROPS: {
    rwdFull: false,
    testId: 'credit-limit-status-dashboard-messagge-button',
    type: buttonConstants.TYPES.SECONDARY,
    url: generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.CREDIT_LIMIT_INCREASE])
    ),
  },
};

export { limitIncreaseConstants };
