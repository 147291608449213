// Vendors
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Constants
import operationsConstants from 'containers/card-settings/views/card-control/components/operations/constants/settings-card-control-operations.constants';
// Enumerations
import { CardControlIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-ids.enumeration';
// Translations
import translations from '../translations/settings-card-control-operations-toast.translations';

export default {
  SETTINGS_CARD_CONTROL_OPERATIONS_ATM_ASSET_PROPS: {
    ...operationsConstants.SETTINGS_CARD_CONTROL_OPERATIONS_COMMON_ASSET_PROPS,
    id: 'ATM',
    testId: 'settings-card-control-operations-atm-asset',
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_ATM_TITLE_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'settings-card-control-operations-atm-title',
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_ATM_DESCRIPTION_PROPS: {
    testId: 'settings-card-control-operations-atm-table-row-description',
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_ATM_TOGGLE_PROPS: {
    id: CardControlIdsEnumeration.ATM,
    translations,
  },
};
