// Vendors
import styled from 'styled-components';
// Types
import { FilterModalInputRangeAmountComponentType } from './types/filter-modal-input-range-amount.component.type';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const FilterModalInputRangeAmountComponentStyled = styled.div.attrs(
  ({ testId }: Pick<FilterModalInputRangeAmountComponentType, 'testId'>) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

const FilterModalInputRangeAmountContentStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
  }
`;
export { FilterModalInputRangeAmountComponentStyled, FilterModalInputRangeAmountContentStyled };
