// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
import { SettingsCardControlLocationsContextConsumerHook } from '../../locations/contexts/settings-card-control-locations.context';
// Events handlers
import SettingsCardControlToggleHandlers from 'containers/card-settings/views/card-control/components/toggle/handlers/settings-card-control-toggle.handlers';
import SettingsCardControlTrackingHandlers from 'containers/card-settings/views/card-control/components/toggle/handlers/settings-card-control-toggle.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SettingsCardControlToggleBuilderHookType } from 'containers/card-settings/views/card-control/components/toggle/hooks/types/settings-card-control-toggle-builder.hook.type';
import { SettingsCardControlToggleHookType } from 'containers/card-settings/views/card-control/components/toggle/hooks/types/settings-card-control-toggle.hook.type';

const SettingsCardControlToggleHook = (
  props: SettingsCardControlToggleBuilderHookType
): SettingsCardControlToggleHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook(),
    { setCard } = ManagerContextConsumerHook(),
    { id: cardId } = currentCard;

  const { setContinents } = SettingsCardControlLocationsContextConsumerHook();
  const [fetching, setFetching] = useState<boolean>(false);

  return {
    ...SettingsCardControlToggleHandlers({
      ...AppTrackingHook(SettingsCardControlTrackingHandlers),
      ...props,
      cardId,
      setCard,
      setFetching,
      setToastConfiguration,
      setContinents
    }),
    fetching,
  };
};

export default SettingsCardControlToggleHook;
