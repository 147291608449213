// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { CreditLimitDecreaseCCContextConsumerHook } from '../contexts/credit-limit-decrease-cc.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CreditLimitDecreaseCCViewHookType } from './types/credit-limit-decrease-cc.view.hook.type';

const CreditLimitDecreaseCCViewHook = (): CreditLimitDecreaseCCViewHookType => {
  const navigate = useNavigate();
  const { formatMessage } = useCbIntl();
  const { currentCard } = AppContextConsumerHook();
  const { defaultResult, error, fetching } = CreditLimitDecreaseCCContextConsumerHook();

  return {
    defaultResult,
    error:
      error || !currentCard.creditDetails?.limit || !currentCard.creditDetails?.minLimitProduct,
    fetching,
    canDecrease: currentCard.creditDetails?.limit > currentCard.creditDetails.minLimitProduct,
    navigate,
    formatMessage,
  };
};

export { CreditLimitDecreaseCCViewHook };
