// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_TRANSACTIONS_DETAIL_SERVICE_CONFIGURATION } from './constants/transactions.services.constants';
// Types
import { TransactionsDetailApiReturnComponentType } from 'types/transactions-api-return.component.type';
import { TransactionDetailsApiBuilderType } from 'containers/transactions/types/transaction-details-api-builder.type';

const fetchCardsTransactionsDetailService = async (
  pathParams: TransactionDetailsApiBuilderType
): Promise<[TransactionsDetailApiReturnComponentType | Record<string, any>, Response]> =>
  apiUtilsCb.get({ ...FETCH_TRANSACTIONS_DETAIL_SERVICE_CONFIGURATION, pathParams });

export { fetchCardsTransactionsDetailService };
