// Enumerations
import { ConsentPPIStep2IdsEnumeration } from 'containers/financing/views/ppi/components/contracting/components/step2/enumerations/consent-ppi-step2-ids.enumeration';
// Types
import {
  FinancingPPIContractingStep2ConsentBuilderHandlersType,
  FinancingPPIContractingStep2ConsentMethodsHandlersType,
} from './types/financing-ppi-contracting-step2-consent.handlers.type';
// Utilities
import { addOrRemoveConsentAcceptance } from './utils/financing-ppi-contracting-step2-consent.handlers.utils';

const updateChecksDone =
  (consentId: FinancingPPIContractingStep2ConsentBuilderHandlersType['consentId']) =>
  (prevConsents: ConsentPPIStep2IdsEnumeration[]): ConsentPPIStep2IdsEnumeration[] =>
    addOrRemoveConsentAcceptance({ consentId, prevConsents });

const changePPIContractingConsentRadioChangeHandler = ({
  consentId,
  handleChangePPIContractingConsentRadioChangeTracking,
  setChecksDone,
}: FinancingPPIContractingStep2ConsentBuilderHandlersType): void => {
  handleChangePPIContractingConsentRadioChangeTracking(consentId);
  setChecksDone(updateChecksDone(consentId));
};

const FinancingPPIContractingStep2ConsentHandlers = (
  props: FinancingPPIContractingStep2ConsentBuilderHandlersType
): FinancingPPIContractingStep2ConsentMethodsHandlersType => ({
  handleChangePPIContractingConsentRadioChange: () =>
    changePPIContractingConsentRadioChangeHandler(props),
});

export { FinancingPPIContractingStep2ConsentHandlers };
