// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';
import { TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlersType } from './types/transactions-report-different-amount-atm-additional-info-actions.handlers.type';
import { TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlersReturnType } from './types/transactions-report-different-amount-atm-additional-info-actions-return.type';

const nextButtonClickHandler = ({
  setCurrentStep,
  setFormValues,
  values,
}: NextButtonClickHandlerType): void => {
  setFormValues(values);
  setCurrentStep(NUMBER.TWO);
};

export const TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlers = ({
  setCurrentStep,
  setFormValues,
}: TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlersType): TransactionsReportDifferentAmountAtmAdditionalInfoActionsHandlersReturnType => ({
  handleNextButtonClick: values =>
    nextButtonClickHandler({ setCurrentStep, values, setFormValues }),
});
