// Vendors
import React from 'react';
// Components
import CopyLinkComponent from 'components/copy-link/copy-link.component';
import { ParagraphComponent, SelectorCardComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  SELECTOR_CARD_LIST_COPIED,
  SELECTOR_CARD_LIST_PROPS,
} from './constants/financing-deposit-money-selector-card-list.component.constants';
// Types
import { SelectorCardListComponentType } from './type/financing-deposit-money-selector-card-list.component.type';
// Utils
import { getDataCopied } from './utils/financing-deposit-money-selector-card-list.component.utils';

const SelectorCardListComponent = ({
  issueBank,
  virtualIban,
}: SelectorCardListComponentType): React.ReactElement => {
  const selectorCardListValues = [virtualIban, issueBank];
  const selectorTitle = SELECTOR_CARD_LIST_PROPS.map(element => element.title);

  const selectorCardList = selectorCardListValues.map((value, index) => {
    return {
      ...SELECTOR_CARD_LIST_PROPS[index],
      children: <ParagraphComponent size="XS">{value}</ParagraphComponent>,
      linkConfiguration: {
        children: (
          <CopyLinkComponent
            label={`${selectorTitle[index]} ${SELECTOR_CARD_LIST_COPIED}`}
            text={getDataCopied(value)}
          />
        ),
      },
    };
  });
  return (
    <SelectorCardComponent {...SELECTOR_CARD_LIST_PROPS} selectorCardList={selectorCardList} />
  );
};

export default SelectorCardListComponent;
