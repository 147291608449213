const NOT_POSSIBLE_ASSET_MODAL_PROPS = {
  id: 'oops-alternative',
  type: 'illustration',
  sizes: { width: 115, height: 115 },
  testId: 'not-possible-asset-modal',
} as const;

const TERTIARY_D = 'tertiaryD';

export { NOT_POSSIBLE_ASSET_MODAL_PROPS, TERTIARY_D };
