// Resolvers
import { generatePortabilityRequestOtpResolver } from '../resolvers/request-portability-step3-actions.resolvers';
// Types
import { RequestPortabilityStep3ActionsBuilderHandlersType } from './types/request-portability-step3-actions-builder.handlers.type';
import { RequestPortabilityStep3ActionsBuilderReturnHandlersType } from './types/request-portability-step3-actions-builder-return.handlers.type';

const confirmRequestPortabilityButtonClickHandler = async ({
  progressId,
  setFetching,
  setResult,
  portabilityParams,
}: RequestPortabilityStep3ActionsBuilderHandlersType): Promise<void> => {
  setFetching(true);
  
  const [, error] = await generatePortabilityRequestOtpResolver({ progressId: progressId ?? '', portabilityParams });

  setResult(!error);
  setFetching(false);
};

const RequestPortabilityStep3ActionsHandlers = (
  props: RequestPortabilityStep3ActionsBuilderHandlersType
): RequestPortabilityStep3ActionsBuilderReturnHandlersType => ({
  handleRequestPortabilityButtonClick: () => confirmRequestPortabilityButtonClickHandler(props),
});

export default RequestPortabilityStep3ActionsHandlers;
