// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsBlockCardFirstStepAddressDeleteModalReturnHookType } from './types/block-card-address-delete-modal-return.hook.type';

const CardSettingsBlockCardFirstStepAddressDeleteModalHook =
  (): CardSettingsBlockCardFirstStepAddressDeleteModalReturnHookType => {
    const { formatMessage } = useCbIntl();

    return {
      formatMessage,
    };
  };

export default CardSettingsBlockCardFirstStepAddressDeleteModalHook;
