// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_DESCRIPTION_PROPS = {
    testId: 'transactions-epp-step1-simulation-options-description',
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_LIST_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_TITLE_PROPS = {
    testId: 'transactions-epp-step1-simulation-options-title',
    tag: titleConstants.TAGS.H6,
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_INITIAL_ATTEMPTS = 0,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_MINIMUM = 2;

export {
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_DESCRIPTION_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_INITIAL_ATTEMPTS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_LAYOUT_LIST_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_MINIMUM,
  TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_TITLE_PROPS,
};
