export default {
  MESSAGE_PROPS: {
    type: 'informative',
  },
  INPUT_PROPS: {
    type: 'number',
    testId: 'tax-id-input',
  },
  BUTTON_PROPS: {
    testId: 'save-tax-id-button'
  }
} as const;
