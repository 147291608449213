// Types
import {
  PersonalNumberStep1RepeatPhoneBuilderHandlersType,
  PersonalNumberStep1RepeatPhoneBuilderReturnHandlersType,
  PersonalNumberStep1RepeatPhoneChangeBuilderHandlersType,
  PersonalNumberStep1RepeatPhoneClickBuilderHandlersType,
  PersonalNumberStep1RepeatPhoneValidateBuilderHandlersType,
} from './types/personal-number-step1-repeat-phone.handlers.type';
// Utilities
import {
  isInvalidPhone,
  IsValidPhoneMatch,
} from 'containers/personal/views/number/components/step1/utils/personal-number-step1.utils';
import { cleanPrefix } from 'containers/personal/views/number/utils/personal-number.utils.utils';

const savePersonalNumberStep1RepeatPrefixOptionClickEventHandler = ({
  handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking,
  repeatPrefix,
  setRepeatPhoneNumber,
  setRepeatPrefix,
  setError,
}: PersonalNumberStep1RepeatPhoneClickBuilderHandlersType): void => {
  setRepeatPhoneNumber('');
  handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking(repeatPrefix);
  setRepeatPrefix(repeatPrefix);
  setError(false);
};

const savePersonalNumberStep1RepeatNumberInputEventHandler = ({
  repeatPhoneNumber,
  setError,
  setRepeatPhoneNumber,
  setNewPhoneNumber,
  setNewPrefix,
  repeatPrefix,
}: PersonalNumberStep1RepeatPhoneChangeBuilderHandlersType): void => {
  setRepeatPhoneNumber(repeatPhoneNumber);
  setNewPhoneNumber(repeatPhoneNumber);
  setNewPrefix(cleanPrefix(repeatPrefix));
  setError(false);
};

const validatePersonalNumberStep1RepeatNumberInputEvent = ({
  handleManagePersonalNumberStep1RepeatNumberInputErrorTracking,
  prefix,
  repeatPrefix,
  repeatPhoneNumber,
  phoneNumber,
  setError,
  setRepeatPrefix,
  setNewPrefix,
  setPhonesMatch,
}: PersonalNumberStep1RepeatPhoneValidateBuilderHandlersType): void => {
  const isValid = !isInvalidPhone({ prefix: repeatPrefix, phoneNumber: repeatPhoneNumber });
  const isNotMatch = !IsValidPhoneMatch({
    prefix,
    repeatPrefix,
    phoneNumber,
    repeatPhoneNumber,
  });

  if (isNotMatch) {
    handleManagePersonalNumberStep1RepeatNumberInputErrorTracking();
    setPhonesMatch(false);
    setError(true);
  } else if (isValid) {
    setRepeatPrefix(repeatPrefix);
    setNewPrefix(cleanPrefix(repeatPrefix));
  }
};

const PersonalPhoneStep1RepeatPhoneHandlers = ({
  handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking,
  handleManagePersonalNumberStep1RepeatNumberInputErrorTracking,
  prefix,
  repeatPrefix,
  phoneNumber,
  repeatPhoneNumber,
  setError,
  setPhonesMatch,
  setRepeatPrefix,
  setRepeatPhoneNumber,
  setNewPhoneNumber,
  setNewPrefix,
}: PersonalNumberStep1RepeatPhoneBuilderHandlersType): PersonalNumberStep1RepeatPhoneBuilderReturnHandlersType => ({
  handleSavePersonalNumberStep1RepeatNumberPrefixOptionClick: (repeatPrefix: string) =>
    savePersonalNumberStep1RepeatPrefixOptionClickEventHandler({
      handleSavePersonalNumberStep1RepeatNumberPrefixOptionClickTracking,
      repeatPrefix,
      setRepeatPhoneNumber,
      setRepeatPrefix,
      setError,
    }),
  handleSavePersonalNumberStep1RepeatNumberInputEvent: (repeatPhoneNumber: string) =>
    savePersonalNumberStep1RepeatNumberInputEventHandler({
      repeatPhoneNumber,
      setError,
      setRepeatPhoneNumber,
      setNewPhoneNumber,
      repeatPrefix,
      setNewPrefix,
    }),
  handleValidatePersonalNumberStep1RepeatNumberInputEvent: () =>
    validatePersonalNumberStep1RepeatNumberInputEvent({
      handleManagePersonalNumberStep1RepeatNumberInputErrorTracking,
      prefix,
      repeatPrefix,
      setError,
      phoneNumber,
      repeatPhoneNumber,
      setRepeatPrefix,
      setNewPrefix,
      setPhonesMatch,
    }),
});

export default PersonalPhoneStep1RepeatPhoneHandlers;
