export enum CreditLimitIncreaseStepsEnumeration {
  // Step1
  SELECT_LIMIT_AMOUNT = 'select-limit-amount',
  // Step2
  LABORAL_ECONOMIC_DATA = 'laboral-economic-data',
  // Step3
  SELECT_METHOD = 'select-method',
  // Step4
  ACCOUNTS_AGGREGATION = 'accounts-aggregation',
  BANK_AGGREGATION_CONFIRMATION = 'bank-aggregation-confirmation',
  UPLOADING_DOCUMENT = 'uploading-document',
  UPLOADING_DOCUMENT_REJECTED = 'uploading-document-rejected',
  // Step5
  APPROVAL = 'approval',
  EVALUATE_NEW_BETTER_OFFER = 'evaluate-new-better-offer',
  PENDING = 'pending',
  RISK_REVIEW = 'risk-review', // Like pending view but with upload documentation
  // Step6
  SIGNATURE = 'signature',
  // Final feeback
  SUCCESS = 'success',
  COMPLETED = 'completed',
  REJECT_M2 = 'reject-m2',
  END_NAVIGATION = 'end-navigation',
  ERROR_CMS_SUCCESS = 'error-cms-success',
}
