// Vendors
import React from 'react';
import { SliderComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/change-credit-limit.constants';
// Hooks
import ChangeCreditLimitHook from './hooks/change-credit-limit.hook';
// Translations
import translations from './translations/change-credit-limit-component.translations';
// Utils
import { formatCreditAmount } from 'containers/financing/views/credit-limit-increase/utils/currency.utils';

const ChangeCreditLimitComponent = (): React.ReactElement => {
  const {
    inputError,
    mainCurrency,
    defaultValue,
    maxAmount,
    minAmount,
    formatMessage,
    formatNumber,
    handleOnChange,
    handleClickChangeCreditLimitBoxTracking,
  } = ChangeCreditLimitHook();

  return (
    <SliderComponent
      {...constants.SLIDER_PROPS}
      defaultValue={defaultValue}
      min={minAmount}
      max={maxAmount}
      onChange={handleOnChange}
      error={
        inputError
          ? formatMessage(
              { id: inputError },
              {
                step: constants.CREDIT_LIMIT_CONFIG.STEP,
                min: minAmount,
                max: maxAmount,
              }
            )
          : undefined
      }
      label={formatMessage(
        { id: translations.SLIDER_LABEL },
        { step: constants.CREDIT_LIMIT_CONFIG.STEP }
      )}
      leftHelperText={`${formatMessage({
        id: translations.SLIDER_MIN_TEXT,
      })} ${formatCreditAmount({
        currency: mainCurrency,
        formatNumber,
        value: minAmount as number,
      })}`}
      rightHelperText={`${formatMessage({
        id: translations.SLIDER_MAX_TEXT,
      })} ${formatCreditAmount({
        currency: mainCurrency,
        formatNumber,
        value: maxAmount as number,
      })}`}
      secondaryText={formatMessage({ id: translations.SLIDER_SECONDARY_TEXT })}
      steps={constants.CREDIT_LIMIT_CONFIG.STEP}
      onMouseUp={handleClickChangeCreditLimitBoxTracking}
      onTouchEnd={handleClickChangeCreditLimitBoxTracking}
    />
  );
};

export default ChangeCreditLimitComponent;
