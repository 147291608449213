// Vendors
import { useEffect, useRef, useState } from 'react';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { DeviceHeaderAliasHandler } from '../handlers/device-header-alias.handlers';
import { DeviceAliasTrackingHandlers } from '../handlers/device-header-alias.tracking.handlers';
// Types
import { DeviceHeaderAliasHookPropsType } from './type/device-header-alias.hook.props.type';
import { DeviceHeaderAliasHookReturnType } from './type/device-header-alias.hook.return.type';
import { DeviceHeaderAliasErrorType } from '../types/device-header-alias-error.type';
// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';

const DeviceHeaderAliasHook = ({
  alias,
  deviceUUID
}: DeviceHeaderAliasHookPropsType): DeviceHeaderAliasHookReturnType => {
  const { formatMessage } = useCbIntl();
  const { loading, setLoading, devices, setDevices, setTrusted } = SignatureKeyDevicesContextConsumerHook();
  const { setToastConfiguration } = ToastContextConsumerHook();
  const [editing, setEditing] = useState<boolean>(false);
  const [name, setName] = useState<string>(alias);
  const [closing, setClosing] = useState<boolean>(false);
  const [error, setError] = useState<DeviceHeaderAliasErrorType>({ description: '' });
  const [success, setSuccess] = useState<boolean>(false);
  const inputRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;

  const {
    openHandler,
    onEditClickHandler,
    onInputChangeHandler,
    onSaveClickHandler,
    onInputFocus
  } = DeviceHeaderAliasHandler({
    ...AppTrackingHook(DeviceAliasTrackingHandlers),
    alias,
    devices,
    deviceUUID,
    closing,
    editing,
    success,
    setSuccess,
    error,
    setDevices,
    setClosing,
    setLoading,
    setEditing,
    setTrusted,
    setError,
    setName,
    setToastConfiguration,
    inputRef,
    name,
    formatMessage
  });

  useEffect(openHandler, [openHandler]);

  return {
    editing,
    loading,
    name,
    error,
    success,
    onEditClickHandler,
    onSaveClickHandler,
    onInputChangeHandler,
    onInputFocus,
    inputRef,
    formatMessage
  };
};

export { DeviceHeaderAliasHook };
