// Vendors
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const TRANSACTIONS_EPP_STEP1_SIMULATION_DESCRIPTION_PROPS = {
    testId: 'transactions-epp-step1-simulation-description',
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_INSTALLMENTS_TITLE_PROPS = {
    bold: true,
    size: paragraphConstants.SIZES.XL,
    testId: 'transactions-epp-step1-simulation-installments-title',
  };

export {
  TRANSACTIONS_EPP_STEP1_SIMULATION_DESCRIPTION_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_LAYOUT_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_INSTALLMENTS_TITLE_PROPS,
};
