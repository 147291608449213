// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  FETCH_CARD_DETAILS_PAN_CONFIGURATION,
  FETCH_CARD_DETAILS_SERVICE_CONFIGURATION,
} from 'providers/card-detail/services/constants/card-detail.services.constants';
// Types
import { CardDetailsApiReturnComponentType } from 'types/card-details-api-return.component.type';
import { CardSettingsContractSidebarComponentType } from './types/card-detail-pan.services.type';

const fetchCardDetailsService = async (
  cardId: string
): Promise<[CardDetailsApiReturnComponentType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...FETCH_CARD_DETAILS_SERVICE_CONFIGURATION,
    pathParams: { cardId },
  });

const fetchCardPanService = async (
  cardId: string
): Promise<[CardSettingsContractSidebarComponentType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...FETCH_CARD_DETAILS_PAN_CONFIGURATION,
    pathParams: { cardId },
  });

export { fetchCardPanService, fetchCardDetailsService };
