// Vendors
import React from 'react';
// Components
import { TagComponent, tagConstants, ParagraphComponent } from '@openbank/cb-ui-commons';
import CardSettingsContractCurrentSectionComponent from '../section/card-settings-contract-current-section.component';
import { CardSettingsContractCurrentTravelPlusMessageComponent } from './components/travelPlusMessage/card-settings-contract-travel-plus-message.component';
import { CardSettingsContractCurrentInsuranceCoverageMessageComponent } from './components/insuranceCoverageMessage/card-settings-contract-insurance-coverage-message.component';
// Constants
import { CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_PROPS } from './constants/card-settings-contract-current-travel.constants';
import { CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DIVIDER_PROPS } from './constants/card-settings-contract-current-benefits-insurances.constants';
// Hooks
import { useCardSettingsContractCurrentTravelHook } from './hooks/card-settings-contract-current-travel.hook';
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
// Styles
import {
  CardSettingsContractCurrentMessageComponentSectionContentStyled,
  CardSettingsContractCurrentMessageComponentStyled,
} from './card-settings-contract-current-benefits-insurances-component.styled';
// Translations
import { INSURANCE_STATUS } from './translations/card-settings-contract-current-benefits-insurances.translations';
// Types
import { CardSettingsContractCurrentBenefitsInsurancesComponentPropsType } from './types/card-settings-contract-current-benefits-insurances-component.type';
// Utilities
import {
  getInsuranceCoverageStatus,
  getTagMessage,
  getTagType,
  getTagTypeFromStatus,
} from './utils/card-settings-contract-current-insurance.utils';

const CardSettingsContractCurrentBenefitsInsurancesComponent = ({
  endCoverageDate,
  status,
}: CardSettingsContractCurrentBenefitsInsurancesComponentPropsType): React.ReactElement => {
  const { checked, expirationDate, formatMessage } = useCardSettingsContractCurrentTravelHook();

  return (
    <CardSettingsContractCurrentSectionComponent
      {...CARD_SETTINGS_CONTRACT_CURRENT_INSURANCE_DIVIDER_PROPS}
    >
      <CardSettingsContractCurrentMessageComponentStyled>
        <CardSettingsContractCurrentMessageComponentSectionContentStyled>
          <ParagraphComponent>{formatMessage({ id: INSURANCE_STATUS })}</ParagraphComponent>
          <TagComponent
            type={getTagTypeFromStatus({ status, endCoverageDate })}
            shape={tagConstants.SHAPES.PILL}
          >
            {formatMessage({
              id: getInsuranceCoverageStatus({
                status,
                endCoverageDate,
              }),
            })}
          </TagComponent>
          <CardSettingsContractCurrentInsuranceCoverageMessageComponent
            status={status}
            endCoverageDate={endCoverageDate}
          />
        </CardSettingsContractCurrentMessageComponentSectionContentStyled>
        <CardSettingsContractCurrentMessageComponentSectionContentStyled>
          <ParagraphComponent>
            {formatMessage({ id: CARD_SETTINGS_CONTRACT_CURRENT_TRAVEL_PROPS.label })}
          </ParagraphComponent>
          <TagComponent
            type={getTagType({ expirationDate, checked })}
            shape={tagConstants.SHAPES.PILL}
          >
            <ParagraphComponent bold>
              {formatMessage({
                id: getTagMessage({ expirationDate, checked }),
              }).toUpperCase()}
            </ParagraphComponent>
          </TagComponent>
          <CardSettingsContractCurrentTravelPlusMessageComponent />
        </CardSettingsContractCurrentMessageComponentSectionContentStyled>
      </CardSettingsContractCurrentMessageComponentStyled>
    </CardSettingsContractCurrentSectionComponent>
  );
};

export { CardSettingsContractCurrentBenefitsInsurancesComponent };
