// Constants
import { GET_UPLOAD_DOCUMENT_URL_PARAMS } from '../constants/upload-document-group.handlers.constants';
// Resolvers
import {
  getUploadDocumentUrlResolver,
  uploadDocumentResolver,
} from 'resolvers/upload-documents/upload-documents.resolvers';
// Enumerations
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';
// Types
import { FileDataType } from 'types/file-data.type';
import { UpdateFileWithCurrentStatePropsType } from './types/update-file-with-current-state-props.type';
import { UploadDocumentWithUrlPropsType } from './types/upload-document-with-uri-props.type';
import { GetUploadDocumentUrlServiceResponseType } from 'services/upload-documents/types/get-upload-document-url-response.service.type';

const updateFileWithCurrentState =
  ({
    documentId,
    file,
    fileId,
    state,
    errorType,
  }: UpdateFileWithCurrentStatePropsType): ((currentFiles: FileDataType[]) => FileDataType[]) =>
  (currentFiles: FileDataType[]): FileDataType[] =>
    currentFiles.map(currentFile =>
      currentFile.id === fileId
        ? {
            ...currentFile,
            ...(documentId ? { documentId } : {}),
            ...(file ? { file } : {}),
            state,
            ...(errorType ? { errorType } : {}),
          }
        : currentFile
    );

const removeFile =
  (documentId?: string): ((files: FileDataType[]) => FileDataType[]) =>
  (files: FileDataType[]): FileDataType[] =>
    files.map(item =>
      item.documentId === documentId
        ? {
            ...item,
            documentId: undefined,
            file: null,
            state: DocumentsUploadStatesEnumeration.DEFAULT,
          }
        : item
    );

const getFileId = (length: number): string => `FILE_${length + 1}`;

const uploadDocumentWithUrl = async ({
  controllers: { docUriController, uploadDocController, updateDocController },
  customerId,
  documentType,
  file,
  updateFilesOnDB,
}: UploadDocumentWithUrlPropsType): Promise<string> => {
  const [response, error] = await getUploadDocumentUrlResolver({
    params: {
      ...GET_UPLOAD_DOCUMENT_URL_PARAMS,
      customers: [{ customerCode: customerId }],
      documentType,
    },
    signal: docUriController,
  });

  if (error) throw new Error();

  const { documentId, documentUri } = response as GetUploadDocumentUrlServiceResponseType;

  const [, uploadError] = await uploadDocumentResolver({
    customerId,
    documentUri,
    file,
    signal: uploadDocController,
  });

  if (uploadError) throw new Error();

  if (!updateFilesOnDB) {
    return documentId;
  }

  const [, updateError] = await updateFilesOnDB({
    customerId,
    documentId,
    documentType,
    signal: updateDocController,
  });

  if (updateError) throw new Error();

  return documentId;
};

export { getFileId, removeFile, updateFileWithCurrentState, uploadDocumentWithUrl };
