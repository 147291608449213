// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Event handlers
import SecurityKeysHandlers from '../handlers/security-keys.handlers';
// Types
import { SecurityKeysHookType } from './types/security-keys.hook.type';

const SecurityKeysHook = (resolver: () => Promise<any>): SecurityKeysHookType => {
  const { formatDate, formatMessage } = useCbIntl();

  const [timestamp, setTimestamp] = useState<string>('');

  const { handleFetchLastUpdateTime } = SecurityKeysHandlers({
    formatDate,
    formatMessage,
    resolver,
    setTimestamp,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchLastUpdateTime();
  }, []);

  return { timestamp };
};

export default SecurityKeysHook;
