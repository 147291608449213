const FINANCING_PPI_CONTRACTING_RESUME_PRODUCT_PROPS = {
    testId: 'financing-ppi-contracting-resume-product',
  },
  FINANCING_PPI_CONTRACTING_RESUME_HOLDER_PROPS = {
    testId: 'financing-ppi-contracting-resume-holder',
  },
  FINANCING_PPI_CONTRACTING_RESUME_FEE_PROPS = {
    testId: 'financing-ppi-contracting-resume-fee',
  };

export {
  FINANCING_PPI_CONTRACTING_RESUME_PRODUCT_PROPS,
  FINANCING_PPI_CONTRACTING_RESUME_HOLDER_PROPS,
  FINANCING_PPI_CONTRACTING_RESUME_FEE_PROPS,
};
