const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbFinancing.landing.cbTable.cbDetails.cbOrders.cbModal';

const DEALER_LABEL = `${DOMAIN}.dealerLabel`,
  DEALER_NAME = `${DOMAIN}.dealerName`,
  LINK_LABEL = `${DOMAIN}.linkLabel`,
  MODAL_CLOSE_BUTTON = `${DOMAIN}.closeButton`,
  ORDER_NUMBER = `${DOMAIN}.orderNumberLabel`,
  PURCHASE_DATE = `${DOMAIN}.purchaseDateLabel`,
  TITLE = `${DOMAIN}.title`,
  VIEW_DETAIL_LINK = `${DOMAIN}.linkViewDetail`;

export {
  DEALER_LABEL,
  DEALER_NAME,
  LINK_LABEL,
  MODAL_CLOSE_BUTTON,
  ORDER_NUMBER,
  PURCHASE_DATE,
  TITLE,
  VIEW_DETAIL_LINK,
};
