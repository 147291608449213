// Resolvers
import { validateNewNumberOtpResolver } from '../resolvers/personal-number-step2-challenge.resolver';
// Types
import { PersonalNumberStep2ChallengeBuilderReturnHandlersType } from './types/personal-number-step2-challenge-builder-return.handlers.type';
import { PersonalNumberStep2ChallengePropsType } from './types/personal-number-step2-challenge-props.handlers.type';
import { PersonalNumberStep2ChallengeBuilderHandlersType } from './types/personal-number-step2-challenge-builder.handlers.type';

const retryButtonClickHandler = ({
  onRetry,
  handleRestart,
}: Omit<PersonalNumberStep2ChallengeBuilderHandlersType, 'props'>): any => {
  onRetry();
  handleRestart();
};

const sendNumberOtpConfirmationButtonClickHandler = async ({
  handleSendNumberOtpConfirmationButtonClickTracking,
  otpId,
  otpValue,
  newPhoneNumber,
  newPrefix,
  setChallenged,
  setFetching,
  setError,
}: PersonalNumberStep2ChallengePropsType): Promise<void> => {
  setFetching(true);
  handleSendNumberOtpConfirmationButtonClickTracking();

  const [response, error] = await validateNewNumberOtpResolver({
    phoneCountryCode: Number(newPrefix),
    phone: newPhoneNumber,
    otpId,
    otpValue,
  });

  if (error) {
    setError(true);
  } else if (response) {
    setError(false);
    setChallenged(false);
  }

  setFetching(false);
};

const PersonalNumberStep2ChallengeHandlers = ({
  handleRestart,
  onRetry,
  props,
}: PersonalNumberStep2ChallengeBuilderHandlersType): PersonalNumberStep2ChallengeBuilderReturnHandlersType => ({
  handleRetryButtonClick: () =>
    retryButtonClickHandler({
      onRetry,
      handleRestart,
    }),
  handleSendNumberOtpConfirmationButtonClick: () =>
    sendNumberOtpConfirmationButtonClickHandler(props),
});

export default PersonalNumberStep2ChallengeHandlers;
