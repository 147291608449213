// Vendors
import styled from 'styled-components';
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const TransactionsReportDuplicateChargeConfirmationComponentStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl}
    ${({ theme }) => theme.spaces.spacingM};

  ${mobileAndTabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingM}
      ${({ theme }) => theme.spaces.spacingM};
  }
`;
