// Constants
import { tagConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_LANDING_TAG_CONTRACTED_PROPS = {
    shape: tagConstants.SHAPES.PILL,
    type: tagConstants.TYPES.SUCCESS,
  },
  FINANCING_PPI_LANDING_TAG_CANCELLED_PROPS = {
    shape: tagConstants.SHAPES.PILL,
    type: tagConstants.TYPES.DISABLED,
  },
  FINANCING_PPI_LANDING_TAG_COVERED_PROPS = {
    shape: tagConstants.SHAPES.PILL,
    type: tagConstants.TYPES.PENDING,
  };

export {
  FINANCING_PPI_LANDING_TAG_CONTRACTED_PROPS,
  FINANCING_PPI_LANDING_TAG_CANCELLED_PROPS,
  FINANCING_PPI_LANDING_TAG_COVERED_PROPS,
};
