// Vendors
import { useState } from 'react';
// Constants
import { TRANSACTIONS_EPP_STEP2_SERVICE_PARAMS } from '../services/constants/transactions-epp-step2.service.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import { TransactionsEppStep2Handlers } from '../handlers/transactions-epp-step2.handlers';
import { TransactionsEppStep2TrackingHandlers } from '../handlers/transactions-epp-step2.tracking.handlers';
// Hooks
import { useWizardChallengeHook } from 'hooks/wizard-challenge/wizard-challenge.hook';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsEppStep2HookType } from './types/transactions-epp-step2.hook.type';

const useTransactionsEppStep2Hook = (): TransactionsEppStep2HookType => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { installments: numOfInstallments, transactionDetails } =
      useTransactionsEppContextConsumerHook(),
    { currentCard } = AppContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { progressId, ...wizardChallengeData } = useWizardChallengeHook(fetching),
    { id: cardId } = currentCard || {},
    { id: transactionId } = transactionDetails;

  return {
    ...TransactionsEppStep2Handlers({
      ...AppTrackingHook(TransactionsEppStep2TrackingHandlers),
      cardId,
      numOfInstallments,
      progressId,
      setFetching,
      setResult,
      transactionId,
    }),
    ...wizardChallengeData,
    baseServiceBuilder: {
      pathParams: { cardId },
      params: { ...TRANSACTIONS_EPP_STEP2_SERVICE_PARAMS, numOfInstallments, transactionId },
    },
    fetching,
  };
};

export { useTransactionsEppStep2Hook };
