// Services
import { fetchDepositMoneyService } from '../services/financing-deposit-money.service';
// Types
import { DepositMoneyComponentType } from '../types/financing-deposit-money.component.type';
// Utils
import { isValidDepositMoneyResponse } from './utils/financing-deposit-money.resolver.utils';

export const fetchDepositMoneyResolver = async (
  cardId: string
): Promise<[DepositMoneyComponentType] | [null, number]> => {
  const [response, { status }] = await fetchDepositMoneyService(cardId);

  return isValidDepositMoneyResponse(response) ? [response as any] : [null, status];
};
