// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  CardSettingsCheck3dConfirmationButtonsHandlersType,
  CardSettingsCheck3dConfirmationButtonsReturnHandlersType,
} from './types/card-settings-check-3d-confirmation-buttons.handlers.type';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const modify3DSecureCodeButtonClickHandler = ({
  handleModify3DSecureCodeButtonClickTracking,
  navigate,
}: Pick<
  CardSettingsCheck3dConfirmationButtonsHandlersType,
  'handleModify3DSecureCodeButtonClickTracking' | 'navigate'
>): void => {
  const url: string = generatePathRoute([
    RoutesEnumeration.CARD_SETTINGS,
    RoutesEnumeration.CHANGE_3D_SECURE,
  ]);

  handleModify3DSecureCodeButtonClickTracking();
  navigate(generateAbsoluteRoute(url));
};

const updateShownCodeStatus =
  ({
    handleShowHide3DSecureCodeButtonClickTracking,
    setShowAllowance,
  }: Pick<
    CardSettingsCheck3dConfirmationButtonsHandlersType,
    'handleShowHide3DSecureCodeButtonClickTracking' | 'setShowAllowance'
  >) =>
  (prevShownCode: boolean): boolean => {
    const newShownCode = !prevShownCode;

    handleShowHide3DSecureCodeButtonClickTracking(newShownCode);

    if (!newShownCode) {
      setShowAllowance(false);
    }

    return newShownCode;
  };

const showHide3DSecureCodeButtonClickHandler = ({
  handleShowHide3DSecureCodeButtonClickTracking,
  setShowAllowance,
  setShownCode,
}: Pick<
  CardSettingsCheck3dConfirmationButtonsHandlersType,
  'handleShowHide3DSecureCodeButtonClickTracking' | 'setShowAllowance' | 'setShownCode'
>): void => {
  setShownCode(
    updateShownCodeStatus({ handleShowHide3DSecureCodeButtonClickTracking, setShowAllowance })
  );
};

const CardSettingsCheck3dConfirmationButtonsHandlers = ({
  handleModify3DSecureCodeButtonClickTracking,
  handleShowHide3DSecureCodeButtonClickTracking,
  navigate,
  setShownCode,
  setShowAllowance,
}: CardSettingsCheck3dConfirmationButtonsHandlersType): CardSettingsCheck3dConfirmationButtonsReturnHandlersType => ({
  handleModify3DSecureCodeButtonClick: () =>
    modify3DSecureCodeButtonClickHandler({ handleModify3DSecureCodeButtonClickTracking, navigate }),
  handleShowHide3DSecureCodeButtonClick: () =>
    showHide3DSecureCodeButtonClickHandler({
      handleShowHide3DSecureCodeButtonClickTracking,
      setShownCode,
      setShowAllowance,
    }),
});

export default CardSettingsCheck3dConfirmationButtonsHandlers;
