export default {
  ASSET_1_PROPS: {
    colors: {
      fill: 'brandB',
    },
    id: 'conection',
    sizes: {
      height: 26,
      width: 26,
    },
    testId: 'how-does-it-work-asset-1',
  },
  ASSET_2_PROPS: {
    colors: {
      fill: 'brandB',
    },
    id: 'document',
    sizes: {
      height: 26,
      width: 26,
    },
    testId: 'how-does-it-work-asset-2',
  },
  ASSET_3_PROPS: {
    colors: {
      fill: 'brandB',
    },
    id: 'success',
    sizes: {
      height: 26,
      width: 26,
    },
    testId: 'how-does-it-work-asset-3',
  },
  ASSET_DOCUMENTATION_PROPS: {
    colors: {
      fill: 'linkPrimaryA',
    },
    id: 'arrows_toggler_up',
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'upload-documentation-asset',
  },
  LOADER_SIZES: {
    border: 2,
    height: 26,
    width: 26,
  },
} as const;
