// Enumerations
import { NotRecognizeChargeMotiveFraudEnum } from 'containers/transactions/views/report/enumerations/not-recognize-charge-motive-fraud.enumeration';
import { getClaimEnum } from 'containers/transactions/views/report/components/fraudulent-movement/components/confirmation/utils/transactions-report-fraudulent-movement-confirmation.utils';
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
// Types
import { ConfirmationActionData } from './types/transactions-report-fraudulent-movement-confirmation-actions-handlers-utils.type';

const validateConfirmationActionData = ({
  cardId,
  transactionId,
  notRecognizeChargeClickLink,
  notRecognizeChargeHaveYourCard,
  notRecognizeChargeMotiveFraud,
}: ConfirmationActionData): void => {
  if (!cardId || !transactionId) {
    throw new Error();
  }
  if (
    notRecognizeChargeClickLink === undefined ||
    notRecognizeChargeHaveYourCard === undefined ||
    notRecognizeChargeMotiveFraud === undefined
  ) {
    throw new Error();
  }
};

const getReasonType = (
  notRecognizeChargeMotiveFraud?: NotRecognizeChargeMotiveFraudEnum
): ClaimReasonEnum | null => {
  return notRecognizeChargeMotiveFraud ? getClaimEnum(notRecognizeChargeMotiveFraud) : null;
};

export { getReasonType, validateConfirmationActionData };
