// Vendors
import React from 'react';
// Constants
import { DASHBOARD_TABLE_LINK_PROPS } from './constants/dashboard-table-link.constants';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import useDashboardTableLinkHook from './hooks/dashboard-table-link.hook';
// Styles
import { DashboardTableLinkComponentStyled } from './dashboard-table-link.component.styled';
// Types
import { DashboardTableHookType } from '../../hooks/types/dashboard-table.hook.type';
// Utilities
import { hasMaximumLength } from './utils/dashboard-table-link.utils';
import { isValidTransactionsApiContract } from 'utils/transactions-api-contract.utils';
// Translations
import { VIEW_ALL } from './translations/dashboard-table-link.translations';

const DashboardTableLinkComponent = ({
  transactions = {},
}: Pick<DashboardTableHookType, 'transactions'>): React.ReactElement | null => {
  const { handleNavigateToTransactionsViewLinkClick } = useDashboardTableLinkHook();

  return isValidTransactionsApiContract(transactions) &&
    hasMaximumLength(transactions?.elements) ? (
    <DashboardTableLinkComponentStyled>
      <LinkComponent
        {...DASHBOARD_TABLE_LINK_PROPS}
        onClick={handleNavigateToTransactionsViewLinkClick}
      >
        <FormattedMessageComponent id={VIEW_ALL} />
      </LinkComponent>
    </DashboardTableLinkComponentStyled>
  ) : null;
};

export default DashboardTableLinkComponent;
