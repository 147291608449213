// Enumerations
import { PaymentMethodsEnumeration } from 'containers/financing/views/payment-method/enumerations/payment-methods.enumeration';
// Translations
import { FULL_PAYMENT, RESOLVING_AMOUNT } from 'translations/billing-payment-methods.translations';

const OPTIONS = [
  {
    name: PaymentMethodsEnumeration.FULL_PAYMENT,
    title: FULL_PAYMENT.TITLE,
    description: FULL_PAYMENT.DESCRIPTION,
  },
  {
    name: PaymentMethodsEnumeration.RESOLVING_AMOUNT,
    title: RESOLVING_AMOUNT.TITLE,
    description: RESOLVING_AMOUNT.DESCRIPTION,
  },
];

export { OPTIONS };
