// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  NOTIFICATIONS_LANDING_SIDEBAR_PROPS: {
    testId: 'notifications-landing',
  },
  NOTIFICATIONS_LANDING_SIDEBAR_DESCRIPTION_PROPS: {
    testId: 'notifications-landing-sidebar-table-row-description',
    size: paragraphConstants.SIZES.M,
  },
  NOTIFICATIONS_LANDING_SIDEBAR_TITLE_PROPS: {
    bold: true,
    size: 'M',
  },
  NOTIFICATIONS_LANDING_SIDEBAR_LINK_PROPS: {
    size: 'big',
    type: 'action_primary',
    testId: 'notifications-landing-sidebar-link',
  },
};
