// Utilities
import {
  isValidNewRecoveryKey,
  recoveryKeyHasCorrectLength,
} from 'containers/recovery-key/components/step5/utils/recovery-key-step5.utils';

const showRecoveryKeyConstraintsError = (recoveryKey: string): boolean =>
  recoveryKeyHasCorrectLength(recoveryKey) && !isValidNewRecoveryKey(recoveryKey);

export { showRecoveryKeyConstraintsError };
