// Services
import { fetchLayoutLanguageService } from '../services/language.services.constants';

const fetchLayoutLanguageResolver = async (): Promise<any> => {
  const [response] = await fetchLayoutLanguageService();

  return response?.resource?.language ? [response?.resource?.language] : [null, true];
};

export { fetchLayoutLanguageResolver };
