// Vendors
import styled from 'styled-components';
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const Step1CreditLimitDecreaseCCStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export const CardLimitFirstRowStyled = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const ProductDetailsStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const CardDetailsStyled = styled.div`
  flex: 1;
`;

export const InputContainerStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 300, base: 14 })};

  ${mobileMediaQuery} {
    width: 100%;
  }
`;
