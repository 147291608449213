// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/close-button.constants';
// Styles
import { CloseButtonComponentStyled } from './close-button.component.styled';
// Types
import { CloseButtonComponentType } from './types/close-button.component.type';

const CloseButtonComponent = ({
  handleCloseButton,
}: CloseButtonComponentType): React.ReactElement | null =>
  handleCloseButton ? (
    <CloseButtonComponentStyled>
      <AssetComponent {...constants.CLOSE_ASSET_PROPS} onClick={handleCloseButton} />
    </CloseButtonComponentStyled>
  ) : null;

export { CloseButtonComponent };
