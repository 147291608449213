// Vendors
import styled from 'styled-components';

const FinancingPPIContractingDownloadErrorComponentStyled = styled.div`
  align-items: center;
  display: flex;
  color: ${({ theme }) => theme.colors.backgroundErrorA};
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;
FinancingPPIContractingDownloadErrorComponentStyled.displayName =
  'FinancingPPIContractingDownloadErrorComponentStyled';

export { FinancingPPIContractingDownloadErrorComponentStyled };
