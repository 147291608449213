export default {
  CONTEXT_DEFAULT_VALUE: {
    amountValue: null,
    challenge: null,
    disableNextBtn: true,
    error: false,
    fastCashCommission: null,
    fastCashLimits: null,
    loading: false,
    setAmountValue: (): null => null,
    setChallenge: (): null => null,
    setDisableNextBtn: (): null => null,
    setError: (): null => null,
    setFastCashCommission: (): null => null,
    setFastCashLimits: (): null => null,
    setLoading: (): null => null,
  },
};
