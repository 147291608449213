// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ProfilePersonalDataContactComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export const ProfilePersonalDataContactContentItemComponentStyled = styled.div`
  flex: 1;

  ${mobileMediaQuery} {
    flex: 100%;
    width: 100%;
  }
`;
