export default {
  CONTEXT_DEFAULT_VALUE: {
    signatureKey: '',
    setSignatureKey: (): null => null,
    setRepeatNewSignatureKey: (): null => null,
    setProgressId: (): null => null,
    setNewSignatureKey: (): null => null,
    setIsDisabledLastStep: (): null => null,
    setInputError: (): null => null,
    setFromPersonal: (): null => null,
    repeatNewSignatureKey: '',
    progressId: '',
    newSignatureKey: '',
    isDisabledLastStep: true,
    inputError: null,
    fromPersonal: false,
  },
};
