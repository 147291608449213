// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { FinancingPaymentMethodContextConsumerHook } from 'containers/financing/views/payment-method/contexts/financing-payment-method.context';
// Handlers
import FinancingPaymentMethodFirstStepPaymentHandlers from '../handlers/financing-payment-method-first-step-payment.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingPaymentMethodFirstStepPaymentReturnHookType } from './types/financing-payment-method-first-step-payment-return.hook.type';

const FinancingPaymentMethodFirstStepPaymentHook =
  (): FinancingPaymentMethodFirstStepPaymentReturnHookType => {
    const [paymentMethodLabel, setPaymenyMethodLabel] = useState<string>('');

    const { formatMessage, formatNumber } = useCbIntl();

    const { currentCardDetail } = AppContextConsumerHook(),
      { creditDetails } = currentCardDetail || {},
      {
        accountBalancePercentage,
        billingCyclePeriodicity,
        currentAmountAuthorized,
        fixedPaymentAmount,
        limit,
        paymentMethodCode,
      } = creditDetails || {};
    const { setError } = FinancingPaymentMethodContextConsumerHook();

    const { handleFirstStepPaymentRender } = FinancingPaymentMethodFirstStepPaymentHandlers({
      accountBalancePercentage,
      fixedPaymentAmount,
      formatMessage,
      formatNumber,
      paymentMethodCode,
      setError,
      setPaymenyMethodLabel,
    });

    useEffect(() => {
      handleFirstStepPaymentRender();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      currentAmountAuthorized,
      billingCyclePeriodicity,
      formatNumber,
      limit,
      paymentMethodLabel,
    };
  };

export default FinancingPaymentMethodFirstStepPaymentHook;
