// Services
import { contractPPIService } from '../services/financing-ppi-contracting-step3.service';
// Types
import { FinancingPPIContractingStep3ApiBuilderType } from '../types/financing-ppi-contracting-step3-api-builder.type';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';

const contractPPIResolver = async (
  params: FinancingPPIContractingStep3ApiBuilderType
): Promise<[number] | [null, boolean]> => {
  const [, { status }] = await contractPPIService(params);

  return wasSuccessRequest(status) ? [status] : [null, true];
};

export { contractPPIResolver };
