// Resolvers
import { getDocumentLinkResolver } from '../resolvers/request-table-row.resolver';
// Types
import { type RequestTableRowMethodHandlersType } from './types/request-table-row-method.handlers.type';
import { type RequestTableRowReturnHandlersType } from './types/request-table-row-return.handlers.type';

const downloadDocumentRequestClickHandler = async ({
  documentId,
}: RequestTableRowMethodHandlersType): Promise<void> => {
  const [response] = await getDocumentLinkResolver({ documentId });

  if (response?.uri) {
    window.open(response?.uri, '_blank');
  }
};

const RequestTableRowHandlers = ({
  documentId,
}: {
  documentId: string;
}): RequestTableRowReturnHandlersType => ({
  handleDownloadDocumentRequestClick: () => downloadDocumentRequestClickHandler({ documentId }),
});

export { RequestTableRowHandlers };
