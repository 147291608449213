// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ModalContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const AssetsContainerStyled = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    flex-direction: column;
  }
`;

export const TipElementStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${({ theme }) => theme.spaces.spacingXxs};
  width: ${convertPxsToRems({ pixels: 180, base: 14 })};
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  gap: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    flex-direction: row;
    align-items: center;
    text-align: left;
    width: 100%;

    img {
      height: ${convertPxsToRems({ pixels: 70, base: 14 })};
      width: ${convertPxsToRems({ pixels: 70, base: 14 })};
    }
  }
`;
