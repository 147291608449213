// Types
import { PersonalNumberStep3UtilsType } from './types/personal-number-step3.utils.type';
import { ConfirmationOptionEnumeration } from 'containers/personal/views/number/components/step3/components/confirmation-option/enumerations/confirmation-option.enumeration';
// Translations
import {
  CURRENT_PHONE,
  WITH_EMAIL,
} from '../../../translations/personal-number-step3.translations';

const isEmailOption = (type: ConfirmationOptionEnumeration | null): boolean => {
  return Boolean(type === ConfirmationOptionEnumeration.EMAIL);
};

const getCorrectTextLabel = ({
  id,
  phone,
  email,
  formatMessage,
}: PersonalNumberStep3UtilsType): string => {
  const confirmationTexts = {
    [ConfirmationOptionEnumeration.PHONE]: `${formatMessage({
      id: CURRENT_PHONE,
    })} ${phone}`,
    [ConfirmationOptionEnumeration.EMAIL]: `${formatMessage({
      id: WITH_EMAIL,
    })} ${email}`,
  };

  const option = confirmationTexts[id];
  return option;
};

export { getCorrectTextLabel, isEmailOption };
