// Contexts
import { useQuickActionsContextConsumerHook } from 'contexts/quick-actions/quick-actions.context';
// Enumerations
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';
import { QuickActionsActionsIdsEnumeration } from 'enumerations/quick-actions/quick-actions-actions-ids.enumeration';

const useCardSettingsLandingActionsHook = (id?: QuickActionsActionsIdsEnumeration): boolean => {
  const { actions: rawActions } = useQuickActionsContextConsumerHook(),
    actions = rawActions[QuickActionsSectionEnumeration.SETTINGS] || [];

  return Boolean(id ? actions[id] : false);
};

export { useCardSettingsLandingActionsHook };
