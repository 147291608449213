// Themes
import { AmazonTheme } from '@openbank/cf-ui-static-data';

export default {
  REQUEST_PORTABILITY_CHECKBOX_PROPS: {
    testing: {
      labelTestId: 'request-portability-checkbox-label',
      elementTestId: 'request-portability-checkbox',
    },
  },
  REQUEST_PORTABILITY_CARDS_CHECKBOX_TITLE_PROPS: {
    testId: 'request-portability-cards-checkbox-title',
  },
  LINE_SEPARATOR_PROPS: {
    color: AmazonTheme.default.colors.backgroundTertiaryA,
  },
};
