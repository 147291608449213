// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import CardSettingsTogglesHandlers from '../handlers/card-settings-toggles.handlers';
import CardSettingsTogglesTrackingHandlers from '../handlers/card-settings-toggles.tracking.handlers';
// Hooks
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsTogglesHookType } from './types/card-settings-toggles.hook.type';
import { LoadingStatus } from 'types/loading-status.type';

const CardSettingsTogglesHook = (): CardSettingsTogglesHookType => {
  const { formatMessage } = useCbIntl();

  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [showTravelPlusModal, setShowTravelPlusModal] = useState<boolean>(false);
  const [cardStatusLoading, setCardStatusLoading] = useState<LoadingStatus>('idle');

  const { currentCard } = AppContextConsumerHook(),
    { id = '', status = '', travelPlus, ui } = currentCard || {},
    { settingLanding } = ui || {},
    { actions } = settingLanding || {};

  const { setCard, setError } = ManagerContextConsumerHook();

  const cardSettingsTogglesHandlers = CardSettingsTogglesHandlers({
    ...AppTrackingHook(CardSettingsTogglesTrackingHandlers),
    id,
    status,
    travelPlus,
    setCardStatusLoading,
    setError,
    setShowConfirmationModal,
    setShowTravelPlusModal,
    setCard,
  });

  return {
    ...cardSettingsTogglesHandlers,
    cardStatusLoading,
    formatMessage,
    showConfirmationModal,
    showTravelPlusModal,
    status,
    travelPlus,
    actions,
  };
};

export default CardSettingsTogglesHook;
