// Vendors
import React from 'react';
// Components
import { DetailConfirmationComponent } from 'components/detail-confirmation/detail-confirmation.component';
import { ParagraphComponent, TitleHeaderComponent } from '@openbank/cb-ui-commons';
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import {
  FINANCING_INSURANCES_CANCELLATION_LPI_DESCRIPTION_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_BUTTON_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_CHALLENGE_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_FOOTER_PROPS,
  FINANCING_INSURANCES_CANCELLATION_LPI_TITLE_PROPS,
} from '../../constants/financing-insurances-cancellation-lpi.constants';
// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';
// Hooks
import { useFinancingInsurancesCancellationLPI } from '../../hooks/financing-insurances-cancellation-lpi.hook';
// Styles
import {
  AppCardContentSectionComponentStyled,
  LayoutColumnFlexComponentStyled,
} from 'styles/app.styled';
import { FinancingInsurancesCancellationLPIComponentStyled } from '../../financing-insurances-cancellation-lpi.component.styled';
// Translations
import { TITLE } from 'containers/financing/views/ppi/components/cancellation/components/landing/translations/financing-ppi-cancellation-landing.translations';
import {
  BUTTON,
  DESCRIPTION,
  DESCRIPTION_2,
  DETAIL_CONFIRMATION_CANCELLING_LABEL,
  DETAIL_CONFIRMATION_CANCELLING_VALUE,
  DETAIL_CONFIRMATION_STATUS_LABEL,
  DETAIL_CONFIRMATION_STATUS_VALUE,
} from '../../translations/financing-insurances-cancellation-lpi.translations';
// Types
import { DetailsType } from 'types/details.type';
// Utilities
import { challengeFlowButtonIsDisabled } from 'utils/challenges/challenge.utils';
import { isSameObject } from 'utils/app.utils';

const FinancingInsurancesCancellationLPIConfirmationComponent = (): React.ReactElement => {
  const {
    contractId,
    challenged,
    fetching,
    formatMessage,
    formatDate,
    handleConfirmCancelLPIContractButtonClick,
    insurancesInfo,
    handleManageFailedCancelLPIChallengeEvent: onError,
    handleManageSuccessCancelLPIChallengeEvent: onSuccess,
  } = useFinancingInsurancesCancellationLPI();

  return (
    <AppCardContentSectionComponentStyled>
      <FinancingInsurancesCancellationLPIComponentStyled>
        <TitleHeaderComponent {...FINANCING_INSURANCES_CANCELLATION_LPI_TITLE_PROPS}>
          <FormattedMessageComponent id={TITLE} />
        </TitleHeaderComponent>
        <LayoutColumnFlexComponentStyled>
          <ParagraphComponent {...FINANCING_INSURANCES_CANCELLATION_LPI_DESCRIPTION_PROPS}>
            <FormattedMessageComponent
              id={
                insurancesInfo?.insurances?.LPI?.status === InsuranceStatusEnumeration.COOLING_OFF
                  ? DESCRIPTION_2
                  : DESCRIPTION
              }
            />
          </ParagraphComponent>
          <DetailConfirmationComponent
            details={
              [
                {
                  label: formatMessage({ id: DETAIL_CONFIRMATION_CANCELLING_LABEL }),
                  value: formatMessage({ id: DETAIL_CONFIRMATION_CANCELLING_VALUE }),
                },
                insurancesInfo?.insurances?.LPI?.status !==
                  InsuranceStatusEnumeration.COOLING_OFF &&
                  insurancesInfo?.insurances?.LPI?.endCoverageDate && {
                    label: formatMessage({ id: DETAIL_CONFIRMATION_STATUS_LABEL }),
                    value: formatMessage(
                      { id: DETAIL_CONFIRMATION_STATUS_VALUE },
                      { date: formatDate(insurancesInfo?.insurances?.LPI?.endCoverageDate) }
                    ),
                  },
              ].filter(Boolean) as DetailsType
            }
          />
          <ChallengeWrapperComponent
            {...FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_CHALLENGE_PROPS}
            {...{ onError, onSuccess }}
            baseServiceBuilder={{ params: { contractId, insuranceType: 'LPI' } }}
          />
          <WizardStepFooterComponent
            {...FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_FOOTER_PROPS}
            shouldShowModal={!isSameObject(challenged, false)}
            rightButtonConfiguration={{
              ...FINANCING_INSURANCES_CANCELLATION_LPI_FOOTER_ACTIONS_BUTTON_PROPS,
              disabled: challengeFlowButtonIsDisabled({ challenged, fetching }),
              children: <FormattedMessageComponent id={BUTTON} />,
              loading: fetching,
              onClick: handleConfirmCancelLPIContractButtonClick,
            }}
          />
        </LayoutColumnFlexComponentStyled>
      </FinancingInsurancesCancellationLPIComponentStyled>
    </AppCardContentSectionComponentStyled>
  );
};

export { FinancingInsurancesCancellationLPIConfirmationComponent };
