// Vendors
import React from 'react';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Components
import {
  ExpandableRowComponent,
  LineSeparatorComponent,
  ParagraphComponent,
  paragraphConstants,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  TITLE,
  TERMINATION,
  NOTIFY,
  PAYMENT,
} from './translations/financing-insurances-contracting-lpi-step3-additional.translations';
// Utils
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';

export const FinancingInsurancesContractingLPIStep3AdditionalComponent = (): React.ReactElement => {
  return (
    <ExpandableRowComponent
      type="accordion"
      title={
        <ParagraphComponent bold size={paragraphConstants.SIZES.M}>
          <FormattedMessageComponent id={TITLE} />
        </ParagraphComponent>
      }
    >
      <LayoutColumnFlexComponentStyled
        gap={AmazonTheme.default.spaces.spacingS}
        marginTop={AmazonTheme.default.spaces.spacingXxs}
      >
        <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingXxs}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={TERMINATION.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent
              id={TERMINATION.description}
              values={{
                terminationLink: chunk =>
                  makeChunkLinkIntlUtil({
                    chunk,
                    onClick: () => window.open('https://www.zinia.de/amazon', '_blank'),
                  }),
              }}
            />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
        <LineSeparatorComponent />
        <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingXxs}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={NOTIFY.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={NOTIFY.description} />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
        <LineSeparatorComponent />
        <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingXxs}>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={PAYMENT.title} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={PAYMENT.description} />
          </ParagraphComponent>
        </LayoutColumnFlexComponentStyled>
      </LayoutColumnFlexComponentStyled>
    </ExpandableRowComponent>
  );
};
