// Vendors
import styled from 'styled-components';

export const TransactionsTableHeaderLinkBarComponentStyled = styled.aside<{ isMobile: boolean }>`
  align-items: center;
  display: flex;
  gap: ${({ theme, isMobile }) => (isMobile ? theme.spaces.spacingS : theme.spaces.spacingL)};
  padding-left: ${({ theme }) => theme.spaces.spacingS};
  height: ${({ theme }) => theme.spaces.spacingXxl};
`;
