// Components
import SecurityKeysAccessKeyChangeFirstStep from '../components/first-step/security-keys-access-key-change-first-step.component';
import SecurityKeysAccessKeyChangeSecondStepComponent from '../components/second-step/security-keys-access-key-change-second-step.component';
import SecurityKeysAccessKeyChangeThirdStepComponent from '../components/third-step/security-keys-access-key-change-third-step.component';
// Translations
import { FAILED, SUCCESS } from '../translations/security-keys-access-key-change-step.configuration.translations';
import translations from '../translations/security-keys-access-key-change.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const ACCESS_KEYS_CHANGE_WIZARD_PROPS = {
  feedbackConfiguration: {
    errorConfiguration: {
      description: FAILED.CONFIRMATION.CONTENT,
      title: FAILED.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SECURITY,
    },
    successConfiguration: {
      description: SUCCESS.CONFIRMATION.CONTENT,
      title: SUCCESS.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SECURITY,
      linkLabel: SUCCESS.BACK_BUTTTON,
    },
  },
  steps: [
    {
      component: SecurityKeysAccessKeyChangeFirstStep,
      title: translations.STEP_1,
    },
    {
      component: SecurityKeysAccessKeyChangeSecondStepComponent,
      title: translations.STEP_2,
    },
    {
      component: SecurityKeysAccessKeyChangeThirdStepComponent,
      title: translations.STEP_3,
    },
  ],
};

export { ACCESS_KEYS_CHANGE_WIZARD_PROPS };
