// Types
import {
  CaptchaBuilderHandlersType,
  CaptchaMethodHandlersType,
  CaptchaMethodsHandlersType,
} from './types/captcha.handler.types';

const verifyCaptchaEventHandler = ({ setCaptcha, token }: CaptchaMethodHandlersType): void => {
  token && setCaptcha(token);
};

const CaptchaHandlers = (props: CaptchaBuilderHandlersType): CaptchaMethodsHandlersType => ({
  handleVerifyCaptchaEvent: (token: string) => verifyCaptchaEventHandler({ ...props, token }),
});

export { CaptchaHandlers };
