export default {
  PERSONAL_EMAIL_STEP_1_NEW_INPUT_PROPS: {
    isRequired: true,
    placeholder: 'Ex: name-button@email.com',
    testing: {
      helperId: 'personal-email-step1-new-email-helper',
      inputId: 'personal-email-step1-new-email',
      labelId: 'personal-email-step1-new-email-label',
    },
  },
};
