// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { useTransactionsReportNotReceivedDocumentsActionsHooks } from './hooks/transactions-report-not-received-documents-actions.hook';
// Types
import { TransactionsReportNotReceivedDocumentsActionsComponentType } from './types/transactions-report-not-received-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from './utils/transactions-report-not-received-documents-actions.utils';

export const TransactionsReportNotReceivedDocumentsActionsComponent = ({
  files,
  setFiles,
}: TransactionsReportNotReceivedDocumentsActionsComponentType): React.ReactElement => {
  const { handleGoBack } = useTransactionsReportNotReceivedDocumentsActionsHooks(setFiles);

  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      hasDefaultLeftButton
      leftButtonConfiguration={{
        onClick: () => handleGoBack(),
      }}
      rightButtonConfiguration={{ disabled: !hasRequiredDocs(files) }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};
