// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import AppToastComponent from 'components/toast/toast.component';
import OrchestratorFooterComponent from './components/footer/orchestrator-footer.component';
import OrchestratorHeaderComponent from './components/header/orchestrator-header.component';
import OrchestratorRouterComponent from './components/router/orchestrator-router.component';
import RequestSignatureKeyModalComponent from 'components/request-signature-key-modal/request-signature-key-modal.component';
// Hooks
import { useOrchestratorHook } from './hooks/orchestrator.hook';
// Styles
import {
  OrchestratorContainerStyled,
  OrchestratorWrapperContainerStyled,
} from './orchestrator.container.styled';

const OrchestratorContainer = (): React.ReactElement => {
  const { fetching, notchSize, pageLoader } = useOrchestratorHook();

  return (
    <OrchestratorWrapperContainerStyled {...{ fetching, pageLoader }}>
      <FetchErrorComponent {...{ fetching }}>
        <OrchestratorContainerStyled {...{ notchSize }}>
          <OrchestratorHeaderComponent />
          <OrchestratorRouterComponent />
          <OrchestratorFooterComponent />
          <AppToastComponent />
          <RequestSignatureKeyModalComponent />
        </OrchestratorContainerStyled>
      </FetchErrorComponent>
    </OrchestratorWrapperContainerStyled>
  );
};

export default OrchestratorContainer;
