// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
import { FinancingPPICancellationLandingResumeCoveredComponent } from './components/covered/financing-ppi-cancellation-landing-resume-covered.component';
// Constants
import { FINANCING_PPI_CANCELLATION_LANDING_PRODUCT_PROPS } from './constants/financing-ppi-cancellation-landing-resume.constants';
// Styles
import { FinancingPPICancellationLandingResumeComponentStyled } from './financing-ppi-cancellation-landing-resume.component.styled';
import { Flex1ComponentStyled } from 'styles/app.styled';
// Translations
import {
  PRODUCT_LABEL,
  PRODUCT_VALUE,
} from './translations/financing-ppi-cancellation-landing-resume.translations';

const FinancingPPICancellationLandingResumeComponent = (): React.ReactElement => (
  <FinancingPPICancellationLandingResumeComponentStyled>
    <Flex1ComponentStyled>
      <ResumeItemComponent
        {...FINANCING_PPI_CANCELLATION_LANDING_PRODUCT_PROPS}
        label={<FormattedMessageComponent id={PRODUCT_LABEL} />}
        value={<FormattedMessageComponent id={PRODUCT_VALUE} />}
      />
    </Flex1ComponentStyled>
    <FinancingPPICancellationLandingResumeCoveredComponent />
  </FinancingPPICancellationLandingResumeComponentStyled>
);

export { FinancingPPICancellationLandingResumeComponent };
