// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
// Types
import { CardStatusButtonBuilderReturnTrackingHandlersType } from './types/card-status-button-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const activateCardButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'activateCard',
  },
  type: TrackingEventsEnum.LINK,
});

const switchOnCardButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'switchOnCard',
  },
  type: TrackingEventsEnum.LINK,
});

const CardStatusButtonTrackingHandlers = (
  track: TrackBuilderType
): CardStatusButtonBuilderReturnTrackingHandlersType => ({
  handleActivateCardButtonClickTracking: () => track(activateCardButtonClickTrackingHandler()),
  handleSwitchOnCardButtonClickTracking: () => track(switchOnCardButtonClickTrackingHandler()),
});

export default CardStatusButtonTrackingHandlers;
