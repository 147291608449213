// Types
import { AddressType } from 'types/address.type';
import { ChangeAddressBodyType } from 'containers/personal/views/address/types/change-address-body.type';

const formatChangeAddressParams = (newAddress: AddressType): ChangeAddressBodyType => {
  return {
    addressDetails: [
      {
        additionalItems1: newAddress.additionalItems1 || '',
        addressTypeTCode: newAddress.addressType,
        countryCode: newAddress.countryCode,
        enableConfirmation: newAddress.enableConfirmation,
        number: newAddress.number,
        postCode: newAddress.postalCode,
        streetLine1: newAddress.street,
        town: newAddress.city,
      },
    ],
  };
};

export { formatChangeAddressParams };
