// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from '../constants/chat.services.constants';
// Types
import {
  ChatConversation,
  GetChatConversations,
  GetChatStatusReturn,
  GetMessagesReturn,
  ResolverResponse,
  SendMessageResolverProps,
  StartSessionResolverProps,
} from '../types/chat.services.type';

export const getStatus = async (): ResolverResponse<GetChatStatusReturn> =>
  apiUtilsCb.get({
    ...constants.GET_CHAT_STATUS_CONFIG,
  });

export const getConversations = async (): ResolverResponse<GetChatConversations> =>
  apiUtilsCb.get({
    ...constants.GET_CHAT_CONVERSATIONS_CONFIG,
  });

export const getChatMessages = async ({
  chatId,
}: Pick<ChatConversation, 'chatId'>): ResolverResponse<GetMessagesReturn> =>
  apiUtilsCb.get({
    ...constants.GET_CHAT_MESSAGES_CONFIG,
    pathParams: {
      chatId,
    },
  });

export const startChatSession = async ({
  applicationLocale,
  ...params
}: StartSessionResolverProps): ResolverResponse<GetMessagesReturn> =>
  apiUtilsCb.post({
    ...constants.START_CHAT_SESSION_CONFIG,
    language: applicationLocale,
    params,
  });

export const sendChatMessage = async ({
  chatId,
  ...params
}: SendMessageResolverProps): ResolverResponse<GetMessagesReturn> =>
  apiUtilsCb.post({
    ...constants.SEND_CHAT_MESSAGE_CONFIG,
    pathParams: {
      chatId,
    },
    params,
  });

export const closeChatSession = async (chatId: string): ResolverResponse<void> =>
  apiUtilsCb.post({
    ...constants.CLOSE_CHAT_SESSION_CONFIG,
    pathParams: {
      chatId,
    },
  });
