// Types
import { TransactionsLandingRowDetailsCreatePlanBuilderHandlersType } from '../types/transactions-landing-row-details-create-plan.handlers.type';
// Utilities
import { cardActive } from 'utils/card.utils';

const cardIsActiveWithPoints = ({
  reward,
  status,
}: Pick<
  TransactionsLandingRowDetailsCreatePlanBuilderHandlersType,
  'reward' | 'status'
>): boolean => Boolean(cardActive(status) && Number(reward?.amount) > 0);

export { cardIsActiveWithPoints };
