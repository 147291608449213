// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/profile-personal-name-title.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ProfilePersonalNameTitleContextType } from './types/profile-personal-name-title.context.type';

const ProfilePersonalNameTitleContext = createContext<ProfilePersonalNameTitleContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const ProfilePersonalNameTitleContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<ProfilePersonalNameTitleContextType>> => {
  const [editTitle, setEditTitle] = useState<boolean>(false);

  return (
    <ProfilePersonalNameTitleContext.Provider value={{ editTitle, setEditTitle }}>
      {children as React.ReactNode}
    </ProfilePersonalNameTitleContext.Provider>
  );
};

export const ProfilePersonalNameTitleContextConsumerHook =
  (): ProfilePersonalNameTitleContextType => useContext(ProfilePersonalNameTitleContext);

/* eslint-disable */
export const ProfilePersonalNameTitleContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<ProfilePersonalNameTitleContextType>> =>
    (
      <ProfilePersonalNameTitleContextProvider>
        <Component {...props} />
      </ProfilePersonalNameTitleContextProvider>
    );
