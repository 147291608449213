// Constants
import constants from '../constants/login-header.constants';
import { DEFAULT_LOGO } from './constants/login-header.configurations.constants';
// Types
import { GetLoginHeaderConfigurationsPropsType } from './types/login-header-props.configurations.type';

const getLoginHeaderLogoConfiguration = (): GetLoginHeaderConfigurationsPropsType => ({
  ...constants.LOGIN_HEADER_ASSET_PROPS,
  id: DEFAULT_LOGO,
});

export { getLoginHeaderLogoConfiguration };
