// Enumerations
import { CaseTypeEnum } from 'containers/transactions/views/report/enumerations/case-type.enumeration';
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
import { MotiveDalEnum } from 'containers/transactions/views/report/enumerations/motive-dal.enumeration';

const DISPUTE_PARAMS = {
  caseType: CaseTypeEnum.MANAGEMENT_TECH_DISPUTES,
  claimReasonType: ClaimReasonEnum.PERFORMED_CANCELLATION_OF_RESERVATION,
  motiveDal: MotiveDalEnum.CHARGED_FOR_SERVICE_ALREADY_CANCELED,
  blockCard: false,
};

export { DISPUTE_PARAMS };
