// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GET_3D_SECURE_SERVICE_CONFIGURATION } from './constants/card-settings-check-3d-wizard-step2.services.constants';
// Types
import { CardSettingsCheck3dWizardStep2ApiReturnType } from '../types/card-settings-check-3d-wizard-step2-api-return.type';

const fetch3DSecureCodeService = async (
  progressId?: string
): Promise<[CardSettingsCheck3dWizardStep2ApiReturnType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...GET_3D_SECURE_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
  });

export { fetch3DSecureCodeService };
