// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, ScriptBoxComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PERSONAL_EMAIL_STEP_1_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_1_MESSAGE_TITLE_PROPS,
  PERSONAL_EMAIL_STEP_1_MESSAGE_DESCRIPTION_PROPS,
} from './constants/personal-email-step1-script-box.constants.cc';
import { PersonalEmailStep1MessageViewStyled } from '../../personal-email-step1.component.styled';
// Translations
import {
  MESSAGE_DESCRIPTION,
  ADDITIONAL_INFORMATION,
} from './translations/personal-email-step1-message.translations.cc';

const PersonalEmailStep1ScriptBoxComponent = (): React.ReactElement => (
  <ScriptBoxComponent {...PERSONAL_EMAIL_STEP_1_MESSAGE_PROPS}>
    <PersonalEmailStep1MessageViewStyled>
      <ParagraphComponent {...PERSONAL_EMAIL_STEP_1_MESSAGE_TITLE_PROPS}>
        <FormattedMessageComponent id={ADDITIONAL_INFORMATION} />
      </ParagraphComponent>
      <ParagraphComponent {...PERSONAL_EMAIL_STEP_1_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
      </ParagraphComponent>
    </PersonalEmailStep1MessageViewStyled>
  </ScriptBoxComponent>
);

export { PersonalEmailStep1ScriptBoxComponent };
