// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { AvatarHookType } from './types/avatar.hook.type';

const useAvatarHook = (): AvatarHookType => {
  const { profile } = AppContextConsumerHook(),
    { avatar } = profile?.userDetails || {};

  return { avatar };
};

export default useAvatarHook;
