// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { CardSettingsStatementsTableRowTagEnumeration } from '../enumerations/card-settings-statements-table-row-tag.enumeration';

const CARD_STATEMENTS_TABLE_ROW_TAG_PROPS = {
    testId: 'card-settings-statements-table-row-tag',
  },
  CARD_STATEMENTS_TABLE_ROW_TAG_TYPES = {
    [CardSettingsStatementsTableRowTagEnumeration.PAID]: tagConstants.TYPES.SUCCESS,
    [CardSettingsStatementsTableRowTagEnumeration.PENDING]: tagConstants.TYPES.PENDING,
    [CardSettingsStatementsTableRowTagEnumeration.UNPAID]: tagConstants.TYPES.WARNING,
  };

export { CARD_STATEMENTS_TABLE_ROW_TAG_PROPS, CARD_STATEMENTS_TABLE_ROW_TAG_TYPES };
