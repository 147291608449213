// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLPIStep2PlansErrorComponent } from './components/error/financing-insurances-contracting-lpi-step2-plans-error.component';
import { SelectorBoxComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Contexts
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import { FinancingInsurancesContractingLPIStep2PlansComponentStyled } from './financing-insurances-contracting-lpi-step2-plans.component.styled';
// Translations
import {
  DISCLAIMER,
  PLAN_COVERAGE,
  PLAN_PRICE,
  TITLE,
} from './translations/financing-insurances-contracting-lpi-step2-plans.translations';
// Types
import { FinancingInsurancesContractingLPIStep2PlansType } from './types/financing-insurances-contracting-lpi-step2-plans.component.type';
// Utils
import { getCurrencyFractionDigits } from 'utils/number.utils';

export const FinancingInsurancesContractingLPIStep2PlansComponent = ({
  error,
}: FinancingInsurancesContractingLPIStep2PlansType): React.ReactElement | null => {
  const { formatMessage, formatNumber } = useCbIntl();
  const { insurancesInfo } = useInsurancesContextConsumerHook();
  const { plan, setPlan } = useFinancingInsurancesContractingLPIContextConsumerHook();

  if (!insurancesInfo?.insurances?.LPI?.availablePlans) {
    return null;
  }

  const {
    insurances: {
      LPI: { availablePlans },
    },
  } = insurancesInfo;

  return (
    <FinancingInsurancesContractingLPIStep2PlansComponentStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={TITLE} />
      </ParagraphComponent>
      {availablePlans.map(({ code, coverage, grossPremium }) => (
        <SelectorBoxComponent
          key={code}
          title={formatMessage(
            { id: PLAN_COVERAGE },
            {
              coverage: formatNumber(coverage, {
                ...EURO_FORMAT_OPTIONS,
                minimumFractionDigits: getCurrencyFractionDigits(coverage),
              }),
            }
          )}
          selected={plan === code}
          onClick={() => setPlan(code)}
        >
          <ParagraphComponent>
            <FormattedMessageComponent
              id={PLAN_PRICE}
              values={{
                price: formatNumber(grossPremium, {
                  ...EURO_FORMAT_OPTIONS,
                  minimumFractionDigits: getCurrencyFractionDigits(grossPremium),
                }),
              }}
            />
          </ParagraphComponent>
        </SelectorBoxComponent>
      ))}
      <ParagraphComponent>
        <FormattedMessageComponent id={DISCLAIMER} />
      </ParagraphComponent>
      <FinancingInsurancesContractingLPIStep2PlansErrorComponent error={error} />
    </FinancingInsurancesContractingLPIStep2PlansComponentStyled>
  );
};
