// Vendors
import { useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsNewCardContextConsumerHook } from 'containers/card-settings/views/new-card/contexts/card-settings-new-card.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { BlockCardContextConsumerHook } from 'components/block-card/contexts/block-card.context';
// Handlers
import { CardSettingsNewCardConfirmationHandlers } from '../handlers/card-settings-new-card-confirmation.handlers';
import { ChallengeHandlers } from 'handlers/challenge/challenge.handlers';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ChallengeType } from 'types/challenge.type';
import { CardSettingsNewCardConfirmationReturnHookType } from './types/card-settings-new-card-confirmation-return.hook.type';
// Utils
import { getSelectedAddress } from 'utils/addresses/addresses.utils';

export const CardSettingsNewCardConfirmationHook =
  (): CardSettingsNewCardConfirmationReturnHookType => {
    const [challenge, setChallenge] = useState<ChallengeType | undefined>();
    const [challengeError, setChallengeError] = useState(false);

    const {
      currentCardDetail: { id: cardId, pan },
    } = AppContextConsumerHook();
    const { setCard } = ManagerContextConsumerHook();
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const { setError } = useCardSettingsNewCardContextConsumerHook();
    const { selectedAddress, tempAddress, setSelectedAddress, setTempAddress } =
      BlockCardContextConsumerHook();
    const { address: customerAddress } = useAddressHook();
    const { formatMessage } = useCbIntl();

    const address = getSelectedAddress({ customerAddress, selectedAddress, tempAddress });

    return {
      ...CardSettingsNewCardConfirmationHandlers({
        address,
        cardId,
        challenge,
        setCard,
        setChallengeError,
        setError,
        setResult,
        setSelectedAddress,
        setTempAddress,
      }),
      ...ChallengeHandlers({
        setChallenge,
        setChallengeError,
      }),
      address,
      cardId,
      challenge,
      challengeError,
      formatMessage,
      pan,
    };
  };
