// Vendors
import styled from 'styled-components';

export const ConsentsStep1HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  margin-bottom: ${({ theme }) => theme.spaces.spacingXxs};
`;

export const ConsentsStep1BodyWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
