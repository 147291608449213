const WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS = {
  hasDefaultRightButton: false,
};

const FIRST_STEP_TARGETS = {
  next: 2,
};

const SECOND_STEP_TARGETS = {
  next: 3,
  previous: 1,
};

const THIRD_STEP_TARGETS = {
  next: 4,
  previous: 2,
};

const FOURTH_STEP_TARGETS = {
  next: 5,
  previous: 3,
};

export {
  FIRST_STEP_TARGETS,
  FOURTH_STEP_TARGETS,
  SECOND_STEP_TARGETS,
  THIRD_STEP_TARGETS,
  WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS,
};
