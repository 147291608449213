// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import { AccessPortabilityRequestStep2ContextConsumerHook } from 'containers/personal/views/access-portability/views/request/components/step2/contexts/access-portability-request-step2.context';
// Event handlers
import RequestPortabilityStep3ChallengeHandlers from '../handlers/request-portability-step3-challenge.handlers';
// Types
import { RequestPortabilityStep3ChallengeHookType } from './types/request-portability-step3-challenge.hook.type';

const RequestPortabilityStep3ChallengeHook = (): RequestPortabilityStep3ChallengeHookType => {
  const { formatMessage } = useCbIntl();

  const [error, setError] = useState<boolean>(false);

  const { otpId, otpValue, setOtpValue } = AccessPortabilityRequestStep2ContextConsumerHook(),
    {
      challenged,
      fetching,
      portabilityParams,
      progressId,
      setChallenged,
      setProgressId,
      setResult,
    } = AccessPortabilityContextConsumerHook();
  const {
    handleSetupStartedChallengeEvent,
    handleManageErrorOnRequestPortabilityEvent,
    handleManageSuccessOnRequestPortabilityEvent,
    handleChallengeRetryButtonClick,
  } = RequestPortabilityStep3ChallengeHandlers({
    otpId,
    otpValue,
    progressId,
    setChallenged,
    setError,
    setProgressId,
    setResult,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupStartedChallengeEvent();
  }, []);

  return {
    challenged,
    error,
    fetching,
    formatMessage,
    handleChallengeRetryButtonClick,
    handleManageErrorOnRequestPortabilityEvent,
    handleManageSuccessOnRequestPortabilityEvent,
    handleSetupStartedChallengeEvent,
    otpValue,
    portabilityParams,
    setOtpValue,
  };
};

export default RequestPortabilityStep3ChallengeHook;
