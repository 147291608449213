// Types
import { SecurityKeysSignatureKeyChangerHandlersReturnType } from './types/security-keys-signature-key-change.handlers.return.type';
import { SecurityKeysSignatureKeyChangeHandlersType } from './types/security-keys-signature-key-change.handlers.type';

const onUserEnterHandler = ({ setFromPersonal, searchParams }: SecurityKeysSignatureKeyChangeHandlersType): void => {
  setFromPersonal(searchParams.get('fromPersonal') === 'true');
};

const SecurityKeysSignatureKeyChangeHandlers = (
  props: SecurityKeysSignatureKeyChangeHandlersType
): SecurityKeysSignatureKeyChangerHandlersReturnType => ({
  userEnterHandler: () => onUserEnterHandler(props)
});

export { SecurityKeysSignatureKeyChangeHandlers };