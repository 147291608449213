// Constants
import { AMOUNT_LABEL_VALUE, INPUT_PROPS } from './../constants/amount.constants';
// Translations
import { INPUT_LABEL } from '../translations/amount.translations';
import { MAX, MIN } from './translations/amount.component.configuration.translations';
// Types
import { GetInputConfigurationPropsType } from './types/get-input-configuration-props.type';
import { GetInputConfigurationReturnType } from './types/get-input-configuration-return.type';
// Utils
import { getCurrency } from 'containers/financing/views/fast-cash/utils/financing-fast-cash.utils';

export const getInputConfiguration = ({
  amountValue,
  currency,
  formatMessage,
  handleInputClickTracking,
  handleInputErrorTracking,
  handleOnChangeValue,
  inputErrorMsg,
  maxAmount,
  minAmount,
}: GetInputConfigurationPropsType): GetInputConfigurationReturnType => {
  const currencySymbol = getCurrency(currency);

  return {
    currencyRange: {
      minMessage: `${formatMessage({ id: MIN })} ${minAmount}`,
      maxMessage: `${formatMessage({ id: MAX })} ${maxAmount}`,
      minMessageId: 'currency-range-min-amount',
      maxMessageId: 'currency-range-max-amount',
    },
    label: formatMessage(
      { id: INPUT_LABEL },
      { amount: `${AMOUNT_LABEL_VALUE} ${currencySymbol}` }
    ),
    suffix: currencySymbol,
    ...INPUT_PROPS,
    value: amountValue ?? '',
    errorMessage: inputErrorMsg,
    onChange: handleOnChangeValue,
    onClick: inputErrorMsg ? handleInputErrorTracking : handleInputClickTracking,
  };
};
