// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  DASHBOARD_SPENT_SUMMARY_SECONDARY_TITLE_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.XS,
    testId: 'dashboard-spent-summary-secondary-title',
  },
  DASHBOARD_SPENT_SUMMARY_SECONDARY_ADDITIONAL_PROPS: {
    size: paragraphConstants.SIZES.XS,
    testId: 'dashboard-spent-summary-secondary-additional',
  },
};
