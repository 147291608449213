// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { PersonalNumberHookType } from './types/personal-number.hook.type';
// Utils
import { getPrimaryTelephone } from './utils/personal-number-data-contact.hook.utils';

const PersonalNumberHook = (): PersonalNumberHookType => {
  const { profile, customerId } = AppContextConsumerHook(),
    { telephones, email } = profile?.contactDetails || {};

  return {
    customerId: customerId || '',
    currentEmail: email,
    ...getPrimaryTelephone(telephones),
  };
};

export default PersonalNumberHook;
