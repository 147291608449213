// Vendors
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Contexts
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
import { AppContextConsumerHook } from 'contexts/app.context';
// Enums
import { AccessPortabilityTypeEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Handlers
import AccessPortabilityCardHandlers from '../handlers/access-portability-card.handlers';
import AccessPortabilityCardTrackingHandlers from '../handlers/access-portability-card.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { AccessPortabilityCardHookType } from './types/access-portability-card.hook.type';

const AccessPortabilityCardHook = (
  rightsType: AccessPortabilityTypeEnumeration
): AccessPortabilityCardHookType => {
  const { formatMessage } = useCbIntl();

  const { information } = AccessPortabilityContextConsumerHook(),
    { empty } = information || {};

  const { profile } = AppContextConsumerHook(),
    { email } = profile?.contactDetails || {};

  return {
    ...AccessPortabilityCardHandlers({
      ...AppTrackingHook(AccessPortabilityCardTrackingHandlers),
      navigate: useNavigate(),
      rightsType,
    }),
    formatMessage,
    error: empty,
    email,
  };
};

export default AccessPortabilityCardHook;
