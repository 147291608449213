const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cardsLanding.paymentSecurity.';

const CHECK_3D = `${DOMAIN}check3DSecureLabel`,
  CHECK_3D_LINK = `${DOMAIN}check3DSecureLink`,
  CHANGE_3D = `${DOMAIN}change3DSecureLabel`,
  CHANGE_3D_LINK = `${DOMAIN}change3DSecureLink`,
  PURCHASE_IN_STORES = `${DOMAIN}purchaseInNotSecureStoresLabel`,
  PURCHASE_LINK_CHANGE = `${DOMAIN}purchaseInNotSecureStores.changeLink`;

export {
  CHECK_3D,
  CHECK_3D_LINK,
  CHANGE_3D,
  CHANGE_3D_LINK,
  PURCHASE_IN_STORES,
  PURCHASE_LINK_CHANGE,
};
