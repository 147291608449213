// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { CardStatusButtonBuilderReturnHandlersType } from './types/card-status-button-builder-return.handlers.type';
import { CardStatusButtonBuilderHandlersType } from './types/card-status-button-builder.handlers.type';
import { HideSwitchOnModalHandlerProps } from './types/hide-switch-on-modal-handler-props.type';
// Utilities
import { cardIsInactive } from 'utils/card.utils';
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const activateCardHandler = ({
  activateCardRedirection,
  breadCrumbPath,
  handleActivateCardButtonClickTracking,
  navigate,
}: Pick<
  CardStatusButtonBuilderHandlersType,
  | 'activateCardRedirection'
  | 'breadCrumbPath'
  | 'handleActivateCardButtonClickTracking'
  | 'navigate'
>): any => {
  const url = generatePathRoute([RoutesEnumeration.CARD_SETTINGS, RoutesEnumeration.ACTIVATE]);

  handleActivateCardButtonClickTracking();
  navigate(generateAbsoluteRoute(url), {
    state: {
      activateCardRedirection,
      breadCrumbPath,
    },
  });
};

const switchOnCardHandler = ({
  handleSwitchOnCardButtonClickTracking,
  setShowActivateModal,
}: Pick<
  CardStatusButtonBuilderHandlersType,
  'handleSwitchOnCardButtonClickTracking' | 'setShowActivateModal'
>): any => {
  handleSwitchOnCardButtonClickTracking();
  setShowActivateModal(true);
};

const manageChangeCardStatusButtonClickHandler = async ({
  activateCardRedirection,
  breadCrumbPath,
  handleActivateCardButtonClickTracking,
  handleSwitchOnCardButtonClickTracking,
  navigate,
  setShowActivateModal,
  status,
}: CardStatusButtonBuilderHandlersType): Promise<void> => {
  cardIsInactive(status)
    ? activateCardHandler({
        activateCardRedirection,
        breadCrumbPath,
        handleActivateCardButtonClickTracking,
        navigate,
      })
    : switchOnCardHandler({ handleSwitchOnCardButtonClickTracking, setShowActivateModal });
};

const hideSwitchOnModalHandler = ({
  callback,
  setShowActivateModal,
}: HideSwitchOnModalHandlerProps): any => {
  setShowActivateModal(false);
  callback?.();
};

const CardStatusButtonHandlers = ({
  activateCardRedirection,
  breadCrumbPath,
  handleActivateCardButtonClickTracking,
  handleSwitchOnCardButtonClickTracking,
  navigate,
  setShowActivateModal,
  status,
}: CardStatusButtonBuilderHandlersType): CardStatusButtonBuilderReturnHandlersType => ({
  handleManageChangeCardStatusButtonClick: () =>
    manageChangeCardStatusButtonClickHandler({
      activateCardRedirection,
      breadCrumbPath,
      handleActivateCardButtonClickTracking,
      handleSwitchOnCardButtonClickTracking,
      navigate,
      setShowActivateModal,
      status,
    }),
  handleHideSwitchOnModal: (callback?: any) =>
    hideSwitchOnModalHandler({ callback, setShowActivateModal }),
});

export default CardStatusButtonHandlers;
