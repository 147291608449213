const PARAGRAPH_PROPS_TITLE = {
  testId: 'exchange-rate-modal-description-transaction-amount-title',
};
const PARAGRAPH_PROPS_VALUE = {
  testId: 'exchange-rate-modal-description-transaction-amount-value',
};
const CONVERTED_AMOUNT_PARAGRAPH_PROPS_TITLE = {
  testId: 'converted-amount-modal-description-transaction-amount-title',
};
const CONVERTED_AMOUNT_PARAGRAPH_PROPS_VALUE = {
  testId: 'converted-amount-modal-description-transaction-amount-value',
};
const FORMATTED_NUMPER_SIGNS_PROP = 'never';

export {
  PARAGRAPH_PROPS_TITLE,
  PARAGRAPH_PROPS_VALUE,
  FORMATTED_NUMPER_SIGNS_PROP,
  CONVERTED_AMOUNT_PARAGRAPH_PROPS_TITLE,
  CONVERTED_AMOUNT_PARAGRAPH_PROPS_VALUE,
};
