// Vendors
import React from 'react';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Components
import { DownloadDocumentComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useFinancingInsurancesContractingLPIStep4Download } from './hooks/financing-insurances-contracting-lpi-step4-download.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { FinancingInsurancesContratingLPIDownloadGridStyled } from 'containers/financing/views/ppi/components/contracting/lpi/financing-insurances-contracting-lpi.component.styled';
// Translations
import {
  CONTRACT_CONFIRMATION_MESSAGE,
  DOWNLOAD_CONTRACT_BUTTON_LABEL,
} from './translations/financing-insurances-contracting-lpi-step4-download.translations';
// Types
import { FinancingInsurancesContractingLPIStep4DownloadComponentType } from './types/financing-insurances-contracting-lpi-step4-download.component.type';
// Utils
import { getDownloadState } from 'containers/financing/views/ppi/components/contracting/lpi/utils/financing-insurances-contracting-lpi.utils';

const FinancingInsurancesContractingLPIStep4DownloadComponent = ({
  downloadedError,
  handleToggleDownloaded,
}: FinancingInsurancesContractingLPIStep4DownloadComponentType): React.ReactElement => {
  const { downloading, handleDownloadLinkClick } =
    useFinancingInsurancesContractingLPIStep4Download({ handleToggleDownloaded });

  return (
    <LayoutColumnFlexComponentStyled gap={AmazonTheme.default.spaces.spacingS}>
      <ParagraphComponent>
        <FormattedMessageComponent id={CONTRACT_CONFIRMATION_MESSAGE} />
      </ParagraphComponent>
      <FinancingInsurancesContratingLPIDownloadGridStyled>
        <DownloadDocumentComponent
          onDownload={handleDownloadLinkClick}
          onRetryDownload={handleDownloadLinkClick}
          state={getDownloadState({ downloading, downloadedError })}
          translations={{
            DOWNLOAD_LINK: <FormattedMessageComponent id={DOWNLOAD_CONTRACT_BUTTON_LABEL} />,
            RETRY_DOWNLOAD_MESSAGE: (
              <FormattedMessageComponent id={DOWNLOAD_CONTRACT_BUTTON_LABEL} />
            ),
            RETRY_DOWNLOAD_LINK: <FormattedMessageComponent id={DOWNLOAD_CONTRACT_BUTTON_LABEL} />,
          }}
        />
      </FinancingInsurancesContratingLPIDownloadGridStyled>
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingInsurancesContractingLPIStep4DownloadComponent };
