// Contexts
import { useCardSettingsCheck3DWizardContextConsumerHook } from 'containers/card-settings/views/check-3d/components/wizard/contexts/card-settings-check-3d-wizard.context';
// Event handlers
import CardSettingsCheck3dWizardStep2ChallengeHandlers from '../handlers/card-settings-check-3d-wizard-step2-challenge.handlers';
// Types
import { CardSettingsCheck3dWizardStep2ChallengeBuilderReturnHandlersType } from '../handlers/types/card-settings-check-3d-wizard-step2-challenge.handlers.type';

const useCardSettingsCheck3dWizardStep2ChallengeHook =
  (): CardSettingsCheck3dWizardStep2ChallengeBuilderReturnHandlersType => {
    const { setResult, setProgressId } = useCardSettingsCheck3DWizardContextConsumerHook();

    return CardSettingsCheck3dWizardStep2ChallengeHandlers({
      setProgressId,
      setResult,
    });
  };

export default useCardSettingsCheck3dWizardStep2ChallengeHook;
