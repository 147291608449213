// Constants
import { ERROR_MESSAGES_WEBSOCKETS } from './constants/websocket.constants';
// Types
import { IAsyncActiveConnection } from './types/websocket-connection.types';
import { IEventsCallback } from './types/websocket-events.types';

export class WebSockets implements IAsyncActiveConnection<string> {
  private readonly url: string;
  private readonly protocols: string[] | undefined;
  private websocket: WebSocket | undefined;
  private eventsCallback: IEventsCallback<string>;
  private isConnectionOpen = false;
  constructor(url: string, protocols?: string[]) {
    this.eventsCallback = {
      onReceiveData: (_data: string): null => null,
      onError: (_error: Error): null => null,
    };
    this.url = url;
    this.protocols = protocols;
  }
  private notifyConnect(): void {
    this.eventsCallback?.onConnect?.();
  }
  private notifyDisconnect(): void {
    this.eventsCallback?.onDisconnect?.();
  }
  private notifyReceiveData(data: string): void {
    this.eventsCallback.onReceiveData(data);
  }
  private notifyError(error: Error): void {
    this.eventsCallback.onError(error);
  }
  connect(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.isConnectionOpen) {
        try {
          this.websocket = new WebSocket(this.url, this.protocols);
          this.websocket.onopen = () => {
            if (this.websocket?.readyState === WebSocket.OPEN) {
              this.isConnectionOpen = true;
              this.notifyConnect();
              resolve();
            }
          };
          this.websocket.onmessage = event => {
            this.notifyReceiveData(event.data);
          };
          this.websocket.onerror = error => {
            window.console.log('🚀 ~ WebSockets ~ error:', error);
            this.notifyError(new Error(error.toString()));
          };
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error(ERROR_MESSAGES_WEBSOCKETS.CONNECTION_ALREADY_OPEN));
      }
    });
  }
  disconnect(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.isConnectionOpen) {
        try {
          this.websocket?.close();
          this.isConnectionOpen = false;
          this.notifyDisconnect();
          resolve();
        } catch (error) {
          reject(error);
        }
      } else {
        reject(new Error(ERROR_MESSAGES_WEBSOCKETS.CONNECTION_ALREADY_CLOSED));
      }
    });
  }
  send(data: string): void {
    if (this.isConnectionOpen) {
      this.websocket?.send(data);
    } else {
      this.notifyError(new Error(ERROR_MESSAGES_WEBSOCKETS.CONNECTION_NOT_OPEN));
    }
  }
  listen(eventsCallback: IEventsCallback<string>): void {
    this.eventsCallback = { ...this.eventsCallback, ...eventsCallback };
  }
}
