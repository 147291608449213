// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { PersonalNumberContextConsumerHook } from 'containers/personal/views/number/contexts/personal-number.context';
// Event handlers
import PersonalNumberStep4ChallengeHandlers from '../handlers/personal-number-step4-challenge.handlers';
// Types
import { PersonalNumberStep4ChallengeHookType } from './types/personal-number-step4-challenge.hook.type';
// Hooks
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';

const PersonalNumberStep4ChallengeHook = (): PersonalNumberStep4ChallengeHookType => {
  const [challengeError, setChallengeError] = useState<boolean>(false);

  const { newPhoneNumber, newPrefix, setChallenged, setProgressId, withEmail, setSignatureExpired, signatureExpired } =
      PersonalNumberContextConsumerHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { primaryConfigurablePhoneId, customerId } = PersonalNumberHook();

  const {
    handleManageErrorOnChangePhoneEvent,
    handleManageSuccessOnChangePhoneEvent,
    handleSetupStartedChallengeEvent,
    handleChallengeRetryButtonClick,
  } = PersonalNumberStep4ChallengeHandlers({
    setChallenged,
    setProgressId,
    setResult,
    setChallengeError,
    setSignatureExpired
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupStartedChallengeEvent();
  }, []);

  return {
    challengeError,
    customerId,
    handleChallengeRetryButtonClick,
    handleManageErrorOnChangePhoneEvent,
    handleManageSuccessOnChangePhoneEvent,
    phoneId: primaryConfigurablePhoneId,
    newPrefix,
    newPhoneNumber,
    signatureExpired,
    withEmail,
  };
};

export default PersonalNumberStep4ChallengeHook;
