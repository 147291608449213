// Event handlers
import { CardSettingsLandingFooterHookTrackingCommonHandlers } from './card-settings-landing-footer.hook.tracking.common.handlers';
// Types
import { CardSettingsLandingFooterHookTrackingHandlersType } from 'containers/card-settings/views/landing/components/footer/hooks/handlers/types/card-settings-landing-footer.hook.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const CardSettingsLandingFooterHookTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsLandingFooterHookTrackingHandlersType =>
  CardSettingsLandingFooterHookTrackingCommonHandlers(track);

export { CardSettingsLandingFooterHookTrackingHandlers };
