// Vendors
import React from 'react';
// Components
import { TitleHeaderComponent } from '@openbank/cb-ui-commons';
import AvatarComponent from 'components/avatar/avatar.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import PersonalPhotoAvatarsComponent from './components/avatars/personal-photo-avatars.component';
import PersonalPhotoNameButtonComponent from './components/name-button/personal-photo-name-button.component';
import PersonalPhotoNavigationFooterComponent from './components/navigation-footer/personal-photo-navigation-footer.component';
import PersonalPhotoSidebarComponent from './components/sidebar/personal-photo-sidebar.component';
// Contexts
import { PersonalPhotoContextConsumerHOC } from './contexts/personal-photo.context';
// Constants
import { PERSONAL_PHOTO_TITLE_PROPS } from './constants/personal-landing.constants';
// Styles
import {
  AppCardContentSectionComponentStyled,
  AppCardSectionComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import {
  PersonalPhotoContentRightViewStyled,
  PersonalPhotoContentViewStyled,
  PersonalPhotoTitleViewStyled,
  PersonalPhotoViewStyled,
} from './personal-photo.view.styled';
// Translations
import { TITLE } from './translations/personal-photo.translations';

const PersonalPhotoView = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <AppCardSectionComponentStyled>
        <AppCardContentSectionComponentStyled>
          <PersonalPhotoViewStyled>
            <PersonalPhotoTitleViewStyled>
              <TitleHeaderComponent {...PERSONAL_PHOTO_TITLE_PROPS}>
                <FormattedMessageComponent id={TITLE} />
              </TitleHeaderComponent>
            </PersonalPhotoTitleViewStyled>
            <PersonalPhotoContentViewStyled>
              <AvatarComponent />
              <PersonalPhotoContentRightViewStyled>
                <PersonalPhotoNameButtonComponent />
                <PersonalPhotoAvatarsComponent />
              </PersonalPhotoContentRightViewStyled>
            </PersonalPhotoContentViewStyled>
            <PersonalPhotoNavigationFooterComponent />
          </PersonalPhotoViewStyled>
        </AppCardContentSectionComponentStyled>
      </AppCardSectionComponentStyled>
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <PersonalPhotoSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export { PersonalPhotoView };
export default PersonalPhotoContextConsumerHOC(PersonalPhotoView);
