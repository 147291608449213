// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from '../constants/support-cancel-contract-second-step-container-header.constants';
// Translations
import translations from 'containers/support/views/cancel-contract/translations/support-cancel-contract.translations';
// Styled
import {
  SupportCancelContractSecondStepContainerHeaderStyled,
  SupportCancelContractSecondStepContainerStyled,
  CancelContractSegmentStyled,
} from './support-cancel-contract-second-step-container-header.styled';
import SupportCancelContractSecondStepChallengeHook from '../challenge/hook/support-cancel-contract-second-step-challenge.hook';
// Utils
import { getReasonToCancel } from 'containers/support/views/cancel-contract/utils/support-cancel-contract.utils';

const SupportCancelContractSecondStepContainerHeader = (): React.ReactElement => {
  const { reason } = SupportCancelContractSecondStepChallengeHook();

  return (
    <>
      <ParagraphComponent>
        <FormattedMessageComponent id={translations.HEADER_TITLE} />
      </ParagraphComponent>
      <SupportCancelContractSecondStepContainerHeaderStyled>
        <SupportCancelContractSecondStepContainerStyled>
          <CancelContractSegmentStyled>
            <ParagraphComponent>
              <FormattedMessageComponent id={translations.HEADER_TEXT_1_LABEL} />
            </ParagraphComponent>
            <ParagraphComponent {...constants.CANCEL_CONTRACT_PARAPGRAPH_1_PROPS}>
              <FormattedMessageComponent id={translations.HEADER_TEXT_BOLD_1_LABEL} />
            </ParagraphComponent>
          </CancelContractSegmentStyled>
          <CancelContractSegmentStyled>
            <ParagraphComponent>
              <FormattedMessageComponent id={translations.HEADER_TEXT_2_LABEL} />
            </ParagraphComponent>
            <ParagraphComponent {...constants.CANCEL_CONTRACT_PARAPGRAPH_2_PROPS}>
              <FormattedMessageComponent id={getReasonToCancel(reason)} />
            </ParagraphComponent>
          </CancelContractSegmentStyled>
        </SupportCancelContractSecondStepContainerStyled>
      </SupportCancelContractSecondStepContainerHeaderStyled>
    </>
  );
};

export default SupportCancelContractSecondStepContainerHeader;
