// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/security-keys-sidebar.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import {
  SecurityKeysSidebarComponentStyled,
  SecurityKeysSidebarItemComponentStyled,
} from './security-keys-sidebar.component.styled';
// Translations
import translations from './translations/security-keys-sidebar.translations';

const SecurityKeysSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.SECURITY_KEYS_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <SecurityKeysSidebarComponentStyled>
        <SecurityKeysSidebarItemComponentStyled>
          <ParagraphComponent {...constants.SECURITY_KEYS_SIDEBAR_MESSAGE_01_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_1} />
          </ParagraphComponent>
        </SecurityKeysSidebarItemComponentStyled>
        <SecurityKeysSidebarItemComponentStyled>
          <ParagraphComponent {...constants.SECURITY_KEYS_SIDEBAR_MESSAGE_02_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_2} />
          </ParagraphComponent>
        </SecurityKeysSidebarItemComponentStyled>
        <SecurityKeysSidebarItemComponentStyled>
          <ParagraphComponent {...constants.SECURITY_KEYS_SIDEBAR_MESSAGE_03_PROPS}>
            <FormattedMessageComponent id={translations.MESSAGE_3} />
          </ParagraphComponent>
        </SecurityKeysSidebarItemComponentStyled>
      </SecurityKeysSidebarComponentStyled>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default SecurityKeysSidebarComponent;
