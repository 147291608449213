// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
import { statusMessages } from './constants/financing-landing-row-details-status-message.component.constants';
// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Types
import { FinancingLandingRowDetailsStatusMessageComponentType } from './types/financing-landing-row-details-status-message.component.type';

const FinancingLandingRowDetailsStatusMessageComponent = ({
  status,
}: FinancingLandingRowDetailsStatusMessageComponentType): React.ReactElement | null => {
  const validStatus = [
    EppElementStatusEnumeration.RETURNED,
    EppElementStatusEnumeration.PARTIAL_RETURNED,
    EppElementStatusEnumeration.CANCELLED,
  ];

  if (!validStatus.includes(status as EppElementStatusEnumeration)) {
    return null;
  }

  return (
    <MessageComponent type={tagConstants.TYPES.INFORMATIVE}>
      <ParagraphComponent>
        <FormattedMessageComponent id={statusMessages[status]} />
      </ParagraphComponent>
    </MessageComponent>
  );
};

export default FinancingLandingRowDetailsStatusMessageComponent;
