// Vendors
import React from 'react';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
import { DeviceModalComponent } from '../device-modal/device-modal.component';
import { SignOutComponent } from '../sign-out/sign-out.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { DeviceInfoPlatformComponent } from './components/platform/platform.component';
// Constants
import { BLOOCK_BUTTON_PROPS, UNROLL_BUTTON_PROPS } from './constants/device-info.constants';
// Styled
import { DeviceInfoComponentButtonsStyled } from './device-info.component-styled';
// Types
import { DeviceInfoPropType } from './types/device-info.component.props.type';
import { getDeviceInfoConfig } from './configurations/device-info.configurations';
// Hooks
import { DeviceInfoHook } from './hooks/device-info.hook';
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Translations
import { UNROLL_TRUSTED_DEVICE } from './translations/device-info.translations';

const DeviceInfoComponent = ({ device }: DeviceInfoPropType): React.ReactElement => {
  const { isMobile } = DeviceTypeHook();
  const {
    open,
    modalType,
    onCloseModal,
    onAccepModal,
    openBlockToggleModal,
    toggleButtonMessage,
    openUnrollModal,
    loading,
    formatMessage,
  } = DeviceInfoHook({ device });

  return (
    <>
      {open && (
        <DeviceModalComponent
          loading={loading}
          type={modalType}
          onClose={onCloseModal}
          device={device}
          onAccept={onAccepModal}
        />
      )}
      <DeviceInfoPlatformComponent configuration={getDeviceInfoConfig({ device, formatMessage })} />
      <DeviceInfoComponentButtonsStyled>
        <ButtonComponent {...BLOOCK_BUTTON_PROPS} onClick={openBlockToggleModal}>
          <FormattedMessageComponent id={toggleButtonMessage} />
        </ButtonComponent>
        {device.trusted && (
          <ButtonComponent {...UNROLL_BUTTON_PROPS} onClick={openUnrollModal}>
            <FormattedMessageComponent id={UNROLL_TRUSTED_DEVICE} />
          </ButtonComponent>
        )}
        {device.lastSession?.active && isMobile && <SignOutComponent device={device} />}
      </DeviceInfoComponentButtonsStyled>
    </>
  );
};

export { DeviceInfoComponent };
