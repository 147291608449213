// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GET_DOCUMENT_BY_TYPE_CONFIGURATION } from './constants/transactions-table-header-link-bar-download-modal.services.constants';
// Types
import { TransactionsTableHeaderLinkBarDownloadModalServicesType } from './types/transactions-table-header-link-bar-download-modal.services.type';

const getDocumentByTypeService = async ({
  cardId,
  downloadDocumentType,
  formValuesParams,
  progressId,
}: TransactionsTableHeaderLinkBarDownloadModalServicesType): Promise<[any | null, Response]> =>
  apiUtilsCb.get({
    ...GET_DOCUMENT_BY_TYPE_CONFIGURATION,
    customHeaders: progressId ? { progressId } : {},
    pathParams: { cardId },
    queryParams: { documentType: downloadDocumentType, ...formValuesParams },
  });

export { getDocumentByTypeService };
