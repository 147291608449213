// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DeviceSessionsComponentStyled = styled.div.attrs(({ testId }: { testId: string }) => ({
  'data-testid': testId,
}))`
  display: grid;
  grid-template-columns: 1fr 1fr ${({ theme }) => theme.spaces.spacingXxxxxxxxl};
  padding: ${({ theme }) => theme.spaces.spacingS};
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundTertiaryC};

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
    padding: 0
      ${({ theme }) => `${theme.spaces.spacingS} ${theme.spaces.spacingS} ${theme.spaces.spacingS}`};
  }
`;

export const DeviceSessionsEmptyComponentStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
`;

export const DeviceSessionsContainerComponentStyled = styled.div.attrs(() => ({
  'data-testid': 'device-sessions-container',
}))``;
