// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { SecurityCheckModalSecondStepHookType } from './types/security-check-modal-second-step.hook.type';

const SecurityCheckModalSecondStepHook = (): SecurityCheckModalSecondStepHookType => {
  const { profile } = AppContextConsumerHook(),
    { telephones = [] } = profile?.contactDetails || {};

  return {
    phone: telephones[0]?.phone ?? '',
  };
};

export { SecurityCheckModalSecondStepHook };
