// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ModalComponent, LineSeparatorComponent } from '@openbank/cb-ui-commons';
import { TransactionAmountComponent } from './components/transaction-amount/transaction-amount.component';
import { ExchangeRateComponent } from './components/exchange-rate/exchange-rate.component';
import { TotalAmountComponent } from './components/total-amount/total-amount.component';
import { FeesComponent } from './components/fees/fees.component';
// Constants
import {
  MODAL_BUTTON_PROPS,
  LINE_SEPARATOR_PROPS,
} from './constants/amount-details-modal.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Styles
import {
  AmountDetailsModalDescriptionComponentStyled,
  AmountDetailsModalRowLineSeparatorComponentStyled,
} from './amount-details-modal.component.styled';
// Translations
import { BUTTON_TITLE, TITLE } from './translations/amount-details-modal.translations';
// Types
import { AmountDetailsModalComponentPropsType } from './types/amount-details-modal.type';

const AmountDetailsModalComponent = ({
  showModal,
  handleSeeDetailsElementAmountLinkClick,
  detailedAmount,
}: AmountDetailsModalComponentPropsType): React.ReactElement | null => {
  const {
    exchangeRate,
    totalAmount,
    originalCurrency,
    originalAmountExchange,
    originalAmountEuro,
    fees,
  } = detailedAmount;

  return showModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      primaryButtonConfiguration={{
        ...MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={BUTTON_TITLE} />,
        onClick: handleSeeDetailsElementAmountLinkClick,
      }}
      onHeaderClose={handleSeeDetailsElementAmountLinkClick}
      title={<FormattedMessageComponent id={TITLE} />}
    >
      <AmountDetailsModalDescriptionComponentStyled>
        <TransactionAmountComponent
          originalCurrency={originalCurrency}
          originalAmountExchange={originalAmountExchange}
        />
        {originalCurrency !== EURO_FORMAT_OPTIONS.currency && (
          <ExchangeRateComponent
            originalCurrency={originalCurrency}
            exchangeRate={exchangeRate}
            originalAmountEuro={originalAmountEuro}
          />
        )}
        {fees && <FeesComponent fees={fees} />}
        <AmountDetailsModalRowLineSeparatorComponentStyled>
          <LineSeparatorComponent {...LINE_SEPARATOR_PROPS} />
        </AmountDetailsModalRowLineSeparatorComponentStyled>
        <TotalAmountComponent totalAmount={totalAmount} />
      </AmountDetailsModalDescriptionComponentStyled>
    </ModalComponent>
  ) : null;
};

export { AmountDetailsModalComponent };
