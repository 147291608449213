// Vendors
import styled from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const ReportSubscriptionAlreadyCancelledAdditionalInformationOptionsStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

ReportSubscriptionAlreadyCancelledAdditionalInformationOptionsStyled.displayName =
  'ReportDefectiveAdditionalInformationOptions';

const ReportSubscriptionAlreadyCancelledAdditionalInformationContainerStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: column;
  gap: inherit;
  width: 100%;
`;

ReportSubscriptionAlreadyCancelledAdditionalInformationContainerStyled.displayName =
  'ReportDefectiveAdditionalInformationContainer';

const ParagraphComponentStyled = styled(ParagraphComponent).attrs(
  ({ testId }: TestIdPropsType) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)``;

export {
  ReportSubscriptionAlreadyCancelledAdditionalInformationContainerStyled,
  ReportSubscriptionAlreadyCancelledAdditionalInformationOptionsStyled,
  ParagraphComponentStyled,
};
