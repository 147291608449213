// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS,
  PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS,
} from './constants/personal-email-step3-message.constants';
// Translations
import { MESSAGE_DESCRIPTION } from './translations/personal-email-step3-message.translations';
// Utils
import { isCC } from 'utils/app.utils';

const PersonalEmailStep3MessageComponent = (): React.ReactElement | null => {
  return !isCC() ? (
    <MessageComponent {...PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS}>
      <ParagraphComponent {...PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;
};

export { PersonalEmailStep3MessageComponent };
