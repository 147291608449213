// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import { PersonalUploadDocumentationHandlers } from '../handlers/personal-upload-documentation.handlers';
// Types
import { FileDataType } from 'types/file-data.type';
import { UsePersonalUploadDocumentationHookType } from './types/personal-upload-documentation.hook.type';
import { UsePersonalUploadDocumentationHookPropsType } from './types/personal-upload-documentation-props.hook';

const usePersonalUploadDocumentationHook = ({
  onChangeFlow,
}: UsePersonalUploadDocumentationHookPropsType): UsePersonalUploadDocumentationHookType => {
  const [error, setError] = useState(false);
  const [fetching, setFetching] = useState(true);
  const [files, setFiles] = useState<FileDataType[]>([]);
  const { setResult } = useWizardFeedbackContextConsumerHook();

  const { handleGetRequestedDocuments, handleSendDocuments } = PersonalUploadDocumentationHandlers({
    onChangeFlow,
    setError,
    setFetching,
    setFiles,
    setResult,
  });

  useEffect(() => {
    handleGetRequestedDocuments();
  }, []);

  return {
    error,
    fetching,
    files,
    handleGetRequestedDocuments,
    handleSendDocuments,
    setFiles,
  };
};

export { usePersonalUploadDocumentationHook };
