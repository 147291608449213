// Vendors
import styled from 'styled-components';

const PersonalAddressSidebarComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;

const PersonalAddressSidebarMessageComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontPrimaryA};
`;

export { PersonalAddressSidebarComponentStyled, PersonalAddressSidebarMessageComponentStyled };
