// Services
import { getLastTimeUserSessionService } from '../services/dashboard-last-session.service';

const getLastTimeUserSessionResolver = async (): Promise<[string] | [null, true]> => {
  const [response] = await getLastTimeUserSessionService();

  return response?.lastLoginTime ? [response.lastLoginTime] : [null, true];
};

export { getLastTimeUserSessionResolver };
