// Vendors
import styled, { css } from 'styled-components';
import { createRGBAFromHexadecimalBackground } from '@openbank/cf-ui-framework';

export const SettingsCardControlLocationsComponentStyled = styled.div<{ fetching: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  ${({ fetching }) =>
    fetching &&
    css`
      padding: ${({ theme }) => theme.spaces.spacingXxxl} ${({ theme }) => theme.spaces.spacingL};
    `};
`;

export const SettingsCardControlLocationsContentComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  position: relative;
  padding-bottom: ${({ theme }) => theme.spaces.spacingL};
`;

export const SettingsCardControlLocationsOverlayComponentStyled = styled.article<{ show: boolean }>`
  ${({ show }) =>
    show &&
    css`
      background-color: ${({ theme }) =>
        createRGBAFromHexadecimalBackground({
          hexadecimal: theme.colors.backgroundPrimaryB,
          opacity: 0.75,
        }) as any};
      bottom: 0;
      cursor: not-allowed;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: ${({ theme }) => theme.zIndex.zIndexB};
    `}
`;
