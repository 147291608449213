// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { usePersonalNumberStep1ContextConsumerHook } from 'containers/personal/views/number/components/step1/contexts/personal-number-step1.context';
// Hooks
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';
// Event handlers
import PersonalPhoneStep1PhoneHandlers from '../handlers/personal-number-step1-phone.handlers';
import PersonalPhoneStep1PhoneTrackingHandlers from '../handlers/personal-number-step1-phone.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { PersonalNumberKeyStep1PhoneHookType } from './types/personal-number-step1-phone.hook.type';
// Utils
import { getDefaultDropDownPrefixValue } from 'containers/personal/views/number/components/step1/utils/personal-number-step1.utils';

const usePersonalNumberStep1PhoneHook = (): PersonalNumberKeyStep1PhoneHookType => {
  const { localization } = AppContextConsumerHook(),
    {
      fetching,
      prefix,
      setPrefix,
      setPhonesMatch,
      phoneNumber,
      setPhoneNumber,
      setRepeatPhoneNumber,
      setPhoneIsValid,
      phoneIsValid,
    } = usePersonalNumberStep1ContextConsumerHook(),
    { phonePrefixes: prefixes = [] } = localization,
    { canUpdatePhone, primaryConfigurablePrefix } = PersonalNumberHook(),
    { formatMessage } = useCbIntl();
  const [error, setError] = useState<boolean>(false);

  const {
    handleManagePersonalNumberStep1NumberInputErrorTracking,
    handleSavePersonalNumberStep1NumberPrefixOptionClickTracking,
    handleSavePersonalNumberStep1NumberInputClickEventTracking,
  } = AppTrackingHook(PersonalPhoneStep1PhoneTrackingHandlers);

  const defaultPrefix = getDefaultDropDownPrefixValue({
    prefixes,
    prefix: `+${primaryConfigurablePrefix}`,
    setFunction: setPrefix,
  });

  return {
    ...PersonalPhoneStep1PhoneHandlers({
      handleManagePersonalNumberStep1NumberInputErrorTracking,
      handleSavePersonalNumberStep1NumberPrefixOptionClickTracking,
      prefix,
      phoneNumber,
      setError,
      setPhoneNumber,
      setRepeatPhoneNumber,
      setPrefix,
      setPhonesMatch,
      setPhoneIsValid,
    }),
    fetching,
    formatMessage,
    error,
    handleSavePersonalNumberStep1NumberInputClickEventTracking,
    prefix,
    prefixes,
    phoneNumber,
    setPhonesMatch,
    setPrefix,
    phoneIsValid,
    primaryConfigurablePrefix,
    canUpdatePhone,
    defaultPrefix,
  };
};

export default usePersonalNumberStep1PhoneHook;
