// Vendors
import React from 'react';
// Components
import SecurityKeysSignatureKeyChangeSecurityKeyComponent from '../security-key-component/security-keys-signature-key-change-second-step-security-key.component';
import SecurityKeysSignatureKeyChangeSecondStepActionsComponent from './actions/security-keys-signature-key-change-second-step-actions.component';
// Hooks
import SecurityKeysSignatureKeyChangeSecondStepHook from './hooks/security-keys-signature-key-change-second-step.hook';
// Styles
import {
  SecurityComponentStyled,
  SecurityContentStyled,
} from './components/security-keys-signature-key-change-second-step.styled';
// Translations
import translations from '../../translations/security-keys-signature-key-change.translations';

const SecurityKeysSignatureKeyChangeSecondStep = (): React.ReactElement => {
  const {
    newSignatureKey,
    repeatNewSignatureKey,
    handleSaveNewSignatureKeyChangeInputEvent,
    handleSaveNewRepeatSignatureKeyChangeInputEvent,
    inputError,
    inputErrorRepeat,
    handleFirstPasswordPositionInputClickTracking,
    handleSecondPasswordPositionInputClickTracking,
    handleShowHidePasswordClickTracking,
  } = SecurityKeysSignatureKeyChangeSecondStepHook();

  return (
    <SecurityComponentStyled>
      <SecurityContentStyled>
        <SecurityKeysSignatureKeyChangeSecurityKeyComponent
          analytics={{ handleOnClick: () => handleShowHidePasswordClickTracking() }}
          constrainsEnabled
          value={newSignatureKey}
          title={translations.PASSWORD_COMPONENT_TITLE_SECOND_STEP}
          onChange={handleSaveNewSignatureKeyChangeInputEvent}
          onClick={handleFirstPasswordPositionInputClickTracking}
          description={translations.PASSWORD_COMPONENT_DESCRIPTION_FIRST_STEP}
          failedValidationStatus={inputError}
          errorMessage={
            inputError && inputError.length > 0 ? translations.ERRORS.INFORMATION_ABOVE : null
          }
        />
        <SecurityContentStyled />
        <SecurityKeysSignatureKeyChangeSecurityKeyComponent
          analytics={{ handleOnClick: () => handleShowHidePasswordClickTracking() }}
          value={repeatNewSignatureKey}
          title={translations.PASSWORD_COMPONENT_TITLE_SECOND_STEP_INPUT}
          onChange={handleSaveNewRepeatSignatureKeyChangeInputEvent}
          onClick={handleSecondPasswordPositionInputClickTracking}
          errorMessage={inputErrorRepeat && translations.ERRORS.DONT_MATCH}
        />
      </SecurityContentStyled>
      <SecurityKeysSignatureKeyChangeSecondStepActionsComponent />
    </SecurityComponentStyled>
  );
};

export default SecurityKeysSignatureKeyChangeSecondStep;
