// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { AccessKeyChangeStep3TrackingHandlersReturnType } from './types/security-keys-access-key-change-third step.tracking.handlers.type';

const nextButtonClickTrackingHandlerStep3 = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'lowerNavigation',
    eventLabel: 'navigationConfirmNewAccessKeyButton',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterThirdStepAccessKeyChangeTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeAccessKey/step03/confirmation',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'security',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SecurityKeysAccessKeyChangeStep3TrackingHandlers = (
  track: TrackBuilderType
): AccessKeyChangeStep3TrackingHandlersReturnType => ({
  handleNextButtonClickTrackingHandlerStep3: () => track(nextButtonClickTrackingHandlerStep3()),
  handleOnEnterThirdStepAccessKeyChangeTrackingHandler: () =>
    track(onEnterThirdStepAccessKeyChangeTrackingHandler()),
});

export default SecurityKeysAccessKeyChangeStep3TrackingHandlers;
