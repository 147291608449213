// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Translations
import { CARD_TITLE } from 'components/card-resume/translations/card-resume.translations';
// Types
import { CardDetailsProps, GetCardDetailsProps } from './types/check-cvv-pin.configs.type';

/**
 * Generates a valid Props model for `CardDetails` component
 */
export const getCardDetailProps = ({
  art,
  currentCard,
  formatMessage,
}: GetCardDetailsProps): CardDetailsProps => ({
  cardImage: art,
  cardNumber: currentCard.cmsCardId,
  cardType: currentCard.productType.productFamily,
  hideToggle: true,
  title: formatMessage({ id: CARD_TITLE}),
});
