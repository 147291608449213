const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbDetails.';

export default {
  CATEGORY: `${DOMAIN}categoryLabel`,
  CATEGORY_VALUE: 'category_',
  EDIT_CATEGORY: `${DOMAIN}editCategoryLink`,
  AUTHORIZED_CATEGORY: `${DOMAIN}authorizedStatus`,
  TO_BE_ASSIGNED: `${DOMAIN}toBeAssigned`,
};
