// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/support-landing-sidebar.constants'; // Styles

import { SidebarComponentStyled } from 'styles/app.styled';
// Translations
import translations from './translations/support-landing-sidebar.translations';

const SupportLandingSidebarComponent = (): React.ReactElement => (
  <SidebarComponentStyled>
    <SidebarComponent
      {...constants.SUPPORT_LANDING_SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <ParagraphComponent {...constants.SUPPORT_LANDING_SIDEBAR_MESSAGE_PROPS}>
        <FormattedMessageComponent id={translations.MESSAGE} />
      </ParagraphComponent>
    </SidebarComponent>
  </SidebarComponentStyled>
);

export default SupportLandingSidebarComponent;
