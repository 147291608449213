// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const SettingsCardControlTabsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `${theme.spaces.spacingL} ${theme.spaces.spacingXxxxxl}`};
`;
SettingsCardControlTabsComponentStyled.displayName = 'SettingsCardControlTabsComponentStyled';

const SettingsCardControlTabsTopComponentStyled = styled.div`
  border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;
SettingsCardControlTabsComponentStyled.displayName = 'SettingsCardControlTabsTopComponentStyled';

const SettingsCardControlTabsTopMessageComponentStyled = styled.div`
  margin: ${({ theme }) => theme.spaces.spacingM} 0;
`;
SettingsCardControlTabsTopMessageComponentStyled.displayName =
  'SettingsCardControlTabsTopMessageComponentStyled';

export {
  SettingsCardControlTabsComponentStyled,
  SettingsCardControlTabsTopComponentStyled,
  SettingsCardControlTabsTopMessageComponentStyled,
};
