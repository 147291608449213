// Vendors
import React from 'react';
// Context
import { PersonalPhotoContextConsumerHook } from 'containers/personal/views/photo/contexts/personal-photo.context';
// Styles
import { PersonalPhotoNameButtonUploadModalPreviewComponentStyled } from 'containers/personal/views/photo/components/name-button/components/upload-modal/components/preview/personal-photo-name-button-upload-modal-preview.component.styled';

const PersonalPhotoNameButtonUploadModalPreviewComponent = (): React.ReactElement | null => {
  const { photo } = PersonalPhotoContextConsumerHook();

  return <PersonalPhotoNameButtonUploadModalPreviewComponentStyled src={photo} />;
};

export default PersonalPhotoNameButtonUploadModalPreviewComponent;
