// Types
import { WizardStepFooterCancelModalBuilderCommonHandlersType } from './types/wizard-step-footer-cancel-modal-builder-common.handlers.type';
import { WizardStepFooterCancelModalBuilderReturnCommonHandlersType } from './types/wizard-step-footer-cancel-modal-builder-return-common.handlers.type';
import { WizardStepFooterCancelModalBuilderHandlersType } from './types/wizard-step-footer-cancel-modal-builder.handlers.type';

const closeWizardStepFooterCancelProcessModalAssetClickHandler = ({
  handleCloseWizardStepFooterCancelProcessModalAssetClickTracking,
  onClose,
}: Pick<
  WizardStepFooterCancelModalBuilderHandlersType,
  'handleCloseWizardStepFooterCancelProcessModalAssetClickTracking' | 'onClose'
>): void => {
  handleCloseWizardStepFooterCancelProcessModalAssetClickTracking();
  onClose();
};

const WizardStepFooterCancelModalCommonHandlers = ({
  handleCloseWizardStepFooterCancelProcessModalAssetClickTracking,
  onClose,
}: WizardStepFooterCancelModalBuilderCommonHandlersType): WizardStepFooterCancelModalBuilderReturnCommonHandlersType => ({
  handleCloseWizardStepFooterCancelProcessModalAssetClick: () =>
    closeWizardStepFooterCancelProcessModalAssetClickHandler({
      handleCloseWizardStepFooterCancelProcessModalAssetClickTracking,
      onClose,
    }),
});

export { WizardStepFooterCancelModalCommonHandlers };
