const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.step2.plans.';

const NOT_SELECTED_ERROR = `${DOMAIN}notSelectedError`;

const INSUFFICIENT_CREDIT_ERROR = {
  title: `${DOMAIN}insufficientCreditError.title`,
  description: `${DOMAIN}insufficientCreditError.description`,
};

export { NOT_SELECTED_ERROR, INSUFFICIENT_CREDIT_ERROR };
