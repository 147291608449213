// Types
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.type';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.type';
import { FinancingEarlyRepaymentSecondStepChallengeHandlersType } from './types/financing-early-repayment-second-step-challenge.handlers.type';
import { FinancingEarlyRepaymentSecondStepChallengeReturnHandlersType } from './types/financing-early-repayment-second-step-challenge-return.handlers.type';

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType) => {
  if (!challenge) setError(true);
};

const challengeSuccessHandler = ({ challenge, setChallenge }: ChallengeSuccessHandlerType) => {
  setChallenge(challenge);
};

const challengeRetryButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => setChallengeError(false);

const FinancingEarlyRepaymentSecondStepChallengeHandlers = ({
  challenge,
  setChallenge,
  setChallengeError,
  setError,
}: FinancingEarlyRepaymentSecondStepChallengeHandlersType): FinancingEarlyRepaymentSecondStepChallengeReturnHandlersType => ({
  handleChallengeError: () => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ challenge, setChallenge }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setChallengeError }),
});

export default FinancingEarlyRepaymentSecondStepChallengeHandlers;
