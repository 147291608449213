// Vendors
import { linkConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { TravelPlusNextStatusEnumeration } from 'components/travel-plus/enumerations/travel-plus-next-status.enumeration';
// Translations
import {
  VIEW_GENERAL_CONDITIONS,
  VIEW_POLICY_CERTIFICATE,
  VIEW_PRODUCT_INFORMATION,
} from '../translations/travel-plus-links.translations';

const TRAVEL_PLUS_LINK_COMMON_PROPS = {
    type: linkConstants.TYPES.ACTION_SECONDARY,
    underline: true,
  },
  TRAVEL_PLUS_LINKS_COMMONS = [
    {
      ...TRAVEL_PLUS_LINK_COMMON_PROPS,
      id: 'cfg_public_travel_product_info',
      text: VIEW_PRODUCT_INFORMATION,
      testId: 'travel-plus-link-view-product-information',
    },
    {
      ...TRAVEL_PLUS_LINK_COMMON_PROPS,
      id: 'cfg_public_travel_general_cond',
      text: VIEW_GENERAL_CONDITIONS,
      testId: 'travel-plus-link-view-general-conditions',
    },
  ],
  TRAVEL_PLUS_LINKS_ITEMS = {
    [TravelPlusNextStatusEnumeration.ENABLE]: [
      {
        ...TRAVEL_PLUS_LINK_COMMON_PROPS,
        id: 'cfg_public_travel_policy_cert',
        text: VIEW_POLICY_CERTIFICATE,
        testId: 'travel-plus-link-view-policy-certificate',
      },
      ...TRAVEL_PLUS_LINKS_COMMONS,
    ],
    [TravelPlusNextStatusEnumeration.DISABLE]: TRAVEL_PLUS_LINKS_COMMONS,
  };

export { TRAVEL_PLUS_LINKS_ITEMS, TRAVEL_PLUS_LINKS_COMMONS };
