// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { FinancingEarlyRepaymentContextConsumerHook } from 'containers/financing/views/early-repayment/contexts/financing-early-repayment.context';
import { AppContextConsumerHook } from 'contexts/app.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Handlers
import FinancingEarlyRepaymentSecondStepActionsHandlers from '../handlers/financing-early-repayment-second-step-actions.handlers';
// Types
import { FinancingEarlyRepaymentSecondStepActionsReturnHookType } from './types/financing-early-repayment-second-step-actions.hook.type';

const FinancingEarlyRepaymentSecondStepActionsHook =
  (): FinancingEarlyRepaymentSecondStepActionsReturnHookType => {
    const { eppId } = useParams();

    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard,
      { challenge, setChallenge, setChallengeError, setError } =
        FinancingEarlyRepaymentContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...FinancingEarlyRepaymentSecondStepActionsHandlers({
        challenge,
        cardId,
        eppId,
        setChallenge,
        setChallengeError,
        setError,
        setResult,
      }),
      challenge,
    };
  };

export default FinancingEarlyRepaymentSecondStepActionsHook;
