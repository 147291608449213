// Enumerations
import { PathsCommonEnum } from '@openbank/cf-ui-static-data';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const CONTAINERS_ROUTE_PROPS = {
    path: PathsCommonEnum.ANY,
  },
  LOGIN_LOGGED_ROUTE_PROPS = {
    path: RoutesEnumeration.LOGIN,
  },
  LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS = {
    replace: true,
    to: `${PathsCommonEnum.ROOT}${RoutesEnumeration.DASHBOARD}`,
  },
  NOT_FOUND_ROUTE_PROPS = {
    path: RoutesEnumeration.NOT_FOUND,
  };

export {
  CONTAINERS_ROUTE_PROPS,
  LOGIN_LOGGED_ROUTE_PROPS,
  LOGIN_LOGGED_NAVIGATE_ROUTE_PROPS,
  NOT_FOUND_ROUTE_PROPS,
};
