// Enumerations
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
// Types
import {
  RecoveryKeyStep1ActionsBuilderHandlersType,
  RecoveryKeyStep1ActionsBuilderReturnHandlersType,
} from './types/recovery-key-step1-actions.handlers.type';
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';
// Resolvers
import { initRecoveryKeyResolver } from '../resolvers/recovery-key-step1-actions.resolver';

const initRecoveryKeyProcessButtonClick = async ({
  captcha,
  email,
  phoneNumber: number,
  prefix,
  setAuthData,
  setCurrentStep,
  setFetching,
  setResult,
}: RecoveryKeyStep1ActionsBuilderHandlersType): Promise<void> => {
  const phoneNumber = `${prefix}${number}`;

  setFetching(true);

  const [response, error] = await initRecoveryKeyResolver({
    captcha,
    username: email ?? '',
    phoneNumber,
  });

  if (error) {
    setResult(false);
  } else {
    setAuthData(response as ChallengeSssApiResponseType);
    setCurrentStep(WizardStepsEnumeration.TWO);
  }

  setFetching(false);
};

const RecoveryKeyStep1ActionsHandlers = (
  props: RecoveryKeyStep1ActionsBuilderHandlersType
): RecoveryKeyStep1ActionsBuilderReturnHandlersType => ({
  handleInitRecoveryKeyProcessButtonClick: () => initRecoveryKeyProcessButtonClick(props),
});

export default RecoveryKeyStep1ActionsHandlers;
