// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { VALIDATE_OTP_SERVICE_CONFIGURATION } from './constants/challenge-download-wrapper.service.constants';
// Types
import { ChallengeDownloadWrapperChallengeDataBuilderType } from '../types/challenge-download-wrapper-validate-api-builder.type';
import { ChallengeDownloadWrapperVerifyReturnHookType } from '../types/challenge-download-wrapper-verify-return.hook.type';

const validateNewNumberOtpService = async ({
  progressId,
  otpId,
  otpValue,
}: ChallengeDownloadWrapperChallengeDataBuilderType): Promise<
  [ChallengeDownloadWrapperVerifyReturnHookType | Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    endpoint: VALIDATE_OTP_SERVICE_CONFIGURATION.verify,
    customHeaders: { progressId },
    pathParams: { challenge: 'otp' },
    params: {
      id: otpId,
      value: otpValue,
    },
  });

const regenerateNewNumberOtpService = async ({
  progressId,
  otpId,
}: ChallengeDownloadWrapperChallengeDataBuilderType): Promise<
  [ChallengeDownloadWrapperVerifyReturnHookType | Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    endpoint: VALIDATE_OTP_SERVICE_CONFIGURATION.regenerate,
    customHeaders: { progressId },
    pathParams: { challenge: 'otp' },
    params: { otpId },
  });

export { regenerateNewNumberOtpService, validateNewNumberOtpService };
