// Vendors
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

export const SectionCreditLimitIncreaseStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};

  > div:first-child {
    margin-left: -${({ theme }) => theme.spaces.spacingXxxxxl};
    width: calc(100% + ${({ theme }) => theme.spaces.spacingXxxxxl} * 2);

    ${mobileAndTabletMediaQuery} {
      width: auto;
      margin-left: -${({ theme }) => theme.spaces.spacingXxxxxl};
      margin-right: -${({ theme }) => theme.spaces.spacingM};
      padding-left: 0;
    }

    > header {
      padding-left: ${({ theme }) => theme.spaces.spacingXxxxxl};
    }
  }
`;
