const DOMAIN_EXAMPLE_MODAL = 'cobranded.services.userSettings.privateArea.uploadTips.exampleModal.';

const BE_COMPLETE_DESCRIPTION = `${DOMAIN_EXAMPLE_MODAL}asset.beComplete`;
const BUTTON = `${DOMAIN_EXAMPLE_MODAL}button`;
const DESCRIPTION = `${DOMAIN_EXAMPLE_MODAL}description`;
const NOT_REFLECTIONS_DESCRIPTION = `${DOMAIN_EXAMPLE_MODAL}asset.notReflections`;
const TITLE = `${DOMAIN_EXAMPLE_MODAL}title`;
const VALID_AND_UP_TO_DATE_DESCRIPTION = `${DOMAIN_EXAMPLE_MODAL}asset.beValidAndUpToDate`;

export {
  BE_COMPLETE_DESCRIPTION,
  BUTTON,
  DESCRIPTION,
  NOT_REFLECTIONS_DESCRIPTION,
  TITLE,
  VALID_AND_UP_TO_DATE_DESCRIPTION,
};
