// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { CHANGE_PIN_SERVICE_CONFIGURATION } from './constants/card-settings-change-pin.service.constants';
// Types
import { CardSettingsChangePinApiType } from '../types/card-settings-change-pin-api.type';

const updateCardPinService = async ({
  cardId,
  pin,
  progressId,
}: CardSettingsChangePinApiType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.put({
    ...CHANGE_PIN_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    pathParams: { cardId },
    params: { pin },
  });

export { updateCardPinService };
