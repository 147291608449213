// Vendors
import React from 'react';
// Components
import { AssetComponent, TagComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
import {
  DEVICE_ICON_PROPS,
  DEVICE_ICON_CURRENT_COLOR,
  DEVICE_ICON_ERROR_COLOR
} from '../constants/device-header-info.constants';
// Enums
import { DeviceHeaderPlatformTypeEnum } from 'containers/security-keys/views/devices/components/device-header/enumerations/device-header.enumerations';
// Types
import { GetAssetConfigurationType } from './types/device-header-info.configurations.asset.type';
import { GetDeviceInfoTagConfigurationType } from './types/device-header-info.configurations.tag.type';
// Translations
import { CONNECTED , BLOCKED } from '../translations/header-info.translations';

export const getDeviceInfoTagConfiguration = ({ isMobile, device }: GetDeviceInfoTagConfigurationType): React.ReactElement => {
  if (isMobile && device.lastSession?.active) {
    return (<TagComponent type={tagConstants.TYPES.SUCCESS }>
      <FormattedMessageComponent uppercase id={CONNECTED} />
    </TagComponent>);
  } else if (isMobile && device.blocked) {
    return (<TagComponent type={tagConstants.TYPES.WARNING }>
      <FormattedMessageComponent uppercase id={BLOCKED} />
    </TagComponent>);
  }

  return <></>;
};

export const getAssetConfiguration = ({ device }: GetAssetConfigurationType): React.ReactElement => {
  return <AssetComponent
    {...{
      ...DEVICE_ICON_PROPS,
      ...(device.lastSession?.active ? DEVICE_ICON_CURRENT_COLOR : {}),
      ...(device.blocked ? DEVICE_ICON_ERROR_COLOR : {})
    }}
    id={DeviceHeaderPlatformTypeEnum[(device.webDeviceInfo?.deviceType || device.mobileDeviceInfo?.platform || 'computer')]}
  />;
};