// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { TagComponent } from '@openbank/cb-ui-commons';
// Constants
import { TRANSACTION_TABLE_ROW_TAG_PROPS } from './constants/transaction-table-row-tag.constants';
import typeConstants from './constants/transaction-table-row-tag-types.constants';
// Styles
import { TransactionTableRowTagComponentStyled } from './transaction-table-row-tag.component.styled';
// Translations
import translations from './translations/transaction-table-row-tag.translations';
// Types
import { TransactionTableRowTagComponentType } from './types/transaction-table-row-tag.component.type';
// Utilities
import { showEppCodeTagComponent } from './utils/transaction-table-row-tag.utils';

const TransactionTableRowTagComponent = ({
  eppStatusCode,
}: TransactionTableRowTagComponentType): React.ReactElement | React.ReactNode | any =>
  showEppCodeTagComponent(eppStatusCode) ? (
    <TransactionTableRowTagComponentStyled>
      <TagComponent {...TRANSACTION_TABLE_ROW_TAG_PROPS} type={typeConstants[eppStatusCode]}>
        <FormattedMessageComponent id={translations[eppStatusCode]} />
      </TagComponent>
    </TransactionTableRowTagComponentStyled>
  ) : (
    <div />
  );

export default TransactionTableRowTagComponent;
