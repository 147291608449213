// Types
import {
  FinancingLandingRowDetailsOrdersHandlerType,
  FinancingLandingRowDetailsOrdersHandlerTypeReturn,
} from './types/financing-landing-row-detail-order.handler.type';

const onHandleModal = ({
  setOpen,
  open,
  handleViewDetailClickTracking,
}: FinancingLandingRowDetailsOrdersHandlerType): void => {
  setOpen(!open);
  handleViewDetailClickTracking(!open);
};

const FinancingLandingRowDetailsOrdersHandler = ({
  setOpen,
  open,
  handleViewDetailClickTracking,
}: FinancingLandingRowDetailsOrdersHandlerType): FinancingLandingRowDetailsOrdersHandlerTypeReturn => ({
  handleModal: () => onHandleModal({ setOpen, open, handleViewDetailClickTracking }),
});

export { FinancingLandingRowDetailsOrdersHandler };
