// Types
import { FormatPhoneNumberUtilsTypes } from './types/personal-number.utils.types';
// Utilities
import { isValidPhoneNumber } from 'utils/phone.utils';
// Constants
import {
  MOCKED_RESULT,
  PREFIX_PLUS,
  REGEX_CLEANER,
  FORMATER_CLEANER_LAYOUT,
  FORMATER_CLEANER_TEMPLATE,
} from './constants/personal-number.utils.constants';

const formatPhoneNumber = ({ newPrefix, phoneNumber }: FormatPhoneNumberUtilsTypes): string =>
  isValidPhoneNumber({ phoneNumber, prefix: newPrefix })
    ? `${PREFIX_PLUS}${newPrefix} ${
        phoneNumber && phoneNumber.replace(FORMATER_CLEANER_LAYOUT, FORMATER_CLEANER_TEMPLATE)
      }`
    : MOCKED_RESULT;

const cleanPrefix = (prefix: string): number | null => {
  const [, resultado] = prefix.split(REGEX_CLEANER as RegExp);
  return resultado ? Number(resultado) : null;
};

export { cleanPrefix, formatPhoneNumber };
