// Types
import { FinancingLandingRowDetailsHandlerType } from './types/financing-landing-row-details-amortization-table.component.handlers.type';

const handleCloseModal = (setModalOpen: FinancingLandingRowDetailsHandlerType): any => {
  setModalOpen(false);
};

const handleOpenModal = (setModalOpen: FinancingLandingRowDetailsHandlerType): any => {
  setModalOpen(true);
};
export const FinancingLandingRowDetailsHandler = {
  handleCloseModal,
  handleOpenModal,
};
