export default {
  VIEW: {
    pageName: '/creditLimitIncrease/step05/assesmentOfYourRequest',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'cards',
    interactionType: 'funnel',
    serviceType: 'productSettings',
    processType: 'creditLimitIncrease',
    processStep: 'step05',
    processDetail: 'assesmentOfYourRequest',
  },
  LINK: {
    GO_TO_FINANCING: {
      action: 'click',
      format: 'link',
      component: 'lowerNavigation',
      element: 'goToFinancing',
      pathname: 'step5',
      eventCategory: 'step5 credit-limit-increase',
      eventAction: 'lowerNavigation',
      eventLabel: 'goToFinancingLink',
    },
    GO_TO_SIGN_CONTRACT: {
      action: 'click',
      format: 'button',
      component: 'lowerNavigation',
      element: 'goToSignContract',
      pathname: 'step5',
      eventCategory: 'step5 credit-limit-increase',
      eventAction: 'lowerNavigation',
      eventLabel: 'goToSignContractButton',
    },
    REJECT_OFFER: {
      action: 'click',
      format: 'button',
      component: 'lowerNavigation',
      element: 'rejectOffer',
      pathname: 'step5',
      eventCategory: 'step5 credit-limit-increase',
      eventAction: 'lowerNavigation',
      eventLabel: 'rejectOfferButton',
    },
    RESTART_THE_PROCESS: {
      action: 'continue',
      format: 'button',
      component: 'lowerNavigation',
      element: 'restartTheProcess',
      pathname: 'step5',
      eventCategory: 'step5 credit-limit-increase',
      eventAction: 'lowerNavigation',
      eventLabel: 'navigationRestartTheProcessButton',
    },
  },
} as const;
