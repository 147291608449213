const DOMAIN_ACCESS_KEY_CC =
  'cobrandedCC.services.userSettings.privateArea.securityAndKeys.changeAccessKey.';

export default {
  CHANGE_ACCESS_KEY_CC_TITLE: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCTitle`,
  CHANGE_ACCESS_KEY_CC_INITIAL_TEXT: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCInitialText`,
  CHANGE_ACCESS_KEY_CC_LAST_TEXT: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCLastText`,
  CHANGE_ACCESS_KEY_CC_FIRST_TEXT: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCFirstText`,
  CHANGE_ACCESS_KEY_CC_SECOND_TEXT: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCSecondText`,
  CHANGE_ACCESS_KEY_CC_FIRST_CONSTRAINTS: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCFirstConstraints`,
  CHANGE_ACCESS_KEY_CC_SECOND_CONSTRAINTS: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCSecondConstraints`,
  CHANGE_ACCESS_KEY_CC_THIRD_CONSTRAINTS: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCThirdConstraints`,
  CHANGE_ACCESS_KEY_CC_FOURTH_CONSTRAINTS: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCFourthConstraints`,
  CHANGE_ACCESS_KEY_CC_THIRD_TEXT: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCThirdText`,
  CHANGE_ACCESS_KEY_CC_FOURTH_TEXT: `${DOMAIN_ACCESS_KEY_CC}changeAccessKeyCCFourthText`,
};
