// Vendors
import styled, { css } from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const OperativeLayoutLeftContentComponentStyled = styled.div<{ $fetching?: boolean; $error: any }>`
  ${({ $fetching, $error }) =>
    ($fetching === true || $error?.description) &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
      padding: ${({ theme }) => theme.spaces.spacingXxxl};

      ${mobileMediaQuery} {
        padding: ${({ theme }) => theme.spaces.spacingL};
      }
    `}
`;
OperativeLayoutLeftContentComponentStyled.displayName = 'OperativeLayoutLeftContentComponentStyled';

export { OperativeLayoutLeftContentComponentStyled };
