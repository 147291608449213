// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { BLOCK_CARD_ENDPOINT } from './constants/block-card.services.constants';
// Types
import { CardSettingsBlockCardServicesType } from './types/block-card.services.type';
import { ChallengeType } from 'types/challenge.type';

const blockCardService = async ({
  cardId,
  params,
  progressId,
}: CardSettingsBlockCardServicesType): Promise<[ChallengeType | null, Response]> =>
  apiUtilsCb.post({
    customHeaders: progressId ? { progressId } : {},
    endpoint: BLOCK_CARD_ENDPOINT,
    params,
    pathParams: { cardId },
  });

export { blockCardService };
