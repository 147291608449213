// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  SidebarComponent,
} from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/card-settings-change-pin-sidebar.constants';
// Styles
import {
  CardSettingsChangePinSidebarComponentStyled,
  CardSettingsChangePinSidebarItemComponentStyled,
} from './card-settings-change-pin-sidebar.component.styled';
// Translations
import translations from './translations/card-settings-change-pin-sidebar.translations';
// Types
import { CardSettingsChangePinSidebarItemComponentType } from './types/card-settings-change-pin-sidebar-item.component.type';

const CardSettingsChangePinSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...constants.CARD_SETTINGS_CHANGE_PIN_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={translations.TITLE} />}
  >
    <CardSettingsChangePinSidebarComponentStyled>
      {constants.CARD_SETTINGS_CHANGE_PIN_EXPANDABLE_ITEMS.map(
        (
          { label, description, testId, testing }: CardSettingsChangePinSidebarItemComponentType,
          key: number
        ) => (
          <ExpandableRowComponent
            {...{ testing }}
            key={key}
            title={<FormattedMessageComponent id={label} />}
          >
            <CardSettingsChangePinSidebarItemComponentStyled>
              <ParagraphComponent
                {...{ ...constants.CARD_SETTINGS_CHANGE_PIN_SIDEBAR_ITEM_PROPS, testId }}
              >
                <FormattedMessageComponent id={description} />
              </ParagraphComponent>
            </CardSettingsChangePinSidebarItemComponentStyled>
          </ExpandableRowComponent>
        )
      )}
    </CardSettingsChangePinSidebarComponentStyled>
  </SidebarComponent>
);

export default CardSettingsChangePinSidebarComponent;
