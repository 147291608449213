// Types
import { SignatureKeyChangeStep1HandlersPropsType } from './types/signature-key-change-step1-handlers-props.type';
import { SignatureKeyChangeStep1HandlersReturnType } from './types/signature-key-change-step1-handlers-return.type';
import { OpenRequestSignatureKeyModalEventHandlerEventHandlerPropsType } from './types/signature-key-change-step1-recovery-signature-key-props.type';
import { SaveNewModifySignatureKeyChangeInputEventHandlerPropsType } from './types/signature-key-change-step1-save-signature-key-props.type';

const saveNewModifySignatureKeyChangeInputEventHandler = ({
  signatureKey,
  setSignatureKey,
}: SaveNewModifySignatureKeyChangeInputEventHandlerPropsType): void => {
  setSignatureKey(signatureKey);
};

const openRequestSignatureKeyModalEventHandler = ({
  setOpenModal,
  handleRecoveryKeyInputClickTrackingHandler,
}: OpenRequestSignatureKeyModalEventHandlerEventHandlerPropsType): void => {
  setOpenModal(true);
  handleRecoveryKeyInputClickTrackingHandler();
};

const SecurityKeysSignatureKeyChangeFirstStepHandlers = ({
  setOpenModal,
  setSignatureKey,
  handleRecoveryKeyInputClickTrackingHandler,
}: SignatureKeyChangeStep1HandlersPropsType): SignatureKeyChangeStep1HandlersReturnType => ({
  handleSaveNewModifySignatureKeyChangeInputEvent: (signatureKey: string) =>
    saveNewModifySignatureKeyChangeInputEventHandler({ signatureKey, setSignatureKey }),
  handleOpenRequestSignatureKeyModalEvent: () =>
    openRequestSignatureKeyModalEventHandler({
      setOpenModal,
      handleRecoveryKeyInputClickTrackingHandler,
    }),
});

export default SecurityKeysSignatureKeyChangeFirstStepHandlers;
