// Vendors
import React from 'react';
// Components
import AmountComponent from './components/amount/amount.component';
import CurrentAccountBoxComponent from './components/current-account-box/current-account-box.component';
import FinancingFastCashFirstStepActionsComponent from './components/actions/financing-fast-cash-first-step-actions.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/financing-fast-cash-first-step.constants';
// Styles
import { FinancingFastCashFirstStepContainerStyled } from './financing-fast-cash-first-step.component.styled';
// Translations
import {
  ACCOUNT_TITLE,
  SUBTITLE,
} from './translations/financing-fast-cash-first-step.translations';

const FinancingFastCashFirstStepComponent = (): React.ReactElement => {
  return (
    <FinancingFastCashFirstStepContainerStyled {...constants.PROPS}>
      <ParagraphComponent {...constants.TITLE_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={SUBTITLE} />
      </ParagraphComponent>
      <ParagraphComponent {...constants.FIRST_BLOCK_TITLE_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={ACCOUNT_TITLE} />
      </ParagraphComponent>
      <CurrentAccountBoxComponent />
      <AmountComponent />
      <FinancingFastCashFirstStepActionsComponent />
    </FinancingFastCashFirstStepContainerStyled>
  );
};

export default FinancingFastCashFirstStepComponent;
