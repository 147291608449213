// Services
import { getLastUserOtpMessagesService } from 'containers/otp/services/otp.service';
// Types
import { OtpApiResponseType } from '../types/otp-api-response.type';
// Utilities
import { hasSmsOrEmailMessages } from './utils/otp.resolver.utils';

const getLastUserOtpMessagesResolver = async (): Promise<
  [OtpApiResponseType | Record<string, any>] | [null, true]
> => {
  const [response] = await getLastUserOtpMessagesService();

  return hasSmsOrEmailMessages(response) ? [response] : [null, true];
};

export { getLastUserOtpMessagesResolver };
