// Resolvers
import { switchOnCardResolver } from '../resolvers/card-status-button-switch-on-modal.resolver';
// Types
import { CardStatusSwitchOnModalBuilderReturnHandlersType } from './types/card-status-switch-on-modal-builder-return.handlers.type';
import { CardStatusSwitchOnModalBuilderHandlersType } from './types/card-status-switch-on-modal-builder.handlers.type';
// Translations
import translations from './translations/card-status-button-switch-on-modal.handlers.translations';
// Utilities
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const cancelSwitchOnCardLinkClickHandler = ({
  handleCancelSwitchOnCardLinkClickTracking,
  onClose,
}: Pick<
  CardStatusSwitchOnModalBuilderHandlersType,
  'handleCancelSwitchOnCardLinkClickTracking' | 'onClose'
>): any => {
  handleCancelSwitchOnCardLinkClickTracking();
  onClose();
};

const closeSwitchOnCardModalAssetClickHandler = ({
  handleCloseSwitchOnCardModalAssetClickTracking,
  setFetching,
  onClose,
}: Pick<
  CardStatusSwitchOnModalBuilderHandlersType,
  'handleCloseSwitchOnCardModalAssetClickTracking' | 'setFetching' | 'onClose'
>): any => {
  handleCloseSwitchOnCardModalAssetClickTracking();
  onClose(() => {
    setFetching('idle');
  });
};

const confirmSwitchOnCardButtonClickHandler = async ({
  cardId,
  handleConfirmSwitchOnCardButtonClickTracking,
  onClose,
  setCard,
  setFetching,
  setToastConfiguration,
}: Omit<
  CardStatusSwitchOnModalBuilderHandlersType,
  'handleCancelSwitchOnCardLinkClickTracking' | 'handleCloseSwitchOnCardModalAssetClickTracking'
>): Promise<void> => {
  handleConfirmSwitchOnCardButtonClickTracking();
  setFetching('loading');

  const [, error] = await switchOnCardResolver(cardId);

  setFetching(error ? 'error' : 'success');
  onClose(() => {
    setFetching('idle');
  });
  setCard(true);
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations }) })
  );
};

const CardStatusSwitchOnModalHandlers = ({
  cardId,
  handleCancelSwitchOnCardLinkClickTracking,
  handleCloseSwitchOnCardModalAssetClickTracking,
  handleConfirmSwitchOnCardButtonClickTracking,
  onClose,
  setCard,
  setFetching,
  setToastConfiguration,
}: CardStatusSwitchOnModalBuilderHandlersType): CardStatusSwitchOnModalBuilderReturnHandlersType => ({
  handleCancelSwitchOnCardLinkClick: () =>
    cancelSwitchOnCardLinkClickHandler({ handleCancelSwitchOnCardLinkClickTracking, onClose }),
  handleCloseSwitchOnCardModalAssetClick: () =>
    closeSwitchOnCardModalAssetClickHandler({
      handleCloseSwitchOnCardModalAssetClickTracking,
      setFetching,
      onClose,
    }),
  handleConfirmSwitchOnCardButtonClick: () =>
    confirmSwitchOnCardButtonClickHandler({
      cardId,
      handleConfirmSwitchOnCardButtonClickTracking,
      onClose,
      setCard,
      setFetching,
      setToastConfiguration,
    }),
});

export default CardStatusSwitchOnModalHandlers;
