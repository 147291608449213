// Vendors
import styled from 'styled-components';

export const RequestPortabilityStep2ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const RequestPortabilityStep2ListItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;

export const RequestPortabilityStep2ListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;