// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/financing-fast-cash.context.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { FastCashCommissionType } from '../types/fast-cash-commission.type';
import { FastCashLimitsType } from '../types/fast-cash-limits.type';
import { FinancingFastCashContextType } from './types/financing-fast-cash.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const FinancingFastCashContext = createContext<FinancingFastCashContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const FinancingFastCashContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<FinancingFastCashContextType>> => {
  const [amountValue, setAmountValue] = useState<number | null>(null);
  const [challenge, setChallenge] = useState<ChallengeType | null>(null);
  const [disableNextBtn, setDisableNextBtn] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [fastCashCommission, setFastCashCommission] = useState<FastCashCommissionType | null>(null);
  const [fastCashLimits, setFastCashLimits] = useState<FastCashLimitsType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <FinancingFastCashContext.Provider
      value={{
        amountValue,
        challenge,
        disableNextBtn,
        error,
        fastCashCommission,
        fastCashLimits,
        loading,
        setAmountValue,
        setChallenge,
        setDisableNextBtn,
        setError,
        setFastCashCommission,
        setFastCashLimits,
        setLoading,
      }}
    >
      {children as React.ReactNode}
    </FinancingFastCashContext.Provider>
  );
};

export const FinancingFastCashContextConsumerHook = (): FinancingFastCashContextType =>
  useContext(FinancingFastCashContext);

/* eslint-disable */
export const FinancingFastCashContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<FinancingFastCashContextType>> =>
    (
      <FinancingFastCashContextProvider>
        <Component {...props} />
      </FinancingFastCashContextProvider>
    );
