// Constants
import { fraudToClaimMap } from '../constants/transaction-report-fraudolent-map-to-claim.constants';
// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
import { NotRecognizeChargeMotiveFraudEnum } from 'containers/transactions/views/report/enumerations/not-recognize-charge-motive-fraud.enumeration';
// Translations
import {
  CARD_TO_BLOCK,
  SHIPPING_ADDRESS,
} from 'containers/transactions/views/report/components/confirmation/translations/transactions-report-confirmation.translations';
// Types
import { AddressType } from 'types/address.type';
import { DetailsType } from 'types/details.type';
import { FormatMessageType } from 'types/format-message.type';
import { GetAdditionalDetailsPropsType } from './types/transactions-report-fraudulent-movement-confirmation-utils.type';

const getClaimEnum = (fraudEnum: NotRecognizeChargeMotiveFraudEnum): ClaimReasonEnum => {
  return fraudToClaimMap[fraudEnum];
};

const isStatusNotBlockedOrCanceled = (status: string): boolean => {
  return (
    status !== CardStatusTypesEnumeration.BLOCKED && status !== CardStatusTypesEnumeration.CANCELLED
  );
};

const formatAddress = ({
  address,
  formatMessage,
}: {
  address: AddressType;
  formatMessage: FormatMessageType;
}): string =>
  `${address.street} ${address.number}, ${address.postalCode}, ${address.city}, ${formatMessage({
    id: `country_${address.countryCode}`,
  })}`;

const getAdditionalDetails = ({
  status,
  cardType,
  cardNumber,
  address,
  formatMessage,
}: GetAdditionalDetailsPropsType): DetailsType => {
  if (isStatusNotBlockedOrCanceled(status)) {
    return [
      {
        label: SHIPPING_ADDRESS,
        value: formatAddress({
          address,
          formatMessage,
        }),
      },
      {
        label: CARD_TO_BLOCK,
        value: `${cardType} ...${cardNumber}`,
      },
    ];
  }
  return [];
};

export { getAdditionalDetails, getClaimEnum, isStatusNotBlockedOrCanceled };
