// Constants
import { HAS_TRANSACTIONS_CHALLENGE_SESSION } from './constants/transactions-table.utils.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { TransactionsTableMetadataComponentType } from 'types/card-transactions-metadata.type';

const isNotDashboardRoute = (): boolean =>
  window?.location?.pathname !== `/${RoutesEnumeration.DASHBOARD}`;

const isValidMetadataContract = (
  metadata: TransactionsTableMetadataComponentType | Record<string, any> = {}
): boolean =>
  Boolean('hasPendingRecords' in metadata || metadata?.nextCursor || metadata?.previousCursor);

const hasTransactionsChallengeSuccess = (): boolean =>
  Boolean(sessionStorage.getItem(HAS_TRANSACTIONS_CHALLENGE_SESSION));

const setHasTransactionsChallengeSuccess = (): void => {
  sessionStorage.setItem(HAS_TRANSACTIONS_CHALLENGE_SESSION, 'true');
};

const removeHasTransactionsChallengeSuccess = (): void => {
  sessionStorage.removeItem(HAS_TRANSACTIONS_CHALLENGE_SESSION);
};

export {
  isNotDashboardRoute,
  isValidMetadataContract,
  hasTransactionsChallengeSuccess,
  setHasTransactionsChallengeSuccess,
  removeHasTransactionsChallengeSuccess,
};
