// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { FinancingInsurancesLandingCardComponent } from './components/card/financing-insurances-landing-card.component';
import { FinancingInsurancesLandingNoInsurancesComponent } from './components/no-insurances/financing-insurances-landing-no-insurances.component';
// Constants
import { CPI_PROPS, LPI_PROPS } from './constants/financing-ppi-landing.constants';
// Hooks
import { useFinancingPPILandingHook } from './hooks/financing-ppi-landing.hook';
// Styles
import { FinancingInsurancesLandingComponentStyled } from './financing-insurances-landing.component.styled';
// Utils
import { hasAvailableInsurances } from './utils/financing-ppi-landing.utils';

const FinancingPPILandingComponent = (): React.ReactElement | null => {
  const { insurancesInfo, tnc_cpi, tnc_lpi, ...hookProps } = useFinancingPPILandingHook();

  if (!insurancesInfo || !hasAvailableInsurances(insurancesInfo)) {
    return <FinancingInsurancesLandingNoInsurancesComponent />;
  }

  const { CPI, LPI } = insurancesInfo.insurances;

  return (
    <FinancingInsurancesLandingComponentStyled>
      <FinancingInsurancesLandingCardComponent
        {...{ ...LPI_PROPS, ...hookProps }}
        insuranceInfo={LPI}
        tnc={tnc_lpi}
      />
      <FinancingInsurancesLandingCardComponent
        {...{ ...CPI_PROPS, ...hookProps }}
        descriptionValues={{
          ppiPercentage: <FormattedNumber value={Number(CPI.ppiPercentage)} />,
          ppiPercentagePromile: <FormattedNumber value={Number(CPI.ppiPercentagePromile)} />,
        }}
        insuranceInfo={CPI}
        tnc={tnc_cpi}
      />
    </FinancingInsurancesLandingComponentStyled>
  );
};

export { FinancingPPILandingComponent };
