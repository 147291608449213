// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
// Enums
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { SepaModalHookType } from './types/sepa-modal.hook.type';

const SepaModalHook = (): SepaModalHookType => {
  const { formatMessage } = useCbIntl();
  const { sepaMandate } = ChangeIbanContextConsumerHook();
  const { profile, currentCard } = AppContextConsumerHook(),
    { userName = CharactersEnum.NO_VALUE } = profile?.userDetails || {},
    { creditDetails = { nextCycleDate: CharactersEnum.NO_VALUE } } = currentCard || {},
    address = profile?.addressDetails?.[0] || {};

  return {
    address,
    userName,
    formatMessage,
    nextCycleDate: creditDetails?.nextCycleDate,
    sepaMandate,
  };
};

export default SepaModalHook;
