// Vendors
import { assetConstants } from '@openbank/cb-ui-commons';

const PERSONAL_NO_DOCUEMNTS_PROPS = {
  assetConfiguration: {
    id: 'Empty_No_Result',
    sizes: {
      height: 120,
      width: 120,
    },
    testId: 'no-documents-illustration',
    type: assetConstants.TYPES.ILLUSTRATION,
  },
};

const SEND_BUTTON_TEST_ID = 'go-to-personal-landing-button';

export { PERSONAL_NO_DOCUEMNTS_PROPS, SEND_BUTTON_TEST_ID };
