// Vendors
import React from 'react';
// Components
import SidebarDropdownsComponent from 'components/sidebar-dropdowns/sidebar-dropdowns.component';
// Constants
import constants from './constants/settings-card-control-sidebar.constants';
// Styles
import { LayoutGridContentRightComponentStyled, SidebarComponentStyled } from 'styles/app.styled';

const SettingsCardControlSidebarComponent = (): React.ReactElement => (
  <LayoutGridContentRightComponentStyled>
    <SidebarComponentStyled>
      <SidebarDropdownsComponent {...constants.SETTINGS_CARD_CONTROL_SIDEBAR_PROPS} />
    </SidebarComponentStyled>
  </LayoutGridContentRightComponentStyled>
);

export default SettingsCardControlSidebarComponent;
