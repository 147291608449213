// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentHookType } from './types/card-settings-contract-current.hook.type';

const useCardSettingsContractCurrentHook = (): CardSettingsContractCurrentHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { isAddon } = currentCard;

  return {
    isAddon,
  };
};

export default useCardSettingsContractCurrentHook;
