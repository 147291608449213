// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { usePaymentUploadDocumentsActionsHook } from './hooks/transactions-report-payment-upload-documents-actions.hook';
// Types
import { TransactionsReportPaymentDocumentsActionsComponentType } from './types/transactions-report-payment-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from './utils/transactions-report-payment-documents-actions.utils';

export const TransactionsReportPaymentDocumentsActionsComponent = ({
  files,
  setFiles,
}: TransactionsReportPaymentDocumentsActionsComponentType): React.ReactElement => {
  const { handleGoBack } = usePaymentUploadDocumentsActionsHook(setFiles);
  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      hasDefaultLeftButton
      leftButtonConfiguration={{
        onClick: () => handleGoBack(),
      }}
      rightButtonConfiguration={{ disabled: !hasRequiredDocs(files) }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};
