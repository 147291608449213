// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsTogglesBuilderReturnTrackingHandlersType } from './types/card-settings-toggles-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const toggleActivityCardToggleChangeTrackingHandler = (
  active: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'turnCard:' + (active ? 'off' : 'on'),
  },
  type: TrackingEventsEnum.LINK,
});

const toggleTravelPlusToggleChangeTrackingHandler = (
  visible: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: visible ? 'open' : 'close',
    eventLabel: 'travelPlusModal',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsTogglesTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsTogglesBuilderReturnTrackingHandlersType => ({
  handleToggleActivityCardToggleChangeTracking: (active: boolean) =>
    track(toggleActivityCardToggleChangeTrackingHandler(active)),
  handleToggleTravelPlusToggleChangeTracking: (visible: boolean) =>
    track(toggleTravelPlusToggleChangeTrackingHandler(visible)),
});

export default CardSettingsTogglesTrackingHandlers;
