// Vendors
import React from 'react';
// Components
import { FinancingPPIContractingStep2ConsentComponent } from '../consent/financing-ppi-contracting-step2-consent.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_TNC_PROPS } from './constants/financing-ppi-contracting-step2-tnc.constants';
// Hooks
import { useFinancingPPIContractingStep2TNCHook } from './hooks/financing-ppi-contracting-step2-tnc.hook';

const FinancingPPIContractingStep2TNCComponent = (): React.ReactElement => (
  <FinancingPPIContractingStep2ConsentComponent
    {...FINANCING_PPI_CONTRACTING_STEP_2_TNC_PROPS}
    {...useFinancingPPIContractingStep2TNCHook()}
  />
);

export { FinancingPPIContractingStep2TNCComponent };
