// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ProfilePersonalNameComponentStyled = styled.section`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingL};
  }
`;

export const ProfilePersonalNameLeftComponentStyled = styled.div`
  flex: 0 0 auto;

  ${mobileMediaQuery} {
    align-items: center;
    display: flex;
    flex: 100%;
    justify-content: center;
    width: 100%;
  }
`;

export const ProfilePersonalNameRightComponentStyled = styled.aside`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    flex: 100%;
    gap: ${({ theme }) => theme.spaces.spacingL};
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
    width: 100%;
  }
`;

export const ProfilePersonalNameHeaderComponentStyled = styled.header`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;

  span {
    display: inline-flex;
  }

  ${mobileMediaQuery} {
    justify-content: center;
  }
`;

export const ProfilePersonalNameEditionComponentStyled = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXl};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;
