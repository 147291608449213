const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step3.';

export default {
  TITLE: `${DOMAIN}title`,
  SUBTITLE: `${DOMAIN}subtitle`,
  SMALL_SUBTITLE: `${DOMAIN}smallSubtitle`,
  HOW_DOES_IT_WORK: `${DOMAIN}howDoesItWork`,
  TITLE_HOW_DOES_IT_WORK_1: `${DOMAIN}titleHowDoesItWork1`,
  DESCRIPTION_HOW_DOES_IT_WORK_1: `${DOMAIN}descriptionHowDoesItWork1`,
  TITLE_HOW_DOES_IT_WORK_2: `${DOMAIN}titleHowDoesItWork2`,
  DESCRIPTION_HOW_DOES_IT_WORK_2: `${DOMAIN}descriptionHowDoesItWork2`,
  TITLE_HOW_DOES_IT_WORK_3: `${DOMAIN}titleHowDoesItWork3`,
  DESCRIPTION_HOW_DOES_IT_WORK_3: `${DOMAIN}descriptionHowDoesItWork3`,
  HOW_DOES_IT_WORK_INFORMATION: `${DOMAIN}howDoesItWorkInformation`,
  HOW_CONNECT_BANKS: `${DOMAIN}howConnectBanks`,
  HOW_CONNECT_BANKS_1: `${DOMAIN}howConnectBanks1`,
  HOW_CONNECT_BANKS_2: `${DOMAIN}howConnectBanks2`,
  HOW_CONNECT_BANKS_3: `${DOMAIN}howConnectBanks3`,
  HOW_CONNECT_BANKS_4: `${DOMAIN}howConnectBanks4`,
  CHECKBOX_TERMS: `${DOMAIN}terms`,
  UPLOAD_DOCUMENTATION_DESCRIPTION: `${DOMAIN}uploadDocumentationDescription`,
  UPLOAD_DOCUMENTATION: `${DOMAIN}uploadDocumentation`,
  PRIVACY_POLICY_TEXT: `${DOMAIN}privacyPolicyText`,
};
