// Constants
import { PASSWORD_MAX_LENGTH } from './constants/login-email.utils.constants';
// Enumeration
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
import { DocumentEventsEnumeration } from '@openbank/cf-ui-static-data';
import { KeyboardKeyEnumeration } from 'enumerations/keyboard.enumeration';
// Types
import { LoginGapsUtilsType, SetupKeyboardClickForLoginType } from './types/login-gaps.utils.type';
// Utilities
import { isValidCaptcha } from 'components/captcha/utils/captcha.utils';

const isValidPasswordPositions = (passwordPositions: string): boolean =>
  passwordPositions?.length === PASSWORD_MAX_LENGTH;

const isContinueButtonDisabled = ({
  captcha,
  fetching,
  password,
  error,
}: LoginGapsUtilsType): boolean =>
  Boolean(
    fetching ||
      error ||
      !isValidPasswordPositions(password) ||
      !isValidCaptcha({ captcha, featureFlag: FeatureFlagsEnumeration.CAPCTHA_LOGIN })
  );

const setupKeyBoardClickForLogin = ({
  password,
  captcha,
  fetching,
  error,
  documentRef,
  handleContinuePasswordGapsButtonClick,
}: SetupKeyboardClickForLoginType): (() => void) | undefined => {

  if (isContinueButtonDisabled({ captcha, fetching, password, error })) {
    return;
  }

  const handleEnterKeyPress = (event: KeyboardEvent) => {
    if (event.key === KeyboardKeyEnumeration.ENTER) handleContinuePasswordGapsButtonClick();
  };

  const controller = new AbortController();
  const { signal } = controller;

  documentRef.current?.addEventListener(
    DocumentEventsEnumeration.KEY_DOWN,
    event => handleEnterKeyPress(event),
    { signal }
  );

  return () => controller.abort();
};

export { isContinueButtonDisabled, isValidPasswordPositions, setupKeyBoardClickForLogin };
