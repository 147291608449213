// Vendors
import React from 'react';
// Types
import { formatTimerTextType } from './types/timer.utils.type';
// Utils
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

export const formatTimerText = ({
  formatMessage,
  timerLabelTranslationId,
  timer,
}: formatTimerTextType): string | React.ReactElement | (string | React.ReactElement)[] => {

  return formatMessage(
    {
      id: timerLabelTranslationId,
    },
    {
      timer,
      b: makeChunkBoldIntlUtil,
    }
  );
};
