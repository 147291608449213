// Vendors
import styled from 'styled-components';

export const RecoverySignatureKeyModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const RecoverySignatureKeyModalListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingM};
  margin: 0;
`;

export const RecoverySignatureKeyModalListItemStyled = styled.li`
  padding: 0;
  margin: 0;
`;
