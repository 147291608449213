// Enumerations
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbRecoveryKey.recoveryKey.step3.cbCheckBox.';

const RECOVERY_OPTIONS = {
  [PasswordRecoveryOptionsEnumeration.CREDIT_CARD]: `${DOMAIN}option02`,
  [PasswordRecoveryOptionsEnumeration.SECURITY_QUESTIONS]: `${DOMAIN}option03`,
  [PasswordRecoveryOptionsEnumeration.SIGNATURE_KEY]: `${DOMAIN}option01`,
};

const WHAT_IS_THIS = `${DOMAIN}WhatIsThis`;

export { RECOVERY_OPTIONS, WHAT_IS_THIS };
