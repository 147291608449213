// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { SECOND_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Types
import { TransactionsReportServiceCancelledDocumentsActionsComponentType } from './types/transactions-report-service-cancelled-documents-actions.component.type';
// Utils
import { hasRequiredDocs } from 'containers/transactions/views/report/utils/transactions-report.utils';
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';
import { useTransactionsReportServiceCancelledDocumentsActionsHooks } from './hooks/transactions-report-service-cancelled-documents-actions.hook';

export const TransactionsReportServiceCancelledDocumentsActionsComponent = ({
  files,
  setFiles,
}: TransactionsReportServiceCancelledDocumentsActionsComponentType): React.ReactElement => {
  const { handleGoBack } = useTransactionsReportServiceCancelledDocumentsActionsHooks(setFiles);
  return (
    <WizardStepFooterComponent
      {...WIZARD_CANCEL_MODAL_PROPS}
      hasDefaultLeftButton
      leftButtonConfiguration={{
        onClick: () => handleGoBack(),
      }}
      rightButtonConfiguration={{
        disabled: !hasRequiredDocs({
          files,
          requiredDocType: DocumentsTypesEnumeration.PROOF_OF_CONTACT,
        }),
      }}
      targets={SECOND_STEP_TARGETS}
    />
  );
};
