
// Types
import { DeviceSessionsHandlersPropsType } from './types/device-sessions.handlers.props.type';
import { DeviceSessionsHandlersReturnType } from './types/device-sessions.handlers.return.type';
// Resolvers
import { deviceSessionsResolver } from 'containers/security-keys/views/devices/resolvers/signature-key-devices.resolvers';

const getDeviceSessionsHandlers = async ({ setFetching, setSessions, setError, deviceUUID, onErrorTracking }) => {
  setFetching(true);

  const [sessions = [], error] = await deviceSessionsResolver({ deviceUUID });

  if (error) {
    onErrorTracking();
    setFetching(false);

    return setError(true);
  }

  setError(false);
  setSessions(currSessions => ({...currSessions, [deviceUUID]: sessions }));
  setFetching(false);
};

const onRetryHandler = (args) => {
  args.onRetryTracking();
  getDeviceSessionsHandlers(args);
};

const DeviceSessionsHandlers = (
  props: DeviceSessionsHandlersPropsType): DeviceSessionsHandlersReturnType => ({
    getDeviceSessions: () => getDeviceSessionsHandlers(props),
    onRetry: () => onRetryHandler(props)
});

export { DeviceSessionsHandlers };