// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ChallengeSmsOtpComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { useRecoveryKey } from 'containers/recovery-key/hooks/recovery-key.hook';
// Styles
import { RecoveryKeyStep2ComponentStyled } from './recovery-key-step2.component.styled';
// Translations
import { DESCRIPTION } from './translations/recovery-key-step2.translations';
import * as translations from 'translations/challenge-sms.translations';
// Utilities
import { translateLabels } from 'utils/translations.utils';
import { formatTimerText } from 'utils/timer.utils';

const RecoveryKeyStep2Component = (): React.ReactElement => {
  const {
    smsOtpChallenged,
    formatMessage,
    fetching,
    handleSendSmsOtpChallenge,
    handleValidateSmsOtpChallenge,
    onChangeSmsOtpChallenge,
    handleResendOtp,
    smsOtpValue,
    timer,
    timerLabelTranslationId,
    isTimerActive,
  } = useRecoveryKey({});

  return (
    <RecoveryKeyStep2ComponentStyled>
      <ParagraphComponent>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      <ChallengeSmsOtpComponent
        {...{ fetching, isTimerActive, value: smsOtpValue }}
        disabled={fetching || smsOtpChallenged}
        handlers={{
          changeHandler: onChangeSmsOtpChallenge,
          buttonHandler: handleSendSmsOtpChallenge,
          requestHandler: handleResendOtp,
        }}
        translations={{
          ...translateLabels({
            formatMessage,
            translations,
          }),
          REQUEST_NEW_CODE: formatTimerText({ formatMessage, timerLabelTranslationId, timer }),
        }}
      />
      <WizardStepFooterComponent
        isFooterDisabled={fetching}
        rightButtonConfiguration={{
          onClick: handleValidateSmsOtpChallenge,
          disabled: !smsOtpChallenged || fetching,
          loading: fetching,
        }}
      />
    </RecoveryKeyStep2ComponentStyled>
  );
};

export { RecoveryKeyStep2Component };
