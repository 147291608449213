const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.benefits.';

const FIRST_INSURANCE_BENEFIT_DESCRIPTION = `${DOMAIN}first`;
const SECOND_INSURANCE_BENEFIT_DESCRIPTION = `${DOMAIN}second`;
const THIRD_INSURANCE_BENEFIT_DESCRIPTION = `${DOMAIN}third`;
const FOURTH_INSURANCE_BENEFIT_DESCRIPTION = `${DOMAIN}fourth`;

export {
  FIRST_INSURANCE_BENEFIT_DESCRIPTION,
  SECOND_INSURANCE_BENEFIT_DESCRIPTION,
  THIRD_INSURANCE_BENEFIT_DESCRIPTION,
  FOURTH_INSURANCE_BENEFIT_DESCRIPTION,
};
