export const CONTEXT_DEFAULT_VALUE = {
  fetching: false,
  error: false,
  devices: [],
  blockedDevices: [],
  sessions: {},
  trusted:  {},
  loading: false,
  setTrusted: (): null => null,
  setFetching: (): null => null,
  setLoading: (): null => null,
  setError: (): null => null,
  setDevices: (): null => null,
  setSessions: (): null => null,
  setBlockedDevices: (): null => null
};
