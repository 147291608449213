// Contexts
import { SignatureKeyDevicesContextConsumerHook } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
// Types
import { TrustedDeviceHookReturnType } from './types/trusted-device.hook.return.type';

const TrustedDeviceHook = (): TrustedDeviceHookReturnType => {
  const { trusted, fetching } = SignatureKeyDevicesContextConsumerHook();

  return { fetching, trusted };
};

export { TrustedDeviceHook }; 