// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Constants
import { CHALLENGE_WRAPPER_PROPS } from './constants/financing-early-repayment-second-step-challenge.constants';
// Hooks
import FinancingEarlyRepaymentSecondStepChallengeHook from './hooks/financing-early-repayment-second-step-challenge.hook';

const FinancingEarlyRepaymentSecondStepChallengeComponent = (): React.ReactElement | null => {
  const {
    cardId,
    eppId,
    handleChallengeError,
    handleChallengeRetryButtonClick,
    handleChallengeSuccess,
  } = FinancingEarlyRepaymentSecondStepChallengeHook();

  return (
    <ChallengeWrapperComponent
      {...CHALLENGE_WRAPPER_PROPS}
      baseServiceBuilder={{ pathParams: { cardId, eppId } }}
      onError={handleChallengeError}
      onRetry={handleChallengeRetryButtonClick}
      onSuccess={handleChallengeSuccess}
    />
  );
};

export default FinancingEarlyRepaymentSecondStepChallengeComponent;
