// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

const MESSAGE_PROPS = {
  type: messageConstants.TYPES.INFORMATIVE,
};

const MESSAGE_TITLE_PROPS = {
  bold: true,
};

export { MESSAGE_PROPS, MESSAGE_TITLE_PROPS };
