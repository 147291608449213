// Constants
import {
  CHANGE_IBAN_SIDEBAR_MESSAGE_01_PROPS,
} from './change-iban-sidebar.constants.common';
import { messageConstants } from '@openbank/cb-ui-commons';

const CHANGE_IBAN_SIDEBAR_PROPS = {
  testId: 'change-iban',
};

const CHANGE_IBAN_SIDEBAR_MESSAGE_02_PROPS = {
  testId: 'change-iban-sidebar-message-02',
  type: messageConstants.TYPES.INFORMATIVE,
};

export {
  CHANGE_IBAN_SIDEBAR_PROPS,
  CHANGE_IBAN_SIDEBAR_MESSAGE_01_PROPS,
  CHANGE_IBAN_SIDEBAR_MESSAGE_02_PROPS,
};
