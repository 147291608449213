// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_DESCRIPTION_PROPS,
} from './constants/financing-ppi-contracting-message.constants';
// Types
import { FinancingPPIContractingMessageType } from './types/financing-ppi-contracting-message.type';

const FinancingPPIContractingMessageComponent = ({
  error,
  label,
}: FinancingPPIContractingMessageType): React.ReactElement | null =>
  error ? (
    <MessageComponent {...FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_PROPS}>
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_1_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={label} />
      </ParagraphComponent>
    </MessageComponent>
  ) : null;

export { FinancingPPIContractingMessageComponent };
