// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Translations
import {
  REPORT_MESSAGE,
  PARTIAL_RETURNED_MESSAGE,
  REPORT_PARTIAL_RETURNED_MESSAGE,
} from '../translations/transactions-landing-row-details-info-message.translations';
// Types
import { GetInfoMessageType } from './types/get-info-message.type';
import { GetInfoMessageReturnType } from './types/get-info-message-return.type';

const getInfoMessage = ({
  instalmentStatus,
  reportId,
}: GetInfoMessageType): GetInfoMessageReturnType => {
  const isPartialReturned = instalmentStatus === EppElementStatusEnumeration.PARTIAL_RETURNED;

  if (reportId) {
    const values = {
      reportId,
    };

    return {
      id: isPartialReturned ? REPORT_PARTIAL_RETURNED_MESSAGE : REPORT_MESSAGE,
      values,
    };
  }

  if (isPartialReturned) {
    return {
      id: PARTIAL_RETURNED_MESSAGE,
    };
  }
};

export { getInfoMessage };
