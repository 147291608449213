export default {
  VIEW: {
    pageName: '/financing/credit-limit-increase',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'cards',
    interactionType: 'funnel',
    serviceType: 'productSettings',
    processType: 'changeCreditLiimit',
  },
  LINK: {
    GO_TO_FINANCING: {
      action: 'click',
      format: 'link',
      component: 'body',
      element: 'goToFinancing',
      pathname: '/financing',
      eventCategory: 'credit-limit-increase',
      eventAction: 'lowerNavigation',
      eventLabel: 'navigationGoToFinancingLink',
    },
  },
} as const;
