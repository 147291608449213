// Types
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';
import { SettingsCardControlLocationsToggleItemUtilsType } from 'containers/card-settings/views/card-control/components/locations/components/toggle/utils/types/settings-card-control-locations-toggle-item.utils.type';

const getAllEnabledCountries = (
  continents: CardControlContinentComponentType[]
): SettingsCardControlLocationsToggleItemUtilsType[] => {
  const enabledCountries: SettingsCardControlLocationsToggleItemUtilsType[] = [];

  continents.forEach(({ countries }: CardControlContinentComponentType) =>
    countries.forEach(
      ({ enable, id }: CardControlContinentCountryComponentType) =>
        enable && enabledCountries.push({ id })
    )
  );

  return enabledCountries;
};

export { getAllEnabledCountries };
