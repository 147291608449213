// Vendors
import React from 'react';
// Components
import {
  ParagraphComponent
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  MILESECONDS,
  SECONDS,
  ERROR_CONFIGURATION_PROPS
} from './constants/device-sessions.configurations.constants';
// Translations
import {
  DURATION,
  ERROR_TITLE,
  ERROR_DESCRIPTION
} from '../translations/device-sessions.translations';
// Types
import { DevicesSessionsErrorConfigurationsPropsType } from './types/devices-sessions.error.configurations.props.type';
import { DevicesSessionsErrorConfigurationsReturnType } from './types/devices-sessions.error.configurations.return.type';
import { DeviceSessionsConfigurationsPropsType } from './types/devices-sessions.configurations.props.type';
import { DeviceSessionsDurationConfigurationsPropsType } from './types/devices-sessions.duration.configurations.props.type';

const format1 = { month: 'long', day: 'numeric' } as const;
const format2 = { hour: 'numeric', minute: 'numeric' } as const;

const getDuration = ({ startTime, endTime, formatMessage }: DeviceSessionsDurationConfigurationsPropsType) => {
  const ms = Math.abs(new Date(startTime).getTime() - new Date(endTime).getTime());

  const min = Math.floor((ms / MILESECONDS / SECONDS));
  const sec = Math.floor((ms / MILESECONDS) % SECONDS);

  return formatMessage({ id: DURATION }, { min, sec });
};

export const getSessionItemConfigurations = ({ session, formatDate, formatMessage }: DeviceSessionsConfigurationsPropsType): React.ReactElement => {
  return (
    <>
      <ParagraphComponent>{ formatDate((new Date(session.endTime)), format1 )}</ParagraphComponent>
      <ParagraphComponent>{ formatDate((new Date(session.endTime)), format2 )}</ParagraphComponent>
      <ParagraphComponent>{ getDuration({ startTime: session.startTime, endTime: session.endTime, formatMessage })}</ParagraphComponent>
    </>
  );
};

export const getFetchDevicesErrorConfiguration = ({
  error,
  formatMessage,
  onClick
}: DevicesSessionsErrorConfigurationsPropsType): DevicesSessionsErrorConfigurationsReturnType => ({
  ...ERROR_CONFIGURATION_PROPS,
  error,
  formatMessage,
  translations: {
    ERROR_TITLE: formatMessage({ id: ERROR_TITLE }),
    ERROR_DESCRIPTION: formatMessage({ id: ERROR_DESCRIPTION })
  },
  buttonConfiguration: {
    children: <FormattedMessageComponent id={ERROR_DESCRIPTION} />,
    onClick
  }
});