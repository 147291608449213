// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const SettingsCardControlLocationsFilteredComponentStyled = styled.div<{ error: boolean }>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spaces.spacingS};
  ${({error, theme}) => error && css`
    padding: ${theme.spaces.spacingXxxl};

    ${mobileMediaQuery} {
      padding: ${theme.spaces.spacingXxxl} ${theme.spaces.spacingM};
    }
  `}
`;
