// Vendors
import { useEffect, useRef, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
import { useNavigate } from 'react-router-dom';
// Constants
import { RECOVER_KEYS_WIZARD_PROPS, TIMER_COUNTER } from '../constants/recovery-key.constants';
// Contexts
import {
  useRecoveryKeyContextConsumerHook,
  useRecoveryOptionContextConsumerHook,
} from 'containers/recovery-key/contexts/recovery-key.context';
import { useDrPicassoManagerContextConsumerHook } from 'contexts/dr-picasso-manager.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Enums
import { RecoveryKeyCCRecoveryOptionEnumeration } from '../enumerations/recovery-key.enumerations';
// Handlers
import { RecoveryKeyHandlers } from '../handlers/recovery-key.handlers';
// Hooks
import { useRecoveryKeyStepChallenge } from './recovery-key-step-challenge.hook';
import useTimer from 'hooks/use-timer.hook';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
// Resolvers
import {
  validateRecoveryKeyDataOtpResolver,
  validateSignatureKeyResolver,
} from '../resolvers/recovery-key.resolvers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
import RecoveryKeyStep3TrackingHandlers from '../components/step3/handlers/recovery-key-step3.tracking.handlers';
// Types
import { useRecoveryKeyInterface, useRecoveryKeyParams } from './types/recovery-key.hook.type';
import { PasswordRecoveryOptionsApiResponseType } from 'types/challenge/password-recovery-options-api-response.type';
import { WizardComponentType } from 'components/wizard/types/wizard.component.type';
import { RecoveryOptionContextInterface } from '../contexts/types/recovery-key.context.type';
// Utilities
import { getTimerLabelTranslationId } from 'components/challenge-wrapper/hooks/utils/challenge-wrapper.hook.utils';
import {
  getNextRecoveryKeyStep,
  resetToStep1IfRecoveryBySMS,
} from './utils/recovery-key.hook.utils';

const useRecoveryKey = ({ nextStep }: useRecoveryKeyParams): useRecoveryKeyInterface => {
  const { formatMessage } = useCbIntl();
  const navigate = useNavigate();
  const { authData, fetching, email, prefix, phoneNumber, setAuthData, setFetching } =
    useRecoveryKeyContextConsumerHook();
  const { recoveryOption, setRecoveryOption } = useRecoveryOptionContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { clientData } = useDrPicassoManagerContextConsumerHook();
  const [steps, setSteps] = useState<WizardComponentType>();
  const [tempRecoveryOption, setTempRecoveryOption] =
    useState<RecoveryKeyCCRecoveryOptionEnumeration>();
  const { handleRecoveryOptionItemRadioClickTracking } = AppTrackingHook(
    RecoveryKeyStep3TrackingHandlers
  );
  const {
    signatureKeyPositions: positions = [],
    passwordRecoveryOptions,
    progressId,
  } = (authData as PasswordRecoveryOptionsApiResponseType) || {};
  const { timer, isTimerActive, handleRestart } = useTimer(TIMER_COUNTER);
  const timerLabelTranslationId = getTimerLabelTranslationId(timer);
  const { setCurrentStep, currentStep } = useWizardContextConsumerHook();
  const { isHybrid, notchSize } = MobileConnectorContextConsumerHook();
  const lastRecoveryOptionRef = useRef<RecoveryOptionContextInterface['recoveryOption']>();

  const {
    challengeValue: smsOtpValue,
    challenged: smsOtpChallenged,
    setChallengeValue: onChangeSmsOtpChallenge,
    handleVerifyRecoveryKeyDataButtonClick: handleSendSmsOtpChallenge,
    handleManageNextRecoveryStepButtonClick: handleValidateSmsOtpChallenge,
  } = useRecoveryKeyStepChallenge({
    nextStep: passwordRecoveryOptions && getNextRecoveryKeyStep(passwordRecoveryOptions),
    resolver: validateRecoveryKeyDataOtpResolver,
  });

  const {
    challengeValue: signatureKeyValue,
    challenged: signatureKeyChallenged,
    setChallengeValue: onChangeSignatureKeyChallenge,
    handleVerifyRecoveryKeyDataButtonClick: handleSendSignatureKeyChallenge,
    handleManageNextRecoveryStepButtonClick: handleValidateSignatureKeyChallenge,
  } = useRecoveryKeyStepChallenge({
    nextStep,
    resolver: validateSignatureKeyResolver,
  });

  useEffect(() => {
    setSteps(RECOVER_KEYS_WIZARD_PROPS(recoveryOption));
    resetToStep1IfRecoveryBySMS({
      currentStep,
      recoveryOption,
      lastRecoveryOptionRef,
      setCurrentStep,
    });

    lastRecoveryOptionRef.current = recoveryOption;
  }, [recoveryOption]);

  const {
    handleSetRecoveryOption,
    handleResendOtp,
    handleInitiateOtp,
    handleRecoveryKeyButtonClick,
  } = RecoveryKeyHandlers({
    setRecoveryOption,
    handleRecoveryOptionItemRadioClickTracking,
    email,
    handleRestart,
    phoneNumber,
    prefix,
    setAuthData,
    setResult,
    setCurrentStep,
    currentStep,
    progressId,
    setFetching,
  });

  return {
    handleSetRecoveryOption,
    handleValidateSmsOtpChallenge,
    onChangeSmsOtpChallenge,
    handleSendSmsOtpChallenge,
    handleValidateSignatureKeyChallenge,
    onChangeSignatureKeyChallenge,
    handleSendSignatureKeyChallenge,
    handleResendOtp,
    formatMessage,
    handleInitiateOtp,
    handleDoneClick: () => navigate(-1),
    setTempRecoveryOption,
    onClickChangeRecoveryKey: handleRecoveryKeyButtonClick,
    isHybrid,
    tempRecoveryOption,
    steps,
    clientData,
    smsOtpChallenged,
    signatureKeyChallenged,
    notchSize,
    recoveryOption,
    smsOtpValue,
    signatureKeyValue,
    positions,
    fetching,
    timer,
    isTimerActive,
    timerLabelTranslationId,
  };
};

export { useRecoveryKey };
