// Vendors
import React from 'react';
// Components
import {
  LinkComponent,
  MessageComponent,
  ParagraphComponent,
  UploadDocTips,
} from '@openbank/cb-ui-commons';
import { InformationModalComponent } from './components/information-modal/information-modal.component';
import { UploadDocumentsSectionComponent } from './components/upload-documents-section/upload-documents-section.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants, {
  cancelUrl,
} from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
import { MESSAGE_COMPONENT_PROPS } from './constants/step-4-upload-documentation.component.constants';
// Configurations
import { getUploadDocTipsConfiguration } from './configurations/upload-doc-tips.configuration';
// Hooks
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import { Step4UploadDocumentationHook } from './hooks/step-4-upload-documentation.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Translations
import { translationsOtherSteps } from 'containers/financing/views/credit-limit-increase/translations/exit-modal-credit-limit-increase.component.translations';
import { step4UploadDocumentationTranslations } from './translations/step-4-upload-documentation.translations';
// Types
import { SetStateType } from '@openbank/cf-ui-static-data';
// Styles
import { ListComponentStyled, ListItemComponentStyled } from 'styles/list.styled';
import {
  ParagraphStyled,
  Step4UploadDocumentationStyled,
  UploadDocTipsStyled,
} from './step-4-upload-documentation.component.styled';
// Utils
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

const Step4UploadDocumentationComponent = (): React.ReactElement => {
  const { formatMessage } = useCbIntl();
  const {
    disabledButtonsCancelModal,
    showModal,
    setShowModal,
    cancelModalButtonClick,
    cancelModalLinkClick,
  } = ExitModalHook();
  const {
    areDocumentsUploaded,
    showInfoModal,
    setShowInfoModal,
    setShowModalWithTracking,
    handleUploadDocumentationBack,
    handleUploadDocumentationGoNext,
  } = Step4UploadDocumentationHook(setShowModal);

  return (
    <Step4UploadDocumentationStyled>
      <ParagraphComponent>
        {formatMessage(
          { id: step4UploadDocumentationTranslations.SUBTITLE },
          { b: makeChunkBoldIntlUtil }
        )}
      </ParagraphComponent>
      <ParagraphComponent bold>
        {formatMessage({ id: step4UploadDocumentationTranslations.REQUIRED_DOCUMENTATION })}
      </ParagraphComponent>
      <ListComponentStyled>
        <ListItemComponentStyled>
          <ParagraphComponent>
            {formatMessage({ id: step4UploadDocumentationTranslations.REQUIRED_1 })}
          </ParagraphComponent>
        </ListItemComponentStyled>
        <ListItemComponentStyled>
          <ParagraphComponent>
            {formatMessage({ id: step4UploadDocumentationTranslations.REQUIRED_2 })}
          </ParagraphComponent>
        </ListItemComponentStyled>
      </ListComponentStyled>
      <UploadDocTipsStyled>
        <UploadDocTips {...getUploadDocTipsConfiguration({ formatMessage, setShowInfoModal })} />
      </UploadDocTipsStyled>

      <UploadDocumentsSectionComponent formatMessage={formatMessage} />

      <MessageComponent {...MESSAGE_COMPONENT_PROPS}>
        <ParagraphStyled>
          {formatMessage({ id: step4UploadDocumentationTranslations.MESSAGE_INFO })}
        </ParagraphStyled>
        <LinkComponent onClick={handleUploadDocumentationBack}>
          {formatMessage({ id: step4UploadDocumentationTranslations.MESSAGE_INFO_LINK })}
        </LinkComponent>
      </MessageComponent>

      <InformationModalComponent
        showInfoModal={showInfoModal}
        setShowInfoModal={setShowInfoModal}
        formatMessage={formatMessage}
      />

      <WizardStepFooterComponent
        rightButtonConfiguration={{
          onClick: handleUploadDocumentationGoNext,
          disabled: !areDocumentsUploaded,
          children: formatMessage({
            id: step4UploadDocumentationTranslations.UPLOAD_DOCUMENTATION,
          }),
        }}
        cancelModal={{
          disabledButtons: disabledButtonsCancelModal,
          cancelUrl,
          showModal,
          setShowModal: setShowModalWithTracking as SetStateType<boolean>,
          clicks: {
            linkClick: cancelModalLinkClick,
            buttonClick: cancelModalButtonClick,
          },
          translations: {
            title: translationsOtherSteps.TITLE,
            description: translationsOtherSteps.DESCRIPTION,
            valuesDescription: { days: constants.EXIT_MODAL_DAYS.FIFTEEN },
            link: translationsOtherSteps.LINK_TEXT,
            button: translationsOtherSteps.PRIMARY_BUTTON_TEXT,
          },
        }}
      />
    </Step4UploadDocumentationStyled>
  );
};

export { Step4UploadDocumentationComponent };
