// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const ConfirmationModalComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const ConfirmationModalFooterComponentStyled = styled.footer`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;

export const ConfirmationModalFeaturesComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  width: 100%;
`;

export const ConfirmationModalFeaturesItemComponentStyled = styled(
  ConfirmationModalFeaturesComponentStyled
)`
  align-items: center;
  flex-direction: row;
`;
