// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TITLE_SECTION_MOBILE_ASSET_PROPS,
  TITLE_SECTION_MOBILE_PROPS,
} from './constants/title-section-mobile.constants';
// Hooks
import { useTitleSectionMobileHook } from './hooks/title-section-mobile.hook';
// Styles
import {
  TitleSectionMobileAssetComponentStyled,
  TitleSectionMobileComponentStyled,
  TitleSectionMobileLabelComponentStyled,
} from './title-section-mobile.component.styled';

const TitleSectionMobileComponent = (): React.ReactElement => {
  const { isHybrid, notchSize, title, handleNavigatePopStateAssetClick } =
    useTitleSectionMobileHook();

  return (
    <TitleSectionMobileComponentStyled {...{ isHybrid, notchSize }}>
      <TitleSectionMobileAssetComponentStyled>
        <AssetComponent
          {...TITLE_SECTION_MOBILE_ASSET_PROPS}
          onClick={handleNavigatePopStateAssetClick}
        />
      </TitleSectionMobileAssetComponentStyled>
      <TitleSectionMobileLabelComponentStyled>
        <ParagraphComponent {...TITLE_SECTION_MOBILE_PROPS}>
          <FormattedMessageComponent id={title} />
        </ParagraphComponent>
      </TitleSectionMobileLabelComponentStyled>
    </TitleSectionMobileComponentStyled>
  );
};
export { TitleSectionMobileComponent };
