// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import WizardFeedbackSuccessHandlers from '../handlers/wizard-feedback-success.handlers';
import WizardFeedbackSuccessTrackingHandlers from '../handlers/wizard-feedback-success.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RecoveryKeyFeedbackBuilderReturnHandlersType } from '../handlers/types/wizard-feedback-success.handlers.type';
import { WizardFeedbackResultComponentType } from 'components/wizard-feedback/types/wizard-feedback-result.component.type';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const useWizardFeedbackSuccessHook = (
  props: Pick<WizardFeedbackResultComponentType, 'exitRoute' | 'linkClick'>
): RecoveryKeyFeedbackBuilderReturnHandlersType => {
  const { onNavigateHybridFlow } = MobileConnectorContextConsumerHook();

  return WizardFeedbackSuccessHandlers({
    ...AppTrackingHook(WizardFeedbackSuccessTrackingHandlers),
    ...props,
    navigate: useNavigate(),
    onNavigateHybridFlow,
  });
};

export default useWizardFeedbackSuccessHook;
