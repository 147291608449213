// Enumerations
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';
// Services
import {
  getUserAvatarService,
  updateUserPhotoService,
} from '../services/personal-photo-navigation-footer.service';
// Utils
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
import { isValidBlobInstance } from 'containers/personal/views/photo/utils/personal-photo.utils';

const updateUserPhotoResolver = async (avatar: string): Promise<[number] | [null, true]> => {
  const [, { status }] = await updateUserPhotoService(avatar);

  return requestHasSuccessStatus(status) ? [status] : [null, true];
};

const getUserAvatarResolver = async (
  avatar: AvatarIdsEnumeration | null
): Promise<[Blob | Record<string, any>] | [null, true]> => {
  const [response, { status }] = await getUserAvatarService(avatar);

  return isValidBlobInstance(response) ? [response] : [null, true];
};

export { getUserAvatarResolver, updateUserPhotoResolver };
