export enum LoginViewsEnumeration {
  EMAIL = 'email',
  ERROR = 'error',
  GAPS = 'gaps',
}

export enum LoginErrorEnum {
  UNAUTHORIZED = 'unauthorized',
  ONBOARDING_INCOMPLETE = 'unboarding_incomplete',
  INVALID_FORMAT = 'invalid_format',
}
