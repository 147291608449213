export default {
  ACCESS_PORTABILITY_REQUEST_STEP1_PARAGRAPH_01_PROPS: {
    testId: 'access-portability-request-paragraph-01',
  },
  ACCESS_PORTABILITY_REQUEST_STEP1_PARAGRAPH_02_PROPS: {
    testId: 'access-portability-request-paragraph-02',
  },
  ACCESS_PORTABILITY_REQUEST_STEP1_PARAGRAPH_03_PROPS: {
    testId: 'access-portability-request-paragraph-03',
  },
  ACCESS_PORTABILITY_REQUEST_STEP1_LINK_PROPS: {
    testId: 'access-portability-request-link',
  },
};
