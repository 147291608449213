// Vendors
import styled from 'styled-components';
// Styles
import { ParagraphStyled } from '../../step-4-upload-documentation.component.styled';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DisplayGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
  }
`;

export const ModalContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

export const ParagraphStartStyled = styled(ParagraphStyled)`
  align-self: start;
`;
