// Vendors
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_PERSONAL_INFO,
} from '../translations/personal-upload-documentation-orchestrator.translations';

const FEEDBACK_PROPS = {
  errorConfiguration: {
    description: FEEDBACK_ERROR_DESCRIPTION,
    title: FEEDBACK_ERROR_TITLE,
    exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
  },
  successConfiguration: {
    description: FEEDBACK_SUCCESS_DESCRIPTION,
    linkLabel: GO_TO_PERSONAL_INFO,
    title: FEEDBACK_SUCCESS_TITLE,
    exitRoute: RoutesEnumeration.PERSONAL_INFORMATION,
  },
};

export { FEEDBACK_PROPS };
