// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsResumeLinkLinksTrackingHandlersType } from 'containers/card-settings/components/resume-link/components/links/handlers/types/card-settings-resume-link-links.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const resumeCardSettingsLinkClickTrackingHandler = (
  trackingLabel: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: trackingLabel,
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsResumeLinkLinksTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsResumeLinkLinksTrackingHandlersType => ({
  handleResumeCardSettingsLinkClickTracking: (trackingLabel: string) =>
    track(resumeCardSettingsLinkClickTrackingHandler(trackingLabel)),
});

export { CardSettingsResumeLinkLinksTrackingHandlers };
