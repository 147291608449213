// Vendors
import styled from 'styled-components';

export const LabelsLinkCardTitleComponentStyled = styled.div.attrs(
  ({ titleId }: { titleId?: string }) => ({ 'data-testid': titleId })
)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;
