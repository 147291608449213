const CONTEXT_DEFAULT_VALUE = {
  challenge: null,
  challengeError: false,
  error: false,
  fee: null,
  paymentMethod: null,
  paymentMethodParams: null,
  revolvingMethod: null,
  revolvingOptions: null,
  setChallenge: (): null => null,
  setChallengeError: (): null => null,
  setError: (): null => null,
  setFee: (): null => null,
  setPaymentMethod: (): null => null,
  setPaymentMethodParams: (): null => null,
  setRevolvingMethod: (): null => null,
  setRevolvingOptions: (): null => null,
};

export { CONTEXT_DEFAULT_VALUE };
