// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Translations
import {
  ERROR_DESCRIPTION,
  SAME_CODE_ERROR_DESCRIPTION,
} from 'containers/card-settings/views/change-3d/translations/card-settings-change-3d-feedback.translations';

const getErrorDescription = (status: number): string => {
  if (status === HttpStatusCodesEnum.BAD_REQUEST) {
    return SAME_CODE_ERROR_DESCRIPTION;
  }

  return ERROR_DESCRIPTION;
};

export { getErrorDescription };
