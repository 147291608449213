// Constants
import {
  CONSENT_CHECKBOXES_CONFIG,
  MAPPED_CONSENTS_TYPES,
  MAPPED_CONSENTS_TYPES_DEPRECATED,
} from './constants/profile-consents.utils.constants';
// Enumerations
import { ConsentTypesEnum } from '../enumerations/consent-types.enum';
import { ConsentsGivenEnum } from '../enumerations/consents-given.enum';
import { ConsentProductIdEnum } from '../enumerations/consent-product-id.enum';
// Types
import { Consent } from '../types/consent.type';
import { ProfileConsentsConfig } from '../types/profile-consent.config.type';
import { ProfileSubConsent } from '../types/profile-consent.type';
import { ProfileConsentType } from '../types/profile-consent-type.type';
import { HasConsentGivenAndHaveNoSubconsentsPropsType } from './types/has-consents-given-and-no-subconsents-props.type';

const isINTLConsent = (consentId: string): boolean => consentId.includes(ConsentTypesEnum.INTL);

const hasConsentGivenAndHaveNoSubconsents = ({
  consentGiven,
  consentSubType,
}: HasConsentGivenAndHaveNoSubconsentsPropsType) =>
  consentGiven === ConsentsGivenEnum.YES && consentSubType === ConsentTypesEnum.NO_CONSENT;

const getMappedConsentsTypes = (productId: ConsentProductIdEnum): string[] =>
  productId === ConsentProductIdEnum.DE_OB_CONSENT
    ? MAPPED_CONSENTS_TYPES_DEPRECATED
    : MAPPED_CONSENTS_TYPES;

const mapResponseConsentsCallback =
  (productId: ConsentProductIdEnum) =>
  (apiConsent: Consent): ProfileSubConsent[] | undefined => {
    if (getMappedConsentsTypes(productId).includes(apiConsent.consentType)) {
      return apiConsent.subTypes.map(({ consentSubType, subTypeConsentGiven }) => ({
        ...CONSENT_CHECKBOXES_CONFIG[consentSubType],
        consentId: apiConsent.consentType,
        id: consentSubType,
        value:
          apiConsent.consentGiven !== undefined
            ? hasConsentGivenAndHaveNoSubconsents({
                consentGiven: apiConsent.consentGiven,
                consentSubType,
              }) || subTypeConsentGiven === ConsentsGivenEnum.YES
            : undefined,
      }));
    }

    return undefined;
  };

const joinConsentSubTypesCallback = (
  mappedConsents: Array<Array<ProfileSubConsent>>,
  currentConsent?: Array<ProfileSubConsent>
): Array<Array<ProfileSubConsent>> => {
  if (!currentConsent) {
    return [...mappedConsents];
  }

  const hasAlreadyOneINTLConsent = mappedConsents.some(subConsents =>
    subConsents.some(({ consentId }) => isINTLConsent(consentId))
  );

  if (isINTLConsent(currentConsent[0].consentId) && hasAlreadyOneINTLConsent) {
    mappedConsents[mappedConsents.length - 1].push(currentConsent[0]);
  } else {
    mappedConsents.push(currentConsent);
  }

  return [...mappedConsents];
};

const mapToProfileConsentsConfigCallback = (
  subConsents: Array<ProfileSubConsent>
): ProfileConsentType => {
  const showConsents = subConsents.some(c => c.value);
  const areConsentsNotInformed = subConsents.some(c => c.value === undefined);

  return {
    subConsents,
    id: subConsents[0].consentId.includes(ConsentTypesEnum.INTL)
      ? ConsentTypesEnum.INTL
      : subConsents[0].consentId,
    ...(areConsentsNotInformed ? {} : { showConsents }),
  };
};

const mapResponseConsentsToProfileConsents = (
  apiConsents: Consent[],
  productId: ConsentProductIdEnum
): ProfileConsentsConfig['consentTypes'] =>
  apiConsents
    .map(mapResponseConsentsCallback(productId))
    .reduce(joinConsentSubTypesCallback, [])
    .flatMap(mapToProfileConsentsConfigCallback);

export { isINTLConsent, mapResponseConsentsToProfileConsents };
