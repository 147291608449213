// Vendors
import styled from 'styled-components';

const SignatureKeySidebarComponentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  margin: 0;
`;

SignatureKeySidebarComponentStyled.displayName = 'SignatureKeySidebarComponentStyled';

export { SignatureKeySidebarComponentStyled };
