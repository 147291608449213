// Types
import {
  CardSettingsResumeLinkActionDisabledBuilderHandlersType,
  CardSettingsResumeLinkLinksBuilderHandlersType,
  CardSettingsResumeLinkLinksBuilderMethodHandlersType,
  CardSettingsResumeLinkLinksBuilderReturnHandlersType,
  CardSettingsResumeLinkLinksClickBuilderHandlersType,
} from './types/card-settings-resume-link-links.handlers.type';

const setActionDisabled = ({
  actionId,
  actionHook,
}: CardSettingsResumeLinkActionDisabledBuilderHandlersType): boolean => actionHook(actionId);

const resumeLinkLinkClickHandler = ({
  handleResumeCardSettingsLinkClickTracking,
  navigate,
  onClick,
  trackingLabel,
  url = '',
  urlState = {},
}: CardSettingsResumeLinkLinksClickBuilderHandlersType): void => {
  handleResumeCardSettingsLinkClickTracking(trackingLabel);

  if (onClick) {
    onClick();
  } else {
    navigate(url, urlState);
  }
};

const CardSettingsResumeLinkLinksHandlers = (
  props: CardSettingsResumeLinkLinksBuilderHandlersType
): CardSettingsResumeLinkLinksBuilderReturnHandlersType => ({
  handleActionDisabled: (actionId?: string) =>
    setActionDisabled({ actionId, actionHook: props.actionHook }),
  handleResumeCardSettingsLinkClick: (
    methodProps: CardSettingsResumeLinkLinksBuilderMethodHandlersType
  ) => resumeLinkLinkClickHandler({ ...methodProps, ...props }),
});

export { CardSettingsResumeLinkLinksHandlers };
