// Constants
import { SECURITY_CHECK_MODAL_TYPE } from './constants/transactions-table-header-link-bar-download-modal.handlers.constants';
// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Resolvers
import { getDocumentByTypeResolver } from '../resolvers/transactions-table-header-link-bar-download-modal.resolvers';
// Translations
import { DOWNLOAD_ERROR } from '../translations/transactions-table-header-link-bar-download-modal.translations';
// Types
import { CloseDownloadModalType } from './types/close-dowload-modal.type';
import { DownloadTransactionHandlerType } from './types/dowload-transactions.type';
import { TransactionsTableHeaderLinkBarDownloadModalHandlersPropsType } from './types/transactions-table-header-link-bar-download-modal.handlers.type';
import { TransactionsTableHeaderLinkBarDownloadModalReturnHandlersType } from './types/transactions-table-header-link-bar-download-modal-return.handlers.type';
// Utilities
import { downloadFile } from 'utils/download-file';
import { getFileTypeAndExtension } from '../utils/transactions-table-header-link-bar-download-modal.utils';

const forbidenResponseHandler = ({
  challenged,
  setChallenged,
  setIsDownloadAvailable,
  setChallengeError,
  response,
  setChallenge,
  setDownloadDocumentType,
  setIsShowDownloadModal,
  setToastConfiguration,
  handleShowSecurityCheckModal,
}) => {
  if (challenged && !response.progressId) {
    setChallengeError(true);
    setChallenged(false);
    setIsShowDownloadModal(false);
    setDownloadDocumentType('');
    setToastConfiguration({ type: ToastTypesEnumeration.ERROR, description: DOWNLOAD_ERROR });
  }
  if (response.progressId) {
    setChallenge(response);
    handleShowSecurityCheckModal(SECURITY_CHECK_MODAL_TYPE);
  } else {
    setIsShowDownloadModal(false);
    setDownloadDocumentType('');
    setToastConfiguration({ type: ToastTypesEnumeration.ERROR, description: DOWNLOAD_ERROR });
  }
  setIsDownloadAvailable(false);
};

const downloadTransactions = async ({
  cardId,
  challenged,
  downloadDocumentType,
  formValuesParams,
  handleHideSecurityCheckModal,
  handleShowSecurityCheckModal,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setFetching,
  setFetchingDownload,
  setIsDownloadAvailable,
  setIsShowDownloadModal,
  setToastConfiguration,
}: DownloadTransactionHandlerType): Promise<void> => {
  setFetching(true);
  setFetchingDownload(true);
  const [response, error] = await getDocumentByTypeResolver({
    cardId,
    downloadDocumentType,
    formValuesParams,
    progressId,
  });
  if (error === HttpStatusCodesEnum.FORBIDDEN) {
    forbidenResponseHandler({
      challenged,
      setChallenged,
      setIsDownloadAvailable,
      setChallengeError,
      response,
      setChallenge,
      setDownloadDocumentType,
      setIsShowDownloadModal,
      setToastConfiguration,
      handleShowSecurityCheckModal,
    });
  } else if ((error !== HttpStatusCodesEnum.FORBIDDEN && error !== undefined) || !response) {
    handleHideSecurityCheckModal(SECURITY_CHECK_MODAL_TYPE);
    setIsShowDownloadModal(false);
    setDownloadDocumentType('');
    setToastConfiguration({ type: ToastTypesEnumeration.ERROR, description: DOWNLOAD_ERROR });
  } else {
    handleHideSecurityCheckModal(SECURITY_CHECK_MODAL_TYPE);
    const { fileType, fileExtension } = getFileTypeAndExtension(downloadDocumentType);
    downloadFile(
      { blob: response.movementsCobranded, filename: response.fileName + fileExtension },
      fileType
    );
    setChallenge(null);
    setDownloadDocumentType('');
    setChallenged(false);
  }
  setFetchingDownload(false);
  setFetching(false);
};

const downloadButtonClickHandler = ({ downloadDocumentType, setDownloadDocumentType }) => {
  setDownloadDocumentType(downloadDocumentType);
};

const cleanDownloadStateHandler = ({ setDownloadDocumentType, setFetching }) => {
  setDownloadDocumentType('');
  setFetching(false);
};

const closeDownloadModal = ({
  setIsShowDownloadModal,
  setDownloadDocumentType,
}: CloseDownloadModalType): void => {
  setDownloadDocumentType('');
  setIsShowDownloadModal(false);
};

const TransactionsTableHeaderLinkBarDownloadModalHandlers = ({
  cardId,
  challenged,
  formValuesParams,
  handleHideSecurityCheckModal,
  handleShowSecurityCheckModal,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setFetching,
  setFetchingDownload,
  setIsDownloadAvailable,
  setIsShowDownloadModal,
  setToastConfiguration,
}: TransactionsTableHeaderLinkBarDownloadModalHandlersPropsType): TransactionsTableHeaderLinkBarDownloadModalReturnHandlersType => ({
  handleDownload: ({ downloadDocumentType }: { downloadDocumentType: string }) => {
    downloadTransactions({
      challenged,
      setIsDownloadAvailable,
      setChallengeError,
      cardId,
      downloadDocumentType,
      formValuesParams,
      handleHideSecurityCheckModal,
      handleShowSecurityCheckModal,
      progressId,
      setChallenge,
      setChallenged,
      setDownloadDocumentType,
      setFetching,
      setFetchingDownload,
      setIsShowDownloadModal,
      setToastConfiguration,
    });
  },
  handleOnClickCancel: () =>
    closeDownloadModal({ setIsShowDownloadModal, setDownloadDocumentType }),
  handleDownloadButtonClick: downloadDocumentType =>
    downloadButtonClickHandler({ downloadDocumentType, setDownloadDocumentType }),
  handleCleanDownloadState: () =>
    cleanDownloadStateHandler({ setDownloadDocumentType, setFetching }),
});

export { TransactionsTableHeaderLinkBarDownloadModalHandlers };
