// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ButtonComponent } from '@openbank/cb-ui-commons';
import CardStatusButtonSwitchOnModalComponent from './components/switch-on-modal/card-status-button-switch-on-modal.component';
// Constants
import constants from './constants/card-status-button.constants';
// Hooks
import CardStatusButtonHook from './hooks/card-status-button.hook';
// Translations
import translations from './translations/card-status-button.translations';
// Types
import { CardStatusButtonComponentType } from './types/card-status-button.component.type';
import { CardStatusComponentType } from '../../types/card-status.component.type';
// Utilities
import { renderButtonWhenInactiveLockedStatus } from './utils/card-status-button.utils';

const CardStatusButtonComponent = ({
  activateCardRedirection,
  breadCrumbPath,
}: CardStatusComponentType): React.ReactElement<CardStatusButtonComponentType> | null => {
  const {
    handleHideSwitchOnModal,
    handleManageChangeCardStatusButtonClick,
    status,
    showActivateModal,
  } = CardStatusButtonHook({ activateCardRedirection, breadCrumbPath });

  return renderButtonWhenInactiveLockedStatus(status) ? (
    <>
      <ButtonComponent
        {...constants.CARD_STATUS_BUTTON_PROPS}
        onClick={handleManageChangeCardStatusButtonClick}
      >
        <FormattedMessageComponent id={translations[status]} />
      </ButtonComponent>
      <CardStatusButtonSwitchOnModalComponent
        {...{ showActivateModal }}
        onClose={handleHideSwitchOnModal}
      />
    </>
  ) : null;
};

export default CardStatusButtonComponent;
