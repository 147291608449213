// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
import { SessionHealthOrchestratorComponent } from './components/orchestrator/session-health-orchestrator.component';
// Hooks
import { useSessionHealthHook } from './hooks/session-health.hook';
// Styles
import { LayoutLoaderStyled } from 'styles/app.styled';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const SessionHealthProvider = ({ children }: ReactChildrenType): React.ReactElement => {
  const { fetching, error } = useSessionHealthHook();

  return (
    <LayoutLoaderStyled {...{ fetching }}>
      <FetchErrorComponent {...{ fetching }}>
        <SessionHealthOrchestratorComponent {...{ error }}>
          {children}
        </SessionHealthOrchestratorComponent>
      </FetchErrorComponent>
    </LayoutLoaderStyled>
  );
};

export { SessionHealthProvider };
