export enum AccessPortabilityTypeEnumeration {
  ACCESS = 'ACCESS',
  PORTABILITY = 'PORTABILITY',
}

export enum AccessPortabilityStatusEnumeration {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PROCESSING = 'PROCESSING',
  AVAILABLE = 'AVAILABLE',
  NOTIFIED = 'NOTIFIED',
  REQUESTED_RECOVERY = 'REQUESTED_RECOVERY',
  RECOVERED_KEY = 'RECOVERED_KEY',
  ERROR = 'ERROR',
  UNRECOVERABLE_ERROR = 'UNRECOVERABLE_ERROR',
  EXPIRED = 'EXPIRED',
}

export enum RequestEmailOptionEnumeration {
  YOUR_EMAIL = 'YOUR_EMAIL',
  OTHER_EMAIL = 'OTHER_EMAIL',
}

export enum RequestEmailInputErrorEnumeration {
  FORMAT = 'FORMAT',
  EMPTY = 'EMPTY',
}

