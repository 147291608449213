// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/transactions-landing-row-details-report.constants';
// Hooks
import TransactionsLandingRowDetailsReportHook from './hooks/transactions-landing-row-details-report.hook';
// Translations
import translations from './translations/transactions-landing-row-details-report.translations';
// Types
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
// Utils
import { showReportProblem } from './utils/transactions-landing-row-details-report.utils';

const TransactionsLandingRowDetailsReportComponent = ({
  id,
  report,
}: Pick<CardTransactionsDetailType, 'id' | 'report'>): React.ReactElement | null => {
  const { handleReportTransactionProblemLinkClick } = TransactionsLandingRowDetailsReportHook(id);

  if (!showReportProblem(report)) {
    return null;
  }

  return (
    <LinkComponent
      {...constants.TRANSACTIONS_LANDING_ROW_DETAILS_REPORT_LINK_PROPS}
      onClick={handleReportTransactionProblemLinkClick}
    >
      <FormattedMessageComponent id={translations.REPORT_A_PROBLEM} />
    </LinkComponent>
  );
};

export default TransactionsLandingRowDetailsReportComponent;
