// Enumerations
import { TravelPlusNextStatusEnumeration } from 'components/travel-plus/enumerations/travel-plus-next-status.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cbTravelPlus.';

const CANCEL_LINK = `${DOMAIN}noCancelLink`,
  BUTTONS = {
    [TravelPlusNextStatusEnumeration.ENABLE]: `${DOMAIN}switchOnButton`,
    [TravelPlusNextStatusEnumeration.DISABLE]: `${DOMAIN}switchOffButton`,
  },
  TITLES = {
    [TravelPlusNextStatusEnumeration.ENABLE]: `${DOMAIN}switchOnTitle`,
    [TravelPlusNextStatusEnumeration.DISABLE]: `${DOMAIN}switchOffTitle`,
  };

export { CANCEL_LINK, BUTTONS, TITLES };
