// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const HYBRID_FLOWS = [
  RoutesEnumeration.ACCESS_KEY,
  RoutesEnumeration.ACCESS_PORTABILITY,
  RoutesEnumeration.ACCESS_REQUEST,
  RoutesEnumeration.BLOCK,
  RoutesEnumeration.CANCEL_CONTRACT,
  RoutesEnumeration.CHANGE_3D_SECURE,
  RoutesEnumeration.CHANGE_IBAN,
  RoutesEnumeration.CHANGE_PIN,
  RoutesEnumeration.CHECK_3D_SECURE,
  RoutesEnumeration.CONSENTS,
  RoutesEnumeration.CREDIT_LIMIT_INCREASE,
  RoutesEnumeration.DOCUMENTATION,
  RoutesEnumeration.NOT_FOUND,
  RoutesEnumeration.PORTABILITY_REQUEST,
  RoutesEnumeration.REQUEST_NEW_CARD,
  RoutesEnumeration.REQUEST_SIGNATURE_KEY,
  RoutesEnumeration.SIGNATURE_KEY,
];

export { HYBRID_FLOWS };
