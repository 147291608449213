// Vendors
import React from 'react';
// Components
import { CreditLimitDecreaseCCSidebarComponent } from './components/sidebar/credit-limit-decrease-cc-sidebar.component';
import { LowestCreditLimitComponent } from './components/lowest-credit-limit/lowest-credit-limit.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import WizardComponent from 'components/wizard/wizard.component';
// Configurations
import { getCreditLimitDecreaseCCConfiguration } from './configurations/credit-limit-decrease-cc.configuration';
// Constants
import { RIGHT_COLUMN_PROPS } from './constants/credit-limit-decrease-cc.constants';
// Contexts
import { CreditLimitDecreaseCCContextConsumerHOC } from './contexts/credit-limit-decrease-cc.context';
// Providers
import { withCardDetailProvider } from 'providers/card-detail/card-detail.provider';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { CreditLimitDecreaseCCViewHook } from './hooks/credit-limit-decrease-cc.view.hook';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
// Translations
import { ERROR_LINK } from './translations/credit-limit-decrease-cc.translations';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const FinancingCreditLimitDecreaseCCBaseView = (): React.ReactElement => {
  const { canDecrease, defaultResult, error, fetching, formatMessage, navigate } =
    CreditLimitDecreaseCCViewHook();

  return (
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <ErrorWrapperComponent
          {...{
            fetching,
            error,
            linkConfiguration: {
              children: formatMessage({ id: ERROR_LINK }),
              onClick: () => navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING)),
            },
          }}
        >
          {canDecrease ? (
            <WizardComponent
              {...getCreditLimitDecreaseCCConfiguration({ defaultResult, navigate })}
            />
          ) : (
            <LowestCreditLimitComponent />
          )}
        </ErrorWrapperComponent>
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled {...RIGHT_COLUMN_PROPS}>
        <CreditLimitDecreaseCCSidebarComponent />
      </LayoutGridContentRightComponentStyled>
    </LayoutGridContentComponentStyled>
  );
};

const FinancingCreditLimitDecreaseCCView = withCardDetailProvider(CreditLimitDecreaseCCContextConsumerHOC(
  FinancingCreditLimitDecreaseCCBaseView
));

export { FinancingCreditLimitDecreaseCCView };
