// Contexts
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';
import useCbIntl from './useCbIntl';
// Configurations
import { getWizardStepsConfiguration } from 'configurations/wizard-steps.configuration';
// Hooks
import { DeviceTypeHook } from './device-type.hook';
// Types
import { WizardStepsHookReturnType } from './types/wizard-steps-return.hook.type';
import { WizardStepsHookType } from './types/wizard-steps.hook.type';

const useWizardStepsHook = ({ steps, step }: WizardStepsHookType): WizardStepsHookReturnType => {
  const { isMobile } = DeviceTypeHook();
  const { isHybrid } = MobileConnectorContextConsumerHook();
  const { formatMessage } = useCbIntl();

  return getWizardStepsConfiguration({
    formatMessage,
    isHybrid,
    isTitleMobile: isHybrid || isMobile,
    step,
    steps,
  });
};

export default useWizardStepsHook;
