const DOMAIN = 'cobranded.services.userSettings.privateArea.ibanBilling.ibanBillingLanding.sidebarInfo.';

export default {
  MESSAGE_1: `${DOMAIN}description01`,
  MESSAGE_2: `${DOMAIN}description02`,
  MESSAGE_3: `${DOMAIN}description03`,
  TITLE_1: `${DOMAIN}title01`,
  TITLE_2: `${DOMAIN}title02`,
  TITLE_3: `${DOMAIN}title03`,
  TITLE: `${DOMAIN}title`,
};
