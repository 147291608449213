// Constants
import { SERVICE_METHOD } from 'constants/service-method.constants';
import { REPORT_PROBLEM_SERVICE_CONFIGURATION } from 'containers/transactions/views/report/services/constants/transactions-report.service.constants';
// Types
import { GetReportDefectiveWizardConfigurationReturnType } from './types/report-defective-confirmation.configuration.type';
import { ParamsServiceType } from '../types/report-defective-confirmation.type';

const getReportDefectiveWizardConfiguration = (
  params: ParamsServiceType
): GetReportDefectiveWizardConfigurationReturnType => {
  return {
    baseService: REPORT_PROBLEM_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: SERVICE_METHOD.POST,
    baseServiceBuilder: {
      pathParams: { cardId: params.cardId, transactionId: params.transactionId },
      params: params.data,
    },
  };
};

export { getReportDefectiveWizardConfiguration };
