// Vendors
import { useEffect } from 'react';
// Contexts
import { LoginContextConsumerHook } from 'containers/login/contexts/login.context';
// Event handlers
import LoginErrorHandlers from '../handlers/login-error.handlers';
import LoginErrorTrackingHandlers from '../handlers/login-error.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LoginErrorBuilderReturnHandlersType } from '../handlers/types/login-error-builder-return.handlers.type';

const LoginErrorHook = (): LoginErrorBuilderReturnHandlersType => {
  const { setLoginView } = LoginContextConsumerHook();

  const { handleRetryAgainButtonClickTracking, handleEnterLoginErrorViewTracking } =
    AppTrackingHook(LoginErrorTrackingHandlers);
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleEnterLoginErrorViewTracking();
  }, []);

  return LoginErrorHandlers({ handleRetryAgainButtonClickTracking, setLoginView });
};

export default LoginErrorHook;
