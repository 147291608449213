// Enumeration
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  CardSettingsStatementsMessageBuilderHandlersType,
  CardSettingsStatementsMessageMethodsHandlersType,
} from './types/card-settings-statements-message.handlers.type';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const cardStatementsTopMessageButtonClickHandler = ({
  handleMakeATransferMoneyButtonClickTracking,
  navigate,
}: CardSettingsStatementsMessageBuilderHandlersType): void => {
  handleMakeATransferMoneyButtonClickTracking();
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.DEPOSIT_MONEY])
    )
  );
};

const CardSettingsStatementsMessageHandlers = (
  props: CardSettingsStatementsMessageBuilderHandlersType
): CardSettingsStatementsMessageMethodsHandlersType => ({
  handleMakeATransferMoneyButtonClick: () => cardStatementsTopMessageButtonClickHandler(props),
});

export { CardSettingsStatementsMessageHandlers };
