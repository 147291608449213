// Vendors
import React from 'react';
// Components
import CardStatusComponent from 'components/card-status/card-status.component';
import { TableOrganismComponent } from 'organisms/table-organism/table-organism.component';
// Constants
import constants from './constants/transactions-landing.constants';
// Styles
import {
  AppCardSectionComponentStyled,
  AppCardStatusSectionComponentStyled,
} from 'styles/app.styled';
import { TransactionsLandingSectionComponentStyled } from './transactions-landing.view.styled';

const TransactionsLandingView = (): React.ReactElement => (
  <AppCardSectionComponentStyled>
    <TransactionsLandingSectionComponentStyled>
      <AppCardStatusSectionComponentStyled>
        <CardStatusComponent {...constants.TRANSACTION_LANDING_CARD_STATUS_PROPS} />
      </AppCardStatusSectionComponentStyled>
      <TableOrganismComponent
        {...{
          tableConfigurationProps: {
            ...constants.TRANSACTION_LANDING_TABLE_PROPS,
          },
          filterId: 'transactions',
        }}
      />
    </TransactionsLandingSectionComponentStyled>
  </AppCardSectionComponentStyled>
);

export default TransactionsLandingView;
