// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import { DeviceHeaderHandlers } from '../handlers/device-header.handlers';
// Types
import { DeviceHeaderHookReturnType } from './types/device-header.hook.return.type';
import { DeviceHeaderHookType } from './types/device-header.hook.type';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';

const DeviceHeaderHook = ({ device }: DeviceHeaderHookType): DeviceHeaderHookReturnType => {
  const { isMobile } = DeviceTypeHook();
  const navigate = useNavigate();

  const { onLinkClick } = DeviceHeaderHandlers({ navigate, device });

  return {
    isMobile,
    onLinkClick,
  };
};

export { DeviceHeaderHook };
