// Vendors
import React from 'react';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
// Hooks
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
// Utils
import { hasAllSelectedOptions, hasSelectedOptionsChanged } from './utils/transactions-report-fraudulent-movement-additional-info-actions.utils';

const TransactionsReportFraudulentMovementAdditionalInfoActionsComponent =
  (): React.ReactElement => {
    const {
      selectedOptions: {
        extraInformation,
        notRecognizeChargeHaveYourCard,
        notRecognizeChargeClickLink,
        notRecognizeChargeMotiveFraud,
      },
    } = TransactionsReportFraudulentMovementConsumerHook();

    const hasValidSelectedOptions = hasAllSelectedOptions({
      notRecognizeChargeHaveYourCard,
      notRecognizeChargeClickLink,
      notRecognizeChargeMotiveFraud,
    });

    const hasOptionsChanged = hasSelectedOptionsChanged({
      extraInformation,
      notRecognizeChargeHaveYourCard,
      notRecognizeChargeClickLink,
      notRecognizeChargeMotiveFraud,
    });

    return (
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        rightButtonConfiguration={{
          disabled: !hasValidSelectedOptions,
        }}
        targets={FIRST_STEP_TARGETS}
        shouldShowModal={hasOptionsChanged}
      />
    );
  };

export { TransactionsReportFraudulentMovementAdditionalInfoActionsComponent };
