// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { WizardFeedbackSuccessTrackingHandlersType } from './types/wizard-feedback-success.tracking.handlers.type';

const wizardFeedbackSuccessLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'back',
  },
  type: TrackingEventsEnum.LINK,
});

const WizardFeedbackSuccessTrackingHandlers = (
  track: TrackBuilderType
): WizardFeedbackSuccessTrackingHandlersType => ({
  handleWizardFeedbackSuccessLinkClickTracking: () =>
    track(wizardFeedbackSuccessLinkClickTrackingHandler()),
});

export default WizardFeedbackSuccessTrackingHandlers;
