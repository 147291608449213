// Vendors
import styled from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const PersonalAddressStep1AddressBoxComponentStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.spaces.spacingXxxxl};

  * {
    width: 100%;
  }

  ${mobileMediaQuery} {
    grid-template-columns: 1fr;
  }
`;
