// Constants
import {
  CATEGORY,
  UPLOAD_METHOD,
} from './constants/step-4-upload-documentation.hook.utils.constants';
// Enumerations
import { ActivityTypeDeclaredEnumeration } from 'containers/financing/views/credit-limit-increase/components/step2/sections/employment-data/enumerations/employment-status.enumeration';
import { UploadDocumentIdsEnumeration } from '../../components/upload-document/enums/upload-document-ids.enumeration';
// Types
import { DocumentToUploadType } from 'containers/financing/views/credit-limit-increase/handlers/types/document-to-upload.type';
import { Step4Type } from 'containers/financing/views/credit-limit-increase/contexts/types/step-4-credit-limit-increase.context.type';

const getHasDocumentsUploaded = (
  documentsInfo: Step4Type['documentsInfo'],
  activityTypeDeclared: ActivityTypeDeclaredEnumeration
): boolean =>
  getHasEmployedDocumentsUploaded(documentsInfo, activityTypeDeclared) ||
  getHasRentierDocumentsUploaded(documentsInfo, activityTypeDeclared) ||
  getHasSelfEmployedDocumentsUploaded(documentsInfo, activityTypeDeclared) ||
  getHasPensionerDocumentsUploaded(documentsInfo, activityTypeDeclared);

/* eslint-disable */
const validateRejectedDocuments = (step4: Step4Type): boolean => {
  const { documentsInfo } = step4;
  const { documentsRejected } = step4.stepData || {};
  if (!documentsInfo || !documentsRejected) return false;
  for (const rejectedDocument of documentsRejected) {
    const currentDocumentInfo = documentsInfo?.[rejectedDocument.documentDescription];
    if (!currentDocumentInfo?.hasOwnProperty('isUploaded') || !currentDocumentInfo?.isUploaded) {
      return false;
    }
  }
  return true;
};

const getHasEmployedDocumentsUploaded = (
  documentsInfo: Step4Type['documentsInfo'],
  activityTypeDeclared: ActivityTypeDeclaredEnumeration
): boolean =>
  activityTypeDeclared === ActivityTypeDeclaredEnumeration.EMPLOYED &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.LAST_SALARY]?.isUploaded) &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.PREVIOUS_SALARY]?.isUploaded) &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.BEFORE_PREVIOUS_SALARY]?.isUploaded);

const getHasRentierDocumentsUploaded = (
  documentsInfo: Step4Type['documentsInfo'],
  activityTypeDeclared: ActivityTypeDeclaredEnumeration
): boolean =>
  activityTypeDeclared === ActivityTypeDeclaredEnumeration.RENTIER &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.LAST_INCOME_TAX]?.isUploaded);

const getHasSelfEmployedDocumentsUploaded = (
  documentsInfo: Step4Type['documentsInfo'],
  activityTypeDeclared: ActivityTypeDeclaredEnumeration
): boolean =>
  activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.LAST_BWA]?.isUploaded) &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.LAST_INCOME_TAX]?.isUploaded);

const getHasPensionerDocumentsUploaded = (
  documentsInfo: Step4Type['documentsInfo'],
  activityTypeDeclared: ActivityTypeDeclaredEnumeration
): boolean =>
  activityTypeDeclared === ActivityTypeDeclaredEnumeration.PENSIONER &&
  Boolean(documentsInfo?.[UploadDocumentIdsEnumeration.ANNUAL_PENSION]?.isUploaded);

export const getIfAreDocumentsUploaded = (step4: Step4Type): boolean => {
  const { documentsInfo } = step4;
  const { documentsRejected } = step4?.stepData || {};
  const { activityTypeDeclared } = step4.stepData?.personalData || {};

  if (documentsRejected && documentsRejected.length > 0) {
    return validateRejectedDocuments(step4);
  }

  if (
    !documentsInfo?.[UploadDocumentIdsEnumeration.MAIN_BANK_ACCOUNT]?.isUploaded ||
    !activityTypeDeclared
  ) {
    return false;
  }

  return getHasDocumentsUploaded(documentsInfo, activityTypeDeclared);
};

export const getDocumentsToUpload = (step4: Step4Type): DocumentToUploadType[] => {
  if (step4.documentsInfo) {
    const keys = Object.keys(step4.documentsInfo);
    const documentsToUpload = keys
      .map(key => {
        if (step4.documentsInfo?.[key]?.dataToUpload) {
          return {
            ...step4.documentsInfo?.[key]?.dataToUpload,
            description: key,
            uploadMethod: UPLOAD_METHOD,
            required: true,
            category: CATEGORY,
          };
        }

        return undefined;
      })
      .filter(d => d) as DocumentToUploadType[];

    return documentsToUpload;
  }

  return [];
};
