// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { CardSettingsContractCurrentTravelHookType } from './types/card-settings-contract-current-travel.hook.type';

const useCardSettingsContractCurrentTravelHook = (): CardSettingsContractCurrentTravelHookType => {
  const { currentCard } = AppContextConsumerHook(),
    { activated, expirationDate } = currentCard?.travelPlus || {};

  const { formatMessage } = useCbIntl();

  return {
    activated,
    checked: !!activated,
    expirationDate,
    formatMessage,
  };
};

export { useCardSettingsContractCurrentTravelHook };
