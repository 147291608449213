// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { AppNoUserDataHeaderHandlers } from '../handlers/header.handlers';
import LogoutHandlers from 'handlers/logout.handlers';
// Types
import { AppNoUserDataHeaderHookReturnType } from './types/header.hook.return.type';

const AppNoUserDataHeaderHook = (): AppNoUserDataHeaderHookReturnType => {
  const { formatMessage } = useCbIntl();
  const [ showLogOutModal, setShowLogOutModal ] = useState<boolean>(false);

  const { handleMakeUserLogoutClick } = AppNoUserDataHeaderHandlers({
    ...LogoutHandlers(),
  });

  return {
    formatMessage,
    showLogOutModal,
    handleMakeUserLogoutClick,
    handleMakeUserCancelClickHandler: () => setShowLogOutModal(false),
    handleMakeUserLogoutButtonClick: () => setShowLogOutModal(true),
  };
};

export { AppNoUserDataHeaderHook };
