// Components
import { TransactionsReportServiceCancelledAdditionalInfoComponent } from '../components/additional-info/transactions-report-service-cancelled-additional-info.component';
import { TransactionsReportServiceCancelledConfirmationComponent } from '../components/confirmation/transactions-report-service-cancelled-confirmation.component';
import { TransactionsReportServiceCancelledDocumentsComponent } from '../components/documents/transactions-report-service-cancelled-documents.component';
// Translations
import {
  ADDITIONAL_INFORMATION_TITLE,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
  UPLOAD_DOCUMENTS_DESCRIPTION,
  UPLOAD_DOCUMENTS_TITLE,
} from 'containers/transactions/views/report/translations/transactions-report.translations';
import { ADDITIONAL_INFORMATION_DESCRIPTION } from '../translations/transactions-report-service-cancelled.translations';

const STEPS = [
  {
    component: TransactionsReportServiceCancelledAdditionalInfoComponent,
    description: ADDITIONAL_INFORMATION_DESCRIPTION,
    title: ADDITIONAL_INFORMATION_TITLE,
  },
  {
    component: TransactionsReportServiceCancelledDocumentsComponent,
    description: UPLOAD_DOCUMENTS_DESCRIPTION,
    title: UPLOAD_DOCUMENTS_TITLE,
  },
  {
    component: TransactionsReportServiceCancelledConfirmationComponent,
    description: CONFIRMATION_DESCRIPTION,
    title: CONFIRMATION_TITLE,
  },
];

export { STEPS };
