// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { FinancingPPICancellationLandingFooterBuilderReturnTrackingHandlersType } from './types/financing-ppi-cancellation-landing-footer.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const confirmCancelPPIContractButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'confirmCancellation',
  },
  type: TrackingEventsEnum.LINK,
});

const FinancingPPICancellationLandingFooterTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPICancellationLandingFooterBuilderReturnTrackingHandlersType => ({
  handleConfirmCancelPPIContractButtonClickTracking: () =>
    track(confirmCancelPPIContractButtonClickTrackingHandler()),
});

export { FinancingPPICancellationLandingFooterTrackingHandlers };
