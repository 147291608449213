// Vendors
import React from 'react';
// Components
import { ParagraphComponent, ProductDetailComponent } from '@openbank/cb-ui-commons';
import { FinancingPaymentMethodSecondStepSummaryTncComponent } from './components/tnc/financing-payment-method-second-step-summary-tnc.component';
// Constants
import { PARAGRAPH_PROPS } from './constants/financing-payment-method-second-step-summary.constants';
// Hooks
import FinancingPaymentMethodSecondStepSummaryHook from './hooks/financing-payment-method-second-step-summary.hook';
// Styles
import { FinancingPaymentMethodSecondStepSummaryComponentStyled } from './financing-payment-method-second-step-summary.component.styled';
// Translations
import {
  NEW_METHOD_TITLE,
  PREVIOUS_METHOD_TITLE,
} from './translations/financing-payment-method-second-step-summary.translations';

const FinancingPaymentMethodSecondStepSummaryComponent = (): React.ReactElement => {
  const { formatMessage, paymentMethodLabel, newPaymentMethodLabel } =
    FinancingPaymentMethodSecondStepSummaryHook();

  return (
    <FinancingPaymentMethodSecondStepSummaryComponentStyled>
      <ProductDetailComponent title={formatMessage({ id: PREVIOUS_METHOD_TITLE })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>{paymentMethodLabel}</ParagraphComponent>
      </ProductDetailComponent>
      <ProductDetailComponent title={formatMessage({ id: NEW_METHOD_TITLE })}>
        <ParagraphComponent {...PARAGRAPH_PROPS}>{newPaymentMethodLabel}</ParagraphComponent>
      </ProductDetailComponent>
      <FinancingPaymentMethodSecondStepSummaryTncComponent />
    </FinancingPaymentMethodSecondStepSummaryComponentStyled>
  );
};

export default FinancingPaymentMethodSecondStepSummaryComponent;
