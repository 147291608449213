// Services
import { rejectM2Service, sendTaxIdService } from '../services/final-feedback.services';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';
// Types
import { SendTaxIdResponseType } from '../services/types/send-tax-id-response.type';
import { SendTaxIdPropsType } from '../services/types/send-tax-id-props.type';

export const sendTaxIdResolver = async ({
  caseId,
  taxId,
}: SendTaxIdPropsType): Promise<[SendTaxIdResponseType | null, boolean]> => {
  const [response, { status }] = await sendTaxIdService({
    caseId,
    taxId,
  });
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

export const rejectM2Resolver = async (caseId: string): Promise<[void | null, boolean]> => {
  const [response, { status }] = await rejectM2Service(caseId);
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};
