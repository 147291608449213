const DOMAIN =
  'cobranded.services.userSettings.privateArea.personalInformation.personalDetails.modifyBillingAddress.step2.';

const TITLE = `${DOMAIN}title`,
  DESCRIPTION = `${DOMAIN}description`,
  SUMMARY_TITLE = `${DOMAIN}summary.title`,
  MESSAGE_DESCRIPTION = `${DOMAIN}cbMessage`,
  NEXT = `${DOMAIN}nextButton`,
  CANCEL = `${DOMAIN}cancelButton`;

export { TITLE, DESCRIPTION, MESSAGE_DESCRIPTION, SUMMARY_TITLE, NEXT, CANCEL };