// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import {
  ParagraphComponent,
  ResumeItemComponent,
  ScriptBoxComponent,
} from '@openbank/cb-ui-commons';
import PersonalNumberStep2ActionsComponent from './components/actions/personal-number-step2-actions.component';
import PersonalNumberStep2ChallengeComponent from './components/challenge/personal-number-step2-challenge.component';
import { ClientName } from 'containers/recovery-key/components/client-name/client-name.component';
// Contexts
import { PersonalNumberStep2ContextConsumerHOC } from './contexts/personal-number-step2.context';
// Hooks
import PersonalNumberStep2Hook from './hooks/personal-number-step2.hook';
// Styles
import { PersonalNumberStepLayoutViewStyled } from '../../personal-number.view.styled';
// Constants
import constants from './constants/personal-number-step2.constants';
// Translations
import * as translations from './translations/personal-number-step2.translations';
import {
  DO_YOU_AGREE,
  MODIFY_PERSONAL_DATA,
  SEND_CONFIRMATION_CODE,
  TELL_CONFIRMATION_CODE,
} from '../../translations/personal-number.translations';
// Utils
import { formatPhoneNumber } from 'containers/personal/views/number/utils/personal-number.utils.utils';
import { isCC } from 'utils/app.utils';

const PersonalEmailStep2Component = (): React.ReactElement => {
  const { handleRequestNewNumberOtpLinkClick, newPhoneNumber, newPrefix, firstLastName } =
    PersonalNumberStep2Hook();

  return (
    <PersonalNumberStepLayoutViewStyled>
      {isCC() ? (
        <ScriptBoxComponent>
          <ClientName firstLastName={firstLastName} />
          <ParagraphComponent>
            <FormattedMessageComponent id={SEND_CONFIRMATION_CODE} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={MODIFY_PERSONAL_DATA} />
          </ParagraphComponent>
        </ScriptBoxComponent>
      ) : (
        <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_2_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.DESCRIPTION} />
        </ParagraphComponent>
      )}
      <ResumeItemComponent
        {...constants.PERSONAL_NUMBER_STEP_2_OTP_PHONE}
        label={<FormattedMessageComponent id={translations.NEW_PHONE} />}
        value={formatPhoneNumber({ newPrefix, phoneNumber: newPhoneNumber })}
      />
      {isCC() && (
        <ScriptBoxComponent>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={DO_YOU_AGREE} values={{ firstLastName }} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={TELL_CONFIRMATION_CODE} />
          </ParagraphComponent>
        </ScriptBoxComponent>
      )}
      <PersonalNumberStep2ChallengeComponent onRetry={handleRequestNewNumberOtpLinkClick} />
      <PersonalNumberStep2ActionsComponent />
    </PersonalNumberStepLayoutViewStyled>
  );
};

export { PersonalEmailStep2Component };

export default PersonalNumberStep2ContextConsumerHOC(PersonalEmailStep2Component);
