export const CONTEXT_DEFAULT_VALUE = {
  consents: null,
  error: false,
  fetching: true,
  initialConsents: null,
  setConsents: (): null => null,
  setError: (): false => false,
  setFetching: (): true => true,
  setInitialConsents: (): null => null,
};
