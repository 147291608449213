// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';
// Resolvers
import { changeCurrentCardStatusResolver } from 'containers/card-settings/views/activate-card/resolvers/card-settings-activate-card.resolvers';
// Types
import { ActivateHandlerType } from './types/activate-handler.handlers.type';
import { CancelHandlerType } from './types/cancel-handler.handlers.type';
import { CardSettingsActivateCardChallengeHandlersType } from './types/card-settings-activate-card-challenge.handlers.type';
import { CardSettingsActivateCardChallengeReturnHandlersType } from './types/card-settings-activate-card-challenge-return.handlers.type';
import { ChallengeSuccessHandlerType } from './types/challenge-success-handler.handlers.type';
import { ChallengeErrorHandlerType } from './types/challenge-error-handler.handlers.type';
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const cancelHandler = ({
  handleCancelLinkClickTracking,
  lastPage,
  navigate,
}: CancelHandlerType) => {
  handleCancelLinkClickTracking();
  navigate(generateAbsoluteRoute(lastPage));
};

const activateHandler = async ({
  cardId,
  challenge,
  handleActivateCardButtonClickTracking,
  setChallenge,
  setChallengeError,
  setResult,
}: ActivateHandlerType) => {
  handleActivateCardButtonClickTracking();
  const [, error] = await changeCurrentCardStatusResolver({
    cardId,
    progressId: challenge?.progressId,
  });
  if (error) {
    if (error === HttpStatusCodesEnum.FORBIDDEN) {
      setChallenge(null);
      setChallengeError(true);
    } else {
      setResult(false);
    }
  } else {
    setResult(true);
  }
};

const challengeSuccessHandler = ({ setChallenge, challenge }: ChallengeSuccessHandlerType) =>
  setChallenge(challenge);

const challengeErrorHandler = ({ challenge, setError }: ChallengeErrorHandlerType) => {
  if (!challenge) setError(true);
};

const challengeRetryButtonClickHandler = ({
  setChallengeError,
}: ChallengeRetryButtonClickHandlerType) => setChallengeError(false);

const CardSettingsActivateCardChallengeHandlers = ({
  cardId,
  challenge,
  handleActivateCardButtonClickTracking,
  handleCancelLinkClickTracking,
  lastPage,
  navigate,
  setChallenge,
  setChallengeError,
  setError,
  setResult,
}: CardSettingsActivateCardChallengeHandlersType): CardSettingsActivateCardChallengeReturnHandlersType => ({
  handleActivateButtonClick: () =>
    activateHandler({
      cardId,
      challenge,
      handleActivateCardButtonClickTracking,
      setChallenge,
      setChallengeError,
      setResult,
    }),
  handleCancelLinkClick: () => cancelHandler({ handleCancelLinkClickTracking, lastPage, navigate }),
  handleChallengeError: challenge => challengeErrorHandler({ challenge, setError }),
  handleChallengeSuccess: challenge => challengeSuccessHandler({ setChallenge, challenge }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler({ setChallengeError }),
});

export default CardSettingsActivateCardChallengeHandlers;
