// Vendors
import React from 'react';
// Components
import SupportRouterComponent from './components/router/support-router.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Constants
import constants from './constants/support.constants';
// Styles
import { LayoutGridComponentStyled } from 'styles/app.styled';

const SupportContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <TitleSectionComponent {...constants.SUPPORT_TITLE_PROPS} />
    <SupportRouterComponent />
  </LayoutGridComponentStyled>
);

export default SupportContainer;
