// Vendors
import { TrackingUserStatusEnum, TrackingUserTypeEnum } from '@openbank/cf-ui-static-data';

const TRACKING_CONFIGURATION = {
  userType: TrackingUserTypeEnum.CLIENT,
  userStatus: TrackingUserStatusEnum.LOGGEDIN,
};
const TRACKING_CUSTOM_CONFIGURATION = { userMarket: 'DE', webArea: 'private' };

export { TRACKING_CONFIGURATION, TRACKING_CUSTOM_CONFIGURATION };
