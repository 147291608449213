// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { TransactionsReportConfirmationComponent } from 'containers/transactions/views/report/components/confirmation/transactions-report-confirmation.component';
import { ReportProblemChallengeComponent } from 'containers/transactions/views/report/components/challenge/report-problem-challenge.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Configurations
import { getReportDefectiveWizardConfiguration } from './configurations/report-defective-confirmation.configuration';
// Constants
import { WIZARD_CANCEL_MODAL_PROPS } from 'containers/transactions/views/report/constants/transactions-report.constants';
import { FOURTH_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Hooks
import { ReportProblemDefectiveConfirmationHook } from './hooks/report-defective-confirmation.hook';
// Translations
import { WIZARD_CONFIRM_BUTTON } from 'translations/wizard.translations';
import { LABEL_TYPE_CLAIM } from './translations/report-defective-confirmation.translations';

export const ReportDefectiveConfirmationComponent = (): React.ReactElement => {
  const { handleReportProblem, params, challenge, challengeError } =
    ReportProblemDefectiveConfirmationHook();

  return (
    <>
      <TransactionsReportConfirmationComponent claimType={LABEL_TYPE_CLAIM} />
      <ReportProblemChallengeComponent {...getReportDefectiveWizardConfiguration(params)} />
      <WizardStepFooterComponent
        {...WIZARD_CANCEL_MODAL_PROPS}
        hasDefaultLeftButton
        hasDefaultRightButton
        rightButtonConfiguration={{
          assetConfiguration: {},
          children: <FormattedMessageComponent id={WIZARD_CONFIRM_BUTTON} />,
          disabled: !challenge || challengeError,
          onClick: handleReportProblem,
        }}
        targets={FOURTH_STEP_TARGETS}
      />
    </>
  );
};
