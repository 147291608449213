// Vendors
import styled, { css } from 'styled-components';

export const PersonalEmailStepLayoutViewStyled = styled.section<{ fetching?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
  ${({ fetching, theme }) =>
    fetching &&
    css`
      align-items: center;
      justify-content: center;
      padding: ${theme.spaces.spacingM};
      width: 100%;
    `}
`;
