// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Constants
import { STEPS } from './constants/report-subscription-already-cancelled.constants';
// Contexts
import { ReportSubscriptionAlreadyCancelledContextConsumerHOC } from './contexts/report-subscription-already-cancelled.context';

const ReportSubscriptionAlreadyCancelledBaseComponent = (): React.ReactElement => (
  <TransactionsReportWizardComponent steps={STEPS} />
);

const ReportSubscriptionAlreadyCancelledComponent =
  ReportSubscriptionAlreadyCancelledContextConsumerHOC(
    ReportSubscriptionAlreadyCancelledBaseComponent
  );

export { ReportSubscriptionAlreadyCancelledComponent };
