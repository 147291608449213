// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { PersonalEmailHookType } from './types/personal-email.hook.type';

const PersonalEmailStep1Hook = (): PersonalEmailHookType => {
  const { profile } = AppContextConsumerHook(),
    { email } = profile?.contactDetails || {};

  return { email };
};

export default PersonalEmailStep1Hook;
