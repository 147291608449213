// Enumerations
import { ActivityTypeDeclaredEnumeration } from '../../components/step2/sections/employment-data/enumerations/employment-status.enumeration';
// Types
import { EmploymentLocalType } from '../../contexts/types/employment-local-step-2.type';
import { PersonalDataLocalType } from '../../contexts/types/personal-data-local-step-2.type';
import { Step2Type } from '../../contexts/types/step-2-credit-limit-increase.context.type';

const isEmploymentDataValidated = (personalDataLocal?: PersonalDataLocalType): boolean => {
  const hastNetIncome = Boolean(personalDataLocal?.employments?.[0]?.netIncome);

  if (
    personalDataLocal?.activityTypeDeclared === ActivityTypeDeclaredEnumeration.EMPLOYED ||
    personalDataLocal?.activityTypeDeclared === ActivityTypeDeclaredEnumeration.SELF_EMPLOYED
  ) {
    return (
      Boolean(personalDataLocal?.employments?.[0]?.monthJoinedCompany) &&
      Boolean(personalDataLocal?.employments?.[0]?.yearJoinedCompany) &&
      hastNetIncome
    );
  }

  return hastNetIncome;
};

const isExpensesDataValidated = (employmentLocalData?: EmploymentLocalType): boolean =>
  Boolean(employmentLocalData?.monthlyRentalExpense) &&
  Boolean(employmentLocalData?.monthlyLoanExpense) &&
  Boolean(employmentLocalData?.monthlyMortagageExpense);

const isPersonalDataValidated = (personalDataLocal?: PersonalDataLocalType): boolean =>
  Boolean(personalDataLocal?.maritalStatus) &&
  personalDataLocal?.employments?.[0]?.childrenInHousehold !== undefined &&
  personalDataLocal?.employments?.[0]?.childrenWithMaintenance !== undefined;

export const validateLaboralAndEmploymentData = (
  personalDataLocal?: PersonalDataLocalType
): Step2Type => ({
  isEmploymentDataEditModeOn: !isEmploymentDataValidated(personalDataLocal),
  isEmploymentDataReady: isEmploymentDataValidated(personalDataLocal),
  isExpensesEditModeOn: !isExpensesDataValidated(personalDataLocal?.employments?.[0]),
  isExpensesReady: isExpensesDataValidated(personalDataLocal?.employments?.[0]),
  isPersonalSituationEditModeOn: !isPersonalDataValidated(personalDataLocal),
  isPersonalSituationReady: isPersonalDataValidated(personalDataLocal),
  personalData: personalDataLocal || {},
});
