// Vendors
import React, { PropsWithChildren } from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import viewCodeConstants from '../constants/view-code.constants';
// Translations
import sectionsTranslations from 'translations/sections.translations';
import viewCodeTranslations from '../translations/view-code.translations';
// Types
import {
  GetButtonPropsProps,
  GetKeyCardComponentPropsProps,
  GetKeyCardComponentPropsReturnType,
  GetLinkPropsReturnType,
} from './types/view-code.config.type';
import { GetKeyCardGoBackPathLabelProps } from './types/get-abutton-props-props.type';

const { rightLinkProps } = viewCodeConstants;

export const getKeyCardComponentProps = ({
  code,
  formatMessage,
  time,
  tracking,
  handleRestartProcess,
  type,
}: GetKeyCardComponentPropsProps): GetKeyCardComponentPropsReturnType => ({
  code,
  labels: {
    buttonAgainText: formatMessage({ id: viewCodeTranslations[type]?.BUTTON_AGAIN }),
    buttonText: formatMessage({ id: viewCodeTranslations[type]?.BUTTON }),
    time: formatMessage({ id: viewCodeTranslations[type]?.TIME }),
    timeExpired: formatMessage({ id: viewCodeTranslations[type]?.TIME_EXPIRED }),
  },
  onAgainClick: tracking.onShowCodeAgain,
  onClick: tracking.onShowCode,
  handleOnNewQuery: handleRestartProcess,
  time,
  type: type.toUpperCase() as GetKeyCardComponentPropsReturnType['type'],
});

export const getKeyCardGoBackPathLabel = ({
  type,
}: GetKeyCardGoBackPathLabelProps): React.ReactElement => {
  const prevPath = localStorage.getItem('prevPath');
  return prevPath ? (
    <FormattedMessageComponent
      id={viewCodeTranslations[type]?.PREVIOUS_BACK_BUTTON}
      values={{ path: <FormattedMessageComponent id={sectionsTranslations[prevPath]} /> }}
    />
  ) : (
    <FormattedMessageComponent id={viewCodeTranslations[type]?.BACK_BUTTON} />
  );
};

export const getRightLinkProps = ({
  children,
  onClick,
}: PropsWithChildren<GetButtonPropsProps>): GetLinkPropsReturnType => ({
  ...rightLinkProps,
  children: <>{children}</>,
  onClick,
});
