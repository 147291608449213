// Types
import { ProfilePersonalNameAliasEmptyBuilderReturnHandlersType } from './types/profile-personal-name-alias-empty-builder-return.handlers.type';
import { ProfilePersonalNameAliasEmptyBuilderHandlersType } from './types/profile-personal-name-alias-empty-builder.handlers.type';

const createNewAliasAssetClickHandler = ({
  handleCreateNewAliasAssetClickTracking,
  setEditMode,
}: ProfilePersonalNameAliasEmptyBuilderHandlersType): any => {
  handleCreateNewAliasAssetClickTracking();
  setEditMode(true);
};

const ProfilePersonalNameAliasEmptyHandlers = (
  props: ProfilePersonalNameAliasEmptyBuilderHandlersType
): ProfilePersonalNameAliasEmptyBuilderReturnHandlersType => ({
  handleCreateNewAliasAssetClick: () => createNewAliasAssetClickHandler(props),
});

export default ProfilePersonalNameAliasEmptyHandlers;
