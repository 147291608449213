// Vendors
import React from 'react';
import { useNavigate } from 'react-router-dom';
// Components
import { FinancingInsurancesRouterComponent } from './components/router/financing-insurances-router.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
// Configurations
import { getErrorLinkConfiguration } from 'configurations/app.configurations';
// Contexts
import {
  InsurancesContextConsumerHOC,
  useInsurancesContextConsumerHook,
} from 'contexts/insurances/insurances.context';
import { FinancingPPIContextConsumerHOC } from './contexts/financing-ppi.context';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { useFinancingPPIHook } from './hooks/financing-ppi.hook';
import useCbIntl from 'hooks/useCbIntl';

const FinancingPPIBaseView = (): React.ReactElement => {
  const { fetchError, fetching } = useInsurancesContextConsumerHook();
  const { formatMessage } = useCbIntl();
  const navigate = useNavigate();
  useFinancingPPIHook();

  return (
    <ErrorWrapperComponent
      fetching={fetching}
      error={fetchError}
      linkConfiguration={getErrorLinkConfiguration({
        formatMessage,
        route: RoutesEnumeration.FINANCING,
        navigate,
      })}
    >
      <FinancingInsurancesRouterComponent />
    </ErrorWrapperComponent>
  );
};

const FinancingPPIView = InsurancesContextConsumerHOC(
  FinancingPPIContextConsumerHOC(FinancingPPIBaseView)
);

export { FinancingPPIView };
