// Types
import { CardSettingsActivateCardSuccessHandlersType } from './types/card-settings-activate-card-success.handlers.type';
import { CardSettingsActivateCardSuccessReturnHandlersType } from './types/card-settings-activate-card-success-return.handlers.type';

const backHandler = ({
  handleBackLinkClickTracking,
  navigate,
  route,
}: CardSettingsActivateCardSuccessHandlersType) => {
  handleBackLinkClickTracking();
  navigate(route);
};

const CardSettingsActivateCardSuccessHandlers = (
  props: CardSettingsActivateCardSuccessHandlersType
): CardSettingsActivateCardSuccessReturnHandlersType => ({
  handleBackLinkClick: () => backHandler(props),
});

export default CardSettingsActivateCardSuccessHandlers;
