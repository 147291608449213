// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useReportSubscriptionAlreadyCancelledContextConsumerHook } from 'containers/transactions/views/report/components/subscription-already-cancelled/contexts/report-subscription-already-cancelled.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { ReportSubscriptionAlreadyCancelledConfirmationHookReturnType } from './types/report-subscription-already-cancelled-confirmation-component-hook-return.type';

export const useReportSubscriptionAlreadyCancelledConfirmationHook =
  (): ReportSubscriptionAlreadyCancelledConfirmationHookReturnType => {
    const { formatMessage } = useCbIntl();
    const { cancelledSubDateBefore, files } =
      useReportSubscriptionAlreadyCancelledContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    return {
      formatMessage,
      cancelledSubDateBefore,
      files,
      transactionId,
      cardId,
    };
  };
