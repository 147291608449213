// Enumerations
import { RequestSignatureKeyEnumeration } from './enumerations/request-signature-key.enumeration';
// Resolvers
import { verifySignatureKeyStatusResolver } from '../resolvers/request-signature-key.resolver';
// Types
import { RequestSignatureKeyBuilderHandlersType } from './types/request-signature-key.handlers.type';
import { RequestsignatureKeyBuilderReturnHandlresType } from './types/request-signature-key-return.handlers.type';

const verifySignatureKeyStatus = async ({
  setFetching,
  setIsAlreadyRequested,
  setErrorResponse,
}: RequestSignatureKeyBuilderHandlersType) => {
  setFetching(true);

  const [response] = await verifySignatureKeyStatusResolver();

  if (response?.message === RequestSignatureKeyEnumeration.SUCCESS) {
    setIsAlreadyRequested(false);
  } else {
    setIsAlreadyRequested(true);
    setErrorResponse(response || {});
  }

  setFetching(false);
};

const RequestSignatureKeyHandlers = ({
  setFetching,
  setIsAlreadyRequested,
  setErrorResponse,
}: RequestSignatureKeyBuilderHandlersType): RequestsignatureKeyBuilderReturnHandlresType => ({
  handleVerifySignatureKeyStatus: () => verifySignatureKeyStatus({ setFetching, setIsAlreadyRequested, setErrorResponse }),
});

export default RequestSignatureKeyHandlers;
