// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import RequestPortabilityStep3ActionsHook from './hooks/request-portability-step3-actions.hook';
// Translations
import translations from './translations/request-portability-step3-actions.translations';
// Utilities
import { requestPortabilityStep3NextButtonIsDisabled } from './utils/request-portability-step3-actions.utils';

const RequestPortabilityStep3ActionsComponent = (): any => {
  const { challenged, fetching, handleRequestPortabilityButtonClick, error } =
    RequestPortabilityStep3ActionsHook();

  return (
    <WizardStepFooterComponent
      isFooterDisabled={fetching}
      rightButtonConfiguration={{
        onClick: handleRequestPortabilityButtonClick,
        disabled: requestPortabilityStep3NextButtonIsDisabled({
          challenged,
          fetching,
          error,
        }),
        loading: fetching,
        children: <FormattedMessageComponent id={translations.CONFIRM} />,
      }}
    />
  );
};

export default RequestPortabilityStep3ActionsComponent;
