// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TransactionsLandingRowDetailsAmountBuilderReturnTrackingHandlersType } from './types/transactions-landing-row-details-amount-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const seeDetailsElementAmountLinkClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickLink',
      eventLabel: 'seeDetailsAmount',
    },
    type: TrackingEventsEnum.LINK,
  });

const TransactionsLandingRowDetailsAmountTrackingHandlers = (
  track: TrackBuilderType
): TransactionsLandingRowDetailsAmountBuilderReturnTrackingHandlersType => ({
  handleSeeDetailsElementAmountLinkClickTracking: () =>
    track(seeDetailsElementAmountLinkClickTrackingHandler()),
});

export default TransactionsLandingRowDetailsAmountTrackingHandlers;
