export default {
  SECURITY_KEY_ASSET_PROPS: {
    sizes: {
      height: 48,
      width: 48,
    },
    testId: 'security-key-chart',
    colors: {
      fill: 'brandB',
    },
  },
};
