// Constants
import {
  ASCENDING_DESCENDING_REGEX,
  CONSECUTIVE_NUMBERS_REGEX,
  EQUALS_3_OR_MORE_NUMBERS,
  ONLY_4_NUMBERS,
} from './constants/access-key-constraints.constants.utils';

const checkHasFourNumbersOnly = (value: string): boolean => Boolean(value.match(ONLY_4_NUMBERS));

const checkNoAscendingDescendingSequences = (value: string): boolean =>
  Boolean(value.match(ASCENDING_DESCENDING_REGEX));

const checkNoUse3EqualsNumbers = (value: string): boolean => !EQUALS_3_OR_MORE_NUMBERS.test(value);

const noContain3OrMoreConsecutiveAndEqualsNumbers = (value: string): boolean =>
  value.match(CONSECUTIVE_NUMBERS_REGEX) !== null;

export {
  checkHasFourNumbersOnly,
  checkNoAscendingDescendingSequences,
  checkNoUse3EqualsNumbers,
  noContain3OrMoreConsecutiveAndEqualsNumbers,
};
