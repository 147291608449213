// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
// Handlers
import { ChangeIbanStep1Handlers } from '../handlers/change-iban-step1.handlers';
// Types
import { ChangeIbanStep1HookType } from './types/change-iban-step1.hook.type';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';

const ChangeIbanStep1Hook = (): ChangeIbanStep1HookType => {
  const { formatMessage } = useCbIntl();
  const {
    checkboxValue,
    ibanValue,
    openSepaModal,
    sepaMandate,
    setCheckboxValue,
    setIbanValue,
    setOpenSepaModal,
  } = ChangeIbanContextConsumerHook();
  const { setCurrentStep } = useWizardContextConsumerHook();
  const [error, setError] = useState<boolean>(false);

  return {
    ...ChangeIbanStep1Handlers({
      setCheckboxValue,
      setOpenSepaModal,
      ibanValue,
      setError,
      setIbanValue,
      setCurrentStep,
    }),
    checkboxValue,
    error,
    formatMessage,
    ibanValue,
    openSepaModal,
    sepaMandate,
  };
};

export { ChangeIbanStep1Hook };
