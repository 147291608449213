// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const DashboardTableLinkComponentStyled = styled.div`
  border-top: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  padding-top: ${({ theme }) => theme.spaces.spacingS};
  text-align: right;
  width: 100%;
`;
DashboardTableLinkComponentStyled.displayName = 'DashboardTableLinkComponentStyled';

export { DashboardTableLinkComponentStyled };
