// Vendors
import React from 'react';
// Components
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import SettingsCardControlLocationsInfoComponent from './components/info/settings-card-control-locations-info.component';
import SettingsCardControlLocationsSearchComponent from './components/search/settings-card-control-locations-search.component';
import SettingsCardControlLocationsOrchestratorComponent from './components/orchestrator/settings-card-control-locations-orchestrator.component';
import SettingsCardControlLocationsMapComponent from './components/map/settings-card-control-locations-map.component';
import SettingsCardControlLocationsWarningComponent from './components/warning/settings-card-control-locations-warning.component';
// Contexts
import { SettingsCardControlLocationsContextConsumerHOC } from './contexts/settings-card-control-locations.context';
// Hooks
import SettingsCardControlLocationsHook from './hooks/settings-card-control-locations.hook';
// Styles
import {
  SettingsCardControlLocationsComponentStyled,
  SettingsCardControlLocationsContentComponentStyled,
  SettingsCardControlLocationsOverlayComponentStyled,
} from './settings-card-control-locations.component.styled';
// Utilities
import { locationsTabIsBlocked } from './utils/setting-card-control-locations.utils';

const SettingsCardControlLocationsComponent = (): React.ReactElement => {
  const { error, fetching, ...rest } = SettingsCardControlLocationsHook();

  return (
    <SettingsCardControlLocationsComponentStyled {...{ fetching }}>
      <SettingsCardControlLocationsWarningComponent />
      <ErrorWrapperComponent {...{ error, fetching }}>
        <SettingsCardControlLocationsContentComponentStyled>
          <SettingsCardControlLocationsOverlayComponentStyled show={locationsTabIsBlocked(rest)} />
          <SettingsCardControlLocationsMapComponent />
          <SettingsCardControlLocationsInfoComponent />
          <SettingsCardControlLocationsSearchComponent />
          <SettingsCardControlLocationsOrchestratorComponent />
        </SettingsCardControlLocationsContentComponentStyled>
      </ErrorWrapperComponent>
    </SettingsCardControlLocationsComponentStyled>
  );
};

export { SettingsCardControlLocationsComponent };

export default SettingsCardControlLocationsContextConsumerHOC(
  SettingsCardControlLocationsComponent
);
