// Vendors
import React from 'react';
// Components
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import { REQUEST_SIGNATURE_KEY_WIZARD_PROPS } from './constants/request-signature-key.constants';
// Hooks
import useRequestSignatureKeyWizardHook from './hooks/request-signature-key-wizard.hook';

const RequestSignatureKeyWizardComponent = (): React.ReactElement => {
  useRequestSignatureKeyWizardHook();

  return <WizardComponent {...REQUEST_SIGNATURE_KEY_WIZARD_PROPS} />;
};

export default RequestSignatureKeyWizardComponent;
