// Vendors
import { useNavigate } from 'react-router-dom';
// Event handlers
import LabelsLinkCardHandlers from '../handlers/labels-link-card.handlers';
import LabelsLinkCardTrackingHandlers from '../handlers/labels-link-card.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { LabelsLinkCardBuilderReturnHandlersType } from '../handlers/types/labels-link-card-builder-return.handlers.type';
import { LabelsLinkCardComponentType } from '../types/labels-link-card.component.type';

const LabelsLinkCardHook = (
  props: Pick<LabelsLinkCardComponentType, 'url' | 'onClick'>
): LabelsLinkCardBuilderReturnHandlersType =>
  LabelsLinkCardHandlers({
    ...props,
    ...AppTrackingHook(LabelsLinkCardTrackingHandlers),
    navigate: useNavigate(),
  });

export default LabelsLinkCardHook;
