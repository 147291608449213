const DOMAIN =
  'cobranded.services.userSettings.privateArea.transactions.cbTransactions.landing.cbTable.cbDetails.';

export default {
  SUBCATEGORY: `${DOMAIN}subcategoryLabel`,
  SUBCATEGORY_VALUE: 'category',
  EDIT_SUBCATEGORY: `${DOMAIN}editSubcategoryLink`,
  AUTHORIZED_CATEGORY: `${DOMAIN}authorizedStatus`,
  TO_BE_ASSIGNED: `${DOMAIN}toBeAssigned`,
};
