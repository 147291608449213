// Vendors
import React from 'react';
// Components
import FinancingLandingTableRowAmountComponent from './components/amount/financing-landing-table-row-amount.component';
import FinancingLandingTableRowTagComponent from './components/tag/financing-landing-table-row-tag.component';
import FinancingLandingRowDetailsComponent from './components/details/financing-landing-table-row-details.component';
import TableRowAssetDateComponent from 'components/table-row-asset-date/table-row-asset-date.component';
import TableRowComponent from 'components/table-row/table-row.component';
import TableRowDescriptionComponent from 'components/table-row-description/table-row-description.component';
// Styles
import {
  FinancingLandingTableRowComponentStyled,
  FinancingLandingTableRowLeftComponentStyled,
} from './financing-landing-table-row.component.styled';
// Types
import { FinancingLandingRowComponentType } from './types/financing-landing-row.component.type';

const FinancingLandingTableRowComponent = ({
  element,
  openDefault = false,
}: FinancingLandingRowComponentType): React.ReactElement<FinancingLandingRowComponentType> => {
  const {
    amortizationTable: { numOfInstallments, numOfPaidInstallments },
    currency,
    eppCreationDate: date,
    originalAmount,
    status,
    transactionInfo: {
      category: { categoryCode },
      description,
      narrative,
      merchantDetails,
      poEditorNarrative,
      reward,
    },
  } = element;

  return (
    <TableRowComponent
      {...{ element }}
      openDefault={openDefault}
      detailsComponent={FinancingLandingRowDetailsComponent}
    >
      <FinancingLandingTableRowComponentStyled id={element?.transactionId}>
        <FinancingLandingTableRowLeftComponentStyled>
          <TableRowAssetDateComponent {...{ categoryCode, date }} />
          <TableRowDescriptionComponent
            {...{ description, narrative, merchantDetails, poEditorNarrative, reward }}
          />
          <FinancingLandingTableRowTagComponent
            {...{ numOfInstallments, numOfPaidInstallments, status }}
          />
        </FinancingLandingTableRowLeftComponentStyled>
        <FinancingLandingTableRowAmountComponent {...{ currency, originalAmount }} />
      </FinancingLandingTableRowComponentStyled>
    </TableRowComponent>
  );
};

export default FinancingLandingTableRowComponent;
