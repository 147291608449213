export enum AvatarIdsEnumeration {
  AVATAR_1 = 'avatar1',
  AVATAR_2 = 'avatar2',
  AVATAR_3 = 'avatar3',
  AVATAR_4 = 'avatar4',
  AVATAR_5 = 'avatar5',
  AVATAR_6 = 'avatar6',
  AVATAR_7 = 'avatar7',
  AVATAR_8 = 'avatar8',
  AVATAR_9 = 'avatar9',
  AVATAR_10 = 'avatar10',
  AVATAR_11 = 'avatar11',
  AVATAR_12 = 'avatar12',
  AVATAR_13 = 'avatar13',
  AVATAR_14 = 'avatar14',
  AVATAR_15 = 'avatar15',
  AVATAR_16 = 'avatar16',
  AVATAR_17 = 'avatar17',
  AVATAR_18 = 'avatar18',
  AVATAR_19 = 'avatar19',
  AVATAR_20 = 'avatar20',
}
