const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.cardStatements.cbDetails.';

const CONSUMPTION_EXPENSES_MONTH_COURSE = `${DOMAIN}consumptionExpensesMonthCourse`,
  EXPENSES_CONSUMPTION = `${DOMAIN}expensesConsumption`,
  PURCHASES_DEFERRED_PAYMENTS = `${DOMAIN}purchasesDeferredPayments`,
  TOTAL_INTEREST = `${DOMAIN}totalInterest`;

export {
  CONSUMPTION_EXPENSES_MONTH_COURSE,
  EXPENSES_CONSUMPTION,
  PURCHASES_DEFERRED_PAYMENTS,
  TOTAL_INTEREST,
};
