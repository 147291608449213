// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import sectionsTranslations from 'translations/sections.translations';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const ORCHESTRATOR_HEADER_NAV_ITEMS = [
  {
    icon: 'symbols_dashboard',
    label: sectionsTranslations[RoutesEnumeration.DASHBOARD],
    href: RoutesEnumeration.DASHBOARD,
    testId: 'dashboard',
  },
  {
    icon: 'simple_transfer',
    label: sectionsTranslations[RoutesEnumeration.TRANSACTIONS],
    href: RoutesEnumeration.TRANSACTIONS,
    testId: 'transactions',
  },
  {
    icon: 'slice_it',
    label: sectionsTranslations[RoutesEnumeration.FINANCING],
    href: RoutesEnumeration.FINANCING,
    testId: 'financing',
  },
  {
    icon: 'simple_card',
    label: sectionsTranslations[RoutesEnumeration.CARD_SETTINGS],
    href: RoutesEnumeration.CARD_SETTINGS,
    testId: 'card_settings',
  },
];

const ORCHESTRATOR_HEADER_AVATAR_PROPS = {
  sizes: {
    height: 40,
    width: 40,
  },
};

const ORCHESTRATOR_HEADER_MAIN_ROUTE = generateAbsoluteRoute(RoutesEnumeration.DASHBOARD);

export {
  ORCHESTRATOR_HEADER_AVATAR_PROPS,
  ORCHESTRATOR_HEADER_NAV_ITEMS,
  ORCHESTRATOR_HEADER_MAIN_ROUTE,
};
