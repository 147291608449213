// Vendors
import React from 'react';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  DEVICES_SIDEBAR_PROPS,
  DEVICES_SIDEBAR_PROPS_MESSAGE_01,
  DEVICES_SIDEBAR_PROPS_MESSAGE_02,
  DEVICES_SIDEBAR_PROPS_MESSAGE_03,
  DEVICES_SIDEBAR_PROPS_MESSAGE_04,
} from './constants/sidebar.constants';
// Styles
import {
  SecurityKeysSidebarComponentStyled,
  SecurityKeysSidebarItemComponentStyled,
} from 'containers/security-keys/components/sidebar/security-keys-sidebar.component.styled';
// Translations
import {
  TITLE,
  PARAGRAPH_1,
  PARAGRAPH_2,
  PARAGRAPH_3,
  PARAGRAPH_4,
} from './translations/sidebar.translations';

const DevicesManagerSidebarComponent = (): React.ReactElement => (
  <SidebarComponent {...DEVICES_SIDEBAR_PROPS} title={<FormattedMessageComponent id={TITLE} />}>
    <SecurityKeysSidebarComponentStyled>
      <SecurityKeysSidebarItemComponentStyled>
        <ParagraphComponent {...DEVICES_SIDEBAR_PROPS_MESSAGE_01}>
          <FormattedMessageComponent id={PARAGRAPH_1} />
        </ParagraphComponent>
      </SecurityKeysSidebarItemComponentStyled>
      <SecurityKeysSidebarItemComponentStyled>
        <ParagraphComponent {...DEVICES_SIDEBAR_PROPS_MESSAGE_02}>
          <FormattedMessageComponent id={PARAGRAPH_2} />
        </ParagraphComponent>
      </SecurityKeysSidebarItemComponentStyled>
      <SecurityKeysSidebarItemComponentStyled>
        <ParagraphComponent {...DEVICES_SIDEBAR_PROPS_MESSAGE_03}>
          <FormattedMessageComponent id={PARAGRAPH_3} />
        </ParagraphComponent>
      </SecurityKeysSidebarItemComponentStyled>
      <SecurityKeysSidebarItemComponentStyled>
        <ParagraphComponent {...DEVICES_SIDEBAR_PROPS_MESSAGE_04}>
          <FormattedMessageComponent id={PARAGRAPH_4} />
        </ParagraphComponent>
      </SecurityKeysSidebarItemComponentStyled>
    </SecurityKeysSidebarComponentStyled>
  </SidebarComponent>
);

export { DevicesManagerSidebarComponent };
