export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_PROPS: {
    testId: 'card-status-tag-message',
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_TITLE_PROPS: {
    bold: true,
    testId: 'card-status-tag-title-section',
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_DESCRIPTION_PROPS: {
    testId: 'card-status-tag-table-row-description',
  },
};
