const REGEX_CLEANER = /^\+/;
const FORMATER_CLEANER_LAYOUT = /(\d)(\d{2})(\d{3})(\d)(\d{3})/;
const FORMATER_CLEANER_TEMPLATE = '$1** *** $4$5';
const PREFIX_PLUS = '+';
const MOCKED_RESULT = 'Invalid input.';

export {
  PREFIX_PLUS,
  REGEX_CLEANER,
  FORMATER_CLEANER_LAYOUT,
  FORMATER_CLEANER_TEMPLATE,
  MOCKED_RESULT,
};
