// Vendors
import React from 'react';
// Components
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import WizardComponent from 'components/wizard/wizard.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import ProfileConsentsSidebarComponent from './components/sidebar/profile-consents-sidebar.component';
// Constants
import { CONSENTS_WIZARD_PROPS } from './constants/profile-consents.constants';
// Contexts
import { ProfileConsentsContextConsumerHOC } from './contexts/profile-consents.context';
// Hooks
import useProfileConsentsHook from './hooks/profile-consents.hook';

const ProfileConsentsView = (): React.ReactElement => {
  const { fetching, error } = useProfileConsentsHook();

  return (
    <ErrorWrapperComponent {...{ fetching, error }}>
      <OperativeLayoutComponent sidebarComponent={ProfileConsentsSidebarComponent}>
        <WizardComponent {...CONSENTS_WIZARD_PROPS} />
      </OperativeLayoutComponent>
    </ErrorWrapperComponent>
  );
};

export { ProfileConsentsView };
export default ProfileConsentsContextConsumerHOC(ProfileConsentsView);
