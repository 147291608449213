// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  NOTIFICATIONS_SETTINGS_FOOTER_BUTTON_PROPS,
  NOTIFICATIONS_SETTINGS_FOOTER_LINK_PROPS,
} from './constants/notifications-settings-footer.constants';
// Syles
import { NotificationSettingsFooterComponentStyled } from './notifications-settings-footer.component.styled';
// Translations
import { LEFT_LINK_TITLE, RIGHT_BUTTON_TITLE } from './translations/settings-footer.translations';
// Types
import { NotificationSettingsFooterComponentPropsType } from './types/notifications-settings-footer.component.type';
// Utils
import { hasInputCurrencyErrors } from '../../utils/notifications-settings.view.utils';

const NotificationSettingsFooterComponent = ({
  handleGoBackButtonClick,
  handleSaveChangesClick,
  hasChangedSettings,
  notificationsSettings,
  saving,
}: NotificationSettingsFooterComponentPropsType): React.ReactElement => {
  return (
    <NotificationSettingsFooterComponentStyled>
      <NavigationFooterComponent
        leftLinkConfiguration={{
          ...NOTIFICATIONS_SETTINGS_FOOTER_LINK_PROPS,
          children: <FormattedMessageComponent id={LEFT_LINK_TITLE} />,
          onClick: handleGoBackButtonClick,
        }}
        rightButtonConfiguration={{
          ...NOTIFICATIONS_SETTINGS_FOOTER_BUTTON_PROPS,
          children: <FormattedMessageComponent id={RIGHT_BUTTON_TITLE} />,
          disabled: hasInputCurrencyErrors({ notificationsSettings }) || !hasChangedSettings,
          loading: saving,
          onClick: handleSaveChangesClick,
        }}
      />
    </NotificationSettingsFooterComponentStyled>
  );
};

export { NotificationSettingsFooterComponent };
