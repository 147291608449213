// Constants
import { INVALID_DATE_ERROR_MESSAGE } from 'containers/transactions/views/report/translations/transactions-report.translations';
import { DATE_CONTROLLER_NAME } from '../constants/transactions-report-not-received-additional-info-inputs.constants';
// Translations
import {
  AFTER_DATE_ERROR_MESSAGE,
  TODAY_OR_LATER_DATE_ERROR_MESSAGE,
} from '../translations/transactions-report-not-received-additional-info-inputs.translations';
// Types
import { TransactionsReportNotReceivedAdditionalInfoInputsComponentType } from '../types/transactions-report-not-received-additional-info-inputs.component.type';

const validateDate = (value: string, maxDate: string): boolean | string => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0, 0);
  const isDateBeforeMaxDate = new Date(maxDate) < new Date(value);
  const isDateAfterToday = new Date(value) < todayDate;

  if (!isDateBeforeMaxDate) {
    return AFTER_DATE_ERROR_MESSAGE;
  }
  if (!isDateAfterToday) {
    return TODAY_OR_LATER_DATE_ERROR_MESSAGE;
  }

  return true;
};

export { validateDate };
