// Services
import { fetchDownloadCardStatementDocumentService } from '../services/card-statement-table-row-download.service';
// Types
import { DownloadCardStatementsUrlServiceType } from '../services/types/card-statement-download-url.service.type';
import { CardStatementTableRowDownloadComponentType } from '../types/card-statement-table-row-download.component.type';

const fetchDownloadCardStatementDocumentResolver = async (
  props: CardStatementTableRowDownloadComponentType
): Promise<[DownloadCardStatementsUrlServiceType | Record<string, any>] | [null, true]> => {
  const [response] = await fetchDownloadCardStatementDocumentService(props);

  return response?.documentUri ? [response] : [null, true];
};

export { fetchDownloadCardStatementDocumentResolver };
