// Vendors
import { useState } from 'react';
// Contexts
import { useCardSettingsCheck3DWizardContextConsumerHook } from 'containers/card-settings/views/check-3d/components/wizard/contexts/card-settings-check-3d-wizard.context';
import { useCardSettingsCheck3DContextConsumerHook } from 'containers/card-settings/views/check-3d/contexts/card-settings-check-3d.context';
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import CardSettingsCheck3dWizardStep2Handlers from '../handlers/card-settings-check-3d-wizard-step2.handlers';
import CardSettingsCheck3dWizardStep2ActionsTrackingHandlers from '../handlers/card-settings-check-3d-wizard-step2-actions.tracking.handlers';
import { ChallengeRetryHandlers } from 'handlers/challenge/challenge-retry.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsCheck3dWizardStep2ActionsHookType } from './types/card-settings-check-3d-wizard-step2-actions.hook.type';

const useCardSettingsCheck3dWizardStep2ActionsHook =
  (): CardSettingsCheck3dWizardStep2ActionsHookType => {
    const [challengeError, setChallengeError] = useState(false);

    const { setHasChallenged, setScaCode } = useCardSettingsCheck3DContextConsumerHook(),
      { progressId, setFetching, setProgressId } =
        useCardSettingsCheck3DWizardContextConsumerHook(),
      { setCurrentStep } = useWizardContextConsumerHook(),
      { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...CardSettingsCheck3dWizardStep2Handlers({
        ...AppTrackingHook(CardSettingsCheck3dWizardStep2ActionsTrackingHandlers),
        progressId,
        setChallengeError,
        setCurrentStep,
        setFetching,
        setHasChallenged,
        setProgressId,
        setResult,
        setScaCode,
      }),
      ...ChallengeRetryHandlers({
        setChallengeError,
      }),
      challengeError,
      progressId,
    };
  };

export default useCardSettingsCheck3dWizardStep2ActionsHook;
