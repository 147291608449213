// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Enumerations
import { EppElementStatusEnumeration } from '../enumerations/epp-element-status.enumeration';

const FINANCING_LANDING_TABLE_ROW_TAG_PROPS = {
  testId: 'financing-landing-table-row-tag',
};

const COMPLETED_STATUS = [
  EppElementStatusEnumeration.CANCELLED,
  EppElementStatusEnumeration.CLOSED,
  EppElementStatusEnumeration.RETURNED,
];

const IN_PROGRESS_STATUS = [
  EppElementStatusEnumeration.CREATED,
  EppElementStatusEnumeration.REQUESTED,
  EppElementStatusEnumeration.PARTIAL_RETURNED,
];

const FINANCING_LANDING_TABLE_ROW_TAG_TYPES = {
  ...Object.fromEntries(IN_PROGRESS_STATUS.map(status => [status, tagConstants.TYPES.ACTION])),
  ...Object.fromEntries(COMPLETED_STATUS.map(status => [status, tagConstants.TYPES.SUCCESS])),
};

export {
  COMPLETED_STATUS,
  FINANCING_LANDING_TABLE_ROW_TAG_PROPS,
  FINANCING_LANDING_TABLE_ROW_TAG_TYPES,
  IN_PROGRESS_STATUS,
};
