// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { OrderModalTrackingHandlersPropsType } from './types/oder-modal.tracking.type';

const handlerViewDetailTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'viewDetailLink',
  },
  type: TrackingEventsEnum.LINK,
});

const OrderModalTrackingHandlers = (
  track: TrackBuilderType
): OrderModalTrackingHandlersPropsType => ({
  handleViewDetailClickTracking: () => track(handlerViewDetailTrackingHandler()),
});

export { OrderModalTrackingHandlers };
