// Vendors
import { useEffect } from 'react';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Handlers
import FinancingFastCashAmountHandlers from '../handlers/amount.handlers';
// Hooks
import { FinancingFastCashAmountCommonHook } from './amount.hook.common';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Tracking
import AmountTrackingHandlers from '../handlers/amount.handlers.tracking';
// Types
import { FinancingFastCashAmountHookType } from './types/amount.hook.type';

const FinancingFastCashAmountHook = (): FinancingFastCashAmountHookType => {
  const {
    amountValue,
    fastCashLimits,
    fastCashCommission,
    formatMessage,
    formatNumber,
    inputErrorMsg,
    setAmountValue,
    setDisableNextBtn,
    setInputErrorMsg,
  } = FinancingFastCashAmountCommonHook();

  const { minAmount = 0, maxAmount = 0 } = fastCashLimits || {};

  useEffect(() => {
    setDisableNextBtn(!amountValue || !!inputErrorMsg);
  }, [amountValue, inputErrorMsg, setDisableNextBtn]);

  return {
    maxAmount: formatNumber(maxAmount, {
      currency: fastCashLimits?.currencyCode,
      ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    }),
    minAmount: formatNumber(minAmount, {
      currency: fastCashLimits?.currencyCode,
      ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
    }),
    amountValue,
    fastCashLimits,
    fastCashCommission,
    formatMessage,
    formatNumber,
    inputErrorMsg,
    setAmountValue,
    setDisableNextBtn,
    setInputErrorMsg,
    ...AppTrackingHook(AmountTrackingHandlers),
    ...FinancingFastCashAmountHandlers({ minAmount, maxAmount, setAmountValue, setInputErrorMsg }),
  };
};

export default FinancingFastCashAmountHook;
