const DOMAIN = 'cobranded.services.financing.creditLimitIncrease.step6.';

export const step6Translations = {
  TITLE: `${DOMAIN}title`,
  SUBTITLE: `${DOMAIN}subtitle`,
  FIRST_SECTION_TITLE: `${DOMAIN}firstSectionTitle`,
  FIRST_SECTION_TEXT: `${DOMAIN}firstSectionText`,
  SECOND_SECTION_TITLE: `${DOMAIN}secondSectionTitle`,
  SECOND_SECTION_TEXT: `${DOMAIN}secondSectionText`,
  WEB_ID_DISCLAIMER: `${DOMAIN}webIdDisclaimer`,
  NEXT: `${DOMAIN}next`,
};
