// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { IVR_COMPONENT_CONSTANTS_CC } from 'components/ivr-cc/constants/ivr-component-wapper-cc.constants';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Styles
import {
  IvrWrapperCcTextStyled,
  IvrWrapperLastTextCcStyled,
} from 'components/ivr-cc/ivr-component-wrapper-cc.component.styled';
// Types
import { IvrComponentWrapperCCPropsType } from 'components/ivr-cc/types/ivr-component-wrapper-cc.component.type';

const IvrComponentCcComponentFinalText = ({
  text,
}: Pick<IvrComponentWrapperCCPropsType, 'text'>): React.ReactElement => {
  const { profile } = AppContextConsumerHook();

  return (
    <>
      <IvrWrapperCcTextStyled data-testid={IVR_COMPONENT_CONSTANTS_CC.thirdTextId}>
        {text.thirdText && (
          <ParagraphComponent>
            <FormattedMessageComponent id={text.thirdText} />
          </ParagraphComponent>
        )}
        {text.lastText && (
          <ParagraphComponent bold>
            <FormattedMessageComponent
              id={text.lastText}
              values={{ name: profile?.userDetails?.firstName }}
            />
          </ParagraphComponent>
        )}
      </IvrWrapperCcTextStyled>
      {text.fourthText && (
        <IvrWrapperLastTextCcStyled>
          <ParagraphComponent testId={IVR_COMPONENT_CONSTANTS_CC.fourthTextId}>
            <FormattedMessageComponent id={text.fourthText} />
          </ParagraphComponent>
        </IvrWrapperLastTextCcStyled>
      )}
    </>
  );
};

export default IvrComponentCcComponentFinalText;
