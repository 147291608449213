// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Contexts
import { PersonalEmailStep2ContextConsumerHOC } from './contexts/personal-email-step2.context';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import PersonalEmailNewEmailComponent from '../new-email/personal-email-new-email.component';
import PersonalEmailStep2ActionsComponent from './components/actions/personal-email-step2-actions.component';
import PersonalEmailStep2ChallengeComponent from './components/challenge/personal-email-step2-challenge.component';
// Constants
import constants from './constants/personal-email-step2.constants';
// Hooks
import PersonalEmailStep2Hook from './hooks/personal-email-step2.hook';
// Styles
import { PersonalEmailStepLayoutViewStyled } from '../../personal-email.view.styled';
// Translations
import * as translations from './translations/personal-email-step2.translations';

const PersonalEmailStep2Component = (): React.ReactElement => {
  const { handleRequestNewEmailOtpLinkClick } = PersonalEmailStep2Hook();

  return (
    <PersonalEmailStepLayoutViewStyled>
      <ParagraphComponent {...constants.PERSONAL_EMAIL_STEP_2_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={translations.DESCRIPTION} />
      </ParagraphComponent>
      <PersonalEmailNewEmailComponent />
      <PersonalEmailStep2ChallengeComponent onRetry={handleRequestNewEmailOtpLinkClick} />
      <PersonalEmailStep2ActionsComponent />
    </PersonalEmailStepLayoutViewStyled>
  );
};

export { PersonalEmailStep2Component };

export default PersonalEmailStep2ContextConsumerHOC(PersonalEmailStep2Component);
