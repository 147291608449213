// Translations
import { ADDRESS_BOX_TITLE } from './request-signature-key-step1.translations.common';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.step1.';

const TITLE = `${DOMAIN}title`,
  MESSAGE_01 = `${DOMAIN}message01`,
  MESSAGE_03 = `${DOMAIN}message03`,
  TRUSTED_TITLE = `${DOMAIN}trustedTitle`,
  TRUSTED_DESCRIPTION = `${DOMAIN}trustedDescription`;

export { ADDRESS_BOX_TITLE, MESSAGE_01, MESSAGE_03, TITLE, TRUSTED_DESCRIPTION, TRUSTED_TITLE };
