// Vendors
import styled from 'styled-components';
// Utils
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

export const FinancingEarlyRepaymentSidebarComponentStyled = styled.div`
  width: ${convertPxsToRems({ pixels: 276, base: 14 })};

  ${mobileAndTabletMediaQuery} {
    width: 100%;
  }

  ${tabletMediaQuery} {
    margin-bottom: ${({ theme }) => theme.spaces.spacingL};
  }

  ${mobileMediaQuery} {
    margin-bottom: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const ExpandableRowContentStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  margin-bottom: ${({ theme }) => theme.spaces.spacingXxs};
`;
