// Constants
import { assetConstants, titleConstants } from '@openbank/cb-ui-commons';
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const DEFAULT_INDEX = 4,
  INACTIVITY_MODAL_ASSET_PROPS = {
    extension: assetConstants.EXTENSIONS.GIF,
    id: 'session-about-to-expire-es',
    sizes: {
      height: 180,
      width: 180,
    },
    type: assetConstants.TYPES.IMAGE,
    testId: 'inactivity-modal-asset',
  },
  INACTIVITY_MODAL_SECONDS_PROPS = {
    testId: 'inactivity-modal-seconds',
    tag: titleConstants.TAGS.H3,
  },
  INACTIVITY_MODAL_TITLE_PROPS = {
    bold: true,
    testId: 'inactivity-modal-title',
  },
  INACTIVITY_MODAL_DESCRIPTION_PROPS = {
    testId: 'inactivity-modal-description',
  },
  INACTIVITY_MODAL_LAYOUT_PROPS = {
    align: 'center',
  },
  INACTIVITY_MODAL_LAYOUT_CONTENT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingXxs,
  },
  INACTIVITY_MODAL_LINK_PROPS = {
    testId: 'inactivity-modal-link',
  },
  INACTIVITY_MODAL_BUTTON_PROPS = {
    testId: 'inactivity-modal-button',
  };

export {
  DEFAULT_INDEX,
  INACTIVITY_MODAL_LAYOUT_PROPS,
  INACTIVITY_MODAL_ASSET_PROPS,
  INACTIVITY_MODAL_BUTTON_PROPS,
  INACTIVITY_MODAL_DESCRIPTION_PROPS,
  INACTIVITY_MODAL_LAYOUT_CONTENT_PROPS,
  INACTIVITY_MODAL_LINK_PROPS,
  INACTIVITY_MODAL_SECONDS_PROPS,
  INACTIVITY_MODAL_TITLE_PROPS,
};
