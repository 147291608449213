// Constants
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/not-received/contexts/constants/transactions-report-not-received.context.constants';
import { NUMBER } from 'constants/number.constants';
// Types
import { TransactionsReportNotRecievedDocumentsActionsHandlersPropsType } from './types/transactions-report-not-received-documents-actions-handlers-props.type';
import { TransactionsReportNotRecievedDocumentsActionsHandlersReturnType } from './types/transactions-report-not-received-documents-actions-handlers-return.type';

const goBackHandler = ({
  setFiles,
  setCurrentStep,
}: TransactionsReportNotRecievedDocumentsActionsHandlersPropsType): void => {
  setCurrentStep(NUMBER.ONE);
  setFiles(FILES_INITIAL_STATE);
};

const TransactionsReportNotReceivedDocumentsActionsHandlers = ({
  setFiles,
  setCurrentStep,
}: TransactionsReportNotRecievedDocumentsActionsHandlersPropsType): TransactionsReportNotRecievedDocumentsActionsHandlersReturnType => ({
  handleGoBack: () => goBackHandler({ setFiles, setCurrentStep }),
});

export { TransactionsReportNotReceivedDocumentsActionsHandlers };
