// Vendors
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Constants
import {
  CHARGED_SERVICE_CANCELLED_ROUTE_PROPS,
  DEFECTIVE_PRODUCT_ROUTE_PROPS,
  DIFFERENT_AMOUNT_ATM_ROUTE_PROPS,
  DUPLICATED_CHARGE_ROUTE_PROPS,
  LANDING_ROUTE_PROPS,
  NOT_RECEIVED_PRODUCT_ROUTE_PROPS,
  NOT_RECOGNIZE_CHARGE_ROUTE_PROPS,
  PAYMENT_OTHER_MEANS_ROUTE_PROPS,
  SUBSCRIPTION_PASSED_ROUTE_PROPS,
} from './constants/transactions-report-router.constants';
import {
  ANY_NAVIGATE_ROUTE_PROPS,
  ANY_ROUTE_PROPS,
} from 'containers/orchestrator/components/router/components/containers/constants/orchestrator-router-containers.constants';
// Lazy components
import {
  ReportDefectiveContainer,
  ReportSubscriptionAlreadyCancelledComponent,
  TransactionsReportDifferentAmountAtmComponent,
  TransactionsReportDuplicateChargeComponent,
  TransactionsReportFraudulentMovementComponent,
  TransactionsReportLandingComponent,
  TransactionsReportNotReceivedComponent,
  TransactionsReportPaymentComponent,
  TransactionsReportServiceCancelledComponent,
} from './lazy-components/transactions-report-router.lazy-components';

export const TransactionsReportRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route
      {...NOT_RECOGNIZE_CHARGE_ROUTE_PROPS}
      element={<TransactionsReportFraudulentMovementComponent />}
    />
    <Route
      {...DUPLICATED_CHARGE_ROUTE_PROPS}
      element={<TransactionsReportDuplicateChargeComponent />}
    />
    <Route
      {...DIFFERENT_AMOUNT_ATM_ROUTE_PROPS}
      element={<TransactionsReportDifferentAmountAtmComponent />}
    />
    <Route {...DEFECTIVE_PRODUCT_ROUTE_PROPS} element={<ReportDefectiveContainer />} />
    <Route
      {...CHARGED_SERVICE_CANCELLED_ROUTE_PROPS}
      element={<TransactionsReportServiceCancelledComponent />}
    />
    <Route
      {...SUBSCRIPTION_PASSED_ROUTE_PROPS}
      element={<ReportSubscriptionAlreadyCancelledComponent />}
    />
    <Route
      {...NOT_RECEIVED_PRODUCT_ROUTE_PROPS}
      element={<TransactionsReportNotReceivedComponent />}
    />
    <Route {...PAYMENT_OTHER_MEANS_ROUTE_PROPS} element={<TransactionsReportPaymentComponent />} />
    <Route {...LANDING_ROUTE_PROPS} element={<TransactionsReportLandingComponent />} />
    <Route {...ANY_ROUTE_PROPS} element={<Navigate {...ANY_NAVIGATE_ROUTE_PROPS} />} />
  </Routes>
);
