// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { OrchestratorRouterContainerErrorTrackingHandlersType } from 'containers/orchestrator/components/router/components/containers/components/error-load/handlers/types/orchestrator-router-container-error.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const reloadAndClearPageErrorButtonClickTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'tryAgain',
  },
  type: TrackingEventsEnum.LINK,
});

const OrchestratorRouterContainerErrorTrackingHandlers = (
  track: TrackBuilderType
): OrchestratorRouterContainerErrorTrackingHandlersType => ({
  handleReloadAndClearPageErrorButtonClickTracking: () =>
    track(reloadAndClearPageErrorButtonClickTracking()),
});

export default OrchestratorRouterContainerErrorTrackingHandlers;
