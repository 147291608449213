// Vendors
import { Usd } from '@openbank/cf-ui-framework';
// Constants
import { SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS_CC } from 'containers/security-keys/views/signature-key-change/constants/security-keys-signature-key-change.constants.cc';
// Translations
import {
  CHANGE_SIGNATURE_KEY_CC_FIRST_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_FIRST_TEXT,
  CHANGE_SIGNATURE_KEY_CC_FOURTH_TEXT,
  CHANGE_SIGNATURE_KEY_CC_INITIAL_TEXT,
  CHANGE_SIGNATURE_KEY_CC_LAST_TEXT,
  CHANGE_SIGNATURE_KEY_CC_SECOND_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_SECOND_TEXT,
  CHANGE_SIGNATURE_KEY_CC_THIRD_CONSTRAINTS,
  CHANGE_SIGNATURE_KEY_CC_THIRD_TEXT,
  CHANGE_SIGNATURE_KEY_CC_TITLE,
} from '../../../translations/security-keys-signature-key-change-card.translations.cc';

const SECURITY_KEYS_SIGNATURE_CHANGE_CONSTANTS = {
  haveConstraints: true,
  ivrFeedback: SIGNATURE_KEY_CHANGE_FEEDBACK_PROPS_CC,
  initiatorIvr: Usd()?.changeSignatureKeyInIvr,
  resultIvr: Usd()?.getSignatureKeyChanged,
  text: {
    firstConstraint: CHANGE_SIGNATURE_KEY_CC_FIRST_CONSTRAINTS,
    firstText: CHANGE_SIGNATURE_KEY_CC_FIRST_TEXT,
    fourthText: CHANGE_SIGNATURE_KEY_CC_FOURTH_TEXT,
    initialText: CHANGE_SIGNATURE_KEY_CC_INITIAL_TEXT,
    lastText: CHANGE_SIGNATURE_KEY_CC_LAST_TEXT,
    secondConstraint: CHANGE_SIGNATURE_KEY_CC_SECOND_CONSTRAINTS,
    secondText: CHANGE_SIGNATURE_KEY_CC_SECOND_TEXT,
    thirdConstraint: CHANGE_SIGNATURE_KEY_CC_THIRD_CONSTRAINTS,
    thirdText: CHANGE_SIGNATURE_KEY_CC_THIRD_TEXT,
    title: CHANGE_SIGNATURE_KEY_CC_TITLE,
  },
};

export default SECURITY_KEYS_SIGNATURE_CHANGE_CONSTANTS;
