// Vendors
import styled from 'styled-components';

export const SettingsCardControlOperationsAtmComponentStyled = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const SettingsCardControlOperationsAtmLeftComponentStyled = styled(
  SettingsCardControlOperationsAtmComponentStyled
)`
  flex: 1;
`;

export const SettingsCardControlOperationsContentAtmComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxs};
`;
