// Services
import { toggleNonSecureStoresService } from '../services/card-settings-payment-modal.service';
// Types
import { CardSettingsPaymentModalApiBuilderType } from '../types/card-settings-payment-modal-api-builder.type';
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const toggleNonSecureStoresResolver = async (
  props: CardSettingsPaymentModalApiBuilderType
): Promise<[number] | [null, true]> => {
  const [, { status }] = await toggleNonSecureStoresService(props);

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

export { toggleNonSecureStoresResolver };
