// Constants
import {
  BENEFITS_LANDING_TAG_CANCELLED_PROPS,
  BENEFITS_LANDING_TAG_CONTRACTED_PROPS,
  BENEFITS_LANDING_TAG_COVERED_PROPS,
} from '../../constants/card-settings-benefits.constants';
// Types
import { CardSettingsBenefitsTravelPlusTagConfigurationReturnType } from './types/card-settings-benefits-travel-plus-tag-return.configuration.type';
import { CardSettingsBenefitsTravelPlusTagConfigurationPropsType } from './types/card-settings-benefits-travel-plus-tag.configuration.type';

const getBenefitsLandingTagContractConfiguration = ({
  formatMessage,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusTagConfigurationPropsType): CardSettingsBenefitsTravelPlusTagConfigurationReturnType =>
  !travelPlusInfo?.status && {
    ...BENEFITS_LANDING_TAG_CANCELLED_PROPS,
    children: formatMessage({ id: BENEFITS_LANDING_TAG_CANCELLED_PROPS.description }),
  };

const getBenefitsLandingTagCancelConfiguration = ({
  formatMessage,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusTagConfigurationPropsType): CardSettingsBenefitsTravelPlusTagConfigurationReturnType =>
  travelPlusInfo?.status && {
    ...BENEFITS_LANDING_TAG_CONTRACTED_PROPS,
    children: formatMessage({ id: BENEFITS_LANDING_TAG_CONTRACTED_PROPS.description }),
  };

const getBenefitsLandingTagCoveredConfiguration = ({
  formatMessage,
  travelPlusInfo,
}: CardSettingsBenefitsTravelPlusTagConfigurationPropsType): CardSettingsBenefitsTravelPlusTagConfigurationReturnType =>
  !travelPlusInfo?.status &&
  travelPlusInfo?.expirationDate && {
    ...BENEFITS_LANDING_TAG_COVERED_PROPS,
    children: formatMessage({ id: BENEFITS_LANDING_TAG_COVERED_PROPS.description }),
  };

const getBenefitsLandingTravelPlusTagConfiguration = (
  props: CardSettingsBenefitsTravelPlusTagConfigurationPropsType
): CardSettingsBenefitsTravelPlusTagConfigurationReturnType =>
  getBenefitsLandingTagCoveredConfiguration(props) ||
  getBenefitsLandingTagContractConfiguration(props) ||
  getBenefitsLandingTagCancelConfiguration(props) ||
  {};

export { getBenefitsLandingTravelPlusTagConfiguration };
