// Constants
import constants from '../services/constants/support-cancel-contract-second-step-challenge.service.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { SupportCancelContractSecondStepChallengeWrapperPropsComponentType } from './types/support-cancel-contract-second-step.challenge.configurations.type';

export const getChallengeConfiguration = ({
  cardContractId,
  reason,
  handleChallengeSuccess,
}: SupportCancelContractSecondStepChallengeWrapperPropsComponentType): ChallengeWrapperComponentType => ({
  baseService: constants.COMPLETE_CANCEL_CONTRACT_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: {
    params: { cancellationReason: reason },
    pathParams: { cardContractId },
  },
  baseServiceMethod: constants.COMPLETE_CANCEL_CONTRACT_OTP_SERVICE_CONFIGURATION.method,
  onError: () => null,
  onSuccess: handleChallengeSuccess,
});
