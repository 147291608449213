// Translations
import optionsTranslations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title-dropdown-options.translations';
import translations from 'containers/personal/views/landing/components/name/components/title/translations/profile-personal-name-title.translations';
// Types
import { ProfilePersonalNameTitleUtilsType } from './types/profile-personal-name-title.utils.type';
import { ProfilePersonalNameTitleUtilsNotEditingType } from './types/profile-personal-name-title-not-editing.utils.type';

const getDefaultOrTranslatedTitle = ({
  formatMessage,
  title,
}: ProfilePersonalNameTitleUtilsType): string =>
  title
    ? formatMessage({ id: optionsTranslations[String(title)] })
    : formatMessage({ id: translations.NO_TITLE });

const isNotEditingAndNoTitle = ({
  title,
  editTitle,
}: ProfilePersonalNameTitleUtilsNotEditingType): boolean => Boolean(!title && !editTitle);

export { getDefaultOrTranslatedTitle, isNotEditingAndNoTitle };
