// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { PathsCommonEnum } from '@openbank/cf-ui-static-data';
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const ACCESS_KEY_PATH = generateAbsoluteRoute(generatePathRoute([
  RoutesEnumeration.SECURITY,
  RoutesEnumeration.ACCESS_KEY
]));

const ACCESS_KEY_ROUTE_PROPS = {
  path: ACCESS_KEY_PATH
};

const ANY_PROSPECT_ROUTE_PROPS = {
  path: PathsCommonEnum.ANY,
};

const ANY_PROSPECT_NAVIGATE_ROUTE_PROPS = {
  replace: true,
  to: ACCESS_KEY_PATH
};

export {
  ACCESS_KEY_ROUTE_PROPS,
  ANY_PROSPECT_ROUTE_PROPS,
  ANY_PROSPECT_NAVIGATE_ROUTE_PROPS
};
