// Constants
import { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT } from 'constants/transactions/transactions.constants.common';
import { TRANSACTIONS_QUERY_PARAMS } from '../../resolvers/constants/transactions-landing.resolver.constants';
// Types
import { TransactionLandingTableApiBuilderComponentType } from '../types/transactions-landing-service.type';

const FETCH_TRANSACTIONS_SERVICE_CONFIGURATION: TransactionLandingTableApiBuilderComponentType = {
  ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT,
  queryParams: {
    ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT.queryParams,
    ...TRANSACTIONS_QUERY_PARAMS,
  },
};

export { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION };
