// Types
import { RecoveryKeyStep4CreditCardHandlersType } from './types/recovery-key-step4-credit-card.handlers.type';
import { RecoveryKeyStep4CreditCardReturnHandlersType } from './types/recovery-key-step4-credit-card.return.handlers.type';

const isValidInput = (value: string): boolean => /\d{4}/.test(value);
const validatePanHandler = ({ pan, setError }) => {
  setError(!isValidInput(pan));
};

const onChangePanHandler = ({ pan, setError, setLast4DigitsPan }) => {
  setError(false);
  setLast4DigitsPan(pan);
};

const onValuesChangeHandler = ({ setCanContinue, pin, last4DigitsPan }) => {
  setCanContinue(
    isValidInput(last4DigitsPan) && isValidInput(pin)
  );
};

const onContinueHandler = async ({ handleManageNextRecoveryStepButtonClick, handleVerifyRecoveryKeyDataButtonClick }) => {
  await handleVerifyRecoveryKeyDataButtonClick();

  handleManageNextRecoveryStepButtonClick();
};

const RecoveryKeyStep4CreditCardHandlers = (
  props: RecoveryKeyStep4CreditCardHandlersType
): RecoveryKeyStep4CreditCardReturnHandlersType => ({
  validatePan: (pan: string) => validatePanHandler({...props, pan }),
  onChangePan: (pan: string) => onChangePanHandler({...props, pan }),
  onValuesChange: () => onValuesChangeHandler(props),
  onContinue: () => onContinueHandler(props)
});

export { RecoveryKeyStep4CreditCardHandlers };
