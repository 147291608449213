// Vendors
import { cookies } from '@openbank/cf-ui-architecture-core';
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';

export const getWebsocketUrl = (): string => {
  const accessToken = cookies.getCookie(StorageCookiesEnum.ACCESS_TOKEN);
  const accessTokenUrlString = `/?accessToken=${accessToken}`;

  return window?.WEBSOCKET_DOMAIN_URL
    ? `${window.WEBSOCKET_DOMAIN_URL}${accessTokenUrlString}`
    : `wss://api.${window.ENVIRONMENT}.cb.amazon.zinia.de/websocket${accessTokenUrlString}`;
};

export const getCurrentAccessToken = (): string =>
  cookies.getCookie(StorageCookiesEnum.ACCESS_TOKEN);
