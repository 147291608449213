// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent, ScriptBoxComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import PersonalNumberStep4ActionsComponent from './components/actions/personal-number-step4-actions.component';
import PersonalNumberStep4ChallengeComponent from './components/challenge/personal-number-step4-challenge.component';
// Hooks
import PersonalNumberHook from 'containers/personal/views/number/hooks/personal-number.hook';
import PersonalNumberStep4Hook from './hooks/personal-number-step4.hook';
// Constants
import constants from './constants/personal-number-step4.constants';
// Styles
import { PersonalNumberStepLayoutViewStyled } from '../../personal-number.view.styled';
import {
  PersonalNumberStep4PhonesWrapperStyled,
  PersonalNumberStep4ParagraphComponentStyled,
  PersonalNumberStep4ParagraphBoldStyled,
} from './personal-number-step4.component.styled';
// Translations
import {
  DESCRIPTION,
  MESSAGE_DESCRIPTION,
  CURRENT_PHONE,
  SMS_CODE_SENT,
  EMAIL_CODE_SENT,
} from './translations/personal-number-step4.translations';
import {
  ARE_YOU_SATISFIED,
  CONFIRM_TRANSACTION,
  POLICY_OBLIGATION,
  READING_REQUIRED,
} from 'containers/personal/views/address/components/step2/components/summary/translations/personal-address-step2-summary.translations';
// Utils
import { formatPhoneNumber } from 'containers/personal/views/number/utils/personal-number.utils.utils';
import { isCC } from 'utils/app.utils';

const PersonalNumberStep4Component = (): React.ReactElement => {
  const { newPhoneNumber, newPrefix, withEmail } = PersonalNumberStep4Hook();
  const { primaryConfigurablePhone, currentEmail } = PersonalNumberHook();

  return (
    <PersonalNumberStepLayoutViewStyled>
      <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_4_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={DESCRIPTION} />
      </ParagraphComponent>
      {isCC() && (
        <ScriptBoxComponent>
          <ParagraphComponent bold>
            <FormattedMessageComponent id={READING_REQUIRED} />
          </ParagraphComponent>
          <ParagraphComponent>
            <FormattedMessageComponent id={POLICY_OBLIGATION} />
          </ParagraphComponent>
        </ScriptBoxComponent>
      )}
      <PersonalNumberStep4PhonesWrapperStyled>
        <PersonalNumberStep4ParagraphComponentStyled>
          <PersonalNumberStep4ParagraphBoldStyled
            {...constants.PERSONAL_NUMBER_STEP_4_OTP_NEW_PHONE_LABEL}
          >
            <FormattedMessageComponent id={CURRENT_PHONE} />
          </PersonalNumberStep4ParagraphBoldStyled>
          <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_4_OTP_NEW_PHONE_VALUE}>
            {formatPhoneNumber({ newPrefix, phoneNumber: newPhoneNumber })}
          </ParagraphComponent>
        </PersonalNumberStep4ParagraphComponentStyled>
        {!!withEmail && (
          <PersonalNumberStep4ParagraphComponentStyled>
            <PersonalNumberStep4ParagraphBoldStyled
              {...constants.PERSONAL_NUMBER_STEP_4_OTP_CURRENT_EMAIL_LABEL}
            >
              <FormattedMessageComponent id={EMAIL_CODE_SENT} />
            </PersonalNumberStep4ParagraphBoldStyled>
            <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_4_OTP_CURRENT_EMAIL_VALUE}>
              {currentEmail}
            </ParagraphComponent>
          </PersonalNumberStep4ParagraphComponentStyled>
        )}
        {!withEmail && (
          <PersonalNumberStep4ParagraphComponentStyled>
            <PersonalNumberStep4ParagraphBoldStyled
              {...constants.PERSONAL_NUMBER_STEP_4_OTP_CURRENT_PHONE_LABEL}
            >
              <FormattedMessageComponent id={SMS_CODE_SENT} />
            </PersonalNumberStep4ParagraphBoldStyled>
            <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_4_OTP_CURRENT_PHONE_VALUE}>
              {primaryConfigurablePhone}
            </ParagraphComponent>
          </PersonalNumberStep4ParagraphComponentStyled>
        )}
        {isCC() ? (
          <ScriptBoxComponent>
            <ParagraphComponent bold>
              <FormattedMessageComponent id={ARE_YOU_SATISFIED} />
            </ParagraphComponent>
            <ParagraphComponent>
              <FormattedMessageComponent id={CONFIRM_TRANSACTION} />
            </ParagraphComponent>
          </ScriptBoxComponent>
        ) : (
          <MessageComponent {...constants.PERSONAL_NUMBER_STEP_4_MESSAGE_PROPS}>
            <ParagraphComponent {...constants.PERSONAL_NUMBER_STEP_4_MESSAGE_DESCRIPTION_PROPS}>
              <FormattedMessageComponent id={MESSAGE_DESCRIPTION} />
            </ParagraphComponent>
          </MessageComponent>
        )}
      </PersonalNumberStep4PhonesWrapperStyled>
      <PersonalNumberStep4ChallengeComponent />
      <PersonalNumberStep4ActionsComponent />
    </PersonalNumberStepLayoutViewStyled>
  );
};
export default PersonalNumberStep4Component;
