// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/settings-card-control-locations-map-legend-item.constants';
// Styles
import { SettingsCardControlLocationsMapLegendItemComponentStyled } from './settings-card-control-locations-map-legend-item.component.styled';
// Types
import { SettingsCardControlLocationsMapLegendItemComponentType } from './types/settings-card-control-locations-map-legend-item.component.type';

const SettingsCardControlLocationsMapLegendItemComponent = ({
  assetColor: fill,
  description,
  testing: { descriptionId },
}: SettingsCardControlLocationsMapLegendItemComponentType): React.ReactElement<SettingsCardControlLocationsMapLegendItemComponentType> | null => (
  <SettingsCardControlLocationsMapLegendItemComponentStyled>
    <AssetComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_ITEM_ASSET_PROPS}
      colors={{ fill }}
    />
    <ParagraphComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_ITEM_DESCRIPTION_PROPS}
      testId={descriptionId}
    >
      <FormattedMessageComponent id={description} />
    </ParagraphComponent>
  </SettingsCardControlLocationsMapLegendItemComponentStyled>
);

export default SettingsCardControlLocationsMapLegendItemComponent;
