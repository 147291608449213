// Enumerations
import { SimulationResumeItemsEnumeration } from 'containers/transactions/views/epp/enumerations/simulation-resume-items.enumeration';
// Translations
import {
  TOTAL_INTERESTS,
  TOTAL_PAID,
  MONTHLY,
} from '../translations/transactions-epp-step1-simulation-summary.translations';

const TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_FINANCED_PROPS = {
    testId: 'transactions-epp-step1-simulation-loan-amount',
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_INTERESTS_PROPS = {
    id: SimulationResumeItemsEnumeration.TOTAL_INTEREST,
    label: TOTAL_INTERESTS,
    testId: 'transactions-epp-step1-simulation-interests',
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_TOTAL_PROPS = {
    id: SimulationResumeItemsEnumeration.TOTAL_AMOUNT,
    label: TOTAL_PAID,
    testId: 'transactions-epp-step1-simulation-total',
  },
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_MONTHLY_PROPS = {
    id: SimulationResumeItemsEnumeration.MONTHLY_PAYMENT,
    label: MONTHLY,
    testId: 'transactions-epp-step1-simulation-monthly',
  };

export {
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_FINANCED_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_INTERESTS_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_MONTHLY_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_SUMMARY_TOTAL_PROPS,
};
