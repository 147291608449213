// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

export default {
  PROFILE_PERSONAL_NAME_TITLE_EDIT_TITLE_PROPS: {
    overflow: true,
    testId: 'profile-personal-name-title-edit-title',
    tag: titleConstants.TAGS.H6,
  },
  PROFILE_PERSONAL_NAME_TITLE_EDIT_ASSET_PROPS: {
    id: 'edit',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'profile-personal-name-title-edit-asset',
  },
};
