// Constants
import { CONTROLLED_LOGOUT } from 'constants/storage/storage.constants';
// Enumerations
import { StorageCookiesEnum, TimerEnum } from '@openbank/cf-ui-static-data';
import { ExpiredSessionReasonsEnumeration } from 'enumerations/session/expired-session-reasons.enumeration';
// Types
import {
  SessionExpiredBuilderHandlersType,
  SessionExpiredBuilderReturnHandlersType,
  SessionExpiredHealthBuilderHandlersType,
} from './types/session-expired.handlers.type';
// Utilities
import {
  cookieWasRemoveAwareMode,
  cookieWasRemovedWithoutReason,
} from './utils/session-expired.handlers.utils';

let EXPIRED_INTERVAL;

const killSessionUserHealthObservation = (): void => {
  clearInterval(EXPIRED_INTERVAL);
};

const checkCookieStatusHealth = ({
  id,
  reason,
  setExpiredReason,
}: SessionExpiredHealthBuilderHandlersType): void => {
  if (cookieWasRemovedWithoutReason(id)) {
    setExpiredReason(reason);
    killSessionUserHealthObservation();
  } else if (cookieWasRemoveAwareMode(id)) {
    localStorage.removeItem(CONTROLLED_LOGOUT);
  }
};

const observeUserSessionHealthEventsHandler = (
  setExpiredReason: SessionExpiredBuilderHandlersType['setExpiredReason']
): void => {
  EXPIRED_INTERVAL = setInterval(() => {
    checkCookieStatusHealth({
      id: StorageCookiesEnum.REFRESH_TOKEN,
      reason: ExpiredSessionReasonsEnumeration.ACCESS_TOKEN,
      setExpiredReason,
    });
    checkCookieStatusHealth({
      id: StorageCookiesEnum.REFRESH_TOKEN,
      reason: ExpiredSessionReasonsEnumeration.REFRESH_TOKEN,
      setExpiredReason,
    });
  }, TimerEnum.TIMEOUT_ONE_SECOND);
};

const SessionExpiredHandlers = ({
  setExpiredReason,
}: SessionExpiredBuilderHandlersType): SessionExpiredBuilderReturnHandlersType => ({
  handleObserveUserSessionHealthEvents: () =>
    observeUserSessionHealthEventsHandler(setExpiredReason),
});

export { SessionExpiredHandlers };
