// Vendors
import { useState } from 'react';
// Handlers
import IvrComponentWrapperCCHandlers from '../handlers/ivr-component-wrapper-cc.handlers';
// Types
import { IvrComponentWrapperHookType } from './types/ivr-component-wrapper-cc.hook.type';
import { IvrComponentWrapperCCHandlersHandlersType } from '../handlers/types/ivr-component-wrapper-cc.handlers.type';

const IvrComponentWrapperHook = ({
  initiatorIvr,
  resultIvr,
}: Pick<
  IvrComponentWrapperCCHandlersHandlersType,
  'initiatorIvr' | 'resultIvr'
>): IvrComponentWrapperHookType => {
  const [result, setResult] = useState<boolean>();
  const [isLoadingIvr, setIsLoadingIvr] = useState<boolean>(false);

  return {
    ...IvrComponentWrapperCCHandlers({
      setIsLoadingIvr,
      setResult,
      initiatorIvr,
      resultIvr,
    }),
    isLoadingIvr,
    result,
  };
};

export default IvrComponentWrapperHook;
