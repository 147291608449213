// Enumerations
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
import { NotRecognizeChargeMotiveFraudEnum } from 'containers/transactions/views/report/enumerations/not-recognize-charge-motive-fraud.enumeration';

const fraudToClaimMap = {
  [NotRecognizeChargeMotiveFraudEnum.LOST_CARD_FRAUD]: ClaimReasonEnum.CARD_LOST_OR_STOLEN,
  [NotRecognizeChargeMotiveFraudEnum.STOLEN_CARD_FRAUD]: ClaimReasonEnum.CARD_LOST_OR_STOLEN,
  [NotRecognizeChargeMotiveFraudEnum.CARD_NOT_RECEIVED_FRAUD]:
    ClaimReasonEnum.ISSUED_CARD_AND_NOT_RECEIVED,
  [NotRecognizeChargeMotiveFraudEnum.COUNTERFEIT_CARD_FRAUD]: ClaimReasonEnum.COUNTERFEIT_CARD,
  [NotRecognizeChargeMotiveFraudEnum.EMAIL_FRAUD]: ClaimReasonEnum.ONLINE_OPERATION_NOT_PERFORMED,
};

export { fraudToClaimMap };
