// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const OrchestratorHeaderComponentStyled = styled.header<{
  isSecondLevel: boolean;
  isHybrid?: boolean;
}>`
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  box-shadow: 0 ${convertPxsToRems({ pixels: 1, base: 14 })}
    ${convertPxsToRems({ pixels: 1, base: 14 })} 0
    ${({ theme }) => theme.colors.backgroundTertiaryD};
  left: 0;
  position: sticky;
  right: 0;
  top: 0;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.zIndexC};

  > div > div > div svg {
    cursor: pointer;

    &:hover {
      stroke: ${({ theme }) => theme.colors.backgroundInfoC};
    }
  }

  ${tabletMediaQuery} {
    ${({ isSecondLevel, isHybrid }) => (isSecondLevel || isHybrid) && 'display: none;'};
  }

  ${mobileMediaQuery} {
    ${({ isSecondLevel, isHybrid }) => (isSecondLevel || isHybrid) && 'display: none;'};
  }
`;
