// Constants
import { linkConstants, paragraphConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_PROPS = {
    type: linkConstants.TYPES.ACTION_SECONDARY,
  },
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_TEST_ID =
    'financing-ppi-contracting-description-link-link-',
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_SIZES = {
    [paragraphConstants.SIZES.XS]: linkConstants.SIZES.SMALL,
    [paragraphConstants.SIZES.S]: linkConstants.SIZES.SMALL,
    [paragraphConstants.SIZES.M]: linkConstants.SIZES.NORMAL,
    [paragraphConstants.SIZES.L]: linkConstants.SIZES.BIG,
    [paragraphConstants.SIZES.XL]: linkConstants.SIZES.BIG,
  };

export {
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_PROPS,
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_TEST_ID,
  FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_LINK_SIZES,
};
