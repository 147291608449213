// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { ALL_NOTIFICATIONS_TRACKING } from '../constants/orchestrator-header.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { OrchestratorBuilderNotificationsTrackingHandlersType } from './types/orchestrator-builder-notifications.tracking.handlers.type';

const makeUserClickOnAllNotifications = (): TrackingLinkEventReturnType => ({
  payload: ALL_NOTIFICATIONS_TRACKING,
  type: TrackingEventsEnum.LINK,
});

const OrchestratorHeaderNotificationsTrackingHandlers = (
  track: TrackBuilderType
): OrchestratorBuilderNotificationsTrackingHandlersType => ({
  handleMakeUserClickOnAllNotifications: () => track(makeUserClickOnAllNotifications()),
});

export default OrchestratorHeaderNotificationsTrackingHandlers;
