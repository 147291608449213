// Constants
import appConstants from 'constants/app.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Resolvers
import { fetchNotificationsSettingsResolver, saveNotificationsSettingsResolver } from '../resolvers/notifications-settings-list.resolver';
// Translations
import { ERROR_MESSAGE, SUCCESS_MESSAGE } from './translations/notifications-settings-list.handlers.translations';
// Types
import { controllerChangeHandlerType } from './types/controller-change.handler.type';
import { fetchNotificationsSettingsHandlerType } from './types/fetch-notifications-settings.handler.type';
import { goBackButtonClickHandlerType } from './types/go-back-click.handler.type';
import { HandleControllerChangeType } from './types/handle-controller-change.handlers.type';
import { HandleToggleNotificationType } from './types/handle-toggle-notification.handlers.type';
import { ModalSaveButtonClickHanlderPropsType } from './types/modal-save-button-click-handler.type';
import { NotificationsSettingsBuilderHandlersReturnType } from './types/notifications-settings-list-builder-return.handlers.type';
import { NotificationsSettingsBuilderHandlersType } from './types/notifications-settings-list-builder.handlers.type';
import { NotificationsSettingsFormattedType } from 'containers/notifications/views/notifications-settings/components/list/types/notification-settings-formatted.type';
import { saveChangesClickHandlerType } from './types/save-changes-click.handler.type';
import { toggleAllNotificationsHandlerType } from './types/toggle-all-notifications.handler.type';
import { toggleNotificationHandlerType } from './types/toggle-notification.handler.type';
// Utilities
import { 
  formatSettingsPayload,
  toggleAllNotificationsUtil,
  toggleNotificationUtil,
  controllerChangeUtil,
  updateNotificationSettings,
} from './utils/notifications-settings-category.handlers.utils';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';

const fetchNotificationsSettingsHandler = async ({
  setFetching,
  setError,
  setNotificationsSettings
}: fetchNotificationsSettingsHandlerType): Promise<void> => {
  setFetching(true);
  const [notificationsSettings, error] = await fetchNotificationsSettingsResolver();
  
  if(error) {
    setError(true);
  } else {
    setNotificationsSettings(notificationsSettings);
  }

  setFetching(false);
};

const saveChangesClickHandler = async ({
  handleErrorNotificationsSettingsSaveTracking,
  handleSaveChangesClickTracking,
  handleSuccessNotificationsSettingsSaveTracking,
  notificationsSettings,
  setHasChangedSettings,
  setSaving,
  setToastConfiguration,
}: saveChangesClickHandlerType): Promise<void> => {
  handleSaveChangesClickTracking();
  setSaving(true);
  const [response, error] = await saveNotificationsSettingsResolver({
    formattedSettings: formatSettingsPayload(notificationsSettings as NotificationsSettingsFormattedType)
  });
  
  setSaving(false);
  error && handleErrorNotificationsSettingsSaveTracking();
  if (response) {
    setHasChangedSettings(false);
    handleSuccessNotificationsSettingsSaveTracking();
  } 
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations: { ERROR_MESSAGE, SUCCESS_MESSAGE } }) })
  );
};

const modalSaveButtonClickHanlder = async ({
  handleSaveAndExitModalTracking,
  navigate,
  setShowModal,
  setSaving,
  notificationsSettings,
  setToastConfiguration,
}: ModalSaveButtonClickHanlderPropsType) => {
  handleSaveAndExitModalTracking();
  setSaving(true);
  const [, error] = await saveNotificationsSettingsResolver({
    formattedSettings: formatSettingsPayload(notificationsSettings as NotificationsSettingsFormattedType)
  });

  setSaving(false);
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations: { ERROR_MESSAGE, SUCCESS_MESSAGE } }) })
  );
  setShowModal(false);
  navigate(generateAbsoluteRoute(RoutesEnumeration.NOTIFICATIONS));
};

const toggleAllNotificationsHandler = ({
  setNotificationsSettings,
  setHasChangedSettings 
}: toggleAllNotificationsHandlerType): void => {
  setHasChangedSettings(true);
  updateNotificationSettings(setNotificationsSettings, toggleAllNotificationsUtil);
};

const toggleNotificationHandler = ({
  categoryId,
  handleToggleNotificationTracking,
  isOn,
  notificationId,
  notificationType,
  setHasChangedSettings,
  setNotificationsSettings,
}: toggleNotificationHandlerType): void => {
  setHasChangedSettings(true);
  updateNotificationSettings(setNotificationsSettings, (prevSettings) =>
    toggleNotificationUtil({ prevSettings, categoryId, notificationId })
  ); 
  handleToggleNotificationTracking(notificationType, isOn);
};

const controllerChangeHandler = ({
  categoryId,
  controllerId,
  handleChangeControllerTracking,
  notificationId,
  setHasChangedSettings,
  setNotificationsSettings,
  value,
}: controllerChangeHandlerType): void => {
  setHasChangedSettings(true);
  updateNotificationSettings(setNotificationsSettings, (prevSettings) =>
    controllerChangeUtil({
      prevSettings,
      categoryId,
      notificationId,
      controllerId,
      value,
    })
  );

  handleChangeControllerTracking(value);
};

const goBackButtonClickHandler = ({
  handleGoBackButtonClickTracking,
  hasChangedSettings,
  navigate,
  setShowModal,
}: goBackButtonClickHandlerType): void => {
  if (!hasChangedSettings) {
    handleGoBackButtonClickTracking();
    navigate(generateAbsoluteRoute(RoutesEnumeration.NOTIFICATIONS));
  }
  setShowModal(true);
};

const faqButtonClickHandler = (): void => {
  window.open(appConstants.AMAZON_PUBLIC_SITE_FAQS); 
};

const NotificationsSettingsHandlers = ({
  handleChangeControllerTracking,
  handleErrorNotificationsSettingsSaveTracking,
  handleGoBackButtonClickTracking,
  handleSaveAndExitModalTracking,
  handleSaveChangesClickTracking,
  handleSuccessNotificationsSettingsSaveTracking,
  handleToggleNotificationTracking,
  hasChangedSettings,
  navigate,
  notificationsSettings,
  setError,
  setFetching,
  setHasChangedSettings,
  setNotificationsSettings,
  setSaving,
  setShowModal,
  setToastConfiguration,
}: NotificationsSettingsBuilderHandlersType): 
NotificationsSettingsBuilderHandlersReturnType =>({
  handleFetchNotificationsSettings: () => 
    fetchNotificationsSettingsHandler({
      setFetching,
      setError,
      setNotificationsSettings,
    }),
  handleToggleAllNotifications: () => 
    toggleAllNotificationsHandler({
      setNotificationsSettings,
      setHasChangedSettings
    }), 
  handleToggleNotification: ({
    categoryId,
    isOn,
    notificationId,
    notificationType,
  }: HandleToggleNotificationType) => 
    toggleNotificationHandler({
      categoryId,
      handleToggleNotificationTracking,
      isOn,
      notificationId,
      notificationType,
      setHasChangedSettings,
      setNotificationsSettings,
    }),
  handleControllerChange: ({
    categoryId,
    controllerId,
    notificationId,
    value,
  }: HandleControllerChangeType) =>
    controllerChangeHandler({
      categoryId,
      controllerId,
      handleChangeControllerTracking,
      notificationId,
      setHasChangedSettings,
      setNotificationsSettings,
      value,
    }),
  handleSaveChangesClick: () => 
    saveChangesClickHandler({
      handleErrorNotificationsSettingsSaveTracking,
      handleSaveChangesClickTracking,
      handleSuccessNotificationsSettingsSaveTracking,
      notificationsSettings,
      setHasChangedSettings,
      setSaving,
      setToastConfiguration,
    }),
  handleModalSaveButtonClick: () =>
    modalSaveButtonClickHanlder({
      handleSaveAndExitModalTracking,
      navigate,
      setShowModal,
      setSaving,
      notificationsSettings,
      setToastConfiguration,
     }),
  handleGoBackButtonClick: () =>
    goBackButtonClickHandler({
      handleGoBackButtonClickTracking,
      hasChangedSettings,
      navigate,
      setShowModal,
    }),
  handleFaqButtonClick: () =>
    faqButtonClickHandler(),
});

export { NotificationsSettingsHandlers };