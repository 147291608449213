// Vendors
import styled from 'styled-components';

export const RequestAccessStep1ContainerStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

export const RequestAccessStep1ListStyled = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 ${({ theme }) => theme.spaces.spacingM};
  margin: 0;
`;

export const RequestAccessStep1ListItemStyled = styled.li`
  padding: 0;
  margin: 0;
`;