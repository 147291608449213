// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Constants
import constants from './constants/challenge-wrapper.hook.constants.';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Hooks
import useTimer from 'hooks/use-timer.hook';
// Handlers
import ChallengeWrapperHandlers from '../handlers/challenge-wrapper.handlers';
// Types
import { ChallengeWrapperReturnHookType } from './types/challenge-wrapper-return.hook.type';
import { ChallengeWrapperHookType } from './types/challenge-wrapper.hook.type';
// Utils
import { getTimerLabelTranslationId } from './utils/challenge-wrapper.hook.utils';

const ChallengeWrapperHook = ({ onRetry }: ChallengeWrapperHookType): ChallengeWrapperReturnHookType => {
  const [challengeKey, setChallengeKey] = useState<Date>(new Date());

  const { setOpenRequestSignatureModal } = AppContextConsumerHook();

  const { formatMessage } = useCbIntl();

  const { timer, isTimerActive, handleRestart } = useTimer(constants.DEFAULT_RETRY_TIMER);

  const timerLabelTranslationId = getTimerLabelTranslationId(timer);

  return {
    ...ChallengeWrapperHandlers({
      onRetry,
      setChallengeKey,
      setOpenRequestSignatureModal,
    }),
    challengeKey,
    formatMessage,
    handleRestart,
    isTimerActive,
    timer,
    timerLabelTranslationId,
  };
};

export default ChallengeWrapperHook;
