// Constants
import { titleConstants, paragraphConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/request-portability-step1.translations';

export const REQUEST_PORTABILITY_STEP1_PARAGRAPH_01_PROPS = {
  testId: 'request-portability-paragraph-01',
};
export const REQUEST_PORTABILITY_STEP1_PARAGRAPH_02_PROPS = {
  testId: 'request-portability-paragraph-03',
};
export const REQUEST_PORTABILITY_STEP1_PARAGRAPH_03_PROPS = {
  testId: 'request-portability-paragraph-03',
};
export const TITLE_PERSONAL_DATA_PROPS = {
  bold: true,
  tag: titleConstants.TAGS.H6,
  testId: 'request-portability-personal-data-title',
};
export const TITLE_CONTRACTED_PRODUCTS_PROPS = {
  bold: true,
  tag: titleConstants.TAGS.H6,
  testId: 'request-portability-contracted-products-title',
};

export const REQUEST_PORTABILITY_STEP1_MESSAGES = [
  {
    id: 0,
    testId: 'request-portability-message-01',
    text: translations.MESSAGE_1,
  },
  {
    id: 1,
    testId: 'request-portability-message-02',
    text: translations.MESSAGE_2,
  },
  {
    id: 2,
    testId: 'request-portability-message-03',
    text: translations.MESSAGE_3,
  },
  {
    id: 3,
    testId: 'request-portability-message-04',
    text: translations.MESSAGE_4,
  },
  {
    id: 4,
    testId: 'request-portability-message-05',
    text: translations.MESSAGE_5,
  },
];

export const REQUEST_PORTABILITY_STEP1_PERSONAL_TOGGLE = {
  testId: 'access-portability-request-personal-toggle',
};

export const REQUEST_PORTABILITY_STEP1_PRODUCTS_TOGGLE = {
  testId: 'access-portability-request-products-toggle',
};
