// Vendors
import React from 'react';
// Components
import { NotificationSettingsSidebarInfoComponent } from './components/sidebar-info/notifications-settings-sidebar-info.component';
import { NotificationSettingsSidebarFaqComponent } from './components/sidebar-faq/notifications-settings-sidebar-faq.component';
// Constants
import { NOTIFICATIONS_SETTINGS_SIDEBAR_TEST_ID } from './constants/notifications-settings-sidebar.component.constants';
// Styles
import { SidebarComponentStyled } from 'styles/app.styled';
import { NotificationsSettingsWrapperSidebarComponentStyled } from './notifications-settings-sidebar.component.styled';
// Types
import { NotificationSettingsSidebarComponentPropsType } from './types/notification-settings-sidebar.type';

const NotificationSettingsSidebarComponent = ({
  handleFaqButtonClick,
}: NotificationSettingsSidebarComponentPropsType): React.ReactElement => (
  <SidebarComponentStyled>
    <NotificationsSettingsWrapperSidebarComponentStyled
      {...{ ...NOTIFICATIONS_SETTINGS_SIDEBAR_TEST_ID }}
    >
      <NotificationSettingsSidebarInfoComponent />
      <NotificationSettingsSidebarFaqComponent {...{ handleFaqButtonClick }} />
    </NotificationsSettingsWrapperSidebarComponentStyled>
  </SidebarComponentStyled>
);

export { NotificationSettingsSidebarComponent };
