// Vendors
import React from 'react';
import { CharactersEnum, MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
import { FormattedDate } from 'react-intl';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TOGGLE_BUTTON_COMPONENT_BUBBLE_STATUS,
  TOGGLE_BUTTON_EXPIRATION_DATE,
} from './constants/toggle-button.component.constants';
// Hooks
import CardSettingsTogglesHook from '../../hooks/card-settings-toggles.hook';
import { useConfirmationModalToastMessage } from 'components/enable-disable-card-confirmation-modal/hooks/enable-disable-card-confirmation-modal.hook';
// Translations
import translations from '../../translations/card-settings-toggles.translations';
// Types
import { ToggleButtonComponentType } from './types/toggle-button.types';
// Styles
import {
  ToggleButtonActioner,
  ToggleButtonActionerText,
  ToggleButtonBubbleStatus,
  ToggleButtonComponentStyled,
  ToggleButtonContainer,
  ToggleButtonExpirationComponentStyled,
  ToggleButtonLabel,
} from './toggle-button.component.styled';
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Utils
import { checkOnOff, showExpirationDate } from './utils/toggle-button.utils';

/* eslint-disable complexity */
const ToggleButtonComponent = ({
  disabled,
  label,
  labelOff,
  labelOn,
  onClick,
  value = false,
  testId,
  expirationDate,
  loadingStatus,
}: ToggleButtonComponentType): React.ReactElement => {
  const { status } = CardSettingsTogglesHook();
  useConfirmationModalToastMessage({
    isEnabled: !disabled,
    loadingStatus,
  });

  return (
    <ToggleButtonComponentStyled {...{ testId }}>
      <ToggleButtonContainer>
        <ToggleButtonLabel>{label?.value}</ToggleButtonLabel>
        <ToggleButtonBubbleStatus
          {...{
            value,
            expired: !showExpirationDate({ expirationDate, value }),
            testId: `${testId}${CharactersEnum.NO_VALUE}${TOGGLE_BUTTON_COMPONENT_BUBBLE_STATUS.valueId}`,
          }}
          bold
        >
          {value ? labelOn : labelOff}
        </ToggleButtonBubbleStatus>
      </ToggleButtonContainer>
      <ToggleButtonActioner {...{ testId: label?.testId }} disabled={disabled} onClick={onClick}>
        <ToggleButtonActionerText bold disabled={disabled}>
          <FormattedMessageComponent id={checkOnOff(value)} values={{ value: label?.value }} />
        </ToggleButtonActionerText>
      </ToggleButtonActioner>
      {showExpirationDate({ expirationDate, value }) ? (
        <ToggleButtonExpirationComponentStyled>
          <ParagraphSecondaryComponentStyled>
            <ParagraphComponent {...TOGGLE_BUTTON_EXPIRATION_DATE}>
              <FormattedMessageComponent id={translations.ACTIVE_UNTIL} />{' '}
              <FormattedDate
                value={expirationDate}
                {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
              />
            </ParagraphComponent>
          </ParagraphSecondaryComponentStyled>
        </ToggleButtonExpirationComponentStyled>
      ) : (
        <></>
      )}
    </ToggleButtonComponentStyled>
  );
};

export default ToggleButtonComponent;
