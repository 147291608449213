// Enumerations
import { ValidateStatusEnumeration } from 'components/address-modal/enums/validate-status.enumeration';
// Resolvers
import { validateCityWithPostcodeResolver } from 'components/address-modal/resolvers/address-modal.resolvers';
// Types
import { ParseCityWithPostcodeType } from './types/parse-city-with-postcode.type';
import { ParseCityWithPostcodeReturnType } from './types/parse-city-with-postcode-return.type';
import { ValidateCodesEnumeration } from 'components/address-modal/enums/validate-codes.enumeration';

const parseCityAndPostcode = async ({
  city,
  postCode,
}: ParseCityWithPostcodeType): ParseCityWithPostcodeReturnType => {
  const [response, error] = await validateCityWithPostcodeResolver({
    city,
    postCode,
  });

  if (error || response?.status === ValidateStatusEnumeration.FAILED) {
    if (response?.code === ValidateCodesEnumeration.E003) {
      return { cityValue: city, postCodeValue: '' };
    }

    return { cityValue: '', postCodeValue: '' };
  }

  return { cityValue: city, postCodeValue: postCode };
};

export { parseCityAndPostcode };
