// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons/';
import { RequestSignatureKeySidebarComponent } from './components/sidebar/request-signature-key-sidebar.component';
import RequestSignatureKeyOrchestratorComponent from './components/orchestrator/request-signature-key-orchestrator.component';
// Context
import { RequestSignatureKeyContextConsumerHOC } from './contexts/request-signature-key.context';
// Hooks
import useRequestSignatureKeyHook from './hooks/request-signature-key.hook';
// Styles
import { RequestSignatureKeyViewStyled } from './request-signature-key.view.styled';
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const RequestSignatureKeyView = (): React.ReactElement => {
  const { fetching } = useRequestSignatureKeyHook();

  return (
    <RequestSignatureKeyViewStyled>
      <LayoutGridContentComponentStyled>
        <LayoutGridContentLeftComponentStyled>
          <FetchErrorComponent {...{ fetching }}>
            <RequestSignatureKeyOrchestratorComponent />
          </FetchErrorComponent>
        </LayoutGridContentLeftComponentStyled>
        <LayoutGridContentRightComponentStyled>
          <RequestSignatureKeySidebarComponent />
        </LayoutGridContentRightComponentStyled>
      </LayoutGridContentComponentStyled>
    </RequestSignatureKeyViewStyled>
  );
};

export { RequestSignatureKeyView };

export default RequestSignatureKeyContextConsumerHOC(RequestSignatureKeyView);
