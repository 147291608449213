// Event handlers
import { FinancingPPIContractingDescriptionLinkLinkHandlers } from '../handlers/financing-ppi-contracting-description-link-link.handlers';
import { FinancingPPIContractingDescriptionLinkLinkTrackingHandlers } from '../handlers/financing-ppi-contracting-description-link-link.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType } from '../handlers/types/financing-ppi-contracting-description-link-link.handlers.type';
import { FinancingPPIContractingStep1DescriptionLinkLinkType } from '../types/financing-ppi-contracting-step1-description-link-link.type';

const useFinancingPPIContractingDescriptionLinkLinkHook = (
  url: FinancingPPIContractingStep1DescriptionLinkLinkType['url']
): FinancingPPIContractingStep1CoverageDescriptionLinkMethodsHandlersType =>
  FinancingPPIContractingDescriptionLinkLinkHandlers({
    ...AppTrackingHook(FinancingPPIContractingDescriptionLinkLinkTrackingHandlers),
    url,
  });

export { useFinancingPPIContractingDescriptionLinkLinkHook };
