// Vendors
import { titleConstants } from '@openbank/cb-ui-commons';
import {
  MiscellaneousCurrenciesEnum,
  MiscellaneousCurrencyConstants,
} from '@openbank/cf-ui-static-data';
// Constants
import { NUMBER } from 'constants/number.constants';

const DEPOSIT_MONEY_EURO_FORMAT_OPTIONS = {
  ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
  currency: MiscellaneousCurrenciesEnum.EURO,
  minimumFractionDigits: NUMBER.TWO,
};

const DEPOSIT_MONEY_TITLE_PROPS = {
  testId: 'card-settings-deposit-money-title',
  tag: titleConstants.TAGS.H4,
};

const DEPOSIT_MONEY_DESCRIPTION_PROPS = {
  testId: 'card-settings-deposit-money-description',
};

export {
  DEPOSIT_MONEY_EURO_FORMAT_OPTIONS,
  DEPOSIT_MONEY_TITLE_PROPS,
  DEPOSIT_MONEY_DESCRIPTION_PROPS,
};
