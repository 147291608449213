const DATE_CONTROLLER_NAME = 'date';

const DATE_CONTROLLER_RULES = {
  required: true,
};

const DATE_INPUT_PROPS = {
  placeholder: 'DD. MM. YYYY',
  isRequired: true,
  testId: 'transactions-report-service-cancelled-additional-info-date-input',
};

export { DATE_CONTROLLER_NAME, DATE_CONTROLLER_RULES, DATE_INPUT_PROPS };
