// Types
import { BlockReasonChangeHandlerType } from './types/block-reason-change-handler.type';
import { BlockCardReasonReturnHandlersType } from './types/block-card-reason-return.handlers.type';
import { BlockCardReasonHandlersType } from './types/block-card-reason.handlers.type';

const blockReasonChangeHandler = ({
  eventLabel,
  handleBlockReasonChangeTracking,
  setBlockReason,
  value,
}: BlockReasonChangeHandlerType) => {
  handleBlockReasonChangeTracking(eventLabel);
  setBlockReason(value);
};

const BlockCardReasonHandlers = ({
  handleBlockReasonChangeTracking,
  setBlockReason,
}: BlockCardReasonHandlersType): BlockCardReasonReturnHandlersType => ({
  handleBlockReasonChange: ({ eventLabel, value }) =>
    blockReasonChangeHandler({
      eventLabel,
      handleBlockReasonChangeTracking,
      setBlockReason,
      value,
    }),
});

export default BlockCardReasonHandlers;
