// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GENERATE_OTP_SERVICE_CONFIGURATION } from './constants/change-iban-step2.services.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { ChangeIbanStep2GenerateApiBuilderType } from './types/change-iban-step2-generate-api-builder.type';

const generateChangeIbanOtpService = async ({
  cardId,
  ibanValue,
  progressId,
}: ChangeIbanStep2GenerateApiBuilderType): Promise<[ChallengeType, Response]> => {
  const customHeaders = { deviceLocale: 'de_DE', applicationLocale: 'de_DE', bankId: 'MB_DEU' };

  if (progressId) {
    customHeaders['progressId'] = progressId;
  }

  return apiUtilsCb.patch({
    ...GENERATE_OTP_SERVICE_CONFIGURATION,
    customHeaders,
    params: { externalIban: ibanValue.replace(/ /g, '') },
    pathParams: { cardId },
  });
};

export { generateChangeIbanOtpService };
