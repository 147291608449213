// Vendors
import React, { Fragment } from 'react';
// Components
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
import { LineSeparatorComponent } from '@openbank/cb-ui-commons';
// Constants
import { DOCUMENTS_GROUP_COMPONENT_PROPS } from './constants/upload-documents-list.constants';
// Styles
import { PersonalUploadDocumentationSeparatorStyled } from '../../personal-upload-documentation.component.styled';
// Types
import { UploadDocumentsListComponentPropsType } from './types/upload-documents-list-props.components.type';

const UploadDocumentsListComponent = ({
  files,
  ...rest
}: UploadDocumentsListComponentPropsType): React.ReactElement => (
  <Fragment>
    {files.map((file, index) => (
      <Fragment key={file.id}>
        <UploadDocumentGroupComponent
          {...DOCUMENTS_GROUP_COMPONENT_PROPS}
          {...{
            files: [file],
            ...rest,
          }}
        />
        {index < files.length - 1 && (
          <PersonalUploadDocumentationSeparatorStyled>
            <LineSeparatorComponent />
          </PersonalUploadDocumentationSeparatorStyled>
        )}
      </Fragment>
    ))}
  </Fragment>
);

export { UploadDocumentsListComponent };
