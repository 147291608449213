// Constants
import { URL_USER_AVATAR } from './constants/orchestrator-header.utils.constants';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Translations
import {
  MODAL_NOTIFICATIONS_ALL_NOTIFICATIONS,
  MODAL_NOTIFICATIONS_ERROR_DESCRIPTION,
  MODAL_NOTIFICATIONS_ERROR_TITLE,
  MODAL_NOTIFICATIONS_SETTINGS,
  MODAL_NOTIFICATIONS_TITLE,
} from '../../translations/orchestrator-header.translations';
// Types
import { UserDetailsType } from 'types/user-details.type';
import { NotificationType } from 'types/notification.type';
import {
  NotificationsModalOptionsReturnType,
  NotificationsModalOptionsPropsType,
} from './types/orchestrator-header.hook.utils.type';
// Utilities
import { getPersonalNameAvatar } from 'utils/avatar.utils';
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const getUserAvatar = ({
  genderTCode,
  targetTCode,
}: Pick<UserDetailsType, 'genderTCode' | 'targetTCode'>): string =>
  URL_USER_AVATAR.replace(':avatar', getPersonalNameAvatar({ genderTCode, targetTCode }));

const getNotificationsCount = (notifications: Array<NotificationType>): number =>
  (notifications || []).filter(({ isReaded }) => !isReaded).length;
const getFiveFirstNotifications = (
  notifications: Array<NotificationType>
): Array<NotificationType> => (notifications || []).slice(0, 5);
const getNotificationsModalOptions = ({
  formatMessage,
  notifications,
  notificationsCount,
  handleMakeUserNotificationsClickHandler,
  handleOpenNotificationModal,
  handleGoToNotificationsSettings,
}: NotificationsModalOptionsPropsType): NotificationsModalOptionsReturnType => ({
  error: {
    description: formatMessage({ id: MODAL_NOTIFICATIONS_ERROR_DESCRIPTION }),
    title: formatMessage({ id: MODAL_NOTIFICATIONS_ERROR_TITLE }),
  },
  header: {
    title: formatMessage({ id: MODAL_NOTIFICATIONS_TITLE }),
    onSettingsClick: handleGoToNotificationsSettings,
    label: formatMessage({ id: MODAL_NOTIFICATIONS_SETTINGS }),
    showSettings: getFeatureFlagInstance().isEnabled(FeatureFlagsEnumeration.NOTIFICATION_SETTING),
  },
  footer: {
    label: formatMessage({ id: MODAL_NOTIFICATIONS_ALL_NOTIFICATIONS }),
    onLinkClick: handleMakeUserNotificationsClickHandler,
  },
  onNotificationsOpen: notifications.length ? handleOpenNotificationModal : null,
  notifications,
  notificationsCount,
});

export {
  getUserAvatar,
  getNotificationsCount,
  getFiveFirstNotifications,
  getNotificationsModalOptions,
};
