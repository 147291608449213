// Constants
import { NUMBER } from 'constants/number.constants';
import { REPORT_DEFECTIVE_DEFAULT_STATE } from 'containers/transactions/views/report/components/defective/contexts/constants/report-defective.context.constants';
// Utils
import { getDocumentIds } from 'containers/transactions/views/report/utils/transactions-report.utils';
// Types
import { ReportDefectiveUploadDocumentsHookReturnType } from '../hooks/types/report-defective-upload-documents.hook.type';
import { ReportDefectiveUploadDocumentsHandlerPropsType } from './types/report-defective-upload-documents.handler.type';

const filesHandler = ({ files, setData, setCurrentStep, data }) => {
  const documentIds = getDocumentIds(files) || [];
  setData({ ...data, documents: documentIds });
  setCurrentStep(NUMBER.FOUR);
};

const goBackHandler = ({ setFiles, setCurrentStep }) => {
  setFiles(REPORT_DEFECTIVE_DEFAULT_STATE.files);
  setCurrentStep(NUMBER.TWO);
};

const ReportDefectiveUploadDocumentsHandler = (
  props: ReportDefectiveUploadDocumentsHandlerPropsType
): Pick<ReportDefectiveUploadDocumentsHookReturnType, 'handleFiles' | 'handleGoBack'> => ({
  handleFiles: () => filesHandler(props),
  handleGoBack: () => goBackHandler(props),
});

export { ReportDefectiveUploadDocumentsHandler };
