// Vendors
import React from 'react';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  SidebarComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { SIDEBAR_PROPS } from './constants/credit-limit-decrease-cc-sidebar.constants';
// Styles
import {
  LastExpandableRowStyled,
  LastParagraphContainerStyled,
  ParagraphContainerStyled,
} from './credit-limit-decrease-cc-sidebar.component.styled';
// Translations
import {
  SIDEBAR_HOW_DOES_IT_WORK,
  SIDEBAR_HOW_DOES_IT_WORK_DESCRIPTION,
  SIDEBAR_INTEREST_CHARGES,
  SIDEBAR_INTEREST_CHARGES_DESCRIPTION,
  SIDEBAR_TITLE,
} from './translations/credit-limit-decrease-cc-sidebar.translations';

const CreditLimitDecreaseCCSidebarComponent = (): React.ReactElement => (
  <SidebarComponent title={<FormattedMessageComponent id={SIDEBAR_TITLE} />}>
    <ExpandableRowComponent title={<FormattedMessageComponent id={SIDEBAR_HOW_DOES_IT_WORK} />}>
      <ParagraphContainerStyled>
        <ParagraphComponent>
          <FormattedMessageComponent id={SIDEBAR_HOW_DOES_IT_WORK_DESCRIPTION} />
        </ParagraphComponent>
      </ParagraphContainerStyled>
    </ExpandableRowComponent>
    <LastExpandableRowStyled>
      <ExpandableRowComponent
        {...SIDEBAR_PROPS}
        title={<FormattedMessageComponent id={SIDEBAR_INTEREST_CHARGES} />}
      >
        <LastParagraphContainerStyled>
          <ParagraphComponent>
            <FormattedMessageComponent id={SIDEBAR_INTEREST_CHARGES_DESCRIPTION} />
          </ParagraphComponent>
        </LastParagraphContainerStyled>
      </ExpandableRowComponent>
    </LastExpandableRowStyled>
  </SidebarComponent>
);

export { CreditLimitDecreaseCCSidebarComponent };
