// Types
import { SupportCancelContractSecondStepChallengeHandlersManageSuccessOnChangeType } from './types/security-keys-access-key-change-third-step-challenge-handlers-manage-success.type';
import { AccessKeyChangeStep3ChallengeHandlersReturnType } from './types/security-keys-access-key-change-third-step-challenge-handlers-return.type';
import { AccessKeyChangeStep3ChallengeHandlersParamsType } from './types/security-keys-access-key-change-third-step-challenge-handlers.type';

const manageSuccessOnChangeSmsEventHandler = async ({
  handleSendOtpCodeStep3TrackingHandler,
  handleSendOtpCodeSuccessStep3TrackingHandler,
  setIsDisabledLastStep,
  progressId,
  setProgressId,
}: SupportCancelContractSecondStepChallengeHandlersManageSuccessOnChangeType): Promise<void> => {
  handleSendOtpCodeStep3TrackingHandler();
  handleSendOtpCodeSuccessStep3TrackingHandler();
  setIsDisabledLastStep(false);
  progressId && setProgressId(progressId);
};

const SecurityKeysAccessKeyChangeThirdStepChallengeHandlers = ({
  handleSendOtpCodeStep3TrackingHandler,
  handleSendOtpCodeSuccessStep3TrackingHandler,
  setIsDisabledLastStep,
  setProgressId,
}: AccessKeyChangeStep3ChallengeHandlersParamsType): AccessKeyChangeStep3ChallengeHandlersReturnType => ({
  handleManageSuccessOnChangeSmsEvent: ({ progressId }: any) =>
    manageSuccessOnChangeSmsEventHandler({
      handleSendOtpCodeStep3TrackingHandler,
      handleSendOtpCodeSuccessStep3TrackingHandler,
      setIsDisabledLastStep,
      progressId,
      setProgressId,
    }),
});

export default SecurityKeysAccessKeyChangeThirdStepChallengeHandlers;
