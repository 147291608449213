// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { LoginEmailBuilderReturnTrackingHandlersType } from './types/login-email-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const emailInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'loginEmailInput',
  },
  type: TrackingEventsEnum.LINK,
});

const emailInputErrorStateTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'loginEmailInputError',
    eventLabel: 'loginEmailInput',
    errorCategory: 'loginEmailInputError',
    errorDescription: 'invalidEmailFormat',
  },
  type: TrackingEventsEnum.LINK,
});

const rememberMeChangeCheckboxTrackingHandler = (value: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickCheckbox',
    eventLabel: 'rememberMe' + (value ? 'Checked' : 'Unchecked'),
  },
  type: TrackingEventsEnum.LINK,
});

const continuePasswordGapsButtonTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'continuePasswordGaps',
  },
  type: TrackingEventsEnum.LINK,
});

const enterLoginEmailViewTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: 'loginEmail',
    sectionFamily: 'cobranded',
    sectionCategory: 'services',
    sectionName: 'login',
    interactionType: 'page',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const LoginEmailTrackingHandlers = (
  track: TrackBuilderType
): LoginEmailBuilderReturnTrackingHandlersType => ({
  handleContinuePasswordGapsButtonClickTracking: () =>
    track(continuePasswordGapsButtonTrackingHandler()),
  handleEmailInputClickTracking: () => track(emailInputClickTrackingHandler()),
  handleEmailInputErrorStateTracking: () => track(emailInputErrorStateTrackingHandler()),
  handleEnterLoginEmailViewTracking: () => track(enterLoginEmailViewTrackingHandler()),
  handleRememberMeChangeCheckboxTracking: (value: boolean) =>
    track(rememberMeChangeCheckboxTrackingHandler(value)),
});

export default LoginEmailTrackingHandlers;
