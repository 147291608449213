const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.step2.';
const CC_DOMAIN =
  'cobrandedCC.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.step2.';

const TITLE = `${DOMAIN}title`,
  MESSAGE_01 = `${DOMAIN}message01`,
  MESSAGE_02 = `${DOMAIN}message02`,
  MESSAGE_03 = `${DOMAIN}message03`,
  REQUEST_BUTTON = `${DOMAIN}button.request`,
  CONFIRM_ADDRESS_DESCRIPTION_1 = `${CC_DOMAIN}confirmAddressDescription1`,
  CONFIRM_ADDRESS_DESCRIPTION_2 = `${CC_DOMAIN}confirmAddressDescription2`;

export { CONFIRM_ADDRESS_DESCRIPTION_1, CONFIRM_ADDRESS_DESCRIPTION_2, TITLE, MESSAGE_01, MESSAGE_02, MESSAGE_03, REQUEST_BUTTON };
