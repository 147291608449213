// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import { ModalComponent, ErrorComponent } from '@openbank/cb-ui-commons';
// Constants
import { ERROR_CONFIGURATION } from './constants/expired-access-key-modal.constants';
// Configurations
import { getModalComponentConfiguration } from './configurations/expired-access-key-modal.configurations';
// Translations
import { DESCRIPTION } from './translations/expired-access-key-modal.translations';
// Types
import { ExpiredAccessKeyModalComponentType } from './types/expired-access-key-modal.component.type';

const ExpiredAccessKeyModalComponent = ({
  show,
  onClose,
  onAccept,
  formatMessage,
}: ExpiredAccessKeyModalComponentType): React.ReactElement | null => {
  return show ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...getModalComponentConfiguration({ onClose, onAccept, formatMessage })}
    >
      <ErrorComponent {...ERROR_CONFIGURATION} description={formatMessage({ id: DESCRIPTION })} />
    </ModalComponent>
  ) : null;
};

export { ExpiredAccessKeyModalComponent };
