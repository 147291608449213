// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const generatePathRoute = (routes: string[]): string => routes.join(CharactersEnum.SLASH);

const generateAbsoluteRoute = (route: string): string => `${CharactersEnum.SLASH}${route}`;

const getPathnameFromBrowser = (): string => {
  const splitUrl = sanitizeUrl(window.location.pathname);

  return splitUrl[splitUrl.length - 1];
};

const getPathnameByLocation = (pathname: string): string => {
  const splitUrl = sanitizeUrl(pathname);

  return splitUrl[splitUrl.length - 1];
};

const sanitizeUrl = (pathname: string): string[] =>
  pathname.split('/').filter((path: string) => !path.includes(':'));

const isBaseRootPath = (path: string): boolean => path === CharactersEnum.SLASH;

const isSecondLevelRoutePath = (): boolean =>
  Boolean(window.location.pathname.split(CharactersEnum.SLASH).filter(Boolean).length > 1);

const getLastPathNameUrlItem = (): string =>
  getPathnameFromBrowser().split(CharactersEnum.SLASH).pop() || '';

const getPreviousPathName = (breadCrumbPath?: string): string =>
  (Object.values(RoutesEnumeration).find(path => path === breadCrumbPath) as string) || '';

export {
  generateAbsoluteRoute,
  generatePathRoute,
  getLastPathNameUrlItem,
  getPathnameByLocation,
  getPathnameFromBrowser,
  getPreviousPathName,
  isBaseRootPath,
  isSecondLevelRoutePath,
};
