// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  TRANSACTIONS_LANDING_ROW_DETAILS_INFO_MESSAGE_DESCRIPTION_PROPS,
  TRANSACTIONS_LANDING_ROW_DETAILS_INFO_MESSAGE_PROPS,
} from './constants/transactions-landing-row-details-info-message.constants';
// Types
import { TransactionsLandingRowDetailsInfoMessageComponentType } from './types/transactions-landing-row-details-info-message.component.type';
// Utils
import { getInfoMessage } from './utils/transactions-landing-row-details-info-message.utils';

const TransactionsLandingRowDetailsInfoMessageComponent = ({
  instalmentStatus,
  reportId,
}: TransactionsLandingRowDetailsInfoMessageComponentType): React.ReactElement | null => {
  const messageText = getInfoMessage({ instalmentStatus, reportId });

  if (!messageText) {
    return null;
  }

  return (
    <MessageComponent {...TRANSACTIONS_LANDING_ROW_DETAILS_INFO_MESSAGE_PROPS}>
      <ParagraphComponent {...TRANSACTIONS_LANDING_ROW_DETAILS_INFO_MESSAGE_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={messageText.id} values={messageText.values} />
      </ParagraphComponent>
    </MessageComponent>
  );
};
export { TransactionsLandingRowDetailsInfoMessageComponent };
