// Constants
import { linkConstants } from '@openbank/cb-ui-commons';

export default {
  DASHBOARD_POINTS_LINK_PROPS: {
    assetConfiguration: {
      id: 'right',
      colors: {
        fill: 'linkPrimaryA',
        hover: 'backgroundInfoD',
      },
      sizes: {
        height: 32,
        width: 32,
      },
      testId: 'dashboard-helper-links-icon',
    },
    testId: 'dashboard-helper-links',
    size: linkConstants.SIZES.SMALL,
  },
  DASHBOARD_POINTS_TITLE_PROPS: {
    size: linkConstants.SIZES.SMALL,
    testId: 'dashboard-spent-title-section',
  },
  DASHBOARD_TOTAL_POINTS_PROPS: {
    currency: '€',
    total: 100,
    respectively: 100,
  },
};
