// Constants
import { TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RETRY_ERROR } from './constants/transactions-epp-step1-simulation-options.handlers.constants';
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_INITIAL_ATTEMPTS } from '../constants/transactions-epp-step1-simulation-options.constants';
// Resolvers
import { simulateTransactionInstallmentsPlanResolver } from '../resolvers/transactions-epp-step1-simulation-options.resolver';
// Types
import {
  TransactionsEppStep1SimulationOptionsBuilderHandlersType,
  TransactionsEppStep1SimulationOptionsMethodBuilderHandlersType,
  TransactionsEppStep1SimulationOptionsMethodsHandlersType,
  TransactionsEppStep1SimulationOptionsSelectBuilderHandlersType,
} from './types/transactions-epp-step1-simulation-options.handlers.type';
import { TransactionsEppStep1SimulationOptionType } from '../types/transactions-epp-step1-simulation-option.type';
// Utilities
import { maxSimulationAttemptsFailedReached } from './utils/transactions-epp-step1-simulation-options.handlers.utils';
import { setupToastConfiguration } from 'components/toast/utils/toast.utils';

const updateApiRetryAttempts =
  ({
    setError,
    setToastConfiguration,
  }: Pick<
    TransactionsEppStep1SimulationOptionsBuilderHandlersType,
    'setError' | 'setToastConfiguration'
  >) =>
  (prevRetries: number): number => {
    const newRetries = prevRetries + 1;

    maxSimulationAttemptsFailedReached(newRetries)
      ? setError(true)
      : setToastConfiguration(
          setupToastConfiguration(TRANSACTION_EPP_STEP_1_SIMULATION_OPTIONS_RETRY_ERROR)
        );

    return newRetries;
  };

const simulateEppInstallmentPlanEventHandler = async ({
  handleSelectInstallmentOptionCheckClickTracking,
  id: numOfInstallments,
  setApiRetryAttempts,
  setFetching,
  setError,
  setInstallments,
  setEppPlanSimulation,
  setToastConfiguration,
  ...props
}: TransactionsEppStep1SimulationOptionsMethodBuilderHandlersType): Promise<void> => {
  setFetching(true);
  handleSelectInstallmentOptionCheckClickTracking(numOfInstallments);
  setEppPlanSimulation({});
  setInstallments(numOfInstallments);

  const [response] = await simulateTransactionInstallmentsPlanResolver({
    ...props,
    numOfInstallments,
  });

  if (response) {
    setEppPlanSimulation(response);
    setApiRetryAttempts(TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_INITIAL_ATTEMPTS);
  } else {
    setApiRetryAttempts(updateApiRetryAttempts({ setError, setToastConfiguration }));
  }

  setFetching(false);
};

const selectInstallmentOptionCheckClickHandler = async ({
  setIsAllOptionsSelected,
  ...props
}: TransactionsEppStep1SimulationOptionsSelectBuilderHandlersType): Promise<void> => {
  setIsAllOptionsSelected(false);
  await simulateEppInstallmentPlanEventHandler(props);
};

const TransactionsEppStep1SimulationOptionsHandlers = ({
  setIsAllOptionsSelected,
  ...rest
}: TransactionsEppStep1SimulationOptionsBuilderHandlersType): TransactionsEppStep1SimulationOptionsMethodsHandlersType => ({
  handleSimulateEppInstallmentPlanEvent: (id: TransactionsEppStep1SimulationOptionType['id']) =>
    simulateEppInstallmentPlanEventHandler({ ...rest, id }),
  handleSelectInstallmentOptionRadioButtonClick: (
    id: TransactionsEppStep1SimulationOptionType['id']
  ) => selectInstallmentOptionCheckClickHandler({ ...rest, setIsAllOptionsSelected, id }),
});

export { TransactionsEppStep1SimulationOptionsHandlers };
