// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsCheck3dConfirmationSecurityModuleDescriptionTrackingHandlersType } from 'containers/card-settings/views/check-3d/components/confirmation/components/security-module/components/description/handlers/types/card-settings-check-3d-confirmation-security-module-description.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const makeANewQueryLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'makeANewQuery',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsCheck3dConfirmationSecurityModuleDescriptionTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsCheck3dConfirmationSecurityModuleDescriptionTrackingHandlersType => ({
  handleMakeANewQueryLinkClickTracking: () => track(makeANewQueryLinkClickTrackingHandler()),
});

export default CardSettingsCheck3dConfirmationSecurityModuleDescriptionTrackingHandlers;
