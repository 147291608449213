// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
import { TransactionsTableHeaderLinkBarListLinksComponentCC } from './components/list-links/transactions-table-header-link-bar-list-links.component.cc';
import { TransactionsTableHeaderLinkBarDownloadModalComponent } from './components/download-modal/transactions-table-header-link-bar-download-modal.component';
// Constants
import constants from './constants/transactions-table-header-link-bar.constants';
// Hooks
import TransactionsTableHeaderLinkBarHook from './hooks/transactions-table-header-link-bar.hook';
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Styles
import { TransactionsTableHeaderLinkBarComponentStyled } from './transactions-table-header-link-bar.component.styled';
// Translations
import translations from './translations/transactions-table-header-link-bar.translations';
// Types
import { TransactionsTableHeaderLinkBarComponentPropsType } from './types/transactions-table-header-link-bar.component.type';
// Utils
import { isCC } from 'utils/app.utils';
import { isDownloadEnabled } from './utils/transactions-table-header-link-bar.component.utils';
import { getTableOrganismPropsById } from 'organisms/table-organism/utils/table-organism.utils';

const TransactionsTableHeaderLinkBarComponent = ({
  challenged,
  downloadDocumentType,
  handleHideSecurityCheckModal,
  handleShowSecurityCheckModal,
  idTable,
  isDownloadAvailable,
  listLinksConfigurations,
  progressId,
  setChallenge,
  setChallenged,
  setChallengeError,
  setDownloadDocumentType,
  setFetchingDownload,
  setIsDownloadAvailable,
  showActionBar,
  showSecurityCheckModal,
}: TransactionsTableHeaderLinkBarComponentPropsType): React.ReactElement | null => {
  const {
    handleDownloadTransactionsLinkClick,
    handleFilterTransactionsLinkClick,
    isShowDownloadModal,
    setIsShowDownloadModal,
  } = TransactionsTableHeaderLinkBarHook();
  const { isMobile } = DeviceTypeHook();

  return showActionBar ? (
    <TransactionsTableHeaderLinkBarComponentStyled {...{ isMobile }}>
      <TransactionsTableHeaderLinkBarListLinksComponentCC {...{ listLinksConfigurations }} />
      <LinkComponent
        {...constants.TRANSACTIONS_TABLE_LINK_FILTER_PROPS}
        onClick={handleFilterTransactionsLinkClick}
      >
        {!isMobile && <FormattedMessageComponent id={translations.FILTERS} />}
      </LinkComponent>
      {!isCC() && getTableOrganismPropsById(idTable).downloadButton?.show && (
        <LinkComponent
          {...constants.TRANSACTIONS_TABLE_LINK_VIEW_OR_DOWNLOAD_PROPS}
          disabled={!isDownloadEnabled(getTableOrganismPropsById(idTable))}
          onClick={handleDownloadTransactionsLinkClick}
        >
          {!isMobile && <FormattedMessageComponent id={translations.VIEW_OR_DOWNLOAD} />}
        </LinkComponent>
      )}

      <TransactionsTableHeaderLinkBarDownloadModalComponent
        {...{
          challenged,
          downloadDocumentType,
          handleHideSecurityCheckModal,
          handleShowSecurityCheckModal,
          isDownloadAvailable,
          isShowDownloadModal,
          progressId,
          setChallenge,
          setChallenged,
          setChallengeError,
          setDownloadDocumentType,
          setFetchingDownload,
          setIsDownloadAvailable,
          setIsShowDownloadModal,
          showSecurityCheckModal,
        }}
      />
    </TransactionsTableHeaderLinkBarComponentStyled>
  ) : null;
};

export default TransactionsTableHeaderLinkBarComponent;
