// Vendors
import styled from 'styled-components';
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const TransactionsReportLandingComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXxxxxl}
    ${({ theme }) => theme.spaces.spacingS};

  ${tabletMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingXl}
      ${({ theme }) => theme.spaces.spacingS};
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingM} ${({ theme }) => theme.spaces.spacingS};
  }
`;
