// Components
import { ChangeIbanStep1Component } from '../components/step1/change-iban-step1.component';
import { ChangeIbanStep2Component } from '../components/step2/change-iban-step2.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK,
  SUCCESS_TITLE,
} from '../translations/change-iban-feedback.translations';
import translations from '../translations/change-iban.translations';
// Types
import { WizardComponentType } from 'components/wizard/types/wizard.component.type';

const getChangeIbanWizardConfiguration = (): WizardComponentType => ({
  feedbackConfiguration: {
    errorConfiguration: {
      description: ERROR_DESCRIPTION,
      title: ERROR_TITLE,
      exitRoute: RoutesEnumeration.IBAN_AND_BILLING,
    },
    successConfiguration: {
      description: SUCCESS_DESCRIPTION,
      linkLabel: SUCCESS_LINK,
      title: SUCCESS_TITLE,
      exitRoute: RoutesEnumeration.IBAN_AND_BILLING,
    },
  },
  steps: [
    {
      component: ChangeIbanStep1Component,
      title: translations.TITLE_STEP_1,
    },
    {
      component: ChangeIbanStep2Component,
      title: translations.TITLE_STEP_2,
    },
  ],
});

export { getChangeIbanWizardConfiguration };
