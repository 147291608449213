// Enumeration
import { RoutesCommonEnumeration } from 'enumerations/routes.common.enumeration';
// Translations
import translations from '../translations/card-settings-activate-card.translations';

export default {
  DEFAULT_BACK_ROUTE: 'card-settings',
  FETCH_ERROR_PROPS: {
    testing: {
      loaderTestId: 'card-settings-activate-card-fetch-error-loader',
    },
  },
  ACTIVATE_CARD_SUCCESS_FEEDBACK_PROPS: {
    description: translations.SUCCESS.CONFIRMATION.CONTENT,
    exitRoute: RoutesCommonEnumeration.CARD_SETTINGS,
    title: translations.SUCCESS.CONFIRMATION.TITLE,
    linkLabel: translations.SUCCESS.BACK_BUTTTON,
  },
  ACTIVATE_CARD_ERROR_FEEDBACK_PROPS: {
    description: translations.FAILED.CONFIRMATION.CONTENT,
    exitRoute: RoutesCommonEnumeration.CARD_SETTINGS,
    title: translations.FAILED.CONFIRMATION.TITLE,
  },
};
