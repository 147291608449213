// Services
import { generateRequestPortabilityOtpService } from '../services/request-portability-step3.services';
// Types
import { ChallengeType } from 'types/challenge.type';
import { RequestPortabilityStep3ActionsApiBuilderType } from '../types/request-portability-step3-actions-api-builder.type';
// Utils
import { isValidAccessPortabilityResponse } from './utils/request-portability-step3-action.resolver.utils';

const generatePortabilityRequestOtpResolver = async (
  { progressId, portabilityParams }: RequestPortabilityStep3ActionsApiBuilderType
): Promise<[ChallengeType] | [null, true]> => {
  const [response] = await generateRequestPortabilityOtpService({ progressId, portabilityParams });

  return isValidAccessPortabilityResponse(response) ? [response] : [null, true];
};

export { generatePortabilityRequestOtpResolver };
