// Enumerations
import { CaseTypeEnum } from 'containers/transactions/views/report/enumerations/case-type.enumeration';
import { ClaimReasonEnum } from 'containers/transactions/views/report/enumerations/claim-reason.enumeration';
import { MotiveDalEnum } from 'containers/transactions/views/report/enumerations/motive-dal.enumeration';

const DISPUTE_PARAMS = {
  caseType: CaseTypeEnum.MANAGEMENT_TECH_DISPUTES,
  motiveDal: MotiveDalEnum.CHARGE_DUPLICATED,
  claimReasonType: ClaimReasonEnum.DUPLICATED_OPERATION,
  blockCard: false,
};

const TITLE_PARAMS = {
  testId: 'transactions-report-duplicate-charge-confirmation-title',
};

const DESCRIPTION_PARAMS = {
  testId: 'transactions-report-duplicate-charge-confirmation-description',
};

export { DISPUTE_PARAMS, TITLE_PARAMS, DESCRIPTION_PARAMS };
