// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const FinancingLandingTableRowComponentStyled = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: center;
    display: flex;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;

const FinancingLandingTableRowLeftComponentStyled = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: auto 2fr 1fr 1fr;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${tabletMediaQuery} {
    grid-template-columns: auto 1fr 1fr 1fr;
  }

  ${mobileMediaQuery} {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXs};
  }
`;

export { FinancingLandingTableRowComponentStyled, FinancingLandingTableRowLeftComponentStyled };
