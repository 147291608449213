// Types
import { FinancingInsurancesReturnHandlersType } from './types/financing-insurances-return.handlers.type';
import { FinancingInsurancesHandlersType } from './types/financing-insurances.handlers.type';

const insurancesInfoRefetchEndHandler = ({
  setFetchPPI,
}: FinancingInsurancesHandlersType): void => {
  setFetchPPI(false);
};

export const FinancingInsurancesHandlers = (
  props: FinancingInsurancesHandlersType
): FinancingInsurancesReturnHandlersType => ({
  handleInsurancesInfoRefetchEnd: () => insurancesInfoRefetchEndHandler(props),
});
