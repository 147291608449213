// Vendors
import React from 'react';
// Components
import { TagComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { SignOutComponent } from 'containers/security-keys/views/devices/components/sign-out/sign-out.component';
import { LastAccessComponent } from '../last-access/last-access.component';
// Constants
import { tagConstants } from '@openbank/cb-ui-commons';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Styled
import {
  DeviceHeaderComponentNotCurrentStyled,
  DeviceHeaderComponentTagStyled,
} from '../../device-header.component-styled';
// Translations
import { BLOCKED, CONNECTED } from '../../translations/device-header.translations';
// Types
import { DeviceHeaderStatusComponentType } from './types/device-header-status.component.type';

const DeviceHeaderStatusComponent = ({
  device,
  button = false,
}: DeviceHeaderStatusComponentType): React.ReactElement => {
  const { isMobile } = DeviceTypeHook();

  return (
    <>
      {device.lastSession?.active ? (
        <>
          {!isMobile && (
            <DeviceHeaderComponentTagStyled>
              <TagComponent type={tagConstants.TYPES.SUCCESS}>
                <FormattedMessageComponent uppercase id={CONNECTED} />
              </TagComponent>
            </DeviceHeaderComponentTagStyled>
          )}
          {button && <SignOutComponent device={device} />}
        </>
      ) : (
        <DeviceHeaderComponentNotCurrentStyled>
          {device.blocked && (
            <TagComponent type={tagConstants.TYPES.WARNING}>
              <FormattedMessageComponent uppercase id={BLOCKED} />
            </TagComponent>
          )}
          <LastAccessComponent device={device} />
        </DeviceHeaderComponentNotCurrentStyled>
      )}
    </>
  );
};

export { DeviceHeaderStatusComponent };
