// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Styles
import { FormattedAddressComponentStyled } from './formatted-address.component.styled';
// Translations
import { COUNTRIES_PREFFIX } from 'translations/locations.translations';
// Types
import { FormattedAddressComponentType } from './types/formatted-address.type';

export const FormattedAddressComponent = ({
  address: { countryCode, street, number, postalCode, city, additionalItems1 = '' },
  paragraphProps,
  testId,
}: FormattedAddressComponentType): React.ReactElement => (
  <FormattedAddressComponentStyled>
    <ParagraphComponent {...paragraphProps} {...{ testId }}>
      {`${street} ${number}, ${additionalItems1}`}
    </ParagraphComponent>
    <ParagraphComponent {...paragraphProps}>
      {postalCode} {city},
    </ParagraphComponent>
    <ParagraphComponent {...paragraphProps}>
      <FormattedMessageComponent id={`${COUNTRIES_PREFFIX}${countryCode}`} />
    </ParagraphComponent>
  </FormattedAddressComponentStyled>
);
