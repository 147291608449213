// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { PersonalNumberStep2BuilderReturnTrackingHandlersType } from './types/personal-number-step2-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';
// Constants
import {
  CLICK_LINK_TRACKING,
  REQUEST_NEW_PHONE_OTP_TRACKING,
} from './constants/personal-number-step2.tracking.handlers.constants';

const requestNewNumberOtpLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: CLICK_LINK_TRACKING,
    eventLabel: REQUEST_NEW_PHONE_OTP_TRACKING,
  },
  type: TrackingEventsEnum.LINK,
});

const PersonalNumberCancelTrackingHandlers = (
  track: TrackBuilderType
): PersonalNumberStep2BuilderReturnTrackingHandlersType => ({
  handleRequestNewNumberOtpLinkClickTracking: () =>
    track(requestNewNumberOtpLinkClickTrackingHandler()),
});

export default PersonalNumberCancelTrackingHandlers;
