// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
import FinancingFastCashSecondStepActionsComponent from './components/actions/financing-fast-cash-second-step-actions.component';
import VerifyCorrectDataComponent from './components/verify-correct-data/verify-correct-data.component';
// Configurations
import { getChallengeConfiguration } from './configurations/financing-fast-cash-second-step.component.configurations';
// Hooks
import FinancingFastCashSecondStepHook from './hooks/financing-fast-cash-second-step.hook';
// Styles
import { FinancingFastCashSecondStepContainerStyled } from './financing-fast-cash-second-step.component.styled';

const FinancingFastCashSecondStepComponent = (): React.ReactElement => {
  const {
    cardId,
    challengeError,
    handleChallengeError,
    handleChallengeSuccess,
    handleRetryChallengeError,
    params,
  } = FinancingFastCashSecondStepHook();

  return (
    <FinancingFastCashSecondStepContainerStyled>
      <VerifyCorrectDataComponent />
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          params,
          cardId,
          challengeError,
          handleChallengeError,
          handleChallengeSuccess,
          handleRetryChallengeError,
        })}
      />
      <FinancingFastCashSecondStepActionsComponent {...{ handleRetryChallengeError }} />
    </FinancingFastCashSecondStepContainerStyled>
  );
};

export default FinancingFastCashSecondStepComponent;
