// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Types
import { getRejectedDocumentsMap } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/hooks/utils/upload-documents-section.utils';
import { RejectedDocumentType } from 'containers/financing/views/credit-limit-increase/contexts/types/document-rejected.type';
import { MainAccountSectionHookReturnPropsType } from './types/main-account-section-return-props.type';
// Utils
import { isUploadDocumentRejected } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-documents-section/utils/upload-documents-section.utils';

const useMainAccoutnSectionHook = (
  documentsRejected?: RejectedDocumentType[]
): MainAccountSectionHookReturnPropsType => {
  const rejectedDocumentsMap = getRejectedDocumentsMap(documentsRejected);
  const isMainBankAccountRejected = isUploadDocumentRejected({
    uploadDocumentId: UploadDocumentIdsEnumeration.MAIN_BANK_ACCOUNT,
    rejectedDocumentsMap,
  });
  const shouldRenderSection = (): boolean => {
    if (!documentsRejected || documentsRejected.length === NUMBER.ZERO) return true;
    return documentsRejected.length > NUMBER.ZERO && isMainBankAccountRejected;
  };

  return {
    isMainBankAccountRejected,
    shouldRenderSection: shouldRenderSection(),
  };
};

export { useMainAccoutnSectionHook };
