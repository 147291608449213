// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { SWITCH_TRAVEL_PLUS_SERVICE_CONFIGURATION } from './constants/travel-plus.service.constants';
// Types
import { TravelPlusApiBuilderType } from '../types/travel-plus-api-builder.type';

const switchTravelPlusStatusService = async ({
  id,
  travelPlus,
}: TravelPlusApiBuilderType): Promise<[Record<string, never>, Response]> =>
  apiUtilsCb.post({
    ...SWITCH_TRAVEL_PLUS_SERVICE_CONFIGURATION,
    pathParams: { id },
    params: { travelPlus },
  });

export { switchTravelPlusStatusService };
