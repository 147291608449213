// Vendors
import React from 'react';
// Components
import { FinancingInsurancesCancellationLPISidebarComponent } from './components/sidebar/financing-insurances-cancellation-lpi-sidebar.component';
import { FinancingInsurancesCancellationLPIConfirmationComponent } from './components/confirmation/financing-insurances-cancellation-lpi-confirmation.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Constants
import { FINANCING_INSURANCES_CANCELLATION_LPI_FEEDBACK_PROPS } from './constants/financing-insurances-cancellation-lpi.constants';

const FinancingInsurancesCancellationLPIComponent = (): React.ReactElement => (
  <OperativeLayoutComponent sidebarComponent={FinancingInsurancesCancellationLPISidebarComponent}>
    <WizardFeedbackComponent {...FINANCING_INSURANCES_CANCELLATION_LPI_FEEDBACK_PROPS}>
      <FinancingInsurancesCancellationLPIConfirmationComponent />
    </WizardFeedbackComponent>
  </OperativeLayoutComponent>
);

export { FinancingInsurancesCancellationLPIComponent };
