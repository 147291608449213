// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameAvatarBuilderReturnTrackingHandlersType } from './types/profile-personal-name-avatar-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const profilePersonalNameEditAvatarAssetClickHandlerTracking = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'editAvatar',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameAvatarTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameAvatarBuilderReturnTrackingHandlersType => ({
  handleProfilePersonalNameEditAvatarAssetClickTracking: () =>
    track(profilePersonalNameEditAvatarAssetClickHandlerTracking()),
});

export default ProfilePersonalNameAvatarTrackingHandlers;
