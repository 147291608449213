// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameHookType } from './types/profile-personal-name.hook.type';

const ProfilePersonalNameHook = (): ProfilePersonalNameHookType => {
  const { formatMessage } = useCbIntl();
  const { profile } = AppContextConsumerHook(),
    { userName = CharactersEnum.NO_VALUE, title } = profile?.userDetails || {};

  return { title, userName, formatMessage };
};

export default ProfilePersonalNameHook;
