// Vendors
import React from 'react';
// Components
import { UploadDocumentsTipsComponent } from 'components/upload-doc-tips/upload-documents-tips.component';
import { TransactionsReportPaymentDocumentsActionsComponent } from './components/actions/transactions-report-payment-documents-actions.component';
import { UploadDocumentGroupComponent } from 'components/upload-document-group/upload-document-group.component';
// Contexts
import { useTransactionsReportPaymentContextConsumerHook } from '../../contexts/transactions-report-payment.context';
// Styles
import { TransactionsReportPaymentDocumentsComponentStyled } from './transactions-report-payment-documents.component.styled';

export const TransactionsReportPaymentDocumentsComponent = (): React.ReactElement => {
  const { files, setFiles } = useTransactionsReportPaymentContextConsumerHook();

  return (
    <TransactionsReportPaymentDocumentsComponentStyled>
      <UploadDocumentsTipsComponent />
      <UploadDocumentGroupComponent files={files} onChangeFiles={setFiles} />
      <TransactionsReportPaymentDocumentsActionsComponent files={files} setFiles={setFiles} />
    </TransactionsReportPaymentDocumentsComponentStyled>
  );
};
