// Utils
import { getPaymentMethodLabel } from 'containers/financing/views/payment-method/utils/financing-payment-method.utils';
// Types
import { FinancingPaymentMethodFirstStepPaymentHandlersType } from './types/financing-payment-method-first-step-payment.handlers.type';
import { FinancingPaymentMethodFirstStepPaymentReturnHandlersType } from './types/financing-payment-method-first-step-payment-return.handlers.type';

const firstStepPaymentRenderHandler = ({
  accountBalancePercentage,
  fixedPaymentAmount,
  formatMessage,
  formatNumber,
  paymentMethodCode,
  setError,
  setPaymenyMethodLabel,
}) => {
  const paymentMethodLabel = getPaymentMethodLabel({
    accountBalancePercentage,
    fixedPaymentAmount,
    formatMessage,
    formatNumber,
    paymentMethodCode,
  });

  if (!paymentMethodLabel) {
    setError(true);
  }

  setPaymenyMethodLabel(paymentMethodLabel);
};

const FinancingPaymentMethodFirstStepPaymentHandlers = (
  props: FinancingPaymentMethodFirstStepPaymentHandlersType
): FinancingPaymentMethodFirstStepPaymentReturnHandlersType => ({
  handleFirstStepPaymentRender: () => firstStepPaymentRenderHandler(props),
});

export default FinancingPaymentMethodFirstStepPaymentHandlers;
