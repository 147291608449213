// Vendors
import { messageConstants, buttonConstants } from '@openbank/cb-ui-commons';

const MESSAGE_PROPS = {
  type: messageConstants.TYPES.WARNING,
};

const TITLE_PROPS = {
  bold: true,
};

const BUTTON_PROPS = {
  rwdFull: false,
  type: buttonConstants.TYPES.SECONDARY,
};

export { MESSAGE_PROPS, TITLE_PROPS, BUTTON_PROPS };
