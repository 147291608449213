// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const TitleSectionMobileComponentStyled = styled.header<{
  isHybrid: boolean;
  notchSize: number;
}>`
  width: 100%;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundTertiaryE};
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spaces.spacingS};
  ${({ isHybrid, notchSize = 0 }) =>
    isHybrid &&
    css`
      position: sticky;
      top: 0;
      padding-top: ${convertPxsToRems({ pixels: notchSize, base: 14 })};
      z-index: 2;
    `}
`;

const TitleSectionMobileAssetComponentStyled = styled.aside`
  flex: 0 0 auto;
`;

const TitleSectionMobileLabelComponentStyled = styled.div`
  flex: 1;
  text-align: center;
`;

const RenderTooltipContentConfigurationWrapperStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const RenderTooltipContentConfigurationStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
`;

export {
  RenderTooltipContentConfigurationWrapperStyled,
  RenderTooltipContentConfigurationStyled,
  TitleSectionMobileComponentStyled,
  TitleSectionMobileAssetComponentStyled,
  TitleSectionMobileLabelComponentStyled,
};
