const DOMAIN = 'cobranded.services.userSettings.privateArea.cancelFlow.genericComponent.cbModal.';

const DEFAULT_BUTTON = `${DOMAIN}yesCancelProcessButton`;
const DEFAULT_DESCRIPTION_01 = `${DOMAIN}description01`;
const DEFAULT_DESCRIPTION_02 = `${DOMAIN}description02`;
const DEFAULT_LINK = `${DOMAIN}noGoBackToProcessLink`;
const DEFAULT_TITLE = `${DOMAIN}title`;

export {
  DEFAULT_BUTTON,
  DEFAULT_TITLE,
  DEFAULT_DESCRIPTION_01,
  DEFAULT_DESCRIPTION_02,
  DEFAULT_LINK,
};
