const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step2.';

const DESCRIPTION = `${DOMAIN}termsAndConditions.description`,
  TERMS_ACCEPTANCE_CONSENT = `${DOMAIN}termsAcceptance.cbCheckBox.label`,
  MESSAGE = `${DOMAIN}consents.cbMessage.descriptionV2`,
  LEGAL_TEXT = `${DOMAIN}legalTextV2`,
  POLICY_HOLDER_TEXT = `${DOMAIN}policyHolderText`,
  TITLE = `${DOMAIN}title`,
  SECOND_TITLE = `${DOMAIN}secondTitle`;

export {
  TERMS_ACCEPTANCE_CONSENT,
  DESCRIPTION,
  LEGAL_TEXT,
  MESSAGE,
  POLICY_HOLDER_TEXT,
  TITLE,
  SECOND_TITLE,
};
