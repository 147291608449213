// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS } from 'constants/wizard/wizard.constants';
// Components
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Hooks
import { SecurityKeysSignatureKeyChangeThirdStepActionsHook } from './hook/security-keys-signature-key-change-third-step-actions.hook';
// Translations
import translations from './translations/security-keys-signature-key-change-third-step.translations';

const SecurityKeysSignatureKeyChangeThirdStepActionsComponent = (): React.ReactElement => {
  const { fetching, handleFinishActionsEvent, isDisabledLastStep } =
    SecurityKeysSignatureKeyChangeThirdStepActionsHook();

  return (
    <WizardStepFooterComponent
      {...WIZARD_LAST_STEP_BUTTON_CONFIRM_PROPS}
      rightButtonConfiguration={{
        onClick: handleFinishActionsEvent,
        children: <FormattedMessageComponent id={translations.NEXT_FINAL_STEP} />,
        disabled: isDisabledLastStep || fetching,
        loading: fetching,
      }}
    />
  );
};

export default SecurityKeysSignatureKeyChangeThirdStepActionsComponent;
