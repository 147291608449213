export default {
  TRANSACTIONS_TABLE_LINK_FILTER_PROPS: {
    assetConfiguration: {
      id: 'filter-a-light',
      colors: {
        fill: 'linkPrimaryA',
        hover: 'backgroundInfoD',
      },
      sizes: {
        height: 24,
        width: 24,
      },
      testId: 'transactions-all-filter-asset',
    },
    disabled: false,
    iconOnLeft: true,
    testId: 'transactions-all-filter',
  },
};
