// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import { generateChangeIbanOtpService } from '../services/change-iban-step2.services';
// Types
import { ChallengeType } from 'types/challenge.type';
import { ChangeIbanStep2GenerateApiBuilderType } from './types/change-iban-step2-generate-api-builder.type';

const generateChangeIbanOtpResolver = async ({
  cardId,
  ibanValue,
  progressId,
}: ChangeIbanStep2GenerateApiBuilderType): Promise<[ChallengeType] | [null, true]> => {
  const [response, { status }] = await generateChangeIbanOtpService({
    cardId,
    ibanValue,
    progressId,
  });

  return requestHasSuccessStatus(status) ? [response] : [null, true];
};

export { generateChangeIbanOtpResolver };
