// Vendors
import React from 'react';
// Components
import { LineSeparatorComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import RequestPortabilityStep2ActionsComponent from './components/actions/request-portability-step2-actions.component';
import RequestPortabilityStep2EmailOptionsComponent from './components/email-options/request-portability-step2-email-options.component';
// Constants
import constants from './constants/request-portability-step2.constants';
// Styles
import {
  RequestPortabilityStep2ContainerStyled,
  RequestPortabilityStep2ListItemStyled,
  RequestPortabilityStep2ListStyled,
} from './request-portability-step2.component.styled';

const RequestPortabilityStep2Component = (): React.ReactElement => (
  <RequestPortabilityStep2ContainerStyled>
    <RequestPortabilityStep2ListStyled>
      {constants.REQUEST_PORTABILITY_STEP2_MESSAGES.map(config => (
        <RequestPortabilityStep2ListItemStyled key={config.id}>
          <ParagraphComponent {...config}>
            <FormattedMessageComponent
              id={config.text}
              values={{ b: boldText => <strong>{boldText}</strong> }}
            />
          </ParagraphComponent>
        </RequestPortabilityStep2ListItemStyled>
      ))}
      <LineSeparatorComponent {...constants.LINE_SEPARATOR_PROPS} />
    </RequestPortabilityStep2ListStyled>
    <RequestPortabilityStep2EmailOptionsComponent />
    <RequestPortabilityStep2ActionsComponent />
  </RequestPortabilityStep2ContainerStyled>
);

export default RequestPortabilityStep2Component;
