// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { CancelContractStep1HandlersTrackingReturnType } from './types/support-cancel-contract-first-step-handlers-tracking-return.type';

const dropdownSelectTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickDropdown',
    eventLabel: 'selectCancelContractDropdown',
  },
  type: TrackingEventsEnum.LINK,
});

const dropdownInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickDropdown',
    eventLabel: 'cancelContractDropdow',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterFirstStepCancelContractTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/CancelContract/step01/cancelContract',
    sectionFamily: 'banking',
    sectionCategory: 'support',
    sectionName: 'cards',
    interactionType: 'page',
    serviceType: 'productSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SupportCancelContractFirstStepTrackingHandlers = (
  track: TrackBuilderType
): CancelContractStep1HandlersTrackingReturnType => ({
  handleDropdownSelectTrackingHandler: () => track(dropdownSelectTrackingHandler()),
  handleOnEnterFirstStepCancelContractTracking: () =>
    track(onEnterFirstStepCancelContractTrackingHandler()),
  handleDropdownInputClickTrackingHandler: () => track(dropdownInputClickTrackingHandler()),
});

export default SupportCancelContractFirstStepTrackingHandlers;
