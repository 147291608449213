const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.change3DSecure.cbChange3DSecure.cbWizard.cbFeedback.';

const ERROR_DESCRIPTION = `${DOMAIN}errorDescription`,
  SAME_CODE_ERROR_DESCRIPTION = `${DOMAIN}sameCodeErrorDescription`,
  ERROR_TITLE = `${DOMAIN}errorTitle`,
  GO_TO_SETTINGS = `${DOMAIN}backToSettingsButton`,
  SUCCESS_DESCRIPTION = `${DOMAIN}successDescription`,
  SUCCESS_TITLE = `${DOMAIN}successTitle`;

export {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  GO_TO_SETTINGS,
  SAME_CODE_ERROR_DESCRIPTION,
  SUCCESS_DESCRIPTION,
  SUCCESS_TITLE,
};
