// Services
import { initRecoveryKeyService } from '../services/recovery-key-step1-actions.service';
// Types
import { RecoveryKeyStep1ApiBuilderType } from '../types/recovery-key-step-1-api-builder.type';
import { ChallengeSssApiResponseType } from 'types/challenge/challenge-sss-api-response.type';
// Utilities
import { isLegitSSSChallengeResponse } from 'utils/challenge.utils';

const initRecoveryKeyResolver = async (
  props: RecoveryKeyStep1ApiBuilderType
): Promise<[ChallengeSssApiResponseType] | [null, true]> => {
  const [response] = await initRecoveryKeyService(props);

  return isLegitSSSChallengeResponse(response)
    ? [response as ChallengeSssApiResponseType]
    : [null, true];
};

export { initRecoveryKeyResolver };
