// Vendors
import styled from 'styled-components';

export const PurchaseAmountComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const PurchaseAmountItemStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
