// Constants
import { VIEW_MORE_ACTION } from 'components/quick-actions/constants/quick-actions-configurations.common.constants';
// Enumerations
import { QuickActionsSectionIdsEnumeration } from 'enumerations/quick-actions/quick-actions-section-ids.enumeration';
// Types
import {
  QuickActionsBuilderHandlersType,
  QuickActionsBuilderReturnHandlersType,
  QuickActionsClickBuilderHandlersType,
  QuickActionsMethodBuilderHandlersType,
} from './types/quick-actions.handlers.type';

const quickActionClickHandler = ({
  documentData,
  handleQuickActionClickTracking,
  id,
  navigate,
  setIsModalOpen,
  setIsExpiredDocumentModalOpen,
  tracking,
  url,
}: QuickActionsClickBuilderHandlersType): void => {
  if (
    id === QuickActionsSectionIdsEnumeration.REQUEST_ADDITIONAL_CREDIT &&
    documentData[0]?.documentFlagRenew
  ) {
    setIsExpiredDocumentModalOpen(true);
    return;
  }
  if (id === VIEW_MORE_ACTION.id) {
    setIsModalOpen(true);
  } else {
    handleQuickActionClickTracking(tracking);
    navigate(url);
  }
};

const clickCloseModalHandler = ({ setIsModalOpen }: QuickActionsBuilderHandlersType): void => {
  setIsModalOpen(false);
};

const QuickActionsHandlers = (
  builderProps: QuickActionsBuilderHandlersType
): QuickActionsBuilderReturnHandlersType => ({
  handleQuickActionClick: (props: QuickActionsMethodBuilderHandlersType) =>
    quickActionClickHandler({ ...props, ...builderProps }),
  handleClickCloseModal: () => clickCloseModalHandler(builderProps),
});

export { QuickActionsHandlers };
