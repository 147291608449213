// Resolvers
import { checkClientUserSessionHealthResolver } from '../resolvers/session-health.resolver';
// Types
import {
  SessionHealthHandlersBuilderType,
  SessionHealthHandlersMethodsHandlersType,
} from './types/session-health.handlers.type';
// Utilities
import { clearSessionData } from 'utils/session/session.utils';

const checkClientUserSessionHealthHandler = async ({
  setFetching,
  setError,
}: SessionHealthHandlersBuilderType): Promise<void> => {
  setFetching(true);

  const [, error] = await checkClientUserSessionHealthResolver();

  if (error) {
    clearSessionData();
    setError(true);
  }
  setFetching(false);
};

const SessionHealthHandlers = (
  props: SessionHealthHandlersBuilderType
): SessionHealthHandlersMethodsHandlersType => ({
  handleCheckClientSessionHealthEvent: () => checkClientUserSessionHealthHandler(props),
});

export { SessionHealthHandlers };
