// Constants
import { BILLED_TRANSACTION_STATUS } from 'containers/transactions/views/epp/constants/transactions-epp.constants';
// Types
import { HasInsufficientCreditType } from './types/has-insufficient-credit.type';
import { TransactionsEppStep1HookUtilsType } from './types/transactions-epp-step1.hook.utils.type';
// Utilities
import { simulationPlanIsNotDone } from 'containers/transactions/views/epp/components/step1/utils/transactions-epp-step1.utils';
import { cardActive } from 'utils/card.utils';

const eppPlanInstallmentStep1FooterIsDisabled = ({
  eppPlanSimulation,
  status,
}: TransactionsEppStep1HookUtilsType): boolean =>
  Boolean(!cardActive(status) || simulationPlanIsNotDone(eppPlanSimulation));

const hasInsufficientCredit = ({
  availableAmount,
  transactionLabel,
  totalAmount,
}: HasInsufficientCreditType): boolean =>
  Boolean(!BILLED_TRANSACTION_STATUS.includes(transactionLabel) && availableAmount < totalAmount);

export { eppPlanInstallmentStep1FooterIsDisabled, hasInsufficientCredit };
