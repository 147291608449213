// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Event handlers
import { TransactionsEppStep1SimulationOptionsAllSliderHandlers } from '../handlers/transactions-epp-step1-simulation-options-all-slider.handlers';
import { TransactionsEppStep1SimulationOptionsAllSliderTrackingHandlers } from '../handlers/transactions-epp-step1-simulation-options-all-slider.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsEppStep1SimulationOptionsAllSliderHookType } from './types/transactions-epp-step1-simulation-options-all-slider.hook.type';
import { TransactionsEppStep1SimulationOptionsAllSliderComponentType } from '../types/transactions-epp-step1-simulation-options-all-slider.component.type';

const useTransactionsEppStep1SimulationOptionsAllHook = (
  props: TransactionsEppStep1SimulationOptionsAllSliderComponentType
): TransactionsEppStep1SimulationOptionsAllSliderHookType => {
  const { formatMessage } = useCbIntl();

  const { installments, isAllOptionsSelected, setInstallments } =
      useTransactionsEppContextConsumerHook(),
    { transactionDetails } = useTransactionsEppContextConsumerHook(),
    { maxNumOfInstallments, minNumOfInstallments } = transactionDetails;

  return {
    ...TransactionsEppStep1SimulationOptionsAllSliderHandlers({
      ...AppTrackingHook(TransactionsEppStep1SimulationOptionsAllSliderTrackingHandlers),
      ...props,
      maxNumOfInstallments,
      minNumOfInstallments,
      setInstallments,
    }),
    installments,
    formatMessage,
    isAllOptionsSelected,
    maxNumOfInstallments,
    minNumOfInstallments,
  };
};

export { useTransactionsEppStep1SimulationOptionsAllHook };
