// Vendors
import { useEffect } from 'react';
// Components
import { setupToastConfiguration } from 'components/toast/utils/toast.utils';
// Hooks
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Translations
import translations from '../translations/enable-disable-card-confirmation-modal.translations';
// Types
import { UseConfirmationModalToastMessageProps } from './types/use-confirmation-modal-toast-message-props.type';

const { ON_DISABLE_ERROR, ON_DISABLE_SUCCESS, ON_ENABLE_ERROR, ON_ENABLE_SUCCESS } = translations;

export const useConfirmationModalToastMessage = ({
  isEnabled,
  loadingStatus,
}: UseConfirmationModalToastMessageProps): void => {
  const { setToastConfiguration } = ToastContextConsumerHook();

  useEffect(() => {
    switch (loadingStatus) {
      case 'success':
        setToastConfiguration(
          setupToastConfiguration({
            error: false,
            description: isEnabled ? ON_DISABLE_SUCCESS : ON_ENABLE_SUCCESS,
          })
        );
        break;
      case 'error':
        setToastConfiguration(
          setupToastConfiguration({
            error: true,
            description: isEnabled ? ON_DISABLE_ERROR : ON_ENABLE_ERROR,
          })
        );
        break;
    }
  }, [isEnabled, loadingStatus, setToastConfiguration]);
};
