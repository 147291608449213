// Vendors
import React from 'react';
// Components
import RecoveryKeyStep4CreditCardComponent from './components/credit-card/recovery-key-step4-credit-card.component';
import RecoveryKeyStep4SignatureKeyComponent from './components/signature-key/recovery-key-step4-signature-key.component';
import RecoveryKeyStep4SecurityQuestionsComponent from './components/security-questions/recovery-key-step4-security-questions.component';
// Contexts
import { useRecoveryOptionContextConsumerHook } from '../../contexts/recovery-key.context';
// Enumerations
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';

const RecoveryKeyStep4Component = (): React.ReactElement => {
  const { recoveryOption } = useRecoveryOptionContextConsumerHook();

  switch (recoveryOption) {
    case PasswordRecoveryOptionsEnumeration.CREDIT_CARD:
      return <RecoveryKeyStep4CreditCardComponent />;
    case PasswordRecoveryOptionsEnumeration.SIGNATURE_KEY:
      return <RecoveryKeyStep4SignatureKeyComponent />;
    case PasswordRecoveryOptionsEnumeration.SECURITY_QUESTIONS:
      return <RecoveryKeyStep4SecurityQuestionsComponent />;
    default:
      return <></>;
  }
};

export default RecoveryKeyStep4Component;
