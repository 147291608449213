// Vendors
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled from 'styled-components';

const PasswordModuleComponentStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  'data-testid': testId,
}))`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

PasswordModuleComponentStyled.displayName = 'PasswordModuleComponentStyled';

const PasswordModuleRightComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    width: 100%;
  }
`;

PasswordModuleRightComponentStyled.displayName = 'PasswordModuleRightComponentStyled';

const PasswordModuleAssetWrapperStyled = styled.div`
  ${mobileMediaQuery} {
    display: none;
  }
`;

PasswordModuleAssetWrapperStyled.displayName = 'PasswordModuleAssetWrapperStyled';

export {
  PasswordModuleComponentStyled,
  PasswordModuleRightComponentStyled,
  PasswordModuleAssetWrapperStyled,
};
