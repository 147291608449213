// Vendors
import React from 'react';
// Components
import FilterModalInputRangeDatePickerComponent from '../components/input-range-date-picker/filter-modal-input-range-date-picker.component';
import FilterModalInputRangeAmountComponent from '../components/input-range-amount/filter-modal-input-range-amount.component';
// Constants
import {
  MiscellaneousCurrencyConstants,
  MiscellaneousDateConstants,
} from '@openbank/cf-ui-static-data';
// Enumerations
import { FilterModalInputRangeTypesEnumeration } from '../enumerations/filter-modal-input-range-types.enumeration';
// Types
import { GetInputRangePropsType, formatAmountPropsType } from './types/get-input-range.utils.type';
import { formatCurrencyInput } from 'utils/format-currency-input.utils';

const getInputRangeComponent = ({
  formValues,
  showLineSeparator,
  formatDate,
  formatNumber,
  handleFormValuesChange,
  title,
  type,
}: GetInputRangePropsType): React.ReactElement | null => {
  if (type === FilterModalInputRangeTypesEnumeration.DATE) {
    return (
      <FilterModalInputRangeDatePickerComponent
        {...{
          showLineSeparator,
          onChangeFromInput: value =>
            handleFormValuesChange({
              field: 'fromDate',
              value: {
                value,
                label: formatDate(value, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY),
              },
            }),
          onChangeToInput: value =>
            handleFormValuesChange({
              field: 'toDate',
              value: {
                value,
                label: formatDate(value, MiscellaneousDateConstants.FORMAT_DD_MM_YYYY),
              },
            }),
          fromValue: formValues?.fromDate?.value,
          toValue: formValues?.toDate?.value,
          title,
        }}
      />
    );
  }

  if (type === FilterModalInputRangeTypesEnumeration.AMOUNT) {
    return (
      <FilterModalInputRangeAmountComponent
        {...{
          fromValue: formValues?.fromAmount?.value,

          onChangeFromInput: (fromAmount: string) =>
            handleFormValuesChange({
              field: 'fromAmount',
              value: {
                value: fromAmount,
                label: formatAmount({ amountValue: fromAmount, formatNumber }),
              },
            }),
          onChangeToInput: (toAmount: string) =>
            handleFormValuesChange({
              field: 'toAmount',
              value: {
                value: toAmount,
                label: formatAmount({ amountValue: toAmount, formatNumber }),
              },
            }),
          showLineSeparator,
          toValue: formValues?.toAmount?.value,
        }}
      />
    );
  }

  return null;
};

const formatAmount = ({ amountValue, formatNumber }: formatAmountPropsType): string => {
  const parseAmount = parseFloat(amountValue);

  return amountValue === ''
    ? ''
    : formatNumber(parseAmount, {
        currency: 'EUR',
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
      }) === '0,00 €'
    ? ''
    : formatNumber(parseAmount, {
        currency: 'EUR',
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
      });
};

export { getInputRangeComponent, formatAmount };
