// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { LoginGapsTitleBuilderReturnTrackingHandlersType } from './types/login-gaps-title-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const editUsernameAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'forgetPassword',
  },
  type: TrackingEventsEnum.LINK,
});

const LoginGapsTitleTrackingHandlers = (
  track: TrackBuilderType
): LoginGapsTitleBuilderReturnTrackingHandlersType => ({
  handleEditUsernameAssetClickTracking: () => track(editUsernameAssetClickTrackingHandler()),
});

export default LoginGapsTitleTrackingHandlers;
