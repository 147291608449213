// Constants
import { CONTROLLED_LOGOUT } from 'constants/storage/storage.constants';
// Resolvers
import { logoutResolver } from 'resolvers/logout.resolvers';
// Types
import { LogoutBuilderReturnHandlersType } from './types/logout-builder-return.handlers.type';
// Utilities
import { clearSessionData } from 'utils/session/session.utils';

const makeUserLogoutEventHandler = async (): Promise<void> => {
  localStorage.setItem(CONTROLLED_LOGOUT, 'true');
  await logoutResolver();
  clearSessionData();
  window.location.reload();
};

const LogoutHandlers = (): LogoutBuilderReturnHandlersType => ({
  handleMakeUserLogoutEvent: () => makeUserLogoutEventHandler(),
});

export default LogoutHandlers;
