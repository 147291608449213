// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const DepositMoneyInformativeContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

const DepositMoneyMessageStyled = styled.div`
  align-self: stretch;
  flex-grow: 0;
  font-size: ${convertPxsToRems({ pixels: 14, base: 14 })};
  letter-spacing: normal;
  text-align: left;
`;

export { DepositMoneyInformativeContentStyled, DepositMoneyMessageStyled };
