// Services
import { refreshTokenService } from 'services/refresh-token/refresh-token.service';
// Utils
import { isValidRefreshResponse } from 'utils/refresh-token/refresh-token.utils';

const refreshTokenResolver = async (): Promise<any> => {
  const [response] = await refreshTokenService();

  return isValidRefreshResponse(response) ? [response] : [null, true];
};

export { refreshTokenResolver };
