// Constants
import constants from 'containers/personal/views/access-portability/views/request-portability/services/constants/request-portability.services.constants';
// Types
import { ChallengeWrapperComponentType } from 'components/challenge-wrapper/types/challenge-wrapper.component.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';

export const getChallengeConfiguration = ({
  challengeError,
  handleChallengeError,
  handleChallengeRetryButtonClick,
  handleChallengeSuccess,
  portabilityParams,
}: GetChallengeConfigurationType): ChallengeWrapperComponentType => ({
  baseService: constants.REQUEST_PORTABILITY_RIGHTS_ENDPOINT,
  baseServiceBuilder: {
    params: portabilityParams,
  },
  baseServiceMethod: constants.REQUEST_PORTABILITY_RIGHTS_METHOD,
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
