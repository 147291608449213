import { assetConstants, linkConstants } from '@openbank/cb-ui-commons';
import { paragraphConstants } from '@openbank/cb-ui-commons';

const WIZARD_STEP_FOOTER_CANCEL_MODAL_TITLE = {
    testId: 'wizard-step-footer-cancel-modal-exit-title',
    size: paragraphConstants.SIZES.XL,
    bold: true,
  },
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_01_PROPS = {
    testId: 'wizard-step-footer-cancel-modal-description-01',
    size: paragraphConstants.SIZES.M,
  },
  WIZARD_STEP_FOOTER_CANCEL_MODAL_ASSET_PROPS = {
    id: 'Attention_Alternative',
    sizes: {
      height: 128,
      width: 128,
    },
    type: assetConstants.TYPES.ILLUSTRATION,
    testId: 'inactivity-expired-asset',
  },
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_02_PROPS = {
    testId: 'wizard-step-footer-cancel-modal-description-02',
    size: paragraphConstants.SIZES.M,
  },
  WIZARD_STEP_FOOTER_CANCEL_MODAL_BUTTON_PROPS = {
    testId: 'wizard-step-footer-cancel-modal-button',
  },
  WIZARD_STEP_FOOTER_CANCEL_MODAL_LINK_PROPS = {
    type: linkConstants.TYPES.ACTION_PRIMARY,
    testId: 'wizard-step-footer-cancel-modal-link',
  };

export {
  WIZARD_STEP_FOOTER_CANCEL_MODAL_TITLE,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_01_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_PARAGRAPH_02_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_BUTTON_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_LINK_PROPS,
  WIZARD_STEP_FOOTER_CANCEL_MODAL_ASSET_PROPS,
};
