// Vendors
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import CardResumeComponent from 'components/card-resume/card-resume.component';
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Constants
import constants from './constants/enable-disable-card-confirmation-modal.constants';
import FormattedMessageComponent from '../formatted-message/formatted-message.component';
// Styles
import {
  ConfirmationModalComponentStyled,
  ConfirmationModalFeaturesComponentStyled,
  ConfirmationModalFeaturesItemComponentStyled,
  ConfirmationModalFooterComponentStyled,
} from './enable-disable-card-confirmation-modal.component.styled';
// Translations
import translations from './translations/enable-disable-card-confirmation-modal.translations';
import {
  getModalConfirmLabel,
  getModalDescription,
  getModalTitle,
  isLoading,
} from './utils/enable-disable-card-confirmation-modal.utils';
// Types
import { EnableDisableCardConfirmationModalProps } from './types/enable-disable-card-confirmation-modal.types';
import { useConfirmationModalToastMessage } from './hooks/enable-disable-card-confirmation-modal.hook';

const {
  CONFIRMATION_MODAL_BUTTON_PROPS,
  CONFIRMATION_MODAL_DESCRIPTION_PROPS,
  CONFIRMATION_MODAL_DETAILS_PROPS,
  CONFIRMATION_MODAL_FEATURES,
  CONFIRMATION_MODAL_FEATURES_BULLET_PROPS,
  CONFIRMATION_MODAL_LINK_PROPS,
} = constants;

const EnableDisableCardConfirmationModal = ({
  isEnabled,
  loadingStatus = 'idle',
  onClose,
  onConfirm,
  onDismiss,
}: EnableDisableCardConfirmationModalProps): React.ReactElement | null => {
  useConfirmationModalToastMessage({
    isEnabled,
    loadingStatus,
  });

  switch (loadingStatus) {
    case 'error':
    case 'success':
      return null;

    case 'idle':
    case 'loading':
    default:
      return (
        <ModalComponent
          isIosDevice={isIosWebView()}
          linkConfiguration={{
            ...CONFIRMATION_MODAL_LINK_PROPS,
            children: <FormattedMessageComponent id={translations.DISMISS} />,
            disabled: isLoading(loadingStatus),
            isLoading: isLoading(loadingStatus),
            onClick: onDismiss,
          }}
          primaryButtonConfiguration={{
            ...CONFIRMATION_MODAL_BUTTON_PROPS,
            children: <FormattedMessageComponent id={getModalConfirmLabel(isEnabled)} />,
            disabled: isLoading(loadingStatus),
            loading: isLoading(loadingStatus),
            isLoading: isLoading(loadingStatus),
            onClick: onConfirm,
          }}
          onHeaderClose={onClose ?? onDismiss}
          title={<FormattedMessageComponent id={getModalTitle(isEnabled)} />}
        >
          <ConfirmationModalComponentStyled>
            <CardResumeComponent {...CONFIRMATION_MODAL_DETAILS_PROPS} />

            <ConfirmationModalFooterComponentStyled>
              <ParagraphSecondaryComponentStyled>
                <ParagraphComponent {...CONFIRMATION_MODAL_DESCRIPTION_PROPS}>
                  <FormattedMessageComponent id={getModalDescription(isEnabled)} />
                </ParagraphComponent>
              </ParagraphSecondaryComponentStyled>

              <ConfirmationModalFeaturesComponentStyled>
                {CONFIRMATION_MODAL_FEATURES.map(({ label, testId }) => (
                  <ParagraphSecondaryComponentStyled key={testId}>
                    <ConfirmationModalFeaturesItemComponentStyled>
                      <AssetComponent {...CONFIRMATION_MODAL_FEATURES_BULLET_PROPS} />
                      <ParagraphComponent {...{ testId }}>
                        <FormattedMessageComponent id={label} />
                      </ParagraphComponent>
                    </ConfirmationModalFeaturesItemComponentStyled>
                  </ParagraphSecondaryComponentStyled>
                ))}
              </ConfirmationModalFeaturesComponentStyled>
            </ConfirmationModalFooterComponentStyled>
          </ConfirmationModalComponentStyled>
        </ModalComponent>
      );
  }
};

export default EnableDisableCardConfirmationModal;
