// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import { getEppDetailsService } from 'services/epp/epp.services';
// Types
import { EppCardType } from 'types/epp-card.type';
import { GetEppDetailsResolverType } from './types/get-epp-details-resolver.type';

const getEppDetailsResolver = async (
  params: GetEppDetailsResolverType
): Promise<[EppCardType] | [null, boolean]> => {
  const [response, { status }] = await getEppDetailsService(params);

  return requestHasSuccessStatus(status) ? [response as EppCardType] : [null, true];
};

export { getEppDetailsResolver };
