// Vendors
import { useNavigate } from 'react-router-dom';
// Contexts
import { useCardSettingsCheck3DConfirmationContextConsumerHook } from 'containers/card-settings/views/check-3d/components/confirmation/contexts/card-settings-check-3d-confirmation.context';
// Event handlers
import CardSettingsCheck3dConfirmationButtonsHandlers from '../handlers/card-settings-check-3d-confirmation-buttons.handlers';
import CardSettingsCheck3dConfirmationButtonsTrackingHandlers from '../handlers/card-settings-check-3d-confirmation-buttons.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsCheck3dConfirmationButtonsHookType } from './types/card-settings-check-3d-confirmation-buttons.hook.type';

const useCardSettingsCheck3dConfirmationSecurityModuleHook =
  (): CardSettingsCheck3dConfirmationButtonsHookType => {
    const { setShowAllowance, showAllowance, setShownCode, shownCode } =
      useCardSettingsCheck3DConfirmationContextConsumerHook();

    return {
      ...CardSettingsCheck3dConfirmationButtonsHandlers({
        ...AppTrackingHook(CardSettingsCheck3dConfirmationButtonsTrackingHandlers),
        navigate: useNavigate(),
        setShowAllowance,
        setShownCode,
      }),
      shownCode,
      showAllowance,
    };
  };

export default useCardSettingsCheck3dConfirmationSecurityModuleHook;
