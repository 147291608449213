// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const TEST_ID = 'financing-ppi-contracting-download-styled';

const FinancingPPIContractingDownloadContentComponentStyled = styled.div.attrs(() => ({
  'data-testid': TEST_ID,
}))<{
  errorDocumentDownloaded: boolean;
}>`
  align-items: center;
  background: ${({ errorDocumentDownloaded, theme }) =>
    errorDocumentDownloaded ? theme.colors.backgroundErrorE : theme.colors.backgroundTertiaryE};
  ${({ theme }) => theme.colors.backgroundSecondaryD};
  border: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ errorDocumentDownloaded, theme }) =>
      errorDocumentDownloaded ? theme.colors.backgroundErrorC : theme.colors.backgroundTertiaryC};
  border-radius: ${({ theme }) => theme.borderRadius.builderRadiusC};
  display: flex;
  flex: 1;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingS};
  padding: ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingXxxxxl};
  width: fit-content;

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingS};
  }
`;
FinancingPPIContractingDownloadContentComponentStyled.displayName =
  'FinancingPPIContractingDownloadContentComponentStyled';

export { FinancingPPIContractingDownloadContentComponentStyled };
