// Vendors
import React, { lazy } from 'react';
// Containers
const OrchestratorContainer = lazy(() => import('containers/orchestrator/orchestrator.container'));
// Providers
import CardsProvider from 'providers/cards/cards.provider';
import ChatProvider from 'providers/chat/chat.provider';
import CustomerIdProvider from 'providers/customerId/customer-id.provider';
import { CustomerProvider } from 'providers/customer/customer.provider';
import { InactivityProvider } from 'providers/inactivity/inactivity.provider';
import LanguageProvider from 'providers/language/language.provider';
import PoeKeysProvider from 'providers/poe-keys/poe-keys.provider';
import { SessionExpiredProvider } from 'providers/session-expired/session-expired.provider';
import { SessionHealthProvider } from 'providers/session-health/session-health.provider';
import { FeatureFlagsProvider } from 'providers/feature-flags/feature-flags.provider';
import { ThirdDownProvider } from 'providers/third-down/third-down.provider';

const AppPrivateComponent = (): React.ReactElement => (
  <ThirdDownProvider>
    <SessionHealthProvider>
      <LanguageProvider>
        <CustomerProvider>
          <CustomerIdProvider>
            <InactivityProvider>
              <SessionExpiredProvider>
                <FeatureFlagsProvider>
                  <PoeKeysProvider>
                    <CardsProvider>
                      <ChatProvider>
                        <OrchestratorContainer />
                      </ChatProvider>
                    </CardsProvider>
                  </PoeKeysProvider>
                </FeatureFlagsProvider>
              </SessionExpiredProvider>
            </InactivityProvider>
          </CustomerIdProvider>
        </CustomerProvider>
      </LanguageProvider>
    </SessionHealthProvider>
  </ThirdDownProvider>
);

export default AppPrivateComponent;
