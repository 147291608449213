// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { LoginGapsBuilderReturnTrackingHandlersType } from 'containers/login/views/gaps/handlers/types/login-gaps-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const passwordPositionInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'loginPasswordPositionsInput',
  },
  type: TrackingEventsEnum.LINK,
});

const passwordPositionInputErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'loginPasswordPositionsInputError',
    eventLabel: 'loginPasswordPositionsInput',
    errorCategory: 'loginPasswordPositionsInputError',
    errorDescription: 'invalidPassword',
  },
  type: TrackingEventsEnum.LINK,
});

const continuePasswordGapsButtonTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'loginUserContinue',
  },
  type: TrackingEventsEnum.LINK,
});

const forgetPasswordLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'forgetPassword',
  },
  type: TrackingEventsEnum.LINK,
});

const enterLoginPasswordPositionsViewTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: 'loginPassword',
    sectionFamily: 'cobranded',
    sectionCategory: 'services',
    sectionName: 'login',
    interactionType: 'page',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const LoginGapsTrackingHandlers = (
  track: TrackBuilderType
): LoginGapsBuilderReturnTrackingHandlersType => ({
  handleContinuePasswordGapsButtonClickTracking: () =>
    track(continuePasswordGapsButtonTrackingHandler()),
  handleForgetPasswordLinkClickTracking: () => track(forgetPasswordLinkClickTrackingHandler()),
  handlePasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickTrackingHandler()),
  handlePasswordPositionInputErrorTracking: () =>
    track(passwordPositionInputErrorTrackingHandler()),
  handleEnterLoginPasswordPositionsViewTracking: () =>
    track(enterLoginPasswordPositionsViewTrackingHandler()),
});

export default LoginGapsTrackingHandlers;
