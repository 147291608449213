// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/security-keys-signature-key-change-second-step-security-key-asset.constants';
// Styles
import { PasswordModuleAssetComponentStyled } from './security-keys-signature-key-change-second-step-security-key-asset.component.styled';
// Types
import { PasswordModuleComponentType } from '../../types/security-keys-signature-key-change-second-step-security-key.component.type';

const SecurityKeysSignatureKeyChangeSecurityKeyPasswordModuleAssetComponent = ({
  assetId,
}: Pick<PasswordModuleComponentType, 'assetId'>): React.ReactElement | null =>
  assetId ? (
    <PasswordModuleAssetComponentStyled>
      <AssetComponent {...constants.SECURITY_KEY_ASSET_PROPS} id={assetId} />
    </PasswordModuleAssetComponentStyled>
  ) : null;

export default SecurityKeysSignatureKeyChangeSecurityKeyPasswordModuleAssetComponent;
