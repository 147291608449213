// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  TransactionsLandingRowDetailsCreatePlanBuilderHandlersType,
  TransactionsLandingRowDetailsCreatePlanBuilderMethodsHandlersType,
} from './types/transactions-landing-row-details-create-plan.handlers.type';
// Utilities
import { cardIsActiveWithPoints } from './utils/transactions-landing-row-details-create-plan.handlers.utils';
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const updateEppPointsModalVisibility =
  (): ((prevModalVisibility: boolean) => boolean) => (prevModalVisibility: boolean) =>
    !prevModalVisibility;

const togglePointsEppModalVisibilityEventHandler = (
  setShowPointsModal: TransactionsLandingRowDetailsCreatePlanBuilderHandlersType['setShowPointsModal']
): void => {
  setShowPointsModal(updateEppPointsModalVisibility());
};

const viewInstallmentsPlanLinkClickHandler = ({
  handleCreateInstallmentsPlanButtonClickTracking,
  id,
  navigate,
  setShowPointsModal,
  ...props
}: TransactionsLandingRowDetailsCreatePlanBuilderHandlersType): void => {
  handleCreateInstallmentsPlanButtonClickTracking();
  cardIsActiveWithPoints(props)
    ? togglePointsEppModalVisibilityEventHandler(setShowPointsModal)
    : navigate(
        generateAbsoluteRoute(
          generatePathRoute([
            RoutesEnumeration.TRANSACTIONS,
            id,
            RoutesEnumeration.CREATE_INSTALLMENTS_PLAN,
          ])
        )
      );
};

const TransactionsLandingRowDetailsCreatePlanHandlers = ({
  setShowPointsModal,
  ...props
}: TransactionsLandingRowDetailsCreatePlanBuilderHandlersType): TransactionsLandingRowDetailsCreatePlanBuilderMethodsHandlersType => ({
  handleCreateInstallmentsPlanButtonClick: () =>
    viewInstallmentsPlanLinkClickHandler({ ...props, setShowPointsModal }),
  handleTogglePointsEppModalVisibilityEvent: () =>
    togglePointsEppModalVisibilityEventHandler(setShowPointsModal),
});

export { TransactionsLandingRowDetailsCreatePlanHandlers };
