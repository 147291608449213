// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';

export default {
  CHALLENGE_WRAPPER_FAIL_MESSAGE_BUTTON_PROPS: {
    rwdFull: false,
    testId: 'challenge-wrapper-message-fail-button',
    type: buttonConstants.TYPES.SECONDARY,
  },
  CHALLENGE_WRAPPER_FAIL_MESSAGE_PROPS: {
    testId: 'challenge-wrapper-message-fail',
    type: 'error',
  },
  CHALLENGE_WRAPPER_FAIL_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'challenge-wrapper-message-fail-description',
  },
};
