// Vendors
import styled from 'styled-components';
// Styles
import { LayoutHorizontalPaddingStyledComponent } from 'styles/app.styled';
// Utils
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsContentContainerStyled = styled.section<{ error?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  ${({ error, theme }) =>
    error ? { padding: `${theme.spaces.spacingXxxxl} ${theme.spaces.spacingL}` } : {}};

  ${tabletMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
  }
`;
CardSettingsContentContainerStyled.displayName = 'CardSettingsContentContainerStyled';

const CardSettingsMiddleContentDetailsStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
CardSettingsMiddleContentDetailsStyled.displayName = 'CardSettingsMiddleContentDetailsStyled';

const CardSettingsTopContentContainerStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;

  ${mobileMediaQuery} {
    flex-direction: column;
    padding: 0 ${({ theme }) => theme.spaces.spacingM};
    gap: ${({ theme }) => theme.spaces.spacingL};
  }
`;
CardSettingsTopContentContainerStyled.displayName = 'CardSettingsTopContentContainerStyled';

const CardSettingSectionDividerComponentStyled = styled(LayoutHorizontalPaddingStyledComponent)<{
  layout?: string;
}>`
  align-items: flex-start;
  display: grid;
  grid-template-columns: ${({ layout = 'repeat(4, 1fr)' }) => layout};
  grid-column-gap: ${({ theme }) => theme.spaces.spacingL};
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
    padding-bottom: ${({ theme }) => theme.spaces.spacingL};
  }
`;
CardSettingSectionDividerComponentStyled.displayName = 'CardSettingSectionDividerComponentStyled';

export {
  CardSettingsContentContainerStyled,
  CardSettingsMiddleContentDetailsStyled,
  CardSettingsTopContentContainerStyled,
  CardSettingSectionDividerComponentStyled,
};
