// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import { PERSONAL_PHOTO_AVATARS_ITEM_CHECK_PROPS } from './constants/personal-photo-avatars-check.constants';
// Styles
import { PersonalPhotoAvatarsCheckComponentStyled } from './personal-photo-avatars-check.component.styled';
// Types
import { PersonalPhotoAvatarsCheckComponentType } from './types/personal-photo-avatars-check.component.type';
// Utilities
import { thisAvatarIsCurrentlySelected } from './utils/personal-photo-avatars-check.utils';

const PersonalPhotoAvatarsCheckComponent = (
  props: PersonalPhotoAvatarsCheckComponentType
): React.ReactElement | null =>
  thisAvatarIsCurrentlySelected(props) ? (
    <PersonalPhotoAvatarsCheckComponentStyled>
      <AssetComponent {...PERSONAL_PHOTO_AVATARS_ITEM_CHECK_PROPS} />
    </PersonalPhotoAvatarsCheckComponentStyled>
  ) : null;

export default PersonalPhotoAvatarsCheckComponent;
