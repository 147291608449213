// Constants
import { buttonConstants, tagConstants } from '@openbank/cb-ui-commons';
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Enumerations
import { AccessPortabilityStatusEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';

const ACCESS_PORTABILITY_DOWNLOAD_BUTTON_PROPS = {
  rwdFull: false,
  testId: 'access-portability-request-table-download-button',
  type: buttonConstants.TYPES.PRIMARY,
  size: buttonConstants.SIZES.SMALL,
};

const ACCESS_PORTABILITY_REQUEST_TAG_TYPES = {
  [AccessPortabilityStatusEnumeration.AVAILABLE]: tagConstants.TYPES.SUCCESS,
  [AccessPortabilityStatusEnumeration.REQUESTED]: tagConstants.TYPES.INFORMATIVE,
  [AccessPortabilityStatusEnumeration.IN_PROGRESS]: tagConstants.TYPES.IN_PROCESS,
  [AccessPortabilityStatusEnumeration.EXPIRED]: tagConstants.TYPES.WARNING,
};

const ACCESS_PORTABILITY_LINE_SEPARATOR_PROPS = {
  color: AmazonTheme.default.colors.backgroundTertiaryD,
};

const ACCESS_PORTABILITY_REQUEST_TAG_ID = {
  [AccessPortabilityStatusEnumeration.AVAILABLE]: `-${tagConstants.TYPES.SUCCESS}-tag`,
  [AccessPortabilityStatusEnumeration.REQUESTED]: `-${tagConstants.TYPES.INFORMATIVE}-tag`,
  [AccessPortabilityStatusEnumeration.IN_PROGRESS]: `-${tagConstants.TYPES.IN_PROCESS}-tag`,
  [AccessPortabilityStatusEnumeration.EXPIRED]: `-${tagConstants.TYPES.WARNING}-tag`,
};

export {
  ACCESS_PORTABILITY_DOWNLOAD_BUTTON_PROPS,
  ACCESS_PORTABILITY_REQUEST_TAG_TYPES,
  ACCESS_PORTABILITY_LINE_SEPARATOR_PROPS,
  ACCESS_PORTABILITY_REQUEST_TAG_ID,
};
