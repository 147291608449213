// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const PersonalEmailLogoutModalComponentStyled = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXl};

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const PersonalEmailCancelModalDescriptionComponentStyled = styled.div`
  text-align: center;
`;
