// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Translations
import aliasTranslations from 'containers/personal/views/landing/components/name/components/alias/translations/profile-personal-name-alias.translations';

export default {
  PROFILE_PERSONAL_NAME_ALIAS_EMPTY_PROPS: {
    label: aliasTranslations.ALIAS,
  },
  PROFILE_PERSONAL_NAME_ALIAS_EMPTY_TITLE_PROPS: {
    bold: false,
    overflow: true,
    testId: 'profile-personal-name-button-alias-empty-title-section',
    tag: titleConstants.TAGS.H6,
  },
  PROFILE_PERSONAL_NAME_ALIAS_EMPTY_ASSET_PROPS: {
    id: 'add',
    colors: {
      fill: 'linkPrimaryA',
      hover: 'linkPrimaryB',
    },
    sizes: {
      height: 20,
      width: 20,
    },
    testId: 'profile-personal-name-button-alias-empty-asset',
  },
};
