export default {
  ASSET_CONFIGURATION: {
    id: 'wait-alternative',
    type: 'illustration',
    sizes: {
      width: 128,
      height: 128,
    },
  },
  PROGRESS_BAR_CONFIGURATION: {
    lastStep: 100,
    height: 8,
    width: 328,
    isFluidBackground: true,
  },
  TITLE_CONFIGURATION: {
    bold: true,
    tag: 'h4',
    testId: 'verifying-details-title',
  },
  PARAGRAPH_CONFIGURATION: {
    size: 'M',
    testId: 'verifying-details-description',
  },
} as const;
