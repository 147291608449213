// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import CustomerIdHandlers from 'providers/customerId/handlers/customer-id.handlers';

const CustomerIdHook = (): any => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { setCustomerId } = AppContextConsumerHook();

  const { handleFetchCustomerId } = CustomerIdHandlers({ setCustomerId, setFetching });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchCustomerId();
  }, []);

  return { fetching };
};

export default CustomerIdHook;
