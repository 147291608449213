// Vendors
import styled from 'styled-components';

export const CardSettingsBlockCardSecondStepComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;

export const CardSettingsBlockCardSecondStepIntroductionStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;

export const MessageContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
