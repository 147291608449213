// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PARAGRAPH_PROPS_TITLE,
  PARAGRAPH_PROPS_VALUE,
  FORMATTED_NUMPER_SIGNS_PROP,
} from './constants/transaction-amount-component.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Styles
import { AmountDetailsModalRowDescriptionComponentStyled } from '../../amount-details-modal.component.styled';
// Translations
import { TRANSACTION_AMOUNT } from '../../translations/amount-details-modal.translations';
// Types
import { CardTransactionDetailedAmountType } from 'types/card-transactions-detailed-amount.type';

export const TransactionAmountComponent = ({
  originalCurrency,
  originalAmountExchange,
}: Pick<
  CardTransactionDetailedAmountType,
  'originalCurrency' | 'originalAmountExchange'
>): React.ReactElement | null => (
  <AmountDetailsModalRowDescriptionComponentStyled>
    <ParagraphComponent {...PARAGRAPH_PROPS_TITLE}>
      <FormattedMessageComponent id={TRANSACTION_AMOUNT} />
    </ParagraphComponent>
    <ParagraphComponent {...PARAGRAPH_PROPS_VALUE}>
      <FormattedNumber
        {...EURO_FORMAT_OPTIONS}
        currency={originalCurrency}
        value={originalAmountExchange}
        signDisplay={FORMATTED_NUMPER_SIGNS_PROP}
      />
    </ParagraphComponent>
  </AmountDetailsModalRowDescriptionComponentStyled>
);