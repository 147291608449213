// Vendors
import { useState } from 'react';
// Contexts
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
import { useFinancingInsurancesContractingLPIContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/lpi/contexts/financing-insurances-contracting-lpi.context';
// Handlers
import { FinancingInsurancesContractingLPIStep4DownloadHandlers } from '../handlers/financing-insurances-contracting-lpi-step4-download.handlers';
// Types
import { FinancingInsurancesContratingLPIStep4DownloadReturnHookType } from './types/financing-insurances-contracting-lpi-step4-download-return.hook.type';
import { FinancingInsurancesContratingLPIStep4DownloadHookType } from './types/financing-insurances-contracting-lpi-step4-download.hook.type';

export const useFinancingInsurancesContractingLPIStep4Download = ({
  handleToggleDownloaded,
}: FinancingInsurancesContratingLPIStep4DownloadHookType): FinancingInsurancesContratingLPIStep4DownloadReturnHookType => {
  const [downloading, setDownloading] = useState(false);

  const { setToastConfiguration } = ToastContextConsumerHook();
  const { insurancesInfo } = useInsurancesContextConsumerHook(),
    { productId, productType } = insurancesInfo?.insurances?.LPI || {};
  const { plan } = useFinancingInsurancesContractingLPIContextConsumerHook();

  return {
    ...FinancingInsurancesContractingLPIStep4DownloadHandlers({
      handleToggleDownloaded,
      plan,
      productId,
      productType,
      setDownloading,
      setToastConfiguration,
    }),
    downloading,
  };
};
