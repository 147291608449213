// Types
import {
  FinancingPPIContractingStep1HiringLegalBuilderHandlersType,
  FinancingPPIContractingStep1HiringLegalMethodsHandlersType,
} from './types/financing-ppi-contracting-step1-hiring-legal.handlers.type';

const openGermanBranchWebsiteLinkClickHandler = ({
  handleOpenGermanBranchWebsiteLinkClickTracking,
  url,
}: FinancingPPIContractingStep1HiringLegalBuilderHandlersType): void => {
  handleOpenGermanBranchWebsiteLinkClickTracking();
  window.open(url);
};

const FinancingPPIContractingStep1HiringLegalHandlers = (
  props: FinancingPPIContractingStep1HiringLegalBuilderHandlersType
): FinancingPPIContractingStep1HiringLegalMethodsHandlersType => ({
  handleOpenGermanBranchWebsiteLinkClick: () => openGermanBranchWebsiteLinkClickHandler(props),
});

export { FinancingPPIContractingStep1HiringLegalHandlers };
