// Vendors
import React from 'react';
// Components
import { TableOrganismComponent } from 'organisms/table-organism/table-organism.component';
import DashboardTableLinkComponent from './components/link/dashboard-table-link.component';
// Constants
import { DASHBOARD_LANDING_TABLE_PROPS } from './constants/dashboard-landing-table.constants';
// Hooks
import DashboardTableHook from './hooks/dashboard-table.hook';
// Styles
import { DashboardTableComponentStyled } from './dashboard-table.component.styled';

const DashboardTableComponent = (): React.ReactElement => {
  const { error, transactions, handleNavigateToTransactions } = DashboardTableHook();

  return (
    <DashboardTableComponentStyled>
      <TableOrganismComponent
        {...{
          tableConfigurationProps: {
            ...DASHBOARD_LANDING_TABLE_PROPS,
            error,
            transactions,
            onClickLink: () => handleNavigateToTransactions(false),
            onClickSecurityCheckBtn: () => handleNavigateToTransactions(true),
          },
          filterId: 'transactions',
        }}
      />
      <DashboardTableLinkComponent {...{ transactions }} />
    </DashboardTableComponentStyled>
  );
};

export default DashboardTableComponent;
