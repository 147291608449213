// Vendors
import styled from 'styled-components';
// Styled
import { LayoutGridComponentStyled } from 'styles/app.styled';
// Utils
import {
  convertPxsToRems,
  mobileAndTabletMediaQuery,
  mobileMediaQuery,
  tabletMediaQuery,
} from '@openbank/cf-ui-framework';

const SecurityKeysSignatureKeyChangeContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxl};
  padding-bottom: ${({ theme }) => theme.spaces.spacingXxxl};

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingL};
    padding-bottom: ${({ theme }) => theme.spaces.spacingL};
  }

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingS};
    padding-bottom: 0;
  }
`;

SecurityKeysSignatureKeyChangeContainerStyled.displayName =
  'SecurityKeysSignatureKeyChangeContainerStyled';

const SecurityKeysSignatureKeyChangeContentContainerStyled = styled.section`
  align-items: flex-start;
  display: flex;
  gap: ${({ theme }) => theme.spaces.spacingM};

  ${mobileAndTabletMediaQuery} {
    flex-direction: column;
  }
`;

SecurityKeysSignatureKeyChangeContentContainerStyled.displayName =
  'SecurityKeysSignatureKeyChangeContentContainerStyled';

const SecurityKeysSignatureKeyChangeContentLeftComponentStyled = styled.div`
  flex: 1;

  ${mobileAndTabletMediaQuery} {
    width: 100%;
  }
`;

SecurityKeysSignatureKeyChangeContentLeftComponentStyled.displayName =
  'SecurityKeysSignatureKeyChangeContentLeftComponentStyled';

const SecurityKeysSignatureKeyChangeGridContentRightComponentStyled = styled(
  LayoutGridComponentStyled
)<{
  maxWidth?: number;
}>`
  flex: 0 0 auto;
  gap: ${({ theme }) => theme.spaces.spacingL};
  ${({ maxWidth }) => maxWidth && `max-width: ${convertPxsToRems({ pixels: maxWidth, base: 14 })}`};

  ${mobileAndTabletMediaQuery} {
    flex: 100%;
    max-width: 100%;
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
    width: 100%;
  }
`;

SecurityKeysSignatureKeyChangeGridContentRightComponentStyled.displayName =
  'SecurityKeysSignatureKeyChangeGridContentRightComponentStyled';

export {
  SecurityKeysSignatureKeyChangeContainerStyled,
  SecurityKeysSignatureKeyChangeContentContainerStyled,
  SecurityKeysSignatureKeyChangeContentLeftComponentStyled,
  SecurityKeysSignatureKeyChangeGridContentRightComponentStyled,
};
