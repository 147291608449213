const DOMAIN = 'cobrandedCC.services.userSettings.privateArea.securityAndKeys.changeAccessKey.';

const FEEDBACK_ERROR_DESCRIPTION = `${DOMAIN}changeAccessKeyCCDescriptionWizardFeedbackError`,
  FEEDBACK_ERROR_TITLE = `${DOMAIN}changeAccessKeyCCTitleWizardFeedbackError`,
  FEEDBACK_SUCCESS_DESCRIPTION = `${DOMAIN}changeAccessKeyCCDescriptionWizardFeedbackSuccess`,
  FEEDBACK_SUCCESS_TITLE = `${DOMAIN}changeAccessKeyCCTitleWizardFeedbackSuccess`,
  FEEDBACK_BUTTON = `${DOMAIN}changeAccessKeyCCButtonWizardFeedbackSuccess`,
  FEEDBACK_BUTTON_ERROR = `${DOMAIN}changeAccessKeyCCButtonWizardFeedbackError`;

export {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  FEEDBACK_BUTTON,
  FEEDBACK_BUTTON_ERROR,
};
