// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/personal-number.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalNumberContextType } from 'containers/personal/views/number/contexts/types/personal-number.context.type';
import { PersonalNumberTypesEnumeration } from 'containers/personal/views/number/enumerations/personal-number-types.enumeration';
// Enumerations
import { OperativeResultsEnumeration } from 'enumerations/operative-results.enumeration';

const PersonalNumberContext = createContext<PersonalNumberContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const PersonalNumberContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalNumberContextType>> => {
  const [fetching, setFetching] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [challenged, setChallenged] = useState<boolean>(false);
  const [progressId, setProgressId] = useState<string>('');
  const [showModal, setShowModal] = useState<PersonalNumberTypesEnumeration | null>(null);
  const [result, setResult] = useState<null | OperativeResultsEnumeration>(null);
  const [newPhoneNumber, setNewPhoneNumber] = useState<string>('');
  const [newPrefix, setNewPrefix] = useState<number | null>(null);
  const [alternativeError, setAlternativeError] = useState<boolean | null>(null);
  const [withEmail, setWithEmail] = useState<boolean>(false);
  const [signatureExpired, setSignatureExpired] = useState<boolean>(false);

  return (
    <PersonalNumberContext.Provider
      value={{
        challenged,
        fetching,
        error,
        progressId,
        showModal,
        newPrefix,
        newPhoneNumber,
        signatureExpired,
        result,
        alternativeError,
        withEmail,
        setFetching,
        setError,
        setProgressId,
        setShowModal,
        setNewPrefix,
        setNewPhoneNumber,
        setChallenged,
        setResult,
        setAlternativeError,
        setWithEmail,
        setSignatureExpired
      }}
    >
      {children as React.ReactNode}
    </PersonalNumberContext.Provider>
  );
};

export const PersonalNumberContextConsumerHook = (): PersonalNumberContextType =>
  useContext(PersonalNumberContext);

/* eslint-disable */
export const PersonalNumberContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalNumberContextType>> =>
    (
      <PersonalNumberContextProvider>
        <Component {...props} />
      </PersonalNumberContextProvider>
    );
