// Translations
import translations from './translations/profile-personal-data-address.translations.utils';
// Types
import { ProfilePersonalDataAddressUtilsType } from './types/profile-personal-data-address.utils.type';

const createMappedPrimaryResidentialAddress = ({
  formatMessage,
  address: { countryCode, number, postCode, streetLine1, town },
}: ProfilePersonalDataAddressUtilsType): string =>
  `${streetLine1} ${number},\n${postCode} ${town}\n${formatMessage({
    id: `${translations.COUNTRY}_${countryCode}`,
  })}`;

export { createMappedPrimaryResidentialAddress };
