// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/personal-email-step2-challenge.service.constants';
// Types
import { PersonalEmailStep2ChallengeValidateApiBuilderType } from '../types/personal-email-step2-challenge-validate-api-builder.type';
import { PersonalEmailStep2ChallengeVerifyReturnHookType } from '../types/personal-email-step2-challenge-verify-return.hook.type';

const validateNewEmailOtpService = async (
  params: PersonalEmailStep2ChallengeValidateApiBuilderType
): Promise<[PersonalEmailStep2ChallengeVerifyReturnHookType | Record<string, any>, Response]> =>
  apiUtilsCb.post({ ...constants.VALIDATE_OTP_SERVICE_CONFIGURATION, params });

export { validateNewEmailOtpService };
