export default {
  CONTEXT_DEFAULT_VALUE: {
    challenged: false,
    fetching: false,
    error: false,
    progressId: '',
    showModal: null,
    newPrefix: null,
    newPhoneNumber: '',
    withEmail: false,
    signatureExpired: false,
    result: null,
    alternativeError: false,
    setChallenged: (): null => null,
    setFetching: (): null => null,
    setError: (): null => null,
    setProgressId: (): null => null,
    setShowModal: (): null => null,
    setNewPrefix: (): null => null,
    setNewPhoneNumber: (): null => null,
    setWithEmail: (): null => null,
    setRepeatPhoneNumber: (): null => null,
    setResult: (): null => null,
    setAlternativeError: (): null => null,
    setSignatureExpired: (): null => null,
  },
};
