// Vendors
import { electronicFormattedIban, isValidIban } from '@openbank/cf-ui-framework';
// Types
import { ChangeIbanStep1BuilderHandlersType } from './types/change-iban-step1-builder.handlers.type';
import { ChangeIbanStep1BuilderMethodHandlersType } from './types/change-iban-step1-builder-method.handlers.type';
import { ChangeIbanStep1BuilderReturnHandlersType } from './types/change-iban-step1-builder-return.handlers.type';

const openSepaModalLinkClickHandler = ({ setOpenSepaModal }): void => {
  setOpenSepaModal(true);
};

const closeSepaModalLinkClickHandler = ({ setOpenSepaModal }): void => {
  setOpenSepaModal(false);
};

const saveNewIbanInputEventHandler = ({
  newIban,
  setIbanValue,
  setError,
}: ChangeIbanStep1BuilderMethodHandlersType): void => {
  setIbanValue(electronicFormattedIban(newIban));
  setError(false);
};

const checkNewIbanInputEventHandler = ({
  ibanValue,
  setError,
}: Pick<ChangeIbanStep1BuilderHandlersType, 'ibanValue' | 'setError'>): void => {
  if (!isValidIban(ibanValue)) {
    setError(true);
  }
};

const changeCheckboxEventHandler = ({
  setCheckboxValue,
}: Pick<ChangeIbanStep1BuilderHandlersType, 'setCheckboxValue'>): void => {
  setCheckboxValue((prevValue: boolean) => !prevValue);
};

const ChangeIbanStep1Handlers = (
  props: ChangeIbanStep1BuilderHandlersType
): ChangeIbanStep1BuilderReturnHandlersType => ({
  handleOpenSepaModalLinkClick: () => openSepaModalLinkClickHandler(props),
  handleCloseSepaModalLinkClick: () => closeSepaModalLinkClickHandler(props),
  handleSaveNewIbanInputEvent: (newIban: string) =>
    saveNewIbanInputEventHandler({ ...props, newIban }),
  handleCheckNewIbanInputEvent: () => checkNewIbanInputEventHandler(props),
  handleChangeCheckboxEvent: () => changeCheckboxEventHandler(props),
});

export { ChangeIbanStep1Handlers };
