// Vendors
import styled from 'styled-components';
// Types
import { FilterModalInputSelectComponentType } from './types/filter-modal-input-select.component.type';
// Utilities
import { convertPxsToRems } from '@openbank/cf-ui-framework';

const FilterModalInputSelectComponentStyled = styled.div.attrs(
  ({ testId }: Pick<FilterModalInputSelectComponentType, 'testId'>) => ({
    'data-testid': testId,
  })
)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${convertPxsToRems({ pixels: 16, base: 14 })};
`;
export { FilterModalInputSelectComponentStyled };
