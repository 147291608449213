// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ProfilePersonalNameAliasContextConsumerHook } from '../contexts/profile-personal-name-alias.context';
// Types
import { ProfilePersonalNameAliasHookType } from './types/profile-personal-name-alias.hook.type';

const ProfilePersonalNameAliasHook = (): ProfilePersonalNameAliasHookType => {
  const { profile } = AppContextConsumerHook(),
    { editMode } = ProfilePersonalNameAliasContextConsumerHook(),
    { alias } = profile?.userDetails || {};

  return {
    alias,
    editMode,
  };
};

export default ProfilePersonalNameAliasHook;
