// Vendors
import React from 'react';
// Components
import {
  ButtonComponent,
  CheckboxComponent,
  DividerComponent,
  FetchErrorComponent,
  InputTextComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LoginAccessDataComponent } from '../../components/access-data/login-access-data.component';
import { LoginErrorComponent } from '../../components/error/login-error.component';
// Constants
import constants from './constants/login-email.constants';
// Custom Hooks
import { useLoginEmail } from './hooks/login-email.hook';
// Styles
import { LoginFormContentChildrenContainerStyled } from '../../login.container.styled';
import { LoginEmailInputMessageViewStyled, LoginEmailViewStyled } from './login-email.view.styled';
// Translations
import { EMAIL } from '../../translations/login.translations';
// Utilities
import { continueLoginEmailButtonIsDisabled } from './utils/login-email.utils';

const LoginEmailView = (): React.ReactElement => {
  const {
    fetching: fetchingEmail,
    formatMessage,
    email,
    error,
    handleChangeRememberMeCheckBoxEvent,
    handleContinuePasswordGapsClick,
    handleEmailInputClickTracking,
    handleSaveLoginEmailInputEvent,
    handleValidateLoginEmailInputEvent,
    rememberMe,
  } = useLoginEmail();

  return (
    <LoginEmailViewStyled>
      <FetchErrorComponent {...{ fetching: fetchingEmail }}>
        <TitleComponent {...constants.LOGIN_EMAIL_TITLE_PROPS}>
          <FormattedMessageComponent id={EMAIL.TITLE} />
        </TitleComponent>
        <LoginFormContentChildrenContainerStyled>
          <LoginEmailInputMessageViewStyled>
            <InputTextComponent
              {...constants.LOGIN_EMAIL_INPUT_PROPS}
              errorMessage={Boolean(error)}
              disabled={fetchingEmail}
              label={formatMessage({ id: EMAIL.INPUT_LABEL })}
              placeholder={formatMessage({ id: EMAIL.PLACEHOLDER })}
              onBlur={handleValidateLoginEmailInputEvent}
              onChange={handleSaveLoginEmailInputEvent}
              onClick={handleEmailInputClickTracking}
              value={email}
            />
            <LoginErrorComponent error={error} message={EMAIL.ERROR_INPUT} />
            <CheckboxComponent
              {...constants.LOGIN_EMAIL_CHECKBOX_PROPS}
              disabled={fetchingEmail}
              onChange={handleChangeRememberMeCheckBoxEvent}
              value={rememberMe}
              title={formatMessage({ id: EMAIL.CHECKBOX_LABEL })}
            />
          </LoginEmailInputMessageViewStyled>
          <ButtonComponent
            {...constants.LOGIN_EMAIL_BUTTON_PROPS}
            disabled={continueLoginEmailButtonIsDisabled({
              fetching: fetchingEmail,
              email,
              error,
            })}
            loading={fetchingEmail}
            onClick={handleContinuePasswordGapsClick}
          >
            <FormattedMessageComponent id={EMAIL.BUTTON} />
          </ButtonComponent>
        </LoginFormContentChildrenContainerStyled>
        <DividerComponent {...constants.LOGIN_EMAIL_DIVIDER_PROPS} />
        <LoginAccessDataComponent />
      </FetchErrorComponent>
    </LoginEmailViewStyled>
  );
};

export default LoginEmailView;
