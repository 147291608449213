// Vendors
import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
// Components
import { WorkInProgressComponent } from 'components/working-in-progress/work-in-progress.component';
// Constants
import {
  SUPPORT_CANCEL_CONTRACT_ROUTE_PROPS,
  SUPPORT_FAQS_ROUTE_PROPS,
  SUPPORT_LANDING_ROUTE_PROPS,
} from './constants/support-router.constants';
// Components
const SupportLandingView = lazy(() => import('../../views/landing/support-landing.view'));

const SupportCancelContractContainer = lazy(
  () => import('containers/support/views/cancel-contract/support-cancel-contract.container')
);

const SupportRouterComponent = (): React.ReactElement => (
  <Routes>
    <Route {...SUPPORT_LANDING_ROUTE_PROPS} element={<SupportLandingView />} />
    <Route {...SUPPORT_FAQS_ROUTE_PROPS} element={<WorkInProgressComponent />} />
    <Route {...SUPPORT_CANCEL_CONTRACT_ROUTE_PROPS} element={<SupportCancelContractContainer />} />
  </Routes>
);

export default SupportRouterComponent;
