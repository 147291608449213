// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { LIMIT_INCREASE_CASES_URL } from './constants/commons.constants';
import { REJECT_M2_URL_SUFFIX, SUCCESS_URL_SUFFIX } from './constants/final-feedback.constants';
// Types
import { SendTaxIdResponseType } from './types/send-tax-id-response.type';
import { SendTaxIdPropsType } from './types/send-tax-id-props.type';

export const sendTaxIdService = ({
  caseId,
  taxId,
}: SendTaxIdPropsType): Promise<[SendTaxIdResponseType, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${SUCCESS_URL_SUFFIX}`,
    params: { taxId },
  });

export const rejectM2Service = (caseId: string): Promise<[void, Response]> =>
  apiUtilsCb.post({
    endpoint: `${LIMIT_INCREASE_CASES_URL}/${caseId}${REJECT_M2_URL_SUFFIX}`,
  });
