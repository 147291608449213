// Vendors
import { useEffect, useState } from 'react';
// Event handlers
import { IncreaseLimitHybridHandlers } from '../handlers/increase-limit-hybrid.handlers';
// Types
import { IncreaseLimitHybridHookType } from './types/increase-limit-hybrid.hook.type';

const useIncreaseLimitHybridHook = (): IncreaseLimitHybridHookType => {
  const [fetching, setFetching] = useState<boolean>(true);

  const { handleDetectBrowserHybridIncreaseLimitFlowEvent } =
    IncreaseLimitHybridHandlers(setFetching);

  useEffect(() => {
    handleDetectBrowserHybridIncreaseLimitFlowEvent();
  }, []);

  return { fetching };
};

export { useIncreaseLimitHybridHook };
