// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, SidebarComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/personal-number-sidebar.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Translations
import translations from './translations/personal-number-sidebar.translations';

const PersonalNumberSidebarComponent = (): React.ReactElement => (
  <SidebarComponent
    {...constants.PERSONAL_NUMBER_SIDEBAR_PROPS}
    title={<FormattedMessageComponent id={translations.TITLE} />}
  >
    <ParagraphSecondaryComponentStyled>
      <ParagraphComponent {...constants.PERSONAL_NUMBER_SIDEBAR_DESCRIPTION_PROPS}>
        <FormattedMessageComponent id={translations.DESCRIPTION} />
      </ParagraphComponent>
    </ParagraphSecondaryComponentStyled>
  </SidebarComponent>
);

export default PersonalNumberSidebarComponent;
