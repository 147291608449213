// Types
import {
  OrderModalHandlerPropsType,
  OrderModalHandlerTypeReturn,
} from './types/order-modal.handler.type';
// Utils
import { getUrlAmazonByOrder } from '../utils/order-modal-orchestrator.utils';

const onHandleViewDetailLink = ({
  handleViewDetailClickTracking,
  order,
  urlAmazon,
}: OrderModalHandlerPropsType): void => {
  handleViewDetailClickTracking();
  window.open(order ? getUrlAmazonByOrder(order) : urlAmazon);
};

const OrderModalHandlers = ({
  urlAmazon,
  handleViewDetailClickTracking,
}: OrderModalHandlerPropsType): OrderModalHandlerTypeReturn => ({
  handleViewDetailLink: (order?: string) =>
    onHandleViewDetailLink({ order, urlAmazon, handleViewDetailClickTracking }),
});

export { OrderModalHandlers };
