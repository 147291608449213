// Vendors
import React from 'react';
// Components
import { DividerComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { UploadDocumentCreditLimitIncreaseComponent } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/upload-document-credit-limit-increase.component';
// Constants
import { PARAGRAPHS_L_SIZE } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/constants/step-4-upload-documentation.component.constants';
import { TERTIARY_D } from '../../constants/upload-documents-section.constants';
// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Hooks
import { useSelfEmployedSectionHook } from './hooks/self-employed-section.hook';
// Styles
import { DisplayGridStyled } from '../../upload-documents-section.component.styled';
// Types
import { SelfEmployedSectionPropsType } from './types/self-employed-section.type';
// Translations
import { step4UploadDocumentationTranslations } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/translations/step-4-upload-documentation.translations';

export const SelfEmployedSectionComponent = ({
  activityTypeDeclared,
  documentsRejected,
  formatMessage,
}: SelfEmployedSectionPropsType): React.ReactElement | null => {
  const {
    showLastIncomeTax,
    showSelfEmployment,
    isSelfEmployedRejected,
    isLastIncomeTaxRejected,
    shouldRenderSection,
  } = useSelfEmployedSectionHook({ activityTypeDeclared, documentsRejected });

  if (!shouldRenderSection) return null;

  return (
    <>
      {showSelfEmployment && (
        <>
          <ParagraphComponent bold size={PARAGRAPHS_L_SIZE}>
            {formatMessage({ id: step4UploadDocumentationTranslations.LAST_BWA })}
          </ParagraphComponent>
          <ParagraphComponent>
            {formatMessage({ id: step4UploadDocumentationTranslations.LAST_BWA_DESCRIPTION })}
          </ParagraphComponent>
          <DisplayGridStyled>
            <UploadDocumentCreditLimitIncreaseComponent
              id={UploadDocumentIdsEnumeration.LAST_BWA}
              isRejected={isSelfEmployedRejected}
            />
          </DisplayGridStyled>
          <DividerComponent type={TERTIARY_D} />
        </>
      )}
      {showLastIncomeTax && (
        <>
          <ParagraphComponent bold size={PARAGRAPHS_L_SIZE}>
            {formatMessage({ id: step4UploadDocumentationTranslations.LAST_INCOME_TAX })}
          </ParagraphComponent>
          <DisplayGridStyled>
            <UploadDocumentCreditLimitIncreaseComponent
              id={UploadDocumentIdsEnumeration.LAST_INCOME_TAX}
              isRejected={isLastIncomeTaxRejected}
            />
          </DisplayGridStyled>
        </>
      )}
    </>
  );
};
