import styled from 'styled-components';

export const ErrorProviderLayoutStyled = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};
  justify-content: center;
  height: 100vh;
  margin: 0 auto;
  padding: ${({ theme }) => theme.spaces.spacingL};
  width: 100%;
`;
