// Enumerations
import { SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration } from '../../components/second-step/enumerations/security-keys-signature-key-change-second-step.enumeration';
// Translations
import translations from '../../translations/security-keys-signature-key-change.translations';

const ONLY_8_NUMBERS = /^\d{8}$/;
const CONSTRAINTS_LABELS = {
  [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_REPEATS_NUMBER]:
    translations.PASSWORD_RULE.INPUT_REPEATS_NUMBER,
  [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_IS_A_SEQUENCE]:
    translations.PASSWORD_RULE.INPUT_IS_A_SEQUENCE,
  [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_CONTAINS_CUSTOMER_DATE]:
    translations.PASSWORD_RULE.INPUT_CONTAINS_CUSTOMER_DATE,
  [SecurityKeysSignatureKeyChangeSecondStepErrorIdEnumeration.INPUT_EQUALS_OLD_KEY]:
    translations.PASSWORD_RULE.INPUT_EQUALS_OLD_KEY,
};

export { ONLY_8_NUMBERS, CONSTRAINTS_LABELS };
