// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Types
import { CardSettingsTogglesApiCardAvalaibleBuilderComponentType } from '../types/card-settings-toggle-api-card-avalaible-builder.component.type';
import { CardSettingsTogglesApiTravelPlusBuilderComponentType } from '../types/card-settings-toggle-api-travel-plus-builder.component.type';
// Utilities
import { getTurnOffOnEndpointAccordingStatus } from './utils/card-settings-toggles.services.utils';
// Constants
import constants from '../services/utils/constants/card-settings-toggles.services.utils.constants';

const toggleCardAvailabilityService = async ({
  cardId,
  status,
}: CardSettingsTogglesApiCardAvalaibleBuilderComponentType): Promise<
  [Record<string, any>, Response]
> =>
  apiUtilsCb.post({
    endpoint: getTurnOffOnEndpointAccordingStatus(status),
    pathParams: { cardId },
  });

const toogleTravelPlusAvailabilityService = async ({
  cardId,
  travelPlus,
}: CardSettingsTogglesApiTravelPlusBuilderComponentType): Promise<
  [Record<string, any>, Response]
> => {
  const { activated } = travelPlus;
  const status = Boolean(!activated);

  return apiUtilsCb.post({
    endpoint: constants.FETCH_CARD_SETTINGS_TRAVEL_PLUS_ACTIVATION_SERVICE.endpoint,
    pathParams: { cardId },
    params: {
      travelPlus: status,
    },
  });
};

export { toggleCardAvailabilityService, toogleTravelPlusAvailabilityService };
