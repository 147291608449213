// Enumerations
import { PayOthersPaymentMethodEnumeration } from 'containers/transactions/views/report/enumerations/pay-others-payment-method.enumeration';

const DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT =
  'cobranded.services.userSettings.privateArea.transactions.reportAProblem.payment.additionalInfo.input.';

const PAYMENT_METHOD_LABEL = `${DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT}paymentMethod.label`;

const PAYMENT_METHOD_PLACEHOLDER = `${DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT}paymentMethod.placeholder`;

const PAYMENT_METHOD_OPTIONS = {
  [PayOthersPaymentMethodEnumeration.CASH_PAYMENT_METHOD]: `${DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT}cashPayment`,
  [PayOthersPaymentMethodEnumeration.ANOTHER_CARD_PAYMENT_METHOD]: `${DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT}anotherCardPayment`,
  [PayOthersPaymentMethodEnumeration.TRANSFER_PAYMENT_METHOD]: `${DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT}transferPayment`,
  [PayOthersPaymentMethodEnumeration.OTHER_PAYMENT_METHOD]: `${DOMAIN_REPORT_A_PROBLEM_PAYMENT_ADDITIONAL_INFO_INPUT}otherPayment`,
};

export { PAYMENT_METHOD_LABEL, PAYMENT_METHOD_PLACEHOLDER, PAYMENT_METHOD_OPTIONS };
