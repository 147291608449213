// Services
import { decreaseLimitService } from '../services/credit-limit-decrease-cc.services';
// Types
import { DecreaseLimitResolversType } from './types/decrease-limit.resolver.type';
import { ChallengeType } from 'types/challenge.type';
import { ErrorBodyType } from 'types/error-body.type';
// Utilities
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';

const decreaseLimitResolver = async (
  props: DecreaseLimitResolversType
): Promise<[null] | [ChallengeType | ErrorBodyType | null, number]> => {
  const [response, { status }] = await decreaseLimitService(props);

  return requestHasSuccessStatus(status) ? [null] : [response, status];
};

export { decreaseLimitResolver };
