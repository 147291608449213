// Vendors
import React from 'react';
// Constants
import { MODAL_FILTER_DEFAULT_PROPS } from 'components/filters/components/filter-modal/constants/filter-modal.constants';
// Components
import { ModalComponent } from '@openbank/cb-ui-commons';
// Hooks
import { FilterModalHook } from './hooks/filter-modal.hook';
// Styles
import { FilterModalChildrenComponentStyled } from './filter-modal.component.styled';
// Translations
import {
  APPLY,
  CANCEL,
} from 'components/filters/components/filter-modal/translations/filter-modal.translations';
import { MODAL_TITLE } from './configurations/transactions-filter-modal-configuration/translations/transactions-filter-modal.translations';
// Types
import { FilterModalComponentType } from './types/filter-modal.component.type';
import { isIosWebView } from '@gruposantander/web-ui-framework';

const FilterModalComponent = ({
  filterId,
  onApplyFilter,
}: FilterModalComponentType): React.ReactElement | null => {
  const {
    filterModalContent,
    formatMessage,
    handleApplyFilter,
    handleOnClickCancel,
    isShowFilterModal,
    primaryButtonDisabled,
  } = FilterModalHook({ filterId, onApplyFilter });

  return isShowFilterModal ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...{
        title: formatMessage({ id: MODAL_TITLE }),
        ...{ onHeaderClose: handleOnClickCancel },
        primaryButtonConfiguration: {
          ...{
            ...MODAL_FILTER_DEFAULT_PROPS.primaryButtonConfiguration,
            children: formatMessage({ id: APPLY }),
            onClick: handleApplyFilter,
            disabled: primaryButtonDisabled,
          },
        },
        linkConfiguration: {
          ...{
            ...MODAL_FILTER_DEFAULT_PROPS.linkConfiguration,
            children: formatMessage({ id: CANCEL }),
            onClick: handleOnClickCancel,
          },
        },
      }}
    >
      <FilterModalChildrenComponentStyled>
        {filterModalContent.map(({ component }) => component)}
      </FilterModalChildrenComponentStyled>
    </ModalComponent>
  ) : null;
};

export { FilterModalComponent };
