// Vendors
import React from 'react';
// Components
import { LastAccessComponent } from 'containers/security-keys/views/devices/components/device-header/components/last-access/last-access.component';
import { DeviceHeaderAliasComponent } from 'containers/security-keys/views/devices/components/device-header/components/alias/device-header-alias.component';
// Configurations
import {
  getAssetConfiguration,
  getDeviceInfoTagConfiguration,
} from './configurations/device-header-info.configurations';
// Hooks
import { DeviceTypeHook } from 'hooks/device-type.hook';
// Styled
import { DeviceHeaderInfoComponentStyled } from './device-header-info.component-styled';
// Types
import { DeviceHeaderInfoComponentType } from './types/device-header-info.component.type';

const DeviceHeaderInfoComponent = ({
  device,
}: DeviceHeaderInfoComponentType): React.ReactElement => {
  const { isMobile } = DeviceTypeHook();

  return (
    <>
      {getAssetConfiguration({ device })}
      <DeviceHeaderInfoComponentStyled>
        {getDeviceInfoTagConfiguration({ device, isMobile })}
        <DeviceHeaderAliasComponent deviceUUID={device.deviceUUID} alias={device.alias} />
        {isMobile && !device.lastSession?.active && <LastAccessComponent device={device} />}
      </DeviceHeaderInfoComponentStyled>
    </>
  );
};

export { DeviceHeaderInfoComponent };
