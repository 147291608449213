// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/card-status-button-switch-on-modal.service.constants';

const switchOnCardService = async (
  cardId: string
): Promise<[any | Record<string, any>, Response]> =>
  apiUtilsCb.post({
    ...constants.CARD_STATUS_BUTTON_SWITCH_ON_MODAL_SERVICE_CONFIGURATION,
    pathParams: { cardId },
  });

export { switchOnCardService };
