// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import {
  MiscellaneousCurrencyConstants,
  MiscellaneousCurrenciesEnum,
} from '@openbank/cf-ui-static-data';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_LANDING_TABLE_ROW_AMOUNT_PROPS } from './constants/financing-landing-table-row-amount.constants';
// Styles
import { ParagraphComponentStyled } from './financing-landing-table-row-amount.component.style';
// Types
import { FinancingLandingTableRowAmountComponentType } from './types/financing-landing-table-row-amount.component.type';

const FinancingLandingTableRowAmountComponent = ({
  currency = MiscellaneousCurrenciesEnum.EURO,
  originalAmount,
}: FinancingLandingTableRowAmountComponentType): React.ReactElement => (
  <ParagraphComponentStyled>
    <ParagraphComponent {...FINANCING_LANDING_TABLE_ROW_AMOUNT_PROPS}>
      <FormattedNumber
        {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
        value={originalAmount}
      />
    </ParagraphComponent>
  </ParagraphComponentStyled>
);

export default FinancingLandingTableRowAmountComponent;
