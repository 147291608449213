// Handlers
import { FilterModalInputRangeDatePickerHandler } from '../handlers/filter-modal-input-range-date-picker.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import {
  FilterModalInputRangeDatePickerHookPropsType,
  FilterModalInputRangeDatePickerHookReturnType,
} from './types/filter-modal-input-range-date-picker.hook.type';

const FilterModalInputRangeDatePickerHook = ({
  onChangeFromInput,
  onChangeToInput,
}: FilterModalInputRangeDatePickerHookPropsType): FilterModalInputRangeDatePickerHookReturnType => {
  const todayDate = new Date();
  const { formatMessage } = useCbIntl();
  const { handleSelectRange } = FilterModalInputRangeDatePickerHandler({
    onChangeFromInput,
    onChangeToInput,
  });

  return {
    formatMessage,
    handleSelectRange,
    todayDate,
  };
};

export { FilterModalInputRangeDatePickerHook };
