// Types
import { FormatFormValuesParamsReturnType } from './types/filter-modal.utils.type';
import { FormValuesType } from 'components/filters/context/types/filters-context.type';

const formatFormValueParams = (formValues: FormValuesType): FormatFormValuesParamsReturnType => {
  return Object.entries(formValues).reduce((acc, [key, value]) => {
    const actualValue = value?.value || value?.code;

    if (actualValue && actualValue !== 'All') {
      acc[key] = actualValue;
    }
    return acc;
  }, {} as FormatFormValuesParamsReturnType);
};

const getValueOrDefault = (formValue: string | undefined): string => {
  return formValue === '' || formValue === undefined ? 'All' : formValue;
};

const isEmptyValue = (value: string | undefined | number): boolean =>
  value === 'All' || value === '' || value === null || value === undefined;

const hasValuesInOtherFields = (formValues: FormValuesType): boolean => {
  const ignoredKeys = ['fromAmount', 'toAmount', 'fromDate', 'toDate', 'dateOption'];
  const listWithFilteredFields = Object.entries(formValues).filter(
    ([key]) => !ignoredKeys.includes(key)
  );
  const hasValues = listWithFilteredFields.some(
    ([key, value]) => !isEmptyValue(value?.value) || !isEmptyValue(value?.code)
  );

  const keys = Object.keys(formValues);
  const hasOtherKeys = keys.some(key => ignoredKeys.includes(key));

  return hasValues || hasOtherKeys;
};

const hasOnlyAmountFields = (formValues: FormValuesType): boolean => {
  const keys = Object.keys(formValues);
  return keys.length === 2 && keys.includes('fromAmount') && keys.includes('toAmount');
};

const hasAmountValues = (formValues: FormValuesType): boolean => {
  return !isEmptyValue(formValues?.fromAmount?.value) && !isEmptyValue(formValues?.toAmount?.value);
};

const isAmountsEmpty = (formValues: FormValuesType): boolean => {
  return isEmptyValue(formValues?.fromAmount?.value) && isEmptyValue(formValues?.toAmount?.value);
};

const isFromAmountLessThanToAmount = (formValues: FormValuesType): boolean => {
  const fromAmountDecimaltsToPoint = formValues?.fromAmount?.value ?? '';

  const toAmountDecimaltsToPoint = formValues?.toAmount?.value ?? '';

  return fromAmountDecimaltsToPoint < toAmountDecimaltsToPoint;
};

const isRangeAmountsFieldsValid = (formValues: FormValuesType): boolean => {
  return (
    (hasAmountValues(formValues) && isFromAmountLessThanToAmount(formValues)) ||
    (isAmountsEmpty(formValues) && !hasOnlyAmountFields(formValues))
  );
};

const hasOnlyDateFields = (formValues: FormValuesType): boolean => {
  const keys = Object.keys(formValues);
  return (
    keys.length === 3 &&
    keys.includes('fromDate') &&
    keys.includes('toDate') &&
    keys.includes('dateOption')
  );
};

const hasDateValues = (formValues: FormValuesType): boolean => {
  return !isEmptyValue(formValues?.fromDate?.value) && !isEmptyValue(formValues?.toDate?.value);
};

const isDatesEmpty = (formValues: FormValuesType): boolean => {
  return isEmptyValue(formValues?.fromDate?.value) && isEmptyValue(formValues?.toDate?.value);
};

const isDatesCustom = (formValues: FormValuesType): boolean =>
  formValues.dateOption?.value === 'custom';

const hasCustomAndDateValues = (formValues: FormValuesType): boolean => {
  return hasDateValues(formValues) && isDatesCustom(formValues);
};

const isEmptyAndNotOnlyDateFields = (formValues: FormValuesType): boolean => {
  return isDatesEmpty(formValues) && !hasOnlyDateFields(formValues);
};

const isRangeDatesFieldsValid = (formValues: FormValuesType): boolean => {
  return (
    hasDateValues(formValues) ||
    (isEmptyAndNotOnlyDateFields(formValues) && !isDatesCustom(formValues)) ||
    (hasCustomAndDateValues(formValues) && !hasOnlyDateFields(formValues))
  );
};

const validationForButtonDisabled = (formValues: FormValuesType): boolean => {
  const validators = [hasValuesInOtherFields, isRangeAmountsFieldsValid, isRangeDatesFieldsValid];

  const isButtonEnabled = validators.every(validator => validator(formValues));

  return !isButtonEnabled;
};

export {
  formatFormValueParams,
  getValueOrDefault,
  validationForButtonDisabled,
  hasValuesInOtherFields,
  hasOnlyAmountFields,
  hasAmountValues,
  isAmountsEmpty,
  isFromAmountLessThanToAmount,
  isRangeAmountsFieldsValid,
  hasOnlyDateFields,
  hasDateValues,
  isDatesEmpty,
  isRangeDatesFieldsValid,
};
