// Types
import { MobileConnectorHookReturnPropsType } from 'providers/mobile-connector/hooks/types/mobile-connector-hook-return.type';
// Utilities
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';
import { isMobileDevice, isTabletDevice } from '@openbank/cf-ui-framework';

const isSecondLevelRoutePathAndMobileOrHybrid = (
  isHybrid: MobileConnectorHookReturnPropsType['isHybrid']
): boolean => isHybrid || (isSecondLevelRoutePath() && (isMobileDevice() || isTabletDevice()));

export { isSecondLevelRoutePathAndMobileOrHybrid };
