// Services
import { fetchCustomerInformationService } from '../services/customer.services';
// Types
import { CustomerDataType } from 'types/customer/customer-data.type';
// Utilities
import { isLegitClientDataApiResponse } from './utils/customer.resolver.utils';

const fetchCustomerInformationResolver = async (): Promise<[CustomerDataType] | [null, true]> => {
  const [response] = await fetchCustomerInformationService();

  return isLegitClientDataApiResponse(response) ? [response as CustomerDataType] : [null, true];
};

export { fetchCustomerInformationResolver };
