// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { TransactionsEppStep1CreditMessageHandlersType } from './types/transactions-epp-step1-credit-message.handlers.type';
import { TransactionsEppStep1CreditMessageHandlersReturnType } from './types/transactions-epp-step1-credit-message-return.handlers.type';
// Utils
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const modifyCreditLimitButtonClickHandler = ({
  navigate,
}: TransactionsEppStep1CreditMessageHandlersType): void => {
  navigate(
    generateAbsoluteRoute(
      generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumeration.CREDIT_LIMIT_INCREASE])
    )
  );
};

export const TransactionsEppStep1CreditMessageHandlers = (
  props: TransactionsEppStep1CreditMessageHandlersType
): TransactionsEppStep1CreditMessageHandlersReturnType => ({
  handleModifyCreditLimitButtonClick: () => modifyCreditLimitButtonClickHandler(props),
});
