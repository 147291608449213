// Vendors
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { WizardStepFooterCancelModalTrackingHandlersType } from './types/wizard-step-footer-cancel-modal.tracking.handlers.type';

const cancelRecoveryProcessLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'yesCancelTheProcess',
  },
  type: TrackingEventsEnum.LINK,
});

const closeWizardStepFooterCancelProcessModalAssetClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickAsset',
      eventLabel: 'close',
    },
    type: TrackingEventsEnum.LINK,
  });

const confirmToContinueWizardStepFooterButtonClickTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickButton',
      eventLabel: 'noContinue',
    },
    type: TrackingEventsEnum.LINK,
  });

const openCancelWizardStepFooterModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'openModal',
    eventLabel: 'cancelWizardStepFooter',
  },
  type: TrackingEventsEnum.LINK,
});

const WizardStepFooterCancelModalTrackingHandlers = (
  track: TrackBuilderType
): WizardStepFooterCancelModalTrackingHandlersType => ({
  handleCancelExitProcessLinkClickTracking: () =>
    track(cancelRecoveryProcessLinkClickTrackingHandler()),
  handleCancelRecoveryProcessLinkClickTracking: () =>
    track(cancelRecoveryProcessLinkClickTrackingHandler()),
  handleCloseWizardStepFooterCancelProcessModalAssetClickTracking: () =>
    track(closeWizardStepFooterCancelProcessModalAssetClickTrackingHandler()),
  handleConfirmToContinueProcessButtonClickTracking: () =>
    track(confirmToContinueWizardStepFooterButtonClickTrackingHandler()),
  handleOpenCancelWizardStepFooterModalTracking: () =>
    track(openCancelWizardStepFooterModalTrackingHandler()),
});

export default WizardStepFooterCancelModalTrackingHandlers;
