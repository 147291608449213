// Vendors
import React from 'react';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  SidebarComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import sidebarContentConstants from './constants/sidebar-content.constants';
// Styles
import { CheckCvvPinSidebarExpandedComponentStyled } from './sidebar-content.component.styled';
// Translations
import sidebarContentTranslations from './translations/sidebar-content.translations';
// Types
import { SidebarContentProps } from './types/sidebar-content.type';

const { sections } = sidebarContentConstants;

const SidebarContentComponent = ({ type }: SidebarContentProps): React.ReactElement => (
  <SidebarComponent
    title={<FormattedMessageComponent id={sidebarContentTranslations[type]?.TITLE} />}
  >
    {sections.map(section => (
      <ExpandableRowComponent
        key={section}
        title={
          <FormattedMessageComponent
            id={sidebarContentTranslations[type]?.[`SIDEBAR_${section}_TITLE`]}
          />
        }
      >
        <CheckCvvPinSidebarExpandedComponentStyled>
          <ParagraphComponent>
            <FormattedMessageComponent
              id={sidebarContentTranslations[type]?.[`SIDEBAR_${section}_DESC`]}
            />
          </ParagraphComponent>
        </CheckCvvPinSidebarExpandedComponentStyled>
      </ExpandableRowComponent>
    ))}
  </SidebarComponent>
);

export default SidebarContentComponent;
