// Services
import { switchTravelPlusStatusService } from '../services/travel-plus.service';
// Types
import { TravelPlusApiBuilderType } from '../types/travel-plus-api-builder.type';
// Utils
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const switchTravelPlusStatusResolver = async (
  params: TravelPlusApiBuilderType
): Promise<[number] | [null, boolean]> => {
  const [, { status }] = await switchTravelPlusStatusService(params);

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

export { switchTravelPlusStatusResolver };
