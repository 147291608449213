// Vendors
import {
  TrackingErrorEventReturnType,
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsChange3dStep1RepeatedCodeBuilderReturnTrackingHandlersType } from './types/card-settings-change-3d-step1-repeated-code.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const repeated3DCodeInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickInput',
    eventLabel: 'repeated3DCode',
  },
  type: TrackingEventsEnum.LINK,
});

const repeated3DCodeInputErrorTrackingHandler = (): TrackingErrorEventReturnType => ({
  payload: {
    eventAction: 'repeated3DCodeError',
    eventLabel: 'invalidCode',
    errorCategory: 'repeated3DCodeError',
    errorDescription: 'notMatched',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsChange3dStep1RepeatedCodeTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsChange3dStep1RepeatedCodeBuilderReturnTrackingHandlersType => ({
  handleRepeated3DCodeInputClickTracking: () => track(repeated3DCodeInputClickTrackingHandler()),
  handleRepeated3DCodeInputErrorTracking: () => track(repeated3DCodeInputErrorTrackingHandler()),
});

export { CardSettingsChange3dStep1RepeatedCodeTrackingHandlers };
