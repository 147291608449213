// Enumerations
import { ToastTypesEnumeration } from 'enumerations/toast-types.enumeration';
// Types
import { GetSnackBarConfigurationType } from './type/sign-out.snackbar.props.type';
import { GetSnackBarConfigurationReturnType } from './type/sign-out.snackbar.return.type';
// Translations
import { MESSAGE_SUCCESS, MESSAGE_ERROR } from '../translations/sign-out.translations';

export const getSnackBarConfiguration = ({
  type,
}: GetSnackBarConfigurationType): GetSnackBarConfigurationReturnType => {
  return {
    [ToastTypesEnumeration.SUCCESS]: {
      type: ToastTypesEnumeration.SUCCESS,
      description: MESSAGE_SUCCESS,
    },
    [ToastTypesEnumeration.ERROR]: {
      type: ToastTypesEnumeration.ERROR,
      description: MESSAGE_ERROR,
    },
  }[type];
};

export const getButtonConfiguration = (device: string): any => ({
  type: 'secondary',
  testId: `device_${device}_sign_out_button`,
});
