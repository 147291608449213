// Themes
import { AmazonTheme } from '@openbank/cf-ui-static-data';
// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Enums
import { RequestEmailOptionEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Translations
import translations from '../translations/request-portability-step2.translations';

export default {
  REQUEST_PORTABILITY_STEP2_MESSAGES: [
    {
      id: 0,
      testId: 'request-portability-step2-message-01',
      text: translations.MESSAGE_1,
    },
    {
      id: 1,
      testId: 'request-portability-step2-message-02',
      text: translations.MESSAGE_2,
    },
    {
      id: 2,
      testId: 'request-portability-step2-message-03',
      text: translations.MESSAGE_3,
    },
    {
      id: 3,
      testId: 'request-portability-step2-message-04',
      text: translations.MESSAGE_4,
    },
  ],
  LINE_SEPARATOR_PROPS: {
    color: AmazonTheme.default.colors.backgroundTertiaryA,
  },
};
