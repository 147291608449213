// Vendors
import React from 'react';
// Components
import { AccessPortabilityRequestStep2Component } from '../components/step2/access-portability-request-step2.component';
import RequestAccessStep1Component from '../components/step1/request-access-step1.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK,
  SUCCESS_TITLE,
} from '../translations/request-access-feedback.translations';
import {
  TITLE_STEP_1,
  TITLE_STEP_2,
} from 'containers/personal/views/access-portability/views/request/translations/request-access.translations';
// Types
import { WizardComponentType } from 'components/wizard/types/wizard.component.type';
// Utils
import { generatePathRoute } from 'utils/app-routing.utils';

const getRequestAccessWizardConfiguration = ({
  email,
}: {
  email: string;
}): WizardComponentType => ({
  feedbackConfiguration: {
    errorConfiguration: {
      description: ERROR_DESCRIPTION,
      title: ERROR_TITLE,
      exitRoute: generatePathRoute([RoutesEnumeration.PERSONAL_INFORMATION, RoutesEnumeration.ACCESS_PORTABILITY]),
    },
    successConfiguration: {
      description: SUCCESS_DESCRIPTION,
      descriptionValues: {
        b: chunks => <strong>{chunks}</strong>,
        email,
        br: <br />,
      },
      linkLabel: SUCCESS_LINK,
      title: SUCCESS_TITLE,
      exitRoute: generatePathRoute([RoutesEnumeration.PERSONAL_INFORMATION, RoutesEnumeration.ACCESS_PORTABILITY]),
    },
  },
  steps: [
    {
      component: RequestAccessStep1Component,
      title: TITLE_STEP_1,
    },
    {
      component: AccessPortabilityRequestStep2Component,
      title: TITLE_STEP_2,
    },
  ],
});

export { getRequestAccessWizardConfiguration };
