// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Types
import { FinancingPPIContractingResumeType } from './types/financing-ppi-contracting-resume.type';

const useFinancingPPIContractingResumeHook = (): FinancingPPIContractingResumeType => {
  const { insurancesInfo } = useInsurancesContextConsumerHook(),
    { ppiPercentage } = insurancesInfo?.insurances.CPI || {},
    { profile, localization } = AppContextConsumerHook(),
    { userName } = profile?.userDetails || {},
    { Cobranded_CpiName: productName } = localization?.config || {};

  return {
    productName,
    ppiPercentage,
    userName,
  };
};

export { useFinancingPPIContractingResumeHook };
