// Vendors
import { useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useFetchErrorContextConsumerHook } from 'contexts/fetch-error/fetch-error.context';
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
import { ToastContextConsumerHook } from 'contexts/toast.context';
// Constants
import { TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_INITIAL_ATTEMPTS } from '../constants/transactions-epp-step1-simulation-options.constants';
// Event handlers
import { TransactionsEppStep1SimulationOptionsHandlers } from '../handlers/transactions-epp-step1-simulation-options.handlers';
import { TransactionsEppStep1SimulationOptionsTrackingHandlers } from '../handlers/transactions-epp-step1-simulation-options.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { TransactionsEppStep1SimulationOptionsHookType } from './types/transactions-epp-step1-simulation-options.hook.type';

const useTransactionsEppStep1SimulationOptionsHook =
  (): TransactionsEppStep1SimulationOptionsHookType => {
    const [, setApiRetryAttempts] = useState<number>(
        TRANSACTIONS_EPP_STEP1_SIMULATION_OPTIONS_INITIAL_ATTEMPTS
      ),
      [fetching, setFetching] = useState<boolean>(false);

    const { formatMessage } = useCbIntl();

    const { installments, isAllOptionsSelected, setInstallments, setIsAllOptionsSelected } =
        useTransactionsEppContextConsumerHook(),
      { setEppPlanSimulation, transactionDetails } = useTransactionsEppContextConsumerHook(),
      { currentCard } = AppContextConsumerHook(),
      { error, setError } = useFetchErrorContextConsumerHook(),
      { setToastConfiguration } = ToastContextConsumerHook(),
      { id: cardId } = currentCard || {},
      { id: transactionId, maxNumOfInstallments, minNumOfInstallments } = transactionDetails;

    return {
      ...TransactionsEppStep1SimulationOptionsHandlers({
        ...AppTrackingHook(TransactionsEppStep1SimulationOptionsTrackingHandlers),
        cardId,
        setApiRetryAttempts,
        setFetching,
        setError,
        setIsAllOptionsSelected,
        setInstallments,
        setEppPlanSimulation,
        setToastConfiguration,
        transactionId,
      }),
      fetching,
      formatMessage,
      error,
      installments,
      isAllOptionsSelected,
      maxNumOfInstallments,
      minNumOfInstallments,
    };
  };

export { useTransactionsEppStep1SimulationOptionsHook };
