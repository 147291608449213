const DOMAIN = 'cobranded.services.userSettings.privateArea.inactivityFlow.idle.cbModal.';

const DESCRIPTION = `${DOMAIN}description02`,
  DESCRIPTION_TITLE = `${DOMAIN}description01`,
  BUTTON = `${DOMAIN}keepSessionAliveButton`,
  SECONDS = `${DOMAIN}secondsLabel`,
  LINK = `${DOMAIN}exitLink`,
  TITLE = `${DOMAIN}title`;

export { DESCRIPTION, DESCRIPTION_TITLE, BUTTON, LINK, SECONDS, TITLE };
