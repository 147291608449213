// Vendors
import { useEffect } from 'react';
// Contexts
import { useChatContext } from '@openbank/cb-ui-commons';
// Event handlers
import { SupportLandingHandlers } from '../handlers/support-landing.handlers';
// Types
import { SupportLandingHookType } from 'containers/support/views/landing/hooks/types/support-landing.hook.type';
// Utils

const useSupportLandingHook = (): SupportLandingHookType => {
  const openChat = useChatContext();

  const { handleWatchOpenChatFromPublicEvent } = SupportLandingHandlers({ openChat });

  useEffect(() => {
    handleWatchOpenChatFromPublicEvent();
  }, []);

  return {
    openChat,
  };
};

export { useSupportLandingHook };
