// Constants
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
import { messageConstants } from '@openbank/cb-ui-commons';
// Translations
import translations from '../../components/message/translations/dashboard-spent-message.translations';
import { translations as topMessageTranslations } from 'containers/dashboard/components/top-message/translations/dashboard-top-message.translations';
// Types
import { getDashboardSpentMessageConfigurationType } from '../types/dashboard-spent-message.configuration.type';
import { getDashboardSpentMessageConfigurationReturnType } from '../configurations/types/get-dashboard-configuration-return.type';
// Utils
import { isDueAmount } from 'containers/dashboard/utils/dashboard-card-block.utils';
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

const createMessageConfig = ({
  formatMessage,
  type,
  titleId,
  descriptionId,
  descriptionVariables,
}) => ({
  title: formatMessage({ id: titleId }),
  type,
  description: formatMessage({ id: descriptionId }, descriptionVariables),
});

const getDashboardSpentMessageConfiguration = ({
  currentDueAmount,
  formatMessage,
  formatNumber,
  primaryAccountId,
  nonPaid,
}: getDashboardSpentMessageConfigurationType): getDashboardSpentMessageConfigurationReturnType | null => {
  if (nonPaid) {
    return {
      title: formatMessage({ id: topMessageTranslations.TITLE }),
      type: messageConstants.TYPES.WARNING,
      description: formatMessage({ id: topMessageTranslations.DESCRIPTION }),
    };
  }

  if (isDueAmount(currentDueAmount)) {
    return createMessageConfig({
      formatMessage,
      titleId: translations.title,
      descriptionId: translations.description2,
      descriptionVariables: {
        currentDueAmount: formatNumber(currentDueAmount, EURO_FORMAT_OPTIONS),
        primaryAccountId,
        b: makeChunkBoldIntlUtil,
      },
      type: messageConstants.TYPES.INFORMATIVE,
    });
  }

  return null;
};

export { getDashboardSpentMessageConfiguration };