const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.insurances.contracting.lpi.step2.plans.';

const TITLE = `${DOMAIN}title`;

const PLAN_COVERAGE = `${DOMAIN}planCoverage`;

const PLAN_PRICE = `${DOMAIN}planPrice`;

const DISCLAIMER = `${DOMAIN}disclaimer`;

export { TITLE, PLAN_COVERAGE, PLAN_PRICE, DISCLAIMER };
