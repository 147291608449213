// Vendors
import React from 'react';
// Components
import { ParagraphComponent, TitleComponent, ToggleComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import RequestPortabilityCheckboxes from '../checkboxes/request-portability-checkboxes.component';
import RequestPortabilityStep1ActionsComponent from './components/actions/request-portability-step1-actions.component';
// Constants
import {
  REQUEST_PORTABILITY_STEP1_MESSAGES,
  REQUEST_PORTABILITY_STEP1_PARAGRAPH_01_PROPS,
  REQUEST_PORTABILITY_STEP1_PARAGRAPH_02_PROPS,
  REQUEST_PORTABILITY_STEP1_PARAGRAPH_03_PROPS,
  REQUEST_PORTABILITY_STEP1_PERSONAL_TOGGLE,
  REQUEST_PORTABILITY_STEP1_PRODUCTS_TOGGLE,
  TITLE_CONTRACTED_PRODUCTS_PROPS,
  TITLE_PERSONAL_DATA_PROPS,
} from './constants/request-portability-step1.constants';
// Hooks
import RequestPortabilityHook from '../../hooks/request-portability.hook';
// Styles
import {
  RequestPortabilityStep1ContainerStyled,
  RequestPortabilityStep1ListItemStyled,
  RequestPortabilityStep1ListStyled,
  RequestPortabilityStep1ToggleContainerStyled,
  RequestPortabilityStep1ToggleLabelsStyled,
} from './request-portability-step1.component.styled';
// Translations
import { OFF, ON } from 'translations/toggle.translations';
import translations from './translations/request-portability-step1.translations';

const RequestPortabilityStep1Component = (): React.ReactElement => {
  const {
    contractedProductsToggle,
    formatMessage,
    handleContractedProductsToggleChangeEvent,
    handlePersonalDataToggleChangeEvent,
    personalDataToggle,
  } = RequestPortabilityHook();

  return (
    <RequestPortabilityStep1ContainerStyled>
      <ParagraphComponent {...REQUEST_PORTABILITY_STEP1_PARAGRAPH_01_PROPS}>
        <FormattedMessageComponent id={translations.DESCRIPTION_01} />
      </ParagraphComponent>
      <RequestPortabilityStep1ToggleContainerStyled>
        <RequestPortabilityStep1ToggleLabelsStyled>
          <TitleComponent {...TITLE_PERSONAL_DATA_PROPS}>
            <FormattedMessageComponent id={translations.PERSONAL_DATA_TITLE} />
          </TitleComponent>
          <ParagraphComponent {...REQUEST_PORTABILITY_STEP1_PARAGRAPH_02_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION_02} />
          </ParagraphComponent>
        </RequestPortabilityStep1ToggleLabelsStyled>
        <ToggleComponent
          {...REQUEST_PORTABILITY_STEP1_PERSONAL_TOGGLE}
          labelOff={formatMessage({ id: OFF }).toUpperCase()}
          labelOn={formatMessage({ id: ON }).toUpperCase()}
          onChange={handlePersonalDataToggleChangeEvent}
          value={personalDataToggle}
        />
      </RequestPortabilityStep1ToggleContainerStyled>
      <RequestPortabilityStep1ListStyled>
        {REQUEST_PORTABILITY_STEP1_MESSAGES.map(config => (
          <RequestPortabilityStep1ListItemStyled key={config.id}>
            <ParagraphComponent {...config}>
              <FormattedMessageComponent id={config.text} />
            </ParagraphComponent>
          </RequestPortabilityStep1ListItemStyled>
        ))}
      </RequestPortabilityStep1ListStyled>
      <RequestPortabilityStep1ToggleContainerStyled>
        <RequestPortabilityStep1ToggleLabelsStyled>
          <TitleComponent {...TITLE_CONTRACTED_PRODUCTS_PROPS}>
            <FormattedMessageComponent id={translations.CONTRACTED_PRODUCTS_TITLE} />
          </TitleComponent>
          <ParagraphComponent {...REQUEST_PORTABILITY_STEP1_PARAGRAPH_03_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION_03} />
          </ParagraphComponent>
        </RequestPortabilityStep1ToggleLabelsStyled>
        <ToggleComponent
          {...REQUEST_PORTABILITY_STEP1_PRODUCTS_TOGGLE}
          labelOff={formatMessage({ id: OFF }).toUpperCase()}
          labelOn={formatMessage({ id: ON }).toUpperCase()}
          onChange={handleContractedProductsToggleChangeEvent}
          value={contractedProductsToggle}
        />
      </RequestPortabilityStep1ToggleContainerStyled>
      <RequestPortabilityCheckboxes {...{ contractedProductsToggle }} />
      <RequestPortabilityStep1ActionsComponent />
    </RequestPortabilityStep1ContainerStyled>
  );
};

export default RequestPortabilityStep1Component;
