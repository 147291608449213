// Vendors
import React, { useEffect } from 'react';
// Components
import { CardSettingsBenefitsCommercialCardComponent } from './components/benefits-commercial-card/card-settings-benefits-commercial-card.component';
import { CardSettingsTogglesTravelPlusModalComponent } from '../landing/components/toggles/components/travel-plus-modal/card-settings-toggles-travel-plus-modal.component';
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
// Configurations
import { getErrorLinkConfiguration } from 'configurations/app.configurations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { CardSettingsBenefitsLandingHook } from './hooks/card-settings-benefits.hook';
// Styles
import { CardSettingsBenefitsComponentStyled } from './card-settings-benefits.component.styled';

const CardSettingsBenefitsInsuranceComponent = (): React.ReactElement => {
  const {
    commercialCardConfiguration,
    error,
    fetching,
    formatMessage,
    handleToggleTravelPlusToggleChange: onClick,
    navigate,
    showTravelPlusModal,
  } = CardSettingsBenefitsLandingHook();

  return (
    <>
      <ErrorWrapperComponent
        {...{
          fetching,
          error,
          linkConfiguration: getErrorLinkConfiguration({
            formatMessage,
            route: RoutesEnumeration.BENEFITS_INSURANCE,
            navigate,
          }),
        }}
      >
        <CardSettingsBenefitsComponentStyled>
          <CardSettingsBenefitsCommercialCardComponent
            commercialCardConfiguration={commercialCardConfiguration}
          />
        </CardSettingsBenefitsComponentStyled>
      </ErrorWrapperComponent>
      <CardSettingsTogglesTravelPlusModalComponent
        onClick={onClick}
        showTravelPlusModal={showTravelPlusModal}
      />
    </>
  );
};

export { CardSettingsBenefitsInsuranceComponent };
