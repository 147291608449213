// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import {
  getUploadDocumentUrlService,
  rejectDocumentService,
  uploadDocumentService,
} from 'services/upload-documents/upload-documents.services';
// Types
import { GetUploadDocumentUrlServicePropsType } from 'services/upload-documents/types/get-upload-document-url-props.service.type';
import { UploadDocumentServicePropsType } from 'services/upload-documents/types/upload-docs-props.service.type';
import { RejectDocumentServicePropsType } from 'services/upload-documents/types/reject-document-props.service.type';
import { GetUploadDocumentUrlServiceResponseType } from 'services/upload-documents/types/get-upload-document-url-response.service.type';

const getUploadDocumentUrlResolver = async (
  props: GetUploadDocumentUrlServicePropsType
): Promise<[Record<string, any> | GetUploadDocumentUrlServiceResponseType | null, boolean]> => {
  const [response, { status }] = await getUploadDocumentUrlService(props);

  return requestHasSuccessStatus(status) ? [response, false] : [null, true];
};

const uploadDocumentResolver = async (
  props: UploadDocumentServicePropsType
): Promise<[Record<string, any> | null, boolean]> => {
  const [response, { status }] = await uploadDocumentService(props);

  return requestHasSuccessStatus(status) ? [response, false] : [null, true];
};

const rejectDocumentResolver = async (
  props: RejectDocumentServicePropsType
): Promise<[Record<string, any> | null, boolean]> => {
  const [response, { status }] = await rejectDocumentService(props);

  return requestHasSuccessStatus(status) ? [response, false] : [null, true];
};

export { getUploadDocumentUrlResolver, rejectDocumentResolver, uploadDocumentResolver };
