// Vendors
import styled from 'styled-components';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

export const DocumentsTableRowComponentStyled = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundPrimaryB};
  display: grid;
  grid-template-columns: ${convertPxsToRems({ pixels: 100, base: 14 })} 1fr;
  min-height: ${convertPxsToRems({ pixels: 56, base: 14 })};
  padding: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
    height: auto;
    padding: ${({ theme }) => theme.spaces.spacingS} 0;
  }
`;

export const DocumentsTableRowComponentSeparatorStyled = styled.div`
  padding: 0 ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    padding: 0;
  }
`;

export const DocumentsTableRowDateStyled = styled.div`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
`;

export const DocumentsTableRowDescriptionStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingS};
  }
`;
