// Constants
import { DISPUTE_PARAMS } from 'containers/transactions/views/report/components/different-amount-atm/components/confirmation/constants/transactions-report-different-amount-atm-confirmation.constants';
// Resolvers
import { fetchReportProblemResolver } from 'containers/transactions/views/report/resolvers/transactions-report.resolvers';
// Types
import { FormValuesType } from 'containers/transactions/views/report/components/different-amount-atm/context/types/transactions-report-different-amount-atm.type';
import { TransactionsReportDifferentAmountAtmConfirmationActionsHandlersType } from './types/transactions-report-different-amount-atm-confirmation-actions.handlers.type';
import { TransactionsReportDifferentAmountAtmConfirmationActionsHandlersReturnType } from './types/transactions-report-different-amount-atm-confirmation-actions-return.handlers.type';
// Utils
import { isWrongOTPError } from 'utils/challenges/challenge.utils';
import { determineClaimReasonType } from 'containers/transactions/views/report/components/different-amount-atm/components/confirmation/utils/transactions-report-different-amount-atm-confirmation.utils';

const confirmButtonClickHandler = async ({
  cardId,
  challenge,
  formValues,
  setCaseId,
  setChallengeError,
  setError,
  setFormValues,
  setResult,
  transactionId,
}: TransactionsReportDifferentAmountAtmConfirmationActionsHandlersType): Promise<void> => {
  try {
    if (!cardId || !transactionId) throw new Error();

    const [response, error] = await fetchReportProblemResolver({
      cardId,
      transactionId,
      data: {
        ...DISPUTE_PARAMS,
        claimReasonType: determineClaimReasonType({ formValues }),
        receiveDiffAmountThanRequested: formValues as FormValuesType,
      },
      progressId: challenge?.progressId,
    });

    if (error) {
      if (isWrongOTPError(error)) {
        setChallengeError(true);
      } else {
        setFormValues({} as FormValuesType);
        setResult(false);
      }
    } else {
      setResult(true);
      setCaseId(response?.caseId);
    }
  } catch {
    setError(true);
  }
};

export const TransactionsReportDifferentAmountAtmConfirmationActionsHandlers = (
  props: TransactionsReportDifferentAmountAtmConfirmationActionsHandlersType
): TransactionsReportDifferentAmountAtmConfirmationActionsHandlersReturnType => ({
  handleConfirmButtonClick: () => confirmButtonClickHandler(props),
});
