// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  TRANSACTIONS_EPP_STEP2_SERVICE_CONFIGURATION,
  TRANSACTIONS_EPP_STEP2_SERVICE_PARAMS,
} from './constants/transactions-epp-step2.service.constants';
// Types
import { TransactionsEppStep2ApiBuilderType } from '../types/transactions-epp-step2-api-builder.type';
import { TransactionsEppStep2ApiReturnType } from '../types/transactions-epp-step2-api-return.type';

const createTransactionEppPlanService = async ({
  cardId,
  progressId,
  ...params
}: TransactionsEppStep2ApiBuilderType): Promise<
  [Record<string, never> | TransactionsEppStep2ApiReturnType, Response]
> =>
  apiUtilsCb.post({
    ...TRANSACTIONS_EPP_STEP2_SERVICE_CONFIGURATION,
    ...(progressId ? { customHeaders: { progressId } } : {}),
    pathParams: { cardId },
    params: { ...TRANSACTIONS_EPP_STEP2_SERVICE_PARAMS, ...params },
  });

export { createTransactionEppPlanService };
