// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SecurityKeysAccessKeyChangeHandlersTrackingReturnType } from './types/security-keys-access-key-change.tracking.handlers.types';

const showHideInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: '<show | hide>AccessKeyLink',
  },
  type: TrackingEventsEnum.LINK,
});

const SecurityKeysAccessKeyChangeTrackingHandlers = (
  track: TrackBuilderType
): SecurityKeysAccessKeyChangeHandlersTrackingReturnType => ({
  handleShowHideInputClickTracking: () => track(showHideInputClickTrackingHandler()),
});

export default SecurityKeysAccessKeyChangeTrackingHandlers;
