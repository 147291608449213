// Vendors
import styled from 'styled-components';
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsTogglesComponentStyled = styled.section`
  display: flex;
  align-items: flex-start;
  flex: 1;
  -webkit-box-pack: justify;
  justify-content: space-evenly;
  width: 100%;

  ${mobileMediaQuery} {
    align-items: stretch;
    border-bottom: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
      ${({ theme }) => theme.colors.backgroundTertiaryD};
    display: flex;
    justify-content: space-evenly;
    padding-bottom: ${({ theme }) => theme.spaces.spacingM};
  }
`;
CardSettingsTogglesComponentStyled.displayName = 'CardSettingsTogglesComponentStyled';

const CardSettingsTogglesLeftComponentStyled = styled.div`
  border-right: ${convertPxsToRems({ pixels: 1, base: 14 })} solid
    ${({ theme }) => theme.colors.backgroundTertiaryD};

  ${mobileMediaQuery} {
    padding-right: ${({ theme }) => theme.spaces.spacingL};
  }
`;
CardSettingsTogglesLeftComponentStyled.displayName = 'CardSettingsTogglesLeftComponentStyled';

export { CardSettingsTogglesComponentStyled, CardSettingsTogglesLeftComponentStyled };
