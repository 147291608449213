// Vendors
import React from 'react';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Components
import { AssetComponent, ModalComponent } from '@openbank/cb-ui-commons';
import { EmployedSectionComponent } from './components/employed-section/employed-section.component';
import { MainAccountSectionComponent } from './components/main-account-section/main-account-section.component';
import { PensionerSectionComponent } from './components/pensioner-section/pensioner-section.component';
import { SelfEmployedSectionComponent } from './components/self-employed-section/self-employed-section.component';
// Constants
import { NOT_POSSIBLE_ASSET_MODAL_PROPS } from './constants/upload-documents-section.constants';
// Enumerations
import { ActivityTypeDeclaredEnumeration } from 'containers/financing/views/credit-limit-increase/components/step2/sections/employment-data/enumerations/employment-status.enumeration';
// Hooks
import { UploadDocumentsSectionHook } from './hooks/upload-documents-section.hook';
// Styles
import {
  ModalContainerStyled,
  ParagraphStartStyled,
} from './upload-documents-section.component.styled';
// Translations
import { step4UploadDocumentationTranslations } from '../../translations/step-4-upload-documentation.translations';
// Types
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';

const UploadDocumentsSectionComponent = ({
  formatMessage,
}: {
  formatMessage: UseCbIntlReturnType['formatMessage'];
}): React.ReactElement => {
  const {
    activityTypeDeclared,
    documentsRejected,
    isLoadingExitProcess,
    onHeaderClose,
    handOnClickExitProcess,
  } = UploadDocumentsSectionHook();

  return (
    <>
      <MainAccountSectionComponent
        documentsRejected={documentsRejected}
        formatMessage={formatMessage}
      />
      <EmployedSectionComponent
        activityTypeDeclared={activityTypeDeclared}
        documentsRejected={documentsRejected}
        formatMessage={formatMessage}
      />
      <PensionerSectionComponent
        activityTypeDeclared={activityTypeDeclared}
        documentsRejected={documentsRejected}
        formatMessage={formatMessage}
      />
      <SelfEmployedSectionComponent
        activityTypeDeclared={activityTypeDeclared}
        documentsRejected={documentsRejected}
        formatMessage={formatMessage}
      />
      {activityTypeDeclared === ActivityTypeDeclaredEnumeration.UNEMPLOYED && (
        <ModalComponent
          isIosDevice={isIosWebView()}
          primaryButtonConfiguration={{
            children: formatMessage({ id: step4UploadDocumentationTranslations.EXIT_THE_PROCESS }),
            disabled: isLoadingExitProcess,
            loading: isLoadingExitProcess,
            onClick: handOnClickExitProcess,
          }}
          onHeaderClose={onHeaderClose}
          title={formatMessage({ id: step4UploadDocumentationTranslations.IT_IS_NOT_POSSIBLE })}
        >
          <ModalContainerStyled>
            <AssetComponent {...NOT_POSSIBLE_ASSET_MODAL_PROPS} />
            <ParagraphStartStyled>
              {formatMessage({ id: step4UploadDocumentationTranslations.LAST_INCOME_TAX })}
            </ParagraphStartStyled>
          </ModalContainerStyled>
        </ModalComponent>
      )}
    </>
  );
};

export { UploadDocumentsSectionComponent };
