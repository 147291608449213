// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, ModalComponent, AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import { IMAGE_ASSEST_PROPS } from './constants/card-pin-info-modal.constants';
// Configurations
import { getCardPinInfoModalConfigurations } from './configurations/card-pin-info-modal.configurations';
// Styles
import {
  CardPinInfoModalComponentImageStyled,
  CardPinInfoModalComponentListItemStyled,
  CardPinInfoModalComponentListStyled,
  CardPinInfoModalComponentStyled,
} from './card-pin-info-modal.component.styled';
// Translations
import {
  MODAL_LIST,
  DESCRIPTION,
  FOUR_DIGITS,
} from './translations/card-pin-info-modal.translations';
// Types
import { CardPinInfoModalComponent } from './types/card-pin-info-modal.component.type';

const CardPinInfoModalComponent = ({
  onCloseModal,
  formatMessage,
}: CardPinInfoModalComponent): React.ReactElement => (
  <ModalComponent
    isIosDevice={isIosWebView()}
    {...{ ...getCardPinInfoModalConfigurations({ onCloseModal, formatMessage }) }}
  >
    <CardPinInfoModalComponentStyled>
      <ParagraphComponent>
        <FormattedMessageComponent {...{ id: DESCRIPTION }} />
      </ParagraphComponent>
      <ParagraphComponent>
        <FormattedMessageComponent {...{ id: FOUR_DIGITS }} />
      </ParagraphComponent>
      <CardPinInfoModalComponentImageStyled>
        <AssetComponent {...{ ...IMAGE_ASSEST_PROPS }} />
      </CardPinInfoModalComponentImageStyled>
      <CardPinInfoModalComponentListStyled>
        {MODAL_LIST.map((item, index) => (
          <CardPinInfoModalComponentListItemStyled key={index}>
            <ParagraphComponent>
              <FormattedMessageComponent {...{ id: item }} />
            </ParagraphComponent>
          </CardPinInfoModalComponentListItemStyled>
        ))}
      </CardPinInfoModalComponentListStyled>
    </CardPinInfoModalComponentStyled>
  </ModalComponent>
);

export { CardPinInfoModalComponent };
