// Constants
import { StepEnumToIndexMap } from '../constants/credit-limit-increase.constants';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../enums/credit-limit-increase.view.enumeration';
// Resolvers
import { backToPreviousStepResolver } from '../resolvers/step-0-init.resolvers';
import { setLaboralEconomicDataResolver } from '../resolvers/step-2-set-laboral-economic-data.resolvers';
// Types
import { handleSetLaboralEconomicDataPropsType } from './types/handle-set-laboral-economic-data.type';
import { SetLaboralEconomicDataReturnType } from './types/step-2-set-laboral-economic-data-handlers-return.type';
import { SetLaboralEconomicPropsType } from './types/step-2-set-laboral-economic-data-handlers-props.type';
import { PersonalDataLocalType } from '../contexts/types/personal-data-local-step-2.type';
// Utils
import { mapLocalToPersonalData } from '../services/utils/step-2-laboral-economic-data.utils';

const setLaboralEconomicDataHandler = async ({
  creditLimitContext,
  personalDataLocal,
  wizardStatus,
  setCurrentStep,
  setWizardStatus,
}: handleSetLaboralEconomicDataPropsType) => {
  const { step2, setStep2 } = creditLimitContext;
  setStep2({ ...step2, isLoadingNextRequest: true });

  const personalData = mapLocalToPersonalData(personalDataLocal);
  const [response, error] = await setLaboralEconomicDataResolver({
    caseId: wizardStatus.caseId,
    personalData,
  });

  if (error) {
    setStep2({ ...step2, isLoadingNextRequest: false });
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }

  if (response) {
    const currentStep = StepEnumToIndexMap[response.nextStep];
    const currentStepInText = response.nextStep as CreditLimitIncreaseStepsEnumeration;

    if (currentStepInText === CreditLimitIncreaseStepsEnumeration.END_NAVIGATION) {
      setStep2({ ...step2, isRejectM1: true });
    } else {
      setWizardStatus({
        ...wizardStatus,
        fetching: false,
        error: false,
        currentStep,
        currentStepInText,
      });
      setCurrentStep(currentStep);
    }
  }
};

const setLaboralEconomicDataBackHandler = async ({
  cardId,
  creditLimitContext,
  setCurrentStep,
  handleClickPreviousButtonTracking,
}: SetLaboralEconomicPropsType) => {
  const { step1, setStep1, step2, setStep2, wizardStatus, setWizardStatus } = creditLimitContext;
  setWizardStatus({ ...wizardStatus, fetching: true, error: false });
  const [response, error] = await backToPreviousStepResolver({
    cardId,
    caseId: wizardStatus.caseId,
    currentStep: CreditLimitIncreaseStepsEnumeration.LABORAL_ECONOMIC_DATA,
  });

  if (error) {
    setWizardStatus({ ...wizardStatus, fetching: false, error: true });
    return;
  }

  if (response) {
    const { newCreditLimit, maxAmount, minAmount } = response.stepData;
    const currentStep = StepEnumToIndexMap[response.nextStep];
    const currentStepInText = response.nextStep as CreditLimitIncreaseStepsEnumeration;
    setStep1({
      ...step1,
      maxAmount: maxAmount as number,
      minAmount: minAmount as number,
      currentCreditLimit: newCreditLimit ?? step1.currentCreditLimit,
      newCreditLimit: newCreditLimit ?? step1.currentCreditLimit,
      nextDisabled: Boolean(
        !newCreditLimit || Number(newCreditLimit) < Number(Number(minAmount) + 500)
      ),
    });
    setStep2({ ...step2, personalData: {} });
    setWizardStatus({
      ...wizardStatus,
      fetching: false,
      error: false,
      currentStep,
      currentStepInText,
    });
    setCurrentStep(currentStep);
    handleClickPreviousButtonTracking();
  }
};

export const SetLaboralEconomicDataHandlers = ({
  cardId,
  creditLimitContext,
  setCurrentStep,
  handleClickPreviousButtonTracking,
}: SetLaboralEconomicPropsType): SetLaboralEconomicDataReturnType => ({
  handleSetLaboralEconomicData: (personalDataLocal: PersonalDataLocalType) =>
    setLaboralEconomicDataHandler({
      creditLimitContext,
      personalDataLocal,
      wizardStatus: creditLimitContext.wizardStatus,
      setCurrentStep,
      setWizardStatus: creditLimitContext.setWizardStatus,
    }),
  handleSetLaboralEconomicDataBack: () =>
    setLaboralEconomicDataBackHandler({
      cardId,
      creditLimitContext,
      handleClickPreviousButtonTracking,
      setCurrentStep,
    }),
});
