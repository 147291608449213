const DOMAIN = 'cobranded.services.userSettings.privateArea.cbChallengeModule.error.';

export default {
  ERROR: {
    DESCRIPTION: `${DOMAIN}description`,
    BUTTON: `${DOMAIN}button`,
  },
  SECURITY_ERROR: {
    DESCRIPTION: `${DOMAIN}security.description`,
    BUTTON: `${DOMAIN}button`,
  },
};
