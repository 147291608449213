// Services
import { fetch3DSecureCodeService } from '../services/card-settings-check-3d-wizard-step2.services';

const fetch3DSecureCodeResolver = async (
  progressId?: string
): Promise<[string] | [null, number]> => {
  const [response, { status }] = await fetch3DSecureCodeService(progressId);

  return response?.scaChallenge ? [response.scaChallenge] : [null, status];
};

export { fetch3DSecureCodeResolver };
