// Resolvers
import { switchTravelPlusStatusResolver } from 'components/travel-plus/resolvers/travel-plus.resolver';
// Translations
import { TOASTS } from './translations/travel-plus.handlers.translations';
// Types
import {
  TravelPlusBuilderHandlersType,
  TravelPlusMethodsHandlersType,
} from './types/travel-plus.handlers.type';
// Utilities
import { getToastMessage, setupToastConfiguration } from 'components/toast/utils/toast.utils';
import { getTravelPlusNextStatusLabel } from 'components/travel-plus/utils/travel-plus.utils';

const cancelShowTravelPlusLinkClickHandler = ({
  activated,
  handleCancelShowTravelPlusLinkClickTracking,
  onHide,
}: Pick<
  TravelPlusBuilderHandlersType,
  'activated' | 'handleCancelShowTravelPlusLinkClickTracking' | 'onHide'
>): void => {
  handleCancelShowTravelPlusLinkClickTracking();
  onHide(!!activated);
};

const closeTravelPlusAssetClickHandler = ({
  activated,
  handleCloseTravelPlusAssetClickTracking,
  onHide,
}: Pick<
  TravelPlusBuilderHandlersType,
  'activated' | 'handleCloseTravelPlusAssetClickTracking' | 'onHide'
>): void => {
  handleCloseTravelPlusAssetClickTracking();
  onHide(!!activated);
};

const confirmTravelPlusButtonClickHandler = async ({
  handleConfirmTravelPlusButtonClickTracking,
  activated,
  id,
  onHide,
  setCard,
  setFetching,
  setToastConfiguration,
}: Omit<
  TravelPlusBuilderHandlersType,
  'handleCloseTravelPlusAssetClickTracking' | 'handleCancelShowTravelPlusLinkClickTracking'
>): Promise<void> => {
  const travelPlus = !activated,
    translations = TOASTS[getTravelPlusNextStatusLabel(travelPlus)];

  setFetching(true);
  handleConfirmTravelPlusButtonClickTracking(travelPlus);

  const [, error] = await switchTravelPlusStatusResolver({ id, travelPlus });

  setCard(true);
  setToastConfiguration(
    setupToastConfiguration({ error, description: getToastMessage({ error, translations }) })
  );
  onHide(error ? !travelPlus : travelPlus);
  setFetching(false);
};

const TravelPlusHandlers = ({
  activated,
  handleCancelShowTravelPlusLinkClickTracking,
  handleCloseTravelPlusAssetClickTracking,
  onHide,
  ...rest
}: TravelPlusBuilderHandlersType): TravelPlusMethodsHandlersType => ({
  handleCancelShowTravelPlusLinkClick: () =>
    cancelShowTravelPlusLinkClickHandler({
      activated,
      handleCancelShowTravelPlusLinkClickTracking,
      onHide,
    }),
  handleCloseTravelPlusAssetClick: () =>
    closeTravelPlusAssetClickHandler({
      activated,
      handleCloseTravelPlusAssetClickTracking,
      onHide,
    }),
  handleConfirmTravelPlusButtonClick: () =>
    confirmTravelPlusButtonClickHandler({
      ...rest,
      activated,
      onHide,
    }),
});

export { TravelPlusHandlers };
