// Vendors
import React from 'react';
// Components
import { InputPrefixSuffixComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  ROW_TOGGLE_INPUT_PREFFIX_TEST_ID,
  DEFAULT_ROW_INPUT_PLACEHOLDER,
  DEFAULT_ROW_INPUT_SUFFIX,
  DEFAULT_TYPE_INPUT,
} from './constants/row-toggle-input-suffix-component.constants';
// Styles
import { RowToggleInputPreffixCurrencyContainerStyled } from '../../settings-row-toggle-input-currency.component.styled';
// Types
import { SettingsRowToggleInputCurrencyComponentPropsType } from '../../types/settings-row-toggle-input-currency-props.type';
// Utils
import {
  formatInputHelperMessage,
  getInputCurrencyErrorMessage,
} from '../../utils/settings-row-toggle-input-currency.utils';

const RowToggleInputPreffixCurrencyComponent = ({
  categoryId,
  controllerId,
  formatNumber,
  formatMessage,
  handleControllerChange,
  notificationSetting,
}: SettingsRowToggleInputCurrencyComponentPropsType): React.ReactElement | null => (
  <RowToggleInputPreffixCurrencyContainerStyled>
    <InputPrefixSuffixComponent
      type={DEFAULT_TYPE_INPUT}
      testId={ROW_TOGGLE_INPUT_PREFFIX_TEST_ID}
      value={notificationSetting.controllers[0].amount}
      disabled={!notificationSetting.enabled}
      suffix={DEFAULT_ROW_INPUT_SUFFIX}
      placeholder={DEFAULT_ROW_INPUT_PLACEHOLDER}
      helperMessage={formatInputHelperMessage({
        formatMessage,
        notificationSetting,
        formatNumber,
      })}
      errorMessage={getInputCurrencyErrorMessage({
        formatMessage,
        formatNumber,
        notificationSetting,
      })}
      onChange={value =>
        handleControllerChange({
          categoryId,
          controllerId,
          notificationId: notificationSetting.id,
          value,
        })
      }
    />
  </RowToggleInputPreffixCurrencyContainerStyled>
);

export { RowToggleInputPreffixCurrencyComponent };
