const DOMAIN =
  'cobranded.services.userSettings.privateArea.securityAndKeys.requestSignatureKey.step1.';

const TITLE = `${DOMAIN}title`,
  ADDRESS_BOX_TITLE = `${DOMAIN}addressBox.title`,
  TRUSTED_TITLE = `${DOMAIN}trustedTitle`,
  TRUSTED_DESCRIPTION = `${DOMAIN}trustedDescription`;

export {
  ADDRESS_BOX_TITLE,
  TITLE,
  TRUSTED_TITLE,
  TRUSTED_DESCRIPTION,
};
