// Vendors
import React from 'react';
// Components
import { SecurityKeysDevicesRouterComponent } from './components/router/security-keys-devices-router.component';
// Contexts
import { SignatureKeyDevicesContextConsumerHOC } from 'containers/security-keys/views/devices/contexts/signature-key-devices.context';
// Styles
import { LayoutGridComponentStyled } from 'styles/app.styled';

const SecurityKeysContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <SecurityKeysDevicesRouterComponent />
  </LayoutGridComponentStyled>
);

export { SecurityKeysContainer };
export default SignatureKeyDevicesContextConsumerHOC(SecurityKeysContainer);