// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { LoginHeaderBuilderReturnTrackingHandlersType } from './types/login-header-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const exitFromLoginViewTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'exitFromLoginView',
  },
  type: TrackingEventsEnum.LINK,
});

const LoginHeaderTrackingHandlers = (
  track: TrackBuilderType
): LoginHeaderBuilderReturnTrackingHandlersType => ({
  handleExitFromLoginViewTracking: () => track(exitFromLoginViewTrackingHandler()),
});

export default LoginHeaderTrackingHandlers;
