// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SignatureKeyChangeStep2TrackingHandlersType } from './types/security-keys-signature-key-change-second-step-handlers-tracking-return.type';

const passwordPositionInputClickFirstPasswordTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickTextBox',
    eventLabel: 'newSignatureKeyTextBox',
  },
  type: TrackingEventsEnum.LINK,
});

const passwordPositionInputClickSecondPasswordTrackingHandler =
  (): TrackingLinkEventReturnType => ({
    payload: {
      eventAction: 'clickTextBox',
      eventLabel: 'repeatSignatureKeyTextBox',
    },
    type: TrackingEventsEnum.LINK,
  });

const showHidePasswordClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'showSignatureKeyLink',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterSecondStepSignatureKeyChangeTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeSignatureKey/step02/newSignatureKey',
    sectionFamily: 'banking',
    sectionCategory: 'products',
    sectionName: 'cards',
    interactionType: 'page',
    serviceType: 'productSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SecurityKeysSignatureKeyChangeSecondStepTrackingHandlers = (
  track: TrackBuilderType
): SignatureKeyChangeStep2TrackingHandlersType => ({
  handleFirstPasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickFirstPasswordTrackingHandler()),
  handleSecondPasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickSecondPasswordTrackingHandler()),
  handleOnEnterSecondStepSignatureKeyChangeTrackingHandler: () =>
    track(onEnterSecondStepSignatureKeyChangeTrackingHandler()),
  handleShowHidePasswordClickTracking: () => track(showHidePasswordClickTrackingHandler()),
});

export default SecurityKeysSignatureKeyChangeSecondStepTrackingHandlers;
