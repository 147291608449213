const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.cbPPI.contracting.step2.termsAndConditions.';

const DOWNLOADING = `${DOMAIN}downloadDocumentLink`,
  DOWNLOAD = `${DOMAIN}downloadDocumentLink`,
  ERROR = `${DOMAIN}retryDownloadLink`,
  ERROR_DESCRIPTION = `${DOMAIN}errorDownloadLabel`,
  ERROR_MESSAGE = `${DOMAIN}cbToast.errorDescription`,
  SUCCESS_MESSAGE = `${DOMAIN}cbToast.successDescription`;

export { DOWNLOADING, DOWNLOAD, ERROR, ERROR_DESCRIPTION, ERROR_MESSAGE, SUCCESS_MESSAGE };
