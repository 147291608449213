const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.change3DSecure.cbChange3DSecure.cbWizard.step1.';

const DESCRIPTION = `${DOMAIN}description`,
  HIDE_PIN = `${DOMAIN}hidePin`,
  MESSAGE_DESCRIPTION = `${DOMAIN}cbMessage.description`,
  SHOW_PIN = `${DOMAIN}showPin`,
  TITLE = `${DOMAIN}title`;

export { DESCRIPTION, HIDE_PIN, MESSAGE_DESCRIPTION, SHOW_PIN, TITLE };
