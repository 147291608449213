// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LinkComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_DISPLAY_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_LINK_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_PROPS,
} from './constants/card-settings-check-3d-confirmation-security-module-description.constants';
// Hooks
import useCardSettingsCheck3dConfirmationSecurityModuleHook from './hooks/card-settings-check-3d-confirmation-security-module-description.hook';
// Translations
import {
  EXPIRED,
  WILL_BE_VISIBLE,
} from './translations/card-settings-check-3d-confirmation-security-module.translations';

const CardSettingsCheck3dConfirmationSecurityModuleDescriptionComponent =
  (): React.ReactElement => {
    const { handleMakeANewQueryLinkClick, showAllowance, seconds } =
      useCardSettingsCheck3dConfirmationSecurityModuleHook();

    return showAllowance ? (
      <ParagraphComponent
        {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_DISPLAY_PROPS}
      >
        <FormattedMessageComponent id={WILL_BE_VISIBLE} values={{ seconds }} />
      </ParagraphComponent>
    ) : (
      <ParagraphComponent
        {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_PROPS}
      >
        <FormattedMessageComponent
          id={EXPIRED}
          values={{
            link: (chunks: React.ReactNode) => (
              <LinkComponent
                {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_SECURITY_MODULE_DESCRIPTION_QUERY_LINK_PROPS}
                onClick={handleMakeANewQueryLinkClick}
              >
                {chunks}
              </LinkComponent>
            ),
          }}
        />
      </ParagraphComponent>
    );
  };

export default CardSettingsCheck3dConfirmationSecurityModuleDescriptionComponent;
