// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery, tabletMediaQuery, convertPxsToRems } from '@openbank/cf-ui-framework';

const TransactionsTableLinkComponentStyled = styled.footer<{ innerPadding?: boolean }>`
  width: 100%;
  ${({ innerPadding }) =>
    innerPadding &&
    css`
      padding: 0 ${({ theme }) => theme.spaces.spacingXxxxxl};

      ${tabletMediaQuery} {
        padding: 0 ${({ theme }) => theme.spaces.spacingXl};
      }

      ${mobileMediaQuery} {
        padding: 0 ${({ theme }) => theme.spaces.spacingS};
      }
    `};
`;
TransactionsTableLinkComponentStyled.displayName = 'TransactionsTableLinkComponentStyled';

const TransactionsTableLinkContentComponentStyled = styled.footer<{ innerPadding?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-top: ${({ theme }) => theme.spaces.spacingXxs};
  text-align: right;
  width: 100%;
`;
TransactionsTableLinkContentComponentStyled.displayName =
  'TransactionsTableLinkContentComponentStyled';

export { TransactionsTableLinkComponentStyled, TransactionsTableLinkContentComponentStyled };
