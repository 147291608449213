// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

const TABLE_ROW_ASSET_DATE_ASSET_PROPS = {
  sizes: {
    height: 32,
    width: 32,
  },
  testId: 'table-row-asset-date-asset',
};
const TABLE_ROW_ASSET_DATE_ASSET_DESCRIPTION_PROPS = {
  size: paragraphConstants.SIZES.XS,
  testId: 'table-row-asset-date-description',
};

export { TABLE_ROW_ASSET_DATE_ASSET_PROPS, TABLE_ROW_ASSET_DATE_ASSET_DESCRIPTION_PROPS };
