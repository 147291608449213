// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Translations
import {
  TOGGLE_ACCEPTED_DESCRIPTION,
  TOGGLE_REJECTED_DESCRIPTION,
} from '../translations/consent-checker.component.translations';

const TOGGLE_PROPS = {
  labelOff: <FormattedMessageComponent id={TOGGLE_REJECTED_DESCRIPTION} />,
  labelOn: <FormattedMessageComponent id={TOGGLE_ACCEPTED_DESCRIPTION} />,
  testId: 'toggle-consent-test-id',
  tristate: true,
};

const TOGGLE_DESCRIPTION_PROPS = {
  tag: 'h7',
};

export { TOGGLE_DESCRIPTION_PROPS, TOGGLE_PROPS };
