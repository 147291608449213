// Vendors
import { ChatApi, ChatMessageTypesEnum } from '@openbank/cb-ui-commons';
// Types
import { ChatMessage, GetMessagesReturn } from '../types/chat.services.type';

const getMessageType = (message: ChatMessage): ChatMessageTypesEnum => {
  if (message.type === 'ParticipantJoined') {
    return ChatMessageTypesEnum.SYSTEM;
  }

  return message.from.type;
};

export const createChatResponseFromApi = (
  response: GetMessagesReturn
): Awaited<ReturnType<ChatApi['getChatMessages']>> => ({
  chatId: response.chatId,
  chatEnded: response.chatEnded,
  messages: response.messages.map(m => ({
    author: m.from.nickname,
    chatId: response.chatId,
    position: m.index,
    text: m.text,
    time: new Date(m.time),
    type: getMessageType(m),
  })),
  nextMessage: response.nextPosition,
});
