const DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION =
  'cobranded.services.userSettings.privateArea.cardSettings.depositMoney.information.';

export default {
  FIRST_TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}first.title`,
  FIRST_CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}first.content`,
  FIRST_SECONDARY_CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}first.secondary.content`,
  SECOND_TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}second.title`,
  SECOND_CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}second.content`,
  THIRD_TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}third.title`,
  THIRD_CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}third.content`,
  FOURTH_CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}fourth.content`,
  FIFTH_CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_INFORMATION}fifth.content`,
};
