// Enumerations
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';
// Types
import { PersonalPhotoAvatarsBuilderReturnHandlersType } from './types/personal-photo-avatars-builder-return.handlers.type';
import { PersonalPhotoAvatarsBuilderHandlersType } from './types/personal-photo-avatars-builder.handlers.type';
import { PersonalPhotoAvatarsMethodBuilderHandlersType } from './types/personal-photo-avatars-method-builder.handlers.type';

const selectNewAvatarAssetClickHandler = ({
  handleSelectNewAvatarAssetClickTracking,
  id,
  setAvatar,
  setPhoto,
}: PersonalPhotoAvatarsMethodBuilderHandlersType): any => {
  handleSelectNewAvatarAssetClickTracking(id);
  setPhoto('');
  setAvatar(id);
};

const PersonalPhotoAvatarsHandlers = (
  props: PersonalPhotoAvatarsBuilderHandlersType
): PersonalPhotoAvatarsBuilderReturnHandlersType => ({
  handleSelectNewAvatarAssetClick: (id: AvatarIdsEnumeration) =>
    selectNewAvatarAssetClickHandler({ ...props, id }),
});

export default PersonalPhotoAvatarsHandlers;
