const EVENT_ACTION = 'cannotProceedInsuranceContracting';

const OPEN_CONTRACT_MODAL_EVENT_LABEL = 'open',
  CLOSE_CONTRACT_MODAL_EVENT_LABEL = 'close',
  UNDERSTOOD_CONTRACT_MODAL_EVENT_LABEL = 'modalNavigationUnderstoodButton';

export {
  EVENT_ACTION,
  OPEN_CONTRACT_MODAL_EVENT_LABEL,
  CLOSE_CONTRACT_MODAL_EVENT_LABEL,
  UNDERSTOOD_CONTRACT_MODAL_EVENT_LABEL,
};
