// Enums
import { AccessPortabilityStatusEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Types
import {
  AccessPortabilityComponentContentType,
  AccessPortabilityComponentType,
} from 'containers/personal/views/access-portability/types/access-portability.component.type';

const isThereAPendingRequest = (
  information: AccessPortabilityComponentType | Record<string, any>
): boolean => {
  const havePendingRequest = (requests: AccessPortabilityComponentContentType) =>
    requests.status === AccessPortabilityStatusEnumeration.REQUESTED;
  return information?.content?.some(havePendingRequest);
};

export { isThereAPendingRequest };
