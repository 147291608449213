// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PARAGRAPH_PROPS_TITLE,
  PARAGRAPH_PROPS_VALUE,
  SIGN_D,
} from './constants/fees-component.constants';
import { EURO_FORMAT_OPTIONS } from 'constants/currency.constants';
// Utils
import { getDescriptionId } from './utils/fees.utils';
// Styles
import { AmountDetailsModalRowDescriptionComponentStyled } from '../../amount-details-modal.component.styled';
// Types
import { CardTransactionDetailedAmountType } from 'types/card-transactions-detailed-amount.type';
import { CardTransactionDetailedAmountFeesType } from 'types/card-transactions-detailed-amount-fees.type';

export const FeesComponent = ({
  fees = [],
}: Pick<CardTransactionDetailedAmountType, 'fees'>): React.ReactElement | null => (
  <>
    {fees.map((fee: CardTransactionDetailedAmountFeesType, key: number) => {
      const { amount, sign, description } = fee;
      return (
        <AmountDetailsModalRowDescriptionComponentStyled key={key}>
          <ParagraphComponent {...PARAGRAPH_PROPS_TITLE}>
            <FormattedMessageComponent id={getDescriptionId(description)} />
          </ParagraphComponent>
          <ParagraphComponent {...PARAGRAPH_PROPS_VALUE}>
            <FormattedNumber {...EURO_FORMAT_OPTIONS} value={sign === SIGN_D ? amount : -amount} />
          </ParagraphComponent>
        </AmountDetailsModalRowDescriptionComponentStyled>
      );
    })}
  </>
);
