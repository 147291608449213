// Vendors
import { useEffect, useState } from 'react';
// Handlers
import VerifyingDetailsCreditLimitIncreaseComponentHandlers from '../handlers/verifying-details-credit-limit-increase.component.handler';

const VerifyingDetailsCreditLimitIncreaseComponentHook = (): { currentStep: number } => {
  const [currentStep, setCurrentStep] = useState(0);
  const { handleIncrementStep } = VerifyingDetailsCreditLimitIncreaseComponentHandlers({
    currentStep,
    setCurrentStep,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleIncrementStep, [currentStep]);

  return {
    currentStep,
  };
};

export default VerifyingDetailsCreditLimitIncreaseComponentHook;
