// Constants
import {
  REPLACEMENT_ERROR_DESCRIPTION_FIELD,
  REPLACEMENT_ERROR_DESCRIPTION,
} from './constants/block-card.utils.constants';
// Types
import { isReplacementErrorType } from './types/is-replacement-error.type';

const isReplacementError = (response: isReplacementErrorType): boolean =>
  Boolean(
    response &&
      REPLACEMENT_ERROR_DESCRIPTION_FIELD in response &&
      response[REPLACEMENT_ERROR_DESCRIPTION_FIELD] === REPLACEMENT_ERROR_DESCRIPTION
  );

export { isReplacementError };
