// Services
import {
  getCategoriesService,
  getDocumentInformationService,
} from '../services/documentation.services';
// Types
import { type DocumentationApiReturnType } from '../types/documentation-api-return.type';
import { type CategoriesApiReturnType } from '../types/categories-api-return.type';
// Utilities
import { wasSuccessRequest, requestHasSuccessStatus } from '@openbank/cf-ui-framework';
import {
  isCategoryTypeResponse,
  isDocumentTypeResponse,
} from './utils/documentation.resolvers.utils';

export const getDocumentsResolver = async (
  categorySelected: string | null,
  formValuesParams: Record<string, any>
): Promise<[DocumentationApiReturnType] | [null, true]> => {
  const [response, { status }] = await getDocumentInformationService(
    categorySelected,
    formValuesParams
  );

  return wasSuccessRequest(status) && isDocumentTypeResponse(response) ? [response] : [null, true];
};

export const getCategoriesResolver = async (): Promise<
  [CategoriesApiReturnType] | [null, true]
> => {
  const [response, { status }] = await getCategoriesService();

  return requestHasSuccessStatus(status) && isCategoryTypeResponse(response)
    ? [response]
    : [null, true];
};
