// Constants
import { FILES_INITIAL_STATE } from 'containers/transactions/views/report/components/not-received/contexts/constants/transactions-report-not-received.context.constants';
import { NUMBER } from 'constants/number.constants';
// Types
import { TransactionsReportServiceCancelledDocumentsActionsHandlersPropsType } from './types/transactions-report-service-cancelled-documents-actions-handlers-props.type';
import { TransactionsReportServiceCancelledDocumentsActionsHandlersReturnType } from './types/transactions-report-service-cancelled-documents-actions-handlers-return.type';

const goBackHandler = ({
  setCurrentStep,
  setFiles,
}: TransactionsReportServiceCancelledDocumentsActionsHandlersPropsType) => {
  setCurrentStep(NUMBER.ONE);
  setFiles(FILES_INITIAL_STATE);
};

const TransactionsReportServiceCancelledDocumentsActionsHandlers = ({
  setFiles,
  setCurrentStep,
}: TransactionsReportServiceCancelledDocumentsActionsHandlersPropsType): TransactionsReportServiceCancelledDocumentsActionsHandlersReturnType => ({
  handleGoBack: () => goBackHandler({ setCurrentStep, setFiles }),
});

export { TransactionsReportServiceCancelledDocumentsActionsHandlers };
