const CLICK_DROPDOWN_TRACKING = 'clickDropdown';
const CLICK_INPUT_TRACKING = 'clickInput';
const NEW_PHONE_NUMBER_TRACKING = 'phoneNumber';
const PREFIX_TRACKING = 'prefix:';
const INVALID_PHONE_NUMBER_TRACKING = 'invalidPhoneNumber';

export {
  CLICK_DROPDOWN_TRACKING,
  CLICK_INPUT_TRACKING,
  NEW_PHONE_NUMBER_TRACKING,
  PREFIX_TRACKING,
  INVALID_PHONE_NUMBER_TRACKING,
};
