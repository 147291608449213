// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TransactionsTableHeaderLinkBarBuilderReturnTrackingHandlersType } from './types/transactions-table-header-link-bar-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const downloadTransactionsLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'downloadTransactions',
  },
  type: TrackingEventsEnum.LINK,
});

const filterTransactionsLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'filterTransactions',
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsTableHeaderLinkBarTrackingHandlers = (
  track: TrackBuilderType
): TransactionsTableHeaderLinkBarBuilderReturnTrackingHandlersType => ({
  handleDownloadTransactionsLinkClickTracking: () =>
    track(downloadTransactionsLinkClickTrackingHandler()),
  handleFilterTransactionsLinkClickTracking: () =>
    track(filterTransactionsLinkClickTrackingHandler()),
});

export default TransactionsTableHeaderLinkBarTrackingHandlers;
