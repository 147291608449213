// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { AccessPortabilityContextConsumerHook } from 'containers/personal/views/access-portability/contexts/access-portability.context';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { RequestAccessHookType } from './types/access-portability-request.hook.type';
import RequestAccessHandlers from '../handlers/request-access.handlers';
import AccessPortabilityRequestTrackingHandlers from '../handlers/access-portability-request.tracking.handlers';
import { MobileConnectorContextConsumerHook } from 'providers/mobile-connector/contexts/mobile-connector.provider.context';

const RequestAccessHook = (): RequestAccessHookType => {
  const { profile } = AppContextConsumerHook(),
    { email } = profile?.contactDetails || {};

  const { isHybrid } = MobileConnectorContextConsumerHook();

  return {
    ...RequestAccessHandlers({
      ...AppTrackingHook(AccessPortabilityRequestTrackingHandlers),
    }),
    email,
    isHybrid,
  };
};

export default RequestAccessHook;
