// Components
import SecurityKeysSignatureKeyChangeFirstStep from '../components/first-step/signature-key-change-first-step.component';
import SecurityKeysSignatureKeyChangeSecondStep from '../components/second-step/security-keys-signature-key-change-second-step.component';
import SecurityKeysSignatureKeyChangeThirdStep from '../components/third-step/security-keys-signature-key-change-third-step.component';
// Translations
import {
  FAILED,
  SUCCESS,
} from '../translations/security-keys-signature-key-change-step.configuration.translations';
import translations from '../translations/security-keys-signature-key-change.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import { getWizardPropsType } from './types/security-keys-signature-key-change.configurations.type';
import { getWizardPropsReturnType } from './types/security-keys-signature-key-change.configurations.return.type';

export const getWizardProps = ({ fromPersonal }: getWizardPropsType): getWizardPropsReturnType => ({
  feedbackConfiguration: {
    errorConfiguration: {
      description: FAILED.CONFIRMATION.CONTENT,
      title: FAILED.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration.SECURITY,
    },
    successConfiguration: {
      description: SUCCESS.CONFIRMATION.CONTENT,
      title: SUCCESS.CONFIRMATION.TITLE,
      exitRoute: RoutesEnumeration[fromPersonal ? 'PERSONAL_INFORMATION' : 'SECURITY'],
      linkLabel: SUCCESS[fromPersonal ? 'PERSONAL_BACK_BUTTTON' : 'BACK_BUTTTON'],
    },
  },
  steps: [
    {
      component: SecurityKeysSignatureKeyChangeFirstStep,
      title: translations.STEP_1,
    },
    {
      component: SecurityKeysSignatureKeyChangeSecondStep,
      title: translations.STEP_2,
    },
    {
      component: SecurityKeysSignatureKeyChangeThirdStep,
      title: translations.STEP_3,
    },
  ],
});
