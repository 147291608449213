// Vendors
import React from 'react';
// Components
import SettingsCardControlLocationsMapLegendItemComponent from './components/item/settings-card-control-locations-map-legend-item.component';
// Constants
import constants from './constants/setting-card-control-locations-map.constants';
// Styles
import { SettingsCardControlLocationsMapComponentStyled } from './settings-card-control-locations-map-legend.component.styled';

const SettingsCardControlLocationsMapLegendComponent = (): React.ReactElement | null => (
  <SettingsCardControlLocationsMapComponentStyled>
    <SettingsCardControlLocationsMapLegendItemComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_ON_PROPS}
    />
    <SettingsCardControlLocationsMapLegendItemComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_PARTIALLY_ON_PROPS}
    />
    <SettingsCardControlLocationsMapLegendItemComponent
      {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_MAP_LEGEND_OFF_PROPS}
    />
  </SettingsCardControlLocationsMapComponentStyled>
);

export default SettingsCardControlLocationsMapLegendComponent;
