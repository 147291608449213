const ASSET_TIPS = {
  SIZE_TIP: {
    assetConfiguration: {
      id: 'see',
      type: 'icon',
      size: 32,
      fill: { default: 'backgroundInfoA', inverted: 'backgroundInfoC' },
    },
  },
  FORMAT_TIP: {
    assetConfiguration: {
      id: 'document',
      size: 32,
      fill: { default: 'backgroundInfoA', inverted: 'backgroundInfoC' },
    },
  },
  IMAGE_TIP: {
    assetConfiguration: {
      id: 'id-card',
      size: 32,
      fill: { default: 'backgroundInfoA', inverted: 'backgroundInfoC' },
    },
  },
} as const;

const MESSAGE_COMPONENT_PROPS = {
  type: 'informative',
};

const PARAGRAPHS_L_SIZE = 'L';

export { ASSET_TIPS, MESSAGE_COMPONENT_PROPS, PARAGRAPHS_L_SIZE };
