// Configurations
import {
  getLinkTypeTrackingConfiguration,
  getViewTypeTrackingConfiguration,
} from 'containers/financing/views/credit-limit-increase/trackings/configurations/credit-limit-increase-tracking.configurations';
import {
  getShowInvalidFormatErrorConfiguration,
  getShowOversizedErrorConfiguration,
  getShowUploadErrorConfiguration,
} from './configurations/step-4-upload-documentation.tracking.configuration';
// Constants
import { step4UploadDocumentationConstants } from './constants/step-4-upload-documentation.tracking.constants';
// Types
import { Step4UploadDocumentationTrackingType } from './types/step-4-upload-documentation.tracking.type';

export const Step4UploadDocumentationTracking = (
  track: (param: unknown) => void
): Step4UploadDocumentationTrackingType => ({
  handleEnterViewTracking: () =>
    track(getViewTypeTrackingConfiguration(step4UploadDocumentationConstants.VIEW.STEP_4_UPLOAD_DOCUMENTATION)),
  handleClickThisExampleDocumentTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_THIS_EXAMPLE_DOCUMENT)),
  handleClickChooseFileTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_CHOOSE_FILE)),
  handleClickCancelUploadTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_CANCEL_UPLOAD)),
  handleClickRemoveUploadedFileTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_REMOVE_UPLOADED_FILE)),
  handleClickRetryUploadTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_RETRY_UPLOAD)),
  handleShowUploadErrorTracking: (id: string) =>
    track(getLinkTypeTrackingConfiguration(getShowUploadErrorConfiguration(id))),
  handleShowInvalidFormatErrorTracking: (id: string) =>
    track(getLinkTypeTrackingConfiguration(getShowInvalidFormatErrorConfiguration(id))),
  handleShowOversizedErrorTracking: (id: string) =>
    track(getLinkTypeTrackingConfiguration(getShowOversizedErrorConfiguration(id))),
  handleClickGoBackToPreviousStepLinkTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_GO_BACK_TO_PREVIOUS_STEP_LINK)),
  handleClickCancelLinkTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_CANCEL_LINK)),
  handleClickUploadDocumentationButtonTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_UPLOAD_DOCUMENTATION_BUTTON)),
  handleOpenItIsNotPossibleModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.OPEN_IT_IS_NOT_POSSIBLE_MODAL)),
  handleCloseItIsNotPossibleModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLOSE_IT_IS_NOT_POSSIBLE_MODAL)),
  handleClickButtonItIsNotPossibleModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_BUTTON_IT_IS_NOT_POSSIBLE_MODAL)),
  handleOpenTipsModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.OPEN_TIPS_MODAL)),
  handleCloseTipsModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLOSE_TIPS_MODAL)),
  handleClickButtonTipsModalTracking: () =>
    track(getLinkTypeTrackingConfiguration(step4UploadDocumentationConstants.LINK.CLICK_BUTTON_TIPS_MODAL)),
});
