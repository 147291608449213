// Types
import { UserContactDetailsTelephoneType } from 'types/user-contact-details-telephone.type';
import {
  GetPrimaryConfigurableTelephoneTypes,
  GetWizardErrorConfigurationReturnType,
} from './types/personal-number-data-contact.hook.types.utils';
// Translations
import {
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  ERROR_DESCRIPTION_2,
  ERROR_TITLE_2,
} from '../../translations/personal-number.translations';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

// eslint-disable-next-line complexity
const getPrimaryTelephone = (
  telephones: UserContactDetailsTelephoneType[]
): GetPrimaryConfigurableTelephoneTypes => {
  const primaryPhone =
    telephones?.find(phone => phone.isPrimaryTelephone && phone.canUpdatePhone) ||
    telephones?.find(phone => phone.isPrimaryTelephone && !phone.canUpdatePhone) ||
    null;

  return {
    primaryConfigurablePhone: primaryPhone?.fullTelephoneNumber ?? undefined,
    primaryConfigurablePrefix: primaryPhone?.countryCode ?? undefined,
    primaryConfigurablePhoneId: primaryPhone?.id ?? undefined,
    canUpdatePhone: primaryPhone?.canUpdatePhone ?? false,
  };
};

const getWizardErrorMessageConfiguration = (
  alternativeError: boolean
): GetWizardErrorConfigurationReturnType => {
  const title = alternativeError ? ERROR_TITLE_2 : ERROR_TITLE;
  const description = alternativeError ? ERROR_DESCRIPTION_2 : ERROR_DESCRIPTION;

  return { title, description, exitRoute: RoutesEnumeration.PERSONAL_INFORMATION };
};

export { getPrimaryTelephone, getWizardErrorMessageConfiguration };
