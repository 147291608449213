export enum QuickActionsSectionIdsEnumeration {
  STATEMENTS = 'statements',
  GET_CVV = 'getCVV',
  GET_PIN = 'getPIN',
  REQUEST_ADDITIONAL_CREDIT = 'requestAdditionalCredit',
  DECREASE_CREDIT_LIMIT = 'decreaseCreditLimit',
  DEPOSIT_MONEY_EXTERNAL = 'depositMoneyExternal',
  FAST_CASH = 'fastCash',
  PPI = 'PPI',
  UPDATE_PAYMENT_METHOD = 'updatePaymentMethod',
  MODIFY_PIN = 'modifyPin',
  CARD_CONTROL = 'cardControl',
  AUTOMATIC_RENEWAL = 'automaticRenewal',
  VIEW_DENIED_MOVEMENTS = 'viewDeniedMovements',
  RESET_BLOCK_PIN = 'resetBlockPIN',
  ALL_OPTIONS = 'allOptions',
}
