// Vendors
import { SetStateType } from '@openbank/cf-ui-static-data';
// Handlers
import { TransactionsReportPaymentDocumentsActionsHandlers } from '../handlers/transactions-report-payment-upload-documents-actions.handlers';
// Hooks
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Types
import { FileDataType } from 'types/file-data.type';
import { UsePaymentUploadDocumentsActionsHookReturnType } from './types/transactions-report-payment-upload-documents-actions.type';

const usePaymentUploadDocumentsActionsHook = (
  setFiles: SetStateType<FileDataType[]>
): UsePaymentUploadDocumentsActionsHookReturnType => {
  const { setCurrentStep } = useWizardContextConsumerHook();
  const { handleGoBack } = TransactionsReportPaymentDocumentsActionsHandlers({
    setFiles,
    setCurrentStep,
  });

  return {
    handleGoBack,
  };
};

export { usePaymentUploadDocumentsActionsHook };
