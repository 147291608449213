// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  GET_UPLOAD_DOCUMENT_URL_PROPS,
  REJECT_DOCUMENT_PROPS,
} from './constants/upload-documents.services.constants';
// Types
import { GetUploadDocumentUrlServicePropsType } from './types/get-upload-document-url-props.service.type';
import { GetUploadDocumentUrlServiceResponseType } from './types/get-upload-document-url-response.service.type';
import { RejectDocumentServicePropsType } from './types/reject-document-props.service.type';
import { UploadDocumentServicePropsType } from './types/upload-docs-props.service.type';

const getUploadDocumentUrlService = async (
  props: GetUploadDocumentUrlServicePropsType
): Promise<[Record<string, any> | GetUploadDocumentUrlServiceResponseType, Response]> =>
  apiUtilsCb.post({
    ...GET_UPLOAD_DOCUMENT_URL_PROPS,
    params: { ...props.params },
    signal: props.signal,
  });

const uploadDocumentService = async ({
  customerId,
  documentUri,
  file,
  signal,
}: UploadDocumentServicePropsType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.put({
    body: file,
    absoluteUrl: documentUri,
    customHeaders: {
      'Content-Type': file?.type ?? '',
      'x-amz-meta-customers': customerId,
    },
    hasCommonHeaders: false,
    signal,
  });

const rejectDocumentService = async ({
  documentId,
}: RejectDocumentServicePropsType): Promise<[Record<string, any>, Response]> =>
  apiUtilsCb.patch({
    ...REJECT_DOCUMENT_PROPS,
    pathParams: { documentId },
  });

export { getUploadDocumentUrlService, uploadDocumentService, rejectDocumentService };
