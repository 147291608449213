export enum HybridFlowIds {
  ACCOUNT_DETAILS = 'accountDetails',
  ACCOUNT_MOVEMENTS = 'accountMovements',
  AGGREGATOR = 'aggregator',
  CARD_ASSOCIATE_ACCOUNTS = 'cardAssociateAccounts',
  CARD_CONTROL = 'cardControl',
  CARD_INFORMATION = 'cardInformation',
  CARD_MODIFY = 'cardModify',
  CARD_MODIFY_ATM_LIMITS = 'cardModifyATMLimits',
  CARD_MOVEMENTS = 'cardMovements',
  CREATE_NEW_SIGNATURE_KEY_VU = 'createNewSignatureKeyVu',
  DOCUMENTATION = 'documentation',
  GLOBAL_POSITION = 'globalPosition',
  INSURANCES_LANDING = 'insurancesLanding',
  INTERNAL_TRANSFER = 'internalTransfer',
  LANDING_ACCOUNTS = 'landingAccounts',
  LANDING_CARDS = 'landingCards',
  LANDING_DEPOSITS = 'landingDeposits',
  LANDING_LOANS = 'landingLoans',
  LANDING_TRANSFERS = 'landingTransfers',
  LOGOUT = 'logout',
  MODIFY_ALIAS = 'modifyAlias',
  MORTGAGE_PANEL = 'mortgagePanel',
  ONBOARDING_FINISHED = 'onboardingFinished',
  ONBOARDING_LANDING = 'onboardingLanding',
  PERSONAL_DATA = 'personalData',
  PERSONAL_DATA_PHONE_MODIFICATION = 'personalData-phoneModification',
  PROMOTIONS_DISCOUNTS = 'promotionsDiscounts',
  ROBO_LANDING = 'robo',
  SECURITY_LANDINGS = 'securityLandings',
  UPDATE_EMAIL = 'updateEmail',
  UPDATE_PHONE = 'updatePhone',
  WEALTH_LANDING = 'wealth',
}
