// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ButtonComponent, FeatureFlagManager } from '@openbank/cb-ui-commons';
import { TransactionsLandingRowDetailsCreatePlanModalComponent } from './components/modal/transactions-landing-row-details-create-plan-modal.component';
// Constants
import { TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_BUTTON_PROPS } from './constants/transactions-landing-row-details-create-plan.constants';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Hooks
import { useTransactionsLandingRowDetailsCreatePlanHook } from './hooks/transactions-landing-row-details-create-plan.hook';
// Translations
import { CREATE_INSTALLMENT_PLAN } from './translations/transactions-landing-row-details-create-plan.translations';
// Types
import { TransactionsLandingRowDetailsCreatePlanComponentType } from './types/transactions-landing-row-details-create-plan.component.type';
// Utilities
import { showCreateNewInstallmentsPlan } from './utils/transactions-landing-row-details-create-plan.utils';
import { getFeatureFlagInstance } from 'providers/feature-flags/utils/feature-flags.utils';

const TransactionsLandingRowDetailsCreatePlanComponent = ({
  eppStatusCode,
  id,
  ...props
}: TransactionsLandingRowDetailsCreatePlanComponentType): React.ReactElement | null => {
  const {
    handleCreateInstallmentsPlanButtonClick,
    handleTogglePointsEppModalVisibilityEvent: onClose,
    showPointsModal: show,
  } = useTransactionsLandingRowDetailsCreatePlanHook({ ...props, id });

  return showCreateNewInstallmentsPlan(eppStatusCode) ? (
    <FeatureFlagManager flags={getFeatureFlagInstance()} flag={FeatureFlagsEnumeration.CREATE_EPP}>
      <ButtonComponent
        {...TRANSACTIONS_LANDING_ROW_DETAILS_CREATE_PLAN_BUTTON_PROPS}
        onClick={handleCreateInstallmentsPlanButtonClick}
      >
        <FormattedMessageComponent id={CREATE_INSTALLMENT_PLAN} />
      </ButtonComponent>
      <TransactionsLandingRowDetailsCreatePlanModalComponent {...{ id, onClose, show }} />
    </FeatureFlagManager>
  ) : null;
};

export default TransactionsLandingRowDetailsCreatePlanComponent;
