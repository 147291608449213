// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { titleConstants } from '@openbank/cb-ui-commons';
// Translations
import { WORKING_IN_PROGRESS_DEFAULT_TITLE } from 'translations/working-in-progress.translations';
import { ERROR_DESCRIPTION } from '../translations/recovery-key-step4-security-questions.translations';

const RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_ERROR_PROPS = {
    assetConfiguration: {
      id: 'Working_on_it_Alternative',
      sizes: { height: 110, width: 110 },
      testId: 'recovery-key-step-4-security-questions-error_asset',
      type: 'illustration',
    },
    description: <FormattedMessageComponent id={ERROR_DESCRIPTION} />,
    testing: {
      titleId: 'recovery-key-step-4-security-questions-error-title',
      descriptionId: 'recovery-key-step-4-security-questions-error-description',
    },
    title: <FormattedMessageComponent id={WORKING_IN_PROGRESS_DEFAULT_TITLE} />,
  },
  RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_TITLE_PROPS = {
    tag: titleConstants.TAGS.H4,
    testId: 'recovery-key-step-4-security-questions-title',
  };

export {
  RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_ERROR_PROPS,
  RECOVERY_KEY_STEP_4_SECURITY_QUESTIONS_TITLE_PROPS,
};
