// Vendors
import { paragraphConstants } from '@openbank/cb-ui-commons';
// Constants
import operationsConstants from 'containers/card-settings/views/card-control/components/operations/constants/settings-card-control-operations.constants';
// Enumerations
import { CardControlIdsEnumeration } from 'containers/card-settings/views/card-control/enumerations/card-control-ids.enumeration';
// Translations
import toastTranslations from '../translations/settings-card-control-operations-shops-toast.translations';
import translations from '../translations/settings-card-control-operations-shops.translations';

export default {
  SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ASSET_PROPS: {
    ...operationsConstants.SETTINGS_CARD_CONTROL_OPERATIONS_COMMON_ASSET_PROPS,
    id: 'cart',
    testId: 'settings-card-control-operations-shops-asset',
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_TITLE_PROPS: {
    bold: true,
    size: paragraphConstants.SIZES.M,
    testId: 'settings-card-control-operations-shops-title',
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ROW_PHYSICAL_PROPS: {
    description: translations.PHYSICAL_DESCRIPTION,
    id: CardControlIdsEnumeration.PHYSICAL_STORES,
    title: translations.PHYSICAL_TITLE,
    translations: toastTranslations[CardControlIdsEnumeration.PHYSICAL_STORES],
  },
  SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ROW_ONLINE_PROPS: {
    description: translations.ONLINE_DESCRIPTION,
    id: CardControlIdsEnumeration.ONLINE_STORES,
    title: translations.ONLINE_TITLE,
    translations: toastTranslations[CardControlIdsEnumeration.ONLINE_STORES],
  },
};
