// Vendors
import React from 'react';
// Components
import { TransactionsEppStep1OrchestratorComponent } from './components/orchestrator/transactions-epp-step1-orchestrator.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FIRST_STEP_TARGETS } from 'constants/wizard/wizard.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Hooks
import { useTransactionsEppStep1Hook } from './hooks/transactions-epp-step1.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Utils
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const TransactionsEppStep1Component = (): React.ReactElement => {
  const { cardEnabled, disabled, isCreditInsufficient, navigate } = useTransactionsEppStep1Hook();

  return (
    <LayoutColumnFlexComponentStyled>
      <TransactionsEppStep1OrchestratorComponent {...{ cardEnabled, isCreditInsufficient }} />
      <WizardStepFooterComponent
        rightButtonConfiguration={{ disabled }}
        leftLinkConfiguration={{
          onClick: () => {
            navigate(generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS));
          },
        }}
        targets={FIRST_STEP_TARGETS}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { TransactionsEppStep1Component };
