// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { usePersonalAddressContextConsumerHook } from 'containers/personal/views/address/contexts/personal-address.context';
import { ManagerContextConsumerHook } from 'contexts/manager.context';
// Handlers
import { PersonalAddressStep2ActionsHandlers } from '../handlers/personal-address-step2-actions.handlers';
// Hooks
import { useAddressHook } from 'hooks/address.hook';
// Types
import { PersonalAddressStep2ActionsHookType } from './types/personal-address-step2-actions.hook.type';
import { PersonalAddressStep2ActionsHookReturnType } from './types/personal-address-step2-actions-return.hook.type';

export const usePersonalAddressStep2ActionsHook = ({
  newAddress,
}: PersonalAddressStep2ActionsHookType): PersonalAddressStep2ActionsHookReturnType => {
  const { challenge, setChallenge, setChallengeError, setNewAddress } =
      usePersonalAddressContextConsumerHook(),
    { address } = useAddressHook(),
    { setResult } = useWizardFeedbackContextConsumerHook(),
    { setMe } = ManagerContextConsumerHook();

  return {
    ...PersonalAddressStep2ActionsHandlers({
      address,
      challenge,
      newAddress,
      setChallenge,
      setChallengeError,
      setMe,
      setNewAddress,
      setResult,
    }),
    challenge,
  };
};
