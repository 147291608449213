// Vendors
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
// Constants
import { USE_FORM_OPTIONS } from './constants/address-modal.hook.constants';
// Handlers
import { AddressModalHandlers } from '../handlers/address-modal.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { AddressModalHookReturnType } from './types/address-modal-return.hook.type';
import { AddressModalHookType } from './types/address-modal.hook.type';
// Utils
import { isIosWebView } from '@gruposantander/web-ui-framework';
import { formatAddressFormValues } from '../utils/address-modal.utils';

export const useAddressModalHook = ({
  defaultValues,
  onSave,
}: AddressModalHookType): AddressModalHookReturnType => {
  const { formatMessage } = useCbIntl(),
    {
      control,
      formState: { errors, isValidating },
      handleSubmit,
      reset,
      setValue,
    } = useForm({ ...USE_FORM_OPTIONS, defaultValues: formatAddressFormValues(defaultValues) });

  useEffect(() => {
    reset(formatAddressFormValues(defaultValues));
  }, [defaultValues, reset]);

  return {
    ...AddressModalHandlers({ defaultValues, onSave, reset }),
    control,
    errors,
    formatMessage,
    handleSubmit,
    isIosDevice: isIosWebView(),
    isValidating,
    setValue,
  };
};
