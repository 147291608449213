// Vendors
import React from 'react';
// Components
import { AssetComponent } from '@openbank/cb-ui-commons';
// Constants
import { TABLE_ROW_ASSET_PROPS, TABLE_ROW_PROPS } from './constants/table-row.constants';
// Hooks
import TransactionsLandingRowHook from './hooks/table-row.hook';
// Styles
import { TableRowComponentStyled, TableRowTopComponentStyled } from './table-row.component.styled';
// Types
import { CardTransactionType } from 'types/card-transaction.type';
import { TableRowComponentType } from './types/table-row.component.type';
// Utilities
import { getTransactionLandingRowToggleId } from './utils/table-row.utils';

const TableRowComponent = ({
  detailsComponent: TransactionsTableListElementsRowDetailsComponent = () => null,
  element,
  children,
  openDefault = false,
}: TableRowComponentType): React.ReactElement<TableRowComponentType> | null => {
  const { handleToggleTransactionsLandingRowAssetClick, open } =
    TransactionsLandingRowHook(openDefault);

  const { id } = element as CardTransactionType;

  return (
    <TableRowComponentStyled data-testid={id && TABLE_ROW_PROPS.testId + id}>
      <TableRowTopComponentStyled>
        <AssetComponent
          {...TABLE_ROW_ASSET_PROPS}
          id={getTransactionLandingRowToggleId(open)}
          onClick={handleToggleTransactionsLandingRowAssetClick}
        />
        {children}
      </TableRowTopComponentStyled>
      <TransactionsTableListElementsRowDetailsComponent {...{ element, open }} />
    </TableRowComponentStyled>
  );
};

export default TableRowComponent;
