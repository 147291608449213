// Types
import { TableRowBuilderReturnHandlersType } from './types/table-row-builder-return.handlers.type';
import { TableRowBuilderHandlersType } from './types/table-row-builder.handlers.type';

const updateToggleVisibility =
  ({
    handleToggleTransactionsLandingRowAssetClickTracking,
  }: Pick<TableRowBuilderHandlersType, 'handleToggleTransactionsLandingRowAssetClickTracking'>) =>
  (previousOpen: boolean): boolean => {
    const newOpen = !previousOpen;

    handleToggleTransactionsLandingRowAssetClickTracking(newOpen);

    return newOpen;
  };

const toggleTransactionsLandingRowAssetClickHandler = ({
  handleToggleTransactionsLandingRowAssetClickTracking,
  setOpen,
}: TableRowBuilderHandlersType): any => {
  setOpen(updateToggleVisibility({ handleToggleTransactionsLandingRowAssetClickTracking }));
};

const TableRowHandlers = (
  props: TableRowBuilderHandlersType
): TableRowBuilderReturnHandlersType => ({
  handleToggleTransactionsLandingRowAssetClick: () =>
    toggleTransactionsLandingRowAssetClickHandler(props),
});

export default TableRowHandlers;
