// Vendors
import { mobileAndTabletMediaQuery, mobileMediaQuery } from '@openbank/cf-ui-framework';
import styled, { css } from 'styled-components';
// Components
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled
} from 'styles/app.styled';
// Constants
import checkCvvPinConstants from './constants/check-cvv-pin.constants';
// Utils
import { convertPxsToRems } from 'utils/styles.utils';

const { styles: { wrapper, sidebar }} = checkCvvPinConstants;

export const CheckCvvPinLayoutGridContent = styled(LayoutGridContentComponentStyled)`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      max-width: ${wrapper.maxWidth};

      ${mobileAndTabletMediaQuery} {
        gap: ${spaces.spacingM};
      }
    `;
  }}
`;

export const CheckCvvPinMainContentComponentStyled = styled(LayoutGridContentLeftComponentStyled)`
  flex: 1;
  overflow: hidden;
`;

export const CheckCvvPinSidebarComponentStyled = styled(LayoutGridContentRightComponentStyled)`
  ${({ theme }) => {
    const { spaces } = theme;

    return css`
      overflow: hidden;
      width: ${convertPxsToRems(sidebar.maxWidth)};

      ${mobileAndTabletMediaQuery} {
        padding: 0 ${spaces.spacingXl};
        width: 100%;
      }

      ${mobileMediaQuery} {
        padding: 0 ${spaces.spacingS};
      }
    `;
  }}
`;

interface Props {
  $padding: boolean;
}

export const CheckCvvPinWrapperComponentStyled = styled.div<Props>`
  ${({ $padding, theme }) => {
    const { spaces } = theme;

    return css`
      ${mobileMediaQuery} {
        & > div > div[type="error"] {
          margin: 0 ${spaces.spacingS}
          width: calc(100% + (${spaces.spacingS} * 2));
        }
      }

      ${$padding
        ? css`
          padding: ${spaces.spacingXl} ${spaces.spacingXxxxxl};
          padding-bottom: ${spaces.spacingS};

          ${mobileAndTabletMediaQuery} {
            padding: ${spaces.spacingXl};
            padding-bottom: ${spaces.spacingS};
          }

          ${mobileMediaQuery} {
            padding: ${spaces.spacingM} ${spaces.spacingS};
            padding-bottom: ${spaces.spacingXxxxl};
          }`
        : null}
    `;
  }}
`;
