// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { DashboardTopMessageButtonBuilderReturnTrackingHandlersType } from './types/dashboard-top-message-button-builder-return.tracking.handlers.type';

const dashboardTopMessageButtonClickTrackingHandler = (
  eventLabel: string
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel,
  },
  type: TrackingEventsEnum.LINK,
});

const DashboardTopMessageButtonTrackingHandlers = (
  track: TrackBuilderType
): DashboardTopMessageButtonBuilderReturnTrackingHandlersType => ({
  handleDashboardTopMessageButtonClickTracking: (eventLabel: string) =>
    track(dashboardTopMessageButtonClickTrackingHandler(eventLabel)),
});

export default DashboardTopMessageButtonTrackingHandlers;
