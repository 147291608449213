// Configurations
import { getAllEnabledCountries } from 'containers/card-settings/views/card-control/components/locations/configurations/settings-card-control-locations.configurations';
// Types
import { CardControlContinentCountryComponentType } from 'types/card-control-continent-country.component.type';
// Utilities
import { someCountryInContinentIsConfigured } from 'containers/card-settings/views/card-control/components/locations/utils/setting-card-control-locations.utils';

const getMappedCountries = (countries: CardControlContinentCountryComponentType[]): any =>
  countries.map(({ id }) => ({ id }));

const activateAllContinentCountries = ({ continents, countries }: any): any =>
  getAllEnabledCountries(continents).concat(getMappedCountries(countries));

const deactivateAllContinentCountries = ({ continents, countries }: any): any =>
  getAllEnabledCountries(continents).filter(
    ({ id }) =>
      !getMappedCountries(countries).some(
        (country: CardControlContinentCountryComponentType) => country.id === id
      )
  );

const getSwitchContinentCountriesList = ({ continents, countries }: any): any =>
  someCountryInContinentIsConfigured(countries)
    ? deactivateAllContinentCountries({ continents, countries })
    : activateAllContinentCountries({ continents, countries });

export { getSwitchContinentCountriesList, someCountryInContinentIsConfigured };
