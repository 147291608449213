// Enumerations
import { BillingPaymentMethodEnumeration } from 'enumerations/billing-payment-method.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.cardSettings.cbCardContractDetails.billing.';

const PAYMENT_METHOD_VALUE = {
  [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: `${DOMAIN}fixedPaymentAmount`,
  [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: `${DOMAIN}fullAccountBalance`,
  [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]: `${DOMAIN}revolvingPercentageAmount`,
};

const TOOLTIP_MESSAGE_TITLE = {
  [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: `${DOMAIN}tooltip.fixedOrRevolvingPaymentAmountTitle`,
  [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: `${DOMAIN}tooltip.fullAccountBalanceTitle`,
  [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]: `${DOMAIN}tooltip.fixedOrRevolvingPaymentAmountTitle`,
};

const TOOLTIP_MESSAGE_CONTENT = {
  [BillingPaymentMethodEnumeration.FIXED_PAYMENT_AMOUNT]: `${DOMAIN}tooltip.fixedOrRevolvingPaymentAmountContent`,
  [BillingPaymentMethodEnumeration.FULL_ACCOUNT_BALANCE]: `${DOMAIN}tooltip.fullAccountBalanceContent`,
  [BillingPaymentMethodEnumeration.PERCENTAGE_ACCOUNT_BALANCE]: `${DOMAIN}tooltip.fixedOrRevolvingPaymentAmountContent`,
};

export { PAYMENT_METHOD_VALUE, TOOLTIP_MESSAGE_CONTENT, TOOLTIP_MESSAGE_TITLE };
