// Constants
import { NON_SECURE_STORES_SERVICE_CONFIGURATION } from '../services/constants/card-settings-payment-modal.service.constants';

const CARD_SETTING_PAYMENT_MODAL_BUTTON_PROPS = {
    testId: 'card-settings-payment-modal-button',
  },
  CARD_SETTING_PAYMENT_MODAL_LINK_PROPS = {
    testId: 'card-settings-payment-modal-link',
  },
  CARD_SETTING_PAYMENT_MODAL_CHALLENGE_PROPS = {
    baseService: NON_SECURE_STORES_SERVICE_CONFIGURATION.endpoint,
    baseServiceMethod: 'patch',
    withTopBorder: false,
  };

export {
  CARD_SETTING_PAYMENT_MODAL_BUTTON_PROPS,
  CARD_SETTING_PAYMENT_MODAL_LINK_PROPS,
  CARD_SETTING_PAYMENT_MODAL_CHALLENGE_PROPS,
};
