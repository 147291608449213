// Vendors
import React from 'react';
// Components
import { LoaderComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import MoreNotificationsHook from './hooks/more-notifications.hook';
// Styles
import { MoreNotificationsComponentStyled } from './more-notifications.component.styled';
// Translations
import translations from './translations/notifications-landing-more-notifications.translations';
// Types
import { MoreNotificationsPropsType } from './hooks/types/more-notifications.hook.type';

const MoreNotificationsComponent = ({
  onNext,
  isLoading,
}: MoreNotificationsPropsType): React.ReactElement => {
  const { ComponentRef } = MoreNotificationsHook({ onNext });

  return (
    <MoreNotificationsComponentStyled ref={ComponentRef}>
      {isLoading && (
        <>
          <LoaderComponent type="primary" />
          <ParagraphComponent>
            <FormattedMessageComponent id={translations.LOADING} />
          </ParagraphComponent>
        </>
      )}
    </MoreNotificationsComponentStyled>
  );
};

export default MoreNotificationsComponent;
