// Vendors
import { isMobileDevice, isTabletDevice } from '@openbank/cf-ui-framework';
// Constants
import constants from './constants/app.tracking.util.constants';

const getWebDeviceVersion = (): string => {
  switch (true) {
    case isMobileDevice():
      return constants.DEVICE_TYPES.MOBILE;
    case isTabletDevice():
      return constants.DEVICE_TYPES.TABLET;
    default:
      return constants.DEVICE_TYPES.DESKTOP;
  }
};

export default getWebDeviceVersion;
