// Constants
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';

export default {
  PROFILE_PERSONAL_DATA_ADDRESS_DIVIDER_PROPS: {
    testId: 'profile-personal-data-address-divider',
    testing: {
      elementId: 'profile-personal-data-address-divider',
      titleId: 'profile-personal-data-address-divider-title-section',
    },
    type: SectionDividerTypesEnumeration.INFORMATIVE,
  },
  PROFILE_PERSONAL_DATA_ADDRESS_PROPS: {
    testId: 'profile-personal-data-address',
  },
};
