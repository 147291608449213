// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_1_TERMS_LINK_PROPS } from './constants/financing-ppi-contracting-step2-terms-link.constants';
// Hooks
import { useFinancingPPIContractingStep2FeaturesSectionHook } from './hooks/financing-ppi-contracting-step2-terms-link.hook';
// Types
import { FinancingPPIContractingStep2TermsLinkType } from './types/financing-ppi-contracting-step2-terms-link.type';

const FinancingPPIContractingStep2TermsLinkComponent = ({
  label,
}: FinancingPPIContractingStep2TermsLinkType): React.ReactElement => {
  const { handleOpenFinancingPPIContractingConditionsFileLinkClick } =
    useFinancingPPIContractingStep2FeaturesSectionHook();

  return (
    <LinkComponent
      {...FINANCING_PPI_CONTRACTING_STEP_1_TERMS_LINK_PROPS}
      onClick={handleOpenFinancingPPIContractingConditionsFileLinkClick}
    >
      {label}
    </LinkComponent>
  );
};

export { FinancingPPIContractingStep2TermsLinkComponent };
