// Vendors
import { useEffect } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { SupportCancelContractContextConsumerHook } from 'containers/support/views/cancel-contract/contexts/support-cancel-contract.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Handlers
import SupportCancelContractSecondStepTrackingHandlers from '../../handlers/support-cancel-contract-second-step.tracking.handlers';
import SupportCancelContractSecondStepActionsHandlers from '../handlers/support-cancel-contract-second-step.actions.handlers';
// Hooks
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SupportCancelContractSecondStepActionsHandlersParamsType } from './types/support-cancel-contract-second-step-actions-handlers.type';

const SupportCancelContractSecondStepActionsHook =
  (): SupportCancelContractSecondStepActionsHandlersParamsType => {
    const { setResult } = useWizardFeedbackContextConsumerHook();
    const {
      isDisabledLastStep,
      progressId,
      reason,
      setIsDisabledLastStep,
      setProgressId,
      setReason,
    } = SupportCancelContractContextConsumerHook();
    const { currentCard } = AppContextConsumerHook(),
      { cardContractId = CharactersEnum.NO_VALUE } = currentCard || {};

    const {
      handleNextButtonClickTrackingHandlerStep2,
      handleOnEnterThirdStepCancelContractTrackingHandler,
    } = AppTrackingHook(SupportCancelContractSecondStepTrackingHandlers);
    const { handleFinishActionsEvent } = SupportCancelContractSecondStepActionsHandlers({
      cardContractId,
      handleNextButtonClickTrackingHandlerStep2,
      progressId,
      reason,
      setIsDisabledLastStep,
      setProgressId,
      setReason,
      setResult,
    });

    useEffect(() => {
      handleOnEnterThirdStepCancelContractTrackingHandler();
    }, []);

    return {
      reason,
      handleFinishActionsEvent,
      isDisabledLastStep,
    };
  };

export default SupportCancelContractSecondStepActionsHook;
