// Enumerations
import { InsuranceStatusEnumeration } from 'enumerations/insurance-status.enumeration';
// Translations
import {
  DEFINITIVE,
  TRIAL,
} from './translations/financing-ppi-cancellation-landing.utils.translations';

const PPIIsOnTrialPeriod = (status?: InsuranceStatusEnumeration): boolean =>
  status === InsuranceStatusEnumeration.COOLING_OFF;

const getPPICancellationDescription = (status?: InsuranceStatusEnumeration): string =>
  PPIIsOnTrialPeriod(status) ? TRIAL : DEFINITIVE;

export { getPPICancellationDescription, PPIIsOnTrialPeriod };
