// Vendors
import styled from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';

const ReportDefectiveAdditionalInformationOptionsStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

ReportDefectiveAdditionalInformationOptionsStyled.displayName =
  'ReportDefectiveAdditionalInformationOptions';

const ReportDefectiveAdditionalInformationContainerStyled = styled.div.attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)`
  display: flex;
  flex-direction: column;
  gap: inherit;
  width: 100%;
`;

ReportDefectiveAdditionalInformationContainerStyled.displayName =
  'ReportDefectiveAdditionalInformationContainer';

const ParagraphComponentStyled = styled(ParagraphComponent).attrs(
  ({ testId }: { testId: string }) => ({
    ...(testId ? { 'data-testid': testId } : {}),
  })
)``;

export {
  ReportDefectiveAdditionalInformationContainerStyled,
  ReportDefectiveAdditionalInformationOptionsStyled,
  ParagraphComponentStyled,
};
