// Vendors
import { useNavigate } from 'react-router-dom';
// Handlers
import FinancingErrorButtonHandlers from '../handlers/financing-error-button.handlers';
import FinancingErrorButtonTrackingHandlers from '../handlers/financing-error-button.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingErrorButtonBuilderReturnHandlersType } from '../handlers/types/financing-error-button.handlers.type';

const useFinancingErrorButtonHook = (): FinancingErrorButtonBuilderReturnHandlersType =>
  FinancingErrorButtonHandlers({
    ...AppTrackingHook(FinancingErrorButtonTrackingHandlers),
    navigate: useNavigate(),
  });

export default useFinancingErrorButtonHook;
