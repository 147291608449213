// Vendors
import React from 'react';
// Components
import { FinancingInsurancesContractingLPIAdditionalComponent } from '../additional/financing-insurances-contracting-lpi-additional.component';
import { FinancingInsurancesContractingLPIStep2PlansComponent } from './components/plans/financing-insurances-contracting-lpi-step2-plans.component';
import { FinancingPPIContractingStep1HiringLegalComponent } from '../../../components/step1/components/legal/financing-ppi-contracting-step1-hiring-legal.component';
import { InsuranceBenefitsComponent } from '../insurance-benefits/insurance-benefits.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import { FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS } from '../../constants/financing-insurances-contracting-lpi.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { useFinancingInsurancesContractingLPIStep2 } from './hooks/financing-insurances-contracting-lpi-step2.hook';

const FinancingInsurancesContractingLPIStep2Component = (): React.ReactElement => {
  const { error, handleNextButtonClick } = useFinancingInsurancesContractingLPIStep2();

  return (
    <LayoutColumnFlexComponentStyled>
      <InsuranceBenefitsComponent />
      <FinancingInsurancesContractingLPIAdditionalComponent />
      <FinancingInsurancesContractingLPIStep2PlansComponent error={error} />
      <FinancingPPIContractingStep1HiringLegalComponent />
      <WizardStepFooterComponent
        {...FINANCING_CONTRACTING_LPI_COMMON_CANCEL_PROPS}
        rightButtonConfiguration={{ onClick: handleNextButtonClick }}
      />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingInsurancesContractingLPIStep2Component };
