// Vendors
import React from 'react';
// Components
import { PersonalUploadDocumentationComponent } from '../upload/personal-upload-documentation.component';
import { PersonalNoDocumentsComponent } from '../no-documents/personal-no-documents.component';
import WizardFeedbackComponent from 'components/wizard-feedback/wizard-feedback.component';
// Constants
import { FEEDBACK_PROPS } from './constants/personal-upload-documentation-orchestrator.constants';
// Enumerations
import { PersonalUploadDocumentationFlows } from '../../enumerations/personal-upload-documentation-flows.enum';
// Types
import { PersonalUploadDocumentationOrchestratorPropsType } from './types/personal-upload-documentation-orchestrator-props.type';

const PersonalUploadDocumentationOrchestrator = ({
  flowId,
  setFlowId,
}: PersonalUploadDocumentationOrchestratorPropsType): React.ReactElement => {
  if (flowId === PersonalUploadDocumentationFlows.UPLOAD_DOCS) {
    return (
      <WizardFeedbackComponent {...FEEDBACK_PROPS}>
        <PersonalUploadDocumentationComponent onChangeFlow={setFlowId} />
      </WizardFeedbackComponent>
    );
  }

  return <PersonalNoDocumentsComponent />;
};

export { PersonalUploadDocumentationOrchestrator };
