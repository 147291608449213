export default {
  VALIDATE_DOCUMENT_STATIC_CONFIGURATION: {
    documentType: 'email',
  },
  LOCALHOST: {
    options: ['localhost', '127.0.0.1'],
  },
  LOGIN_FROM_PRIVATE: 'loginFromPrivate',
  IS_CUSTOMER: 'isCustomer',
};
