// Vendors
import React from 'react';
// Components
import { TransactionsReportWizardComponent } from '../wizard/transactions-report-wizard.component';
// Constants
import { STEPS } from './constants/transactions-report-different-amount-atm.constants';
// Contexts
import { TransactionsReportDifferentAmountAtmContextConsumerHOC } from './context/transactions-report-different-amount-atm.context';

const TransactionsReportDifferentAmountAtmBaseComponent = (): React.ReactElement => {
  return <TransactionsReportWizardComponent steps={STEPS} />;
};

const TransactionsReportDifferentAmountAtmComponent =
  TransactionsReportDifferentAmountAtmContextConsumerHOC(
    TransactionsReportDifferentAmountAtmBaseComponent
  );

export { TransactionsReportDifferentAmountAtmComponent };
