// Vendors
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Services
import { logoutService } from 'services/logout.services';

const logoutResolver = async (): Promise<any> => {
  const [, { status }] = (await logoutService()) ?? [
    null,
    { status: HttpStatusCodesEnum.INTERNAL_SERVER_ERROR },
  ];

  return requestRespondedNoContent(status) ? [status] : [null, true];
};

export { logoutResolver };
