// Enumerations
import { CustomerTitlesEnumeration } from 'enumerations/customer-titles.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.personalInformation.editTitle.';

export default {
  [CustomerTitlesEnumeration.DOCTOR]: `${DOMAIN}doctor`,
  [CustomerTitlesEnumeration.DOCTOR_MISS]: `${DOMAIN}doctorMiss`,
  [CustomerTitlesEnumeration.DOCTOR_MISTER]: `${DOMAIN}doctorMister`,
  [CustomerTitlesEnumeration.DOCTOR_MX]: `${DOMAIN}doctorMx`,
  [CustomerTitlesEnumeration.PROFESSOR]: `${DOMAIN}professor`,
  [CustomerTitlesEnumeration.PROFESSOR_MISS]: `${DOMAIN}professorMiss`,
  [CustomerTitlesEnumeration.PROFESSOR_MISTER]: `${DOMAIN}professorMister`,
  [CustomerTitlesEnumeration.PROFESSOR_MX]: `${DOMAIN}professorMx`,
  [CustomerTitlesEnumeration.PROFESSOR_DOCTOR]: `${DOMAIN}professorDoctor`,
  [CustomerTitlesEnumeration.PROFESSOR_DOCTOR_MISS]: `${DOMAIN}professorDoctorMiss`,
  [CustomerTitlesEnumeration.PROFESSOR_DOCTOR_MISTER]: `${DOMAIN}professorDoctorMister`,
  [CustomerTitlesEnumeration.PROFESSOR_DOCTOR_MX]: `${DOMAIN}professorDoctorMx`,
  [CustomerTitlesEnumeration.MISTER]: `${DOMAIN}mister`,
  [CustomerTitlesEnumeration.MISS]: `${DOMAIN}miss`,
  [CustomerTitlesEnumeration.MISS_MISTER]: `${DOMAIN}gender`,
  [CustomerTitlesEnumeration.NO_TITLE]: `${DOMAIN}noTitle`,
};
