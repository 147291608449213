// Enumerations
import { CharactersEnum, MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Types
import { TransactionsEppStep2AmountUtilsType } from './types/transactions-epp-step2-amount.utils.type';

const installmentPlanSimulationHasId = ({
  id,
  simulation,
}: Pick<TransactionsEppStep2AmountUtilsType, 'id' | 'simulation'>): boolean =>
  Boolean(simulation && simulation[id] && simulation[id] >= 0);

const getTransactionsEppStep2ResumeAmountTypeValue = ({
  currency,
  formatNumber,
  id,
  simulation,
}: TransactionsEppStep2AmountUtilsType): CharactersEnum | string =>
  installmentPlanSimulationHasId({ id, simulation })
    ? formatNumber(simulation[id], {
        ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS,
        currency,
      })
    : CharactersEnum.NO_VALUE;

export { getTransactionsEppStep2ResumeAmountTypeValue };
