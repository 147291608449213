// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Constants
import { CONSENTS_TRACKING_IDS } from './constants/profile-consents-step1.tracking.handlers.constants';
// Enumerations
import { SubconsentsTarckingEnum } from './enumerations/subconsents-tracking.enum';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { ConsentsStep1TrackingHandlersType } from './types/consents-step1-tracking.handlers.type';

const onConsentsStep1StepLoadedTracking = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/comunicationManagement/step01/selectCommunications',
    sectionFamily: 'banking',
    sectionCategory: 'settings',
    sectionName: 'accounts',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const onSelectConsentsTracking = (checked: boolean, id: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickToggle',
    eventLabel: `${CONSENTS_TRACKING_IDS[id]}${checked ? 'yes' : 'no'}Toggle`,
  },
  type: TrackingEventsEnum.LINK,
});

const onCheckSubconsentsTracking = (id: string): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickCheckBox',
    eventLabel: `${SubconsentsTarckingEnum[id]}CheckBox`,
  },
  type: TrackingEventsEnum.LINK,
});

const ConsentsStep1TrackingHandlers = (
  track: TrackBuilderType
): ConsentsStep1TrackingHandlersType => ({
  handleConsentsStep1StepLoadedTracking: () => track(onConsentsStep1StepLoadedTracking()),
  handleSelectConsentsTracking: (checked: boolean, id: string) =>
    track(onSelectConsentsTracking(checked, id)),
  handleCheckSubconsentsTracking: (id: string) => track(onCheckSubconsentsTracking(id)),
});

export { ConsentsStep1TrackingHandlers };
