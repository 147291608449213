// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CancelModalBuilderReturnTrackingHandlersType } from './types/cancel-modal-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const confirmCancelModalButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'yesCancelOperation',
  },
  type: TrackingEventsEnum.LINK,
});

const cancelShowCancelModalLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'noGoBackToProcess',
  },
  type: TrackingEventsEnum.LINK,
});

const closeChangeEmailModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancelModalClose',
  },
  type: TrackingEventsEnum.LINK,
});

const openCancelModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'cancelModalOpen',
  },
  type: TrackingEventsEnum.LINK,
});

const CancelModalTrackingHandlers = (
  track: TrackBuilderType
): CancelModalBuilderReturnTrackingHandlersType => ({
  handleConfirmCancelModalButtonClickTracking: () =>
    track(confirmCancelModalButtonClickTrackingHandler()),
  handleCloseCancelModalAssetClickTracking: () => track(closeChangeEmailModalTrackingHandler()),
  handleCancelShowCancelModalLinkClickTracking: () =>
    track(cancelShowCancelModalLinkClickTrackingHandler()),
  handleOpenCancelModalTracking: () => track(openCancelModalTrackingHandler()),
});

export default CancelModalTrackingHandlers;
