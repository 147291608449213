// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, TitleComponent } from '@openbank/cb-ui-commons';
import ProfilePersonalNameResumeLayoutComponent from 'containers/personal/views/landing/components/name/components/resume-layout/profile-personal-name-resume-layout.component';
// Constants
import constants from './constants/profile-personal-name-alias-empty.constants';
// Hooks
import ProfilePersonalNameAliasEmptyHook from './hooks/profile-personal-name-alias-empty.hook';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import { ProfilePersonalNameEditContentComponentStyled } from './profile-personal-name-alias-empty.component.styled';
// Translations
import translations from './translations/profile-personal-name-alias-empty.translations';

const ProfilePersonalNameAliasEmptyComponent = (): React.ReactElement => {
  const { handleCreateNewAliasAssetClick } = ProfilePersonalNameAliasEmptyHook();

  return (
    <ProfilePersonalNameResumeLayoutComponent
      {...constants.PROFILE_PERSONAL_NAME_ALIAS_EMPTY_PROPS}
    >
      <ProfilePersonalNameEditContentComponentStyled>
        <ParagraphSecondaryComponentStyled>
          <TitleComponent {...constants.PROFILE_PERSONAL_NAME_ALIAS_EMPTY_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.NO_ALIAS} />
          </TitleComponent>
        </ParagraphSecondaryComponentStyled>
        <AssetComponent
          {...constants.PROFILE_PERSONAL_NAME_ALIAS_EMPTY_ASSET_PROPS}
          onClick={handleCreateNewAliasAssetClick}
        />
      </ProfilePersonalNameEditContentComponentStyled>
    </ProfilePersonalNameResumeLayoutComponent>
  );
};

export default ProfilePersonalNameAliasEmptyComponent;
