// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameTitleEmptyBuilderReturnTrackingHandlersType } from './types/profile-personal-name-title-empty-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const handleCreateNewTitleAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'createNewTitle',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameTitleEmptyTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameTitleEmptyBuilderReturnTrackingHandlersType => ({
  handleCreateNewTitleAssetClickTracking: () =>
    track(handleCreateNewTitleAssetClickTrackingHandler()),
});

export { ProfilePersonalNameTitleEmptyTrackingHandlers };
