// Vendors
import styled from 'styled-components';
// Utilities
import { mobileAndTabletMediaQuery } from '@openbank/cf-ui-framework';

export const LayoutGridContentRightComponentContainerStyled = styled.div<{ $hidden: boolean }>`
  display: ${({ $hidden }) => ($hidden ? 'none' : 'block')};

  ${mobileAndTabletMediaQuery} {
    width: 100%;
  }
`;
