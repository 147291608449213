// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Styles
import {
  AppCardSectionComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';
import { OperativeLayoutLeftContentComponentStyled } from './operative-layout.component.styled';
// Types
import { OperativeLayoutComponentType } from './types/operative-layout.component.type';

const OperativeLayoutComponent = ({
  children,
  fetching,
  error,
  sidebarComponent: OperativeLayoutSidebarComponent,
}: OperativeLayoutComponentType): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <AppCardSectionComponentStyled>
        <OperativeLayoutLeftContentComponentStyled $fetching={fetching} $error={error}>
          <FetchErrorComponent {...{ fetching, error }}>{children}</FetchErrorComponent>
        </OperativeLayoutLeftContentComponentStyled>
      </AppCardSectionComponentStyled>
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <OperativeLayoutSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export { OperativeLayoutComponent };
