// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { HasBottomComponentType } from './types/dashboard-spent-bottom.utilts.types';

const hasBottomComponent = ({
  spentMessageConfiguration,
  expenses,
}: HasBottomComponentType): boolean =>
  spentMessageConfiguration !== null || expenses.length > NUMBER.ZERO;

export { hasBottomComponent };
