// Vendors
import { useErrorBoundary } from 'react-error-boundary';
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Event handlers
import { CardDetailHandlers } from '../handlers/card-detail.handlers';
// Types
import { CardDetailHookType } from './types/card-detail.hook.types';
import { ManagerContextConsumerHook } from 'contexts/manager.context';

const CardDetailHook = (): CardDetailHookType => {
  const [fetching, setFetching] = useState(true);

  const { showBoundary: setError } = useErrorBoundary();

  const { setCurrentCardDetail, currentCard, currentCardDetail } = AppContextConsumerHook();
  const { cardDetail, setCardDetail } = ManagerContextConsumerHook();

  const { handleSetupCardInformationEvent } = CardDetailHandlers({
    currentCard,
    setCardDetail,
    setCurrentCardDetail,
    setFetching,
    setError,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setFetching(!currentCardDetail.id);
    cardDetail && handleSetupCardInformationEvent();
  }, [cardDetail]);

  return { fetching };
};

export { CardDetailHook };
