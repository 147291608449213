// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Types
import { FinancingPPICancellationLandingResumeCoveredHookType } from './types/financing-ppi-cancellation-landing-resume-covered.hook.type';

const useFinancingPPICancellationLandingResumeCoveredHook =
  (): FinancingPPICancellationLandingResumeCoveredHookType => {
    const { insurancesInfo } = useInsurancesContextConsumerHook(),
      { status } = insurancesInfo?.insurances.CPI || {},
      { currentCard } = AppContextConsumerHook(),
      { nextCycleDate } = currentCard?.creditDetails || {};

    const { formatDate } = useCbIntl();

    return {
      formatDate,
      nextCycleDate,
      status,
    };
  };

export { useFinancingPPICancellationLandingResumeCoveredHook };
