// Vendors
import styled from 'styled-components';
// Utils
import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Types
import { TestIdPropsType } from 'types/test-id.type';

const CardSettingsStatementsRowDetailsComponentStyled = styled.div.attrs(
  ({ testId }: TestIdPropsType) => ({
    'data-testid': testId,
  })
)`
  align-items: stretch;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spaces.spacingS} 0 0 calc((100% / 5) + 54px);

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingXs} 0 0 ${({ theme }) => theme.spaces.spacingL};
  }
`;
CardSettingsStatementsRowDetailsComponentStyled.displayName =
  'CardSettingsStatementsRowDetailsComponentStyled';

export { CardSettingsStatementsRowDetailsComponentStyled };
