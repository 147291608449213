const DOMAIN = 'cobranded.services.userSettings.privateArea.consents.consentsLanding.';

const FEEDBACK_ERROR_DESCRIPTION = `${DOMAIN}errorFeedbackDescription`;
const FEEDBACK_ERROR_TITLE = `${DOMAIN}errorFeedbackTitle`;
const FEEDBACK_SUCCESS_DESCRIPTION = `${DOMAIN}successFeedbackDescription`;
const FEEDBACK_SUCCESS_TITLE = `${DOMAIN}successFeedbackTitle`;
const GO_TO_PERSONAL_INFO = `${DOMAIN}successFeedbackLink`;
const STEP_1_TITLE = `${DOMAIN}step1.title`;
const STEP_2_TITLE = `${DOMAIN}step2.title`;
const TITLE = `${DOMAIN}title`;

const PROFILE_CONSENTS_TITLES = {
  APP: `${DOMAIN}appTitle`,
  EMAIL: `${DOMAIN}emailTitle`,
  PHONE: `${DOMAIN}phoneTitle`,
  POSTAL: `${DOMAIN}postalTitle`,
  SMS: `${DOMAIN}smsTitle`,
  WEB: `${DOMAIN}webTitle`,
};

export {
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_PERSONAL_INFO,
  PROFILE_CONSENTS_TITLES,
  STEP_1_TITLE,
  STEP_2_TITLE,
  TITLE,
};
