// Contexts
import { useWizardContextConsumerHook } from 'components/wizard/contexts/wizard.context';
// Handlers
import WizardHandlers from '../handlers/wizard.handlers';
// Types
import { RecoveryKeyStep1ActionsHookType } from './types/recovery-key-step1-actions.hook.type';

const useWizardHook = (): RecoveryKeyStep1ActionsHookType => {
  const { currentStep, setCurrentStep } = useWizardContextConsumerHook();

  return {
    ...WizardHandlers({ setCurrentStep }),
    currentStep,
  };
};

export default useWizardHook;
