// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_APR_PROPS,
  TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_NIR_PROPS,
} from './constants/transactions-epp-step1-simulation-interests.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Contexts
import { useTransactionsEppContextConsumerHook } from 'containers/transactions/views/epp/contexts/transactions-epp.context';
// Styles
import { LayoutRowFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  APR,
  NIR,
  NIR_TOOLTIP,
} from './translations/transactions-epp-step1-simulation-interests.translations';
// Utilities
import { simulationPlanIsNotDone } from 'containers/transactions/views/epp/components/step1/utils/transactions-epp-step1.utils';

const TransactionsEppStep1SimulationInterestsComponent = (): React.ReactElement | null => {
  const { eppPlanSimulation } = useTransactionsEppContextConsumerHook(),
    { interestRate, annualPercentageRate } = eppPlanSimulation,
    amountProps = MiscellaneousCurrencyConstants.AMOUNT_PERCENTAGE_FORMAT_PROPS;

  return simulationPlanIsNotDone(eppPlanSimulation) ? null : (
    <LayoutRowFlexComponentStyled>
      <ResumeItemComponent
        {...TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_APR_PROPS}
        label={<FormattedMessageComponent id={APR} />}
        value={<FormattedNumber {...amountProps} value={annualPercentageRate / 100} />}
      />
      <ResumeItemComponent
        {...TRANSACTIONS_EPP_STEP1_SIMULATION_INTERESTS_NIR_PROPS}
        label={<FormattedMessageComponent id={NIR} />}
        tooltipConfiguration={{ content: <FormattedMessageComponent id={NIR_TOOLTIP} /> }}
        value={<FormattedNumber {...amountProps} value={interestRate / 100} />}
      />
    </LayoutRowFlexComponentStyled>
  );
};

export { TransactionsEppStep1SimulationInterestsComponent };
