// Vendors
import { useEffect, useState } from 'react';
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useCardSettingsChange3DContextConsumerHook } from 'containers/card-settings/views/change-3d/contexts/card-settings-change-3d.context';
// Event handlers
import { CardSettingsChange3dStep1NewCodeHandlers } from '../handlers/card-settings-change-3d-step1-new-code.handlers';
import { CardSettingsChange3dStep1NewCodeTrackingHandlers } from '../handlers/card-settings-change-3d-step1-new-code.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { CardSettingsChange3dStep1NewCodeHookType } from './types/card-settings-change-3d-step1-new-code.hook.type';

const useCardSettingsChange3dStep1NewCodeHook = (): CardSettingsChange3dStep1NewCodeHookType => {
  const [error, setError] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();

  const { new3DCode, setNew3DCode, setIsRepeated3DCodeEnabled } =
      useCardSettingsChange3DContextConsumerHook(),
    { profile } = AppContextConsumerHook(),
    { dateOfBirth } = profile?.birthDetails || {};

  const { handleChange3DNewCodeInputClickTracking, handleChange3DNewCodeInputErrorTracking } =
      AppTrackingHook(CardSettingsChange3dStep1NewCodeTrackingHandlers),
    { handleSaveNew3DCodeInputChangeEvent, handleValidateNew3DCodeInputChangeEvent } =
      CardSettingsChange3dStep1NewCodeHandlers({
        dateOfBirth,
        handleChange3DNewCodeInputErrorTracking,
        new3DCode,
        setNew3DCode,
        setError,
        setIsRepeated3DCodeEnabled,
      });

  return {
    dateOfBirth,
    formatMessage,
    error,
    handleChange3DNewCodeInputClickTracking,
    handleSaveNew3DCodeInputChangeEvent,
    handleValidateNew3DCodeInputChangeEvent,
    new3DCode,
  };
};

export { useCardSettingsChange3dStep1NewCodeHook };
