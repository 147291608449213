// Vendors
import React from 'react';
// Components
import { BlockCardAddressComponent } from 'components/block-card/components/address/block-card-address.component';
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import BlockCardReasonComponent from 'components/block-card/components/block-card-reason/block-card-reason.component';
import CardSettingsBlockCardFirstStepActionsComponent from './components/actions/card-settings-block-card-first-step-actions.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { FIRST_STEP_INTRODUCTION_TEST_ID } from './constants/card-settings-block-card-first-step.constants';
// Styles
import {
  CardSettingsBlockCardFirstStepComponentStyled,
  CardSettingsBlockCardFirstStepIntroductionStyled,
} from './card-settings-block-card-first-step.component.styled';
// Translations
import { DESCRIPTION } from './translations/card-settings-block-card-first-step.translations';

const CardSettingsBlockCardFirstStepComponent = (): React.ReactElement => {
  return (
    <CardSettingsBlockCardFirstStepComponentStyled>
      <CardSettingsBlockCardFirstStepIntroductionStyled
        {...{ testId: FIRST_STEP_INTRODUCTION_TEST_ID }}
      >
        <ParagraphComponent>
          <FormattedMessageComponent id={DESCRIPTION} />
        </ParagraphComponent>
      </CardSettingsBlockCardFirstStepIntroductionStyled>
      <BlockCardReasonComponent />
      <BlockCardAddressComponent />
      <CardSettingsBlockCardFirstStepActionsComponent />
    </CardSettingsBlockCardFirstStepComponentStyled>
  );
};

export default CardSettingsBlockCardFirstStepComponent;
