// Vendors
import React from 'react';
// Components
import SupportCancelContractSidebarComponent from './components/sidebar/support-cancel-contract-sidebar.component';
import { SupportCancelContractOrchestrator } from './components/orchestrator/support-cancel-contract-orchestrator';
// Contexts
import { SupportCancelContractContextConsumerHOC } from './contexts/support-cancel-contract.context';
// Providers
import { withCardDetailProvider } from 'providers/card-detail/card-detail.provider';
// Styles
import {
  SupportCancelContractContainerStyled,
  SupportCancelContractContentContainerStyled,
  SupportCancelContractContentLeftComponentStyled,
  SupportCancelContractGridContentRightComponentStyled,
} from './support-cancel-contract.container.styled';

const SupportCancelContractContainer = (): React.ReactElement => (
  <SupportCancelContractContainerStyled>
    <SupportCancelContractContentContainerStyled>
      <SupportCancelContractContentLeftComponentStyled>
        <SupportCancelContractOrchestrator />
      </SupportCancelContractContentLeftComponentStyled>
      <SupportCancelContractGridContentRightComponentStyled>
        <SupportCancelContractSidebarComponent />
      </SupportCancelContractGridContentRightComponentStyled>
    </SupportCancelContractContentContainerStyled>
  </SupportCancelContractContainerStyled>
);

export default withCardDetailProvider(SupportCancelContractContextConsumerHOC(SupportCancelContractContainer));
