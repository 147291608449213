// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const PersonalPhotoNameButtonUploadModalComponentStyled = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export { PersonalPhotoNameButtonUploadModalComponentStyled };
