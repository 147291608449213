// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { SignatureKeyChangeStep2ActionsUtilsType } from './types/security-keys-signature-key-change-second-step-actions.utils.type';

const modifySignatureKeyChangeStep2NextButtonIsDisabled = ({
  newSignatureKey,
  repeatNewSignatureKey,
  inputError,
}: SignatureKeyChangeStep2ActionsUtilsType): boolean => {
  return !(
    newSignatureKey === repeatNewSignatureKey &&
    (!inputError || inputError.length === 0) &&
    newSignatureKey.length === NUMBER.EIGHT
  );
};

export { modifySignatureKeyChangeStep2NextButtonIsDisabled };
