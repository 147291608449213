// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { RequestPortabilityCheckboxesBuilderReturnTrackingHandlersType } from './types/request-portability-checkboxes-builder-return.tracking.handlers.type';

const mainCardChangeCheckboxTracking = (value: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'mainCardCheckBox',
    eventLabel: 'movements' + (value ? 'Checked' : 'Unchecked'),
  },
  type: TrackingEventsEnum.LINK,
});

const beneficiaryCardChangeCheckboxTracking = (value: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'beneficiaryCardCheckBox',
    eventLabel: 'movements' + (value ? 'Checked' : 'Unchecked'),
  },
  type: TrackingEventsEnum.LINK,
});

const movementsChangeCheckboxTrackingHandler = (value: boolean): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickCheckbox',
    eventLabel: 'movements' + (value ? 'Checked' : 'Unchecked'),
  },
  type: TrackingEventsEnum.LINK,
});

const RequestPortabilityCheckboxesTrackingHandlers = (
  track: (param: unknown) => void
): RequestPortabilityCheckboxesBuilderReturnTrackingHandlersType => ({
  handleMainCardChangeCheckboxTracking: (value: boolean) =>
    track(mainCardChangeCheckboxTracking(value)),
  handleBeneficiaryCardChangeCheckboxTracking: (value: boolean) =>
    track(beneficiaryCardChangeCheckboxTracking(value)),
  handleMovementsChangeCheckboxTracking: (value: boolean) =>
    track(movementsChangeCheckboxTrackingHandler(value)),
});

export default RequestPortabilityCheckboxesTrackingHandlers;
