// Vendors
import { requestHasSuccessStatus } from '@openbank/cf-ui-framework';
// Services
import { cancelEppService } from '../services/financing-early-repayment.services';
// Types
import { CancelEppResolverType } from './types/cancel-epp-resolver.type';
import { ChallengeType } from 'types/challenge.type';

const cancelEppResolver = async (
  params: CancelEppResolverType
): Promise<[null] | [ChallengeType | null, number]> => {
  const [response, { status }] = await cancelEppService(params);

  return requestHasSuccessStatus(status) ? [null] : [response, status];
};

export { cancelEppResolver };
