// Enumerations
import { AddressTypesEnum } from 'components/block-card/components/enumerations/address-types.enumeration';
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';

const TRANSACTIONS_REPORT_FRAUDULENT_MOVEMENT_CONTEXT_DEFAULT_VALUE = {
  addresses: [{ address: null, type: AddressTypesEnum.HBTL }],
  blockReason: null,
  files: [],
  selectedOptions: {
    length: 0,
    notRecognizeChargeHaveYourCard: false,
    notRecognizeChargeClickLink: false,
    notRecognizeChargeMotiveFraud: undefined,
    extraInformation: '',
  },
  setBlockReason: (): null => null,
  setFiles: (): null => null,
  setSelectedOptions: (): null => null,
  status: CardStatusTypesEnumeration.ACTIVE,
};

const NOT_RECOGNIZED_DEFAULT_SELECTED_OPTIONS = {
  extraInformation: '',
  length: 0,
  notRecognizeChargeClickLink: undefined,
  notRecognizeChargeHaveYourCard: undefined,
  notRecognizeChargeMotiveFraud: undefined,
};

const FILES_INITIAL_STATE = [
  {
    id: 'proof-of-contact',
    documentId: undefined,
    documentType: DocumentsTypesEnumeration.POLICE_REPORT,
    file: null,
    state: DocumentsUploadStatesEnumeration.DEFAULT,
  },
];

export {
  FILES_INITIAL_STATE,
  NOT_RECOGNIZED_DEFAULT_SELECTED_OPTIONS,
  TRANSACTIONS_REPORT_FRAUDULENT_MOVEMENT_CONTEXT_DEFAULT_VALUE,
};
