// Types
import { ChangeCreditLimitHandlerReturnPropsType } from './types/change-credit-limit-increase-handler-return.type';
import { ChangeCreditLimitHandlerPropsType } from './types/change-credit-limit-increase.handler.type';
import { OnChangeCreditLimitType } from './types/on-change-credit-limit.type';
// Utils
import { isNewCreditLimitValid } from './utils/credit-limit-validation.utils';

const onChangeCreditLimit = ({
  currentLimit,
  newCreditLimit,
  step,
  wizardStatus,
  setInputError,
  setStep,
  setWizardStatus,
}: OnChangeCreditLimitType): void => {
  const validationError = isNewCreditLimitValid({
    newCreditLimit: Number(newCreditLimit),
    currentLimit,
    maxAmount: step.maxAmount as number,
    minAmount: step.minAmount as number,
  });

  if (!validationError) {
    setInputError('');
    setStep(prevStep => ({
      ...prevStep,
      newCreditLimit: Number(newCreditLimit),
      nextDisabled: false,
    }));
    setWizardStatus({ ...wizardStatus, error: false, fetching: false });
  } else {
    setInputError(validationError);
    setStep(prevStep => ({ ...prevStep, nextDisabled: true }));
  }
};

const ChangeCreditLimitHandler = ({
  currentLimit,
  step,
  wizardStatus,
  setInputError,
  setStep,
  setWizardStatus,
}: ChangeCreditLimitHandlerPropsType): ChangeCreditLimitHandlerReturnPropsType => ({
  handleOnChange: (newCreditLimit: number) =>
    onChangeCreditLimit({
      currentLimit,
      newCreditLimit,
      step,
      wizardStatus,
      setInputError,
      setStep,
      setWizardStatus,
    }),
});

export default ChangeCreditLimitHandler;
