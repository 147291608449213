// Vendors
import React from 'react';
import { createRoot } from 'react-dom/client';
// Entrypoint
import Entrypoint from 'entrypoint/entrypoint';

const BootstrapCommon = (): any => {
  createRoot(document.getElementById('root') as HTMLElement).render(<Entrypoint />);
};

export default BootstrapCommon;
