const DOMAIN_FAST_CASH = 'cobranded.services.userSettings.privateArea.financing.fastCash.';
const DOMAIN_FAST_CASH_ERROR = `${DOMAIN_FAST_CASH}error.`;
const DOMAIN_FAST_CASH_FAILED_BUTTONS =
  'cobranded.services.userSettings.privateArea.financing.fastCash.failed.buttons.';
const DOMAIN_FAST_CASH_FAILED_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.financing.fastCash.failed.confirmation.';
const DOMAIN_FAST_CASH_STEPS_FIRST =
  'cobranded.services.userSettings.privateArea.financing.fastCash.steps.first.';
const DOMAIN_FAST_CASH_STEPS_SECOND =
  'cobranded.services.userSettings.privateArea.financing.fastCash.steps.second.';
const DOMAIN_FAST_CASH_SUCCESS_BUTTONS =
  'cobranded.services.userSettings.privateArea.financing.fastCash.success.buttons.';
const DOMAIN_FAST_CASH_SUCCESS_CONFIRMATION =
  'cobranded.services.userSettings.privateArea.financing.fastCash.success.confirmation.';

const TITLE = `${DOMAIN_FAST_CASH}title`;

const ERROR = {
  ERROR_TITLE: `${DOMAIN_FAST_CASH_ERROR}title`,
  ERROR_DESCRIPTION: `${DOMAIN_FAST_CASH_ERROR}description`,
};

const SUCCESS = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_FAST_CASH_SUCCESS_CONFIRMATION}title`,
    CONTENT: `${DOMAIN_FAST_CASH_SUCCESS_CONFIRMATION}content`,
  },
  BACK_BUTTON: `${DOMAIN_FAST_CASH_SUCCESS_BUTTONS}back`,
};

const FAILED = {
  CONFIRMATION: {
    TITLE: `${DOMAIN_FAST_CASH_FAILED_CONFIRMATION}title`,
    CONTENT: `${DOMAIN_FAST_CASH_FAILED_CONFIRMATION}content`,
  },
  CANCEL_BUTTON: `${DOMAIN_FAST_CASH_FAILED_BUTTONS}cancel`,
  TRY_AGAIN_BUTTON: `${DOMAIN_FAST_CASH_FAILED_BUTTONS}again`,
};

const STEPS = {
  FIRST: {
    TITLE: `${DOMAIN_FAST_CASH_STEPS_FIRST}title`,
    CANCEL: `${DOMAIN_FAST_CASH_STEPS_FIRST}cancel`,
    NEXT: `${DOMAIN_FAST_CASH_STEPS_FIRST}next`,
  },
  SECOND: {
    TITLE: `${DOMAIN_FAST_CASH_STEPS_SECOND}title`,
    BACK: `${DOMAIN_FAST_CASH_STEPS_SECOND}back`,
    CANCEL: `${DOMAIN_FAST_CASH_STEPS_SECOND}cancel`,
    NEXT: `${DOMAIN_FAST_CASH_STEPS_SECOND}next`,
  },
};

export { TITLE, ERROR, SUCCESS, FAILED, STEPS };
