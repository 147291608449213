// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Enumerations
import { CharactersEnum } from '@openbank/cf-ui-static-data';
// Handlers
import CardDetailsWrapperHandlers from '../handlers/card-details-wrapper.handlers';
// Types
import { CardDetailsWrapperReturnType } from './types/card-details-wrapper-return.hook.type';

const CardDetailsWrapperHook = (): CardDetailsWrapperReturnType => {
  const [cardImage, setCardImage] = useState<string | undefined>(undefined);

  const { currentCard } = AppContextConsumerHook(),
    {
      cmsCardId = CharactersEnum.NO_VALUE,
      productType = { productFamily: CharactersEnum.NO_VALUE },
      productTypeCode = CharactersEnum.NO_VALUE,
    } = currentCard || {};

  const { handleCardImage } = CardDetailsWrapperHandlers({
    productTypeCode,
    setCardImage,
  });

  useEffect(() => {
    handleCardImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cardImage,
    cardNumber: cmsCardId,
    cardType: productType?.productFamily,
  };
};

export default CardDetailsWrapperHook;
