// Constants
import constants from '../constants/card-settings-activate-card.constants';
import serviceConstants from 'containers/card-settings/views/activate-card/services/constants/card-settings-activate-card.services.constants';
// Translations
import translations from '../translations/card-settings-activate-card-challenge.translations';
// Types
import { getNavigationFooterConfigurationReturnType } from './types/get-navigation-footer-configuration-return.type';
import { getNavigationFooterConfigurationType } from './types/get-navigation-footer-configuration.type';
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
import { GetChallengeConfigurationReturnType } from './types/get-challenge-configuration-return.type';

export const getChallengeConfiguration = ({
  cardId,
  challengeError,
  handleChallengeError,
  handleChallengeSuccess,
  handleChallengeRetryButtonClick,
}: GetChallengeConfigurationType): GetChallengeConfigurationReturnType => ({
  baseService: serviceConstants.endpoint,
  baseServiceBuilder: { pathParams: { cardId } },
  baseServiceMethod: constants.CARD_SETTINGS_CHALLENGE_PROPS.baseServiceMethod,
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});

export const getNavigationFooterConfiguration = ({
  challenge,
  formatMessage,
  handleActivateButtonClick,
  handleCancelLinkClick,
}: getNavigationFooterConfigurationType): getNavigationFooterConfigurationReturnType => ({
  testId: constants.CARD_SETTINGS_ACTIVATE_CARD_CHALLENGE_NAVIGATION_FOOTER_PROPS.testId,
  rightButtonConfiguration: {
    ...constants.CARD_SETTINGS_ACTIVATE_CARD_CHALLENGE_NAVIGATION_FOOTER_PROPS
      .rightButtonConfiguration,
    disabled: !challenge,
    onClick: handleActivateButtonClick,
    children: formatMessage({ id: translations.BUTTONS.ACTIVATE }),
  },
  leftLinkConfiguration: {
    ...constants.CARD_SETTINGS_ACTIVATE_CARD_CHALLENGE_NAVIGATION_FOOTER_PROPS
      .leftLinkConfiguration,
    onClick: handleCancelLinkClick,
    children: formatMessage({ id: translations.BUTTONS.CANCEL }),
  },
});
