// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { PPI_INFO_URL } from '../constants/card-settings-contract.constants';
// Types
import { PPIInfoResponseType } from './types/card-settings-contract-view-service.type';

const getPPIInfoService = (): Promise<[PPIInfoResponseType, Response]> =>
  apiUtilsCb.get({
    endpoint: PPI_INFO_URL,
  });

export { getPPIInfoService };
