// Types
import { FinancingPPIContractingStep2ConsentUtilsType } from './types/financing-ppi-contracting-step2-consent.utils.type';

const consentHasBeenChecked = ({
  checksDone,
  consentId,
}: Omit<FinancingPPIContractingStep2ConsentUtilsType, 'errorChecksDone'>): boolean =>
  checksDone.includes(consentId);

const getIfConsentHasError = ({
  checksDone,
  consentId,
  errorChecksDone,
}: FinancingPPIContractingStep2ConsentUtilsType): boolean =>
  !consentHasBeenChecked({ checksDone, consentId }) ? errorChecksDone : false;

export { consentHasBeenChecked, getIfConsentHasError };
