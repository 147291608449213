// Vendors
import styled, { css } from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

export const EmailOptionsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const EmailOptionsInputContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${({ theme }) => theme.spaces.spacingM};
  width: 50%;

  ${mobileMediaQuery} {
    width: 100%;
  }
`;
