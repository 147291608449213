// Vendors
import React from 'react';
// Components
import {
  SidebarComponent,
  ExpandableRowComponent,
  ParagraphComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/financing-payment-method-sidebar.constants';
// Styles
import {
  FinancingPaymentMethodSidebarComponentStyled,
  ExpandableRowContentStyled,
} from './financing-payment-method-sidebar.component.styled';
// Translations
import translations from './translations/financing-payment-method-sidebar.translations';

const FinancingPaymentMethodSidebarComponent = (): React.ReactElement => (
  <FinancingPaymentMethodSidebarComponentStyled>
    <SidebarComponent
      {...constants.SIDEBAR_PROPS}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      {constants.ROWS_PROPS.map(({ title, content }, i) => (
        <ExpandableRowComponent key={i} title={<FormattedMessageComponent id={title} />}>
          <ExpandableRowContentStyled>
            <ParagraphComponent {...constants.PARAGRAPH_PROPS}>
              <FormattedMessageComponent id={content} />
            </ParagraphComponent>
          </ExpandableRowContentStyled>
        </ExpandableRowComponent>
      ))}
    </SidebarComponent>
  </FinancingPaymentMethodSidebarComponentStyled>
);

export default FinancingPaymentMethodSidebarComponent;
