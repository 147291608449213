// Vendors
import React from 'react';
// Components
import { ConfirmationNextStepsComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getConfirmationNextStepsConfiguration } from './configurations/card-settings-block-card-success-content.component.configurations';
// Hooks
import CardSettingsBlockCardSuccessContentHook from './hooks/card-settings-block-card-success-content.hook';
// Styles
import { CardSettingsBlockCardSuccessContentComponentStyled } from './card-settings-block-card-success-content.component.styled';

const CardSettingsBlockCardSuccessContentComponent = (): React.ReactElement => {
  const { formatMessage } = CardSettingsBlockCardSuccessContentHook();

  return (
    <CardSettingsBlockCardSuccessContentComponentStyled>
      <ConfirmationNextStepsComponent
        {...getConfirmationNextStepsConfiguration({ formatMessage })}
      />
    </CardSettingsBlockCardSuccessContentComponentStyled>
  );
};

export default CardSettingsBlockCardSuccessContentComponent;
