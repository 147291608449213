// Services
import { change3DSecureCodeService } from '../services/card-settings-change-3d-step2.service';
// Types
import { CardSettingsChange3dStep2ApiBuilderType } from '../types/card-settings-change-3d-step2-api-builder.type';
// Utilities
import { requestRespondedNoContent } from '@openbank/cf-ui-framework';

const change3DSecureCodeResolver = async (
  props: CardSettingsChange3dStep2ApiBuilderType
): Promise<[number] | [null, number]> => {
  const [, { status }] = await change3DSecureCodeService(props);

  return requestRespondedNoContent(status) ? [status] : [null, status];
};

export { change3DSecureCodeResolver };
