// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Types
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';

const useCardSettingsStatementsTableRowDateHook = (): Pick<
  UseCbIntlReturnType,
  'formatDate' | 'formatMessage'
> => {
  const { formatDate, formatMessage } = useCbIntl();

  return { formatDate, formatMessage };
};

export { useCardSettingsStatementsTableRowDateHook };
