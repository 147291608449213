// Vendors
import React from 'react';
// Components
import { InputPrefixSuffixComponent } from '@openbank/cb-ui-commons';
import EditableInfoPanelComponent from 'containers/financing/views/credit-limit-increase/components/editable-info-panel/editable-info-panel.component';
import SectionCreditLimitIncreaseComponent from '../../components/section/section-credit-limit-increase.component';
// Configurations
import { getInfoPanelContentConfig } from './configurations/expenses-credit-limit-increase.configuration';
// Constants
import constants from '../../constants/credit-limit-increase.component.constants';
import { constants as sectionConstants } from './constants/expenses-credit-limit-increase.constants';
// Hooks
import ExpensesCreditLimitIncreaseHook from './hooks/expenses-credit-limit-increase.component.hook';
import useCbIntl from 'hooks/useCbIntl';
// Translations
import translations from './translations/expenses-credit-limit-increase.component.translations';
// Styles
import { CreditLimitIncreaseFormWrapper } from './expenses-credit-limit-increase.component.styled';
import { SizeOneInputStyled } from '../size-one-input.styled';

const ExpensesCreditLimitIncreaseComponent = (): React.ReactElement => {
  const { formatMessage, formatNumber } = useCbIntl();
  const {
    currency,
    isEditModeOn,
    monthlyRentalExpense,
    monthlyMortagageExpense,
    monthlyLoanExpense,
    onChangeMonthlyRentalExpense,
    onChangeMonthlyLoanExpense,
    onChangeMonthlyMortagageExpense,
    onClickSpendMonthlyOnRent,
    onClickSpendMonthlyOnLoans,
    onClickSpendMonthlyOnMortgages,
    setIsEditModeOn,
  } = ExpensesCreditLimitIncreaseHook();

  return (
    <SectionCreditLimitIncreaseComponent title={formatMessage({ id: translations.EXPENSES })}>
      {isEditModeOn ? (
        <CreditLimitIncreaseFormWrapper>
          <SizeOneInputStyled>
            <InputPrefixSuffixComponent
              {...constants.INPUT_PREFIX_SUFFIX_PROPS}
              label={formatMessage({ id: translations.MONTHLY_EXPENSES_ON_RENT })}
              value={monthlyRentalExpense}
              onFocus={onClickSpendMonthlyOnRent}
              onChange={onChangeMonthlyRentalExpense}
            />
          </SizeOneInputStyled>
          <SizeOneInputStyled>
            <InputPrefixSuffixComponent
              {...constants.INPUT_PREFIX_SUFFIX_PROPS}
              label={formatMessage({ id: translations.MONTHLY_EXPENSES_ON_LOANS })}
              value={monthlyLoanExpense}
              onFocus={onClickSpendMonthlyOnLoans}
              onChange={onChangeMonthlyLoanExpense}
            />
          </SizeOneInputStyled>
          <SizeOneInputStyled>
            <InputPrefixSuffixComponent
              {...constants.INPUT_PREFIX_SUFFIX_PROPS}
              label={formatMessage({ id: translations.MONTHLY_EXPENSES_ON_MORTGAGES })}
              value={monthlyMortagageExpense}
              onFocus={onClickSpendMonthlyOnMortgages}
              onChange={onChangeMonthlyMortagageExpense}
            />
          </SizeOneInputStyled>
        </CreditLimitIncreaseFormWrapper>
      ) : (
        <EditableInfoPanelComponent
          key={sectionConstants.CONTENT_TEST_ID}
          content={getInfoPanelContentConfig({
            currency,
            monthlyRentalExpense,
            monthlyLoanExpense,
            monthlyMortagageExpense,
            formatMessage,
            formatNumber,
          })}
          onEdit={setIsEditModeOn}
        />
      )}
    </SectionCreditLimitIncreaseComponent>
  );
};

export default ExpensesCreditLimitIncreaseComponent;
