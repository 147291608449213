// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import {
  SIMULATE_TRANSACTION_EPP_SERVICE_CONFIGURATION,
  SIMULATE_TRANSACTION_EPP_SERVICE_PARAMS,
} from './constants/transactions-epp-step1-simulation-options.service.constants';
// Types
import { TransactionsEppStep1SimulationApiBuilderType } from '../types/transactions-epp-step1-simulation-api-builder.type';
import { TransactionsEppStep1SimulationApiReturnType } from '../types/transactions-epp-step1-simulation-api-return.type';

const simulateTransactionInstallmentsPlanService = async ({
  cardId,
  ...params
}: TransactionsEppStep1SimulationApiBuilderType): Promise<
  [TransactionsEppStep1SimulationApiReturnType | null, Response]
> =>
  apiUtilsCb.post({
    ...SIMULATE_TRANSACTION_EPP_SERVICE_CONFIGURATION,
    pathParams: { cardId },
    params: { ...params, ...SIMULATE_TRANSACTION_EPP_SERVICE_PARAMS },
  });

export { simulateTransactionInstallmentsPlanService };
