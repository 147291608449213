// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import { FINANCING_PPI_CANCELLATION_LANDING_PRODUCT_COVERED_PROPS } from './constants/financing-ppi-cancellation-landing-resume.constants';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Hooks
import { useFinancingPPICancellationLandingResumeCoveredHook } from './hooks/financing-ppi-cancellation-landing-resume-covered.hook';
// Styles
import { Flex1ComponentStyled } from 'styles/app.styled';
// Translations
import {
  COVERED_UNTIL,
  COVERED,
} from './translations/financing-ppi-cancellation-landing-resume-covered.translations';
// Utilities
import { PPIIsOnTrialPeriod } from 'containers/financing/views/ppi/components/cancellation/components/landing/utils/financing-ppi-cancellation-landing.utils';
import { getNextCycleBillingPPIDate } from './utils/financing-ppi-cancellation-landing-resume-covered.utils';

const FinancingPPICancellationLandingResumeCoveredComponent = (): React.ReactElement | null => {
  const { formatDate, nextCycleDate, status } =
      useFinancingPPICancellationLandingResumeCoveredHook(),
    date = getNextCycleBillingPPIDate({ formatDate, nextCycleDate });

  return PPIIsOnTrialPeriod(status) ? null : (
    <Flex1ComponentStyled>
      <ResumeItemComponent
        {...FINANCING_PPI_CANCELLATION_LANDING_PRODUCT_COVERED_PROPS}
        label={<FormattedMessageComponent id={COVERED_UNTIL} />}
        value={<FormattedMessageComponent id={COVERED} values={{ date }} />}
      />
    </Flex1ComponentStyled>
  );
};

export { FinancingPPICancellationLandingResumeCoveredComponent };
