// Types
import { InternationalizationRegisterTranslationProviderType } from '../../types/internationalization-register-translation.provider.type';
import { PublicConfigurationType } from '../../types/public-configuration.type';

const isValidLanguageConfiguration = (
  response: InternationalizationRegisterTranslationProviderType | Record<string, any>
): boolean =>
  Boolean(response?.defaultLanguage && response?.downloadUrl && response?.lastChangedVersion);

const isValidPoeConfiguration = (
  response: PublicConfigurationType | Record<string, any>
): boolean => Boolean(response?.amazonTransactionsUrl && response?.config && response?.params);

export { isValidPoeConfiguration, isValidLanguageConfiguration };
