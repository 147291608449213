// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import RecoveryKeyStep5ActionsComponent from './components/actions/recovery-key-step5-actions.component';
import RecoveryKeyStep5ConstraintsComponent from './components/constraints/recovery-key-step5-constraints.component';
import RecoveryKeyStep5MessageComponent from './components/message/recovery-key-step5-message.component';
// Constants
import { RECOVERY_KEY_STEP_5_DESCRIPTION_PROPS } from './constants/recovery-key-step5.constants';
// Contexts
import { RecoveryKeyStep5ContextConsumerHOC } from './contexts/recovery-key-step5.context';
// Styles
import {
  RecoveryKeyStep5ComponentStyled,
  RecoveryKeyStep1ContentComponentStyled,
} from './recovery-key-step5.component.styled';
// Translations
import { DESCRIPTION } from './translations/recovery-key-step5.translations';

const RecoveryKeyStep5Component = (): React.ReactElement => (
  <RecoveryKeyStep5ComponentStyled>
    <ParagraphComponent {...RECOVERY_KEY_STEP_5_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <RecoveryKeyStep1ContentComponentStyled>
      <RecoveryKeyStep5ConstraintsComponent />
      <RecoveryKeyStep5MessageComponent />
    </RecoveryKeyStep1ContentComponentStyled>
    <RecoveryKeyStep5ActionsComponent />
  </RecoveryKeyStep5ComponentStyled>
);

export { RecoveryKeyStep5Component };
export default RecoveryKeyStep5ContextConsumerHOC(RecoveryKeyStep5Component);
