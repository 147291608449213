// Vendors
import React from 'react';
// Components
import { ExpandableRowComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep2ExpandableDetailsComponent } from './components/details/financing-ppi-contracting-step2-expandable-details.component';
import { FinancingPPIContractingStep2ExpandableCoveredComponent } from './components/covered/financing-ppi-contracting-step2-expandable-covered.component';
import { FinancingPPIContractingStep2ExpandableUncoveredComponent } from './components/uncovered/financing-ppi-contracting-step2-expandable-uncovered.component';
import { FinancingPPIContractingStep2ExpandableLimitationsComponent } from './components/limitations/financing-ppi-contracting-step2-expandable-limitations.component';
import { FinancingPPIContractingStep2ExpandableLimitComponent } from './components/limit/financing-ppi-contracting-step2-expandable-limit.component';
import { FinancingPPIContractingStep2ExpandableTerminateComponent } from './components/terminate/financing-ppi-contracting-step2-expandable-terminate.component';
import { FinancingPpiContractingStep2ExpandableCircumstancesComponent } from './components/circumstances/financing-ppi-contracting-step2-expandable-circumstances.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_CONTENT_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_PROPS,
} from './constants/financing-ppi-contracting-step2-expandable.constants';
import { FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE } from '../../constants/financing-ppi-contracting-step2.constants';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
// Translations
import { MORE_INFORMATION } from './translations/financing-ppi-contracting-step2.translations';

const FinancingPpiContractingStep2ExpandableComponent = (): React.ReactElement => (
  <ExpandableRowComponent
    {...FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_PROPS}
    title={
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE}>
        <FormattedMessageComponent id={MORE_INFORMATION} />
      </ParagraphComponent>
    }
  >
    <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_STEP_2_EXPANDABLE_CONTENT_PROPS}>
      <FinancingPPIContractingStep2ExpandableDetailsComponent />
      <FinancingPPIContractingStep2ExpandableCoveredComponent />
      <FinancingPPIContractingStep2ExpandableUncoveredComponent />
      <FinancingPPIContractingStep2ExpandableLimitationsComponent />
      <FinancingPPIContractingStep2ExpandableLimitComponent />
      <FinancingPPIContractingStep2ExpandableTerminateComponent />
      <FinancingPpiContractingStep2ExpandableCircumstancesComponent />
    </LayoutColumnFlexComponentStyled>
  </ExpandableRowComponent>
);

export { FinancingPpiContractingStep2ExpandableComponent };
