// Vendors
import styled from 'styled-components';

export const TransactionsReportFraudulentMovementFirstStepOptionsComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;

export const TransactionsReportFraudulentMovementFirstStepOptionsBoxContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const TransactionsReportFraudulentMovementFirstStepOptionsTitleStyled = styled.div`
  align-items: stretch;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  margin-bottom: ${({ theme }) => theme.spaces.spacingS};
  justify-content: flex-start;
  padding: 0;
`;
