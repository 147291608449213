// Vendors
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Components
import { FinancingInsurancesCancellationLPIComponent } from '../cancellation/lpi/financing-insurances-cancellation-lpi.component';
import { FinancingInsurancesContractingLPIComponent } from '../contracting/lpi/financing-insurances-contracting-lpi.component';
import { FinancingInsurancesRouterCancelComponent } from './components/cancel/financing-insurances-router-cancel.component';
import { FinancingInsurancesRouterContractComponent } from './components/contract/financing-insurances-router-contract.component';
import { FinancingInsurancesRouterInsuranceComponent } from './components/insurance/financing-insurances-router-insurance.component';
import { FinancingPPICancellationComponent } from '../cancellation/financing-ppi-cancellation.component';
import { FinancingPPIContractingComponent } from '../contracting/financing-ppi-contracting.component';
import { FinancingPPILandingComponent } from '../landing/financing-ppi-landing.component';
// Constants
import {
  FINANCING_ANY_ROUTE_PROPS,
  LANDING_ROUTE,
} from './constants/financing-insurances.constants';
// Contexts
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

export const FinancingInsurancesRouterComponent = (): React.ReactElement => {
  const { insurancesInfo } = useInsurancesContextConsumerHook();

  if (!insurancesInfo) {
    return <Navigate to={LANDING_ROUTE} />;
  }

  const { CPI, LPI } = insurancesInfo.insurances;

  return (
    <Routes>
      <Route
        element={
          <FinancingInsurancesRouterInsuranceComponent
            insurance={LPI}
            featureFlag={FeatureFlagsEnumeration.INSURANCES_LPI}
          />
        }
      >
        <Route element={<FinancingInsurancesRouterCancelComponent status={LPI.status} />}>
          <Route
            element={<FinancingInsurancesCancellationLPIComponent />}
            path={RoutesEnumeration.LPI_CANCELLATION}
          />
        </Route>
        <Route element={<FinancingInsurancesRouterContractComponent status={LPI.status} />}>
          <Route
            element={<FinancingInsurancesContractingLPIComponent />}
            path={RoutesEnumeration.LPI_CONTRACTING}
          />
        </Route>
      </Route>
      <Route
        element={
          <FinancingInsurancesRouterInsuranceComponent
            insurance={CPI}
            featureFlag={FeatureFlagsEnumeration.INSURANCES_CPI}
          />
        }
      >
        <Route element={<FinancingInsurancesRouterCancelComponent status={CPI.status} />}>
          <Route
            element={<FinancingPPICancellationComponent />}
            path={RoutesEnumeration.CPI_CANCELLATION}
          />
        </Route>
        <Route element={<FinancingInsurancesRouterContractComponent status={CPI.status} />}>
          <Route
            element={<FinancingPPIContractingComponent />}
            path={RoutesEnumeration.CPI_CONTRACTING}
          />
        </Route>
      </Route>
      <Route element={<FinancingPPILandingComponent />} index />
      <Route {...FINANCING_ANY_ROUTE_PROPS} element={<Navigate to={LANDING_ROUTE} />} />
    </Routes>
  );
};
