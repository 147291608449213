// Enumerations
import { CardStatusTypesEnumeration } from 'enumerations/card-status-types.enumeration';

const DOMAIN = 'cobranded.services.userSettings.privateArea.cardStatus.cbMessage.';

export default {
  DESCRIPTIONS: {
    [CardStatusTypesEnumeration.BLOCKED]: `${DOMAIN}blockedDescription`,
    [CardStatusTypesEnumeration.CANCELLED]: `${DOMAIN}cancelledDescription`,
    [CardStatusTypesEnumeration.INACTIVE]: `${DOMAIN}inactiveDescription`,
    [CardStatusTypesEnumeration.LOCKED]: `${DOMAIN}lockedDescription`,
    [CardStatusTypesEnumeration.TEMPORARILY_BLOCKED]: `${DOMAIN}temporaryBlockedDescription`,
  },
  TITLES: {
    [CardStatusTypesEnumeration.BLOCKED]: `${DOMAIN}blockedTitle`,
    [CardStatusTypesEnumeration.CANCELLED]: `${DOMAIN}cancelledTitle`,
    [CardStatusTypesEnumeration.INACTIVE]: `${DOMAIN}inactiveTitle`,
    [CardStatusTypesEnumeration.LOCKED]: `${DOMAIN}lockedTitle`,
    [CardStatusTypesEnumeration.TEMPORARILY_BLOCKED]: `${DOMAIN}temporaryBlockedTitle`,
  },
};
