// Enumerations
import { RevolvingMethodsEnumeration } from 'containers/financing/views/payment-method/enumerations/revolving-methods.enumeration';
// Constants
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
import { EURO_FORMAT_OPTIONS } from 'containers/financing/views/payment-method/constants/financing-payment-method.constants';
// Types
import { FormatSliderCurrencyType } from './types/format-slider-currency.type';

const formatSliderCurrency = ({
  formatNumber,
  revolvingMethod,
  value,
}: FormatSliderCurrencyType): string => {
  const formattingOptions = {
    [RevolvingMethodsEnumeration.FIX_AMOUNT]: formatNumber(value, EURO_FORMAT_OPTIONS),
    [RevolvingMethodsEnumeration.PERCENTAGE_AMOUNT]: formatNumber(value / 100, {
      style: MiscellaneousCurrencyConstants.AMOUNT_PERCENTAGE_FORMAT_PROPS.style,
    }),
  };

  return formattingOptions[revolvingMethod] || formatNumber(value);
};

export { formatSliderCurrency };
