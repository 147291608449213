// Vendors
import React from 'react';
// Components
import {
  FetchErrorComponent,
  RowToggleComponent,
  SectionDividerComponent,
} from '@openbank/cb-ui-commons';
import { NotificationSettingsCategoryComponent } from './components/category/notifications-settings-category.component';
// Constants
import {
  DEFAULT_TEST_ID,
  FETCH_ERROR_TEST_ID,
  SECTION_DIVIDER_TESTING,
  ROW_TOGGLE_CONFIGURATION,
} from './constants/notifications-settings-list.constants';
import {
  SWITCH_ON,
  SWITCH_OFF,
} from './components/category/translations/notifications-settings-category.translations';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Enumerations
import { SectionDividerTypesEnumeration } from '@openbank/cb-ui-commons';
// Styles
import {
  NotificactionsSettingsListComponentStyled,
  NotificactionsSettingsListToggleComponentStyled,
  NotificactionsSettingsListSectionDividerWrapperStyled,
} from './notifications-settings-list.component.styled';
// Translations
import {
  SECTION_TITLE,
  ROW_TOGGLE_TITLE,
} from './translations/notifications-settings-list.translations';
// Types
import { NotificationSettingsListPropsType } from './types/notifications-settings-list.component.type';

const NotificationSettingsListComponent = ({
  error,
  fetching,
  formatMessage,
  formatNumber,
  handleControllerChange,
  handleToggleAllNotifications,
  handleToggleNotification,
  notificationsSettings,
}: NotificationSettingsListPropsType): React.ReactElement => {
  return (
    <NotificactionsSettingsListComponentStyled {...{ ...DEFAULT_TEST_ID }}>
      <FetchErrorComponent
        {...{
          ...FETCH_ERROR_TEST_ID,
          fetching,
          error: getFetchErrorConfiguration({
            error,
            formatMessage,
          }),
        }}
      >
        <NotificactionsSettingsListSectionDividerWrapperStyled>
          <SectionDividerComponent
            {...{ ...SECTION_DIVIDER_TESTING }}
            type={SectionDividerTypesEnumeration.INVERTED}
            title={formatMessage({ id: SECTION_TITLE })}
          />
        </NotificactionsSettingsListSectionDividerWrapperStyled>
        <NotificactionsSettingsListToggleComponentStyled>
          <RowToggleComponent
            title={formatMessage({ id: ROW_TOGGLE_TITLE })}
            toggleConfiguration={{
              labelOn: formatMessage({ id: SWITCH_ON }),
              labelOff: formatMessage({ id: SWITCH_OFF }),
              ...ROW_TOGGLE_CONFIGURATION,
              onChange: handleToggleAllNotifications,
              defaultChecked: notificationsSettings?.allEnabled,
            }}
          />
        </NotificactionsSettingsListToggleComponentStyled>
        <NotificationSettingsCategoryComponent
          {...{
            formatMessage,
            formatNumber,
            handleControllerChange,
            handleToggleNotification,
            notificationsSettings,
          }}
        />
      </FetchErrorComponent>
    </NotificactionsSettingsListComponentStyled>
  );
};

export { NotificationSettingsListComponent };
