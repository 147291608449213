// Vendors
import { apiUtils } from '@openbank/cf-ui-architecture-core';
// Constants
import { UPDATE_CONSENTS_ENDPOINT } from './constants/profile-consents-step2.service.constants';
// Types
import { ApiUtilsReturnType } from 'utils/validate-type/types/validate-type.types';
import { UpdateConsentsPropsType } from './types/update-consents-props.type';
import { ChallengeType } from 'types/challenge.type';

export const updateConsentsService = (
  params: UpdateConsentsPropsType
): Promise<ApiUtilsReturnType<ChallengeType | Record<string, unknown>>> =>
  apiUtils.patch({
    endpoint: UPDATE_CONSENTS_ENDPOINT,
    pathParams: { arrangementId: params.arrangementId },
    ...(params.progressId ? { customHeaders: { progressId: params.progressId } } : {}),
    params: params.body,
  });
