// Vendors
import { useEffect, useState } from 'react';
// Enums
import { DeviceModalEnum } from '../enumerations/device-modal.enumeration';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Handlers
import { DeviceModalHandlers } from '../handlers/device-modal.handlers';
import { DeviceModalTrackingHandlers } from '../handlers/device-modal.tracking.handlers';
// Types
import { DeviceModalHookReturnType } from './types/device-modal.hook.return.type';
import { DeviceModalHookType } from './types/device-modal.hook.type';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';

const DeviceModalHook = ({
  type,
  onAccept,
  onClose,
  device
}: DeviceModalHookType): DeviceModalHookReturnType => {
  const { formatMessage } = useCbIntl();
  const [challenged, setChallenged] = useState<boolean>(type === DeviceModalEnum.UNROLL_TRUSTED);
  const [solvedChallenged, setSolvedChallenged] = useState<boolean>(type !== DeviceModalEnum.UNROLL_TRUSTED);
  const [progressId, setProgressId] = useState<string>('');
  
  const {
    handleManageErrorUnrollTrustedDeviceEvent,
    handleManageSuccessUnrollTrustedDeviceEvent,
    handleOnAccept,
    onModalOpen,
    onLinkClose,
    onCrossClose
  } = DeviceModalHandlers({
    ...AppTrackingHook(DeviceModalTrackingHandlers),
    type,
    progressId,
    setChallenged,
    setSolvedChallenged,
    setProgressId,
    onAccept,
    onClose,
    device
  });

  useEffect(() => { onModalOpen(); }, []);

  return {
    formatMessage,
    challenged,
    solvedChallenged,
    handleManageErrorUnrollTrustedDeviceEvent,
    handleManageSuccessUnrollTrustedDeviceEvent,
    handleOnAccept,
    onLinkClose,
    onCrossClose,
  };
};

export { DeviceModalHook };