// Types
import {
  TransactionsEppStep1SimulationOptionsAllBuilderHandlersType,
  TransactionsEppStep1SimulationOptionsAllMethodBuilderHandlersType,
  TransactionsEppStep1SimulationOptionsAllMethodsHandlersType,
} from './types/transactions-epp-step1-simulation-options-all-slider.handlers.type';
// Utilities
import { allOptionsValueIsOutAllowedRange } from '../utils/transactions-epp-step1-simulation-options-all-slider.utils';

const changeAllOptionsSliderChangeEventHandler = ({
  handleChangeAllOptionsSliderChangeEventTracking,
  onChange,
  maxNumOfInstallments,
  minNumOfInstallments,
  setInstallments,
  value,
}: TransactionsEppStep1SimulationOptionsAllMethodBuilderHandlersType): void => {
  handleChangeAllOptionsSliderChangeEventTracking(value);
  setInstallments(value);
  !allOptionsValueIsOutAllowedRange({
    defaultValue: value,
    min: minNumOfInstallments,
    max: maxNumOfInstallments,
  }) && onChange(value);
};

const TransactionsEppStep1SimulationOptionsAllSliderHandlers = (
  props: TransactionsEppStep1SimulationOptionsAllBuilderHandlersType
): TransactionsEppStep1SimulationOptionsAllMethodsHandlersType => ({
  handleChangeAllOptionsSliderChangeEvent: (value: number) =>
    changeAllOptionsSliderChangeEventHandler({ ...props, value }),
});

export { TransactionsEppStep1SimulationOptionsAllSliderHandlers };
