// Vendors
import { useParams } from 'react-router-dom';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { useTransactionsReportNotReceivedContextConsumerHook } from 'containers/transactions/views/report/components/not-received/contexts/transactions-report-not-received.context';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { TransactionsReportNotReceivedConfirmationComponentHookReturnType } from './types/transactions-report-not-received-confirmation-component-hook-return.type';

export const useTransactionsReportNotReceivedConfirmationHook =
  (): TransactionsReportNotReceivedConfirmationComponentHookReturnType => {
    const { formatMessage } = useCbIntl();
    const { notReceivedDate, description, files } =
      useTransactionsReportNotReceivedContextConsumerHook();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    return {
      cardId,
      description,
      files,
      formatMessage,
      notReceivedDate,
      transactionId,
    };
  };
