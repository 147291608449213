// Vendors
import React from 'react';
// Components
import { InputTextAreaComponent } from '@openbank/cb-ui-commons';
// Constants
import { NUMBER } from 'constants/number.constants';
import { MAX_SELECTED_OPTION_LENGHT } from './constants/extra-information-input-component.constants';
// Hooks
import { useExtraInformationHook } from './hooks/extra-information-input.hook';
import { TransactionsReportFraudulentMovementConsumerHook } from 'containers/transactions/views/report/components/fraudulent-movement/contexts/transactions-report-fraudulent-movement.context';
// Translations
import {
  ERROR_MESSAGE,
  LABEL,
  PLACEHOLDER,
} from './translations/extra-information-input.translations';

const ExtraInformationInputComponent = (): React.ReactElement => {
  const { selectedOptions, setSelectedOptions } =
    TransactionsReportFraudulentMovementConsumerHook();

  const { value, formatMessage, handleChangeInputEvent } = useExtraInformationHook({
    selectedOptions,
    setSelectedOptions,
  });

  return (
    <InputTextAreaComponent
      errorMessage={selectedOptions.length >= MAX_SELECTED_OPTION_LENGHT ? ERROR_MESSAGE : ''}
      isAlternative={false}
      label={formatMessage({ id: LABEL })}
      onChange={handleChangeInputEvent}
      placeholder={formatMessage({ id: PLACEHOLDER })}
      value={value}
      limitCharsConfiguration={{ limit: NUMBER.SIX_HUNDRED }}
    />
  );
};

export { ExtraInformationInputComponent };
