export default {
  REQUEST_PORTABILITY_STEP_3_REQUEST_RESUME_ITEM_PROPS: {
    testing: {
      labelId: 'request-portability-step3-request-item-label',
      valueId: 'request-portability-step3-request-item-value',
    },
  },
  REQUEST_PORTABILITY_STEP_3_COST_RESUME_ITEM_PROPS: {
    testing: {
      labelId: 'request-portability-step3-cost-item-label',
      valueId: 'request-portability-step3-cost-item-value',
    },
  },
  REQUEST_PORTABILITY_STEP_3_MESSAGE_PROPS: {
    showIcon: false,
    testId: 'request-portability-message',
    type: 'informative',
  },
};
