// Vendors
import React from 'react';
// Components
import IvrComponentWrapperCC from 'components/ivr-cc/ivr-component-wrapper-cc.component';
import WizardComponent from 'components/wizard/wizard.component';
import SecurityKeysSignatureKeyChangeSidebarComponent from './components/sidebar/security-keys-signature-key-change-sidebar.component';
// Configurations
import SECURITY_KEYS_SIGNATURE_CHANGE_CONSTANTS from './components/cc/constants/security-keys-signature-key-change.content.constants.cc';
import { getWizardProps } from './configurations/security-keys-signature-key-change.configurations';
// Contexts
import { SignatureKeyChangeContextConsumerHOC } from './contexts/security-keys-signature-key-change.context';
// Hooks
import { SecurityKeysSignatureKeyChangeHook } from './hooks/security-keys-signature-key-change.hook';
// Styles
import {
  SecurityKeysSignatureKeyChangeContainerStyled,
  SecurityKeysSignatureKeyChangeContentContainerStyled,
  SecurityKeysSignatureKeyChangeContentLeftComponentStyled,
  SecurityKeysSignatureKeyChangeGridContentRightComponentStyled,
} from './security-keys-signature-key-change.view.styled';
// Utils
import { isCC } from 'utils/app.utils';

const SecurityKeysSignatureKeyChangeView = (): React.ReactElement => {
  const { fromPersonal } = SecurityKeysSignatureKeyChangeHook();

  return (
    <SecurityKeysSignatureKeyChangeContainerStyled>
      <SecurityKeysSignatureKeyChangeContentContainerStyled>
        <SecurityKeysSignatureKeyChangeContentLeftComponentStyled>
          {isCC() ? (
            <IvrComponentWrapperCC {...SECURITY_KEYS_SIGNATURE_CHANGE_CONSTANTS} />
          ) : (
            <WizardComponent {...getWizardProps({ fromPersonal })} />
          )}
        </SecurityKeysSignatureKeyChangeContentLeftComponentStyled>
        <SecurityKeysSignatureKeyChangeGridContentRightComponentStyled>
          <SecurityKeysSignatureKeyChangeSidebarComponent />
        </SecurityKeysSignatureKeyChangeGridContentRightComponentStyled>
      </SecurityKeysSignatureKeyChangeContentContainerStyled>
    </SecurityKeysSignatureKeyChangeContainerStyled>
  );
};

export default SignatureKeyChangeContextConsumerHOC(SecurityKeysSignatureKeyChangeView);
