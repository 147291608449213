// Vendors
import React from 'react';
// Contexts
import { SettingsCardControlLocationsContextConsumerHook } from 'containers/card-settings/views/card-control/components/locations/contexts/settings-card-control-locations.context';
// Components
import SettingsCardControlLocationsFilteredComponent from '../filtered/settings-card-control-locations-filtered.component';
import SettingsCardControlLocationsRawComponent from '../raw/settings-card-control-locations-raw.component';

const SettingsCardControlLocationsOrchestratorComponent = (): React.ReactElement | null => {
  const { keyword } = SettingsCardControlLocationsContextConsumerHook();

  switch (true) {
    case !keyword:
      return <SettingsCardControlLocationsRawComponent />;
    case keyword.length > 0:
      return <SettingsCardControlLocationsFilteredComponent />;
    default:
      return null;
  }
};

export default SettingsCardControlLocationsOrchestratorComponent;
