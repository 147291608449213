const DOMAIN =
  'cobranded.services.userSettings.privateArea.cbChallengeModule.signatureKeyChallenge.';

export default {
  BUTTON: `${DOMAIN}sendButton`,
  DESCRIPTION: `${DOMAIN}description`,
  ERROR: `${DOMAIN}errorDescription`,
  HELPER: `${DOMAIN}requestNewCodeDescription`,
  REQUEST_LINK: `${DOMAIN}requestNewCodeLink`,
  PLACEHOLDER: `${DOMAIN}cbInput.inputOtpPlaceholder`,
  TITLE: `${DOMAIN}title`,
};
