// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import WizardFeedbackHostComponent from '../host/wizard-feedback-host.component';
// Constants
import {
  HAS_LEFT_LINK,
  WIZARD_FEEDBACK_ERROR_PROPS,
} from './constants/wizard-feedback-error.constants';
// Hooks
import useWizardFeedbackHook from './hooks/wizard-feedback-error.hook';
// Types
import { WizardFeedbackResultComponentType } from '../../types/wizard-feedback-result.component.type';
// Translations
import { CANCEL, TRY_AGAIN } from './translations/wizard-feedback-error.translations';

const WizardFeedbackErrorComponent = ({
  buttonClick,
  exitRoute,
  linkClick,
  linkLabelId,
  buttonLabelId,
  hasDefaultLeftLink = HAS_LEFT_LINK,
  ...rest
}: WizardFeedbackResultComponentType): React.ReactElement<WizardFeedbackResultComponentType> => {
  const { handleWizardFeedbackErrorLinkClick, handleWizardFeedbackErrorButtonClick } =
    useWizardFeedbackHook({ buttonClick, linkClick, exitRoute });

  return (
    <WizardFeedbackHostComponent
      {...{ ...WIZARD_FEEDBACK_ERROR_PROPS, ...rest }}
      leftLinkConfiguration={
        hasDefaultLeftLink
          ? {
              children: <FormattedMessageComponent id={linkLabelId ?? CANCEL} />,
              onClick: handleWizardFeedbackErrorLinkClick,
            }
          : undefined
      }
      rightButtonConfiguration={{
        children: <FormattedMessageComponent id={buttonLabelId ?? TRY_AGAIN} />,
        onClick: handleWizardFeedbackErrorButtonClick,
      }}
    />
  );
};

export default WizardFeedbackErrorComponent;
