// Types
import { DownloadDocumentResponse } from './types/download-file.type';

export const downloadFile = (resObj: DownloadDocumentResponse, type = 'application/pdf'): void => {
  const base64 = resObj.blob;
  // Decodifica el base64 a un ArrayBuffer
  const binaryString = window.atob(base64.toString());
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  if (type === 'application/pdf') {
    const newBlob = new Blob([bytes], { type });
    const objUrl = window.URL.createObjectURL(newBlob);

    window.open(objUrl, '_blank');
    setTimeout(() => {
      window.URL.revokeObjectURL(objUrl);
    }, 250);
  }

  const newBlob = new Blob([bytes], { type });
  const objUrl = window.URL.createObjectURL(newBlob);

  const link = document.createElement('a');
  link.href = objUrl;
  link.download = resObj.filename;
  link.click();

  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
};
