// Vendors
import { useState } from 'react';
// Handlers
import { FinancingInsurancesLandingCardHandlers } from '../handlers/financing-insurances-landing-card.handlers';
import { FinancingInsurancesLandingCardTrackingHandlers } from '../handlers/financing-insurances-landing-card.handlers.tracking';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
import useCbIntl from 'hooks/useCbIntl';
// Types
import { FinancingInsurancesLandingCardHookReturnType } from './types/financing-insurances-landing-card.hook.type';

export const useFinancingInsurancesLandingCard =
  (): FinancingInsurancesLandingCardHookReturnType => {
    const [isContractModalOpen, setIsContractModalOpen] = useState(false);

    const { formatDate, formatMessage } = useCbIntl();

    return {
      ...FinancingInsurancesLandingCardHandlers({
        ...AppTrackingHook(FinancingInsurancesLandingCardTrackingHandlers),
        setIsContractModalOpen,
      }),
      formatDate,
      formatMessage,
      isContractModalOpen,
    };
  };
