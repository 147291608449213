// Vendors
import React from 'react';
// Components
import { ChallengeSmsOtpComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { CONTENT_PARAGRAPH_PROPS } from 'organisms/table-organism/components/transactions-table/components/security-check-modal/components/second-step/constants/security-check-modal.constants';
// Hooks
import { ChallengeDownloadWrapperComponentHook } from './hooks/challenge-download-wrapper.hook';
// Styles
import { ChallengeDownloadWrapperComponentStyled } from './challenge-download-wrapper.component.styled';
// Translations
import * as translationsOtp from 'translations/challenge-sms.translations';
import { CONTENT } from 'organisms/table-organism/components/transactions-table/components/security-check-modal/components/second-step/translations/security-check-modal-second-step.translations';
import challengeCommonTranslations from '@/translations/challenge-common.translations';
// Types
import { ChallengeDownloadWrapperPropsType } from './types/challenge-download-wrapper.type';
// Utilties
import { translateLabels } from 'utils/translations.utils';
import { makeChunkBoldIntlUtil } from 'hooks/useCbIntl/utils';

const ChallengeDownloadWrapperComponent = ({
  challenge,
  challenged,
  challengeError,
  otpId,
  otpValue,
  setChallenge,
  setChallenged,
  setChallengeError,
  setIsDownloadAvailable,
  setOtpId,
  setOtpValue,
}: ChallengeDownloadWrapperPropsType): React.ReactElement => {
  const {
    disabled,
    fetching,
    formatMessage,
    handleResendOtp,
    handleSendChallenge,
    handleTryAgain,
    isTimerActive,
    onChangeChallenge,
    telephones,
    timer,
    timerLabelTranslationId,
    value,
  } = ChallengeDownloadWrapperComponentHook({
    challenge,
    challenged,
    challengeError,
    otpId,
    otpValue,
    setChallenge,
    setChallenged,
    setChallengeError,
    setIsDownloadAvailable,
    setOtpId,
    setOtpValue,
  });

  return (
    <ChallengeDownloadWrapperComponentStyled>
      <ParagraphComponent {...CONTENT_PARAGRAPH_PROPS}>
        <FormattedMessageComponent id={CONTENT} values={{ phone: telephones[0]?.phone ?? '' }} />
      </ParagraphComponent>
      <ChallengeSmsOtpComponent
        {...{ isTimerActive, value }}
        disabled={disabled}
        error={challengeError}
        handlers={{
          buttonHandler: handleSendChallenge,
          changeHandler: onChangeChallenge,
          requestHandler: handleResendOtp,
          tryAgainHandler: handleTryAgain,
        }}
        translations={{
          ...translateLabels({
            formatMessage,
            translations: translationsOtp,
          }),
          ERROR: {
            BUTTON: formatMessage({ id: challengeCommonTranslations.ERROR.BUTTON }),
            DESCRIPTION: formatMessage({ id: challengeCommonTranslations.ERROR.DESCRIPTION }),
          },
          REQUEST_NEW_CODE: formatMessage(
            { id: timerLabelTranslationId },
            { timer, b: makeChunkBoldIntlUtil }
          ),
        }}
      />
    </ChallengeDownloadWrapperComponentStyled>
  );
};

export { ChallengeDownloadWrapperComponent };
