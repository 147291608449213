// Types
import { TimerBuilderReturnHandlersType } from './types/use-timer-builder-return.handlers.type';
import { TimerBuilderHandlersType } from './types/use-timer-builder.handlers.type';

const restartTimer = ({
  initialTime,
  setTimer,
  setIsTimerActive,
}: TimerBuilderHandlersType): any => {
  setTimer(initialTime);
  setIsTimerActive(true);
};

const TimerHandlers = (props: TimerBuilderHandlersType): TimerBuilderReturnHandlersType => ({
  handleRestart: () => restartTimer(props),
});

export default TimerHandlers;
