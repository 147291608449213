// Constants
import { PLACE_PREDICTIONS_REQUEST_PROPS } from './constants/use-address-predictions.handler.constants';
// Types
import {
  GetPredictionHandlerPropsType,
  GetPredictionHandlerReturnType,
} from './types/get-predictions.handler.type';

export const getPredictionsHandler = async ({
  autocompleteService,
  minTriggerLength,
  query,
  setIsLoading,
  setPredictions,
}: GetPredictionHandlerPropsType): GetPredictionHandlerReturnType => {
  try {
    if (query.length < minTriggerLength) throw new Error();

    setIsLoading(true);

    const response = await autocompleteService.current.getPlacePredictions({
      input: query,
      ...PLACE_PREDICTIONS_REQUEST_PROPS,
    });

    setPredictions(response.predictions.map(prediction => prediction.description));
  } catch (error) {
    setPredictions([]);
  } finally {
    setIsLoading(false);
  }
};
