// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Types
import { CardSettingsBlockCardReplacementModalReturnHookType } from './types/card-settings-block-card-replacement-modal-return.hook.type';

const CardSettingsBlockCardReplacementModalHook =
  (): CardSettingsBlockCardReplacementModalReturnHookType => {
    const { formatMessage } = useCbIntl();

    return {
      formatMessage,
    };
  };

export default CardSettingsBlockCardReplacementModalHook;
