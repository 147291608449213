// Vendors
import React from 'react';
// Components
import { UploadDocumentComponent } from '@openbank/cb-ui-commons';
// Enumerations
import { UploadDocumentComponentStateEnum } from '../../enums/upload-document-component-state.enumeration';
// Hooks
import { UploadDocumentCreditLimitIncreaseHook } from './hooks/upload-document-credit-limit-increase.hook';
// Types
import { UploadDocumentCreditLimitIncreaseComponentType } from './types/upload-document-credit-limit-increase.component.type';

const UploadDocumentCreditLimitIncreaseComponent = ({
  id,
  title,
  isRejected,
}: UploadDocumentCreditLimitIncreaseComponentType): React.ReactElement => {
  const {
    BACK_OFFICE_ERROR_LINK,
    BACK_OFFICE_ERROR_MESSAGE,
    BACK_OFFICE_ERROR_TAG,
    DEFAULT_TEXT,
    DEFAULT_LINK,
    LOADING_LINK,
    SYSTEM_ERROR_LINK,
    SYSTEM_ERROR_MESSAGE,
    SYSTEM_ERROR_TAG,
    UPLOADED_LINK,
    REJECTED_LINK,
    REJECTED_MESSAGE,
    REJECTED_TAG,
    REJECTED_TEXT,
    state,
    file,
    setFile,
    onCancelUpload,
    onRemoveUploadedFile,
    onRetryUpload,
  } = UploadDocumentCreditLimitIncreaseHook(id);

  return (
    <UploadDocumentComponent
      inputId={`${id}-upload-document--input`}
      testId={`${id}-upload-document--test-id`}
      state={isRejected && !file ? UploadDocumentComponentStateEnum.REJECTED : state}
      file={file}
      setFile={setFile}
      onCancelUpload={onCancelUpload}
      onRemoveUploadedFile={onRemoveUploadedFile}
      onRetryUpload={onRetryUpload}
      translations={{
        BACK_OFFICE_ERROR_LINK,
        BACK_OFFICE_ERROR_MESSAGE,
        BACK_OFFICE_ERROR_TAG,
        DEFAULT_TEXT,
        DEFAULT_LINK,
        LOADING_LINK,
        SYSTEM_ERROR_LINK,
        SYSTEM_ERROR_MESSAGE,
        SYSTEM_ERROR_TAG,
        UPLOADED_LINK,
        REJECTED_LINK,
        REJECTED_MESSAGE,
        REJECTED_TAG,
        REJECTED_TEXT,
        TITLE: title,
      }}
    />
  );
};

export { UploadDocumentCreditLimitIncreaseComponent };
