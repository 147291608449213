// Constants
import constants from 'constants/app.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Utils
import { isDemo, isDev, isStg } from 'utils/app.utils';

const getAggregationDomain = (): string => {
  if (isDev()) {
    return constants.LOCALHOST_URL;
  }
  if (isStg()) {
    return constants.STG_URL;
  }
  if (isDemo()) {
    return constants.DEMO_URL;
  }
  return constants.PROD_URL;
};

export const getAggregationCallback = (): string => {
  return `${getAggregationDomain()}/${RoutesEnumeration.FINANCING}/${
    RoutesEnumeration.CREDIT_LIMIT_INCREASE
  }`;
};
