// Vendors
import React from 'react';
import { MessageComponent, ResumeItemComponent } from '@openbank/cb-ui-commons';
// Contexts
import { AccessPortabilityRequestStep2ContextConsumerHOC } from './contexts/access-portability-request-step2.context';
// Components
import AccessPortabilityRequestStep2ActionsComponent from './components/actions/request-access-step2-actions.component';
import RequestAccessStep2ChallengeComponent from './components/challenge/request-access-step2-challenge.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import constants from './constants/access-portability-request-step2.constants';
// Styles
import {
  AccessPortabilityRequestStep2ComponentStyled,
  AccessPortabilityRequestStep2MessageItemStyled,
  AccessPortabilityRequestStep2MessageStyled,
} from './access-portability-request-step2.component.styled';
// Translations
import translations from './translations/access-portability-request-step2.translations';

const AccessPortabilityRequestStep2Component = (): React.ReactElement => (
  <AccessPortabilityRequestStep2ComponentStyled>
    <MessageComponent {...constants.ACCESS_PORTABILITY_REQUEST_STEP_2_MESSAGE_PROPS}>
      <AccessPortabilityRequestStep2MessageStyled>
        <AccessPortabilityRequestStep2MessageItemStyled>
          <ResumeItemComponent
            {...constants.ACCESS_PORTABILITY_REQUEST_STEP_2_REQUEST_RESUME_ITEM_PROPS}
            label={<FormattedMessageComponent id={translations.REQUESTING_LABEL} />}
            value={<FormattedMessageComponent id={translations.ACCESS_RIGHTS_LABEL} />}
          />
        </AccessPortabilityRequestStep2MessageItemStyled>
        <AccessPortabilityRequestStep2MessageItemStyled>
          <ResumeItemComponent
            {...constants.ACCESS_PORTABILITY_REQUEST_STEP_2_COST_RESUME_ITEM_PROPS}
            label={<FormattedMessageComponent id={translations.COST_LABEL} />}
            value={<FormattedMessageComponent id={translations.FREE_LABEL} />}
          />
        </AccessPortabilityRequestStep2MessageItemStyled>
      </AccessPortabilityRequestStep2MessageStyled>
    </MessageComponent>
    <RequestAccessStep2ChallengeComponent />
    <AccessPortabilityRequestStep2ActionsComponent />
  </AccessPortabilityRequestStep2ComponentStyled>
);

export { AccessPortabilityRequestStep2Component };

export default AccessPortabilityRequestStep2ContextConsumerHOC(
  AccessPortabilityRequestStep2Component
);
