// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { NavigationFooterComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  CARD_SETTINGS_CHECK_PIN_ACTIONS_BUTTON_PROPS,
  CARD_SETTINGS_CHECK_PIN_ACTIONS_LINK_PROPS,
} from './constants/card-settings-change-pin-actions.constants';
// Hooks
import useCardSettingsChangePinActionsHook from './hooks/card-settings-change-pin-actions.hook';
// Styles
import { CardSettingsChangePinActionsComponentStyled } from './card-settings-change-pin-actions.component.styled';
// Translations
import { BUTTON, LINK } from './translations/card-settings-change-pin-actions.translations';
// Utilities
import { changePinButtonIsDisabled } from './utils/card-settings-change-pin-actions.utils';

const CardSettingsChangePinActionsComponent = (): React.ReactElement => {
  const {
    fetching,
    handleCancelChangePinProcessLinkClick,
    handleConfirmChangePinButtonClick,
    solvedChallenged,
  } = useCardSettingsChangePinActionsHook();

  return (
    <NavigationFooterComponent
      leftLinkConfiguration={{
        ...CARD_SETTINGS_CHECK_PIN_ACTIONS_LINK_PROPS,
        children: <FormattedMessageComponent id={LINK} />,
        onClick: handleCancelChangePinProcessLinkClick,
      }}
      rightButtonConfiguration={{
        ...CARD_SETTINGS_CHECK_PIN_ACTIONS_BUTTON_PROPS,
        children: <FormattedMessageComponent id={BUTTON} />,
        disabled: changePinButtonIsDisabled({ fetching, solvedChallenged }),
        loading: fetching,
        onClick: handleConfirmChangePinButtonClick,
      }}
    />
  );
};

export default CardSettingsChangePinActionsComponent;
