const TITLE_PROPS = {
  bold: true,
  size: 'L',
};

const PENDING_AMOUNT_VALUE_PARAGRAPH_PROPS = {
  bold: true,
};

const PRODUCT_DETAIL_PROPS = {
  showTitleIcon: true,
};

const PRODUCT_DETAIL_TOOLTIP_PROPS = {
  position: 'right',
};

export {
  PENDING_AMOUNT_VALUE_PARAGRAPH_PROPS,
  PRODUCT_DETAIL_PROPS,
  PRODUCT_DETAIL_TOOLTIP_PROPS,
  TITLE_PROPS,
};
