// Vendors
import React from 'react';
import { FormattedDate } from 'react-intl';
import { MiscellaneousDateConstants } from '@openbank/cf-ui-static-data';
// Components
import { ParagraphComponent, TagComponent, LineSeparatorComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  ACCESS_PORTABILITY_DOWNLOAD_BUTTON_PROPS,
  ACCESS_PORTABILITY_LINE_SEPARATOR_PROPS,
  ACCESS_PORTABILITY_REQUEST_TAG_ID,
  ACCESS_PORTABILITY_REQUEST_TAG_TYPES,
} from './constants/request-table-row.constants';
// Hooks
import { RequestTableRowHook } from './hooks/request-table-row.hook';
// Styles
import {
  AccessPortabilityRequestTableRowContainerStyled,
  AccessPortabilityDownloadButtonStyled,
  AccessPortabilityRequestTableRowContentStyled,
} from './request-table-row.component.styled';
// Types
import { AccessRequestTableRowComponentType } from './types/request-table-row.component.type';
// Translations
import {
  ACCESS_PORTABILITY_DOWNLOAD_BUTTON,
  ACCESS_PORTABILITY_REQUEST_LABELS,
} from './translations/request-table-row.component.translations';

const RequestTableRowComponent = ({
  email,
  requestDate,
  status,
  documentId,
  requestId,
}: AccessRequestTableRowComponentType): React.ReactElement => {
  const { handleDownloadDocumentRequestClick } = RequestTableRowHook({ documentId });

  return (
    <>
      <AccessPortabilityRequestTableRowContainerStyled>
        <AccessPortabilityRequestTableRowContentStyled>
          <ParagraphComponent>{email}</ParagraphComponent>
          <ParagraphComponent>
            {
              <FormattedDate
                {...MiscellaneousDateConstants.FORMAT_DD_MM_YYYY}
                value={requestDate}
              />
            }
          </ParagraphComponent>
          {status && (
            <TagComponent
              testId={requestId + ACCESS_PORTABILITY_REQUEST_TAG_ID[status]}
              type={ACCESS_PORTABILITY_REQUEST_TAG_TYPES[status]}
            >
              <FormattedMessageComponent id={ACCESS_PORTABILITY_REQUEST_LABELS[status]} />
            </TagComponent>
          )}
          <AccessPortabilityDownloadButtonStyled
            {...ACCESS_PORTABILITY_DOWNLOAD_BUTTON_PROPS}
            $status={status}
            onClick={handleDownloadDocumentRequestClick}
          >
            <FormattedMessageComponent id={ACCESS_PORTABILITY_DOWNLOAD_BUTTON} />
          </AccessPortabilityDownloadButtonStyled>
        </AccessPortabilityRequestTableRowContentStyled>
      </AccessPortabilityRequestTableRowContainerStyled>
      <LineSeparatorComponent {...ACCESS_PORTABILITY_LINE_SEPARATOR_PROPS} />
    </>
  );
};

export default RequestTableRowComponent;
