// Enumerations
import { UploadDocumentIdsEnumeration } from 'containers/financing/views/credit-limit-increase/components/step4/upload-documentation/components/upload-document/enums/upload-document-ids.enumeration';
// Types
import { RejectedDocumentType } from 'containers/financing/views/credit-limit-increase/contexts/types/document-rejected.type';
import { RejectedDocumentsMap } from './types/upload-documents-sections.type';

const getRejectedDocumentsMap = (
  rejectedDocuments?: RejectedDocumentType[]
): RejectedDocumentsMap[] => {
  return (
    Object.values(UploadDocumentIdsEnumeration).reduce((acc, uploadDocumentId) => {
      acc[uploadDocumentId] = rejectedDocuments?.some(
        rejectedDocument => rejectedDocument?.documentDescription === uploadDocumentId
      );
      return acc;
    }, [] as RejectedDocumentsMap[]) || []
  );
};

export { getRejectedDocumentsMap };
