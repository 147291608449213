// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ParagraphComponent, ButtonComponent, buttonConstants } from '@openbank/cb-ui-commons';
// Hooks
import { useLoginAccessData } from './hooks/login-access-data.hook';
// Translations
import { ACCESS_DATA } from 'containers/login/translations/login.translations';
// Styles
import { LoginFormContentChildrenContainerStyled } from 'containers/login/login.container.styled';

const LoginAccessDataComponent: React.FC = () => {
  const { handleIdenfityButtonClick } = useLoginAccessData();

  return (
    <LoginFormContentChildrenContainerStyled>
      <ParagraphComponent textAlign="center" bold>
        <FormattedMessageComponent id={ACCESS_DATA.MESSAGE} />
      </ParagraphComponent>
      <ButtonComponent
        size={buttonConstants.SIZES.FULL}
        type={buttonConstants.TYPES.TERTIARY}
        onClick={handleIdenfityButtonClick}
      >
        <FormattedMessageComponent id={ACCESS_DATA.BUTTON} />
      </ButtonComponent>
    </LoginFormContentChildrenContainerStyled>
  );
};

export { LoginAccessDataComponent };
