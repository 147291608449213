// Constants
import { GOOGLE_API_MESSAGE_ERROR } from './constants/use-address-predictions.handler.constants';
// Types
import {
  LoadGoogleServicesHandlerPropsType,
  LoadGoogleServicesHandlerReturnType,
} from './types/load-google-services.handler.type';

export const loadGoogleServicesHandler = ({
  autocompleteService,
  geocoderService,
}: LoadGoogleServicesHandlerPropsType): LoadGoogleServicesHandlerReturnType => {
  const isGoogleAPIAvailable = Boolean(window.google);

  if (isGoogleAPIAvailable) {
    autocompleteService.current = new window.google.maps.places.AutocompleteService();
    geocoderService.current = new window.google.maps.Geocoder();
  } else {
    window.console.error(GOOGLE_API_MESSAGE_ERROR);
  }
};
