// Vendors
import styled, { css } from 'styled-components';
// Types
import { TransactionsTableHookType } from './hooks/types/transactions-table.hook.type';
import { TestIdPropsType } from 'types/test-id.type';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const TransactionsTableOrganismStyled = styled.section.attrs(({ testId }: TestIdPropsType) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

const TransactionsTableContentOrganismStyled = styled.section<
  Pick<TransactionsTableHookType, 'fetching' | 'error'>
>`
  width: 100%;
  ${({ fetching, error }) =>
    (fetching || error) &&
    css`
      align-items: center;
      display: flex;
      justify-content: center;
      padding: ${({ theme }) => theme.spaces.spacingS} 0;
      ${mobileMediaQuery} {
        padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingS};
      }
    `}
`;

const TransactionsTableBottomContentOrganismStyled = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
TransactionsTableOrganismStyled.displayName = 'TransactionsTableOrganismStyled';

export {
  TransactionsTableOrganismStyled,
  TransactionsTableContentOrganismStyled,
  TransactionsTableBottomContentOrganismStyled,
};
