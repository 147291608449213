// Vendors
import { useState } from 'react';
// Handlers
import UploadDocumentsTipsHandlers from '../handlers/upload-documents-tips.handlers';
// Types
import { useUploadDocumentsTipsHookType } from './types/upload-documents-tips.hook.type';

const useUploadDocumentsTipsHook = (): useUploadDocumentsTipsHookType => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return {
    ...UploadDocumentsTipsHandlers({
      setShowModal,
    }),
    showModal,
  };
};

export default useUploadDocumentsTipsHook;
