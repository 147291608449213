// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlOperationsShopsRowComponent from './components/row/settings-card-control-operations-shops-row.component';
// Constants
import constants from './constants/settings-card-control-operations-shops.constants';
import {
  SettingsCardControlOperationsShopsComponentStyled,
  SettingsCardControlOperationsShopsTopComponentStyled,
  SettingsCardControlOperationsShopsBottomComponentStyled,
} from './settings-card-control-operations-shops.component.styled';
// Translations
import translations from './translations/settings-card-control-operations-shops.translations';

const SettingsCardControlOperationsShopsComponent = (): React.ReactElement => (
  <SettingsCardControlOperationsShopsComponentStyled>
    <SettingsCardControlOperationsShopsTopComponentStyled>
      <AssetComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ASSET_PROPS} />
      <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_TITLE_PROPS}>
        <FormattedMessageComponent id={translations.TITLE} />
      </ParagraphComponent>
    </SettingsCardControlOperationsShopsTopComponentStyled>
    <SettingsCardControlOperationsShopsBottomComponentStyled>
      <SettingsCardControlOperationsShopsRowComponent
        {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ROW_PHYSICAL_PROPS}
      />
      <SettingsCardControlOperationsShopsRowComponent
        {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ROW_ONLINE_PROPS}
      />
    </SettingsCardControlOperationsShopsBottomComponentStyled>
  </SettingsCardControlOperationsShopsComponentStyled>
);

export default SettingsCardControlOperationsShopsComponent;
