// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';
// Resolvers
import { fetchCardDetailsResolver, fetchCardPanResolver } from '../resolvers/card-detail.resolvers';
// Types
import { CardDetailBuilderReturnHandlersType } from './types/card-detail-builder-return.handlers.type';
import { CardDetailBuilderHandlersType } from './types/card-detail-builder.handlers.type';
import { CardDetailDetailsBuilderHandlersType } from './types/card-detail-details-builder.handlers.type';
import { CardDetailPanBuilderHandlersType } from './types/card-detail-pan-builder.handlers.type';

const fetchCardPanHandler = async ({
  details,
  setCardDetail,
  setCurrentCardDetail,
  setFetching,
}: CardDetailPanBuilderHandlersType): Promise<void> => {
  const [pan] = await fetchCardPanResolver(details?.id);

  setCurrentCardDetail(Object.assign(details, { pan }));
  setFetching(false);
  setCardDetail(false);
};

const fetchPrimaryCardDetailsInformation = async ({
  currentCard,
  setCardDetail,
  setCurrentCardDetail,
  setError,
  setFetching,
}: CardDetailDetailsBuilderHandlersType): Promise<void> => {
  const { id, ui } = currentCard;
  const [details, error] = await fetchCardDetailsResolver(id);

  if (error) {
    setError(ErrorCodesEnumeration.DEFAULT);
  } else if (details) {
    const updateDetails = { ...details, ui };

    await fetchCardPanHandler({ details: updateDetails, setCurrentCardDetail, setFetching, setCardDetail });
  }
};

const CardDetailHandlers = (
  props: CardDetailBuilderHandlersType
): CardDetailBuilderReturnHandlersType => ({
  handleSetupCardInformationEvent: () => fetchPrimaryCardDetailsInformation(props),
});

export { CardDetailHandlers };
