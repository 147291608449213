// Types
import { PersonalEmailStep1ActionsUtilsType } from './types/personal-email-step1-actions.utils.type';
// Utilities
import { newEmailAndRepeatedDontMatch } from 'containers/personal/views/email/components/step1/utils/personal-email-step1.utils';
import { isValidEmail } from 'utils/email.utils';

const modifyEmailStep1NextButtonIsDisabled = ({
  newEmail,
  repeatEmail,
}: PersonalEmailStep1ActionsUtilsType): boolean =>
  Boolean(
    !isValidEmail(newEmail) ||
      !isValidEmail(repeatEmail) ||
      newEmailAndRepeatedDontMatch({ newEmail, repeatEmail })
  );

export { modifyEmailStep1NextButtonIsDisabled };
