// Types
import { TransactionsEppContextType } from 'containers/transactions/views/epp/contexts/types/transactions-epp.context.type';

const simulationPlanIsNotDone = (
  eppPlanSimulation: TransactionsEppContextType['eppPlanSimulation']
): boolean =>
  Boolean(
    !eppPlanSimulation ||
      !eppPlanSimulation?.installments ||
      eppPlanSimulation.installments.length < 1
  );

export { simulationPlanIsNotDone };
