// Constants
import { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT } from './transactions.constants.common';

const transactionStatusCodes =
  'AUTHORIZED, PENDING_CONSOLIDATION, LIQUIDATED, ANNULLED, PENDING_LIQUIDATION, DROPPED, FAILED, NEW, REJECTED';

const statusCodeDeclined = { transactionStatusCodes: 'DECLINED' };

const FETCH_TRANSACTIONS_SERVICE_CONFIGURATION = {
  ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT,
  ...{ transactionStatusCodes },
};

const FETCH_TRANSACTIONS_DECLINED_SERVICE_CONFIGURATION = {
  ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT,
  queryParams: {
    ...FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT.queryParams,
    ...statusCodeDeclined,
  },
};

export {
  FETCH_TRANSACTIONS_SERVICE_CONFIGURATION,
  FETCH_TRANSACTIONS_DECLINED_SERVICE_CONFIGURATION,
  transactionStatusCodes,
};
