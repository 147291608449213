// Vendors
import { useState, useEffect } from 'react';
// Types
import { RequestSignatureKeyStep2ChallengeHookType } from './types/request-signature-key-step2-challenge.hook.type';
// Handlers
import { useRequestSignatureKeyContextConsumerHook } from 'containers/security-keys/views/request-signature-key/contexts/request-signature-key.context';
import RequestSignatureKeyStep2ChallengeHandlers from '../handlers/request-signature-key-step2-challenge.handlers';

const useRequestSignatureKeyStep2ChallengeHook = (): RequestSignatureKeyStep2ChallengeHookType => {
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const { setProgressId, challenged, setChallenged } = useRequestSignatureKeyContextConsumerHook();

  const {
    handleSetupStartedChallengeEvent,
    handleManageErrorOnRequestSignatureKeyEvent,
    handleManageSuccessOnRequestSignatureKeyEvent,
    handleChallengeRetryButtonClick,
  } = RequestSignatureKeyStep2ChallengeHandlers({
    setChallenged,
    setChallengeError,
    setProgressId,
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupStartedChallengeEvent();
  }, []);

  return {
    challengeError,
    challenged,
    handleManageErrorOnRequestSignatureKeyEvent,
    handleManageSuccessOnRequestSignatureKeyEvent,
    handleChallengeRetryButtonClick,
  };
};

export default useRequestSignatureKeyStep2ChallengeHook;
