// Constants
import { assetConstants, buttonConstants } from '@openbank/cb-ui-commons';

export const APP_NO_USER_DATA_LOGO_PROPS = {
  id: 'amazon-zinia-logo',
  sizes: {
    width: 272,
    height: 40,
  },
  type: assetConstants.TYPES.LOGO,
  testId: 'app-no-user-data-logo'
};

export const ACTION_BUTTON_SIZE = buttonConstants.SIZES.SMALL;
export const ACTION_BUTTON_TESTID = 'Menu_Action_Logout_Button';
export const ACTION_BUTTON_TYPE = buttonConstants.TYPES.TERTIARY;