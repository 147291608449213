// Resolvers
import { generateChangeIbanOtpResolver } from '../resolvers/change-iban-step2.resolvers';
// Types
import { ChangeIbanStep2ActionsBuilderHandlersType } from './types/change-iban-step2-actions-builder.handlers.type';
import { ChangeIbanStep2ActionsBuilderReturnHandlersType } from './types/change-iban-step2-actions-builder-return.handlers.type';

const confirmChangeIbanButtonClickHandler = async ({
  cardId,
  ibanValue,
  progressId,
  setFetching,
  setResult,
}: ChangeIbanStep2ActionsBuilderHandlersType): Promise<void> => {
  setFetching(true);

  const [, error] = await generateChangeIbanOtpResolver({ cardId, ibanValue, progressId });

  setResult(!error);
  setFetching(false);
};

const ChangeIbanStep2ActionsHandlers = (
  props: ChangeIbanStep2ActionsBuilderHandlersType
): ChangeIbanStep2ActionsBuilderReturnHandlersType => ({
  handleChangeIbanButtonClick: () => confirmChangeIbanButtonClickHandler(props),
});

export { ChangeIbanStep2ActionsHandlers };
