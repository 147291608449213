// Vendors
import React from 'react';
// Components
import { TabsComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlOrchestratorComponent from '../orchestrator/settings-card-control-orchestrator.component';
// Configurations
import { getCardControlMappedTabsConfiguration } from './configurations/settings-card-control-tabs.configurations';
// Hooks
import SettingsCardControlTabsHook from './hooks/settings-card-control-tabs.hook';
// Styles
import {
  SettingsCardControlTabsComponentStyled,
  SettingsCardControlTabsTopComponentStyled,
  SettingsCardControlTabsTopMessageComponentStyled,
} from './settings-card-control-tabs.component.styled';
import CardStatusComponent from 'components/card-status/card-status.component';
// Utilities
import { isSelectedTabOperations } from './utils/setting-card-control-tab.utils';

const SettingsCardControlTabsComponent = (): React.ReactElement => {
  const {
    handleChangeCardControlTabClick: onClick,
    formatMessage,
    tabSelectedId,
  } = SettingsCardControlTabsHook();

  return (
    <SettingsCardControlTabsComponentStyled>
      <SettingsCardControlTabsTopComponentStyled>
        <TabsComponent
          {...{ tabSelectedId }}
          tabs={getCardControlMappedTabsConfiguration({ formatMessage, onClick })}
        />
      </SettingsCardControlTabsTopComponentStyled>
      <SettingsCardControlTabsTopMessageComponentStyled>
        {isSelectedTabOperations(tabSelectedId) && <CardStatusComponent />}
      </SettingsCardControlTabsTopMessageComponentStyled>
      <SettingsCardControlOrchestratorComponent {...{ tabSelectedId }} />
    </SettingsCardControlTabsComponentStyled>
  );
};

export default SettingsCardControlTabsComponent;
