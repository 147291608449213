// Constants
import { paragraphConstants } from '@openbank/cb-ui-commons';

export default {
  DASHBOARD_SPENT_TOP_TITLE_PROPS: {
    size: paragraphConstants.SIZES.XS,
    testId: 'dashboard-spent-title-section',
  },
  DASHBOARD_SPENT_TOP_DEFAULT_TEST_ID: 'dashboard-spent-top-component',
};
