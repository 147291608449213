// Types
import { AddressModalHandlersReturnType } from './types/address-modal-return.handlers.type';
import { AddressModalHandlersType } from './types/address-modal.handlers.type';
import { ResetHandlerType } from './types/reset-handler.type';
import { SaveHandlerType } from './types/save-handler.type';
// Utils
import { formatAddressFormValues, trimValues } from '../utils/address-modal.utils';

const resetHandler =
  ({ callback, defaultValues, reset }: ResetHandlerType): VoidFunction =>
  (): void => {
    reset(formatAddressFormValues(defaultValues));
    callback();
  };

const saveHandler = ({ formValues, onSave }: SaveHandlerType): void =>
  onSave(trimValues(formValues));

export const AddressModalHandlers = ({
  defaultValues,
  onSave,
  reset,
}: AddressModalHandlersType): AddressModalHandlersReturnType => ({
  handleReset: callback => resetHandler({ callback, defaultValues, reset }),
  handleSave: formValues => saveHandler({ formValues, onSave }),
});
