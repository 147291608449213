// Types
import { SetEmploymentDataPropsType } from './types/step-2-credit-limit-increase.utils.type';

export const setEmploymentData = ({
  setStep2,
  step2,
  values,
}: SetEmploymentDataPropsType): void => {
  setStep2({
    ...step2,
    personalData: {
      ...step2.personalData,
      employments: [{ ...step2.personalData.employments?.[0], ...values }],
    },
  });
};
