// Vendors
import {
  Platform,
  isWebViewPlatform as baseIsWebViewPlatform,
  getBrowserPlatform,
} from '@openbank/web-ui-framework';
// Constants
export * as MobileConnectorMessages from './constants/mobile-connector.constants';
// Enumerations
export { HybridFlowIds } from './enumerations/hybrid-flow-ids.enumerations';
// Helpers
export * from './helpers/mobile-connector.helpers';
// Initializations
export * from './initialization/mobile-connector.initialization';

const isWebViewPlatform = (): boolean => baseIsWebViewPlatform();
const getCurrentPlatform = (): Platform => (isWebViewPlatform() ? Platform.HYBRID : Platform.WEB);
const getCurrentMobile = (): Platform => getBrowserPlatform();

export { getCurrentPlatform, isWebViewPlatform, Platform, getCurrentMobile };
