// Vendors
import React from 'react';
// Components
import {
  ExpandableRowComponent,
  ParagraphComponent,
  ProductDetailComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import CardDetailsWrapperComponent from 'components/card-details/card-details-wrapper.component';
import ChangeCreditLimitComponent from '../change-credit-limit/change-credit-limit.component';
import WizardStepFooterComponent from 'components/wizard-step-footer/wizard-step-footer.component';
// Constants
import constants from './constants/step-1-credit-limit-increase.constants';
import { cancelUrl } from 'containers/financing/views/credit-limit-increase/constants/credit-limit-increase.constants';
import { PUBLIC_URL_PRIVACY_POLICY } from 'constants/po-editor.constants';
// Hooks
import ChangeCreditLimitHook from '../change-credit-limit/hooks/change-credit-limit.hook';
import ExitModalHook from 'containers/financing/views/credit-limit-increase/hooks/exit-modal.hook';
import { Step1CreditLimitIncreaseHook } from './hooks/step-1-credit-limit-increase.hook';
// Styles
import {
  Step1CreditLimitIncreaseStyled,
  SetCardLimitFirstRowStyled,
  ProductDetailsWrapper,
  CreditLimitSliderWrapper,
  FirstRowComponentWrapper,
  ParagraphComponentWrapper,
} from './step-1-credit-limit-increase.component.styled';
// Translations
import translations from './translations/step-1-credit-limit-increase.translations';
import { translationsStep1Or2 } from '../../translations/exit-modal-credit-limit-increase.component.translations';
// Utils
import { formatCreditAmount } from '../../utils/currency.utils';
import { getPaymentMethodValueBy } from 'containers/financing/utils/financing.utils';
import { makeChunkLinkIntlUtil } from 'hooks/useCbIntl/utils/useCbIntl.utils';
import { getConfigurationValue } from 'utils/get-configuration-value';
import { isSameObject } from 'utils/app.utils';

export const Step1CreditLimitIncreaseComponent = (): React.ReactElement => {
  const { showModal, setShowModal, cancelModalLinkClick } = ExitModalHook();
  const {
    disabled,
    isLoadingNextRequest,
    handleSelectCreditLimit,
    handleOpenExternalUrlOnHybridFlow,
  } = Step1CreditLimitIncreaseHook();

  const {
    accountBalancePercentage,
    defaultValue,
    fixedPaymentAmount,
    formatMessage,
    formatNumber,
    limit,
    mainCurrency,
    maxAmount,
    minAmount,
    newCreditLimit,
    paymentMethodCode,
  } = ChangeCreditLimitHook();

  return (
    <Step1CreditLimitIncreaseStyled>
      <SetCardLimitFirstRowStyled>
        <FirstRowComponentWrapper>
          <CardDetailsWrapperComponent />
        </FirstRowComponentWrapper>
        <ProductDetailsWrapper>
          <ProductDetailComponent
            {...constants.PRODUCT_DETAIL_PROPS}
            min={minAmount}
            max={maxAmount}
            title={formatMessage({ id: translations.CONTENT_TITLE })}
          >
            <ParagraphComponent {...constants.PARAGRAPH_PROPS}>{`${formatCreditAmount({
              currency: mainCurrency,
              formatNumber,
              value: limit,
            })}`}</ParagraphComponent>
          </ProductDetailComponent>
          <ProductDetailComponent
            {...constants.PRODUCT_DETAIL_PROPS}
            title={formatMessage({ id: translations.PAYMENT_METHOD_TEXT })}
          >
            <ParagraphComponent {...constants.PARAGRAPH_PROPS}>
              {getPaymentMethodValueBy({
                accountBalancePercentage,
                fixedPaymentAmount,
                mainCurrency,
                paymentMethodCode,
                formatMessage,
                formatNumber,
              })}
            </ParagraphComponent>
          </ProductDetailComponent>
        </ProductDetailsWrapper>
      </SetCardLimitFirstRowStyled>
      <CreditLimitSliderWrapper>
        <ChangeCreditLimitComponent />
      </CreditLimitSliderWrapper>
      <ExpandableRowComponent
        title={<FormattedMessageComponent id={translations.MESSAGE_COMPONENT_TITLE} />}
        {...constants.STEP_1_ACCORDION_PROPS}
      >
        <ParagraphComponentWrapper>
          <ParagraphComponent>
            {formatMessage(
              { id: translations.MESSAGE_COMPONENT_TEXT },
              {
                br: <br />,
                privacityUrl: chunk =>
                  makeChunkLinkIntlUtil({
                    chunk,
                    onClick: () =>
                      handleOpenExternalUrlOnHybridFlow(
                        getConfigurationValue(PUBLIC_URL_PRIVACY_POLICY)
                      ),
                  }),
              }
            )}
          </ParagraphComponent>
        </ParagraphComponentWrapper>
      </ExpandableRowComponent>

      <WizardStepFooterComponent
        shouldShowModal={!isSameObject(defaultValue, newCreditLimit)}
        isFooterDisabled={isLoadingNextRequest}
        rightButtonConfiguration={{
          onClick: handleSelectCreditLimit,
          disabled: isLoadingNextRequest || disabled,
          loading: isLoadingNextRequest,
        }}
        cancelModal={{
          ...constants.STEP_1_WIZARD_FOOTER_PROPS,
          cancelUrl,
          showModal,
          setShowModal,
          clicks: {
            linkClick: cancelModalLinkClick,
            buttonClick: () => setShowModal(false),
          },
          translations: {
            link: translationsStep1Or2.LINK_TEXT,
            button: translationsStep1Or2.PRIMARY_BUTTON_TEXT,
          },
        }}
      />
    </Step1CreditLimitIncreaseStyled>
  );
};

export default Step1CreditLimitIncreaseComponent;
