// Translations
import {
  NOTIFICATION_SETTING,
  SWITCH_OFF,
  SWITCH_ON,
} from 'containers/notifications/views/notifications-settings/components/list/components/category/translations/notifications-settings-category.translations';
// Types
import { getSettingsRowToggleConfigurationPropsType } from './types/get-settings-row-toggle.configuration-props.type';
import { SettingsRowToggleConfigurationType } from './types/get-settings-row-toggle.configuration.type';

export const getSettingsRowToggleConfiguration = ({
  categoryId,
  formatMessage,
  handleToggleNotification,
  notificationSetting,
}: getSettingsRowToggleConfigurationPropsType): SettingsRowToggleConfigurationType => ({
  text: formatMessage({ id: `${NOTIFICATION_SETTING}${notificationSetting.description}` }),
  testId: `toggle-row-${notificationSetting.id}-${notificationSetting.title}`,
  hasLineSeparator: true,
  onChange: () =>
    handleToggleNotification({
      categoryId,
      isOn: !notificationSetting.enabled,
      notificationId: notificationSetting.id,
      notificationType: notificationSetting.title,
    }),
  defaultChecked: notificationSetting.enabled,
  labelOn: formatMessage({ id: SWITCH_ON }),
  labelOff: formatMessage({ id: SWITCH_OFF }),
});
