// Types
import { ChangePaymentMethodHandlerType } from './types/change-payment-method-handler.type';
import { FinancingPaymentMethodFirstStepPaymentOptionsReturnHandlersType } from './types/financing-payment-method-first-step-payment-options-return.handlers.type';
import { FinancingPaymentMethodFirstStepPaymentOptionsHandlersType } from './types/financing-payment-method-first-step-payment-options.handlers.type';

const changePaymentMethodHandler = ({ name, setPaymentMethod }: ChangePaymentMethodHandlerType) => {
  setPaymentMethod(name);
};

const FinancingPaymentMethodFirstStepPaymentOptionsHandlers = ({
  setPaymentMethod,
}: FinancingPaymentMethodFirstStepPaymentOptionsHandlersType): FinancingPaymentMethodFirstStepPaymentOptionsReturnHandlersType => ({
  handleChangePaymentMethod: name => changePaymentMethodHandler({ name, setPaymentMethod }),
});

export default FinancingPaymentMethodFirstStepPaymentOptionsHandlers;
