// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlOperationsAtmComponent from './components/atm/settings-card-control-operations-atm.component';
import SettingsCardControlOperationsShopsComponent from './components/shops/settings-card-control-operations-shops.component';
// Constants
import constants from './constants/settings-card-control-operations.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import {
  SettingsCardControlOperationsComponentStyled,
  SettingsCardControlOperationsContentBottomComponentStyled,
  SettingsCardControlOperationsContentComponentStyled,
} from './settings-card-control-operations.component.styled';
// Translations
import translations from './translations/settings-card-control-operations.translations';

const SettingsCardControlOperationsComponent = (): React.ReactElement => (
  <SettingsCardControlOperationsComponentStyled>
    <SettingsCardControlOperationsContentComponentStyled>
      <ParagraphSecondaryComponentStyled>
        <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_DESCRIPTION_PROPS}>
          <FormattedMessageComponent id={translations.DESCRIPTION} />
        </ParagraphComponent>
      </ParagraphSecondaryComponentStyled>
      <SettingsCardControlOperationsContentBottomComponentStyled>
        <SettingsCardControlOperationsAtmComponent />
        <SettingsCardControlOperationsShopsComponent />
      </SettingsCardControlOperationsContentBottomComponentStyled>
    </SettingsCardControlOperationsContentComponentStyled>
  </SettingsCardControlOperationsComponentStyled>
);

export default SettingsCardControlOperationsComponent;
