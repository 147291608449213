// Types
import {
  CardSettingsPaymentBuilderHandlersType,
  CardSettingsPaymentBuilderReturnHandlersType,
} from './types/card-settings-payment.handlers.type';

const updateNonSecureStoreModalStatus =
  (): ((bool: boolean) => boolean) =>
  (visible: boolean): boolean =>
    !visible;

const toggleNonSecureStoreModalHandler = ({
  setShowNonSecureStoreModal,
}: Pick<CardSettingsPaymentBuilderHandlersType, 'setShowNonSecureStoreModal'>): void => {
  setShowNonSecureStoreModal(updateNonSecureStoreModalStatus());
};

const showNonSecureStoreModalHandler = ({
  handleShowNonSecureStoreModalTracking,
  setShowNonSecureStoreModal,
}: CardSettingsPaymentBuilderHandlersType): void => {
  handleShowNonSecureStoreModalTracking();
  toggleNonSecureStoreModalHandler({ setShowNonSecureStoreModal });
};

const CardSettingsPaymentHandlers = ({
  handleShowNonSecureStoreModalTracking,
  setShowNonSecureStoreModal,
}: CardSettingsPaymentBuilderHandlersType): CardSettingsPaymentBuilderReturnHandlersType => ({
  handleShowNonSecureStoreModal: () =>
    showNonSecureStoreModalHandler({
      handleShowNonSecureStoreModalTracking,
      setShowNonSecureStoreModal,
    }),
  handleToggleNonSecureStoreModal: () =>
    toggleNonSecureStoreModalHandler({
      setShowNonSecureStoreModal,
    }),
});

export { CardSettingsPaymentHandlers };
