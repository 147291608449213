// Enumarations
import { CardSettingsToggleServiceActivationEnum } from '../enums/cars-settings-toggles.services.enum';

export default {
  FETCH_CARD_SETTINGS_CARD_ACTIVATION_SERVICE: {
    endpoint: 'cb-api-cards/v3/cards/:cardId/',
    options: CardSettingsToggleServiceActivationEnum,
  },
  FETCH_CARD_SETTINGS_TRAVEL_PLUS_ACTIVATION_SERVICE: {
    endpoint: 'cb-api-cards/v3/cards/:cardId/travelPlus',
  },
};
