// Vendors
import React from 'react';
// Components
import { Step4AccountAggregationComponent } from './account-aggregation/step-4-account-aggregation.component';
import { Step4UploadDocumentationComponent } from './upload-documentation/step-4-upload-documentation.component';
// Contexts
import { CreditLimitIncreaseContextConsumerHook } from '../../contexts/credit-limit-increase.context';
// Enumerations
import { CreditLimitIncreaseStepsEnumeration } from '../../enums/credit-limit-increase.view.enumeration';

const Step4CreditLimitIncreaseComponent = (): React.ReactElement => {
  const { wizardStatus } = CreditLimitIncreaseContextConsumerHook();
  const { currentStepInText } = wizardStatus;

  if (
    currentStepInText === CreditLimitIncreaseStepsEnumeration.ACCOUNTS_AGGREGATION ||
    currentStepInText === CreditLimitIncreaseStepsEnumeration.BANK_AGGREGATION_CONFIRMATION
  ) {
    return <Step4AccountAggregationComponent />;
  }
  if (
    currentStepInText === CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT ||
    currentStepInText === CreditLimitIncreaseStepsEnumeration.UPLOADING_DOCUMENT_REJECTED
  ) {
    return <Step4UploadDocumentationComponent />;
  }

  return <></>;
};

export { Step4CreditLimitIncreaseComponent };
