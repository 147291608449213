// Vendors
import React from 'react';
// Components
import { MessageComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TITLE_PROPS,
  CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TYPES,
} from './constants/card-settings-check-3d-confirmation-message.constants';
// Hooks
import useCardSettingsCheck3dConfirmationFooterHook from './hooks/card-settings-check-3d-confirmation-message.hook';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import {
  CardSettingsCheck3dConfirmationMessageComponentStyled,
  CardSettingsCheck3dConfirmationMessageContentComponentStyled,
} from './card-settings-check-3d-confirmation-message.component.styled';
// Translations
import {
  DESCRIPTIONS,
  TITLES,
} from './translations/card-settings-check-3d-confirmation-message.translations';

const CardSettingsCheck3dConfirmationMessageComponent = (): React.ReactElement => {
  const { type } = useCardSettingsCheck3dConfirmationFooterHook();

  return (
    <CardSettingsCheck3dConfirmationMessageComponentStyled>
      <MessageComponent
        {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_PROPS}
        type={CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TYPES[type]}
      >
        <CardSettingsCheck3dConfirmationMessageContentComponentStyled>
          <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_TITLE_PROPS}>
            <FormattedMessageComponent id={TITLES[type]} />
          </ParagraphComponent>
          <ParagraphComponent {...CARD_SETTINGS_CHECK_3D_CONFIRMATION_MESSAGE_DESCRIPTION_PROPS}>
            <ParagraphSecondaryComponentStyled>
              <FormattedMessageComponent id={DESCRIPTIONS[type]} />
            </ParagraphSecondaryComponentStyled>
          </ParagraphComponent>
        </CardSettingsCheck3dConfirmationMessageContentComponentStyled>
      </MessageComponent>
    </CardSettingsCheck3dConfirmationMessageComponentStyled>
  );
};

export default CardSettingsCheck3dConfirmationMessageComponent;
