// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

const OTP_SECTION_TITLE_PROPS = {
    tag: titleConstants.TAGS.H4,
    testId: 'otp-section-title',
  },
  OTP_SECTION_RESUME_ITEM_1_PROPS = {
    testId: 'otp-section-resume-item-1',
  },
  OTP_SECTION_RESUME_ITEM_2_PROPS = {
    testId: 'otp-section-resume-item-2',
  };

export {
  OTP_SECTION_TITLE_PROPS,
  OTP_SECTION_RESUME_ITEM_1_PROPS,
  OTP_SECTION_RESUME_ITEM_2_PROPS,
};
