// Enumeration
import { WizardStepsEnumeration } from 'enumerations/wizards-steps.enumeration';
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';
import { RecoveryKeyCCRecoveryOptionEnumeration } from 'containers/recovery-key/enumerations/recovery-key.enumerations';
// Types
import { ResetToStep1IfRecoveryBySMSType } from './types/reset-to-step-1-if-recovery-by-sms.type';

const dontHaveRecoveryOptionsOrHasDirect = (
  passwordRecoveryOptions: PasswordRecoveryOptionsEnumeration[]
) =>
  passwordRecoveryOptions?.length === 0 ||
  passwordRecoveryOptions.includes(PasswordRecoveryOptionsEnumeration.DIRECT);

const getNextRecoveryKeyStep = (
  passwordRecoveryOptions: PasswordRecoveryOptionsEnumeration[]
): WizardStepsEnumeration =>
  dontHaveRecoveryOptionsOrHasDirect(passwordRecoveryOptions)
    ? WizardStepsEnumeration.FIVE
    : WizardStepsEnumeration.THREE;

const resetToStep1IfRecoveryBySMS = ({
  currentStep,
  recoveryOption,
  lastRecoveryOptionRef,
  setCurrentStep,
}: ResetToStep1IfRecoveryBySMSType): void => {
  if (
    currentStep !== undefined &&
    recoveryOption === RecoveryKeyCCRecoveryOptionEnumeration.RECOVERY_BY_SMS &&
    lastRecoveryOptionRef.current === RecoveryKeyCCRecoveryOptionEnumeration.PHONE_AND_SIGNATURE_KEY
  ) {
    setCurrentStep(1);
  }
};

export { getNextRecoveryKeyStep, resetToStep1IfRecoveryBySMS };
