// Vendors
import { apiUtilsCb, cookies } from '@openbank/cf-ui-architecture-core';
// Constants
import { REFRESH_TOKEN_SERVICE_CONFIGURATION } from './constants/refresh-token.service.constants';
// Enumerations
import { StorageCookiesEnum } from '@openbank/cf-ui-static-data';
// Types
import { UserSessionType } from 'types/user-session.type';

const refreshTokenService = async (): Promise<
  [UserSessionType | Record<string, unknown>, Response]
> =>
  apiUtilsCb.post({
    ...REFRESH_TOKEN_SERVICE_CONFIGURATION,
    params: {
      refreshToken: cookies.getCookie(StorageCookiesEnum.REFRESH_TOKEN),
      username: localStorage.getItem(StorageCookiesEnum.USERNAME),
    },
  });

export { refreshTokenService };
