// Vendors
import React from 'react';
// Components
import { LinkComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Hooks
import { useCardSettingsResumeLinkLinksHook } from './hooks/card-settings-resume-link-links.hook';
// Styles
import {
  CardSettingsResumeLinkLinksComponentStyled,
  CardSettingsResumeLinkLinksLayoutComponentStyled,
} from './card-settings-resume-link-links.component.styled';
// Types
import { CardSettingsResumeLinkComponentType } from '../../types/card-settings-resume-link.component.type';
import { CardSettingsResumeLinkConfigurationComponentType } from '../../types/card-settings-resume-link-configuration.component.type';
// Utilities
import { isCardSettingsResumeLinkDisabled } from './utils/card-settings-resume-link-links.component.utils';

const CardSettingsResumeLinkLinksComponent = ({
  links,
}: Pick<CardSettingsResumeLinkComponentType, 'links'>): React.ReactElement | null => {
  const { handleActionDisabled, handleResumeCardSettingsLinkClick } =
    useCardSettingsResumeLinkLinksHook();

  return (
    <CardSettingsResumeLinkLinksLayoutComponentStyled>
      {links?.map(
        (
          {
            actionId,
            disabled,
            label,
            testId,
            ...rest
          }: CardSettingsResumeLinkConfigurationComponentType,
          key: number
        ): LinkComponent => (
          <CardSettingsResumeLinkLinksComponentStyled key={actionId}>
            <LinkComponent
              {...{ testId }}
              disabled={isCardSettingsResumeLinkDisabled({
                actionDisabled: handleActionDisabled(actionId),
                disabled,
              })}
              onClick={() => handleResumeCardSettingsLinkClick(rest)}
            >
              <FormattedMessageComponent id={label} />
            </LinkComponent>
          </CardSettingsResumeLinkLinksComponentStyled>
        )
      )}
    </CardSettingsResumeLinkLinksLayoutComponentStyled>
  );
};

export { CardSettingsResumeLinkLinksComponent };
