// Vendors
import styled from 'styled-components';

const SupportCancelContractModalDescriptionComponentStyled = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  width: 100%;
`;

SupportCancelContractModalDescriptionComponentStyled.displayName =
  'SupportCancelContractModalDescriptionComponentStyled';

const SupportCancelContractModalDescriptionTextComponentStyled = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    text-align: left;
  }
`;

SupportCancelContractModalDescriptionTextComponentStyled.displayName =
  'SupportCancelContractModalDescriptionTextComponentStyled';

export {
  SupportCancelContractModalDescriptionComponentStyled,
  SupportCancelContractModalDescriptionTextComponentStyled,
};
