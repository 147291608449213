// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { ProfileConsentsContextConsumerHook } from '../contexts/profile-consents.context';
// Event Handlers
import ProfileConsentsHandlers from '../handlers/profile-consents.handlers';
// Types
import { ProfileConsentsHookType } from './types/profile-consents.hook.type';

const ProfileConsentsHook = (): ProfileConsentsHookType => {
  const { error, fetching, setFetching, setConsents, setError, setInitialConsents } =
    ProfileConsentsContextConsumerHook();

  const { fetchProfileConsents } = ProfileConsentsHandlers({
    setConsents,
    setError,
    setFetching,
    setInitialConsents,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    fetchProfileConsents();
  }, []);

  return { error, fetching };
};

export default ProfileConsentsHook;
