// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Hooks
import { useTransactionsEppStep1SimulationSummaryItemHook } from './hooks/transactions-epp-step1-simulation-summary-item.hook';
// Types
import { TransactionsEppStep1SimulationSummaryItemComponentType } from './types/transactions-epp-step1-simulation-summary-item.component.type';
// Utilities
import { getEppSimulationDataOrPlaceholder } from './utils/transactions-epp-step1-simulation-summary-item.utils';

const TransactionsEppStep1SimulationSummaryItemComponent = ({
  label,
  id,
  linkConfiguration = {},
  testId,
}: TransactionsEppStep1SimulationSummaryItemComponentType): React.ReactElement => (
  <ResumeItemComponent
    {...{ linkConfiguration, testId }}
    label={<FormattedMessageComponent id={label} />}
    value={getEppSimulationDataOrPlaceholder({
      ...useTransactionsEppStep1SimulationSummaryItemHook(),
      id,
    })}
  />
);

export { TransactionsEppStep1SimulationSummaryItemComponent };
