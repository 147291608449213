// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Services
import { changeCurrentCardStatusService } from '../services/card-settings-activate-card.services';
// Types
import { CardSettingsActivateCardApiBuilderComponentType } from '../types/card-settings-activate-card-api-builder.component.type';
import { ChallengeType } from 'types/challenge.type';

const changeCurrentCardStatusResolver = async (
  props: CardSettingsActivateCardApiBuilderComponentType
): Promise<[null] | [ChallengeType | null, number]> => {
  const [response, { status }] = await changeCurrentCardStatusService(props);

  return status === HttpStatusCodesEnum.NO_CONTENT ? [null] : [response, status];
};

export { changeCurrentCardStatusResolver };
