// Translations
import translations from '../translations/financing-deposit-money-sidebar.translations';

export default {
  CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_PROPS: {
    testId: 'deposit-money',
  },
  ROWS_PROPS: [
    {
      id: 'statements-sidebar-first-row',
      title: translations.ROWS.FIRST.TITLE,
      content: translations.ROWS.FIRST.CONTENT,
    },
    {
      id: 'statements-sidebar-second-row',
      title: translations.ROWS.SECOND.TITLE,
      content: translations.ROWS.SECOND.CONTENT,
    },
    {
      id: 'statements-sidebar-third-row',
      title: translations.ROWS.THIRD.TITLE,
      content: translations.ROWS.THIRD.CONTENT,
    },
  ],
  PARAGRAPH_PROPS: {
    size: 'M',
  },
};
