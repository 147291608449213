// Vendors
import React from 'react';
// Components
import {
  AccordionComponent,
  FetchErrorComponent,
  TitleComponent
} from '@openbank/cb-ui-commons';
import { DeviceHeaderComponent } from 'containers/security-keys/views/devices/components/device-header/device-header.component';
import { DeviceInfoComponent } from 'containers/security-keys/views/devices/components/device-info/device-info.component';
import { DeviceSessionsComponent } from 'containers/security-keys/views/devices/components/device-sessions/device-sessions.component';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import { LABEL } from './constants/devices-list.constants';
// Hooks
import { DevicesListHook } from './hooks/devices-list.hook';
// Styled
import { DevicesManagerListComponentStyled, DevicesManagerListItemComponentStyled } from './devices-list.component.styled';
// Translations
import { OTHER_DEVICES, BLOCKED_DEVICES } from './translations/devices-list.translations';
// Configurations
import { getFetchDevicesErrorConfiguration } from './configurations/devices-list.configurations';
import { getFetchErrorConfiguration } from 'configurations/app.configurations';

const DevicesManagerListComponent = (): React.ReactElement => {
  const { fetching, devices, blockedDevices, isMobile, getDevices, error, formatMessage } = DevicesListHook();

  return (
    <FetchErrorComponent fetching={fetching} error={getFetchErrorConfiguration(
      getFetchDevicesErrorConfiguration({
       error,
        formatMessage,
        onClick: getDevices
      }))
    }>
      <DevicesManagerListComponentStyled>
        <TitleComponent {...LABEL}>
          <FormattedMessageComponent id={OTHER_DEVICES} />
        </TitleComponent>
        { devices.map(device => (
          <DevicesManagerListItemComponentStyled key={device.deviceUUID}>
            { isMobile ? <DeviceHeaderComponent link button device={device}/> : 
            <AccordionComponent
              headerChildren={ <DeviceHeaderComponent button device={device}/> }
            >
              <DeviceInfoComponent device={device} />
              <DeviceSessionsComponent deviceUUID={device.deviceUUID} />
            </AccordionComponent> }
          </DevicesManagerListItemComponentStyled>
          ))
        }

        { blockedDevices.length > 0 && <>
          <TitleComponent {...LABEL}>
          <FormattedMessageComponent id={BLOCKED_DEVICES} />
          </TitleComponent>
          { blockedDevices.map(device => (
            <DevicesManagerListItemComponentStyled key={device.deviceUUID}>
              { isMobile ? <DeviceHeaderComponent button link device={device}/> : 
              <AccordionComponent
                headerChildren={ <DeviceHeaderComponent button device={device}/> }
              >
                <DeviceInfoComponent device={device} />
                <DeviceSessionsComponent deviceUUID={device.deviceUUID} />
              </AccordionComponent> }
            </DevicesManagerListItemComponentStyled>
          ))} </>
        }
      </DevicesManagerListComponentStyled>
    </ FetchErrorComponent>
  );
};

export { DevicesManagerListComponent };