// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ConstraintsComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_CHECK_PIN_CONSTRAINTS_TITLE_PROPS } from './constants/card-settings-change-pin-constraints.constants';
// Hooks
import useCardSettingsChangePinConstraintsHook from './hooks/card-settings-change-pin-constraints.hook';
// Styles
import { CardSettingsChangePinConstraintsComponentStyled } from './card-settings-change-pin-constraints.component.styled';
// Translations
import {
  CONSTRAINT_1,
  CONSTRAINT_2,
  CONSTRAINT_3,
  CONSTRAINT_4,
  TITLE,
} from './translations/card-settings-change-pin-constraints.translations';
// Utilities
import {
  checkHasFourNumbersOnly,
  checkNoAscendingDescendingSequences,
  noContain3OrMoreConsecutiveAndEqualsNumbers,
  notMatchMontDayOrYearCustomerBirthDate,
} from '../../utils/card-settings-change-pin-constraints.utils';

const CardSettingsChangePinConstraintsComponent = (): React.ReactElement => {
  const { challenged, solvedChallenged, dateOfBirth, newPin } =
    useCardSettingsChangePinConstraintsHook();

  return (
    <CardSettingsChangePinConstraintsComponentStyled>
      <ParagraphComponent {...CARD_SETTINGS_CHECK_PIN_CONSTRAINTS_TITLE_PROPS}>
        <FormattedMessageComponent id={TITLE} />
      </ParagraphComponent>
      <ConstraintsComponent
        constraints={[
          {
            label: <FormattedMessageComponent id={CONSTRAINT_1} />,
            check: value => challenged || solvedChallenged || checkHasFourNumbersOnly(value),
          },
          {
            label: <FormattedMessageComponent id={CONSTRAINT_2} />,
            check: value =>
              challenged || solvedChallenged || checkNoAscendingDescendingSequences(value),
          },
          {
            label: <FormattedMessageComponent id={CONSTRAINT_3} />,
            check: value =>
              challenged || solvedChallenged || noContain3OrMoreConsecutiveAndEqualsNumbers(value),
          },
          {
            label: <FormattedMessageComponent id={CONSTRAINT_4} />,
            check: (value: string) =>
              challenged ||
              solvedChallenged ||
              notMatchMontDayOrYearCustomerBirthDate({ dateOfBirth, value }),
          },
        ]}
        value={newPin}
      />
    </CardSettingsChangePinConstraintsComponentStyled>
  );
};

export default CardSettingsChangePinConstraintsComponent;
