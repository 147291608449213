// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Types
import { TransactionsEppStep2AmountComponentType } from './types/transactions-epp-step2-amount.component.type';
// Utilities
import { getTransactionsEppStep2ResumeAmountTypeValue } from './utils/transactions-epp-step2-amount.utils';
import { useTransactionsEppStep2ResumeAmountHook } from './hooks/transactions-epp-step2-resume-amount.hook';

const TransactionsEppStep2AmountComponent = ({
  label,
  id,
  testId,
}: TransactionsEppStep2AmountComponentType): React.ReactElement => (
  <ResumeItemComponent
    {...{ testId }}
    label={<FormattedMessageComponent id={label} />}
    value={getTransactionsEppStep2ResumeAmountTypeValue({
      ...useTransactionsEppStep2ResumeAmountHook(),
      id,
    })}
  />
);

export { TransactionsEppStep2AmountComponent };
