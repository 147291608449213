// Vendors
import React from 'react';
// Components
import {
  AssetComponent,
  ParagraphComponent,
  ProgressBarComponent,
  TitleComponent,
} from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/verifying-details-credit-limit-increase.component.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
import VerifyingDetailsCreditLimitIncreaseComponentHook from './hooks/verifying-details-credit-limit-increase.component.hook';
// Translations
import translations from './translations/verifying-details-credit-limit-increase.component.translations';
// Styles
import {
  VerifyingDetailsContentStyled,
  VerifyingDetailsCreditLimitIncreaseStyled,
  VerifyingDetailsDescriptionStyled,
  VerifyingDetailsProgressBarStyled,
  VerifyingDetailsTextsStyled,
} from './verifying-details-credit-limit-increase.component.styled';

const VerifyingDetailsCreditLimitIncreaseComponent = (): React.ReactElement => {
  const { currentStep } = VerifyingDetailsCreditLimitIncreaseComponentHook();
  const { formatMessage } = useCbIntl();

  return (
    <VerifyingDetailsCreditLimitIncreaseStyled>
      <AssetComponent {...constants.ASSET_CONFIGURATION} />
      <VerifyingDetailsProgressBarStyled>
        <ProgressBarComponent {...{ currentStep }} {...constants.PROGRESS_BAR_CONFIGURATION} />
      </VerifyingDetailsProgressBarStyled>
      <VerifyingDetailsContentStyled>
        <VerifyingDetailsTextsStyled>
          <TitleComponent {...constants.TITLE_CONFIGURATION}>
            {formatMessage({ id: translations.TITLE })}
          </TitleComponent>
          <VerifyingDetailsDescriptionStyled>
            <ParagraphComponent {...constants.PARAGRAPH_CONFIGURATION}>
              {formatMessage({ id: translations.DESCRIPTION }, { br: <br /> })}
            </ParagraphComponent>
          </VerifyingDetailsDescriptionStyled>
        </VerifyingDetailsTextsStyled>
      </VerifyingDetailsContentStyled>
    </VerifyingDetailsCreditLimitIncreaseStyled>
  );
};

export default VerifyingDetailsCreditLimitIncreaseComponent;
