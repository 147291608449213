// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Services
import { fetchNotificationsList, fetchNotificationsUpdate } from 'services/notifications.services';
// Utilities
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Types
import {
  NotificationsListServiceProps,
  NotificationsStatusEnum,
} from 'services/types/notifications.services.type';

const notificationsDetailsResolver = async ({
  params,
  filters,
}: NotificationsListServiceProps): Promise<any> => {
  const [response, { status }] = await fetchNotificationsList({ params, filters });

  if (!wasSuccessRequest(status)) {
    return [null, true];
  }

  response.data = response.data.map(notification => ({
    notificationDate: new Date(notification.deliveryTimeStamp),
    title: notification.subject,
    text: notification.text,
    isReaded: notification.status.description === NotificationsStatusEnum.readed,
    messageId: notification.messageId,
  }));

  return [response, false];
};

const notificationsUpdateResolver = async (): Promise<any> => {
  const [, { status }] = await fetchNotificationsUpdate();

  return [null, wasSuccessRequest(status)];
};

export { notificationsDetailsResolver, notificationsUpdateResolver };
