// Resolvers
import { ChallengeRetryButtonClickHandlerType } from './types/challenge-retry-button-click-handler.handlers.type';
// Types
import { RequestAccessStep2ChallengeBuilderReturnHandlersType } from './types/request-access-step2-challenge-builder-return.handlers.type';
import { AccessPortabilityRequestStep2BuilderHandlersType } from './types/request-access-step2-challenge-builder.handlers.type';
import { RequestAccessStep2ChallengeMethodBuilderHandlersType } from './types/request-access-step2-challenge-method-builder.handlers.type';

const manageErrorOnRequestAccessEvent = ({
  setChallenged,
  setError,
  setFetching,
}: RequestAccessStep2ChallengeMethodBuilderHandlersType): void => {
  setFetching(false);
  setChallenged(false);
  setError(true);
};

const manageSuccessOnRequestAccessEvent = ({
  progressId,
  setChallenged,
  setFetching,
  setProgressId,
}: RequestAccessStep2ChallengeMethodBuilderHandlersType): void => {
  setFetching(false);
  setChallenged(false);
  if (progressId) {
    setProgressId(progressId);
  }
};

const challengeRetryButtonClickHandler = ({
  setError,
  setFetching,
}: ChallengeRetryButtonClickHandlerType) => {
  setFetching(true);
  setError(false);
};

const setupStartedChallengeEventHandler = ({
  setChallenged,
}: Pick<AccessPortabilityRequestStep2BuilderHandlersType, 'setChallenged'>): any => {
  setChallenged(true);
};

const RequestAccessStep2ChallengeHandlers = (
  props: AccessPortabilityRequestStep2BuilderHandlersType
): RequestAccessStep2ChallengeBuilderReturnHandlersType => ({
  handleManageErrorOnRequestAccessEvent: () => manageErrorOnRequestAccessEvent(props),
  handleManageSuccessOnRequestAccessEvent: ({ progressId }) =>
    manageSuccessOnRequestAccessEvent({ ...props, progressId }),
  handleChallengeRetryButtonClick: () => challengeRetryButtonClickHandler(props),
  handleSetupStartedChallengeEvent: () => setupStartedChallengeEventHandler(props),
});

export default RequestAccessStep2ChallengeHandlers;
