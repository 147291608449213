// Constants
import constants from './constants/dashboard.resolver.utils.constants';
// Types
import { DashboardComponentType } from '../../types/dashboard.component.type';

const generateBasicDashboardSkeleton = (
  response: { resource: DashboardComponentType } | Record<string, any>
): DashboardComponentType =>
  Object.assign(constants.DEFAULT_DASHBOARD_INFORMATION, response?.resource || {});

export { generateBasicDashboardSkeleton };
