const DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.secondStep.summary.';

const AMOUNT_PAID = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}amountPaid`,
  CONSUMPTION_CONCEPT = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}consumptionConcept`,
  CREATION_DATE = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}creationDate`,
  INSTALMENTS_PAID = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}instalmentsPaid`,
  INSTALMENTS_PAID_VALUE = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}instalmentsPaidValue`,
  ORIGINAL_AMOUNT = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}originalAmount`,
  OUTSTANDING_AMOUNT = `${DOMAIN_PAYMENT_METHOD_SECOND_STEP_SUMMARY}outstandingAmount`;

export {
  AMOUNT_PAID,
  CONSUMPTION_CONCEPT,
  CREATION_DATE,
  INSTALMENTS_PAID,
  INSTALMENTS_PAID_VALUE,
  ORIGINAL_AMOUNT,
  OUTSTANDING_AMOUNT,
};
