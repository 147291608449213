// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { ProfilePersonalNameAliasEditBuilderReturnTrackingHandlersType } from './types/profile-personal-name-alias-edit-builder-return.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const changeCurrentAliasAssetClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'changeCurrentAlias',
  },
  type: TrackingEventsEnum.LINK,
});

const ProfilePersonalNameAliasEditTrackingHandlers = (
  track: TrackBuilderType
): ProfilePersonalNameAliasEditBuilderReturnTrackingHandlersType => ({
  handleChangeCurrentAliasAssetClickTracking: () =>
    track(changeCurrentAliasAssetClickTrackingHandler()),
});

export default ProfilePersonalNameAliasEditTrackingHandlers;
