import { messageConstants } from '@openbank/cb-ui-commons';

const CARD_SETTINGS_CHANGE_3D_STEP_1_DESCRIPTION_PROPS = {
    testId: 'card-settings-change-3d-step1-description',
  },
  CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_PROPS = {
    testId: 'card-settings-change-3d-step1-message',
    type: messageConstants.TYPES.INFORMATIVE,
  },
  CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_DESCRIPTION_PROPS = {
    testId: 'card-settings-change-3d-step1-message-description',
  },
  THREE = 3;

export {
  CARD_SETTINGS_CHANGE_3D_STEP_1_DESCRIPTION_PROPS,
  CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_PROPS,
  CARD_SETTINGS_CHANGE_3D_STEP_1_MESSAGE_DESCRIPTION_PROPS,
  THREE,
};
