// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import {
  FILES_INITIAL_STATE,
  NOT_RECOGNIZED_DEFAULT_SELECTED_OPTIONS,
  TRANSACTIONS_REPORT_FRAUDULENT_MOVEMENT_CONTEXT_DEFAULT_VALUE,
} from './constants/transactions-report-fraudulent-movement.context.constants';
// Hooks
import BlockCardAddressHook from 'components/block-card/components/address/hooks/block-card-address.hook';
import BlockCardReasonHook from 'components/block-card/components/block-card-reason/hooks/block-card-reason.hook';
import CardStatusHook from 'hooks/card-status.hook';
// Types
import { FileDataType } from 'types/file-data.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { TransactionsReportFraudulentMovementContextType } from './types/transactions-report-fraudulent-movement.context.type';
import { SelectedOptionsType } from 'types/selected-options.type';

const TransactionsReportFraudulentMovementContext =
  createContext<TransactionsReportFraudulentMovementContextType>(
    TRANSACTIONS_REPORT_FRAUDULENT_MOVEMENT_CONTEXT_DEFAULT_VALUE
  );

export const TransactionsReportFraudulentMovementContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<any>> => {
  const [selectedOptions, setSelectedOptions] = useState<SelectedOptionsType>(
    NOT_RECOGNIZED_DEFAULT_SELECTED_OPTIONS
  );
  const { blockReason } = BlockCardReasonHook();
  const { status } = CardStatusHook();
  const [files, setFiles] = useState<FileDataType[]>(FILES_INITIAL_STATE);
  const { addresses } = BlockCardAddressHook();
  const value = useMemo(
    () => ({
      addresses,
      blockReason,
      files,
      selectedOptions,
      setFiles,
      setSelectedOptions,
      status,
    }),
    [addresses, blockReason, files, selectedOptions, status]
  );

  return (
    <TransactionsReportFraudulentMovementContext.Provider value={value}>
      {children}
    </TransactionsReportFraudulentMovementContext.Provider>
  );
};

export const TransactionsReportFraudulentMovementConsumerHook =
  (): TransactionsReportFraudulentMovementContextType =>
    useContext(TransactionsReportFraudulentMovementContext);

/* eslint-disable */
export const TransactionsReportFraudulentMovementConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<any>> =>
    (
      <TransactionsReportFraudulentMovementContextProvider>
        <Component {...props} />
      </TransactionsReportFraudulentMovementContextProvider>
    );
