// Types
import { CardSettingsCheck3dConfirmationMessageHookType } from './types/card-settings-check-3d-confirmation-message.hook.type';
// Utilities
import { getCheck3DMessageTypeAccordingTrustedDevice } from './utils/card-settings-check-3d-confirmation-message.hook.utils';

const useCardSettingsCheck3dConfirmationFooterHook =
  (): CardSettingsCheck3dConfirmationMessageHookType => {
    const devices = [];

    return {
      type: getCheck3DMessageTypeAccordingTrustedDevice(devices),
    };
  };

export default useCardSettingsCheck3dConfirmationFooterHook;
