// Vendors
import styled from 'styled-components';

const DashboardTableComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
DashboardTableComponentStyled.displayName = 'DashboardTableComponentStyled';

export { DashboardTableComponentStyled };
