// Vendors
import React from 'react';
// Components
import SecurityKeysAccessDevicesComponent from './components/access-devices/security-keys-access-devices.component';
import SecurityKeysAccessKeyComponent from './components/access-key/security-keys-access-key.component';
import SecurityKeysSidebarComponent from '../../components/sidebar/security-keys-sidebar.component';
import SecurityKeysSignatureKeyComponent from './components/signature-key/security-keys-signature-key.component';
import { SecurityKeys3DSecurityComponent } from './components/3d-security/3d-security.component';
// Styles
import { SecurityKeysLandingViewStyled } from './security-keys-landing.view.styled';
import {
  LayoutGridComponentStyled,
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const SecurityKeysLandingView = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <LayoutGridContentComponentStyled>
      <LayoutGridContentLeftComponentStyled>
        <SecurityKeysLandingViewStyled>
          <SecurityKeysAccessKeyComponent />
          <SecurityKeysSignatureKeyComponent />
          <SecurityKeysAccessDevicesComponent />
          <SecurityKeys3DSecurityComponent />
        </SecurityKeysLandingViewStyled>
      </LayoutGridContentLeftComponentStyled>
      <LayoutGridContentRightComponentStyled>
        <SecurityKeysSidebarComponent />
      </LayoutGridContentRightComponentStyled>
    </LayoutGridContentComponentStyled>
  </LayoutGridComponentStyled>
);

export default SecurityKeysLandingView;
