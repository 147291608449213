// Vendors
import styled from 'styled-components';

const CardSettingsCheck3dSidebarComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
`;
CardSettingsCheck3dSidebarComponentStyled.displayName = 'CardSettingsCheck3dSidebarComponentStyled';

export { CardSettingsCheck3dSidebarComponentStyled };
