// Vendors
import React from 'react';
// Components
import { FilterModalListSelectorDateComponent } from '../../components/list-selector-date/filter-modal-list-selector-date.component';
// Constants
// Enumerations
// Translations
import {
  GetTransactionsLandingFilterModalConfigurationReturnType,
  GetTransactionsLandingFilterModalConfigurationType,
} from '../transactions-filter-modal-configuration/types/transactions-landing-filter-modal.configuration.type';
// Types

const getDocumentsLandingFilterModalConfiguration = ({
  formatMessage,
  formValues,
  handleFormValuesChange,
}: GetTransactionsLandingFilterModalConfigurationType): GetTransactionsLandingFilterModalConfigurationReturnType => {
  return {
    filterModalContentConfiguration: [
      {
        id: 'list-dates',
        component: (
          <FilterModalListSelectorDateComponent
            {...{
              formValues,
              handleFormValuesChange,
            }}
          />
        ),
      },
    ],
  };
};

export { getDocumentsLandingFilterModalConfiguration };
