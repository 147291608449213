// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS = {
    id: 'download_simple',
    colors: {
      fill: 'linkPrimaryA',
    },
  },
  FINANCING_PPI_CONTRACTING_STEP_2_ERROR_ASSET_PROPS = {
    id: 'critical',
    colors: {
      fill: 'fontTertiaryE',
    },
  },
  FINANCING_PPI_CONTRACTING_STEP_2_LOADING_ASSET_PROPS = {
    id: 'loader',
    type: assetConstants.TYPES.IMAGE,
    extension: assetConstants.EXTENSIONS.GIF,
  };

export {
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_ERROR_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_2_LOADING_ASSET_PROPS,
};
