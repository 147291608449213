// Constants
import dashboardPointsLineGraphConstants from '../constants/dashboard-points-line-graph.constants';
// Hooks
import DashboardPointsLinegraphHook from '../hooks/dashboard-points-line-graph.component.hook';
// Types
import { LineGraphConfigurationType } from './types/dashboard-points-line-graph.configurations.type';

export const getLineGraphConfiguration = (isMobile: boolean): LineGraphConfigurationType => {
  const dashboarPointsLineGraphProps = {
    ...dashboardPointsLineGraphConstants.DASHBOARD_POINTS_LINE_GRAPH_PROPS,
  };

  const { formatMessage } = DashboardPointsLinegraphHook();
  const translations = {
    ...dashboarPointsLineGraphProps.translations,
    AVAILABLE_POINTS: formatMessage({
      id: dashboarPointsLineGraphProps.translations.AVAILABLE_POINTS,
    }),
    BALANCE: formatMessage({ id: dashboarPointsLineGraphProps.translations.BALANCE }),
    COLLECTED_POINTS: formatMessage({
      id: dashboarPointsLineGraphProps.translations.COLLECTED_POINTS,
    }),
    REDEEMED_POINTS: formatMessage({
      id: dashboarPointsLineGraphProps.translations.REDEEMED_POINTS,
    }),
    EMPTY_TITLE: formatMessage({
      id: dashboarPointsLineGraphProps.translations.EMPTY_TITLE,
    }),
    EMPTY_DESCRIPTION: formatMessage({
      id: dashboarPointsLineGraphProps.translations.EMPTY_DESCRIPTION,
    }),
  };

  return isMobile
    ? {
        ...dashboarPointsLineGraphProps,
        yearBalance: '',
        translations: { ...translations, BALANCE: '' },
      }
    : {
        ...dashboarPointsLineGraphProps,
        translations,
      };
};
