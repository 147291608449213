// Translations
import { INPUT_LABEL, INPUT_ERROR_MESSAGE, INPUT_HELPER_MESSAGE } from '../translations/recovery-key-step4-credit-card.translations';
// Types
import { GetInputConfigurationReturnType } from './types/recovery-key-step4-credit-card.configurations.return.type';
import { GetInputConfigurationType } from './types/recovery-key-step4-credit-card.configurations.type';

export const getPanInputConfiguration = (
  { formatMessage, value, onBlur, onChange, error }: GetInputConfigurationType
): GetInputConfigurationReturnType => ({
  label: formatMessage({ id: INPUT_LABEL }),
  errorMessage: error && formatMessage({ id: INPUT_ERROR_MESSAGE }),
  helperMessage: formatMessage({ id: INPUT_HELPER_MESSAGE }),
  limit: 4,
  maxLength: 4,
  value,
  onBlur,
  onChange
});