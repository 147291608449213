// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import { CARD_STATEMENTS_CONTEXT_DEFAULT_VALUE } from './constants/card-settings-statements.context.constants';
// Types
import {
  CardSettingsStatementsContextType,
  CardSettingsStatementsStatementContextType,
} from './types/card-settings-statements.context.type';
import { ReactChildrenType } from '@openbank/cf-ui-static-data';

const CardSettingsStatementsContext = createContext<CardSettingsStatementsContextType>(
  CARD_STATEMENTS_CONTEXT_DEFAULT_VALUE
);

const CardSettingsStatementsContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<CardSettingsStatementsContextType>> => {
  const [statements, setStatements] = useState<CardSettingsStatementsStatementContextType>({});

  return (
    <CardSettingsStatementsContext.Provider value={{ statements, setStatements }}>
      {children}
    </CardSettingsStatementsContext.Provider>
  );
};

const useCardSettingsStatementsContextConsumerHook = (): CardSettingsStatementsContextType =>
  useContext(CardSettingsStatementsContext);

/* eslint-disable */
const CardSettingsStatementsContextConsumerHOC =
  (
    Component: React.FunctionComponent<CardSettingsStatementsContextType>
  ): React.FunctionComponent<any> =>
  (props: any): React.ReactElement<React.Provider<CardSettingsStatementsContextType>> =>
    (
      <CardSettingsStatementsContextProvider {...props}>
        <Component {...props} />
      </CardSettingsStatementsContextProvider>
    );

export { useCardSettingsStatementsContextConsumerHook, CardSettingsStatementsContextConsumerHOC };
