// Vendors
import styled from 'styled-components';

export const DeviceModalContentStyled = styled.div.attrs(() => ({
  'data-testid': 'device-modal-content'
}))`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const DeviceModalContentHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: ${({ theme }) => theme.spaces.spacingS};
  justify-content: center;
  width: 100%;
`;

export const DeviceModalContentBodyStyled = styled.div`
  padding: ${({ theme }) => theme.spaces.spacingS} 0;
`;
