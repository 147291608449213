// Services
import { fetchCardControlLocationsService } from '../services/settings-card-control-locations.service';
// Types
import { CardControlLocationComponentType } from 'types/card-control-location.component.type';
// Utilities
import { isValidCardControlLocationsResponse } from './utils/settings-card-control-locations.resolver.utils';

const fetchCardControlLocationsResolver = async (
  cardId: string
): Promise<[CardControlLocationComponentType] | [null, true]> => {
  const [response] = await fetchCardControlLocationsService(cardId);

  return isValidCardControlLocationsResponse(response) ? [response.resource] : [null, true];
};

export { fetchCardControlLocationsResolver };
