// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { SupportCancelContractSecondStepChallengeTrackingHandlersType } from './types/support-cancel-contract-second-step-challenge-handlers-tracking-return.type';

const sendOtpCodeStep2TrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'verifyButton',
  },
  type: TrackingEventsEnum.LINK,
});

const sendOtpCodeSuccessStep2TrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'otpAuthenticationSMS',
    eventLabel: 'success',
  },
  type: TrackingEventsEnum.LINK,
});

const SupportCancelContractSecondStepChallengeTrackingHandlers = (
  track: TrackBuilderType
): SupportCancelContractSecondStepChallengeTrackingHandlersType => ({
  handleSendOtpCodeStep2TrackingHandler: () => track(sendOtpCodeStep2TrackingHandler()),
  handleSendOtpCodeSuccessStep2TrackingHandler: () =>
    track(sendOtpCodeSuccessStep2TrackingHandler()),
});

export default SupportCancelContractSecondStepChallengeTrackingHandlers;
