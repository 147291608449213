// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/settings-card-control-locations.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { SettingsCardControlLocationsContextType } from './types/settings-card-control-locations.context.type';
import { SettingsCardControlsLocationsMainCountryType } from '../types/settings-card-controls-locations-main-country.type';
import { CardControlContinentComponentType } from 'types/card-control-continent.component.type';

const CardSettingsSettingsCardControlLocationsContext =
  createContext<SettingsCardControlLocationsContextType>(constants.CONTEXT_DEFAULT_VALUE);

export const SettingsCardControlLocationsContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<SettingsCardControlLocationsContextType>
> => {
  const [continents, setContinents] = useState<CardControlContinentComponentType[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [mainCountry, setMainCountry] = useState<
    SettingsCardControlsLocationsMainCountryType | Record<string, any>
  >({});

  return (
    <CardSettingsSettingsCardControlLocationsContext.Provider
      value={{
        continents,
        keyword,
        mainCountry,
        setContinents,
        setKeyword,
        setMainCountry,
      }}
    >
      {children as React.ReactNode}
    </CardSettingsSettingsCardControlLocationsContext.Provider>
  );
};

export const SettingsCardControlLocationsContextConsumerHook =
  (): SettingsCardControlLocationsContextType =>
    useContext(CardSettingsSettingsCardControlLocationsContext);

/* eslint-disable */
export const SettingsCardControlLocationsContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<SettingsCardControlLocationsContextType>> =>
    (
      <SettingsCardControlLocationsContextProvider>
        <Component {...props} />
      </SettingsCardControlLocationsContextProvider>
    );
