// Vendors
import { useEffect } from 'react';
// Configurations
import { getTrackingIdAccordingConfiguredView } from 'tracking/configurations/app.tracking.configurations';
// Event Handlers
import AppTrackingHandlers from 'tracking/handlers/app.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Utilities
import { getPathnameFromBrowser } from 'utils/app-routing.utils';
import { buildPageName } from '../utils/wizard-feedback-host.utils';

const WizardFeedbackHook = ({ type }: any): any => {
  const { handleEnterSectionTracking } = AppTrackingHook(AppTrackingHandlers);

  useEffect(
    () => {
      const configuration = getTrackingIdAccordingConfiguredView(getPathnameFromBrowser());
      configuration &&
        handleEnterSectionTracking(
          buildPageName({
            type,
            page: configuration,
          })
        );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

export default WizardFeedbackHook;
