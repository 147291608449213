// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { DashboardPointsLineGraphComponent } from './components/line-graph/dashboard-points-line-graph.component';
import DashboardTotalPointsComponent from './components/total-points/dashboard-total-points.component';
import { FetchErrorComponent, LinkComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getFetchErrorConfiguration } from 'configurations/app.configurations';
// Constants
import constants from './constants/dashboard-points.constants';
import errorConstants from './constants/dashboard-points-error.constants';
// Hooks
import { useDashboardPointsHook } from './hooks/dashboard-points.hook';
// Styles
import { AppCardSectionComponentStyled } from 'styles/app.styled';
import { DashboardWrapperCardContainerStyled } from '../../dashboard.container.styled';
import {
  DashboardPointsComponentStyled,
  DashboardPointsRowStyled,
  DashboardPointsTopComponentStyled,
} from './dashboard-points.component.styled';
// Translations
import translations from './translations/dashboard-points.translations';
// Utils
import { getDashboardPointsError } from './components/line-graph/utils/dashboard-points.component.utils';

const DashboardPointsComponent = (): React.ReactElement => {
  const {
      availableBalance,
      availableBalanceIso,
      currencyCard,
      error: errorFlag,
      fetching,
      formatMessage,
      handleKnowMoreAboutPointsLinkClick,
      hasGainedPoints,
      historicalGainedBalance,
      historicalGainedBalanceIso,
      historicalTotalConsumedBalance,
      historicalTotalConsumedBalanceIso,
    } = useDashboardPointsHook(),
    error = getFetchErrorConfiguration({
      ...errorConstants.DASHBOARD_POINTS_ERROR_CONFIGURATION,
      error: getDashboardPointsError({ errorFlag, availableBalance }),
      formatMessage,
    });

  return (
    <AppCardSectionComponentStyled>
      <DashboardWrapperCardContainerStyled>
        <DashboardPointsComponentStyled>
          <DashboardPointsTopComponentStyled>
            <ParagraphComponent {...constants.DASHBOARD_POINTS_TITLE_PROPS}>
              <FormattedMessageComponent id={translations.TITLE} />
            </ParagraphComponent>
            <LinkComponent
              {...constants.DASHBOARD_POINTS_LINK_PROPS}
              onClick={handleKnowMoreAboutPointsLinkClick}
            >
              <FormattedMessageComponent id={translations.KNOW_MORE} />
            </LinkComponent>
          </DashboardPointsTopComponentStyled>
          <FetchErrorComponent {...{ fetching, error }}>
            <DashboardPointsRowStyled $hasGainedPoints={hasGainedPoints}>
              <DashboardTotalPointsComponent
                currency={currencyCard}
                showRespectively={hasGainedPoints}
                respectively={availableBalanceIso}
                total={availableBalance}
              />
              <DashboardPointsLineGraphComponent
                availablePoints={availableBalance}
                availableCreditPoints={availableBalanceIso}
                collectedPoints={historicalGainedBalance}
                collectedCreditPoints={historicalGainedBalanceIso}
                currencyCode={currencyCard}
                redeemedPoints={historicalTotalConsumedBalance}
                redeemedCreditPoints={historicalTotalConsumedBalanceIso}
              />
            </DashboardPointsRowStyled>
          </FetchErrorComponent>
        </DashboardPointsComponentStyled>
      </DashboardWrapperCardContainerStyled>
    </AppCardSectionComponentStyled>
  );
};

export { DashboardPointsComponent };
