// Constants
import { NUMBER } from 'constants/number.constants';
// Types
import { TransactionsReportPaymentAdditionalInfoActionsHandlersType } from './types/transactions-report-payment-additional-info-actions.handlers.type';
import { TransactionsReportPaymentAdditionalInfoActionsHandlersReturnType } from './types/transactions-report-payment-additional-info-actions-return.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';

const nextButtonClickHandler = ({
  paymentMethod,
  setPaymentMethod,
  setCurrentStep,
}: NextButtonClickHandlerType): void => {
  setPaymentMethod(paymentMethod);
  setCurrentStep(NUMBER.TWO);
};

export const TransactionsReportPaymentAdditionalInfoActionsHandlers = ({
  setPaymentMethod,
  setCurrentStep,
}: TransactionsReportPaymentAdditionalInfoActionsHandlersType): TransactionsReportPaymentAdditionalInfoActionsHandlersReturnType => ({
  handleNextButtonClick: ({ paymentMethod }) =>
    nextButtonClickHandler({
      paymentMethod,
      setPaymentMethod,
      setCurrentStep,
    }),
});
