// Constants
import { CHANGE_SIGNATURE_KEY_PATH } from './constants/expired-signature-message.handlers.constants';
// Types
import { ExpiredSignatureMessageHandlersReturnType } from './types/expired-signature-message.handlers.return.type';
import { ExpiredSignatureMessageHandlersType } from './types/expired-signature-message.handlers.type';

const onNavigateToChangeSignatureHandler = ({ navigate }: ExpiredSignatureMessageHandlersType): void => {
  navigate(CHANGE_SIGNATURE_KEY_PATH);
};

const ExpiredSignatureHandlers = (
  props: ExpiredSignatureMessageHandlersType
): ExpiredSignatureMessageHandlersReturnType => ({
  navigateToChangeSignatureHandler: () => onNavigateToChangeSignatureHandler(props)
});

export { ExpiredSignatureHandlers };
