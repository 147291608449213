const MESSAGE_PROPS = {
  type: 'informative',
  testId: 'personal-address-step2-summary-message',
};

const DESCRIPTION_PARAGRAPH_PROPS = {
  size: 'M',
  testId: 'personal-address-step2-summary-description',
};

const PRODUCT_DETAIL_PROPS = {
  testId: 'personal-address-step2-summary-product-detail',
};

const PRODUCT_DETAIL_PARAGRAPH_PROPS = {
  bold: true,
  size: 'M',
};

const SCRIPT_TITLE_PROPS = {
  bold: true,
};

const FIRST_SCRIPT_TITLE_PROPS = {
  ...SCRIPT_TITLE_PROPS,
  testId: 'personal-address-step2-summary-script-1-title',
};

const FIRST_SCRIPT_DESCRIPTION_PROPS = {
  testId: 'personal-address-step2-summary-script-1-description',
};

const SECOND_SCRIPT_TITLE_PROPS = {
  ...SCRIPT_TITLE_PROPS,
  testId: 'personal-address-step2-summary-script-2-title',
};

const SECOND_SCRIPT_DESCRIPTION_PROPS = {
  testId: 'personal-address-step2-summary-script-2-description',
};

export {
  DESCRIPTION_PARAGRAPH_PROPS,
  FIRST_SCRIPT_DESCRIPTION_PROPS,
  FIRST_SCRIPT_TITLE_PROPS,
  MESSAGE_PROPS,
  PRODUCT_DETAIL_PARAGRAPH_PROPS,
  PRODUCT_DETAIL_PROPS,
  SECOND_SCRIPT_DESCRIPTION_PROPS,
  SECOND_SCRIPT_TITLE_PROPS,
};
