// Vendors
import React from 'react';
import { FormattedNumber } from 'react-intl';
// Components
import { TransactionTableRowAmountHelperComponent } from './components/helper/transaction-table-row-amount-helper.component';
// Constants
import { TRANSACTION_TABLE_ROW_AMOUNT_PROPS } from './constants/transaction-table-row-amount.constants';
import { MiscellaneousCurrencyConstants } from '@openbank/cf-ui-static-data';
// Styles
import {
  TransactionTableRowAmountComponentStyled,
  ParagraphComponentStyled,
} from './transaction-table-row-amount.component.styled';
// Types
import { TransactionTableRowAmountType } from './types/transaction-table-row-amount.component.types';
// Utilities
import {
  findTransactionTotalAmount,
  isTransactionTotalAmountPositive,
  transaformTransactionTotalAmount,
} from 'utils/transactions/transactions.utils';

const TransactionTableRowAmountComponent = ({
  amounts,
  transactionStatusCode,
  transactionLabel,
}: TransactionTableRowAmountType): React.ReactElement => {
  const currency = amounts[0]?.currency;
  return (
    <TransactionTableRowAmountComponentStyled>
      <ParagraphComponentStyled
        {...{
          ...TRANSACTION_TABLE_ROW_AMOUNT_PROPS,
          positive: isTransactionTotalAmountPositive(amounts),
        }}
      >
        {isTransactionTotalAmountPositive(amounts) && transaformTransactionTotalAmount(amounts)}
        <FormattedNumber
          {...{ ...MiscellaneousCurrencyConstants.AMOUNT_FORMAT_PROPS, currency }}
          value={findTransactionTotalAmount(amounts)}
        />
      </ParagraphComponentStyled>
      <TransactionTableRowAmountHelperComponent {...{ transactionStatusCode, transactionLabel }} />
    </TransactionTableRowAmountComponentStyled>
  );
};

export { TransactionTableRowAmountComponent };
