// Constants
import { PUBLIC_AMAZON_SITE_URL } from './constants/login-header.handlers.constants';
// Types
import { LoginHeaderBuilderReturnHandlersType } from './types/login-header-builder-return.handlers.type';
import { LoginHeaderBuilderHandlersType } from './types/login-header-builder.handlers.type';

const exitFromLoginViewHandler = ({
  handleExitFromLoginViewTracking,
}: LoginHeaderBuilderHandlersType): any => {
  handleExitFromLoginViewTracking();
  window.location.replace(PUBLIC_AMAZON_SITE_URL);
};

const LoginHeaderHandlers = (
  props: LoginHeaderBuilderHandlersType
): LoginHeaderBuilderReturnHandlersType => ({
  handleExitFromLoginView: () => exitFromLoginViewHandler(props),
});

export default LoginHeaderHandlers;
