// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/settings-card-control-locations.services.constants';
// Types
import { SettingsCardControlsLocationsApiReturnType } from '../types/settings-card-controls-locations-api-return.type';

const fetchCardControlLocationsService = async (
  cardId: string
): Promise<[SettingsCardControlsLocationsApiReturnType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...constants.CARD_CONTROL_LOCATIONS_SERVICE_CONFIGURATION,
    pathParams: { cardId },
  });

export { fetchCardControlLocationsService };
