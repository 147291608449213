// Vendors
import useCbIntl from 'hooks/useCbIntl';
// Configurations
import { getDashboardSpentMessageConfiguration } from '../hooks/configurations/dashboard-spent-message.configuration';
// Contexts
import { DashboardContextConsumerHook } from 'containers/dashboard/contexts/dashboard.context';
// Types
import { DashboardSpentMessageComponentHookType } from '../hooks/types/dashboard-spent-message.hook.type';
// Utils
import { getDefaultCardInfo } from 'containers/dashboard/utils/get-default-card-info.utils';

const DashboardSpentMessageComponent = (): DashboardSpentMessageComponentHookType => {
  const { formatMessage, formatNumber } = useCbIntl();
  const { information } = DashboardContextConsumerHook();
  const {
    primaryAccountId,
    creditDetails: { currentDueAmount = 0 } = {},
    blockDetails: { nonPaid = false } = {},
  } = getDefaultCardInfo(information) ?? {};

  const spentMessageConfiguration = getDashboardSpentMessageConfiguration({
    nonPaid,
    currentDueAmount,
    formatMessage,
    formatNumber,
    primaryAccountId,
  });

  return {
    spentMessageConfiguration,
  };
};

export default DashboardSpentMessageComponent;
