// Vendors
import { useEffect, useState } from 'react';
// Event handlers
import TransactionsTableListGroupsHandlers from '../handlers/transactions-table-list-groups.handlers';
// Types
import { TransactionsTableListGroupsGroupComponentType } from '../types/transactions-table-list-groups-group.component.type';
import { TransactionsTableListGroupsPropsHookType } from './types/transactions-table-list-groups-props.hook.type';
import { TransactionsTableListGroupsHookType } from './types/transactions-table-list-groups.hook.type';

const TransactionsTableListGroupsHook = (
  props: TransactionsTableListGroupsPropsHookType
): TransactionsTableListGroupsHookType => {
  const [groups, setGroups] = useState<TransactionsTableListGroupsGroupComponentType[]>([]);

  const { handleSetupTransactionsLandingTableGroups } = TransactionsTableListGroupsHandlers({
    ...props,
    setGroups,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupTransactionsLandingTableGroups();
  }, []);

  return {
    groups,
  };
};

export default TransactionsTableListGroupsHook;
