// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ResumeItemComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/personal-email-new-email.constants';
// Contexts
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
// Translations
import translations from './translations/personal-email-new-email.translations';
// Utilities
import { blurEmail } from './utils/personal-email-new-email.utils';

const PersonalEmailNewEmailComponent = (): React.ReactElement => {
  const { newEmail } = PersonalEmailContextConsumerHook();

  return (
    <ResumeItemComponent
      {...constants.PERSONAL_EMAIL_NEW_EMAIL_RESUME_ITEM_PROPS}
      label={<FormattedMessageComponent id={translations.NEW_EMAIL} />}
      value={blurEmail(newEmail)}
    />
  );
};

export default PersonalEmailNewEmailComponent;
