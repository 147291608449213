const WEBSOCKET_SEND_MESSAGE_CONSTANTS = {
  eventType: '$default',
} as const;

const SERVICE_CARDS_CREDIT = 'cb-api-cards-credit';

const SERVICE_WEBID = 'cb-api-webid';

const WEBSOCKET_NOT_AVAILABLE = 'Websocket not available';

export {
  WEBSOCKET_SEND_MESSAGE_CONSTANTS,
  WEBSOCKET_NOT_AVAILABLE,
  SERVICE_CARDS_CREDIT,
  SERVICE_WEBID,
};
