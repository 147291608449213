// Constants
import { titleConstants } from '@openbank/cb-ui-commons';

export default {
  TITLE_SECTION_DESCRIPTION_PROPS: {
    bold: false,
    tag: titleConstants.TAGS.H2,
    testId: 'title-section-section-table-row-description',
  },
};
