// Vendors
import { UseCbIntlReturnType } from 'hooks/useCbIntl/types/use-cb-intl-return.type';
// Components
import { ConfirmationNextStepsComponent } from '@openbank/cb-ui-commons';
// Translations
import translations from '../translations/step-3-credit-limit-increase.translations';

export const getConfirmationNextStepsConfiguration = (
  formatMessage: UseCbIntlReturnType['formatMessage']
): React.ComponentProps<typeof ConfirmationNextStepsComponent> => ({
  isTitleHeader: true,
  steps: [
    {
      description: formatMessage({ id: translations.HOW_CONNECT_BANKS_1 }),
    },
    {
      description: formatMessage({ id: translations.HOW_CONNECT_BANKS_2 }),
    },
    {
      description: formatMessage({ id: translations.HOW_CONNECT_BANKS_3 }),
    },
    {
      description: formatMessage({ id: translations.HOW_CONNECT_BANKS_4 }),
    },
  ],
  testId: 'confirmation-next-steps',
  title: formatMessage({ id: translations.HOW_CONNECT_BANKS }),
});
