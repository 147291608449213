// Types

const convertBlobToBase64 = async (blob: Blob): Promise<any> =>
  new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });

export { convertBlobToBase64 };
