// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { RequestSignatureKeyWizardTrackingHandlersType } from './types/request-signature-key-wizard.tracking.handlers.type';

const enterRequestSignatureKeyWizardTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'requestNewSignatureKeyOpen',
  },
  type: TrackingEventsEnum.LINK,
});

const RequestSignatureKeyWizardTrackingHandlers = (
  track: TrackBuilderType
): RequestSignatureKeyWizardTrackingHandlersType => ({
  handleEnterRequestSignatureKeyWizardTracking: () => track(enterRequestSignatureKeyWizardTrackingHandler()),
});

export default RequestSignatureKeyWizardTrackingHandlers;
