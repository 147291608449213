// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/personal-email-step3-actions.services.constants';
// Types
import { PersonalEmailStep3ActionsApiBuilderType } from '../types/personal-email-step3-actions-api-builder.type';
import { PersonalEmailStep3ActionsApiReturnType } from '../types/personal-email-step3-actions-api-return.type';

const changeUserEmailOtpService = async ({
  customerId,
  email,
  progressId,
}: PersonalEmailStep3ActionsApiBuilderType): Promise<
  [PersonalEmailStep3ActionsApiReturnType | Record<string, any>, Response]
> =>
  apiUtilsCb.patch({
    ...constants.CHANGE_EMAIL_SERVICE_CONFIGURATION,
    customHeaders: { progressId },
    pathParams: { customerId },
    params: {
      email,
    },
  });

export { changeUserEmailOtpService };
