// Contexts
import { SupportCancelContractContextConsumerHook } from 'containers/support/views/cancel-contract/contexts/support-cancel-contract.context';
// Event handlers
import SupportCancelContractSecondStepChallengeHandlers from '../handlers/support-cancel-contract-second-step-challenge.handlers';
import CancelContractStep2ChallengeTrackingHandlers from '../handlers/support-cancel-contract-second-step-challenge.tracking.handlers';
// Hooks
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { SupportCancelContractSecondStepChallengeType } from './types/support-cancel-contract-second-step-challenge.type';
import { AppContextConsumerHook } from 'contexts/app.context';
import { CharactersEnum } from '@openbank/cf-ui-static-data';

const SupportCancelContractSecondStepChallengeHook = (): SupportCancelContractSecondStepChallengeType => {
  const { setProgressId, setIsDisabledLastStep, reason } = SupportCancelContractContextConsumerHook();
  const { currentCard } = AppContextConsumerHook(),
    { cardContractId = CharactersEnum.NO_VALUE } = currentCard || {};
  const { handleSendOtpCodeStep2TrackingHandler, handleSendOtpCodeSuccessStep2TrackingHandler } =
    AppTrackingHook(CancelContractStep2ChallengeTrackingHandlers);

  const { handleManageSuccessOnChangeSmsEvent } = SupportCancelContractSecondStepChallengeHandlers({
    handleSendOtpCodeStep2TrackingHandler,
    handleSendOtpCodeSuccessStep2TrackingHandler,
    setIsDisabledLastStep,
    setProgressId,
  });

  return {
    cardContractId,
    handleManageSuccessOnChangeSmsEvent,
    reason,
  };
};

export default SupportCancelContractSecondStepChallengeHook;
