// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Enumerations
import { PasswordRecoveryOptionsEnumeration } from 'enumerations/sss/password-recovery-options.enumeration';
// Types
import { RecoveryKeyStep3TrackingHandlersType } from './types/recovery-key-step3.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const recoveryOptionItemRadioClickTrackingHandler = (
  id: PasswordRecoveryOptionsEnumeration
): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickRadio',
    eventLabel: 'recoverOption:' + id,
  },
  type: TrackingEventsEnum.LINK,
});

const RecoveryKeyStep3TrackingHandlers = (
  track: TrackBuilderType
): RecoveryKeyStep3TrackingHandlersType => ({
  handleRecoveryOptionItemRadioClickTracking: (id: PasswordRecoveryOptionsEnumeration) =>
    track(recoveryOptionItemRadioClickTrackingHandler(id)),
});

export default RecoveryKeyStep3TrackingHandlers;
