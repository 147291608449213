// Vendors
import React from 'react';
// Components
import { SliderComponent } from '@openbank/cb-ui-commons';
// Configurations
import { getSliderConfiguration } from './configurations/financing-payment-method-first-step-revolving-fee-slider.component.configurations';
// Hooks
import FinancingPaymentMethodFirstStepRevolvingFeeSliderHook from './hooks/financing-payment-method-first-step-revolving-fee-slider.hook';

const FinancingPaymentMethodFirstStepRevolvingFeeSliderComponent =
  (): React.ReactElement | null => {
    const { revolvingMethod, revolvingOptions, ...rest } =
      FinancingPaymentMethodFirstStepRevolvingFeeSliderHook();

    return revolvingMethod && revolvingOptions ? (
      <SliderComponent
        {...getSliderConfiguration({ revolvingMethod, revolvingOptions, ...rest })}
      />
    ) : null;
  };

export default FinancingPaymentMethodFirstStepRevolvingFeeSliderComponent;
