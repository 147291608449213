const DOMAIN_EARLY_REPAYMENT_SIDEBAR =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.sidebar.';
const DOMAIN_EARLY_REPAYMENT_SIDEBAR_ROWS =
  'cobranded.services.userSettings.privateArea.financing.earlyRepayment.sidebar.rows.';

export default {
  TITLE: `${DOMAIN_EARLY_REPAYMENT_SIDEBAR}title`,
  ROWS: {
    FIRST: {
      TITLE: `${DOMAIN_EARLY_REPAYMENT_SIDEBAR_ROWS}first.title`,
      CONTENT: `${DOMAIN_EARLY_REPAYMENT_SIDEBAR_ROWS}first.content`,
    },
    SECOND: {
      TITLE: `${DOMAIN_EARLY_REPAYMENT_SIDEBAR_ROWS}second.title`,
      CONTENT: `${DOMAIN_EARLY_REPAYMENT_SIDEBAR_ROWS}second.content`,
    },
  },
};
