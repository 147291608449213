// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { FINANCING_INSURANCES_LANDING_GO_TO_FINANCING_TRACKING_PROPS } from './constants/financing-insurances-landing-no-insurances.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { FinancingInsurancesLandingNoInsurancesReturnHandlersTrackingType } from './types/financing-insurances-landing-no-insurances-return.handlers.tracking.type';

const goToFinancingButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...FINANCING_INSURANCES_LANDING_GO_TO_FINANCING_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const FinancingInsurancesLandingNoInsurancesTrackingHandlers = (
  track: TrackBuilderType
): FinancingInsurancesLandingNoInsurancesReturnHandlersTrackingType => ({
  handleGoToFinancingButtonClickTracking: () => track(goToFinancingButtonClickTrackingHandler()),
});

export { FinancingInsurancesLandingNoInsurancesTrackingHandlers };
