// Enumerations
import { PersonalUploadDocumentationFlows } from 'containers/personal/views/upload-documentation/enumerations/personal-upload-documentation-flows.enum';
// Resolvers
import { getRequestedDocumentsResolver } from '../resolvers/personal-upload-documentation.resolvers';
// Utilities
import { mapRequestedDocumentsToFileData } from './utils/personal-upload-documentation.handlers.utils';
// Types
import { PersonalUploadDocumentationHandlersPropsType } from './types/personal-upload-documentation-props.handlers.type';
import { PersonalUploadDocumentationHandlersType } from './types/personal-upload-documentation.handlers.type';

const onGetRequestedDocumentsHandler = async ({
  onChangeFlow,
  setError,
  setFetching,
  setFiles,
}: Omit<PersonalUploadDocumentationHandlersPropsType, 'setResult'>): Promise<void> => {
  setFetching(true);

  const [response, error] = await getRequestedDocumentsResolver();

  if (error) {
    setError(true);
    return;
  }

  if (!response?.length) {
    onChangeFlow(PersonalUploadDocumentationFlows.NO_DOCS);
    return;
  }

  setFiles(mapRequestedDocumentsToFileData(response));

  setFetching(false);
};

const onHandleSendDocumentsHandler = ({
  setResult,
}: Pick<PersonalUploadDocumentationHandlersPropsType, 'setResult'>): void => setResult(true);

const PersonalUploadDocumentationHandlers = ({
  onChangeFlow,
  setError,
  setFetching,
  setFiles,
  setResult,
}: PersonalUploadDocumentationHandlersPropsType): PersonalUploadDocumentationHandlersType => ({
  handleGetRequestedDocuments: () =>
    onGetRequestedDocumentsHandler({ onChangeFlow, setError, setFetching, setFiles }),
  handleSendDocuments: () => onHandleSendDocumentsHandler({ setResult }),
});

export { PersonalUploadDocumentationHandlers };
