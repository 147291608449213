// Vendors
import React from 'react';
// Enumerations
import { DocumentsUploadErrorsEnumeration } from '../../enumerations/documents-upload-errors.enumeration';
// Translations
import { UPLOAD_DOCUMENT_TRANSLATIONS } from 'translations/upload-document.translations';

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const VALID_FORMATS = ['application/pdf', 'image/jpeg', 'image/bmp', 'image/png'];

const TITLE_PREFIX = 'documentType.';

const ERROR_MESSAGES = {
  [DocumentsUploadErrorsEnumeration.OVERSIZED_FILE]: {
    backOfficeErrorMessage: UPLOAD_DOCUMENT_TRANSLATIONS.BACK_OFFICE_OVERSIZE_FILE_MESSAGE,
    backOfficeErrorTag: UPLOAD_DOCUMENT_TRANSLATIONS.BACK_OFFICE_OVERSIZE_FILE_TAG,
  },
  [DocumentsUploadErrorsEnumeration.INVALID_FORMAT]: {
    backOfficeErrorMessage: UPLOAD_DOCUMENT_TRANSLATIONS.BACK_OFFICE_INVALID_FORMAT_MESSAGE,
    backOfficeErrorTag: UPLOAD_DOCUMENT_TRANSLATIONS.BACK_OFFICE_INVALID_FORMAT_FILE_TAG,
  },
};

export { ERROR_MESSAGES, MAX_FILE_SIZE, TITLE_PREFIX, VALID_FORMATS };
