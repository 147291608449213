// Vendors
import React from 'react';
// Components
import ErrorWrapperComponent from 'components/error-wrapper/error-wrapper.component';
import CardSettingsBlockCardSidebarComponent from './components/sidebar/card-settings-block-card-sidebar.component';
import { OperativeLayoutComponent } from 'components/operative-layout/operative-layout.component';
import CardSettingsBlockCardReplacementModalComponent from './components/replacement-modal/card-settings-block-card-replacement-modal.component';
import WizardComponent from 'components/wizard/wizard.component';
// Constants
import { BLOCK_CARD_WIZARD_PROPS } from './constants/card-settings-block-card.constants';
// Contexts
import { BlockCardContextConsumerHOC } from 'components/block-card/contexts/block-card.context';
// Hooks
import CardSettingsBlockCardHook from './hooks/card-settings-block-card.hook';

const CardSettingsBlockCardComponent = (): React.ReactElement => {
  const { error, replacementModalActions, showReplacementModal } = CardSettingsBlockCardHook();

  return (
    <ErrorWrapperComponent error={error}>
      <OperativeLayoutComponent sidebarComponent={CardSettingsBlockCardSidebarComponent}>
        <WizardComponent {...BLOCK_CARD_WIZARD_PROPS} />
      </OperativeLayoutComponent>
      <CardSettingsBlockCardReplacementModalComponent
        isOpen={showReplacementModal}
        actions={replacementModalActions}
      />
    </ErrorWrapperComponent>
  );
};

export { CardSettingsBlockCardComponent };

export default BlockCardContextConsumerHOC(CardSettingsBlockCardComponent);
