// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  PERSONAL_EMAIL_STEP_3_MESSAGE_PROPS: {
    testId: 'personal-email-step3-message',
    type: messageConstants.TYPES.WARNING,
  },
  PERSONAL_EMAIL_STEP_3_MESSAGE_DESCRIPTION_PROPS: {
    testId: 'personal-email-step3-message-table-row-description',
  },
  PERSONAL_EMAIL_STEP_3_DESCRIPTION_PROPS: {
    testId: 'personal-email-step3-table-row-description',
  },
};
