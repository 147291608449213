const DOMAIN = 'cobranded.services.userSettings.privateArea.cbModalAddress.';
const DOMAIN_INPUTS = 'cobranded.services.userSettings.privateArea.cbModalAddress.inputs.';

const NO_RESULTS = {
  noResultsTitle: `${DOMAIN_INPUTS}noResults`,
  noResultsDescription: `${DOMAIN_INPUTS}noResultsDescription`,
};

const ADDITIONAL = {
  label: `${DOMAIN_INPUTS}additional.label`,
  deleteLabel: `${DOMAIN_INPUTS}additional.delete`,
};

const NUMBER = {
  label: `${DOMAIN_INPUTS}number.label`,
};

const ZIP = {
  label: `${DOMAIN_INPUTS}zip.label`,
};

const ERROR_MESSAGES = {
  FORMAT: `${DOMAIN}errorMessages.format`,
  NUMBER_MAX_LENGTH: `${DOMAIN_INPUTS}errorMessages.numberMaxLength`,
  REQUIRED: `${DOMAIN_INPUTS}errorMessages.required`,
  STREET_MAX_LENGTH: `${DOMAIN_INPUTS}errorMessages.streetMaxLength`,
  ZIP_FORMAT: `${DOMAIN_INPUTS}errorMessages.zipFormat`,
  ZIP_MAX_LENGTH: `${DOMAIN_INPUTS}errorMessages.zipMaxLength`,
  ZIP_NOT_MATCH: `${DOMAIN_INPUTS}errorMessages.zipNotMatch`,
};

export { ADDITIONAL, NUMBER, ZIP, NO_RESULTS, ERROR_MESSAGES };
