// Constants
import {
  CONFIRMATION_NEXT_STEPS_PROPS,
  STEPS,
} from './constants/card-settings-block-card-success-content.configurations.constants';
// Translations
import { TITLE } from './translations/card-settings-block-card-success-content.configurations.translations';
// Types
import { GetConfirmationNextStepsConfigurationReturnType } from './types/get-confirmation-next-steps-configuration-return.type';
import { GetConfirmationNextStepsConfigurationType } from './types/get-confirmation-next-steps-configuration.type';

export const getConfirmationNextStepsConfiguration = ({
  formatMessage,
}: GetConfirmationNextStepsConfigurationType): GetConfirmationNextStepsConfigurationReturnType => ({
  ...CONFIRMATION_NEXT_STEPS_PROPS,
  steps: STEPS.map(({ title, description }) => ({
    description: formatMessage({ id: description }),
    title: formatMessage({ id: title }),
  })),
  title: formatMessage({ id: TITLE }),
});
