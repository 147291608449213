// Types
import { TransactionLandingTableApiBuilderComponentType } from 'containers/transactions/views/landing/services/types/transactions-landing-service.type';

const FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT: TransactionLandingTableApiBuilderComponentType =
  {
    endpoint: 'cb-api-cards/v4/cards/transactions',
    queryParams: {
      limit: 25,
      epp: true,
    },
  };

export { FETCH_TRANSACTIONS_SERVICE_CONFIGURATION_DEFAULT };
