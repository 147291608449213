const FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT = {
  tag: 'h5',
  bold: false,
};
const FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS = {
  showLineSeparator: true,
  tag: 'h5',
  testId: 'filter-modal-list-selector',
  gap: 'spacingS',
};

export { FILTER_MODAL_LIST_SELECTOR_DEFAULT_PROPS, FILTER_MODAL_LIST_SELECTOR_TITLE_COMPONENT };
