// Vendors
import React from 'react';
// Components
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import { LinkComponent } from '@openbank/cb-ui-commons';
// Constants
import { EARLY_REPAYMENT_LINK_PROPS } from './constants/financing-landing-row-details-early-repayment-link.component.constants';
// Enumerations
import { EppElementStatusEnumeration } from 'containers/financing/views/landing/components/row/components/tag/enumerations/epp-element-status.enumeration';
// Hooks
import FinancingLandingRowDetailsEarlyRepaymentLinkHook from './hooks/financing-landing-row-details-early-repayment-link.hook';
// Translations
import translations from './translations/financing-landing-row-details-early-repayment-link.translations';
// Types
import { FinancingLandingRowDetailsEarlyRepaymentLinkComponentType } from './types/financing-landing-row-details-early-repayment-link.component.type';

const FinancingLandingRowDetailsEarlyRepaymentLinkComponent = ({
  eppId,
  status,
}: FinancingLandingRowDetailsEarlyRepaymentLinkComponentType): React.ReactElement | null => {
  const { handleEarlyRepaymentLinkClick } = FinancingLandingRowDetailsEarlyRepaymentLinkHook({
    eppId,
  });

  if (
    status !== EppElementStatusEnumeration.CREATED &&
    status !== EppElementStatusEnumeration.REQUESTED &&
    status !== EppElementStatusEnumeration.PARTIAL_RETURNED
  ) {
    return null;
  }

  return (
    <LinkComponent {...EARLY_REPAYMENT_LINK_PROPS} onClick={handleEarlyRepaymentLinkClick}>
      <FormattedMessageComponent id={translations.EARLY_REPAYMENT_LINK} />
    </LinkComponent>
  );
};

export default FinancingLandingRowDetailsEarlyRepaymentLinkComponent;
