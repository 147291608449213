// Vendors
import React from 'react';
// Components
import { AssetComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Constants
import {
  COINS_ASSET_PROPS,
  HEALTH_ASSET_PROPS,
  ROW_FLEX_PROS,
  SECURITY_ASSET_PROPS,
  WAITING_ASSET_PROPS,
} from './constants/financing-ppi-contracting-step1-points.constants';
// Contexts
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Styles
import { LayoutRowFlexComponentStyled } from 'styles/app.styled';
// Translations
import {
  COINS_POINT_TEXT,
  HEALTH_POINT_TEXT,
  SECURITY_POINT_TEXT,
  WAITING_POINT_TEXT,
} from './translations/financing-ppi-contracting-step1-points.translations';

const FinancingPPIContractingStep1PointsComponent = (): React.ReactElement => {
  const { insurancesInfo } = useInsurancesContextConsumerHook();

  return (
    <>
      <LayoutRowFlexComponentStyled {...ROW_FLEX_PROS}>
        <AssetComponent {...SECURITY_ASSET_PROPS} />
        <ParagraphComponent>
          <FormattedMessageComponent id={SECURITY_POINT_TEXT} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...ROW_FLEX_PROS}>
        <AssetComponent {...HEALTH_ASSET_PROPS} />
        <ParagraphComponent>
          <FormattedMessageComponent id={HEALTH_POINT_TEXT} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...ROW_FLEX_PROS}>
        <AssetComponent {...WAITING_ASSET_PROPS} />
        <ParagraphComponent>
          <FormattedMessageComponent id={WAITING_POINT_TEXT} />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
      <LayoutRowFlexComponentStyled {...ROW_FLEX_PROS}>
        <AssetComponent {...COINS_ASSET_PROPS} />
        <ParagraphComponent>
          <FormattedMessageComponent
            id={COINS_POINT_TEXT}
            values={{ ppiPercentage: insurancesInfo?.insurances.CPI.ppiPercentage }}
          />
        </ParagraphComponent>
      </LayoutRowFlexComponentStyled>
    </>
  );
};

export { FinancingPPIContractingStep1PointsComponent };
