const DOMAIN_BLOCK_CARD_REPLACEMENT_MODAL =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.replacementModal.';
const DOMAIN_BLOCK_CARD_REPLACEMENT_MODAL_BUTTONS =
  'cobranded.services.userSettings.privateArea.cardSettings.blockCard.replacementModal.buttons.';

const TITLE = `${DOMAIN_BLOCK_CARD_REPLACEMENT_MODAL}title`;

const BUTTONS = {
  BACK: `${DOMAIN_BLOCK_CARD_REPLACEMENT_MODAL_BUTTONS}back`,
};

export { TITLE, BUTTONS };
