// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import { TRANSACTIONS_EPP_MODAL_LINK_TRACKING_PROPS } from './constants/transactions-epp-modal.handlers.tracking.constants';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TransactionsEppModalMethodsTrackingHandlersType } from './types/transactions-epp-modal.tracking.handlers.type';

const toggleAmortizationTableModalLinkClickTrackingHandler = (
  showModal: boolean
): TrackingLinkEventReturnType => ({
  payload: {
    ...TRANSACTIONS_EPP_MODAL_LINK_TRACKING_PROPS,
    eventLabel: `${showModal ? 'show' : 'hide'}AmortizationTableModal`,
  },
  type: TrackingEventsEnum.LINK,
});

const TransactionsEppModalTrackingHandlers = (
  track: TrackBuilderType
): TransactionsEppModalMethodsTrackingHandlersType => ({
  handleToggleAmortizationTableModalLinkClickTracking: (showModal: boolean) =>
    track(toggleAmortizationTableModalLinkClickTrackingHandler(showModal)),
});

export { TransactionsEppModalTrackingHandlers };
