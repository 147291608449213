// Constants
import { linkConstants, paragraphConstants } from '@openbank/cb-ui-commons';

const FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_PROPS = {
    testId: 'financing-ppi-contracting-step1-hiring-legal',
    size: paragraphConstants.SIZES.XS,
  },
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_LINK_PROPS = {
    size: linkConstants.SIZES.SMALL,
    testId: 'financing-ppi-contracting-step1-hiring-legal-link',
    type: linkConstants.TYPES.ACTION_SECONDARY,
  };

export {
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_PROPS,
  FINANCING_PPI_CONTRACTING_STEP_1_HIRING_LEGAL_LINK_PROPS,
};
