// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import translations from '../translations/dashboard.translations';
// Utils
import { QuickActionsSectionEnumeration } from 'enumerations/quick-actions/quick-actions-section.enumeration';

export default {
  CARD_STATUS_PROPS: {
    activateCardRedirection: {
      linkLabel: translations.TITLE,
      route: RoutesEnumeration.DASHBOARD,
    },
    breadCrumbPath: RoutesEnumeration.DASHBOARD,
  },
  DASHBOARD_CREDIT_CARD_PROPS: {
    title: translations.CREDIT_BALANCE,
    messageTooltip: translations.CREDIT_BALANCE_TOOLTIP,
  },
  DASHBOARD_QUICK_ACTIONS_PROPS: {
    id: QuickActionsSectionEnumeration.DASHBOARD,
  },
};
