// vendors
import { useState } from 'react';
// Contexts
import { useRecoveryKeyContextConsumerHook } from 'containers/recovery-key/contexts/recovery-key.context';
import { useRecoveryKeyStep5ContextConsumerHook } from 'containers/recovery-key/components/step5/contexts/recovery-key-step5.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Event handlers
import RecoveryKeyStep5ActionsHandlers from '../handlers/recovery-key-step5-actions.handlers';
// Types
import { RecoveryKeyStep5ActionsHookType } from './types/recovery-key-step5-actions.hook.type';

const useRecoveryKeyStep5ActionsHook = (): RecoveryKeyStep5ActionsHookType => {
  const [fetching, setFetching] = useState<boolean>(false);

  const { recoveryKey } = useRecoveryKeyStep5ContextConsumerHook(),
    { authData } = useRecoveryKeyContextConsumerHook(),
    { progressId } = authData || {},
    { setResult } = useWizardFeedbackContextConsumerHook();

  return {
    ...RecoveryKeyStep5ActionsHandlers({
      progressId,
      recoveryKey,
      setFetching,
      setResult,
    }),
    fetching,
    recoveryKey,
  };
};

export default useRecoveryKeyStep5ActionsHook;
