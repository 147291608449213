// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent, MessageComponent } from '@openbank/cb-ui-commons';
// Constants
import constants from './constants/settings-card-control-locations-info.constants';
// Translations
import translations from './translations/settings-card-control-locations-info.translations';

const SettingsCardControlLocationsInfoComponent = (): React.ReactElement => (
  <MessageComponent {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_INFO_PROPS}>
    <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_LOCATIONS_INFO_DESCRIPTION_PROPS}>
      <FormattedMessageComponent id={translations.DESCRIPTION} />
    </ParagraphComponent>
  </MessageComponent>
);

export default SettingsCardControlLocationsInfoComponent;
