import { EppElementStatusEnumeration } from '../enumerations/epp-element-status.enumeration';

const DOMAIN =
  'cobranded.services.userSettings.privateArea.financing.financingLanding.cbTable.cbTag.';

const FINANCING_TABLE_TAG_LABELS = {
  [EppElementStatusEnumeration.CREATED]: `${DOMAIN}inProgressLabel`,
  [EppElementStatusEnumeration.CANCELLED]: `${DOMAIN}completedLabel`,
  [EppElementStatusEnumeration.CLOSED]: `${DOMAIN}completedLabel`,
  [EppElementStatusEnumeration.ERROR]: `${DOMAIN}completedLabel`,
  [EppElementStatusEnumeration.PARTIAL_RETURNED]: `${DOMAIN}inProgressLabel`,
  [EppElementStatusEnumeration.REQUESTED]: `${DOMAIN}inProgressLabel`,
  [EppElementStatusEnumeration.RETURNED]: `${DOMAIN}completedLabel`,
};

export { FINANCING_TABLE_TAG_LABELS };
