// Vendors
import styled from 'styled-components';
import { mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const LabelsLinkCardComponentStyled = styled.div<{ useTitleHeader: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${({ theme, useTitleHeader }) =>
    useTitleHeader ? theme.spaces.spacingM : theme.spaces.spacingS};
  padding: ${({ theme, useTitleHeader }) =>
    `${theme.spaces.spacingL} ${
      useTitleHeader
        ? `calc(${theme.spaces.spacingXxxxl} + ${theme.spaces.spacingS})`
        : theme.spaces.spacingXl
    }`};

  ${tabletMediaQuery} {
    padding: ${({ theme, useTitleHeader }) =>
      useTitleHeader
        ? `calc(${theme.spaces.spacingL} + ${theme.spaces.spacingXs}) ${theme.spaces.spacingL}`
        : `${theme.spaces.spacingL} ${theme.spaces.spacingXl}`};
  }

  ${mobileMediaQuery} {
    padding: ${({ theme }) => theme.spaces.spacingL} ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const LabelsLinkCardContentComponentStyled = styled.section`
  color: ${({ theme }) => theme.colors.fontSecondaryA};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;
