// Vendors
import { useEffect, useState } from 'react';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { PersonalEmailContextConsumerHook } from 'containers/personal/views/email/contexts/personal-email.context';
// Event handlers
import PersonalEmailStep3ChallengeHandlers from '../handlers/personal-email-step3-challenge.handlers';
// Types
import { PersonalEmailStep3ChallengeHookType } from './types/personal-email-step3-challenge.hook.type';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';

const PersonalEmailStep3ChallengeHook = (): PersonalEmailStep3ChallengeHookType => {
  const [challengeError, setChallengeError] = useState<boolean>(false);

  const { profile } = AppContextConsumerHook(),
    { customerId } = profile?.userDetails || {};

  const { newEmail, setChallenged, setProgressId, setSignatureExpired, signatureExpired } = PersonalEmailContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();

  const {
    handleManageErrorOnChangeEmailEvent,
    handleManageSuccessOnChangeEmailEvent,
    handleSetupStartedChallengeEvent,
    handleChallengeRetryButtonClick,
  } = PersonalEmailStep3ChallengeHandlers({
    setChallenged,
    setProgressId,
    setResult,
    setChallengeError,
    setSignatureExpired
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupStartedChallengeEvent();
  }, []);

  return {
    challengeError,
    customerId,
    handleChallengeRetryButtonClick,
    handleManageErrorOnChangeEmailEvent,
    handleManageSuccessOnChangeEmailEvent,
    newEmail,
    signatureExpired
  };
};

export default PersonalEmailStep3ChallengeHook;
