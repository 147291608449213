// Types
import { CardSettingsChangePinNewPinHookType } from '../hooks/types/card-settings-change-pin-new-pin.hook.type';

const newPinComponentIsDisabled = ({
  challenged,
  solvedChallenged,
}: Pick<CardSettingsChangePinNewPinHookType, 'challenged' | 'solvedChallenged'>): boolean =>
  Boolean(challenged || solvedChallenged);

export { newPinComponentIsDisabled };
