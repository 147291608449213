// Types
import {
  TravelPlusLinksBuilderHandlersType,
  TravelPlusLinksInstanceBuilderHandlersType,
  TravelPlusLinksMethodBuilderHandlersType,
  TravelPlusLinksMethodsHandlersType,
} from './types/travel-plus-links.handlers.type';

const openTravelPlusModalLinkClickHandler = ({
  configuration,
  formatMessage,
  handleOpenTravelPlusModalLinkClickTracking,
  id,
  text,
}: TravelPlusLinksMethodBuilderHandlersType): void => {
  const url = configuration[id] || '';

  handleOpenTravelPlusModalLinkClickTracking(formatMessage({ id: text }));
  url && window.open(url);
};

const TravelPlusLinksHandlers = (
  props: TravelPlusLinksBuilderHandlersType
): TravelPlusLinksMethodsHandlersType => ({
  handleOpenTravelPlusModalLinkClick: (methodProps: TravelPlusLinksInstanceBuilderHandlersType) =>
    openTravelPlusModalLinkClickHandler({ ...props, ...methodProps }),
});

export { TravelPlusLinksHandlers };
