// Constants
import constants from './constants/personal-number-step4.challenge.constants';
import serviceConstants from '../services/constants/personal-number-step4-challenge.service.constants';
// Types
import { GetChallengeConfigurationType } from './types/get-challenge-configuration.type';
import { GetChallengeConfigurationReturnType } from './types/get-challenge-configuration-return.type';

export const getChallengeConfiguration = ({
  challengeError,
  newPhoneNumber: phone,
  newPrefix: countryCode,
  withEmail,
  id,
  handleChallengeError,
  handleChallengeSuccess,
  handleChallengeRetryButtonClick,
}: GetChallengeConfigurationType): GetChallengeConfigurationReturnType => ({
  baseService: serviceConstants.VALIDATE_OTP_SERVICE_CONFIGURATION.endpoint,
  baseServiceBuilder: { params: { id, countryCode, phone, withEmail } },
  baseServiceMethod: constants.PHONE_CHALLENGE_PROPS.baseServiceMethod,
  challengeError,
  onRetry: handleChallengeRetryButtonClick,
  onError: handleChallengeError,
  onSuccess: handleChallengeSuccess,
});
