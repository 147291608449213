// Types
import {
  RecoveryKeyStep1EmailBuilderHandlersType,
  RecoveryKeyStep1EmailBuilderMethodHandlersType,
  RecoveryKeyStep1EmailBuilderReturnHandlersType,
} from './types/recovery-key-email.handlers.type';
// Utilities
import { isValidEmail } from 'utils/email.utils';

const blurRecoveryKeyStep1EmailInputEventHandler = ({
  handleRecoveryKeyStep1EmailErrorInputEventTracking,
  email,
  setError,
}: Pick<
  RecoveryKeyStep1EmailBuilderHandlersType,
  'handleRecoveryKeyStep1EmailErrorInputEventTracking' | 'email' | 'setError'
>): void => {
  if (email && !isValidEmail(email)) {
    handleRecoveryKeyStep1EmailErrorInputEventTracking();
    setError(true);
  }
};

const saveRecoveryKeyStep1EmailInputEventHandler = ({
  inputEmail,
  setError,
  setEmail,
}: RecoveryKeyStep1EmailBuilderMethodHandlersType): void => {
  setError(false);
  setEmail(inputEmail);
};

const RecoveryKeyStep1EmailHandlers = ({
  handleRecoveryKeyStep1EmailErrorInputEventTracking,
  email,
  setError,
  setEmail,
}: RecoveryKeyStep1EmailBuilderHandlersType): RecoveryKeyStep1EmailBuilderReturnHandlersType => ({
  handleBlurRecoveryKeyStep1EmailInputEvent: () =>
    blurRecoveryKeyStep1EmailInputEventHandler({
      handleRecoveryKeyStep1EmailErrorInputEventTracking,
      email,
      setError,
    }),
  handleSaveRecoveryKeyStep1EmailInputEvent: (inputEmail: string) =>
    saveRecoveryKeyStep1EmailInputEventHandler({ inputEmail, setError, setEmail }),
});

export default RecoveryKeyStep1EmailHandlers;
