const FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_ASSET_PROPS = {
    id: 'critical',
    colors: {
      fill: 'backgroundErrorA',
    },
    sizes: {
      height: 16,
      width: 16,
    },
    testId: 'financing-ppi-contracting-download-error-asset',
  },
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_DESCRIPTION_PROPS = {
    testId: 'financing-ppi-contracting-download-error-description',
  };

export {
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ERROR_DESCRIPTION_PROPS,
};
