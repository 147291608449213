// Services
import { getPPIInfoService } from '../services/card-settings-contract-view.service';
// Types
import { PPIInfoResponseType } from '../services/types/card-settings-contract-view-service.type';
// Utils
import { httpStatusUtils } from '@openbank/cf-ui-architecture-core';

const getPPIInfoResolver = async (): Promise<[PPIInfoResponseType | null, boolean]> => {
  const [response, { status }] = await getPPIInfoService();
  return httpStatusUtils.isSuccessStatus(status) ? [response, false] : [null, true];
};

export { getPPIInfoResolver };
