// Vendors
import React from 'react';
// Components
import { DocumentsTableHeaderComponent } from './components/documents-table-header/documents-table-header.component';
import { DocumentsTableRowComponent } from './components/documents-table-row/documents-table-row.component';
import { useDocumentationTableHook } from './hooks/documents-table.hook';
// Types
import { type DocumentsType } from 'types/documents.type';

export const DocumentsTableComponent = ({
  documents,
}: {
  documents: DocumentsType[] | null;
}): React.ReactElement => {
  const { handleFetchDocumentLinkEvent } = useDocumentationTableHook();

  return (
    <div>
      <DocumentsTableHeaderComponent />
      {documents?.map((document: DocumentsType) => (
        <DocumentsTableRowComponent
          key={document.id}
          document={document}
          onClick={() => handleFetchDocumentLinkEvent({ document })}
        />
      ))}
    </div>
  );
};
