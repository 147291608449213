const chatPrefix = 'cb-api-comm-chat/v1/chat';
const chatSessionPrefix = `${chatPrefix}/:chatId`;

export default {
  GET_CHAT_MESSAGES_CONFIG: {
    endpoint: `${chatSessionPrefix}/messages`,
  },
  SEND_CHAT_MESSAGE_CONFIG: {
    endpoint: `${chatSessionPrefix}/messages`,
  },
  CLOSE_CHAT_SESSION_CONFIG: {
    endpoint: `${chatSessionPrefix}/end-conversation`,
  },
  START_CHAT_SESSION_CONFIG: {
    endpoint: `${chatPrefix}/start-conversation`,
  },
  GET_CHAT_STATUS_CONFIG: {
    endpoint: `${chatPrefix}/status`,
  },
  GET_CHAT_CONVERSATIONS_CONFIG: {
    endpoint: `${chatPrefix}/conversations`,
  },
};
