const CUSTOMER_INFO_URL = 'cb-api-customer/v2/customer/:customerId';
const LIMIT_INCREASE_URL = 'cb-api-cards-credit/v1/limit-increase';
const LIMIT_INCREASE_CASES_URL = `${LIMIT_INCREASE_URL}/cases`;
const PPI_INFO_URL = 'cb-api-cards-contra/v1/card-contracts/ppi/info';
const EXTEND_TOKEN_SERVICE_URL = `${LIMIT_INCREASE_URL}/extend-token`;
const TINK_EXTEND_TOKEN_SERVICE_URL = `${EXTEND_TOKEN_SERVICE_URL}/tink`;
const WEBID_EXTEND_TOKEN_SERVICE_URL = `${EXTEND_TOKEN_SERVICE_URL}/webid`;

export {
  CUSTOMER_INFO_URL,
  LIMIT_INCREASE_URL,
  LIMIT_INCREASE_CASES_URL,
  PPI_INFO_URL,
  TINK_EXTEND_TOKEN_SERVICE_URL,
  WEBID_EXTEND_TOKEN_SERVICE_URL,
};
