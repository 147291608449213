// Vendors
import styled from 'styled-components';

const SecurityComponentStyled = styled.div`
  width: 100%;
`;

SecurityComponentStyled.displayName = 'SecurityComponentStyled';

const SecurityContentStyled = styled.div`
  padding-bottom: ${({ theme }) => theme.spaces.spacingL};
`;

SecurityContentStyled.displayName = 'SecurityContentStyled';

export { SecurityComponentStyled, SecurityContentStyled };
