// Types
import {
  FinancingPpiContractingStepExpandableItemUtilsType,
  FinancingPpiContractingStepExpandableUtilsType,
} from './types/financing-ppi-contracting-step2-expandable.utils.type';
// Utilities
import { translateItemsList } from 'utils/translations.utils';

const mapAndTranslateSectionsListItems = ({
  formatMessage,
  items,
}: FinancingPpiContractingStepExpandableUtilsType): FinancingPpiContractingStepExpandableItemUtilsType[] =>
  items.map(({ label: id, subItems }: FinancingPpiContractingStepExpandableItemUtilsType) => ({
    label: formatMessage({ id }),
    subItems: translateItemsList({ formatMessage, list: subItems }),
  }));

export { mapAndTranslateSectionsListItems };
