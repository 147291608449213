import checkCvvPinConstants from 'containers/card-settings/views/check-cvv-pin/constants/check-cvv-pin.constants';

const { CVV, PIN, TRANSLATIONS: { CVV_DOMAIN, PIN_DOMAIN } } = checkCvvPinConstants;
const SECTION = 'koScreen.';

export default {
  [CVV]: {
    TITLE: `${CVV_DOMAIN}${SECTION}title`,
    DESCRIPTION: `${CVV_DOMAIN}${SECTION}description`,
    TRY_AGAIN: `${CVV_DOMAIN}${SECTION}tryAgain`
  },
  [PIN]: {
    TITLE: `${PIN_DOMAIN}${SECTION}title`,
    DESCRIPTION: `${PIN_DOMAIN}${SECTION}description`,
    TRY_AGAIN: `${PIN_DOMAIN}${SECTION}tryAgain`
  },
};
