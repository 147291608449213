// Enumerations
import { CardContractInfoSectionsEnumeration } from 'containers/card-settings/views/contract/components/current/enumerations/card-contract-info-sections.enumeration';
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
import { RoutesEnumeration as RoutesEnumerationCC } from 'enumerations/routes.enumeration.cc';
// Translations
import {
  AVAILABLE_AMOUNT,
  DRAWN_AMOUNT,
  LIMIT_AMOUNT,
  TITLE,
} from '../translations/card-settings-contract-current-credit.translations';
// Utilities
import { generateAbsoluteRoute, generatePathRoute } from 'utils/app-routing.utils';

const CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_CONSUMED_PROPS = {
  label: DRAWN_AMOUNT,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_LINK_PROPS = {
  trackingLabel: 'creditLimitDecreaseCC',
  url: generateAbsoluteRoute(
    generatePathRoute([RoutesEnumeration.FINANCING, RoutesEnumerationCC.CREDIT_LIMIT_DECREASE_CC])
  ),
};

const CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_PROPS = {
  label: LIMIT_AMOUNT,
};

const CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_DIVIDER_PROPS = {
  id: CardContractInfoSectionsEnumeration.CREDIT_LIMIT,
  title: TITLE,
};

export {
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_CONSUMED_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_DIVIDER_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_PROPS,
  CARD_SETTINGS_CONTRACT_CURRENT_CREDIT_LIMIT_LINK_PROPS,
};
