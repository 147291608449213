// Vendors
import styled from 'styled-components';
// Utilities
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsChangePinComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXl};

  ${mobileMediaQuery} {
    padding: 0 ${({ theme }) => theme.spaces.spacingS};
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;
CardSettingsChangePinComponentStyled.displayName = 'CardSettingsChangePinComponentStyled';

const CardSettingsChangePinContentComponentStyled = styled(CardSettingsChangePinComponentStyled)`
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${mobileMediaQuery} {
    padding: 0;
  }
`;
CardSettingsChangePinContentComponentStyled.displayName =
  'CardSettingsChangePinContentComponentStyled';

export { CardSettingsChangePinComponentStyled, CardSettingsChangePinContentComponentStyled };
