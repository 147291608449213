import { mobileMediaQuery } from '@openbank/cf-ui-framework';
// Vendors
import styled from 'styled-components';

export const Step1CreditLimitIncreaseStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;
`;
export const SetCardLimitFirstRowStyled = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};

  ${mobileMediaQuery} {
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const ProductDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;

export const FirstRowComponentWrapper = styled.div`
  flex: 1;
  padding: 0 ${({ theme }) => theme.spaces.spacingS};
`;

export const CreditLimitSliderWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacingXxs};

  ${mobileMediaQuery} {
    margin-top: 0;
  }
`;

export const ParagraphComponentWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spaces.spacingXxs};
`;
