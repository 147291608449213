// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { NotificationsLandingSideBarHookHandlersTrackingReturnType } from './types/notifications-landing-sidebar-hook-handlers-traking-return.type';

const FaqsClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink', 
    eventLabel: 'frequentlyAskedQuestionsLink' 
  },
  type: TrackingEventsEnum.LINK,
});

const SidebarHookTrankingHandlers = (
  track: (param: unknown) => void
): NotificationsLandingSideBarHookHandlersTrackingReturnType => ({
  handleClickFaqsTracking: () =>
    track(FaqsClickTrackingHandler()),
});

export default SidebarHookTrankingHandlers;