// Vendors
import React from 'react';
// Components
import { FinancingPPICancellationSidebarComponent } from './components/sidebar/financing-ppi-cancellation-sidebar.component';
import { FinancingPPICancellationLandingComponent } from './components/landing/financing-ppi-cancellation-landing.component';
// Styles
import {
  LayoutGridContentComponentStyled,
  LayoutGridContentLeftComponentStyled,
  LayoutGridContentRightComponentStyled,
} from 'styles/app.styled';

const FinancingPPICancellationComponent = (): React.ReactElement => (
  <LayoutGridContentComponentStyled>
    <LayoutGridContentLeftComponentStyled>
      <FinancingPPICancellationLandingComponent />
    </LayoutGridContentLeftComponentStyled>
    <LayoutGridContentRightComponentStyled>
      <FinancingPPICancellationSidebarComponent />
    </LayoutGridContentRightComponentStyled>
  </LayoutGridContentComponentStyled>
);

export { FinancingPPICancellationComponent };
