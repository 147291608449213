// Constants
import { paragraphConstants, linkConstants } from '@openbank/cb-ui-commons';

const NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PROPS = {
    testId: 'notifications-settings',
  },
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PARRAGRAPH_PROPS = {
    testId: 'notifications-settings-sidebar-parragraph-description',
    size: paragraphConstants.SIZES.M,
    type: linkConstants.TYPES.ACTION_PRIMARY,
    bold: true,
  },
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_LINK_PROPS = {
    testId: 'notifications-settings-sidebar-link-description',
    size: linkConstants.SIZES.BIG,
    type: linkConstants.TYPES.ACTION_PRIMARY,
  };

export {
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_PARRAGRAPH_PROPS,
  NOTIFICATION_SETTINGS_SIDEBAR_FAQ_LINK_PROPS,
};
