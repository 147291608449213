// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import { TRANSACTIONS_REPORT_CONTEXT_DEFAULT_VALUE } from './constants/transactions-report.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { ChallengeType } from 'types/challenge.type';
import { CardTransactionsDetailType } from 'types/card-transtions-details.type';
import { TransactionsReportContextType } from './types/transactions-report.context.type';

const TransactionsReportContext = createContext<TransactionsReportContextType>(
  TRANSACTIONS_REPORT_CONTEXT_DEFAULT_VALUE
);

export const TransactionsReportContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<TransactionsReportContextType>> => {
  const [error, setError] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(true);
  const [challenge, setChallenge] = useState<ChallengeType>();
  const [challengeError, setChallengeError] = useState<boolean>(false);
  const [transactionDetails, setTransactionDetails] = useState<CardTransactionsDetailType | null>(
    null
  );
  const [caseId, setCaseId] = useState<string | undefined>(undefined);

  const contextValue = useMemo(
    () => ({
      caseId,
      error,
      fetching,
      challenge,
      challengeError,
      setCaseId,
      setError,
      setFetching,
      setTransactionDetails,
      setChallenge,
      setChallengeError,
      transactionDetails,
    }),
    [caseId, error, fetching, challenge, challengeError, transactionDetails]
  );

  return (
    <TransactionsReportContext.Provider value={contextValue}>
      {children}
    </TransactionsReportContext.Provider>
  );
};

export const useTransactionsReportContextConsumerHook = (): TransactionsReportContextType =>
  useContext(TransactionsReportContext);

/* eslint-disable */
export const TransactionsReportContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<TransactionsReportContextType>> =>
    (
      <TransactionsReportContextProvider>
        <Component {...props} />
      </TransactionsReportContextProvider>
    );
