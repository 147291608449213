const DOMAIN = 'cobranded.services.userSettings.privateArea.cardSettings.changePin.cbFeedback.';

const ERROR_BUTTON = `${DOMAIN}tryAgainButton`,
  ERROR_LINK = `${DOMAIN}cancelLink`,
  ERROR_TITLE = `${DOMAIN}errorTitle`,
  SUCCESS_DESCRIPTION = `${DOMAIN}successDescription`,
  SUCCESS_LINK = `${DOMAIN}goToCardSettingsLink`,
  SUCCESS_TITLE = `${DOMAIN}successTitle`;

export { ERROR_BUTTON, ERROR_LINK, ERROR_TITLE, SUCCESS_DESCRIPTION, SUCCESS_LINK, SUCCESS_TITLE };
