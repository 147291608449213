// Vendors
import { useEffect, useState } from 'react';
import { useDocumentationContextConsumerHook } from '../contexts/documentation.context';
// Handlers
import { DocumentationHandlers } from '../handlers/documentation.handlers';
import { DocumentationTrackingHandlers } from '../handlers/documentation.tracking.handlers';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { type DocumentationReturnHookType } from './types/documentation-return.hook.type';
import { useFiltersContextConsumerHook } from 'components/filters/context/filters.context';

export const useDocumentationHook = (): DocumentationReturnHookType => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingTableContent, setLoadingTableContent] = useState<boolean>(false);

  const { formatMessage } = useCbIntl();

  const {
    categories,
    categoryError,
    categorySelected,
    documents,
    documentsError,
    setCategories,
    setCategoryError,
    setCategorySelected,
    setDocuments,
    setDocumentsError,
  } = useDocumentationContextConsumerHook();

  const {
    formValues,
    formValuesParams,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setIsShowFilterModal,
  } = useFiltersContextConsumerHook();

  const {
    handleCategoryClickEvent,
    handleFetchCategoriesEvent,
    handleFetchDocumentsByCategoryEvent,
    handleClickFilter,
  } = DocumentationHandlers({
    categorySelected,
    formValuesParams,
    setFormValues,
    setFormValuesParams,
    setCategories,
    setCategoryError,
    setCategorySelected,
    setDocuments,
    setDocumentsError,
    setLoading,
    setLoadingTableContent,
    setIsShowFilterModal,
    ...AppTrackingHook(DocumentationTrackingHandlers),
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (categorySelected !== '') {
      handleFetchDocumentsByCategoryEvent();
    }
  }, [categorySelected, formValuesParams]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleFetchCategoriesEvent();
  }, []);

  return {
    categories,
    categoryError,
    documents,
    documentsError,
    formatMessage,
    formValues,
    formValuesParams,
    handleCategoryClickEvent,
    handleClickFilter,
    handleFetchCategoriesEvent,
    handleFetchDocumentsByCategoryEvent,
    loading,
    loadingTableContent,
    setCopyOfFormValues,
    setFormValues,
    setFormValuesParams,
    setIsShowFilterModal,
  };
};
