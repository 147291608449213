// Vendors
import { useEffect } from 'react';
// Handlers
import { ChangeIbanStep2ChallengeHandlers } from '../handlers/change-iban-step2-challenge.handler';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
import { ChangeIbanContextConsumerHook } from 'containers/iban-billings/views/change-iban/contexts/change-iban.context';
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
// Types
import { ChangeIbanStep2ChallengeHookType } from './types/change-iban-step2-challenge.hook.type';

const ChangeIbanStep2ChallengeHook = (): ChangeIbanStep2ChallengeHookType => {
  const { error, setChallenged, setProgressId, setError, ibanValue } =
    ChangeIbanContextConsumerHook();
  const { setResult } = useWizardFeedbackContextConsumerHook();
  const { currentCard } = AppContextConsumerHook(),
    { cardContractId: cardId } = currentCard || {};
  const {
    handleChallengeRetryButtonClick,
    handleManageErrorOnChangeIbanEvent,
    handleManageSuccessOnChangeIbanEvent,
    handleSetupStartedChallengeEvent,
  } = ChangeIbanStep2ChallengeHandlers({
    setChallenged,
    setError,
    setProgressId,
    setResult,
  });
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    handleSetupStartedChallengeEvent();
  }, []);

  return {
    cardId,
    error,
    handleChallengeRetryButtonClick,
    handleManageErrorOnChangeIbanEvent,
    handleManageSuccessOnChangeIbanEvent,
    ibanValue,
  };
};

export { ChangeIbanStep2ChallengeHook };
