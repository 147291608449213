// Types
import {
  CardSettingsChangePinFeedbackBuilderHandlersType,
  CardSettingsChangePinFeedbackHandlersBuilderReturnHandlersType,
} from './types/card-settings-change-pin-feedback.handlers.type';

const retryChangeEmailProcessLinkClickHandler = ({
  setNewPin,
  setRepeatedPin,
  setSolvedChallenged,
  setChallenged,
}: CardSettingsChangePinFeedbackBuilderHandlersType): void => {
  setNewPin('');
  setRepeatedPin('');
  setSolvedChallenged(false);
  setChallenged(false);
};

const CardSettingsChangePinFeedbackHandlers = ({
  setNewPin,
  setRepeatedPin,
  setSolvedChallenged,
  setChallenged,
}: CardSettingsChangePinFeedbackBuilderHandlersType): CardSettingsChangePinFeedbackHandlersBuilderReturnHandlersType => ({
  handleRetryChangeCardPinButtonClick: () =>
    retryChangeEmailProcessLinkClickHandler({
      setNewPin,
      setRepeatedPin,
      setSolvedChallenged,
      setChallenged,
    }),
});

export default CardSettingsChangePinFeedbackHandlers;
