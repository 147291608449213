// Enumerations
import { ErrorCodesEnumeration } from 'enumerations/error-codes.enumeration';

export default {
  [ErrorCodesEnumeration.CARD]: 'Empty_No_Order',
  [ErrorCodesEnumeration.DEFAULT]: 'oops-alternative',
  [ErrorCodesEnumeration.ME]: 'Avatar_Female_10',
  [ErrorCodesEnumeration.TRANSLATIONS]: 'Empty_No_Result',
  [ErrorCodesEnumeration.UNKNOWN]: 'Attention_Alternative',
};
