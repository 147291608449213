// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { GET_BENEFITS } from './constants/card-settings-benefits.constants';
import { CardSettingsBenefitsServicesPropsType } from './types/card-settings-benefits.services.type';
import { CardSettingsBenefitsType } from '../hooks/types/card-settings-benefits-travel.hook.type';

const getBenefitsService = async ({
  cardContractId,
}: CardSettingsBenefitsServicesPropsType): Promise<[CardSettingsBenefitsType | null, Response]> =>
  apiUtilsCb.get({
    ...GET_BENEFITS,
    pathParams: { cardContractId },
  });

export { getBenefitsService };
