// Vendors
import React from 'react';
// Components
import { SectionDividerComponent } from '@openbank/cb-ui-commons';
import { SettingsRowToggleComponent } from './components/settings-row-toggle/settings-row-toggle.component';
// Constants
import {
  NOTIFICATTIONS_SETTINGS_CATEGORY_ROW_TOGGLE_TEST_ID,
  NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TEXT_TEST_ID,
  NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_PROPS,
  NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_TEST_ID,
} from './constants/notifications-settings-category.constants';
// Styles
import {
  CategorySectionDividerWrapperStyled,
  NotificationsSettingsRowToggleWrapperStyled,
} from './notifications-settings-category.component.styled';
// Translations
import { CATEGORY_TITLE } from './translations/notifications-settings-category.translations';
// Types
import { NotificationSettingsCategoryPropsType } from './types/notifications-settings-category.component.type';
import { NotificationsSettingsListCategoryType } from '../../types/notifications-settings-list-category.type';
import { NotificationSettingsListType } from '../../types/notification-settings-list.type';

const NotificationSettingsCategoryComponent = ({
  formatMessage,
  formatNumber,
  handleControllerChange,
  handleToggleNotification,
  notificationsSettings,
}: NotificationSettingsCategoryPropsType): React.ReactElement<any> | null => {
  if (!notificationsSettings) return null;

  return (
    <>
      {notificationsSettings?.categories?.map(
        (settingsCategory: NotificationsSettingsListCategoryType) => (
          <React.Fragment key={settingsCategory.id}>
            <CategorySectionDividerWrapperStyled>
              <SectionDividerComponent
                {...{ ...NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_PROPS }}
                testing={{
                  elementId:
                    NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TITLE_TEST_ID + settingsCategory.id,
                  titleId:
                    NOTIFICATTIONS_SETTINGS_CATEGORY_SECTION_TEXT_TEST_ID + settingsCategory.id,
                }}
                title={formatMessage({ id: CATEGORY_TITLE + settingsCategory.title })}
              />
            </CategorySectionDividerWrapperStyled>
            <NotificationsSettingsRowToggleWrapperStyled>
              {settingsCategory?.notifications?.map(
                (
                  notificationSetting: NotificationSettingsListType,
                  index: number,
                  array: NotificationSettingsListType[]
                ) => {
                  const isLastElement: boolean = index === array.length - 1;
                  return (
                    <SettingsRowToggleComponent
                      key={notificationSetting.id}
                      {...{
                        categoryId: settingsCategory.id,
                        controllerId: notificationSetting?.controllers[0]?.id,
                        formatMessage,
                        formatNumber,
                        handleControllerChange,
                        handleToggleNotification,
                        hasLineSeparator: !isLastElement,
                        notificationSetting,
                        testing: {
                          testId: `${NOTIFICATTIONS_SETTINGS_CATEGORY_ROW_TOGGLE_TEST_ID}_${notificationSetting.id}`,
                        },
                      }}
                    />
                  );
                }
              )}
            </NotificationsSettingsRowToggleWrapperStyled>
          </React.Fragment>
        )
      )}
    </>
  );
};

export { NotificationSettingsCategoryComponent };
