// Vendors
import React, { PropsWithChildren, createContext, useContext, useRef, useState } from 'react';
// Types
import { CaptchaContextType } from './types/captcha.context.type';
import HCaptcha from '@hcaptcha/react-hcaptcha';

const LoginContext = createContext({} as CaptchaContextType);

const CaptchaProvider = ({ children }: PropsWithChildren<any>): React.JSX.Element => {
  const [captcha, setCaptcha] = useState<string>('');
  const captchaRef = useRef<HCaptcha>(null);

  return (
    <LoginContext.Provider value={{ captcha, captchaRef, setCaptcha }}>
      {children}
    </LoginContext.Provider>
  );
};

const useCaptchaContextHook = (): CaptchaContextType => useContext(LoginContext);

export { CaptchaProvider, useCaptchaContextHook };
