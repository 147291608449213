// Vendors
import React from 'react';
// Components
import TransactionsTableListGroupsComponent from './components/groups/transactions-table-list-groups.component';
import TransactionsTableListElementsComponent from './components/elements/transactions-table-list-elements.component';
// Types
import { TransactionsLandingTableListComponentType } from './types/transactions-landing-table-list.component.type';

const TransactionsTableListComponent = ({
  hasGroups,
  groupId,
  rowComponent,
  elements,
  isFinancing,
}: any): React.ReactElement<TransactionsLandingTableListComponentType> | null =>
  hasGroups ? (
    <TransactionsTableListGroupsComponent {...{ rowComponent, elements, isFinancing, groupId }} />
  ) : (
    <TransactionsTableListElementsComponent {...{ rowComponent, elements, isFinancing }} />
  );

export default TransactionsTableListComponent;
