// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { CreditChartComponent } from '@openbank/cb-ui-commons';
// Hooks
import CreditHook from './hooks/credit.hook';
// Translations
import translations from './translations/credit.translations';
// Types
import { CreditComponentType } from 'components/credit/types/credit.component.type';
// Utilities
import { formatCreditAmount } from './utils/credit.utils';

const CreditComponent = ({
  messageTooltip,
  positionTooltip,
  title,
}: CreditComponentType): React.ReactElement<CreditComponentType> => {
  const {
    availableAmount,
    consumedPercent: percentage,
    currentAmountAuthorized: consumed,
    formatNumber,
    mainCurrency: currency,
    limit,
    totalLoanBalance: balance,
  } = CreditHook();

  return (
    <CreditChartComponent
      {...{ percentage, positionTooltip }}
      {...(messageTooltip
        ? {
            messageTooltip: (
              <FormattedMessageComponent
                id={messageTooltip}
                values={{ balance: formatCreditAmount({ currency, formatNumber, value: balance }) }}
              />
            ),
          }
        : {})}
      {...(title ? { title: <FormattedMessageComponent id={title} /> } : {})}
      available={formatCreditAmount({ currency, formatNumber, value: availableAmount })}
      consumed={formatCreditAmount({ currency, formatNumber, value: consumed })}
      limit={formatCreditAmount({ currency, formatNumber, value: limit })}
      translations={{
        AVAILABLE: <FormattedMessageComponent id={translations.AVAILABLE} />,
        CONSUMED: <FormattedMessageComponent id={translations.CONSUMED} />,
        LIMIT: <FormattedMessageComponent id={translations.LIMIT} />,
      }}
    />
  );
};

export default CreditComponent;
