const CARD_SETTINGS_RESUME_LINK_ASSET_PROPS = {
  id: 'info',
  colors: {
    fill: 'linkPrimaryA',
    hover: 'linkPrimaryB',
  },
  sizes: {
    height: 16,
    width: 16,
  },
  testId: 'card-settings-resume-link-asset',
};

export { CARD_SETTINGS_RESUME_LINK_ASSET_PROPS };
