// Vendors
import React, { useState, useContext, createContext } from 'react';
// Constants
import constants from './constants/personal-photo.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { PersonalPhotoContextType } from './types/personal-photo.context.type';
import { AvatarIdsEnumeration } from 'containers/personal/views/photo/enumerations/avatar-ids.enumeration';
import { PhotoModalIdsEnumeration } from 'containers/personal/views/photo/enumerations/photo-modal-ids.enumeration';

const PersonalPhotoContext = createContext<PersonalPhotoContextType>(
  constants.CONTEXT_DEFAULT_VALUE
);

export const PersonalPhotoContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<React.Provider<PersonalPhotoContextType>> => {
  const [avatar, setAvatar] = useState<AvatarIdsEnumeration | null>(null);
  const [cropCompleted, setCropCompleted] = useState<boolean>(false);
  const [photo, setPhoto] = useState<string>('');
  const [photoModal, setPhotoModal] = useState<PhotoModalIdsEnumeration | null>(null);

  return (
    <PersonalPhotoContext.Provider
      value={{
        avatar,
        cropCompleted,
        photo,
        photoModal,
        setAvatar,
        setCropCompleted,
        setPhoto,
        setPhotoModal,
      }}
    >
      {children as React.ReactNode}
    </PersonalPhotoContext.Provider>
  );
};

export const PersonalPhotoContextConsumerHook = (): PersonalPhotoContextType =>
  useContext(PersonalPhotoContext);

/* eslint-disable */
export const PersonalPhotoContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<PersonalPhotoContextType>> =>
    (
      <PersonalPhotoContextProvider>
        <Component {...props} />
      </PersonalPhotoContextProvider>
    );
