// Constants
import checkCvvPinConstants from 'containers/card-settings/views/check-cvv-pin/constants/check-cvv-pin.constants';

const {
  CVV,
  PIN,
  TRANSLATIONS: { CVV_DOMAIN, PIN_DOMAIN },
} = checkCvvPinConstants;
const SECTION = 'viewCode.';

export default {
  [CVV]: {
    BACK_BUTTON: `${CVV_DOMAIN}${SECTION}goBack`,
    BUTTON_AGAIN: `${CVV_DOMAIN}${SECTION}showAgain`,
    BUTTON: `${CVV_DOMAIN}${SECTION}showCode`,
    DESCRIPTION: `${CVV_DOMAIN}${SECTION}description`,
    PREVIOUS_BACK_BUTTON: `${CVV_DOMAIN}${SECTION}goPreviousBack`,
    TIME_EXPIRED: `${CVV_DOMAIN}${SECTION}timeExpired`,
    TIME: `${CVV_DOMAIN}${SECTION}time`,
    TITLE: `${CVV_DOMAIN}${SECTION}title`,
  },
  [PIN]: {
    BACK_BUTTON: `${PIN_DOMAIN}${SECTION}goBack`,
    BUTTON_AGAIN: `${PIN_DOMAIN}${SECTION}showAgain`,
    BUTTON: `${PIN_DOMAIN}${SECTION}showCode`,
    DESCRIPTION: `${PIN_DOMAIN}${SECTION}description`,
    PREVIOUS_BACK_BUTTON: `${PIN_DOMAIN}${SECTION}goPreviousBack`,
    TIME_EXPIRED: `${PIN_DOMAIN}${SECTION}timeExpired`,
    TIME: `${PIN_DOMAIN}${SECTION}time`,
    TITLE: `${PIN_DOMAIN}${SECTION}title`,
  },
};
