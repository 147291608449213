// Vendors
import styled from 'styled-components';
// Utilities
import { convertPxsToRems, mobileMediaQuery, tabletMediaQuery } from '@openbank/cf-ui-framework';

export const CardSettingsActivateCardChallengeComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXxxxl};
  padding: ${({ theme }) => theme.spaces.spacingXl} ${convertPxsToRems({ pixels: 80, base: 14 })}
    ${({ theme }) => theme.spaces.spacingS} ${convertPxsToRems({ pixels: 80, base: 14 })};

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
    padding: ${({ theme }) => theme.spaces.spacingXl} ${({ theme }) => theme.spaces.spacingXxl}
      ${({ theme }) => theme.spaces.spacingS} ${({ theme }) => theme.spaces.spacingXxl};
  }

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingXs};
    padding: ${({ theme }) => theme.spaces.spacingXl} ${({ theme }) => theme.spaces.spacingS}
      ${({ theme }) => theme.spaces.spacingXxxl} ${({ theme }) => theme.spaces.spacingS};
  }
`;

export const CardSettingsActivateCardChallengeContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingL};

  ${tabletMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }

  ${mobileMediaQuery} {
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;

export const CardSettingsActivateCardChallengeTitleStyled = styled.div`
  ${mobileMediaQuery} {
    margin-bottom: ${({ theme }) => theme.spaces.spacingM};
  }
`;
