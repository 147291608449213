// Vendors
import React from 'react';
// Components
import { BannerComponent } from '@openbank/cb-ui-commons';
// Hooks
import { AppBannerComponentHook } from './hooks/banner.component.hook';
// Styles
import { BannerComponentStyled } from './banner.component.styled';

const AppBannerComponent = (): React.ReactElement => {
  const { bannerProps } = AppBannerComponentHook();

  return (
    <BannerComponentStyled {...bannerProps}>
      <BannerComponent {...bannerProps} />
    </BannerComponentStyled>
  );
};

export default AppBannerComponent;
