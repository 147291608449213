import { linkConstants, paragraphConstants } from '@openbank/cb-ui-commons';

const PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_DESCRIPTION_PROPS = {
  size: paragraphConstants.SIZES.XS,
  testId: 'personal-photo-name-button-upload-modal-description',
};
const PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTON_PROPS = {
  testId: 'personal-photo-name-button-upload-modal-button',
};
const PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_LINK_PROPS = {
  type: linkConstants.TYPES.ACTION_SECONDARY,
  testId: 'personal-photo-name-button-upload-modal-links',
};

export {
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_BUTTON_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_DESCRIPTION_PROPS,
  PERSONAL_PHOTO_NAME_BUTTON_UPLOAD_MODAL_LINK_PROPS,
};
