// Vendors
import { useEffect, useState } from 'react';
// Enumerations
import { ExpiredSessionReasonsEnumeration } from 'enumerations/session/expired-session-reasons.enumeration';
// Event handlers
import { SessionExpiredHandlers } from '../handlers/session-expired.handlers';
// Types
import { SessionExpiredHookType } from 'providers/session-expired/hooks/types/session-expired.hook.type';

const useSessionExpiredHook = (): SessionExpiredHookType => {
  const [expiredReason, setExpiredReason] = useState<ExpiredSessionReasonsEnumeration | undefined>(
    undefined
  );
  const { handleObserveUserSessionHealthEvents } = SessionExpiredHandlers({
    setExpiredReason,
  });

  useEffect(() => {
    handleObserveUserSessionHealthEvents();
  }, []);

  return {
    expiredReason,
  };
};

export { useSessionExpiredHook };
