// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Constants
import {
  FINANCING_PPI_LANDING_CANCEL_PPI_CONTRACT_TRACKING_PROPS,
  FINANCING_PPI_LANDING_CONTRACT_PPI_TRACKING_PROPS,
  FINANCING_PPI_LANDING_VIEW_TERMS_TRACKING_PROPS,
} from './constants/financing-ppi-landing.handlers.tracking.constants';
// Constants
// Types
import { FinancingPPPILandingHandlersMethodsTrackingType } from './types/financing-ppi-landing.handlers.tracking.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const contractPPIButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...FINANCING_PPI_LANDING_CONTRACT_PPI_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const cancelPPIContractButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...FINANCING_PPI_LANDING_CANCEL_PPI_CONTRACT_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const viewTermsPolicyLinkClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  ...FINANCING_PPI_LANDING_VIEW_TERMS_TRACKING_PROPS,
  type: TrackingEventsEnum.LINK,
});

const FinancingPPILandingTrackingHandlers = (
  track: TrackBuilderType
): FinancingPPPILandingHandlersMethodsTrackingType => ({
  handleCancelPPIContractButtonClickTracking: () =>
    track(cancelPPIContractButtonClickTrackingHandler()),
  handleContractPPIButtonClickTracking: () => track(contractPPIButtonClickTrackingHandler()),
  handleViewTermsPolicyLinkClickTracking: () => track(viewTermsPolicyLinkClickTrackingHandler()),
});

export { FinancingPPILandingTrackingHandlers };
