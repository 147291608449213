// Constants
import { PROPS } from './constants/limit-modal.component.configuration.constants';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { MODAL } from './../translations/limit-modal.component.translations';
// Types
import { GetModalConfigurationPropsType } from './types/get-modal-configuration-props.type';
import { GetModalConfigurationReturnType } from './types/get-modal-configuration-return.type';
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

export const getLimitModalConfiguration = ({
  formatMessage,
  username,
  onCloseModal,
  navigate,
  handleCancelLinkClickTracking,
  handleCloseButtonClickTracking,
  handleContinueButtonClickTracking,
}: GetModalConfigurationPropsType): GetModalConfigurationReturnType => ({
  ...PROPS,
  onHeaderClose: () => {
    handleCloseButtonClickTracking();
    navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING));
    onCloseModal();
  },
  title: formatMessage({ id: MODAL.CONFIG.TITLE }, { username }),
  primaryButtonConfiguration: {
    children: formatMessage({ id: MODAL.CONFIG.BUTTON }),
    onClick: () => {
      navigate(
        generateAbsoluteRoute(
          `${RoutesEnumeration.FINANCING}/${RoutesEnumeration.CREDIT_LIMIT_INCREASE}`
        )
      );
      handleContinueButtonClickTracking();
    },
  },
  linkConfiguration: {
    onClick: () => {
      handleCancelLinkClickTracking();
      navigate(generateAbsoluteRoute(RoutesEnumeration.FINANCING));
      onCloseModal();
    },
    children: formatMessage({ id: MODAL.CONFIG.LINK }),
  },
});
