// Types
import { RecoveryKeyFeedbackBuilderCommonHandlersType } from './types/wizard-feedback-error-builder.common.handlers.type';
import { RecoveryKeyFeedbackBuilderReturnCommonHandlersType } from './types/wizard-feedback-error-builder-return.common.handlers.type';

const wizardFeedbackErrorButtonClickHandler = ({
  buttonClick,
  handleWizardFeedbackErrorButtonClickTracking,
  setResult,
}: Pick<
  RecoveryKeyFeedbackBuilderCommonHandlersType,
  'buttonClick' | 'handleWizardFeedbackErrorButtonClickTracking' | 'setResult'
>): void => {
  handleWizardFeedbackErrorButtonClickTracking();
  try {
    const result = buttonClick?.();
    setResult(result ?? undefined);
  } catch (error) {
    setResult(undefined);
  }
};

const WizardFeedbackErrorCommonHandlers = ({
  buttonClick,
  handleWizardFeedbackErrorButtonClickTracking,
  setResult,
}: RecoveryKeyFeedbackBuilderCommonHandlersType): RecoveryKeyFeedbackBuilderReturnCommonHandlersType => ({
  handleWizardFeedbackErrorButtonClick: () =>
    wizardFeedbackErrorButtonClickHandler({
      buttonClick,
      handleWizardFeedbackErrorButtonClickTracking,
      setResult,
    }),
});

export { WizardFeedbackErrorCommonHandlers };
