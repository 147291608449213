// Types
import { OrchestratorFooterNavItemComponentType } from '../types/orchestrator-footer-nav-item.component.type';
import { OrchestratorFooterConfigurationType } from './types/orchestrator-footer.configuration.type';

const convertNavItemLinksConfiguration = ({
  formatMessage,
  items,
}: OrchestratorFooterConfigurationType): OrchestratorFooterNavItemComponentType[] =>
  items.map((item: OrchestratorFooterNavItemComponentType) => ({
    ...item,
    label: formatMessage({ id: item.label }),
  }));

export { convertNavItemLinksConfiguration };
