// Vendors
import React from 'react';
// Constants
import { CHALLENGE_SERVICE } from './contants/report-problem-challenge.constants';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Hooks
import { ReportProblemChallengeHook } from './hooks/report-problem-challenge.hook';
// Types
import { ReportProblemChallengeComponentProps } from './types/report-problem-challenge.component.type';

const ReportProblemChallengeComponent = ({
  baseServiceBuilder,
}: ReportProblemChallengeComponentProps): React.ReactElement => {
  const {
    challengeError,
    handleChallengeRetryButtonClick,
    handleChallengeSuccess,
  } = ReportProblemChallengeHook();

  return (
    <ChallengeWrapperComponent
      baseService={CHALLENGE_SERVICE.baseService}
      baseServiceMethod={CHALLENGE_SERVICE.baseServiceMethod}
      baseServiceBuilder={baseServiceBuilder}
      challengeError={challengeError}
      onRetry={handleChallengeRetryButtonClick}
      onSuccess={handleChallengeSuccess}
    />
  );
};

export { ReportProblemChallengeComponent };
