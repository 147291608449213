// Constants
import { messageConstants } from '@openbank/cb-ui-commons';

export default {
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_PROPS: {
    testId: 'settings-card-control-locations-warning',
    type: messageConstants.TYPES.WARNING,
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_TITLE_PROPS: {
    bold: true,
    testId: 'settings-card-control-locations-warning-title',
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_DESCRIPTION_PROPS: {
    testId: 'settings-card-control-locations-warning-table-row-description',
  },
  SETTINGS_CARD_CONTROL_LOCATIONS_WARNING_LINK_PROPS: {
    testId: 'settings-card-control-locations-warning-links',
  },
};
