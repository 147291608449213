// Constants
import { NO_TRUSTED_DEVICE_PAYLOAD } from './constants/devices-list.handlers.constants';
// Types
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { TrustedDeviceHandlersTrackingReturnType } from './types/trusted-device.handlers.tracking.return.type';

const noTrustedDeviceTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: NO_TRUSTED_DEVICE_PAYLOAD,
  type: TrackingEventsEnum.LINK,
});

const DevicesListHandlersTracking = (
  track: (param: unknown) => void
): TrustedDeviceHandlersTrackingReturnType => ({
    onNoTrustedDeviceTracking: () => track(noTrustedDeviceTrackingHandler())
});

export { DevicesListHandlersTracking };