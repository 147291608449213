const DOMAIN = 'cobranded.services.userSettings.privateArea.support.supportLanding.';

export default {
  AMAZON_CARD_LINK: `${DOMAIN}cbCard.visitAmazonSupportLink`,
  CANCEL_CARD_LINK: `${DOMAIN}cbCard.endYourContractLink`,
  CANCEL_CARD_TITLE: `${DOMAIN}cbCard.cancelTitle`,
  CANCEL_CARD_DESCRIPTION: `${DOMAIN}cbCard.cancelDescription`,
  CHAT_CARD_LINK: `${DOMAIN}cbCard.contactUsLink`,
  CHAT_CARD_TITLE: `${DOMAIN}cbCard.chatTitle`,
  CHAT_CARD_DESCRIPTION: `${DOMAIN}cbCard.contactDescription`,
  FAQS_CARD_LINK: `${DOMAIN}cbCard.faqsLink`,
  FAQS_CARD_TITLE: `${DOMAIN}cbCard.faqsTitle`,
  FAQS_CARD_DESCRIPTION: `${DOMAIN}cbCard.faqsDescription`,
  TITLE: `${DOMAIN}title`,
};
