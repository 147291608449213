// Constants
import { NUMBER } from 'constants/number.constants';
import { GET_CITIES_DEBOUNCED_TIME } from './constants/address-modal-inputs-city.handlers.constants';
// Resolvers
import { getCitiesByTermResolver } from 'components/address-modal/resolvers/address-modal.resolvers';
// Translations
import {
  CITIES_ERROR,
  MIN_LENGTH_ERROR,
} from '../translations/address-modal-inputs-city.translations';
import { NO_RESULTS } from 'components/address-modal/components/inputs/translations/address-modal-inputs.translations';
// Types
import { AddressModalInputsCityHandlersType } from './types/address-modal-inputs-city.handlers.type';
import { AddressModalInputsCityHandlersReturnType } from './types/address-modal-inputs-city-return.handlers.type';
import { CityChangeHandlerType } from './types/city-change-handler.type';
// Utils
import { debounce } from 'utils/debounce/debounce.utils';
import { formatCityOptions } from './utils/address-modal-inputs-city.handlers.utils';

const cityChangeHandler = debounce(
  async ({ city, setOptions, setIsLoading, setError }: CityChangeHandlerType) => {
    if (city.length < NUMBER.THREE) {
      setOptions([]);
      setError(MIN_LENGTH_ERROR);
      return;
    }

    try {
      setIsLoading(true);

      const [response, error] = await getCitiesByTermResolver(city);

      if (error) {
        throw new Error();
      }

      setError(NO_RESULTS);
      setOptions(formatCityOptions(response));
    } catch (_) {
      setError(CITIES_ERROR);
      setOptions([]);
    } finally {
      setIsLoading(false);
    }
  },
  GET_CITIES_DEBOUNCED_TIME
);

const AddressModalInputsCityHandlers = (
  props: AddressModalInputsCityHandlersType
): AddressModalInputsCityHandlersReturnType => ({
  handleCityChange: () => cityChangeHandler(props),
});

export default AddressModalInputsCityHandlers;
