// Types
import { InputSearchOptionType } from 'components/address-modal/components/inputs/types/input-search-option.type';

const formatPredictions = (predictions: string[]): InputSearchOptionType[] =>
  predictions.map(value => ({ label: value, value }));

const formatOptions = (street: string, predictions: string[]): InputSearchOptionType[] => {
  const formattedPredictions = formatPredictions(predictions);

  return street ? [{ label: street.concat(' '), value: street }, ...formattedPredictions] : [];
};

export { formatOptions };
