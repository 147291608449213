// Vendors
import { assetConstants, buttonConstants, titleConstants } from '@openbank/cb-ui-commons';

const ALREADY_CANCELLED_ASSET_PROPS = {
    id: 'Attention_Alternative',
    sizes: {
      height: 128,
      width: 128,
    },
    testId: 'support-cancel-contract-already-cancelled-asset',
    type: assetConstants.TYPES.ILLUSTRATION,
  },
  ALREADY_CANCELLED_TITLE_PROPS = {
    tag: titleConstants.TAGS.H4,
    bold: true,
    testId: 'support-cancel-contract-already-cancelled-title',
  },
  ALREADY_CANCELLED_DESCRIPTION_PROPS = {
    testId: 'support-cancel-contract-already-cancelled-description',
  },
  ALREADY_CANCELLED_BUTTON_PROPS = {
    testId: 'request-signature-key-confirmation-button',
    rwdFull: false,
    type: buttonConstants.TYPES.PRIMARY,
  };

export {
  ALREADY_CANCELLED_ASSET_PROPS,
  ALREADY_CANCELLED_BUTTON_PROPS,
  ALREADY_CANCELLED_DESCRIPTION_PROPS,
  ALREADY_CANCELLED_TITLE_PROPS,
};
