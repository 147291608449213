// Vendors
import {
  TrackingEventsEnum,
  TrackingLinkEventReturnType,
  TrackingViewEventReturnType,
} from '@openbank/cf-ui-static-data';
// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { AccessKeyChangeStep1HandlersTrackingReturnType } from './types/security-keys-access-key-change-first-step-handlers-tracking-return.type';

const passwordPositionInputClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickTextBox',
    eventLabel: 'currentAccessKeyTextBox',
  },
  type: TrackingEventsEnum.LINK,
});

const onEnterFirstStepAccessKeyChangeTrackingHandler = (): TrackingViewEventReturnType => ({
  payload: {
    pageName: '/changeAccessKey/step01/currentAccessKey',
    sectionFamily: 'banking',
    sectionCategory: 'services',
    sectionName: 'security',
    interactionType: 'funnel',
    serviceType: 'userSettings',
  },
  type: TrackingEventsEnum.VIEW,
});

const SecurityKeysAccessKeyChangeFirstStepTrackingHandlers = (
  track: TrackBuilderType
): AccessKeyChangeStep1HandlersTrackingReturnType => ({
  handlePasswordPositionInputClickTracking: () =>
    track(passwordPositionInputClickTrackingHandler()),
  handleOnEnterFirstStepAccessKeyChangeTrackingHandler: () =>
    track(onEnterFirstStepAccessKeyChangeTrackingHandler()),
});

export default SecurityKeysAccessKeyChangeFirstStepTrackingHandlers;
