// Types
import { TransactionsEppStep1SimulationOptionsConfigurationUtilsType } from './types/transactions-epp-step1-simulation-options.configuration.utils.type';
import { TransactionsEppStep1SimulationOptionType } from '../../types/transactions-epp-step1-simulation-option.type';

const optionValueIsOnRangeWithMaxAndMinInstallments = ({
  maxNumOfInstallments,
  minNumOfInstallments,
  option,
}: TransactionsEppStep1SimulationOptionsConfigurationUtilsType): boolean =>
  option?.id <= maxNumOfInstallments && option?.id >= minNumOfInstallments;

const filterOptionAccordingMaxInstallments = (
  props: TransactionsEppStep1SimulationOptionsConfigurationUtilsType
): TransactionsEppStep1SimulationOptionType | null =>
  optionValueIsOnRangeWithMaxAndMinInstallments(props) ? props.option : null;

export { filterOptionAccordingMaxInstallments };
