// Vendors
import React from 'react';
// Components
import ChallengeWrapperComponent from 'components/challenge-wrapper/challenge-wrapper.component';
// Configurations
import { getChallengeConfiguration } from './configurations/security-keys-signature-key-change-third-step.challenge.configurations';
// Hooks
import SecurityKeysSignatureKeyChangeThirdStepChallengeHook from './hook/security-keys-signature-key-change-third-step-challenge.hook';
// Styled
import { SecurityKeysSignatureKeyChangeChallengeComponentStyled } from './security-keys-signature-key-change-third-step-challenge.component.styled';

const SecurityKeysSignatureKeyChangeChallengeComponent = (): React.ReactElement => {
  const { handleManageSuccessOnChangeSmsEvent, signatureKey, newSignatureKey } =
    SecurityKeysSignatureKeyChangeThirdStepChallengeHook();

  return (
    <SecurityKeysSignatureKeyChangeChallengeComponentStyled>
      <ChallengeWrapperComponent
        {...getChallengeConfiguration({
          handleChallengeSuccess: handleManageSuccessOnChangeSmsEvent,
          oldSignatureKey: signatureKey,
          newSignatureKey,
        })}
      />
    </SecurityKeysSignatureKeyChangeChallengeComponentStyled>
  );
};

export default SecurityKeysSignatureKeyChangeChallengeComponent;
