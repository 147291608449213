// Contexts
import { ToastContextConsumerHook } from 'contexts/toast.context';
import { useFetchErrorContextConsumerHook } from 'contexts/fetch-error/fetch-error.context';
import { useFinancingPPIContractingContextConsumerHook } from 'containers/financing/views/ppi/components/contracting/contexts/financing-ppi-contracting.context';
// Event handlers
import { FinancingPPIContractingDownloadHandlers } from '../handlers/financing-ppi-contracting-download.handlers';
import { FinancingPPIContractingDownloadTrackingHandlers } from '../handlers/financing-ppi-contracting-download.tracking.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import {
  FinancingPPIContractingDownloadBuilderType,
  FinancingPPIContractingDownloadType,
} from './types/financing-ppi-contracting-download.type';

const useFinancingPPIContractingDownloadHook = (
  props: FinancingPPIContractingDownloadBuilderType
): FinancingPPIContractingDownloadType => {
  const { setDocumentDownloaded, errorDocumentDownloaded, setErrorDocumentDownloaded } =
      useFinancingPPIContractingContextConsumerHook(),
    { setToastConfiguration } = ToastContextConsumerHook(),
    { fetching, setFetching } = useFetchErrorContextConsumerHook();

  return {
    ...FinancingPPIContractingDownloadHandlers({
      ...AppTrackingHook(FinancingPPIContractingDownloadTrackingHandlers),
      ...props,
      setFetching,
      setErrorDocumentDownloaded,
      setDocumentDownloaded,
      setToastConfiguration,
    }),
    fetching,
    errorDocumentDownloaded,
  };
};

export { useFinancingPPIContractingDownloadHook };
