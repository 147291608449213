// Enumerations
import { PhotoModalIdsEnumeration } from 'containers/personal/views/photo/enumerations/photo-modal-ids.enumeration';
// Types
import { PersonalPhotoNavigationFooterUtilsType } from './types/personal-photo-navigation-footer.utils.type';

const saveUploadPhotoButtonDisabled = ({
  avatar,
  fetching,
  photo,
}: PersonalPhotoNavigationFooterUtilsType): boolean => Boolean((!avatar && !photo) || fetching);

const isCancelPhotoModalSet = (photoModal: PhotoModalIdsEnumeration | null): boolean =>
  photoModal === PhotoModalIdsEnumeration.CANCEL_OPERATION;

export { isCancelPhotoModalSet, saveUploadPhotoButtonDisabled };
