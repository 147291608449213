// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
import React from 'react';
// Constants
import constants from './constants/personal-email-logout-modal.constants';
// Components
import { AssetComponent, ModalComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
// Hooks
import PersonalNumberLogoutModalHook from './hooks/personal-number-logout-modal.hook';
// Styles
import {
  PersonalNumberLogoutModalComponentStyled,
  PersonalNumberCancelModalDescriptionComponentStyled,
} from './personal-number-logout-modal.component.styled';
// Translations
import translations from './translations/personal-number-logout-modal.translations';
// Utilities
import { isLogoutModalConfigured } from './utils/personal-number-logout-modal.utils';

const PersonalNumberLogoutModalComponent = (): React.ReactElement | null => {
  const { handleLogoutFromChangeNumberButtonClick, showModal } = PersonalNumberLogoutModalHook();

  return isLogoutModalConfigured(showModal) ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      primaryButtonConfiguration={{
        ...constants.PERSONAL_NUMBER_LOGOUT_MODAL_BUTTON_PROPS,
        children: <FormattedMessageComponent id={translations.BUTTON} />,
        onClick: handleLogoutFromChangeNumberButtonClick,
      }}
      title={<FormattedMessageComponent id={translations.TITLE} />}
    >
      <PersonalNumberLogoutModalComponentStyled>
        <AssetComponent {...constants.PERSONAL_NUMBER_LOGOUT_MODAL_ASSET_PROPS} />
        <PersonalNumberCancelModalDescriptionComponentStyled>
          <ParagraphComponent {...constants.PERSONAL_NUMBER_LOGOUT_MODAL_PARAGRAPH_01_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION_01} />
          </ParagraphComponent>
          <ParagraphComponent {...constants.PERSONAL_NUMBER_LOGOUT_MODAL_PARAGRAPH_02_PROPS}>
            <FormattedMessageComponent id={translations.DESCRIPTION_02} />
          </ParagraphComponent>
        </PersonalNumberCancelModalDescriptionComponentStyled>
      </PersonalNumberLogoutModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export default PersonalNumberLogoutModalComponent;
