// Constants
import {
  HIDE_BUTTON_CONFIGURATION,
  SHOW_BUTTON_CONFIGURATION,
} from './constants/card-settings-check-3d-confirmation.configuration.constants';
// Translations
import {
  HIDE,
  SHOW,
} from './translations/card-settings-check-3d-wizard.configuration.translations';
// Types
import { CardSettingsCheck3dConfirmationConfigurationType } from './types/card-settings-check-3d-confirmation.configuration.type';

const getShowHideButtonConfiguration = (
  shownCode: boolean
): CardSettingsCheck3dConfirmationConfigurationType =>
  shownCode ? HIDE_BUTTON_CONFIGURATION : SHOW_BUTTON_CONFIGURATION;

const getShowHideButtonLabel = (shownCode: boolean): string => (shownCode ? HIDE : SHOW);

export { getShowHideButtonLabel, getShowHideButtonConfiguration };
