// Resolvers
import { decreaseLimitResolver } from '../resolvers/credit-limit-decrease-cc.resolvers';
// Types
import { CreditLimitDecreaseCCPropsHandlersType } from './types/credit-limit-decrease-cc-props.handler.type';
import { CreditLimitDecreaseCCReturnHandlersType } from './types/credit-limit-decrease-cc-return.handler.type';

const decreaseLimitHandler = async ({
  currentCard,
  progressId,
  newCreditLimit,
  setCurrentCard,
  setDefaultResult,
  setIsConfirmLoading,
}: CreditLimitDecreaseCCPropsHandlersType) => {
  setIsConfirmLoading(true);

  const [_, error] = await decreaseLimitResolver({
    cardContractId: currentCard?.cardContractId,
    progressId,
    params: { limit: newCreditLimit },
  });

  setDefaultResult(!error);
  setCurrentCard({
    ...currentCard,
    creditDetails: { ...currentCard?.creditDetails, limit: newCreditLimit },
  });
};

const CreditLimitDecreaseCCHandlers = (
  props: CreditLimitDecreaseCCPropsHandlersType
): CreditLimitDecreaseCCReturnHandlersType => ({
  handleDecreaseLimit: () => decreaseLimitHandler(props),
});

export { CreditLimitDecreaseCCHandlers };
