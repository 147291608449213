// Enumerations
import { RequestEmailOptionEnumeration } from 'containers/personal/views/access-portability/enumerations/access-portability.enumeration';
// Types
import { RequestPortabilityParamsType } from 'containers/personal/views/access-portability/types/request-portability-params.type';
import { TransactionsTableBuilderReturnHandlersType } from './types/request-portability-step2-builder-return.handlers.type';
import { RequestPortabilityStep2BuilderHandlersType } from './types/request-portability-step2-builder.handlers.type';

const nextStepClickHandler = ({
  emailCheckboxValue,
  emailValue,
  setCurrentStep,
  setPortabilityParams,
}) => {
  setPortabilityParams((prevState: RequestPortabilityParamsType | null) => {
    return {
      ...prevState,
      emailThirdParty:
        emailCheckboxValue === RequestEmailOptionEnumeration.OTHER_EMAIL ? emailValue : '',
    };
  });
  setCurrentStep(3);
};

const RequestPortabilityStep2Handlers = ({
  emailValue,
  emailCheckboxValue,
  setPortabilityParams,
  setCurrentStep,
}: RequestPortabilityStep2BuilderHandlersType): TransactionsTableBuilderReturnHandlersType => ({
  handleNextStepClickEvent: () =>
    nextStepClickHandler({ setPortabilityParams, emailCheckboxValue, emailValue, setCurrentStep }),
});

export default RequestPortabilityStep2Handlers;
