// Types
import { AccessPortabilityRequestBuilderHandlersType } from './types/personal-access-portability-request-builder.handlers.type';
import { AccessPortabilityRequestBuilderReturnHandlersType } from './types/personal-access-portability-request-builder-return.handlers.type';
// Utils
import { getPrivacyUrl } from '../utils/personal-access-portability-request.utils';

const privacyPolicyLinkClick = ({
  handlePrivacyPolicyLinkClickTracking,
}: Pick<
  AccessPortabilityRequestBuilderHandlersType,
  'handlePrivacyPolicyLinkClickTracking'
>): void => {
  handlePrivacyPolicyLinkClickTracking();
  window.open(getPrivacyUrl(), '_blank');
};

const RequestAccessHandlers = ({
  handlePrivacyPolicyLinkClickTracking,
}: AccessPortabilityRequestBuilderHandlersType): AccessPortabilityRequestBuilderReturnHandlersType => ({
  handlePrivacyPolicyLinkClick: () =>
    privacyPolicyLinkClick({ handlePrivacyPolicyLinkClickTracking }),
});

export default RequestAccessHandlers;
