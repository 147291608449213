// Vendors
import React from 'react';
import { Controller } from 'react-hook-form';
import { isIosWebView } from '@gruposantander/web-ui-framework';
// Components
import { InputDropdownComponent } from '@openbank/cb-ui-commons';
// Constants
import {
  PAYMENT_METHOD_CONTROLLER_NAME,
  PAYMENT_METHOD_CONTROLLER_RULES,
  PAYMENT_METHOD_DROPDOWN_PROPS,
} from './constants/transactions-report-payment-additional-info-input.constants';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Styles
import {
  TransactionsReportPaymentAdditionalInfoInputDateStyled,
  TransactionsReportPaymentAdditionalInfoInputComponentStyled,
} from './transactions-report-payment-additional-info-input.component.styled';
// Translations
import {
  PAYMENT_METHOD_LABEL,
  PAYMENT_METHOD_PLACEHOLDER,
} from './translations/transactions-report-payment-additional-info-input.translations';
// Types
import { TransactionsReportPaymentAdditionalInfoInputComponentType } from './types/transactions-report-payment-additional-info-input.component.type';
// Utils
import { getPaymentMethodOptions } from './utils/transactions-report-payment-additional-info-input.utils';

export const TransactionsReportPaymentAdditionalInfoInputComponent = ({
  control,
}: TransactionsReportPaymentAdditionalInfoInputComponentType): React.ReactElement => {
  const { formatMessage } = useCbIntl();

  return (
    <TransactionsReportPaymentAdditionalInfoInputComponentStyled>
      <TransactionsReportPaymentAdditionalInfoInputDateStyled>
        <Controller
          control={control}
          name={PAYMENT_METHOD_CONTROLLER_NAME}
          rules={PAYMENT_METHOD_CONTROLLER_RULES}
          render={({ field }) => (
            <InputDropdownComponent
              {...PAYMENT_METHOD_DROPDOWN_PROPS}
              {...field}
              isIosDevice={isIosWebView()}
              label={formatMessage({ id: PAYMENT_METHOD_LABEL })}
              placeholder={formatMessage({ id: PAYMENT_METHOD_PLACEHOLDER })}
              onSelectDate={field.onChange}
              options={getPaymentMethodOptions(formatMessage)}
              onSelectOption={field.onChange}
            />
          )}
        />
      </TransactionsReportPaymentAdditionalInfoInputDateStyled>
    </TransactionsReportPaymentAdditionalInfoInputComponentStyled>
  );
};
