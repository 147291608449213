// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import { FETCH_DOWNLOAD_CARD_STATEMENTS_URL_SERVICE_CONFIGURATION } from './constants/card-statement-table-row-download.service.constants';
// Types
import { DownloadCardStatementsUrlServiceType } from './types/card-statement-download-url.service.type';
import { CardStatementTableRowDownloadComponentType } from '../types/card-statement-table-row-download.component.type';

const fetchDownloadCardStatementDocumentService = async (
  pathParams: CardStatementTableRowDownloadComponentType
): Promise<[DownloadCardStatementsUrlServiceType | Record<string, any>, Response]> =>
  apiUtilsCb.get({
    ...FETCH_DOWNLOAD_CARD_STATEMENTS_URL_SERVICE_CONFIGURATION,
    pathParams,
  });

export { fetchDownloadCardStatementDocumentService };
