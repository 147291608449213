const DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR =
  'cobranded.services.userSettings.privateArea.cardSettings.depositMoney.sidebar.';
const DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS =
  'cobranded.services.userSettings.privateArea.cardSettings.depositMoney.sidebar.rows.';

export default {
  TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR}title`,
  ROWS: {
    FIRST: {
      TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS}first.title`,
      CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS}first.content`,
    },
    SECOND: {
      TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS}second.title`,
      CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS}second.content`,
    },
    THIRD: {
      TITLE: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS}third.title`,
      CONTENT: `${DOMAIN_CARD_SETTINGS_DEPOSIT_MONEY_SIDEBAR_ROWS}third.content`,
    },
  },
};
