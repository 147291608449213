// Vendors
import React from 'react';
// Components
import { FilterModalListSelectorComponent } from '../../components/list-selector/filter-modal-list-selector.component';
import { FilterModalListSelectorDateComponent } from '../../components/list-selector-date/filter-modal-list-selector-date.component';
import { FilterModalInputRangeComponent } from '../../components/input-range/filter-modal-input-range.component';
// Constants
import { STATUS_EPP_OPTIONS } from './constants/financing-filter-modal.constants';
// Enumerations
import { FilterModalInputRangeTypesEnumeration } from '../../components/input-range/enumerations/filter-modal-input-range-types.enumeration';
// Translations
import { STATUS_TITLE } from './translations/financing-filter-modal.translations';
import {
  GetTransactionsLandingFilterModalConfigurationReturnType,
  GetTransactionsLandingFilterModalConfigurationType,
} from '../transactions-filter-modal-configuration/types/transactions-landing-filter-modal.configuration.type';
// Types

const getFinancingLandingFilterModalConfiguration = ({
  formatMessage,
  formValues,
  handleFormValuesChange,
}: GetTransactionsLandingFilterModalConfigurationType): GetTransactionsLandingFilterModalConfigurationReturnType => {
  return {
    filterModalContentConfiguration: [
      {
        id: 'list-dates',
        component: (
          <FilterModalListSelectorDateComponent
            {...{
              formValues,
              handleFormValuesChange,
              showLineSeparator: true,
            }}
          />
        ),
      },
      {
        id: 'range-amount',
        component: (
          <FilterModalInputRangeComponent
            {...{
              handleFormValuesChange,
              formValues,
              type: FilterModalInputRangeTypesEnumeration.AMOUNT,
            }}
          />
        ),
      },
      {
        id: 'status',
        component: (
          <FilterModalListSelectorComponent
            {...{
              title: formatMessage({
                id: STATUS_TITLE,
              }),
              handleFormValuesChange,
              field: 'status',
              listOptions: STATUS_EPP_OPTIONS,
              formValues,
              showLineSeparator: false,
            }}
          />
        ),
      },
    ],
  };
};

export { getFinancingLandingFilterModalConfiguration };
