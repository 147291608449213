// Types
import { TrackBuilderType } from 'types/tracking/track-builder.type';
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
import { CardSettingsPaymentTrackingBuilderReturnHandlersType } from './types/card-settings-payment.tracking.handlers.type';

const showNonSecureStoreModalTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickLink',
    eventLabel: 'change',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsPaymentTrackingHandlers = (
  track: TrackBuilderType
): CardSettingsPaymentTrackingBuilderReturnHandlersType => ({
  handleShowNonSecureStoreModalTracking: () => track(showNonSecureStoreModalTrackingHandler()),
});

export { CardSettingsPaymentTrackingHandlers };
