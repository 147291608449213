// Vendors
import styled, { css } from 'styled-components';
import { mobileMediaQuery } from '@openbank/cf-ui-framework';

const CardSettingsStatementsTableRowComponentStyled = styled.div`
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: ${({ theme }) => theme.spaces.spacingM};
  width: 100%;

  ${mobileMediaQuery} {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.spaces.spacingXs};
  }
`;
CardSettingsStatementsTableRowComponentStyled.displayName =
  'CardSettingsStatementsTableRowComponentStyled';

const CardSettingsStatementsTableRowContentComponentStyled = styled(
  CardSettingsStatementsTableRowComponentStyled
)`
  grid-template-columns: repeat(2, 1fr);

  ${mobileMediaQuery} {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => theme.spaces.spacingM};
  }
`;
CardSettingsStatementsTableRowContentComponentStyled.displayName =
  'CardSettingsStatementsTableRowContentComponentStyled';

const CardStatementsTableMobileColumnStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spaces.spacingXs};
`;

const CardSettingsStatementsTableRowColumnLeftComponentStyled = styled(
  CardSettingsStatementsTableRowComponentStyled
)`
  grid-template-columns: 2fr 1fr;

  ${mobileMediaQuery} {
    ${CardStatementsTableMobileColumnStyles};
    flex: 1;
  }
`;
CardSettingsStatementsTableRowColumnLeftComponentStyled.displayName =
  'CardSettingsStatementsTableRowColumnLeftComponentStyled';

const CardSettingsStatementsTableRowColumnRightComponentStyled = styled(
  CardSettingsStatementsTableRowComponentStyled
)`
  grid-template-columns: repeat(2, 1fr);

  ${mobileMediaQuery} {
    ${CardStatementsTableMobileColumnStyles};
    align-items: flex-end;
    gap: ${({ theme }) => theme.spaces.spacingL};
    flex: auto;
  }
`;
CardSettingsStatementsTableRowColumnRightComponentStyled.displayName =
  'CardSettingsStatementsTableRowColumnComponentStyled';

const CardSettingsStatementsTableRowTagComponentStyled = styled.div`
  display: flex;
  justify-content: center;
`;
CardSettingsStatementsTableRowTagComponentStyled.displayName =
  'CardSettingsStatementsTableRowTagComponentStyled';

const CardSettingsStatementsTableRowAmountComponentStyled = styled.div`
  display: flex;
  justify-content: flex-end;
`;
CardSettingsStatementsTableRowTagComponentStyled.displayName =
  'CardSettingsStatementsTableRowTagComponentStyled';

export {
  CardSettingsStatementsTableRowComponentStyled,
  CardSettingsStatementsTableRowColumnLeftComponentStyled,
  CardSettingsStatementsTableRowColumnRightComponentStyled,
  CardSettingsStatementsTableRowAmountComponentStyled,
  CardSettingsStatementsTableRowContentComponentStyled,
  CardSettingsStatementsTableRowTagComponentStyled,
};
