// Services
import { fetchCardResumeCardArtService } from 'components/card-resume/services/card-resume.service';
// Utilities
import { isValidCardArt } from './utils/card-resume.resolver.utils';

const fetchCardResumeCardArtResolver = async (
  productType: string
): Promise<[string] | [null, true]> => {
  const [response] = await fetchCardResumeCardArtService(productType);

  return isValidCardArt(response) ? [response.cardArts[0].src] : [null, true];
};

export { fetchCardResumeCardArtResolver };
