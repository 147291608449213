// Types
import { NUMBER } from 'constants/number.constants';
import {
  RequestSignatureKeyStep1BuilderHandlersType,
  RequestSignatureKeyStep1BuilderReturnHandlersType,
} from './types/request-signature-key-step1.handlers.type';

const clickNextButtonHandler = ({
  setCurrentStep
}: Pick<RequestSignatureKeyStep1BuilderHandlersType, 'setCurrentStep'>): void => {
  setCurrentStep(NUMBER.TWO);
};

const RequestSignatureKeyStep1Handlers = (props: RequestSignatureKeyStep1BuilderHandlersType):
  RequestSignatureKeyStep1BuilderReturnHandlersType => ({
  handleClickNextButton: () => clickNextButtonHandler(props),
});

export { RequestSignatureKeyStep1Handlers };
 