// Vendors
import { isIosWebView } from '@gruposantander/web-ui-framework';
import React from 'react';
// Components
import {
  ModalComponent,
  SectionDividerComponent,
  ResumeItemComponent,
} from '@openbank/cb-ui-commons';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Configurations
import { getSepaModalConfig } from './configurations/sepa-modal.configurations';
// Constants
import {
  SEPA_MODAL_ADDRESS_PROPS,
  SEPA_MODAL_CREDITOR_ADDRESS_PROPS,
  SEPA_MODAL_CREDITOR_DIVIDER_TEXT,
  SEPA_MODAL_CREDITOR_DIVIDER,
  SEPA_MODAL_CREDITOR_ID_PROPS,
  SEPA_MODAL_CYCLE_DAY_PROPS,
  SEPA_MODAL_DEBTOR_DIVIDER_TEXT,
  SEPA_MODAL_DEBTOR_DIVIDER,
  SEPA_MODAL_DEBTORS_NAME_PROPS,
  SEPA_MODAL_IBAN_AND_BIC_PROPS,
  SEPA_MODAL_SEPA_MANDATE_PROPS,
  SEPA_MODAL_TYPE_OF_PAYMENT_PROPS,
} from './constants/sepa-modal.component.constants';
// Hooks
import SepaModalHook from './hooks/sepa-modal.hook';
// Translations
import translations from './translations/sepa-modal.translations';
// Types
import { SepaModalComponentType } from './types/sepa-modal.component.type';
// Styles
import {
  SepaModalComponentStyled,
  SepaModalRowStyled,
  SepaModalColumnStyled,
} from './sepa-modal.component.styled';
// Utils
import { createMappedDebtorsAddress, getSEPAModalBICValue } from './utils/sepa-modal.utils';

const SepaModalComponent = ({
  isOpen,
  onClose,
}: SepaModalComponentType): React.ReactElement | null => {
  const { address, userName, formatMessage, nextCycleDate, sepaMandate } = SepaModalHook();

  return isOpen ? (
    <ModalComponent
      isIosDevice={isIosWebView()}
      {...getSepaModalConfig({ formatMessage, onClose })}
    >
      <SepaModalComponentStyled>
        <SectionDividerComponent
          {...SEPA_MODAL_CREDITOR_DIVIDER}
          title={
            <FormattedMessageComponent
              {...SEPA_MODAL_CREDITOR_DIVIDER_TEXT}
              id={translations.CREDITOR_TITLE}
            />
          }
        />
        <SepaModalRowStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_CREDITOR_ID_PROPS}
              label={<FormattedMessageComponent id={translations.CREDITOR_ID} />}
              value={<FormattedMessageComponent id={translations.CREDITOR_ID_VALUE} />}
            />
          </SepaModalColumnStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_IBAN_AND_BIC_PROPS}
              label={<FormattedMessageComponent id={translations.IBAN_AND_BIC} />}
              value={getSEPAModalBICValue({ bic: sepaMandate?.bic, formatMessage })}
            />
          </SepaModalColumnStyled>
        </SepaModalRowStyled>
        <SepaModalRowStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_CREDITOR_ADDRESS_PROPS}
              label={<FormattedMessageComponent id={translations.CREDITOR_ADDRESS} />}
              value={<FormattedMessageComponent id={translations.CREDITOR_ADDRESS_VALUE} />}
            />
          </SepaModalColumnStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_SEPA_MANDATE_PROPS}
              label={<FormattedMessageComponent id={translations.SEPA_MANDATE} />}
              value={sepaMandate?.id}
            />
          </SepaModalColumnStyled>
        </SepaModalRowStyled>
        <SectionDividerComponent
          {...SEPA_MODAL_DEBTOR_DIVIDER}
          title={
            <FormattedMessageComponent
              {...SEPA_MODAL_DEBTOR_DIVIDER_TEXT}
              id={translations.DEBTOR_TITLE}
            />
          }
        />
        <SepaModalRowStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_DEBTORS_NAME_PROPS}
              label={<FormattedMessageComponent id={translations.DEBTORS_NAME} />}
              value={userName}
            />
          </SepaModalColumnStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_CYCLE_DAY_PROPS}
              label={<FormattedMessageComponent id={translations.CYCLE_DAY} />}
              value={nextCycleDate}
            />
          </SepaModalColumnStyled>
        </SepaModalRowStyled>
        <SepaModalRowStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_ADDRESS_PROPS}
              label={<FormattedMessageComponent id={translations.DEBTORS_ADDRESS} />}
              value={createMappedDebtorsAddress({ address, formatMessage })}
            />
          </SepaModalColumnStyled>
          <SepaModalColumnStyled>
            <ResumeItemComponent
              {...SEPA_MODAL_TYPE_OF_PAYMENT_PROPS}
              label={<FormattedMessageComponent id={translations.TYPE_OF_PAYMENT} />}
              value={<FormattedMessageComponent id={translations.TYPE_OF_PAYMENT_VALUE} />}
            />
          </SepaModalColumnStyled>
        </SepaModalRowStyled>
      </SepaModalComponentStyled>
    </ModalComponent>
  ) : null;
};

export default SepaModalComponent;
