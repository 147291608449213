// Enumerations
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { updateConsentsResolver } from '../resolvers/profile-consents-step2.resolvers';
// Types
import { ProfileConsentsStep2HandlersPropsType } from './types/profile-consents-step2-props.handlers.type';
import { ProfileConsentsStep2HandlersType } from './types/profile-consents-step2.handlers.type';
import { OnUpdateConsnetsPropsHandlerType } from './types/update-consents-handler-props.handlers.type';
// Utilities
import { mapProfileConsentsToPatchConsents } from '../utils/profile-consents-step2.utils';

const onUpdateConsentsHandler = async ({
  consents,
  challenge,
  initialConsents,
  setConsents,
  setChallengeError,
  setFetching,
  setResult,
}: OnUpdateConsnetsPropsHandlerType): Promise<void> => {
  setFetching(true);

  const [, error] = await updateConsentsResolver({
    arrangementId: consents?.arrangementId ?? '',
    body: consents ? mapProfileConsentsToPatchConsents(consents) : {},
    progressId: challenge?.progressId ?? '',
  });

  if (error === HttpStatusCodesEnum.FORBIDDEN) {
    setChallengeError(true);
    setFetching(false);
    return;
  }

  if (error) {
    setResult(false);
    setConsents(initialConsents);
  } else {
    setResult(true);
  }

  setFetching(false);
};

const challengeSuccessHandler = ({
  setChallenge,
  setChallenged,
  challenge,
}: Pick<ProfileConsentsStep2HandlersPropsType, 'challenge' | 'setChallenge' | 'setChallenged'>) => {
  setChallenge(challenge);
  setChallenged(true);
};

const challengeErrorHandler = ({
  challenge,
  setError,
}: Pick<ProfileConsentsStep2HandlersPropsType, 'challenge' | 'setError'>) => {
  if (!challenge) {
    setError(true);
  }
};

const onRetryButtonClickHandler = ({
  setChallengeError,
  setChallenged,
}: Pick<ProfileConsentsStep2HandlersPropsType, 'setChallenged' | 'setChallengeError'>) => {
  setChallengeError(prev => !prev);
  setChallenged(false);
};

const ProfileConsentsStep2Handlers = ({
  challenge,
  consents,
  initialConsents,
  setChallenge,
  setChallengeError,
  setChallenged,
  setConsents,
  setError,
  setFetching,
  setResult,
}: ProfileConsentsStep2HandlersPropsType): ProfileConsentsStep2HandlersType => ({
  handleUpdateConsents: () =>
    onUpdateConsentsHandler({
      challenge,
      consents,
      initialConsents,
      setChallengeError,
      setConsents,
      setFetching,
      setResult,
    }),
  handleChallengeError: newChallange =>
    challengeErrorHandler({ challenge: newChallange, setError }),
  handleChallengeRetryButtonClick: () =>
    onRetryButtonClickHandler({ setChallenged, setChallengeError }),
  handleChallengeSuccess: newChallange =>
    challengeSuccessHandler({ challenge: newChallange, setChallenge, setChallenged }),
});

export default ProfileConsentsStep2Handlers;
