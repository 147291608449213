// Translations
import {
  CONSUMPTION_EXPENSES_MONTH_COURSE,
  EXPENSES_CONSUMPTION,
  TOTAL_INTEREST,
} from '../translations/card-settings-statements-table-row-details.translations';
import { AmazonTheme } from '@openbank/cf-ui-static-data';

const CARD_STATEMENTS_TABLE_ROW_DETAILS_LAYOUT_PROPS = {
    gap: AmazonTheme.default.spaces.spacingS,
  },
  CARD_STATEMENTS_TABLE_ROW_DETAILS_ITEMS = [
    {
      showAlways: true,
      testId: 'card-settings-statements-table-row-details-expenses-consumption',
      title: EXPENSES_CONSUMPTION,
      value: 'amountPayConsumptionMonth',
    },
    {
      testId: 'card-settings-statements-table-row-details-total-interest',
      title: TOTAL_INTEREST,
      value: 'totalInterest',
    },
    {
      testId: 'card-settings-statements-table-row-details-consumption-expenses-month-course',
      title: CONSUMPTION_EXPENSES_MONTH_COURSE,
      value: 'consumptionExpenseMonthCourse',
    },
  ];

export { CARD_STATEMENTS_TABLE_ROW_DETAILS_LAYOUT_PROPS, CARD_STATEMENTS_TABLE_ROW_DETAILS_ITEMS };
