// Constants
import { assetConstants } from '@openbank/cb-ui-commons';

export default {
  ERROR_ASSET_CONFIGURATION_PROPS: {
    assetConfiguration: {
      id: 'error-search-results',
      sizes: {
        height: 160,
        width: 160,
      },
      extension: assetConstants.EXTENSIONS.PNG,
      type: assetConstants.TYPES.IMAGE,
    },
    smallSize: true,
    testing: {
      descriptionId: 'card-settings-configurations-error-table-row-description',
      titleId: 'card-settings-configurations-error-title-section',
    },
  },
};
