// Constants
import { NO_SUBCONSENT_FOUND } from './constants/profile-consents-step1.handlers.utils.constants';
// Constants
import { NUMBER } from 'constants/number.constants';
// Enumerations
import { ConsentTypesEnum } from 'containers/personal/views/consents/enumerations/consent-types.enum';
// Types
import { ProfileConsentType } from 'containers/personal/views/consents/types/profile-consent-type.type';
import { ProfileConsentsConfig } from 'containers/personal/views/consents/types/profile-consent.config.type';
import { ProfileSubConsent } from 'containers/personal/views/consents/types/profile-consent.type';

const toggleSubconsent = (
  subConsents: Array<ProfileSubConsent>,
  index: number
): Array<ProfileSubConsent> =>
  subConsents.map((subConsent, i) => {
    if (i === index) {
      return {
        ...subConsent,
        value: !subConsent.value,
      };
    }

    return subConsent;
  });

const toggleSubconsentById = (
  consents: ProfileConsentsConfig['consentTypes'],
  subConsentId: string
): ProfileConsentsConfig['consentTypes'] =>
  consents.map((consent, index) => {
    const subConsentIndex = consent.subConsents.findIndex(({ id }) => id === subConsentId);

    if (subConsentIndex !== NO_SUBCONSENT_FOUND) {
      return {
        ...consent,
        subConsents: toggleSubconsent(consent.subConsents, subConsentIndex),
      };
    }

    return consent;
  });

const toggleSubconsents = (consent: ProfileConsentType): Array<ProfileSubConsent> => {
  const hasNoSubconsents = consent.subConsents?.length < NUMBER.TWO;

  if (hasNoSubconsents) {
    return consent.subConsents.map(subConsent => ({ ...subConsent, value: !subConsent.value }));
  }

  if (consent.showConsents) {
    return consent.subConsents.map(subConsent => ({ ...subConsent, value: false }));
  }

  return consent.subConsents;
};

const toggleConsent = ({
  consents,
  index,
  tracking,
}: {
  consents: ProfileConsentsConfig['consentTypes'];
  index: number;
  tracking(checked: boolean, id: string);
}): ProfileConsentsConfig['consentTypes'] =>
  consents.map((consent, i) => {
    if (i === index) {
      tracking(!consent.showConsents, consent.id);
      return {
        ...consent,
        subConsents: toggleSubconsents(consent),
        showConsents: !consent.showConsents,
      };
    }

    return consent;
  });

export { toggleConsent, toggleSubconsentById };
