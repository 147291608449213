// Components
import FinancingPaymentMethodFirstStepComponent from '../components/first-step/financing-early-repayment-first-step.component';
import FinancingPaymentMethodSecondStepComponent from '../components/second-step/financing-early-repayment-second-step.component';
// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Translations
import { FAILED, SUCCESS, STEPS } from '../translations/financing-early-repayment.translations';

const FETCH_ERROR_PROPS = {
    testing: {
      loaderTestId: 'financing-early-repayment-fetch-error-component-loader',
    },
  },
  EARLY_REPAYMENT_WIZARD_STEPS_PROPS = [
    {
      component: FinancingPaymentMethodFirstStepComponent,
      title: STEPS.FIRST.TITLE,
    },
    {
      component: FinancingPaymentMethodSecondStepComponent,
      title: STEPS.SECOND.TITLE,
    },
  ],
  EARLY_REPAYMENT_WIZARD_SUCCESS_PROPS = {
    description: SUCCESS.CONFIRMATION.CONTENT,
    linkLabel: SUCCESS.BACK_BUTTTON,
    title: SUCCESS.CONFIRMATION.TITLE,
    exitRoute: RoutesEnumeration.FINANCING,
  },
  EARLY_REPAYMENT_WIZARD_ERROR_PROPS = {
    description: FAILED.CONFIRMATION.CONTENT,
    title: FAILED.CONFIRMATION.TITLE,
    exitRoute: RoutesEnumeration.FINANCING,
  };

export {
  FETCH_ERROR_PROPS,
  EARLY_REPAYMENT_WIZARD_ERROR_PROPS,
  EARLY_REPAYMENT_WIZARD_STEPS_PROPS,
  EARLY_REPAYMENT_WIZARD_SUCCESS_PROPS,
};
