const TABLE_ORGANISM_PROPS_IDS = {
  CARD_STATEMENTS: 'card-statements',
  DASHBOARD: 'dashboard',
  DENIED_TRANSACTIONS: 'denied-transactions',
  DOWNLOAD: 'download',
  FINANCING: 'financing',
  TRANSACTIONS: 'transactions',
};

export { TABLE_ORGANISM_PROPS_IDS };
