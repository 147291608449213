// Constants
import { DETAIL_ROUTE } from './constants/device-header.handlers.constants';
// Types
import { DeviceHeaderHandlersReturnType } from './types/device-header.handlers.return.type';
import { DeviceHeaderHandlersType } from './types/device-header.handlers.type';

const navigateToDetail = ({navigate, device}) => {
  navigate(`${DETAIL_ROUTE}${device.deviceUUID}`);
};

const DeviceHeaderHandlers = (
  props: DeviceHeaderHandlersType): DeviceHeaderHandlersReturnType => ({
    onLinkClick: () => navigateToDetail(props)
  }
);

export { DeviceHeaderHandlers };