const DOMAIN = 'cobranded.services.userSettings.privateArea.financing.cbLPI.cancellation.';

const DESCRIPTION = `${DOMAIN}description`,
  DESCRIPTION_2 = `${DOMAIN}description2`,
  BUTTON = `${DOMAIN}confirmCancellationButton`,
  DETAIL_CONFIRMATION_CANCELLING_LABEL = `${DOMAIN}detailConfirmationCancellingLabel`,
  DETAIL_CONFIRMATION_CANCELLING_VALUE = `${DOMAIN}detailConfirmationCancellingValue`,
  DETAIL_CONFIRMATION_STATUS_LABEL = `${DOMAIN}detailConfirmationStatusLabel`,
  DETAIL_CONFIRMATION_STATUS_VALUE = `${DOMAIN}detailConfirmationStatusValue`,
  FEEDBACK_ERROR_DESCRIPTION = `${DOMAIN}error.description`,
  FEEDBACK_ERROR_TITLE = `${DOMAIN}error.title`,
  FEEDBACK_SUCCESS_DESCRIPTION = `${DOMAIN}success.description`,
  FEEDBACK_SUCCESS_TITLE = `${DOMAIN}success.title`,
  GO_TO_INSURANCES = `${DOMAIN}goToInsurancesLink`,
  TITLE = `${DOMAIN}title`;

export {
  DESCRIPTION,
  DESCRIPTION_2,
  BUTTON,
  DETAIL_CONFIRMATION_CANCELLING_LABEL,
  DETAIL_CONFIRMATION_CANCELLING_VALUE,
  DETAIL_CONFIRMATION_STATUS_LABEL,
  DETAIL_CONFIRMATION_STATUS_VALUE,
  FEEDBACK_ERROR_DESCRIPTION,
  FEEDBACK_ERROR_TITLE,
  FEEDBACK_SUCCESS_DESCRIPTION,
  FEEDBACK_SUCCESS_TITLE,
  GO_TO_INSURANCES,
  TITLE,
};
