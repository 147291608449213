// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const NOTIFICATIONS_ROUTE_PROPS = {
  index: true,
},
NOTIFICATIONS_SETTINGS_ROUTE_PROPS = {
  path: RoutesEnumeration.NOTIFICATIONS_SETTINGS,
};

export {
  NOTIFICATIONS_ROUTE_PROPS,
  NOTIFICATIONS_SETTINGS_ROUTE_PROPS,
};