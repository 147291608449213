// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { RecoveryKeyStep1ActionsComponent } from './components/actions/recovery-key-step1-actions.component';
import { RecoveryKeyEmailComponent } from '../email/recovery-key-email.component';
import { RecoveryKeyPhoneComponent } from '../phone/recovery-key-phone.component';
import { CaptchaComponent } from 'components/captcha/captcha.component';
// Enumerations
import { FeatureFlagsEnumeration } from 'enumerations/feature-flags.enumeration';
// Styles
import {
  RecoveryKeyStep1ComponentStyled,
  RecoveryKeyStep1ContentComponentStyled,
} from './recovery-key-step1.component.styled';
// Translations
import { DESCRIPTION } from './translations/recovery-key-step1.translations';

const RecoveryKeyStep1Component = (): React.ReactElement => (
  <RecoveryKeyStep1ComponentStyled>
    <ParagraphComponent>
      <FormattedMessageComponent id={DESCRIPTION} />
    </ParagraphComponent>
    <RecoveryKeyStep1ContentComponentStyled>
      <RecoveryKeyEmailComponent />
      <RecoveryKeyPhoneComponent />
    </RecoveryKeyStep1ContentComponentStyled>
    <CaptchaComponent featureFlag={FeatureFlagsEnumeration.CAPTCHA_CHANGE_ACCESS_KEY} />
    <RecoveryKeyStep1ActionsComponent />
  </RecoveryKeyStep1ComponentStyled>
);

export { RecoveryKeyStep1Component };
