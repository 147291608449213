// Constants
import { buttonConstants } from '@openbank/cb-ui-commons';
import { FINANCING_PPI_LANDING_CARD_BASE_PROPS } from './financing-ppi-landing.configuration.constants';

const FINANCING_PPI_LANDING_CANCEL_BUTTON_PROPS = {
  type: buttonConstants.TYPES.SECONDARY,
  testId: `${FINANCING_PPI_LANDING_CARD_BASE_PROPS.testId}-cancel-button`,
};

const FINANCING_PPI_LANDING_CONTRACT_BUTTON_PROPS = {
  testId: `${FINANCING_PPI_LANDING_CARD_BASE_PROPS.testId}-contract-button`,
};

export { FINANCING_PPI_LANDING_CANCEL_BUTTON_PROPS, FINANCING_PPI_LANDING_CONTRACT_BUTTON_PROPS };
