// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ProfilePersonalDataMessageCCComponent } from './components/message-cc/profile-personal-data-message-cc.component';
import { TitleHeaderComponent } from '@openbank/cb-ui-commons';
import ProfilePersonalDataAddressComponent from './components/address/profile-personal-data-address.component';
import ProfilePersonalDataContactComponent from './components/contact/profile-personal-data-contact.component';
// import ProfilePersonalDataFormComponent from './components/form/profile-personal-data-form.component';
// Constants
import constants from './constants/profile-personal-data.constants';
// Styles
import {
  AppCardVerticalSectionComponentStyled,
  AppCardSectionComponentStyled,
  LayoutHorizontalPaddingStyledComponent,
} from 'styles/app.styled';
import {
  ProfilePersonalDataComponentStyled,
  ProfilePersonalDataContentComponentStyled,
} from './profile-personal-data.component.styled';
// Translations
import translations from './translations/profile-personal-data.translations';

const ProfilePersonalDataComponent = (): React.ReactElement => (
  <AppCardSectionComponentStyled>
    <AppCardVerticalSectionComponentStyled>
      <ProfilePersonalDataComponentStyled>
        <LayoutHorizontalPaddingStyledComponent>
          <TitleHeaderComponent {...constants.PROFILE_PERSONAL_CARD_TITLE_PROPS}>
            <FormattedMessageComponent id={translations.TITLE} />
          </TitleHeaderComponent>
        </LayoutHorizontalPaddingStyledComponent>
        <ProfilePersonalDataContentComponentStyled>
          <ProfilePersonalDataMessageCCComponent />
          <ProfilePersonalDataContactComponent />
          <ProfilePersonalDataAddressComponent />
          {/* <ProfilePersonalDataFormComponent /> */}
        </ProfilePersonalDataContentComponentStyled>
      </ProfilePersonalDataComponentStyled>
    </AppCardVerticalSectionComponentStyled>
  </AppCardSectionComponentStyled>
);

export default ProfilePersonalDataComponent;
