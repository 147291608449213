const MODAL_BUTTON_PROPS = {
  testId: 'address-modal-save-button',
};

const MODAL_LINK_PROPS = {
  testId: 'address-modal-cancel-button',
  type: 'tertiary',
};

const MODAL_PROPS = {
  testId: 'address-modal-component',
};

export { MODAL_BUTTON_PROPS, MODAL_LINK_PROPS, MODAL_PROPS };
