// Vendors
import { useState } from 'react';
// Contexts
import { useWizardFeedbackContextConsumerHook } from 'components/wizard-feedback/contexts/wizard-feedback.context';
import { useFinancingPPIContextConsumerHook } from 'containers/financing/views/ppi/contexts/financing-ppi.context';
import { useInsurancesContextConsumerHook } from 'contexts/insurances/insurances.context';
// Events handlers
import { FinancingPPICancellationLandingFooterTrackingHandlers } from '../handlers/financing-ppi-cancellation-landing-footer.tracking.handlers';
import { FinancingPPICancellationLandingFooterHandlers } from '../handlers/financing-ppi-cancellation-landing-footer.handlers';
// Tracking
import AppTrackingHook from 'tracking/app.tracking.hook';
// Types
import { FinancingPPICancellationLandingFooterHookType } from './types/financing-ppi-cancellation-landing-footer.hook.type';

const useFinancingPPICancellationLandingFooterActionsHook =
  (): FinancingPPICancellationLandingFooterHookType => {
    const [challenged, setChallenged] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(false);
    const [progressId, setProgressId] = useState<string>('');

    const { setFetchPPI } = useFinancingPPIContextConsumerHook();
    const { insurancesInfo } = useInsurancesContextConsumerHook(),
      { contractId } = insurancesInfo?.insurances.CPI || {};

    const { setResult } = useWizardFeedbackContextConsumerHook();

    return {
      ...FinancingPPICancellationLandingFooterHandlers({
        ...AppTrackingHook(FinancingPPICancellationLandingFooterTrackingHandlers),
        contractId,
        progressId,
        setFetching,
        setFetchPPI,
        setChallenged,
        setProgressId,
        setResult,
      }),
      contractId,
      challenged,
      fetching,
    };
  };

export { useFinancingPPICancellationLandingFooterActionsHook };
