// Vendors
import styled, { StyledProps } from 'styled-components';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Utils
import { convertPxsToRems, mobileMediaQuery } from '@openbank/cf-ui-framework';

function setBackgroundBubbleStatus({ expired, theme, value }: any): any {
  return !expired && !value
    ? theme.colors.backgroundWarningD
    : value
    ? theme.colors.backgroundDoneD
    : theme.colors.fontSecondaryB;
}

const ToggleButtonLabel = styled(ParagraphComponent)`
  color: ${({ theme }) => theme.colors.fontPrimaryA};
`;

const ToggleButtonBubbleStatus = styled(ParagraphComponent)<{ value: boolean; expired: boolean }>`
  background-color: ${({ expired, value, theme }) =>
    setBackgroundBubbleStatus({ expired, theme, value })};
  border-radius: ${convertPxsToRems({ pixels: 24, base: 16 })};
  font-size: ${({ theme }) => theme.fontSizes.fontSizeP};
  padding: ${convertPxsToRems({ pixels: 4, base: 16 })} ${convertPxsToRems({ pixels: 8, base: 16 })};
`;

const ToggleButtonActioner = styled.button.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
}))<{ disabled?: boolean }>`
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: max-content;
  ${({ disabled, theme }) =>
    disabled && { color: theme.colors.colorLinkTertiaryA, cursor: 'not-allowed' }}
`;
const ToggleButtonExpirationComponentStyled = styled.div`
  p {
    font-size: ${convertPxsToRems({ pixels: 10, base: 16 })};
    text-align: center;
  }
`;
ToggleButtonExpirationComponentStyled.displayName = 'ToggleButtonExpirationComponentStyled';

const ToggleButtonActionerText = styled(ParagraphComponent)<{ disabled?: boolean }>`
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.colorLinkTertiaryA : theme.colors.linkPrimaryA};
  font-size: ${({ theme }) => theme.fontSizes.fontSizeP};
  ${({ disabled }) => !disabled && { textDecoration: 'underline' }}
`;

const ToggleButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
`;

const ToggleButtonComponentStyled = styled.div.attrs(({ testId }: { testId?: string }) => ({
  ...(testId ? { 'data-testid': testId } : {}),
  ariaLabel: 'toggle',
  role: 'toggle',
  tabIndex: 0,
  title: 'toggle',
}))`
  align-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spaces.spacingXxs};
  padding: 0 ${({ theme }) => theme.spaces.spacingXxl};

  ${mobileMediaQuery} {
    padding: 0;
  }
`;

export {
  ToggleButtonActioner,
  ToggleButtonActionerText,
  ToggleButtonBubbleStatus,
  ToggleButtonComponentStyled,
  ToggleButtonExpirationComponentStyled,
  ToggleButtonContainer,
  ToggleButtonLabel,
};
