// Vendors
import { HttpStatusCodesEnum } from '@openbank/cf-ui-static-data';
// Resolvers
import { cancelEppResolver } from 'containers/financing/views/early-repayment/resolvers/financing-early-repayment.resolvers';
// Types
import { FinancingEarlyRepaymentSecondStepActionsHandlersType } from './types/financing-early-repayment-second-step-actions.handlers.type';
import { NextButtonClickHandlerType } from './types/next-button-click-handler.type';
import { FinancingEarlyRepaymentSecondStepActionsReturnHandlersType } from './types/financing-early-repayment-second-step-actions-return.handlers.type';

const nextButtonClickHandler = async ({
  challenge,
  cardId,
  eppId,
  setChallenge,
  setChallengeError,
  setError,
  setResult,
}: NextButtonClickHandlerType) => {
  if (eppId) {
    const [, error] = await cancelEppResolver({
      cardId,
      eppId,
      progressId: challenge?.progressId,
    });

    if (error) {
      setChallenge(null);

      if (error === HttpStatusCodesEnum.FORBIDDEN) {
        setChallengeError(true);
      } else {
        setResult(false);
      }
    } else {
      setResult(true);
    }
  } else {
    setError(true);
  }
};

const FinancingEarlyRepaymentSecondStepActionsHandlers = (
  props: FinancingEarlyRepaymentSecondStepActionsHandlersType
): FinancingEarlyRepaymentSecondStepActionsReturnHandlersType => ({
  handleNextButtonClick: () => nextButtonClickHandler(props),
});

export default FinancingEarlyRepaymentSecondStepActionsHandlers;
