// Vendors
import styled from 'styled-components';

const FilterModalListSelectorComponentStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.spaces.spacingS};
`;
export { FilterModalListSelectorComponentStyled };
