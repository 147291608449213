// Vendors
import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
// Constants
import {
  ANY_PROSPECT_NAVIGATE_ROUTE_PROPS,
  ANY_PROSPECT_ROUTE_PROPS,
  LOGIN_ROUTE_PROPS,
  RECOVERY_KEY_ROUTE_PROPS,
} from './constants/app-prospect.constants';
// Containers
const LoginContainer = lazy(() => import('containers/login/login.container'));
const RecoveryKeyContainer = lazy(() => import('containers/recovery-key/recovery-key.container'));

const AppProspectComponent = (): React.ReactElement => (
  <Routes>
    <Route {...LOGIN_ROUTE_PROPS} element={<LoginContainer />} />
    <Route {...RECOVERY_KEY_ROUTE_PROPS} element={<RecoveryKeyContainer />} />
    <Route
      {...ANY_PROSPECT_ROUTE_PROPS}
      element={<Navigate {...ANY_PROSPECT_NAVIGATE_ROUTE_PROPS} />}
    />
  </Routes>
);

export default AppProspectComponent;
