// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { AssetComponent, LinkComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingDownloadErrorComponent } from './components/error/financing-ppi-contracting-download-error.component';
// Constants
import {
  FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS,
  FINANCING_PPI_CONTRACTING_DOWNLOAD_LAYOUT_PROPS,
  FINANCING_PPI_CONTRACTING_DOWNLOAD_LINK_PROPS,
} from './constants/financing-ppi-contracting-download.constants';
// Hooks
import { useFinancingPPIContractingDownloadHook } from './hooks/financing-ppi-contracting-download.hook';
// Styles
import { LayoutColumnFlexComponentStyled } from 'styles/app.styled';
import { FinancingPPIContractingDownloadContentComponentStyled } from './financing-ppi-contracting.component.styled';
// Types
import { FinancingPPIContractingDownloadComponentType } from './types/financing-ppi-contracting-download.component.type';
// Utilities
import {
  getDownloadDocumentTermsAsset,
  getDownloadDocumentTermsLabel,
} from './utils/financing-ppi-contracting-download.utils';

const FinancingPPIContractingDownloadComponent = ({
  translations: { DOWNLOAD, DOWNLOADING, ERROR, ERROR_DESCRIPTION: error, ...rest },
  ...props
}: FinancingPPIContractingDownloadComponentType): React.ReactElement => {
  const {
    fetching,
    errorDocumentDownloaded,
    handleDownloadContractingPPIDocumentClick: onClick,
  } = useFinancingPPIContractingDownloadHook({ ...props, translations: rest });

  return (
    <LayoutColumnFlexComponentStyled {...FINANCING_PPI_CONTRACTING_DOWNLOAD_LAYOUT_PROPS}>
      <FinancingPPIContractingDownloadContentComponentStyled {...{ errorDocumentDownloaded }}>
        <AssetComponent
          {...{ ...FINANCING_PPI_CONTRACTING_DOWNLOAD_ASSET_PROPS, onClick }}
          {...getDownloadDocumentTermsAsset({ fetching, errorDocumentDownloaded })}
          disabled={fetching}
        />
        <LinkComponent
          {...{ ...FINANCING_PPI_CONTRACTING_DOWNLOAD_LINK_PROPS, onClick }}
          disabled={fetching}
        >
          <FormattedMessageComponent
            id={getDownloadDocumentTermsLabel({
              fetching,
              errorDocumentDownloaded,
              translations: { DOWNLOAD, DOWNLOADING, ERROR },
            })}
          />
        </LinkComponent>
      </FinancingPPIContractingDownloadContentComponentStyled>
      <FinancingPPIContractingDownloadErrorComponent {...{ error, errorDocumentDownloaded }} />
    </LayoutColumnFlexComponentStyled>
  );
};

export { FinancingPPIContractingDownloadComponent };
