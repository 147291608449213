// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { LineSeparatorComponent, ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingStep2ExpandableLinkComponent } from '../link/financing-ppi-contracting-step2-expandable-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_DESCRIPTION_LINK_PROPS } from './constants/financing-ppi-contracting-step2-expandable-description-link.constants';
import { FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE } from 'containers/financing/views/ppi/components/contracting/components/step2/constants/financing-ppi-contracting-step2.constants';
// Types
import { FinancingPPIContractingStep2ExpandableDescriptionLinkType } from './types/financing-ppi-contracting-step2-expandable-description-link.type';

const FinancingPPIContractingStep2ExpandableDescriptionLinkComponent = ({
  description01,
  description02,
  title,
  showLineSeparator = true,
}: FinancingPPIContractingStep2ExpandableDescriptionLinkType): React.ReactElement => (
  <>
    <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_BOLD_M_SIZE}>
      <FormattedMessageComponent id={title} />
    </ParagraphComponent>
    <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_DESCRIPTION_LINK_PROPS}>
      <FormattedMessageComponent
        id={description01}
        values={{
          link: (chunks: string | React.ReactNode) => (
            <FinancingPPIContractingStep2ExpandableLinkComponent {...{ chunks }} />
          ),
        }}
      />
    </ParagraphComponent>
    {description02 && (
      <ParagraphComponent {...FINANCING_PPI_CONTRACTING_STEP_2_COVERAGE_DESCRIPTION_LINK_PROPS}>
        <FormattedMessageComponent
          id={description02}
          values={{
            link: (chunks: string | React.ReactNode) => (
              <FinancingPPIContractingStep2ExpandableLinkComponent {...{ chunks }} />
            ),
          }}
        />
      </ParagraphComponent>
    )}
    {showLineSeparator && <LineSeparatorComponent />}
  </>
);

export { FinancingPPIContractingStep2ExpandableDescriptionLinkComponent };
