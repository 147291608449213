const LINK_PROPS = {
  type: 'action_primary',
};

const ASSET_PROPS = {
  id: 'add',
};

const SELECTOR_BOX_BASE_TEST_ID = 'card-settings-block-card-first-step-address-';

const SELECTOR_BOX_ADDRESS_ACTIONS_ID = {
  DELETE: { testId: 'card-settings-block-card-first-step-address-delete' },
  EDIT: { testId: 'card-settings-block-card-first-step-address-edit' },
};

const DEFAULT_REPORT_VALUE = false;

export {
  ASSET_PROPS,
  LINK_PROPS,
  DEFAULT_REPORT_VALUE,
  SELECTOR_BOX_ADDRESS_ACTIONS_ID,
  SELECTOR_BOX_BASE_TEST_ID,
};
