// Vendors
import { useParams } from 'react-router-dom';
// Hooks
import useCbIntl from 'hooks/useCbIntl';
// Contexts
import { AppContextConsumerHook } from 'contexts/app.context';
// Types
import { TransactionsReportDuplicateChargeConfirmationHookReturnType } from './types/transactions-report-duplicate-charge-confirmation.hook.type';

const useTransactionsReportDuplicateChargeConfirmationHook =
  (): TransactionsReportDuplicateChargeConfirmationHookReturnType => {
    const { formatMessage } = useCbIntl();
    const { transactionId } = useParams();
    const { currentCard } = AppContextConsumerHook(),
      { id: cardId } = currentCard || {};

    return {
      formatMessage,
      transactionId,
      cardId,
    };
  };

export { useTransactionsReportDuplicateChargeConfirmationHook };
