// Vendors
import { wasSuccessRequest } from '@openbank/cf-ui-framework';
// Services
import { reportProblemService } from '../services/transactions-report.services';
// Types
import {
  TransactionsReportPropsType,
  TransactionsReportResponseType,
} from '../types/transactions-report.type';

const fetchReportProblemResolver = async (
  props: TransactionsReportPropsType
): Promise<[TransactionsReportResponseType] | [null, number]> => {
  const [response, { status }] = await reportProblemService(props);

  return wasSuccessRequest(status) && 'caseId' in response ? [response] : [null, status];
};

export { fetchReportProblemResolver };
