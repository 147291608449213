// Vendors
import React from 'react';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
// Constants
import { CARD_SETTINGS_STATEMENTS_TABLE_ROW_DATE_PROPS } from './constants/card-settings-statements-table-row-date.constants';
// Hooks
import { useCardSettingsStatementsTableRowDateHook } from './hooks/card-settings-statements-table-row-date.hook';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
// Types
import { CardStatementsType } from 'types/card-statements-type';
// Utils
import { getUserFriendlyRelativeDate } from 'utils/dates/date.utils';

const CardSettingsStatementsTableRowDateComponent = ({
  statementDate: date,
}: Pick<CardStatementsType, 'statementDate'>): React.ReactElement => (
  <ParagraphSecondaryComponentStyled>
    <ParagraphComponent {...CARD_SETTINGS_STATEMENTS_TABLE_ROW_DATE_PROPS}>
      {getUserFriendlyRelativeDate({ ...useCardSettingsStatementsTableRowDateHook(), date })}
    </ParagraphComponent>
  </ParagraphSecondaryComponentStyled>
);

export { CardSettingsStatementsTableRowDateComponent };
