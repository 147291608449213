// Enumerations
import { RoutesEnumeration } from 'enumerations/routes.enumeration';
// Types
import {
  DashboardTableBuilderHandlersType,
  DashboardTableBuilderReturnHandlersType,
} from './types/dashboard-table.handlers.type';
// Utilities
import { generateAbsoluteRoute } from 'utils/app-routing.utils';

const navigateToTransactionsViewLinkClick = ({
  handleNavigateToTransactionsViewLinkClickTracking,
  navigate,
}: DashboardTableBuilderHandlersType): any => {
  handleNavigateToTransactionsViewLinkClickTracking();
  navigate(generateAbsoluteRoute(RoutesEnumeration.TRANSACTIONS));
};

const DashboardTableLinkHandlers = (
  props: DashboardTableBuilderHandlersType
): DashboardTableBuilderReturnHandlersType => ({
  handleNavigateToTransactionsViewLinkClick: () => navigateToTransactionsViewLinkClick(props),
});

export default DashboardTableLinkHandlers;
