// Vendors
import React from 'react';
// Components
import TransactionTableRowComponent from 'components/transaction-table-row/transaction-table-row.component';
import TableRowComponent from 'components/table-row/table-row.component';
import TransactionsLandingRowDetailsComponent from './components/details/transactions-landing-row-details.component';
// Types
import { TransactionsLandingRowComponentType } from './types/transactions-landing-row.component.type';

const TransactionsLandingRowComponent = ({
  element,
}: TransactionsLandingRowComponentType): React.ReactElement<TransactionsLandingRowComponentType> => (
  <TableRowComponent {...{ element }} detailsComponent={TransactionsLandingRowDetailsComponent}>
    <TransactionTableRowComponent {...element} />
  </TableRowComponent>
);

export default TransactionsLandingRowComponent;
