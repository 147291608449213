// Vendors
import React from 'react';
// Components
import CreditComponent from 'components/credit/credit.component';
import TitleSectionComponent from 'components/title-section/title-section.component';
// Constants
import { FINANCING_HEADER_CREDIT_COMPONENT_PROPS } from './constants/financing-header.component.constants';
// Hooks
import { FinancingContentContainerStyled } from 'containers/financing/financing.container.styled';
// Utilities
import { isSecondLevelRoutePath } from 'utils/app-routing.utils';

const FinancingHeaderComponent = (): React.ReactElement => (
  <FinancingContentContainerStyled>
    <TitleSectionComponent />
    {!isSecondLevelRoutePath() && <CreditComponent {...FINANCING_HEADER_CREDIT_COMPONENT_PROPS} />}
  </FinancingContentContainerStyled>
);

export { FinancingHeaderComponent };
