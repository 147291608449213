// Enumerations
import { DocumentsTypesEnumeration } from 'enumerations/documents/documents-types.enumeration';
import { DocumentsUploadStatesEnumeration } from 'enumerations/documents/documents-upload-states.enumeration';

const TRANSACTIONS_REPORT_NOT_RECEIVED_CONTEXT_DEFAULT_VALUE = {
  notReceivedDate: '',
  description: '',
  files: [],
  setNotReceivedDate: (): null => null,
  setDescription: (): null => null,
  setFiles: (): null => null,
};

const FILES_INITIAL_STATE = [
  {
    id: 'proof-of-contact',
    documentId: undefined,
    documentType: DocumentsTypesEnumeration.PROOF_OF_CONTACT,
    file: null,
    state: DocumentsUploadStatesEnumeration.DEFAULT,
  },
];

export { FILES_INITIAL_STATE, TRANSACTIONS_REPORT_NOT_RECEIVED_CONTEXT_DEFAULT_VALUE };
