// Vendors
import React from 'react';
// Components
import { FetchErrorComponent } from '@openbank/cb-ui-commons';
// Hooks
import FeatureFlagsHook from './hooks/feature-flags.hook';
// Types
import type { FeatureFlagsProviderType } from './types/feature-flags.provider.type';

const FeatureFlagsProvider = ({ children }: FeatureFlagsProviderType): React.ReactElement => {
  const { fetching } = FeatureFlagsHook();

  return <FetchErrorComponent {...{ fetching }}>{children}</FetchErrorComponent>;
};

export { FeatureFlagsProvider };
