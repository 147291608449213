// Resolvers
import { getRevolvingOptionsResolver } from '../resolvers/financing-payment-method.resolvers';
// Types
import { FinancingPaymentMethodHandlersType } from './types/financing-payment-method.handlers.type';
import { FinancingPaymentMethodReturnHandlersType } from './types/financing-payment-method-return.handlers.type';
import { LoadRevolvingOptionsType } from './types/load-revolving-options.type';

const loadRevolvingOptionsHandler = async ({
  handleSelectCurrentMethod,
  setError,
  setFetching,
  setRevolvingOptions,
}: LoadRevolvingOptionsType) => {
  setFetching(true);

  const [response, error] = await getRevolvingOptionsResolver();

  if (error) {
    setError(true);
  } else {
    setRevolvingOptions(response);
    handleSelectCurrentMethod();
  }

  setFetching(false);
};

const FinancingPaymentMethodHandlers = (
  props: FinancingPaymentMethodHandlersType
): FinancingPaymentMethodReturnHandlersType => ({
  handleLoadRevolvingOptions: () => loadRevolvingOptionsHandler(props),
});

export default FinancingPaymentMethodHandlers;
