// Vendors
import React, { useState, useContext, createContext, useMemo } from 'react';
// Constants
import {
  FILES_INITIAL_STATE,
  TRANSACTIONS_REPORT_NOT_RECEIVED_CONTEXT_DEFAULT_VALUE,
} from './constants/transactions-report-not-received.context.constants';
// Types
import { ReactChildrenType } from '@openbank/cf-ui-static-data';
import { TransactionsReportNotReceivedContextType } from './types/transactions-report-not-received.context.type';
import { FileDataType } from 'types/file-data.type';

const TransactionsReportNotReceivedContext =
  createContext<TransactionsReportNotReceivedContextType>(
    TRANSACTIONS_REPORT_NOT_RECEIVED_CONTEXT_DEFAULT_VALUE
  );

export const TransactionsReportNotReceivedContextProvider = ({
  children,
}: ReactChildrenType): React.ReactElement<
  React.Provider<TransactionsReportNotReceivedContextType>
> => {
  const [notReceivedDate, setNotReceivedDate] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [files, setFiles] = useState<FileDataType[]>(FILES_INITIAL_STATE);

  const contextValue = useMemo(
    () => ({
      notReceivedDate,
      description,
      files,
      setNotReceivedDate,
      setDescription,
      setFiles,
    }),
    [notReceivedDate, description, files]
  );

  return (
    <TransactionsReportNotReceivedContext.Provider value={contextValue}>
      {children}
    </TransactionsReportNotReceivedContext.Provider>
  );
};

export const useTransactionsReportNotReceivedContextConsumerHook =
  (): TransactionsReportNotReceivedContextType => useContext(TransactionsReportNotReceivedContext);

/* eslint-disable */
export const TransactionsReportNotReceivedContextConsumerHOC =
  (Component: React.FunctionComponent<unknown>): React.FunctionComponent<unknown> =>
  (props: any): React.ReactElement<React.Provider<TransactionsReportNotReceivedContextType>> =>
    (
      <TransactionsReportNotReceivedContextProvider>
        <Component {...props} />
      </TransactionsReportNotReceivedContextProvider>
    );
