// Types
import {
  PersonalNumberLogoutModalBuilderHandlersType,
  PersonalNumberLogoutModalBuilderReturnHandlersType,
} from './types/personal-number-logout-modal.handlers.type';

const logoutFromChangeEmailButtonClickHandler = async ({
  handleLogoutFromChangeNumberButtonClickTracking,
  handleMakeUserLogoutEvent,
  setFetching,
}: PersonalNumberLogoutModalBuilderHandlersType): Promise<void> => {
  handleLogoutFromChangeNumberButtonClickTracking();
  setFetching(true);

  await handleMakeUserLogoutEvent();
};

const PersonalNumberLogoutModalHandlers = (
  props: PersonalNumberLogoutModalBuilderHandlersType
): PersonalNumberLogoutModalBuilderReturnHandlersType => ({
  handleLogoutFromChangeNumberButtonClick: () => logoutFromChangeEmailButtonClickHandler(props),
});

export default PersonalNumberLogoutModalHandlers;
