// Vendors
import React from 'react';
// Components
import TitleSectionComponent from 'components/title-section/title-section.component';
import IbanBillingRouterComponent from './components/router/iban-billing-router.component';
// Styles
import { LayoutGridComponentStyled } from 'styles/app.styled';

const IbanBillingContainer = (): React.ReactElement => (
  <LayoutGridComponentStyled>
    <TitleSectionComponent />
    <IbanBillingRouterComponent />
  </LayoutGridComponentStyled>
);

export default IbanBillingContainer;
