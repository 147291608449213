// Components
import CardSettingsCheck3dWizardStep1Component from '../../components/step1/card-settings-check-3d-wizard-step1.component';
import CardSettingsCheck3dWizardStep2Component from '../../components/step2/card-settings-check-3d-wizard-step2.component';
// Translations
import {
  STEP_1,
  STEP_2,
} from '../translations/card-settings-check-3d-wizard.configuration.translations';

const CARD_SETTINGS_CHECK_3D_WIZARD_STEPS_CONFIGURATION = [
  {
    component: CardSettingsCheck3dWizardStep1Component,
    title: STEP_1,
  },
  {
    component: CardSettingsCheck3dWizardStep2Component,
    title: STEP_2,
  },
];

export { CARD_SETTINGS_CHECK_3D_WIZARD_STEPS_CONFIGURATION };
