// Vendors
import { TrackingEventsEnum, TrackingLinkEventReturnType } from '@openbank/cf-ui-static-data';
// Types
import { CardSettingsLandingFooterHookTrackingHandlersType } from 'containers/card-settings/views/landing/components/footer/hooks/handlers/types/card-settings-landing-footer.hook.tracking.handlers.type';
import { TrackBuilderType } from 'types/tracking/track-builder.type';

const blockDefinitiveCardButtonClickTrackingHandler = (): TrackingLinkEventReturnType => ({
  payload: {
    eventAction: 'clickButton',
    eventLabel: 'definitiveBlockCardButton',
  },
  type: TrackingEventsEnum.LINK,
});

const CardSettingsLandingFooterHookTrackingCommonHandlers = (
  track: TrackBuilderType
): CardSettingsLandingFooterHookTrackingHandlersType => ({
  handleBlockDefinitiveCardButtonClickTracking: () =>
    track(blockDefinitiveCardButtonClickTrackingHandler()),
});

export { CardSettingsLandingFooterHookTrackingCommonHandlers };
