// Vendors
import { apiUtilsCb } from '@openbank/cf-ui-architecture-core';
// Constants
import constants from './constants/request-portability-step3.services.constants';
// Types
import { ChallengeType } from 'types/challenge.type';
import { RequestPortabilityStep3ActionsApiBuilderType } from '../types/request-portability-step3-actions-api-builder.type';

const generateRequestPortabilityOtpService = async ({
  progressId,
  portabilityParams,
}: RequestPortabilityStep3ActionsApiBuilderType): Promise<[ChallengeType, Response]> => {
  const customHeaders = { deviceLocale: 'de_DE', applicationLocale: 'de_DE', bankId: 'MB_DEU' };

  if (progressId) {
    customHeaders['progressId'] = progressId;
  }

  return apiUtilsCb.post({
    ...constants.GENERATE_OTP_SERVICE_CONFIGURATION,
    customHeaders,
    params: portabilityParams,
  });
};

export { generateRequestPortabilityOtpService };
