// Translations
import {
  ADDITIONAL_INFORMATION_TITLE,
  BLOCK_CARD_TITLE,
  CONFIRM_BUTTON_LABEL,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  INVALID_DATE_ERROR_MESSAGE,
  PAGE_TITLE,
  PRODUCT_INFORMATION_DESCRIPTION,
  PRODUCT_INFORMATION_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK_LABEL,
  SUCCESS_TITLE,
  UPLOAD_DOCUMENTS_DESCRIPTION,
  UPLOAD_DOCUMENTS_OPTIONAL_TITLE,
  UPLOAD_DOCUMENTS_TITLE,
} from './transactions-report.translations.common';

export {
  ADDITIONAL_INFORMATION_TITLE,
  BLOCK_CARD_TITLE,
  CONFIRM_BUTTON_LABEL,
  CONFIRMATION_DESCRIPTION,
  CONFIRMATION_TITLE,
  ERROR_DESCRIPTION,
  ERROR_TITLE,
  INVALID_DATE_ERROR_MESSAGE,
  PAGE_TITLE,
  PRODUCT_INFORMATION_DESCRIPTION,
  PRODUCT_INFORMATION_TITLE,
  SUCCESS_DESCRIPTION,
  SUCCESS_LINK_LABEL,
  SUCCESS_TITLE,
  UPLOAD_DOCUMENTS_DESCRIPTION,
  UPLOAD_DOCUMENTS_OPTIONAL_TITLE,
  UPLOAD_DOCUMENTS_TITLE,
};
