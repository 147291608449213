// Vendors
import { useRef } from 'react';
import { useWatch } from 'react-hook-form';
// Handlers
import { AddressModalInputsStreetHandlers } from '../handlers/address-modal-inputs-street.handlers';
// Hooks
import { useAddressPredictions } from 'hooks/useAddressPredictions';
// Types
import { AddressModalInputsStreetHookType } from './types/address-modal-inputs-street.hook.type';
import { AddressModalInputsStreetHookReturnType } from './types/address-modal-inputs-street-return.hook.type';
// Utils
import { formatOptions } from './utils/address-modal-inputs-street.hook.utils';

export const useAddressModalInputsStreetHook = ({
  control,
  setValue,
}: AddressModalInputsStreetHookType): AddressModalInputsStreetHookReturnType => {
  const { street = '' } = useWatch({ control });

  const { predictions, addDetailsToPredictionUtil, isLoading } = useAddressPredictions(street);

  const { handleStreetOptionClick } = AddressModalInputsStreetHandlers({
    addDetailsToPredictionUtil,
    setValue,
  });

  return {
    isLoading,
    onElementClick: handleStreetOptionClick,
    options: formatOptions(street, predictions),
  };
};
