// Enumeration
import { RoutesEnumeration } from 'enumerations/routes.enumeration';

const ACCESS_PORTABILITY_LANDING_ROUTE_PROPS = {
  index: true,
};
const ACCESS_REQUEST_ROUTE_PROPS = {
  path: RoutesEnumeration.ACCESS_REQUEST,
};
const PORTABILITY_REQUEST_ROUTE_PROPS = {
  path: RoutesEnumeration.PORTABILITY_REQUEST,
};


export {
  ACCESS_REQUEST_ROUTE_PROPS,
  ACCESS_PORTABILITY_LANDING_ROUTE_PROPS,
  PORTABILITY_REQUEST_ROUTE_PROPS,
};
