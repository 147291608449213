// Enumerations
import { TransactionsEppStatusCodeEnumeration } from 'enumerations/transactions-epp-status-code.enumeration';
// Types
import { TransactionsTableBuilderHandlersType } from 'organisms/table-organism/components/transactions-table/handlers/types/transactions-table-builder.handlers.type';
import { TransactionsDetailsBuilderHandlersType } from './types/transactions-details-builder.handlers.type';
import { TransactionsDetailsBuilderReturnHandlersType } from './types/transactions-details-return-builder.handlers.type';
// Resolvers
import { getEppDetailsResolver } from 'resolvers/epp/epp.resolvers';
// Utilities
import { isLegitTransactionsDetailResponse } from './utils/transactions-details.handlers.utils';

const fetchCardsTransactionsDetailHandler = async ({
  cardId,
  resolver = () => Promise.resolve([]),
  setDetails,
  setEppDetails,
  setError,
  setFetching,
  transactionId,
}: Omit<TransactionsDetailsBuilderHandlersType, 'wrapperError'>): Promise<void> => {
  try {
    setFetching(true);

    const [response, error] = await resolver({ cardId, transactionId });

    if (error) {
      throw new Error();
    }

    if (isLegitTransactionsDetailResponse(response)) {
      setDetails(response);
      setError(false);

      const { eppStatusCode, eppId } = response;

      if (eppStatusCode === TransactionsEppStatusCodeEnumeration.CONVERTED_TO_EPP) {
        const [response, error] = await getEppDetailsResolver({ cardId, eppId });

        if (error) {
          throw new Error();
        }

        setEppDetails(response);
      }
    }
  } catch (_) {
    setError(true);
  } finally {
    setFetching(false);
  }
};

const setOutProvidingErrorEvent = ({ setError }) => {
  setError(true);
};

const closeTransactionsDetailsHandler = ({
  setDetails,
  setError,
}: Pick<TransactionsDetailsBuilderHandlersType, 'setDetails' | 'setError'>): void => {
  setDetails({});
  setError(false);
};

const TransactionsDetailsHandlers = ({
  cardId,
  resolver,
  setDetails,
  setEppDetails,
  setError,
  setFetching,
  transactionId,
}: TransactionsDetailsBuilderHandlersType): TransactionsDetailsBuilderReturnHandlersType => ({
  handleCloseTransactionsDetail: () => closeTransactionsDetailsHandler({ setDetails, setError }),
  handleFetchCardTransactionsDetail: () =>
    fetchCardsTransactionsDetailHandler({
      cardId,
      resolver,
      setDetails,
      setEppDetails,
      setError,
      setFetching,
      transactionId,
    }),
  handleSetOutProvidingErrorEvent: () => setOutProvidingErrorEvent({ setError }),
});

export { TransactionsDetailsHandlers };
