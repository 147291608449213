// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import SettingsCardControlOperationsToggleComponent from 'containers/card-settings/views/card-control/components/operations/components/toggle/settings-card-control-operations-toggle.component';
// Constants
import constants from './constants/settings-card-control-operations-shops-row.constants';
// Styles
import { ParagraphSecondaryComponentStyled } from 'styles/app.styled';
import {
  SettingsCardControlOperationsShopsRowComponentStyled,
  SettingsCardControlOperationsShopsRowLeftComponentStyled,
  SettingsCardControlOperationsContentShopsRowComponentStyled,
} from './settings-card-control-operations-shops-row.component.styled';
// Types
import { SettingsCardControlsOperationsShopsRowType } from './types/settings-card-controls-operations-shops-row.type';

const SettingsCardControlOperationsShopsRowComponent = ({
  description,
  id,
  title,
  translations,
}: SettingsCardControlsOperationsShopsRowType): React.ReactElement<SettingsCardControlsOperationsShopsRowType> => (
  <SettingsCardControlOperationsShopsRowComponentStyled>
    <SettingsCardControlOperationsShopsRowLeftComponentStyled>
      <ParagraphSecondaryComponentStyled>
        <SettingsCardControlOperationsContentShopsRowComponentStyled>
          <ParagraphComponent {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ROW_TITLE_PROPS}>
            <FormattedMessageComponent id={title} />
          </ParagraphComponent>
          <ParagraphComponent
            {...constants.SETTINGS_CARD_CONTROL_OPERATIONS_SHOPS_ROW_DESCRIPTION_PROPS}
          >
            <FormattedMessageComponent id={description} />
          </ParagraphComponent>
        </SettingsCardControlOperationsContentShopsRowComponentStyled>
      </ParagraphSecondaryComponentStyled>
    </SettingsCardControlOperationsShopsRowLeftComponentStyled>
    <SettingsCardControlOperationsToggleComponent {...{ id, translations }} />
  </SettingsCardControlOperationsShopsRowComponentStyled>
);

export default SettingsCardControlOperationsShopsRowComponent;
