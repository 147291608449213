// Vendors
import React from 'react';
import FormattedMessageComponent from 'components/formatted-message/formatted-message.component';
// Components
import { ParagraphComponent } from '@openbank/cb-ui-commons';
import { FinancingPPIContractingDescriptionLinkLinkComponent } from './components/link/financing-ppi-contracting-description-link-link.component';
// Constants
import { FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_TEST_ID } from './constants/financing-ppi-contracting-description-link.constants';
// Types
import { FinancingPPIContractingDescriptionLinkType } from './types/financing-ppi-contracting-description-link.type';

const FinancingPPIContractingDescriptionLinkComponent = ({
  description,
  size,
  values,
  testId,
  url,
}: FinancingPPIContractingDescriptionLinkType): React.ReactElement => (
  <ParagraphComponent
    {...{ size }}
    testId={FINANCING_PPI_CONTRACTING_DESCRIPTION_LINK_TEST_ID + testId}
  >
    <FormattedMessageComponent
      id={description}
      values={{
        ...values,
        link: (chunks: string | React.ReactNode) => (
          <FinancingPPIContractingDescriptionLinkLinkComponent {...{ chunks, size, testId, url }} />
        ),
      }}
    />
  </ParagraphComponent>
);

export { FinancingPPIContractingDescriptionLinkComponent };
